<template>
  <b-container fluid>
    <StepWizard current-step="LOST_RATIO" />
    <b-row>
      <b-col md="12" v-if="detailMi">
        <form action="" @submit.prevent="onSave()">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"><b>Loss Ratio Input</b></h4>
            </template>
            <template v-slot:headerAction>
              <h4 style="margin-right: 10px;"> From Forecast List </h4>
              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color">
                <div class="custom-switch-inner">
                  <input type="checkbox" class="custom-control-input" :class="'bg-'+forecastList.class" :id="'forecastList'" v-model="forecastList.checked">
                  <label class="custom-control-label" :for="'forecastList'" :data-on-label="forecastList.activeLabel" :data-off-label="forecastList.inactiveLabel">
                  </label>
                </div>
              </div>
            </template>
            <template v-slot:body>
              <b-row v-for="(row, index) in form.sources" :key="index">
                <b-col md="12">
                  <b-alert :show="true" variant="secondary">
                    <div class="iq-alert-text"><h4>{{row.type}}</h4></div>
                  </b-alert>
                </b-col>
                <b-col md="5">
                  <b-form-group label="Current Premium *" class="mt-5">
                    <b-input-group size="md" prepend="Rp">
                      <b-form-input type="number" @change="handleCalculateLossRatio(index)" step=".001" required v-model="row.premium" ></b-form-input>
                    </b-input-group>
                    <b-form-text class="text-muted">{{ formatCurrency(row.premium) }}</b-form-text>
                  </b-form-group>
                </b-col>
                <b-col md="5">
                  <b-form-group label="Claim Loss *" class="mt-5">
                    <b-input-group size="md" prepend="Rp">
                      <b-form-input type="number" @change="handleCalculateLossRatio(index)" step=".001" required v-model="row.loss" ></b-form-input>
                    </b-input-group>
                    <b-form-text class="text-muted">{{ formatCurrency(row.loss) }}</b-form-text>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="Loss Ratio *" class="mt-5">
                    <b-input-group size="md" append="%">
                      <b-form-input type="number" step=".01" required v-model="row.ratio" ></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </template>
            <template v-slot:footer>
              <b-row class="mt-3 mb-3">
                <b-col cols="12" md="12" class="pull-right text-center" >
                  <b-button type="submit" :disabled="form._saving" variant="primary">Submit</b-button>
                  <b-button @click="$router.go(-1)" variant="none" class="iq-bg-danger ml-3">Cancel</b-button>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </form>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { fleetsActions, miActions } from '@src/Utils/helper'
import StepNavigation from '@src/views/MI/component/StepNavigation'
import StepWizard from '@src/views/MI/component/StepWizard'

export default {
  name: 'BlankPage',
  components: { StepNavigation, StepWizard },
  mounted () {
    xray.index()
    if (this.$route.query.formId) {
      this.handleFetchMi()
    }
  },
  data () {
    return {
      permission_user:JSON.parse(localStorage.getItem('profile')).role.roles,
      detailMi: null,
      form: {
        _saving: false,
        'sources': [
          {
            'type': 'INDIVIDUAL',
            'premium': '',
            'loss': '',
            'ratio': ''
          },
          {
            'type': 'ACCUMULATIVE',
            'premium': '',
            'loss': '',
            'ratio': ''
          }
        ]
      },
      forecastList: {
        activeLabel: 'Yes',
        inactiveLabel: 'No',
        label: '',
        class: 'success',
        checked: true
      }
    }
  },
  computed: {
    userPermission(){
      if(['SUPER ADMIN', 'MARINE INSURANCE', 'MARINE INSURANCE ADMIN', 'MARINE INSURANCE MANAGER'].includes(this.permission_user)){
        return true
      } else{
        return false
      } 
    }
  },
  methods: {
    ...fleetsActions,
    ...miActions,
    formatCurrency(value) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      }).format(value)
    },
    async handleFetchMi () {
      const { data, status } = await this.fetchDetailMi({
        id: this.$route.query.formId,
        with: 'vehicle,type_coverage,type_loss,company,documents,company_claimant,status,creator,histories,loss_ratio'
      })

      if ([500, 404, 403].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        this.form._saving = false
        return
      }

      this.detailMi = data

      if (data.loss_ratio.length > 0) {
        this.handleFetchLossRatioInput()
      } else {
        this.handleFetchLossRatio()
      }
    },
    async handleFetchLossRatio () {
      const { data, status } = await this.getLossRatio({
        data: {
          id: this.$route.query.formId
        }
      })

      if ([500, 404, 403].includes(status)) {
        return
      }

      if (data.individual || data.accumulative) {
        this.form.sources = []

        if (data.individual) {
          this.form.sources.push({
            'type': 'INDIVIDUAL',
            'premium': data.individual.premium,
            'loss': data.individual.claim_loss,
            'ratio': data.individual.loss_ratio
          })
        }

        if (data.accumulative) {
          this.form.sources.push({
            'type': 'ACCUMULATIVE',
            'premium': data.accumulative.premium,
            'loss': data.accumulative.claim_loss,
            'ratio': data.accumulative.loss_ratio
          })
        }
      }
    },
    async handleFetchLossRatioInput () {
      const { data, status } = await this.getLossRatioInput({
        data: {
          id: this.$route.query.formId
        }
      })

      if ([500, 404, 403].includes(status)) {
        return
      }

      if (data.individual || data.accumulative) {
        this.form.sources = []

        if (data.individual) {
          this.form.sources.push({
            'type': 'INDIVIDUAL',
            'premium': data.individual.premium,
            'loss': data.individual.claim_loss,
            'ratio': data.individual.loss_ratio
          })
        }

        if (data.accumulative) {
          this.form.sources.push({
            'type': 'ACCUMULATIVE',
            'premium': data.accumulative.premium,
            'loss': data.accumulative.claim_loss,
            'ratio': data.accumulative.loss_ratio
          })
        }
      }
    },
    async onSave () {
      this.form._saving = true

      const { data, status } = await this.saveLossRatio({
        'form_id': this.$route.query.formId,
        'data': {
          'sources': this.form.sources
        }
      })

      if (status === 422) {
        this.$swal(
          `Oops!`,
          'Semua Field Wajib Diisi',
          'error'
        )
        this.form._saving = false

        return
      }

      if ([500, 404, 403].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        this.form._saving = false

        return
      }

      this.$swal(
        `Tersimpan`,
        'Data Tersimpan',
        'success'
      )

      setTimeout(() => {
        this.$router.push('/mi/list-initial')
      }, 1500)

      this.form._saving = false
    },
    handleCalculateLossRatio (index) {
      if (this.form.sources[index]) {
        this.form.sources[index].ratio = Number(this.form.sources[index].premium) > 0 ? Number(Number(this.form.sources[index].loss) / Number(this.form.sources[index].premium) * 100).toFixed(2) : 0
      }
    }
  }
}
</script>
