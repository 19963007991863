var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._l(_vm.menus, function (menu, menuIndex) {
    return _c('div', {
      key: menuIndex
    }, [_c('h4', {
      staticClass: "card-title text-primary"
    }, [_vm._v(_vm._s(menu.menu))]), menu.menu === 'Operations' ? _c('b-row', {
      staticClass: "text-center mb-4"
    }, _vm._l(menu.child, function (child, childIndex) {
      return _c('b-col', {
        key: childIndex,
        staticClass: "mb-2",
        attrs: {
          "lg": "1",
          "md": "2",
          "sm": "3"
        }
      }, [['SCL', 'Operational'].includes(child.menu) ? [_c('div', {
        staticClass: "d-flex flex-column align-items-center justify-content-center"
      }, [_c('img', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.top",
          value: child.menu,
          expression: "child.menu",
          modifiers: {
            "top": true
          }
        }],
        staticClass: "cursor-pointer",
        staticStyle: {
          "width": "80px",
          "height": "80px"
        },
        attrs: {
          "src": child.icon ? child.icon : _vm.fallbackIcon,
          "alt": "img"
        },
        on: {
          "click": function click($event) {
            return _vm.openModalOps(child);
          }
        }
      }), _c('span', [_c('small', {
        staticClass: "text-wrap"
      }, [_vm._v(_vm._s(child.menu))])])])] : [_c('router-link', {
        staticClass: "d-flex flex-column align-items-center justify-content-center",
        attrs: {
          "to": {
            path: child.link.name,
            query: {
              menuId: _vm.$route.query.menuId,
              menuObject: _vm.hashObject(child)
            }
          }
        }
      }, [_c('img', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.top",
          value: child.menu,
          expression: "child.menu",
          modifiers: {
            "top": true
          }
        }],
        staticStyle: {
          "width": "80px",
          "height": "80px"
        },
        attrs: {
          "src": child.icon ? child.icon : _vm.fallbackIcon,
          "alt": "e=img"
        }
      }), _c('span', [_c('small', {
        staticClass: "text-wrap"
      }, [_vm._v(_vm._s(child.menu))])])])]], 2);
    }), 1) : _c('b-row', {
      staticClass: "text-center mb-4"
    }, _vm._l(menu.child, function (child, childIndex) {
      return _c('b-col', {
        key: childIndex,
        staticClass: "mb-2",
        attrs: {
          "lg": "1",
          "md": "2",
          "sm": "3"
        }
      }, [_c('router-link', {
        staticClass: "d-flex flex-column align-items-center justify-content-center",
        attrs: {
          "to": {
            path: child.link.name,
            query: {
              menuId: _vm.$route.query.menuId,
              menuObject: _vm.hashObject(child)
            }
          }
        }
      }, [_c('img', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.top",
          value: child.menu,
          expression: "child.menu",
          modifiers: {
            "top": true
          }
        }],
        staticStyle: {
          "width": "80px",
          "height": "80px"
        },
        attrs: {
          "src": child.icon ? child.icon : _vm.fallbackIcon,
          "alt": "Responsive image"
        }
      }), _c('span', [_c('small', {
        staticClass: "text-wrap"
      }, [_vm._v(_vm._s(child.menu))])])])], 1);
    }), 1)], 1);
  }), _c('b-modal', {
    attrs: {
      "id": "modal-select-vehicle",
      "title": "Select Vehicle First",
      "centered": "",
      "no-close-on-backdrop": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              return _vm.$bvModal.hide('modal-select-vehicle');
            }
          }
        }, [_vm._v(" Cancel ")]), _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "success",
            "disabled": _vm.selectedFleet === null || _vm.selectedFleet === ''
          },
          on: {
            "click": function click($event) {
              return _vm.selectVehicle();
            }
          }
        }, [_vm._v(" OK ")])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "my-4"
  }, [_c('v-select', {
    attrs: {
      "label": "name",
      "options": _vm.fleetList,
      "reduce": function reduce(fleetList) {
        return fleetList.id;
      }
    },
    model: {
      value: _vm.selectedFleet,
      callback: function callback($$v) {
        _vm.selectedFleet = $$v;
      },
      expression: "selectedFleet"
    }
  })], 1)])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }