<template>
  <b-container fluid>
    <form action="#" method="post" @submit="onSave">
      <b-row>
        <b-col lg="4">
          <iq-card class-name="iq-user-profile-block1">
            <template v-slot:body>
              <div class="user-details-block">
                <div class="user-profile text-center">
                  <img
                    src="../../assets/images/user/11.png"
                    alt="profile-img"
                    class="avatar-130 img-fluid"
                  />
                </div>
                <div class="text-center mt-3">
                  <h4
                    ><b>{{
                      userDetails?.personal_data?.full_name ?? '-'
                    }}</b></h4
                  >
                  <p>
                    {{ countAge }}
                    Tahun,
                    {{ userDetails?.personal_data?.place_of_birth ?? '-' }}
                    <br />
                    <b-badge variant="primary">Lokasi : Asam-Asam</b-badge>
                  </p>
                </div>
              </div>
              <table class="table table-striped">
                <tbody>
                  <tr>
                    <td><strong>Nomor KTP</strong></td>
                    <td>{{ userDetails?.personal_data?.nik_ktp ?? '-' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Seaferer Code</strong></td>
                    <td>{{
                      userDetails?.personal_data?.seaferer_code ?? '-'
                    }}</td>
                  </tr>
                  <tr>
                    <td><strong>Nomor Seaman Book</strong></td>
                    <td>{{
                      userDetails?.personal_data?.seaman_book_number ?? '-'
                    }}</td>
                  </tr>
                  <tr>
                    <td><strong>Nomor Passport</strong></td>
                    <td>{{
                      userDetails?.personal_data?.passport_number ?? '-'
                    }}</td>
                  </tr>
                  <tr>
                    <td><strong>Nomor NPWP</strong></td>
                    <td>{{
                      userDetails?.personal_data?.npwp_number ?? '-'
                    }}</td>
                  </tr>
                </tbody>
              </table>
            </template>
          </iq-card>
        </b-col>
        <b-col lg="8">
          <iq-card>
            <template v-slot:body>
              <h4 class="card-title text-primary"
                ><strong>DATA DIRI</strong></h4
              >
              <table class="table table-striped">
                <tbody>
                  <tr>
                    <td width="25%"><strong>Apply Date</strong></td>
                    <td width="25%">{{
                      userDetails?.personal_data?.created_at ?? '-'
                    }}</td>
                    <td width="25%"><strong>Tempat / Tgl Lahir</strong></td>
                    <td width="25%"
                      >{{ userDetails?.personal_data?.place_of_birth ?? '-' }} /
                      {{ userDetails?.personal_data?.date_of_birth ?? '-' }}</td
                    >
                  </tr>
                  <tr>
                    <td width="25%"><strong>Kewarganegaraan</strong></td>
                    <td width="25%">{{
                      userDetails?.personal_data?.citizenship ?? '-'
                    }}</td>
                    <td width="25%"><strong>Status Pernikahan</strong></td>
                    <td width="25%">{{
                      userDetails?.personal_data?.marital_status?.name ?? '-'
                    }}</td>
                  </tr>
                  <tr>
                    <td width="25%"><strong>Agama</strong></td>
                    <td width="25%">{{
                      userDetails?.personal_data?.religion?.name ?? '-'
                    }}</td>
                    <td width="25%"><strong>Jumlah Anak</strong></td>
                    <td width="25%"
                      >{{
                        userDetails?.personal_data?.number_of_children ?? '-'
                      }}
                      Orang</td
                    >
                  </tr>
                  <tr>
                    <td width="25%"><strong>Golongan Darah</strong></td>
                    <td width="25%">{{
                      userDetails?.personal_data?.blood_type?.alias ?? '-'
                    }}</td>
                    <td width="25%"><strong>Nomor Tlp/Hp</strong></td>
                    <td width="25%">{{
                      userDetails?.personal_data?.hp_number ?? '-'
                    }}</td>
                  </tr>
                  <tr>
                    <td width="25%"><strong>Jenis Kelamin</strong></td>
                    <td width="25%">{{
                      userDetails?.personal_data?.gender ?? '-'
                    }}</td>
                    <td width="25%"><strong>Email</strong></td>
                    <td width="25%">{{
                      userDetails?.personal_data?.email ?? '-'
                    }}</td>
                  </tr>

                  <tr>
                    <td width="25%"><strong>Alamat</strong></td>
                    <td colspan="3">{{
                      userDetails?.alamat_ktp?.[0]?.alamat ?? '-'
                    }}</td>
                  </tr>
                  <tr>
                    <td width="25%"><strong>Alamat Domisili</strong></td>
                    <td colspan="3">{{
                      userDetails?.alamat_domisili?.[0]?.alamat ?? '-'
                    }}</td>
                  </tr>
                </tbody>
              </table>
            </template>
          </iq-card>
        </b-col>
        <b-col lg="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"
                ><strong
                  >FORMULIR WAWANCARA
                  {{
                    userDetails?.personal_data?.position?.name?.toUpperCase()
                  }}
                  /
                  <i
                    >{{
                      userDetails?.personal_data?.position?.name?.toUpperCase()
                    }}
                    INTERVIEW FORM</i
                  ></strong
                ></h4
              >
            </template>
            <template v-slot:body>
              <b-row>
                <b-col md="12">
                  <b-button
                    type="submit"
                    variant="primary"
                    class="float-right ml-3"
                    @click="onSave"
                    ><i class="fa fa-save"></i> Save</b-button
                  >
                  <b-button
                    type="reset"
                    variant="none"
                    class="iq-bg-danger float-right"
                    @click="resetForm"
                    ><i class="fa fa-close"></i> Reset Form</b-button
                  >
                </b-col>
                <b-col md="12">
                  <fieldset
                    class="form-group border p-3"
                    v-for="(interview, keyInterview) in interview_questions"
                    :key="keyInterview"
                  >
                    <legend class="w-auto px-2" style="font-size: 12pt">{{
                      interview?.name
                    }}</legend>
                    <b-row>
                      <b-col md="12">
                        <table class="table table-striped">
                          <thead>
                            <tr class="text-center">
                              <th width="45%"></th>
                              <th
                                width="15%"
                                v-for="(item, index) in point_interview"
                                v-bind:key="index"
                                >{{ item?.name }} <br />
                                {{ item?.value_letter }}</th
                              >
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                criteria, keyCriteria
                              ) in interview.data_criteria"
                              :key="keyCriteria"
                            >
                              <th>{{ criteria.content }}<br /></th>
                              <td
                                class="text-center"
                                v-for="(point, keyPoint) in criteria?.point"
                                :key="keyPoint"
                              >
                                <template>
                                  <b-form-checkbox
                                    v-model="point.checked"
                                    name="check-button"
                                    class="ml-4"
                                    switch
                                    inline
                                    @change="
                                      selectMenus(
                                        point,
                                        keyInterview,
                                        keyCriteria,
                                        keyPoint
                                      )
                                    "
                                  >
                                  </b-form-checkbox>
                                </template>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <label for="komentar"><strong>Komentar</strong></label>
                        <b-form-textarea
                          rows="2"
                          value="Comments if any / Komentar jika ada"
                          v-model="interview.comment"
                          required
                        ></b-form-textarea>
                      </b-col>
                    </b-row>
                  </fieldset>
                </b-col>
                <!-- <b-col md="12">
                  <div
                    class="d-flex align-items-center justify-content-between w-100 px-5"
                    v-if="isShow"
                  >
                    <div>
                      <label
                        >Total Score: <strong>{{ total }}</strong></label
                      >
                    </div>
                    <div
                      ><label
                        >Average Score:
                        <strong>{{ average.toFixed(2) }}</strong></label
                      >
                    </div>
                  </div>

                  <b-button
                    type="button"
                    @click="showScore"
                    block
                    variant="none"
                    class="iq-bg-success float-right"
                    ><i class="fa fa-eye"></i
                    >{{ isShow ? 'Update' : 'Show' }} Score</b-button
                  >
                </b-col> -->
              </b-row>
              <hr />
              <b-row class="mt-3">
                <b-col md="6">
                  <div class="form-group row">
                    <label
                      label="Clasification *"
                      for="nama_kandidiat"
                      class="col-sm-6"
                      ><strong>Wawancara Oleh</strong><br /><i
                        >Interview by</i
                      ></label
                    >
                    <div class="col-sm-6">
                      <b-form-input
                        id="job_name"
                        type="text"
                        placeholder="Nama"
                        v-model="wawancara_oleh"
                        required
                      ></b-form-input>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      label="Clasification *"
                      for="nama_kandidiat"
                      class="col-sm-6"
                      ><strong>Hasil Wawancara</strong><br /><i
                        >Result Interview</i
                      ></label
                    >
                    <div class="col-sm-6">
                      <b-form-select
                        plain
                        v-model="hasil_wawancara"
                        :options="wawancara"
                        size="lg"
                        required
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null"
                            >Hasil Wawancara</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      label="Clasification *"
                      for="nama_kandidiat"
                      class="col-sm-6"
                      ><strong>Urgensi Wawancara</strong><br /><i
                        >Interview Urgency</i
                      ></label
                    >
                    <div class="col-sm-6">
                      <b-form-select
                        plain
                        v-model="urgensi"
                        :options="optionsUrgensi"
                        size="lg"
                        required
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null"
                            >None</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      label="Clasification *"
                      for="nama_kandidiat"
                      class="col-sm-6"
                      ><strong>Lokasi Wawancara</strong><br /><i
                        >Interview Location</i
                      ></label
                    >
                    <div class="col-sm-6">
                      <b-form-input
                        id="location"
                        type="text"
                        placeholder="Lokasi Wawancara"
                        v-model="location"
                        required
                      ></b-form-input>
                    </div>
                  </div>
                </b-col>
                <b-col md="6" class="mt-3">
                  <b-button
                    :disabled="saving"
                    variant="primary"
                    block
                    type="submit"
                  >
                    <span
                      v-if="saving"
                      class="spinner-border spinner-border-sm"
                    />
                    <i v-if="!saving" class="fa fa-file-signature"></i>
                    <span v-if="!saving">Save</span>
                  </b-button>

                  <b-button
                    type="reset"
                    block
                    variant="none"
                    class="iq-bg-danger float-right"
                    @click="resetForm"
                    ><i class="fa fa-close"></i> Reset Form</b-button
                  >
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { crewingActions } from '@src/Utils/helper'
import moment from 'moment'

export default {
  name: 'InterviewReview',
  data() {
    return {
      wawancara: [
        { value: 'Passed', text: 'Suitable / Cocok' },
        { value: 'Failed', text: 'Not Suitable / Tidak Cocok' },
      ],
      optionsUrgensi: [
        { value: 'emergency', text: 'Emergency' },
        { value: 'normal', text: 'Normal' },
        { value: 'backup', text: 'Backup' },
      ],
      loading: false,
      saving: false,
      urgensi: '',
      location: '',
      hasil_wawancara: '',
      wawancara_oleh: '',
      userDetails: '',
      alamat_ktp: '',
      alamat_domisili: '',
      type_position_id: '',
      point_interview: [],
      criteria_interview: [],
      interview_questions: [],

      total: 0,
      average: 0,
      isShow: false,
    }
  },

  async mounted() {
    xray.index()
    // this.fetchFleets()
    await this.getProfileUser()
    await this.getPointInterview()
    await this.getCriteriaInterview()
    this.mergeInterview()
  },
  computed: {
    countAge() {
      let userAge = moment().diff(
        new Date(this.userDetails?.personal_data?.date_of_birth),
        'years'
      )
      return userAge
    },
  },
  methods: {
    ...crewingActions,
    async getProfileUser() {
      if (this.$route.params.id) {
        let method = 'getProfileCandidate'

        const { status, data } = await this[method](this.$route.params.id)

        console.log(this.$route.params.id, 'ID CANDIDATE')
        console.log(data, 'THIS IS PROFILE CANDIDATE')

        if (status !== 'success') {
          this.$swal(`Oops!`, `User candidate tidak ada.`, 'error')
          return
        }

        this.userDetails = data
        this.alamat_ktp = data?.alamat_ktp?.[0]?.alamat
        this.alamat_domisili = data?.alamat_domisili?.[0]?.alamat
        this.type_position_id = data?.personal_data?.position?.type_position_id
        this.urgensi = data?.interview?.[0]?.urgensi
        this.location = data?.interview?.[0]?.location

        console.log(this.type_position_id, 'POSITION ID')
      }
    },

    async getPointInterview() {
      const { status, data } = await this.fetchPointInterview()

      if (status !== 'success') {
        return
      }

      console.log(data, 'THIS IS POINT INTERVIEW')

      this.point_interview = data?.data
    },

    async getCriteriaInterview() {
      if (this.type_position_id) {
        let params = { type_position_id: this.type_position_id }

        const { status, data } = await this.fetchCriteriaInterview(params)

        console.log(data, 'THIS IS CRITERIA INTERVIEW')
        this.criteria_interview = data
      }
    },

    mergeInterview() {
      if (
        this.criteria_interview.length > 0 &&
        this.point_interview.length > 0
      ) {
        this.criteria_interview.map((criteria) => {
          this.interview_questions.push({
            ...criteria,
            comment: '',
            data_criteria: criteria.data_criteria.map((criteria) => {
              return {
                ...criteria,
                point: this.point_interview.map((point) => {
                  return {
                    ...point,
                    checked: false,
                  }
                }),
              }
            }),
          })
        })
        console.log(this.interview_questions, 'INTERVIEW QUESTIONS')
      } else {
        console.log('Data interview dan point interview tidak ada.')
      }
    },

    selectMenus(point, keyInterview, keyCriteria, keyPoint) {
      if (point.checked) {
        this.interview_questions[keyInterview].data_criteria[
          keyCriteria
        ].point.forEach((val, index) => {
          if (index !== keyPoint) {
            val.checked = false
          }
        })
      }
    },

    resetForm() {
      if (this.interview_questions.length > 0) {
        this.interview_questions.forEach((interview, index) => {
          interview.comment = ''
          interview.data_criteria.forEach((criteria, ind) => {
            criteria.point.forEach((point, i) => {
              return (point.checked = false)
            })
          })
        })
      }
    },

    showScore() {
      let readyToSave = {
        total: 0,
        average: 0,

        interview: [],
        content: [],
      }

      this.interview_questions.forEach((interview, index) => {
        if (interview.comment) {
          readyToSave.interview.push({
            comment: interview.comment,
          })
        }
        interview.data_criteria.forEach((criteria, ind) => {
          criteria.point.forEach((point, i) => {
            if (point.checked) {
              readyToSave.content.push({
                criteria_id: criteria.id_interview_point,
                point_value: point.value,
              })
            }
          })
        })
      })

      if (readyToSave.content.length > 0) {
        this.total = readyToSave.content.reduce((o, n) => o + n.point_value, 0)
        this.average =
          readyToSave.content.reduce((o, n) => o + n.point_value, 0) /
          readyToSave.content.length
      }

      this.isShow = true
    },

    async onSave(e) {
      e.preventDefault()
      this.saving = true

      let readyToSave = {
        status: this.hasil_wawancara,
        date: new Date(),
        location: this.location,
        total: 0,
        average: 0,
        urgensi: this.urgensi,
        interview_by: [],
        interview: [],
        content: [],
      }

      this.interview_questions.forEach((interview, index) => {
        if (interview.comment) {
          readyToSave.interview.push({
            comment: interview.comment,
          })
        }
        interview.data_criteria.forEach((criteria, ind) => {
          criteria.point.forEach((point, i) => {
            if (point.checked) {
              readyToSave.content.push({
                criteria_id: criteria.id_interview_point,
                point_value: point.value,
              })
            }
          })
        })
      })

      if (readyToSave.content.length > 0) {
        readyToSave.total = readyToSave.content.reduce(
          (o, n) => o + n.point_value,
          0
        )
        readyToSave.average =
          readyToSave.content.reduce((o, n) => o + n.point_value, 0) /
          readyToSave.content.length
      }
      readyToSave.interview_by.push({ name: this.wawancara_oleh })

      console.log(readyToSave, 'READY TO SAVE')

      const { status } = await this.setFormInterview({
        data: readyToSave,
        id: this.$route.params.id,
      })

      if (status !== 'success') {
        this.saving = false
        this.$swal(`Oops!`, 'Semua Field Wajib Diisi', 'error')
        return
      }

      let changeStatus = new FormData()

      changeStatus.append('change_status', 'mcu')

      await this.changeStatusCandidate({
        id: this.$route.params.id,
        change_status: changeStatus,
      })

      this.$swal(`Berhasil`, 'Review Interview Candidate.', 'success')

      this.saving = false

      this.$router.push('/crewing/list-crew')
    },
  },
}
</script>
