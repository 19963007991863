<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">
              <strong>Spesification</strong>
            </h4>
          </template>
          <template v-slot:headerAction>
            <div class="d-flex align-items-center gap-4 float-right">
              <a class="text-primary mr-4" role="button" style="cursor: pointer" @click="onExportSpesification">
                <i class="fa fa-file-excel mr-2" />
                <span>Export Data</span>
              </a>
              <a class="text-primary mr-4" role="button" style="cursor: pointer" @click="openslideFormSpesification('ADD', null)" v-if="addPermission">
                <i class="fa fa-plus mr-2" />
                <span>Create Data</span>
              </a>
            </div>
          </template>
          <template v-slot:body>
            <!-- FILTERING SpesificationS -->
            <b-row class="mb-4">
              <b-col md="3">
                <b-form-group label="Search" label-for="search" v-model="params.search">
                  <b-form-input
                    type="text"
                    placeholder="Search ID/Name..."
                    v-model="params.search"
                    name="search"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="1">
                <b-form-group label="Filter" label-for="button_search">
                  <b-button
                    @click="getListSpesification"
                    type="button"
                    variant="primary"
                    ><i class="fa fa-filter"></i
                  ></b-button>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- TABLE CONTENT -->
            <b-row>
              <b-col md="12">
                <div v-if="loading" class="alert alert-info">Loading...</div>
                <iq-card> 
                  <div class="overflow-auto d-flex" v-if="list?.data?.length > 0">
                    <table class="table table-striped table-bordered">
                      <thead>
                        <tr>
                            <th class="text-center" style="width: 20px">No</th>
                            <th class="text-center text-truncate">Code</th>
                            <th class="text-center text-truncate">Name</th>
                            <th class="text-center text-truncate">Description</th>
                            <th class="text-center text-truncate">Active</th>
                            <th class="text-center text-truncate">For Fleet Type</th>
                            <th class="text-center" style="width: 20px">#</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(state, index) in list.data" :key="index">
                          <td>{{ index + 1 }}</td>
                          <td class="text-truncate">{{ state?.code }}</td>
                          <td class="text-truncate">{{ state?.name }}</td>
                          <td>{{ state?.description }}</td>
                          <td class="text-truncate text-center">{{ state?.active ? 'Yes' : 'No' }}</td>
                          <td class="text-truncate text-center">
                            <div class="d-flex flex-wrap" v-if="state?.vehicleType?.length > 0">
                              <b-badge variant="primary" v-for="(obj, objIndex) in state?.vehicleType" :key="objIndex" class="mr-2 mb-2">
                                {{ obj?.name }}
                              </b-badge>
                            </div>
                            <span v-else>-</span>
                          </td>
                          <td v-if="updatePermission || deletePermission">
                            <div class="d-flex align-items-center">
                              <i class="fa fa-pen text-primary mr-3" style="cursor: pointer;" @click="openslideFormSpesification('EDIT', state?.id)" />
                              <i class="fa fa-trash text-danger" style="cursor: pointer;" @click="onDeleteSpesification(state)" />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- <div class="mt-3" v-if="list?.data?.length > 0">
                    <b-pagination
                      v-model="params.page"
                      :total-rows="list?.meta?.totalItem"
                      @change="getListSpesification"
                      :per-page="list?.meta?.perPage"
                      first-number
                      align="center"
                    ></b-pagination>
                  </div> -->
                  <p v-else class="text-center my-4">No Spesification data found.</p>
                </iq-card>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>

      <b-col lg="12">
        <b-sidebar v-model="slideFormSpesification" id="form-spesification" :title="`${action} Spesification`" width="40%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
          <template #default="{hide}">
            <b-row class="m-3">
              <b-col cols="12">
                <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 text-primary">Spesification</legend>
                    <b-row class="mt-2">
                      <b-col md="12">
                        <FormSpesification 
                          :action="action" 
                          :edit-id="editId" 
                          :refresh-data="refreshData" 
                        />
                      </b-col>
                    </b-row>
                </fieldset>
              </b-col>
            </b-row>
            </template>
        </b-sidebar>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
  import _ from 'lodash'
  import { createPopper } from '@popperjs/core'
  import moment from 'moment'
  import FormSpesification from './FormSpesification.vue'
  import { busdevActions, fleetsActions } from '@src/Utils/helper'

  export default {
    name: 'Spesification',
    props:{
      refreshMasterData: {
        type: Function,
        default: () => {}
      },
    },
    data() {
      return {
        exporting:false,
        loading:false,

        slideFormSpesification:false,

        action:'',
        editId:null,

        params:{
          page: 1,
          perPage:25,
          search:'',
        },

        list: {
          data:[],
          meta:null
        },
      }
    },
    components:{
      FormSpesification,
    },
    watch: {},
    computed: {
      addPermission(){
        let hasAccess = true
        // let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
        //   .find(obj => obj.menu === 'Apps').child
        //   .find(obj => obj.menu === 'Business Development').child
        //   .find(obj => obj.menu === 'Busdev Spesification').add

        return hasAccess
      },
      updatePermission(){
        let hasAccess = true
        // let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
        //   .find(obj => obj.menu === 'Apps').child
        //   .find(obj => obj.menu === 'Business Development').child
        //   .find(obj => obj.menu === 'Busdev Spesification').update

        return hasAccess
      },
      deletePermission(){
        let hasAccess = true
        // let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
        //   .find(obj => obj.menu === 'Apps').child
        //   .find(obj => obj.menu === 'Business Development').child
        //   .find(obj => obj.menu === 'Busdev Spesification').delete

        return hasAccess
      },
    },
    async mounted() {
      this.getListSpesification()
    },
    created () {
      // this.getListSpesification = _.debounce(this.getListSpesification, 500)
    },
    methods: {
      ...busdevActions,
      ...fleetsActions,
      refreshData(){
        this.getListSpesification()
        this.refreshMasterData()
      },
      openslideFormSpesification(action, id){
        this.action = action
        this.editId = id || null

        this.slideFormSpesification = true
        this.$root.$emit('bv::toggle::collapse', `form-spesification`)
      },
      closeslideFormSpesification(){
        this.action = ''
        this.editId = null

        this.slideFormSpesification = true
        this.$root.$emit('bv::toggle::collapse', `form-spesification`)
      }, 
      refreshData(){
        this.slideFormSpesification = false
        this.$root.$emit('bv::toggle::collapse', `form-spesification`)

        this.getListSpesification()
        this.refreshMasterData()
      },
      
      async getListSpesification(page) {
        let params = {
          page: page || 1,
          ...this.params,
        }

        if(!params.search) delete params.search

        let res = await this.fetchSpesification(params)

        console.log(res, "RESST")

        if (res.data.status === true && res.data.data.length > 0) {
          this.list.data = res.data?.data
          // this.list.meta = res.data.data?.meta
        } else {
          this.list.data = []
          this.list.meta = null
        }
      },
      async onExportSpesification() {
        this.exporting = true
        try {
          let params = {
            ...this.params,
          }

          if(!params.search) delete params.search

          let res = await this.exportSpesification(params)

          if(res.status === 'success'){
            saveAs(res.data, 'List Spesifications.xlsx')
          }
        } catch (error) {
          this.$swal(
            'Failed Export!',
            'Something went wrong.',
            'error'
          ) 
        }

        this.exporting = false
      },

      async onDeleteSpesification(data){
        this.$swal({
            title: `Are you sure want to delete Spesification Code ${data?.code} ?`,
            text: "Yes, i'm sure.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
              const res = await this.deleteSpesification({id:data.id})

              if(res?.data?.status === false || res?.data?.code === 400){
                  this.$swal(
                      'Failed!',
                      res?.data?.message,
                      'error'
                  )
                  return 
              } 
              await this.getListSpesification()
              this.$swal(
                  'Success!',
                  'Success delete Spesification.',
                  'success'
              )
            }
        })
      },
    }
  }
</script>