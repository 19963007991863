<template>
  <div class="overflow-auto d-flex flex-column">
    <table class="table mb-0 table-striped table-hover">
      <thead class="bg-primary">
        <th class="text-center">No</th>
        <th>Fleet</th>
        <th>Date</th>
        <th
          v-for="(item, i) in activitiesHeader"
          :key="i"
          class="text-truncate"
          >{{ item }}</th
        >
      </thead>
      <tbody v-for="(item, i) in matrix" :key="i" class="">
        <tr class="" style="background: #d2d2d2 !important">
          <td colspan="19"
            ><strong>{{ item.projectSub.name }}</strong></td
          >
        </tr>
        <template v-for="(vehicle, j) in item.vehicleTypes">
          <tr :key="j + i">
            <td colspan="19" style="background: #ffe8e8 !important" class="pl-3"
              >{{ vehicle.vehicleType.name }}
            </td>
          </tr>
          <tr v-for="(fleet, idx) in vehicle.vehicles" :key="idx + j + i +1">
            <td class="text-center">{{ idx + 1 }}</td>
            <td class="text-truncate">{{ fleet.vehicle.name }}</td>
            <td colspan="20">
                {{fleet}}
            </td>
            <!-- <td
              v-for="(label, index) in fleet?.activities"
              :key="index + idx + j + i"
              class="text-truncate"
            >
              sdasd
            </td> -->
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    matrix: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activitiesHeader: [
        'Ops',
        'Running',
        'Technical',
        'Docking',
        'Weather',
        'Purchasing',
        'Waiting Loading',
        'Waiting Unloading',
        'Waiting DOC POL',
        'Waiting DOC POD',
        'Crewing',
        'Finance',
        'Loading',
        'Unloading',
        'Waiting upslip dock',
        'Breakdown',
      ],
    }
  },
  computed: {
    findActivity(activities, label) {
        console.log(label);
        
      return activities.find((el, i) => el.name == label)
    },
  },
}
</script>
<style scoped>
.table-container {
  overflow: hidden;
}
</style>
