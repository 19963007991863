var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("SPAL TEMPLATE")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('b-button', {
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "primary",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              return _vm.openSlideFormSpal('ADD', null);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v("   CREATE SPAL")])];
      },
      proxy: true
    }])
  }, [[_c('b-row', {
    staticClass: "m-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "SEARCH"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Search..."
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "CONTRACT TYPE"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "placeholder": "Select Options...",
      "options": _vm.optionsContractType
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("Select Options")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.params.contract_type_id,
      callback: function callback($$v) {
        _vm.$set(_vm.params, "contract_type_id", $$v);
      },
      expression: "params.contract_type_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "5"
    }
  }), _c('b-col', {
    attrs: {
      "lg": "1"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Filter",
      "label-for": "button_search"
    }
  }, [_c('b-button', {
    staticClass: "mb-3 mr-1",
    attrs: {
      "variant": "primary"
    }
  }, [_c('i', {
    staticClass: "fa fa-search"
  })])], 1)], 1)], 1)], [_c('b-row', {
    staticClass: "m-2"
  }, [!_vm.loading ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_vm.listSpal.data.length > 0 ? _c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "10px"
    }
  }, [_vm._v("NO")]), _c('th', {
    staticStyle: {
      "width": "150px"
    }
  }, [_vm._v("NAME")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("TYPE")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("LANGUAGE")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("STATUS")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("ACTION")])])]), _c('tbody', _vm._l(_vm.listSpal.data, function (state, index) {
    var _state$name, _state$contract_type$, _state$contract_type;
    return _c('tr', {
      key: index
    }, [_c('th', {
      staticClass: "text-center",
      attrs: {
        "width": "10px"
      }
    }, [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s((_state$name = state === null || state === void 0 ? void 0 : state.name) !== null && _state$name !== void 0 ? _state$name : '-'))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s((_state$contract_type$ = state === null || state === void 0 ? void 0 : (_state$contract_type = state.contract_type) === null || _state$contract_type === void 0 ? void 0 : _state$contract_type.name) !== null && _state$contract_type$ !== void 0 ? _state$contract_type$ : '-'))]), _c('td', {
      staticClass: "text-center"
    }, [_c('div', {
      staticClass: "d-flex flex-wrap align-items-center flex-column"
    }, _vm._l(JSON.parse(state === null || state === void 0 ? void 0 : state.language), function (language, langIndex) {
      return _c('b-badge', {
        staticClass: "border border-primary text-primary text-uppercase mb-2 mr-2 w-auto",
        attrs: {
          "variant": "none"
        }
      }, [_vm._v(_vm._s(language === null || language === void 0 ? void 0 : language.text))]);
    }), 1)]), _c('td', {
      staticClass: "text-center text-success"
    }, [_vm._v("Active")]), _c('td', {
      staticClass: "text-center"
    }, [_c('b-dropdown', {
      attrs: {
        "size": "lg",
        "variant": "link iq-bg-primary iq-border-radius-10",
        "toggle-class": "text-decoration-none",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function fn() {
          return [_c('span', {
            staticClass: "dropdown-toggle p-0",
            attrs: {
              "id": "dropdownMenuButton5",
              "data-toggle": "dropdown"
            }
          }, [_c('i', {
            staticClass: "fa fa-pencil-square-o m-0 text-primary"
          })])];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.openSlideFormSpal('PREVIEW', state === null || state === void 0 ? void 0 : state.id);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-eye mr-2"
    }), _vm._v("Preview ")]), _c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": "success"
      },
      on: {
        "click": function click($event) {
          return _vm.openSlideFormSpal('EDIT', state === null || state === void 0 ? void 0 : state.id);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-pen mr-2"
    }), _vm._v("Edit ")]), _c('b-dropdown-divider'), _c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.onDeleteSpal(state);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-ban mr-2"
    }), _vm._v("Delete ")])], 1)], 1)]);
  }), 0)]) : _vm._e(), _vm.listSpal.data.length > 0 ? _c('div', {
    staticClass: "mt-3"
  }, [_c('b-pagination', {
    attrs: {
      "first-number": "",
      "align": "center"
    }
  })], 1) : _c('div', {
    staticClass: "d-flex align-items-center justify-content-center w-100 my-2 mb-4"
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v("No Data Spal.")])])]) : _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "alert alert-info"
  }, [_vm._v(" Loading... ")])])], 1)], _c('b-sidebar', {
    attrs: {
      "id": "add_spal",
      "title": "".concat(_vm.action, " SPAL TEMPLATE"),
      "width": "100%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_c('SlideFormSpal', {
          attrs: {
            "refresh-data": _vm.refreshData,
            "optionsContractType": _vm.optionsContractType,
            "editId": _vm.editId,
            "action": _vm.action
          },
          on: {
            "updateAction": _vm.updateAction
          }
        })];
      }
    }]),
    model: {
      value: _vm.slideAddSpal,
      callback: function callback($$v) {
        _vm.slideAddSpal = $$v;
      },
      expression: "slideAddSpal"
    }
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }