<template>
  <b-row class="px-3">
    <b-sidebar v-model="show.detailExpanse" right width="70%" bg-variant="white" title="Detail Expanse">
        <DetailExpanse/>
    </b-sidebar>
    <b-col md="12">
      <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
        <template #headerTitle>
          <h5 class="card-title text-primary"
            ><strong>Budget Technical</strong></h5
          >
        </template>
        <template #body>
          <b-row>
            <b-col md="6"> <TechnicalExpanseChartBudget /> </b-col>
            <b-col md="6"> <BarChartBudgetTechnical /> </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <div class="overflow-auto">
                <table class="table mb-0 table-striped table-hover">
                  <thead>
                    <th
                      class="text-right text-truncate"
                      v-for="(col, i) in columns"
                      :key="i"
                      :colspan="col.colspan"
                      :style="{ width: col?.width }"
                    >
                      <div :class="col.text" v-if="col.subtitle">
                        <div>{{ col.name }}</div>
                        <small>{{ col.subtitle }}</small>
                      </div>
                      <div :class="col.text" v-else>
                        {{ col.name }}
                      </div>
                    </th>
                  </thead>
                  <tbody v-for="(item, i) in companies" :key="i">
                    <tr class="text-truncate">
                      <td colspan="2">
                        <div
                          class="mb-1 w-100 cursor-pointer d-flex align-items-center"
                          @click="item.collapse = !item.collapse"
                        >
                          <i
                            class="fas fa-chevron-right animation-rotate mr-2"
                            :class="item.collapse ? 'rotate-90' : 'rotate-0'"
                          ></i>
                          <h6>
                            <strong>
                              {{ item.name }}
                            </strong>
                          </h6>
                        </div>
                      </td>
                      <td class="text-right">{{ item.budget }}</td>
                      <td class="text-right">{{ item.ytdBudget }}</td>
                      <td class="text-right">{{ item.ytdReal }}</td>
                      <td class="text-right">{{ item.budgetAug }}</td>
                      <td class="text-right">{{ item.budgetAugReal }}</td>
                      <td class="text-center">{{ item.percentAug }}</td>
                      <td class="text-center">{{ item.realisasiYtd }}</td>
                      <td class="text-center">Notes</td>
                    </tr>
                    <tr>
                      <td class="text-truncate p-0" colspan="11">
                        <b-collapse
                          :id="`collapse-table-` + i"
                          v-model="item.collapse"
                        >
                          <table class="table mb-0 table-striped table-hove">
                            <thead>
                              <th
                                v-for="(col, colIdx) in subColumn"
                                :key="colIdx"
                                style="background: #3a5de7"
                                >{{ col.name }}</th
                              >
                            </thead>
                            <tbody>
                              <tr
                                v-for="(row, rowIdx) in item.vehicles"
                                :key="rowIdx"
                              >
                                <td>{{ row.name }}</td>
                                <td>{{ row.projectName }}</td>
                                <td class="text-right">{{ row.budget }}</td>
                                <td class="text-right">{{ row.ytdBudget }}</td>
                                <td class="text-right">{{ row.ytdReal }}</td>
                                <td class="text-right">{{ row.budgetAug }}</td>
                                <td class="text-right">{{
                                  row.budgetAugReal
                                }}</td>
                                <td class="text-center">{{
                                  row.percentAug
                                }}</td>
                                <td class="text-center">{{
                                  row.realisasiYtd
                                }}</td>
                                <td class="text-center">
                                  <b-button
                                    variant="primary"
                                    size="sm"
                                    style="background: #3a5de7"
                                    class="text-white"
                                    @click="
                                      show.detailExpanse = !show.detailExpanse
                                    "
                                  >
                                    Detail
                                  </b-button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </b-collapse>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
          </b-row>
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>

<script>
import BarChartBudgetTechnical from './Chart/BarChartBudgetTechnical.vue'
import TechnicalExpanseChartBudget from './Chart/TechnicalExpanseChartBudget.vue'
import DetailExpanse from './DetailExpanse.vue';

export default {
  components: {
    TechnicalExpanseChartBudget,
    BarChartBudgetTechnical,
    DetailExpanse
  },
  data() {
    return {
      chartData: [
        {
          title: 'PT. Energy Transporter Indonesia',
          elemnt: 'energy-transporter-indonesia',
          data: [12, 34, 56, 78, 56, 12, 56, 78, 89, 56, 23, 89],
        },
        {
          title: 'PT. Transcoal Pacific',
          elemnt: 'transcoal-pacific',
          data: [12, 34, 56, 78, 56, 12, 56, 78, 89, 56, 23, 89],
        },
        {
          title: 'PT. Sentra Makmur Lines',
          elemnt: 'sentra-makmur-lines',
          data: [12, 34, 56, 78, 56, 12, 56, 78, 89, 56, 23, 89],
        },
        {
          title: 'PT. Renjani Maritim Transportasi',
          elemnt: 'renjani-maritim-transportasi',
          data: [12, 34, 56, 78, 56, 12, 56, 78, 89, 56, 23, 89],
        },
      ],
      companies: [
        {
          name: 'PT. Energy Transporter Indonesia',
          budget: '1.584.000.000',
          ytdBudget: '1.056.000.000',
          ytdReal: '1.065.000.000',
          budgetAug: '132.000.000',
          budgetAugReal: '132.000.000',
          percentAug: '97.87%',
          realisasiYtd: '100.84%',
          collapse: false,
          vehicles: [
            {
              name: 'TB. ETI 311',
              projectName: 'AI Project - Transhipment',
              budget: '1.584.000.000',
              ytdBudget: '1.056.000.000',
              ytdReal: '1.065.000.000s',
              budgetAug: '132.000.000',
              budgetAugReal: '132.000.000',
              percentAug: '97.87%',
              realisasiYtd: '100.84%',
            },
            {
              name: 'TB. ETI 311',
              projectName: 'AI Project - Transhipment',
              budget: '1.584.000.000',
              ytdBudget: '1.056.000.000',
              ytdReal: '1.065.000.000s',
              budgetAug: '132.000.000',
              budgetAugReal: '132.000.000',
              percentAug: '97.87%',
              realisasiYtd: '100.84%',
            },
            {
              name: 'TB. ETI 311',
              projectName: 'AI Project - Transhipment',
              budget: '1.584.000.000',
              ytdBudget: '1.056.000.000',
              ytdReal: '1.065.000.000s',
              budgetAug: '132.000.000',
              budgetAugReal: '132.000.000',
              percentAug: '97.87%',
              realisasiYtd: '100.84%',
            },
          ],
        },
      ],
      columns: [
        {
          name: 'Company',
          text: 'text-left',
          colspan: '2',
          width: '20%',
        },
        {
          name: 'Budget 2024',
          text: 'text-right',
          width: '15%',
        },
        {
          name: 'YTD Budget',
          subtitle: 'January - August',
          text: 'text-right',
        },
        {
          name: 'YTD Realization',
          subtitle: 'January - August',
          text: 'text-right',
        },
        {
          name: 'Budget Aug 2024',
          text: 'text-right',
        },
        {
          name: 'Realization Aug 2024',
          text: 'text-right',
        },
        {
          name: '% Realization Aug 2024',
          text: 'text-right',
          subtitle: '(Budget Vs Realization)',
        },
        {
          name: '% Realisasi YTD',
          text: 'text-left',
          subtitle: '(Budget Vs Realization)',
        },
        {
          name: 'Remarks Realisasi',
          text: 'text-left',
        },
      ],
      subColumn: [
        {
          name: 'Vessel Name',
          text: 'text-left',
        },
        {
          name: 'Project',
          text: 'text-left',
        },
        {
          name: 'Total Budget 2024',
          text: 'text-left',
          width: '15%',
        },
        {
          name: 'YTD Budget',
          text: 'text-left',
          subtitle: 'January - August',
        },
        {
          name: 'YTD Realization',
          text: 'text-left',
          subtitle: 'January - August',
        },
        {
          name: 'Budget Aug 2024',
          text: 'text-left',
        },
        {
          name: 'Realization Aug 2024',
          text: 'text-left',
        },
        {
          name: '% Realization Aug 2024',
          text: 'text-left',
        },
        {
          name: '% Realization YTD Aug 2024',
          text: 'text-left',
        },
        {
          name: 'actions',
          text: 'text-center',
        },
      ],
      show: {
        detailExpanse: false,
      },
    }
  },
}
</script>

<style scoped>
table th {
  background: #012061;
  color: white;
  padding: 4px 12px;
  vertical-align: middle !important;
}

.animation-rotate {
  animation: all;
  transition-duration: 300ms;
}

.rotate-90 {
  rotate: 90deg;
}
.rotate-0 {
  rotate: 0;
}
</style>
