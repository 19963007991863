<template>
    <b-row>
      <b-col md="12">
        <div class="d-flex align-items-center justify-content-end w-100">
          <b-button
              variant="danger"
              type="button"
              class="mr-2"
              @click="closeSlide"
            >
            <div>
              <i class="fa fa-ban"></i>
              <span>Cancel</span>
            </div>
            </b-button>
        </div>
        <hr>
      </b-col>
      
      <b-col md="12">
        <fieldset class="form-group border p-3">
          <legend class="w-auto px-2 text-primary">Item Information</legend>
          <b-row>
            <b-col md="4" >
              <b-form-group label="Code Item" label-for="code-item">
                <strong class="text-muted">{{detailData?.code ?? '-'}}</strong>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Name Item" label-for="name">
                <strong class="text-muted">{{detailData?.name ?? '-'}}</strong>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Spesification" label-for="to_vehicle_id">
                <strong class="text-muted">{{detailData?.spesification?.name ?? '-'}}</strong>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Unit" label-for="to_vehicle_id">
                <strong class="text-muted">{{detailData?.unit?.name ?? '-'}}</strong>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Unit Price" label-for="to_vehicle_id">
                <strong class="text-muted">{{ formatCurrency(detailData?.unit_price) }} <small class="text-black">({{ detailData?.unit_price_tax_inc ? 'Included Tax' : 'Excluded Tax' }})</small></strong>
              </b-form-group>
            </b-col> 
            <b-col md="4">
              <b-form-group label="Photo & File" label-for="to_vehicle_id">
                <strong class="text-muted">-</strong>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="QC Inspection Method" label-for="to_vehicle_id">
                <strong class="text-muted text-capitalize">{{ detailData?.qc_method_check }}</strong>
              </b-form-group>
            </b-col> 
            <b-col md="4" v-if="detailData?.qc_method_check === 'sampling'">
              <b-form-group label="QC Inspection Method Value" label-for="to_vehicle_id">
                <strong class="text-muted text-capitalize">{{ detailData?.qc_method_check_value_sample }}</strong>
              </b-form-group>
            </b-col> 
          </b-row>
        </fieldset>
      </b-col>

      <b-col md="12">
        <fieldset class="form-group border p-3">
          <legend class="w-auto px-2 text-primary">Quantity</legend>
          <b-row>
            <b-col md="4" >
              <b-form-group label="Safe Stock Qty" label-for="code-item">
                <strong class="text-muted">{{detailData?.safe_stock_qty ?? '-'}}</strong>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Procurement Period" label-for="name">
                <strong class="text-muted">{{detailData?.procurement_period ?? '-'}}</strong>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Minimum Buy Qty" label-for="to_vehicle_id">
                <strong class="text-muted">{{detailData?.minimum_buy_qty ?? '-'}}</strong>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Brand" label-for="to_vehicle_id">
                <strong class="text-muted">{{detailData?.brand ?? '-'}}</strong>
              </b-form-group>
            </b-col>
          </b-row>
        </fieldset>
      </b-col>

      <b-col md="12">
        <fieldset class="form-group border p-3">
          <legend class="w-auto px-2 text-primary">Price</legend>
          <b-row>
            <b-col md="4">
              <b-form-group label="Buy Price" label-for="code-item">
                <strong class="text-muted">{{ formatCurrency(detailData?.purchase_price) ?? '0'}} <small class="text-black">({{ detailData?.purchase_price_tax_inc ? 'Included Tax' : 'Excluded Tax' }})</small></strong>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Sell Price" label-for="code-item">
                <strong class="text-muted">{{ formatCurrency(detailData?.sell_price) ?? '0'}} <small class="text-black">({{ detailData?.sell_price_tax_inc ? 'Included Tax' : 'Excluded Tax' }})</small> </strong>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Event Price" label-for="code-item">
                <strong class="text-muted">{{ formatCurrency(detailData?.event_price) ?? '0' }} <small class="text-black">({{ detailData?.unit_price_tax_inc ? 'Included Tax' : 'Excluded Tax' }})</small></strong>
              </b-form-group>
            </b-col>
          </b-row>
        </fieldset>
      </b-col>

      <b-col md="12">
        <fieldset class="form-group border p-3">
          <legend class="w-auto px-2 text-primary">Item</legend>
          <b-row>
            <b-col md="4">
              <b-form-group label="Event Price" label-for="code-item">
                <strong class="text-muted">{{ formatCurrency(detailData?.o_e_price) ?? '0'}} <small class="text-black">({{ detailData?.o_e_price_tax_inc ? 'Included Tax' : 'Excluded Tax' }})</small></strong>
              </b-form-group>
            </b-col>
          </b-row>
        </fieldset>
      </b-col>
    </b-row>
</template>

<script>
import { busdevActions } from '@src/Utils/helper'
import _ from 'lodash'

export default {
  name: 'DetailStock',
  props:{
    detailData:{
      type:Object,
      default: () => {}
    },
    closeSlide:{
      type:Function,
      default: () => {}
    },
  },
  data(){
    return {
    }
  },
  components:{
  },

  async mounted () {},

  methods: {
    ...busdevActions,
    formatCurrency(value) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      }).format(value)
    },
  }
}
</script>
