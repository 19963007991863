<template>
 <b-container fluid>
  <b-row>
    <b-col lg="12" class="text-center align-content-center">
      <div class="w-100 align-items-center justify-content-center d-flex" style="height: 100vh">
        <div class="w-100" style="max-width:700px">
          <iq-card>
            <div class="p-4 " v-if="!loading && !approvalDone">
              <span>Approval <br /><h3><strong class="text-muted">{{ detailProjectContract?.project_sub?.project?.project_name }} - {{ detailProjectContract?.project_sub?.name  }}</strong></h3></span>
              <h5 class="mb-4 text-left mt-2">Dear, <strong>{{ encryptedData?.userName }}</strong></h5>
              <!-- CONTENT TABLE -->
              <div class="d-flex w-100">
                <table class="table table-striped">
                  <tbody>
                    <tr>
                      <th class="text-left">{{ detailProjectContract?.fleet_type?.name }}</th>
                      <th style="width:5%">:</th>
                      <td class="text-left text-truncate">
                        <div class="d-flex flex-column">
                          <b-badge v-for="(state, index) in detailProjectContract?.fleets" class="border border-primary text-primary text-uppercase mb-2" variant="none">{{ state.vehicle_name }}</b-badge>
                        </div>
                      </td>
                      <th class="text-left text-truncate">Laycan</th>
                      <th style="width:5%">:</th>
                      <td  class="text-left">{{ detailProjectContract?.laycan ? moment(detailProjectContract?.laycan).format('DD MMMM YYYY') : "-" }}</td>
                    </tr>
                    <tr>
                      <th class="text-left text-truncate">Periode Start</th>
                      <th style="width:5%">:</th>
                      <td  class="text-left">{{ detailProjectContract?.periode_start ? moment(detailProjectContract?.periode_start).format('DD MMMM YYYY') : "-" }}</td>
                      <th class="text-left text-truncate">Periode End</th>
                      <th style="width:5%">:</th>
                      <td  class="text-left">{{ detailProjectContract?.periode_end ? moment(detailProjectContract?.periode_end).format('DD MMMM YYYY') : "-" }}</td>
                    </tr>
                    <tr>
                      <th  class="text-left text-truncate">POL</th>
                      <th style="width:5%">:</th>
                      <td class="text-left">
                        <span v-for="(state, index) in detailProjectContract?.pol" :key="index">{{ state?.value }}, </span>
                      </td>
                      <th class="text-left">POD</th>
                      <th style="width:5%">:</th>
                      <td class="text-left">
                        <span v-for="(state, index) in detailProjectContract?.pod" :key="index">{{ state?.value }}, </span>
                      </td>
                    </tr>
                    <tr>
                      <th class="text-left text-truncate">Distance</th>
                      <th style="width:5%">:</th>
                      <td class="text-left">{{ detailProjectContract?.distance?.toLocaleString() }}</td>
                      <th class="text-left">Duration</th>
                      <th style="width:5%">:</th>
                      <td class="text-left">{{ detailProjectContract?.duration?.toLocaleString() }}</td>
                    </tr>
                    <tr>
                      <th class="text-left text-truncate">Cargo Type</th>
                      <th style="width:5%">:</th>
                      <td class="text-left">{{ detailProjectContract?.cargo_type?.name }}</td>
                      <th class="text-left">Contract Type</th>
                      <th style="width:5%">:</th>
                      <td class="text-left">{{ detailProjectContract?.contract_type?.name }}</td>
                    </tr>
                    <tr>
                      <th class="text-left text-truncate ">Freigh Rate (Est)</th>
                      <th style="width:5%">:</th>
                      <td class="text-left">{{ formatCurrency(detailProjectContract?.freight_rate_est) }} <br /> <small><strong class="text-muted">{{ detailProjectContract?.ppn == 'include' ? 'Include PPN' : 'Exclude PPN' }}</strong></small></td>
                      <th class="text-left text-truncate">Freigh Rate</th>
                      <th style="width:5%">:</th>
                      <td class="text-left">{{ formatCurrency(detailProjectContract?.freight_rate) }}</td>
                    </tr>
                    <tr>
                      <th class="text-left">Terms</th>
                      <th style="width:5%">:</th>
                      <td class="text-left">{{ detailProjectContract?.terms?.name }} <br /> <small class="text-muted">{{ detailProjectContract?.terms_remarks ?? '-' }}</small></td>
                      <th class="text-left text-truncate">Total Quantity</th>
                      <th style="width:5%">:</th>
                      <td class="text-left">{{ detailProjectContract?.total_quantity?.toLocaleString() }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- CONTENT TABLE END -->

              <!-- REMARKS -->
              <div class="w-100">
                <strong>Remarks</strong>
                <p class="text-center">{{ detailProjectContract?.remarks ?? '-' }}</p>
              </div>
              <!-- REMARKS END -->

              <div class="w-100 d-flex border" />
              <div class="d-inline-block w-100 mt-4">
                <button type="button" class="btn btn-warning mr-4" @click="onSaveApproval('rejected')"><i class="fa fa-ban mr-2" />  Reject</button>
                <button type="button" class="btn btn-primary " @click="onSaveApproval('approved')"> <i class="fa fa-check mr-2" /> Approve</button>
              </div>
            </div>
            <div class="p-4" v-if="approvalDone && !loading">
              <h3>Approval Success</h3>
              <h1 class="my-4"><i class="fa fa-check text-success" /></h1>
              <b-button variant="primary" @click="handleRouteToDashboard">Back to Dashboard</b-button>
            </div>
            <div v-if="!approvalDone && loading" class="p-4">
              <h4 class="text-primary">Please wait a moment ...</h4> <br />
              <i  class="spinner-border spinner-border-lg text-primary"></i>
            </div>
          </iq-card>
        </div>
      </div>
    </b-col>
  </b-row>
 </b-container>
</template>

<script>
import { busdevActions } from '@src/Utils/helper'
import moment from 'moment'
import CryptoJS from 'crypto-js';

export default {
  name: 'ConfirmationApprovalMail',
  data(){
    return {
      loading:false,
      detailProjectContract:null,

      approvalDone:false,
      encryptedData: null
    }
  },
  async mounted () {
    this.getDetailProjectContract(this.$route.query.id)
  },
  methods:{
    ...busdevActions,
    formatCurrency(value) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      }).format(value)
    },
    handleRouteToDashboard(){
      this.$router.push('/busdev/dashboard')
    },
    decryptData(data) {
      try {
        const key = 'agungtrii';
        const decryptedData = CryptoJS.AES.decrypt(data, key).toString(CryptoJS.enc.Utf8);

        if (!decryptedData.trim()) {
          throw new Error('Decrypted data is empty or invalid UTF-8 format.');
        }

        const jsonDataDecrypted = JSON.parse(decryptedData);

        return jsonDataDecrypted;
      } catch (error) {
        console.error('Error decrypting data:', error);
        return null; // or handle the error in a way that makes sense for your application
      }
    },
    // FETCHING DATA EDIT & DETAIL PLAN
    async getDetailProjectContract(id){
      this.loading = true
      // Assuming you have the encryptedValue from the URL query parameter
      const urlParams = new URLSearchParams(window.location.search);
      const encryptedValue = urlParams.get('value');
      let encryptedQuery = this.decryptData(encryptedValue)
      this.encryptedData = encryptedQuery

      let res = await this.fetchDetailProjectContractPublic({id: Number(encryptedQuery.id)})

      if (res.data.status === true) {
        this.detailProjectContract = res.data.data
      } else {
        this.detailProjectContract = null
      }
      this.loading = false
    },
    async onSaveApproval(status_approval){
      this.approvalDone = false
      let {id, userId, userName} = this.encryptedData

      let payload = {
        id: Number(userId),
        status_approval: status_approval,
        name: userName,
        remarks: status_approval == 'approved' ? 'Project Approved.' : 'Project Rejected.' ,
      }   

      if(Number(id)){
        this.$swal({
            title: `Are you sure want to ${status_approval}?`,
            text: "Yes, i'm sure.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `Yes, ${status_approval} it!`
        }).then(async (result) => {
          if (result.isConfirmed) {
            const res = await this.saveApprovalUserPublic({data: payload, id: Number(id)})

            if(res.data.status === true){
              this.approvalDone = true
              this.$swal(
                'Success!',
                'Success ' + status_approval,
                'success'
              )
            } else {
              this.$swal(
                'Oops!',
                res?.data?.message,
                'error'
              )
            }
          }
        })
      }

      this.approval_loading = false
    },
  }
}
</script>