<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">
              <strong>VENDOR</strong>
            </h4>
          </template>
          <template v-slot:headerAction>
            <div class="d-flex align-items-center gap-4 float-right">
              <a class="text-primary mr-4" role="button" style="cursor: pointer" @click="openVendorMaster">
                <i class="fa fa-home mr-2" />
                <span>Masters Vendor</span>
              </a>
              <a class="text-primary mr-4" role="button" style="cursor: pointer" @click="onExportVendor">
                <i class="fa fa-file-excel mr-2" />
                <span>Export Data</span>
              </a>
              <a class="text-primary mr-4" role="button" style="cursor: pointer" @click="addVendor" v-if="addPermission">
                <i class="fa fa-plus mr-2" />
                <span>Create Data</span>
              </a>
            </div>
          </template>
          <template v-slot:body>
            <!-- FILTERING VENDOR -->
            <b-row class="mb-4">
              <b-col md="3">
                <b-form-group label="Search" label-for="search">
                  <b-form-input
                    type="text"
                    placeholder="Search ID/Name..."
                    name="search"
                    v-model="params.search"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Vendor Class" label-for="search">
                  <b-form-select plain  size="sm" :options="optionsVendorClass"
                    name="vendor_class_id"
                    v-model="params.vendor_class_id"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="''">All</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Vendor Status" label-for="search">
                  <b-form-select plain  size="sm"
                    name="vendor_status"
                    v-model="params.vendor_status"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="''">All</b-form-select-option>
                      <b-form-select-option :value="'active'">Active</b-form-select-option>
                      <b-form-select-option :value="'on-hold'">On Hold</b-form-select-option>
                      <b-form-select-option :value="'credit-hold'">Credit Hold</b-form-select-option>
                      <b-form-select-option :value="'one-time'">One-Time</b-form-select-option>
                      <b-form-select-option :value="'inactive'">Inactive</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="2" />
              <b-col md="1">
                <b-form-group label="Filter" label-for="button_search">
                  <b-button
                    variant="primary"
                    @click="getListVendor"
                    type="button"
                    ><i class="fa fa-filter"></i
                  ></b-button>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- TABLE CONTENT -->
            <b-row>
              <b-col md="12">
                <div v-if="loading" class="alert alert-info">Loading...</div>
                <iq-card> 
                  <div class="overflow-auto d-flex">
                    <table class="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th class="text-center align-middle">No</th>
                          <th class="text-center text-truncate">Vendor ID</th>
                          <th class="text-center text-truncate">Vendor Name</th>
                          <th class="text-center text-truncate">Vendor Class</th>
                          <th class="text-center text-truncate">Country</th>
                          <th class="text-center text-truncate">City</th>
                          <th class="text-center text-truncate">Currency ID</th>
                          <th class="text-center text-truncate">Terms</th>
                          <th class="text-center text-truncate">Vendor Status</th>
                          <th class="text-center text-truncate">Address</th>
                          <th class="text-center align-middle" style="width: 20px">#</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(vendor, vendorIndex) in listVendor.data" :key="vendorIndex">
                          <td>{{ vendorIndex + 1 }}</td>
                          <td class="text-truncate">{{ vendor.vendor_id }}</td>
                          <td class="text-truncate">{{ vendor.name }}</td>
                          <td class="text-truncate">{{ vendor.vendor_class?.name ?? '-' }}</td>
                          <td class="text-truncate">{{ vendor.country_id ?? '-' }}</td>
                          <td class="text-truncate" style="max-width:150px" v-b-tooltip.top="vendor?.city">{{ vendor?.city }}</td>
                          <td class="text-truncate">{{ vendor.currency?.alias }}</td>
                          <td class="text-truncate">{{ vendor.terms?.code ?? '-'}}</td>
                          <td class="text-truncate">{{ vendor.vendor_status }}</td>
                          <td class="text-truncate" style="max-width:250px" v-b-tooltip.top="vendor?.address">{{ vendor?.address }}</td>
                          <td v-if="updatePermission || deletePermission">
                            <div class="d-flex align-items-center">
                              <i class="fa fa-pen text-primary mr-3" style="cursor: pointer;" @click="editVendor(vendor?.id)" />
                              <i class="fa fa-trash text-danger" style="cursor: pointer;" @click="onDeleteVendor(vendor)" />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="mt-3" v-if="listVendor?.data?.length > 0">
                    <b-pagination
                      v-model="params.page"
                      :total-rows="listVendor?.meta?.totalItem"
                      @change="getListVendor"
                      :per-page="listVendor?.meta?.perPage"
                      first-number
                      align="center"
                    ></b-pagination>
                  </div>
                  <p v-else class="text-center my-4">No vendor data found.</p>
                </iq-card>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>

      <b-col lg="12">
        <b-sidebar v-model="showAddVendor" id="add-vendor" :title="`${isEdit ? 'EDIT' : 'ADD'} VENDOR`"  width="100%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
          <template #default="{hide}">
              <b-row class="m-3">
                <b-col cols="12">
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 text-primary">Vendors</legend>
                      <b-row class="mt-2">
                          <b-col md="12">
                            <FormVendor 
                              :is-edit="isEdit" 
                              :edit-id="editId" 
                              :optionsVendorClass="optionsVendorClass" 
                              :optionsVendorType="optionsVendorType" 
                              :refreshData="refreshData" 
                            />
                          </b-col>
                      </b-row>
                  </fieldset>
                </b-col>
              </b-row>
            </template>
        </b-sidebar>
      </b-col>

      <b-col lg="12">
        <b-sidebar v-model="showVendorMaster" id="vendor-master" :title="`VENDOR MASTERS`"  width="100%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
          <template #default="{hide}">
              <b-row class="m-3">
                <b-col cols="12">
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 text-primary">Vendor Masters</legend>
                      <b-row class="mt-2">
                          <b-col md="12">
                            <ContentVendorMasters :refreshMasterData="refreshMasterData"  />
                          </b-col>
                      </b-row>
                  </fieldset>
                </b-col>
              </b-row>
            </template>
        </b-sidebar>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
  import _ from 'lodash'
  import { createPopper } from '@popperjs/core'
  import moment from 'moment'
  import FormVendor from './FormVendor.vue'
  import ContentVendorMasters from './ContentVendorMasters.vue'
  import { busdevActions } from '@src/Utils/helper'

  export default {
    name: 'Vendor',
    data() {
      return {
        exporting:false,
        loading:false,
        showAddVendor:false,
        showVendorMaster:false,

        optionsVendorClass:[],
        optionsVendorType:[],

        isEdit: false,
        editId: null,

        params:{
          page: 1,
          perPage:25,
          vendor_class_id:'',
          search:'',
          vendor_status:'',
        },

        listVendor: {
          data:[],
          meta:null
        },
      }
    },
    components:{
      FormVendor,
      ContentVendorMasters,
    },
    watch: {},
    computed: {
      addPermission(){
        let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
          .find(obj => obj.menu === 'Apps').child
          .find(obj => obj.menu === 'Business Development').child
          .find(obj => obj.menu === 'Busdev Vendor').add

        return hasAccess
      },
      updatePermission(){
        let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
          .find(obj => obj.menu === 'Apps').child
          .find(obj => obj.menu === 'Business Development').child
          .find(obj => obj.menu === 'Busdev Vendor').update

        return hasAccess
      },
      deletePermission(){
        let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
          .find(obj => obj.menu === 'Apps').child
          .find(obj => obj.menu === 'Business Development').child
          .find(obj => obj.menu === 'Busdev Vendor').delete

        return hasAccess
      },
    },
    async mounted() {
      this.getVendorClass()
      this.getVendorType()
      this.getListVendor()
    },
    created () {
      // this.getListVendor = _.debounce(this.getListVendor, 500)
    },
    methods: {
      ...busdevActions,
      refreshData(){
        this.getListVendor()
      },
      refreshMasterData(){
        this.getVendorClass()
        this.getVendorType()
      },
      openVendorMaster(){
        this.showVendorMaster = true
        this.$root.$emit('bv::toggle::collapse', `vendor-master`)
      }, 
      addVendor(){
        this.isEdit = false
        this.editId = null

        this.showAddVendor = true
        this.$root.$emit('bv::toggle::collapse', `add-vendor`)
      }, 
      editVendor(id){
        this.isEdit = true
        this.editId = id

        this.showAddVendor = true
        this.$root.$emit('bv::toggle::collapse', `add-vendor`)
      },
      async getVendorType() {
        this.optionsVendorType = []
        let res = await this.fetchVendorType()
        if (res.data.status === true) {
          if (res.data.data && res.data.data.length > 0) {
            res.data.data.map((state) => {
              this.optionsVendorType.push({
                value: state.id,
                text: state.name,
              })
            })
          }
        }
      },
      async getVendorClass() {
        this.optionsVendorClass = []
        let res = await this.fetchVendorClass()
        if (res.data.status === true) {
          if (res.data.data && res.data.data.length > 0) {
            res.data.data.map((state) => {
              this.optionsVendorClass.push({
                value: state.id,
                text: state.name,
              })
            })
          }
        }
      },
      async getListVendor(page) {
        let params = {
          page: page || 1,
          ...this.params,
        }
        if(!params.vendor_class_id) delete params.vendor_class_id
        if(!params.search) delete params.search
        if(!params.vendor_status) delete params.vendor_status

        let res = await this.fetchVendor(params)

        if (res.data.status === true && res.data.data?.data.length > 0) {
          this.listVendor.data = res.data.data?.data
          this.listVendor.meta = res.data.data?.meta
        } else {
          this.listVendor.data = []
          this.listVendor.meta = null
        }
      }, 
      async onExportVendor() {
        this.exporting = true
        try {
          let params = {
            ...this.params,
          }

          if(!params.vendor_class_id) delete params.vendor_class_id
          if(!params.search) delete params.search
          if(!params.vendor_status) delete params.vendor_status

          let res = await this.exportVendor(params)

          if(res.status === 'success'){
            saveAs(res.data, 'List Vendors.xlsx')
          }
        } catch (error) {
          this.$swal(
            'Failed Export!',
            'Something went wrong.',
            'error'
          ) 
        }

        this.exporting = false
      },

      async onDeleteVendor(data){
        this.$swal({
            title: `Are you sure want to delete Vendor ID ${data?.vendor_id} ?`,
            text: "Yes, i'm sure.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
            const res = await this.deleteVendor({id:data.id})
            if(res.status === false || res.status === 400 || res.status === 500){
                this.$swal(
                    'Failed!',
                    'Something went wrong.',
                    'error'
                )
                return 
            } 
                await this.getListVendor()
                this.$swal(
                    'Success!',
                    'Success delete Vendor.',
                    'success'
                )
            }
        })
      },
    }
  }
</script>