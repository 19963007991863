<template>
  <form @submit="onSave"  action="#">
    <b-row>
      <b-col md="12">
        <div class="d-flex align-items-center justify-content-end w-100">
          <b-button
              variant="danger"
              type="button"
              class="mr-2"
              @click="closeSlide"
            >
            <div>
              <i class="fa fa-ban"></i>
              <span>Cancel</span>
            </div>
            </b-button>
  
          <b-button
              :disabled="saving"
              variant="primary"
              type="submit"
            >
              <span
                v-if="saving"
                class="spinner-border spinner-border-sm"
              />
              <div v-else>
                <i class="fa fa-save"></i>
                <span>Submit Data</span>
              </div>
            </b-button>
        </div>
        <hr>
      </b-col>
      <b-col md="12">
        <b-row v-if="['TRANSFER'].includes(type)">
          <b-col md="4" >
            <b-form-group label="*Date" label-for="date">
              <b-form-input
                id="date"
                type="date"
                v-model="form.date"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="*From Vehicle" label-for="from_vehicle_id">
              <v-select
                label="name"
                :options="optionsFleets"
                :reduce="(type) => type.id"
                v-model="form.from_vehicle_id"
                placeholder="Select..."
                disabled
              ></v-select>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="*To Vehicle" label-for="to_vehicle_id">
              <v-select
                label="name"
                :options="optionsFleets"
                :reduce="(type) => type.id"
                v-model="form.to_vehicle_id"
                placeholder="Select..."
              ></v-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="['ADJUSTMENT'].includes(type)">
          <b-col md="6">
            <b-form-group label="*Vehicle" label-for="to_vehicle_id">
              <v-select
                label="name"
                :options="optionsFleets"
                :reduce="(type) => type.id"
                v-model="form.to_vehicle_id"
                placeholder="Select..."
                disabled
              ></v-select>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="*Date" label-for="date">
              <b-form-input
                id="date"
                type="date"
                v-model="form.date"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label="Proof Number" label-for="proof_number">
              <b-form-input
                placeholder="Type Text"
                id="text"
                type="text"
                v-model="form.proof_number"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Type" label-for="type"  v-if="['ADJUSTMENT'].includes(type)">
              <b-form-select
                required
                id="type"
                plain
                size="md"
                v-model="form.type"
              >
                <template v-slot:first>
                  <b-form-select-option :value="''">Select...</b-form-select-option>
                </template>
                <b-form-select-option :value="'stock_in'">Stock In</b-form-select-option>
                <b-form-select-option :value="'stock_out'">Stock Out</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
  
        <b-row>
          <b-col md="12">
            <div class="d-flex align-items-center justify-content-between w-100 mb-2">
              <label><strong>*Select Products</strong></label>
              <div class="d-flex align-items-center w-auto">
                <b-form-input v-model="search" placeholder="Search product..." />
                <i class="fa fa-search ml-2 text-primary text-lg" />
              </div>
            </div>
            <div class="d-flex overflow-auto" style="max-height:330px">
              <table class="table table-striped">
                <thead class='sticky-top bg-primary'>
                  <tr>
                    <th style="width:5px" class="text-center">#</th>
                    <th class="text-truncate" style="width:220px">Product Code</th>
                    <th style="min-width:250px" >Product</th>
                    <th class="text-truncate">Category</th>
                    <th class="text-truncate">Spesifikasi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(val, valIndex) in items" :key="valIndex">
                    <td class="text-center">
                      <b-form-checkbox
                        class="ml-2"
                        v-model="val.checked"
                        :value="true"
                        :unchecked-value="false"
                        @change="onHandleCheckedProduct(val)"
                      >
                      </b-form-checkbox>
                    </td>
                    <td style="width:220px">{{val?.code}}</td>
                    <td>{{val?.name}}</td>
                    <td>{{val?.category?.name}}</td>
                    <td>{{val?.spesification?.name}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="mt-3" v-if="items?.length > 0">
              <b-pagination
                v-model="page"
                :total-rows="meta?.totalItem"
                @change="getListItem"
                :per-page="meta?.perPage"
                first-number
                align="center"
              ></b-pagination>
            </div>
          </b-col>
        </b-row>
  
        <b-row>
          <b-col md="12">
            <label class="text-primary"><strong>Selected Products</strong></label>
            <div class="d-flex overflow-auto">
              <table class="table table-striped">
                  <thead>
                    <tr>
                      <th class="text-truncate">PRODUCT</th>
                      <th class="text-truncate">PRODUCT CODE</th>
                      <th class="text-truncate text-center" v-if="['ADD'].includes(action)">AVAILABLE STOCK</th>
                      <th class="text-truncate text-center" v-if="['EDIT'].includes(action)">BEGINNING STOCK</th>
                      <th class="text-truncate text-center" style="min-width:200px">QUANTITY</th>
                      <th class="text-truncate text-center" v-if="['ADD'].includes(action)">NEW STOCK</th>
                      <th class="text-truncate text-center" v-if="['EDIT'].includes(action)">UPDATE STOCK</th>
                      <th class="text-truncate" v-if="['ADJUSTMENT'].includes(type)">TYPE</th>
                      <th class="text-truncate">REMARKS</th>
                      <th v-if="['ADD', 'EDIT'].includes(action)" style="width:5px">#</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="selected_products.length > 0 && form.type">
                      <tr v-for="(state, index) in selected_products" :key="index">
                        <td>{{state?.name}}</td>
                        <td>{{state?.code}}</td>
                        <td class="text-center" v-if="['ADD'].includes(action)">{{state?.stock_available ?? 0}}</td>
                        <td class="text-center" v-if="['EDIT'].includes(action)">{{state?.beginning_stock ?? 0}}</td>
                        <td>
                          <b-form-input placeholder="0" v-model="state.qty" type="number" :max="form.type === 'stock_out' ? state?.stock_available : 1000000" class="text-center" />
                        </td>
                        <td class="text-center" v-if="['transfer_stock', 'stock_out'].includes(form.type)">{{ Number(state?.stock_available) - Number(state.qty) }}</td>
                        <td class="text-center" v-else>{{ Number(state?.stock_available) + Number(state.qty) }}</td>
                        <!-- <td class="text-center" v-if="['EDIT'].includes(action)">{{ state?.update_stock }}</td> -->
                        <td v-if="['ADJUSTMENT'].includes(type)">{{form.type === 'stock_in' ? "Stock In" : 'Stock Out'}}</td>
                        <td>
                          <b-form-textarea placeholder="Type Text" v-model="state.remarks" />
                        </td>
                        <td v-if="['ADD', 'EDIT'].includes(action)" style="width:5px">
                          <i v-if="state?.itemId" class="fa fa-trash text-danger" style="cursor: pointer;" @click="onRemove(state?.itemId, index, true)" />
                          <i v-else class="fa fa-ban text-danger" style="cursor: pointer;" @click="onRemove(state?.id, index, false)" />
                        </td>
                      </tr>
                    </template>
                  </tbody>
              </table>
            </div>
            <div class="d-flex w-100 justify-content-center" v-if="selected_products.length <= 0 || !form.type">
              <strong class="text-muted">Select Products & Type First.</strong>
            </div>
            <hr>
          </b-col>
        </b-row>
  
        <b-row>
          <b-col md="12">
            <b-form-group label="Notes" label-for="notes">
              <b-form-textarea
                placeholder="Type Text"
                id="text"
                type="text"
                v-model="form.notes"
                rows="4"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </form>
</template>

<script>
import { busdevActions } from '@src/Utils/helper'
import _ from 'lodash'
import moment from 'moment'

export default {
  name: 'FormStock',
  props:{
    type:{
      type:String,
      default: ''
    },
    action: {
      type: String,
      default: ''
    }, 
    editId: {
      type: Number,
      default: null
    }, 
    detailData:{
      type: Object,
      default: () => {}
    },
    closeSlide: {
      type: Function,
      default: () => {}
    }, 
    refreshData: {
      type: Function,
      default: () => {}
    }, 
    optionsFleets:{
      type: Array,
      default: () => []
    }, 
    vehicle_type_id:{
      type: Number,
      default: null
    },  
  },
  data(){
    return {
      saving: false,

      search:'',
      selected_products: [],

      form:{
        from_vehicle_id: null,
        to_vehicle_id: null,
        date: null,
        total_qty: null,
        proof_number: '',
        type: '',  //'stock_in','stock_out'
        notes: '',
        details: []
      },

      items:[],
      meta:{},
      page:1
    }
  },
  components:{
  },
  created(){
    this.getListItem = _.debounce(this.getListItem, 500)
  },
  watch:{
    'action': {
      handler(value) {
        if(value === 'ADD'){
          this.selected_products = []
          this.form = {
            from_vehicle_id: null,
            to_vehicle_id: null,
            date: null,
            total_qty: null,
            proof_number: '',
            type: '',  //'stock_in','stock_out'
            notes: '',
            details: []
          }
        }
      },
      immediate: true,
      deep:true
    },
    'type': {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (['TRANSFER'].includes(value) ) {
          this.form.from_vehicle_id = Number(this.$route.query.vehicleId)
          this.form.to_vehicle_id = null
          this.form.type = 'transfer_stock'
        } else {
          this.form.type = ''
          this.form.from_vehicle_id = null
          this.form.to_vehicle_id = Number(this.$route.query.vehicleId)
        }
      },
    },
    'editId': {
        handler(value) {
          if(value){
            this.getDetailStockAdjustment(value)
          } else {
            this.resetForm()
          }
        },
        immediate: true,
        deep:true
      }, 
    'vehicle_type_id': {
      handler(value) {
        if(value){
          this.getListItem()
        } 
      },
      immediate: true,
      deep:true
    },
    'search': {
      handler(value) {
        if(value){
          this.getListItem()
        } 
      },
      immediate: true,
      deep:true
    },
  },
  async mounted () {
    await this.getListItem()
  },

  methods: {
    ...busdevActions,
    resetForm(){
      this.form = {
        from_vehicle_id: null,
        to_vehicle_id: null,
        date: null,
        total_qty: null,
        proof_number: '',
        type: '',  //'stock_in','stock_out'
        notes: '',
        details: []
      }

      this.selected_products = []
    },
    async getDetailStockAdjustment(id){
      if(id){
        let tempArr = []
        const promises = this.detailData?.details?.map(async obj => {
          let params = {
            vehicle_id:Number(this.$route.query.vehicleId),
            item_id:Number(obj.item_id),
          }
          let stock = await this.checkStockAvailable(params)
          
          tempArr.push({
            id: obj.item_id ?? null,
            code:obj?.items?.code,
            name:obj?.items?.name,
            qty:obj.qty,
            remarks:obj.remarks,
            itemId:obj?.id ?? null,
            stock_available: stock?.data?.data?.stock_available ?? 0,
            beginning_stock: obj.beginning_stock ?? 0,
            update_stock: obj.update_stock ?? 0,
            checked:true,
            disabled:false
          })

          this.items = this.items?.map(item => {
            if(obj.item_id === item.id) item.checked = true

            return item
          })
        })

        await Promise.all(promises)

        this.selected_products = tempArr
  
        this.form = {
          from_vehicle_id: this.detailData?.from_vehicle_id,
          to_vehicle_id: this.detailData?.to_vehicle_id,
          date: this.detailData?.date ? moment(this.detailData?.date).format('YYYY-MM-DD') : null,
          total_qty: null,
          proof_number: this.detailData?.proof_number,
          type: this.detailData?.type,  //'stock_in','stock_out'
          notes:  this.detailData?.notes,
          details: []
        }
      }
    },

    onHandleCheckedProduct(item) {
      if (item.checked) {
        this.selected_products.push(item);
      } else {
        const index = this.selected_products.findIndex(selectedItem => selectedItem.id === item.id);
        if (index !== -1) {
          this.selected_products.splice(index, 1);
        }
      }
    },
    
    async onRemove (id, index, api) {
      if(!api){
        this.selected_products.splice(index, 1)
      } else {
        this.$swal({
          title: 'Delete Product?',
          text: `This Product will be deleted and cannot be recovered.`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Delete',
        }).then(async (result) => {
          if (result.isConfirmed) {
            let res = await this.deleteStockAdjustmentDetail({id})
            if (res.status != 'success') {
              if (res.data.message != null) {
                this.$swal('Error', res?.data?.message, 'error')
              } else {
                this.$swal(
                  'Error',
                  'Something wrong with the server. Cannot get user data.',
                  'error'
                )
              }
            } else {
              this.getListItem()
              this.refreshData()
              this.$swal(
                `Product deleted!`,
                `Product successfully deleted`,
                'success'
              )
            }
          }
        })
      }
    },

    async getListItem(page) {
      if(!Number(this.vehicle_type_id)) return

      let params = {
        page:page || 1,
        perPage:25,
        vehicle_id:Number(this.$route.query.vehicleId),
        vehicle_type_id: Number(this.vehicle_type_id),
        search: this.search
      }

      if(!params.search) delete params.search

      let res = await this.getListProducts(params)

      if (res.status === 'success' && res?.data?.data?.length > 0) {
        this.items = res.data?.data.map(obj => {
          let existedProduct = this.selected_products.find(old => old.id === obj.id)
          return {
            ...obj,
            qty: 0,
            checked:existedProduct ? true : false,
            disabled:false
          }
        })
        this.meta = res.data.meta
      } else {
        this.items = []
        this.meta = null
      }
    },

    async onSave(e) {
      e.preventDefault()

      if(this.selected_products.length <= 0) 
        return this.$swal(`Required.`, 'Select For Vehicle Type, at least one!', 'warning')

      this.saving = true

      let payload = {
        ...this.form,
        from_vehicle_id: ['ADJUSTMENT'].includes(this.type) ? null : this.form.from_vehicle_id,
        type: ['TRANSFER'].includes(this.type) ? 'transfer_stock' : this.form.type,
        total_qty: this.selected_products.reduce((acc, obj) => {
          return Number(acc) + Number(obj.qty)
        },0)
      }

      let validationQty = false
      // CLEANSING DATA
      let newDetails = []
      this.selected_products.map(obj => {
        if(['stock_out', 'transfer_stock'].includes(payload.type) && Number(obj.qty) > Number(obj.stock_available)){
          validationQty = true
        }

        newDetails.push({
          itemId:obj.itemId || null,
          "item_id": obj.id,
          "type": ['TRANSFER'].includes(this.type) ? null : this.form.type,
          "qty": Number(obj.qty),
          "beginning_stock": Number(obj.stock_available),
          "update_stock": payload.type === 'stock_in' ? Number(obj.stock_available) + Number(obj.qty) : 
                                                    Number(obj.stock_available) - Number(obj.qty),
          "remarks": obj.remarks
        })
      })

      if(validationQty){
        this.saving = false
        return this.$swal(`Validation.`, 'Quantity cant be more than Stock Available, please change it.', 'warning')
      }

      payload.details = newDetails

      let res = null
      if(this.editId){
        let newEditPayload = {...payload}
        delete newEditPayload.details

        res = await this.updateStockAdjustments({data: payload, id: Number(this.editId)})

        if(payload.details.length > 0){
          const promises = payload.details.map(async (val, valIndex) => {
            let payloadDetail = {
              data: {...val},
              id: val.itemId ? val.itemId : Number(this.editId)
            }

            if(val.itemId){
              delete payloadDetail.data.itemId
              await this.updateStockAdjustmentDetail(payloadDetail)
            } else {
              delete payloadDetail.data.itemId
              await this.createStockAdjustmentDetail(payloadDetail)
            }
          })

          await Promise.all(promises)
        }
      } else {
        res = await this.createStockAdjustments({data: payload})
      }

      if([500, 404, 403, 422].includes(res.status)){
        this.saving = false
        this.$swal(`Oops.`, res?.message ?? res?.data?.message ?? 'Something went wrong!', 'error')
        return
      }

      if(res?.data?.status === true){
        this.saving = false
        this.refreshData()
        this.resetForm()
        this.$swal(`Success!`, `Stock Item Saved.`, 'success')
        this.getListItem()
      } else {
        this.saving = false
        this.$swal(`Oops.`, res?.data?.message, 'error')
      }

      this.saving = false
    }
  }
}
</script>

<style>
.dropdown-menu {    
    z-index:9999;
}
</style>
