<template>
  <b-container fluid>
    <form  @submit="onSave"  action="#">
      <b-row>
        <b-col md="12" v-if="!previewData">
          <div class="d-flex align-items-center justify-content-end w-100">
            <b-button
                :disabled="saving"
                block
                variant="primary"
                type="submit"
                style="width:15%"
              >
                <span
                  v-if="saving"
                  class="spinner-border spinner-border-sm"
                />
                <div v-else>
                  <i class="fa fa-save"></i>
                  <span>Submit Data</span>
                </div>
              </b-button>
          </div>
        </b-col>
        <b-col md="12" v-else>
          <div class="d-flex align-items-center justify-content-end w-100">
            <b-button
              block
              variant="primary"
              type="button"
              style="width:15%"
              @click="$nextTick(() => $bvModal.hide('new-customer'))"
            >
              <div>
                <i class="fa fa-check"></i>
                <span>Select Customer</span>
              </div>
            </b-button>
          </div>
        </b-col>
        <b-col lg="12" class="mb-4">
          <b-row>
            <b-col md="3">
              <b-form-group label="* Customer Code" label-for="customer_code">
                <span v-if="previewData" class="text-capitalize">{{ formCustomer.customer_code }}</span>
                <b-form-input
                  v-else
                  id="customer_code"
                  type="text"
                  placeholder="Type text"
                  v-model="formCustomer.customer_code"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="* Customer Status" label-for="customer_status">
                <span v-if="previewData" class="text-capitalize">{{ formCustomer.customer_status }}</span>
                <b-form-select
                  v-else
                  id="customer_status"
                  plain
                  size="md"
                  v-model="formCustomer.customer_status"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="''">Select Customer Status...</b-form-select-option>
                    <b-form-select-option :value="'active'">Active</b-form-select-option>
                    <b-form-select-option :value="'on-hold'">On Hold</b-form-select-option>
                    <b-form-select-option :value="'credit-hold'">Credit Hold</b-form-select-option>
                    <b-form-select-option :value="'one-time'">One-Time</b-form-select-option>
                    <b-form-select-option :value="'inactive'">Inactive</b-form-select-option>
                    <b-form-select-option :value="'not-recommended'">Not Recommended</b-form-select-option>
                    <b-form-select-option :value="'blacklist'">Blacklist</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <b-form-group label="* Customer Class" label-for="customer_class_id">
                <span v-if="previewData" class="text-capitalize">{{ customerClassName(formCustomer.customer_class_id) }}</span>
                <b-form-select
                  v-else
                  id="customer_class_id"
                  plain
                  size="md"
                  v-model="formCustomer.customer_class_id"
                  :options="optionsCustomerClass"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null">Select Customer Class...</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="12" >
          <iq-card body-class="p-0">
            <template v-slot:body>
              <div class="iq-edit-list">
                <tab-nav :pills="true" class="nav-fill mb-3">
                  <tab-nav-items class="col-md-2 p-0" :active="true" @click.native="tab = 'GENERAL'" href="#general" title="General"/>
                  <tab-nav-items class="col-md-2 p-0" :active="false" @click.native="tab = 'BILLING'" href="#billing" title="Billing"/>
                  <tab-nav-items class="col-md-2 p-0" :active="false" @click.native="tab = 'SHIPPING'" href="#shipping" title="Shipping"/>
                  <tab-nav-items class="col-md-2 p-0" :active="false" @click.native="tab = 'CONTACT'" href="#contact" title="Contact"/>
                  <tab-nav-items class="col-md-2 p-0" :active="false" @click.native="tab = 'CUSTOMER_FILE'" href="#customer-file" title="Customer File"/>
                </tab-nav>
              </div>
            </template>
          </iq-card>
        </b-col>
        <b-col lg="12">
          <div class="tab-content">
            <!-- General -->
            <tab-content-item :active="true" id="general">
              <div class="row">
                <b-col sm="6">
                  <fieldset class="form-group border p-3 mb-2">
                    <legend class="w-auto px-2 text-primary">Account Info</legend>
                    <b-row class="mt-2">
                      <b-col md="12">
                        <b-form-group label="* Account Name" label-for="customer_name">
                          <span v-if="previewData" class="text-capitalize">{{ formCustomer.account_name }}</span>
                          <b-form-input
                            v-else
                            id="account_name"
                            type="text"
                            placeholder="Type text"
                            v-model="formCustomer.account_name"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </fieldset>
                  <fieldset class="form-group border p-3 mb-2">
                    <legend class="w-auto px-2 text-primary">Account Address</legend>
                    <b-row class="mt-2">
                      <!-- <b-col md="12">
                        <b-button>VIEW ON MAP</b-button>
                      </b-col> -->
                      <b-col md="12">
                        <b-form-group label="* Country" label-for="customer_country_id">
                          <span v-if="previewData" class="text-capitalize">{{ formCustomer.customer_country_id }}</span>
                          <v-select
                            label="text"
                            :options="optionsCountry"
                            :reduce="(type) => type.value"
                            v-model="formCustomer.customer_country_id"
                            placeholder="Select country..."
                          ></v-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Province" label-for="customer_province">
                          <span v-if="previewData" class="text-capitalize">{{ formCustomer.customer_province }}</span>
                          <b-form-input
                            v-else
                            id="customer_province"
                            type="text"
                            placeholder="Type text"
                            v-model="formCustomer.customer_province"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="City" label-for="customer_city">
                          <span v-if="previewData" class="text-capitalize">{{ formCustomer.customer_city }}</span>
                          <b-form-input v-else
                            id="customer_city"
                            type="text"
                            placeholder="Type text"
                            v-model="formCustomer.customer_city"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="District" label-for="customer_district">
                          <span v-if="previewData" class="text-capitalize">{{ formCustomer.customer_district }}</span>
                          <b-form-input
                            v-else
                            id="customer_district"
                            type="text"
                            placeholder="Type text"
                            v-model="formCustomer.customer_district"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Village" label-for="customer_village">
                          <span v-if="previewData" class="text-capitalize">{{ formCustomer.customer_village }}</span>
                          <b-form-input
                            v-else
                            id="customer_village"
                            type="text"
                            placeholder="Type text"
                            v-model="formCustomer.customer_village"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Postal Code" label-for="customer_postal_code">
                          <span v-if="previewData" class="text-capitalize">{{ formCustomer.customer_postal_code }}</span>
                          <b-form-input
                            v-else
                            id="customer_postal_code"
                            type="number"
                            placeholder="Type text"
                            v-model="formCustomer.customer_postal_code"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Address" label-for="customer_address">
                          <span v-if="previewData" class="text-capitalize">{{ formCustomer.customer_address }}</span>
                          <b-form-input
                            v-else
                            id="customer_address"
                            type="textarea"
                            placeholder="Type text"
                            v-model="formCustomer.customer_address"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </fieldset>
                  <fieldset class="form-group border p-3 mb-2">
                    <legend class="w-auto px-2 text-primary">Additional Account Info</legend>
                    <b-row class="mt-2">
                      <b-col md="12">
                        <b-form-group label="Logo" label-for="logo">
                          <img v-if="previewData" :src="formCustomer?.logo" alt="logo" style="width: 70px; height: 70px;" />
                          <b-form-file
                            v-else 
                            id="file"
                            v-model="formCustomer.logo"
                            accept="image/*"
                          ></b-form-file>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Web" label-for="customer_web">
                          <span v-if="previewData" class="text-capitalize">{{ formCustomer.customer_web }}</span>
                          <b-form-input
                            v-else 
                            id="customer_web"
                            type="text"
                            placeholder="Type text"
                            v-model="formCustomer.customer_web"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </fieldset>
                </b-col>
                <b-col sm="6">
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 text-primary">Primary Contact</legend>
                    <b-row class="mt-2">
                      <b-col md="12">
                        <b-form-group label="Fullname" label-for="customer_name">
                          <span v-if="previewData" class="text-capitalize">{{ formCustomer.customer_name }}</span>
                          <b-form-input
                            v-else
                            id="customer_name"
                            type="text"
                            placeholder="Type text"
                            v-model="formCustomer.customer_name"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <!-- <b-col md="12">
                        <b-form-group label="Job Title" label-for="job_title">
                          <b-form-input
                            id="job_title"
                            type="text"
                            placeholder="Type text"
                            v-model="formCustomer.customer_name"
                          ></b-form-input>
                        </b-form-group>
                      </b-col> -->
                      <b-col md="12">
                        <b-form-group label="Email" label-for="customer_email">
                          <span v-if="previewData" class="text-capitalize">{{ formCustomer.customer_email }}</span>
                          <b-form-input
                            v-else
                            id="customer_email"
                            type="email"
                            placeholder="Type text"
                            v-model="formCustomer.customer_email"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Phone 1" label-for="customer_phone1">
                          <span v-if="previewData" class="text-capitalize">{{ formCustomer.customer_phone1 }}</span>
                          <b-form-input
                            v-else
                            id="customer_phone1"
                            type="text"
                            placeholder="Type text"
                            v-model="formCustomer.customer_phone1"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Phone 2" label-for="customer_phone2">
                          <span v-if="previewData" class="text-capitalize">{{ formCustomer.customer_phone2 }}</span>
                          <b-form-input
                            id="customer_phone2"
                            type="text"
                            placeholder="Type text"
                            v-model="formCustomer.customer_phone2"
                            v-else
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Mobile 1" label-for="customer_mobile1">
                          <span v-if="previewData" class="text-capitalize">{{ formCustomer.customer_mobile1 }}</span>
                          <b-form-input
                            v-else
                            id="customer_mobile1"
                            type="text"
                            placeholder="Type text"
                            v-model="formCustomer.customer_mobile1"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Mobile 2" label-for="customer_mobile2">
                          <span v-if="previewData" class="text-capitalize">{{ formCustomer.customer_mobile2 }}</span>
                          <b-form-input
                            v-else
                            id="customer_mobile2"
                            type="text"
                            placeholder="Type text"
                            v-model="formCustomer.customer_mobile2"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Assistant" label-for="customer_assistant">
                          <span v-if="previewData" class="text-capitalize">{{ formCustomer.customer_assistant }}</span>
                          <b-form-input
                            id="customer_assistant"
                            type="text"
                            placeholder="Type text"
                            v-model="formCustomer.customer_assistant"
                            v-else
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Fax" label-for="customer_fax">
                          <span v-if="previewData" class="text-capitalize">{{ formCustomer.customer_fax }}</span>
                          <b-form-input
                            v-else
                            id="customer_fax"
                            type="text"
                            placeholder="Type text"
                            v-model="formCustomer.customer_fax"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </fieldset>
                </b-col>
              </div>
            </tab-content-item>
            <!-- End General -->
            <!-- Billing -->
            <tab-content-item :active="false" id="billing">
              <div class="row">
                <b-col sm="6">
                  <fieldset class="form-group border p-3 mb-2">
                    <legend class="w-auto px-2 text-primary">Bill-to Address</legend>
                    <b-row class="mt-2">
                      <b-col md="12">
                        <b-form-group>
                          <span v-if="previewData" class="text-capitalize">{{ formBilling.billing_from_general ? "From General" : "Not From General" }}</span>
                          <b-form-checkbox
                            v-else
                            name="check-button"
                            switch
                            inline
                            v-model="formBilling.billing_from_general"
                          >
                            From General
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="* Country" label-for="billing_country_id">
                          <span v-if="previewData" class="text-capitalize">{{ formBilling.billing_country_id }}</span>
                          <v-select
                            label="text"
                            :options="optionsCountry"
                            :reduce="(type) => type.value"
                            v-model="formBilling.billing_country_id"
                            placeholder="Select country..."
                          ></v-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Province" label-for="billing_province">
                          <span v-if="previewData" class="text-capitalize">{{ formBilling.billing_province }}</span>
                          <b-form-input
                            v-else
                            id="billing_province"
                            type="text"
                            placeholder="Type text"
                            v-model="formBilling.billing_province"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="City" label-for="billing_city">
                          <span v-if="previewData" class="text-capitalize">{{ formBilling.billing_city }}</span>
                          <b-form-input
                            v-else
                            id="billing_city"
                            type="text"
                            placeholder="Type text"
                            v-model="formBilling.billing_city"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="District" label-for="billing_district">
                          <span v-if="previewData" class="text-capitalize">{{ formBilling.billing_district }}</span>
                          <b-form-input
                            v-else
                            id="billing_district"
                            type="text"
                            placeholder="Type text"
                            v-model="formBilling.billing_district"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Village" label-for="billing_village">
                          <span v-if="previewData" class="text-capitalize">{{ formBilling.billing_village }}</span>
                          <b-form-input
                            v-else
                            id="billing_village"
                            type="text"
                            placeholder="Type text"
                            v-model="formBilling.billing_village"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Postal Code" label-for="billing_postal_code">
                          <span v-if="previewData" class="text-capitalize">{{ formBilling.billing_postal_code }}</span>
                          <b-form-input
                            v-else
                            id="billing_postal_code"
                            type="number"
                            placeholder="Type text"
                            v-model="formBilling.billing_postal_code"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Address" label-for="billing_address">
                          <span v-if="previewData" class="text-capitalize">{{ formBilling.billing_address }}</span>
                          <b-form-input
                            v-else
                            id="billing_address"
                            type="textarea"
                            placeholder="Type text"
                            v-model="formBilling.billing_address"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </fieldset>
                  <fieldset class="form-group border p-3 mb-2">
                    <legend class="w-auto px-2 text-primary">Bill-to Info</legend>
                    <b-row class="mt-2">
                      <b-col md="12">
                        <b-form-group label="Account Name" label-for="billing_account_name">
                          <span v-if="previewData" class="text-capitalize">{{ formBilling?.billing_account_name ?? '-' }}</span>
                          <b-form-input
                            v-else
                            id="billing_account_name"
                            type="text"
                            placeholder="Type text"
                            v-model="formBilling.billing_account_name"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Attention" label-for="billing_attention">
                          <span v-if="previewData" class="text-capitalize">{{ formBilling?.billing_attention ?? '-' }}</span>
                          <b-form-input
                            v-else
                            id="billing_attention"
                            type="text"
                            placeholder="Type text"
                            v-model="formBilling.billing_attention"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Bussiness 1" label-for="billing_business1">
                          <span v-if="previewData" class="text-capitalize">{{ formBilling?.billing_business1 ?? '-' }}</span>
                          <b-form-input
                            v-else
                            id="billing_business1"
                            type="text"
                            placeholder="Type text"
                            v-model="formBilling.billing_business1"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Bussiness 2" label-for="billing_business2">
                          <span v-if="previewData" class="text-capitalize">{{ formBilling?.billing_business2 ?? '-' }}</span>
                          <b-form-input
                            v-else
                            id="billing_business2"
                            type="text"
                            placeholder="Type text"
                            v-model="formBilling.billing_business2"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Bussiness 3" label-for="billing_business3">
                          <span v-if="previewData" class="text-capitalize">{{ formBilling?.billing_business3 ?? '-' }}</span>
                          <b-form-input
                            v-else
                            id="billing_business3"
                            type="text"
                            placeholder="Type text"
                            v-model="formBilling.billing_business3"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Cell" label-for="billing_cell">
                          <span v-if="previewData" class="text-capitalize">{{ formBilling?.billing_cell ?? '-' }}</span>
                          <b-form-input
                            v-else
                            id="billing_cell"
                            type="text"
                            placeholder="Type text"
                            v-model="formBilling.billing_cell"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Assistant" label-for="billing_assistant">
                          <span v-if="previewData" class="text-capitalize">{{ formBilling?.billing_assistant ?? '-' }}</span>
                          <b-form-input
                            v-else
                            id="billing_assistant"
                            type="text"
                            placeholder="Type text"
                            v-model="formBilling.billing_assistant"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Fax" label-for="billing_fax">
                          <span v-if="previewData" class="text-capitalize">{{ formBilling?.billing_fax ?? '-' }}</span>
                          <b-form-input
                            v-else
                            id="billing_fax"
                            type="text"
                            placeholder="Type text"
                            v-model="formBilling.billing_fax"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Home" label-for="billing_home">
                          <span v-if="previewData" class="text-capitalize">{{ formBilling?.billing_home ?? '-' }}</span>
                          <b-form-input
                            v-else
                            id="billing_home"
                            type="text"
                            placeholder="Type text"
                            v-model="formBilling.billing_home"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Home Fax" label-for="billing_home_fax">
                          <span v-if="previewData" class="text-capitalize">{{ formBilling?.billing_home_fax ?? '-' }}</span>
                          <b-form-input
                            v-else
                            id="billing_home_fax"
                            type="text"
                            placeholder="Type text"
                            v-model="formBilling.billing_home_fax"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Email" label-for="billing_email">
                          <span v-if="previewData" class="text-capitalize">{{ formBilling?.billing_email ?? '-' }}</span>
                          <b-form-input
                            v-else
                            id="billing_email"
                            type="text"
                            placeholder="Type text"
                            v-model="formBilling.billing_email"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Web" label-for="billing_web">
                          <span v-if="previewData" class="text-capitalize">{{ formBilling?.billing_web ?? '-' }}</span>
                          <b-form-input
                            v-else
                            id="billing_web"
                            type="text"
                            placeholder="Type text"
                            v-model="formBilling.billing_web"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </fieldset>
                </b-col>
                <b-col sm="6">
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 text-primary">Parent Info</legend>
                    <b-row class="mt-2">
                      <b-col md="12">
                        <b-form-group>
                          <span v-if="previewData" class="text-capitalize">Consolidate Balance? <br /> {{ formBilling.consolidate_balance ? "√" : "x" }}</span>
                          <b-form-checkbox
                            v-else
                            name="check-button"
                            switch
                            inline
                            v-model="formBilling.consolidate_balance"
                          >
                            Consolidate Balance
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group>
                          <span v-if="previewData" class="text-capitalize">Consolidate Statements? <br /> {{ formBilling.consolidate_statements ? "√" : "x" }}</span>
                          <b-form-checkbox
                            v-else
                            name="check-button"
                            switch
                            inline
                            v-model="formBilling.consolidate_statements"
                          >
                            Consolidate Statements
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </fieldset>
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 text-primary">Print and Email Settings</legend>
                    <b-row class="mt-2">
                      <b-col md="12">
                        <div class="d-flex align-items-center">
                          <b-form-group>
                            <span v-if="previewData" class="text-capitalize">Send invoice by email? <br /> {{ formBilling.send_invoice_by_email ? "√" : "x" }}</span>
                            <b-form-checkbox
                              v-else
                              name="check-button"
                              switch
                              inline
                              class="mr-2"
                              v-model="formBilling.send_invoice_by_email"
                            >
                              Send Invoices by Email
                            </b-form-checkbox>
                          </b-form-group>
                          <b-form-group>
                            <span v-if="previewData" class="text-capitalize">Print Invoices? <br /> {{ formBilling.print_invoices ? "√" : "x" }}</span>
                            <b-form-checkbox
                              v-else
                              name="check-button"
                              switch
                              inline
                              class="mr-2"
                              v-model="formBilling.print_invoices"
                            >
                              Print Invoices
                            </b-form-checkbox>
                          </b-form-group>
                        </div>
                      </b-col>
                      <b-col md="12">
                        <div class="d-flex align-items-center">
                          <b-form-group>
                            <span v-if="previewData" class="text-capitalize">Send Statement Email? <br /> {{ formBilling.send_statement_email ? "√" : "x" }}</span>
                            <b-form-checkbox
                              v-else
                              name="check-button"
                              switch
                              inline
                              class="mr-2"
                              v-model="formBilling.send_statement_email"
                            >
                              Send Statements by Email
                            </b-form-checkbox>
                          </b-form-group>
                          <b-form-group>
                            <span v-if="previewData" class="text-capitalize">Print Statements? <br /> {{ formBilling.print_statements ? "√" : "x" }}</span>
                            <b-form-checkbox
                              v-else
                              name="check-button"
                              switch
                              inline
                              class="mr-2"
                              v-model="formBilling.print_statements"
                            >
                              Print Statements
                            </b-form-checkbox>
                          </b-form-group>
                        </div>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="* Statement Type" label-for="statement_type">
                          <span v-if="previewData" class="text-capitalize">{{ formBilling.statement_type }}</span>
                          <b-form-select v-else plain  size="sm" id="statement_type" v-model="formBilling.statement_type">
                            <template v-slot:first>
                              <b-form-select-option :value="''">Select Statement Type...</b-form-select-option>
                              <b-form-select-option :value="'open-item'">Open Item</b-form-select-option>
                              <b-form-select-option :value="'balance-brought-forward'">Balance Brought Forward</b-form-select-option>
                              <b-form-select-option :value="'custom'">Custom</b-form-select-option>
                              <b-form-select-option :value="'none'">None</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group>
                          <span v-if="previewData" class="text-capitalize">Multi Currency Statements? <br /> {{ formBilling.multi_currency_statements ? "√" : "x" }}</span>
                          <b-form-checkbox
                            v-else
                            name="check-button"
                            switch
                            inline
                            v-model="formBilling.multi_currency_statements"
                          >
                            Multi-Currency Statements
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </fieldset>
                </b-col>
              </div>
            </tab-content-item>
            <!-- End Billing -->
            <!-- Shipping -->
            <tab-content-item :active="false" id="shipping">
              <div class="row">
                <b-col sm="6">
                  <fieldset class="form-group border p-3 mb-2">
                    <legend class="w-auto px-2 text-primary">Ship-to Address</legend>
                    <b-row class="mt-2">
                      <b-col md="12">
                        <b-form-group>
                          <span v-if="previewData" class="text-capitalize">Shipping from general? {{ formShipping.shipping_from_general ? "√" : "x" }}</span>
                          <b-form-checkbox
                            v-else
                            name="check-button"
                            switch
                            inline
                            v-model="formShipping.shipping_from_general"
                          >
                            From General
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="* Country" label-for="shipping_country_id">
                          <span v-if="previewData" class="text-capitalize">{{ formShipping.shipping_country_id }}</span>
                          <v-select
                            label="text"
                            :options="optionsCountry"
                            :reduce="(type) => type.value"
                            v-model="formShipping.shipping_country_id"
                            placeholder="Select country..."
                          ></v-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Province" label-for="billing_province">
                          <span v-if="previewData" class="text-capitalize">{{ formShipping.shipping_province }}</span>
                          <b-form-input
                            v-else
                            id="billing_province"
                            type="text"
                            placeholder="Type text"
                            v-model="formShipping.shipping_province"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="City" label-for="billing_city">
                          <span v-if="previewData" class="text-capitalize">{{ formShipping.shipping_city }}</span>
                          <b-form-input
                            v-else
                            id="billing_city"
                            type="text"
                            placeholder="Type text"
                            v-model="formShipping.shipping_city"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="District" label-for="billing_district">
                          <span v-if="previewData" class="text-capitalize">{{ formShipping.shipping_district }}</span>
                          <b-form-input
                            v-else
                            id="billing_district"
                            type="text"
                            placeholder="Type text"
                            v-model="formShipping.shipping_district"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Village" label-for="billing_village">
                          <span v-if="previewData" class="text-capitalize">{{ formShipping.shipping_village }}</span>
                          <b-form-input
                            v-else
                            id="billing_village"
                            type="text"
                            placeholder="Type text"
                            v-model="formShipping.shipping_village"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Postal Code" label-for="billing_postal_code">
                          <span v-if="previewData" class="text-capitalize">{{ formShipping.shipping_postal_code }}</span>
                          <b-form-input
                            v-else
                            id="billing_postal_code"
                            type="number"
                            placeholder="Type text"
                            v-model="formShipping.shipping_postal_code"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      
                      <b-col md="12">
                        <b-form-group label="Address" label-for="billing_address">
                          <span v-if="previewData" class="text-capitalize">{{ formShipping.shipping_address }}</span>
                          <b-form-input
                            v-else
                            id="billing_address"
                            type="textarea"
                            placeholder="Type text"
                            v-model="formShipping.shipping_address"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </fieldset>
                  <fieldset class="form-group border p-3 mb-2">
                    <legend class="w-auto px-2 text-primary">Ship-to Info</legend>
                    <b-row class="mt-2">
                      <b-col md="12">
                        <b-form-group label="Account Name" label-for="shipping_account_name">
                          <span v-if="previewData" class="text-capitalize">{{ formShipping.shipping_account_name }}</span>
                          <b-form-input
                            v-else
                            id="shipping_account_name"
                            type="text"
                            placeholder="Type text"
                            v-model="formShipping.shipping_account_name"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Attention" label-for="shipping_attention">
                          <span v-if="previewData" class="text-capitalize">{{ formShipping.shipping_attention }}</span>
                          <b-form-input
                            v-else
                            id="shipping_attention"
                            type="text"
                            placeholder="Type text"
                            v-model="formShipping.shipping_attention"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Bussiness 1" label-for="shipping_business1">
                          <span v-if="previewData" class="text-capitalize">{{ formShipping.shipping_business1 }}</span>
                          <b-form-input
                            v-else
                            id="shipping_business1"
                            type="text"
                            placeholder="Type text"
                            v-model="formShipping.shipping_business1"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Bussiness 2" label-for="shipping_business2">
                          <span v-if="previewData" class="text-capitalize">{{ formShipping.shipping_business2 }}</span>
                          <b-form-input
                            v-else
                            id="shipping_business2"
                            type="text"
                            placeholder="Type text"
                            v-model="formShipping.shipping_business2"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Bussiness 3" label-for="shipping_business3">
                          <span v-if="previewData" class="text-capitalize">{{ formShipping.shipping_business3 }}</span>
                          <b-form-input
                            v-else
                            id="shipping_business3"
                            type="text"
                            placeholder="Type text"
                            v-model="formShipping.shipping_business3"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Cell" label-for="shipping_cell">
                          <span v-if="previewData" class="text-capitalize">{{ formShipping.shipping_cell }}</span>
                          <b-form-input
                            v-else
                            id="shipping_cell"
                            type="text"
                            placeholder="Type text"
                            v-model="formShipping.shipping_cell"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Assistant" label-for="shipping_assistant">
                          <span v-if="previewData" class="text-capitalize">{{ formShipping.shipping_assistant }}</span>
                          <b-form-input
                            v-else
                            id="shipping_assistant"
                            type="text"
                            placeholder="Type text"
                            v-model="formShipping.shipping_assistant"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Fax" label-for="shipping_fax">
                          <span v-if="previewData" class="text-capitalize">{{ formShipping.shipping_fax }}</span>
                          <b-form-input
                            v-else
                            id="shipping_fax"
                            type="text"
                            placeholder="Type text"
                            v-model="formShipping.shipping_fax"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Home" label-for="shipping_home">
                          <span v-if="previewData" class="text-capitalize">{{ formShipping.shipping_home }}</span>
                          <b-form-input
                            v-else
                            id="shipping_home"
                            type="text"
                            placeholder="Type text"
                            v-model="formShipping.shipping_home"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Home Fax" label-for="shipping_home_fax">
                          <span v-if="previewData" class="text-capitalize">{{ formShipping.shipping_home_fax }}</span>
                          <b-form-input
                            v-else
                            id="shipping_home_fax"
                            type="text"
                            placeholder="Type text"
                            v-model="formShipping.shipping_home_fax"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Email" label-for="shipping_email">
                          <span v-if="previewData" class="text-capitalize">{{ formShipping.shipping_email }}</span>
                          <b-form-input
                            v-else
                            id="shipping_email"
                            type="text"
                            placeholder="Type text"
                            v-model="formShipping.shipping_email"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Web" label-for="shipping_web">
                          <span v-if="previewData" class="text-capitalize">{{ formShipping.shipping_web }}</span>
                          <b-form-input 
                            v-else
                            id="shipping_web"
                            type="text"
                            placeholder="Type text"
                            v-model="formShipping.shipping_web"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </fieldset>
                </b-col>
                <b-col sm="6">
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 text-primary">Tax Settings</legend>
                    <b-row class="mt-2">
                      <b-col md="12">
                        <b-form-group label="Tax Registration ID" label-for="tax_registration_number">
                          <span v-if="previewData" class="text-capitalize">{{ formShipping.tax_registration_number }}</span>
                          <b-form-input
                            v-else
                            id="tax_registration_number"
                            type="text"
                            placeholder="Type text"
                            v-model="formShipping.tax_registration_number"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Tax Zone" label-for="tax_zone_id">
                          <span v-if="previewData" class="text-capitalize">{{ formShipping.tax_zone_id }}</span>
                          <b-form-select v-else plain  size="sm" 
                            id="tax_zone_id"
                            v-model="formShipping.tax_zone_id"
                            :options="optionsTaxZone"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null">Select Tax zone...</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </fieldset>
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 text-primary">Shipping Instructions</legend>
                    <b-row class="mt-2">
                      <b-col md="12">
                        <b-form-group label="Warehouse" label-for="si_warehouse">
                          <span v-if="previewData" class="text-capitalize">{{ formShipping.si_warehouse }}</span>
                          <v-select
                           v-else
                            label="text"
                            :options="[]"
                            :reduce="(type) => type?.value"
                            v-model="formShipping.si_warehouse"
                            placeholder="Select..."
                          ></v-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Ship Via" label-for="si_ship_via">
                          <span v-if="previewData" class="text-capitalize">{{ formShipping.si_ship_via }}</span>
                          <b-form-input
                            v-else
                            id="si_ship_via"
                            type="text"
                            placeholder="Type text"
                            v-model="formShipping.si_ship_via"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Shipping Terms" label-for="si_shipping_terms_id">
                          <span v-if="previewData" class="text-capitalize">{{ formShipping.si_shipping_terms?.code }}</span>
                          <b-form-select
                            v-else
                            id="si_shipping_terms_id"
                            plain
                            size="md"
                            v-model="formShipping.si_shipping_terms_id"
                            :options="optionsTerms"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null">Select terms....</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Shipping Zone" label-for="si_shipping_zone">
                          <span v-if="previewData" class="text-capitalize">{{ formShipping.si_shipping_zone }}</span>
                          <b-form-input 
                            v-else
                            id="si_shipping_zone"
                            type="text"
                            placeholder="Type text"
                            v-model="formShipping.si_shipping_zone"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="FOB Point" label-for="si_fob_point">
                          <span v-if="previewData" class="text-capitalize">{{ formShipping.si_fob_point }}</span>
                          <b-form-input
                            v-else
                            id="si_fob_point"
                            type="text"
                            placeholder="Type text"
                            v-model="formShipping.si_fob_point"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group>
                          <span v-if="previewData" class="text-capitalize">Residential Delivery? {{ formShipping.si_residential_delivery ? "√" : "x" }}</span>
                          <b-form-checkbox
                            v-else
                            name="check-button"
                            switch
                            inline
                            v-model="formShipping.si_residential_delivery"
                          >
                            Residential Delivery
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group>
                          <span v-if="previewData" class="text-capitalize">Saturday Delivery? {{ formShipping.si_saturday_delivery ? "√" : "x" }}</span>
                          <b-form-checkbox
                            v-else
                            name="check-button"
                            switch
                            inline
                            v-model="formShipping.si_saturday_delivery"
                          >
                            Saturday Delivery
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group>
                          <span v-if="previewData" class="text-capitalize">Insurance? {{ formShipping.si_insurance ? "√" : "x" }}</span>
                          <b-form-checkbox
                            v-else
                            name="check-button"
                            switch
                            inline
                            v-model="formShipping.si_insurance"
                          >
                            Insurance
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="* Shipping Rule" label-for="shipping_rule">
                          <span v-if="previewData" class="text-capitalize">{{ formShipping.si_shipping_rule }}</span>
                          <b-form-select
                            v-else
                            id="shipping_rule"
                            plain
                            size="md"
                            v-model="formShipping.si_shipping_rule"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="''">Select Shipping Rule...</b-form-select-option>
                              <b-form-select-option :value="'ship-complete'">Ship Complete</b-form-select-option>
                              <b-form-select-option :value="'back-order-allowed'">Back Order Allowed</b-form-select-option>
                              <b-form-select-option :value="'cancel-reminder'">Cancel Remainder</b-form-select-option>
                              <b-form-select-option :value="'custom'">Custom</b-form-select-option>
                              <b-form-select-option :value="'none'">None</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Order Priority" label-for="si_order_priority">
                          <span v-if="previewData" class="text-capitalize">{{ formShipping.si_order_priority }}</span>
                          <b-form-input
                            v-else
                            id="si_order_priority"
                            type="number"
                            placeholder="0"
                            v-model="formShipping.si_order_priority"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Lead Time (Days)" label-for="si_lead_time_days">
                          <span v-if="previewData" class="text-capitalize">{{ formShipping.si_lead_time_days }}</span>
                          <b-form-input
                            v-else
                            id="si_lead_time_days"
                            type="number"
                            placeholder="0"
                            v-model="formShipping.si_lead_time_days"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Calendar" label-for="si_calendar">
                          <span v-if="previewData" class="text-capitalize">{{ formShipping.si_calendar }}</span>
                          <b-form-input
                            v-else
                            id="si_calendar"
                            type="number"
                            placeholder="0"
                            v-model="formShipping.si_calendar"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </fieldset>
                </b-col>
              </div>
            </tab-content-item>
            <!-- End Shiping -->
            <!-- Contact -->
            <tab-content-item :active="false" id="contact">
              <div class="row">
                <b-col sm="12">
                  <fieldset class="form-group border p-3 mb-2">
                    <b-row class="mt-2">
                      <b-col md="12" class="mb-2">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="d-flex align-items-center">
                            <a class="text-primary mr-4" role="button" style="cursor: pointer"  @click="addRowContact">
                              <i class="fa fa-plus mr-2" />
                              <span>Add Row</span>
                            </a>
                          </div>
                        </div>  
                      </b-col>
                      <b-col md="12">
                        <div class="overflow-auto">
                          <table class="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th class="text-center align-middle" v-if="!previewData">#</th>
                                <th class="text-center align-middle">No</th>
                                <th class="text-left align-middle" style="min-width: 200px;">Contact</th>
                                <th class="text-left align-middle" style="min-width: 200px;">Job Title</th>
                                <th class="text-left align-middle" style="min-width: 200px;">Division</th>
                                <th class="text-left align-middle" style="min-width: 200px;">Email</th>
                                <th class="text-left align-middle" style="min-width: 200px;">Phone</th>
                                <th class="text-left align-middle" style="min-width: 200px;">Mobile Phone</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(state, index) in formContact" :key="index">
                                <td v-if="!previewData">
                                  <i class="fa fa-trash text-danger" @click="formContact.splice(index, 1)" style="cursor: pointer;" />
                                </td>
                                <td>{{ index + 1 }}</td>
                                <td>
                                  <b-form-group>
                                    <b-form-input
                                      id="contact_name"
                                      type="text"
                                      placeholder="Type text"
                                      v-model="state.contact_name"
                                      :disabled="previewData"
                                    ></b-form-input>
                                  </b-form-group>
                                </td>
                                <td>
                                  <b-form-group>
                                    <b-form-input
                                      id="contact_job_title"
                                      type="text"
                                      placeholder="Type text"
                                      v-model="state.contact_job_title"
                                      :disabled="previewData"
                                    ></b-form-input>
                                  </b-form-group>
                                </td>
                                <td>
                                  <b-form-group>
                                    <b-form-input
                                      id="contact_division"
                                      type="text"
                                      placeholder="Type text"
                                      v-model="state.contact_division"
                                      :disabled="previewData"
                                    ></b-form-input>
                                  </b-form-group>
                                </td>
                                <td>
                                  <b-form-group>
                                    <b-form-input
                                      id="contact_email"
                                      type="text"
                                      placeholder="Type text"
                                      v-model="state.contact_email"
                                      :disabled="previewData"
                                    ></b-form-input>
                                  </b-form-group>
                                </td>
                                <td>
                                  <b-form-group>
                                    <b-form-input
                                      id="contact_phone"
                                      type="text"
                                      placeholder="Type text"
                                      v-model="state.contact_phone"
                                      :disabled="previewData"
                                    ></b-form-input>
                                  </b-form-group>
                                </td>
                                <td>
                                  <b-form-group>
                                    <b-form-input
                                      id="contact_mobile"
                                      type="text"
                                      placeholder="Type text"
                                      v-model="state.contact_mobile"
                                      :disabled="previewData"
                                    ></b-form-input>
                                  </b-form-group>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-col>
                    </b-row>
                  </fieldset>
                </b-col>
              </div>
            </tab-content-item>
            <!-- End Contact -->
            <!-- Vendor File -->
            <tab-content-item :active="false" id="customer-file">
              <div class="row">
                <b-col sm="12">
                  <fieldset class="form-group border p-3 mb-2">
                    <b-row class="mt-2">
                      <b-col md="12" class="mb-2">
                        <div class="d-flex align-items-center">
                          <a class="text-primary mr-4" role="button" style="cursor: pointer"  @click="addRowVendor">
                              <i class="fa fa-plus mr-2" />
                              <span>Add Row</span>
                            </a>
                        </div>  
                      </b-col>
                      <b-col md="12">
                        <div class="overflow-auto">
                          <table class="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th class="text-center align-middle" style="width: 30px" v-if="!previewData">#</th>
                                <th class="text-center align-middle" style="width: 20px">No</th>
                                <th class="text-left align-middle" style="min-width: 300px;">Document List</th>
                                <th class="text-left align-middle" style="min-width: 150px;">File</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(state, index) in formVendorFile" :key="index">
                                <td v-if="!previewData">
                                  <i class="fa fa-trash text-danger" @click="formVendorFile.splice(index, 1)" style="cursor: pointer;" />
                                </td>
                                <td style="width: 20px">{{ index + 1 }}</td>
                                <td>
                                  <b-form-select
                                    id="document_list_id"
                                    plain
                                    required
                                    size="md"
                                    v-model="state.document_list_id"
                                    :options="optionsDocumentList"
                                    :disabled="previewData"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="null">Select Document...</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                </td>
                                <td>
                                  <b-form-group>
                                    <b-form-file
                                      plain
                                      id="file"
                                      v-model="state.file"
                                      accept="image/*,application/*"
                                      multiple
                                      class="form-control"
                                      :disabled="previewData"
                                    ></b-form-file>
                                  </b-form-group>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-col>
                    </b-row>
                  </fieldset>
                </b-col>
              </div>
            </tab-content-item>
            <!-- End Vendor File -->
          </div>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>
<script>
  import _ from 'lodash'
  import { busdevActions } from '@src/Utils/helper'
  import moment from 'moment'

  export default {
    name: 'FormCustomer',
    props:{
      refreshData: {
        type: Function,
        default: true
      },
      isEdit: {
        type: Boolean,
        default: false
      },
      editId: {
        type: Number,
        default: null
      }, 
      previewData: {
        type: Boolean,
        default: false
      }, 
      optionsCustomerClass: {
        type: Array,
        default: []
      },
    },
    data() {
      return {
        loading:false,
        saving:false,
        optionsTerms: [],
        optionsTaxZone: [],
        optionsDocumentList: [],
        optionsCountry: [],
        formCustomer:{
          customer_code:'',
          account_name:'',
          customer_class_id:Number(this.optionsCustomerClass?.[0]?.value),
          customer_country_id:'',
          customer_name:'',
          customer_email:'',
          logo:null,
          customer_web:'',
          customer_phone1:'',
          customer_phone2:'',
          customer_mobile1:'',
          customer_mobile2:'',
          customer_fax:'',
          customer_assistant:'',
          customer_address:'',
          customer_province:'',
          customer_city:'',
          customer_district:'',
          customer_village:'',
          customer_postal_code:'',
          customer_maps:JSON.stringify({
            lat: -1.1234,
            lng:106.1234
          }),
          start_date:'',
          customer_status:'active', // ['active', 'on-hold', 'credit-hold','one-time','not-recommended','blacklist','inactive']
        },
        formBilling:{
          billing_from_general:null,
          billing_account_name:'',
          billing_attention:'',
          billing_business1:'',
          billing_business2:'',
          billing_business3:'',
          billing_cell:'',
          billing_assistant:'',
          billing_fax:'',
          billing_home:'',
          billing_home_fax:'',
          billing_email:'',
          billing_web:'',
          billing_address:'',
          billing_province:'',
          billing_city:'',
          billing_district:'',
          billing_village:'',
          billing_postal_code:'',
          billing_country_id:'',
          consolidate_balance:'',
          consolidate_statements:'',
          send_invoice_by_email:'',
          print_invoices:'',
          print_statements:'',
          statement_type:'open-item', // ['open-item', 'balance-brought-forward','custom','none']
          multi_currency_statements:'',
        },
        formShipping:{
          shipping_from_general:'',
          tax_registration_number:'',
          tax_zone_id:null,
          shipping_account_name:'',
          shipping_attention:'',
          shipping_business1:'',
          shipping_business2:'',
          shipping_business3:'',
          shipping_cell:'',
          shipping_assistant:'',
          shipping_fax:'',
          shipping_home:'',
          shipping_home_fax:'',
          shipping_email:'',
          shipping_web:'',
          shipping_address:'',
          shipping_province:'',
          shipping_city:'',
          shipping_district:'',
          shipping_village:'',
          shipping_postal_code:'',
          shipping_country_id:null,
          si_warehouse:'',
          si_ship_via:'',
          si_shipping_terms_id:'',
          si_shipping_zone:'',
          si_fob_point:'',
          si_residential_delivery:'',
          si_saturday_delivery:'',
          si_insurance:'',
          si_shipping_rule:'back-order-allowed', // ['ship-complete','back-order-allowed','cancel-remainder','custom','none']
          si_order_priority:'',
          si_lead_time_days:'',
          si_calendar:'',
        },
        formContact:[],
        formVendorFile:[],
        optionsCurrency:[]
      }
    },
    components:{},
    watch:{
      'editId': {
        handler(value) {
          if(value){
            this.getCustomerById()
          } else {
            this.resetFormCustomer()
          }
        },
        immediate: true,
        deep: true,
      },
      'optionsCustomerClass.length':{
        handler(value){
          this.formCustomer.customer_class_id = Number(this.optionsCustomerClass?.[0]?.value)
        }
      },
    },
    computed: {},
    async mounted() {
      this.getCurrency()
      this.getTerms()
      this.getTaxZone()
      this.getDocumentList()
      this.getCountry()
    },
    methods: {
      ...busdevActions,
      customerClassName(id){
        let customerClass = this.optionsCustomerClass?.find(obj => obj?.value === id)

        return customerClass?.text ?? '-'
      },
      customerCountryName(id){
        let customerCountry = this.optionsCountry?.find(obj => obj?.value === id)

        return customerCountry?.text ?? '-'
      },

      addRowContact (){
        this.formContact.push({
          contact_name:'',
          contact_job_title:'',
          contact_division:'',
          contact_email:'',
          contact_phone:'',
          contact_mobile:'',
        })
      },
      addRowVendor (){
        this.formVendorFile.push({
          document_list_id:null,
          file:null,
        })
      },
      resetFormCustomer(){
        this.formCustomer = {
          customer_code:'',
          account_name:'',
          customer_class_id:Number(this.optionsCustomerClass?.[0]?.value),
          customer_country_id:'',
          customer_name:'',
          customer_email:'',
          logo:null,
          customer_web:'',
          customer_phone1:'',
          customer_phone2:'',
          customer_mobile1:'',
          customer_mobile2:'',
          customer_fax:'',
          customer_assistant:'',
          customer_address:'',
          customer_province:'',
          customer_city:'',
          customer_district:'',
          customer_village:'',
          customer_postal_code:'',
          customer_maps:JSON.stringify({
            lat: -1.1234,
            lng:106.1234
          }),
          start_date:'',
          customer_status:'active', // ['active', 'on-hold', 'credit-hold','one-time','not-recommended','blacklist','inactive']
        }
        this.formBilling = {
          billing_from_general:null,
          billing_account_name:'',
          billing_attention:'',
          billing_business1:'',
          billing_business2:'',
          billing_business3:'',
          billing_cell:'',
          billing_assistant:'',
          billing_fax:'',
          billing_home:'',
          billing_home_fax:'',
          billing_email:'',
          billing_web:'',
          billing_address:'',
          billing_province:'',
          billing_city:'',
          billing_district:'',
          billing_village:'',
          billing_postal_code:'',
          billing_country_id:'',
          consolidate_balance:'',
          consolidate_statements:'',
          send_invoice_by_email:'',
          print_invoices:'',
          print_statements:'',
          statement_type:'open-item', // ['open-item', 'balance-brought-forward','custom','none']
          multi_currency_statements:'',
        }
        this.formShipping = {
          shipping_from_general:'',
          tax_registration_number:'',
          tax_zone_id:null,
          shipping_account_name:'',
          shipping_attention:'',
          shipping_business1:'',
          shipping_business2:'',
          shipping_business3:'',
          shipping_cell:'',
          shipping_assistant:'',
          shipping_fax:'',
          shipping_home:'',
          shipping_home_fax:'',
          shipping_email:'',
          shipping_web:'',
          shipping_address:'',
          shipping_province:'',
          shipping_city:'',
          shipping_district:'',
          shipping_village:'',
          shipping_postal_code:'',
          shipping_country_id:'',
          si_warehouse:'',
          si_ship_via:'',
          si_shipping_terms_id:'',
          si_shipping_zone:'',
          si_fob_point:'',
          si_residential_delivery:'',
          si_saturday_delivery:'',
          si_insurance:'',
          si_shipping_rule:'back-order-allowed', // ['ship-complete','back-order-allowed','cancel-remainder','custom','none']
          si_order_priority:'',
          si_lead_time_days:'',
          si_calendar:'',
        }
        this.formContact = []
        this.formVendorFile = []
      },
      async getCurrency() {
        let res = await this.fetchCurrency()
        if (res.data.status === true) {
          if (res.data.data && res.data.data.length > 0) {
            res.data.data.map((state) => {
              this.optionsCurrency.push({
                value: state.id,
                text: state.alias,
              })
            })
          }
        }
      },  
      async getCustomerById (){
        if(this.isEdit && this.editId){
          const res = await this.fetchCustomerById({id: this.editId})

          if(res.status === 'success'){
            this.formVendorFile = []
            const data = res.data.data
            const dataBilling = res.data.data.customer_billing
            const dataShipping = res.data.data.customer_shipping
            const dataContact = res.data.data.customer_contact
            const customerFile = res.data.data.customer_file

            console.log(res.data.data, "res.data.data")

            this.formCustomer = {
              customer_code:data.customer_code,
              account_name:data.account_name,
              customer_class_id:data.customer_class_id,
              customer_country_id:data.country_id,
              customer_name:data.name,
              customer_email:data.email,
              logo:null,
              customer_web:data.web,
              customer_phone1:data.phone1,
              customer_phone2:data.phone,
              customer_mobile1:data.mobile1,
              customer_mobile2:data.mobile2,
              customer_fax:data.fax,
              customer_assistant: data.assistant,
              customer_address:data.address,
              customer_province:data.province,
              customer_city:data.city,
              customer_district:data.district,
              customer_village:data.village,
              customer_postal_code:data.postal_code,
              customer_maps:JSON.stringify({
                lat: -1.1234,
                lng:106.1234
              }),
              start_date:data.start_date,
              customer_status:data.customer_status, 
            }

            this.formBilling = {
              billing_from_general:dataBilling.from_general ? true : false,
              billing_account_name:dataBilling.account_name,
              billing_attention:dataBilling.attention,
              billing_business1:dataBilling.business1,
              billing_business2:dataBilling.business2,
              billing_business3:dataBilling.business3,
              billing_cell:dataBilling.cell,
              billing_assistant:dataBilling.assistant,
              billing_fax:dataBilling.fax,
              billing_home:dataBilling.home,
              billing_home_fax:dataBilling.home_fax,
              billing_email:dataBilling.email,
              billing_web:dataBilling.web,
              billing_address:dataBilling.address,
              billing_province:dataBilling.province,
              billing_city:dataBilling.city,
              billing_district:dataBilling.district,
              billing_village:dataBilling.village,
              billing_postal_code:dataBilling.postal_code,
              billing_country_id:dataBilling.country_id,
              consolidate_balance:dataBilling.consolidate_balance,
              consolidate_statements:dataBilling.consolidate_statements,
              send_invoice_by_email:dataBilling.send_invoice_by_email ? true : false,
              print_invoices:dataBilling.print_invoices ? true : false,
              print_statements:dataBilling.print_statements ? true : false,
              statement_type:dataBilling.statement_type, // ['open-item', 'balance-brought-forward','custom','none']
              multi_currency_statements:dataBilling.multi_currency_statements ? true : false,
            }

            this.formShipping = {
              shipping_from_general:dataShipping.from_general ? true : false,
              tax_registration_number:dataShipping.tax_registration_number,
              tax_zone_id:dataShipping.tax_zone_id,
              shipping_account_name:dataShipping.account_name,
              shipping_attention:dataShipping.attention,
              shipping_business1:dataShipping.business1,
              shipping_business2:dataShipping.business2,
              shipping_business3:dataShipping.business3,
              shipping_cell:dataShipping.cell,
              shipping_assistant:dataShipping.assistant,
              shipping_fax:dataShipping.fax,
              shipping_home:dataShipping.home,
              shipping_home_fax:dataShipping.home_fax,
              shipping_email:dataShipping.email,
              shipping_web:dataShipping.web,
              shipping_address:dataShipping.address,
              shipping_province:dataShipping.province,
              shipping_city:dataShipping.city,
              shipping_district:dataShipping.district,
              shipping_village:dataShipping.village,
              shipping_postal_code:dataShipping.postal_code,
              shipping_country_id:dataShipping.country_id,
              si_warehouse:dataShipping.si_warehouse,
              si_ship_via:dataShipping.si_ship_via,
              si_shipping_terms_id:dataShipping.si_shipping_terms_id,
              si_shipping_zone:dataShipping.si_shipping_zone,
              si_fob_point:dataShipping.si_fob_point,
              si_residential_delivery:dataShipping.si_residential_delivery ? true : false,
              si_saturday_delivery:dataShipping.si_saturday_delivery ? true : false,
              si_insurance:dataShipping.si_insurance ? true : false,
              si_shipping_rule:dataShipping.si_shipping_rule, // ['ship-complete','back-order-allowed','cancel-remainder','custom','none']
              si_order_priority:dataShipping.si_order_priority, 
              si_lead_time_days:dataShipping.si_lead_time_days,
              si_calendar:dataShipping.si_calendar,
            }

            if(dataContact.length > 0){
              dataContact.map(val => {
                this.formContact.push({
                  contact_name:val.name,
                  contact_job_title:val.job_title,
                  contact_division:val.division,
                  contact_email:val.email,
                  contact_phone:val.phone,
                  contact_mobile:val.mobile,
                })
              })
            }

            if(customerFile?.length > 0){
              customerFile.map(val => {
                this.formVendorFile.push({
                  document_list_id:val.document_list_id,
                  file:null,
                  url:val.file_location
                })
              })
            }
          }
        }
      },
      async getTerms() {
        let res = await this.fetchTerms()
        if (res.data.status === true) {
          if (res.data.data && res.data.data.length > 0) {
            res.data.data.map((state) => {
              this.optionsTerms.push({
                value: state.id,
                text: state.name,
              })
            })
          }
        }
      },
      async getTaxZone() {
        let res = await this.fetchTaxZone()
        if (res.data.status === true) {
          if (res.data.data && res.data.data.length > 0) {
            res.data.data.map((state) => {
              this.optionsTaxZone.push({
                value: state.id,
                text: state.name,
              })
            })
          }
        }
      }, 
      async getDocumentList() {
        let res = await this.fetchDocumentList()
        if (res.data.status === true) {
          if (res.data.data && res.data.data.length > 0) {
            res.data.data.map((state) => {
              this.optionsDocumentList.push({
                value: state.id,
                text: state.name,
              })
            })
          }
        }
      },
      async getCountry() {
        let res = await this.fetchCountry()
        if (res.data.status === true) {
          if (res.data.data && res.data.data.length > 0) {
            res.data.data.map((state) => {
              this.optionsCountry.push({
                value: state.code,
                text: `${state.code} - ${state.name}`,
              })
            })
          }
        }
      },
      async onSave(e) {
        e.preventDefault()
        this.saving = true

        let payload = {
          ...this.formCustomer,
          ...this.formBilling,
          ...this.formShipping,
          contact: this.formContact,
          vendor:this.formVendorFile
        }

        let formData = new FormData()

        // CUSTOMER PAYLOAD
        formData.append('customer_code', payload.customer_code)
        formData.append('customer_class_id', Number(payload.customer_class_id))
        formData.append('customer_country_id', payload.customer_country_id)
        formData.append('account_name', payload.account_name)
        formData.append('customer_name', payload.customer_name)
        formData.append('customer_email', payload.customer_email)
        formData.append('logo', payload.logo)
        formData.append('customer_web', payload.customer_web)
        formData.append('customer_phone1', payload.customer_phone1)
        formData.append('customer_phone2', payload.customer_phone2)
        formData.append('customer_mobile1', payload.customer_mobile1)
        formData.append('customer_mobile2', payload.customer_mobile2)
        formData.append('customer_fax', payload.customer_fax)
        formData.append('customer_assistant', payload.customer_assistant)
        formData.append('customer_address', payload.customer_address)
        formData.append('customer_province', payload.customer_province)
        formData.append('customer_city', payload.customer_city)
        formData.append('customer_district', payload.customer_district)
        formData.append('customer_village', payload.customer_village)
        formData.append('customer_postal_code', Number(payload.customer_postal_code))
        formData.append('customer_maps', payload.customer_maps)
        formData.append('start_date', moment().format('YYYY-MM-DD'))
        formData.append('customer_status', payload.customer_status)

        // BILLING PAYLOAD
        formData.append('billing_from_general', payload.billing_from_general ? 1 : 0)
        formData.append('billing_account_name', payload.billing_account_name)
        formData.append('billing_attention', payload.billing_attention)
        formData.append('billing_business1', payload.billing_business1)
        formData.append('billing_business2', payload.billing_business2)
        formData.append('billing_business3', payload.billing_business3)
        formData.append('billing_cell', payload.billing_cell)
        formData.append('billing_assistant', payload.billing_assistant)
        formData.append('billing_fax', payload.billing_fax)
        formData.append('billing_home', payload.billing_home)
        formData.append('billing_home_fax', payload.billing_home_fax)
        formData.append('billing_email', payload.billing_email)
        formData.append('billing_web', payload.billing_web)
        formData.append('billing_address', payload.billing_address)
        formData.append('billing_province', payload.billing_province)
        formData.append('billing_city', payload.billing_city)
        formData.append('billing_district', payload.billing_district)
        formData.append('billing_village', payload.billing_village)
        formData.append('billing_postal_code', Number(payload.billing_postal_code))
        formData.append('billing_country_id', payload.billing_country_id)
        formData.append('consolidate_balance', payload.consolidate_balance ? 1 : 0)
        formData.append('consolidate_statements', payload.consolidate_statements ? 1 : 0)
        formData.append('send_invoice_by_email', payload.send_invoice_by_email ? 1 : 0)
        formData.append('send_statement_email', payload.send_statement_email ? 1 : 0) 
        formData.append('print_invoices', payload.print_invoices ? 1 : 0)
        formData.append('print_statements', payload.print_statements ? 1 : 0)
        formData.append('statement_type', payload.statement_type)
        formData.append('multi_currency_statements', payload.multi_currency_statements ? 1 : 0)

        // SHIPPING PAYLOAD
        formData.append('shipping_from_general', payload.shipping_from_general ? 1 : 0)
        formData.append('tax_registration_number', payload.tax_registration_number)
        formData.append('tax_zone_id', payload.tax_zone_id)
        formData.append('shipping_account_name', payload.shipping_account_name)
        formData.append('shipping_attention', payload.shipping_attention)
        formData.append('shipping_business1', payload.shipping_business1)
        formData.append('shipping_business2', payload.shipping_business2)
        formData.append('shipping_business3', payload.shipping_business3)
        formData.append('shipping_cell', payload.shipping_cell)
        formData.append('shipping_assistant', payload.shipping_assistant)
        formData.append('shipping_fax', payload.shipping_fax)
        formData.append('shipping_home', payload.shipping_home)
        formData.append('shipping_home_fax', payload.shipping_home_fax)
        formData.append('shipping_email', payload.shipping_email)
        formData.append('shipping_web', payload.shipping_web)
        formData.append('shipping_address', payload.shipping_address)
        formData.append('shipping_province', payload.shipping_province)
        formData.append('shipping_city', payload.shipping_city)
        formData.append('shipping_district', payload.shipping_district)
        formData.append('shipping_village', payload.shipping_village)
        formData.append('shipping_postal_code', Number(payload.shipping_postal_code))
        formData.append('shipping_country_id', payload.shipping_country_id)
        formData.append('si_warehouse', payload.si_warehouse)
        formData.append('si_ship_via', payload.si_ship_via)
        formData.append('si_shipping_terms_id', Number(payload.si_shipping_terms_id))
        formData.append('si_shipping_zone', payload.si_shipping_zone)
        formData.append('si_fob_point', payload.si_fob_point)
        formData.append('si_residential_delivery', payload.si_residential_delivery ? 1 : 0)
        formData.append('si_saturday_delivery', payload.si_saturday_delivery ? 1 : 0)
        formData.append('si_insurance', payload.si_insurance ? 1 : 0)
        formData.append('si_shipping_rule', payload.si_shipping_rule)
        formData.append('si_order_priority', payload.si_order_priority)
        formData.append('si_lead_time_days', payload.si_lead_time_days)
        formData.append('si_calendar', payload.si_calendar)

        payload?.contact?.map((item, index) => {
            for (const property in item) {
                formData.append(
                    `contact[${index}][${property}]`,
                    item[property]
                )
            }
        })

        payload?.vendor?.forEach((item, index) => {
            item?.file?.forEach((file, fileIndex) => {
                formData.append(`vendor_file[${index}]`, file);
            });
   
            // formData.append(`vendor[${index}][document_list_id]`, Number(item.document_list_id))
            formData.append(`vendor[${index}][document_list_id]`, index + 1)
        })

        let res = null
        if(this.isEdit && this.editId){
          res = await this.updateCustomer({data: formData, id: Number(this.editId)})
        } else {
          res = await this.createCustomer({data: formData})
        }

        if([500, 404, 403, 422].includes(res.status)){
          this.saving = false
          this.$swal(`Oops.`, res?.message ?? res?.data?.message ?? 'Something went wrong!', 'error')
          return
        }

        if(res?.data?.status === true){
          this.saving = false
          this.refreshData()
          this.resetFormCustomer()
          this.$swal(`Success!`, `Create Customer Success.`, 'success')
        } else {
          this.saving = false
          this.$swal(`Oops.`, res?.data?.message, 'error')
        }

        this.saving = false
      }
    }
  }
</script>