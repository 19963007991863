var render = function render(){
  var _vm$detailProjectCont, _vm$detailProjectCont2, _vm$detailProjectCont3, _vm$detailProjectCont4, _vm$detailProjectCont5, _vm$encryptedData, _vm$detailProjectCont6, _vm$detailProjectCont7, _vm$detailProjectCont8, _vm$detailProjectCont9, _vm$detailProjectCont10, _vm$detailProjectCont11, _vm$detailProjectCont12, _vm$detailProjectCont13, _vm$detailProjectCont14, _vm$detailProjectCont15, _vm$detailProjectCont16, _vm$detailProjectCont17, _vm$detailProjectCont18, _vm$detailProjectCont19, _vm$detailProjectCont20, _vm$detailProjectCont21, _vm$detailProjectCont22, _vm$detailProjectCont23, _vm$detailProjectCont24, _vm$detailProjectCont25, _vm$detailProjectCont26, _vm$detailProjectCont27, _vm$detailProjectCont28, _vm$detailProjectCont29, _vm$detailProjectCont30, _vm$detailProjectCont31, _vm$detailProjectCont32, _vm$detailProjectCont33, _vm$detailProjectCont34, _vm$detailProjectCont35;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "text-center align-content-center",
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "w-100 align-items-center justify-content-center d-flex",
    staticStyle: {
      "height": "100vh"
    }
  }, [_c('div', {
    staticClass: "w-100",
    staticStyle: {
      "max-width": "700px"
    }
  }, [_c('iq-card', [!_vm.loading && !_vm.approvalDone ? _c('div', {
    staticClass: "p-4"
  }, [_c('span', [_vm._v("Approval "), _c('br'), _c('h3', [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$detailProjectCont = _vm.detailProjectContract) === null || _vm$detailProjectCont === void 0 ? void 0 : (_vm$detailProjectCont2 = _vm$detailProjectCont.project_sub) === null || _vm$detailProjectCont2 === void 0 ? void 0 : (_vm$detailProjectCont3 = _vm$detailProjectCont2.project) === null || _vm$detailProjectCont3 === void 0 ? void 0 : _vm$detailProjectCont3.project_name) + " - " + _vm._s((_vm$detailProjectCont4 = _vm.detailProjectContract) === null || _vm$detailProjectCont4 === void 0 ? void 0 : (_vm$detailProjectCont5 = _vm$detailProjectCont4.project_sub) === null || _vm$detailProjectCont5 === void 0 ? void 0 : _vm$detailProjectCont5.name))])])]), _c('h5', {
    staticClass: "mb-4 text-left mt-2"
  }, [_vm._v("Dear, "), _c('strong', [_vm._v(_vm._s((_vm$encryptedData = _vm.encryptedData) === null || _vm$encryptedData === void 0 ? void 0 : _vm$encryptedData.userName))])]), _c('div', {
    staticClass: "d-flex w-100"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('tbody', [_c('tr', [_c('th', {
    staticClass: "text-left"
  }, [_vm._v(_vm._s((_vm$detailProjectCont6 = _vm.detailProjectContract) === null || _vm$detailProjectCont6 === void 0 ? void 0 : (_vm$detailProjectCont7 = _vm$detailProjectCont6.fleet_type) === null || _vm$detailProjectCont7 === void 0 ? void 0 : _vm$detailProjectCont7.name))]), _c('th', {
    staticStyle: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    staticClass: "text-left text-truncate"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, _vm._l((_vm$detailProjectCont8 = _vm.detailProjectContract) === null || _vm$detailProjectCont8 === void 0 ? void 0 : _vm$detailProjectCont8.fleets, function (state, index) {
    return _c('b-badge', {
      staticClass: "border border-primary text-primary text-uppercase mb-2",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v(_vm._s(state.vehicle_name))]);
  }), 1)]), _c('th', {
    staticClass: "text-left text-truncate"
  }, [_vm._v("Laycan")]), _c('th', {
    staticStyle: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    staticClass: "text-left"
  }, [_vm._v(_vm._s((_vm$detailProjectCont9 = _vm.detailProjectContract) !== null && _vm$detailProjectCont9 !== void 0 && _vm$detailProjectCont9.laycan ? _vm.moment((_vm$detailProjectCont10 = _vm.detailProjectContract) === null || _vm$detailProjectCont10 === void 0 ? void 0 : _vm$detailProjectCont10.laycan).format('DD MMMM YYYY') : "-"))])]), _c('tr', [_c('th', {
    staticClass: "text-left text-truncate"
  }, [_vm._v("Periode Start")]), _c('th', {
    staticStyle: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    staticClass: "text-left"
  }, [_vm._v(_vm._s((_vm$detailProjectCont11 = _vm.detailProjectContract) !== null && _vm$detailProjectCont11 !== void 0 && _vm$detailProjectCont11.periode_start ? _vm.moment((_vm$detailProjectCont12 = _vm.detailProjectContract) === null || _vm$detailProjectCont12 === void 0 ? void 0 : _vm$detailProjectCont12.periode_start).format('DD MMMM YYYY') : "-"))]), _c('th', {
    staticClass: "text-left text-truncate"
  }, [_vm._v("Periode End")]), _c('th', {
    staticStyle: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    staticClass: "text-left"
  }, [_vm._v(_vm._s((_vm$detailProjectCont13 = _vm.detailProjectContract) !== null && _vm$detailProjectCont13 !== void 0 && _vm$detailProjectCont13.periode_end ? _vm.moment((_vm$detailProjectCont14 = _vm.detailProjectContract) === null || _vm$detailProjectCont14 === void 0 ? void 0 : _vm$detailProjectCont14.periode_end).format('DD MMMM YYYY') : "-"))])]), _c('tr', [_c('th', {
    staticClass: "text-left text-truncate"
  }, [_vm._v("POL")]), _c('th', {
    staticStyle: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    staticClass: "text-left"
  }, _vm._l((_vm$detailProjectCont15 = _vm.detailProjectContract) === null || _vm$detailProjectCont15 === void 0 ? void 0 : _vm$detailProjectCont15.pol, function (state, index) {
    return _c('span', {
      key: index
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.value) + ", ")]);
  }), 0), _c('th', {
    staticClass: "text-left"
  }, [_vm._v("POD")]), _c('th', {
    staticStyle: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    staticClass: "text-left"
  }, _vm._l((_vm$detailProjectCont16 = _vm.detailProjectContract) === null || _vm$detailProjectCont16 === void 0 ? void 0 : _vm$detailProjectCont16.pod, function (state, index) {
    return _c('span', {
      key: index
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.value) + ", ")]);
  }), 0)]), _c('tr', [_c('th', {
    staticClass: "text-left text-truncate"
  }, [_vm._v("Distance")]), _c('th', {
    staticStyle: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    staticClass: "text-left"
  }, [_vm._v(_vm._s((_vm$detailProjectCont17 = _vm.detailProjectContract) === null || _vm$detailProjectCont17 === void 0 ? void 0 : (_vm$detailProjectCont18 = _vm$detailProjectCont17.distance) === null || _vm$detailProjectCont18 === void 0 ? void 0 : _vm$detailProjectCont18.toLocaleString()))]), _c('th', {
    staticClass: "text-left"
  }, [_vm._v("Duration")]), _c('th', {
    staticStyle: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    staticClass: "text-left"
  }, [_vm._v(_vm._s((_vm$detailProjectCont19 = _vm.detailProjectContract) === null || _vm$detailProjectCont19 === void 0 ? void 0 : (_vm$detailProjectCont20 = _vm$detailProjectCont19.duration) === null || _vm$detailProjectCont20 === void 0 ? void 0 : _vm$detailProjectCont20.toLocaleString()))])]), _c('tr', [_c('th', {
    staticClass: "text-left text-truncate"
  }, [_vm._v("Cargo Type")]), _c('th', {
    staticStyle: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    staticClass: "text-left"
  }, [_vm._v(_vm._s((_vm$detailProjectCont21 = _vm.detailProjectContract) === null || _vm$detailProjectCont21 === void 0 ? void 0 : (_vm$detailProjectCont22 = _vm$detailProjectCont21.cargo_type) === null || _vm$detailProjectCont22 === void 0 ? void 0 : _vm$detailProjectCont22.name))]), _c('th', {
    staticClass: "text-left"
  }, [_vm._v("Contract Type")]), _c('th', {
    staticStyle: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    staticClass: "text-left"
  }, [_vm._v(_vm._s((_vm$detailProjectCont23 = _vm.detailProjectContract) === null || _vm$detailProjectCont23 === void 0 ? void 0 : (_vm$detailProjectCont24 = _vm$detailProjectCont23.contract_type) === null || _vm$detailProjectCont24 === void 0 ? void 0 : _vm$detailProjectCont24.name))])]), _c('tr', [_c('th', {
    staticClass: "text-left text-truncate"
  }, [_vm._v("Freigh Rate (Est)")]), _c('th', {
    staticStyle: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    staticClass: "text-left"
  }, [_vm._v(_vm._s(_vm.formatCurrency((_vm$detailProjectCont25 = _vm.detailProjectContract) === null || _vm$detailProjectCont25 === void 0 ? void 0 : _vm$detailProjectCont25.freight_rate_est)) + " "), _c('br'), _vm._v(" "), _c('small', [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(((_vm$detailProjectCont26 = _vm.detailProjectContract) === null || _vm$detailProjectCont26 === void 0 ? void 0 : _vm$detailProjectCont26.ppn) == 'include' ? 'Include PPN' : 'Exclude PPN'))])])]), _c('th', {
    staticClass: "text-left text-truncate"
  }, [_vm._v("Freigh Rate")]), _c('th', {
    staticStyle: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    staticClass: "text-left"
  }, [_vm._v(_vm._s(_vm.formatCurrency((_vm$detailProjectCont27 = _vm.detailProjectContract) === null || _vm$detailProjectCont27 === void 0 ? void 0 : _vm$detailProjectCont27.freight_rate)))])]), _c('tr', [_c('th', {
    staticClass: "text-left"
  }, [_vm._v("Terms")]), _c('th', {
    staticStyle: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    staticClass: "text-left"
  }, [_vm._v(_vm._s((_vm$detailProjectCont28 = _vm.detailProjectContract) === null || _vm$detailProjectCont28 === void 0 ? void 0 : (_vm$detailProjectCont29 = _vm$detailProjectCont28.terms) === null || _vm$detailProjectCont29 === void 0 ? void 0 : _vm$detailProjectCont29.name) + " "), _c('br'), _vm._v(" "), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$detailProjectCont30 = (_vm$detailProjectCont31 = _vm.detailProjectContract) === null || _vm$detailProjectCont31 === void 0 ? void 0 : _vm$detailProjectCont31.terms_remarks) !== null && _vm$detailProjectCont30 !== void 0 ? _vm$detailProjectCont30 : '-'))])]), _c('th', {
    staticClass: "text-left text-truncate"
  }, [_vm._v("Total Quantity")]), _c('th', {
    staticStyle: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    staticClass: "text-left"
  }, [_vm._v(_vm._s((_vm$detailProjectCont32 = _vm.detailProjectContract) === null || _vm$detailProjectCont32 === void 0 ? void 0 : (_vm$detailProjectCont33 = _vm$detailProjectCont32.total_quantity) === null || _vm$detailProjectCont33 === void 0 ? void 0 : _vm$detailProjectCont33.toLocaleString()))])])])])]), _c('div', {
    staticClass: "w-100"
  }, [_c('strong', [_vm._v("Remarks")]), _c('p', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s((_vm$detailProjectCont34 = (_vm$detailProjectCont35 = _vm.detailProjectContract) === null || _vm$detailProjectCont35 === void 0 ? void 0 : _vm$detailProjectCont35.remarks) !== null && _vm$detailProjectCont34 !== void 0 ? _vm$detailProjectCont34 : '-'))])]), _c('div', {
    staticClass: "w-100 d-flex border"
  }), _c('div', {
    staticClass: "d-inline-block w-100 mt-4"
  }, [_c('button', {
    staticClass: "btn btn-warning mr-4",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.onSaveApproval('rejected');
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-ban mr-2"
  }), _vm._v(" Reject")]), _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.onSaveApproval('approved');
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-check mr-2"
  }), _vm._v(" Approve")])])]) : _vm._e(), _vm.approvalDone && !_vm.loading ? _c('div', {
    staticClass: "p-4"
  }, [_c('h3', [_vm._v("Approval Success")]), _c('h1', {
    staticClass: "my-4"
  }, [_c('i', {
    staticClass: "fa fa-check text-success"
  })]), _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.handleRouteToDashboard
    }
  }, [_vm._v("Back to Dashboard")])], 1) : _vm._e(), !_vm.approvalDone && _vm.loading ? _c('div', {
    staticClass: "p-4"
  }, [_c('h4', {
    staticClass: "text-primary"
  }, [_vm._v("Please wait a moment ...")]), _vm._v(" "), _c('br'), _c('i', {
    staticClass: "spinner-border spinner-border-lg text-primary"
  })]) : _vm._e()])], 1)])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }