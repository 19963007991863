var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "8"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("List Master Project Terms")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('b-form', {
          staticClass: "row w-100",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.getBusdevMProjTerms();
            }
          }
        }, [_c('b-col', {
          staticClass: "p-0",
          attrs: {
            "cols": "12",
            "md": "10"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "name",
            "type": "text",
            "placeholder": "Search..."
          },
          model: {
            value: _vm.busdevMProjTerms.params.name,
            callback: function callback($$v) {
              _vm.$set(_vm.busdevMProjTerms.params, "name", $$v);
            },
            expression: "busdevMProjTerms.params.name"
          }
        })], 1), _c('b-col', {
          staticClass: "p-0",
          attrs: {
            "cols": "12",
            "md": "2"
          }
        }, [_c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.busdevMProjTerms._loading ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.busdevMProjTerms.data && _vm.busdevMProjTerms.data.length > 0 ? _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("No")]), _c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Name")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Status")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Remarks")]), _c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.busdevMProjTerms.data, function (p, i) {
          var _p$remarks;
          return _c('tr', {
            key: "p-".concat(i)
          }, [_c('td', [_vm._v(_vm._s(i + 1))]), _c('td', [_vm._v(_vm._s(p.name))]), _c('td', [_vm._v(_vm._s(p.status ? 'Active' : 'Inactive'))]), _c('td', [_vm._v(_vm._s((_p$remarks = p.remarks) !== null && _p$remarks !== void 0 ? _p$remarks : '-'))]), _c('td', [_c('div', {
            staticClass: "d-flex justify-content-center"
          }, [_c('b-button', {
            staticClass: "mr-2",
            attrs: {
              "variant": "success",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.editFormBusdemMProjTerms(p);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]), _c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeleteBusdevMProjTerms(p);
              }
            }
          }, [!_vm.delete_contract_type ? _c('i', {
            staticClass: "fa fa-trash"
          }) : [_c('span', {
            staticClass: "spinner-border spinner-border-sm",
            attrs: {
              "role": "status",
              "aria-hidden": "true"
            }
          }), _vm._v("Deleting... ")]], 2)], 1)])]);
        }), 0)])]) : _vm._e(), _vm.busdevMProjTerms.data && _vm.busdevMProjTerms.data.length > 0 ? _c('div', [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.busdevMProjTerms.meta.totalItem,
            "per-page": _vm.busdevMProjTerms.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getBusdevMProjTerms
          },
          model: {
            value: _vm.busdevMProjTerms.meta.page,
            callback: function callback($$v) {
              _vm.$set(_vm.busdevMProjTerms.meta, "page", $$v);
            },
            expression: "busdevMProjTerms.meta.page"
          }
        })], 1) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("No Project Terms data found.")])]];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('iq-card', {
    class: {
      'border border-danger shadow-lg': _vm.busdev_m_Proj_terms_edit
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v(_vm._s(_vm.busdev_m_Proj_terms_edit ? 'Edit' : 'Add') + " Project Terms")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.onSaveBusdevMProjTerms();
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Name",
            "label-for": "name"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "name",
            "type": "text",
            "placeholder": "Type Name"
          },
          model: {
            value: _vm.formProjTerms.name,
            callback: function callback($$v) {
              _vm.$set(_vm.formProjTerms, "name", $$v);
            },
            expression: "formProjTerms.name"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "status"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.formProjTerms.status,
            callback: function callback($$v) {
              _vm.$set(_vm.formProjTerms, "status", $$v);
            },
            expression: "formProjTerms.status"
          }
        }, [_vm._v(" Inactive / Active ")])], 1), _c('b-form-group', {
          attrs: {
            "label": "Remarks",
            "label-for": "remarks"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "required": "",
            "id": "remarks",
            "type": "textarea",
            "placeholder": "Type Remarks"
          },
          model: {
            value: _vm.formProjTerms.remarks,
            callback: function callback($$v) {
              _vm.$set(_vm.formProjTerms, "remarks", $$v);
            },
            expression: "formProjTerms.remarks"
          }
        })], 1), _c('hr'), _c('b-form-group', {
          staticClass: "text-right"
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [!_vm.busdev_m_Proj_terms_edit ? _c('span', [_vm._v("Add")]) : _c('span', [_vm._v("Save Edit")])]), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "button",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              return _vm.resetformContractType();
            }
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }