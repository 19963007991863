var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "section"
  }, [_c('g-gantt-chart', {
    attrs: {
      "chart-start": _vm.chart2.chartStart,
      "chart-end": _vm.chart2.chartEnd,
      "grid": _vm.chart2.grid,
      "grid-size": _vm.chart2.gridSize,
      "hide-timeaxis": _vm.chart2.hideTimeaxis,
      "push-on-overlap": _vm.chart2.pushOnOverlap,
      "snap-back-on-overlap": "",
      "precision": _vm.chart2.precision,
      "is-magnetic": _vm.chart2.isMagnetic,
      "highlighted-days": _vm.chart2.highlightedDays,
      "row-label-width": _vm.chart2.rowLabelWidth,
      "row-height": _vm.chart2.rowHeight,
      "theme": _vm.theme,
      "width": _vm.chart2.width,
      "height": _vm.chart2.height,
      "allow-add": _vm.chart2.allowAdd
    }
  }, _vm._l(_vm.chart2.rows, function (row) {
    return _c('g-gantt-row', {
      key: row.label,
      attrs: {
        "label": row.label,
        "row-style": row.style,
        "bars": row.bars,
        "highlight-on-hover": _vm.chart2.highlightOnHover
      },
      scopedSlots: _vm._u([{
        key: "row-label",
        fn: function fn() {
          return [_c('span', [_vm._v(_vm._s(row.label))])];
        },
        proxy: true
      }, {
        key: "bar-label",
        fn: function fn(_ref) {
          var bar = _ref.bar;
          return [_c('span', [_vm._v(_vm._s(bar.label))])];
        }
      }], null, true)
    });
  }), 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }