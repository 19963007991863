<template>
  <b-row>
    <b-col md="6" class="d-flex justify-content-between">
      <div class="d-flex">
        <b-form-input size="lg" placeholder="search job name..." v-model="maintenance.params.search"></b-form-input>
        <b-button variant="primary" class="mx-2" @click="$emit('refetch')">
          <i class="fa fa-search"></i>
        </b-button>
        <b-button
          variant="outline-primary"
          class="d-flex align-items-center"
          @click="$emit('toggleFilter')"
        >
          <i class="fas fa-sliders"></i>
          <p class="text-truncate m-0"> Filter Data </p>
        </b-button>
      </div>
    </b-col>
    <b-col md="6">
      <div class="text-right">
        <strong>Display : &nbsp;</strong>
        <div class="btn-group">
          <button
            type="button"
            @click="view_style = 'list'"
            class="btn btn-outline-primary"
            :class="{ 'btn-menu-active': view_style === 'list' }"
            ><span class="fa fa-list"></span
          ></button>
          <button
            type="button"
            @click="view_style = 'calendar'"
            class="btn btn-outline-primary"
            :class="{ 'btn-menu-active': view_style === 'calendar' }"
            ><span class="fa fa-calendar"></span
          ></button>
          <button
            type="button"
            @click="view_style = 'gantt-chart'"
            class="btn btn-outline-primary"
            :class="{ 'btn-menu-active': view_style === 'gantt-chart' }"
            ><span class="fa fa-bars"></span
          ></button>
        </div>
      </div>
    </b-col>
    <b-col md="12" style="padding: 0 30px;" class="my-3">
      <b-row class="border rounded-md pt-2">
          <b-col md="2" v-if="maintenance?.dateRange.length">
            <b-form-group label="Period :">
              <div class="d-flex">
                <p class="mb-0 text-black" style="font-weight: 500;" >{{ maintenance?.dateRange[0] ? moment(maintenance?.dateRange[0]).format("DD MMM YYYY"):'' }} - <span>{{ maintenance?.dateRange[1] ? moment(maintenance?.dateRange[1]).format("DD MMM YYYY"):'' }}</span></p>
              </div>
            </b-form-group>
          </b-col>
          <b-col md="3" v-if="maintenance?.params?.vehicleTypeIds.length">
            <b-form-group label='Fleet Category :'>
              <template >
                <b-badge variant="success" v-for="(type, index) in maintenance?.params?.vehicleTypeIds" :key="index" class="mr-1">{{ type.name }}</b-badge>
              </template>
            </b-form-group>
          </b-col>
          <b-col md="3" v-if="maintenance?.params?.vehicleIds.length">
            <b-form-group label="Fleet Name :">
              <template >
                <b-badge variant="warning" v-for="(type, index) in maintenance?.params?.vehicleIds" :key="index" class="mr-1">{{ type.name }}</b-badge>
              </template>
            </b-form-group>
          </b-col> 
          <b-col md="2" v-if="filterOptionSelected == 'jobClass' && maintenance?.params?.jobClassIds.length">
            <b-form-group label="Job Class :">
              <b-badge variant="danger" v-for="(type, index) in maintenance?.params?.jobClassIds" :key="index" class="mr-1">{{ type.name }}</b-badge>
            </b-form-group>
          </b-col>   
          <b-col md="2" v-if="filterOptionSelected == 'jobType' && maintenance?.params?.jobTypeIds.length">
            <b-form-group label="Job Type :">
              <b-badge variant="danger" v-for="(type, index) in maintenance?.params?.jobTypeIds" :key="index" class="mr-1">{{ type.name }}</b-badge>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Status :">
              <p class="mb-0 text-black" style="font-weight: 500;">{{ maintenance?.params?.status ? statusList.find(el=> el.value == maintenance?.params?.status).text : "All"}}</p>
            </b-form-group>
          </b-col>     
        </b-row>
    </b-col>
    <b-col md="12">
      <template v-if="!maintenance.isLoading">
        <template v-if="view_style === 'calendar'">
          <ProgressCalendar
            :maintenance="maintenance?.data"
            @viewDetailMaintenance="(e) => viewDetailMaintenance(e.id, e.type)"
            @onApprove="onApprove"
            @onReview="onReview"
            :canAdd="canAdd"
            :canApprove="canApprove"
            :canReview="canReview"
          />
        </template>
        <template v-if="view_style === 'list'">
          <ProgressList
            :maintenance="maintenance"
            @refetch="$emit('refetch')"
            @viewDetailMaintenance="(e) => viewDetailMaintenance(e.id, e.type)"
            @onApprove="onApprove"
            @onReview="onReview"
            :canAdd="canAdd"
            :canApprove="canApprove"
            :canReview="canReview"
          />
        </template>
        <template v-if="view_style === 'gantt-chart'">
          <ProgressGanttChart />
        </template>
      </template>
      <template v-else>
        <div class="py-5 w-100 d-flex justify-content-center">
          <b-spinner label="Spinning"></b-spinner>
        </div>
      </template>
    </b-col>
    <!-- Sidebar View -->
    <b-sidebar
      v-model="viewDetailMaintenanceSidebar"
      id="view-detail-maintenance"
      title="View Job Maintenance"
      width="100%"
      backdrop-variant="dark"
      right
      backdrop
      shadow
      header-class="bg-white"
    >
      <template>
        <ViewMaintenanceDetail
          v-if="viewDetailMaintenanceSidebar"
          :isEdit="isEdit"
          @save="closeDetailMaintenance()"
          :maintenance-id="selectedMaintenanceId"
          :action="actionMaintenanceSidebar"
          :onApprove="onApprove"
          :onReview="onReview"
        />
      </template>
    </b-sidebar>
    <!-- End Sidebar View -->
  </b-row>
</template>

<script>
import { maintenanceActions } from '@/src/Utils/helper'
import ProgressCalendar from './Components/ProgressCalendar.vue'
import ProgressGanttChart from './Components/ProgressGanttChart.vue'
import ProgressList from './Components/ProgressList.vue'
import ViewMaintenanceDetail from './ViewMaintenanceDetail.vue'

export default {
  name: 'MaintenanceSummaryListProgress',
  components: {
    ProgressCalendar,
    ProgressGanttChart,
    ProgressList,
    ViewMaintenanceDetail,
  },
  props: {
    filterOptionSelected:{
      type:String,
      default:'jobClass'
    },
    maintenance: {
      type: Object,
      default: () => {
        return {
          data: null,
          meta: null,
          isLoading: false,
        }
      },
    },
    jobClassList: {
      type: Array,
      default: () => [],
    },
    jobTypeList: {
      type: Array,
      default: () => [],
    },
    statusList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      view_style: 'list',
      selectedMaintenanceId: null,
      viewDetailMaintenanceSidebar: false,
      isEdit: false,
      actionMaintenanceSidebar: 'VIEW_DETAIL',
      lsProfile: JSON.parse(localStorage.getItem('profile')),
    }
  },
  methods: {
    ...maintenanceActions,
    onApprove(id, fromDetail) {
      this.$swal({
        title: 'Lakukan Approve Maintenance ?',
        text: `Periksa Kembali Seluruh Data`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Approve',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let payload = {
            id,
          }
          const res = await this.saveApproveMaintenance(payload)

          if (res.status === 'success') {
            this.$swal(`Approve Berhasil`, '', 'success')

            if (fromDetail) {
              this.closeDetailMaintenance()
            } else {
              this.$emit('refetch')
            }
          } else {
            this.$swal(`Oops!`, res.data.message, 'error')
          }
        }
      })
    },
    onReview(id, fromDetail) {
      this.$swal({
        title: 'Lakukan Review Maintenance ?',
        text: `Periksa Kembali Seluruh Data`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Review',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let payload = {
            id,
          }
          const res = await this.saveReviewMaintenance(payload)
          if (res.status === 'success') {
            this.$swal(`Review Berhasil`, '', 'success')
            if (fromDetail) {
              this.closeDetailMaintenance()
            } else {
              this.$emit('refetch')
            }
          } else {
            this.$swal(`Oops!`, res.data.message, 'error')
          }
        }
      })
    },
    viewDetailMaintenance(id, action = 'VIEW_DETAIL', isEdit = false) {
      if (typeof id !== 'undefined' && id) {
        this.selectedMaintenanceId = id
        this.viewDetailMaintenanceSidebar = true
        this.actionMaintenanceSidebar = action
        this.isEdit = isEdit
      }
    },
    async closeDetailMaintenance() {
      this.selectedMaintenanceId = null
      this.viewDetailMaintenanceSidebar = false
      this.$emit('refetch')
    },
  },
  computed: {
    maintenancePermission() {
      return this.lsProfile.menu
        .find((menu) => menu.menu === 'Apps')
        ?.child.find((child) => child.menu === 'Technical')
        ?.child.find((child) => child.menu === 'Maintenance')
    },
    canAdd() {
      return this.maintenancePermission
        ? this.maintenancePermission?.add
        : false
    },
    canReview() {
      return this.maintenancePermission
        ? this.maintenancePermission?.review
        : false
    },
    canApprove() {
      return this.maintenancePermission
        ? this.maintenancePermission?.approval
        : false
    },
  },
  mounted() {},
}
</script>

<style scoped>
.btn-menu:hover {
  background: #0088af !important;
  border: #0088af;
}

.btn-menu-active {
  background: #0088af !important;
  color: white !important;
}
</style>
