var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex w-100 align-items-center justify-content-end"
  }, [_c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": _vm.openSlideCreateEmail
    }
  }, [_c('i', {
    staticClass: "fa fa-ban mr-2"
  }), _vm._v(" Discard ")]), _c('b-button', {
    attrs: {
      "variant": "primary"
    }
  }, [_c('i', {
    staticClass: "fa fa-check"
  }), _vm._v(" Save ")])], 1)])], 1), _c('div', {
    staticClass: "border w-100 d-flex my-4"
  }), _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end w-100"
  }, [_c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "primary"
    }
  }, [_c('i', {
    staticClass: "fa fa-file mr-2"
  }), _vm._v(" Add Context Account ")]), _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.$nextTick(function () {
          _vm.$bvModal.show('preview');
        });
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-eye"
  }), _vm._v(" Preview ")])], 1)]), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Type Text"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Applies to"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Type Text"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "border w-100 d-flex my-4"
  }), _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {}, [_c('tab-nav', {
    staticClass: "d-flex",
    attrs: {
      "pills": true
    }
  }, [_c('tab-nav-items', {
    staticClass: "p-0",
    attrs: {
      "active": true,
      "href": "#content",
      "title": "CONTENT"
    }
  }), _c('tab-nav-items', {
    staticClass: "p-0",
    attrs: {
      "active": false,
      "href": "#email-configuration",
      "title": "EMAIL CONFIGURATION"
    }
  }), _c('tab-nav-items', {
    staticClass: "p-0",
    attrs: {
      "active": false,
      "href": "#advance-setting",
      "title": "ADVANCE SETTING"
    }
  }), _c('tab-nav-items', {
    staticClass: "p-0",
    attrs: {
      "active": false,
      "href": "#placeholder-generator",
      "title": "DYNAMIC PLACEHOLDER GENERATOR"
    }
  })], 1)], 1)]), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "border w-100 d-flex my-4"
  })]), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "pills-tabContent-2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "content"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Subject"
    }
  }, [_c('b-form-input', {
    staticClass: "w-50",
    attrs: {
      "placeholder": "Type Text"
    }
  })], 1), _c('wysiwyg', {
    staticClass: "my-2"
  }), _c('b-form-group', {
    attrs: {
      "label": "Attachment"
    }
  }, [_c('b-form-file', {
    staticClass: "w-50"
  })], 1)], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "email-configuration"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Form"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Override authors email"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Default Recipient"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "type": "checkbox"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "To Emails"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Comma-seperated recipient addresses"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "To Partners"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Comma-seperated ids of recipient partners"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Cc"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Comma-seperated carbon copy recipient addresses"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Reply to"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Preferred reply address"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Qualification URL"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Type Text"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Scheduled Send Date"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Type Text"
    }
  })], 1)], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "advance-setting"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Language"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "${object.partner_id.lang}"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Outgoing Mail Server"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Type Text"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Auto Delete"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "checked": true,
      "type": "checkbox"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Optional report to print and attachr"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Type Text"
    }
  })], 1)], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "placeholder-generator"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Field"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Type Text"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Sub Model"
    }
  }), _c('b-form-group', {
    attrs: {
      "label": "Sub Field"
    }
  }), _c('b-form-group', {
    attrs: {
      "label": "Default Value"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Type Text"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Placeholder Expression"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Type Text"
    }
  })], 1)], 1)], 1)])], 1)], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "preview",
      "size": "xl",
      "title": "Template Preview",
      "cancel-title": "Close",
      "hide-footer": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(" Preview of Test ")]), _c('br'), _c('span', {
    staticClass: "text-muted"
  }, [_vm._v(" Choose an example "), _c('b-button', {
    attrs: {
      "variant": "link"
    }
  }, [_vm._v("Analytic Account")]), _vm._v(" Record: ")], 1), _c('br'), _c('span', [_vm._v("Force a Language:")]), _c('br'), _c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(" Subject ")])]), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('strong', {
    staticClass: "text-warning"
  }, [_vm._v("No record for this model")]), _c('b-form-select', [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("Select options")]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("English (US)")]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("Indonesian / Bahasa Indonesia")])])], 1), _c('div', {
    staticClass: "border w-100 d-flex my-4"
  }), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-button', {
    on: {
      "click": function click($event) {
        _vm.$nextTick(function () {
          _vm.$bvModal.hide('preview');
        });
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }