<template>
  <b-row class="mt-2">
    <b-col md="12">
      <fieldset class="form-group border p-3">
        <legend class="w-auto px-2 text-primary">Forms</legend>
        <table class="table mb-0 table-striped">
          <tbody>
            <tr>
              <th width="20%">Code</th>
              <td width="5%">:</td>
              <td width="25%">{{ detailForm?.code ?? '-' }}</td>
              <th width="20%">Name</th>
              <td width="5%">:</td>
              <td width="25%">{{ detailForm?.name ?? '-' }}</td>
            </tr>
            <tr>
              <th width="20%">Title</th>
              <td width="5%">:</td>
              <td width="25%">{{ detailForm?.title ?? '-' }}</td>
              <th width="20%">Description</th>
              <td width="5%">:</td>
              <td width="25%">{{ detailForm?.description ?? '-' }}</td>
            </tr>
            <tr>
              <th width="20%">Question Type</th>
              <td width="5%">:</td>
              <td width="25%">{{ detailForm?.question_type?.name ?? '-' }}</td>
              <th width="20%">Form for Vehicle</th>
              <td width="5%">:</td>
              <td width="25%">{{ detailForm?.form_for_vehicle ? 'Yes' : 'No' }}</td>
            </tr>
            <tr>
              <th width="20%">Using Remarks</th>
              <td width="5%">:</td>
              <td width="25%">{{ detailForm?.using_remarks ? 'Yes' : 'No' }}</td>
              <th width="20%">Status</th>
              <td width="5%">:</td>
              <td width="25%">{{ detailForm?.status ? 'Active' : 'Inacive' }}</td>
            </tr>
          </tbody>
        </table>
      </fieldset>
    </b-col>
    <b-col :md="'6'">
      <fieldset class="form-group border p-3">
        <legend class="w-auto px-2 text-primary">Field</legend>
        <table class="table mb-0 table-striped">
          <thead>
            <tr>
              <th>Label</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(state, index) in detailForm?.field" :key="index">
              <td>{{ state?.label }}</td>
              <td>{{ state?.type }}</td>
            </tr>
          </tbody>
        </table>
      </fieldset>
    </b-col>
    
    <b-col :md="'6'">
      <fieldset class="form-group border p-3">
        <legend class="w-auto px-2 text-primary">Signature</legend>
        <table class="table mb-0 table-striped">
          <thead>
            <tr>
              <th>Label</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(state, index) in detailForm?.signature" :key="index">
              <td>{{ state?.label }}</td>
              <td>{{ state?.type }}</td>
            </tr>
          </tbody>
        </table>
      </fieldset>
    </b-col>
    <b-col md="12">
      <fieldset class="form-group border p-3">
        <legend class="w-auto px-2 text-primary">Question Type Content</legend>
        <div class="table-responsive" v-if="detailForm?.collection_of_forms_category?.length > 0">
          <table class="table table-striped" v-for="(parentQuestion, parentQuestionIndex) in detailForm.collection_of_forms_category" :key="parentQuestionIndex">
            <thead>
              <tr v-if="!parentQuestion.parent_id && parentQuestion?.inspection_checklist_question?.length <= 0">
                <th colspan="6" class="align-middle text-center">{{ parentQuestion?.name }}</th>
              </tr>
              <tr v-else>
                <th style="max-width:20px">No</th>
                <th>{{parentQuestion?.name }}</th>
              </tr>
            </thead>
            <tbody v-if="parentQuestion?.inspection_checklist_question?.length > 0">
              <tr v-for="(question, questionId) in parentQuestion?.inspection_checklist_question" :key="questionId">
                <td style="max-width:20px">{{ questionId + 1 }}</td>
                <td>{{ question.question }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </fieldset>
    </b-col>
  </b-row>
</template>

<script>
import { busdevActions } from '@src/Utils/helper'
import _ from 'lodash'
export default {
  name: 'SlideDetailForm',
  props:{
    detailForm: {
      type: Object,
      default: null
    },
  },
  data () {
    return {

    }
  },

  async mounted () {
  },
  methods: {
    ...busdevActions,
  }
}
</script>

<style scoped>
</style>
