var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('form', {
    attrs: {
      "action": "#"
    },
    on: {
      "submit": _vm.onSave
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end w-100"
  }, [_c('b-button', {
    staticStyle: {
      "width": "15%"
    },
    attrs: {
      "block": "",
      "variant": "primary",
      "type": "submit"
    }
  }, [_vm.saving ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _c('div', [_c('i', {
    staticClass: "fa fa-save"
  }), _c('span', [_vm._v("Submit Data")])])])], 1)]), _c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "lg": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "* Vendor ID",
      "label-for": "vendor_id"
    }
  }, [_c('b-form-input', {
    attrs: {
      "required": "",
      "id": "vendor_id",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formVendor.vendor_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formVendor, "vendor_id", $$v);
      },
      expression: "formVendor.vendor_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "* Vendor Status",
      "label-for": "vendor_status"
    }
  }, [_c('b-form-select', {
    attrs: {
      "required": "",
      "id": "vendor_status",
      "plain": "",
      "size": "md"
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": ''
          }
        }, [_vm._v("Select status...")]), _c('b-form-select-option', {
          attrs: {
            "value": 'active'
          }
        }, [_vm._v("Active")]), _c('b-form-select-option', {
          attrs: {
            "value": 'on-hold'
          }
        }, [_vm._v("On Hold")]), _c('b-form-select-option', {
          attrs: {
            "value": 'credit-hold'
          }
        }, [_vm._v("Credit Hold")]), _c('b-form-select-option', {
          attrs: {
            "value": 'one-time'
          }
        }, [_vm._v("One-Time")]), _c('b-form-select-option', {
          attrs: {
            "value": 'inactive'
          }
        }, [_vm._v("Inactive")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.formVendor.vendor_status,
      callback: function callback($$v) {
        _vm.$set(_vm.formVendor, "vendor_status", $$v);
      },
      expression: "formVendor.vendor_status"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "* Vendor Class",
      "label-for": "vendor_class_id"
    }
  }, [_c('b-form-select', {
    attrs: {
      "required": "",
      "id": "vendor_class_id",
      "plain": "",
      "size": "md",
      "options": _vm.optionsVendorClass
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("Select status...")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.formVendor.vendor_class_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formVendor, "vendor_class_id", $$v);
      },
      expression: "formVendor.vendor_class_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Vendor Type",
      "label-for": "vendor_type_id"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "vendor_type_id",
      "plain": "",
      "size": "md",
      "options": _vm.optionsVendorType
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("Select type...")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.formVendor.vendor_type_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formVendor, "vendor_type_id", $$v);
      },
      expression: "formVendor.vendor_type_id"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Vendor Category",
      "label-for": "category"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "category",
      "plain": "",
      "size": "md",
      "required": ""
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": ''
          }
        }, [_vm._v("Select category...")]), _c('b-form-select-option', {
          attrs: {
            "value": 'temp'
          }
        }, [_vm._v("Temp")]), _c('b-form-select-option', {
          attrs: {
            "value": 'selected'
          }
        }, [_vm._v("Selected")]), _c('b-form-select-option', {
          attrs: {
            "value": 'rejected'
          }
        }, [_vm._v("Rejected")]), _c('b-form-select-option', {
          attrs: {
            "value": 'none'
          }
        }, [_vm._v("None")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.formVendor.category,
      callback: function callback($$v) {
        _vm.$set(_vm.formVendor, "category", $$v);
      },
      expression: "formVendor.category"
    }
  })], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "nav-fill mb-3",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-2 p-0",
          attrs: {
            "active": true,
            "href": "#general",
            "title": "General"
          },
          nativeOn: {
            "click": function click($event) {
              _vm.tab = 'GENERAL';
            }
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-2 p-0",
          attrs: {
            "active": false,
            "href": "#financial",
            "title": "Financial"
          },
          nativeOn: {
            "click": function click($event) {
              _vm.tab = 'FINANCIAL';
            }
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-2 p-0",
          attrs: {
            "active": false,
            "href": "#vendor-file",
            "title": "Vendor File"
          },
          nativeOn: {
            "click": function click($event) {
              _vm.tab = 'VENDOR_FILE';
            }
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "tab-content"
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "general"
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3 mb-2"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Account Info")]), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "* Account Name",
      "label-for": "name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "name",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formVendor.name,
      callback: function callback($$v) {
        _vm.$set(_vm.formVendor, "name", $$v);
      },
      expression: "formVendor.name"
    }
  })], 1)], 1)], 1)], 1), _c('fieldset', {
    staticClass: "form-group border p-3 mb-2"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Account Address")]), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "* Country",
      "label-for": "country_id"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "text",
      "options": _vm.optionsCountry,
      "reduce": function reduce(type) {
        return type.value;
      },
      "placeholder": "Select country..."
    },
    model: {
      value: _vm.formVendor.country_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formVendor, "country_id", $$v);
      },
      expression: "formVendor.country_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Province",
      "label-for": "province"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "province",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formVendor.province,
      callback: function callback($$v) {
        _vm.$set(_vm.formVendor, "province", $$v);
      },
      expression: "formVendor.province"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "City",
      "label-for": "city"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "city",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formVendor.city,
      callback: function callback($$v) {
        _vm.$set(_vm.formVendor, "city", $$v);
      },
      expression: "formVendor.city"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "District",
      "label-for": "customer_district"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "customer_district",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formVendor.district,
      callback: function callback($$v) {
        _vm.$set(_vm.formVendor, "district", $$v);
      },
      expression: "formVendor.district"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Village",
      "label-for": "village"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "village",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formVendor.village,
      callback: function callback($$v) {
        _vm.$set(_vm.formVendor, "village", $$v);
      },
      expression: "formVendor.village"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Address",
      "label-for": "address"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "address",
      "rows": "4",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formVendor.address,
      callback: function callback($$v) {
        _vm.$set(_vm.formVendor, "address", $$v);
      },
      expression: "formVendor.address"
    }
  })], 1)], 1)], 1)], 1), _c('fieldset', {
    staticClass: "form-group border p-3 mb-2"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Additional Account Info")]), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Logo",
      "label-for": "logo"
    }
  }, [_c('b-form-file', {
    attrs: {
      "id": "file",
      "accept": "image/*"
    },
    model: {
      value: _vm.formVendor.logo,
      callback: function callback($$v) {
        _vm.$set(_vm.formVendor, "logo", $$v);
      },
      expression: "formVendor.logo"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Web",
      "label-for": "web"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "web",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formVendor.web,
      callback: function callback($$v) {
        _vm.$set(_vm.formVendor, "web", $$v);
      },
      expression: "formVendor.web"
    }
  })], 1)], 1)], 1)], 1)]), _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Primary Contact")]), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fullname",
      "label-for": "fullname"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "fullname",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formVendor.fullname,
      callback: function callback($$v) {
        _vm.$set(_vm.formVendor, "fullname", $$v);
      },
      expression: "formVendor.fullname"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Job Title",
      "label-for": "pic_job_title"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "pic_job_title",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formVendor.pic_job_title,
      callback: function callback($$v) {
        _vm.$set(_vm.formVendor, "pic_job_title", $$v);
      },
      expression: "formVendor.pic_job_title"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Email",
      "label-for": "pic_email"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "pic_email",
      "type": "email",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formVendor.pic_email,
      callback: function callback($$v) {
        _vm.$set(_vm.formVendor, "pic_email", $$v);
      },
      expression: "formVendor.pic_email"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Phone",
      "label-for": "pic_phone"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "pic_phone",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formVendor.pic_phone,
      callback: function callback($$v) {
        _vm.$set(_vm.formVendor, "pic_phone", $$v);
      },
      expression: "formVendor.pic_phone"
    }
  })], 1)], 1)], 1)], 1), _c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Vendor Properties")]), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.formVendor.landed_cost_vendor,
      callback: function callback($$v) {
        _vm.$set(_vm.formVendor, "landed_cost_vendor", $$v);
      },
      expression: "formVendor.landed_cost_vendor"
    }
  }, [_vm._v(" Landed Cost Vendor ")])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.formVendor.vendor_tax_agency,
      callback: function callback($$v) {
        _vm.$set(_vm.formVendor, "vendor_tax_agency", $$v);
      },
      expression: "formVendor.vendor_tax_agency"
    }
  }, [_vm._v(" Vendor is Tax Agency ")])], 1)], 1)], 1)], 1), _c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Other Info")]), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "NPWP Number",
      "label-for": "npwp_number"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "npwp_number",
      "type": "number",
      "placeholder": "Type number"
    },
    model: {
      value: _vm.formVendor.npwp_number,
      callback: function callback($$v) {
        _vm.$set(_vm.formVendor, "npwp_number", $$v);
      },
      expression: "formVendor.npwp_number"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "SKTD Number",
      "label-for": "sktd_number"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "sktd_number",
      "type": "number",
      "placeholder": "Type number"
    },
    model: {
      value: _vm.formVendor.sktd_number,
      callback: function callback($$v) {
        _vm.$set(_vm.formVendor, "sktd_number", $$v);
      },
      expression: "formVendor.sktd_number"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "NIB Number",
      "label-for": "nib_number"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "nib_number",
      "type": "number",
      "placeholder": "Type number"
    },
    model: {
      value: _vm.formVendor.nib_number,
      callback: function callback($$v) {
        _vm.$set(_vm.formVendor, "nib_number", $$v);
      },
      expression: "formVendor.nib_number"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Bank",
      "label-for": "bank_id"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "text",
      "options": _vm.optionsBank,
      "reduce": function reduce(type) {
        return type.value;
      },
      "placeholder": "Select bank..."
    },
    model: {
      value: _vm.formVendor.bank_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formVendor, "bank_id", $$v);
      },
      expression: "formVendor.bank_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Bank Account Name",
      "label-for": "bank_account_name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "bank_account_name",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formVendor.bank_account_name,
      callback: function callback($$v) {
        _vm.$set(_vm.formVendor, "bank_account_name", $$v);
      },
      expression: "formVendor.bank_account_name"
    }
  })], 1)], 1)], 1)], 1)])], 1)]), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "financial"
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3 mb-2"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Financial Settings")]), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Terms",
      "label-for": "terms_id"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "terms_id",
      "plain": "",
      "size": "md",
      "options": _vm.optionsTerms
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("Select terms....")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.formVendor.terms_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formVendor, "terms_id", $$v);
      },
      expression: "formVendor.terms_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Restict Visibility To",
      "label-for": "restrict_visibility_to"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "restrict_visibility_to",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.formVendor.restrict_visibility_to,
      callback: function callback($$v) {
        _vm.$set(_vm.formVendor, "restrict_visibility_to", $$v);
      },
      expression: "formVendor.restrict_visibility_to"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Currency ID",
      "label-for": "currency_id"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "currency_id",
      "plain": "",
      "size": "md",
      "options": _vm.optionsCurrency
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("Select Currency....")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.formVendor.currency_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formVendor, "currency_id", $$v);
      },
      expression: "formVendor.currency_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Enable Currency Override"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.formVendor.enable_currency_override,
      callback: function callback($$v) {
        _vm.$set(_vm.formVendor, "enable_currency_override", $$v);
      },
      expression: "formVendor.enable_currency_override"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Curr. Rate Type",
      "label-for": "currency_rate_type_id"
    }
  }, [_c('b-form-select', {
    attrs: {
      "id": "currency_rate_type_id",
      "plain": "",
      "size": "md"
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("Select Currency Rate Type....")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.formVendor.currency_rate_type_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formVendor, "currency_rate_type_id", $$v);
      },
      expression: "formVendor.currency_rate_type_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Enable Rate Override"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.formVendor.enable_rate_override,
      callback: function callback($$v) {
        _vm.$set(_vm.formVendor, "enable_rate_override", $$v);
      },
      expression: "formVendor.enable_rate_override"
    }
  })], 1)], 1)], 1)], 1)])], 1)]), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "vendor-file"
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3 mb-2"
  }, [_c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('a', {
    staticClass: "text-primary mr-4",
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "role": "button"
    },
    on: {
      "click": _vm.addRowVendor
    }
  }, [_c('i', {
    staticClass: "fa fa-plus mr-2"
  }), _c('span', [_vm._v("Add Row")])])])])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "overflow-auto"
  }, [_c('table', {
    staticClass: "table table-striped table-bordered"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center align-middle",
    staticStyle: {
      "width": "30px"
    }
  }, [_vm._v("#")]), _c('th', {
    staticClass: "text-center align-middle",
    staticStyle: {
      "width": "20px"
    }
  }, [_vm._v("No")]), _c('th', {
    staticClass: "text-left align-middle",
    staticStyle: {
      "min-width": "300px"
    }
  }, [_vm._v("Document List")]), _c('th', {
    staticClass: "text-left align-middle",
    staticStyle: {
      "min-width": "150px"
    }
  }, [_vm._v("File")])])]), _c('tbody', _vm._l(_vm.formVendorFile, function (state, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_c('i', {
      staticClass: "fa fa-trash text-danger",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.formVendorFile.splice(index, 1);
        }
      }
    })]), _c('td', {
      staticStyle: {
        "width": "20px"
      }
    }, [_vm._v(_vm._s(index + 1))]), _c('td', [_c('b-form-select', {
      attrs: {
        "id": "customer_status",
        "plain": "",
        "required": "",
        "size": "md",
        "options": _vm.optionsDocumentList
      },
      scopedSlots: _vm._u([{
        key: "first",
        fn: function fn() {
          return [_c('b-form-select-option', {
            attrs: {
              "value": null
            }
          }, [_vm._v("Select Document...")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: state.document_list_id,
        callback: function callback($$v) {
          _vm.$set(state, "document_list_id", $$v);
        },
        expression: "state.document_list_id"
      }
    })], 1), _c('td', [_c('b-form-group', [_c('b-form-file', {
      staticClass: "form-control",
      attrs: {
        "plain": "",
        "id": "file",
        "accept": "image/*,application/*",
        "multiple": ""
      },
      model: {
        value: state.file,
        callback: function callback($$v) {
          _vm.$set(state, "file", $$v);
        },
        expression: "state.file"
      }
    })], 1)], 1)]);
  }), 0)])])])], 1)], 1)])], 1)])], 1)])], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }