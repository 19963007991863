<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">
              <strong>SERVICE & REPAIR DASHBOARD</strong>
            </h4>
          </template>
          <template v-slot:headerAction>
            <a class="text-primary float-right" v-b-toggle.collapse-filter role="button" style="cursor: pointer">
              <i class="fa fa-filter" />
            </a>
          </template>
          <template v-slot:body>
            <b-collapse visible id="collapse-filter" class="mb-2">
              <b-row>
                <b-col md="3">
                  <v-multiselect v-model="fleetCategorySelect" tag-placeholder="Category" placeholder="Fleet Category" label="text" track-by="value" :options="fleetCategory" :multiple="true" :taggable="true"></v-multiselect>
                </b-col>
                <b-col md="2">
                  <b-form-select plain v-model="selectedFleets" :options="kapal" size="lg">
                    <template v-slot:first>
                      <b-form-select-option :value="null">Select Fleet</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-col>
                <b-col md="3">
                  <b-form-select plain v-model="selectedStatus" :options="optionsStatus" size="lg">
                    <template v-slot:first>
                      <b-form-select-option :value="null">Select Status</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-col>
                <b-col md="3">
                  <date-picker :lang="lang" v-model="dateRange" type="date" range placeholder="Select date range"></date-picker>
                </b-col>
                <b-col md="1">
                  <b-button variant="primary" class="mb-3 mr-1"><i class="fa fa-filter"></i></b-button>
                </b-col>
              </b-row>
            </b-collapse>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items class="col-md-4 p-0" :active="true" href="#request" title="REQUEST & PROGRESS" />
                <tab-nav-items class="col-md-4 p-0" :active="false" href="#finish" title="FINISH REPAIR & MAINTENANCE" />
                <tab-nav-items class="col-md-4 p-0" :active="false" href="#report" title="REPORT REPAIR & MAINTENANCE" />
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="12">
        <div class="iq-edit-list-data">
          <div id="pills-tabContent-2" class="tab-content">
            <tab-content-item :active="true" id="request">
              <iq-card>
                <template v-slot:headerTitle>
                  <h5 class="card-title text-primary">
                    <strong>REQUEST REPAIR & MAINTENANCE</strong>
                  </h5>
                </template>
                <template v-slot:headerAction>
                  <b-button block variant="primary" v-b-modal.add-init @click="showInitModal = !showInitModal">
                    <i class="fa fa-plus"></i> &nbsp; Add Data
                  </b-button>
                  <!-- Modal -->
                  <b-modal v-model="showInitModal" id="add-init" size="xl" title="ADD REPAIR & MAINTENANCE" header-bg-variant="light" header-text-variant="dark" footer-bg-variant="light" footer-text-variant="dark">
                    <b-row class="mb-4">
                      <b-col md="4" class="text-right">
                        <b-form-input type="text" placeholder="Search fleets..." v-model="fleet_params.search" @input="fetchFleets"></b-form-input>
                      </b-col>
                    </b-row>
                    <div v-if="loading_fleet" class="text-center my-5">
                      <b-spinner type="grow" label="Spinning"></b-spinner>
                    </div>
                    <template v-else>
                      <b-row v-if="fleets.length > 0">
                        <b-col md="3" class="text-center py-5 border-top" v-for="(fleet, i) in fleets" :key="`fleet-${i}`">
                          <div class="custom-control custom-checkbox image-checkbox">
                            <input :id="`ck-fl-${i}`" type="checkbox" class="custom-control-input" :value="fleet.id" v-model="checkedFleets" />
                            <label class="custom-control-label" :for="`ck-fl-${i}`">
                              <img :src="
                                fleet.vehicleType.icon.url
                                  | checkPhoto(
                                    require('@src/assets/images/fleet/PusherBarge.png')
                                  )
                              " alt="#" class="img-fluid" />
                            </label>
                            <small>
                              <strong>{{ fleet.name }}</strong>
                            </small>
                          </div>
                        </b-col>
                      </b-row>
                      <div v-else class="text-center my-5">
                        <p class="mb-0 text-muted">No fleets data found.</p>
                      </div>
                    </template>
                    <template #modal-footer="{ ok, cancel, hide }">
                      <b-row>
                        <b-col md="12" class="text-center">
                          <b-button variant="none" class="iq-bg-danger text-right" @click="cancel()">Cancel</b-button>
                          <b-button type="submit" variant="primary" @click="showInitModalNext = !showInitModalNext;showInitModal = !showInitModal" class="ml-2 text-right">Next <i class="fa fa-arrow-right"></i>
                          </b-button>
                        </b-col>
                      </b-row>
                    </template>
                  </b-modal>
                </template>
                <template v-slot:body>
                  <table class="table table-striped table-sm">
                    <thead>
                      <tr>
                        <th class="text-center">FLEET&nbsp;NAME</th>
                        <th class="text-center">LOCATION</th>
                        <th class="text-center">TANGGAL</th>
                        <th class="text-center">TOTAL REPAIR</th>
                        <th class="text-center">CLASSIFICATION</th>
                        <th class="text-center">OFFICE/PIC</th>
                        <th class="text-center">STATUS</th>
                        <th class="text-center">ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-center">TB. TCP 201</td>
                        <td class="text-center">Lubuk Tutung</td>
                        <td class="text-center">05-Jul-2021</td>
                        <td class="text-center">11 Repairs</td>
                        <td class="text-center">
                          <b-badge variant="success d-block" class="mb-1">Minor : 2 Repair</b-badge>
                          <b-badge variant="info d-block" class="mb-1">Medium : 6 Repair</b-badge>
                          <b-badge variant="danger d-block">Major : 3 Repair</b-badge>
                        </td>
                        <td class="text-center"> Asam Asam Office <br>
                          <small>PIC : <strong>Darmawan</strong>
                          </small>
                        </td>
                        <td class="text-center">
                          <b-badge variant="info d-block">Review</b-badge>
                        </td>
                        <td class="text-center">
                          <b-dropdown size="lg" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                            <template v-slot:button-content>
                              <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                              </span>
                            </template>
                            <b-dropdown-item href="#" v-b-toggle.review_sr>
                              <i class="fa fa-eye mr-2"></i>Review
                            </b-dropdown-item>
                            <b-dropdown-item href="#" v-b-toggle.update_sr>
                              <i class="fa fa-pencil-square mr-2"></i>Update Data
                            </b-dropdown-item>
                            <b-dropdown-item href="#" v-b-toggle.add-job>
                              <i class="fa fa-plus mr-2"></i>Add Job
                            </b-dropdown-item>
                            <b-dropdown-item href="#" v-b-toggle.detail_sr>
                              <i class="fa fa-info-circle mr-2"></i>Detail
                            </b-dropdown-item>
                            <b-dropdown-item href="#" v-b-modal.reject-modal>
                              <i class="fa fa-remove mr-2"></i>Reject R & M
                            </b-dropdown-item>
                          </b-dropdown>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">PT. TCP 207</td>
                        <td class="text-center">Celukan Bawang</td>
                        <td class="text-center">21-Oct-2022</td>
                        <td class="text-center">12 Repairs</td>
                        <td class="text-center">
                          <b-badge variant="success d-block" class="mb-1">Minor : 10 Repair</b-badge>
                          <b-badge variant="info d-block" class="mb-1">Medium : 2 Repair</b-badge>
                          <b-badge variant="danger d-block">Major : 0 Repair</b-badge>
                        </td>
                        <td class="text-center"> Sangatta Office <br>
                          <small>PIC : <strong>Hendra Ridwan</strong>
                          </small>
                        </td>
                        <td class="text-center">
                          <b-badge variant="info d-block">Review</b-badge>
                        </td>
                        <td class="text-center">
                          <b-dropdown size="lg" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                            <template v-slot:button-content>
                              <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                              </span>
                            </template>
                            <b-dropdown-item href="#" v-b-toggle.review_sr>
                              <i class="fa fa-eye mr-2"></i>Review
                            </b-dropdown-item>
                            <b-dropdown-item href="#" v-b-toggle.update_sr>
                              <i class="fa fa-pencil-square mr-2"></i>Update Data
                            </b-dropdown-item>
                            <b-dropdown-item href="#" v-b-toggle.add-job>
                              <i class="fa fa-plus mr-2"></i>Add Job
                            </b-dropdown-item>
                            <b-dropdown-item href="#" v-b-toggle.detail_sr>
                              <i class="fa fa-info-circle mr-2"></i>Detail
                            </b-dropdown-item>
                            <b-dropdown-item href="#" v-b-modal.reject-modal>
                              <i class="fa fa-remove mr-2"></i>Reject R & M
                            </b-dropdown-item>
                          </b-dropdown>
                        </td>
                      </tr>
                      <tr>
                        <td class="text-center">PT. ETI 301</td>
                        <td class="text-center">Sangatta</td>
                        <td class="text-center">02-Feb-2022</td>
                        <td class="text-center">8 Repairs</td>
                        <td class="text-center">
                          <b-badge variant="success d-block" class="mb-1">Minor : 5 Repair</b-badge>
                          <b-badge variant="info d-block" class="mb-1">Medium : 2 Repair</b-badge>
                          <b-badge variant="danger d-block">Major : 1 Repair</b-badge>
                        </td>
                        <td class="text-center"> Kendari Office <br>
                          <small>PIC : <strong>Nurul Akbar</strong>
                          </small>
                        </td>
                        <td class="text-center">
                          <b-badge variant="primary d-block">Approve</b-badge>
                        </td>
                        <td class="text-center">
                          <b-dropdown size="lg" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                            <template v-slot:button-content>
                              <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                              </span>
                            </template>
                            <b-dropdown-item href="#" v-b-toggle.approve_sr>
                              <i class="fa fa-check mr-2"></i>Approve
                            </b-dropdown-item>
                            <b-dropdown-item href="#" v-b-toggle.add-job>
                              <i class="fa fa-plus mr-2"></i>Add Job
                            </b-dropdown-item>
                            <b-dropdown-item href="#" v-b-toggle.detail_sr>
                              <i class="fa fa-info-circle mr-2"></i>Detail
                            </b-dropdown-item>
                            <b-dropdown-item href="#" v-b-modal.reject-modal>
                              <i class="fa fa-remove mr-2"></i>Reject R & M
                            </b-dropdown-item>
                          </b-dropdown>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="mt-3">
                    <b-pagination :value="1" :total-rows="50" align="center" />
                  </div>
                  <!-- SLIDE CREATE R & M DATA -->
                  <b-sidebar v-model="showInitModalNext" id="add-sr" title="ADD REPAIR & MAINTENANCE - TB. TCP 201" width="100%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
                    <template #default="{hide}">
                      <b-row class="m-3">
                        <b-col cols="12">
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Engine Information</legend>
                            <table class="table mb-0 table-striped">
                              <tbody>
                                <tr>
                                  <th>Main Engine 1 - Yanmar 12LAK</th>
                                  <td class="text-right">Actual RH : <strong>2.800 RH</strong>
                                  </td>
                                </tr>
                                <tr>
                                  <th>Main Engine 1 - Yanmar 12LAK</th>
                                  <td class="text-right">Actual RH : <strong>2.800 RH</strong>
                                  </td>
                                </tr>
                                <tr>
                                  <th>Auxuauxiliary Engine 1 - Cummin KW32</th>
                                  <td class="text-right">Actual RH : <strong>1.800 RH</strong>
                                  </td>
                                </tr>
                                <tr>
                                  <th>Auxuauxiliary Engine 1 - Cummin KW32</th>
                                  <td class="text-right">Actual RH : <strong>1.800 RH</strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </fieldset>
                        </b-col>
                        <b-col cols="12">
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Service & Repair Form</legend>
                            <b-row>
                              <b-col md="10">
                                <b-form-input id="job_name" type="text" placeholder="Type Text ..."></b-form-input>
                              </b-col>
                              <b-col md="2">
                                <b-button variant="success" block>SEARCH</b-button>
                              </b-col>
                              <b-col md="4">
                                <v-jstree :data="tree" show-checkbox allow-batch whole-row :item-events="itemEvents" @item-click="itemClick" class="mt-3">
                                  <template slot-scope="_">
                                    <div style="display: inherit; width: 200px" @click.ctrl="customItemClickWithCtrl">
                                      <i :class="_.vm.themeIconClasses" role="presentation" v-if="!_.model.loading"></i>
                                      {{_.model.text}}
                                    </div>
                                  </template>
                                </v-jstree>
                              </b-col>
                              <b-col md="8">
                                <b-row class="mt-3">
                                  <b-col md="12">
                                    <b-alert :show="true" variant="primary">
                                      <div class="custom-control custom-control-inline">
                                        <label>
                                          <b>SELECT JOB FROM :</b>
                                        </label>
                                      </div>
                                      <div class="custom-control custom-control-inline custom-radio">
                                        <input type="radio" name="number" class="custom-control-input" value="one" id="fleet_equipment">
                                        <label class="custom-control-label" for="fleet_equipment">From Equipment</label>
                                      </div>
                                      <div class="custom-control custom-control-inline custom-radio">
                                        <input type="radio" name="number" class="custom-control-input" value="two" id="general_sr">
                                        <label class="custom-control-label" for="general_sr">Custom</label>
                                      </div>
                                    </b-alert>
                                  </b-col>
                                  <b-col md="12">
                                    <fieldset class="form-group border p-3">
                                      <legend class="w-auto px-2 text-primary">Detail Information</legend>
                                      <table class="table mb-0 table-striped">
                                        <tbody>
                                          <tr>
                                            <th width="20%">Lokasi Pekerjaan</th>
                                            <td width="5%">:</td>
                                            <td width="25%">
                                              <b-form-input type="text" placeholder="Type Text ..."></b-form-input>
                                            </td>
                                            <th width="20%">Tanggal</th>
                                            <td width="5%">:</td>
                                            <td width="25%">
                                              <b-form-input type="date"></b-form-input>
                                            </td>
                                          </tr>
                                          <tr>
                                            <th width="20%">Site & Group</th>
                                            <td width="5%">:</td>
                                            <td width="25%">
                                              <b-form-input type="text" placeholder="Type Text ..."></b-form-input>
                                            </td>
                                            <th width="20%">P.I.C</th>
                                            <td width="5%">:</td>
                                            <td width="25%">
                                              <b-form-input type="text" placeholder="Type Text ..."></b-form-input>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </fieldset>
                                    <hr>
                                    <fieldset class="form-group border p-3">
                                      <legend class="w-auto px-2 text-primary">Repair #1</legend>
                                      <b-form-row>
                                        <b-col>
                                          <b-form-group label="Equipment Name" label-for="equipmentName">
                                            <b-form-input type="text" placeholder="Type Text ..."></b-form-input>
                                          </b-form-group>
                                        </b-col>
                                        <b-col>
                                          <b-form-group label="Work Item" label-for="repairTitle">
                                            <b-form-input type="text" placeholder="Type Text ..."></b-form-input>
                                          </b-form-group>
                                        </b-col>
                                        <b-col>
                                          <b-form-group label="Type R & M *" label-for="type_repair">
                                            <b-form-select plain v-model="selectedTypeRM" :options="optionsTypeRM" size="lg">
                                              <template v-slot:first>
                                                <b-form-select-option :value="null">Select Type R & M</b-form-select-option>
                                              </template>
                                            </b-form-select>
                                          </b-form-group>
                                        </b-col>
                                      </b-form-row>
                                      <b-form-row>
                                        <b-col>
                                          <b-form-group label="Department*" label-for="department">
                                            <b-form-select plain v-model="selectedDepartment" :options="optionsDepartment" size="lg">
                                              <template v-slot:first>
                                                <b-form-select-option :value="null">Select Department</b-form-select-option>
                                              </template>
                                            </b-form-select>
                                          </b-form-group>
                                        </b-col>
                                        <b-col>
                                          <b-form-group label="Category *" label-for="category">
                                            <b-form-select plain v-model="selectedCategory" :options="category" size="lg">
                                              <template v-slot:first>
                                                <b-form-select-option :value="null">Select Category</b-form-select-option>
                                              </template>
                                            </b-form-select>
                                          </b-form-group>
                                        </b-col>
                                        <b-col>
                                          <b-form-group label="Clasification *" label-for="clasification">
                                            <b-form-select plain v-model="selectedClasification" :options="clasification" size="lg">
                                              <template v-slot:first>
                                                <b-form-select-option :value="null">Select Clasification</b-form-select-option>
                                              </template>
                                            </b-form-select>
                                          </b-form-group>
                                        </b-col>
                                      </b-form-row>
                                      <b-form-row>
                                        <b-col>
                                          <b-form-group label="Cause *" label-for="cause">
                                            <b-form-select plain v-model="selectedCause" :options="optionsCause" size="lg">
                                              <template v-slot:first>
                                                <b-form-select-option :value="null">Select Cause</b-form-select-option>
                                              </template>
                                            </b-form-select>
                                          </b-form-group>
                                        </b-col>
                                        <b-col>
                                          <b-form-group label="Kind of Task *" label-for="kind_of_task">
                                            <b-form-select plain v-model="selectedKindTask" :options="kindTask" size="lg">
                                              <template v-slot:first>
                                                <b-form-select-option :value="null">Select Kind of Task</b-form-select-option>
                                              </template>
                                            </b-form-select>
                                          </b-form-group>
                                        </b-col>
                                        <b-col>
                                          <b-form-group label="Berita Acara" label-for="attactment_file">
                                            <b-form-file plain id="attactment_file"></b-form-file>
                                          </b-form-group>
                                        </b-col>
                                      </b-form-row>
                                      <b-form-row>
                                        <b-col>
                                          <b-form-group label="Rencana Mulai *" label-for="rencana_mulai">
                                            <b-form-input type="date" id="rencana_mulai"></b-form-input>
                                          </b-form-group>
                                        </b-col>
                                        <b-col>
                                          <b-form-group label="Rencana Selesai *" label-for="rencana_selesai">
                                            <b-form-input type="date" id="rencana_selesai"></b-form-input>
                                          </b-form-group>
                                        </b-col>
                                        <b-col>
                                          <b-form-group label="Estimasi Hari" label-for="estimasi_hari">
                                            <b-button variant="outline-primary" class="mb-1">
                                              Perkiraan Pekerjaan <b-badge variant="primary" class="ml-2">7 Hari</b-badge>
                                            </b-button>
                                          </b-form-group>
                                        </b-col>
                                      </b-form-row>
                                      <b-form-row>
                                        <b-col>
                                          <b-form-group label="Description" label-for="description">
                                            <b-form-textarea id="description" rows="3" placeholder="Type Text ..."></b-form-textarea>
                                          </b-form-group>
                                        </b-col>
                                      </b-form-row>
                                      <b-row class="mt-2">
                                        <b-col md="12">
                                          <b-button variant="primary" class="pull-right" size="sm">
                                            <i class="fa fa-trash"></i> Delete Repair Job
                                          </b-button>
                                        </b-col>
                                      </b-row>
                                    </fieldset>
                                    <b-button variant="success" block>
                                      <i class="fa fa-plus"></i> ADD JOB REPAIR & MAINTENANCE
                                    </b-button>
                                  </b-col>
                                  <b-col md="12" class="text-right mt-2">
                                    <b-card-text>
                                      <small class="text-muted">Sign * : <b-badge variant="warning">Mandatory (Must be Input)</b-badge>
                                      </small>
                                    </b-card-text>
                                    <b-button type="submit" variant="primary">
                                      <i class="fa fa-save"></i> Save
                                    </b-button>
                                    <b-button type="submit" variant="none" class="iq-bg-danger ml-3">
                                      <i class="fa fa-close"></i> Reset Form
                                    </b-button>
                                  </b-col>
                                </b-row>
                              </b-col>
                            </b-row>
                          </fieldset>
                        </b-col>
                      </b-row>
                    </template>
                  </b-sidebar>
                  <!-- SLIDE END -->
                  <!-- SLIDE TASK R & M DATA -->
                  <b-sidebar id="add-job" title="ADD JOB REPAIR & MAINTENANCE - TB. TCP 201" width="100%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
                    <template #default="{hide}">
                      <b-row class="m-3">
                        <b-col cols="12">
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Engine Information</legend>
                            <table class="table mb-0 table-striped">
                              <tbody>
                                <tr>
                                  <th>Main Engine 1 - Yanmar 12LAK</th>
                                  <td class="text-right">Actual RH : <strong>2.800 RH</strong>
                                  </td>
                                </tr>
                                <tr>
                                  <th>Main Engine 1 - Yanmar 12LAK</th>
                                  <td class="text-right">Actual RH : <strong>2.800 RH</strong>
                                  </td>
                                </tr>
                                <tr>
                                  <th>Auxuauxiliary Engine 1 - Cummin KW32</th>
                                  <td class="text-right">Actual RH : <strong>1.800 RH</strong>
                                  </td>
                                </tr>
                                <tr>
                                  <th>Auxuauxiliary Engine 1 - Cummin KW32</th>
                                  <td class="text-right">Actual RH : <strong>1.800 RH</strong>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </fieldset>
                        </b-col>
                        <b-col cols="12">
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Service & Repair Form</legend>
                            <b-row>
                              <b-col md="10">
                                <b-form-input id="job_name" type="text" placeholder="Type Text ..."></b-form-input>
                              </b-col>
                              <b-col md="2">
                                <b-button variant="success" block>SEARCH</b-button>
                              </b-col>
                              <b-col md="4">
                                <v-jstree :data="tree" show-checkbox allow-batch whole-row :item-events="itemEvents" @item-click="itemClick" class="mt-3">
                                  <template slot-scope="_">
                                    <div style="display: inherit; width: 200px" @click.ctrl="customItemClickWithCtrl">
                                      <i :class="_.vm.themeIconClasses" role="presentation" v-if="!_.model.loading"></i>
                                      {{_.model.text}}
                                    </div>
                                  </template>
                                </v-jstree>
                              </b-col>
                              <b-col md="8">
                                <b-row class="mt-3">
                                  <b-col md="12">
                                    <b-alert :show="true" variant="primary">
                                      <div class="custom-control custom-control-inline">
                                        <label>
                                          <b>SELECT JOB FROM :</b>
                                        </label>
                                      </div>
                                      <div class="custom-control custom-control-inline custom-radio">
                                        <input type="radio" name="number" class="custom-control-input" value="one" id="fleet_equipment">
                                        <label class="custom-control-label" for="fleet_equipment">From Equipment</label>
                                      </div>
                                      <div class="custom-control custom-control-inline custom-radio">
                                        <input type="radio" name="number" class="custom-control-input" value="two" id="general_sr">
                                        <label class="custom-control-label" for="general_sr">Custom</label>
                                      </div>
                                    </b-alert>
                                  </b-col>
                                  <b-col md="12">
                                    <fieldset class="form-group border p-3">
                                      <legend class="w-auto px-2 text-primary">Detail Information</legend>
                                      <table class="table mb-0 table-striped">
                                        <tbody>
                                          <tr>
                                            <th width="20%">Lokasi Pekerjaan</th>
                                            <td width="5%">:</td>
                                            <td width="25%">Morosi</td>
                                            <th width="20%">Tanggal</th>
                                            <td width="5%">:</td>
                                            <td width="25%">15 Juni 2022</td>
                                          </tr>
                                          <tr>
                                            <th width="20%">Office / Site</th>
                                            <td width="5%">:</td>
                                            <td width="25%">Kendari</td>
                                            <th width="20%">PIC Repair</th>
                                            <td width="5%">:</td>
                                            <td width="25%">Sangatta</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </fieldset>
                                    <div class="accordion mb-3" role="tablist">
                                      <b-card no-body>
                                        <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none">
                                          <b-button block v-b-toggle.job-1-1 variant="info" class="text-left">
                                            <i class="fa fa-chevron-circle-down"></i> FUEL INJECTOR - PEBAIKAN KEBOCORAN FUEL INJECTOR  <strong>(REVIEW)</strong>
                                          </b-button>
                                        </b-card-header>
                                        <b-collapse id="job-1-1" accordion="my-accordion-sub" role="tabpanel">
                                          <table class="table table-striped mt-3">
                                            <tbody>
                                              <tr>
                                                <th width="20%">Equipment Name</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Fuel Injector</td>
                                                <th width="20%">Repair Title</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Perbaikan Kebocoran Fuel Injector</td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Type R & M</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Running Maintenance</td>
                                                <th width="20%">Department</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Engine</td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Category</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Mechanical</td>
                                                <th width="20%">Classification</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Minor (3 Days)</td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Cause</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Material Fatique</td>
                                                <th width="20%">Kind Task</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Repair</td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Rencana Mulai</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">12 Januari 2023</td>
                                                <th width="20%">Rencana Selesai (Est)</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">15 Januari 2023</td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Berita Acara</th>
                                                <td class="text-center" width="5%">:</td>
                                                <td class="text-center align-top" colspan="4">
                                                  <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                    <li class="col-md-1 col-1">
                                                      <a href="javascript:void(0);">
                                                        <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                                      </a>
                                                    </li>
                                                    <li class="col-md-1 col-1">
                                                      <a href="javascript:void(0);">
                                                        <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                                      </a>
                                                    </li>
                                                    <li class="col-md-1 col-1">
                                                      <a href="javascript:void(0);">
                                                        <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                                      </a>
                                                    </li>
                                                    <li class="col-md-1 col-1">
                                                      <a href="javascript:void(0);">
                                                        <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                                      </a>
                                                    </li>
                                                  </ul>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th class="align-top" width="20%">Description</th>
                                                <td class="text-center align-top" width="5%">:</td>
                                                <td class="align-top" colspan="4">
                                                  <ul type="number">
                                                    <li>- Remove the injector</li>
                                                    <li> - Clean up deposit <ul>
                                                        <li>- Clean parts using a brass brush to prevent damage to polished surfaces.</li>
                                                        <li>- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.</li>
                                                        <li>- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.</li>
                                                      </ul>
                                                    </li>
                                                    <li>- Pressure injection test, check if the pressure isn't same with maker standart.</li>
                                                    <li>- Fit up</li>
                                                  </ul>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </b-collapse>
                                      </b-card>
                                      <b-card no-body>
                                        <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none">
                                          <b-button block v-b-toggle.job-1-2 variant="warning" class="text-left">
                                            <i class="fa fa-chevron-circle-down"></i> SENSOR - KALIBRASI SENSOR SONAR <strong>(PROGRESS)</strong>
                                          </b-button>
                                        </b-card-header>
                                        <b-collapse id="job-1-2" accordion="my-accordion-sub" role="tabpanel">
                                          <table class="table table-striped mt-3">
                                            <tbody>
                                              <tr>
                                                <th width="20%">Equipment Name</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Sensor</td>
                                                <th width="20%">Repair Title</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Kalibrasi Sensor Sonar</td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Type R & M</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Running Maintenance</td>
                                                <th width="20%">Department</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Deck</td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Category</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">General Service</td>
                                                <th width="20%">Classification</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Medium (7 Days)</td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Cause</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Human Error</td>
                                                <th width="20%">Kind Task</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Renew</td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Rencana Mulai</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">20 Januari 2023</td>
                                                <th width="20%">Rencana Selesai (Est)</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">27 Januari 2023</td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Estimasi Cost</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td colspan="4">Rp. 15.000.000,-</td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Berita Acara</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td colspan="4">
                                                  <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                    <li class="col-md-1 col-1">
                                                      <a href="javascript:void(0);">
                                                        <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                                      </a>
                                                    </li>
                                                    <li class="col-md-1 col-1">
                                                      <a href="javascript:void(0);">
                                                        <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                                      </a>
                                                    </li>
                                                    <li class="col-md-1 col-1">
                                                      <a href="javascript:void(0);">
                                                        <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                                      </a>
                                                    </li>
                                                    <li class="col-md-1 col-1">
                                                      <a href="javascript:void(0);">
                                                        <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                                      </a>
                                                    </li>
                                                  </ul>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th width="20%" class="align-top">Description</th>
                                                <td width="5%" class="align-top text-center">:</td>
                                                <td class="align-top" colspan="4">
                                                  <ul type="number">
                                                    <li>- Remove the injector</li>
                                                    <li> - Clean up deposit <ul>
                                                        <li>- Clean parts using a brass brush to prevent damage to polished surfaces.</li>
                                                        <li>- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.</li>
                                                        <li>- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.</li>
                                                      </ul>
                                                    </li>
                                                    <li>- Pressure injection test, check if the pressure isn't same with maker standart.</li>
                                                    <li>- Fit up</li>
                                                  </ul>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                          <fieldset class="form-group border p-3">
                                            <legend class="w-auto px-2">Internal Man Power</legend>
                                            <table class="table table-striped table-sm">
                                              <tbody>
                                                <tr>
                                                  <th width="50%" class="pl-3">NAMA LENGKAP</th>
                                                  <th width="50%">JABATAN</th>
                                                </tr>
                                                <tr>
                                                  <td class="pl-3">Agung Tri Laksono</td>
                                                  <td>Manager Engineer</td>
                                                </tr>
                                                <tr>
                                                  <td class="pl-3">Nurul Akbar</td>
                                                  <td>Senior Engineer</td>
                                                </tr>
                                                <tr>
                                                  <td class="pl-3">Hanel Prilian</td>
                                                  <td>Engineer</td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </fieldset>
                                          <fieldset class="form-group border p-3">
                                            <legend class="w-auto px-2">Sub Kontraktor</legend>
                                            <table class="table table-striped table-sm">
                                              <tbody>
                                                <tr>
                                                  <th class="pl-3">NAMA KONTRAKTOR</th>
                                                  <th>PIC/EXECUTIVE ENGINEER</th>
                                                  <th class="text-center">QUOTATION</th>
                                                  <th class="text-center">FINAL QUOTATION</th>
                                                  <th class="text-center">BERITA ACARA</th>
                                                </tr>
                                                <tr>
                                                  <td class="pl-3">PT. Cahaya Marine</td>
                                                  <td>Dendy Prasetya</td>
                                                  <td class="text-center">Rp. 30.000.000,-</td>
                                                  <td class="text-center">Rp. 27.500.000,-</td>
                                                  <td class="text-center">
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </fieldset>
                                        </b-collapse>
                                      </b-card>
                                    </div>
                                    <hr>
                                    <fieldset class="form-group border p-3">
                                      <legend class="w-auto px-2 text-primary">Add Job - #1</legend>
                                      <b-form-row>
                                        <b-col>
                                          <b-form-group label="Equipment Name" label-for="equipmentName">
                                            <b-form-input type="text" placeholder="Type Text ..."></b-form-input>
                                          </b-form-group>
                                        </b-col>
                                        <b-col>
                                          <b-form-group label="Repair Title" label-for="repairTitle">
                                            <b-form-input type="text" placeholder="Type Text ..."></b-form-input>
                                          </b-form-group>
                                        </b-col>
                                        <b-col>
                                          <b-form-group label="Type R & M *" label-for="type_repair">
                                            <b-form-select plain v-model="selectedTypeRM" :options="optionsTypeRM" size="lg">
                                              <template v-slot:first>
                                                <b-form-select-option :value="null">Select Type R & M</b-form-select-option>
                                              </template>
                                            </b-form-select>
                                          </b-form-group>
                                        </b-col>
                                      </b-form-row>
                                      <b-form-row>
                                        <b-col>
                                          <b-form-group label="Department*" label-for="department">
                                            <b-form-select plain v-model="selectedDepartment" :options="optionsDepartment" size="lg">
                                              <template v-slot:first>
                                                <b-form-select-option :value="null">Select Department</b-form-select-option>
                                              </template>
                                            </b-form-select>
                                          </b-form-group>
                                        </b-col>
                                        <b-col>
                                          <b-form-group label="Category *" label-for="category">
                                            <b-form-select plain v-model="selectedCategory" :options="category" size="lg">
                                              <template v-slot:first>
                                                <b-form-select-option :value="null">Select Category</b-form-select-option>
                                              </template>
                                            </b-form-select>
                                          </b-form-group>
                                        </b-col>
                                        <b-col>
                                          <b-form-group label="Clasification *" label-for="clasification">
                                            <b-form-select plain v-model="selectedClasification" :options="clasification" size="lg">
                                              <template v-slot:first>
                                                <b-form-select-option :value="null">Select Clasification</b-form-select-option>
                                              </template>
                                            </b-form-select>
                                          </b-form-group>
                                        </b-col>
                                      </b-form-row>
                                      <b-form-row>
                                        <b-col>
                                          <b-form-group label="Cause *" label-for="cause">
                                            <b-form-select plain v-model="selectedCause" :options="optionsCause" size="lg">
                                              <template v-slot:first>
                                                <b-form-select-option :value="null">Select Cause</b-form-select-option>
                                              </template>
                                            </b-form-select>
                                          </b-form-group>
                                        </b-col>
                                        <b-col>
                                          <b-form-group label="Kind of Task *" label-for="kind_of_task">
                                            <b-form-select plain v-model="selectedKindTask" :options="kindTask" size="lg">
                                              <template v-slot:first>
                                                <b-form-select-option :value="null">Select Kind of Task</b-form-select-option>
                                              </template>
                                            </b-form-select>
                                          </b-form-group>
                                        </b-col>
                                        <b-col>
                                          <b-form-group label="Berita Acara" label-for="attactment_file">
                                            <b-form-file plain id="attactment_file"></b-form-file>
                                          </b-form-group>
                                        </b-col>
                                      </b-form-row>
                                      <b-form-row>
                                        <b-col>
                                          <b-form-group label="Rencana Mulai *" label-for="rencana_mulai">
                                            <b-form-input type="date" id="rencana_mulai"></b-form-input>
                                          </b-form-group>
                                        </b-col>
                                        <b-col>
                                          <b-form-group label="Rencana Selesai *" label-for="rencana_selesai">
                                            <b-form-input type="date" id="rencana_selesai"></b-form-input>
                                          </b-form-group>
                                        </b-col>
                                        <b-col>
                                          <b-form-group label="Estimasi Hari" label-for="estimasi_hari">
                                            <b-button variant="outline-primary" class="mb-1">
                                              Perkiraan Pekerjaan <b-badge variant="primary" class="ml-2">7 Hari</b-badge>
                                            </b-button>
                                          </b-form-group>
                                        </b-col>
                                      </b-form-row>
                                      <b-form-row>
                                        <b-col>
                                          <b-form-group label="Description" label-for="description">
                                            <b-form-textarea id="description" rows="3" placeholder="Type Text ..."></b-form-textarea>
                                          </b-form-group>
                                        </b-col>
                                      </b-form-row>
                                      <b-row class="mt-2">
                                        <b-col md="12">
                                          <b-button variant="primary" class="pull-right" size="sm">
                                            <i class="fa fa-trash"></i> Delete Repair Job
                                          </b-button>
                                        </b-col>
                                      </b-row>
                                    </fieldset>
                                    <b-button variant="success" block>
                                      <i class="fa fa-plus"></i> ADD JOB REPAIR & MAINTENANCE
                                    </b-button>
                                  </b-col>
                                  <b-col md="12" class="text-right mt-2">
                                    <b-card-text>
                                      <small class="text-muted">Sign * : <b-badge variant="warning">Mandatory (Must be Input)</b-badge>
                                      </small>
                                    </b-card-text>
                                    <b-button type="submit" variant="primary">
                                      <i class="fa fa-save"></i> Save
                                    </b-button>
                                    <b-button type="submit" variant="none" class="iq-bg-danger ml-3">
                                      <i class="fa fa-close"></i> Reset Form
                                    </b-button>
                                  </b-col>
                                </b-row>
                              </b-col>
                            </b-row>
                          </fieldset>
                        </b-col>
                      </b-row>
                    </template>
                  </b-sidebar>
                  <!-- SLIDE END -->
                  <!-- SLIDE REVIEW -->
                  <b-sidebar id="review_sr" title="REVIEW REPAIR & MAINTENANCE - TB. ETI 201" width="70%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
                    <template #default="{hide}">
                      <b-row class="m-3">
                        <b-col md="12">
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Detail Information</legend>
                            <table class="table mb-0 table-striped">
                              <tbody>
                                <tr>
                                  <th width="20%">Lokasi Pekerjaan</th>
                                  <td width="5%">:</td>
                                  <td width="25%">Morosi</td>
                                  <th width="20%">Tanggal</th>
                                  <td width="5%">:</td>
                                  <td width="25%">15 Juni 2022</td>
                                </tr>
                                <tr>
                                  <th width="20%">Office / Site</th>
                                  <td width="5%">:</td>
                                  <td width="25%">Kendari</td>
                                  <th width="20%">PIC Repair</th>
                                  <td width="5%">:</td>
                                  <td width="25%">Sangatta</td>
                                </tr>
                              </tbody>
                            </table>
                          </fieldset>
                          <div class="accordion" role="tablist">
                            <b-card no-body>
                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none">
                                <b-button block v-b-toggle.job-1-1 variant="info" class="text-left">
                                  <i class="fa fa-chevron-circle-down"></i> FUEL INJECTOR - PEBAIKAN KEBOCORAN FUEL INJECTOR
                                </b-button>
                              </b-card-header>
                              <b-collapse id="job-1-1" accordion="my-accordion-sub" role="tabpanel">
                                <table class="table table-striped mt-3">
                                  <tbody>
                                    <tr>
                                      <th width="20%">Equipment Name</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Fuel Injector</td>
                                      <th width="20%">Repair Title</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Perbaikan Kebocoran Fuel Injector</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Type R & M</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Running Maintenance</td>
                                      <th width="20%">Department</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Engine</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Category</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Mechanical</td>
                                      <th width="20%">Classification</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Minor (3 Days)</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Cause</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Material Fatique</td>
                                      <th width="20%">Kind Task</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Repair</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Rencana Mulai</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">12 Januari 2023</td>
                                      <th width="20%">Rencana Selesai (Est)</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">15 Januari 2023</td>
                                    </tr>
                                    <tr>
                                      <td width="20%"><b>Estimate Cost</b></td>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">
                                        <div class="input-group">
                                          <div class="input-group-prepend">
                                            <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                          </div>
                                          <input type="number" class="form-control" id="penawaran" placeholder="Type Number ...">
                                        </div>
                                      </td>
                                      <th width="20%">Estimasi Hari</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">3 Hari</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Berita Acara</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td colspan="4">
                                        <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Description</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td class="align-top" colspan="4">
                                        <ul type="number">
                                          <li>- Remove the injector</li>
                                          <li> - Clean up deposit <ul>
                                              <li>- Clean parts using a brass brush to prevent damage to polished surfaces.</li>
                                              <li>- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.</li>
                                              <li>- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.</li>
                                            </ul>
                                          </li>
                                          <li>- Pressure injection test, check if the pressure isn't same with maker standart.</li>
                                          <li>- Fit up</li>
                                        </ul>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <hr>
                                <b-form-row>
                                  <b-col>
                                    <b-form-group label="Internal Man Power" label-for="internal_man_power">
                                      <template v-for="(item,index) in text">
                                        <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                          <div class="custom-switch-inner">
                                            <p class="mb-0"> {{ item.label }} </p>
                                            <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                            <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel"></label>
                                          </div>
                                        </div>
                                      </template>
                                    </b-form-group>
                                  </b-col>
                                </b-form-row>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Internal Man Power</legend>
                                  <table class="table table-borderless">
                                    <tbody>
                                      <tr class="iq-bg-primary">
                                        <th width="45%">NAMA LENGKAP</th>
                                        <th width="45%">JABATAN</th>
                                        <th class="text-center" width="10%">ACTION</th>
                                      </tr>
                                      <tr>
                                        <td><b-form-input type="text" id="nama_internal_man_power" size="sm" placeholder="Type Text ..."></b-form-input></td>
                                        <td>
                                          <b-form-select plain v-model="selectedJabatan" :options="optionsJabatan" size="sm">
                                            <template v-slot:first>
                                              <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                            </template>
                                          </b-form-select>
                                        </td>
                                        <td class="text-center"><b-button variant="danger"><i class="fa fa-trash pr-0"></i></b-button></td>
                                      </tr>
                                      <tr>
                                        <td><b-form-input type="text" id="nama_internal_man_power" size="sm" placeholder="Type Text ..."></b-form-input></td>
                                        <td>
                                          <b-form-select plain v-model="selectedJabatan" :options="optionsJabatan" size="sm">
                                            <template v-slot:first>
                                              <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                            </template>
                                          </b-form-select>
                                        </td>
                                        <td class="text-center"><b-button variant="danger"><i class="fa fa-trash pr-0"></i></b-button></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <b-button variant="success">
                                    <i class="fa fa-users"></i> ADD INTERNAL MAN POWER
                                  </b-button>
                                </fieldset>
                                <b-form-row>
                                  <b-col>
                                    <b-form-group label="Sub Kontraktor" label-for="subkontraktor">
                                      <template v-for="(item,index) in text">
                                        <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                          <div class="custom-switch-inner">
                                            <p class="mb-0"> {{ item.label }} </p>
                                            <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                            <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel"></label>
                                          </div>
                                        </div>
                                      </template>
                                    </b-form-group>
                                  </b-col>
                                </b-form-row>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Sub Kontraktor</legend>
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                        <b-form-input type="text" id="nama_kontraktor" placeholder="Type Text ..."></b-form-input>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                        <b-form-input type="text" id="executive_engineer" placeholder="Type Text ..."></b-form-input>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label="Penawaran" label-for="penawaran">
                                        <div class="input-group">
                                          <div class="input-group-prepend">
                                            <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                          </div>
                                          <input type="number" class="form-control" id="penawaran" placeholder="Type Number ...">
                                        </div>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                        <div class="input-group">
                                          <div class="input-group-prepend">
                                            <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                          </div>
                                          <input type="number" class="form-control" id="penawaran_nego" placeholder="Type Number ...">
                                        </div>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label="Notes" label-for="notes">
                                        <b-form-textarea id="description" rows="2" placeholder="Type Text ..."></b-form-textarea>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="Berita Acara" label-for="doc_shipyard">
                                        <div class="custom-file">
                                          <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                          <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                        </div>
                                        <small class="text-muted"> File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge>
                                          <br> File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                        </small>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                  <hr>
                                  <b-button variant="success">
                                    <i class="fa fa-users"></i> ADD SUB KONTRAKTOR
                                  </b-button>
                                </fieldset>
                              </b-collapse>
                            </b-card>
                            <b-card no-body>
                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none">
                                <b-button block v-b-toggle.job-1-2 variant="info" class="text-left">
                                  <i class="fa fa-chevron-circle-down"></i> SENSOR - KALIBRASI SENSOR SONAR
                                </b-button>
                              </b-card-header>
                              <b-collapse id="job-1-2" accordion="my-accordion-sub" role="tabpanel">
                                <table class="table table-striped mt-3">
                                  <tbody>
                                    <tr>
                                      <th width="20%">Equipment Name</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Sensor</td>
                                      <th width="20%">Repair Title</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Kalibrasi Sensor Sonar</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Type R & M</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Running Maintenance</td>
                                      <th width="20%">Department</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Deck</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Category</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">General Service</td>
                                      <th width="20%">Classification</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Medium (7 Days)</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Cause</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Human Error</td>
                                      <th width="20%">Kind Task</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Renew</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Rencana Mulai</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">20 Januari 2023</td>
                                      <th width="20%">Rencana Selesai (Est)</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">27 Januari 2023</td>
                                    </tr>
                                    <tr>
                                      <td width="20%"><b>Estimate Cost</b></td>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">
                                        <div class="input-group">
                                          <div class="input-group-prepend">
                                            <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                          </div>
                                          <input type="number" class="form-control" id="penawaran"  >
                                        </div>
                                      </td>
                                      <th width="20%">Estimasi Hari</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">7 Hari</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Berita Acara</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td colspan="4">
                                        <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Description</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td class="align-top" colspan="4">
                                        <ul type="number">
                                          <li>- Remove the injector</li>
                                          <li> - Clean up deposit <ul>
                                              <li>- Clean parts using a brass brush to prevent damage to polished surfaces.</li>
                                              <li>- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.</li>
                                              <li>- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.</li>
                                            </ul>
                                          </li>
                                          <li>- Pressure injection test, check if the pressure isn't same with maker standart.</li>
                                          <li>- Fit up</li>
                                        </ul>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <hr>
                                <b-form-row>
                                  <b-col>
                                    <b-form-group label="Internal Man Power" label-for="internal_man_power">
                                      <template v-for="(item,index) in text">
                                        <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                          <div class="custom-switch-inner">
                                            <p class="mb-0"> {{ item.label }} </p>
                                            <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                            <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel"></label>
                                          </div>
                                        </div>
                                      </template>
                                    </b-form-group>
                                  </b-col>
                                </b-form-row>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Internal Man Power</legend>
                                  <table class="table table-borderless">
                                    <tbody>
                                      <tr class="iq-bg-primary">
                                        <th width="45%">NAMA LENGKAP</th>
                                        <th width="45%">JABATAN</th>
                                        <th class="text-center" width="10%">ACTION</th>
                                      </tr>
                                      <tr>
                                        <td><b-form-input type="text" id="nama_internal_man_power" size="sm" placeholder="Type Text ..."></b-form-input></td>
                                        <td>
                                          <b-form-select plain v-model="selectedJabatan" :options="optionsJabatan" size="sm">
                                            <template v-slot:first>
                                              <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                            </template>
                                          </b-form-select>
                                        </td>
                                        <td class="text-center"><b-button variant="danger"><i class="fa fa-trash pr-0"></i></b-button></td>
                                      </tr>
                                      <tr>
                                        <td><b-form-input type="text" id="nama_internal_man_power" size="sm" placeholder="Type Text ..."></b-form-input></td>
                                        <td>
                                          <b-form-select plain v-model="selectedJabatan" :options="optionsJabatan" size="sm">
                                            <template v-slot:first>
                                              <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                            </template>
                                          </b-form-select>
                                        </td>
                                        <td class="text-center"><b-button variant="danger"><i class="fa fa-trash pr-0"></i></b-button></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <b-button variant="success">
                                    <i class="fa fa-users"></i> ADD INTERNAL MAN POWER
                                  </b-button>
                                </fieldset>
                                <b-form-row>
                                  <b-col>
                                    <b-form-group label="Sub Kontraktor" label-for="subkontraktor">
                                      <template v-for="(item,index) in text">
                                        <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                          <div class="custom-switch-inner">
                                            <p class="mb-0"> {{ item.label }} </p>
                                            <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                            <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel"></label>
                                          </div>
                                        </div>
                                      </template>
                                    </b-form-group>
                                  </b-col>
                                </b-form-row>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Sub Kontraktor</legend>
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                        <b-form-input type="text" id="nama_kontraktor" placeholder="Type Text ..."></b-form-input>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                        <b-form-input type="text" id="executive_engineer" placeholder="Type Text ..."></b-form-input>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label="Penawaran" label-for="penawaran">
                                        <div class="input-group">
                                          <div class="input-group-prepend">
                                            <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                          </div>
                                          <input type="number" class="form-control" id="penawaran" placeholder="Type Number ...">
                                        </div>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                        <div class="input-group">
                                          <div class="input-group-prepend">
                                            <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                          </div>
                                          <input type="number" class="form-control" id="penawaran_nego" placeholder="Type Number ...">
                                        </div>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label="Notes" label-for="notes">
                                        <b-form-textarea id="description" rows="2" placeholder="Type Text ..."></b-form-textarea>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="Berita Acara" label-for="doc_shipyard">
                                        <div class="custom-file">
                                          <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                          <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                        </div>
                                        <small class="text-muted"> File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge>
                                          <br> File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                        </small>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                  <hr>
                                  <b-button variant="success">
                                    <i class="fa fa-users"></i> ADD SUB KONTRAKTOR
                                  </b-button>
                                </fieldset>
                              </b-collapse>
                            </b-card>
                          </div>
                        </b-col>
                        <b-col md="12">
                          <b-button variant="primary" class="mt-2 pull-right">REVIEW & SUBMIT</b-button>
                        </b-col>
                      </b-row>
                    </template>
                  </b-sidebar>
                  <!-- SLIDE END -->
                  <!-- SLIDE APPROVE -->
                  <b-sidebar id="approve_sr" title="APPROVE REPAIR & MAINTENANCE - TB. ETI 201" width="70%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
                    <template #default="{hide}">
                      <b-row class="m-3">
                        <b-col md="12">
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Detail Information</legend>
                            <table class="table mb-0 table-striped">
                              <tbody>
                                <tr>
                                  <th width="20%">Lokasi Pekerjaan</th>
                                  <td width="5%">:</td>
                                  <td width="25%">Morosi</td>
                                  <th width="20%">Tanggal</th>
                                  <td width="5%">:</td>
                                  <td width="25%">15 Juni 2022</td>
                                </tr>
                                <tr>
                                  <th width="20%">Office / Site</th>
                                  <td width="5%">:</td>
                                  <td width="25%">Kendari</td>
                                  <th width="20%">PIC Repair</th>
                                  <td width="5%">:</td>
                                  <td width="25%">Sangatta</td>
                                </tr>
                              </tbody>
                            </table>
                          </fieldset>
                          <div class="accordion" role="tablist">
                            <b-card no-body>
                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none">
                                <b-button block v-b-toggle.job-1-1 variant="info" class="text-left">
                                  <i class="fa fa-chevron-circle-down"></i> FUEL INJECTOR - PEBAIKAN KEBOCORAN FUEL INJECTOR
                                </b-button>
                              </b-card-header>
                              <b-collapse id="job-1-1" accordion="my-accordion-sub" role="tabpanel">
                                <table class="table table-striped mt-3">
                                  <tbody>
                                    <tr>
                                      <th width="20%">Equipment Name</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Fuel Injector</td>
                                      <th width="20%">Repair Title</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Perbaikan Kebocoran Fuel Injector</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Type R & M</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Running Maintenance</td>
                                      <th width="20%">Department</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Engine</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Category</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Mechanical</td>
                                      <th width="20%">Classification</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Minor (3 Days)</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Cause</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Material Fatique</td>
                                      <th width="20%">Kind Task</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Repair</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Rencana Mulai</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">12 Januari 2023</td>
                                      <th width="20%">Rencana Selesai (Est)</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">15 Januari 2023</td>
                                    </tr>
                                    <tr>
                                      <td width="20%"><b>Estimate Cost</b></td>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Rp. 15.000.000,-</td>
                                      <th width="20%">Estimasi Hari</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">3 Hari</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Berita Acara</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td colspan="4">
                                        <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Description</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td class="align-top" colspan="4">
                                        <ul type="number">
                                          <li>- Remove the injector</li>
                                          <li> - Clean up deposit <ul>
                                              <li>- Clean parts using a brass brush to prevent damage to polished surfaces.</li>
                                              <li>- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.</li>
                                              <li>- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.</li>
                                            </ul>
                                          </li>
                                          <li>- Pressure injection test, check if the pressure isn't same with maker standart.</li>
                                          <li>- Fit up</li>
                                        </ul>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <hr>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Internal Man Power</legend>
                                  <table class="table table-striped table-sm">
                                    <tbody>
                                      <tr>
                                        <th width="50%" class="pl-3">NAMA LENGKAP</th>
                                        <th width="50%">JABATAN</th>
                                      </tr>
                                      <tr>
                                        <td class="pl-3">Agung Tri Laksono</td>
                                        <td>Manager Engineer</td>
                                      </tr>
                                      <tr>
                                        <td class="pl-3">Nurul Akbar</td>
                                        <td>Senior Engineer</td>
                                      </tr>
                                      <tr>
                                        <td class="pl-3">Hanel Prilian</td>
                                        <td>Engineer</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Sub Kontraktor</legend>
                                  <table class="table table-striped table-sm">
                                    <tbody>
                                      <tr>
                                        <th class="pl-3">NAMA KONTRAKTOR</th>
                                        <th>PIC/EXECUTIVE ENGINEER</th>
                                        <th class="text-center">QUOTATION</th>
                                        <th class="text-center">FINAL QUOTATION</th>
                                        <th class="text-center">BERITA ACARA</th>
                                      </tr>
                                      <tr>
                                        <td class="pl-3">PT. Cahaya Marine</td>
                                        <td>Dendy Prasetya</td>
                                        <td class="text-center">Rp. 30.000.000,-</td>
                                        <td class="text-center">Rp. 27.500.000,-</td>
                                        <td class="text-center">
                                          <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                          <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                          <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <b-alert :show="true" variant="success">
                                  <div class="iq-alert-icon">
                                    <i class="fa fa-thumbs-up"></i>
                                  </div>
                                  <div class="iq-alert-text">Docking Upslip <b>APPROVED</b></div>
                                </b-alert>
                                <b-alert :show="true" variant="danger">
                                  <div class="iq-alert-icon">
                                    <i class="fa fa-close"></i>
                                  </div>
                                  <div class="iq-alert-text">Docking Upslip <b>REJECT</b></div>
                                </b-alert>
                                <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>
                                <b-button type="submit" variant="success" class="float-right"><i class="fa fa-thumbs-up"></i> APPROVE JOB</b-button>
                              </b-collapse>
                            </b-card>
                            <b-card no-body>
                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none">
                                <b-button block v-b-toggle.job-1-2 variant="info" class="text-left">
                                  <i class="fa fa-chevron-circle-down"></i> SENSOR - KALIBRASI SENSOR SONAR
                                </b-button>
                              </b-card-header>
                              <b-collapse id="job-1-2" accordion="my-accordion-sub" role="tabpanel">
                                <table class="table table-striped mt-3">
                                  <tbody>
                                    <tr>
                                      <th width="20%">Equipment Name</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Sensor</td>
                                      <th width="20%">Repair Title</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Kalibrasi Sensor Sonar</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Type R & M</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Running Maintenance</td>
                                      <th width="20%">Department</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Deck</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Category</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">General Service</td>
                                      <th width="20%">Classification</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Medium (7 Days)</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Cause</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Human Error</td>
                                      <th width="20%">Kind Task</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Renew</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Rencana Mulai</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">20 Januari 2023</td>
                                      <th width="20%">Rencana Selesai (Est)</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">27 Januari 2023</td>
                                    </tr>
                                    <tr>
                                      <td width="20%"><b>Estimate Cost</b></td>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Rp. 18.000.000,-</td>
                                      <th width="20%">Estimasi Hari</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">7 Hari</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Berita Acara</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td colspan="4">
                                        <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Description</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td class="align-top" colspan="4">
                                        <ul type="number">
                                          <li>- Remove the injector</li>
                                          <li> - Clean up deposit <ul>
                                              <li>- Clean parts using a brass brush to prevent damage to polished surfaces.</li>
                                              <li>- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.</li>
                                              <li>- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.</li>
                                            </ul>
                                          </li>
                                          <li>- Pressure injection test, check if the pressure isn't same with maker standart.</li>
                                          <li>- Fit up</li>
                                        </ul>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <hr>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Internal Man Power</legend>
                                  <table class="table table-striped table-sm">
                                    <tbody>
                                      <tr>
                                        <th width="50%" class="pl-3">NAMA LENGKAP</th>
                                        <th width="50%">JABATAN</th>
                                      </tr>
                                      <tr>
                                        <td class="pl-3">Agung Tri Laksono</td>
                                        <td>Manager Engineer</td>
                                      </tr>
                                      <tr>
                                        <td class="pl-3">Nurul Akbar</td>
                                        <td>Senior Engineer</td>
                                      </tr>
                                      <tr>
                                        <td class="pl-3">Hanel Prilian</td>
                                        <td>Engineer</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Sub Kontraktor</legend>
                                  <table class="table table-striped table-sm">
                                    <tbody>
                                      <tr>
                                        <th class="pl-3">NAMA KONTRAKTOR</th>
                                        <th>PIC/EXECUTIVE ENGINEER</th>
                                        <th class="text-center">QUOTATION</th>
                                        <th class="text-center">FINAL QUOTATION</th>
                                        <th class="text-center">BERITA ACARA</th>
                                      </tr>
                                      <tr>
                                        <td class="pl-3">PT. Cahaya Marine</td>
                                        <td>Dendy Prasetya</td>
                                        <td class="text-center">Rp. 30.000.000,-</td>
                                        <td class="text-center">Rp. 27.500.000,-</td>
                                        <td class="text-center">
                                          <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                          <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                          <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <b-alert :show="true" variant="success">
                                  <div class="iq-alert-icon">
                                    <i class="fa fa-thumbs-up"></i>
                                  </div>
                                  <div class="iq-alert-text">Docking Upslip <b>APPROVED</b></div>
                                </b-alert>
                                <b-alert :show="true" variant="danger">
                                  <div class="iq-alert-icon">
                                    <i class="fa fa-close"></i>
                                  </div>
                                  <div class="iq-alert-text">Docking Upslip <b>REJECT</b></div>
                                </b-alert>
                                <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>
                                <b-button type="submit" variant="success" class="float-right"><i class="fa fa-thumbs-up"></i> APPROVE JOB</b-button>
                              </b-collapse>
                            </b-card>
                          </div>
                        </b-col>
                        <b-col md="12">
                          <b-button variant="primary" class="mt-2 pull-right">APPROVE ALL & SUBMIT</b-button>
                        </b-col>
                      </b-row>
                    </template>
                  </b-sidebar>
                  <!-- SLIDE END -->
                  <!-- SLIDE DETAIL -->
                  <b-sidebar id="detail_sr" title="DETAIL REPAIR & MAINTENANCE - TB. ETI 201" width="70%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
                    <template #default="{hide}">
                      <b-row class="m-3">
                        <b-col md="12">
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Detail Information</legend>
                            <table class="table mb-0 table-striped">
                              <tbody>
                                <tr>
                                  <th width="20%">Lokasi Pekerjaan</th>
                                  <td width="5%">:</td>
                                  <td width="25%">Morosi</td>
                                  <th width="20%">Tanggal</th>
                                  <td width="5%">:</td>
                                  <td width="25%">15 Juni 2022</td>
                                </tr>
                                <tr>
                                  <th width="20%">Office / Site</th>
                                  <td width="5%">:</td>
                                  <td width="25%">Kendari</td>
                                  <th width="20%">PIC Repair</th>
                                  <td width="5%">:</td>
                                  <td width="25%">Sangatta</td>
                                </tr>
                              </tbody>
                            </table>
                          </fieldset>
                          <div class="accordion mb-3" role="tablist">
                            <b-card no-body>
                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none">
                                <b-button block v-b-toggle.job-1-1 variant="info" class="text-left">
                                  <i class="fa fa-chevron-circle-down"></i> FUEL INJECTOR - PEBAIKAN KEBOCORAN FUEL INJECTOR <strong>(REVIEW)</strong>
                                </b-button>
                              </b-card-header>
                              <b-collapse id="job-1-1" accordion="my-accordion-sub" role="tabpanel">
                                <table class="table table-striped mt-3">
                                  <tbody>
                                    <tr>
                                      <th width="20%">Equipment Name</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Fuel Injector</td>
                                      <th width="20%">Repair Title</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Perbaikan Kebocoran Fuel Injector</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Type R & M</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Running Maintenance</td>
                                      <th width="20%">Department</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Engine</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Category</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Mechanical</td>
                                      <th width="20%">Classification</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Minor (3 Days)</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Cause</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Material Fatique</td>
                                      <th width="20%">Kind Task</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Repair</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Rencana Mulai</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">12 Januari 2023</td>
                                      <th width="20%">Rencana Selesai (Est)</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">15 Januari 2023</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Berita Acara</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td colspan="4">
                                        <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th class="align-top" width="20%">Description</th>
                                      <td class="align-top text-center" width="5%">:</td>
                                      <td class="align-top" colspan="4">
                                        <ul type="number">
                                          <li>- Remove the injector</li>
                                          <li> - Clean up deposit <ul>
                                              <li>- Clean parts using a brass brush to prevent damage to polished surfaces.</li>
                                              <li>- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.</li>
                                              <li>- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.</li>
                                            </ul>
                                          </li>
                                          <li>- Pressure injection test, check if the pressure isn't same with maker standart.</li>
                                          <li>- Fit up</li>
                                        </ul>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </b-collapse>
                            </b-card>
                            <b-card no-body>
                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none">
                                <b-button block v-b-toggle.job-1-2 variant="primary" class="text-left">
                                  <i class="fa fa-chevron-circle-down"></i> SENSOR - KALIBRASI SENSOR SONAR <strong>(APPROVE)</strong>
                                </b-button>
                              </b-card-header>
                              <b-collapse id="job-1-2" accordion="my-accordion-sub" role="tabpanel">
                                <table class="table table-striped mt-3">
                                  <tbody>
                                    <tr>
                                      <th width="20%">Equipment Name</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Sensor</td>
                                      <th width="20%">Repair Title</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Kalibrasi Sensor Sonar</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Type R & M</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Running Maintenance</td>
                                      <th width="20%">Department</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Deck</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Category</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">General Service</td>
                                      <th width="20%">Classification</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Medium (7 Days)</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Cause</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Human Error</td>
                                      <th width="20%">Kind Task</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Renew</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Rencana Mulai</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">20 Januari 2023</td>
                                      <th width="20%">Rencana Selesai (Est)</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">27 Januari 2023</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Berita Acara</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td colspan="4">
                                        <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th class="align-top" width="20%">Description</th>
                                      <td class="align-top text-center" width="5%">:</td>
                                      <td class="align-top" colspan="4">
                                        <ul type="number">
                                          <li>- Remove the injector</li>
                                          <li> - Clean up deposit <ul>
                                              <li>- Clean parts using a brass brush to prevent damage to polished surfaces.</li>
                                              <li>- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.</li>
                                              <li>- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.</li>
                                            </ul>
                                          </li>
                                          <li>- Pressure injection test, check if the pressure isn't same with maker standart.</li>
                                          <li>- Fit up</li>
                                        </ul>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </b-collapse>
                            </b-card>
                            <b-card no-body>
                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none">
                                <b-button block v-b-toggle.job-1-3 variant="warning" class="text-left">
                                  <i class="fa fa-chevron-circle-down"></i> ELECTRICAL - PERBAIAKAN KORSLETING LISTRIK <strong>(PROGESS)</strong>
                                </b-button>
                              </b-card-header>
                              <b-collapse id="job-1-3" accordion="my-accordion-sub" role="tabpanel">
                                <table class="table table-striped mt-3">
                                  <tbody>
                                    <tr>
                                      <th width="20%">Equipment Name</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Electrical</td>
                                      <th width="20%">Repair Title</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Perbaiakan Korsleting Ruang Deck</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Type R & M</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Emergency Maintenance</td>
                                      <th width="20%">Department</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Deck</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Category</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Electrical</td>
                                      <th width="20%">Classification</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Minor (3 Days)</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Cause</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Material Fatique</td>
                                      <th width="20%">Kind Task</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Repair</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Rencana Mulai</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">18 Januari 2023</td>
                                      <th width="20%">Rencana Selesai (Est)</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">21 Januari 2023</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Berita Acara</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td colspan="4">
                                        <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th class="align-top" width="20%">Description</th>
                                      <td class="align-top text-center" width="5%">:</td>
                                      <td class="align-top" colspan="4">
                                        <ul type="number">
                                          <li>- Remove the injector</li>
                                          <li> - Clean up deposit <ul>
                                              <li>- Clean parts using a brass brush to prevent damage to polished surfaces.</li>
                                              <li>- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.</li>
                                              <li>- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.</li>
                                            </ul>
                                          </li>
                                          <li>- Pressure injection test, check if the pressure isn't same with maker standart.</li>
                                          <li>- Fit up</li>
                                        </ul>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Internal Man Power</legend>
                                  <table class="table table-striped table-sm">
                                    <tbody>
                                      <tr>
                                        <th width="50%" class="pl-3">NAMA LENGKAP</th>
                                        <th width="50%">JABATAN</th>
                                      </tr>
                                      <tr>
                                        <td class="pl-3">Agung Tri Laksono</td>
                                        <td>Manager Engineer</td>
                                      </tr>
                                      <tr>
                                        <td class="pl-3">Nurul Akbar</td>
                                        <td>Senior Engineer</td>
                                      </tr>
                                      <tr>
                                        <td class="pl-3">Hanel Prilian</td>
                                        <td>Engineer</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">REPAIR & MAINTENANCE TIME LINE (PROGESS & DELAY FACTOR)</legend>
                                  <ul class="iq-timeline" style="height:500px">
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                        <small>24 November 2019 15:45</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melakukan inspeksi pada kerusakan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/6.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/1.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Agung Tri Laksono</h6><br/>
                                        <small>23 November 2019 21:25</small>
                                        <div class="d-inline-block w-100">
                                          <p>Sparepart tiba kapal</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Tri Leksono</h6><br/>
                                        <small>22 November 2019 10:12</small>
                                        <div class="d-inline-block w-100">
                                          <p>Menunggu sparepart tiba</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                        <small>21 November 2019 11:21</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melengkapi dokumen perbaikan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1">
                                              <a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a>
                                            </li>
                                            <li class="col-md-1 col-1">
                                              <a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a>
                                            </li>
                                            <li class="col-md-1 col-1">
                                              <a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a>
                                            </li>
                                            <li class="col-md-1 col-1">
                                              <a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a>
                                            </li>
                                          </ul>
                                        </div>
                                    </li>
                                  </ul>
                                </fieldset>
                              </b-collapse>
                            </b-card>
                            <b-card no-body>
                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none">
                                <b-button block v-b-toggle.job-1-4 variant="success" class="text-left">
                                  <i class="fa fa-chevron-circle-down"></i> ELECTRICAL - INSTALL LO COOLER AE PORTSIDE <strong>(FINISH)</strong>
                                </b-button>
                              </b-card-header>
                              <b-collapse id="job-1-4" accordion="my-accordion-sub" role="tabpanel">
                                <table class="table table-striped mt-3">
                                  <tbody>
                                    <tr>
                                      <th width="20%">Equipment Name</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Electrical</td>
                                      <th width="20%">Repair Title</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Install LO cooler AE portside</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Type R & M</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Plan Maintenance</td>
                                      <th width="20%">Department</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Engine</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Category</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Electrical</td>
                                      <th width="20%">Classification</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Major (21 Days)</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Cause</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Weather</td>
                                      <th width="20%">Kind Task</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Renew</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Rencana Mulai</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">10 Januari 2023</td>
                                      <th width="20%">Rencana Selesai (Est)</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">01 Februari 2023</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Berita Acara</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td colspan="4">
                                        <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                          <li class="col-md-1 col-1">
                                            <a href="javascript:void(0);">
                                              <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                            </a>
                                          </li>
                                        </ul>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th class="align-top" width="20%">Description</th>
                                      <td class="align-top text-center" width="5%">:</td>
                                      <td class="align-top" colspan="4">
                                        <ul type="number">
                                          <li>- Remove the injector</li>
                                          <li> - Clean up deposit <ul>
                                              <li>- Clean parts using a brass brush to prevent damage to polished surfaces.</li>
                                              <li>- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.</li>
                                              <li>- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.</li>
                                            </ul>
                                          </li>
                                          <li>- Pressure injection test, check if the pressure isn't same with maker standart.</li>
                                          <li>- Fit up</li>
                                        </ul>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Internal Man Power</legend>
                                  <table class="table table-striped table-sm">
                                    <tbody>
                                      <tr>
                                        <th width="50%" class="pl-3">NAMA LENGKAP</th>
                                        <th width="50%">JABATAN</th>
                                      </tr>
                                      <tr>
                                        <td class="pl-3">Agung Tri Laksono</td>
                                        <td>Manager Engineer</td>
                                      </tr>
                                      <tr>
                                        <td class="pl-3">Nurul Akbar</td>
                                        <td>Senior Engineer</td>
                                      </tr>
                                      <tr>
                                        <td class="pl-3">Hanel Prilian</td>
                                        <td>Engineer</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Sub Kontraktor</legend>
                                  <table class="table table-striped table-sm">
                                    <tbody>
                                      <tr>
                                        <th class="pl-3">NAMA KONTRAKTOR</th>
                                        <th>PIC/EXECUTIVE ENGINEER</th>
                                        <th class="text-center">QUOTATION</th>
                                        <th class="text-center">FINAL QUOTATION</th>
                                        <th class="text-center">BERITA ACARA</th>
                                      </tr>
                                      <tr>
                                        <td class="pl-3">PT. Cahaya Marine</td>
                                        <td>Dendy Prasetya</td>
                                        <td class="text-center">Rp. 30.000.000,-</td>
                                        <td class="text-center">Rp. 27.500.000,-</td>
                                        <td class="text-center">
                                          <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                          <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                          <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">REPAIR & MAINTENANCE TIME LINE (PROGESS & DELAY FACTOR)</legend>
                                  <ul class="iq-timeline" style="height:500px">
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                        <small>24 November 2019 15:45</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melakukan inspeksi pada kerusakan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/6.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/1.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Agung Tri Laksono</h6><br/>
                                        <small>23 November 2019 21:25</small>
                                        <div class="d-inline-block w-100">
                                          <p>Sparepart tiba kapal</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Tri Leksono</h6><br/>
                                        <small>22 November 2019 10:12</small>
                                        <div class="d-inline-block w-100">
                                          <p>Menunggu sparepart tiba</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                        <small>21 November 2019 11:21</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melengkapi dokumen perbaikan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1">
                                              <a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a>
                                            </li>
                                            <li class="col-md-1 col-1">
                                              <a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a>
                                            </li>
                                            <li class="col-md-1 col-1">
                                              <a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a>
                                            </li>
                                            <li class="col-md-1 col-1">
                                              <a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a>
                                            </li>
                                          </ul>
                                        </div>
                                    </li>
                                  </ul>
                                </fieldset>
                              </b-collapse>
                            </b-card>
                          </div>
                        </b-col>
                      </b-row>
                    </template>
                  </b-sidebar>
                  <!-- SLIDE END -->
                  <!-- SLIDE UPDATE DATA -->
                  <b-sidebar id="update_sr" title="UPDATE REPAIR & MAINTENANCE - TB. ETI 201" width="70%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
                    <template #default="{hide}">
                      <b-row class="m-3">
                        <b-col cols="12">
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Detail Information</legend>
                            <table class="table mb-0 table-striped">
                              <tbody>
                                <tr>
                                  <th width="20%">Lokasi Pekerjaan</th>
                                  <td width="5%">:</td>
                                  <td width="25%">
                                    <b-form-input type="text" placeholder="Type Text ..."></b-form-input>
                                  </td>
                                  <th width="20%">Tanggal</th>
                                  <td width="5%">:</td>
                                  <td width="25%">
                                    <b-form-input type="date"></b-form-input>
                                  </td>
                                </tr>
                                <tr>
                                  <th width="20%">Office / Site</th>
                                  <td width="5%">:</td>
                                  <td width="25%">
                                    <b-form-input type="text" placeholder="Type Text ..."></b-form-input>
                                  </td>
                                  <th width="20%">PIC Repair</th>
                                  <td width="5%">:</td>
                                  <td width="25%">
                                    <b-form-input type="text" placeholder="Type Text ..."></b-form-input>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </fieldset>
                          <div class="accordion" role="tablist">
                            <b-card no-body>
                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none">
                                <b-button block v-b-toggle.job-1-1 variant="info" class="text-left">
                                  <i class="fa fa-chevron-circle-down"></i> FUEL INJECTOR - PEBAIKAN KEBOCORAN FUEL INJECTOR
                                </b-button>
                              </b-card-header>
                              <b-collapse id="job-1-1" accordion="my-accordion-sub" role="tabpanel">
                                <fieldset class="form-group border p-3 mt-3">
                                  <legend class="w-auto px-2 text-primary">FUEL INJECTOR - PEBAIKAN KEBOCORAN FUEL INJECTOR</legend>
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label="Equipment Name" label-for="equipmentName">
                                        <b-form-input type="text" placeholder="Type Text ..."></b-form-input>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="Repair Title" label-for="repairTitle">
                                        <b-form-input type="text" placeholder="Type Text ..."></b-form-input>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="Type R & M *" label-for="type_repair">
                                        <b-form-select plain v-model="selectedTypeRM" :options="optionsTypeRM" size="sm">
                                          <template v-slot:first>
                                            <b-form-select-option :value="null">Select Type R & M</b-form-select-option>
                                          </template>
                                        </b-form-select>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label="Department*" label-for="department">
                                        <b-form-select plain v-model="selectedDepartment" :options="optionsDepartment" size="sm">
                                          <template v-slot:first>
                                            <b-form-select-option :value="null">Select Department</b-form-select-option>
                                          </template>
                                        </b-form-select>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="Category *" label-for="category">
                                        <b-form-select plain v-model="selectedCategory" :options="category" size="sm">
                                          <template v-slot:first>
                                            <b-form-select-option :value="null">Select Category</b-form-select-option>
                                          </template>
                                        </b-form-select>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="Clasification *" label-for="clasification">
                                        <b-form-select plain v-model="selectedClasification" :options="clasification" size="sm">
                                          <template v-slot:first>
                                            <b-form-select-option :value="null">Select Clasification</b-form-select-option>
                                          </template>
                                        </b-form-select>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label="Cause *" label-for="cause">
                                        <b-form-select plain v-model="selectedCause" :options="optionsCause" size="sm">
                                          <template v-slot:first>
                                            <b-form-select-option :value="null">Select Cause</b-form-select-option>
                                          </template>
                                        </b-form-select>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="Kind of Task *" label-for="kind_of_task">
                                        <b-form-select plain v-model="selectedKindTask" :options="kindTask" size="sm">
                                          <template v-slot:first>
                                            <b-form-select-option :value="null">Select Kind of Task</b-form-select-option>
                                          </template>
                                        </b-form-select>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="Berita Acara" label-for="attactment_file">
                                        <b-form-file plain id="attactment_file"></b-form-file>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label="Rencana Mulai *" label-for="rencana_mulai">
                                        <b-form-input type="date" id="rencana_mulai"></b-form-input>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="Rencana Selesai *" label-for="rencana_selesai">
                                        <b-form-input type="date" id="rencana_selesai"></b-form-input>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="Estimasi Hari" label-for="estimasi_hari">
                                        <b-button variant="outline-primary" class="mb-1">
                                          Perkiraan Pekerjaan <b-badge variant="primary" class="ml-2">7 Hari</b-badge>
                                        </b-button>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label="Description" label-for="description">
                                        <b-form-textarea id="description" rows="3" placeholder="Type Text ..."></b-form-textarea>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                </fieldset>
                              </b-collapse>
                            </b-card>
                            <b-card no-body>
                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none">
                                <b-button block v-b-toggle.job-1-2 variant="info" class="text-left">
                                  <i class="fa fa-chevron-circle-down"></i> SENSOR - KALIBRASI SENSOR SONAR
                                </b-button>
                              </b-card-header>
                              <b-collapse id="job-1-2" accordion="my-accordion-sub" role="tabpanel">
                                <fieldset class="form-group border p-3 mt-3">
                                  <legend class="w-auto px-2 text-primary">SENSOR - KALIBRASI SENSOR SONAR</legend>
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label="Equipment Name" label-for="equipmentName">
                                        <b-form-input type="text" placeholder="Type Text ..."></b-form-input>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="Repair Title" label-for="repairTitle">
                                        <b-form-input type="text" placeholder="Type Text ..."></b-form-input>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="Type R & M *" label-for="type_repair">
                                        <b-form-select plain v-model="selectedTypeRM" :options="optionsTypeRM" size="sm">
                                          <template v-slot:first>
                                            <b-form-select-option :value="null">Select Type R & M</b-form-select-option>
                                          </template>
                                        </b-form-select>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label="Department*" label-for="department">
                                        <b-form-select plain v-model="selectedDepartment" :options="optionsDepartment" size="sm">
                                          <template v-slot:first>
                                            <b-form-select-option :value="null">Select Department</b-form-select-option>
                                          </template>
                                        </b-form-select>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="Category *" label-for="category">
                                        <b-form-select plain v-model="selectedCategory" :options="category" size="sm">
                                          <template v-slot:first>
                                            <b-form-select-option :value="null">Select Category</b-form-select-option>
                                          </template>
                                        </b-form-select>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="Clasification *" label-for="clasification">
                                        <b-form-select plain v-model="selectedClasification" :options="clasification" size="sm">
                                          <template v-slot:first>
                                            <b-form-select-option :value="null">Select Clasification</b-form-select-option>
                                          </template>
                                        </b-form-select>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label="Cause *" label-for="cause">
                                        <b-form-select plain v-model="selectedCause" :options="optionsCause" size="sm">
                                          <template v-slot:first>
                                            <b-form-select-option :value="null">Select Cause</b-form-select-option>
                                          </template>
                                        </b-form-select>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="Kind of Task *" label-for="kind_of_task">
                                        <b-form-select plain v-model="selectedKindTask" :options="kindTask" size="sm">
                                          <template v-slot:first>
                                            <b-form-select-option :value="null">Select Kind of Task</b-form-select-option>
                                          </template>
                                        </b-form-select>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="Berita Acara" label-for="attactment_file">
                                        <b-form-file plain id="attactment_file"></b-form-file>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label="Rencana Mulai *" label-for="rencana_mulai">
                                        <b-form-input type="date" id="rencana_mulai"></b-form-input>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="Rencana Selesai *" label-for="rencana_selesai">
                                        <b-form-input type="date" id="rencana_selesai"></b-form-input>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="Estimasi Hari" label-for="estimasi_hari">
                                        <b-button variant="outline-primary" class="mb-1">
                                          Perkiraan Pekerjaan <b-badge variant="primary" class="ml-2">7 Hari</b-badge>
                                        </b-button>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label="Description" label-for="description">
                                        <b-form-textarea id="description" rows="3" placeholder="Type Text ..."></b-form-textarea>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                </fieldset>
                              </b-collapse>
                            </b-card>
                          </div>
                        </b-col>
                        <b-col md="12" class="text-right">
                          <b-card-text>
                            <small class="text-muted">Sign * : <b-badge variant="warning">Mandatory (Must be Input)</b-badge>
                            </small>
                          </b-card-text>
                          <b-button type="submit" variant="primary">
                            <i class="fa fa-save"></i> Save
                          </b-button>
                          <b-button type="submit" variant="none" class="iq-bg-danger ml-3">
                            <i class="fa fa-close"></i> Reset Form
                          </b-button>
                        </b-col>
                      </b-row>
                    </template>
                  </b-sidebar>
                  <!-- SLIDE END -->
                </template>
              </iq-card>
              <iq-card>
                <template v-slot:headerTitle>
                  <h5 class="card-title text-primary">
                    <strong>PROGRESS REPAIR & MAINTENANCE</strong>
                  </h5>
                </template>
                <template v-slot:body>
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th class="text-center">FLEET&nbsp;NAME</th>
                        <th class="text-center">LOCATION</th>
                        <th class="text-center">TANGGAL</th>
                        <th class="text-center">TOTAL R & M</th>
                        <th class="text-center">CLASSIFICATION</th>
                        <th class="text-center">OFFICE/PIC</th>
                        <th class="text-center">ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-center">TB. SML 301</td>
                        <td class="text-center">Tanjung Bara</td>
                        <td class="text-center">12-Jun-2021</td>
                        <td class="text-center">5 Repairs</td>
                        <td class="text-center">
                          <b-badge variant="success d-block" class="mb-1">Minor : 2 Repair</b-badge>
                          <b-badge variant="primary d-block" class="mb-1">Medium : 3 Repair</b-badge>
                          <b-badge variant="danger d-block">Major : 0 Repair</b-badge>
                        </td>
                        <td class="text-center"> Asam Asam Office <br>
                          <small>PIC : <strong>Darmawan</strong>
                          </small>
                        </td>
                        <td class="text-center">
                          <b-dropdown size="lg" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                            <template v-slot:button-content>
                              <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                              </span>
                            </template>
                            <b-dropdown-item href="#" v-b-toggle.add-activity>
                              <i class="fa fa-comments mr-2"></i>Update Activity
                            </b-dropdown-item>
                            <b-dropdown-item href="#" v-b-toggle.add-job>
                              <i class="fa fa-plus mr-2"></i>Add Job
                            </b-dropdown-item>
                          </b-dropdown>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="mt-3">
                    <b-pagination :value="1" :total-rows="50" align="center" />
                  </div>
                  <!-- SLIDE REVIEW -->
                  <b-sidebar id="add-activity" title="UPDATE REPAIR & MAINTENANCE TIME LINE (PROGESS & DELAY FACTOR)" width="100%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
                    <template #default="{hide}">
                      <b-row class="m-3">
                        <b-col md="7">
                          <table class="table table-striped">
                            <tbody>
                              <tr>
                                <th width="20%">Equipment Name</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">Fuel Injector</td>
                                <th width="20%">Repair Title</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">Perbaikan Kebocoran Fuel Injector</td>
                              </tr>
                              <tr>
                                <th width="20%">Type R & M</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">Running Maintenance</td>
                                <th width="20%">Department</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">Engine</td>
                              </tr>
                              <tr>
                                <th width="20%">Category</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">Mechanical</td>
                                <th width="20%">Classification</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">Minor (3 Days)</td>
                              </tr>
                              <tr>
                                <th width="20%">Cause</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">Material Fatique</td>
                                <th width="20%">Kind Task</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">Repair</td>
                              </tr>
                              <tr>
                                <th width="20%">Rencana Mulai</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">12 Januari 2023</td>
                                <th width="20%">Rencana Selesai</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">15 Januari 2023</td>
                              </tr>
                              <tr>
                                <th width="20%">Berita Acara</th>
                                <td width="5%" class="text-center">:</td>
                                <td colspan="4">
                                  <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                    <li class="col-md-1 col-1">
                                      <a href="javascript:void(0);">
                                        <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                      </a>
                                    </li>
                                    <li class="col-md-1 col-1">
                                      <a href="javascript:void(0);">
                                        <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                      </a>
                                    </li>
                                    <li class="col-md-1 col-1">
                                      <a href="javascript:void(0);">
                                        <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                      </a>
                                    </li>
                                    <li class="col-md-1 col-1">
                                      <a href="javascript:void(0);">
                                        <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                      </a>
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                              <tr>
                                <th class="align-top" width="20%">Description</th>
                                <td class="align-top text-center" width="5%">:</td>
                                <td class="align-top" colspan="4">
                                  <ul type="number">
                                    <li>- Remove the injector</li>
                                    <li> - Clean up deposit <ul>
                                        <li>- Clean parts using a brass brush to prevent damage to polished surfaces.</li>
                                        <li>- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.</li>
                                        <li>- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.</li>
                                      </ul>
                                    </li>
                                    <li>- Pressure injection test, check if the pressure isn't same with maker standart.</li>
                                    <li>- Fit up</li>
                                  </ul>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2">Internal Man Power</legend>
                            <table class="table table-striped table-sm">
                              <tbody>
                                <tr>
                                  <th width="50%" class="pl-3">NAMA LENGKAP</th>
                                  <th width="50%">JABATAN</th>
                                </tr>
                                <tr>
                                  <td class="pl-3">Agung Tri Laksono</td>
                                  <td>Manager Engineer</td>
                                </tr>
                                <tr>
                                  <td class="pl-3">Nurul Akbar</td>
                                  <td>Senior Engineer</td>
                                </tr>
                                <tr>
                                  <td class="pl-3">Hanel Prilian</td>
                                  <td>Engineer</td>
                                </tr>
                              </tbody>
                            </table>
                          </fieldset>
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2">Sub Kontraktor</legend>
                            <table class="table table-striped table-sm">
                              <tbody>
                                <tr>
                                  <th class="pl-3">NAMA KONTRAKTOR</th>
                                  <th>PIC/EXECUTIVE ENGINEER</th>
                                  <th class="text-center">QUOTATION</th>
                                  <th class="text-center">FINAL QUOTATION</th>
                                  <th class="text-center">BERITA ACARA</th>
                                </tr>
                                <tr>
                                  <td class="pl-3">PT. Cahaya Marine</td>
                                  <td>Dendy Prasetya</td>
                                  <td class="text-center">Rp. 30.000.000,-</td>
                                  <td class="text-center">Rp. 27.500.000,-</td>
                                  <td class="text-center">
                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                    <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </fieldset>
                        </b-col>
                        <b-col md="5">
                          <tab-content-item :active="true" id="power">
                            <tab-nav :tabs="true">
                              <tab-nav-items :active="true" href="#activity" title="Activity" />
                              <tab-nav-items :active="false" href="#logs" title="Logs" />
                            </tab-nav>
                            <div id="myTabContent" class="tab-content">
                              <tab-content-item :active="true" id="activity">
                                <b-row>
                                  <b-col md="12" class="mb-5">
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Select Repair & Maintenance" label-for="repair_maintenance">
                                          <b-form-select plain v-model="selectedRepairMaintenance" :options="optionsRepairMaintenance">
                                            <template v-slot:first>
                                              <b-form-select-option :value="null">Select Repair & Maintenance</b-form-select-option>
                                            </template>
                                          </b-form-select>
                                        </b-form-group>
                                      </b-col>
                                      <b-col>
                                        <b-form-group label="Select Activity" label-for="activity">
                                          <b-form-select plain v-model="selectedActivity" :options="optionsActivity">
                                            <template v-slot:first>
                                              <b-form-select-option :value="null">Select Activity</b-form-select-option>
                                            </template>
                                          </b-form-select>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Tanggal Aktual *" label-for="date">
                                          <b-form-input type="date" id="start"></b-form-input>
                                        </b-form-group>
                                      </b-col>
                                      <b-col>
                                        <b-form-group label="Berita Acara" label-for="berita_acara">
                                          <div class="custom-file">
                                            <input type="file" class="custom-file-input" id="validatedCustomFile">
                                            <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                          </div>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Notes" label-for="notes">
                                          <b-form-textarea id="description" rows="3" placeholder="Type Text ..."></b-form-textarea>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Is Finish Job ?" label-for="internal_man_power">
                                          <template v-for="(item,index) in text">
                                            <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                              <div class="custom-switch-inner">
                                                <p class="mb-0"> {{ item.label }} </p>
                                                <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel"></label>
                                              </div>
                                            </div>
                                          </template>
                                        </b-form-group>
                                      </b-col>
                                      <b-col>
                                        <b-button type="submit" variant="success" class="pull-right"><i class="fa fa-save"></i> Submit Data</b-button>
                                      </b-col>
                                    </b-form-row>
                                  </b-col>
                                  <b-col md="12">
                                    <ul class="iq-timeline" style="height:100%">
                                      <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6>
                                        <br />
                                        <small>24 November 2019 15:45</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melakukan inspeksi pada kerusakan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-2 col-2 pb-2">
                                              <a href="javascript:void(0);">
                                                <img src="../../assets/images/activity/6.jpg" alt="gallary-image" class="img-fluid">
                                              </a>
                                            </li>
                                            <li class="col-md-2 col-2 pb-2">
                                              <a href="javascript:void(0);">
                                                <img src="../../assets/images/activity/1.png" alt="gallary-image" class="img-fluid">
                                              </a>
                                            </li>
                                            <li class="col-md-2 col-2 pb-2">
                                              <a href="javascript:void(0);">
                                                <img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid">
                                              </a>
                                            </li>
                                            <li class="col-md-2 col-2 pb-2">
                                              <a href="javascript:void(0);">
                                                <img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid">
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Agung Tri Laksono</h6>
                                        <br />
                                        <small>23 November 2019 21:25</small>
                                        <div class="d-inline-block w-100">
                                          <p>Barang tiba di galangan</p>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Tri Leksono</h6>
                                        <br />
                                        <small>22 November 2019 10:12</small>
                                        <div class="d-inline-block w-100">
                                          <p>Menunggu sparepart tiba</p>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6>
                                        <br />
                                        <small>21 November 2019 11:21</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melengkapi dokumen perbaikan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-2 col-2 pb-2">
                                              <a href="javascript:void(0);">
                                                <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                              </a>
                                            </li>
                                            <li class="col-md-2 col-2 pb-2">
                                              <a href="javascript:void(0);">
                                                <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                              </a>
                                            </li>
                                            <li class="col-md-2 col-2 pb-2">
                                              <a href="javascript:void(0);">
                                                <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                              </a>
                                            </li>
                                            <li class="col-md-2 col-2 pb-2">
                                              <a href="javascript:void(0);">
                                                <img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid">
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>
                                    </ul>
                                  </b-col>
                                </b-row>
                              </tab-content-item>
                              <tab-content-item :active="false" id="logs">
                                <b-row>
                                  <b-col md="12">
                                    <ul class="iq-timeline" style="height:100%">
                                      <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6>
                                        <br />
                                        <small>19 Oktober 2019 21:25</small>
                                        <div class="d-inline-block w-100">
                                          <p>Service Repair Finish</p>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Agung Tri Laksono</h6>
                                        <br />
                                        <small>18 Oktober 2019 15:41</small>
                                        <div class="d-inline-block w-100">
                                          <p>Update Stage</p>
                                          <b-button variant="success" class="mr-1">REVIEW & APPROVE</b-button>
                                          <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
                                          <b-button variant="danger" class="ml-1">REJECT</b-button>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">System</h6>
                                        <br />
                                        <small>17 Oktober 2019 18:30</small>
                                        <div class="d-inline-block w-100">
                                          <p>System mengirim email ke : <b-badge class="border border-primary text-primary ml-1" variant="none">aguuungtriilaksono@gmail.com</b-badge>
                                            <b-badge class="border border-primary text-primary ml-1" variant="none">nurulakbar93@gmail.com</b-badge>
                                          </p>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Agung Tri Laksono</h6>
                                        <br />
                                        <small>17 Oktober 2019 18:30</small>
                                        <div class="d-inline-block w-100">
                                          <p>Update Stage</p>
                                          <b-button variant="primary" class="mr-1">REQUEST</b-button>
                                          <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
                                          <b-button variant="success" class="ml-1">REVIEW & APPROVE</b-button>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">System</h6>
                                        <br />
                                        <small>15 Oktober 2019 16:00</small>
                                        <div class="d-inline-block w-100">
                                          <p>System mengirim email ke : <b-badge class="border border-primary text-primary ml-1" variant="none">aguuungtriilaksono@gmail.com</b-badge>
                                            <b-badge class="border border-primary text-primary ml-1" variant="none">nurulakbar93@gmail.com</b-badge>
                                          </p>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6>
                                        <br />
                                        <small>15 Oktober 2019 20:24</small>
                                        <div class="d-inline-block w-100">
                                          <p>Mengupdate data service repair</p>
                                        </div>
                                      </li>
                                      <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6>
                                        <br />
                                        <small>15 Oktober 2019 16:00</small>
                                        <div class="d-inline-block w-100">
                                          <p>Membuat data service repair</p>
                                        </div>
                                      </li>
                                    </ul>
                                  </b-col>
                                </b-row>
                              </tab-content-item>
                            </div>
                          </tab-content-item>
                        </b-col>
                      </b-row>
                    </template>
                  </b-sidebar>
                  <!-- SLIDE END -->
                </template>
              </iq-card>
            </tab-content-item>
            <tab-content-item :active="false" id="finish">
              <b-row>
                <b-col sm="12">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h5 class="card-title text-primary">
                        <strong>HISTORY SERVICE REPAIR</strong>
                      </h5>
                    </template>
                    <template v-slot:body>
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="text-center">FLEET NAME</th>
                            <th class="text-center">TOTAL COST</th>
                            <th class="text-center">TOTAL REPAIR</th>
                            <th class="text-center">STATUS</th>
                            <th class="text-center">MINOR</th>
                            <th class="text-center">MEDIUM</th>
                            <th class="text-center">MAJOR</th>
                            <th class="text-center">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center">TB. TCP 201</td>
                            <td class="text-center">Rp. 1.238.203.200,-</td>
                            <td class="text-center">53 Jobs
                            </td>
                            <td class="text-center">
                              <b-badge variant="success" class="mb-1">On Time : 45 Jobs</b-badge><br>
                              <b-badge variant="danger">Delay : 23 Jobs</b-badge>
                            </td>
                            <td class="text-center">15 Jobs</td>
                            <td class="text-center">31 Jobs</td>
                            <td class="text-center">22 Jobs</td>
                            <td class="text-center">
                              <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                  </span>
                                </template>
                                <b-dropdown-item href="#">
                                  <router-link to="/templates/service-repair-history">
                                    <i class="fa fa-ship mr-2"></i>Detail History
                                  </router-link>
                                </b-dropdown-item>
                              </b-dropdown>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">TB. ETI 203</td>
                            <td class="text-center">Rp. 1.375.123.180,-</td>
                            <td class="text-center">36 Jobs</td>
                            <td class="text-center">
                              <b-badge variant="success" class="mb-1">On Time : 32 Jobs</b-badge><br>
                              <b-badge variant="danger">Delay : 4 Jobs</b-badge>
                            </td>
                            <td class="text-center">15 Jobs</td>
                            <td class="text-center">12 Jobs</td>
                            <td class="text-center">16 Jobs</td>
                            <td class="text-center">
                              <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                  </span>
                                </template>
                                <b-dropdown-item href="#">
                                  <router-link to="/templates/service-repair-history">
                                    <i class="fa fa-ship mr-2"></i>Detail History
                                  </router-link>
                                </b-dropdown-item>
                              </b-dropdown>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">TB. SML 201</td>
                            <td class="text-center">Rp. 1.387.384.000,-</td>
                            <td class="text-center">72 Jobs</td>
                            <td class="text-center">
                              <b-badge variant="success" class="mb-1">On Time : 52 Jobs</b-badge><br>
                              <b-badge variant="danger">Delay : 20 Jobs</b-badge>
                            </td>
                            <td class="text-center">21 Jobs</td>
                            <td class="text-center">17 Jobs</td>
                            <td class="text-center">19 Jobs</td>
                            <td class="text-center">
                              <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                  </span>
                                </template>
                                <b-dropdown-item href="#">
                                  <router-link to="/templates/service-repair-history">
                                    <i class="fa fa-ship mr-2"></i>Detail History
                                  </router-link>
                                </b-dropdown-item>
                              </b-dropdown>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">BG. ETI </td>
                            <td class="text-center">Rp. 1.387.384.000,-</td>
                            <td class="text-center">72 Jobs</td>
                            <td class="text-center">
                              <b-badge variant="success" class="mb-1">On Time : 52 Jobs</b-badge><br>
                              <b-badge variant="danger">Delay : 20 Jobs</b-badge>
                            </td>
                            <td class="text-center">21 Jobs</td>
                            <td class="text-center">17 Jobs</td>
                            <td class="text-center">19 Jobs</td>
                            <td class="text-center">
                              <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                  </span>
                                </template>
                                <b-dropdown-item href="#">
                                  <router-link to="/templates/service-repair-history">
                                    <i class="fa fa-ship mr-2"></i>Detail History
                                  </router-link>
                                </b-dropdown-item>
                              </b-dropdown>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="mt-3">
                        <b-pagination :value="1" :total-rows="50" align="center" />
                      </div>
                    </template>
                  </iq-card>
                </b-col>
              </b-row>
            </tab-content-item>
            <tab-content-item :active="false" id="report">
              <b-row>
                <b-col md="6" lg="3">
                  <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-primary rounded">
                    <template v-slot:body>
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="rounded-circle iq-card-icon bg-primary"><i class="fa fa-ship"></i></div>
                        <div class="text-right">
                          <h2 class="mb-0"><span class="counter">23</span></h2>
                          <h5 class="">Fleet Repairs</h5>
                        </div>
                      </div>
                    </template>
                  </iq-card>
                </b-col>
                <b-col md="6" lg="3">
                  <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-warning rounded">
                    <template v-slot:body >
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="rounded-circle iq-card-icon bg-warning"><i class="fas fa-dollar-sign"></i></div>
                        <div class="text-right">
                          <h3 class="mb-0"><span class="counter">3.552.017.210,-</span></h3>
                          <h5 class="">Total Cost All Fleet</h5>
                        </div>
                      </div>
                    </template>
                  </iq-card>
                </b-col>
                <b-col md="6" lg="3">
                  <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-danger rounded">
                    <template v-slot:body >
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="rounded-circle iq-card-icon bg-danger"><i class="fa fa-tools"></i></div>
                        <div class="text-right">
                          <h2 class="mb-0"><span class="counter">432</span></h2>
                          <h5 class="">Total Repairs All Fleet</h5>
                        </div>
                      </div>
                    </template>
                  </iq-card>
                </b-col>
                <b-col md="6" lg="3">
                  <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-info rounded">
                    <template v-slot:body >
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="rounded-circle iq-card-icon bg-info"><i class="fa fa-spinner"></i></div>
                        <div class="text-right">
                          <h2 class="mb-0"><span class="counter">36</span></h2>
                          <h5 class="">On Progress Job</h5>
                        </div>
                      </div>
                    </template>
                  </iq-card>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="6" v-for="(item,index) in classification" :key="index">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4>{{ item.title }}</h4>
                    </template>
                    <template v-slot:body>
                      <HighChart :ref="item.type" :option="item.bodyData"/>
                    </template>
                  </iq-card>
                </b-col>
                <b-col lg="6" v-for="(item,index) in cause" :key="index">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4>{{ item.title }}</h4>
                    </template>
                    <template v-slot:body>
                      <HighChart :ref="item.type" :option="item.bodyData"/>
                    </template>
                  </iq-card>
                </b-col>
                <b-col lg="6" v-for="(item,index) in typeRM" :key="index">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4>{{ item.title }}</h4>
                    </template>
                    <template v-slot:body>
                      <HighChart :ref="item.type" :option="item.bodyData"/>
                    </template>
                  </iq-card>
                </b-col>
                <b-col lg="6" v-for="(item,index) in costRM" :key="index">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4>{{ item.title }}</h4>
                    </template>
                    <template v-slot:body>
                      <HighChart :ref="item.type" :option="item.bodyData"/>
                    </template>
                  </iq-card>
                </b-col>
                <b-col lg="6" v-for="(item,index) in equipmentRM" :key="index">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4>{{ item.title }}</h4>
                    </template>
                    <template v-slot:body>
                      <HighChart :ref="item.type" :option="item.bodyData"/>
                    </template>
                  </iq-card>
                </b-col>
                <b-col lg="6" v-for="(item,index) in categoryRM" :key="index">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4>{{ item.title }}</h4>
                    </template>
                    <template v-slot:body>
                      <HighChart :ref="item.type" :option="item.bodyData"/>
                    </template>
                  </iq-card>
                </b-col>
                <b-col sm="6">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h5 class="card-title text-primary">
                        <strong>Matriks Realisasi R & M</strong>
                      </h5>
                    </template>
                    <template v-slot:body>
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="text-center">Group</th>
                            <th class="text-center">Rencana R & M (jam)</th>
                            <th class="text-center">Aktual R&M (jam)</th>
                            <th class="text-center">Realisasi (%)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Transhipment</th>
                            <td class="text-center">840 Jam</td>
                            <td class="text-center">216 Jam</td>
                            <td class="text-center">26%</td>
                          </tr>
                          <tr>
                            <th>Long Hauling</th>
                            <td class="text-center">48 Jam</td>
                            <td class="text-center">48 Jam</td>
                            <td class="text-center">0%</td>
                          </tr>
                          <tr>
                            <th>Fuel Storage</th>
                            <td class="text-center">168 Jam</td>
                            <td class="text-center">72 Jam</td>
                            <td class="text-center">43%</td>
                          </tr>
                          <tr>
                            <th>Assist Tug</th>
                            <td class="text-center">12 Jam</td>
                            <td class="text-center">9 Jam</td>
                            <td class="text-center">5%</td>
                          </tr>
                          <tr>
                            <th>TOTAL</th>
                            <th class="text-center">1056 Jam</th>
                            <th class="text-center">336 Jam</th>
                            <th class="text-center">32%</th>
                          </tr>
                        </tbody>
                      </table>
                    </template>
                  </iq-card>
                </b-col>
                <b-col sm="6">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h5 class="card-title text-primary">
                        <strong>Matriks Prosentase R & M</strong>
                      </h5>
                    </template>
                    <template v-slot:body>
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="text-center">Group</th>
                            <th class="text-center">Armada</th>
                            <th class="text-center">Jam Operasi</th>
                            <th class="text-center">Jam R & M</th>
                            <th class="text-center">Persentase R & M</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Transhipment</th>
                            <td class="text-center">17</td>
                            <td class="text-center">12.648 Jam</td>
                            <td class="text-center">216 Jam</td>
                            <td class="text-center">1.71%</td>
                          </tr>
                          <tr>
                            <th>Long Hauling</th>
                            <td class="text-center">1</td>
                            <td class="text-center">774 Jam</td>
                            <td class="text-center">72 Jam</td>
                            <td class="text-center">968%</td>
                          </tr>
                          <tr>
                            <th>Fuel Storage</th>
                            <td class="text-center">5</td>
                            <td class="text-center">168 Jam</td>
                            <td class="text-center">72 Jam</td>
                            <td class="text-center">43%</td>
                          </tr>
                          <tr>
                            <th>Assist Tug</th>
                            <td class="text-center">7</td>
                            <td class="text-center">12 Jam</td>
                            <td class="text-center">9 Jam</td>
                            <td class="text-center">5%</td>
                          </tr>
                          <tr>
                            <th>TOTAL</th>
                            <th class="text-center">3</th>
                            <th class="text-center">1056 Jam</th>
                            <th class="text-center">336 Jam</th>
                            <th class="text-center">32%</th>
                          </tr>
                        </tbody>
                      </table>
                    </template>
                  </iq-card>
                </b-col>
                <b-col sm="6">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h5 class="card-title text-primary">
                        <strong>Type R & M</strong>
                      </h5>
                    </template>
                    <template v-slot:body>
                      <table class="table table-striped table-sm">
                        <thead>
                          <tr>
                            <th class="text-center">PM (Job)</th>
                            <th class="text-center">RM (Job)</th>
                            <th class="text-center">PB (Job)</th>
                            <th class="text-center">EB (Job)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center">45 Jobs</td>
                            <td class="text-center">18 Jobs</td>
                            <td class="text-center">5 Jobs</td>
                            <td class="text-center">3 Jobs</td>
                          </tr>
                        </tbody>
                      </table>
                    </template>
                  </iq-card>
                </b-col>
                <b-col sm="6">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h5 class="card-title text-primary">
                        <strong>Status R & M</strong>
                      </h5>
                    </template>
                    <template v-slot:body>
                      <table class="table table-striped table-sm">
                        <tbody>
                          <tr>
                            <th>OPEN (On Progress Job)</th>
                            <td class="text-center">18 Jobs</td>
                          </tr>
                          <tr>
                            <th>CLOSE (Finish Job)</th>
                            <td class="text-center">45 Jobs</td>
                          </tr>
                        </tbody>
                      </table>
                    </template>
                  </iq-card>
                </b-col>
                <b-col sm="12">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h5 class="card-title text-primary">
                        <strong>Vessel Type R & M</strong>
                      </h5>
                    </template>
                    <template v-slot:body>
                      <table class="table table-striped">
                        <tbody>
                          <tr>
                            <th class="text-center">Fleet Type</th>
                            <th class="text-center">Total Job</th>
                            <th class="text-center">Minor</th>
                            <th class="text-center">Medium</th>
                            <th class="text-center">Major</th>
                          </tr>
                          <tr>
                            <td class="text-center">Tug Boat</td>
                            <td class="text-center">76 Jobs</td>
                            <td class="text-center">51 Jobs</td>
                            <td class="text-center">34 Jobs</td>
                            <td class="text-center">12 Jobs</td>
                          </tr>
                          <tr>
                            <td class="text-center">Barge</td>
                            <td class="text-center">84 Jobs</td>
                            <td class="text-center">15 Jobs</td>
                            <td class="text-center">53 Jobs</td>
                            <td class="text-center">9 Jobs</td>
                          </tr>
                          <tr>
                            <td class="text-center">Pusher Tug</td>
                            <td class="text-center">67 Jobs</td>
                            <td class="text-center">43 Jobs</td>
                            <td class="text-center">15 Jobs</td>
                            <td class="text-center">4 Jobs</td>
                          </tr>
                          <tr>
                            <td class="text-center">Pusher Barge</td>
                            <td class="text-center">91 Jobs</td>
                            <td class="text-center">56 Jobs</td>
                            <td class="text-center">31 Jobs</td>
                            <td class="text-center">14 Jobs</td>
                          </tr>
                        </tbody>
                      </table>
                    </template>
                  </iq-card>
                </b-col>
              </b-row>
            </tab-content-item>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal id="reject-modal" size="l" title="REJECT REPAIR & MAINTENANCE" ok-title="Save Changes" cancel-title="Close">
      <b-row>
        <b-col md="12">
          <b-form-group label="Select Repair List" label-for="repair_list">
            <b-form-select plain>
              <template>
                <b-form-select-option>Select Reason Group</b-form-select-option>
                <b-form-select-option>Reason 1</b-form-select-option>
                <b-form-select-option>Reason 2</b-form-select-option>
                <b-form-select-option>Reason 3</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <b-form-group label="Reason" label-for="reason">
            <b-form-textarea id="reason" rows="2" placeholder="Type Text ..."></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </b-container>
</template>
<script>
  import {
    xray
  } 
  from '../../config/pluginInit'
  import _ from 'lodash'
  import {
    fleetsActions
  } from '@src/Utils/helper'
  import {
    createPopper
  } from '@popperjs/core'
  export default {
    name: 'ProfileEdit',
    mounted() {
      xray.index()
      this.fetchFleets()
    },
    data() {
      return {
        // fleet
        showInitModal: false,
        showInitModalNext: false,
        select_all_fleets: false,
        loading_fleet: false,
        editingNode: null,
        // CHART
        classification:
        [
          {
            title: 'GRAPHIC CLASSIFICATION',
            bodyData: {
              chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
              },
              colorAxis: {},
              title: {
                text: 'Repair & Maintenance Group By Data Classification'
              },
              tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
              },
              plotOptions: {
                pie: {
                  allowPointSelect: true,
                  cursor: 'pointer',
                  dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                  }
                }
              },
              series: [{
                name: 'Brands',
                colorByPoint: true,
                data: [
                  {
                    name: 'Minor',
                    y: 20,
                    color: '#00ca00'
                  },
                  {
                    name: 'Medium',
                    y: 40,
                    color: '#0084ff'
                  },
                  {
                    name: 'Major',
                    y: 40,
                    color: '#e64141'
                  }
                ]
              }]
            }
          }
        ],
        cause:
        [
          {
            title: 'GRAPHIC CAUSE',
            bodyData: {
              chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
              },
              colorAxis: {},
              title: {
                text: 'Repair & Maintenance Group By Data Cause'
              },
              tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
              },
              plotOptions: {
                pie: {
                  allowPointSelect: true,
                  cursor: 'pointer',
                  dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                  }
                }
              },
              series: [{
                name: 'Brands',
                colorByPoint: true,
                data: [
                  {
                    name: 'Material Fatique',
                    y: 61.41,
                    color: '#0084ff'
                  },
                  {
                    name: 'Engine Failure',
                    y: 11.84,
                    color: '#00ca00'
                  },
                  {
                    name: 'Electrical Failure',
                    y: 10.85,
                    color: '#e64141'
                  },
                  {
                    name: 'Equipment Failure',
                    y: 4.67,
                    color: '#ffd400'
                  },
                  {
                    name: 'Human Error',
                    y: 4.18,
                    color: '#00d0ff'
                  },
                  {
                    name: 'Bad Weather',
                    y: 1.64,
                    color: '#374948'
                  },
                  {
                    name: 'RH Achieved',
                    y: 1.6,
                    color: '#E9EDF4'
                  },
                  {
                    name: 'Modification',
                    y: 1.2,
                    color: '#777D74'
                  },
                  {
                    name: 'New Installation',
                    y: 2.61,
                    color: '#ffcc00'
                  }
                ]
              }]
            }
          }
        ],
        typeRM:
        [
          {
            title: 'GRAPHIC TYPE REPAIR & MAINTENANCE',
            bodyData: {
              title: {
                text: 'Repair & Maintenance Group By Data Type RM'
              },
              yAxis: {
                title: {
                  text: 'Number of Job'
                }
              },
              xAxis: {
                  type: 'datetime'
              },
              plotOptions: {
                  series: {
                      pointStart: Date.UTC(2022, 0, 1),
                      pointIntervalUnit: 'month'
                  }
              },
              series:
              [
                {
                  name: 'Planing Maintenance',
                  data: [29.9, 71.5, 106.4, 129.2, 144.0, 176.0],
                  color: '#99cc33'
                },
                {
                  name: 'Running Maintenance',
                  data: [144.0, 176.0, 135.6, 148.5, 216.4, 194.1],
                  color: '#0099ff'
                },
                {
                  name: 'Planing Breakdown',
                  data: [50, 144.0, 176.0, 44.0, 176.0, 135.6],
                  color: '#ff9933'
                },
                {
                  name: 'Emergency Breakdown',
                  data: [10, 60, 80, 75, 90, 101],
                  color: '#ff3333'
                },
              ]
            }
          }
        ],
        costRM:
        [
          {
            title: 'GRAPHIC COST REPAIR & MAINTENANCE',
            bodyData: {
              chart: {
                renderTo: 'high-3d-chart',
                type: 'column',
                options3d: {
                  enabled: true,
                  alpha: 15,
                  beta: 15,
                  depth: 50,
                  viewDistance: 25
                }
              },
              title: {
                text: 'Repair & Maintenance Cost'
              },
              xAxis: {
                  type: 'datetime'
              },
              plotOptions: {
                  series: {
                      pointStart: Date.UTC(2022, 0, 1),
                      pointIntervalUnit: 'month'
                  }
              },
              series:
              [
                {
                  name: "Tug Boat",
                  data: [55, 71.5, 106.4, 129.2, 144.0, 120],
                  color: '#99cc33'
                },
                {
                  name: "Barge",
                  data: [65, 80, 90, 89, 101, 150],
                  color: '#0099ff'
                }
              ]
            }
          },
        ],
        equipmentRM:
        [
          {
            title: 'GRAPHIC EQUIPMENT REPAIR & MAINTENANCE',
            bodyData: {
              chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
              },
              colorAxis: {},
              title: {
                text: 'Repair & Maintenance Group By Equipment'
              },
              tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
              },
              plotOptions: {
                pie: {
                  allowPointSelect: true,
                  cursor: 'pointer',
                  dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                  }
                }
              },
              series: [{
                name: 'Brands',
                colorByPoint: true,
                data: [
                  {
                    name: 'ACCOMMODATION',
                    y: 21.41,
                    color: '#0084ff'
                  },
                  {
                    name: 'HULL & LOAD LINE',
                    y: 11.84,
                    color: '#00ca00'
                  },
                  {
                    name: 'TANK',
                    y: 10.85,
                    color: '#e64141'
                  },
                  {
                    name: 'MOORING & ANCHOR EQUIPMENT',
                    y: 4.67,
                    color: '#ffd400'
                  },
                  {
                    name: 'DECK & OUT FITTING EQUIPMENT',
                    y: 4.18,
                    color: '#00d0ff'
                  },
                  {
                    name: 'FIRE FIGHTING APPLIANCE',
                    y: 1.64,
                    color: '#374948'
                  },
                  {
                    name: 'LIFE SAVING APPLIANCE',
                    y: 1.6,
                    color: '#E9EDF4'
                  },
                  {
                    name: 'NAVIGATION EQUIPMENT',
                    y: 1.2,
                    color: '#777D74'
                  },
                  {
                    name: 'RADIO EQUIPMENT',
                    y: 2.61,
                    color: '#ffcc00'
                  },
                  {
                    name: 'AUXILARY ENGINE',
                    y: 18,
                    color: '#0099ff'
                  },
                  {
                    name: 'MAIN ENGINE',
                    y: 12,
                    color: '#cc9966'
                  },
                ]
              }]
            }
          }
        ],
        categoryRM:
        [
          {
            title: 'GRAPHIC CATEGORY R &M',
            bodyData: {
              chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
              },
              colorAxis: {},
              title: {
                text: 'Repair & Maintenance Group By Data Category R & M'
              },
              tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
              },
              plotOptions: {
                pie: {
                  allowPointSelect: true,
                  cursor: 'pointer',
                  dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                  }
                }
              },
              series: [{
                name: 'Brands',
                colorByPoint: true,
                data: [
                  {
                    name: 'Welding',
                    y: 25,
                    color: '#00ca00'
                  },
                  {
                    name: 'Mechanical',
                    y: 35,
                    color: '#0084ff'
                  },
                  {
                    name: 'Electrical',
                    y: 21,
                    color: '#e64141'
                  },
                  {
                    name: 'General Service',
                    y: 19,
                    color: '#ff9933'
                  }
                ]
              }]
            }
          }
        ],
        categoryRM:
        [
          {
            title: 'GRAPHIC DELAY FACTOR R &M',
            bodyData: {
              chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
              },
              colorAxis: {},
              title: {
                text: 'Repair & Maintenance Group By Data Delay Factor R & M'
              },
              tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
              },
              plotOptions: {
                pie: {
                  allowPointSelect: true,
                  cursor: 'pointer',
                  dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                  }
                }
              },
              series: [{
                name: 'Brands',
                colorByPoint: true,
                data: [
                  {
                    name: 'No Man Power',
                    y: 15,
                    color: '#00ca00'
                  },
                  {
                    name: 'Waiting Spare Part',
                    y: 28,
                    color: '#0084ff'
                  },
                  {
                    name: 'Bad Weather',
                    y: 21,
                    color: '#e64141'
                  },
                  {
                    name: 'Bad Location/ Position',
                    y: 19,
                    color: '#ff9933'
                  },
                  {
                    name: 'Operation Distrubtion',
                    y: 7,
                    color: '#00bef1'
                  },
                  {
                    name: 'Contractor Delay',
                    y: 10,
                    color: '#fff311'
                  }

                ]
              }]
            }
          }
        ],
        fleet_params: {
          page: 1,
          showAll: true,
          search: ''
        },
        fleetCategorySelect: [],
        fleetCategory:
        [
          {text: 'Tug Boat',color: 'success',value: 'tugboat'}, 
          {text: 'Barge',color: 'warning',value: 'barge'},
          {text: 'Mother Vessel',color: 'primary',value: 'mother_vessel'},
          {text: 'Heavy Equipment',color: 'primary',value: 'heavy_equipment'},
          {text: 'Pusher Tug',color: 'primary',value: 'pusher_tug'},
          {text: 'Pusher Barge',color: 'primary',value: 'pusher_barge'}
        ],
        dateRange: '',
        menus: [],
        checkedFleets: [],
        selectedJabatan: null,
        optionsJabatan:
        [
          {value: '1',text: 'Port Enginer'},
          {value: '2',text: 'Port Captain'},
          {value: '3',text: 'Technical Superintendent'},
          {value: '4',text: 'Technical Manager'}
        ],
        selectedTypeRM: null,
        optionsTypeRM:
        [
          {value: '1', text: 'Planning Maintenance'},
          {value: '2', text: 'Running Maintenance'},
          {value: '3', text: 'Planning Breakdown'},
          {value: '4', text: 'Emergency Breakdown'}
        ],
        selectedDepartment: null,
        optionsDepartment:
        [
          {value: '1', text: 'Deck'},
          {value: '2', text: 'Engine'}
        ],
        selectedClasification: null,
        clasification: [
          {value: '1', text: 'Minor (3 Days)'},
          {value: '2', text: 'Medium (7 Days)'}, 
          {value: '3', text: 'Major (21 Days)'}
        ],
        selectedFleets: null,
        kapal:
        [
          {value: '1', text: 'TB. ETI 201' },
          {value: '2', text: 'TB. ETI 202'},
          {value: '3', text: 'TB. TCP 301'}
        ],
        selectedCategory: null,
        category:
        [
          {value: '1', text: 'Welding' },
          {value: '2', text: 'Mechanical' },
          {value: '3', text: 'Electrical'},
          {value: '4', text: 'General Service'}
        ],
        selectedCause: null,
        optionsCause:
        [
          {value: '1',text: 'Material Fatigue'},
          {value: '2',text: 'Engine Failure'},
          {value: '3',text: 'Equipment Failure'},
          {value: '4',text: 'Human Error'},
          {value: '5',text: 'Bad Weather'},
          {value: '6',text: 'RH Achieved'},
          {value: '7',text: 'Modification'},
          {value: '8',text: 'New Installation'}
        ],
        selectedKindTask: null,
        kindTask:
        [
          {value: '1', text: 'Repair'},
          {value: '2', text: 'Renew'}
        ],
        text: [{
          activeLabel: 'Yes',
          inactiveLabel: 'No',
          class: 'primary',
          checked: true
        }],
        color: {
          color: 'success',
          checked: false
        },
        selectedRepairMaintenance: null,
        optionsRepairMaintenance: [
          {value: '1', text: 'Auxuliary Engine - Repair block engine AE kanan'}, 
          {value: '2', text: 'Main Engine - Resurface block engine'},
          {value: '3', text: 'Electrical - Repair jalur pendingin'},
          {value: '4', text: 'Main Engine - Pengukuran cylinder liner'},
          {value: '5', text: 'Main Engine - Pengukuran clearance main bearing'},
          {value: '6', text: 'Main Engine - Polish Crankshaft'},
          {value: '7', text: 'Main Engine - Cek & repair camshaft'}
        ],
        selectedActivity: null,
        optionsActivity: [
          {value: '1', text: 'Checking'}, 
          {value: '2', text: 'Work'},
          {value: '3', text: 'Submission PR/ SR'},
          {value: '4',text: 'Receive Spare Part/ Material'},
          {value: '5', text: 'Testing / Sea Trial'},
          {value: '6', text: 'No Man Power'},
          {value: '7', text: 'Waiting Spare Part'},
          {value: '8', text: 'Bad Weather'},
          {value: '9', text: 'Bad Location/ Position'},
          {value: '10', text: 'Operation Distrubtion'},
          {value: '11', text: 'Contractor Delay'},
          {value: '12', text: 'Closed'}
        ],
        selectedStatus: null,
        optionsStatus: [
          {value: '1', text: 'Review'},
          {value: '2', text: 'Approve'}
        ],
        tree:
        [
          {'text': 'ACCOMMODATION', 'opened': true, 'selected': false, 
          'children':
            [
              { 'text': 'WHEEL HOUSE', 'selected': false},
              { 'text': 'ENGINE ROOM', 'selected': false},
              { 'text': 'ACCOMODATION ROOM', 'selected': false},
            ]
          },
          {'text': 'HULL & LOAD LINE', 'opened': true, 'selected': false, 'children': 
            [
              {'text': 'HULL', 'selected': false},
              {'text': 'LOAD LINE', 'selected': false}
            ]
          },
          {'text': 'TANK', 'opened': true, 'selected': false, 'children': 
            [
              {'text': 'FUEL OIL TANK', 'selected': false},
              {'text': 'FUEL OIL TANK DAILY', 'selected': false},
              {'text': 'LUBRICATION OIL TANK', 'selected': false},
              {'text': 'HYDRAULIC OIL TANK', 'selected': false},
              {'text': 'FRESH WATER TANK', 'selected': false},
              {'text': 'BALLAST TANK', 'selected': false},
              {'text': 'DIRTY OIL TANK', 'selected': false},
              {'text': 'VOID TANK', 'selected': false},
              {'text': 'COFFERDAM', 'selected': false},
              {'text': 'CHAIN LOCKER', 'selected': false},
              {'text': 'CARGO TANK', 'selected': false}
            ]
          },
          {'text': 'MOORING & ANCHOR EQUIPMENT', 'opened': true, 'selected': false, 'children': 
            [
              {'text': 'TOWING HOOK & EQUIPMENT', 'selected': false},
              {'text': 'ANCHOR', 'selected': false},
              {'text': 'ANCHOR CHAIN CABLE', 'selected': false},
              {'text': 'ANCHOR WINDLASS', 'selected': false},
              {'text': 'MOORING ROPE CIR 8"', 'selected': false},
              {'text': 'MOORING ROPE CIR 10"', 'selected': false},
              {'text': 'MOORING ROPE CIR 12"', 'selected': false}
            ]
          },
          {'text': 'DECK & OUT FITTING EQUIPMENT', 'opened': true, 'selected': false, 'children': 
            [
              {'text': 'WATER TIGHT DOOR', 'selected': false},
              {'text': 'WATER TIGHT HATCH/ EMERGENCY HATCH', 'selected': false},
              {'text': 'WINDOWS', 'selected': false},
              {'text': 'ANCHOR WINDLASS', 'selected': false},
              {'text': 'MAST"', 'selected': false},
              {'text': 'SAFETY HAND RAILING"', 'selected': false},
              {'text': 'FENDER"', 'selected': false},
              {'text': 'BOLLARD"', 'selected': false}
            ]
          },
          {'text': 'FIRE FIGHTING APPLIANCE', 'opened': true, 'selected': false, 'children': 
            [
              {'text': 'BREATHING APPARATUS', 'selected': false},
              {'text': 'FIRE PROTECTION SUITS', 'selected': false},
              {'text': 'CO2 FIRE EXTINGUISHER', 'selected': false},
              {'text': 'DRY POWDER FIRE EXTINGUISHER', 'selected': false},
              {'text': 'FOAM FIRE EXTINGUISHER"', 'selected': false},
              {'text': 'FIRE HYDRANT"', 'selected': false},
              {'text': 'FIRE HOSE & NOZZLE"', 'selected': false},
              {'text': 'FIRE MONITOR"', 'selected': false},
              {'text': 'SMOKE / HEAT DETECTOR"', 'selected': false},
            ]
          },
        ]
      }
    },
    watch: {
      select_all_fleets: {
        deep: true,
        handler: function(checked) {
          this.checkedFleets = []
          if (checked) {
            this.fleets.forEach((f) => this.checkedFleets.push(f.id))
          }
        }
      }
    },
    created() {
      this.fetchFleets = _.debounce(this.fetchFleets, 1000)
    },
    methods: {
      ...fleetsActions,
      async fetchFleets() {
        this.loading_fleet = true
        let params = this.$options.filters.cleanObject(this.fleet_params)
        let res = await this.getFleets(params)
        if (!res.status) {
          this.loading_fleet = false
          this.fleets = []
        } else {
          this.loading_fleet = false
          this.fleets = res.data
        }
      },
      withPopper(dropdownList, component, {
        width
      }) {
        dropdownList.style.width = width
        const popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: this.placement,
          modifiers: [{
            name: 'offset',
            options: {
              offset: [0, -1]
            }
          }, {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({
              state
            }) {
              component.$el.classList.toggle('drop-up', state.placement === 'top')
            }
          }]
        })
        return () => popper.destroy()
      },
      itemClick(node) {
        console.log(node.model.text + ' clicked !')
      },
      customItemClick: function(node, item, e) {
        e.stopPropagation()
        var index = node.parentItem.indexOf(item)
        node.parentItem.splice(index, 1)
      },
      customItemClickWithCtrl: function() {
        console.log('click + ctrl')
      }
    }
  }
</script>