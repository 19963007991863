<template>
  <b-row class="mt-3">
    <b-col md="12">
      <div class="d-flex overflow-auto">
        <table class="table">
          <thead>
            <tr>
              <th
                v-for="(col, index) in columns"
                :key="index"
                class="text-truncate"
                style="background-color: #099bab; color: white"
                :class="col.style"
                >{{ col.label ?? col }}</th
              >
            </tr>
          </thead>
          <tbody>
            <!-- <tr v-for="(item, index) in data" :key="index"> -->
            <tr
              v-for="(job, index) in maintenance.data"
              :key="index"
              :style="`background:${index % 2 != 0 ? 'white' : '#f5f5f5'}`"
            >
              <td
                class="text-center sticky-column column-1"
                :style="`background:${index % 2 != 0 ? 'white' : '#f5f5f5'}`"
                >{{ index + 1 }}</td
              >
              <td
                class="text-truncate text-left sticky-column column-2"
                :style="`background:${index % 2 != 0 ? 'white' : '#f5f5f5'}`"
                ><a
                  href="#"
                  @click.prevent="
                    $emit('viewDetailMaintenance', {
                      id: job.id,
                      type: 'VIEW_DETAIL',
                    })
                  "
                  >{{ job.vehicleName }}</a
                ></td
              >
              <td
                class="text-truncate text-left sticky-column column-3"
                :style="`background:${index % 2 != 0 ? 'white' : '#f5f5f5'}`"
                >{{ job.jobName ?? '-' }}</td
              >
              <td class="text-truncate text-left">{{
                job.equipmentName ?? '-'
              }}</td>
              <td class="text-truncate text-left">{{ job.jobClass ?? '-' }}</td>
              <td class="text-truncate text-left">{{ job.trigger ?? '-' }}</td>
              <td class="text-truncate text-left">{{ job.interval ?? '-' }}</td>
              <td class="text-truncate text-left"
                >{{
                  job.lastMaintenanceDate
                    ? moment(job.lastMaintenanceDate, 'DD-MM-YYYY').format(
                        'DD MMM YYYY'
                      )
                    : '-'
                }}
                <span v-if="job.lastMaintenanceHours">|</span>
                {{ job.lastMaintenanceHours ?? '-' }}</td
              >
              <td class="text-truncate text-left">{{
                job.dueDate
                  ? moment(job.dueDate, 'DD-MM-YYYY')
                      .locale('id')
                      .format('DD MMMM YYYY')
                  : '-'
              }}</td>
              <td class="text-truncate text-left">
                <a
                  href="#"
                  class="p-1 d-flex align-items-center px-2 rounded-sm"
                  style="background-color: #eceeed"
                >
                  <i class="fa fa-download mr-2" style="color: #3862dd" />
                  Attachment.pdf
                </a></td
              >
              <td class="text-truncate text-left">
                <template v-if="job.status === 'OVERDUE'">
                  <b-badge :id="'popover-status' + index" variant="danger"
                    >OVERDUE /
                    {{
                      job.statusDetail && job.statusDetail.includes('days')
                        ? generateDayDurations(
                            parseInt(
                              job.statusDetail.replace('days', '').trim()
                            )
                          ).durationString
                        : job.statusDetail
                    }}</b-badge
                  >
                </template>
                <template v-else-if="job.status === 'TOWARDS'">
                  <b-badge :id="'popover-status' + index" variant="info"
                    >TOWARDS</b-badge
                  >
                </template>
                <template v-else-if="job.status === 'REVIEW'">
                  <b-badge :id="'popover-status' + index" variant="warning"
                    >REVIEW</b-badge
                  >
                </template>
                <template v-else-if="job.status === 'APPROVE'">
                  <b-badge :id="'popover-status' + index" variant="success"
                    >APPROVE</b-badge
                  >
                </template>
                <template v-else-if="job.status === 'POSTPONE'">
                  <b-badge :id="'popover-status' + index" variant="info"
                    >TOWARDS (POSTPONE)</b-badge
                  >
                </template>
                <template v-else-if="job.status === 'NEW'">
                  <b-badge :id="'popover-status' + index" variant="primary"
                    >ACTIVE</b-badge
                  >
                </template>
                <template v-else>
                  <b-badge variant="light">{{ job.status }}</b-badge>
                </template></td
              >
              <td>
                <b-dropdown
                  size="sm"
                  variant="link iq-bg-primary iq-border-radius-10"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <span
                      class="dropdown-toggle p-0"
                      id="dropdownMenuButton5"
                      data-toggle="dropdown"
                    >
                      Actions
                    </span>
                  </template>
                  <b-dropdown-item
                    size="sm"
                    href="#"
                    v-if="
                      canAdd &&
                      ['TOWARDS', 'OVERDUE', 'POSTPONE', 'NEW'].includes(
                        job.status
                      )
                    "
                    @click.prevent="
                      $emit('viewDetailMaintenance', {
                        id: job.id,
                        type: 'ON_MAINTENANCE',
                      })
                    "
                    variant="primary"
                    ><i class="fa fa-tasks"></i> Maintenance</b-dropdown-item
                  >
                  <b-dropdown-item
                    size="sm"
                    href="#"
                    v-if="['APPROVE'].includes(job.status) && canApprove"
                    variant="info"
                    @click.prevent="$emit('onApprove', job.id)"
                    ><i class="fa fa-thumbs-up"></i> Approve</b-dropdown-item
                  >
                  <b-dropdown-item
                    size="sm"
                    href="#"
                    v-if="['TOWARDS', 'OVERDUE', 'NEW'].includes(job.status)"
                    @click.prevent="
                      $emit('viewDetailMaintenance', {
                        id: job.id,
                        type: 'ON_POSTPONE',
                      })
                    "
                    variant="warning"
                    ><i class="fa fa-clock"></i> Postpone</b-dropdown-item
                  >
                  <b-dropdown-item
                    size="sm"
                    href="#"
                    v-if="
                      ['MAINTENANCE', 'REVIEW'].includes(job.status) &&
                      canReview
                    "
                    @click.prevent="$emit('onReview', job.id)"
                    variant="info"
                    ><i class="fa fa-search"></i> Review</b-dropdown-item
                  >
                  <b-dropdown-item
                    size="sm"
                    href="#"
                    v-if="['APPROVE', 'REVIEW'].includes(job.status)"
                    @click.prevent="
                      $emit('viewDetailMaintenance', {
                        id: job.id,
                        type: 'ON_MAINTENANCE',
                        isEdit: true,
                      })
                    "
                    variant="primary"
                    ><i class="fa fa-pen"></i> Edit</b-dropdown-item
                  >
                  <b-dropdown-item
                    size="sm"
                    href="#"
                    v-if="['TOWARDS', 'OVERDUE', 'NEW'].includes(job.status)"
                    variant="danger"
                    @click.prevent="
                      $emit('viewDetailMaintenance', {
                        id: job.id,
                        type: 'ON_CONVERT',
                      })
                    "
                    ><i class="fa fa-tools"></i> Convert Repair</b-dropdown-item
                  >
                </b-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <template v-if="!maintenance.isLoading && !maintenance.data?.length">
        <p class="text-black"> No data available </p>
      </template>
    </b-col>
  </b-row>
</template>

<script>
import moment from 'moment'
export default {
  name: 'ProgressList',
  props: {
    maintenance: {
      type: Object,
      default: () => {
        return {
          data: [],
          meta: null,
          isLoading: false,
        }
      },
    },
    canAdd: Boolean,
    canApprove: Boolean,
    canReview: Boolean,
  },
  data() {
    return {
      columns: [
        {
          label: 'No',
          style: 'sticky-column column-1',
        },
        {
          label: 'Fleet Name',
          style: 'sticky-column column-2',
        },
        {
          label: 'Job Name',
          style: 'sticky-column column-3',
        },
        'Equipment Name',
        'Job Class',
        'Trigger',
        'Frequency',
        'Last Maintenance',
        'Due Date / Hours',
        'Attachment',
        'Status',
        'Action',
      ],
    }
  },
  methods: {
    generateDayDurations(days) {
      let start = moment()
      let end = moment().add(days, 'days')
      let difference = moment(end).diff(moment(start))
      let duration = moment.duration(difference)
      let durationString = ''

      if (Math.round(duration.years()) > 0) {
        durationString += `${Math.round(duration.years())} years `
      }

      if (Math.round(duration.months()) > 0) {
        durationString += `${Math.round(duration.months())} months `
      }

      if (Math.round(duration.weeks()) > 0) {
        durationString += `${Math.round(duration.weeks())} weeks `
      }

      if (Math.round(duration.days()) > 0) {
        durationString += `${Math.round(duration.days())} days `
      }

      return {
        start,
        end,
        duration: difference / 1000,
        durationString,
      }
    },
  },
  mounted() {},
}
</script>

<style scoped>
.sticky-column {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
}
.column-1 {
  left: 0;
}
.column-2 {
  left: 2.4rem;
}
.column-3 {
  left: 9.4rem;
}

.custom {
  background-color: '#099BAB' !important;
  color: white !important;
}
</style>
