var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('form', {
    attrs: {
      "action": "#",
      "method": "post"
    },
    on: {
      "submit": _vm.onSave
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('b', [_vm._v("CLAIM FORM")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$form;
        return [_c('b-row', [_c('b-container', {
          attrs: {
            "fluid": ""
          }
        }, [_c('b-row', [_c('b-col', {
          staticClass: "text-center mb-3",
          attrs: {
            "cols": "12"
          }
        }, [_vm._l(_vm.typeFormInsurance, function (item, index) {
          return [_c('b-form-radio', {
            key: index,
            staticClass: "custom-radio-color-checked",
            attrs: {
              "inline": "",
              "color": item.color,
              "name": "color",
              "value": item.value,
              "disabled": item.disabled
            },
            model: {
              value: _vm.form.type_form,
              callback: function callback($$v) {
                _vm.$set(_vm.form, "type_form", $$v);
              },
              expression: "form.type_form"
            }
          }, [_c('strong', [_c('h5', [_vm._v(_vm._s(item.label))])])])];
        })], 2), _c('b-col', {
          staticClass: "mb-3",
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('h5', {
          staticClass: "card-title text-success"
        }, [_c('b', [_vm._v("INFORMATION OF ACCIDENT/INCIDENT")])]), _c('b-form-group', {
          staticClass: "row",
          attrs: {
            "label-cols-sm": "3",
            "label": "Company *",
            "label-for": "company"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.companies,
            "reduce": function reduce(company) {
              return company.value;
            }
          },
          model: {
            value: _vm.form.company_id,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "company_id", $$v);
            },
            expression: "form.company_id"
          }
        })], 1), _c('b-form-group', {
          staticClass: "row",
          attrs: {
            "label-cols-sm": "3",
            "label": "Fleet Name *",
            "label-for": "fleets"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "name",
            "options": _vm.fleets,
            "reduce": function reduce(fleet) {
              return fleet.code;
            }
          },
          model: {
            value: _vm.form.vehicle_id,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "vehicle_id", $$v);
            },
            expression: "form.vehicle_id"
          }
        })], 1), _c('b-form-group', {
          staticClass: "row",
          attrs: {
            "label-cols-sm": "3",
            "label": "Date of Loss (DOL) *",
            "label-for": "dol"
          }
        }, [_c('date-picker', {
          staticStyle: {
            "width": "100%"
          },
          attrs: {
            "type": "date",
            "placeholder": "Select Date Time",
            "value-type": "YYYY-MM-DD",
            "format": "DD MMM YYYY"
          },
          model: {
            value: _vm.form.date_of_loss,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "date_of_loss", $$v);
            },
            expression: "form.date_of_loss"
          }
        })], 1), _c('b-form-group', {
          staticClass: "row",
          attrs: {
            "label-cols-sm": "3",
            "label": "Place of Loss (POL) *",
            "label-for": "pol"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.form.place_of_loss,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "place_of_loss", $$v);
            },
            expression: "form.place_of_loss"
          }
        })], 1), _c('b-form-group', {
          staticClass: "row",
          attrs: {
            "label-cols-sm": "3",
            "label": "Type of Coverage *",
            "label-for": "coverage"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "name",
            "options": _vm.typeCoverages,
            "reduce": function reduce(coverage) {
              return coverage.code;
            }
          },
          model: {
            value: _vm.form.type_coverage_id,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "type_coverage_id", $$v);
            },
            expression: "form.type_coverage_id"
          }
        })], 1), _c('b-form-group', {
          staticClass: "row",
          attrs: {
            "label-cols-sm": "3",
            "label": "Type of Loss *",
            "label-for": "loss"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "name",
            "options": _vm.typeLoss,
            "reduce": function reduce(loss) {
              return loss.code;
            }
          },
          model: {
            value: _vm.form.type_loss_id,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "type_loss_id", $$v);
            },
            expression: "form.type_loss_id"
          }
        })], 1)], 1), _c('b-col', {
          staticClass: "mb-3",
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('h5', {
          staticClass: "card-title text-success"
        }, [_c('b', [_vm._v("CLAIMANT")])]), _c('b-form-group', {
          staticClass: "row",
          attrs: {
            "label-cols-sm": "3",
            "label": "Company *",
            "label-for": "company"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.companies,
            "reduce": function reduce(company) {
              return company.value;
            }
          },
          model: {
            value: _vm.form.company_claimant_id,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "company_claimant_id", $$v);
            },
            expression: "form.company_claimant_id"
          }
        })], 1), _c('b-form-group', {
          staticClass: "row",
          attrs: {
            "label-cols-sm": "3",
            "label": "Name *",
            "label-for": "name"
          }
        }, [_c('b-form-input', {
          attrs: {
            "required": "",
            "type": "text"
          },
          model: {
            value: _vm.form.name,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "name", $$v);
            },
            expression: "form.name"
          }
        })], 1), _c('b-form-group', {
          staticClass: "row",
          attrs: {
            "label-cols-sm": "3",
            "label": "Position *",
            "label-for": "position"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.form.position,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "position", $$v);
            },
            expression: "form.position"
          }
        })], 1), _c('b-form-group', {
          staticClass: "row",
          attrs: {
            "label-cols-sm": "3",
            "label": "Department of Fleet *",
            "label-for": "departments"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "name",
            "options": _vm.departments,
            "reduce": function reduce(department) {
              return department.code;
            }
          },
          model: {
            value: _vm.form.department_id,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "department_id", $$v);
            },
            expression: "form.department_id"
          }
        })], 1), _c('b-form-group', {
          staticClass: "row",
          attrs: {
            "label-cols-sm": "3",
            "label": "Cause of Loss *",
            "label-for": "cause-of-loss"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "name",
            "options": _vm.causesOfLoss,
            "reduce": function reduce(causesOfLoss) {
              return causesOfLoss.code;
            }
          },
          model: {
            value: _vm.form.cause_of_loss_id,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "cause_of_loss_id", $$v);
            },
            expression: "form.cause_of_loss_id"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('h5', {
          staticClass: "card-title text-success"
        }, [_c('b', [_vm._v("DETAILS OF CHRONOLOGY")])]), _c('b-form-group', {
          attrs: {
            "label": "Decribe of Detail Chronology"
          }
        }, [_c('wysiwyg', {
          model: {
            value: _vm.form.details_of_chronology,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "details_of_chronology", $$v);
            },
            expression: "form.details_of_chronology"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('h5', {
          staticClass: "card-title text-success"
        }, [_c('b', [_vm._v("ESTIMATE DAMAGE OR LOSS")])]), _c('b-form-group', {
          attrs: {
            "label": "Decribe of Estimate Damage or Loss"
          }
        }, [_c('wysiwyg', {
          model: {
            value: _vm.form.estimate_damage,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "estimate_damage", $$v);
            },
            expression: "form.estimate_damage"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "6",
            "md": "6"
          }
        }, [_c('h5', {
          staticClass: "card-title text-success"
        }, [_c('b', [_vm._v("CONCLUSION")])]), _c('b-form-group', {
          attrs: {
            "label": "Decribe of Conclusion"
          }
        }, [_c('wysiwyg', {
          model: {
            value: _vm.form.conclusion,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "conclusion", $$v);
            },
            expression: "form.conclusion"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "6",
            "md": "6"
          }
        }, [_c('h5', {
          staticClass: "card-title text-success"
        }, [_c('b', [_vm._v("COST")])]), _c('b-form-group', {
          attrs: {
            "label": "Estimate your cost"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('div', {
          staticClass: "input-group-append"
        }, [_c('span', {
          staticClass: "input-group-text"
        }, [_vm._v("Rp")])]), _c('b-form-input', {
          attrs: {
            "step": ".001",
            "type": "number"
          },
          model: {
            value: _vm.form.cost,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "cost", $$v);
            },
            expression: "form.cost"
          }
        })], 1), _c('b-form-text', {
          staticClass: "text-muted"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.form.cost)) + " ")])], 1)], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "12"
          }
        }, [_c('h5', {
          staticClass: "card-title text-success"
        }, [_c('b', [_vm._v("FILE / DOCUMENT SUPPORT")])]), _c('table', {
          staticClass: "table mb-0 table-borderless table-box-shadow"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v("No.")]), _c('th', {
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("Name of File / Document")]), _c('th', {
          attrs: {
            "width": "35%"
          }
        }, [_vm._v("File / Document")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("#")])])]), _c('tbody', _vm._l((_vm$form = _vm.form) === null || _vm$form === void 0 ? void 0 : _vm$form.fileSupport, function (fileSupport, index) {
          return _c('tr', {
            key: index
          }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_c('b-form-input', {
            attrs: {
              "required": "",
              "type": "text",
              "placeholder": "Name of File / Document Support",
              "value": ""
            },
            on: {
              "change": function change($event) {
                return _vm.onUpdateDocument(fileSupport);
              }
            },
            model: {
              value: fileSupport.name,
              callback: function callback($$v) {
                _vm.$set(fileSupport, "name", $$v);
              },
              expression: "fileSupport.name"
            }
          })], 1), _c('td', [fileSupport.file ? _c('div', {
            staticClass: "d-flex justify-content-between"
          }, [fileSupport.existed ? _c('span', {
            staticClass: "text-primary",
            staticStyle: {
              "cursor": "pointer"
            },
            on: {
              "click": function click($event) {
                return _vm.downloadFile(_vm.form.fileSupport[index].file);
              }
            }
          }, [_vm._v(_vm._s(_vm.form.fileSupport[index].name + _vm.form.fileSupport[index].extension))]) : _vm._e(), !fileSupport.existed ? _c('span', [_vm._v(_vm._s(_vm.form.fileSupport[index].file_label))]) : _vm._e()]) : [_vm.uploading ? _c('span', {
            staticClass: "spinner-border spinner-border-sm text-primary"
          }) : _c('div', {
            staticClass: "custom-file"
          }, [_c('input', {
            staticClass: "custom-file-input",
            attrs: {
              "type": "file",
              "required": "",
              "accept": "image/*,application/pdf,application/vnd.ms-excel,application/msword",
              "id": "validatedCustomFile"
            },
            on: {
              "change": function change($event) {
                return _vm.onDocumentsFileChanged(index, $event);
              }
            }
          }), _c('label', {
            staticClass: "custom-file-label",
            attrs: {
              "for": "validatedCustomFile"
            }
          }, [_vm._v("Browse file...")])])]], 2), _c('td', [[_c('b-button', {
            staticClass: "mb-3 mr-1",
            attrs: {
              "size": "sm",
              "variant": "danger"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.removeFileSupportList(index);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })])]], 2)]);
        }), 0)]), _c('b-button', {
          staticClass: "mt-2 mb-3",
          attrs: {
            "variant": "warning",
            "block": ""
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.addFileSupportList();
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" Add More File Support")])], 1), _c('b-col', {
          attrs: {
            "cols": "6",
            "md": "6"
          }
        }, [_c('h5', {
          staticClass: "card-title text-success"
        }, [_c('b', [_vm._v("SEND MAIL NOTIFICATION TO USERS")])]), _c('b-row', [_c('b-col', {
          attrs: {
            "lg": "12"
          }
        }, [_c('b-form-group', [_c('v-multiselect', {
          attrs: {
            "placeholder": "Notif user",
            "label": "name",
            "track-by": "id",
            "options": _vm.optionsExpertUsers,
            "multiple": true
          },
          model: {
            value: _vm.notifMailUser,
            callback: function callback($$v) {
              _vm.notifMailUser = $$v;
            },
            expression: "notifMailUser"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Data not found.")])])], 1)], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "6",
            "md": "6"
          }
        }, [_c('h5', {
          staticClass: "card-title text-success"
        }, [_c('b', [_vm._v("SEND MAIL APPROVAL TO USERS")])]), _c('b-row', [_c('b-col', {
          attrs: {
            "lg": "12"
          }
        }, [_c('b-form-group', [_c('v-multiselect', {
          attrs: {
            "placeholder": "Notif approval user",
            "label": "name",
            "track-by": "id",
            "options": _vm.optionsExpertUsers,
            "multiple": true
          },
          model: {
            value: _vm.approvalMailUser,
            callback: function callback($$v) {
              _vm.approvalMailUser = $$v;
            },
            expression: "approvalMailUser"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Data not found.")])])], 1)], 1)], 1)], 1), _c('b-col', {
          staticClass: "pull-right text-center mt-2",
          attrs: {
            "cols": "12",
            "md": "12"
          }
        }, [_c('b-form-checkbox', {
          staticClass: "mb-3",
          model: {
            value: _vm.statementTruth,
            callback: function callback($$v) {
              _vm.statementTruth = $$v;
            },
            expression: "statementTruth"
          }
        }, [_vm._v(" I Believe (The Claimant Believes) that the facts stated in these particulars of accident / incident are true ")])], 1), _c('b-col', {
          staticClass: "pull-right text-center mt-3",
          attrs: {
            "cols": "12",
            "md": "12"
          }
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "disabled": !_vm.statementTruth || _vm.form._saving,
            "variant": "primary"
          }
        }, [_vm._v(_vm._s(_vm.form._saving ? 'Menyimpan...' : 'Simpan'))])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }