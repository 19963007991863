var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "27",
      "height": "26",
      "viewBox": "0 0 27 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M5.5768 5.34424H18.0768V6.59424H5.5768V5.34424ZM5.5768 11.5942H11.894V12.8442H5.5768V11.5942ZM17.5909 8.46924H5.5768V9.71924H16.3416L17.5909 8.46924ZM9.32212 21.6505C9.53217 21.8913 9.79876 22.0762 10.0979 22.1886C10.3971 22.301 10.7195 22.3373 11.0362 22.2942H17.1331C17.2781 22.2942 17.4172 22.2366 17.5198 22.1341C17.6223 22.0315 17.6799 21.8924 17.6799 21.7474C17.6799 21.6023 17.6223 21.4632 17.5198 21.3607C17.4172 21.2581 17.2781 21.2005 17.1331 21.2005H11.0362C10.3245 21.188 10.451 20.57 10.551 20.3786C10.6811 20.1538 10.7827 19.9137 10.8534 19.6638C10.9005 19.4626 10.8787 19.2514 10.7914 19.0641C10.7041 18.8768 10.5565 18.7243 10.3721 18.631C10.0764 18.4978 9.74513 18.4653 9.4291 18.5382C9.11307 18.6112 8.82963 18.7856 8.62212 19.0349C8.35337 19.3122 7.97993 19.7364 7.62212 20.1489C7.84321 19.2825 8.12993 18.1708 8.41118 17.0864C8.49177 16.8393 8.47573 16.5709 8.36628 16.3352C8.25682 16.0995 8.0621 15.914 7.82133 15.8161C7.5547 15.7246 7.26329 15.7377 7.00592 15.8527C6.74855 15.9677 6.54438 16.176 6.43462 16.4356C6.09712 17.0849 3.42524 21.5622 3.39868 21.6075C3.36185 21.6692 3.33752 21.7375 3.32709 21.8086C3.31666 21.8796 3.32032 21.952 3.33788 22.0217C3.37334 22.1623 3.46321 22.2831 3.58774 22.3575C3.71227 22.4319 3.86124 22.4538 4.0019 22.4183C4.14255 22.3829 4.26336 22.293 4.33774 22.1685C4.43774 22.0005 6.57055 18.4278 7.24321 17.2349C6.73696 19.188 6.17915 21.3614 6.14633 21.5567C6.10953 21.7003 6.12068 21.852 6.17809 21.9887C6.23549 22.1254 6.336 22.2395 6.4643 22.3138C6.60731 22.3847 6.76986 22.406 6.92632 22.3743C7.08277 22.3426 7.22422 22.2597 7.32837 22.1388C7.4268 22.0497 7.61118 21.838 8.07602 21.2981C8.50911 20.7888 8.95269 20.2884 9.40649 19.7974C9.5768 19.6177 9.67993 19.6974 9.57993 19.8739C9.38424 20.1211 9.25734 20.4156 9.21207 20.7275C9.16679 21.0395 9.20476 21.3579 9.32212 21.6505Z",
      "fill": _vm.color
    }
  }), _c('path', {
    attrs: {
      "d": "M21.2031 15.8867V23.9375H2.45313V2.0625H21.2031V5.96328L22.2422 4.92422C22.4021 4.76645 22.5775 4.62511 22.7656 4.50234V1.28125C22.7656 1.07405 22.6833 0.875336 22.5368 0.728823C22.3903 0.58231 22.1916 0.5 21.9844 0.5H1.67188C1.46467 0.5 1.26596 0.58231 1.11945 0.728823C0.972935 0.875336 0.890625 1.07405 0.890625 1.28125V24.7188C0.890625 24.926 0.972935 25.1247 1.11945 25.2712C1.26596 25.4177 1.46467 25.5 1.67188 25.5H21.9844C22.1916 25.5 22.3903 25.4177 22.5368 25.2712C22.6833 25.1247 22.7656 24.926 22.7656 24.7188V15.6773C22.2867 15.9319 21.7322 16.0062 21.2031 15.8867Z",
      "fill": _vm.color
    }
  }), _c('path', {
    attrs: {
      "d": "M25.9912 8.2038L25.5826 7.7952C25.7912 7.51912 25.8967 7.17886 25.881 6.83322C25.8653 6.48759 25.7294 6.15829 25.4967 5.90224C25.1943 5.6357 24.7988 5.49945 24.3964 5.52315C23.994 5.54686 23.6173 5.7286 23.3483 6.0288L13.5631 15.8124L12.9092 18.2671C12.8989 18.2992 12.8976 18.3336 12.9056 18.3663C12.9135 18.3991 12.9304 18.4291 12.9544 18.4529C12.9783 18.4766 13.0084 18.4933 13.0412 18.5011C13.0741 18.5088 13.1084 18.5073 13.1405 18.4968L15.5865 17.835L24.7209 8.70067L25.1069 9.08739C25.1339 9.11426 25.1554 9.14621 25.17 9.18141C25.1847 9.2166 25.1922 9.25435 25.1922 9.29247C25.1922 9.33059 25.1847 9.36834 25.17 9.40353C25.1554 9.43873 25.1339 9.47068 25.1069 9.49755L21.2655 13.339C21.1483 13.4562 21.0825 13.6152 21.0826 13.781C21.0826 13.9468 21.1486 14.1058 21.2658 14.2229C21.3831 14.3401 21.5421 14.4059 21.7079 14.4058C21.8737 14.4057 22.0327 14.3398 22.1498 14.2225L25.9912 10.3811C26.1344 10.2382 26.2479 10.0684 26.3254 9.88156C26.4029 9.69469 26.4428 9.49438 26.4428 9.29208C26.4428 9.08978 26.4029 8.88947 26.3254 8.7026C26.2479 8.51572 26.1344 8.34596 25.9912 8.20302",
      "fill": _vm.color
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }