<template>
  <b-row>
    <b-col lg="12">
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title text-primary"><strong>OPERATIONAL HISTORY - {{ fleet.name }}</strong></h4>
        </template>
        <template v-slot:body>
          <b-row>
            <b-col md="4">
              <b-form-group label="Type" label-for="type">
                <b-form-select  
                  plain
                  size="sm"
                  placeholder="Select type..."
                  v-model="params.type"
                >
                  <option value="">All</option>
                  <option value="progress">Progress</option>
                  <option value="finish">finish</option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Date" label-for="date">
                <date-picker v-model="dateRange" type="date" range placeholder="Select date range" format="DD-MM-YYYY"></date-picker>
              </b-form-group>
            </b-col>
            <b-col md="1">
              <b-form-group label="Filter" label-for="filter">
                <b-button variant="primary" type="button" @click="fetchVoyageByTrips"><i class="fa fa-filter"></i></b-button>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-row>
                <b-col md="12">
                  <div class="d-flex flex-column mb-2">
                      <div class="d-flex overflow-auto" v-if="list.length > 0">
                        <table class="table table-striped ">
                          <thead>
                            <tr>
                              <th rowspan="2" style="width:5px" class="text-center table-warning text-truncate align-middle">NO</th>
                              <th rowspan="2" class="text-center table-warning text-truncate align-middle">FLEET</th>                       
                              <th rowspan="2" class="text-center table-warning text-truncate align-middle">CARGO TYPE</th>                       
                              <th rowspan="2" class="text-center table-warning text-truncate align-middle">CARGO LOADING</th>                       
                              <th rowspan="2" class="text-center table-warning text-truncate align-middle">CARGO UNLOADING</th>  
                              <th rowspan="2" class="table-warning text-truncate align-middle">START DATE</th>
                              <th rowspan="2" class="table-warning text-truncate align-middle">END DATE</th>
                              <th colspan="15" class="table-primary text-center">Port of Loading</th>
                              <th colspan="15" class="table-info text-center">Port of Discharge</th>
                              <th rowspan="2" class="table-warning text-center align-middle" style="width:5px; position: sticky; right: 0; z-index: 1">#</th>
                            </tr>
                            <tr>
                              <th class="table-primary text-truncate">Arival Time POL</th>
                              <th class="table-primary text-truncate">BL Date</th>
                              <th class="table-primary text-truncate">NOR</th>
                              <th class="table-primary text-truncate">NOA</th>
                              <th class="table-primary text-truncate">Anchor Up</th>
                              <th class="table-primary text-truncate">In Position / Berthing</th>
                              <th class="table-primary text-truncate">Commenced</th>
                              <th class="table-primary text-truncate">Completed</th>
                              <th class="table-primary text-truncate">Draught Survey</th>
                              <th class="table-primary text-truncate">Cast In</th>
                              <th class="table-primary text-truncate">Cast Out</th>
                              <th class="table-primary text-truncate">LHV</th>
                              <th class="table-primary text-truncate">Document on Board</th>
                              <th class="table-primary text-truncate">Departure to POD</th>
                              <th class="table-primary text-truncate">Remarks</th>
           
                              <th class="table-info text-truncate">Arival Time POD</th>
                              <th class="table-info text-truncate">BL Date</th>
                              <th class="table-info text-truncate">NOR</th>
                              <th class="table-info text-truncate">NOA</th>
                              <th class="table-info text-truncate">Anchor Up</th>
                              <th class="table-info text-truncate">In Position / Berthing</th>
                              <th class="table-info text-truncate">Commenced</th>
                              <th class="table-info text-truncate">Completed</th>
                              <th class="table-info text-truncate">Draught Survey</th>
                              <th class="table-info text-truncate">Cast In</th>
                              <th class="table-info text-truncate">Cast Out</th>
                              <th class="table-info text-truncate">LHV</th>
                              <th class="table-info text-truncate">Document on Board</th>
                              <th class="table-info text-truncate">Departure to POL</th>
                              <th class="table-info text-truncate">Remarks</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(state, index) in list" :key="index">
                              <td>{{index + 1}}</td>
                              <td class="text-truncate">
                                <div class="d-flex flex-column align-items-center">
                                  <template v-if="state?.vehicle?.length > 0">
                                    <strong v-for="(val, index) in state?.vehicle">{{ val?.name ?? '-' }}</strong>
                                  </template>
                                  <b-badge 
                                    :class="`border text-xl ${state?.tripRecord?.status === 'Progress' ? 'border-dark text-warning' : 
                                    state?.tripRecord?.status === 'Finish' ? 'border-dark text-success' : 
                                    'border-dark text-primary'} text-uppercase mr-2`" variant="none"
                                  >{{ state?.tripRecord?.status ?? '-' }}</b-badge>
                                </div>
                              </td>
                              <td>{{ state?.tripRecord?.cargoType ?? '-' }}</td>
                              <td>{{ state?.tripRecord?.cargoLoading ?? '0' }}</td>
                              <td>{{ state?.tripRecord?.cargoUnloading ?? '0' }}</td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.startDate ? moment(state?.tripRecord?.startDate, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.endDate ? moment(state?.tripRecord?.endDate, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.polArrivalTime ? moment(state?.tripRecord?.polArrivalTime, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate text-center">-</td>
                              <td class="text-truncate text-center">-</td>
                              <td class="text-truncate text-center">-</td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.polAnchorUp ? moment(state?.tripRecord?.polAnchorUp, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.polBerthing ? moment(state?.tripRecord?.polBerthing, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.polCommenced ? moment(state?.tripRecord?.polCommenced, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.polCompleted ? moment(state?.tripRecord?.polCompleted, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate text-center">{{state?.tripRecord?.polDraughtSurvey ?? 0}}</td>
                              <td class="text-truncate text-center">-</td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.polCastOut ? moment(state?.tripRecord?.polCastOut, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td> 
                              <td class="text-truncate">
                                {{ state?.tripRecord?.polLhv ? moment(state?.tripRecord?.polLhv, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.polDocOnBoard ? moment(state?.tripRecord?.polDocOnBoard, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.polDeparture ? moment(state?.tripRecord?.polDeparture, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate text-center">-</td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.podArrivalTime ? moment(state?.tripRecord?.podArrivalTime, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate text-center">-</td>
                              <td class="text-truncate text-center">-</td>
                              <td class="text-truncate text-center">-</td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.podAnchorUp ? moment(state?.tripRecord?.podAnchorUp, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.podBerthing ? moment(state?.tripRecord?.podBerthing, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.podCommenced ? moment(state?.tripRecord?.podCommenced, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.podCompleted ? moment(state?.tripRecord?.podCompleted, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate text-center">{{state?.tripRecord?.podDraughtSurvey ?? 0}}</td>
                              <td class="text-truncate text-center">-</td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.podCastOut ? moment(state?.tripRecord?.podCastOut, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td> 
                              <td class="text-truncate">
                                {{ state?.tripRecord?.podLhv ? moment(state?.tripRecord?.podLhv, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.podDocOnBoard ? moment(state?.tripRecord?.podDocOnBoard, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate">
                                {{ state?.tripRecord?.podDeparture ? moment(state?.tripRecord?.podDeparture, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                              </td>
                              <td class="text-truncate text-center">-</td>
                              <td class="text-center align-middle table-warning" style="width:5px; position: sticky; right: 0; z-index: 1">
                                <span class="p-0" @click="openSlideDetailRealisasi('PREVIEW', state)" style="cursor:pointer">
                                  <i class="fa fa-eye m-0 text-primary text-xl"></i>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                </b-col>
                <b-col md="12" class="text-center">
                  <div class="mt-3" v-if="list.length > 0">
                    <b-pagination v-model="page" :total-rows="1" align="center" />
                  </div>
                  <strong class="text-center text-muted" v-else>Data not found.</strong>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </template>
      </iq-card>
    </b-col>

     <!-- SLIDE - DETAIL REALISASI -->
     <b-col md="12">
        <b-sidebar
          v-model="slideDetailRealisasi"
          id="slideDetailRealisasi"
          :title="`Detail Real Shipment`"
          width="100%"
          backdrop-variant="dark"
          bg-variant="white"
          right
          backdrop
          shadow>
          <template #default="{hide}">
            <SlideDetailRealisasi 
              :id="id" 
            />
          </template>
        </b-sidebar>
      </b-col>
      <!-- SLIDE - DETAIL REALISASI END -->
  </b-row>
</template>

<script>
import { fleetsActions, voyagesActions } from '@src/Utils/helper'
import SlideDetailRealisasi from '../Busdev/Dashboard/SlideDetailRealisasi.vue'
import moment from 'moment'

export default {
  name: 'TabVoyageByTrip',
  props:{
    fleet:{
      type:Object,
      default:() => {}
    }
  },
  data () {
    return {
      slideDetailRealisasi: false,

      id: null,

      dateRange: [new Date(moment().startOf('year')), new Date(moment().endOf('year'))],
      page:1,
      params:{
        type:'', // 'progress', 'finish'
        startDate:null,
        endDate:null,
      },

      list:[],
      meta:null,
    }
  },
  components:{
    SlideDetailRealisasi,
  },
  computed: {
  },
  mounted () {
    this.fetchVoyageByTrips()
  },
  methods:{
    ...voyagesActions,
    closeSlideDetailRealisasi(){
      this.slideDetailRealisasi = false
      this.matrixData = null
      this.id = null
    },  
    openSlideDetailRealisasi(value, state){
      this.id = state?.tripRecord?.id
      this.slideDetailRealisasi = !this.slideDetailRealisasi;

      if(state?.tripRecord?.id) this.id = state?.tripRecord?.id
    },
    async fetchVoyageByTrips(page) {
      let params = {
        page: 1,
        perPage: 25,
        vehicleId: Number(this.$route.query.vehicleId),
        ...this.params,
        startDate: this.dateRange?.[0] ? moment(this.dateRange?.[0]).format('DD-MM-YYYY') : null,
        endDate: this.dateRange?.[1] ? moment(this.dateRange?.[1]).format('DD-MM-YYYY') : null, 
      }

      // Iterate over each property in params
      for (const prop in params) {
        if (params.hasOwnProperty(prop)) {
          // Check conditions for each property and delete if necessary
          if ((params[prop] === '') || (params[prop] === null)) {
            delete params[prop];
          }
        }
      }

      const res = await this.getVoyageTrips(params)

      if (res.status == 'success') {
        this.list = res.data
      } else {
        this.list = []
      }
    },  
  }
}
</script>