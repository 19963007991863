var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.dockingDetail && _vm.fleetDetail ? _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-user-profile-block1"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "user-details-block"
        }, [_c('div', {
          staticClass: "user-profile text-center"
        }, [_c('img', {
          staticClass: "avatar-130 img-fluid",
          attrs: {
            "src": require("../../assets/images/ship.png"),
            "alt": "profile-img"
          }
        })]), _c('div', {
          staticClass: "text-center mt-3"
        }, [_c('h4', [_c('b', [_vm._v(_vm._s(_vm.fleetDetail.name))])]), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(_vm._s(_vm.fleetDetail.company.company))])], 1)]), _c('table', {
          staticClass: "table table-borderless mt-3"
        }, [_c('tbody', [_c('tr', {
          staticClass: "bg-primary"
        }, [_c('td', {
          staticClass: "text-center",
          attrs: {
            "colspan": "2"
          }
        }, [_c('strong', [_vm._v("SHIP PARTICULAR")])])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Type Kapal")])]), _c('td', [_vm._v(_vm._s(_vm.fleetDetail.vehicleType.name))])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Bendera")])]), _c('td', [_vm._v(_vm._s(_vm.fleetDetail.flag))])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Klasifikasi")])]), _c('td', [_vm._v(_vm._s(_vm.fleetDetail.classification))])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Tahun Pembuatan")])]), _c('td', [_vm._v("-")])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Tempat Pembangunan")])]), _c('td', [_vm._v(_vm._s(_vm.fleetDetail.yearBuildCountry))])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Tempat Pendaftaran")])]), _c('td', [_vm._v("-")])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Ukuran Kapal")])]), _c('td', [_vm._v(_vm._s(_vm.fleetDetail.dimension.width.meter) + " x " + _vm._s(_vm.fleetDetail.dimension.length.meter) + " x " + _vm._s(_vm.fleetDetail.dimension.depth.meter) + " meter")])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Main Engine")])]), _c('td', [_c('ul', {
          staticClass: "text-left"
        }, _vm._l(_vm.fleetDetail.powers, function (power, powerIndex) {
          return _c('li', {
            key: powerIndex
          }, [_vm._v(_vm._s(power.powerType ? power.powerType.name : '-') + " - " + _vm._s(power.powerSeries ? power.powerSeries.name : '-') + " " + _vm._s(power.powerSeries ? power.powerSeries.model : '-') + " "), _c('span', {
            staticClass: "pull-right"
          }, [_vm._v("Actual RH : "), _c('b-badge', {
            attrs: {
              "variant": "info"
            }
          }, [_c('strong', [_vm._v(_vm._s(power.actualRunningHours) + " RH")])])], 1)]);
        }), 0)])])])])];
      },
      proxy: true
    }], null, false, 295882611)
  })], 1), _c('b-col', {
    attrs: {
      "lg": "8"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("SERAH TERIMA")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$fleetDetail, _vm$fleetDetail$vehic, _vm$fleetDetail2, _vm$fleetDetail2$vehi, _vm$fleetDetail3, _vm$fleetDetail3$vehi, _vm$fleetDetail4, _vm$fleetDetail4$vehi;
        return [_c('div', [_c('table', {
          staticClass: "table table-striped"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Arrival Shipyard")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s(_vm.dockingDetail.arrival))]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Up Slip")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s(_vm.dockingDetail.upSlip))])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Down Slip")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('date-picker', {
          attrs: {
            "required": "",
            "value-type": "DD-MM-YYYY",
            "type": "date",
            "placeholder": "Select date",
            "format": "DD MMM YYYY"
          },
          model: {
            value: _vm.form.downSlip,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "downSlip", $$v);
            },
            expression: "form.downSlip"
          }
        })], 1), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Complete Date")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('date-picker', {
          attrs: {
            "required": "",
            "value-type": "DD-MM-YYYY",
            "type": "date",
            "placeholder": "Select date",
            "format": "DD MMM YYYY"
          },
          model: {
            value: _vm.form.deliveryDate,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "deliveryDate", $$v);
            },
            expression: "form.deliveryDate"
          }
        })], 1)]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Budget")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Rp" + _vm._s(_vm.numberFormat(_vm.dockingDetail.budgetEstimation)))]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Penawaran")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Rp" + _vm._s(_vm.numberFormat(_vm.dockingDetail.budgetReal)))])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Shipyard")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s(_vm.dockingDetail.shipyard.name))]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("PIC")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s(_vm.dockingDetail.user.name))])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Document Shipyard")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm.dockingDetail.docShipyard ? _c('ul', {
          staticClass: "d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "pb-1",
          staticStyle: {
            "width": "30px"
          }
        }, [_c('a', {
          attrs: {
            "href": _vm.dockingDetail.docShipyard.url,
            "target": "_blank"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])])]) : _vm._e()]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Tonage Docking")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "inputGroupPrepend2"
          }
        }, [_vm._v("Tons")])]), _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form.tonageDocking,
            expression: "form.tonageDocking"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "tonage_docking",
            "placeholder": "5"
          },
          domProps: {
            "value": _vm.form.tonageDocking
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form, "tonageDocking", $event.target.value);
            }
          }
        })])])]), ['BARGE', 'PUSHER BARGE', 'OIL BARGE'].includes((_vm$fleetDetail = _vm.fleetDetail) === null || _vm$fleetDetail === void 0 ? void 0 : (_vm$fleetDetail$vehic = _vm$fleetDetail.vehicleType) === null || _vm$fleetDetail$vehic === void 0 ? void 0 : _vm$fleetDetail$vehic.name) ? _c('tr', [_vm._v("   ")]) : _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Start Engine")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('date-picker', {
          attrs: {
            "value-type": "DD-MM-YYYY",
            "type": "date",
            "placeholder": "Select date",
            "format": "DD MMM YYYY"
          },
          model: {
            value: _vm.form.startEngine,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "startEngine", $$v);
            },
            expression: "form.startEngine"
          }
        })], 1), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Finish Engine")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('date-picker', {
          attrs: {
            "value-type": "DD-MM-YYYY",
            "type": "date",
            "placeholder": "Select date",
            "format": "DD MMM YYYY"
          },
          model: {
            value: _vm.form.finishEngine,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "finishEngine", $$v);
            },
            expression: "form.finishEngine"
          }
        })], 1)]), ['BARGE', 'PUSHER BARGE', 'OIL BARGE'].includes((_vm$fleetDetail2 = _vm.fleetDetail) === null || _vm$fleetDetail2 === void 0 ? void 0 : (_vm$fleetDetail2$vehi = _vm$fleetDetail2.vehicleType) === null || _vm$fleetDetail2$vehi === void 0 ? void 0 : _vm$fleetDetail2$vehi.name) ? _c('tr', [_vm._v("   ")]) : _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Start ME")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('date-picker', {
          attrs: {
            "value-type": "DD-MM-YYYY",
            "type": "date",
            "placeholder": "Select date",
            "format": "DD MMM YYYY"
          },
          model: {
            value: _vm.form.startMe,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "startMe", $$v);
            },
            expression: "form.startMe"
          }
        })], 1), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Finish ME")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('date-picker', {
          attrs: {
            "value-type": "DD-MM-YYYY",
            "type": "date",
            "placeholder": "Select date",
            "format": "DD MMM YYYY"
          },
          model: {
            value: _vm.form.finishMe,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "finishMe", $$v);
            },
            expression: "form.finishMe"
          }
        })], 1)]), ['BARGE', 'PUSHER BARGE', 'OIL BARGE'].includes((_vm$fleetDetail3 = _vm.fleetDetail) === null || _vm$fleetDetail3 === void 0 ? void 0 : (_vm$fleetDetail3$vehi = _vm$fleetDetail3.vehicleType) === null || _vm$fleetDetail3$vehi === void 0 ? void 0 : _vm$fleetDetail3$vehi.name) ? _c('tr', [_vm._v("   ")]) : _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Start AE")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('date-picker', {
          attrs: {
            "value-type": "DD-MM-YYYY",
            "type": "date",
            "placeholder": "Select date",
            "format": "DD MMM YYYY"
          },
          model: {
            value: _vm.form.startAe,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "startAe", $$v);
            },
            expression: "form.startAe"
          }
        })], 1), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Finish AE")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('date-picker', {
          attrs: {
            "value-type": "DD-MM-YYYY",
            "type": "date",
            "placeholder": "Select date",
            "format": "DD MMM YYYY"
          },
          model: {
            value: _vm.form.finishAe,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "finishAe", $$v);
            },
            expression: "form.finishAe"
          }
        })], 1)]), _c('tr', [['BARGE', 'PUSHER BARGE', 'OIL BARGE'].includes((_vm$fleetDetail4 = _vm.fleetDetail) === null || _vm$fleetDetail4 === void 0 ? void 0 : (_vm$fleetDetail4$vehi = _vm$fleetDetail4.vehicleType) === null || _vm$fleetDetail4$vehi === void 0 ? void 0 : _vm$fleetDetail4$vehi.name) ? void 0 : [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Engine Test")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('date-picker', {
          attrs: {
            "value-type": "DD-MM-YYYY",
            "type": "date",
            "placeholder": "Select date",
            "format": "DD MMM YYYY"
          },
          model: {
            value: _vm.form.engineTest,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "engineTest", $$v);
            },
            expression: "form.engineTest"
          }
        })], 1)], _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Time Departure *")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('date-picker', {
          attrs: {
            "required": "",
            "value-type": "DD-MM-YYYY",
            "type": "date",
            "placeholder": "Select date",
            "format": "DD MMM YYYY"
          },
          model: {
            value: _vm.form.readySail,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "readySail", $$v);
            },
            expression: "form.readySail"
          }
        })], 1)], 2), _c('tr', [_c('td', {
          staticClass: "align-top",
          attrs: {
            "colspan": "3"
          }
        }, [_c('strong', [_vm._v("Remarks")]), _c('div', {
          staticClass: "mt-2"
        }, [_c('wysiwyg', {
          model: {
            value: _vm.form.remark,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "remark", $$v);
            },
            expression: "form.remark"
          }
        })], 1)]), _c('td', {
          staticClass: "align-top",
          attrs: {
            "colspan": "3"
          }
        }, [_c('strong', [_vm._v("Attachment Serah Terima")]), _c('div', {
          staticClass: "custom-file mt-2"
        }, [_c('input', {
          staticClass: "custom-file-input",
          attrs: {
            "type": "file",
            "id": "validatedCustomFile"
          },
          on: {
            "change": _vm.onUploadAttachment
          }
        }), _c('label', {
          staticClass: "custom-file-label",
          attrs: {
            "for": "validatedCustomFile"
          }
        }, [_vm._v("Browse file...")])]), _c('small', {
          staticClass: "text-muted"
        }, [_vm._v(" File Type * : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("3 MB / File")])], 1)])])])]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('iq-card', {
          attrs: {
            "class-name": "iq-card-block iq-card-stretch iq-card-height",
            "body-class": "iq-bg-primary rounded"
          },
          scopedSlots: _vm._u([{
            key: "body",
            fn: function fn() {
              return [_c('div', {
                staticClass: "d-flex align-items-center justify-content-between"
              }, [_c('div', {
                staticClass: "rounded-circle iq-card-icon bg-primary"
              }, [_c('i', {
                staticClass: "fa fa-percent"
              })]), _c('div', {
                staticClass: "text-right"
              }, [_c('h2', {
                staticClass: "mb-0"
              }, [_c('span', {
                staticClass: "counter"
              }, [_vm._v("100%")])]), _c('h5', {}, [_vm._v("Persentase Job Category")])])])];
            },
            proxy: true
          }], null, false, 1032408984)
        })], 1)], 1), _c('div', {
          staticClass: "accordion",
          attrs: {
            "role": "tablist"
          }
        }, [_c('b-card', {
          attrs: {
            "no-body": ""
          }
        }, [_vm._l(_vm.dockingDetail.dockingTaskCategory, function (jobCategory, indexJobCategory) {
          return [_c('b-card-header', {
            key: indexJobCategory,
            staticClass: "p-1",
            staticStyle: {
              "background-color": "white",
              "border": "none"
            },
            attrs: {
              "header-tag": "header",
              "role": "tab"
            }
          }, [_c('b-button', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: "jobCat-".concat(indexJobCategory),
              expression: "`jobCat-${indexJobCategory}`"
            }],
            staticClass: "text-left",
            attrs: {
              "block": "",
              "variant": "primary"
            }
          }, [_c('i', {
            staticClass: "fa fa-chevron-circle-down"
          }), _vm._v(_vm._s(jobCategory.dockingJobCategory.name) + " "), _c('strong', {
            staticClass: "pull-right"
          }, [_vm._v("BOBOT PEKERJAAN : " + _vm._s(jobCategory.wokloadPercentage) + "%")])])], 1), _c('b-collapse', {
            key: indexJobCategory,
            attrs: {
              "id": "jobCat-".concat(indexJobCategory),
              "accordion": "my-accordion",
              "role": "tabpanel"
            }
          }, [_c('b-card-body', [_c('iq-card', {
            attrs: {
              "class-name": "iq-card-block iq-card-stretch iq-card-height",
              "body-class": "iq-bg-info rounded"
            },
            scopedSlots: _vm._u([{
              key: "body",
              fn: function fn() {
                return [_c('div', {
                  staticClass: "d-flex align-items-center justify-content-between"
                }, [_c('div', {
                  staticClass: "rounded-circle iq-card-icon bg-info"
                }, [_c('i', {
                  staticClass: "fa fa-percent"
                })]), _c('div', {
                  staticClass: "text-right"
                }, [_c('h2', {
                  staticClass: "mb-0"
                }, [_c('span', {
                  staticClass: "counter"
                }, [_vm._v("100%")])]), _c('h5', {}, [_vm._v("Persentase Docking Job")])])])];
              },
              proxy: true
            }], null, true)
          }), _c('div', {
            staticClass: "accordion",
            attrs: {
              "role": "tablist"
            }
          }, _vm._l(jobCategory.dockingTask, function (job, indexJob) {
            return _c('b-card', {
              key: "".concat(indexJob, "-").concat(indexJobCategory),
              attrs: {
                "no-body": ""
              }
            }, [_c('b-card-header', {
              staticClass: "p-1",
              staticStyle: {
                "background-color": "white",
                "border": "none"
              },
              attrs: {
                "header-tag": "header",
                "role": "tab"
              }
            }, [_c('b-button', {
              directives: [{
                name: "b-toggle",
                rawName: "v-b-toggle",
                value: "task-".concat(indexJob, "-").concat(indexJobCategory),
                expression: "`task-${indexJob}-${indexJobCategory}`"
              }],
              staticClass: "text-left",
              attrs: {
                "block": "",
                "variant": "info"
              }
            }, [_c('i', {
              staticClass: "fa fa-chevron-circle-down"
            }), _vm._v(" " + _vm._s(job.dockingJob.name) + " "), _c('strong', {
              staticClass: "pull-right"
            }, [_vm._v("BOBOT PEKERJAAN : " + _vm._s(job.jobWorkloadPercentage) + "%")])])], 1), _c('b-collapse', {
              attrs: {
                "id": "task-".concat(indexJob, "-").concat(indexJobCategory),
                "accordion": "my-accordion-sub",
                "role": "tabpanel"
              }
            }, [_c('table', {
              staticClass: "table table-striped mt-2"
            }, [_c('tbody', [_c('tr', [_c('th', {
              attrs: {
                "width": "20%"
              }
            }, [_vm._v("Start Date")]), _c('td', {
              staticClass: "text-center",
              attrs: {
                "width": "5%"
              }
            }, [_vm._v(":")]), _c('td', {
              attrs: {
                "width": "25%"
              }
            }, [_vm._v(_vm._s(job.jobStart))]), _c('th', {
              attrs: {
                "width": "20%"
              }
            }, [_vm._v("Est. Finish")]), _c('td', {
              staticClass: "text-center",
              attrs: {
                "width": "5%"
              }
            }, [_vm._v(":")]), _c('td', {
              attrs: {
                "width": "25%"
              }
            }, [_vm._v(_vm._s(job.jobEstFinish))])]), _c('tr', [_c('th', {
              attrs: {
                "width": "20%"
              }
            }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
              staticClass: "text-center",
              attrs: {
                "width": "5%"
              }
            }, [_vm._v(":")]), _c('td', {
              attrs: {
                "width": "25%"
              }
            }, [_vm._v(_vm._s(job.jobWorkloadPercentage) + "%")]), _c('th', {
              attrs: {
                "width": "20%"
              }
            }, [_vm._v("Dokumen")]), _c('td', {
              staticClass: "text-center",
              attrs: {
                "width": "5%"
              }
            }, [_vm._v(":")]), _c('td', {
              attrs: {
                "width": "25%"
              }
            }, [job.jobDoc ? _c('ul', {
              staticClass: "d-flex flex-wrap p-0 m-0"
            }, [_c('li', {
              staticClass: "pb-1",
              staticStyle: {
                "width": "30px"
              }
            }, [_c('a', {
              attrs: {
                "href": job.jobDoc.url,
                "target": "_blank"
              }
            }, [_c('img', {
              staticClass: "img-fluid",
              attrs: {
                "src": require("../../assets/images/activity/pdf.png"),
                "alt": "gallary-image"
              }
            })])])]) : _vm._e()])]), _c('tr', [_c('th', {
              attrs: {
                "width": "20%"
              }
            }, [_vm._v("Created By")]), _c('td', {
              staticClass: "text-center",
              attrs: {
                "width": "5%"
              }
            }, [_vm._v(":")]), _c('td', {
              attrs: {
                "width": "25%"
              }
            }, [_vm._v(_vm._s(job.createdBy ? JSON.parse(job.createdBy).name : '-'))]), _c('th', {
              attrs: {
                "width": "20%"
              }
            }, [_vm._v("Review By")]), _c('td', {
              staticClass: "text-center",
              attrs: {
                "width": "5%"
              }
            }, [_vm._v(":")]), _c('td', {
              attrs: {
                "width": "25%"
              }
            }, [_vm._v(_vm._s(job.reviewedBy ? JSON.parse(job.reviewedBy).name : '-'))])]), _c('tr', [_c('th', {
              staticClass: "align-top"
            }, [_vm._v("Notes")]), _c('td', {
              staticClass: "text-center align-top"
            }, [_vm._v(":")]), _c('td', {
              attrs: {
                "colspan": "4"
              }
            }, [_vm._v(" " + _vm._s(job.notes) + " ")])]), job.isUseManPower ? _c('tr', [_c('th', {
              staticClass: "align-top"
            }, [_vm._v("Internal Man Power")]), _c('td', {
              staticClass: "text-center align-top"
            }, [_vm._v(":")]), _c('td', {
              attrs: {
                "colspan": "4"
              }
            }, _vm._l(job.manPowers, function (manPower, indexManPower) {
              return _c('h5', {
                key: indexManPower,
                staticClass: "mt-1"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(" " + _vm._s(manPower.name) + " - " + _vm._s(manPower.position))])], 1);
            }), 0)]) : _vm._e()])]), job.isUseContractor ? _c('table', {
              staticClass: "table table-striped mt-2"
            }, [_c('tbody', _vm._l(job.subContractors, function (contractor, indexContractor) {
              return _c('fieldset', {
                key: indexContractor,
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Kontraktor " + _vm._s(indexContractor + 1))]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s(contractor.name))]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s(contractor.pic))])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp" + _vm._s(_vm.numberFormat(contractor.offer)))]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Final")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp" + _vm._s(_vm.numberFormat(contractor.offerNegotiation)))])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Note")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v(_vm._s(contractor.notes))]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Attachment")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [contractor.contractorDoc ? _c('ul', {
                staticClass: "d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "pb-1",
                staticStyle: {
                  "width": "30px"
                }
              }, [_c('a', {
                attrs: {
                  "href": contractor.contractorDoc.url,
                  "target": "_blank"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])])]) : _vm._e()])])]);
            }), 0)]) : _vm._e(), _c('b-alert', {
              attrs: {
                "show": true,
                "variant": "success"
              }
            }, [_c('div', {
              staticClass: "iq-alert-icon"
            }, [_c('i', {
              staticClass: "fa fa-check"
            })]), _c('div', {
              staticClass: "iq-alert-text"
            }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("FINISH")])])])], 1)], 1);
          }), 1)], 1)], 1)];
        })], 2)], 1)], 1), _c('b-button', {
          staticClass: "mt-3",
          attrs: {
            "block": "",
            "variant": "primary",
            "type": "submit"
          }
        }, [_vm._v("Submit Data")])];
      },
      proxy: true
    }], null, false, 2844047612)
  })], 1)], 1)], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }