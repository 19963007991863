<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title text-primary">
                        <strong>HISTORY REPAIR & MAINTENANCE - TB. ETI 201</strong>
                        </h4>
                    </template>
                    <template v-slot:headerAction>
                        <a class="text-primary float-right" v-b-toggle.collapse-filter role="button" style="cursor: pointer"><i class="fa fa-calendar" /></a>
                    </template>
                    <template v-slot:body>
                    <b-collapse id="collapse-filter" class="mb-2">
                        <b-row>
                            <b-col md="3">
                                <date-picker :lang="lang" v-model="dateRange" type="date" range placeholder="Select date range"></date-picker>
                            </b-col>
                            <b-col md="1">
                                <b-button variant="primary" class="mb-3 mr-1"><i class="fa fa-filter"></i></b-button>
                            </b-col>
                        </b-row>
                    </b-collapse>
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th class="text-center">TOTAL REPAIR</th>
                                <th class="text-center">TOTAL COST</th>
                                <th class="text-center">LOKASI</th>
                                <th class="text-center">TANGGAL</th>
                                <th class="text-center">STATUS</th>
                                <th class="text-center">MINOR</th>
                                <th class="text-center">MEDIUM</th>
                                <th class="text-center">MAJOR</th>
                                <th class="text-center">ACTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-center">53 Jobs</td>
                                <td class="text-center">Rp. 1.238.203.200,-</td>
                                <td class="text-center">MOROSI - KENDARI</td>
                                <td class="text-center">15 Juni 2022</td>
                                <td class="text-center">
                                    <b-badge variant="success" class="mb-1">On Time : 45 Jobs</b-badge><br>
                                    <b-badge variant="danger">Delay : 23 Jobs</b-badge>
                                </td>
                                <td class="text-center">15 Jobs</td>
                                <td class="text-center">31 Jobs</td>
                                <td class="text-center">22 Jobs</td>
                                <td class="text-center">
                                    <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                    <template v-slot:button-content>
                                        <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                        <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                        </span>
                                    </template>
                                    <b-dropdown-item href="#">
                                        <b-dropdown-item href="#" v-b-toggle.detail_sr>
                                            <i class="fa fa-info-circle mr-2"></i>Detail
                                        </b-dropdown-item>
                                    </b-dropdown-item>
                                    </b-dropdown>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">36 Jobs</td>
                                <td class="text-center">Rp. 1.375.123.180,-</td>
                                <td class="text-center">CELUKAN BAWANG</td>
                                <td class="text-center">18 Januari 2022</td>
                                <td class="text-center">
                                    <b-badge variant="success" class="mb-1">On Time : 32 Jobs</b-badge><br>
                                    <b-badge variant="danger">Delay : 4 Jobs</b-badge>
                                </td>
                                <td class="text-center">15 Jobs</td>
                                <td class="text-center">12 Jobs</td>
                                <td class="text-center">16 Jobs</td>
                                <td class="text-center">
                                    <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                    <template v-slot:button-content>
                                        <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                        <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                        </span>
                                    </template>
                                    <b-dropdown-item href="#">
                                        <b-dropdown-item href="#" v-b-toggle.detail_sr>
                                            <i class="fa fa-info-circle mr-2"></i>Detail
                                        </b-dropdown-item>
                                    </b-dropdown-item>
                                    </b-dropdown>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">64 Jobs</td>
                                <td class="text-center">Rp. 1.837.129.500,-</td>
                                <td class="text-center">TEGAL</td>
                                <td class="text-center">25 September 2021</td>
                                <td class="text-center">
                                    <b-badge variant="success" class="mb-1">On Time : 43 Jobs</b-badge><br>
                                    <b-badge variant="danger">Delay : 21 Jobs</b-badge>
                                </td>
                                <td class="text-center">15 Jobs</td>
                                <td class="text-center">11 Jobs</td>
                                <td class="text-center">23 Jobs</td>
                                <td class="text-center">
                                    <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                    <template v-slot:button-content>
                                        <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                        <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                        </span>
                                    </template>
                                    <b-dropdown-item href="#">
                                        <b-dropdown-item href="#" v-b-toggle.detail_sr>
                                            <i class="fa fa-info-circle mr-2"></i>Detail
                                        </b-dropdown-item>
                                    </b-dropdown-item>
                                    </b-dropdown>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center">72 Jobs</td>
                                <td class="text-center">Rp. 1.387.384.000,-</td>
                                <td class="text-center">SANGATTA</td>
                                <td class="text-center">08 Maret 2021</td>
                                <td class="text-center">
                                    <b-badge variant="success" class="mb-1">On Time : 52 Jobs</b-badge><br>
                                    <b-badge variant="danger">Delay : 20 Jobs</b-badge>
                                </td>
                                <td class="text-center">21 Jobs</td>
                                <td class="text-center">17 Jobs</td>
                                <td class="text-center">19 Jobs</td>
                                <td class="text-center">
                                    <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                    <template v-slot:button-content>
                                        <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                        <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                        </span>
                                    </template>
                                    <b-dropdown-item href="#">
                                        <b-dropdown-item href="#" v-b-toggle.detail_sr>
                                            <i class="fa fa-info-circle mr-2"></i>Detail
                                        </b-dropdown-item>
                                    </b-dropdown-item>
                                    </b-dropdown>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="mt-3">
                    <b-pagination :value="1" :total-rows="50" align="center" />
                    </div>
                    <!-- SLIDE DETAIL -->
                    <b-sidebar id="detail_sr" title="DETAIL REPAIR & MAINTENANCE - TB. ETI 201" width="70%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
                        <template #default="{hide}">
                        <b-row class="m-3">
                            <b-col md="12">
                            <fieldset class="form-group border p-3">
                                <legend class="w-auto px-2 text-primary">Detail Information</legend>
                                <table class="table mb-0 table-striped">
                                <tbody>
                                    <tr>
                                        <th width="20%">Lokasi Pekerjaan</th>
                                        <td width="5%">:</td>
                                        <td width="25%">Morosi</td>
                                        <th width="20%">Tanggal</th>
                                        <td width="5%">:</td>
                                        <td width="25%">15 Juni 2022</td>
                                    </tr>
                                    <tr>
                                        <th width="20%">Office / Site</th>
                                        <td width="5%">:</td>
                                        <td width="25%">Kendari</td>
                                        <th width="20%">PIC Repair</th>
                                        <td width="5%">:</td>
                                        <td width="25%">Sangatta</td>
                                    </tr>
                                </tbody>
                                </table>
                            </fieldset>
                            <div class="accordion mb-3" role="tablist">
                                <b-card no-body>
                                <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none">
                                    <b-button block v-b-toggle.job-1-1 variant="info" class="text-left">
                                    <i class="fa fa-chevron-circle-down"></i> FUEL INJECTOR - PEBAIKAN KEBOCORAN FUEL INJECTOR <strong>(REVIEW)</strong>
                                    </b-button>
                                </b-card-header>
                                <b-collapse id="job-1-1" accordion="my-accordion-sub" role="tabpanel">
                                    <table class="table table-striped mt-3">
                                    <tbody>
                                        <tr>
                                            <th width="20%">Equipment Name</th>
                                            <td width="5%" class="text-center">:</td>
                                            <td width="25%">Fuel Injector</td>
                                            <th width="20%">Repair Title</th>
                                            <td width="5%" class="text-center">:</td>
                                            <td width="25%">Perbaikan Kebocoran Fuel Injector</td>
                                        </tr>
                                        <tr>
                                            <th width="20%">Type R & M</th>
                                            <td width="5%" class="text-center">:</td>
                                            <td width="25%">Running Maintenance</td>
                                            <th width="20%">Department</th>
                                            <td width="5%" class="text-center">:</td>
                                            <td width="25%">Engine</td>
                                        </tr>
                                        <tr>
                                            <th width="20%">Category</th>
                                            <td width="5%" class="text-center">:</td>
                                            <td width="25%">Mechanical</td>
                                            <th width="20%">Classification</th>
                                            <td width="5%" class="text-center">:</td>
                                            <td width="25%">Minor (3 Days)</td>
                                        </tr>
                                        <tr>
                                            <th width="20%">Cause</th>
                                            <td width="5%" class="text-center">:</td>
                                            <td width="25%">Material Fatique</td>
                                            <th width="20%">Kind Task</th>
                                            <td width="5%" class="text-center">:</td>
                                            <td width="25%">Repair</td>
                                        </tr>
                                        <tr>
                                            <th width="20%">Tanggal Mulai</th>
                                            <td width="5%" class="text-center">:</td>
                                            <td width="25%">12 Januari 2023</td>
                                            <th width="20%">Tanggal Selesai (Est)</th>
                                            <td width="5%" class="text-center">:</td>
                                            <td width="25%">15 Januari 2023</td>
                                        </tr>
                                        <tr>
                                        <th width="20%">Berita Acara</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">
                                            <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1">
                                                <a href="javascript:void(0);">
                                                <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                                </a>
                                            </li>
                                            <li class="col-md-1 col-1">
                                                <a href="javascript:void(0);">
                                                <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                                </a>
                                            </li>
                                            <li class="col-md-1 col-1">
                                                <a href="javascript:void(0);">
                                                <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                                </a>
                                            </li>
                                            <li class="col-md-1 col-1">
                                                <a href="javascript:void(0);">
                                                <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                                </a>
                                            </li>
                                            </ul>
                                        </td>
                                        </tr>
                                        <tr>
                                        <th class="align-top" width="20%">Description</th>
                                        <td class="align-top text-center" width="5%">:</td>
                                        <td class="align-top" colspan="4">
                                            <ul type="number">
                                            <li>- Remove the injector</li>
                                            <li> - Clean up deposit <ul>
                                                <li>- Clean parts using a brass brush to prevent damage to polished surfaces.</li>
                                                <li>- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.</li>
                                                <li>- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.</li>
                                                </ul>
                                            </li>
                                            <li>- Pressure injection test, check if the pressure isn't same with maker standart.</li>
                                            <li>- Fit up</li>
                                            </ul>
                                        </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </b-collapse>
                                </b-card>
                                <b-card no-body>
                                <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none">
                                    <b-button block v-b-toggle.job-1-2 variant="primary" class="text-left">
                                    <i class="fa fa-chevron-circle-down"></i> SENSOR - KALIBRASI SENSOR SONAR <strong>(APPROVE)</strong>
                                    </b-button>
                                </b-card-header>
                                <b-collapse id="job-1-2" accordion="my-accordion-sub" role="tabpanel">
                                    <table class="table table-striped mt-3">
                                    <tbody>
                                        <tr>
                                        <th width="20%">Equipment Name</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Sensor</td>
                                        <th width="20%">Repair Title</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Kalibrasi Sensor Sonar</td>
                                        </tr>
                                        <tr>
                                        <th width="20%">Type R & M</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Running Maintenance</td>
                                        <th width="20%">Department</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Deck</td>
                                        </tr>
                                        <tr>
                                        <th width="20%">Category</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">General Service</td>
                                        <th width="20%">Classification</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Medium (7 Days)</td>
                                        </tr>
                                        <tr>
                                        <th width="20%">Cause</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Human Error</td>
                                        <th width="20%">Kind Task</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Renew</td>
                                        </tr>
                                        <tr>
                                        <th width="20%">Tanggal Mulai</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">20 Januari 2023</td>
                                        <th width="20%">Tanggal Selesai (Est)</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">27 Januari 2023</td>
                                        </tr>
                                        <tr>
                                        <th width="20%">Berita Acara</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">
                                            <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1">
                                                <a href="javascript:void(0);">
                                                <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                                </a>
                                            </li>
                                            <li class="col-md-1 col-1">
                                                <a href="javascript:void(0);">
                                                <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                                </a>
                                            </li>
                                            <li class="col-md-1 col-1">
                                                <a href="javascript:void(0);">
                                                <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                                </a>
                                            </li>
                                            <li class="col-md-1 col-1">
                                                <a href="javascript:void(0);">
                                                <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                                </a>
                                            </li>
                                            </ul>
                                        </td>
                                        </tr>
                                        <tr>
                                        <th class="align-top" width="20%">Description</th>
                                        <td class="align-top text-center" width="5%">:</td>
                                        <td class="align-top" colspan="4">
                                            <ul type="number">
                                            <li>- Remove the injector</li>
                                            <li> - Clean up deposit <ul>
                                                <li>- Clean parts using a brass brush to prevent damage to polished surfaces.</li>
                                                <li>- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.</li>
                                                <li>- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.</li>
                                                </ul>
                                            </li>
                                            <li>- Pressure injection test, check if the pressure isn't same with maker standart.</li>
                                            <li>- Fit up</li>
                                            </ul>
                                        </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </b-collapse>
                                </b-card>
                                <b-card no-body>
                                <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none">
                                    <b-button block v-b-toggle.job-1-3 variant="warning" class="text-left">
                                    <i class="fa fa-chevron-circle-down"></i> ELECTRICAL - PERBAIAKAN KORSLETING LISTRIK <strong>(PROGESS)</strong>
                                    </b-button>
                                </b-card-header>
                                <b-collapse id="job-1-3" accordion="my-accordion-sub" role="tabpanel">
                                    <table class="table table-striped mt-3">
                                    <tbody>
                                        <tr>
                                        <th width="20%">Equipment Name</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Electrical</td>
                                        <th width="20%">Repair Title</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Perbaiakan Korsleting Ruang Deck</td>
                                        </tr>
                                        <tr>
                                        <th width="20%">Type R & M</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Emergency Maintenance</td>
                                        <th width="20%">Department</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Deck</td>
                                        </tr>
                                        <tr>
                                        <th width="20%">Category</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Electrical</td>
                                        <th width="20%">Classification</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Minor (3 Days)</td>
                                        </tr>
                                        <tr>
                                        <th width="20%">Cause</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Material Fatique</td>
                                        <th width="20%">Kind Task</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Repair</td>
                                        </tr>
                                        <tr>
                                        <th width="20%">Tanggal Mulai</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">18 Januari 2023</td>
                                        <th width="20%">Tanggal Selesai (Est)</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">21 Januari 2023</td>
                                        </tr>
                                        <tr>
                                        <th width="20%">Berita Acara</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">
                                            <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1">
                                                <a href="javascript:void(0);">
                                                <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                                </a>
                                            </li>
                                            <li class="col-md-1 col-1">
                                                <a href="javascript:void(0);">
                                                <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                                </a>
                                            </li>
                                            <li class="col-md-1 col-1">
                                                <a href="javascript:void(0);">
                                                <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                                </a>
                                            </li>
                                            <li class="col-md-1 col-1">
                                                <a href="javascript:void(0);">
                                                <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                                </a>
                                            </li>
                                            </ul>
                                        </td>
                                        </tr>
                                        <tr>
                                        <th class="align-top" width="20%">Description</th>
                                        <td class="align-top text-center" width="5%">:</td>
                                        <td class="align-top" colspan="4">
                                            <ul type="number">
                                            <li>- Remove the injector</li>
                                            <li> - Clean up deposit <ul>
                                                <li>- Clean parts using a brass brush to prevent damage to polished surfaces.</li>
                                                <li>- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.</li>
                                                <li>- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.</li>
                                                </ul>
                                            </li>
                                            <li>- Pressure injection test, check if the pressure isn't same with maker standart.</li>
                                            <li>- Fit up</li>
                                            </ul>
                                        </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                    <fieldset class="form-group border p-3">
                                    <legend class="w-auto px-2">Internal Man Power</legend>
                                    <table class="table table-striped table-sm">
                                        <tbody>
                                        <tr>
                                            <th width="50%" class="pl-3">NAMA LENGKAP</th>
                                            <th width="50%">JABATAN</th>
                                        </tr>
                                        <tr>
                                            <td class="pl-3">Agung Tri Laksono</td>
                                            <td>Manager Engineer</td>
                                        </tr>
                                        <tr>
                                            <td class="pl-3">Nurul Akbar</td>
                                            <td>Senior Engineer</td>
                                        </tr>
                                        <tr>
                                            <td class="pl-3">Hanel Prilian</td>
                                            <td>Engineer</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    </fieldset>
                                    <fieldset class="form-group border p-3">
                                    <legend class="w-auto px-2">REPAIR & MAINTENANCE TIME LINE (PROGESS & DELAY FACTOR)</legend>
                                    <ul class="iq-timeline" style="height:500px">
                                        <li>
                                            <div class="timeline-dots border-success"></div>
                                            <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                            <small>24 November 2019 15:45</small>
                                            <div class="d-inline-block w-100">
                                            <p>Melakukan inspeksi pada kerusakan</p>
                                            <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/6.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                                <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/1.png" alt="gallary-image" class="img-fluid"></a></li>
                                                <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                                <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                            </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="timeline-dots border-success"></div>
                                            <h6 class="float-left mb-1">Agung Tri Laksono</h6><br/>
                                            <small>23 November 2019 21:25</small>
                                            <div class="d-inline-block w-100">
                                            <p>Sparepart tiba kapal</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="timeline-dots border-success"></div>
                                            <h6 class="float-left mb-1">Tri Leksono</h6><br/>
                                            <small>22 November 2019 10:12</small>
                                            <div class="d-inline-block w-100">
                                            <p>Menunggu sparepart tiba</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="timeline-dots border-success"></div>
                                            <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                            <small>21 November 2019 11:21</small>
                                            <div class="d-inline-block w-100">
                                            <p>Melengkapi dokumen perbaikan</p>
                                            <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                <li class="col-md-1 col-1">
                                                <a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a>
                                                </li>
                                                <li class="col-md-1 col-1">
                                                <a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a>
                                                </li>
                                                <li class="col-md-1 col-1">
                                                <a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a>
                                                </li>
                                                <li class="col-md-1 col-1">
                                                <a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a>
                                                </li>
                                            </ul>
                                            </div>
                                        </li>
                                    </ul>
                                    </fieldset>
                                </b-collapse>
                                </b-card>
                                <b-card no-body>
                                <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none">
                                    <b-button block v-b-toggle.job-1-4 variant="success" class="text-left">
                                    <i class="fa fa-chevron-circle-down"></i> ELECTRICAL - INSTALL LO COOLER AE PORTSIDE <strong>(FINISH)</strong>
                                    </b-button>
                                </b-card-header>
                                <b-collapse id="job-1-4" accordion="my-accordion-sub" role="tabpanel">
                                    <table class="table table-striped mt-3">
                                    <tbody>
                                        <tr>
                                        <th width="20%">Equipment Name</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Electrical</td>
                                        <th width="20%">Repair Title</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Install LO cooler AE portside</td>
                                        </tr>
                                        <tr>
                                        <th width="20%">Type R & M</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Plan Maintenance</td>
                                        <th width="20%">Department</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Engine</td>
                                        </tr>
                                        <tr>
                                        <th width="20%">Category</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Electrical</td>
                                        <th width="20%">Classification</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Major (21 Days)</td>
                                        </tr>
                                        <tr>
                                        <th width="20%">Cause</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Weather</td>
                                        <th width="20%">Kind Task</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Renew</td>
                                        </tr>
                                        <tr>
                                        <th width="20%">Tanggal Mulai</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">10 Januari 2023</td>
                                        <th width="20%">Tanggal Selesai (Est)</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">01 Februari 2023</td>
                                        </tr>
                                        <tr>
                                        <th width="20%">Berita Acara</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">
                                            <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1">
                                                <a href="javascript:void(0);">
                                                <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                                </a>
                                            </li>
                                            <li class="col-md-1 col-1">
                                                <a href="javascript:void(0);">
                                                <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                                </a>
                                            </li>
                                            <li class="col-md-1 col-1">
                                                <a href="javascript:void(0);">
                                                <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                                </a>
                                            </li>
                                            <li class="col-md-1 col-1">
                                                <a href="javascript:void(0);">
                                                <img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid">
                                                </a>
                                            </li>
                                            </ul>
                                        </td>
                                        </tr>
                                        <tr>
                                        <th class="align-top" width="20%">Description</th>
                                        <td class="align-top text-center" width="5%">:</td>
                                        <td class="align-top" colspan="4">
                                            <ul type="number">
                                            <li>- Remove the injector</li>
                                            <li> - Clean up deposit <ul>
                                                <li>- Clean parts using a brass brush to prevent damage to polished surfaces.</li>
                                                <li>- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.</li>
                                                <li>- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.</li>
                                                </ul>
                                            </li>
                                            <li>- Pressure injection test, check if the pressure isn't same with maker standart.</li>
                                            <li>- Fit up</li>
                                            </ul>
                                        </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                    <fieldset class="form-group border p-3">
                                    <legend class="w-auto px-2">Internal Man Power</legend>
                                    <table class="table table-striped table-sm">
                                        <tbody>
                                        <tr>
                                            <th width="50%" class="pl-3">NAMA LENGKAP</th>
                                            <th width="50%">JABATAN</th>
                                        </tr>
                                        <tr>
                                            <td class="pl-3">Agung Tri Laksono</td>
                                            <td>Manager Engineer</td>
                                        </tr>
                                        <tr>
                                            <td class="pl-3">Nurul Akbar</td>
                                            <td>Senior Engineer</td>
                                        </tr>
                                        <tr>
                                            <td class="pl-3">Hanel Prilian</td>
                                            <td>Engineer</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    </fieldset>
                                    <fieldset class="form-group border p-3">
                                    <legend class="w-auto px-2">Sub Kontraktor</legend>
                                    <table class="table table-striped table-sm">
                                        <tbody>
                                        <tr>
                                            <th class="pl-3">NAMA KONTRAKTOR</th>
                                            <th>PIC/EXECUTIVE ENGINEER</th>
                                            <th class="text-center">QUOTATION</th>
                                            <th class="text-center">FINAL QUOTATION</th>
                                            <th class="text-center">BERITA ACARA</th>
                                        </tr>
                                        <tr>
                                            <td class="pl-3">PT. Cahaya Marine</td>
                                            <td>Dendy Prasetya</td>
                                            <td class="text-center">Rp. 30.000.000,-</td>
                                            <td class="text-center">Rp. 27.500.000,-</td>
                                            <td class="text-center">
                                            <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                            <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                            <i class="fa fa-file-pdf text-primary h4 mr-2"></i>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    </fieldset>
                                    <fieldset class="form-group border p-3">
                                    <legend class="w-auto px-2">REPAIR & MAINTENANCE TIME LINE (PROGESS & DELAY FACTOR)</legend>
                                    <ul class="iq-timeline" style="height:500px">
                                        <li>
                                            <div class="timeline-dots border-success"></div>
                                            <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                            <small>24 November 2019 15:45</small>
                                            <div class="d-inline-block w-100">
                                            <p>Melakukan inspeksi pada kerusakan</p>
                                            <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/6.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                                <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/1.png" alt="gallary-image" class="img-fluid"></a></li>
                                                <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                                <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                            </ul>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="timeline-dots border-success"></div>
                                            <h6 class="float-left mb-1">Agung Tri Laksono</h6><br/>
                                            <small>23 November 2019 21:25</small>
                                            <div class="d-inline-block w-100">
                                            <p>Sparepart tiba kapal</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="timeline-dots border-success"></div>
                                            <h6 class="float-left mb-1">Tri Leksono</h6><br/>
                                            <small>22 November 2019 10:12</small>
                                            <div class="d-inline-block w-100">
                                            <p>Menunggu sparepart tiba</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="timeline-dots border-success"></div>
                                            <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                            <small>21 November 2019 11:21</small>
                                            <div class="d-inline-block w-100">
                                            <p>Melengkapi dokumen perbaikan</p>
                                            <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                <li class="col-md-1 col-1">
                                                <a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a>
                                                </li>
                                                <li class="col-md-1 col-1">
                                                <a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a>
                                                </li>
                                                <li class="col-md-1 col-1">
                                                <a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a>
                                                </li>
                                                <li class="col-md-1 col-1">
                                                <a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a>
                                                </li>
                                            </ul>
                                            </div>
                                        </li>
                                    </ul>
                                    </fieldset>
                                </b-collapse>
                                </b-card>
                            </div>
                            </b-col>
                        </b-row>
                        </template>
                    </b-sidebar>
                    <!-- SLIDE END -->
                </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { xray }
from '../../config/pluginInit'
export default {
    name: 'ServiceRepairHistory',
    mounted () {
        xray.index()
    }
}
</script>