var render = function render(){
  var _vm$maintenance, _vm$maintenance2, _vm$maintenance3, _vm$maintenance4, _vm$maintenance5, _vm$maintenance6, _vm$maintenance6$para, _vm$maintenance7, _vm$maintenance7$para, _vm$maintenance8, _vm$maintenance8$para, _vm$maintenance9, _vm$maintenance9$para, _vm$maintenance10, _vm$maintenance10$par, _vm$maintenance11, _vm$maintenance11$par, _vm$maintenance12, _vm$maintenance12$par, _vm$maintenance13, _vm$maintenance13$par, _vm$maintenance14, _vm$maintenance14$par, _vm$maintenance16;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    staticClass: "d-flex justify-content-between",
    attrs: {
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('b-form-input', {
    attrs: {
      "size": "lg",
      "placeholder": "search job name..."
    },
    model: {
      value: _vm.maintenance.params.search,
      callback: function callback($$v) {
        _vm.$set(_vm.maintenance.params, "search", $$v);
      },
      expression: "maintenance.params.search"
    }
  }), _c('b-button', {
    staticClass: "mx-2",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('refetch');
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-search"
  })]), _c('b-button', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('toggleFilter');
      }
    }
  }, [_c('i', {
    staticClass: "fas fa-sliders"
  }), _c('p', {
    staticClass: "text-truncate m-0"
  }, [_vm._v(" Filter Data ")])])], 1)]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "text-right"
  }, [_c('strong', [_vm._v("Display :  ")]), _c('div', {
    staticClass: "btn-group"
  }, [_c('button', {
    staticClass: "btn btn-outline-primary",
    class: {
      'btn-menu-active': _vm.view_style === 'list'
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.view_style = 'list';
      }
    }
  }, [_c('span', {
    staticClass: "fa fa-list"
  })]), _c('button', {
    staticClass: "btn btn-outline-primary",
    class: {
      'btn-menu-active': _vm.view_style === 'calendar'
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.view_style = 'calendar';
      }
    }
  }, [_c('span', {
    staticClass: "fa fa-calendar"
  })]), _c('button', {
    staticClass: "btn btn-outline-primary",
    class: {
      'btn-menu-active': _vm.view_style === 'gantt-chart'
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.view_style = 'gantt-chart';
      }
    }
  }, [_c('span', {
    staticClass: "fa fa-bars"
  })])])])]), _c('b-col', {
    staticClass: "my-3",
    staticStyle: {
      "padding": "0 30px"
    },
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', {
    staticClass: "border rounded-md pt-2"
  }, [(_vm$maintenance = _vm.maintenance) !== null && _vm$maintenance !== void 0 && _vm$maintenance.dateRange.length ? _c('b-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Period :"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('p', {
    staticClass: "mb-0 text-black",
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s((_vm$maintenance2 = _vm.maintenance) !== null && _vm$maintenance2 !== void 0 && _vm$maintenance2.dateRange[0] ? _vm.moment((_vm$maintenance3 = _vm.maintenance) === null || _vm$maintenance3 === void 0 ? void 0 : _vm$maintenance3.dateRange[0]).format("DD MMM YYYY") : '') + " - "), _c('span', [_vm._v(_vm._s((_vm$maintenance4 = _vm.maintenance) !== null && _vm$maintenance4 !== void 0 && _vm$maintenance4.dateRange[1] ? _vm.moment((_vm$maintenance5 = _vm.maintenance) === null || _vm$maintenance5 === void 0 ? void 0 : _vm$maintenance5.dateRange[1]).format("DD MMM YYYY") : ''))])])])])], 1) : _vm._e(), (_vm$maintenance6 = _vm.maintenance) !== null && _vm$maintenance6 !== void 0 && (_vm$maintenance6$para = _vm$maintenance6.params) !== null && _vm$maintenance6$para !== void 0 && _vm$maintenance6$para.vehicleTypeIds.length ? _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fleet Category :"
    }
  }, [_vm._l((_vm$maintenance7 = _vm.maintenance) === null || _vm$maintenance7 === void 0 ? void 0 : (_vm$maintenance7$para = _vm$maintenance7.params) === null || _vm$maintenance7$para === void 0 ? void 0 : _vm$maintenance7$para.vehicleTypeIds, function (type, index) {
    return _c('b-badge', {
      key: index,
      staticClass: "mr-1",
      attrs: {
        "variant": "success"
      }
    }, [_vm._v(_vm._s(type.name))]);
  })], 2)], 1) : _vm._e(), (_vm$maintenance8 = _vm.maintenance) !== null && _vm$maintenance8 !== void 0 && (_vm$maintenance8$para = _vm$maintenance8.params) !== null && _vm$maintenance8$para !== void 0 && _vm$maintenance8$para.vehicleIds.length ? _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fleet Name :"
    }
  }, [_vm._l((_vm$maintenance9 = _vm.maintenance) === null || _vm$maintenance9 === void 0 ? void 0 : (_vm$maintenance9$para = _vm$maintenance9.params) === null || _vm$maintenance9$para === void 0 ? void 0 : _vm$maintenance9$para.vehicleIds, function (type, index) {
    return _c('b-badge', {
      key: index,
      staticClass: "mr-1",
      attrs: {
        "variant": "warning"
      }
    }, [_vm._v(_vm._s(type.name))]);
  })], 2)], 1) : _vm._e(), _vm.filterOptionSelected == 'jobClass' && (_vm$maintenance10 = _vm.maintenance) !== null && _vm$maintenance10 !== void 0 && (_vm$maintenance10$par = _vm$maintenance10.params) !== null && _vm$maintenance10$par !== void 0 && _vm$maintenance10$par.jobClassIds.length ? _c('b-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Job Class :"
    }
  }, _vm._l((_vm$maintenance11 = _vm.maintenance) === null || _vm$maintenance11 === void 0 ? void 0 : (_vm$maintenance11$par = _vm$maintenance11.params) === null || _vm$maintenance11$par === void 0 ? void 0 : _vm$maintenance11$par.jobClassIds, function (type, index) {
    return _c('b-badge', {
      key: index,
      staticClass: "mr-1",
      attrs: {
        "variant": "danger"
      }
    }, [_vm._v(_vm._s(type.name))]);
  }), 1)], 1) : _vm._e(), _vm.filterOptionSelected == 'jobType' && (_vm$maintenance12 = _vm.maintenance) !== null && _vm$maintenance12 !== void 0 && (_vm$maintenance12$par = _vm$maintenance12.params) !== null && _vm$maintenance12$par !== void 0 && _vm$maintenance12$par.jobTypeIds.length ? _c('b-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Job Type :"
    }
  }, _vm._l((_vm$maintenance13 = _vm.maintenance) === null || _vm$maintenance13 === void 0 ? void 0 : (_vm$maintenance13$par = _vm$maintenance13.params) === null || _vm$maintenance13$par === void 0 ? void 0 : _vm$maintenance13$par.jobTypeIds, function (type, index) {
    return _c('b-badge', {
      key: index,
      staticClass: "mr-1",
      attrs: {
        "variant": "danger"
      }
    }, [_vm._v(_vm._s(type.name))]);
  }), 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status :"
    }
  }, [_c('p', {
    staticClass: "mb-0 text-black",
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s((_vm$maintenance14 = _vm.maintenance) !== null && _vm$maintenance14 !== void 0 && (_vm$maintenance14$par = _vm$maintenance14.params) !== null && _vm$maintenance14$par !== void 0 && _vm$maintenance14$par.status ? _vm.statusList.find(function (el) {
    var _vm$maintenance15, _vm$maintenance15$par;
    return el.value == ((_vm$maintenance15 = _vm.maintenance) === null || _vm$maintenance15 === void 0 ? void 0 : (_vm$maintenance15$par = _vm$maintenance15.params) === null || _vm$maintenance15$par === void 0 ? void 0 : _vm$maintenance15$par.status);
  }).text : "All"))])])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [!_vm.maintenance.isLoading ? [_vm.view_style === 'calendar' ? [_c('ProgressCalendar', {
    attrs: {
      "maintenance": (_vm$maintenance16 = _vm.maintenance) === null || _vm$maintenance16 === void 0 ? void 0 : _vm$maintenance16.data,
      "canAdd": _vm.canAdd,
      "canApprove": _vm.canApprove,
      "canReview": _vm.canReview
    },
    on: {
      "viewDetailMaintenance": function viewDetailMaintenance(e) {
        return _vm.viewDetailMaintenance(e.id, e.type);
      },
      "onApprove": _vm.onApprove,
      "onReview": _vm.onReview
    }
  })] : _vm._e(), _vm.view_style === 'list' ? [_c('ProgressList', {
    attrs: {
      "maintenance": _vm.maintenance,
      "canAdd": _vm.canAdd,
      "canApprove": _vm.canApprove,
      "canReview": _vm.canReview
    },
    on: {
      "refetch": function refetch($event) {
        return _vm.$emit('refetch');
      },
      "viewDetailMaintenance": function viewDetailMaintenance(e) {
        return _vm.viewDetailMaintenance(e.id, e.type);
      },
      "onApprove": _vm.onApprove,
      "onReview": _vm.onReview
    }
  })] : _vm._e(), _vm.view_style === 'gantt-chart' ? [_c('ProgressGanttChart')] : _vm._e()] : [_c('div', {
    staticClass: "py-5 w-100 d-flex justify-content-center"
  }, [_c('b-spinner', {
    attrs: {
      "label": "Spinning"
    }
  })], 1)]], 2), _c('b-sidebar', {
    attrs: {
      "id": "view-detail-maintenance",
      "title": "View Job Maintenance",
      "width": "100%",
      "backdrop-variant": "dark",
      "right": "",
      "backdrop": "",
      "shadow": "",
      "header-class": "bg-white"
    },
    model: {
      value: _vm.viewDetailMaintenanceSidebar,
      callback: function callback($$v) {
        _vm.viewDetailMaintenanceSidebar = $$v;
      },
      expression: "viewDetailMaintenanceSidebar"
    }
  }, [[_vm.viewDetailMaintenanceSidebar ? _c('ViewMaintenanceDetail', {
    attrs: {
      "isEdit": _vm.isEdit,
      "maintenance-id": _vm.selectedMaintenanceId,
      "action": _vm.actionMaintenanceSidebar,
      "onApprove": _vm.onApprove,
      "onReview": _vm.onReview
    },
    on: {
      "save": function save($event) {
        return _vm.closeDetailMaintenance();
      }
    }
  }) : _vm._e()]], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }