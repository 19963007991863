var render = function render(){
  var _vm$data, _vm$data2, _vm$data3, _vm$data11, _vm$data12;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "m-4"
  }, [_vm.data ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "disabled": _vm.exporting
    },
    on: {
      "click": function click($event) {
        return _vm.onExport(_vm.inspectionChecklistData.id, 'pdf');
      }
    }
  }, [_vm.exporting ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _c('i', {
    staticClass: "fa fa-file mr-2"
  }), _vm._v(" Export PDF ")])], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "border my-2 mx-3 w-100"
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Title"
    }
  }, [_vm.isAddInspectionChecklist ? _c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "Type Text",
      "required": ""
    },
    model: {
      value: _vm.form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  }) : _c('strong', [_vm._v(_vm._s((_vm$data = _vm.data) === null || _vm$data === void 0 ? void 0 : _vm$data.title))])], 1)], 1), _vm.isAddInspectionChecklist ? _vm._l((_vm$data2 = _vm.data) === null || _vm$data2 === void 0 ? void 0 : _vm$data2.field, function (state, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "md": "6"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": state === null || state === void 0 ? void 0 : state.label
      }
    }, [state.type === 'Numeric Type' ? _c('b-form-input', {
      attrs: {
        "type": "number",
        "placeholder": "Type Number",
        "required": state === null || state === void 0 ? void 0 : state.required
      },
      model: {
        value: state.value,
        callback: function callback($$v) {
          _vm.$set(state, "value", $$v);
        },
        expression: "state.value"
      }
    }) : _vm._e(), state.type === 'Text Type' ? _c('b-form-input', {
      attrs: {
        "placeholder": "Type Text",
        "required": state === null || state === void 0 ? void 0 : state.required
      },
      model: {
        value: state.value,
        callback: function callback($$v) {
          _vm.$set(state, "value", $$v);
        },
        expression: "state.value"
      }
    }) : _vm._e(), state.type === 'Textarea' ? _c('b-form-textarea', {
      attrs: {
        "placeholder": "Type Text",
        "required": state === null || state === void 0 ? void 0 : state.required
      },
      model: {
        value: state.value,
        callback: function callback($$v) {
          _vm.$set(state, "value", $$v);
        },
        expression: "state.value"
      }
    }) : _vm._e(), state.type === 'Date' ? _c('b-form-input', {
      attrs: {
        "type": "date",
        "required": state === null || state === void 0 ? void 0 : state.required
      },
      model: {
        value: state.value,
        callback: function callback($$v) {
          _vm.$set(state, "value", $$v);
        },
        expression: "state.value"
      }
    }) : _vm._e(), state.type === 'Datetime' ? _c('b-form-input', {
      attrs: {
        "type": "datetime-local",
        "required": state === null || state === void 0 ? void 0 : state.required
      },
      model: {
        value: state.value,
        callback: function callback($$v) {
          _vm.$set(state, "value", $$v);
        },
        expression: "state.value"
      }
    }) : _vm._e(), state.type === 'Boolean Type' ? _c('b-form-checkbox', {
      attrs: {
        "name": "check-button",
        "switch": ""
      },
      model: {
        value: state.value,
        callback: function callback($$v) {
          _vm.$set(state, "value", $$v);
        },
        expression: "state.value"
      }
    }, [_vm._v("Yes / No ")]) : _vm._e()], 1)], 1);
  }) : _vm._l((_vm$data3 = _vm.data) === null || _vm$data3 === void 0 ? void 0 : _vm$data3.field, function (state, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "md": "6"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": state === null || state === void 0 ? void 0 : state.label
      }
    }, [_c('strong', [_vm._v(_vm._s(_vm.handleTypeDate(state === null || state === void 0 ? void 0 : state.value)))])])], 1);
  })], 2)], 1), _c('div', {
    staticClass: "border my-2 mx-3 w-100"
  }), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Question Type Content")]), _vm.data.question_content.length > 0 ? _c('div', {
    staticClass: "w-100"
  }, _vm._l(_vm.data.question_content, function (parentQuestion, parentQuestionIndex) {
    var _parentQuestion$inspe, _vm$data4, _vm$data5, _vm$data6, _vm$data6$question_ty, _vm$data7, _vm$data7$question_co;
    return _c('table', {
      key: parentQuestionIndex,
      staticClass: "table table-striped"
    }, [_c('thead', [!parentQuestion.parent_id && (parentQuestion === null || parentQuestion === void 0 ? void 0 : (_parentQuestion$inspe = parentQuestion.inspection_checklist_question) === null || _parentQuestion$inspe === void 0 ? void 0 : _parentQuestion$inspe.length) <= 0 ? _c('tr', [_c('th', {
      staticClass: "align-middle text-center",
      attrs: {
        "colspan": "6"
      }
    }, [_vm._v(_vm._s(parentQuestion === null || parentQuestion === void 0 ? void 0 : parentQuestion.name))])]) : _c('tr', [_c('th', {
      staticStyle: {
        "max-width": "20px"
      }
    }, [_vm._v("No")]), _c('th', [_vm._v(_vm._s(parentQuestion === null || parentQuestion === void 0 ? void 0 : parentQuestion.name))]), Array.isArray((_vm$data4 = _vm.data) === null || _vm$data4 === void 0 ? void 0 : _vm$data4.question_type_content) ? _vm._l((_vm$data5 = _vm.data) === null || _vm$data5 === void 0 ? void 0 : _vm$data5.question_type_content, function (questionType, questionTypeIndex) {
      return _c('th', {
        key: questionTypeIndex
      }, [_vm._v(" " + _vm._s(questionType === null || questionType === void 0 ? void 0 : questionType.label) + " ")]);
    }) : [['date', 'single-lines-text-box', 'datetime', 'numerical-value'].includes(_vm.data.question_type.code) ? _c('th', [_vm._v(_vm._s((_vm$data6 = _vm.data) === null || _vm$data6 === void 0 ? void 0 : (_vm$data6$question_ty = _vm$data6.question_type_content) === null || _vm$data6$question_ty === void 0 ? void 0 : _vm$data6$question_ty.label))]) : _vm._e()], _vm.data.using_remarks ? _c('th', [_vm._v("Remarks")]) : _vm._e()], 2)]), ((_vm$data7 = _vm.data) === null || _vm$data7 === void 0 ? void 0 : (_vm$data7$question_co = _vm$data7.question_content) === null || _vm$data7$question_co === void 0 ? void 0 : _vm$data7$question_co.length) > 0 ? _c('tbody', _vm._l(parentQuestion === null || parentQuestion === void 0 ? void 0 : parentQuestion.inspection_checklist_question, function (question, questionIndex) {
      var _vm$data8, _vm$data9, _vm$data10;
      return _c('tr', {
        key: questionIndex
      }, [_c('td', {
        staticStyle: {
          "max-width": "20px"
        }
      }, [_vm._v(_vm._s(questionIndex + 1))]), _c('td', [_vm._v(_vm._s(question.question))]), _vm.data.question_type.code == 'multiple-choice-multiple-answers-allowed' ? _vm._l((_vm$data8 = _vm.data) === null || _vm$data8 === void 0 ? void 0 : _vm$data8.question_type_content, function (questionType, questionTypeIndex) {
        var _question$answer, _question$answer$ques;
        return _c('td', {
          key: questionTypeIndex
        }, [_vm.isAddInspectionChecklist ? _c('b-form-checkbox', {
          attrs: {
            "value": questionType === null || questionType === void 0 ? void 0 : questionType.label,
            "unchecked-value": ''
          },
          model: {
            value: question.answer[questionTypeIndex].value,
            callback: function callback($$v) {
              _vm.$set(question.answer[questionTypeIndex], "value", $$v);
            },
            expression: "question.answer[questionTypeIndex].value"
          }
        }) : _c('span', [_vm._v(_vm._s((_question$answer = question.answer) !== null && _question$answer !== void 0 && (_question$answer$ques = _question$answer[questionTypeIndex]) !== null && _question$answer$ques !== void 0 && _question$answer$ques.value ? '✔️' : ''))])], 1);
      }) : _vm._e(), _vm.data.question_type.code == 'multiple-choice-only-one-answer' ? _vm._l((_vm$data9 = _vm.data) === null || _vm$data9 === void 0 ? void 0 : _vm$data9.question_type_content, function (questionType, questionTypeIndex) {
        var _question$answer2, _question$answer2$que;
        return _c('td', {
          key: questionTypeIndex
        }, [_vm.isAddInspectionChecklist ? _c('b-form-checkbox', {
          attrs: {
            "value": questionType === null || questionType === void 0 ? void 0 : questionType.label,
            "unchecked-value": ''
          },
          model: {
            value: question.answer[questionTypeIndex].value,
            callback: function callback($$v) {
              _vm.$set(question.answer[questionTypeIndex], "value", $$v);
            },
            expression: "question.answer[questionTypeIndex].value"
          }
        }) : _c('span', [_vm._v(_vm._s((_question$answer2 = question.answer) !== null && _question$answer2 !== void 0 && (_question$answer2$que = _question$answer2[questionTypeIndex]) !== null && _question$answer2$que !== void 0 && _question$answer2$que.value ? '✔️' : ''))])], 1);
      }) : _vm._e(), ['single-lines-text-box'].includes(_vm.data.question_type.code) ? [_c('td', [_vm.isAddInspectionChecklist ? _c('b-form-input', {
        attrs: {
          "placeholder": "Type Text"
        },
        model: {
          value: question.answer,
          callback: function callback($$v) {
            _vm.$set(question, "answer", $$v);
          },
          expression: "question.answer"
        }
      }) : _c('span', [_vm._v(_vm._s(_vm.handleTypeDate(question === null || question === void 0 ? void 0 : question.answer)))])], 1)] : _vm._e(), ['multiple-lines-text-box'].includes(_vm.data.question_type.code) ? _vm._l((_vm$data10 = _vm.data) === null || _vm$data10 === void 0 ? void 0 : _vm$data10.question_type_content, function (questionType, questionTypeIndex) {
        return _c('td', {
          key: questionTypeIndex
        }, [_vm.isAddInspectionChecklist ? _c('b-form-input', {
          attrs: {
            "placeholder": "Type Text"
          },
          model: {
            value: question.answer[questionTypeIndex].value,
            callback: function callback($$v) {
              _vm.$set(question.answer[questionTypeIndex], "value", $$v);
            },
            expression: "question.answer[questionTypeIndex].value"
          }
        }) : _c('span', [_vm._v(_vm._s(_vm.handleTypeDate(question.answer[questionTypeIndex].value)))])], 1);
      }) : _vm._e(), ['numerical-value'].includes(_vm.data.question_type.code) ? [_c('td', [_vm.isAddInspectionChecklist ? _c('b-form-input', {
        attrs: {
          "type": "number",
          "placeholder": "Type Number"
        },
        model: {
          value: question.answer,
          callback: function callback($$v) {
            _vm.$set(question, "answer", $$v);
          },
          expression: "question.answer"
        }
      }) : _c('span', [_vm._v(_vm._s(_vm.handleTypeDate(question.answer)))])], 1)] : _vm._e(), ['date'].includes(_vm.data.question_type.code) ? [_c('td', [_vm.isAddInspectionChecklist ? _c('b-form-input', {
        attrs: {
          "type": "date"
        },
        model: {
          value: question.answer,
          callback: function callback($$v) {
            _vm.$set(question, "answer", $$v);
          },
          expression: "question.answer"
        }
      }) : _c('span', [_vm._v(_vm._s(_vm.handleTypeDate(question.answer)))])], 1)] : _vm._e(), ['datetime'].includes(_vm.data.question_type.code) ? [_c('td', [_vm.isAddInspectionChecklist ? _c('b-form-input', {
        attrs: {
          "type": "datetime-local"
        },
        model: {
          value: question.answer,
          callback: function callback($$v) {
            _vm.$set(question, "answer", $$v);
          },
          expression: "question.answer"
        }
      }) : _c('span', [_vm._v(_vm._s(_vm.handleTypeDate(question.answer)))])], 1)] : _vm._e(), _vm.data.using_remarks ? _c('td', [_vm.isAddInspectionChecklist ? _c('b-form-textarea', {
        model: {
          value: question.remarks,
          callback: function callback($$v) {
            _vm.$set(question, "remarks", $$v);
          },
          expression: "question.remarks"
        }
      }) : _c('span', [_vm._v(_vm._s(_vm.handleTypeDate(question === null || question === void 0 ? void 0 : question.remarks)))])], 1) : _vm._e()], 2);
    }), 0) : _vm._e()]);
  }), 0) : _vm._e()])]), _c('div', {
    staticClass: "border my-2 mx-3 w-100"
  }), _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_vm.isAddInspectionChecklist ? _vm._l((_vm$data11 = _vm.data) === null || _vm$data11 === void 0 ? void 0 : _vm$data11.signature, function (state, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "md": "3"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": state === null || state === void 0 ? void 0 : state.label
      }
    }, [state.type === 'Numeric Type' ? _c('b-form-input', {
      attrs: {
        "type": "number",
        "placeholder": "Type Number",
        "required": state === null || state === void 0 ? void 0 : state.required
      },
      model: {
        value: state.value,
        callback: function callback($$v) {
          _vm.$set(state, "value", $$v);
        },
        expression: "state.value"
      }
    }) : _vm._e(), state.type === 'Text Type' ? _c('b-form-input', {
      attrs: {
        "placeholder": "Type Text",
        "required": state === null || state === void 0 ? void 0 : state.required
      },
      model: {
        value: state.value,
        callback: function callback($$v) {
          _vm.$set(state, "value", $$v);
        },
        expression: "state.value"
      }
    }) : _vm._e(), state.type === 'Textarea' ? _c('b-form-textarea', {
      attrs: {
        "placeholder": "Type Text",
        "required": state === null || state === void 0 ? void 0 : state.required
      },
      model: {
        value: state.value,
        callback: function callback($$v) {
          _vm.$set(state, "value", $$v);
        },
        expression: "state.value"
      }
    }) : _vm._e(), state.type === 'Date' ? _c('b-form-input', {
      attrs: {
        "type": "date",
        "required": state === null || state === void 0 ? void 0 : state.required
      },
      model: {
        value: state.value,
        callback: function callback($$v) {
          _vm.$set(state, "value", $$v);
        },
        expression: "state.value"
      }
    }) : _vm._e(), state.type === 'Datetime' ? _c('b-form-input', {
      attrs: {
        "type": "datetime-local",
        "required": state === null || state === void 0 ? void 0 : state.required
      },
      model: {
        value: state.value,
        callback: function callback($$v) {
          _vm.$set(state, "value", $$v);
        },
        expression: "state.value"
      }
    }) : _vm._e(), state.type === 'Boolean Type' ? _c('b-form-checkbox', {
      attrs: {
        "name": "check-button",
        "switch": ""
      },
      model: {
        value: state.value,
        callback: function callback($$v) {
          _vm.$set(state, "value", $$v);
        },
        expression: "state.value"
      }
    }, [_vm._v("Yes / No ")]) : _vm._e()], 1)], 1);
  }) : _vm._l((_vm$data12 = _vm.data) === null || _vm$data12 === void 0 ? void 0 : _vm$data12.signature, function (state, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "md": "3"
      }
    }, [_c('b-form-group', {
      attrs: {
        "label": state === null || state === void 0 ? void 0 : state.label
      }
    }, [_c('strong', [_vm._v(_vm._s(_vm.handleTypeDate(state === null || state === void 0 ? void 0 : state.value)))])])], 1);
  })], 2)], 1)], 1)], 1) : _vm._e(), _vm.data && _vm.isAddInspectionChecklist ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end w-100"
  }, [_c('b-button', {
    staticClass: "mr-4 mt-2",
    staticStyle: {
      "width": "15%"
    },
    attrs: {
      "block": "",
      "variant": "danger",
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.isAddInspectionChecklist = false;
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-remove"
  }), _c('span', [_vm._v("Cancel")])]), _c('b-button', {
    staticStyle: {
      "width": "20%"
    },
    attrs: {
      "disabled": _vm.saving,
      "block": "",
      "variant": "primary",
      "type": "button"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onSave.apply(null, arguments);
      }
    }
  }, [_vm.saving ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _c('div', [_c('i', {
    staticClass: "fa fa-save"
  }), _c('span', [_vm._v("Submit Data")])])])], 1)]) : _vm._e(), !_vm.data ? _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('span', [_vm._v("No Project Forms Data.")])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }