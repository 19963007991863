var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "x": "0.75",
      "y": "0.75",
      "width": "38.5",
      "height": "38.5",
      "rx": "19.25",
      "stroke": _vm.color,
      "stroke-width": "1.5"
    }
  }), _c('path', {
    attrs: {
      "d": "M20.6134 32.5239H10.159C9.54489 32.5239 8.95593 32.2799 8.5217 31.8457C8.08746 31.4114 7.84351 30.8225 7.84351 30.2084V16.3155C7.84351 15.0304 8.8739 14 10.159 14H17.1054L19.4209 16.3155H28.6829C29.297 16.3155 29.8859 16.5594 30.3202 16.9937C30.7544 17.4279 30.9984 18.0169 30.9984 18.631V29.6295L29.7943 28.4023C29.8059 28.2402 29.8406 28.0666 29.8406 27.8929C29.8406 26.5962 29.4007 25.4153 28.6829 24.4544V18.631H10.159V30.2084H18.7494C19.1546 31.1346 19.803 31.945 20.6134 32.5239ZM32.0056 33.9016L27.2473 29.1433C27.7219 27.9392 27.4557 26.5268 26.46 25.5427C25.418 24.4891 23.8551 24.2808 22.5931 24.8596L24.8392 27.1056L23.2762 28.6802L20.9723 26.4226C20.3471 27.6845 20.6366 29.2475 21.6554 30.301C22.1152 30.7701 22.7021 31.0945 23.3438 31.2344C23.9856 31.3742 24.6542 31.3235 25.2675 31.0883L30.0259 35.835C30.2342 36.055 30.5468 36.055 30.7552 35.835L31.9593 34.6425C32.214 34.4342 32.214 34.0637 32.0056 33.9016Z",
      "fill": _vm.color
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }