var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "nav-fill mb-3",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-2 p-0",
          attrs: {
            "active": true,
            "href": "#class",
            "title": "CUSTOMER CLASS"
          },
          nativeOn: {
            "click": function click($event) {
              _vm.tab = 'class';
            }
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "tab-content"
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "class"
    }
  }, [_c('TabCustomerClass', {
    attrs: {
      "refreshMasterData": _vm.refreshMasterData
    }
  })], 1)], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }