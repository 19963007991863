<template>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
              <h5 class="card-title text-primary"><strong>SPAL TEMPLATE</strong></h5>
          </template>
          <template v-slot:headerAction>
            <b-button @click="openSlideFormSpal('ADD', null)"  variant="primary" class="float-right mr-1" size="sm"><i class="fa fa-plus"></i> &nbsp; CREATE SPAL</b-button>
          </template>
  
          <!-- FILTER START -->
          <template>
            <b-row class="m-2">
              <b-col lg="3">
                <b-form-group label="SEARCH">
                  <b-form-input placeholder="Search..." />
                </b-form-group>
              </b-col>
              <b-col lg="3">
                <b-form-group label="CONTRACT TYPE">
                  <b-form-select plain placeholder="Select Options..." :options="optionsContractType" v-model="params.contract_type_id">
                    <template v-slot:first>
                      <b-form-select-option :value="null">Select Options</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>  

              <b-col lg="5"></b-col>
              
              <b-col lg="1">
                <b-form-group style label="Filter" label-for="button_search">
                  <b-button variant="primary" class="mb-3 mr-1" ><i class="fa fa-search"></i></b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </template>
  
          <!-- LIST -->
          <template>
            <b-row class="m-2">
              <b-col md="12" v-if="!loading">
                <table class="table table-striped" v-if="listSpal.data.length > 0">
                  <thead>
                    <tr>
                      <th class="text-center" width="10px">NO</th>
                      <th style="width:150px">NAME</th>
                      <th class="text-center">TYPE</th>
                      <th class="text-center">LANGUAGE</th>
                      <th class="text-center">STATUS</th>
                      <th class="text-center">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(state, index) in listSpal.data" :key="index">
                      <th class="text-center" width="10px">{{ index + 1 }}</th>
                      <td>{{ state?.name ?? '-' }}</td>
                      <td class="text-center">{{ state?.contract_type?.name ?? '-' }}</td>
                      <td class="text-center">
                        <div class="d-flex flex-wrap align-items-center flex-column">
                          <b-badge 
                            v-for="(language, langIndex) in JSON.parse(state?.language)"
                            class="border border-primary text-primary text-uppercase mb-2 mr-2 w-auto" 
                            variant="none" 
                          >{{ language?.text }}</b-badge>
                        </div>
                      </td>
                      <td class="text-center text-success">Active</td>
                      <td class="text-center">
                        <b-dropdown 
                          size="lg"
                          variant="link iq-bg-primary iq-border-radius-10"
                          toggle-class="text-decoration-none"
                          no-caret
                        >
                          <template v-slot:button-content>
                            <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                              <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                            </span>
                          </template>
                          <b-dropdown-item href="#" variant="primary" @click="openSlideFormSpal('PREVIEW', state?.id)">
                            <i class="fa fa-eye mr-2"></i>Preview
                          </b-dropdown-item>
                          <b-dropdown-item href="#" variant="success" @click="openSlideFormSpal('EDIT', state?.id)">
                            <i class="fa fa-pen mr-2"></i>Edit
                          </b-dropdown-item>
                          <b-dropdown-divider />
                          <b-dropdown-item href="#" variant="danger" @click="onDeleteSpal(state)">
                            <i class="fa fa-ban mr-2"></i>Delete
                          </b-dropdown-item>
                        </b-dropdown>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="mt-3" v-if="listSpal.data.length > 0">
                  <b-pagination
                    first-number
                    align="center"
                  ></b-pagination>
                </div>
                <div class="d-flex align-items-center justify-content-center w-100 my-2 mb-4" v-else>
                    <strong class="text-muted">No Data Spal.</strong>
                  </div>
              </b-col>
              <b-col md="12" v-else> 
                <div  class="alert alert-info">
                Loading...
                </div>
              </b-col>
            </b-row>
          </template>
  
          <!-- SLIDE - FORM ADD PLAN -->
          <b-sidebar
            v-model="slideAddSpal"
            id="add_spal"
            :title="`${action} SPAL TEMPLATE`"
            width="100%"
            backdrop-variant="dark"
            bg-variant="white"
            right
            backdrop
            shadow>
            <template #default="{hide}">
              <SlideFormSpal 
                :refresh-data="refreshData" 
                :optionsContractType="optionsContractType" 
                :editId="editId" 
                :action="action" 
                @updateAction="updateAction" 
              /> 
            </template>
          </b-sidebar>
          <!-- SLIDE - FORM ADD PLAN END -->
        </iq-card>
      </b-col>
    </b-row>
  </template>
  
  <script>
  import _ from 'lodash'
  import moment from 'moment'
  import { projectsActions, busdevActions } from '@src/Utils/helper'
  import SlideFormSpal from './SlideFormSpal.vue'
  
  export default {
    name: 'Spal',
    data() {
      return{
        action: '',

        loading: false,
        editId: null,

        optionsContractType: [],

        slideAddSpal: false,

        params: {
          page:1,
          perPage:25,
          search:'',
          contract_type_id:null,
        },  
        listSpal: {
          data:[],
          meta:null
        }
      }

    },
    async mounted () {
      this.getContractType()
      this.getListSpal()
    },
    components:{
      SlideFormSpal
    },
  
    methods: {
      ...busdevActions,
      updateAction(newAction){
        this.action = newAction
      },
      openSlideFormSpal(action, id){
        this.action = action
        this.editId = id
        this.slideAddSpal = !this.slideAddSpal
      },
      async refreshData(){
        this.slideAddSpal = false

        await this.getListSpal()
      },
      async getContractType() {
        let res = await this.fetchContractType()
        if (res.data.status === true && res.data.data.length > 0) {
          this.optionsContractType = res.data.data.map(val => {
            return {
              text: val.name,
              value: val.id
            }
          })
        }
      },
      async getListSpal() {
        let params = {
          ...this.params
        }

        if(!params.search) delete params.search
        if(!params.contract_type_id) delete params.contract_type_id

        let res = await this.fetchSpal(params)

        if (res.data.status == 'success' || res.data.status == true) {
          if (res.data.data && res.data.data.data.length > 0) {
            this.listSpal.data = res.data.data.data
            this.listSpal.meta = res.data.data.meta
          }
        } 
      },

    // ON DELETE
    async onDeleteSpal(data){
      this.$swal({
          title: `Are you sure want to delete ${data?.name}?`,
          text: "Yes, i'm sure.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
          if (result.isConfirmed) {
            const res = await this.deleteSpal({id: data.id })

            if(res.data?.status === true ){
              this.refreshData()
              this.$swal(
                'Success!',
                'Success deleted data.',
                'success'
              )
            } else {
              this.$swal(
                  'Failed!',
                  'Something went wrong.',
                  'error'
              )
              return 
            }
          }
      })
    },
    },
  }
  </script>