var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-nav', {
    staticClass: "custom-nav",
    class: {
      'custom-nav-primary': _vm.primary
    }
  }, _vm._l(_vm.items, function (item, index) {
    return _c('b-nav-item', {
      key: index,
      staticClass: "custom-nav-item",
      class: {
        active: _vm.activeIndex === index,
        'bg-variant-primary': _vm.primary && _vm.activeIndex === index,
        'bg-default': !_vm.primary && _vm.activeIndex === index,
        'hover-primary': _vm.primary,
        'hover-default': !_vm.primary
      },
      on: {
        "click": function click($event) {
          return _vm.handleClick(item.value, index);
        }
      }
    }, [_c('div', {
      staticClass: "m-0 w-100 h-100 nav-item-text",
      class: {
        'text-primary': _vm.activeIndex === index && !_vm.primary,
        'text-white': _vm.activeIndex === index && _vm.primary,
        'text-secondary': _vm.activeIndex != index,
        'hover-text-primary': _vm.primary,
        'hover-text-default': !_vm.primary
      }
    }, [_c('p', {
      staticClass: "m-0",
      staticStyle: {
        "font-weight": "500"
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")])])]);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }