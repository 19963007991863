var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "iq-edit-profile d-flex",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-6 p-0",
          attrs: {
            "active": true,
            "href": "#operational_tab",
            "title": "".concat(_vm.fleet.name, " - OPERATIONAL HISTORY")
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-6 p-0",
          attrs: {
            "active": false,
            "href": "#scl_tab",
            "title": "".concat(_vm.fleet.name, " - SCL (SOUNDING CHECK LIST)")
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "pills-tabContent-2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "operational_tab"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "iq-edit-list"
  }, [_c('tab-nav', {
    staticClass: "nav-fill mb-3 d-flex w-100 justify-content-end align-items-center rounded-0",
    attrs: {
      "pills": true
    }
  }, [_c('tab-nav-items', {
    staticClass: "col-md-2 p-0 bg-white text-truncate",
    attrs: {
      "active": true,
      "href": "#monthly",
      "title": "Monthly Voyage"
    },
    nativeOn: {
      "click": function click($event) {
        _vm.tab = 'monthly';
      }
    }
  }), _c('tab-nav-items', {
    staticClass: "col-md-2 p-0 bg-white text-truncate",
    attrs: {
      "active": false,
      "href": "#by-trip",
      "title": "Voyage by Trip"
    },
    nativeOn: {
      "click": function click($event) {
        _vm.tab = 'by-trip';
      }
    }
  })], 1)], 1)]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "tab-content"
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "monthly"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("OPERATIONAL HISTORY - " + _vm._s(_vm.fleet.name))])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "8"
          }
        }, [_c('date-picker', {
          attrs: {
            "type": "datetime",
            "range": "",
            "placeholder": "Select date range",
            "format": "DD-MM-YYYY HH:mm"
          },
          model: {
            value: _vm.historiesParamsDate,
            callback: function callback($$v) {
              _vm.historiesParamsDate = $$v;
            },
            expression: "historiesParamsDate"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.onFilterMonthly();
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1)], 1), _c('b-col', {
          staticClass: "text-right",
          attrs: {
            "md": "6"
          }
        }, [_vm.lsProfile.menu[1].child[0].child[1].export && _vm.lsProfile.menu[1].child[0].child[1].export === true ? _c('button', {
          staticClass: "btn btn-dark mb-3 mr-1",
          on: {
            "click": function click($event) {
              return _vm.exportVoyageHistories();
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-file-excel"
        }), _vm._v(" Export")]) : _vm._e(), _c('router-link', {
          attrs: {
            "to": {
              name: 'dashboard.operational-input',
              query: {
                vehicleId: _vm.$route.query.vehicleId
              }
            }
          }
        }, [_vm.lsProfile.menu[1].child[0].child[1].add && _vm.lsProfile.menu[1].child[0].child[1].add === true && !_vm.isChildComponent ? _c('button', {
          staticClass: "btn btn-primary mb-3"
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" Add Operational Data")]) : _vm._e()])], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('hr'), _vm.voyageMatrix.missingDuration ? _c('b-alert', {
          attrs: {
            "show": "",
            "variant": "warning"
          }
        }, [_vm._v(_vm._s(_vm.voyageMatrix.missingDuration) + ", Not yet input")]) : _vm._e(), _vm.loading_voyage_history ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.voyageHistories.length > 0 ? [_c('div', {
          staticClass: "overflow-auto tableFixHead",
          staticStyle: {
            "max-height": "450px"
          }
        }, [_c('table', {
          staticClass: "table mb-0 table-striped table-hover"
        }, [_c('thead', {
          staticClass: "thead-dark text-center"
        }, [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("No")]), _c('th', [_vm._v("Type Voyage")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Project")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Start Date")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("End Date")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Reason")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Hours")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Cargo Loading")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Cargo Unloading")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("POL")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("POD")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Current Position")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Remarks")]), _vm.showLatitude && _vm.showLongtitude ? _c('th', {
          staticClass: "text-center"
        }, [_vm._v("GPS")]) : _vm._e(), _vm.showSpeed ? _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Speed")]) : _vm._e(), (_vm.lsProfile.menu[1].child[0].child[1].delete === true || _vm.lsProfile.menu[1].child[0].child[1].update === true) && !_vm.isChildComponent ? _c('th', [_vm._v("#")]) : _vm._e()])]), _c('tbody', _vm._l(_vm.voyageHistories, function (item, index) {
          return _c('tr', {
            key: "h-".concat(index),
            staticStyle: {
              "font-size": "10pt"
            }
          }, [_c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(index += 1))]), _c('td', [_c('ol', {
            staticStyle: {
              "width": "150px"
            }
          }, _vm._l(item.vehicle, function (v, v_index) {
            return v.id != _vm.vehicleId ? _c('li', {
              key: "h-v-".concat(v_index)
            }, [_c('span', {
              staticClass: "text-truncate"
            }, [_vm._v(_vm._s(v.name))])]) : _vm._e();
          }), 0), _vm._v(" "), _vm.isBreakdown(item) ? _c('br') : _vm._e(), _vm.isBreakdown(item) ? _c('strong', {
            staticClass: "text-danger"
          }, [_vm._v("Breakdown")]) : _vm._e()]), _c('td', {
            staticClass: "text-center"
          }, [_c('p', {
            staticClass: "mb-0 text-truncate"
          }, [_vm._v(_vm._s(item.subProject.project.projectName) + " - " + _vm._s(item.subProject.name))])]), _c('td', {
            staticClass: "text-center text-truncate"
          }, [_vm._v(" " + _vm._s(item.startDate ? _vm.moment(item.startDate, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-center text-truncate"
          }, [_vm._v(" " + _vm._s(item.endDate ? _vm.moment(item.endDate, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-center text-truncate"
          }, [_vm._v(_vm._s(item.reason.name))]), _c('td', {
            staticClass: "text-center text-truncate"
          }, [_vm._v(" " + _vm._s(item.activityDurationFormat) + " ")]), _c('td', {
            staticClass: "text-center text-truncate"
          }, [_vm._v(_vm._s(item.cargoLoading ? _vm.numberFormat(item.cargoLoading) : '-'))]), _c('td', {
            staticClass: "text-center text-truncate"
          }, [_vm._v(_vm._s(item.cargoUnloading ? _vm.numberFormat(item.cargoUnloading) : '-'))]), _vm.showPortAgentPol ? _c('td', {
            staticClass: "text-center text-truncate"
          }, [_c('p', {
            staticClass: "mb-0"
          }, [_vm._v(_vm._s(item.portLoading.portName))])]) : _vm._e(), _vm.showPortAgentPod ? _c('td', {
            staticClass: "text-center text-truncate"
          }, [_c('p', {
            staticClass: "mb-0"
          }, [_vm._v(_vm._s(item.portDischarge.portName))])]) : _vm._e(), _c('td', {
            staticClass: "text-center text-truncate"
          }, [_vm._v(_vm._s(item.currentPosition))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(item.remark))]), _vm.showLatitude && _vm.showLongtitude ? _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(item.dmsLatitude || '-') + ", " + _vm._s(item.dmsLongitude || '-'))]) : _vm._e(), _vm.showSpeed ? _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(item.speed))]) : _vm._e(), (_vm.lsProfile.menu[1].child[0].child[1].delete === true || _vm.lsProfile.menu[1].child[0].child[1].update === true) && !_vm.isChildComponent ? _c('td', {
            staticStyle: {
              "position": "sticky",
              "right": "0",
              "z-index": "1020",
              "background": "white"
            }
          }, [_c('div', {
            staticClass: "d-flex flex-column"
          }, [_vm.lsProfile.menu[1].child[0].child[1].update === true ? _c('b-button', {
            staticClass: "mb-2",
            attrs: {
              "variant": "success",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.toEditHistory(item.id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]) : _vm._e(), _vm.lsProfile.menu[1].child[0].child[1].delete === true ? _c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeleteActivity(item);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })]) : _vm._e()], 1)]) : _vm._e()]);
        }), 0)])])] : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v(" No voyage histories data found. ")])]], 2)], 1)];
      },
      proxy: true
    }])
  })], 1), _vm.voyageMatrix && _vm.voyageMatrix.activities && _vm.voyageMatrix.activities.length > 0 ? _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("OPERATIONAL MATRIX - " + _vm._s(_vm.fleet.name))])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table mb-0 table-striped table-hover",
          staticStyle: {
            "width": "2200px"
          }
        }, [_c('thead', {
          staticClass: "text-center"
        }, [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Date")]), _vm._l(_vm.voyageMatrix.activities, function (reason, index) {
          return _c('th', {
            key: index,
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(reason.name))]);
        })], 2)]), _c('tbody', [_vm.voyageHistories.length > 0 ? _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("1")]), _c('td', {
          staticClass: "text-center text-truncate"
        }, [_vm._v(_vm._s(_vm.voyageMatrix.startDate ? _vm.formatDate(_vm.voyageMatrix.startDate) : '') + " - " + _vm._s(_vm.voyageMatrix.endDate ? _vm.formatDate(_vm.voyageMatrix.endDate) : ''))]), _vm._l(_vm.voyageMatrix.activities, function (value, indexValue) {
          return _c('td', {
            key: indexValue,
            staticClass: "text-center text-truncate"
          }, [_vm._v(_vm._s(value.durationFormat))]);
        })], 2) : _vm._e()])])])])], 1)];
      },
      proxy: true
    }], null, false, 963016765)
  })], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        var _vm$fleet;
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("OPERATIONAL MATRIX BY SYSTEM - " + _vm._s((_vm$fleet = _vm.fleet) === null || _vm$fleet === void 0 ? void 0 : _vm$fleet.name))])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_vm.listMatrixBySystem && _vm.listMatrixBySystem.activities.length > 0 ? _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex overflow-auto"
        }, [_c('table', {
          staticClass: "table mb-0 table-striped table-hover"
        }, [_c('thead', {
          staticClass: "text-center"
        }, [_c('tr', [_c('th', [_vm._v("No")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Date")]), _vm._l(_vm.listMatrixBySystem.activities, function (reason, index) {
          return _c('th', {
            key: index,
            staticClass: "text-center text-truncate"
          }, [_vm._v(_vm._s(reason.name))]);
        })], 2)]), _c('tbody', [_c('tr', [_c('th', [_vm._v("1")]), _c('td', {
          staticClass: "text-center text-truncate"
        }, [_vm._v(_vm._s(_vm.listMatrixBySystem.startDate ? _vm.formatDate(_vm.listMatrixBySystem.startDate) : '') + " - " + _vm._s(_vm.listMatrixBySystem.endDate ? _vm.formatDate(_vm.voyageMatrix.endDate) : ''))]), _vm._l(_vm.listMatrixBySystem.activities, function (value, indexValue) {
          return _c('td', {
            key: indexValue,
            staticClass: "text-center text-truncate"
          }, [_vm._v(_vm._s(value.durationFormat))]);
        })], 2)])])])]) : _c('b-col', {
          staticClass: "align-center text-center",
          attrs: {
            "md": "12"
          }
        }, [_c('strong', {
          staticClass: "text-center text-muted"
        }, [_vm._v("No Data.")])])], 1)];
      },
      proxy: true
    }])
  })], 1), _vm.voyageMatrix.activities && _vm.voyageMatrix.activities.length > 0 ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height pb-4",
      "body-class": ""
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("OPERATIONAL STATISTIC")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [!_vm.isLoadChart ? _c('b-row', [_c('b-col', [_c('div', {
          staticClass: "text-center"
        }, [_c('b-spinner', {
          attrs: {
            "label": "Spinning"
          }
        })], 1)])], 1) : _vm._e(), _vm.isLoadChart && _vm.voyageHistories.length > 0 ? _c('b-row', _vm._l(_vm.opsChart, function (item, index) {
          return _c('b-col', {
            key: index,
            attrs: {
              "md": "12"
            }
          }, [item.type === 'pie' ? _c('div', {
            staticClass: "mb-2"
          }, [_c('b-form-group', {
            attrs: {
              "label": 'Filter Chart '
            }
          }, [_c('v-multiselect', {
            attrs: {
              "tag-placeholder": "Category",
              "placeholder": "Search or statistic",
              "label": "name",
              "track-by": "name",
              "options": _vm.filterFields,
              "multiple": true,
              "taggable": true
            },
            model: {
              value: item.bodyData.data,
              callback: function callback($$v) {
                _vm.$set(item.bodyData, "data", $$v);
              },
              expression: "item.bodyData.data"
            }
          })], 1), _c('AmChart', {
            attrs: {
              "element": "pie-chart-".concat(index, "-"),
              "type": item.bodyData.type,
              "option": item.bodyData
            }
          })], 1) : _vm._e(), item.type === 'bar' ? _c('div', {
            staticClass: "mb-2"
          }, [_c('b-form-group', {
            attrs: {
              "label": 'Filter Chart '
            }
          }, [_c('v-multiselect', {
            attrs: {
              "tag-placeholder": "Category",
              "placeholder": "Search or statistic",
              "options": _vm.filterFields.map(function (field) {
                return field.name;
              }),
              "multiple": true,
              "taggable": true
            },
            on: {
              "input": function input($event) {
                return _vm.onChangeChart(index);
              }
            },
            model: {
              value: item.bodyData.xaxis.categories,
              callback: function callback($$v) {
                _vm.$set(item.bodyData.xaxis, "categories", $$v);
              },
              expression: "item.bodyData.xaxis.categories"
            }
          })], 1), !item.loadingChart ? _c('ApexChart', {
            attrs: {
              "element": "bar-chart-".concat(index, "-"),
              "chartOption": item.bodyData
            }
          }) : _vm._e()], 1) : _vm._e()]);
        }), 1) : _vm._e()];
      },
      proxy: true
    }], null, false, 2477670246)
  })], 1) : _vm._e(), _vm.voyageMatrix.projects && _vm.voyageMatrix.projects.length > 0 ? _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch",
      "body-class": ""
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("PROJECT & CARGO")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table mb-0 table-striped table-hover"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("No")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Project")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Trip")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Cargo")])])]), _c('tbody', _vm._l(_vm.voyageMatrix.projects, function (item, index) {
          return _c('tr', {
            key: index
          }, [_c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(index + 1))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(item.name))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(_vm.numberFormat(item.trip)))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(_vm.numberFormat(item.cargo)))])]);
        }), 0), _c('tfoot', [_c('tr', [_c('td', {
          staticClass: "font-weight-bold text-right",
          attrs: {
            "colspan": "2"
          }
        }, [_vm._v("Total")]), _c('td', {
          staticClass: "font-weight-bold text-center"
        }, [_vm._v(_vm._s(_vm.totalTripMatrixProject))]), _c('td', {
          staticClass: "font-weight-bold text-center"
        }, [_vm._v(_vm._s(_vm.totalCargoMatrixProject))])])])])])];
      },
      proxy: true
    }], null, false, 3227080906)
  })], 1) : _vm._e()], 1)], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "by-trip"
    }
  }, [_c('TabVoyageByTrip', {
    attrs: {
      "fleet": _vm.fleet
    }
  })], 1)], 1)])], 1)], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "scl_tab"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("STEAMING TIME, SOUNDING CHECK LIST, & SUMMARY")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "8"
          }
        }, [_c('b-form', {
          staticClass: "row",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.fetchSCL();
            }
          }
        }, [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "From",
            "label-for": "from"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "exampleInputmonth",
            "type": "date"
          },
          model: {
            value: _vm.sclDateFilter.startDate,
            callback: function callback($$v) {
              _vm.$set(_vm.sclDateFilter, "startDate", $$v);
            },
            expression: "sclDateFilter.startDate"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "To",
            "label-for": "to"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "exampleInputmonth",
            "type": "date"
          },
          model: {
            value: _vm.sclDateFilter.endDate,
            callback: function callback($$v) {
              _vm.$set(_vm.sclDateFilter, "endDate", $$v);
            },
            expression: "sclDateFilter.endDate"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Filter",
            "label-for": "filter"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1)], 1)], 1), _c('b-col', {
          staticClass: "text-right",
          attrs: {
            "md": "4"
          }
        }, [_c('button', {
          staticClass: "btn btn-dark mb-3 mr-1"
        }, [_vm.sclCanExport ? _c('i', {
          staticClass: "fa fa-file-excel"
        }) : _vm._e(), _vm._v(" Export")]), _vm.sclCanAdd ? _c('router-link', {
          attrs: {
            "to": {
              path: '/scl/scl-add',
              query: {
                vehicleId: _vm.$route.query.vehicleId
              }
            }
          }
        }, [_c('button', {
          staticClass: "btn btn-primary mb-3"
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" Add SCL")])]) : _vm._e()], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('hr'), _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table mb-0 table-striped table-hover"
        }, [_c('thead', {
          staticClass: "thead-dark"
        }, [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Fleet")]), _c('th', [_vm._v("Port of Loading")]), _c('th', [_vm._v("Port of Discharge")]), _c('th', [_vm._v("Cargo Type")]), _c('th', [_vm._v("Quantity")]), _c('th', [_vm._v("Total Steaming Time")]), _vm.sclCanDelete || _vm.sclCanEdit ? _c('th') : _vm._e()])]), _c('tbody', [_vm._l(_vm.sclData, function (each, i) {
          return _vm.sclData.length > 0 ? _c('tr', [_c('td', [_vm._v(_vm._s(i += 1))]), _c('td', [_c('router-link', {
            attrs: {
              "to": ""
            }
          }, [_vm._v(_vm._s(each.tugBargeName))])], 1), _c('td', [_vm._v(_vm._s(each.pol))]), _c('td', [_vm._v(_vm._s(each.pod))]), _c('td', [_vm._v(_vm._s(each.cargoType))]), _c('td', [_vm._v(_vm._s(each.quantity) + " MT")]), _c('td', [_c('router-link', {
            attrs: {
              "to": ""
            }
          }, [_vm._v(_vm._s(each.steamingTime))])], 1), _vm.sclCanDelete || _vm.sclCanEdit ? _c('td', [_vm.sclCanEdit ? _c('router-link', {
            attrs: {
              "to": {
                path: '/scl/scl-add',
                query: {
                  vehicleId: _vm.$route.query.vehicleId,
                  scl_id: each.id
                }
              }
            }
          }, [_c('b-button', {
            staticClass: "mr-2",
            attrs: {
              "variant": "success",
              "size": "sm"
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })])], 1) : _vm._e(), _vm.sclCanDelete ? _c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeleteSCL(each);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })]) : _vm._e()], 1) : _vm._e()]) : _vm._e();
        }), _vm.sclData.length === 0 ? _c('tr', [_c('td', {
          staticClass: "text-center",
          attrs: {
            "colspan": "8"
          }
        }, [_vm._v("No Data")])]) : _vm._e()], 2)])])])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }