<template>
  <ApexChart
    :element="`chart-breakdown-vs-availability-${element}`"
    :chartOption="chartFleetBreakdown"
  />
</template>
<script>
export default {
  props: {
    series: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    element: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      chartFleetBreakdown: {
        series: [70, 30],
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: ['Breakdown', 'Availability'],
        colors:['#1235C5','#DB8E01'],
        legend: {
          position: 'bottom',
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -40,
              minAngleToShowLabel: 10,
              style: {
                fontfontSize: '18px',
              },
            },
          },
        },
        fill: {
          type: 'gradient', // Menggunakan gradient fill
          gradient: {
            shade: 'light', // Warna gradient (light atau dark)
            type: 'radial', // Radial gradient untuk pie chart
            shadeIntensity: 0.5, // Intensitas shade
            gradientToColors: ['#40A0FC','#FFA500'], // Warna tujuan gradient
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [10, 100], // Gradient stops dari 0% hingga 100%
          },
        },
      },
    }
  },
}
</script>
