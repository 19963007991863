<template>
  <b-row>
    <b-col lg="12">
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title text-primary"><strong>MASTER PFA</strong></h4>
        </template>
        <template v-slot:body>
          <b-form class="row">
            <b-col cols="12" md="3">
              <b-form-group
                label="Search pfa :"
                label-for="kind-fleet"
              >
                <b-form-input
                  id="name"
                  class="w-100"
                  type="text"
                  placeholder="Search..."
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="2">
              <b-form-group label="Search" label-for="button_search">
                <b-button type="submit" variant="primary" class="ml-2"><i class="fa fa-search"></i></b-button>
              </b-form-group>
            </b-col>
          </b-form>
        </template>
      </iq-card>
    </b-col>
    <b-col sm="8">
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">List Master PFA</h4>
        </template>
        <template v-slot:body>
          <div v-if="list._loading" class="text-center my-5">
            <b-spinner type="grow" label="Spinning"></b-spinner>
          </div>
          <template v-else>
            <div class="d-flex w-100 overflow-auto" v-if="list.data && list.data.length > 0">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th width="10%">No</th>
                    <th width="20%" class="text-truncate">Vehicle Type</th>
                    <th width="30%" class="text-truncate">Component Expense</th>
                    <th width="30%" class="text-truncate">Cost Factor</th>
                    <th width="30%" class="text-truncate">Port Stay</th>
                    <th width="10%" class="text-right">#</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(p, i) in list.data" :key="`p-${i}`">
                    <td>{{ i + 1 }}</td>
                    <td>{{ p.vehice_type_name }}</td>
                    <td>
                      <div class="d-flex flex-wrap w-100" v-if="p.component_expense.length > 0">
                        <b-badge v-for="(obj, objIndex) in p?.component_expense" :key="objIndex" class="border border-primary text-primary text-uppercase mr-2 mb-2" variant="none">{{ obj?.name }}</b-badge>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex flex-wrap w-100" v-if="p.cost_factor.length > 0">
                        <b-badge v-for="(obj, objIndex) in p?.cost_factor" :key="objIndex" class="border border-primary text-primary text-uppercase mr-2 mb-2" variant="none">{{ obj?.name }}</b-badge>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex flex-wrap w-100" v-if="p.port_stay.length > 0">
                        <b-badge v-for="(obj, objIndex) in p?.port_stay" :key="objIndex" class="border border-primary text-primary text-uppercase mr-2 mb-2" variant="none">{{ obj?.name }}</b-badge>
                      </div>
                    </td>
                    <td class="text-right">
                      <b-button variant="success" size="sm" class="mb-2" @click="editForm(p)"><i class="fa fa-edit"></i></b-button>
                      <b-button variant="danger" size="sm" @click="onDelete(p)">
                        <i v-if="!deleted" class="fa fa-trash"></i>
                        <template v-else>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Deleting...
                        </template>
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="list.data && list.data.length > 0">
              <b-pagination
                v-model="list.meta.page"
                :total-rows="list.meta.totalItem"
                @change="getMasterPFA"
                :per-page="list.meta.perPage"
                first-number
                align="center"
              ></b-pagination>
            </div>
            <p v-else class="text-center my-4">No Master PFA data found.</p>
          </template>
        </template>
      </iq-card>
    </b-col>
    <b-col sm="4">
      <iq-card
        :class="{
          'border border-danger shadow-lg': edit,
        }"
      >
        <template v-slot:headerTitle>
          <h4 class="card-title"
          >{{ edit ? 'Edit' : 'Add' }} Master PFA</h4
          >
        </template>
        <template v-slot:body>
          <b-form @submit.prevent="onSave()">
            <b-form-group label="Vehicle Type" label-for="vehicle_type">
              <v-multiselect
                :options="optionsVehicleType"
                placeholder="Select Vehicle Type Group"
                track-by="id"
                label="name"
                v-model="form.vehicle_type"
                :disabled="edit"
              >
                <span slot="noResult"
                >Oops! No elements found.</span>
              </v-multiselect>
            </b-form-group>
            <b-form-group label="Component Expense" label-for="project_name">
              <v-multiselect   v-model="form.component_expense" placeholder="Select component" label="name"
                track-by="id" :options="optionsComponent" :multiple="true">
                <span slot="noResult">Data not found.</span>
              </v-multiselect>
            </b-form-group>
            <b-form-group label="Cost Factor" label-for="name">
              <v-multiselect   v-model="form.cost_factor" placeholder="Select Cost factor" label="name"
                track-by="id" :options="optionsCostFactor" :multiple="true">
                <span slot="noResult">Data not found.</span>
              </v-multiselect>
            </b-form-group>
            <b-form-group label="Port Stay" label-for="name">
              <v-multiselect   v-model="form.port_stay" placeholder="Select Port Stay" label="name"
                track-by="id" :options="optionsPortStay" :multiple="true">
                <span slot="noResult">Data not found.</span>
              </v-multiselect>
            </b-form-group>
            <hr />
            <b-form-group class="text-right">
              <b-button type="submit" variant="primary">
                <span v-if="!edit">Add</span>
                <span v-else>Save Edit</span>
              </b-button>
              <b-button
                type="button"
                variant="danger"
                class="iq-bg-danger ml-3"
                @click="resetForm()"
              >Cancel</b-button>
            </b-form-group>
          </b-form>
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>

<script>
import { busdevActions, srActions } from '@src/Utils/helper'
import _ from 'lodash'
export default {
  name: 'Pfa',
  data () {
    return {
      optionsComponent:[],
      optionsPortStay:[],
      optionsCostFactor:[],
      optionsVehicleType:[],

      list: {
        data: [],
        _loading: true,
        detail: null,
        params: {
          perPage: 10000,
          page: 1
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        }
      },
      loading:false,
      saving:false,
      deleted:false,

      form: {
        "component_expense": [],
        "cost_factor": [],
        "port_stay": [],
        "vehicle_type": null,
      },
      edit: null,
      id: null
    }
  },
  async mounted () {
    await this.getMasterPFA()
    await this.getPortStay()
    await this.getCostFactor()
    await this.getComponentExpense()
    await this.getListVehicleType()
  },
  created () {
    this.getMasterPFA = _.debounce(this.getMasterPFA, 200)
  },
  methods: {
    ...srActions,
    ...busdevActions,
    async getPortStay () {
      let params = {
        perPage: 100000,
        page: 1
      }

      const res = await this.fetchPortStay(params)
  
      if (res.data.status == true) {
        if(res?.data?.data?.data.length > 0){
          this.optionsPortStay = res.data.data?.data
        }
      }
    },
    async getListVehicleType() {
      let res = await this.fetchListVehicleType()
      if (res.status === 'error') {
        this.optionsVehicleType = []
      } else {
        // Filter out objects from data1 that have the same name and value as any object in data2
        this.optionsVehicleType = res?.data?.data
        // ?.filter(item1 => !this.list?.data?.some(item2 => item1?.name == item2?.vehice_type_name));
      }
    },
    async getComponentExpense () {
      this.optionsComponent = []
      let params = {
        perPage: 100000,
        page: 1
      }

      const res = await this.fetchComponentExpense(params)

      if (res.data.status == true) {
        this.optionsComponent = res.data.data?.data
      } 
    },
    async getCostFactor () {
      this.optionsCostFactor = []
      let params = {
        perPage: 10000,
        page: 1
      }

      const res = await this.fetchCostFactor(params)
  
      if (res.data.status == true) {
        if(res?.data?.data?.data.length > 0){
          this.optionsCostFactor = res.data.data?.data
        }
      }
    },
    async getMasterPFA (page) {
      this.list._loading = true
      this.list.data = []
      let params = {
        ...this.list.params,
        page: page || 1
      }

      const res = await this.fetchMasterPfa(params)
  
      if (res.data.status == true) {
        if(res?.data?.data?.data.length > 0){
          this.list.data = res.data.data?.data
          this.list.meta = res.data.data?.meta
        }
        this.list._loading = false
      } else {
        this.list._loading = false
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },

    resetForm () {
      this.form = {
        "component_expense": [],
        "cost_factor": [],
        "port_stay": [],
        "vehicle_type": null,
      }
      this.edit = false
      this.id = null
    },

    editForm (data) {
      this.form = {
        "component_expense": data.component_expense,
        "cost_factor": data.cost_factor,
        "port_stay": data.port_stay,
        "vehicle_type": data.vehicle_type,
      }
      this.edit = true
      this.id = data.vehicle_type.id
    },

    async onDelete (data) {
      this.$swal({
        title: 'Delete Master PFA?',
        text: `Master PFA will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.saving = true
          let res = await this.deleteMasterPfa({id: data.vehicle_type.id})
          if (res.status != 'success') {
            if (res.data.message != null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
            this.saving = false
          } else {
            this.$swal(
              `Master PFA deleted!`,
              `Master PFA ${data.name} successfully deleted`,
              'success'
            )
            await this.getMasterPFA()
            this.saving = false
          }
        }
      })
    },
    async onSave () {
      this.saving = true
      let tempPayload = {
        ...this.form,
      }
      console.log(tempPayload)

      let payload = []
      
      if(tempPayload.component_expense.length > 0){
        tempPayload.component_expense.map(obj => {
          payload.push({
            "component_expense_id": obj.id,
            "cost_factor_id": null,
            "port_stay_id": null,
            "vehicle_type_id": tempPayload.vehicle_type.id,
          })
        })
      }

      if(tempPayload.cost_factor.length > 0){
        tempPayload.cost_factor.map(obj => {
          payload.push({
            "component_expense_id": null,
            "port_stay_id": null,
            "cost_factor_id": obj.id,
            "vehicle_type_id": tempPayload.vehicle_type.id,
          })
        })
      }

      if(tempPayload.port_stay.length > 0){
        tempPayload.port_stay.map(obj => {
          payload.push({
            "component_expense_id": null,
            "cost_factor_id": null,
            "vehicle_type_id": tempPayload.vehicle_type.id,
            "port_stay_id": obj.id,
          })
        })
      }

      let res = null
      if(this.edit && this.id){
        res = await this.updateMasterPfa({data: payload, id: this.id})
      } else if (!this.edit) {
        res = await this.createMasterPfa({data: payload})
      }

      if (res.data.status === true) {
        this.$swal(`${this.edit ? 'Edit' : "Add"} Master PFA Success!`, res.data.message, 'success')
        this.resetForm()
        this.getMasterPFA()
        this.saving = false
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
        this.saving = false
      }
      this.saving = false
    },
  }
}
</script>

<style scoped>
</style>

