<template>
  <div class="iq-comingsoon pt-5">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-sm-9 text-center">
          <div class="iq-comingsoon-info">
            <a href="#">
              <img
                :src="require('../../../assets/images/logo.png')"
                class="img-fluid w-25"
                alt="logo"
              />
            </a>
          </div>
        </div>
      </div>

      <form action="#" method="post" @submit="onSave">
        <div class="row justify-content-center mt-3">
          <div class="col-lg-8">
            <iq-card>
              <template v-slot:headerTitle>
                <h5 class="card-title text-primary"
                  ><strong>REQUEST SIGN OFF</strong></h5
                >
              </template>
              <template v-slot:headerAction>
                <a
                  class="text-primary float-right ml-3"
                  v-b-toggle.filter
                  role="button"
                  style="cursor: pointer"
                  ><i class="fa fa-filter"
                /></a>
              </template>
              <template v-slot:body>
                <b-collapse visible id="filter" class="mb-2">
                  <b-row>
                    <b-col md="5">
                      <b-form-group label="Nama" label-for="fullname">
                        <b-form-input
                          id="fullname"
                          name="fullname"
                          placeholder="Type Text ..."
                          v-model="params.fullname"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label="NIP" label-for="nip">
                        <b-form-input
                          id="nip"
                          name="nip"
                          placeholder="Type NIP ..."
                          v-model="params.nip"
                        ></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col md="1">
                      <b-button
                        variant="primary"
                        class="mr-1 mt-4"
                        @click="getCrewList()"
                        ><i class="fa fa-search"></i
                      ></b-button>
                    </b-col>
                  </b-row>
                  <!-- FILTER DOCKING SCHEDULE END -->
                </b-collapse>
                <b-row v-if="CrewData !== null">
                  <b-col md="12">
                    <b-form-group label="Nama Crew" label-for="name_crew">
                      <b-form-input
                        type="text"
                        placeholder="Type Text ..."
                        :value="CrewData?.fullname"
                        disabled
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="NIP" label-for="nip">
                      <b-form-input
                        type="text"
                        placeholder="Type Text ..."
                        :value="CrewData?.nip"
                        disabled
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="CrewData !== null">
                  <b-col md="12">
                    <fieldset class="form-group border p-3">
                      <form action="#" method="post" @submit="onSave">
                        <b-form-group
                          label="Alasan Sign Off"
                          label-for="reason_sign_off_id"
                        >
                          <b-form-select
                            plain
                            v-model="form.reason_sign_off_id"
                            :options="optionsReasonSignOff"
                            size="sm"
                            required
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null"
                                >Select Alasan Sign Off</b-form-select-option
                              >
                            </template>
                          </b-form-select>
                        </b-form-group>
                        <b-form-group label="Notice Off" label-for="notice_off">
                          <b-form-input
                            type="date"
                            id="notice_off"
                            name="notice_off"
                            placeholder="Type Text ..."
                            v-model="form.notice_off"
                            required
                          ></b-form-input>
                        </b-form-group>

                        <b-form-group
                          label="Detail Notice Off"
                          label-for="detail_notice_off"
                        >
                          <b-form-input
                            type="text"
                            id="detail_notice_off"
                            name="detail_notice_off"
                            placeholder="Type Text ..."
                            v-model="form.detail_notice_off"
                            required
                          ></b-form-input>
                        </b-form-group>

                        <b-form-group
                          label="Attachment Notice Off"
                          label-for="attachment_notice_off"
                        >
                          <b-form-file
                            type="file"
                            id="attachment_notice_off"
                            name="attachment_notice_off"
                            placeholder="Type Text ..."
                            v-model="form.attachment_notice_off"
                            required
                          ></b-form-file>
                        </b-form-group>
                      </form>
                    </fieldset>
                  </b-col>
                  <div
                    v-if="CrewData === null"
                    class="px-4 mb-4 d-flex align-items-center justify-content-center w-100 bg-active h5"
                  >
                    <strong class="text-muted">
                      PILIH CREW TERLEBIH DAHULU.
                    </strong>
                  </div>
                  <hr />

                  <b-col md="12" class="mt-3">
                    <b-button
                      :disabled="saving"
                      block
                      variant="primary"
                      type="submit"
                      class="my-3"
                    >
                      <span
                        v-if="saving"
                        class="spinner-border spinner-border-sm"
                      />
                      <span v-else>Submit</span>
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import { publicActions } from '@src/Utils/helper'
export default {
  name: 'Interview',
  data() {
    return {
      saving: false,
      optionsReasonSignOff: [],
      params: {
        fullname: '',
        nip: null,
      },
      crewId: null,
      form: {
        reason_sign_off_id: null,
        notice_off: null,
        detail_notice_off: '',
        attachment_notice_off: null,
      },
      CrewData: null,
    }
  },
  async mounted() {
    // await getCrewList()
    await this.getMasterSignOff()
  },
  watch: {
    'form.candidate_id': {
      async handler() {
        await this.getProfileCandidateAndCriteriaInterviewPublic()
      },
      deep: true,
    },
  },
  methods: {
    ...publicActions,
    async getCrewList() {
      const { status, data } = await this.fetchCrewList(this.params)

      console.log(status, 'THIS CREW DATA')
      console.log(data, 'THIS CREW DATA')
      if (status) {
        this.CrewData = data
      }
    },

    async getMasterSignOff() {
      const { status, data } = await this.fetchMasterSignOff()

      if (status) {
        console.log(data)
        data.data.map((val) => {
          this.optionsReasonSignOff.push({
            value: val.id,
            text: val.name,
          })
        })

        console.log(this.optionsReasonSignOff)
      }
    },

    async onSave(e) {
      e.preventDefault()
      this.saving = true

      let formData = new FormData()
      formData.append('reason_sign_off_id', this.form.reason_sign_off_id)
      formData.append('notice_off', this.form.notice_off)
      formData.append('detail_notice_off', this.form.detail_notice_off)
      formData.append('attachment_notice_off', this.form.attachment_notice_off)

      const { status, data } = await this.setNoticeSignOff({
        formData,
        emp_crewing_id: this.CrewData?.id,
      })

      if (status !== 'success') {
        this.saving = false
        this.$swal(`Oops!`, `Harap Isi Semua Form.`, 'error')
        return
      }

      this.$swal(`Tersimpan`, 'Data Tersimpan', 'success')

      setTimeout(() => {
        window.location.reload()
      }, 1000)

      this.saving = false
    },
  },
}
</script>
