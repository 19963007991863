var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("DOCKING FINISH")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-modal', {
          attrs: {
            "centered": "",
            "ok-title": "Filter",
            "title": "Filter Data"
          },
          model: {
            value: _vm.modal.filter,
            callback: function callback($$v) {
              _vm.$set(_vm.modal, "filter", $$v);
            },
            expression: "modal.filter"
          }
        }, [_c('b-row', {
          staticClass: "mb-3"
        }, [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          staticClass: "mb-3",
          attrs: {
            "label": "Period Docking",
            "label-for": "Period",
            "label-cols-sm": "4",
            "label-cols-lg": "3"
          }
        }, [_c('date-picker', {
          staticClass: "w-100",
          attrs: {
            "lang": _vm.lang,
            "type": "date",
            "range": "",
            "placeholder": "Select date range"
          },
          model: {
            value: _vm.lists.dateRange,
            callback: function callback($$v) {
              _vm.$set(_vm.lists, "dateRange", $$v);
            },
            expression: "lists.dateRange"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          staticClass: "mb-3",
          attrs: {
            "label": "Fleet Category",
            "label-for": "fleet_category",
            "placeholder": "Select Fleet category",
            "label-cols-sm": "4",
            "label-cols-lg": "3"
          }
        }, [_c('v-select', {
          attrs: {
            "multiple": "",
            "label": "name",
            "options": _vm.vehicleTypes,
            "reduce": function reduce(type) {
              return type.id;
            }
          },
          on: {
            "input": _vm.onChangeFleetCategory
          },
          model: {
            value: _vm.lists.params.vehicleTypeIds,
            callback: function callback($$v) {
              _vm.$set(_vm.lists.params, "vehicleTypeIds", $$v);
            },
            expression: "lists.params.vehicleTypeIds"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          staticClass: "mb-3",
          attrs: {
            "label": "Vehicles",
            "label-for": "vehicleId",
            "label-cols-sm": "4",
            "label-cols-lg": "3"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.fleetFilteredOptions,
            "reduce": function reduce(fleetFilteredOptions) {
              return fleetFilteredOptions.value;
            },
            "placeholder": "Select Vehicle"
          },
          model: {
            value: _vm.lists.params.vehicleId,
            callback: function callback($$v) {
              _vm.$set(_vm.lists.params, "vehicleId", $$v);
            },
            expression: "lists.params.vehicleId"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Docking Type",
            "label-for": "docking_type",
            "label-cols-sm": "4",
            "label-cols-lg": "3"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.dockingTypes,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Docking Type")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.lists.params.dockingType,
            callback: function callback($$v) {
              _vm.$set(_vm.lists.params, "dockingType", $$v);
            },
            expression: "lists.params.dockingType"
          }
        })], 1)], 1)], 1)], 1), _c('div', {
          staticClass: "d-flex justify-content-between mb-3"
        }, [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-form-input', {
          attrs: {
            "size": "md",
            "placeholder": "search..."
          }
        }), _c('b-button', {
          staticClass: "mx-2",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })]), _c('b-button', {
          staticClass: "d-flex align-items-center",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": _vm.toggleModalFilter
          }
        }, [_c('i', {
          staticClass: "fas fa-sliders"
        }), _c('p', {
          staticClass: "text-truncate m-0"
        }, [_vm._v(" Filter Data")])])], 1)]), _c('table', {
          staticClass: "table table-striped table-sm"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("FLEET NAME")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TOTAL COST")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TOTAL JOBS")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("PERFOMANCE INDICATOR")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("INTERMEDIATE SURVEY")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("SPESIAL SURVEY")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ACTION")])])]), _c('tbody', _vm._l(_vm.lists.data, function (state, index) {
          return _c('tr', {
            key: index
          }, [_c('td', {
            staticClass: "text-center text-uppercase"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.vehicleName))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(_vm.formatCurrency(state === null || state === void 0 ? void 0 : state.totalCost)))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.jobCount) + " Jobs")]), _c('td', {
            staticClass: "text-center"
          }, [_c('b-badge', {
            staticClass: "mr-1",
            attrs: {
              "variant": "success"
            }
          }, [_vm._v("On Time : " + _vm._s(state === null || state === void 0 ? void 0 : state.onTimeJob) + " Jobs")]), _c('b-badge', {
            attrs: {
              "variant": "danger"
            }
          }, [_vm._v("Delay : " + _vm._s(state === null || state === void 0 ? void 0 : state.delayJob) + " Jobs")])], 1), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.intermediate) + " Jobs")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.special) + " Jobs")]), _c('td', {
            staticClass: "text-center"
          }, [_c('b-dropdown', {
            attrs: {
              "size": "sm",
              "variant": "link iq-bg-primary iq-border-radius-10",
              "toggle-class": "text-decoration-none",
              "no-caret": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function fn() {
                return [_c('span', {
                  staticClass: "dropdown-toggle p-0",
                  attrs: {
                    "id": "dropdownMenuButton5",
                    "data-toggle": "dropdown"
                  }
                }, [_c('i', {
                  staticClass: "fa fa-pencil-square-o m-0 text-primary"
                })])];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', {
            attrs: {
              "href": "#",
              "disabled": ""
            }
          }, [_c('router-link', {
            attrs: {
              "to": "/templates/docking-history"
            }
          }, [_c('i', {
            staticClass: "fa fa-ship mr-2"
          }), _vm._v("Detail History ")])], 1)], 1)], 1)]);
        }), 0)]), _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.lists.meta.total,
            "per-page": _vm.lists.meta.perPage,
            "align": "center"
          },
          on: {
            "input": _vm.getDockingScheduleFinishData
          },
          model: {
            value: _vm.lists.params.page,
            callback: function callback($$v) {
              _vm.$set(_vm.lists.params, "page", $$v);
            },
            expression: "lists.params.page"
          }
        })], 1)];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }