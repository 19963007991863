var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "row"
        }, [_c('div', {
          staticClass: "col-md-12"
        }, [_vm.stepLoading ? _c('div', {
          staticClass: "d-flex w-100 align-items-center justify-content-center"
        }, [_c('span', {
          staticClass: "fa fa-spinner text-center text-primary text-lg"
        })]) : _c('div', {
          class: "d-flex w-100  align-items-center gap-2 overflow-auto ".concat(_vm.type_form === 'claim' ? 'justify-content-between' : 'justify-content-center')
        }, _vm._l(_vm.steps, function (state, index) {
          return _c('div', {
            key: index + '-step',
            staticClass: "align-items-center justify-content-center mr-1 d-flex",
            staticStyle: {
              "cursor": "pointer"
            }
          }, [_c('div', {
            staticClass: "align-items-center justify-content-center w-100 d-flex flex-column",
            on: {
              "click": function click($event) {
                return _vm.routing(state.url, state.menu);
              }
            }
          }, [_c('b-avatar', {
            staticClass: "d-flex align-items-center justify-content-center rounded-circle",
            attrs: {
              "variant": _vm.currentStep === state.key && state.isDataExist ? 'success' : _vm.currentStep === state.key && !state.isDataExist ? 'danger' : 'secondary',
              "size": "30"
            }
          }, [state.isDataExist ? _c('i', {
            staticClass: "fa fa-check"
          }) : _c('i', {
            staticClass: "fa fa-ban"
          })]), _c('small', {
            class: "text-truncate ".concat(_vm.currentStep === state.key && state.isDataExist ? 'text-success' : _vm.currentStep === state.key && !state.isDataExist ? 'text-danger' : 'text-secondary')
          }, [_vm._v(_vm._s(state.title))])], 1), _vm.steps.length - 1 !== index ? _c('i', {
            class: "fa fa-arrow-right ".concat(_vm.currentStep === state.key && state.isDataExist ? 'text-success' : _vm.currentStep === state.key && !state.isDataExist ? 'text-danger' : 'text-secondary')
          }) : _vm._e()]);
        }), 0)])])];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }