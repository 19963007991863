var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overflow-auto d-flex flex-column"
  }, [_c('table', {
    staticClass: "table mb-0 table-striped table-hover"
  }, [_c('thead', {
    staticClass: "bg-primary"
  }, [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("No")]), _c('th', [_vm._v("Fleet")]), _c('th', [_vm._v("Date")]), _vm._l(_vm.activitiesHeader, function (item, i) {
    return _c('th', {
      key: i,
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(item))]);
  })], 2), _vm._l(_vm.matrix, function (item, i) {
    return _c('tbody', {
      key: i
    }, [_c('tr', {
      staticStyle: {
        "background": "#d2d2d2 !important"
      }
    }, [_c('td', {
      attrs: {
        "colspan": "19"
      }
    }, [_c('strong', [_vm._v(_vm._s(item.projectSub.name))])])]), _vm._l(item.vehicleTypes, function (vehicle, j) {
      return [_c('tr', {
        key: j + i
      }, [_c('td', {
        staticClass: "pl-3",
        staticStyle: {
          "background": "#ffe8e8 !important"
        },
        attrs: {
          "colspan": "19"
        }
      }, [_vm._v(_vm._s(vehicle.vehicleType.name) + " ")])]), _vm._l(vehicle.vehicles, function (fleet, idx) {
        return _c('tr', {
          key: idx + j + i + 1
        }, [_c('td', {
          staticClass: "text-center"
        }, [_vm._v(_vm._s(idx + 1))]), _c('td', {
          staticClass: "text-truncate"
        }, [_vm._v(_vm._s(fleet.vehicle.name))]), _c('td', {
          attrs: {
            "colspan": "20"
          }
        }, [_vm._v(" " + _vm._s(fleet) + " ")])]);
      })];
    })], 2);
  })], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }