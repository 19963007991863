var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSendMail.apply(null, arguments);
      }
    }
  }, [_c('b-row', {
    staticClass: "m-3"
  }, [!_vm.loading ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    class: "d-flex align-items-center justify-content-end w-100"
  }, [_c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "type": "button",
      "variant": "danger"
    },
    on: {
      "click": _vm.closeSlideSendMail
    }
  }, [_c('div', [_c('i', {
    staticClass: "fa fa-ban"
  }), _c('span', [_vm._v("Cancel")])])]), _c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit"
    }
  }, [_vm.saving ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _c('div', [_c('i', {
    staticClass: "fa fa-save"
  }), _c('span', [_vm._v("Send Mail")])])])], 1), _c('hr')]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Vehicle",
      "label-for": "vehicle_id"
    }
  }, [_c('v-multiselect', {
    attrs: {
      "options": _vm.listVehicle,
      "placeholder": "Select vehicle",
      "track-by": "name",
      "label": "name"
    },
    on: {
      "input": _vm.onSelectedVehicle
    },
    model: {
      value: _vm.form.vehicle_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "vehicle_id", $$v);
      },
      expression: "form.vehicle_id"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v("Oops! No elements found. Consider changing the search query.")])])], 1)], 1), _vm.form.vehicle_id ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Collection Forms",
      "label-for": "collection_forms"
    }
  }, [_c('v-multiselect', {
    attrs: {
      "options": _vm.listCollectionForm,
      "placeholder": "Select forms",
      "track-by": "name",
      "label": "name"
    },
    model: {
      value: _vm.form.collection_of_forms_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "collection_of_forms_id", $$v);
      },
      expression: "form.collection_of_forms_id"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v("Oops! No elements found. Consider changing the search query.")])])], 1)], 1) : _vm._e(), _vm.form.collection_of_forms_id ? _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3 bg-default"
  }, [_c('legend', {
    staticClass: "w-auto px-2 h5 text-primary"
  }, [_vm._v("Head Mail")]), _c('b-form-group', {
    attrs: {
      "label": "Title",
      "label-for": "title"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "id": "title",
      "placeholder": "Type Text"
    },
    model: {
      value: _vm.form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "To",
      "label-for": "to"
    }
  }, [_c('v-multiselect', {
    attrs: {
      "options": _vm.optionsUsers,
      "placeholder": "Select PIC...",
      "track-by": "email",
      "label": "email",
      "multiple": true
    },
    model: {
      value: _vm.form.to,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "to", $$v);
      },
      expression: "form.to"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v("Oops! No elements found. Consider changing the search query.")])])], 1)], 1)]) : _vm._e(), _vm.form.collection_of_forms_id ? _c('b-col', {
    attrs: {
      "md": "8"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3 bg-default"
  }, [_c('legend', {
    staticClass: "w-auto px-2 h5 text-primary"
  }, [_vm._v("Body Mail")]), _c('div', [_c('wysiwyg', {
    style: {
      height: '500px'
    },
    model: {
      value: _vm.form.content,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1)])]) : _vm._e()], 1)], 1) : _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "alert alert-info m-4"
  }, [_vm._v(" Loading... ")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }