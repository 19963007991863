var render = function render(){
  var _vm$list, _vm$list$data;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("PLATFORM MODULE")])])];
      },
      proxy: true
    }])
  }, [[_c('b-row', {
    staticClass: "m-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex w-100 overflow-auto"
  }, [_c('table', {
    staticClass: "table table-striped table-sm"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("No")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "width": "150px"
    }
  }, [_vm._v("Alias")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("Name")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("Show ESS Mobile")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("Request")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("Approval")]), !_vm.edit ? _c('th', {
    staticClass: "text-center"
  }, [_vm._v("Approval Users")]) : _vm._e(), _c('th', {
    staticClass: "text-right"
  }, [_vm._v("#")])])]), _c('tbody', [_vm._l((_vm$list = _vm.list) === null || _vm$list === void 0 ? void 0 : _vm$list.data, function (state, index) {
    var _state$alias, _state$name;
    return _c('tr', {
      key: index
    }, [_c('th', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(index + 1))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s((_state$alias = state === null || state === void 0 ? void 0 : state.alias) !== null && _state$alias !== void 0 ? _state$alias : '-'))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s((_state$name = state === null || state === void 0 ? void 0 : state.name) !== null && _state$name !== void 0 ? _state$name : '-'))]), !_vm.edit ? _c('td', {
      class: "text-center ".concat(state !== null && state !== void 0 && state.showEssMobile ? 'text-success' : 'text-danger')
    }, [_vm._v(_vm._s(state !== null && state !== void 0 && state.showEssMobile ? 'Yes' : "No"))]) : _vm._e(), _vm.edit ? _c('td', {
      class: "text-center"
    }, [_c('b-form-checkbox', {
      attrs: {
        "name": "check-button",
        "switch": ""
      },
      model: {
        value: _vm.form.showEssMobile,
        callback: function callback($$v) {
          _vm.$set(_vm.form, "showEssMobile", $$v);
        },
        expression: "form.showEssMobile"
      }
    }, [_vm._v(" Active / Inactive ")])], 1) : _vm._e(), !_vm.edit ? _c('td', {
      class: "text-center ".concat(state !== null && state !== void 0 && state.request ? 'text-success' : 'text-danger')
    }, [_vm._v(_vm._s(state !== null && state !== void 0 && state.request ? 'Yes' : "No"))]) : _vm._e(), _vm.edit ? _c('td', {
      class: "text-center"
    }, [_c('b-form-checkbox', {
      attrs: {
        "name": "check-button",
        "switch": ""
      },
      model: {
        value: _vm.form.request,
        callback: function callback($$v) {
          _vm.$set(_vm.form, "request", $$v);
        },
        expression: "form.request"
      }
    }, [_vm._v("Active / Inactive")])], 1) : _vm._e(), !_vm.edit ? _c('td', {
      class: "text-center"
    }, [!(state !== null && state !== void 0 && state.approval) ? _c('b-button', {
      attrs: {
        "variant": "outline-primary",
        "type": "button"
      },
      on: {
        "click": function click($event) {
          return _vm.openModal(state, true);
        }
      }
    }, [_vm._v("No Approval")]) : _c('b-button', {
      attrs: {
        "variant": "outline-primary",
        "type": "button"
      },
      on: {
        "click": function click($event) {
          return _vm.openModal(state, true);
        }
      }
    }, [_vm._v("Approval")])], 1) : _vm._e(), !_vm.edit ? _c('td', {
      class: "text-center"
    }, [_c('div', {
      staticClass: "d-flex flex-wrap"
    }, _vm._l(state === null || state === void 0 ? void 0 : state.user, function (obj, objIndex) {
      return _c('b-badge', {
        key: objIndex,
        staticClass: "mr-2 mb-2",
        attrs: {
          "variant": "primary"
        }
      }, [_vm._v(_vm._s(obj === null || obj === void 0 ? void 0 : obj.name))]);
    }), 1)]) : _vm._e(), _vm.edit ? _c('td', {
      class: "text-center"
    }, [_c('b-form-checkbox', {
      attrs: {
        "name": "check-button",
        "switch": ""
      },
      model: {
        value: _vm.form.approval,
        callback: function callback($$v) {
          _vm.$set(_vm.form, "approval", $$v);
        },
        expression: "form.approval"
      }
    }, [_vm._v("Active / Inactive")])], 1) : _vm._e(), _c('td', {
      staticClass: "text-right"
    }, [_c('div', {
      staticClass: "d-flex flex-column align-items-end"
    }, [!_vm.edit ? _c('i', {
      staticClass: "fa fa-pen text-primary mb-2",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.onEdit(state);
        }
      }
    }) : _c('div', {
      staticClass: "d-flex flex-column align-items-center justify-content-end"
    }, [_c('i', {
      staticClass: "fa fa-check text-success mb-2",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.onUpdatePlatformModule(state);
        }
      }
    }), _c('i', {
      staticClass: "fa fa-ban text-danger",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          _vm.edit = !_vm.edit;
        }
      }
    })])])])]);
  }), ((_vm$list$data = _vm.list.data) === null || _vm$list$data === void 0 ? void 0 : _vm$list$data.length) <= 0 ? _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "9"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v("No data exists.")])])]) : _vm._e()], 2)])])])], 1)], _c('b-modal', {
    attrs: {
      "id": "approval-modal",
      "size": "md",
      "title": "Approval",
      "cancel-title": "Close",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "lg": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "List Users",
            "label-for": "list_users"
          }
        }, [_c('v-select', {
          attrs: {
            "multiple": "",
            "label": "name",
            "options": _vm.listUsers,
            "reduce": function reduce(user) {
              return user.id;
            },
            "placeholder": "Select Users..."
          },
          model: {
            value: _vm.form.approvalRules,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "approvalRules", $$v);
            },
            expression: "form.approvalRules"
          }
        })], 1), _c('div', {
          staticClass: "d-flex w-100 border my-2"
        }), _c('div', {
          staticClass: "d-flex w-100 align-items-center justify-content-end"
        }, [_c('b-button', {
          staticClass: "mr-2",
          attrs: {
            "variant": "danger",
            "type": "button"
          },
          on: {
            "click": function click($event) {
              return _vm.openModal(null, false);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-ban mr-2"
        }), _vm._v(" Cancel ")]), _c('b-button', {
          attrs: {
            "variant": "success",
            "type": "button"
          },
          on: {
            "click": _vm.onStatusApproval
          }
        }, [_c('i', {
          staticClass: "fa fa-check mr-2"
        }), _vm._v(" Approve ")])], 1)], 1)], 1)];
      }
    }])
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }