<template>
  <div>
    <b-col md="12" class="mb-3">
      <table class="table table-striped">
        <thead>
          <tr class="text-center">
            <th width="18%" rowspan="2" class="align-middle">URAIAN</th>
            <th
              width="15%"
              v-for="(point, index) in condite_points"
              :key="index"
              >NILAI Poin = {{ point.value }}</th
            >
            <th width="12%" rowspan="2" class="align-middle">POIN</th>
          </tr>
          <tr class="text-center">
            <th v-for="(point, index) in condite_points" :key="index">{{
              point.name
            }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            style="font-size: 9pt"
            v-for="(state, index) in merged_condite"
            :key="index"
          >
            <th> {{ state.uraian }} </th>
            <td v-for="(val, i) in state.description" :key="i">
              {{ val.description }}
            </td>

            <td class="text-center">
              <b-form-select
                v-if="!previewMode"
                plain
                v-model="state.value"
                :options="optionsPoints"
                size="sm"
                class="col-md-12"
                required
              >
                <template v-slot:first>
                  <b-form-select-option :value="null"
                    >Nilai</b-form-select-option
                  >
                </template>
              </b-form-select>
              <span class="text-muted" v-else>{{ state.value }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </b-col>
  </div>
</template>

<script>
import { xray } from '../../config/pluginInit'
import _ from 'lodash'
import { crewingActions } from '@src/Utils/helper'
import moment from 'moment'

export default {
  name: 'FormCondite',
  props: {
    previewMode: {
      type: Boolean,
      default: false,
    },
    dataCondites: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isShow: false,
      total_point: 0,
      average_point: 0,

      optionsPoints: [],

      condite_questions: [],
      condite_points: [],
      condite_mapping: [],

      merged_condite: [],
    }
  },
  async mounted() {
    xray.index()
    // this.fetchFleets()
    console.log(this.dataCondites, "THIS IS CONDITE DATA")
    await this.getConditeModel()
    if (this.previewMode) {
      this.mergedPreviewCondite()
    }
  },
  methods: {
    ...crewingActions,
    async getConditeModel() {
      let res = await this.fetchConditeModel()
      if (res.status) {
        let tempMap = []

        res.data.points.map((val) => {
          this.optionsPoints.push({
            value: val.value,
            text: val.value_letter,
          })
        })

        this.condite_questions = res.data.questions
        this.condite_points = res.data.points.sort((a, b) => a.value - b.value)
        this.condite_mapping = res.data.mapping

        // MERGING QUESTIONS,MAPPING, AND POINTS
        this.condite_questions.map((question) => {
          tempMap = []
          this.condite_mapping.map((map) => {
            this.condite_points.map((point) => {
              if (
                question.id_master_condite_report_list ===
                  map.question_condite_id &&
                map.point_id === point.id
              ) {
                if (map.point_id === point.id) {
                  tempMap.push({
                    description: map.point_description,
                    value: point.value,
                    point_id: map.point_id,
                    id_point: point.id,
                    condite_report_mapping_id: map.condite_report_mapping_id,
                  })
                }
                this.merged_condite.push({
                  uraian: question.uraian,
                  category: question.category,
                  id_master_condite_report_list:
                    question.id_master_condite_report_list,
                  question_condite_id: map.question_condite_id,
                  description: tempMap,
                  id_condite_report_mapping_content:
                    map.id_condite_report_mapping_content,
                  value: null,
                })
              }
            })
          })
        })

        // REMOVE THE SAME VALUE OF THE URAIAN
        let mapObj = new Map()
        this.merged_condite.forEach((v) => {
          let prevValue = mapObj.get(v.uraian)
          if (!prevValue) {
            mapObj.set(v.uraian, v)
          }
        })
        this.merged_condite = [...mapObj.values()]
      }
    },

    mergedPreviewCondite() {
      this.merged_condite.map((item) => {
        this.dataCondites.map((val) => {
          console.log(val.point_value)
          console.log(item.value)
          if (item.question_condite_id === val.id_condite_content) {
            item.value = val.point_value
          }
        })
      })
      console.log(this.merged_condite, 'CONDITE MERGED')
    },
    showScore() {
      this.total_point = this.merged_condite.reduce((o, n) => o + n.value, 0)
      this.average_point =
        this.merged_condite.reduce((o, n) => o + n.value, 0) /
        this.merged_condite.length

      this.isShow = true
    },
  },
}
</script>
