<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items class="col-md-3 p-0" :active="true" href="#schedule" title="SCHEDULE" />
                <tab-nav-items class="col-md-3 p-0" :active="false" href="#progress" title="PROGRESS" />
                <tab-nav-items class="col-md-3 p-0" :active="false" href="#finish" title="FINISH" />
                <tab-nav-items class="col-md-3 p-0" :active="false" href="#report" title="REPORT" />
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="12">
        <div class="iq-edit-list-data">
          <div id="pills-tabContent-2" class="tab-content">
            <!-- SCHEDULE -->
            <tab-content-item :active="true" id="schedule" >
              <iq-card>
                <template v-slot:headerTitle>
                    <h5 class="card-title text-primary"><strong>DOCKING SCHEDULE</strong></h5>
                </template>
                <template v-slot:headerAction>
                  <b-button variant="primary" v-b-modal.add-init @click="showInitModal = !showInitModal" class="mr-1"><i class="fa fa-edit"></i> &nbsp; CREATE EMERGENCY DOCKING</b-button>
                  <a href="#" v-b-modal.set_schedule class="btn btn-primary"><i class="fa fa-calendar mr-2"></i>SET SCHEDULE DOCKING</a>
                  <!-- MODAL ALL FLEET -->
                  <b-modal v-model="showInitModal" id="add-init" size="xl" title="Select Fleet" header-bg-variant="light" header-text-variant="dark"
                  footer-bg-variant="light" footer-text-variant="dark">
                    <b-row class="mb-4">
                      <b-col md="4" class="text-right">
                        <b-form-input
                          type="text"
                          placeholder="Search fleets..."
                          v-model="fleet_params.search"
                          @input="fetchFleets"
                        ></b-form-input>
                      </b-col>
                    </b-row>
                    <div v-if="loading_fleet" class="text-center my-5">
                      <b-spinner type="grow" label="Spinning"></b-spinner>
                    </div>
                    <template v-else>
                      <b-row v-if="fleets.length > 0">
                        <b-col
                          md="3"
                          class="text-center py-5 border-top"
                          v-for="(fleet, i) in fleets"
                          :key="`fleet-${i}`"
                        >
                          <div class="custom-control custom-checkbox image-checkbox">
                            <input
                              :id="`ck-fl-${i}`"
                              type="checkbox"
                              class="custom-control-input"
                              :value="fleet.id"
                              v-model="checkedFleets"
                            />
                            <label class="custom-control-label" :for="`ck-fl-${i}`">
                              <img
                                :src="
                              fleet.vehicleType.icon.url
                                | checkPhoto(
                                  require('@src/assets/images/fleet/PusherBarge.png')
                                )
                            "
                                alt="#"
                                class="img-fluid"
                              />
                            </label>
                            <small
                            ><strong>{{ fleet.name }}</strong></small
                            >
                          </div>
                        </b-col>
                      </b-row>
                      <div v-else class="text-center my-5">
                        <p class="mb-0 text-muted">No fleets data found.</p>
                      </div>
                    </template>
                    <template #modal-footer="{ ok, cancel, hide }">
                      <b-row>
                        <b-col md="12" class="text-center">
                          <b-button variant="none" class="iq-bg-danger text-right" @click="cancel()">Cancel</b-button>
                          <b-button type="submit" variant="primary" @click="modalCreateDocking = !modalCreateDocking;showInitModal = !showInitModal" class="ml-2 text-right">Next <i class="fa fa-arrow-right"></i> </b-button>
                        </b-col>
                      </b-row>
                    </template>
                  </b-modal>
                  <!-- MODAL ALL FLEET END -->

                  <!-- SETUP SCHEDULE FROM CALENDAR -->
                  <b-modal id="set_schedule" size="lg" title="SET SCHEDULE DOCKING" ok-title="Save" cancel-title="Close">
                    <b-row>
                      <b-col md="6">
                        <b-form-group label="Fleet*" label-for="fleet">
                          <b-form-select plain v-model="selectedFleets" :options="kapal" size="lg" required>
                              <template v-slot:first>
                              <b-form-select-option :value="null">Select Fleet</b-form-select-option>
                              </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group label="Last Docking*" label-for="last_docking">
                          <b-form-input type="date" id="end" required></b-form-input>
                          <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group label="Fleet*" label-for="fleet">
                          <b-form-select plain size="lg" required>
                              <template v-slot:first>
                              <b-form-select-option>INTERMEMDIATE SURVEY</b-form-select-option>
                              <b-form-select-option>SPECIAL SURVEY</b-form-select-option>
                              </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group label="Interval*" label-for="interval">
                          <div class="input-group">
                            <b-form-input type="number" required></b-form-input>
                            <div class="input-group-prepend">
                              <span class="input-group-text">Month</span>
                            </div>
                          </div>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group label="Start Schedule*" label-for="start_schedule">
                          <div class="input-group">
                            <b-form-input type="number"></b-form-input>
                            <div class="input-group-prepend">
                              <span class="input-group-text">Month</span>
                            </div>
                          </div>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group label="End Schedule" label-for="end_schedule">
                          <div class="input-group">
                            <b-form-input type="number"></b-form-input>
                            <div class="input-group-prepend">
                              <span class="input-group-text">Month</span>
                            </div>
                          </div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-modal>
                  <!-- SETUP SCHEDULE FROM CALENDAR END -->
                </template>
                <template v-slot:body>
                  <b-collapse id="docking_schedule_card" class="mb-2">
                    <!-- FILTER DOCKING SCHEDULE-->
                    <b-row class="mb-3">
                      <b-col md="6">
                        <v-multiselect v-model="fleetCategorySelect" tag-placeholder="Category" placeholder="Fleet Category" label="text" track-by="value" :options="fleetCategory" :multiple="true" :taggable="true">
                          </v-multiselect>
                      </b-col>
                      <b-col md="2">
                        <b-form-select plain size="sm">
                          <b-form-select-option>Select Fleet</b-form-select-option>
                          <b-form-select-option>TB. TAVIA 1</b-form-select-option>
                          <b-form-select-option>TB. TAVIA 2</b-form-select-option>
                          <b-form-select-option>TB. TAVIA 3</b-form-select-option>
                          <b-form-select-option>TB. TAVIA 4</b-form-select-option>
                          <b-form-select-option>BG. TAVIA 5</b-form-select-option>
                          <b-form-select-option>BG. TAVIA 6</b-form-select-option>
                        </b-form-select>
                      </b-col>
                      <b-col md="2">
                        <b-form-select plain v-model="selectedDockingType" :options="docking_type" size="sm">
                          <template v-slot:first>
                          <b-form-select-option :value="null">Select Docking Type</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-col>
                      <b-col md="2">
                        <b-form-select plain v-model="selectedDockingStatus" :options="dockingStatus" size="sm">
                          <template v-slot:first>
                          <b-form-select-option :value="null">Select Status</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-col>
                      <b-col md="3" class="mt-3">
                        <date-picker :lang="lang" v-model="dateRange" type="date" range placeholder="Select date range" size="sm"></date-picker>
                      </b-col>
                      <b-col md="9" class="mt-3">
                        <b-button variant="primary" block size="sm"><i class="fa fa-filter mr-2"></i> Filter</b-button>
                      </b-col>
                    </b-row>
                    <!-- FILTER DOCKING SCHEDULE END -->
                  </b-collapse>

                  <!-- DOCKING SCHEDULE -->
                  <table class="table table-striped table-sm">
                    <thead>
                      <tr>
                        <th class="text-center">NO</th>
                        <th class="text-center">FLEET</th>
                        <th class="text-center">DOCKING TYPE</th>
                        <th class="text-center">REMAINING DAYS</th>
                        <th class="text-center">SCHEDULE</th>
                        <th class="text-center">STATUS</th>
                        <th class="text-center">ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th class="text-center">1</th>
                        <td class="text-center">TB. TAVIA 1</td>
                        <td class="text-center">INTERMEDIATE SURVEY</td>
                        <td class="text-center">-</td>
                        <td class="text-center">2020-03-15</td>
                        <td class="text-center"><b-badge variant="success d-block">ACTIVE</b-badge></td>
                        <td class="text-center">
                          <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                            <template v-slot:button-content>
                              <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                <i class="fa fa-ship m-0 text-primary"></i>
                              </span>
                            </template>
                              <b-dropdown-item variant="dark"><router-link to="/templates/docking-history"><i class="fa fa-history mr-2"></i>HISTORY DOCKING</router-link></b-dropdown-item>
                              <b-dropdown-item href="#" variant="danger"><i class="fa fa-trash mr-2"></i>DELETE</b-dropdown-item>
                          </b-dropdown>
                        </td>
                      </tr>
                      <tr>
                        <th class="text-center">2</th>
                        <td class="text-center">TB. TAVIA 2</td>
                        <td class="text-center">INTERMEDIATE SURVEY</td>
                        <td class="text-center">180 Days</td>
                        <td class="text-center">2022-07-17</td>
                        <td class="text-center"><b-badge variant="warning d-block">DUE DATE</b-badge></td>
                        <td class="text-center">
                          <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                            <template v-slot:button-content>
                              <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                <i class="fa fa-ship m-0 text-primary"></i>
                              </span>
                            </template>
                            <b-dropdown-item href="#" @click="modalCreateDocking = !modalCreateDocking;" variant="primary"><i class="fa fa-pencil-square-o mr-2"></i>DOCKING</b-dropdown-item>
                            <b-dropdown-item v-b-modal.pending_docking  variant="danger"><i class="fa fa-clock mr-2"></i>PENDING</b-dropdown-item>
                            <b-dropdown-item variant="dark"><router-link to="/templates/docking-history"><i class="fa fa-history mr-2"></i>HISTORY DOCKING</router-link></b-dropdown-item>
                            <b-dropdown-item href="#" variant="danger"><i class="fa fa-trash mr-2"></i>DELETE</b-dropdown-item>
                          </b-dropdown>
                        </td>
                      </tr>
                      <tr>
                        <th class="text-center">3</th>
                        <td class="text-center">TB. TAVIA 3</td>
                        <td class="text-center">INTERMEDIATE SURVEY</td>
                        <td class="text-center">31 Days</td>
                        <td class="text-center">2023-09-25</td>
                        <td class="text-center"><b-badge variant="primary d-block">FIRST PENDING</b-badge></td>
                        <td class="text-center">
                          <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                            <template v-slot:button-content>
                              <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                <i class="fa fa-ship m-0 text-primary"></i>
                              </span>
                            </template>
                            <b-dropdown-item href="#" @click="modalCreateDocking = !modalCreateDocking;" variant="primary"><i class="fa fa-pencil-square-o mr-2"></i>DOCKING</b-dropdown-item>
                            <b-dropdown-item v-b-modal.pending_docking  variant="danger"><i class="fa fa-clock mr-2"></i>PENDING</b-dropdown-item>
                            <b-dropdown-item variant="dark"><router-link to="/templates/docking-history"><i class="fa fa-history mr-2"></i>HISTORY DOCKING</router-link></b-dropdown-item>
                            <b-dropdown-item href="#" variant="danger"><i class="fa fa-trash mr-2"></i>DELETE</b-dropdown-item>
                          </b-dropdown>
                        </td>
                      </tr>
                      <tr class="bg-danger">
                        <th class="text-center">4</th>
                        <td class="text-center">TB. TAVIA 4</td>
                        <td class="text-center">SPECIAL SURVEY</td>
                        <td class="text-center">- 15 Days</td>
                        <td class="text-center">2023-03-17</td>
                        <td class="text-center"><b-badge variant="light d-block">OVER SCHEDULE</b-badge></td>
                        <td class="text-center">
                          <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                            <template v-slot:button-content>
                              <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                <i class="fa fa-ship m-0 text-primary"></i>
                              </span>
                            </template>
                            <b-dropdown-item href="#" @click="modalCreateDocking = !modalCreateDocking;" variant="primary"><i class="fa fa-pencil-square-o mr-2"></i>DOCKING</b-dropdown-item>
                            <b-dropdown-item v-b-modal.pending_docking variant="danger"><i class="fa fa-clock mr-2"></i>PENDING</b-dropdown-item>
                            <b-dropdown-item variant="dark"><router-link to="/templates/docking-history"><i class="fa fa-history mr-2"></i>HISTORY DOCKING</router-link></b-dropdown-item>
                            <b-dropdown-item href="#" variant="danger"><i class="fa fa-trash mr-2"></i>DELETE</b-dropdown-item>
                          </b-dropdown>
                        </td>
                      </tr>
                      <tr class="bg-info">
                        <th class="text-center">5</th>
                        <td class="text-center">TB. TAVIA 5</td>
                        <td class="text-center">INTERMEDIATE SURVEY</td>
                        <td class="text-center">31 DAYS</td>
                        <td class="text-center">2020-01-15</td>
                        <td class="text-center" colspan="2"> <strong>PROGRESS - CHECK TAB PROGRESS</strong> </td>
                      </tr>
                      <tr class="bg-warning">
                        <th class="text-center">6</th>
                        <td class="text-center">TB. TAVIA 6</td>
                        <td class="text-center">INTERMEDIATE SURVEY</td>
                        <td class="text-center">-</td>
                        <td class="text-center">2020-01-15</td>
                        <td class="text-center" colspan="2"> <strong>REVIEW - CHECK TAB PROGRESS</strong> </td>
                      </tr>
                      <tr class="bg-primary">
                        <th class="text-center">7</th>
                        <td class="text-center">TB. TAVIA 7</td>
                        <td class="text-center">INTERMEDIATE SURVEY</td>
                        <td class="text-center">-</td>
                        <td class="text-center">2020-01-15</td>
                        <td class="text-center" colspan="2"> <strong>APPROVE - CHECK TAB PROGRESS</strong> </td>
                      </tr>
                      <tr>
                        <th class="text-center">8</th>
                        <td class="text-center">TB. TAVIA 8</td>
                        <td class="text-center">INTERMEDIATE SURVEY</td>
                        <td class="text-center">180 Days</td>
                        <td class="text-center">2022-07-17</td>
                        <td class="text-center"><b-badge variant="danger d-block">APPROVE DELETE</b-badge></td>
                        <td class="text-center">
                          <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                            <template v-slot:button-content>
                              <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                <i class="fa fa-ship m-0 text-primary"></i>
                              </span>
                            </template>
                            <b-dropdown-item href="#" @click="modalCreateDocking = !modalCreateDocking;" variant="primary"><i class="fa fa-pencil-square-o mr-2"></i>DOCKING</b-dropdown-item>
                            <b-dropdown-item v-b-modal.pending_docking  variant="danger"><i class="fa fa-clock mr-2"></i>PENDING</b-dropdown-item>
                            <b-dropdown-item variant="dark"><router-link to="/templates/docking-history"><i class="fa fa-history mr-2"></i>HISTORY DOCKING</router-link></b-dropdown-item>
                            <b-dropdown-item href="#" variant="primary"><i class="fa fa-trash mr-2"></i>DELETE APPROVED</b-dropdown-item>
                          </b-dropdown>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="mt-3">
                    <b-pagination :value="1" :total-rows="50" align="center" />
                  </div>
                  <!-- DOCKING SCHEDULE END -->
                  

                  <!-- MODAL POSTPONED -->
                  <b-modal id="pending_docking" size="md" title="Pending Docking - TB. TAVIA 1" ok-title="Save" cancel-title="Close">
                    <b-row>
                      <b-col md="12">
                          <b-form-group label="Pending Date *" label-for="pending_date">
                            <b-form-input type="date" id="docking_date"></b-form-input>
                            <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                          </b-form-group>
                          <b-form-group label="Reason Pending" label-for="reason_pending">
                            <b-form-textarea id="reason_pending" rows="3"></b-form-textarea>
                          </b-form-group>
                      </b-col>
                    </b-row>
                  </b-modal>
                  <!-- MODAL POSTPONED END -->

                  <!-- SLIDE - FORM EMERGENCY DOCKING -->
                  <b-sidebar
                    v-model="modalCreateDocking"
                    id="emergency_docking"
                    title="CREATE DOCKING - TB. TAVIA 1"
                    width="100%"
                    backdrop-variant="dark"
                    bg-variant="white"
                    right
                    backdrop
                    shadow>
                    <template #default="{hide}">
                      <b-row class="m-3">
                        <b-col cols="4">
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Job List</legend>
                            <b-form-input id="job_name" type="text" placeholder="Search Job Name"></b-form-input>
                            <v-jstree :data="tree" show-checkbox allow-batch whole-row :item-events="itemEvents" @item-click="itemClick" class="mt-3">
                              <template slot-scope="_">
                                <div style="display: inherit; width: 200px" @click.ctrl="customItemClickWithCtrl">
                                  <i :class="_.vm.themeIconClasses" role="presentation" v-if="!_.model.loading"></i>
                                  {{_.model.text}}
                                </div>
                              </template>
                            </v-jstree>
                            <b-button block variant="success"><i class="fa fa-plus"></i>ADD JOB</b-button>
                          </fieldset>
                        </b-col>
                        <b-col cols="8">
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Docking Info</legend>
                            <b-row>
                              <b-col md="4">
                                <b-form-group label="Nama Shipyard*" label-for="shipyard">
                                  <b-form-select plain v-model="selectedShipyard" :options="shipyard" size="lg" style="border: solid 1px gray">
                                    <template v-slot:first>
                                    <b-form-select-option :value="null">Pilih Shipyard</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                </b-form-group>
                              </b-col>
                              <b-col md="4">
                                <b-form-group label="PIC*" label-for="pic">
                                  <b-form-select plain size="lg" style="border: solid 1px gray">
                                    <template v-slot:first>
                                    <b-form-select-option>Pilih PIC</b-form-select-option>
                                    <b-form-select-option>Nurul Akbar</b-form-select-option>
                                    <b-form-select-option>Agung Tri Laksono</b-form-select-option>
                                    <b-form-select-option>Dendy Haidar</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                </b-form-group>
                              </b-col>
                              <b-col md="4">
                                <b-form-group label="Jenis Pekerjaan*" label-for="jenis_pekerjaan">
                                  <b-form-select plain size="lg" style="border: solid 1px gray">
                                    <template v-slot:first>
                                    <b-form-select-option>Pilih Jenis Pekerjaan</b-form-select-option>
                                    <b-form-select-option>General Overhaul</b-form-select-option>
                                    <b-form-select-option>Top Overhaul</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                </b-form-group>
                              </b-col>
                              <b-col md="4">
                                <b-form-group label="TA. Shipyard*" label-for="arrival_shipyard">
                                  <b-form-input type="date" id="arrival_shipyard" style="border: solid 1px gray"></b-form-input>
                                  <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                </b-form-group>
                              </b-col>
                              <b-col md="4">
                                <b-form-group label="Floating Repair" label-for="floating_repair">
                                  <b-form-input type="date" id="floating_repair" style="border: solid 1px gray"></b-form-input>
                                  <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                </b-form-group>
                              </b-col>
                              <b-col md="4">
                                <b-form-group label="Up Slip *" label-for="up_slip*">
                                  <b-form-input type="date" id="up_slip" style="border: solid 1px gray"></b-form-input>
                                  <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                </b-form-group>
                              </b-col>
                              <b-col md="4">
                                <b-form-group label="Downslip" label-for="downslip">
                                  <b-form-input type="date" id="downslip" style="border: solid 1px gray"></b-form-input>
                                  <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                </b-form-group>
                              </b-col>
                              <b-col md="4">
                                <b-form-group label="Sea Trial" label-for="sea_trail">
                                  <b-form-input type="date" id="sea_trail" style="border: solid 1px gray"></b-form-input>
                                  <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                </b-form-group>
                              </b-col>
                              <b-col md="4">
                                <b-form-group label="Complete / Delivery" label-for="delivery_date">
                                  <b-form-input type="date" id="delivery_date" style="border: solid 1px gray"></b-form-input>
                                  <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                </b-form-group>
                              </b-col>
                              <b-col md="2">
                                <b-form-group label="Waiting Space" label-for="waiting_space">
                                  <b-form-input type="number" readonly id="waiting_space" style="border: solid 1px gray" placeholder="9"></b-form-input>
                                </b-form-group>
                              </b-col>
                              <b-col md="2">
                                <b-form-group label="Target Days" label-for="target_days">
                                  <b-form-input type="number" readonly id="target_days" style="border: solid 1px gray" placeholder="60"></b-form-input>
                                </b-form-group>
                              </b-col>
                              <b-col md="4">
                                <b-form-group label="Estimasi Tonase" label-for="est_tonase">
                                  <div class="input-group">
                                    <input type="number" class="form-control" id="est_tonase" style="border: solid 1px gray">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text bg-primary" id="inputGroupPrepend2">Ton</span>
                                    </div>
                                  </div>
                                </b-form-group>
                              </b-col>
                              <b-col md="4">
                                <b-form-group label="Estimasi Budget" label-for="est_budget">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                    </div>
                                    <input type="number" class="form-control" id="est_budget" style="border: solid 1px gray">
                                  </div>
                                </b-form-group>
                              </b-col>
                              <b-col md="4">
                                <b-form-group label="Dokumen Shipyard" label-for="doc_shipyard">
                                  <div class="custom-file">
                                    <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                    <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                  </div>
                                  <small class="text-muted">
                                    File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                    File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                  </small>
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </fieldset>
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Docking Job</legend>
                            <b-alert :show="true" variant="primary">
                              <div class="iq-alert-icon">
                                <i class="fa fa-percent"></i>
                              </div>
                              <div class="iq-alert-text">Docking Job Category <b>100%</b></div>
                            </b-alert>

                            <a href="#" v-b-toggle.jobCat-1>
                              <b-alert :show="true" class="text-white bg-primary">
                                <div class="iq-alert-icon">
                                  <i class="fas fa-tasks"></i>
                                </div>
                                <div class="iq-alert-text">Docking / Undocking <b>(2 Jobs) - 30%</b></div>
                                <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                  <i class="far fa-hand-pointer"></i> Click Here
                                </a>
                              </b-alert>
                            </a>
                            <b-collapse id="jobCat-1" accordion="my-accordion" role="tabpanel">
                              <b-card-body>
                                <b-form-group label="Persentase Job Category*" label-for="percent_job_category">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text bg-primary" id="percent_job_category">%</span>
                                    </div>
                                    <input type="text" class="form-control" id="percent_job_category">
                                  </div>
                                </b-form-group>
                                <b-alert :show="true" variant="danger">
                                  <div class="iq-alert-icon">
                                    <i class="fa fa-percent"></i>
                                  </div>
                                  <div class="iq-alert-text">Docking Job <b>100%</b></div>
                                </b-alert>
                                <div class="accordion" role="tablist">
                                  <b-card no-body >
                                    <a href="#" v-b-toggle.job-1-1>
                                      <b-alert :show="true" class="text-white bg-danger">
                                        <div class="iq-alert-icon">
                                          <i class="fas fa-clipboard-list"></i>
                                        </div>
                                        <div class="iq-alert-text"><b>Upslip</b> - Persentase Pekerjaan <b>50% (New)</b></div>
                                        <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                          <i class="far fa-hand-pointer"></i> Click Here
                                        </a>
                                      </b-alert>
                                    </a>
                                    <b-collapse id="job-1-1" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Upslip</legend>
                                        <b-row class="mt-3">
                                          <b-col md="3">
                                            <b-form-group label="Est. Start*" label-for="est_start">
                                              <b-form-input type="date" id="est_start"></b-form-input>
                                              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Est. Finish *" label-for="end">
                                              <b-form-input type="date" id="end"></b-form-input>
                                              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group>
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                </div>
                                                <input type="text" class="form-control" id="bobot_pekerjaan">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Attachment Job" label-for="attachment_job">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File* : <b-badge variant="info">.jpg/.png/.xlx/.doc/.pdf</b-badge><br>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="12">
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="3" placeholder="Check water tubes/plates and clean/free from clogged."></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                      </fieldset>
                                      <b-row>
                                        <b-col md="12">
                                          <b-form-group label="Internal Man Power" label-for="internal_man_power">
                                            <template v-for="(item,index) in text">
                                              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                <div class="custom-switch-inner">
                                                  <p class="mb-0"> {{ item.label }} </p>
                                                  <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                  <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                  </label>
                                                </div>
                                              </div>
                                            </template>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Internal Man Power</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                              <b-form-input type="text" id="nama_internal_man_power" placeholder="Nurul Akbar"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Jabatan *" label-for="jabatan">
                                              <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                <template v-slot:first>
                                                <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                </template>
                                              </b-form-select>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                              <b-form-input type="text" id="nama_internal_man_power" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Jabatan *" label-for="jabatan">
                                              <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                <template v-slot:first>
                                                <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                </template>
                                              </b-form-select>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-button variant="success" class="mt-3"><i class="fa fa-users"></i> ADD INTERNAL MAN POWER</b-button>
                                      </fieldset>
                                      <b-row>
                                        <b-col md="12">
                                          <b-form-group label="Sub Kontraktor" label-for="subkontraktor">
                                            <template v-for="(item,index) in text">
                                              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                <div class="custom-switch-inner">
                                                  <p class="mb-0"> {{ item.label }} </p>
                                                  <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                  <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                  </label>
                                                </div>
                                              </div>
                                            </template>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                              <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                              <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Penawaran" label-for="penawaran">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Attachment Shipyard" label-for="doc_shipyard">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #2</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                              <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                              <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Penawaran" label-for="penawaran">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Attachment Shipyard" label-for="doc_shipyard">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                      </fieldset>
                                      <b-button variant="success" class="mb-3"><i class="fa fa-users"></i> ADD SUB KONTRAKTOR</b-button>
                                    </b-collapse>
                                  </b-card>
                                  <b-card no-body >
                                    <a href="#" v-b-toggle.job-1-2>
                                      <b-alert :show="true" class="text-white bg-danger">
                                        <div class="iq-alert-icon">
                                          <i class="fas fa-clipboard-list"></i>
                                        </div>
                                        <div class="iq-alert-text"><b>Downslip</b> - Persentase Pekerjaan <b>50% (New)</b></div>
                                        <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                          <i class="far fa-hand-pointer"></i> Click Here
                                        </a>
                                      </b-alert>
                                    </a>
                                    <b-collapse id="job-1-2" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Downslip</legend>
                                        <b-row class="mt-3">
                                          <b-col md="3">
                                            <b-form-group label="Est. Start*" label-for="est_start">
                                              <b-form-input type="date" id="est_start"></b-form-input>
                                              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Est. Finish *" label-for="end">
                                              <b-form-input type="date" id="end"></b-form-input>
                                              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Bobot Pekerjaan *" label-for="bobot_pekerjaan">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                </div>
                                                <input type="text" class="form-control" id="bobot_pekerjaan">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Attachment Job" label-for="attachment_job">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File* : <b-badge variant="info">.jpg/.png/.xlx/.doc/.pdf</b-badge><br>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="12">
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="3" placeholder="Check water tubes/plates and clean/free from clogged."></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                      </fieldset>
                                      <b-row>
                                        <b-col md="12">
                                          <b-form-group label="Internal Man Power" label-for="internal_man_power">
                                            <template v-for="(item,index) in text">
                                              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                <div class="custom-switch-inner">
                                                  <p class="mb-0"> {{ item.label }} </p>
                                                  <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                  <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                  </label>
                                                </div>
                                              </div>
                                            </template>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Internal Man Power</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                              <b-form-input type="text" id="nama_internal_man_power" placeholder="Nurul Akbar"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Jabatan *" label-for="jabatan">
                                              <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                <template v-slot:first>
                                                <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                </template>
                                              </b-form-select>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                              <b-form-input type="text" id="nama_internal_man_power" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Jabatan *" label-for="jabatan">
                                              <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                <template v-slot:first>
                                                <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                </template>
                                              </b-form-select>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-button variant="success" class="mt-3"><i class="fa fa-users"></i> ADD INTERNAL MAN POWER</b-button>
                                      </fieldset>
                                      <b-row>
                                        <b-col md="12">
                                          <b-form-group label="Sub Kontraktor" label-for="subkontraktor">
                                            <template v-for="(item,index) in text">
                                              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                <div class="custom-switch-inner">
                                                  <p class="mb-0"> {{ item.label }} </p>
                                                  <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                  <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                  </label>
                                                </div>
                                              </div>
                                            </template>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                              <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                              <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Penawaran" label-for="penawaran">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Attachment Shipyard" label-for="doc_shipyard">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #2</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                              <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                              <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Penawaran" label-for="penawaran">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Attachment Shipyard" label-for="doc_shipyard">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                      </fieldset>
                                      <b-button variant="success" class="mb-3"><i class="fa fa-users"></i> ADD SUB KONTRAKTOR</b-button>
                                    </b-collapse>
                                  </b-card>
                                </div>
                              </b-card-body>
                            </b-collapse>

                            <a href="#" v-b-toggle.jobCat-2>
                              <b-alert :show="true" class="text-white bg-primary">
                                <div class="iq-alert-icon">
                                  <i class="fas fa-tasks"></i>
                                </div>
                                <div class="iq-alert-text">Sand Blasting <b>(4 Jobs) - 70%</b></div>
                                <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                  <i class="far fa-hand-pointer"></i> Click Here
                                </a>
                              </b-alert>
                            </a>
                            <b-collapse id="jobCat-2" accordion="my-accordion" role="tabpanel">
                              <b-card-body>
                                <b-form-group label="Persentase Job Category*" label-for="percent_job_category">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text bg-primary" id="percent_job_category">%</span>
                                    </div>
                                    <input type="text" class="form-control" id="percent_job_category">
                                  </div>
                                </b-form-group>
                                <b-alert :show="true" variant="danger">
                                  <div class="iq-alert-icon">
                                    <i class="fa fa-percent"></i>
                                  </div>
                                  <div class="iq-alert-text">Docking Job <b>100%</b></div>
                                </b-alert>
                                <div class="accordion" role="tablist">
                                  <b-card no-body >
                                    <a href="#" v-b-toggle.job-2-1>
                                      <b-alert :show="true" class="text-white bg-danger">
                                        <div class="iq-alert-icon">
                                          <i class="fas fa-clipboard-list"></i>
                                        </div>
                                        <div class="iq-alert-text"><b>Scraping Teripting di Lambung & Lunas</b> - Persentase Pekerjaan <b>25% (New)</b></div>
                                        <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                          <i class="far fa-hand-pointer"></i> Click Here
                                        </a>
                                      </b-alert>
                                    </a>
                                    <b-collapse id="job-2-1" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Scraping Teripting di Lambung & Lunas</legend>
                                        <b-row class="mt-3">
                                          <b-col md="3">
                                            <b-form-group label="Est. Start*" label-for="est_start">
                                              <b-form-input type="date" id="est_start"></b-form-input>
                                              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Est. Finish *" label-for="end">
                                              <b-form-input type="date" id="end"></b-form-input>
                                              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Bobot Pekerjaan *" label-for="bobot_pekerjaan">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                </div>
                                                <input type="text" class="form-control" id="bobot_pekerjaan">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Attachment Job" label-for="attachment_job">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File* : <b-badge variant="info">.jpg/.png/.xlx/.doc/.pdf</b-badge><br>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="12">
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="3" placeholder="Pencucian Lambung dengan Air Tawar"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                      </fieldset>
                                      <b-row>
                                        <b-col md="12">
                                          <b-form-group label="Internal Man Power" label-for="internal_man_power">
                                            <template v-for="(item,index) in text">
                                              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                <div class="custom-switch-inner">
                                                  <p class="mb-0"> {{ item.label }} </p>
                                                  <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                  <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                  </label>
                                                </div>
                                              </div>
                                            </template>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Internal Man Power</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                              <b-form-input type="text" id="nama_internal_man_power" placeholder="Nurul Akbar"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Jabatan *" label-for="jabatan">
                                              <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                <template v-slot:first>
                                                <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                </template>
                                              </b-form-select>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                              <b-form-input type="text" id="nama_internal_man_power" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Jabatan *" label-for="jabatan">
                                              <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                <template v-slot:first>
                                                <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                </template>
                                              </b-form-select>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-button variant="success" class="mt-3"><i class="fa fa-users"></i> ADD INTERNAL MAN POWER</b-button>
                                      </fieldset>
                                      <b-row>
                                        <b-col md="12">
                                          <b-form-group label="Sub Kontraktor" label-for="subkontraktor">
                                            <template v-for="(item,index) in text">
                                              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                <div class="custom-switch-inner">
                                                  <p class="mb-0"> {{ item.label }} </p>
                                                  <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                  <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                  </label>
                                                </div>
                                              </div>
                                            </template>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                              <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                              <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Penawaran" label-for="penawaran">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Attachment Shipyard" label-for="doc_shipyard">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #2</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                              <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                              <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Penawaran" label-for="penawaran">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Attachment Shipyard" label-for="doc_shipyard">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                      </fieldset>
                                      <b-button variant="success" class="mb-3"><i class="fa fa-users"></i> ADD SUB KONTRAKTOR</b-button>
                                    </b-collapse>
                                  </b-card>
                                  <b-card no-body >
                                    <a href="#" v-b-toggle.job-2-2>
                                      <b-alert :show="true" class="text-white bg-danger">
                                        <div class="iq-alert-icon">
                                          <i class="fas fa-clipboard-list"></i>
                                        </div>
                                        <div class="iq-alert-text"><b>Pencucian Lambung dengan Air Tawar</b> - Persentase Pekerjaan <b>25% (New)</b></div>
                                        <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                          <i class="far fa-hand-pointer"></i> Click Here
                                        </a>
                                      </b-alert>
                                    </a>
                                    <b-collapse id="job-2-2" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Pencucian Lambung dengan Air Tawar</legend>
                                        <b-row class="mt-3">
                                          <b-col md="3">
                                            <b-form-group label="Est. Start*" label-for="est_start">
                                              <b-form-input type="date" id="est_start"></b-form-input>
                                              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Est. Finish *" label-for="end">
                                              <b-form-input type="date" id="end"></b-form-input>
                                              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Bobot Pekerjaan *" label-for="bobot_pekerjaan">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                </div>
                                                <input type="text" class="form-control" id="bobot_pekerjaan">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Attachment Job" label-for="attachment_job">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File* : <b-badge variant="info">.jpg/.png/.xlx/.doc/.pdf</b-badge><br>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="12">
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="3" placeholder="Pencucian Lambung dengan Air Tawar"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                      </fieldset>
                                      <b-row>
                                        <b-col md="12">
                                          <b-form-group label="Internal Man Power" label-for="internal_man_power">
                                            <template v-for="(item,index) in text">
                                              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                <div class="custom-switch-inner">
                                                  <p class="mb-0"> {{ item.label }} </p>
                                                  <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                  <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                  </label>
                                                </div>
                                              </div>
                                            </template>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Internal Man Power</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                              <b-form-input type="text" id="nama_internal_man_power" placeholder="Nurul Akbar"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Jabatan *" label-for="jabatan">
                                              <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                <template v-slot:first>
                                                <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                </template>
                                              </b-form-select>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                              <b-form-input type="text" id="nama_internal_man_power" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Jabatan *" label-for="jabatan">
                                              <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                <template v-slot:first>
                                                <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                </template>
                                              </b-form-select>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-button variant="success" class="mt-3"><i class="fa fa-users"></i> ADD INTERNAL MAN POWER</b-button>
                                      </fieldset>
                                      <b-row>
                                        <b-col md="12">
                                          <b-form-group label="Sub Kontraktor" label-for="subkontraktor">
                                            <template v-for="(item,index) in text">
                                              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                <div class="custom-switch-inner">
                                                  <p class="mb-0"> {{ item.label }} </p>
                                                  <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                  <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                  </label>
                                                </div>
                                              </div>
                                            </template>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                              <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                              <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Penawaran" label-for="penawaran">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Attachment Shipyard" label-for="doc_shipyard">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #2</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                              <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                              <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Penawaran" label-for="penawaran">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Attachment Shipyard" label-for="doc_shipyard">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                      </fieldset>
                                      <b-button variant="success" class="mb-3"><i class="fa fa-users"></i> ADD SUB KONTRAKTOR</b-button>
                                    </b-collapse>
                                  </b-card>
                                  <b-card no-body >
                                    <a href="#" v-b-toggle.job-2-3>
                                      <b-alert :show="true" class="text-white bg-danger">
                                        <div class="iq-alert-icon">
                                          <i class="fas fa-clipboard-list"></i>
                                        </div>
                                        <div class="iq-alert-text"><b>Sand Blasting Lunas</b> - Persentase Pekerjaan <b>25% (New)</b></div>
                                        <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                          <i class="far fa-hand-pointer"></i> Click Here
                                        </a>
                                      </b-alert>
                                    </a>
                                    <b-collapse id="job-2-3" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sand Blasting Lunas</legend>
                                        <b-row class="mt-3">
                                          <b-col md="3">
                                            <b-form-group label="Est. Start*" label-for="est_start">
                                              <b-form-input type="date" id="est_start"></b-form-input>
                                              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Est. Finish *" label-for="end">
                                              <b-form-input type="date" id="end"></b-form-input>
                                              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Bobot Pekerjaan *" label-for="bobot_pekerjaan">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                </div>
                                                <input type="text" class="form-control" id="bobot_pekerjaan">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Attachment Job" label-for="attachment_job">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File* : <b-badge variant="info">.jpg/.png/.xlx/.doc/.pdf</b-badge><br>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="12">
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="3" placeholder="Sand Blasting Lunas"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                      </fieldset>
                                      <b-row>
                                        <b-col md="12">
                                          <b-form-group label="Internal Man Power" label-for="internal_man_power">
                                            <template v-for="(item,index) in text">
                                              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                <div class="custom-switch-inner">
                                                  <p class="mb-0"> {{ item.label }} </p>
                                                  <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                  <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                  </label>
                                                </div>
                                              </div>
                                            </template>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Internal Man Power</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                              <b-form-input type="text" id="nama_internal_man_power" placeholder="Nurul Akbar"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Jabatan *" label-for="jabatan">
                                              <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                <template v-slot:first>
                                                <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                </template>
                                              </b-form-select>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                              <b-form-input type="text" id="nama_internal_man_power" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Jabatan *" label-for="jabatan">
                                              <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                <template v-slot:first>
                                                <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                </template>
                                              </b-form-select>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-button variant="success" class="mt-3"><i class="fa fa-users"></i> ADD INTERNAL MAN POWER</b-button>
                                      </fieldset>
                                      <b-row>
                                        <b-col md="12">
                                          <b-form-group label="Sub Kontraktor" label-for="subkontraktor">
                                            <template v-for="(item,index) in text">
                                              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                <div class="custom-switch-inner">
                                                  <p class="mb-0"> {{ item.label }} </p>
                                                  <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                  <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                  </label>
                                                </div>
                                              </div>
                                            </template>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                              <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                              <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Penawaran" label-for="penawaran">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Attachment Shipyard" label-for="doc_shipyard">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #2</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                              <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                              <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Penawaran" label-for="penawaran">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Attachment Shipyard" label-for="doc_shipyard">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                      </fieldset>
                                      <b-button variant="success" class="mb-3"><i class="fa fa-users"></i> ADD SUB KONTRAKTOR</b-button>
                                    </b-collapse>
                                  </b-card>
                                  <b-card no-body >
                                    <a href="#" v-b-toggle.job-2-4>
                                      <b-alert :show="true" class="text-white bg-danger">
                                        <div class="iq-alert-icon">
                                          <i class="fas fa-clipboard-list"></i>
                                        </div>
                                        <div class="iq-alert-text"><b>Sand Blasting Deck & Bullwark</b> - Persentase Pekerjaan <b>25% (New)</b></div>
                                        <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                          <i class="far fa-hand-pointer"></i> Click Here
                                        </a>
                                      </b-alert>
                                    </a>
                                    <b-collapse id="job-2-4" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sand Blasting Deck & Bullwark</legend>
                                        <b-row class="mt-3">
                                          <b-col md="3">
                                            <b-form-group label="Est. Start*" label-for="est_start">
                                              <b-form-input type="date" id="est_start"></b-form-input>
                                              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Est. Finish *" label-for="end">
                                              <b-form-input type="date" id="end"></b-form-input>
                                              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Bobot Pekerjaan *" label-for="bobot_pekerjaan">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                </div>
                                                <input type="text" class="form-control" id="bobot_pekerjaan">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Attachment Job" label-for="attachment_job">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File* : <b-badge variant="info">.jpg/.png/.xlx/.doc/.pdf</b-badge><br>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="12">
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="3" placeholder="Sand Blasting Deck & Bullwark"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                      </fieldset>
                                      <b-row>
                                        <b-col md="12">
                                          <b-form-group label="Internal Man Power" label-for="internal_man_power">
                                            <template v-for="(item,index) in text">
                                              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                <div class="custom-switch-inner">
                                                  <p class="mb-0"> {{ item.label }} </p>
                                                  <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                  <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                  </label>
                                                </div>
                                              </div>
                                            </template>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Internal Man Power</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                              <b-form-input type="text" id="nama_internal_man_power" placeholder="Nurul Akbar"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Jabatan *" label-for="jabatan">
                                              <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                <template v-slot:first>
                                                <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                </template>
                                              </b-form-select>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                              <b-form-input type="text" id="nama_internal_man_power" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Jabatan *" label-for="jabatan">
                                              <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                <template v-slot:first>
                                                <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                </template>
                                              </b-form-select>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-button variant="success" class="mt-3"><i class="fa fa-users"></i> ADD INTERNAL MAN POWER</b-button>
                                      </fieldset>
                                      <b-row>
                                        <b-col md="12">
                                          <b-form-group label="Sub Kontraktor" label-for="subkontraktor">
                                            <template v-for="(item,index) in text">
                                              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                <div class="custom-switch-inner">
                                                  <p class="mb-0"> {{ item.label }} </p>
                                                  <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                  <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                  </label>
                                                </div>
                                              </div>
                                            </template>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                              <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                              <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Penawaran" label-for="penawaran">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Attachment Shipyard" label-for="doc_shipyard">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #2</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                              <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                              <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Penawaran" label-for="penawaran">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Attachment Shipyard" label-for="doc_shipyard">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                      </fieldset>
                                      <b-button variant="success" class="mb-3"><i class="fa fa-users"></i> ADD SUB KONTRAKTOR</b-button>
                                    </b-collapse>
                                  </b-card>
                                </div>
                              </b-card-body>
                            </b-collapse>

                            <b-row>
                              <b-col md="12" class="mt-3">
                                <b-card-text class="pull-right"><small class="text-muted">Sign * : <b-badge variant="warning">Mandatory (Must be Input)</b-badge></small></b-card-text>
                                <br><br>
                                <b-button type="submit" variant="danger" class="pull-right"><i class="fa fa-close"></i> Reset Form</b-button>
                                <b-button type="submit" variant="primary" class="pull-right mr-1"><i class="fa fa-save"></i> Save</b-button>
                              </b-col>
                            </b-row>
                          </fieldset>
                        </b-col>
                      </b-row>
                    </template>
                  </b-sidebar>
                  <!-- SLIDE - FORM EMERGENCY DOCKING END -->
                </template>
              </iq-card>
              <b-col sm="12">
                <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
                  <template v-slot:headerTitle>
                    <h5 class="card-title text-primary"><strong>DAFTAR KAPAL BELUM TERSISTEM DOCKING</strong></h5>
                  </template>
                  <template v-slot:body class="d-flex flex-wrap align-items-center p-0 slick-initialized slick-slider">
                      <b-row class="mb-4">
                        <b-col md="4" class="text-right">
                          <b-form-input type="text" placeholder="Search fleets..." v-model="fleet_params.search" @input="fetchFleets"></b-form-input>
                        </b-col>
                      </b-row>
                      <hr>
                      <Slick :option="slickOptions" id="doster-list-slide">
                          <div class="docter-list-item-inner rounded text-center" v-for="fleetDetail in fleetList" :key="fleetDetail.id">
                            <div class="donter-profile">
                              <img :src="fleetDetail.img" class="img-fluid rounded-circle" alt="user-img">
                            </div>
                            <div class="doctor-detail mt-3">
                              <h5>{{fleetDetail.name}}</h5>
                              <h6>{{fleetDetail.position}}</h6>
                            </div>
                            <hr>
                            <b-button v-b-modal.set_schedule size="sm" variant="danger" class="mb-1"><i class="fa fa-ship"></i>Set Intermediate Survey</b-button>
                            <b-button v-b-modal.set_schedule size="sm" variant="primary"><i class="fa fa-ship"></i>Set Special Survey</b-button>
                          </div>
                      </Slick>
                  </template>
                </iq-card>
              </b-col>
            </tab-content-item>
            <!-- PROGRESS -->
            <tab-content-item :active="false" id="progress">
              <iq-card>
                <template v-slot:headerTitle>
                    <h5 class="card-title text-primary"><strong>DOCKING PROGRESS</strong></h5>
                </template>
                <template v-slot:body>
                  <!-- FILTER DOCKING SCHEDULE-->
                  <b-row class="mb-3">
                    <b-col md="6">
                      <v-multiselect v-model="fleetCategorySelect" tag-placeholder="Category" placeholder="Fleet Category" label="text" track-by="value" :options="fleetCategory" :multiple="true" :taggable="true">
                        </v-multiselect>
                    </b-col>
                    <b-col md="2">
                      <b-form-select plain size="sm">
                        <b-form-select-option>Select Fleet</b-form-select-option>
                        <b-form-select-option>TB. TAVIA 1</b-form-select-option>
                        <b-form-select-option>TB. TAVIA 2</b-form-select-option>
                        <b-form-select-option>TB. TAVIA 3</b-form-select-option>
                        <b-form-select-option>TB. TAVIA 4</b-form-select-option>
                        <b-form-select-option>BG. TAVIA 5</b-form-select-option>
                        <b-form-select-option>BG. TAVIA 6</b-form-select-option>
                      </b-form-select>
                    </b-col>
                    <b-col md="2">
                      <b-form-select plain v-model="selectedDockingType" :options="docking_type" size="sm">
                        <template v-slot:first>
                        <b-form-select-option :value="null">Select Docking Type</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-col>
                    <b-col md="2">
                      <b-form-select plain v-model="selectedDockingStatus" :options="dockingStatus" size="sm">
                        <template v-slot:first>
                        <b-form-select-option :value="null">Select Status</b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-col>
                    <b-col md="3" class="mt-3">
                      <date-picker :lang="lang" v-model="dateRange" type="date" range placeholder="Select date range" size="sm"></date-picker>
                    </b-col>
                    <b-col md="9" class="mt-3">
                      <b-button variant="primary" block size="sm"><i class="fa fa-filter mr-2"></i> Filter</b-button>
                    </b-col>
                  </b-row>
                  <!-- FILTER DOCKING SCHEDULE END -->

                  <!-- DOCKING PROGRESS -->
                  <table class="table table-striped table-sm">
                    <thead>
                      <tr>
                        <th class="text-center">NO</th>
                        <th class="text-center">FLEET</th>
                        <th class="text-center">DOCKING TYPE</th>
                        <th class="text-center">SHIPYARD</th>
                        <th class="text-center">UPLSIP</th>
                        <th class="text-center">COMPLETE DATE</th>
                        <th class="text-center">JOB</th>
                        <th class="text-center">STATUS</th>
                        <th class="text-center">ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th class="text-center">1</th>
                        <td class="text-center"><a href="#" @click="slideDetailDocking = !slideDetailDocking;">TB. TAVIA 1</a></td>
                        <td class="text-center">INTERMEDIATE SURVEY</td>
                        <td class="text-center">PT. CAHAYA BAHARI SHIPYARD</td>
                        <td class="text-center">2017-05-10</td>
                        <td class="text-center">2022-06-12</td>
                        <td class="text-center">0/20</td>
                        <td class="text-center"><b-badge variant="warning d-block">NEED REVIEW</b-badge></td>
                        <td class="text-center">
                          <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                            <template v-slot:button-content>
                              <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                              </span>
                            </template>
                            <b-dropdown-item href="#" @click="slideReviewDocking = !slideReviewDocking;"  variant="warning"><i class="fa fa-search mr-2"></i>REVIEW</b-dropdown-item>
                            <b-dropdown-item variant="dark"><router-link to="/templates/docking-history"><i class="fa fa-history mr-2"></i>HISTORY DOCKING</router-link></b-dropdown-item>
                            <b-dropdown-item href="#" variant="danger"><i class="fa fa-trash mr-2"></i>DELETE</b-dropdown-item>
                          </b-dropdown>
                        </td>
                      </tr>
                      <tr>
                        <th class="text-center">2</th>
                        <td class="text-center"><a href="#" @click="slideDetailDocking = !slideDetailDocking;">TB. TAVIA 2</a></td>
                        <td class="text-center">SPECIAL SURVEY</td>
                        <td class="text-center">PT. CITRA SHIPYARD INDONESIA</td>
                        <td class="text-center">2018-03-25</td>
                        <td class="text-center">2018-04-30</td>
                        <td class="text-center">0/15</td>
                        <td class="text-center"><b-badge variant="primary d-block">NEED APPROVE</b-badge></td>
                        <td class="text-center">
                          <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                            <template v-slot:button-content>
                              <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                              </span>
                            </template>
                            <b-dropdown-item href="#" @click="slideApproveDocking = !slideApproveDocking;"  variant="success"><i class="fa fa-check mr-2"></i>APPROVE</b-dropdown-item>
                            <b-dropdown-item variant="dark"><router-link to="/templates/docking-history"><i class="fa fa-history mr-2"></i>HISTORY DOCKING</router-link></b-dropdown-item>
                            <b-dropdown-item href="#" variant="danger"><i class="fa fa-trash mr-2"></i>DELETE</b-dropdown-item>
                            <b-dropdown-item href="#" @click="slideDetailDocking = !slideDetailDocking;"  variant="success"><i class="fas fa-info-circle"></i> DETAIL</b-dropdown-item>
                          </b-dropdown>
                        </td>
                      </tr>
                      <tr>
                        <th class="text-center">3</th>
                        <td class="text-center"><a href="#" @click="slideDetailDocking = !slideDetailDocking;">TB. TAVIA 3</a></td>
                        <td class="text-center">INTERMEDIATE SURVEY</td>
                        <td class="text-center">PT. SARANA SHIPYARD BAHARI</td>
                        <td class="text-center">2020-01-15</td>
                        <td class="text-center">2020-02-20</td>
                        <td class="text-center">0/15</td>
                        <td class="text-center"><b-badge variant="info d-block">ON PROGRESS</b-badge></td>
                        <td class="text-center">
                          <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                            <template v-slot:button-content>
                              <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                              </span>
                            </template>
                            <b-dropdown-item @click="slideActivity = !slideActivity;" variant="info"><i class="fa fa-comments mr-2"></i>ACTIVITY</b-dropdown-item>
                            <b-dropdown-item @click="slideUpdateDocking = !slideUpdateDocking;" variant="primary"><i class="fa fa-plus-circle mr-2"></i>ADD JOB</b-dropdown-item>
                            <b-dropdown-item variant="dark"><router-link to="/templates/docking-history"><i class="fa fa-history mr-2"></i>HISTORY DOCKING</router-link></b-dropdown-item>
                            <b-dropdown-item href="#" variant="danger"><i class="fa fa-trash mr-2"></i>DELETE</b-dropdown-item>
                          </b-dropdown>
                        </td>
                      </tr>
                      <tr class="bg-danger">
                        <th class="text-center">4</th>
                        <td class="text-center"><a href="#" @click="slideDetailDocking = !slideDetailDocking;">TB. TAVIA 4</a></td>
                        <td class="text-center">INTERMEDIATE SURVEY</td>
                        <td class="text-center">PT. SARANA SHIPYARD BAHARI</td>
                        <td class="text-center">2020-01-15</td>
                        <td class="text-center">2020-02-20</td>
                        <td class="text-center">0/15</td>
                        <td class="text-center"><b-badge variant="light d-block">REJECT</b-badge></td>
                        <td class="text-center">
                          <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                            <template v-slot:button-content>
                              <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                              </span>
                            </template>
                            <b-dropdown-item variant="danger" @click="slideUpdateDocking = !slideUpdateDocking;"><i class="fa fa-percent mr-2"></i>UPDATE DOCKING</b-dropdown-item>
                            <b-dropdown-item variant="dark"><router-link to="/templates/docking-history"><i class="fa fa-history mr-2"></i>HISTORY DOCKING</router-link></b-dropdown-item>
                            <b-dropdown-item href="#" variant="danger"><i class="fa fa-trash mr-2"></i>DELETE</b-dropdown-item>
                          </b-dropdown>
                        </td>
                      </tr>
                      <tr>
                        <th class="text-center">5</th>
                        <td class="text-center"><a href="#" @click="slideDetailDocking = !slideDetailDocking;">TB. TAVIA 5</a></td>
                        <td class="text-center">INTERMEDIATE SURVEY</td>
                        <td class="text-center">PT. CITRA SHIPYARD INDONESIA</td>
                        <td class="text-center">2018-03-25</td>
                        <td class="text-center">2018-04-30</td>
                        <td class="text-center">15/15</td>
                        <td class="text-center"><b-badge variant="success d-block">SEA TRIAL</b-badge></td>
                        <td class="text-center">
                          <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                            <template v-slot:button-content>
                              <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                              </span>
                            </template>
                            <b-dropdown-item href="#"  variant="success"><router-link to="docking-sea-trial"><i class="fa fa-ship mr-2"></i>SEA TRIAL</router-link></b-dropdown-item>
                            <b-dropdown-item variant="dark"><router-link to="/templates/docking-history"><i class="fa fa-history mr-2"></i>HISTORY DOCKING</router-link></b-dropdown-item>
                            <b-dropdown-item href="#" variant="danger"><i class="fa fa-trash mr-2"></i>DELETE</b-dropdown-item>
                          </b-dropdown>
                        </td>
                      </tr>
                      <tr>
                        <th class="text-center">6</th>
                        <td class="text-center"><a href="#" @click="slideDetailDocking = !slideDetailDocking;">TB. TAVIA 6</a></td>
                        <td class="text-center">INTERMEDIATE SURVEY</td>
                        <td class="text-center">PT. CITRA SHIPYARD INDONESIA</td>
                        <td class="text-center">2018-03-25</td>
                        <td class="text-center">2018-04-30</td>
                        <td class="text-center">15/15</td>
                        <td class="text-center"><b-badge variant="primary d-block">SERAH TERIMA</b-badge></td>
                        <td class="text-center">
                          <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                            <template v-slot:button-content>
                              <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                              </span>
                            </template>
                            <b-dropdown-item href="#" variant="primary"><router-link to="docking-serah-terima"><i class="fas fa-handshake mr-2"></i>SERAH TERIMA</router-link></b-dropdown-item>
                            <b-dropdown-item variant="dark"><router-link to="/templates/docking-history"><i class="fa fa-history mr-2"></i>HISTORY DOCKING</router-link></b-dropdown-item>
                            <b-dropdown-item href="#" variant="danger"><i class="fa fa-trash mr-2"></i>DELETE</b-dropdown-item>
                          </b-dropdown>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="mt-3">
                    <b-pagination :value="1" :total-rows="50" align="center" />
                  </div>
                  <!-- DOCKING PROGRESS END -->

                  <!-- SLIDE - FORM REVIEW DOCKING -->
                  <b-sidebar
                    v-model="slideReviewDocking"
                    id="review_docking"
                    title="FORM REVIEW DOCKING - TB. TAVIA 1"
                    width="100%"
                    backdrop-variant="dark"
                    bg-variant="white"
                    right
                    backdrop
                    shadow>
                    <template #default="{hide}">
                      <b-row class="m-3">
                        <b-col cols="4">
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Job List</legend>
                            <b-form-input id="job_name" type="text" placeholder="Search Job Name"></b-form-input>
                            <v-jstree :data="tree" show-checkbox allow-batch whole-row :item-events="itemEvents" @item-click="itemClick" class="mt-3">
                              <template slot-scope="_">
                                <div style="display: inherit; width: 200px" @click.ctrl="customItemClickWithCtrl">
                                  <i :class="_.vm.themeIconClasses" role="presentation" v-if="!_.model.loading"></i>
                                  {{_.model.text}}
                                </div>
                              </template>
                            </v-jstree>
                            <b-button block variant="success"><i class="fa fa-plus"></i>ADD JOB</b-button>
                          </fieldset>
                        </b-col>
                        <b-col cols="8">
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Docking Info</legend>
                            <b-row>
                              <b-col md="4">
                                <b-form-group label="Nama Shipyard*" label-for="shipyard">
                                  <b-form-select plain v-model="selectedShipyard" :options="shipyard" size="lg" style="border: solid 1px gray">
                                    <template v-slot:first>
                                    <b-form-select-option :value="null">Pilih Shipyard</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                </b-form-group>
                              </b-col>
                              <b-col md="4">
                                <b-form-group label="PIC*" label-for="pic">
                                  <b-form-select plain size="lg" style="border: solid 1px gray">
                                    <template v-slot:first>
                                    <b-form-select-option>Pilih PIC</b-form-select-option>
                                    <b-form-select-option>Nurul Akbar</b-form-select-option>
                                    <b-form-select-option>Agung Tri Laksono</b-form-select-option>
                                    <b-form-select-option>Dendy Haidar</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                </b-form-group>
                              </b-col>
                              <b-col md="4">
                                <b-form-group label="Jenis Pekerjaan*" label-for="jenis_pekerjaan">
                                  <b-form-select plain size="lg" style="border: solid 1px gray">
                                    <template v-slot:first>
                                    <b-form-select-option>Pilih Jenis Pekerjaan</b-form-select-option>
                                    <b-form-select-option>General Overhaul</b-form-select-option>
                                    <b-form-select-option>Top Overhaul</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                </b-form-group>
                              </b-col>
                              <b-col md="4">
                                <b-form-group label="TA. Shipyard*" label-for="arrival_shipyard">
                                  <b-form-input type="date" id="arrival_shipyard" style="border: solid 1px gray"></b-form-input>
                                  <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                </b-form-group>
                              </b-col>
                              <b-col md="4">
                                <b-form-group label="Floating Repair" label-for="floating_repair">
                                  <b-form-input type="date" id="floating_repair" style="border: solid 1px gray"></b-form-input>
                                  <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                </b-form-group>
                              </b-col>
                              <b-col md="4">
                                <b-form-group label="Up Slip*" label-for="up_slip*">
                                  <b-form-input type="date" id="up_slip" style="border: solid 1px gray"></b-form-input>
                                  <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                </b-form-group>
                              </b-col>
                              <b-col md="4">
                                <b-form-group label="Downslip" label-for="downslip">
                                  <b-form-input type="date" id="downslip" style="border: solid 1px gray"></b-form-input>
                                  <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                </b-form-group>
                              </b-col>
                              <b-col md="4">
                                <b-form-group label="Sea Trial" label-for="sea_trail">
                                  <b-form-input type="date" id="sea_trail" style="border: solid 1px gray"></b-form-input>
                                  <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                </b-form-group>
                              </b-col>
                              <b-col md="4">
                                <b-form-group label="Complete / Delivery" label-for="delivery_date">
                                  <b-form-input type="date" id="delivery_date" style="border: solid 1px gray"></b-form-input>
                                  <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                </b-form-group>
                              </b-col>
                              <b-col md="2">
                                <b-form-group label="Waiting Space" label-for="waiting_space">
                                  <b-form-input type="number" readonly id="waiting_space" style="border: solid 1px gray" placeholder="9"></b-form-input>
                                </b-form-group>
                              </b-col>
                              <b-col md="2">
                                <b-form-group label="Target Days" label-for="target_days">
                                  <b-form-input type="number" readonly id="target_days" style="border: solid 1px gray" placeholder="60"></b-form-input>
                                </b-form-group>
                              </b-col>
                              <b-col md="4">
                                <b-form-group label="Estimasi Tonase" label-for="est_tonase">
                                  <div class="input-group">
                                    <input type="number" class="form-control" id="est_tonase" style="border: solid 1px gray">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text bg-primary" id="inputGroupPrepend2">Ton</span>
                                    </div>
                                  </div>
                                </b-form-group>
                              </b-col>
                              <b-col md="4">
                                <b-form-group label="Estimasi Budget" label-for="est_budget">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                    </div>
                                    <input type="number" class="form-control" id="est_budget" style="border: solid 1px gray">
                                  </div>
                                </b-form-group>
                              </b-col>
                              <b-col md="4">
                                <b-form-group label="Dokumen Shipyard" label-for="doc_shipyard">
                                  <div class="custom-file">
                                    <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                    <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                  </div>
                                  <small class="text-muted">
                                    File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                    File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                  </small>
                                </b-form-group>
                              </b-col>
                            </b-row>
                          </fieldset>
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Docking Job</legend>
                            <b-alert :show="true" variant="primary">
                              <div class="iq-alert-icon">
                                <i class="fa fa-percent"></i>
                              </div>
                              <div class="iq-alert-text">Docking Job Category <b>100%</b></div>
                            </b-alert>

                            <a href="#" v-b-toggle.jobCat-1>
                              <b-alert :show="true" class="text-white bg-primary">
                                <div class="iq-alert-icon">
                                  <i class="fas fa-tasks"></i>
                                </div>
                                <div class="iq-alert-text">Docking / Undocking <b>(2 Jobs) - 30%</b></div>
                                <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                  <i class="far fa-hand-pointer"></i> Click Here
                                </a>
                              </b-alert>
                            </a>
                            <b-collapse id="jobCat-1" accordion="my-accordion" role="tabpanel">
                              <b-card-body>
                                <b-form-group label="Persentase Job Category*" label-for="percent_job_category">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text bg-primary" id="percent_job_category">%</span>
                                    </div>
                                    <input type="text" class="form-control" id="percent_job_category">
                                  </div>
                                </b-form-group>
                                <b-alert :show="true" variant="danger">
                                  <div class="iq-alert-icon">
                                    <i class="fa fa-percent"></i>
                                  </div>
                                  <div class="iq-alert-text">Docking Job <b>100%</b></div>
                                </b-alert>
                                <div class="accordion" role="tablist">
                                  <b-card no-body >
                                    <a href="#" v-b-toggle.job-1-1>
                                      <b-alert :show="true" class="text-white bg-danger">
                                        <div class="iq-alert-icon">
                                          <i class="fas fa-clipboard-list"></i>
                                        </div>
                                        <div class="iq-alert-text"><b>Upslip</b> - Persentase Pekerjaan <b>50% (New)</b></div>
                                        <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                          <i class="far fa-hand-pointer"></i> Click Here
                                        </a>
                                      </b-alert>
                                    </a>
                                    <b-collapse id="job-1-1" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Upslip</legend>
                                        <b-row class="mt-3">
                                          <b-col md="3">
                                            <b-form-group label="Est. Start*" label-for="est_start">
                                              <b-form-input type="date" id="est_start"></b-form-input>
                                              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Est. Finish *" label-for="end">
                                              <b-form-input type="date" id="end"></b-form-input>
                                              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Bobot Pekerjaan *" label-for="bobot_pekerjaan">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                </div>
                                                <input type="text" class="form-control" id="bobot_pekerjaan">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Attachment Job" label-for="attachment_job">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File* : <b-badge variant="info">.jpg/.png/.xlx/.doc/.pdf</b-badge><br>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="12">
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="3" placeholder="Check water tubes/plates and clean/free from clogged."></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                      </fieldset>
                                      <b-row>
                                        <b-col md="12">
                                          <b-form-group label="Internal Man Power" label-for="internal_man_power">
                                            <template v-for="(item,index) in text">
                                              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                <div class="custom-switch-inner">
                                                  <p class="mb-0"> {{ item.label }} </p>
                                                  <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                  <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                  </label>
                                                </div>
                                              </div>
                                            </template>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Internal Man Power</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                              <b-form-input type="text" id="nama_internal_man_power" placeholder="Nurul Akbar"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Jabatan *" label-for="jabatan">
                                              <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                <template v-slot:first>
                                                <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                </template>
                                              </b-form-select>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                              <b-form-input type="text" id="nama_internal_man_power" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Jabatan *" label-for="jabatan">
                                              <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                <template v-slot:first>
                                                <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                </template>
                                              </b-form-select>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-button variant="success" class="mt-3"><i class="fa fa-users"></i> ADD INTERNAL MAN POWER</b-button>
                                      </fieldset>
                                      <b-row>
                                        <b-col md="12">
                                          <b-form-group label="Sub Kontraktor" label-for="subkontraktor">
                                            <template v-for="(item,index) in text">
                                              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                <div class="custom-switch-inner">
                                                  <p class="mb-0"> {{ item.label }} </p>
                                                  <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                  <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                  </label>
                                                </div>
                                              </div>
                                            </template>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                              <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                              <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Penawaran" label-for="penawaran">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Attachment Shipyard" label-for="doc_shipyard">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #2</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                              <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                              <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Penawaran" label-for="penawaran">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Attachment Shipyard" label-for="doc_shipyard">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                      </fieldset>
                                      <b-button variant="success" class="mb-3"><i class="fa fa-users"></i> ADD SUB KONTRAKTOR</b-button>
                                    </b-collapse>
                                  </b-card>
                                  <b-card no-body >
                                    <a href="#" v-b-toggle.job-1-2>
                                      <b-alert :show="true" class="text-white bg-danger">
                                        <div class="iq-alert-icon">
                                          <i class="fas fa-clipboard-list"></i>
                                        </div>
                                        <div class="iq-alert-text"><b>Downslip</b> - Persentase Pekerjaan <b>50% (New)</b></div>
                                        <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                          <i class="far fa-hand-pointer"></i> Click Here
                                        </a>
                                      </b-alert>
                                    </a>
                                    <b-collapse id="job-1-2" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Downslip</legend>
                                        <b-row class="mt-3">
                                          <b-col md="3">
                                            <b-form-group label="Est. Start*" label-for="est_start">
                                              <b-form-input type="date" id="est_start"></b-form-input>
                                              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Est. Finish *" label-for="end">
                                              <b-form-input type="date" id="end"></b-form-input>
                                              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Bobot Pekerjaan *" label-for="bobot_pekerjaan">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                </div>
                                                <input type="text" class="form-control" id="bobot_pekerjaan">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Attachment Job" label-for="attachment_job">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File* : <b-badge variant="info">.jpg/.png/.xlx/.doc/.pdf</b-badge><br>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="12">
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="3" placeholder="Check water tubes/plates and clean/free from clogged."></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                      </fieldset>
                                      <b-row>
                                        <b-col md="12">
                                          <b-form-group label="Internal Man Power" label-for="internal_man_power">
                                            <template v-for="(item,index) in text">
                                              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                <div class="custom-switch-inner">
                                                  <p class="mb-0"> {{ item.label }} </p>
                                                  <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                  <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                  </label>
                                                </div>
                                              </div>
                                            </template>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Internal Man Power</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                              <b-form-input type="text" id="nama_internal_man_power" placeholder="Nurul Akbar"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Jabatan *" label-for="jabatan">
                                              <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                <template v-slot:first>
                                                <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                </template>
                                              </b-form-select>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                              <b-form-input type="text" id="nama_internal_man_power" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Jabatan *" label-for="jabatan">
                                              <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                <template v-slot:first>
                                                <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                </template>
                                              </b-form-select>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-button variant="success" class="mt-3"><i class="fa fa-users"></i> ADD INTERNAL MAN POWER</b-button>
                                      </fieldset>
                                      <b-row>
                                        <b-col md="12">
                                          <b-form-group label="Sub Kontraktor" label-for="subkontraktor">
                                            <template v-for="(item,index) in text">
                                              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                <div class="custom-switch-inner">
                                                  <p class="mb-0"> {{ item.label }} </p>
                                                  <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                  <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                  </label>
                                                </div>
                                              </div>
                                            </template>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                              <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                              <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Penawaran" label-for="penawaran">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Attachment Shipyard" label-for="doc_shipyard">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #2</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                              <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                              <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Penawaran" label-for="penawaran">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Attachment Shipyard" label-for="doc_shipyard">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                      </fieldset>
                                      <b-button variant="success" class="mb-3"><i class="fa fa-users"></i> ADD SUB KONTRAKTOR</b-button>
                                    </b-collapse>
                                  </b-card>
                                </div>
                              </b-card-body>
                            </b-collapse>

                            <a href="#" v-b-toggle.jobCat-2>
                              <b-alert :show="true" class="text-white bg-primary">
                                <div class="iq-alert-icon">
                                  <i class="fas fa-tasks"></i>
                                </div>
                                <div class="iq-alert-text">Sand Blasting <b>(4 Jobs) - 70%</b></div>
                                <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                  <i class="far fa-hand-pointer"></i> Click Here
                                </a>
                              </b-alert>
                            </a>
                            <b-collapse id="jobCat-2" accordion="my-accordion" role="tabpanel">
                              <b-card-body>
                                <b-form-group label="Persentase Job Category*" label-for="percent_job_category">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text bg-primary" id="percent_job_category">%</span>
                                    </div>
                                    <input type="text" class="form-control" id="percent_job_category">
                                  </div>
                                </b-form-group>
                                <b-alert :show="true" variant="danger">
                                  <div class="iq-alert-icon">
                                    <i class="fa fa-percent"></i>
                                  </div>
                                  <div class="iq-alert-text">Docking Job <b>100%</b></div>
                                </b-alert>
                                <div class="accordion" role="tablist">
                                  <b-card no-body >
                                    <a href="#" v-b-toggle.job-2-1>
                                      <b-alert :show="true" class="text-white bg-danger">
                                        <div class="iq-alert-icon">
                                          <i class="fas fa-clipboard-list"></i>
                                        </div>
                                        <div class="iq-alert-text"><b>Scraping Teripting di Lambung & Lunas</b> - Persentase Pekerjaan <b>25% (New)</b></div>
                                        <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                          <i class="far fa-hand-pointer"></i> Click Here
                                        </a>
                                      </b-alert>
                                    </a>
                                    <b-collapse id="job-2-1" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Scraping Teripting di Lambung & Lunas</legend>
                                        <b-row class="mt-3">
                                          <b-col md="3">
                                            <b-form-group label="Est. Start*" label-for="est_start">
                                              <b-form-input type="date" id="est_start"></b-form-input>
                                              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Est. Finish *" label-for="end">
                                              <b-form-input type="date" id="end"></b-form-input>
                                              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Bobot Pekerjaan *" label-for="bobot_pekerjaan">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                </div>
                                                <input type="text" class="form-control" id="bobot_pekerjaan">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Attachment Job" label-for="attachment_job">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File* : <b-badge variant="info">.jpg/.png/.xlx/.doc/.pdf</b-badge><br>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="12">
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="3" placeholder="Scraping Teripting di Lambung & Lunas"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                      </fieldset>
                                      <b-row>
                                        <b-col md="12">
                                          <b-form-group label="Internal Man Power" label-for="internal_man_power">
                                            <template v-for="(item,index) in text">
                                              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                <div class="custom-switch-inner">
                                                  <p class="mb-0"> {{ item.label }} </p>
                                                  <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                  <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                  </label>
                                                </div>
                                              </div>
                                            </template>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Internal Man Power</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                              <b-form-input type="text" id="nama_internal_man_power" placeholder="Nurul Akbar"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Jabatan *" label-for="jabatan">
                                              <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                <template v-slot:first>
                                                <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                </template>
                                              </b-form-select>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                              <b-form-input type="text" id="nama_internal_man_power" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Jabatan *" label-for="jabatan">
                                              <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                <template v-slot:first>
                                                <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                </template>
                                              </b-form-select>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-button variant="success" class="mt-3"><i class="fa fa-users"></i> ADD INTERNAL MAN POWER</b-button>
                                      </fieldset>
                                      <b-row>
                                        <b-col md="12">
                                          <b-form-group label="Sub Kontraktor" label-for="subkontraktor">
                                            <template v-for="(item,index) in text">
                                              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                <div class="custom-switch-inner">
                                                  <p class="mb-0"> {{ item.label }} </p>
                                                  <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                  <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                  </label>
                                                </div>
                                              </div>
                                            </template>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                              <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                              <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Penawaran" label-for="penawaran">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Attachment Shipyard" label-for="doc_shipyard">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                      </fieldset>
                                      <b-button variant="success" class="mb-3"><i class="fa fa-users"></i> ADD SUB KONTRAKTOR</b-button>
                                    </b-collapse>
                                  </b-card>
                                  <b-card no-body >
                                    <a href="#" v-b-toggle.job-2-2>
                                      <b-alert :show="true" class="text-white bg-danger">
                                        <div class="iq-alert-icon">
                                          <i class="fas fa-clipboard-list"></i>
                                        </div>
                                        <div class="iq-alert-text"><b>Pencucian Lambung dengan Air Tawar</b> - Persentase Pekerjaan <b>25% (New)</b></div>
                                        <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                          <i class="far fa-hand-pointer"></i> Click Here
                                        </a>
                                      </b-alert>
                                    </a>
                                    <b-collapse id="job-2-2" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Pencucian Lambung dengan Air Tawar</legend>
                                        <b-row class="mt-3">
                                          <b-col md="3">
                                            <b-form-group label="Est. Start*" label-for="est_start">
                                              <b-form-input type="date" id="est_start"></b-form-input>
                                              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Est. Finish *" label-for="end">
                                              <b-form-input type="date" id="end"></b-form-input>
                                              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Bobot Pekerjaan *" label-for="bobot_pekerjaan">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                </div>
                                                <input type="text" class="form-control" id="bobot_pekerjaan">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Attachment Job" label-for="attachment_job">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File* : <b-badge variant="info">.jpg/.png/.xlx/.doc/.pdf</b-badge><br>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="12">
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="3" placeholder="Pencucian Lambung dengan Air Tawar"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                      </fieldset>
                                      <b-row>
                                        <b-col md="12">
                                          <b-form-group label="Internal Man Power" label-for="internal_man_power">
                                            <template v-for="(item,index) in text">
                                              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                <div class="custom-switch-inner">
                                                  <p class="mb-0"> {{ item.label }} </p>
                                                  <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                  <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                  </label>
                                                </div>
                                              </div>
                                            </template>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Internal Man Power</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                              <b-form-input type="text" id="nama_internal_man_power" placeholder="Nurul Akbar"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Jabatan *" label-for="jabatan">
                                              <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                <template v-slot:first>
                                                <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                </template>
                                              </b-form-select>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                              <b-form-input type="text" id="nama_internal_man_power" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Jabatan *" label-for="jabatan">
                                              <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                <template v-slot:first>
                                                <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                </template>
                                              </b-form-select>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-button variant="success" class="mt-3"><i class="fa fa-users"></i> ADD INTERNAL MAN POWER</b-button>
                                      </fieldset>
                                      <b-row>
                                        <b-col md="12">
                                          <b-form-group label="Sub Kontraktor" label-for="subkontraktor">
                                            <template v-for="(item,index) in text">
                                              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                <div class="custom-switch-inner">
                                                  <p class="mb-0"> {{ item.label }} </p>
                                                  <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                  <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                  </label>
                                                </div>
                                              </div>
                                            </template>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                              <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                              <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Penawaran" label-for="penawaran">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Attachment Shipyard" label-for="doc_shipyard">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #2</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                              <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                              <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Penawaran" label-for="penawaran">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Attachment Shipyard" label-for="doc_shipyard">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                      </fieldset>
                                      <b-button variant="success" class="mb-3"><i class="fa fa-users"></i> ADD SUB KONTRAKTOR</b-button>
                                    </b-collapse>
                                  </b-card>
                                  <b-card no-body >
                                    <a href="#" v-b-toggle.job-2-3>
                                      <b-alert :show="true" class="text-white bg-danger">
                                        <div class="iq-alert-icon">
                                          <i class="fas fa-clipboard-list"></i>
                                        </div>
                                        <div class="iq-alert-text"><b>Sand Blasting Lunas</b> - Persentase Pekerjaan <b>25% (New)</b></div>
                                        <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                          <i class="far fa-hand-pointer"></i> Click Here
                                        </a>
                                      </b-alert>
                                    </a>
                                    <b-collapse id="job-2-3" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sand Blasting Lunas</legend>
                                        <b-row class="mt-3">
                                          <b-col md="3">
                                            <b-form-group label="Est. Start*" label-for="est_start">
                                              <b-form-input type="date" id="est_start"></b-form-input>
                                              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Est. Finish *" label-for="end">
                                              <b-form-input type="date" id="end"></b-form-input>
                                              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Bobot Pekerjaan *" label-for="bobot_pekerjaan">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                </div>
                                                <input type="text" class="form-control" id="bobot_pekerjaan">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Attachment Job" label-for="attachment_job">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File* : <b-badge variant="info">.jpg/.png/.xlx/.doc/.pdf</b-badge><br>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="12">
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="3" placeholder="Sand Blasting Lunas"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                      </fieldset>
                                      <b-row>
                                        <b-col md="12">
                                          <b-form-group label="Internal Man Power" label-for="internal_man_power">
                                            <template v-for="(item,index) in text">
                                              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                <div class="custom-switch-inner">
                                                  <p class="mb-0"> {{ item.label }} </p>
                                                  <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                  <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                  </label>
                                                </div>
                                              </div>
                                            </template>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Internal Man Power</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                              <b-form-input type="text" id="nama_internal_man_power" placeholder="Nurul Akbar"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Jabatan *" label-for="jabatan">
                                              <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                <template v-slot:first>
                                                <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                </template>
                                              </b-form-select>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                              <b-form-input type="text" id="nama_internal_man_power" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Jabatan *" label-for="jabatan">
                                              <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                <template v-slot:first>
                                                <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                </template>
                                              </b-form-select>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-button variant="success" class="mt-3"><i class="fa fa-users"></i> ADD INTERNAL MAN POWER</b-button>
                                      </fieldset>
                                      <b-row>
                                        <b-col md="12">
                                          <b-form-group label="Sub Kontraktor" label-for="subkontraktor">
                                            <template v-for="(item,index) in text">
                                              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                <div class="custom-switch-inner">
                                                  <p class="mb-0"> {{ item.label }} </p>
                                                  <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                  <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                  </label>
                                                </div>
                                              </div>
                                            </template>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                              <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                              <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Penawaran" label-for="penawaran">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Attachment Shipyard" label-for="doc_shipyard">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #2</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                              <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                              <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Penawaran" label-for="penawaran">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Attachment Shipyard" label-for="doc_shipyard">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                      </fieldset>
                                      <b-button variant="success" class="mb-3"><i class="fa fa-users"></i> ADD SUB KONTRAKTOR</b-button>
                                    </b-collapse>
                                  </b-card>
                                  <b-card no-body >
                                    <a href="#" v-b-toggle.job-2-4>
                                      <b-alert :show="true" class="text-white bg-danger">
                                        <div class="iq-alert-icon">
                                          <i class="fas fa-clipboard-list"></i>
                                        </div>
                                        <div class="iq-alert-text"><b>Sand Blasting Deck & Bullwark</b> - Persentase Pekerjaan <b>25% (New)</b></div>
                                        <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                          <i class="far fa-hand-pointer"></i> Click Here
                                        </a>
                                      </b-alert>
                                    </a>
                                    <b-collapse id="job-2-4" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sand Blasting Deck & Bullwark</legend>
                                        <b-row class="mt-3">
                                          <b-col md="3">
                                            <b-form-group label="Est. Start*" label-for="est_start">
                                              <b-form-input type="date" id="est_start"></b-form-input>
                                              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Est. Finish *" label-for="end">
                                              <b-form-input type="date" id="end"></b-form-input>
                                              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Bobot Pekerjaan *" label-for="bobot_pekerjaan">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                </div>
                                                <input type="text" class="form-control" id="bobot_pekerjaan">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Attachment Job" label-for="attachment_job">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File* : <b-badge variant="info">.jpg/.png/.xlx/.doc/.pdf</b-badge><br>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="12">
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="3" placeholder="Sand Blasting Deck & Bullwark"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                      </fieldset>
                                      <b-row>
                                        <b-col md="12">
                                          <b-form-group label="Internal Man Power" label-for="internal_man_power">
                                            <template v-for="(item,index) in text">
                                              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                <div class="custom-switch-inner">
                                                  <p class="mb-0"> {{ item.label }} </p>
                                                  <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                  <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                  </label>
                                                </div>
                                              </div>
                                            </template>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Internal Man Power</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                              <b-form-input type="text" id="nama_internal_man_power" placeholder="Nurul Akbar"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Jabatan *" label-for="jabatan">
                                              <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                <template v-slot:first>
                                                <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                </template>
                                              </b-form-select>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                              <b-form-input type="text" id="nama_internal_man_power" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Jabatan *" label-for="jabatan">
                                              <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                <template v-slot:first>
                                                <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                </template>
                                              </b-form-select>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-button variant="success" class="mt-3"><i class="fa fa-users"></i> ADD INTERNAL MAN POWER</b-button>
                                      </fieldset>
                                      <b-row>
                                        <b-col md="12">
                                          <b-form-group label="Sub Kontraktor" label-for="subkontraktor">
                                            <template v-for="(item,index) in text">
                                              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                <div class="custom-switch-inner">
                                                  <p class="mb-0"> {{ item.label }} </p>
                                                  <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                  <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                  </label>
                                                </div>
                                              </div>
                                            </template>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                              <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                              <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Penawaran" label-for="penawaran">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Attachment Shipyard" label-for="doc_shipyard">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #2</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                              <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                              <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Penawaran" label-for="penawaran">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Attachment Shipyard" label-for="doc_shipyard">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                      </fieldset>
                                      <b-button variant="success" class="mb-3"><i class="fa fa-users"></i> ADD SUB KONTRAKTOR</b-button>
                                    </b-collapse>
                                  </b-card>
                                </div>
                              </b-card-body>
                            </b-collapse>

                            <b-row>
                              <b-col md="12" class="mt-3">
                                <b-card-text class="pull-right"><small class="text-muted">Sign * : <b-badge variant="warning">Mandatory (Must be Input)</b-badge></small></b-card-text>
                                <br><br>
                                <b-button type="submit" variant="danger" class="pull-right"><i class="fa fa-close"></i> Cancel</b-button>
                                <b-button type="submit" variant="primary" class="pull-right mr-1"><i class="fa fa-save"></i> Review</b-button>
                              </b-col>
                            </b-row>
                          </fieldset>
                        </b-col>
                      </b-row>
                    </template>
                  </b-sidebar>
                  <!-- SLIDE - FORM REVIEW DOCKING END -->

                  <!-- SLIDE - FORM UPDATE DOCKING -->
                  <b-sidebar
                    v-model="slideUpdateDocking"
                    id="update_docking"
                    title="UPDATE DOCKING - TB. TAVIA"
                    width="100%"
                    backdrop-variant="dark"
                    bg-variant="white"
                    right
                    backdrop
                    shadow>
                    <template #default="{hide}">
                      <b-row class="m-3">
                        <b-col cols="4">
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Job List</legend>
                            <b-form-input id="job_name" type="text" placeholder="Search Job Name"></b-form-input>
                            <v-jstree :data="tree" show-checkbox allow-batch whole-row :item-events="itemEvents" @item-click="itemClick" class="mt-3">
                              <template slot-scope="_">
                                <div style="display: inherit; width: 200px" @click.ctrl="customItemClickWithCtrl">
                                  <i :class="_.vm.themeIconClasses" role="presentation" v-if="!_.model.loading"></i>
                                  {{_.model.text}}
                                </div>
                              </template>
                            </v-jstree>
                            <b-button block variant="success"><i class="fa fa-plus"></i>ADD JOB</b-button>
                          </fieldset>
                        </b-col>
                        <b-col cols="8">
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Docking Info</legend>
                            <table class="table table-striped">
                              <tbody>
                                <tr>
                                  <th width="20%">Nama Shipyard*</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">Samudara Indonesia, PT</td>
                                  <th width="20%">PIC*</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">Nurul Akbar</td>
                                </tr>
                                <tr>
                                  <th width="20%">Jenis Pekerjaan*</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">General Overhaul</td>
                                  <th width="20%">Estimasi Budget</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">Rp. 256.000.000,-</td>
                                </tr>
                                <tr>
                                  <th width="20%">TA. Shipyard*</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">07/06/2023</td>
                                  <th width="20%">Floating Repair</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">15/06/2023</td>
                                </tr>
                                <tr>
                                  <th width="20%">Upslip*</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">04/07/2023</td>
                                  <th width="20%">Downslip</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">30/09/2023</td>
                                </tr>
                                <tr>
                                  <th width="20%">Sea Trial</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">27 Oktober 2023</td>
                                  <th width="20%">Delivery</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">31 Oktober 2023</td>
                                </tr>
                                <tr>
                                  <th width="20%">Waiting Space</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">5 Hari</td>
                                  <th width="20%">Target Days</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">60 Hari</td>
                                </tr>
                                <tr>
                                  <th width="20%">Estimasi Tonase</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">8.9</td>
                                  <th width="20%">Dokumen Shipyard</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">
                                    <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                      <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                      <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                    </ul>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </fieldset>
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Docking Job</legend>
                            <b-alert :show="true" variant="primary">
                              <div class="iq-alert-icon">
                                <i class="fa fa-percent"></i>
                              </div>
                              <div class="iq-alert-text">Docking Job Category <b>100%</b></div>
                            </b-alert>

                            <a href="#" v-b-toggle.jobCat-1>
                              <b-alert :show="true" class="text-white bg-primary">
                                <div class="iq-alert-icon">
                                  <i class="fas fa-tasks"></i>
                                </div>
                                <div class="iq-alert-text">Docking / Undocking <b>(2 Jobs) - 30%</b></div>
                                <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                  <i class="far fa-hand-pointer"></i> Click Here
                                </a>
                              </b-alert>
                            </a>
                            <b-collapse id="jobCat-1" accordion="my-accordion" role="tabpanel">
                              <b-card-body>
                                <b-form-group label="Persentase Job Category*" label-for="percent_job_category">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text bg-primary" id="percent_job_category">%</span>
                                    </div>
                                    <input type="text" class="form-control" id="percent_job_category">
                                  </div>
                                </b-form-group>
                                <b-alert :show="true" variant="danger">
                                  <div class="iq-alert-icon">
                                    <i class="fa fa-percent"></i>
                                  </div>
                                  <div class="iq-alert-text">Docking Job <b>100%</b></div>
                                </b-alert>
                                <div class="accordion" role="tablist">
                                  <b-card no-body >
                                    <a href="#" v-b-toggle.job-1-1>
                                      <b-alert :show="true" class="text-white bg-success">
                                        <div class="iq-alert-icon">
                                          <i class="fas fa-clipboard-list"></i>
                                        </div>
                                        <div class="iq-alert-text"><b>Upslip</b> - Persentase Pekerjaan <b>20% (Finish)</b></div>
                                        <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                          <i class="far fa-hand-pointer"></i> Click Here
                                        </a>
                                      </b-alert>
                                    </a>
                                    <b-collapse id="job-1-1" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Upslip</legend>
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Start Date</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-10</td>
                                              <th width="20%">Est. Finish</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-15</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Bobot Pekerjaan</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">
                                                <b-form-group>
                                                  <div class="input-group">
                                                    <div class="input-group-prepend">
                                                      <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                    </div>
                                                    <input type="text" class="form-control" id="bobot_pekerjaan">
                                                  </div>
                                                </b-form-group>
                                              </td>
                                              <th width="20%">Dokumen</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Created By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Nurul Akbar</td>
                                              <th width="20%">Review By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th class="align-top">Notes</th>
                                              <td class="text-center align-top">:</td>
                                              <td colspan="4">
                                                Check water tubes/plates and clean/free from clogged.<br>
                                                Check  from any corrosion <br>
                                                Check  zink anodes,  replace when necessary<br>
                                                Check  leakage after assembly
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Internal Man Power</legend>
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="50%" class="pl-3">Agung Tri Laksono</th>
                                              <td width="50%">First Engineer</td>
                                            </tr>
                                            <tr>
                                              <th width="50%" class="pl-3">Nurul Akbar</th>
                                              <td width="50%">Second Engineer</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                        <table class="table table-striped">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Nama Kontraktor </th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">PT. CAHAYA PELAYARAN</td>
                                              <th width="20%">Executive Engineer</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Penawaran</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">328.000.000</td>
                                              <th width="20%">Penawaran Nego</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">325.000.000</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Notes</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td colspan="4">Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Document</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td colspan="4">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #2</legend>
                                        <table class="table table-striped">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Nama Kontraktor </th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">PT. INTAN BAHARI</td>
                                              <th width="20%">Executive Engineer</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Heri Budianto</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Penawaran</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">178.000.000</td>
                                              <th width="20%">Penawaran Nego</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">175.000.000</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Notes</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td colspan="4">Perbaikan lambung</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Document</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td colspan="4">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                    </b-collapse>
                                  </b-card>
                                  <b-card no-body >
                                    <a href="#" v-b-toggle.job-1-2>
                                      <b-alert :show="true" class="text-white bg-info">
                                        <div class="iq-alert-icon">
                                          <i class="fas fa-clipboard-list"></i>
                                        </div>
                                        <div class="iq-alert-text"><b>Downslip</b> - Persentase Pekerjaan <b>20% (Progress)</b></div>
                                        <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                          <i class="far fa-hand-pointer"></i> Click Here
                                        </a>
                                      </b-alert>
                                    </a>
                                    <b-collapse id="job-1-2" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Downslip</legend>
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Start Date</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-10</td>
                                              <th width="20%">Est. Finish</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-15</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Bobot Pekerjaan</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">
                                                <div class="input-group">
                                                  <div class="input-group-prepend">
                                                    <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                  </div>
                                                  <input type="text" class="form-control" id="bobot_pekerjaan">
                                                </div>
                                              </td>
                                              <th width="20%">Dokumen</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Created By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Nurul Akbar</td>
                                              <th width="20%">Review By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th class="align-top">Notes</th>
                                              <td class="text-center align-top">:</td>
                                              <td colspan="4">
                                                Check water tubes/plates and clean/free from clogged.<br>
                                                Check  from any corrosion <br>
                                                Check  zink anodes,  replace when necessary<br>
                                                Check  leakage after assembly
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Internal Man Power</legend>
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="50%" class="pl-3">Agung Tri Laksono</th>
                                              <td width="50%">First Engineer</td>
                                            </tr>
                                            <tr>
                                              <th width="50%" class="pl-3">Nurul Akbar</th>
                                              <td width="50%">Second Engineer</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                        <table class="table table-striped">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Nama Kontraktor </th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">PT. CAHAYA PELAYARAN</td>
                                              <th width="20%">Executive Engineer</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Penawaran</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">328.000.000</td>
                                              <th width="20%">Penawaran Nego</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">325.000.000</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Notes</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td colspan="4">Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Document</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td colspan="4">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                    </b-collapse>
                                  </b-card>
                                </div>
                              </b-card-body>
                            </b-collapse>

                            <a href="#" v-b-toggle.jobCat-2>
                              <b-alert :show="true" class="text-white bg-primary">
                                <div class="iq-alert-icon">
                                  <i class="fas fa-tasks"></i>
                                </div>
                                <div class="iq-alert-text">Sand Blasting <b>(4 Jobs) - 70%</b></div>
                                <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                  <i class="far fa-hand-pointer"></i> Click Here
                                </a>
                              </b-alert>
                            </a>
                            <b-collapse id="jobCat-2" accordion="my-accordion" role="tabpanel">
                              <b-card-body>
                                <b-form-group label="Persentase Job Category*" label-for="percent_job_category">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text bg-primary" id="percent_job_category">%</span>
                                    </div>
                                    <input type="text" class="form-control" id="percent_job_category">
                                  </div>
                                </b-form-group>
                                <b-alert :show="true" variant="danger">
                                  <div class="iq-alert-icon">
                                    <i class="fa fa-percent"></i>
                                  </div>
                                  <div class="iq-alert-text">Docking Job <b>100%</b></div>
                                </b-alert>
                                <div class="accordion" role="tablist">
                                  <b-card no-body >
                                    <a href="#" v-b-toggle.job-2-1>
                                      <b-alert :show="true" class="text-white bg-success">
                                        <div class="iq-alert-icon">
                                          <i class="fas fa-clipboard-list"></i>
                                        </div>
                                        <div class="iq-alert-text"><b>Scraping Teripting di Lambung & Lunas</b> - Persentase Pekerjaan <b>20% (Finish)</b></div>
                                        <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                          <i class="far fa-hand-pointer"></i> Click Here
                                        </a>
                                      </b-alert>
                                    </a>
                                    <b-collapse id="job-2-1" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Scraping Teripting di Lambung & Lunas</legend>
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Start Date</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-10</td>
                                              <th width="20%">Est. Finish</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-15</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Bobot Pekerjaan</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">
                                                <div class="input-group">
                                                  <div class="input-group-prepend">
                                                    <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                  </div>
                                                  <input type="text" class="form-control" id="bobot_pekerjaan">
                                                </div>
                                              </td>
                                              <th width="20%">Dokumen</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Created By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Nurul Akbar</td>
                                              <th width="20%">Review By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th class="align-top">Notes</th>
                                              <td class="text-center align-top">:</td>
                                              <td colspan="4">
                                                Check water tubes/plates and clean/free from clogged.<br>
                                                Check  from any corrosion <br>
                                                Check  zink anodes,  replace when necessary<br>
                                                Check  leakage after assembly
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Internal Man Power</legend>
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="50%" class="pl-3">Agung Tri Laksono</th>
                                              <td width="50%">First Engineer</td>
                                            </tr>
                                            <tr>
                                              <th width="50%" class="pl-3">Nurul Akbar</th>
                                              <td width="50%">Second Engineer</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                        <table class="table table-striped">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Nama Kontraktor </th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">PT. CAHAYA PELAYARAN</td>
                                              <th width="20%">Executive Engineer</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Penawaran</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">328.000.000</td>
                                              <th width="20%">Penawaran Nego</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">325.000.000</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Notes</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td colspan="4">Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Document</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td colspan="4">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                    </b-collapse>
                                  </b-card>
                                  <b-card no-body >
                                    <a href="#" v-b-toggle.job-2-2>
                                      <b-alert :show="true" class="text-white bg-success">
                                        <div class="iq-alert-icon">
                                          <i class="fas fa-clipboard-list"></i>
                                        </div>
                                        <div class="iq-alert-text"><b>Pencucian Lambung dengan Air Tawar</b> - Persentase Pekerjaan <b>20% (Finish)</b></div>
                                        <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                          <i class="far fa-hand-pointer"></i> Click Here
                                        </a>
                                      </b-alert>
                                    </a>
                                    <b-collapse id="job-2-2" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Pencucian Lambung dengan Air Tawar</legend>
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Start Date</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-10</td>
                                              <th width="20%">Est. Finish</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-15</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Bobot Pekerjaan</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">
                                                <div class="input-group">
                                                  <div class="input-group-prepend">
                                                    <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                  </div>
                                                  <input type="text" class="form-control" id="bobot_pekerjaan">
                                                </div>
                                              </td>
                                              <th width="20%">Dokumen</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Created By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Nurul Akbar</td>
                                              <th width="20%">Review By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th class="align-top">Notes</th>
                                              <td class="text-center align-top">:</td>
                                              <td colspan="4">
                                                Check water tubes/plates and clean/free from clogged.<br>
                                                Check  from any corrosion <br>
                                                Check  zink anodes,  replace when necessary<br>
                                                Check  leakage after assembly
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Internal Man Power</legend>
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="50%" class="pl-3">Agung Tri Laksono</th>
                                              <td width="50%">First Engineer</td>
                                            </tr>
                                            <tr>
                                              <th width="50%" class="pl-3">Nurul Akbar</th>
                                              <td width="50%">Second Engineer</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                        <table class="table table-striped">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Nama Kontraktor </th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">PT. CAHAYA PELAYARAN</td>
                                              <th width="20%">Executive Engineer</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Penawaran</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">328.000.000</td>
                                              <th width="20%">Penawaran Nego</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">325.000.000</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Notes</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td colspan="4">Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Document</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td colspan="4">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                    </b-collapse>
                                  </b-card>
                                  <b-card no-body >
                                    <a href="#" v-b-toggle.job-2-3>
                                      <b-alert :show="true" class="text-white bg-info">
                                        <div class="iq-alert-icon">
                                          <i class="fas fa-clipboard-list"></i>
                                        </div>
                                        <div class="iq-alert-text"><b>Sand Blasting Lunas</b> - Persentase Pekerjaan <b>20% (Progress)</b></div>
                                        <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                          <i class="far fa-hand-pointer"></i> Click Here
                                        </a>
                                      </b-alert>
                                    </a>
                                    <b-collapse id="job-2-3" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sand Blasting Lunas</legend>
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Start Date</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-10</td>
                                              <th width="20%">Est. Finish</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-15</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Bobot Pekerjaan</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">
                                                <b-form-group>
                                                  <div class="input-group">
                                                    <div class="input-group-prepend">
                                                      <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                    </div>
                                                    <input type="text" class="form-control" id="bobot_pekerjaan">
                                                  </div>
                                                </b-form-group>
                                              </td>
                                              <th width="20%">Dokumen</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Created By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Nurul Akbar</td>
                                              <th width="20%">Review By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th class="align-top">Notes</th>
                                              <td class="text-center align-top">:</td>
                                              <td colspan="4">
                                                Check water tubes/plates and clean/free from clogged.<br>
                                                Check  from any corrosion <br>
                                                Check  zink anodes,  replace when necessary<br>
                                                Check  leakage after assembly
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Internal Man Power</legend>
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="50%" class="pl-3">Agung Tri Laksono</th>
                                              <td width="50%">First Engineer</td>
                                            </tr>
                                            <tr>
                                              <th width="50%" class="pl-3">Nurul Akbar</th>
                                              <td width="50%">Second Engineer</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                        <table class="table table-striped">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Nama Kontraktor </th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">PT. CAHAYA PELAYARAN</td>
                                              <th width="20%">Executive Engineer</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Penawaran</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">328.000.000</td>
                                              <th width="20%">Penawaran Nego</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">325.000.000</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Notes</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td colspan="4">Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Document</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td colspan="4">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                    </b-collapse>
                                  </b-card>
                                  <b-card no-body >
                                    <a href="#" v-b-toggle.job-2-4>
                                      <b-alert :show="true" class="text-white bg-warning">
                                        <div class="iq-alert-icon">
                                          <i class="fas fa-clipboard-list"></i>
                                        </div>
                                        <div class="iq-alert-text"><b>Sand Blasting Deck & Bullwark</b> - Persentase Pekerjaan <b>20% (Review)</b></div>
                                        <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                          <i class="far fa-hand-pointer"></i> Click Here
                                        </a>
                                      </b-alert>
                                    </a>
                                    <b-collapse id="job-2-4" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sand Blasting Lunas</legend>
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Start Date</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-10</td>
                                              <th width="20%">Est. Finish</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-15</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Bobot Pekerjaan</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">
                                                <b-form-group>
                                                  <div class="input-group">
                                                    <div class="input-group-prepend">
                                                      <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                    </div>
                                                    <input type="text" class="form-control" id="bobot_pekerjaan">
                                                  </div>
                                                </b-form-group>
                                              </td>
                                              <th width="20%">Dokumen</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Created By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Nurul Akbar</td>
                                              <th width="20%">Review By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th class="align-top">Notes</th>
                                              <td class="text-center align-top">:</td>
                                              <td colspan="4">
                                                Check water tubes/plates and clean/free from clogged.<br>
                                                Check  from any corrosion <br>
                                                Check  zink anodes,  replace when necessary<br>
                                                Check  leakage after assembly
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Internal Man Power</legend>
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="50%" class="pl-3">Agung Tri Laksono</th>
                                              <td width="50%">First Engineer</td>
                                            </tr>
                                            <tr>
                                              <th width="50%" class="pl-3">Nurul Akbar</th>
                                              <td width="50%">Second Engineer</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                        <table class="table table-striped">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Nama Kontraktor </th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">PT. CAHAYA PELAYARAN</td>
                                              <th width="20%">Executive Engineer</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Penawaran</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">328.000.000</td>
                                              <th width="20%">Penawaran Nego</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">325.000.000</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Notes</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td colspan="4">Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Document</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td colspan="4">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                    </b-collapse>
                                  </b-card>
                                  <b-card no-body >
                                    <a href="#" v-b-toggle.job-2-5>
                                      <b-alert :show="true" class="text-white bg-danger">
                                        <div class="iq-alert-icon">
                                          <i class="fas fa-clipboard-list"></i>
                                        </div>
                                        <div class="iq-alert-text"><b>Sand Blasting Main Deck</b> - Persentase Pekerjaan <b>20% (Add Job)</b></div>
                                        <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                          <i class="far fa-hand-pointer"></i> Click Here
                                        </a>
                                      </b-alert>
                                    </a>
                                    <b-collapse id="job-2-5" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sand Blasting Main Deck</legend>
                                        <b-row class="mt-3">
                                          <b-col md="3">
                                            <b-form-group label="Est. Start*" label-for="est_start">
                                              <b-form-input type="date" id="est_start"></b-form-input>
                                              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Est. Finish *" label-for="end">
                                              <b-form-input type="date" id="end"></b-form-input>
                                              <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group>
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                </div>
                                                <input type="text" class="form-control" id="bobot_pekerjaan">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="3">
                                            <b-form-group label="Attachment Job" label-for="attachment_job">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File* : <b-badge variant="info">.jpg/.png/.xlx/.doc/.pdf</b-badge><br>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                          <b-col md="12">
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="3" placeholder="Sand Blasting Deck & Bullwark"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                        </b-row>
                                      </fieldset>
                                      <b-row>
                                        <b-col md="12">
                                          <b-form-group label="Internal Man Power" label-for="internal_man_power">
                                            <template v-for="(item,index) in text">
                                              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                <div class="custom-switch-inner">
                                                  <p class="mb-0"> {{ item.label }} </p>
                                                  <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                  <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                  </label>
                                                </div>
                                              </div>
                                            </template>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Internal Man Power</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                              <b-form-input type="text" id="nama_internal_man_power" placeholder="Nurul Akbar"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Jabatan *" label-for="jabatan">
                                              <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                <template v-slot:first>
                                                <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                </template>
                                              </b-form-select>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                              <b-form-input type="text" id="nama_internal_man_power" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Jabatan *" label-for="jabatan">
                                              <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                <template v-slot:first>
                                                <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                </template>
                                              </b-form-select>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-button variant="success" class="mt-3"><i class="fa fa-users"></i> ADD INTERNAL MAN POWER</b-button>
                                      </fieldset>
                                      <b-row>
                                        <b-col md="12">
                                          <b-form-group label="Sub Kontraktor" label-for="subkontraktor">
                                            <template v-for="(item,index) in text">
                                              <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                <div class="custom-switch-inner">
                                                  <p class="mb-0"> {{ item.label }} </p>
                                                  <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                  <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                  </label>
                                                </div>
                                              </div>
                                            </template>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                              <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                              <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Penawaran" label-for="penawaran">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Attachment Shipyard" label-for="doc_shipyard">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #2</legend>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                              <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                              <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Penawaran" label-for="penawaran">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                </div>
                                                <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                              </div>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                        <b-form-row>
                                          <b-col>
                                            <b-form-group label="Notes" label-for="notes">
                                              <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                            </b-form-group>
                                          </b-col>
                                          <b-col>
                                            <b-form-group label="Attachment Shipyard" label-for="doc_shipyard">
                                              <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                              </div>
                                              <small class="text-muted">
                                                File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                              </small>
                                            </b-form-group>
                                          </b-col>
                                        </b-form-row>
                                      </fieldset>
                                      <b-button variant="success" class="mb-3"><i class="fa fa-users"></i> ADD SUB KONTRAKTOR</b-button>
                                    </b-collapse>
                                  </b-card>
                                </div>
                              </b-card-body>
                            </b-collapse>

                            <b-row>
                              <b-col md="12" class="mt-3">
                                <b-card-text class="pull-right"><small class="text-muted">Sign * : <b-badge variant="warning">Mandatory (Must be Input)</b-badge></small></b-card-text>
                                <br><br>
                                <b-button type="submit" variant="danger" class="pull-right"><i class="fa fa-close"></i> Reset Form</b-button>
                                <b-button type="submit" variant="primary" class="pull-right mr-1"><i class="fa fa-save"></i> Save</b-button>
                              </b-col>
                            </b-row>
                          </fieldset>
                        </b-col>
                      </b-row>
                    </template>
                  </b-sidebar>
                  <!-- SLIDE - FORM UPDATE DOCKING END -->

                  <!-- SLIDE - FORM APPROVE DOCKING -->
                  <b-sidebar
                    v-model="slideApproveDocking"
                    id="approve_docking"
                    title="FORM APPROVE DOCKING - TB. TAVIA 1"
                    width="100%"
                    backdrop-variant="dark"
                    bg-variant="white"
                    right
                    backdrop
                    shadow>
                    <template #default="{hide}">
                      <b-row class="m-3">
                        <b-col cols="4">
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Job List</legend>
                            <b-form-input id="job_name" type="text" placeholder="Search Job Name"></b-form-input>
                            <v-jstree :data="tree" show-checkbox allow-batch whole-row :item-events="itemEvents" @item-click="itemClick" class="mt-3">
                              <template slot-scope="_">
                                <div style="display: inherit; width: 200px" @click.ctrl="customItemClickWithCtrl">
                                  <i :class="_.vm.themeIconClasses" role="presentation" v-if="!_.model.loading"></i>
                                  {{_.model.text}}
                                </div>
                              </template>
                            </v-jstree>
                          </fieldset>
                        </b-col>
                        <b-col cols="8">
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Docking Info</legend>
                            <table class="table table-striped">
                              <tbody>
                                <tr>
                                  <th width="20%">Nama Shipyard</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">Samudara Indonesia, PT</td>
                                  <th width="20%">PIC</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">Nurul Akbar</td>
                                </tr>
                                <tr>
                                  <th width="20%">Jenis Pekerjaan</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">General Overhaul</td>
                                  <th width="20%">Estimasi Budget</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">Rp. 256.000.000,-</td>
                                </tr>
                                <tr>
                                  <th width="20%">TA. Shipyard*</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">
                                    <b-form-input type="date" id="ta_shipyard"></b-form-input>
                                  </td>
                                  <th width="20%">Floating Repair</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">
                                    <b-form-input type="date" id="upslip"></b-form-input>
                                  </td>
                                </tr>
                                <tr>
                                  <th width="20%">Upslip</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">
                                    <b-form-input type="date" id="upslip"></b-form-input>
                                  </td>
                                  <th width="20%">Downslip</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">
                                    <b-form-input type="date" id="est_downslip"></b-form-input>
                                  </td>
                                </tr>
                                <tr>
                                  <th width="20%">Sea Trial</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">
                                    <b-form-input type="date" id="est_sea_trial"></b-form-input>
                                  </td>
                                  <th width="20%">Delivery</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">
                                    <b-form-input type="date" id="est_delivery"></b-form-input>
                                  </td>
                                </tr>
                                <tr>
                                  <th width="20%">Waiting Space</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">5 Hari</td>
                                  <th width="20%">Target Days</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">60 Hari</td>
                                </tr>
                                <tr>
                                  <th width="20%">Estimasi Tonase</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">8.9</td>
                                  <th width="20%">Dokumen Shipyard</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">
                                    <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                      <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                      <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                    </ul>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </fieldset>
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Docking Job</legend>
                            <b-alert :show="true" variant="primary">
                              <div class="iq-alert-icon">
                                <i class="fa fa-percent"></i>
                              </div>
                              <div class="iq-alert-text">Docking Job Category <b>100%</b></div>
                            </b-alert>

                            <a href="#" v-b-toggle.jobCat-1>
                              <b-alert :show="true" class="text-white bg-primary">
                                <div class="iq-alert-icon">
                                  <i class="fas fa-tasks"></i>
                                </div>
                                <div class="iq-alert-text">Docking / Undocking <b>(2 Jobs) - 30%</b></div>
                                <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                  <i class="far fa-hand-pointer"></i> Click Here
                                </a>
                              </b-alert>
                            </a>
                            <b-collapse id="jobCat-1" accordion="my-accordion" role="tabpanel">
                              <b-card-body>
                                <b-alert :show="true" variant="danger">
                                  <div class="iq-alert-icon">
                                    <i class="fa fa-percent"></i>
                                  </div>
                                  <div class="iq-alert-text">Docking Job <b>100%</b></div>
                                </b-alert>
                                <div class="accordion" role="tablist">
                                  <b-card no-body >
                                    <a href="#" v-b-toggle.job-1-1>
                                      <b-alert :show="true" class="text-white bg-warning">
                                        <div class="iq-alert-icon">
                                          <i class="fas fa-clipboard-list"></i>
                                        </div>
                                        <div class="iq-alert-text"><b>Upslip</b> - Persentase Pekerjaan <b>50% (Review)</b></div>
                                        <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                          <i class="far fa-hand-pointer"></i> Click Here
                                        </a>
                                      </b-alert>
                                    </a>
                                    <b-collapse id="job-1-1" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Upslip</legend>
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Start Date</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-10</td>
                                              <th width="20%">Est. Finish</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-15</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Bobot Pekerjaan</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">50%</td>
                                              <th width="20%">Dokumen</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Created By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Nurul Akbar</td>
                                              <th width="20%">Review By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th class="align-top">Notes</th>
                                              <td class="text-center align-top">:</td>
                                              <td colspan="4">
                                                Check water tubes/plates and clean/free from clogged.<br>
                                                Check  from any corrosion <br>
                                                Check  zink anodes,  replace when necessary<br>
                                                Check  leakage after assembly
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Internal Man Power</legend>
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="50%" class="pl-3">Agung Tri Laksono</th>
                                              <td width="50%">First Engineer</td>
                                            </tr>
                                            <tr>
                                              <th width="50%" class="pl-3">Nurul Akbar</th>
                                              <td width="50%">Second Engineer</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                        <table class="table table-striped">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Nama Kontraktor </th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">PT. CAHAYA PELAYARAN</td>
                                              <th width="20%">Executive Engineer</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Penawaran</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">328.000.000</td>
                                              <th width="20%">Penawaran Nego</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">325.000.000</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Notes</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td colspan="4">Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Document</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td colspan="4">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #2</legend>
                                        <table class="table table-striped">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Nama Kontraktor </th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">PT. INTAN BAHARI</td>
                                              <th width="20%">Executive Engineer</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Heri Budianto</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Penawaran</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">178.000.000</td>
                                              <th width="20%">Penawaran Nego</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">175.000.000</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Notes</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td colspan="4">Perbaikan lambung</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Document</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td colspan="4">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                      <b-alert :show="true" variant="success">
                                        <div class="iq-alert-icon">
                                          <i class="fa fa-thumbs-up"></i>
                                        </div>
                                        <div class="iq-alert-text">Upslip <b>APPROVED</b></div>
                                      </b-alert>
                                      <b-alert :show="true" variant="danger">
                                        <div class="iq-alert-icon">
                                          <i class="fa fa-close"></i>
                                        </div>
                                        <div class="iq-alert-text">Upslip <b>REJECT</b></div>
                                      </b-alert>
                                      <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>
                                      <b-button type="submit" variant="primary" class="float-right"><i class="fa fa-thumbs-up"></i> APPROVE</b-button>
                                    </b-collapse>
                                  </b-card>
                                  <b-card no-body >
                                    <a href="#" v-b-toggle.job-1-2>
                                      <b-alert :show="true" class="text-white bg-warning">
                                        <div class="iq-alert-icon">
                                          <i class="fas fa-clipboard-list"></i>
                                        </div>
                                        <div class="iq-alert-text"><b>Downslip</b> - Persentase Pekerjaan <b>50% (Review)</b></div>
                                        <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                          <i class="far fa-hand-pointer"></i> Click Here
                                        </a>
                                      </b-alert>
                                    </a>
                                    <b-collapse id="job-1-2" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Downslip</legend>
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Start Date</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-10</td>
                                              <th width="20%">Est. Finish</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-15</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Bobot Pekerjaan</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">50%</td>
                                              <th width="20%">Dokumen</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Created By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Nurul Akbar</td>
                                              <th width="20%">Review By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th class="align-top">Notes</th>
                                              <td class="text-center align-top">:</td>
                                              <td colspan="4">
                                                Check water tubes/plates and clean/free from clogged.<br>
                                                Check  from any corrosion <br>
                                                Check  zink anodes,  replace when necessary<br>
                                                Check  leakage after assembly
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Internal Man Power</legend>
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="50%" class="pl-3">Agung Tri Laksono</th>
                                              <td width="50%">First Engineer</td>
                                            </tr>
                                            <tr>
                                              <th width="50%" class="pl-3">Nurul Akbar</th>
                                              <td width="50%">Second Engineer</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                        <table class="table table-striped">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Nama Kontraktor </th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">PT. CAHAYA PELAYARAN</td>
                                              <th width="20%">Executive Engineer</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Penawaran</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">328.000.000</td>
                                              <th width="20%">Penawaran Nego</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">325.000.000</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Notes</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td colspan="4">Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Document</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td colspan="4">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                      <b-alert :show="true" variant="success">
                                        <div class="iq-alert-icon">
                                          <i class="fa fa-thumbs-up"></i>
                                        </div>
                                        <div class="iq-alert-text">Upslip <b>APPROVED</b></div>
                                      </b-alert>
                                      <b-alert :show="true" variant="danger">
                                        <div class="iq-alert-icon">
                                          <i class="fa fa-close"></i>
                                        </div>
                                        <div class="iq-alert-text">Upslip <b>REJECT</b></div>
                                      </b-alert>
                                      <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>
                                      <b-button type="submit" variant="primary" class="float-right"><i class="fa fa-thumbs-up"></i> APPROVE</b-button>
                                    </b-collapse>
                                  </b-card>
                                </div>
                              </b-card-body>
                            </b-collapse>

                            <a href="#" v-b-toggle.jobCat-2>
                              <b-alert :show="true" class="text-white bg-primary">
                                <div class="iq-alert-icon">
                                  <i class="fas fa-tasks"></i>
                                </div>
                                <div class="iq-alert-text">Sand Blasting <b>(4 Jobs) - 70%</b></div>
                                <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                  <i class="far fa-hand-pointer"></i> Click Here
                                </a>
                              </b-alert>
                            </a>
                            <b-collapse id="jobCat-2" accordion="my-accordion" role="tabpanel">
                              <b-card-body>
                                <b-alert :show="true" variant="danger">
                                  <div class="iq-alert-icon">
                                    <i class="fa fa-percent"></i>
                                  </div>
                                  <div class="iq-alert-text">Docking Job <b>100%</b></div>
                                </b-alert>
                                <div class="accordion" role="tablist">
                                  <b-card no-body >
                                    <a href="#" v-b-toggle.job-2-1>
                                      <b-alert :show="true" class="text-white bg-warning">
                                        <div class="iq-alert-icon">
                                          <i class="fas fa-clipboard-list"></i>
                                        </div>
                                        <div class="iq-alert-text"><b>Scraping Teripting di Lambung & Lunas</b> - Persentase Pekerjaan <b>25% (Review)</b></div>
                                        <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                          <i class="far fa-hand-pointer"></i> Click Here
                                        </a>
                                      </b-alert>
                                    </a>
                                    <b-collapse id="job-2-1" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Scraping Teripting di Lambung & Lunas</legend>
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Start Date</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-10</td>
                                              <th width="20%">Est. Finish</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-15</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Bobot Pekerjaan</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">50%</td>
                                              <th width="20%">Dokumen</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Created By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Nurul Akbar</td>
                                              <th width="20%">Review By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th class="align-top">Notes</th>
                                              <td class="text-center align-top">:</td>
                                              <td colspan="4">
                                                Check water tubes/plates and clean/free from clogged.<br>
                                                Check  from any corrosion <br>
                                                Check  zink anodes,  replace when necessary<br>
                                                Check  leakage after assembly
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Internal Man Power</legend>
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="50%" class="pl-3">Agung Tri Laksono</th>
                                              <td width="50%">First Engineer</td>
                                            </tr>
                                            <tr>
                                              <th width="50%" class="pl-3">Nurul Akbar</th>
                                              <td width="50%">Second Engineer</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                        <table class="table table-striped">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Nama Kontraktor </th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">PT. CAHAYA PELAYARAN</td>
                                              <th width="20%">Executive Engineer</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Penawaran</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">328.000.000</td>
                                              <th width="20%">Penawaran Nego</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">325.000.000</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Notes</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td colspan="4">Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Document</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td colspan="4">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                      <b-alert :show="true" variant="success">
                                        <div class="iq-alert-icon">
                                          <i class="fa fa-thumbs-up"></i>
                                        </div>
                                        <div class="iq-alert-text">Upslip <b>APPROVED</b></div>
                                      </b-alert>
                                      <b-alert :show="true" variant="danger">
                                        <div class="iq-alert-icon">
                                          <i class="fa fa-close"></i>
                                        </div>
                                        <div class="iq-alert-text">Upslip <b>REJECT</b></div>
                                      </b-alert>
                                      <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>
                                      <b-button type="submit" variant="primary" class="float-right"><i class="fa fa-thumbs-up"></i> APPROVE</b-button>
                                    </b-collapse>
                                  </b-card>
                                  <b-card no-body >
                                    <a href="#" v-b-toggle.job-2-2>
                                      <b-alert :show="true" class="text-white bg-warning">
                                        <div class="iq-alert-icon">
                                          <i class="fas fa-clipboard-list"></i>
                                        </div>
                                        <div class="iq-alert-text"><b>Pencucian Lambung dengan Air Tawar</b> - Persentase Pekerjaan <b>25% (Review)</b></div>
                                        <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                          <i class="far fa-hand-pointer"></i> Click Here
                                        </a>
                                      </b-alert>
                                    </a>
                                    <b-collapse id="job-2-2" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Pencucian Lambung dengan Air Tawar</legend>
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Start Date</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-10</td>
                                              <th width="20%">Est. Finish</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-15</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Bobot Pekerjaan</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">50%</td>
                                              <th width="20%">Dokumen</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Created By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Nurul Akbar</td>
                                              <th width="20%">Review By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th class="align-top">Notes</th>
                                              <td class="text-center align-top">:</td>
                                              <td colspan="4">
                                                Check water tubes/plates and clean/free from clogged.<br>
                                                Check  from any corrosion <br>
                                                Check  zink anodes,  replace when necessary<br>
                                                Check  leakage after assembly
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Internal Man Power</legend>
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="50%" class="pl-3">Agung Tri Laksono</th>
                                              <td width="50%">First Engineer</td>
                                            </tr>
                                            <tr>
                                              <th width="50%" class="pl-3">Nurul Akbar</th>
                                              <td width="50%">Second Engineer</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                        <table class="table table-striped">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Nama Kontraktor </th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">PT. CAHAYA PELAYARAN</td>
                                              <th width="20%">Executive Engineer</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Penawaran</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">328.000.000</td>
                                              <th width="20%">Penawaran Nego</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">325.000.000</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Notes</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td colspan="4">Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Document</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td colspan="4">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                      <b-alert :show="true" variant="success">
                                        <div class="iq-alert-icon">
                                          <i class="fa fa-thumbs-up"></i>
                                        </div>
                                        <div class="iq-alert-text">Upslip <b>APPROVED</b></div>
                                      </b-alert>
                                      <b-alert :show="true" variant="danger">
                                        <div class="iq-alert-icon">
                                          <i class="fa fa-close"></i>
                                        </div>
                                        <div class="iq-alert-text">Upslip <b>REJECT</b></div>
                                      </b-alert>
                                      <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>
                                      <b-button type="submit" variant="primary" class="float-right"><i class="fa fa-thumbs-up"></i> APPROVE</b-button>
                                    </b-collapse>
                                  </b-card>
                                  <b-card no-body >
                                    <a href="#" v-b-toggle.job-2-3>
                                      <b-alert :show="true" class="text-white bg-warning">
                                        <div class="iq-alert-icon">
                                          <i class="fas fa-clipboard-list"></i>
                                        </div>
                                        <div class="iq-alert-text"><b>Sand Blasting Lunas</b> - Persentase Pekerjaan <b>25% (Review)</b></div>
                                        <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                          <i class="far fa-hand-pointer"></i> Click Here
                                        </a>
                                      </b-alert>
                                    </a>
                                    <b-collapse id="job-2-3" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sand Blasting Lunas</legend>
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Start Date</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-10</td>
                                              <th width="20%">Est. Finish</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-15</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Bobot Pekerjaan</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">50%</td>
                                              <th width="20%">Dokumen</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Created By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Nurul Akbar</td>
                                              <th width="20%">Review By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th class="align-top">Notes</th>
                                              <td class="text-center align-top">:</td>
                                              <td colspan="4">
                                                Check water tubes/plates and clean/free from clogged.<br>
                                                Check  from any corrosion <br>
                                                Check  zink anodes,  replace when necessary<br>
                                                Check  leakage after assembly
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Internal Man Power</legend>
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="50%" class="pl-3">Agung Tri Laksono</th>
                                              <td width="50%">First Engineer</td>
                                            </tr>
                                            <tr>
                                              <th width="50%" class="pl-3">Nurul Akbar</th>
                                              <td width="50%">Second Engineer</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                        <table class="table table-striped">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Nama Kontraktor </th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">PT. CAHAYA PELAYARAN</td>
                                              <th width="20%">Executive Engineer</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Penawaran</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">328.000.000</td>
                                              <th width="20%">Penawaran Nego</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">325.000.000</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Notes</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td colspan="4">Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Document</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td colspan="4">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                      <b-alert :show="true" variant="success">
                                        <div class="iq-alert-icon">
                                          <i class="fa fa-thumbs-up"></i>
                                        </div>
                                        <div class="iq-alert-text">Upslip <b>APPROVED</b></div>
                                      </b-alert>
                                      <b-alert :show="true" variant="danger">
                                        <div class="iq-alert-icon">
                                          <i class="fa fa-close"></i>
                                        </div>
                                        <div class="iq-alert-text">Upslip <b>REJECT</b></div>
                                      </b-alert>
                                      <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>
                                      <b-button type="submit" variant="primary" class="float-right"><i class="fa fa-thumbs-up"></i> APPROVE</b-button>
                                    </b-collapse>
                                  </b-card>
                                  <b-card no-body >
                                    <a href="#" v-b-toggle.job-2-4>
                                      <b-alert :show="true" class="text-white bg-warning">
                                        <div class="iq-alert-icon">
                                          <i class="fas fa-clipboard-list"></i>
                                        </div>
                                        <div class="iq-alert-text"><b>Sand Blasting Deck & Bullwark</b> - Persentase Pekerjaan <b>25% (Review)</b></div>
                                        <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                          <i class="far fa-hand-pointer"></i> Click Here
                                        </a>
                                      </b-alert>
                                    </a>
                                    <b-collapse id="job-2-4" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sand Blasting Deck & Bullwark</legend>
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Start Date</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-10</td>
                                              <th width="20%">Est. Finish</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">2022-06-15</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Bobot Pekerjaan</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">50%</td>
                                              <th width="20%">Dokumen</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Created By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Nurul Akbar</td>
                                              <th width="20%">Review By</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th class="align-top">Notes</th>
                                              <td class="text-center align-top">:</td>
                                              <td colspan="4">
                                                Check water tubes/plates and clean/free from clogged.<br>
                                                Check  from any corrosion <br>
                                                Check  zink anodes,  replace when necessary<br>
                                                Check  leakage after assembly
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Internal Man Power</legend>
                                        <table class="table table-striped mt-2">
                                          <tbody>
                                            <tr>
                                              <th width="50%" class="pl-3">Agung Tri Laksono</th>
                                              <td width="50%">First Engineer</td>
                                            </tr>
                                            <tr>
                                              <th width="50%" class="pl-3">Nurul Akbar</th>
                                              <td width="50%">Second Engineer</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                      <fieldset class="form-group border p-3">
                                        <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                        <table class="table table-striped">
                                          <tbody>
                                            <tr>
                                              <th width="20%">Nama Kontraktor </th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">PT. CAHAYA PELAYARAN</td>
                                              <th width="20%">Executive Engineer</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">Agung Tri Laksono</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Penawaran</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">328.000.000</td>
                                              <th width="20%">Penawaran Nego</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td width="25%">325.000.000</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Notes</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td colspan="4">Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan</td>
                                            </tr>
                                            <tr>
                                              <th width="20%">Document</th>
                                              <td width="5%" class="text-center">:</td>
                                              <td colspan="4">
                                                <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                </ul>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </fieldset>
                                      <b-alert :show="true" variant="success">
                                        <div class="iq-alert-icon">
                                          <i class="fa fa-thumbs-up"></i>
                                        </div>
                                        <div class="iq-alert-text">Upslip <b>APPROVED</b></div>
                                      </b-alert>
                                      <b-alert :show="true" variant="danger">
                                        <div class="iq-alert-icon">
                                          <i class="fa fa-close"></i>
                                        </div>
                                        <div class="iq-alert-text">Upslip <b>REJECT</b></div>
                                      </b-alert>
                                      <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>
                                      <b-button type="submit" variant="primary" class="float-right"><i class="fa fa-thumbs-up"></i> APPROVE</b-button>
                                    </b-collapse>
                                  </b-card>
                                </div>
                              </b-card-body>
                            </b-collapse>

                            <b-row>
                              <b-col md="12" class="mt-3">
                                <b-card-text class="pull-right"><small class="text-muted">Sign * : <b-badge variant="warning">Mandatory (Must be Input)</b-badge></small></b-card-text>
                                <br><br>
                                <b-button type="submit" variant="danger" class="pull-right"><i class="fa fa-close"></i> Cancel</b-button>
                                <b-button type="submit" variant="primary" class="pull-right mr-1"><i class="fa fa-save"></i> Approve</b-button>
                              </b-col>
                            </b-row>
                          </fieldset>
                        </b-col>
                      </b-row>
                    </template>
                  </b-sidebar>
                  <!-- SLIDE - FORM APPROVE DOCKING END -->


                  <!-- SLIDE - ACTIVITY -->
                  <b-sidebar
                    v-model="slideActivity"
                    id="activity"
                    title="UPDATE ACTIVITY"
                    width="100%"
                    backdrop-variant="dark"
                    bg-variant="white"
                    right
                    backdrop
                    shadow>
                    <template #default="{hide}">
                    <b-row class="m-3">
                      <b-col md="7">
                        <fieldset class="form-group border p-3">
                          <legend class="w-auto px-2 text-primary">Docking Info</legend>
                          <table class="table table-striped">
                            <tbody>
                              <tr>
                                <th width="20%">Nama Shipyard*</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">Samudara Indonesia, PT</td>
                                <th width="20%">PIC*</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">Nurul Akbar</td>
                              </tr>
                              <tr>
                                <th width="20%">Jenis Pekerjaan*</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">General Overhaul</td>
                                <th width="20%">Estimasi Budget</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">Rp. 256.000.000,-</td>
                              </tr>
                              <tr>
                                <th width="20%">TA. Shipyard*</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">07/06/2023</td>
                                <th width="20%">Floating Repair</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">15/06/2023</td>
                              </tr>
                              <tr>
                                <th width="20%">Upslip*</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">04/07/2023</td>
                                <th width="20%">Downslip</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">30/09/2023</td>
                              </tr>
                              <tr>
                                <th width="20%">Sea Trial</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">27 Oktober 2023</td>
                                <th width="20%">Delivery</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">31 Oktober 2023</td>
                              </tr>
                              <tr>
                                <th width="20%">Waiting Space</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">5 Hari</td>
                                <th width="20%">Target Days</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">60 Hari</td>
                              </tr>
                              <tr>
                                <th width="20%">Estimasi Tonase</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">8.9</td>
                                <th width="20%">Dokumen Shipyard</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">
                                  <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                  </ul>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </fieldset>
                        <fieldset class="form-group border p-3">
                          <legend class="w-auto px-2">Info Pekerjaan</legend>
                          <table class="table table-striped mt-2">
                            <tbody>
                              <tr>
                                <th width="20%">Start Date</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">2022-06-10</td>
                                <th width="20%">Est. Finish</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">2022-06-15</td>
                              </tr>
                              <tr>
                                <th width="20%">Bobot Pekerjaan</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">50%</td>
                                <th width="20%">Dokumen</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">
                                  <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                  </ul>
                                </td>
                              </tr>
                              <tr>
                                <th width="20%">Created By</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">Nurul Akbar</td>
                                <th width="20%">Review By</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">Agung Tri Laksono</td>
                              </tr>
                              <tr>
                                <th class="align-top">Notes</th>
                                <td class="text-center align-top">:</td>
                                <td colspan="4">
                                  Check water tubes/plates and clean/free from clogged.<br>
                                  Check  from any corrosion <br>
                                  Check  zink anodes,  replace when necessary<br>
                                  Check  leakage after assembly
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </fieldset>
                        <fieldset class="form-group border p-3">
                          <legend class="w-auto px-2">Internal Man Power</legend>
                          <table class="table table-striped table-sm">
                            <tbody>
                              <tr>
                                <th width="50%" class="pl-3">NAMA LENGKAP</th>
                                <th width="50%">JABATAN</th>
                              </tr>
                              <tr>
                                <td class="pl-3">Agung Tri Laksono</td>
                                <td>Manager Engineer</td>
                              </tr>
                              <tr>
                                <td class="pl-3">Nurul Akbar</td>
                                <td>Senior Engineer</td>
                              </tr>
                              <tr>
                                <td class="pl-3">Hanel Prilian</td>
                                <td>Engineer</td>
                              </tr>
                            </tbody>
                          </table>
                        </fieldset>
                        <fieldset class="form-group border p-3">
                          <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                          <table class="table table-striped">
                            <tbody>
                              <tr>
                                <th width="20%">Nama Kontraktor </th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">PT. CAHAYA PELAYARAN</td>
                                <th width="20%">Executive Engineer</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">Agung Tri Laksono</td>
                              </tr>
                              <tr>
                                <th width="20%">Penawaran</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">328.000.000</td>
                                <th width="20%">Penawaran Nego</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">325.000.000</td>
                              </tr>
                              <tr>
                                <th width="20%">Notes</th>
                                <td width="5%" class="text-center">:</td>
                                <td colspan="4">Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan</td>
                              </tr>
                              <tr>
                                <th width="20%">Document</th>
                                <td width="5%" class="text-center">:</td>
                                <td colspan="4">
                                  <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                  </ul>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </fieldset>
                        <fieldset class="form-group border p-3">
                          <legend class="w-auto px-2">Sub Kontraktor #2</legend>
                          <table class="table table-striped">
                            <tbody>
                              <tr>
                                <th width="20%">Nama Kontraktor </th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">PT. MARIBAYA</td>
                                <th width="20%">Executive Engineer</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">Suhendar</td>
                              </tr>
                              <tr>
                                <th width="20%">Penawaran</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">276.600.000</td>
                                <th width="20%">Penawaran Nego</th>
                                <td width="5%" class="text-center">:</td>
                                <td width="25%">275.000.000</td>
                              </tr>
                              <tr>
                                <th width="20%">Notes</th>
                                <td width="5%" class="text-center">:</td>
                                <td colspan="4">Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kiri</td>
                              </tr>
                              <tr>
                                <th width="20%">Document</th>
                                <td width="5%" class="text-center">:</td>
                                <td colspan="4">
                                  <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                  </ul>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </fieldset>
                      </b-col>
                      <b-col md="5">
                        <tab-content-item :active="true" id="power">
                          <tab-nav :tabs="true">
                            <tab-nav-items :active="true"  href="#activity" title="Activity" />
                            <tab-nav-items :active="false" href="#logs" title="Logs" />
                          </tab-nav>
                          <div id="myTabContent" class="tab-content">
                            <tab-content-item :active="true" id="activity">
                              <b-row>
                                <b-col md="12" class="mb-5">
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label="Select Docking Job" label-for="docking_job">
                                        <b-form-select plain v-model="selectedDockingJob" :options="docking_job">
                                          <template v-slot:first>
                                          <b-form-select-option :value="null">List Docking Job</b-form-select-option>
                                          </template>
                                        </b-form-select>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label-for="progress_bar"><h4 class="mb-2">Progrees : <b-badge variant="warning">80%</b-badge></h4>
                                        <b-progress :value="80" variant="success" max="100"></b-progress>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label="Start *" label-for="start">
                                        <b-form-input type="date" id="start"></b-form-input>
                                        <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="Progress Pekerjaan *" label-for="progress_pekerjaan">
                                        <div class="input-group">
                                          <div class="input-group-prepend">
                                            <span class="input-group-text bg-primary" id="progress_pekerjaan">%</span>
                                          </div>
                                          <input type="text" class="form-control" id="progress_pekerjaan" placeholder="80">
                                        </div>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="Report Progress" label-for="report_progress">
                                        <div class="custom-file">
                                          <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                          <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                        </div>
                                        <small class="text-muted">
                                          File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                          File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                        </small>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label="Notes" label-for="notes">
                                        <b-form-textarea id="description" rows="3" placeholder="Check water tubes/plates and clean/free from clogged."></b-form-textarea>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>

                                  <div class="form-group row align-items-center mt-3 m-0">
                                    <div class="d-flex flex-grow-1 align-items-center">
                                      <div class="send-panel">
                                        <label for="file" class="mb-0 iq-bg-primary rounded">
                                          <input type="file" id="file" style="display: none;">
                                          <a>
                                            <i class="ri-attachment-line"></i>
                                          </a>
                                        </label>
                                        <label class="ml-2 mb-0 iq-bg-primary rounded">
                                          <a href="javascript:void(0)">
                                            <i class="ri-gallery-line text-primary"></i>
                                          </a>
                                        </label>
                                      </div>
                                    </div>
                                    <div class="d-flex align-items-center">
                                      <div class="send-panel float-left">
                                        <div class="send-btn">
                                          <b-button variant="success"><i class="fa fa-check"></i> Finish</b-button>
                                          <b-button variant="primary" class="ml-2"><i class="fa fa-comment"></i> Update Activity</b-button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </b-col>
                                <b-col md="12">
                                  <ul class="iq-timeline" style="height:100%">
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                        <small>24 November 2019 15:45</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melakukan inspeksi pada kerusakan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/6.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/1.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Agung Tri Laksono</h6><br/>
                                        <small>23 November 2019 21:25</small>
                                        <div class="d-inline-block w-100">
                                          <p>Barang tiba di galangan</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Tri Leksono</h6><br/>
                                        <small>22 November 2019 10:12</small>
                                        <div class="d-inline-block w-100">
                                          <p>Menunggu sparepart tiba</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                        <small>21 November 2019 11:21</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melengkapi dokumen perbaikan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </div>
                                    </li>
                                  </ul>
                                </b-col>
                              </b-row>
                            </tab-content-item>

                            <tab-content-item :active="false" id="logs">
                              <b-row>
                                <b-col md="12">
                                  <ul class="iq-timeline" style="height:100%">
                                    <li>
                                      <div class="timeline-dots border-success"></div>
                                      <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                      <small>19 Oktober 2019 21:25</small>
                                      <div class="d-inline-block w-100">
                                        <p>Docking Upslip - Finish</p>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-dots border-success"></div>
                                      <h6 class="float-left mb-1">System</h6><br/>
                                      <small>19 Oktober 2019 18:30</small>
                                      <div class="d-inline-block w-100">
                                        <p>System mengirim email ke :
                                          <b-badge class="border border-primary text-primary ml-1" variant="none">aguuungtriilaksono@gmail.com</b-badge>
                                          <b-badge class="border border-primary text-primary ml-1" variant="none">nurulakbar93@gmail.com</b-badge>
                                        </p>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-dots border-success"></div>
                                      <h6 class="float-left mb-1">Agung Tri Laksono</h6><br/>
                                      <small>18 Oktober 2019 15:41</small>
                                      <div class="d-inline-block w-100">
                                        <p>Update Stage</p>
                                        <b-button variant="primary" class="mr-1">APPROVE</b-button>
                                        <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
                                        <b-button variant="info" class="ml-1">PROGRESS</b-button>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-dots border-success"></div>
                                      <h6 class="float-left mb-1">System</h6><br/>
                                      <small>19 Oktober 2019 18:30</small>
                                      <div class="d-inline-block w-100">
                                        <p>System mengirim email ke :
                                          <b-badge class="border border-primary text-primary ml-1" variant="none">aguuungtriilaksono@gmail.com</b-badge>
                                          <b-badge class="border border-primary text-primary ml-1" variant="none">nurulakbar93@gmail.com</b-badge>
                                        </p>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-dots border-success"></div>
                                      <h6 class="float-left mb-1">Agung Tri Laksono</h6><br/>
                                      <small>18 Oktober 2019 15:41</small>
                                      <div class="d-inline-block w-100">
                                        <p>Update Stage</p>
                                        <b-button variant="warning" class="mr-1">REVIEW</b-button>
                                        <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
                                        <b-button variant="primary" class="ml-1">APPROVE</b-button>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-dots border-success"></div>
                                      <h6 class="float-left mb-1">System</h6><br/>
                                      <small>19 Oktober 2019 18:30</small>
                                      <div class="d-inline-block w-100">
                                        <p>System mengirim email ke :
                                          <b-badge class="border border-primary text-primary ml-1" variant="none">aguuungtriilaksono@gmail.com</b-badge>
                                          <b-badge class="border border-primary text-primary ml-1" variant="none">nurulakbar93@gmail.com</b-badge>
                                        </p>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-dots border-success"></div>
                                      <h6 class="float-left mb-1">Agung Tri Laksono</h6><br/>
                                      <small>18 Oktober 2019 15:41</small>
                                      <div class="d-inline-block w-100">
                                        <p>Update Stage</p>
                                        <b-button variant="warning" class="mr-1">REVIEW</b-button>
                                        <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
                                        <b-button variant="danger" class="ml-1">REJECT</b-button>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-dots border-success"></div>
                                      <h6 class="float-left mb-1">System</h6><br/>
                                      <small>17 Oktober 2019 16:00</small>
                                      <div class="d-inline-block w-100">
                                        <p>System mengirim email ke :
                                          <b-badge class="border border-primary text-primary ml-1" variant="none">aguuungtriilaksono@gmail.com</b-badge>
                                          <b-badge class="border border-primary text-primary ml-1" variant="none">nurulakbar93@gmail.com</b-badge>
                                        </p>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-dots border-success"></div>
                                      <h6 class="float-left mb-1">Agung Tri Laksono</h6><br/>
                                      <small>16 Oktober 2019 18:30</small>
                                      <div class="d-inline-block w-100">
                                        <p>Update Stage</p>
                                        <b-button variant="light" class="mr-1">CREATE</b-button>
                                        <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
                                        <b-button variant="warning" class="ml-1">REVIEW</b-button>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-dots border-success"></div>
                                      <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                      <small>15 Oktober 2019 20:24</small>
                                      <div class="d-inline-block w-100">
                                        <p>Mengupdate Job Upslip</p>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-dots border-success"></div>
                                      <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                      <small>15 Oktober 2019 16:00</small>
                                      <div class="d-inline-block w-100">
                                        <p>Membuat Job Upslip</p>
                                      </div>
                                    </li>
                                  </ul>
                                </b-col>
                              </b-row>
                            </tab-content-item>
                          </div>
                        </tab-content-item>
                      </b-col>
                    </b-row>
                    </template>
                  </b-sidebar>
                  <!-- SLIDE - ACTIVITY END -->
                </template>
              </iq-card>
            </tab-content-item>
            <!-- FINISH -->
            <tab-content-item :active="false" id="finish">
              <b-row>
                <b-col sm="12">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h5 class="card-title text-primary">
                        <strong>DOCKING FINISH</strong>
                      </h5>
                    </template>
                    <template v-slot:body>
                      <!-- FILTER DOCKING PROGRESS-->
                      <b-row class="mb-3">
                          <b-col md="4">
                            <v-multiselect v-model="fleetCategorySelect" tag-placeholder="Category" placeholder="Fleet Category" label="text" track-by="value" :options="fleetCategory" :multiple="true" :taggable="true">
                              </v-multiselect>
                          </b-col>
                          <b-col md="2">
                            <b-form-select plain v-model="selectedDockingType" :options="docking_type" size="sm">
                              <template v-slot:first>
                              <b-form-select-option :value="null">Select Docking Type</b-form-select-option>
                              </template>
                            </b-form-select>
                          </b-col>
                          <b-col md="3">
                            <date-picker :lang="lang" v-model="dateRange" type="date" range placeholder="Select date range" size="sm"></date-picker>
                          </b-col>
                          <b-col md="1">
                            <b-button variant="primary" size="sm"><i class="fa fa-filter"></i></b-button>
                          </b-col>
                      </b-row>
                      <!-- FILTER DOCKING PROGRESS END -->
                      <table class="table table-striped table-sm">
                        <thead>
                          <tr>
                            <th class="text-center">FLEET NAME</th>
                            <th class="text-center">TOTAL COST</th>
                            <th class="text-center">TOTAL JOBS</th>
                            <th class="text-center">PERFOMANCE INDICATOR</th>
                            <th class="text-center">INTERMEDIATE SURVEY</th>
                            <th class="text-center">SPESIAL SURVEY</th>
                            <th class="text-center">EMERGENCY DOCKING</th>
                            <th class="text-center">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center">TB. TAVIA 1</td>
                            <td class="text-center">Rp. 1.238.203.200,-</td>
                            <td class="text-center">53 Jobs</td>
                            <td class="text-center">
                              <b-badge variant="success" class="mr-1">On Time : 45 Jobs</b-badge>
                              <b-badge variant="danger">Delay : 23 Jobs</b-badge>
                            </td>
                            <td class="text-center">15 Jobs</td>
                            <td class="text-center">31 Jobs</td>
                            <td class="text-center">22 Jobs</td>
                            <td class="text-center">
                              <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                  </span>
                                </template>
                                <b-dropdown-item href="#">
                                  <router-link to="/templates/docking-history">
                                    <i class="fa fa-ship mr-2"></i>Detail History
                                  </router-link>
                                </b-dropdown-item>
                              </b-dropdown>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">TB. TAVIA 2</td>
                            <td class="text-center">Rp. 1.375.123.180,-</td>
                            <td class="text-center">36 Jobs</td>
                            <td class="text-center">
                              <b-badge variant="success" class="mr-1">On Time : 32 Jobs</b-badge>
                              <b-badge variant="danger">Delay : 4 Jobs</b-badge>
                            </td>
                            <td class="text-center">15 Jobs</td>
                            <td class="text-center">12 Jobs</td>
                            <td class="text-center">16 Jobs</td>
                            <td class="text-center">
                              <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                  </span>
                                </template>
                                <b-dropdown-item href="#">
                                  <router-link to="/templates/docking-history">
                                    <i class="fa fa-ship mr-2"></i>Detail History
                                  </router-link>
                                </b-dropdown-item>
                              </b-dropdown>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">TB. TAVIA 3</td>
                            <td class="text-center">Rp. 1.387.384.000,-</td>
                            <td class="text-center">61 Jobs</td>
                            <td class="text-center">
                              <b-badge variant="success" class="mr-1">On Time : 52 Jobs</b-badge>
                              <b-badge variant="danger">Delay : 20 Jobs</b-badge>
                            </td>
                            <td class="text-center">21 Jobs</td>
                            <td class="text-center">17 Jobs</td>
                            <td class="text-center">19 Jobs</td>
                            <td class="text-center">
                              <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                  </span>
                                </template>
                                <b-dropdown-item href="#">
                                  <router-link to="/templates/docking-history">
                                    <i class="fa fa-ship mr-2"></i>Detail History
                                  </router-link>
                                </b-dropdown-item>
                              </b-dropdown>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">BG. TAVIA 4 </td>
                            <td class="text-center">Rp. 1.387.384.000,-</td>
                            <td class="text-center">72 Jobs</td>
                            <td class="text-center">
                              <b-badge variant="success" class="mr-1">On Time : 52 Jobs</b-badge>
                              <b-badge variant="danger">Delay : 20 Jobs</b-badge>
                            </td>
                            <td class="text-center">36 Jobs</td>
                            <td class="text-center">21 Jobs</td>
                            <td class="text-center">13 Jobs</td>
                            <td class="text-center">
                              <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                  </span>
                                </template>
                                <b-dropdown-item href="#">
                                  <router-link to="/templates/docking-history">
                                    <i class="fa fa-ship mr-2"></i>Detail History
                                  </router-link>
                                </b-dropdown-item>
                              </b-dropdown>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="mt-3">
                        <b-pagination :value="1" :total-rows="50" align="center" />
                      </div>
                    </template>
                  </iq-card>
                </b-col>
              </b-row>
            </tab-content-item>

            <!-- REPORT -->
            <tab-content-item :active="false" id="report">
              <b-row>
                <b-col md="12">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h5 class="card-title text-primary">
                        <strong>DOCKING REPORT</strong>
                      </h5>
                    </template>
                    <template v-slot:body>
                      <!-- FILTER DOCKING PROGRESS-->
                      <b-row>
                          <b-col md="3">
                            <v-multiselect v-model="fleetCategorySelect" tag-placeholder="Category" placeholder="Fleet Category" label="text" track-by="value" :options="fleetCategory" :multiple="true" :taggable="true">
                              </v-multiselect>
                          </b-col>
                          <b-col md="3">
                            <v-multiselect v-model="fleetCategorySelect" tag-placeholder="Category" placeholder="Project Filter" label="text" track-by="value" :options="fleetCategory" :multiple="true" :taggable="true">
                              </v-multiselect>
                          </b-col>
                          <b-col md="2">
                            <b-form-select plain size="sm">
                              <template v-slot:first>
                              <b-form-select-option>Category Ownership</b-form-select-option>
                              <b-form-select-option>Chartered</b-form-select-option>
                              <b-form-select-option>Own Fleet</b-form-select-option>
                              </template>
                            </b-form-select>
                          </b-col>
                          <b-col md="3">
                            <date-picker :lang="lang" v-model="dateRange" type="date" range placeholder="Select date range" size="sm"></date-picker>
                          </b-col>
                          <b-col md="1">
                            <b-button variant="primary" size="sm"><i class="fa fa-filter"></i></b-button>
                          </b-col>
                      </b-row>
                      <!-- FILTER DOCKING PROGRESS END -->
                    </template>
                  </iq-card>
                </b-col>
                <b-col md="6" lg="3">
                  <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-primary rounded">
                    <template v-slot:body>
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="rounded-circle iq-card-icon bg-primary"><i class="fa fa-ship"></i></div>
                        <div class="text-right">
                          <h4 class="mb-0"><span class="counter"><b>7</b></span></h4>
                          <h6 class="">Fleet On Docking</h6>
                        </div>
                      </div>
                    </template>
                  </iq-card>
                </b-col>
                <b-col md="6" lg="3">
                  <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-warning rounded">
                    <template v-slot:body >
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="rounded-circle iq-card-icon bg-warning"><i class="fas fa-dollar-sign"></i></div>
                        <div class="text-right">
                          <h4 class="mb-0"><span class="counter"><b>Rp. 3.552.017.210</b></span></h4>
                          <h6 class="">Total Cost All Fleet</h6>
                        </div>
                      </div>
                    </template>
                  </iq-card>
                </b-col>
                <b-col md="6" lg="3">
                  <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-danger rounded">
                    <template v-slot:body >
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="rounded-circle iq-card-icon bg-danger"><i class="fa fa-tools"></i></div>
                        <div class="text-right">
                          <h4 class="mb-0"><span class="counter"><b>45</b></span></h4>
                          <h6 class="">Total Job Docking</h6>
                        </div>
                      </div>
                    </template>
                  </iq-card>
                </b-col>
                <b-col md="6" lg="3">
                  <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-info rounded">
                    <template v-slot:body >
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="rounded-circle iq-card-icon bg-info"><i class="fa fa-spinner"></i></div>
                        <div class="text-right">
                          <h4 class="mb-0"><span class="counter"><b>36</b></span></h4>
                          <h6 class="">On Progress Job</h6>
                        </div>
                      </div>
                    </template>
                  </iq-card>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="6" v-for="(item,index) in costDocking" :key="index">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4>{{ item.title }}</h4>
                    </template>
                    <template v-slot:body>
                      <HighChart :ref="item.type" :option="item.bodyData"/>
                    </template>
                  </iq-card>
                </b-col>
                <b-col lg="6">
                  <b-row>
                    <b-col lg="6" v-for="(item,index) in costTugboat" :key="index">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4>{{ item.title }}</h4>
                        </template>
                        <template v-slot:body>
                          <HighChart :ref="item.type" :option="item.bodyData"/>
                        </template>
                      </iq-card>
                    </b-col>
                    <b-col lg="6" v-for="(item,index) in costBarge" :key="index">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4>{{ item.title }}</h4>
                        </template>
                        <template v-slot:body>
                          <HighChart :ref="item.type" :option="item.bodyData"/>
                        </template>
                      </iq-card>
                    </b-col>
                    <b-col lg="6" v-for="(item,index) in costPusherTug" :key="index">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4>{{ item.title }}</h4>
                        </template>
                        <template v-slot:body>
                          <HighChart :ref="item.type" :option="item.bodyData"/>
                        </template>
                      </iq-card>
                    </b-col>
                    <b-col lg="6" v-for="(item,index) in costPusherBarge" :key="index">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4>{{ item.title }}</h4>
                        </template>
                        <template v-slot:body>
                          <HighChart :ref="item.type" :option="item.bodyData"/>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col sm="12">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h5 class="card-title text-primary">
                        <strong>Performance Monthly Report</strong>
                      </h5>
                    </template>
                    <template v-slot:body>
                      <b-button variant="outline-primary" class="mb-2">
                        01-09-2023 s/d 30-09-2023 <b-badge variant="primary" class="ml-2">30 Days</b-badge>
                      </b-button>
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="text-center">No</th>
                            <th class="text-center">Fleet Name</th>
                            <th class="text-center">TA. Arrival</th>
                            <th class="text-center">Star Floating</th>
                            <th class="text-center">Uplsip</th>
                            <th class="text-center">Delivery Date</th>
                            <th class="text-center">Waiting Space</th>
                            <th class="text-center">Target Days</th>
                            <th class="text-center text-primary">Performance Monthly</th>
                            <th class="text-center text-danger">Actual Docking Days</th>
                            <th class="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>#1</th>
                            <td class="text-center">TB. TAVIA 1</td>
                            <td class="text-center">07/06/2023</td>
                            <td class="text-center">15/06/2023</td>
                            <td class="text-center">04/07/2023</td>
                            <td class="text-center">-</td>
                            <td class="text-center">38 Days</td>
                            <td class="text-center">60 Days</td>
                            <td class="text-center bg-primary">30 Days</td>
                            <td class="text-center bg-danger">88 Days</td>
                            <td class="text-center">
                              <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="fa fa-edit m-0 text-primary"></i>
                                  </span>
                                </template>
                                  <b-dropdown-item variant="dark"><a href="" @click="slideDetailDocking = !slideDetailDocking;"><i class="fa fa-ship mr-2"></i>Detail</a></b-dropdown-item>
                                  <b-dropdown-item variant="dark"><a href="" @click="slideHistoryMonthlyReport = !slideHistoryMonthlyReport;"><i class="fa fa-history mr-2"></i>Monthly Report</a></b-dropdown-item>
                              </b-dropdown>
                            </td>
                          </tr>
                          <tr>
                            <th>#2</th>
                            <td class="text-center">TB. TAVIA 2</td>
                            <td class="text-center">15/08/2022</td>
                            <td class="text-center">18/08/2022</td>
                            <td class="text-center">20/08/2022</td>
                            <td class="text-center">04/09/2023</td>
                            <td class="text-center">5 Days</td>
                            <td class="text-center">60 Days</td>
                            <td class="text-center bg-primary">4 Days</td>
                            <td class="text-center bg-danger">15 Days</td>
                            <td class="text-center">
                              <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="fa fa-edit m-0 text-primary"></i>
                                  </span>
                                </template>
                                  <b-dropdown-item variant="dark"><a href="" @click="slideDetailDocking = !slideDetailDocking;"><i class="fa fa-ship mr-2"></i>Detail</a></b-dropdown-item>
                                  <b-dropdown-item variant="dark"><a href="" @click="slideHistoryMonthlyReport = !slideHistoryMonthlyReport;"><i class="fa fa-history mr-2"></i>Monthly Report</a></b-dropdown-item>
                              </b-dropdown>
                            </td>
                          </tr>
                          <tr>
                            <th>#3</th>
                            <td class="text-center">TB. TAVIA 3</td>
                            <td class="text-center">23/07/2022</td>
                            <td class="text-center">25/07/2022</td>
                            <td class="text-center">31/07/2022</td>
                            <td class="text-center">15/09/2023</td>
                            <td class="text-center">6 Days</td>
                            <td class="text-center">60 Days</td>
                            <td class="text-center bg-primary">15 Days</td>
                            <td class="text-center bg-danger">67 Days</td>
                            <td class="text-center">
                              <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="fa fa-edit m-0 text-primary"></i>
                                  </span>
                                </template>
                                  <b-dropdown-item variant="dark"><a href="" @click="slideDetailDocking = !slideDetailDocking;"><i class="fa fa-ship mr-2"></i>Detail</a></b-dropdown-item>
                                  <b-dropdown-item variant="dark"><a href="" @click="slideHistoryMonthlyReport = !slideHistoryMonthlyReport;"><i class="fa fa-history mr-2"></i>Monthly Report</a></b-dropdown-item>
                              </b-dropdown>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </template>
                  </iq-card>
                </b-col>
                <b-col sm="6">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h5 class="card-title text-primary">
                        <strong>Matriks Man Power</strong>
                      </h5>
                    </template>
                    <template v-slot:body>
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="text-center">Bulan</th>
                            <th class="text-center">Fleet Name</th>
                            <th class="text-center">Shipyardl</th>
                            <th class="text-center">Day Count</th>
                            <th class="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center">September</td>
                            <td class="text-center">TB. TAVIA 1</td>
                            <td class="text-center">Cirebon</td>
                            <td class="text-center">27 Days</td>
                            <td class="text-center">
                              <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="fa fa-edit m-0 text-primary"></i>
                                  </span>
                                </template>
                                  <b-dropdown-item variant="dark"><a href=""><i class="fa fa-users mr-2"></i>Detail</a></b-dropdown-item>
                              </b-dropdown>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">September</td>
                            <td class="text-center">TB. TAVIA 2</td>
                            <td class="text-center">Tegal</td>
                            <td class="text-center">33 Days</td>
                            <td class="text-center">
                              <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="fa fa-edit m-0 text-primary"></i>
                                  </span>
                                </template>
                                  <b-dropdown-item variant="dark"><a href=""><i class="fa fa-users mr-2"></i>Detail</a></b-dropdown-item>
                              </b-dropdown>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </template>
                  </iq-card>
                </b-col>
              </b-row>
            </tab-content-item>

            <b-sidebar
              v-model="slideDetailDocking"
              id="detail_docking"
              title="DETAIL DOCKING - TB. TAVIA 1"
              width="60%"
              backdrop-variant="dark"
              bg-variant="white"
              right
              backdrop
              shadow>
              <template #default="{hide}">
                <b-row class="m-3">
                  <b-col cols="12">
                    <fieldset class="form-group border p-3">
                      <legend class="w-auto px-2 text-primary">Docking Info</legend>
                      <table class="table table-striped">
                        <tbody>
                          <tr>
                            <th width="20%">Nama Shipyard*</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">Samudara Indonesia, PT</td>
                            <th width="20%">PIC*</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">Nurul Akbar</td>
                          </tr>
                          <tr>
                            <th width="20%">Jenis Pekerjaan*</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">General Overhaul</td>
                            <th width="20%">Estimasi Budget</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">Rp. 256.000.000,-</td>
                          </tr>
                          <tr>
                            <th width="20%">TA. Shipyard*</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">01 Oktober 2023</td>
                            <th width="20%">Floating Repair</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">03 Oktober 2023</td>
                          </tr>
                          <tr>
                            <th width="20%">Upslip*</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">05 Oktober 2023</td>
                            <th width="20%">Downslip*</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">25 Oktober 2023</td>
                          </tr>
                          <tr>
                            <th width="20%">Sea Trial</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">27 Oktober 2023</td>
                            <th width="20%">Delivery</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">31 Oktober 2023</td>
                          </tr>
                          <tr>
                            <th width="20%">Waiting Space</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">5 Hari</td>
                            <th width="20%">Target Days</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">60 Hari</td>
                          </tr>
                          <tr>
                            <th width="20%">Estimasi Tonase</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">8.9</td>
                            <th width="20%">Dokumen Shipyard</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">
                              <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                              </ul>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </fieldset>
                    <fieldset class="form-group border p-3">
                      <legend class="w-auto px-2 text-primary">Docking Job</legend>
                      <b-alert :show="true" variant="primary">
                        <div class="iq-alert-icon">
                          <i class="fa fa-percent"></i>
                        </div>
                        <div class="iq-alert-text">Docking Job Category <b>100%</b></div>
                      </b-alert>

                      <a href="#" v-b-toggle.jobCat-1>
                        <b-alert :show="true" class="text-white bg-primary">
                          <div class="iq-alert-icon">
                            <i class="fas fa-tasks"></i>
                          </div>
                          <div class="iq-alert-text">Docking / Undocking <b>(2 Jobs) - 30%</b></div>
                          <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                            <i class="far fa-hand-pointer"></i> Click Here
                          </a>
                        </b-alert>
                      </a>
                      <b-collapse id="jobCat-1" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                          <b-alert :show="true" variant="danger">
                            <div class="iq-alert-icon">
                              <i class="fa fa-percent"></i>
                            </div>
                            <div class="iq-alert-text">Docking Job <b>100%</b></div>
                          </b-alert>
                          <div class="accordion" role="tablist">
                            <b-card no-body >
                              <a href="#" v-b-toggle.job-1-1>
                                <b-alert :show="true" class="text-white bg-success">
                                  <div class="iq-alert-icon">
                                    <i class="fas fa-clipboard-list"></i>
                                  </div>
                                  <div class="iq-alert-text"><b>Upslip</b> - Persentase Pekerjaan <b>50% (Finish)</b></div>
                                  <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                    <i class="far fa-hand-pointer"></i> Click Here
                                  </a>
                                </b-alert>
                              </a>
                              <b-collapse id="job-1-1" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Upslip</legend>
                                  <table class="table table-striped mt-2">
                                    <tbody>
                                      <tr>
                                        <th width="20%">Start Date</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">2022-06-10</td>
                                        <th width="20%">Est. Finish</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">2022-06-15</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Bobot Pekerjaan</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">50%</td>
                                        <th width="20%">Dokumen</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Created By</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Nurul Akbar</td>
                                        <th width="20%">Review By</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Agung Tri Laksono</td>
                                      </tr>
                                      <tr>
                                        <th class="align-top">Notes</th>
                                        <td class="text-center align-top">:</td>
                                        <td colspan="4">
                                          Check water tubes/plates and clean/free from clogged.<br>
                                          Check  from any corrosion <br>
                                          Check  zink anodes,  replace when necessary<br>
                                          Check  leakage after assembly
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Internal Man Power</legend>
                                  <table class="table table-striped mt-2">
                                    <tbody>
                                      <tr>
                                        <th width="50%" class="pl-3">Agung Tri Laksono</th>
                                        <td width="50%">First Engineer</td>
                                      </tr>
                                      <tr>
                                        <th width="50%" class="pl-3">Nurul Akbar</th>
                                        <td width="50%">Second Engineer</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                  <table class="table table-striped">
                                    <tbody>
                                      <tr>
                                        <th width="20%">Nama Kontraktor </th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">PT. CAHAYA PELAYARAN</td>
                                        <th width="20%">Executive Engineer</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Agung Tri Laksono</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Penawaran</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">328.000.000</td>
                                        <th width="20%">Penawaran Nego</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">325.000.000</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Notes</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Document</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Sub Kontraktor #2</legend>
                                  <table class="table table-striped">
                                    <tbody>
                                      <tr>
                                        <th width="20%">Nama Kontraktor </th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">PT. INTAN BAHARI</td>
                                        <th width="20%">Executive Engineer</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Heri Budianto</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Penawaran</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">178.000.000</td>
                                        <th width="20%">Penawaran Nego</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">175.000.000</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Notes</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">Perbaikan lambung</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Document</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Daily Progress</legend>
                                  <b-col md="12" class="mb-5">
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Select Docking Job" label-for="docking_job">
                                          <b-form-select plain v-model="selectedDockingJob" :options="docking_job">
                                            <template v-slot:first>
                                            <b-form-select-option :value="null">List Docking Job</b-form-select-option>
                                            </template>
                                          </b-form-select>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label-for="progress_bar"><h4 class="mb-2">Progrees : <b-badge variant="warning">80%</b-badge></h4>
                                          <b-progress :value="80" variant="success" max="100"></b-progress>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Start *" label-for="start">
                                          <b-form-input type="date" id="start"></b-form-input>
                                          <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                        </b-form-group>
                                      </b-col>
                                      <b-col>
                                        <b-form-group label="Progress Pekerjaan *" label-for="progress_pekerjaan">
                                          <div class="input-group">
                                            <div class="input-group-prepend">
                                              <span class="input-group-text bg-primary" id="progress_pekerjaan">%</span>
                                            </div>
                                            <input type="text" class="form-control" id="progress_pekerjaan" placeholder="80">
                                          </div>
                                        </b-form-group>
                                      </b-col>
                                      <b-col>
                                        <b-form-group label="Report Progress" label-for="report_progress">
                                          <div class="custom-file">
                                            <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                            <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                          </div>
                                          <small class="text-muted">
                                            File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                            File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                          </small>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Notes" label-for="notes">
                                          <b-form-textarea id="description" rows="3" placeholder="Check water tubes/plates and clean/free from clogged."></b-form-textarea>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>

                                    <div class="form-group row align-items-center mt-3 m-0">
                                      <div class="d-flex flex-grow-1 align-items-center">
                                        <div class="send-panel">
                                          <label for="file" class="mb-0 iq-bg-primary rounded">
                                            <input type="file" id="file" style="display: none;">
                                            <a>
                                              <i class="ri-attachment-line"></i>
                                            </a>
                                          </label>
                                          <label class="ml-2 mb-0 iq-bg-primary rounded">
                                            <a href="javascript:void(0)">
                                              <i class="ri-gallery-line text-primary"></i>
                                            </a>
                                          </label>
                                        </div>
                                      </div>
                                      <div class="d-flex align-items-center">
                                        <div class="send-panel float-left">
                                          <div class="send-btn">
                                            <b-button variant="success"><i class="fa fa-check"></i> Finish</b-button>
                                            <b-button variant="primary" class="ml-2"><i class="fa fa-comment"></i> Update Activity</b-button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </b-col>
                                  <ul class="iq-timeline" style="height:100%">
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                        <small>24 November 2019 15:45</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melakukan inspeksi pada kerusakan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/6.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/1.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Agung Tri Laksono</h6><br/>
                                        <small>23 November 2019 21:25</small>
                                        <div class="d-inline-block w-100">
                                          <p>Barang tiba di galangan</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Tri Leksono</h6><br/>
                                        <small>22 November 2019 10:12</small>
                                        <div class="d-inline-block w-100">
                                          <p>Menunggu sparepart tiba</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                        <small>21 November 2019 11:21</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melengkapi dokumen perbaikan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </div>
                                    </li>
                                  </ul>
                                </fieldset>
                              </b-collapse>
                            </b-card>
                            <b-card no-body >
                              <a href="#" v-b-toggle.job-1-2>
                                <b-alert :show="true" class="text-white bg-info">
                                  <div class="iq-alert-icon">
                                    <i class="fas fa-clipboard-list"></i>
                                  </div>
                                  <div class="iq-alert-text"><b>Downslip</b> - Persentase Pekerjaan <b>50% (Progress)</b></div>
                                  <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                    <i class="far fa-hand-pointer"></i> Click Here
                                  </a>
                                </b-alert>
                              </a>
                              <b-collapse id="job-1-2" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Downslip</legend>
                                  <table class="table table-striped mt-2">
                                    <tbody>
                                      <tr>
                                        <th width="20%">Start Date</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">2022-06-10</td>
                                        <th width="20%">Est. Finish</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">2022-06-15</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Bobot Pekerjaan</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">50%</td>
                                        <th width="20%">Dokumen</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Created By</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Nurul Akbar</td>
                                        <th width="20%">Review By</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Agung Tri Laksono</td>
                                      </tr>
                                      <tr>
                                        <th class="align-top">Notes</th>
                                        <td class="text-center align-top">:</td>
                                        <td colspan="4">
                                          Check water tubes/plates and clean/free from clogged.<br>
                                          Check  from any corrosion <br>
                                          Check  zink anodes,  replace when necessary<br>
                                          Check  leakage after assembly
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Internal Man Power</legend>
                                  <table class="table table-striped mt-2">
                                    <tbody>
                                      <tr>
                                        <th width="50%" class="pl-3">Agung Tri Laksono</th>
                                        <td width="50%">First Engineer</td>
                                      </tr>
                                      <tr>
                                        <th width="50%" class="pl-3">Nurul Akbar</th>
                                        <td width="50%">Second Engineer</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                  <table class="table table-striped">
                                    <tbody>
                                      <tr>
                                        <th width="20%">Nama Kontraktor </th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">PT. CAHAYA PELAYARAN</td>
                                        <th width="20%">Executive Engineer</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Agung Tri Laksono</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Penawaran</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">328.000.000</td>
                                        <th width="20%">Penawaran Nego</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">325.000.000</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Notes</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Document</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Daily Progress</legend>
                                  <b-col md="12" class="mb-5">
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Select Docking Job" label-for="docking_job">
                                          <b-form-select plain v-model="selectedDockingJob" :options="docking_job">
                                            <template v-slot:first>
                                            <b-form-select-option :value="null">List Docking Job</b-form-select-option>
                                            </template>
                                          </b-form-select>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label-for="progress_bar"><h4 class="mb-2">Progrees : <b-badge variant="warning">80%</b-badge></h4>
                                          <b-progress :value="80" variant="success" max="100"></b-progress>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Start *" label-for="start">
                                          <b-form-input type="date" id="start"></b-form-input>
                                          <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                        </b-form-group>
                                      </b-col>
                                      <b-col>
                                        <b-form-group label="Progress Pekerjaan *" label-for="progress_pekerjaan">
                                          <div class="input-group">
                                            <div class="input-group-prepend">
                                              <span class="input-group-text bg-primary" id="progress_pekerjaan">%</span>
                                            </div>
                                            <input type="text" class="form-control" id="progress_pekerjaan" placeholder="80">
                                          </div>
                                        </b-form-group>
                                      </b-col>
                                      <b-col>
                                        <b-form-group label="Report Progress" label-for="report_progress">
                                          <div class="custom-file">
                                            <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                            <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                          </div>
                                          <small class="text-muted">
                                            File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                            File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                          </small>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Notes" label-for="notes">
                                          <b-form-textarea id="description" rows="3" placeholder="Check water tubes/plates and clean/free from clogged."></b-form-textarea>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>

                                    <div class="form-group row align-items-center mt-3 m-0">
                                      <div class="d-flex flex-grow-1 align-items-center">
                                        <div class="send-panel">
                                          <label for="file" class="mb-0 iq-bg-primary rounded">
                                            <input type="file" id="file" style="display: none;">
                                            <a>
                                              <i class="ri-attachment-line"></i>
                                            </a>
                                          </label>
                                          <label class="ml-2 mb-0 iq-bg-primary rounded">
                                            <a href="javascript:void(0)">
                                              <i class="ri-gallery-line text-primary"></i>
                                            </a>
                                          </label>
                                        </div>
                                      </div>
                                      <div class="d-flex align-items-center">
                                        <div class="send-panel float-left">
                                          <div class="send-btn">
                                            <b-button variant="success"><i class="fa fa-check"></i> Finish</b-button>
                                            <b-button variant="primary" class="ml-2"><i class="fa fa-comment"></i> Update Activity</b-button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </b-col>
                                  <ul class="iq-timeline" style="height:100%">
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                        <small>24 November 2019 15:45</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melakukan inspeksi pada kerusakan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/6.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/1.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Agung Tri Laksono</h6><br/>
                                        <small>23 November 2019 21:25</small>
                                        <div class="d-inline-block w-100">
                                          <p>Barang tiba di galangan</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Tri Leksono</h6><br/>
                                        <small>22 November 2019 10:12</small>
                                        <div class="d-inline-block w-100">
                                          <p>Menunggu sparepart tiba</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                        <small>21 November 2019 11:21</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melengkapi dokumen perbaikan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </div>
                                    </li>
                                  </ul>
                                </fieldset>
                              </b-collapse>
                            </b-card>
                          </div>
                        </b-card-body>
                      </b-collapse>

                      <a href="#" v-b-toggle.jobCat-2>
                        <b-alert :show="true" class="text-white bg-primary">
                          <div class="iq-alert-icon">
                            <i class="fas fa-tasks"></i>
                          </div>
                          <div class="iq-alert-text">Sand Blasting <b>(4 Jobs) - 70%</b></div>
                          <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                            <i class="far fa-hand-pointer"></i> Click Here
                          </a>
                        </b-alert>
                      </a>
                      <b-collapse id="jobCat-2" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                          <b-alert :show="true" variant="danger">
                            <div class="iq-alert-icon">
                              <i class="fa fa-percent"></i>
                            </div>
                            <div class="iq-alert-text">Docking Job <b>100%</b></div>
                          </b-alert>
                          <div class="accordion" role="tablist">
                            <b-card no-body >
                              <a href="#" v-b-toggle.job-2-1>
                                <b-alert :show="true" class="text-white bg-success">
                                  <div class="iq-alert-icon">
                                    <i class="fas fa-clipboard-list"></i>
                                  </div>
                                  <div class="iq-alert-text"><b>Scraping Teripting di Lambung & Lunas</b> - Persentase Pekerjaan <b>25% (Finish)</b></div>
                                  <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                    <i class="far fa-hand-pointer"></i> Click Here
                                  </a>
                                </b-alert>
                              </a>
                              <b-collapse id="job-2-1" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Scraping Teripting di Lambung & Lunas</legend>
                                  <table class="table table-striped mt-2">
                                    <tbody>
                                      <tr>
                                        <th width="20%">Start Date</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">2022-06-10</td>
                                        <th width="20%">Est. Finish</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">2022-06-15</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Bobot Pekerjaan</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">50%</td>
                                        <th width="20%">Dokumen</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Created By</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Nurul Akbar</td>
                                        <th width="20%">Review By</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Agung Tri Laksono</td>
                                      </tr>
                                      <tr>
                                        <th class="align-top">Notes</th>
                                        <td class="text-center align-top">:</td>
                                        <td colspan="4">
                                          Check water tubes/plates and clean/free from clogged.<br>
                                          Check  from any corrosion <br>
                                          Check  zink anodes,  replace when necessary<br>
                                          Check  leakage after assembly
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Internal Man Power</legend>
                                  <table class="table table-striped mt-2">
                                    <tbody>
                                      <tr>
                                        <th width="50%" class="pl-3">Agung Tri Laksono</th>
                                        <td width="50%">First Engineer</td>
                                      </tr>
                                      <tr>
                                        <th width="50%" class="pl-3">Nurul Akbar</th>
                                        <td width="50%">Second Engineer</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                  <table class="table table-striped">
                                    <tbody>
                                      <tr>
                                        <th width="20%">Nama Kontraktor </th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">PT. CAHAYA PELAYARAN</td>
                                        <th width="20%">Executive Engineer</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Agung Tri Laksono</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Penawaran</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">328.000.000</td>
                                        <th width="20%">Penawaran Nego</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">325.000.000</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Notes</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Document</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Daily Progress</legend>
                                  <b-col md="12" class="mb-5">
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Select Docking Job" label-for="docking_job">
                                          <b-form-select plain v-model="selectedDockingJob" :options="docking_job">
                                            <template v-slot:first>
                                            <b-form-select-option :value="null">List Docking Job</b-form-select-option>
                                            </template>
                                          </b-form-select>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label-for="progress_bar"><h4 class="mb-2">Progrees : <b-badge variant="warning">80%</b-badge></h4>
                                          <b-progress :value="80" variant="success" max="100"></b-progress>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Start *" label-for="start">
                                          <b-form-input type="date" id="start"></b-form-input>
                                          <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                        </b-form-group>
                                      </b-col>
                                      <b-col>
                                        <b-form-group label="Progress Pekerjaan *" label-for="progress_pekerjaan">
                                          <div class="input-group">
                                            <div class="input-group-prepend">
                                              <span class="input-group-text bg-primary" id="progress_pekerjaan">%</span>
                                            </div>
                                            <input type="text" class="form-control" id="progress_pekerjaan" placeholder="80">
                                          </div>
                                        </b-form-group>
                                      </b-col>
                                      <b-col>
                                        <b-form-group label="Report Progress" label-for="report_progress">
                                          <div class="custom-file">
                                            <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                            <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                          </div>
                                          <small class="text-muted">
                                            File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                            File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                          </small>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Notes" label-for="notes">
                                          <b-form-textarea id="description" rows="3" placeholder="Check water tubes/plates and clean/free from clogged."></b-form-textarea>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>

                                    <div class="form-group row align-items-center mt-3 m-0">
                                      <div class="d-flex flex-grow-1 align-items-center">
                                        <div class="send-panel">
                                          <label for="file" class="mb-0 iq-bg-primary rounded">
                                            <input type="file" id="file" style="display: none;">
                                            <a>
                                              <i class="ri-attachment-line"></i>
                                            </a>
                                          </label>
                                          <label class="ml-2 mb-0 iq-bg-primary rounded">
                                            <a href="javascript:void(0)">
                                              <i class="ri-gallery-line text-primary"></i>
                                            </a>
                                          </label>
                                        </div>
                                      </div>
                                      <div class="d-flex align-items-center">
                                        <div class="send-panel float-left">
                                          <div class="send-btn">
                                            <b-button variant="success"><i class="fa fa-check"></i> Finish</b-button>
                                            <b-button variant="primary" class="ml-2"><i class="fa fa-comment"></i> Update Activity</b-button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </b-col>
                                  <ul class="iq-timeline" style="height:100%">
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                        <small>24 November 2019 15:45</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melakukan inspeksi pada kerusakan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/6.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/1.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Agung Tri Laksono</h6><br/>
                                        <small>23 November 2019 21:25</small>
                                        <div class="d-inline-block w-100">
                                          <p>Barang tiba di galangan</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Tri Leksono</h6><br/>
                                        <small>22 November 2019 10:12</small>
                                        <div class="d-inline-block w-100">
                                          <p>Menunggu sparepart tiba</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                        <small>21 November 2019 11:21</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melengkapi dokumen perbaikan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </div>
                                    </li>
                                  </ul>
                                </fieldset>
                              </b-collapse>
                            </b-card>
                            <b-card no-body >
                              <a href="#" v-b-toggle.job-2-2>
                                <b-alert :show="true" class="text-white bg-success">
                                  <div class="iq-alert-icon">
                                    <i class="fas fa-clipboard-list"></i>
                                  </div>
                                  <div class="iq-alert-text"><b>Pencucian Lambung dengan Air Tawar</b> - Persentase Pekerjaan <b>25% (Finish)</b></div>
                                  <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                    <i class="far fa-hand-pointer"></i> Click Here
                                  </a>
                                </b-alert>
                              </a>
                              <b-collapse id="job-2-2" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Pencucian Lambung dengan Air Tawar</legend>
                                  <table class="table table-striped mt-2">
                                    <tbody>
                                      <tr>
                                        <th width="20%">Start Date</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">2022-06-10</td>
                                        <th width="20%">Est. Finish</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">2022-06-15</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Bobot Pekerjaan</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">50%</td>
                                        <th width="20%">Dokumen</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Created By</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Nurul Akbar</td>
                                        <th width="20%">Review By</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Agung Tri Laksono</td>
                                      </tr>
                                      <tr>
                                        <th class="align-top">Notes</th>
                                        <td class="text-center align-top">:</td>
                                        <td colspan="4">
                                          Check water tubes/plates and clean/free from clogged.<br>
                                          Check  from any corrosion <br>
                                          Check  zink anodes,  replace when necessary<br>
                                          Check  leakage after assembly
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Internal Man Power</legend>
                                  <table class="table table-striped mt-2">
                                    <tbody>
                                      <tr>
                                        <th width="50%" class="pl-3">Agung Tri Laksono</th>
                                        <td width="50%">First Engineer</td>
                                      </tr>
                                      <tr>
                                        <th width="50%" class="pl-3">Nurul Akbar</th>
                                        <td width="50%">Second Engineer</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                  <table class="table table-striped">
                                    <tbody>
                                      <tr>
                                        <th width="20%">Nama Kontraktor </th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">PT. CAHAYA PELAYARAN</td>
                                        <th width="20%">Executive Engineer</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Agung Tri Laksono</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Penawaran</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">328.000.000</td>
                                        <th width="20%">Penawaran Nego</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">325.000.000</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Notes</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Document</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Daily Progress</legend>
                                  <b-col md="12" class="mb-5">
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Select Docking Job" label-for="docking_job">
                                          <b-form-select plain v-model="selectedDockingJob" :options="docking_job">
                                            <template v-slot:first>
                                            <b-form-select-option :value="null">List Docking Job</b-form-select-option>
                                            </template>
                                          </b-form-select>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label-for="progress_bar"><h4 class="mb-2">Progrees : <b-badge variant="warning">80%</b-badge></h4>
                                          <b-progress :value="80" variant="success" max="100"></b-progress>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Start *" label-for="start">
                                          <b-form-input type="date" id="start"></b-form-input>
                                          <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                        </b-form-group>
                                      </b-col>
                                      <b-col>
                                        <b-form-group label="Progress Pekerjaan *" label-for="progress_pekerjaan">
                                          <div class="input-group">
                                            <div class="input-group-prepend">
                                              <span class="input-group-text bg-primary" id="progress_pekerjaan">%</span>
                                            </div>
                                            <input type="text" class="form-control" id="progress_pekerjaan" placeholder="80">
                                          </div>
                                        </b-form-group>
                                      </b-col>
                                      <b-col>
                                        <b-form-group label="Report Progress" label-for="report_progress">
                                          <div class="custom-file">
                                            <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                            <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                          </div>
                                          <small class="text-muted">
                                            File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                            File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                          </small>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Notes" label-for="notes">
                                          <b-form-textarea id="description" rows="3" placeholder="Check water tubes/plates and clean/free from clogged."></b-form-textarea>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>

                                    <div class="form-group row align-items-center mt-3 m-0">
                                      <div class="d-flex flex-grow-1 align-items-center">
                                        <div class="send-panel">
                                          <label for="file" class="mb-0 iq-bg-primary rounded">
                                            <input type="file" id="file" style="display: none;">
                                            <a>
                                              <i class="ri-attachment-line"></i>
                                            </a>
                                          </label>
                                          <label class="ml-2 mb-0 iq-bg-primary rounded">
                                            <a href="javascript:void(0)">
                                              <i class="ri-gallery-line text-primary"></i>
                                            </a>
                                          </label>
                                        </div>
                                      </div>
                                      <div class="d-flex align-items-center">
                                        <div class="send-panel float-left">
                                          <div class="send-btn">
                                            <b-button variant="success"><i class="fa fa-check"></i> Finish</b-button>
                                            <b-button variant="primary" class="ml-2"><i class="fa fa-comment"></i> Update Activity</b-button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </b-col>
                                  <ul class="iq-timeline" style="height:100%">
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                        <small>24 November 2019 15:45</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melakukan inspeksi pada kerusakan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/6.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/1.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Agung Tri Laksono</h6><br/>
                                        <small>23 November 2019 21:25</small>
                                        <div class="d-inline-block w-100">
                                          <p>Barang tiba di galangan</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Tri Leksono</h6><br/>
                                        <small>22 November 2019 10:12</small>
                                        <div class="d-inline-block w-100">
                                          <p>Menunggu sparepart tiba</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                        <small>21 November 2019 11:21</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melengkapi dokumen perbaikan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </div>
                                    </li>
                                  </ul>
                                </fieldset>
                              </b-collapse>
                            </b-card>
                            <b-card no-body >
                              <a href="#" v-b-toggle.job-2-3>
                                <b-alert :show="true" class="text-white bg-info">
                                  <div class="iq-alert-icon">
                                    <i class="fas fa-clipboard-list"></i>
                                  </div>
                                  <div class="iq-alert-text"><b>Sand Blasting Lunas</b> - Persentase Pekerjaan <b>25% (Progress)</b></div>
                                  <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                    <i class="far fa-hand-pointer"></i> Click Here
                                  </a>
                                </b-alert>
                              </a>
                              <b-collapse id="job-2-3" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Sand Blasting Lunas</legend>
                                  <table class="table table-striped mt-2">
                                    <tbody>
                                      <tr>
                                        <th width="20%">Start Date</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">2022-06-10</td>
                                        <th width="20%">Est. Finish</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">2022-06-15</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Bobot Pekerjaan</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">50%</td>
                                        <th width="20%">Dokumen</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Created By</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Nurul Akbar</td>
                                        <th width="20%">Review By</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Agung Tri Laksono</td>
                                      </tr>
                                      <tr>
                                        <th class="align-top">Notes</th>
                                        <td class="text-center align-top">:</td>
                                        <td colspan="4">
                                          Check water tubes/plates and clean/free from clogged.<br>
                                          Check  from any corrosion <br>
                                          Check  zink anodes,  replace when necessary<br>
                                          Check  leakage after assembly
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Internal Man Power</legend>
                                  <table class="table table-striped mt-2">
                                    <tbody>
                                      <tr>
                                        <th width="50%" class="pl-3">Agung Tri Laksono</th>
                                        <td width="50%">First Engineer</td>
                                      </tr>
                                      <tr>
                                        <th width="50%" class="pl-3">Nurul Akbar</th>
                                        <td width="50%">Second Engineer</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                  <table class="table table-striped">
                                    <tbody>
                                      <tr>
                                        <th width="20%">Nama Kontraktor </th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">PT. CAHAYA PELAYARAN</td>
                                        <th width="20%">Executive Engineer</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Agung Tri Laksono</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Penawaran</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">328.000.000</td>
                                        <th width="20%">Penawaran Nego</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">325.000.000</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Notes</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Document</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Daily Progress</legend>
                                  <b-col md="12" class="mb-5">
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Select Docking Job" label-for="docking_job">
                                          <b-form-select plain v-model="selectedDockingJob" :options="docking_job">
                                            <template v-slot:first>
                                            <b-form-select-option :value="null">List Docking Job</b-form-select-option>
                                            </template>
                                          </b-form-select>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label-for="progress_bar"><h4 class="mb-2">Progrees : <b-badge variant="warning">80%</b-badge></h4>
                                          <b-progress :value="80" variant="success" max="100"></b-progress>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Start *" label-for="start">
                                          <b-form-input type="date" id="start"></b-form-input>
                                          <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                        </b-form-group>
                                      </b-col>
                                      <b-col>
                                        <b-form-group label="Progress Pekerjaan *" label-for="progress_pekerjaan">
                                          <div class="input-group">
                                            <div class="input-group-prepend">
                                              <span class="input-group-text bg-primary" id="progress_pekerjaan">%</span>
                                            </div>
                                            <input type="text" class="form-control" id="progress_pekerjaan" placeholder="80">
                                          </div>
                                        </b-form-group>
                                      </b-col>
                                      <b-col>
                                        <b-form-group label="Report Progress" label-for="report_progress">
                                          <div class="custom-file">
                                            <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                            <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                          </div>
                                          <small class="text-muted">
                                            File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                            File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                          </small>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Notes" label-for="notes">
                                          <b-form-textarea id="description" rows="3" placeholder="Check water tubes/plates and clean/free from clogged."></b-form-textarea>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>

                                    <div class="form-group row align-items-center mt-3 m-0">
                                      <div class="d-flex flex-grow-1 align-items-center">
                                        <div class="send-panel">
                                          <label for="file" class="mb-0 iq-bg-primary rounded">
                                            <input type="file" id="file" style="display: none;">
                                            <a>
                                              <i class="ri-attachment-line"></i>
                                            </a>
                                          </label>
                                          <label class="ml-2 mb-0 iq-bg-primary rounded">
                                            <a href="javascript:void(0)">
                                              <i class="ri-gallery-line text-primary"></i>
                                            </a>
                                          </label>
                                        </div>
                                      </div>
                                      <div class="d-flex align-items-center">
                                        <div class="send-panel float-left">
                                          <div class="send-btn">
                                            <b-button variant="success"><i class="fa fa-check"></i> Finish</b-button>
                                            <b-button variant="primary" class="ml-2"><i class="fa fa-comment"></i> Update Activity</b-button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </b-col>
                                  <ul class="iq-timeline" style="height:100%">
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                        <small>24 November 2019 15:45</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melakukan inspeksi pada kerusakan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/6.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/1.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Agung Tri Laksono</h6><br/>
                                        <small>23 November 2019 21:25</small>
                                        <div class="d-inline-block w-100">
                                          <p>Barang tiba di galangan</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Tri Leksono</h6><br/>
                                        <small>22 November 2019 10:12</small>
                                        <div class="d-inline-block w-100">
                                          <p>Menunggu sparepart tiba</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                        <small>21 November 2019 11:21</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melengkapi dokumen perbaikan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </div>
                                    </li>
                                  </ul>
                                </fieldset>
                              </b-collapse>
                            </b-card>
                            <b-card no-body >
                              <a href="#" v-b-toggle.job-2-4>
                                <b-alert :show="true" class="text-white bg-warning">
                                  <div class="iq-alert-icon">
                                    <i class="fas fa-clipboard-list"></i>
                                  </div>
                                  <div class="iq-alert-text"><b>Sand Blasting Deck & Bullwark</b> - Persentase Pekerjaan <b>15% (Progress)</b></div>
                                  <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                    <i class="far fa-hand-pointer"></i> Click Here
                                  </a>
                                </b-alert>
                              </a>
                              <b-collapse id="job-2-4" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Sand Blasting Lunas</legend>
                                  <table class="table table-striped mt-2">
                                    <tbody>
                                      <tr>
                                        <th width="20%">Start Date</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">2022-06-10</td>
                                        <th width="20%">Est. Finish</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">2022-06-15</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Bobot Pekerjaan</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">50%</td>
                                        <th width="20%">Dokumen</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Created By</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Nurul Akbar</td>
                                        <th width="20%">Review By</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Agung Tri Laksono</td>
                                      </tr>
                                      <tr>
                                        <th class="align-top">Notes</th>
                                        <td class="text-center align-top">:</td>
                                        <td colspan="4">
                                          Check water tubes/plates and clean/free from clogged.<br>
                                          Check  from any corrosion <br>
                                          Check  zink anodes,  replace when necessary<br>
                                          Check  leakage after assembly
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Internal Man Power</legend>
                                  <table class="table table-striped mt-2">
                                    <tbody>
                                      <tr>
                                        <th width="50%" class="pl-3">Agung Tri Laksono</th>
                                        <td width="50%">First Engineer</td>
                                      </tr>
                                      <tr>
                                        <th width="50%" class="pl-3">Nurul Akbar</th>
                                        <td width="50%">Second Engineer</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                  <table class="table table-striped">
                                    <tbody>
                                      <tr>
                                        <th width="20%">Nama Kontraktor </th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">PT. CAHAYA PELAYARAN</td>
                                        <th width="20%">Executive Engineer</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Agung Tri Laksono</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Penawaran</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">328.000.000</td>
                                        <th width="20%">Penawaran Nego</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">325.000.000</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Notes</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Document</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Daily Progress</legend>
                                  <b-col md="12" class="mb-5">
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Select Docking Job" label-for="docking_job">
                                          <b-form-select plain v-model="selectedDockingJob" :options="docking_job">
                                            <template v-slot:first>
                                            <b-form-select-option :value="null">List Docking Job</b-form-select-option>
                                            </template>
                                          </b-form-select>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label-for="progress_bar"><h4 class="mb-2">Progrees : <b-badge variant="warning">80%</b-badge></h4>
                                          <b-progress :value="80" variant="success" max="100"></b-progress>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Start *" label-for="start">
                                          <b-form-input type="date" id="start"></b-form-input>
                                          <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                        </b-form-group>
                                      </b-col>
                                      <b-col>
                                        <b-form-group label="Progress Pekerjaan *" label-for="progress_pekerjaan">
                                          <div class="input-group">
                                            <div class="input-group-prepend">
                                              <span class="input-group-text bg-primary" id="progress_pekerjaan">%</span>
                                            </div>
                                            <input type="text" class="form-control" id="progress_pekerjaan" placeholder="80">
                                          </div>
                                        </b-form-group>
                                      </b-col>
                                      <b-col>
                                        <b-form-group label="Report Progress" label-for="report_progress">
                                          <div class="custom-file">
                                            <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                            <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                          </div>
                                          <small class="text-muted">
                                            File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                            File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                          </small>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Notes" label-for="notes">
                                          <b-form-textarea id="description" rows="3" placeholder="Check water tubes/plates and clean/free from clogged."></b-form-textarea>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>

                                    <div class="form-group row align-items-center mt-3 m-0">
                                      <div class="d-flex flex-grow-1 align-items-center">
                                        <div class="send-panel">
                                          <label for="file" class="mb-0 iq-bg-primary rounded">
                                            <input type="file" id="file" style="display: none;">
                                            <a>
                                              <i class="ri-attachment-line"></i>
                                            </a>
                                          </label>
                                          <label class="ml-2 mb-0 iq-bg-primary rounded">
                                            <a href="javascript:void(0)">
                                              <i class="ri-gallery-line text-primary"></i>
                                            </a>
                                          </label>
                                        </div>
                                      </div>
                                      <div class="d-flex align-items-center">
                                        <div class="send-panel float-left">
                                          <div class="send-btn">
                                            <b-button variant="success"><i class="fa fa-check"></i> Finish</b-button>
                                            <b-button variant="primary" class="ml-2"><i class="fa fa-comment"></i> Update Activity</b-button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </b-col>
                                  <ul class="iq-timeline" style="height:100%">
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                        <small>24 November 2019 15:45</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melakukan inspeksi pada kerusakan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/6.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/1.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Agung Tri Laksono</h6><br/>
                                        <small>23 November 2019 21:25</small>
                                        <div class="d-inline-block w-100">
                                          <p>Barang tiba di galangan</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Tri Leksono</h6><br/>
                                        <small>22 November 2019 10:12</small>
                                        <div class="d-inline-block w-100">
                                          <p>Menunggu sparepart tiba</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                        <small>21 November 2019 11:21</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melengkapi dokumen perbaikan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </div>
                                    </li>
                                  </ul>
                                </fieldset>
                              </b-collapse>
                            </b-card>
                            <b-card no-body >
                              <a href="#" v-b-toggle.job-2-5>
                                <b-alert :show="true" class="text-white bg-danger">
                                  <div class="iq-alert-icon">
                                    <i class="fas fa-clipboard-list"></i>
                                  </div>
                                  <div class="iq-alert-text"><b>Sand Blasting Main Deck</b> - Persentase Pekerjaan <b>10% (New)</b></div>
                                  <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                    <i class="far fa-hand-pointer"></i> Click Here
                                  </a>
                                </b-alert>
                              </a>
                              <b-collapse id="job-2-5" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Sand Blasting Main Deck</legend>
                                  <table class="table table-striped mt-2">
                                    <tbody>
                                      <tr>
                                        <th width="20%">Start Date</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">2022-06-10</td>
                                        <th width="20%">Est. Finish</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">2022-06-15</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Bobot Pekerjaan</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">50%</td>
                                        <th width="20%">Dokumen</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Created By</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Nurul Akbar</td>
                                        <th width="20%">Review By</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Agung Tri Laksono</td>
                                      </tr>
                                      <tr>
                                        <th class="align-top">Notes</th>
                                        <td class="text-center align-top">:</td>
                                        <td colspan="4">
                                          Check water tubes/plates and clean/free from clogged.<br>
                                          Check  from any corrosion <br>
                                          Check  zink anodes,  replace when necessary<br>
                                          Check  leakage after assembly
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Internal Man Power</legend>
                                  <table class="table table-striped mt-2">
                                    <tbody>
                                      <tr>
                                        <th width="50%" class="pl-3">Agung Tri Laksono</th>
                                        <td width="50%">First Engineer</td>
                                      </tr>
                                      <tr>
                                        <th width="50%" class="pl-3">Nurul Akbar</th>
                                        <td width="50%">Second Engineer</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                  <table class="table table-striped">
                                    <tbody>
                                      <tr>
                                        <th width="20%">Nama Kontraktor </th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">PT. CAHAYA PELAYARAN</td>
                                        <th width="20%">Executive Engineer</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Agung Tri Laksono</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Penawaran</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">328.000.000</td>
                                        <th width="20%">Penawaran Nego</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">325.000.000</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Notes</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Document</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                              </b-collapse>
                            </b-card>
                          </div>
                        </b-card-body>
                      </b-collapse>
                    </fieldset>
                  </b-col>
                </b-row>
              </template>
            </b-sidebar>

            <b-sidebar
              v-model="slideHistoryMonthlyReport"
              id="detail_docking"
              title="HISTORY MONTHLY REPORT"
              width="100%"
              backdrop-variant="dark"
              bg-variant="white"
              right
              backdrop
              shadow>
              <template #default="{hide}">
                <b-row class="m-3">
                  <b-col cols="12">
                    <fieldset class="form-group border p-3">
                      <legend class="w-auto px-2 text-primary">TB. TAVIA 1</legend>
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="text-center">Bulan Report</th>
                            <th class="text-center">TA. Arrival</th>
                            <th class="text-center">Star Floating</th>
                            <th class="text-center">Uplsip</th>
                            <th class="text-center">Delivery Date</th>
                            <th class="text-center">Waiting Space</th>
                            <th class="text-center">Target Days</th>
                            <th class="text-center text-primary">Performance Monthly</th>
                            <th class="text-center text-danger">Actual Docking Days</th>
                            <th class="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center">30 Sept 2023</td>
                            <td class="text-center">07/06/2023</td>
                            <td class="text-center">15/06/2023</td>
                            <td class="text-center">04/07/2023</td>
                            <td class="text-center">-</td>
                            <td class="text-center">27 Days</td>
                            <td class="text-center">60 Days</td>
                            <td class="text-center bg-primary">30 Days</td>
                            <td class="text-center bg-danger">88 Days</td>
                            <td class="text-center">
                              <a href=""><b-button variant="primary" class="mr-1"><i class="fa fa-ship"></i></b-button></a>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">31 Agustus 2023</td>
                            <td class="text-center">07/06/2023</td>
                            <td class="text-center">15/06/2023</td>
                            <td class="text-center">04/07/2023</td>
                            <td class="text-center">-</td>
                            <td class="text-center">27 Days</td>
                            <td class="text-center">60 Days</td>
                            <td class="text-center bg-primary">31 Days</td>
                            <td class="text-center bg-danger">57 Days</td>
                            <td class="text-center">
                              <a href=""><b-button variant="primary" class="mr-1"><i class="fa fa-ship"></i></b-button></a>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">31 Juli 2023</td>
                            <td class="text-center">07/06/2023</td>
                            <td class="text-center">15/06/2023</td>
                            <td class="text-center">04/07/2023</td>
                            <td class="text-center">-</td>
                            <td class="text-center">27 Days</td>
                            <td class="text-center">60 Days</td>
                            <td class="text-center bg-primary">31 Days</td>
                            <td class="text-center bg-danger">26 Days</td>
                            <td class="text-center">
                              <a href=""><b-button variant="primary" class="mr-1"><i class="fa fa-ship"></i></b-button></a>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">30 Juni 2023</td>
                            <td class="text-center">07/06/2023</td>
                            <td class="text-center">15/06/2023</td>
                            <td class="text-center">04/07/2023</td>
                            <td class="text-center">-</td>
                            <td class="text-center">27 Days</td>
                            <td class="text-center">60 Days</td>
                            <td class="text-center bg-primary">30 Days</td>
                            <td class="text-center bg-danger">26 Days</td>
                            <td class="text-center">
                              <a href=""><b-button variant="primary" class="mr-1"><i class="fa fa-ship"></i></b-button></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </fieldset>
                  </b-col>
                </b-row>
              </template>
            </b-sidebar>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import _ from 'lodash'
import {
  fleetsActions
} from '@src/Utils/helper'
import { createPopper } from '@popperjs/core'

export default {
  name: 'ProfileEdit',
  mounted () {
    xray.index()
    this.fetchFleets()
  },
  data () {
    return {
      slickOptions: {
        centerMode: false,
        centerPadding: '60px',
        slidesToShow: 5,
        slidesToScroll: 1,
        focusOnSelect: true,
        responsive: [{
          breakpoint: 992,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '30',
            slidesToShow: 3
          }
        }, {
          breakpoint: 480,
          settings: {
            arrows: false,
            centerMode: true,
            centerPadding: '15',
            slidesToShow: 1
          }
        }],
        nextArrow: '<a href="#" class="ri-arrow-left-s-line left"></a>',
        prevArrow: '<a href="#" class="ri-arrow-right-s-line right"></a>'
      },
      fleetList: [
        { name: 'TB. TAVIA 1', img: require('../../assets/images/Km3FO09.png'), position: 'Transcoal Pacific'},
        { name: 'TB. TAVIA 2', img: require('../../assets/images/Km3FO09.png'), position: 'Transcoal Pacific'},
        { name: 'TB. TAVIA 3', img: require('../../assets/images/Km3FO09.png'), position: 'Energy Transporter'},
        { name: 'TB. TAVIA 4', img: require('../../assets/images/Km3FO09.png'), position: 'Sentra Makmur Lines'},
        { name: 'TB. TAVIA 5', img: require('../../assets/images/Km3FO09.png'), position: 'Transcoal Pacific'},
        { name: 'TB. TAVIA 6', img: require('../../assets/images/Km3FO09.png'), position: 'Transcoal Pacific'}
      ],
      showInitModal: false,
      modalCreateDocking: false,
      slideUpdateDocking: false,
      slideReviewDocking: false,
      slideApproveDocking: false,
      slideDetailDocking: false,
      slideHistoryMonthlyReport: false,
      slideActivity: false,
      slideFinishDocking: false,
      modalDocking: false,

      selectedDockingType: null,
      docking_type: [
        { value: '1', text: 'Intermediate Survey' },
        { value: '2', text: 'Special Survey' },
        { value: '3', text: 'Emergency Docking' }
      ],

      selectedDockingJob: null,
      docking_job: [
        { value: '1', text: 'Up slip' },
        { value: '2', text: 'Downslip' },
        { value: '3', text: 'Scraping teritip di lambung dan lunas' },
        { value: '4', text: 'Pencucian lambung dengan air tawar teritip di lambung dan lunas' },
        { value: '5', text: 'Sand blasting lunas' },
        { value: '6', text: 'Sand blasting lambung' },
        { value: '6', text: 'Sand blasting deck dan bulwark' },
        { value: '6', text: 'General Overhaul ME (P/S)' },
        { value: '6', text: 'Kalibrasi injector (P/S)' },
        { value: '6', text: 'Service/ganti baru dynamo starter (P/S)' },
      ],
      selectedJabatan: 1,
      jabatan: [
        { value: '1', text: 'Port Enginer' },
        { value: '2', text: 'Port Captain' },
        { value: '3', text: 'Technical Superintendent' },
        { value: '4', text: 'Technical Manager' }
      ],
      selectedShipyard: null,
      shipyard: [
        { value: '1', text: 'PT. Indonesia Marina Shipyard' },
        { value: '2', text: 'PT. Samudra Pratama' },
        { value: '3', text: 'PT. Rukindo Shipyard' }
      ],

      selectedFleets: null,
      kapal: [
        { value: '1', text: 'TB. TAVIA 1' },
        { value: '2', text: 'TB. TAVIA 2' },
        { value: '3', text: 'TB. TAVIA 3' }
      ],
      selectedDockingType: null,
      docking_type: [
        { value: '1', text: 'Intermediate Survey' },
        { value: '2', text: 'Special Survey' },
        { value: '3', text: 'Emergency Docking' }
      ],
      selectedDockingStatus: null,
      dockingStatus: [
        { value: '1', text: 'DUE DATE' },
        { value: '2', text: 'PENDING' },
        { value: '3', text: 'OVER SCHEDULE' },
        { value: '4', text: 'REVIEW' },
        { value: '5', text: 'APPROVE' },
        { value: '6', text: 'PROGRESS' },
        { value: '7', text: 'ACTIVE' },
      ],
      selectedJobCategory: null,
      jobCategory: [
        { value: '1', text: 'Docking/Undocking' },
        { value: '2', text: 'Sand Blasting' },
        { value: '3', text: 'Painting' },
        { value: '4', text: 'Hull Contruction' },
        { value: '5', text: 'Machinery Equipment' },
        { value: '6', text: 'Outfiting' }
      ],
      text: [
        {
          activeLabel: 'Yes',
          inactiveLabel: 'No',
          class: 'primary',
          checked: true
        }
      ],
      fleetCategorySelect:[],
      fleetCategory: [
        {
          text: 'Tug Boat',
          color: 'success',
          value: 'tugboat'
        },
        {
          text: 'Barge',
          color: 'warning',
          value: 'barge'
        },
        {
          text: 'Mother Vessel',
          color: 'primary',
          value: 'mother_vessel'
        },
        {
          text: 'Heavy Equipment',
          color: 'primary',
          value: 'heavy_equipment'
        },
        {
          text: 'Pusher Tug',
          color: 'primary',
          value: 'pusher_tug'
        },
        {
          text: 'Pusher Barge',
          color: 'primary',
          value: 'pusher_barge'
        }
      ],
      select_all_fleets: false,
      loading_fleet: false,
      editingNode: null,
      fleet_params: {
        page: 1,
        showAll: true,
        search: ''
      },
      fleets: [],
      checkedFleets: [],
      color:
      {
        color: 'success',
        checked: false
      },
      tree: [
        {
          'text': 'Docking/Undocking',
          'opened': true,
          'selected': false,
          'children': [
            {
              'text': 'Up slip',
              'selected': false
            },
            {
              'text': 'Downslip',
              'selected': false
            }
          ]
        },
        {
          'text': 'Sand blasting',
          'opened': true,
          'selected': false,
          'children': [
            {
              'text': 'Hard Scraping Flat Bottom to Vertical Bottom Area',
              'selected': false
            },
            {
              'text': 'HP fresh washing Bottom + Topside',
              'selected': false
            },
            {
              'text': 'Sand blasting Bottom Plate',
              'selected': false
            },
            {
              'text': 'Sand blasting Hull Side Shell Bottom',
              'selected': false
            },
            {
              'text': 'Sand blasting Hull Side Shell Topside & Bulwark',
              'selected': false
            },
            {
              'text': 'Sand blasting Main deck',
              'selected': false
            },
            {
              'text': 'Sand blasting Inner side Bullwark',
              'selected': false
            }
          ]
        },
        {
          'text': 'Painting',
          'opened': true,
          'selected': false,
          'children': [
            {
              'text': 'Bottom Coating (AC ke 1) ',
              'selected': false
            },
            {
              'text': 'Bottom Coating (AC ke 2), sealer coat',
              'selected': false
            },
            {
              'text': 'Bottom Coating (AF ke 1)',
              'selected': false
            },
            {
              'text': 'Top side Coating (AC ke 1)',
              'selected': false
            },
            {
              'text': 'Top side Coating (1 x Finish Coat)',
              'selected': false
            },
            {
              'text': 'Painting shipname, plimsolmark, draftmark, Port registry dan imo number',
              'selected': false
            }
          ]
        },
        {
          'text': 'Hull Construction',
          'opened': true,
          'selected': false,
          'children': [
            {
              'text': 'Bongkar eyelug',
              'selected': false
            },
            {
              'text': 'Fabrication eyelug',
              'selected': false
            },
            {
              'text': 'Replace eyelug',
              'selected': false
            },
            {
              'text': 'Bongkar tire fender',
              'selected': false
            },
            {
              'text': 'Renew tire fender',
              'selected': false
            },
            {
              'text': 'Pasang tire fender',
              'selected': false
            },
            {
              'text': 'Bongkar push tug',
              'selected': false
            },
            {
              'text': 'Fabrication push tug',
              'selected': false
            },
            {
              'text': 'Renew push tug',
              'selected': false
            },
            {
              'text': 'Bongkar anchor pocket',
              'selected': false
            },
            {
              'text': 'Fabrikasi anchor pocket',
              'selected': false
            },
            {
              'text': 'Pemasangan anchor pocket',
              'selected': false
            },
            {
              'text': 'Bongkar fender pipe',
              'selected': false
            },
            {
              'text': 'Fabrikasi fender pipe',
              'selected': false
            },
            {
              'text': 'Pemasangan fender pipe',
              'selected': false
            }
          ]
        },
        {
          'text': 'Machinery Equipment',
          'opened': true,
          'selected': false,
          'children': [
            {
              'text': 'General Overhaul ME (P/S)',
              'selected': false
            },
            {
              'text': 'Kalibrasi injector (P/S)',
              'selected': false
            },
            {
              'text': 'Test Fungsi/ sea trial',
              'selected': false
            },
            {
              'text': 'Ganti baru dinamo charge (P/S)',
              'selected': false
            },
            {
              'text': 'Service alternator (P/S)',
              'selected': false
            }
          ]
        },
        {
          'text': 'Outfitting',
          'opened': true,
          'selected': false,
          'children': [
            {
              'text': 'Install new accomodation scuper pipe P/S',
              'selected': false
            },
            {
              'text': 'Replace platform ladder FP',
              'selected': false
            },
            {
              'text': 'Replace store door and rubber',
              'selected': false
            },
            {
              'text': 'Service hinge hatch door cabin crew',
              'selected': false
            },
            {
              'text': 'Perbaikan pondasi stopper winch jangkar',
              'selected': false
            },
            {
              'text': 'Ganti baru kampas rem winch jangkar',
              'selected': false
            },
            {
              'text': 'Ganti baru rantai jangkar sebanyak 5 segel',
              'selected': false
            },
            {
              'text': 'Modifikasi push fender haluan (naik 400mm)',
              'selected': false
            },
            {
              'text': 'Ganti baru anak tangga dan handle main deck',
              'selected': false
            },
            {
              'text': 'Service roda towing hub',
              'selected': false
            },
            {
              'text': 'Service release dan stopper towing hub',
              'selected': false
            },
            {
              'text': 'Ganti pintu hatch ER',
              'selected': false
            },
            {
              'text': 'Ganti manhole',
              'selected': false
            },
            {
              'text': 'Ganti pipa dewi-dewi',
              'selected': false
            },
            {
              'text': 'Service toggle pintu masuk ruang akomodasi',
              'selected': false
            }
          ]
        },
        {
          'text': 'Piping & Valve',
          'opened': true,
          'selected': false,
          'children': [
            {
              'text': 'Service Pipa SW cooling ME  P',
              'selected': false
            }
          ]
        },
        {
          'text': 'Propultion & steering system',
          'opened': true,
          'selected': false
        },
        {
          'text': 'Navigation & Electrical',
          'opened': true,
          'selected': false
        }
      ],
      costDocking:[
        {
          title: 'Cost Docking All Fleet',
          bodyData: {
            chart: {
              renderTo: 'high-3d-chart',
              type: 'column',
              height: 500,
              options3d: {
                enabled: true,
                alpha: 15,
                beta: 15,
                depth: 50,
                viewDistance: 25
              }
            },
            title: {
              text: 'Cost All Fleet'
            },
            xAxis: {
                type: 'datetime'
            },
            plotOptions: {
                series: {
                    pointStart: Date.UTC(2022, 0, 1),
                    pointIntervalUnit: 'month'
                }
            },
            series:
            [
              {
                name: "Realisasi",
                data: [105000000, 176000000, 125500000, 162000000, 126000000, 185000000],
                color: '#2D4A9E'
              },
              {
                name: "Plan",
                data: [125500000, 162000000, 126000000, 185000000, 105000000, 176000000],
                color: '#F7941D'
              }
            ]
          }
        },
      ],
      costTugboat:
      [
        {
          title: 'Cost Tugboat',
          bodyData: {
            chart: {
              renderTo: 'high-3d-chart',
              type: 'column',
              height: 180,
              options3d: {
                enabled: true,
                alpha: 15,
                beta: 15,
                depth: 50,
                viewDistance: 25
              }
            },
            title: {
              text: 'Tugboat'
            },
            xAxis: {
                type: 'datetime'
            },
            plotOptions: {
                series: {
                    pointStart: Date.UTC(2022, 0, 1),
                    pointIntervalUnit: 'month'
                }
            },
            series:
            [
              {
                name: "Realisasi",
                data: [165200000, 126000000, 176000000, 124000000, 127000000, 128800000],
                color: '#2D4A9E'
              },
              {
                name: "Plan",
                data: [176000000, 124000000, 128800000, 165200000, 126000000, 127000000],
                color: '#F7941D'
              }
            ]
          }
        },
      ],
      costBarge:
      [
        {
          title: 'Cost Barge',
          bodyData: {
            chart: {
              renderTo: 'high-3d-chart',
              type: 'column',
              height: 180,
              options3d: {
                enabled: true,
                alpha: 15,
                beta: 15,
                depth: 50,
                viewDistance: 25
              }
            },
            title: {
              text: 'Barge'
            },
            xAxis: {
                type: 'datetime'
            },
            plotOptions: {
                series: {
                    pointStart: Date.UTC(2022, 0, 1),
                    pointIntervalUnit: 'month'
                }
            },
            series:
            [
              {
                name: "Realisasi",
                data: [176000000, 124000000, 127000000, 165200000, 126000000, 128800000],
                color: '#ED1C24'
              },
              {
                name: "Plan",
                data: [165200000, 126000000, 176000000, 124000000, 127000000, 128800000],
                color: '#27AAE1'
              }
            ]
          }
        },
      ],
      costPusherTug:
      [
        {
          title: 'Cost Pusher Tug',
          bodyData: {
            chart: {
              renderTo: 'high-3d-chart',
              type: 'column',
              height: 180,
              options3d: {
                enabled: true,
                alpha: 15,
                beta: 15,
                depth: 50,
                viewDistance: 25
              }
            },
            title: {
              text: 'Pusher Tug'
            },
            xAxis: {
                type: 'datetime'
            },
            plotOptions: {
                series: {
                    pointStart: Date.UTC(2022, 0, 1),
                    pointIntervalUnit: 'month'
                }
            },
            series:
            [
              {
                name: "Realisasi",
                data: [165200000, 126000000, 176000000, 124000000, 127000000, 128800000],
                color: '#27AAE1'
              },
              {
                name: "Plan",
                data: [124000000, 127000000, 128800000, 165200000, 126000000, 176000000],
                color: '#8DC63F'
              }
            ]
          }
        },
      ],
      costPusherBarge:
      [
        {
          title: 'Cost Pusher Barge',
          bodyData: {
            chart: {
              renderTo: 'high-3d-chart',
              type: 'column',
              height: 180,
              options3d: {
                enabled: true,
                alpha: 15,
                beta: 15,
                depth: 50,
                viewDistance: 25
              }
            },
            title: {
              text: 'Pusher Barge'
            },
            xAxis: {
                type: 'datetime'
            },
            plotOptions: {
                series: {
                    pointStart: Date.UTC(2022, 0, 1),
                    pointIntervalUnit: 'month'
                }
            },
            series:
            [
              {
                name: "Realisasi",
                data: [126000000, 176000000, 165200000, 124000000, 127000000, 128800000],
                color: '#009444'
              },
              {
                name: "Plan",
                data: [124000000, 127000000, 128800000, 126000000, 176000000, 165200000],
                color: '#FBB040'
              }
            ]
          }
        },
      ],
    }
  },

  // FLEET MODAL SELECTED
  watch: {
    select_all_fleets: {
      deep: true,
      handler: function (checked) {
        this.checkedFleets = []

        if (checked) {
          this.fleets.forEach((f) => this.checkedFleets.push(f.id))
        }
      }
    }
  },
  created () {
    this.fetchFleets = _.debounce(this.fetchFleets, 1000)
  },
  methods: {
    ...fleetsActions,
    async fetchFleets () {
      this.loading_fleet = true
      let params = this.$options.filters.cleanObject(this.fleet_params)
      let res = await this.getFleets(params)

      if (!res.status) {
        this.loading_fleet = false
        this.fleets = []
      } else {
        this.loading_fleet = false
        this.fleets = res.data
      }
    },
    withPopper (dropdownList, component, { width }) {
      dropdownList.style.width = width
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1]
            }
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn ({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              )
            }
          }
        ]
      })
      return () => popper.destroy()
    },
    itemClick (node) {
      console.log(node.model.text + ' clicked !')
    },
    customItemClick: function (node, item, e) {
      e.stopPropagation()
      var index = node.parentItem.indexOf(item)
      node.parentItem.splice(index, 1)
    },
    customItemClickWithCtrl: function () {
      console.log('click + ctrl')
    }
  }
}
</script>