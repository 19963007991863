<template>
  <b-container fluid>
    <!-- Tab -->
    <!-- <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"
              ><strong>SERVICE AND REPAIR MASTER DATA</strong></h4
            >
          </template>
          <template v-slot:body>
          </template>
        </iq-card>
      </b-col>
    </b-row> -->
    <tab-nav :pills="true" class="button-container bg-white rounded mb-4 px-3 btn-group">
      <tab-nav-items
        v-for="(item, index) in listNav"
        :key="index"
        class="p-0 text-center py-3 rounded"
        :active="item.active"
        :href="item.href"
        :title="item.title"
      />
    </tab-nav>
    <!-- End Tab -->
    <div id="pills-tabContent-2" class="tab-content">
      <tab-content-item :active="true" id="task-type">
        <SrMasterTaskType />
      </tab-content-item>
      <tab-content-item :active="false" id="category">
        <SrMasterCategory />
      </tab-content-item>
      <tab-content-item :active="false" id="classification">
        <SrMasterClassification />
      </tab-content-item>
      <tab-content-item :active="false" id="cause">
        <SrMasterCause />
      </tab-content-item>
      <tab-content-item :active="false" id="kind-task">
        <SrMasterKindTask />
      </tab-content-item>
      <tab-content-item :active="false" id="activity">
        <SrMasterActivity />
      </tab-content-item>
      <tab-content-item :active="false" id="reject-reason">
        <SrMasterRejectReason />
      </tab-content-item>
    </div>
  </b-container>
</template>

<script>
import { srActions } from '@src/Utils/helper'

import SrMasterTaskType from './SrMasterTaskType.vue'
import SrMasterActivity from './SrMasterActivity.vue'
import SrMasterCategory from './SrMasterCategory.vue'
import SrMasterCause from './SrMasterCause.vue'
import SrMasterClassification from './SrMasterClassification.vue'
import SrMasterKindTask from './SrMasterKindTask.vue'
import SrMasterRejectReason from './SrMasterRejectReason.vue'

import _ from 'lodash'

export default {
  name: 'ServiceRepairMasterData',
  components: {
    SrMasterTaskType,
    SrMasterActivity,
    SrMasterCategory,
    SrMasterCause,
    SrMasterClassification,
    SrMasterKindTask,
    SrMasterRejectReason,
  },
  data() {
    return {
      listNav: [
        {
          title: 'TASK TYPE',
          href: '#task-type',
          active: true,
        },
        {
          title: 'CATEGORY',
          href: '#category',
          active: false,
        },
        {
          title: 'CLASSIFICATION',
          href: '#classification',
          active: false,
        },
        {
          title: 'CAUSE',
          href: '#cause',
          active: false,
        },
        {
          title: 'KIND TASK',
          href: '#kind-task',
          active: false,
        },
        {
          title: 'ACTIVITY',
          href: '#activity',
          active: false,
        },
        {
          title: 'REJECT',
          href: '#reject-reason',
          active: false,
        },
      ],
    }
  },
}
</script>

<style scoped>
.button-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
</style>
