var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    staticClass: "m-2",
    attrs: {
      "lg": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": _vm.fleetParamsAvailable ? 2 : 4
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Periode Certificate:",
      "label-for": "typeCertificate"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.remarks_filter,
      "value-field": "value",
      "text-field": "label"
    },
    model: {
      value: _vm.certificate_params.type,
      callback: function callback($$v) {
        _vm.$set(_vm.certificate_params, "type", $$v);
      },
      expression: "certificate_params.type"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": _vm.fleetParamsAvailable ? 3 : 4
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Category",
      "label-for": "kind-fleet"
    }
  }, [_c('v-multiselect', {
    attrs: {
      "options": _vm.certificate_categories,
      "multiple": true,
      "group-values": "subCategory",
      "group-label": "name",
      "group-select": true,
      "placeholder": "",
      "track-by": "id",
      "label": "name"
    },
    model: {
      value: _vm.certificate_params.certificateCategoryIds,
      callback: function callback($$v) {
        _vm.$set(_vm.certificate_params, "certificateCategoryIds", $$v);
      },
      expression: "certificate_params.certificateCategoryIds"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v("Oops! No elements found. Consider changing the search query.")])])], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": _vm.fleetParamsAvailable ? 3 : 4
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Status Certificate:",
      "label-for": "statusCertificate"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "options": _vm.certificate_statuses,
      "value-field": "value",
      "text-field": "text"
    },
    on: {
      "change": _vm.onStatusCertificateChange
    },
    model: {
      value: _vm.certificate_params.status,
      callback: function callback($$v) {
        _vm.$set(_vm.certificate_params, "status", $$v);
      },
      expression: "certificate_params.status"
    }
  })], 1)], 1), _c('b-col', {
    class: !_vm.fleetParamsAvailable ? 'mt-4' : '',
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Search Certifiicate:",
      "label-for": "kind-fleet"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "fleet_name",
      "type": "text",
      "placeholder": "Search..."
    },
    model: {
      value: _vm.certificate_params.search,
      callback: function callback($$v) {
        _vm.$set(_vm.certificate_params, "search", $$v);
      },
      expression: "certificate_params.search"
    }
  })], 1)], 1), _c('b-col', {
    class: !_vm.fleetParamsAvailable ? 'mt-4' : '',
    attrs: {
      "cols": "12",
      "md": "1"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Apply",
      "label-for": "button_search"
    }
  }, [_c('b-button', {
    staticClass: "mb-3 mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.fetchCertificates.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-filter"
  })])], 1)], 1)], 1)], 1), _c('b-col', {
    staticClass: "m-2",
    attrs: {
      "md": "12"
    }
  }, [_vm.loading_certificates ? _c('div', {
    staticClass: "text-center my-5"
  }, [_c('b-spinner', {
    attrs: {
      "type": "grow",
      "label": "Spinning"
    }
  })], 1) : [_vm.certificates.length > 0 ? [_c('b-row', [_c('b-col', [_vm.menuPermission('export') || _vm.lsProfile.menu[1].child[0].child[0].export == true ? _c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.doExportCertificateVehicles
    }
  }, [!_vm.loading_export_certificates ? [_c('i', {
    staticClass: "fa fa-file-excel"
  }), _vm._v("   Export ")] : [_c('span', {
    staticClass: "spinner-border spinner-border-sm",
    attrs: {
      "role": "status",
      "aria-hidden": "true"
    }
  }), _vm._v(" Exporting... ")]], 2) : _vm._e()], 1), _c('b-col', [_c('div', {
    staticClass: "justify-content-end text-right"
  }, [_c('strong', [_vm._v("Display :  ")]), _c('div', {
    staticClass: "btn-group"
  }, [_c('button', {
    staticClass: "btn btn-outline-primary",
    class: {
      active: _vm.view_style === 'list'
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.view_style = 'list';
      }
    }
  }, [_c('span', {
    staticClass: "fa fa-list"
  }), _vm._v(" List ")]), _c('button', {
    staticClass: "btn btn-outline-primary",
    class: {
      active: _vm.view_style === 'grid'
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.view_style = 'grid';
      }
    }
  }, [_c('span', {
    staticClass: "fa fa-th-large"
  }), _vm._v(" Grid ")]), _c('button', {
    staticClass: "btn btn-outline-primary",
    class: {
      active: _vm.view_style === 'calendar'
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.view_style = 'calendar';
      }
    }
  }, [_c('span', {
    staticClass: "fa fa-calendar"
  }), _vm._v(" Calendar ")])])])])], 1), _vm.view_style == 'grid' ? _c('b-row', _vm._l(_vm.certificates, function (cert) {
    return _c('b-col', {
      key: cert.code + cert.id,
      attrs: {
        "sm": "12",
        "md": "3"
      }
    }, [_c('b-card', {
      staticClass: "iq-mb-3"
    }, [_c('b-card-body', [_c('div', {
      staticClass: "doc-profile text-center"
    }, [_c('img', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "img-fluid avatar-80 button-pointer",
      attrs: {
        "title": "View Details",
        "src": require('@assets/images/flaticon/certificate.png'),
        "alt": "profile"
      },
      on: {
        "click": function click($event) {
          return _vm.toggleModalDetail(true, cert);
        }
      }
    })]), _c('div', {
      staticClass: "iq-doc-info mt-3 text-center"
    }, [_c('h4', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "text-default button-pointer",
      attrs: {
        "title": "View Details"
      },
      on: {
        "click": function click($event) {
          return _vm.toggleModalDetail(true, cert);
        }
      }
    }, [_vm._v(" " + _vm._s(cert.certificate.name))]), cert.certificate.certificateType == 'FULL_TERM' && _vm.$options.filters.getDateDifferenceInMonthWithFormat(cert.expired, cert.dateIssued) <= 12 ? [_c('br'), _c('b-badge', {
      staticClass: "mr-1",
      attrs: {
        "pill": "",
        "variant": "secondary"
      }
    }, [_vm._v("INTERM ")])] : _vm._e(), _c('h6', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "text-primary button-pointer",
      attrs: {
        "title": "View Details"
      },
      on: {
        "click": function click($event) {
          return _vm.toggleModalDetail(true, cert);
        }
      }
    }, [_vm._v(" " + _vm._s(cert.vehicle.name) + " ")]), _c('p', {
      staticClass: "mb-0"
    }, [_c('small', [_vm._v("Place Issued:")]), _vm._v(" " + _vm._s(cert.placeIssued) + " ")]), _c('p', {
      staticClass: "mb-0"
    }, [_c('small', [_vm._v("Date Issued:")]), _vm._v(" " + _vm._s(cert.dateIssued) + " ")]), cert.expired ? _c('p', {
      staticClass: "mb-0"
    }, [_c('small', [_vm._v("Expired: ")]), _vm._v(_vm._s(cert.expired))]) : _vm._e(), cert.endorsement.length > 0 ? _c('p', [_c('small', [_vm._v("Next Endorsement: ")]), _vm._v(_vm._s(_vm.findEndorsement(cert.endorsement, cert.lastEndorsementNumber)))]) : _vm._e()], 2), _c('div', {
      staticClass: "iq-doc-description mt-2 text-center"
    }, [_c('p', {
      staticClass: "mb-1"
    }, [cert.certificate.certificateType === 'PERMANENT' ? [_c('b-badge', {
      staticClass: "mr-1",
      attrs: {
        "variant": "dark"
      }
    }, [_vm._v("Permanent ")])] : cert.certificate.certificateType === 'SHORT_TERM' ? [_c('b-badge', {
      staticClass: "mr-1",
      attrs: {
        "variant": "secondary"
      }
    }, [_vm._v("Short Term ")])] : cert.certificate.certificateType === 'FULL_TERM' ? [_c('b-badge', {
      staticClass: "mr-1",
      attrs: {
        "variant": "light"
      }
    }, [_vm._v("Full Term ")])] : [_c('b-badge', {
      staticClass: "mr-1",
      attrs: {
        "variant": "primary"
      }
    }, [_vm._v("Provisional ")])]], 2), _c('div', {
      staticClass: "mb-1"
    }, [_c('span', {
      staticClass: "badge badge-pill text-capitalize",
      style: "background-color: ".concat(cert.status.color, "; color: white")
    }, [_vm._v(_vm._s(cert.status.text))])])]), _c('b-row', {
      staticClass: "mt-3 text-center"
    }, [_c('b-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('b-button-group', {
      staticClass: "mr-2"
    }, [cert.file && cert.file.url && (_vm.menuPermission('export') || _vm.lsProfile.menu[1].child[0].child[0].export == true) ? _c('b-button', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      attrs: {
        "variant": "dark",
        "title": "Download Certificate"
      },
      on: {
        "click": function click($event) {
          return _vm.downloadCertificate(cert.file.url);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-download"
    })]) : _vm._e(), !['PROVISIONAL'].includes(cert.certificate.certificateType) ? [cert.certificate.certificateType === 'PERMANENT' && cert.status.showEndorsement && (_vm.menuPermission('update') && _vm.menuPermission('add') || _vm.lsProfile.menu[1].child[0].child[0].add == true && _vm.lsProfile.menu[1].child[0].child[0].update == true) ? _c('b-dropdown', {
      attrs: {
        "variant": "primary"
      }
    }, [_c('template', {
      slot: "button-content"
    }, [_vm._v(" Action "), _c('i', {
      staticClass: "uil uil-angle-down"
    })]), [_c('b-dropdown-item', {
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.toggleModalEndorsement(true, cert);
        }
      }
    }, [_vm._v("Proses Endorsement ")])]], 2) : _c('b-dropdown', {
      attrs: {
        "variant": "primary"
      }
    }, [_c('template', {
      slot: "button-content"
    }, [_vm._v(" Action "), _c('i', {
      staticClass: "uil uil-angle-down"
    })]), cert.status.showRenewal && (_vm.menuPermission('update') && _vm.menuPermission('add') || _vm.lsProfile.menu[1].child[0].child[0].add == true && _vm.lsProfile.menu[1].child[0].child[0].update == true) ? _c('b-dropdown-item', {
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          return _vm.toggleModalRenewal(true, cert);
        }
      }
    }, [_vm._v("Proses Perpanjang ")]) : _vm._e(), cert.status.showLog ? _c('b-dropdown-item', {
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.toggleModalLog(true, cert);
        }
      }
    }, [_vm._v("Log History ")]) : _vm._e()], 2)] : _vm._e()], 2)], 1)], 1)], 1)], 1)], 1);
  }), 1) : _vm.view_style == 'list' ? _c('table', {
    staticClass: "table table-striped mt-4"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticStyle: {
      "width": "30px"
    }
  }, [_vm._v("No")]), _c('th', [_vm._v("Fleet")]), _c('th', [_vm._v("Certificate")]), _vm.fleetParamsAvailable ? _c('th', {
    staticStyle: {
      "width": "120px"
    }
  }, [_vm._v("Issued")]) : _vm._e(), _c('th', {
    staticStyle: {
      "width": "120px"
    }
  }, [_vm._v("Expired")]), _c('th', [_vm._v("Next Endorse")]), _c('th', [_vm._v("Status")]), _c('th', [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.certificates, function (cert, index) {
    return _c('tr', {
      key: cert.code + cert.id
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(" " + _vm._s(cert.vehicle.name) + " "), cert.vehicleOnDocking ? [_c('b-badge', {
      staticClass: "mr-1",
      attrs: {
        "variant": "warning"
      }
    }, [_vm._v("Docking ")])] : _vm._e()], 2), _c('td', [_c('span', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "text-uppercase button-pointer text-primary",
      attrs: {
        "title": "View Details"
      },
      on: {
        "click": function click($event) {
          return _vm.toggleModalDetail(true, cert);
        }
      }
    }, [_vm._v(_vm._s(cert.certificate.name))]), _c('br'), cert.certificate.certificateType === 'PROVISIONAL' ? _c('b-badge', {
      staticClass: "mr-1",
      attrs: {
        "variant": "secondary"
      }
    }, [_vm._v(" PERMANENT (NO ENDORS) ")]) : _c('b-badge', {
      staticClass: "mr-1",
      attrs: {
        "variant": "secondary"
      }
    }, [_vm._v(" " + _vm._s(cert.certificate.certificateType.replace('_', ' ')) + " ")]), cert.certificate.certificateType == 'FULL_TERM' && _vm.$options.filters.getDateDifferenceInMonthWithFormat(cert.expired, cert.dateIssued) <= 12 ? [_c('b-badge', {
      staticClass: "mr-1",
      attrs: {
        "pill": "",
        "variant": "secondary"
      }
    }, [_vm._v("INTERM ")])] : _vm._e()], 2), _vm.fleetParamsAvailable ? _c('td', [_vm._v(_vm._s(cert.dateIssued ? cert.dateIssued : '-'))]) : _vm._e(), _c('td', [_vm._v(_vm._s(cert.expired ? cert.expired : '-'))]), _c('td', [_vm._v(_vm._s(cert.endorsement.length > 0 ? _vm.findEndorsement(cert.endorsement, cert.lastEndorsementNumber) : '-') + " ")]), _c('td', [_c('span', {
      staticClass: "badge badge-pill text-capitalize",
      style: "background-color: ".concat(cert.status.color, "; color: white")
    }, [_vm._v(_vm._s(cert.status.text))])]), _c('td', [_c('b-button-group', {
      staticClass: "mr-2"
    }, [cert.file && cert.file.url && (_vm.menuPermission('export') || _vm.lsProfile.menu[1].child[0].child[0].export == true) ? _c('b-button', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      attrs: {
        "variant": "dark",
        "title": "Download Certificate"
      },
      on: {
        "click": function click($event) {
          return _vm.downloadCertificate(cert.file.url);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-download"
    })]) : _vm._e(), !['PROVISIONAL'].includes(cert.certificate.certificateType) && !_vm.isChildComponent ? [cert.certificate.certificateType === 'PERMANENT' && cert.status.showEndorsement && (_vm.menuPermission('update') && _vm.menuPermission('add') || _vm.lsProfile.menu[1].child[0].child[0].update == true && _vm.lsProfile.menu[1].child[0].child[0].add == true) ? _c('b-dropdown', {
      attrs: {
        "variant": "primary"
      }
    }, [_c('template', {
      slot: "button-content"
    }, [_vm._v(" Action "), _c('i', {
      staticClass: "uil uil-angle-down"
    })])], 2) : _c('b-dropdown', {
      attrs: {
        "variant": "primary"
      }
    }, [_c('template', {
      slot: "button-content"
    }, [_vm._v(" Action "), _c('i', {
      staticClass: "uil uil-angle-down"
    })]), cert.status.showLog ? _c('b-dropdown-item', {
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.toggleModalLog(true, cert);
        }
      }
    }, [_vm._v("Log History ")]) : _vm._e()], 2)] : _vm._e()], 2)], 1)]);
  }), 0)]) : _c('v-calendar', {
    staticClass: "custom-calendar max-w-full mb-2 mt-4",
    attrs: {
      "masks": _vm.masks,
      "attributes": _vm.attributes,
      "disable-page-swipe": "",
      "is-expanded": ""
    },
    scopedSlots: _vm._u([{
      key: "day-content",
      fn: function fn(_ref) {
        var day = _ref.day,
          attributes = _ref.attributes;
        return [_c('div', {
          staticClass: "flex flex-col h-full z-10 overflow-hidden mb-4 mt-2 text-right"
        }, [_c('span', {
          staticClass: "day-label text-sm text-gray-900"
        }, [_vm._v(_vm._s(day.day))]), _c('div', {
          staticClass: "flex-grow overflow-y-auto overflow-x-auto"
        }, _vm._l(attributes, function (attr) {
          return _c('p', {
            key: attr.key,
            staticClass: "text-xs leading-tight rounded-sm p-1 mt-0 mb-1 text-justify",
            class: attr.customData.class
          }, [_c('strong', [_vm._v(_vm._s(attr.customData.vehicleName))]), _c('br'), attr.customData.title.length > 25 ? _c('span', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              modifiers: {
                "hover": true
              }
            }],
            attrs: {
              "title": attr.customData.title
            }
          }, [_vm._v(" " + _vm._s(attr.customData.title.substring(0, 25).concat('...')) + " ")]) : _c('span', [_vm._v(" " + _vm._s(attr.customData.title) + " ")])]);
        }), 0)])];
      }
    }], null, false, 3207740262)
  }), [_c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "col-6": ""
    }
  }, [_vm._v(" Total: " + _vm._s(_vm.pagination.total) + "   |   Per page: " + _vm._s(_vm.pagination.perPage) + " ")]), _c('b-col', {
    attrs: {
      "col-6": ""
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.pagination.total,
      "per-page": _vm.pagination.perPage,
      "align": "right"
    },
    on: {
      "input": _vm.fetchCertificates
    },
    model: {
      value: _vm.certificate_params.page,
      callback: function callback($$v) {
        _vm.$set(_vm.certificate_params, "page", $$v);
      },
      expression: "certificate_params.page"
    }
  })], 1)], 1)]] : _c('p', {
    staticClass: "text-center my-4"
  }, [_vm._v("No certificates data found. "), (!_vm.vehicleIdParam || _vm.fleetParamsAvailable) && (_vm.menuPermission('add') || _vm.lsProfile.menu[1].child[0].child[0].add == true) ? _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.onAddCertificateVehicle
    }
  }, [_vm._v("Add New")]) : _vm._e()])], _c('ModalDetailCertificate', {
    attrs: {
      "reminders": _vm.reminders
    },
    on: {
      "close": function close($event) {
        return _vm.toggleModalDetail(false);
      }
    }
  }), _c('ModalLogHistory', {
    on: {
      "close": function close($event) {
        return _vm.toggleModalLog(false);
      }
    }
  }), _c('ModalEndorsement', {
    on: {
      "refresh": _vm.fetchCertificates,
      "close": function close($event) {
        return _vm.toggleModalEndorsement(false);
      }
    }
  }), _c('ModalRenewal', {
    on: {
      "refresh": _vm.fetchCertificates,
      "close": function close($event) {
        return _vm.toggleModalRenewal(false);
      }
    }
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }