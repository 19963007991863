<template>
  <div class="m-4 bg-white" style="border-radius: 12px">
    <b-row v-if="maintenanceDetailData" class="p-4">
      <b-col cols="12" class="text-right" v-if="stateAction === 'VIEW_DETAIL'">
        <b-button
          v-if="['REVIEW'].includes(maintenanceDetailData.status) && canReview"
          @click="onReview(maintenanceId, true)"
          variant="info"
          size="sm"
          class="mr-2"
          ><i class="fa fa-eye"></i> Review
        </b-button>
        <b-button
          v-if="
            ['APPROVE'].includes(maintenanceDetailData.status) && canApprove
          "
          @click="onApprove(maintenanceId, true)"
          variant="success"
          size="sm"
          class="mr-2"
          ><i class="fa fa-check"></i> Approve
        </b-button>
        <b-button
          v-if="
            ['TOWARDS', 'OVERDUE'].includes(maintenanceDetailData.status) &&
            canAdd
          "
          @click="onAction('ON_MAINTENANCE')"
          variant="primary"
          size="sm"
          class="mr-2"
          ><i class="las la-tasks"></i>Maintenance
        </b-button>
        <b-button
          v-if="['TOWARDS', 'OVERDUE'].includes(maintenanceDetailData.status)"
          @click="onAction('ON_POSTPONE')"
          variant="warning"
          size="sm"
          class="mr-2"
          ><i class="las la-calendar"></i> Postpone
        </b-button>
        <!-- <b-button v-if="['TOWARDS','OVERDUE'].includes(maintenanceDetailData.status)" @click="onAction('ON_CONVERT')" variant="danger" size="sm" class="mr-2"><i class="las la-anchor"></i> Convert Service & Repair </b-button> -->
      </b-col>
      <b-col cols="12">
        <b-row>
          <!-- NEW UI -->
          <b-col md="6" class="mb-4">
            <div class="d-flex align-items-center">
              <div class="w-50 p-5 text-center">
                <img
                  :src="
                    maintenanceDetailData.vehicle.vehicleType.icon.url ??
                    require('@src/assets/images/Km3FO09.png')
                  "
                  alt="images"
                  style="width: 100%"
                />
              </div>
              <div class="w-50">
                <div class="d-flex">
                  <!-- icon -->
                  <div class="mr-2 mb-2">
                    <IconFleet />
                  </div>
                  <div class="d-flex flex-column align-items-start">
                    <strong class="text-primary"
                      >{{ maintenanceDetailData.vehicle.name }} 🇮🇩</strong
                    >
                    <span>{{
                      maintenanceDetailData.vehicle.company.company
                    }}</span>
                  </div>
                </div>
                <div class="d-flex">
                  <table class="table table-striped" style="font-size: 14px">
                    <tbody>
                      <tr>
                        <td>Fleet Type</td>
                        <th>{{
                          maintenanceDetailData.vehicle.vehicleType.name
                        }}</th>
                      </tr>
                      <tr>
                        <td>Call Sign</td>
                        <th>-</th>
                      </tr>
                      <tr>
                        <td>Flag</td>
                        <th>{{ maintenanceDetailData?.vehicle.flag }}</th>
                      </tr>
                      <tr>
                        <td>Year & Building</td>
                        <th
                          >{{
                            maintenanceDetailData?.vehicle.yearBuildCountry
                          }}
                          / {{ maintenanceDetailData?.vehicle.flag }}</th
                        >
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </b-col>

          <!-- TABS - 2 -->
          <b-col md="6" class="mb-4">
            <CustomNav
              :items="navItems2"
              class="mb-3"
              @item-click="(val) => (viewMenu2 = val)"
            />
            <!-- CONTENT TABS -->
            <b-row>
              <b-col md="12" v-if="viewMenu2 === 'mp'">
                <template
                  v-if="
                    ['ON_MAINTENANCE', 'ON_POSTPONE', 'ON_CONVERT'].includes(
                      stateAction
                    ) && !isEdit
                  "
                >
                  <b-row v-if="stateAction === 'ON_MAINTENANCE'">
                    <b-col cols="4">
                      <b-form-group label="Maintenance Date *">
                        <date-picker
                          style="width: 100%"
                          v-model="formMaintenance.maintenanceDate"
                          value-type="DD-MM-YYYY"
                          format="DD MMM YYYY"
                          type="date"
                          placeholder="Select date"
                        ></date-picker>
                      </b-form-group>
                    </b-col>
                    <b-col cols="4">
                      <b-form-group label="Cost">
                        <input
                          type="number"
                          v-model="formMaintenance.cost"
                          class="form-control"
                          placeholder="0"
                        />
                        <div class="text-right">
                          Rp. {{ numberFormat(formMaintenance.cost) }}
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col cols="4">
                      <b-form-group label="File Maintenance">
                        <b-form-file
                          placeholder="Choose a file"
                          name="select-file-maintenance"
                          @change="uploadFile"
                        ></b-form-file>
                        <small class="text-muted">
                          File Type * :
                          <b-badge variant="warning"
                            >.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs |
                            .pdf</b-badge
                          ><br />
                          File Capacity * :
                          <b-badge variant="warning">2 MB / File</b-badge>
                        </small>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="6"
                      v-if="maintenanceDetailData.trigger === 'COUNTER'"
                    >
                      <b-form-group label="Actual Running Hours *">
                        <input
                          placeholder="0"
                          type="number"
                          v-model="formMaintenance.maintenanceRunningHours"
                          class="form-control"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label="Notes *">
                        <wysiwyg v-model="formMaintenance.notes" />
                        <small
                          >Sign * :
                          <strong>Mandatory (Must Fill)</strong></small
                        >
                      </b-form-group>
                    </b-col>
                    <b-col md="6" class="text-center">
                      <button
                        type="button"
                        @click="onAction('VIEW_DETAIL')"
                        class="btn btn-outline-primary w-100"
                        >Cancel</button
                      >
                    </b-col>
                    <b-col md="6" class="text-center">
                      <button
                        type="button"
                        class="btn btn-primary w-100"
                        @click="saveMaintenance()"
                        >Submit</button
                      >
                    </b-col>
                  </b-row>
                  <b-row v-else-if="stateAction === 'ON_POSTPONE'">
                    <b-col
                      cols="6"
                      v-if="maintenanceDetailData.trigger === 'COUNTER'"
                    >
                      <b-form-group label="Extra Hours *">
                        <input
                          type="number"
                          v-model="formPostpone.extraHours"
                          class="form-control"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="6"
                      v-if="maintenanceDetailData.trigger === 'CALENDAR'"
                    >
                      <b-form-group label="Extra Date *">
                        <input
                          type="date"
                          v-model="formPostpone.extraDate"
                          class="form-control"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label="Reason *">
                        <b-form-textarea
                          rows="3"
                          placeholder="Type Reason"
                          v-model="formPostpone.reason"
                        ></b-form-textarea>
                      </b-form-group>
                    </b-col>
                    <b-col md="6" class="text-center">
                      <button
                        type="button"
                        @click="onAction('VIEW_DETAIL')"
                        class="btn btn-outline-primary w-100"
                        >Cancel</button
                      >
                    </b-col>
                    <b-col md="6" class="text-center">
                      <button
                        type="button"
                        class="btn btn-primary w-100"
                        @click="savePostpone()"
                        >Submit</button
                      >
                    </b-col>
                  </b-row>
                  <b-row v-else-if="stateAction === 'ON_CONVERT'"> </b-row>
                </template>
                <template
                  v-if="
                    ['REVIEW', 'APPROVE', 'FINISH'].includes(
                      maintenanceDetailData.status
                    )
                  "
                >
                  <table class="table table-borderless">
                    <tbody>
                      <tr>
                        <th width="30%">Last Maintenance Date</th>
                        <td width="5%" class="text-center">:</td>
                        <td width="65%">{{
                          maintenanceDetailData.lastMaintenanceDate
                        }}</td>
                      </tr>
                      <tr>
                        <th width="30%">Current Maintenance Date</th>
                        <td width="5%" class="text-center">:</td>
                        <td width="65%" v-if="isEdit">
                          <date-picker
                            style="width: 100%"
                            v-model="formEdit.maintenanceDate"
                            value-type="DD-MM-YYYY"
                            format="DD MMM YYYY"
                            type="date"
                            placeholder="Select date"
                          ></date-picker>
                        </td>
                        <td width="65%" v-else>{{
                          maintenanceDetailData.maintenanceDate
                        }}</td>
                      </tr>
                      <tr v-if="maintenanceDetailData.trigger === 'COUNTER'">
                        <th width="30%">Maintenance Running Hours</th>
                        <td width="5%" class="text-center">:</td>
                        <td width="65%" v-if="isEdit">
                          <b-form-input
                            v-model="formEdit.maintenanceRunningHours"
                            type="number"
                            placeholder="0"
                          ></b-form-input>
                        </td>
                        <td width="65%" v-else
                          ><p v-if="maintenanceDetailData.trigger === 'COUNTER'"
                            >{{
                              maintenanceDetailData.lastMaintenanceHours
                            }}
                            HOURS</p
                          ></td
                        >
                      </tr>
                      <tr>
                        <th width="30%">Attachment</th>
                        <td width="5%" class="text-center">:</td>
                        <td width="65%" v-if="isEdit">
                          <div class="d-flex flex-column">
                            <b-form-file
                              placeholder="Choose a file"
                              name="select-file-maintenance"
                              @change="uploadFile"
                              accept="application/*"
                            ></b-form-file>
                            <span v-if="formEdit.fileName" class="text-primary"
                              >New File: <i class="fa fa-file" />
                              {{ formEdit.fileName }}</span
                            >
                            <span
                              v-if="maintenanceDetailData?.file?.fileName"
                              @click="
                                handleOpenFilePreview(
                                  maintenanceDetailData?.file?.url
                                )
                              "
                              class="text-secondary"
                              >Existed File: <i class="fa fa-file" />
                              {{ maintenanceDetailData?.file?.fileName }}</span
                            >
                          </div>
                        </td>
                        <td width="65%" v-else
                          ><span
                            class="text-primary"
                            @click="
                              handleOpenFilePreview(
                                maintenanceDetailData?.file?.url
                              )
                            "
                            >{{ maintenanceDetailData?.file?.fileName }}</span
                          ></td
                        >
                      </tr>
                      <tr>
                        <th width="30%">Cost</th>
                        <td width="5%" class="text-center">:</td>
                        <td width="65%" v-if="isEdit">
                          <b-input-group prepend="Rp">
                            <b-form-input
                              v-model="formEdit.cost"
                              type="number"
                              placeholder="0"
                            ></b-form-input>
                          </b-input-group>
                          <div class="text-right">
                            Rp. {{ numberFormat(formEdit.cost) }}
                          </div>
                        </td>
                        <td width="65%" v-else
                          >Rp.{{ numberFormat(maintenanceDetailData.cost) }}</td
                        >
                      </tr>
                      <tr>
                        <th class="align-top">Current Notes</th>
                        <td class="text-center align-top">:</td>
                        <td colspan="4" v-if="isEdit">
                          <b-form-textarea
                            v-model="formEdit.notes"
                            placeholder="Type Text"
                          ></b-form-textarea>
                        </td>
                        <td colspan="4" v-else>
                          <span
                            v-if="maintenanceDetailData.status === 'APPROVE'"
                            >{{ maintenanceDetailData.finishNotes }}</span
                          >
                          <span v-else>{{ maintenanceDetailData.notes }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <b-row v-if="isEdit">
                    <b-col md="6" class="text-center">
                      <button
                        type="button"
                        @click="onAction('VIEW_DETAIL')"
                        class="btn btn-outline-primary w-100"
                        >Cancel</button
                      >
                    </b-col>
                    <b-col md="6" class="text-center">
                      <button
                        type="button"
                        class="btn btn-primary w-100"
                        @click="editDoMaintenance()"
                        >Save Update</button
                      >
                    </b-col>
                  </b-row>
                </template>
              </b-col>

              <b-col md="12" v-if="viewMenu2 === 'lh'">
                <div
                  class="overflow-auto d-flex w-100"
                  style="max-height: 400px"
                >
                  <b-row>
                    <b-col lg="12">
                      <div class="p-3 px-3">
                        <div
                          class="log-history px-3"
                          v-for="(state, index) in maintenanceDetailData?.logs"
                          :key="index"
                          :class="{
                            'border-l-blue':
                              index + 1 !== maintenanceDetailData?.logs?.length,
                          }"
                        >
                          <div
                            class="circle-dot"
                            :class="
                              index + 1 == maintenanceDetailData?.logs?.length
                                ? 'left-8'
                                : 'left-10'
                            "
                          >
                            <div class="dot"></div>
                          </div>
                          <div class="d-flex flex-column">
                            <strong class="mr-2">{{ state?.createdBy }}</strong>
                            <span class="text-primary"
                              ><i class="fa fa-calendar" />
                              {{
                                moment(state.createdAt, 'DD-MM-YYYY').format(
                                  'DD MMMM YYYY'
                                )
                              }}</span
                            >
                            <b-badge variant="warning">{{
                              state.status
                            }}</b-badge>
                            <div class="mb-4"></div>
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-col>

              <b-col md="12" v-if="viewMenu2 === 'com'">
                <b-row class="mb-2">
                  <b-col cols="12">
                    <b-form-group label="Notes *">
                      <wysiwyg v-model="formComment.comment" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Attachment">
                      <b-form-file
                        placeholder="Choose a file"
                        disabled
                      ></b-form-file>
                      <small class="text-muted">
                        File Type * :
                        <b-badge variant="warning"
                          >.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs |
                          .pdf</b-badge
                        ><br />
                        File Capacity * :
                        <b-badge variant="warning">2 MB / File</b-badge>
                      </small>
                    </b-form-group>
                  </b-col>
                  <b-col md="6" class="text-right">
                    <button
                      :disabled="!formComment.comment"
                      type="button"
                      class="btn btn-outline-primary w-100"
                      @click="formComment.comment = ''"
                      >Cancel</button
                    >
                  </b-col>
                  <b-col md="6" class="text-right">
                    <button
                      :disabled="!formComment.comment"
                      type="button"
                      class="btn btn-primary w-100"
                      @click="saveComment()"
                      >Submit Comment</button
                    >
                  </b-col>
                </b-row>

                <div
                  class="overflow-auto d-flex w-100"
                  style="max-height: 400px"
                >
                  <b-row v-if="maintenanceDetailData.comments.length > 0">
                    <b-col
                      md="12"
                      class="mb-4"
                      v-for="(
                        comment, commentIndex
                      ) in maintenanceDetailData.comments"
                      :key="commentIndex"
                    >
                      <div class="d-flex flex-column">
                        <span class="text-primary"
                          ><i class="fa fa-calendar" />
                          {{
                            moment(comment.createdAt).format(
                              'DD MMMM YYYY HH:mm'
                            )
                          }}</span
                        >
                        <strong class="mr-2">{{ comment.name }}</strong>
                        <span>{{ comment.comment }}</span>
                      </div>
                      <div class="border my-2 w-100"></div>
                    </b-col>
                  </b-row>
                  <span class="text-muted text-center" v-else
                    >Tidak ada komentar.</span
                  >
                </div>
              </b-col>
            </b-row>
          </b-col>

          <!-- TABS -->
          <b-col md="6" class="mb-4">
            <CustomNav
              :items="navItems"
              class="mb-3"
              @item-click="(val) => (viewMenu1 = val)"
            />
            <!-- CONTENT TABS -->
            <b-row>
              <b-col md="12" v-if="viewMenu1 === 'rh'">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th
                        class="text-truncate"
                        style="background-color: #099bab; color: white"
                        >Engine Name</th
                      >
                      <th
                        class="text-truncate"
                        style="background-color: #099bab; color: white"
                        >Engine Branch</th
                      >
                      <th
                        class="text-truncate"
                        style="background-color: #099bab; color: white"
                        >Last RH Date</th
                      >
                      <th
                        class="text-truncate"
                        style="background-color: #099bab; color: white"
                        >Last RH</th
                      >
                      <th
                        class="text-truncate"
                        style="background-color: #099bab; color: white"
                        >Total RH</th
                      >
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(engine, engineIndex) in maintenanceDetailData
                        .vehicle.powers"
                      :key="engineIndex"
                    >
                      <td class="text-truncate">{{ engine.powerType.name }}</td>
                      <td class="text-truncate"
                        >{{ engine.powerSeries.name }}
                        {{ engine.powerSeries.fuel }}</td
                      >
                      <td class="text-truncate">-</td>
                      <th class="text-truncate"
                        >{{ engine.actualRunningHours }} Hours</th
                      >
                      <th class="text-truncate">-</th>
                    </tr>
                  </tbody>
                </table>
              </b-col>

              <b-col md="12" v-if="viewMenu1 === 'mi'">
                <table class="table table-borderless">
                  <tbody>
                    <tr>
                      <th width="18%">Job Code</th>
                      <td width="2%" class="text-center">:</td>
                      <td width="30%">{{ maintenanceDetailData.jobCode }}</td>
                      <th width="18%">Job Class</th>
                      <td width="2%" class="text-center">:</td>
                      <td width="30%">{{
                        `${maintenanceDetailData.jobClass.jobClassCode} - ${maintenanceDetailData.jobClass.jobClass}`
                      }}</td>
                    </tr>
                    <tr>
                      <th width="18%">Job Type</th>
                      <td width="2%" class="text-center">:</td>
                      <td width="30%"
                        >{{ maintenanceDetailData.jobType.jobTypeCode }}
                        {{ maintenanceDetailData.jobType.description }}</td
                      >
                      <th width="18%">Job Name</th>
                      <td width="2%" class="text-center">:</td>
                      <td width="30%">{{ maintenanceDetailData.jobName }}</td>
                    </tr>
                    <tr>
                      <th width="18%">Type - Serial No</th>
                      <td width="2%" class="text-center">:</td>
                      <td width="30%"
                        >{{
                          maintenanceDetailData.maintenanceMaster.equipmentId
                        }}
                        -
                        {{
                          maintenanceDetailData.maintenanceMaster.equipmentName
                        }}</td
                      >
                      <th width="18%">Interval/Frequency</th>
                      <td width="2%" class="text-center">:</td>
                      <td
                        width="30%"
                        v-if="maintenanceDetailData.trigger === 'CALENDAR'"
                        >{{ maintenanceDetailData.interval }} MONTH</td
                      >
                      <td
                        width="30%"
                        v-if="maintenanceDetailData.trigger === 'COUNTER'"
                        >{{ maintenanceDetailData.interval }} HOURS</td
                      >
                    </tr>
                    <tr>
                      <th width="18%">Trigger</th>
                      <td width="2%" class="text-center">:</td>
                      <td
                        width="30%"
                        v-if="maintenanceDetailData.trigger === 'CALENDAR'"
                        >CALENDAR</td
                      >
                      <td
                        width="30%"
                        v-else-if="maintenanceDetailData.trigger === 'COUNTER'"
                        >COUNTER</td
                      >
                      <th width="18%">Status</th>
                      <td width="2%" class="text-center">:</td>
                      <td width="30%"
                        ><b-badge variant="danger">{{
                          maintenanceDetailData.status
                        }}</b-badge></td
                      >
                    </tr>
                    <tr v-if="maintenanceDetailData.trigger === 'CALENDAR'">
                      <th width="18%">Last Maintenance</th>
                      <td width="2%" class="text-center">:</td>
                      <td width="30%">{{
                        maintenanceDetailData.lastMaintenanceDate
                      }}</td>
                      <th width="18%">{{
                        maintenanceDetailData?.status === 'FINISH'
                          ? 'Finish Maintenance'
                          : 'Possible Maintenance'
                      }}</th>
                      <td width="2%" class="text-center">:</td>
                      <td width="30%">{{
                        maintenanceDetailData.possibleMaintenanceDate
                      }}</td>
                    </tr>
                    <tr>
                      <th width="18%">Last Cost</th>
                      <td width="2%" class="text-center">:</td>
                      <td width="30%">{{
                        maintenanceDetailData.cost?.toLocaleString()
                      }}</td>
                      <th width="18%">Attachment</th>
                      <td width="2%" class="text-center">:</td>
                      <td width="30%">
                        <ul
                          class="profile-img-gallary d-flex flex-wrap p-0 m-0"
                          v-if="maintenanceDetailData.file"
                        >
                          <li
                            class="col-md-1 col-1 pb-1"
                            @click="
                              handleOpenFilePreview(maintenanceDetailData.file)
                            "
                            ><img
                              src="../../assets/images/activity/pdf.png"
                              alt="gallary-image"
                              class="img-fluid"
                          /></li>
                        </ul>
                        <span class="text-muted" v-else>No File Exist..</span>
                      </td>
                    </tr>
                    <tr>
                      <th class="align-top">Job Description</th>
                      <td class="text-center align-top">:</td>
                      <td colspan="4"
                        ><p v-html="maintenanceDetailData.jobDesc"></p
                      ></td>
                    </tr>
                    <tr>
                      <th class="align-top">Last Notes</th>
                      <td class="text-center align-top">:</td>
                      <td colspan="4"
                        ><p v-html="maintenanceDetailData.notes"
                          >{{ maintenanceDetailData.notes || '-' }}"></p
                        ></td
                      >
                    </tr>
                  </tbody>
                </table>
              </b-col>

              <b-col md="12" v-if="viewMenu1 === 'ei'">
                <table class="table table-borderless">
                  <tbody>
                    <tr>
                      <th width="23%">Equipment Name</th>
                      <td width="2%" class="text-center">:</td>
                      <td width="25%">{{
                        maintenanceDetailData.possibleMaintenanceHours
                          ?.componentSetup?.typeNo ?? '-'
                      }}</td>
                      <th width="23%">Type Number</th>
                      <td width="2%" class="text-center">:</td>
                      <td width="25%">{{
                        maintenanceDetailData.possibleMaintenanceHours
                          ?.componentSetup?.typeNo ?? '-'
                      }}</td>
                    </tr>
                    <tr>
                      <th width="23%">Serial Number</th>
                      <td width="2%" class="text-center">:</td>
                      <td width="25%">{{
                        maintenanceDetailData.possibleMaintenanceHours
                          ?.componentSetup?.serialNo ?? '-'
                      }}</td>
                      <th width="23%">Maker Number</th>
                      <td width="2%" class="text-center">:</td>
                      <td width="25%">{{
                        maintenanceDetailData.possibleMaintenanceHours
                          ?.componentSetup?.makerNo ?? '-'
                      }}</td>
                    </tr>
                    <tr>
                      <th width="23%">Maker</th>
                      <td width="2%" class="text-center">:</td>
                      <td width="25%">{{
                        maintenanceDetailData.possibleMaintenanceHours
                          ?.componentSetup?.maker ?? '-'
                      }}</td>
                      <th width="23%">Drawing Number</th>
                      <td width="2%" class="text-center">:</td>
                      <td width="25%">{{
                        maintenanceDetailData.possibleMaintenanceHours
                          ?.componentSetup?.drawingNo ?? '-'
                      }}</td>
                    </tr>
                    <tr>
                      <th width="23%">Certificate</th>
                      <td width="2%" class="text-center">:</td>
                      <td width="25%">{{
                        maintenanceDetailData.possibleMaintenanceHours
                          ?.componentSetup?.haveCertificate
                          ? 'Yes'
                          : 'No'
                      }}</td>
                      <th width="23%"></th>
                      <td width="2%" class="text-center"></td>
                      <td width="25%"></td>
                    </tr>
                    <tr>
                      <th width="23%">Certificate</th>
                      <td width="2%" class="text-center">:</td>
                      <td width="25%">{{
                        maintenanceDetailData.possibleMaintenanceHours
                          ?.componentSetup?.haveCertificate
                          ? 'Yes'
                          : 'No'
                      }}</td>
                      <th width="23%"></th>
                      <td width="2%" class="text-center"></td>
                      <td width="25%"></td>
                    </tr>
                    <tr>
                      <th width="18%">Additional Info</th>
                      <td width="2%" class="text-center">:</td>
                      <td width="25%">{{
                        maintenanceDetailData.possibleMaintenanceHours
                          ?.componentSetup?.additionalInfo ?? '-'
                      }}</td>
                      <th width="23%"></th>
                      <td width="2%" class="text-center"></td>
                      <td width="25%"></td>
                    </tr>
                    <tr>
                      <th width="18%">Descriptions</th>
                      <td width="2%" class="text-center">:</td>
                      <td width="25%">{{
                        maintenanceDetailData.possibleMaintenanceHours
                          ?.componentSetup?.description ?? '-'
                      }}</td>
                      <th width="23%"></th>
                      <td width="2%" class="text-center"></td>
                      <td width="25%"></td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  filesActions,
  fleetsActions,
  maintenanceActions,
} from '@src/Utils/helper'
import { numberFormat } from '@src/plugins/helpers'
import moment from 'moment'
import IconFleet from './Components/Icons/IconFleet'
import CustomNav from '@/src/components/global/CustomNav.vue'

export default {
  name: 'ViewMaintenanceDetail',
  components: { IconFleet, CustomNav },
  props: {
    maintenanceId: {
      type: Number,
      require: true,
    },
    action: {
      type: String,
      default: 'VIEW_DETAIL',
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    jobClassList: {
      type: Array,
      default: () => [],
    },
    onApprove: {
      type: Function,
      default: () => {},
    },
    onReview: {
      type: Function,
      default: () => {},
    },
    jobTypeList: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    maintenanceId: {
      immediate: true,
      async handler(value) {
        if (value) {
          await this.fetchMaintenanceDetail(value)
          await this.getComponentStruct()
        }
      },
    },
    stateAction: {
      immediate: true,
      async handler(value) {
        let title = ''

        switch (value) {
          case 'VIEW_DETAIL':
            title = ['FINISH'].includes(this.maintenanceDetailData?.status)
              ? 'Finished Maintenance Detail'
              : ' Maintenance Detail'
            break
          case 'ON_MAINTENANCE':
            title = 'Create Maintenance'
            break
          case 'ON_POSTPONE':
            title = 'Postponed Maintenance'
            break
          case 'ON_CONVERT':
            title = 'Convert Service & Repair'
            break
          default:
            title = 'Detail Maintenance'
            break
        }

        setTimeout(() => {
          document.getElementById(
            'view-detail-maintenance___title__'
          ).innerText = title
        }, 300)
      },
    },
  },
  data() {
    return {
      stateAction: 'VIEW_DETAIL',
      viewMenu1: 'rh',
      viewMenu2: 'mp',

      checkedFleets: [],
      maintenanceDetailData: null,
      treeInitMaintenance: [],
      formComment: {
        comment: '',
      },
      formMaintenance: {
        fileName: '',
        maintenanceDate: '',
        maintenanceRunningHours: '',
        cost: '',
        notes: '',
      },
      formPostpone: {
        reason: '',
        extraHours: '',
        extraDate: '',
      },
      formEdit: {
        fileName: '',
        maintenanceDate: '',
        maintenanceRunningHours: 0,
        cost: 0,
        notes: '',
      },
      lsProfile: JSON.parse(localStorage.getItem('profile')),
      navItems: [
        { label: 'Running Hours', value: 'rh' },
        { label: 'Last Maintenance Info', value: 'mi' },
        { label: 'Equipment Info', value: 'ei' },
      ],
    }
  },
  computed: {
    maintenancePermission() {
      return this.lsProfile.menu
        .find((menu) => menu.menu === 'Apps')
        ?.child.find((child) => child.menu === 'Technical')
        ?.child.find((child) => child.menu === 'Maintenance')
    },
    canAdd() {
      return this.maintenancePermission
        ? this.maintenancePermission?.add
        : false
    },
    canEdit() {
      return this.maintenancePermission
        ? this.maintenancePermission?.update
        : false
    },
    canApprove() {
      return this.maintenancePermission
        ? this.maintenancePermission?.approval
        : false
    },
    canReview() {
      return this.maintenancePermission
        ? this.maintenancePermission?.review
        : false
    },
    canDelete() {
      return this.maintenancePermission
        ? this.maintenancePermission?.delete
        : false
    },
    canExport() {
      return this.maintenancePermission
        ? this.maintenancePermission?.export
        : false
    },
    navItems2() {
      let menus = [
        { label: 'Log History', value: 'lh' },
        { label: 'Comment', value: 'com' },
      ]
      if (
        ['ON_MAINTENANCE', 'ON_POSTPONE', 'ON_CONVERT'].includes(
          this.stateAction
        ) ||
        ['REVIEW', 'APPROVE', 'FINISH'].includes(
          this.maintenanceDetailData.status
        )
      ) {
        return [{ label: 'Maintenance Progress', value: 'mp' }, ...menus]
      } else {
        return menus
      }
    },
  },
  methods: {
    ...fleetsActions,
    ...maintenanceActions,
    ...filesActions,
    handleOpenFilePreview: function (url) {
      window.open(url, '_blank')
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      }).format(value)
    },
    async fetchMaintenanceDetail(maintenanceId) {
      const res = await this.getMaintenanceDetail(maintenanceId)
      if (res.status === 'success') {
        if (res.data) {
          this.maintenanceDetailData = { ...res.data, maintenanceId }
          this.checkedFleets.push({
            ...this.maintenanceDetailData.vehicle,
            componentSetup: [],
          })
          this.componentIdSelected = this.maintenanceDetailData.component.id
          this.formMaintenance.maintenanceDate =
            this.maintenanceDetailData.possibleMaintenanceDate
          this.maintenanceDetailData.vehicle.powers.sort((a, b) => {
            return a.powerType.id - b.powerType.id
          })
          this.formEdit = {
            fileName: '',
            maintenanceDate: this.maintenanceDetailData.maintenanceDate,
            maintenanceRunningHours:
              this.maintenanceDetailData.maintenanceRunningHours,
            cost: this.maintenanceDetailData.cost,
            notes: this.maintenanceDetailData.notes,
          }
          await this.componentSetupCheck()
        }
      } else {
        this.$swal(`Oops!`, res.data, 'error')
      }
    },
    async componentSetupCheck() {
      const paramsQ = {
        vehicleIds: [],
      }

      if (this.checkedFleets && this.checkedFleets.length > 0) {
        this.checkedFleets.forEach((el) => {
          paramsQ.vehicleIds.push(el.id)
        })
        paramsQ.vehicleIds = paramsQ.vehicleIds.toString()
      }

      const dataNeed = {
        id: this.componentIdSelected,
        params: paramsQ,
      }

      const res = await this.checkHasComponentSetup(dataNeed)

      if (res.status === 'success') {
        if (res.data.data && res.data.data.length > 0) {
          if (this.checkedFleets && this.checkedFleets.length > 0) {
            res.data.data.forEach((el) => {
              this.checkedFleets.forEach((fleet) => {
                if (fleet.id === el.vehicle.id) {
                  fleet.componentSetup = []
                  el.componentSetup.forEach((componentSetup) => {
                    fleet.componentSetup.push(componentSetup)
                  })
                }
              })
            })
          }
        }
      } else {
        this.$swal(`Oops!`, res.data, 'error')
      }
    },
    async getComponentStruct() {
      this.treeInitMaintenance = []
      const paramsQ = {
        vehicleTypeId: 1,
      }
      const res = await this.getComponent(paramsQ)

      if (res.status === 'success') {
        if (
          res.data.maintenanceComponents &&
          res.data.maintenanceComponents.length > 0
        ) {
          const dataTemp = res.data.maintenanceComponents
          dataTemp.forEach((s) =>
            this.treeInitMaintenance.push(this.putStructureObject(s))
          )
        }
      } else {
        this.$swal(`Oops!`, res.data.message, 'error')
      }
    },
    async saveComment() {
      let payload = {
        id: this.maintenanceId,
        comment: this.formComment.comment,
      }
      const res = await this.saveCommentMaintenance(payload)

      if (res.status === 'success') {
        this.formComment.comment = ''
        await this.fetchMaintenanceDetail(this.maintenanceId)
      } else {
        this.$swal(`Oops!`, res.data.message, 'error')
      }
    },
    async saveMaintenance() {
      // if(!this.formMaintenance.cost || !this.formMaintenance.fileName) {
      //   return alert('Lengkapi form maintenance terlebih dahulu')
      // }

      let payload = {
        id: this.maintenanceId,
        data: this.formMaintenance,
      }

      // if(Number(payload.data.maintenanceRunningHours) <= Number(this.maintenanceDetailData?.lastMaintenanceHours)){
      //   return this.$swal(
      //     `Running Hours must be greater than last maintenance`,
      //     'Oops.',
      //     'warning'
      //   )
      // }
      const res = await this.saveDoMaintenance(payload)

      if (res.status === 'success') {
        this.$swal(`Maintenance Berhasil`, '', 'success')
        await this.fetchMaintenanceDetail(this.maintenanceId)
        this.onAction('VIEW_DETAIL')
        this.$emit('save')
      } else {
        this.$swal(`Oops!`, res.data.message, 'error')
      }
    },
    async savePostpone() {
      if (!this.formPostpone.reason) {
        return alert('Lengkapi form postpone terlebih dahulu')
      }

      let payload = {
        id: this.maintenanceId,
        data: {
          ...this.formPostpone,
          extraDate: moment(this.formPostpone.extraDate).format('DD-MM-YYYY'),
        },
      }
      const res = await this.savePostponeMaintenance(payload)

      if (res.status === 'success') {
        this.$swal(`Postpone Berhasil`, '', 'success')
        await this.fetchMaintenanceDetail(this.maintenanceId)
        this.onAction('VIEW_DETAIL')
        this.$emit('save')
      } else {
        this.$swal(`Oops!`, res.data.message, 'error')
      }
    },
    async saveReview() {
      let payload = {
        id: this.maintenanceId,
      }
      const res = await this.saveReviewMaintenance(payload)

      if (res.status === 'success') {
        this.$swal(`Review Berhasil`, '', 'success')
        await this.fetchMaintenanceDetail(this.maintenanceId)
        this.onAction('VIEW_DETAIL')
        this.$emit('save')
      } else {
        this.$swal(`Oops!`, res.data.message, 'error')
      }
    },
    async saveApprove() {
      let payload = {
        id: this.maintenanceId,
      }
      const res = await this.saveApproveMaintenance(payload)

      if (res.status === 'success') {
        this.$swal(`Approve Berhasil`, '', 'success')
        await this.fetchMaintenanceDetail(this.maintenanceId)
        this.onAction('VIEW_DETAIL')
        this.$emit('save')
      } else {
        this.$swal(`Oops!`, res.data.message, 'error')
      }
    },
    async editDoMaintenance() {
      let payload = {
        id: this.maintenanceId,
        data: {
          ...this.formEdit,
        },
      }

      const res = await this.updateDoMaintenance(payload)

      if (res.status === 'success') {
        this.$swal(`Update Maintenance Berhasil`, '', 'success')
        await this.fetchMaintenanceDetail(this.maintenanceId)
        this.onAction('VIEW_DETAIL')
        this.$emit('save')
      } else {
        this.$swal(`Oops!`, res.data.message, 'error')
      }
    },
    async uploadFile(event) {
      const files = [...event.target.files]
      let file = files[0]

      let formData = new FormData()
      formData.append('file', file)

      let res = await this.upload(formData)
      if (res.status === 'success') {
        if (this.isEdit) {
          this.formEdit.fileName = res.data.fileName
        } else {
          this.formMaintenance.fileName = res.data.fileName
        }
      } else {
        if (res.data.message != null) {
          this.$swal('Error', res.data.message, 'error')
        } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
        }
      }
    },

    putStructureObject(object) {
      let children = []

      if (object.child.length > 0) {
        object.child.forEach((child) =>
          children.push(this.putStructureObject(child))
        )
      }

      let selected =
        this.maintenanceDetailData && this.maintenanceDetailData.component
          ? this.maintenanceDetailData.component.id === object.component.id
          : false
      let disabled = this.maintenanceDetailData

      return {
        id: object.component.id,
        text: object.component.componentName,
        disabled,
        opened: true,
        selected,
        children,
      }
    },
    onAction(action) {
      this.resetFormMaintenance()
      this.resetFormPostpone()

      if (action === 'ON_REVIEW') {
        this.$swal({
          title: 'Lakukan Review Maintenance ?',
          text: `Periksa Kembali Seluruh Data`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Review',
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.saveReview()
          }
        })
      } else if (action === 'ON_APPROVE') {
        this.$swal({
          title: 'Lakukan Approve Maintenance ?',
          text: `Periksa Kembali Seluruh Data`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Approve',
        }).then(async (result) => {
          if (result.isConfirmed) {
            await this.saveApprove()
          }
        })
      }

      this.stateAction = action
    },
    resetFormMaintenance() {
      this.formMaintenance = {
        fileName: '',
        maintenanceDate: '',
        maintenanceRunningHours:
          this.maintenanceDetailData?.power?.actualRunningHours ?? 0,
        cost: '',
        notes: '',
      }
    },
    resetFormPostpone() {
      this.formPostpone = {
        reason: '',
        extraHours: '',
        extraDate: '',
      }
    },
    numberFormat(number) {
      return numberFormat(number)
    },
  },
  mounted() {
    this.onAction(this.action)
  },
}
</script>

<style>
.ul-lists-styled ul,
.ul-lists-styled {
  list-style: circle;
}
.log-history {
  /* background: blue; */
  position: relative;
}
.circle-dot {
  border: 1px solid #1f48aa;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  width: 18px;
  position: absolute;
  top: -5px;
}
.circle-dot .dot {
  background: #1f48aa;
  border-radius: 50%;
  height: 10px;
  width: 10px;
}
.border-l-blue {
  border-left: 2px solid #E5E7EB;
}

.left-8 {
  left: -8px;
}
.left-10 {
  left: -10px;
}
</style>
