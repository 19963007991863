var render = function render(){
  var _vm$projectData, _vm$projectData$proje, _vm$projectData2, _vm$tripData, _vm$tripData$trip_att, _vm$tripData2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSaveTripDocument.apply(null, arguments);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [['EDIT', 'ADD'].includes(_vm.action) ? _c('b-button', {
    staticClass: "float-right mr-1",
    attrs: {
      "variant": "primary",
      "size": "sm",
      "type": "submit"
    }
  }, [_c('i', {
    staticClass: "fa fa-save"
  }), _vm._v(" Create Trip Document  ")]) : _vm._e(), _c('b-button', {
    staticClass: "float-right mr-1",
    attrs: {
      "variant": "danger",
      "size": "sm",
      "type": "button"
    },
    on: {
      "click": _vm.closeSlideFormTripDocument
    }
  }, [_c('i', {
    staticClass: "fa fa-ban"
  }), _vm._v(" Cancel  ")])], 1), _c('b-col', {
    staticClass: "p-4",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Document Name"
    }
  }, [['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-input', {
    attrs: {
      "placeholder": "Type Text",
      "type": "text"
    },
    model: {
      value: _vm.form.name_document,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name_document", $$v);
      },
      expression: "form.name_document"
    }
  }) : _vm._e(), ['PREVIEW'].includes(_vm.action) ? _c('strong', [_vm._v(_vm._s(_vm.form.name_document))]) : _vm._e()], 1)], 1), ((_vm$projectData = _vm.projectData) === null || _vm$projectData === void 0 ? void 0 : (_vm$projectData$proje = _vm$projectData.project_contract_plan) === null || _vm$projectData$proje === void 0 ? void 0 : _vm$projectData$proje.length) > 0 ? _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "For Trip"
    }
  }, [['EDIT', 'ADD'].includes(_vm.action) ? _c('v-select', {
    attrs: {
      "label": "shipment_plan_detail_number",
      "track-by": "id",
      "name": "plan_id",
      "options": (_vm$projectData2 = _vm.projectData) === null || _vm$projectData2 === void 0 ? void 0 : _vm$projectData2.project_contract_plan,
      "reduce": function reduce(plan) {
        return plan.id;
      },
      "placeholder": "Select...",
      "required": ""
    },
    model: {
      value: _vm.form.plan_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "plan_id", $$v);
      },
      expression: "form.plan_id"
    }
  }) : _vm._e(), ['PREVIEW'].includes(_vm.action) ? _c('strong', [_vm._v(_vm._s(_vm.form.plan_id))]) : _vm._e()], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Documents"
    }
  }, [['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-file', {
    staticClass: "border p-2",
    attrs: {
      "accept": "application/*",
      "multiple": "",
      "name": "document",
      "id": "document"
    },
    model: {
      value: _vm.form.document,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "document", $$v);
      },
      expression: "form.document"
    }
  }) : _vm._e(), ((_vm$tripData = _vm.tripData) === null || _vm$tripData === void 0 ? void 0 : (_vm$tripData$trip_att = _vm$tripData.trip_attachment) === null || _vm$tripData$trip_att === void 0 ? void 0 : _vm$tripData$trip_att.length) > 0 && ['EDIT', 'PREVIEW'].includes(_vm.action) ? _c('div', {
    staticClass: "d-flex flex-column"
  }, _vm._l((_vm$tripData2 = _vm.tripData) === null || _vm$tripData2 === void 0 ? void 0 : _vm$tripData2.trip_attachment, function (file, fileIndex) {
    return _c('div', {
      key: fileIndex,
      staticClass: "d-flex align-items-center mb-1",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.handleOpenFilePreview("".concat(_vm.apiUrl, "/").concat(file === null || file === void 0 ? void 0 : file.file_location));
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-file text-primary mr-2"
    }), _c('span', {
      staticClass: "text-primary text-truncate"
    }, [_vm._v(_vm._s(file.file_name))])]);
  }), 0) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }