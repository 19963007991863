<template>
  <b-row>
    <b-col md="12">
      <iq-card headerClass="bg-head-budget-docking-category">
        <template v-slot:headerTitle>
          <h4 class="card-title text-white"
            ><strong>BUDGET DOCKING BY CATEGORY</strong></h4
          >
        </template>
        <template v-slot:body>
          <div style="min-height: 350px">
            <ApexChart
              element="chart-budget-docking-by-category"
              :chartOption="chartBudgetDockingCategory"
            />
          </div>
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      chartBudgetDockingCategory: {
        chart: {
          type: 'bar',
          height: 350,
        },
        series: [
          {
            name: 'Emergency Docking',
            data: [44],
            color: '#40A0FC',
          },
          {
            name: 'Intermediate Survey',
            data: [70],
            color: '#1EF627',
          },
          {
            name: 'Special Survey',
            data: [60],
            color: '#FF0000',
          },
        ],
        plotOptions: {
          bar: {
            columnWidth: '100%',
          },
        },
        stroke: {
          width: 10,
          colors: ['#fff'],
        },
        dataLabels: {
          enabled: true,
          offsetX: 5,
          style: {
            fontSize: '18px',
            fontWeight: 700,
          },
          formatter: function (val) {
            return val + '%' // Menambahkan simbol % pada label data
          },
        },
        xaxis: {
          categories: ['Jan'],
          labels: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            formatter: (val) => {
              return val + '%' // memformat label sumbu y menjadi persentase
            },
          },
          max: 100, // set nilai maksimum pada sumbu y
          tickAmount: 5,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + '%'
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: 'bottom',
        },
        fill: {
          type: 'gradient', // Menggunakan gradient fill
          gradient: {
            shade: 'light', // Warna gradient (light atau dark)
            type: 'vertical', // Radial gradient untuk pie chart
            shadeIntensity: 0.5, // Intensitas shade
            gradientToColors: ['#1235C5', '#17A31D','#C30101'], // Warna tujuan gradient
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [10, 100], // Gradient stops dari 0% hingga 100%
          },
        },
      },
    }
  },
  methods: {
    next() {
      this.chartIdx = this.chartIdx + 1
    },
    prev() {
      this.chartIdx = this.chartIdx - 1
    },
  },
}
</script>
<style>
.bg-head-budget-docking-category {
  background: #ff0000;
}
</style>
