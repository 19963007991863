var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex w-100 align-items-center justify-content-end"
  }, [_c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": _vm.openSlideCreateWhatsapp
    }
  }, [_c('i', {
    staticClass: "fa fa-ban mr-2"
  }), _vm._v(" Discard ")]), _c('b-button', {
    attrs: {
      "variant": "primary"
    }
  }, [_c('i', {
    staticClass: "fa fa-check"
  }), _vm._v(" Save ")])], 1)])], 1), _c('div', {
    staticClass: "border w-100 d-flex my-4"
  }), _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-start w-100"
  }, [_c('b-button', {
    staticClass: "mb-2",
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(" Test Template ")])], 1)]), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Type Text"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Message"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Type Text"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Broadcast Template"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Type Text"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Namespace Content"
    }
  })], 1)], 1), _c('div', {
    staticClass: "border w-100 d-flex my-4"
  }), _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {}, [_c('tab-nav', {
    staticClass: "d-flex",
    attrs: {
      "pills": true
    }
  }, [_c('tab-nav-items', {
    staticClass: "p-0",
    attrs: {
      "active": true,
      "href": "#variables",
      "title": "VARIABLES"
    }
  })], 1)], 1)]), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "border w-100 d-flex my-4"
  })]), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "pills-tabContent-2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "content"
    }
  }, [_c('div', [_c('table', {
    staticClass: "table table-striped table-hover"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("NAME")]), _c('th', [_vm._v("DESCRIPTION")])])]), _c('tbody', [_c('tr', {
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function click($event) {
        _vm.$nextTick(function () {
          _vm.$bvModal.show('preview-wa');
        });
      }
    }
  }, [_c('td', [_vm._v("${applicant_name}")]), _c('td', [_vm._v("Applicant Name")])]), _c('tr', {
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function click($event) {
        _vm.$nextTick(function () {
          _vm.$bvModal.show('preview-wa');
        });
      }
    }
  }, [_c('td', [_vm._v("${survey_url}")]), _c('td', [_vm._v("Survey or Test URL")])]), _c('tr', {
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function click($event) {
        _vm.$nextTick(function () {
          _vm.$bvModal.show('preview-wa');
        });
      }
    }
  }, [_c('td', [_vm._v("${survey_name}")]), _c('td', [_vm._v("Survey or Test Name")])])])])]), _c('div', {
    staticClass: "border w-100 d-flex my-2"
  }), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between w-100"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "primary",
      "size": "sm"
    }
  }, [_vm._v("Send Message")]), _c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "primary",
      "size": "sm"
    }
  }, [_vm._v("Log Note")]), _c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "primary",
      "size": "sm"
    }
  }, [_vm._v("Schedule activity")])], 1), _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "mr-4 text-dark"
  }, [_c('i', {
    staticClass: "fa fa-paperclip"
  }), _vm._v(" 0")]), _c('div', {
    staticClass: "mr-4 text-dark"
  }, [_vm._v("Follow")]), _c('div', {
    staticClass: "text-dark"
  }, [_c('i', {
    staticClass: "fa fa-user"
  }), _vm._v(" 0")])])]), _c('div', {
    staticClass: "border w-100 d-flex my-2"
  }), _c('div', {
    staticClass: "d-flex align-items-center justify-content-center w-100"
  }, [_c('strong', [_vm._v("Today")])]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-avatar', {
    staticClass: "mr-2"
  }), _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v("Demo Trans Energy Logistik "), _c('small', [_vm._v("- 2 hours ago")])]), _c('span', [_vm._v("Creating a new record...")])])], 1), _c('div', {
    staticClass: "border w-100 d-flex"
  })])], 1)])], 1)], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "preview-wa",
      "size": "xl",
      "title": "Open: Wa Variable",
      "cancel-title": "Close",
      "hide-footer": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Wa"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Type Text"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Description"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Applicant Name"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "${applicant_name}"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "border w-100 d-flex my-4"
  })]), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "success",
      "size": "sm"
    }
  }, [_vm._v("Save & Close")]), _c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "success",
      "size": "sm"
    }
  }, [_vm._v("Save & New")]), _c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "dark",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        _vm.$nextTick(function () {
          _vm.$bvModal.hide('preview-wa');
        });
      }
    }
  }, [_vm._v("Discard")])], 1)])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }