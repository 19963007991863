<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">
              <strong>Inventory Adjustment</strong>
            </h4>
          </template>
          <template v-slot:headerAction>
            <div class="d-flex align-items-center gap-4 float-right">
              <a class="text-primary mr-4" role="button" style="cursor: pointer" @click="onExportStockAdjustment">
                <i class="fa fa-file-excel mr-2" />
                <span>Export Data</span>
              </a>
              <a class="text-primary mr-4" role="button" style="cursor: pointer"  @click="openslideFormStockAdjustment('ADD', null, 'TRANSFER')">
                <i class="fa fa-plus mr-2" />
                <span>Transfer</span>
              </a>
              <a class="text-primary mr-4" role="button" style="cursor: pointer"  @click="openslideFormStockAdjustment('ADD', null, 'ADJUSTMENT')">
                <i class="fa fa-plus mr-2" />
                <span>Adjustment</span>
              </a>
            </div>
          </template>
          <template v-slot:body>
            <!-- FILTERING StockAdjustment -->
            <b-row class="mb-4">
              <b-col md="12" class="mb-4">
                <div class="w-100 d-flex border" />
              </b-col>
              <b-col md="3">
                <b-form-group label="Search" label-for="search" >
                  <b-form-input
                    type="text"
                    placeholder="Search Code/Name..."
                    name="search"
                    :value="value" 
                    @input="$emit('input', $event.target.value)"
                  ></b-form-input>
                </b-form-group>
              </b-col> 
              <b-col md="4">
                <b-form-group label="Date" label-for="search">
                  <date-picker v-model="dateRange" type="date" range format="DD MMM YYYY" placeholder="Select date range"></date-picker>
                </b-form-group>
              </b-col> 
              <b-col md="1">
                <b-form-group label="Filter" label-for="button_search">
                  <b-button
                    @click="getListStockAdjustment(1, dateRange)"
                    type="button"
                    variant="primary"
                    ><i class="fa fa-filter"></i
                  ></b-button>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- TABLE CONTENT -->
            <b-row>
              <b-col md="12">
                <div v-if="loading" class="alert alert-info">Loading...</div>
                <iq-card> 
                  <div class="overflow-auto d-flex" v-if="list?.data?.length > 0">
                    <table class="table table-striped table-bordered">
                      <thead>
                        <tr>
                            <th class="text-center" style="width: 20px">No</th>
                            <th class="text-center text-truncate">Adjustment Type</th>
                            <th class="text-center text-truncate">Vehicle</th>
                            <th class="text-center text-truncate">Date</th>
                            <th class="text-center text-truncate">Proof Number</th>
                            <th class="text-center text-truncate">Total Qty</th>
                            <th class="text-center text-truncate">Notes</th>
                            <th class="text-center text-truncate">Total Items</th>
                            <th class="text-center" style="width: 20px">#</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(state, index) in list.data" :key="index">
                          <td>{{ index + 1 }}</td>
                          <td class="text-truncate">
                            <a class="text-primary mr-4" role="button" style="cursor: pointer" @click="openSlideDetailAdjustment(state)">
                              <span>{{ state?.type === 'stock_in' ? 'Stock In' : 'Stock Out' }}</span>
                            </a>
                          </td>
                          <td>
                            <div className='d-flex flex-column' v-if="state?.from_vehicle_id">
                              <span class="text-truncate">From: <strong class="text-muted">{{state?.from_vehicle?.name}}</strong></span> <br>
                              <span class="text-truncate">To: <strong class="text-muted">{{state?.to_vehicle?.name}}</strong></span>
                            </div> 
                            <div v-else>
                              <strong class="text-truncate text-muted">{{state?.to_vehicle?.name}}</strong>
                            </div>
                          </td>
                          <td class="text-truncate">{{ state?.date ? moment(state?.date).format('DD MMM YYYY') : '-' }}</td>
                          <td class="text-truncate">{{ state?.proof_number }}</td>
                          <td class="text-center">{{ countTotalQty(state?.details) }}</td>
                          <td>{{ state?.notes ?? '-' }}</td>
                          <td class="text-center">{{ state?.details?.length }}</td>
                          <td >
                            <div class="d-flex align-items-center">
                              <i class="fa fa-pen text-primary mr-3" style="cursor: pointer;" @click="openslideFormStockAdjustment('EDIT', state, 'ADJUSTMENT')" />
                              <i class="fa fa-trash text-danger" style="cursor: pointer;" @click="onDeleteStockAdjustment(state?.id)" />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="mt-3" v-if="list?.data?.length > 0">
                    <b-pagination
                      v-model="page"
                      :total-rows="list?.meta?.totalPage"
                      @change="getListStockAdjustment($event, dateRange)"
                      :per-page="list?.meta?.limit"
                      first-number
                      align="center"
                    ></b-pagination>
                  </div>
                  <p v-else class="text-center my-4">No Stock Adjustment data found.</p>
                </iq-card>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>

      <b-col lg="12">
        <b-sidebar v-model="slideFormStockAdjustment" id="form-StockAdjustment" :title="`${action} Stock Adjustment`" width="100%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
          <template #default="{hide}">
            <b-row class="m-3">
              <b-col cols="12">
                <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 text-primary">Stock</legend>
                    <b-row class="mt-2">
                      <b-col md="12">
                        <FormStock 
                          :action="action" 
                          :type="type" 
                          :edit-id="editId" 
                          :detailData="detailData" 
                          :closeSlide="closeslideFormStockAdjustment" 
                          :refresh-data="refreshData" 
                          :optionsFleets="optionsFleets" 
                          :vehicle_type_id="vehicle_type_id" 
                        />
                      </b-col>
                    </b-row>
                </fieldset>
              </b-col>
            </b-row>
            </template>
        </b-sidebar>
      </b-col>

      <b-col lg="12">
        <b-sidebar v-model="slideDetailAdjustment" id="detail-adjustment" :title="`Preview Detail`" width="100%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
          <template #default="{hide}">
            <b-row class="m-3">
              <b-col cols="12">
                <DetailStockAdjustment 
                  :detailData="detailData" 
                  :closeSlide="clodeSlideDetailAdjustment" 
                />
              </b-col>
            </b-row>
          </template>
        </b-sidebar>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
  import _ from 'lodash'
  import { createPopper } from '@popperjs/core'
  import moment from 'moment'
  import FormStock from './FormStock.vue'
  import DetailStockAdjustment from './DetailStockAdjustment.vue'
  import { busdevActions, fleetsActions } from '@src/Utils/helper'
  import { saveAs } from 'file-saver'

  export default {
    name: 'TabStockAdjustmentAdjustment',
    props:{
      value:{
        type: String,
        default:''
      },
      optionsFleets:{
        type: Array,
        default: () => []
      },   
      vehicle_type_id:{
        type: Number,
        default: null
      },  
      getListStockAdjustment:{
        type: Function,
        default: () => {}
      },    
      refreshMasterData:{
        type: Function,
        default: () => {}
      },   
      list:{
        type: Object,
        default: () => {}
      }, 
    },
    data() {
      return {
        exporting:false,
        loading:false,

        slideDetailAdjustment:false,
        slideFormStockAdjustment:false,

        dateRange:[],
        detailData:null,
        page:1,
        action:'',
        type:'',
        editId:null,
      }
    },
    components:{
      FormStock,
      DetailStockAdjustment,
    },
    computed: {
      addPermission(){
        let hasAccess = true
        // let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
        //   .find(obj => obj.menu === 'Apps').child
        //   .find(obj => obj.menu === 'Business Development').child
        //   .find(obj => obj.menu === 'Busdev StockAdjustment').add

        return hasAccess
      },
      updatePermission(){
        let hasAccess = true
        // let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
        //   .find(obj => obj.menu === 'Apps').child
        //   .find(obj => obj.menu === 'Business Development').child
        //   .find(obj => obj.menu === 'Busdev StockAdjustment').update

        return hasAccess
      },
      deletePermission(){
        let hasAccess = true
        // let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
        //   .find(obj => obj.menu === 'Apps').child
        //   .find(obj => obj.menu === 'Business Development').child
        //   .find(obj => obj.menu === 'Busdev StockAdjustment').delete

        return hasAccess
      },
    },
    async mounted() {
      this.setDefaultDate()
    },

    methods: {
      ...busdevActions,
      ...fleetsActions,
      updateSearching(value) {
        this.$emit('updateSearch', value); // Emit custom event to update search in parent component
      },
      setDefaultDate() {
        const currentDate = moment();

        let dayOfYear = moment().startOf('year');
        let substractedDayOfYear = dayOfYear.subtract(1, 'year')
     
        const startDate = currentDate.month() === 0 ? substractedDayOfYear : moment().startOf('year');
        const endDate = currentDate;

        const formattedStartDate = new Date(startDate);
        const formattedEndDate = new Date(endDate);

        this.dateRange = [formattedStartDate, formattedEndDate]
      },
      async openSlideDetailAdjustment(data){
        let newDetails = []
        if(data?.details?.length > 0){
          const promises = data?.details?.map(async obj => {
            let params = {
              vehicle_id:Number(this.$route.query.vehicleId),
              item_id:Number(obj.item_id),
            }
            let stock = await this.checkStockAvailable(params)

            newDetails.push({
              ...obj,
              stock_available: stock?.data?.data?.stock_available ?? 0,
            })
          })

          await Promise.all(promises)
        }

        this.detailData = {
          ...data,
          details:newDetails,
          total_qty: newDetails.reduce((total, current) => total + current.qty, 0),
        }

        this.slideDetailAdjustment = true
        this.$root.$emit('bv::toggle::collapse', `detail-adjustment`)
      }, 
      clodeSlideDetailAdjustment(){
        this.detailData = null

        this.slideDetailAdjustment = false
        this.$root.$emit('bv::toggle::collapse', `detail-adjustment`)
      }, 
      openslideFormStockAdjustment(action, data, type){
        console.log(data)
        this.action = action
        this.type = data?.type === 'transfer_stock' ? 'TRANSFER' : type
        this.editId = data?.id || null
        this.detailData = data || null

        this.slideFormStockAdjustment = true
        this.$root.$emit('bv::toggle::collapse', `form-StockAdjustment`)
      },
      closeslideFormStockAdjustment(){
        this.type = ''
        this.action = ''
        this.editId = null

        this.slideFormStockAdjustment = true
        this.$root.$emit('bv::toggle::collapse', `form-StockAdjustment`)
      }, 
      refreshData(){
        this.slideFormStockAdjustment = false
        this.$root.$emit('bv::toggle::collapse', `form-StockAdjustment`)
        this.action = ''
        this.type = ''
        this.editId = null

        this.refreshMasterData()
      },
      countTotalQty(details){
        let total = details?.reduce((acc, obj) => {
          acc += Number(obj?.qty)
          return acc
        }, 0) 

        return total
      },

      async onExportStockAdjustment() {
        this.exporting = true
        try {
          let params = {
          }

          let res = await this.exportVehicleStockAdjustment({params, vehicle_id: Number(this.$route.query.vehicleId)})

          console.log(res)

          if(res.status === 'success'){
            saveAs(res.data, 'List Stock Adjustment.xlsx')
          }
        } catch (error) {
          this.$swal(
            'Failed Export!',
            'Something went wrong.',
            'error'
          ) 
        }

        this.exporting = false
      },

      async onDeleteStockAdjustment(id){
        this.$swal({
            title: `Are you sure want to delete this Adjustment?`,
            text: "Yes, i'm sure.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
            const res = await this.deleteStockAdjustment({id})
            if(res.status === false || res.status === 400 || res.status === 500){
                this.$swal(
                    'Failed!',
                    'Something went wrong.',
                    'error'
                )
                return 
            } 
                await this.refreshMasterData()
                this.$swal(
                    'Success!',
                    'Success delete Materia Category.',
                    'success'
                )
            }
        })
      },
    }
  }
</script>