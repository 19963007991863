<template>
  <b-container fluid>
    <form action="#" @submit.prevent="onSave">
      <b-row v-if="edit_loading" class="p-2">
        <b-col md="12">
          <div class="text-center my-5">
              <b-spinner type="grow" label="Spinning"></b-spinner>
          </div>
        </b-col>
      </b-row>

      <!-- <b-row v-if="!edit_loading && ['DETAIL', 'EDIT'].includes(action)" class="mb-4">
        <b-col md="12">
          <div class="d-flex align-items-center justify-content-end w-100">
            <b-button
              v-if="['DETAIL'].includes(action)"
              block
              variant="primary"
              type="button"
              style="width:20%"
              @click="changeAction('EDIT')"
            >
              <i class="fa fa-pen"></i>
              <span>Edit Data</span>
            </b-button>
  
            <b-button
              v-if="['EDIT'].includes(action)"
              block
              variant="danger"
              type="button"
              style="width:20%"
              @click="changeAction('DETAIL')"
            >
              <i class="fa fa-remove"></i>
              <span>Cancel</span>
            </b-button>
          </div>
        </b-col>
      </b-row> -->
    
      <b-row v-if="!edit_loading" class="p-2">
        <b-col md="12">
          <b-form-group
            v-if="['ADD', 'EDIT'].includes(action)"
            label="PIC / USER"
            label-for="pic"
          >
            <v-select
              placeholder="Select pic/user" 
              label="text"
              :options="optionsPIC"
              :reduce="(optionsPIC) => optionsPIC.value"
              @input="onChangePIC"
              v-model="form.pic"
            >
            </v-select>
          </b-form-group>
          <strong v-else>PIC Name: <br />{{ detailData?.sourcePic?.name ?? 'N/A' }}</strong>
        </b-col>
        
        <b-col md="12" v-if="form.pic">
          <b-row class="mt-2">
            <b-col lg="3">
              <b-form-group label="Position">
                <span v-if="form.pic" class="font-weight-bold">{{ positionPic }}</span>
                <i v-else class="text-muted">Choose PIC first.</i>
              </b-form-group>  
            </b-col>
            <b-col lg="9" v-if="form.pic">
              <span class="text-primary font-weight-bold ">Current Fleet</span>
              <div class="overflow-auto border p-2" style="height:250px" v-if="form.pic">
                <b-row v-if="vehiclePic.length > 0">
                  <b-col md="3" sm="4" xs="6" v-for="(state, index) in vehiclePic" :key="index">
                    <div class="d-flex flex-column align-items-center justify-content-center border mb-2 p-1">
                      <img :src="require('@src/assets/images/fleet/PusherBarge.png')" alt="fleet-img" class="img-fluid" />
                      <small class="text-center">{{ state?.name }}</small>
                    </div>
                  </b-col>
                </b-row>
                <i v-else class="text-muted">PIC doesnt have fleets.</i>
              </div>
              <i v-else class="text-muted"><br /> Choose PIC first.</i>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12">
              <div class="border d-flex my-2 w-100" />
            </b-col>
          </b-row>
          <b-row class="my-2">
            <b-col lg="12">
              <b-form-group label="Transfer Type">
                <b-form-radio
                  :disabled="!['ADD', 'EDIT'].includes(action)"
                  id="radio-1"
                  class="custom-radio-color-checked"
                  v-model="form.transfer_type"
                  value="new"
                  inline
                  color="info"
                  name="new"
                  >New Placement</b-form-radio>
                <b-form-radio
                  :disabled="!['ADD', 'EDIT'].includes(action) || vehiclePic.length <= 0"
                  inline
                  id="radio-2"
                  class="custom-radio-color-checked"
                  v-model="form.transfer_type"
                  value="rotate"
                  color="info"
                  name="rotate"
                  >Switch Fleet</b-form-radio>
                <b-form-radio
                  :disabled="!['ADD', 'EDIT'].includes(action) || vehiclePic.length <= 0"
                  id="radio-3"
                  inline
                  class="custom-radio-color-checked"
                  value="reduce"
                  v-model="form.transfer_type"
                  color="info"
                  name="reduce"
                  >Less Fleet</b-form-radio>
              </b-form-group>
            </b-col> 
            <b-col lg="12">
              <span  v-if="['rotate'].includes(form.transfer_type)">Switch with other PIC</span>
              <v-select
                v-if="['rotate'].includes(form.transfer_type) && ['ADD', 'EDIT'].includes(action)"
                placeholder="Select pic" 
                label="text"
                :options="optionsOtherPIC"
                :reduce="(optionsOtherPIC) => optionsOtherPIC.value"
                v-model="form.destinationPic"
                @input="onChangeOtherPIC"
              >
              </v-select>
              <span v-else-if="['DETAIL'].includes(action) && ['rotate'].includes(form.transfer_type)">Ananda Pratama</span>
              <div  class="border d-flex my-2 w-100" v-if="['rotate'].includes(form.transfer_type)" />
              <b-row v-if="['rotate'].includes(form.transfer_type)">
                <b-col sm="6">
                  <span class="text-warning font-weight-bold ">Current Fleet</span>
                </b-col>
                <b-col sm="6" class="text-right">
                  <span class="text-primary font-weight-bold ">Swap Fleet</span>
                </b-col>
                <b-col sm="12" v-if="['ADD', 'EDIT'].includes(action)">
                  <div class="d-flex w-100 align-items-center justify-content-between mb-2" v-for="(state, index) in form.switchVehicles" :key="index">
                    <div class="d-flex w-100 align-items-center">
                      <span class="mr-2">#{{ index + 1 }}</span>
                      <v-select
                        label="name"
                        :options="vehiclePic"
                        :reduce="(vehiclePic) => vehiclePic.id"
                        placeholder="Select fleet..."
                        class="w-100"
                        v-model="state.sourceVehicleId"
                        required
                      ></v-select>
                    </div>
                    <i class="fa fa-arrow-right mx-2" />
                    <div class="d-flex w-100  align-items-center" v-if="form.destinationPic && vehicleOtherPic.length > 0">
                      <v-select
                        label="name"
                        :options="vehicleOtherPic"
                        :reduce="(vehicleOtherPic) => vehicleOtherPic.id"
                        placeholder="Select fleet..."
                        class="w-100"
                        v-model="state.destinationVehicleId"
                        required
                      ></v-select>
                      <i class="fa fa-trash text-danger ml-2" v-if="index !== 0" @click="form.switchVehicles.splice(index, 1)" style="cursor:pointer" />
                    </div>
                    <i v-else-if="vehicleOtherPic.length <= 0 && form.destinationPic" class="text-truncate w-100">This PIC doesnt have Fleets.</i>
                    <i v-else class="text-truncate w-100 ">Choose Other PIC.</i>
                  </div>
                </b-col>
                <b-col sm="12" v-else>
                  <div class="d-flex align-items-center justify-content-between mb-2" >
                    <div class="d-flex align-items-center">
                      <span class="mr-2">#1</span>
                      <b-badge class="border border-dark text-dark text-uppercase mr-1 mb-1" variant="none">TB. TAVIA 001</b-badge>
                    </div>
                    <i class="fa fa-arrow-right mx-2" />
                    <div class="d-flex align-items-center">
                      <b-badge class="border border-primary text-primary text-uppercase mr-1 mb-1" variant="none">TB. TAVIA 002</b-badge>
                    </div>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-2" >
                    <div class="d-flex align-items-center">
                      <span class="mr-2">#2</span>
                      <b-badge class="border border-dark text-dark text-uppercase mr-1 mb-1" variant="none">TB. TAVIA 002</b-badge>
                    </div>
                    <i class="fa fa-arrow-right mx-2" />
                    <div class="d-flex  align-items-center">
                      <b-badge class="border border-primary text-primary text-uppercase mr-1 mb-1" variant="none">TB. TAVIA 003</b-badge>
                    </div>
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-2" >
                    <div class="d-flex align-items-center">
                      <span class="mr-2">#3</span>
                      <b-badge class="border border-dark text-dark text-uppercase mr-1 mb-1" variant="none">TB. TAVIA 003</b-badge>
                    </div>
                    <i class="fa fa-arrow-right mx-2" />
                    <div class="d-flex align-items-center">
                      <b-badge class="border border-primary text-primary text-uppercase mr-1 mb-1" variant="none">TB. TAVIA 004</b-badge>
                    </div>
                  </div>
                </b-col>
                <b-col sm="12" class="mt-2" v-if="['ADD', 'EDIT'].includes(action)">
                  <b-button variant="success" @click="form.switchVehicles.push({sourceVehicleId:null, destinationVehicleId:null})"><i class="fa fa-plus" /> ADD MORE DATA</b-button>
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col lg="12" v-if="['ADD', 'EDIT'].includes(action)" class='mt-2'>
                  <b-form-input size="sm" placeholder="search fleet..." class="w-50 mb-2" />
                  <div class="overflow-auto border p-2" style="height:400px">
                    <b-row v-if="['new'].includes(form.transfer_type)">
                      <b-col lg="2" md="3" sm="4" v-for="(state, index) in newFleets">
                        <div class="d-flex flex-column align-items-center justify-content-center border mb-1 p-1" style="cursor:pointer" @click="onCheckedNewFleets(index)">
                          <b-form-checkbox type="checkbox" class="align-self-start" :checked="state.checked"></b-form-checkbox>
                          <img :src="require('@src/assets/images/fleet/PusherBarge.png')" alt="fleet-img" class="img-fluid" />
                          <small>{{ state.name }}</small>
                        </div>
                      </b-col>
                    </b-row>
                    <b-row v-if="['reduce'].includes(form.transfer_type)">
                      <b-col lg="2" md="3" sm="4" v-for="(state, index) in reduceFleets">
                        <div class="d-flex flex-column align-items-center justify-content-center border mb-1 p-1" style="cursor:pointer" @click="onCheckedReduceFleets(index)">
                          <b-form-checkbox type="checkbox" class="align-self-start" :checked="state.checked"></b-form-checkbox>
                          <img :src="require('@src/assets/images/fleet/PusherBarge.png')" alt="fleet-img" class="img-fluid" />
                          <small>{{ state.name }}</small>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
                <b-col lg="12" v-else>
                  <div class="d-flex w-100 align-items-center flex-wrap ">
                    <b-badge v-for="(fleet, fleetIndex) in detailData?.vehicles" :key="fleetIndex" class="border border-dark text-dark text-uppercase mr-1 mb-1" variant="none">{{ fleet?.name }}</b-badge>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12">
              <div class="border d-flex my-2 w-100" />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6">
              <b-form-group label="Effective Date">
                <b-form-input type="date" v-if="['ADD', 'EDIT'].includes(action)" v-model="form.effectiveDate" required />
                <strong v-else>{{ form.effectiveDate ? moment(form.effectiveDate).format('DD MMMM YYYY') : '-' }}</strong>
              </b-form-group>
            </b-col>
            <b-col lg="6">
              <b-form-group label="Attachment" v-if="['ADD', 'EDIT'].includes(action)">
                <b-form-file v-model="form.attachment" disabled accept="application/pdf"></b-form-file>
                <small class="text-muted"> File Type * :
                  <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs |.pdf</b-badge>
                    <br />
                    File CapaKota * :
                  <b-badge variant="info">3 MB / File</b-badge>
                </small>
              </b-form-group>
              <b-form-group label="Attachment" v-else>
                <h4 class="text-primary"><i class="fa fa-file" /></h4>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12">
              <b-form-group label="Reason">
                <b-form-textarea v-model="form.reason" placeholder="Type TExt" rows="4" v-if="['ADD', 'EDIT'].includes(action)"></b-form-textarea>
                <strong v-else class="text-muted">{{ form?.reason ?? '-' }}</strong>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12">
              <b-form-checkbox type="checkbox" :disabled="!['ADD', 'EDIT'].includes(action)" v-model="form.notifyGmBod"> NOTIFY GENERAL MANAGER & BOD</b-form-checkbox>
            </b-col>
            <b-col lg="12">
              <b-form-checkbox type="checkbox" :disabled="!['ADD', 'EDIT'].includes(action)" v-model="form.notifyEmployee"> NOTIFY EMPLOYEE / PIC</b-form-checkbox>
            </b-col>
          </b-row>
        </b-col>

        <b-col lg="12" v-if="form.pic">
          <div class="border w-100 d-flex my-4"></div>
        </b-col>

        <b-col md="12" class="mb-4" v-if="['ADD', 'EDIT'].includes(action) && form.pic">
          <div class="d-flex align-items-center justify-content-end w-100">
            <b-button
              :disabled="saving"
              block
              variant="primary"
              type="submit"
              style="width:20%"
            >
            <span
              v-if="saving"
              class="spinner-border spinner-border-sm"
            />
            <div v-else>
              <i class="fa fa-save"></i>
              <span>Submit Data</span>
            </div>
            </b-button>
          </div>
        </b-col>
        <b-col md="12" class="text-center" v-if="['ADD', 'EDIT'].includes(action) && !form.pic">
          <strong class="text-muted">Choose PIC First.</strong>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>
  
  <script>
  import { busdevActions, fleetsActions, crewingActions, picMovementActions } from '@src/Utils/helper'
  import _ from 'lodash'
  import moment from 'moment'
  
  export default {
    name: 'SlideFormPicMovement',
    props:{
      action:{
        type:String,
        default:''
      },   
      editId:{
        type:Number,
        default:null
      },  
      refreshData:{
        type:Function,
        default:true
      },  
    },
    components:{},
    data () {
      return {
        edit_loading:false,
        saving:false,

        detailData:null,
        vehiclePic:[],
        vehicleOtherPic:[],

        optionsPIC:[],
        optionsOtherPIC:[],
        masterOtherPIC:[],
        optionsPosition:[],

        optionsFleets:[],
        currentFleets:[],
        switchFleets:[],
        newFleets:[],
        reduceFleets:[],

        form:{
          pic:null,
          destinationPic:null,
          "vehicleId":[],
          effectiveDate:'',
          notifyGmBod:false,
          notifyEmployee:false,
          switchVehicles:[{sourceVehicleId: null, destinationVehicleId:null}],
          transfer_type:'new',
          attachment:'',
          reason:'',
        }
      }
    },
    watch:{
      editId: {
        deep: true,
        immediate:true,
        handler: function (value) {
          if (value) {
            this.fetchPicMovementDetail(value)
          } else {
            this.resetForm()
          }
        },
      },
    },
    async mounted () {
      await this.fetchFleets()
      await this.getPositionList()
      await this.fetchDataUserExpert()
    },
    computed:{
      positionPic(){
        let user = this.optionsPIC.find(obj => obj.id === Number(this.form.pic))

        return user?.role?.roles ?? '-'
      }
    },
    methods: {
      ...busdevActions,
      ...fleetsActions,
      ...crewingActions,
      ...picMovementActions,
      changeAction(val) {
        // Emit an event to notify the parent component
        this.$emit('updateAction', val);
      },
      onCheckedNewFleets(index){
        this.newFleets[index].checked = !this.newFleets[index].checked 
      },
      onCheckedReduceFleets(index){
        this.reduceFleets[index].checked = !this.reduceFleets[index].checked 
      },
      async onChangeOtherPIC(){
        this.vehicleOtherPic = []
        let pic = this.optionsOtherPIC.find(obj => obj.value == this.form.destinationPic)

        let tempVehicle =  await this.fetchVehiclesByPic(pic.id)
        if(tempVehicle.length > 0){
          this.vehicleOtherPic = tempVehicle
        }
      },
      async onChangePIC(){
        this.vehiclePic = []
        let pic = this.optionsPIC.find(obj => obj.value == this.form.pic)

        let tempPic = this.masterOtherPIC
        this.optionsOtherPIC = tempPic.filter(obj => obj.value !== this.form.pic)

        let tempVehicle = await this.fetchVehiclesByPic(pic.id)

        if(tempVehicle.length > 0){
          this.vehiclePic = tempVehicle

          let idsToRemove = tempVehicle.map(item => item.id);
          let tempNewFleets = this.optionsFleets.filter(item => !idsToRemove.includes(item.id));

          this.newFleets = tempNewFleets.map(val => {
            return {
              ...val,
              checked:false
            }
          })
          this.reduceFleets = tempVehicle.map(val => {
            return {
              ...val,
              checked:false
            }
          })
        } else {
          this.newFleets = this.optionsFleets
          this.form.transfer_type = 'new'
        }
      },
      async fetchVehiclesByPic(id){
        let listVehicles = []
        let params = {
          params:{
            page:1,
            perPage:10000,
          },
          userId:id
        }
        if(id){
          const res = await this.getVehicleByPic(params)
  
          if(res.status === 'success'){
            res.data.data.map(obj => {
              listVehicles.push({
                ...obj.vehicle,
              })
            })
          } else {
            listVehicles = []
          }
        }
        return listVehicles
      },
      async fetchFleets() {
        let params = this.$options.filters.cleanObject({
          page: 1,
          perPage: 9999,
          showAll: true,
          active: true,
        })

        let res = await this.getFleets(params)

        if (res.status) {
          if (res.data && res.data.length > 0) {
            this.optionsFleets = res.data.map((val) => {
              return {
                ...val,
                checked:false
              }
            })
            this.currentFleets = res.data.map((val) => {
              return {
                ...val,
                checked:false
              }
            })
            this.switchFleets = res.data.map((val) => {
              return {
                ...val,
                checked:false
              }
            })
          }
        }
      },
      async fetchDataUserExpert() {
        let params = {
          page: 1,
          active: true,
          perPage: 200,
        }

        const { status, data } = await this.fetchUserExpert(params)

        if (status) {
          if (data && data.length > 0) {
            data.map((state) => {
              this.optionsPIC.push({
                value: state.id,
                text: state.userDetail.name,
                ...state
              }) 
              this.masterOtherPIC = this.optionsPIC
            })
          }
        }
      },
      async getPositionList() {
        let res = await this.fetchPositionList()
        if (res.status) {
          if (res.data && res.data.length > 0) {
            res.data.map((state) => {
              this.optionsPosition.push({
                value: state.id_position,
                text: state.name_position,
                ...state
              })
            })
          }
        }
      },

      async fetchPicMovementDetail(id){
        if(id){
          let res = await this.getPicMovementDetail({id})
  
          if(res.status === 'success'){
            this.detailData = res?.data?.data
            let tempVehicle = await this.fetchVehiclesByPic(this.detailData?.sourcePic?.id)
            
            this.form = {
              pic:this.detailData.sourcePic.id,
              destinationPic:this.detailData.destinationPic,
              "vehicleId":[],
              effectiveDate:this.detailData.effectiveDate ? moment(new Date(this.detailData.effectiveDate)).format('YYYY-MM-DD') : null,
              notifyGmBod:this.detailData.notifyGmBod,
              notifyEmployee:this.detailData.notifyEmployee,
              switchVehicles:[{sourceVehicleId: null, destinationVehicleId:null}],
              transfer_type:this.detailData.movementType,
              attachment:'',
              reason:this.detailData.reason,
            }

            if(tempVehicle.length > 0){
              this.vehiclePic = tempVehicle

              let idsToRemove = tempVehicle.map(item => item.id);
              let tempNewFleets = this.optionsFleets.filter(item => !idsToRemove.includes(item.id));

              this.newFleets = tempNewFleets.map(val => {
                return {
                  ...val,
                  checked:false
                }
              })

              if(this.detailData.movementType === 'new'){
                this.newFleets = this.newFleets.map(val => {
                  if(this.detailData.vehicles.some(obj => obj.id === val.id)){
                    val.checked = true
                  }

                  return val
                })
              }

              this.reduceFleets = tempVehicle.map(val => {
                return {
                  ...val,
                  checked:false
                }
              })

              if(this.detailData.movementType === 'reduce'){
                this.reduceFleets = this.reduceFleets.map(val => {
                  if(this.detailData.vehicles.some(obj => obj.id === val.id)){
                    val.checked = true
                  }

                  return val
                })
              }
            } else {
              this.newFleets = this.optionsFleets.map(val => {
                return {
                  ...val,
                  checked:false
                }
              })
              this.newFleets = this.newFleets.map(val => {
                if(this.detailData.vehicles.some(obj => obj.id === val.id)){
                  val.checked = true
                }

                return val
              })
              this.form.transfer_type = 'new'
            }
          }
        }
      },

      resetForm(){
        this.newReduceFleets = []
        this.form = {
          pic:null,
          destinationPic:null,
          "vehicleId":[],
          effectiveDate:'',
          notifyGmBod:false,
          notifyEmployee:false,
          switchVehicles:[{sourceVehicleId: null, destinationVehicleId:null}],
          transfer_type:'rotate',
          attachment:'',
          reason:'',
        }
      },

      async onSave(){
        this.saving = true
        let payload = {
            url:'',
            data:{
              ...this.form,
            }
          }
          
        let res = null
          if(['rotate'].includes(payload.data.transfer_type)){
            let url = payload.data.transfer_type
            let newPayload = {
              "sourcePic":payload?.data?.pic,
              "destinationPic":payload?.data?.destinationPic,
              "effectiveDate": payload?.data?.effectiveDate ? moment(payload?.data?.effectiveDate).format('DD-MM-YYYY'): '',
              "reason": payload?.data?.reason,
              "notifyGmBod": payload?.data?.notifyGmBod,
              "notifyEmployee": payload?.data?.notifyEmployee,
              "attachment": payload?.data?.attachment,
              "switchVehicles": payload?.data?.switchVehicles
            }

            res = await this.createPICMovement({data:newPayload, url})
          } else {
            let checkedFleets = []

            if(['new'].includes(payload.data.transfer_type)){
              checkedFleets = this.newFleets.filter(obj => obj.checked).map(obj => obj.id)
            } else if (['reduce'].includes(payload.data.transfer_type)){
              checkedFleets = this.reduceFleets.filter(obj => obj.checked).map(obj => obj.id)
            }
            
            let url = payload.data.transfer_type
            let newPayload = {
              "userId":payload?.data?.pic,
              "vehicleId":checkedFleets,
              "effectiveDate": payload?.data?.effectiveDate ? moment(payload?.data?.effectiveDate).format('DD-MM-YYYY'): '',
              "reason": payload?.data?.reason,
              "notifyGmBod": payload?.data?.notifyGmBod,
              "notifyEmployee": payload?.data?.notifyEmployee,
              "attachment": payload?.data?.attachment,
            }

            res = await this.createPICMovement({data:newPayload, url})
          }

          if([400, 500, 404, 409, 401, 402].includes(res.status)){
            this.saving = false
            this.$swal(`Oops.`, res?.data?.message, 'error')
          } else {
            this.resetForm()
            this.refreshData()
            this.$swal(`Success!`, `Create PIC Movement.`, 'success')
            this.saving = false
          }
          this.saving = false
      }
    }
  }
  </script>

