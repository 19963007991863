<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">
              <strong>COLLECTION FORMS</strong>
            </h4>
          </template>
          <template v-slot:headerAction>
            <div class="d-flex align-items-center gap-4 float-right">
              <a class="text-primary mr-4" role="button" style="cursor: pointer" @click="addCollectionForm" v-if="addPermission">
                <i class="fa fa-plus mr-2" />
                <span>Create Data</span>
              </a>
            </div>
          </template>
          <template v-slot:body>
            <!-- FILTERING CUSTOMERS -->
            <b-row class="mb-4">
              <b-col md="4">
                <b-form-group label="search" label-for="search">
                  <b-form-input placeholder="Search code/name..." name="search" v-model="params.search"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="2" />
              <b-col md="2" />
              <b-col md="3" />
              <b-col md="1">
                <b-form-group label="Filter" label-for="button_search">
                  <b-button
                    variant="primary"
                    type="button"
                    @click="getListCollectionForm"
                    ><i class="fa fa-filter"></i
                  ></b-button>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row v-if="loading">
              <b-col md="12">
                <div class="text-center my-5">
                  <b-spinner type="grow" label="Spinning"></b-spinner>
                </div>
              </b-col>
            </b-row>
            <!-- TABLE CONTENT -->
            <b-row v-else>
              <b-col md="12">
                <div v-if="loading" class="alert alert-info">Loading...</div>
                <iq-card> 
                  <div class="overflow-auto d-flex" v-if="listsCollectionForm?.data?.length > 0">
                    <table class="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th class="text-center align-middle" style="width: 20px">No</th>
                          <th class="text-left align-middle text-truncate">Code</th>
                          <th class="text-left align-middle text-truncate">Name</th>
                          <th class="text-left align-middle text-truncate">Title</th>
                          <th class="text-left align-middle text-truncate"  style="width: 200px">Description</th>
                          <th class="text-left align-middle text-truncate">Question Type</th>
                          <th class="text-left align-middle text-truncate">Using Remarks</th>
                          <th class="text-left align-middle text-truncate">Status</th>
                          <th class="text-center align-middle" style="width: 20px">#</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(collectionForm, collectionFormIndex) in listsCollectionForm.data" :key="collectionFormIndex">
                          <td>{{ collectionFormIndex + 1 }}</td>
                          <td class="text-truncate">{{ collectionForm?.code }}</td>
                          <td class="text-truncate">{{ collectionForm?.name }}</td>
                          <td class="text-truncate">{{ collectionForm?.title ?? '-' }}</td>
                          <td class="text-truncate" style="max-width:200px" v-b-tooltip.top="collectionForm?.description">{{ collectionForm?.description }}</td>
                          <td class="text-truncate">{{ collectionForm?.question_type?.name }}</td>
                          <td class="text-truncate">{{ collectionForm?.using_remarks ? 'Yes' : "No" }}</td>
                          <td class="text-truncate">{{ collectionForm?.status ? 'Active' : "Inactive" }}</td>
                          <td v-if="updatePermission || deletePermission">
                            <div class="d-flex align-items-center">
                              <i class="fa fa-pen text-primary mr-3" style="cursor: pointer;" @click="editCollectionForm(collectionForm?.id)" />
                              <i class="fa fa-trash text-danger" style="cursor: pointer;" @click="onDeleteForm(collectionForm)" />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="mt-3" v-if="listsCollectionForm?.data?.length > 0">
                    <b-pagination
                      v-model="params.page"
                      :total-rows="listsCollectionForm.meta.totalItem"
                      @change="getListCollectionForm"
                      :per-page="listsCollectionForm.meta.perPage"
                      first-number
                      align="center"
                    ></b-pagination>
                  </div>
                  <p v-else class="text-center my-4">No forms data found.</p>
                </iq-card>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>

      <b-col lg="12">
        <b-sidebar v-model="showAddCollectionForm" id="add-collection-form" :title="`${isEdit ? 'EDIT' : 'ADD'} FORMS`" width="100%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
          <template #default="{hide}">
            <b-row class="m-3">
              <b-col cols="12">
                <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 text-primary">{{ isEdit ? 'Update' : 'Create' }} Sections and Questions</legend>
                    <b-row class="mt-2">
                        <b-col md="12">
                          <FormCollectionForm 
                            :is-edit="isEdit" 
                            :edit-id="editId" 
                            :refreshData="refreshData"
                            :closeModalForm="closeModalForm"
                          />
                        </b-col>
                    </b-row>
                </fieldset>
              </b-col>
            </b-row>
            </template>
        </b-sidebar>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
  import _ from 'lodash'
  import moment from 'moment'
  import FormCollectionForm from './FormCollectionForm.vue'
  import { busdevActions } from '@src/Utils/helper'

  export default {
    name: 'CollectionForm',
    data() {
      return {
        loading:false,
        showAddCollectionForm:false,

        isEdit:false,
        editId:null,

        params:{
          page: 1,
          perPage:25,
          search:'',
        },

        listsCollectionForm: {
          data:[],
          meta:null
        }
      }
    },
    components:{
      FormCollectionForm
    },
    watch: {

    },
    computed: {
      addPermission(){
        let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
          .find(obj => obj.menu === 'Apps').child
          .find(obj => obj.menu === 'Business Development').child
          .find(obj => obj.menu === 'Busdev Master Forms').add

        return hasAccess
      },
      updatePermission(){
        let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
          .find(obj => obj.menu === 'Apps').child
          .find(obj => obj.menu === 'Business Development').child
          .find(obj => obj.menu === 'Busdev Master Forms').update

        return hasAccess
      },
      deletePermission(){
        let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
          .find(obj => obj.menu === 'Apps').child
          .find(obj => obj.menu === 'Business Development').child
          .find(obj => obj.menu === 'Busdev Master Forms').delete

        return hasAccess
      },
    },
    async mounted() {
      this.getListCollectionForm()
    },
    methods: {
      ...busdevActions,
      closeModalForm(){
        this.isEdit = false
        this.editId = null

        this.showAddCollectionForm = false
        this.$root.$emit('bv::toggle::collapse', `add-collection-form`)
      },
      addCollectionForm(){
        this.isEdit = false
        this.editId = null

        this.showAddCollectionForm = true
        this.$root.$emit('bv::toggle::collapse', `add-collection-form`)
      }, 
      editCollectionForm(id){
        this.isEdit = true
        this.editId = id

        this.showAddCollectionForm = true
        this.$root.$emit('bv::toggle::collapse', `add-collection-form`)
      },
      refreshData(){
        this.isEdit = false
        this.editId = null

        this.$root.$emit('bv::toggle::collapse', `add-collection-form`)
        this.getListCollectionForm()
      },
      async getListCollectionForm(page) {
        this.listsCollectionForm = {
          data:[],
          meta:null
        }

        let params = {
          ...this.params,
        }
        if(!params.search) delete params.search

        this.loading = true

        let res = await this.fetchCollectionForm(params)

        if (res?.data?.status === true && res?.data?.data?.data?.length > 0) {
          this.listsCollectionForm.data = res.data.data?.data
          this.listsCollectionForm.meta = res.data.data?.meta
        }

        this.loading = false
      },

      async onDeleteForm(data){
        this.$swal({
            title: `Are you sure want to delete ${data?.name} Form?`,
            text: "Yes, i'm sure.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
              const res = await this.deleteCollectionForm({id:data.id})
              if(res?.data?.status === true){
                await this.getListCollectionForm()
                this.$swal(
                    'Success!',
                    'Success delete Form.',
                    'success'
                )
              } else {
                this.$swal(
                      'Failed!',
                      'Something went wrong.',
                      'error'
                  )
                return 
              }
             
            }
        })
      },
    }
  }
</script>