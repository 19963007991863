<template>
    <ApexChart
      :element="`chart-budget-docking`"
      :chartOption="chartBudgetDocking"
    />
  </template>
  
  <script>
  export default {
    data() {
      return {
        chartBudgetDocking: {
          chart: {
            type: 'bar',
            height: 350,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '70%',
              endingShape: 'rounded',
            },
          },
          dataLabels: {
            enabled: true,
          },
          series: [
            {
              data: [12, 34, 56, 78, 56, 12, 56, 78, 89, 56, 23],
              color: '#213F8B',
            },
          ],
          xaxis: {
            categories: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Oct',
              'Dec',
            ],
          },
          yaxis: {
            labels: {
              formatter: (val) => {
                return val // memformat label sumbu y menjadi persentase
              },
            },
            max: 100, // set nilai maksimum pada sumbu y
            tickAmount: 5,
          },
          fill: {
            opacity: 2,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + '%'
              },
            },
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          title: {
            text: 'Budget Docking 2024',
            floating: true,
            offsetY: 0,
            opacity: '0.7',
            align: 'center',
            style: {
              color: '#0088AF',
            },
          },
        },
      }
    },
  }
  </script>
  