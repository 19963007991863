<template>
  <b-col md="12">
    <CertificateDashboard />
    <b-row>
      <b-col md="12">
        <iq-card
          class-name="iq-card-block iq-card-stretch iq-card-height"
          body-class=""
        >
          <template v-slot:headerTitle>
            <h5 class="card-title text-primary">
              <strong>Daily Report Fleet Position & Progress</strong>
            </h5>
          </template>
          <template v-slot:body>
            <b-modal v-model="modal.filter" centered size="lg" title="Filter Data" @ok="fetchDailyOps()" ok-title="Filter">
              <b-row class="px-2">
                    <b-col md="12">
                      <b-form-group  label-for="dateIssued" label="Date"  label-cols-sm="4" label-cols-lg="3">
                        <b-form-input
                          type="date"
                          v-model="filterDailyOps.date"
                        ></b-form-input>
                        <small style="float: right"
                          >click <i class="fa fa-calendar"></i> to show
                        </small>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                        label-for="dateIssued"
                        label="Project Name"
                        label-cols-sm="4"
                        label-cols-lg="3"
                      >
                        <v-multiselect
                          v-model="filterDailyOps.subProject"
                          :options="projectOptions"
                          :multiple="true"
                          group-values="sub"
                          group-label="project"
                          :group-select="true"
                          placeholder="Select one"
                          track-by="name"
                          label="name"
                        >
                          <span slot="noResult"
                            >Oops! No elements found. Consider changing the
                            search query.</span
                          >
                        </v-multiselect>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                        label-for="dateIssued"
                        label="Ownership"
                         label-cols-sm="4"
                        label-cols-lg="3"
                      >
                        <b-form-select
                          plain
                          :options="categoryOwnership"
                          id="gender"
                          v-model="filterDailyOps.ownership"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label-for="filter-options" label="Filter Options"  label-cols-sm="4" label-cols-lg="3" >
                        <b-form-select
                          plain
                          :options="filterOptions"
                          id="filtet-options"
                          v-model="filterOpionSelected"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="12" v-if="filterOpionSelected == 'category'">
                      <b-form-group
                        label-for="dateIssued"
                        label="Fleet Category"
                        label-cols-sm="4"
                        label-cols-lg="3"
                      >
                        <v-multiselect
                          v-model="filterDailyOps.vehicleTypeIds"
                          :options="vehicle"
                          :multiple="true"
                          track-by="value"
                          placeholder="Select one"
                          label="text"
                          value="value"
                        >
                          <span slot="noResult"
                            >Oops! No elements found. Consider changing the
                            search query.</span
                          >
                        </v-multiselect>
                      </b-form-group>
                    </b-col>
                    <b-col md="12" v-else>
                      <b-form-group label-for="pic" label="PIC"  label-cols-sm="4"
                      label-cols-lg="3" >
                        <b-form-select
                          plain
                          :options="optionsUsers"
                          id="pic"
                          v-model="filterDailyOps.pic"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                    <!-- <b-col md="3">
                      <div
                        class="d-flex align-items-end h-100"
                        style="padding-bottom: 20px"
                      >
                        <button
                          class="btn w-100 btn-success"
                          >Filter Data <i class="fa fa-search ml-1"></i
                        ></button>
                      </div>
                    </b-col> -->
                  </b-row>
            </b-modal>
            <div class="">
              <b-row>
                <!-- <b-col md="10">
                  <tab-nav :tabs="true">
                    <tab-nav-items
                      :active="true"
                      id="position-tab"
                      ariaControls="position"
                      role="tab"
                      :ariaSelected="true"
                      title="Update Position"
                    />
                    <tab-nav-items
                      :active="false"
                      id="repair-tab"
                      ariaControls="repair"
                      role="tab"
                      :ariaSelected="false"
                      title="Update Idle / Repair"
                    /><tab-nav-items
                      :active="false"
                      id="docking-tab"
                      ariaControls="docking"
                      role="tab"
                      :ariaSelected="false"
                      title="Update at Shipyard"
                    />
                    <tab-nav-items :active="false" id="tug-assist" ariaControls="assist" role="tab" :ariaSelected="false" title="Tug Assist" />
                  </tab-nav>
                </b-col> -->
                <b-col cols="12" class="d-flex justify-content-between" v-if="findMenuPermission()">
                  <div>
                    <b-button variant="outline-primary" class="mb-3 mr-2" @click="modal.filter = true">
                      <i class="fas fa-sliders"></i>
                      Filter Data
                    </b-button>
                  </div>
                  <div>
                    <b-button variant="outline-primary" class="mb-3 mr-2">
                      Import Data
                      <i class="fas fa-download"></i>
                    </b-button>
                    <b-button
                      @click="exportDailyOps"
                      variant="primary"
                      class="mb-3 mr-1"
                    >
                      Export Data
                      <i class="fas fa-upload"></i>
                    </b-button>
                  </div>
                </b-col>
              </b-row>

              <!-- SHOW FILTER VALUE DAILY -->
              <div style="padding: 0 14px;" class="mb-3">
                <b-row class="border rounded-md pt-2">
                    <b-col md="2" v-if="filterDailyOps.date">
                      <b-form-group label="Date :">
                        <p class="mb-0 text-black" style="font-weight: 500;">{{filterDailyOps.date ? moment(filterDailyOps.date).format("DD MMMM YYYY"):'-'}}</p>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Ownership :">
                        <p class="mb-0 text-black" style="font-weight: 500;">{{ filterDailyOps.ownership || "All"}}</p>
                      </b-form-group>
                    </b-col>   
                    <b-col md="3" v-if="filterDailyOps.subProject.length">
                      <b-form-group label="Project Name :" >
                        <template>
                          <p class="mb-0 text-black" style="font-weight: 500;" ><span v-for="(project, index) in filterDailyOps.subProject" :key="index">{{ project.name }}{{ (index + 1) !==  filterDailyOps.subProject.length? ', ':'' }}</span></p>
                        </template>
                      </b-form-group>
                    </b-col>
                    <b-col md="4" v-if="filterOpionSelected == 'category' && filterDailyOps.vehicleTypeIds.length">
                      <b-form-group label='Fleet Category :'>
                        <template v-if="filterDailyOps.vehicleTypeIds.length">
                          <b-badge variant="success" v-for="(vehicle, index) in filterDailyOps.vehicleTypeIds" :key="index" class="mr-1">{{ vehicle.text }}</b-badge>
                        </template>
                        <div v-else>-</div>
                      </b-form-group>
                    </b-col> 
                    <template v-else>
                      <b-col md="4" v-if="filterDailyOps.pic">
                        {{ filterDailyOps.pic }}
                        <b-form-group label='PIC'>
                          <p class="mb-0 text-black" style="font-weight: 500;">{{optionsUsers.find((el, i)=>el.value == filterDailyOps.pic)?.text ?? '-'}}</p>
                        </b-form-group>
                      </b-col>  
                    </template>
                  </b-row>
              </div>
              <!-- END SHOW FILTER VALUE DAILY -->

              <div id="myTabContent" class="tab-content">
                <tab-content-item :active="true" id="position">
                  <div
                    class="overflow-auto d-flex flex-column"
                    style="max-height: 600px"
                  >
                    <table class="table mb-0 table-striped table-hover">
                      <thead
                        class="thead-dark text-center sticky-top"
                        style="z-index: 1"
                      >
                        <tr>
                          <th scope="col">No</th>
                          <th scope="col" class="text-truncate">Fleet</th>
                          <th scope="col" class="text-truncate">Start Date</th>
                          <th scope="col" class="text-truncate">End Date</th>
                          <th scope="col" class="text-truncate"
                            >Current Position</th
                          >
                          <th scope="col" class="text-truncate"
                            >Port Loading</th
                          >
                          <th scope="col" class="text-truncate"
                            >Port Discharge</th
                          >
                          <th scope="col" class="text-truncate">Agent POL</th>
                          <th scope="col" class="text-truncate">Agent POD</th>
                          <th scope="col" class="text-truncate">PIC / PE</th>
                          <th scope="col" class="text-truncate">Departure</th>
                          <th scope="col" class="text-truncate">ETA</th>
                          <th scope="col" class="text-truncate"
                            >Update Today</th
                          >
                          <th scope="col" class="text-truncate">Reason</th>
                          <th scope="col" class="text-truncate"
                            >Idle (Hours)</th
                          >
                          <th
                            scope="col"
                            class="text-truncate"
                            style="min-width: 300px"
                            >Remarks</th
                          >
                          <th scope="col" class="text-truncate"
                            >Document on Board</th
                          >
                          <th scope="col" class="text-truncate"
                            >Cargo Loading</th
                          >
                          <th scope="col" class="text-truncate">GPS</th>
                          <th scope="col" class="text-truncate">Speed</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="(position, index) in dailyOpsData">
                          <template v-if="dailyOpsData.length > 0">
                            <tr :key="`pos-${index}`">
                              <td colspan="22">
                                <h5>
                                  <strong
                                    >{{
                                      position.projectSub.project.projectName
                                    }}
                                    - {{ position.projectSub.name }}</strong
                                  >
                                </h5>
                              </td>
                            </tr>
                            <template v-for="(project, idx) in position.voyage">
                              <tr
                                v-if="position.voyage"
                                :key="`proj-${index}${idx}`"
                              >
                                <td>{{ idx + 1 }}</td>
                                <td class="text-truncate">
                                  <span
                                    v-for="(each, i) in project.vehicle"
                                    :key="i"
                                  >
                                    <b-badge variant="success">{{
                                      each.name
                                    }}</b-badge
                                    >&nbsp;
                                  </span>
                                </td>
                                <td class="text-truncate">{{
                                  moment(
                                    project.startDate,
                                    'DD-MM-YYYY HH:mm'
                                  ).format('DD MMM YYYY HH:mm')
                                }}</td>
                                <td class="text-truncate">{{
                                  moment(
                                    project.endDate,
                                    'DD-MM-YYYY HH:mm'
                                  ).format('DD MMM YYYY HH:mm')
                                }}</td>
                                <td class="text-truncate">{{
                                  project.currentPosition
                                }}</td>
                                <td class="text-truncate">{{
                                  project.portLoading
                                    ? project.portLoading.portName
                                    : '-'
                                }}</td>
                                <td class="text-truncate">{{
                                  project.portDischarge
                                    ? project.portDischarge.portName
                                    : '-'
                                }}</td>
                                <td class="text-truncate">{{
                                  project.agentPol || '-'
                                }}</td>
                                <td class="text-truncate">{{
                                  project.agentPod || '-'
                                }}</td>
                                <td class="text-truncate">
                                  <span
                                    v-for="(each, i) in project.pic"
                                    :key="i"
                                  >
                                    <b-badge variant="info">{{
                                      each.name
                                    }}</b-badge
                                    >&nbsp;
                                  </span>
                                </td>
                                <td class="text-truncate">{{
                                  moment(
                                    project.startDate,
                                    'DD-MM-YYYY HH:mm'
                                  ).format('DD MMM YYYY HH:mm')
                                }}</td>
                                <td class="text-truncate">{{
                                  project.arrival
                                    ? moment(
                                        project.arrival,
                                        'DD-MM-YYYY HH:mm'
                                      ).format('DD MMM YYYY HH:mm')
                                    : '-'
                                }}</td>
                                <td class="text-truncate">{{
                                  formatDate(filterDailyOps?.date) || '-'
                                }}</td>
                                <td class="text-truncate">{{
                                  project.reason ? project.reason.name : '-'
                                }}</td>
                                <td class="text-truncate">{{
                                  project.activityDurationFormat || '-'
                                }}</td>
                                <td>{{ project.remark || '-' }}</td>
                                <td class="text-truncate">{{
                                  project.docOnBoard
                                    ? moment(
                                        project.docOnBoard,
                                        'DD-MM-YYYY HH:mm'
                                      ).format('DD MMM YYYY HH:mm')
                                    : '-'
                                }}</td>
                                <td class="text-truncate">{{
                                  numberFormat(project.cargoLoading) || '-'
                                }}</td>
                                <td class="text-truncate"
                                  >{{ project.dmsLatitude || '-' }},
                                  {{ project.dmsLongitude || '-' }}</td
                                >
                                <td class="text-truncate">{{
                                  project.speed || '-'
                                }}</td>
                              </tr>
                            </template>
                          </template>
                        </template>
                      </tbody>
                    </table>
                  </div>
                  <div
                    v-if="dailyOpsData.length <= 0"
                    class="d-flex align-items-center justify-content-center w-100 mt-2"
                  >
                    <strong class="text-muted">No Data.</strong>
                  </div>
                </tab-content-item>
                <tab-content-item
                  :active="false"
                  id="repair"
                  aria-labelled-by="profile-tab"
                >
                  <div class="overflow-auto d-flex flex-column tableFixHead">
                    <table class="table mb-0 table-striped table-hover">
                      <thead class="thead-dark text-center">
                        <tr>
                          <th scope="col">No</th>
                          <th scope="col" class="text-truncate">Fleet</th>
                          <th scope="col" class="text-truncate"
                            >Current Position</th
                          >
                          <th scope="col" class="text-truncate">Remarks</th>
                          <th scope="col" class="text-truncate">Arrival</th>
                          <th scope="col" class="text-truncate"
                            >Update Today / On Going Repair</th
                          >
                          <th scope="col" class="text-truncate"
                            >Stoppage Time (Hours)</th
                          >
                          <th scope="col" class="text-truncate"
                            >Est Complete Repair</th
                          >
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(repair, index) in idleRepair"
                          :key="`rep-${index}`"
                        >
                          <td>{{ repair.no }}</td>
                          <td class="text-truncate">{{ repair.fleet }}</td>
                          <td class="text-truncate">{{
                            repair.currentPosition
                          }}</td>
                          <td>{{ repair.remarks }}</td>
                          <td class="text-truncate">{{ repair.arrival }}</td>
                          <td class="text-truncate">{{
                            repair.onGoingRepair
                          }}</td>
                          <td class="text-truncate">{{
                            repair.StoppageTime
                          }}</td>
                          <td class="text-truncate">{{
                            repair.completeRepair
                          }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </tab-content-item>
                <tab-content-item
                  :active="false"
                  id="docking"
                  aria-labelled-by="contact-tab"
                >
                  <div class="table-responsive tableFixHead">
                    <table
                      class="table mb-0 table-striped table-hover"
                      style="width: 1800px"
                    >
                      <thead class="thead-dark text-center">
                        <tr>
                          <th scope="col" width="5%">No</th>
                          <th scope="col" width="10%">Fleet</th>
                          <th scope="col" width="15%">Position</th>
                          <th scope="col" width="5%">Preedocking</th>
                          <th scope="col" width="5%">Up Slip</th>
                          <th scope="col" width="5%">Waiting Up Slip (H)</th>
                          <th scope="col" width="5%">Date</th>
                          <th scope="col" width="5%"
                            >Docking Progress (Hours)</th
                          >
                          <th scope="col" width="5%">Down Slip</th>
                          <th scope="col" width="5%">Waiting Down Slip (H)</th>
                          <th scope="col" width="5%">Completed Docking</th>
                          <th scope="col" width="5%">Progress %</th>
                          <th scope="col" width="25%">Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(shipyard, index) in shipyard"
                          :key="`ship-${index}`"
                        >
                          <td class="text-center">{{ shipyard.no }}</td>
                          <td>{{ shipyard.fleet }}</td>
                          <td>{{ shipyard.position }}</td>
                          <td class="text-center">{{ shipyard.predocking }}</td>
                          <td class="text-center">{{ shipyard.upSlip }}</td>
                          <td class="text-center">
                            {{ shipyard.waitingUpSlip }}
                          </td>
                          <td class="text-center">{{
                            shipyard.updateToday
                          }}</td>
                          <td class="text-center">
                            {{ shipyard.dockingProgress }}
                          </td>
                          <td class="text-center">{{ shipyard.downSlip }}</td>
                          <td class="text-center">
                            {{ shipyard.waitingDownSlip }}
                          </td>
                          <td class="text-center">{{ shipyard.completed }}</td>
                          <td class="text-center">{{ shipyard.progress }}</td>
                          <td>{{ shipyard.remarks }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </tab-content-item>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col sm="12">
        <iq-card
          class-name="iq-card-block iq-card-stretch iq-card-height"
          body-class=""
        >
          <template v-slot:headerTitle>
            <h5 class="card-title text-primary">
              <strong>Operational Fleet Matrix</strong>
            </h5>
          </template>
          <template v-slot:body>
            <div>
              <b-modal v-model="modal.filterMonthly" centered size="lg" title="Filter Data" @ok="fetchMonthlyOps()" ok-title="Filter">
                  <b-row class="px-2">
                    <b-col md="12">
                      <b-form-group
                        label-for="dateIssued"
                        label="Date Range Filter"
                        label-cols-sm="4"
                        label-cols-lg="3"
                      >
                        <date-picker
                          class="w-100"
                          :lang="lang"
                          v-model="filterMonthlyOps.dateRange"
                          type="date"
                          range
                          placeholder="Select date range"
                        ></date-picker>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                        label-for="dateIssued"
                        label="Project Name"
                        label-cols-sm="4"
                        label-cols-lg="3"
                      >
                        <v-multiselect
                          v-model="filterMonthlyOps.subProject"
                          :options="projectOptions"
                          :multiple="true"
                          group-values="sub"
                          group-label="project"
                          :group-select="true"
                          placeholder="Type to search"
                          track-by="name"
                          label="name"
                        >
                          <span slot="noResult"
                            >Oops! No elements found. Consider changing the
                            search query.</span
                          >
                        </v-multiselect>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <!-- {{ vehicle }} -->
                      <b-form-group
                        label-for="kind-fleet"
                        label="Fleet Category"
                        label-cols-sm="4"
                        label-cols-lg="3"
                      >
                        <v-multiselect
                          v-model="filterMonthlyOps.vehicleTypeIds"
                          :options="vehicle"
                          :multiple="true"
                          track-by="value"
                          placeholder="Select one"
                          label="text"
                          value="value"
                          id="kind-fleet"
                        >
                          <span slot="noResult"
                            >Oops! No elements found. Consider changing the
                            search query.</span
                          >
                        </v-multiselect>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                        label-for="dateIssued"
                        label="Ownership"
                        label-cols-sm="4"
                        label-cols-lg="3"
                      >
                        <b-form-select
                          plain
                          :options="categoryOwnership"
                          id="gender"
                          v-model="filterMonthlyOps.ownership"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
              </b-modal>
              <div class="d-flex justify-content-between align-items-center mb-3">
                <b-button variant="outline-primary" @click="modal.filterMonthly = true">
                  <i class="fas fa-sliders"></i>
                  Filter Data
                </b-button>
                <b-button
                  @click="exportMonthlyOps"
                  variant="primary"
                >
                  Export Data
                  <i class="fas fa-upload"></i>
                </b-button>
              </div>
              <!-- SHOW FILTER VALUE MONTHLY -->
              <div style="padding: 0 14px;" class="mb-3">
                <b-row class="border rounded-md pt-2">
                    <b-col md="3" v-if="filterMonthlyOps.dateRange.length">
                      <b-form-group label="Period :">
                        <div class="d-flex">
                          <p class="mb-0 text-black" style="font-weight: 500;" >{{ filterMonthlyOps.dateRange[0] ? moment(filterMonthlyOps.dateRange[0]).format("DD MMMM YYYY"):'' }} - <span>{{ filterMonthlyOps.dateRange[1] ? moment(filterMonthlyOps.dateRange[1]).format("DD MMMM YYYY"):'' }}</span></p>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label="Ownership :">
                        <p class="mb-0 text-black" style="font-weight: 500;">{{ filterMonthlyOps.ownership || "All"}}</p>
                      </b-form-group>
                    </b-col>   
                    <b-col md="2" v-if="filterMonthlyOps.subProject.length">
                      <b-form-group label="Project Name :">
                        <template v-if="filterMonthlyOps.subProject.length">
                          <p class="mb-0 text-black" style="font-weight: 500;" ><span v-for="(project, index) in filterMonthlyOps.subProject" :key="index">{{ project.name }}{{ (index + 1) !==  filterMonthlyOps.subProject.length? ', ':'' }}</span></p>
                        </template>
                        <div v-else>-</div>
                      </b-form-group>
                    </b-col>
                    <b-col md="4" v-if="filterMonthlyOps.vehicleTypeIds.length">
                      <b-form-group label='Fleet Category :'>
                        <template v-if="filterMonthlyOps.vehicleTypeIds.length">
                          <b-badge variant="success" v-for="(vehicle, index) in filterMonthlyOps.vehicleTypeIds" :key="index" class="mr-1">{{ vehicle.text }}</b-badge>
                        </template>
                        <div v-else>-</div>
                      </b-form-group>
                    </b-col>
                  </b-row>
              </div>
              <!-- END SHOW FILTER VALUE MONTHLY -->

              <div class="accordion" role="tablist">
                <div class="text-center" v-if="loadingOpsMatrix">
                  <b-spinner type="grow" label="Spinning"></b-spinner>
                </div>
                <template v-else>
                  <template v-for="(matrix, index) in opsMatrix.matrix">
                    <b-card no-body class="mb-1" :key="`mat-${index}`">
                      <b-card-header
                        header-tag="header"
                        class="p-1"
                        role="tab"
                        v-b-toggle="'matrix' + matrix.projectSub.id"
                      >
                        <h4
                          style="
                            cursor: pointer;
                            text-transform: uppercase;
                            font-weight: bold;
                          "
                          class="p-3"
                        >
                          {{ matrix.projectSub.project.projectName }} -
                          {{ matrix.projectSub.name }}
                        </h4>
                      </b-card-header>
                      <b-collapse
                        :id="'matrix' + matrix.projectSub.id"
                        :visible="matrix.visibility"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <template
                            v-for="(category, index2) in matrix.vehicleTypes"
                          >
                            <b-card no-body class="mb-1" :key="`mt2-${index2}`">
                              <b-card-header
                                header-tag="header"
                                class="p-1"
                                role="tab"
                                v-b-toggle="'category' + index2"
                              >
                                <h4 style="cursor: pointer" class="p-3">
                                  {{ category.vehicleType.name }}
                                </h4>
                              </b-card-header>
                              <b-collapse
                                :id="'category' + index2"
                                visible
                                role="tabpanel"
                              >
                                <b-card-body>
                                  <b-card-text>
                                    <div
                                      class="table-responsive"
                                      v-if="category.vehicles.vehicle !== 'he'"
                                    >
                                      <table
                                        class="table mb-0 table-striped table-hover"
                                        style="width: 3000px"
                                      >
                                        <thead class="text-center">
                                          <tr
                                            class="bg-primary"
                                            style="text-transform: uppercase"
                                          >
                                            <th>No</th>
                                            <th>Fleet</th>
                                            <th>Date</th>
                                            <template
                                              v-if="category.vehicles[0]"
                                            >
                                              <th
                                                class="text-truncate"
                                                v-for="(activity, i) in category
                                                  .vehicles[0].activities"
                                                :key="i"
                                                >{{ activity.name }}</th
                                              >
                                            </template>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr
                                            v-for="(
                                              ops, idx
                                            ) in category.vehicles"
                                            :key="`cat-${idx}`"
                                          >
                                            <td class="text-center">
                                              {{ idx + 1 }}
                                            </td>
                                            <td class="text-truncate">{{
                                              ops.vehicle.name
                                            }}</td>
                                            <td class="text-center"
                                              >{{
                                                formatDate(
                                                  filterMonthlyOps?.dateRange[0]
                                                )
                                              }}
                                              -
                                              {{
                                                formatDate(
                                                  filterMonthlyOps?.dateRange[1]
                                                )
                                              }}</td
                                            >
                                            <td
                                              class="text-center text-truncate"
                                              v-for="(
                                                activity, i
                                              ) in ops.activities"
                                              :key="i"
                                            >
                                              {{ activity.durationFormat }}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </b-card-text>
                                </b-card-body>
                              </b-collapse>
                            </b-card>
                          </template>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </template>
                  <p class="m-0" v-if="!opsMatrix.matrix.length">No data available</p>
                </template>
              </div>
            </div>
            <!-- <b-row>
              <b-col md="4">
                <b-form-group label="Category:" label-for="kind-fleet">
                  <h5>
                    <strong>{{
                      filterMonthlyOps.vehicleTypeIds.length > 0
                        ? showTextVehicleType(filterMonthlyOps.vehicleTypeIds)
                        : 'All Category Vehicle'
                    }}</strong>
                  </h5>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label-for="dateIssued" label="Date Range">
                  <h5>
                    <strong
                      >{{ formatDate(filterMonthlyOps.dateRange[0]) }} -
                      {{ formatDate(filterMonthlyOps.dateRange[1]) }}</strong
                    >
                  </h5>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label-for="dateIssued"
                  label="Category Ownership"
                >
                  <h5>
                    <strong>{{
                      filterMonthlyOps.ownership
                        ? showTextOwnership(filterMonthlyOps.ownership)
                        : 'All Category Ownership'
                    }}</strong>
                  </h5>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label-for="dateIssued" label="Project Filter">
                  <h5 v-if="filterMonthlyOps.subProject.length > 0">
                    <strong
                      class="mr-2"
                      v-for="(each, i) in filterMonthlyOps.subProject"
                      v-bind:key="i"
                      >{{ each.name }},</strong
                    >
                  </h5>
                  <h5 v-else>
                    <strong>All Project</strong>
                  </h5>
                </b-form-group>
              </b-col>
            </b-row> -->
          </template>
        </iq-card>
      </b-col>
      <b-col md="12">
        <b-row>
          <b-col md="12">
            <iq-card class-name="iq-card-block" body-class="">
              <template v-slot:headerTitle>
                <h5 class="card-title text-primary">
                  <strong>Operational Statistic</strong>
                </h5>
              </template>
              <template v-slot:body>
                <div class="text-center" v-if="loadingOpsMatrix">
                  <b-spinner type="grow" label="Spinning"></b-spinner>
                </div>
                <template v-else>
                  <tab-nav :tabs="true">
                    <tab-nav-items
                      :active="true"
                      id="percent-tab"
                      ariaControls="percent"
                      role="tab"
                      :ariaSelected="true"
                      title="Based on Percentage"
                    />
                    <tab-nav-items
                      :active="false"
                      id="hours-tab"
                      ariaControls="hours"
                      role="tab"
                      :ariaSelected="false"
                      title="Based on Hours"
                    />
                  </tab-nav>
                  <div id="myTabContent" class="tab-content">
                    <tab-content-item :active="true" id="percent">
                      <template v-for="(percentage, index) in opsMatrix.matrix">
                        <b-card no-body class="mb-1" :key="`per-${index}`">
                          <b-card-header
                            header-tag="header"
                            class="p-1"
                            role="tab"
                            v-b-toggle="'percentage' + percentage.projectSub.id"
                          >
                            <h4 style="cursor: pointer" class="p-3">
                              {{ percentage.projectSub.project.projectName }}
                              - {{ percentage.projectSub.name }}
                            </h4>
                          </b-card-header>
                          <b-collapse
                            :id="'percentage' + percentage.projectSub.id"
                            :visible="percentage.visibility"
                            role="tabpanel"
                          >
                            <!--accordion="percentage-accordion"-->
                            <b-card-body>
                              <!-- Accordion for Category Fleet -->
                              <template
                                v-for="(
                                  category, index2
                                ) in percentage.vehicleTypes"
                              >
                                <b-card
                                  no-body
                                  class="mb-1"
                                  :key="`per2-${index2}`"
                                >
                                  <b-card-header
                                    header-tag="header"
                                    class="p-1"
                                    role="tab"
                                    v-b-toggle="'category' + index2"
                                  >
                                    <h4 style="cursor: pointer" class="p-3">
                                      {{ category.vehicleType.name }}
                                      <!-- <b-badge>{{ category.total }}</b-badge> -->
                                    </h4>
                                  </b-card-header>
                                  <b-collapse
                                    :id="'category' + index2"
                                    visible
                                    role="tabpanel"
                                  >
                                    <b-card-body>
                                      <b-form-group
                                        v-if="percentage.TypeVehicleOpt"
                                        :label="
                                          'Filter Chart ' +
                                          category.vehicleType.name
                                        "
                                      >
                                        <v-multiselect
                                          @input="
                                            onChange($event, index, index2)
                                          "
                                          v-model="category.bodyData.data"
                                          tag-placeholder="Category"
                                          placeholder="Search or statistic"
                                          label="name"
                                          track-by="name"
                                          :options="percentage.TypeVehicleOpt"
                                          :multiple="true"
                                          :taggable="true"
                                        ></v-multiselect>
                                      </b-form-group>
                                      <b-card-text>
                                        <AmChart
                                          :element="`chart-${percentage.projectSub.id}-${index}-${index2}-${category.bodyData.type}`"
                                          :type="category.bodyData.type"
                                          :option="category.bodyData"
                                        />
                                      </b-card-text>
                                    </b-card-body>
                                  </b-collapse>
                                </b-card>
                              </template>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </template>
                    </tab-content-item>
                    <tab-content-item
                      :active="false"
                      id="hours"
                      aria-labelled-by="hours-tab"
                    >
                      <template v-for="(hours, index) in opsMatrix.matrix">
                        <b-card no-body class="mb-1" :key="`stat-${index}`">
                          <b-card-header
                            header-tag="header"
                            class="p-1"
                            role="tab"
                            v-b-toggle="'hours' + hours.projectSub.id"
                          >
                            <h4 style="cursor: pointer" class="p-3">
                              {{ hours.projectSub.project.projectName }} -
                              {{ hours.projectSub.name }}
                            </h4>
                          </b-card-header>
                          <b-collapse
                            :id="'hours' + hours.projectSub.id"
                            :visible="hours.visibility"
                            accordion="my-accordion"
                            role="tabpanel"
                          >
                            <b-card-body>
                              <!-- Accordion for Category Fleet -->
                              <template
                                v-for="(category, index2) in hours.vehicleTypes"
                              >
                                <b-card
                                  no-body
                                  class="mb-1"
                                  :key="`stat-h-${index2}`"
                                >
                                  <b-card-header
                                    header-tag="header"
                                    class="p-1"
                                    role="tab"
                                    v-b-toggle="
                                      `category-${hours.projectSub.id}=${index2}`
                                    "
                                  >
                                    <h4 style="cursor: pointer" class="p-3">
                                      {{ category.vehicleType.name }}
                                      <!-- <b-badge>{{ category.total }}</b-badge> -->
                                    </h4>
                                  </b-card-header>
                                  <b-collapse
                                    :id="`category-${hours.projectSub.id}=${index2}`"
                                    visible
                                    role="tabpanel"
                                  >
                                    <b-card-body>
                                      <b-form-group
                                        :label="
                                          'Filter Chart ' +
                                          category.vehicleType.name
                                        "
                                      >
                                        <v-multiselect
                                          @input="
                                            onChangeBarChart(
                                              $event,
                                              index,
                                              index2
                                            )
                                          "
                                          v-model="
                                            category.opsChart.xaxis.categories
                                          "
                                          tag-placeholder="Category"
                                          placeholder="Search or statistic"
                                          :options="
                                            category.bodyData.data.map(
                                              (f) => f.name
                                            )
                                          "
                                          :multiple="true"
                                          :taggable="true"
                                        ></v-multiselect>
                                      </b-form-group>
                                      <b-card-text>
                                        <ApexChart
                                          v-if="!category.loadingChart"
                                          :element="`chart-hours-${hours.projectSub.id}-${index}-${index2}-${category.bodyData.type}`"
                                          :chartOption="category.opsChart"
                                        />
                                      </b-card-text>
                                    </b-card-body>
                                  </b-collapse>
                                </b-card>
                              </template>
                            </b-card-body>
                          </b-collapse>
                        </b-card>
                      </template>
                    </tab-content-item>
                  </div>
                </template>
              </template>
            </iq-card>
          </b-col>
          <!-- <b-col md="12"><iq-card><template v-slot:headerTitle><h4 class="card-title text-primary"><strong>REPORT DOCKING</strong></h4></template><template v-slot:body><b-row><b-col lg="4"><div class="training-block d-flex align-items-center mt-3"><div class="ml-2"><h5 class="">32 Task</h5><p class="mb-0">Total Task</p></div></div></b-col><b-col lg="4"><div class="training-block d-flex align-items-center mt-3"><div class="ml-2"><h5 class="">15 Task</h5><p class="mb-0">Delay</p></div></div></b-col><b-col lg="4"><div class="training-block d-flex align-items-center mt-3"><div class="ml-2"><h5 class="">Rp. 1.523.000.000</h5><p class="mb-0">Total Cost</p></div></div></b-col></b-row><div class="btn btn-primary d-block mt-4"><i class="fa fa-search"></i> View Detail
                </div></template></iq-card></b-col> -->
        </b-row>
      </b-col>
      <b-col sm="6">
        <iq-card class-name="iq-card-block iq-card-stretch">
          <template v-slot:headerTitle>
            <h5 class="card-title text-primary">
              <strong>Trip & Cargo</strong>
            </h5>
          </template>
          <template v-slot:headerAction></template>
          <template v-slot:body>
            <div class="accordion" role="tablist">
              <template v-for="(cargo, index) in opsMatrix.tripCargo">
                <b-card no-body class="mb-1" :key="`trip-${index}`">
                  <b-card-header
                    header-tag="header"
                    class="p-1"
                    role="tab"
                    v-b-toggle="'cargo' + cargo.projectSub.id"
                  >
                    <h4 style="cursor: pointer" class="p-3">
                      {{ cargo.projectSub.project.projectName }} -
                      {{ cargo.projectSub.name }}
                    </h4>
                  </b-card-header>
                  <b-collapse
                    :id="'cargo' + cargo.projectSub.id"
                    :visible="cargo.visibility"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <b-card-text>
                        <div class="table-responsive">
                          <table class="table mb-0 table-striped table-hover">
                            <thead class="thead-dark text-center">
                              <tr>
                                <th scope="col">No</th>
                                <th scope="col">Fleet</th>
                                <th scope="col">Date</th>
                                <th scope="col">Trip Status</th>
                                <th scope="col">Cargo Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(trip, index2) in cargo.trip"
                                :key="`trip-d-${index2}`"
                              >
                                <td>{{ index2 + 1 }}</td>
                                <td>
                                  <span
                                    v-for="(each, index3) in trip.vehicle"
                                    :key="`fleet-${index3}`"
                                  >
                                    {{
                                      index3 > 0 ? ' / ' + each.name : each.name
                                    }}
                                  </span>
                                </td>
                                <td class="text-center"
                                  >{{
                                    formatDate(filterMonthlyOps?.dateRange[0])
                                  }}
                                  -
                                  {{
                                    formatDate(filterMonthlyOps?.dateRange[1])
                                  }}</td
                                >
                                <td class="text-center">{{
                                  trip.tripStatus
                                }}</td>
                                <td class="text-center">{{
                                  trip.cargoStatus
                                }}</td>
                              </tr>
                              <tr>
                                <td
                                  colspan="3"
                                  class="text-right font-weight-bold"
                                  >Total</td
                                >
                                <td class="text-center font-weight-bold">{{
                                  numberFormat(cargo.totalTripStatus)
                                }}</td>
                                <td class="text-center font-weight-bold">{{
                                  numberFormat(cargo.totalCargoStatus)
                                }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </template>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import { xray } from '../../../config/pluginInit'
import moment from 'moment'
import CertificateDashboard from '../OperationalDashboardComponents/CertificateDashboard.vue'
import AdvancedFilter from '../AdvancedFilter.vue'

import {
  fleetsActions,
  dashboardActions,
  projectsActions,
  maintenanceActions,
  reasonsActions,
  usersActions,
} from '@src/Utils/helper'
import TableOperationalFleetMatrix from './TableOperationalFleetMatrix.vue'

export default {
  name: 'OperationalDashboard',
  components: {
    AdvancedFilter,
    CertificateDashboard,
    TableOperationalFleetMatrix
  },
  props: {
    vehicleTypes: {
      type: Array,
      default: () => [],
    },
    vehicle: {
      type: Array,
      default: () => [],
    },
    projectOptions: {
      type: Array,
      default: () => [],
    },
    optionsUsers: {
      type: Array,
      default: () => [],
    },
  },
  computed: {},
  watch: {
    vehicle: function (newVal, oldVal) {
      this.filterDailyOps.vehicleTypeIds = newVal
      this.filterMonthlyOps.vehicleTypeIds = newVal
      this.fetchMonthlyOps()
      this.fetchDailyOps()
    },
  },
  mounted() {
    xray.index()
  },
  data() {
    return {
      lang: {
        formatLocale: {
          // MMMM
          months: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ],
          // MMM
          monthsShort: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ],
          // dddd
          weekdays: [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
          ],
          // ddd
          weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          // dd
          weekdaysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
          // first day of week
          firstDayOfWeek: 0,
          // first week contains January 1st.
          firstWeekContainsDate: 1,
        },
        monthBeforeYear: false,
      },
      dateRange: [],
      categoryOwnership: [
        { value: '', text: 'All Category Ownership' },
        { value: 'OWNED', text: 'Owned By Company' },
        { value: 'CHARTERED', text: 'Chartered' },
      ],
      idleRepair: [
        {
          no: '1',
          fleet: 'Seatrans 01',
          currentPosition: 'Muara Asam-asam',
          remarks:
            'Continue Pembuatan DudWeatheran ME\t\t\n' +
            'persiapan bubut flywheel ME\t\t\n' +
            'pembuatan housing connector ME-Gearbox\t\t',
          arrival: '08.00 15/9/2020',
          onGoingRepair: '08.00 8/4/2021',
          StoppageTime: ' 4.920 ',
          completeRepair: '14/04/2021',
        },
        {
          no: '2',
          fleet: 'BG. KANAYA',
          currentPosition: 'Sungai Danau',
          remarks:
            'Perbaikan sideboard, engine winch, anchor rack dan penggantian wire jangkar',
          arrival: '21.30 29/3/2021',
          onGoingRepair: '08.00 8/4/2021',
          StoppageTime: '  227  ',
          completeRepair: '30/04/2021',
        },
        {
          no: '3',
          fleet: 'TB. NAVARENO',
          currentPosition: 'Darmais',
          remarks: 'Menunggu FIP Pump Kalibrasi BJM Pump',
          arrival: '20.00 1/4/2021',
          onGoingRepair: '08.00 8/4/2021',
          StoppageTime: '  156 ',
          completeRepair: 'TBN',
        },
      ],
      shipyard: [
        {
          no: '1',
          fleet: 'TB. RAVA II',
          position: 'Citra Bahari Shipyard - Tegal',
          predocking: '23.00.00 09/10/2020',
          upSlip: '08.00 13/12/2020]',
          waitingUpSlip: '1.545',
          updateToday: '08.00 8/4/2021',
          dockingProgress: ' 4.329',
          downSlip: '',
          waitingDownSlip: '',
          completed: '',
          progress: '92%',
          remarks:
            '<b>OUT FITTING</b>' +
            'Install Rantai & Jangkarn' +
            'PIPING' +
            'PROPULTION & STEERING SYSTEM' +
            'NAVIGATION & ELECTRICAL' +
            'Continue Wiring Cable DC' +
            'Wiring Cable Data Main Engine P/S' +
            'CARPENTRY' +
            'Maintenance by crew' +
            'Continue Finishing Area Wheelhouse & Deck House' +
            'Continue Painting Area Engine Room Area' +
            'Continue Painting Area M Deck + Marking Ket Work & Internal Bulwark',
        },
        {
          no: '2',
          fleet: 'BG. ETI 3309',
          position: 'Karya Pacific Teknik - Samarinda',
          predocking: '08:00 14/2/2021',
          upSlip: '08:00 2/3/2021',
          waitingUpSlip: '384',
          updateToday: '08:00 8/4/2021',
          dockingProgress: '1,272',
          downSlip: '',
          waitingDownSlip: '',
          completed: '',
          progress: '99%',
          remarks:
            '1.Progress report mengacu dari update kapro PT KPT<br/>2.Pihak dari PT KPT belum submite update progress',
        },
        {
          no: '3',
          fleet: 'TB. RAVA III',
          position: 'Karya Pacific Teknik - Samarinda',
          predocking: '14:00 31/12/2020',
          upSlip: '08:00 10/1/2021',
          waitingUpSlip: '234',
          updateToday: '08:00 8/4/2021',
          dockingProgress: '2,346',
          downSlip: '',
          waitingDownSlip: '',
          completed: '',
          progress: '100%',
          remarks: 'Standby di dock PT KPT',
        },
        {
          no: '4',
          fleet: 'TB. ETI 3007',
          position: 'Citra Bahari Shipyard - Tegal',
          predocking: '03:00 18/1/2021',
          upSlip: '08:00 21/1/2021',
          waitingUpSlip: '77',
          updateToday: '08:00 8/4/2021',
          dockingProgress: '1,925',
          downSlip: '',
          waitingDownSlip: '',
          completed: '',
          progress: '99%',
          remarks: 'Standby Area Jetty',
        },
        {
          no: '5',
          fleet: 'TB. ETI 105',
          position: 'Citra Bahari Shipyard - Tegal',
          predocking: '12:00 11/2/2021',
          upSlip: '08:00 21/1/2021',
          waitingUpSlip: '1,340',
          updateToday: '08:00 8/4/2021',
          dockingProgress: '1,340',
          downSlip: '',
          waitingDownSlip: '',
          completed: '',
          progress: '77%',
          remarks:
            'Out Fitting:<br/>Hull Construction<br/>1.Additional job: Replating main deck setelah dilakWeatheran chiping oleh crew didapati<br/>main deck keropos<br/>Out fitting<br/>1.Pembuatan penyangga tutup terpal winch<br/>2.Pemasangan safety quick closing FO Day  valve (Finish)<br/>3.Pergantian seating out door AC (Finish)<br/>4.Pergantian rubber water tight door at whell house deck & Emergency exit crew room<br/>5.Doubling conduit pipe cable accu whell house <br/>6.Pergantian housing blower crew room',
        },
      ],
      loadingOpsMatrix: false,
      opsMatrix: {
        matrix: [],
        tripCargo: [],
      },
      statisticAccordion: [
        {
          project: 'KPC Project - Direct Barging Sangatta',
          code: 'AccordionStatisticData1',
          visibility: true,
          data: [
            {
              category: 'Tug Boat',
              code: 'tb',
              total: '4',
              bodyData: {
                type: 'pie',
                colors: [
                  '#0084ff',
                  '#00ca00',
                  '#e64141',
                  '#ffd400',
                  '#00d0ff',
                  '#374948',
                ],
                value: ['litres'],
                category: ['country'],
                data: [
                  {
                    country: 'Running',
                    litres: 501.9,
                    fill: 'red',
                  },
                  {
                    country: 'Crewing',
                    litres: 165.8,
                  },
                  {
                    country: 'Tech',
                    litres: 139.9,
                  },
                  {
                    country: 'Waiting Loading',
                    litres: 128.3,
                  },
                  {
                    country: 'Weather',
                    litres: 99,
                  },
                  {
                    country: 'Ops',
                    litres: 60,
                  },
                ],
              },
            },
            {
              category: 'Barge',
              code: 'bg',
              total: '4',
              bodyData: {
                type: 'pie',
                colors: [
                  '#0084ff',
                  '#00ca00',
                  '#e64141',
                  '#ffd400',
                  '#00d0ff',
                  '#374948',
                ],
                value: ['litres'],
                category: ['country'],
                data: [
                  {
                    country: 'Running',
                    litres: 501.9,
                    fill: 'red',
                  },
                  {
                    country: 'Crewing',
                    litres: 165.8,
                  },
                  {
                    country: 'Tech',
                    litres: 139.9,
                  },
                  {
                    country: 'Waiting Loading',
                    litres: 128.3,
                  },
                  {
                    country: 'Weather',
                    litres: 99,
                  },
                  {
                    country: 'Ops',
                    litres: 60,
                  },
                ],
              },
            },
          ],
        },
        {
          project: 'KPC Project - Transhipment',
          code: 'AccordionStatisticData2',
          visibility: false,
          data: [
            {
              category: 'Tug Boat',
              code: 'tb',
              total: '3',
              bodyData: {
                type: 'pie',
                colors: [
                  '#0084ff',
                  '#00ca00',
                  '#e64141',
                  '#ffd400',
                  '#00d0ff',
                  '#374948',
                ],
                value: ['litres'],
                category: ['country'],
                data: [
                  {
                    country: 'Running',
                    litres: 501.9,
                    fill: 'red',
                  },
                  {
                    country: 'Crewing',
                    litres: 165.8,
                  },
                  {
                    country: 'Tech',
                    litres: 139.9,
                  },
                  {
                    country: 'Waiting Loading',
                    litres: 128.3,
                  },
                  {
                    country: 'Weather',
                    litres: 99,
                  },
                  {
                    country: 'Ops',
                    litres: 60,
                  },
                ],
              },
            },
            {
              category: 'Barge',
              code: 'bg',
              total: '3',
              bodyData: {
                type: 'pie',
                colors: [
                  '#0084ff',
                  '#00ca00',
                  '#e64141',
                  '#ffd400',
                  '#00d0ff',
                  '#374948',
                ],
                value: ['litres'],
                category: ['country'],
                data: [
                  {
                    country: 'Running',
                    litres: 501.9,
                    fill: 'red',
                  },
                  {
                    country: 'Crewing',
                    litres: 165.8,
                  },
                  {
                    country: 'Tech',
                    litres: 139.9,
                  },
                  {
                    country: 'Waiting Loading',
                    litres: 128.3,
                  },
                  {
                    country: 'Weather',
                    litres: 99,
                  },
                  {
                    country: 'Ops',
                    litres: 60,
                  },
                ],
              },
            },
          ],
        },
        {
          project: 'KPC Project - Heavy Equipment',
          code: 'kpc-he',
          visibility: true,
          data: [
            {
              category: 'Heavy Equipment',
              code: 'he',
              total: 2,
              bodyData: {
                type: 'pie',
                colors: [
                  '#0084ff',
                  '#00ca00',
                  '#e64141',
                  '#ffd400',
                  '#00d0ff',
                  '#374948',
                ],
                value: ['litres'],
                category: ['country'],
                data: [
                  {
                    country: 'Utility',
                    litres: 501.9,
                    fill: 'red',
                  },
                  {
                    country: 'Tech',
                    litres: 165.8,
                  },
                  {
                    country: 'Standby for Use',
                    litres: 139.9,
                  },
                ],
              },
            },
          ],
        },
        {
          project: 'AI Project - Direct Barging',
          code: 'ai-direct',
          visibility: false,
          data: [
            {
              category: 'Pusher Tug',
              code: 'pushertug',
              total: '2',
              bodyData: {
                type: 'pie',
                colors: [
                  '#0084ff',
                  '#00ca00',
                  '#e64141',
                  '#ffd400',
                  '#00d0ff',
                  '#374948',
                ],
                value: ['litres'],
                category: ['country'],
                data: [
                  {
                    country: 'Running',
                    litres: 501.9,
                    fill: 'red',
                  },
                  {
                    country: 'Crewing',
                    litres: 165.8,
                  },
                  {
                    country: 'Tech',
                    litres: 139.9,
                  },
                  {
                    country: 'Waiting Loading',
                    litres: 128.3,
                  },
                  {
                    country: 'Weather',
                    litres: 99,
                  },
                  {
                    country: 'Ops',
                    litres: 60,
                  },
                ],
              },
            },
            {
              category: 'Pusher Barge',
              code: 'pusherbarge',
              total: '2',
              bodyData: {
                type: 'pie',
                colors: [
                  '#0084ff',
                  '#00ca00',
                  '#e64141',
                  '#ffd400',
                  '#00d0ff',
                  '#374948',
                ],
                value: ['litres'],
                category: ['country'],
                data: [
                  {
                    country: 'Running',
                    litres: 501.9,
                    fill: 'red',
                  },
                  {
                    country: 'Crewing',
                    litres: 165.8,
                  },
                  {
                    country: 'Tech',
                    litres: 139.9,
                  },
                  {
                    country: 'Waiting Loading',
                    litres: 128.3,
                  },
                  {
                    country: 'Weather',
                    litres: 99,
                  },
                  {
                    country: 'Ops',
                    litres: 60,
                  },
                ],
              },
            },
          ],
        },
        {
          project: 'Nickel Project - Transhipment',
          code: 'nickel-transhipment',
          visibility: false,
          data: [
            {
              category: 'Tug Boat',
              code: 'tb',
              total: '3',
              bodyData: {
                type: 'pie',
                colors: [
                  '#0084ff',
                  '#00ca00',
                  '#e64141',
                  '#ffd400',
                  '#00d0ff',
                  '#374948',
                ],
                value: ['litres'],
                category: ['country'],
                data: [
                  {
                    country: 'Running',
                    litres: 501.9,
                    fill: 'red',
                  },
                  {
                    country: 'Crewing',
                    litres: 165.8,
                  },
                  {
                    country: 'Tech',
                    litres: 139.9,
                  },
                  {
                    country: 'Waiting Loading',
                    litres: 128.3,
                  },
                  {
                    country: 'Weather',
                    litres: 99,
                  },
                  {
                    country: 'Ops',
                    litres: 60,
                  },
                ],
              },
            },
            {
              category: 'Barge',
              code: 'bg',
              total: '3',
              bodyData: {
                type: 'pie',
                colors: [
                  '#0084ff',
                  '#00ca00',
                  '#e64141',
                  '#ffd400',
                  '#00d0ff',
                  '#374948',
                ],
                value: ['litres'],
                category: ['country'],
                data: [
                  {
                    country: 'Running',
                    litres: 501.9,
                    fill: 'red',
                  },
                  {
                    country: 'Crewing',
                    litres: 165.8,
                  },
                  {
                    country: 'Tech',
                    litres: 139.9,
                  },
                  {
                    country: 'Waiting Loading',
                    litres: 128.3,
                  },
                  {
                    country: 'Weather',
                    litres: 99,
                  },
                  {
                    country: 'Ops',
                    litres: 60,
                  },
                ],
              },
            },
          ],
        },
      ],
      tripChart: {
        series: [
          {
            name: 'Trip Statistic',
            data: [2, 1, 7, 1, 3, 8, 7, 3, 6, 5],
          },
        ],
        annotations: {
          points: [
            {
              x: 'Bananas',
              seriesIndex: 0,
              label: {
                borderColor: '#775DD0',
                offsetY: 0,
                style: {
                  color: '#fff',
                  background: '#775DD0',
                },
                text: 'Bananas are good',
              },
            },
          ],
        },
        chart: {
          height: 350,
          type: 'bar',
        },
        colors: ['#089bab'],
        plotOptions: {
          bar: {
            columnWidth: '50%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
        },

        grid: {
          row: {
            colors: ['#fff', '#f2f2f2'],
          },
        },
        xaxis: {
          labels: {
            rotate: -45,
          },
          categories: [
            'TB.ETI 301 / BG.ETI 3601',
            'TB.ETI 302 / BG.ETI 3602',
            'TB.ANUGERAH 1 / BG.ETI 801',
            'TB.ETI 103 / BG.ETI 3003',
            'TB.NAVARENO / BG.ETI 3004',
            'TB.ETI 101 / BG.ETI 3301',
            'TB.ETI 304 / BG.ETI 3302',
            'TB.ETI 308 / BG.ETI 3304',
            'TB.LOTUS 22-07 / BG.MHKL 35',
            'TB.ETI 311 / BG.HM 303',
          ],
          tickPlacement: 'on',
        },
        yaxis: {
          title: {
            text: 'Trip Statistics - Transhipment Project',
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'horizontal',
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [50, 0, 100],
          },
        },
      },
      cargoChart: {
        series: [
          {
            name: 'Cargo Statistic',
            data: [
              '23.943,12',
              '12.022,13',
              '52.649,40',
              '7.518,74',
              '22.519,13',
              '76.536,26',
              '57.131,08',
              '28.578,94',
              '42.784,80',
              '34.630,66',
            ],
          },
        ],
        annotations: {
          points: [
            {
              x: 'Bananas',
              seriesIndex: 0,
              label: {
                borderColor: '#775DD0',
                offsetY: 0,
                style: {
                  color: '#fff',
                  background: '#775DD0',
                },
                text: 'Bananas are good',
              },
            },
          ],
        },
        chart: {
          height: 350,
          type: 'bar',
        },
        colors: ['#089bab'],
        plotOptions: {
          bar: {
            columnWidth: '50%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
        },

        grid: {
          row: {
            colors: ['#fff', '#f2f2f2'],
          },
        },
        xaxis: {
          labels: {
            rotate: -45,
          },
          categories: [
            'TB.ETI 301 / BG.ETI 3601',
            'TB.ETI 302 / BG.ETI 3602',
            'TB.ANUGERAH 1 / BG.ETI 801',
            'TB.ETI 103 / BG.ETI 3003',
            'TB.NAVARENO / BG.ETI 3004',
            'TB.ETI 101 / BG.ETI 3301',
            'TB.ETI 304 / BG.ETI 3302',
            'TB.ETI 308 / BG.ETI 3304',
            'TB.LOTUS 22-07 / BG.MHKL 35',
            'TB.ETI 311 / BG.HM 303',
          ],
          tickPlacement: 'on',
        },
        yaxis: {
          title: {
            text: 'Cargo Statistics - Transhipment Project',
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'horizontal',
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [50, 0, 100],
          },
        },
      },
      opsChart: {
        series: [
          {
            name: 'Reason Day',
            data: [44, 55, 41, 67, 22, 43, 21, 33, 45, 31, 15, 14, 41],
          },
        ],
        annotations: {
          points: [
            {
              x: 'Bananas',
              seriesIndex: 0,
              label: {
                borderColor: '#775DD0',
                offsetY: 0,
                style: {
                  color: '#fff',
                  background: '#775DD0',
                },
                text: 'Bananas are good',
              },
            },
          ],
        },
        chart: {
          height: 300,
          type: 'bar',
        },
        colors: ['#089bab'],
        dataLabels: {
          enabled: false,
        },
        grid: {
          row: {
            colors: ['#fff', '#f2f2f2'],
          },
        },
        xaxis: {
          labels: {
            rotate: -45,
          },
          categories: [
            'Run',
            'Tech',
            'Dock',
            'Weather',
            'Ops',
            'Waiting Loading',
            'Waiting Unloading',
            'Doc POL',
            'Doc POD',
            'Crewing',
            'Finance',
            'Loading',
            'Unloading',
          ],
          tickPlacement: 'on',
        },
      },
      filterDailyOps: {
        date: moment().format('YYYY-MM-DD'),
        ownership: 'OWNED',
        subProject: [],
        vehicleTypeIds: [],
        pic: '',
      },
      filterMonthlyOps: {
        dateRange: [],
        ownership: 'OWNED',
        subProject: [],
        vehicleTypeIds: [],
        pic: '',
      },
      dailyOpsData: [],
      reasons: [],
      lsProfile: JSON.parse(localStorage.getItem('profile')),
      modal:{
        filter:false,
        filterMonthly:false
      },
      filterOptions:[{text:'Pic',value:'pic' }, {text:'Fleet Category', value:'category'}],
      filterOpionSelected:'pic'
    }
  },
  methods: {
    ...fleetsActions,
    ...dashboardActions,
    ...projectsActions,
    ...maintenanceActions,
    ...reasonsActions,
    ...usersActions,
    async fetchDailyOps() {
      let subProjectVal = []
      this.dailyOpsData = []
      if (this.filterDailyOps.subProject.length !== 0) {
        this.filterDailyOps.subProject?.forEach((el) => {
          subProjectVal.push(el.id)
        })
      }
      let vehicleTypeIds = this.filterDailyOps.vehicleTypeIds?.map(
        (item) => item.value
      )
      let params = {
        date: this.filterDailyOps.date,
        ownership: this.filterDailyOps.ownership,
        subProjectIds: subProjectVal.toString(),
        vehicleTypeIds: vehicleTypeIds.toString(),
      }

      if (this.filterDailyOps?.pic?.length > 0) {
        let temp = this.filterDailyOps?.pic.map((obj) => obj.value)
        temp = temp.toString()

        params.pic = temp
      }

      if (
        this.filterDailyOps.ownership === '' ||
        this.filterDailyOps.ownership === null
      )
        delete params.ownership
      if (this.filterDailyOps.subProject.length === 0)
        delete params.subProjectIds
      if (params.date) params.date = moment(params.date).format('DD-MM-YYYY')

      let res = await this.getDailyOps(params)
      if (res.data) {
        const data = res.data
        // this.dailyOpsData = data;
        data?.forEach((res) => {
          if (res.voyage && res.voyage.length > 0) {
            this.dailyOpsData.push(res)
          }
        })
      }
    },
    async exportDailyOps() {
      let subProjectVal = []
      if (this.filterDailyOps.subProject.length !== 0) {
        this.filterDailyOps.subProject?.forEach((el) => {
          subProjectVal.push(el.id)
        })
      }
      let vehicleTypeIds = this.filterDailyOps.vehicleTypeIds?.map(
        (item) => item.value
      )
      const params = {
        date: this.filterDailyOps.date,
        ownership: this.filterDailyOps.ownership,
        subProjectIds: subProjectVal.toString(),
        vehicleTypeIds: vehicleTypeIds.toString(),
      }
      if (
        this.filterDailyOps.ownership === '' ||
        this.filterDailyOps.ownership === null
      )
        delete params.ownership
      if (this.filterDailyOps.subProject.length === 0)
        delete params.subProjectIds
      if (params.date) params.date = moment(params.date).format('DD-MM-YYYY')

      let res = await this.getDailyOpsExport(params)

      if (res.data) {
        let blob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        let url = window.URL.createObjectURL(blob)
        window.open(url)
      }
    },
    async fetchMonthlyOps() {
      let vehicleTypeIds = this.filterMonthlyOps.vehicleTypeIds?.map(
        (item) => item.value
      )

      let params = {
        startDate: moment(this.filterMonthlyOps.dateRange[0]).format(
          'DD-MM-YYYY'
        ),
        endDate: moment(this.filterMonthlyOps.dateRange[1]).format(
          'DD-MM-YYYY'
        ),
        ownership: this.filterMonthlyOps.ownership,
        vehicleTypeIds: vehicleTypeIds.toString(),
      }

      if (this.filterMonthlyOps?.pic?.length > 0) {
        let temp = this.filterMonthlyOps?.pic.map((obj) => obj.value)
        temp = temp.toString()

        params.pic = temp
      }

      if (
        this.filterMonthlyOps.ownership === '' ||
        this.filterMonthlyOps.ownership === null ||
        this.filterMonthlyOps.ownership === undefined
      )
        delete params.ownership
      if (
        this.filterMonthlyOps.vehicleTypeIds === '' ||
        this.filterMonthlyOps.vehicleTypeIds === null ||
        this.filterMonthlyOps.vehicleTypeIds === undefined
      )
        delete params.vehicleTypeIds

      if (
        this.filterMonthlyOps.subProject &&
        this.filterMonthlyOps.subProject.length > 0
      ) {
        let dataTemp = []
        this.filterMonthlyOps.subProject.forEach((el) => {
          dataTemp.push(el.id)
        })
        params.subProjectIds = dataTemp.toString()
      }
      this.loadingOpsMatrix = true
      let res = await this.getMonthlyOps(params)
      
      if (res.status == 'success') {
        if (res.data) {
          const new_data = {
            matrix: null,
            tripCargo: res.data.tripCargo,
          }
          let tempMatrix = [...res.data.matrix]

          new_data.matrix = tempMatrix.map((matrix, index) => {
            matrix.vehicleTypes.map((vehicleType, i) => {
              vehicleType.vehicles.map((vehicle) => {
                let tempArr = []
                vehicle.activities.map((activity) => {
                  this.reasons.map((reason) => {
                    if (activity.name === reason.name) {
                      tempArr.push(activity)
                    }

                    if (activity.name === 'Breakdown') {
                      tempArr.push(activity)
                    }
                  })
                })

                const uniqueData = tempArr.reduce((acc, curr) => {
                  // Check if the current object's id already exists in the accumulator
                  if (acc[curr.name]) {
                    // If the current object has a non-zero value, replace the existing object in the accumulator
                    if (curr.duration !== 0) {
                      acc[curr.name] = curr
                    }
                  } else {
                    // Add the current object to the accumulator
                    acc[curr.name] = curr
                  }
                  return acc
                }, {})
                // Convert the object back to an array of objects
                const result = Object.values(uniqueData)
                vehicle.activities = result
                return vehicle
              })
              return vehicleType
            })
            return matrix
          })

          const data = new_data

          this.opsMatrix = {
            matrix: [],
            tripCargo: [],
          }

          setTimeout(() => {
            data.matrix.forEach((el, i) => {
              el.vehicleTypes.forEach((el2) => {
                
                el2.bodyData = {
                  type: 'pie',
                  colors: [
                    '#0084ff',
                    '#00ca00',
                    '#e64141',
                    '#ffd400',
                    '#00d0ff',
                    '#374948',
                  ],
                  value: ['duration'],
                  category: ['name'],
                  data: el2.vehicles[0].activities,
                }

                el.TypeVehicleOpt = []

                let dataValBarChart = []
                let dataHeaderBarChart = []

                el2.vehicles[0].activities.forEach((el3) => {
                  dataValBarChart.push(el3.duration)
                  dataHeaderBarChart.push(el3.name)
                  el.TypeVehicleOpt.push(el3)
                })

                el2.opsChart = {
                  series: [
                    {
                      name: 'Reason Day',
                      data: dataValBarChart,
                    },
                  ],
                  annotations: {
                    points: [
                      {
                        x: 'Bananas',
                        seriesIndex: 0,
                        label: {
                          borderColor: '#775DD0',
                          offsetY: 0,
                          style: {
                            color: '#fff',
                            background: '#775DD0',
                          },
                          text: 'Bananas are good',
                        },
                      },
                    ],
                  },
                  chart: {
                    height: 300,
                    type: 'bar',
                  },
                  colors: ['#089bab'],
                  dataLabels: {
                    enabled: false,
                  },
                  grid: {
                    row: {
                      colors: ['#fff', '#f2f2f2'],
                    },
                  },
                  xaxis: {
                    labels: {
                      rotate: -45,
                    },
                    categories: dataHeaderBarChart,
                    tickPlacement: 'on',
                  },
                }
                el2.loadingChart = false
              })
              el.visibility = false
              if (i == 0) el.visibility = true
            })
            data.tripCargo.forEach((el, i) => {
              el.visibility = false
              el.visibilityTripStats = false
              el.visibilityStats = false
              if (i == 0) {
                el.visibility = true
                el.visibilityTripStats = true
                el.visibilityStats = true
              }
              el.totalTripStatus = 0
              el.totalCargoStatus = 0
              let dataValTripStatsChart = []
              let dataValCargoStatsChart = []
              let dataHeaderTripStatsChart = []
              el.trip.forEach((el2) => {
                dataValTripStatsChart.push(el2.tripStatus)
                dataValCargoStatsChart.push(el2.cargoStatus)
                let nameTrip = ''
                el2.vehicle.forEach((el3, idx3) => {
                  if (idx3 > 0) {
                    nameTrip = nameTrip + ' / ' + el3.name
                  } else {
                    nameTrip = el3.name
                  }
                })
                dataHeaderTripStatsChart.push(nameTrip)

                el.totalTripStatus += parseInt(el2.tripStatus)
                el.totalCargoStatus += parseInt(el2.cargoStatus)
              })

              el.tripChart = {
                series: [
                  {
                    name: 'Trip Statistic',
                    data: dataValTripStatsChart,
                  },
                ],
                annotations: {
                  points: [
                    {
                      x: 'Bananas',
                      seriesIndex: 0,
                      label: {
                        borderColor: '#775DD0',
                        offsetY: 0,
                        style: {
                          color: '#fff',
                          background: '#775DD0',
                        },
                        text: 'Bananas are good',
                      },
                    },
                  ],
                },
                chart: {
                  height: 350,
                  type: 'bar',
                },
                colors: ['#089bab'],
                plotOptions: {
                  bar: {
                    columnWidth: '50%',
                    endingShape: 'rounded',
                  },
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  width: 2,
                },

                grid: {
                  row: {
                    colors: ['#fff', '#f2f2f2'],
                  },
                },
                xaxis: {
                  labels: {
                    rotate: -45,
                  },
                  categories: dataHeaderTripStatsChart,
                  tickPlacement: 'on',
                },
                yaxis: {
                  title: {
                    text: 'Trip Statistics - Transhipment Project',
                  },
                },
                fill: {
                  type: 'gradient',
                  gradient: {
                    shade: 'light',
                    type: 'horizontal',
                    shadeIntensity: 0.25,
                    gradientToColors: undefined,
                    inverseColors: true,
                    opacityFrom: 0.85,
                    opacityTo: 0.85,
                    stops: [50, 0, 100],
                  },
                },
              }

              el.cargoChart = {
                series: [
                  {
                    name: 'Cargo Statistic',
                    data: dataValCargoStatsChart,
                  },
                ],
                annotations: {
                  points: [
                    {
                      x: 'Bananas',
                      seriesIndex: 0,
                      label: {
                        borderColor: '#775DD0',
                        offsetY: 0,
                        style: {
                          color: '#fff',
                          background: '#775DD0',
                        },
                        text: 'Bananas are good',
                      },
                    },
                  ],
                },
                chart: {
                  height: 350,
                  type: 'bar',
                },
                colors: ['#089bab'],
                plotOptions: {
                  bar: {
                    columnWidth: '50%',
                    endingShape: 'rounded',
                  },
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  width: 2,
                },

                grid: {
                  row: {
                    colors: ['#fff', '#f2f2f2'],
                  },
                },
                xaxis: {
                  labels: {
                    rotate: -45,
                  },
                  categories: dataHeaderTripStatsChart,
                  tickPlacement: 'on',
                },
                yaxis: {
                  title: {
                    text: 'Cargo Statistics - Transhipment Project',
                  },
                },
                fill: {
                  type: 'gradient',
                  gradient: {
                    shade: 'light',
                    type: 'horizontal',
                    shadeIntensity: 0.25,
                    gradientToColors: undefined,
                    inverseColors: true,
                    opacityFrom: 0.85,
                    opacityTo: 0.85,
                    stops: [50, 0, 100],
                  },
                },
              }
            })

            this.opsMatrix = data

            this.loadingOpsMatrix = false
          }, 500)
        }
      } else {
        this.$swal(`Oops!`, res.data.message, 'error')
      }
    },
    async exportMonthlyOps() {
      let vehicleTypeIds = this.filterMonthlyOps.vehicleTypeIds?.map(
        (item) => item.value
      )
      let params = {
        startDate: moment(this.filterMonthlyOps.dateRange[0]).format(
          'DD-MM-YYYY'
        ),
        endDate: moment(this.filterMonthlyOps.dateRange[1]).format(
          'DD-MM-YYYY'
        ),
        ownership: this.filterMonthlyOps.ownership,
        vehicleTypeIds: vehicleTypeIds.toString(),
      }

      if (!params.ownership) delete params.ownership
      if (!params.vehicleTypeIds) delete params.vehicleTypeIds

      if (
        this.filterMonthlyOps.subProject &&
        this.filterMonthlyOps.subProject.length > 0
      ) {
        let dataTemp = []
        this.filterMonthlyOps.subProject.forEach((el) => {
          dataTemp.push(el.id)
        })
        params.subProjectIds = dataTemp.toString()
      }

      let res = await this.getMonthlyOpsExport(params)
      if (res.data) {
        let blob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        let url = window.URL.createObjectURL(blob)
        window.open(url)
      }
    },
    formatDate (val) {
      console.log('date',val);
      
      if (val) {
        // let dateVal = `${val.split('-')[2]}-${val.split('-')[1]}-${val.split('-')[0]}`
        return moment(val).format('DD MMMM YYYY')
      }
    }
  },
}
</script>
<style scoped>
table th {
  background: #099bab !important;
}
</style>
