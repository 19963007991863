<template>
  <b-container fluid>
    <CustomNav
      :items="itemTabs"
      primary
      @item-click="(val) => (tabSelected = val)"
    ></CustomNav>
    <template v-if="tabSelected == 1">
      <SummaryFleetStatus />
      <PercentageAvailability />
      <Breakdown />
      <b-row>
        <b-col md="8">
          <BreakdownPercentage />
        </b-col>
        <b-col md="4"> <BreakdownVsAvailability /> </b-col>
      </b-row>
    </template>
    <template v-else>
      <SummaryTotalBudget />
      <b-row>
        <b-col md="6">
          <BudgetExpanse />
        </b-col>
        <b-col md="6"><BudgetPlanVsExpanse /></b-col>
      </b-row>
      <SummaryDocking />
      <b-row>
        <b-col md="6">
          <BudgetDockingCategory />
        </b-col>
        <b-col md="6"><BudgetExpanseVsPlan /></b-col>
      </b-row>
    </template>
  </b-container>
</template>
<script>
import CustomNav from '@/src/components/global/CustomNav.vue'
import Breakdown from './TechnicalDashboardComponents/Breakdown.vue'
import BreakdownPercentage from './TechnicalDashboardComponents/BreakdownPercentage.vue'
import BreakdownVsAvailability from './TechnicalDashboardComponents/BreakdownVsAvailability.vue'
import PercentageAvailability from './TechnicalDashboardComponents/PercentageAvailability.vue'
import SummaryFleetStatus from './TechnicalDashboardComponents/SummaryFleetStatus.vue'
import SummaryTotalBudget from './TechnicalDashboardComponents/SummaryTotalBudget.vue'
import BudgetExpanse from './TechnicalDashboardComponents/BudgetExpanse.vue'
import BudgetPlanVsExpanse from './TechnicalDashboardComponents/BudgetPlanVsExpanse.vue'
import SummaryDocking from './TechnicalDashboardComponents/SummaryDocking.vue'
import BudgetDockingCategory from './TechnicalDashboardComponents/BudgetDockingCategory.vue'
import BudgetExpanseVsPlan from './TechnicalDashboardComponents/BudgetExpanseVsPlan.vue'

export default {
  components: {
    SummaryFleetStatus,
    BreakdownPercentage,
    PercentageAvailability,
    Breakdown,
    BreakdownVsAvailability,
    CustomNav,
    SummaryTotalBudget,
    BudgetExpanse,
    BudgetPlanVsExpanse,
    SummaryDocking,
    BudgetDockingCategory,
    BudgetExpanseVsPlan,
  },
  data() {
    return {
      charts: [
        {
          title: 'Tug & Barge',
          breakdownElement: 'tug-and-barge-breakdown',
          availabilityElement: 'tug-and-barge-availability',
          breakdownSeries: [
            {
              name: 'Plan Breakdown',
              data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 40, 60],
              color: '#017AFF',
            },
            {
              name: 'Emergency Breakdown',
              data: [76, 85, 89, 98, 87, 78, 91, 90, 94, 72, 76],
              color: '#FB6F92',
            },
          ],
          availabilitySeries: [
            {
              data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 40, 60],
              color: '#2280BF',
            },
          ],
        },
        {
          title: 'Floating Crane',
          breakdownElement: 'floating-crane-breakdown',
          availabilityElement: 'floating-crane-availability',
          breakdownSeries: [
            {
              name: 'Plan Breakdown',
              data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 40, 60],
              color: '#094E86',
            },
            {
              name: 'Emergency Breakdown',
              data: [76, 85, 89, 98, 87, 78, 91, 90, 94, 72, 76],
              color: '#2BDCAA',
            },
          ],
          availabilitySeries: [
            {
              data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 40, 60],
              color: '#E8C9AB',
            },
          ],
        },
        {
          title: 'Heavy Equipment',
          breakdownElement: 'heavy-equipment-breakdown',
          availabilityElement: 'heavy-equipment-availability',
          breakdownSeries: [
            {
              name: 'Plan Breakdown',
              data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 40, 60],
              color: '#6500E9',
            },
            {
              name: 'Emergency Breakdown',
              data: [76, 85, 89, 98, 87, 78, 91, 90, 94, 72, 76],
              color: '#00E2D8',
            },
          ],
          availabilitySeries: [
            {
              data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 40, 60],
              color: '#05B3B7',
            },
          ],
        },
      ],
      tabSelected: 1,
      itemTabs: [
        {
          label: 'AVAILABILITY & BREAKDOWN REPORT',
          value: 1,
        },
        {
          label: 'BUDGET REPORT',
          value: 2,
        },
      ],
    }
  },
}
</script>
