<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <b-row class="mb-2">
          <b-col lg="12">
            <div class="d-flex w-100 align-items-center justify-content-end">
              <b-button variant="danger" class="mr-2" @click="openSlideCreateEmail"> 
                <i class="fa fa-ban mr-2"></i> Discard
              </b-button>
              <b-button variant="primary">
                <i class="fa fa-check"></i> Save
              </b-button>
            </div>
          </b-col>
        </b-row>
        <div class="border w-100 d-flex my-4"></div>
        <b-row class="mb-2">
          <b-col lg="12">
            <div class="d-flex align-items-center justify-content-end w-100">
              <b-button variant="primary" class="mr-2"> 
                <i class="fa fa-file mr-2"></i> Add Context Account
              </b-button>
              <b-button variant="primary" @click="$nextTick(() => {
                  $bvModal.show('preview')
                })">
                <i class="fa fa-eye"></i> Preview
              </b-button>
            </div>
          </b-col>
          <b-col lg="4">
            <b-form-group label="Name">
              <b-form-input placeholder="Type Text"></b-form-input>
            </b-form-group>
            <b-form-group label="Applies to">
              <b-form-input placeholder="Type Text"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="border w-100 d-flex my-4"></div>
        <b-row class="mb-2">
          <b-col lg="12">
            <b-row>
              <b-col lg="12" >
                <div class="">
                  <tab-nav :pills="true" class=" d-flex">
                    <tab-nav-items
                      class="p-0"
                      :active="true"
                      href="#content"
                      title="CONTENT"
                    />
                    <tab-nav-items
                      class="p-0"
                      :active="false"
                      href="#email-configuration"
                      title="EMAIL CONFIGURATION"
                    />  
                    <tab-nav-items
                      class="p-0"
                      :active="false"
                      href="#advance-setting"
                      title="ADVANCE SETTING"
                    />  
                    <tab-nav-items
                      class="p-0"
                      :active="false"
                      href="#placeholder-generator"
                      title="DYNAMIC PLACEHOLDER GENERATOR"
                    />  
                  </tab-nav>
                </div>
              </b-col>
              <b-col lg="12"><div class="border w-100 d-flex my-4"></div></b-col>
              <b-col lg="12">
                <div id="pills-tabContent-2" class="tab-content">
                  <tab-content-item :active="true" id="content">
                    <b-form-group label="Subject">
                      <b-form-input placeholder="Type Text" class="w-50"></b-form-input>
                    </b-form-group>
                    <wysiwyg class="my-2" />
                    <b-form-group label="Attachment">
                      <b-form-file class="w-50"></b-form-file>
                    </b-form-group>
                  </tab-content-item>

                  <tab-content-item :active="false" id="email-configuration">
                    <b-form-group label="Form">
                      <b-form-input placeholder="Override authors email"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Default Recipient">
                      <b-form-checkbox type="checkbox" ></b-form-checkbox>
                    </b-form-group>
                    <b-form-group label="To Emails">
                      <b-form-input placeholder="Comma-seperated recipient addresses" ></b-form-input>
                    </b-form-group>
                    <b-form-group label="To Partners">
                      <b-form-input placeholder="Comma-seperated ids of recipient partners" ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Cc">
                      <b-form-input placeholder="Comma-seperated carbon copy recipient addresses" ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Reply to">
                      <b-form-input placeholder="Preferred reply address" ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Qualification URL">
                      <b-form-input placeholder="Type Text" ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Scheduled Send Date">
                      <b-form-input placeholder="Type Text" ></b-form-input>
                    </b-form-group>
                  </tab-content-item>

                  <tab-content-item :active="false" id="advance-setting">
                    <b-form-group label="Language">
                      <b-form-input placeholder="${object.partner_id.lang}"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Outgoing Mail Server">
                      <b-form-input placeholder="Type Text"></b-form-input>
                    </b-form-group> 
                    <b-form-group label="Auto Delete">
                      <b-form-checkbox :checked="true" type="checkbox"></b-form-checkbox>
                    </b-form-group>
                    <b-form-group label="Optional report to print and attachr">
                      <b-form-input placeholder="Type Text"></b-form-input>
                    </b-form-group> 
                  </tab-content-item>

                  <tab-content-item :active="false" id="placeholder-generator">
                    <b-form-group label="Field">
                      <b-form-input placeholder="Type Text"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Sub Model">
                    </b-form-group> 
                    <b-form-group label="Sub Field">
                    </b-form-group>
                    <b-form-group label="Default Value">
                      <b-form-input placeholder="Type Text"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Placeholder Expression">
                      <b-form-input placeholder="Type Text"></b-form-input>
                    </b-form-group>
                  </tab-content-item>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>

      <!-- MODAL PREVIEW -->
      <b-modal
        id="preview"
        size="xl"
        title="Template Preview"
        cancel-title="Close"
        hide-footer
      >
        <b-row>
          <b-col lg="6">
            <strong class="text-muted">
              Preview of Test
            </strong>
            <br />
            <span class="text-muted">
              Choose an example <b-button variant="link">Analytic Account</b-button> Record:
            </span>
            <br />
            <span>Force a Language:</span> 
            <br />
            <strong class="text-muted">
              Subject
            </strong>
          </b-col>
          <b-col lg="6">
            <strong class="text-warning">No record for this model</strong>
            <b-form-select>
              <option value="">Select options</option>
              <option value="">English (US)</option>
              <option value="">Indonesian / Bahasa Indonesia</option>
            </b-form-select>
          </b-col>
          <div class="border w-100 d-flex my-4"></div>
          <b-col lg="12">
            <b-button @click="$nextTick(() => {$bvModal.hide('preview')})">Cancel</b-button>
          </b-col>
        </b-row>
      </b-modal>
      <!-- MEDICAL CHECKUP END -->
    </b-row>
  </b-container>
</template>

<script>
  import _ from 'lodash'
  import moment from 'moment'
  import { projectsActions, companiesActions, fleetsActions, busdevActions, crewingActions } from '@src/Utils/helper'

  export default {
    name:'SlideFormCreateEmail',
    props:{
      openSlideCreateEmail: {
        type:Function,
        default: true
      }
    },
    components:{
     
    },
    data(){
      return{

      }
    },
    mounted(){},
    methods:{
    }
  }
</script>
