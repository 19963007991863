var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.loading ? _c('div', {
    staticClass: "alert alert-info m-4"
  }, [_vm._v(" Loading... ")]) : _c('b-row', {
    staticClass: "m-4"
  }, [_vm.projectForms.length > 0 ? _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "nav-fill mb-4 rounded shadow",
          attrs: {
            "pills": true
          }
        }, [_c('div', {
          staticClass: "d-flex w-100 overflow-auto align-item-center"
        }, _vm._l(_vm.projectForms, function (state, index) {
          return _c('div', {
            key: index,
            class: "col-md-2 p-2 rounded ".concat(_vm.activeTab === index ? 'bg-primary' : ''),
            staticStyle: {
              "cursor": "pointer"
            },
            on: {
              "click": function click($event) {
                return _vm.changeTab(index);
              }
            }
          }, [_c('div', {
            staticClass: "d-flex align-items-center justify-content-center w-100"
          }, [_c('span', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(state.tabTitle))])])]);
        }), 0)])], 1)];
      },
      proxy: true
    }], null, false, 115274935)
  })], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "border my-2 w-100"
  })]), _vm.projectForms.length > 0 ? _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "tab-content"
  }, _vm._l(_vm.projectForms, function (form, formIndex) {
    return _c('div', {
      key: formIndex,
      staticClass: "tab-pane",
      class: {
        active: _vm.activeTab === formIndex
      }
    }, [_c('div', {
      staticClass: "d-flex my-2 w-100 align-items-center justify-content-end"
    }, [_c('b-button', {
      attrs: {
        "variant": "success"
      },
      on: {
        "click": function click($event) {
          var _vm$detailProjectCont, _vm$detailProjectCont2, _vm$detailProjectCont3;
          return _vm.openInspectionChecklistSlide(false, true, form, _vm.detailProjectContract.vehicle_id, (_vm$detailProjectCont = _vm.detailProjectContract.vehicle) === null || _vm$detailProjectCont === void 0 ? void 0 : (_vm$detailProjectCont2 = _vm$detailProjectCont.vehicle_type) === null || _vm$detailProjectCont2 === void 0 ? void 0 : _vm$detailProjectCont2.name, (_vm$detailProjectCont3 = _vm.detailProjectContract) === null || _vm$detailProjectCont3 === void 0 ? void 0 : _vm$detailProjectCont3.id);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-plus mr-2"
    }), _vm._v(" Add Inspection")])], 1), _c('div', {
      staticClass: "overflow-auto d-flex"
    }, [_c('table', {
      staticClass: "table table-striped table-bordered"
    }, [_c('thead', [form.inspection_checklist.length > 0 ? _c('tr', [_c('th', {
      staticClass: "text-center align-middle",
      staticStyle: {
        "width": "20px"
      }
    }, [_vm._v("√")]), _c('th', {
      staticClass: "text-center align-middle text-truncate"
    }, [_vm._v("Fleet")]), _c('th', {
      staticClass: "text-center align-middle text-truncate"
    }, [_vm._v("Field")]), _c('th', {
      staticClass: "text-center align-middle text-truncate"
    }, [_vm._v("Signature")]), _c('th', {
      staticClass: "text-center align-middle text-truncate"
    }, [_vm._v("Status")]), _c('th', {
      staticClass: "text-center align-middle",
      staticStyle: {
        "width": "20px"
      }
    }, [_vm._v("#")])]) : _vm._e()]), _c('tbody', [form.inspection_checklist.length > 0 ? _vm._l(form.inspection_checklist, function (state, index) {
      var _state$vehicle$name, _state$vehicle;
      return _c('tr', {
        key: index
      }, [_c('td', [_c('b-form-checkbox', {
        model: {
          value: state.checked,
          callback: function callback($$v) {
            _vm.$set(state, "checked", $$v);
          },
          expression: "state.checked"
        }
      })], 1), _c('td', [_c('div', {
        staticClass: "d-flex flex-column"
      }, [_c('strong', {
        staticClass: "text-truncate"
      }, [_vm._v(_vm._s((_state$vehicle$name = state === null || state === void 0 ? void 0 : (_state$vehicle = state.vehicle) === null || _state$vehicle === void 0 ? void 0 : _state$vehicle.name) !== null && _state$vehicle$name !== void 0 ? _state$vehicle$name : '-'))])])]), _c('td', [_c('div', {
        staticClass: "d-flex flex-column"
      }, _vm._l(state === null || state === void 0 ? void 0 : state.field, function (field, fieldIndex) {
        return _c('div', {
          key: fieldIndex,
          staticClass: "d-flex mb-1"
        }, [_c('strong', {
          staticClass: "mr-2 text-truncate"
        }, [_vm._v(_vm._s(field === null || field === void 0 ? void 0 : field.label) + ": ")]), _c('span', {
          staticClass: "text-truncate"
        }, [_vm._v(_vm._s(field !== null && field !== void 0 && field.value ? _vm.handleTypeDate(field === null || field === void 0 ? void 0 : field.value) : '-'))])]);
      }), 0)]), _c('td', [_c('div', {
        staticClass: "d-flex flex-column"
      }, _vm._l(state === null || state === void 0 ? void 0 : state.signature, function (signature, signatureIndex) {
        return _c('div', {
          key: signatureIndex,
          staticClass: "d-flex mb-1"
        }, [_c('strong', {
          staticClass: "mr-2 text-truncate"
        }, [_vm._v(_vm._s(signature === null || signature === void 0 ? void 0 : signature.label) + ": ")]), _c('span', {
          staticClass: "text-truncate"
        }, [_vm._v(_vm._s(signature !== null && signature !== void 0 && signature.value ? _vm.handleTypeDate(signature === null || signature === void 0 ? void 0 : signature.value) : '-'))])]);
      }), 0)]), _c('td', [_c('strong', {
        staticClass: "text-primary text-capitalize"
      }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.status))])]), _c('td', [_c('b-dropdown', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.top",
          value: 'Action',
          expression: "'Action'",
          modifiers: {
            "top": true
          }
        }],
        staticClass: "float-right mr-1",
        attrs: {
          "variant": "link iq-bg-primary",
          "toggle-class": "text-decoration-none",
          "no-caret": "",
          "size": "sm"
        },
        scopedSlots: _vm._u([{
          key: "button-content",
          fn: function fn() {
            return [_c('span', {
              staticClass: "dropdown-toggle p-0",
              attrs: {
                "id": "dropdownMenuButton5",
                "data-toggle": "dropdown"
              }
            }, [_c('i', {
              staticClass: "fa fa-pen m-0"
            })])];
          },
          proxy: true
        }], null, true)
      }, [_c('b-dropdown-item', {
        attrs: {
          "href": "#",
          "variant": "primary"
        },
        on: {
          "click": function click($event) {
            var _vm$detailProjectCont4, _vm$detailProjectCont5, _vm$detailProjectCont6;
            return _vm.openInspectionChecklistSlide(true, false, state, _vm.detailProjectContract.vehicle_id, (_vm$detailProjectCont4 = _vm.detailProjectContract.vehicle) === null || _vm$detailProjectCont4 === void 0 ? void 0 : (_vm$detailProjectCont5 = _vm$detailProjectCont4.vehicle_type) === null || _vm$detailProjectCont5 === void 0 ? void 0 : _vm$detailProjectCont5.name, (_vm$detailProjectCont6 = _vm.detailProjectContract) === null || _vm$detailProjectCont6 === void 0 ? void 0 : _vm$detailProjectCont6.id);
          }
        }
      }, [_c('i', {
        staticClass: "fa fa-eye mr-2"
      }), _vm._v("Preview")])], 1)], 1)]);
    }) : [_c('tr', [_c('td', {
      staticClass: "text-center",
      attrs: {
        "colSpan": "6"
      }
    }, [_vm._v(" There's no inspection yet. ")])])]], 2)])]), form.inspection_checklist.length > 0 ? _c('div', {
      staticClass: "d-flex align-items-center justify-content-end w-100 mt-4"
    }, [_c('b-button', {
      staticStyle: {
        "width": "20%"
      },
      attrs: {
        "disabled": _vm.saving,
        "block": "",
        "variant": "primary",
        "type": "button"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.onSave.apply(null, arguments);
        }
      }
    }, [_vm.saving ? _c('span', {
      staticClass: "spinner-border spinner-border-sm"
    }) : _c('div', [_c('i', {
      staticClass: "fa fa-save"
    }), _c('span', [_vm._v("Submit Data")])])])], 1) : _vm._e()]);
  }), 0)]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }