<template>
  <b-container fluid>
    <form action="#" method="post" @submit="onSave">
      <b-row>
        <b-col lg="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"
                ><strong
                  >LAPORAN CONDITE CREW / <i>CONDITE CREW REPORT</i></strong
                ></h4
              >
            </template>
            <template v-slot:body>
              <!-- INFORMATION CREW -->
              <fieldset class="form-group border p-3">
                <legend class="w-auto px-2 text-primary"
                  >Crew Information</legend
                >
                <b-row>
                  <b-col md="10">
                    <table class="table table-striped mt-2">
                      <tbody>
                        <tr>
                          <th width="20%">Nama Crew</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">{{
                            userDetails?.fullname ?? '-'
                          }}</td>
                          <th width="20%">No KTP</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">{{ userDetails?.no_ktp ?? '-' }}</td>
                        </tr>
                        <tr>
                          <th width="20%">Tempat/Tanggal Lahir</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%"
                            >{{
                              userDetails?.personal_data?.place_of_birth ?? '-'
                            }},
                            {{
                              userDetails?.personal_data?.date_of_birth ?? '-'
                            }}</td
                          >
                          <th width="20%">No NPWP</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">{{
                            userDetails?.personal_data?.npwp_number ?? '-'
                          }}</td>
                        </tr>
                        <tr>
                          <th width="20%">Jabatan</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">{{
                            userDetails?.position?.name ?? '-'
                          }}</td>
                          <th width="20%">ID Crew</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">{{ userDetails?.nip ?? '-' }}</td>
                        </tr>
                        <tr>
                          <th width="20%">Perusahaan</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">{{
                            userDetails?.company?.company ?? '-'
                          }}</td>
                          <th width="20%">Seaferer Code</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">{{
                            userDetails?.personal_data?.seaferer_code ?? '-'
                          }}</td>
                        </tr>
                        <tr>
                          <th width="20%">Nama Kapal</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">{{
                            userDetails?.vehicle?.name ?? '-'
                          }}</td>
                          <th width="20%">Seaman Book</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">{{
                            userDetails?.seamant_book ?? '-'
                          }}</td>
                        </tr>
                        <tr>
                          <th width="20%">Pelabuhan</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">{{
                            userDetails?.port?.port_name ?? '-'
                          }}</td>
                          <th width="20%">No Passport</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">{{
                            userDetails?.personal_data?.passport_number ?? '-'
                          }}</td>
                        </tr>
                        <tr>
                          <th width="20%">Sign On</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">{{ userDetails?.sign_on ?? '-' }}</td>
                          <th width="20%">Nomor Tlp / Hp</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">{{
                            userDetails?.personal_data?.hp_number ?? '-'
                          }}</td>
                        </tr>
                        <tr>
                          <th width="20%">Join Onboard</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">{{
                            userDetails?.join_on_board ?? '-'
                          }}</td>
                          <th width="20%">Email</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">{{ userDetails?.email ?? '-' }}</td>
                        </tr>
                        <tr>
                          <th width="20%">Sign Off</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">{{
                            userDetails?.sign_off ?? '-'
                          }}</td>
                          <th width="20%">Periode Penilaian</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">
                            <b-form-select
                              plain
                              v-model="form.evaluation_period"
                              :options="evaluasi"
                              size="sm"
                              required
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="null"
                                  >Pilih Periode</b-form-select-option
                                >
                              </template>
                            </b-form-select>
                          </td>
                        </tr>
                        <tr>
                          <th width="20%">Alamat</th>
                          <td width="5%" class="text-center">:</td>
                          <td colspan="2">
                            {{
                              userDetails?.candidate?.alamat_ktp?.[0]?.alamat ??
                              '-'
                            }}
                          </td>
                          <td width="5%" class="text-center">&nbsp;</td>
                          <td width="25%">&nbsp;</td>
                        </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <b-col md="2" class="text-center">
                    <b-img
                      thumbnail
                      class="ml-0"
                      :src="
                        userDetails?.photo
                          ? userDetails?.photo
                          : require('../../assets/images/page-img/10.jpg')
                      "
                      alt="Responsive image"
                    ></b-img>
                  </b-col>
                </b-row>
              </fieldset>
              <!-- CONDITE FORM -->
              <fieldset class="form-group border p-3">
                <legend class="w-auto px-2 text-primary">Form Condite</legend>
                <b-row class="mt-3">
                  <b-col md="12" class="mb-3">
                    <table class="table table-striped">
                      <thead>
                        <tr class="text-center">
                          <th width="18%" rowspan="2" class="align-middle"
                            >URAIAN</th
                          >
                          <th
                            width="15%"
                            v-for="(point, index) in condite_points"
                            :key="index"
                            >NILAI Poin = {{ point.value }}</th
                          >
                          <th width="12%" rowspan="2" class="align-middle"
                            >POIN</th
                          >
                        </tr>
                        <tr class="text-center">
                          <th
                            v-for="(point, index) in condite_points"
                            :key="index"
                            >{{ point.name }}</th
                          >
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          style="font-size: 9pt"
                          v-for="(state, index) in merged_condite"
                          :key="index"
                        >
                          <th> {{ state.uraian }} </th>
                          <td v-for="(val, i) in state.description" :key="i">
                            {{ val.description }}
                          </td>

                          <td class="text-center">
                            <b-form-select
                              plain
                              v-model="state.value"
                              :options="optionsPoints"
                              size="sm"
                              class="col-md-12"
                              required
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="null"
                                  >Nilai</b-form-select-option
                                >
                              </template>
                            </b-form-select>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-col>

                  <b-col md="3">
                    <div class="form-group">
                      <small class="mb-3"
                        ><strong
                          >Rekomendasi oleh Nakhoda / Chief Officer <br />
                        </strong>
                        Recommendation by Master</small
                      >

                      <b-form-textarea
                        style="font-size: 9pt"
                        rows="2"
                        placeholder="Comments if any / Komentar jika ada"
                        required
                        v-model="form.recommendation_master"
                      ></b-form-textarea>
                    </div>
                    <hr />
                    <div class="form-group row">
                      <label
                        label="Clasification *"
                        for="nama_kandidiat"
                        class="col-sm-6"
                        >Wawancara Oleh</label
                      >

                      <div class="col-sm-6">
                        <b-form-input
                          id="job_name"
                          type="text"
                          placeholder="Nama"
                          v-model="form.name_master"
                          required
                        ></b-form-input>
                      </div>
                    </div>
                  </b-col>
                  <b-col md="3">
                    <div class="form-group">
                      <small class="mb-3"
                        ><strong
                          >Rekomendasi oleh KKM (khusus untuk crew bagian mesin)
                          <br />
                        </strong>
                        Recommendation by Chief Engineer (only for engine
                        crew)</small
                      >

                      <b-form-textarea
                        style="font-size: 9pt"
                        rows="2"
                        placeholder="Comments if any / Komentar jika ada"
                        v-model="form.recommendation_user"
                        required
                      ></b-form-textarea>
                    </div>
                    <hr />
                    <div class="form-group row">
                      <label
                        label="Clasification *"
                        for="nama_kandidiat"
                        class="col-sm-6"
                        >Wawancara Oleh</label
                      >

                      <div class="col-sm-6">
                        <b-form-input
                          id="job_name"
                          type="text"
                          placeholder="Nama"
                          v-model="form.name_user"
                          required
                        ></b-form-input>
                      </div>
                    </div>
                  </b-col>
                  <b-col md="3">
                    <div class="form-group">
                      <small class="mb-3"
                        ><strong
                          >Rekomendasi oleh Port Captain / Port Engineer <br />
                        </strong>
                        Recommendation by Port Captain / Port Engineer</small
                      >

                      <b-form-textarea
                        style="font-size: 9pt"
                        rows="2"
                        placeholder="Comments if any / Komentar jika ada"
                        required
                        v-model="form.recommendation4"
                      ></b-form-textarea>
                    </div>
                    <hr />
                    <div class="form-group row">
                      <label
                        label="Clasification *"
                        for="nama_kandidiat"
                        class="col-sm-6"
                        >Wawancara Oleh</label
                      >

                      <div class="col-sm-6">
                        <b-form-input
                          id="job_name"
                          type="text"
                          placeholder="Nama"
                          v-model="form.name4"
                          required
                        ></b-form-input>
                      </div>
                    </div>
                  </b-col>
                  <b-col md="3">
                    <div class="form-group">
                      <small class="mb-3"
                        ><strong>Rekomendasi oleh Site Manager <br /> </strong>
                        Recommendation by Site Manager</small
                      >

                      <b-form-textarea
                        style="font-size: 9pt"
                        rows="2"
                        placeholder="Comments if any / Komentar jika ada"
                        required
                        v-model="form.recommendation_manager"
                      ></b-form-textarea>
                    </div>
                    <hr />
                    <div class="form-group row">
                      <label
                        label="Clasification *"
                        for="nama_kandidiat"
                        class="col-sm-6"
                        >Wawancara Oleh</label
                      >

                      <div class="col-sm-6">
                        <b-form-input
                          id="job_name"
                          type="text"
                          placeholder="Nama"
                          v-model="form.name_manager"
                          required
                        ></b-form-input>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </fieldset>
              <b-row class="mt-3">
                <b-col md="12" class="mt-3">
                  <b-button
                    :disabled="saving"
                    variant="primary"
                    type="submit"
                    class="mt-3"
                  >
                    <span
                      v-if="saving"
                      class="spinner-border spinner-border-sm"
                    />

                    <span v-else>Save</span>
                  </b-button>
                  <b-button
                    type="reset"
                    variant="none"
                    class="iq-bg-danger float-right"
                    ><i class="fa fa-close"></i> Reset Form</b-button
                  >
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import _ from 'lodash'
import { fleetsActions } from '@src/Utils/helper'
import { createPopper } from '@popperjs/core'
import { crewingActions } from '@src/Utils/helper'
import moment from 'moment'

export default {
  name: 'ProfileEdit',

  data() {
    return {
      saving: false,
      levelSelected: null,
      isShow: false,
      total_point: 0,
      average_point: 0,
      evaluasi: [
        { value: '1', text: '3 Bulan' },
        { value: '2', text: '6 Bulan' },
        { value: '2', text: '1 Tahun' },
        { value: '3', text: 'Khusus' },
      ],

      userDetails: null,

      optionsPoints: [],

      condite_questions: [],
      condite_points: [],
      condite_mapping: [],

      merged_condite: [],

      form: {
        name_of_condite: '', // done
        emp_crewing_id: null, // done
        date_of_condite: moment(new Date()).format('YYYY-MM-DD'),
        evaluation_period: '',
        total_point: null, // done
        average_point: null, // done
        recommendation_master: '',
        name_master: '',
        recommendation_user: '',
        name_user: '',
        recommendation_manager: '',
        name_manager: '',
        recommendation4: '',
        name4: '',
        contents: [],
      },
    }
  },
  async mounted() {
    xray.index()
    // this.fetchFleets()
    await this.getConditeModel()
    await this.getProfileUser()
  },
  methods: {
    ...crewingActions,
    async getConditeModel() {
      let res = await this.fetchConditeModel()
      if (res.status) {
        console.log(res.data, 'THIS IS CONDITE')

        let tempMap = []

        res.data.points.map((val) => {
          this.optionsPoints.push({
            value: val.value,
            text: val.value_letter,
          })
        })

        this.condite_questions = res.data.questions
        this.condite_points = res.data.points.sort((a, b) => a.value - b.value)
        this.condite_mapping = res.data.mapping

        // MERGING QUESTIONS,MAPPING, AND POINTS
        this.condite_questions.map((question) => {
          tempMap = []
          this.condite_mapping.map((map) => {
            this.condite_points.map((point) => {
              if (
                question.id_master_condite_report_list ===
                  map.question_condite_id &&
                map.point_id === point.id
              ) {
                if (map.point_id === point.id) {
                  tempMap.push({
                    description: map.point_description,
                    value: point.value,
                    point_id: map.point_id,
                    id_point: point.id,
                    condite_report_mapping_id: map.condite_report_mapping_id,
                  })
                }
                this.merged_condite.push({
                  uraian: question.uraian,
                  category: question.category,
                  id_master_condite_report_list:
                    question.id_master_condite_report_list,
                  question_condite_id: map.question_condite_id,
                  description: tempMap,
                  id_condite_report_mapping_content:
                    map.id_condite_report_mapping_content,
                  value: null,
                })
              }
            })
          })
        })

        // REMOVE THE SAME VALUE OF THE URAIAN
        let mapObj = new Map()
        this.merged_condite.forEach((v) => {
          let prevValue = mapObj.get(v.uraian)
          if (!prevValue) {
            mapObj.set(v.uraian, v)
          }
        })
        this.merged_condite = [...mapObj.values()]

        console.log(this.merged_condite, 'MERGED CONDITE')
      }
    },
    async getProfileUser() {
      if (this.$route.params.id) {
        let method = 'getCrewDetail'

        const { status, data } = await this[method](this.$route.params.id)

        console.log(this.$route.params.id, 'ID CREW')
        console.log(data, 'THIS IS PROFILE CREW')

        if (status !== 'success') {
          this.$swal(`Oops!`, `User candidate tidak ada.`, 'error')
          return
        }

        this.userDetails = data[0]

        this.form.emp_crewing_id = data[0].personal_data.emp_crewing_id
        this.form.name_of_condite = data[0].fullname
      }
    },

    showScore() {
      this.total_point = this.merged_condite.reduce((o, n) => o + n.value, 0)
      this.average_point =
        this.merged_condite.reduce((o, n) => o + n.value, 0) /
        this.merged_condite.length

      this.isShow = true
    },

    async onSave(e) {
      e.preventDefault()
      this.saving = true
      this.form.total_point = this.merged_condite.reduce(
        (o, n) => o + n.value,
        0
      )
      this.form.average_point =
        this.merged_condite.reduce((o, n) => o + n.value, 0) /
        this.merged_condite.length

      // this.form.contents
      console.log(this.merged_condite, 'MERGED CONDITE RESULT')
      this.merged_condite.map((item) => {
        item.description.map((val) => {
          if (item.value && item.value === val.value) {
            this.form.contents.push({
              condite_report_mapping_id: val.condite_report_mapping_id,
              point_value: item.value,
              remarks: '',
            })
          }
        })
      })

      let method = 'setConditeCrew'

      const { status } = await this[method](this.form)

      if (status !== 'success') {
        this.saving = false
        this.$swal(`Oops!`, `Harap isi semua form.`, 'error')
        return
      }

      this.$swal(`Berhasil!`, `Condite Crew Berhasil.`, 'success')
      this.saving = false
      this.$router.push('/crewing/list-crew')
    },
  },
}
</script>
