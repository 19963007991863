var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("OPERATIONAL")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$formOperationalAc, _vm$formOperationalAc2, _vm$formOperationalAc3, _vm$formOperationalAc4, _vm$formOperationalAc5;
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "8",
            "md": "8"
          }
        }, [_c('h5', {
          staticClass: "card-title text-primary mb-4"
        }, [_c('strong', [_vm._v("INPUT DAILY OPERATIONAL")])]), _c('hr'), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "First Fleet *",
            "label-for": "first_fleet"
          }
        }, [_c('h5', [_c('strong', [_vm._v(_vm._s(!_vm.isEdit ? _vm.fleet.name : _vm.fleetEdit.name))])])])], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-row', [_vm.showVoyageWidth ? _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Voyage with",
            "label-for": "voyage_with"
          }
        }, [!['BARGE', 'PUSHER BARGE'].includes(_vm.fleet.vehicleType.name) ? _c('b-form-radio', {
          staticClass: "custom-radio-color-checked",
          attrs: {
            "inline": "",
            "color": "default",
            "name": "color",
            "value": "UNTOWING",
            "disabled": _vm.loadingCheck || _vm.locked
          },
          model: {
            value: _vm.formOperationalActivity.voyageActivityType,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "voyageActivityType", $$v);
            },
            expression: "formOperationalActivity.voyageActivityType"
          }
        }, [_vm._v("Towing Free")]) : _vm._e(), _c('b-form-radio', {
          staticClass: "custom-radio-color-checked",
          attrs: {
            "inline": "",
            "color": "primary",
            "name": "color",
            "value": "SINGLE_TOWING",
            "disabled": _vm.loadingCheck || _vm.locked
          },
          model: {
            value: _vm.formOperationalActivity.voyageActivityType,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "voyageActivityType", $$v);
            },
            expression: "formOperationalActivity.voyageActivityType"
          }
        }, [_vm._v("Single Towing")]), _c('b-form-radio', {
          staticClass: "custom-radio-color-checked",
          attrs: {
            "inline": "",
            "color": "primary",
            "name": "color",
            "value": "DOUBLE_TOWING",
            "disabled": _vm.loadingCheck || _vm.locked
          },
          model: {
            value: _vm.formOperationalActivity.voyageActivityType,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "voyageActivityType", $$v);
            },
            expression: "formOperationalActivity.voyageActivityType"
          }
        }, [_vm._v("Double Towing")])], 1)], 1) : _vm._e(), _vm.formOperationalActivity.voyageActivityType === 'SINGLE_TOWING' || _vm.formOperationalActivity.voyageActivityType === 'DOUBLE_TOWING' ? _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-row', {
          staticClass: "mb-3"
        }, [_c('b-col', {
          attrs: {
            "md": "7"
          }
        }, [_c('label', {
          attrs: {
            "for": "second_fleet"
          }
        }, [_vm._v("Second Fleet")])]), _c('b-col', {
          staticClass: "text-right",
          attrs: {
            "md": "5"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": "",
            "disabled": _vm.loadingCheck || _vm.locked
          },
          model: {
            value: _vm.formOperationalActivity.vehicles[0].breakdown,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity.vehicles[0], "breakdown", $$v);
            },
            expression: "formOperationalActivity.vehicles[0].breakdown"
          }
        }, [_vm._v(" Breakdown ? ")])], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "name",
            "options": _vm.fleetListFiltered,
            "reduce": function reduce(fleetList) {
              return fleetList.id;
            },
            "disabled": _vm.loadingCheck || _vm.locked
          },
          on: {
            "input": function input($event) {
              return _vm.handleSelectFleet(_vm.fleetList, $event, 0);
            }
          },
          model: {
            value: _vm.formOperationalActivity.vehicles[0].vehicleId,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity.vehicles[0], "vehicleId", $$v);
            },
            expression: "formOperationalActivity.vehicles[0].vehicleId"
          }
        })], 1)], 1)], 1) : _vm._e(), _vm.formOperationalActivity.voyageActivityType === 'DOUBLE_TOWING' ? _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-row', {
          staticClass: "mb-3"
        }, [_c('b-col', {
          attrs: {
            "md": "7"
          }
        }, [_c('label', {
          attrs: {
            "for": "another_fleet"
          }
        }, [_vm._v("Another Fleet")])]), _c('b-col', {
          staticClass: "text-right",
          attrs: {
            "md": "5"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": "",
            "disabled": _vm.loadingCheck || _vm.locked
          },
          model: {
            value: _vm.formOperationalActivity.vehicles[1].breakdown,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity.vehicles[1], "breakdown", $$v);
            },
            expression: "formOperationalActivity.vehicles[1].breakdown"
          }
        }, [_vm._v(" Breakdown ? ")])], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "name",
            "options": _vm.fleetListFiltered,
            "reduce": function reduce(fleetList) {
              return fleetList.id;
            },
            "disabled": _vm.loadingCheck || _vm.locked
          },
          on: {
            "input": function input($event) {
              return _vm.handleSelectFleet(_vm.fleetList, $event, 1);
            }
          },
          model: {
            value: _vm.formOperationalActivity.vehicles[1].vehicleId,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity.vehicles[1], "vehicleId", $$v);
            },
            expression: "formOperationalActivity.vehicles[1].vehicleId"
          }
        })], 1)], 1)], 1) : _vm._e()], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('hr'), !_vm.formOperationalActivity.newTrip ? _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex flex-column mb-2"
        }, [_c('div', {
          staticClass: "d-flex align-items-start justify-content-between w-100"
        }, [_c('label', [_vm._v("Unfinished Trip")]), _c('b-form-group', {
          attrs: {
            "label-for": "crossTrip",
            "label": "Cross Trip?"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "switch": "",
            "value": true,
            "unchecked-value": false,
            "disabled": _vm.locked
          },
          model: {
            value: _vm.formOperationalActivity.crossTrip,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "crossTrip", $$v);
            },
            expression: "formOperationalActivity.crossTrip"
          }
        }, [_vm._v(" Yes / No ")])], 1)], 1), !_vm.formOperationalActivity.crossTrip ? _c('div', {
          staticClass: "d-flex overflow-auto border"
        }, [_vm.unfinishedTrip.length > 0 ? _c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "table-info align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("#")]), _c('th', {
          staticClass: "table-info text-truncate align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Start Date")]), _c('th', {
          staticClass: "table-info text-truncate align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("End Date")]), _c('th', {
          staticClass: "table-info text-truncate align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Sub Project")]), _c('th', {
          staticClass: "table-primary text-center",
          attrs: {
            "colspan": "12"
          }
        }, [_vm._v("Port of Loading")]), _c('th', {
          staticClass: "table-warning text-center",
          attrs: {
            "colspan": "12"
          }
        }, [_vm._v("Port of Discharge")])]), _c('tr', [_c('th', {
          staticClass: "table-primary text-truncate"
        }, [_vm._v("POL")]), _c('th', {
          staticClass: "table-primary text-truncate"
        }, [_vm._v("Agent POL")]), _c('th', {
          staticClass: "table-primary text-truncate"
        }, [_vm._v("Draught Survey")]), _c('th', {
          staticClass: "table-primary text-truncate"
        }, [_vm._v("Arival Time POL")]), _c('th', {
          staticClass: "table-primary text-truncate"
        }, [_vm._v("Anchor Up")]), _c('th', {
          staticClass: "table-primary text-truncate"
        }, [_vm._v("In Position / Berthing")]), _c('th', {
          staticClass: "table-primary text-truncate"
        }, [_vm._v("Commenced")]), _c('th', {
          staticClass: "table-primary text-truncate"
        }, [_vm._v("Completed")]), _c('th', {
          staticClass: "table-primary text-truncate"
        }, [_vm._v("Cast Out")]), _c('th', {
          staticClass: "table-primary text-truncate"
        }, [_vm._v("LHV")]), _c('th', {
          staticClass: "table-primary text-truncate"
        }, [_vm._v("Document on Board")]), _c('th', {
          staticClass: "table-primary text-truncate"
        }, [_vm._v("Departure to POD")]), _c('th', {
          staticClass: "table-warning text-truncate"
        }, [_vm._v("POD")]), _c('th', {
          staticClass: "table-warning text-truncate"
        }, [_vm._v("Agent POD")]), _c('th', {
          staticClass: "table-warning text-truncate"
        }, [_vm._v("Draught Survey")]), _c('th', {
          staticClass: "table-warning text-truncate"
        }, [_vm._v("Arival Time POD")]), _c('th', {
          staticClass: "table-warning text-truncate"
        }, [_vm._v("Anchor Up")]), _c('th', {
          staticClass: "table-warning text-truncate"
        }, [_vm._v("In Position / Berthing")]), _c('th', {
          staticClass: "table-warning text-truncate"
        }, [_vm._v("Commenced")]), _c('th', {
          staticClass: "table-warning text-truncate"
        }, [_vm._v("Completed")]), _c('th', {
          staticClass: "table-warning text-truncate"
        }, [_vm._v("Cast Out")]), _c('th', {
          staticClass: "table-warning text-truncate"
        }, [_vm._v("LHV")]), _c('th', {
          staticClass: "table-warning text-truncate"
        }, [_vm._v("Document on Board")]), _c('th', {
          staticClass: "table-warning text-truncate"
        }, [_vm._v("Departure to POL")])])]), _c('tbody', _vm._l(_vm.unfinishedTrip, function (state, index) {
          var _state$subProject, _state$subProject$pro, _state$subProject2, _state$portLoading$po, _state$portLoading, _state$polDraughtSurv, _state$portDischarge$, _state$portDischarge, _state$podDraughtSurv;
          return _c('tr', {
            key: index
          }, [_c('td', [_c('b-form-checkbox', {
            attrs: {
              "disabled": _vm.locked
            },
            on: {
              "change": function change($event) {
                return _vm.onCheckedUnfinishedTrip(index);
              }
            },
            model: {
              value: state.checked,
              callback: function callback($$v) {
                _vm.$set(state, "checked", $$v);
              },
              expression: "state.checked"
            }
          })], 1), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.startDate ? _vm.moment(state === null || state === void 0 ? void 0 : state.startDate, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.endDate ? _vm.moment(state === null || state === void 0 ? void 0 : state.endDate, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state === null || state === void 0 ? void 0 : (_state$subProject = state.subProject) === null || _state$subProject === void 0 ? void 0 : (_state$subProject$pro = _state$subProject.project) === null || _state$subProject$pro === void 0 ? void 0 : _state$subProject$pro.projectName) + " - " + _vm._s(state === null || state === void 0 ? void 0 : (_state$subProject2 = state.subProject) === null || _state$subProject2 === void 0 ? void 0 : _state$subProject2.name) + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s((_state$portLoading$po = state === null || state === void 0 ? void 0 : (_state$portLoading = state.portLoading) === null || _state$portLoading === void 0 ? void 0 : _state$portLoading.portName) !== null && _state$portLoading$po !== void 0 ? _state$portLoading$po : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.agentPol))]), _c('td', {
            staticClass: "text-truncate text-center"
          }, [_vm._v(_vm._s((_state$polDraughtSurv = state === null || state === void 0 ? void 0 : state.polDraughtSurvey) !== null && _state$polDraughtSurv !== void 0 ? _state$polDraughtSurv : 0))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.polArrivalTime ? _vm.moment(state === null || state === void 0 ? void 0 : state.polArrivalTime, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.polAnchorUp ? _vm.moment(state === null || state === void 0 ? void 0 : state.polAnchorUp, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.polBerthing ? _vm.moment(state === null || state === void 0 ? void 0 : state.polBerthing, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.polCommenced ? _vm.moment(state === null || state === void 0 ? void 0 : state.polCommenced, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.polCompleted ? _vm.moment(state === null || state === void 0 ? void 0 : state.polCompleted, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.polCastOut ? _vm.moment(state === null || state === void 0 ? void 0 : state.polCastOut, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.polLhv ? _vm.moment(state === null || state === void 0 ? void 0 : state.polLhv, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.polDocOnBoard ? _vm.moment(state === null || state === void 0 ? void 0 : state.polDocOnBoard, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.polDeparture ? _vm.moment(state === null || state === void 0 ? void 0 : state.polDeparture, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s((_state$portDischarge$ = state === null || state === void 0 ? void 0 : (_state$portDischarge = state.portDischarge) === null || _state$portDischarge === void 0 ? void 0 : _state$portDischarge.portName) !== null && _state$portDischarge$ !== void 0 ? _state$portDischarge$ : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.agentPod))]), _c('td', {
            staticClass: "text-truncate text-center"
          }, [_vm._v(_vm._s((_state$podDraughtSurv = state === null || state === void 0 ? void 0 : state.podDraughtSurvey) !== null && _state$podDraughtSurv !== void 0 ? _state$podDraughtSurv : 0))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.podArrivalTime ? _vm.moment(state === null || state === void 0 ? void 0 : state.podArrivalTime, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.podAnchorUp ? _vm.moment(state === null || state === void 0 ? void 0 : state.podAnchorUp, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.podBerthing ? _vm.moment(state === null || state === void 0 ? void 0 : state.podBerthing, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.podCommenced ? _vm.moment(state === null || state === void 0 ? void 0 : state.podCommenced, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.podCompleted ? _vm.moment(state === null || state === void 0 ? void 0 : state.podCompleted, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.podCastOut ? _vm.moment(state === null || state === void 0 ? void 0 : state.podCastOut, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.podLhv ? _vm.moment(state === null || state === void 0 ? void 0 : state.podLhv, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.podDocOnBoard ? _vm.moment(state === null || state === void 0 ? void 0 : state.podDocOnBoard, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.podDeparture ? _vm.moment(state === null || state === void 0 ? void 0 : state.podDeparture, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")])]);
        }), 0)]) : _vm._e()]) : _c('strong', {
          staticClass: "text-center text-muted"
        }, [_vm._v("No Data found.")])])]), _vm.formOperationalActivity.crossTrip ? _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Fleet",
            "label-for": "fleet"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.optionsFleets,
            "reduce": function reduce(optionsFleets) {
              return optionsFleets.value;
            },
            "placeholder": "Select..."
          },
          on: {
            "input": _vm.onChangeFleet
          },
          model: {
            value: _vm.formOperationalActivity.fleetId,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "fleetId", $$v);
            },
            expression: "formOperationalActivity.fleetId"
          }
        })], 1)], 1) : _vm._e(), _vm.formOperationalActivity.crossTrip ? _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex flex-column mb-2"
        }, [_c('label', [_vm._v("Unfinished Trip")]), !_vm.formOperationalActivity.fleetId ? _c('strong', {
          staticClass: "text-muted"
        }, [_vm._v("Select Vehicle.")]) : _vm._e(), _vm.crossTripList.length > 0 ? _c('div', {
          staticClass: "d-flex overflow-auto border"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "table-info align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("#")]), _c('th', {
          staticClass: "table-info text-truncate align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Start Date")]), _c('th', {
          staticClass: "table-info text-truncate align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("End Date")]), _c('th', {
          staticClass: "table-info text-truncate align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Sub Project")]), _c('th', {
          staticClass: "table-primary text-center",
          attrs: {
            "colspan": "12"
          }
        }, [_vm._v("Port of Loading")]), _c('th', {
          staticClass: "table-warning text-center",
          attrs: {
            "colspan": "12"
          }
        }, [_vm._v("Port of Discharge")])]), _c('tr', [_c('th', {
          staticClass: "table-primary text-truncate"
        }, [_vm._v("POL")]), _c('th', {
          staticClass: "table-primary text-truncate"
        }, [_vm._v("Agent POL")]), _c('th', {
          staticClass: "table-primary text-truncate"
        }, [_vm._v("Draught Survey")]), _c('th', {
          staticClass: "table-primary text-truncate"
        }, [_vm._v("Arival Time POL")]), _c('th', {
          staticClass: "table-primary text-truncate"
        }, [_vm._v("Anchor Up")]), _c('th', {
          staticClass: "table-primary text-truncate"
        }, [_vm._v("In Position / Berthing")]), _c('th', {
          staticClass: "table-primary text-truncate"
        }, [_vm._v("Commenced")]), _c('th', {
          staticClass: "table-primary text-truncate"
        }, [_vm._v("Completed")]), _c('th', {
          staticClass: "table-primary text-truncate"
        }, [_vm._v("Cast Out")]), _c('th', {
          staticClass: "table-primary text-truncate"
        }, [_vm._v("LHV")]), _c('th', {
          staticClass: "table-primary text-truncate"
        }, [_vm._v("Document on Board")]), _c('th', {
          staticClass: "table-primary text-truncate"
        }, [_vm._v("Departure to POD")]), _c('th', {
          staticClass: "table-warning text-truncate"
        }, [_vm._v("POD")]), _c('th', {
          staticClass: "table-warning text-truncate"
        }, [_vm._v("Agent POD")]), _c('th', {
          staticClass: "table-warning text-truncate"
        }, [_vm._v("Draught Survey")]), _c('th', {
          staticClass: "table-warning text-truncate"
        }, [_vm._v("Arival Time POD")]), _c('th', {
          staticClass: "table-warning text-truncate"
        }, [_vm._v("Anchor Up")]), _c('th', {
          staticClass: "table-warning text-truncate"
        }, [_vm._v("In Position / Berthing")]), _c('th', {
          staticClass: "table-warning text-truncate"
        }, [_vm._v("Commenced")]), _c('th', {
          staticClass: "table-warning text-truncate"
        }, [_vm._v("Completed")]), _c('th', {
          staticClass: "table-warning text-truncate"
        }, [_vm._v("Cast Out")]), _c('th', {
          staticClass: "table-warning text-truncate"
        }, [_vm._v("LHV")]), _c('th', {
          staticClass: "table-warning text-truncate"
        }, [_vm._v("Document on Board")]), _c('th', {
          staticClass: "table-warning text-truncate"
        }, [_vm._v("Departure to POL")])])]), _c('tbody', _vm._l(_vm.crossTripList, function (state, index) {
          var _state$subProject3, _state$subProject3$pr, _state$subProject4, _state$portLoading$po2, _state$portLoading2, _state$polDraughtSurv2, _state$portDischarge$2, _state$portDischarge2, _state$podDraughtSurv2;
          return _c('tr', {
            key: index
          }, [_c('td', [_c('b-form-checkbox', {
            attrs: {
              "disabled": _vm.locked
            },
            on: {
              "change": function change($event) {
                return _vm.onCheckedCrossTrip(index);
              }
            },
            model: {
              value: state.checked,
              callback: function callback($$v) {
                _vm.$set(state, "checked", $$v);
              },
              expression: "state.checked"
            }
          })], 1), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.startDate ? _vm.moment(state === null || state === void 0 ? void 0 : state.startDate, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.endDate ? _vm.moment(state === null || state === void 0 ? void 0 : state.endDate, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state === null || state === void 0 ? void 0 : (_state$subProject3 = state.subProject) === null || _state$subProject3 === void 0 ? void 0 : (_state$subProject3$pr = _state$subProject3.project) === null || _state$subProject3$pr === void 0 ? void 0 : _state$subProject3$pr.projectName) + " - " + _vm._s(state === null || state === void 0 ? void 0 : (_state$subProject4 = state.subProject) === null || _state$subProject4 === void 0 ? void 0 : _state$subProject4.name) + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s((_state$portLoading$po2 = state === null || state === void 0 ? void 0 : (_state$portLoading2 = state.portLoading) === null || _state$portLoading2 === void 0 ? void 0 : _state$portLoading2.portName) !== null && _state$portLoading$po2 !== void 0 ? _state$portLoading$po2 : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.agentPol))]), _c('td', {
            staticClass: "text-truncate text-center"
          }, [_vm._v(_vm._s((_state$polDraughtSurv2 = state === null || state === void 0 ? void 0 : state.polDraughtSurvey) !== null && _state$polDraughtSurv2 !== void 0 ? _state$polDraughtSurv2 : 0))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.polArrivalTime ? _vm.moment(state === null || state === void 0 ? void 0 : state.polArrivalTime, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.polAnchorUp ? _vm.moment(state === null || state === void 0 ? void 0 : state.polAnchorUp, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.polBerthing ? _vm.moment(state === null || state === void 0 ? void 0 : state.polBerthing, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.polCommenced ? _vm.moment(state === null || state === void 0 ? void 0 : state.polCommenced, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.polCompleted ? _vm.moment(state === null || state === void 0 ? void 0 : state.polCompleted, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.polCastOut ? _vm.moment(state === null || state === void 0 ? void 0 : state.polCastOut, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.polLhv ? _vm.moment(state === null || state === void 0 ? void 0 : state.polLhv, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.polDocOnBoard ? _vm.moment(state === null || state === void 0 ? void 0 : state.polDocOnBoard, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.polDeparture ? _vm.moment(state === null || state === void 0 ? void 0 : state.polDeparture, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s((_state$portDischarge$2 = state === null || state === void 0 ? void 0 : (_state$portDischarge2 = state.portDischarge) === null || _state$portDischarge2 === void 0 ? void 0 : _state$portDischarge2.portName) !== null && _state$portDischarge$2 !== void 0 ? _state$portDischarge$2 : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.agentPod))]), _c('td', {
            staticClass: "text-truncate text-center"
          }, [_vm._v(_vm._s((_state$podDraughtSurv2 = state === null || state === void 0 ? void 0 : state.podDraughtSurvey) !== null && _state$podDraughtSurv2 !== void 0 ? _state$podDraughtSurv2 : 0))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.podArrivalTime ? _vm.moment(state === null || state === void 0 ? void 0 : state.podArrivalTime, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.podAnchorUp ? _vm.moment(state === null || state === void 0 ? void 0 : state.podAnchorUp, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.podBerthing ? _vm.moment(state === null || state === void 0 ? void 0 : state.podBerthing, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.podCommenced ? _vm.moment(state === null || state === void 0 ? void 0 : state.podCommenced, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.podCompleted ? _vm.moment(state === null || state === void 0 ? void 0 : state.podCompleted, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.podCastOut ? _vm.moment(state === null || state === void 0 ? void 0 : state.podCastOut, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.podLhv ? _vm.moment(state === null || state === void 0 ? void 0 : state.podLhv, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.podDocOnBoard ? _vm.moment(state === null || state === void 0 ? void 0 : state.podDocOnBoard, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(" " + _vm._s(state !== null && state !== void 0 && state.podDeparture ? _vm.moment(state === null || state === void 0 ? void 0 : state.podDeparture, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")])]);
        }), 0)])]) : _vm._e(), _vm.crossTripList.length <= 0 && _vm.formOperationalActivity.fleetId ? _c('strong', {
          staticClass: "text-center text-muted"
        }, [_vm._v("No Data found.")]) : _vm._e()])]) : _vm._e()], 1) : _vm._e(), _c('b-form-group', {
          attrs: {
            "label-for": "new_trip",
            "label": "New Trip"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "switch": "",
            "value": true,
            "unchecked-value": false,
            "disabled": _vm.locked
          },
          model: {
            value: _vm.formOperationalActivity.newTrip,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "newTrip", $$v);
            },
            expression: "formOperationalActivity.newTrip"
          }
        }, [_vm._v(" Yes / No ")])], 1), _c('hr')], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Start Date *",
            "label-for": "start_date"
          }
        }, [_c('date-picker', {
          class: {
            'border-danger': _vm.$v.formOperationalActivity.startDate.$error
          },
          attrs: {
            "type": "datetime",
            "placeholder": "Select date and time",
            "disabled": _vm.loadingCheck || _vm.locked
          },
          model: {
            value: _vm.formOperationalActivity.startDate,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "startDate", $$v);
            },
            expression: "formOperationalActivity.startDate"
          }
        }), _c('p', [_vm.$v.formOperationalActivity.startDate.$error && !_vm.$v.formOperationalActivity.startDate.required ? _c('small', {
          staticClass: "text-danger m-t-5"
        }, [_vm._v("Start Date is Required")]) : _vm._e()])], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "End Date *",
            "label-for": "end_date"
          }
        }, [_c('date-picker', {
          class: {
            'border-danger': _vm.$v.formOperationalActivity.startDate.$error
          },
          attrs: {
            "type": "datetime",
            "placeholder": "Select date and time",
            "disabled": _vm.loadingCheck || _vm.locked
          },
          model: {
            value: _vm.formOperationalActivity.endDate,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "endDate", $$v);
            },
            expression: "formOperationalActivity.endDate"
          }
        }), _c('p', [_vm.$v.formOperationalActivity.endDate.$error && !_vm.$v.formOperationalActivity.endDate.required ? _c('small', {
          staticClass: "text-danger m-t-5"
        }, [_vm._v("End Date is Required")]) : _vm._e()])], 1)], 1), !_vm.isEdit ? _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Process",
            "label-for": "process"
          }
        }, [!_vm.loadingCheck ? _c('b-button', {
          staticClass: "mt-3",
          attrs: {
            "block": "",
            "variant": "success",
            "disabled": _vm.locked
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.checkVoyage.apply(null, arguments);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-map-pin"
        }), _vm._v("Process")]) : _c('b-button', {
          staticClass: "mt-3",
          attrs: {
            "block": "",
            "variant": "light"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.edit.apply(null, arguments);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-edit"
        }), _vm._v("Edit")]), _vm.nextStep || _vm.locked ? _c('b-button', {
          staticClass: "mt-3",
          attrs: {
            "block": "",
            "variant": "danger",
            "type": "button"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.resetForm.apply(null, arguments);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-ban"
        }), _vm._v("Reset Form")]) : _vm._e()], 1)], 1) : _vm._e()], 1), _c('hr'), _vm.nextStep ? [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('fieldset', {
          staticClass: "form-group border p-3 iq-bg-info"
        }, [_c('legend', {
          staticClass: "w-auto px-2 text-primary h6"
        }, [_c('strong', [_vm._v("PROJECT RECORD")])]), _c('b-form', [_c('b-form-group', {
          attrs: {
            "label": "Project *",
            "label-for": "project"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "options": _vm.projectOptions,
            "group-values": "sub",
            "group-label": "project",
            "placeholder": "Select one",
            "track-by": "name",
            "label": "name"
          },
          model: {
            value: _vm.formOperationalActivity.subProjectId,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "subProjectId", $$v);
            },
            expression: "formOperationalActivity.subProjectId"
          }
        })], 1)], 1)], 1)]), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('fieldset', {
          staticClass: "form-group border p-3 iq-bg-info"
        }, [_c('legend', {
          staticClass: "w-auto px-2 text-primary h6"
        }, [_c('strong', [_vm._v("POSITION")])]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Voyage For",
            "label-for": "voyage_for"
          }
        }, [_vm._l(_vm.voyagefor, function (item, index) {
          return [_c('b-form-radio', {
            key: index,
            attrs: {
              "inline": "",
              "name": item.name,
              "value": item.value,
              "disabled": item.disabled
            },
            model: {
              value: _vm.formOperationalActivity.voyageFor,
              callback: function callback($$v) {
                _vm.$set(_vm.formOperationalActivity, "voyageFor", $$v);
              },
              expression: "formOperationalActivity.voyageFor"
            }
          }, [_vm._v(_vm._s(item.label))])];
        })], 2)], 1)], 1), ['others'].includes(_vm.formOperationalActivity.voyageFor) ? _c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Voyage For Other",
            "label-for": "voyageForOtherValue"
          }
        }, [_c('b-form-input', {
          staticClass: "bg-white",
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.formOperationalActivity.voyageForOtherValue,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "voyageForOtherValue", $$v);
            },
            expression: "formOperationalActivity.voyageForOtherValue"
          }
        })], 1)], 1)], 1) : _vm._e(), _c('b-form-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Origin",
            "label-for": "origin"
          }
        }, [_c('v-select', {
          staticClass: "bg-white",
          attrs: {
            "label": "text",
            "options": _vm.positionList,
            "reduce": function reduce(positionList) {
              return positionList.text;
            },
            "placeholder": "Select..."
          },
          model: {
            value: _vm.formOperationalActivity.origin,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "origin", $$v);
            },
            expression: "formOperationalActivity.origin"
          }
        })], 1)], 1), ['Others Position'].includes(_vm.formOperationalActivity.origin) ? _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Other Origin",
            "label-for": "origin"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.formOperationalActivity.originOther,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "originOther", $$v);
            },
            expression: "formOperationalActivity.originOther"
          }
        })], 1)], 1) : _vm._e(), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Destination",
            "label-for": "destination"
          }
        }, [_c('v-select', {
          staticClass: "bg-white",
          attrs: {
            "label": "text",
            "options": _vm.positionList,
            "reduce": function reduce(positionList) {
              return positionList.text;
            },
            "placeholder": "Select..."
          },
          model: {
            value: _vm.formOperationalActivity.destination,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "destination", $$v);
            },
            expression: "formOperationalActivity.destination"
          }
        })], 1)], 1), ['Others Position'].includes(_vm.formOperationalActivity.destination) ? _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Other Destination",
            "label-for": "destination"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.formOperationalActivity.destinationOther,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "destinationOther", $$v);
            },
            expression: "formOperationalActivity.destinationOther"
          }
        })], 1)], 1) : _vm._e()], 1), _c('b-form-row', [_c('b-col', {
          class: _vm.position === 'Others Position' ? 'col-md-6' : ''
        }, [_c('b-form-group', {
          attrs: {
            "label": "Current Position *",
            "label-for": "current_position"
          }
        }, [_c('v-select', {
          staticClass: "bg-white",
          attrs: {
            "label": "text",
            "options": _vm.positionList,
            "reduce": function reduce(positionList) {
              return positionList.text;
            },
            "placeholder": "Select Position"
          },
          model: {
            value: _vm.position,
            callback: function callback($$v) {
              _vm.position = $$v;
            },
            expression: "position"
          }
        })], 1)], 1), _vm.position === 'Others Position' ? _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Other Position ",
            "label-for": "other_position"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text"
          },
          model: {
            value: _vm.positionOther,
            callback: function callback($$v) {
              _vm.positionOther = $$v;
            },
            expression: "positionOther"
          }
        })], 1)], 1) : _vm._e()], 1), _c('b-form-row')], 1), _c('b-card-text', {
          staticStyle: {
            "margin-top": "-18px"
          }
        }, [_c('small', {
          staticClass: "text-muted"
        }, [_vm._v("Sign * : "), _c('b-badge', {
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v("Mandatory (Must be Input)")])], 1)])], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('hr')]), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('fieldset', {
          staticClass: "form-group border p-3 iq-bg-info"
        }, [_c('legend', {
          staticClass: "w-auto px-2 text-primary h6"
        }, [_c('strong', [_vm._v("LOADING ACTIVITY RECORD")])]), _c('b-form-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Port of Loading",
            "label-for": "port_of_loading"
          }
        }, [_c('v-select', {
          staticClass: "bg-white",
          attrs: {
            "label": "text",
            "options": _vm.positionList,
            "reduce": function reduce(positionList) {
              return positionList.value;
            },
            "placeholder": "Select..."
          },
          model: {
            value: _vm.formOperationalActivity.portLoading,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "portLoading", $$v);
            },
            expression: "formOperationalActivity.portLoading"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate",
          attrs: {
            "label": "Agent POL",
            "label-for": "agent_pol"
          }
        }, [_c('b-form-input', {
          staticClass: "bg-white",
          attrs: {
            "type": "text",
            "id": "agent_pol",
            "placeholder": "Type Text"
          },
          model: {
            value: _vm.formOperationalActivity.agentPol,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "agentPol", $$v);
            },
            expression: "formOperationalActivity.agentPol"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Is Pol Sts Mv:",
            "label-for": "kind-fleet"
          }
        }, [_c('b-form-checkbox', {
          key: _vm.index,
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.formOperationalActivity.polStsMv,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "polStsMv", $$v);
            },
            expression: "formOperationalActivity.polStsMv"
          }
        }, [_vm._v(" No / Yes ")])], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate",
          attrs: {
            "label": "Pol Mv Name",
            "label-for": "pol_mv_name"
          }
        }, [_c('b-form-input', {
          staticClass: "bg-white",
          attrs: {
            "type": "text",
            "id": "pol_mv_name",
            "placeholder": "Type Text"
          },
          model: {
            value: _vm.formOperationalActivity.polMvName,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "polMvName", $$v);
            },
            expression: "formOperationalActivity.polMvName"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Pol grab Type",
            "label-for": "pod_grab_type"
          }
        }, [_c('v-select', {
          staticClass: "bg-white",
          attrs: {
            "label": "text",
            "options": _vm.polGrabTypeOpt,
            "reduce": function reduce(item) {
              return item.value;
            },
            "placeholder": "Select Pol Grab Type"
          },
          model: {
            value: _vm.formOperationalActivity.polGrabType,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "polGrabType", $$v);
            },
            expression: "formOperationalActivity.polGrabType"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate",
          attrs: {
            "label": "Pol Fc Name",
            "label-for": "pol_fc_name"
          }
        }, [_c('b-form-input', {
          class: {
            'bg-white': _vm.formOperationalActivity.polGrabType == 'gear_and_grab'
          },
          attrs: {
            "disabled": _vm.formOperationalActivity.polGrabType !== 'gear_and_grab',
            "type": "text",
            "id": "pol_mv_name",
            "placeholder": "Type Text"
          },
          model: {
            value: _vm.formOperationalActivity.polFcName,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "polFcName", $$v);
            },
            expression: "formOperationalActivity.polFcName"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Is Pod Sts Mv:",
            "label-for": "pod_sts_mv"
          }
        }, [_c('b-form-checkbox', {
          key: _vm.index,
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.formOperationalActivity.podStsMv,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "podStsMv", $$v);
            },
            expression: "formOperationalActivity.podStsMv"
          }
        }, [_vm._v(" No / Yes ")])], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate",
          attrs: {
            "label": "Pod Mv Name",
            "label-for": "pod_mv_name"
          }
        }, [_c('b-form-input', {
          staticClass: "bg-white",
          attrs: {
            "type": "text",
            "id": "pol_mv_name",
            "placeholder": "Type Text"
          },
          model: {
            value: _vm.formOperationalActivity.podMvName,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "podMvName", $$v);
            },
            expression: "formOperationalActivity.podMvName"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Pod grab Type",
            "label-for": "pod_grab_type"
          }
        }, [_c('v-select', {
          staticClass: "bg-white",
          attrs: {
            "label": "text",
            "options": _vm.polGrabTypeOpt,
            "reduce": function reduce(item) {
              return item.value;
            },
            "placeholder": "Select POd Grab Type"
          },
          model: {
            value: _vm.formOperationalActivity.podGrabType,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "podGrabType", $$v);
            },
            expression: "formOperationalActivity.podGrabType"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate",
          attrs: {
            "label": "Pod Fc Name",
            "label-for": "pod_fc_name"
          }
        }, [_c('b-form-input', {
          class: {
            'bg-white': _vm.formOperationalActivity.podGrabType == 'gear_and_grab'
          },
          attrs: {
            "disabled": _vm.formOperationalActivity.podGrabType !== 'gear_and_grab',
            "type": "text",
            "id": "pol_mv_name",
            "placeholder": "Type Text"
          },
          model: {
            value: _vm.formOperationalActivity.podFcName,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "podFcName", $$v);
            },
            expression: "formOperationalActivity.podFcName"
          }
        })], 1)], 1), _c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate mb-0",
          attrs: {
            "label": "NOR",
            "label-for": "nor"
          }
        }, [_c('date-picker', {
          staticClass: "custom-date-picker",
          attrs: {
            "value-type": "DD-MM-YYYY HH:mm",
            "type": "datetime",
            "placeholder": "Select date",
            "format": "DD-MM-YYYY HH:mm"
          }
        })], 1)], 1), _c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate mb-0",
          attrs: {
            "label": "NOA",
            "label-for": "noa"
          }
        }, [_c('date-picker', {
          staticClass: "custom-date-picker",
          attrs: {
            "value-type": "DD-MM-YYYY HH:mm",
            "type": "datetime",
            "placeholder": "Select date",
            "format": "DD-MM-YYYY HH:mm"
          }
        })], 1)], 1), _c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate mb-0",
          attrs: {
            "label": "Anchor Up",
            "label-for": "polAnchorUp"
          }
        }, [_c('date-picker', {
          staticClass: "custom-date-picker",
          attrs: {
            "value-type": "DD-MM-YYYY HH:mm",
            "type": "datetime",
            "placeholder": "Select date",
            "format": "DD-MM-YYYY HH:mm"
          },
          on: {
            "change": function change($event) {
              return _vm.validationTime(_vm.formOperationalActivity.polArrivalTime, _vm.formOperationalActivity.polAnchorUp, 'Anchor Up', 'Arrival Time', 1);
            }
          },
          model: {
            value: _vm.formOperationalActivity.polAnchorUp,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "polAnchorUp", $$v);
            },
            expression: "formOperationalActivity.polAnchorUp"
          }
        })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 1 ? _c('small', {
          staticClass: "text-danger",
          staticStyle: {
            "margin-top": "-30px"
          }
        }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1), _c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate mb-0",
          attrs: {
            "label": "In Position / Berthing",
            "label-for": "polBerthing"
          }
        }, [_c('date-picker', {
          staticClass: "custom-date-picker",
          attrs: {
            "value-type": "DD-MM-YYYY HH:mm",
            "type": "datetime",
            "placeholder": "Select date",
            "format": "DD-MM-YYYY HH:mm"
          },
          on: {
            "change": function change($event) {
              return _vm.validationTime(_vm.formOperationalActivity.polAnchorUp, _vm.formOperationalActivity.polBerthing, 'Berthing', 'Anchor Up', 2);
            }
          },
          model: {
            value: _vm.formOperationalActivity.polBerthing,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "polBerthing", $$v);
            },
            expression: "formOperationalActivity.polBerthing"
          }
        })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 2 ? _c('small', {
          staticClass: "text-danger",
          staticStyle: {
            "margin-top": "-30px"
          }
        }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1), _c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate mb-0",
          attrs: {
            "label": "Commenced",
            "label-for": "polCommenced"
          }
        }, [_c('date-picker', {
          staticClass: "custom-date-picker",
          attrs: {
            "value-type": "DD-MM-YYYY HH:mm",
            "type": "datetime",
            "placeholder": "Select date",
            "format": "DD-MM-YYYY HH:mm"
          },
          on: {
            "change": function change($event) {
              return _vm.validationTime(_vm.formOperationalActivity.polBerthing, _vm.formOperationalActivity.polCommenced, 'Commenced', 'Berthing', 3);
            }
          },
          model: {
            value: _vm.formOperationalActivity.polCommenced,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "polCommenced", $$v);
            },
            expression: "formOperationalActivity.polCommenced"
          }
        })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 3 ? _c('small', {
          staticClass: "text-danger",
          staticStyle: {
            "margin-top": "-30px"
          }
        }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1), _c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate mb-0",
          attrs: {
            "label": "Completed",
            "label-for": "completed"
          }
        }, [_c('date-picker', {
          staticClass: "custom-date-picker",
          attrs: {
            "value-type": "DD-MM-YYYY HH:mm",
            "type": "datetime",
            "placeholder": "Select date",
            "format": "DD-MM-YYYY HH:mm"
          },
          on: {
            "change": function change($event) {
              return _vm.validationTime(_vm.formOperationalActivity.polCommenced, _vm.formOperationalActivity.polCompleted, 'Completed', 'Commenced', 4);
            }
          },
          model: {
            value: _vm.formOperationalActivity.polCompleted,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "polCompleted", $$v);
            },
            expression: "formOperationalActivity.polCompleted"
          }
        })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 4 ? _c('small', {
          staticClass: "text-danger",
          staticStyle: {
            "margin-top": "-30px"
          }
        }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate mb-0",
          attrs: {
            "label": "Draught Survey",
            "label-for": "polDraughtSurvey"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "step": "0.0001",
            "id": "polDraughtSurvey",
            "placeholder": "Type Number"
          },
          model: {
            value: _vm.formOperationalActivity.polDraughtSurvey,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "polDraughtSurvey", $$v);
            },
            expression: "formOperationalActivity.polDraughtSurvey"
          }
        })], 1), _c('span', {
          staticClass: "text-black"
        }, [_vm._v(_vm._s(_vm.formOperationalActivity.polDraughtSurvey ? Number(_vm.formOperationalActivity.polDraughtSurvey).toLocaleString() : 0))])], 1), _c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate mb-0",
          attrs: {
            "label": "BL Date",
            "label-for": "blDate"
          }
        }, [_c('date-picker', {
          staticClass: "custom-date-picker",
          attrs: {
            "value-type": "DD-MM-YYYY HH:mm",
            "type": "datetime",
            "placeholder": "Select date",
            "format": "DD-MM-YYYY HH:mm"
          }
        })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 5 ? _c('small', {
          staticClass: "text-danger",
          staticStyle: {
            "margin-top": "-30px"
          }
        }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1), _c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate mb-0",
          attrs: {
            "label": "Cast In",
            "label-for": "polCastIn"
          }
        }, [_c('date-picker', {
          staticClass: "custom-date-picker",
          attrs: {
            "value-type": "DD-MM-YYYY HH:mm",
            "type": "datetime",
            "placeholder": "Select date",
            "format": "DD-MM-YYYY HH:mm"
          }
        })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 5 ? _c('small', {
          staticClass: "text-danger",
          staticStyle: {
            "margin-top": "-30px"
          }
        }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1), _c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate mb-0",
          attrs: {
            "label": "Cast out",
            "label-for": "polCastOut"
          }
        }, [_c('date-picker', {
          staticClass: "custom-date-picker",
          attrs: {
            "value-type": "DD-MM-YYYY HH:mm",
            "type": "datetime",
            "placeholder": "Select date",
            "format": "DD-MM-YYYY HH:mm"
          },
          on: {
            "change": function change($event) {
              return _vm.validationTime(_vm.formOperationalActivity.polCompleted, _vm.formOperationalActivity.polCastOut, 'Cast Out', 'Completed', 5);
            }
          },
          model: {
            value: _vm.formOperationalActivity.polCastOut,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "polCastOut", $$v);
            },
            expression: "formOperationalActivity.polCastOut"
          }
        })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 5 ? _c('small', {
          staticClass: "text-danger",
          staticStyle: {
            "margin-top": "-30px"
          }
        }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1), _c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate mb-0",
          attrs: {
            "label": "LHV",
            "label-for": "polLhv"
          }
        }, [_c('date-picker', {
          staticClass: "custom-date-picker",
          attrs: {
            "value-type": "DD-MM-YYYY HH:mm",
            "type": "datetime",
            "placeholder": "Select date",
            "format": "DD-MM-YYYY HH:mm"
          },
          on: {
            "change": function change($event) {
              return _vm.validationTime(_vm.formOperationalActivity.polCastOut, _vm.formOperationalActivity.polLhv, 'LHV', 'Cast Out', 6);
            }
          },
          model: {
            value: _vm.formOperationalActivity.polLhv,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "polLhv", $$v);
            },
            expression: "formOperationalActivity.polLhv"
          }
        })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 6 ? _c('small', {
          staticClass: "text-danger",
          staticStyle: {
            "margin-top": "-30px"
          }
        }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1), _vm.showDocumentOnBoard ? _c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate mb-0",
          attrs: {
            "label": "Document On Board POL",
            "label-for": "document_on_board"
          }
        }, [_c('date-picker', {
          staticClass: "custom-date-picker",
          attrs: {
            "value-type": "DD-MM-YYYY HH:mm",
            "type": "datetime",
            "placeholder": "Select date",
            "format": "DD-MM-YYYY HH:mm"
          },
          on: {
            "change": function change($event) {
              return _vm.validationTime(_vm.formOperationalActivity.polLhv, _vm.formOperationalActivity.polDocOnBoard, 'Doc on Board', 'LHV', 7);
            }
          },
          model: {
            value: _vm.formOperationalActivity.polDocOnBoard,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "polDocOnBoard", $$v);
            },
            expression: "formOperationalActivity.polDocOnBoard"
          }
        })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 7 ? _c('small', {
          staticClass: "text-danger",
          staticStyle: {
            "margin-top": "-30px"
          }
        }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1) : _vm._e(), _c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate mb-0",
          attrs: {
            "label": "Departure to POD",
            "label-for": "polDeparture"
          }
        }, [_c('date-picker', {
          staticClass: "custom-date-picker",
          attrs: {
            "value-type": "DD-MM-YYYY HH:mm",
            "type": "datetime",
            "placeholder": "Select date",
            "format": "DD-MM-YYYY HH:mm"
          },
          on: {
            "change": function change($event) {
              return _vm.validationTime(_vm.formOperationalActivity.polDocOnBoard, _vm.formOperationalActivity.polDeparture, 'Departure', 'Doc on Board', 8);
            }
          },
          model: {
            value: _vm.formOperationalActivity.polDeparture,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "polDeparture", $$v);
            },
            expression: "formOperationalActivity.polDeparture"
          }
        })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 8 ? _c('small', {
          staticClass: "text-danger",
          staticStyle: {
            "margin-top": "-30px"
          }
        }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1), _c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate mb-0",
          attrs: {
            "label": "Remarks",
            "label-for": "polCastIn"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "placeholder": "Type Text..."
          }
        })], 1)], 1)], 1)], 1)]), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('fieldset', {
          staticClass: "form-group border p-3 iq-bg-info"
        }, [_c('legend', {
          staticClass: "w-auto px-2 text-primary h6"
        }, [_c('strong', [_vm._v("DISCHARGE ACTIVITY RECORD")])]), _c('b-form-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Port of Discharge",
            "label-for": "port_of_discharge"
          }
        }, [_c('v-select', {
          staticClass: "bg-white",
          attrs: {
            "label": "text",
            "options": _vm.positionList,
            "reduce": function reduce(positionList) {
              return positionList.value;
            },
            "placeholder": "Select..."
          },
          model: {
            value: _vm.formOperationalActivity.portDischarge,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "portDischarge", $$v);
            },
            expression: "formOperationalActivity.portDischarge"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate",
          attrs: {
            "label": "Agent POD",
            "label-for": "agent_pod"
          }
        }, [_c('b-form-input', {
          staticClass: "bg-white",
          attrs: {
            "type": "text",
            "id": "agent_pod",
            "placeholder": "Type Text"
          },
          model: {
            value: _vm.formOperationalActivity.agentPod,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "agentPod", $$v);
            },
            expression: "formOperationalActivity.agentPod"
          }
        })], 1)], 1), _c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate",
          attrs: {
            "label": "Arrival Time POD",
            "label-for": "arrival_time_pod"
          }
        }, [_c('date-picker', {
          staticClass: "custom-date-picker",
          attrs: {
            "value-type": "DD-MM-YYYY HH:mm",
            "type": "datetime",
            "placeholder": "Select date",
            "format": "DD-MM-YYYY HH:mm"
          },
          on: {
            "change": function change($event) {
              return _vm.validationTime(_vm.currentValidTime, _vm.formOperationalActivity.podArrivalTime);
            }
          },
          model: {
            value: _vm.formOperationalActivity.podArrivalTime,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "podArrivalTime", $$v);
            },
            expression: "formOperationalActivity.podArrivalTime"
          }
        })], 1)], 1), _c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate mb-0",
          attrs: {
            "label": "NOR",
            "label-for": "nor"
          }
        }, [_c('date-picker', {
          staticClass: "custom-date-picker",
          attrs: {
            "value-type": "DD-MM-YYYY HH:mm",
            "type": "datetime",
            "placeholder": "Select date",
            "format": "DD-MM-YYYY HH:mm"
          }
        })], 1)], 1), _c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate mb-0",
          attrs: {
            "label": "NOA",
            "label-for": "noa"
          }
        }, [_c('date-picker', {
          staticClass: "custom-date-picker",
          attrs: {
            "value-type": "DD-MM-YYYY HH:mm",
            "type": "datetime",
            "placeholder": "Select date",
            "format": "DD-MM-YYYY HH:mm"
          }
        })], 1)], 1), _c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate mb-0",
          attrs: {
            "label": "Anchor Up",
            "label-for": "podAnchorUp"
          }
        }, [_c('date-picker', {
          staticClass: "custom-date-picker",
          attrs: {
            "value-type": "DD-MM-YYYY HH:mm",
            "type": "datetime",
            "placeholder": "Select date",
            "format": "DD-MM-YYYY HH:mm"
          },
          on: {
            "change": function change($event) {
              return _vm.validationTime(_vm.formOperationalActivity.podArrivalTime, _vm.formOperationalActivity.podAnchorUp, 'Anchor Up', 'Arrival Time', 11);
            }
          },
          model: {
            value: _vm.formOperationalActivity.podAnchorUp,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "podAnchorUp", $$v);
            },
            expression: "formOperationalActivity.podAnchorUp"
          }
        })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 11 ? _c('small', {
          staticClass: "text-danger",
          staticStyle: {
            "margin-top": "-30px"
          }
        }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1), _c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate mb-0",
          attrs: {
            "label": "In Position / Berthing",
            "label-for": "in_position_pod"
          }
        }, [_c('date-picker', {
          staticClass: "custom-date-picker",
          attrs: {
            "value-type": "DD-MM-YYYY HH:mm",
            "type": "datetime",
            "placeholder": "Select date",
            "format": "DD-MM-YYYY HH:mm"
          },
          on: {
            "change": function change($event) {
              return _vm.validationTime(_vm.formOperationalActivity.podAnchorUp, _vm.formOperationalActivity.podBerthing, 'Berthing', 'Anchor Up', 12);
            }
          },
          model: {
            value: _vm.formOperationalActivity.podBerthing,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "podBerthing", $$v);
            },
            expression: "formOperationalActivity.podBerthing"
          }
        })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 12 ? _c('small', {
          staticClass: "text-danger",
          staticStyle: {
            "margin-top": "-30px"
          }
        }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1), _c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate mb-0",
          attrs: {
            "label": "Commenced",
            "label-for": "commenced_pod"
          }
        }, [_c('date-picker', {
          staticClass: "custom-date-picker",
          attrs: {
            "value-type": "DD-MM-YYYY HH:mm",
            "type": "datetime",
            "placeholder": "Select date",
            "format": "DD-MM-YYYY HH:mm"
          },
          on: {
            "change": function change($event) {
              return _vm.validationTime(_vm.formOperationalActivity.podBerthing, _vm.formOperationalActivity.podCommenced, 'Commenced', 'Berthing', 13);
            }
          },
          model: {
            value: _vm.formOperationalActivity.podCommenced,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "podCommenced", $$v);
            },
            expression: "formOperationalActivity.podCommenced"
          }
        })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 13 ? _c('small', {
          staticClass: "text-danger",
          staticStyle: {
            "margin-top": "-30px"
          }
        }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1), _c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate mb-0",
          attrs: {
            "label": "Completed",
            "label-for": "completed_pod"
          }
        }, [_c('date-picker', {
          staticClass: "custom-date-picker",
          attrs: {
            "value-type": "DD-MM-YYYY HH:mm",
            "type": "datetime",
            "placeholder": "Select date",
            "format": "DD-MM-YYYY HH:mm"
          },
          on: {
            "change": function change($event) {
              return _vm.validationTime(_vm.formOperationalActivity.podCommenced, _vm.formOperationalActivity.podCompleted, 'Completed', 'Commenced', 14);
            }
          },
          model: {
            value: _vm.formOperationalActivity.podCompleted,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "podCompleted", $$v);
            },
            expression: "formOperationalActivity.podCompleted"
          }
        })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 14 ? _c('small', {
          staticClass: "text-danger",
          staticStyle: {
            "margin-top": "-30px"
          }
        }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate mb-0",
          attrs: {
            "label": "Draught Survey",
            "label-for": "podDraughtSurvey"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "step": "0.0001",
            "id": "podDraughtSurvey",
            "placeholder": "Type Number"
          },
          model: {
            value: _vm.formOperationalActivity.podDraughtSurvey,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "podDraughtSurvey", $$v);
            },
            expression: "formOperationalActivity.podDraughtSurvey"
          }
        })], 1), _c('span', {
          staticClass: "text-black"
        }, [_vm._v(_vm._s(_vm.formOperationalActivity.podDraughtSurvey ? Number(_vm.formOperationalActivity.podDraughtSurvey).toLocaleString() : 0))])], 1), _c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate mb-0",
          attrs: {
            "label": "BL Date",
            "label-for": "blDate"
          }
        }, [_c('date-picker', {
          staticClass: "custom-date-picker",
          attrs: {
            "value-type": "DD-MM-YYYY HH:mm",
            "type": "datetime",
            "placeholder": "Select date",
            "format": "DD-MM-YYYY HH:mm"
          }
        })], 1)], 1), _c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate mb-0",
          attrs: {
            "label": "Cast In",
            "label-for": "polCastIn"
          }
        }, [_c('date-picker', {
          staticClass: "custom-date-picker",
          attrs: {
            "value-type": "DD-MM-YYYY HH:mm",
            "type": "datetime",
            "placeholder": "Select date",
            "format": "DD-MM-YYYY HH:mm"
          }
        })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 5 ? _c('small', {
          staticClass: "text-danger",
          staticStyle: {
            "margin-top": "-30px"
          }
        }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1), _c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate mb-0",
          attrs: {
            "label": "Cast Out",
            "label-for": "podCastOut"
          }
        }, [_c('date-picker', {
          staticClass: "custom-date-picker",
          attrs: {
            "value-type": "DD-MM-YYYY HH:mm",
            "type": "datetime",
            "placeholder": "Select date",
            "format": "DD-MM-YYYY HH:mm"
          },
          on: {
            "change": function change($event) {
              return _vm.validationTime(_vm.formOperationalActivity.podCompleted, _vm.formOperationalActivity.podCastOut, 'Cast Out', 'Completed', 15);
            }
          },
          model: {
            value: _vm.formOperationalActivity.podCastOut,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "podCastOut", $$v);
            },
            expression: "formOperationalActivity.podCastOut"
          }
        })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 15 ? _c('small', {
          staticClass: "text-danger",
          staticStyle: {
            "margin-top": "-30px"
          }
        }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1), _vm.showDocumentOnBoard ? _c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate mb-0",
          attrs: {
            "label": "Document On Board POD",
            "label-for": "document_on_board"
          }
        }, [_c('date-picker', {
          staticClass: "custom-date-picker",
          attrs: {
            "value-type": "DD-MM-YYYY HH:mm",
            "type": "datetime",
            "placeholder": "Select date",
            "format": "DD-MM-YYYY HH:mm"
          },
          on: {
            "change": function change($event) {
              return _vm.validationTime(_vm.formOperationalActivity.podLhv, _vm.formOperationalActivity.docOnBoard, 'Doc on Board', 'LHV', 17);
            }
          },
          model: {
            value: _vm.formOperationalActivity.docOnBoard,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "docOnBoard", $$v);
            },
            expression: "formOperationalActivity.docOnBoard"
          }
        })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 17 ? _c('small', {
          staticClass: "text-danger",
          staticStyle: {
            "margin-top": "-30px"
          }
        }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1) : _vm._e(), _c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate mb-0",
          attrs: {
            "label": "Departure to POL",
            "label-for": "podDeparture"
          }
        }, [_c('date-picker', {
          staticClass: "custom-date-picker",
          attrs: {
            "value-type": "DD-MM-YYYY HH:mm",
            "type": "datetime",
            "placeholder": "Select date",
            "format": "DD-MM-YYYY HH:mm"
          },
          on: {
            "change": function change($event) {
              return _vm.validationTime(_vm.formOperationalActivity.docOnBoard, _vm.formOperationalActivity.podDeparture, 'Departure', 'Doc on Board', 18);
            }
          },
          model: {
            value: _vm.formOperationalActivity.podDeparture,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "podDeparture", $$v);
            },
            expression: "formOperationalActivity.podDeparture"
          }
        })], 1), _vm.errorTimeText && _vm.errorTimeIndex === 18 ? _c('small', {
          staticClass: "text-danger",
          staticStyle: {
            "margin-top": "-30px"
          }
        }, [_vm._v("*" + _vm._s(_vm.errorTimeText))]) : _vm._e()], 1), _c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          staticClass: "text-truncate mb-0",
          attrs: {
            "label": "Remarks",
            "label-for": "polCastIn"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "placeholder": "Type Text..."
          }
        })], 1)], 1)], 1)], 1)]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('hr')]), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('fieldset', {
          staticClass: "form-group border p-3 iq-bg-info"
        }, [_c('legend', {
          staticClass: "w-auto px-2 text-primary h6"
        }, [_c('strong', [_vm._v("PROCESS ACTIVITY")])]), [_c('b-form-group', {
          attrs: {
            "label": "Current Activity *",
            "label-for": "reason"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.reasonList,
            "reduce": function reduce(reasonList) {
              return reasonList.value + '|' + reasonList.text;
            },
            "placeholder": "Select Current Activity"
          },
          model: {
            value: _vm.formOperationalActivity.reasonId,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "reasonId", $$v);
            },
            expression: "formOperationalActivity.reasonId"
          }
        })], 1), _vm.formOperationalActivity.reasonId && _vm.formOperationalActivity.reasonId.split('|')[1] === 'Loading' ? _c('b-form-row', {
          staticClass: "mb-3"
        }, [_c('b-col', [_vm.formOperationalActivity.reasonId.split('|')[1] === 'Loading' ? _c('b-form-group', {
          attrs: {
            "label": "Cargo Type *",
            "label-for": "cargo_type"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.optionsCargoType,
            "reduce": function reduce(cargoOptions) {
              return cargoOptions.value;
            },
            "placeholder": "Select Type of Cargo"
          },
          model: {
            value: _vm.formOperationalActivity.cargoType,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "cargoType", $$v);
            },
            expression: "formOperationalActivity.cargoType"
          }
        })], 1) : _vm._e()], 1), _vm.formOperationalActivity.reasonId.split('|')[1] === 'Loading' ? _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Cargo Unit",
            "label-for": "polCargoUnit"
          }
        }, [_c('b-form-select', {
          attrs: {
            "id": "polCargoUnit",
            "plain": "",
            "size": "md",
            "options": _vm.optionsUnit
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select....")])];
            },
            proxy: true
          }], null, false, 2630454102),
          model: {
            value: _vm.formOperationalActivity.polCargoUnit,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "polCargoUnit", $$v);
            },
            expression: "formOperationalActivity.polCargoUnit"
          }
        })], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm.formOperationalActivity.reasonId && _vm.formOperationalActivity.reasonId.split('|')[1] === 'Loading' ? _c('b-form-row', {
          staticClass: "mb-3"
        }, [_c('b-col', [_c('b-form-group', {
          staticClass: "d-flex flex-column",
          attrs: {
            "label": "Cargo Loading *",
            "label-for": "cargo_loading"
          }
        }, [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": (_vm$formOperationalAc = _vm.formOperationalActivity.polCargoUnit) !== null && _vm$formOperationalAc !== void 0 ? _vm$formOperationalAc : 'N/A'
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "step": "0.0001"
          },
          model: {
            value: _vm.formOperationalActivity.cargoLoading,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "cargoLoading", $$v);
            },
            expression: "formOperationalActivity.cargoLoading"
          }
        })], 1), _c('span', {
          staticClass: "text-black"
        }, [_vm._v(_vm._s((_vm$formOperationalAc2 = _vm.formOperationalActivity) !== null && _vm$formOperationalAc2 !== void 0 && _vm$formOperationalAc2.cargoLoading ? Number((_vm$formOperationalAc3 = _vm.formOperationalActivity) === null || _vm$formOperationalAc3 === void 0 ? void 0 : _vm$formOperationalAc3.cargoLoading).toLocaleString() : 0) + " " + _vm._s(_vm.formOperationalActivity.polCargoUnit))]), _c('br'), _c('small', {
          staticClass: "text-warning",
          staticStyle: {
            "float": "left"
          }
        }, [_c('i', {
          staticClass: "fa fa-warning"
        }), _vm._v(" Type Number")])], 1)], 1)], 1) : _vm._e(), _vm.formOperationalActivity.reasonId && _vm.formOperationalActivity.reasonId.split('|')[1] === 'Unloading' ? _c('b-form-row', {
          staticClass: "mb-3"
        }, [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Cargo Unloading *",
            "label-for": "cargo_unloading"
          }
        }, [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "Ton"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number"
          },
          model: {
            value: _vm.formOperationalActivity.cargoUnloading,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "cargoUnloading", $$v);
            },
            expression: "formOperationalActivity.cargoUnloading"
          }
        })], 1), _c('span', {
          staticClass: "text-black"
        }, [_vm._v(_vm._s((_vm$formOperationalAc4 = _vm.formOperationalActivity) !== null && _vm$formOperationalAc4 !== void 0 && _vm$formOperationalAc4.cargoUnloading ? Number((_vm$formOperationalAc5 = _vm.formOperationalActivity) === null || _vm$formOperationalAc5 === void 0 ? void 0 : _vm$formOperationalAc5.cargoUnloading).toLocaleString() : 0) + " Ton")]), _c('br'), _c('small', {
          staticClass: "text-warning",
          staticStyle: {
            "float": "left"
          }
        }, [_c('i', {
          staticClass: "fa fa-warning"
        }), _vm._v(" Type Number")])], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Is End Trip:",
            "label-for": "kind-fleet"
          }
        }, [_c('b-form-checkbox', {
          key: _vm.index,
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.formOperationalActivity.endOfTrip,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "endOfTrip", $$v);
            },
            expression: "formOperationalActivity.endOfTrip"
          }
        }, [_vm._v(" No / Yes ")])], 1)], 1)], 1) : _vm._e(), _c('b-form-group', {
          attrs: {
            "label": "Remarks",
            "label-for": "remarks"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "description",
            "rows": "2"
          },
          model: {
            value: _vm.formOperationalActivity.remarks,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "remarks", $$v);
            },
            expression: "formOperationalActivity.remarks"
          }
        }), _c('small', {
          staticStyle: {
            "float": "left"
          }
        }, [_c('i', {
          staticClass: "fa fa-warning"
        }), _vm._v(" Max 500 Character")])], 1)]], 2)]), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('fieldset', {
          staticClass: "form-group border p-3 iq-bg-info"
        }, [_c('legend', {
          staticClass: "w-auto px-2 text-primary h6"
        }, [_c('strong', [_vm._v("DATA TRACKER")])]), _vm.showLatitude || _vm.showLongtitude ? [_c('b-alert', {
          staticClass: "text-white bg-primary",
          attrs: {
            "show": true,
            "variant": " "
          }
        }, [_c('strong', [_vm._v("GPS DMS Lat Long")])]), _vm.showLatitude ? [_c('b-alert', {
          staticClass: "text-white bg-info",
          attrs: {
            "show": true,
            "variant": " "
          }
        }, [_c('strong', [_vm._v("LATITUDE DMS")])]), _c('b-form-row', {
          staticClass: "mb-0"
        }, [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Degrees *",
            "label-for": "gps"
          }
        }, [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "°"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "placeholder": "Degrees"
          },
          model: {
            value: _vm.dmsLat.degrees,
            callback: function callback($$v) {
              _vm.$set(_vm.dmsLat, "degrees", $$v);
            },
            expression: "dmsLat.degrees"
          }
        })], 1)], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Minutes *",
            "label-for": "gps"
          }
        }, [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "'"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "placeholder": "Minutes"
          },
          model: {
            value: _vm.dmsLat.minutes,
            callback: function callback($$v) {
              _vm.$set(_vm.dmsLat, "minutes", $$v);
            },
            expression: "dmsLat.minutes"
          }
        })], 1)], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Seconds *",
            "label-for": "gps"
          }
        }, [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "\""
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "placeholder": "Seconds"
          },
          model: {
            value: _vm.dmsLat.seconds,
            callback: function callback($$v) {
              _vm.$set(_vm.dmsLat, "seconds", $$v);
            },
            expression: "dmsLat.seconds"
          }
        })], 1)], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Directions",
            "label-for": "gps"
          }
        }, [_c('b-form-select', {
          attrs: {
            "size": "md"
          },
          model: {
            value: _vm.dmsLat.directions,
            callback: function callback($$v) {
              _vm.$set(_vm.dmsLat, "directions", $$v);
            },
            expression: "dmsLat.directions"
          }
        }, [_c('b-form-select-option', [_vm._v("Please select an option")]), _c('b-form-select-option', {
          attrs: {
            "value": "N"
          }
        }, [_vm._v("N")]), _c('b-form-select-option', {
          attrs: {
            "value": "S"
          }
        }, [_vm._v("S")])], 1)], 1)], 1)], 1)] : _vm._e(), _vm.showLongtitude ? [_c('b-alert', {
          staticClass: "text-white bg-info",
          attrs: {
            "show": true,
            "variant": " "
          }
        }, [_c('strong', [_vm._v("LONGITUDE DMS")])]), _c('b-form-row', {
          staticClass: "mb-0"
        }, [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Degrees *",
            "label-for": "gps"
          }
        }, [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "°"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "placeholder": "Degrees"
          },
          model: {
            value: _vm.dmsLon.degrees,
            callback: function callback($$v) {
              _vm.$set(_vm.dmsLon, "degrees", $$v);
            },
            expression: "dmsLon.degrees"
          }
        })], 1)], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Minutes *",
            "label-for": "gps"
          }
        }, [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "'"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "placeholder": "Minutes"
          },
          model: {
            value: _vm.dmsLon.minutes,
            callback: function callback($$v) {
              _vm.$set(_vm.dmsLon, "minutes", $$v);
            },
            expression: "dmsLon.minutes"
          }
        })], 1)], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Seconds *",
            "label-for": "gps"
          }
        }, [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "\""
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "placeholder": "Seconds"
          },
          model: {
            value: _vm.dmsLon.seconds,
            callback: function callback($$v) {
              _vm.$set(_vm.dmsLon, "seconds", $$v);
            },
            expression: "dmsLon.seconds"
          }
        })], 1)], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Directions",
            "label-for": "gps"
          }
        }, [_c('b-form-select', {
          attrs: {
            "size": "md"
          },
          model: {
            value: _vm.dmsLon.directions,
            callback: function callback($$v) {
              _vm.$set(_vm.dmsLon, "directions", $$v);
            },
            expression: "dmsLon.directions"
          }
        }, [_c('b-form-select-option', [_vm._v("Please select an option")]), _c('b-form-select-option', {
          attrs: {
            "value": "E"
          }
        }, [_vm._v("E")]), _c('b-form-select-option', {
          attrs: {
            "value": "W"
          }
        }, [_vm._v("W")])], 1)], 1)], 1)], 1)] : _vm._e(), _c('b-form-row', {
          staticClass: "mb-0"
        }, [_vm.showLatitude ? _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "GPS Latitude",
            "label-for": "gps"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "placeholder": "latitude",
            "disabled": ""
          },
          model: {
            value: _vm.formOperationalActivity.lat,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "lat", $$v);
            },
            expression: "formOperationalActivity.lat"
          }
        })], 1)], 1) : _vm._e(), _vm.showLongtitude ? _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "GPS Longitude",
            "label-for": "gps"
          }
        }, [_c('b-form-input', {
          staticClass: "mt-2",
          attrs: {
            "type": "number",
            "placeholder": "longitude",
            "disabled": ""
          },
          model: {
            value: _vm.formOperationalActivity.lon,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "lon", $$v);
            },
            expression: "formOperationalActivity.lon"
          }
        })], 1)], 1) : _vm._e()], 1)] : _vm._e(), _vm.showSpeed ? _c('b-form-row', {
          staticClass: "mb-0"
        }, [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Speed *",
            "label-for": "speed"
          }
        }, [_c('b-input-group', {
          attrs: {
            "size": "md",
            "append": "Knot"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number"
          },
          model: {
            value: _vm.formOperationalActivity.speed,
            callback: function callback($$v) {
              _vm.$set(_vm.formOperationalActivity, "speed", $$v);
            },
            expression: "formOperationalActivity.speed"
          }
        })], 1), _c('small', {
          staticStyle: {
            "float": "left"
          }
        }, [_c('i', {
          staticClass: "fa fa-warning"
        }), _vm._v(" Type Number")])], 1)], 1)], 1) : _vm._e(), _vm.showPIC ? _c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "PIC / PE *",
            "label-for": "pic"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.picList,
            "reduce": function reduce(picList) {
              return picList.value;
            },
            "placeholder": "Select PIC",
            "multiple": true
          },
          model: {
            value: _vm.picSelected,
            callback: function callback($$v) {
              _vm.picSelected = $$v;
            },
            expression: "picSelected"
          }
        })], 1)], 1)], 1) : _vm._e()], 2), _c('b-card-text', {
          staticStyle: {
            "margin-top": "-18px"
          }
        }, [_c('small', {
          staticClass: "text-muted"
        }, [_vm._v("Sign * : "), _c('b-badge', {
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v("Mandatory (Must be Input)")])], 1)])], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('hr')])], 1)], 1)] : _vm._e()], 2), _c('b-col', {
          attrs: {
            "cols": "4",
            "md": "4"
          }
        }, [_c('h5', {
          staticClass: "card-title text-primary mb-4"
        }, [_c('strong', [_vm._v("LAST OPERATIONAL")])]), _c('ul', {
          staticClass: "iq-timeline m-0 p-0",
          staticStyle: {
            "height": "600px"
          }
        }, _vm._l(_vm.activityList, function (each) {
          return _c('li', {
            key: each.id
          }, [_c('b-row', {
            staticClass: "m-0"
          }, [_c('b-col', {
            attrs: {
              "md": "10"
            }
          }, [_c('div', {
            staticClass: "timeline-dots-fill"
          }), _c('h6', {
            staticClass: "float-left mb-2 text-dark"
          }, [_c('i', {
            staticClass: "ri-time-fill"
          }), _vm._v(" " + _vm._s(each.startDate + ' - ' + each.endDate))]), _c('div', {
            staticClass: "d-inline-block w-100"
          }, [_c('b-badge', {
            attrs: {
              "variant": "info"
            }
          }, [_vm._v(_vm._s(each.activityDurationFormat))]), _c('p', [_vm._v(_vm._s(each.activityName))])], 1)]), _c('b-col', {
            attrs: {
              "md": "2"
            }
          }, [_c('b-dropdown', {
            attrs: {
              "variant": "link iq-bg-primary iq-border-radius-10",
              "toggle-class": "text-decoration-none",
              "no-caret": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function fn() {
                return [_c('span', {
                  staticClass: "dropdown-toggle p-0",
                  attrs: {
                    "id": "dropdownMenuButton5",
                    "data-toggle": "dropdown"
                  }
                }, [_c('i', {
                  staticClass: "fa fa-caret-down m-0 text-primary"
                })])];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', {
            staticClass: "m-0 p-0",
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click() {
                _vm.getActivityDetail(each.id, true);
                _vm.locked = true;
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-pencil-square-o mr-2"
          }), _vm._v("Edit")]), _c('b-dropdown-item', {
            staticClass: "m-0 p-0",
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeleteActivity(each);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash mr-2"
          }), _vm._v("Delete")])], 1)], 1)], 1)], 1);
        }), 0)]), _vm.nextStep ? _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-checkbox', {
          staticClass: "mb-3",
          attrs: {
            "name": "check-button",
            "switch": ""
          },
          model: {
            value: _vm.useShelter,
            callback: function callback($$v) {
              _vm.useShelter = $$v;
            },
            expression: "useShelter"
          }
        }, [_vm._v("Use Shelter? ")]), _vm.useShelter ? _c('fieldset', {
          staticClass: "form-group border p-3 iq-bg-warning"
        }, [_c('legend', {
          staticClass: "w-auto px-2 text-danger h6"
        }, [_c('strong', [_vm._v("SHELTER")])]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex justify-content-end w-100 m-0"
        }, [_c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "primary",
            "type": "button"
          },
          on: {
            "click": _vm.addShelter
          }
        }, [_vm._v("Add Shelter "), _c('i', {
          staticClass: "fa fa-plus"
        })])], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex w-100 border mb-2"
        })]), _vm._l(_vm.formOperationalActivity.shelter, function (val, index) {
          return _c('b-row', {
            key: index
          }, [_c('b-col', {
            attrs: {
              "md": "2"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Shelter For",
              "label-for": "shelter_for"
            }
          }, [_c('b-form-select', {
            staticStyle: {
              "background-color": "#ffffff"
            },
            attrs: {
              "plain": "",
              "size": "sm"
            },
            scopedSlots: _vm._u([{
              key: "first",
              fn: function fn() {
                return [_c('b-form-select-option', {
                  attrs: {
                    "value": ''
                  }
                }, [_vm._v("Select...")]), _c('b-form-select-option', {
                  attrs: {
                    "value": 'loading'
                  }
                }, [_vm._v("Loading")]), _c('b-form-select-option', {
                  attrs: {
                    "value": 'discharge'
                  }
                }, [_vm._v("Discharge")]), _c('b-form-select-option', {
                  attrs: {
                    "value": 'others'
                  }
                }, [_vm._v("Others")])];
              },
              proxy: true
            }], null, true),
            model: {
              value: val.shelterFor,
              callback: function callback($$v) {
                _vm.$set(val, "shelterFor", $$v);
              },
              expression: "val.shelterFor"
            }
          })], 1)], 1), _c('b-col', {
            attrs: {
              "md": ['Others Position'].includes(val.shelterLocation) ? '4' : '2'
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Shelter Location",
              "label-for": "shelter_location"
            }
          }, [_c('div', {
            staticClass: "d-flex"
          }, [_c('v-select', {
            staticClass: "mr-2",
            staticStyle: {
              "background-color": "#ffffff",
              "width": "200px"
            },
            attrs: {
              "label": "text",
              "options": _vm.positionList,
              "reduce": function reduce(positionList) {
                return positionList.text;
              },
              "placeholder": "Select..."
            },
            model: {
              value: val.shelterLocation,
              callback: function callback($$v) {
                _vm.$set(val, "shelterLocation", $$v);
              },
              expression: "val.shelterLocation"
            }
          }), ['Others Position'].includes(val.shelterLocation) ? _c('b-form-input', {
            staticStyle: {
              "background-color": "#ffffff",
              "width": "200px"
            },
            attrs: {
              "type": "text",
              "placeholder": "Other shelter..."
            },
            model: {
              value: val.shelterLocationOther,
              callback: function callback($$v) {
                _vm.$set(val, "shelterLocationOther", $$v);
              },
              expression: "val.shelterLocationOther"
            }
          }) : _vm._e()], 1)])], 1), _c('b-col', {
            attrs: {
              "md": ['Others Position'].includes(val.destination) ? '4' : '2'
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Destination",
              "label-for": "destination"
            }
          }, [_c('div', {
            staticClass: "d-flex"
          }, [_c('v-select', {
            staticClass: "mr-2",
            staticStyle: {
              "background-color": "#ffffff",
              "width": "200px"
            },
            attrs: {
              "label": "text",
              "options": _vm.positionList,
              "reduce": function reduce(positionList) {
                return positionList.text;
              },
              "placeholder": "Select..."
            },
            model: {
              value: val.destination,
              callback: function callback($$v) {
                _vm.$set(val, "destination", $$v);
              },
              expression: "val.destination"
            }
          }), ['Others Position'].includes(val.destination) ? _c('b-form-input', {
            staticStyle: {
              "background-color": "#ffffff",
              "width": "200px"
            },
            attrs: {
              "type": "text",
              "placeholder": "Other destination..."
            },
            model: {
              value: val.destinationOther,
              callback: function callback($$v) {
                _vm.$set(val, "destinationOther", $$v);
              },
              expression: "val.destinationOther"
            }
          }) : _vm._e()], 1)])], 1), _c('b-col', {
            attrs: {
              "md": "2"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Arrival Shelter",
              "label-for": "arrival"
            }
          }, [_c('date-picker', {
            staticClass: "custom-date-picker",
            attrs: {
              "value-type": "DD-MM-YYYY HH:mm",
              "type": "datetime",
              "placeholder": "Select date",
              "format": "DD-MM-YYYY HH:mm"
            },
            model: {
              value: val.arrivalShelter,
              callback: function callback($$v) {
                _vm.$set(val, "arrivalShelter", $$v);
              },
              expression: "val.arrivalShelter"
            }
          })], 1)], 1), _c('b-col', {
            attrs: {
              "md": "2"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Drop Anchor Shelter",
              "label-for": "arrival"
            }
          }, [_c('date-picker', {
            staticClass: "custom-date-picker",
            attrs: {
              "value-type": "DD-MM-YYYY HH:mm",
              "type": "datetime",
              "placeholder": "Select date",
              "format": "DD-MM-YYYY HH:mm"
            },
            model: {
              value: val.dropAnchorShelter,
              callback: function callback($$v) {
                _vm.$set(val, "dropAnchorShelter", $$v);
              },
              expression: "val.dropAnchorShelter"
            }
          })], 1)], 1), _c('b-col', {
            attrs: {
              "md": "2"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Full Aways Shelter",
              "label-for": "fullaways"
            }
          }, [_c('date-picker', {
            staticClass: "custom-date-picker",
            attrs: {
              "value-type": "DD-MM-YYYY HH:mm",
              "type": "datetime",
              "placeholder": "Select date",
              "format": "DD-MM-YYYY HH:mm"
            },
            model: {
              value: val.fullAwayShelter,
              callback: function callback($$v) {
                _vm.$set(val, "fullAwayShelter", $$v);
              },
              expression: "val.fullAwayShelter"
            }
          })], 1)], 1), _c('b-col', {
            attrs: {
              "md": "4"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Remarks",
              "label-for": "arrival"
            }
          }, [_c('b-form-textarea', {
            staticStyle: {
              "background-color": "#ffffff"
            },
            attrs: {
              "name": "remarks",
              "placeholder": "Type Text"
            },
            model: {
              value: val.remarks,
              callback: function callback($$v) {
                _vm.$set(val, "remarks", $$v);
              },
              expression: "val.remarks"
            }
          })], 1)], 1), index !== 0 ? _c('b-col', {
            attrs: {
              "md": "1"
            }
          }, [_c('div', {
            staticClass: "d-flex align-items-center w-auto h-100 mt-1"
          }, [_c('i', {
            staticClass: "fa fa-trash text-danger text-xl",
            staticStyle: {
              "cursor": "pointer"
            },
            on: {
              "click": function click($event) {
                return _vm.formOperationalActivity.shelter.splice(index, 1);
              }
            }
          })])]) : _vm._e(), _c('b-col', {
            attrs: {
              "md": "12"
            }
          }, [_c('div', {
            staticClass: "d-flex w-100 border mb-2"
          })])], 1);
        })], 2)], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm.nextStep ? _c('b-col', {
          staticClass: "text-right mt-3",
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.saveActivity();
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-save"
        }), _vm._v(" Save")]), !_vm.isEdit ? _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "submit",
            "variant": "none"
          },
          on: {
            "click": _vm.resetForm
          }
        }, [_c('i', {
          staticClass: "fa fa-close"
        }), _vm._v(" Reset Form")]) : _vm._e(), _vm.isEdit ? _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "submit",
            "variant": "none"
          },
          on: {
            "click": function click($event) {
              return _vm.cancelEdit();
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-close"
        }), _vm._v(" Cancel Edit")]) : _vm._e()], 1) : _vm._e()], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }