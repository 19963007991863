<template>
  <b-container fluid>
    <div class="alert info" v-if="loading">Loading Data....</div>
    <form v-else action="" @submit.prevent="handleSavePriceFleet">
      <b-row>
        <b-col lg="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"><strong>{{ id ? 'Edit' : 'Add' }} Sum Insured - Insurance Period</strong></h4>
            </template>
            <template v-slot:headerAction>
              <b-button @click="$router.push('/mi/price-fleet')" variant="none" class="iq-bg-danger mr-3">Cancel</b-button>
              <b-button type="submit" variant="primary" :disabled="form.saving">{{ form.saving ? 'Menyimpan..' : 'Save' }}</b-button>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col md="3">
                  <b-form-group label="Insurance Periode" label-for="serial">
                    <date-picker style="width: 100%" @change="onChangeDate" v-model="dateRange" type="date" value-type="YYYY-MM-DD"
                                 range placeholder="Select date range"></date-picker>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="HM Percentage" label-for="serial">
                    <b-input-group size="sm" append="%">
                      <b-form-input type="number" required v-model="form.hm" min="1" value="75"></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="IV Percentage" label-for="serial">
                    <b-input-group size="sm" append="%">
                      <b-form-input type="number" required v-model="form.iv" min="1" value="25"></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
        <!-- Currency -->
        <b-col lg="12">
          <iq-card>
            <template v-slot:body>
              <b-row>
                <b-col md="12">
                  <div class="accordion" role="tablist">
                    <b-card no-body class="mb-5">
                      <b-card-header
                        variant="danger"
                        header-tag="header"
                        class="p-1"
                        role="tab"
                        v-b-toggle.kurs
                      >
                        <h4 style="cursor: pointer" class="p-3">
                          Pilih Data Kurs
                        </h4>
                      </b-card-header>
                      <b-collapse id="kurs" :visible="true" role="tabpanel">
                        <b-card-body>
                          <b-card-text>
                            <div class="table-responsive">
                              <table class="table mb-0 table-striped table-hover">
                                <thead class="thead-dark text-center">
                                <tr>
                                  <th scope="col">#</th>
                                  <th v-for="currencyMaster in currencies" v-if="currencyMaster.alias !== 'IDR'" :key="currencyMaster.id" scope="col">{{currencyMaster.alias}}</th>
                                  <th scope="col">Update Kurs</th>
                                  <th scope="col">Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(currency, index) in currenciesMapping" :key="index" @click="form.currency_mapping_id = currency.id">
                                  <td class="text-center">
                                    <b-form-radio v-model="form.currency_mapping_id" name="some-radios" :value="currency.id"></b-form-radio>
                                  </td>
                                  <td v-for="currencyMaster in currencies" v-if="currencyMaster.alias !== 'IDR'" :key="currencyMaster.id">
                                    <h5>IDR {{currency.childs.find(child => child.currency_id === currencyMaster.id) ? currency.childs.find(child => child.currency_id === currencyMaster.id).value : '-'}},-</h5>
                                  </td>
                                  <td>
                                    {{ currency.date }}
                                  </td>
                                  <td>
                                    <!--                                    <b-button-group>-->
                                    <!--                                      <b-button variant="info" size="sm"><i class="fa fa-edit"></i> </b-button>-->
                                    <!--                                      <b-button variant="danger" size="sm"><i class="fa fa-trash"></i></b-button>-->
                                    <!--                                    </b-button-group>-->
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-center">
                                  </td>
                                  <td v-for="child in formCurrency.contents" scope="col">
                                    <b-input-group size="md" :prepend="child.currency">
                                      <b-form-input type="number" v-model="child.value" style="max-width: 50%;"></b-form-input>
                                    </b-input-group>
                                  </td>
                                  <td>
                                    <date-picker v-model="formCurrency.date" type="date" value-type="YYYY-MM-DD" placeholder="Select date"></date-picker>
                                  </td>
                                  <td>
                                    <b-button @click="handleSaveCurrencyMapping" :disabled="formCurrency.date === '' || formCurrency.contents.filter(content => content.value === '').length > 0" variant="primary"><i class="fa fa-save"></i> Submit </b-button>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                          </b-card-text>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </div>
                </b-col>
                <b-col md="12">
                  <table class="table table-bordered table-striped shadow">
                    <thead>
                    <tr>
                      <th>Currency <small>!= IDR</small></th>
                      <th>Input Kurs to IDR</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="(currency, index) in currenciesMapping.filter(currency => currency.id === form.currency_mapping_id)">
                      <tr v-for="(type, indexType) in currency.childs" :key="indexType">
                        <td><strong>{{ type.currency.name }}</strong></td>
                        <td>
                          <h5><strong>IDR {{ type.value }}-</strong></h5>
                        </td>
                      </tr>
                    </template>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
        <!-- End Currency -->
        <b-col lg="12">
          <iq-card>
            <template v-slot:body>
              <b-row>
                <b-col md="12">
                  <template v-for="(company, index) in companies">
                    <b-card no-body class="mb-1" :key="`comp-${index}`">
                      <b-card-header header-tag="header" class="p-1" role="tab" v-b-toggle="'company' + company.id">
                        <h4 style="cursor: pointer; font-weight: bold" class="p-3">
                          {{ company.company }}
                        </h4>
                      </b-card-header>
                      <b-collapse :id="'company' + company.id" :visible="index === 0" role="tabpanel">
                        <!-- accordion="company-accordion" -->
                        <b-card-body>
                          <!-- Accordion for Category Fleet -->
                          <template v-for="(category, index2) in company.types">
                            <b-card no-body class="mb-1" :key="`mt2-${index2}`">
                              <b-card-header
                                header-tag="header"
                                class="p-1"
                                role="tab"
                                v-b-toggle="`category-${index2}-company-${index}`"
                              >
                                <h4 style="cursor: pointer" class="p-3">
                                  {{ category.name }}
                                </h4>
                              </b-card-header>
                              <b-collapse :id="`category-${index2}-company-${index}`"
                                          @shown="handleGetFleetByCompanyAndType(company.id, category.id)"
                                          role="tabpanel">
                                <b-card-body>
                                  <b-card-text>
                                    <div class="mb-4">
                                      <b-row>
                                        <b-col md="4">
                                          <b-form-group label="Option Type Last Value" label-for="serial">
                                            <b-form-radio-group
                                              v-model="category.initial_value"
                                              @change="handleGetLastValue(company.id, category.id)"
                                              :options="optionsLastValue"
                                              button-variant="outline-primary" size="md"
                                              name="radio-btn-outline" buttons></b-form-radio-group>
                                          </b-form-group>
                                        </b-col>
                                      </b-row>
                                    </div>
                                    <div class="overflow-auto tableFixHead">
                                      <table class="table mb-0 table-striped table-hover" style="display: block;height: 700px; overflow-y: scroll;">
                                        <thead class="text-center thead-dark">
                                          <tr style="z-index: 999999;">
                                            <th rowspan="2" style="vertical-align: middle; width: 5px">No</th>
                                            <th rowspan="2" style="vertical-align: middle; min-width: 300px">Fleet</th>
                                            <th rowspan="2" style="vertical-align: middle; min-width: 300px">Currency</th>
                                            <th rowspan="2" style="vertical-align: middle; min-width: 300px">Last Value</th>
                                            <th rowspan="2" style="vertical-align: middle; min-width: 300px">Reduce Value</th>
                                            <th colspan="4" style="vertical-align: middle;">Sum Insured</th>
                                            <th colspan="3" style="vertical-align: middle;">Rate %</th>
                                            <th colspan="2" style="vertical-align: middle;">Deductible</th>
                                            <th rowspan="2" style="vertical-align: middle; min-width: 300px">Insurance Period</th>
                                            <th rowspan="2" style="vertical-align: middle; width: 5px">Action</th>
                                          </tr>
                                          <tr>
                                            <th style="vertical-align: middle; min-width: 200px">TSI/Value(New)</th>
                                            <th style="vertical-align: middle; min-width: 200px">HM </th>
                                            <th style="vertical-align: middle; min-width: 200px">IV </th>
                                            <th style="vertical-align: middle; min-width: 200px">WAR</th>
                                            <th style="vertical-align: middle; min-width: 200px">Rate HM </th>
                                            <th style="vertical-align: middle; min-width: 200px">Rate IV </th>
                                            <th style="vertical-align: middle; min-width: 200px">WAR</th>
                                            <th style="vertical-align: middle; min-width: 200px">Type</th>
                                            <th style="vertical-align: middle; min-width: 200px">Percentage</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr v-for="(content, indexContent) in handleFilterContents(company.id, category.id)" :key="indexContent">
                                            <td>{{indexContent+1}}</td>
                                            <td>
                                              <v-select label="name" v-model="content.vehicle_id" :options="content.fleets" @input="onFilteredFleets(company.id, category.id, indexContent)" :reduce="(type) => type.id" placeholder="select Fleet" ></v-select>
                                            </td>
                                            <td>
                                              <v-select label="alias" :options="currencies" :reduce="(type) => type.id" v-model="content.currency_id"></v-select>
                                            </td>
                                            <td>
                                              <b-input-group size="md" prepend="Rp">
                                                <b-form-input required v-model="content.value" type="number" step=".0000000001" min="1"></b-form-input>
                                              </b-input-group>
                                              <div class="text-right"><small>Rp. {{ numberFormat(content.value) }}</small></div>
                                            </td>
                                            <td>
                                              <b-input-group size="md" append="%">
                                                <b-form-input class="text-right" required v-model="content.reduce" type="number" step=".0000000001" style="max-width: 100%;"></b-form-input>
                                              </b-input-group>
                                              <span class="text-right">{{numberFormat(content.reduce)}}%</span>
                                            </td>
                                            <td>
                                              <template v-if="content.vehicle_type_id === 3">
                                                <!--if mother vessel, assign tsi to war-->
                                                {{ numberFormat(content.war = content.tsi = content.reduce == 0 ? Number(content.value) : content.value > 0 && content.reduce > 0 ? Number(content.value) - (Number(content.value) * Number(content.reduce) / 100) : 0)}}
                                              </template>
                                              <template v-else>
                                                {{ numberFormat(content.tsi = content.reduce == 0 ? Number(content.value) : content.value > 0 && content.reduce > 0 ? Number(content.value) - (Number(content.value) * Number(content.reduce) / 100) : 0)}}
                                              </template>
                                            </td>
                                            <td>{{ numberFormat(content.hm = content.tsi > 0 && form.hm > 0 ? Number(content.tsi) * Number(form.hm) / 100 : 0) }}</td>
                                            <td>{{ numberFormat(content.iv = content.tsi > 0 && form.iv > 0 ? Number(content.tsi) * Number(form.iv) / 100 : 0) }}</td>
                                            <td>
                                              <b-form-input class="text-right" required v-model="content.war" type="number" step=".0000000001" style="max-width: 100%;"></b-form-input>
                                              <p>{{numberFormat(content.war)}}</p>
                                            </td>
                                            <td>
                                              <b-input-group size="md" append="%">
                                                <b-form-input class="text-right" required v-model="content.rate_hm" type="number" step=".0000000001"" style="max-width: 100%;"></b-form-input>
                                              </b-input-group>
                                            </td>
                                            <td>
                                              <b-input-group size="md" append="%">
                                                <b-form-input class="text-right" required v-model="content.rate_iv" type="number" step=".0000000001" style="max-width: 100%;"></b-form-input>
                                              </b-input-group>
                                            </td>
                                            <td>
                                              <b-input-group size="md" append="%">
                                                <b-form-input class="text-right" required v-model="content.rate_war" type="number" step=".0000000001" style="max-width: 100%;"></b-form-input>
                                              </b-input-group>
                                            </td>
                                            <td>
                                              <b-form-radio-group v-model="content.deductible_type">
                                                <b-form-radio class="custom-radio-color-checked" inline name="color" value="percentage">Percentage</b-form-radio>
                                                <b-form-radio class="custom-radio-color-checked" inline name="color" value="float">By Value</b-form-radio>
                                              </b-form-radio-group>
                                            </td>
                                            <td class="text-right">
                                              <b-input-group size="md" append="%" v-if="content.deductible_type === 'percentage'">
                                                <b-form-input class="text-right" type="number" step=".0000000001" v-model="content.deductible_value" style="max-width: 100%;"></b-form-input>
                                              </b-input-group>
                                              <span v-if="content.deductible_type === 'percentage'">
                                                {{ numberFormat(content.suggest_rate_hm = content.deductible_value === 0 ? Number(content.hm) : Number(content.deductible_value) > 0 ? (Number(content.deductible_value) / 100) * Number(content.hm): 0) }}
                                                <p class="d-none">{{ content.deductible_final_value = Number(content.deductible_value) > 0 ? (Number(content.deductible_value) / 100) * Number(content.hm): 0 }}</p>
                                              </span>
                                              <b-input-group size="md" prepend="value" v-if="content.deductible_type === 'float'">
                                                <b-form-input class="text-right" type="number" step=".0000000001" v-model="content.deductible_value" style="max-width: 100%;"></b-form-input>
                                              </b-input-group>
                                              <span v-if="content.deductible_type === 'float'" class="d-none">
                                                {{ numberFormat(content.suggest_rate_hm = Number(0)) }}
                                                {{ numberFormat(content.deductible_final_value = Number(content.deductible_value)) }}
                                              </span>
                                            </td>
                                            <td>
                                              <date-picker style="width: 100%;" @change="onChangeContentDate(company.id, category.id, indexContent, $event)" :value="[content.from,content.to]" type="date" value-type="YYYY-MM-DD"
                                                          range placeholder="Select date range"></date-picker>
                                            </td>
                                            <td>
                                              <b-button v-if="content.pricefleet_id" variant="danger" @click="handleDeletePriceFleet(company.id, category.id, indexContent)" size="sm"><i class="fa fa-trash"></i> </b-button>
                                              <b-button v-else variant="danger" @click="onDeleteContent(company.id, category.id, indexContent)" size="sm"><i class="fa fa-trash"></i> </b-button>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <div>
                                      <b-button variant="warning" class="mb-3 mr-1" @click="onAddContent(company.id, category.id)" block><i class="ri-add-line"></i> Add More Data</b-button>
                                    </div>
                                  </b-card-text>
                                </b-card-body>
                              </b-collapse>
                            </b-card>
                          </template>
                          <!-- END Accordion for Category Fleet -->
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </template>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { companiesActions, fleetsActions, miActions } from '@src/Utils/helper'
import moment from 'moment'
import { numberFormat } from '@src/plugins/helpers'

export default {
  name: 'PriceOfFleetForm',
  async mounted () {
    xray.index()
    this.loading = true
    this.onResetForm()
    this.onResetCurrencyForm()
    this.onChangeDate()
    await this.handleFetchCompanies()
    await this.handleFetchCurrenciesMapping()
    await this.handleFetchCurrencies()

    if (this.id) {
      await this.handleFetchFleetDetail()
    }
    this.loading = false
  },
  computed: {
    id () {
      return this.$route.query.id
    }
  },
  data () {
    return {
      loading: true,
      dateRange: [moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')],
      optionsLastValue: [
        { text: 'Free Text', value: 'free_text' },
        { text: 'By Last Value', value: 'last_value' }
      ],
      companies: [],
      currencies: [],
      currenciesMapping: [],
      form: {
        saving: false,
        'from': '',
        'to': '',
        'hm': 75,
        'iv': 25,
        'currency_mapping_id': '',
        'contents': []
      },
      formCurrency: {
        saving: false,
        'date': '',
        'contents': []
      }
    }
  },
  methods: {
    ...fleetsActions,
    ...companiesActions,
    ...miActions,
    numberFormat (number) {
      return numberFormat(number)
    },
    onResetForm () {
      this.form = {
        saving: false,
        'from': '',
        'to': '',
        'hm': 75,
        'iv': 25,
        'currency_mapping_id': '',
        'contents': []
      }
    },
    onResetCurrencyForm () {
      this.formCurrency.saving = false
      this.formCurrency.date = ''
    },
    async handleGetFleetByCompanyAndType (companyId, vehicleTypeId) {
      let params = { companyId, vehicleTypeId }

      const company = this.companies.find(company => company.id === companyId)
      if (company) {
        const vehicleType = company.types.find(type => type.id === vehicleTypeId)
        if (vehicleType) {
          if (vehicleType.fleets.length > 0) {
            return
          }

          const { data } = await this.getFleetByCompanyAndType(params)
          vehicleType.fleets = data
          const contents = this.handleFilterContents(companyId, vehicleTypeId)
          contents.forEach((content, index) => {
            content.fleets = data
          })
          contents.forEach((content, index) => {
            this.onFilteredFleets(companyId, vehicleTypeId, index)
          })
        }
      }
    },
    onFilteredFleets (companyId, vehicleTypeId, contentIndex) {
      const contents = this.handleFilterContents(companyId, vehicleTypeId)
      const vehicleId = contents[contentIndex].vehicle_id

      contents.forEach((content, index) => {
        if (index !== contentIndex) {
          content.fleets = content.fleets.filter(fleet => fleet.id !== vehicleId)
        }
      })
    },
    async handleFetchCurrenciesMapping () {
      const { data } = await this.getCurrencyMapping()
      this.currenciesMapping = data
    },
    async handleFetchCurrencies () {
      const { data } = await this.getCurrencies()
      this.currencies = data
      this.formCurrency.contents = this.currencies.filter(child => child.alias !== 'IDR').map(child => {
        return {
          currency: child.alias,
          id: child.id,
          value: ''
        }
      })
    },
    async handleFetchCompanies () {
      const { data } = await this.getFleetPriceFilter()
      this.companies = data.map(d => {
        return {
          ...d,
          types: d.types.map(type => {
            return {
              ...type,
              initial_value: 'free_text',
              fleets: []
            }
          })
        }
      })
    },
    async handleGetLastValue (companyId, vehicleTypeId) {
      const company = this.companies.find(company => company.id === companyId).types.find(type => type.id === vehicleTypeId)

      if (company && company.initial_value === 'last_value') {
        const { data } = await this.getFleetPriceLastValue({
          date: this.form.from
        })

        if (data) {
          data.childs.filter(c => c.company_id === companyId && c.vehicle_type_id === vehicleTypeId).forEach(c => {
            this.form.contents.push(c)
          })
        }
      }
    },
    async handleSaveCurrencyMapping () {
      this.formCurrency.saving = true
      const payload = { ...this.formCurrency }

      const { status } = await this.createCurrencyMapping(payload)

      if (status === 422) {
        this.$swal(
          `Oops!`,
          'Semua Field Wajib Diisi',
          'error'
        )
        return
      }

      if ([500, 404, 403].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        return
      }

      this.handleFetchCurrenciesMapping()
      this.onResetCurrencyForm()
      this.formCurrency.saving = false
    },
    async handleSavePriceFleet () {
      const payload = { ...this.form }
      let method = 'createPriceFleet'

      this.$swal.fire({
        title: 'Sedang Menyimpan..',
        text: 'Tunggu sebentar',
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        onOpen: () => {
        }
      })
      this.$swal.showLoading()

      if (this.id) {
        payload.id = Number(this.id)
        method = 'updatePriceFleet'

        for (const company of this.companies) {
          for (const type of company.types) {
            for (let contentIndex = 0; contentIndex <= this.handleFilterContents(company.id, type.id).length; contentIndex++) {
              await this.handleSavePriceFleetChild(company.id, type.id, contentIndex)
            }
          }
        }
      }

      const { status } = await this[method](payload)

      if (status === 422) {
        return this.$swal(
          `Oops!`,
          'Semua Field Wajib Diisi',
          'error'
        )
      }

      if (status === 409) {
        return this.$swal(
          `Oops!`,
          'Data Sudah Ada',
          'error'
        )
      }

      if ([500, 404, 403].includes(status)) {
        return this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
      }

      this.form.saving = true

      this.$swal(
        `Tersimpan`,
        'Data Tersimpan',
        'success'
      )

      this.$router.push('/mi/price-fleet')
    },
    async handleSavePriceFleetChild (companyId, vehicleTypeId, index) {
      const data = this.handleFilterContents(companyId, vehicleTypeId)
      if (data[index]) {
        const payload = { ...data[index] }
        payload.deductible_final_value = payload.deductible_final_value ? Number(payload.deductible_final_value) : 0
        payload.deductible_value = payload.deductible_value ? Number(payload.deductible_value) : 0
        payload.hm = payload.hm ? Number(payload.hm) : 0
        payload.iv = payload.iv ? Number(payload.iv) : 0
        payload.tsi = payload.tsi ? Number(payload.tsi) : 0
        payload.war = payload.war ? Number(payload.war) : 0
        payload.rate_hm = payload.rate_hm ? Number(payload.rate_hm) : 0
        payload.rate_iv = payload.rate_iv ? Number(payload.rate_iv) : 0
        payload.rate_war = payload.rate_war ? Number(payload.rate_war) : 0
        payload.reduce = payload.reduce ? Number(payload.reduce) : 0
        payload.suggest_rate_hm = payload.suggest_rate_hm ? Number(payload.suggest_rate_hm) : 0
        payload.value = payload.value ? Number(payload.value) : 0

        let method = 'createPriceFleetChild'
        if (payload.pricefleet_id) {
          method = 'updatePriceFleetChild'
        } else {
          payload.pricefleet_id = this.id
        }

        const { status } = await this[method](payload)

        if ([422].includes(status)) {
          return this.$swal(
            `Oops!`,
            'Semua Field Wajib Diisi',
            'error'
          )
        }

        if ([409].includes(status)) {
          return this.$swal(
            `Oops!`,
            'Data Sudah Ada',
            'error'
          )
        }

        if ([500, 404, 403].includes(status)) {
          return this.$swal(
            `Oops!`,
            'Ada Yang Salah',
            'error'
          )
        }
      }
    },
    async handleDeletePriceFleet (companyId, vehicleTypeId, index) {
      const data = this.handleFilterContents(companyId, vehicleTypeId)
      if (data[index]) {
        const payload = { ...data[index] }
        const { status } = await this.deletePriceFleetChild(payload)

        if ([422].includes(status)) {
          return this.$swal(
            `Oops!`,
            'Semua Field Wajib Diisi',
            'error'
          )
        }

        if ([409].includes(status)) {
          return this.$swal(
            `Oops!`,
            'Data Sudah Ada',
            'error'
          )
        }

        if ([500, 404, 403].includes(status)) {
          return this.$swal(
            `Oops!`,
            'Ada Yang Salah',
            'error'
          )
        }

        if (status === 200) {
          return this.$swal(
            `Terhapus`,
            'Data Berhasil Terhapus',
            'success'
          )
        }

        this.onDeleteContent(companyId, vehicleTypeId, index)
      }
    },
    async handleFetchFleetDetail () {
      const { data } = await this.getFleetPriceListDetail({
        id: this.id
      })

      const priceFleet = data.pricefleet
      const childs = data.data
      if (priceFleet && childs) {
        this.form = { ...this.form, ...priceFleet }
        childs.forEach(child => {
          child.types.forEach(type => {
            type.data.forEach(typeData => {
              this.form.contents.push({
                ...typeData,
                fleets: [],
                pricefleet_id: this.id
              })
            })
          })
        })
        this.dateRange = [this.form.from, this.form.to]
      }
    },
    handleFilterContents (companyId, vehicleTypeId) {
      return this.form.contents.filter(content => content.company_id === companyId && content.vehicle_type_id === vehicleTypeId)
    },
    onAddContent (companyId, vehicleTypeId) {
      const company = this.companies.find(company => company.id === companyId)
      if (company) {
        const vehicleType = company.types.find(type => type.id === vehicleTypeId)
        if (vehicleType) {
          const contents = this.handleFilterContents(companyId, vehicleTypeId)
          this.form.contents.push({
            fleets: vehicleType.fleets.filter(fleet => !contents.map(content => content.vehicle_id).includes(fleet.id)),
            'company_id': companyId,
            'vehicle_type_id': vehicleTypeId,
            'vehicle_id': '',
            'currency_id': '',
            'value': '',
            'reduce': 0,
            'tsi': '',
            'hm': '',
            'iv': '',
            'war': 0,
            'rate_hm': 0,
            'rate_iv': 0,
            'rate_war': 0,
            'deductible_type': 'percentage',
            'deductible_value': 0,
            'initial_value': 'free_text',
            'suggest_rate_hm': 0,
            'deductible_final_value': 0,
            'from': '',
            'to': ''
          })
        }
      }
    },
    onDeleteContent (companyId, vehicleTypeId, contentIndex) {
      const contents = this.handleFilterContents(companyId, vehicleTypeId)
      this.form.contents = contents.filter((content, index) => index !== contentIndex)
    },
    onChangeDate () {
      this.form.from = this.dateRange ? `${moment(this.dateRange[0]).format('YYYY-MM-DD')}` : null
      this.form.to = this.dateRange ? `${moment(this.dateRange[1]).format('YYYY-MM-DD')}` : null
    },
    onChangeContentDate (companyId, vehicleTypeId, index, event) {
      if (event) {
        const data = this.handleFilterContents(companyId, vehicleTypeId)
        if (data[index]) {
          data[index].from = `${moment(event[0]).format('YYYY-MM-DD')}`
          data[index].to = `${moment(event[1]).format('YYYY-MM-DD')}`
        }
      }
    }
  }
}
</script>
