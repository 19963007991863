var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "maintenance-summary-init"
  }, [_c('b-modal', {
    attrs: {
      "centered": "",
      "size": "lg",
      "title": "Filter Data",
      "ok-title": "Filter"
    },
    on: {
      "ok": function ok($event) {
        return _vm.getInitiationList();
      }
    },
    model: {
      value: _vm.modal.filter,
      callback: function callback($$v) {
        _vm.$set(_vm.modal, "filter", $$v);
      },
      expression: "modal.filter"
    }
  }, [_c('b-row', {
    staticClass: "px-3"
  }, [!_vm.queryVehicleId ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fleet/Equipment:",
      "label-for": "fleet-equipment",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('v-multiselect', {
    attrs: {
      "disabled": _vm.queryVehicleId,
      "tag-placeholder": "Category",
      "placeholder": "Search or add a category",
      "label": "name",
      "track-by": "code",
      "options": _vm.fleetEquipments,
      "multiple": true,
      "taggable": true
    },
    model: {
      value: _vm.initMaintenance.params.vehicleIds,
      callback: function callback($$v) {
        _vm.$set(_vm.initMaintenance.params, "vehicleIds", $$v);
      },
      expression: "initMaintenance.params.vehicleIds"
    }
  })], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Choose your Job Filter",
      "label-for": "fleet-equipment",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_vm._l(_vm.jobOptions, function (item, index) {
    return [_c('b-form-radio', {
      key: index,
      staticClass: "custom-radio-color-checked",
      attrs: {
        "inline": "",
        "color": item.color,
        "name": "color",
        "value": item.value,
        "disabled": item.disabled
      },
      model: {
        value: _vm.initMaintenance.selectedJob,
        callback: function callback($$v) {
          _vm.$set(_vm.initMaintenance, "selectedJob", $$v);
        },
        expression: "initMaintenance.selectedJob"
      }
    }, [_vm._v(_vm._s(item.label))])];
  })], 2)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Job Name/Equipment",
      "label-for": "equipment",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "search..."
    },
    model: {
      value: _vm.initMaintenance.params.search,
      callback: function callback($$v) {
        _vm.$set(_vm.initMaintenance.params, "search", $$v);
      },
      expression: "initMaintenance.params.search"
    }
  })], 1)], 1), _vm.initMaintenance.selectedJob === 'job_class' ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Job Class",
      "label-for": "fleet-equipment",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('v-multiselect', {
    attrs: {
      "tag-placeholder": "Category",
      "placeholder": "Search or add a job class",
      "label": "name",
      "track-by": "code",
      "options": _vm.jobClassList,
      "multiple": true,
      "taggable": true
    },
    model: {
      value: _vm.initMaintenance.params.jobClassIds,
      callback: function callback($$v) {
        _vm.$set(_vm.initMaintenance.params, "jobClassIds", $$v);
      },
      expression: "initMaintenance.params.jobClassIds"
    }
  })], 1)], 1) : _vm._e(), _vm.initMaintenance.selectedJob === 'job_type' ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_vm.initMaintenance.selectedJob === 'job_type' ? _c('b-form-group', {
    attrs: {
      "label": "Job Type",
      "label-for": "fleet-equipment",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('v-multiselect', {
    attrs: {
      "tag-placeholder": "Category",
      "placeholder": "Search or add a job type",
      "label": "name",
      "track-by": "code",
      "options": _vm.jobTypeList,
      "multiple": true,
      "taggable": true
    },
    model: {
      value: _vm.initMaintenance.params.jobTypeIds,
      callback: function callback($$v) {
        _vm.$set(_vm.initMaintenance.params, "jobTypeIds", $$v);
      },
      expression: "initMaintenance.params.jobTypeIds"
    }
  })], 1) : _vm._e()], 1) : _vm._e()], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Maintenance Initiation " + _vm._s(_vm.queryVehicleId && _vm.initMaintenance.params.vehicleIds[0] ? ' - ' + _vm.initMaintenance.params.vehicleIds[0].name : ''))])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-content-between mb-3"
        }, [_c('b-button', {
          staticClass: "d-flex align-items-center",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              _vm.modal.filter = true;
            }
          }
        }, [_c('i', {
          staticClass: "fas fa-sliders"
        }), _c('p', {
          staticClass: "text-truncate m-0"
        }, [_vm._v(" Filter Data ")])]), _c('div', [_c('b-modal', {
          attrs: {
            "id": "import",
            "size": "large",
            "title": "Import Data Maintenance Initiation Using Template",
            "header-bg-variant": "light",
            "header-text-variant": "dark",
            "footer-bg-variant": "light",
            "footer-text-variant": "dark"
          }
        }, [_c('p', {
          staticClass: "my-2"
        }, [_vm._v(" Download Template for Importing first "), _c('b-button', {
          attrs: {
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-excel-o"
        }), _vm._v(" Template")])], 1), _c('p', {
          staticClass: "my-2"
        }, [_c('b-form-group', {
          attrs: {
            "label": "Make Sure your data using template"
          }
        }, [_c('b-form-file', {
          attrs: {
            "id": "fileSupport",
            "placeholder": "Choose a file",
            "accept": "application/pdf"
          }
        })], 1)], 1)]), _c('b-button', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.clone_initiation",
            modifiers: {
              "clone_initiation": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-clone"
        }), _vm._v(" Duplicate Maintenance Initiation")]), _vm.canAdd ? _c('b-button', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.add-init",
            modifiers: {
              "add-init": true
            }
          }],
          attrs: {
            "variant": "success"
          },
          on: {
            "click": function click($event) {
              return _vm.setFormInitMaintenance();
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v("Maintenance Initiation")]) : _vm._e(), _c('b-modal', {
          attrs: {
            "id": "add-init",
            "size": "xl",
            "title": "Add Initiation - Choose Fleet/Equipment First",
            "header-bg-variant": "light",
            "header-text-variant": "dark",
            "footer-bg-variant": "light",
            "scrollable": "",
            "footer-text-variant": "dark"
          },
          scopedSlots: _vm._u([{
            key: "modal-footer",
            fn: function fn(_ref) {
              var cancel = _ref.cancel;
              return [_c('b-row', [_c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-button', {
                staticClass: "iq-bg-danger text-right",
                attrs: {
                  "variant": "none"
                },
                on: {
                  "click": function click($event) {
                    return cancel();
                  }
                }
              }, [_vm._v("Cancel")]), _c('b-button', {
                staticClass: "ml-2 text-right",
                attrs: {
                  "disabled": _vm.checkedFleets && _vm.checkedFleets.length === 0,
                  "type": "submit",
                  "variant": "primary"
                },
                on: {
                  "click": function click($event) {
                    return _vm.nextStep();
                  }
                }
              }, [_vm._v("Next "), _c('i', {
                staticClass: "fa fa-arrow-right"
              })])], 1)], 1)];
            }
          }]),
          model: {
            value: _vm.showInitModal,
            callback: function callback($$v) {
              _vm.showInitModal = $$v;
            },
            expression: "showInitModal"
          }
        }, [_c('b-row', {
          staticClass: "mb-4"
        }, [_c('b-col', {
          staticClass: "text-right",
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Search fleets..."
          },
          on: {
            "input": _vm.fetchFleets
          },
          model: {
            value: _vm.fleetParams.search,
            callback: function callback($$v) {
              _vm.$set(_vm.fleetParams, "search", $$v);
            },
            expression: "fleetParams.search"
          }
        })], 1), _c('b-col', {
          staticClass: "text-right",
          attrs: {
            "md": "8"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.selectAllFleets,
            callback: function callback($$v) {
              _vm.selectAllFleets = $$v;
            },
            expression: "selectAllFleets"
          }
        }, [_vm._v(" Select All ")])], 1)], 1), _vm.fleetsLoading ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.fleets.length > 0 ? _c('b-row', _vm._l(_vm.fleets, function (fleet, i) {
          return _c('b-col', {
            key: "fleet-".concat(i),
            staticClass: "text-center py-5 border-top",
            attrs: {
              "md": "3"
            }
          }, [_c('div', {
            staticClass: "custom-control custom-checkbox image-checkbox"
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: _vm.checkedFleets,
              expression: "checkedFleets"
            }],
            staticClass: "custom-control-input",
            attrs: {
              "id": "ck-fl-".concat(i),
              "type": "checkbox",
              "disabled": _vm.queryVehicleId
            },
            domProps: {
              "value": fleet,
              "checked": Array.isArray(_vm.checkedFleets) ? _vm._i(_vm.checkedFleets, fleet) > -1 : _vm.checkedFleets
            },
            on: {
              "change": function change($event) {
                var $$a = _vm.checkedFleets,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;
                if (Array.isArray($$a)) {
                  var $$v = fleet,
                    $$i = _vm._i($$a, $$v);
                  if ($$el.checked) {
                    $$i < 0 && (_vm.checkedFleets = $$a.concat([$$v]));
                  } else {
                    $$i > -1 && (_vm.checkedFleets = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                  }
                } else {
                  _vm.checkedFleets = $$c;
                }
              }
            }
          }), _c('label', {
            staticClass: "custom-control-label",
            attrs: {
              "for": "ck-fl-".concat(i)
            }
          }, [_c('img', {
            staticClass: "img-fluid",
            attrs: {
              "src": fleet.vehicleType.icon.url,
              "alt": "#"
            }
          })]), _c('small', [_c('strong', [_vm._v(_vm._s(fleet.name))])])])]);
        }), 1) : _c('div', {
          staticClass: "text-center my-5"
        }, [_c('p', {
          staticClass: "mb-0 text-muted"
        }, [_vm._v("No fleets data found.")])])]], 2), _c('b-modal', {
          attrs: {
            "scrollable": "",
            "id": "add-init-next",
            "size": "huge",
            "title": "Init your maintenance Job",
            "header-bg-variant": "light",
            "header-text-variant": "dark",
            "footer-bg-variant": "light",
            "footer-text-variant": "dark"
          },
          scopedSlots: _vm._u([{
            key: "modal-footer",
            fn: function fn() {
              return [_c('b-row', [_c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "12"
                }
              }, [!_vm.maintenanceDetailData ? _c('b-button', {
                staticClass: "iq-bg-danger text-right",
                attrs: {
                  "variant": "warning"
                },
                on: {
                  "click": function click($event) {
                    _vm.showInitModalNext = !_vm.showInitModalNext;
                    _vm.showInitModal = !_vm.showInitModal;
                  }
                }
              }, [_c('i', {
                staticClass: "fa fa-arrow-left"
              }), _vm._v(" Back ")]) : _vm._e(), _c('b-button', {
                staticClass: "ml-2 text-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                },
                on: {
                  "click": function click($event) {
                    return _vm.submitFormInit();
                  }
                }
              }, [_vm._v("Submit "), _c('i', {
                staticClass: "fa fa-save"
              })])], 1)], 1)];
            },
            proxy: true
          }]),
          model: {
            value: _vm.showInitModalNext,
            callback: function callback($$v) {
              _vm.showInitModalNext = $$v;
            },
            expression: "showInitModalNext"
          }
        }, [_c('b-row', [_c('b-col', {
          staticClass: "text-center",
          attrs: {
            "cols": "12"
          }
        }, [_vm.checkedFleets && _vm.checkedFleets.length > 0 ? _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table mb-0 table-striped table-hover table-dark"
        }, [_c('tbody', _vm._l(_vm.checkedFleets, function (each) {
          return _c('tr', {
            key: each.id
          }, [_c('td', {
            attrs: {
              "width": "15%"
            }
          }, [_vm._v(_vm._s(each.name))]), _c('td', {
            attrs: {
              "width": "5%"
            }
          }, [_vm._v(":")]), _c('td', [each.powers && each.powers.length > 0 ? _c('ul', {
            staticClass: "text-left"
          }, _vm._l(each.powers, function (power) {
            return _c('li', {
              key: power.id
            }, [_vm._v(_vm._s(power.powerType ? power.powerType.name : '-') + " - " + _vm._s(power.powerSeries ? power.powerSeries.name : '-') + " " + _vm._s(power.powerSeries ? power.powerSeries.model : '-') + " "), _c('span', {
              staticClass: "pull-right text-warning"
            }, [_vm._v("Actual RH: "), _c('strong', [_vm._v(_vm._s(power.actualRunningHours) + " RH")])])]);
          }), 0) : _c('b-alert', {
            attrs: {
              "show": "",
              "variant": "warning"
            }
          }, [_vm._v(" no powers data")])], 1)]);
        }), 0)])]) : _vm._e()]), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Setup Job Component / Equipment:")]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('v-jstree', {
          attrs: {
            "data": _vm.treeInitMaintenance,
            "show-checkbox": "",
            "allow-batch": "",
            "whole-row": ""
          },
          on: {
            "item-click": _vm.itemClickInitComponent
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_) {
              return [_c('div', {
                staticStyle: {
                  "display": "inherit",
                  "width": "200px"
                }
              }, [!_.model.loading ? _c('i', {
                class: _.vm.themeIconClasses,
                attrs: {
                  "role": "presentation"
                }
              }) : _vm._e(), _vm._v(" " + _vm._s(_.model.text) + " ")])];
            }
          }])
        })], 1), _c('b-col', {
          attrs: {
            "md": "8"
          }
        }, [_vm.checkedFleets && _vm.checkedFleets.length > 0 && _vm.componentIdSelected ? _c('b-row', _vm._l(_vm.checkedFleets, function (each, i) {
          return _c('fieldset', {
            key: i,
            staticClass: "form-group border p-3"
          }, [_c('legend', {
            staticClass: "w-auto px-2"
          }, [_c('h5', [_c('b-badge', [_vm._v(_vm._s(each.name))])], 1)]), each.componentSetup && each.componentSetup.length === 0 ? _c('b-row', [_c('b-col', {
            attrs: {
              "cols": "12"
            }
          }, [_c('b-alert', {
            staticClass: "text-white bg-warning",
            attrs: {
              "show": true,
              "variant": " "
            }
          }, [_c('div', {
            staticClass: "iq-alert-icon"
          }, [_c('i', {
            staticClass: "ri-alert-line"
          })]), _c('div', {
            staticClass: "iq-alert-text"
          }, [_vm._v("For Information Equipment Not Setup for Fleet "), _c('b', [_vm._v(_vm._s(each.name))]), _vm._v(", Please Setup First")])])], 1)], 1) : _c('b-row', [_c('b-col', {
            attrs: {
              "cols": "12"
            }
          }, [_c('b-form-input', {
            staticClass: "mb-2",
            attrs: {
              "id": "searchEquipment",
              "type": "text",
              "placeholder": "Search equipment..."
            },
            model: {
              value: _vm.searchEquipment,
              callback: function callback($$v) {
                _vm.searchEquipment = $$v;
              },
              expression: "searchEquipment"
            }
          }), _c('div', {
            staticClass: "table-responsive"
          }, [_c('table', {
            staticClass: "table mb-0 table-striped table-hover"
          }, [_c('thead', {
            staticClass: "text-center"
          }, [_c('tr', [_c('td', {
            attrs: {
              "width": "3%"
            }
          }, [_vm._v("#")]), _c('td', [_vm._v("Equipment - Code")]), _c('td', [_vm._v("Type - Serial No")]), _c('td', [_vm._v("Maker")]), _c('td', [_vm._v("Job Index")])])]), _c('tbody', _vm._l(each.componentSetup, function (components) {
            return _c('tr', {
              key: components.id
            }, [_c('td', [_c('b-form-group', [_c('b-form-radio', {
              attrs: {
                "disabled": _vm.maintenanceDetailData,
                "name": "radio-component-vehicle-".concat(_vm.formInitMaintenance.vehicles[i].vehicleId),
                "value": components.id
              },
              model: {
                value: _vm.formInitMaintenance.vehicles[i].componentSetupId,
                callback: function callback($$v) {
                  _vm.$set(_vm.formInitMaintenance.vehicles[i], "componentSetupId", $$v);
                },
                expression: "\n                                                  formInitMaintenance\n                                                    .vehicles[i]\n                                                    .componentSetupId\n                                                "
              }
            })], 1)], 1), _c('td', [_vm._v(_vm._s(components.maintenanceMaster.equipmentId) + " - " + _vm._s(components.maintenanceMaster.equipmentName))]), _c('td', [_vm._v(_vm._s(components.typeNo) + " - " + _vm._s(components.serialNo))]), _c('td', [_c('ul', [_c('li', [_vm._v("Maker No: " + _vm._s(components.makerNo))]), _c('li', [_vm._v("Maker: " + _vm._s(components.maker))])])]), _c('td', {
              staticClass: "text-center"
            }, [components.status.length === 0 ? _c('small', [_c('b-badge', {
              attrs: {
                "variant": "danger d-block"
              }
            }, [_vm._v("NOT YET INIT")])], 1) : _c('small', _vm._l(components.status, function (stats, index) {
              return _c('b-badge', {
                key: index,
                staticClass: "mb-1",
                attrs: {
                  "variant": "success d-block"
                }
              }, [_vm._v(_vm._s(stats))]);
            }), 1)])]);
          }), 0)])])], 1), _c('b-col', {
            attrs: {
              "cols": "12"
            }
          }, [_c('b-row', [_c('b-col', {
            attrs: {
              "md": "6"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Job Code:",
              "label-for": "job_name"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "job_name",
              "type": "text",
              "placeholder": "Job Code"
            },
            model: {
              value: _vm.formInitMaintenance.jobCode,
              callback: function callback($$v) {
                _vm.$set(_vm.formInitMaintenance, "jobCode", $$v);
              },
              expression: "\n                                            formInitMaintenance.jobCode\n                                          "
            }
          })], 1)], 1), _c('b-col', {
            attrs: {
              "md": "6"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Job Name:",
              "label-for": "job_name"
            }
          }, [_c('b-form-input', {
            attrs: {
              "id": "job_name",
              "type": "text",
              "placeholder": "Job Name"
            },
            model: {
              value: _vm.formInitMaintenance.jobName,
              callback: function callback($$v) {
                _vm.$set(_vm.formInitMaintenance, "jobName", $$v);
              },
              expression: "\n                                            formInitMaintenance.jobName\n                                          "
            }
          })], 1)], 1), _c('b-col', {
            attrs: {
              "md": "6"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Job Class",
              "label-for": "fleet-equipment"
            }
          }, [_c('v-multiselect', {
            attrs: {
              "tag-placeholder": "Category",
              "placeholder": "Search or add a job class",
              "label": "name",
              "track-by": "code",
              "options": _vm.jobClassList,
              "multiple": false,
              "taggable": false
            },
            model: {
              value: _vm.formInitMaintenance.jobClassId,
              callback: function callback($$v) {
                _vm.$set(_vm.formInitMaintenance, "jobClassId", $$v);
              },
              expression: "\n                                            formInitMaintenance.jobClassId\n                                          "
            }
          })], 1)], 1), _c('b-col', {
            attrs: {
              "cols": "6"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Job Type",
              "label-for": "fleet-equipment"
            }
          }, [_c('v-multiselect', {
            attrs: {
              "tag-placeholder": "Category",
              "placeholder": "Search or add a job type",
              "label": "name",
              "track-by": "code",
              "options": _vm.jobTypeList,
              "multiple": false,
              "taggable": false
            },
            model: {
              value: _vm.formInitMaintenance.vehicles[i].jobTypeId,
              callback: function callback($$v) {
                _vm.$set(_vm.formInitMaintenance.vehicles[i], "jobTypeId", $$v);
              },
              expression: "\n                                            formInitMaintenance.vehicles[i]\n                                              .jobTypeId\n                                          "
            }
          })], 1)], 1), _c('b-col', {
            attrs: {
              "cols": "12"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Job Description",
              "label-for": "fleet-equipment"
            }
          }, [_c('wysiwyg', {
            staticStyle: {
              "width": "100%"
            },
            attrs: {
              "id": "description"
            },
            model: {
              value: _vm.formInitMaintenance.vehicles[i].jobDesc,
              callback: function callback($$v) {
                _vm.$set(_vm.formInitMaintenance.vehicles[i], "jobDesc", $$v);
              },
              expression: "\n                                            formInitMaintenance.vehicles[i]\n                                              .jobDesc\n                                          "
            }
          })], 1)], 1), _c('b-col', {
            staticClass: "text-center mb-4 mt-3 bg-light p-3",
            attrs: {
              "md": "12"
            }
          }, [_c('h4', [_vm._v("Trigger")]), _vm._l(_vm.triggers, function (item, index) {
            return [_c('b-form-radio', {
              key: index,
              staticClass: "custom-radio-color-checked",
              attrs: {
                "inline": "",
                "color": item.color,
                "name": "radio-trigger-".concat(index, "-vehicle-").concat(_vm.formInitMaintenance.vehicles[i].vehicleId),
                "value": item.value,
                "disabled": item.disabled
              },
              model: {
                value: _vm.formInitMaintenance.vehicles[i].trigger,
                callback: function callback($$v) {
                  _vm.$set(_vm.formInitMaintenance.vehicles[i], "trigger", $$v);
                },
                expression: "\n                                            formInitMaintenance.vehicles[i]\n                                              .trigger\n                                          "
              }
            }, [_vm._v(_vm._s(item.label))])];
          })], 2), _c('b-col', {
            attrs: {
              "md": "12"
            }
          }, [_c('b-row', {
            staticClass: "p-3"
          }, [_vm.formInitMaintenance.vehicles[i].trigger === 'CALENDAR' ? [_c('b-col', {
            attrs: {
              "md": "6"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label-for": "pattern",
              "label": "Pattern"
            }
          }, [_vm._l(_vm.patternCalendar, function (item, index2) {
            return [_c('b-form-radio', {
              key: index2,
              staticClass: "custom-radio-color-checked",
              attrs: {
                "inline": "",
                "color": item.color,
                "name": "radio-pattern-".concat(index2, "-vehicle-").concat(_vm.formInitMaintenance.vehicles[i].vehicleId),
                "value": item.value,
                "disabled": item.disabled
              },
              model: {
                value: _vm.formInitMaintenance.vehicles[i].calendar.calendarType,
                callback: function callback($$v) {
                  _vm.$set(_vm.formInitMaintenance.vehicles[i].calendar, "calendarType", $$v);
                },
                expression: "\n                                                    formInitMaintenance\n                                                      .vehicles[i].calendar\n                                                      .calendarType\n                                                  "
              }
            }, [_vm._v(_vm._s(item.label))])];
          })], 2)], 1), _c('b-col', {
            attrs: {
              "md": "6"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Interval/Frequency:",
              "label-for": "Interval:"
            }
          }, [_c('b-input-group', {
            attrs: {
              "size": "md",
              "append": _vm.formInitMaintenance.vehicles[i].calendar.calendarType === 'DAY' ? 'days' : _vm.formInitMaintenance.vehicles[i].calendar.calendarType === 'WEEK' ? 'Weeks' : 'Months'
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "number"
            },
            model: {
              value: _vm.formInitMaintenance.vehicles[i].calendar.interval,
              callback: function callback($$v) {
                _vm.$set(_vm.formInitMaintenance.vehicles[i].calendar, "interval", $$v);
              },
              expression: "\n                                                    formInitMaintenance\n                                                      .vehicles[i].calendar\n                                                      .interval\n                                                  "
            }
          })], 1)], 1)], 1), _c('b-col', {
            attrs: {
              "md": "6"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Last Maintenance:",
              "label-for": "lastMaintenance"
            }
          }, [_c('date-picker', {
            staticStyle: {
              "width": "100%"
            },
            attrs: {
              "value-type": "DD-MM-YYYY",
              "format": "DD MMM YYYY",
              "type": "date",
              "placeholder": "Select date"
            },
            model: {
              value: _vm.formInitMaintenance.vehicles[i].calendar.lastMaintenanceDate,
              callback: function callback($$v) {
                _vm.$set(_vm.formInitMaintenance.vehicles[i].calendar, "lastMaintenanceDate", $$v);
              },
              expression: "\n                                                  formInitMaintenance\n                                                    .vehicles[i].calendar\n                                                    .lastMaintenanceDate\n                                                "
            }
          })], 1)], 1), _c('b-col', {
            attrs: {
              "md": "6"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Notif Before Days:",
              "label-for": "cost"
            }
          }, [_c('b-input-group', {
            attrs: {
              "size": "md"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "number"
            },
            model: {
              value: _vm.formInitMaintenance.vehicles[i].calendar.notifBeforeDays,
              callback: function callback($$v) {
                _vm.$set(_vm.formInitMaintenance.vehicles[i].calendar, "notifBeforeDays", $$v);
              },
              expression: "\n                                                    formInitMaintenance\n                                                      .vehicles[i].calendar\n                                                      .notifBeforeDays\n                                                  "
            }
          })], 1)], 1)], 1), _c('b-col', {
            attrs: {
              "md": "6"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Cost:",
              "label-for": "cost"
            }
          }, [_c('b-input-group', {
            attrs: {
              "size": "md",
              "prepend": "Rp"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "number"
            },
            model: {
              value: _vm.formInitMaintenance.vehicles[i].calendar.cost,
              callback: function callback($$v) {
                _vm.$set(_vm.formInitMaintenance.vehicles[i].calendar, "cost", $$v);
              },
              expression: "\n                                                    formInitMaintenance\n                                                      .vehicles[i].calendar\n                                                      .cost\n                                                  "
            }
          })], 1), _c('small', [_vm._v(_vm._s(_vm.formatCurrency(_vm.formInitMaintenance.vehicles[i].calendar.cost)))])], 1)], 1), _c('b-col', {
            attrs: {
              "md": "6"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label-for": "maintenanceFile",
              "label": "File Maintenance"
            }
          }, [_c('b-form-file', {
            attrs: {
              "placeholder": "Choose a file",
              "name": "maintenance-file-".concat(i, "-vehicle-").concat(_vm.formInitMaintenance.vehicles[i].vehicleId),
              "id": "maintenance-file-".concat(i, "-vehicle-").concat(_vm.formInitMaintenance.vehicles[i].vehicleId),
              "multiple": ""
            },
            on: {
              "change": function change($event) {
                return _vm.uploadFile($event, i, null);
              }
            }
          })], 1)], 1), _c('b-col', {
            attrs: {
              "md": "12"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label-for": "Notes",
              "label": "Notes"
            }
          }, [_c('b-form-textarea', {
            attrs: {
              "id": "notes",
              "rows": "11",
              "placeholder": "Type Notes"
            },
            model: {
              value: _vm.formInitMaintenance.vehicles[i].calendar.notes,
              callback: function callback($$v) {
                _vm.$set(_vm.formInitMaintenance.vehicles[i].calendar, "notes", $$v);
              },
              expression: "\n                                                  formInitMaintenance\n                                                    .vehicles[i].calendar\n                                                    .notes\n                                                "
            }
          })], 1)], 1), _c('b-col', {
            attrs: {
              "md": "6"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Service Repair"
            }
          }, [_c('b-form-radio', {
            attrs: {
              "value": true
            },
            model: {
              value: _vm.formInitMaintenance.vehicles[i].calendar.isServiceRepair,
              callback: function callback($$v) {
                _vm.$set(_vm.formInitMaintenance.vehicles[i].calendar, "isServiceRepair", $$v);
              },
              expression: "\n                                                  formInitMaintenance\n                                                    .vehicles[i].calendar\n                                                    .isServiceRepair\n                                                "
            }
          }, [_vm._v("Yes")]), _c('b-form-radio', {
            attrs: {
              "value": false
            },
            model: {
              value: _vm.formInitMaintenance.vehicles[i].calendar.isServiceRepair,
              callback: function callback($$v) {
                _vm.$set(_vm.formInitMaintenance.vehicles[i].calendar, "isServiceRepair", $$v);
              },
              expression: "\n                                                  formInitMaintenance\n                                                    .vehicles[i].calendar\n                                                    .isServiceRepair\n                                                "
            }
          }, [_vm._v("No")])], 1)], 1)] : _vm._e(), _vm.formInitMaintenance.vehicles[i].trigger === 'COUNTER' ? [each.powers && each.powers.length > 0 ? _c('b-col', {
            staticClass: "mb-3 bg-light w-100",
            attrs: {
              "md": "12"
            }
          }, [_vm._l(each.powers, function (powersData, index) {
            return [_vm.maintenanceDetailData && _vm.maintenanceDetailData.power && _vm.maintenanceDetailData.power.id === powersData.id || !_vm.maintenanceDetailData ? _c('fieldset', {
              key: index,
              staticClass: "form-group border p-3 w-100 mb-3"
            }, [_c('legend', {
              staticClass: "w-auto px-2"
            }, [_c('b-form-checkbox', {
              attrs: {
                "id": "checkbox-counter-".concat(index, "-vehicle-").concat(_vm.formInitMaintenance.vehicles[i].vehicleId),
                "name": "checkbox-counter-".concat(index, "-vehicle-").concat(_vm.formInitMaintenance.vehicles[i].vehicleId)
              },
              model: {
                value: _vm.formInitMaintenance.vehicles[i].counter[index].selected,
                callback: function callback($$v) {
                  _vm.$set(_vm.formInitMaintenance.vehicles[i].counter[index], "selected", $$v);
                },
                expression: "\n                                                      formInitMaintenance\n                                                        .vehicles[i].counter[\n                                                        index\n                                                      ].selected\n                                                    "
              }
            }, [_vm._v(" " + _vm._s(powersData.powerType ? powersData.powerType.name : '') + " " + _vm._s(powersData.powerType ? powersData.powerType.model : '') + " "), _c('small', [_vm._v(_vm._s(powersData.powerSeries ? powersData.powerSeries.name : '') + " - " + _vm._s(powersData.powerSeries ? powersData.powerSeries.model : ''))])])], 1), _vm.formInitMaintenance.vehicles[i].counter[index].selected ? _c('b-row', [_c('b-col', {
              attrs: {
                "md": "6"
              }
            }, [_c('b-form-group', {
              attrs: {
                "label": "Interval/Frequency:",
                "label-for": "Interval:"
              }
            }, [_c('b-input-group', {
              attrs: {
                "size": "md",
                "append": "Hours"
              }
            }, [_c('b-form-input', {
              attrs: {
                "type": "number"
              },
              model: {
                value: _vm.formInitMaintenance.vehicles[i].counter[index].interval,
                callback: function callback($$v) {
                  _vm.$set(_vm.formInitMaintenance.vehicles[i].counter[index], "interval", $$v);
                },
                expression: "\n                                                            formInitMaintenance\n                                                              .vehicles[i]\n                                                              .counter[index]\n                                                              .interval\n                                                          "
              }
            })], 1)], 1)], 1), _c('b-col', {
              attrs: {
                "md": "6"
              }
            }, [_c('b-form-group', {
              attrs: {
                "label-for": "maintenanceFile",
                "label": "File Maintenance"
              }
            }, [_c('b-form-file', {
              attrs: {
                "name": "maintenance-file-counter-".concat(i, "-").concat(index, "-vehicle-").concat(_vm.formInitMaintenance.vehicles[i].vehicleId),
                "id": "maintenance-file-counter-".concat(i, "-").concat(index, "-vehicle-").concat(_vm.formInitMaintenance.vehicles[i].vehicleId),
                "placeholder": "Choose a file",
                "multiple": ""
              },
              on: {
                "change": function change($event) {
                  return _vm.uploadFile($event, i, index);
                }
              }
            })], 1)], 1), _c('b-col', {
              attrs: {
                "md": "6"
              }
            }, [_c('b-form-group', {
              attrs: {
                "label": "Last Maintenance Date:",
                "label-for": "lastMaintenance"
              }
            }, [_c('date-picker', {
              staticStyle: {
                "width": "100%"
              },
              attrs: {
                "value-type": "DD-MM-YYYY",
                "format": "DD MMM YYYY",
                "type": "date",
                "placeholder": "Select date"
              },
              model: {
                value: _vm.formInitMaintenance.vehicles[i].counter[index].lastMaintenanceDate,
                callback: function callback($$v) {
                  _vm.$set(_vm.formInitMaintenance.vehicles[i].counter[index], "lastMaintenanceDate", $$v);
                },
                expression: "\n                                                          formInitMaintenance\n                                                            .vehicles[i]\n                                                            .counter[index]\n                                                            .lastMaintenanceDate\n                                                        "
              }
            }), _c('small', {
              staticStyle: {
                "float": "right"
              }
            }, [_vm._v("click "), _c('i', {
              staticClass: "fa fa-calendar"
            }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
              attrs: {
                "md": "6"
              }
            }, [_c('b-form-group', {
              attrs: {
                "label": "Last Maintenance Hours:",
                "label-for": "lastMaintenanceHours:"
              }
            }, [_c('b-input-group', {
              attrs: {
                "size": "md",
                "append": "Hours"
              }
            }, [_c('b-form-input', {
              attrs: {
                "type": "number"
              },
              model: {
                value: _vm.formInitMaintenance.vehicles[i].counter[index].lastMaintenanceHours,
                callback: function callback($$v) {
                  _vm.$set(_vm.formInitMaintenance.vehicles[i].counter[index], "lastMaintenanceHours", $$v);
                },
                expression: "\n                                                            formInitMaintenance\n                                                              .vehicles[i]\n                                                              .counter[index]\n                                                              .lastMaintenanceHours\n                                                          "
              }
            })], 1)], 1)], 1), _c('b-col', {
              attrs: {
                "md": "6"
              }
            }, [_c('b-form-group', {
              attrs: {
                "label": "Cost:",
                "label-for": "cost"
              }
            }, [_c('b-input-group', {
              attrs: {
                "size": "md",
                "prepend": "Rp"
              }
            }, [_c('b-form-input', {
              attrs: {
                "type": "number"
              },
              model: {
                value: _vm.formInitMaintenance.vehicles[i].counter[index].cost,
                callback: function callback($$v) {
                  _vm.$set(_vm.formInitMaintenance.vehicles[i].counter[index], "cost", $$v);
                },
                expression: "\n                                                            formInitMaintenance\n                                                              .vehicles[i]\n                                                              .counter[index]\n                                                              .cost\n                                                          "
              }
            })], 1), _c('small', {
              staticClass: "ml-4"
            }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.formInitMaintenance.vehicles[i].counter[index].cost)))])], 1)], 1), _c('b-col', {
              attrs: {
                "md": "6"
              }
            }, [_c('b-form-group', {
              attrs: {
                "label": "Notif Before Percents:",
                "label-for": "cost"
              }
            }, [_c('b-input-group', {
              attrs: {
                "size": "md"
              }
            }, [_c('b-form-input', {
              attrs: {
                "type": "number"
              },
              model: {
                value: _vm.formInitMaintenance.vehicles[i].counter[index].notifBeforePercents,
                callback: function callback($$v) {
                  _vm.$set(_vm.formInitMaintenance.vehicles[i].counter[index], "notifBeforePercents", $$v);
                },
                expression: "\n                                                            formInitMaintenance\n                                                              .vehicles[i]\n                                                              .counter[index]\n                                                              .notifBeforePercents\n                                                          "
              }
            })], 1)], 1)], 1), _c('b-col', {
              staticClass: "mb-3",
              attrs: {
                "md": "6"
              }
            }, [_c('b-form-group', {
              attrs: {
                "label": "Reset Running Hour for this job ?",
                "label-for": "sub_sub_project"
              }
            }, [_c('b-form-checkbox', {
              attrs: {
                "name": "check-button",
                "switch": "",
                "inline": ""
              },
              model: {
                value: _vm.formInitMaintenance.vehicles[i].counter[index].isResetRunningHours,
                callback: function callback($$v) {
                  _vm.$set(_vm.formInitMaintenance.vehicles[i].counter[index], "isResetRunningHours", $$v);
                },
                expression: "\n                                                          formInitMaintenance\n                                                            .vehicles[i]\n                                                            .counter[index]\n                                                            .isResetRunningHours\n                                                        "
              }
            }, [_vm.formInitMaintenance.vehicles[i].counter[index].isResetRunningHours ? [_vm._v("Yes")] : [_vm._v("No")]], 2)], 1)], 1), _c('b-col', {
              attrs: {
                "md": "6"
              }
            }, [_c('b-form-group', {
              attrs: {
                "label-for": "Notes",
                "label": "Notes"
              }
            }, [_c('b-form-textarea', {
              attrs: {
                "id": "notes",
                "rows": "3",
                "placeholder": "Type Notes"
              },
              model: {
                value: _vm.formInitMaintenance.vehicles[i].counter[index].notes,
                callback: function callback($$v) {
                  _vm.$set(_vm.formInitMaintenance.vehicles[i].counter[index], "notes", $$v);
                },
                expression: "\n                                                          formInitMaintenance\n                                                            .vehicles[i]\n                                                            .counter[index]\n                                                            .notes\n                                                        "
              }
            })], 1)], 1)], 1) : _vm._e()], 1) : _vm._e()];
          })], 2) : _vm._e()] : _vm._e()], 2)], 1)], 1)], 1)], 1)], 1);
        }), 0) : _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-alert', {
          attrs: {
            "show": true,
            "variant": "success"
          }
        }, [_c('div', {
          staticClass: "iq-alert-icon"
        }, [_c('i', {
          staticClass: "ri-alert-line"
        })]), _c('div', {
          staticClass: "iq-alert-text"
        }, [_c('b', [_vm._v("Perhatian,")]), _vm._v(" pilih Kategori Equipment disebelah kiri dahulu")])])], 1)], 1)], 1)], 1)], 1)])], 1)], 1), _c('b-modal', {
          attrs: {
            "id": "clone_initiation",
            "size": "large",
            "title": "Duplikasi Komponen Kapal",
            "header-bg-variant": "light",
            "header-text-variant": "dark",
            "footer-bg-variant": "light",
            "footer-text-variant": "dark"
          }
        }, [_c('b-alert', {
          attrs: {
            "show": true,
            "variant": "primary"
          }
        }, [_c('div', {
          staticClass: "iq-alert-icon"
        }, [_c('i', {
          staticClass: "ri-alert-line"
        })]), _c('div', {
          staticClass: "iq-alert-text"
        }, [_c('b', [_vm._v("Perhatikan")]), _vm._v(" Pastikan kapal yang akan diduplikasi komponen nya sudah sesuai datanya")])]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Dari Init Kapal*",
            "label-for": "fleet"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "size": "lg"
          }
        }, [_c('b-form-select-option', [_vm._v("Select Fleet")]), _c('b-form-select-option', [_vm._v("TB. TAVIA 1")]), _c('b-form-select-option', [_vm._v("TB. TAVIA 2")]), _c('b-form-select-option', [_vm._v("BG. LANGIT 1")]), _c('b-form-select-option', [_vm._v("BG. LANGIT 2")])], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Ke Init Kapal*",
            "label-for": "fleet"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "size": "lg"
          }
        }, [_c('b-form-select-option', [_vm._v("Select Fleet")]), _c('b-form-select-option', [_vm._v("TB. TAVIA 1")]), _c('b-form-select-option', [_vm._v("TB. TAVIA 2")]), _c('b-form-select-option', [_vm._v("BG. LANGIT 1")]), _c('b-form-select-option', [_vm._v("BG. LANGIT 2")])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table mb-0 table-striped table-hover"
        }, [_c('thead', {
          staticClass: "text-center"
        }, [_c('tr', [_c('th', [_vm._v("No")]), !_vm.queryVehicleId ? _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Fleet/Equipment")]) : _vm._e(), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Job Name - Code")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Category")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Job Type")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Trigger - Interval")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Last Maintenance")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Due Date / Hours")]), _c('th', {
          staticClass: "text-truncate"
        }, [_vm._v("Status")]), _c('th', [_vm._v("Action")])])]), _vm.initMaintenance.data.length === 0 ? _c('tbody', [_vm.initMaintenance.isLoading ? _c('tr', [_c('td', {
          staticClass: "text-center",
          attrs: {
            "colspan": "12"
          }
        }, [_c('b-spinner', {
          attrs: {
            "label": "Spinning"
          }
        })], 1)]) : _c('tr', [_c('td', {
          staticClass: "text-center",
          attrs: {
            "colspan": "12"
          }
        }, [_vm._v("No Data")])])]) : _vm._e(), _vm.initMaintenance.data && _vm.initMaintenance.data.length > 0 ? _c('tbody', [_vm._l(_vm.initMaintenance.data, function (job, index) {
          return [_c('tr', {
            key: index
          }, [_c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(index + 1))]), !_vm.queryVehicleId ? _c('td', [_c('a', {
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.viewDetailMaintenance(job.id);
              }
            }
          }, [_vm._v(_vm._s(job.vehicleName))])]) : _vm._e(), _c('td', [_c('router-link', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: 'view-job-init' + index,
              expression: "'view-job-init' + index"
            }],
            attrs: {
              "to": ""
            }
          }, [_vm._v(_vm._s(job.jobName))])], 1), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(job.componentName))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(job.jobTypeName))]), _c('td', {
            staticClass: "text-center"
          }, [_c('strong', [_vm._v(_vm._s(job.trigger))])]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(job.lastMaintenanceDate))]), _c('td', {
            staticClass: "text-center"
          }, [_c('span', [_vm._v(_vm._s(job.dueDate))])]), _c('td', {
            staticClass: "text-center"
          }, [_c('b-badge', {
            attrs: {
              "variant": "success"
            }
          }, [_vm._v(_vm._s(job.status))])], 1), _c('td', {
            staticClass: "text-center"
          }, [job.status !== 'Already Run' && job.status !== 'already_run' && job.status !== 'already run' ? [_c('b-dropdown', {
            attrs: {
              "variant": "link iq-bg-primary iq-border-radius-10",
              "toggle-class": "text-decoration-none",
              "no-caret": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function fn() {
                return [_c('span', {
                  staticClass: "dropdown-toggle p-0",
                  attrs: {
                    "id": "dropdownMenuButton5",
                    "data-toggle": "dropdown"
                  }
                }, [_c('i', {
                  staticClass: "fa fa-caret-down m-0 text-primary"
                })])];
              },
              proxy: true
            }], null, true)
          }, [_vm.canEdit ? _c('b-dropdown-item', {
            staticClass: "m-0 p-0",
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click($event) {
                return _vm.fetchMaintenanceDetail(job.id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-pencil-square-o mr-2"
          }), _vm._v("Edit")]) : _vm._e(), _vm.canDelete ? _c('b-dropdown-item', {
            staticClass: "m-0 p-0",
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeleteInit(job);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash mr-2"
          }), _vm._v("Delete")]) : _vm._e()], 1)] : _vm._e()], 2)])];
        })], 2) : _vm._e()])])]), _vm.initMaintenance.data && _vm.initMaintenance.data.length > 0 ? _c('b-col', {
          staticClass: "mt-4"
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.initMaintenance.meta.total,
            "per-page": _vm.initMaintenance.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getInitiationList
          },
          model: {
            value: _vm.initMaintenance.meta.currentPage,
            callback: function callback($$v) {
              _vm.$set(_vm.initMaintenance.meta, "currentPage", $$v);
            },
            expression: "initMaintenance.meta.currentPage"
          }
        })], 1) : _vm._e(), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('small', [_vm._v("NB: Jika sudah masuk jadwal dan sudah berjalan, initiation berpindah ke maintenance board")])])], 1)];
      },
      proxy: true
    }])
  }), _c('b-sidebar', {
    attrs: {
      "id": "view-detail-maintenance",
      "title": "View Job Maintenance",
      "width": "1300px",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    model: {
      value: _vm.viewDetailMaintenanceSidebar,
      callback: function callback($$v) {
        _vm.viewDetailMaintenanceSidebar = $$v;
      },
      expression: "viewDetailMaintenanceSidebar"
    }
  }, [[_vm.viewDetailMaintenanceSidebar ? _c('ViewMaintenanceDetail', {
    attrs: {
      "maintenance-id": _vm.selectedMaintenanceId
    }
  }) : _vm._e()]], 2)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }