<template>
  <b-row>
    <b-col md="12">
      <iq-card headerClass="bg-head-availablility">
        <template v-slot:headerTitle>
          <h4 class="card-title text-white"
            ><strong>YTD AVAILABILTY VS BREAKDOWN</strong></h4
          >
        </template>
        <template v-slot:body>
          <div style="min-height: 350px">
            <div class="d-flex justify-content-between mb-3">
              <div class="">
                <h5 class="text-primary"
                  ><strong>{{ chartSeries[chartIdx].title }}</strong></h5
                >
              </div>
              <div class="d-flex btn-group">
                <b-button
                  :variant="chartIdx == 0 ? 'outline-primary' : 'primary'"
                  size="sm"
                  class="mr-2"
                  :disabled="chartIdx == 0"
                  @click="prev()"
                >
                  <i class="fas fa-chevron-left"></i>
                </b-button>
                <b-button
                  :variant="
                    chartIdx + 1 == chartSeries.length
                      ? 'outline-primary'
                      : 'primary'
                  "
                  size="sm"
                  :disabled="chartIdx + 1 == chartSeries.length"
                  @click="next()"
                >
                  <i class="fas fa-chevron-right"></i>
                </b-button>
              </div>
            </div>
            <div v-for="(chart, index) in chartSeries" :key="index">
              <template v-if="index == chartIdx">
                <BreakdownVsAvailabilityChart
                  :series="chart.series"
                  :element="`${index}`"
                />
              </template>
            </div>
          </div>
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>
<script>
import BreakdownVsAvailabilityChart from './Chart/BreakdownVsAvailabilityChart.vue'

export default {
  components: {
    BreakdownVsAvailabilityChart,
  },
  data() {
    return {
      chartIdx: 0,
      chartSeries: [
        {
          title: 'Tugboat & Barge',
          series: [30, 70],
        },
        {
          title: 'Pusher Tugboat & Pusher Barge',
          series: [30, 70],
        },
        {
          title: 'Floating Crane & Oil Barge',
          series: [30, 70],
        },
      ],
    }
  },
  methods: {
    next() {
      this.chartIdx = this.chartIdx + 1
    },
    prev() {
      this.chartIdx = this.chartIdx - 1
    },
  },
}
</script>
<style>
.bg-head-availablility {
  background: #27bb2d;
}
</style>
