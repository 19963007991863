var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "iq-edit-profile d-flex",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-3 p-0",
          attrs: {
            "active": true,
            "href": "#schedule",
            "title": "SCHEDULE"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-3 p-0",
          attrs: {
            "active": false,
            "href": "#progress",
            "title": "PROGRESS"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-3 p-0",
          attrs: {
            "active": false,
            "href": "#finish",
            "title": "FINISH"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-3 p-0",
          attrs: {
            "active": false,
            "href": "#report",
            "title": "REPORT"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "iq-edit-list-data"
  }, [_c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "pills-tabContent-2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "schedule"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("DOCKING SCHEDULE")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('b-button', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.add-init",
            modifiers: {
              "add-init": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.showInitModal = !_vm.showInitModal;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-edit"
        }), _vm._v("   CREATE EMERGENCY DOCKING")]), _c('a', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.set_schedule",
            modifiers: {
              "set_schedule": true
            }
          }],
          staticClass: "btn btn-primary",
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-calendar mr-2"
        }), _vm._v("SET SCHEDULE DOCKING")]), _c('b-modal', {
          attrs: {
            "id": "add-init",
            "size": "xl",
            "title": "Select Fleet",
            "header-bg-variant": "light",
            "header-text-variant": "dark",
            "footer-bg-variant": "light",
            "footer-text-variant": "dark"
          },
          scopedSlots: _vm._u([{
            key: "modal-footer",
            fn: function fn(_ref) {
              var ok = _ref.ok,
                cancel = _ref.cancel,
                hide = _ref.hide;
              return [_c('b-row', [_c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-button', {
                staticClass: "iq-bg-danger text-right",
                attrs: {
                  "variant": "none"
                },
                on: {
                  "click": function click($event) {
                    return cancel();
                  }
                }
              }, [_vm._v("Cancel")]), _c('b-button', {
                staticClass: "ml-2 text-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                },
                on: {
                  "click": function click($event) {
                    _vm.modalCreateDocking = !_vm.modalCreateDocking;
                    _vm.showInitModal = !_vm.showInitModal;
                  }
                }
              }, [_vm._v("Next "), _c('i', {
                staticClass: "fa fa-arrow-right"
              })])], 1)], 1)];
            }
          }]),
          model: {
            value: _vm.showInitModal,
            callback: function callback($$v) {
              _vm.showInitModal = $$v;
            },
            expression: "showInitModal"
          }
        }, [_c('b-row', {
          staticClass: "mb-4"
        }, [_c('b-col', {
          staticClass: "text-right",
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Search fleets..."
          },
          on: {
            "input": _vm.fetchFleets
          },
          model: {
            value: _vm.fleet_params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.fleet_params, "search", $$v);
            },
            expression: "fleet_params.search"
          }
        })], 1)], 1), _vm.loading_fleet ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.fleets.length > 0 ? _c('b-row', _vm._l(_vm.fleets, function (fleet, i) {
          return _c('b-col', {
            key: "fleet-".concat(i),
            staticClass: "text-center py-5 border-top",
            attrs: {
              "md": "3"
            }
          }, [_c('div', {
            staticClass: "custom-control custom-checkbox image-checkbox"
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: _vm.checkedFleets,
              expression: "checkedFleets"
            }],
            staticClass: "custom-control-input",
            attrs: {
              "id": "ck-fl-".concat(i),
              "type": "checkbox"
            },
            domProps: {
              "value": fleet.id,
              "checked": Array.isArray(_vm.checkedFleets) ? _vm._i(_vm.checkedFleets, fleet.id) > -1 : _vm.checkedFleets
            },
            on: {
              "change": function change($event) {
                var $$a = _vm.checkedFleets,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;
                if (Array.isArray($$a)) {
                  var $$v = fleet.id,
                    $$i = _vm._i($$a, $$v);
                  if ($$el.checked) {
                    $$i < 0 && (_vm.checkedFleets = $$a.concat([$$v]));
                  } else {
                    $$i > -1 && (_vm.checkedFleets = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                  }
                } else {
                  _vm.checkedFleets = $$c;
                }
              }
            }
          }), _c('label', {
            staticClass: "custom-control-label",
            attrs: {
              "for": "ck-fl-".concat(i)
            }
          }, [_c('img', {
            staticClass: "img-fluid",
            attrs: {
              "src": _vm._f("checkPhoto")(fleet.vehicleType.icon.url, require('@src/assets/images/fleet/PusherBarge.png')),
              "alt": "#"
            }
          })]), _c('small', [_c('strong', [_vm._v(_vm._s(fleet.name))])])])]);
        }), 1) : _c('div', {
          staticClass: "text-center my-5"
        }, [_c('p', {
          staticClass: "mb-0 text-muted"
        }, [_vm._v("No fleets data found.")])])]], 2), _c('b-modal', {
          attrs: {
            "id": "set_schedule",
            "size": "lg",
            "title": "SET SCHEDULE DOCKING",
            "ok-title": "Save",
            "cancel-title": "Close"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Fleet*",
            "label-for": "fleet"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.kapal,
            "size": "lg",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Fleet")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedFleets,
            callback: function callback($$v) {
              _vm.selectedFleets = $$v;
            },
            expression: "selectedFleets"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Last Docking*",
            "label-for": "last_docking"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date",
            "id": "end",
            "required": ""
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Fleet*",
            "label-for": "fleet"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "size": "lg",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', [_vm._v("INTERMEMDIATE SURVEY")]), _c('b-form-select-option', [_vm._v("SPECIAL SURVEY")])];
            },
            proxy: true
          }])
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Interval*",
            "label-for": "interval"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "required": ""
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text"
        }, [_vm._v("Month")])])], 1)])], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Start Schedule*",
            "label-for": "start_schedule"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "type": "number"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text"
        }, [_vm._v("Month")])])], 1)])], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "End Schedule",
            "label-for": "end_schedule"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('b-form-input', {
          attrs: {
            "type": "number"
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text"
        }, [_vm._v("Month")])])], 1)])], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "docking_schedule_card"
          }
        }, [_c('b-row', {
          staticClass: "mb-3"
        }, [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "tag-placeholder": "Category",
            "placeholder": "Fleet Category",
            "label": "text",
            "track-by": "value",
            "options": _vm.fleetCategory,
            "multiple": true,
            "taggable": true
          },
          model: {
            value: _vm.fleetCategorySelect,
            callback: function callback($$v) {
              _vm.fleetCategorySelect = $$v;
            },
            expression: "fleetCategorySelect"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "size": "sm"
          }
        }, [_c('b-form-select-option', [_vm._v("Select Fleet")]), _c('b-form-select-option', [_vm._v("TB. TAVIA 1")]), _c('b-form-select-option', [_vm._v("TB. TAVIA 2")]), _c('b-form-select-option', [_vm._v("TB. TAVIA 3")]), _c('b-form-select-option', [_vm._v("TB. TAVIA 4")]), _c('b-form-select-option', [_vm._v("BG. TAVIA 5")]), _c('b-form-select-option', [_vm._v("BG. TAVIA 6")])], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.docking_type,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Docking Type")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedDockingType,
            callback: function callback($$v) {
              _vm.selectedDockingType = $$v;
            },
            expression: "selectedDockingType"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.dockingStatus,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Status")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedDockingStatus,
            callback: function callback($$v) {
              _vm.selectedDockingStatus = $$v;
            },
            expression: "selectedDockingStatus"
          }
        })], 1), _c('b-col', {
          staticClass: "mt-3",
          attrs: {
            "md": "3"
          }
        }, [_c('date-picker', {
          attrs: {
            "lang": _vm.lang,
            "type": "date",
            "range": "",
            "placeholder": "Select date range",
            "size": "sm"
          },
          model: {
            value: _vm.dateRange,
            callback: function callback($$v) {
              _vm.dateRange = $$v;
            },
            expression: "dateRange"
          }
        })], 1), _c('b-col', {
          staticClass: "mt-3",
          attrs: {
            "md": "9"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "primary",
            "block": "",
            "size": "sm"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter mr-2"
        }), _vm._v(" Filter")])], 1)], 1)], 1), _c('table', {
          staticClass: "table table-striped table-sm"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("NO")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("FLEET")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("DOCKING TYPE")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("REMAINING DAYS")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("SCHEDULE")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("STATUS")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ACTION")])])]), _c('tbody', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("1")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. TAVIA 1")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("INTERMEDIATE SURVEY")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2020-03-15")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "success d-block"
          }
        }, [_vm._v("ACTIVE")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-ship m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "variant": "dark"
          }
        }, [_c('router-link', {
          attrs: {
            "to": "/templates/docking-history"
          }
        }, [_c('i', {
          staticClass: "fa fa-history mr-2"
        }), _vm._v("HISTORY DOCKING")])], 1), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "danger"
          }
        }, [_c('i', {
          staticClass: "fa fa-trash mr-2"
        }), _vm._v("DELETE")])], 1)], 1)]), _c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("2")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. TAVIA 2")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("INTERMEDIATE SURVEY")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("180 Days")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2022-07-17")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "warning d-block"
          }
        }, [_vm._v("DUE DATE")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-ship m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.modalCreateDocking = !_vm.modalCreateDocking;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-pencil-square-o mr-2"
        }), _vm._v("DOCKING")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.pending_docking",
            modifiers: {
              "pending_docking": true
            }
          }],
          attrs: {
            "variant": "danger"
          }
        }, [_c('i', {
          staticClass: "fa fa-clock mr-2"
        }), _vm._v("PENDING")]), _c('b-dropdown-item', {
          attrs: {
            "variant": "dark"
          }
        }, [_c('router-link', {
          attrs: {
            "to": "/templates/docking-history"
          }
        }, [_c('i', {
          staticClass: "fa fa-history mr-2"
        }), _vm._v("HISTORY DOCKING")])], 1), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "danger"
          }
        }, [_c('i', {
          staticClass: "fa fa-trash mr-2"
        }), _vm._v("DELETE")])], 1)], 1)]), _c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("3")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. TAVIA 3")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("INTERMEDIATE SURVEY")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("31 Days")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2023-09-25")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "primary d-block"
          }
        }, [_vm._v("FIRST PENDING")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-ship m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.modalCreateDocking = !_vm.modalCreateDocking;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-pencil-square-o mr-2"
        }), _vm._v("DOCKING")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.pending_docking",
            modifiers: {
              "pending_docking": true
            }
          }],
          attrs: {
            "variant": "danger"
          }
        }, [_c('i', {
          staticClass: "fa fa-clock mr-2"
        }), _vm._v("PENDING")]), _c('b-dropdown-item', {
          attrs: {
            "variant": "dark"
          }
        }, [_c('router-link', {
          attrs: {
            "to": "/templates/docking-history"
          }
        }, [_c('i', {
          staticClass: "fa fa-history mr-2"
        }), _vm._v("HISTORY DOCKING")])], 1), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "danger"
          }
        }, [_c('i', {
          staticClass: "fa fa-trash mr-2"
        }), _vm._v("DELETE")])], 1)], 1)]), _c('tr', {
          staticClass: "bg-danger"
        }, [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("4")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. TAVIA 4")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("SPECIAL SURVEY")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("- 15 Days")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2023-03-17")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "light d-block"
          }
        }, [_vm._v("OVER SCHEDULE")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-ship m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.modalCreateDocking = !_vm.modalCreateDocking;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-pencil-square-o mr-2"
        }), _vm._v("DOCKING")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.pending_docking",
            modifiers: {
              "pending_docking": true
            }
          }],
          attrs: {
            "variant": "danger"
          }
        }, [_c('i', {
          staticClass: "fa fa-clock mr-2"
        }), _vm._v("PENDING")]), _c('b-dropdown-item', {
          attrs: {
            "variant": "dark"
          }
        }, [_c('router-link', {
          attrs: {
            "to": "/templates/docking-history"
          }
        }, [_c('i', {
          staticClass: "fa fa-history mr-2"
        }), _vm._v("HISTORY DOCKING")])], 1), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "danger"
          }
        }, [_c('i', {
          staticClass: "fa fa-trash mr-2"
        }), _vm._v("DELETE")])], 1)], 1)]), _c('tr', {
          staticClass: "bg-info"
        }, [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("5")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. TAVIA 5")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("INTERMEDIATE SURVEY")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("31 DAYS")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2020-01-15")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "colspan": "2"
          }
        }, [_c('strong', [_vm._v("PROGRESS - CHECK TAB PROGRESS")])])]), _c('tr', {
          staticClass: "bg-warning"
        }, [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("6")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. TAVIA 6")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("INTERMEDIATE SURVEY")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2020-01-15")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "colspan": "2"
          }
        }, [_c('strong', [_vm._v("REVIEW - CHECK TAB PROGRESS")])])]), _c('tr', {
          staticClass: "bg-primary"
        }, [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("7")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. TAVIA 7")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("INTERMEDIATE SURVEY")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2020-01-15")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "colspan": "2"
          }
        }, [_c('strong', [_vm._v("APPROVE - CHECK TAB PROGRESS")])])]), _c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("8")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. TAVIA 8")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("INTERMEDIATE SURVEY")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("180 Days")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2022-07-17")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "danger d-block"
          }
        }, [_vm._v("APPROVE DELETE")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-ship m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.modalCreateDocking = !_vm.modalCreateDocking;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-pencil-square-o mr-2"
        }), _vm._v("DOCKING")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.pending_docking",
            modifiers: {
              "pending_docking": true
            }
          }],
          attrs: {
            "variant": "danger"
          }
        }, [_c('i', {
          staticClass: "fa fa-clock mr-2"
        }), _vm._v("PENDING")]), _c('b-dropdown-item', {
          attrs: {
            "variant": "dark"
          }
        }, [_c('router-link', {
          attrs: {
            "to": "/templates/docking-history"
          }
        }, [_c('i', {
          staticClass: "fa fa-history mr-2"
        }), _vm._v("HISTORY DOCKING")])], 1), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-trash mr-2"
        }), _vm._v("DELETE APPROVED")])], 1)], 1)])])]), _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "value": 1,
            "total-rows": 50,
            "align": "center"
          }
        })], 1), _c('b-modal', {
          attrs: {
            "id": "pending_docking",
            "size": "md",
            "title": "Pending Docking - TB. TAVIA 1",
            "ok-title": "Save",
            "cancel-title": "Close"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Pending Date *",
            "label-for": "pending_date"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date",
            "id": "docking_date"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1), _c('b-form-group', {
          attrs: {
            "label": "Reason Pending",
            "label-for": "reason_pending"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "reason_pending",
            "rows": "3"
          }
        })], 1)], 1)], 1)], 1), _c('b-sidebar', {
          attrs: {
            "id": "emergency_docking",
            "title": "CREATE DOCKING - TB. TAVIA 1",
            "width": "100%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var hide = _ref2.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "cols": "4"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Job List")]), _c('b-form-input', {
                attrs: {
                  "id": "job_name",
                  "type": "text",
                  "placeholder": "Search Job Name"
                }
              }), _c('v-jstree', {
                staticClass: "mt-3",
                attrs: {
                  "data": _vm.tree,
                  "show-checkbox": "",
                  "allow-batch": "",
                  "whole-row": "",
                  "item-events": _vm.itemEvents
                },
                on: {
                  "item-click": _vm.itemClick
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_) {
                    return [_c('div', {
                      staticStyle: {
                        "display": "inherit",
                        "width": "200px"
                      },
                      on: {
                        "click": function click($event) {
                          if (!$event.ctrlKey) return null;
                          return _vm.customItemClickWithCtrl.apply(null, arguments);
                        }
                      }
                    }, [!_.model.loading ? _c('i', {
                      class: _.vm.themeIconClasses,
                      attrs: {
                        "role": "presentation"
                      }
                    }) : _vm._e(), _vm._v(" " + _vm._s(_.model.text) + " ")])];
                  }
                }], null, true)
              }), _c('b-button', {
                attrs: {
                  "block": "",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-plus"
              }), _vm._v("ADD JOB")])], 1)]), _c('b-col', {
                attrs: {
                  "cols": "8"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Docking Info")]), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Nama Shipyard*",
                  "label-for": "shipyard"
                }
              }, [_c('b-form-select', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "plain": "",
                  "options": _vm.shipyard,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Pilih Shipyard")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedShipyard,
                  callback: function callback($$v) {
                    _vm.selectedShipyard = $$v;
                  },
                  expression: "selectedShipyard"
                }
              })], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "PIC*",
                  "label-for": "pic"
                }
              }, [_c('b-form-select', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "plain": "",
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', [_vm._v("Pilih PIC")]), _c('b-form-select-option', [_vm._v("Nurul Akbar")]), _c('b-form-select-option', [_vm._v("Agung Tri Laksono")]), _c('b-form-select-option', [_vm._v("Dendy Haidar")])];
                  },
                  proxy: true
                }], null, true)
              })], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Jenis Pekerjaan*",
                  "label-for": "jenis_pekerjaan"
                }
              }, [_c('b-form-select', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "plain": "",
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', [_vm._v("Pilih Jenis Pekerjaan")]), _c('b-form-select-option', [_vm._v("General Overhaul")]), _c('b-form-select-option', [_vm._v("Top Overhaul")])];
                  },
                  proxy: true
                }], null, true)
              })], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "TA. Shipyard*",
                  "label-for": "arrival_shipyard"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "date",
                  "id": "arrival_shipyard"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Floating Repair",
                  "label-for": "floating_repair"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "date",
                  "id": "floating_repair"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Up Slip *",
                  "label-for": "up_slip*"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "date",
                  "id": "up_slip"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Downslip",
                  "label-for": "downslip"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "date",
                  "id": "downslip"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Sea Trial",
                  "label-for": "sea_trail"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "date",
                  "id": "sea_trail"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Complete / Delivery",
                  "label-for": "delivery_date"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "date",
                  "id": "delivery_date"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "2"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Waiting Space",
                  "label-for": "waiting_space"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "number",
                  "readonly": "",
                  "id": "waiting_space",
                  "placeholder": "9"
                }
              })], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "2"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Target Days",
                  "label-for": "target_days"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "number",
                  "readonly": "",
                  "id": "target_days",
                  "placeholder": "60"
                }
              })], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Estimasi Tonase",
                  "label-for": "est_tonase"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('input', {
                staticClass: "form-control",
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "number",
                  "id": "est_tonase"
                }
              }), _c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Ton")])])])])], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Estimasi Budget",
                  "label-for": "est_budget"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "number",
                  "id": "est_budget"
                }
              })])])], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Dokumen Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Docking Job")]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "primary"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-percent"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Job Category "), _c('b', [_vm._v("100%")])])]), _c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.jobCat-1",
                  modifiers: {
                    "jobCat-1": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-primary",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-tasks"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking / Undocking "), _c('b', [_vm._v("(2 Jobs) - 30%")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "jobCat-1",
                  "accordion": "my-accordion",
                  "role": "tabpanel"
                }
              }, [_c('b-card-body', [_c('b-form-group', {
                attrs: {
                  "label": "Persentase Job Category*",
                  "label-for": "percent_job_category"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "percent_job_category"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "percent_job_category"
                }
              })])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-percent"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Job "), _c('b', [_vm._v("100%")])])]), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-1",
                  modifiers: {
                    "job-1-1": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-danger",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Upslip")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("50% (New)")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                staticClass: "p-2",
                attrs: {
                  "id": "job-1-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Upslip")]), _c('b-row', {
                staticClass: "mt-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Est. Start*",
                  "label-for": "est_start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "est_start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Est. Finish *",
                  "label-for": "end"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])])], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Job",
                  "label-for": "attachment_job"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File* : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg/.png/.xlx/.doc/.pdf")]), _c('br')], 1)])], 1), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Check water tubes/plates and clean/free from clogged."
                }
              })], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Nurul Akbar"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER")])], 1), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #2")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('b-button', {
                staticClass: "mb-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-2",
                  modifiers: {
                    "job-1-2": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-danger",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Downslip")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("50% (New)")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                staticClass: "p-2",
                attrs: {
                  "id": "job-1-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Downslip")]), _c('b-row', {
                staticClass: "mt-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Est. Start*",
                  "label-for": "est_start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "est_start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Est. Finish *",
                  "label-for": "end"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Bobot Pekerjaan *",
                  "label-for": "bobot_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])])], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Job",
                  "label-for": "attachment_job"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File* : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg/.png/.xlx/.doc/.pdf")]), _c('br')], 1)])], 1), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Check water tubes/plates and clean/free from clogged."
                }
              })], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Nurul Akbar"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER")])], 1), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #2")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('b-button', {
                staticClass: "mb-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR")])], 1)], 1)], 1)], 1)], 1), _c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.jobCat-2",
                  modifiers: {
                    "jobCat-2": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-primary",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-tasks"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Sand Blasting "), _c('b', [_vm._v("(4 Jobs) - 70%")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "jobCat-2",
                  "accordion": "my-accordion",
                  "role": "tabpanel"
                }
              }, [_c('b-card-body', [_c('b-form-group', {
                attrs: {
                  "label": "Persentase Job Category*",
                  "label-for": "percent_job_category"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "percent_job_category"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "percent_job_category"
                }
              })])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-percent"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Job "), _c('b', [_vm._v("100%")])])]), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-1",
                  modifiers: {
                    "job-2-1": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-danger",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Scraping Teripting di Lambung & Lunas")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("25% (New)")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                staticClass: "p-2",
                attrs: {
                  "id": "job-2-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Scraping Teripting di Lambung & Lunas")]), _c('b-row', {
                staticClass: "mt-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Est. Start*",
                  "label-for": "est_start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "est_start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Est. Finish *",
                  "label-for": "end"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Bobot Pekerjaan *",
                  "label-for": "bobot_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])])], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Job",
                  "label-for": "attachment_job"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File* : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg/.png/.xlx/.doc/.pdf")]), _c('br')], 1)])], 1), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Pencucian Lambung dengan Air Tawar"
                }
              })], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Nurul Akbar"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER")])], 1), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #2")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('b-button', {
                staticClass: "mb-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-2",
                  modifiers: {
                    "job-2-2": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-danger",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Pencucian Lambung dengan Air Tawar")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("25% (New)")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                staticClass: "p-2",
                attrs: {
                  "id": "job-2-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Pencucian Lambung dengan Air Tawar")]), _c('b-row', {
                staticClass: "mt-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Est. Start*",
                  "label-for": "est_start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "est_start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Est. Finish *",
                  "label-for": "end"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Bobot Pekerjaan *",
                  "label-for": "bobot_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])])], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Job",
                  "label-for": "attachment_job"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File* : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg/.png/.xlx/.doc/.pdf")]), _c('br')], 1)])], 1), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Pencucian Lambung dengan Air Tawar"
                }
              })], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Nurul Akbar"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER")])], 1), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #2")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('b-button', {
                staticClass: "mb-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-3",
                  modifiers: {
                    "job-2-3": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-danger",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Sand Blasting Lunas")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("25% (New)")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                staticClass: "p-2",
                attrs: {
                  "id": "job-2-3",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sand Blasting Lunas")]), _c('b-row', {
                staticClass: "mt-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Est. Start*",
                  "label-for": "est_start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "est_start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Est. Finish *",
                  "label-for": "end"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Bobot Pekerjaan *",
                  "label-for": "bobot_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])])], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Job",
                  "label-for": "attachment_job"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File* : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg/.png/.xlx/.doc/.pdf")]), _c('br')], 1)])], 1), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Sand Blasting Lunas"
                }
              })], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Nurul Akbar"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER")])], 1), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #2")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('b-button', {
                staticClass: "mb-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-4",
                  modifiers: {
                    "job-2-4": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-danger",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Sand Blasting Deck & Bullwark")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("25% (New)")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                staticClass: "p-2",
                attrs: {
                  "id": "job-2-4",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sand Blasting Deck & Bullwark")]), _c('b-row', {
                staticClass: "mt-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Est. Start*",
                  "label-for": "est_start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "est_start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Est. Finish *",
                  "label-for": "end"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Bobot Pekerjaan *",
                  "label-for": "bobot_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])])], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Job",
                  "label-for": "attachment_job"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File* : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg/.png/.xlx/.doc/.pdf")]), _c('br')], 1)])], 1), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Sand Blasting Deck & Bullwark"
                }
              })], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Nurul Akbar"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER")])], 1), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #2")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('b-button', {
                staticClass: "mb-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR")])], 1)], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
                staticClass: "mt-3",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-card-text', {
                staticClass: "pull-right"
              }, [_c('small', {
                staticClass: "text-muted"
              }, [_vm._v("Sign * : "), _c('b-badge', {
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("Mandatory (Must be Input)")])], 1)]), _c('br'), _c('br'), _c('b-button', {
                staticClass: "pull-right",
                attrs: {
                  "type": "submit",
                  "variant": "danger"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" Reset Form")]), _c('b-button', {
                staticClass: "pull-right mr-1",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-save"
              }), _vm._v(" Save")])], 1)], 1)], 1)])], 1)];
            }
          }]),
          model: {
            value: _vm.modalCreateDocking,
            callback: function callback($$v) {
              _vm.modalCreateDocking = $$v;
            },
            expression: "modalCreateDocking"
          }
        })];
      },
      proxy: true
    }])
  }), _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("DAFTAR KAPAL BELUM TERSISTEM DOCKING")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', {
          staticClass: "mb-4"
        }, [_c('b-col', {
          staticClass: "text-right",
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Search fleets..."
          },
          on: {
            "input": _vm.fetchFleets
          },
          model: {
            value: _vm.fleet_params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.fleet_params, "search", $$v);
            },
            expression: "fleet_params.search"
          }
        })], 1)], 1), _c('hr'), _c('Slick', {
          attrs: {
            "option": _vm.slickOptions,
            "id": "doster-list-slide"
          }
        }, _vm._l(_vm.fleetList, function (fleetDetail) {
          return _c('div', {
            key: fleetDetail.id,
            staticClass: "docter-list-item-inner rounded text-center"
          }, [_c('div', {
            staticClass: "donter-profile"
          }, [_c('img', {
            staticClass: "img-fluid rounded-circle",
            attrs: {
              "src": fleetDetail.img,
              "alt": "user-img"
            }
          })]), _c('div', {
            staticClass: "doctor-detail mt-3"
          }, [_c('h5', [_vm._v(_vm._s(fleetDetail.name))]), _c('h6', [_vm._v(_vm._s(fleetDetail.position))])]), _c('hr'), _c('b-button', {
            directives: [{
              name: "b-modal",
              rawName: "v-b-modal.set_schedule",
              modifiers: {
                "set_schedule": true
              }
            }],
            staticClass: "mb-1",
            attrs: {
              "size": "sm",
              "variant": "danger"
            }
          }, [_c('i', {
            staticClass: "fa fa-ship"
          }), _vm._v("Set Intermediate Survey")]), _c('b-button', {
            directives: [{
              name: "b-modal",
              rawName: "v-b-modal.set_schedule",
              modifiers: {
                "set_schedule": true
              }
            }],
            attrs: {
              "size": "sm",
              "variant": "primary"
            }
          }, [_c('i', {
            staticClass: "fa fa-ship"
          }), _vm._v("Set Special Survey")])], 1);
        }), 0)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "progress"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("DOCKING PROGRESS")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', {
          staticClass: "mb-3"
        }, [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "tag-placeholder": "Category",
            "placeholder": "Fleet Category",
            "label": "text",
            "track-by": "value",
            "options": _vm.fleetCategory,
            "multiple": true,
            "taggable": true
          },
          model: {
            value: _vm.fleetCategorySelect,
            callback: function callback($$v) {
              _vm.fleetCategorySelect = $$v;
            },
            expression: "fleetCategorySelect"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "size": "sm"
          }
        }, [_c('b-form-select-option', [_vm._v("Select Fleet")]), _c('b-form-select-option', [_vm._v("TB. TAVIA 1")]), _c('b-form-select-option', [_vm._v("TB. TAVIA 2")]), _c('b-form-select-option', [_vm._v("TB. TAVIA 3")]), _c('b-form-select-option', [_vm._v("TB. TAVIA 4")]), _c('b-form-select-option', [_vm._v("BG. TAVIA 5")]), _c('b-form-select-option', [_vm._v("BG. TAVIA 6")])], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.docking_type,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Docking Type")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedDockingType,
            callback: function callback($$v) {
              _vm.selectedDockingType = $$v;
            },
            expression: "selectedDockingType"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.dockingStatus,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Status")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedDockingStatus,
            callback: function callback($$v) {
              _vm.selectedDockingStatus = $$v;
            },
            expression: "selectedDockingStatus"
          }
        })], 1), _c('b-col', {
          staticClass: "mt-3",
          attrs: {
            "md": "3"
          }
        }, [_c('date-picker', {
          attrs: {
            "lang": _vm.lang,
            "type": "date",
            "range": "",
            "placeholder": "Select date range",
            "size": "sm"
          },
          model: {
            value: _vm.dateRange,
            callback: function callback($$v) {
              _vm.dateRange = $$v;
            },
            expression: "dateRange"
          }
        })], 1), _c('b-col', {
          staticClass: "mt-3",
          attrs: {
            "md": "9"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "primary",
            "block": "",
            "size": "sm"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter mr-2"
        }), _vm._v(" Filter")])], 1)], 1), _c('table', {
          staticClass: "table table-striped table-sm"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("NO")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("FLEET")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("DOCKING TYPE")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("SHIPYARD")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("UPLSIP")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("COMPLETE DATE")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("JOB")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("STATUS")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ACTION")])])]), _c('tbody', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("1")]), _c('td', {
          staticClass: "text-center"
        }, [_c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              _vm.slideDetailDocking = !_vm.slideDetailDocking;
            }
          }
        }, [_vm._v("TB. TAVIA 1")])]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("INTERMEDIATE SURVEY")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("PT. CAHAYA BAHARI SHIPYARD")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2017-05-10")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2022-06-12")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("0/20")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "warning d-block"
          }
        }, [_vm._v("NEED REVIEW")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "warning"
          },
          on: {
            "click": function click($event) {
              _vm.slideReviewDocking = !_vm.slideReviewDocking;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-search mr-2"
        }), _vm._v("REVIEW")]), _c('b-dropdown-item', {
          attrs: {
            "variant": "dark"
          }
        }, [_c('router-link', {
          attrs: {
            "to": "/templates/docking-history"
          }
        }, [_c('i', {
          staticClass: "fa fa-history mr-2"
        }), _vm._v("HISTORY DOCKING")])], 1), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "danger"
          }
        }, [_c('i', {
          staticClass: "fa fa-trash mr-2"
        }), _vm._v("DELETE")])], 1)], 1)]), _c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("2")]), _c('td', {
          staticClass: "text-center"
        }, [_c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              _vm.slideDetailDocking = !_vm.slideDetailDocking;
            }
          }
        }, [_vm._v("TB. TAVIA 2")])]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("SPECIAL SURVEY")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("PT. CITRA SHIPYARD INDONESIA")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2018-03-25")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2018-04-30")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("0/15")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "primary d-block"
          }
        }, [_vm._v("NEED APPROVE")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "success"
          },
          on: {
            "click": function click($event) {
              _vm.slideApproveDocking = !_vm.slideApproveDocking;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-check mr-2"
        }), _vm._v("APPROVE")]), _c('b-dropdown-item', {
          attrs: {
            "variant": "dark"
          }
        }, [_c('router-link', {
          attrs: {
            "to": "/templates/docking-history"
          }
        }, [_c('i', {
          staticClass: "fa fa-history mr-2"
        }), _vm._v("HISTORY DOCKING")])], 1), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "danger"
          }
        }, [_c('i', {
          staticClass: "fa fa-trash mr-2"
        }), _vm._v("DELETE")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "success"
          },
          on: {
            "click": function click($event) {
              _vm.slideDetailDocking = !_vm.slideDetailDocking;
            }
          }
        }, [_c('i', {
          staticClass: "fas fa-info-circle"
        }), _vm._v(" DETAIL")])], 1)], 1)]), _c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("3")]), _c('td', {
          staticClass: "text-center"
        }, [_c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              _vm.slideDetailDocking = !_vm.slideDetailDocking;
            }
          }
        }, [_vm._v("TB. TAVIA 3")])]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("INTERMEDIATE SURVEY")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("PT. SARANA SHIPYARD BAHARI")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2020-01-15")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2020-02-20")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("0/15")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "info d-block"
          }
        }, [_vm._v("ON PROGRESS")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "variant": "info"
          },
          on: {
            "click": function click($event) {
              _vm.slideActivity = !_vm.slideActivity;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-comments mr-2"
        }), _vm._v("ACTIVITY")]), _c('b-dropdown-item', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.slideUpdateDocking = !_vm.slideUpdateDocking;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus-circle mr-2"
        }), _vm._v("ADD JOB")]), _c('b-dropdown-item', {
          attrs: {
            "variant": "dark"
          }
        }, [_c('router-link', {
          attrs: {
            "to": "/templates/docking-history"
          }
        }, [_c('i', {
          staticClass: "fa fa-history mr-2"
        }), _vm._v("HISTORY DOCKING")])], 1), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "danger"
          }
        }, [_c('i', {
          staticClass: "fa fa-trash mr-2"
        }), _vm._v("DELETE")])], 1)], 1)]), _c('tr', {
          staticClass: "bg-danger"
        }, [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("4")]), _c('td', {
          staticClass: "text-center"
        }, [_c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              _vm.slideDetailDocking = !_vm.slideDetailDocking;
            }
          }
        }, [_vm._v("TB. TAVIA 4")])]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("INTERMEDIATE SURVEY")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("PT. SARANA SHIPYARD BAHARI")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2020-01-15")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2020-02-20")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("0/15")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "light d-block"
          }
        }, [_vm._v("REJECT")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              _vm.slideUpdateDocking = !_vm.slideUpdateDocking;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-percent mr-2"
        }), _vm._v("UPDATE DOCKING")]), _c('b-dropdown-item', {
          attrs: {
            "variant": "dark"
          }
        }, [_c('router-link', {
          attrs: {
            "to": "/templates/docking-history"
          }
        }, [_c('i', {
          staticClass: "fa fa-history mr-2"
        }), _vm._v("HISTORY DOCKING")])], 1), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "danger"
          }
        }, [_c('i', {
          staticClass: "fa fa-trash mr-2"
        }), _vm._v("DELETE")])], 1)], 1)]), _c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("5")]), _c('td', {
          staticClass: "text-center"
        }, [_c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              _vm.slideDetailDocking = !_vm.slideDetailDocking;
            }
          }
        }, [_vm._v("TB. TAVIA 5")])]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("INTERMEDIATE SURVEY")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("PT. CITRA SHIPYARD INDONESIA")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2018-03-25")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2018-04-30")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("15/15")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "success d-block"
          }
        }, [_vm._v("SEA TRIAL")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "success"
          }
        }, [_c('router-link', {
          attrs: {
            "to": "docking-sea-trial"
          }
        }, [_c('i', {
          staticClass: "fa fa-ship mr-2"
        }), _vm._v("SEA TRIAL")])], 1), _c('b-dropdown-item', {
          attrs: {
            "variant": "dark"
          }
        }, [_c('router-link', {
          attrs: {
            "to": "/templates/docking-history"
          }
        }, [_c('i', {
          staticClass: "fa fa-history mr-2"
        }), _vm._v("HISTORY DOCKING")])], 1), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "danger"
          }
        }, [_c('i', {
          staticClass: "fa fa-trash mr-2"
        }), _vm._v("DELETE")])], 1)], 1)]), _c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("6")]), _c('td', {
          staticClass: "text-center"
        }, [_c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              _vm.slideDetailDocking = !_vm.slideDetailDocking;
            }
          }
        }, [_vm._v("TB. TAVIA 6")])]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("INTERMEDIATE SURVEY")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("PT. CITRA SHIPYARD INDONESIA")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2018-03-25")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2018-04-30")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("15/15")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "primary d-block"
          }
        }, [_vm._v("SERAH TERIMA")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "primary"
          }
        }, [_c('router-link', {
          attrs: {
            "to": "docking-serah-terima"
          }
        }, [_c('i', {
          staticClass: "fas fa-handshake mr-2"
        }), _vm._v("SERAH TERIMA")])], 1), _c('b-dropdown-item', {
          attrs: {
            "variant": "dark"
          }
        }, [_c('router-link', {
          attrs: {
            "to": "/templates/docking-history"
          }
        }, [_c('i', {
          staticClass: "fa fa-history mr-2"
        }), _vm._v("HISTORY DOCKING")])], 1), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "danger"
          }
        }, [_c('i', {
          staticClass: "fa fa-trash mr-2"
        }), _vm._v("DELETE")])], 1)], 1)])])]), _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "value": 1,
            "total-rows": 50,
            "align": "center"
          }
        })], 1), _c('b-sidebar', {
          attrs: {
            "id": "review_docking",
            "title": "FORM REVIEW DOCKING - TB. TAVIA 1",
            "width": "100%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var hide = _ref3.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "cols": "4"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Job List")]), _c('b-form-input', {
                attrs: {
                  "id": "job_name",
                  "type": "text",
                  "placeholder": "Search Job Name"
                }
              }), _c('v-jstree', {
                staticClass: "mt-3",
                attrs: {
                  "data": _vm.tree,
                  "show-checkbox": "",
                  "allow-batch": "",
                  "whole-row": "",
                  "item-events": _vm.itemEvents
                },
                on: {
                  "item-click": _vm.itemClick
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_) {
                    return [_c('div', {
                      staticStyle: {
                        "display": "inherit",
                        "width": "200px"
                      },
                      on: {
                        "click": function click($event) {
                          if (!$event.ctrlKey) return null;
                          return _vm.customItemClickWithCtrl.apply(null, arguments);
                        }
                      }
                    }, [!_.model.loading ? _c('i', {
                      class: _.vm.themeIconClasses,
                      attrs: {
                        "role": "presentation"
                      }
                    }) : _vm._e(), _vm._v(" " + _vm._s(_.model.text) + " ")])];
                  }
                }], null, true)
              }), _c('b-button', {
                attrs: {
                  "block": "",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-plus"
              }), _vm._v("ADD JOB")])], 1)]), _c('b-col', {
                attrs: {
                  "cols": "8"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Docking Info")]), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Nama Shipyard*",
                  "label-for": "shipyard"
                }
              }, [_c('b-form-select', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "plain": "",
                  "options": _vm.shipyard,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Pilih Shipyard")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedShipyard,
                  callback: function callback($$v) {
                    _vm.selectedShipyard = $$v;
                  },
                  expression: "selectedShipyard"
                }
              })], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "PIC*",
                  "label-for": "pic"
                }
              }, [_c('b-form-select', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "plain": "",
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', [_vm._v("Pilih PIC")]), _c('b-form-select-option', [_vm._v("Nurul Akbar")]), _c('b-form-select-option', [_vm._v("Agung Tri Laksono")]), _c('b-form-select-option', [_vm._v("Dendy Haidar")])];
                  },
                  proxy: true
                }], null, true)
              })], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Jenis Pekerjaan*",
                  "label-for": "jenis_pekerjaan"
                }
              }, [_c('b-form-select', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "plain": "",
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', [_vm._v("Pilih Jenis Pekerjaan")]), _c('b-form-select-option', [_vm._v("General Overhaul")]), _c('b-form-select-option', [_vm._v("Top Overhaul")])];
                  },
                  proxy: true
                }], null, true)
              })], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "TA. Shipyard*",
                  "label-for": "arrival_shipyard"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "date",
                  "id": "arrival_shipyard"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Floating Repair",
                  "label-for": "floating_repair"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "date",
                  "id": "floating_repair"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Up Slip*",
                  "label-for": "up_slip*"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "date",
                  "id": "up_slip"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Downslip",
                  "label-for": "downslip"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "date",
                  "id": "downslip"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Sea Trial",
                  "label-for": "sea_trail"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "date",
                  "id": "sea_trail"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Complete / Delivery",
                  "label-for": "delivery_date"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "date",
                  "id": "delivery_date"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "2"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Waiting Space",
                  "label-for": "waiting_space"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "number",
                  "readonly": "",
                  "id": "waiting_space",
                  "placeholder": "9"
                }
              })], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "2"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Target Days",
                  "label-for": "target_days"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "number",
                  "readonly": "",
                  "id": "target_days",
                  "placeholder": "60"
                }
              })], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Estimasi Tonase",
                  "label-for": "est_tonase"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('input', {
                staticClass: "form-control",
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "number",
                  "id": "est_tonase"
                }
              }), _c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Ton")])])])])], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Estimasi Budget",
                  "label-for": "est_budget"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "number",
                  "id": "est_budget"
                }
              })])])], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Dokumen Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Docking Job")]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "primary"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-percent"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Job Category "), _c('b', [_vm._v("100%")])])]), _c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.jobCat-1",
                  modifiers: {
                    "jobCat-1": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-primary",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-tasks"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking / Undocking "), _c('b', [_vm._v("(2 Jobs) - 30%")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "jobCat-1",
                  "accordion": "my-accordion",
                  "role": "tabpanel"
                }
              }, [_c('b-card-body', [_c('b-form-group', {
                attrs: {
                  "label": "Persentase Job Category*",
                  "label-for": "percent_job_category"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "percent_job_category"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "percent_job_category"
                }
              })])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-percent"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Job "), _c('b', [_vm._v("100%")])])]), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-1",
                  modifiers: {
                    "job-1-1": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-danger",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Upslip")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("50% (New)")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                staticClass: "p-2",
                attrs: {
                  "id": "job-1-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Upslip")]), _c('b-row', {
                staticClass: "mt-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Est. Start*",
                  "label-for": "est_start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "est_start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Est. Finish *",
                  "label-for": "end"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Bobot Pekerjaan *",
                  "label-for": "bobot_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])])], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Job",
                  "label-for": "attachment_job"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File* : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg/.png/.xlx/.doc/.pdf")]), _c('br')], 1)])], 1), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Check water tubes/plates and clean/free from clogged."
                }
              })], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Nurul Akbar"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER")])], 1), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #2")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('b-button', {
                staticClass: "mb-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-2",
                  modifiers: {
                    "job-1-2": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-danger",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Downslip")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("50% (New)")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                staticClass: "p-2",
                attrs: {
                  "id": "job-1-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Downslip")]), _c('b-row', {
                staticClass: "mt-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Est. Start*",
                  "label-for": "est_start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "est_start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Est. Finish *",
                  "label-for": "end"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Bobot Pekerjaan *",
                  "label-for": "bobot_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])])], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Job",
                  "label-for": "attachment_job"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File* : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg/.png/.xlx/.doc/.pdf")]), _c('br')], 1)])], 1), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Check water tubes/plates and clean/free from clogged."
                }
              })], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Nurul Akbar"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER")])], 1), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #2")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('b-button', {
                staticClass: "mb-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR")])], 1)], 1)], 1)], 1)], 1), _c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.jobCat-2",
                  modifiers: {
                    "jobCat-2": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-primary",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-tasks"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Sand Blasting "), _c('b', [_vm._v("(4 Jobs) - 70%")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "jobCat-2",
                  "accordion": "my-accordion",
                  "role": "tabpanel"
                }
              }, [_c('b-card-body', [_c('b-form-group', {
                attrs: {
                  "label": "Persentase Job Category*",
                  "label-for": "percent_job_category"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "percent_job_category"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "percent_job_category"
                }
              })])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-percent"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Job "), _c('b', [_vm._v("100%")])])]), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-1",
                  modifiers: {
                    "job-2-1": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-danger",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Scraping Teripting di Lambung & Lunas")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("25% (New)")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                staticClass: "p-2",
                attrs: {
                  "id": "job-2-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Scraping Teripting di Lambung & Lunas")]), _c('b-row', {
                staticClass: "mt-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Est. Start*",
                  "label-for": "est_start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "est_start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Est. Finish *",
                  "label-for": "end"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Bobot Pekerjaan *",
                  "label-for": "bobot_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])])], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Job",
                  "label-for": "attachment_job"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File* : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg/.png/.xlx/.doc/.pdf")]), _c('br')], 1)])], 1), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Scraping Teripting di Lambung & Lunas"
                }
              })], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Nurul Akbar"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER")])], 1), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('b-button', {
                staticClass: "mb-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-2",
                  modifiers: {
                    "job-2-2": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-danger",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Pencucian Lambung dengan Air Tawar")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("25% (New)")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                staticClass: "p-2",
                attrs: {
                  "id": "job-2-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Pencucian Lambung dengan Air Tawar")]), _c('b-row', {
                staticClass: "mt-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Est. Start*",
                  "label-for": "est_start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "est_start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Est. Finish *",
                  "label-for": "end"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Bobot Pekerjaan *",
                  "label-for": "bobot_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])])], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Job",
                  "label-for": "attachment_job"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File* : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg/.png/.xlx/.doc/.pdf")]), _c('br')], 1)])], 1), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Pencucian Lambung dengan Air Tawar"
                }
              })], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Nurul Akbar"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER")])], 1), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #2")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('b-button', {
                staticClass: "mb-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-3",
                  modifiers: {
                    "job-2-3": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-danger",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Sand Blasting Lunas")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("25% (New)")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                staticClass: "p-2",
                attrs: {
                  "id": "job-2-3",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sand Blasting Lunas")]), _c('b-row', {
                staticClass: "mt-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Est. Start*",
                  "label-for": "est_start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "est_start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Est. Finish *",
                  "label-for": "end"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Bobot Pekerjaan *",
                  "label-for": "bobot_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])])], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Job",
                  "label-for": "attachment_job"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File* : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg/.png/.xlx/.doc/.pdf")]), _c('br')], 1)])], 1), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Sand Blasting Lunas"
                }
              })], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Nurul Akbar"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER")])], 1), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #2")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('b-button', {
                staticClass: "mb-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-4",
                  modifiers: {
                    "job-2-4": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-danger",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Sand Blasting Deck & Bullwark")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("25% (New)")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                staticClass: "p-2",
                attrs: {
                  "id": "job-2-4",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sand Blasting Deck & Bullwark")]), _c('b-row', {
                staticClass: "mt-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Est. Start*",
                  "label-for": "est_start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "est_start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Est. Finish *",
                  "label-for": "end"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Bobot Pekerjaan *",
                  "label-for": "bobot_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])])], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Job",
                  "label-for": "attachment_job"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File* : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg/.png/.xlx/.doc/.pdf")]), _c('br')], 1)])], 1), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Sand Blasting Deck & Bullwark"
                }
              })], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Nurul Akbar"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER")])], 1), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #2")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('b-button', {
                staticClass: "mb-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR")])], 1)], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
                staticClass: "mt-3",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-card-text', {
                staticClass: "pull-right"
              }, [_c('small', {
                staticClass: "text-muted"
              }, [_vm._v("Sign * : "), _c('b-badge', {
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("Mandatory (Must be Input)")])], 1)]), _c('br'), _c('br'), _c('b-button', {
                staticClass: "pull-right",
                attrs: {
                  "type": "submit",
                  "variant": "danger"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" Cancel")]), _c('b-button', {
                staticClass: "pull-right mr-1",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-save"
              }), _vm._v(" Review")])], 1)], 1)], 1)])], 1)];
            }
          }]),
          model: {
            value: _vm.slideReviewDocking,
            callback: function callback($$v) {
              _vm.slideReviewDocking = $$v;
            },
            expression: "slideReviewDocking"
          }
        }), _c('b-sidebar', {
          attrs: {
            "id": "update_docking",
            "title": "UPDATE DOCKING - TB. TAVIA",
            "width": "100%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var hide = _ref4.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "cols": "4"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Job List")]), _c('b-form-input', {
                attrs: {
                  "id": "job_name",
                  "type": "text",
                  "placeholder": "Search Job Name"
                }
              }), _c('v-jstree', {
                staticClass: "mt-3",
                attrs: {
                  "data": _vm.tree,
                  "show-checkbox": "",
                  "allow-batch": "",
                  "whole-row": "",
                  "item-events": _vm.itemEvents
                },
                on: {
                  "item-click": _vm.itemClick
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_) {
                    return [_c('div', {
                      staticStyle: {
                        "display": "inherit",
                        "width": "200px"
                      },
                      on: {
                        "click": function click($event) {
                          if (!$event.ctrlKey) return null;
                          return _vm.customItemClickWithCtrl.apply(null, arguments);
                        }
                      }
                    }, [!_.model.loading ? _c('i', {
                      class: _.vm.themeIconClasses,
                      attrs: {
                        "role": "presentation"
                      }
                    }) : _vm._e(), _vm._v(" " + _vm._s(_.model.text) + " ")])];
                  }
                }], null, true)
              }), _c('b-button', {
                attrs: {
                  "block": "",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-plus"
              }), _vm._v("ADD JOB")])], 1)]), _c('b-col', {
                attrs: {
                  "cols": "8"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Docking Info")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Shipyard*")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Samudara Indonesia, PT")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("PIC*")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Jenis Pekerjaan*")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("General Overhaul")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Estimasi Budget")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp. 256.000.000,-")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("TA. Shipyard*")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("07/06/2023")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Floating Repair")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("15/06/2023")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Upslip*")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("04/07/2023")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Downslip")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("30/09/2023")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Sea Trial")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("27 Oktober 2023")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Delivery")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("31 Oktober 2023")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Waiting Space")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("5 Hari")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Target Days")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("60 Hari")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Estimasi Tonase")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("8.9")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen Shipyard")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Docking Job")]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "primary"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-percent"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Job Category "), _c('b', [_vm._v("100%")])])]), _c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.jobCat-1",
                  modifiers: {
                    "jobCat-1": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-primary",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-tasks"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking / Undocking "), _c('b', [_vm._v("(2 Jobs) - 30%")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "jobCat-1",
                  "accordion": "my-accordion",
                  "role": "tabpanel"
                }
              }, [_c('b-card-body', [_c('b-form-group', {
                attrs: {
                  "label": "Persentase Job Category*",
                  "label-for": "percent_job_category"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "percent_job_category"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "percent_job_category"
                }
              })])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-percent"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Job "), _c('b', [_vm._v("100%")])])]), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-1",
                  modifiers: {
                    "job-1-1": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-success",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Upslip")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("20% (Finish)")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                staticClass: "p-2",
                attrs: {
                  "id": "job-1-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Upslip")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-group', [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])])], 1), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Agung Tri Laksono")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("First Engineer")])]), _c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Second Engineer")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor ")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. CAHAYA PELAYARAN")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("328.000.000")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Nego")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("325.000.000")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v("Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Document")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #2")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor ")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. INTAN BAHARI")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Heri Budianto")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("178.000.000")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Nego")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("175.000.000")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v("Perbaikan lambung")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Document")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])])])], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-2",
                  modifiers: {
                    "job-1-2": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-info",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Downslip")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("20% (Progress)")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                staticClass: "p-2",
                attrs: {
                  "id": "job-1-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Downslip")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Agung Tri Laksono")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("First Engineer")])]), _c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Second Engineer")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor ")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. CAHAYA PELAYARAN")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("328.000.000")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Nego")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("325.000.000")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v("Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Document")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])])])], 1)], 1)], 1)], 1), _c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.jobCat-2",
                  modifiers: {
                    "jobCat-2": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-primary",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-tasks"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Sand Blasting "), _c('b', [_vm._v("(4 Jobs) - 70%")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "jobCat-2",
                  "accordion": "my-accordion",
                  "role": "tabpanel"
                }
              }, [_c('b-card-body', [_c('b-form-group', {
                attrs: {
                  "label": "Persentase Job Category*",
                  "label-for": "percent_job_category"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "percent_job_category"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "percent_job_category"
                }
              })])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-percent"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Job "), _c('b', [_vm._v("100%")])])]), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-1",
                  modifiers: {
                    "job-2-1": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-success",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Scraping Teripting di Lambung & Lunas")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("20% (Finish)")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                staticClass: "p-2",
                attrs: {
                  "id": "job-2-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Scraping Teripting di Lambung & Lunas")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Agung Tri Laksono")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("First Engineer")])]), _c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Second Engineer")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor ")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. CAHAYA PELAYARAN")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("328.000.000")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Nego")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("325.000.000")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v("Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Document")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])])])], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-2",
                  modifiers: {
                    "job-2-2": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-success",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Pencucian Lambung dengan Air Tawar")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("20% (Finish)")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                staticClass: "p-2",
                attrs: {
                  "id": "job-2-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Pencucian Lambung dengan Air Tawar")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Agung Tri Laksono")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("First Engineer")])]), _c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Second Engineer")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor ")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. CAHAYA PELAYARAN")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("328.000.000")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Nego")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("325.000.000")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v("Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Document")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])])])], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-3",
                  modifiers: {
                    "job-2-3": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-info",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Sand Blasting Lunas")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("20% (Progress)")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                staticClass: "p-2",
                attrs: {
                  "id": "job-2-3",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sand Blasting Lunas")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-group', [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])])], 1), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Agung Tri Laksono")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("First Engineer")])]), _c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Second Engineer")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor ")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. CAHAYA PELAYARAN")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("328.000.000")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Nego")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("325.000.000")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v("Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Document")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])])])], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-4",
                  modifiers: {
                    "job-2-4": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-warning",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Sand Blasting Deck & Bullwark")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("20% (Review)")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                staticClass: "p-2",
                attrs: {
                  "id": "job-2-4",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sand Blasting Lunas")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-group', [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])])], 1), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Agung Tri Laksono")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("First Engineer")])]), _c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Second Engineer")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor ")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. CAHAYA PELAYARAN")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("328.000.000")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Nego")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("325.000.000")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v("Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Document")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])])])], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-5",
                  modifiers: {
                    "job-2-5": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-danger",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Sand Blasting Main Deck")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("20% (Add Job)")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                staticClass: "p-2",
                attrs: {
                  "id": "job-2-5",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sand Blasting Main Deck")]), _c('b-row', {
                staticClass: "mt-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Est. Start*",
                  "label-for": "est_start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "est_start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Est. Finish *",
                  "label-for": "end"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "end"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "bobot_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "bobot_pekerjaan"
                }
              })])])], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Job",
                  "label-for": "attachment_job"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File* : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg/.png/.xlx/.doc/.pdf")]), _c('br')], 1)])], 1), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Sand Blasting Deck & Bullwark"
                }
              })], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Nurul Akbar"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Internal Man Power *",
                  "label-for": "nama_internal_man_power"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Jabatan *",
                  "label-for": "jabatan"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.jabatan,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1)], 1)], 1), _c('b-button', {
                staticClass: "mt-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER")])], 1), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #2")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "PT. CAHAYA PELAYARAN"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Agung Tri Laksono"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "1.500.000"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "1.300.000"
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Subkontraktor perbaikan general electrical"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Attachment Shipyard",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1)], 1), _c('b-button', {
                staticClass: "mb-3",
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR")])], 1)], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
                staticClass: "mt-3",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-card-text', {
                staticClass: "pull-right"
              }, [_c('small', {
                staticClass: "text-muted"
              }, [_vm._v("Sign * : "), _c('b-badge', {
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("Mandatory (Must be Input)")])], 1)]), _c('br'), _c('br'), _c('b-button', {
                staticClass: "pull-right",
                attrs: {
                  "type": "submit",
                  "variant": "danger"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" Reset Form")]), _c('b-button', {
                staticClass: "pull-right mr-1",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-save"
              }), _vm._v(" Save")])], 1)], 1)], 1)])], 1)];
            }
          }]),
          model: {
            value: _vm.slideUpdateDocking,
            callback: function callback($$v) {
              _vm.slideUpdateDocking = $$v;
            },
            expression: "slideUpdateDocking"
          }
        }), _c('b-sidebar', {
          attrs: {
            "id": "approve_docking",
            "title": "FORM APPROVE DOCKING - TB. TAVIA 1",
            "width": "100%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var hide = _ref5.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "cols": "4"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Job List")]), _c('b-form-input', {
                attrs: {
                  "id": "job_name",
                  "type": "text",
                  "placeholder": "Search Job Name"
                }
              }), _c('v-jstree', {
                staticClass: "mt-3",
                attrs: {
                  "data": _vm.tree,
                  "show-checkbox": "",
                  "allow-batch": "",
                  "whole-row": "",
                  "item-events": _vm.itemEvents
                },
                on: {
                  "item-click": _vm.itemClick
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_) {
                    return [_c('div', {
                      staticStyle: {
                        "display": "inherit",
                        "width": "200px"
                      },
                      on: {
                        "click": function click($event) {
                          if (!$event.ctrlKey) return null;
                          return _vm.customItemClickWithCtrl.apply(null, arguments);
                        }
                      }
                    }, [!_.model.loading ? _c('i', {
                      class: _.vm.themeIconClasses,
                      attrs: {
                        "role": "presentation"
                      }
                    }) : _vm._e(), _vm._v(" " + _vm._s(_.model.text) + " ")])];
                  }
                }], null, true)
              })], 1)]), _c('b-col', {
                attrs: {
                  "cols": "8"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Docking Info")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Shipyard")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Samudara Indonesia, PT")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("PIC")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Jenis Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("General Overhaul")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Estimasi Budget")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp. 256.000.000,-")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("TA. Shipyard*")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "ta_shipyard"
                }
              })], 1), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Floating Repair")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "upslip"
                }
              })], 1)]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Upslip")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "upslip"
                }
              })], 1), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Downslip")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "est_downslip"
                }
              })], 1)]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Sea Trial")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "est_sea_trial"
                }
              })], 1), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Delivery")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "est_delivery"
                }
              })], 1)]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Waiting Space")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("5 Hari")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Target Days")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("60 Hari")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Estimasi Tonase")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("8.9")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen Shipyard")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Docking Job")]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "primary"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-percent"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Job Category "), _c('b', [_vm._v("100%")])])]), _c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.jobCat-1",
                  modifiers: {
                    "jobCat-1": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-primary",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-tasks"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking / Undocking "), _c('b', [_vm._v("(2 Jobs) - 30%")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "jobCat-1",
                  "accordion": "my-accordion",
                  "role": "tabpanel"
                }
              }, [_c('b-card-body', [_c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-percent"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Job "), _c('b', [_vm._v("100%")])])]), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-1",
                  modifiers: {
                    "job-1-1": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-warning",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Upslip")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("50% (Review)")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                staticClass: "p-2",
                attrs: {
                  "id": "job-1-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Upslip")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Agung Tri Laksono")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("First Engineer")])]), _c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Second Engineer")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor ")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. CAHAYA PELAYARAN")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("328.000.000")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Nego")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("325.000.000")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v("Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Document")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #2")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor ")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. INTAN BAHARI")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Heri Budianto")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("178.000.000")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Nego")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("175.000.000")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v("Perbaikan lambung")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Document")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-2",
                  modifiers: {
                    "job-1-2": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-warning",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Downslip")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("50% (Review)")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                staticClass: "p-2",
                attrs: {
                  "id": "job-1-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Downslip")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Agung Tri Laksono")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("First Engineer")])]), _c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Second Engineer")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor ")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. CAHAYA PELAYARAN")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("328.000.000")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Nego")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("325.000.000")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v("Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Document")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE")])], 1)], 1)], 1)], 1)], 1), _c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.jobCat-2",
                  modifiers: {
                    "jobCat-2": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-primary",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-tasks"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Sand Blasting "), _c('b', [_vm._v("(4 Jobs) - 70%")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "jobCat-2",
                  "accordion": "my-accordion",
                  "role": "tabpanel"
                }
              }, [_c('b-card-body', [_c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-percent"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Job "), _c('b', [_vm._v("100%")])])]), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-1",
                  modifiers: {
                    "job-2-1": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-warning",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Scraping Teripting di Lambung & Lunas")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("25% (Review)")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                staticClass: "p-2",
                attrs: {
                  "id": "job-2-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Scraping Teripting di Lambung & Lunas")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Agung Tri Laksono")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("First Engineer")])]), _c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Second Engineer")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor ")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. CAHAYA PELAYARAN")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("328.000.000")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Nego")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("325.000.000")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v("Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Document")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-2",
                  modifiers: {
                    "job-2-2": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-warning",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Pencucian Lambung dengan Air Tawar")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("25% (Review)")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                staticClass: "p-2",
                attrs: {
                  "id": "job-2-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Pencucian Lambung dengan Air Tawar")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Agung Tri Laksono")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("First Engineer")])]), _c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Second Engineer")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor ")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. CAHAYA PELAYARAN")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("328.000.000")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Nego")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("325.000.000")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v("Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Document")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-3",
                  modifiers: {
                    "job-2-3": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-warning",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Sand Blasting Lunas")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("25% (Review)")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                staticClass: "p-2",
                attrs: {
                  "id": "job-2-3",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sand Blasting Lunas")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Agung Tri Laksono")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("First Engineer")])]), _c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Second Engineer")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor ")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. CAHAYA PELAYARAN")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("328.000.000")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Nego")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("325.000.000")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v("Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Document")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-4",
                  modifiers: {
                    "job-2-4": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-warning",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Sand Blasting Deck & Bullwark")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("25% (Review)")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                staticClass: "p-2",
                attrs: {
                  "id": "job-2-4",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sand Blasting Deck & Bullwark")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Agung Tri Laksono")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("First Engineer")])]), _c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Second Engineer")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor ")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. CAHAYA PELAYARAN")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("328.000.000")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Nego")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("325.000.000")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v("Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Document")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE")])], 1)], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
                staticClass: "mt-3",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-card-text', {
                staticClass: "pull-right"
              }, [_c('small', {
                staticClass: "text-muted"
              }, [_vm._v("Sign * : "), _c('b-badge', {
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("Mandatory (Must be Input)")])], 1)]), _c('br'), _c('br'), _c('b-button', {
                staticClass: "pull-right",
                attrs: {
                  "type": "submit",
                  "variant": "danger"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" Cancel")]), _c('b-button', {
                staticClass: "pull-right mr-1",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-save"
              }), _vm._v(" Approve")])], 1)], 1)], 1)])], 1)];
            }
          }]),
          model: {
            value: _vm.slideApproveDocking,
            callback: function callback($$v) {
              _vm.slideApproveDocking = $$v;
            },
            expression: "slideApproveDocking"
          }
        }), _c('b-sidebar', {
          attrs: {
            "id": "activity",
            "title": "UPDATE ACTIVITY",
            "width": "100%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref6) {
              var hide = _ref6.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "7"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Docking Info")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Shipyard*")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Samudara Indonesia, PT")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("PIC*")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Jenis Pekerjaan*")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("General Overhaul")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Estimasi Budget")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp. 256.000.000,-")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("TA. Shipyard*")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("07/06/2023")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Floating Repair")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("15/06/2023")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Upslip*")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("04/07/2023")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Downslip")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("30/09/2023")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Sea Trial")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("27 Oktober 2023")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Delivery")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("31 Oktober 2023")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Waiting Space")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("5 Hari")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Target Days")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("60 Hari")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Estimasi Tonase")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("8.9")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen Shipyard")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Info Pekerjaan")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('table', {
                staticClass: "table table-striped table-sm"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("NAMA LENGKAP")]), _c('th', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("JABATAN")])]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_vm._v("Agung Tri Laksono")]), _c('td', [_vm._v("Manager Engineer")])]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_vm._v("Nurul Akbar")]), _c('td', [_vm._v("Senior Engineer")])]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_vm._v("Hanel Prilian")]), _c('td', [_vm._v("Engineer")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor ")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. CAHAYA PELAYARAN")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("328.000.000")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Nego")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("325.000.000")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v("Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Document")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #2")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor ")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. MARIBAYA")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Suhendar")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("276.600.000")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Nego")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("275.000.000")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v("Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kiri")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Document")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])])]), _c('b-col', {
                attrs: {
                  "md": "5"
                }
              }, [_c('tab-content-item', {
                attrs: {
                  "active": true,
                  "id": "power"
                }
              }, [_c('tab-nav', {
                attrs: {
                  "tabs": true
                }
              }, [_c('tab-nav-items', {
                attrs: {
                  "active": true,
                  "href": "#activity",
                  "title": "Activity"
                }
              }), _c('tab-nav-items', {
                attrs: {
                  "active": false,
                  "href": "#logs",
                  "title": "Logs"
                }
              })], 1), _c('div', {
                staticClass: "tab-content",
                attrs: {
                  "id": "myTabContent"
                }
              }, [_c('tab-content-item', {
                attrs: {
                  "active": true,
                  "id": "activity"
                }
              }, [_c('b-row', [_c('b-col', {
                staticClass: "mb-5",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Select Docking Job",
                  "label-for": "docking_job"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.docking_job
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("List Docking Job")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedDockingJob,
                  callback: function callback($$v) {
                    _vm.selectedDockingJob = $$v;
                  },
                  expression: "selectedDockingJob"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label-for": "progress_bar"
                }
              }, [_c('h4', {
                staticClass: "mb-2"
              }, [_vm._v("Progrees : "), _c('b-badge', {
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("80%")])], 1), _c('b-progress', {
                attrs: {
                  "value": 80,
                  "variant": "success",
                  "max": "100"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Start *",
                  "label-for": "start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Progress Pekerjaan *",
                  "label-for": "progress_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "progress_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "progress_pekerjaan",
                  "placeholder": "80"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Report Progress",
                  "label-for": "report_progress"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Check water tubes/plates and clean/free from clogged."
                }
              })], 1)], 1)], 1), _c('div', {
                staticClass: "form-group row align-items-center mt-3 m-0"
              }, [_c('div', {
                staticClass: "d-flex flex-grow-1 align-items-center"
              }, [_c('div', {
                staticClass: "send-panel"
              }, [_c('label', {
                staticClass: "mb-0 iq-bg-primary rounded",
                attrs: {
                  "for": "file"
                }
              }, [_c('input', {
                staticStyle: {
                  "display": "none"
                },
                attrs: {
                  "type": "file",
                  "id": "file"
                }
              }), _c('a', [_c('i', {
                staticClass: "ri-attachment-line"
              })])]), _c('label', {
                staticClass: "ml-2 mb-0 iq-bg-primary rounded"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0)"
                }
              }, [_c('i', {
                staticClass: "ri-gallery-line text-primary"
              })])])])]), _c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('div', {
                staticClass: "send-panel float-left"
              }, [_c('div', {
                staticClass: "send-btn"
              }, [_c('b-button', {
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-check"
              }), _vm._v(" Finish")]), _c('b-button', {
                staticClass: "ml-2",
                attrs: {
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-comment"
              }), _vm._v(" Update Activity")])], 1)])])])], 1), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('ul', {
                staticClass: "iq-timeline",
                staticStyle: {
                  "height": "100%"
                }
              }, [_c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("24 November 2019 15:45")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Melakukan inspeksi pada kerusakan")]), _c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/6.jpg"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/1.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/4.jpg"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/3.jpg"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("23 November 2019 21:25")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Barang tiba di galangan")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Tri Leksono")]), _c('br'), _c('small', [_vm._v("22 November 2019 10:12")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Menunggu sparepart tiba")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("21 November 2019 11:21")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Melengkapi dokumen perbaikan")]), _c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])], 1)], 1), _c('tab-content-item', {
                attrs: {
                  "active": false,
                  "id": "logs"
                }
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('ul', {
                staticClass: "iq-timeline",
                staticStyle: {
                  "height": "100%"
                }
              }, [_c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("19 Oktober 2019 21:25")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Docking Upslip - Finish")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("System")]), _c('br'), _c('small', [_vm._v("19 Oktober 2019 18:30")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("System mengirim email ke : "), _c('b-badge', {
                staticClass: "border border-primary text-primary ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_vm._v("aguuungtriilaksono@gmail.com")]), _c('b-badge', {
                staticClass: "border border-primary text-primary ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_vm._v("nurulakbar93@gmail.com")])], 1)])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("18 Oktober 2019 15:41")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Update Stage")]), _c('b-button', {
                staticClass: "mr-1",
                attrs: {
                  "variant": "primary"
                }
              }, [_vm._v("APPROVE")]), _c('i', {
                staticClass: "fa fa-arrow-circle-right",
                attrs: {
                  "aria-hidden": "true"
                }
              }), _c('b-button', {
                staticClass: "ml-1",
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("PROGRESS")])], 1)]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("System")]), _c('br'), _c('small', [_vm._v("19 Oktober 2019 18:30")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("System mengirim email ke : "), _c('b-badge', {
                staticClass: "border border-primary text-primary ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_vm._v("aguuungtriilaksono@gmail.com")]), _c('b-badge', {
                staticClass: "border border-primary text-primary ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_vm._v("nurulakbar93@gmail.com")])], 1)])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("18 Oktober 2019 15:41")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Update Stage")]), _c('b-button', {
                staticClass: "mr-1",
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("REVIEW")]), _c('i', {
                staticClass: "fa fa-arrow-circle-right",
                attrs: {
                  "aria-hidden": "true"
                }
              }), _c('b-button', {
                staticClass: "ml-1",
                attrs: {
                  "variant": "primary"
                }
              }, [_vm._v("APPROVE")])], 1)]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("System")]), _c('br'), _c('small', [_vm._v("19 Oktober 2019 18:30")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("System mengirim email ke : "), _c('b-badge', {
                staticClass: "border border-primary text-primary ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_vm._v("aguuungtriilaksono@gmail.com")]), _c('b-badge', {
                staticClass: "border border-primary text-primary ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_vm._v("nurulakbar93@gmail.com")])], 1)])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("18 Oktober 2019 15:41")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Update Stage")]), _c('b-button', {
                staticClass: "mr-1",
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("REVIEW")]), _c('i', {
                staticClass: "fa fa-arrow-circle-right",
                attrs: {
                  "aria-hidden": "true"
                }
              }), _c('b-button', {
                staticClass: "ml-1",
                attrs: {
                  "variant": "danger"
                }
              }, [_vm._v("REJECT")])], 1)]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("System")]), _c('br'), _c('small', [_vm._v("17 Oktober 2019 16:00")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("System mengirim email ke : "), _c('b-badge', {
                staticClass: "border border-primary text-primary ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_vm._v("aguuungtriilaksono@gmail.com")]), _c('b-badge', {
                staticClass: "border border-primary text-primary ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_vm._v("nurulakbar93@gmail.com")])], 1)])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("16 Oktober 2019 18:30")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Update Stage")]), _c('b-button', {
                staticClass: "mr-1",
                attrs: {
                  "variant": "light"
                }
              }, [_vm._v("CREATE")]), _c('i', {
                staticClass: "fa fa-arrow-circle-right",
                attrs: {
                  "aria-hidden": "true"
                }
              }), _c('b-button', {
                staticClass: "ml-1",
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("REVIEW")])], 1)]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("15 Oktober 2019 20:24")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Mengupdate Job Upslip")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("15 Oktober 2019 16:00")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Membuat Job Upslip")])])])])])], 1)], 1)], 1)], 1)], 1)], 1)];
            }
          }]),
          model: {
            value: _vm.slideActivity,
            callback: function callback($$v) {
              _vm.slideActivity = $$v;
            },
            expression: "slideActivity"
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "finish"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("DOCKING FINISH")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', {
          staticClass: "mb-3"
        }, [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "tag-placeholder": "Category",
            "placeholder": "Fleet Category",
            "label": "text",
            "track-by": "value",
            "options": _vm.fleetCategory,
            "multiple": true,
            "taggable": true
          },
          model: {
            value: _vm.fleetCategorySelect,
            callback: function callback($$v) {
              _vm.fleetCategorySelect = $$v;
            },
            expression: "fleetCategorySelect"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.docking_type,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Docking Type")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedDockingType,
            callback: function callback($$v) {
              _vm.selectedDockingType = $$v;
            },
            expression: "selectedDockingType"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('date-picker', {
          attrs: {
            "lang": _vm.lang,
            "type": "date",
            "range": "",
            "placeholder": "Select date range",
            "size": "sm"
          },
          model: {
            value: _vm.dateRange,
            callback: function callback($$v) {
              _vm.dateRange = $$v;
            },
            expression: "dateRange"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "primary",
            "size": "sm"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1), _c('table', {
          staticClass: "table table-striped table-sm"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("FLEET NAME")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TOTAL COST")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TOTAL JOBS")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("PERFOMANCE INDICATOR")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("INTERMEDIATE SURVEY")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("SPESIAL SURVEY")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("EMERGENCY DOCKING")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ACTION")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. TAVIA 1")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Rp. 1.238.203.200,-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("53 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          staticClass: "mr-1",
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("On Time : 45 Jobs")]), _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("Delay : 23 Jobs")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("15 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("31 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("22 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "sm",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_c('router-link', {
          attrs: {
            "to": "/templates/docking-history"
          }
        }, [_c('i', {
          staticClass: "fa fa-ship mr-2"
        }), _vm._v("Detail History ")])], 1)], 1)], 1)]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. TAVIA 2")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Rp. 1.375.123.180,-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("36 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          staticClass: "mr-1",
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("On Time : 32 Jobs")]), _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("Delay : 4 Jobs")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("15 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("12 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("16 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "sm",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_c('router-link', {
          attrs: {
            "to": "/templates/docking-history"
          }
        }, [_c('i', {
          staticClass: "fa fa-ship mr-2"
        }), _vm._v("Detail History ")])], 1)], 1)], 1)]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. TAVIA 3")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Rp. 1.387.384.000,-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("61 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          staticClass: "mr-1",
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("On Time : 52 Jobs")]), _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("Delay : 20 Jobs")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("21 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("17 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("19 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "sm",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_c('router-link', {
          attrs: {
            "to": "/templates/docking-history"
          }
        }, [_c('i', {
          staticClass: "fa fa-ship mr-2"
        }), _vm._v("Detail History ")])], 1)], 1)], 1)]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("BG. TAVIA 4 ")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Rp. 1.387.384.000,-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("72 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          staticClass: "mr-1",
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("On Time : 52 Jobs")]), _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("Delay : 20 Jobs")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("36 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("21 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("13 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "sm",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_c('router-link', {
          attrs: {
            "to": "/templates/docking-history"
          }
        }, [_c('i', {
          staticClass: "fa fa-ship mr-2"
        }), _vm._v("Detail History ")])], 1)], 1)], 1)])])]), _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "value": 1,
            "total-rows": 50,
            "align": "center"
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "report"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("DOCKING REPORT")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "tag-placeholder": "Category",
            "placeholder": "Fleet Category",
            "label": "text",
            "track-by": "value",
            "options": _vm.fleetCategory,
            "multiple": true,
            "taggable": true
          },
          model: {
            value: _vm.fleetCategorySelect,
            callback: function callback($$v) {
              _vm.fleetCategorySelect = $$v;
            },
            expression: "fleetCategorySelect"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "tag-placeholder": "Category",
            "placeholder": "Project Filter",
            "label": "text",
            "track-by": "value",
            "options": _vm.fleetCategory,
            "multiple": true,
            "taggable": true
          },
          model: {
            value: _vm.fleetCategorySelect,
            callback: function callback($$v) {
              _vm.fleetCategorySelect = $$v;
            },
            expression: "fleetCategorySelect"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', [_vm._v("Category Ownership")]), _c('b-form-select-option', [_vm._v("Chartered")]), _c('b-form-select-option', [_vm._v("Own Fleet")])];
            },
            proxy: true
          }])
        })], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('date-picker', {
          attrs: {
            "lang": _vm.lang,
            "type": "date",
            "range": "",
            "placeholder": "Select date range",
            "size": "sm"
          },
          model: {
            value: _vm.dateRange,
            callback: function callback($$v) {
              _vm.dateRange = $$v;
            },
            expression: "dateRange"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "primary",
            "size": "sm"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-primary rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-primary"
        }, [_c('i', {
          staticClass: "fa fa-ship"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h4', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_c('b', [_vm._v("7")])])]), _c('h6', {}, [_vm._v("Fleet On Docking")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-warning rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-warning"
        }, [_c('i', {
          staticClass: "fas fa-dollar-sign"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h4', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_c('b', [_vm._v("Rp. 3.552.017.210")])])]), _c('h6', {}, [_vm._v("Total Cost All Fleet")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-danger rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-danger"
        }, [_c('i', {
          staticClass: "fa fa-tools"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h4', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_c('b', [_vm._v("45")])])]), _c('h6', {}, [_vm._v("Total Job Docking")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-info rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-info"
        }, [_c('i', {
          staticClass: "fa fa-spinner"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h4', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_c('b', [_vm._v("36")])])]), _c('h6', {}, [_vm._v("On Progress Job")])])])];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_vm._l(_vm.costDocking, function (item, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "lg": "6"
      }
    }, [_c('iq-card', {
      scopedSlots: _vm._u([{
        key: "headerTitle",
        fn: function fn() {
          return [_c('h4', [_vm._v(_vm._s(item.title))])];
        },
        proxy: true
      }, {
        key: "body",
        fn: function fn() {
          return [_c('HighChart', {
            ref: item.type,
            refInFor: true,
            attrs: {
              "option": item.bodyData
            }
          })];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-row', [_vm._l(_vm.costTugboat, function (item, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "lg": "6"
      }
    }, [_c('iq-card', {
      scopedSlots: _vm._u([{
        key: "headerTitle",
        fn: function fn() {
          return [_c('h4', [_vm._v(_vm._s(item.title))])];
        },
        proxy: true
      }, {
        key: "body",
        fn: function fn() {
          return [_c('HighChart', {
            ref: item.type,
            refInFor: true,
            attrs: {
              "option": item.bodyData
            }
          })];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), _vm._l(_vm.costBarge, function (item, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "lg": "6"
      }
    }, [_c('iq-card', {
      scopedSlots: _vm._u([{
        key: "headerTitle",
        fn: function fn() {
          return [_c('h4', [_vm._v(_vm._s(item.title))])];
        },
        proxy: true
      }, {
        key: "body",
        fn: function fn() {
          return [_c('HighChart', {
            ref: item.type,
            refInFor: true,
            attrs: {
              "option": item.bodyData
            }
          })];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), _vm._l(_vm.costPusherTug, function (item, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "lg": "6"
      }
    }, [_c('iq-card', {
      scopedSlots: _vm._u([{
        key: "headerTitle",
        fn: function fn() {
          return [_c('h4', [_vm._v(_vm._s(item.title))])];
        },
        proxy: true
      }, {
        key: "body",
        fn: function fn() {
          return [_c('HighChart', {
            ref: item.type,
            refInFor: true,
            attrs: {
              "option": item.bodyData
            }
          })];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), _vm._l(_vm.costPusherBarge, function (item, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "lg": "6"
      }
    }, [_c('iq-card', {
      scopedSlots: _vm._u([{
        key: "headerTitle",
        fn: function fn() {
          return [_c('h4', [_vm._v(_vm._s(item.title))])];
        },
        proxy: true
      }, {
        key: "body",
        fn: function fn() {
          return [_c('HighChart', {
            ref: item.type,
            refInFor: true,
            attrs: {
              "option": item.bodyData
            }
          })];
        },
        proxy: true
      }], null, true)
    })], 1);
  })], 2)], 1), _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Performance Monthly Report")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "outline-primary"
          }
        }, [_vm._v(" 01-09-2023 s/d 30-09-2023 "), _c('b-badge', {
          staticClass: "ml-2",
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v("30 Days")])], 1), _c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("No")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Fleet Name")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TA. Arrival")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Star Floating")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Uplsip")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Delivery Date")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Waiting Space")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Target Days")]), _c('th', {
          staticClass: "text-center text-primary"
        }, [_vm._v("Performance Monthly")]), _c('th', {
          staticClass: "text-center text-danger"
        }, [_vm._v("Actual Docking Days")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Action")])])]), _c('tbody', [_c('tr', [_c('th', [_vm._v("#1")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. TAVIA 1")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("07/06/2023")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("15/06/2023")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("04/07/2023")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("38 Days")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("60 Days")]), _c('td', {
          staticClass: "text-center bg-primary"
        }, [_vm._v("30 Days")]), _c('td', {
          staticClass: "text-center bg-danger"
        }, [_vm._v("88 Days")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-edit m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "variant": "dark"
          }
        }, [_c('a', {
          attrs: {
            "href": ""
          },
          on: {
            "click": function click($event) {
              _vm.slideDetailDocking = !_vm.slideDetailDocking;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-ship mr-2"
        }), _vm._v("Detail")])]), _c('b-dropdown-item', {
          attrs: {
            "variant": "dark"
          }
        }, [_c('a', {
          attrs: {
            "href": ""
          },
          on: {
            "click": function click($event) {
              _vm.slideHistoryMonthlyReport = !_vm.slideHistoryMonthlyReport;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-history mr-2"
        }), _vm._v("Monthly Report")])])], 1)], 1)]), _c('tr', [_c('th', [_vm._v("#2")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. TAVIA 2")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("15/08/2022")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("18/08/2022")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("20/08/2022")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("04/09/2023")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("5 Days")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("60 Days")]), _c('td', {
          staticClass: "text-center bg-primary"
        }, [_vm._v("4 Days")]), _c('td', {
          staticClass: "text-center bg-danger"
        }, [_vm._v("15 Days")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-edit m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "variant": "dark"
          }
        }, [_c('a', {
          attrs: {
            "href": ""
          },
          on: {
            "click": function click($event) {
              _vm.slideDetailDocking = !_vm.slideDetailDocking;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-ship mr-2"
        }), _vm._v("Detail")])]), _c('b-dropdown-item', {
          attrs: {
            "variant": "dark"
          }
        }, [_c('a', {
          attrs: {
            "href": ""
          },
          on: {
            "click": function click($event) {
              _vm.slideHistoryMonthlyReport = !_vm.slideHistoryMonthlyReport;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-history mr-2"
        }), _vm._v("Monthly Report")])])], 1)], 1)]), _c('tr', [_c('th', [_vm._v("#3")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. TAVIA 3")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("23/07/2022")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("25/07/2022")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("31/07/2022")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("15/09/2023")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("6 Days")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("60 Days")]), _c('td', {
          staticClass: "text-center bg-primary"
        }, [_vm._v("15 Days")]), _c('td', {
          staticClass: "text-center bg-danger"
        }, [_vm._v("67 Days")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-edit m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "variant": "dark"
          }
        }, [_c('a', {
          attrs: {
            "href": ""
          },
          on: {
            "click": function click($event) {
              _vm.slideDetailDocking = !_vm.slideDetailDocking;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-ship mr-2"
        }), _vm._v("Detail")])]), _c('b-dropdown-item', {
          attrs: {
            "variant": "dark"
          }
        }, [_c('a', {
          attrs: {
            "href": ""
          },
          on: {
            "click": function click($event) {
              _vm.slideHistoryMonthlyReport = !_vm.slideHistoryMonthlyReport;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-history mr-2"
        }), _vm._v("Monthly Report")])])], 1)], 1)])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Matriks Man Power")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("Bulan")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Fleet Name")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Shipyardl")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Day Count")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Action")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("September")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. TAVIA 1")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Cirebon")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("27 Days")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-edit m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "variant": "dark"
          }
        }, [_c('a', {
          attrs: {
            "href": ""
          }
        }, [_c('i', {
          staticClass: "fa fa-users mr-2"
        }), _vm._v("Detail")])])], 1)], 1)]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("September")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. TAVIA 2")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Tegal")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("33 Days")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-edit m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "variant": "dark"
          }
        }, [_c('a', {
          attrs: {
            "href": ""
          }
        }, [_c('i', {
          staticClass: "fa fa-users mr-2"
        }), _vm._v("Detail")])])], 1)], 1)])])])];
      },
      proxy: true
    }])
  })], 1)], 2)], 1), _c('b-sidebar', {
    attrs: {
      "id": "detail_docking",
      "title": "DETAIL DOCKING - TB. TAVIA 1",
      "width": "60%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var hide = _ref7.hide;
        return [_c('b-row', {
          staticClass: "m-3"
        }, [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 text-primary"
        }, [_vm._v("Docking Info")]), _c('table', {
          staticClass: "table table-striped"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Nama Shipyard*")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Samudara Indonesia, PT")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("PIC*")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Nurul Akbar")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Jenis Pekerjaan*")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("General Overhaul")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Estimasi Budget")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Rp. 256.000.000,-")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("TA. Shipyard*")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("01 Oktober 2023")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Floating Repair")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("03 Oktober 2023")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Upslip*")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("05 Oktober 2023")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Downslip*")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("25 Oktober 2023")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Sea Trial")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("27 Oktober 2023")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Delivery")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("31 Oktober 2023")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Waiting Space")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("5 Hari")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Target Days")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("60 Hari")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Estimasi Tonase")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("8.9")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Dokumen Shipyard")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])])])])])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 text-primary"
        }, [_vm._v("Docking Job")]), _c('b-alert', {
          attrs: {
            "show": true,
            "variant": "primary"
          }
        }, [_c('div', {
          staticClass: "iq-alert-icon"
        }, [_c('i', {
          staticClass: "fa fa-percent"
        })]), _c('div', {
          staticClass: "iq-alert-text"
        }, [_vm._v("Docking Job Category "), _c('b', [_vm._v("100%")])])]), _c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.jobCat-1",
            modifiers: {
              "jobCat-1": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('b-alert', {
          staticClass: "text-white bg-primary",
          attrs: {
            "show": true
          }
        }, [_c('div', {
          staticClass: "iq-alert-icon"
        }, [_c('i', {
          staticClass: "fas fa-tasks"
        })]), _c('div', {
          staticClass: "iq-alert-text"
        }, [_vm._v("Docking / Undocking "), _c('b', [_vm._v("(2 Jobs) - 30%")])]), _c('a', {
          staticClass: "text-primary float-right text-white",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "far fa-hand-pointer"
        }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
          attrs: {
            "id": "jobCat-1",
            "accordion": "my-accordion",
            "role": "tabpanel"
          }
        }, [_c('b-card-body', [_c('b-alert', {
          attrs: {
            "show": true,
            "variant": "danger"
          }
        }, [_c('div', {
          staticClass: "iq-alert-icon"
        }, [_c('i', {
          staticClass: "fa fa-percent"
        })]), _c('div', {
          staticClass: "iq-alert-text"
        }, [_vm._v("Docking Job "), _c('b', [_vm._v("100%")])])]), _c('div', {
          staticClass: "accordion",
          attrs: {
            "role": "tablist"
          }
        }, [_c('b-card', {
          attrs: {
            "no-body": ""
          }
        }, [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.job-1-1",
            modifiers: {
              "job-1-1": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('b-alert', {
          staticClass: "text-white bg-success",
          attrs: {
            "show": true
          }
        }, [_c('div', {
          staticClass: "iq-alert-icon"
        }, [_c('i', {
          staticClass: "fas fa-clipboard-list"
        })]), _c('div', {
          staticClass: "iq-alert-text"
        }, [_c('b', [_vm._v("Upslip")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("50% (Finish)")])]), _c('a', {
          staticClass: "text-primary float-right text-white",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "far fa-hand-pointer"
        }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
          staticClass: "p-2",
          attrs: {
            "id": "job-1-1",
            "accordion": "my-accordion-sub",
            "role": "tabpanel"
          }
        }, [_c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Upslip")]), _c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Start Date")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("2022-06-10")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Est. Finish")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("50%")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Dokumen")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/excel.png"),
            "alt": "gallary-image"
          }
        })])])])])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Created By")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Nurul Akbar")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Review By")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
          staticClass: "align-top"
        }, [_vm._v("Notes")]), _c('td', {
          staticClass: "text-center align-top"
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Internal Man Power")]), _c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          staticClass: "pl-3",
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("Agung Tri Laksono")]), _c('td', {
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("First Engineer")])]), _c('tr', [_c('th', {
          staticClass: "pl-3",
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("Nurul Akbar")]), _c('td', {
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("Second Engineer")])])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Sub Kontraktor #1")]), _c('table', {
          staticClass: "table table-striped"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Nama Kontraktor ")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("PT. CAHAYA PELAYARAN")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Executive Engineer")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Penawaran")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("328.000.000")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Penawaran Nego")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("325.000.000")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Notes")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_vm._v("Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Document")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/excel.png"),
            "alt": "gallary-image"
          }
        })])])])])])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Sub Kontraktor #2")]), _c('table', {
          staticClass: "table table-striped"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Nama Kontraktor ")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("PT. INTAN BAHARI")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Executive Engineer")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Heri Budianto")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Penawaran")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("178.000.000")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Penawaran Nego")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("175.000.000")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Notes")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_vm._v("Perbaikan lambung")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Document")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/excel.png"),
            "alt": "gallary-image"
          }
        })])])])])])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Daily Progress")]), _c('b-col', {
          staticClass: "mb-5",
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Select Docking Job",
            "label-for": "docking_job"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.docking_job
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("List Docking Job")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.selectedDockingJob,
            callback: function callback($$v) {
              _vm.selectedDockingJob = $$v;
            },
            expression: "selectedDockingJob"
          }
        })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label-for": "progress_bar"
          }
        }, [_c('h4', {
          staticClass: "mb-2"
        }, [_vm._v("Progrees : "), _c('b-badge', {
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v("80%")])], 1), _c('b-progress', {
          attrs: {
            "value": 80,
            "variant": "success",
            "max": "100"
          }
        })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Start *",
            "label-for": "start"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date",
            "id": "start"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Progress Pekerjaan *",
            "label-for": "progress_pekerjaan"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "progress_pekerjaan"
          }
        }, [_vm._v("%")])]), _c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text",
            "id": "progress_pekerjaan",
            "placeholder": "80"
          }
        })])])], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Report Progress",
            "label-for": "report_progress"
          }
        }, [_c('div', {
          staticClass: "custom-file"
        }, [_c('input', {
          staticClass: "custom-file-input",
          attrs: {
            "type": "file",
            "id": "validatedCustomFile",
            "required": ""
          }
        }), _c('label', {
          staticClass: "custom-file-label",
          attrs: {
            "for": "validatedCustomFile"
          }
        }, [_vm._v("Browse file...")])]), _c('small', {
          staticClass: "text-muted"
        }, [_vm._v(" File Type * : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Notes",
            "label-for": "notes"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "description",
            "rows": "3",
            "placeholder": "Check water tubes/plates and clean/free from clogged."
          }
        })], 1)], 1)], 1), _c('div', {
          staticClass: "form-group row align-items-center mt-3 m-0"
        }, [_c('div', {
          staticClass: "d-flex flex-grow-1 align-items-center"
        }, [_c('div', {
          staticClass: "send-panel"
        }, [_c('label', {
          staticClass: "mb-0 iq-bg-primary rounded",
          attrs: {
            "for": "file"
          }
        }, [_c('input', {
          staticStyle: {
            "display": "none"
          },
          attrs: {
            "type": "file",
            "id": "file"
          }
        }), _c('a', [_c('i', {
          staticClass: "ri-attachment-line"
        })])]), _c('label', {
          staticClass: "ml-2 mb-0 iq-bg-primary rounded"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0)"
          }
        }, [_c('i', {
          staticClass: "ri-gallery-line text-primary"
        })])])])]), _c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('div', {
          staticClass: "send-panel float-left"
        }, [_c('div', {
          staticClass: "send-btn"
        }, [_c('b-button', {
          attrs: {
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "fa fa-check"
        }), _vm._v(" Finish")]), _c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-comment"
        }), _vm._v(" Update Activity")])], 1)])])])], 1), _c('ul', {
          staticClass: "iq-timeline",
          staticStyle: {
            "height": "100%"
          }
        }, [_c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("24 November 2019 15:45")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Melakukan inspeksi pada kerusakan")]), _c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/6.jpg"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/1.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/4.jpg"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/3.jpg"),
            "alt": "gallary-image"
          }
        })])])])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("23 November 2019 21:25")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Barang tiba di galangan")])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Tri Leksono")]), _c('br'), _c('small', [_vm._v("22 November 2019 10:12")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Menunggu sparepart tiba")])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("21 November 2019 11:21")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Melengkapi dokumen perbaikan")]), _c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/excel.png"),
            "alt": "gallary-image"
          }
        })])])])])])])], 1)])], 1), _c('b-card', {
          attrs: {
            "no-body": ""
          }
        }, [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.job-1-2",
            modifiers: {
              "job-1-2": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('b-alert', {
          staticClass: "text-white bg-info",
          attrs: {
            "show": true
          }
        }, [_c('div', {
          staticClass: "iq-alert-icon"
        }, [_c('i', {
          staticClass: "fas fa-clipboard-list"
        })]), _c('div', {
          staticClass: "iq-alert-text"
        }, [_c('b', [_vm._v("Downslip")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("50% (Progress)")])]), _c('a', {
          staticClass: "text-primary float-right text-white",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "far fa-hand-pointer"
        }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
          staticClass: "p-2",
          attrs: {
            "id": "job-1-2",
            "accordion": "my-accordion-sub",
            "role": "tabpanel"
          }
        }, [_c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Downslip")]), _c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Start Date")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("2022-06-10")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Est. Finish")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("50%")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Dokumen")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/excel.png"),
            "alt": "gallary-image"
          }
        })])])])])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Created By")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Nurul Akbar")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Review By")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
          staticClass: "align-top"
        }, [_vm._v("Notes")]), _c('td', {
          staticClass: "text-center align-top"
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Internal Man Power")]), _c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          staticClass: "pl-3",
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("Agung Tri Laksono")]), _c('td', {
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("First Engineer")])]), _c('tr', [_c('th', {
          staticClass: "pl-3",
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("Nurul Akbar")]), _c('td', {
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("Second Engineer")])])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Sub Kontraktor #1")]), _c('table', {
          staticClass: "table table-striped"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Nama Kontraktor ")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("PT. CAHAYA PELAYARAN")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Executive Engineer")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Penawaran")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("328.000.000")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Penawaran Nego")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("325.000.000")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Notes")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_vm._v("Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Document")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/excel.png"),
            "alt": "gallary-image"
          }
        })])])])])])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Daily Progress")]), _c('b-col', {
          staticClass: "mb-5",
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Select Docking Job",
            "label-for": "docking_job"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.docking_job
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("List Docking Job")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.selectedDockingJob,
            callback: function callback($$v) {
              _vm.selectedDockingJob = $$v;
            },
            expression: "selectedDockingJob"
          }
        })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label-for": "progress_bar"
          }
        }, [_c('h4', {
          staticClass: "mb-2"
        }, [_vm._v("Progrees : "), _c('b-badge', {
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v("80%")])], 1), _c('b-progress', {
          attrs: {
            "value": 80,
            "variant": "success",
            "max": "100"
          }
        })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Start *",
            "label-for": "start"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date",
            "id": "start"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Progress Pekerjaan *",
            "label-for": "progress_pekerjaan"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "progress_pekerjaan"
          }
        }, [_vm._v("%")])]), _c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text",
            "id": "progress_pekerjaan",
            "placeholder": "80"
          }
        })])])], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Report Progress",
            "label-for": "report_progress"
          }
        }, [_c('div', {
          staticClass: "custom-file"
        }, [_c('input', {
          staticClass: "custom-file-input",
          attrs: {
            "type": "file",
            "id": "validatedCustomFile",
            "required": ""
          }
        }), _c('label', {
          staticClass: "custom-file-label",
          attrs: {
            "for": "validatedCustomFile"
          }
        }, [_vm._v("Browse file...")])]), _c('small', {
          staticClass: "text-muted"
        }, [_vm._v(" File Type * : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Notes",
            "label-for": "notes"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "description",
            "rows": "3",
            "placeholder": "Check water tubes/plates and clean/free from clogged."
          }
        })], 1)], 1)], 1), _c('div', {
          staticClass: "form-group row align-items-center mt-3 m-0"
        }, [_c('div', {
          staticClass: "d-flex flex-grow-1 align-items-center"
        }, [_c('div', {
          staticClass: "send-panel"
        }, [_c('label', {
          staticClass: "mb-0 iq-bg-primary rounded",
          attrs: {
            "for": "file"
          }
        }, [_c('input', {
          staticStyle: {
            "display": "none"
          },
          attrs: {
            "type": "file",
            "id": "file"
          }
        }), _c('a', [_c('i', {
          staticClass: "ri-attachment-line"
        })])]), _c('label', {
          staticClass: "ml-2 mb-0 iq-bg-primary rounded"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0)"
          }
        }, [_c('i', {
          staticClass: "ri-gallery-line text-primary"
        })])])])]), _c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('div', {
          staticClass: "send-panel float-left"
        }, [_c('div', {
          staticClass: "send-btn"
        }, [_c('b-button', {
          attrs: {
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "fa fa-check"
        }), _vm._v(" Finish")]), _c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-comment"
        }), _vm._v(" Update Activity")])], 1)])])])], 1), _c('ul', {
          staticClass: "iq-timeline",
          staticStyle: {
            "height": "100%"
          }
        }, [_c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("24 November 2019 15:45")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Melakukan inspeksi pada kerusakan")]), _c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/6.jpg"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/1.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/4.jpg"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/3.jpg"),
            "alt": "gallary-image"
          }
        })])])])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("23 November 2019 21:25")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Barang tiba di galangan")])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Tri Leksono")]), _c('br'), _c('small', [_vm._v("22 November 2019 10:12")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Menunggu sparepart tiba")])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("21 November 2019 11:21")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Melengkapi dokumen perbaikan")]), _c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/excel.png"),
            "alt": "gallary-image"
          }
        })])])])])])])], 1)])], 1)], 1)], 1)], 1), _c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.jobCat-2",
            modifiers: {
              "jobCat-2": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('b-alert', {
          staticClass: "text-white bg-primary",
          attrs: {
            "show": true
          }
        }, [_c('div', {
          staticClass: "iq-alert-icon"
        }, [_c('i', {
          staticClass: "fas fa-tasks"
        })]), _c('div', {
          staticClass: "iq-alert-text"
        }, [_vm._v("Sand Blasting "), _c('b', [_vm._v("(4 Jobs) - 70%")])]), _c('a', {
          staticClass: "text-primary float-right text-white",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "far fa-hand-pointer"
        }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
          attrs: {
            "id": "jobCat-2",
            "accordion": "my-accordion",
            "role": "tabpanel"
          }
        }, [_c('b-card-body', [_c('b-alert', {
          attrs: {
            "show": true,
            "variant": "danger"
          }
        }, [_c('div', {
          staticClass: "iq-alert-icon"
        }, [_c('i', {
          staticClass: "fa fa-percent"
        })]), _c('div', {
          staticClass: "iq-alert-text"
        }, [_vm._v("Docking Job "), _c('b', [_vm._v("100%")])])]), _c('div', {
          staticClass: "accordion",
          attrs: {
            "role": "tablist"
          }
        }, [_c('b-card', {
          attrs: {
            "no-body": ""
          }
        }, [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.job-2-1",
            modifiers: {
              "job-2-1": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('b-alert', {
          staticClass: "text-white bg-success",
          attrs: {
            "show": true
          }
        }, [_c('div', {
          staticClass: "iq-alert-icon"
        }, [_c('i', {
          staticClass: "fas fa-clipboard-list"
        })]), _c('div', {
          staticClass: "iq-alert-text"
        }, [_c('b', [_vm._v("Scraping Teripting di Lambung & Lunas")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("25% (Finish)")])]), _c('a', {
          staticClass: "text-primary float-right text-white",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "far fa-hand-pointer"
        }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
          staticClass: "p-2",
          attrs: {
            "id": "job-2-1",
            "accordion": "my-accordion-sub",
            "role": "tabpanel"
          }
        }, [_c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Scraping Teripting di Lambung & Lunas")]), _c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Start Date")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("2022-06-10")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Est. Finish")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("50%")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Dokumen")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/excel.png"),
            "alt": "gallary-image"
          }
        })])])])])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Created By")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Nurul Akbar")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Review By")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
          staticClass: "align-top"
        }, [_vm._v("Notes")]), _c('td', {
          staticClass: "text-center align-top"
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Internal Man Power")]), _c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          staticClass: "pl-3",
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("Agung Tri Laksono")]), _c('td', {
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("First Engineer")])]), _c('tr', [_c('th', {
          staticClass: "pl-3",
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("Nurul Akbar")]), _c('td', {
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("Second Engineer")])])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Sub Kontraktor #1")]), _c('table', {
          staticClass: "table table-striped"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Nama Kontraktor ")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("PT. CAHAYA PELAYARAN")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Executive Engineer")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Penawaran")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("328.000.000")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Penawaran Nego")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("325.000.000")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Notes")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_vm._v("Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Document")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/excel.png"),
            "alt": "gallary-image"
          }
        })])])])])])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Daily Progress")]), _c('b-col', {
          staticClass: "mb-5",
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Select Docking Job",
            "label-for": "docking_job"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.docking_job
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("List Docking Job")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.selectedDockingJob,
            callback: function callback($$v) {
              _vm.selectedDockingJob = $$v;
            },
            expression: "selectedDockingJob"
          }
        })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label-for": "progress_bar"
          }
        }, [_c('h4', {
          staticClass: "mb-2"
        }, [_vm._v("Progrees : "), _c('b-badge', {
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v("80%")])], 1), _c('b-progress', {
          attrs: {
            "value": 80,
            "variant": "success",
            "max": "100"
          }
        })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Start *",
            "label-for": "start"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date",
            "id": "start"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Progress Pekerjaan *",
            "label-for": "progress_pekerjaan"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "progress_pekerjaan"
          }
        }, [_vm._v("%")])]), _c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text",
            "id": "progress_pekerjaan",
            "placeholder": "80"
          }
        })])])], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Report Progress",
            "label-for": "report_progress"
          }
        }, [_c('div', {
          staticClass: "custom-file"
        }, [_c('input', {
          staticClass: "custom-file-input",
          attrs: {
            "type": "file",
            "id": "validatedCustomFile",
            "required": ""
          }
        }), _c('label', {
          staticClass: "custom-file-label",
          attrs: {
            "for": "validatedCustomFile"
          }
        }, [_vm._v("Browse file...")])]), _c('small', {
          staticClass: "text-muted"
        }, [_vm._v(" File Type * : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Notes",
            "label-for": "notes"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "description",
            "rows": "3",
            "placeholder": "Check water tubes/plates and clean/free from clogged."
          }
        })], 1)], 1)], 1), _c('div', {
          staticClass: "form-group row align-items-center mt-3 m-0"
        }, [_c('div', {
          staticClass: "d-flex flex-grow-1 align-items-center"
        }, [_c('div', {
          staticClass: "send-panel"
        }, [_c('label', {
          staticClass: "mb-0 iq-bg-primary rounded",
          attrs: {
            "for": "file"
          }
        }, [_c('input', {
          staticStyle: {
            "display": "none"
          },
          attrs: {
            "type": "file",
            "id": "file"
          }
        }), _c('a', [_c('i', {
          staticClass: "ri-attachment-line"
        })])]), _c('label', {
          staticClass: "ml-2 mb-0 iq-bg-primary rounded"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0)"
          }
        }, [_c('i', {
          staticClass: "ri-gallery-line text-primary"
        })])])])]), _c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('div', {
          staticClass: "send-panel float-left"
        }, [_c('div', {
          staticClass: "send-btn"
        }, [_c('b-button', {
          attrs: {
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "fa fa-check"
        }), _vm._v(" Finish")]), _c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-comment"
        }), _vm._v(" Update Activity")])], 1)])])])], 1), _c('ul', {
          staticClass: "iq-timeline",
          staticStyle: {
            "height": "100%"
          }
        }, [_c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("24 November 2019 15:45")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Melakukan inspeksi pada kerusakan")]), _c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/6.jpg"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/1.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/4.jpg"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/3.jpg"),
            "alt": "gallary-image"
          }
        })])])])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("23 November 2019 21:25")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Barang tiba di galangan")])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Tri Leksono")]), _c('br'), _c('small', [_vm._v("22 November 2019 10:12")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Menunggu sparepart tiba")])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("21 November 2019 11:21")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Melengkapi dokumen perbaikan")]), _c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/excel.png"),
            "alt": "gallary-image"
          }
        })])])])])])])], 1)])], 1), _c('b-card', {
          attrs: {
            "no-body": ""
          }
        }, [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.job-2-2",
            modifiers: {
              "job-2-2": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('b-alert', {
          staticClass: "text-white bg-success",
          attrs: {
            "show": true
          }
        }, [_c('div', {
          staticClass: "iq-alert-icon"
        }, [_c('i', {
          staticClass: "fas fa-clipboard-list"
        })]), _c('div', {
          staticClass: "iq-alert-text"
        }, [_c('b', [_vm._v("Pencucian Lambung dengan Air Tawar")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("25% (Finish)")])]), _c('a', {
          staticClass: "text-primary float-right text-white",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "far fa-hand-pointer"
        }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
          staticClass: "p-2",
          attrs: {
            "id": "job-2-2",
            "accordion": "my-accordion-sub",
            "role": "tabpanel"
          }
        }, [_c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Pencucian Lambung dengan Air Tawar")]), _c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Start Date")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("2022-06-10")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Est. Finish")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("50%")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Dokumen")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/excel.png"),
            "alt": "gallary-image"
          }
        })])])])])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Created By")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Nurul Akbar")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Review By")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
          staticClass: "align-top"
        }, [_vm._v("Notes")]), _c('td', {
          staticClass: "text-center align-top"
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Internal Man Power")]), _c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          staticClass: "pl-3",
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("Agung Tri Laksono")]), _c('td', {
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("First Engineer")])]), _c('tr', [_c('th', {
          staticClass: "pl-3",
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("Nurul Akbar")]), _c('td', {
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("Second Engineer")])])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Sub Kontraktor #1")]), _c('table', {
          staticClass: "table table-striped"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Nama Kontraktor ")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("PT. CAHAYA PELAYARAN")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Executive Engineer")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Penawaran")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("328.000.000")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Penawaran Nego")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("325.000.000")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Notes")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_vm._v("Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Document")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/excel.png"),
            "alt": "gallary-image"
          }
        })])])])])])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Daily Progress")]), _c('b-col', {
          staticClass: "mb-5",
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Select Docking Job",
            "label-for": "docking_job"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.docking_job
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("List Docking Job")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.selectedDockingJob,
            callback: function callback($$v) {
              _vm.selectedDockingJob = $$v;
            },
            expression: "selectedDockingJob"
          }
        })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label-for": "progress_bar"
          }
        }, [_c('h4', {
          staticClass: "mb-2"
        }, [_vm._v("Progrees : "), _c('b-badge', {
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v("80%")])], 1), _c('b-progress', {
          attrs: {
            "value": 80,
            "variant": "success",
            "max": "100"
          }
        })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Start *",
            "label-for": "start"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date",
            "id": "start"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Progress Pekerjaan *",
            "label-for": "progress_pekerjaan"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "progress_pekerjaan"
          }
        }, [_vm._v("%")])]), _c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text",
            "id": "progress_pekerjaan",
            "placeholder": "80"
          }
        })])])], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Report Progress",
            "label-for": "report_progress"
          }
        }, [_c('div', {
          staticClass: "custom-file"
        }, [_c('input', {
          staticClass: "custom-file-input",
          attrs: {
            "type": "file",
            "id": "validatedCustomFile",
            "required": ""
          }
        }), _c('label', {
          staticClass: "custom-file-label",
          attrs: {
            "for": "validatedCustomFile"
          }
        }, [_vm._v("Browse file...")])]), _c('small', {
          staticClass: "text-muted"
        }, [_vm._v(" File Type * : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Notes",
            "label-for": "notes"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "description",
            "rows": "3",
            "placeholder": "Check water tubes/plates and clean/free from clogged."
          }
        })], 1)], 1)], 1), _c('div', {
          staticClass: "form-group row align-items-center mt-3 m-0"
        }, [_c('div', {
          staticClass: "d-flex flex-grow-1 align-items-center"
        }, [_c('div', {
          staticClass: "send-panel"
        }, [_c('label', {
          staticClass: "mb-0 iq-bg-primary rounded",
          attrs: {
            "for": "file"
          }
        }, [_c('input', {
          staticStyle: {
            "display": "none"
          },
          attrs: {
            "type": "file",
            "id": "file"
          }
        }), _c('a', [_c('i', {
          staticClass: "ri-attachment-line"
        })])]), _c('label', {
          staticClass: "ml-2 mb-0 iq-bg-primary rounded"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0)"
          }
        }, [_c('i', {
          staticClass: "ri-gallery-line text-primary"
        })])])])]), _c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('div', {
          staticClass: "send-panel float-left"
        }, [_c('div', {
          staticClass: "send-btn"
        }, [_c('b-button', {
          attrs: {
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "fa fa-check"
        }), _vm._v(" Finish")]), _c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-comment"
        }), _vm._v(" Update Activity")])], 1)])])])], 1), _c('ul', {
          staticClass: "iq-timeline",
          staticStyle: {
            "height": "100%"
          }
        }, [_c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("24 November 2019 15:45")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Melakukan inspeksi pada kerusakan")]), _c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/6.jpg"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/1.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/4.jpg"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/3.jpg"),
            "alt": "gallary-image"
          }
        })])])])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("23 November 2019 21:25")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Barang tiba di galangan")])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Tri Leksono")]), _c('br'), _c('small', [_vm._v("22 November 2019 10:12")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Menunggu sparepart tiba")])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("21 November 2019 11:21")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Melengkapi dokumen perbaikan")]), _c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/excel.png"),
            "alt": "gallary-image"
          }
        })])])])])])])], 1)])], 1), _c('b-card', {
          attrs: {
            "no-body": ""
          }
        }, [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.job-2-3",
            modifiers: {
              "job-2-3": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('b-alert', {
          staticClass: "text-white bg-info",
          attrs: {
            "show": true
          }
        }, [_c('div', {
          staticClass: "iq-alert-icon"
        }, [_c('i', {
          staticClass: "fas fa-clipboard-list"
        })]), _c('div', {
          staticClass: "iq-alert-text"
        }, [_c('b', [_vm._v("Sand Blasting Lunas")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("25% (Progress)")])]), _c('a', {
          staticClass: "text-primary float-right text-white",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "far fa-hand-pointer"
        }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
          staticClass: "p-2",
          attrs: {
            "id": "job-2-3",
            "accordion": "my-accordion-sub",
            "role": "tabpanel"
          }
        }, [_c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Sand Blasting Lunas")]), _c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Start Date")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("2022-06-10")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Est. Finish")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("50%")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Dokumen")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/excel.png"),
            "alt": "gallary-image"
          }
        })])])])])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Created By")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Nurul Akbar")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Review By")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
          staticClass: "align-top"
        }, [_vm._v("Notes")]), _c('td', {
          staticClass: "text-center align-top"
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Internal Man Power")]), _c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          staticClass: "pl-3",
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("Agung Tri Laksono")]), _c('td', {
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("First Engineer")])]), _c('tr', [_c('th', {
          staticClass: "pl-3",
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("Nurul Akbar")]), _c('td', {
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("Second Engineer")])])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Sub Kontraktor #1")]), _c('table', {
          staticClass: "table table-striped"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Nama Kontraktor ")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("PT. CAHAYA PELAYARAN")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Executive Engineer")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Penawaran")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("328.000.000")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Penawaran Nego")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("325.000.000")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Notes")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_vm._v("Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Document")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/excel.png"),
            "alt": "gallary-image"
          }
        })])])])])])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Daily Progress")]), _c('b-col', {
          staticClass: "mb-5",
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Select Docking Job",
            "label-for": "docking_job"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.docking_job
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("List Docking Job")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.selectedDockingJob,
            callback: function callback($$v) {
              _vm.selectedDockingJob = $$v;
            },
            expression: "selectedDockingJob"
          }
        })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label-for": "progress_bar"
          }
        }, [_c('h4', {
          staticClass: "mb-2"
        }, [_vm._v("Progrees : "), _c('b-badge', {
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v("80%")])], 1), _c('b-progress', {
          attrs: {
            "value": 80,
            "variant": "success",
            "max": "100"
          }
        })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Start *",
            "label-for": "start"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date",
            "id": "start"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Progress Pekerjaan *",
            "label-for": "progress_pekerjaan"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "progress_pekerjaan"
          }
        }, [_vm._v("%")])]), _c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text",
            "id": "progress_pekerjaan",
            "placeholder": "80"
          }
        })])])], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Report Progress",
            "label-for": "report_progress"
          }
        }, [_c('div', {
          staticClass: "custom-file"
        }, [_c('input', {
          staticClass: "custom-file-input",
          attrs: {
            "type": "file",
            "id": "validatedCustomFile",
            "required": ""
          }
        }), _c('label', {
          staticClass: "custom-file-label",
          attrs: {
            "for": "validatedCustomFile"
          }
        }, [_vm._v("Browse file...")])]), _c('small', {
          staticClass: "text-muted"
        }, [_vm._v(" File Type * : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Notes",
            "label-for": "notes"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "description",
            "rows": "3",
            "placeholder": "Check water tubes/plates and clean/free from clogged."
          }
        })], 1)], 1)], 1), _c('div', {
          staticClass: "form-group row align-items-center mt-3 m-0"
        }, [_c('div', {
          staticClass: "d-flex flex-grow-1 align-items-center"
        }, [_c('div', {
          staticClass: "send-panel"
        }, [_c('label', {
          staticClass: "mb-0 iq-bg-primary rounded",
          attrs: {
            "for": "file"
          }
        }, [_c('input', {
          staticStyle: {
            "display": "none"
          },
          attrs: {
            "type": "file",
            "id": "file"
          }
        }), _c('a', [_c('i', {
          staticClass: "ri-attachment-line"
        })])]), _c('label', {
          staticClass: "ml-2 mb-0 iq-bg-primary rounded"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0)"
          }
        }, [_c('i', {
          staticClass: "ri-gallery-line text-primary"
        })])])])]), _c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('div', {
          staticClass: "send-panel float-left"
        }, [_c('div', {
          staticClass: "send-btn"
        }, [_c('b-button', {
          attrs: {
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "fa fa-check"
        }), _vm._v(" Finish")]), _c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-comment"
        }), _vm._v(" Update Activity")])], 1)])])])], 1), _c('ul', {
          staticClass: "iq-timeline",
          staticStyle: {
            "height": "100%"
          }
        }, [_c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("24 November 2019 15:45")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Melakukan inspeksi pada kerusakan")]), _c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/6.jpg"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/1.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/4.jpg"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/3.jpg"),
            "alt": "gallary-image"
          }
        })])])])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("23 November 2019 21:25")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Barang tiba di galangan")])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Tri Leksono")]), _c('br'), _c('small', [_vm._v("22 November 2019 10:12")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Menunggu sparepart tiba")])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("21 November 2019 11:21")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Melengkapi dokumen perbaikan")]), _c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/excel.png"),
            "alt": "gallary-image"
          }
        })])])])])])])], 1)])], 1), _c('b-card', {
          attrs: {
            "no-body": ""
          }
        }, [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.job-2-4",
            modifiers: {
              "job-2-4": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('b-alert', {
          staticClass: "text-white bg-warning",
          attrs: {
            "show": true
          }
        }, [_c('div', {
          staticClass: "iq-alert-icon"
        }, [_c('i', {
          staticClass: "fas fa-clipboard-list"
        })]), _c('div', {
          staticClass: "iq-alert-text"
        }, [_c('b', [_vm._v("Sand Blasting Deck & Bullwark")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("15% (Progress)")])]), _c('a', {
          staticClass: "text-primary float-right text-white",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "far fa-hand-pointer"
        }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
          staticClass: "p-2",
          attrs: {
            "id": "job-2-4",
            "accordion": "my-accordion-sub",
            "role": "tabpanel"
          }
        }, [_c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Sand Blasting Lunas")]), _c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Start Date")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("2022-06-10")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Est. Finish")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("50%")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Dokumen")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/excel.png"),
            "alt": "gallary-image"
          }
        })])])])])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Created By")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Nurul Akbar")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Review By")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
          staticClass: "align-top"
        }, [_vm._v("Notes")]), _c('td', {
          staticClass: "text-center align-top"
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Internal Man Power")]), _c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          staticClass: "pl-3",
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("Agung Tri Laksono")]), _c('td', {
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("First Engineer")])]), _c('tr', [_c('th', {
          staticClass: "pl-3",
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("Nurul Akbar")]), _c('td', {
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("Second Engineer")])])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Sub Kontraktor #1")]), _c('table', {
          staticClass: "table table-striped"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Nama Kontraktor ")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("PT. CAHAYA PELAYARAN")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Executive Engineer")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Penawaran")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("328.000.000")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Penawaran Nego")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("325.000.000")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Notes")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_vm._v("Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Document")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/excel.png"),
            "alt": "gallary-image"
          }
        })])])])])])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Daily Progress")]), _c('b-col', {
          staticClass: "mb-5",
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Select Docking Job",
            "label-for": "docking_job"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.docking_job
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("List Docking Job")])];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.selectedDockingJob,
            callback: function callback($$v) {
              _vm.selectedDockingJob = $$v;
            },
            expression: "selectedDockingJob"
          }
        })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label-for": "progress_bar"
          }
        }, [_c('h4', {
          staticClass: "mb-2"
        }, [_vm._v("Progrees : "), _c('b-badge', {
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v("80%")])], 1), _c('b-progress', {
          attrs: {
            "value": 80,
            "variant": "success",
            "max": "100"
          }
        })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Start *",
            "label-for": "start"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date",
            "id": "start"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Progress Pekerjaan *",
            "label-for": "progress_pekerjaan"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "progress_pekerjaan"
          }
        }, [_vm._v("%")])]), _c('input', {
          staticClass: "form-control",
          attrs: {
            "type": "text",
            "id": "progress_pekerjaan",
            "placeholder": "80"
          }
        })])])], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Report Progress",
            "label-for": "report_progress"
          }
        }, [_c('div', {
          staticClass: "custom-file"
        }, [_c('input', {
          staticClass: "custom-file-input",
          attrs: {
            "type": "file",
            "id": "validatedCustomFile",
            "required": ""
          }
        }), _c('label', {
          staticClass: "custom-file-label",
          attrs: {
            "for": "validatedCustomFile"
          }
        }, [_vm._v("Browse file...")])]), _c('small', {
          staticClass: "text-muted"
        }, [_vm._v(" File Type * : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Notes",
            "label-for": "notes"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "description",
            "rows": "3",
            "placeholder": "Check water tubes/plates and clean/free from clogged."
          }
        })], 1)], 1)], 1), _c('div', {
          staticClass: "form-group row align-items-center mt-3 m-0"
        }, [_c('div', {
          staticClass: "d-flex flex-grow-1 align-items-center"
        }, [_c('div', {
          staticClass: "send-panel"
        }, [_c('label', {
          staticClass: "mb-0 iq-bg-primary rounded",
          attrs: {
            "for": "file"
          }
        }, [_c('input', {
          staticStyle: {
            "display": "none"
          },
          attrs: {
            "type": "file",
            "id": "file"
          }
        }), _c('a', [_c('i', {
          staticClass: "ri-attachment-line"
        })])]), _c('label', {
          staticClass: "ml-2 mb-0 iq-bg-primary rounded"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0)"
          }
        }, [_c('i', {
          staticClass: "ri-gallery-line text-primary"
        })])])])]), _c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('div', {
          staticClass: "send-panel float-left"
        }, [_c('div', {
          staticClass: "send-btn"
        }, [_c('b-button', {
          attrs: {
            "variant": "success"
          }
        }, [_c('i', {
          staticClass: "fa fa-check"
        }), _vm._v(" Finish")]), _c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-comment"
        }), _vm._v(" Update Activity")])], 1)])])])], 1), _c('ul', {
          staticClass: "iq-timeline",
          staticStyle: {
            "height": "100%"
          }
        }, [_c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("24 November 2019 15:45")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Melakukan inspeksi pada kerusakan")]), _c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/6.jpg"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/1.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/4.jpg"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/3.jpg"),
            "alt": "gallary-image"
          }
        })])])])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("23 November 2019 21:25")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Barang tiba di galangan")])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Tri Leksono")]), _c('br'), _c('small', [_vm._v("22 November 2019 10:12")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Menunggu sparepart tiba")])])]), _c('li', [_c('div', {
          staticClass: "timeline-dots border-success"
        }), _c('h6', {
          staticClass: "float-left mb-1"
        }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("21 November 2019 11:21")]), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('p', [_vm._v("Melengkapi dokumen perbaikan")]), _c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-2 col-2 pb-2"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/excel.png"),
            "alt": "gallary-image"
          }
        })])])])])])])], 1)])], 1), _c('b-card', {
          attrs: {
            "no-body": ""
          }
        }, [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.job-2-5",
            modifiers: {
              "job-2-5": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('b-alert', {
          staticClass: "text-white bg-danger",
          attrs: {
            "show": true
          }
        }, [_c('div', {
          staticClass: "iq-alert-icon"
        }, [_c('i', {
          staticClass: "fas fa-clipboard-list"
        })]), _c('div', {
          staticClass: "iq-alert-text"
        }, [_c('b', [_vm._v("Sand Blasting Main Deck")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("10% (New)")])]), _c('a', {
          staticClass: "text-primary float-right text-white",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "far fa-hand-pointer"
        }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
          staticClass: "p-2",
          attrs: {
            "id": "job-2-5",
            "accordion": "my-accordion-sub",
            "role": "tabpanel"
          }
        }, [_c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Sand Blasting Main Deck")]), _c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Start Date")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("2022-06-10")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Est. Finish")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("50%")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Dokumen")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/excel.png"),
            "alt": "gallary-image"
          }
        })])])])])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Created By")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Nurul Akbar")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Review By")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
          staticClass: "align-top"
        }, [_vm._v("Notes")]), _c('td', {
          staticClass: "text-center align-top"
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Internal Man Power")]), _c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          staticClass: "pl-3",
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("Agung Tri Laksono")]), _c('td', {
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("First Engineer")])]), _c('tr', [_c('th', {
          staticClass: "pl-3",
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("Nurul Akbar")]), _c('td', {
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("Second Engineer")])])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Sub Kontraktor #1")]), _c('table', {
          staticClass: "table table-striped"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Nama Kontraktor ")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("PT. CAHAYA PELAYARAN")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Executive Engineer")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Penawaran")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("328.000.000")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Penawaran Nego")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("325.000.000")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Notes")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_vm._v("Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Document")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_c('ul', {
          staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
        }, [_c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })])]), _c('li', {
          staticClass: "col-md-1 col-1 pb-1"
        }, [_c('a', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_c('img', {
          staticClass: "img-fluid",
          attrs: {
            "src": require("../../assets/images/activity/excel.png"),
            "alt": "gallary-image"
          }
        })])])])])])])])])])], 1)], 1)], 1)], 1)], 1)])], 1)];
      }
    }]),
    model: {
      value: _vm.slideDetailDocking,
      callback: function callback($$v) {
        _vm.slideDetailDocking = $$v;
      },
      expression: "slideDetailDocking"
    }
  }), _c('b-sidebar', {
    attrs: {
      "id": "detail_docking",
      "title": "HISTORY MONTHLY REPORT",
      "width": "100%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var hide = _ref8.hide;
        return [_c('b-row', {
          staticClass: "m-3"
        }, [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 text-primary"
        }, [_vm._v("TB. TAVIA 1")]), _c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("Bulan Report")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TA. Arrival")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Star Floating")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Uplsip")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Delivery Date")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Waiting Space")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Target Days")]), _c('th', {
          staticClass: "text-center text-primary"
        }, [_vm._v("Performance Monthly")]), _c('th', {
          staticClass: "text-center text-danger"
        }, [_vm._v("Actual Docking Days")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Action")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("30 Sept 2023")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("07/06/2023")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("15/06/2023")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("04/07/2023")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("27 Days")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("60 Days")]), _c('td', {
          staticClass: "text-center bg-primary"
        }, [_vm._v("30 Days")]), _c('td', {
          staticClass: "text-center bg-danger"
        }, [_vm._v("88 Days")]), _c('td', {
          staticClass: "text-center"
        }, [_c('a', {
          attrs: {
            "href": ""
          }
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-ship"
        })])], 1)])]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("31 Agustus 2023")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("07/06/2023")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("15/06/2023")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("04/07/2023")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("27 Days")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("60 Days")]), _c('td', {
          staticClass: "text-center bg-primary"
        }, [_vm._v("31 Days")]), _c('td', {
          staticClass: "text-center bg-danger"
        }, [_vm._v("57 Days")]), _c('td', {
          staticClass: "text-center"
        }, [_c('a', {
          attrs: {
            "href": ""
          }
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-ship"
        })])], 1)])]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("31 Juli 2023")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("07/06/2023")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("15/06/2023")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("04/07/2023")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("27 Days")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("60 Days")]), _c('td', {
          staticClass: "text-center bg-primary"
        }, [_vm._v("31 Days")]), _c('td', {
          staticClass: "text-center bg-danger"
        }, [_vm._v("26 Days")]), _c('td', {
          staticClass: "text-center"
        }, [_c('a', {
          attrs: {
            "href": ""
          }
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-ship"
        })])], 1)])]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("30 Juni 2023")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("07/06/2023")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("15/06/2023")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("04/07/2023")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("27 Days")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("60 Days")]), _c('td', {
          staticClass: "text-center bg-primary"
        }, [_vm._v("30 Days")]), _c('td', {
          staticClass: "text-center bg-danger"
        }, [_vm._v("26 Days")]), _c('td', {
          staticClass: "text-center"
        }, [_c('a', {
          attrs: {
            "href": ""
          }
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-ship"
        })])], 1)])])])])])])], 1)];
      }
    }]),
    model: {
      value: _vm.slideHistoryMonthlyReport,
      callback: function callback($$v) {
        _vm.slideHistoryMonthlyReport = $$v;
      },
      expression: "slideHistoryMonthlyReport"
    }
  })], 1)])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }