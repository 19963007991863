var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "iq-edit-profile d-flex",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-12 p-0",
          attrs: {
            "active": true,
            "href": "#hm",
            "title": "Sum Insured Fleet of TEL Group"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "pills-tabContent-2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "hm"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Filter Sum Insured")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_vm.canExport ? _c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.exportPriceOfFleets
          }
        }, [!_vm.loading_export ? [_c('i', {
          staticClass: "fa fa-file-excel"
        }), _vm._v("   Export ")] : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _vm._v(" Exporting... ")]], 2) : _vm._e()];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Choose your Type Filter",
            "label-for": "fleet-equipment"
          }
        }, [_vm._l(_vm.typeFilterOptions, function (item, index) {
          return [_c('b-form-radio', {
            key: index,
            staticClass: "custom-radio-color-checked",
            attrs: {
              "inline": "",
              "color": item.color,
              "name": "color",
              "value": item.value,
              "disabled": item.disabled
            },
            model: {
              value: _vm.fleetPrices.params.type,
              callback: function callback($$v) {
                _vm.$set(_vm.fleetPrices.params, "type", $$v);
              },
              expression: "fleetPrices.params.type"
            }
          }, [_vm._v(_vm._s(item.label))])];
        })], 2)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_vm.fleetPrices.params.type === 'company' ? _c('b-form-group', {
          attrs: {
            "label": "Company :",
            "label-for": "kind-fleet"
          }
        }, [_c('v-select', {
          staticClass: "w-100",
          attrs: {
            "id": "fleet_name",
            "reduce": function reduce(item) {
              return item.value;
            },
            "label": "text",
            "placeholder": "Select fleet",
            "options": _vm.companyOptions,
            "multiple": ""
          },
          model: {
            value: _vm.fleetPrices.params.values,
            callback: function callback($$v) {
              _vm.$set(_vm.fleetPrices.params, "values", $$v);
            },
            expression: "fleetPrices.params.values"
          }
        })], 1) : _vm._e(), _vm.fleetPrices.params.type === 'vehicle_type' ? _c('b-form-group', {
          attrs: {
            "label": "Vessel Type :",
            "label-for": "kind-fleet"
          }
        }, [_c('v-select', {
          staticClass: "w-100",
          attrs: {
            "id": "fleet_name",
            "reduce": function reduce(item) {
              return item.value;
            },
            "label": "text",
            "placeholder": "Select fleet",
            "options": _vm.fleetTypes,
            "multiple": ""
          },
          model: {
            value: _vm.fleetPrices.params.values,
            callback: function callback($$v) {
              _vm.$set(_vm.fleetPrices.params, "values", $$v);
            },
            expression: "fleetPrices.params.values"
          }
        })], 1) : _vm._e(), _vm.fleetPrices.params.type === 'vehicle' ? _c('b-form-group', {
          attrs: {
            "label": "Fleet :",
            "label-for": "kind-fleet"
          }
        }, [_c('v-select', {
          staticClass: "w-100",
          attrs: {
            "id": "fleet_name",
            "reduce": function reduce(item) {
              return item.value;
            },
            "label": "text",
            "placeholder": "Select fleet",
            "options": _vm.fleetOptions,
            "multiple": ""
          },
          model: {
            value: _vm.fleetPrices.params.values,
            callback: function callback($$v) {
              _vm.$set(_vm.fleetPrices.params, "values", $$v);
            },
            expression: "fleetPrices.params.values"
          }
        })], 1) : _vm._e()], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "dateIssued",
            "label": "Date"
          }
        }, [_c('date-picker', {
          staticStyle: {
            "width": "100%"
          },
          attrs: {
            "type": "date",
            "value-type": "YYYY-MM-DD",
            "placeholder": "Select date"
          },
          model: {
            value: _vm.fleetPrices.params.date,
            callback: function callback($$v) {
              _vm.$set(_vm.fleetPrices.params, "date", $$v);
            },
            expression: "fleetPrices.params.date"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "1"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "disabled": _vm.fleetPrices.params.date === '',
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.fetchFleetPrices(1);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table table-bordered table-striped shadow"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Currency "), _c('small', [_vm._v("!= IDR")])]), _c('th', [_vm._v("Kurs to IDR")])])]), _c('tbody', [_vm._l(_vm.fleetPrices.mapping, function (currency, index) {
    return [_c('tr', {
      key: index
    }, [_c('td', [_c('strong', [_vm._v(_vm._s(currency.currency.name))])]), _c('td', [_c('h5', [_c('strong', [_vm._v("IDR " + _vm._s(_vm.numberFormat(currency.value)))])])])])];
  })], 2)])]), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          staticClass: "text-right",
          attrs: {
            "md": "12"
          }
        }, [_c('b-row', [_c('b-col', {
          staticClass: "text-left",
          attrs: {
            "md": "6"
          }
        }, [_vm._v(" Data Period of "), _c('strong', [_vm._v(" " + _vm._s(_vm.fleetPrices.params.date) + " ")])]), _c('b-col', {
          staticClass: "text-right",
          attrs: {
            "md": "6"
          }
        }, [_vm.fleetPrices.detail && _vm.canEdit ? _c('button', {
          staticClass: "btn btn-warning mb-3 mr-2",
          on: {
            "click": function click($event) {
              return _vm.$router.push("/mi/price-fleet-form?id=".concat(_vm.fleetPrices.detail.id));
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-edit"
        }), _vm._v(" Edit Data")]) : _vm._e(), _vm.canAdd ? _c('router-link', {
          attrs: {
            "to": {
              name: 'mi.price-fleet-form'
            }
          }
        }, [_c('button', {
          staticClass: "btn btn-primary mb-3"
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" Add Sum Insured New Period")])]) : _vm._e()], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-alert', {
          attrs: {
            "show": _vm.fleetPrices.data.length === 0,
            "variant": "danger"
          }
        }, [_c('div', {
          staticClass: "iq-alert-text text-center"
        }, [_vm._v("Data Not Found")])])], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_vm._l(_vm.fleetPrices.data, function (company, index) {
          return [_c('b-card', {
            key: "comp-".concat(index),
            staticClass: "mb-1",
            attrs: {
              "no-body": ""
            }
          }, [_c('b-card-header', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: 'company' + company.id,
              expression: "'company' + company.id"
            }],
            staticClass: "p-1",
            attrs: {
              "header-tag": "header",
              "role": "tab"
            }
          }, [_c('h4', {
            staticClass: "p-3",
            staticStyle: {
              "cursor": "pointer",
              "font-weight": "bold"
            }
          }, [_vm._v(" " + _vm._s(company.company) + " ")])]), _c('b-collapse', {
            attrs: {
              "id": 'company' + company.id,
              "visible": true,
              "role": "tabpanel"
            }
          }, [_c('b-card-body', [_vm._l(company.types.filter(function (category) {
            return !category.type_row || category.type_row && category.type_row !== 'total';
          }), function (category, index2) {
            return [_c('b-card', {
              key: "mt2-".concat(index2),
              staticClass: "mb-1",
              attrs: {
                "no-body": ""
              }
            }, [_c('b-card-header', {
              directives: [{
                name: "b-toggle",
                rawName: "v-b-toggle",
                value: "category-".concat(index2, "-company-").concat(index),
                expression: "`category-${index2}-company-${index}`"
              }],
              staticClass: "p-1",
              attrs: {
                "header-tag": "header",
                "role": "tab"
              }
            }, [_c('h4', {
              staticClass: "p-3",
              staticStyle: {
                "cursor": "pointer"
              }
            }, [_vm._v(" " + _vm._s(category.name) + " ")])]), _c('b-collapse', {
              attrs: {
                "id": "category-".concat(index2, "-company-").concat(index),
                "visible": false,
                "role": "tabpanel"
              }
            }, [_c('b-card-body', [_c('b-card-text', [_c('div', {
              staticClass: "overflow-auto tableFixHead"
            }, [_c('table', {
              staticClass: "table mb-0 table-striped table-hover",
              staticStyle: {
                "width": "2200px",
                "display": "block",
                "height": "700px",
                "overflow-y": "scroll"
              }
            }, [_c('thead', {
              staticClass: "text-center thead-dark"
            }, [_c('tr', [_c('th', {
              staticStyle: {
                "vertical-align": "middle"
              },
              attrs: {
                "rowspan": "2",
                "width": "5%"
              }
            }, [_vm._v("No")]), _c('th', {
              staticClass: "text-truncate",
              staticStyle: {
                "vertical-align": "middle"
              },
              attrs: {
                "rowspan": "2",
                "width": "15%"
              }
            }, [_vm._v("Fleet")]), _c('th', {
              staticClass: "text-truncate",
              staticStyle: {
                "vertical-align": "middle"
              },
              attrs: {
                "rowspan": "2",
                "width": "15%"
              }
            }, [_vm._v("Last Value")]), _c('th', {
              staticClass: "text-truncate",
              staticStyle: {
                "vertical-align": "middle"
              },
              attrs: {
                "rowspan": "2",
                "width": "10%"
              }
            }, [_vm._v("Reduce Value")]), _c('th', {
              staticClass: "text-truncate",
              staticStyle: {
                "vertical-align": "middle"
              },
              attrs: {
                "colspan": "4"
              }
            }, [_vm._v("Sum Insured")]), _c('th', {
              staticClass: "text-truncate",
              staticStyle: {
                "vertical-align": "middle"
              },
              attrs: {
                "colspan": "3"
              }
            }, [_vm._v("Rate %")]), _c('th', {
              staticClass: "text-truncate",
              staticStyle: {
                "vertical-align": "middle"
              },
              attrs: {
                "rowspan": "2",
                "width": "15%"
              }
            }, [_vm._v("Deductible")]), _c('th', {
              staticClass: "text-truncate",
              staticStyle: {
                "vertical-align": "middle"
              },
              attrs: {
                "rowspan": "2",
                "width": "12%"
              }
            }, [_vm._v("Insurance Period")])]), _c('tr', [_c('th', {
              staticClass: "text-truncate",
              attrs: {
                "width": "12%"
              }
            }, [_vm._v("TSI/Value(New)")]), _c('th', {
              staticClass: "text-truncate",
              attrs: {
                "width": "12%"
              }
            }, [_vm._v("HM ")]), _c('th', {
              staticClass: "text-truncate",
              attrs: {
                "width": "12%"
              }
            }, [_vm._v("IV ")]), _c('th', {
              staticClass: "text-truncate",
              attrs: {
                "width": "12%"
              }
            }, [_vm._v("WAR")]), _c('th', {
              staticClass: "text-truncate",
              attrs: {
                "width": "10%"
              }
            }, [_vm._v("Rate HM ")]), _c('th', {
              staticClass: "text-truncate",
              attrs: {
                "width": "10%"
              }
            }, [_vm._v("Rate IV ")]), _c('th', {
              staticClass: "text-truncate",
              attrs: {
                "width": "10%"
              }
            }, [_vm._v("WAR")])])]), _c('tbody', _vm._l(category.data.filter(function (content) {
              return !content.type_row || content.type_row && content.type_row !== 'total';
            }), function (content, indexContent) {
              var _content$vehicle;
              return _c('tr', {
                key: indexContent
              }, [_c('td', [_vm._v(_vm._s(indexContent + 1) + ".")]), _c('td', {
                staticClass: "text-truncate"
              }, [_vm._v(" " + _vm._s(content.vehicle ? content === null || content === void 0 ? void 0 : (_content$vehicle = content.vehicle) === null || _content$vehicle === void 0 ? void 0 : _content$vehicle.name : '-') + " ")]), _c('td', {
                staticClass: "text-truncate"
              }, [_vm._v(" " + _vm._s(content.currency.alias) + " " + _vm._s(_vm.numberFormat(content.value)) + " ")]), _c('td', {
                staticClass: "text-truncate"
              }, [_vm._v(" " + _vm._s(_vm.numberFormat(content.reduce)) + "% ")]), _c('td', {
                staticClass: "text-truncate"
              }, [_vm._v(_vm._s(content.currency.alias) + " " + _vm._s(_vm.numberFormat(Number(content.tsi).toFixed(2))) + " ")]), _c('td', {
                staticClass: "text-truncate"
              }, [_vm._v(_vm._s(content.currency.alias) + " " + _vm._s(_vm.numberFormat(Number(content.hm).toFixed(2))) + " ")]), _c('td', {
                staticClass: "text-truncate"
              }, [_vm._v(_vm._s(content.currency.alias) + " " + _vm._s(_vm.numberFormat(Number(content.iv).toFixed(2))) + " ")]), _c('td', {
                staticClass: "text-truncate"
              }, [_vm._v(" " + _vm._s(_vm.numberFormat(content.war)) + " ")]), _c('td', {
                staticClass: "text-truncate"
              }, [_vm._v(" " + _vm._s(Number(content.rate_hm).toFixed(4)) + " ")]), _c('td', {
                staticClass: "text-truncate"
              }, [_vm._v(" " + _vm._s(Number(content.rate_iv).toFixed(4)) + " ")]), _c('td', {
                staticClass: "text-truncate"
              }, [_vm._v(" " + _vm._s(Number(content.rate_war).toFixed(4)) + " ")]), _c('td', {
                staticClass: "text-truncate"
              }, [_vm._v(_vm._s(content.currency.alias) + " " + _vm._s(_vm.numberFormat(content.deductible_type === 'float' ? content.deductible_value : content.suggest_rate_hm)) + " ")]), _c('td', {
                staticClass: "text-truncate"
              }, [_vm._v(" " + _vm._s(content.from ? _vm.moment(content.from).format('DD MMM YYYY') : '-') + " "), _c('strong', [_vm._v("s.d")]), _vm._v(" " + _vm._s(content.to ? _vm.moment(content.to).format('DD MMM YYYY') : '-') + " ")])]);
            }), 0), _vm._l(category.data.filter(function (content) {
              return content.type_row && content.type_row === 'total';
            }), function (content, indexContent) {
              return _c('tfoot', {
                key: indexContent,
                staticClass: "bg-warning text-white"
              }, [_c('tr', [_c('td', {
                attrs: {
                  "colspan": "2",
                  "rowspan": "2"
                }
              }, [_vm._v("TOTAL")]), _c('td', {
                attrs: {
                  "colspan": "2"
                }
              }, [content.value ? _vm._l(Object.keys(content.value), function (key) {
                return _c('div', {
                  key: key
                }, [_vm._v(" " + _vm._s(key) + " " + _vm._s(_vm.numberFormat(content.value[key])) + " ")]);
              }) : _vm._e()], 2), _c('td'), _c('td'), _c('td'), _c('td'), _c('td', {
                attrs: {
                  "colspan": "6"
                }
              })]), _c('tr', [_c('td', {
                attrs: {
                  "colspan": "2"
                }
              }, [content.value ? _vm._l(Object.keys(content.value), function (key) {
                return _c('div', {
                  key: key
                }, [_vm._v(" " + _vm._s(key) + " " + _vm._s(_vm.numberFormat(content.value[key])) + " ")]);
              }) : _vm._e()], 2), _c('td', [content.value ? _vm._l(Object.keys(content.value), function (key) {
                return _c('div', {
                  key: key
                }, [_vm._v(" " + _vm._s(key) + " " + _vm._s(_vm.numberFormat(content.value[key])) + " ")]);
              }) : _vm._e()], 2), _c('td', [content.hm ? _vm._l(Object.keys(content.hm), function (key) {
                return _c('div', {
                  key: key
                }, [_vm._v(" " + _vm._s(key) + " " + _vm._s(_vm.numberFormat(content.hm[key])) + " ")]);
              }) : _vm._e()], 2), _c('td', [content.iv ? _vm._l(Object.keys(content.iv), function (key) {
                return _c('div', {
                  key: key
                }, [_vm._v(" " + _vm._s(key) + " " + _vm._s(_vm.numberFormat(content.iv[key])) + " ")]);
              }) : _vm._e()], 2), _c('td', [content.war ? _vm._l(Object.keys(content.war), function (key) {
                return _c('div', {
                  key: key
                }, [_vm._v(" " + _vm._s(key) + " " + _vm._s(_vm.numberFormat(content.war[key])) + " ")]);
              }) : _vm._e()], 2), _c('td'), _c('td'), _c('td'), _c('td', {
                attrs: {
                  "colspan": "6"
                }
              })])]);
            })], 2)])])], 1)], 1)], 1)];
          }), _c('table', {
            staticClass: "table"
          }, [_c('thead', {
            staticClass: "text-center thead-dark"
          }, [_c('tr', [_c('th', {
            staticStyle: {
              "vertical-align": "middle"
            },
            attrs: {
              "rowspan": "2",
              "width": "15%"
            }
          }), _c('th', {
            staticStyle: {
              "vertical-align": "middle"
            },
            attrs: {
              "rowspan": "2",
              "width": "15%"
            }
          }, [_vm._v("Last Value")]), _c('th', {
            staticStyle: {
              "vertical-align": "middle"
            },
            attrs: {
              "colspan": "4"
            }
          }, [_vm._v("Sum Insured")])]), _c('tr', [_c('td', {
            attrs: {
              "width": "12%"
            }
          }, [_vm._v("TSI/Value(New)")]), _c('td', {
            attrs: {
              "width": "12%"
            }
          }, [_vm._v("HM ")]), _c('td', {
            attrs: {
              "width": "12%"
            }
          }, [_vm._v("IV ")]), _c('td', {
            attrs: {
              "width": "12%"
            }
          }, [_vm._v("WAR")])])]), _vm._l(company.types.filter(function (category) {
            return category.type_row && category.type_row === 'total';
          }), function (category, idx) {
            return _c('tfoot', {
              key: idx,
              staticClass: "bg-warning text-white"
            }, [_c('tr', [_c('td', {
              attrs: {
                "rowspan": "2"
              }
            }, [_vm._v("TOTAL")]), _c('td', [category.value ? _vm._l(Object.keys(category.value), function (key) {
              return _c('div', {
                key: key
              }, [_vm._v(" " + _vm._s(key) + " " + _vm._s(_vm.numberFormat(category.value[key])) + " ")]);
            }) : _vm._e()], 2), _c('td'), _c('td'), _c('td'), _c('td')]), _c('tr', [_c('td', [category.value ? _vm._l(Object.keys(category.value), function (key) {
              return _c('div', {
                key: key
              }, [_vm._v(" " + _vm._s(key) + " " + _vm._s(_vm.numberFormat(category.value[key])) + " ")]);
            }) : _vm._e()], 2), _c('td', [category.value ? _vm._l(Object.keys(category.value), function (key) {
              return _c('div', {
                key: key
              }, [_vm._v(" " + _vm._s(key) + " " + _vm._s(_vm.numberFormat(category.value[key])) + " ")]);
            }) : _vm._e()], 2), _c('td', [category.hm ? _vm._l(Object.keys(category.hm), function (key) {
              return _c('div', {
                key: key
              }, [_vm._v(" " + _vm._s(key) + " " + _vm._s(_vm.numberFormat(category.hm[key])) + " ")]);
            }) : _vm._e()], 2), _c('td', [category.iv ? _vm._l(Object.keys(category.iv), function (key) {
              return _c('div', {
                key: key
              }, [_vm._v(" " + _vm._s(key) + " " + _vm._s(_vm.numberFormat(category.iv[key])) + " ")]);
            }) : _vm._e()], 2), _c('td', [category.war ? _vm._l(Object.keys(category.war), function (key) {
              return _c('div', {
                key: key
              }, [_vm._v(" " + _vm._s(key) + " " + _vm._s(_vm.numberFormat(category.war[key])) + " ")]);
            }) : _vm._e()], 2)])]);
          })], 2)], 2)], 1)], 1)];
        })], 2)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }