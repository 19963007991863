var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Spesification")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center gap-4 float-right"
        }, [_c('a', {
          staticClass: "text-primary mr-4",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          },
          on: {
            "click": _vm.onExportSpesification
          }
        }, [_c('i', {
          staticClass: "fa fa-file-excel mr-2"
        }), _c('span', [_vm._v("Export Data")])]), _vm.addPermission ? _c('a', {
          staticClass: "text-primary mr-4",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          },
          on: {
            "click": function click($event) {
              return _vm.openslideFormSpesification('ADD', null);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus mr-2"
        }), _c('span', [_vm._v("Create Data")])]) : _vm._e()])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$list, _vm$list$data;
        return [_c('b-row', {
          staticClass: "mb-4"
        }, [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "search"
          },
          model: {
            value: _vm.params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "search", $$v);
            },
            expression: "params.search"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Search ID/Name...",
            "name": "search"
          },
          model: {
            value: _vm.params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "search", $$v);
            },
            expression: "params.search"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Filter",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          attrs: {
            "type": "button",
            "variant": "primary"
          },
          on: {
            "click": _vm.getListSpesification
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_vm.loading ? _c('div', {
          staticClass: "alert alert-info"
        }, [_vm._v("Loading...")]) : _vm._e(), _c('iq-card', [((_vm$list = _vm.list) === null || _vm$list === void 0 ? void 0 : (_vm$list$data = _vm$list.data) === null || _vm$list$data === void 0 ? void 0 : _vm$list$data.length) > 0 ? _c('div', {
          staticClass: "overflow-auto d-flex"
        }, [_c('table', {
          staticClass: "table table-striped table-bordered"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center",
          staticStyle: {
            "width": "20px"
          }
        }, [_vm._v("No")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Code")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Name")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Description")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Active")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("For Fleet Type")]), _c('th', {
          staticClass: "text-center",
          staticStyle: {
            "width": "20px"
          }
        }, [_vm._v("#")])])]), _c('tbody', _vm._l(_vm.list.data, function (state, index) {
          var _state$vehicleType;
          return _c('tr', {
            key: index
          }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.code))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.name))]), _c('td', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.description))]), _c('td', {
            staticClass: "text-truncate text-center"
          }, [_vm._v(_vm._s(state !== null && state !== void 0 && state.active ? 'Yes' : 'No'))]), _c('td', {
            staticClass: "text-truncate text-center"
          }, [(state === null || state === void 0 ? void 0 : (_state$vehicleType = state.vehicleType) === null || _state$vehicleType === void 0 ? void 0 : _state$vehicleType.length) > 0 ? _c('div', {
            staticClass: "d-flex flex-wrap"
          }, _vm._l(state === null || state === void 0 ? void 0 : state.vehicleType, function (obj, objIndex) {
            return _c('b-badge', {
              key: objIndex,
              staticClass: "mr-2 mb-2",
              attrs: {
                "variant": "primary"
              }
            }, [_vm._v(" " + _vm._s(obj === null || obj === void 0 ? void 0 : obj.name) + " ")]);
          }), 1) : _c('span', [_vm._v("-")])]), _vm.updatePermission || _vm.deletePermission ? _c('td', [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('i', {
            staticClass: "fa fa-pen text-primary mr-3",
            staticStyle: {
              "cursor": "pointer"
            },
            on: {
              "click": function click($event) {
                return _vm.openslideFormSpesification('EDIT', state === null || state === void 0 ? void 0 : state.id);
              }
            }
          }), _c('i', {
            staticClass: "fa fa-trash text-danger",
            staticStyle: {
              "cursor": "pointer"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeleteSpesification(state);
              }
            }
          })])]) : _vm._e()]);
        }), 0)])]) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("No Spesification data found.")])])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "form-spesification",
      "title": "".concat(_vm.action, " Spesification"),
      "width": "40%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_c('b-row', {
          staticClass: "m-3"
        }, [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 text-primary"
        }, [_vm._v("Spesification")]), _c('b-row', {
          staticClass: "mt-2"
        }, [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('FormSpesification', {
          attrs: {
            "action": _vm.action,
            "edit-id": _vm.editId,
            "refresh-data": _vm.refreshData
          }
        })], 1)], 1)], 1)])], 1)];
      }
    }]),
    model: {
      value: _vm.slideFormSpesification,
      callback: function callback($$v) {
        _vm.slideFormSpesification = $$v;
      },
      expression: "slideFormSpesification"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }