var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-primary rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-primary"
        }, [_c('i', {
          staticClass: "fa fa-ship"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h4', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_c('b', [_vm._v("7")])])]), _c('h6', {}, [_vm._v("Total Docking")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-warning rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-warning"
        }, [_c('i', {
          staticClass: "fas fa-dollar-sign"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h4', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_c('b', [_vm._v("Rp. 3.552.017.210")])])]), _c('h6', {}, [_vm._v("Total Cost All Fleet")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-danger rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-danger"
        }, [_c('i', {
          staticClass: "fa fa-tools"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h4', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_c('b', [_vm._v("45")])])]), _c('h6', {}, [_vm._v("Total Job Docking")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-info rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-info"
        }, [_c('i', {
          staticClass: "fa fa-spinner"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h4', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_c('b', [_vm._v("36")])])]), _c('h6', {}, [_vm._v("On Progress Job")])])])];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', _vm._l(_vm.costDocking, function (item, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "lg": "12"
      }
    }, [_c('iq-card', {
      scopedSlots: _vm._u([{
        key: "headerTitle",
        fn: function fn() {
          return [_c('h4', [_vm._v(_vm._s(item.title))])];
        },
        proxy: true
      }, {
        key: "body",
        fn: function fn() {
          return [_c('HighChart', {
            ref: item.type,
            refInFor: true,
            attrs: {
              "option": item.bodyData
            }
          })];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("DOCKING HISTORY - TB. TAVIA 1")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', {
          staticClass: "mb-3"
        }, [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', [_vm._v("Select Docking Type")]), _c('b-form-select-option', [_vm._v("Intermediate Survey")]), _c('b-form-select-option', [_vm._v("Special Survey")]), _c('b-form-select-option', [_vm._v("Emergency Docking")])];
            },
            proxy: true
          }])
        })], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "primary",
            "size": "sm"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1), _c('table', {
          staticClass: "table table-striped table-sm"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("SHIPYARD NAME")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("DOCKING TYPE")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TOTAL JOB")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TOTAL COST")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("UPSLIP/DOCKING DATE")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("STATUS")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ACTION")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "pl-4"
        }, [_vm._v("PT. KRAKATAU SHIPYARD")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("Intermediate Survey")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("53 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Rp. 1.238.203.200,-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("15 Juni 2022")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          staticClass: "mr-1",
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("On Time : 45 Jobs")]), _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("Delay : 23 Jobs")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "sm",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.detail_docking",
            modifiers: {
              "detail_docking": true
            }
          }],
          attrs: {
            "href": "#",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-info-circle mr-2"
        }), _vm._v("Detail ")]), _c('b-dropdown-item', {
          attrs: {
            "variant": "primary"
          }
        }, [_c('a', {
          attrs: {
            "href": ""
          },
          on: {
            "click": function click($event) {
              _vm.slideHistoryMonthlyReport = !_vm.slideHistoryMonthlyReport;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-history mr-2"
        }), _vm._v("Monthly Report")])]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-trash mr-2"
        }), _vm._v("Delete ")])], 1)], 1)]), _c('tr', [_c('td', {
          staticClass: "pl-4"
        }, [_vm._v("ANUGRAH BUANA SHIPYARD")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("Intermediate Survey")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("36 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Rp. 1.375.123.180,-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("18 Januari 2022")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          staticClass: "mr-1",
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("On Time : 32 Jobs")]), _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("Delay : 4 Jobs")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "sm",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.detail_docking",
            modifiers: {
              "detail_docking": true
            }
          }],
          attrs: {
            "href": "#",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-info-circle mr-2"
        }), _vm._v("Detail ")]), _c('b-dropdown-item', {
          attrs: {
            "variant": "primary"
          }
        }, [_c('a', {
          attrs: {
            "href": ""
          },
          on: {
            "click": function click($event) {
              _vm.slideHistoryMonthlyReport = !_vm.slideHistoryMonthlyReport;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-history mr-2"
        }), _vm._v("Monthly Report")])]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-trash mr-2"
        }), _vm._v("Delete ")])], 1)], 1)]), _c('tr', [_c('td', {
          staticClass: "pl-4"
        }, [_vm._v("MARIBAYA SHIPYARD")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v("Special Survey")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("64 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Rp. 1.837.129.500,-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("25 September 2021")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          staticClass: "mr-1",
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("On Time : 43 Jobs")]), _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("Delay : 21 Jobs")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "sm",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.detail_docking",
            modifiers: {
              "detail_docking": true
            }
          }],
          attrs: {
            "href": "#",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-info-circle mr-2"
        }), _vm._v("Detail ")]), _c('b-dropdown-item', {
          attrs: {
            "variant": "primary"
          }
        }, [_c('a', {
          attrs: {
            "href": ""
          },
          on: {
            "click": function click($event) {
              _vm.slideHistoryMonthlyReport = !_vm.slideHistoryMonthlyReport;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-history mr-2"
        }), _vm._v("Monthly Report")])]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-trash mr-2"
        }), _vm._v("Delete ")])], 1)], 1)]), _c('tr', [_c('td', {
          staticClass: "pl-4"
        }, [_vm._v("PT. GLOBAL SHIPYARD INDONESIA")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("Emergency Docking")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("72 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Rp. 1.387.384.000,-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("08 Maret 2021")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          staticClass: "mr-1",
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("On Time : 52 Jobs")]), _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("Delay : 20 Jobs")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "sm",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.detail_docking",
            modifiers: {
              "detail_docking": true
            }
          }],
          attrs: {
            "href": "#",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-info-circle mr-2"
        }), _vm._v("Detail ")]), _c('b-dropdown-item', {
          attrs: {
            "variant": "primary"
          }
        }, [_c('a', {
          attrs: {
            "href": ""
          },
          on: {
            "click": function click($event) {
              _vm.slideHistoryMonthlyReport = !_vm.slideHistoryMonthlyReport;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-history mr-2"
        }), _vm._v("Monthly Report")])]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-trash mr-2"
        }), _vm._v("Delete ")])], 1)], 1)])])]), _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "value": 1,
            "total-rows": 50,
            "align": "center"
          }
        })], 1), _c('b-sidebar', {
          attrs: {
            "id": "detail_docking",
            "title": "DETAIL DOCKING - TB. TAVIA 1",
            "width": "60%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref) {
              var hide = _ref.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Docking Info")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Shipyard*")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Samudara Indonesia, PT")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("PIC*")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Jenis Pekerjaan*")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("General Overhaul")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Estimasi Budget")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp. 256.000.000,-")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("TA. Shipyard*")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("01 Oktober 2023")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Floating Repair")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("03 Oktober 2023")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Upslip*")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("05 Oktober 2023")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Downslip*")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("25 Oktober 2023")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Sea Trial")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("27 Oktober 2023")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Delivery")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("31 Oktober 2023")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Waiting Space")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("5 Hari")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Target Days")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("60 Hari")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Estimasi Tonase")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("8.9")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen Shipyard")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Docking Job")]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "primary"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-percent"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Job Category "), _c('b', [_vm._v("100%")])])]), _c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.jobCat-1",
                  modifiers: {
                    "jobCat-1": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-primary",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-tasks"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking / Undocking "), _c('b', [_vm._v("(2 Jobs) - 30%")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "jobCat-1",
                  "accordion": "my-accordion",
                  "role": "tabpanel"
                }
              }, [_c('b-card-body', [_c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-percent"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Job "), _c('b', [_vm._v("100%")])])]), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-1",
                  modifiers: {
                    "job-1-1": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-success",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Upslip")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("50% (Finish)")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                staticClass: "p-2",
                attrs: {
                  "id": "job-1-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Upslip")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Agung Tri Laksono")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("First Engineer")])]), _c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Second Engineer")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor ")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. CAHAYA PELAYARAN")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("328.000.000")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Nego")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("325.000.000")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v("Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Document")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #2")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor ")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. INTAN BAHARI")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Heri Budianto")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("178.000.000")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Nego")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("175.000.000")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v("Perbaikan lambung")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Document")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Daily Progress")]), _c('b-col', {
                staticClass: "mb-5",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Select Docking Job",
                  "label-for": "docking_job"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.docking_job
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("List Docking Job")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedDockingJob,
                  callback: function callback($$v) {
                    _vm.selectedDockingJob = $$v;
                  },
                  expression: "selectedDockingJob"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label-for": "progress_bar"
                }
              }, [_c('h4', {
                staticClass: "mb-2"
              }, [_vm._v("Progrees : "), _c('b-badge', {
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("80%")])], 1), _c('b-progress', {
                attrs: {
                  "value": 80,
                  "variant": "success",
                  "max": "100"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Start *",
                  "label-for": "start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Progress Pekerjaan *",
                  "label-for": "progress_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "progress_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "progress_pekerjaan",
                  "placeholder": "80"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Report Progress",
                  "label-for": "report_progress"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Check water tubes/plates and clean/free from clogged."
                }
              })], 1)], 1)], 1), _c('div', {
                staticClass: "form-group row align-items-center mt-3 m-0"
              }, [_c('div', {
                staticClass: "d-flex flex-grow-1 align-items-center"
              }, [_c('div', {
                staticClass: "send-panel"
              }, [_c('label', {
                staticClass: "mb-0 iq-bg-primary rounded",
                attrs: {
                  "for": "file"
                }
              }, [_c('input', {
                staticStyle: {
                  "display": "none"
                },
                attrs: {
                  "type": "file",
                  "id": "file"
                }
              }), _c('a', [_c('i', {
                staticClass: "ri-attachment-line"
              })])]), _c('label', {
                staticClass: "ml-2 mb-0 iq-bg-primary rounded"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0)"
                }
              }, [_c('i', {
                staticClass: "ri-gallery-line text-primary"
              })])])])]), _c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('div', {
                staticClass: "send-panel float-left"
              }, [_c('div', {
                staticClass: "send-btn"
              }, [_c('b-button', {
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-check"
              }), _vm._v(" Finish")]), _c('b-button', {
                staticClass: "ml-2",
                attrs: {
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-comment"
              }), _vm._v(" Update Activity")])], 1)])])])], 1), _c('ul', {
                staticClass: "iq-timeline",
                staticStyle: {
                  "height": "100%"
                }
              }, [_c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("24 November 2019 15:45")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Melakukan inspeksi pada kerusakan")]), _c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/6.jpg"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/1.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/4.jpg"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/3.jpg"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("23 November 2019 21:25")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Barang tiba di galangan")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Tri Leksono")]), _c('br'), _c('small', [_vm._v("22 November 2019 10:12")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Menunggu sparepart tiba")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("21 November 2019 11:21")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Melengkapi dokumen perbaikan")]), _c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])], 1)])], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-2",
                  modifiers: {
                    "job-1-2": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-info",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Downslip")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("50% (Progress)")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                staticClass: "p-2",
                attrs: {
                  "id": "job-1-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Downslip")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Agung Tri Laksono")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("First Engineer")])]), _c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Second Engineer")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor ")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. CAHAYA PELAYARAN")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("328.000.000")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Nego")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("325.000.000")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v("Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Document")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Daily Progress")]), _c('b-col', {
                staticClass: "mb-5",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Select Docking Job",
                  "label-for": "docking_job"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.docking_job
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("List Docking Job")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedDockingJob,
                  callback: function callback($$v) {
                    _vm.selectedDockingJob = $$v;
                  },
                  expression: "selectedDockingJob"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label-for": "progress_bar"
                }
              }, [_c('h4', {
                staticClass: "mb-2"
              }, [_vm._v("Progrees : "), _c('b-badge', {
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("80%")])], 1), _c('b-progress', {
                attrs: {
                  "value": 80,
                  "variant": "success",
                  "max": "100"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Start *",
                  "label-for": "start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Progress Pekerjaan *",
                  "label-for": "progress_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "progress_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "progress_pekerjaan",
                  "placeholder": "80"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Report Progress",
                  "label-for": "report_progress"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Check water tubes/plates and clean/free from clogged."
                }
              })], 1)], 1)], 1), _c('div', {
                staticClass: "form-group row align-items-center mt-3 m-0"
              }, [_c('div', {
                staticClass: "d-flex flex-grow-1 align-items-center"
              }, [_c('div', {
                staticClass: "send-panel"
              }, [_c('label', {
                staticClass: "mb-0 iq-bg-primary rounded",
                attrs: {
                  "for": "file"
                }
              }, [_c('input', {
                staticStyle: {
                  "display": "none"
                },
                attrs: {
                  "type": "file",
                  "id": "file"
                }
              }), _c('a', [_c('i', {
                staticClass: "ri-attachment-line"
              })])]), _c('label', {
                staticClass: "ml-2 mb-0 iq-bg-primary rounded"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0)"
                }
              }, [_c('i', {
                staticClass: "ri-gallery-line text-primary"
              })])])])]), _c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('div', {
                staticClass: "send-panel float-left"
              }, [_c('div', {
                staticClass: "send-btn"
              }, [_c('b-button', {
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-check"
              }), _vm._v(" Finish")]), _c('b-button', {
                staticClass: "ml-2",
                attrs: {
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-comment"
              }), _vm._v(" Update Activity")])], 1)])])])], 1), _c('ul', {
                staticClass: "iq-timeline",
                staticStyle: {
                  "height": "100%"
                }
              }, [_c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("24 November 2019 15:45")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Melakukan inspeksi pada kerusakan")]), _c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/6.jpg"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/1.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/4.jpg"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/3.jpg"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("23 November 2019 21:25")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Barang tiba di galangan")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Tri Leksono")]), _c('br'), _c('small', [_vm._v("22 November 2019 10:12")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Menunggu sparepart tiba")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("21 November 2019 11:21")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Melengkapi dokumen perbaikan")]), _c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])], 1)])], 1)], 1)], 1)], 1), _c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.jobCat-2",
                  modifiers: {
                    "jobCat-2": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-primary",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-tasks"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Sand Blasting "), _c('b', [_vm._v("(4 Jobs) - 70%")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "jobCat-2",
                  "accordion": "my-accordion",
                  "role": "tabpanel"
                }
              }, [_c('b-card-body', [_c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-percent"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Job "), _c('b', [_vm._v("100%")])])]), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-1",
                  modifiers: {
                    "job-2-1": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-success",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Scraping Teripting di Lambung & Lunas")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("25% (Finish)")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                staticClass: "p-2",
                attrs: {
                  "id": "job-2-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Scraping Teripting di Lambung & Lunas")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Agung Tri Laksono")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("First Engineer")])]), _c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Second Engineer")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor ")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. CAHAYA PELAYARAN")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("328.000.000")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Nego")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("325.000.000")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v("Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Document")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Daily Progress")]), _c('b-col', {
                staticClass: "mb-5",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Select Docking Job",
                  "label-for": "docking_job"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.docking_job
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("List Docking Job")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedDockingJob,
                  callback: function callback($$v) {
                    _vm.selectedDockingJob = $$v;
                  },
                  expression: "selectedDockingJob"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label-for": "progress_bar"
                }
              }, [_c('h4', {
                staticClass: "mb-2"
              }, [_vm._v("Progrees : "), _c('b-badge', {
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("80%")])], 1), _c('b-progress', {
                attrs: {
                  "value": 80,
                  "variant": "success",
                  "max": "100"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Start *",
                  "label-for": "start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Progress Pekerjaan *",
                  "label-for": "progress_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "progress_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "progress_pekerjaan",
                  "placeholder": "80"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Report Progress",
                  "label-for": "report_progress"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Check water tubes/plates and clean/free from clogged."
                }
              })], 1)], 1)], 1), _c('div', {
                staticClass: "form-group row align-items-center mt-3 m-0"
              }, [_c('div', {
                staticClass: "d-flex flex-grow-1 align-items-center"
              }, [_c('div', {
                staticClass: "send-panel"
              }, [_c('label', {
                staticClass: "mb-0 iq-bg-primary rounded",
                attrs: {
                  "for": "file"
                }
              }, [_c('input', {
                staticStyle: {
                  "display": "none"
                },
                attrs: {
                  "type": "file",
                  "id": "file"
                }
              }), _c('a', [_c('i', {
                staticClass: "ri-attachment-line"
              })])]), _c('label', {
                staticClass: "ml-2 mb-0 iq-bg-primary rounded"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0)"
                }
              }, [_c('i', {
                staticClass: "ri-gallery-line text-primary"
              })])])])]), _c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('div', {
                staticClass: "send-panel float-left"
              }, [_c('div', {
                staticClass: "send-btn"
              }, [_c('b-button', {
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-check"
              }), _vm._v(" Finish")]), _c('b-button', {
                staticClass: "ml-2",
                attrs: {
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-comment"
              }), _vm._v(" Update Activity")])], 1)])])])], 1), _c('ul', {
                staticClass: "iq-timeline",
                staticStyle: {
                  "height": "100%"
                }
              }, [_c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("24 November 2019 15:45")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Melakukan inspeksi pada kerusakan")]), _c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/6.jpg"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/1.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/4.jpg"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/3.jpg"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("23 November 2019 21:25")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Barang tiba di galangan")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Tri Leksono")]), _c('br'), _c('small', [_vm._v("22 November 2019 10:12")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Menunggu sparepart tiba")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("21 November 2019 11:21")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Melengkapi dokumen perbaikan")]), _c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])], 1)])], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-2",
                  modifiers: {
                    "job-2-2": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-success",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Pencucian Lambung dengan Air Tawar")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("25% (Finish)")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                staticClass: "p-2",
                attrs: {
                  "id": "job-2-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Pencucian Lambung dengan Air Tawar")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Agung Tri Laksono")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("First Engineer")])]), _c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Second Engineer")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor ")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. CAHAYA PELAYARAN")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("328.000.000")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Nego")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("325.000.000")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v("Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Document")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Daily Progress")]), _c('b-col', {
                staticClass: "mb-5",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Select Docking Job",
                  "label-for": "docking_job"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.docking_job
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("List Docking Job")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedDockingJob,
                  callback: function callback($$v) {
                    _vm.selectedDockingJob = $$v;
                  },
                  expression: "selectedDockingJob"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label-for": "progress_bar"
                }
              }, [_c('h4', {
                staticClass: "mb-2"
              }, [_vm._v("Progrees : "), _c('b-badge', {
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("80%")])], 1), _c('b-progress', {
                attrs: {
                  "value": 80,
                  "variant": "success",
                  "max": "100"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Start *",
                  "label-for": "start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Progress Pekerjaan *",
                  "label-for": "progress_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "progress_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "progress_pekerjaan",
                  "placeholder": "80"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Report Progress",
                  "label-for": "report_progress"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Check water tubes/plates and clean/free from clogged."
                }
              })], 1)], 1)], 1), _c('div', {
                staticClass: "form-group row align-items-center mt-3 m-0"
              }, [_c('div', {
                staticClass: "d-flex flex-grow-1 align-items-center"
              }, [_c('div', {
                staticClass: "send-panel"
              }, [_c('label', {
                staticClass: "mb-0 iq-bg-primary rounded",
                attrs: {
                  "for": "file"
                }
              }, [_c('input', {
                staticStyle: {
                  "display": "none"
                },
                attrs: {
                  "type": "file",
                  "id": "file"
                }
              }), _c('a', [_c('i', {
                staticClass: "ri-attachment-line"
              })])]), _c('label', {
                staticClass: "ml-2 mb-0 iq-bg-primary rounded"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0)"
                }
              }, [_c('i', {
                staticClass: "ri-gallery-line text-primary"
              })])])])]), _c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('div', {
                staticClass: "send-panel float-left"
              }, [_c('div', {
                staticClass: "send-btn"
              }, [_c('b-button', {
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-check"
              }), _vm._v(" Finish")]), _c('b-button', {
                staticClass: "ml-2",
                attrs: {
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-comment"
              }), _vm._v(" Update Activity")])], 1)])])])], 1), _c('ul', {
                staticClass: "iq-timeline",
                staticStyle: {
                  "height": "100%"
                }
              }, [_c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("24 November 2019 15:45")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Melakukan inspeksi pada kerusakan")]), _c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/6.jpg"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/1.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/4.jpg"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/3.jpg"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("23 November 2019 21:25")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Barang tiba di galangan")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Tri Leksono")]), _c('br'), _c('small', [_vm._v("22 November 2019 10:12")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Menunggu sparepart tiba")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("21 November 2019 11:21")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Melengkapi dokumen perbaikan")]), _c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])], 1)])], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-3",
                  modifiers: {
                    "job-2-3": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-info",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Sand Blasting Lunas")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("25% (Progress)")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                staticClass: "p-2",
                attrs: {
                  "id": "job-2-3",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sand Blasting Lunas")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Agung Tri Laksono")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("First Engineer")])]), _c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Second Engineer")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor ")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. CAHAYA PELAYARAN")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("328.000.000")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Nego")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("325.000.000")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v("Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Document")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Daily Progress")]), _c('b-col', {
                staticClass: "mb-5",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Select Docking Job",
                  "label-for": "docking_job"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.docking_job
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("List Docking Job")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedDockingJob,
                  callback: function callback($$v) {
                    _vm.selectedDockingJob = $$v;
                  },
                  expression: "selectedDockingJob"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label-for": "progress_bar"
                }
              }, [_c('h4', {
                staticClass: "mb-2"
              }, [_vm._v("Progrees : "), _c('b-badge', {
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("80%")])], 1), _c('b-progress', {
                attrs: {
                  "value": 80,
                  "variant": "success",
                  "max": "100"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Start *",
                  "label-for": "start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Progress Pekerjaan *",
                  "label-for": "progress_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "progress_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "progress_pekerjaan",
                  "placeholder": "80"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Report Progress",
                  "label-for": "report_progress"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Check water tubes/plates and clean/free from clogged."
                }
              })], 1)], 1)], 1), _c('div', {
                staticClass: "form-group row align-items-center mt-3 m-0"
              }, [_c('div', {
                staticClass: "d-flex flex-grow-1 align-items-center"
              }, [_c('div', {
                staticClass: "send-panel"
              }, [_c('label', {
                staticClass: "mb-0 iq-bg-primary rounded",
                attrs: {
                  "for": "file"
                }
              }, [_c('input', {
                staticStyle: {
                  "display": "none"
                },
                attrs: {
                  "type": "file",
                  "id": "file"
                }
              }), _c('a', [_c('i', {
                staticClass: "ri-attachment-line"
              })])]), _c('label', {
                staticClass: "ml-2 mb-0 iq-bg-primary rounded"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0)"
                }
              }, [_c('i', {
                staticClass: "ri-gallery-line text-primary"
              })])])])]), _c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('div', {
                staticClass: "send-panel float-left"
              }, [_c('div', {
                staticClass: "send-btn"
              }, [_c('b-button', {
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-check"
              }), _vm._v(" Finish")]), _c('b-button', {
                staticClass: "ml-2",
                attrs: {
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-comment"
              }), _vm._v(" Update Activity")])], 1)])])])], 1), _c('ul', {
                staticClass: "iq-timeline",
                staticStyle: {
                  "height": "100%"
                }
              }, [_c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("24 November 2019 15:45")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Melakukan inspeksi pada kerusakan")]), _c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/6.jpg"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/1.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/4.jpg"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/3.jpg"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("23 November 2019 21:25")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Barang tiba di galangan")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Tri Leksono")]), _c('br'), _c('small', [_vm._v("22 November 2019 10:12")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Menunggu sparepart tiba")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("21 November 2019 11:21")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Melengkapi dokumen perbaikan")]), _c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])], 1)])], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-4",
                  modifiers: {
                    "job-2-4": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-warning",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Sand Blasting Deck & Bullwark")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("15% (Progress)")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                staticClass: "p-2",
                attrs: {
                  "id": "job-2-4",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sand Blasting Lunas")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Agung Tri Laksono")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("First Engineer")])]), _c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Second Engineer")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor ")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. CAHAYA PELAYARAN")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("328.000.000")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Nego")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("325.000.000")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v("Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Document")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Daily Progress")]), _c('b-col', {
                staticClass: "mb-5",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Select Docking Job",
                  "label-for": "docking_job"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.docking_job
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("List Docking Job")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedDockingJob,
                  callback: function callback($$v) {
                    _vm.selectedDockingJob = $$v;
                  },
                  expression: "selectedDockingJob"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label-for": "progress_bar"
                }
              }, [_c('h4', {
                staticClass: "mb-2"
              }, [_vm._v("Progrees : "), _c('b-badge', {
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("80%")])], 1), _c('b-progress', {
                attrs: {
                  "value": 80,
                  "variant": "success",
                  "max": "100"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Start *",
                  "label-for": "start"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start"
                }
              }), _c('small', {
                staticStyle: {
                  "float": "right"
                }
              }, [_vm._v("click "), _c('i', {
                staticClass: "fa fa-calendar"
              }), _vm._v(" to show")])], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Progress Pekerjaan *",
                  "label-for": "progress_pekerjaan"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "progress_pekerjaan"
                }
              }, [_vm._v("%")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "text",
                  "id": "progress_pekerjaan",
                  "placeholder": "80"
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Report Progress",
                  "label-for": "report_progress"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Check water tubes/plates and clean/free from clogged."
                }
              })], 1)], 1)], 1), _c('div', {
                staticClass: "form-group row align-items-center mt-3 m-0"
              }, [_c('div', {
                staticClass: "d-flex flex-grow-1 align-items-center"
              }, [_c('div', {
                staticClass: "send-panel"
              }, [_c('label', {
                staticClass: "mb-0 iq-bg-primary rounded",
                attrs: {
                  "for": "file"
                }
              }, [_c('input', {
                staticStyle: {
                  "display": "none"
                },
                attrs: {
                  "type": "file",
                  "id": "file"
                }
              }), _c('a', [_c('i', {
                staticClass: "ri-attachment-line"
              })])]), _c('label', {
                staticClass: "ml-2 mb-0 iq-bg-primary rounded"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0)"
                }
              }, [_c('i', {
                staticClass: "ri-gallery-line text-primary"
              })])])])]), _c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('div', {
                staticClass: "send-panel float-left"
              }, [_c('div', {
                staticClass: "send-btn"
              }, [_c('b-button', {
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-check"
              }), _vm._v(" Finish")]), _c('b-button', {
                staticClass: "ml-2",
                attrs: {
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-comment"
              }), _vm._v(" Update Activity")])], 1)])])])], 1), _c('ul', {
                staticClass: "iq-timeline",
                staticStyle: {
                  "height": "100%"
                }
              }, [_c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("24 November 2019 15:45")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Melakukan inspeksi pada kerusakan")]), _c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/6.jpg"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/1.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/4.jpg"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/3.jpg"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("23 November 2019 21:25")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Barang tiba di galangan")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Tri Leksono")]), _c('br'), _c('small', [_vm._v("22 November 2019 10:12")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Menunggu sparepart tiba")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("21 November 2019 11:21")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Melengkapi dokumen perbaikan")]), _c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])], 1)])], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-2-5",
                  modifiers: {
                    "job-2-5": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-danger",
                attrs: {
                  "show": true
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_c('b', [_vm._v("Sand Blasting Main Deck")]), _vm._v(" - Persentase Pekerjaan "), _c('b', [_vm._v("10% (New)")])]), _c('a', {
                staticClass: "text-primary float-right text-white",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "far fa-hand-pointer"
              }), _vm._v(" Click Here ")])])], 1), _c('b-collapse', {
                staticClass: "p-2",
                attrs: {
                  "id": "job-2-5",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sand Blasting Main Deck")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Start Date")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-10")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Est. Finish")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("2022-06-15")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("50%")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Dokumen")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Created By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Review By")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                staticClass: "align-top"
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v(" Check water tubes/plates and clean/free from clogged."), _c('br'), _vm._v(" Check from any corrosion "), _c('br'), _vm._v(" Check zink anodes, replace when necessary"), _c('br'), _vm._v(" Check leakage after assembly ")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('table', {
                staticClass: "table table-striped mt-2"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Agung Tri Laksono")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("First Engineer")])]), _c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Nurul Akbar")]), _c('td', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Second Engineer")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor #1")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Kontraktor ")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("PT. CAHAYA PELAYARAN")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Executive Engineer")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri Laksono")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("328.000.000")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Penawaran Nego")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("325.000.000")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Notes")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v("Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Document")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1 pb-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])])])], 1)], 1)], 1)], 1)], 1)])], 1)];
            }
          }])
        }), _c('b-sidebar', {
          attrs: {
            "id": "slideHistoryMonthlyReport",
            "title": "HISTORY MONTHLY REPORT",
            "width": "100%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var hide = _ref2.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("TB. TAVIA 1")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('thead', [_c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("Bulan Report")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("TA. Arrival")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("Star Floating")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("Uplsip")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("Delivery Date")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("Waiting Space")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("Target Days")]), _c('th', {
                staticClass: "text-center text-primary"
              }, [_vm._v("Performance Monthly")]), _c('th', {
                staticClass: "text-center text-danger"
              }, [_vm._v("Actual Docking Days")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("Action")])])]), _c('tbody', [_c('tr', [_c('td', {
                staticClass: "text-center"
              }, [_vm._v("30 Sept 2023")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("07/06/2023")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("15/06/2023")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("04/07/2023")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("27 Days")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("60 Days")]), _c('td', {
                staticClass: "text-center bg-primary"
              }, [_vm._v("30 Days")]), _c('td', {
                staticClass: "text-center bg-danger"
              }, [_vm._v("88 Days")]), _c('td', {
                staticClass: "text-center"
              }, [_c('a', {
                attrs: {
                  "href": ""
                }
              }, [_c('b-button', {
                staticClass: "mr-1",
                attrs: {
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-ship"
              })])], 1)])]), _c('tr', [_c('td', {
                staticClass: "text-center"
              }, [_vm._v("31 Agustus 2023")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("07/06/2023")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("15/06/2023")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("04/07/2023")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("27 Days")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("60 Days")]), _c('td', {
                staticClass: "text-center bg-primary"
              }, [_vm._v("31 Days")]), _c('td', {
                staticClass: "text-center bg-danger"
              }, [_vm._v("57 Days")]), _c('td', {
                staticClass: "text-center"
              }, [_c('a', {
                attrs: {
                  "href": ""
                }
              }, [_c('b-button', {
                staticClass: "mr-1",
                attrs: {
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-ship"
              })])], 1)])]), _c('tr', [_c('td', {
                staticClass: "text-center"
              }, [_vm._v("31 Juli 2023")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("07/06/2023")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("15/06/2023")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("04/07/2023")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("27 Days")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("60 Days")]), _c('td', {
                staticClass: "text-center bg-primary"
              }, [_vm._v("31 Days")]), _c('td', {
                staticClass: "text-center bg-danger"
              }, [_vm._v("26 Days")]), _c('td', {
                staticClass: "text-center"
              }, [_c('a', {
                attrs: {
                  "href": ""
                }
              }, [_c('b-button', {
                staticClass: "mr-1",
                attrs: {
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-ship"
              })])], 1)])]), _c('tr', [_c('td', {
                staticClass: "text-center"
              }, [_vm._v("30 Juni 2023")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("07/06/2023")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("15/06/2023")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("04/07/2023")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("27 Days")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("60 Days")]), _c('td', {
                staticClass: "text-center bg-primary"
              }, [_vm._v("30 Days")]), _c('td', {
                staticClass: "text-center bg-danger"
              }, [_vm._v("26 Days")]), _c('td', {
                staticClass: "text-center"
              }, [_c('a', {
                attrs: {
                  "href": ""
                }
              }, [_c('b-button', {
                staticClass: "mr-1",
                attrs: {
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-ship"
              })])], 1)])])])])])])], 1)];
            }
          }]),
          model: {
            value: _vm.slideHistoryMonthlyReport,
            callback: function callback($$v) {
              _vm.slideHistoryMonthlyReport = $$v;
            },
            expression: "slideHistoryMonthlyReport"
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }