var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('StepWizard', {
    attrs: {
      "current-step": "LIST_RCA"
    }
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Root Cause Analysis")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.isPreview ? _c('RootAnalysisPreview') : _c('RootCauseAnalysisForm')];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }