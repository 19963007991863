<template>
  <b-row>
    <b-col lg="12" class="px-5 py-2">
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title text-primary"><strong>List Birthday Crew</strong></h4>
        </template>
        <template v-slot:body>
          <b-row>
            <b-col md="12" class="mb-2" v-if="list?.data?.length > 0">
              <b-button variant="primary" @click="onExportListBirtday">
                <div class="d-flex gap-2 align-items-center"> 
                  <span class="spinner-border spinner-border-sm mr-2" v-if="exporting"></span>
                  <i class="fa fa-file-excel mr-2" v-else></i> 
                  <span>Export</span>
                </div>
              </b-button>
            </b-col>
            <b-col md="12">
              <div class="d-flex w-100 align-items-center justify-content-between mb-4">
                <div class="d-flex align-items-center ">
                  <b-form-input class="align-self-end w-50 mr-2" v-model="filter" placeholder="Search name..."></b-form-input>
                  <date-picker
                    v-model="dateRangeBirthday"
                    type="date"
                    range
                    placeholder="Select date range"
                    value-type="YYYY-MM-DD"
                  ></date-picker>
                </div>
                <b-button
                  @click="filterDate"
                  variant="primary"
                  ><i class="fa fa-filter"></i
                ></b-button>
              </div>
            </b-col>
            <b-col  v-if="loading" md="12">
              <div class="alert alert-info m-4 align-items-center ">
                <span class="spinner-border spinner-border-sm mr-2"></span>  Loading...
              </div>
            </b-col>
            <b-col md="12" v-else>
              <div class="d-flex overflow-auto" v-if="list?.data?.length > 0">
                <table class="table table-bordered" >
                  <thead>
                    <tr>
                      <th class=" text-center">NO</th>
                      <th class=" text-center text-truncate">Name</th>
                      <th class=" text-center text-truncate">Fleet</th>
                      <th class=" text-center text-truncate">Position</th>
                      <th class=" text-center text-truncate">Birthday</th>
                      <th class=" text-center text-truncate">Age</th>
                      <th class=" text-center text-truncate">Join on Board</th>
                      <th class=" text-center text-truncate">Sign On</th>
                      <th class=" text-center text-truncate">Sign Off</th>
                      <th class=" text-center">Status</th>
                    </tr>
                  </thead>
  
                  <tbody>
                    <tr v-for="(crew, i) in list?.data" :key="'crew-' + i" >
                      <td class="text-center">{{ list?.from + i }}</td>
                      <td class="text-truncate">
                        <router-link
                          :to="{
                            path: `profile`,
                            query: {
                              user: 'crew',
                              id: crew.emp_crewing_id,
                            },
                          }">{{ crew?.emp_crewing?.fullname }}</router-link><br />
                          <small style="color: gray">NIP : <b>{{ crew?.emp_crewing?.nip ?? '-' }}</b></small>
                      </td>
                      <td class="text-center text-truncate">{{ crew?.emp_crewing?.vehicle?.name ?? '-' }}
                        <br />
                        <small style="color: gray">{{crew?.emp_crewing?.company?.company ?? '-'}}</small>
                      </td>
                      <td class="text-center text-truncate ">{{ crew?.emp_crewing?.position?.name ?? '-' }} </td>
                      <td class="text-center text-truncate">{{ crew?.date_of_birth ? moment(crew?.date_of_birth).format('DD MMM YYYY') : '-' }}
                      <td class="text-center text-truncate">{{ crew?.date_of_birth ? calculateAge(crew?.date_of_birth) : '-' }}
                      <td class="text-center text-truncate">{{ crew?.emp_crewing?.join_on_board ? moment(crew?.emp_crewing?.join_on_board).format('DD MMM YYYY HH:mm') : '-' }}
                        <b-badge
                          :variant="
                            joinOnBoardStatus(crew) === 'More than 1 year'
                              ? 'success d-block'
                              : joinOnBoardStatus(crew) === 'More than 6 month'
                              ? 'primary d-block'
                              : joinOnBoardStatus(crew) === 'More than 3 month'
                              ? 'warning d-block'
                              : 'dark d-block'
                          "
                          v-if="joinOnBoardStatus(crew)">{{ joinOnBoardStatus(crew) }}</b-badge>
                      </td>
                      <td class="text-center text-truncate">{{ crew?.emp_crewing?.sign_on ? moment(crew?.emp_crewing?.sign_on).format('DD MMM YYYY HH:mm') : '-' }}</td>
                      <td class="text-center text-truncate">{{ crew?.emp_crewing?.sign_off ? moment(crew?.emp_crewing?.sign_off).format('DD MMM YYYY HH:mm') :  '-' }} <br /> </td>
                      <td class="text-center text-truncate">
                        <b-badge class="mb-1" :variant="`${crew?.status_emp_crewing?.alias === 'active' ? 'success' : 'warning'} d-block`"
                          >{{ crew?.status_emp_crewing?.name ?? '-' }}
                        </b-badge>
                        <b-badge variant="danger d-block" v-if="expiredContract(crew)"
                          >{{ expiredContract(crew) }}
                        </b-badge>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="mt-3" v-if="list?.data?.length > 0">
                <b-pagination
                  :value="list?.current_page"
                  :total-rows="list?.total"
                  :per-page="list?.per_page"
                  first-number
                  @change="getListBirtdayCrew"
                  align="center"
                />
              </div>
              <p v-else class="text-center my-4">List Crew Birthday tidak ada.</p>
            </b-col>
          </b-row>
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>

<script>
import moment from 'moment'
import { saveAs } from 'file-saver'
import { crewingActions } from '@src/Utils/helper'

export default {
  name: 'SlideListBirthday',
  props:{  
  },
  data(){
    return {
      loading:false,
      exporting:false,
      filter:'',
      dateRangeBirthday: [moment().startOf('month').format('YYYY-MM-DD'),moment().endOf('month').format('YYYY-MM-DD')], 

      list:null,
    }
  },
  async mounted(){
    await this.getListBirtdayCrew()
  },
  methods:{
    ...crewingActions,
    calculateAge(born) {
      const today = new Date();
      const birthdate = new Date(born);
      let age = today.getFullYear() - birthdate.getFullYear();
      const monthDiff = today.getMonth() - birthdate.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthdate.getDate())) {
        age--;
      }

      return age;
    },
    joinOnBoardStatus(data) {
      if (!data?.emp_crewing?.join_on_board) return ''
      if (data.emp_crewing?.working_period > 12) {
        return 'More than 1 year'
      } else if (data.emp_crewing?.working_period > 6) {
        return 'More than 6 month'
      } else if (data.emp_crewing?.working_period > 3) {
        return 'More than 3 month'
      } else {
        return 'Less than 3 month'
      }
    },
    expiredContract(data) {
      return moment(data?.emp_crewing?.sign_off).format('DD-MM-YYYY') <
        moment(new Date()).format('DD-MM-YYYY') &&
        data?.emp_crewing?.status_emp_crewing?.name === 'Active'
        ? 'Out of Contract'
        : ''
    },
    async getListBirtdayCrew(page){
      try {
        this.loading = true

        let start_date = this.dateRangeBirthday?.[0] ? moment(new Date(this.dateRangeBirthday[0])).format('YYYY-MM-DD') : null
        let end_date = this.dateRangeBirthday?.[1] ? moment(new Date(this.dateRangeBirthday[1])).format('YYYY-MM-DD') : null
    
        let params = {
          start_date:start_date,
          end_date:end_date,
          export: 0,
          page: page || 1,
          per_page:50,
          filter:this.filter
        }

        if(!params.start_date) delete params.start_date
        if(!params.end_date) delete params.end_date
        if(!params.filter) delete params.filter

        const res = await this.fetchListBirthdayCrew(params)

        this.list = res.data
        this.loading = false
      } catch (err) {
        this.loading = false
        this.list = null
      }
    },

    async onExportListBirtday(){
      try {
        this.exporting = true

        let start_date = this.dateRangeBirthday?.[0] ? moment(new Date(this.dateRangeBirthday[0])).format('YYYY-MM-DD') : null
        let end_date = this.dateRangeBirthday?.[1] ? moment(new Date(this.dateRangeBirthday[1])).format('YYYY-MM-DD') : null

        let params = {
          start_date:start_date,
          end_date:end_date,
          export: 1,
          filter:this.filter
        }

        if(!params.start_date) delete params.start_date
        if(!params.end_date) delete params.end_date
        if(!params.filter) delete params.filter

        const res = await this.exportListBirthdayCrew(params)

        saveAs(res?.data, 'List Birthday.xlsx')
        this.exporting = false
      } catch (error) {
        this.$swal(`Oops!`, 'Export Failed: ' + error, 'error')
        this.exporting = false
      }
    },

    async filterDate(){
      await this.getListBirtdayCrew(1)
    },
  }
}
</script>