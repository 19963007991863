<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <b-button variant="primary" class="float-right mr-1" size="sm" type="button" @click="openSlideFormPayment('ADD', null)"><i class="fa fa-plus"></i> &nbsp; ADD DATA</b-button>
      </b-col>
      <!-- FILTER -->
      <b-col md="12">
        <b-row>
          <b-col md="4">
            <b-form-group label="Periode" label-for="periode">
              <date-picker v-model="dateRange" type="date" range placeholder="Select date range"></date-picker>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Search" label-for="Period">
              <b-form-input v-model="params.search"  placeholder="Search..."></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Status Payment" label-for="status">
              <b-form-select plain v-model="params.status" class="mb-2">
                <b-form-select-option value="">All</b-form-select-option>
                <b-form-select-option value="request">Request</b-form-select-option>
                <b-form-select-option value="paid">Paid</b-form-select-option>
                <b-form-select-option value="revision">Revision</b-form-select-option>
                <b-form-select-option value="canceled">Canceled</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="1">
            <b-form-group style label="Filter" label-for="button_search">
              <b-button variant="primary" @click="getListPayment(1)" type='button'><i class="fa fa-search"></i></b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>

      <!-- CONTENT TOTAL -->
      <b-col md="12">
        <b-row>
          <b-col md="3">
            <iq-card class-name="iq-card-block iq-card-stretch" body-class="bg-primary rounded">
              <template v-slot:body>
                <div class="d-flex align-items-center justify-content-between">
                  <div class="text-left">
                    <h4 class="mb-0 text-white font-weight-bold text-truncate ">IDR {{formattedCurrencyIDR(paymentTotal?.total_billing)}}</h4>
                    <span class=" text-white">Total Billing</span>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
          <b-col md="3">
            <iq-card class-name="iq-card-block iq-card-stretch" body-class="bg-primary rounded">
              <template v-slot:body>
                <div class="d-flex align-items-center justify-content-between">
                  <div class="text-left">
                    <h4 class="mb-0 text-white font-weight-bold text-truncate ">IDR {{formattedCurrencyIDR(paymentTotal?.total_unpaid)}}</h4>
                    <span class=" text-white">Total Unpaid</span>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
          <b-col md="3">
            <iq-card class-name="iq-card-block iq-card-stretch" body-class="bg-primary rounded">
              <template v-slot:body>
                <div class="d-flex align-items-center justify-content-between">
                  <div class="text-left">
                    <h4 class="mb-0 text-white font-weight-bold text-truncate ">IDR {{formattedCurrencyIDR(paymentTotal?.total_paid)}}</h4>
                    <span class=" text-white">Total Paid</span>
                  </div>
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </b-col>

      <!-- CONTENT TABLE -->
      <b-col md="12">
        <template>
          <div class="d-flex overflow-auto">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th class="align-middle " style="width:5px">No</th>
                  <th class="align-middle text-truncate">For Trip</th>
                  <th class="align-middle text-truncate">Request Name</th>
                  <th class="align-middle text-truncate">Request Date</th>
                  <th class="align-middle text-truncate">Invoice Price</th>
                  <th class="align-middle text-truncate">Tax</th>
                  <th class="align-middle text-truncate">Invoice Total</th>
                  <th class="align-middle text-truncate">Balance</th>
                  <th class="align-middle text-truncate">Invoice Percent Age</th>
                  <th class="align-middle text-truncate">Real Pricing</th>
                  <th class="align-middle text-truncate">Real Invoice Number</th>
                  <th class="align-middle text-truncate">Invoice Date</th>
                  <th class="align-middle text-truncate">Due Date</th>
                  <th class="align-middle text-truncate">Invoice File</th>
                  <th class="align-middle text-truncate">Status</th>
                  <th class="align-middle text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="list.length > 0">
                  <tr v-for="(state, index) in list" :key="index">
                    <td class="text-truncate">{{index + 1}}</td>
                    <td class="text-truncate">{{state?.project_plan?.shipment_plan_detail_number ?? '-'}}</td>
                    <td class="text-truncate">{{state?.request_name}}</td>
                    <td class="text-truncate">{{state?.request_date ? moment(state?.request_date).format('DD MMM YYYY') : '-'}}</td>
                    <td class="text-truncate">{{state?.invoice_price ? formatCurrency(state?.invoice_price) : '-'}}</td>
                    <td class="text-truncate">{{state?.name_tax ?? '-'}}</td>
                    <td class="text-truncate">{{state?.invoice_total ? formatCurrency(state?.invoice_total) : '-'}}</td>
                    <td class="text-truncate">{{state?.balance?.toLocaleString() ?? '-'}}</td>
                    <td class="text-truncate">-</td>
                    <td class="text-truncate">{{state?.real_pricing ? formatCurrency(state?.real_pricing) : '-'}}</td>
                    <td class="text-truncate">{{state?.real_invoice_number ?? '-'}}</td>
                    <td class="text-truncate">{{state?.invoice_date ? moment(state?.invoice_date).format('DD MMM YYYY') : '-'}}</td>
                    <td class="text-truncate">{{state?.due_date ? moment(state?.due_date).format('DD MMM YYYY') : '-'}}</td>
                    <td class="text-truncate">
                      <div class="d-flex flex-column">
                        <div class="d-flex align-items-center mb-2" style="cursor:pointer" v-for="(file, fileIndex) in state?.real_invoice_attachment" :key="fileIndex">
                          <i class="fa fa-file text-primary mr-2" />
                          <span class="text-primary">{{file.file_name}}</span>
                        </div>
                      </div>
                    </td>
                    <td class="text-truncate">{{state?.status ? 'Active' : 'Inactive'}}</td>
                    <td class="text-center">
                      <b-dropdown 
                        size="lg"
                        variant="link iq-bg-primary iq-border-radius-10"
                        toggle-class="text-decoration-none"
                        no-caret
                      >
                        <template v-slot:button-content>
                          <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                            <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                          </span>
                        </template>
                        <b-dropdown-item href="#" variant="primary" @click="openSlideFormPayment('PREVIEW', state?.id)">
                          <i class="fa fa-eye mr-2"></i>Detail
                        </b-dropdown-item>
                        <b-dropdown-item href="#" variant="primary" @click="openSlideFormPayment('EDIT', state?.id)">
                          <i class="fa fa-pen mr-2"></i>Edit
                        </b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item href="#" variant="warning" @click="openSlideSendMail('ADD', projectData?.id, state?.id)">
                          <i class="fa fa-envelope mr-2"></i>Send Mail
                        </b-dropdown-item>
                        <b-dropdown-item href="#" variant="warning" @click="openSlideHistoryMail('PREVIEW', state?.id)" v-if="state?.history_mail?.length > 0">
                          <i class="fa fa-envelope mr-2"></i>History Mail
                        </b-dropdown-item>
                        <b-dropdown-item href="#" variant="warning" @click="openSlideRealInvoice(state?.id)" v-if="!state?.real_invoice_fulfilled">
                          <i class="fa fa-scroll mr-2"></i>Real Invoice
                        </b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item href="#" variant="danger" @click="onDeletePayment(state)">
                          <i class="fa fa-trash mr-2"></i>Delete
                        </b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </template>

        <div class="mt-3" v-if="list.length > 0">
          <b-pagination
            v-model="page"
            :total-rows="meta?.totalItem"
            @change="getListPayment"
            :per-page="meta?.perPage"
            first-number
            align="center"
          ></b-pagination>
        </div>
        <div class="d-flex align-items-center justify-content-center w-100 my-2 mb-4" v-else>
          <strong class="text-muted">No Data Payment List.</strong>
        </div>
      </b-col>

      <!-- SLIDE - FORM PAYMENT LIST -->
      <b-col md="12">
        <b-sidebar
          v-model="slideFormPaymentList"
          id="slideFormPaymentList"
          :title="`${action} Payment List`"
          width="70%"
          backdrop-variant="dark"
          bg-variant="white"
          right
          backdrop
          shadow>
          <template #default="{hide}">
            <SlidePaymentList
              :closeSlideFormPayment="closeSlideFormPayment"
              :refreshData="refreshData"
              :projectData="projectData"
              :totalUnpaid="paymentTotal.total_unpaid"
              :id="id"
              :action="action"
            />
          </template>
        </b-sidebar>
      </b-col>
      <!-- SLIDE - FORM PAYMENT LISTT END -->

        <!-- SLIDE - FORM REAL INVOICES -->
       <b-col md="12">
        <b-sidebar
          v-model="slideRealInvoice"
          id="slideRealInvoice"
          :title="`Create Real Invoice`"
          width="40%"
          backdrop-variant="dark"
          bg-variant="white"
          right
          backdrop
          shadow>
          <template #default="{hide}">
            <SlideRealInvoice
              :closeSlideRealInvoice="closeSlideRealInvoice"
              :refresh-data="refreshData"
              :paymentId="id"
            />
          </template>
        </b-sidebar>
      </b-col>
      <!-- SLIDE - FORM REAL INVOICE END -->

      <!-- SLIDE - SEND MAIL -->
      <b-col md="12">
        <b-sidebar
          v-model="slideSendMail"
          id="slideSendMail"
          :title="'Send Mail'"
          width="90%"
          backdrop-variant="dark"
          bg-variant="white"
          right
          backdrop
          shadow>
          <template #default="{hide}">
            <SlideSendMail
              :projectId="projectId"
              :id="id"
              :closeSlideSendMail="closeSlideSendMail"
              :projectData="projectData"
            />
          </template>
        </b-sidebar>
      </b-col>
      <!-- SLIDE - SEND MAIL END -->

       <!-- SLIDE - SEND MAIL -->
       <b-col md="12">
        <b-sidebar
          v-model="slideHistoryMail"
          id="slideHistoryMail"
          :title="'History Mail'"
          width="50%"
          backdrop-variant="dark"
          bg-variant="white"
          right
          backdrop
          shadow>
          <template #default="{hide}">
            <SlideHistoryMail
              :id="id"
              :closeSlideHistoryMail="closeSlideHistoryMail"
            />
          </template>
        </b-sidebar>
      </b-col>
      <!-- SLIDE - SEND MAIL END -->
    </b-row>
  </b-container>
</template>

<script>
  import _ from 'lodash'
  import moment from 'moment'
  import SlidePaymentList from './SlidePaymentList.vue'
  import SlideRealInvoice from './SlideRealInvoice.vue'
  import SlideSendMail from './SlideSendMail.vue' 
  import SlideHistoryMail from './SlideHistoryMail.vue' 
  import { fleetsActions, crewingActions, busdevActions } from '@src/Utils/helper'

  export default {
    name:'TabPaymentList',
    props:{
      projectData:{
        type:Object,
        default:null
      },
    },
    components:{
      SlidePaymentList,
      SlideRealInvoice,
      SlideSendMail,
      SlideHistoryMail,
    },
    data(){
      return{
        slideSendMail:false,
        slideHistoryMail:false,
        slideFormPaymentList:false,
        slideRealInvoice:false,

        id:null,
        projectId:null,

        page:1,
        action:'',
        dateRange:[],
        params: {
          startDate:null,
          endDate:null,
          search:'',
          status:'',
        },
        paymentTotal:{},
        list:[],
        meta:null,
      }
    },
    watch:{

    },
    mounted(){
      this.getListPayment(1)
    },
    computed:{},
    methods:{
      ...busdevActions,
      openSlideHistoryMail(value, id){
        this.id = null
        this.slideHistoryMail = !this.slideHistoryMail;

        if(id) this.id = id
      }, 
      closeSlideHistoryMail(){
        this.id = null
        this.slideHistoryMail = false
      },  
      openSlideSendMail(value, project_id, payment_id){
        this.id = null
        this.projectId = null

        this.slideSendMail = !this.slideSendMail;

        if(project_id) this.projectId = project_id
        if(payment_id) this.id = payment_id
      },  
      closeSlideSendMail(){
        this.id = null
        this.slideSendMail = false
      },  
      closeSlideFormPayment(){
        this.id = null
        this.action = ''
        this.slideFormPaymentList = false
      },  
      openSlideFormPayment(value, id){
        this.id = null
        this.action = value
        this.slideFormPaymentList = !this.slideFormPaymentList;

        if(id) this.id = id
      },
      closeSlideRealInvoice(){
        this.id = null
        this.action = ''
        this.slideRealInvoice = false
      },
      openSlideRealInvoice(id){
        this.id = null
        this.slideRealInvoice = !this.slideRealInvoice;

        if(id) this.id = id
      },
      formattedCurrencyIDR(number){
        const formattedNumber = new Intl.NumberFormat('id-ID').format(number);
        let formattedIDR = formattedNumber.replace('.', ',').replace(',', '.');
        // Check if there are decimal digits present
        let decimalIndex = formattedIDR.indexOf(',');
        if (decimalIndex === -1) {
          // No decimal digits present, append ",00"
          formattedIDR += ',00';
        } else if (formattedIDR.length - decimalIndex - 1 === 1) {
          // Only one decimal digit present, append trailing zero
          formattedIDR += '0';
        }

        return formattedIDR
      },
      formatCurrency(value) {
        return new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        }).format(value)
      },
     
      async getListPayment(page){
        let params = {
          params:{
            ...this.params,
            page: page ?? 1,
            perPage: 25,
            startDate: this.dateRange?.[0] ? moment(this.dateRange?.[0]).format('YYYY-MM-DD') : null,
            endDate: this.dateRange?.[1] ? moment(this.dateRange?.[1]).format('YYYY-MM-DD') : null,
          },
          project_contract_id: this.projectData.id
        }

        // Iterate over each property in params
        for (const prop in params.params) {
          if (params.params.hasOwnProperty(prop)) {
            // Check conditions for each property and delete if necessary
            if ((params.params[prop] === '') || (params.params[prop] === null)) {
              delete params.params[prop];
            }
          }
        }

        const res = await this.fetchPaymentList(params)

        if(res.data.status === true){
          this.list = res.data.data.list
          this.meta = res.data.data.meta
          this.paymentTotal = res.data.data
          delete this.paymentTotal.list
        } else {
          this.list = []
          this.paymentTotal = {}
        }
      },
      refreshData(){
        this.getListPayment()
      },

      async onDeletePayment(data){
        this.$swal({
            title: `Are you sure want to payment ${data?.request_name}?`,
            text: "Yes, i'm sure.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
          if (result.isConfirmed) {
          const res = await this.deletePaymentList({id:data.id})
            if(res.status === false || res.status === 400 || res.status === 500){
              this.$swal(
                  'Failed!',
                  'Something went wrong.',
                  'error'
              )
              return 
            } 

            this.$swal(
              'Success!',
              'Success delete payment.',
              'success'
            )
            this.refreshData()
          }
        })
      },
    }
  }
</script>
