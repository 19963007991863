<template>
  <iq-card>
    <template v-slot:body>
      <div class="row">
        <div class="col-md-6">
          <b-button v-if="prevUrl" @click="$router.push(prevUrl)" variant="outline-danger">{{ prevNavTitle }}</b-button>
        </div>
        <div class="col-md-6 text-right">
          <b-button v-if="nextUrl" @click="$router.push(nextUrl)" variant="outline-success">{{ nextNavTitle }}</b-button>
        </div>
      </div>
    </template>
  </iq-card>
</template>
<script>
import { xray } from '../../../config/pluginInit'

export default {
  name: 'StepNavigation',
  mounted () {
    xray.index()
  },
  props: {
    currentStep: {
      type: String,
      default: 'CLAIM_ANALYSIS'
    },
    claimType: {
      type: String,
      default: 'CLAIM'
    }
  },
  computed: {
    idParam () {
      let ret = ''

      if (this.$route.params.id) {
        ret = `/${this.$route.params.id}`
      } else if (this.$route.query.id) {
        ret = `id=${this.$route.query.id}`
      }

      return ret
    },
    formIdParam () {
      let ret = ''

      ret = `formId=${this.$route.query.formId}`

      return ret
    },
    prevUrl () {
      if (this.currentStep === 'CLAIM_ANALYSIS') {
        return '/mi/list-initial'
      }

      let menuAccess = this.lsProfile.menu.find(val => val.menuId === 2)
      let menuChildAccess = menuAccess.child.find(val => val.menuId === 52)

      const stepIndex = this.handleGetStepIndex()
      const prevStep = this.steps.filter(e => e.claimType.includes(this.claimType))[stepIndex - 1]

      let isHaveAccess = menuChildAccess.child.find(val => prevStep.menuId === val.menuId)

      if (prevStep && isHaveAccess.add || isHaveAccess.update ) {
        // return `${prevStep.url}${this.idParam}${this.formIdParam}`
        return `${prevStep.url}${this.formIdParam}`
      }

      return '/mi/list-initial'
    },
    prevNavTitle () {
      if (this.currentStep === 'CLAIM_ANALYSIS') {
        return 'Back'
      }

      let menuAccess = this.lsProfile.menu.find(val => val.menuId === 2)
      let menuChildAccess = menuAccess.child.find(val => val.menuId === 52)
     
      const stepIndex = this.handleGetStepIndex()
      const prevStep = this.steps.filter(e => e.claimType.includes(this.claimType))[stepIndex - 1]

      let isHaveAccess = menuChildAccess.child.find(val => prevStep.menuId === val.menuId)

      if (prevStep && isHaveAccess.add || isHaveAccess.update ) {
        return `Back to ${prevStep.title}`
      }

      return 'Back'
    },
    nextUrl () {
      let menuAccess = this.lsProfile.menu.find(val => val.menuId === 2)
      let menuChildAccess = menuAccess.child.find(val => val.menuId === 52)

      const stepIndex = this.handleGetStepIndex()
      const nextStep = this.steps.filter(e => e.claimType.includes(this.claimType))[stepIndex + 1]

      let isHaveAccess = menuChildAccess.child.find(val => nextStep.menuId === val.menuId)

      if (nextStep && isHaveAccess.add || isHaveAccess.update ) {
        // return `${nextStep.url}${this.idParam}${this.formIdParam}`
        return `${nextStep.url}${this.formIdParam}`
      }

      return ''
    },
    nextNavTitle () {
      let menuAccess = this.lsProfile.menu.find(val => val.menuId === 2)
      let menuChildAccess = menuAccess.child.find(val => val.menuId === 52)

      const stepIndex = this.handleGetStepIndex()
      const nextStep = this.steps.filter(e => e.claimType.includes(this.claimType))[stepIndex + 1]

      let isHaveAccess = menuChildAccess.child.find(val => nextStep.menuId === val.menuId)

      if (nextStep && isHaveAccess.add || isHaveAccess.update ) {
        return `Go to ${nextStep.title}`
      }

      return 'Go'
    }
  },
  methods: {
    handleGetStepIndex () {
      return this.steps.filter(e => e.claimType.includes(this.claimType)).map(e => e.key).indexOf(this.currentStep)
    }
  },
  data () {
    return {
      lsProfile: JSON.parse(localStorage.getItem('profile')), // BASED ON APPROVAL
      steps: [
        {
          key: 'CLAIM_ANALYSIS',
          title: 'Claim Analysis',
          url: '/mi/claim-analysis?',
          menuId: 118,
          claimType: ['CLAIM']
        },
        {
          key: 'COST_BREAKDOWN',
          title: 'Cost Breakdown',
          url: '/mi/cost-breakdown?',
          menuId: 119,
          claimType: ['CLAIM']
        },
        {
          key: 'LOST_RATIO',
          title: 'Loss Ratio',
          url: '/mi/loss-ratio-input?',
          menuId: 120,
          claimType: ['CLAIM']
        },
        {
          key: 'MANAGEMENT_APPROVAL',
          title: 'Management Approval',
          url: '/mi/management-approval?',
          menuId: 121,
          claimType: ['CLAIM']
        },
        {
          key: 'LIST_RCA',
          title: 'List RCA',
          url: '/mi/root-cause-analysis?',
          menuId: 122,
          claimType: ['CLAIM']
        },
        {
          key: 'RCA_FINAL',
          title: 'RCA Final',
          url: '/mi/final-report-root-cause-analysis?',
          menuId: 123,
          claimType: ['CLAIM']
        },
        {
          key: 'MONITORING',
          title: 'Monitoring',
          url: '/mi/monitoring-form?',
          menuId: 124,
          claimType: ['CLAIM']
        },
        {
          key: 'RECOMMENDATION',
          title: 'Recommendation',
          url: '/mi/add-form-recommendation?',
          menuId: 125,
          claimType: ['NON_CLAIM', 'CLAIM']
        },
        {
          key: 'EVALUATION',
          title: 'Evaluation',
          url: '/mi/add-form-evaluation?',
          menuId: 126,
          claimType: ['NON_CLAIM', 'CLAIM']
        },
        {
          key: 'ERM',
          title: 'Enterprise Risk Management',
          url: '/mi/enterprise-risk-management?',
          menuId: 127,
          claimType: ['NON_CLAIM', 'CLAIM']
        },
        {
          key: 'MANAGEMENT_SUMMARY',
          title: 'Management Summary',
          url: '/mi/management-summary?',
          menuId: 128,
          claimType: ['CLAIM']
        }
      ]
    }
  }
}
</script>
