<template>
      <iq-card>
        <template #body>
          <b-row>
            <b-col lg="3" md="6" v-for="(card, index) in cards" :key="index" class="mb-3 mb-lg-0">
              <div
                class="p-3 d-flex align-items-center card-container"
                :style="{
                  borderRadius: '12px',
                  background: card.bg,
                }"
              >
                <img
                  style="width: 84px; height: 100px"
                  class="card-image"
                  :src="require(`@/src/assets/images/icon/${card.img}`)"
                  alt=""
                />
                <div>
                  <h6 style="color: #fff000"
                    ><strong>{{ card.title }}</strong></h6
                  >
                  <h5 class="text-white"
                    ><strong>{{ card.value }}</strong></h5
                  >
                  <p class="mb-0 text-xs text-white"
                    >{{ index !== 0 ? 'Percentage :':'' }}<strong>{{ card.percent }}</strong></p
                  >
                </div>
              </div>
            </b-col>
          </b-row>
        </template>
      </iq-card>
</template>

<script>
export default {
  data() {
    return {
      cards: [
        {
          title: 'Vessel Docking',
          value: '17/25  Vessels',
          percent: '(Tug & Barge)',
          bg: '#FF0000',
          img: 'ship-3d.png',
        },
        {
          title: 'Budget Docking',
          value: 'Rp. 87.597.234.800,-',
          percent: '63%',
          bg: '#3A5DE7',
          img: 'budget-docking.svg',
        },
        {
          title: 'Budget Expenses',
          value: 'Rp. 20.772.164.180,-',
          percent: '23.71%',
          bg: '#F7941D',
          img: 'budget-expanse.svg',
        },
        {
          title: 'Budget Remaining',
          value: 'Rp. 66.825.070.620,-',
          percent: '76.29%',
          bg: '#27BB2D',
          img: 'budget-remaining-saving.svg',
        },
      ],
    }
  },
}
</script>

<style scoped>
.card-container {
  background: white;
  border: 0;
  border-radius: 10px;
  padding: 12px;
  position: relative;
  /* border: 1px solid; */
  transition: transform 0.25s ease;
}
.card-container .card-image {
  position: absolute;
  right: 24px;
  top: 0;
  transition: all 0.3s ease;
}

.card-container:hover {
  transform: scale(1.05);
}
.card-container:hover .card-image {
  transform: scale(1.25);
  top: -12px;
}
</style>
