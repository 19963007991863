<template>
  <b-container fluid>
    <StepWizard current-step="EVALUATION" />
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:body>
            <form @submit.prevent="onSave()">
              <b-row v-if="incident">
                <b-col cols="6">
                  <img :src="image" alt="logo" class="img-fluid w-20">
                </b-col>
                <b-col cols="6" align-self="center">
                  <!--                <h4 class="mb-0 float-right text-primary"><b>{{ title }}</b></h4>-->
                </b-col>
                <b-col cols="12">
                  <hr />
                </b-col>
                <b-col cols="12" md="6" class="mb-3">
                  <h5 class="card-title text-success"><b>INFORMATION OF ACCIDENT/INCIDENT</b></h5>
                  <b-form-group class="row" label-cols-sm="3" label="Company *" label-for="company">
                    <b-form-input type="text" :value="incident.company.company" readonly />
                  </b-form-group>
                  <b-form-group class="row" label-cols-sm="3" label="Fleet Name *" label-for="fleets">
                    <b-form-input type="text" :value="incident.vehicle.name" readonly />
                  </b-form-group>
                  <b-form-group class="row" label-cols-sm="3" label="Date of Loss (DOL) *" label-for="dol">
                    <b-form-input :value="incident.date_of_loss" type="text" readonly />
                  </b-form-group>
                  <b-form-group class="row" label-cols-sm="3" label="Place of Loss (POL) *" label-for="pol">
                    <b-form-input :value="incident.place_of_loss" type="text" readonly />
                  </b-form-group>
                  <b-form-group class="row" label-cols-sm="3" label="Type of Coverage *" label-for="coverage">
                    <b-form-input type="text" :value="incident.type_coverage.name" readonly />
                  </b-form-group>
                  <b-form-group class="row" label-cols-sm="3" label="Type of Loss *" label-for="loss">
                    <b-form-input type="text" :value="incident.type_loss.name" readonly />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" class="mb-3">
                  <h5 class="card-title text-success"><b>CLAIMANT</b></h5>
                  <b-form-group class="row" label-cols-sm="3" label="Company *" label-for="company">
                    <b-form-input type="text" :value="incident.company.company" readonly />
                  </b-form-group>
                  <b-form-group class="row" label-cols-sm="3" label="Name *" label-for="name">
                    <b-form-input type="text" :value="incident.name" readonly></b-form-input>
                  </b-form-group>
                  <b-form-group class="row" label-cols-sm="3" label="Position *" label-for="position">
                    <b-form-input type="text" :value="incident.position" readonly></b-form-input>
                  </b-form-group>
                  <b-form-group class="row" label-cols-sm="3" label="Department of FLeet *" label-for="departments">
                    <b-form-input type="text" :value="incident.department ? incident.department.name : '-'" readonly />
                  </b-form-group>
                  <b-form-group class="row" label-cols-sm="3" label="Cause of Loss" label-for="cause_of_loss">
                    <b-form-input type="text" :value="incident.cause_of_loss ? incident.cause_of_loss.name : '-'" readonly />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <hr />
                </b-col>
                <b-col cols="12">
                  <h3>Recommendation</h3>
                  <div class="table-responsive">
                    <table class="table mb-0 table-borderless table-box-shadow">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>Permasalahan</th>
                          <th>Vessel Case</th>
                          <th>Root Cause</th>
                          <th>Rekomendasi</th>
                          <th>Cause of Loss</th>
                          <th>Status</th>
                          <th>Closed Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(recommendation, idx) in recommendations" :key="recommendation.id">
                          <td>{{ idx + 1 }}. </td>
                          <td>{{ recommendation.problem }}</td>
                          <td>{{ recommendation.vessel_case }}</td>
                          <td>{{ recommendation.root_cause }}</td>
                          <td>{{ recommendation.recommendation }}</td>
                          <td>{{ recommendation.cause_of_loss_id }}</td>
                          <td>{{ recommendation.status }}</td>
                          <td>{{ recommendation.closed_at }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
                <b-col cols="12" class="mt-4">
                  <h3>Notif Mail to Users</h3>
                  <b-form-group class="mt-2">
                    <v-multiselect   v-model="notifMailUser" placeholder="Notif user" label="name"
                      track-by="id" :options="optionsUser" :multiple="true">
                      <span slot="noResult">Data not found.</span>
                    </v-multiselect>
                  </b-form-group>
                </b-col>
                <b-col cols="12" class="mt-4">
                  <h3>Input Evaluation</h3>
                  <div class="table-responsive">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>No.</th>
                          <th>Tahapan Kegiatan</th>
                          <th>Sasaran Tahapan <br />(Milestone)</th>
                          <th>Uraian Tugas</th>
                          <th>Indikator Keberhasilan</th>
                          <th>Metode Evaluasi</th>
                          <th>Frekuensi</th>
                          <th>Pelaksana</th>
                          <th>Kebutuhan Tindak lanjut</th>
                          <th>#</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(form, idx) in forms" :key="idx">
                          <td>{{ idx + 1 }}</td>
                          <td>
                            <b-form-textarea   v-model="form.activity_stage" id="tahapan-kegiatan" rows="3">
                            </b-form-textarea>
                          </td>
                          <td>
                            <b-form-textarea   v-model="form.milestone" id="sasaran-tahapan" rows="3"></b-form-textarea>
                          </td>
                          <td>
                            <b-form-textarea   v-model="form.job_description" id="uraian-tugas" rows="3">
                            </b-form-textarea>
                          </td>
                          <td>
                            <b-form-textarea   v-model="form.success_indicator" id="indikator-keberhasilan" rows="3">
                            </b-form-textarea>
                          </td>
                          <td>
                            <b-form-textarea   v-model="form.evaluation_result" id="hasil-evaluasi" rows="3">
                            </b-form-textarea>
                          </td>
                          <td>
                            <b-form-textarea   v-model="form.frequency" id="frekuensi" rows="3"></b-form-textarea>
                          </td>
                          <td>
                            <b-form-textarea   v-model="form.executor" id="pelaksana" rows="3"></b-form-textarea>
                          </td>
                          <td>
                            <template v-for="(item, index) in typeFormInsurance">
                              <b-form-radio  v-model="form.follow_up_needs" class="custom-radio-color-checked" inline
                                :color="item.color" :name="`follow_up_needs-${idx + 1}`" :key="index"
                                :value="item.value" :id="`follow_up_needs-${item.value}-${idx + 1}`"><strong>
                                  <h5>{{ item.label }}</h5>
                                </strong></b-form-radio>
                            </template>
                          </td>
                          <td>
                            <b-button   @click="deleteForm(idx)" variant="danger" size="sm"><i class="fa fa-trash"></i>
                            </b-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <b-button   @click="addForms" variant="warning" block class="mt-2 mb-3"><i class="fa fa-plus"></i> Add
                      More Data</b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" class="text-right mt-4">
                  <b-button   type="submit" :disabled="saving" variant="primary">Submit</b-button>
                  <b-button  @click="$router.go(-1)" :disabled="saving" variant="none" class="iq-bg-danger ml-3">Cancel
                  </b-button>
                </b-col>
              </b-row>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { miActions, crewingActions } from '@src/Utils/helper'
import StepNavigation from '@src/views/MI/component/StepNavigation'
import StepWizard from '@src/views/MI/component/StepWizard'

export default {
  name: 'AddFormEvaluation',
  components: { StepNavigation, StepWizard },
  async mounted () {
    xray.index()
    if (!this.$route.query.formId) {
      this.$router.push({
        name: 'mi.listInitial'
      })
    }
    await this.fetchDataUserExpert()
    await this.getIncidents()
    await this.getRecommendationList()
    await this.fetchList()
  },
  data () {
    return {
      permission_user:JSON.parse(localStorage.getItem('profile')).role.roles,
      image: require('../../assets/images/logo.png'),
      saving: false,
      incident: null,
      vehicleList: [],
      departmentList: [],
      recommendations: [],

      optionsUser: [],
      notifMailUser: [],

      forms: [
        {
          id: null,
          activity_stage: '',
          milestone: '',
          job_description: '',
          success_indicator: '',
          evaluation_result: '',
          frequency: '',
          executor: '',
          follow_up_needs: 'yes'
        }
      ],
      typeFormInsurance: [
        {
          label: 'Yes',
          color: 'success',
          value: 'yes'
        },
        {
          label: 'No',
          color: 'danger',
          value: 'no'
        }
      ]
    }
  },
  computed: {
    userPermission(){
      if(['SUPER ADMIN', 'MARINE INSURANCE', 'MARINE INSURANCE ADMIN', 'MARINE INSURANCE MANAGER'].includes(this.permission_user)){
        return true
      } else{
        return false
      } 
    }
  },
  methods: {
    ...miActions,
    ...crewingActions,
    async fetchDataUserExpert() {
      let params = {
        page: 1,
        active: true,
        perPage: 10000,
      }
      const { status, data } = await this.fetchUserExpert(params)

      if (status) {
        if (data && data.length > 0) {
          this.optionsUser = data.map(val => {
            return {
              ...val,
              name: val.userDetail.name,
            }
          })
        }
      }
    },
    async getIncidents () {
      const { data, status } = await this.getMi({
        id: this.$route.query.formId,
        with: 'type_loss,vehicle,type_coverage,company,department,cause_of_loss',
        pagination: 0
      })
      if (status === 'success') {
        const incident = data[0]
        this.incident = incident
      } else {
        this.$router.push({
          name: 'mi.listInitial'
        })
      }
    },
    async getRecommendationList () {
      const params = {
        form_id: this.$route.query.formId,
        pagination: 0
      }
      const res = await this.getRecommendation(params)

      if (res.status === 'success' && res.data) {
        const { data } = await this.getMasterCauseOfLoss()
        if (res.data.data) {
          this.recommendations = res.data.data.map(val => ({
            ...val,
            cause_of_loss_id: data.find(d => d.id === val.cause_of_loss_id).name
          }))
        }
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    async fetchList () {
      const params = {
        form_id: this.$route.query.formId
      }
      const res = await this.getEvaluation(params)

      if (res.status === 'success' && res.data) {
        if (res.data.length) {
          this.forms = res.data
        }
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    deleteForm (index) {
      this.forms.splice(index, 1)
    },
    async onSave () {
      const payload = {
        form_id: this.$route.query.formId,
        data: this.forms
      }

      this.saving = true

      const { status, data } = await this.saveEvaluation(payload)

      if (status !== 'success') {
        this.$swal(
          `Oops!`,
          'Semua Field Wajib Diisi',
          'error'
        )
        this.saving = false
        return
      }

      let emailPayload = {
        user_id:[]
      }

      if(this.notifMailUser.length > 0){
        this.notifMailUser.map(obj => {
          emailPayload.user_id.push(obj.id)
        })
  
        await this.sendEmailRecommendation({data:emailPayload, id: data[0].form_id})
      }


      this.$swal(
        `Tersimpan`,
        'Data Tersimpan',
        'success'
      )

      this.saving = false
    },
    addForms () {
      this.forms.push({
        id: null,
        activity_stage: '',
        milestone: '',
        job_description: '',
        success_indicator: '',
        evaluation_result: '',
        frequency: '',
        executor: '',
        follow_up_needs: 'yes'
      })
    }
  }
}
</script>
