<template>
  <b-row>
    <b-col cols="12" class="mt-4">
      <iq-card>
        <template #body>
          <b-row>
            <b-col md="4" v-for="(card, index) in cards" :key="index">
              <div
                class="p-3 d-flex align-items-center card-container"
                :style="{ borderRadius: '12px', background: card.bg }"
              >
                <div
                  class="bg-white d-flex justify-content-center align-items-center mr-2"
                  style="width: 78px; height: 78px; border-radius: 8px"
                >
                  <img :src="require(`@/src/assets/images/icon/${card.img}`)" alt="" />
                </div>
                <div>
                  <h6 style="color: #fff000"
                    ><strong>{{ card.title }}</strong></h6
                  >
                  <h5 class="text-white"
                    ><strong>{{ card.value }}</strong></h5
                  >
                  <p class="mb-0 text-xs text-white"
                    >Percentage : <strong>{{ card.percent }}</strong></p
                  >
                </div>
              </div>
            </b-col>
          </b-row>
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  data() {
    return {
      cards: [
        {
          title: 'Total Budget',
          value: 'Rp. 87.597.234.800,-',
          percent: '100%',
          bg: '#3A5DE7',
          img: 'total-budget.svg',
        },
        {
          title: 'Total Expenses',
          value: 'Rp. 20.772.164.180,-',
          percent: '63%',
          bg: '#FFA500',
          img: 'total-expanse.svg',
        },
        {
          title: 'Budget Remaining',
          value: 'Rp. 66.825.070.620,-',
          percent: '37%',
          bg: '#0188AF',
          img: 'budget-remaining.svg',
        },
      ],
    }
  },
}
</script>

<style scoped></style>
