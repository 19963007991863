<template>
  <b-row>
    <b-col md="12">
      <div class="d-flex align-items-center justify-content-end w-100">
        <b-button
            variant="danger"
            type="button"
            class="mr-2"
            @click="closeSlide"
          >
          <div>
            <i class="fa fa-ban"></i>
            <span>Cancel</span>
          </div>
          </b-button>
      </div>
      <hr>
    </b-col>
    
    <b-col md="12">
      <fieldset class="form-group border p-3">
        <legend class="w-auto px-2 text-primary">Detail Stock Adjustment</legend>
        <b-row>
          <b-col md="12">
            <div class="d-flex overflow-auto">
              <table class="table table-striped">
                <thead class="bg-primary">
                  <tr>
                    <th class="text-truncate" style="width:220px">PRODUCT CODE</th>
                    <th class="text-truncate">PRODUCT</th>
                    <th class="text-truncate text-center">AVAILABLE STOCK</th>
                    <th class="text-truncate text-center">QTY</th>
                    <th class="text-truncate">TYPE</th>
                    <th class="text-truncate">REMARKS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(state, index) in detailData?.details">
                    <td style="width:220px">{{state?.items?.code}}</td>
                    <td>{{state?.items?.name}}</td>
                    <td class="text-center">{{state?.stock_available}}</td>
                    <td class="text-center">{{state?.qty ?? 0}}</td>
                    <td class="text-truncate">{{state?.type === 'stock_in' ? 'Stock In' : 'Stock Out' }}</td>
                    <td>{{state?.remarks ?? '-'}}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th colspan='3' class="text-center">Total Qty</th>
                    <th class="text-center">{{detailData?.total_qty ?? 0}}</th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </b-col>
        </b-row>
      </fieldset>
    </b-col>
  </b-row>
</template>

<script>
import { busdevActions } from '@src/Utils/helper'
import _ from 'lodash'

export default {
name: 'DetailStockAdjustment',
props:{
  detailData:{
    type:Object,
    default: () => {}
  },
  closeSlide:{
    type:Function,
    default: () => {}
  },
},
data(){
  return {
  }
},
components:{
},
async mounted () {},

methods: {
  ...busdevActions,
  formatCurrency(value) {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
    }).format(value)
  },
}
}
</script>
