<template>
  <b-modal :id="`modal---${id}`" v-model="model" size="xl" title="Set Docking Schedule" header-bg-variant="light" header-text-variant="dark"
           hide-footer
           footer-bg-variant="light" footer-text-variant="dark">
    <b-form @submit.prevent="submit()">
      <b-row>
        <b-col md="6">
          <b-form-group label="Fleet*" label-for="fleet">
            <v-select
              v-model="form.vehicleId"
              label="text"
              :options="fleets"
              :reduce="(fleet) => fleet.value"
              name="vehicleId"
            ></v-select>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Last Docking*" label-for="last_docking">
            <b-form-input type="date" id="end" required v-model="form.lastDocking" name="lastDocking"></b-form-input>
            <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Fleet*" label-for="fleet">
            <b-form-select plain size="lg" required v-model="typeSchedule" name="typeSchedule">
                <template v-slot:first>
                <b-form-select-option value="intermerdiate">INTERMEMDIATE SURVEY</b-form-select-option>
                <b-form-select-option value="special_value">SPECIAL SURVEY</b-form-select-option>
                </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Interval*" label-for="interval">
            <div class="input-group">
              <b-form-input type="number" required v-model="form.interval" name="interval"></b-form-input>
              <div class="input-group-prepend">
                <span class="input-group-text">Month</span>
              </div>
            </div>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Start Schedule*" label-for="start_schedule">
            <div class="input-group">
              <b-form-input type="number" v-model="form.startSchedule" name="startSchedule"></b-form-input>
              <div class="input-group-prepend">
                <span class="input-group-text">Month</span>
              </div>
            </div>
          </b-form-group>
        </b-col>
        
        <b-col md="6" v-if="typeSchedule == 'intermerdiate'">
          <b-form-group label="End Schedule" label-for="end_schedule">
            <div class="input-group">
              <b-form-input type="number" v-model="form.endSchedule" name="endSchedule"></b-form-input>
              <div class="input-group-prepend">
                <span class="input-group-text">Month</span>
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" class="text-right">
          <b-button variant="none" :disabled="form.saving" class="iq-bg-danger text-right" @click="cancel()">Cancel</b-button>
          <b-button type="submit" :disabled="form.saving" variant="primary" class="ml-2 text-right">Save </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import { dockingActions, fleetsActions } from '@src/Utils/helper'
import moment from 'moment'

export default {
  name: 'ModalDockingSetSchedule',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: 'set-schedule-docking'
    },
    typeSchedule: {
      type: String,
      default:''
    },
    fetchUnscheduledDocking: {
      type: Function,
      required: true,
    },
  },
  data () {
    return {
      fleetParams: {
        page: 1,
        showAll: true,
        search: ''
      },
      fleets: [],
      form: {
        saving: false,
        vehicleId: null,
        optionFleet:'special_value',
        lastDocking: null,
        interval: null,
        startSchedule: null,
        endSchedule: null
      }
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  mounted () {
  },
  watch: {
    'typeSchedule': {
      handler(n, o) {
        this.form.optionFleet = this.typeSchedule
      },
      deep: true,
    },
    model () {
      this.resetForm()
      if (this.model === true) {
        this.fetchFleets()
      }
    }
  },
  methods: {
    ...fleetsActions,
    ...dockingActions,
    resetForm () {
      this.form = {
        saving: false,
        vehicleId: null,
        optionFleet:'special_value',
        lastDocking: null,
        interval: null,
        startSchedule: null,
        endSchedule: null
      }
    },
    cancel () {
      this.resetForm()
      this.$emit('cancel')
    },
    async submit () {
      this.form.saving = true
      // console.log(this.form.optionFleet)

      if(this.typeSchedule == 'intermerdiate'){
        let payload = {
          ...this.form,
          lastDocking: moment(this.form.lastDocking).format('DD-MM-YYYY')
        }
        delete payload.optionFleet

        const { status, data } = await this.createDockingScheduleIntermediate({data:payload})

        if (status === 422) {
          this.$swal(
            `Oops!`,
            'Semua Field Wajib Diisi',
            'error'
          )
          this.form.saving = false
          return
        }

        if ([500, 404, 403, 400].includes(status)) {
          this.$swal(
            `Oops!`,
            data?.message ?? 'Ada Yang Salah',
            'error'
          )
          this.form.saving = false
          return
        }
      } else if (this.typeSchedule == 'special_value'){
        let payload = {
          ...this.form,
          lastDocking: moment(this.form.lastDocking).format('DD-MM-YYYY')
        }
        delete payload.optionFleet
        delete payload.endSchedule

        const { status } = await this.createDockingScheduleSpecialSurvey({data:payload})

        if (status === 422) {
          this.$swal(
            `Oops!`,
            'Semua Field Wajib Diisi',
            'error'
          )
          this.form.saving = false
          return
        }

        if ([500, 404, 403, 400].includes(status)) {
          this.$swal(
            `Oops!`,
            data?.message ?? 'Ada Yang Salah',
            'error'
          )
          this.form.saving = false
          return
        }
      }

      this.$swal(
        `Tersimpan`,
        'Data Tersimpan',
        'success'
      )
      this.fetchUnscheduledDocking()
      this.form.saving = false
      this.$emit('submit')
    },
    async fetchFleets () {
      let params = this.$options.filters.cleanObject(this.fleetParams)
      let res = await this.getFleets(params)

      if (!res.status) {
        this.fleets = []
      } else {
        let tempArr = []
        res.data.map(state => {
          tempArr.push({
            value: state.id,
            text: state.name,
          })
        })
        this.fleets = tempArr
      }
    }
  }
}
</script>

<style scoped>

</style>
