var render = function render(){
  var _vm$detailProjectCont, _vm$detailProjectCont2, _vm$detailProjectCont3, _vm$detailProjectCont5, _vm$detailProjectCont6, _vm$detailProjectCont7, _vm$form$voyage, _vm$form, _vm$detailProjectCont8, _vm$detailProjectCont9, _vm$detailProjectCont10, _vm$optionsCargoType, _vm$optionsCargoType$, _vm$detailProjectCont11, _vm$form$quantity, _vm$detailProjectCont12, _vm$detailProjectCont13, _vm$detailProjectCont14, _vm$detailProjectCont15, _vm$detailProjectCont16, _vm$detailProjectCont17, _vm$detailProjectCont18, _vm$detailProjectCont19, _vm$detailProjectCont20, _vm$detailProjectCont21, _vm$form$freight_rate, _vm$form$freight_rate2, _vm$detailProjectCont22, _vm$detailProjectCont23, _vm$detailProjectCont24, _vm$detailProjectCont25, _vm$detailProjectCont26, _vm$detailProjectCont27, _vm$cost_factor_multi, _vm$detailProjectCont28, _vm$detailProjectCont29, _vm$detailProjectCont30, _vm$detailProjectCont31, _vm$time_voyage_multi, _vm$handleCountDurati, _vm$handleCountConsum, _vm$detailProjectCont32, _vm$detailProjectCont33, _vm$handleCountDurati2, _vm$handleCountDurati3, _vm$handleCountGrandT, _vm$handleCountGrandT2, _vm$detailProjectCont34, _vm$detailProjectCont35, _vm$idle_turn_time, _vm$tc_hire_day, _vm$detailProjectCont36, _vm$detailProjectCont37, _vm$handleCountTotalC, _vm$handleCountTotalC2, _vm$detailProjectCont38, _vm$detailProjectCont39, _vm$detailProjectCont40, _vm$detailProjectCont41, _vm$detailProjectCont42, _vm$detailProjectCont43, _vm$detailProjectCont44, _vm$detailProjectCont45, _vm$detailProjectCont46, _vm$detailProjectCont47, _vm$detailProjectCont48, _vm$detailProjectCont49, _vm$detailProjectCont50, _vm$detailProjectCont51, _vm$detailProjectCont52, _vm$detailProjectCont53, _vm$detailProjectCont54, _vm$detailProjectCont55, _vm$detailProjectCont56, _vm$detailProjectCont57, _vm$detailProjectCont58, _vm$detailProjectCont59;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSave.apply(null, arguments);
      }
    }
  }, [_c('b-row', {
    staticClass: "m-3"
  }, [!_vm.loading && (_vm$detailProjectCont = _vm.detailProjectContract) !== null && _vm$detailProjectCont !== void 0 && _vm$detailProjectCont.project_pfa && !_vm.vehicleTypeNotSettingYet.includes((_vm$detailProjectCont2 = _vm.detailProjectContract) === null || _vm$detailProjectCont2 === void 0 ? void 0 : (_vm$detailProjectCont3 = _vm$detailProjectCont2.fleet_type) === null || _vm$detailProjectCont3 === void 0 ? void 0 : _vm$detailProjectCont3.name) ? _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex w-100 justify-content-end"
  }, [_c('b-dropdown', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.top",
      value: 'Export Data',
      expression: "'Export Data'",
      modifiers: {
        "top": true
      }
    }],
    staticClass: "float-right mr-1",
    attrs: {
      "variant": "link iq-bg-primary",
      "toggle-class": "text-decoration-none",
      "no-caret": "",
      "size": "sm"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('span', {
          staticClass: "dropdown-toggle p-0",
          attrs: {
            "id": "dropdownMenuButton5",
            "data-toggle": "dropdown"
          }
        }, [_c('i', {
          staticClass: "fa fa-save m-0"
        })])];
      },
      proxy: true
    }], null, false, 524960243)
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#",
      "variant": "dark"
    },
    on: {
      "click": function click($event) {
        var _vm$detailProjectCont4;
        return _vm.onExportPFA((_vm$detailProjectCont4 = _vm.detailProjectContract) === null || _vm$detailProjectCont4 === void 0 ? void 0 : _vm$detailProjectCont4.id, 'pdf');
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-file-pdf mr-2"
  }), _vm._v("Export PDF")]), _c('b-dropdown-item', {
    attrs: {
      "href": "#",
      "variant": "dark",
      "disabled": ""
    }
  }, [_c('i', {
    staticClass: "fa fa-file-excel mr-2"
  }), _vm._v("Export Excel")])], 1)], 1)]) : _vm._e(), ['PREVIEW'].includes(_vm.pfaAction) && !((_vm$detailProjectCont5 = _vm.detailProjectContract) !== null && _vm$detailProjectCont5 !== void 0 && _vm$detailProjectCont5.project_pfa) && !_vm.loading ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-alert', {
    staticClass: "text-white bg-warning",
    attrs: {
      "show": true,
      "variant": " "
    }
  }, [_c('div', {
    staticClass: "iq-alert-icon"
  }, [_c('i', {
    staticClass: "ri-alert-line"
  })]), _c('div', {
    staticClass: "iq-alert-text"
  }, [_vm._v("PFA not setup yet.")])])], 1) : [!_vm.loading && !_vm.vehicleTypeNotSettingYet.includes((_vm$detailProjectCont6 = _vm.detailProjectContract) === null || _vm$detailProjectCont6 === void 0 ? void 0 : (_vm$detailProjectCont7 = _vm$detailProjectCont6.fleet_type) === null || _vm$detailProjectCont7 === void 0 ? void 0 : _vm$detailProjectCont7.name) ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 h5 text-primary"
  }, [_vm._v("General Information")]), _c('b-row', {
    staticClass: "pl-3 pr-3"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "row",
    attrs: {
      "label-cols-md": "4",
      "label": "PFA ID",
      "label-for": "pfa_id"
    }
  }, [['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('b-form-input', {
    attrs: {
      "id": "pfa_id",
      "disabled": "",
      "placeholder": "<< CREATE PFA ID >>"
    },
    model: {
      value: _vm.form.pfa_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "pfa_id", $$v);
      },
      expression: "form.pfa_id"
    }
  }) : _c('strong', [_vm._v(_vm._s(_vm.form.pfa_id))])], 1), _c('b-form-group', {
    staticClass: "row",
    attrs: {
      "label-cols-md": "4",
      "label": "Status",
      "label-for": "status"
    }
  }, [['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('b-form-select', {
    attrs: {
      "plain": "",
      "size": "lg",
      "placeholder": "Select Status..."
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": ''
          }
        }, [_vm._v("Select Status...")]), _c('b-form-select-option', {
          attrs: {
            "value": 'active'
          }
        }, [_vm._v("Active")]), _c('b-form-select-option', {
          attrs: {
            "value": 'inactive'
          }
        }, [_vm._v("Inactive")]), _c('b-form-select-option', {
          attrs: {
            "value": 'no-sales'
          }
        }, [_vm._v("No Sales")]), _c('b-form-select-option', {
          attrs: {
            "value": 'no-purchase'
          }
        }, [_vm._v("No Purchase")]), _c('b-form-select-option', {
          attrs: {
            "value": 'no-request'
          }
        }, [_vm._v("No Request")])];
      },
      proxy: true
    }], null, false, 1781842748),
    model: {
      value: _vm.form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "status", $$v);
      },
      expression: "form.status"
    }
  }) : _c('strong', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.form.status))])], 1), _c('b-form-group', {
    staticClass: "row",
    attrs: {
      "label-cols-md": "4",
      "label": "Name Pfa",
      "label-for": "descriptionItem"
    }
  }, [['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "Type Text",
      "id": "descriptionItem"
    },
    model: {
      value: _vm.form.pfa_name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "pfa_name", $$v);
      },
      expression: "form.pfa_name"
    }
  }) : _c('strong', [_vm._v(_vm._s(_vm.form.pfa_name))])], 1), _c('b-form-group', {
    staticClass: "row",
    attrs: {
      "label-cols-md": "4",
      "label": "Voyage",
      "label-for": "uom"
    }
  }, [['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('b-button-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.handleCountVoyage(false);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-minus"
  })]), _c('b-button', {
    staticStyle: {
      "width": "80px"
    }
  }, [_vm._v(_vm._s((_vm$form$voyage = (_vm$form = _vm.form) === null || _vm$form === void 0 ? void 0 : _vm$form.voyage) !== null && _vm$form$voyage !== void 0 ? _vm$form$voyage : 1))]), _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.handleCountVoyage(true);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  })])], 1) : _c('strong', [_vm._v(_vm._s(_vm.form.voyage))])], 1), _c('b-form-group', {
    staticClass: "row",
    attrs: {
      "label-cols-md": "4",
      "label": "Port of Loading",
      "label-for": "descriptionItem"
    }
  }, [['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('b-form-input', {
    attrs: {
      "type": "text",
      "id": "descriptionItem"
    },
    model: {
      value: _vm.form.pol,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "pol", $$v);
      },
      expression: "form.pol"
    }
  }) : _c('strong', [_vm._v(_vm._s(_vm.form.pol))])], 1), _c('b-form-group', {
    staticClass: "row",
    attrs: {
      "label-cols-md": "4",
      "label": "Port of Discharge",
      "label-for": "descriptionItem"
    }
  }, [['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('b-form-input', {
    attrs: {
      "type": "text",
      "id": "descriptionItem"
    },
    model: {
      value: _vm.form.pod,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "pod", $$v);
      },
      expression: "form.pod"
    }
  }) : _c('strong', [_vm._v(_vm._s(_vm.form.pod))])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form', [_c('b-form-group', {
    staticClass: "row",
    attrs: {
      "label-cols-md": "4",
      "label": (_vm$detailProjectCont8 = _vm.detailProjectContract) === null || _vm$detailProjectCont8 === void 0 ? void 0 : (_vm$detailProjectCont9 = _vm$detailProjectCont8.fleet_type) === null || _vm$detailProjectCont9 === void 0 ? void 0 : _vm$detailProjectCont9.name,
      "label-for": "Department"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-wrap"
  }, _vm._l((_vm$detailProjectCont10 = _vm.detailProjectContract) === null || _vm$detailProjectCont10 === void 0 ? void 0 : _vm$detailProjectCont10.fleets, function (state, index) {
    return _c('b-badge', {
      key: index,
      staticClass: "border border-primary text-primary text-uppercase mr-1 mb-1",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v(_vm._s(_vm.showVehicleName(state === null || state === void 0 ? void 0 : state.vehicle_id)))]);
  }), 1)]), _c('b-form-group', {
    staticClass: "row",
    attrs: {
      "label-cols-md": "4",
      "label": "Kind of Cargo",
      "label-for": "picName"
    }
  }, [['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('b-form-select', {
    attrs: {
      "plain": "",
      "options": _vm.optionsCargoType
    },
    model: {
      value: _vm.form.cargo_type_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "cargo_type_id", $$v);
      },
      expression: "form.cargo_type_id"
    }
  }, [_c('b-form-select-option', {
    attrs: {
      "value": null
    }
  }, [_vm._v("Select Cargo Type")])], 1) : _c('strong', [_vm._v(_vm._s((_vm$optionsCargoType = _vm.optionsCargoType) === null || _vm$optionsCargoType === void 0 ? void 0 : (_vm$optionsCargoType$ = _vm$optionsCargoType.find(function (val) {
    var _vm$form2;
    return (val === null || val === void 0 ? void 0 : val.value) === ((_vm$form2 = _vm.form) === null || _vm$form2 === void 0 ? void 0 : _vm$form2.cargo_type_id);
  })) === null || _vm$optionsCargoType$ === void 0 ? void 0 : _vm$optionsCargoType$.text))])], 1), _vm.detailProjectContract.currency_rate > 1 ? _c('b-form-group', {
    staticClass: "row",
    attrs: {
      "label-cols-md": "4",
      "label": "Exch. Rate",
      "label-for": "location"
    }
  }, [['ADD', 'EDIT'].includes(_vm.pfaAction) ? [_c('b-form-input', {
    staticClass: "text-right",
    attrs: {
      "type": "number",
      "step": ".0001",
      "id": "qty"
    },
    model: {
      value: _vm.usdRate,
      callback: function callback($$v) {
        _vm.usdRate = $$v;
      },
      expression: "usdRate"
    }
  }), _c('small', [_vm._v(_vm._s(_vm.formatCurrency(_vm.usdRate)))])] : _c('strong', [_vm._v(_vm._s(_vm.formatCurrency(_vm.usdRate)))])], 2) : _vm._e(), _c('b-form-group', {
    staticClass: "row",
    attrs: {
      "label-cols-md": "4",
      "label": "Quantity (".concat((_vm$detailProjectCont11 = _vm.detailProjectContract) === null || _vm$detailProjectCont11 === void 0 ? void 0 : _vm$detailProjectCont11.unit, ")"),
      "label-for": "location"
    }
  }, [['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('b-form-input', {
    staticClass: "text-right",
    attrs: {
      "type": "number",
      "step": ".0001",
      "id": "qty"
    },
    model: {
      value: _vm.form.quantity,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "quantity", $$v);
      },
      expression: "form.quantity"
    }
  }) : _vm._e(), ['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('small', [_vm._v(_vm._s((_vm$form$quantity = _vm.form.quantity) === null || _vm$form$quantity === void 0 ? void 0 : _vm$form$quantity.toLocaleString()))]) : _c('strong', [_vm._v(_vm._s(_vm.form.quantity))])], 1), _vm.detailProjectContract.currency_rate > 1 ? [['TUG BOAT'].includes((_vm$detailProjectCont12 = _vm.detailProjectContract) === null || _vm$detailProjectCont12 === void 0 ? void 0 : (_vm$detailProjectCont13 = _vm$detailProjectCont12.fleet_type) === null || _vm$detailProjectCont13 === void 0 ? void 0 : _vm$detailProjectCont13.name) ? _c('b-form-group', {
    staticClass: "row",
    attrs: {
      "label-cols-md": "4",
      "label": "Freight USD",
      "label-for": "location"
    }
  }, [['ADD', 'EDIT'].includes(_vm.pfaAction) ? [_c('b-form-input', {
    staticClass: "text-right",
    attrs: {
      "type": "number",
      "step": ".0001",
      "id": "qty"
    },
    model: {
      value: _vm.form.freight_rate_usd,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "freight_rate_usd", $$v);
      },
      expression: "form.freight_rate_usd"
    }
  }), _c('small', [_vm._v(_vm._s(_vm.formatCurrency(_vm.form.freight_rate_usd)))])] : _c('strong', [_vm._v(_vm._s(_vm.formatCurrency(_vm.form.freight_rate_usd)))])], 2) : _vm._e(), ['TUG BOAT'].includes((_vm$detailProjectCont14 = _vm.detailProjectContract) === null || _vm$detailProjectCont14 === void 0 ? void 0 : (_vm$detailProjectCont15 = _vm$detailProjectCont14.fleet_type) === null || _vm$detailProjectCont15 === void 0 ? void 0 : _vm$detailProjectCont15.name) ? _c('b-form-group', {
    staticClass: "row",
    attrs: {
      "label-cols-md": "4",
      "label": "Freight IDR",
      "label-for": "location"
    }
  }, [['ADD', 'EDIT'].includes(_vm.pfaAction) ? [_c('b-form-input', {
    staticClass: "text-right",
    attrs: {
      "type": "number",
      "step": ".0001",
      "id": "qty"
    },
    model: {
      value: _vm.form.freight_rate_idr,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "freight_rate_idr", $$v);
      },
      expression: "form.freight_rate_idr"
    }
  }), _c('small', [_vm._v(_vm._s(_vm.formatCurrency(_vm.form.freight_rate_idr)))])] : _c('strong', [_vm._v(_vm._s(_vm.formatCurrency(_vm.form.freight_rate_idr)))])], 2) : _vm._e()] : [['TUG BOAT'].includes((_vm$detailProjectCont16 = _vm.detailProjectContract) === null || _vm$detailProjectCont16 === void 0 ? void 0 : (_vm$detailProjectCont17 = _vm$detailProjectCont16.fleet_type) === null || _vm$detailProjectCont17 === void 0 ? void 0 : _vm$detailProjectCont17.name) ? _c('b-form-group', {
    staticClass: "row",
    attrs: {
      "label-cols-md": "4",
      "label": "Freight",
      "label-for": "location"
    }
  }, [['ADD', 'EDIT'].includes(_vm.pfaAction) ? [_c('b-form-input', {
    staticClass: "text-right",
    attrs: {
      "type": "number",
      "step": ".0001",
      "id": "qty"
    },
    model: {
      value: _vm.form.freight_rate_est,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "freight_rate_est", $$v);
      },
      expression: "form.freight_rate_est"
    }
  }), _c('small', [_vm._v(_vm._s(_vm.formatCurrency(_vm.form.freight_rate_est)))])] : _c('strong', [_vm._v(_vm._s(_vm.formatCurrency(_vm.form.freight_rate_est)))])], 2) : _vm._e()], ['MOTHER VESSEL'].includes((_vm$detailProjectCont18 = _vm.detailProjectContract) === null || _vm$detailProjectCont18 === void 0 ? void 0 : (_vm$detailProjectCont19 = _vm$detailProjectCont18.fleet_type) === null || _vm$detailProjectCont19 === void 0 ? void 0 : _vm$detailProjectCont19.name) ? _c('b-form-group', {
    staticClass: "row",
    attrs: {
      "label-cols-md": "4",
      "label": "Freight USD$",
      "label-for": "location"
    }
  }, [_c('span', [_vm._v("$" + _vm._s(Number(_vm.form.freight_rate_usd).toFixed(2)))])]) : _vm._e(), ['MOTHER VESSEL'].includes((_vm$detailProjectCont20 = _vm.detailProjectContract) === null || _vm$detailProjectCont20 === void 0 ? void 0 : (_vm$detailProjectCont21 = _vm$detailProjectCont20.fleet_type) === null || _vm$detailProjectCont21 === void 0 ? void 0 : _vm$detailProjectCont21.name) ? _c('b-form-group', {
    staticClass: "row",
    attrs: {
      "label-cols-md": "4",
      "label": "Freight IDR",
      "label-for": "location"
    }
  }, [['ADD', 'EDIT'].includes(_vm.pfaAction) ? [_c('b-form-input', {
    staticClass: "text-right",
    attrs: {
      "type": "number",
      "step": ".0001",
      "id": "qty"
    },
    model: {
      value: _vm.form.freight_rate_idr,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "freight_rate_idr", $$v);
      },
      expression: "form.freight_rate_idr"
    }
  }), _c('small', [_vm._v(_vm._s(_vm.formatCurrency((_vm$form$freight_rate = _vm.form.freight_rate_idr) !== null && _vm$form$freight_rate !== void 0 ? _vm$form$freight_rate : 0)))])] : _c('strong', [_vm._v(_vm._s(_vm.formatCurrency((_vm$form$freight_rate2 = _vm.form.freight_rate_idr) !== null && _vm$form$freight_rate2 !== void 0 ? _vm$form$freight_rate2 : 0)))])], 2) : _vm._e(), _c('b-form-group', {
    staticClass: "row",
    attrs: {
      "label-cols-md": "4",
      "label": "Revenue",
      "label-for": "location"
    }
  }, [_c('h3', [_c('b-badge', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.handleCountRevenue)))])], 1)])], 2)], 1)], 1)], 1), _c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 h5 text-primary"
  }, [_vm._v("Charter Company")]), _vm._l(_vm.tableCharterCompany, function (state, index) {
    var _state$value;
    return _c('b-row', {
      key: index
    }, [_c('b-col', {
      attrs: {
        "md": "6"
      }
    }, [_c('strong', [_vm._v(_vm._s(state.name))])]), _c('b-col', {
      attrs: {
        "md": "6"
      }
    }, [['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('b-form-input', {
      attrs: {
        "type": "text",
        "placeholder": "Type text"
      },
      model: {
        value: state.value,
        callback: function callback($$v) {
          _vm.$set(state, "value", $$v);
        },
        expression: "state.value"
      }
    }) : _c('strong', [_vm._v(_vm._s((_state$value = state === null || state === void 0 ? void 0 : state.value) !== null && _state$value !== void 0 ? _state$value : '-'))])], 1)], 1);
  })], 2), ['TUG BOAT'].includes((_vm$detailProjectCont22 = _vm.detailProjectContract) === null || _vm$detailProjectCont22 === void 0 ? void 0 : (_vm$detailProjectCont23 = _vm$detailProjectCont22.fleet_type) === null || _vm$detailProjectCont23 === void 0 ? void 0 : _vm$detailProjectCont23.name) ? _c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 h5 text-primary"
  }, [_vm._v("Distance, Speed, Est Time(Hour)")]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("Laden")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("Ballast")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("Est Dist (NM)")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("Speed")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("Est Time(Hour)")]), ['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('th', {
    staticClass: "text-center"
  }, [_vm._v("#")]) : _vm._e()])]), _c('tbody', [_vm._l(_vm.form.pfa_travel, function (state, index) {
    var _state$laden, _state$ballast, _state$est_dist, _state$speed, _state$est_time$toFix, _state$est_time;
    return _c('tr', {
      key: index
    }, [_c('td', [['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('b-form-input', {
      staticClass: "text-left",
      attrs: {
        "type": "text",
        "placeholder": "Type Text",
        "id": "qty"
      },
      model: {
        value: state.laden,
        callback: function callback($$v) {
          _vm.$set(state, "laden", $$v);
        },
        expression: "state.laden"
      }
    }) : _c('strong', [_vm._v(_vm._s((_state$laden = state === null || state === void 0 ? void 0 : state.laden) !== null && _state$laden !== void 0 ? _state$laden : '-'))])], 1), _c('td', [['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('b-form-input', {
      staticClass: "text-left",
      attrs: {
        "type": "text",
        "placeholder": "Type Text",
        "id": "qty"
      },
      model: {
        value: state.ballast,
        callback: function callback($$v) {
          _vm.$set(state, "ballast", $$v);
        },
        expression: "state.ballast"
      }
    }) : _c('strong', [_vm._v(_vm._s((_state$ballast = state === null || state === void 0 ? void 0 : state.ballast) !== null && _state$ballast !== void 0 ? _state$ballast : '-'))])], 1), _c('td', [['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('b-form-input', {
      staticClass: "text-center",
      attrs: {
        "type": "number",
        "step": ".0001",
        "placeholder": "Type Number",
        "id": "qty"
      },
      on: {
        "input": function input($event) {
          return _vm.handleCountEstTime(state);
        }
      },
      model: {
        value: state.est_dist,
        callback: function callback($$v) {
          _vm.$set(state, "est_dist", $$v);
        },
        expression: "state.est_dist"
      }
    }) : _c('strong', [_vm._v(_vm._s((_state$est_dist = state === null || state === void 0 ? void 0 : state.est_dist) !== null && _state$est_dist !== void 0 ? _state$est_dist : '-'))])], 1), _c('td', [['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('b-form-input', {
      staticClass: "text-center",
      attrs: {
        "type": "number",
        "step": ".0001",
        "placeholder": "Type Number",
        "id": "qty"
      },
      on: {
        "input": function input($event) {
          return _vm.handleCountEstTime(state);
        }
      },
      model: {
        value: state.speed,
        callback: function callback($$v) {
          _vm.$set(state, "speed", $$v);
        },
        expression: "state.speed"
      }
    }) : _c('strong', [_vm._v(_vm._s((_state$speed = state === null || state === void 0 ? void 0 : state.speed) !== null && _state$speed !== void 0 ? _state$speed : '-'))])], 1), _c('td', {
      staticClass: "text-center"
    }, [_c('h5', [_c('b-badge', {
      attrs: {
        "variant": "info"
      }
    }, [_vm._v(_vm._s((_state$est_time$toFix = state === null || state === void 0 ? void 0 : (_state$est_time = state.est_time) === null || _state$est_time === void 0 ? void 0 : _state$est_time.toFixed(2)) !== null && _state$est_time$toFix !== void 0 ? _state$est_time$toFix : 0))])], 1)]), ['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('td', [_c('i', {
      staticClass: "fa fa-trash text-danger",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.form.pfa_travel.splice(index, 1);
        }
      }
    })]) : _vm._e()]);
  }), ['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('tr', [_c('td', {
    attrs: {
      "colspan": "6"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "info",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.handleAddPfaTravel(_vm.form.pfa_travel, 'TUG BOAT');
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add Data")])], 1)]) : _vm._e()], 2)])])])], 1)], 1) : _vm._e(), ['MOTHER VESSEL'].includes((_vm$detailProjectCont24 = _vm.detailProjectContract) === null || _vm$detailProjectCont24 === void 0 ? void 0 : (_vm$detailProjectCont25 = _vm$detailProjectCont24.fleet_type) === null || _vm$detailProjectCont25 === void 0 ? void 0 : _vm$detailProjectCont25.name) ? _c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 h5 text-primary"
  }, [_vm._v("Distance, Speed, Est Time(Hour)")]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("Laden")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("Ballast")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("Est Dist (NM)")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("Speed")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("Est Time(Hour)")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("Est Time(Day)")]), ['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('th', {
    staticClass: "text-center"
  }, [_vm._v("#")]) : _vm._e()])]), _c('tbody', [_vm._l(_vm.form.pfa_travel_mv, function (state, index) {
    var _state$laden2, _state$ballast2, _state$est_dist2, _state$speed2, _state$est_time$toFix2, _state$est_time2, _vm$handleCountEstDay;
    return _c('tr', {
      key: index
    }, [_c('td', [['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('b-form-input', {
      staticClass: "text-left",
      attrs: {
        "type": "text",
        "placeholder": "Type Text",
        "id": "qty"
      },
      model: {
        value: state.laden,
        callback: function callback($$v) {
          _vm.$set(state, "laden", $$v);
        },
        expression: "state.laden"
      }
    }) : _c('strong', [_vm._v(_vm._s((_state$laden2 = state === null || state === void 0 ? void 0 : state.laden) !== null && _state$laden2 !== void 0 ? _state$laden2 : '-'))])], 1), _c('td', [['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('b-form-input', {
      staticClass: "text-left",
      attrs: {
        "type": "text",
        "placeholder": "Type Text",
        "id": "qty"
      },
      model: {
        value: state.ballast,
        callback: function callback($$v) {
          _vm.$set(state, "ballast", $$v);
        },
        expression: "state.ballast"
      }
    }) : _c('strong', [_vm._v(_vm._s((_state$ballast2 = state === null || state === void 0 ? void 0 : state.ballast) !== null && _state$ballast2 !== void 0 ? _state$ballast2 : '-'))])], 1), _c('td', [['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('b-form-input', {
      staticClass: "text-center",
      attrs: {
        "type": "number",
        "step": ".0001",
        "placeholder": "Type Number",
        "id": "qty"
      },
      on: {
        "input": function input($event) {
          return _vm.handleCountEstTime(state);
        }
      },
      model: {
        value: state.est_dist,
        callback: function callback($$v) {
          _vm.$set(state, "est_dist", $$v);
        },
        expression: "state.est_dist"
      }
    }) : _c('strong', [_vm._v(_vm._s((_state$est_dist2 = state === null || state === void 0 ? void 0 : state.est_dist) !== null && _state$est_dist2 !== void 0 ? _state$est_dist2 : '-'))])], 1), _c('td', [['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('b-form-input', {
      staticClass: "text-center",
      attrs: {
        "type": "number",
        "step": ".0001",
        "placeholder": "Type Number",
        "id": "qty"
      },
      on: {
        "input": function input($event) {
          return _vm.handleCountEstTime(state);
        }
      },
      model: {
        value: state.speed,
        callback: function callback($$v) {
          _vm.$set(state, "speed", $$v);
        },
        expression: "state.speed"
      }
    }) : _c('strong', [_vm._v(_vm._s((_state$speed2 = state === null || state === void 0 ? void 0 : state.speed) !== null && _state$speed2 !== void 0 ? _state$speed2 : '-'))])], 1), _c('td', {
      staticClass: "text-center"
    }, [_c('h5', [_c('b-badge', {
      attrs: {
        "variant": "info"
      }
    }, [_vm._v(_vm._s((_state$est_time$toFix2 = state === null || state === void 0 ? void 0 : (_state$est_time2 = state.est_time) === null || _state$est_time2 === void 0 ? void 0 : _state$est_time2.toFixed(2)) !== null && _state$est_time$toFix2 !== void 0 ? _state$est_time$toFix2 : 0))])], 1)]), _c('td', {
      staticClass: "text-center"
    }, [_c('h5', [_c('b-badge', {
      attrs: {
        "variant": "info"
      }
    }, [_vm._v(_vm._s((_vm$handleCountEstDay = _vm.handleCountEstDay(state === null || state === void 0 ? void 0 : state.est_time)) === null || _vm$handleCountEstDay === void 0 ? void 0 : _vm$handleCountEstDay.toFixed(2)))])], 1)]), ['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('td', [_c('i', {
      staticClass: "fa fa-trash text-danger",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.form.pfa_travel_mv.splice(index, 1);
        }
      }
    })]) : _vm._e()]);
  }), ['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('tr', [_c('td', {
    attrs: {
      "colspan": "6"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "info",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.handleAddPfaTravel(_vm.form.pfa_travel_mv, 'MV');
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add Data")])], 1)]) : _vm._e()], 2)])])])], 1)], 1) : _vm._e(), ['TUG BOAT'].includes((_vm$detailProjectCont26 = _vm.detailProjectContract) === null || _vm$detailProjectCont26 === void 0 ? void 0 : (_vm$detailProjectCont27 = _vm$detailProjectCont26.fleet_type) === null || _vm$detailProjectCont27 === void 0 ? void 0 : _vm$detailProjectCont27.name) ? _c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 h5 text-primary"
  }, [_vm._v("Cost Factor")]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex w-100 justify-content-end"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Cost Factor Multiplication"
    }
  }, [['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('b-form-input', {
    attrs: {
      "type": "number",
      "step": ".0001"
    },
    model: {
      value: _vm.cost_factor_multiplication,
      callback: function callback($$v) {
        _vm.cost_factor_multiplication = $$v;
      },
      expression: "cost_factor_multiplication"
    }
  }) : _c('strong', [_vm._v(_vm._s((_vm$cost_factor_multi = _vm.cost_factor_multiplication) !== null && _vm$cost_factor_multi !== void 0 ? _vm$cost_factor_multi : '0'))])], 1)], 1), _c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("Item")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("Price")])])]), _c('tbody', _vm._l(_vm.tableCostFactor, function (state, index) {
    var _vm$formatCurrency;
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.name))]), _c('td', [['ADD', 'EDIT'].includes(_vm.pfaAction) ? [_c('b-form-input', {
      attrs: {
        "type": "number",
        "step": ".0001",
        "placeholder": "0"
      },
      model: {
        value: state.value,
        callback: function callback($$v) {
          _vm.$set(state, "value", $$v);
        },
        expression: "state.value"
      }
    }), state !== null && state !== void 0 && state.is_calculation ? _c('small', {
      staticClass: "text-muted text-truncate"
    }, [_vm._v(" " + _vm._s(_vm.formatCurrency(Number(state.value))) + _vm._s(_vm.operatorSymbol(state.operator)) + _vm._s(_vm.cost_factor_multiplication) + " = " + _vm._s(state.value ? _vm.formatCurrency(_vm.handleCalcute(state.operator, true, state.value, _vm.cost_factor_multiplication)) : '0') + " ")]) : _c('small', [_vm._v(_vm._s(_vm.formatCurrency(Number(state.value))))])] : _vm._e(), ['PREVIEW'].includes(_vm.pfaAction) && state !== null && state !== void 0 && state.is_calculation ? _c('strong', [_vm._v(_vm._s((_vm$formatCurrency = _vm.formatCurrency(_vm.handleCalcute(state.operator, true, state.value, _vm.cost_factor_multiplication))) !== null && _vm$formatCurrency !== void 0 ? _vm$formatCurrency : '-'))]) : _vm._e(), ['PREVIEW'].includes(_vm.pfaAction) && !(state !== null && state !== void 0 && state.is_calculation) ? _c('strong', [_vm._v(_vm._s(_vm.formatCurrency(Number(state.value))))]) : _vm._e()], 2)]);
  }), 0)])])])], 1)], 1) : _vm._e(), ['MOTHER VESSEL'].includes((_vm$detailProjectCont28 = _vm.detailProjectContract) === null || _vm$detailProjectCont28 === void 0 ? void 0 : (_vm$detailProjectCont29 = _vm$detailProjectCont28.fleet_type) === null || _vm$detailProjectCont29 === void 0 ? void 0 : _vm$detailProjectCont29.name) ? _c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 h5 text-primary"
  }, [_vm._v("FUEL PRICE")]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("Item")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("Price")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("Unit")]), ['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('th', {
    staticClass: "text-right"
  }, [_vm._v("#")]) : _vm._e()])]), _c('tbody', [_vm._l(_vm.tableCostFactor, function (state, index) {
    var _state$name, _state$value2, _state$unit;
    return _c('tr', {
      key: index
    }, [_c('td', [['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('b-form-input', {
      attrs: {
        "type": "text",
        "placeholder": "Type Text"
      },
      model: {
        value: state.name,
        callback: function callback($$v) {
          _vm.$set(state, "name", $$v);
        },
        expression: "state.name"
      }
    }) : _c('strong', [_vm._v(_vm._s((_state$name = state === null || state === void 0 ? void 0 : state.name) !== null && _state$name !== void 0 ? _state$name : '-'))])], 1), _c('td', [['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('b-form-input', {
      attrs: {
        "type": "number",
        "step": ".0001",
        "placeholder": "0"
      },
      model: {
        value: state.value,
        callback: function callback($$v) {
          _vm.$set(state, "value", $$v);
        },
        expression: "state.value"
      }
    }) : _vm._e(), ['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('small', [_vm._v(_vm._s(_vm.formatCurrency(Number(state.value))))]) : _c('strong', [_vm._v(_vm._s((_state$value2 = state === null || state === void 0 ? void 0 : state.value) !== null && _state$value2 !== void 0 ? _state$value2 : '-'))])], 1), _c('td', [['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('b-form-input', {
      attrs: {
        "type": "text",
        "placeholder": "Type Text"
      },
      model: {
        value: state.unit,
        callback: function callback($$v) {
          _vm.$set(state, "unit", $$v);
        },
        expression: "state.unit"
      }
    }) : _c('strong', [_vm._v(_vm._s((_state$unit = state === null || state === void 0 ? void 0 : state.unit) !== null && _state$unit !== void 0 ? _state$unit : '-'))])], 1), ['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('td', [_c('i', {
      staticClass: "fa fa-trash text-danger",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.tableCostFactor.splice(index, 1);
        }
      }
    })]) : _vm._e()]);
  }), ['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('tr', [_c('td', {
    attrs: {
      "colspan": "4"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "info",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.tableCostFactor.push({
          key: _vm.tableCostFactor[_vm.tableCostFactor.length - 1].key + 1,
          name: '',
          value: null,
          unit: ''
        });
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add Data")])], 1)]) : _vm._e()], 2)])])])], 1)], 1) : _vm._e(), ['TUG BOAT'].includes((_vm$detailProjectCont30 = _vm.detailProjectContract) === null || _vm$detailProjectCont30 === void 0 ? void 0 : (_vm$detailProjectCont31 = _vm$detailProjectCont30.fleet_type) === null || _vm$detailProjectCont31 === void 0 ? void 0 : _vm$detailProjectCont31.name) ? _c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 h5 text-primary"
  }, [_vm._v("Time Voyage")]), _c('b-row', [_c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex w-100 justify-content-end"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Cost Factor Multiplication"
    }
  }, [['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('b-form-input', {
    attrs: {
      "type": "number",
      "step": ".0001"
    },
    model: {
      value: _vm.time_voyage_multiplication,
      callback: function callback($$v) {
        _vm.time_voyage_multiplication = $$v;
      },
      expression: "time_voyage_multiplication"
    }
  }) : _c('strong', [_vm._v(_vm._s((_vm$time_voyage_multi = _vm.time_voyage_multiplication) !== null && _vm$time_voyage_multi !== void 0 ? _vm$time_voyage_multi : '0'))])], 1)], 1), _c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("Distances steamed (Ballast / Laden)")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("Steaming Time / hours")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("Cons")])])]), _c('tbody', _vm._l(_vm.tableTimeVoyage, function (state, index) {
    var _state$streaming_time, _vm$handleCalcute, _vm$handleCalcute2;
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.name))]), _c('td', [['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('b-form-input', {
      attrs: {
        "type": "number",
        "step": ".0001",
        "placeholder": "0"
      },
      on: {
        "input": function input($event) {
          return _vm.handleCountCons(state, _vm.time_voyage_multiplication);
        }
      },
      model: {
        value: state.streaming_time,
        callback: function callback($$v) {
          _vm.$set(state, "streaming_time", $$v);
        },
        expression: "state.streaming_time"
      }
    }) : _vm._e()], 1), _c('td', [['ADD', 'EDIT'].includes(_vm.pfaAction) ? [_c('b-form-input', {
      attrs: {
        "type": "number",
        "step": ".0001",
        "placeholder": "0"
      },
      model: {
        value: state.cons,
        callback: function callback($$v) {
          _vm.$set(state, "cons", $$v);
        },
        expression: "state.cons"
      }
    }), state !== null && state !== void 0 && state.is_calculation ? _c('small', {
      staticClass: "text-muted text-truncate"
    }, [_vm._v(" " + _vm._s((_state$streaming_time = state === null || state === void 0 ? void 0 : state.streaming_time) !== null && _state$streaming_time !== void 0 ? _state$streaming_time : 0) + _vm._s(_vm.operatorSymbol(state.operator)) + _vm._s(_vm.time_voyage_multiplication) + " = " + _vm._s(state.streaming_time ? (_vm$handleCalcute = _vm.handleCalcute(state.operator, false, state.streaming_time, _vm.time_voyage_multiplication)) === null || _vm$handleCalcute === void 0 ? void 0 : _vm$handleCalcute.toFixed(2) : '0') + " ")]) : _vm._e()] : _c('strong', [_vm._v(_vm._s(state.streaming_time ? (_vm$handleCalcute2 = _vm.handleCalcute(state.operator, false, state.streaming_time, _vm.time_voyage_multiplication)) === null || _vm$handleCalcute2 === void 0 ? void 0 : _vm$handleCalcute2.toFixed(2) : '0'))])], 2)]);
  }), 0), _c('tfoot', [_c('tr', [_c('td', {
    staticClass: "bg-warning"
  }, [_vm._v("Total duration(hour/Day) & Consumption")]), _c('td', {
    staticClass: "bg-info"
  }, [_vm._v(_vm._s((_vm$handleCountDurati = _vm.handleCountDuration) === null || _vm$handleCountDurati === void 0 ? void 0 : _vm$handleCountDurati.toFixed(2)))]), _c('td', {
    staticClass: "bg-info"
  }, [_vm._v(_vm._s((_vm$handleCountConsum = _vm.handleCountConsumption) === null || _vm$handleCountConsum === void 0 ? void 0 : _vm$handleCountConsum.toFixed(2)) + " ")])])])])])])], 1)], 1) : _vm._e(), ['TUG BOAT'].includes((_vm$detailProjectCont32 = _vm.detailProjectContract) === null || _vm$detailProjectCont32 === void 0 ? void 0 : (_vm$detailProjectCont33 = _vm$detailProjectCont32.fleet_type) === null || _vm$detailProjectCont33 === void 0 ? void 0 : _vm$detailProjectCont33.name) ? _c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 h5 text-primary"
  }, [_vm._v("Port Stay")]), _c('b-row', [_c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("Port Stay (load/discharge)")]), _c('th', [_vm._v("Steaming Time / day")]), _c('th', [_vm._v("Idling/waiting")])])]), _c('tbody', _vm._l(_vm.tablePortStay, function (state, index) {
    var _state$streaming_time2, _vm$handleCalcute3;
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.name))]), _c('td', [['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('b-form-input', {
      attrs: {
        "type": "number",
        "step": ".0001",
        "placeholder": "0"
      },
      on: {
        "input": function input($event) {
          return _vm.handleCountIdle(state, 168);
        }
      },
      model: {
        value: state.streaming_time,
        callback: function callback($$v) {
          _vm.$set(state, "streaming_time", $$v);
        },
        expression: "state.streaming_time"
      }
    }) : _vm._e()], 1), _c('td', [['ADD', 'EDIT'].includes(_vm.pfaAction) ? [_c('b-form-input', {
      attrs: {
        "type": "number",
        "step": ".0001",
        "placeholder": "0"
      },
      model: {
        value: state.idle,
        callback: function callback($$v) {
          _vm.$set(state, "idle", $$v);
        },
        expression: "state.idle"
      }
    }), state !== null && state !== void 0 && state.is_calculation ? _c('small', {
      staticClass: "text-muted text-truncate"
    }, [_vm._v(" " + _vm._s((_state$streaming_time2 = state === null || state === void 0 ? void 0 : state.streaming_time) !== null && _state$streaming_time2 !== void 0 ? _state$streaming_time2 : 0) + _vm._s(_vm.operatorSymbol(state.operator)) + "(24 * 7) = " + _vm._s(state.streaming_time ? _vm.handleCalcute(state.operator, false, state.streaming_time, 168) : '0') + " ")]) : _vm._e()] : _vm._e(), ['PREVIEW'].includes(_vm.pfaAction) && state !== null && state !== void 0 && state.is_calculation ? _c('strong', [_vm._v(_vm._s((_vm$handleCalcute3 = _vm.handleCalcute(state.operator, false, state.streaming_time, 168)) !== null && _vm$handleCalcute3 !== void 0 ? _vm$handleCalcute3 : '0'))]) : _vm._e(), ['PREVIEW'].includes(_vm.pfaAction) && !(state !== null && state !== void 0 && state.is_calculation) ? _c('strong', [_vm._v(_vm._s(_vm.formatCurrency(Number(state.idle))))]) : _vm._e()], 2)]);
  }), 0), _c('tfoot', [_c('tr', [_c('td', {
    staticClass: "bg-warning"
  }, [_vm._v("Total duration & Total Bunker Consumption")]), _c('td', {
    staticClass: "bg-info"
  }, [_vm._v(_vm._s((_vm$handleCountDurati2 = _vm.handleCountDurationStreamingTime) === null || _vm$handleCountDurati2 === void 0 ? void 0 : _vm$handleCountDurati2.toFixed(2)))]), _c('td', {
    staticClass: "bg-info"
  }, [_vm._v(_vm._s((_vm$handleCountDurati3 = _vm.handleCountDurationIdle) === null || _vm$handleCountDurati3 === void 0 ? void 0 : _vm$handleCountDurati3.toFixed(2)) + " ")])]), _c('tr', [_c('td', {
    staticClass: "bg-warning"
  }, [_vm._v("Grand total duration (day) & consumption ")]), _c('td', {
    staticClass: "bg-info"
  }, [_vm._v(_vm._s((_vm$handleCountGrandT = _vm.handleCountGrandTotalStreamingTime) === null || _vm$handleCountGrandT === void 0 ? void 0 : _vm$handleCountGrandT.toFixed(2)))]), _c('td', {
    staticClass: "bg-info"
  }, [_vm._v(_vm._s((_vm$handleCountGrandT2 = _vm.handleCountGrandTotalIdle) === null || _vm$handleCountGrandT2 === void 0 ? void 0 : _vm$handleCountGrandT2.toFixed(2)) + " ")])])])])])])], 1)], 1) : _vm._e(), ['MOTHER VESSEL'].includes((_vm$detailProjectCont34 = _vm.detailProjectContract) === null || _vm$detailProjectCont34 === void 0 ? void 0 : (_vm$detailProjectCont35 = _vm$detailProjectCont34.fleet_type) === null || _vm$detailProjectCont35 === void 0 ? void 0 : _vm$detailProjectCont35.name) ? _c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 h5 text-primary"
  }, [_vm._v("CARGO ESTIMATION LOAD & DISCH PORT")]), _c('b-row', [_c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "colSpan": "2"
    }
  }, [_vm._v("loading/disch rate/day (FC)")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("laytime POL/POD")]), _c('th')])]), _c('tbody', [_vm._l(_vm.tablePortStay, function (state, index) {
    var _state$rate, _state$rate2, _state$laytime;
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.name))]), _c('td', [['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('b-form-input', {
      attrs: {
        "type": "number",
        "step": ".0001",
        "placeholder": "0"
      },
      on: {
        "input": function input($event) {
          return _vm.handleCountLaytime(state);
        }
      },
      model: {
        value: state.rate,
        callback: function callback($$v) {
          _vm.$set(state, "rate", $$v);
        },
        expression: "state.rate"
      }
    }) : _vm._e(), ['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('small', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$rate = state.rate) === null || _state$rate === void 0 ? void 0 : _state$rate.toLocaleString()))]) : _c('strong', [_vm._v(_vm._s((_state$rate2 = state === null || state === void 0 ? void 0 : state.rate) !== null && _state$rate2 !== void 0 ? _state$rate2 : '-'))])], 1), _c('td', [['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('b-form-input', {
      attrs: {
        "type": "number",
        "step": ".0001",
        "placeholder": "0"
      },
      model: {
        value: state.laytime,
        callback: function callback($$v) {
          _vm.$set(state, "laytime", $$v);
        },
        expression: "state.laytime"
      }
    }) : _c('strong', [_vm._v(_vm._s((_state$laytime = state === null || state === void 0 ? void 0 : state.laytime) !== null && _state$laytime !== void 0 ? _state$laytime : '-'))])], 1), _c('td', [_c('span', [_vm._v("Days")])])]);
  }), _c('tr', [_c('td', [_vm._v("Idle (Turn Time)")]), _c('td'), _c('td', [['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('b-form-input', {
    attrs: {
      "type": "number",
      "step": ".0001",
      "placeholder": "0"
    },
    model: {
      value: _vm.idle_turn_time,
      callback: function callback($$v) {
        _vm.idle_turn_time = $$v;
      },
      expression: "idle_turn_time"
    }
  }) : _c('strong', [_vm._v(_vm._s((_vm$idle_turn_time = _vm.idle_turn_time) !== null && _vm$idle_turn_time !== void 0 ? _vm$idle_turn_time : '-'))])], 1), _c('td', [_c('span', [_vm._v("Days")])])]), _c('tr', [_c('td', [_vm._v("TC Hire/Day")]), _c('td'), _c('td', [['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('b-form-input', {
    attrs: {
      "type": "number",
      "step": ".0001",
      "placeholder": "0"
    },
    model: {
      value: _vm.tc_hire_day,
      callback: function callback($$v) {
        _vm.tc_hire_day = $$v;
      },
      expression: "tc_hire_day"
    }
  }) : _c('strong', [_vm._v(_vm._s((_vm$tc_hire_day = _vm.tc_hire_day) !== null && _vm$tc_hire_day !== void 0 ? _vm$tc_hire_day : '-'))])], 1), _c('td', [_c('span', [_vm._v("Per Days")])])])], 2)])])])], 1)], 1) : _vm._e(), ['MOTHER VESSEL'].includes((_vm$detailProjectCont36 = _vm.detailProjectContract) === null || _vm$detailProjectCont36 === void 0 ? void 0 : (_vm$detailProjectCont37 = _vm$detailProjectCont36.fleet_type) === null || _vm$detailProjectCont37 === void 0 ? void 0 : _vm$detailProjectCont37.name) ? _c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 h5 text-primary"
  }, [_vm._v("FUEL CONSUMPTION")]), _c('b-row', [_c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("Distances steamed (Ballast / Laden)")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("CONS/DAY (MT/DAY)")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("Cons MT")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("Cons KL")])])]), _c('tbody', _vm._l(_vm.tableTimeVoyage, function (state, index) {
    var _state$cons_day, _state$cons_mt, _vm$handleCountConsMT, _state$cons_kl, _vm$handleCountConsKL;
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.name))]), _c('td', [['ADD', 'EDIT'].includes(_vm.pfaAction) ? [_c('b-form-input', {
      attrs: {
        "type": "number",
        "step": ".0001",
        "placeholder": "0"
      },
      model: {
        value: state.cons_day,
        callback: function callback($$v) {
          _vm.$set(state, "cons_day", $$v);
        },
        expression: "state.cons_day"
      }
    }), state !== null && state !== void 0 && state.cons_day_calculation ? _c('small', {
      staticClass: "text-muted text-truncate"
    }, [_vm._v(" " + _vm._s((_state$cons_day = state === null || state === void 0 ? void 0 : state.cons_day) !== null && _state$cons_day !== void 0 ? _state$cons_day : 0) + " * " + _vm._s(_vm.handleCountConsDay(state).multiplyBy) + " = " + _vm._s(_vm.handleCountConsDay(state).value) + " ")]) : _vm._e()] : _c('strong', [_vm._v(" " + _vm._s(_vm.handleCountConsDay(state).value) + " ")])], 2), _c('td', [state !== null && state !== void 0 && state.cons_mt_calculation ? _c('span', {
      staticClass: "text-truncate"
    }, [_c('span', {
      staticClass: "text-muted"
    }, [_vm._v(_vm._s((_state$cons_mt = state === null || state === void 0 ? void 0 : state.cons_mt) !== null && _state$cons_mt !== void 0 ? _state$cons_mt : 0) + " * " + _vm._s(_vm.handleCountConsMT(state).multiplyBy))]), _vm._v(" = " + _vm._s((_vm$handleCountConsMT = _vm.handleCountConsMT(state).value) === null || _vm$handleCountConsMT === void 0 ? void 0 : _vm$handleCountConsMT.toFixed(2)) + " ")]) : _vm._e()]), _c('td', [state !== null && state !== void 0 && state.cons_kl_calculation ? _c('span', {
      staticClass: "text-truncate"
    }, [_c('span', {
      staticClass: "text-muted"
    }, [_vm._v(_vm._s((_state$cons_kl = state === null || state === void 0 ? void 0 : state.cons_kl) !== null && _state$cons_kl !== void 0 ? _state$cons_kl : 0) + " / " + _vm._s(_vm.handleCountConsKL(state).multiplyBy))]), _vm._v(" = " + _vm._s((_vm$handleCountConsKL = _vm.handleCountConsKL(state).value) === null || _vm$handleCountConsKL === void 0 ? void 0 : _vm$handleCountConsKL.toFixed(2)) + " ")]) : _vm._e()])]);
  }), 0), _c('tfoot', [_c('tr', [_c('td', {
    staticClass: "bg-warning"
  }, [_vm._v("Total duration(hour/Day) & Consumption")]), _c('td', {
    staticClass: "bg-info"
  }), _c('td', {
    staticClass: "bg-info"
  }, [_vm._v(_vm._s((_vm$handleCountTotalC = _vm.handleCountTotalConsMT) === null || _vm$handleCountTotalC === void 0 ? void 0 : _vm$handleCountTotalC.toFixed(2)))]), _c('td', {
    staticClass: "bg-info"
  }, [_vm._v(_vm._s((_vm$handleCountTotalC2 = _vm.handleCountTotalConsKL) === null || _vm$handleCountTotalC2 === void 0 ? void 0 : _vm$handleCountTotalC2.toFixed(2)))])])])])])])], 1)], 1) : _vm._e(), ['TUG BOAT'].includes((_vm$detailProjectCont38 = _vm.detailProjectContract) === null || _vm$detailProjectCont38 === void 0 ? void 0 : (_vm$detailProjectCont39 = _vm$detailProjectCont38.fleet_type) === null || _vm$detailProjectCont39 === void 0 ? void 0 : _vm$detailProjectCont39.name) ? _c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 h5 text-primary"
  }, [_vm._v("Miscellaneous Charge")]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("Item")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("Cost")])])]), _c('tbody', _vm._l(_vm.tableMiscellaneousCharge, function (state, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.name))]), _c('td', {
      staticClass: "text-center"
    }, [['ADD', 'EDIT'].includes(_vm.pfaAction) ? [_c('b-form-input', {
      staticClass: "text-center",
      attrs: {
        "type": "number",
        "step": ".0001",
        "placeholder": "0"
      },
      model: {
        value: state.value,
        callback: function callback($$v) {
          _vm.$set(state, "value", $$v);
        },
        expression: "state.value"
      }
    }), _c('small', [_vm._v(_vm._s(_vm.formatCurrency(Number(state.value))))])] : _c('strong', [_vm._v(" " + _vm._s(_vm.formatCurrency(Number(state.value))) + " ")])], 2)]);
  }), 0), _c('tfoot', [_c('tr', [_c('td', [_c('strong', [_vm._v("TOTAL")])]), _c('td', {
    staticClass: "text-center bg-success font-weight-bold"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.handleCountTotalMC)))])])])])])])], 1)], 1) : _vm._e(), ['TUG BOAT'].includes((_vm$detailProjectCont40 = _vm.detailProjectContract) === null || _vm$detailProjectCont40 === void 0 ? void 0 : (_vm$detailProjectCont41 = _vm$detailProjectCont40.fleet_type) === null || _vm$detailProjectCont41 === void 0 ? void 0 : _vm$detailProjectCont41.name) ? _c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 h5 text-primary"
  }, [_vm._v("Result Plus - General")]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("Item")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("Cost")])])]), _c('tbody', _vm._l(_vm.tableResultPlus, function (state, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.name))]), _c('td', {
      staticClass: "text-center"
    }, [['ADD', 'EDIT'].includes(_vm.pfaAction) ? [_c('b-form-input', {
      staticClass: "text-center",
      attrs: {
        "type": "number",
        "step": ".0001",
        "placeholder": "0"
      },
      model: {
        value: state.value,
        callback: function callback($$v) {
          _vm.$set(state, "value", $$v);
        },
        expression: "state.value"
      }
    }), _c('small', [_vm._v(_vm._s(_vm.formatCurrency(Number(state.value))))])] : _c('strong', [_vm._v(" " + _vm._s(_vm.formatCurrency(Number(state.value))) + " ")])], 2)]);
  }), 0), _c('tfoot', [_c('tr', [_c('td', [_c('strong', [_vm._v("TOTAL")])]), _c('td', {
    staticClass: "text-center bg-success font-weight-bold"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.handleCountResultPlus)))])])])])])])], 1)], 1) : _vm._e(), ['TUG BOAT'].includes((_vm$detailProjectCont42 = _vm.detailProjectContract) === null || _vm$detailProjectCont42 === void 0 ? void 0 : (_vm$detailProjectCont43 = _vm$detailProjectCont42.fleet_type) === null || _vm$detailProjectCont43 === void 0 ? void 0 : _vm$detailProjectCont43.name) ? _c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 h5 text-primary"
  }, [_vm._v("Operational Expenses")]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("Operation")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("Expense")])])]), _c('tbody', _vm._l(_vm.tableComponentExpense, function (state, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "text-truncate"
    }, [_c('div', {
      staticClass: "d-flex"
    }, [_c('span', {
      staticClass: "mr-2"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.name))])])]), _c('td', [['ADD', 'EDIT'].includes(_vm.pfaAction) ? [!(state !== null && state !== void 0 && state.is_calculation) ? _c('b-form-input', {
      attrs: {
        "type": "number",
        "step": ".0001",
        "placeholder": "0"
      },
      model: {
        value: state.value,
        callback: function callback($$v) {
          _vm.$set(state, "value", $$v);
        },
        expression: "state.value"
      }
    }) : _vm._e(), !(state !== null && state !== void 0 && state.is_calculation) ? _c('small', [_vm._v(_vm._s(_vm.formatCurrency(Number(state.value))))]) : _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('span', {
      staticClass: "mr-2"
    }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.handleCountComponentExpense(state))))])])] : _vm._e(), ['PREVIEW'].includes(_vm.pfaAction) && state !== null && state !== void 0 && state.is_calculation ? _c('strong', [_vm._v(_vm._s(_vm.formatCurrency(_vm.handleCountComponentExpense(state))))]) : _vm._e(), ['PREVIEW'].includes(_vm.pfaAction) && !(state !== null && state !== void 0 && state.is_calculation) ? _c('strong', [_vm._v(_vm._s(_vm.formatCurrency(Number(state.value))))]) : _vm._e()], 2)]);
  }), 0), _c('tfoot', [_c('tr', [_c('td', [_c('strong', [_vm._v("Total Operational Expenses")])]), _c('td', {
    staticClass: "text-center bg-primary font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.handleCountTotalOperationalExpenses)) + " ")])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Revenue ")])]), _c('td', {
    staticClass: "text-center bg-primary font-weight-bold"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.handleCountRevenue)))])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Grand Total Expenses ")])]), _c('td', {
    staticClass: "text-center bg-primary font-weight-bold"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.handleCountGrandTotalExpenses)))])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Profit")])]), _c('td', {
    staticClass: "text-center bg-success font-weight-bold"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.handleCountProfit)))])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Profit %")])]), _c('td', {
    class: "text-center font-weight-bold ".concat(_vm.handleCountProfitPersen.toFixed(2) > 16 ? 'bg-success' : 'bg-warning')
  }, [_vm._v(_vm._s(_vm.handleCountProfitPersen.toFixed(2)) + "% "), _c('small', [_vm._v("from Equity ( Grand Total Expenses)")])])])])])])])], 1)], 1) : _vm._e(), ['MOTHER VESSEL'].includes((_vm$detailProjectCont44 = _vm.detailProjectContract) === null || _vm$detailProjectCont44 === void 0 ? void 0 : (_vm$detailProjectCont45 = _vm$detailProjectCont44.fleet_type) === null || _vm$detailProjectCont45 === void 0 ? void 0 : _vm$detailProjectCont45.name) ? _c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 h5 text-primary"
  }, [_vm._v("Operational Expenses")]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("Operation")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("Expense IDR")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("Expense USD")])])]), _c('tbody', _vm._l(_vm.tableComponentExpense, function (state, index) {
    var _vm$handleCountCompon, _state$more_calculati, _vm$handleCountCompon2;
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.name))]), _c('td', [state !== null && state !== void 0 && state.sum_port_stay ? _c('span', [_vm._v(_vm._s(_vm.formatCurrency((_vm$handleCountCompon = _vm.handleCountComponentExpenseMV(state)) === null || _vm$handleCountCompon === void 0 ? void 0 : _vm$handleCountCompon.value)))]) : _vm._e(), !(state !== null && state !== void 0 && state.sum_port_stay) ? _c('b-form-input', {
      attrs: {
        "type": "number",
        "step": ".0001",
        "placeholder": "0"
      },
      model: {
        value: state.value,
        callback: function callback($$v) {
          _vm.$set(state, "value", $$v);
        },
        expression: "state.value"
      }
    }) : _vm._e(), !(state !== null && state !== void 0 && state.is_calculation) && !(state !== null && state !== void 0 && state.more_calculation) && !state.sum_port_stay ? _c('small', [_vm._v(_vm._s(_vm.formatCurrency(state === null || state === void 0 ? void 0 : state.value)))]) : _vm._e(), (state !== null && state !== void 0 && state.is_calculation || state !== null && state !== void 0 && state.more_calculation) && !state.sum_port_stay ? _c('small', [_vm._v(_vm._s(state !== null && state !== void 0 && state.value ? _vm.formatCurrency(state.value) : 0) + " " + _vm._s(_vm.operatorSymbol(state !== null && state !== void 0 && state.operation ? state === null || state === void 0 ? void 0 : state.operation : (_state$more_calculati = state.more_calculation_operator) !== null && _state$more_calculati !== void 0 ? _state$more_calculati : '')) + " " + _vm._s(state.more_calculation_number.toLocaleString()) + " = " + _vm._s(_vm.formatCurrency(_vm.handleCountComponentExpenseMV(state).value)))]) : _vm._e()], 1), _c('td', [_c('span', [_vm._v(_vm._s(_vm.formatUSD(_vm.convertComponentExpenseToUSD((_vm$handleCountCompon2 = _vm.handleCountComponentExpenseMV(state)) === null || _vm$handleCountCompon2 === void 0 ? void 0 : _vm$handleCountCompon2.value))))])])]);
  }), 0), _c('tfoot', [_c('tr', [_c('td', [_c('strong', [_vm._v("Total Operational Expenses")])]), _c('td', {
    staticClass: "text-center bg-primary font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.handleCountTotalExpensesMVIDR)) + " ")]), _c('td', {
    staticClass: "text-center bg-primary font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.formatUSD(_vm.handleCountTotalExpensesMVUSD)) + " ")])])])])])])], 1)], 1) : _vm._e(), ['MOTHER VESSEL'].includes((_vm$detailProjectCont46 = _vm.detailProjectContract) === null || _vm$detailProjectCont46 === void 0 ? void 0 : (_vm$detailProjectCont47 = _vm$detailProjectCont46.fleet_type) === null || _vm$detailProjectCont47 === void 0 ? void 0 : _vm$detailProjectCont47.name) ? _c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 h5 text-primary"
  }, [_vm._v("Variable Cost")]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("Operation")]), _c('th', [_vm._v("Expense IDR")]), _c('th', [_vm._v("Expense USD")])])]), _c('tbody', [_c('tr', [_c('td', {
    staticClass: "text-truncate"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('span', {
    staticClass: "mr-1"
  }, [_vm._v("MFO Fuel Consumption")])])]), _c('td', [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(_vm.handleCountMFO)))])]), _c('td', [_c('span', [_vm._v(_vm._s(_vm.formatUSD(_vm.handleCountMFO / _vm.usdRate)))])])]), _c('tr', [_c('td', {
    staticClass: "text-truncate"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('span', {
    staticClass: "mr-1"
  }, [_vm._v("MDO Fuel Consumption")])])]), _c('td', [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(_vm.handleCountMDO)))])]), _c('td', [_c('span', [_vm._v(_vm._s(_vm.formatUSD(_vm.handleCountMDO / _vm.usdRate)))])])])]), _c('tfoot', [_c('tr', [_c('td', [_c('strong', [_vm._v("Actual Fuel Consumption")])]), _c('td', {
    staticClass: "text-center bg-primary font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.handleCountActualIDR)) + " ")]), _c('td', {
    staticClass: "text-center bg-primary font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.formatUSD(_vm.handleCountActualUSD)) + " ")])])])])])])], 1)], 1) : _vm._e(), ['MOTHER VESSEL'].includes((_vm$detailProjectCont48 = _vm.detailProjectContract) === null || _vm$detailProjectCont48 === void 0 ? void 0 : (_vm$detailProjectCont49 = _vm$detailProjectCont48.fleet_type) === null || _vm$detailProjectCont49 === void 0 ? void 0 : _vm$detailProjectCont49.name) ? _c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 h5 text-primary"
  }, [_vm._v("Grand Total Expenses")]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('tbody', [_c('tr', [_c('td', {
    staticClass: "bg-warning"
  }, [_c('strong', [_vm._v("TOTAL GRAND EXPENSES")])]), _c('td', {
    staticClass: "text-center bg-primary font-weight-bold"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.handleCountGrandTotalExpenseIDR)))]), _c('td', {
    staticClass: "text-center bg-primary font-weight-bold"
  }, [_vm._v(_vm._s(_vm.formatUSD(_vm.handleCountGrandTotalExpenseUSD)))])])])])])])], 1)], 1) : _vm._e(), ['MOTHER VESSEL'].includes((_vm$detailProjectCont50 = _vm.detailProjectContract) === null || _vm$detailProjectCont50 === void 0 ? void 0 : (_vm$detailProjectCont51 = _vm$detailProjectCont50.fleet_type) === null || _vm$detailProjectCont51 === void 0 ? void 0 : _vm$detailProjectCont51.name) ? _c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 h5 text-primary"
  }, [_vm._v("BREAKEVEN FREIGHT (PFA)")]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('tbody', [_c('tr', [_c('td', {
    staticClass: "bg-warning"
  }, [_c('strong', [_vm._v("Freight")])]), _c('td', {
    staticClass: "text-center bg-primary font-weight-bold"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.handleCountFreightIDR)))]), _c('td', {
    staticClass: "text-center bg-primary font-weight-bold"
  }, [_vm._v(_vm._s(_vm.formatUSD(_vm.handleCountFreightUSD)))])]), _c('tr', [_c('td', {
    staticClass: "bg-warning"
  }, [_c('strong', [_vm._v("Bunker")])]), _c('td', {
    staticClass: "text-center bg-primary font-weight-bold"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.handleCountBunkerIDR)))]), _c('td', {
    staticClass: "text-center bg-primary font-weight-bold"
  }, [_vm._v(_vm._s(_vm.formatUSD(_vm.handleCountBunkerUSD)))])]), _c('tr', [_c('td', {
    staticClass: "bg-warning"
  }, [_c('strong', [_vm._v("Demurage")])]), _c('td', {
    staticClass: "text-center bg-primary font-weight-bold",
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("TC + Bunker Cons. Idle")])])])])])])], 1)], 1) : _vm._e()]) : _vm._e(), !_vm.loading && !_vm.vehicleTypeNotSettingYet.includes((_vm$detailProjectCont52 = _vm.detailProjectContract) === null || _vm$detailProjectCont52 === void 0 ? void 0 : (_vm$detailProjectCont53 = _vm$detailProjectCont52.fleet_type) === null || _vm$detailProjectCont53 === void 0 ? void 0 : _vm$detailProjectCont53.name) && ['ADD', 'EDIT'].includes(_vm.pfaAction) ? _c('b-col', {
    staticClass: "mt-5 mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit",
      "block": "",
      "disabled": _vm.saving
    }
  }, [_vm.saving ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _c('span', [_vm._v("Submit")])])], 1) : _vm._e(), _vm.loading && !_vm.vehicleTypeNotSettingYet.includes((_vm$detailProjectCont54 = _vm.detailProjectContract) === null || _vm$detailProjectCont54 === void 0 ? void 0 : (_vm$detailProjectCont55 = _vm$detailProjectCont54.fleet_type) === null || _vm$detailProjectCont55 === void 0 ? void 0 : _vm$detailProjectCont55.name) ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "alert alert-info m-4"
  }, [_vm._v(" Loading... ")])]) : _vm._e()], _vm.vehicleTypeNotSettingYet.includes((_vm$detailProjectCont56 = _vm.detailProjectContract) === null || _vm$detailProjectCont56 === void 0 ? void 0 : (_vm$detailProjectCont57 = _vm$detailProjectCont56.fleet_type) === null || _vm$detailProjectCont57 === void 0 ? void 0 : _vm$detailProjectCont57.name) && !_vm.loading ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-alert', {
    staticClass: "text-white bg-warning",
    attrs: {
      "show": true,
      "variant": " "
    }
  }, [_c('div', {
    staticClass: "iq-alert-icon"
  }, [_c('i', {
    staticClass: "ri-alert-line"
  })]), _c('div', {
    staticClass: "iq-alert-text"
  }, [_vm._v("PFA for vehicle Type "), _c('b', [_vm._v(_vm._s((_vm$detailProjectCont58 = _vm.detailProjectContract) === null || _vm$detailProjectCont58 === void 0 ? void 0 : (_vm$detailProjectCont59 = _vm$detailProjectCont58.fleet_type) === null || _vm$detailProjectCont59 === void 0 ? void 0 : _vm$detailProjectCont59.name))]), _vm._v(", doesnt setup yet.")])])], 1) : _vm._e()], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }