var render = function render(){
  var _vm$detailData$code, _vm$detailData, _vm$detailData$name, _vm$detailData2, _vm$detailData$spesif, _vm$detailData3, _vm$detailData3$spesi, _vm$detailData$unit$n, _vm$detailData4, _vm$detailData4$unit, _vm$detailData5, _vm$detailData6, _vm$detailData7, _vm$detailData8, _vm$detailData9, _vm$detailData$safe_s, _vm$detailData10, _vm$detailData$procur, _vm$detailData11, _vm$detailData$minimu, _vm$detailData12, _vm$detailData$brand, _vm$detailData13, _vm$formatCurrency, _vm$detailData14, _vm$detailData15, _vm$formatCurrency2, _vm$detailData16, _vm$detailData17, _vm$formatCurrency3, _vm$detailData18, _vm$detailData19, _vm$formatCurrency4, _vm$detailData20, _vm$detailData21;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end w-100"
  }, [_c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "danger",
      "type": "button"
    },
    on: {
      "click": _vm.closeSlide
    }
  }, [_c('div', [_c('i', {
    staticClass: "fa fa-ban"
  }), _c('span', [_vm._v("Cancel")])])])], 1), _c('hr')]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Item Information")]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Code Item",
      "label-for": "code-item"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$detailData$code = (_vm$detailData = _vm.detailData) === null || _vm$detailData === void 0 ? void 0 : _vm$detailData.code) !== null && _vm$detailData$code !== void 0 ? _vm$detailData$code : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Name Item",
      "label-for": "name"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$detailData$name = (_vm$detailData2 = _vm.detailData) === null || _vm$detailData2 === void 0 ? void 0 : _vm$detailData2.name) !== null && _vm$detailData$name !== void 0 ? _vm$detailData$name : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Spesification",
      "label-for": "to_vehicle_id"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$detailData$spesif = (_vm$detailData3 = _vm.detailData) === null || _vm$detailData3 === void 0 ? void 0 : (_vm$detailData3$spesi = _vm$detailData3.spesification) === null || _vm$detailData3$spesi === void 0 ? void 0 : _vm$detailData3$spesi.name) !== null && _vm$detailData$spesif !== void 0 ? _vm$detailData$spesif : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Unit",
      "label-for": "to_vehicle_id"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$detailData$unit$n = (_vm$detailData4 = _vm.detailData) === null || _vm$detailData4 === void 0 ? void 0 : (_vm$detailData4$unit = _vm$detailData4.unit) === null || _vm$detailData4$unit === void 0 ? void 0 : _vm$detailData4$unit.name) !== null && _vm$detailData$unit$n !== void 0 ? _vm$detailData$unit$n : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Unit Price",
      "label-for": "to_vehicle_id"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.formatCurrency((_vm$detailData5 = _vm.detailData) === null || _vm$detailData5 === void 0 ? void 0 : _vm$detailData5.unit_price)) + " "), _c('small', {
    staticClass: "text-black"
  }, [_vm._v("(" + _vm._s((_vm$detailData6 = _vm.detailData) !== null && _vm$detailData6 !== void 0 && _vm$detailData6.unit_price_tax_inc ? 'Included Tax' : 'Excluded Tax') + ")")])])])], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Photo & File",
      "label-for": "to_vehicle_id"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v("-")])])], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "QC Inspection Method",
      "label-for": "to_vehicle_id"
    }
  }, [_c('strong', {
    staticClass: "text-muted text-capitalize"
  }, [_vm._v(_vm._s((_vm$detailData7 = _vm.detailData) === null || _vm$detailData7 === void 0 ? void 0 : _vm$detailData7.qc_method_check))])])], 1), ((_vm$detailData8 = _vm.detailData) === null || _vm$detailData8 === void 0 ? void 0 : _vm$detailData8.qc_method_check) === 'sampling' ? _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "QC Inspection Method Value",
      "label-for": "to_vehicle_id"
    }
  }, [_c('strong', {
    staticClass: "text-muted text-capitalize"
  }, [_vm._v(_vm._s((_vm$detailData9 = _vm.detailData) === null || _vm$detailData9 === void 0 ? void 0 : _vm$detailData9.qc_method_check_value_sample))])])], 1) : _vm._e()], 1)], 1)]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Quantity")]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Safe Stock Qty",
      "label-for": "code-item"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$detailData$safe_s = (_vm$detailData10 = _vm.detailData) === null || _vm$detailData10 === void 0 ? void 0 : _vm$detailData10.safe_stock_qty) !== null && _vm$detailData$safe_s !== void 0 ? _vm$detailData$safe_s : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Procurement Period",
      "label-for": "name"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$detailData$procur = (_vm$detailData11 = _vm.detailData) === null || _vm$detailData11 === void 0 ? void 0 : _vm$detailData11.procurement_period) !== null && _vm$detailData$procur !== void 0 ? _vm$detailData$procur : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Minimum Buy Qty",
      "label-for": "to_vehicle_id"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$detailData$minimu = (_vm$detailData12 = _vm.detailData) === null || _vm$detailData12 === void 0 ? void 0 : _vm$detailData12.minimum_buy_qty) !== null && _vm$detailData$minimu !== void 0 ? _vm$detailData$minimu : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Brand",
      "label-for": "to_vehicle_id"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$detailData$brand = (_vm$detailData13 = _vm.detailData) === null || _vm$detailData13 === void 0 ? void 0 : _vm$detailData13.brand) !== null && _vm$detailData$brand !== void 0 ? _vm$detailData$brand : '-'))])])], 1)], 1)], 1)]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Price")]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Buy Price",
      "label-for": "code-item"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$formatCurrency = _vm.formatCurrency((_vm$detailData14 = _vm.detailData) === null || _vm$detailData14 === void 0 ? void 0 : _vm$detailData14.purchase_price)) !== null && _vm$formatCurrency !== void 0 ? _vm$formatCurrency : '0') + " "), _c('small', {
    staticClass: "text-black"
  }, [_vm._v("(" + _vm._s((_vm$detailData15 = _vm.detailData) !== null && _vm$detailData15 !== void 0 && _vm$detailData15.purchase_price_tax_inc ? 'Included Tax' : 'Excluded Tax') + ")")])])])], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Sell Price",
      "label-for": "code-item"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$formatCurrency2 = _vm.formatCurrency((_vm$detailData16 = _vm.detailData) === null || _vm$detailData16 === void 0 ? void 0 : _vm$detailData16.sell_price)) !== null && _vm$formatCurrency2 !== void 0 ? _vm$formatCurrency2 : '0') + " "), _c('small', {
    staticClass: "text-black"
  }, [_vm._v("(" + _vm._s((_vm$detailData17 = _vm.detailData) !== null && _vm$detailData17 !== void 0 && _vm$detailData17.sell_price_tax_inc ? 'Included Tax' : 'Excluded Tax') + ")")])])])], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Event Price",
      "label-for": "code-item"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$formatCurrency3 = _vm.formatCurrency((_vm$detailData18 = _vm.detailData) === null || _vm$detailData18 === void 0 ? void 0 : _vm$detailData18.event_price)) !== null && _vm$formatCurrency3 !== void 0 ? _vm$formatCurrency3 : '0') + " "), _c('small', {
    staticClass: "text-black"
  }, [_vm._v("(" + _vm._s((_vm$detailData19 = _vm.detailData) !== null && _vm$detailData19 !== void 0 && _vm$detailData19.unit_price_tax_inc ? 'Included Tax' : 'Excluded Tax') + ")")])])])], 1)], 1)], 1)]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Item")]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Event Price",
      "label-for": "code-item"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$formatCurrency4 = _vm.formatCurrency((_vm$detailData20 = _vm.detailData) === null || _vm$detailData20 === void 0 ? void 0 : _vm$detailData20.o_e_price)) !== null && _vm$formatCurrency4 !== void 0 ? _vm$formatCurrency4 : '0') + " "), _c('small', {
    staticClass: "text-black"
  }, [_vm._v("(" + _vm._s((_vm$detailData21 = _vm.detailData) !== null && _vm$detailData21 !== void 0 && _vm$detailData21.o_e_price_tax_inc ? 'Included Tax' : 'Excluded Tax') + ")")])])])], 1)], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }