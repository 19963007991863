<template>
  <iq-card>
    <template v-slot:body>
      <div class="row">
        <div class="col-md-12">
          <div class="d-flex w-100 align-items-center justify-content-center" v-if="stepLoading">
            <span class="fa fa-spinner text-center text-primary text-lg" ></span>
          </div>
          <div :class="`d-flex w-100  align-items-center gap-2 overflow-auto ${type_form === 'claim' ? 'justify-content-between' : 'justify-content-center' }`" v-else>
            <div v-for="(state, index) in steps" :key="index+'-step'" class="align-items-center justify-content-center mr-1 d-flex" style="cursor: pointer;">
              <div class="align-items-center justify-content-center w-100 d-flex flex-column" @click="routing(state.url, state.menu)" >
                <b-avatar :variant="currentStep === state.key && state.isDataExist ? 'success' : currentStep === state.key && !state.isDataExist ? 'danger' : 'secondary'" size="30" class="d-flex align-items-center justify-content-center rounded-circle">
                  <i class="fa fa-check" v-if="state.isDataExist"></i>
                  <i class="fa fa-ban" v-else></i>
                </b-avatar> 
                <small :class="`text-truncate ${currentStep === state.key && state.isDataExist ? 'text-success' : currentStep === state.key && !state.isDataExist ? 'text-danger' : 'text-secondary'}`">{{ state.title }}</small>
              </div>
              <i :class="`fa fa-arrow-right ${currentStep === state.key && state.isDataExist ? 'text-success' : currentStep === state.key && !state.isDataExist ? 'text-danger' : 'text-secondary'}` " v-if="steps.length - 1 !== index"></i>
            </div>
          </div>
        </div>
      </div>
    </template>
  </iq-card>
</template>
<script>
import { xray } from '../../../config/pluginInit'
import { miActions } from '@src/Utils/helper'

export default {
  name: 'StepWizard',
  async mounted () {
    xray.index()
    await this.conditionalMI()
  },
  props: {
    currentStep: {
      type: String,
      default: 'CLAIM_ANALYSIS'
    },
    claimType: {
      type: String,
      default: 'CLAIM'
    },
  },
  computed: {
    idParam () {
      let ret = ''

      if (this.$route.params.id) {
        ret = `/${this.$route.params.id}`
      } else if (this.$route.query.id) {
        ret = `id=${this.$route.query.id}`
      }

      return ret
    },
    formIdParam () {
      let ret = ''

      ret = `formId=${this.$route.query.formId}`

      return ret
    },
   
  },
  methods: {
    ...miActions,
    handleGetStepIndex () {
      return this.steps.filter(e => e.claimType.includes(this.claimType)).map(e => e.key).indexOf(this.currentStep)
    },
    routing(url, menu){
      let menuAccess = this.lsProfile.menu.find(val => val.menuId === 2)
      let menuChildAccess = menuAccess.child.find(val => val.menuId === 52)

      let isHaveAccess = menuChildAccess.child.find(val => menu === val.menu)
      console.log(menu)
      console.log(isHaveAccess)


      if (isHaveAccess.add || isHaveAccess.update) {
        // return `${nextStep.url}${this.idParam}${this.formIdParam}`
        this.$router.push(`${url}${this.formIdParam}`)
      } else {
        alert('You do not have permission to access that page.')
      }
    },
    async conditionalMI () {
      if (this.$route.query.formId) {
        this.stepLoading = true
        const { data } = await this.fetchDetailMi({
          id: this.$route.query.formId,
          with: this.incidents.detailParams.withDetail
        })

        this.type_form = data.type_form

        this.steps = this.steps.map(val => {
          if(val.key === 'CLAIM_ANALYSIS') val.isDataExist = data.claim_analysis ? true : false
          if(val.key === 'COST_BREAKDOWN') val.isDataExist = data.cost_breakdown ? true : false
          if(val.key === 'LOST_RATIO') val.isDataExist = data.loss_ratio.length > 0 ? true : false
          if(val.key === 'MANAGEMENT_APPROVAL') val.isDataExist = (data.creator_fullname && data.creator_date && data.approver_fullname && data.approver_date) ? true : false
          if(val.key === 'LIST_RCA') val.isDataExist = data.rcas.length > 0 ? true : false
          if(val.key === 'RCA_FINAL') val.isDataExist = data.rca_final ? true : false
          if(val.key === 'MONITORING') val.isDataExist = data.monitorings.length > 0 ? true : false
          if(val.key === 'RECOMMENDATION') val.isDataExist = data.recommendations.length > 0 ? true : false
          if(val.key === 'EVALUATION') val.isDataExist = data.evaluations.length > 0 ? true : false
          if(val.key === 'ERM') val.isDataExist = data.erm ? true : false
          if(val.key === 'MANAGEMENT_SUMMARY') val.isDataExist = data.summary ? true : false

          return val
        })
        

        if(data.type_form !== 'claim'){
          let keyToKeep = ['RECOMMENDATION','EVALUATION','ERM']

          const filteredStep = this.steps.filter(item => keyToKeep.includes(item.key));

          this.steps = filteredStep
        }
        
        this.stepLoading = false
        console.log(this.steps, "CONDITIONAL DATA")
      }
    },
  },
  data () {
    return {
      lsProfile: JSON.parse(localStorage.getItem('profile')), // BASED ON APPROVAL
      stepLoading:false,
      type_form:null,
      steps: [
        {
          key: 'CLAIM_ANALYSIS',
          title: 'Claim Analysis',
          url: '/mi/claim-analysis?',
          menu: "Initial Claim Analysis",
          claimType: ['CLAIM']
        },
        {
          key: 'COST_BREAKDOWN',
          title: 'Cost Breakdown',
          url: '/mi/cost-breakdown?',
          menu: "Cost Breakdown",
          claimType: ['CLAIM']
        },
        {
          key: 'LOST_RATIO',
          title: 'Loss Ratio',
          url: '/mi/loss-ratio-input?',
          menu: "Loss Ratio",
          claimType: ['CLAIM']
        },
        {
          key: 'MANAGEMENT_APPROVAL',
          title: 'Management Approval',
          url: '/mi/management-approval?',
          menu: "Management Approval",
          claimType: ['CLAIM']
        },
        {
          key: 'LIST_RCA',
          title: 'List RCA',
          url: '/mi/root-cause-analysis?',
          menu: "RCA",
          claimType: ['CLAIM']
        },
        {
          key: 'RCA_FINAL',
          title: 'RCA Final',
          url: '/mi/final-report-root-cause-analysis?',
          menu: "Final RCA",
          claimType: ['CLAIM']
        },
        {
          key: 'MONITORING',
          title: 'Monitoring',
          url: '/mi/monitoring-form?',
          menu: "Monitoring",
          claimType: ['CLAIM']
        },
        {
          key: 'RECOMMENDATION',
          title: 'Recommendation',
          url: '/mi/add-form-recommendation?',
          menu: "Recommendation",
          claimType: ['NON_CLAIM', 'CLAIM']
        },
        {
          key: 'EVALUATION',
          title: 'Evaluation',
          url: '/mi/add-form-evaluation?',
          menu: "Evaluation",
          claimType: ['NON_CLAIM', 'CLAIM']
        },
        {
          key: 'ERM',
          title: 'Enterprise Risk Management',
          url: '/mi/enterprise-risk-management?',
          menu: "Enterprise Risk Management",
          claimType: ['NON_CLAIM', 'CLAIM']
        },
        {
          key: 'MANAGEMENT_SUMMARY',
          title: 'Management Summary',
          url: '/mi/management-summary?',
          menu: "Management Summary",
          claimType: ['CLAIM']
        }
      ],
      incidents: {
        isLoading: false,
        data: [],
        meta: {
          from: 0,
          to: 0,
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        },
        params: {
          page: 1,
          from: null,
          to: null,
          company_id: null,
          vehicle_id: null,
          type_form: null,
          status_id: null,
          id_type_coverage: null,
          id_type_loss: null,
          sort: 'date_of_loss:asc',
          with: 'vehicle,type_coverage,type_loss,cause_of_loss,company,documents,company_claimant,status,creator,histories,rcas,claim_analysis,cost_breakdown,loss_ratio,rca_final,department'
        },
        detailParams: {
          withDetail:
            'vehicle,type_coverage,type_loss,cause_of_loss,company,documents,company_claimant,status,creator,histories,rcas,claim_analysis,cost_breakdown,loss_ratio,rca_final,insurers,classification_of_loss,monitorings,recommendations,evaluations,erm,summary,department'
        },
      },
    }
  },
}
</script>