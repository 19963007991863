import axios from './auth'

const FLEET_BASE_URL = `${process.env.VUE_APP_API_OPS_BASE_URL}/vehicles`
const FLEET_TYPE_BASE_URL = `${process.env.VUE_APP_API_OPS_BASE_URL}/vehicle-types`
const FLEET_TYPE_GROUP_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/mi/vehicle-type-group`

const CREWING_BASE_URL = `${process.env.VUE_APP_API_CREWING_BASE_URL}/vehicle-crew`

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async getUnscheduledDockingFleet({ commit }, params = {}) {
    const url = `${FLEET_BASE_URL}/unscheduled-docking`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  }, 
  async getFleets({ commit }, params = {}) {
    const url = FLEET_BASE_URL

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async getFleetActivities({ commit }, params = {}) {
    const url = `${FLEET_BASE_URL}/activities`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        currentPage: response.data.currentPage,
        totalPage: response.data.totalPage,
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async getFleetById({ commit }, id) {
    const url = `${FLEET_BASE_URL}/${id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async getFleetTypes({ commit }, params = {}) {
    const url = FLEET_TYPE_BASE_URL

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async getFleetTypeGroups({ commit }, params = {}) {
    const url = FLEET_TYPE_GROUP_BASE_URL

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
      params,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },

  async getCrewInFleet({ commit }, params = {}) {
    const url = `${CREWING_BASE_URL}/${params.vehicle_id}`

    const method = 'get'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async addFleet({ commit }, payload) {
    const url = FLEET_BASE_URL

    const method = 'post'
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    const config = {
      url,
      method,
      data: payload,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async addFleetType({ commit }, payload = {}) {
    const url = `${FLEET_TYPE_BASE_URL}/v2`
    // let urlencoded = new URLSearchParams()
    // urlencoded.append('name', payload.name)
    // urlencoded.append('icon', payload.icon)
    // urlencoded.append('isCrewing', payload.isCrewing ? true : false )
    // urlencoded.append('crewCapacity', payload.crewCapacity ?? null)
    // urlencoded.append('groupId', payload.groupId)
    // urlencoded.append('seaTrial', payload.seaTrial ? true : false)
    // urlencoded.append('active', payload.active)

    const method = 'post'
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    const config = {
      url,
      method,
      data: payload.data,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async updateFleetType({ commit }, payload = {}) {
    const url = `${FLEET_TYPE_BASE_URL}/v2/${payload.id}`
    // const url = `${FLEET_TYPE_BASE_URL}/${payload.id}`
    // let urlencoded = new URLSearchParams()
    // urlencoded.append('name', payload.data.name)
    // urlencoded.append('icon', payload.data.icon)
    // urlencoded.append('active', payload.data.active)

    const method = 'put'
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    const config = {
      url,
      method,
      data: payload.data,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async updateFleet({ commit }, payload) {
    const url = `${FLEET_BASE_URL}/${payload.id}`

    const method = 'put'
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    const config = {
      url,
      method,
      data: payload.payload,
      headers,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async deleteFleetType({ commit }, payload) {
    const url = `${FLEET_TYPE_BASE_URL}/${payload}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      await axios(config)
      return { status: 'success' }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
  async deleteFleet({ commit }, id) {
    const url = `${FLEET_BASE_URL}/${id}`

    const method = 'delete'
    const headers = {}
    const config = {
      url,
      method,
      headers,
    }

    try {
      await axios(config)
      return { status: 'success' }
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data,
      }
    }
  },
}
