<template>
  <b-container fluid>
    <!-- Top Section !-->
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"><strong>OPERATIONAL</strong></h4>
          </template>
          <template v-slot:body>
            <b-row>
              <b-col cols="8" md="8">
                <h5 class="card-title text-primary mb-4"><strong>INPUT DAILY OPERATIONAL</strong></h5>
                <hr>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="First Fleet *" label-for="first_fleet">
                      <h5><strong>{{ !isEdit ? fleet.name :  fleetEdit.name }}</strong></h5>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-row>
                      <b-col md="12" v-if="showVoyageWidth">
                        <b-form-group label="Voyage with" label-for="voyage_with">
                          <b-form-radio v-if="!['BARGE', 'PUSHER BARGE'].includes(fleet.vehicleType.name)" class="custom-radio-color-checked" inline v-model="formOperationalActivity.voyageActivityType" color="default" name="color" value="UNTOWING" :disabled="loadingCheck || locked">Towing Free</b-form-radio>
                          <b-form-radio class="custom-radio-color-checked" inline v-model="formOperationalActivity.voyageActivityType" color="primary" name="color" value="SINGLE_TOWING" :disabled="loadingCheck || locked">Single Towing</b-form-radio>
                          <b-form-radio class="custom-radio-color-checked" inline v-model="formOperationalActivity.voyageActivityType" color="primary" name="color" value="DOUBLE_TOWING" :disabled="loadingCheck || locked">Double Towing</b-form-radio>
                        </b-form-group>
                      </b-col>
                      <b-col md="12" v-if="formOperationalActivity.voyageActivityType === 'SINGLE_TOWING' || formOperationalActivity.voyageActivityType === 'DOUBLE_TOWING'" >
                        <b-row class="mb-3">
                          <b-col md="7">
                            <label for="second_fleet">Second Fleet</label>
                          </b-col>
                          <b-col md="5" class="text-right">
                            <b-form-checkbox v-model="formOperationalActivity.vehicles[0].breakdown" name="check-button" switch inline :disabled="loadingCheck || locked">
                              Breakdown ?
                            </b-form-checkbox>
                          </b-col>
                          <b-col md="12">
                            <v-select v-model="formOperationalActivity.vehicles[0].vehicleId" label="name" :options="fleetListFiltered" :reduce="(fleetList) => fleetList.id" :disabled="loadingCheck || locked" v-on:input="handleSelectFleet(fleetList, $event, 0)"></v-select>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col md="12" v-if="formOperationalActivity.voyageActivityType === 'DOUBLE_TOWING'" >
                        <b-row class="mb-3">
                          <b-col md="7">
                            <label for="another_fleet">Another Fleet</label>
                          </b-col>
                          <b-col md="5" class="text-right">
                            <b-form-checkbox v-model="formOperationalActivity.vehicles[1].breakdown" name="check-button" switch inline :disabled="loadingCheck || locked">
                              Breakdown ?
                            </b-form-checkbox>
                          </b-col>
                          <b-col md="12">
                            <v-select v-model="formOperationalActivity.vehicles[1].vehicleId" label="name" :options="fleetListFiltered" :reduce="(fleetList) => fleetList.id" :disabled="loadingCheck || locked" v-on:input="handleSelectFleet(fleetList, $event, 1)"></v-select>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col md="12">
                    <hr>
                    <b-row v-if="!formOperationalActivity.newTrip">
                      <b-col md="12">
                        <div class="d-flex flex-column mb-2">
                          <div class="d-flex align-items-start justify-content-between w-100">
                            <label >Unfinished Trip</label>
                            <b-form-group label-for="crossTrip" label="Cross Trip?">
                              <b-form-checkbox
                                v-model="formOperationalActivity.crossTrip"
                                switch
                                :value="true"
                                :unchecked-value="false"
                                :disabled="locked"
                              >
                                Yes / No
                              </b-form-checkbox>
                            </b-form-group>
                          </div>
                          <div class="d-flex overflow-auto border" v-if="!formOperationalActivity.crossTrip">
                            <table class="table table-striped " v-if="unfinishedTrip.length > 0">
                              <thead>
                                <tr>
                                  <th rowspan="2"  class="table-info align-middle">#</th>
                                  <th rowspan="2" class="table-info text-truncate  align-middle">Start Date</th>
                                  <th rowspan="2" class="table-info text-truncate  align-middle">End Date</th>
                                  <th rowspan="2" class="table-info text-truncate  align-middle">Sub Project</th>
                                  <th colspan="12" class="table-primary text-center">Port of Loading</th>
                                  <th colspan="12" class="table-warning text-center">Port of Discharge</th>
                                </tr>
                                <tr>
                                  <th class="table-primary text-truncate">POL</th>
                                  <th class="table-primary text-truncate">Agent POL</th>
                                  <th class="table-primary text-truncate">Draught Survey</th>
                                  <th class="table-primary text-truncate">Arival Time POL</th>
                                  <th class="table-primary text-truncate">Anchor Up</th>
                                  <th class="table-primary text-truncate">In Position / Berthing</th>
                                  <th class="table-primary text-truncate">Commenced</th>
                                  <th class="table-primary text-truncate">Completed</th>
                                  <th class="table-primary text-truncate">Cast Out</th>
                                  <th class="table-primary text-truncate">LHV</th>
                                  <th class="table-primary text-truncate">Document on Board</th>
                                  <th class="table-primary text-truncate">Departure to POD</th>
                                  <th class="table-warning text-truncate">POD</th>
                                  <th class="table-warning text-truncate">Agent POD</th>
                                  <th class="table-warning text-truncate">Draught Survey</th>
                                  <th class="table-warning text-truncate">Arival Time POD</th>
                                  <th class="table-warning text-truncate">Anchor Up</th>
                                  <th class="table-warning text-truncate">In Position / Berthing</th>
                                  <th class="table-warning text-truncate">Commenced</th>
                                  <th class="table-warning text-truncate">Completed</th>
                                  <th class="table-warning text-truncate">Cast Out</th>
                                  <th class="table-warning text-truncate">LHV</th>
                                  <th class="table-warning text-truncate">Document on Board</th>
                                  <th class="table-warning text-truncate">Departure to POL</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(state, index) in unfinishedTrip" :key="index">
                                  <td>
                                    <b-form-checkbox :disabled="locked" v-model="state.checked" @change="onCheckedUnfinishedTrip(index)" />
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.startDate ? moment(state?.startDate, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.endDate ? moment(state?.endDate, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.subProject?.project?.projectName }} - {{ state?.subProject?.name }} 
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.portLoading?.portName ?? '-' }}
                                  </td>
                                  <td class="text-truncate">{{state?.agentPol}}</td>
                                  <td class="text-truncate text-center">{{state?.polDraughtSurvey ?? 0}}</td>
                                  <td class="text-truncate">
                                    {{ state?.polArrivalTime ? moment(state?.polArrivalTime, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.polAnchorUp ? moment(state?.polAnchorUp, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.polBerthing ? moment(state?.polBerthing, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.polCommenced ? moment(state?.polCommenced, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.polCompleted ? moment(state?.polCompleted, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.polCastOut ? moment(state?.polCastOut, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td> 
                                  <td class="text-truncate">
                                    {{ state?.polLhv ? moment(state?.polLhv, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.polDocOnBoard ? moment(state?.polDocOnBoard, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.polDeparture ? moment(state?.polDeparture, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.portDischarge?.portName ?? '-' }}
                                  </td>
                                  <td class="text-truncate">{{state?.agentPod}}</td>
                                  <td class="text-truncate text-center">{{state?.podDraughtSurvey ?? 0}}</td>
                                  <td class="text-truncate">
                                    {{ state?.podArrivalTime ? moment(state?.podArrivalTime, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.podAnchorUp ? moment(state?.podAnchorUp, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.podBerthing ? moment(state?.podBerthing, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.podCommenced ? moment(state?.podCommenced, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.podCompleted ? moment(state?.podCompleted, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.podCastOut ? moment(state?.podCastOut, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td> 
                                  <td class="text-truncate">
                                    {{ state?.podLhv ? moment(state?.podLhv, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.podDocOnBoard ? moment(state?.podDocOnBoard, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.podDeparture ? moment(state?.podDeparture, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <strong class="text-center text-muted" v-else>No Data found.</strong>
                        </div>
                      </b-col>
                      <b-col md="12" v-if="formOperationalActivity.crossTrip">
                        <b-form-group label="Fleet" label-for="fleet">
                          <v-select
                            label="text"
                            :options="optionsFleets"
                            :reduce="
                              (optionsFleets) => optionsFleets.value
                            "
                            placeholder="Select..."
                            v-model="formOperationalActivity.fleetId"
                            @input="onChangeFleet"
                          ></v-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="12" v-if="formOperationalActivity.crossTrip">
                        <div class="d-flex flex-column mb-2">
                          <label>Unfinished Trip</label>
                          <strong class="text-muted" v-if="!formOperationalActivity.fleetId">Select Vehicle.</strong>
                          <div class="d-flex overflow-auto border" v-if="crossTripList.length > 0">
                            <table class="table table-striped ">
                              <thead>
                                <tr>
                                  <th rowspan="2"  class="table-info align-middle">#</th>
                                  <th rowspan="2" class="table-info text-truncate  align-middle">Start Date</th>
                                  <th rowspan="2" class="table-info text-truncate  align-middle">End Date</th>
                                  <th rowspan="2" class="table-info text-truncate  align-middle">Sub Project</th>
                                  <th colspan="12" class="table-primary text-center">Port of Loading</th>
                                  <th colspan="12" class="table-warning text-center">Port of Discharge</th>
                                </tr>
                                <tr>
                                  <th class="table-primary text-truncate">POL</th>
                                  <th class="table-primary text-truncate">Agent POL</th>
                                  <th class="table-primary text-truncate">Draught Survey</th>
                                  <th class="table-primary text-truncate">Arival Time POL</th>
                                  <th class="table-primary text-truncate">Anchor Up</th>
                                  <th class="table-primary text-truncate">In Position / Berthing</th>
                                  <th class="table-primary text-truncate">Commenced</th>
                                  <th class="table-primary text-truncate">Completed</th>
                                  <th class="table-primary text-truncate">Cast Out</th>
                                  <th class="table-primary text-truncate">LHV</th>
                                  <th class="table-primary text-truncate">Document on Board</th>
                                  <th class="table-primary text-truncate">Departure to POD</th>
                                  <th class="table-warning text-truncate">POD</th>
                                  <th class="table-warning text-truncate">Agent POD</th>
                                  <th class="table-warning text-truncate">Draught Survey</th>
                                  <th class="table-warning text-truncate">Arival Time POD</th>
                                  <th class="table-warning text-truncate">Anchor Up</th>
                                  <th class="table-warning text-truncate">In Position / Berthing</th>
                                  <th class="table-warning text-truncate">Commenced</th>
                                  <th class="table-warning text-truncate">Completed</th>
                                  <th class="table-warning text-truncate">Cast Out</th>
                                  <th class="table-warning text-truncate">LHV</th>
                                  <th class="table-warning text-truncate">Document on Board</th>
                                  <th class="table-warning text-truncate">Departure to POL</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(state, index) in crossTripList" :key="index">
                                  <td>
                                    <b-form-checkbox :disabled="locked" v-model="state.checked" @change="onCheckedCrossTrip(index)" />
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.startDate ? moment(state?.startDate, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.endDate ? moment(state?.endDate, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.subProject?.project?.projectName }} - {{ state?.subProject?.name }} 
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.portLoading?.portName ?? '-' }}
                                  </td>
                                  <td class="text-truncate">{{state?.agentPol}}</td>
                                  <td class="text-truncate text-center">{{state?.polDraughtSurvey ?? 0}}</td>
                                  <td class="text-truncate">
                                    {{ state?.polArrivalTime ? moment(state?.polArrivalTime, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.polAnchorUp ? moment(state?.polAnchorUp, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.polBerthing ? moment(state?.polBerthing, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.polCommenced ? moment(state?.polCommenced, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.polCompleted ? moment(state?.polCompleted, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.polCastOut ? moment(state?.polCastOut, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td> 
                                  <td class="text-truncate">
                                    {{ state?.polLhv ? moment(state?.polLhv, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.polDocOnBoard ? moment(state?.polDocOnBoard, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.polDeparture ? moment(state?.polDeparture, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.portDischarge?.portName ?? '-' }}
                                  </td>
                                  <td class="text-truncate">{{state?.agentPod}}</td>
                                  <td class="text-truncate text-center">{{state?.podDraughtSurvey ?? 0}}</td>
                                  <td class="text-truncate">
                                    {{ state?.podArrivalTime ? moment(state?.podArrivalTime, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.podAnchorUp ? moment(state?.podAnchorUp, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.podBerthing ? moment(state?.podBerthing, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.podCommenced ? moment(state?.podCommenced, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.podCompleted ? moment(state?.podCompleted, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.podCastOut ? moment(state?.podCastOut, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td> 
                                  <td class="text-truncate">
                                    {{ state?.podLhv ? moment(state?.podLhv, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.podDocOnBoard ? moment(state?.podDocOnBoard, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                  <td class="text-truncate">
                                    {{ state?.podDeparture ? moment(state?.podDeparture, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <strong class="text-center text-muted" v-if="crossTripList.length <= 0 && formOperationalActivity.fleetId">No Data found.</strong>
                        </div>
                      </b-col>
                    </b-row>
                    <b-form-group label-for="new_trip" label="New Trip">
                      <b-form-checkbox
                        v-model="formOperationalActivity.newTrip"
                        switch
                        :value="true"
                        :unchecked-value="false"
                        :disabled="locked"
                      >
                        Yes / No
                      </b-form-checkbox>
                    </b-form-group>
                    <hr>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Start Date *" label-for="start_date">
                      <date-picker v-model="formOperationalActivity.startDate" :class="{ 'border-danger': $v.formOperationalActivity.startDate.$error }" type="datetime" placeholder="Select date and time" :disabled="loadingCheck || locked"></date-picker>
                      <p><small v-if="$v.formOperationalActivity.startDate.$error  && !$v.formOperationalActivity.startDate.required " class="text-danger m-t-5">Start Date is Required</small></p>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="End Date *" label-for="end_date">
                      <date-picker v-model="formOperationalActivity.endDate" :class="{ 'border-danger': $v.formOperationalActivity.startDate.$error }" type="datetime" placeholder="Select date and time" :disabled="loadingCheck || locked"></date-picker>
                      <p><small v-if="$v.formOperationalActivity.endDate.$error  && !$v.formOperationalActivity.endDate.required " class="text-danger m-t-5">End Date is Required</small></p>
                    </b-form-group>
                  </b-col>
                  <b-col md="12" v-if="!isEdit">
                    <b-form-group label="Process" label-for="process">
                      <b-button block variant="success" v-if="!loadingCheck" class="mt-3" @click.prevent="checkVoyage" :disabled="locked"><i class="fa fa-map-pin"></i>Process</b-button>
                      <b-button block variant="light" v-else class="mt-3" @click.prevent="edit"><i class="fa fa-edit"></i>Edit</b-button>
                      <b-button block variant="danger" v-if="nextStep || locked" class="mt-3" @click.prevent="resetForm" type="button"><i class="fa fa-ban"></i>Reset Form</b-button>
                    </b-form-group>
                  </b-col>
                 
                </b-row>
                <hr>
                <template v-if="nextStep">
                  <b-col md="12">
                    <b-row>
                      <b-col md="6">
                        <fieldset class="form-group border p-3 iq-bg-info">
                          <legend class="w-auto px-2 text-primary h6"><strong>PROJECT RECORD</strong></legend>
                          <b-form>
                            <b-form-group label="Project *" label-for="project">
                              <v-multiselect v-model="formOperationalActivity.subProjectId" :options="projectOptions" group-values="sub" group-label="project" placeholder="Select one" track-by="name" label="name"></v-multiselect>
                            </b-form-group>
                          </b-form>
                        </fieldset>
                      </b-col>
                      <b-col md="6">
                        <fieldset class="form-group border p-3 iq-bg-info">
                          <legend class="w-auto px-2 text-primary h6"><strong>POSITION</strong></legend>
                          <!-- <b-form-row>
                            <b-col>
                              <b-form-group label="Port of Loading" label-for="port_of_loading">
                                <b-form-select plain size="sm">
                                  <b-form-select-option>ASAM ASAM &raquo; LONTAR</b-form-select-option>
                                  <b-form-select-option>TJ. BARA &raquo; ASAM ASAM</b-form-select-option>
                                </b-form-select>
                              </b-form-group>
                            </b-col>
                          </b-form-row> -->
                          <b-form-row>
                            <b-col>
                              <b-form-group label="Voyage For" label-for="voyage_for">
                                <template v-for="(item,index) in voyagefor">
                                  <b-form-radio inline v-model="formOperationalActivity.voyageFor" :name="item.name" :key="index" :value="item.value" :disabled="item.disabled">{{ item.label }}</b-form-radio>
                                </template>
                              </b-form-group>
                            </b-col>
                          </b-form-row>
                          <b-form-row v-if="['others'].includes(formOperationalActivity.voyageFor)">
                            <b-col>
                              <b-form-group label="Voyage For Other" label-for="voyageForOtherValue">
                                <b-form-input class="bg-white" type="text" v-model="formOperationalActivity.voyageForOtherValue"></b-form-input>
                              </b-form-group>
                            </b-col>
                          </b-form-row>
                          <b-form-row>
                            <b-col md="6">
                              <b-form-group label="Origin" label-for="origin">
                                <v-select class="bg-white" v-model="formOperationalActivity.origin" label="text" :options="positionList" :reduce="positionList => positionList.text" placeholder="Select..."></v-select>
                              </b-form-group>
                            </b-col>
                            <b-col v-if="['Others Position'].includes(formOperationalActivity.origin)" md="6">
                              <b-form-group label="Other Origin" label-for="origin">
                                <b-form-input type="text" v-model="formOperationalActivity.originOther"></b-form-input>
                              </b-form-group>
                            </b-col>
                            <b-col md="6">
                              <b-form-group label="Destination" label-for="destination">
                                <v-select class="bg-white" v-model="formOperationalActivity.destination" label="text" :options="positionList" :reduce="positionList => positionList.text" placeholder="Select..."></v-select>
                              </b-form-group>
                            </b-col>
                            <b-col v-if="['Others Position'].includes(formOperationalActivity.destination)" md="6">
                              <b-form-group label="Other Destination" label-for="destination">
                                <b-form-input type="text" v-model="formOperationalActivity.destinationOther"></b-form-input>
                              </b-form-group>
                            </b-col>
                          </b-form-row>
                          <b-form-row>
                            <b-col :class="position === 'Others Position' ? 'col-md-6': ''">
                              <b-form-group label="Current Position *" label-for="current_position">
                                <v-select class="bg-white" v-model="position" label="text" :options="positionList" :reduce="positionList => positionList.text" placeholder="Select Position"></v-select>
                              </b-form-group>
                            </b-col>
                            <b-col v-if="position === 'Others Position'">
                              <b-form-group label="Other Position " label-for="other_position">
                                <b-form-input type="text" v-model="positionOther"></b-form-input>
                              </b-form-group>
                            </b-col>
                          </b-form-row>
                          <b-form-row>
                            <!-- <b-col cols="6" v-if="showDepartureDateTime">
                              <b-form-group label="Departure" label-for="departure">
                                <date-picker v-model="formOperationalActivity.departure" type="datetime" placeholder="Select date and time"></date-picker>
                              </b-form-group>
                            </b-col>
                            <b-col cols="6" v-if="showArrivalDateTime">
                              <b-form-group label="ETA / Arrival" label-for="eta">
                                <date-picker v-model="formOperationalActivity.arrival" type="datetime" placeholder="Select date and time"></date-picker>
                              </b-form-group>
                            </b-col> -->
                          </b-form-row>
                          <!-- <b-form-row v-if="showPortOfLoading || showPortOfDischarge">
                            <b-col md="6" v-if="showPortOfLoading">
                              <b-form-group label="Port of Loading" label-for="firts_fleet">
                                <v-select v-model="formOperationalActivity.portLoading" label="text" :options="positionList" :reduce="positionList => positionList.value" placeholder="Select Port of Loading"></v-select>
                              </b-form-group>
                              <b-form-group v-if="formOperationalActivity.portLoading === 'Others'" label="Other Position ">
                                <b-form-input type="text" v-model="formOperationalActivity.portLoadingOther"></b-form-input>
                              </b-form-group>
                            </b-col>
                            <b-col md="6" v-if="showPortOfDischarge">
                              <b-form-group label="Port of Discharge" label-for="second_fleet">
                                <v-select v-model="formOperationalActivity.portDischarge" label="text" :options="positionList" :reduce="positionList => positionList.value" placeholder="Select Port of Discharge"></v-select>
                              </b-form-group>
                              <b-form-group v-if="formOperationalActivity.portDischarge === 'Others'" label="Other Position ">
                                <b-form-input type="text" v-model="formOperationalActivity.portDischargeOther"></b-form-input>
                              </b-form-group>
                            </b-col>
                          </b-form-row> -->
                          <!-- <b-form-row v-if="showPortAgentPol || showPortAgentPod">
                            <b-col v-if="showPortAgentPol">
                              <b-form-group label="Agent POL" label-for="firts_fleet">
                                <b-form-input type="text" v-model="formOperationalActivity.agentPol"></b-form-input>
                              </b-form-group>
                            </b-col>
                            <b-col v-if="showPortAgentPod">
                              <b-form-group label="Agent POD" label-for="second_fleet">
                                <b-form-input type="text" v-model="formOperationalActivity.agentPod"></b-form-input>
                              </b-form-group>
                            </b-col>
                          </b-form-row> -->
                        </fieldset>
                        <b-card-text style="margin-top:-18px"><small class="text-muted">Sign * : <b-badge variant="warning">Mandatory (Must be Input)</b-badge></small></b-card-text>
                      </b-col>
                      <b-col md="12">
                        <hr>
                      </b-col>
                      <b-col md="6">
                        <fieldset class="form-group border p-3 iq-bg-info">
                          <legend class="w-auto px-2 text-primary h6"><strong>LOADING ACTIVITY RECORD</strong></legend>
                          <b-form-row>
                            <b-col md="6">
                              <b-form-group label="Port of Loading" label-for="port_of_loading">
                                <v-select v-model="formOperationalActivity.portLoading" label="text" :options="positionList" :reduce="positionList => positionList.value" placeholder="Select..." class="bg-white"></v-select>
                              </b-form-group>
                            </b-col>
                            <b-col md="6">
                              <b-form-group label="Agent POL" label-for="agent_pol" class="text-truncate">
                                <b-form-input v-model="formOperationalActivity.agentPol" type="text" id="agent_pol" placeholder="Type Text" class="bg-white"></b-form-input>
                              </b-form-group>
                            </b-col>
                            <b-col md="6">
                              <b-form-group label="Is Pol Sts Mv:" label-for="kind-fleet">
                                  <b-form-checkbox v-model="formOperationalActivity.polStsMv" name="check-button" switch inline :key="index">
                                    No / Yes
                                  </b-form-checkbox>
                              </b-form-group>
                            </b-col>
                            <b-col md="6">
                              <b-form-group label="Pol Mv Name" label-for="pol_mv_name" class="text-truncate">
                                <b-form-input v-model="formOperationalActivity.polMvName" type="text" id="pol_mv_name" placeholder="Type Text" class="bg-white"></b-form-input>
                              </b-form-group>
                            </b-col>
                            <b-col md="6">
                              <b-form-group label="Pol grab Type" label-for="pod_grab_type">
                                <v-select v-model="formOperationalActivity.polGrabType" label='text' :options="polGrabTypeOpt" :reduce="item => item.value"  placeholder="Select Pol Grab Type" class="bg-white"></v-select>
                              </b-form-group>
                            </b-col>
                            <b-col md="6">
                              <b-form-group label="Pol Fc Name" label-for="pol_fc_name" class="text-truncate">
                                <b-form-input v-model="formOperationalActivity.polFcName" :class="{'bg-white' : formOperationalActivity.polGrabType == 'gear_and_grab'}" :disabled="formOperationalActivity.polGrabType !== 'gear_and_grab'" type="text" id="pol_mv_name" placeholder="Type Text"></b-form-input>
                              </b-form-group>
                            </b-col>
                            <b-col md="6">
                              <b-form-group label="Is Pod Sts Mv:" label-for="pod_sts_mv">
                                  <b-form-checkbox v-model="formOperationalActivity.podStsMv" name="check-button" switch inline :key="index">
                                    No / Yes
                                  </b-form-checkbox>
                              </b-form-group>
                            </b-col>
                            <b-col md="6">
                              <b-form-group label="Pod Mv Name" label-for="pod_mv_name" class="text-truncate">
                                <b-form-input v-model="formOperationalActivity.podMvName" type="text" id="pol_mv_name" placeholder="Type Text" class="bg-white"></b-form-input>
                              </b-form-group>
                            </b-col>
                            <b-col md="6">
                              <b-form-group label="Pod grab Type" label-for="pod_grab_type">
                                <v-select v-model="formOperationalActivity.podGrabType" label='text' :options="polGrabTypeOpt" :reduce="item => item.value"  placeholder="Select POd Grab Type" class="bg-white"></v-select>
                              </b-form-group>
                            </b-col>
                            <b-col md="6">
                              <b-form-group label="Pod Fc Name" label-for="pod_fc_name" class="text-truncate">
                                <b-form-input v-model="formOperationalActivity.podFcName" :class="{'bg-white' : formOperationalActivity.podGrabType == 'gear_and_grab'}" :disabled="formOperationalActivity.podGrabType !== 'gear_and_grab'" type="text" id="pol_mv_name" placeholder="Type Text"></b-form-input>
                              </b-form-group>
                            </b-col>
                            <!-- Data Not Yet Provided -->
                            <b-col md="6" class="mb-2"> 
                              <b-form-group label="NOR" label-for="nor" class="text-truncate mb-0">
                                <date-picker class="custom-date-picker" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                              </b-form-group>
                            </b-col>
                            <!-- Data Not Yet Provided -->
                            <b-col md="6" class="mb-2"> 
                              <b-form-group label="NOA" label-for="noa" class="text-truncate mb-0">
                                <date-picker class="custom-date-picker" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                              </b-form-group>
                            </b-col>
                            <b-col md="6" class="mb-2">
                              <b-form-group label="Anchor Up" label-for="polAnchorUp" class="text-truncate mb-0">
                                <date-picker @change="validationTime(formOperationalActivity.polArrivalTime, formOperationalActivity.polAnchorUp, 'Anchor Up', 'Arrival Time', 1)" class="custom-date-picker" v-model="formOperationalActivity.polAnchorUp" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                              </b-form-group>
                              <small v-if="errorTimeText && errorTimeIndex === 1" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
                            </b-col>
                            <b-col  md="6" class="mb-2">
                              <b-form-group label="In Position / Berthing" label-for="polBerthing" class="text-truncate mb-0">
                                <date-picker @change="validationTime(formOperationalActivity.polAnchorUp, formOperationalActivity.polBerthing, 'Berthing', 'Anchor Up', 2)"  class="custom-date-picker" v-model="formOperationalActivity.polBerthing" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                              </b-form-group>
                              <small v-if="errorTimeText && errorTimeIndex === 2" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
                            </b-col>
                            <b-col md="6" class="mb-2">
                              <b-form-group label="Commenced" label-for="polCommenced" class="text-truncate mb-0">
                                <date-picker @change="validationTime(formOperationalActivity.polBerthing, formOperationalActivity.polCommenced, 'Commenced', 'Berthing', 3)" class="custom-date-picker"v-model="formOperationalActivity.polCommenced" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                              </b-form-group>
                              <small v-if="errorTimeText && errorTimeIndex === 3" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
                            </b-col>
                            <b-col md="6" class="mb-2">
                              <b-form-group label="Completed" label-for="completed" class="text-truncate mb-0">
                                <date-picker @change="validationTime(formOperationalActivity.polCommenced, formOperationalActivity.polCompleted, 'Completed', 'Commenced', 4)" class="custom-date-picker" v-model="formOperationalActivity.polCompleted" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                              </b-form-group>
                              <small v-if="errorTimeText && errorTimeIndex === 4" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
                            </b-col>
                            <b-col md="6">
                              <b-form-group label="Draught Survey" label-for="polDraughtSurvey" class="text-truncate mb-0">
                                <b-form-input v-model="formOperationalActivity.polDraughtSurvey" type="number" step="0.0001" id="polDraughtSurvey" placeholder="Type Number"></b-form-input>
                              </b-form-group>
                              <span class="text-black">{{ formOperationalActivity.polDraughtSurvey ? Number(formOperationalActivity.polDraughtSurvey).toLocaleString() : 0 }}</span>
                            </b-col>
                            <!-- Data Not Yet Provided -->
                            <b-col md="6" class="mb-2"> 
                              <b-form-group label="BL Date" label-for="blDate" class="text-truncate mb-0">
                                <date-picker class="custom-date-picker" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                              </b-form-group>
                              <small v-if="errorTimeText && errorTimeIndex === 5" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
                            </b-col>
                            <!-- Data Not Yet Provided -->
                            <b-col md="6" class="mb-2"> 
                              <b-form-group label="Cast In" label-for="polCastIn" class="text-truncate mb-0">
                                <date-picker class="custom-date-picker" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                              </b-form-group>
                              <small v-if="errorTimeText && errorTimeIndex === 5" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
                            </b-col>
                            <b-col md="6" class="mb-2">
                              <b-form-group label="Cast out" label-for="polCastOut" class="text-truncate mb-0">
                                <date-picker @change="validationTime(formOperationalActivity.polCompleted, formOperationalActivity.polCastOut, 'Cast Out', 'Completed', 5)"  class="custom-date-picker" v-model="formOperationalActivity.polCastOut" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                              </b-form-group>
                              <small v-if="errorTimeText && errorTimeIndex === 5" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
                            </b-col>
                            <b-col md="6" class="mb-2">
                              <b-form-group label="LHV" label-for="polLhv" class="text-truncate mb-0">
                                <date-picker @change="validationTime(formOperationalActivity.polCastOut, formOperationalActivity.polLhv, 'LHV', 'Cast Out', 6)"  class="custom-date-picker" v-model="formOperationalActivity.polLhv" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                              </b-form-group>
                              <small v-if="errorTimeText && errorTimeIndex === 6" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
                            </b-col>
                            <b-col md="6" class="mb-2"  v-if="showDocumentOnBoard">
                              <b-form-group label="Document On Board POL" label-for="document_on_board" class="text-truncate mb-0">
                                <date-picker @change="validationTime(formOperationalActivity.polLhv, formOperationalActivity.polDocOnBoard, 'Doc on Board', 'LHV', 7)" class="custom-date-picker" v-model="formOperationalActivity.polDocOnBoard" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                              </b-form-group>
                              <small v-if="errorTimeText && errorTimeIndex === 7" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
                            </b-col>
                            <b-col md="6" class="mb-2">
                              <b-form-group label="Departure to POD" label-for="polDeparture" class="text-truncate mb-0">
                                <date-picker @change="validationTime(formOperationalActivity.polDocOnBoard, formOperationalActivity.polDeparture, 'Departure', 'Doc on Board', 8)" class="custom-date-picker" v-model="formOperationalActivity.polDeparture" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                              </b-form-group>
                              <small v-if="errorTimeText && errorTimeIndex === 8" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
                            </b-col>
                            <!-- Data Not Yet Provided -->
                            <b-col md="12" class="mb-2"> 
                              <b-form-group label="Remarks" label-for="polCastIn" class="text-truncate mb-0">
                                <b-form-textarea placeholder="Type Text...">
                                </b-form-textarea>
                              </b-form-group>
                            </b-col>
                          </b-form-row>
                        </fieldset>
                      </b-col>
                      <b-col md="6">
                        <fieldset class="form-group border p-3 iq-bg-info">
                          <legend class="w-auto px-2 text-primary h6"><strong>DISCHARGE ACTIVITY RECORD</strong></legend>
                          <b-form-row>
                            <b-col md="6">
                              <b-form-group label="Port of Discharge" label-for="port_of_discharge">
                                <v-select class="bg-white" v-model="formOperationalActivity.portDischarge" label="text" :options="positionList" :reduce="positionList => positionList.value" placeholder="Select..."></v-select>
                              </b-form-group>
                            </b-col>
                            <b-col md="6">
                              <b-form-group label="Agent POD" label-for="agent_pod" class="text-truncate">
                                <b-form-input v-model="formOperationalActivity.agentPod" type="text" id="agent_pod" placeholder="Type Text" class="bg-white" ></b-form-input>
                              </b-form-group>
                            </b-col> 
                            <b-col md="6" class="mb-2">
                              <b-form-group label="Arrival Time POD" label-for="arrival_time_pod" class="text-truncate">
                                <date-picker @change="validationTime(currentValidTime, formOperationalActivity.podArrivalTime)"  class="custom-date-picker" v-model="formOperationalActivity.podArrivalTime" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                              </b-form-group>
                            </b-col>
                            <!-- Data Not Yet Provided -->
                            <b-col md="6" class="mb-2"> 
                              <b-form-group label="NOR" label-for="nor" class="text-truncate mb-0">
                                <date-picker class="custom-date-picker" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                              </b-form-group>
                            </b-col>
                            <!-- Data Not Yet Provided -->
                            <b-col md="6" class="mb-2"> 
                              <b-form-group label="NOA" label-for="noa" class="text-truncate mb-0">
                                <date-picker class="custom-date-picker" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                              </b-form-group>
                            </b-col>
                            <b-col md="6" class="mb-2">
                              <b-form-group label="Anchor Up" label-for="podAnchorUp" class="text-truncate mb-0">
                                <date-picker @change="validationTime(formOperationalActivity.podArrivalTime, formOperationalActivity.podAnchorUp, 'Anchor Up', 'Arrival Time', 11)" class="custom-date-picker" v-model="formOperationalActivity.podAnchorUp" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                              </b-form-group>
                              <small v-if="errorTimeText && errorTimeIndex === 11" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
                            </b-col>
                            <b-col md="6" class="mb-2">
                              <b-form-group label="In Position / Berthing" label-for="in_position_pod" class="text-truncate mb-0">
                                <date-picker @change="validationTime(formOperationalActivity.podAnchorUp, formOperationalActivity.podBerthing, 'Berthing', 'Anchor Up', 12)" class="custom-date-picker" v-model="formOperationalActivity.podBerthing" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                              </b-form-group>
                              <small v-if="errorTimeText && errorTimeIndex === 12" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
                            </b-col>
                            <b-col md="6" class="mb-2">
                              <b-form-group label="Commenced" label-for="commenced_pod" class="text-truncate mb-0">
                                <date-picker @change="validationTime(formOperationalActivity.podBerthing, formOperationalActivity.podCommenced, 'Commenced', 'Berthing', 13)" class="custom-date-picker" v-model="formOperationalActivity.podCommenced" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                              </b-form-group>
                              <small v-if="errorTimeText && errorTimeIndex === 13" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
                            </b-col>
                            <b-col md="6" class="mb-2">
                              <b-form-group label="Completed" label-for="completed_pod" class="text-truncate mb-0">
                                <date-picker @change="validationTime(formOperationalActivity.podCommenced, formOperationalActivity.podCompleted, 'Completed', 'Commenced', 14)" class="custom-date-picker" v-model="formOperationalActivity.podCompleted" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                              </b-form-group>
                              <small v-if="errorTimeText && errorTimeIndex === 14" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
                            </b-col>
                            <b-col md="6">
                              <b-form-group label="Draught Survey" label-for="podDraughtSurvey" class="text-truncate mb-0">
                                <b-form-input v-model="formOperationalActivity.podDraughtSurvey" type="number" step="0.0001" id="podDraughtSurvey" placeholder="Type Number"></b-form-input>
                              </b-form-group>
                              <span class="text-black">{{ formOperationalActivity.podDraughtSurvey ? Number(formOperationalActivity.podDraughtSurvey).toLocaleString() : 0 }}</span>
                            </b-col>
                            <!-- Data Not Yet Provided -->
                            <b-col md="6" class="mb-2"> 
                              <b-form-group label="BL Date" label-for="blDate" class="text-truncate mb-0">
                                <date-picker class="custom-date-picker" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                              </b-form-group>
                            </b-col>
                            <!-- Data Not Yet Provided -->
                            <b-col md="6" class="mb-2"> 
                              <b-form-group label="Cast In" label-for="polCastIn" class="text-truncate mb-0">
                                <date-picker class="custom-date-picker" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                              </b-form-group>
                              <small v-if="errorTimeText && errorTimeIndex === 5" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
                            </b-col>
                            <b-col md="6" class="mb-2">
                              <b-form-group label="Cast Out" label-for="podCastOut" class="text-truncate mb-0">
                                <date-picker @change="validationTime(formOperationalActivity.podCompleted, formOperationalActivity.podCastOut, 'Cast Out', 'Completed', 15)" class="custom-date-picker" v-model="formOperationalActivity.podCastOut" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                              </b-form-group>
                              <small v-if="errorTimeText && errorTimeIndex === 15" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
                            </b-col>
                            <!-- <b-col md="6" class="mb-2">
                              <b-form-group label="LHV" label-for="podLhv" class="text-truncate mb-0"> 
                                <date-picker @change="validationTime(formOperationalActivity.podCastOut, formOperationalActivity.podLhv, 'LHV', 'Cast Out', 16)" class="custom-date-picker" v-model="formOperationalActivity.podLhv" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                              </b-form-group>
                              <small v-if="errorTimeText && errorTimeIndex === 16" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
                            </b-col> -->
                            <b-col v-if="showDocumentOnBoard" md="6"  class="mb-2">
                              <b-form-group label="Document On Board POD" label-for="document_on_board" class="text-truncate mb-0">
                                <date-picker @change="validationTime(formOperationalActivity.podLhv, formOperationalActivity.docOnBoard, 'Doc on Board', 'LHV', 17)" class="custom-date-picker" v-model="formOperationalActivity.docOnBoard" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                              </b-form-group>
                              <small v-if="errorTimeText && errorTimeIndex === 17" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
                            </b-col>
                            <b-col md="6" class="mb-2">
                              <b-form-group label="Departure to POL" label-for="podDeparture" class="text-truncate mb-0">
                                <date-picker @change="validationTime(formOperationalActivity.docOnBoard, formOperationalActivity.podDeparture, 'Departure', 'Doc on Board', 18)" class="custom-date-picker" v-model="formOperationalActivity.podDeparture" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm"></date-picker>
                              </b-form-group>
                              <small v-if="errorTimeText && errorTimeIndex === 18" class="text-danger" style="margin-top:-30px">*{{ errorTimeText}}</small>
                            </b-col>
                             <!-- Data Not Yet Provided -->
                             <b-col md="12" class="mb-2"> 
                              <b-form-group label="Remarks" label-for="polCastIn" class="text-truncate mb-0">
                                <b-form-textarea placeholder="Type Text...">
                                </b-form-textarea>
                              </b-form-group>
                            </b-col>
                          </b-form-row>
                        </fieldset>
                      </b-col>
                      <b-col md="12">
                        <hr>
                      </b-col>
                      <b-col md="6">
                        <fieldset class="form-group border p-3 iq-bg-info">
                          <legend class="w-auto px-2 text-primary h6"><strong>PROCESS ACTIVITY</strong></legend>
                          <template>
                            <b-form-group label="Current Activity *" label-for="reason">
                              <v-select v-model="formOperationalActivity.reasonId" label="text" :options="reasonList" :reduce="reasonList => (reasonList.value+'|'+reasonList.text)" placeholder="Select Current Activity"></v-select>
                            </b-form-group>
                            <b-form-row class="mb-3" v-if="formOperationalActivity.reasonId && formOperationalActivity.reasonId.split('|')[1] === 'Loading'">
                              <b-col>
                                <b-form-group label="Cargo Type *" label-for="cargo_type" v-if="formOperationalActivity.reasonId.split('|')[1] === 'Loading'">
                                  <v-select v-model="formOperationalActivity.cargoType" label="text" :options="optionsCargoType" :reduce="cargoOptions => cargoOptions.value" placeholder="Select Type of Cargo"></v-select>
                                </b-form-group> 
                              </b-col>
                              <b-col  v-if="formOperationalActivity.reasonId.split('|')[1] === 'Loading'">
                                <b-form-group label="Cargo Unit" label-for="polCargoUnit">
                                  <b-form-select
                                    id="polCargoUnit"
                                    plain
                                    size="md"
                                    v-model="formOperationalActivity.polCargoUnit"
                                    :options="optionsUnit"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="null">Select....</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                </b-form-group>
                              </b-col> 
                              <!-- <b-col v-if="ormOperationalActivity.cargoType === 'others'">
                                <b-form-group label="Other Cargo " label-for="other_cargo">
                                  <b-form-input type="text" v-model="typeOfCargoOther"></b-form-input>
                                </b-form-group>
                              </b-col> -->
                            </b-form-row>
                            <b-form-row class="mb-3" v-if="formOperationalActivity.reasonId && formOperationalActivity.reasonId.split('|')[1] === 'Loading'">
                              <b-col>
                                <b-form-group label="Cargo Loading *" label-for="cargo_loading" class="d-flex flex-column">
                                  <b-input-group size="md" :append="formOperationalActivity.polCargoUnit ?? 'N/A'">
                                    <b-form-input type="number" step="0.0001" v-model="formOperationalActivity.cargoLoading"></b-form-input>
                                  </b-input-group>
                                  <span class="text-black">{{ formOperationalActivity?.cargoLoading ? Number(formOperationalActivity?.cargoLoading).toLocaleString() : 0 }} {{ formOperationalActivity.polCargoUnit }}</span>
                                  <br />
                                  <small class="text-warning" style="float: left"><i class="fa fa-warning"></i> Type Number</small>
                                </b-form-group>
                              </b-col>
                            </b-form-row>
                            <b-form-row class="mb-3" v-if="formOperationalActivity.reasonId && formOperationalActivity.reasonId.split('|')[1]  === 'Unloading'">
                              <b-col>
                                <b-form-group label="Cargo Unloading *" label-for="cargo_unloading">
                                  <b-input-group size="md" append="Ton">
                                    <b-form-input type="number" v-model="formOperationalActivity.cargoUnloading"></b-form-input>
                                  </b-input-group>
                                  <span class="text-black">{{ formOperationalActivity?.cargoUnloading ? Number(formOperationalActivity?.cargoUnloading).toLocaleString() : 0 }} Ton</span>
                                  <br />
                                  <small class="text-warning" style="float: left"><i class="fa fa-warning"></i> Type Number</small>
                                </b-form-group>
                              </b-col>
                              <b-col>
                                <b-form-group label="Is End Trip:" label-for="kind-fleet">
                                    <b-form-checkbox v-model="formOperationalActivity.endOfTrip" name="check-button" switch inline :key="index">
                                      No / Yes
                                    </b-form-checkbox>
                                </b-form-group>
                              </b-col>
                            </b-form-row>
                            <b-form-group label="Remarks" label-for="remarks">
                              <b-form-textarea id="description" rows="2" v-model="formOperationalActivity.remarks"></b-form-textarea>
                              <small style="float: left"><i class="fa fa-warning"></i> Max 500 Character</small>
                            </b-form-group>
                          </template>
                        </fieldset>
                      </b-col>
                      <b-col md="6">
                        <fieldset class="form-group border p-3 iq-bg-info">
                          <legend class="w-auto px-2 text-primary h6"><strong>DATA TRACKER</strong></legend>
                          <template v-if="showLatitude || showLongtitude">
                            <b-alert :show="true" variant=" " class="text-white bg-primary"><strong>GPS DMS Lat Long</strong></b-alert>
                            <template v-if="showLatitude">
                              <b-alert :show="true" variant=" " class="text-white bg-info"><strong>LATITUDE DMS</strong></b-alert>
                              <b-form-row class="mb-0">
                                <b-col>
                                  <b-form-group label="Degrees *" label-for="gps">
                                    <b-input-group size="md" append="°">
                                      <b-form-input type="number" placeholder="Degrees" v-model="dmsLat.degrees"></b-form-input>
                                    </b-input-group>
                                  </b-form-group>
                                </b-col>
                                <b-col>
                                  <b-form-group label="Minutes *" label-for="gps">
                                    <b-input-group size="md" append="'">
                                      <b-form-input type="number" placeholder="Minutes" v-model="dmsLat.minutes"></b-form-input>
                                    </b-input-group>
                                  </b-form-group>
                                </b-col>
                                <b-col>
                                  <b-form-group label="Seconds *" label-for="gps">
                                    <b-input-group size="md" append='"'>
                                      <b-form-input type="number" placeholder="Seconds" v-model="dmsLat.seconds"></b-form-input>
                                    </b-input-group>
                                  </b-form-group>
                                </b-col>
                                <b-col>
                                  <b-form-group label="Directions" label-for="gps">
                                    <b-form-select v-model="dmsLat.directions" size="md">
                                      <b-form-select-option>Please select an option</b-form-select-option>
                                      <b-form-select-option value="N">N</b-form-select-option>
                                      <b-form-select-option value="S">S</b-form-select-option>
                                    </b-form-select>
                                  </b-form-group>
                                </b-col>
                              </b-form-row>
                            </template>
                            <template v-if="showLongtitude">
                              <b-alert :show="true" variant=" " class="text-white bg-info"><strong>LONGITUDE DMS</strong></b-alert>
                              <b-form-row class="mb-0">
                                <b-col>
                                  <b-form-group label="Degrees *" label-for="gps">
                                    <b-input-group size="md" append="°">
                                      <b-form-input type="number" placeholder="Degrees" v-model="dmsLon.degrees"></b-form-input>
                                    </b-input-group>
                                  </b-form-group>
                                </b-col>
                                <b-col>
                                  <b-form-group label="Minutes *" label-for="gps">
                                    <b-input-group size="md" append="'">
                                      <b-form-input type="number" placeholder="Minutes" v-model="dmsLon.minutes"></b-form-input>
                                    </b-input-group>
                                  </b-form-group>
                                </b-col>
                                <b-col>
                                  <b-form-group label="Seconds *" label-for="gps">
                                    <b-input-group size="md" append='"'>
                                      <b-form-input type="number" placeholder="Seconds" v-model="dmsLon.seconds"></b-form-input>
                                    </b-input-group>
                                  </b-form-group>
                                </b-col>
                                <b-col>
                                  <b-form-group label="Directions" label-for="gps">
                                    <b-form-select v-model="dmsLon.directions" size="md">
                                      <b-form-select-option>Please select an option</b-form-select-option>
                                      <b-form-select-option value="E">E</b-form-select-option>
                                      <b-form-select-option value="W">W</b-form-select-option>
                                    </b-form-select>
                                  </b-form-group>
                                </b-col>
                              </b-form-row>
                            </template>
                            <b-form-row class="mb-0">
                              <b-col v-if="showLatitude">
                                <b-form-group label="GPS Latitude" label-for="gps">
                                  <b-form-input type="number" placeholder="latitude" v-model="formOperationalActivity.lat" disabled></b-form-input>
                                </b-form-group>
                              </b-col>
                              <b-col v-if="showLongtitude">
                                <b-form-group label="GPS Longitude" label-for="gps">
                                  <b-form-input type="number" placeholder="longitude" class="mt-2" v-model="formOperationalActivity.lon" disabled></b-form-input>
                                </b-form-group>
                              </b-col>
                            </b-form-row>
                          </template>
                          <b-form-row class="mb-0" v-if="showSpeed">
                            <b-col>
                              <b-form-group label="Speed *" label-for="speed">
                                <b-input-group size="md" append="Knot">
                                  <b-form-input type="number" v-model="formOperationalActivity.speed"></b-form-input>
                                </b-input-group>
                                <small style="float: left"><i class="fa fa-warning"></i> Type Number</small>
                              </b-form-group>
                            </b-col>
                          </b-form-row>
                          <b-form-row v-if="showPIC">
                            <b-col>
                              <b-form-group label="PIC / PE *" label-for="pic">
                                <v-select v-model="picSelected" label="text" :options="picList" :reduce="picList => picList.value" placeholder="Select PIC" :multiple="true"></v-select>
                                <!-- <v-multiselect v-model="picSelected" placeholder="Choose PIC" label="name" track-by="value" :options="picList" :reduce="picList => picList.value" :multiple="true">
                                  <span slot="noResult">Category not found.</span>
                                </v-multiselect> -->
                                <!-- <b-form-input type="text" value="Auto Suggest Based on Login"> </b-form-input> -->
                              </b-form-group>
                            </b-col>
                          </b-form-row>
                        </fieldset>
                        <b-card-text style="margin-top:-18px"><small class="text-muted">Sign * : <b-badge variant="warning">Mandatory (Must be Input)</b-badge></small></b-card-text>
                      </b-col>
                      <b-col md="12">
                        <hr>
                      </b-col>
                    </b-row>
                  </b-col>
                </template>
              </b-col>
               <!-- Last Operational -->
              <b-col cols="4" md="4">
                <h5 class="card-title text-primary mb-4"><strong>LAST OPERATIONAL</strong></h5>
                <ul class="iq-timeline m-0 p-0" style="height:600px">
                  <li v-for="each in activityList" :key="each.id">
                    <b-row class="m-0">
                      <b-col md="10">
                        <div class="timeline-dots-fill"></div>
                        <h6 class="float-left mb-2 text-dark"><i class="ri-time-fill"></i> {{each.startDate +' - '+ each.endDate}}</h6>
                        <div class="d-inline-block w-100">
                          <!-- <b-badge variant="danger">Market : 2 Hours | 42 Minutes</b-badge> -->
                          <b-badge variant="info">{{each.activityDurationFormat}}</b-badge>
                          <p>{{each.activityName}}</p>
                        </div>
                      </b-col>
                      <b-col md="2">
                        <b-dropdown variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                          <template v-slot:button-content>
                            <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                            <i class="fa fa-caret-down m-0 text-primary"></i>
                            </span>
                          </template>
                          <b-dropdown-item href="#" class="m-0 p-0" @click="() => {getActivityDetail(each.id, true); locked = true}"><i class="fa fa-pencil-square-o mr-2"></i>Edit</b-dropdown-item>
                          <b-dropdown-item href="#" class="m-0 p-0" @click="onDeleteActivity(each)"><i class="fa fa-trash mr-2"></i>Delete</b-dropdown-item>
                        </b-dropdown>
                      </b-col>
                    </b-row>
                  </li>
                </ul>
              </b-col>
              <!-- SHELTER -->
              <b-col md="12" v-if="nextStep">
                <b-form-checkbox name="check-button" class="mb-3" switch v-model="useShelter">Use Shelter?
                </b-form-checkbox>
                <fieldset class="form-group border p-3 iq-bg-warning" v-if="useShelter">
                  <legend class="w-auto px-2 text-danger h6"><strong>SHELTER</strong></legend>
                  <b-row>
                    <b-col md="12">
                      <div class='d-flex justify-content-end w-100 m-0'>
                        <b-button class="mb-2" variant="primary" type="button" @click="addShelter">Add Shelter <i class="fa fa-plus" /></b-button>
                      </div>
                      <b-col md="12">
                        <div class="d-flex w-100 border mb-2" />
                      </b-col>
                      <b-row v-for="(val, index) in formOperationalActivity.shelter" :key="index">
                        <b-col md="2">
                          <b-form-group label="Shelter For" label-for="shelter_for"> 
                            <b-form-select v-model="val.shelterFor" plain size="sm" style="background-color:#ffffff;">
                              <template v-slot:first>
                                <b-form-select-option :value="''">Select...</b-form-select-option>
                                <b-form-select-option :value="'loading'">Loading</b-form-select-option>
                                <b-form-select-option :value="'discharge'">Discharge</b-form-select-option>
                                <b-form-select-option :value="'others'">Others</b-form-select-option>
                              </template>
                            </b-form-select>
                          </b-form-group> 
                        </b-col>
                        <b-col :md="['Others Position'].includes(val.shelterLocation) ? '4' : '2'">
                          <b-form-group label="Shelter Location" label-for="shelter_location"> 
                            <div class="d-flex">
                              <v-select 
                                v-model="val.shelterLocation" 
                                label="text" 
                                :options="positionList" 
                                :reduce="positionList => positionList.text" 
                                placeholder="Select..." 
                                style="background-color:#ffffff; width:200px"
                                class="mr-2"
                              />
                              <b-form-input
                                v-if="['Others Position'].includes(val.shelterLocation)"
                                type="text" v-model="val.shelterLocationOther"
                                style="background-color:#ffffff; width:200px"
                                placeholder="Other shelter..."
                              />
                            </div>
                          </b-form-group> 
                        </b-col> 
                        <b-col :md="['Others Position'].includes(val.destination) ? '4' : '2'">
                          <b-form-group label="Destination" label-for="destination"> 
                            <div class="d-flex">
                              <v-select 
                                v-model="val.destination" 
                                label="text" 
                                :options="positionList" 
                                :reduce="positionList => positionList.text" 
                                placeholder="Select..." 
                                style="background-color:#ffffff; width:200px"
                                class="mr-2"
                              />
                              <b-form-input
                                v-if="['Others Position'].includes(val.destination)"
                                type="text" v-model="val.destinationOther"
                                style="background-color:#ffffff; width:200px"
                                placeholder="Other destination..."
                              />
                            </div>
                          </b-form-group> 
                        </b-col> 
                        <b-col md="2">
                          <b-form-group label="Arrival Shelter" label-for="arrival"> 
                            <date-picker 
                              v-model="val.arrivalShelter" 
                              value-type="DD-MM-YYYY HH:mm" 
                              type="datetime"
                              placeholder="Select date" 
                              format="DD-MM-YYYY HH:mm" 
                              class="custom-date-picker"
                            />
                          </b-form-group> 
                        </b-col> 
                        <b-col md="2">
                          <b-form-group label="Drop Anchor Shelter" label-for="arrival"> 
                            <date-picker 
                              v-model="val.dropAnchorShelter" 
                              value-type="DD-MM-YYYY HH:mm" 
                              type="datetime"
                              placeholder="Select date" 
                              format="DD-MM-YYYY HH:mm" 
                              class="custom-date-picker"
                            />
                          </b-form-group> 
                        </b-col> 
                        <b-col md="2">
                          <b-form-group label="Full Aways Shelter" label-for="fullaways"> 
                            <date-picker 
                              v-model="val.fullAwayShelter" 
                              value-type="DD-MM-YYYY HH:mm" 
                              type="datetime"
                              placeholder="Select date" 
                              format="DD-MM-YYYY HH:mm" 
                              class="custom-date-picker"
                            />
                          </b-form-group> 
                        </b-col> 
                        <b-col md="4">
                          <b-form-group label="Remarks" label-for="arrival"> 
                            <b-form-textarea 
                              v-model="val.remarks"
                              name="remarks"
                              placeholder="Type Text"
                              style="background-color:#ffffff;"
                            />
                          </b-form-group> 
                        </b-col>  
                        <b-col md="1" v-if="index !== 0">
                          <div class="d-flex align-items-center w-auto h-100 mt-1">
                            <i class="fa fa-trash text-danger text-xl" @click="formOperationalActivity.shelter.splice(index, 1)" style="cursor: pointer" />
                          </div>
                        </b-col> 
                        <b-col md="12">
                          <div class="d-flex w-100 border mb-2" />
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </fieldset>
              </b-col>

              <b-col md="12" class="text-right mt-3" v-if="nextStep">
                <b-button type="submit" variant="primary" @click="saveActivity()"><i class="fa fa-save"></i> Save</b-button>
                <b-button v-if="!isEdit" type="submit" variant="none" class="iq-bg-danger ml-3" @click="resetForm"><i class="fa fa-close"></i> Reset Form</b-button>
                <b-button v-if="isEdit" type="submit" variant="none" class="iq-bg-danger ml-3" @click="cancelEdit()"><i class="fa fa-close"></i> Cancel Edit</b-button>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Top Section !-->
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { voyagesActions, fleetsActions, projectsActions, reasonsActions, portsActions, usersActions, busdevActions } from '@src/Utils/helper'
import { required, requiredIf, sameAs, minLength, minValue } from 'vuelidate/lib/validators'
import moment from 'moment'
import { singleConvertDMSToDD } from '@src/plugins/helpers'

var date = new Date()

export default {
name: 'Operational',
data () {
  return {
    optionsUnit:[],
    optionsCargoType:[],
    // Connect with API
    useShelter: false,
    isEdit: false,
    isActivityId: '',
    fleet: {
      vehicleType: {
        name: ''
      }
    },
    fleetEdit: null,
    activityList: [],
    fleetList: [],
    dmsLat: {
      degrees: '',
      minutes: '',
      seconds: '',
      directions: 'S'
    },
    dmsLon: {
      degrees: '',
      minutes: '',
      seconds: '',
      directions: 'E'
    },
    formOperationalActivity: {
      startDate: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
      endDate: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
      vehicles: [
        {
          vehicleId: '',
          vehicleNumber: 1,
          breakdown: false
        },
        {
          vehicleId: '',
          vehicleNumber: 2,
          breakdown: false
        }
      ],
      fleetId:null,
      voyageActivityType: 'UNTOWING',
      subProjectId: '',
      reasonId: '',
      cargoType: '',
      cargoLoading: '',
      cargoUnloading: '',
      remarks: '',
      currentPosition: '',
      arrival: '',
      departure: '',
      portLoading: '',
      portLoadingOther: '',
      portDischarge: '',
      portDischargeOther: '',
      agentPol: '',
      agentPod: '',
      dmsLat: '',
      dmsLon: '',
      lat: '',
      lon: '',
      speed: '',
      docOnBoard: '',
      pic: [],
      endOfTrip: true,
      "destinationOther": "",
      "originOther": "",
      "newTrip": false,
      "polCargoUnit": "",
      "polAnchorUp": "",
      "polArrivalTime":"",
      "polBerthing":"",
      "polCommenced":"",
      "polCompleted":"",
      "polDocOnBoard":"", 
      "polDeparture":"",
      "polCastOut":"",
      "polLhv":"",
      "podCargoUnit":"",
      "podAnchorUp":"",
      "podLhv":"",
      "podCastOut":"",
      "podDeparture":"",
      "podArrivalTime":"",
      "podBerthing":"",
      "podCommenced":"",
      "podCompleted":"",
      "voyageFor": "loading",
      "voyageForOtherValue": "",  
      "origin": "",
      "destination": "",
      "speedBySystem": 0,
      "polDraughtSurvey": 0,
      "podDraughtSurvey": 0,
      polStsMv:false,
      polMvName:'',
      polGrabType:'',
      polFcName:'',
      podStsMv:false,
      podMvName:'',
      podGrabType:'',
      podFcName:'',

      "shelter": [{
        "shelterLocationOther": "", 
        "destinationOther": "", 
        "shelterFor": "", // loading, discharge, others
        "shelterLocation": "",
        "destination": "",
        "arrivalShelter": "",
        "dropAnchorShelter": "",
        "fullAwayShelter": "",
        "remarks": ""
      }]
    },
    locked: false,

    loadingForm: false,
    loadingCheck: false,
    // End Connect
    nextStep: false,
    secondFleet: '7',
    anotherFleet: '3',
    startDate: '2021-04-13T13:45:00',
    endDate: '',
    reason: null,
    moreLoading: true,
    breakdown: false,
    breakdownAnother: false,
    endMoreLoading: true,
    selectedAction: 'UNTOWING',
    action: [
      {
        label: 'Not Towing',
        color: 'default',
        value: 'UNTOWING'
      },
      {
        label: 'Towing Tug/Barge',
        color: 'primary',
        value: 'SINGLE_TOWING'
      },
      {
        label: 'Double Towing',
        color: 'warning',
        value: 'DOUBLE_TOWING'
      }
    ],
    projectValue: '',
    projectOptions: [],
    project: 2,
    projectList: [
      { value: '1', text: 'Arutmin Project' },
      { value: '2', text: 'KPC Project' },
      { value: '3', text: 'AI Project' },
      { value: '5', text: 'Virtue Dragon Nickel Industry Project' },
      { value: '6', text: 'Pracadi Project' },
      { value: '7', text: 'Conch Project' },
      { value: '8', text: 'Tsingshan Steel Indonesia Project' }
    ],
    subProject: 1,
    subProjectList: [
      { value: '1', text: 'Transhipment' },
      { value: '2', text: 'Long Hauling' },
      { value: '3', text: 'Armada Oil Barge' },
      { value: '4', text: 'Finance' },
      { value: '5', text: 'Loading' },
      { value: '6', text: 'Unloading' }
    ],
    position: '',
    positionOther: '',
    positionList: [
      { value: 'Others', text: 'Others Position' }
    ],
    reasonList: [],
    portList: [
      { value: 'Asam-Asam', text: 'Asam-Asam' },
      { value: 'Sangatta', text: 'Sangatta' },
      { value: 'Paiton', text: 'Paiton' },
      { value: 'Bengalon', text: 'Bengalon' },
      { value: 'Tanjung Bara', text: 'Tanjung Bara' },
      { value: 'Tanjung Awar-Awar', text: 'Tanjung Awar-Awar' },
      { value: 'Rembang', text: 'Rembang' }
    ],

    picSelected: null,
    picList: [],
    agentPOL: 'TCP',
    agentPOD: 'SAJ',
    agentList: [
      { value: 'TCP', text: 'TCP' },
      { value: 'SAJ', text: 'SAJ' },
      { value: 'ANTAM', text: 'ANTAM' }
    ],
    typeOfCargo: 'COAL',
    typeOfCargoOther: '',
    cargoOptions: [
      { value: 'COAL', text: 'COAL' },
      { value: 'FUEL', text: 'FUEL' },
      { value: 'OIL', text: 'OIL' },
      { value: 'NICKEL', text: 'NICKEL' },
      { value: 'CRANE-CONTAINER', text: 'CRANE CONTAINER' },
      { value: 'Others', text: 'Others...' }
    ],

    voyageActive: {
      single: 'active',
      disable: 'active',
      number: 'loading',
      color: 'danger'
    },

    

    voyagefor: [
      {
        name: 'number',
        label: 'Loading',
        value: 'loading',
        disabled: false
      },
      {
        name: 'number',
        label: 'Discharge',
        value: 'discharge',
        disabled: false
      },
      {
        name: 'number',
        label: 'Others',
        value: 'others',
        disabled: false
      }
    ],

    polGrabTypeOpt:[
      {
        text:'Gearless',
        value:'gearless'
      },
      {
        text:'Gear and Grab',
        value:'gear_and_grab'
      }
    ],
    
    currentValidTime: null,
    errorTimeText: '',
    errorTimeIndex: null,
    errorValidation: false,
    
    optionsFleets: [],

    unfinishedTrip: [],
    finishedTrip: [],
    
    crossTrip:false,
    crossTripList: [],
  }
},
computed: {
  vehicleId () {
    const { vehicleId } = this.$route.query

    if (!vehicleId) {
      return null
    }

    return vehicleId
  },
  vehicleIdParam () {
    return this.$route.query.vehicleId
      ? this.$route.query.vehicleId
      : ''
  },
  combineDNSLat () {
    return `${this.dmsLat.degrees}° ${this.dmsLat.minutes}' ${this.dmsLat.seconds}" ${this.dmsLat.directions}`
  },
  combineDNSLng () {
    return `${this.dmsLon.degrees}° ${this.dmsLon.minutes}' ${this.dmsLon.seconds}" ${this.dmsLon.directions}`
  },
  fleetListFiltered () {
    let currentFleetId = !this.isEdit ? this.fleet.id : this.fleetEdit.id
    return this.fleetList.filter(fleet => Number(fleet.id) !== Number(currentFleetId))
  },
  // field visibilities
  showVoyageWidth () {
    return ![3, 6].includes(this.fleet?.vehicleType?.id)
  },
  showDepartureDateTime () {
    return ![6].includes(this.fleet?.vehicleType?.id)
  },
  showArrivalDateTime () {
    return ![6].includes(this.fleet?.vehicleType?.id)
  },
  showPortOfLoading () {
    return ![6].includes(this.fleet?.vehicleType?.id)
  },
  showPortOfDischarge () {
    return ![6].includes(this.fleet?.vehicleType?.id)
  },
  showPortAgentPol () {
    return ![6].includes(this.fleet?.vehicleType?.id)
  },
  showPortAgentPod () {
    return ![6].includes(this.fleet?.vehicleType?.id)
  },
  showLatitude () {
    return ![6].includes(this.fleet?.vehicleType?.id)
  },
  showLongtitude () {
    return ![6].includes(this.fleet?.vehicleType?.id)
  },
  showSpeed () {
    return ![6].includes(this.fleet?.vehicleType?.id)
  },
  showDocumentOnBoard () {
    return ![6].includes(this.fleet?.vehicleType?.id)
  },
  showPIC () {
    return ![6].includes(this.fleet?.vehicleType?.id)
  }
},
validations () {
  let ret = {
    formOperationalActivity: {
      startDate: { required },
      endDate: { required }
    }
  }

  return ret
  /* if (this.formOperationalActivity.voyageActivityType == 'TOWING' || this.formOperationalActivity.voyageActivityType == 'DOUBLE-TOWING'){
    return {
      formOperationalActivity: {
        startDate: { required },
        endDate: { required }
      }
    }
  } else {
    return {
      formOperationalActivity: {
        startDate: { required },
        endDate: { required },
      }
    }
  } */
},
async mounted () {
  xray.index()
  await this.getCargoType()
  await this.getUnit()
  await this.fetchFleets()
  await this.fetchFleet()
  await this.listFleet()
  await this.getProjectList()
  await this.getReasonList()
  await this.getPortList()
  await this.getUserList()
  await this.getLastActivity()
  await this.fetchUnfinishTrip()
  if (this.$route.query.activityEditId) {
    this.getActivityDetail(this.$route.query.activityEditId, true)
  } else {
    this.initActivityForm()
  }
},
watch: {
  'dmsLat': {
    handler () {
      this.generateGPSLat()
    },
    deep: true
  },
  'dmsLon': {
    handler () {
      this.generateGPSLng()
    },
    deep: true
  },
  endDate: function () {
    if (this.endDate <= this.startDate) {
      this.$swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'End Date tidak boleh kurang dari Start Date!'
      }).then((result) => {
        this.endDate = ''
      })
    }
  }
},
methods: {
  ...busdevActions,
  ...voyagesActions,
  ...fleetsActions,
  ...projectsActions,
  ...reasonsActions,
  ...portsActions,
  ...usersActions,
  onCheckedUnfinishedTrip(index){
    this.unfinishedTrip = this.unfinishedTrip.map((val, valIndex) => {
      if(valIndex !== index) {
        val.checked = false
      } 

      return val
    })
  },
  onCheckedCrossTrip(index){
    this.crossTripList = this.crossTripList.map((val, valIndex) => {
      if(valIndex !== index) {
        val.checked = false
      } 

      return val
    })
  },
  validationTime(oldTime, currentTime, labelCurrent, labelBefore, indexes){
    // Parse the datetime strings using Moment.js
    let old = moment(new Date(oldTime), 'DD-MM-YYYY HH:mm');
    let current = moment(new Date(currentTime), 'DD-MM-YYYY HH:mm');

    this.errorTimeIndex = indexes

    if (current.isSameOrBefore(old)) {
      this.errorTimeText = `${labelCurrent} must greater than ${labelBefore}`
      this.errorValidation =  true
      return
    } 

    this.errorTimeText = ''
    this.currentValidTime = currentTime
    this.errorValidation =  false
  },
  addShelter(){
    this.formOperationalActivity.shelter.push({
      "shelterLocationOther": "", 
      "destinationOther": "", 
      "shelterFor": "", 
      "shelterLocation": "",
      "destination": "",
      "arrivalShelter": "",
      "dropAnchorShelter": "",
      "fullAwayShelter": "",
      "remarks": ""
    })
  },
  resetForm(){
    this.nextStep = false
    this.locked = false

    // this.unfinishedTrip = []

    this.formOperationalActivity =  {
      startDate: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
      endDate: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
      vehicles: [
        {
          vehicleId: '',
          vehicleNumber: 1,
          breakdown: false
        },
        {
          vehicleId: '',
          vehicleNumber: 2,
          breakdown: false
        }
      ],
      voyageActivityType: 'UNTOWING',
      subProjectId: '',
      reasonId: '',
      cargoType: '',
      cargoLoading: '',
      cargoUnloading: '',
      remarks: '',
      currentPosition: '',
      arrival: '',
      departure: '',
      portLoading: '',
      portLoadingOther: '',
      portDischarge: '',
      portDischargeOther: '',
      agentPol: '',
      agentPod: '',
      dmsLat: '',
      dmsLon: '',
      lat: '',
      lon: '',
      speed: '',
      docOnBoard: '',
      pic: [],
      endOfTrip: true,
      "destinationOther": "",
      "originOther": "",
      "newTrip": false,
      "polCargoUnit": "",
      "polAnchorUp": "",
      "polArrivalTime":"",
      "polBerthing":"",
      "polCommenced":"",
      "polCompleted":"",
      "polDocOnBoard":"", 
      "polDeparture":"",
      "polCastOut":"",
      "polLhv":"",
      "podCargoUnit":"",
      "podAnchorUp":"",
      "podLhv":"",
      "podCastOut":"",
      "podDeparture":"",
      "podArrivalTime":"",
      "podBerthing":"",
      "podCommenced":"",
      "podCompleted":"",
      "voyageFor": "loading",
      "voyageForOtherValue": "",  
      "origin": "",
      "destination": "",
      "speedBySystem": 0,
      "polDraughtSurvey": 0,
      "podDraughtSurvey": 0,

      "shelter": [{
        "shelterLocationOther": "", 
        "destinationOther": "", 
        "shelterFor": "", // loading, discharge, others
        "shelterLocation": "",
        "destination": "",
        "arrivalShelter": "",
        "dropAnchorShelter": "",
        "fullAwayShelter": "",
        "remarks": ""
      }]
    }

    this.unfinishedTrip?.map(val => {
      val.checked = false

      return val
    })
    this.crossTripList?.map(val => {
      val.checked = false

      return val
    })

  },
  onChangeFleet(e){
    this.fetchCrossTrip(e)
  },
  async fetchUnfinishTrip () {
    this.unfinishedTrip = []
    let params = {
      vehicleId: this.$route.query.vehicleId
    }

    const res = await this.getVoyageUnfinishedTrip(params)

    if (res.status == 'success') {
      res.data.map(val => {
        this.unfinishedTrip.push({
          ...val,
          checked: false
        })
      })
    }

    if(this.unfinishedTrip.length <= 0) this.formOperationalActivity.newTrip = true
  },
  async fetchCrossTrip (vehicleId) {
    this.crossTripList = []
    let params = {
      vehicleId
    }
    const res = await this.getVoyageUnfinishedTrip(params)

    if (res.status == 'success' && res?.data?.length > 0) {
      res?.data?.map(val => {
        this.crossTripList.push({
          ...val,
          checked: false
        })
      })
    }
  }, 
   async fetchFleets() {
    let params = {
      page: 1,
      perPage: 9999,
      showAll: true,
      active: true,
    }

    let res = await this.getFleets(params)

    console.log(res, " fleets ")
    if (res.data && res.data.length > 0) {
      this.optionsFleets = res.data.map((val) => {
        return {
          ...val,
          text: val.name,
          value: val.id,
        }
      })
    }
  },
  async getUnit () {
    this.optionsUnit = []
    const res = await this.fetchUnit()

    if (res.data.status == true) {
      if(res.data.data.length > 0){
        let data = res.data.data.filter(val => val.child.length <= 0)

        let tempArr = []
        data?.map(val => {
          tempArr.push({
            text:`${val.name}`,
            value:`${val.name}`
          })
        })
        this.optionsUnit = tempArr
      }
    }
  },

  async getCargoType () {
    this.optionsCargoType = []

    const res = await this.fetchCargoType()

    if (res.data.status == true) {
      let tempArr = []
      if(res.data.data.length > 0){
        res.data.data.map(val => {
          tempArr.push({
            text:`${val.name}`,
            value:`${val.name}`
          })
        })
        this.optionsCargoType = tempArr
      }
    
    } 
  },
  async fetchFleet () {
    this.loadingForm = true
    const res = await this.getFleetById(this.vehicleId)

    if (res.status == 'success') {
      this.fleet = res.data
      console.log('fleet', this.fleet)
    } else {
      this.fleet = {}
      this.$swal(
        `Oops!`,
        res.data,
        'error'
      )
    }
    this.loadingForm = false
  },
  async listFleet () {
    if (this.fleet.vehicleType.name == 'Tugboat') {
      let params = {
        vehicleTypeId: [1, 2],
        page: 1,
        active: true,
        showAll: true
      }
      const res = await this.getFleets(params)
      if (res.status == 'success') {
        this.fleetList = res.data
      } else {
        this.fleetList = {}
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    } else if (this.fleet.vehicleType.name == 'Pusher Tug') {
      let params = {
        vehicleTypeId: [21, 22],
        page: 1,
        active: true,
        showAll: true
      }
      const res = await this.getFleets(params)
      if (res.status == 'success') {
        this.fleetList = res.data
      } else {
        this.fleetList = {}
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    } else {
      let params = {
        page: 1,
        active: true,
        showAll: true
      }
      const res = await this.getFleets(params)
      if (res.status == 'success') {
        this.fleetList = res.data
      } else {
        this.fleetList = {}
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    }
  },
  async getProjectList () {
    let params = {
      active: true,
      search: '',
      page: 1,
      perPage: 250
    }
    const res = await this.getProjects(params)
    if (res.status == 'success') {
      if (res.data.data && res.data.data.length > 0) {
        res.data.data.forEach(val => {
          this.projectOptions.push({
            project: val.projectName,
            sub: val.subProject
          })
        })
      }
    } else {
      this.$swal(
        `Oops!`,
        res.data.data,
        'error'
      )
    }
  },
  async getReasonList () {
    let params = {
      search: '',
      vehicleTypeId: this.fleet.vehicleType.id,
      page: 1,
      perPage: 250
    }
    const res = await this.getReasons(params)
    if (res.status == 'success') {
      if (res.data.data && res.data.data.length > 0) {
        res.data.data.forEach(val => {
          if(val.active === true){
            this.reasonList.push({ value: val.id, text: val.name })
          }
        })
      }
    } else {
      this.$swal(
        `Oops!`,
        res.data.data,
        'error'
      )
    }
  },
  async getPortList () {
    let params = {
      active: true,
      search: '',
      page: 1,
      perPage: 250
    }
    const res = await this.getPorts(params)
    if (res.status == 'success') {
      if (res.data.data && res.data.data.length > 0) {
        res.data.data.forEach(val => {
          this.positionList.push({ value: val.id, text: val.portName })
        })
      }
    } else {
      this.$swal(
        `Oops!`,
        res.data.data,
        'error'
      )
    }
  },
  async getUserList () {
    let params = {
      active: true,
      page: 1,
      perPage: 1000
    }
    const res = await this.getUsers(params)
    console.log(res.data.data, "PIC LIST ")
    if (res.data.status == 'success' || res.data.status == true) {
      if (res.data.data && res.data.data.length > 0) {
        res.data.data.forEach(val => {
          this.picList.push({ value: val.id, text: val.userDetail.name })
        })
        // const findIdx = this.picList.findIndex(val => val.value === this.fleet.id)
        // if (findIdx !== -1) this.picSelected = this.fleet.id
      }
    } else {
      this.$swal(
        `Oops!`,
        res.message,
        'error'
      )
    }
  },
  async getLastActivity () {
    this.activityList = []
    const vehicleId = this.$route.query.vehicleId
    let params = {
      page: 1
    }
    const payload = {
      vehicleId: vehicleId,
      params
    }
    const res = await this.getVoyageLastActivity(payload)
    if (res.status == 'success') {
      if (res.data && res.data.length > 0) {
        this.activityList = res.data
      }
    } else {
      this.$swal(
        `Oops!`,
        res.data,
        'error'
      )
    }
  },

  async getActivityDetail (activityId, isEdit = false) {
    const vehicleId = this.$route.query.vehicleId
    const res = await this.getActivityById({ vehicleId, id: activityId })
    if (res.status === 'success') {
      if (isEdit) {
        this.isActivityId = res.data.id
        this.isEdit = true
      }
      this.initActivityForm(1, res.data)
      this.nextStep = true
      this.loadingCheck = false
    } else {
      this.$swal(
        `Oops!`,
        res.data,
        'error'
      )
    }
  },
  async checkVoyage () {
    let self = this
    const vehicleId = this.$route.query.vehicleId
    this.$v.formOperationalActivity.$touch()

    let unfinished = this.unfinishedTrip?.find(obj => obj.checked)

    if(!this.formOperationalActivity.newTrip && this.unfinishedTrip.length > 0){
      if(!unfinished){
        this.$swal(
          'Unfinished Trip Required.',
          'Choose atleast 1 Unfinished Trip.',
          'warning'
        )
        return
      }

      let dateUnfinished = new Date(unfinished.startDate)
      let startDate = new Date(this.formOperationalActivity.startDate)

      if(startDate < dateUnfinished){
        this.$swal(
          'Cant process.',
          'Start Date Cant be lower than Start Date Unfinished Trip.',
          'warning'
        )

        return
      }
    }

    let isCrossTrip = this.crossTripList?.find(obj => obj.checked)
    if(this.formOperationalActivity.crossTrip && !isCrossTrip && !this.formOperationalActivity.newTrip){
      this.$swal(
        'Required.',
        'Choose atleast 1 Cross Trip or uncheck the cross trip to process.',
        'warning'
      )
      return
    }

    if (this.formOperationalActivity.endDate <= this.formOperationalActivity.startDate) {
      this.$swal(
        'Error',
        'End Date harus lebih besar dari Start Date',
        'error'
      )
      return
    }

    if (!this.$v.formOperationalActivity.$invalid) {
      this.loadingCheck = true
      let param = {
        'startDate': moment(this.formOperationalActivity.startDate).format('DD-MM-YYYY HH:mm'),
        'endDate': moment(this.formOperationalActivity.endDate).format('DD-MM-YYYY HH:mm'),
        'vehicles': [
          {
            'vehicleId': Number(this.formOperationalActivity.vehicles[0].vehicleId),
            'vehicleNumber': 1,
            'breakdown': this.formOperationalActivity.vehicles[0].breakdown
          },
          {
            'vehicleId': Number(this.formOperationalActivity.vehicles[1].vehicleId),
            'vehicleNumber': 2,
            'breakdown': this.formOperationalActivity.vehicles[1].breakdown
          }
        ]
      }

      if (this.formOperationalActivity.voyageActivityType === 'UNTOWING') {
        delete param.vehicles
      } else if (this.formOperationalActivity.voyageActivityType === 'SINGLE_TOWING') {
        param.vehicles.splice(1, 1)
      }

      const vehicleId = this.$route.query.vehicleId
      let res = await self.checkVehicleVoyage({ vehicleId, data: param })
      if (res.status === 'success') {
        this.$swal('Available!', res.data.message, 'success')
        if (this.activityList[0]) {
          await this.getActivityDetail(this.activityList[0].id)
        }

        this.nextStep = true
      } else {
        if (res.data.message != null) {
          this.$swal('Error', res.data.message, 'error')
          this.loadingCheck = false
        } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
          this.loadingCheck = false
        }
        return
      }
    }

    if(unfinished){
      this.usingDataUnfinished(unfinished)
    }

    this.locked = true
  },
  usingDataUnfinished(data){
    this.formOperationalActivity.origin = data ? data.origin : ''
    this.formOperationalActivity.polDraughtSurvey = data ? data.polDraughtSurvey : 0
    this.formOperationalActivity.podDraughtSurvey = data ? data.podDraughtSurvey : 0
    this.formOperationalActivity.destination = data ? data.destination : ''
    this.formOperationalActivity.polCargoUnit = data ? data.polCargoUnit : ''
    this.formOperationalActivity.podCargoUnit = data ? data.podCargoUnit : ''
    this.formOperationalActivity.polDocOnBoard = data.polDocOnBoard ? moment(new Date(data.polDocOnBoard), 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm') : ''
    this.formOperationalActivity.polAnchorUp = data.polAnchorUp ? moment(new Date(data.polAnchorUp), 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm') : ''
    this.formOperationalActivity.polArrivalTime = data.polArrivalTime ? moment(new Date(data.polArrivalTime), 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm') : ''
    this.formOperationalActivity.polBerthing = data && data.polBerthing ? moment(new Date(data.polBerthing), 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm') : ''
    this.formOperationalActivity.polCommenced = data && data.polCommenced ? moment(new Date(data.polCommenced), 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm') : ''
    this.formOperationalActivity.polCompleted = data && data.polCompleted ? moment(new Date(data.polCompleted), 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm') : ''
    this.formOperationalActivity.polDeparture = data && data.polDeparture ? moment(new Date(data.polDeparture), 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm') : ''
    this.formOperationalActivity.polLhv = data && data.polLhv ? moment(new Date(data.polLhv), 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm') : ''
    this.formOperationalActivity.podAnchorUp = data && data.podAnchorUp ? moment(new Date(data.podAnchorUp), 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm') : ''
    this.formOperationalActivity.podLhv = data && data.podLhv ? moment(new Date(data.podLhv), 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm') : ''
    this.formOperationalActivity.podCastOut = data && data.podCastOut ? moment(new Date(data.podCastOut), 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm') : ''
    this.formOperationalActivity.podDeparture = data && data.podDeparture ? moment(new Date(data.podDeparture), 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm') : ''
    this.formOperationalActivity.podArrivalTime = data.podArrivalTime ? moment(new Date(data.podArrivalTime), 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm') : ''
    this.formOperationalActivity.podBerthing = data && data.podBerthing ? moment(new Date(data.podBerthing), 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm') : ''
    this.formOperationalActivity.podCommenced = data && data.podCommenced ? moment(new Date(data.podCommenced), 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm') : ''
    this.formOperationalActivity.podCompleted = data && data.podCompleted ? moment(new Date(data.podCompleted), 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm') : ''
    this.formOperationalActivity.docOnBoard = data && data.docOnBoard ? moment(new Date(data.docOnBoard), 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm') : ''
    this.formOperationalActivity.portLoading = data ? Number(data.portLoading.id) : ''
    this.formOperationalActivity.portDischarge = data ? Number(data.portDischarge.id) : ''
    this.formOperationalActivity.agentPol = data ? data.agentPol : ''
    this.formOperationalActivity.agentPod = data ? data.agentPod : ''

  },
  process () {
    let timerInterval
    this.$swal.fire({
      title: 'Check Available!',
      timer: 500,
      timerProgressBar: true,
      didOpen: () => {
        this.$swal.showLoading()
        timerInterval = setInterval(() => {
          const content = this.$swal.getContent()
          if (content) {
            const b = content.querySelector('b')
            if (b) {
              b.textContent = this.$swal.getTimerLeft()
            }
          }
        }, 100)
      },
      willClose: () => {
        clearInterval(timerInterval)
      }
    }).then((result) => {
      this.nextStep = true
    })
  },
  edit () {
    this.nextStep = false
    this.loadingCheck = false
    this.endDate = ''
  },
  handleSelectFleet (data, e, idx) {
    let findIdx = data.findIndex(val => val.id === e)
    this.formOperationalActivity.vehicles[idx].breakdown = false
    if (findIdx !== -1) {
      if (data[findIdx].vehicleType.id === this.fleet.vehicleType.id) {
        this.formOperationalActivity.vehicles[idx].breakdown = true
      }
    }
  },
  initActivityForm (type, data) {
    if (type) {
      var date = new Date()
      if (this.isEdit) {
        this.formOperationalActivity.startDate = data ? new Date(data.startDate.split(' ')[0].split('-')[2], (data.startDate.split(' ')[0].split('-')[1] - 1), data.startDate.split(' ')[0].split('-')[0], data.startDate.split(' ')[1].split(':')[0], data.startDate.split(' ')[1].split(':')[1]) : new Date(date.getFullYear(), date.getMonth(), date.getDate())
        this.formOperationalActivity.endDate = data ? new Date(data.endDate.split(' ')[0].split('-')[2], (data.endDate.split(' ')[0].split('-')[1] - 1), data.endDate.split(' ')[0].split('-')[0], data.endDate.split(' ')[1].split(':')[0], data.endDate.split(' ')[1].split(':')[1]) : new Date(date.getFullYear(), date.getMonth(), date.getDate())
        this.formOperationalActivity.newTrip = data?.newTrip ? true : false
      }
      // this.formOperationalActivity.vehicles = [
      //   {
      //     vehicleId: data && (data.voyageType === 'SINGLE_TOWING' || data.voyageType === 'DOUBLE_TOWING') ? data.vehicle[1].id : '',
      //     vehicleNumber: data && (data.voyageType === 'SINGLE_TOWING' || data.voyageType === 'DOUBLE_TOWING') ? data.vehicle[1].vehicleNumber : 1,
      //     breakdown: data && (data.voyageType === 'SINGLE_TOWING' || data.voyageType === 'DOUBLE_TOWING') ? data.vehicle[1].breakdown : false
      //   },
      //   {
      //     vehicleId: data && data.voyageType === 'DOUBLE_TOWING' ? data.vehicle[2].id : '',
      //     vehicleNumber: data && data.voyageType === 'DOUBLE_TOWING' ? data.vehicle[2].vehicleNumber : 2,
      //     breakdown: data && data.voyageType === 'DOUBLE_TOWING' ? data.vehicle[2].breakdown : false
      //   }
      // ]
      this.fleetEdit = data ? data.vehicle[0] : null
    }
    if(['BARGE', 'PUSHER BARGE'].includes(this.fleet.vehicleType.name)){
      this.formOperationalActivity.voyageActivityType = 'SINGLE_TOWING'
    }
    this.formOperationalActivity.subProjectId = data ? { id: data.subProject.id, name: data.subProject.name } : ''

    if (this.isEdit) {
      this.formOperationalActivity.reasonId = data ? `${data.reason.id}|${data.reason.name}` : ''
      this.formOperationalActivity.cargoType = ''
      this.formOperationalActivity.cargoLoading = data && data.reason.name === 'Loading' ? data.cargoLoading : ''
    }
    this.formOperationalActivity.cargoUnloading = data && data.reason.name === 'Unloading' ? data.cargoUnloading : ''
    this.formOperationalActivity.remarks = data ? data.remark : ''
    this.formOperationalActivity.currentPosition = ''
    this.formOperationalActivity.arrival = data && data.arrival ? new Date(data.arrival.split(' ')[0].split('-')[2], (data.arrival.split(' ')[0].split('-')[1] - 1), data.arrival.split(' ')[0].split('-')[0], data.arrival.split(' ')[1].split(':')[0], data.arrival.split(' ')[1].split(':')[1]) : ''
    this.formOperationalActivity.departure = data && data.departure ? new Date(data.departure.split(' ')[0].split('-')[2], (data.departure.split(' ')[0].split('-')[1] - 1), data.departure.split(' ')[0].split('-')[0], data.departure.split(' ')[1].split(':')[0], data.departure.split(' ')[1].split(':')[1]) : ''
    this.formOperationalActivity.portLoading = data ? Number(data.portLoading.id) : ''
    this.formOperationalActivity.portDischarge = data ? Number(data.portDischarge.id) : ''
    this.formOperationalActivity.agentPol = data ? data.agentPol : ''
    this.formOperationalActivity.agentPod = data ? data.agentPod : ''
    this.formOperationalActivity.lat = data ? (data.lat ? data.lat : '') : ''
    this.formOperationalActivity.lon = data ? (data.lon ? data.lon : '') : ''
    this.formOperationalActivity.speed = data ? data.speed : '' 
    
    this.formOperationalActivity.origin = data ? data.origin : ''
    this.formOperationalActivity.polDraughtSurvey = data ? data.polDraughtSurvey : 0
    this.formOperationalActivity.podDraughtSurvey = data ? data.podDraughtSurvey : 0
    this.formOperationalActivity.destination = data ? data.destination : ''
    this.formOperationalActivity.speedBySystem = data ? data.speedBySystem : 0
    this.formOperationalActivity.polCargoUnit = data ? data.polCargoUnit : ''
    this.formOperationalActivity.podCargoUnit = data ? data.podCargoUnit : ''
    this.formOperationalActivity.voyageFor = data ? data.voyageFor : ''
    this.formOperationalActivity.voyageForOtherValue = data ? data.voyageForOtherValue : ''
    this.formOperationalActivity.polDocOnBoard = data?.polDocOnBoard ?? ''
    this.formOperationalActivity.polAnchorUp = data?.polAnchorUp ?? ''
    this.formOperationalActivity.polArrivalTime = data?.polArrivalTime ?? ''
    this.formOperationalActivity.polBerthing = data?.polBerthing ?? ''
    this.formOperationalActivity.polCommenced = data?.polCommenced ?? ''
    this.formOperationalActivity.polCompleted = data?.polCompleted ?? ''
    this.formOperationalActivity.polDeparture = data?.polDeparture ?? ''
    this.formOperationalActivity.polLhv = data?.polLhv ?? ''
    this.formOperationalActivity.podAnchorUp = data?.podAnchorUp ?? ''
    this.formOperationalActivity.podLhv = data?.podLhv ?? ''
    this.formOperationalActivity.podCastOut = data?.podCastOut ?? ''
    this.formOperationalActivity.podDeparture = data?.podDeparture ?? ''
    this.formOperationalActivity.podArrivalTime = data?.podArrivalTime ?? ''
    this.formOperationalActivity.podBerthing = data?.podBerthing ?? ''
    this.formOperationalActivity.podCommenced = data?.podCommenced ?? ''
    this.formOperationalActivity.podCompleted = data?.podCompleted ?? ''

    this.formOperationalActivity.docOnBoard = data?.docOnBoard ?? ''
    this.formOperationalActivity.pic = []
    if (this.isEdit) {
      this.typeOfCargo = data ? (data.reason.name === 'Loading' && this.cargoOptions.findIndex(val => val.value === data.cargoType) !== -1 ? data.cargoType : 'Others') : ''
      this.typeOfCargoOther = data && data.reason.name === 'Loading' && this.typeOfCargo === 'Others' ? data.cargoType : ''
    }
    this.position = data ? (this.positionList.findIndex(val => val.text === data.currentPosition) !== -1 ? data.currentPosition : 'Others Position') : ''
    this.positionOther = data ? (this.position === 'Others Position' ? data.currentPosition : '') : ''
    this.picSelected = data?.pic ? data.pic.map(pic => pic.id) : []

    this.dmsLat.degrees = data ? data.dmsLatitude.split(' ')[0]?.substr(0, data.dmsLatitude.split(' ')[0].length - 1) : ''
    this.dmsLat.minutes = data ? data.dmsLatitude.split(' ')[1]?.substr(0, data.dmsLatitude.split(' ')[1].length - 1) : ''
    this.dmsLat.seconds = data ? data.dmsLatitude.split(' ')[2]?.substr(0, data.dmsLatitude.split(' ')[2].length - 1) : ''
    this.dmsLat.directions = data ? data.dmsLatitude.split(' ')[3] : ''

    this.dmsLon.degrees = data ? data.dmsLongitude.split(' ')[0]?.substr(0, data.dmsLongitude.split(' ')[0].length - 1) : ''
    this.dmsLon.minutes = data ? data.dmsLongitude.split(' ')[1]?.substr(0, data.dmsLongitude.split(' ')[1].length - 1) : ''
    this.dmsLon.seconds = data ? data.dmsLongitude.split(' ')[2]?.substr(0, data.dmsLongitude.split(' ')[2].length - 1) : ''
    this.dmsLon.directions = data ? data.dmsLongitude.split(' ')[3] : ''

    /* if (this.formOperationalActivity.arrival) {
      this.formOperationalActivity.arrival = moment(this.formOperationalActivity.arrival).format('YYYY-MM-DDTHH:mm')
    } */
    /* if (this.formOperationalActivity.docOnBoard) {
      this.formOperationalActivity.docOnBoard = moment(this.formOperationalActivity.docOnBoard).format('YYYY-MM-DDTHH:mm')
    } */
  },
  generateGPSLat () {
    if (this.dmsLat.degrees && this.dmsLat.minutes && this.dmsLat.seconds) {
      this.formOperationalActivity.lat = singleConvertDMSToDD(this.combineDNSLat)
    }
  },
  generateGPSLng () {
    if (this.dmsLon.degrees && this.dmsLon.minutes && this.dmsLon.seconds) {
      this.formOperationalActivity.lon = singleConvertDMSToDD(this.combineDNSLng)
    }
  },
  async saveActivity () {
    if(this.errorValidation) return this.$swal(
      'Error',
        this.errorTimeText,
      'warning'
    )

    let self = this
    let payload = {
      ...this.formOperationalActivity,
      "destination": this.formOperationalActivity.destinationOther ?? this.formOperationalActivity.destination,
      "origin": this.formOperationalActivity.originOther ?? this.formOperationalActivity.origin,
      'startDate': this.formOperationalActivity.startDate ? moment(this.formOperationalActivity.startDate).format('DD-MM-YYYY HH:mm') : null,
      'endDate': this.formOperationalActivity.endDate ? moment(this.formOperationalActivity.endDate).format('DD-MM-YYYY HH:mm') : null,
      'vehicles': [
        {
          'vehicleId': this.formOperationalActivity.vehicles[0].vehicleId,
          'vehicleNumber': 1,
          'breakdown': this.formOperationalActivity.vehicles[0].breakdown
        },
        {
          'vehicleId': this.formOperationalActivity.vehicles[1].vehicleId,
          'vehicleNumber': 2,
          'breakdown': this.formOperationalActivity.vehicles[1].breakdown
        }
      ],
      'voyageActivityType': this.formOperationalActivity.voyageActivityType,
      'polCargoUnit': this.formOperationalActivity.polCargoUnit,
      'podCargoUnit': this.formOperationalActivity.polCargoUnit,
      'subProjectId': this.formOperationalActivity.subProjectId.id,
      'reasonId': Number(this.formOperationalActivity.reasonId.split('|')[0]),
      'cargoType': this.formOperationalActivity.reasonId.split('|')[1] === 'Loading' ? (this.typeOfCargo === 'Others' ? this.typeOfCargoOther : this.typeOfCargo) : null,
      'cargoLoading': this.formOperationalActivity.reasonId.split('|')[1] === 'Loading' ? Number(this.formOperationalActivity.cargoLoading) : null,
      'cargoUnloading': this.formOperationalActivity.reasonId.split('|')[1] === 'Unloading' ? Number(this.formOperationalActivity.cargoUnloading) : null,
      'remarks': this.formOperationalActivity.remarks,
      'currentPosition': this.position === 'Others Position' ? this.positionOther : this.position,
      'arrival': this.formOperationalActivity.arrival ? moment(this.formOperationalActivity.arrival).format('DD-MM-YYYY HH:mm') : null,
      'departure': this.formOperationalActivity.departure ? moment(this.formOperationalActivity.departure).format('DD-MM-YYYY HH:mm') : null,
      'portLoading': this.formOperationalActivity.portLoading === 'Others' ? this.formOperationalActivity.portLoadingOther : this.formOperationalActivity.portLoading,
      'portDischarge': this.formOperationalActivity.portDischarge === 'Others' ? this.formOperationalActivity.portDischargeOther : this.formOperationalActivity.portDischarge,
      'agentPol': this.formOperationalActivity.agentPol,
      'agentPod': this.formOperationalActivity.agentPod,
      'lat': Number(this.formOperationalActivity.lat),
      'lon': Number(this.formOperationalActivity.lon),
      'speed': Number(this.formOperationalActivity.speed),
      'docOnBoard': this.formOperationalActivity.docOnBoard ? moment(this.formOperationalActivity.docOnBoard).format('DD-MM-YYYY HH:mm') : null,
      'pic': this.picSelected,
      'dmsLatitude': this.combineDNSLat,
      'dmsLongitude': this.combineDNSLng,
      shelter: this.formOperationalActivity.shelter?.length > 0 && this.useShelter ? this.formOperationalActivity?.shelter?.map(val => {
        delete val.shelterLocationOther
        delete val.destinationOther

        return val
      }) : []
    }

    delete payload.originOther
    delete payload.destinationOther

    // 'endOfTrip': this.formOperationalActivity.reasonId.split('|')[1] === 'Unloading' ? this.formOperationalActivity.endOfTrip : true,

    if (this.formOperationalActivity.voyageActivityType === 'UNTOWING') {
      delete payload.vehicles
    } else if (this.formOperationalActivity.voyageActivityType === 'SINGLE_TOWING') {
      payload.vehicles.splice(1, 1)
    }

    if(this.unfinishedTrip.length > 0){
      let unfinishedTripData = this.unfinishedTrip.find(obj => obj.checked)

      if(unfinishedTripData){
        payload.tripId = unfinishedTripData.id
      } else {
        delete payload?.tripId
      }
    } else {
      payload.newTrip = true
      delete payload?.tripId
    }

    if(this.formOperationalActivity.reasonId && this.formOperationalActivity.reasonId.split('|')[1]  !== 'Unloading'){
      payload.endOfTrip = false
    }

    const id = this.$route.query.vehicleId
    this.$swal.fire({
      title: 'Processing...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        swal.showLoading()
      }
    })

    if (!this.isEdit) {
      let res = await self.createVoyageActivity({ id, data: payload })
      if (res.status === 'success') {
        this.$swal('Add Activity Success!', res.data.message, 'success')
        setTimeout(() => {
          this.$router.go(-1)
        }, 2000)
        this.initActivityForm(1)
        this.nextStep = false
        this.loadingCheck = true
      } else {
        if (res.data.message != null) {
          this.$swal('Error', res.data.message, 'error')
          this.loadingCheck = false
        } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
          this.loadingCheck = false
        }
      }
    } else {
      let res = await self.updateActivity({ id: this.isActivityId, vehicleId: this.fleetEdit.id, data: payload })
      if (res.status == 'success') {
        this.$swal('Update Activity Success!', res.data.message, 'success')
        setTimeout(() => {
          this.$router.go(-1)
        }, 1000)
        this.cancelEdit()
        this.getLastActivity()
      } else {
        if (res.data.message != null) {
          this.$swal('Error', res.data.message, 'error')
        } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
        }
      }
    }
  },
  async onDeleteActivity (activityData) {
    let activity = activityData

    this.$swal({
      title: 'Delete Activity?',
      text: `Activity ${activity.activityName} will be deleted and cannot be recovered.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const vehicleId = this.$route.query.vehicleId
        let res = await this.deleteActivityById({ vehicleId, id: activity.id })
        if (res.status != 'success') {
          if (res.data.message != null) {
            this.$swal('Error', res.data.message, 'error')
          } else {
            this.$swal(
              'Error',
              'Something wrong with the server. Cannot get user data.',
              'error'
            )
          }
        } else {
          this.getLastActivity()
          this.$swal(
            `Activity deleted!`,
            `Activity ${activity.activityName} successfully deleted`,
            'success'
          )
        }
      }
    })
  },
  cancelEdit () {
    this.isEdit = false
    this.nextStep = false
    this.loadingCheck = false
    this.initActivityForm(1)
  }
}
}
</script>

<style scoped>
.custom-date-picker {
  /* Add your custom styles here */
  width: 100%;
}
.v-menu__content {
  position: absolute !important;
}
</style>
