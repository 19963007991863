var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('StepWizard', {
    attrs: {
      "current-step": "LOST_RATIO"
    }
  }), _c('b-row', [_vm.detailMi ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('form', {
    attrs: {
      "action": ""
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSave();
      }
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('b', [_vm._v("Loss Ratio Input")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('h4', {
          staticStyle: {
            "margin-right": "10px"
          }
        }, [_vm._v(" From Forecast List ")]), _c('div', {
          staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
        }, [_c('div', {
          staticClass: "custom-switch-inner"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.forecastList.checked,
            expression: "forecastList.checked"
          }],
          staticClass: "custom-control-input",
          class: 'bg-' + _vm.forecastList.class,
          attrs: {
            "type": "checkbox",
            "id": 'forecastList'
          },
          domProps: {
            "checked": Array.isArray(_vm.forecastList.checked) ? _vm._i(_vm.forecastList.checked, null) > -1 : _vm.forecastList.checked
          },
          on: {
            "change": function change($event) {
              var $$a = _vm.forecastList.checked,
                $$el = $event.target,
                $$c = $$el.checked ? true : false;
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v);
                if ($$el.checked) {
                  $$i < 0 && _vm.$set(_vm.forecastList, "checked", $$a.concat([$$v]));
                } else {
                  $$i > -1 && _vm.$set(_vm.forecastList, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                }
              } else {
                _vm.$set(_vm.forecastList, "checked", $$c);
              }
            }
          }
        }), _c('label', {
          staticClass: "custom-control-label",
          attrs: {
            "for": 'forecastList',
            "data-on-label": _vm.forecastList.activeLabel,
            "data-off-label": _vm.forecastList.inactiveLabel
          }
        })])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return _vm._l(_vm.form.sources, function (row, index) {
          return _c('b-row', {
            key: index
          }, [_c('b-col', {
            attrs: {
              "md": "12"
            }
          }, [_c('b-alert', {
            attrs: {
              "show": true,
              "variant": "secondary"
            }
          }, [_c('div', {
            staticClass: "iq-alert-text"
          }, [_c('h4', [_vm._v(_vm._s(row.type))])])])], 1), _c('b-col', {
            attrs: {
              "md": "5"
            }
          }, [_c('b-form-group', {
            staticClass: "mt-5",
            attrs: {
              "label": "Current Premium *"
            }
          }, [_c('b-input-group', {
            attrs: {
              "size": "md",
              "prepend": "Rp"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "number",
              "step": ".001",
              "required": ""
            },
            on: {
              "change": function change($event) {
                return _vm.handleCalculateLossRatio(index);
              }
            },
            model: {
              value: row.premium,
              callback: function callback($$v) {
                _vm.$set(row, "premium", $$v);
              },
              expression: "row.premium"
            }
          })], 1), _c('b-form-text', {
            staticClass: "text-muted"
          }, [_vm._v(_vm._s(_vm.formatCurrency(row.premium)))])], 1)], 1), _c('b-col', {
            attrs: {
              "md": "5"
            }
          }, [_c('b-form-group', {
            staticClass: "mt-5",
            attrs: {
              "label": "Claim Loss *"
            }
          }, [_c('b-input-group', {
            attrs: {
              "size": "md",
              "prepend": "Rp"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "number",
              "step": ".001",
              "required": ""
            },
            on: {
              "change": function change($event) {
                return _vm.handleCalculateLossRatio(index);
              }
            },
            model: {
              value: row.loss,
              callback: function callback($$v) {
                _vm.$set(row, "loss", $$v);
              },
              expression: "row.loss"
            }
          })], 1), _c('b-form-text', {
            staticClass: "text-muted"
          }, [_vm._v(_vm._s(_vm.formatCurrency(row.loss)))])], 1)], 1), _c('b-col', {
            attrs: {
              "md": "2"
            }
          }, [_c('b-form-group', {
            staticClass: "mt-5",
            attrs: {
              "label": "Loss Ratio *"
            }
          }, [_c('b-input-group', {
            attrs: {
              "size": "md",
              "append": "%"
            }
          }, [_c('b-form-input', {
            attrs: {
              "type": "number",
              "step": ".01",
              "required": ""
            },
            model: {
              value: row.ratio,
              callback: function callback($$v) {
                _vm.$set(row, "ratio", $$v);
              },
              expression: "row.ratio"
            }
          })], 1)], 1)], 1)], 1);
        });
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('b-row', {
          staticClass: "mt-3 mb-3"
        }, [_c('b-col', {
          staticClass: "pull-right text-center",
          attrs: {
            "cols": "12",
            "md": "12"
          }
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "disabled": _vm.form._saving,
            "variant": "primary"
          }
        }, [_vm._v("Submit")]), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "variant": "none"
          },
          on: {
            "click": function click($event) {
              return _vm.$router.go(-1);
            }
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }], null, false, 4204980227)
  })], 1)]) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }