<template>
  <div>
    <b-row>
      <b-col sm="8">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"><strong>List Master Project Terms</strong></h4>
          </template>
          <template #headerAction>
            <b-form
              class="row w-100"
              @submit.prevent="getBusdevMProjTerms()"
            >
              <b-col cols="12" md="10" class="p-0">
                <b-form-input
                  id="name"
                  type="text"
                  placeholder="Search..."
                  v-model="busdevMProjTerms.params.name"
                ></b-form-input>
              </b-col>
              <b-col cols="12" md="2" class="p-0">
                <b-button type="submit" variant="primary" class="ml-2"
                  ><i class="fa fa-search"></i
                ></b-button>
              </b-col>
            </b-form>
          </template>
          <template v-slot:body>
            <div v-if="busdevMProjTerms._loading" class="text-center my-5">
              <b-spinner type="grow" label="Spinning"></b-spinner>
            </div>
            <template v-else>
              <div
                class="table-responsive"
                v-if="busdevMProjTerms.data && busdevMProjTerms.data.length > 0"
              >
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th width="10%">No</th>
                      <th width="25%">Name</th>
                      <th width="10%">Status</th>
                      <th width="10%">Remarks</th>
                      <th width="15%" class="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(p, i) in busdevMProjTerms.data" :key="`p-${i}`">
                      <td>{{ i + 1 }}</td>
                      <td>{{ p.name }}</td>
                      <td>{{ p.status ? 'Active' : 'Inactive' }}</td>
                      <td>{{ p.remarks ?? '-' }}</td>
                      <td>
                        <div class="d-flex justify-content-center">
                          <b-button
                            variant="success"
                            size="sm"
                            class="mr-2"
                            @click="editFormBusdemMProjTerms(p)"
                            ><i class="fa fa-edit"></i
                          ></b-button>
                          <b-button
                            variant="danger"
                            size="sm"
                            @click="onDeleteBusdevMProjTerms(p)"
                          >
                            <i
                              v-if="!delete_contract_type"
                              class="fa fa-trash"
                            ></i>
                            <template v-else>
                              <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span
                              >Deleting...
                            </template>
                          </b-button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                v-if="busdevMProjTerms.data && busdevMProjTerms.data.length > 0"
              >
                <b-pagination
                  v-model="busdevMProjTerms.meta.page"
                  :total-rows="busdevMProjTerms.meta.totalItem"
                  @change="getBusdevMProjTerms"
                  :per-page="busdevMProjTerms.meta.perPage"
                  first-number
                  align="center"
                ></b-pagination>
              </div>
              <p v-else class="text-center my-4"
                >No Project Terms data found.</p
              >
            </template>
          </template>
        </iq-card>
      </b-col>
      <b-col sm="4">
        <iq-card
          :class="{
            'border border-danger shadow-lg': busdev_m_Proj_terms_edit,
          }"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title"
              >{{ busdev_m_Proj_terms_edit ? 'Edit' : 'Add' }} Project Terms</h4
            >
          </template>
          <template v-slot:body>
            <b-form @submit.prevent="onSaveBusdevMProjTerms()">
              <b-form-group label="Name" label-for="name">
                <b-form-input
                  v-model="formProjTerms.name"
                  id="name"
                  type="text"
                  placeholder="Type Name"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Status" label-for="status">
                <b-form-checkbox
                  v-model="formProjTerms.status"
                  name="check-button"
                  switch
                  inline
                  > Inactive / Active
                </b-form-checkbox>
              </b-form-group>
              <b-form-group label="Remarks" label-for="remarks">
                <b-form-textarea
                  required
                  v-model="formProjTerms.remarks"
                  id="remarks"
                  type="textarea"
                  placeholder="Type Remarks"
                ></b-form-textarea>
              </b-form-group>
              <hr />
              <b-form-group class="text-right">
                <b-button type="submit" variant="primary">
                    <span v-if="!busdev_m_Proj_terms_edit">Add</span>
                    <span v-else>Save Edit</span>
                  </b-button>
                <b-button
                  type="button"
                  variant="danger"
                  class="iq-bg-danger ml-3"
                  @click="resetformContractType()"
                  >Cancel</b-button
                >
              </b-form-group>
            </b-form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { busdevActions, portsActions } from '@src/Utils/helper'
import _ from 'lodash'
export default {
  name: 'MaterTripDistance',
  data() {
    return {
      busdevMProjTerms: {
        data: [],
        _loading: true,
        detail: null,
        params: {
          name: '',
          // active: true,
          perPage: 30,
          page: 1,
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0,
        },
      },

      loading_contract_type: false,
      saving_busdev_m_proj_terms: false,
      delete_contract_type: false,

      formProjTerms: {
        name:'',
        status:false,
        remarks:''
      },

      portOptions: [],

      busdev_m_Proj_terms_edit: null,
      contract_type_id: null,
    }
  },
  created() {
    this.getBusdevMProjTerms = _.debounce(this.getBusdevMProjTerms, 200)
  },
  methods: {
    ...busdevActions,
    ...portsActions,
    async getBusdevMProjTerms(page) {
      this.busdevMProjTerms._loading = true
      this.busdevMProjTerms.data = []
      let params = {
        ...this.busdevMProjTerms.params,
        page: page || 1,
      }
      if (!params.name) delete params.name

      const res = await this.fetcBusdevMProjTerms(params)
      if (res.data.status == true) {
        this.busdevMProjTerms.data = res.data.data.data
      } else {
        this.busdevMProjTerms._loading = false
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
      this.busdevMProjTerms._loading = false
    },

    async onSaveBusdevMProjTerms() {
      this.saving_busdev_m_proj_terms = true
      let payload = {
        ...this.formProjTerms,
        is_spal: this.formProjTerms.is_spal ? 1 : 0,
        status: this.formProjTerms.status ? 1 : 0,
      }

      let res = null
      if (this.busdev_m_Proj_terms_edit && this.contract_type_id) {
        res = await this.updateBusdevMProjTerms({
          data: payload,
          id: this.contract_type_id,
        })
      } else if (!this.busdev_m_Proj_terms_edit) {
        res = await this.createBusdevMProjTerms({ data: payload })
      }

      if (res.data.status === true) {
        this.$swal(
          `${this.busdev_m_Proj_terms_edit ? 'Edit' : 'Add'} Busdev Mater Project Terms Success!`,
          res.data.message,
          'success'
        )
        this.resetformContractType()
        this.getBusdevMProjTerms()
        this.saving_busdev_m_proj_terms = false
      } else {
        this.$swal(`Oops!`, res.data, 'error')
        this.saving_busdev_m_proj_terms = false
      }
      this.saving_busdev_m_proj_terms = false
    },

    resetformContractType() {
      this.formProjTerms = {
        name: '',
        alias: '',
        is_spal: false,
        status: true, // 1 = active, 0 = inactive
      }
      this.busdev_m_Proj_terms_edit = false
      this.contract_type_id = null
    },

    editFormBusdemMProjTerms(data) {
      this.formProjTerms = {
        name: data?.name,
        remarks: data?.remarks,
        status: data?.status ? true : false, // 1 = active, 0 = inactive
      }
      this.busdev_m_Proj_terms_edit = true
      this.contract_type_id = data.id
    },

    async onDeleteBusdevMProjTerms(data) {
      this.$swal({
        title: 'Delete Busdev Mater Project Terms?',
        text: `Busdev Mater Project Terms ${data.name} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.saving_busdev_m_proj_terms = true
          let res = await this.deleteBusdevMProjTerms({ id: data.id })
          if (res.status != 'success') {
            if (res.data.message != null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
            this.saving_busdev_m_proj_terms = false
          } else {
            this.$swal(
              `Busdev Mater Project Terms deleted!`,
              `Busdev Mater Project Terms ${data.name} successfully deleted`,
              'success'
            )
            await this.getBusdevMProjTerms()
            this.saving_busdev_m_proj_terms = false
          }
        }
      })
    },

    async fetchPorts() {
      try {
        const res = await this.getPorts({ page: 1 })
        this.portOptions = res.data.data
        this.portOptions.forEach((el, i) => {
          el.text = el.portName
          el.value = el.id
        })
      } catch (error) {
        console.log(error)
      }
    },
  },
  async mounted() {
    await this.getBusdevMProjTerms()
    await this.fetchPorts()
  },
}
</script>

<style scoped></style>
