<template>
  <b-row>
    <b-col md="12">
      <iq-card>
        <template v-slot:headerTitle>
            <h5 class="card-title text-primary"><strong>REPORT</strong></h5>
        </template>

        <template v-slot:body>
          <!-- FILTER START -->
          <b-row>
            <b-col md="3">
              <b-form-group label="Period" label-for="Period">
                <date-picker v-model="dateRange" type="date" range placeholder="Select date range"></date-picker>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Company" label-for="cargo">
                <b-form-select plain>
                  <b-form-select-option>All</b-form-select-option>
                  <b-form-select-option>Own Ship</b-form-select-option>
                  <b-form-select-option>Third Party</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Cargo Type" label-for="cargo">
                <b-form-select plain>
                  <b-form-select-option>All</b-form-select-option>
                  <b-form-select-option>COAL</b-form-select-option>
                  <b-form-select-option>SPLIT STONE</b-form-select-option>
                  <b-form-select-option>NICKEL</b-form-select-option>
                  <b-form-select-option>CPO</b-form-select-option>
                  <b-form-select-option>ASD</b-form-select-option>
                  <b-form-select-option>OTHERS</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="1">
              <b-form-group style label="Search" label-for="button_search">
                <b-button variant="primary" class="mb-3 mr-1"><i class="fa fa-search"></i></b-button>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- FILTER END -->

          <b-row>
            <b-col md="12">
              <h4><strong class="text-primary">Cargo By Project</strong></h4>
              <div class="w-100 d-flex border my-2"></div>
            </b-col>
            <b-col md="5">
              <ApexChart
                  :element="`bar-chart-1-`"
                  :chartOption="patientChart1"
                />
            </b-col>
            <b-col md="7">
                <AmChart
                  :element="`pie-chart-1-`"
                  type="pie"
                  :option="bodyData"
                />
            </b-col>

            <b-col md="12">
              <div class="w-100 d-flex border my-2"></div>
            </b-col>

            <b-col md="6" >
              <h4><strong class="text-primary">Revenue By PT</strong></h4>
              <ApexChart
                  :element="`bar-chart-2-`"
                  :chartOption="patientChart2"
                />
            </b-col>
            <b-col md="6" >
              <h4><strong class="text-primary">Cargo By PT</strong></h4>
              <ApexChart
                  :element="`bar-chart-3-`"
                  :chartOption="patientChart3"
                />
            </b-col>
          </b-row>
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>

<script>
import { xray } from '../../../config/pluginInit'
import _ from 'lodash'
import moment from 'moment'
import { projectsActions, companiesActions, fleetsActions } from '@src/Utils/helper'
import { numberFormat } from '@src/plugins/helpers'

export default {
  name: 'TabReport',
  data() {
    return {
      dateRange:[],

      patientChart3: {
        series: [{
          name: 'Cargo By PT',
          data: [44, 55, 41, 67]
        }],
        annotations: {
          points: [{
            x: 'Bananas',
            seriesIndex: 0,
            label: {
              borderColor: '#775DD0',
              offsetY: 0,
              style: {
                color: '#fff',
                background: '#775DD0'
              },
              text: 'Bananas are good'
            }
          }]
        },
        chart: {
          height: 350,
          type: 'bar'
        },
        colors: ['#089bab'],
        plotOptions: {
          bar: {
            columnWidth: '50%',
            endingShape: 'rounded'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2
        },

        grid: {
          row: {
            colors: ['#fff', '#f2f2f2']
          }
        },
        xaxis: {
          labels: {
            rotate: -45
          },
          categories: ['PT. ETI', 'PT. TCP', 'PT. SML', 'PT. KSI'],
          tickPlacement: 'on'
        },
        yaxis: {
          title: {
            text: 'Cargo By PT'
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'horizontal',
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [50, 0, 100]
          }
        }
      },
      patientChart2: {
        series: [{
          name: 'Revenue by PT',
          data: [44, 55, 41, 67]
        }],
        annotations: {
          points: [{
            x: 'Bananas',
            seriesIndex: 0,
            label: {
              borderColor: '#775DD0',
              offsetY: 0,
              style: {
                color: '#fff',
                background: '#775DD0'
              },
              text: 'Bananas are good'
            }
          }]
        },
        chart: {
          height: 350,
          type: 'bar'
        },
        colors: ['#089bab'],
        plotOptions: {
          bar: {
            columnWidth: '50%',
            endingShape: 'rounded'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2
        },

        grid: {
          row: {
            colors: ['#fff', '#f2f2f2']
          }
        },
        xaxis: {
          labels: {
            rotate: -45
          },
          categories: ['PT. ETI', 'PT. TCP', 'PT. SML', 'PT. KSI'],
          tickPlacement: 'on'
        },
        yaxis: {
          title: {
            text: 'Revenue by PT'
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'horizontal',
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [50, 0, 100]
          }
        }
      },
      patientChart1: {
        series: [{
          name: 'Cargo by project',
          data: [44, 55, 41, 67, 22, 43]
        }],
        annotations: {
          points: [{
            x: 'Bananas',
            seriesIndex: 0,
            label: {
              borderColor: '#775DD0',
              offsetY: 0,
              style: {
                color: '#fff',
                background: '#775DD0'
              },
              text: 'Bananas are good'
            }
          }]
        },
        chart: {
          height: 350,
          type: 'bar'
        },
        colors: ['#089bab'],
        plotOptions: {
          bar: {
            columnWidth: '50%',
            endingShape: 'rounded'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2
        },

        grid: {
          row: {
            colors: ['#fff', '#f2f2f2']
          }
        },
        xaxis: {
          labels: {
            rotate: -45
          },
          categories: ['KPC-PLN NON JMB', 'KPC-JMB', 'AI-PLN-REG', 'AI-PLN PJB', 'AI-ANTAM', 'OTHERS'],
          tickPlacement: 'on'
        },
        yaxis: {
          title: {
            text: 'Cargo by project'
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'horizontal',
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [50, 0, 100]
          }
        }
      },
      bodyData: {
        colors: ['#0084ff', '#00ca00', '#e64141'],
        value: ['litres'],
        category: ['country'],
        data: [
          {
            country: 'Own Ship',
            litres: 501.9,
            fill: 'red'
          },
          {
            country: 'Third Party',
            litres: 165.8
          },
        ]
      }
    }
  },
  components:{
  },
  async mounted() {
    xray.index()
  },
  methods: {
  }
}
</script>