var render = function render(){
  var _vm$detailData$source, _vm$detailData, _vm$detailData$source2, _vm$detailData2, _vm$form$reason, _vm$form;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('form', {
    attrs: {
      "action": "#"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSave.apply(null, arguments);
      }
    }
  }, [_vm.edit_loading ? _c('b-row', {
    staticClass: "p-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "text-center my-5"
  }, [_c('b-spinner', {
    attrs: {
      "type": "grow",
      "label": "Spinning"
    }
  })], 1)])], 1) : _vm._e(), !_vm.edit_loading ? _c('b-row', {
    staticClass: "p-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [['ADD', 'EDIT'].includes(_vm.action) ? _c('b-form-group', {
    attrs: {
      "label": "PIC / USER",
      "label-for": "pic"
    }
  }, [_c('v-select', {
    attrs: {
      "placeholder": "Select pic/user",
      "label": "text",
      "options": _vm.optionsPIC,
      "reduce": function reduce(optionsPIC) {
        return optionsPIC.value;
      }
    },
    on: {
      "input": _vm.onChangePIC
    },
    model: {
      value: _vm.form.pic,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "pic", $$v);
      },
      expression: "form.pic"
    }
  })], 1) : _c('strong', [_vm._v("PIC Name: "), _c('br'), _vm._v(_vm._s((_vm$detailData$source = (_vm$detailData = _vm.detailData) === null || _vm$detailData === void 0 ? void 0 : (_vm$detailData$source2 = _vm$detailData.sourcePic) === null || _vm$detailData$source2 === void 0 ? void 0 : _vm$detailData$source2.name) !== null && _vm$detailData$source !== void 0 ? _vm$detailData$source : 'N/A'))])], 1), _vm.form.pic ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Position"
    }
  }, [_vm.form.pic ? _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.positionPic))]) : _c('i', {
    staticClass: "text-muted"
  }, [_vm._v("Choose PIC first.")])])], 1), _vm.form.pic ? _c('b-col', {
    attrs: {
      "lg": "9"
    }
  }, [_c('span', {
    staticClass: "text-primary font-weight-bold"
  }, [_vm._v("Current Fleet")]), _vm.form.pic ? _c('div', {
    staticClass: "overflow-auto border p-2",
    staticStyle: {
      "height": "250px"
    }
  }, [_vm.vehiclePic.length > 0 ? _c('b-row', _vm._l(_vm.vehiclePic, function (state, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "md": "3",
        "sm": "4",
        "xs": "6"
      }
    }, [_c('div', {
      staticClass: "d-flex flex-column align-items-center justify-content-center border mb-2 p-1"
    }, [_c('img', {
      staticClass: "img-fluid",
      attrs: {
        "src": require('@src/assets/images/fleet/PusherBarge.png'),
        "alt": "fleet-img"
      }
    }), _c('small', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.name))])])]);
  }), 1) : _c('i', {
    staticClass: "text-muted"
  }, [_vm._v("PIC doesnt have fleets.")])], 1) : _c('i', {
    staticClass: "text-muted"
  }, [_c('br'), _vm._v(" Choose PIC first.")])]) : _vm._e()], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "border d-flex my-2 w-100"
  })])], 1), _c('b-row', {
    staticClass: "my-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Transfer Type"
    }
  }, [_c('b-form-radio', {
    staticClass: "custom-radio-color-checked",
    attrs: {
      "disabled": !['ADD', 'EDIT'].includes(_vm.action),
      "id": "radio-1",
      "value": "new",
      "inline": "",
      "color": "info",
      "name": "new"
    },
    model: {
      value: _vm.form.transfer_type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "transfer_type", $$v);
      },
      expression: "form.transfer_type"
    }
  }, [_vm._v("New Placement")]), _c('b-form-radio', {
    staticClass: "custom-radio-color-checked",
    attrs: {
      "disabled": !['ADD', 'EDIT'].includes(_vm.action) || _vm.vehiclePic.length <= 0,
      "inline": "",
      "id": "radio-2",
      "value": "rotate",
      "color": "info",
      "name": "rotate"
    },
    model: {
      value: _vm.form.transfer_type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "transfer_type", $$v);
      },
      expression: "form.transfer_type"
    }
  }, [_vm._v("Switch Fleet")]), _c('b-form-radio', {
    staticClass: "custom-radio-color-checked",
    attrs: {
      "disabled": !['ADD', 'EDIT'].includes(_vm.action) || _vm.vehiclePic.length <= 0,
      "id": "radio-3",
      "inline": "",
      "value": "reduce",
      "color": "info",
      "name": "reduce"
    },
    model: {
      value: _vm.form.transfer_type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "transfer_type", $$v);
      },
      expression: "form.transfer_type"
    }
  }, [_vm._v("Less Fleet")])], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [['rotate'].includes(_vm.form.transfer_type) ? _c('span', [_vm._v("Switch with other PIC")]) : _vm._e(), ['rotate'].includes(_vm.form.transfer_type) && ['ADD', 'EDIT'].includes(_vm.action) ? _c('v-select', {
    attrs: {
      "placeholder": "Select pic",
      "label": "text",
      "options": _vm.optionsOtherPIC,
      "reduce": function reduce(optionsOtherPIC) {
        return optionsOtherPIC.value;
      }
    },
    on: {
      "input": _vm.onChangeOtherPIC
    },
    model: {
      value: _vm.form.destinationPic,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "destinationPic", $$v);
      },
      expression: "form.destinationPic"
    }
  }) : ['DETAIL'].includes(_vm.action) && ['rotate'].includes(_vm.form.transfer_type) ? _c('span', [_vm._v("Ananda Pratama")]) : _vm._e(), ['rotate'].includes(_vm.form.transfer_type) ? _c('div', {
    staticClass: "border d-flex my-2 w-100"
  }) : _vm._e(), ['rotate'].includes(_vm.form.transfer_type) ? _c('b-row', [_c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('span', {
    staticClass: "text-warning font-weight-bold"
  }, [_vm._v("Current Fleet")])]), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "sm": "6"
    }
  }, [_c('span', {
    staticClass: "text-primary font-weight-bold"
  }, [_vm._v("Swap Fleet")])]), ['ADD', 'EDIT'].includes(_vm.action) ? _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, _vm._l(_vm.form.switchVehicles, function (state, index) {
    return _c('div', {
      key: index,
      staticClass: "d-flex w-100 align-items-center justify-content-between mb-2"
    }, [_c('div', {
      staticClass: "d-flex w-100 align-items-center"
    }, [_c('span', {
      staticClass: "mr-2"
    }, [_vm._v("#" + _vm._s(index + 1))]), _c('v-select', {
      staticClass: "w-100",
      attrs: {
        "label": "name",
        "options": _vm.vehiclePic,
        "reduce": function reduce(vehiclePic) {
          return vehiclePic.id;
        },
        "placeholder": "Select fleet...",
        "required": ""
      },
      model: {
        value: state.sourceVehicleId,
        callback: function callback($$v) {
          _vm.$set(state, "sourceVehicleId", $$v);
        },
        expression: "state.sourceVehicleId"
      }
    })], 1), _c('i', {
      staticClass: "fa fa-arrow-right mx-2"
    }), _vm.form.destinationPic && _vm.vehicleOtherPic.length > 0 ? _c('div', {
      staticClass: "d-flex w-100 align-items-center"
    }, [_c('v-select', {
      staticClass: "w-100",
      attrs: {
        "label": "name",
        "options": _vm.vehicleOtherPic,
        "reduce": function reduce(vehicleOtherPic) {
          return vehicleOtherPic.id;
        },
        "placeholder": "Select fleet...",
        "required": ""
      },
      model: {
        value: state.destinationVehicleId,
        callback: function callback($$v) {
          _vm.$set(state, "destinationVehicleId", $$v);
        },
        expression: "state.destinationVehicleId"
      }
    }), index !== 0 ? _c('i', {
      staticClass: "fa fa-trash text-danger ml-2",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.form.switchVehicles.splice(index, 1);
        }
      }
    }) : _vm._e()], 1) : _vm.vehicleOtherPic.length <= 0 && _vm.form.destinationPic ? _c('i', {
      staticClass: "text-truncate w-100"
    }, [_vm._v("This PIC doesnt have Fleets.")]) : _c('i', {
      staticClass: "text-truncate w-100"
    }, [_vm._v("Choose Other PIC.")])]);
  }), 0) : _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between mb-2"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v("#1")]), _c('b-badge', {
    staticClass: "border border-dark text-dark text-uppercase mr-1 mb-1",
    attrs: {
      "variant": "none"
    }
  }, [_vm._v("TB. TAVIA 001")])], 1), _c('i', {
    staticClass: "fa fa-arrow-right mx-2"
  }), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-badge', {
    staticClass: "border border-primary text-primary text-uppercase mr-1 mb-1",
    attrs: {
      "variant": "none"
    }
  }, [_vm._v("TB. TAVIA 002")])], 1)]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between mb-2"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v("#2")]), _c('b-badge', {
    staticClass: "border border-dark text-dark text-uppercase mr-1 mb-1",
    attrs: {
      "variant": "none"
    }
  }, [_vm._v("TB. TAVIA 002")])], 1), _c('i', {
    staticClass: "fa fa-arrow-right mx-2"
  }), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-badge', {
    staticClass: "border border-primary text-primary text-uppercase mr-1 mb-1",
    attrs: {
      "variant": "none"
    }
  }, [_vm._v("TB. TAVIA 003")])], 1)]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between mb-2"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v("#3")]), _c('b-badge', {
    staticClass: "border border-dark text-dark text-uppercase mr-1 mb-1",
    attrs: {
      "variant": "none"
    }
  }, [_vm._v("TB. TAVIA 003")])], 1), _c('i', {
    staticClass: "fa fa-arrow-right mx-2"
  }), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-badge', {
    staticClass: "border border-primary text-primary text-uppercase mr-1 mb-1",
    attrs: {
      "variant": "none"
    }
  }, [_vm._v("TB. TAVIA 004")])], 1)])]), ['ADD', 'EDIT'].includes(_vm.action) ? _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "sm": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "success"
    },
    on: {
      "click": function click($event) {
        return _vm.form.switchVehicles.push({
          sourceVehicleId: null,
          destinationVehicleId: null
        });
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" ADD MORE DATA")])], 1) : _vm._e()], 1) : _c('b-row', [['ADD', 'EDIT'].includes(_vm.action) ? _c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "lg": "12"
    }
  }, [_c('b-form-input', {
    staticClass: "w-50 mb-2",
    attrs: {
      "size": "sm",
      "placeholder": "search fleet..."
    }
  }), _c('div', {
    staticClass: "overflow-auto border p-2",
    staticStyle: {
      "height": "400px"
    }
  }, [['new'].includes(_vm.form.transfer_type) ? _c('b-row', _vm._l(_vm.newFleets, function (state, index) {
    return _c('b-col', {
      attrs: {
        "lg": "2",
        "md": "3",
        "sm": "4"
      }
    }, [_c('div', {
      staticClass: "d-flex flex-column align-items-center justify-content-center border mb-1 p-1",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.onCheckedNewFleets(index);
        }
      }
    }, [_c('b-form-checkbox', {
      staticClass: "align-self-start",
      attrs: {
        "type": "checkbox",
        "checked": state.checked
      }
    }), _c('img', {
      staticClass: "img-fluid",
      attrs: {
        "src": require('@src/assets/images/fleet/PusherBarge.png'),
        "alt": "fleet-img"
      }
    }), _c('small', [_vm._v(_vm._s(state.name))])], 1)]);
  }), 1) : _vm._e(), ['reduce'].includes(_vm.form.transfer_type) ? _c('b-row', _vm._l(_vm.reduceFleets, function (state, index) {
    return _c('b-col', {
      attrs: {
        "lg": "2",
        "md": "3",
        "sm": "4"
      }
    }, [_c('div', {
      staticClass: "d-flex flex-column align-items-center justify-content-center border mb-1 p-1",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.onCheckedReduceFleets(index);
        }
      }
    }, [_c('b-form-checkbox', {
      staticClass: "align-self-start",
      attrs: {
        "type": "checkbox",
        "checked": state.checked
      }
    }), _c('img', {
      staticClass: "img-fluid",
      attrs: {
        "src": require('@src/assets/images/fleet/PusherBarge.png'),
        "alt": "fleet-img"
      }
    }), _c('small', [_vm._v(_vm._s(state.name))])], 1)]);
  }), 1) : _vm._e()], 1)], 1) : _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex w-100 align-items-center flex-wrap"
  }, _vm._l((_vm$detailData2 = _vm.detailData) === null || _vm$detailData2 === void 0 ? void 0 : _vm$detailData2.vehicles, function (fleet, fleetIndex) {
    return _c('b-badge', {
      key: fleetIndex,
      staticClass: "border border-dark text-dark text-uppercase mr-1 mb-1",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v(_vm._s(fleet === null || fleet === void 0 ? void 0 : fleet.name))]);
  }), 1)])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "border d-flex my-2 w-100"
  })])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Effective Date"
    }
  }, [['ADD', 'EDIT'].includes(_vm.action) ? _c('b-form-input', {
    attrs: {
      "type": "date",
      "required": ""
    },
    model: {
      value: _vm.form.effectiveDate,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "effectiveDate", $$v);
      },
      expression: "form.effectiveDate"
    }
  }) : _c('strong', [_vm._v(_vm._s(_vm.form.effectiveDate ? _vm.moment(_vm.form.effectiveDate).format('DD MMMM YYYY') : '-'))])], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [['ADD', 'EDIT'].includes(_vm.action) ? _c('b-form-group', {
    attrs: {
      "label": "Attachment"
    }
  }, [_c('b-form-file', {
    attrs: {
      "disabled": "",
      "accept": "application/pdf"
    },
    model: {
      value: _vm.form.attachment,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "attachment", $$v);
      },
      expression: "form.attachment"
    }
  }), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v(" File Type * : "), _c('b-badge', {
    attrs: {
      "variant": "info"
    }
  }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs |.pdf")]), _c('br'), _vm._v(" File CapaKota * : "), _c('b-badge', {
    attrs: {
      "variant": "info"
    }
  }, [_vm._v("3 MB / File")])], 1)], 1) : _c('b-form-group', {
    attrs: {
      "label": "Attachment"
    }
  }, [_c('h4', {
    staticClass: "text-primary"
  }, [_c('i', {
    staticClass: "fa fa-file"
  })])])], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Reason"
    }
  }, [['ADD', 'EDIT'].includes(_vm.action) ? _c('b-form-textarea', {
    attrs: {
      "placeholder": "Type TExt",
      "rows": "4"
    },
    model: {
      value: _vm.form.reason,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "reason", $$v);
      },
      expression: "form.reason"
    }
  }) : _c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$form$reason = (_vm$form = _vm.form) === null || _vm$form === void 0 ? void 0 : _vm$form.reason) !== null && _vm$form$reason !== void 0 ? _vm$form$reason : '-'))])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "type": "checkbox",
      "disabled": !['ADD', 'EDIT'].includes(_vm.action)
    },
    model: {
      value: _vm.form.notifyGmBod,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "notifyGmBod", $$v);
      },
      expression: "form.notifyGmBod"
    }
  }, [_vm._v(" NOTIFY GENERAL MANAGER & BOD")])], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "type": "checkbox",
      "disabled": !['ADD', 'EDIT'].includes(_vm.action)
    },
    model: {
      value: _vm.form.notifyEmployee,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "notifyEmployee", $$v);
      },
      expression: "form.notifyEmployee"
    }
  }, [_vm._v(" NOTIFY EMPLOYEE / PIC")])], 1)], 1)], 1) : _vm._e(), _vm.form.pic ? _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "border w-100 d-flex my-4"
  })]) : _vm._e(), ['ADD', 'EDIT'].includes(_vm.action) && _vm.form.pic ? _c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end w-100"
  }, [_c('b-button', {
    staticStyle: {
      "width": "20%"
    },
    attrs: {
      "disabled": _vm.saving,
      "block": "",
      "variant": "primary",
      "type": "submit"
    }
  }, [_vm.saving ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _c('div', [_c('i', {
    staticClass: "fa fa-save"
  }), _c('span', [_vm._v("Submit Data")])])])], 1)]) : _vm._e(), ['ADD', 'EDIT'].includes(_vm.action) && !_vm.form.pic ? _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "md": "12"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v("Choose PIC First.")])]) : _vm._e()], 1) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }