var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "px-3"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Budget Technical")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table mb-0 table-striped table-hover"
        }, [_c('thead', [_c('th', [_vm._v("Company")]), _c('th', {
          staticClass: "text-center"
        }, [_c('div', [_vm._v("% Realization Aug 2024")]), _c('small', [_vm._v("(Budget Vs Realization)")])]), _c('th', {
          staticClass: "text-center"
        }, [_c('div', [_vm._v("% Realisasi YTD ")]), _c('small', [_vm._v(" (Budget Vs Realization)")])])]), _c('tbody', _vm._l(_vm.chartData, function (item, i) {
          return _c('tr', {
            key: i
          }, [_c('td', [_c('router-link', {
            attrs: {
              "to": '/ops/budget-technical/' + i
            }
          }, [_vm._v(" " + _vm._s(item.title) + " ")])], 1), _c('td', {
            staticClass: "text-center",
            staticStyle: {
              "color": "#18c63e"
            }
          }, [_vm._v("97.87%")]), _c('td', {
            staticClass: "text-center",
            staticStyle: {
              "color": "red"
            }
          }, [_vm._v("100.84%")])]);
        }), 0)])])], 1), _c('b-row', {
          staticClass: "mt-5"
        }, _vm._l(_vm.chartData, function (chart, index) {
          return _c('b-col', {
            key: index,
            attrs: {
              "lg": "6"
            }
          }, [_c('LineChartBudget', {
            attrs: {
              "title": chart.title,
              "element": "".concat(chart.element, "-").concat(index),
              "chartData": chart.data
            }
          })], 1);
        }), 1)];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }