<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" >
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="nav-fill mb-3">
                <tab-nav-items class="col-md-2 p-0" :active="true" @click.native="tab = 'class'" href="#class" title="VENDOR CLASS"/>
                <tab-nav-items class="col-md-2 p-0" :active="false" @click.native="tab = 'type'" href="#type" title="VENDOR TYPE"/>
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="12">
        <div class="tab-content">
          <tab-content-item :active="true" id="class">
            <TabVendorClass :refreshMasterData="refreshMasterData" />
          </tab-content-item>
          <!-- -------------- -->
            <tab-content-item :active="false" id="type">
            <TabVendorType :refreshMasterData="refreshMasterData"  />
          </tab-content-item>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
  import _ from 'lodash'
  import moment from 'moment'
  import TabVendorType from './TabVendorType.vue'
  import TabVendorClass from './TabVendorClass.vue'
  import { busdevActions } from '@src/Utils/helper'

  export default {
    name: 'ContentVendorMasters',
    props:{
      refreshMasterData: {
        type: Function,
        default: true
      },
    },
    data() {
      return {
      
      }
    },
    components:{
      TabVendorType,
      TabVendorClass,
    },
    watch:{},
    computed: {},
    async mounted() {
    },
    methods:{
      ...busdevActions
    }
  }
</script>