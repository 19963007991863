var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    class: {
      'px-3': _vm.showFilter
    }
  }, [_vm.showFilter ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "pb-1"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('p', {
          staticClass: "text-primary text-nowrap m-0"
        }, [_vm._v("Advance Search")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', {}, [_c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-align": "left",
            "label-for": "year"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": ['2022', '2022'],
            "id": "year",
            "size": "lg"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-align": "left",
            "label-for": "period"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": ['January-Juli', 'Juli-Juli'],
            "id": "period",
            "size": "lg"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-align": "left",
            "label-for": "period"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": ['January-Juli', 'Juli-Juli'],
            "id": "period",
            "size": "lg"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center h-100 pb-3"
        }, [_c('button', {
          staticClass: "btn btn-primary px-3 py-2"
        }, [_c('span', {
          staticClass: "fa fa-search"
        })])])]), _c('b-col', {
          attrs: {
            "md": "5"
          }
        }, [_c('div', {
          staticClass: "d-flex justify-content-end h-100 pb-3"
        }, [_c('button', {
          staticClass: "btn btn-primary px-3 py-2 gap-2 mr-3"
        }, [_c('span', {
          staticClass: "fa fa-file-excel mr-2"
        }), _vm._v("Export")]), _c('button', {
          staticClass: "btn btn-primary px-3 py-2 gap-2",
          on: {
            "click": function click($event) {
              _vm.isOpenCreateRM = true;
            }
          }
        }, [_c('span', {
          staticClass: "fa fa-plus mr-2"
        }), _vm._v("Create Reapair Maintenance")])])])], 1)];
      },
      proxy: true
    }], null, false, 3439669003)
  })], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "pb-1"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', [_c('strong', {
          staticClass: "text-primary"
        }, [_vm._v("Breakdown Record")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', {
          staticClass: "p-3"
        }, [_c('b-col', {
          staticClass: "overflow-auto p-0",
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "w-100 mb-3"
        }, [_c('thead', {
          staticClass: "bg-primary text-truncate"
        }, _vm._l(_vm.columns, function (col, i) {
          return _c('th', {
            key: i,
            staticClass: "p-2 fs-5"
          }, [_vm._v(_vm._s(col))]);
        }), 0), _c('tbody', _vm._l(5, function (col, i) {
          return _c('tr', {
            key: i,
            staticClass: "text-black",
            class: {
              'table-row-slate': i % 2 == 0
            }
          }, [_c('td', {
            staticClass: "p-2 text-truncate"
          }, [_vm._v(_vm._s(i + 1))]), _c('td', {
            staticClass: "p-2 text-truncate"
          }, [_vm._v("BG. SML 104")]), _c('td', {
            staticClass: "p-2 text-truncate"
          }, [_vm._v("Barge")]), _c('td', {
            staticClass: "p-2 text-truncate"
          }, [_vm._v("Repair")]), _c('td', {
            staticClass: "p-2 text-truncate"
          }, [_vm._v("03 Mar 2024")]), _c('td', {
            staticClass: "p-2 text-truncate"
          }, [_vm._v("10 Mar 2024")]), _c('td', {
            staticClass: "p-2 text-truncate"
          }, [_vm._v("Lubricating Oil Cooler")]), _c('td', {
            staticClass: "p-2 text-truncate"
          }, [_vm._v("Subkontraktor")]), _c('td', {
            staticClass: "p-2 text-truncate"
          }, [_vm._v(" Lubricating Oil Cooler ")]), _c('td', {
            staticClass: "p-2 text-truncate"
          }, [_vm._v(" Lubricating Oil Cooler ")]), _c('td', {
            staticClass: "p-2 text-truncate"
          }, [_vm._v(" Subkontraktor ")]), _c('td', {
            staticClass: "p-2 text-truncate"
          }, [_vm._v(" Iwan ")])]);
        }), 0)])]), _c('b-col', {
          staticClass: "my-2"
        }, [_c('p', {
          staticClass: "d-inline"
        }, [_vm._v("Total Rows : "), _c('span', {
          staticClass: "text-black"
        }, [_vm._v("10")])]), _c('div', {
          staticClass: "d-inline float-right"
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": 10,
            "per-page": 5,
            "aria-controls": "my-table"
          }
        })], 1)])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('CreateRepairMaintenance', {
    attrs: {
      "isOpen": _vm.isOpenCreateRM
    },
    on: {
      "close": function close($event) {
        _vm.isOpenCreateRM = false;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }