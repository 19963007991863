var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "iq-comingsoon pt-5"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-sm-9 text-center"
  }, [_c('div', {
    staticClass: "iq-comingsoon-info"
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('img', {
    staticClass: "img-fluid w-25",
    attrs: {
      "src": require('../../../assets/images/logo.png'),
      "alt": "logo"
    }
  })])])])]), _c('form', {
    attrs: {
      "action": "#",
      "method": "post"
    },
    on: {
      "submit": _vm.onSave
    }
  }, [_c('div', {
    staticClass: "row justify-content-center mt-3"
  }, [_c('div', {
    staticClass: "col-lg-8"
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("REQUEST SIGN OFF")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.filter",
            modifiers: {
              "filter": true
            }
          }],
          staticClass: "text-primary float-right ml-3",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$CrewData, _vm$CrewData2;
        return [_c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "visible": "",
            "id": "filter"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "5"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Nama",
            "label-for": "fullname"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "fullname",
            "name": "fullname",
            "placeholder": "Type Text ..."
          },
          model: {
            value: _vm.params.fullname,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "fullname", $$v);
            },
            expression: "params.fullname"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "NIP",
            "label-for": "nip"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "nip",
            "name": "nip",
            "placeholder": "Type NIP ..."
          },
          model: {
            value: _vm.params.nip,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "nip", $$v);
            },
            expression: "params.nip"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-button', {
          staticClass: "mr-1 mt-4",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.getCrewList();
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)], 1), _vm.CrewData !== null ? _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Nama Crew",
            "label-for": "name_crew"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Type Text ...",
            "value": (_vm$CrewData = _vm.CrewData) === null || _vm$CrewData === void 0 ? void 0 : _vm$CrewData.fullname,
            "disabled": ""
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "NIP",
            "label-for": "nip"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Type Text ...",
            "value": (_vm$CrewData2 = _vm.CrewData) === null || _vm$CrewData2 === void 0 ? void 0 : _vm$CrewData2.nip,
            "disabled": ""
          }
        })], 1)], 1)], 1) : _vm._e(), _vm.CrewData !== null ? _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('form', {
          attrs: {
            "action": "#",
            "method": "post"
          },
          on: {
            "submit": _vm.onSave
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Alasan Sign Off",
            "label-for": "reason_sign_off_id"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionsReasonSignOff,
            "size": "sm",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Alasan Sign Off")])];
            },
            proxy: true
          }], null, false, 3296111450),
          model: {
            value: _vm.form.reason_sign_off_id,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "reason_sign_off_id", $$v);
            },
            expression: "form.reason_sign_off_id"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Notice Off",
            "label-for": "notice_off"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date",
            "id": "notice_off",
            "name": "notice_off",
            "placeholder": "Type Text ...",
            "required": ""
          },
          model: {
            value: _vm.form.notice_off,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "notice_off", $$v);
            },
            expression: "form.notice_off"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Detail Notice Off",
            "label-for": "detail_notice_off"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "id": "detail_notice_off",
            "name": "detail_notice_off",
            "placeholder": "Type Text ...",
            "required": ""
          },
          model: {
            value: _vm.form.detail_notice_off,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "detail_notice_off", $$v);
            },
            expression: "form.detail_notice_off"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Attachment Notice Off",
            "label-for": "attachment_notice_off"
          }
        }, [_c('b-form-file', {
          attrs: {
            "type": "file",
            "id": "attachment_notice_off",
            "name": "attachment_notice_off",
            "placeholder": "Type Text ...",
            "required": ""
          },
          model: {
            value: _vm.form.attachment_notice_off,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "attachment_notice_off", $$v);
            },
            expression: "form.attachment_notice_off"
          }
        })], 1)], 1)])]), _vm.CrewData === null ? _c('div', {
          staticClass: "px-4 mb-4 d-flex align-items-center justify-content-center w-100 bg-active h5"
        }, [_c('strong', {
          staticClass: "text-muted"
        }, [_vm._v(" PILIH CREW TERLEBIH DAHULU. ")])]) : _vm._e(), _c('hr'), _c('b-col', {
          staticClass: "mt-3",
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "my-3",
          attrs: {
            "disabled": _vm.saving,
            "block": "",
            "variant": "primary",
            "type": "submit"
          }
        }, [_vm.saving ? _c('span', {
          staticClass: "spinner-border spinner-border-sm"
        }) : _c('span', [_vm._v("Submit")])])], 1)], 1) : _vm._e()];
      },
      proxy: true
    }])
  })], 1)])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }