var render = function render(){
  var _vm$optionsCargoType$, _vm$optionsCargoType$2, _vm$optionsContractTy, _vm$optionsContractTy2, _vm$optionsStage$find, _vm$optionsStage$find2, _vm$params$ownership;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("SHIPMENT PLANNING")])])];
      },
      proxy: true
    }])
  }, [_c('b-modal', {
    attrs: {
      "title": "Filter Data",
      "ok-title": "Filter",
      "centered": ""
    },
    on: {
      "ok": _vm.onFilter
    },
    model: {
      value: _vm.modal.filter,
      callback: function callback($$v) {
        _vm.$set(_vm.modal, "filter", $$v);
      },
      expression: "modal.filter"
    }
  }, [_c('b-row', {}, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-3",
    attrs: {
      "label": "Period",
      "label-for": "Period",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('date-picker', {
    style: {
      width: '100%'
    },
    attrs: {
      "type": "date",
      "range": "",
      "placeholder": "Select date range"
    },
    model: {
      value: _vm.dateRange,
      callback: function callback($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-3",
    attrs: {
      "label": "Cargo Type",
      "label-for": "cargo",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "options": _vm.optionsCargoType
    },
    model: {
      value: _vm.params.cargo_type_id,
      callback: function callback($$v) {
        _vm.$set(_vm.params, "cargo_type_id", $$v);
      },
      expression: "params.cargo_type_id"
    }
  }, [_c('b-form-select-option', {
    attrs: {
      "value": null
    }
  }, [_vm._v("All")])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-3",
    attrs: {
      "label": "Contract Type",
      "label-for": "cargo",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": ""
    },
    model: {
      value: _vm.params.contract_type_id,
      callback: function callback($$v) {
        _vm.$set(_vm.params, "contract_type_id", $$v);
      },
      expression: "params.contract_type_id"
    }
  }, [_c('b-form-select-option', {
    attrs: {
      "value": null
    }
  }, [_vm._v("All")]), _vm._l(_vm.optionsContractType, function (option, index) {
    return _c('b-form-select-option', {
      key: index,
      attrs: {
        "value": option.id
      }
    }, [_vm._v(_vm._s(option.name))]);
  })], 2)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-3",
    attrs: {
      "label": "Stage Status",
      "label-for": "stage_id",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('b-form-select', {
    staticClass: "mb-2",
    attrs: {
      "plain": "",
      "options": _vm.optionsStage
    },
    model: {
      value: _vm.params.stage_id,
      callback: function callback($$v) {
        _vm.$set(_vm.params, "stage_id", $$v);
      },
      expression: "params.stage_id"
    }
  }, [_c('b-form-select-option', {
    attrs: {
      "value": null
    }
  }, [_vm._v("All")])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-3",
    attrs: {
      "label": "Ownership",
      "label-for": "fleet_ownership",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('b-form-select', {
    staticClass: "mb-2",
    attrs: {
      "plain": ""
    },
    model: {
      value: _vm.params.fleet_ownership,
      callback: function callback($$v) {
        _vm.$set(_vm.params, "fleet_ownership", $$v);
      },
      expression: "params.fleet_ownership"
    }
  }, [_c('b-form-select-option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("All")]), _c('b-form-select-option', {
    attrs: {
      "value": "own-ship"
    }
  }, [_vm._v("Own Ship")]), _c('b-form-select-option', {
    attrs: {
      "value": "chartered"
    }
  }, [_vm._v("Chartered")])], 1)], 1)], 1), _vm.params.fleet_ownership ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-3",
    attrs: {
      "label": "Vehicle",
      "label-for": "vehicle_id",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "text",
      "name": "vehicle_id",
      "options": _vm.optionsFleets,
      "reduce": function reduce(optionsFleets) {
        return optionsFleets.value;
      },
      "placeholder": "Select Fleet"
    },
    model: {
      value: _vm.params.vehicle_id,
      callback: function callback($$v) {
        _vm.$set(_vm.params, "vehicle_id", $$v);
      },
      expression: "params.vehicle_id"
    }
  })], 1)], 1) : _vm._e()], 1)], 1), _c('b-row', {
    staticClass: "my-3 px-4 d-flex justify-content-end"
  }, [_c('b-col', {
    staticClass: "d-flex",
    attrs: {
      "md": "5"
    }
  }, [_c('b-button', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.toggleModalFilter
    }
  }, [_c('i', {
    staticClass: "fas fa-sliders"
  }), _c('p', {
    staticClass: "text-truncate m-0"
  }, [_vm._v(" Filter Data")])])], 1), _c('b-col', {
    staticClass: "d-flex justify-content-end",
    attrs: {
      "md": "7"
    }
  }, [_c('strong', {
    staticStyle: {
      "align-self": "center"
    }
  }, [_vm._v("Display :  ")]), _c('div', {
    staticClass: "btn-group"
  }, [_c('button', {
    staticClass: "btn btn-outline-primary",
    class: {
      active: _vm.view_style === 'list'
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.view_style = 'list';
      }
    }
  }, [_c('span', {
    staticClass: "fa fa-list"
  }), _vm._v(" List")]), _c('button', {
    staticClass: "btn btn-outline-primary",
    class: {
      active: _vm.view_style === 'kanban'
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.view_style = 'kanban';
      }
    }
  }, [_c('span', {
    staticClass: "fa fa-th-large"
  }), _vm._v(" Kanban")]), _c('button', {
    staticClass: "btn btn-outline-primary",
    class: {
      active: _vm.view_style === 'gantt-chart'
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.view_style = 'gantt-chart';
      }
    }
  }, [_c('span', {
    staticClass: "fa fa-bars"
  }), _vm._v(" Gantt Chart")])]), _c('div', {
    staticClass: "mx-2",
    staticStyle: {
      "border-left": "1px solid"
    }
  }), [_c('b-button', {
    attrs: {
      "variant": "primary",
      "size": "md"
    },
    on: {
      "click": _vm.refreshDataTable
    }
  }, [_c('i', {
    staticClass: "fa fa-refresh"
  }), _vm._v("   Refresh Data")]), _c('b-button', {
    staticClass: "mx-1",
    attrs: {
      "variant": "primary",
      "size": "md"
    },
    on: {
      "click": _vm.onExportShipmentPlanning
    }
  }, [_c('i', {
    staticClass: "fa fa-file-excel"
  }), _vm._v(" Export")]), _vm.addPermission ? _c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.openSlideFormAddPlan('ADD', null);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v("   Add Plan")]) : _vm._e()]], 2)], 1), _c('div', {
    staticStyle: {
      "padding": "12px 40px"
    }
  }, [_c('b-row', {
    staticClass: "border rounded-md pt-2"
  }, [_c('b-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Cargo Type :"
    }
  }, [_c('p', {
    staticClass: "mb-0 text-black",
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s((_vm$optionsCargoType$ = (_vm$optionsCargoType$2 = _vm.optionsCargoType.find(function (el, i) {
    return el.id == _vm.params.cargo_type_id;
  })) === null || _vm$optionsCargoType$2 === void 0 ? void 0 : _vm$optionsCargoType$2.name) !== null && _vm$optionsCargoType$ !== void 0 ? _vm$optionsCargoType$ : 'All'))])])], 1), _c('b-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Contract Type :"
    }
  }, [_c('p', {
    staticClass: "mb-0 text-black",
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s((_vm$optionsContractTy = (_vm$optionsContractTy2 = _vm.optionsContractType.find(function (el, i) {
    return el.id == _vm.params.contract_type_id;
  })) === null || _vm$optionsContractTy2 === void 0 ? void 0 : _vm$optionsContractTy2.name) !== null && _vm$optionsContractTy !== void 0 ? _vm$optionsContractTy : 'All'))])])], 1), _c('b-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Stage Status :"
    }
  }, [_c('p', {
    staticClass: "mb-0 text-black",
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s((_vm$optionsStage$find = (_vm$optionsStage$find2 = _vm.optionsStage.find(function (el, i) {
    return el.value == _vm.params.stage_id;
  })) === null || _vm$optionsStage$find2 === void 0 ? void 0 : _vm$optionsStage$find2.text) !== null && _vm$optionsStage$find !== void 0 ? _vm$optionsStage$find : 'All'))])])], 1), _c('b-col', {
    attrs: {
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Ownership"
    }
  }, [_c('p', {
    staticClass: "mb-0 text-black",
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s((_vm$params$ownership = _vm.params.ownership) !== null && _vm$params$ownership !== void 0 ? _vm$params$ownership : "All"))])])], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_vm.dateRange.length ? _c('b-form-group', {
    attrs: {
      "label": "Period :"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('p', {
    staticClass: "mb-0 text-black",
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v(_vm._s(_vm.dateRange[0] ? _vm.moment(_vm.dateRange[0]).format("DD MMMM YYYY") : '-') + " - "), _c('span', [_vm._v(_vm._s(_vm.dateRange[1] ? _vm.moment(_vm.dateRange[1]).format("DD MMMM YYYY") : '-'))])])])]) : _vm._e()], 1)], 1)], 1), _vm.view_style === 'list' ? [_c('b-row', {
    staticClass: "m-2"
  }, [!_vm.loading ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex overflow-auto",
    staticStyle: {
      "max-height": "500px"
    }
  }, [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center"
  }, [_vm._v("NO")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "width": "150px"
    }
  }, [_vm._v("PERIODE")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("PROJECT")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("FLEETS")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("TYPE")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("JUMLAH SHIPMENT")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("CARGO TYPE")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("CONTRACT TYPE")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("TOTAL VOLUME")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("STAGE")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("CREATED BY")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("APPROVAL")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("ACTION")])])]), _vm.listShipmentPlanningTable.data.length > 0 ? _c('tbody', _vm._l(_vm.listShipmentPlanningTable.data, function (state, index) {
    var _state$cargo_type, _state$contract_type, _state$contract_type2, _state$contract_type3, _state$volume, _state$project_log, _state$project_log$, _state$project_stage2, _state$contract_type4;
    return _c('tr', {
      key: index
    }, [_c('th', {
      staticClass: "text-center bg-white text-black"
    }, [_vm._v(_vm._s(index + 1))]), _c('td', {
      staticClass: "text-center bg-white text-black"
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('strong', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(_vm.moment(state === null || state === void 0 ? void 0 : state.periode_start).format('DD MMM YYYY')))]), _c('span', {
      staticClass: "mx-2"
    }, [_vm._v("s/d")]), _c('strong', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(_vm.moment(state === null || state === void 0 ? void 0 : state.periode_end).format('DD MMM YYYY')))])])]), _c('td', {
      staticClass: "text-truncate"
    }, [_c('span', {
      staticClass: "hover text-primary",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.openSlideFormAddPlan('PREVIEW', state === null || state === void 0 ? void 0 : state.id);
        }
      }
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.project))])]), _c('td', {
      staticClass: "text-center bg-white text-black",
      staticStyle: {
        "width": "150px"
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, _vm._l(state === null || state === void 0 ? void 0 : state.fleets, function (state, index) {
      return _c('b-badge', {
        key: index,
        staticClass: "border border-primary text-primary text-uppercase mr-2",
        attrs: {
          "variant": "none"
        }
      }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.vehicle_name))]);
    }), 1)]), _c('th', {
      staticClass: "text-center bg-white text-black"
    }, [_c('b-badge', {
      staticClass: "border border-primary text-primary text-uppercase",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v(_vm._s((state === null || state === void 0 ? void 0 : state.project_type) === 'existing' ? 'EXISTING' : 'NEW PROJECT'))])], 1), _c('td', {
      staticClass: "text-center text-truncate"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.shipment) + " SHIPMENT")]), _c('td', {
      staticClass: "text-center text-truncate"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$cargo_type = state.cargo_type) === null || _state$cargo_type === void 0 ? void 0 : _state$cargo_type.name))]), _c('td', {
      staticClass: "text-center text-truncate"
    }, [_c('div', {
      staticClass: "d-flex flex-column align-items-center justify-content-center"
    }, [_c('span', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$contract_type = state.contract_type) === null || _state$contract_type === void 0 ? void 0 : _state$contract_type.name))]), _c('strong', {
      class: "".concat(state !== null && state !== void 0 && (_state$contract_type2 = state.contract_type) !== null && _state$contract_type2 !== void 0 && _state$contract_type2.is_spal ? 'text-success' : 'text-warning')
    }, [_c('small', [_vm._v(_vm._s(state !== null && state !== void 0 && (_state$contract_type3 = state.contract_type) !== null && _state$contract_type3 !== void 0 && _state$contract_type3.is_spal ? 'Yes, using spal' : 'Not using spal'))])])])]), _c('td', {
      staticClass: "text-center text-truncate"
    }, [_vm._v(_vm._s(state !== null && state !== void 0 && state.volume ? state === null || state === void 0 ? void 0 : (_state$volume = state.volume) === null || _state$volume === void 0 ? void 0 : _state$volume.toLocaleString() : '0') + " " + _vm._s(state === null || state === void 0 ? void 0 : state.unit))]), _c('td', {
      staticClass: "text-center bg-white text-black"
    }, [_c('b-dropdown', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.top",
        value: 'Action',
        expression: "'Action'",
        modifiers: {
          "top": true
        }
      }],
      staticClass: "float-right mr-1",
      attrs: {
        "variant": "link iq-bg-primary",
        "toggle-class": "text-decoration-none",
        "no-caret": "",
        "size": "sm"
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function fn() {
          var _state$project_stage;
          return [_c('span', {
            staticClass: "dropdown-toggle p-0",
            attrs: {
              "id": "dropdownMenuButton5",
              "data-toggle": "dropdown"
            }
          }, [_vm._v(" " + _vm._s(state === null || state === void 0 ? void 0 : (_state$project_stage = state.project_stage) === null || _state$project_stage === void 0 ? void 0 : _state$project_stage.title) + " ")])];
        },
        proxy: true
      }], null, true)
    }, _vm._l(_vm.listProjectStage, function (obj, objIndex) {
      return _c('b-dropdown-item', {
        key: objIndex,
        attrs: {
          "href": "#",
          "variant": (state === null || state === void 0 ? void 0 : state.detail_project_stage_id) === obj.id ? 'primary' : 'secondary'
        }
      }, [_c('strong', {
        on: {
          "click": function click($event) {
            return _vm.onChangeStatusStage(index, state === null || state === void 0 ? void 0 : state.id, obj.id);
          }
        }
      }, [_vm._v(_vm._s(obj === null || obj === void 0 ? void 0 : obj.title))])]);
    }), 1)], 1), _c('td', {
      staticClass: "text-center text-primary text-truncate"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$project_log = state.project_log) === null || _state$project_log === void 0 ? void 0 : (_state$project_log$ = _state$project_log[0]) === null || _state$project_log$ === void 0 ? void 0 : _state$project_log$.name))]), _c('td', {
      staticClass: "text-center text-primary"
    }, [_c('div', {
      staticClass: "d-flex align-items-center justify-content-center"
    }, [_vm._v(" " + _vm._s(state === null || state === void 0 ? void 0 : state.count_approval) + "/" + _vm._s(state === null || state === void 0 ? void 0 : state.many_approval) + " "), _c('i', {
      staticClass: "fa fa-user ml-2"
    })])]), _vm.updatePermission || _vm.deletePermission ? _c('td', {
      staticClass: "text-center bg-white text-black"
    }, [_c('b-dropdown', {
      attrs: {
        "size": "lg",
        "variant": "link iq-bg-primary iq-border-radius-10",
        "toggle-class": "text-decoration-none",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function fn() {
          return [_c('span', {
            staticClass: "dropdown-toggle p-0",
            attrs: {
              "id": "dropdownMenuButton5",
              "data-toggle": "dropdown"
            }
          }, [_c('i', {
            staticClass: "fa fa-pencil-square-o m-0 text-primary"
          })])];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.openSlideFormAddPlan('PREVIEW', state === null || state === void 0 ? void 0 : state.id);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-eye mr-2"
    }), _vm._v("Detail ")]), state !== null && state !== void 0 && state.is_pfa ? _c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": "warning"
      },
      on: {
        "click": function click($event) {
          return _vm.openPfaSlide(state === null || state === void 0 ? void 0 : state.id);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-info-circle mr-2"
    }), _vm._v("PFA ")]) : _vm._e(), _c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.openProjectFormSlide(state === null || state === void 0 ? void 0 : state.id, state === null || state === void 0 ? void 0 : state.project);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-info-circle mr-2"
    }), _vm._v("Collection Forms ")]), state !== null && state !== void 0 && (_state$project_stage2 = state.project_stage) !== null && _state$project_stage2 !== void 0 && _state$project_stage2.spal_process && state !== null && state !== void 0 && (_state$contract_type4 = state.contract_type) !== null && _state$contract_type4 !== void 0 && _state$contract_type4.is_spal ? _c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": "success"
      },
      on: {
        "click": function click($event) {
          return _vm.openSlideSpalProcess('ADD', state === null || state === void 0 ? void 0 : state.id);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-info-circle mr-2"
    }), _vm._v("Spal Process ")]) : _vm._e(), _vm.approvalPermission(state === null || state === void 0 ? void 0 : state.user_approval) === true || ['System Checker'].includes(_vm.userProfileName) && Number(state === null || state === void 0 ? void 0 : state.count_approval) !== Number(state === null || state === void 0 ? void 0 : state.many_approval) ? _c('b-dropdown-divider') : _vm._e(), _vm.approvalPermission(state === null || state === void 0 ? void 0 : state.user_approval) === true || ['System Checker'].includes(_vm.userProfileName) && Number(state === null || state === void 0 ? void 0 : state.count_approval) !== Number(state === null || state === void 0 ? void 0 : state.many_approval) ? _c('b-dropdown-item', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal.project-approval",
        modifiers: {
          "project-approval": true
        }
      }],
      attrs: {
        "href": "#",
        "variant": "primary"
      },
      on: {
        "click": function click($event) {
          _vm.projectId = state === null || state === void 0 ? void 0 : state.id;
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-check mr-2"
    }), _vm._v("Approval ")]) : _vm._e(), _c('b-dropdown-divider'), _c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.onDeleteProjectContract(state);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash mr-2"
    }), _vm._v("Delete ")])], 1)], 1) : _vm._e()]);
  }), 0) : _vm._e()])]), _vm.listShipmentPlanningTable.data.length > 0 ? _c('div', {
    staticClass: "mt-3"
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.listShipmentPlanningTable.meta.totalItem,
      "per-page": _vm.listShipmentPlanningTable.meta.perPage,
      "first-number": "",
      "align": "center"
    },
    on: {
      "change": _vm.getListShipmentPlanningTable
    },
    model: {
      value: _vm.listShipmentPlanningTable.meta.page,
      callback: function callback($$v) {
        _vm.$set(_vm.listShipmentPlanningTable.meta, "page", $$v);
      },
      expression: "listShipmentPlanningTable.meta.page"
    }
  })], 1) : _c('div', {
    staticClass: "d-flex align-items-center justify-content-center w-100 my-2 mb-4"
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v("No Data Shipment Planning.")])])]) : _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "alert alert-info"
  }, [_vm._v(" Loading... ")])])], 1)] : _vm._e(), _vm.view_style === 'kanban' ? [_c('Kanban', {
    attrs: {
      "kanban-data": _vm.kanbanData,
      "open-slide-detail-project-contract": _vm.openSlideDetailProjectContract,
      "openSlideFormAddPlan": _vm.openSlideFormAddPlan,
      "openSlideSpalProcess": _vm.openSlideSpalProcess,
      "on-add-list": _vm.onAddList,
      "on-card-move": _vm.onCardMove,
      "on-move-set-end-stage": _vm.onMoveSetEndStage,
      "on-remove-list": _vm.onRemoveList,
      "on-change-prioritize": _vm.onChangePrioritize,
      "on-delete-project-contract": _vm.onDeleteProjectContract,
      "on-edit-title": _vm.onEditTitle,
      "on-denied": _vm.onDenied,
      "on-spal-process": _vm.onSpalProcess,
      "on-running-process": _vm.onRunningProcess,
      "open-pfa-slide": _vm.openPfaSlide,
      "open-project-form-slide": _vm.openProjectFormSlide,
      "on-save-approval": _vm.onSaveApproval
    }
  })] : _vm._e(), _vm.view_style === 'gantt-chart' ? [_c('GanttChart', {
    attrs: {
      "gantt-chart-rows": _vm.ganttChartRows,
      "on-update-periode-date": _vm.onUpdatePeriodeDate,
      "open-slide-detail-project-contract": _vm.openSlideDetailProjectContract,
      "openSlideFormAddPlan": _vm.openSlideFormAddPlan,
      "on-delete-project-contract": _vm.onDeleteProjectContract,
      "open-pfa-slide": _vm.openPfaSlide,
      "open-project-form-slide": _vm.openProjectFormSlide
    }
  })] : _vm._e(), _c('b-sidebar', {
    attrs: {
      "id": "add_rakor",
      "title": ['PREVIEW', 'EDIT'].includes(_vm.action) ? 'DETAIL PLAN' : "ADD PLAN",
      "width": "100%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_c('FormAddPlan', {
          attrs: {
            "open-customer-slide": _vm.openCustomerSlide,
            "refresh-data-table": _vm.refreshDataTable,
            "refreshDataTableWithoutCloseModal": _vm.refreshDataTableWithoutCloseModal,
            "action": _vm.action,
            "edit-id": _vm.editId,
            "openInspectionChecklistSlide": _vm.openInspectionChecklistSlide,
            "optionsCompanies": _vm.optionsCompanies
          },
          on: {
            "updateAction": _vm.updateAction
          }
        })];
      }
    }]),
    model: {
      value: _vm.slideAddRakor,
      callback: function callback($$v) {
        _vm.slideAddRakor = $$v;
      },
      expression: "slideAddRakor"
    }
  }), _c('b-sidebar', {
    attrs: {
      "id": "new-customer",
      "title": "NEW CUSTOMER",
      "width": "100%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var hide = _ref2.hide;
        return [_c('b-row', {
          staticClass: "m-3"
        }, [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 text-primary"
        }, [_vm._v("Customers")]), _c('b-row', {
          staticClass: "mt-2"
        }, [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('FormCustomer', {
          attrs: {
            "is-edit": false,
            "edit-id": null,
            "refresh-data": _vm.refreshData,
            "optionsCustomerClass": _vm.optionsCustomerClass
          }
        })], 1)], 1)], 1)])], 1)];
      }
    }]),
    model: {
      value: _vm.slideNewCustomer,
      callback: function callback($$v) {
        _vm.slideNewCustomer = $$v;
      },
      expression: "slideNewCustomer"
    }
  }), _c('b-sidebar', {
    attrs: {
      "id": "slideAddPFA",
      "title": "ADD PFA(PRE FIXTURE ANALYSIS)",
      "width": "80%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var hide = _ref3.hide;
        return [_c('SlideFormPFA', {
          attrs: {
            "pfa-id": _vm.pfaId,
            "pfaAction": 'ADD',
            "options-cargo-type": _vm.optionsCargoType,
            "optionsFleets": _vm.optionsFleets,
            "openPfaSlide": _vm.openPfaSlide,
            "refreshDataTable": _vm.refreshDataTable,
            "onExportPFA": _vm.onExportPFA
          }
        })];
      }
    }]),
    model: {
      value: _vm.slideAddPFA,
      callback: function callback($$v) {
        _vm.slideAddPFA = $$v;
      },
      expression: "slideAddPFA"
    }
  }), _c('b-sidebar', {
    attrs: {
      "id": "slideSpalProcess",
      "title": "SPAL Process",
      "width": "70%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var hide = _ref4.hide;
        return [_c('SlideSpalProcess', {
          attrs: {
            "id": _vm.editId,
            "closeSlideSpalProcess": _vm.closeSlideSpalProcess,
            "refreshDataTableWithoutCloseModal": _vm.refreshDataTableWithoutCloseModal,
            "action": 'ADD'
          }
        })];
      }
    }]),
    model: {
      value: _vm.slideSpalProcess,
      callback: function callback($$v) {
        _vm.slideSpalProcess = $$v;
      },
      expression: "slideSpalProcess"
    }
  }), _c('b-sidebar', {
    attrs: {
      "id": "slideProjectForms",
      "title": _vm.projectName + '/Collection of Forms',
      "width": "80%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var hide = _ref5.hide;
        return [_c('SlideProjectForms', {
          attrs: {
            "form-id": _vm.editId,
            "open-inspection-checklist-slide": _vm.openInspectionChecklistSlide,
            "saved-project-form": _vm.savedProjectForm
          }
        })];
      }
    }]),
    model: {
      value: _vm.slideProjectForms,
      callback: function callback($$v) {
        _vm.slideProjectForms = $$v;
      },
      expression: "slideProjectForms"
    }
  }), _c('b-sidebar', {
    attrs: {
      "id": "slideAddPreviewInspectionChecklist",
      "title": 'Inspection Checklist',
      "width": "70%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var hide = _ref6.hide;
        return [_c('SlideInspectionChecklist', {
          attrs: {
            "is-preview-inspection-checklist": _vm.isPreviewInspectionChecklist,
            "is-add-inspection-checklist": _vm.isAddInspectionChecklist,
            "inspection-checklist-data": _vm.inspectionChecklistData,
            "refresh-slide-forms": _vm.refreshSlideForms
          }
        })];
      }
    }]),
    model: {
      value: _vm.slideAddPreviewInspectionChecklist,
      callback: function callback($$v) {
        _vm.slideAddPreviewInspectionChecklist = $$v;
      },
      expression: "slideAddPreviewInspectionChecklist"
    }
  }), _c('b-modal', {
    attrs: {
      "id": "project-approval",
      "size": "md",
      "title": "APPROVAL",
      "cancel-title": "Close",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var hide = _ref7.hide;
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-textarea', {
          staticClass: "mb-2",
          attrs: {
            "rows": "3",
            "name": "remarks",
            "placeholder": "Remarks..."
          },
          model: {
            value: _vm.remarks,
            callback: function callback($$v) {
              _vm.remarks = $$v;
            },
            expression: "remarks"
          }
        })], 1), _c('b-col', {
          attrs: {
            "lg": "12"
          }
        }, [_c('div', {
          staticClass: "border d-flex w-100 my-4"
        })]), _vm.approval_loading ? _c('b-col', {
          attrs: {
            "lg": "12",
            "align": "center"
          }
        }, [_c('span', {
          staticClass: "spinner-border spinner-border-sm"
        })]) : _vm._e(), !_vm.approval_loading ? _c('b-col', {
          attrs: {
            "lg": "6"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "warning",
            "block": ""
          },
          on: {
            "click": function click($event) {
              return _vm.onSaveApproval('rejected');
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-ban mr-2"
        }), _vm._v(" Reject ")])], 1) : _vm._e(), !_vm.approval_loading ? _c('b-col', {
          attrs: {
            "lg": "6"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "success",
            "block": ""
          },
          on: {
            "click": function click($event) {
              return _vm.onSaveApproval('approved');
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-check mr-2"
        }), _vm._v("Approve ")])], 1) : _vm._e()], 1)];
      }
    }])
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }