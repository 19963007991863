<template>
  <b-container fluid>
    <form  @submit="onSave"  action="#">
      <b-row>
        <b-col md="12">
          <div class="d-flex align-items-center justify-content-end w-100">
            <b-button
                variant="danger"
                type="button"
                class="mr-2"
                @click="closeslideFormItem"
              >
              <div>
                <i class="fa fa-ban"></i>
                <span>Cancel</span>
              </div>
              </b-button>

            <b-button
                :disabled="saving"
                variant="primary"
                type="submit"
              >
                <span
                  v-if="saving"
                  class="spinner-border spinner-border-sm"
                />
                <div v-else>
                  <i class="fa fa-save"></i>
                  <span>Submit Data</span>
                </div>
              </b-button>
          </div>
        </b-col>
        <b-col lg="12" >
          <hr>
          <iq-card body-class="p-0">
            <template v-slot:body>
              <div class="iq-edit-list">
                <tab-nav :pills="true" class="nav-fill mb-3">
                  <tab-nav-items class="col-md-3 p-0" :active="true" @click.native="tab = 'INFO'" href="#info" title="INFORMASI BARANG"/>
                  <tab-nav-items class="col-md-3 p-0" :active="false" @click.native="tab = 'QUANTITY'" href="#quantity" title="KUANTITAS"/>
                  <tab-nav-items class="col-md-3 p-0" :active="false" @click.native="tab = 'PRICE'" href="#price" title="HARGA"/>
                  <!-- <tab-nav-items class="col-md-3 p-0" :active="false" @click.native="tab = 'ITEM'" href="#item" title="BARANG"/> -->
                </tab-nav>
              </div>
            </template>
          </iq-card>
        </b-col>
        <b-col lg="12">
          <div class="tab-content">
            <!-- General -->
            <tab-content-item :active="true" id="info">
              <b-row>
                <b-col md="12">
                  <b-form-group label="For Fleet Type" label-for="selectAll">
                    <v-multiselect
                      v-model="form.vehicleType"
                      placeholder="Search Fleet Type"
                      label="name"
                      track-by="id"
                      :options="optionsFleetType"
                      :multiple="true"
                      required
                    >
                      <span slot="noResult">Fleet type not found.</span>
                    </v-multiselect>
                    <b-form-checkbox
                      name="check-button"
                      switch
                      inline
                      v-model="selectAll"
                    >
                      Select All Fleet Type 
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="*Code" label-for="code">
                    <span v-if="previewData" class="text-capitalize">{{ form.code }}</span>
                    <b-form-input
                      v-else
                      id="code"
                      type="text"
                      placeholder="Type text"
                      v-model="form.code"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="*Name" label-for="name">
                    <span v-if="previewData" class="text-capitalize">{{ form.name }}</span>
                    <b-form-input
                      v-else
                      id="name"
                      type="text"
                      placeholder="Type text"
                      v-model="form.name"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="*Spesification" label-for="spesification">
                    <span v-if="previewData" class="text-capitalize">{{ form.spesification_id }}</span>
                    <v-select
                      v-else
                      label="name"
                      :options="optionsSpesification"
                      :reduce="(type) => type.id"
                      v-model="form.spesification_id"
                      required
                      placeholder="Select..."
                    ></v-select>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="*Category Item" label-for="category_id">
                    <span v-if="previewData" class="text-capitalize">{{ form.category_id }}</span>
                    <v-select
                      v-else
                      label="name"
                      :options="optionsCategory"
                      :reduce="(type) => type.id"
                      v-model="form.category_id"
                      required
                      placeholder="Select..."
                    ></v-select>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="*Satuan" label-for="unit">
                    <span v-if="previewData" class="text-capitalize">{{ form.unit_id }}</span>
                    <v-select
                      v-else
                      label="alias"
                      :options="optionsUnit"
                      :reduce="(type) => type.id"
                      v-model="form.unit_id"
                      placeholder="Select..."
                    ></v-select>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Harga Satuan" label-for="unit_price">
                    <span v-if="previewData" >{{ form.unit_price }}</span>
                    <b-form-input
                      v-else
                      id="unit_price"
                      type="number"
                      placeholder="0"
                      v-model="form.unit_price"
                    ></b-form-input>
                    <span class="mb-2">{{formatCurrency(form.unit_price)}}</span> <br>
                    <b-form-checkbox
                      name="check-button"
                      switch
                      inline
                      v-model="form.unit_price_tax_inc"
                    >
                      Termasuk Pajak
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Gambar" label-for="photo">
                    <b-form-file
                      id="photo"
                      accept="image/*"
                      @change="onHandleUpload($event, 'photo')"
                    ></b-form-file>
                    <span class="text-primary" v-if="form.photo">{{ form.photo.split('/').pop() }}</span>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="File" label-for="File">
                    <b-form-file
                      id="file"
                      accept="application/*"
                      @change="onHandleUpload($event, 'file')"
                    ></b-form-file>
                    <span class="text-primary" v-if="form.file">{{ form.file.split('/').pop() }}</span>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Method Inspection QC" label-for="inspection_qc">
                    <span v-if="previewData" class="text-capitalize">{{ form.qc_method_check }}</span>
                    <b-form-select
                    v-else
                      id="qc_method_check"
                      plain
                      size="md"
                      v-model="form.qc_method_check"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="''">Select...</b-form-select-option>
                      </template>
                      <b-form-select-option :value="'lot'">Lot</b-form-select-option>
                      <b-form-select-option :value="'sampling'">Sampling</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="6" v-if="form.qc_method_check === 'sampling'">
                  <b-form-group label="Value" label-for="inspection_qc">
                    <span v-if="previewData" class="text-capitalize">{{ form.qc_method_check_value_sample }}</span>
                    <b-form-input
                      v-else
                      id="qc_method_check_value_sample"
                      type="number"
                      placeholder="0"
                      v-model="form.qc_method_check_value_sample"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group label="Keterangan" label-for="description">
                    <span v-if="previewData" class="text-capitalize">{{ form.description }}</span>
                    <b-form-textarea
                      v-else
                      id="description"
                      type="text"
                      placeholder="Type Text"
                      v-model="form.description"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
            </tab-content-item>
            <!-- End General -->
            <!-- Billing -->
            <tab-content-item :active="false" id="quantity">
              <b-row>
                <b-col md="6">
                  <b-form-group label="Jumlah Stok Aman" label-for="safe_stock_qty">
                    <span v-if="previewData" >{{ form.safe_stock_qty }}</span>
                    <b-form-input
                      v-else
                      id="safe_stock_qty"
                      type="number"
                      placeholder="0"
                      v-model="form.safe_stock_qty"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Jumlah Minimum Pembelian" label-for="minimum_buy_qty">
                    <span v-if="previewData" >{{ form.minimum_buy_qty }}</span>
                    <b-form-input
                      v-else
                      id="minimum_buy_qty"
                      type="number"
                      placeholder="0"
                      v-model="form.minimum_buy_qty"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Periode Pengadaan" label-for="procurement_period">
                    <span v-if="previewData" >{{ form.procurement_period }}</span>
                    <b-form-input
                      v-else
                      id="procurement_period"
                      type="text"
                      placeholder="Type Text"
                      v-model="form.procurement_period"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Brand" label-for="brand">
                    <span v-if="previewData" >{{ form.brand }}</span>
                    <b-form-input
                      v-else
                      id="brand"
                      type="text"
                      placeholder="Type Text"
                      v-model="form.brand"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </tab-content-item>
   
            <tab-content-item :active="false" id="price">
              <b-row>
                <b-col md="12">
                  <b-form-group label="Harga Beli" label-for="brand">
                    <span v-if="previewData" >{{ form.purchase_price }}</span>
                    <b-form-input
                      v-else
                      id="purchase_price"
                      type="number"
                      placeholder="0"
                      v-model="form.purchase_price"
                    ></b-form-input>
                    <span class="mb-2">{{formatCurrency(form.purchase_price)}}</span> <br>
                    <b-form-checkbox
                      name="check-button"
                      switch
                      inline
                      v-model="form.purchase_price_tax_inc"
                    >
                      Termasuk Pajak
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="Harga Jual" label-for="sell_price">
                    <span v-if="previewData" >{{ form.sell_price }}</span>
                    <b-form-input
                      v-else
                      id="sell_price"
                      type="number"
                      placeholder="0"
                      v-model="form.sell_price"
                    ></b-form-input>
                    <span class="mb-2">{{formatCurrency(form.sell_price)}}</span> <br>
                    <b-form-checkbox
                      name="check-button"
                      switch
                      inline
                      v-model="form.sell_price_tax_inc"
                    >
                      Termasuk Pajak
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="Harga Event" label-for="event_price">
                    <span v-if="previewData" >{{ form.event_price }}</span>
                    <b-form-input
                      v-else
                      id="event_price"
                      type="number"
                      placeholder="0"
                      v-model="form.event_price"
                    ></b-form-input>
                    <span class="mb-2">{{formatCurrency(form.event_price)}}</span> <br>
                    <b-form-checkbox
                      name="check-button"
                      switch
                      inline
                      v-model="form.event_price_tax_inc"
                    >
                      Termasuk Pajak
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
            </tab-content-item>
            <!-- End Shiping -->
            <!-- Contact -->
            <!-- <tab-content-item :active="false" id="item">
              <b-row>
                <b-col md="12">
                  <b-form-group label="Harga O/E" label-for="o_e_price" class="d-flex flex-column">
                    <span v-if="previewData" >{{ form.o_e_price }}</span>
                    <b-form-input
                      v-else
                      id="o_e_price"
                      type="number"
                      placeholder="0"
                      v-model="form.o_e_price"
                    ></b-form-input>
                    <span class="mb-2">{{formatCurrency(form.o_e_price)}}</span> <br>
                    <b-form-checkbox
                      name="check-button"
                      switch
                      inline
                      v-model="form.o_e_price_tax_inc"
                    >
                      Termasuk Pajak
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
            </tab-content-item> -->
            <!-- End Contact -->
          </div>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>
<script>
  import _ from 'lodash'
  import { busdevActions, fleetsActions } from '@src/Utils/helper'
  import moment from 'moment'

  export default {
    name: 'FormInventoryItem',
    props:{
      closeslideFormItem: {
        type: Function,
        default: () => {}
      }, 
      refreshData: {
        type: Function,
        default: () => {}
      }, 
      editId: {
        type: Number,
        default: null
      },
      action: {
        type: String,
        default: ''
      }, 
      previewData: {
        type: Boolean,
        default: false
      }, 

      optionsUnit: {
        type: Array,
        default: () => []
      },
      optionsSpesification: {
        type: Array,
        default: () => []
      },
      optionsCategory: {
        type: Array,
        default: () => []
      },
    },
    data() {
      return {
        loading:false,
        saving:false,

        selectAll:false,

        optionsFleetType:[],

        form:{
          "code": "",
          "name": "",
          "category_id": null,
          "spesification_id": null,
          "unit_id": null,
          "barcode": "",
          "photo": "",
          "file": "",
          "description": "",
          "qc_method_check": "lot",   //lot, sampling
          "qc_method_check_value_sample": null,
          "safe_stock_qty": null,
          "procurement_period": "",
          "minimum_buy_qty": null,
          "brand": "",
          "unit_price": null,
          "unit_price_tax_inc": false,
          "purchase_price": null,
          "purchase_price_tax_inc": false,
          "sell_price": null,
          "sell_price_tax_inc": false,
          "event_price":null,
          "event_price_tax_inc": false,
          "o_e_price":null,
          "o_e_price_tax_inc": false,
          createdBy:JSON.parse(localStorage.getItem('profile')).userDetail.name,
          vehicleType:[]
        }
      }
    },
    components:{},
    watch:{
      'editId': {
        handler(value) {
          if(value){
            this.getInventoryItemById(value)
          } else {
            this.resetFormInventoryItem()
          }
        },
        immediate: true,
      },
      'selectAll': {
        handler(value) {
          if(value){
            this.form.vehicleType = this.optionsFleetType
          } else {
            this.form.vehicleType = []
          }
        },
        immediate: true,
      },
    },
    computed: {},
    async mounted() {
      this.fetchFleetTypes()
    },
    methods: {
      ...busdevActions,
      ...fleetsActions,
      formatCurrency(value) {
        return new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        }).format(value)
      },
      resetFormInventoryItem(){
        this.form = {
          "code": "",
          "name": "",
          "category_id": null,
          "spesification_id": null,
          "unit_id": null,
          "barcode": "",
          "photo": "",
          "file": "",
          "description": "",
          "qc_method_check": "lot",   //lot, sampling
          "qc_method_check_value_sample": null,
          "safe_stock_qty": null,
          "procurement_period": "",
          "minimum_buy_qty": null,
          "brand": "",
          "unit_price": null,
          "unit_price_tax_inc": false,
          "purchase_price": null,
          "purchase_price_tax_inc": false,
          "sell_price": null,
          "sell_price_tax_inc": false,
          "event_price":null,
          "event_price_tax_inc": false,
          "o_e_price":null,
          "o_e_price_tax_inc": false,
          createdBy:JSON.parse(localStorage.getItem('profile')).userDetail.name,
          vehicleType:[]
        }
      },

      async onHandleUpload(event, key){
        const files = [...event.target.files]
        let file = files[0]

        let formData = new FormData()
        formData.append('file', file)


        if(key === 'photo'){
          let res = await this.uploadFileSingle({filepath: 'invent_item', data: formData})

          if(res?.data?.status === true){
            this.form.photo = res.data.data.filepath
          }
        } else if(key === 'file'){
          let res = await this.uploadFileSingle({filepath: 'invent_item', data: formData})

          if(res?.data?.status === true){
            this.form.file = res.data.data.filepath
          }
        }
      },

      async fetchFleetTypes () {
        let res = await this.getFleetTypes()
        this.optionsFleetType = res?.data?.length > 0 ? res?.data : []
      },

      async getInventoryItemById(id){
        const res = await this.fetchInventoryItemById({id})

        if(res.status === 'success'){
          let data = res.data.data

          this.form = {
            ...res.data.data
          }
        }
      },

      async onSave(e) {
        e.preventDefault()
        this.saving = true

        let payload = {
          ...this.form,
        }

        if(payload.vehicleType.length > 0){
          payload.vehicleType = payload.vehicleType.map(val => val.id)
        } else {
          this.saving = false
          return this.$swal(`Required.`, 'Select For Vehicle Type, at least one!', 'warning')
        }

        let formData = new FormData()
        // Looping through the object and appending key-value pairs to the FormData
        for (let key in payload) {
          if (payload.hasOwnProperty(key)) {
            formData.append(key, payload[key]);
          }
        }

        let res = null
        if(this.editId){
          res = await this.updateInventoryItem({data: payload, id: Number(this.editId)})
        } else {
          res = await this.createInventoryItem({data: payload})
        }

        if([500, 404, 403, 422].includes(res.status)){
          this.saving = false
          this.$swal(`Oops.`, res?.message ?? res?.data?.message ?? 'Something went wrong!', 'error')
          return
        }

        if(res?.data?.status === true){
          this.saving = false
          this.refreshData()
          this.$swal(`Success!`, `Create Inventory Item Success.`, 'success')
        } else {
          this.saving = false
          this.$swal(`Oops.`, res?.data?.message, 'error')
        }

        this.saving = false
      }
    }
  }
</script>