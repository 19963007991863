<template>
  <b-container fluid>
    <!-- Tab -->
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items
                  class="col-md-6 p-0"
                  :active="true"
                  href="#email"
                  title="EMAIL"
                />
                <tab-nav-items
                  class="col-md-6 p-0"
                  :active="false"
                  href="#whatsapp"
                  title="WHATSAPP"
                />
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <div id="pills-tabContent-2" class="tab-content">
          <!-- EMAIL CONTENT -->
          <tab-content-item :active="true" id="email">
            <TemplateEmail />
          </tab-content-item>

          <!-- WHATSAPP CONTENT -->
          <tab-content-item :active="false" id="whatsapp">
            <TemplateWhatsapp />
          </tab-content-item>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import _ from 'lodash'
import { saveAs } from 'file-saver'
import { portsActions, usersActions } from '@src/Utils/helper'
import TemplateEmail from './TemplateEmail.vue'
import TemplateWhatsapp from './TemplateWhatsapp.vue'

export default {
  name: 'EmailTemplate',
  data () {
    return {
    }
  },
  components:{
    TemplateEmail,
    TemplateWhatsapp
  },
  mounted () {},
  methods: {
  }
}
</script>
