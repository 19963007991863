var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-input', {
    staticClass: "mb-3",
    attrs: {
      "placeholder": "Search..."
    },
    model: {
      value: _vm.keyword,
      callback: function callback($$v) {
        _vm.keyword = $$v;
      },
      expression: "keyword"
    }
  })], 1), _c('b-col', {
    staticClass: "mt-4 m-auto",
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": ""
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Recommendations")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-recommendations",
            modifiers: {
              "collapse-recommendations": true
            }
          }],
          staticClass: "text-primary float-right",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-recommendations",
            "visible": ""
          }
        }, [_c('table', {
          staticClass: "table table-bordered"
        }, [_c('thead', [_c('tr', {
          staticClass: "bg-warning"
        }, [_c('th', {}, [_vm._v("Fleet")]), _c('th', {}, [_vm._v("Problem")]), _c('th', {}, [_vm._v("Root Cause")]), _c('th', {}, [_vm._v("Vessel Case")]), _c('th', {}, [_vm._v("Recommendation")]), _c('th', {}, [_vm._v("Status")])])]), _c('tbody', [_vm.recommendations.length === 0 ? _c('tr', [_c('td', {
          attrs: {
            "colspan": "6"
          }
        }, [_vm._v("No Data or Search not found")])]) : _vm._e(), _vm._l(_vm.recommendations, function (list, index) {
          return [list.data.length > 0 ? _c('tr', {
            key: index
          }, [_c('td', {
            attrs: {
              "rowspan": Number(list.data.length) + 1
            }
          }, [_vm._v(" " + _vm._s(list.vehicle ? list.vehicle.name : '-') + " ")])]) : _c('tr', {
            key: index
          }, [_c('td', {
            attrs: {
              "rowspan": Number(list.data.length) + 1
            }
          }, [_vm._v(" " + _vm._s(list.vehicle ? list.vehicle.name : '-') + " ")]), _c('td', [_vm._v("N/A")]), _c('td', [_vm._v("N/A")]), _c('td', [_vm._v("N/A")]), _c('td', [_vm._v("N/A")]), _c('td', [_vm._v("N/A")])]), _vm._l(list.data, function (listData, indexData) {
            return _c('tr', [_c('td', [_vm._v(" " + _vm._s(listData.problem) + " ")]), _c('td', [_vm._v(" " + _vm._s(listData.root_cause) + " ")]), _c('td', [_vm._v(" " + _vm._s(listData.vessel_case) + " ")]), _c('td', [_vm._v(" " + _vm._s(listData.recommendation) + " ")]), _c('td', [_vm._v(" " + _vm._s(listData.status) + " "), _vm.showActionButtons ? _c('p', [_c('b-button', {
              attrs: {
                "variant": "dark",
                "size": "sm"
              },
              on: {
                "click": function click($event) {
                  return _vm.onCreateEvaluation(listData.form_id);
                }
              }
            }, [_vm._v(" Add More Evaluation ")])], 1) : _vm._e()])]);
          })];
        })], 2)])])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }