<template>
  <b-row class="m-2">
    <b-col md="12">
      <div class="d-flex justify-content-center">
        <div class="d-flex overflow-auto">
          <draggable :list="kanbanData" :animation="200" group="kanbanData" class="d-flex column-height" @change="onMoveStage">
            <div
              v-for="column in kanbanData"
              :key="column.title"
              class="bg-light px-3 column-width column-height mr-4 rounded pb-4 mb-4"
            >
              <div class="d-flex justify-content-between align-items-center" v-if="!column.edit">
                <p class="text-primary font-weight-bold font-sans mt-3">{{column.title}}</p>
                <div class="d-flex align-items-center">
                  <b-badge v-if="column.is_end_stage" class="border border-primary text-primary text-uppercase mr-2" variant="none">End Stage</b-badge>
                  <b-badge v-if="column.is_deny" class="border border-primary text-warning text-uppercase" variant="none">Denied</b-badge>
                  <b-badge v-if="column.spal_process" class="border border-primary text-success text-uppercase" variant="none">Spal Process</b-badge>
                  <b-badge v-if="column.is_running" class="border border-primary text-primary text-uppercase" variant="none">Running</b-badge>
                </div>
                <b-dropdown
                  size="sm"
                  variant="link iq-border-radius-10"
                  toggle-class="text-decoration-none"
                  no-caret
                  class="mt-0"
                >
                  <template v-slot:button-content>
                    <span
                      class="dropdown-toggle p-0"
                      id="dropdownMenuButton5"
                      data-toggle="dropdown"
                    >
                      <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                    </span>
                  </template>
                  <b-dropdown-item variant="primary" @click="column.edit = true">
                    <i class="fas fa-pen mr-2"></i>Edit Stage
                  </b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item variant="warning" @click="onDenied(column.id)">
                    <i class="fas fa-exclamation mr-3"></i>Is Denied?
                  </b-dropdown-item>
                  <b-dropdown-item variant="success" @click="onSpalProcess(column.id)">
                    <i class="fas fa-check mr-2"></i>Spal Process?
                  </b-dropdown-item>
                  <b-dropdown-item variant="primary" @click="onRunningProcess(column.id)">
                    <i class="fas fa-check mr-2"></i>Is Running?
                  </b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item variant="danger" @click="onRemoveList(column)">
                    <i class="fas fa-ban mr-2"></i>Remove List
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div v-else class="mt-3">
                <b-form-input placeholder="Edit title..." :value="column.title" name="title"></b-form-input>
                <div class="d-flex align-items-center mt-2">
                  <b-button variant="primary" class="mr-2" @click="onEditTitle(column.id, column.title); column.edit = false">Edit title</b-button>
                  <b-button variant="danger" @click="column.edit = false">
                    <i class="fa fa-ban" style="cursor: pointer;" ></i>
                  </b-button>
                </div>
              </div>
              <!-- Draggable component comes from vuedraggable. It provides drag & drop functionality -->
              <draggable :list="column.tasks" :animation="200" ghost-class="ghost-card" group="tasks" @change="onMove">
                <!-- Each element from here will be draggable and animated. Note :key is very important here to be unique both for draggable and animations to be smooth & consistent. -->
                <task-card
                  v-for="(task) in column.tasks"
                  :key="task.id"
                  :task="task"
                  :open-slide-detail-project-contract="openSlideDetailProjectContract" 
                  :openSlideFormAddPlan="openSlideFormAddPlan" 
                  :openSlideSpalProcess="openSlideSpalProcess" 
                  :on-change-prioritize="onChangePrioritize" 
                  :on-delete-project-contract="onDeleteProjectContract" 
                  :open-pfa-slide="openPfaSlide" 
                  :open-project-form-slide="openProjectFormSlide" 
                  :on-save-approval="onSaveApproval" 
                  class="mt-3 cursor-move"
                ></task-card>
                <!-- </transition-group> -->             
              </draggable>
            </div>
          </draggable>
          <div class="column-width" style="cursor: pointer;" @click="isAddAnotherList" v-if="!isAddList && kanbanData.length > 0">
            <div class="d-flex justify-content-between align-items-center bg-primary px-3 mr-4 rounded">
              <p class="font-weight-bold font-sans mt-3">Add Another List</p>
              <i class="fa fa-plus"></i>
            </div>
          </div>

          <div class="column-width" v-else-if="isAddList">
            <div class="mr-4 rounded bg-light px-3 py-3 ">
              <b-form-input placeholder="Enter list title..." v-model="columnTitle" name="columnTitle"></b-form-input>
              <div class="d-flex align-items-center mt-2">
                <b-button variant="primary" class="mr-2" @click="addAnotherList">Add list</b-button>
                <b-button variant="danger" @click="isAddAnotherList">
                  <i class="fa fa-ban" style="cursor: pointer;" ></i>
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import draggable from "vuedraggable";
import TaskCard from './TaskCard.vue'

export default {
  name:'Kanban',
  props:{
    kanbanData:{
      type:Array,
      default:[]
    },
    openSlideDetailProjectContract:{
      type:Function,
      default:() => {}
    },
    openSlideSpalProcess:{
      type:Function,
      default:() => {}
    },
    onAddList:{
      type:Function,
      default:() => {}
    },
    onCardMove:{
      type:Function,
      default:() => {}
    },
    onRemoveList:{
      type:Function,
      default:() => {}
    },
    onMoveSetEndStage:{
      type:Function,
      default:() => {}
    },
    onChangePrioritize:{
      type:Function,
      default:() => {}
    }, 
    onDeleteProjectContract:{
      type:Function,
      default:() => {}
    }, 
    onEditTitle:{
      type:Function,
      default:() => {}
    }, 
    onDenied:{
      type:Function,
      default:() => {}
    },  
    onSpalProcess:{
      type:Function,
      default:() => {}
    }, 
    onRunningProcess:{
      type:Function,
      default:() => {}
    }, 
    openPfaSlide:{
      type:Function,
      default:() => {}
    }, 
    openProjectFormSlide:{
      type:Function,
      default:() => {}
    }, 
    openSlideFormAddPlan:{
      type:Function,
      default:() => {}
    },
    onSaveApproval:{
      type:Function,
      default:() => {}
    },
  },
  data() {
    return {
      columnTitle:'',
      isAddList:false
    };
  },
  components: {
    TaskCard,
    draggable
  },
  methods:{
    onMove: function(){
      console.log('Moved Card')
      this.onCardMove()
    },
    onMoveStage(){
      this.onMoveSetEndStage()
    },
    async addAnotherList(){
      if(!this.columnTitle) return

      this.onAddList(this.columnTitle)

      this.columnTitle = ''
      this.isAddList = !this.isAddList
    },
    isAddAnotherList(){
      this.isAddList = !this.isAddList
    },
    updateParentData() {
      this.$emit('update:data', this.kanbanData);
    },
  }
}
</script>

<style>
.column-width {
  min-width: 320px;
  width: 320px;
}
.column-height {
  min-height: 500px;
}
.ghost-card {
  opacity: 0.5;
  background: #F7FAFC;
  border: 1px solid #4299e1;
}
</style>
