<template>
  <b-container fluid>
    <!-- Top Section !-->
    <b-row v-if="!editMode && !isAddForChild">
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Add Fleet</h4>
          </template>
          <template v-slot:headerAction>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb" style="background:none;">
                <li class="breadcrumb-item"><a href="#"><i class="ri-home-4-line mr-1 float-left"></i>Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Add Fleet</li>
              </ol>
            </nav>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Top Section !-->
    <form @submit.prevent="saveFleet()" @reset="editMode || isAddForChild ? cancelFromParent() : initFormFleet()" v-if="fleetPermission">
      <b-row>
        <b-col lg="12" v-if="!editMode && !isAddForChild">
          <iq-card body-class="p-0">
            <template v-slot:body>
              <div class="iq-edit-list">
                <tab-nav :pills="true" class="iq-edit-profile d-flex">
                  <tab-nav-items class="col-md-12 p-0" :active="true" href="#fleet-information" title="Fleet Information" />
                </tab-nav>
              </div>
            </template>
          </iq-card>
        </b-col>
        <b-col lg="12">
          <div class="iq-edit-list-data">
            <div id="pills-tabContent-2" class="tab-content">
              <tab-content-item :active="true" id="fleet-information" >
                <iq-card>
                  <template v-slot:headerTitle>
                    <h4 class="card-title">Fleet Information</h4>
                  </template>
                  <template v-slot:body>
                    <nav aria-label="breadcrumb ">
                      <ol class="breadcrumb bg-primary">
                        <li class="breadcrumb-item active text-white" aria-current="page">GENERAL INFORMATION</li>
                      </ol>
                    </nav>
                    <b-row align-v="center" class="mt-3">
                      <b-col md="12" class="text-center mb-4">
                        <template v-for="(item,index) in fleetCategory">
                          <b-form-radio v-model="formFleet.vehicleTypeId" class="custom-radio-color-checked" inline :color="item.color" name="color" :key="index" :value="item.value" :disabled="item.disabled">{{ item.label }}</b-form-radio>
                        </template>
                      </b-col>
                      <b-form-group class="col-md-3" label="Fleet *" label-for="fleet_name">
                          <b-form-input v-model="formFleet.name" type="text"></b-form-input>
                      </b-form-group>
                      <b-form-group class="col-md-3" label="Call Sign *" label-for="fleet_code" v-if="formFleet.vehicleTypeId !== 2">
                          <b-form-input v-model="formFleet.code" type="text"></b-form-input>
                      </b-form-group>
                      <b-form-group class="col-md-3" label="Company *" label-for="company_name">
                          <b-form-select plain v-model="formFleet.companyId" :options="CompanyName" :reduce="CompanyName => CompanyName.value" size="lg">
                            <template v-slot:first>
                              <b-form-select-option :value="null">Select Company</b-form-select-option>
                            </template>
                          </b-form-select>
                      </b-form-group>
                      <b-form-group class="col-md-3" label="Ownership *" label-for="contract">
                          <b-form-select plain v-model="formFleet.ownership" :options="Contract" size="lg">
                            <template v-slot:first>
                              <b-form-select-option :value="null">Select Contract</b-form-select-option>
                            </template>
                          </b-form-select>
                      </b-form-group>
                      <b-form-group class="col-md-2" label="Technical Module" label-for="technical_module">
                          <template v-for="(item,index) in technical">
                            <b-form-radio inline v-model="formFleet.technical" :name="item.name" :key="index" :value="item.value" :disabled="item.disabled">{{ item.label }}</b-form-radio>
                          </template>
                      </b-form-group>
                      <b-form-group class="col-md-2" label="Latitude" label-for="id_gps"  v-if="formFleet.vehicleTypeId !== 2">
                        <b-form-input type="text" v-model="formFleet.latitude"></b-form-input>
                      </b-form-group>
                      <b-form-group class="col-md-2"  label="Longitude" label-for="id_gps"  v-if="formFleet.vehicleTypeId !== 2">
                        <b-form-input type="text" v-model="formFleet.longitude"></b-form-input>
                      </b-form-group>
                      <b-form-group class="col-md-3" label="Flag *" label-for="fleet_name">
                        <v-select
                          label="name"
                          :options="countries"
                          :reduce="(type) => type.name"
                          v-model="formFleet.flag"
                        ></v-select>
                      </b-form-group>
                      <b-form-group class="col-md-3" label="Year Build & Country" label-for="fleet_code">
                        <b-form-input type="text" v-model="formFleet.yearBuildCountry"></b-form-input>
                      </b-form-group>
                      <b-form-group class="col-md-6" label="Picture of Fleet" label-for="fleet_code">
                        <b-form-file
                        id="fileSupport"
                        placeholder="Choose a file"
                        accept=".jpg, .png, .gif, .jpeg, .svg"
                        :disabled="loading_upload_file"
                        @change="uploadFilePhoto"></b-form-file>
                        <p class="mt-2 text-danger" v-if="loading_upload_file"
                        >Please wait, uploading file is in process...</p
                        >
                        <span v-if="editMode">{{formFleet.img}}</span>
                      </b-form-group>
                      <b-form-group class="col-md-6" label="ID GPS" label-for="id_gps"  v-if="formFleet.vehicleTypeId !== 2">
                          <b-form-input v-model="formFleet.idGps" type="number"></b-form-input>
                      </b-form-group>
<!--                        <b-form-group class="col-sm-3" label="Complete Date" label-for="delivery_date">
                        <b-form-input v-model="formFleet.deliveryDate"  type="date" id="deliveryDate"></b-form-input>
                        <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                      </b-form-group>-->
                      <b-form-group class="col-sm-12" label="Class" label-for="classification">
                        <b-form-textarea v-model="formFleet.classification" name="Classification" style="line-height: 22px;" rows="5">
                        </b-form-textarea>
                      </b-form-group>
<!--                        <b-form-group class="col-sm-6" label="Basic Function" label-for="basic_function">
                        <b-form-textarea v-model="formFleet.basicFunction" name="Basic Function" style="line-height: 22px;" rows="5">
                        </b-form-textarea>
                      </b-form-group>-->
                    </b-row>
                    <nav aria-label="breadcrumb ">
                      <ol class="breadcrumb bg-primary">
                        <li class="breadcrumb-item active text-white" aria-current="page">DIMENSION</li>
                      </ol>
                    </nav>
                    <b-row align-v="center" class="mt-3 mb-3">
                      <b-col md="12">
                        <table class="table mb-0 table-borderless table-box-shadow">
                          <thead>
                          <tr>
                            <th width="50%">Dimensions</th>
<!--                              <th width="25%">Feet</th>-->
                            <th width="25%">Meters</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr>
                            <td><h6>Length</h6></td>
<!--                              <td><div class="input-group">
                              <b-form-input v-model="formFleet.dimension.length.feet" type="number"></b-form-input>
                              <div class="input-group-prepend">
                                <span class="input-group-text">Ft</span>
                              </div>
                            </div></td>-->
                            <td><div class="input-group">
                              <b-form-input v-model="formFleet.dimension.length.meter" type="text"></b-form-input>
                              <div class="input-group-prepend">
                                <span class="input-group-text">M</span>
                              </div>
                            </div></td>
                          </tr>
                          <tr>
                            <td><h6>Width</h6></td>
<!--                              <td><div class="input-group">
                              <b-form-input v-model="formFleet.dimension.width.feet" type="number"></b-form-input>
                              <div class="input-group-prepend">
                                <span class="input-group-text">Ft</span>
                              </div>
                            </div></td>-->
                            <td><div class="input-group">
                              <b-form-input v-model="formFleet.dimension.width.meter" type="text"></b-form-input>
                              <div class="input-group-prepend">
                                <span class="input-group-text">M</span>
                              </div>
                            </div></td>
                          </tr>
                          <tr>
                            <td><h6>Depth</h6></td>
<!--                              <td><div class="input-group">
                              <b-form-input v-model="formFleet.dimension.depth.feet" type="number"></b-form-input>
                              <div class="input-group-prepend">
                                <span class="input-group-text">Ft</span>
                              </div>
                            </div></td>-->
                            <td><div class="input-group">
                              <b-form-input v-model="formFleet.dimension.depth.meter" type="text"></b-form-input>
                              <div class="input-group-prepend">
                                <span class="input-group-text">M</span>
                              </div>
                            </div></td>
                          </tr>
                          <tr>
                            <td><h6>Draft</h6></td>
<!--                              <td><div class="input-group">
                              <b-form-input v-model="formFleet.dimension.draft.feet" type="number"></b-form-input>
                              <div class="input-group-prepend">
                                <span class="input-group-text">Ft</span>
                              </div>
                            </div></td>-->
                            <td><div class="input-group">
                              <b-form-input v-model="formFleet.dimension.draft.meter" type="text"></b-form-input>
                              <div class="input-group-prepend">
                                <span class="input-group-text">M</span>
                              </div>
                            </div></td>
                          </tr>
                          <!-- <tr>
                            <td><h6>Speed</h6></td>
                            <td colspan="2"><div class="input-group">
                              <b-form-input v-model="formFleet.dimension.speed" type="number"></b-form-input>
                              <div class="input-group-prepend">
                                <span class="input-group-text">Knots</span>
                              </div>
                            </div></td>
                          </tr>-->
                          </tbody>
                        </table>
                      </b-col>
                    </b-row>
                    <nav aria-label="breadcrumb "  v-if="![2, 5].includes(formFleet.vehicleTypeId)" class="mt-3">
                      <ol class="breadcrumb bg-primary">
                        <li class="breadcrumb-item active text-white" aria-current="page">*POWER</li>
                      </ol>
                    </nav>
                    <b-row align-v="center" class="mt-3 mb-3"  v-if="![2, 5].includes(formFleet.vehicleTypeId)">
                      <b-col md="12" v-if="formPower.typeOfPower.length > 0">
                        <table class="table mb-0 table-borderless table-box-shadow">
                          <thead>
                          <tr>
                            <th width="35%">Type</th>
                            <th width="35%">Series</th>
                            <th width="20%" v-if="showActualRunningHoursField">Actual Running Hours</th>
                            <th width="10%" v-if="showActualRunningHoursField">#</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(power, index) in formPower.typeOfPower" :key="index">
                            <td>
                              <v-select label="text" v-model="power.powerTypeId" :options="powerOptions" :reduce="powerOptions => powerOptions.value"></v-select>
                            </td>
                            <td>
                              <v-select label="text" v-model="power.powerSeriesId" :options="main_engine" :reduce="main_engine => main_engine.value"></v-select>
                            </td>
                            <td v-if="showActualRunningHoursField">
                              <div class="input-group">
                                <b-form-input v-model="power.initialRunningHours" type="number"></b-form-input>
                                <div class="input-group-prepend">
                                  <span class="input-group-text">H</span>
                                </div>
                              </div>
                            </td>
                            <td v-if="showActualRunningHoursField">
                              <template v-if="formPower.typeOfPower.length > 1">
                                <b-button @click.prevent="removePower(index)" size="sm" variant="danger" class="mb-3 mr-1"><i class="fa fa-trash"></i></b-button>
                              </template>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                        <b-button variant="warning" block class="mt-2 mb-3" @click.prevent="addPower()"><i class="fa fa-plus"></i> Add More Type of Power</b-button>
                      </b-col>
                    </b-row>
                    <nav aria-label="breadcrumb "  v-if="![2, 5].includes(formFleet.vehicleTypeId)">
                      <ol class="breadcrumb bg-primary">
                        <li class="breadcrumb-item active text-white" aria-current="page">*CAPACITY</li>
                      </ol>
                    </nav>
                    <b-row align-v="center" class="mt-3 mb-3"  v-if="![2, 5].includes(formFleet.vehicleTypeId)">
                      <b-col md="12" v-if="formROBType.typeOfROB.length > 0">
                        <table class="table mb-0 table-borderless table-box-shadow">
                          <thead>
                          <tr>
                            <th width="35%">Type</th>
                            <th width="35%">Series</th>
                            <th width="20%" v-if="showRobsField">Capacity</th>
                            <th width="10%" v-if="showRobsField">#</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(rob, index) in formROBType.typeOfROB" :key="index">
                            <td>
                              <v-select label="text" v-model="rob.robTypeId" :options="ROBOptions" :reduce="ROBOptions => ROBOptions.value"></v-select>
                            </td>
                            <td>
                              <v-select label="text" v-model="rob.robSeriesId" :options="seriesROBOptions" :reduce="seriesROBOptions => seriesROBOptions.value"></v-select>
                            </td>
                            <td v-if="showRobsField">
                              <div class="input-group">
                                <b-form-input v-model="rob.initialRob" type="number"></b-form-input>
                                <div class="input-group-prepend">
                                  <span class="input-group-text">H</span>
                                </div>
                              </div>
                            </td>
                            <td v-if="showRobsField">
                              <template v-if="formROBType.typeOfROB.length > 1">
                                <b-button @click.prevent="removeROBType(index)" size="sm" variant="danger" class="mb-3 mr-1"><i class="fa fa-trash"></i></b-button>
                              </template>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                        <b-button variant="warning" block class="mt-2 mb-3" @click.prevent="addROBType()"><i class="fa fa-plus"></i> Add More Type</b-button>
                      </b-col>
                    </b-row>
                    <nav aria-label="breadcrumb ">
                      <ol class="breadcrumb bg-primary">
                        <li class="breadcrumb-item active text-white" aria-current="page">FILE / DOCUMENT SUPPORT FOR THIS FLEET/EQUIPMENT</li>
                      </ol>
                    </nav>
                    <b-row align-v="center" class="mt-3 mb-3">
                      <b-col cols="12" md="12" v-if="formFileSupportList.fileSupport.length > 0">
                        <table class="table mb-0 table-borderless table-box-shadow">
                          <thead>
                          <tr>
                            <th width="5%">No.</th>
                            <!-- <th width="50%">Name of File / Document</th> -->
                            <th width="35%">File / Document</th>
                            <th width="10%">#</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(fileSupport, index) in formFileSupportList.fileSupport" :key="index">
                            <td>{{index+1}}</td>
                            <!-- <td><b-form-input type="text" v-model="fileSupport.fileName" placeholder="Name of File / Document Support"></b-form-input></td> -->
                            <td>
                              <b-form-file
                              :id="'fileDocs'+index"
                              placeholder="Choose a file"
                              accept=".pdf, .docx"
                              :disabled="formFileSupportList.fileSupport[index].isUploading"
                              @change="uploadFileDoc($event,index)"></b-form-file>
                              <p class="mt-2 text-danger" v-if="formFileSupportList.fileSupport[index].isUploading"
                              >Please wait, uploading file is in process...</p
                              >
                              <span v-if="editMode">{{fileSupport.fileName}}</span>
                              </td>
                            <td>
                              <template v-if="formFileSupportList.fileSupport.length > 1">
                                <b-button @click.prevent="removeFileSupportList(index)" size="sm" variant="danger" class="mb-3 mr-1"><i class="fa fa-trash"></i></b-button>
                              </template>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                        <b-button variant="warning" block class="mt-2 mb-3" @click.prevent="addFileSupportList()"><i class="fa fa-plus"></i> Add More File Support</b-button>
                      </b-col>
                    </b-row>

                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb bg-primary">
                        <li class="breadcrumb-item active text-white" aria-current="page">ADD USERS</li>
                      </ol>
                    </nav>
                    <hr />
                    <b-row class="mb-4">
                      <b-col md="6">
                        <h5 class="mb-3">Filter User</h5>
                      </b-col>
                      <b-col md="4" class="text-right">
                        <b-form-input
                          type="text"
                          placeholder="Search fleets..."
                          v-model="users_params.search"
                          @input="fetchUsers"
                        ></b-form-input>
                      </b-col>
                      <b-col md="2" class="text-right">
                        <b-form-checkbox
                          name="check-button"
                          switch
                          inline
                          v-model="select_all_users"
                        >
                          Select All
                        </b-form-checkbox>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <div v-if="loading_user" class="text-center my-5">
                          <b-spinner type="grow" label="Spinning"></b-spinner>
                        </div>
                        <template v-else>
                          <small class="text-muted"
                            >click images for checked / unchecked</small
                          >
                          <b-row v-if="usersData.length > 0">
                            <b-col
                              md="3"
                              class="text-center py-5 border-top"
                              v-for="(user, i) in usersData"
                              :key="`user-${i}`"
                            >
                              <div class="custom-control custom-checkbox image-checkbox">
                                <input
                                  :id="`ck-user-${i}`"
                                  type="checkbox"
                                  class="custom-control-input"
                                  :value="user.id"
                                  v-model="checkedUsers"
                                />
                                <label class="custom-control-label" :for="`ck-user-${i}`">
                                  <img
                                    :src="
                                      user.userDetail.photo
                                        | checkPhoto(
                                          require('@src/assets/images/user/09.jpg')
                                        )
                                    "
                                    alt="#"
                                    style="width: 100px; height: 100px; background-color:#fff"
                                  />
                                </label>
                                <div class="text-center mt-3">
                                  <h4> {{ user.userDetail.name }} </h4>
                                  <strong class="mb-0">{{ user.username }}</strong>
                                  <p class="mb-0">{{ user.role.roles }}</p>
                                  <span class="text-primary">{{ user.company.location }}</span>
                                </div>
                                <div class="text-center mt-2">
                                  <p class="mb-0">{{ user.company.company }}</p>
                                  <p class="mb-0">
                                    <b-badge :variant="user.active ? 'warning' : 'danger'" class="ml-2" >
                                      {{ user.active ? 'Active' : 'Not Active' }}
                                    </b-badge
                                    >
                                  </p>
                                </div>
                              </div>
                            </b-col>
                          </b-row>
                          <div v-else class="text-center my-5">
                            <p class="mb-0 text-muted">No Users data found.</p>
                          </div>
                        </template>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12" class="text-right">
                        <b-card-text><small class="text-muted">Sign * : <b-badge variant="warning">Mandatory (Must be Input)</b-badge></small></b-card-text>
                        <b-button type="submit" variant="primary" class="mr-2 text-right">Submit</b-button>
                        <b-button type="reset" variant="none" class="iq-bg-danger text-right">Cancel</b-button>
                      </b-col>
                    </b-row>
                  </template>
                </iq-card>
              </tab-content-item>
            </div>
          </div>
        </b-col>
      </b-row>
    </form>
    <b-alert :show="true" variant=" " class="text-white bg-warning" v-else>
      <div class="iq-alert-icon">
          <i class="ri-alert-line"></i>
      </div>
      <div class="iq-alert-text">You dont have permission to add fleet.</div>
    </b-alert>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { fleetsActions, companiesActions, powerActions, robActions, filesActions, usersActions } from '@src/Utils/helper'
import moment from 'moment'
import listCrewOptionsData from '@src/core/mock/data/ListCrewOptionsData'

export default {
  name: 'ProfileEdit',
  mounted () {
    xray.index()
  },
  computed: {
    'showActualRunningHoursField' () {
      if(this.formData && this.formData.powers[0]) {
        return !this.formData.powers[0].haveHistory
      }

      return true
    },
    'showRobsField' () {
      if(this.formData && this.formData.robs[0]) {
        return !this.formData.robs[0].haveHistory
      }

      return true
    }
  },
  data () {
    return {
      ...listCrewOptionsData,
      selectedDock: 1,
      type_dock: [
        { value: '1', text: 'Intermediate Survey' },
        { value: '2', text: 'Special Survey' }
      ],

      editMode: false,
      selected: ['select-1'],
      fleetCategory: [],
      technicalActive: {
        technical_module: true
      },
      formPower: {
        _saving: false,
        typeOfPower: []
      },
      loading_upload_file: false,
      powerOptions: [],
      ROBOptions: [],
      seriesROBOptions: [],
      formROBType: {
        _saving: false,
        typeOfROB: []
      },
      technical: [
        {
          name: 'technical_module',
          label: 'Yes',
          value: true,
          disabled: false
        },
        {
          name: 'technical_module',
          label: 'No',
          value: false,
          disabled: false
        }
      ],
      main_engine: [],
      CompanyName: [],
      Contract: [
        { value: 'OWNED', text: 'Owned by Company' },
        { value: 'CHARTERED', text: 'Chartered' }
      ],
      formFileSupportList: {
        _saving: false,
        fileSupport: []
      },
      formFleet: {
        name: '',
        code: '',
        horsePower: null,
        idGps: '',
        deliveryDate: '',
        classification: '',
        basicFunction: '',
        ownership: '',
        companyId: null,
        vehicleTypeId: 1,
        technical: false,
        latitude: null,
        longitude: null,
        flag: '',
        yearBuildCountry: '',
        img: null,
        dimension: {
          length: {
            feet: '',
            meter: ''
          },
          width: {
            feet: '',
            meter: ''
          },
          depth: {
            feet: '',
            meter: ''
          },
          draft: {
            feet: '',
            meter: ''
          },
          speed: ''
        },
        powers: [],
        robs: [],
        docs: []
      },
      select_all_users:false,
      usersData:[],
      checkedUsers:[],
      loading_user:false,
      users_params: {
        search:'',
      },
      lsProfile: JSON.parse(localStorage.getItem('profile'))
    }
  },
  computed:{
    fleetPermission () {
      let add_permission = this.lsProfile.menu.find(menu => menu.menu === 'Apps').child.find(menu => menu.menu === "Master & Setting").child.find(menu => menu.menu === "Add Fleet").add

      return add_permission
    },
  },
  props: {
    formData: {
      default: null,
      type: Object,
      required: false
    },
    isAddForChild: {
      default: false,
      type: Boolean,
      required: false
    }
  },
  async mounted () {
    if (this.formData) {
      this.editMode = true
    }
    await this.getVehicleTypeData()
    await this.getCompanyListData()
    await this.getPowerData()
    await this.getRobData()
    await this.fetchUsers()
    this.initFormFleet()
  },
  watch: {
    select_all_users: {
      deep: true,
      handler: function (checked) {
        this.checkedUsers = []

        if (checked) {
          this.usersData.forEach((f) => this.checkedUsers.push(f.id))
        }
      },
    },
  },
  created () {
    this.fetchUsers = _.debounce(this.fetchUsers, 1000)
  },
  methods: {
    ...fleetsActions,
    ...usersActions,
    ...companiesActions,
    ...powerActions,
    ...robActions,
    ...filesActions,
    addPower () {
      this.formPower.typeOfPower.push(
        {
          powerTypeId: null,
          powerSeriesId: null,
          actualRunningHours: null,
          initialRunningHours: null,
        }
      )
    },
    removePower (index) {
      this.formPower.typeOfPower.splice(index, 1)
    },
    addROBType () {
      let dataAdd = {
        robTypeId: null,
        robSeriesId: null,
        actualRob: null,
        initialRob: null,
      }
      if (this.editMode) delete dataAdd.actualRob
      this.formROBType.typeOfROB.push(dataAdd)
    },
    removeROBType (index) {
      this.formROBType.typeOfROB.splice(index, 1)
    },
    addFileSupportList () {
      this.formFileSupportList.fileSupport.push(
        {
          fileName: '',
          isUploading: false
        }
      )
    },
    removeFileSupportList (index) {
      this.formFileSupportList.fileSupport.splice(index, 1)
    },
    async fetchUsers() {
      this.loading_user = true
      this.usersData = []
      this.checkedUsers = []
      let params = {
        page: 1,
        perPage: 1000,
        active:true,
        search:this.users_params.search
      }
      if(!params.search) delete params.search

      let res = await this.getUsers(params)

      if (res.data.status == 'success' || res.data.status == true) {
        if (res.data.data && res.data.data.length > 0) {
          this.usersData = res.data.data
          if(this.formData?.user && this.formData?.user?.length > 0){
            this.usersData.map(val => {
              this.formData.user.map(obj => {
                if(val.id === obj.id) this.checkedUsers.push(val.id)
              })
            })
          }
       
          this.loading_user = false
        }
      }

      this.loading_user = false
    },
    async getVehicleTypeData () {
      const res = await this.getFleetTypes()

      if (res.status == 'success' && res.data && res.data.length > 0) {
        res.data.forEach(element => {
          if (element.active) {
            this.fleetCategory.push({
              label: element.name,
              color: element.name === 'Tugboat' ? 'success' : (element.name === 'Barge' ? 'warning' : ' primary'),
              value: element.id
            })
          }
        })
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    async getCompanyListData () {
      const res = await this.getCompanies()

      if (res.status == 'success' && res.data && res.data.length > 0) {
        res.data.forEach(element => {
          this.CompanyName.push({
            // text: `${element.company} - ${element.location}`,
            text: `${element.company}`,
            value: element.id
          })
        })
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    async getPowerData () {
      let paramsQ = {
        perPage: 1000,
        page: 1,
        search: '',
        active: true
      }
      const res = await this.getPowerTypes({ params: paramsQ })
      if (res.status == 'success') {
        if (res.data.data && res.data.data.length > 0) {
          res.data.data.forEach(element => {
            this.powerOptions.push({
              text: element.name,
              value: element.id
            })
          })
        }
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }

      const res2 = await this.getPowerSeries({ params: paramsQ })

      if (res2.status == 'success') {
        if (res2.data.data && res2.data.data.length > 0) {
          res2.data.data.forEach(element => {
            this.main_engine.push({
              text: `${element.name} - ${element.model}`,
              value: element.id
            })
          })
        }
      } else {
        this.$swal(
          `Oops!`,
          res2.data,
          'error'
        )
      }
    },
    async getRobData () {
      let paramsQ = {
        perPage: 1000,
        page: 1,
        search: '',
        active: true
      }
      const res = await this.getRobTypes({ params: paramsQ })

      if (res.status == 'success') {
        if (res.data.data && res.data.data.length > 0) {
          res.data.data.forEach(element => {
            this.ROBOptions.push({
              text: element.name,
              value: element.id
            })
          })
        }
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }

      const res2 = await this.getRobSeries({ params: paramsQ })

      if (res2.status == 'success') {
        if (res2.data.data && res2.data.data.length > 0) {
          res2.data.data.forEach(element => {
            this.seriesROBOptions.push({
              text: `${element.series} - ${element.manufacturer}`,
              value: element.id
            })
          })
        }
      } else {
        this.$swal(
          `Oops!`,
          res2.data,
          'error'
        )
      }
    },

    async uploadFilePhoto (event) {
      this.loading_upload_file = true

      const files = [...event.target.files]
      let file = files[0]

      let formData = new FormData()
      formData.append('file', file)

      let res = await this.upload(formData)
      if (res.status == 'success') {
        this.loading_upload_file = false
        this.formFleet.img = res.data.fileName
      } else {
        this.loading_upload_file = false
        if (res.data.message != null) {
          this.$swal('Error', res.data.message, 'error')
        } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
        }
      }
    },
    async uploadFileDoc (event, i) {
      this.formFileSupportList.fileSupport[i].isUploading = true

      const files = [...event.target.files]
      let file = files[0]

      let formData = new FormData()
      formData.append('file', file)

      let res = await this.upload(formData)
      if (res.status == 'success') {
        this.formFileSupportList.fileSupport[i].isUploading = false
        this.formFileSupportList.fileSupport[i].fileName = res.data.fileName
      } else {
        this.formFileSupportList.fileSupport[i].isUploading = false
        if (res.data.message != null) {
          this.$swal('Error', res.data.message, 'error')
        } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
        }
      }
    },
    cancelFromParent () {
      this.$emit('childAction', true)
    },
    initFormFleet () {
      this.formFleet = {
        name: this.editMode ? this.formData.name : '',
        code: this.editMode ? this.formData.code : '',
        horsePower: this.editMode ? this.formData.horsePower : null,
        idGps: this.editMode ? this.formData.idGps : '',
        deliveryDate: this.editMode ? (this.formData.deliveryDate ? `${this.formData.deliveryDate.split('-')[2]}-${this.formData.deliveryDate.split('-')[1]}-${this.formData.deliveryDate.split('-')[0]}` : '') : '',
        classification: this.editMode ? this.formData.classification : '',
        basicFunction: this.editMode ? this.formData.basicFunction : '',
        ownership: this.editMode ? this.formData.ownership : '',
        companyId: this.editMode ? this.formData.company?.id || null : null,
        vehicleTypeId: this.editMode ? this.formData.vehicleType?.id || null : 1,
        technical: this.editMode ? this.formData.technical : false,
        latitude: this.editMode ? this.formData.latitude : null,
        longitude: this.editMode ? this.formData.longitude : null,
        flag: this.editMode ? this.formData.flag : '',
        yearBuildCountry: this.editMode ? this.formData.yearBuildCountry : '',
        img: this.editMode && this.formData.img ? this.formData.img.iconName : null,
        dimension: {
          length: {
            feet: this.editMode ? this.formData.dimension.length.feet : '',
            meter: this.editMode ? this.formData.dimension.length.meter : ''
          },
          width: {
            feet: this.editMode ? this.formData.dimension.width.feet : '',
            meter: this.editMode ? this.formData.dimension.width.meter : ''
          },
          depth: {
            feet: this.editMode ? this.formData.dimension.depth.feet : '',
            meter: this.editMode ? this.formData.dimension.depth.meter : ''
          },
          draft: {
            feet: this.editMode ? this.formData.dimension.draft.feet : '',
            meter: this.editMode ? this.formData.dimension.draft.meter : ''
          },
          speed: this.editMode ? this.formData.dimension.speed : ''
        },
        powers: [],
        robs: [],
        docs: []
      }
      this.technicalActive.technical_module = this.editMode ? this.formData.technical : true

      if (this.editMode && this.formData && this.formData.powers.length > 0) {
        this.formData.powers.forEach(el => {
          this.formPower.typeOfPower.push({
            id: el.id,
            powerTypeId: el.powerType ? el.powerType?.id : null,
            powerSeriesId: el.powerSeries ? el.powerSeries?.id : null,
            actualRunningHours: el.actualRunningHours,
            initialRunningHours: el.initialRunningHours,
          })
        })
      } else {
        this.formPower.typeOfPower = [
          {
            powerTypeId: null,
            powerSeriesId: null,
            actualRunningHours: null,
            initialRunningHours: null
          }
        ]
      }

      if (this.editMode && this.formData && this.formData.robs.length > 0) {
        this.formData.robs.forEach(el => {
          this.formROBType.typeOfROB.push({
            id: el.id,
            robTypeId: el.robType ? el.robType.id : null,
            robSeriesId: el.robSeries ? el.robSeries.id : null,
            actualRob: el.actualRob,
            initialRob: el.initialRob
          })
        })

      } else {
        let dataAdd = {
          robTypeId: null,
          robSeriesId: null,
          actualRob: null,
          initialRob: null,
        }
        if (this.editMode) delete dataAdd.actualRob
        this.formROBType.typeOfROB = [dataAdd]
      }

      if (this.editMode && this.formData && this.formData.docs.length > 0) {
        this.formData.docs.forEach(el => {
          this.formFileSupportList.fileSupport.push({
            id: el.id,
            fileName: el.fileName ? el.fileName : '',
            isUploading: false
          })
        })
      } else {
        this.formFileSupportList.fileSupport = [
          {
            fileName: '',
            isUploading: false
          }
        ]
      }
    },
    async saveFleet () {
      this.$swal.fire({
        title: 'Processing...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading()
        }
      })

      this.formFleet.horsePower = this.formFleet.horsePower ? Number(this.formFleet.horsePower) : null
      this.formFleet.companyId = this.formFleet.companyId ? Number(this.formFleet.companyId) : null
      this.formFleet.latitude = this.formFleet.latitude ? Number(this.formFleet.latitude) : null
      this.formFleet.longitude = this.formFleet.longitude ? Number(this.formFleet.longitude) : null
      this.formFleet.longitude = this.formFleet.longitude ? Number(this.formFleet.longitude) : null
      this.formPower.typeOfPower.forEach(el => {
        el.powerTypeId = el.powerTypeId ? Number(el.powerTypeId) : null
        el.powerSeriesId = el.powerSeriesId ? Number(el.powerSeriesId) : null
        el.actualRunningHours = el.actualRunningHours ? Number(el.actualRunningHours) : null
        el.initialRunningHours = el.initialRunningHours ? Number(el.initialRunningHours) : null
      })
      this.formFleet.powers = this.formPower.typeOfPower
      this.formROBType.typeOfROB.forEach(el => {
        el.robTypeId = el.robTypeId ? Number(el.robTypeId) : null
        el.robSeriesId = el.robSeriesId ? Number(el.robSeriesId) : null
        if (!this.editMode) {
          el.initialRob = el.initialRob ? Number(el.initialRob) : null
        }
      })
      this.formFleet.robs = this.formROBType.typeOfROB
      if (this.formFleet.vehicleTypeId === 2) {
        this.formFleet.powers = []
        this.formFleet.robs = []
      }
      if (this.formFileSupportList.fileSupport && this.formFileSupportList.fileSupport[0].fileName) {
        this.formFileSupportList.fileSupport.forEach(el => {
          if (this.editMode) {
            delete el.isUploading
            this.formFleet.docs.push(el)
          } else this.formFleet.docs.push({ fileName: el.fileName })
        })
      }
      this.formFleet.deliveryDate = this.formFleet.deliveryDate ? moment(this.formFleet.deliveryDate).format('DD-MM-YYYY') : ''
      this.formFleet.users = this.checkedUsers

      if (!this.editMode) {
        const res = await this.addFleet(this.formFleet)
        if (res.status == 'success') {
          this.$swal('Add Fleet Success!', res.data.message, 'success')
          this.$emit('save')
          this.initFormFleet()
          setTimeout(() => {
            this.$router.push({ name: 'dashboard.pms' })
          }, 300)
        } else {
          this.$swal(
            `Oops!`,
            res.data.message,
            'error'
          )
        }
      } else {
        const res = await this.updateFleet({ id: this.formData.id, payload: this.formFleet })
        if (res.status == 'success') {
          this.$swal('Update Fleet Success!', res.data.message, 'success')
          this.$emit('save')
          setTimeout(() => {
            this.cancelFromParent()
          }, 200)
        } else {
          this.$swal(
            `Oops!`,
            res.data.message,
            'error'
          )
        }
      }
    }
  }
}
</script>
