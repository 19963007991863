var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "btn-group"
  }, [_c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.collapse-filter",
      modifiers: {
        "collapse-filter": true
      }
    }],
    attrs: {
      "href": "#collapse-filter",
      "variant": "light"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-filter"
  }), _vm._v(" Filter ")]), _c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.upcoming-job",
      modifiers: {
        "upcoming-job": true
      }
    }],
    attrs: {
      "href": "#upcoming-job",
      "variant": "dark"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-info"
  }), _vm._v(" Jobs Maintenance Information "), _c('b-badge', {
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v("10")])], 1)], 1)]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-collapse', {
    staticClass: "mt-2",
    attrs: {
      "id": "collapse-filter"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Category:",
      "label-for": "kind-fleet"
    }
  }, [_vm._l(_vm.vehicleTypes, function (item, index) {
    return [_c('b-form-checkbox', {
      key: index,
      attrs: {
        "name": "check-button",
        "switch": "",
        "inline": ""
      },
      model: {
        value: item.checked,
        callback: function callback($$v) {
          _vm.$set(item, "checked", $$v);
        },
        expression: "item.checked"
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])];
  })], 2)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Cari"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "size": "xs"
    },
    model: {
      value: _vm.params.search,
      callback: function callback($$v) {
        _vm.$set(_vm.params, "search", $$v);
      },
      expression: "params.search"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "dateIssued",
      "label": "Category Ownership"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "options": _vm.categoryOwnership,
      "id": "gender"
    },
    model: {
      value: _vm.params.ownership,
      callback: function callback($$v) {
        _vm.$set(_vm.params, "ownership", $$v);
      },
      expression: "params.ownership"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Filter",
      "label-for": "button_search"
    }
  }, [_c('b-button', {
    staticClass: "mb-3 mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.fetchFleetActivities
    }
  }, [_c('i', {
    staticClass: "fa fa-filter"
  })])], 1)], 1)], 1)], 1)], 1), _c('b-collapse', {
    staticClass: "mt-2",
    attrs: {
      "id": "upcoming-job"
    }
  }, [_c('b-card', _vm._l(_vm.maintenance.data, function (vehicle, indexVehicle) {
    return _c('div', {
      key: indexVehicle,
      staticClass: "bg-white shadow rounded px-3 pt-3 pb-3 border border-white mb-3"
    }, [vehicle.jobs.length === 0 ? _c('div', [_vm._v("No Job")]) : _vm._e(), _vm._l(vehicle.jobs, function (job, indexJob) {
      return [_c('div', {
        key: indexJob,
        staticClass: "flex justify-between mb-2"
      }, [_c('b-btn', {
        directives: [{
          name: "b-toggle",
          rawName: "v-b-toggle",
          value: 'maintenance-job-' + indexVehicle + '-' + indexJob,
          expression: "\n                  'maintenance-job-' + indexVehicle + '-' + indexJob\n                "
        }],
        staticClass: "text-left",
        attrs: {
          "block": "",
          "href": "#",
          "variant": "secondary"
        }
      }, [_vm._v(" " + _vm._s(job.jobName) + " "), job.statusDetail ? _c('b-badge', {
        staticClass: "ml-2",
        attrs: {
          "variant": "danger"
        }
      }, [_vm._v(_vm._s(job.statusDetail))]) : _vm._e(), _c('span', {
        staticClass: "when-opened text-right pull-right"
      }, [_c('i', {
        staticClass: "fa fa-arrow-down"
      })]), _c('span', {
        staticClass: "when-closed text-right pull-right"
      }, [_c('i', {
        staticClass: "fa fa-arrow-up"
      })])], 1)], 1), _c('b-collapse', {
        attrs: {
          "id": 'maintenance-job-' + indexVehicle + '-' + indexJob,
          "role": "tabpanel"
        }
      }, [_c('b-row', [_c('b-col', {
        attrs: {
          "md": "12"
        }
      }, [_c('table', {
        staticClass: "table mb-0"
      }, [_c('thead', {
        staticClass: "text-center"
      }, [_c('tr', [_c('td', [_vm._v("Component Name")]), _c('td', [_vm._v("Frequency")]), _c('td', [_vm._v("Last Maintenance")]), _c('td', [_vm._v("Current")]), _c('td', [_vm._v("Due Date / Hours")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v(_vm._s(job.componentName))]), _c('td', [_vm._v(_vm._s(job.interval))]), _c('td', [_vm._v(_vm._s(job.lastMaintenanceDate))]), _c('td', [_vm._v(_vm._s(job.lastMaintenanceDate))]), _c('td', [_c('div', [_c('small', [_vm._v("Last Due #1 : " + _vm._s(job.dueDate))])])])])])])])], 1)], 1)];
    }), _c('b-row', {
      staticClass: "mt-3"
    }, [_c('b-col', {
      attrs: {
        "md": "6"
      }
    }, [_c('h5', [_vm._v(_vm._s(vehicle.group))])])], 1)], 2);
  }), 0), _c('b-modal', {
    attrs: {
      "id": "edit-fleet",
      "size": "xl",
      "no-close-on-backdrop": true,
      "header-bg-variant": _vm.modal.headerBgVariant,
      "header-text-variant": _vm.modal.headerTextVariant,
      "body-bg-variant": _vm.modal.bodyBgVariant,
      "body-text-variant": _vm.modal.bodyTextVariant,
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('h5', [_vm._v("Edit Your Fleet")]), _c('b-button', {
          attrs: {
            "size": "md",
            "variant": "outline-danger"
          },
          on: {
            "click": close
          }
        }, [_c('i', {
          staticClass: "fa fa-times"
        }), _vm._v(" Close ")])];
      }
    }])
  }, [_c('AddFleet', {
    attrs: {
      "formData": _vm.fleetDetail
    },
    on: {
      "childAction": function childAction($event) {
        return _vm.$bvModal.hide('edit-fleet');
      }
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }