<template>
   <b-row class="m-2">
    <b-col md="12" class="mb-2">
      <div class="d-flex w-100 align-items-center justify-content-end">
        <!-- <b-form-group label="Date Filter" label-for="date">
          <b-form-select  
            plain
            size="sm"
            placeholder="Select options..."
            v-model="dateOption"
            @change="convertDataIntoGanttChart()"
          >
            <option value="1">Monthly</option>
            <option value="2">Quarterly</option>
            <option value="3">Semesterly</option>
            <option value="4">Yearly</option>
          </b-form-select>
        </b-form-group> -->
        <b-form-group >
          <b-button variant="primary" class="mt-3" @click="onDisableImmutable" v-if="!isImmutable"><i class="fa fa-pen mr-1"></i> Edit Periode</b-button>
          <b-button variant="danger" class="mt-3" @click="onDisableImmutable" v-else><i class="fa fa-ban mr-1"></i>Cancel</b-button>
        </b-form-group>
      </div>
    </b-col>
    <b-col md="12" class="mb-4">
      <g-gantt-chart
        :chart-start="ganttChartData.chartStart"
        :chart-end="ganttChartData.chartEnd"
        :grid="ganttChartData.grid"
        :grid-size="ganttChartData.gridSize"
        :hide-timeaxis="ganttChartData.hideTimeaxis"
        :push-on-overlap="ganttChartData.pushOnOverlap"
        snap-back-on-overlap
        :precision="ganttChartData.precision"
        :is-magnetic="ganttChartData.isMagnetic"
        :row-label-width="ganttChartData.rowLabelWidth"
        :row-height="ganttChartData.rowHeight"
        :theme="theme"
        :width="ganttChartData.width"
        :height="ganttChartData.height"
        :allow-add="ganttChartData.allowAdd"
        @dragend-bar="onDragend($event)"
      >
        <g-gantt-row
          v-for="row in ganttChartData.rows"
          :key="row.label"
          :label="row.label"
          :row-style="row.style"
          :bars="row.bars"
        >
          <template #bar-label="{ bar }" >
            <div class="d-flex w-100 align-items-center justify-content-between px-2" v-b-tooltip.top="bar.label">
              <span class="text-truncate">{{ bar.label }}</span>
              <!-- <b-dropdown
                size="sm"
                variant="link iq-border-radius-10"
                toggle-class="text-decoration-none"
                id="dropdown1" class="mt-0 custom-dropdown"
                no-caret
              >
                <template v-slot:button-content>
                  <span
                    class="dropdown-toggle p-0"
                    id="dropdownMenuButton5"
                    data-toggle="dropdown"
                  >
                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                  </span>
                </template>
                <b-dropdown-item variant="primary"  @click="openSlideFormAddPlan('PREVIEW', row.id)">
                  <i class="fas fa-eye mr-2"></i>Detail
                </b-dropdown-item>
                <b-dropdown-item variant="warning">
                  <i class="fas fa-info-circle mr-2"></i>PFA
                </b-dropdown-item>
                <b-dropdown-item variant="primary">
                  <i class="fas fa-info-circle mr-2"></i>Project Forms
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item variant="danger" @click="onDeleteProjectContract(row?.id)">
                  <i class="fas fa-trash mr-2"></i>Delete
                </b-dropdown-item>
              </b-dropdown> -->
            </div>
          </template>
        </g-gantt-row>
      </g-gantt-chart>
    </b-col>
  </b-row>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { busdevActions } from '@src/Utils/helper'
import {GGanttChart, GGanttRow} from '@tenrok/vue-ganttastic'

export default {
  name: 'GanttChart',
  props:{
    ganttChartRows:{
      type:Array,
      default:[]
    }, 
    onUpdatePeriodeDate:{
      type:Function,
      default:true
    }, 
    openSlideDetailProjectContract:{
      type:Function,
      default:true
    }, 
    openSlideFormAddPlan:{
      type:Function,
      default:true
    }, 
    onDeleteProjectContract:{
      type:Function,
      default:true
    },
  },
  data() {
    return {
      dateOption:'3',
      isImmutable: false,

      theme: 'default',
      themes: [
        'creamy',
        'crimson',
        'dark',
        'default',
        'flare',
        'fuchsia',
        'grove',
        'material-blue',
        'sky',
        'slumber',
        'vue'
      ],
      chart2: {
        chartStart: '2020-03-01 00:00',
        chartEnd: '2020-04-01 00:00',
        precision: 'month',
        pushOnOverlap: false,
        isMagnetic: true,
        grid: true,
        gridSize: 50,
        rowHeight: 40,
        rowLabelWidth: 300,
        hideTimeaxis: false,
        highlightOnHover: true,
        highlightedDays: ['2020-03-01', '2020-03-08', '2020-03-15', '2020-03-22', '2020-03-29'],
        width: '90vw',
        height: '250px',
        allowAdd: false,
        rows: [
          {
            label: 'Row #1',
            bars: [
              {
                start: '2020-03-05 00:00',
                end: '2020-03-10 23:59',
                label: 'Bar',
                tooltip: 'Bar tooltip',
                ganttBarConfig: {
                  color: 'white',
                  backgroundColor: '#2e74a3',
                  bundle: 'blueBundle',
                }
              }
            ]
          },
          {
            label: 'Row #2',
            bars: [
              {
                start: '2020-03-02 00:00',
                end: '2020-03-09 23:59',
                label: 'We belong together ^',
                tooltip: 'Bar tooltip',
                ganttBarConfig: {
                  color: 'white',
                  backgroundColor: '#2e74a3',
                  bundle: 'blueBundle'
                }
              },
              {
                start: '2020-03-24 00:00',
                end: '2020-03-26 23:00',
                label: 'Bar',
                tooltip: 'Bar tooltip',
                ganttBarConfig: {
                  color: 'white',
                  backgroundColor: '#de3b26'
                }
              }
            ]
          },
          {
            label: 'Row #3',
            bars: [
              {
                start: '2020-03-15 00:00',
                end: '2020-03-18 23:59',
                label: 'Bar',
                tooltip: 'Bar tooltip',
                ganttBarConfig: {
                  color: 'white',
                  backgroundColor: '#408e2f',
                  handles: true
                }
              }
            ],
            style: {
              background: 'linear-gradient(-45deg, rgba(0, 0, 0, 0) 48%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0) 52%)',
              backgroundSize: '1em 1em'
            }
          },
          {
            label:
              'Lorem ipsum dolor sit amet. Vel odit debitis qui aliquam sequi et reprehenderit Quis. Et ipsam enim aut culpa quia sed maiores veniam in consequuntur accusantium.',
            bars: []
          },
          {
            label: 'Row #5',
            bars: []
          },
          {
            label: 'Row #6',
            bars: []
          },
          {
            label: 'Row #7',
            bars: []
          },
          {
            label: 'Row #8',
            bars: []
          }
        ]
      },

      ganttChartData: null
    }
  },
  mounted () {
    this.convertDataIntoGanttChart()

    const dropdownMenu = document.querySelector('#dropdown1 .dropdown-menu');
    if (dropdownMenu) {
      dropdownMenu.style.zIndex = '100';
    }
  },

  components:{
    GGanttChart,
    GGanttRow
  },

  methods: {
    ...busdevActions,
    onDisableImmutable(){
      this.isImmutable = !this.isImmutable
      this.ganttChartData.rows.map(val => {
        val.bars[0].ganttBarConfig.handles = !val.bars[0].ganttBarConfig.handles
        val.bars[0].ganttBarConfig.immobile = !val.bars[0].ganttBarConfig.immobile
      })
    },
    onDragend(e) {
      const { event, bar, movedBars } = e
      // eslint-disable-next-line
      console.log('onDragend', { event: event.type, bar, movedBars })

      this.onUpdatePeriodeDate(bar.project_contract_id, bar.start, bar.end)
    },
    getDefaultYearDates() {
      let currentDate = moment();
      const currentYear = moment().year();
      const currentMonth = moment().month();
      const nextYear = moment().add(1, 'year').year();
      const previousYear = moment().subtract(1, 'year').year();

      let startDate = null
      let endDate = null

      if(this.dateOption === '1'){
        startDate = moment(`${currentYear}-01-01`).format('YYYY-MM-DD HH:mm');
        endDate = moment(`${currentYear}-02-01`).format('YYYY-MM-DD HH:mm');
      } else if (this.dateOption === '2'){
    
        startDate = moment(`${currentYear}-01-01`).format('YYYY-MM-DD HH:mm');
        endDate = moment(`${currentYear}-04-01`).format('YYYY-MM-DD HH:mm');
      } else if (this.dateOption === '3'){
        startDate = moment(`${currentYear}-01-01`).format('YYYY-MM-DD HH:mm');
        endDate = moment(`${currentYear}-11-01`).format('YYYY-MM-DD HH:mm');
      } else if (this.dateOption === '4'){
        startDate = moment(`${currentYear}-01-01`).format('YYYY-MM-DD HH:mm');
        endDate = moment(`${nextYear}-12-01`).format('YYYY-MM-DD HH:mm');
      }

      const pickedDate = [startDate, endDate]
      console.log(pickedDate)
      return pickedDate;
    },
    convertDataIntoGanttChart(){
      console.log(this.getDefaultYearDates())
      this.ganttChartData = {
        chartStart: '2020-03-01 00:00',
        chartEnd: '2020-12-01 23:59',
        precision: 'month',
        pushOnOverlap: false,
        isMagnetic: true,
        grid: true,
        gridSize: 20,
        rowHeight: 40,
        rowLabelWidth: 300,
        hideTimeaxis: false,
        highlightOnHover: false,
        width: '100%',
        height: '100%',
        allowAdd: false,
        rows: []
      }

      if(this.getDefaultYearDates()?.[0]) this.ganttChartData.chartStart = this.getDefaultYearDates()?.[0]
      if(this.getDefaultYearDates()?.[0]) this.ganttChartData.chartEnd = this.getDefaultYearDates()?.[1]
      
      this.ganttChartData.rows = this.ganttChartRows
    },
    
  }
}
</script>

<style lang="scss">
  @import '~@tenrok/vue-ganttastic/dist/vue-ganttastic.css';

  /* Custom */
  .g-gantt-line-highlighted {
    background: #ffb0b0 !important;
  }

  .custom-dropdown .dropdown-menu {
    z-index: 100 !important;
  }
</style>