var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('CustomNav', {
    attrs: {
      "items": _vm.itemTabs,
      "primary": ""
    },
    on: {
      "item-click": function itemClick(val) {
        return _vm.tabSelected = val;
      }
    }
  }), _vm.tabSelected == 1 ? [_c('SummaryFleetStatus'), _c('PercentageAvailability'), _c('Breakdown'), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "8"
    }
  }, [_c('BreakdownPercentage')], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('BreakdownVsAvailability')], 1)], 1)] : [_c('SummaryTotalBudget'), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('BudgetExpanse')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('BudgetPlanVsExpanse')], 1)], 1), _c('SummaryDocking'), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('BudgetDockingCategory')], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('BudgetExpanseVsPlan')], 1)], 1)]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }