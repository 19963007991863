<template>
  <form v-if="detailMi" action="" @submit.prevent="onSave">
    <b-alert v-if="!rootCauseAnalysis" variant="danger" class="d-flex align-items-center" show>Isi RCA Terlebih Dahulu  <b-button  class="ml-3" variant="primary" :to="`/mi/root-cause-analysis?formId=${$route.query.formId}`">Disini</b-button></b-alert>
    <b-row v-else>
      <b-col md="12" class="mb-3 text-right">
        <b-button  variant="primary" :disabled="form._saving" type="submit">{{form._saving ? 'Menyimpan...' : 'Simpan'}}</b-button>
      </b-col>
      <b-col md="12" class="mb-4">
        <b-row>
          <b-col md="12" class="text-center mb-4"><h4>Assessor of the Accident</h4></b-col>
          <b-col md="3">
            <b-form-group label="Name">
              <b-form-input class="w-100" v-model="rootCauseAnalysis.assessor_fullname" readonly type="text" placeholder="Name..."></b-form-input>
            </b-form-group>
            <b-form-group label="Vessel">
              <b-form-input class="w-100" :value="detailMi.vehicle.name" readonly type="text" placeholder="Vessel..."></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Company">
              <b-form-select plain v-model="rootCauseAnalysis.assessor_company_id" disabled :options="companies" size="lg">
                <template v-slot:first>
                  <b-form-select-option :value="null">Select Company</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
            <b-form-group label="Report Number">
              <b-form-input class="w-100" v-model="form.report_number" type="text" placeholder="Report Number..."></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Position">
              <b-form-input class="w-100" v-model="rootCauseAnalysis.assessor_position" readonly type="text" placeholder="Name..."></b-form-input>
            </b-form-group>
            <b-form-group label="Place of Loss">
              <b-form-input class="w-100" :value="detailMi.place_of_loss" readonly type="text" placeholder="Name..."></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Type of Loss">
              <b-form-input class="w-100" :value="detailMi.type_loss.name" readonly type="text" placeholder="Name..."></b-form-input>
            </b-form-group>
            <b-form-group label="Type of Coverage">
              <b-form-input class="w-100" :value="detailMi.type_coverage.name" readonly type="text" placeholder="Name..."></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group label="Date of Loss">
              <date-picker  type="datetime" v-model="rootCauseAnalysis.date_of_loss" disabled value-type="YYYY-MM-DD" format="DD MMM YYYY" class="w-100" placeholder="Select date and time"></date-picker>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Starting Date *">
              <date-picker  type="date" is-required v-model="form.starting_date" value-type="YYYY-MM-DD" format="DD MMM YYYY" class="w-100" placeholder="Select date and time"></date-picker>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Closing Date *">
              <date-picker  type="date" is-required v-model="form.closing_date" value-type="YYYY-MM-DD" format="DD MMM YYYY" class="w-100" placeholder="Select date and time"></date-picker>
            </b-form-group>
          </b-col>
<!--          <b-col md="3">-->
<!--            <b-form-group label="Images (multiple) *">-->
<!--              <b-form-file class="w-100" multiple placeholder="Select file..."></b-form-file>-->
<!--            </b-form-group>-->
<!--          </b-col>-->
        </b-row>
      </b-col>
      <b-col md="12" class="mt-2">
        <b-row>
          <b-col md="1">
            <div class="bg-info text-center text-white p-4">
              <h2 class="text-white">A</h2>
            </div>
          </b-col>
          <b-col md="11">
            <h2 class="mt-4">INVESTIGATION DETAILS</h2>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="12" class="mt-2 m-auto">
        <div class="my-3">
          <h4 class="my-3"><strong>A.1 Investigation Team</strong></h4>
          <div>
            <table class="table w-100">
              <thead>
              <tr>
                <th width="20%">Team List</th>
                <th width="20%">Name</th>
                <th width="20%">Position</th>
                <th width="20%">Remarks</th>
                <th width="10%">Action</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(data, index) in form.investigation_teams">
                <td>
                  <b-form-input v-model="data.team" class="w-100" type="text" placeholder="Name..."></b-form-input>
                </td>
                <td>
                  <b-form-input v-model="data.name" class="w-100" type="text" placeholder="Name..."></b-form-input>
                </td>
                <td>
                  <b-form-input v-model="data.position" class="w-100" type="text" placeholder="Position..."></b-form-input>
                </td>
                <td>
                  <b-form-input v-model="data.remarks" class="w-100" type="text" placeholder="Remarks..."></b-form-input>
                </td>
                <td>
                  <b-button  @click="removeInvestigationTeam(index)" variant="danger">Remove</b-button>
                </td>
              </tr>
              </tbody>
            </table>
            <b-button  variant="warning" @click="addInvestigationTeam()" block class="mt-2 mb-3"><i class="fa fa-plus"></i> Add More Data</b-button>
          </div>
        </div>
        <div class="my-3">
          <h4 class="my-3"><strong>A.2 Direct Witness</strong></h4>
          <div>
            <table class="table w-100">
              <thead>
              <tr>
                <th width="10%">No</th>
                <th width="20%">Name</th>
                <th width="20%">Position</th>
                <th width="20%">Remarks</th>
                <th width="10%">Action</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(data, index) in form.direct_witnesses">
                <td>
                  {{index+1}}
                </td>
                <td>
                  {{data.detail ? data.detail.name : '-'}}
<!--                  <b-form-select plain v-model="data.detail" size="lg">-->
<!--                    <b-form-select-option :value="null">(Please select)</b-form-select-option>-->
<!--                    <b-form-select-option v-for="(listRca, indexRca) in listRootCauseAnalysis" :key="indexRca" :value="listRca">{{listRca.assessor_fullname}}</b-form-select-option>-->
<!--                  </b-form-select>-->
                </td>
                <td>
                  {{data.detail ? data.detail.position : '-'}}
                </td>
                <td>
                  {{data.detail ? data.detail.remarks : '-'}}
                </td>
                <td>
<!--                  <b-button  @click="removeDirectWitnesses(index)" variant="danger">Remove</b-button>-->
                </td>
              </tr>
              </tbody>
            </table>
<!--            <b-button  variant="warning" @click="addDirectWitnesses()" block class="mt-2 mb-3"><i class="fa fa-plus"></i> Add More Data</b-button>-->
          </div>
        </div>
        <div class="my-3">
          <h4 class="my-3"><strong>A.2 Indirect Witness</strong></h4>
          <div>
            <table class="table w-100">
              <thead>
              <tr>
                <th width="10%">No</th>
                <th width="20%">Name</th>
                <th width="20%">Position</th>
                <th width="20%">Remarks</th>
                <th width="10%">Action</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(data, index) in form.indirect_witnesses">
                <td>
                  {{index+1}}
                </td>
                <td>
                  {{data.detail ? data.detail.name : '-'}}
                  <!--                  <b-form-select plain v-model="data.detail" size="lg">-->
                  <!--                    <b-form-select-option :value="null">(Please select)</b-form-select-option>-->
                  <!--                    <b-form-select-option v-for="(listRca, indexRca) in listRootCauseAnalysis" :key="indexRca" :value="listRca">{{listRca.assessor_fullname}}</b-form-select-option>-->
                  <!--                  </b-form-select>-->
                </td>
                <td>
                  {{data.detail ? data.detail.position : '-'}}
                </td>
                <td>
                  {{data.detail ? data.detail.remarks : '-'}}
                </td>
                <td>
                  <!--                  <b-button  @click="removeIndirectWitnesses(index)" variant="danger">Remove</b-button>-->
                </td>
              </tr>
              </tbody>
            </table>
<!--            <b-button  variant="warning" @click="addIndirectWitnesses()" block class="mt-2 mb-3"><i class="fa fa-plus"></i> Add More Data</b-button>-->
          </div>
        </div>
      </b-col>

      <!--    supporting document-->
      <b-col md="12" class="mt-2">
        <b-row>
          <b-col md="1">
            <div class="bg-info text-center text-white p-4">
              <h2 class="text-white">B</h2>
            </div>
          </b-col>
          <b-col md="11">
            <h2 class="mt-4">Supporting Document</h2>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="12" class="mt-2">
        <div>
          <table class="table w-100">
            <thead>
            <tr>
              <th width="5%" class="text-center">No</th>
              <th width="20%" class="text-center">Document</th>
              <th width="5%" class="text-center">Original</th>
              <th width="5%" class="text-center">Copy</th>
              <th width="15%" class="text-center">PIC</th>
              <th width="15%" class="text-center">Attachment</th>
              <th width="20%" class="text-center">Remarks</th>
              <th width="10%" class="text-center">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(data, index) in form.supporting_documents">
              <td>
                {{index+1}}
              </td>
              <td>
                <b-form-input v-model="data.document" required class="w-100" type="text" placeholder="Name..."></b-form-input>
              </td>
              <td class="text-center">
                <b-form-checkbox  v-model="data.original" value="v" unchecked-value="x" class="w-100"></b-form-checkbox>
              </td>
              <td class="text-center">
                <b-form-checkbox  v-model="data.copy" value="v" unchecked-value="x" class="w-100"></b-form-checkbox>
              </td>
              <td>
                <b-form-input v-model="data.pic" required class="w-100" type="text" placeholder="PIC..."></b-form-input>
              </td>
              <td>
                <div v-if="data.document" class="d-flex justify-content-between">
                  <a :href="data.document" class="text-primary" v-b-tooltip.hover title="Download File" download><i class="fa fa-download"></i> Download</a>
                  <a href="#" @click.prevent="data.document = '';data.file = ''" class="text-danger" v-b-tooltip.hover title="Download" download><i class="fa fa-trash"></i></a>
                </div>
                <template v-else>
                  <div class="custom-file">
                    <input type="file" @change="onSupportingDocumentsFileChanged(index, $event)" accept="image/png,image/jpg,image/jpeg" class="custom-file-input" id="validatedCustomFile">
                    <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                  </div>
                </template>
              </td>
              <td>
                <b-form-input v-model="data.remarks" class="w-100" type="text" placeholder="Remarks..."></b-form-input>
              </td>
              <td class="text-center">
                <b-button  @click="removeSupportingDocuments(index)" variant="danger">Remove</b-button>
              </td>
            </tr>
            </tbody>
          </table>
          <b-button  variant="warning" @click="addSupportingDocuments()" block class="mt-2 mb-3"><i class="fa fa-plus"></i> Add More Data</b-button>
        </div>
      </b-col>

      <!--    details of investigation-->
      <b-col md="12" class="mt-2">
        <b-row>
          <b-col md="1">
            <div class="bg-info text-center text-white p-4">
              <h2 class="text-white">C</h2>
            </div>
          </b-col>
          <b-col md="11">
            <h2 class="mt-4">Details Of Investigation</h2>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="12" class="mt-2">
        <b-form-group label="Images (multiple) *">
          <b-form-file class="w-100" accept="image/*" @change="onImagesFileChanged" multiple placeholder="Select file..."></b-form-file>
        </b-form-group>
      </b-col>

      <!--    summary of accident-->
      <b-col md="12" class="mt-2">
        <b-row>
          <b-col md="1">
            <div class="bg-info text-center text-white p-4">
              <h2 class="text-white">D</h2>
            </div>
          </b-col>
          <b-col md="11">
            <h2 class="mt-4">Summary Of Accident</h2>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="12" class="mt-2">
        <b-row>
          <b-col md="12">
            <b-row class="mt-2 mb-2">
              <b-col md="5">
                <div class="bg-light p-4 font-size-18"><strong class="text-center">The Problem *</strong></div>
              </b-col>
              <b-col md="7">
                <b-form-textarea  required v-model="form.problem" rows="3"></b-form-textarea>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12">
            <b-row class="mt-2 mb-2">
              <b-col md="5">
                <div class="bg-light p-4 font-size-18"><strong class="text-center">Cause Of Loss *</strong></div>
              </b-col>
              <b-col md="7">
                <b-form-textarea  required v-model="form.cause_of_loss" rows="4"></b-form-textarea>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12">
            <b-row class="mt-2 mb-2">
              <b-col md="5">
                <div class="bg-light p-4 font-size-18"><strong class="text-center">Impact Of Damage *</strong></div>
              </b-col>
              <b-col md="7">
                <b-form-textarea  required v-model="form.impact" rows="4"></b-form-textarea>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <div>
              <h4 class="my-3"><strong>D.4 Cost of Damage</strong></h4>
              <div>
                <table class="table w-100">
                  <thead>
                  <tr>
                    <th width="5%">No</th>
                    <th width="20%">Description</th>
                    <th width="20%">Cost</th>
                    <th width="20%">Total</th>
                    <th width="15%">Remarks</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(data, index) in form.damage_costs">
                    <td>
                      {{index+1}}
                    </td>
                    <td>
                      <b-form-input v-model="data.description" class="w-100" type="text" placeholder="Name..."></b-form-input>
                    </td>
                    <td>
                      <b-input-group prepend="Rp">
                        <b-form-input v-model="data.cost" type="number"></b-form-input>
                      </b-input-group>
                    </td>
                    <td>
                      <b-input-group prepend="Rp">
                        <b-form-input v-model="data.total" type="number"></b-form-input>
                      </b-input-group>
                    </td>
                    <td>
                      <b-form-input v-model="data.remarks" class="w-100" type="text" placeholder="Remarks..."></b-form-input>
                    </td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr>
                    <th bgcolor="#ffd700" colspan="2" class="text-center">
                      TOTAL
                    </th>
                    <th bgcolor="#ffd700">
                      Rp. {{ numberFormat(costOfDamagesSumCost) }}
                    </th>
                    <th bgcolor="#ffd700">
                      Rp. {{ numberFormat(costOfDamagesSumTotal) }}
                    </th>
                    <th bgcolor="#ffd700">
                    </th>
                    <th bgcolor="#ffd700">
                    </th>
                  </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <div>
              <h4 class="my-3"><strong>D.5 Cause & Effect (Findings) - For Details please see attachment of Fishbone Diagram for each Witness</strong></h4>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="9">
            <div>
              <h4 class="my-3"><strong>D.5.1 Human</strong></h4>
              <div>
                <table class="table w-100">
                  <thead>
                  <tr>
                    <th width="5%">No</th>
                    <th width="40%">Findings</th>
                    <th width="10%">Proximity Level</th>
                    <th width="10%">Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(data, index) in findingsByPrefix(1)">
                    <td>
                      {{data.value}}
                    </td>
                    <td>
                      <b-form-textarea  required v-model="data.text" rows="2"></b-form-textarea>
                    </td>
                    <td>
                      <b-form-select plain v-model="data.proximity" size="lg">
                        <b-form-select-option :value="null">(Please select)</b-form-select-option>
                        <b-form-select-option v-for="n in 5" :key="n" :value="n">{{n}}</b-form-select-option>
                      </b-form-select>
                    </td>
                    <td>
                      <b-button  @click="removeFindings(data.value)" variant="danger">Remove</b-button>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <b-button  variant="warning" @click="addFindings(1)" block class="mt-2 mb-3"><i class="fa fa-plus"></i> Add More Data</b-button>
              </div>
            </div>
          </b-col>
          <b-col md="3">
            <img src="/img/level-priority.png" alt="">
          </b-col>
        </b-row>
        <b-row>
          <b-col md="9">
            <div>
              <h4 class="my-3"><strong>D.5.2 Machinery/Equipment etc.</strong></h4>
              <div>
                <table class="table w-100">
                  <thead>
                  <tr>
                    <th width="5%">No</th>
                    <th width="40%">Findings</th>
                    <th width="10%">Proximity Level</th>
                    <th width="10%">Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(data, index) in findingsByPrefix(2)">
                    <td>
                      {{data.value}}
                    </td>
                    <td>
                      <b-form-textarea  required v-model="data.text" rows="2"></b-form-textarea>
                    </td>
                    <td>
                      <b-form-select plain v-model="data.proximity" size="lg">
                        <b-form-select-option :value="null">(Please select)</b-form-select-option>
                        <b-form-select-option v-for="n in 5" :key="n" :value="n">{{n}}</b-form-select-option>
                      </b-form-select>
                    </td>
                    <td>
                      <b-button  @click="removeFindings(data.value)" variant="danger">Remove</b-button>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <b-button  variant="warning" @click="addFindings(2)" block class="mt-2 mb-3"><i class="fa fa-plus"></i> Add More Data</b-button>
              </div>
            </div>
          </b-col>
          <b-col md="3">
            <img src="/img/level-priority.png" alt="">
          </b-col>
        </b-row>
        <b-row>
          <b-col md="9">
            <div>
              <h4 class="my-3"><strong>D.5.3 Control, System, Method,Process.</strong></h4>
              <div>
                <table class="table w-100">
                  <thead>
                  <tr>
                    <th width="5%">No</th>
                    <th width="40%">Findings</th>
                    <th width="10%">Proximity Level</th>
                    <th width="10%">Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(data, index) in findingsByPrefix(3)">
                    <td>
                      {{data.value}}
                    </td>
                    <td>
                      <b-form-textarea  required v-model="data.text" rows="2"></b-form-textarea>
                    </td>
                    <td>
                      <b-form-select plain v-model="data.proximity" size="lg">
                        <b-form-select-option :value="null">(Please select)</b-form-select-option>
                        <b-form-select-option v-for="n in 5" :key="n" :value="n">{{n}}</b-form-select-option>
                      </b-form-select>
                    </td>
                    <td>
                      <b-button  @click="removeFindings(data.value)" variant="danger">Remove</b-button>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <b-button  variant="warning" @click="addFindings(3)" block class="mt-2 mb-3"><i class="fa fa-plus"></i> Add More Data</b-button>
              </div>
            </div>
          </b-col>
          <b-col md="3">
            <img src="/img/level-priority.png" alt="">
          </b-col>
        </b-row>
        <b-row>
          <b-col md="9">
            <div>
              <h4 class="my-3"><strong>D.5.4 Other Factor.</strong></h4>
              <div>
                <table class="table w-100">
                  <thead>
                  <tr>
                    <th width="5%">No</th>
                    <th width="40%">Findings</th>
                    <th width="10%">Proximity Level</th>
                    <th width="10%">Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(data, index) in findingsByPrefix(4)">
                    <td>
                      {{data.value}}
                    </td>
                    <td>
                      <b-form-textarea  required v-model="data.text" rows="2"></b-form-textarea>
                    </td>
                    <td>
                      <b-form-select plain v-model="data.proximity" size="lg">
                        <b-form-select-option :value="null">(Please select)</b-form-select-option>
                        <b-form-select-option v-for="n in 5" :key="n" :value="n">{{n}}</b-form-select-option>
                      </b-form-select>
                    </td>
                    <td>
                      <b-button  @click="removeFindings(data.value)" variant="danger">Remove</b-button>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <b-button  variant="warning" @click="addFindings(4)" block class="mt-2 mb-3"><i class="fa fa-plus"></i> Add More Data</b-button>
              </div>
            </div>
          </b-col>
          <b-col md="3">
            <img src="/img/level-priority.png" alt="">
          </b-col>
        </b-row>
      </b-col>

      <!-- claim opportunity -->
      <b-col md="12" class="mt-2">
        <b-row>
          <b-col md="1">
            <div class="bg-info text-center text-white p-4">
              <h2 class="text-white">E</h2>
            </div>
          </b-col>
          <b-col md="11">
            <h2 class="mt-4">Claims Opportunity</h2>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="12" class="mt-2">
        <b-row>
          <b-col md="12">
            <div>
              <h4 class="my-3"><strong>E.1 Initial Claim Analysis</strong></h4>
              <div v-if="detailSurvey">
                <b-table-simple responsive width="100%" striped>
                  <b-thead>
                    <b-th width="5%">NO</b-th>
                    <b-th v-for="(header, headerIndex) in detailSurvey.headers">{{header.label_name}}</b-th>
                  </b-thead>
                  <b-tbody v-if="detailSurvey.headers[0]">
                    <b-tr v-for="number in detailSurvey.headers[0].contents.length">
                      <b-td>{{number}}</b-td>
                      <b-td v-for="(header, headerIndex) in detailSurvey.headers">
                        <template v-if="header.contents[number-1]">
                          <template v-if="header.contents[number-1].text">
                            {{header.contents[number-1].text}}
                          </template>
                          <template v-else-if="header.contents[number-1].input">
                            {{header.contents[number-1].input.value}}
                          </template>
                          <template v-else>
                            -
                          </template>
                        </template>
                      </b-td>
                    </b-tr>
                    <b-tr class="iq-bg-primary">
                      <b-td colspan="3" class="text-right"><h5><b>Nilai Seluruhnya</b></h5></b-td>
                      <b-td class="text-center"><h5><b>{{ totalAll }}</b></h5></b-td>
                      <b-td>&nbsp;</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>
              <div v-else>
                -
              </div>
              <template v-if="detailFillSurvey && detailFillSurvey.claim_analysis">
                <div class="mb-2">
                  <h4>Conclusion</h4>
                  <div>
                    {{detailFillSurvey.claim_analysis.conclusion || '-'}}
                  </div>
                </div>
                <div class="mb-2">
                  <h4>Comments</h4>
                  <div>
                    {{detailFillSurvey.claim_analysis.comments || '-'}}
                  </div>
                </div>
              </template>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <div>
              <h4 class="my-3"><strong>E.2 Document Control</strong></h4>
              <div>
                <table class="table w-100">
                  <thead>
                  <tr>
                    <th width="5%">No</th>
                    <th width="20%">Document</th>
                    <th width="20%">Remarks</th>
                    <th width="10%">Status</th>
                    <th width="10%">Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(data, index) in form.document_controls">
                    <td>
                      {{index+1}}
                    </td>
                    <td>
                      <b-form-input v-model="data.document" required class="w-100" type="text" placeholder="Name..."></b-form-input>
                    </td>
                    <td>
                      <b-form-input v-model="data.remarks" class="w-100" type="text" placeholder="Remarks..."></b-form-input>
                    </td>
                    <td>
                      <b-form-select v-model="data.status" plain>
                        <b-form-select-option :value="null">Status</b-form-select-option>
                        <b-form-select-option value="Top Priority">Top Priority</b-form-select-option>
                        <b-form-select-option value="Priority">Priority</b-form-select-option>
                      </b-form-select>
                    </td>
                    <td>
                      <b-button  @click="removeDocumentControls(index)" variant="danger">Remove</b-button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </b-col>
          <b-col md="12">
            <b-button  variant="warning" @click="addDocumentControls()" block class="mt-2 mb-3"><i class="fa fa-plus"></i> Add More Data</b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <div>
              <h4 class="my-3"><strong>E.3 Cost of Loss</strong></h4>
              <div>
                <table class="table w-100">
                  <thead>
                  <tr>
                    <th width="5%">No</th>
                    <th width="20%">Description</th>
                    <th width="20%">Cost</th>
                    <th width="20%">Total</th>
                    <th width="15%">Remarks</th>
                    <th width="10%">Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(data, index) in form.loss_costs">
                    <td>
                      {{index+1}}
                    </td>
                    <td>
                      <b-form-input v-model="data.description" class="w-100" type="text" placeholder="Name..."></b-form-input>
                    </td>
                    <td>
                      <b-input-group prepend="Rp">
                        <b-form-input v-model="data.cost" type="number"></b-form-input>
                      </b-input-group>
                    </td>
                    <td>
                      <b-input-group prepend="Rp">
                        <b-form-input v-model="data.total" type="number"></b-form-input>
                      </b-input-group>
                    </td>
                    <td>
                      <b-form-input v-model="data.remarks" class="w-100" type="text" placeholder="Remarks..."></b-form-input>
                    </td>
                    <td class="text-center">
                      <b-button  @click="removeCostLoss(index)" variant="danger">Remove</b-button>
                    </td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr>
                    <th bgcolor="#ffd700" colspan="2" class="text-center">
                      TOTAL
                    </th>
                    <th bgcolor="#ffd700">
                      Rp. {{ numberFormat(costOfLossSumCost) }}
                    </th>
                    <th bgcolor="#ffd700">
                      Rp. {{ numberFormat(costOfLossSumTotal) }}
                    </th>
                    <th bgcolor="#ffd700">
                    </th>
                    <th bgcolor="#ffd700">
                    </th>
                  </tr>
                  </tfoot>
                </table>
                <b-button  variant="warning" @click="addCostLoss()" block class="mt-2 mb-3"><i class="fa fa-plus"></i> Add More Data</b-button>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <div>
              <h4 class="my-3"><strong>E.4 Impact to Loss Ratio</strong></h4>
              <h6 class="my-3"><strong>Individual</strong></h6>
              <table class="table table-striped">
                <thead>
                <tr>
                  <th width="20%" class="text-center">Years</th>
                  <th width="20%" class="text-center">Premium</th>
                  <th width="20%" class="text-center">Claim Loss</th>
                  <th width="20%" class="text-center">Loss Ratio</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="ratio in detailLossRatio" >
                  <tr v-if="ratio.individual">
                    <td class="text-center"><strong>{{ ratio.individual.year }}</strong></td>
                    <td class="text-center">{{ formatCurrency(ratio.individual.premium) }},-</td>
                    <td class="text-center">{{ formatCurrency(ratio.individual.claim_loss) }},-</td>
                    <td class="text-center">{{ ratio.individual.loss_ratio }}%</td>
                  </tr>
                </template>
                </tbody>
              </table>
              <h6 class="my-3"><strong>Accumulation</strong></h6>
              <table class="table table-striped">
                <thead>
                <tr>
                  <th width="20%" class="text-center">Years</th>
                  <th width="20%" class="text-center">Acc Premium</th>
                  <th width="20%" class="text-center">Acc Loss</th>
                  <th width="20%" class="text-center">Acc Loss Ratio</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="ratio in detailLossRatio" >
                  <tr v-if="ratio.accumulative">
                    <td class="text-center"><strong>{{ ratio.accumulative.year }}</strong></td>
                    <td class="text-center">{{ formatCurrency(ratio.accumulative.premium) }},-</td>
                    <td class="text-center">{{ formatCurrency(ratio.accumulative.claim_loss) }},-</td>
                    <td class="text-center">{{ ratio.accumulative.loss_ratio }}%</td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <!-- claim opportunity end-->

      <!-- Conclusion -->
      <b-col md="12" class="mt-2">
        <b-row>
          <b-col md="1">
            <div class="bg-info text-center text-white p-4">
              <h2 class="text-white">F</h2>
            </div>
          </b-col>
          <b-col md="11">
            <h2 class="mt-4">Conclusion</h2>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="12" class="mt-2">
        <b-row>
          <b-col md="12">
            <div>
              <div>
                <table class="table">
                  <thead>
                  <tr>
                    <th width="5%" class="text-center" rowspan="2">No</th>
                    <th width="30%" class="text-center" rowspan="2">Damage</th>
                    <th width="10%" class="text-center" colspan="4">Root Cause &amp; Effect (Findings)</th>
                    <th width="15%" class="text-center" rowspan="2">Status</th>
                    <th width="10%" class="text-center" rowspan="2">Action</th>
                  </tr>
                  <tr>
                    <th width="10%" class="text-center">Human</th>
                    <th width="10%" class="text-center">Machinery/ Equipment</th>
                    <th width="10%" class="text-center">Control, System, Method,Process</th>
                    <th width="10%" class="text-center">Other Factor</th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-for="(data, index) in form.conclusions">
                    <tr v-for="(row, indexRow) in data.rows" :key="indexRow">
                      <td v-if="indexRow === 0" class="text-center" :rowspan="data.rows.length + 1">
                        {{index+1}}
                      </td>
                      <td v-if="indexRow === 0" :rowspan="data.rows.length + 1">
                        <b-form-textarea  required v-model="data.damage" rows="3"></b-form-textarea>
                        <b-button  size="sm" @click="removeConclusion(index)" variant="link">Remove</b-button>
                      </td>
                      <td class="text-center">
                        <b-form-select v-model="row.human" required plain>
                          <b-form-select-option value="-">-</b-form-select-option>
                          <b-form-select-option v-for="finding in findingsByPrefix(1)" :value="finding.value">{{finding.value}}</b-form-select-option>
                        </b-form-select>
                      </td>
                      <td class="text-center">
                        <b-form-select v-model="row.equipment" required plain>
                          <b-form-select-option value="-">-</b-form-select-option>
                          <b-form-select-option v-for="finding in findingsByPrefix(2)" :value="finding.value">{{finding.value}}</b-form-select-option>
                        </b-form-select>
                      </td>
                      <td class="text-center">
                        <b-form-select v-model="row.csmp" required plain>
                          <b-form-select-option value="-">-</b-form-select-option>
                          <b-form-select-option v-for="finding in findingsByPrefix(3)" :value="finding.value">{{finding.value}}</b-form-select-option>
                        </b-form-select>
                      </td>
                      <td class="text-center">
                        <b-form-select v-model="row.other" required plain>
                          <b-form-select-option value="-">-</b-form-select-option>
                          <b-form-select-option v-for="finding in findingsByPrefix(4)" :value="finding.value">{{finding.value}}</b-form-select-option>
                        </b-form-select>
                      </td>
                      <td class="text-center" :class="{
                        'bg-danger text-white' : row.status === 'fatal',
                        'bg-warning text-white' : row.status === 'danger',
                        'bg-info text-white' : row.status === 'alert',
                        'bg-success text-white' : row.status === 'normal',
                      }">
                        <b-form-select
                          :class="{
                            'bg-danger text-white' : row.status === 'fatal',
                            'bg-warning text-white' : row.status === 'danger',
                            'bg-info text-white' : row.status === 'alert',
                            'bg-success text-white' : row.status === 'normal',
                          }"
                          v-model="row.status" plain>
                          <b-form-select-option :value="null">Status</b-form-select-option>
                          <b-form-select-option value="fatal">Fatal</b-form-select-option>
                          <b-form-select-option value="danger">Danger</b-form-select-option>
                          <b-form-select-option value="alert">Alert</b-form-select-option>
                          <b-form-select-option value="normal">Normal</b-form-select-option>
                        </b-form-select>
                      </td>
                      <td class="text-center" > 
                        <b-button v-if="indexRow > 0" @click="removeConclusionRow(index, indexRow)" variant="danger">Remove</b-button>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="5">
                        <b-button  variant="warning" @click="addConclusionRow(index)" block class="mt-2 mb-3"><i class="fa fa-plus"></i> Add More Data</b-button>
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>
                <b-button  variant="warning" @click="addConclusion()" block class="mt-2 mb-3"><i class="fa fa-plus"></i> Add More Data</b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <!-- Conclusion End -->

      <!-- Recommendation -->
      <b-col md="12" class="mt-2">
        <b-row>
          <b-col md="1">
            <div class="bg-info text-center text-white p-4">
              <h2 class="text-white">G</h2>
            </div>
          </b-col>
          <b-col md="11">
            <h2 class="mt-4">Recommendation</h2>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="12" class="mt-2">
        <b-row>
          <b-col md="12">
            <div>
              <div>
                <table class="table">
                  <thead>
                  <tr>
                    <th width="30%" class="text-center" rowspan="2">Helpful</th>
                    <th width="30%" class="text-center" rowspan="2">Harmful</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>
                      <div class="text-bold">Strength</div>
                      <b-form-textarea  required v-model="form.recommendation.strength" rows="3"></b-form-textarea>
                    </td>
                    <td>
                      <div class="text-bold">Weakness</div>
                      <b-form-textarea  required v-model="form.recommendation.weakness" rows="3"></b-form-textarea>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="text-bold">Opportunity</div>
                      <b-form-textarea  required v-model="form.recommendation.opportunity" rows="3"></b-form-textarea>
                    </td>
                    <td>
                      <div class="text-bold">Threat</div>
                      <b-form-textarea  required v-model="form.recommendation.threat" rows="3"></b-form-textarea>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <!-- Recommendation End -->

      <b-col md="12" class="mb-3 text-right">
        <b-button  variant="primary" :disabled="form._saving" type="submit">{{form._saving ? 'Menyimpan...' : 'Simpan'}}</b-button>
      </b-col>
    </b-row>
  </form>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { companiesActions, filesActions, fleetsActions, miActions } from '@src/Utils/helper'
import { numberFormat } from '@src/plugins/helpers'

export default {
  name: 'FinalReportRootCauseAnalysisForm',
  async mounted () {
    xray.index()
    if (this.$route.query.formId) {
      this.fetchCompanies()
      await this.fetchMi()
      await this.fetchDetailRCAFinal()
      await this.fetchFormSurvey()
      await this.fetchSurvey()
    }
  },
  computed: {
    userPermission(){
      if(['SUPER ADMIN', 'MARINE INSURANCE', 'MARINE INSURANCE ADMIN', 'MARINE INSURANCE MANAGER'].includes(this.permission_user)){
        return true
      } else{
        return false
      } 
    },
    totalAll () {
      let ret = 0

      if (this.detailSurvey) {
        let header = this.detailSurvey.headers.find(header => header.type === 'user_input' && header.contents[0] && header.contents[0].input && header.contents[0].input.alias === 'max100')

        if (header) {
          let filteredContents = header.contents.filter(content => content.input.value)

          filteredContents.forEach(content => {
            ret += parseInt(content.input.value)
          })

          if (filteredContents.length > 0) {
            ret = ret / filteredContents.length
          }
        }
      }

      return Math.round(ret)
    },
    rootCauseAnalysis () {
      return this.detailMi && this.detailMi.rcas ? this.detailMi.rcas[0] : null
    },
    listRootCauseAnalysis () {
      return this.detailMi && this.detailMi.rcas ? this.detailMi.rcas : []
    },
    costOfDamagesSumCost () {
      let total = 0
      this.form.damage_costs.map(cod => total += (cod.cost ? parseInt(cod.cost) : 0))
      return total
    },
    costOfDamagesSumTotal () {
      let total = 0
      this.form.damage_costs.map(cod => total += (cod.total ? parseInt(cod.total) : 0))
      return total
    },
    costOfLossSumCost () {
      let total = 0
      this.form.loss_costs.map(cod => total += (cod.cost ? parseInt(cod.cost) : 0))
      return total
    },
    costOfLossSumTotal () {
      let total = 0
      this.form.loss_costs.map(cod => total += (cod.total ? parseInt(cod.total) : 0))
      return total
    }
  },
  data () {
    return {
      permission_user:JSON.parse(localStorage.getItem('profile')).role.roles,
      detailMi: null,
      detailFillSurvey: null,
      detailSurvey: null,
      detailLossRatio: [],
      form: {
        _saving: false,
        'images': [],
        'investigation_teams': [],
        'direct_witnesses': [],
        'indirect_witnesses': [],
        'supporting_documents': [],
        'document_controls': [],
        'problem': '',
        'cause_of_loss': '',
        'impact': '',
        'report_number': '',
        'starting_date': '',
        'closing_date': '',
        'damage_costs': [],
        'loss_costs': [],
        'findings': [],
        'conclusions': [],
        'recommendation': {
          'strength': '',
          'weakness': '',
          'opportunity': '',
          'threat': ''
        }
      },
      stateActive: {
        single: 'active',
        disable: 'active',
        number: 'two',
        colorDisabled: 'five',
        color: 'danger'
      },
      state: [
        {
          name: 'single',
          label: 'Male',
          value: 'male',
          disabled: false
        },
        {
          name: 'single',
          label: 'Female',
          value: 'female',
          disabled: false
        }
      ],
      marital: [
        { value: '1', text: 'Single' },
        { value: '2', text: 'Married' },
        { value: '3', text: 'Divorce' }
      ],
      options3: [
        { value: '1', text: 'Transcoal Pacific' },
        { value: '2', text: 'Energi Transporter' },
        { value: '3', text: 'Sentra Makmur Lines' }
      ],
      companies: []
    }
  },
  methods: {
    ...fleetsActions,
    ...companiesActions,
    ...miActions,
    ...filesActions,
    numberFormat (number) {
      return numberFormat(number)
    },
    formatCurrency (number) {
      const formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        maximumFractionDigits: 2
      })
      return formatter.format(Number.isNaN(number) ? 0 : number)
    },
    async fetchCompanies () {
      let res = await this.getCompanies()
      this.companies = res.data.map(val => ({ value: val.id, text: val.company }))
    },
    async fetchMi () {
      const { data, status } = await this.fetchDetailMi({
        id: this.$route.query.formId,
        with: 'vehicle,type_coverage,type_loss,company,documents,company_claimant,status,creator,histories,loss_ratio,rcas,rca_final,cost_breakdown'
      })

      if ([500, 404, 403].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        this.form._saving = false
        return
      }

      if (data) {
        this.detailMi = data
        if (data.witnesses) {
          if (data.witnesses.direct) {
            this.form.direct_witnesses = data.witnesses.direct.map(direct => {
              return {
                detail: direct
              }
            })
          }
          if (data.witnesses.indirect) {
            this.form.indirect_witnesses = data.witnesses.indirect.map(indirect => {
              return {
                detail: indirect
              }
            })
          }
        }
        if (data.cost_breakdown && data.cost_breakdown.data) {
          this.form.damage_costs = data.cost_breakdown.data.map(cost => {
            return {
              'description': cost.description,
              'cost': cost.value,
              'total': cost.value,
              'remarks': ''
            }
          })
          this.form.loss_costs = data.cost_breakdown.data.map(cost => {
            return {
              'description': cost.description,
              'cost': cost.value,
              'total': cost.value,
              'remarks': ''
            }
          })
        }

        if (data.type_coverage && data.type_coverage === 'Hull & Machinery') {
          await this.handleFetchLossRatioInput()
        } else {
          await this.handleFetchLossRatio()
        }
      }
    },
    async fetchDetailRCAFinal () {
      const { data, status } = await this.getRCAFinal(this.$route.query.formId)

      if ([500, 404, 403, 'error'].includes(status)) {
        this.form._saving = false
        return
      }

      if (data) {
        this.form.images = data.images
        this.form.starting_date = data.starting_date
        this.form.closing_date = data.closing_date
        this.form.investigation_teams = data.investigation_teams
        // TODO delete soon if not used anymore
        // this.form.direct_witnesses = []
        // this.form.indirect_witnesses = []
        // data.direct_witnesses.map(d => {
        //   this.form.direct_witnesses.push({
        //     detail: this.listRootCauseAnalysis.find(rca => rca.id === d)
        //   })
        // })
        // data.indirect_witnesses.map(d => {
        //   this.form.indirect_witnesses.push({
        //     detail: this.listRootCauseAnalysis.find(rca => rca.id === d)
        //   })
        // })
        this.form.supporting_documents = data.supporting_documents
        this.form.images = data.images
        this.form.document_controls = data.document_controls
        this.form.damage_costs = data.damage_costs.length > 0 ? data.damage_costs : this.form.damage_costs
        this.form.problem = data.problem
        this.form.cause_of_loss = data.cause_of_loss
        this.form.impact = data.impact
        this.form.loss_costs = data.loss_costs
        this.form.findings = data.findings
        this.form.conclusions = data.conclusions
        this.form.report_number = data.report_number
        this.form.recommendation = data.recommendation
      }
    },
    async fetchSurvey () {
      const { data, status } = await this.getClaimAnalysisByFormId({
        id: this.$route.query.formId,
        survey: 'init_claim'
      })

      if ([500, 404, 403].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        return
      }

      this.detailSurvey = data

      if (this.detailSurvey.headers && this.detailFillSurvey.headers) {
        this.detailSurvey.headers.map((header, headerIndex) => {
          if (this.detailFillSurvey.headers[headerIndex] && header.contents) {
            header.contents.map((content, contentIndex) => {
              if (this.detailFillSurvey.headers[headerIndex].contents[contentIndex] &&
                this.detailFillSurvey.headers[headerIndex].contents[contentIndex].value &&
                content.input) {
                content.input.value = this.detailFillSurvey.headers[headerIndex].contents[contentIndex].value.value_anything
              }
            })
          }
        })
      }
    },
    async fetchFormSurvey () {
      const { data, status } = await this.getFormClaimAnalysisByFormId({
        id: this.$route.query.formId,
        survey: 'init_claim'
      })

      if ([500, 404, 403].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        return
      }

      this.detailFillSurvey = data
    },
    async handleFetchLossRatio () {
      const { data, status } = await this.getLossRatio({
        data: {
          id: this.$route.query.formId,
          all: true
        }
      })

      if ([500, 404, 403, 'error'].includes(status)) {
        return false
      }

      if (data) {
        this.detailLossRatio = data
      }
    },
    async handleFetchLossRatioInput () {
      const { data, status } = await this.getLossRatioInput({
        data: {
          id: this.$route.query.formId,
          all: true

        }
      })

      if ([500, 404, 403].includes(status)) {
        return
      }

      this.form.sources = []

      if (data.individual) {
        this.detailLossRatio.push({
          'individual': data.individual
        })
      }

      if (data.accumulative) {
        this.detailLossRatio.push({
          'accumulative': data.accumulative
        })
      }
    },

    async onImagesFileChanged (event) {
      const files = [...event.target.files].map(file => {
        const reader = new FileReader()
        return new Promise(resolve => {
          reader.onload = () => resolve(reader.result)
          reader.readAsDataURL(file)
        })
      })
      const res = await Promise.all(files)
      if (res) {
        res.map(r => {
          this.form.images.push(r)
        })
      }
    },
    addInvestigationTeam () {
      this.form.investigation_teams.push({
        'team': '',
        'name': '',
        'position': '',
        'remarks': ''
      })
    },
    removeInvestigationTeam (index) {
      this.form.investigation_teams.splice(index, 1)
    },
    // TODO delete soon if not used anymore
    // addDirectWitnesses () {
    //   this.form.direct_witnesses.push({
    //     detail: undefined
    //   })
    // },
    // removeDirectWitnesses (index) {
    //   this.form.direct_witnesses.splice(index, 1)
    // },
    // addIndirectWitnesses () {
    //   this.form.indirect_witnesses.push({
    //     detail: undefined
    //   })
    // },
    // removeIndirectWitnesses (index) {
    //   this.form.indirect_witnesses.splice(index, 1)
    // },
    addDocumentControls () {
      this.form.document_controls.push({
        'document': '',
        'remarks': '',
        'status': ''
      })
    },
    removeDocumentControls (index) {
      this.form.document_controls.splice(index, 1)
    },
    addSupportingDocuments () {
      this.form.supporting_documents.push({
        'document': '',
        'file': '',
        'original': 'x',
        'copy': 'x',
        'pic': '',
        'remarks': ''
      })
    },
    removeSupportingDocuments (index) {
      this.form.supporting_documents.splice(index, 1)
    },
    async onSupportingDocumentsFileChanged (index, event) {
      const { data, status } = await this.handleFileRemoteUpload(event)

      if (status === 'success') {
        this.form.supporting_documents[index].file = data.url
        this.form.supporting_documents[index].document = data.url
        return true
      }

      const reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = () => {
        this.form.supporting_documents[index].file = reader.result
        this.form.supporting_documents[index].document = reader.result
      }
    },
    addCostOfDamages () {
      this.form.damage_costs.push({
        'description': '',
        'cost': '',
        'total': '',
        'remarks': ''
      })
    },
    removeCostOfDamages (index) {
      this.form.damage_costs.splice(index, 1)
    },
    addCostLoss () {
      this.form.loss_costs.push({
        'description': '',
        'cost': '',
        'total': '',
        'remarks': ''
      })
    },
    removeCostLoss (index) {
      this.form.loss_costs.splice(index, 1)
    },
    findingsByPrefix (prefixNumber) {
      return this.form.findings.filter(finding => finding.value.includes(`D.5.${prefixNumber}-`))
    },
    addFindings (prefixNumber) {
      let filterFinding = this.findingsByPrefix(prefixNumber)
      let incrementNumber = filterFinding.length + 1

      this.form.findings.push({
        'value': `D.5.${prefixNumber}-${incrementNumber}`,
        'text': '',
        'proximity': 1
      })
    },
    removeFindings (value) {
      let index = this.form.findings.findIndex(finding => finding.value === value)
      this.form.findings.splice(index, 1)
    },
    addConclusion () {
      this.form.conclusions.push({
        'damage': '',
        'rows': [
          {
            'human': '-',
            'equipment': '-',
            'csmp': '-',
            'other': '-',
            'status': '-'
          }
        ]
      })
    },
    removeConclusion (index) {
      this.form.conclusions.splice(index, 1)
    },
    addConclusionRow (index) {
      if (this.form.conclusions[index]) {
        this.form.conclusions[index].rows.push({
          'human': '-',
          'equipment': '-',
          'csmp': '-',
          'other': '-',
          'status': '-'
        })
      }
    },
    removeConclusionRow (index, conclusionRow) {
      if (this.form.conclusions[index]) {
        this.form.conclusions[index].rows.splice(conclusionRow, 1)
      }
    },
    async onSave () {
      let payload = { ...this.form }

      // TODO delete soon if not used anymore
      // payload.direct_witnesses = payload.direct_witnesses.map(data => data.detail ? data.detail.id : null)
      // payload.indirect_witnesses = payload.indirect_witnesses.map(data => data.detail ? data.detail.id : null)
      delete payload.direct_witnesses
      delete payload.indirect_witnesses

      this.form._saving = true

      const { data, status } = await this.createRCAFinal({
        form_id: this.$route.query.formId,
        ...payload
      })

      if (status === 422) {
        this.$swal(
          `Oops!`,
          'Semua Field Wajib Diisi',
          'error'
        )
        this.form._saving = false
        return
      }

      if ([500, 404, 403].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        this.form._saving = false
        return
      }

      this.$swal(
        `Tersimpan`,
        'Data Tersimpan',
        'success'
      )

      setTimeout(() => {
        this.$router.push('/mi/list-initial')
      }, 1500)

      this.form._saving = false
    },
    async handleFileRemoteUpload (event) {
      const files = [...event.target.files]
      let file = files[0]

      let formData = new FormData()
      formData.append('file', file)

      const { data, status } = await this.upload(formData)

      if (status === 'error') {
        return {
          status
        }
      }

      return {
        data, status
      }
    }
  }
}
</script>
