var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "m-2"
  }, [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex w-100 align-items-center justify-content-end"
  }, [_c('b-form-group', [!_vm.isImmutable ? _c('b-button', {
    staticClass: "mt-3",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.onDisableImmutable
    }
  }, [_c('i', {
    staticClass: "fa fa-pen mr-1"
  }), _vm._v(" Edit Periode")]) : _c('b-button', {
    staticClass: "mt-3",
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": _vm.onDisableImmutable
    }
  }, [_c('i', {
    staticClass: "fa fa-ban mr-1"
  }), _vm._v("Cancel")])], 1)], 1)]), _c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('g-gantt-chart', {
    attrs: {
      "chart-start": _vm.ganttChartData.chartStart,
      "chart-end": _vm.ganttChartData.chartEnd,
      "grid": _vm.ganttChartData.grid,
      "grid-size": _vm.ganttChartData.gridSize,
      "hide-timeaxis": _vm.ganttChartData.hideTimeaxis,
      "push-on-overlap": _vm.ganttChartData.pushOnOverlap,
      "snap-back-on-overlap": "",
      "precision": _vm.ganttChartData.precision,
      "is-magnetic": _vm.ganttChartData.isMagnetic,
      "row-label-width": _vm.ganttChartData.rowLabelWidth,
      "row-height": _vm.ganttChartData.rowHeight,
      "theme": _vm.theme,
      "width": _vm.ganttChartData.width,
      "height": _vm.ganttChartData.height,
      "allow-add": _vm.ganttChartData.allowAdd
    },
    on: {
      "dragend-bar": function dragendBar($event) {
        return _vm.onDragend($event);
      }
    }
  }, _vm._l(_vm.ganttChartData.rows, function (row) {
    return _c('g-gantt-row', {
      key: row.label,
      attrs: {
        "label": row.label,
        "row-style": row.style,
        "bars": row.bars
      },
      scopedSlots: _vm._u([{
        key: "bar-label",
        fn: function fn(_ref) {
          var bar = _ref.bar;
          return [_c('div', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.top",
              value: bar.label,
              expression: "bar.label",
              modifiers: {
                "top": true
              }
            }],
            staticClass: "d-flex w-100 align-items-center justify-content-between px-2"
          }, [_c('span', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(bar.label))])])];
        }
      }], null, true)
    });
  }), 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }