<template>
  <b-container fluid>
    <form  @submit="onSave"  action="#">
      <b-row>
        <b-col md="12" v-if="!previewData">
          <div class="d-flex align-items-center justify-content-end w-100">
            <b-button
                :disabled="saving"
                variant="primary"
                type="submit"
              >
                <span
                  v-if="saving"
                  class="spinner-border spinner-border-sm"
                />
                <div v-else>
                  <i class="fa fa-save"></i>
                  <span>Submit Data</span>
                </div>
              </b-button>
          </div>
        </b-col>
        <b-col md="12" v-else>
          <div class="d-flex align-items-center justify-content-end w-100">
            <b-button
              variant="primary"
              type="button"
              @click="$nextTick(() => $bvModal.hide('form-inventory'))"
            >
              <div>
                <i class="fa fa-check"></i>
                <span>Select MaterialCategory</span>
              </div>
            </b-button>
          </div>
        </b-col>
        <b-col lg="12" class="mb-4">
          <b-row>
            <b-col md="12">
              <b-form-group label="*Code" label-for="code">
                <span v-if="previewData" class="text-capitalize">{{ form.code }}</span>
                <b-form-input
                  v-else
                  id="code"
                  type="text"
                  placeholder="Type text"
                  v-model="form.code"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="*Name" label-for="name">
                <span v-if="previewData" class="text-capitalize">{{ form.name }}</span>
                <b-form-input
                  v-else
                  id="name"
                  type="text"
                  placeholder="Type text"
                  v-model="form.name"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Description" label-for="description">
                <span v-if="previewData" class="text-capitalize">{{ form.description }}</span>
                <b-form-textarea
                  v-else
                  id="description"
                  type="text"
                  placeholder="Type text"
                  v-model="form.description"
                ></b-form-textarea>
              </b-form-group>
            </b-col>  
            <b-col md="12">
              <b-form-group label="Status" label-for="status">
                <b-form-checkbox name="check-button" switch  v-model="form.active">Active / Inactive
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="For Fleet Type" label-for="vehicleType">
                <v-multiselect
                  v-model="form.vehicleType"
                  placeholder="Search Fleet Type"
                  label="name"
                  track-by="id"
                  :options="optionsFleetType"
                  :multiple="true"
                >
                  <span slot="noResult">Fleet type not found.</span>
                </v-multiselect>
                <b-form-checkbox
                  name="check-button"
                  switch
                  inline
                  v-model="selectAll"
                >
                  Select All Fleet Type 
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>
<script>
  import _ from 'lodash'
  import { busdevActions, fleetsActions } from '@src/Utils/helper'
  import moment from 'moment'

  export default {
    name: 'FormMaterialCategory',
    props:{
      action:{
        type:String,
        default:''
      },
      refreshData: {
        type: Function,
        default:() => {}
      },
      editId: {
        type: Number,
        default: null
      }, 
      previewData: {
        type: Boolean,
        default: false
      }, 
    },
    data() {
      return {
        loading:false,
        saving:false,

        selectAll:false,

        optionsFleetType:[],

        form:{
          name:'',
          code:'',
          description:'',
          active:true,
          createdBy:JSON.parse(localStorage.getItem('profile')).userDetail.name,
          vehicleType:[]
        }
      }
    },
    components:{},
    watch:{
      'editId': {
        handler(value) {
          if(value){
            this.getMaterialCategoryById(value)
          } else {
            this.resetForm()
          }
        },
        immediate: true,
        deep:true
      },
      'selectAll': {
        handler(value) {
          if(value){
            this.form.vehicleType = this.optionsFleetType
          } else {
            this.form.vehicleType = []
          }
        },
        immediate: true,
      },
    },
    computed: {},
    async mounted() {
      this.fetchFleetTypes()
    },
    methods:{
      ...busdevActions,
      ...fleetsActions,
      async fetchFleetTypes () {
        let res = await this.getFleetTypes()
        this.optionsFleetType = res?.data?.length > 0 ? res?.data : []
      },

      resetForm(){
        this.form = {
          name:'',
          code:'',
          description:'',
          active:true,
          createdBy:JSON.parse(localStorage.getItem('profile')).userDetail.name,
          vehicleType:[]
        }
      },
      async getMaterialCategoryById(id){
        const res = await this.fetchMaterialCategoryById({id: this.editId})

        if(res.status === 'success'){
          let data = res.data.data

          this.form = {
            name: data?.name,
            code: data?.code,
            description: data?.description,
            active: data?.active,
            createdBy:JSON.parse(localStorage.getItem('profile')).userDetail.name,
            vehicleType: data?.vehicleType?.length > 0 ? data?.vehicleType : []
          }
        }
      },

      async onSave(e) {
        e.preventDefault()
        this.saving = true

        let payload = {
          ...this.form,
        }

        if(payload.vehicleType.length > 0){
          payload.vehicleType = payload.vehicleType.map(val => val.id)
        }

        console.log(payload)

        let res = null
        if(this.editId){
          res = await this.updateMaterialCategory({data: payload, id: this.editId})
        } else {
          res = await this.createMaterialCategory({data: payload})
        }

        if([500, 404, 403, 422].includes(res.status)){
          this.saving = false
          this.$swal(`Oops.`, res?.data?.message, 'error')
          return
        }

        if(res?.data?.status === true){
          this.refreshData()
          this.$root.$emit('bv::toggle::collapse', `form-category`)
          this.$swal(`Success!`, `Create Material Category Success.`, 'success')
        } else {
          this.$swal(`Oops.`, res?.data?.message, 'error')
        }

        this.saving = false
      }
    }
  }
</script>