<template>
  <b-row>
    <b-col md="12">
      <div class="btn-group">
        <b-button
          v-b-toggle.collapse-filter
          href="#collapse-filter"
          @click.prevent
          variant="light"
        >
          <i class="fa fa-filter" /> Filter
        </b-button>
        <b-button
          v-b-toggle.upcoming-job
          href="#upcoming-job"
          @click.prevent
          variant="dark"
        >
          <i class="fa fa-info" /> Jobs Maintenance Information
          <b-badge variant="warning">10</b-badge>
        </b-button> 
      </div>
    </b-col>
    <b-col md="12">
      <b-collapse id="collapse-filter" class="mt-2">
        <b-card>
          <b-row>
            <b-col md="4">
              <b-form-group label="Category:" label-for="kind-fleet">
                <template v-for="(item, index) in vehicleTypes">
                  <b-form-checkbox
                    v-model="item.checked"
                    name="check-button"
                    switch
                    inline
                    :key="index"
                  >
                    {{ item.name }}
                  </b-form-checkbox>
                </template>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Cari">
                <b-form-input
                  type="text"
                  v-model="params.search"
                  size="xs"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label-for="dateIssued"
                label="Category Ownership"
              >
                <b-form-select
                  plain
                  :options="categoryOwnership"
                  id="gender"
                  v-model="params.ownership"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="1">
              <b-form-group label="Filter" label-for="button_search">
                <b-button
                  @click="fetchFleetActivities"
                  variant="primary"
                  class="mb-3 mr-1"
                >
                  <i class="fa fa-filter"></i>
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-collapse>
      <b-collapse id="upcoming-job" class="mt-2">
        <b-card>
          <div
            v-for="(vehicle, indexVehicle) in maintenance.data"
            :key="indexVehicle"
            class="bg-white shadow rounded px-3 pt-3 pb-3 border border-white mb-3"
          >
            <div v-if="vehicle.jobs.length === 0">No Job</div>
            <template v-for="(job, indexJob) in vehicle.jobs">
              <div class="flex justify-between mb-2" :key="indexJob">
                <b-btn
                  block
                  href="#"
                  v-b-toggle="
                    'maintenance-job-' + indexVehicle + '-' + indexJob
                  "
                  variant="secondary"
                  class="text-left"
                >
                  {{ job.jobName }}
                  <b-badge
                    v-if="job.statusDetail"
                    variant="danger"
                    class="ml-2"
                    >{{ job.statusDetail }}</b-badge
                  >
                  <span class="when-opened text-right pull-right">
                    <i class="fa fa-arrow-down" />
                  </span>
                  <span class="when-closed text-right pull-right">
                    <i class="fa fa-arrow-up" />
                  </span>
                </b-btn>
              </div>
              <b-collapse
                :id="'maintenance-job-' + indexVehicle + '-' + indexJob"
                role="tabpanel"
              >
                <b-row>
                  <b-col md="12">
                    <table class="table mb-0">
                      <thead class="text-center">
                        <tr>
                          <td>Component Name</td>
                          <td>Frequency</td>
                          <td>Last Maintenance</td>
                          <td>Current</td>
                          <td>Due Date / Hours</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ job.componentName }}</td>
                          <td>{{ job.interval }}</td>
                          <td>{{ job.lastMaintenanceDate }}</td>
                          <td>{{ job.lastMaintenanceDate }}</td>
                          <td>
                            <div>
                              <small>Last Due #1 : {{ job.dueDate }}</small>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
              </b-collapse>
            </template>
            <b-row class="mt-3">
              <b-col md="6">
                <h5>{{ vehicle.group }}</h5>
              </b-col>
            </b-row>
          </div>
          <!--                  <b-row class="text-center mt-5">-->
          <!--                    <b-col>-->
          <!--                      <b-pagination-->
          <!--                        v-model="currentPage"-->
          <!--                        :total-rows="rows"-->
          <!--                        :per-page="perPage"-->
          <!--                        first-number-->
          <!--                        align="center"-->
          <!--                      ></b-pagination>-->
          <!--                    </b-col>-->
          <!--                  </b-row>-->
        </b-card>
        <!-- Edit Fleet Form -->
        <b-modal
          id="edit-fleet"
          size="xl"
          :no-close-on-backdrop="true"
          :header-bg-variant="modal.headerBgVariant"
          :header-text-variant="modal.headerTextVariant"
          :body-bg-variant="modal.bodyBgVariant"
          :body-text-variant="modal.bodyTextVariant"
          hide-footer
        >
          <template v-slot:modal-header="{ close }">
            <h5>Edit Your Fleet</h5>
            <!-- Emulate built in modal header close button action -->
            <b-button size="md" variant="outline-danger" @click="close">
              <i class="fa fa-times"></i> Close
            </b-button>
          </template>
          <AddFleet
            v-bind:formData="fleetDetail"
            @childAction="$bvModal.hide('edit-fleet')"
          />
        </b-modal>
        <!-- End Edit Fleet Form -->
      </b-collapse>
    </b-col>
  </b-row>
</template>

<script>
import { xray } from '../../../config/pluginInit'
import AddFleet from '../../MasterPages/AddFleet.vue'

export default {
  name: 'FilterMaps',
  components:{
    AddFleet
  },
  props:{
    vehicleTypes:{
      type:Array,
      default:() => []
    }, 
    categoryOwnership:{
      type:Array,
      default:() => []
    },
    fetchFleetActivities:{
      type:Function,
      default:() => {}
    }, 
    maintenance:{
      type:Object,
      default:() => {}
    }, 
    fleetDetail:{
      type:Object,
      default:() => {}
    }, 
    close:{
      type:Function,
      default:() => {}
    },
    value: {
      type: Object,
      default: () => {
        return {
          ownership: '',
          search: '',
          page: 1,
          active: true,
          showAll: true,
        }
      },
    },
  },
  computed: {
    params: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  mounted () {
    xray.index()
  },
  data () {
    return {
      modal: {
        show: false,
        variants: [
          'primary',
          'secondary',
          'success',
          'warning',
          'danger',
          'info',
          'light',
          'dark',
        ],
        headerBgVariant: 'light',
        headerTextVariant: 'dark',
        bodyBgVariant: 'default',
        bodyTextVariant: 'dark',
        footerBgVariant: '#000000',
        footerTextVariant: 'dark',
      },
    }
  }
}
</script>
