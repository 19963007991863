<template>
  <b-sidebar
    class="p-0 m-0"
    :visible="isOpen"
    right
    @hidden="handleCloseModal"
    width="700px"
    bg-variant="white"
    header-class="header-class"
    shadow
  >
    <template #header><div></div></template>
    <template #default>
      <div class="container-slider">
        <div
          class="w-100 border-bottom d-flex justify-content-between align-items-center shadow-sm head-slider"
        >
          <i
            @click="handleCloseModal"
            class="fas fa-close cursor-pointer title"
          ></i>
          <strong class="p-0 title">Create Repair Maintenance</strong>
        </div>
        <div class="w-100 flex justify-content-center">
          <b-form @submit.prevent="handleSubmit">
            <b-row class="p-4">
              <b-col v-for="(item, i) in fields" :key="i" :sm="item.cols">
                <template v-if="item.type == 'select'">
                  <b-form-group
                    label-align="left"
                    :label-for="item.label"
                    :label="item.label"
                  >
                    <b-select
                      plain
                      :id="item.label"
                      :options="item.options"
                      size="lg"
                    ></b-select>
                  </b-form-group>
                </template>
                <template v-if="item.type == 'date'">
                  <b-form-group
                    label-align="left"
                    :label-for="item.label"
                    :label="item.label"
                  >
                    <date-picker
                      style="width: 100%"
                      format="DD MMM YYYY"
                      type="date"
                      :id="item.label"
                      placeholder="Select date"
                    ></date-picker>
                  </b-form-group>
                </template>
                <template v-if="item.type == 'file'">
                  <b-form-group
                    label-align="left"
                    :label-for="item.label"
                    :label="item.label"
                  >
                    <b-form-file
                      multiple
                      :id="item.label"
                      placeholder="Choose a file"
                      accept="application/pdf"
                    ></b-form-file>
                  </b-form-group>
                </template>
                <template v-if="item.type == 'area'">
                  <b-form-group
                    label-align="left"
                    :label-for="item.label"
                    :label="item.label"
                    placeholder=""
                  >
                    <wysiwyg :id="item.label" :style="{ height: '300px' }" />
                  </b-form-group>
                </template>
              </b-col>
              <b-col sm="6">
                <button type="submit" class="btn btn-primary w-100 p-2"
                  >Submit</button
                >
              </b-col>
            </b-row>
          </b-form>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>
<script>
export default {
  data() {
    return {
      fields: [
        {
          label: 'Fleet Type',
          type: 'select',
          value: '',
          cols: '6',
          options: [],
        },
        {
          label: 'Fleet Name',
          type: 'select',
          value: '',
          cols: '6',
          options: [],
        },
        {
          label: 'Repair Maintenance Date',
          type: 'date',
          value: '',
          cols: '6',
          options: [],
        },
        {
          label: 'Location',
          type: 'select',
          value: '',
          cols: '6',
          options: [],
        },
        {
          label: 'Breakdown Type',
          type: 'select',
          value: '',
          cols: '6',
          options: [],
        },
        {
          label: 'Equipment',
          type: 'select',
          value: '',
          cols: '6',
          options: [],
        },
        {
          label: 'Time Start',
          type: 'date',
          value: '',
          cols: '6',
          options: [],
        },
        {
          label: 'Time Start',
          type: 'date',
          value: '',
          cols: '6',
          options: [],
        },
        {
          label: 'Attachment',
          type: 'file',
          value: '',
          cols: '12',
          options: [],
        },
        {
          label: 'Issue',
          type: 'area',
          value: '',
          cols: '12',
          options: [],
        },
        {
          label: 'Remedy',
          type: 'area',
          value: '',
          cols: '12',
          options: [],
        },
      ],
    }
  },
  props: {
    isOpen: {
      default: true,
      type: Boolean,
    },
  },
  methods: {
    handleCloseModal() {
      this.$emit('close')
    },
    handleSubmit(e) {
      console.log('ahdi permana')
    },
  },
}
</script>
<style scoped>
.container-slider {
  overflow: hidden;
  position: relative;
  margin-top: 20px;
}
.head-slider {
  position: fixed;
  top: 0;
  background: #ffffff;
  z-index: 100;
  padding: 10px 14px;
}
.title {
  font-size: 16px !important;
}
</style>
