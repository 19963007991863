var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "20",
      "height": "26",
      "viewBox": "0 0 20 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M5.13902 15.7776C4.95485 15.7776 4.77821 15.7044 4.64798 15.5742C4.51774 15.4439 4.44458 15.2673 4.44458 15.0831C4.44458 14.8989 4.51774 14.7223 4.64798 14.5921C4.77821 14.4618 4.95485 14.3887 5.13902 14.3887H14.8612C15.0454 14.3887 15.2221 14.4618 15.3523 14.5921C15.4825 14.7223 15.5557 14.8989 15.5557 15.0831C15.5557 15.2673 15.4825 15.4439 15.3523 15.5742C15.2221 15.7044 15.0454 15.7776 14.8612 15.7776H5.13902ZM5.13902 19.9442C4.95485 19.9442 4.77821 19.8711 4.64798 19.7408C4.51774 19.6106 4.44458 19.434 4.44458 19.2498C4.44458 19.0656 4.51774 18.889 4.64798 18.7587C4.77821 18.6285 4.95485 18.5553 5.13902 18.5553H14.8612C15.0454 18.5553 15.2221 18.6285 15.3523 18.7587C15.4825 18.889 15.5557 19.0656 15.5557 19.2498C15.5557 19.434 15.4825 19.6106 15.3523 19.7408C15.2221 19.8711 15.0454 19.9442 14.8612 19.9442H5.13902Z",
      "fill": _vm.color
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M11.6459 0.5H2.36117C1.80863 0.5 1.27873 0.719494 0.888026 1.11019C0.497326 1.5009 0.277832 2.0308 0.277832 2.58333V23.4167C0.277832 23.9692 0.497326 24.4991 0.888026 24.8898C1.27873 25.2805 1.80863 25.5 2.36117 25.5H17.6389C18.1915 25.5 18.7214 25.2805 19.1121 24.8898C19.5028 24.4991 19.7223 23.9692 19.7223 23.4167V9.11389C19.7221 8.59232 19.5264 8.08978 19.1737 7.70556L13.182 1.175C12.9867 0.962146 12.7493 0.792223 12.4849 0.67602C12.2205 0.559818 11.9347 0.499876 11.6459 0.5ZM1.66672 2.58333C1.66672 2.39916 1.73989 2.22252 1.87012 2.09229C2.00035 1.96205 2.17699 1.88889 2.36117 1.88889H11.6459C11.7422 1.88878 11.8376 1.90873 11.9258 1.94747C12.014 1.98621 12.0932 2.04288 12.1584 2.11389L18.1501 8.64444C18.2678 8.77245 18.3332 8.93997 18.3334 9.11389V23.4167C18.3334 23.6008 18.2602 23.7775 18.13 23.9077C17.9998 24.0379 17.8231 24.1111 17.6389 24.1111H2.36117C2.17699 24.1111 2.00035 24.0379 1.87012 23.9077C1.73989 23.7775 1.66672 23.6008 1.66672 23.4167V2.58333Z",
      "fill": _vm.color
    }
  }), _c('path', {
    attrs: {
      "d": "M11.3889 8.83333H19.0278C19.212 8.83333 19.3886 8.9065 19.5188 9.03673C19.6491 9.16697 19.7222 9.3436 19.7222 9.52778C19.7222 9.71196 19.6491 9.88859 19.5188 10.0188C19.3886 10.1491 19.212 10.2222 19.0278 10.2222H10.6944C10.5103 10.2222 10.3336 10.1491 10.2034 10.0188C10.0732 9.88859 10 9.71196 10 9.52778V1.19444C10 1.01027 10.0732 0.833632 10.2034 0.703398C10.3336 0.573164 10.5103 0.5 10.6944 0.5C10.8786 0.5 11.0553 0.573164 11.1855 0.703398C11.3157 0.833632 11.3889 1.01027 11.3889 1.19444V8.83333Z",
      "fill": _vm.color
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }