var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('a', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.add-fleet",
      modifiers: {
        "add-fleet": true
      }
    }],
    staticClass: "button-float",
    attrs: {
      "id": "add-vehicle",
      "href": "#"
    }
  }, [_c('i', {
    staticClass: "fa fa-plus my-button-float"
  }), _c('i', {
    staticClass: "fa fa-plus fa fa-ship my-button-float"
  })]), _c('b-modal', {
    attrs: {
      "id": "add-fleet",
      "size": "xl",
      "no-close-on-backdrop": true,
      "header-bg-variant": _vm.modal.headerBgVariant,
      "header-text-variant": _vm.modal.headerTextVariant,
      "body-bg-variant": _vm.modal.bodyBgVariant,
      "body-text-variant": _vm.modal.bodyTextVariant,
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('h5', [_vm._v("Add More Your Fleet")]), _c('b-button', {
          attrs: {
            "size": "md",
            "variant": "outline-danger"
          },
          on: {
            "click": close
          }
        }, [_c('i', {
          staticClass: "fa fa-times"
        }), _vm._v(" Close ")])];
      }
    }])
  }, [_c('AddFleet', {
    attrs: {
      "isAddForChild": true
    },
    on: {
      "childAction": function childAction($event) {
        return _vm.$bvModal.hide('add-fleet');
      }
    }
  })], 1), _c('b-tooltip', {
    attrs: {
      "target": "add-vehicle"
    }
  }, [_vm._v("Add More Your Fleet")]), _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('div', {
    staticStyle: {
      "height": "100%",
      "width": "100%",
      "position": "fixed",
      "margin-left": "-30px",
      "margin-top": "-120px"
    }
  }, [_c('l-map', {
    staticStyle: {
      "width": "100%",
      "height": "100%"
    },
    attrs: {
      "zoom": _vm.zoom,
      "center": _vm.center,
      "options": _vm.mapOptions
    },
    on: {
      "update:center": _vm.centerUpdate,
      "update:zoom": _vm.zoomUpdate
    }
  }, [_c('l-tile-layer', {
    attrs: {
      "url": _vm.url,
      "attribution": _vm.attribution
    }
  }), _c('l-control-zoom', {
    attrs: {
      "position": "bottomleft"
    }
  }), _vm._l(_vm.newFleetActivities, function (f, i) {
    var _f$vehicleType$icon$u, _f$vehicleType, _f$vehicleType$icon;
    return [f !== null && f !== void 0 && f.latitude && f !== null && f !== void 0 && f.longitude ? [_c('l-marker', {
      key: "fleet-".concat(i),
      attrs: {
        "lat-lng": [f === null || f === void 0 ? void 0 : f.latitude, f === null || f === void 0 ? void 0 : f.longitude]
      }
    }, [_c('l-icon', {
      attrs: {
        "icon-size": _vm.iconSize,
        "icon-anchor": _vm.iconAnchor
      }
    }, [_c('img', {
      staticStyle: {
        "width": "72px"
      },
      attrs: {
        "src": (_f$vehicleType$icon$u = f === null || f === void 0 ? void 0 : (_f$vehicleType = f.vehicleType) === null || _f$vehicleType === void 0 ? void 0 : (_f$vehicleType$icon = _f$vehicleType.icon) === null || _f$vehicleType$icon === void 0 ? void 0 : _f$vehicleType$icon.url) !== null && _f$vehicleType$icon$u !== void 0 ? _f$vehicleType$icon$u : require('../../../assets/images/fleet/PusherTug.png')
      }
    })]), _c('l-popup', {
      attrs: {
        "options": {
          maxWidth: 'auto'
        },
        "lat-lng": [f === null || f === void 0 ? void 0 : f.latitude, f === null || f === void 0 ? void 0 : f.longitude]
      }
    }, [_c('div', {
      staticStyle: {
        "width": "400px"
      }
    }, [_c('h4', [_vm._v(_vm._s(f === null || f === void 0 ? void 0 : f.name))]), _c('div', {
      staticClass: "mb-5 position-absolute",
      staticStyle: {
        "right": "20px",
        "top": "10px"
      }
    }, [_c('b-dropdown', {
      attrs: {
        "size": "sm",
        "text": "Small",
        "variant": "light",
        "right": ""
      }
    }, [_c('template', {
      slot: "button-content"
    }, [_c('i', {
      staticClass: "fa fa-ellipsis-h"
    })]), _c('b-dropdown-item', {
      attrs: {
        "variant": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.fetchFleetDetail(f === null || f === void 0 ? void 0 : f.id);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-edit"
    }), _vm._v(" Edit ")]), _c('b-dropdown-item', {
      attrs: {
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.onDeleteFleet(f);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash"
    }), _vm._v(" Delete ")])], 2)], 1), _c('b-row', [_c('b-col', {
      attrs: {
        "md": "12"
      }
    }, [_c('b-card-text', [_c('b-row', {
      staticClass: "text-center"
    }, [_vm._l(f === null || f === void 0 ? void 0 : f.modules, function (m, im) {
      var _m$icon, _m$icon2;
      return [m !== null && m !== void 0 && m.hasAccess && (_vm.validateTechnicalPermission(m === null || m === void 0 ? void 0 : m.url) || _vm.validateOperationalPermission(m === null || m === void 0 ? void 0 : m.url) || _vm.validateReportPermission(m === null || m === void 0 ? void 0 : m.url)) ? _c('b-col', {
        key: "m-".concat(im),
        staticClass: "mr-2 mb-2",
        attrs: {
          "cols": "2",
          "md": "2"
        }
      }, [_c('router-link', {
        attrs: {
          "to": {
            path: m === null || m === void 0 ? void 0 : m.url,
            query: {
              vehicleId: f === null || f === void 0 ? void 0 : f.id
            }
          }
        }
      }, [m !== null && m !== void 0 && m.icon && m !== null && m !== void 0 && (_m$icon = m.icon) !== null && _m$icon !== void 0 && _m$icon.url ? _c('img', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.top",
          value: m === null || m === void 0 ? void 0 : m.name,
          expression: "m?.name",
          modifiers: {
            "top": true
          }
        }],
        staticStyle: {
          "width": "70px",
          "height": "70px"
        },
        attrs: {
          "src": m === null || m === void 0 ? void 0 : (_m$icon2 = m.icon) === null || _m$icon2 === void 0 ? void 0 : _m$icon2.url,
          "alt": "icon-image"
        }
      }) : _c('img', {
        directives: [{
          name: "b-tooltip",
          rawName: "v-b-tooltip.top",
          value: 'Operational',
          expression: "'Operational'",
          modifiers: {
            "top": true
          }
        }],
        staticStyle: {
          "width": "70px",
          "height": "70px"
        },
        attrs: {
          "src": require("@assets/images/flaticon/operational.png"),
          "alt": "icon-image"
        }
      })])], 1) : _vm._e()];
    })], 2)], 1)], 1)], 1)], 1)]), _c('l-tooltip', {
      attrs: {
        "options": {
          permanent: false,
          interactive: true
        }
      }
    }, [_vm._v(_vm._s(f === null || f === void 0 ? void 0 : f.name))])], 1)] : _vm._e()];
  })], 2)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }