<template>
  <div
    style="background: rgb(241 245 249); min-height: calc(100vh - 60px)"
    class="py-4"
  >
    <b-col md="12">
      <iq-card>
        <template #body>
          <b-row class="">
            <b-col md="3">
              <b-form-group label="Period" label-for="Period">
                <date-picker
                  type="date"
                  range
                  placeholder="Select date range"
                  :style="{ width: '100%' }"
                ></date-picker>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Status" label-for="status">
                <b-form-select plain v-model="params.status">
                  <b-form-select-option :value="null">All</b-form-select-option>
                  <b-form-select-option value="progress"
                    >Progress</b-form-select-option
                  >
                  <b-form-select-option value="completed"
                    >Completed</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Filter Option" label-for="cargo">
                <b-form-select plain v-model="filterOptSelected">
                  <b-form-select-option :value="null">All</b-form-select-option>
                  <b-form-select-option value="by-project"
                    >By Project</b-form-select-option
                  >
                  <b-form-select-option value="by-fleet"
                    >By Fleet</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="2" v-if="filterOptSelected == 'by-fleet'">
              <b-form-group label="Fleet Name" label-for="fleet-categtory">
                <v-multiselect
                  id="fleet-category"
                  :multiple="true"
                  placeholder="Select Categories"
                  label="name"
                  :options="fleetNameOpt"
                  track-by="code"
                  v-model="params.vehicleIds"
                >
                  <span slot="noResult"
                    >Oops! No elements found. Consider changing the search
                    query.</span
                  >
                </v-multiselect>
              </b-form-group>
            </b-col>
            <b-col md="3" v-if="filterOptSelected == 'by-project'">
              <b-form-group label="Project Name" label-for="fleet-categtory">
                <v-multiselect
                  id="fleet-category"
                  :multiple="true"
                  placeholder="Select Categories"
                  label="name"
                  :options="projectNameOpt"
                  track-by="code"
                  v-model="params.projectIds"
                >
                  <span slot="noResult"
                    >Oops! No elements found. Consider changing the search
                    query.</span
                  >
                </v-multiselect>
              </b-form-group>
            </b-col>
            <b-col md="2" class="d-flex align-items-end">
              <b-form-group class="w-100">
                <b-button variant="primary" class="w-100">
                  <i class="fas fa-search"></i>
                  Show Data
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-between align-items-center mb-3">
            <h5 class="mb-0"
              ><strong>Operational Data Running Shipment</strong></h5
            >
            <!-- <b-button
              variant="outline-primary"
              size="md"
              class="d-flex align-items-center"
              @click="toggleModalFilter"
            >
              <i class="fas fa-sliders"></i>
              <p class="text-truncate m-0"> Filter Data</p>
            </b-button> -->
            <b-button variant="outline-primary" class="mx-2 d-flex" size="md">
              <i class="fa fa-download"></i>
              Export
            </b-button>
          </div>
          <div>
            <div style="background: #e5e4e4" class="mb-3 p-3">
              <h5><strong>KPC Project - Transhipment</strong></h5>
            </div>
            <div class="overflow-auto">
              <table class="table mb-0 table-striped table-hover">
                <thead>
                  <th
                    v-for="(column, index) in columns"
                    :key="index"
                    class="text-truncate column-primary"
                    >{{ column }}</th
                  >
                </thead>
                <tbody class="bg-white">
                  <tr v-for="(item, index) in 4" :key="index">
                    <td>{{ index }}</td>
                    <td class="text-truncate">
                      <b-badge variant="warning">TB.SML 301</b-badge>
                      <b-badge variant="primary" class="ml-2"
                        >BG. SML 104</b-badge
                      >
                    </td>
                    <td class="text-truncate">Kintap</td>
                    <td class="text-truncate">Asam-Asam</td>
                    <td class="text-truncate">FC SAMARINDA FIDELITY & AKM 3</td>
                    <td class="text-truncate bg-primary">12.034.555</td>
                    <td class="text-truncate">Completed</td>
                    <td class="text-truncate">
                      <b-button
                        variant="primary"
                        @click="modal.freightRate = true"
                      >
                        <i class="fa fa-plus"></i>
                        Input Freight
                      </b-button>
                    </td>
                    <td class="text-truncate">26/06/2024 22.30.00</td>
                    <td class="text-truncate">26/06/2024 22.30.00</td>
                    <td class="text-truncate">26/06/2024 22.30.00</td>
                    <td class="text-truncate">26/06/2024 22.30.00</td>
                    <td class="text-truncate">26/06/2024 22.30.00</td>
                    <td class="text-truncate">26/06/2024 22.30.00</td>
                    <td class="text-truncate">26/06/2024 22.30.00</td>
                    <td class="text-truncate">26/06/2024 22.30.00</td>
                    <td class="text-truncate">26/06/2024 22.30.00</td>
                    <td class="text-truncate">26/06/2024 22.30.00</td>
                    <td class="text-truncate">26/06/2024 22.30.00</td>
                    <td class="text-truncate">26/06/2024 22.30.00</td>
                    <td class="text-truncate">26/06/2024 22.30.00</td>
                    <td class="text-truncate">26/06/2024 22.30.00</td>
                    <td class="text-truncate">26/06/2024 22.30.00</td>
                    <td class="text-truncate">26/06/2024 22.30.00</td>
                    <td class="text-truncate">26/06/2024 22.30.00</td>
                    <td class="text-truncate">26/06/2024 22.30.00</td>
                    <td class="text-truncate">26/06/2024 22.30.00</td>
                    <td class="text-truncate">26/06/2024 22.30.00</td>
                    <td class="text-truncate">26/06/2024 22.30.00</td>
                    <td class="text-truncate">26/06/2024 22.30.00</td>
                    <td class="text-truncate">26/06/2024 22.30.00</td>
                    <td class="text-truncate">26/06/2024 22.30.00</td>
                    <td class="text-truncate">27/06/2024 18.15.00</td>
                    <td class="text-truncate">0,50</td>
                    <td class="text-truncate">27/06/2024 18.45.00</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </template>
      </iq-card>
    </b-col>
    <b-modal
      v-model="modal.freightRate"
      title="Input Freight Rate"
      centered
      size="lg"
      ok-title="Submit"
      cancel-title="Close"
      title-class="title-modal"
    >
      <FormFreightRate />
    </b-modal>
  </div>
</template>
<script>
import { fleetsActions, projectsActions } from '@/src/Utils/helper'
import FormFreightRate from './FormFreightRate.vue'

export default {
  components: {
    FormFreightRate,
  },
  data() {
    return {
      fleetNameOpt: [],
      projectNameOpt: [],
      filterOptSelected: null,
      params: {
        status:null,
        projectIds: [],
        vehicleIds: [],
      },
      modal: {
        freightRate: false,
      },
      vehicleSelected: [],
      inputFreightSelected: null,
      columns: [
        'No',
        'Fleet Name',
        'POL',
        'POD',
        'Grab Type',
        'Actual COB',
        'Status',
        'Freight Rate',
        'Arrival Time POL',
        'Waiting for Berthing',
        'Alongside',
        'Waiting For Loading',
        'Commenced',
        'Completed',
        'Loading',
        'Cast Off',
        'LHV',
        'Waiting LHV',
        'Document on Board',
        'Waiting Document',
        'Time Departure',
        'Waiting Departure',
        'Arrival POD',
        'Steaming Time',
        'Anchor Up',
        'Berthing Process',
        'Alongside',
        'Waiting for Berthing',
        'Discharge',
        'Cast Off',
        'Waiting Full Away',
        'Departure To POL',
        'Arrival Time POL',
        'Steaming Time',
        'Total Steaming Time',
      ],
      rows: [
        {
          showModal: false,
          row: '',
        },
      ],
    }
  },
  methods: {
    ...fleetsActions,
    ...projectsActions,
    async fetchFleets() {
      let params = {
        page: 1,
        perPage: 1000,
      }
      try {
        this.fleetNameOpt = []
        const res = await this.getFleets(params)
        res.data.forEach((el) => {
          this.fleetNameOpt.push({
            name: el.name,
            code: el.id,
          })
        })
      } catch (error) {
        console.log(error)
      }
    },
    async fetchProjects() {
      let params = {
        page: 1,
        perPage: 1000,
      }
      try {
        this.projectNameOpt = []
        const res = await this.getProjects(params)
        console.log('res', res)
        res.data.data.forEach((el) => {
          this.projectNameOpt.push({
            name: el.projectName,
            code: el.id,
          })
        })
      } catch (error) {
        console.log(error)
      }
    },
    onFilter() {},
  },
  computed: {
    userVehicles() {
      return this.$store.state.auth.profile.vehicle || []
    },
    vehicleTypes() {
      let vehicleTypes = []

      this.userVehicles.forEach((vehicle) => {
        const vehicleTypeExist = vehicleTypes.find(
          (v) => v.id === vehicle.vehicleType.id
        )
        if (!vehicleTypeExist) {
          vehicle.vehicleType.checked = true
          vehicleTypes.push(vehicle.vehicleType)
        }
      })

      return vehicleTypes
    },
  },
  mounted() {
    this.fetchFleets()
    this.fetchProjects()
  },
}
</script>
<style>
.column-primary {
  background: #049fb0;
  color: white;
  /* padding: 4px 12px;
  vertical-align: middle !important; */
}
.title-modal {
  font-weight: 500 !important;
}
</style>
