var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-sidebar', {
    attrs: {
      "id": "modal---".concat(_vm.id),
      "title": _vm.modalTitle,
      "width": "100%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _vm$detailTask, _vm$detailTask$dockin, _vm$detailTask$dockin2, _JSON$parse$name, _JSON$parse, _vm$detailTask2, _vm$detailTask3, _vm$detailTask3$docki, _vm$detailTask3$docki2, _vm$detailTask4, _vm$detailTask4$docki, _vm$detailTask5, _vm$detailTask5$docki, _vm$detailTask$dockin3, _vm$detailTask6, _vm$detailTask6$docki, _vm$detailTask$dockin4, _vm$detailTask7, _vm$detailTask7$docki, _vm$detailTask$dockin5, _vm$detailTask8, _vm$detailTask8$docki, _vm$detailTask$dockin6, _vm$detailTask9, _vm$detailTask9$docki, _vm$detailTask$dockin7, _vm$detailTask10, _vm$detailTask10$dock, _vm$detailTask$dockin8, _vm$detailTask11, _vm$detailTask11$dock, _vm$detailTask$dockin9, _vm$detailTask12, _vm$detailTask12$dock, _vm$detailTask13, _vm$detailTask13$dock, _vm$detailTask13$dock2, _vm$detailTask14, _vm$detailTask14$dock, _vm$detailTask14$dock2, _vm$detailTask$jobSta, _vm$detailTask15, _vm$detailTask$jobEst, _vm$detailTask16, _vm$detailTask$jobWor, _vm$detailTask17, _vm$detailTask18, _vm$detailTask18$jobD, _vm$detailTask19, _vm$detailTask19$jobD, _JSON$parse$name2, _JSON$parse2, _vm$detailTask20, _JSON$parse$name3, _JSON$parse3, _vm$detailTask21, _vm$detailTask22, _vm$detailTask23, _vm$detailTask23$manP, _vm$detailTask24, _vm$detailTask25, _vm$detailTask25$subC, _vm$detailTask26, _vm$activities;
        var hide = _ref.hide;
        return [_vm.loading ? _c('div', {
          staticClass: "text-center"
        }, [_vm._v(" Loading Content... ")]) : _c('b-row', {
          staticClass: "m-3"
        }, [_c('b-col', {
          attrs: {
            "md": "7"
          }
        }, [_vm.selectedTaskId ? _c('div', [_c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 text-primary"
        }, [_vm._v("Docking Info")]), _c('table', {
          staticClass: "table table-striped"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Nama Shipyard*")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$detailTask = _vm.detailTask) === null || _vm$detailTask === void 0 ? void 0 : (_vm$detailTask$dockin = _vm$detailTask.dockingSchedule) === null || _vm$detailTask$dockin === void 0 ? void 0 : (_vm$detailTask$dockin2 = _vm$detailTask$dockin.shipyard) === null || _vm$detailTask$dockin2 === void 0 ? void 0 : _vm$detailTask$dockin2.name))]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("PIC*")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_JSON$parse$name = (_JSON$parse = JSON.parse((_vm$detailTask2 = _vm.detailTask) === null || _vm$detailTask2 === void 0 ? void 0 : _vm$detailTask2.createdBy)) === null || _JSON$parse === void 0 ? void 0 : _JSON$parse.name) !== null && _JSON$parse$name !== void 0 ? _JSON$parse$name : '-'))])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Jenis Pekerjaan*")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$detailTask3 = _vm.detailTask) === null || _vm$detailTask3 === void 0 ? void 0 : (_vm$detailTask3$docki = _vm$detailTask3.dockingSchedule) === null || _vm$detailTask3$docki === void 0 ? void 0 : (_vm$detailTask3$docki2 = _vm$detailTask3$docki.dockingTaskType) === null || _vm$detailTask3$docki2 === void 0 ? void 0 : _vm$detailTask3$docki2.name))]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Estimasi Budget")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$detailTask4 = _vm.detailTask) !== null && _vm$detailTask4 !== void 0 && (_vm$detailTask4$docki = _vm$detailTask4.dockingSchedule) !== null && _vm$detailTask4$docki !== void 0 && _vm$detailTask4$docki.budgetEstimation ? _vm.formatCurrency((_vm$detailTask5 = _vm.detailTask) === null || _vm$detailTask5 === void 0 ? void 0 : (_vm$detailTask5$docki = _vm$detailTask5.dockingSchedule) === null || _vm$detailTask5$docki === void 0 ? void 0 : _vm$detailTask5$docki.budgetEstimation) : '-'))])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("TA. Shipyard*")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$detailTask$dockin3 = (_vm$detailTask6 = _vm.detailTask) === null || _vm$detailTask6 === void 0 ? void 0 : (_vm$detailTask6$docki = _vm$detailTask6.dockingSchedule) === null || _vm$detailTask6$docki === void 0 ? void 0 : _vm$detailTask6$docki.arrival) !== null && _vm$detailTask$dockin3 !== void 0 ? _vm$detailTask$dockin3 : '-'))]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Floating Repair")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$detailTask$dockin4 = (_vm$detailTask7 = _vm.detailTask) === null || _vm$detailTask7 === void 0 ? void 0 : (_vm$detailTask7$docki = _vm$detailTask7.dockingSchedule) === null || _vm$detailTask7$docki === void 0 ? void 0 : _vm$detailTask7$docki.floatingRepair) !== null && _vm$detailTask$dockin4 !== void 0 ? _vm$detailTask$dockin4 : '-'))])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Upslip*")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$detailTask$dockin5 = (_vm$detailTask8 = _vm.detailTask) === null || _vm$detailTask8 === void 0 ? void 0 : (_vm$detailTask8$docki = _vm$detailTask8.dockingSchedule) === null || _vm$detailTask8$docki === void 0 ? void 0 : _vm$detailTask8$docki.upSlip) !== null && _vm$detailTask$dockin5 !== void 0 ? _vm$detailTask$dockin5 : '-'))]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Downslip")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$detailTask$dockin6 = (_vm$detailTask9 = _vm.detailTask) === null || _vm$detailTask9 === void 0 ? void 0 : (_vm$detailTask9$docki = _vm$detailTask9.dockingSchedule) === null || _vm$detailTask9$docki === void 0 ? void 0 : _vm$detailTask9$docki.downSlip) !== null && _vm$detailTask$dockin6 !== void 0 ? _vm$detailTask$dockin6 : '-'))])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Estimation Complete / Delivery")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$detailTask$dockin7 = (_vm$detailTask10 = _vm.detailTask) === null || _vm$detailTask10 === void 0 ? void 0 : (_vm$detailTask10$dock = _vm$detailTask10.dockingSchedule) === null || _vm$detailTask10$dock === void 0 ? void 0 : _vm$detailTask10$dock.deliveryDate) !== null && _vm$detailTask$dockin7 !== void 0 ? _vm$detailTask$dockin7 : '-'))]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }), _c('td', {
          attrs: {
            "width": "25%"
          }
        })]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Waiting Space")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$detailTask$dockin8 = (_vm$detailTask11 = _vm.detailTask) === null || _vm$detailTask11 === void 0 ? void 0 : (_vm$detailTask11$dock = _vm$detailTask11.dockingSchedule) === null || _vm$detailTask11$dock === void 0 ? void 0 : _vm$detailTask11$dock.waitingDockSpace) !== null && _vm$detailTask$dockin8 !== void 0 ? _vm$detailTask$dockin8 : '-') + " Hari")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Target Days")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("60 Hari")])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Estimasi Tonase")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$detailTask$dockin9 = (_vm$detailTask12 = _vm.detailTask) === null || _vm$detailTask12 === void 0 ? void 0 : (_vm$detailTask12$dock = _vm$detailTask12.dockingSchedule) === null || _vm$detailTask12$dock === void 0 ? void 0 : _vm$detailTask12$dock.tonageEstimation) !== null && _vm$detailTask$dockin9 !== void 0 ? _vm$detailTask$dockin9 : '-') + " Ton")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Dokumen Shipyard")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [(_vm$detailTask13 = _vm.detailTask) !== null && _vm$detailTask13 !== void 0 && (_vm$detailTask13$dock = _vm$detailTask13.dockingSchedule) !== null && _vm$detailTask13$dock !== void 0 && (_vm$detailTask13$dock2 = _vm$detailTask13$dock.docShipyard) !== null && _vm$detailTask13$dock2 !== void 0 && _vm$detailTask13$dock2.fileName ? _c('a', {
          attrs: {
            "href": (_vm$detailTask14 = _vm.detailTask) === null || _vm$detailTask14 === void 0 ? void 0 : (_vm$detailTask14$dock = _vm$detailTask14.dockingSchedule) === null || _vm$detailTask14$dock === void 0 ? void 0 : (_vm$detailTask14$dock2 = _vm$detailTask14$dock.docShipyard) === null || _vm$detailTask14$dock2 === void 0 ? void 0 : _vm$detailTask14$dock2.url,
            "target": "_blank"
          }
        }, [_c('img', {
          staticStyle: {
            "width": "30px"
          },
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })]) : _vm._e()])])])])]), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Info Pekerjaan")]), _c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Start Date")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$detailTask$jobSta = (_vm$detailTask15 = _vm.detailTask) === null || _vm$detailTask15 === void 0 ? void 0 : _vm$detailTask15.jobStart) !== null && _vm$detailTask$jobSta !== void 0 ? _vm$detailTask$jobSta : '-'))]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Est. Finish")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$detailTask$jobEst = (_vm$detailTask16 = _vm.detailTask) === null || _vm$detailTask16 === void 0 ? void 0 : _vm$detailTask16.jobEstFinish) !== null && _vm$detailTask$jobEst !== void 0 ? _vm$detailTask$jobEst : '-'))])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Bobot Pekerjaan")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$detailTask$jobWor = (_vm$detailTask17 = _vm.detailTask) === null || _vm$detailTask17 === void 0 ? void 0 : _vm$detailTask17.jobWorkloadPercentage) !== null && _vm$detailTask$jobWor !== void 0 ? _vm$detailTask$jobWor : '0') + "%")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Dokumen")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [(_vm$detailTask18 = _vm.detailTask) !== null && _vm$detailTask18 !== void 0 && (_vm$detailTask18$jobD = _vm$detailTask18.jobDoc) !== null && _vm$detailTask18$jobD !== void 0 && _vm$detailTask18$jobD.fileName ? _c('a', {
          attrs: {
            "href": (_vm$detailTask19 = _vm.detailTask) === null || _vm$detailTask19 === void 0 ? void 0 : (_vm$detailTask19$jobD = _vm$detailTask19.jobDoc) === null || _vm$detailTask19$jobD === void 0 ? void 0 : _vm$detailTask19$jobD.url,
            "target": "_blank"
          }
        }, [_c('img', {
          staticStyle: {
            "width": "30px"
          },
          attrs: {
            "src": require("../../assets/images/activity/pdf.png"),
            "alt": "gallary-image"
          }
        })]) : _vm._e()])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Created By")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_JSON$parse$name2 = (_JSON$parse2 = JSON.parse((_vm$detailTask20 = _vm.detailTask) === null || _vm$detailTask20 === void 0 ? void 0 : _vm$detailTask20.createdBy)) === null || _JSON$parse2 === void 0 ? void 0 : _JSON$parse2.name) !== null && _JSON$parse$name2 !== void 0 ? _JSON$parse$name2 : '-'))]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Review By")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_JSON$parse$name3 = (_JSON$parse3 = JSON.parse((_vm$detailTask21 = _vm.detailTask) === null || _vm$detailTask21 === void 0 ? void 0 : _vm$detailTask21.reviewedBy)) === null || _JSON$parse3 === void 0 ? void 0 : _JSON$parse3.name) !== null && _JSON$parse$name3 !== void 0 ? _JSON$parse$name3 : '-'))])]), _c('tr', [_c('th', {
          staticClass: "align-top"
        }, [_vm._v("Notes")]), _c('td', {
          staticClass: "text-center align-top"
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "colspan": "4"
          }
        }, [_vm._v(" " + _vm._s((_vm$detailTask22 = _vm.detailTask) === null || _vm$detailTask22 === void 0 ? void 0 : _vm$detailTask22.notes) + " ")])])])])]), ((_vm$detailTask23 = _vm.detailTask) === null || _vm$detailTask23 === void 0 ? void 0 : (_vm$detailTask23$manP = _vm$detailTask23.manPowers) === null || _vm$detailTask23$manP === void 0 ? void 0 : _vm$detailTask23$manP.length) > 0 ? _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Internal Man Power")]), _c('table', {
          staticClass: "table table-striped table-sm"
        }, [_c('tbody', [_c('tr', [_c('th', {
          staticClass: "pl-3",
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("NAMA LENGKAP")]), _c('th', {
          attrs: {
            "width": "50%"
          }
        }, [_vm._v("JABATAN")])]), _vm._l((_vm$detailTask24 = _vm.detailTask) === null || _vm$detailTask24 === void 0 ? void 0 : _vm$detailTask24.manPowers, function (state, index) {
          var _state$name, _state$position;
          return _c('tr', {
            key: index
          }, [_c('td', {
            staticClass: "pl-3"
          }, [_vm._v(_vm._s((_state$name = state === null || state === void 0 ? void 0 : state.name) !== null && _state$name !== void 0 ? _state$name : '-'))]), _c('td', [_vm._v(_vm._s((_state$position = state === null || state === void 0 ? void 0 : state.position) !== null && _state$position !== void 0 ? _state$position : '-'))])]);
        })], 2)])]) : _vm._e(), ((_vm$detailTask25 = _vm.detailTask) === null || _vm$detailTask25 === void 0 ? void 0 : (_vm$detailTask25$subC = _vm$detailTask25.subContractors) === null || _vm$detailTask25$subC === void 0 ? void 0 : _vm$detailTask25$subC.length) > 0 ? _vm._l((_vm$detailTask26 = _vm.detailTask) === null || _vm$detailTask26 === void 0 ? void 0 : _vm$detailTask26.subContractors, function (state, index) {
          var _state$name2, _state$pic, _state$notes, _state$contractorDoc, _state$contractorDoc2;
          return _c('fieldset', {
            key: index,
            staticClass: "form-group border p-3"
          }, [_c('legend', {
            staticClass: "w-auto px-2"
          }, [_vm._v("Sub Kontraktor #" + _vm._s(index + 1))]), _c('table', {
            staticClass: "table table-striped"
          }, [_c('tbody', [_c('tr', [_c('th', {
            attrs: {
              "width": "20%"
            }
          }, [_vm._v("Nama Kontraktor ")]), _c('td', {
            staticClass: "text-center",
            attrs: {
              "width": "5%"
            }
          }, [_vm._v(":")]), _c('td', {
            attrs: {
              "width": "25%"
            }
          }, [_vm._v(_vm._s((_state$name2 = state === null || state === void 0 ? void 0 : state.name) !== null && _state$name2 !== void 0 ? _state$name2 : '-'))]), _c('th', {
            attrs: {
              "width": "20%"
            }
          }, [_vm._v("Executive Engineer")]), _c('td', {
            staticClass: "text-center",
            attrs: {
              "width": "5%"
            }
          }, [_vm._v(":")]), _c('td', {
            attrs: {
              "width": "25%"
            }
          }, [_vm._v(_vm._s((_state$pic = state === null || state === void 0 ? void 0 : state.pic) !== null && _state$pic !== void 0 ? _state$pic : '-'))])]), _c('tr', [_c('th', {
            attrs: {
              "width": "20%"
            }
          }, [_vm._v("Penawaran")]), _c('td', {
            staticClass: "text-center",
            attrs: {
              "width": "5%"
            }
          }, [_vm._v(":")]), _c('td', {
            attrs: {
              "width": "25%"
            }
          }, [_vm._v(_vm._s(state !== null && state !== void 0 && state.offer ? _vm.formatCurrency(state === null || state === void 0 ? void 0 : state.offer) : '-'))]), _c('th', {
            attrs: {
              "width": "20%"
            }
          }, [_vm._v("Penawaran Nego")]), _c('td', {
            staticClass: "text-center",
            attrs: {
              "width": "5%"
            }
          }, [_vm._v(":")]), _c('td', {
            attrs: {
              "width": "25%"
            }
          }, [_vm._v(_vm._s(state !== null && state !== void 0 && state.offerNegotiation ? _vm.formatCurrency(state === null || state === void 0 ? void 0 : state.offerNegotiation) : '-'))])]), _c('tr', [_c('th', {
            attrs: {
              "width": "20%"
            }
          }, [_vm._v("Notes")]), _c('td', {
            staticClass: "text-center",
            attrs: {
              "width": "5%"
            }
          }, [_vm._v(":")]), _c('td', {
            attrs: {
              "colspan": "4"
            }
          }, [_vm._v(_vm._s((_state$notes = state === null || state === void 0 ? void 0 : state.notes) !== null && _state$notes !== void 0 ? _state$notes : '-'))])]), _c('tr', [_c('th', {
            attrs: {
              "width": "20%"
            }
          }, [_vm._v("Document")]), _c('td', {
            staticClass: "text-center",
            attrs: {
              "width": "5%"
            }
          }, [_vm._v(":")]), _c('td', {
            attrs: {
              "colspan": "4"
            }
          }, [state !== null && state !== void 0 && (_state$contractorDoc = state.contractorDoc) !== null && _state$contractorDoc !== void 0 && _state$contractorDoc.fileName ? _c('a', {
            attrs: {
              "href": state === null || state === void 0 ? void 0 : (_state$contractorDoc2 = state.contractorDoc) === null || _state$contractorDoc2 === void 0 ? void 0 : _state$contractorDoc2.url,
              "target": "_blank"
            }
          }, [_c('img', {
            staticStyle: {
              "width": "30px"
            },
            attrs: {
              "src": require("../../assets/images/activity/pdf.png"),
              "alt": "gallary-image"
            }
          })]) : _vm._e()])])])])]);
        }) : _vm._e()], 2) : _vm._e()]), _c('b-col', {
          attrs: {
            "md": "5"
          }
        }, [_c('tab-content-item', {
          attrs: {
            "active": true,
            "id": "power"
          }
        }, [_c('tab-nav', {
          attrs: {
            "tabs": true
          }
        }, [_c('tab-nav-items', {
          attrs: {
            "active": true,
            "href": "#activity",
            "title": "Activity"
          }
        })], 1), _c('div', {
          staticClass: "tab-content",
          attrs: {
            "id": "myTabContent"
          }
        }, [_c('tab-content-item', {
          attrs: {
            "active": true,
            "id": "activity"
          }
        }, [_c('b-row', [_c('b-col', {
          staticClass: "mb-5",
          attrs: {
            "md": "12"
          }
        }, [_c('form', {
          attrs: {
            "action": ""
          },
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.submit.apply(null, arguments);
            }
          }
        }, [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Select Docking Job *",
            "label-for": "docking_job"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "displayName",
            "options": _vm.tasks,
            "reduce": function reduce(task) {
              return task.id;
            },
            "placeholder": "Select docking job..."
          },
          on: {
            "input": _vm.handleTaskDetail
          },
          model: {
            value: _vm.selectedTaskId,
            callback: function callback($$v) {
              _vm.selectedTaskId = $$v;
            },
            expression: "selectedTaskId"
          }
        })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label-for": "progress_bar"
          }
        }, [_c('h4', {
          staticClass: "mb-2"
        }, [_vm._v("Progrees : "), _c('b-badge', {
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v(" " + _vm._s(_vm.form.progressBar) + "%")])], 1), _c('b-progress', {
          attrs: {
            "value": _vm.form.progressBar,
            "variant": "success",
            "max": "100"
          }
        })], 1)], 1)], 1), _vm.form.progressBar < 100 ? [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Start *",
            "label-for": "start"
          }
        }, [_c('date-picker', {
          attrs: {
            "required": "",
            "value-type": "DD-MM-YYYY",
            "type": "date",
            "placeholder": "Select date",
            "format": "DD MMM YYYY"
          },
          model: {
            value: _vm.form.date,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "date", $$v);
            },
            expression: "form.date"
          }
        })], 1)], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Progress Pekerjaan *",
            "label-for": "progress_pekerjaan"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary",
          attrs: {
            "id": "progress_pekerjaan"
          }
        }, [_vm._v("%")])]), _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form.progress,
            expression: "form.progress"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "min": _vm.form.progressBar,
            "required": "",
            "id": "progress_pekerjaan",
            "placeholder": "80"
          },
          domProps: {
            "value": _vm.form.progress
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form, "progress", $event.target.value);
            }
          }
        })])])], 1), _c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Report Progress",
            "label-for": "report_progress"
          }
        }, [_vm.form.fileName ? _c('div', {
          staticClass: "d-flex justify-content-between"
        }, [_c('a', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "text-primary",
          attrs: {
            "href": _vm.form.fileUrl,
            "title": "Download File",
            "download": ""
          }
        }, [_c('i', {
          staticClass: "fa fa-download"
        }), _vm._v(" Download")]), _c('a', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "text-danger",
          attrs: {
            "href": "#",
            "title": "Hapus",
            "download": ""
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return function () {
                _vm.form.fileUrl = '';
                _vm.form.fileName = '';
              }.apply(null, arguments);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-trash"
        })])]) : [_c('div', {
          staticClass: "custom-file"
        }, [_c('input', {
          staticClass: "custom-file-input",
          attrs: {
            "type": "file",
            "accept": "image/png,image/jpg,image/jpeg,.pdf,.xls,.xlsx,.doc,.docx",
            "id": "attachment-shipyard"
          },
          on: {
            "change": _vm.onUploadFile
          }
        }), _c('label', {
          staticClass: "custom-file-label",
          attrs: {
            "for": "attachment-shipyard"
          }
        }, [_vm._v("Browse file...")])]), _c('small', {
          staticClass: "text-muted"
        }, [_vm._v(" File Type * : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("3 MB / File")])], 1)]], 2)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": "Notes",
            "label-for": "notes"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "id": "description",
            "rows": "3",
            "placeholder": "Notes"
          },
          model: {
            value: _vm.form.notes,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "notes", $$v);
            },
            expression: "form.notes"
          }
        })], 1)], 1)], 1), _c('b-button', {
          attrs: {
            "type": "submit",
            "block": "",
            "variant": "success"
          }
        }, [_vm._v("UPDATE ACTIVITY")])] : _vm._e()], 2)]), ((_vm$activities = _vm.activities) === null || _vm$activities === void 0 ? void 0 : _vm$activities.length) > 0 ? _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('ul', {
          staticClass: "iq-timeline",
          staticStyle: {
            "height": "100%"
          }
        }, _vm._l(_vm.activities, function (activity, index) {
          var _activity$createdBy, _activity$createdAt, _activity$taskName;
          return _c('li', {
            key: index
          }, [_c('div', {
            staticClass: "timeline-dots border-success"
          }), _c('span', [_vm._v(_vm._s((_activity$createdBy = activity === null || activity === void 0 ? void 0 : activity.createdBy) !== null && _activity$createdBy !== void 0 ? _activity$createdBy : '-') + " | "), _c('small', [_vm._v(_vm._s((_activity$createdAt = activity === null || activity === void 0 ? void 0 : activity.createdAt) !== null && _activity$createdAt !== void 0 ? _activity$createdAt : '-'))])]), !_vm.selectedTask ? _c('h6', {
            staticClass: "float-left mb-1"
          }, [_vm._v(_vm._s((_activity$taskName = activity === null || activity === void 0 ? void 0 : activity.taskName) !== null && _activity$taskName !== void 0 ? _activity$taskName : '-'))]) : _vm._e(), _c('br'), _vm._l(activity.doc, function (doc, docIndex) {
            return _c('div', {
              key: docIndex,
              staticClass: "d-flex my-3",
              staticStyle: {
                "gap": "10px"
              }
            }, [doc.url ? _c('div', {
              staticStyle: {
                "width": "50px"
              }
            }, [_c('a', {
              attrs: {
                "href": doc.url,
                "target": "_blank"
              }
            }, [_c('img', {
              staticClass: "img-fluid",
              attrs: {
                "src": require("../../assets/images/activity/pdf.png"),
                "alt": "gallary-image"
              }
            })])]) : _vm._e()]);
          }), _c('div', {
            staticClass: "d-inline-block w-100"
          }, [_c('p', [_vm._v(_vm._s(activity.notes))])])], 2);
        }), 0)]) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.model,
      callback: function callback($$v) {
        _vm.model = $$v;
      },
      expression: "model"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }