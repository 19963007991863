var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ValidationObserver', {
    ref: "form",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('form', {
          staticClass: "mt-4",
          attrs: {
            "novalidate": ""
          },
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.doLogin);
            }
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "vid": "username",
            "name": "Username",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('div', {
                staticClass: "form-group"
              }, [_c('label', {
                attrs: {
                  "for": "username"
                }
              }, [_vm._v("Username")]), _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.username,
                  expression: "user.username"
                }],
                class: 'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : ''),
                attrs: {
                  "type": "text",
                  "id": "username",
                  "placeholder": "Enter username",
                  "required": ""
                },
                domProps: {
                  "value": _vm.user.username
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) return;
                    _vm.$set(_vm.user, "username", $event.target.value);
                  }
                }
              }), _c('div', {
                staticClass: "invalid-feedback"
              }, [_c('span', [_vm._v(_vm._s(errors[0]))])])])];
            }
          }], null, true)
        }), _c('ValidationProvider', {
          attrs: {
            "vid": "password",
            "name": "Password",
            "rules": "required|min:6"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors;
              return [_c('div', {
                staticClass: "form-group"
              }, [_c('label', {
                attrs: {
                  "for": "passwordInput"
                }
              }, [_vm._v("Password")]), _c('router-link', {
                staticClass: "float-right",
                attrs: {
                  "to": "/reset-password"
                }
              }, [_vm._v(" Forgot password? ")]), (_vm.showPassword ? 'text' : 'password') === 'checkbox' ? _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.password,
                  expression: "user.password"
                }],
                class: 'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : ''),
                attrs: {
                  "id": "passwordInput",
                  "placeholder": "Password",
                  "required": "",
                  "type": "checkbox"
                },
                domProps: {
                  "checked": Array.isArray(_vm.user.password) ? _vm._i(_vm.user.password, null) > -1 : _vm.user.password
                },
                on: {
                  "change": function change($event) {
                    var $$a = _vm.user.password,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false;
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v);
                      if ($$el.checked) {
                        $$i < 0 && _vm.$set(_vm.user, "password", $$a.concat([$$v]));
                      } else {
                        $$i > -1 && _vm.$set(_vm.user, "password", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                      }
                    } else {
                      _vm.$set(_vm.user, "password", $$c);
                    }
                  }
                }
              }) : (_vm.showPassword ? 'text' : 'password') === 'radio' ? _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.password,
                  expression: "user.password"
                }],
                class: 'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : ''),
                attrs: {
                  "id": "passwordInput",
                  "placeholder": "Password",
                  "required": "",
                  "type": "radio"
                },
                domProps: {
                  "checked": _vm._q(_vm.user.password, null)
                },
                on: {
                  "change": function change($event) {
                    return _vm.$set(_vm.user, "password", null);
                  }
                }
              }) : _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.password,
                  expression: "user.password"
                }],
                class: 'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : ''),
                attrs: {
                  "id": "passwordInput",
                  "placeholder": "Password",
                  "required": "",
                  "type": _vm.showPassword ? 'text' : 'password'
                },
                domProps: {
                  "value": _vm.user.password
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) return;
                    _vm.$set(_vm.user, "password", $event.target.value);
                  }
                }
              }), _c('a', {
                attrs: {
                  "href": "#"
                },
                on: {
                  "click": function click($event) {
                    $event.preventDefault();
                    _vm.showPassword = !_vm.showPassword;
                  }
                }
              }, [_vm._v(_vm._s(_vm.showPassword ? 'Hide' : 'Show') + " password ")]), _c('div', {
                staticClass: "invalid-feedback"
              }, [_c('span', [_vm._v(_vm._s(errors[0]))])])], 1)];
            }
          }], null, true)
        }), _c('div', {
          staticClass: "d-inline-block w-100"
        }, [_c('button', {
          staticClass: "btn btn-primary float-right",
          attrs: {
            "type": "submit"
          }
        }, [!_vm.loading ? [_vm._v(" Login ")] : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _c('span', {
          staticClass: "sr-only"
        }, [_vm._v("Loading...")])]], 2)]), _c('div', {
          staticClass: "sign-info"
        }, [_c('span', {
          staticClass: "dark-color d-inline-block line-height-2"
        }, [_vm._v(" Don't have an account? "), _c('router-link', {
          staticClass: "iq-waves-effect pr-4",
          attrs: {
            "to": "#"
          }
        }, [_vm._v(" Contact Your Administrator! ")])], 1)])], 1)];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }