var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "iq-edit-profile d-flex",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-6 p-0",
          attrs: {
            "active": true,
            "href": "#data",
            "title": "MASTER DATA"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-6 p-0",
          attrs: {
            "active": false,
            "href": "#pfa",
            "title": "PFA"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "pills-tabContent-2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "data"
    }
  }, [_c('tab-nav', {
    attrs: {
      "tabs": true,
      "align": "center"
    }
  }, [_c('tab-nav-items', {
    attrs: {
      "active": true,
      "href": "#component-expense",
      "title": "Component Expense"
    }
  }), _c('tab-nav-items', {
    attrs: {
      "active": false,
      "href": "#time-voyage",
      "title": "Time Voyage"
    }
  }), _c('tab-nav-items', {
    attrs: {
      "active": false,
      "href": "#charter-company",
      "title": "Charter Company"
    }
  }), _c('tab-nav-items', {
    attrs: {
      "active": false,
      "href": "#cost-factor",
      "title": "Cost Factor"
    }
  }), _c('tab-nav-items', {
    attrs: {
      "active": false,
      "href": "#miscellaneous-charge",
      "title": "Miscellaneous Charge"
    }
  }), _c('tab-nav-items', {
    attrs: {
      "active": false,
      "href": "#port-stay",
      "title": "Port Stay"
    }
  }), _c('tab-nav-items', {
    attrs: {
      "active": false,
      "href": "#result-plus",
      "title": "Result Plus"
    }
  })], 1), _c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "myTabContent2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "component-expense"
    }
  }, [_c('MasterComponent')], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "time-voyage"
    }
  }, [_c('MasterTimeVoyage')], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "charter-company"
    }
  }, [_c('MasterCharterCompany')], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "cost-factor"
    }
  }, [_c('MasterCostFactor')], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "miscellaneous-charge"
    }
  }, [_c('MasterMiscellaneousCharge')], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "port-stay"
    }
  }, [_c('MasterPortStay')], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "result-plus"
    }
  }, [_c('MasterResultPlus')], 1)], 1)], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "pfa"
    }
  }, [_c('Pfa')], 1)], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }