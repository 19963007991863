<template>
  <div>
    <b-row v-if="!checked">
      <b-form-group class="col-md-6" label="Provinsi" label-for="Provinsi">
        <b-form-select
          plain
          v-model="form.provinsi"
          size="sm"
          :disabled="checked"
          required
        >
          <template v-slot:first>
            <b-form-select-option :value="null"
              >Select Provinsi</b-form-select-option
            >
            <b-form-select-option
              v-for="(state, index) in option.provience"
              v-bind:key="index"
              :value="state.name"
            >
              {{ state.name }}
            </b-form-select-option>
          </template>
        </b-form-select>
        <small class="text-muted"> Provinsi: {{ alamat?.provinsi }} </small>
      </b-form-group>
      <b-form-group class="col-md-6" label="Kota / Kabupaten" label-for="Kota">
        <b-form-select
          plain
          v-model="form.kabupaten"
          :disabled="checked || this.loadingKota || !form.provinsi"
          size="sm"
          required
        >
          <template v-slot:first>
            <b-form-select-option v-if="!form.provinsi" :value="null"
              >Pilih provinsi dahulu</b-form-select-option
            >
            <b-form-select-option v-else :value="null"
              >Select Kota</b-form-select-option
            >
            <b-form-select-option
              v-for="(state, index) in option.cities"
              v-bind:key="index"
              :value="state.name"
            >
              {{ state.name }}
            </b-form-select-option>
          </template>
        </b-form-select>
        <small class="text-muted">
          Kabupaten/Kota: {{ alamat?.kabupaten }}
        </small>
      </b-form-group>
      <b-form-group class="col-md-3" label="Kecamatan" label-for="Kecamatan">
        <b-form-select
          plain
          v-model="form.kecamatan"
          :disabled="checked || this.loadingKecamatan || !form.kabupaten"
          size="sm"
          required
        >
          <template v-slot:first>
            <b-form-select-option v-if="!form.kabupaten" :value="null"
              >Pilih kota/kabupaten dahulu</b-form-select-option
            >
            <b-form-select-option v-else :value="null"
              >Select Kecamatan</b-form-select-option
            >
            <b-form-select-option
              v-for="(state, index) in option.districts"
              v-bind:key="index"
              :value="state.name"
            >
              {{ state.name }}
            </b-form-select-option>
          </template>
        </b-form-select>
        <small class="text-muted"> Kecamatan: {{ alamat?.kecamatan }} </small>
      </b-form-group>
      <b-form-group class="col-md-3" label="Kelurahan" label-for="Kelurahan">
        <b-form-select
          plain
          v-model="form.kelurahan"
          :disabled="checked || this.loadingKelurahan || !form.kecamatan"
          size="sm"
          required
        >
          <template v-slot:first>
            <b-form-select-option v-if="!form.kecamatan" :value="null"
              >Pilih kecamatan dahulu</b-form-select-option
            >
            <b-form-select-option :value="null"
              >Select Kelurahan</b-form-select-option
            >
            <b-form-select-option
              v-for="(state, index) in option.villages"
              v-bind:key="index"
              :value="state.name"
            >
              {{ state.name }}
            </b-form-select-option>
          </template>
        </b-form-select>
        <small class="text-muted"> Kelurahan: {{ alamat?.kelurahan }} </small>
      </b-form-group>
      <b-form-group class="col-md-3" label="No Rumah" label-for="no_rumah">
        <b-form-input
          id="no_rumah"
          style="height: 35px"
          type="number"
          name="no_rumah"
          placeholder="Type Number..."
          :disabled="checked"
          v-model="form.no_rumah"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group class="col-md-3" label="Kode POS" label-for="kode_pos">
        <b-form-input
          id="kode_pos"
          style="height: 35px"
          type="number"
          name="kode_pos"
          :disabled="checked"
          v-model="form.kode_pos"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        class="col-md-12"
        label="Alamat Domisili"
        label-for="alamat_domisili"
      >
        <b-form-textarea
          id="alamat_domisili"
          name="alamat_domisili"
          rows="3"
          v-model="form.alamat"
          :disabled="checked"
          required
          placeholder="Alamat..."
        ></b-form-textarea>
      </b-form-group>
    </b-row>
    <b-row v-else>
      <b-form-group class="col-md-6" label="Provinsi" label-for="Provinsi">
        <span>{{
          form.provinsi
            ? form.provinsi
            : 'Kosong pilih alamat KTP terlebih dahulu'
        }}</span> </b-form-group
      ><b-form-group class="col-md-6" label="Kabupaten" label-for="Kabupaten">
        <span>{{
          form.kabupaten
            ? form.kabupaten
            : 'Kosong pilih alamat KTP terlebih dahulu'
        }}</span> </b-form-group
      ><b-form-group class="col-md-3" label="Kecamatan" label-for="Kecamatan">
        <span>{{
          form.kecamatan
            ? form.kecamatan
            : 'Kosong pilih alamat KTP terlebih dahulu'
        }}</span> </b-form-group
      ><b-form-group class="col-md-3" label="Kelurahan" label-for="Kelurahan">
        <span>{{
          form.kelurahan
            ? form.kelurahan
            : 'Kosong pilih alamat KTP terlebih dahulu'
        }}</span>
      </b-form-group>
      <b-form-group class="col-md-3" label="No Rumah" label-for="no_rumah">
        <span>{{
          form.no_rumah
            ? form.no_rumah
            : 'Kosong pilih alamat KTP terlebih dahulu'
        }}</span>
      </b-form-group>

      <b-form-group class="col-md-3" label="Kode Pos" label-for="Kode Pos">
        <span>{{
          form.kode_pos
            ? form.kode_pos
            : 'Kosong pilih alamat KTP terlebih dahulu'
        }}</span>
      </b-form-group>

      <b-form-group class="col-md-12" label="Alamat" label-for="Alamat">
        <span>{{
          form.alamat
            ? form.alamat
            : 'Kosong Kosong pilih alamat KTP terlebih dahulu'
        }}</span>
      </b-form-group>
    </b-row>
  </div>
</template>
<script>
import optionsData from '@src/core/mock/data'
import { crewingActions } from '@src/Utils/helper'

export default {
  name: 'FormInputAddressEdit',
  data() {
    return {
      ...optionsData,
      _loading: false,
      loadingProvinsi: false,
      loadingKota: false,
      loadingKecamatan: false,
      loadingKelurahan: false,
      option: {
        provience: [],
        cities: [],
        districts: [],
        villages: [],
      },
    }
  },
  mounted() {
    this.fetchProvinsi()
  },
  props: {
    alamat: Object,
    value: {
      type: Object,
      default: () => {
        return {
          provinsi: null,
          kabupaten: null,
          kecamatan: null,
          kelurahan: null,
          kode_pos: 0,
          alamat: '',
          no_rumah: '',
        }
      },
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    'form.provinsi': {
      handler(n, o) {
        if (n != o && n != null && n != '') {
          if (this.checked) return n
          this.form.kabupaten = null
          this.form.kecamatan = null
          this.form.kelurahan = null
          const { id } = this.option.provience.find((e) => e.name === n)
          this.fetchKota(id)
        }
      },
      immediate: true,
    },
    'form.kabupaten': {
      handler(n, o) {
        if (n != o && n != null && n != '') {
          if (this.checked) return n
          this.form.kecamatan = null
          this.form.kelurahan = null
          const { id } = this.option.cities.find((e) => e.name === n)
          this.fetchKecamatan(id)
        }
      },
      immediate: true,
    },
    'form.kecamatan': {
      handler(n, o) {
        if (n != o && n != null && n != '') {
          if (this.checked) return n
          this.form.kelurahan = null
          const { id } = this.option.districts.find((e) => e.name === n)
          this.fetchKelurahan(id)
        }
      },
      immediate: true,
    },
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    ...crewingActions,
    async fetchProvinsi() {
      this._loading = true
      let res = await this.fetchProvience()

      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.map((state) => {
            this.option.provience.push({
              id: state.id,
              name: state.name,
            })
          })
          this._loading = false
        }
      }

      this._loading = false
    },
    async fetchKota(id) {
      this.loadingKota = true
      let method = 'fetchCities'

      const { data } = await this[method]({ id: id })
      this.option.cities = []
      this.option.districts = []
      this.option.villages = []

      for (const property in data) {
        this.option.cities.push({
          id: property,
          name: data[property],
        })
      }

      this.loadingKota = false
    },
    async fetchKecamatan(id) {
      this.loadingKecamatan = true
      let method = 'fetchDistricts'

      const { data } = await this[method]({ id: id })
      this.option.districts = []
      this.option.villages = []
      for (const property in data) {
        this.option.districts.push({
          id: property,
          name: data[property],
        })
      }

      this.loadingKecamatan = false
    },
    async fetchKelurahan(id) {
      this.loadingKelurahan = true
      let method = 'fetchVillages'

      const { data } = await this[method]({ id: id })
      this.option.villages = []
      for (const property in data) {
        this.option.villages.push({
          id: property,
          name: data[property],
        })
      }

      this.loadingKelurahan = false
    },
  },
}
</script>
