<template>
   <form @submit.prevent="onSaveEdit" v-if="!detail_project_loading">
      <b-row class="m-3">
        <b-col lg="12" class="mb-4">
          <div class="d-flex align-items-center w-100 justify-content-end">
            <div v-for="(state, index) in listProjectStage" :key="index">
              <b-badge 
                class="border border-primary text-primary text-uppercase" 
                :variant="detailProjectContract?.detail_project_stage_id === state.id ? 'primary' : 'none'" 
                style="cursor: pointer;" 
                @click="onChangeStatusStage(detailProjectContract?.id, state.id)"
              >
                <span :class="detailProjectContract?.detail_project_stage_id === state.id ? 'text-white' : ''">{{ state?.title }}</span>
              </b-badge>
              <strong v-if="listProjectStage?.length - 1 !== index">&nbsp;>>&nbsp;</strong>
            </div>
          </div>
        </b-col>

        <b-col lg="12" class="mb-4">
          <div :class="`d-flex align-items-center justify-content-end w-100`">
            <b-button
                v-if="isEdit"
                :disabled="saving"
                block
                variant="primary"
                type="submit"
                style="width:15%"
                class="mt-2 mr-3"
              >
                <span
                  v-if="saving"
                  class="spinner-border spinner-border-sm"
                />
                <div v-else>
                  <i class="fa fa-save"></i>
                  <span>Submit Data</span>
                </div>
              </b-button>
            <b-button
                v-if="isEdit"
                :disabled="saving"
                block
                type="button"
                variant="danger"
                style="width:10%"
    
                @click="isEdit = false"
              >
                <div>
                  <i class="fa fa-ban"></i>
                  <span>Cancel</span>
                </div>
              </b-button>

              <b-button
                type="button"
                v-if="!isEdit"
                block
                variant="primary"
                style="width:15%"
                @click="isEdit = true"
              >
                <div>
                  <i class="fa fa-pen"></i>
                  <span>Edit Data</span>
                </div>
              </b-button>
          </div>
        </b-col>

        <b-col cols="12">
          <fieldset class="form-group border p-3">
            <legend class="w-auto px-2 text-primary">Project Contract Info</legend>
            <b-form-group label-for="dateIssued" label="Projects">
              <v-select
                v-if="isEdit"
                label="text"
                name="project_sub_id"
                v-model="form.project_sub_id"
                :options="projectOptions"
                :reduce="
                  (projectOptions) => projectOptions.value
                "
                placeholder="Select Project"
                required
                @input="onSelectedProjectEdit"
              ></v-select>
              <span v-else>{{ detailProjectContract?.project_sub?.project?.project_name }} - {{ detailProjectContract?.project_sub?.name }}</span>
            </b-form-group>
            <b-form-group
              label="Customer"
              label-for="customer_id"
            >
              <v-select
                v-if="isEdit"
                label="text"
                name="customer_id"
                v-model="form.customer_id"
                :options="optionsListCustomer"
                :reduce="
                  (optionsListCustomer) => optionsListCustomer.value
                "
                placeholder="Select Customer"
                required
                @input="onSelectedCustomerEdit"
              ></v-select>
              <span v-else>{{ detailProjectContract?.customer?.customer_code }} - {{ detailProjectContract?.customer?.account_name }}</span>
            </b-form-group>
          </fieldset>
        </b-col>

        <b-col lg="6">
          <b-row>
            <!-- PROJECT PLAN -->
            <b-col cols="12" v-if="form.project_sub_id">
              <fieldset class="form-group border p-3">
                <legend class="w-auto px-2 text-primary">Project Info</legend>
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>PROJECT NAME</th>
                      <th>PROJECT SUB NAME</th>
                      <th>LOCATION</th>
                      <th>STATUS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ projectDetail?.project?.project_name }}</td>
                      <td>{{ projectDetail?.name }}</td>
                      <td>{{ projectDetail?.project?.location }}</td>
                      <td>{{ projectDetail?.status ?? '-' }}</td>
                    </tr>
                  </tbody>
                </table>
              </fieldset>
            </b-col>

            <!-- COMPANY INFO -->
            <b-col cols="12">
              <fieldset class="form-group border p-3" v-if="form.customer_id">
                <legend class="w-auto px-2 text-primary">Company Info</legend>
                <table class="table mb-0 table-striped">
                  <tbody>
                    <tr>
                      <th width="15%">Nama Perusahaan</th>
                      <td width="5%">:</td>
                      <td width="30%">{{ customerDetail?.account_name ?? '-' }}</td>
                      <th width="15%">Alamat</th>
                      <td width="5%">:</td>
                      <td width="30%">{{ customerDetail?.address ?? '-' }}</td>
                    </tr>
                    <tr>
                      <th width="15%">No. Telepon</th>
                      <td width="5%">:</td>
                      <td width="30%">{{ customerDetail?.phone1 ?? customerDetail?.phone2 ?? '-' }}</td>
                      <th width="15%">Email</th>
                      <td width="5%">:</td>
                      <td width="30%">{{ customerDetail?.email ?? '-' }}</td>
                    </tr>

                    <tr>
                      <th width="15%">Negara</th>
                      <td width="5%">:</td>
                      <td width="30%">{{ customerDetail?.country?.name ?? '-' }}</td>
                      <th width="15%">Provinsi</th>
                      <td width="5%">:</td>
                      <td width="30%">{{ customerDetail?.province ?? '-' }}</td>
                    </tr>

                    <tr>
                      <th width="15%">Kota</th>
                      <td width="5%">:</td>
                      <td width="30%">{{ customerDetail?.city ?? '-' }}</td>
                      <th width="15%">Kecamatan</th>
                      <td width="5%">:</td>
                      <td width="30%">{{ customerDetail?.district ?? '-' }}</td>
                    </tr>

                    <tr>
                      <th width="15%">Kelurahan</th>
                      <td width="5%">:</td>
                      <td width="30%">{{ customerDetail?.village ?? '-' }}</td>
                      <th width="15%"></th>
                      <td width="5%"></td>
                      <td width="30%"></td>
                    </tr>

                    <tr>
                      <th width="15%">PIC</th>
                      <td width="5%">:</td>
                      <td width="30%">{{ customerDetail?.name ?? '-' }}</td>
                      <th width="15%">No. Handphone</th>
                      <td width="5%">:</td>
                      <td width="30%">{{ customerDetail?.mobile1 ?? customerDetail?.mobile2 ?? '-' }}</td>
                    </tr>
                  </tbody>
                </table>
              </fieldset>
            </b-col>
          </b-row>
        </b-col>

        <!-- LOG ACITIVITY HISTORY PROJECT CONTRACT -->
        <b-col lg="6">
          <fieldset class="form-group border p-3">
            <legend class="w-auto px-2 text-primary">Log History Activity</legend>
      
            <div class="overflow-auto d-flex w-100 " style="max-height:544px">
              <b-row>
                <b-col lg="12" class="mb-4" v-for="(state, index) in detailProjectContract?.project_log" :key="index">
                  <div class="d-flex">
                    <strong class="mr-2">{{ state?.name }} -</strong>
                    <span class="text-muted">{{ moment(state.date_log).format('DD MMMM YYYY') }}</span>
                  </div>
                  <span>{{ state.log_description }}</span>
                  <!-- <div class="ml-4">
                    > <strong class="text-muted">New</strong> -> <strong class="text-primary">Proposition</strong> <i>(Stage)</i>
                  </div> -->
                  <br />
                  <strong>----</strong>
                </b-col>
              </b-row>
            </div>
          </fieldset>
        </b-col>

        <b-col lg="12">
          <b-row>
            <b-col lg="6">
              <b-row>
                <!-- DETAIL PROJECT CONTRACT -->
                <b-col lg="12">
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 text-primary">Detail Project Contract {{ isEdit ? 'Form' : '' }}</legend>
                    <table class="table table-striped">
                      <tbody>
                        <tr>
                          <th width="15%">Vehicle</th>
                          <td width="5%">:</td>
                          <td width="30%">
                            <div class="d-flex flex-column" v-if="isEdit">
                              <b-form-select plain v-model="form.fleet_ownership" class="form-control" required name="fleet_ownership"
                                @change="onDataIntoPlan('fleet_ownership', form.fleet_ownership); onChangeOwnership(form.fleet_ownership)"
                              >
                                <option value="">Select ownership...</option>
                                <option value="own-ship">OWN SHIP</option>
                                <option value="chartered">CHARTERED</option>
                              </b-form-select>
                              <div class="d-flex mt-2 flex-column w-100" v-if="form.fleet_ownership">
                                <strong>{{ vehicleName(form.vehicle_id) }}</strong>
                                <b-button variant="primary" size="sm" @click="onOpenSlideDetailVehicle">{{form.fleet_ownership ? 'Change ' : 'Select '}} vehicle</b-button>
                              </div>
                            </div>
                            <div class="d-flex flex-column align-items-center justify-content-center w-100" v-else>
                              <strong>{{ vehicleName(form.vehicle_id) }}</strong>
                              <b-badge class="border border-primary text-primary text-uppercase" variant="none">{{ form?.fleet_ownership }}</b-badge>
                            </div>
                          </td>
                          <th width="15%">Collection of Forms</th>
                          <td width="5%">:</td>
                          <td width="30%" v-if="isEdit">
                            <v-multiselect 
                              v-if="(optionsForms?.length > 0)"
                              v-model="form.project_forms"
                              :options="optionsForms" 
                              :multiple="true" 
                              :group-select="true" 
                              placeholder="Select Inspection" 
                              track-by="label" 
                              label="label"
                            />     
                            <strong v-else class="text-muted">No forms.</strong>
                          </td>
                          <td width="30%" v-else>
                            <div class="d-flex flex-wrap gap-2">
                              <b-badge 
                                v-for="(state, index) in form.project_forms"
                                :key="index"
                                class="border border-primary text-primary text-uppercase m-1" 
                                variant="none"
                              >
                                {{ state?.label }}
                              </b-badge>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th width="15%">Periode Start</th>
                          <td width="5%">:</td>
                          <td width="30%">
                            <b-form-input
                              v-if="isEdit"
                              id="periode_start"
                              name="periode_start"
                              type="date"
                              v-model="form.periode_start"
                            ></b-form-input>
                            <span v-else>{{ form?.periode_start ? moment(form?.periode_start).format('DD MMMM YYYY') : '-' }}</span>
                          </td>
                          <th width="15%">Periode End</th>
                          <td width="5%">:</td>
                          <td width="30%">
                            <b-form-input
                              v-if="isEdit"
                              id="periode_end"
                              name="periode_end"
                              type="date"
                              v-model="form.periode_end"
                            ></b-form-input>
                            <span v-else>{{ form?.periode_start ? moment(form?.periode_start).format('DD MMMM YYYY') : '-' }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th width="15%">Cargo Type</th>
                          <td width="5%">:</td>
                          <td width="30%">
                            <b-form-select v-if="isEdit" plain size="sm" :options="optionsCargoType" v-model="form.cargo_type_id" @change="onDataIntoPlan('cargo_type_id', form.cargo_type_id)"> 
                              <b-form-select-option :value="null">Select Cargo Type</b-form-select-option>
                            </b-form-select>
                            <span v-else>{{ detailProjectContract?.cargo_type?.name ?? '-' }}</span>
                          </td>
                          <th width="15%">Contract Type</th>
                          <td width="5%">:</td>
                          <td width="30%">
                            <v-multiselect
                              v-if="isEdit"
                              v-model="form.contract_type_id"
                              placeholder="search/add contract"
                              label="name"
                              track-by="id"
                              :close-on-select="true"
                              :clear-on-select="true"
                              :options="optionsContractType"
                              @search-change="searchContractType"
                            >
                              <span slot="noResult" @click="doAddContractType">
                                Not Found. Click to add
                                <strong>{{ contractTypeName }}</strong>
                              </span>
                            </v-multiselect>   
                            <span v-else>{{ detailProjectContract?.contract_type?.name }}</span>
                          </td>
                        </tr>
                        <tr> 
                          <th width="15%">Term</th>
                          <td width="5%">:</td>
                          <td width="30%">
                            <b-form-select v-if="isEdit" plain size="sm" :options="optionsTerms" v-model="form.terms_id" @change="onDataIntoPlan('terms_id', form.terms_id)">
                              <b-form-select-option :value="null">Select Term</b-form-select-option>
                            </b-form-select>
                            <span v-else>{{ detailProjectContract?.terms?.name ?? '-' }}</span>
                          </td> 
                          <th width="15%">Total Volume/Quantity</th>
                          <td width="5%">:</td>
                          <td width="30%">
                            <span class="form-control">{{ totalQuantityEdit?.toLocaleString() }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th width="15%">POL</th>
                          <td width="5%">:</td>
                          <td width="30%">
                            <b-form-input
                              v-if="isEdit"
                              @change="onDataIntoPlan('pol', form.pol)"
                              id="pol"
                              name="pol"
                              placeholder="Type Text ..."
                              v-model="form.pol"
                            ></b-form-input>
                            <span v-else>{{ form?.pol }}</span>
                          </td>
                          <th width="15%">POD</th>
                          <td width="5%">:</td>
                          <td width="30%">
                            <b-form-input
                              v-if="isEdit"
                              @change="onDataIntoPlan('pod', form.pod)"
                              id="pod"
                              name="pod"
                              placeholder="Type Text ..."
                              v-model="form.pod"
                            ></b-form-input>
                            <span v-else>{{ form?.pod }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th width="15%">Laycan</th>
                          <td width="5%">:</td>
                          <td width="30%">
                            <b-form-input
                              v-if="isEdit"
                              id="laycan"
                              name="laycan"
                              placeholder="Type Text ..."
                              v-model="form.laycan"
                            ></b-form-input>
                            <span v-else>{{ form?.laycan }}</span>
                          </td>
                          <th width="15%">Distance</th>
                          <td width="5%">:</td>
                          <td width="30%">
                            <b-form-input
                              v-if="isEdit"
                              id="distance"
                              name="distance"
                              placeholder="Type Number ..."
                              type="number"
                              v-model="form.distance"
                            ></b-form-input>
                            <span v-else>{{ form?.distance }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th width="15%">Duration</th>
                          <td width="5%">:</td>
                          <td width="30%">
                            <b-form-input
                              v-if="isEdit"
                              id="duration"
                              name="duration"
                              placeholder="Type Number ..."
                              type="number"
                              v-model="form.duration"
                            ></b-form-input>
                            <span v-else>{{ form?.duration }}</span>
                          </td>
                          <th width="15%">Freigh Rate Excl.Ppn</th>
                          <td width="5%">:</td>
                          <td width="30%">
                            <b-form-input
                              v-if="isEdit"
                              id="freight_rate_excl_ppn"
                              name="freight_rate_excl_ppn"
                              placeholder="Type Text ..."
                              v-model="form.freight_rate_excl_ppn"
                            ></b-form-input>
                            <span v-else>{{ form?.freight_rate_excl_ppn }}</span>
                          </td>
                        </tr>
                        <!-- <tr>
                          <th width="15%">Prioritize</th>
                          <td width="5%">:</td>
                          <td width="30%">
                            <b-form-rating 
                              :readonly="!isEdit"
                              no-border 
                              inline 
                              size="sm" 
                              variant="primary" 
                              v-model="form.prioritize"
                              style="margin-left:-10px; margin-bottom:-20px"
                            >
                            </b-form-rating> <br />
                            <small class="text-primary"><strong>{{ prioritizeProject(form?.prioritize) }}</strong></small>
                          </td>
                          <th></th>
                          <td></td>
                          <td></td>
                        </tr> -->
                        <tr>
                          <th width="15%">Remarks</th>
                          <td width="5%">:</td>
                          <td width="30%" colSpan="4">
                            <b-form-textarea
                              v-if="isEdit"
                              id="remarks"
                              name="remarks"
                              placeholder="Type Text ..."
                              v-model="form.remarks"
                            ></b-form-textarea>
                            <span v-else>{{ form.remarks }}</span>
                          </td>
                        </tr>
                     
                        <tr>
                          <th width="15%">Needs More Detail Plan ?</th>
                          <td width="5%">:</td>
                          <td width="30%">
                            <b-form-checkbox
                              v-if="isEdit"
                              name="check-button"
                              switch
                              inline
                              v-model="form.need_more_detail"
                            >
                              Yes / No
                            </b-form-checkbox>
                            <span v-else :class="form.need_more_detail ? 'text-success' : 'text-danger'">{{ form.need_more_detail ? 'Yes' : 'No' }}</span>
                          </td>
                          <th width="15%">is Tender ?</th>
                          <td width="5%">:</td>
                          <td width="30%">
                            <b-form-checkbox
                              v-if="isEdit"
                              name="check-button"
                              switch
                              inline
                              v-model="form.is_tender"
                            >
                              Yes / No
                            </b-form-checkbox>
                            <span v-else :class="form.is_tender ? 'text-success' : 'text-danger'">{{ form.is_tender ? 'Yes' : 'No' }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th width="15%">Needs Pre-Fixtured Analysis?</th>
                          <td width="5%">:</td>
                          <td width="30%">
                            <b-form-checkbox
                              v-if="isEdit"
                              name="check-button"
                              switch
                              inline
                              v-model="form.is_pfa"
                            >
                              Yes / No
                            </b-form-checkbox>
                            <span v-else :class="form.is_pfa ? 'text-success' : 'text-danger'">{{ form.is_pfa ? 'Yes' : 'No' }}</span>
                          </td>
                          <th width="15%">Need Document?</th>
                          <td width="5%">:</td>
                          <td width="30%">
                            <b-form-checkbox
                             v-if="isEdit"
                              name="check-button"
                              switch
                              inline
                              v-model="form.need_document"
                            >
                              Yes / No
                            </b-form-checkbox>
                            <span v-else :class="form.need_document ? 'text-success' : 'text-danger'">{{ form.need_document ? 'Yes' : 'No' }}</span>
                          </td>
                        </tr>
                       
                        <tr>
                          <th width="15%">Is this project needs approval ?</th>
                          <td width="5%">:</td>
                          <td width="30%">
                            <b-form-checkbox
                              v-if="isEdit"
                              name="check-button"
                              switch
                              inline
                              v-model="form.need_approval"
                            >
                              Yes / No
                            </b-form-checkbox>
                            <span v-else :class="form.need_approval ? 'text-success' : 'text-danger'">{{ form.need_approval ? 'Yes' : 'No' }}</span>
                          </td>
                          <th width="15%" v-if="form.need_approval">How much approval is needed?</th>
                          <td width="5%" v-if="form.need_approval">:</td>
                          <td width="30%" v-if="form.need_approval">
                            <b-form-select v-if="isEdit" plain size="sm" v-model="form.many_approval" required @change="onChangeManyApproval"> 
                              <b-form-select-option value="">Select values....</b-form-select-option>
                              <b-form-select-option value="1">1</b-form-select-option>
                              <b-form-select-option value="2">2</b-form-select-option>
                              <b-form-select-option value="3">3</b-form-select-option>
                              <b-form-select-option value="4">4</b-form-select-option>
                            </b-form-select>
                            <span v-else>{{ form.many_approval }} User</span>
                          </td>
                        </tr>  
                        <tr v-if="form.many_approval">
                          <th width="15%">User Approval</th>
                          <td width="5%">:</td>
                          <td width="30%" colSpan="4" v-if="isEdit">
                            <div class="d-flex align-items-center mb-2" v-for="(user, userIndex) in form.user_approval" :key="userIndex">
                              <strong>#{{ userIndex + 1 }}</strong>
                              <v-select
                                label="text"
                                name="user"
                                v-model="user.user"
                                :options="optionsExpertUsers"
                                :reduce="
                                  (optionsExpertUsers) => optionsExpertUsers.value
                                "
                                placeholder="Select User"
                                class="w-100 ml-2"
                                required
                              ></v-select>
                            </div>
                          </td>
                          <td width="30%" colSpan="4" v-else>
                            <div class="d-flex align-items-center mb-2" v-for="(user, userIndex) in form.user_approval" :key="userIndex">
                              <strong class="mr-2">#{{ userIndex + 1 }}</strong>
                              <span>{{ userExpertName(user?.user) }}</span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </fieldset>
                </b-col>
              </b-row>
            </b-col>
            <b-col lg="6">
              <b-row>
                <!-- TENDER -->
                <b-col lg="12" v-if="form.is_tender">
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 text-primary">Tender Form</legend>
                    <table class="table table-striped">
                      <tbody>
                        <tr>
                          <th width="15%">PIC</th>
                          <td width="5%">:</td>
                          <td width="30%">
                            <b-form-input placeholder="Type Text" required v-model="form.formTender.pic" v-if="isEdit" />
                            <span v-else>{{form.formTender.pic}}</span>
                          </td>
                          <th width="15%">Url</th>
                          <td width="5%">:</td>
                          <td width="30%">
                            <b-form-input placeholder="Type Text" required v-model="form.formTender.url" v-if="isEdit"  />
                            <span v-else>{{form.formTender.url}}</span>
                          </td>
                        </tr>
                        <tr>
                          <th width="15%">Description</th>
                          <td width="5%">:</td>
                          <td colSpan="4">
                            <b-form-textarea placeholder="Type Text" required v-model="form.formTender.description_of_tender" v-if="isEdit"  />
                            <span v-else>{{form.formTender.description_of_tender}}</span>
                          </td>
                        </tr>
                        <tr>
                          <th width="15%">File</th>
                          <td width="5%">:</td>
                          <td colSpan="4">
                            <b-form-file
                              v-if="isEdit" 
                              plain
                              id="file"
                              v-model="form.formTender.file"
                              accept="image/*,application/*"
                              multiple
                              class="form-control"
                            ></b-form-file>
                            <img src="../../../assets/images/activity/pdf.png" alt="img-image" style="width: 40px; height: 40px;" v-else />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </fieldset>
                </b-col>
    
                <!-- DOCUMENT ATTACHMENT -->
                <b-col lg="12" v-if="form.need_document">
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 text-primary">Document Attachment</legend>
                    <div class="overflow-auto">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="text-center" style="vertical-align:middle; width: 20px">NO</th>
                            <th>Name of File / Document</th>
                            <th class="text-center" style="vertical-align:middle;">File / Document</th>
                            <th class="text-center" style="vertical-align:middle; width: 20px">#</th>
                          </tr>
                        </thead>
                        <tbody v-if="isEdit">
                          <tr v-for="(state, index) in form.form_document" :key="index">
                            <th class="text-center align-top">#{{ index + 1 }}</th>
                            <td>
                              <b-form-input placeholder="Name of File" v-model="state.filename" required />
                            </td>
                            <td class="align-top">
                              <b-form-file
                                :required="state?.url ? false : true"
                                :id="'fileSupport'+index"
                                placeholder="Choose a file"
                                accept="application/pdf"
                                v-model="state.file"
                                >
                              </b-form-file>
                              <a :href="state?.url" class="text-primary">{{ state?.filename }}.{{ state?.extension }}</a>
                            </td>
                            <td class="text-center align-top">
                              <b-button size="sm" variant="danger" @click="form.form_document.splice(index, 1)" v-if="index !== 0"><i class="fa fa-trash pr-0"></i></b-button>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr v-for="(state, index) in detailProjectContract.project_document" :key="index">
                            <td class="text-center align-top">{{ index + 1 }}</td>
                            <td>
                              <span>{{ state?.filename }}</span>
                            </td>
                            <td class="text-center">
                              <a :href="state?.url" class="text-primary">{{ state?.filename }}.{{ state?.extension }}</a>
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <b-button v-if="isEdit" block variant="success" @click="addFormDocument()"><i class="fa fa-plus pr-0"></i> ADD MORE ATTACHMENT</b-button>
                  </fieldset>
                </b-col> 
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        
        <!-- DETAIL PLAN -->
        <b-col cols="12" v-if="form.need_more_detail">
          <fieldset class="form-group border p-3">
            <legend class="w-auto px-2 text-primary">Detail Project Contract Plan</legend>
            <div class="overflow-auto">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th class="text-center" rowspan="2" style="vertical-align:middle;">NO</th>
                    <th class="text-center" rowspan="2" style="vertical-align:middle; min-width: 350px;" >DESCRIPTION</th>
                    <th class="text-center" rowspan="2" style="vertical-align:middle; min-width: 250px;">FLEET</th>
                    <th class="text-center" rowspan="2" style="vertical-align:middle; min-width: 300px;">CARGO TYPE</th>
                    <th class="text-center" rowspan="2" style="vertical-align:middle; min-width: 250px;">LOCATION</th>
                    <th class="text-center" rowspan="2" style="vertical-align:middle; min-width: 250px;">SHIPMENT (MONTH)</th>
                    <th class="text-center" colspan="2" style="min-width: 250px;">PLAN DATE</th>
                    <th class="text-center" rowspan="2" style="vertical-align:middle; min-width: 250px;">PLAN QUANTITY</th>
                    <th class="text-center" rowspan="2" style="vertical-align:middle; min-width: 250px;">REMARKS</th>
                    <th class="text-center" rowspan="2" style="vertical-align:middle;">ACTION</th>
                  </tr>
                  <tr>
                    <th class="text-center" style="min-width: 250px;">ETA POL</th>
                    <th class="text-center" style="min-width: 250px;">ETA POD</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(state, index) in form.detail_plan" :key="index">
                    <th class="text-center align-top">#{{ index + 1 }}</th>
                    <td class="text-center align-top">
                      <b-form-textarea v-if="isEdit" placeholder="Type Text" type="text" id="description" name="description" v-model="state.description" size="sm" style="border: solid 1px gray;"></b-form-textarea>
                      <span v-else>{{ state?.description ?? '-' }}</span>
                    </td>
                    <td class="text-left align-top">
                      <b-row v-if="isEdit">
                        <b-col lg="12" class="mb-2">
                          <b-form-select plain v-model="state.fleet_ownership" class="form-control" required>
                            <option value="">Select ownership...</option>
                            <option value="own-ship">OWN SHIP</option>
                            <option value="chartered">CHARTERED</option>
                          </b-form-select>
                        </b-col>
                        <b-col lg="12" v-if="state.fleet_ownership" style="z-index: 100000;">
                          <v-select
                            required
                            label="text"
                            name="vehicle_id"
                            v-model="state.vehicle_id"
                            :options="optionsFleets"
                            :reduce="(optionsFleets) => optionsFleets.value"
                            placeholder="Select Fleet"
                          ></v-select>
                        </b-col>
                      </b-row>
                      <div class="d-flex flex-column align-items-center justify-content-center w-100" v-else>
                        <strong>{{ vehicleName(state.vehicle_id) }}</strong>
                        <b-badge class="border border-primary text-primary text-uppercase" variant="none">{{ state?.fleet_ownership }}</b-badge>
                      </div>
                    </td>
                    <td class="text-left align-top">
                      <b-form-group class="row mb-2" label-cols-md="4" label="Cargo Type" label-for="cargo_type_id">
                        <b-form-select v-if="isEdit" plain size="sm" style="border: solid 1px gray;" :options="optionsCargoType" v-model="state.cargo_type_id">
                          <b-form-select-option :value="null">Select Cargo Type</b-form-select-option>
                        </b-form-select>
                        <b-badge v-else>{{ state?.cargo_type?.name }}</b-badge>
                      </b-form-group>
                      <b-form-group class="row mb-2" label-cols-md="4" label="Term" label-for="descriptionItem">
                        <b-form-select v-if="isEdit" plain size="sm" style="border: solid 1px gray;" :options="optionsTerms" v-model="state.terms_id">
                          <b-form-select-option :value="null">Select Term</b-form-select-option>
                        </b-form-select>
                        <b-badge v-else>{{ state?.terms?.name }}</b-badge>
                      </b-form-group>
                    </td>
                    <td class="text-left align-top">
                      <b-form-group class="row mb-2" label-cols-md="4" label="POL" label-for="descriptionItem"> 
                        <b-form-input v-if="isEdit" type="text" v-model="state.pol" id="pol" size="sm" style="border: solid 1px gray;"></b-form-input>
                        <span v-else>{{ state?.pol }}</span>
                      </b-form-group>
                      <b-form-group class="row mb-2" label-cols-md="4" label="POD" label-for="descriptionItem">
                        <b-form-input v-if="isEdit" type="text" v-model="state.pod" id="pod" size="sm" style="border: solid 1px gray;"></b-form-input>
                        <span v-else>{{ state?.pod }}</span>
                      </b-form-group>
                    </td>
                    <td class="text-center align-top">
                      <b-form-select v-if="isEdit" plain size="sm" style="border: solid 1px gray;" v-model="state.shipment">
                        <b-form-select-option value="">SELECT MONTH</b-form-select-option>
                        <b-form-select-option value="JANUARY">JANUARI</b-form-select-option>
                        <b-form-select-option value="FEBRUARI">FEBRUARI</b-form-select-option>
                        <b-form-select-option value="MARET">MARET</b-form-select-option>
                        <b-form-select-option value="APRIL">APRIL</b-form-select-option>
                        <b-form-select-option value="MEI">MEI</b-form-select-option>
                        <b-form-select-option value="JUNI">JUNI</b-form-select-option>
                        <b-form-select-option value="JULI">JULI</b-form-select-option>
                        <b-form-select-option value="AGUSTUS">AGUSTUS</b-form-select-option>
                        <b-form-select-option value="SEPTEMBER">SEPTEMBER</b-form-select-option>
                        <b-form-select-option value="OKTOBER">OKTOBER</b-form-select-option>
                        <b-form-select-option value="NOVEMBER">NOVEMBER</b-form-select-option>
                        <b-form-select-option value="DESEMBER">DESEMBER</b-form-select-option>
                      </b-form-select>
                      <span v-else>{{ state.shipment }}</span>
                    </td>
                    <td class="text-center align-top" colspan="2">
                      <b-row>
                        <b-col lg="6">
                          <b-form-input v-if="isEdit" type="date" id="eta_pol" v-model="state.eta_pol" class="mb-1" size="sm" style="border: solid 1px gray;"></b-form-input>
                          <span v-else>{{ state?.eta_pol }}</span>
                        </b-col>
                        <b-col lg="6">
                          <b-form-input v-if="isEdit" type="date" id="eta_pod" v-model="state.eta_pod" class="mb-1" size="sm" style="border: solid 1px gray;"></b-form-input>
                          <span v-else>{{ state?.eta_pod }}</span>
                        </b-col>
                      </b-row>
                    </td>
                    <td class="text-center align-top">
                      <b-form-input v-if="isEdit" type="number" id="plan_quantity" v-model="state.plan_quantity" size="sm" style="border: solid 1px gray;" placeholder="0"></b-form-input>
                      <span v-else>{{ state?.eta_pod }}</span>
                    </td>
                    <td class="text-center align-top">
                      <b-form-textarea v-if="isEdit" rows="2" placeholder="Comments if any / Komentar jika ada" v-model="state.remarks" name="remarks"></b-form-textarea>
                      <span v-else>{{ state?.remarks ?? '-' }}</span>
                    </td>
                    <td class="text-center align-top">
                      <b-button size="sm" variant="danger" @click="onDeleteProjectContractPlan(state.id, index)" v-if="isEdit"><i class="fa fa-trash pr-0"></i></b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-button block variant="success" @click="addDetailPlanData(true)" v-if="isEdit"><i class="fa fa-plus pr-0"></i> ADD MORE DATA</b-button>
          </fieldset>
        </b-col>
        <b-col md="12">
          <!-- SLIDE - FORM ADD PLAN -->
          <b-sidebar
            v-model="slidePreviewDetailVehicle"
            id="detail_vehicle"
            title="Detail Vehicle"
            width="100%"
            backdrop-variant="dark"
            bg-variant="white"
            right
            backdrop
            shadow>
            <template #default="{hide}">
              <SlidePreviewDetailVehicle 
                :options-fleets="optionsFleets" 
                :vehicle-id="form.vehicle_id" 
                @updateVehicleId="updateVehicleId" 
                :ownership="form.fleet_ownership" 
                :on-open-slide-detail-vehicle="onOpenSlideDetailVehicle"
              />
            </template>
          </b-sidebar>
          <!-- SLIDE - FORM ADD PLAN END -->
      </b-col>
      </b-row>
    </form>
    <div v-else class="alert alert-info m-4">
      Loading...
    </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { projectsActions, companiesActions, fleetsActions, busdevActions, crewingActions } from '@src/Utils/helper'
import SlidePreviewDetailVehicle from './SlidePreviewDetailVehicle.vue'

export default {
  name: 'FormEditAndDetailPlan',
  props:{
    editId:{
      type:Number,
      default:true
    }, 
    refreshDataTable:{
      type:Function,
      default:true
    },  
    openProjectFormSlide:{
      type:Function,
      default:true
    }, 
  },
  components:{
    SlidePreviewDetailVehicle
  },
  data() {
    return {
      saving: false,
      loading:false,
      detail_project_loading:false,
      fleet_loading:false,
      isEdit:false,

      slidePreviewDetailVehicle:false,
    
      optionsFormsByVehicle:[],
      optionsForms:[],
      optionsExpertUsers:[],
      projectOptions: [],
      optionsListCustomer: [],
      optionsTerms: [],
      optionsFleets: [],
      optionsContractType: [],
      optionsCargoType: [],
      optionsStage:[],
      listProjectStage:[],

      customerDetail:null,
      projectDetail:null,

      project_type:'existing',
      
      // COMPANY
      company: [
        {
          name: 'single',
          label: 'EXISTING PROJECT',
          value: 'existing',
          disabled: false
        },
        {
          name: 'single',
          label: 'NEW PROJECT',
          value: 'new_project',
          disabled: false
        }
      ],

      // OWNERSHIP
      ownership: [
        {
          name: 'ownship',
          label: 'OWN SHIP',
          value: 'own-ship',
          disabled: false
        },
        {
          name: 'chartered',
          label: 'CHARTERED',
          value: 'chartered',
          disabled: false
        }
      ],

      detailProjectContract:null,

      contractTypeName:'',
      form: {
        "active": 1, // 1 = active , 0 = Inactive
        "created_by": JSON.parse(localStorage.getItem('profile')).id,
        "created_name": JSON.parse(localStorage.getItem('profile')).userDetail.name,
        "created_date": moment().format('YYYY-MM-DD'),
        "update_by":null,
        "update_date": "",
        "status":"proposed", // proposed, running, waiting, process

        customer_id:null,
        "project_sub_id":null,
        "contract_type_id":null,
        "cargo_type_id":null,
        "detail_project_stage_id":null,
        "history_project_id":null,
        "terms_id":null,
        "periode_start":null,
        "periode_end":null,
        "fleet_ownership":"",
        "vehicle_id":"",
        "laycan":"",
        "pol":"",
        "pod":"",
        "distance":"",
        "duration":"",
        "freight_rate_excl_ppn":"",
        "remarks":"",
        "prioritize":"",

        is_tender: false,
        need_more_detail:false,
        need_approval:false,
        need_document:false,
        is_pfa:false,
        many_approval:null,
        user_approval:[],
        project_forms:[],

        form_document: [
          {
            filename:'',
            file:null
          }
        ],

        formTender:{
          pic:'',
          url:'',
          description_of_tender:'',
          file:''
        },
      },
    }
  },
  async mounted () {
    await this.getListCustomer()
    await this.getListProjectStage() 
    await this.getContractType() 
    await this.getCargoType() 
    await this.getTerms() 
    await this.fetchListProjects() 
    await this.getProjectList() 
    await this.fetchFleets()
    await this.getListCollectionForm()
    await this.fetchDataUserExpert()
  },
  computed: {
    totalQuantityEdit() {
      return this.form?.detail_plan?.reduce((total, item) => total + Number(item.plan_quantity), 0);
    },
  },
  watch: {
    editId: {
      deep: true,
      handler: function (value) {
        if (value) {
          this.getDetailProjectContract(value)
        }
      },
    }
  },
  methods: {
    ...projectsActions,
    ...fleetsActions,
    ...companiesActions,
    ...crewingActions,
    ...busdevActions,
    onOpenSlideDetailVehicle(){
      this.slidePreviewDetailVehicle = !this.slidePreviewDetailVehicle
    },
    updateVehicleId(newVehicleId) {
      // Update vehicleId when received from the child component
      this.form.vehicle_id = newVehicleId;
    },
    onSelectVehicle(id){
      let vehicle = this.optionsFleets.find(obj => obj.value == id)
    },
    onChangeManyApproval(){
      this.form.user_approval = []
      let many = Number(this.form.many_approval)

      for(let i = 0; i < many; i++){
        this.form.user_approval.push({
          user:null
        })
      }
    },
    addFormDocument(){
      this.form.form_document.push({
          filename:'',
          file:null
      })
    },
    onDataIntoPlan(key, value){
      this.form.detail_plan[0][key] = value
    },
    onSelectOwnership(){},
    vehicleName(id){
      let vehicle = this.optionsFleets.find(obj => obj.value == id)

      return vehicle?.text
    },
    userExpertName(id){
      let user = this.optionsExpertUsers.find(obj => obj.value === id)

      return user?.text
    },
    prioritizeProject(prioritize){
      if(prioritize == '1'){
        return 'Low'
      } else if (prioritize == '2'){
        return 'Medium'
      }else if (prioritize == '3'){
        return 'High'
      }else if (prioritize == '4'){
        return 'Very High'
      }else if (prioritize == '5'){
        return 'Immediately'
      } else {
        return "-"
      }
      // 'Prioritize: 1 = low, 2 = medium, 3 = high, 4 = very high, 5 = immediately'
    },
    onSelectedCustomerEdit(){
      const customer = this.optionsListCustomer.find(obj => obj.value === this.form.customer_id)

      if(customer){
        this.customerDetail = customer
      }
    },
    onSelectedProjectEdit(){
      const project = this.projectOptions.find(obj => obj.value === this.form.project_sub_id)

      if(project){
        this.projectDetail = project
      }
    },
    addDetailPlanData(isEdit){
      if(isEdit){
        this.form.detail_plan.push({
          "description":"",
          "fleet_ownership":this.form.fleet_ownership ?? '',
          "vehicle_id":this.form.vehicle_id ?? null,
          "cargo_type_id":this.form.cargo_type_id ?? null,
          "terms_id":this.form.terms_id ?? null,
          "pol":this.form.pol ?? '',
          "pod":this.form.pod ?? '',
          "shipment":"",
          "eta_pol":"",
          "eta_pod":"",
          "plan_quantity":null,
          "remarks":""
        })
      }
    },
    resetFormEdit(){
      this.isEdit = false

      this.contractTypeName = ''

      this.form = {
        "active": 1, // 1 = active , 0 = Inactive
        "created_by": JSON.parse(localStorage.getItem('profile')).id,
        "created_name": JSON.parse(localStorage.getItem('profile')).userDetail.name,
        "created_date": moment().format('YYYY-MM-DD'),
        "update_by":null,
        "update_date": "",
        "status":"proposed", // proposed, running, waiting, process

        customer_id:null,
        "project_sub_id":null,
        "contract_type_id":null,
        "cargo_type_id":null,
        "detail_project_stage_id":null,
        "history_project_id":null,
        "terms_id":null,
        "periode_start":null,
        "periode_end":null,
        "fleet_ownership":"",
        "vehicle_id":"",
        "laycan":"",
        "pol":"",
        "pod":"",
        "distance":"",
        "duration":"",
        "freight_rate_excl_ppn":"",
        "remarks":"",
        "prioritize":"",

        is_tender: false,
        need_more_detail:false,
        need_approval:false,
        need_document:false,
        is_pfa:false,
        many_approval:null,
        user_approval:[],
        project_forms:[],

        form_document: [
          {
            filename:'',
            file:null
          }
        ],

        formTender:{
          pic:'',
          url:'',
          description_of_tender:'',
          file:''
        },
      }
    },
    async onChangeOwnership(value){
      let ownership = value === 'own-ship' ? 'OWNED' : 'CHARTERED'
      this.fetchFleets(ownership)
    },
    searchContractType (query) {
      this.contractTypeName = query
    },
    async doAddContractType () {
      let payload = {
        name:this.contractTypeName,
        alias:this.contractTypeName,
        status:1
      }
      let res = await this.createContractType({data: payload})
      if (res.data.status == true) {
        this.form.contract_type_id = res.data.data
        this.getContractType()
      } else {
        if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
        }
      }
    },
    refreshData(){
      this.project_type = 'existing'
      this.refreshDataTable()
    },

    // FETCHING MASTER DATA
    async getVehicleTypeForm(vehicle_type_id) {
      this.loading_fleet_form = true
      this.optionsFormsByVehicle = []

      let params = {
        page:1,
        perPage:1000000,
        vehicle_type_id: Number(vehicle_type_id)
      }

      let res = await this.fetchVehicleTypeForm(params)

      if (res?.data?.status === true && res?.data?.data?.data?.length > 0) {
        this.optionsFormsByVehicle = res.data.data?.data[0].collection_forms.map(val => {
          return {
            ...val,
            label:`${val.code}/${val.name}`,
          }
        })
        this.loading_fleet_form = false
      }

      this.loading_fleet_form = false
    },
    async getListCollectionForm() {
      this.optionsForms = []
      let res = await this.fetchOptionsCollectionForm()

      if (res?.data?.status === true && res?.data?.data?.length > 0) {
        this.optionsForms = res.data.data.map(val => {
          return {
            ...val,
            label: `${val.code}/${val.name}`
          }
        })
      }
    },
    async fetchDataUserExpert() {
      let params = {
        page: 1,
        active: true,
        perPage: 200,
      }
      const { status, data } = await this.fetchUserExpert(params)

      if (status) {
        if (data && data.length > 0) {
          data.map((state) => {
            this.optionsExpertUsers.push({
              value: state.id,
              email: state.email,
              text: state.userDetail.name,
            })
          })
        }
      }
    },
    async getListCustomer (){
      this.optionsListCustomer = []
      let params = {  
        page:1,
        perPage:100000,
        status:1
      }

      let res = await this.fetchCustomer(params)

      if (res.data.status === true && res.data.data?.data.length > 0) {
        res.data.data?.data.map(val => {
          this.optionsListCustomer?.push({
            ...val,
            value: val.id,
            text:`${val.customer_code} - ${val.account_name}`
          })
        })
      }
    }, 
    async getTerms() {
      let res = await this.fetchTerms()
      if (res.data.status === true && res.data.data.length > 0) {
        res.data.data.map((state) => {
          this.optionsTerms.push({
            value: state.id,
            text: state.name,
          })
        })
      }
    },  
    async getCargoType() {
      let res = await this.fetchCargoType()
      if (res.data.status === true && res.data.data.length > 0) {
        res.data.data.map((state) => {
          this.optionsCargoType.push({
            value: state.id,
            text: state.name,
          })
        })
      }
    }, 
    async getContractType() {
      let res = await this.fetchContractType()
      if (res.data.status === true && res.data.data.length > 0) {
        this.optionsContractType = res.data.data
      }
    },
    async fetchListProjects () {
      this.projects = []
      let params = {
        perPage: 10000,
        page: 1
      }

      let res = await this.getProjects(params)
      if (res.data.status == 'success' || res.data.status == true) {
        if (res.data.data && res.data.data.length > 0) {
          this.projects = res.data.data
        }
      } 
    },
    async getProjectList () {
      const res = await this.fetchProjects()
      if (res.data.status == true) {
        if (res.data.data && res.data.data.length > 0) {
          res.data.data.forEach(val => {
            this.projectOptions.push({
              ...val,
              text: `${val.project.project_name} - ${val.name}`,
              value: val.id
            })
          })
        }
      }
    },
    async fetchFleets(ownership) {
      this.fleet_loading = true
      let params = this.$options.filters.cleanObject({
        page: 1,
        perPage: 9999,
        showAll: true,
        active: true,
        ownership
      })

      if(!params.ownership) delete params.ownership
      
      let res = await this.getFleets(params)

      if (res.status) {
        if (res.data && res.data.length > 0) {
          this.optionsFleets = res.data.map((val) => ({
            text: val.name,
            value: val.id,
            vehicle_type_id: val.vehicleType.id,
          }))
        }
      }

      this.fleet_loading = false
    },
    async getListProjectStage () {
      this.listProjectStage = []
      let res = await this.fetchDetailProjectStage()
      if (res?.status == 'success' || res.data.status == true) {
        if (res.data.data && res.data.data.length > 0) {
          this.optionsStage = res.data.data.map(val => {
            return {
              text:val.name,
              value:val.id
            }
          })
          this.listProjectStage = res.data.data
        }
      } 
    },
 
    async onChangeStatusStage(id, project_stage_id){
      let payload = {
        id,
        project_stage_id
      }

      const res = await this.changeStatusStage({data: payload})

      if(res.data.status === true){
        await this.getDetailProjectContract(id)
        await this.getListProjectStage()
        this.refreshDataTable()
      }
    },

    async onDeleteProjectContractPlan(id, index){
      const res = await this.deleteProjectContractPlan({id})

      if(res.data.status === true){
        this.form.detail_plan.splice(index, 1)
      }
    },

    // ON DELETE
    async onDeleteProjectContract(id){
      this.$swal({
          title: `Are you sure want to delete this?`,
          text: "Yes, i'm sure.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
          if (result.isConfirmed) {
            const res = await this.deleteProjectContract({id})
            if(res.data?.status === true ){
              this.$swal(
                'Success!',
                'Success deleted data.',
                'success'
              )
            } else {
              this.$swal(
                  'Failed!',
                  'Something went wrong.',
                  'error'
              )
              return 
            }
          }
      })
    },

    // ON SAVE SHIPMENT PLANNING
    async onSaveEdit(){
      this.saving = true

      let payload = {
        ...this.form,
        prioritize:'5',
        contract_type_id: Number(this.form.contract_type_id?.id),
        is_tender: this.form.is_tender ? 1 : 0,
        need_more_detail: this.form.need_more_detail ? 1 : 0,
        need_approval: this.form.need_approval ? 1 : 0,
        need_document: this.form.need_document ? 1 : 0,
        many_approval: Number(this.form.many_approval),
        is_pfa: this.form.is_pfa ? 1 : 0,
        detail_plan: this.form.need_more_detail ? this.form.detail_plan : [],
        project_forms: this.form.project_forms.map(val => {
          return {
            collection_forms_id:val.id,
            inspection_checklist_id: null
          }
        }),
        user_approval: this.form.user_approval.map(val => {
          let userExpert = this.optionsExpertUsers.find(obj => obj.value == val.user)
          return {
            id: val.user,
            email:userExpert?.email,
            name:userExpert?.text,
            status_approval:''
          }
        })
      }

      let res = await this.updateProjectContract({data: payload, id: Number(this.detailProjectContract?.id)})

      if([500, 404, 403, 422].includes(res.status)){
          this.saving = false
          this.$swal(`Oops.`, res?.message ?? res?.data?.message ?? 'Something went wrong!', 'error')
          return
        }

      if(res?.data?.status === true){
        if(payload?.is_tender){
          const formData = new FormData()

          formData.append('pic', this.form.formTender.pic)
          formData.append('url', this.form.formTender.url)
          formData.append('description_of_tender', this.form.formTender.description_of_tender)
          // if(this.form?.formTender?.file?.length > 0){
          //   this.form.formTender.file?.forEach((file, fileIndex) => {
          //     formData.append(`file[${fileIndex}]`, file);
          //   });
          // }

          await this.updateProjectContractTender({data: formData, id:Number(this.detailProjectContract?.id)})
        }
        if(payload?.need_document){
          // const formData = new FormData()

          // if(this.form?.form_document?.length > 0){
          //   formData.append('project_contract_id', this.detailProjectContract?.id)
          //   this.form_document?.forEach((item, index) => {
          //     for (const property in item) {
          //       formData.append(`document[${index}][${property}]`, item[property])
          //     }
          //   });
          // }

          // await this.updateProjectContractDocument({data: formData, id: this.detailProjectContract?.id})

          // this.saving = false
        } 

        const promises = payload.user_approval.map(async val => {
          let payload = {
            ...val,
            "to":val.email,
            "name":`Dear, ${val.name}`,
            "subject":"Project Plan has been edited",
            "instructions":`Edited by: ${JSON.parse(localStorage.getItem('profile')).userDetail.email}`,
            "intro":"Project Planning Edited.",
            "outro":"Thank you for reading this email, as you know this email is automatically",
            "buttonText":"Go to Dashboard",
            "url":"busdev/dashboard"
          }

          await this.sendEmailNotification({data: payload})
        })

        Promise.all(promises)

        this.refreshData()
        this.resetFormEdit()
        this.$swal(`Success!`, `Edit Plan Success.`, 'success')
        this.isEdit = false
        this.saving = false
      } else {
        this.saving = false
        this.$swal(`Oops.`, res?.data?.message, 'error')
      }
    }
  }
}
</script>