<template>
  <div>
    <b-row>
      <b-col md="4">
        <b-input v-model="keyword" class="mb-3" placeholder="Search..."></b-input>
      </b-col>
    </b-row>
   
    <b-row
      v-for="(list, index) in erms"
      :key="index"
    >          
      <template v-if="list.data">
        <b-col md="12">
          <iq-card
            class-name="iq-card-block iq-card-stretch iq-card-height"
            body-class=""
          >
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">
                <strong>{{ list.vehicle.name }}</strong>
              </h4>
            </template>
            <template v-slot:headerAction>
              <a
                class="text-primary float-right"
                v-b-toggle="`collapse-erm-i-${index}`"
                role="button"
                style="cursor: pointer"
              >
                <i class="fa fa-filter" />
              </a>
            </template>
            <template v-slot:body>
              <b-collapse :id="`collapse-erm-i-${index}`" class="mb-2" visible>
                <b-row class="font-size-18 sticky-top bg-info text-white px-3 py-3 border-bottom">
                  <b-col md="3">
                    <strong>Vessel:</strong>
                    <span class="ml-2">{{ list.vehicle.name }}</span>
                  </b-col>
                  <b-col md="3">
                    <strong>Assessor:</strong>
                    <span class="ml-2">{{ list.data.assessor_fullname }}</span>
                  </b-col>
                  <b-col md="3">
                    <strong>Target Location:</strong>
                    <span class="ml-2">{{ list.data.target_location }}</span>
                  </b-col>
                  <b-col md="3">
                    <strong>Target PIC:</strong>
                    <span class="ml-2">{{ list.data.target_pic }}</span>
                  </b-col>
                </b-row>
                <b-row class="px-3">
                  <b-col md="12" class="mt-3">
                    <h4>Sasaran Smart</h4>
                    <table class="table table-bordered">
                      <thead>
                      <tr class="bg-warning">
                        <th width="16%" class="text-center">Sasaran</th>
                        <th width="16%" class="text-center">Specific</th>
                        <th width="16%" class="text-center">Measurable</th>
                        <th width="16%" class="text-center">Achievable</th>
                        <th width="16%" class="text-center">Relevant</th>
                        <th width="16%" class="text-center">Time-Bound</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-if="list.data.sasaran_smart.length === 0">
                        <td colspan="6">No Data</td>
                      </tr>
                      <tr
                        v-for="(child, childIndex) in list.data.sasaran_smart"
                        :key="childIndex"
                      >
                        <td class="align-top">
                          {{child.sasaran}}
                        </td>
                        <td class="align-top">
                          {{child.spesific}}
                        </td>
                        <td class="align-top">
                          {{child.measurable}}
                        </td>
                        <td class="align-top">
                          {{child.achievable}}
                        </td>
                        <td class="align-top">
                          {{child.relevant}}
                        </td>
                        <td class="align-top">
                          {{child.timebound}}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <b-col md="12" class="mt-3">
                    <h4>Penamaan Risiko</h4>
                    <table class="table table-bordered">
                      <thead>
                      <tr class="bg-warning">
                        <th width="14%" class="text-center">Sasaran</th>
                        <th width="8%" class="text-center">Nama Risiko</th>
                        <th width="14%" class="text-center">Perisitiwa</th>
                        <th width="14%" class="text-center">Sebab</th>
                        <th width="14%" class="text-center">Konsekuensi</th>
                        <th width="8%" class="text-center">Kategori Risiko</th>
                        <th width="20%" class="text-center">Tindakan</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-if="list.data.penamaan_resiko.length === 0">
                        <td colspan="7">No Data</td>
                      </tr>
                      <tr
                        v-for="(child, childIndex) in list.data.penamaan_resiko"
                        :key="childIndex"
                      >
                        <td class="align-top">
                          {{child.sasaran}}
                        </td>
                        <td class="align-top">
                          {{child.risiko}}
                        </td>
                        <td class="align-top">
                          {{child.peristiwa}}
                        </td>
                        <td class="align-top">
                          {{child.sebab}}
                        </td>
                        <td class="align-top">
                          {{child.konsekuensi}}
                        </td>
                        <td class="align-top">
                          {{child.kategori_risiko}}
                        </td>
                        <td class="align-top">
                          {{child.tindakan}}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <b-col md="12" class="mt-3">
                    <h4>Prinsip Manajemen Risiko</h4>
                    <table class="table table-bordered">
                      <thead>
                      <tr class="bg-warning">
                        <th width="15%" class="text-center">Prinsip Manajemen Risiko</th>
                        <th width="25%" class="text-center">Definisi Prinsip</th>
                        <th width="25%" class="text-center">Implementasi Manajemen Risiko</th>
                        <th width="10%" class="text-center">Status Implementasi</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-if="list.data.prinsip_manajemen_resiko.length === 0">
                        <td colspan="4">No Data</td>
                      </tr>
                      <tr
                        v-for="(child, childIndex) in list.data.prinsip_manajemen_resiko"
                        :key="childIndex"
                      >
                        <td class="align-top">
                          {{child.prinsip}}
                        </td>
                        <td class="align-top">
                          {{child.definisi}}
                        </td>
                        <td class="align-top">
                          {{child.implementasi}}
                        </td>
                        <td class="align-top">
                          {{child.status}}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <b-col md="12" class="mt-3">
                    <h4>Kepemimpinan Dan Komitmen</h4>
                    <table class="table table-bordered">
                      <thead>
                      <tr class="bg-warning">
                        <th width="15%" class="text-center">Pemimpin Risiko</th>
                        <th width="24%" class="text-center">Uraian Tugas Pemimpin Risiko</th>
                        <th width="20%" class="text-center">Dokumen Pendukung</th>
                        <th width="24%" class="text-center">Remarks</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-if="list.data.kepemimpinan_komitmen.length === 0">
                        <td colspan="4">No Data</td>
                      </tr>
                      <tr
                        v-for="(child, childIndex) in list.data.kepemimpinan_komitmen"
                        :key="childIndex"
                      >
                        <td class="align-top">
                          {{child.pimpinan}}
                        </td>
                        <td class="align-top">
                          {{child.tugas}}
                        </td>
                        <td class="align-top">
                          <div v-if="child.dokumen" class="d-flex justify-content-between">
                            <a :href="child.dokumen" class="text-primary" v-b-tooltip.hover title="Download File" download><i class="fa fa-download"></i> Download</a>
                          </div>
                          <div v-else>
                            -
                          </div>
                        </td>
                        <td class="align-top">
                          {{child.remarks}}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <b-col md="12" class="mt-3">
                    <h4>Analisa Pemangku Kepentingan</h4>
                    <table class="table table-bordered">
                      <thead>
                      <tr class="bg-warning">
                        <th width="11%" class="text-center">Status</th>
                        <th width="11%" class="text-center">Nama</th>
                        <th width="14%" class="text-center">Kebutuhan/Tantangan</th>
                        <th width="11%" class="text-center">Dukungan</th>
                        <th width="11%" class="text-center">Ancaman</th>
                        <th width="11%" class="text-center">Strategi</th>
                        <th width="14%" class="text-center">Deskripsi Tindakan</th>
                        <th width="14%" class="text-center">Remarks</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-if="list.data.analisa_pemangku_jabatan.length === 0">
                        <td colspan="8">No Data</td>
                      </tr>
                      <tr
                        v-for="(child, childIndex) in list.data.analisa_pemangku_jabatan"
                        :key="childIndex"
                      >
                        <td class="align-top">
                          {{child.status}}
                        </td>
                        <td class="align-top">
                          {{child.nama}}
                        </td>
                        <td class="align-top">
                          {{child.tantangan}}
                        </td>
                        <td class="align-top">
                          {{child.dukungan}}
                        </td>
                        <td class="align-top">
                          {{child.ancaman}}
                        </td>
                        <td class="align-top">
                          {{child.strategi}}
                        </td>
                        <td class="align-top">
                          {{child.tindakan}}
                        </td>
                        <td class="align-top">
                          {{child.remarks}}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <b-col md="12" class="mt-3">
                    <h4>Integrasi - Sasaran Smart</h4>
                    <table class="table table-bordered">
                      <thead>
                      <tr class="bg-warning">
                        <th width="25%" class="text-center">Status Kesesuaian</th>
                        <th width="25%" class="text-center">Deskripsi</th>
                        <th width="25%" class="text-center">Dokumen Pendukung</th>
                        <th width="25%" class="text-center">Remarks</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-if="list.data.integrasi_sasaran_smart.length === 0">
                        <td colspan="4">No Data</td>
                      </tr>
                      <tr
                        v-for="(child, childIndex) in list.data.integrasi_sasaran_smart"
                        :key="childIndex"
                      >
                        <td class="align-top">
                          {{child.kesesuaian}}
                        </td>
                        <td class="align-top">
                          {{child.deskripsi}}
                        </td>
                        <td class="align-top">
                          <div v-if="child.dokumen" class="d-flex justify-content-between">
                            <a :href="child.dokumen" class="text-primary" v-b-tooltip.hover title="Download File" download><i class="fa fa-download"></i> Download</a>
                          </div>
                          <div v-else>
                            -
                          </div>
                        </td>
                        <td class="align-top">
                          {{child.remarks}}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <b-col md="12" class="mt-3">
                    <h4>Evaluasi</h4>
                    <table class="table table-bordered">
                      <thead>
                      <tr class="bg-warning">
                        <th width="13%" class="text-center">Tahapan Kegiatan</th>
                        <th width="13%" class="text-center">Sasaran Tahapan (Milestone)</th>
                        <th width="13%" class="text-center">Uraian Tugas</th>
                        <th width="13%" class="text-center">Indikator Keberhasilan</th>
                        <th width="12%" class="text-center">Metode Evaluasi</th>
                        <th width="12%" class="text-center">Frekuensi</th>
                        <th width="12%" class="text-center">Remarks</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-if="list.data.evaluasi.length === 0">
                        <td colspan="7">No Data</td>
                      </tr>
                      <tr
                        v-for="(child, childIndex) in list.data.evaluasi"
                        :key="childIndex"
                      >
                        <td class="align-top">
                          {{child.kegiatan}}
                        </td>
                        <td class="align-top">
                          {{child.sasaran}}
                        </td>
                        <td class="align-top">
                          {{child.uraian}}
                        </td>
                        <td class="align-top">
                          {{child.keberhasilan}}
                        </td>
                        <td class="align-top">
                          {{child.evaluasi}}
                        </td>
                        <td class="align-top">
                          {{child.frekuensi}}
                        </td>
                        <td class="align-top">
                          {{child.remarks}}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <b-col md="12" class="mt-3">
                    <h4>Perbaikan Sinambung</h4>
                    <table class="table table-bordered">
                      <thead>
                      <tr class="bg-warning">
                        <th width="14%" class="text-center">Tahapan</th>
                        <th width="14%" class="text-center"
                        >Sasaran Tahapan</th
                        >
                        <th width="15%" class="text-center">Uraian Tugas</th>
                        <th width="14%" class="text-center"
                        >Indikator Keberhasilan</th
                        >
                        <th width="14%" class="text-center"
                        >Hasil Evaluasi</th
                        >
                        <th width="15%" class="text-center">Frekuensi</th>
                        <th width="14%" class="text-center">Pelaksana</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-if="list.data.perbaikan_sinambung.length === 0">
                        <td colspan="7">No Data</td>
                      </tr>
                      <tr
                        v-for="(child, childIndex) in list.data.perbaikan_sinambung"
                        :key="childIndex"
                      >
                        <td class="align-top">
                          {{child.tahapan}}
                        </td>
                        <td class="align-top">
                          {{child.sasaran}}
                        </td>
                        <td class="align-top">
                          {{child.uraian}}
                        </td>
                        <td class="align-top">
                          {{child.keberhasilan}}
                        </td>
                        <td class="align-top">
                          {{child.evaluasi}}
                        </td>
                        <td class="align-top">
                          {{child.frekuensi}}
                        </td>
                        <td class="align-top">
                          {{child.remarks}}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <b-col md="12" class="mt-3">
                    <h4>Komunikasi Dan Konsultasi</h4>
                    <table class="table table-bordered">
                      <thead>
                      <tr class="bg-warning">
                        <th width="12%" class="text-center">Pemangku</th>
                        <th width="12%" class="text-center">Konten</th>
                        <th width="12%" class="text-center">Tujuan</th>
                        <th width="12%" class="text-center">Komunikator</th>
                        <th width="12%" class="text-center">Waktu</th>
                        <th width="12%" class="text-center">Frekuensi</th>
                        <th width="12%" class="text-center">Media/Metode</th>
                        <th width="12%" class="text-center">PIC</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-if="list.data.komunikasi_konsultasi.length === 0">
                        <td colspan="8">No Data</td>
                      </tr>
                      <tr
                        v-for="(child, childIndex) in list.data.komunikasi_konsultasi"
                        :key="childIndex"
                      >
                        <td class="align-top">
                          {{child.pemangku}}
                        </td>
                        <td class="align-top">
                          {{child.konten}}
                        </td>
                        <td class="align-top">
                          {{child.tujuan}}
                        </td>
                        <td class="align-top">
                          {{child.komunikator}}
                        </td>
                        <td class="align-top">
                          {{child.waktu}}
                        </td>
                        <td class="align-top">
                          {{child.frekuensi}}
                        </td>
                        <td class="align-top">
                          {{child.metode}}
                        </td>
                        <td class="align-top">
                          {{child.pic}}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <b-col md="12" class="mt-3">
                    <h4>Raci Matrix</h4>
                    <b-row>
                      <b-col md="12" class="mt-2 m-auto">
                        <table class="table mb-2" v-if="raciMatrix">
                          <thead>
                          <tr>
                            <th class="text-center" rowspan="2">Details</th>
                            <th class="text-center" v-if="raciMatrix.columns" :colspan="raciMatrix.columns.length">Owner Risk</th>
                          </tr>
                          <tr v-if="raciMatrix.columns">
                            <th class="text-center" v-for="(col, colIndex) in raciMatrix.columns" :key="colIndex">{{ col.name }}</th>
                          </tr>
                          </thead>
                          <tbody v-if="raciMatrix.rows">
                          <tr v-for="(row, rowIndex) in raciMatrix.rows" :key="rowIndex">
                            <td>
                              <strong>{{ row.name }}</strong>
                            </td>
                            <template v-if="raciMatrix.columns">
                              <td class="text-center" v-for="(col, colIndex) in raciMatrix.columns" :key="colIndex">
                                {{raxiMatrixFormValue(index, row.id, col.id) && raxiMatrixFormValue(index, row.id, col.id).length > 0 ? raxiMatrixFormValue(index, row.id, col.id).join(',') : '-'}}
                              </td>
                            </template>
                          </tr>
                          </tbody>
                        </table>
                      </b-col>
                    </b-row>
                    <hr />
                    <b-row>
                      <b-col md="1">
                        <div class="bg-danger text-center text-white">
                          <h4 class="text-white">R</h4>
                        </div>
                      </b-col>
                      <b-col md="11">
                        <p
                        ><strong>"Responsible"</strong> adalah orang atau unit
                          yang harus melaksanakan kegiatan atau aktivitas dalam
                          mekanisme informasi dan pelaporan</p
                        >
                      </b-col>

                      <b-col md="1">
                        <div class="bg-warning text-center text-white">
                          <h4 class="text-white">A</h4>
                        </div>
                      </b-col>
                      <b-col md="11">
                        <p
                        ><strong>"Accountable"</strong> adalah orang atau unit
                          yang berhak membuat keputusan atas kegiatan dalam
                          mekanisme informasi dan pelaporan</p
                        >
                      </b-col>

                      <b-col md="1">
                        <div class="bg-success text-center text-white">
                          <h4 class="text-white">C</h4>
                        </div>
                      </b-col>
                      <b-col md="11">
                        <p
                        ><strong>"Consulted"</strong> adalah orang atau unit yang
                          harus di ajak konsultasi atau dilibatkan sebelum atau saat
                          kegiatan dalam mekanisme informasi dan pelaporan</p
                        >
                      </b-col>

                      <b-col md="1">
                        <div class="bg-info text-center text-white">
                          <h4 class="text-white">I</h4>
                        </div>
                      </b-col>
                      <b-col md="11">
                        <p
                        ><strong>"Informed"</strong> adalah orang atau unit yang
                          harus di beri cukup informasi mengenal kegiatan dalam
                          mekanisme informasi, berikut pelaporan hasil dan proses
                          pelaksanaan</p
                        >
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col md="12" class="mt-3">
                    <h4>Kriteria Konsultasi</h4>
                    <table class="table table-bordered">
                      <thead>
                      <tr class="bg-warning">
                        <th width="10%" class="text-center"
                        >Skala Konsekuensi</th
                        >
                        <th width="35%" class="text-center"
                        >Deskripsi Skala</th
                        >
                        <th width="20%" class="text-center"
                        >Deskripsi Kriteria</th
                        >
                        <th width="35%" class="text-center">Remarks</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-if="list.data.kriteria_konsekuensi.length === 0">
                        <td colspan="4">No Data</td>
                      </tr>
                      <tr
                        v-for="(child, childIndex) in list.data.kriteria_konsekuensi"
                        :key="childIndex"
                      >
                        <td class="align-top">
                          {{child.skala}}
                        </td>
                        <td class="align-top">
                          {{child.deskripsi_skala}}
                        </td>
                        <td class="align-top">
                          {{child.deskripsi_kriteria}}
                        </td>
                        <td class="align-top">
                          {{child.remarks}}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <b-col md="12" class="mt-3">
                    <h4>Kriteria Kemungkinan</h4>
                    <table class="table table-bordered">
                      <thead>
                      <tr class="bg-warning">
                        <th width="10%" class="text-center"
                        >Skala Kemungkinan</th
                        >
                        <th width="35%" class="text-center"
                        >Deskripsi Skala</th
                        >
                        <th width="20%" class="text-center"
                        >Deskripsi Kriteria</th
                        >
                        <th width="35%" class="text-center">Remarks</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-if="list.data.kriteria_kemungkinan.length === 0">
                        <td colspan="4">No Data</td>
                      </tr>
                      <tr
                        v-for="(child, childIndex) in list.data.kriteria_kemungkinan"
                        :key="childIndex"
                      >
                        <td class="align-top">
                          {{child.skala}}
                        </td>
                        <td class="align-top">
                          {{child.deskripsi_skala}}
                        </td>
                        <td class="align-top">
                          {{child.deskripsi_kriteria}}
                        </td>
                        <td class="align-top">
                          {{child.remarks}}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <b-col md="12" class="mt-3">
                    <h4>Efektifitas Kendali</h4>
                    <table class="table table-bordered">
                      <thead>
                      <tr class="bg-warning">
                        <th width="10%" class="text-center"
                        >Skala Efiktifitas Kendali</th
                        >
                        <th width="35%" class="text-center"
                        >Deskripsi Skala</th
                        >
                        <th width="20%" class="text-center"
                        >Deskripsi Kriteria</th
                        >
                        <th width="35%" class="text-center">Remarks</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-if="list.data.efektifitas_kendali.length === 0">
                        <td colspan="4">No Data</td>
                      </tr>
                      <tr
                        v-for="(child, childIndex) in list.data.efektifitas_kendali"
                        :key="childIndex"
                      >
                        <td class="align-top">
                          {{child.skala}}
                        </td>
                        <td class="align-top">
                          {{child.deskripsi_skala}}
                        </td>
                        <td class="align-top">
                          {{child.deskripsi_kriteria}}
                        </td>
                        <td class="align-top">
                          {{child.remarks}}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <b-col md="12" class="mt-3">
                    <h4>Kriteria Evaluasi</h4>
                    <table class="table table-bordered">
                      <thead>
                      <tr class="bg-warning">
                        <th width="10%" class="text-center"
                        >Efiktifitas Kendali</th
                        >
                        <th width="30%" class="text-center">Selera Risiko</th>
                        <th width="30%" class="text-center"
                        >Toleransi Risiko</th
                        >
                        <th width="30%" class="text-center"
                        >Tindakan & Eskalasi</th
                        >
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-if="list.data.kriteria_evaluasi.length === 0">
                        <td colspan="4">No Data</td>
                      </tr>
                      <tr
                        v-for="(child, childIndex) in list.data.kriteria_evaluasi"
                        :key="childIndex"
                      >
                        <td class="align-top">
                          {{child.skala}}
                        </td>
                        <td class="align-top">
                          {{child.selera}}
                        </td>
                        <td class="align-top">
                          {{child.toleransi}}
                        </td>
                        <td class="align-top">
                          {{child.tindakan}}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <b-col md="12" class="mt-3">
                    <h4>Identifikasi Risiko</h4>
                    <table class="table table-bordered">
                      <thead>
                      <tr class="bg-warning">
                        <th width="25%" class="text-center">Sasaran</th>
                        <th width="25%" class="text-center">Peristiwa Risiko</th>
                        <th width="25%" class="text-center">Penyebab</th>
                        <th width="25%" class="text-center">Akibat</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-if="list.data.identifikasi_resiko.length === 0">
                        <td colspan="4">No Data</td>
                      </tr>
                      <tr
                        v-for="(child, childIndex) in list.data.identifikasi_resiko"
                        :key="childIndex"
                      >
                        <td class="align-top">
                          {{child.sasaran}}
                        </td>
                        <td class="align-top">
                          {{child.peristiwa}}
                        </td>
                        <td class="align-top">
                          {{child.penyebab}}
                        </td>
                        <td class="align-top">
                          {{child.akibat}}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <b-col md="12" class="mt-3">
                    <h4>Analisis Dan Evaluasi Risiko</h4>
                    <table class="table table-bordered">
                      <thead>
                      <tr class="bg-warning">
                        <th width="18%" class="text-center">Peristiwa Risiko</th>
                        <th width="12%" class="text-center">Efektivitas</th>
                        <th width="12%" class="text-center">Konsekuensi</th>
                        <th width="12%" class="text-center">Kemungkinan</th>
                        <th width="12%" class="text-center">Total Eksposur</th>
                        <th width="12%" class="text-center">Tingkat Risiko</th>
                        <th width="12%" class="text-center">Priority Risk</th>
                        <th width="12%" class="text-center">Evaluasi Risiko</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-if="list.data.analisis_evaluasi_resiko.length === 0">
                        <td colspan="8">No Data</td>
                      </tr>
                      <tr
                        v-for="(child, childIndex) in list.data.analisis_evaluasi_resiko"
                        :key="childIndex"
                      >
                        <td class="align-top">
                          {{child.peristiwa}}
                        </td>
                        <td class="align-top">
                          {{child.efektifitas}}
                        </td>
                        <td class="align-top">
                          {{child.konsekuensi}}
                        </td>
                        <td class="align-top">
                          {{child.kemungkinan}}
                        </td>
                        <td class="align-top">
                          {{child.eksposur}}
                        </td>
                        <td class="align-top">
                          {{child.tingkat_risiko}}
                        </td>
                        <td class="align-top">
                          {{child.prioritas}}
                        </td>
                        <td class="align-top">
                          {{child.evaluasi}}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <b-col md="12" class="mt-3">
                    <h4>Peta Risiko - Before</h4>
                    <table class="table table-bordered">
                      <thead>
                      <tr class="bg-warning">
                        <th rowspan="2" class="text-center">Likelihood/Kemungkinan/</th>
                        <th colspan="5" class="text-center">Impact/Konsekuensi</th>
                      </tr>
                      <tr class="bg-warning">
                        <th class="text-center" v-for="col in 5" :key="col">{{ col }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="row in 5" :key="row">
                        <td class="text-center">
                          <p>{{row}}</p>
                        </td>
                        <td class="text-center" v-for="col in 5" :key="col" :class="{'bg-danger text-white' : petaRisikoBefore(index, row, col).length > 0}">
                          {{ petaRisikoBefore(index, row, col).map((r, ir) => ir+1).join(',')}}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <b-col md="12" class="mt-3">
                    <h4>Perlakuan Risiko</h4>
                    <table class="table table-bordered">
                      <thead>
                      <tr class="bg-warning">
                        <th width="15%" class="text-center"
                        >Peristiwa Risiko</th
                        >
                        <th width="8%" class="text-center">Total Eksposur</th>
                        <th width="8%" class="text-center"
                        >Evaluasi Risiko</th
                        >
                        <th width="19%" class="text-center"
                        >Tindakan/Kendali</th
                        >
                        <th width="10%" class="text-center"
                        >Frekuensi Pemantauan</th
                        >
                        <th width="10%" class="text-center"
                        >Frekuensi Pelaporan</th
                        >
                        <th width="10%" class="text-center"
                        >Konsekuensi Residual</th
                        >
                        <th width="10%" class="text-center"
                        >Kemungkinan Residual</th
                        >
                        <th width="10%" class="text-center"
                        >Total Eksposur Residual</th
                        >
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-if="list.data.perlakuan_resiko.length === 0">
                        <td colspan="9">No Data</td>
                      </tr>
                      <tr
                        v-for="(child, childIndex) in list.data.perlakuan_resiko"
                        :key="childIndex"
                      >
                        <td class="align-top">
                          {{child.peristiwa}}
                        </td>
                        <td class="align-top">
                          {{child.total_eksposur}}
                        </td>
                        <td class="align-top">
                          {{child.evaluasi_resiko}}
                        </td>
                        <td class="align-top">
                          {{child.tindakan}}
                        </td>
                        <td class="align-top">
                          {{child.frekuensi_pemantauan}}
                        </td>
                        <td class="align-top">
                          {{child.frekuensi_pelaporan}}
                        </td>
                        <td class="align-top">
                          {{child.konsekuensi_residual}}
                        </td>
                        <td class="align-top">
                          {{child.kemungkinan_residual}}
                        </td>
                        <td class="align-top">
                          {{child.total_eksposur_residual}}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <b-col md="12" class="mt-3">
                    <h4>Peta Risiko - After</h4>
                    <table class="table table-bordered">
                      <thead>
                      <tr class="bg-warning">
                        <th rowspan="2" class="text-center">Likelihood/Kemungkinan/</th>
                        <th colspan="5" class="text-center">Impact/Konsekuensi</th>
                      </tr>
                      <tr class="bg-warning">
                        <th class="text-center" v-for="col in 5" :key="col">{{ col }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="row in 5" :key="row">
                        <td class="text-center">
                          <p>{{row}}</p>
                        </td>
                        <td class="text-center" v-for="col in 5" :key="col" :class="{'bg-success text-white' : petaRisikoAfter(index, row, col).length > 0}">
                          {{ petaRisikoAfter(index, row, col).map((r, ir) => ir+1).join(',')}}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
              </b-collapse>
            </template>
          </iq-card>
        </b-col>
      </template>
      <b-col md="12" v-else>
        <iq-card
          class-name="iq-card-block iq-card-stretch iq-card-height"
          body-class=""
        >
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">
              <strong>{{ list?.vehicle?.name ?? "N/A" }}</strong> -
              <strong v-if="list?.type_data?.type" :class="`${list?.type_data?.type === 'non_claim' ? 'text-warning' : 'text-success'}`">{{ list?.type_data?.type === 'non_claim' ? 'Non Claim' : 'Claim' }}</strong> -
              <strong v-if="list?.type_data?.date_of_loss">{{ moment(list?.type_data?.date_of_loss).format('DD MMMM YYYY') }}</strong> 
            </h4>
          </template>
          <template v-slot:body>
            <strong class="uppercase">No Data.</strong>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment'
import { numberFormat } from '@src/plugins/helpers'
import { miActions } from '@src/Utils/helper'

export default {
  name: 'SummaryManagementERM',
  props: {
    lists: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  mounted () {
    this.fetchRaciMatrix()
  },
  computed: {
    erms () {
      if (!this.lists) {
        return []
      }

      return this.lists.filter(form => {
        if (!this.keyword) {
          return true
        }

        return (form.vehicle && form.vehicle.name.toString().toLowerCase().includes(this.keyword.toLowerCase()))
      })
    }
  },
  data () {
    return {
      raciMatrix: null,
      keyword: ''
    }
  },
  methods: {
    ...miActions,
    async fetchRaciMatrix () {
      let { data, status } = await this.getRaciMatrix()

      if (status === 'success') {
        if (data) {
          this.raciMatrix = data
        }
      }
    },
    numberFormat (number) {
      return numberFormat(number)
    },
    raxiMatrixFormValue (ermsIndex, matrixId, departmentId) {
      const erm = this.erms[ermsIndex]

      if (erm && erm.data) {
        const form = erm.data.raci_matrix.find(matrix => matrix.raci_matrix_id === matrixId && matrix.department_id === departmentId)
        if (form) {
          return form.value
        }
      }

      return null
    },
    petaRisikoBefore (ermsIndex, likelihoodNumber, impactNumber) {
      const erm = this.erms[ermsIndex]

      if (erm && erm.data) {
        return erm.data.analisis_evaluasi_resiko.filter(row => Number(row.kemungkinan) === Number(likelihoodNumber) && Number(row.konsekuensi) === Number(impactNumber))
      }

      return []
    },
    petaRisikoAfter (ermsIndex, likelihoodNumber, impactNumber) {
      const erm = this.erms[ermsIndex]

      if (erm && erm.data) {
        return erm.data.perlakuan_resiko.filter(row => Number(row.kemungkinan_residual) === Number(likelihoodNumber) && Number(row.konsekuensi_residual) === Number(impactNumber))
      }

      return []
    }
  }
}
</script>

<style scoped>

</style>
