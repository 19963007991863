var render = function render(){
  var _vm$vehicle, _vm$detailData$polDra, _vm$detailData, _vm$detailData2, _vm$detailData3, _vm$detailData4, _vm$detailData5, _vm$detailData6, _vm$detailData7, _vm$detailData8, _vm$detailData9, _vm$detailData10, _vm$detailData11, _vm$detailData12, _vm$detailData13, _vm$detailData14, _vm$detailData15, _vm$detailData$podDra, _vm$detailData16, _vm$detailData17, _vm$detailData18, _vm$detailData19, _vm$detailData20, _vm$detailData21, _vm$detailData22, _vm$detailData23, _vm$detailData24, _vm$detailData25, _vm$detailData26, _vm$detailData27, _vm$detailData28, _vm$detailData29, _vm$detailData30;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "m-4"
  }, [!_vm.loading ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("DETAIL TRIP")]), _c('b-row', {
    staticClass: "p-2"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-row', {
    staticClass: "m-2"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "FLEET"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$vehicle = _vm.vehicle) === null || _vm$vehicle === void 0 ? void 0 : _vm$vehicle.name))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "SECOND FLEET"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v("-")])])], 1)], 1), _c('b-row', {
    staticClass: "m-2 py-2",
    staticStyle: {
      "background-color": "#E5F9FF"
    }
  }, [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "12"
    }
  }, [_c('strong', {
    staticClass: "text-primary"
  }, [_vm._v("LOADING ACTIVITY RECORD")])]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Port of Loading"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v("-")])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Agent POL"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v("-")])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Draught Survey"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$detailData$polDra = (_vm$detailData = _vm.detailData) === null || _vm$detailData === void 0 ? void 0 : _vm$detailData.polDraughtSurvey) !== null && _vm$detailData$polDra !== void 0 ? _vm$detailData$polDra : 0))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Arival Time POL"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$detailData2 = _vm.detailData) !== null && _vm$detailData2 !== void 0 && _vm$detailData2.polArrivalTime ? _vm.moment((_vm$detailData3 = _vm.detailData) === null || _vm$detailData3 === void 0 ? void 0 : _vm$detailData3.polArrivalTime, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Anchor Up"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$detailData4 = _vm.detailData) !== null && _vm$detailData4 !== void 0 && _vm$detailData4.polAnchorUp ? _vm.moment((_vm$detailData5 = _vm.detailData) === null || _vm$detailData5 === void 0 ? void 0 : _vm$detailData5.polAnchorUp, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "In Position / Berthing"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$detailData6 = _vm.detailData) !== null && _vm$detailData6 !== void 0 && _vm$detailData6.polBerthing ? _vm.moment((_vm$detailData7 = _vm.detailData) === null || _vm$detailData7 === void 0 ? void 0 : _vm$detailData7.polBerthing, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Cast Out"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$detailData8 = _vm.detailData) !== null && _vm$detailData8 !== void 0 && _vm$detailData8.polCastOut ? _vm.moment((_vm$detailData9 = _vm.detailData) === null || _vm$detailData9 === void 0 ? void 0 : _vm$detailData9.polCastOut, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "LHV"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$detailData10 = _vm.detailData) !== null && _vm$detailData10 !== void 0 && _vm$detailData10.polLhv ? _vm.moment((_vm$detailData11 = _vm.detailData) === null || _vm$detailData11 === void 0 ? void 0 : _vm$detailData11.polLhv, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Document on Board POL"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$detailData12 = _vm.detailData) !== null && _vm$detailData12 !== void 0 && _vm$detailData12.polDocOnBoard ? _vm.moment((_vm$detailData13 = _vm.detailData) === null || _vm$detailData13 === void 0 ? void 0 : _vm$detailData13.polDocOnBoard, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Departure to POD"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$detailData14 = _vm.detailData) !== null && _vm$detailData14 !== void 0 && _vm$detailData14.polDeparture ? _vm.moment((_vm$detailData15 = _vm.detailData) === null || _vm$detailData15 === void 0 ? void 0 : _vm$detailData15.polDeparture, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-'))])])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-row', {
    staticClass: "m-2"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticStyle: {
      "opacity": "0"
    },
    attrs: {
      "label": "PROJECT"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v("-")])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticStyle: {
      "opacity": "0"
    },
    attrs: {
      "label": "PROJECT"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v("-")])])], 1)], 1), _c('b-row', {
    staticClass: "m-2 py-2",
    staticStyle: {
      "background-color": "#EFFEE6"
    }
  }, [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "12"
    }
  }, [_c('strong', {
    staticClass: "text-primary"
  }, [_vm._v("LOADING ACTIVITY RECORD")])]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Port of Discharge"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v("-")])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Agent POD"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v("-")])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Draught Survey"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$detailData$podDra = (_vm$detailData16 = _vm.detailData) === null || _vm$detailData16 === void 0 ? void 0 : _vm$detailData16.podDraughtSurvey) !== null && _vm$detailData$podDra !== void 0 ? _vm$detailData$podDra : 0))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Arival Time POL"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$detailData17 = _vm.detailData) !== null && _vm$detailData17 !== void 0 && _vm$detailData17.podArrivalTime ? _vm.moment((_vm$detailData18 = _vm.detailData) === null || _vm$detailData18 === void 0 ? void 0 : _vm$detailData18.podArrivalTime, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Anchor Up"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$detailData19 = _vm.detailData) !== null && _vm$detailData19 !== void 0 && _vm$detailData19.podAnchorUp ? _vm.moment((_vm$detailData20 = _vm.detailData) === null || _vm$detailData20 === void 0 ? void 0 : _vm$detailData20.podAnchorUp, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "In Position / Berthing"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$detailData21 = _vm.detailData) !== null && _vm$detailData21 !== void 0 && _vm$detailData21.podBerthing ? _vm.moment((_vm$detailData22 = _vm.detailData) === null || _vm$detailData22 === void 0 ? void 0 : _vm$detailData22.podBerthing, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Cast Out"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$detailData23 = _vm.detailData) !== null && _vm$detailData23 !== void 0 && _vm$detailData23.podCastOut ? _vm.moment((_vm$detailData24 = _vm.detailData) === null || _vm$detailData24 === void 0 ? void 0 : _vm$detailData24.podCastOut, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "LHV"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$detailData25 = _vm.detailData) !== null && _vm$detailData25 !== void 0 && _vm$detailData25.podLhv ? _vm.moment((_vm$detailData26 = _vm.detailData) === null || _vm$detailData26 === void 0 ? void 0 : _vm$detailData26.podLhv, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Document on Board POL"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$detailData27 = _vm.detailData) !== null && _vm$detailData27 !== void 0 && _vm$detailData27.podDocOnBoard ? _vm.moment((_vm$detailData28 = _vm.detailData) === null || _vm$detailData28 === void 0 ? void 0 : _vm$detailData28.podDocOnBoard, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-'))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Departure to POD"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$detailData29 = _vm.detailData) !== null && _vm$detailData29 !== void 0 && _vm$detailData29.podDeparture ? _vm.moment((_vm$detailData30 = _vm.detailData) === null || _vm$detailData30 === void 0 ? void 0 : _vm$detailData30.podDeparture, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-'))])])], 1)], 1)], 1)], 1), _c('b-row', {
    staticClass: "p-3"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('h4', [_c('strong', {
    staticClass: "text-primary"
  }, [_vm._v("Activities")])]), _c('hr'), _c('div', {
    staticClass: "d-flex overflow-auto"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center table-info text-truncate",
    staticStyle: {
      "width": "5px"
    }
  }, [_vm._v("NO")]), _c('th', {
    staticClass: "text-center table-info text-truncate"
  }, [_vm._v("START DATE")]), _c('th', {
    staticClass: "text-center table-info text-truncate"
  }, [_vm._v("END DATE")]), _c('th', {
    staticClass: "text-center table-info text-truncate"
  }, [_vm._v("REASON")]), _c('th', {
    staticClass: "text-center table-info text-truncate"
  }, [_vm._v("HOURS")]), _c('th', {
    staticClass: "text-center table-info text-truncate"
  }, [_vm._v("CARGO LOADING")]), _c('th', {
    staticClass: "text-center table-info text-truncate"
  }, [_vm._v("CARGO UNLOADING")]), _c('th', {
    staticClass: "text-center table-info text-truncate"
  }, [_vm._v("CURRENT POSITION")]), _c('th', {
    staticClass: "text-center table-info text-truncate"
  }, [_vm._v("REMARKS")]), _c('th', {
    staticClass: "text-center table-info text-truncate"
  }, [_vm._v("GPS")]), _c('th', {
    staticClass: "text-center table-info text-truncate"
  }, [_vm._v("SPEED")]), _c('th', {
    staticClass: "text-center table-info text-truncate"
  }, [_vm._v("REASON")]), _c('th', {
    staticClass: "text-center table-info"
  }, [_vm._v("ACTION")])])]), _c('tbody', _vm._l(_vm.activities, function (state, index) {
    var _state$reason$name, _state$reason, _state$cargoLoading, _state$cargoUnloading, _state$currentPositio, _state$remark, _state$reason2;
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(index + 1))]), _c('td', {
      staticClass: "text-center text-truncate"
    }, [_vm._v(_vm._s(state !== null && state !== void 0 && state.startDate ? _vm.moment(state === null || state === void 0 ? void 0 : state.startDate, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-'))]), _c('td', {
      staticClass: "text-center text-truncate"
    }, [_vm._v(_vm._s(state !== null && state !== void 0 && state.endDate ? _vm.moment(state === null || state === void 0 ? void 0 : state.endDate, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-'))]), _c('td', {
      staticClass: "text-center text-truncate"
    }, [_vm._v(_vm._s((_state$reason$name = state === null || state === void 0 ? void 0 : (_state$reason = state.reason) === null || _state$reason === void 0 ? void 0 : _state$reason.name) !== null && _state$reason$name !== void 0 ? _state$reason$name : '-'))]), _c('td', {
      staticClass: "text-center text-truncate"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.activityDurationFormat))]), _c('td', {
      staticClass: "text-center text-truncate"
    }, [_vm._v(_vm._s((_state$cargoLoading = state === null || state === void 0 ? void 0 : state.cargoLoading) !== null && _state$cargoLoading !== void 0 ? _state$cargoLoading : '-'))]), _c('td', {
      staticClass: "text-center text-truncate"
    }, [_vm._v(_vm._s((_state$cargoUnloading = state === null || state === void 0 ? void 0 : state.cargoUnloading) !== null && _state$cargoUnloading !== void 0 ? _state$cargoUnloading : '-'))]), _c('td', {
      staticClass: "text-center text-truncate"
    }, [_vm._v(_vm._s((_state$currentPositio = state === null || state === void 0 ? void 0 : state.currentPosition) !== null && _state$currentPositio !== void 0 ? _state$currentPositio : '-'))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s((_state$remark = state === null || state === void 0 ? void 0 : state.remark) !== null && _state$remark !== void 0 ? _state$remark : '-'))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v("-")]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.speed))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$reason2 = state.reason) === null || _state$reason2 === void 0 ? void 0 : _state$reason2.name))]), _c('td', {
      staticClass: "text-center"
    }, [_c('b-dropdown', {
      attrs: {
        "size": "lg",
        "variant": "link iq-bg-primary iq-border-radius-10",
        "toggle-class": "text-decoration-none",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function fn() {
          return [_c('span', {
            staticClass: "dropdown-toggle p-0",
            attrs: {
              "id": "dropdownMenuButton5",
              "data-toggle": "dropdown"
            }
          }, [_c('i', {
            staticClass: "fa fa-pencil-square-o m-0 text-primary"
          })])];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": "primary"
      }
    }, [_c('i', {
      staticClass: "fa fa-eye mr-2"
    }), _vm._v("Detail ")]), _c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": "primary"
      }
    }, [_c('i', {
      staticClass: "fa fa-pen mr-2"
    }), _vm._v("Edit ")]), _c('b-dropdown-divider'), _c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": "danger"
      }
    }, [_c('i', {
      staticClass: "fa fa-trash mr-2"
    }), _vm._v("Delete ")])], 1)], 1)]);
  }), 0)])])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "mt-3"
  }, [_c('b-pagination', {
    attrs: {
      "value": 1,
      "total-rows": 50,
      "align": "center"
    }
  })], 1)])], 1), _c('b-row', {
    staticClass: "p-3"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('h4', [_c('strong', {
    staticClass: "text-primary"
  }, [_vm._v("Voyage Matrix")])]), _c('hr'), _c('div', {
    staticClass: "d-flex overflow-auto"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_vm._l(_vm.matrix, function (head, headIndex) {
    return _c('th', {
      key: headIndex,
      staticClass: "text-center table-info text-truncate text-uppercase"
    }, [_vm._v(" " + _vm._s(head === null || head === void 0 ? void 0 : head.name) + " ")]);
  }), _c('th', {
    staticClass: "text-center table-info",
    staticStyle: {
      "width": "5px",
      "position": "sticky",
      "right": "0",
      "z-index": "1"
    }
  }, [_vm._v("#")])], 2)]), _c('tbody', [_c('tr', [_vm._l(_vm.matrix, function (body, bodyIndex) {
    var _body$durationFormat;
    return _c('td', {
      key: bodyIndex,
      staticClass: "text-center text-truncate"
    }, [_vm._v(" " + _vm._s((_body$durationFormat = body === null || body === void 0 ? void 0 : body.durationFormat) !== null && _body$durationFormat !== void 0 ? _body$durationFormat : '-') + " ")]);
  }), _c('td', {
    staticClass: "text-right table-info",
    staticStyle: {
      "width": "5px",
      "position": "sticky",
      "right": "0",
      "z-index": "1"
    }
  }, [_c('b-dropdown', {
    attrs: {
      "size": "lg",
      "variant": "link iq-bg-primary iq-border-radius-10",
      "toggle-class": "text-decoration-none",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('span', {
          staticClass: "dropdown-toggle p-0",
          attrs: {
            "id": "dropdownMenuButton5",
            "data-toggle": "dropdown"
          }
        }, [_c('i', {
          staticClass: "fa fa-pencil-square-o m-0 text-primary"
        })])];
      },
      proxy: true
    }], null, false, 1174777629)
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#",
      "variant": "primary"
    }
  }, [_c('i', {
    staticClass: "fa fa-eye mr-2"
  }), _vm._v("Detail ")]), _c('b-dropdown-item', {
    attrs: {
      "href": "#",
      "variant": "primary"
    }
  }, [_c('i', {
    staticClass: "fa fa-pen mr-2"
  }), _vm._v("Edit ")]), _c('b-dropdown-divider'), _c('b-dropdown-item', {
    attrs: {
      "href": "#",
      "variant": "danger"
    }
  }, [_c('i', {
    staticClass: "fa fa-trash mr-2"
  }), _vm._v("Delete ")])], 1)], 1)], 2)])])])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "mt-3"
  }, [_c('b-pagination', {
    attrs: {
      "value": 1,
      "total-rows": 50,
      "align": "center"
    }
  })], 1)])], 1)], 1)]) : _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "alert alert-info m-4"
  }, [_vm._v(" Loading... ")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }