<template>
<b-container fluid>
  <b-row>
    <b-col lg="12">
      <iq-card body-class="p-0">
        <template v-slot:body>
          <div class="iq-edit-list">
            <tab-nav :pills="true" class="iq-edit-profile d-flex">
              <tab-nav-items class="col-md-4 p-0" :active="true" href="#schedule" title="SCHEDULE" />
              <tab-nav-items class="col-md-4 p-0" :active="false" href="#progress" title="PROGRESS" />
              <tab-nav-items class="col-md-4 p-0" :active="false" href="#finish" title="FINISH" />
            </tab-nav>
          </div>
        </template>
      </iq-card>
    </b-col>
    <b-col lg="12">
      <div class="iq-edit-list-data">
        <div id="pills-tabContent-2" class="tab-content">
          <tab-content-item :active="true" id="schedule" >
            <DockingDashboardSchedule :on-global-refresh="onGlobalRefresh" :global-refresh="globalRefresh" />
          </tab-content-item>

          <tab-content-item :active="false" id="progress">
            <DockingDashboardProgress :on-global-refresh="onGlobalRefresh" :global-refresh="globalRefresh" />
          </tab-content-item>

          <tab-content-item :active="false" id="progress">
            <iq-card>
              <template v-slot:body>
                <b-collapse visible id="docking_progress_card" class="mb-2">

                  <!-- DOCKING PROGRESS -->
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th class="text-center">NO</th>
                      <th class="text-center">FLEET</th>
                      <th class="text-center">DOCKING TYPE</th>
                      <th class="text-center">SHIPYARD</th>
                      <th class="text-center">DOCKING DATE</th>
                      <th class="text-center">COMPLETE DATE</th>
                      <th class="text-center">JOB</th>
                      <th class="text-center">STATUS</th>
                      <th class="text-center">ACTION</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <th class="text-center">1</th>
                      <td class="text-center">TB. TCP 201</td>
                      <td class="text-center">INTERMEDIATE SURVEY</td>
                      <td class="text-center">PT. CAHAYA BAHARI SHIPYARD</td>
                      <td class="text-center">2017-05-10</td>
                      <td class="text-center">2022-06-12</td>
                      <td class="text-center">0/20</td>
                      <td class="text-center"><b-badge variant="warning d-block">NEED REVIEW</b-badge></td>
                      <td class="text-center">
                        <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                          <template v-slot:button-content>
                              <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                              </span>
                          </template>
                          <b-dropdown-item href="#" @click="slideReviewDocking = !slideReviewDocking;"  variant="warning"><i class="fa fa-search mr-2"></i>REVIEW</b-dropdown-item>
                          <b-dropdown-item href="#" variant="dark"><i class="fa fa-history mr-2"></i>HISTORY DOCKING</b-dropdown-item>
                        </b-dropdown>
                      </td>
                    </tr>
                    <tr>
                      <th class="text-center">2</th>
                      <td class="text-center">TB. SML 301</td>
                      <td class="text-center">SPECIAL SURVEY</td>
                      <td class="text-center">PT. CITRA SHIPYARD INDONESIA</td>
                      <td class="text-center">2018-03-25</td>
                      <td class="text-center">2018-04-30</td>
                      <td class="text-center">0/15</td>
                      <td class="text-center"><b-badge variant="primary d-block">NEED APPROVE</b-badge></td>
                      <td class="text-center">
                        <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                          <template v-slot:button-content>
                              <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                              </span>
                          </template>
                          <b-dropdown-item href="#" @click="slideApproveDocking = !slideApproveDocking;"  variant="success"><i class="fa fa-check mr-2"></i>APPROVE</b-dropdown-item>
                          <b-dropdown-item href="#" variant="dark"><i class="fa fa-history mr-2"></i>HISTORY DOCKING</b-dropdown-item>
                        </b-dropdown>
                      </td>
                    </tr>
                    <tr>
                      <th class="text-center">3</th>
                      <td class="text-center">TB. ETI 304</td>
                      <td class="text-center">INTERMEDIATE SURVEY</td>
                      <td class="text-center">PT. SARANA SHIPYARD BAHARI</td>
                      <td class="text-center">2020-01-15</td>
                      <td class="text-center">2020-02-20</td>
                      <td class="text-center">0/15</td>
                      <td class="text-center"><b-badge variant="info d-block">ON PROGRESS</b-badge></td>
                      <td class="text-center">
                        <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                          <template v-slot:button-content>
                              <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                              </span>
                          </template>
                          <b-dropdown-item @click="slideActivity = !slideActivity;" variant="info"><i class="fa fa-comments mr-2"></i>ACTIVITY</b-dropdown-item>
                          <b-dropdown-item @click="slideUpdateDocking = !slideUpdateDocking;" variant="primary"><i class="fa fa-plus-circle mr-2"></i>ADD JOB</b-dropdown-item>
                          <b-dropdown-item  variant="dark"><i class="fa fa-history mr-2"></i>HISTORY DOCKING</b-dropdown-item>
                        </b-dropdown>
                      </td>
                    </tr>
                    <tr class="bg-danger">
                      <th class="text-center">4</th>
                      <td class="text-center">TB. SML 201</td>
                      <td class="text-center">INTERMEDIATE SURVEY</td>
                      <td class="text-center">PT. SARANA SHIPYARD BAHARI</td>
                      <td class="text-center">2020-01-15</td>
                      <td class="text-center">2020-02-20</td>
                      <td class="text-center">0/15</td>
                      <td class="text-center"><b-badge variant="light d-block">REJECT</b-badge></td>
                      <td class="text-center">
                        <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                          <template v-slot:button-content>
                              <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                              </span>
                          </template>
                          <b-dropdown-item variant="danger" @click="slideUpdateDocking = !slideUpdateDocking;"><i class="fa fa-percent mr-2"></i>UPDATE DOCKING</b-dropdown-item>
                          <b-dropdown-item href="#" variant="dark"><i class="fa fa-history mr-2"></i>HISTORY DOCKING</b-dropdown-item>
                        </b-dropdown>
                      </td>
                    </tr>
                    <tr>
                      <th class="text-center">5</th>
                      <td class="text-center">TB. TCP 3301</td>
                      <td class="text-center">INTERMEDIATE SURVEY</td>
                      <td class="text-center">PT. CITRA SHIPYARD INDONESIA</td>
                      <td class="text-center">2018-03-25</td>
                      <td class="text-center">2018-04-30</td>
                      <td class="text-center">15/15</td>
                      <td class="text-center"><b-badge variant="success d-block">SEA TRIAL</b-badge></td>
                      <td class="text-center">
                        <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                          <template v-slot:button-content>
                              <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                              </span>
                          </template>
                          <b-dropdown-item href="#"  variant="success"><router-link to="sea-trial"><i class="fa fa-ship mr-2"></i>SEA TRIAL</router-link></b-dropdown-item>
                          <b-dropdown-item href="#" variant="dark"><i class="fa fa-history mr-2"></i>HISTORY DOCKING</b-dropdown-item>
                        </b-dropdown>
                      </td>
                    </tr>
                    <tr>
                      <th class="text-center">6</th>
                      <td class="text-center">TB. TCP 3301</td>
                      <td class="text-center">INTERMEDIATE SURVEY</td>
                      <td class="text-center">PT. CITRA SHIPYARD INDONESIA</td>
                      <td class="text-center">2018-03-25</td>
                      <td class="text-center">2018-04-30</td>
                      <td class="text-center">15/15</td>
                      <td class="text-center"><b-badge variant="primary d-block">SERAH TERIMA</b-badge></td>
                      <td class="text-center">
                        <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                          <template v-slot:button-content>
                              <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                              </span>
                          </template>
                          <b-dropdown-item href="#" variant="primary"><router-link to="serah-terima"><i class="fas fa-handshake mr-2"></i>SERAH TERIMA</router-link></b-dropdown-item>
                          <b-dropdown-item href="#" variant="dark"><i class="fa fa-history mr-2"></i>HISTORY DOCKING</b-dropdown-item>
                        </b-dropdown>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div class="mt-3">
                    <b-pagination :value="1" :total-rows="50" align="center" />
                  </div>
                  <!-- DOCKING PROGRESS END -->
                </b-collapse>

                <!-- SLIDE - FORM REVIEW DOCKING -->
                <b-sidebar
                  v-model="slideReviewDocking"
                  id="review_docking"
                  title="FORM REVIEW DOCKING - TB. SML 201"
                  width="90%"
                  backdrop-variant="dark"
                  bg-variant="white"
                  right
                  backdrop
                  shadow>
                  <template #default="{hide}">
                    <b-row class="m-3">
                      <b-col cols="12">
                        <fieldset class="form-group border p-3">
                          <legend class="w-auto px-2">Select Docking Job</legend>
                          <b-row>
                            <b-col md="10">
                              <b-form-input id="job_name" type="text" placeholder="Search Job Name"></b-form-input>
                            </b-col>
                            <b-col md="2">
                              <b-button variant="success" block>SEARCH</b-button>
                            </b-col>
                            <b-col md="4">
                              <v-jstree :data="tree" show-checkbox allow-batch whole-row :item-events="itemEvents" @item-click="itemClick" class="mt-3">
                                <template slot-scope="_">
                                  <div style="display: inherit; width: 200px" @click.ctrl="customItemClickWithCtrl">
                                    <i :class="_.vm.themeIconClasses" role="presentation" v-if="!_.model.loading"></i>
                                    {{_.model.text}}
                                  </div>
                                </template>
                              </v-jstree>
                            </b-col>
                            <b-col md="8">
                              <b-row>
                                <b-col md="12" class="mt-3">
                                  <b-button block variant="success" class="mt-3"><i class="fa fa-plus"></i> ADD DOCKING JOB</b-button>
                                  <table class="table table-striped mt-3">
                                    <tbody>
                                    <tr>
                                      <th width="20%">Arrival Shipyard</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">
                                        <b-form-input type="date" id="arrival_shipyard"></b-form-input>
                                      </td>
                                      <th width="20%">Up Slip</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%"><b-form-input type="date" id="upslip"></b-form-input></td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Waiting Dock Space</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">5 Hari</td>
                                      <th width="20%">Down Slip</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">
                                        <b-form-input type="date" id="downslip"></b-form-input>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Complete Date</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">
                                        <b-form-input type="date" id="delivery_date"></b-form-input>
                                      </td>
                                      <th width="20%">Budget</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Rp. 385.000.000,-</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Shipyard</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">PT. CAHAYA BAHARI SHIPYARD</td>
                                      <th width="20%">Real Badget</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">
                                        <div class="input-group">
                                          <div class="input-group-prepend">
                                            <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                          </div>
                                          <input type="number" class="form-control" id="real_budget" placeholder="324.000.000">
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th width="20%">PIC</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">NURUL AKBAR</td>
                                      <th width="20%">Document Shipyard</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">
                                        <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                        </ul>
                                      </td>
                                    </tr>
                                    </tbody>
                                  </table>
                                  <hr>
                                  <!-- JOB CATEGORY PERCENT -->
                                  <b-row>
                                    <b-col md="12">
                                      <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-primary rounded">
                                        <template v-slot:body >
                                          <div class="d-flex align-items-center justify-content-between">
                                            <div class="rounded-circle iq-card-icon bg-primary"><i class="fa fa-percent"></i></div>
                                            <div class="text-right">
                                              <h2 class="mb-0"><span class="counter">100%</span></h2>
                                              <h5 class="">Persentase Job Category</h5>
                                            </div>
                                          </div>
                                        </template>
                                      </iq-card>
                                    </b-col>
                                  </b-row>
                                  <!-- JOB CATEGORY PERCENT END -->

                                  <!-- DOCKING JOB -->
                                  <div class="accordion" role="tablist">
                                    <b-card no-body>
                                      <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-success">
                                        <template v-slot:body >
                                          <div class="d-flex align-items-center justify-content-between">
                                            <b-button variant="primary" class="mr-1" v-b-toggle.jobCat-1><i class="fa fa-chevron-circle-down"></i>Click, Job Detail</b-button>
                                            <h4 class="mb-0">DOCKING / UNDOCKING</h4>
                                            <div class="text-right">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">%</span>
                                                </div>
                                                <input type="number" class="form-control" id="real_budget" placeholder="30">
                                              </div>
                                            </div>
                                          </div>
                                        </template>
                                      </iq-card>

                                      <b-collapse id="jobCat-1" accordion="my-accordion" role="tabpanel">
                                        <b-card-body>
                                          <!-- BOBOT DOCKING JOB -->
                                          <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-info rounded">
                                            <template v-slot:body >
                                              <div class="d-flex align-items-center justify-content-between">
                                                <div class="rounded-circle iq-card-icon bg-info"><i class="fa fa-percent"></i></div>
                                                <div class="text-right">
                                                  <h2 class="mb-0"><span class="counter">100%</span></h2>
                                                  <h5 class="">Persentase Docking Job</h5>
                                                </div>
                                              </div>
                                            </template>
                                          </iq-card>
                                          <!-- BOBOT DOCKING JOB END -->
                                          <div class="accordion" role="tablist">
                                            <b-card no-body >
                                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                                <b-button block v-b-toggle.job-1-1 variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i> Up Slip <strong class="pull-right">BOBOT PEKERJAAN : 50%</strong></b-button>
                                              </b-card-header>
                                              <b-collapse id="job-1-1" accordion="my-accordion-sub" role="tabpanel">
                                                <b-form-row class="mt-3">
                                                  <b-col>
                                                    <b-form-group label="Start *" label-for="start">
                                                      <b-form-input type="date" id="start"></b-form-input>
                                                      <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                                    </b-form-group>
                                                  </b-col>
                                                  <b-col>
                                                    <b-form-group label="Est. Finish *" label-for="end">
                                                      <b-form-input type="date" id="end"></b-form-input>
                                                      <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <b-form-row>
                                                  <b-col>
                                                    <b-form-group label="Bobot Pekerjaan *" label-for="bobot_pekerjaan">
                                                      <div class="input-group">
                                                        <div class="input-group-prepend">
                                                          <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="bobot_pekerjaan">
                                                      </div>
                                                    </b-form-group>
                                                  </b-col>
                                                  <b-col>
                                                    <b-form-group label="Attachment Job" label-for="attachment_job">
                                                      <div class="custom-file">
                                                        <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                        <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                                      </div>
                                                      <small class="text-muted">
                                                        File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                        File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                                      </small>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <b-form-row>
                                                  <b-col>
                                                    <b-form-group label="Notes" label-for="notes">
                                                      <b-form-textarea id="description" rows="3" placeholder="Check water tubes/plates and clean/free from clogged."></b-form-textarea>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <b-form-row>
                                                  <b-col>
                                                    <b-form-group label="Internal Man Power" label-for="internal_man_power">
                                                      <template v-for="(item,index) in text">
                                                        <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                          <div class="custom-switch-inner">
                                                            <p class="mb-0"> {{ item.label }} </p>
                                                            <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                            <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                            </label>
                                                          </div>
                                                        </div>
                                                      </template>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <fieldset class="form-group border p-3">
                                                  <legend class="w-auto px-2">Internal Man Power</legend>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                                        <b-form-input type="text" id="nama_internal_man_power" placeholder="Nurul Akbar"></b-form-input>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="Jabatan *" label-for="jabatan">
                                                        <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                          <template v-slot:first>
                                                            <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                          </template>
                                                        </b-form-select>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                                        <b-form-input type="text" id="nama_internal_man_power" placeholder="Agung Tri Laksono"></b-form-input>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="Jabatan *" label-for="jabatan">
                                                        <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                          <template v-slot:first>
                                                            <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                          </template>
                                                        </b-form-select>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                  <b-button variant="success" class="mt-3"><i class="fa fa-users"></i> ADD INTERNAL MAN POWER</b-button>
                                                </fieldset>
                                                <b-form-row>
                                                  <b-col>
                                                    <b-form-group label="Sub Kontraktor" label-for="subkontraktor">
                                                      <template v-for="(item,index) in text">
                                                        <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                          <div class="custom-switch-inner">
                                                            <p class="mb-0"> {{ item.label }} </p>
                                                            <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                            <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                            </label>
                                                          </div>
                                                        </div>
                                                      </template>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <fieldset class="form-group border p-3">
                                                  <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                                        <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                                        <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Penawaran" label-for="penawaran">
                                                        <div class="input-group">
                                                          <div class="input-group-prepend">
                                                            <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                          </div>
                                                          <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                                        </div>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                                        <div class="input-group">
                                                          <div class="input-group-prepend">
                                                            <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                          </div>
                                                          <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                                        </div>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Notes" label-for="notes">
                                                        <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="Attachment Shipyard" label-for="doc_shipyard">
                                                        <div class="custom-file">
                                                          <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                          <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                                        </div>
                                                        <small class="text-muted">
                                                          File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                          File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                                        </small>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                </fieldset>
                                                <b-button variant="success" class="mb-3"><i class="fa fa-users"></i> ADD SUB KONTRAKTOR</b-button>
                                              </b-collapse>
                                            </b-card>
                                            <b-card no-body >
                                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                                <b-button block v-b-toggle.job-1-2 variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i> Down Slip <strong class="pull-right">BOBOT PEKERJAAN : 50%</strong></b-button>
                                              </b-card-header>
                                              <b-collapse id="job-1-2" accordion="my-accordion-sub" role="tabpanel">
                                                <b-form-row class="mt-3">
                                                  <b-col>
                                                    <b-form-group label="Start *" label-for="start">
                                                      <b-form-input type="date" id="start"></b-form-input>
                                                      <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                                    </b-form-group>
                                                  </b-col>
                                                  <b-col>
                                                    <b-form-group label="Est. Finish *" label-for="end">
                                                      <b-form-input type="date" id="end"></b-form-input>
                                                      <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <b-form-row>
                                                  <b-col>
                                                    <b-form-group label="Bobot Pekerjaan *" label-for="bobot_pekerjaan">
                                                      <div class="input-group">
                                                        <div class="input-group-prepend">
                                                          <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="bobot_pekerjaan">
                                                      </div>
                                                    </b-form-group>
                                                  </b-col>
                                                  <b-col>
                                                    <b-form-group label="Attachment Job" label-for="attachment_job">
                                                      <div class="custom-file">
                                                        <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                        <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                                      </div>
                                                      <small class="text-muted">
                                                        File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                        File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                                      </small>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <b-form-row>
                                                  <b-col>
                                                    <b-form-group label="Notes" label-for="notes">
                                                      <b-form-textarea id="description" rows="3" placeholder="Check water tubes/plates and clean/free from clogged."></b-form-textarea>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <b-form-row>
                                                  <b-col>
                                                    <b-form-group label="Internal Man Power" label-for="internal_man_power">
                                                      <template v-for="(item,index) in text">
                                                        <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                          <div class="custom-switch-inner">
                                                            <p class="mb-0"> {{ item.label }} </p>
                                                            <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                            <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                            </label>
                                                          </div>
                                                        </div>
                                                      </template>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <fieldset class="form-group border p-3">
                                                  <legend class="w-auto px-2">Internal Man Power</legend>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                                        <b-form-input type="text" id="nama_internal_man_power" placeholder="Nurul Akbar"></b-form-input>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="Jabatan *" label-for="jabatan">
                                                        <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                          <template v-slot:first>
                                                            <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                          </template>
                                                        </b-form-select>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                                        <b-form-input type="text" id="nama_internal_man_power" placeholder="Agung Tri Laksono"></b-form-input>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="Jabatan *" label-for="jabatan">
                                                        <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                          <template v-slot:first>
                                                            <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                          </template>
                                                        </b-form-select>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                  <b-button variant="success" class="mt-3"><i class="fa fa-users"></i> ADD INTERNAL MAN POWER</b-button>
                                                </fieldset>
                                                <b-form-row>
                                                  <b-col>
                                                    <b-form-group label="Sub Kontraktor" label-for="subkontraktor">
                                                      <template v-for="(item,index) in text">
                                                        <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                          <div class="custom-switch-inner">
                                                            <p class="mb-0"> {{ item.label }} </p>
                                                            <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                            <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                            </label>
                                                          </div>
                                                        </div>
                                                      </template>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <fieldset class="form-group border p-3">
                                                  <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                                        <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                                        <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Penawaran" label-for="penawaran">
                                                        <div class="input-group">
                                                          <div class="input-group-prepend">
                                                            <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                          </div>
                                                          <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                                        </div>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                                        <div class="input-group">
                                                          <div class="input-group-prepend">
                                                            <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                          </div>
                                                          <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                                        </div>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Notes" label-for="notes">
                                                        <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="Attachment Shipyard" label-for="doc_shipyard">
                                                        <div class="custom-file">
                                                          <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                          <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                                        </div>
                                                        <small class="text-muted">
                                                          File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                          File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                                        </small>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                </fieldset>
                                                <b-button variant="success" class="mb-3"><i class="fa fa-users"></i> ADD SUB KONTRAKTOR</b-button>
                                              </b-collapse>
                                            </b-card>
                                          </div>
                                        </b-card-body>
                                      </b-collapse>
                                    </b-card>
                                  </div>
                                  <!-- DOCKING JOB END -->
                                  <!-- DOCKING JOB -->
                                  <div class="accordion" role="tablist">
                                    <b-card no-body>
                                      <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-success">
                                        <template v-slot:body >
                                          <div class="d-flex align-items-center justify-content-between">
                                            <b-button variant="primary" class="mr-1" v-b-toggle.jobCat-2><i class="fa fa-chevron-circle-down"></i>Click, Job Detail</b-button>
                                            <h4 class="mb-0">SAND BLASTING</h4>
                                            <div class="text-right">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">%</span>
                                                </div>
                                                <input type="number" class="form-control" id="real_budget" placeholder="30">
                                              </div>
                                            </div>
                                          </div>
                                        </template>
                                      </iq-card>

                                      <b-collapse id="jobCat-2" accordion="my-accordion" role="tabpanel">
                                        <b-card-body>
                                          <!-- BOBOT DOCKING JOB -->
                                          <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-info rounded">
                                            <template v-slot:body >
                                              <div class="d-flex align-items-center justify-content-between">
                                                <div class="rounded-circle iq-card-icon bg-info"><i class="fa fa-percent"></i></div>
                                                <div class="text-right">
                                                  <h2 class="mb-0"><span class="counter">100%</span></h2>
                                                  <h5 class="">Persentase Docking Job</h5>
                                                </div>
                                              </div>
                                            </template>
                                          </iq-card>
                                          <!-- BOBOT DOCKING JOB END -->
                                          <div class="accordion" role="tablist">
                                            <b-card no-body >
                                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                                <b-button block v-b-toggle.job-2-1 variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i> Scraping teritip di lambung dan lunas<strong class="pull-right">BOBOT PEKERJAAN : 30%</strong></b-button>
                                              </b-card-header>
                                              <b-collapse id="job-2-1" accordion="my-accordion-sub" role="tabpanel">
                                                <b-form-row class="mt-3">
                                                  <b-col>
                                                    <b-form-group label="Start *" label-for="start">
                                                      <b-form-input type="date" id="start"></b-form-input>
                                                      <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                                    </b-form-group>
                                                  </b-col>
                                                  <b-col>
                                                    <b-form-group label="Est. Finish *" label-for="end">
                                                      <b-form-input type="date" id="end"></b-form-input>
                                                      <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <b-form-row>
                                                  <b-col>
                                                    <b-form-group label="Bobot Pekerjaan *" label-for="bobot_pekerjaan">
                                                      <div class="input-group">
                                                        <div class="input-group-prepend">
                                                          <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="bobot_pekerjaan">
                                                      </div>
                                                    </b-form-group>
                                                  </b-col>
                                                  <b-col>
                                                    <b-form-group label="Attachment Job" label-for="attachment_job">
                                                      <div class="custom-file">
                                                        <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                        <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                                      </div>
                                                      <small class="text-muted">
                                                        File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                        File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                                      </small>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <b-form-row>
                                                  <b-col>
                                                    <b-form-group label="Notes" label-for="notes">
                                                      <b-form-textarea id="description" rows="3" placeholder="Check water tubes/plates and clean/free from clogged."></b-form-textarea>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <b-form-row>
                                                  <b-col>
                                                    <b-form-group label="Internal Man Power" label-for="internal_man_power">
                                                      <template v-for="(item,index) in text">
                                                        <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                          <div class="custom-switch-inner">
                                                            <p class="mb-0"> {{ item.label }} </p>
                                                            <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                            <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                            </label>
                                                          </div>
                                                        </div>
                                                      </template>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <fieldset class="form-group border p-3">
                                                  <legend class="w-auto px-2">Internal Man Power</legend>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                                        <b-form-input type="text" id="nama_internal_man_power" placeholder="Nurul Akbar"></b-form-input>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="Jabatan *" label-for="jabatan">
                                                        <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                          <template v-slot:first>
                                                            <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                          </template>
                                                        </b-form-select>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                                        <b-form-input type="text" id="nama_internal_man_power" placeholder="Agung Tri Laksono"></b-form-input>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="Jabatan *" label-for="jabatan">
                                                        <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                          <template v-slot:first>
                                                            <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                          </template>
                                                        </b-form-select>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                  <b-button variant="success" class="mt-3"><i class="fa fa-users"></i> ADD INTERNAL MAN POWER</b-button>
                                                </fieldset>
                                                <b-form-row>
                                                  <b-col>
                                                    <b-form-group label="Sub Kontraktor" label-for="subkontraktor">
                                                      <template v-for="(item,index) in text">
                                                        <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                          <div class="custom-switch-inner">
                                                            <p class="mb-0"> {{ item.label }} </p>
                                                            <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                            <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                            </label>
                                                          </div>
                                                        </div>
                                                      </template>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <fieldset class="form-group border p-3">
                                                  <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                                        <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                                        <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Penawaran" label-for="penawaran">
                                                        <div class="input-group">
                                                          <div class="input-group-prepend">
                                                            <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                          </div>
                                                          <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                                        </div>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                                        <div class="input-group">
                                                          <div class="input-group-prepend">
                                                            <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                          </div>
                                                          <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                                        </div>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Notes" label-for="notes">
                                                        <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="Attachment Shipyard" label-for="doc_shipyard">
                                                        <div class="custom-file">
                                                          <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                          <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                                        </div>
                                                        <small class="text-muted">
                                                          File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                          File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                                        </small>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                </fieldset>
                                                <b-button variant="success" class="mb-3"><i class="fa fa-users"></i> ADD SUB KONTRAKTOR</b-button>
                                              </b-collapse>
                                            </b-card>
                                            <b-card no-body >
                                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                                <b-button block v-b-toggle.job-2-2 variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i> Pencucian lambung dengan air tawar<strong class="pull-right">BOBOT PEKERJAAN : 10%</strong></b-button>
                                              </b-card-header>
                                              <b-collapse id="job-2-2" accordion="my-accordion-sub" role="tabpanel">
                                                <b-form-row class="mt-3">
                                                  <b-col>
                                                    <b-form-group label="Start *" label-for="start">
                                                      <b-form-input type="date" id="start"></b-form-input>
                                                      <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                                    </b-form-group>
                                                  </b-col>
                                                  <b-col>
                                                    <b-form-group label="Est. Finish *" label-for="end">
                                                      <b-form-input type="date" id="end"></b-form-input>
                                                      <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <b-form-row>
                                                  <b-col>
                                                    <b-form-group label="Bobot Pekerjaan *" label-for="bobot_pekerjaan">
                                                      <div class="input-group">
                                                        <div class="input-group-prepend">
                                                          <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="bobot_pekerjaan">
                                                      </div>
                                                    </b-form-group>
                                                  </b-col>
                                                  <b-col>
                                                    <b-form-group label="Attachment Job" label-for="attachment_job">
                                                      <div class="custom-file">
                                                        <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                        <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                                      </div>
                                                      <small class="text-muted">
                                                        File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                        File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                                      </small>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <b-form-row>
                                                  <b-col>
                                                    <b-form-group label="Notes" label-for="notes">
                                                      <b-form-textarea id="description" rows="3" placeholder="Check water tubes/plates and clean/free from clogged."></b-form-textarea>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <b-form-row>
                                                  <b-col>
                                                    <b-form-group label="Internal Man Power" label-for="internal_man_power">
                                                      <template v-for="(item,index) in text">
                                                        <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                          <div class="custom-switch-inner">
                                                            <p class="mb-0"> {{ item.label }} </p>
                                                            <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                            <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                            </label>
                                                          </div>
                                                        </div>
                                                      </template>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <fieldset class="form-group border p-3">
                                                  <legend class="w-auto px-2">Internal Man Power</legend>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                                        <b-form-input type="text" id="nama_internal_man_power" placeholder="Nurul Akbar"></b-form-input>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="Jabatan *" label-for="jabatan">
                                                        <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                          <template v-slot:first>
                                                            <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                          </template>
                                                        </b-form-select>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                                        <b-form-input type="text" id="nama_internal_man_power" placeholder="Agung Tri Laksono"></b-form-input>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="Jabatan *" label-for="jabatan">
                                                        <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                          <template v-slot:first>
                                                            <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                          </template>
                                                        </b-form-select>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                  <b-button variant="success" class="mt-3"><i class="fa fa-users"></i> ADD INTERNAL MAN POWER</b-button>
                                                </fieldset>
                                                <b-form-row>
                                                  <b-col>
                                                    <b-form-group label="Sub Kontraktor" label-for="subkontraktor">
                                                      <template v-for="(item,index) in text">
                                                        <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                          <div class="custom-switch-inner">
                                                            <p class="mb-0"> {{ item.label }} </p>
                                                            <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                            <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                            </label>
                                                          </div>
                                                        </div>
                                                      </template>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <fieldset class="form-group border p-3">
                                                  <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                                        <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                                        <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Penawaran" label-for="penawaran">
                                                        <div class="input-group">
                                                          <div class="input-group-prepend">
                                                            <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                          </div>
                                                          <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                                        </div>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                                        <div class="input-group">
                                                          <div class="input-group-prepend">
                                                            <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                          </div>
                                                          <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                                        </div>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Notes" label-for="notes">
                                                        <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="Attachment Shipyard" label-for="doc_shipyard">
                                                        <div class="custom-file">
                                                          <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                          <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                                        </div>
                                                        <small class="text-muted">
                                                          File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                          File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                                        </small>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                </fieldset>
                                                <b-button variant="success" class="mb-3"><i class="fa fa-users"></i> ADD SUB KONTRAKTOR</b-button>
                                              </b-collapse>
                                            </b-card>
                                            <b-card no-body >
                                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                                <b-button block v-b-toggle.job-2-3 variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i> Sand blasting lunas<strong class="pull-right">BOBOT PEKERJAAN : 10%</strong></b-button>
                                              </b-card-header>
                                              <b-collapse id="job-2-3" accordion="my-accordion-sub" role="tabpanel">
                                                <b-form-row class="mt-3">
                                                  <b-col>
                                                    <b-form-group label="Start *" label-for="start">
                                                      <b-form-input type="date" id="start"></b-form-input>
                                                      <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                                    </b-form-group>
                                                  </b-col>
                                                  <b-col>
                                                    <b-form-group label="Est. Finish *" label-for="end">
                                                      <b-form-input type="date" id="end"></b-form-input>
                                                      <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <b-form-row>
                                                  <b-col>
                                                    <b-form-group label="Bobot Pekerjaan *" label-for="bobot_pekerjaan">
                                                      <div class="input-group">
                                                        <div class="input-group-prepend">
                                                          <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="bobot_pekerjaan">
                                                      </div>
                                                    </b-form-group>
                                                  </b-col>
                                                  <b-col>
                                                    <b-form-group label="Attachment Job" label-for="attachment_job">
                                                      <div class="custom-file">
                                                        <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                        <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                                      </div>
                                                      <small class="text-muted">
                                                        File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                        File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                                      </small>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <b-form-row>
                                                  <b-col>
                                                    <b-form-group label="Notes" label-for="notes">
                                                      <b-form-textarea id="description" rows="3" placeholder="Check water tubes/plates and clean/free from clogged."></b-form-textarea>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <b-form-row>
                                                  <b-col>
                                                    <b-form-group label="Internal Man Power" label-for="internal_man_power">
                                                      <template v-for="(item,index) in text">
                                                        <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                          <div class="custom-switch-inner">
                                                            <p class="mb-0"> {{ item.label }} </p>
                                                            <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                            <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                            </label>
                                                          </div>
                                                        </div>
                                                      </template>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <fieldset class="form-group border p-3">
                                                  <legend class="w-auto px-2">Internal Man Power</legend>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                                        <b-form-input type="text" id="nama_internal_man_power" placeholder="Nurul Akbar"></b-form-input>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="Jabatan *" label-for="jabatan">
                                                        <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                          <template v-slot:first>
                                                            <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                          </template>
                                                        </b-form-select>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                                        <b-form-input type="text" id="nama_internal_man_power" placeholder="Agung Tri Laksono"></b-form-input>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="Jabatan *" label-for="jabatan">
                                                        <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                          <template v-slot:first>
                                                            <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                          </template>
                                                        </b-form-select>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                  <b-button variant="success" class="mt-3"><i class="fa fa-users"></i> ADD INTERNAL MAN POWER</b-button>
                                                </fieldset>
                                                <b-form-row>
                                                  <b-col>
                                                    <b-form-group label="Sub Kontraktor" label-for="subkontraktor">
                                                      <template v-for="(item,index) in text">
                                                        <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                          <div class="custom-switch-inner">
                                                            <p class="mb-0"> {{ item.label }} </p>
                                                            <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                            <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                            </label>
                                                          </div>
                                                        </div>
                                                      </template>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <fieldset class="form-group border p-3">
                                                  <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                                        <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                                        <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Penawaran" label-for="penawaran">
                                                        <div class="input-group">
                                                          <div class="input-group-prepend">
                                                            <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                          </div>
                                                          <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                                        </div>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                                        <div class="input-group">
                                                          <div class="input-group-prepend">
                                                            <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                          </div>
                                                          <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                                        </div>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Notes" label-for="notes">
                                                        <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="Attachment Shipyard" label-for="doc_shipyard">
                                                        <div class="custom-file">
                                                          <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                          <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                                        </div>
                                                        <small class="text-muted">
                                                          File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                          File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                                        </small>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                </fieldset>
                                                <b-button variant="success" class="mb-3"><i class="fa fa-users"></i> ADD SUB KONTRAKTOR</b-button>
                                              </b-collapse>
                                            </b-card>
                                            <b-card no-body >
                                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                                <b-button block v-b-toggle.job-2-4 variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i> Sand blasting deck dan bulwark<strong class="pull-right">BOBOT PEKERJAAN : 10%</strong></b-button>
                                              </b-card-header>
                                              <b-collapse id="job-2-4" accordion="my-accordion-sub" role="tabpanel">
                                                <b-form-row class="mt-3">
                                                  <b-col>
                                                    <b-form-group label="Start *" label-for="start">
                                                      <b-form-input type="date" id="start"></b-form-input>
                                                      <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                                    </b-form-group>
                                                  </b-col>
                                                  <b-col>
                                                    <b-form-group label="Est. Finish *" label-for="end">
                                                      <b-form-input type="date" id="end"></b-form-input>
                                                      <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <b-form-row>
                                                  <b-col>
                                                    <b-form-group label="Bobot Pekerjaan *" label-for="bobot_pekerjaan">
                                                      <div class="input-group">
                                                        <div class="input-group-prepend">
                                                          <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="bobot_pekerjaan">
                                                      </div>
                                                    </b-form-group>
                                                  </b-col>
                                                  <b-col>
                                                    <b-form-group label="Attachment Job" label-for="attachment_job">
                                                      <div class="custom-file">
                                                        <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                        <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                                      </div>
                                                      <small class="text-muted">
                                                        File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                        File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                                      </small>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <b-form-row>
                                                  <b-col>
                                                    <b-form-group label="Notes" label-for="notes">
                                                      <b-form-textarea id="description" rows="3" placeholder="Check water tubes/plates and clean/free from clogged."></b-form-textarea>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <b-form-row>
                                                  <b-col>
                                                    <b-form-group label="Internal Man Power" label-for="internal_man_power">
                                                      <template v-for="(item,index) in text">
                                                        <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                          <div class="custom-switch-inner">
                                                            <p class="mb-0"> {{ item.label }} </p>
                                                            <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                            <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                            </label>
                                                          </div>
                                                        </div>
                                                      </template>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <fieldset class="form-group border p-3">
                                                  <legend class="w-auto px-2">Internal Man Power</legend>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                                        <b-form-input type="text" id="nama_internal_man_power" placeholder="Nurul Akbar"></b-form-input>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="Jabatan *" label-for="jabatan">
                                                        <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                          <template v-slot:first>
                                                            <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                          </template>
                                                        </b-form-select>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                                        <b-form-input type="text" id="nama_internal_man_power" placeholder="Agung Tri Laksono"></b-form-input>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="Jabatan *" label-for="jabatan">
                                                        <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                          <template v-slot:first>
                                                            <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                          </template>
                                                        </b-form-select>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                  <b-button variant="success" class="mt-3"><i class="fa fa-users"></i> ADD INTERNAL MAN POWER</b-button>
                                                </fieldset>
                                                <b-form-row>
                                                  <b-col>
                                                    <b-form-group label="Sub Kontraktor" label-for="subkontraktor">
                                                      <template v-for="(item,index) in text">
                                                        <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                          <div class="custom-switch-inner">
                                                            <p class="mb-0"> {{ item.label }} </p>
                                                            <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                            <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                            </label>
                                                          </div>
                                                        </div>
                                                      </template>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <fieldset class="form-group border p-3">
                                                  <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                                        <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                                        <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Penawaran" label-for="penawaran">
                                                        <div class="input-group">
                                                          <div class="input-group-prepend">
                                                            <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                          </div>
                                                          <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                                        </div>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                                        <div class="input-group">
                                                          <div class="input-group-prepend">
                                                            <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                          </div>
                                                          <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                                        </div>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Notes" label-for="notes">
                                                        <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="Attachment Shipyard" label-for="doc_shipyard">
                                                        <div class="custom-file">
                                                          <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                          <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                                        </div>
                                                        <small class="text-muted">
                                                          File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                          File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                                        </small>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                </fieldset>
                                                <b-button variant="success" class="mb-3"><i class="fa fa-users"></i> ADD SUB KONTRAKTOR</b-button>
                                              </b-collapse>
                                            </b-card>
                                          </div>
                                        </b-card-body>
                                      </b-collapse>
                                    </b-card>
                                  </div>
                                  <!-- DOCKING JOB END -->
                                  <!-- DOCKING JOB -->
                                  <div class="accordion-jobCat-3" role="tablist">
                                    <b-card no-body>
                                      <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-success">
                                        <template v-slot:body >
                                          <div class="d-flex align-items-center justify-content-between">
                                            <b-button variant="primary" class="mr-1" v-b-toggle.jobCat-3><i class="fa fa-chevron-circle-down"></i>Click, Job Detail</b-button>
                                            <h4 class="mb-0">MACHINERY EQUIPMENT</h4>
                                            <div class="text-right">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">%</span>
                                                </div>
                                                <input type="number" class="form-control" id="real_budget" placeholder="30">
                                              </div>
                                            </div>
                                          </div>
                                        </template>
                                      </iq-card>

                                      <b-collapse id="jobCat-3" accordion="my-accordion-jobCat-3" role="tabpanel">
                                        <b-card-body>
                                          <!-- BOBOT DOCKING JOB -->
                                          <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-info rounded">
                                            <template v-slot:body >
                                              <div class="d-flex align-items-center justify-content-between">
                                                <div class="rounded-circle iq-card-icon bg-info"><i class="fa fa-percent"></i></div>
                                                <div class="text-right">
                                                  <h2 class="mb-0"><span class="counter">100%</span></h2>
                                                  <h5 class="">Persentase Docking Job</h5>
                                                </div>
                                              </div>
                                            </template>
                                          </iq-card>
                                          <!-- BOBOT DOCKING JOB END -->
                                          <div class="accordion" role="tablist">
                                            <b-card no-body >
                                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                                <b-button block v-b-toggle.job-3-1 variant="success" class="text-left"><i class="fa fa-chevron-circle-down"></i> General Overhaul ME (P/S)<strong class="pull-right">BOBOT PEKERJAAN : 30%</strong></b-button>
                                              </b-card-header>
                                              <b-collapse id="job-3-1" accordion="my-accordion-sub" role="tabpanel">
                                                <table class="table table-striped mt-2">
                                                  <tbody>
                                                  <tr>
                                                    <th width="20%">Start Date</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">2022-06-10</td>
                                                    <th width="20%">Est. Finish</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">2022-06-15</td>
                                                  </tr>
                                                  <tr>
                                                    <th width="20%">Bobot Pekerjaan</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                      <div class="input-group">
                                                        <div class="input-group-prepend">
                                                          <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="bobot_pekerjaan">
                                                      </div>
                                                    </td>
                                                    <th width="20%">Dokumen</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                      <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                      </ul>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <th width="20%">Created By</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Nurul Akbar</td>
                                                    <th width="20%">Review By</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Agung Tri Laksono</td>
                                                  </tr>
                                                  <tr>
                                                    <th class="align-top">Notes</th>
                                                    <td class="text-center align-top">:</td>
                                                    <td colspan="4">
                                                      Check water tubes/plates and clean/free from clogged.<br>
                                                      Check  from any corrosion <br>
                                                      Check  zink anodes,  replace when necessary<br>
                                                      Check  leakage after assembly
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <th class="align-top">Internal Man Power</th>
                                                    <td class="text-center align-top">:</td>
                                                    <td colspan="4">
                                                      <h5 class="mt-1"><b-badge variant="info">Agung Tri Laksono - Port Captain</b-badge></h5>
                                                      <h5 class="mt-1"><b-badge variant="info">Nurul Akbar - Port Engineer</b-badge></h5>
                                                      <h5 class="mt-1"><b-badge variant="info">Tri Leksono - Electrical Engineer</b-badge></h5>
                                                    </td>
                                                  </tr>
                                                  </tbody>
                                                </table>
                                                <table class="table table-striped mt-2">
                                                  <tbody>
                                                  <fieldset class="form-group border p-3">
                                                    <legend class="w-auto px-2">Kontraktor 1</legend>
                                                    <tr>
                                                      <th width="20%">Nama Kontraktor</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">PT. Tavia Digital Solusi</td>
                                                      <th width="20%">Executive Engineer</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Agung Tri Laksono</td>
                                                    </tr>
                                                    <tr>
                                                      <th width="20%">Penawaran</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Rp.300.000.000,-</td>
                                                      <th width="20%">Penawaran Final</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Rp. 28.000.000,-</td>
                                                    </tr>
                                                    <tr>
                                                      <th width="20%">Note</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Note Here</td>
                                                      <th width="20%">Attachment</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">
                                                        <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        </ul>
                                                      </td>
                                                    </tr>
                                                  </fieldset>
                                                  </tbody>
                                                </table>
                                                <b-alert :show="true" variant="success">
                                                  <div class="iq-alert-icon">
                                                    <i class="fa fa-thumbs-up"></i>
                                                  </div>
                                                  <div class="iq-alert-text">General Overhaul ME (P/S) <b>APPROVED</b></div>
                                                </b-alert>
                                              </b-collapse>
                                            </b-card>
                                            <b-card no-body >
                                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                                <b-button block v-b-toggle.job-3-2 variant="success" class="text-left"><i class="fa fa-chevron-circle-down"></i> Kalibrasi injector (P/S)<strong class="pull-right">BOBOT PEKERJAAN : 10%</strong></b-button>
                                              </b-card-header>
                                              <b-collapse id="job-3-2" accordion="my-accordion-sub" role="tabpanel">
                                                <table class="table table-striped mt-2">
                                                  <tbody>
                                                  <tr>
                                                    <th width="20%">Start Date</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">2022-06-10</td>
                                                    <th width="20%">Est. Finish</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">2022-06-15</td>
                                                  </tr>
                                                  <tr>
                                                    <th width="20%">Bobot Pekerjaan</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                      <div class="input-group">
                                                        <div class="input-group-prepend">
                                                          <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="bobot_pekerjaan">
                                                      </div>
                                                    </td>
                                                    <th width="20%">Dokumen</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                      <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                      </ul>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <th width="20%">Created By</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Nurul Akbar</td>
                                                    <th width="20%">Review By</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Agung Tri Laksono</td>
                                                  </tr>
                                                  <tr>
                                                    <th class="align-top">Notes</th>
                                                    <td class="text-center align-top">:</td>
                                                    <td colspan="4">
                                                      Check water tubes/plates and clean/free from clogged.<br>
                                                      Check  from any corrosion <br>
                                                      Check  zink anodes,  replace when necessary<br>
                                                      Check  leakage after assembly
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <th class="align-top">Internal Man Power</th>
                                                    <td class="text-center align-top">:</td>
                                                    <td colspan="4">
                                                      <h5 class="mt-1"><b-badge variant="info">Agung Tri Laksono - Port Captain</b-badge></h5>
                                                      <h5 class="mt-1"><b-badge variant="info">Nurul Akbar - Port Engineer</b-badge></h5>
                                                      <h5 class="mt-1"><b-badge variant="info">Tri Leksono - Electrical Engineer</b-badge></h5>
                                                    </td>
                                                  </tr>
                                                  </tbody>
                                                </table>
                                                <b-alert :show="true" variant="success">
                                                  <div class="iq-alert-icon">
                                                    <i class="fa fa-thumbs-up"></i>
                                                  </div>
                                                  <div class="iq-alert-text">General Overhaul ME (P/S) <b>APPROVED</b></div>
                                                </b-alert>
                                              </b-collapse>
                                            </b-card>
                                            <b-card no-body >
                                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                                <b-button block v-b-toggle.job-3-3 variant="success" class="text-left"><i class="fa fa-chevron-circle-down"></i> Service/ganti baru dynamo starter (P/S)<strong class="pull-right">BOBOT PEKERJAAN : 10%</strong></b-button>
                                              </b-card-header>
                                              <b-collapse id="job-3-3" accordion="my-accordion-sub" role="tabpanel">
                                                <table class="table table-striped mt-2">
                                                  <tbody>
                                                  <tr>
                                                    <th width="20%">Start Date</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">2022-06-10</td>
                                                    <th width="20%">Est. Finish</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">2022-06-15</td>
                                                  </tr>
                                                  <tr>
                                                    <th width="20%">Bobot Pekerjaan</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                      <div class="input-group">
                                                        <div class="input-group-prepend">
                                                          <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="bobot_pekerjaan">
                                                      </div>
                                                    </td>
                                                    <th width="20%">Dokumen</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                      <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                      </ul>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <th width="20%">Created By</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Nurul Akbar</td>
                                                    <th width="20%">Review By</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Agung Tri Laksono</td>
                                                  </tr>
                                                  <tr>
                                                    <th class="align-top">Notes</th>
                                                    <td class="text-center align-top">:</td>
                                                    <td colspan="4">
                                                      Check water tubes/plates and clean/free from clogged.<br>
                                                      Check  from any corrosion <br>
                                                      Check  zink anodes,  replace when necessary<br>
                                                      Check  leakage after assembly
                                                    </td>
                                                  </tr>
                                                  </tbody>
                                                </table>
                                                <table class="table table-striped mt-2">
                                                  <tbody>
                                                  <fieldset class="form-group border p-3">
                                                    <legend class="w-auto px-2">Kontraktor 1</legend>
                                                    <tr>
                                                      <th width="20%">Nama Kontraktor</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">PT. Tavia Digital Solusi</td>
                                                      <th width="20%">Executive Engineer</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Agung Tri Laksono</td>
                                                    </tr>
                                                    <tr>
                                                      <th width="20%">Penawaran</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Rp.300.000.000,-</td>
                                                      <th width="20%">Penawaran Final</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Rp. 28.000.000,-</td>
                                                    </tr>
                                                    <tr>
                                                      <th width="20%">Note</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Note Here</td>
                                                      <th width="20%">Attachment</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">
                                                        <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        </ul>
                                                      </td>
                                                    </tr>
                                                  </fieldset>
                                                  </tbody>
                                                </table>
                                                <b-alert :show="true" variant="success">
                                                  <div class="iq-alert-icon">
                                                    <i class="fa fa-thumbs-up"></i>
                                                  </div>
                                                  <div class="iq-alert-text">General Overhaul ME (P/S) <b>APPROVED</b></div>
                                                </b-alert>
                                              </b-collapse>
                                            </b-card>
                                            <b-card no-body >
                                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                                <b-button block v-b-toggle.job-3-4 variant="success" class="text-left"><i class="fa fa-chevron-circle-down"></i> Service/ganti baru alternator (P/S)<strong class="pull-right">BOBOT PEKERJAAN : 10%</strong></b-button>
                                              </b-card-header>
                                              <b-collapse id="job-3-4" accordion="my-accordion-sub" role="tabpanel">
                                                <table class="table table-striped mt-2">
                                                  <tbody>
                                                  <tr>
                                                    <th width="20%">Start Date</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">2022-06-10</td>
                                                    <th width="20%">Est. Finish</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">2022-06-15</td>
                                                  </tr>
                                                  <tr>
                                                    <th width="20%">Bobot Pekerjaan</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                      <div class="input-group">
                                                        <div class="input-group-prepend">
                                                          <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="bobot_pekerjaan">
                                                      </div>
                                                    </td>
                                                    <th width="20%">Dokumen</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                      <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                      </ul>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <th width="20%">Created By</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Nurul Akbar</td>
                                                    <th width="20%">Review By</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Agung Tri Laksono</td>
                                                  </tr>
                                                  <tr>
                                                    <th class="align-top">Notes</th>
                                                    <td class="text-center align-top">:</td>
                                                    <td colspan="4">
                                                      Check water tubes/plates and clean/free from clogged.<br>
                                                      Check  from any corrosion <br>
                                                      Check  zink anodes,  replace when necessary<br>
                                                      Check  leakage after assembly
                                                    </td>
                                                  </tr>
                                                  </tbody>
                                                </table>
                                                <table class="table table-striped mt-2">
                                                  <tbody>
                                                  <fieldset class="form-group border p-3">
                                                    <legend class="w-auto px-2">Kontraktor 1</legend>
                                                    <tr>
                                                      <th width="20%">Nama Kontraktor</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">PT. Tavia Digital Solusi</td>
                                                      <th width="20%">Executive Engineer</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Agung Tri Laksono</td>
                                                    </tr>
                                                    <tr>
                                                      <th width="20%">Penawaran</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Rp.300.000.000,-</td>
                                                      <th width="20%">Penawaran Final</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Rp. 28.000.000,-</td>
                                                    </tr>
                                                    <tr>
                                                      <th width="20%">Note</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Note Here</td>
                                                      <th width="20%">Attachment</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">
                                                        <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        </ul>
                                                      </td>
                                                    </tr>
                                                  </fieldset>
                                                  </tbody>
                                                </table>
                                                <b-alert :show="true" variant="success">
                                                  <div class="iq-alert-icon">
                                                    <i class="fa fa-thumbs-up"></i>
                                                  </div>
                                                  <div class="iq-alert-text">General Overhaul ME (P/S) <b>APPROVED</b></div>
                                                </b-alert>
                                              </b-collapse>
                                            </b-card>
                                          </div>
                                        </b-card-body>
                                      </b-collapse>
                                    </b-card>
                                  </div>
                                  <!-- DOCKING JOB END -->

                                </b-col>
                                <!-- BUTTON SAVE - RESET -->
                                <b-col md="12" class="mt-3">
                                  <b-card-text class="pull-right"><small class="text-muted">Sign * : <b-badge variant="warning">Mandatory (Must be Input)</b-badge></small></b-card-text>
                                  <br><br>
                                  <b-button type="submit" variant="success" class="pull-right mr-1"><i class="fa fa-save"></i> Review</b-button>
                                </b-col>
                                <!-- BUTTON SAVE - RESET END -->
                              </b-row>

                            </b-col>
                          </b-row>
                        </fieldset>
                      </b-col>
                    </b-row>
                  </template>
                </b-sidebar>
                <!-- SLIDE - FORM REVIEW DOCKING END -->

                <!-- SLIDE - FORM UPDATE DOCKING -->
                <b-sidebar
                  v-model="slideUpdateDocking"
                  id="update_docking"
                  title="FORM UPDATE DOCKING - TB. SML 201"
                  width="90%"
                  backdrop-variant="dark"
                  bg-variant="white"
                  right
                  backdrop
                  shadow>
                  <template #default="{hide}">
                    <b-row class="m-3">
                      <b-col cols="12">
                        <fieldset class="form-group border p-3">
                          <legend class="w-auto px-2">Select Docking Job</legend>
                          <b-row>
                            <b-col md="10">
                              <b-form-input id="job_name" type="text" placeholder="Search Job Name"></b-form-input>
                            </b-col>
                            <b-col md="2">
                              <b-button variant="success" block>SEARCH</b-button>
                            </b-col>
                            <b-col md="4">
                              <v-jstree :data="tree" show-checkbox allow-batch whole-row :item-events="itemEvents" @item-click="itemClick" class="mt-3">
                                <template slot-scope="_">
                                  <div style="display: inherit; width: 200px" @click.ctrl="customItemClickWithCtrl">
                                    <i :class="_.vm.themeIconClasses" role="presentation" v-if="!_.model.loading"></i>
                                    {{_.model.text}}
                                  </div>
                                </template>
                              </v-jstree>
                            </b-col>
                            <b-col md="8">

                              <b-row>
                                <b-col md="12" class="mt-3">
                                  <b-button block variant="success" class="mt-3"><i class="fa fa-plus"></i> ADD DOCKING JOB</b-button>
                                  <table class="table table-striped mt-3">
                                    <tbody>
                                    <tr>
                                      <th width="20%">Arrival Shipyard</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">2022-06-10</td>
                                      <th width="20%">Up Slip</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">2022-06-15</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Waiting Dock Space</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">5 Hari</td>
                                      <th width="20%">Down Slip</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">2022-06-15</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Complete Date</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">2022-06-10</td>
                                      <th width="20%">Budget</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Rp. 385.000.000,-</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">Shipyard</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">PT. CAHAYA BAHARI SHIPYARD</td>
                                      <th width="20%">Real Badget</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">Rp. 350.000.000,-</td>
                                    </tr>
                                    <tr>
                                      <th width="20%">PIC</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">NURUL AKBAR</td>
                                      <th width="20%">Document Shipyard</th>
                                      <td width="5%" class="text-center">:</td>
                                      <td width="25%">
                                        <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                        </ul>
                                      </td>
                                    </tr>
                                    </tbody>
                                  </table>
                                  <hr>
                                  <!-- JOB CATEGORY PERCENT -->
                                  <b-row>
                                    <b-col md="12">
                                      <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-primary rounded">
                                        <template v-slot:body >
                                          <div class="d-flex align-items-center justify-content-between">
                                            <div class="rounded-circle iq-card-icon bg-primary"><i class="fa fa-percent"></i></div>
                                            <div class="text-right">
                                              <h2 class="mb-0"><span class="counter">100%</span></h2>
                                              <h5 class="">Persentase Job Category</h5>
                                            </div>
                                          </div>
                                        </template>
                                      </iq-card>
                                    </b-col>
                                  </b-row>
                                  <!-- JOB CATEGORY PERCENT END -->

                                  <!-- DOCKING JOB -->
                                  <div class="accordion" role="tablist">
                                    <b-card no-body>
                                      <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-success">
                                        <template v-slot:body >
                                          <div class="d-flex align-items-center justify-content-between">
                                            <b-button variant="primary" class="mr-1" v-b-toggle.jobCat-1><i class="fa fa-chevron-circle-down"></i>Click, Job Detail</b-button>
                                            <h4 class="mb-0">DOCKING / UNDOCKING</h4>
                                            <div class="text-right">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">%</span>
                                                </div>
                                                <input type="number" class="form-control" id="real_budget" placeholder="30">
                                              </div>
                                            </div>
                                          </div>
                                        </template>
                                      </iq-card>

                                      <b-collapse id="jobCat-1" accordion="my-accordion" role="tabpanel">
                                        <b-card-body>
                                          <!-- BOBOT DOCKING JOB -->
                                          <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-info rounded">
                                            <template v-slot:body >
                                              <div class="d-flex align-items-center justify-content-between">
                                                <div class="rounded-circle iq-card-icon bg-info"><i class="fa fa-percent"></i></div>
                                                <div class="text-right">
                                                  <h2 class="mb-0"><span class="counter">100%</span></h2>
                                                  <h5 class="">Persentase Docking Job</h5>
                                                </div>
                                              </div>
                                            </template>
                                          </iq-card>
                                          <!-- BOBOT DOCKING JOB END -->
                                          <div class="accordion" role="tablist">
                                            <b-card no-body >
                                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                                <b-button block v-b-toggle.job-1-1 variant="success" class="text-left"><i class="fa fa-chevron-circle-down"></i> Up Slip <strong class="pull-right">BOBOT PEKERJAAN : 50%</strong></b-button>
                                              </b-card-header>
                                              <b-collapse id="job-1-1" accordion="my-accordion-sub" role="tabpanel">
                                                <table class="table table-striped mt-2">
                                                  <tbody>
                                                  <tr>
                                                    <th width="20%">Start Date</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">2022-06-10</td>
                                                    <th width="20%">Est. Finish</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">2022-06-15</td>
                                                  </tr>
                                                  <tr>
                                                    <th width="20%">Bobot Pekerjaan</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                      <div class="input-group">
                                                        <div class="input-group-prepend">
                                                          <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="bobot_pekerjaan">
                                                      </div>
                                                    </td>
                                                    <th width="20%">Dokumen</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                      <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                      </ul>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <th width="20%">Created By</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Nurul Akbar</td>
                                                    <th width="20%">Review By</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Agung Tri Laksono</td>
                                                  </tr>
                                                  <tr>
                                                    <th class="align-top">Notes</th>
                                                    <td class="text-center align-top">:</td>
                                                    <td colspan="4">
                                                      Check water tubes/plates and clean/free from clogged.<br>
                                                      Check  from any corrosion <br>
                                                      Check  zink anodes,  replace when necessary<br>
                                                      Check  leakage after assembly
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <th class="align-top">Internal Man Power</th>
                                                    <td class="text-center align-top">:</td>
                                                    <td colspan="4">
                                                      <h5 class="mt-1"><b-badge variant="info">Agung Tri Laksono - Port Captain</b-badge></h5>
                                                      <h5 class="mt-1"><b-badge variant="info">Nurul Akbar - Port Engineer</b-badge></h5>
                                                      <h5 class="mt-1"><b-badge variant="info">Tri Leksono - Electrical Engineer</b-badge></h5>
                                                    </td>
                                                  </tr>
                                                  </tbody>
                                                </table>
                                                <table class="table table-striped mt-2">
                                                  <tbody>
                                                  <fieldset class="form-group border p-3">
                                                    <legend class="w-auto px-2">Kontraktor 1</legend>
                                                    <tr>
                                                      <th width="20%">Nama Kontraktor</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">PT. Tavia Digital Solusi</td>
                                                      <th width="20%">Executive Engineer</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Agung Tri Laksono</td>
                                                    </tr>
                                                    <tr>
                                                      <th width="20%">Penawaran</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Rp.300.000.000,-</td>
                                                      <th width="20%">Penawaran Final</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Rp. 28.000.000,-</td>
                                                    </tr>
                                                    <tr>
                                                      <th width="20%">Note</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Note Here</td>
                                                      <th width="20%">Attachment</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">
                                                        <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        </ul>
                                                      </td>
                                                    </tr>
                                                  </fieldset>
                                                  </tbody>
                                                </table>
                                                <b-alert :show="true" variant="success">
                                                  <div class="iq-alert-icon">
                                                    <i class="fa fa-thumbs-up"></i>
                                                  </div>
                                                  <div class="iq-alert-text">General Overhaul ME (P/S) <b>APPROVED</b></div>
                                                </b-alert>
                                              </b-collapse>
                                            </b-card>
                                            <b-card no-body >
                                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                                <b-button block v-b-toggle.job-1-2 variant="success" class="text-left"><i class="fa fa-chevron-circle-down"></i> Down Slip <strong class="pull-right">BOBOT PEKERJAAN : 50%</strong></b-button>
                                              </b-card-header>
                                              <b-collapse id="job-1-2" accordion="my-accordion-sub" role="tabpanel">
                                                <table class="table table-striped mt-2">
                                                  <tbody>
                                                  <tr>
                                                    <th width="20%">Start Date</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">2022-06-10</td>
                                                    <th width="20%">Est. Finish</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">2022-06-15</td>
                                                  </tr>
                                                  <tr>
                                                    <th width="20%">Bobot Pekerjaan</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                      <div class="input-group">
                                                        <div class="input-group-prepend">
                                                          <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="bobot_pekerjaan">
                                                      </div>
                                                    </td>
                                                    <th width="20%">Dokumen</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                      <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                      </ul>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <th width="20%">Created By</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Nurul Akbar</td>
                                                    <th width="20%">Review By</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Agung Tri Laksono</td>
                                                  </tr>
                                                  <tr>
                                                    <th class="align-top">Notes</th>
                                                    <td class="text-center align-top">:</td>
                                                    <td colspan="4">
                                                      Check water tubes/plates and clean/free from clogged.<br>
                                                      Check  from any corrosion <br>
                                                      Check  zink anodes,  replace when necessary<br>
                                                      Check  leakage after assembly
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <th class="align-top">Internal Man Power</th>
                                                    <td class="text-center align-top">:</td>
                                                    <td colspan="4">
                                                      <h5 class="mt-1"><b-badge variant="info">Agung Tri Laksono - Port Captain</b-badge></h5>
                                                      <h5 class="mt-1"><b-badge variant="info">Nurul Akbar - Port Engineer</b-badge></h5>
                                                      <h5 class="mt-1"><b-badge variant="info">Tri Leksono - Electrical Engineer</b-badge></h5>
                                                    </td>
                                                  </tr>
                                                  </tbody>
                                                </table>
                                                <table class="table table-striped mt-2">
                                                  <tbody>
                                                  <fieldset class="form-group border p-3">
                                                    <legend class="w-auto px-2">Kontraktor 1</legend>
                                                    <tr>
                                                      <th width="20%">Nama Kontraktor</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">PT. Tavia Digital Solusi</td>
                                                      <th width="20%">Executive Engineer</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Agung Tri Laksono</td>
                                                    </tr>
                                                    <tr>
                                                      <th width="20%">Penawaran</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Rp.300.000.000,-</td>
                                                      <th width="20%">Penawaran Final</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Rp. 28.000.000,-</td>
                                                    </tr>
                                                    <tr>
                                                      <th width="20%">Note</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Note Here</td>
                                                      <th width="20%">Attachment</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">
                                                        <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        </ul>
                                                      </td>
                                                    </tr>
                                                  </fieldset>
                                                  </tbody>
                                                </table>
                                                <b-alert :show="true" variant="success">
                                                  <div class="iq-alert-icon">
                                                    <i class="fa fa-thumbs-up"></i>
                                                  </div>
                                                  <div class="iq-alert-text">General Overhaul ME (P/S) <b>APPROVED</b></div>
                                                </b-alert>
                                              </b-collapse>
                                            </b-card>
                                          </div>
                                        </b-card-body>
                                      </b-collapse>
                                    </b-card>
                                  </div>
                                  <!-- DOCKING JOB END -->
                                  <!-- DOCKING JOB -->
                                  <div class="accordion" role="tablist">
                                    <b-card no-body>
                                      <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-success">
                                        <template v-slot:body >
                                          <div class="d-flex align-items-center justify-content-between">
                                            <b-button variant="primary" class="mr-1" v-b-toggle.jobCat-2><i class="fa fa-chevron-circle-down"></i>Click, Job Detail</b-button>
                                            <h4 class="mb-0">SAND BLASTING</h4>
                                            <div class="text-right">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">%</span>
                                                </div>
                                                <input type="number" class="form-control" id="real_budget" placeholder="30">
                                              </div>
                                            </div>
                                          </div>
                                        </template>
                                      </iq-card>

                                      <b-collapse id="jobCat-2" accordion="my-accordion" role="tabpanel">
                                        <b-card-body>
                                          <!-- BOBOT DOCKING JOB -->
                                          <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-info rounded">
                                            <template v-slot:body >
                                              <div class="d-flex align-items-center justify-content-between">
                                                <div class="rounded-circle iq-card-icon bg-info"><i class="fa fa-percent"></i></div>
                                                <div class="text-right">
                                                  <h2 class="mb-0"><span class="counter">100%</span></h2>
                                                  <h5 class="">Persentase Docking Job</h5>
                                                </div>
                                              </div>
                                            </template>
                                          </iq-card>
                                          <!-- BOBOT DOCKING JOB END -->
                                          <div class="accordion" role="tablist">
                                            <b-card no-body >
                                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                                <b-button block v-b-toggle.job-2-1 variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i> Scraping teritip di lambung dan lunas<strong class="pull-right">BOBOT PEKERJAAN : 30%</strong></b-button>
                                              </b-card-header>
                                              <b-collapse id="job-2-1" accordion="my-accordion-sub" role="tabpanel">
                                                <b-form-row class="mt-3">
                                                  <b-col>
                                                    <b-form-group label="Start *" label-for="start">
                                                      <b-form-input type="date" id="start"></b-form-input>
                                                      <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                                    </b-form-group>
                                                  </b-col>
                                                  <b-col>
                                                    <b-form-group label="Est. Finish *" label-for="end">
                                                      <b-form-input type="date" id="end"></b-form-input>
                                                      <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <b-form-row>
                                                  <b-col>
                                                    <b-form-group label="Bobot Pekerjaan *" label-for="bobot_pekerjaan">
                                                      <div class="input-group">
                                                        <div class="input-group-prepend">
                                                          <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="bobot_pekerjaan">
                                                      </div>
                                                    </b-form-group>
                                                  </b-col>
                                                  <b-col>
                                                    <b-form-group label="Attachment Job" label-for="attachment_job">
                                                      <div class="custom-file">
                                                        <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                        <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                                      </div>
                                                      <small class="text-muted">
                                                        File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                        File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                                      </small>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <b-form-row>
                                                  <b-col>
                                                    <b-form-group label="Notes" label-for="notes">
                                                      <b-form-textarea id="description" rows="3" placeholder="Check water tubes/plates and clean/free from clogged."></b-form-textarea>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <b-form-row>
                                                  <b-col>
                                                    <b-form-group label="Internal Man Power" label-for="internal_man_power">
                                                      <template v-for="(item,index) in text">
                                                        <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                          <div class="custom-switch-inner">
                                                            <p class="mb-0"> {{ item.label }} </p>
                                                            <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                            <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                            </label>
                                                          </div>
                                                        </div>
                                                      </template>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <fieldset class="form-group border p-3">
                                                  <legend class="w-auto px-2">Internal Man Power</legend>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                                        <b-form-input type="text" id="nama_internal_man_power" placeholder="Nurul Akbar"></b-form-input>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="Jabatan *" label-for="jabatan">
                                                        <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                          <template v-slot:first>
                                                            <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                          </template>
                                                        </b-form-select>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Nama Internal Man Power *" label-for="nama_internal_man_power">
                                                        <b-form-input type="text" id="nama_internal_man_power" placeholder="Agung Tri Laksono"></b-form-input>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="Jabatan *" label-for="jabatan">
                                                        <b-form-select plain v-model="selectedJabatan" :options="jabatan" size="lg">
                                                          <template v-slot:first>
                                                            <b-form-select-option :value="null">Select Jabatan</b-form-select-option>
                                                          </template>
                                                        </b-form-select>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                  <b-button variant="success" class="mt-3"><i class="fa fa-users"></i> ADD INTERNAL MAN POWER</b-button>
                                                </fieldset>
                                                <b-form-row>
                                                  <b-col>
                                                    <b-form-group label="Sub Kontraktor" label-for="subkontraktor">
                                                      <template v-for="(item,index) in text">
                                                        <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                                          <div class="custom-switch-inner">
                                                            <p class="mb-0"> {{ item.label }} </p>
                                                            <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                                            <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                                            </label>
                                                          </div>
                                                        </div>
                                                      </template>
                                                    </b-form-group>
                                                  </b-col>
                                                </b-form-row>
                                                <fieldset class="form-group border p-3">
                                                  <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Nama Kontraktor *" label-for="nama_kontraktor">
                                                        <b-form-input type="text" id="nama_kontraktor" placeholder="PT. CAHAYA PELAYARAN"></b-form-input>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="PIC / Executive Engineer *" label-for="executive_engineer">
                                                        <b-form-input type="text" id="executive_engineer" placeholder="Agung Tri Laksono"></b-form-input>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Penawaran" label-for="penawaran">
                                                        <div class="input-group">
                                                          <div class="input-group-prepend">
                                                            <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                          </div>
                                                          <input type="number" class="form-control" id="penawaran" placeholder="1.500.000">
                                                        </div>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="Penawaran Nego" label-for="penawaran_nego">
                                                        <div class="input-group">
                                                          <div class="input-group-prepend">
                                                            <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                                          </div>
                                                          <input type="number" class="form-control" id="penawaran_nego" placeholder="1.300.000">
                                                        </div>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                  <b-form-row>
                                                    <b-col>
                                                      <b-form-group label="Notes" label-for="notes">
                                                        <b-form-textarea id="description" rows="2" placeholder="Subkontraktor perbaikan general electrical"></b-form-textarea>
                                                      </b-form-group>
                                                    </b-col>
                                                    <b-col>
                                                      <b-form-group label="Attachment Shipyard" label-for="doc_shipyard">
                                                        <div class="custom-file">
                                                          <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                                          <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                                        </div>
                                                        <small class="text-muted">
                                                          File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                                          File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                                        </small>
                                                      </b-form-group>
                                                    </b-col>
                                                  </b-form-row>
                                                </fieldset>
                                                <b-button variant="success" class="mb-3"><i class="fa fa-users"></i> ADD SUB KONTRAKTOR</b-button>
                                              </b-collapse>
                                            </b-card>
                                            <b-card no-body >
                                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                                <b-button block v-b-toggle.job-2-2 variant="success" class="text-left"><i class="fa fa-chevron-circle-down"></i> Pencucian lambung dengan air tawar<strong class="pull-right">BOBOT PEKERJAAN : 10%</strong></b-button>
                                              </b-card-header>
                                              <b-collapse id="job-2-2" accordion="my-accordion-sub" role="tabpanel">
                                                <table class="table table-striped mt-2">
                                                  <tbody>
                                                  <tr>
                                                    <th width="20%">Start Date</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">2022-06-10</td>
                                                    <th width="20%">Est. Finish</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">2022-06-15</td>
                                                  </tr>
                                                  <tr>
                                                    <th width="20%">Bobot Pekerjaan</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                      <div class="input-group">
                                                        <div class="input-group-prepend">
                                                          <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="bobot_pekerjaan">
                                                      </div>
                                                    </td>
                                                    <th width="20%">Dokumen</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                      <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                      </ul>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <th width="20%">Created By</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Nurul Akbar</td>
                                                    <th width="20%">Review By</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Agung Tri Laksono</td>
                                                  </tr>
                                                  <tr>
                                                    <th class="align-top">Notes</th>
                                                    <td class="text-center align-top">:</td>
                                                    <td colspan="4">
                                                      Check water tubes/plates and clean/free from clogged.<br>
                                                      Check  from any corrosion <br>
                                                      Check  zink anodes,  replace when necessary<br>
                                                      Check  leakage after assembly
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <th class="align-top">Internal Man Power</th>
                                                    <td class="text-center align-top">:</td>
                                                    <td colspan="4">
                                                      <h5 class="mt-1"><b-badge variant="info">Agung Tri Laksono - Port Captain</b-badge></h5>
                                                      <h5 class="mt-1"><b-badge variant="info">Nurul Akbar - Port Engineer</b-badge></h5>
                                                      <h5 class="mt-1"><b-badge variant="info">Tri Leksono - Electrical Engineer</b-badge></h5>
                                                    </td>
                                                  </tr>
                                                  </tbody>
                                                </table>
                                                <table class="table table-striped mt-2">
                                                  <tbody>
                                                  <fieldset class="form-group border p-3">
                                                    <legend class="w-auto px-2">Kontraktor 1</legend>
                                                    <tr>
                                                      <th width="20%">Nama Kontraktor</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">PT. Tavia Digital Solusi</td>
                                                      <th width="20%">Executive Engineer</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Agung Tri Laksono</td>
                                                    </tr>
                                                    <tr>
                                                      <th width="20%">Penawaran</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Rp.300.000.000,-</td>
                                                      <th width="20%">Penawaran Final</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Rp. 28.000.000,-</td>
                                                    </tr>
                                                    <tr>
                                                      <th width="20%">Note</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Note Here</td>
                                                      <th width="20%">Attachment</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">
                                                        <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        </ul>
                                                      </td>
                                                    </tr>
                                                  </fieldset>
                                                  </tbody>
                                                </table>
                                                <b-alert :show="true" variant="success">
                                                  <div class="iq-alert-icon">
                                                    <i class="fa fa-thumbs-up"></i>
                                                  </div>
                                                  <div class="iq-alert-text">General Overhaul ME (P/S) <b>APPROVED</b></div>
                                                </b-alert>
                                              </b-collapse>
                                            </b-card>
                                            <b-card no-body >
                                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                                <b-button block v-b-toggle.job-2-3 variant="success" class="text-left"><i class="fa fa-chevron-circle-down"></i> Sand blasting lunas<strong class="pull-right">BOBOT PEKERJAAN : 10%</strong></b-button>
                                              </b-card-header>
                                              <b-collapse id="job-2-3" accordion="my-accordion-sub" role="tabpanel">
                                                <table class="table table-striped mt-2">
                                                  <tbody>
                                                  <tr>
                                                    <th width="20%">Start Date</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">2022-06-10</td>
                                                    <th width="20%">Est. Finish</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">2022-06-15</td>
                                                  </tr>
                                                  <tr>
                                                    <th width="20%">Bobot Pekerjaan</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                      <div class="input-group">
                                                        <div class="input-group-prepend">
                                                          <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="bobot_pekerjaan">
                                                      </div>
                                                    </td>
                                                    <th width="20%">Dokumen</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                      <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                      </ul>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <th width="20%">Created By</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Nurul Akbar</td>
                                                    <th width="20%">Review By</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Agung Tri Laksono</td>
                                                  </tr>
                                                  <tr>
                                                    <th class="align-top">Notes</th>
                                                    <td class="text-center align-top">:</td>
                                                    <td colspan="4">
                                                      Check water tubes/plates and clean/free from clogged.<br>
                                                      Check  from any corrosion <br>
                                                      Check  zink anodes,  replace when necessary<br>
                                                      Check  leakage after assembly
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <th class="align-top">Internal Man Power</th>
                                                    <td class="text-center align-top">:</td>
                                                    <td colspan="4">
                                                      <h5 class="mt-1"><b-badge variant="info">Agung Tri Laksono - Port Captain</b-badge></h5>
                                                      <h5 class="mt-1"><b-badge variant="info">Nurul Akbar - Port Engineer</b-badge></h5>
                                                      <h5 class="mt-1"><b-badge variant="info">Tri Leksono - Electrical Engineer</b-badge></h5>
                                                    </td>
                                                  </tr>
                                                  </tbody>
                                                </table>
                                                <table class="table table-striped mt-2">
                                                  <tbody>
                                                  <fieldset class="form-group border p-3">
                                                    <legend class="w-auto px-2">Kontraktor 1</legend>
                                                    <tr>
                                                      <th width="20%">Nama Kontraktor</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">PT. Tavia Digital Solusi</td>
                                                      <th width="20%">Executive Engineer</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Agung Tri Laksono</td>
                                                    </tr>
                                                    <tr>
                                                      <th width="20%">Penawaran</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Rp.300.000.000,-</td>
                                                      <th width="20%">Penawaran Final</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Rp. 28.000.000,-</td>
                                                    </tr>
                                                    <tr>
                                                      <th width="20%">Note</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Note Here</td>
                                                      <th width="20%">Attachment</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">
                                                        <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        </ul>
                                                      </td>
                                                    </tr>
                                                  </fieldset>
                                                  </tbody>
                                                </table>
                                                <b-alert :show="true" variant="success">
                                                  <div class="iq-alert-icon">
                                                    <i class="fa fa-thumbs-up"></i>
                                                  </div>
                                                  <div class="iq-alert-text">General Overhaul ME (P/S) <b>APPROVED</b></div>
                                                </b-alert>
                                              </b-collapse>
                                            </b-card>
                                            <b-card no-body >
                                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                                <b-button block v-b-toggle.job-2-4 variant="success" class="text-left"><i class="fa fa-chevron-circle-down"></i> Sand blasting deck dan bulwark<strong class="pull-right">BOBOT PEKERJAAN : 10%</strong></b-button>
                                              </b-card-header>
                                              <b-collapse id="job-2-4" accordion="my-accordion-sub" role="tabpanel">
                                                <table class="table table-striped mt-2">
                                                  <tbody>
                                                  <tr>
                                                    <th width="20%">Start Date</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">2022-06-10</td>
                                                    <th width="20%">Est. Finish</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">2022-06-15</td>
                                                  </tr>
                                                  <tr>
                                                    <th width="20%">Bobot Pekerjaan</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                      <div class="input-group">
                                                        <div class="input-group-prepend">
                                                          <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="bobot_pekerjaan">
                                                      </div>
                                                    </td>
                                                    <th width="20%">Dokumen</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                      <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                      </ul>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <th width="20%">Created By</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Nurul Akbar</td>
                                                    <th width="20%">Review By</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Agung Tri Laksono</td>
                                                  </tr>
                                                  <tr>
                                                    <th class="align-top">Notes</th>
                                                    <td class="text-center align-top">:</td>
                                                    <td colspan="4">
                                                      Check water tubes/plates and clean/free from clogged.<br>
                                                      Check  from any corrosion <br>
                                                      Check  zink anodes,  replace when necessary<br>
                                                      Check  leakage after assembly
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <th class="align-top">Internal Man Power</th>
                                                    <td class="text-center align-top">:</td>
                                                    <td colspan="4">
                                                      <h5 class="mt-1"><b-badge variant="info">Agung Tri Laksono - Port Captain</b-badge></h5>
                                                      <h5 class="mt-1"><b-badge variant="info">Nurul Akbar - Port Engineer</b-badge></h5>
                                                      <h5 class="mt-1"><b-badge variant="info">Tri Leksono - Electrical Engineer</b-badge></h5>
                                                    </td>
                                                  </tr>
                                                  </tbody>
                                                </table>
                                                <table class="table table-striped mt-2">
                                                  <tbody>
                                                  <fieldset class="form-group border p-3">
                                                    <legend class="w-auto px-2">Kontraktor 1</legend>
                                                    <tr>
                                                      <th width="20%">Nama Kontraktor</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">PT. Tavia Digital Solusi</td>
                                                      <th width="20%">Executive Engineer</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Agung Tri Laksono</td>
                                                    </tr>
                                                    <tr>
                                                      <th width="20%">Penawaran</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Rp.300.000.000,-</td>
                                                      <th width="20%">Penawaran Final</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Rp. 28.000.000,-</td>
                                                    </tr>
                                                    <tr>
                                                      <th width="20%">Note</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Note Here</td>
                                                      <th width="20%">Attachment</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">
                                                        <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        </ul>
                                                      </td>
                                                    </tr>
                                                  </fieldset>
                                                  </tbody>
                                                </table>
                                                <b-alert :show="true" variant="success">
                                                  <div class="iq-alert-icon">
                                                    <i class="fa fa-thumbs-up"></i>
                                                  </div>
                                                  <div class="iq-alert-text">General Overhaul ME (P/S) <b>APPROVED</b></div>
                                                </b-alert>
                                              </b-collapse>
                                            </b-card>
                                          </div>
                                        </b-card-body>
                                      </b-collapse>
                                    </b-card>
                                  </div>
                                  <!-- DOCKING JOB END -->
                                  <!-- DOCKING JOB -->
                                  <div class="accordion-jobCat-3" role="tablist">
                                    <b-card no-body>
                                      <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-success">
                                        <template v-slot:body >
                                          <div class="d-flex align-items-center justify-content-between">
                                            <b-button variant="primary" class="mr-1" v-b-toggle.jobCat-3><i class="fa fa-chevron-circle-down"></i>Click, Job Detail</b-button>
                                            <h4 class="mb-0">MACHINERY EQUIPMENT</h4>
                                            <div class="text-right">
                                              <div class="input-group">
                                                <div class="input-group-prepend">
                                                  <span class="input-group-text bg-primary" id="inputGroupPrepend2">%</span>
                                                </div>
                                                <input type="number" class="form-control" id="real_budget" placeholder="30">
                                              </div>
                                            </div>
                                          </div>
                                        </template>
                                      </iq-card>

                                      <b-collapse id="jobCat-3" accordion="my-accordion-jobCat-3" role="tabpanel">
                                        <b-card-body>
                                          <!-- BOBOT DOCKING JOB -->
                                          <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-info rounded">
                                            <template v-slot:body >
                                              <div class="d-flex align-items-center justify-content-between">
                                                <div class="rounded-circle iq-card-icon bg-info"><i class="fa fa-percent"></i></div>
                                                <div class="text-right">
                                                  <h2 class="mb-0"><span class="counter">100%</span></h2>
                                                  <h5 class="">Persentase Docking Job</h5>
                                                </div>
                                              </div>
                                            </template>
                                          </iq-card>
                                          <!-- BOBOT DOCKING JOB END -->
                                          <div class="accordion" role="tablist">
                                            <b-card no-body >
                                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                                <b-button block v-b-toggle.job-3-1 variant="success" class="text-left"><i class="fa fa-chevron-circle-down"></i> General Overhaul ME (P/S)<strong class="pull-right">BOBOT PEKERJAAN : 30%</strong></b-button>
                                              </b-card-header>
                                              <b-collapse id="job-3-1" accordion="my-accordion-sub" role="tabpanel">
                                                <table class="table table-striped mt-2">
                                                  <tbody>
                                                  <tr>
                                                    <th width="20%">Start Date</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">2022-06-10</td>
                                                    <th width="20%">Est. Finish</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">2022-06-15</td>
                                                  </tr>
                                                  <tr>
                                                    <th width="20%">Bobot Pekerjaan</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                      <div class="input-group">
                                                        <div class="input-group-prepend">
                                                          <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="bobot_pekerjaan">
                                                      </div>
                                                    </td>
                                                    <th width="20%">Dokumen</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                      <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                      </ul>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <th width="20%">Created By</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Nurul Akbar</td>
                                                    <th width="20%">Review By</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Agung Tri Laksono</td>
                                                  </tr>
                                                  <tr>
                                                    <th class="align-top">Notes</th>
                                                    <td class="text-center align-top">:</td>
                                                    <td colspan="4">
                                                      Check water tubes/plates and clean/free from clogged.<br>
                                                      Check  from any corrosion <br>
                                                      Check  zink anodes,  replace when necessary<br>
                                                      Check  leakage after assembly
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <th class="align-top">Internal Man Power</th>
                                                    <td class="text-center align-top">:</td>
                                                    <td colspan="4">
                                                      <h5 class="mt-1"><b-badge variant="info">Agung Tri Laksono - Port Captain</b-badge></h5>
                                                      <h5 class="mt-1"><b-badge variant="info">Nurul Akbar - Port Engineer</b-badge></h5>
                                                      <h5 class="mt-1"><b-badge variant="info">Tri Leksono - Electrical Engineer</b-badge></h5>
                                                    </td>
                                                  </tr>
                                                  </tbody>
                                                </table>
                                                <table class="table table-striped mt-2">
                                                  <tbody>
                                                  <fieldset class="form-group border p-3">
                                                    <legend class="w-auto px-2">Kontraktor 1</legend>
                                                    <tr>
                                                      <th width="20%">Nama Kontraktor</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">PT. Tavia Digital Solusi</td>
                                                      <th width="20%">Executive Engineer</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Agung Tri Laksono</td>
                                                    </tr>
                                                    <tr>
                                                      <th width="20%">Penawaran</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Rp.300.000.000,-</td>
                                                      <th width="20%">Penawaran Final</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Rp. 28.000.000,-</td>
                                                    </tr>
                                                    <tr>
                                                      <th width="20%">Note</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Note Here</td>
                                                      <th width="20%">Attachment</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">
                                                        <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        </ul>
                                                      </td>
                                                    </tr>
                                                  </fieldset>
                                                  </tbody>
                                                </table>
                                                <b-alert :show="true" variant="success">
                                                  <div class="iq-alert-icon">
                                                    <i class="fa fa-thumbs-up"></i>
                                                  </div>
                                                  <div class="iq-alert-text">General Overhaul ME (P/S) <b>APPROVED</b></div>
                                                </b-alert>
                                              </b-collapse>
                                            </b-card>
                                            <b-card no-body >
                                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                                <b-button block v-b-toggle.job-3-2 variant="success" class="text-left"><i class="fa fa-chevron-circle-down"></i> Kalibrasi injector (P/S)<strong class="pull-right">BOBOT PEKERJAAN : 10%</strong></b-button>
                                              </b-card-header>
                                              <b-collapse id="job-3-2" accordion="my-accordion-sub" role="tabpanel">
                                                <table class="table table-striped mt-2">
                                                  <tbody>
                                                  <tr>
                                                    <th width="20%">Start Date</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">2022-06-10</td>
                                                    <th width="20%">Est. Finish</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">2022-06-15</td>
                                                  </tr>
                                                  <tr>
                                                    <th width="20%">Bobot Pekerjaan</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                      <div class="input-group">
                                                        <div class="input-group-prepend">
                                                          <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="bobot_pekerjaan">
                                                      </div>
                                                    </td>
                                                    <th width="20%">Dokumen</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                      <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                      </ul>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <th width="20%">Created By</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Nurul Akbar</td>
                                                    <th width="20%">Review By</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Agung Tri Laksono</td>
                                                  </tr>
                                                  <tr>
                                                    <th class="align-top">Notes</th>
                                                    <td class="text-center align-top">:</td>
                                                    <td colspan="4">
                                                      Check water tubes/plates and clean/free from clogged.<br>
                                                      Check  from any corrosion <br>
                                                      Check  zink anodes,  replace when necessary<br>
                                                      Check  leakage after assembly
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <th class="align-top">Internal Man Power</th>
                                                    <td class="text-center align-top">:</td>
                                                    <td colspan="4">
                                                      <h5 class="mt-1"><b-badge variant="info">Agung Tri Laksono - Port Captain</b-badge></h5>
                                                      <h5 class="mt-1"><b-badge variant="info">Nurul Akbar - Port Engineer</b-badge></h5>
                                                      <h5 class="mt-1"><b-badge variant="info">Tri Leksono - Electrical Engineer</b-badge></h5>
                                                    </td>
                                                  </tr>
                                                  </tbody>
                                                </table>
                                                <b-alert :show="true" variant="success">
                                                  <div class="iq-alert-icon">
                                                    <i class="fa fa-thumbs-up"></i>
                                                  </div>
                                                  <div class="iq-alert-text">General Overhaul ME (P/S) <b>APPROVED</b></div>
                                                </b-alert>
                                              </b-collapse>
                                            </b-card>
                                            <b-card no-body >
                                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                                <b-button block v-b-toggle.job-3-3 variant="success" class="text-left"><i class="fa fa-chevron-circle-down"></i> Service/ganti baru dynamo starter (P/S)<strong class="pull-right">BOBOT PEKERJAAN : 10%</strong></b-button>
                                              </b-card-header>
                                              <b-collapse id="job-3-3" accordion="my-accordion-sub" role="tabpanel">
                                                <table class="table table-striped mt-2">
                                                  <tbody>
                                                  <tr>
                                                    <th width="20%">Start Date</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">2022-06-10</td>
                                                    <th width="20%">Est. Finish</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">2022-06-15</td>
                                                  </tr>
                                                  <tr>
                                                    <th width="20%">Bobot Pekerjaan</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                      <div class="input-group">
                                                        <div class="input-group-prepend">
                                                          <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="bobot_pekerjaan">
                                                      </div>
                                                    </td>
                                                    <th width="20%">Dokumen</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                      <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                      </ul>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <th width="20%">Created By</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Nurul Akbar</td>
                                                    <th width="20%">Review By</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Agung Tri Laksono</td>
                                                  </tr>
                                                  <tr>
                                                    <th class="align-top">Notes</th>
                                                    <td class="text-center align-top">:</td>
                                                    <td colspan="4">
                                                      Check zwater tubes/plates and clean/free from clogged.<br>
                                                      Check  from any corrosion <br>
                                                      Check  zink anodes,  replace when necessary<br>
                                                      Check  leakage after assembly
                                                    </td>
                                                  </tr>
                                                  </tbody>
                                                </table>
                                                <table class="table table-striped mt-2">
                                                  <tbody>
                                                  <fieldset class="form-group border p-3">
                                                    <legend class="w-auto px-2">Kontraktor 1</legend>
                                                    <tr>
                                                      <th width="20%">Nama Kontraktor</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">PT. Tavia Digital Solusi</td>
                                                      <th width="20%">Executive Engineer</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Agung Tri Laksono</td>
                                                    </tr>
                                                    <tr>
                                                      <th width="20%">Penawaran</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Rp.300.000.000,-</td>
                                                      <th width="20%">Penawaran Final</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Rp. 28.000.000,-</td>
                                                    </tr>
                                                    <tr>
                                                      <th width="20%">Note</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Note Here</td>
                                                      <th width="20%">Attachment</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">
                                                        <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        </ul>
                                                      </td>
                                                    </tr>
                                                  </fieldset>
                                                  </tbody>
                                                </table>
                                                <b-alert :show="true" variant="success">
                                                  <div class="iq-alert-icon">
                                                    <i class="fa fa-thumbs-up"></i>
                                                  </div>
                                                  <div class="iq-alert-text">General Overhaul ME (P/S) <b>APPROVED</b></div>
                                                </b-alert>
                                              </b-collapse>
                                            </b-card>
                                            <b-card no-body >
                                              <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                                <b-button block v-b-toggle.job-3-4 variant="success" class="text-left"><i class="fa fa-chevron-circle-down"></i> Service/ganti baru alternator (P/S)<strong class="pull-right">BOBOT PEKERJAAN : 10%</strong></b-button>
                                              </b-card-header>
                                              <b-collapse id="job-3-4" accordion="my-accordion-sub" role="tabpanel">
                                                <table class="table table-striped mt-2">
                                                  <tbody>
                                                  <tr>
                                                    <th width="20%">Start Date</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">2022-06-10</td>
                                                    <th width="20%">Est. Finish</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">2022-06-15</td>
                                                  </tr>
                                                  <tr>
                                                    <th width="20%">Bobot Pekerjaan</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                      <div class="input-group">
                                                        <div class="input-group-prepend">
                                                          <span class="input-group-text bg-primary" id="bobot_pekerjaan">%</span>
                                                        </div>
                                                        <input type="text" class="form-control" id="bobot_pekerjaan">
                                                      </div>
                                                    </td>
                                                    <th width="20%">Dokumen</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">
                                                      <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                      </ul>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <th width="20%">Created By</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Nurul Akbar</td>
                                                    <th width="20%">Review By</th>
                                                    <td width="5%" class="text-center">:</td>
                                                    <td width="25%">Agung Tri Laksono</td>
                                                  </tr>
                                                  <tr>
                                                    <th class="align-top">Notes</th>
                                                    <td class="text-center align-top">:</td>
                                                    <td colspan="4">
                                                      Check water tubes/plates and clean/free from clogged.<br>
                                                      Check  from any corrosion <br>
                                                      Check  zink anodes,  replace when necessary<br>
                                                      Check  leakage after assembly
                                                    </td>
                                                  </tr>
                                                  </tbody>
                                                </table>
                                                <table class="table table-striped mt-2">
                                                  <tbody>
                                                  <fieldset class="form-group border p-3">
                                                    <legend class="w-auto px-2">Kontraktor 1</legend>
                                                    <tr>
                                                      <th width="20%">Nama Kontraktor</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">PT. Tavia Digital Solusi</td>
                                                      <th width="20%">Executive Engineer</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Agung Tri Laksono</td>
                                                    </tr>
                                                    <tr>
                                                      <th width="20%">Penawaran</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Rp.300.000.000,-</td>
                                                      <th width="20%">Penawaran Final</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Rp. 28.000.000,-</td>
                                                    </tr>
                                                    <tr>
                                                      <th width="20%">Note</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">Note Here</td>
                                                      <th width="20%">Attachment</th>
                                                      <td width="5%" class="text-center">:</td>
                                                      <td width="25%">
                                                        <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                          <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                        </ul>
                                                      </td>
                                                    </tr>
                                                  </fieldset>
                                                  </tbody>
                                                </table>
                                                <b-alert :show="true" variant="success">
                                                  <div class="iq-alert-icon">
                                                    <i class="fa fa-thumbs-up"></i>
                                                  </div>
                                                  <div class="iq-alert-text">General Overhaul ME (P/S) <b>APPROVED</b></div>
                                                </b-alert>
                                              </b-collapse>
                                            </b-card>
                                          </div>
                                        </b-card-body>
                                      </b-collapse>
                                    </b-card>
                                  </div>
                                  <!-- DOCKING JOB END -->

                                </b-col>
                                <!-- BUTTON SAVE - RESET -->
                                <b-col md="12" class="mt-3">
                                  <b-card-text class="pull-right"><small class="text-muted">Sign * : <b-badge variant="warning">Mandatory (Must be Input)</b-badge></small></b-card-text>
                                  <br><br>
                                  <b-button type="submit" variant="danger" class="pull-right"><i class="fa fa-close"></i> Reset Form</b-button>
                                  <b-button type="submit" variant="success" class="pull-right mr-1"><i class="fa fa-save"></i> Save</b-button>
                                </b-col>
                                <!-- BUTTON SAVE - RESET END -->
                              </b-row>

                            </b-col>
                          </b-row>
                        </fieldset>
                      </b-col>
                    </b-row>
                  </template>
                </b-sidebar>
                <!-- SLIDE - FORM UPDATE DOCKING END -->

                <!-- SLIDE - FORM APPROVE DOCKING -->
                <b-sidebar
                  v-model="slideApproveDocking"
                  id="approve_docking"
                  title="FORM APPROVE DOCKING - TB. SML 201"
                  width="90%"
                  backdrop-variant="dark"
                  bg-variant="white"
                  right
                  backdrop
                  shadow>
                  <template #default="{hide}">
                    <b-row class="m-3">
                      <b-col cols="12">
                        <fieldset class="form-group border p-3">
                          <legend class="w-auto px-2">Select Docking Job</legend>
                          <b-row>
                            <b-col md="10">
                              <b-form-input id="job_name" type="text" placeholder="Search Job Name"></b-form-input>
                            </b-col>
                            <b-col md="2">
                              <b-button variant="success" block>SEARCH</b-button>
                            </b-col>
                            <b-col md="4">
                              <v-jstree :data="tree" show-checkbox allow-batch whole-row :item-events="itemEvents" @item-click="itemClick" class="mt-3">
                                <template slot-scope="_">
                                  <div style="display: inherit; width: 200px" @click.ctrl="customItemClickWithCtrl">
                                    <i :class="_.vm.themeIconClasses" role="presentation" v-if="!_.model.loading"></i>
                                    {{_.model.text}}
                                  </div>
                                </template>
                              </v-jstree>
                            </b-col>
                            <b-col md="8" class="mt-3">
                              <table class="table table-striped">
                                <tbody>
                                <tr>
                                  <th width="20%">Arrival Shipyard</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">
                                    <b-form-input type="date" id="arrival_shipyard"></b-form-input>
                                  </td>
                                  <th width="20%">Up Slip</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">
                                    <b-form-input type="date" id="upslip"></b-form-input>
                                  </td>
                                </tr>
                                <tr>
                                  <th width="20%">Waiting Dock Space</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">5 Hari</td>
                                  <th width="20%">Down Slip</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">
                                    <b-form-input type="date" id="downslip"></b-form-input>
                                  </td>
                                </tr>
                                <tr>
                                  <th width="20%">Complete Date</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">
                                    <b-form-input type="date" id="delivery_date"></b-form-input>
                                  </td>
                                  <th width="20%">Budget</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">Rp. 385.000.000,-</td>
                                </tr>
                                <tr>
                                  <th width="20%">Shipyard</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">PT. CAHAYA BAHARI SHIPYARD</td>
                                  <th width="20%">Penawaran</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">
                                    <div class="input-group">
                                      <div class="input-group-prepend">
                                        <span class="input-group-text bg-primary" id="inputGroupPrepend2">Rp.</span>
                                      </div>
                                      <input type="number" class="form-control" id="real_budget" placeholder="324.000.000">
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th width="20%">PIC</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">NURUL AKBAR</td>
                                  <th width="20%">Document Shipyard</th>
                                  <td width="5%" class="text-center">:</td>
                                  <td width="25%">
                                    <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                      <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                      <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                      <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                      <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                    </ul>
                                  </td>
                                </tr>
                                </tbody>
                              </table>

                              <!-- JOB CATEGORY PERCENT -->
                              <b-row>
                                <b-col md="12">
                                  <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-primary rounded">
                                    <template v-slot:body >
                                      <div class="d-flex align-items-center justify-content-between">
                                        <div class="rounded-circle iq-card-icon bg-primary"><i class="fa fa-percent"></i></div>
                                        <div class="text-right">
                                          <h2 class="mb-0"><span class="counter">100%</span></h2>
                                          <h5 class="">Persentase Job Category</h5>
                                        </div>
                                      </div>
                                    </template>
                                  </iq-card>
                                </b-col>
                              </b-row>
                              <!-- JOB CATEGORY PERCENT END -->

                              <!-- DOCKING JOB -->
                              <div class="accordion" role="tablist">
                                <b-card no-body >
                                  <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none">
                                    <b-button block v-b-toggle.jobCat-1 variant="primary" class="text-left"><i class="fa fa-chevron-circle-down"></i>DOCKING / UNDOCKING <strong class="pull-right">BOBOT PEKERJAAN : 30%</strong></b-button>
                                  </b-card-header>

                                  <b-collapse id="jobCat-1" accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                      <!-- BOBOT DOCKING JOB -->
                                      <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-info rounded">
                                        <template v-slot:body >
                                          <div class="d-flex align-items-center justify-content-between">
                                            <div class="rounded-circle iq-card-icon bg-info"><i class="fa fa-percent"></i></div>
                                            <div class="text-right">
                                              <h2 class="mb-0"><span class="counter">100%</span></h2>
                                              <h5 class="">Persentase Docking Job</h5>
                                            </div>
                                          </div>
                                        </template>
                                      </iq-card>

                                      <b-modal id="reason_reject" size="md" title="Reject - Docking Up Slip" ok-title="Save" cancel-title="Close">
                                        <b-row>
                                          <b-col md="12">
                                            <b-form-group label="Reason Reject" label-for="reason_reject">
                                              <b-form-textarea id="reason_reject" rows="3"></b-form-textarea>
                                            </b-form-group>
                                            <b-button block variant="primary"><i class="fa fa-close mr-2"></i>Reject Docking Job</b-button>
                                          </b-col>
                                        </b-row>
                                      </b-modal>

                                      <div class="accordion" role="tablist">
                                        <b-card no-body >
                                          <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                            <b-button block v-b-toggle.job-1-1 variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i> Up Slip <strong class="pull-right">BOBOT PEKERJAAN : 50%</strong></b-button>
                                          </b-card-header>
                                          <b-collapse id="job-1-1" accordion="my-accordion-sub" role="tabpanel">
                                            <table class="table table-striped mt-2">
                                              <tbody>
                                              <tr>
                                                <th width="20%">Start Date</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-10</td>
                                                <th width="20%">Est. Finish</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-15</td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Bobot Pekerjaan</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">50%</td>
                                                <th width="20%">Dokumen</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">
                                                  <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  </ul>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Created By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Nurul Akbar</td>
                                                <th width="20%">Review By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Agung Tri Laksono</td>
                                              </tr>
                                              <tr>
                                                <th class="align-top">Notes</th>
                                                <td class="text-center align-top">:</td>
                                                <td colspan="4">
                                                  Check water tubes/plates and clean/free from clogged.<br>
                                                  Check  from any corrosion <br>
                                                  Check  zink anodes,  replace when necessary<br>
                                                  Check  leakage after assembly
                                                </td>
                                              </tr>
                                              <tr>
                                                <th class="align-top">Internal Man Power</th>
                                                <td class="text-center align-top">:</td>
                                                <td colspan="4">
                                                  <h5 class="mt-1"><b-badge variant="info">Agung Tri Laksono - Port Captain</b-badge></h5>
                                                  <h5 class="mt-1"><b-badge variant="info">Nurul Akbar - Port Engineer</b-badge></h5>
                                                  <h5 class="mt-1"><b-badge variant="info">Tri Leksono - Electrical Engineer</b-badge></h5>
                                                </td>
                                              </tr>
                                              </tbody>
                                            </table>
                                            <b-alert :show="true" variant="success">
                                              <div class="iq-alert-icon">
                                                <i class="fa fa-thumbs-up"></i>
                                              </div>
                                              <div class="iq-alert-text">Docking Upslip <b>APPROVED</b></div>
                                            </b-alert>
                                            <b-alert :show="true" variant="danger">
                                              <div class="iq-alert-icon">
                                                <i class="fa fa-close"></i>
                                              </div>
                                              <div class="iq-alert-text">Docking Upslip <b>REJECT</b></div>
                                            </b-alert>
                                            <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>
                                            <b-button type="submit" variant="primary" class="float-right"><i class="fa fa-thumbs-up"></i> APPROVE</b-button>
                                          </b-collapse>
                                        </b-card>
                                        <b-card no-body >
                                          <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                            <b-button block v-b-toggle.job-1-2 variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i> Down Slip <strong class="pull-right">BOBOT PEKERJAAN : 50%</strong></b-button>
                                          </b-card-header>
                                          <b-collapse id="job-1-2" accordion="my-accordion-sub" role="tabpanel">
                                            <table class="table table-striped mt-2">
                                              <tbody>
                                              <tr>
                                                <th width="20%">Start Date</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-10</td>
                                                <th width="20%">Est. Finish</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-15</td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Bobot Pekerjaan</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">50%</td>
                                                <th width="20%">Dokumen</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">
                                                  <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  </ul>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Created By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Nurul Akbar</td>
                                                <th width="20%">Review By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Agung Tri Laksono</td>
                                              </tr>
                                              <tr>
                                                <th class="align-top">Notes</th>
                                                <td class="text-center align-top">:</td>
                                                <td colspan="4">
                                                  Clean motor housing including stator and rotor<br>
                                                  Check  bearing,  replace when  necessary<br>
                                                  Check  cable installations and connections<br>
                                                  Check  foundation and tighten all bolts
                                                </td>
                                              </tr>
                                              </tbody>
                                            </table>
                                            <fieldset class="form-group border p-3">
                                              <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                              <table class="table table-striped">
                                                <tbody>
                                                <tr>
                                                  <th width="20%">Nama Kontraktor </th>
                                                  <td width="5%" class="text-center">:</td>
                                                  <td width="25%">PT. CAHAYA PELAYARAN</td>
                                                  <th width="20%">Executive Engineer</th>
                                                  <td width="5%" class="text-center">:</td>
                                                  <td width="25%">Agung Tri Laksono</td>
                                                </tr>
                                                <tr>
                                                  <th width="20%">Penawaran</th>
                                                  <td width="5%" class="text-center">:</td>
                                                  <td width="25%">328.000.000</td>
                                                  <th width="20%">Penawaran Nego</th>
                                                  <td width="5%" class="text-center">:</td>
                                                  <td width="25%">325.000.000</td>
                                                </tr>
                                                <tr>
                                                  <th width="20%">Notes</th>
                                                  <td width="5%" class="text-center">:</td>
                                                  <td width="25%">Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan</td>
                                                  <th width="20%">Document</th>
                                                  <td width="5%" class="text-center">:</td>
                                                  <td width="25%">
                                                    <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                      <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                      <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                      <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                      <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    </ul>
                                                  </td>
                                                </tr>
                                                </tbody>
                                              </table>
                                            </fieldset>
                                            <b-alert :show="true" variant="success">
                                              <div class="iq-alert-icon">
                                                <i class="fa fa-thumbs-up"></i>
                                              </div>
                                              <div class="iq-alert-text">Docking Upslip <b>APPROVED</b></div>
                                            </b-alert>
                                            <b-alert :show="true" variant="danger">
                                              <div class="iq-alert-icon">
                                                <i class="fa fa-close"></i>
                                              </div>
                                              <div class="iq-alert-text">Docking Upslip <b>REJECT</b></div>
                                            </b-alert>
                                            <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>
                                            <b-button type="submit" variant="primary" class="float-right"><i class="fa fa-thumbs-up"></i> APPROVE</b-button>
                                          </b-collapse>
                                        </b-card>
                                      </div>
                                    </b-card-body>
                                  </b-collapse>
                                </b-card>
                              </div>
                              <!-- DOCKING JOB END -->

                              <!-- DOCKING JOB -->
                              <div class="accordion" role="tablist">
                                <b-card no-body >
                                  <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none">
                                    <b-button block v-b-toggle.jobCat-2 variant="primary" class="text-left"><i class="fa fa-chevron-circle-down"></i>SAND BLASTING<strong class="pull-right">BOBOT PEKERJAAN : 70%</strong></b-button>
                                  </b-card-header>

                                  <b-collapse id="jobCat-2" accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                      <!-- BOBOT DOCKING JOB -->
                                      <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-info rounded">
                                        <template v-slot:body >
                                          <div class="d-flex align-items-center justify-content-between">
                                            <div class="rounded-circle iq-card-icon bg-info"><i class="fa fa-percent"></i></div>
                                            <div class="text-right">
                                              <h2 class="mb-0"><span class="counter">100%</span></h2>
                                              <h5 class="">Persentase Docking Job</h5>
                                            </div>
                                          </div>
                                        </template>
                                      </iq-card>
                                      <!-- BOBOT DOCKING JOB END -->
                                      <div class="accordion" role="tablist">
                                        <b-card no-body >
                                          <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                            <b-button block v-b-toggle.job-2-1 variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i> Scraping teritip di lambung dan lunas<strong class="pull-right">BOBOT PEKERJAAN : 30%</strong></b-button>
                                          </b-card-header>
                                          <b-collapse id="job-2-1" accordion="my-accordion-sub" role="tabpanel">
                                            <table class="table table-striped mt-2">
                                              <tbody>
                                              <tr>
                                                <th width="20%">Start Date</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-10</td>
                                                <th width="20%">Est. Finish</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-15</td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Bobot Pekerjaan</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">50%</td>
                                                <th width="20%">Dokumen</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">
                                                  <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  </ul>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Created By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Nurul Akbar</td>
                                                <th width="20%">Review By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Agung Tri Laksono</td>
                                              </tr>
                                              <tr>
                                                <th class="align-top">Notes</th>
                                                <td class="text-center align-top">:</td>
                                                <td colspan="4">
                                                  Clean motor housing including stator and rotor<br>
                                                  Check  bearing,  replace when  necessary<br>
                                                  Check  cable installations and connections<br>
                                                  Check  foundation and tighten all bolts
                                                </td>
                                              </tr>
                                              </tbody>
                                            </table>
                                            <b-alert :show="true" variant="success">
                                              <div class="iq-alert-icon">
                                                <i class="fa fa-thumbs-up"></i>
                                              </div>
                                              <div class="iq-alert-text">Docking Upslip <b>APPROVED</b></div>
                                            </b-alert>
                                            <b-alert :show="true" variant="danger">
                                              <div class="iq-alert-icon">
                                                <i class="fa fa-close"></i>
                                              </div>
                                              <div class="iq-alert-text">Docking Upslip <b>REJECT</b></div>
                                            </b-alert>
                                            <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>
                                            <b-button type="submit" variant="primary" class="float-right"><i class="fa fa-thumbs-up"></i> APPROVE</b-button>
                                          </b-collapse>
                                        </b-card>
                                        <b-card no-body >
                                          <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                            <b-button block v-b-toggle.job-2-2 variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i> Pencucian lambung dengan air tawar<strong class="pull-right">BOBOT PEKERJAAN : 10%</strong></b-button>
                                          </b-card-header>
                                          <b-collapse id="job-2-2" accordion="my-accordion-sub" role="tabpanel">
                                            <table class="table table-striped mt-2">
                                              <tbody>
                                              <tr>
                                                <th width="20%">Start Date</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-10</td>
                                                <th width="20%">Est. Finish</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-15</td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Bobot Pekerjaan</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">50%</td>
                                                <th width="20%">Dokumen</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">
                                                  <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  </ul>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Created By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Nurul Akbar</td>
                                                <th width="20%">Review By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Agung Tri Laksono</td>
                                              </tr>
                                              <tr>
                                                <th class="align-top">Notes</th>
                                                <td class="text-center align-top">:</td>
                                                <td colspan="4">
                                                  Clean motor housing including stator and rotor<br>
                                                  Check  bearing,  replace when  necessary<br>
                                                  Check  cable installations and connections<br>
                                                  Check  foundation and tighten all bolts
                                                </td>
                                              </tr>
                                              </tbody>
                                            </table>
                                            <b-alert :show="true" variant="success">
                                              <div class="iq-alert-icon">
                                                <i class="fa fa-thumbs-up"></i>
                                              </div>
                                              <div class="iq-alert-text">Docking Upslip <b>APPROVED</b></div>
                                            </b-alert>
                                            <b-alert :show="true" variant="danger">
                                              <div class="iq-alert-icon">
                                                <i class="fa fa-close"></i>
                                              </div>
                                              <div class="iq-alert-text">Docking Upslip <b>REJECT</b></div>
                                            </b-alert>
                                            <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>
                                            <b-button type="submit" variant="primary" class="float-right"><i class="fa fa-thumbs-up"></i> APPROVE</b-button>
                                          </b-collapse>
                                        </b-card>
                                        <b-card no-body >
                                          <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                            <b-button block v-b-toggle.job-2-3 variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i> Sand blasting lunas<strong class="pull-right">BOBOT PEKERJAAN : 10%</strong></b-button>
                                          </b-card-header>
                                          <b-collapse id="job-2-3" accordion="my-accordion-sub" role="tabpanel">
                                            <table class="table table-striped mt-2">
                                              <tbody>
                                              <tr>
                                                <th width="20%">Start Date</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-10</td>
                                                <th width="20%">Est. Finish</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-15</td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Bobot Pekerjaan</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">50%</td>
                                                <th width="20%">Dokumen</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">
                                                  <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  </ul>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Created By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Nurul Akbar</td>
                                                <th width="20%">Review By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Agung Tri Laksono</td>
                                              </tr>
                                              <tr>
                                                <th class="align-top">Notes</th>
                                                <td class="text-center align-top">:</td>
                                                <td colspan="4">
                                                  Clean motor housing including stator and rotor<br>
                                                  Check  bearing,  replace when  necessary<br>
                                                  Check  cable installations and connections<br>
                                                  Check  foundation and tighten all bolts
                                                </td>
                                              </tr>
                                              </tbody>
                                            </table>
                                            <b-alert :show="true" variant="success">
                                              <div class="iq-alert-icon">
                                                <i class="fa fa-thumbs-up"></i>
                                              </div>
                                              <div class="iq-alert-text">Docking Upslip <b>APPROVED</b></div>
                                            </b-alert>
                                            <b-alert :show="true" variant="danger">
                                              <div class="iq-alert-icon">
                                                <i class="fa fa-close"></i>
                                              </div>
                                              <div class="iq-alert-text">Docking Upslip <b>REJECT</b></div>
                                            </b-alert>
                                            <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>
                                            <b-button type="submit" variant="primary" class="float-right"><i class="fa fa-thumbs-up"></i> APPROVE</b-button>
                                          </b-collapse>
                                        </b-card>
                                        <b-card no-body >
                                          <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                            <b-button block v-b-toggle.job-2-4 variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i> Sand blasting lambung<strong class="pull-right">BOBOT PEKERJAAN : 40%</strong></b-button>
                                          </b-card-header>
                                          <b-collapse id="job-2-4" accordion="my-accordion-sub" role="tabpanel">
                                            <table class="table table-striped mt-2">
                                              <tbody>
                                              <tr>
                                                <th width="20%">Start Date</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-10</td>
                                                <th width="20%">Est. Finish</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-15</td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Bobot Pekerjaan</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">50%</td>
                                                <th width="20%">Dokumen</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">
                                                  <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  </ul>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Created By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Nurul Akbar</td>
                                                <th width="20%">Review By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Agung Tri Laksono</td>
                                              </tr>
                                              <tr>
                                                <th class="align-top">Notes</th>
                                                <td class="text-center align-top">:</td>
                                                <td colspan="4">
                                                  Clean motor housing including stator and rotor<br>
                                                  Check  bearing,  replace when  necessary<br>
                                                  Check  cable installations and connections<br>
                                                  Check  foundation and tighten all bolts
                                                </td>
                                              </tr>
                                              </tbody>
                                            </table>
                                            <b-alert :show="true" variant="success">
                                              <div class="iq-alert-icon">
                                                <i class="fa fa-thumbs-up"></i>
                                              </div>
                                              <div class="iq-alert-text">Docking Upslip <b>APPROVED</b></div>
                                            </b-alert>
                                            <b-alert :show="true" variant="danger">
                                              <div class="iq-alert-icon">
                                                <i class="fa fa-close"></i>
                                              </div>
                                              <div class="iq-alert-text">Docking Upslip <b>REJECT</b></div>
                                            </b-alert>
                                            <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>
                                            <b-button type="submit" variant="primary" class="float-right"><i class="fa fa-thumbs-up"></i> APPROVE</b-button>
                                          </b-collapse>
                                        </b-card>
                                        <b-card no-body >
                                          <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                            <b-button block v-b-toggle.job-2-5 variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i> Sand blasting deck dan bulwark<strong class="pull-right">BOBOT PEKERJAAN : 10%</strong></b-button>
                                          </b-card-header>
                                          <b-collapse id="job-2-5" accordion="my-accordion-sub" role="tabpanel">
                                            <table class="table table-striped mt-2">
                                              <tbody>
                                              <tr>
                                                <th width="20%">Start Date</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-10</td>
                                                <th width="20%">Est. Finish</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-15</td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Bobot Pekerjaan</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">50%</td>
                                                <th width="20%">Dokumen</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">
                                                  <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  </ul>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Created By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Nurul Akbar</td>
                                                <th width="20%">Review By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Agung Tri Laksono</td>
                                              </tr>
                                              <tr>
                                                <th class="align-top">Notes</th>
                                                <td class="text-center align-top">:</td>
                                                <td colspan="4">
                                                  Clean motor housing including stator and rotor<br>
                                                  Check  bearing,  replace when  necessary<br>
                                                  Check  cable installations and connections<br>
                                                  Check  foundation and tighten all bolts
                                                </td>
                                              </tr>
                                              </tbody>
                                            </table>
                                            <b-alert :show="true" variant="success">
                                              <div class="iq-alert-icon">
                                                <i class="fa fa-thumbs-up"></i>
                                              </div>
                                              <div class="iq-alert-text">Docking Upslip <b>APPROVED</b></div>
                                            </b-alert>
                                            <b-alert :show="true" variant="danger">
                                              <div class="iq-alert-icon">
                                                <i class="fa fa-close"></i>
                                              </div>
                                              <div class="iq-alert-text">Docking Upslip <b>REJECT</b></div>
                                            </b-alert>
                                            <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>
                                            <b-button type="submit" variant="primary" class="float-right"><i class="fa fa-thumbs-up"></i> APPROVE</b-button>
                                          </b-collapse>
                                        </b-card>
                                      </div>
                                    </b-card-body>
                                  </b-collapse>
                                </b-card>
                              </div>
                              <!-- DOCKING JOB END -->

                              <!-- DOCKING JOB -->
                              <div class="accordion" role="tablist">
                                <b-card no-body >
                                  <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none">
                                    <b-button block v-b-toggle.jobCat-3 variant="primary" class="text-left"><i class="fa fa-chevron-circle-down"></i>MACHINERY EQUIPMENT<strong class="pull-right">BOBOT PEKERJAAN : 30%</strong></b-button>
                                  </b-card-header>

                                  <b-collapse id="jobCat-3" accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                      <!-- BOBOT DOCKING JOB -->
                                      <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-info rounded">
                                        <template v-slot:body >
                                          <div class="d-flex align-items-center justify-content-between">
                                            <div class="rounded-circle iq-card-icon bg-info"><i class="fa fa-percent"></i></div>
                                            <div class="text-right">
                                              <h2 class="mb-0"><span class="counter">100%</span></h2>
                                              <h5 class="">Persentase Docking Job</h5>
                                            </div>
                                          </div>
                                        </template>
                                      </iq-card>
                                      <!-- BOBOT DOCKING JOB END -->
                                      <div class="accordion" role="tablist">
                                        <b-card no-body >
                                          <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                            <b-button block v-b-toggle.job-3-1 variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i> General Overhaul ME (P/S)<strong class="pull-right">BOBOT PEKERJAAN : 30%</strong></b-button>
                                          </b-card-header>
                                          <b-collapse id="job-3-1" accordion="my-accordion-sub" role="tabpanel">
                                            <table class="table table-striped mt-2">
                                              <tbody>
                                              <tr>
                                                <th width="20%">Start Date</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-10</td>
                                                <th width="20%">Est. Finish</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-15</td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Bobot Pekerjaan</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">50%</td>
                                                <th width="20%">Dokumen</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">
                                                  <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  </ul>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Created By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Nurul Akbar</td>
                                                <th width="20%">Review By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Agung Tri Laksono</td>
                                              </tr>
                                              <tr>
                                                <th class="align-top">Notes</th>
                                                <td class="text-center align-top">:</td>
                                                <td colspan="4">
                                                  Clean motor housing including stator and rotor<br>
                                                  Check  bearing,  replace when  necessary<br>
                                                  Check  cable installations and connections<br>
                                                  Check  foundation and tighten all bolts
                                                </td>
                                              </tr>
                                              </tbody>
                                            </table>
                                            <b-alert :show="true" variant="success">
                                              <div class="iq-alert-icon">
                                                <i class="fa fa-thumbs-up"></i>
                                              </div>
                                              <div class="iq-alert-text">Docking Upslip <b>APPROVED</b></div>
                                            </b-alert>
                                            <b-alert :show="true" variant="danger">
                                              <div class="iq-alert-icon">
                                                <i class="fa fa-close"></i>
                                              </div>
                                              <div class="iq-alert-text">Docking Upslip <b>REJECT</b></div>
                                            </b-alert>
                                            <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>
                                            <b-button type="submit" variant="primary" class="float-right"><i class="fa fa-thumbs-up"></i> APPROVE</b-button>
                                          </b-collapse>
                                        </b-card>
                                        <b-card no-body >
                                          <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                            <b-button block v-b-toggle.job-3-2 variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i> Kalibrasi injector (P/S)<strong class="pull-right">BOBOT PEKERJAAN : 10%</strong></b-button>
                                          </b-card-header>
                                          <b-collapse id="job-3-2" accordion="my-accordion-sub" role="tabpanel">
                                            <table class="table table-striped mt-2">
                                              <tbody>
                                              <tr>
                                                <th width="20%">Start Date</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-10</td>
                                                <th width="20%">Est. Finish</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-15</td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Bobot Pekerjaan</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">50%</td>
                                                <th width="20%">Dokumen</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">
                                                  <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  </ul>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Created By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Nurul Akbar</td>
                                                <th width="20%">Review By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Agung Tri Laksono</td>
                                              </tr>
                                              <tr>
                                                <th class="align-top">Notes</th>
                                                <td class="text-center align-top">:</td>
                                                <td colspan="4">
                                                  Clean motor housing including stator and rotor<br>
                                                  Check  bearing,  replace when  necessary<br>
                                                  Check  cable installations and connections<br>
                                                  Check  foundation and tighten all bolts
                                                </td>
                                              </tr>
                                              </tbody>
                                            </table>
                                            <b-alert :show="true" variant="success">
                                              <div class="iq-alert-icon">
                                                <i class="fa fa-thumbs-up"></i>
                                              </div>
                                              <div class="iq-alert-text">Docking Upslip <b>APPROVED</b></div>
                                            </b-alert>
                                            <b-alert :show="true" variant="danger">
                                              <div class="iq-alert-icon">
                                                <i class="fa fa-close"></i>
                                              </div>
                                              <div class="iq-alert-text">Docking Upslip <b>REJECT</b></div>
                                            </b-alert>
                                            <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>
                                            <b-button type="submit" variant="primary" class="float-right"><i class="fa fa-thumbs-up"></i> APPROVE</b-button>
                                          </b-collapse>
                                        </b-card>
                                        <b-card no-body >
                                          <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                            <b-button block v-b-toggle.job-3-3 variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i> Service/ganti baru dynamo starter (P/S)<strong class="pull-right">BOBOT PEKERJAAN : 10%</strong></b-button>
                                          </b-card-header>
                                          <b-collapse id="job-3-3" accordion="my-accordion-sub" role="tabpanel">
                                            <table class="table table-striped mt-2">
                                              <tbody>
                                              <tr>
                                                <th width="20%">Start Date</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-10</td>
                                                <th width="20%">Est. Finish</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-15</td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Bobot Pekerjaan</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">50%</td>
                                                <th width="20%">Dokumen</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">
                                                  <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  </ul>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Created By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Nurul Akbar</td>
                                                <th width="20%">Review By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Agung Tri Laksono</td>
                                              </tr>
                                              <tr>
                                                <th class="align-top">Notes</th>
                                                <td class="text-center align-top">:</td>
                                                <td colspan="4">
                                                  Clean motor housing including stator and rotor<br>
                                                  Check  bearing,  replace when  necessary<br>
                                                  Check  cable installations and connections<br>
                                                  Check  foundation and tighten all bolts
                                                </td>
                                              </tr>
                                              </tbody>
                                            </table>
                                            <b-alert :show="true" variant="success">
                                              <div class="iq-alert-icon">
                                                <i class="fa fa-thumbs-up"></i>
                                              </div>
                                              <div class="iq-alert-text">Docking Upslip <b>APPROVED</b></div>
                                            </b-alert>
                                            <b-alert :show="true" variant="danger">
                                              <div class="iq-alert-icon">
                                                <i class="fa fa-close"></i>
                                              </div>
                                              <div class="iq-alert-text">Docking Upslip <b>REJECT</b></div>
                                            </b-alert>
                                            <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>
                                            <b-button type="submit" variant="primary" class="float-right"><i class="fa fa-thumbs-up"></i> APPROVE</b-button>
                                          </b-collapse>
                                        </b-card>
                                        <b-card no-body >
                                          <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                            <b-button block v-b-toggle.job-3-4 variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i> Service/ganti baru alternator (P/S)<strong class="pull-right">BOBOT PEKERJAAN : 40%</strong></b-button>
                                          </b-card-header>
                                          <b-collapse id="job-3-4" accordion="my-accordion-sub" role="tabpanel">
                                            <table class="table table-striped mt-2">
                                              <tbody>
                                              <tr>
                                                <th width="20%">Start Date</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-10</td>
                                                <th width="20%">Est. Finish</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-15</td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Bobot Pekerjaan</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">50%</td>
                                                <th width="20%">Dokumen</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">
                                                  <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  </ul>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Created By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Nurul Akbar</td>
                                                <th width="20%">Review By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Agung Tri Laksono</td>
                                              </tr>
                                              <tr>
                                                <th class="align-top">Notes</th>
                                                <td class="text-center align-top">:</td>
                                                <td colspan="4">
                                                  Clean motor housing including stator and rotor<br>
                                                  Check  bearing,  replace when  necessary<br>
                                                  Check  cable installations and connections<br>
                                                  Check  foundation and tighten all bolts
                                                </td>
                                              </tr>
                                              </tbody>
                                            </table>
                                            <b-alert :show="true" variant="success">
                                              <div class="iq-alert-icon">
                                                <i class="fa fa-thumbs-up"></i>
                                              </div>
                                              <div class="iq-alert-text">Docking Upslip <b>APPROVED</b></div>
                                            </b-alert>
                                            <b-alert :show="true" variant="danger">
                                              <div class="iq-alert-icon">
                                                <i class="fa fa-close"></i>
                                              </div>
                                              <div class="iq-alert-text">Docking Upslip <b>REJECT</b></div>
                                            </b-alert>
                                            <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>
                                            <b-button type="submit" variant="primary" class="float-right"><i class="fa fa-thumbs-up"></i> APPROVE</b-button>
                                          </b-collapse>
                                        </b-card>
                                        <b-card no-body >
                                          <b-card-header header-tag="header" class="p-1" role="tab" style="background-color:white; border:none" >
                                            <b-button block v-b-toggle.job-3-5 variant="info" class="text-left"><i class="fa fa-chevron-circle-down"></i> Service turbocharger<strong class="pull-right">BOBOT PEKERJAAN : 10%</strong></b-button>
                                          </b-card-header>
                                          <b-collapse id="job-3-5" accordion="my-accordion-sub" role="tabpanel">
                                            <table class="table table-striped mt-2">
                                              <tbody>
                                              <tr>
                                                <th width="20%">Start Date</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-10</td>
                                                <th width="20%">Est. Finish</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">2022-06-15</td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Bobot Pekerjaan</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">50%</td>
                                                <th width="20%">Dokumen</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">
                                                  <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                                    <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                                  </ul>
                                                </td>
                                              </tr>
                                              <tr>
                                                <th width="20%">Created By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Nurul Akbar</td>
                                                <th width="20%">Review By</th>
                                                <td width="5%" class="text-center">:</td>
                                                <td width="25%">Agung Tri Laksono</td>
                                              </tr>
                                              <tr>
                                                <th class="align-top">Notes</th>
                                                <td class="text-center align-top">:</td>
                                                <td colspan="4">
                                                  Clean motor housing including stator and rotor<br>
                                                  Check  bearing,  replace when  necessary<br>
                                                  Check  cable installations and connections<br>
                                                  Check  foundation and tighten all bolts
                                                </td>
                                              </tr>
                                              </tbody>
                                            </table>
                                            <b-alert :show="true" variant="success">
                                              <div class="iq-alert-icon">
                                                <i class="fa fa-thumbs-up"></i>
                                              </div>
                                              <div class="iq-alert-text">Docking Upslip <b>APPROVED</b></div>
                                            </b-alert>
                                            <b-alert :show="true" variant="danger">
                                              <div class="iq-alert-icon">
                                                <i class="fa fa-close"></i>
                                              </div>
                                              <div class="iq-alert-text">Docking Upslip <b>REJECT</b></div>
                                            </b-alert>
                                            <b-button v-b-modal.reason_reject variant="none" class="iq-bg-danger float-right mb-3 ml-1"><i class="fa fa-close"></i> REJECT</b-button>
                                            <b-button type="submit" variant="primary" class="float-right"><i class="fa fa-thumbs-up"></i> APPROVE</b-button>
                                          </b-collapse>
                                        </b-card>
                                      </div>
                                    </b-card-body>
                                  </b-collapse>
                                </b-card>
                              </div>
                              <!-- DOCKING JOB END -->
                              <b-row>
                                <b-col md="12" class="mt-3">
                                  <b-card-text class="pull-right"><small class="text-muted">Sign * : <b-badge variant="warning">Mandatory (Must be Input)</b-badge></small></b-card-text>
                                  <br><br>
                                  <b-button type="submit" variant="success" class="pull-right mr-1"><i class="fa fa-save"></i> Save</b-button>
                                </b-col>
                              </b-row>
                            </b-col>
                          </b-row>
                        </fieldset>
                      </b-col>
                    </b-row>
                  </template>
                </b-sidebar>
                <!-- SLIDE - FORM APPROVE DOCKING END -->

                <!-- SLIDE - ACTIVITY -->
                <b-sidebar
                  v-model="slideActivity"
                  id="activity"
                  title="UPDATE ACTIVITY"
                  width="50%"
                  backdrop-variant="dark"
                  bg-variant="white"
                  right
                  backdrop
                  shadow>
                  <template #default="{hide}">
                    <b-row class="m-3">
                      <b-col md="12">
                        <tab-content-item :active="true" id="power">
                          <tab-nav :tabs="true">
                            <tab-nav-items :active="true"  href="#activity" title="Activity" />
                            <tab-nav-items :active="false" href="#logs" title="Logs" />
                          </tab-nav>
                          <div id="myTabContent" class="tab-content">
                            <tab-content-item :active="true" id="activity">
                              <b-row>
                                <b-col md="12" class="mb-5">
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label="Select Docking Job" label-for="docking_job">
                                        <b-form-select plain v-model="selectedDockingJob" :options="docking_job">
                                          <template v-slot:first>
                                            <b-form-select-option :value="null">List Docking Job</b-form-select-option>
                                          </template>
                                        </b-form-select>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label-for="progress_bar"><h4 class="mb-2">Progrees : <b-badge variant="warning">80%</b-badge></h4>
                                        <b-progress :value="80" variant="success" max="100"></b-progress>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label="Start *" label-for="start">
                                        <b-form-input type="date" id="start"></b-form-input>
                                        <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="Progress Pekerjaan *" label-for="progress_pekerjaan">
                                        <div class="input-group">
                                          <div class="input-group-prepend">
                                            <span class="input-group-text bg-primary" id="progress_pekerjaan">%</span>
                                          </div>
                                          <input type="text" class="form-control" id="progress_pekerjaan" placeholder="80">
                                        </div>
                                      </b-form-group>
                                    </b-col>
                                    <b-col>
                                      <b-form-group label="Report Progress" label-for="report_progress">
                                        <div class="custom-file">
                                          <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                          <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                        </div>
                                        <small class="text-muted">
                                          File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                          File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                        </small>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                  <b-form-row>
                                    <b-col>
                                      <b-form-group label="Notes" label-for="notes">
                                        <b-form-textarea id="description" rows="3" placeholder="Check water tubes/plates and clean/free from clogged."></b-form-textarea>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>

                                  <div class="form-group row align-items-center mt-3 m-0">
                                    <div class="d-flex flex-grow-1 align-items-center">
                                      <div class="send-panel">
                                        <label for="file" class="mb-0 iq-bg-primary rounded">
                                          <input type="file" id="file" style="display: none;">
                                          <a>
                                            <i class="ri-attachment-line"></i>
                                          </a>
                                        </label>
                                        <label class="ml-2 mb-0 iq-bg-primary rounded">
                                          <a href="javascript:void(0)">
                                            <i class="ri-gallery-line text-primary"></i>
                                          </a>
                                        </label>
                                      </div>
                                    </div>
                                    <div class="d-flex align-items-center">
                                      <div class="send-panel float-left">
                                        <div class="send-btn">
                                          <b-button variant="success"><i class="fa fa-check"></i> Finish</b-button>
                                          <b-button variant="primary" class="ml-2"><i class="fa fa-comment"></i> Update Activity</b-button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </b-col>
                                <b-col md="12">
                                  <ul class="iq-timeline" style="height:100%">
                                    <li>
                                      <div class="timeline-dots border-success"></div>
                                      <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                      <small>24 November 2019 15:45</small>
                                      <div class="d-inline-block w-100">
                                        <p>Melakukan inspeksi pada kerusakan</p>
                                        <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                          <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/6.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                          <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/1.png" alt="gallary-image" class="img-fluid"></a></li>
                                          <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                          <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                        </ul>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-dots border-success"></div>
                                      <h6 class="float-left mb-1">Agung Tri Laksono</h6><br/>
                                      <small>23 November 2019 21:25</small>
                                      <div class="d-inline-block w-100">
                                        <p>Barang tiba di galangan</p>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-dots border-success"></div>
                                      <h6 class="float-left mb-1">Tri Leksono</h6><br/>
                                      <small>22 November 2019 10:12</small>
                                      <div class="d-inline-block w-100">
                                        <p>Menunggu sparepart tiba</p>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-dots border-success"></div>
                                      <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                      <small>21 November 2019 11:21</small>
                                      <div class="d-inline-block w-100">
                                        <p>Melengkapi dokumen perbaikan</p>
                                        <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                          <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                          <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                          <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                          <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                        </ul>
                                      </div>
                                    </li>
                                  </ul>
                                </b-col>
                              </b-row>
                            </tab-content-item>

                            <tab-content-item :active="false" id="logs">
                              <b-row>
                                <b-col md="12">
                                  <ul class="iq-timeline" style="height:100%">
                                    <li>
                                      <div class="timeline-dots border-success"></div>
                                      <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                      <small>19 Oktober 2019 21:25</small>
                                      <div class="d-inline-block w-100">
                                        <p>Docking Upslip - Finish</p>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-dots border-success"></div>
                                      <h6 class="float-left mb-1">System</h6><br/>
                                      <small>19 Oktober 2019 18:30</small>
                                      <div class="d-inline-block w-100">
                                        <p>System mengirim email ke :
                                          <b-badge class="border border-primary text-primary ml-1" variant="none">aguuungtriilaksono@gmail.com</b-badge>
                                          <b-badge class="border border-primary text-primary ml-1" variant="none">nurulakbar93@gmail.com</b-badge>
                                        </p>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-dots border-success"></div>
                                      <h6 class="float-left mb-1">Agung Tri Laksono</h6><br/>
                                      <small>18 Oktober 2019 15:41</small>
                                      <div class="d-inline-block w-100">
                                        <p>Update Stage</p>
                                        <b-button variant="primary" class="mr-1">APPROVE</b-button>
                                        <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
                                        <b-button variant="info" class="ml-1">PROGRESS</b-button>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-dots border-success"></div>
                                      <h6 class="float-left mb-1">System</h6><br/>
                                      <small>19 Oktober 2019 18:30</small>
                                      <div class="d-inline-block w-100">
                                        <p>System mengirim email ke :
                                          <b-badge class="border border-primary text-primary ml-1" variant="none">aguuungtriilaksono@gmail.com</b-badge>
                                          <b-badge class="border border-primary text-primary ml-1" variant="none">nurulakbar93@gmail.com</b-badge>
                                        </p>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-dots border-success"></div>
                                      <h6 class="float-left mb-1">Agung Tri Laksono</h6><br/>
                                      <small>18 Oktober 2019 15:41</small>
                                      <div class="d-inline-block w-100">
                                        <p>Update Stage</p>
                                        <b-button variant="warning" class="mr-1">REVIEW</b-button>
                                        <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
                                        <b-button variant="primary" class="ml-1">APPROVE</b-button>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-dots border-success"></div>
                                      <h6 class="float-left mb-1">System</h6><br/>
                                      <small>19 Oktober 2019 18:30</small>
                                      <div class="d-inline-block w-100">
                                        <p>System mengirim email ke :
                                          <b-badge class="border border-primary text-primary ml-1" variant="none">aguuungtriilaksono@gmail.com</b-badge>
                                          <b-badge class="border border-primary text-primary ml-1" variant="none">nurulakbar93@gmail.com</b-badge>
                                        </p>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-dots border-success"></div>
                                      <h6 class="float-left mb-1">Agung Tri Laksono</h6><br/>
                                      <small>18 Oktober 2019 15:41</small>
                                      <div class="d-inline-block w-100">
                                        <p>Update Stage</p>
                                        <b-button variant="warning" class="mr-1">REVIEW</b-button>
                                        <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
                                        <b-button variant="danger" class="ml-1">REJECT</b-button>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-dots border-success"></div>
                                      <h6 class="float-left mb-1">System</h6><br/>
                                      <small>17 Oktober 2019 16:00</small>
                                      <div class="d-inline-block w-100">
                                        <p>System mengirim email ke :
                                          <b-badge class="border border-primary text-primary ml-1" variant="none">aguuungtriilaksono@gmail.com</b-badge>
                                          <b-badge class="border border-primary text-primary ml-1" variant="none">nurulakbar93@gmail.com</b-badge>
                                        </p>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-dots border-success"></div>
                                      <h6 class="float-left mb-1">Agung Tri Laksono</h6><br/>
                                      <small>16 Oktober 2019 18:30</small>
                                      <div class="d-inline-block w-100">
                                        <p>Update Stage</p>
                                        <b-button variant="light" class="mr-1">CREATE</b-button>
                                        <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
                                        <b-button variant="warning" class="ml-1">REVIEW</b-button>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-dots border-success"></div>
                                      <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                      <small>15 Oktober 2019 20:24</small>
                                      <div class="d-inline-block w-100">
                                        <p>Mengupdate Job Upslip</p>
                                      </div>
                                    </li>
                                    <li>
                                      <div class="timeline-dots border-success"></div>
                                      <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                      <small>15 Oktober 2019 16:00</small>
                                      <div class="d-inline-block w-100">
                                        <p>Membuat Job Upslip</p>
                                      </div>
                                    </li>
                                  </ul>
                                </b-col>
                              </b-row>
                            </tab-content-item>
                          </div>
                        </tab-content-item>
                      </b-col>
                    </b-row>
                  </template>
                </b-sidebar>
                <!-- SLIDE - ACTIVITY END -->
              </template>
            </iq-card>
          </tab-content-item>

          <tab-content-item :active="false" id="finish">
            <DockingDashboardFinish />
          </tab-content-item>
        </div>
      </div>
    </b-col>
  </b-row>
</b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import _ from 'lodash'
import {
  fleetsActions
} from '@src/Utils/helper'
import { createPopper } from '@popperjs/core'
import DockingDashboardSchedule from '@components/Docking/DockingDashboardSchedule'
import DockingDashboardProgress from '@components/Docking/DockingDashboardProgress'
import DockingDashboardFinish from '@components/Docking/DockingDashboardFinish'

export default {
  name: 'ProfileEdit',
  components: { DockingDashboardProgress, DockingDashboardSchedule, DockingDashboardFinish },
  mounted () {
    xray.index()
    this.fetchFleets()
  },
  data () {
    return {
      // fleet
      globalRefresh:false,
      showInitModal: false,
      modalCreateDocking: false,
      slideUpdateDocking: false,
      slideReviewDocking: false,
      slideApproveDocking: false,
      slideActivity: false,
      slideFinishDocking: false,
      modalDocking: false,

      selectedDockingType: null,
      docking_type: [
        { value: '1', text: 'Intermediate Survey' },
        { value: '2', text: 'Special Survey' },
        { value: '3', text: 'Emergency Docking' }
      ],

      selectedDockingJob: null,
      docking_job: [
        { value: '1', text: 'Up slip' },
        { value: '2', text: 'Downslip' },
        { value: '3', text: 'Scraping teritip di lambung dan lunas' },
        { value: '4', text: 'Pencucian lambung dengan air tawar teritip di lambung dan lunas' },
        { value: '5', text: 'Sand blasting lunas' },
        { value: '6', text: 'Sand blasting lambung' },
        { value: '6', text: 'Sand blasting deck dan bulwark' },
        { value: '6', text: 'General Overhazul ME (P/S)' },
        { value: '6', text: 'Kalibrasi injector (P/S)' },
        { value: '6', text: 'Service/ganti baru dynamo starter (P/S)' },
      ],

      selectedPIC: null,
      pic: [
        { value: '1', text: 'Nurul Akbar' },
        { value: '2', text: 'Agung Tri Laksono' },
        { value: '3', text: 'Hanel Prillian Chozzy' }
      ],

      selectedJabatan: 1,
      jabatan: [
        { value: '1', text: 'Port Enginer' },
        { value: '2', text: 'Port Captain' },
        { value: '3', text: 'Technical Superintendent' },
        { value: '4', text: 'Technical Manager' }
      ],

      selectedShipyard: null,
      shipyard: [
        { value: '1', text: 'PT. Indonesia Marina Shipyard' },
        { value: '2', text: 'PT. Samudra Pratama' },
        { value: '3', text: 'PT. Rukindo Shipyard' }
      ],

      selectedFleets: null,
      kapal: [
        { value: '1', text: 'TB. ETI 201' },
        { value: '2', text: 'TB. ETI 202' },
        { value: '3', text: 'TB. TCP 301' }
      ],
      selectedDockingType: null,
      docking_type: [
        { value: '1', text: 'Intermediate Survey' },
        { value: '2', text: 'Special Survey' },
        { value: '3', text: 'Emergency Docking' }
      ],
      selectedDockingStatus: null,
      dockingStatus: [
        { value: '1', text: 'TOWARDS' },
        { value: '2', text: 'PROGRESS' },
        { value: '3', text: 'DUE DATE' },
        { value: '4', text: 'OVER SCHEDULE' },
        { value: '5', text: 'REVIEW' },
        { value: '6', text: 'APPROVE' }
      ],
      selectedDockingStatusFinish: null,
      dockingStatusFinish: [
        { value: '1', text: 'ON TIME' },
        { value: '2', text: 'DELAY' }
      ],
      selectedJobCategory: null,
      jobCategory: [
        { value: '1', text: 'Docking/Undocking' },
        { value: '2', text: 'Sand Blasting' },
        { value: '3', text: 'Painting' },
        { value: '4', text: 'Hull Contruction' },
        { value: '5', text: 'Machinery Equipment' },
        { value: '6', text: 'Outfiting' }
      ],
      text: [
        {
          activeLabel: 'Yes',
          inactiveLabel: 'No',
          class: 'primary',
          checked: true
        }
      ],
      fleetCategorySelect:[],
      fleetCategory: [
        {
          text: 'Tug Boat',
          color: 'success',
          value: 'tugboat'
        },
        {
          text: 'Barge',
          color: 'warning',
          value: 'barge'
        },
        {
          text: 'Mother Vessel',
          color: 'primary',
          value: 'mother_vessel'
        },
        {
          text: 'Heavy Equipment',
          color: 'primary',
          value: 'heavy_equipment'
        },
        {
          text: 'Pusher Tug',
          color: 'primary',
          value: 'pusher_tug'
        },
        {
          text: 'Pusher Barge',
          color: 'primary',
          value: 'pusher_barge'
        }
      ],
      select_all_fleets: false,
      loading_fleet: false,
      editingNode: null,
      fleet_params: {
        page: 1,
        showAll: true,
        search: ''
      },
      fleets: [],
      checkedFleets: [],
      color:
      {
        color: 'success',
        checked: false
      },
      tree: [
        {
          'text': 'Docking/Undocking',
          'opened': true,
          'selected': false,
          'children': [
            {
              'text': 'Up slip',
              'selected': false
            },
            {
              'text': 'Downslip',
              'selected': false
            }
          ]
        },
        {
          'text': 'Sand blasting',
          'opened': true,
          'selected': false,
          'children': [
            {
              'text': 'Hard Scraping Flat Bottom to Vertical Bottom Area',
              'selected': false
            },
            {
              'text': 'HP fresh washing Bottom + Topside',
              'selected': false
            },
            {
              'text': 'Sand blasting Bottom Plate',
              'selected': false
            },
            {
              'text': 'Sand blasting Hull Side Shell Bottom',
              'selected': false
            },
            {
              'text': 'Sand blasting Hull Side Shell Topside & Bulwark',
              'selected': false
            },
            {
              'text': 'Sand blasting Main deck',
              'selected': false
            },
            {
              'text': 'Sand blasting Inner side Bullwark',
              'selected': false
            }
          ]
        },
        {
          'text': 'Painting',
          'opened': true,
          'selected': false,
          'children': [
            {
              'text': 'Bottom Coating (AC ke 1) ',
              'selected': false
            },
            {
              'text': 'Bottom Coating (AC ke 2), sealer coat',
              'selected': false
            },
            {
              'text': 'Bottom Coating (AF ke 1)',
              'selected': false
            },
            {
              'text': 'Top side Coating (AC ke 1)',
              'selected': false
            },
            {
              'text': 'Top side Coating (1 x Finish Coat)',
              'selected': false
            },
            {
              'text': 'Painting shipname, plimsolmark, draftmark, Port registry dan imo number',
              'selected': false
            }
          ]
        },
        {
          'text': 'Hull Construction',
          'opened': true,
          'selected': false,
          'children': [
            {
              'text': 'Bongkar eyelug',
              'selected': false
            },
            {
              'text': 'Fabrication eyelug',
              'selected': false
            },
            {
              'text': 'Replace eyelug',
              'selected': false
            },
            {
              'text': 'Bongkar tire fender',
              'selected': false
            },
            {
              'text': 'Renew tire fender',
              'selected': false
            },
            {
              'text': 'Pasang tire fender',
              'selected': false
            },
            {
              'text': 'Bongkar push tug',
              'selected': false
            },
            {
              'text': 'Fabrication push tug',
              'selected': false
            },
            {
              'text': 'Renew push tug',
              'selected': false
            },
            {
              'text': 'Bongkar anchor pocket',
              'selected': false
            },
            {
              'text': 'Fabrikasi anchor pocket',
              'selected': false
            },
            {
              'text': 'Pemasangan anchor pocket',
              'selected': false
            },
            {
              'text': 'Bongkar fender pipe',
              'selected': false
            },
            {
              'text': 'Fabrikasi fender pipe',
              'selected': false
            },
            {
              'text': 'Pemasangan fender pipe',
              'selected': false
            }
          ]
        },
        {
          'text': 'Machinery Equipment',
          'opened': true,
          'selected': false,
          'children': [
            {
              'text': 'General Overhaul ME (P/S)',
              'selected': false
            },
            {
              'text': 'Kalibrasi injector (P/S)',
              'selected': false
            },
            {
              'text': 'Test Fungsi/ sea trial',
              'selected': false
            },
            {
              'text': 'Ganti baru dinamo charge (P/S)',
              'selected': false
            },
            {
              'text': 'Service alternator (P/S)',
              'selected': false
            }
          ]
        },
        {
          'text': 'Outfitting',
          'opened': true,
          'selected': false,
          'children': [
            {
              'text': 'Install new accomodation scuper pipe P/S',
              'selected': false
            },
            {
              'text': 'Replace platform ladder FP',
              'selected': false
            },
            {
              'text': 'Replace store door and rubber',
              'selected': false
            },
            {
              'text': 'Service hinge hatch door cabin crew',
              'selected': false
            },
            {
              'text': 'Perbaikan pondasi stopper winch jangkar',
              'selected': false
            },
            {
              'text': 'Ganti baru kampas rem winch jangkar',
              'selected': false
            },
            {
              'text': 'Ganti baru rantai jangkar sebanyak 5 segel',
              'selected': false
            },
            {
              'text': 'Modifikasi push fender haluan (naik 400mm)',
              'selected': false
            },
            {
              'text': 'Ganti baru anak tangga dan handle main deck',
              'selected': false
            },
            {
              'text': 'Service roda towing hub',
              'selected': false
            },
            {
              'text': 'Service release dan stopper towing hub',
              'selected': false
            },
            {
              'text': 'Ganti pintu hatch ER',
              'selected': false
            },
            {
              'text': 'Ganti manhole',
              'selected': false
            },
            {
              'text': 'Ganti pipa dewi-dewi',
              'selected': false
            },
            {
              'text': 'Service toggle pintu masuk ruang akomodasi',
              'selected': false
            }
          ]
        },
        {
          'text': 'Piping & Valve',
          'opened': true,
          'selected': false,
          'children': [
            {
              'text': 'Service Pipa SW cooling ME  P',
              'selected': false
            }
          ]
        },
        {
          'text': 'Propultion & steering system',
          'opened': true,
          'selected': false
        },
        {
          'text': 'Navigation & Electrical',
          'opened': true,
          'selected': false
        }
      ]
    }
  },

  // FLEET MODAL SELECTED
  watch: {
    select_all_fleets: {
      deep: true,
      handler: function (checked) {
        this.checkedFleets = []

        if (checked) {
          this.fleets.forEach((f) => this.checkedFleets.push(f.id))
        }
      }
    }
  },
  created () {
    this.fetchFleets = _.debounce(this.fetchFleets, 1000)
  },
  methods: {
    ...fleetsActions,
    onGlobalRefresh(){
      this.globalRefresh = !this.globalRefresh 
    },
    async fetchFleets () {
      this.loading_fleet = true
      let params = this.$options.filters.cleanObject(this.fleet_params)
      let res = await this.getFleets(params)

      if (!res.status) {
        this.loading_fleet = false
        this.fleets = []
      } else {
        this.loading_fleet = false
        this.fleets = res.data
      }
    },
    withPopper (dropdownList, component, { width }) {
      dropdownList.style.width = width
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1]
            }
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn ({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              )
            }
          }
        ]
      })
      return () => popper.destroy()
    },
    itemClick (node) {
      console.log(node.model.text + ' clicked !')
    },
    customItemClick: function (node, item, e) {
      e.stopPropagation()
      var index = node.parentItem.indexOf(item)
      node.parentItem.splice(index, 1)
    },
    customItemClickWithCtrl: function () {
      console.log('click + ctrl')
    }
  }
}
</script>
