<template>
  <b-row>
    <b-col lg="12" class="px-5 py-2">
      <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"><strong>{{ action === 'COC' ? 'List Expired COC' : 'List Expired COP' }}</strong></h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col md="12">
                  <b-button variant="primary" class="mb-2" @click="exportTable()" v-if="list?.coc_list_expired?.data?.length > 0 || list?.cop_list_expired?.data?.length > 0" >
                      <div class="d-flex gap-2 align-items-center"> 
                        <span class="spinner-border spinner-border-sm mr-2" v-if="exporting"></span>
                        <i class="fa fa-file-excel mr-2" v-else></i> 
                        <span>Export</span>
                      </div>
                    </b-button>
                  <div  v-if="list?.coc_list_expired?.data?.length > 0 || list?.cop_list_expired?.data?.length > 0" class="d-flex w-100 align-items-center justify-content-between mb-4">
                    <b-form-input class="align-self-end w-50 " v-model="searchQuery" placeholder="Search name/nip/certificate..."></b-form-input>
                    <b-button
                      @click.prevent="onFilter"
                      type="button"
                      variant="primary"
                      ><i class="fa fa-filter"></i>
                    </b-button>
                  </div>
                </b-col>
                <b-col  v-if="loading" md="12">
                  <div class="alert alert-info m-4 align-items-center ">
                    <span class="spinner-border spinner-border-sm mr-2"></span>  Loading...
                  </div>
                </b-col>
                <b-col md="12" v-if="['COC'].includes(action) && !loading">
                  <div class="d-flex overflow-auto" v-if="list?.coc_list_expired?.data?.length > 0">
                    <table class="table table-bordered" >
                      <thead>
                        <tr>
                          <th class=" text-center">NO</th>
                          <th class=" text-center">Name</th>
                          <th class="text-left">Certificate</th>
                          <th class="text-center">No.&nbsp;Certificate</th>
                          <th class="text-center">Tempat &&nbsp;Tanggal diterbitkan</th>
                          <th class="text-center">Tanggal&nbsp;Berakhir</th>
                          <th class="text-center">File</th>
                        </tr>
                      </thead>
  
                      <tbody>
                        <tr v-for="(crew, i) in list?.coc_list_expired?.data" :key="'crew-' + i">
                          <td class="text-center">{{ list?.coc_list_expired?.from + i }}</td>
                          <td>
                            <router-link
                              :to="{
                                path: `profile`,
                                query: {
                                  user: 'crew',
                                  id: crew.emp_crewing?.id,
                                },
                              }">{{ crew.emp_crewing?.fullname }}</router-link><br />
                              <small style="color: gray">NIP : <b>{{ crew?.emp_crewing?.nip ?? '-' }}</b></small>
                          </td>
                          <td class="text-center">{{ crew?.certificate?.name ?? '-' }}</td>
                          <td class="text-center">{{ crew?.document_number ?? '-' }} </td>
                          <td class="text-center">{{ crew?.place_issued + ', ' + moment(crew?.date_issued).format('DD MMMM YYYY')}}</td>
                          <td class="text-center">{{ crew?.validity_period && crew?.validity_period !== 'null' ? moment(crew.validity_period).format('DD MMMM YYYY') : '-'}}</td>
                          <td class="text-center" v-if="crew.file_url" style="cursor: pointer;" @click="handleOpenFilePreview(crew.file_url)"
                            ><i class="fa fa-file-alt text-primary"></i
                          ></td>
                          <td class="text-center" v-else>{{ '-' }}</td>
                        </tr>
                      </tbody>

                    </table>
                  </div>
                  <div class="mt-3" v-if="list?.coc_list_expired?.data?.length > 0">
                    <b-pagination
                      :value="list?.coc_list_expired?.current_page"
                      :total-rows="list?.coc_list_expired?.total"
                      :per-page="list?.coc_list_expired?.per_page"
                      first-number
                      @change="getListExpiredCocCop"
                      align="center"
                    />
                  </div>
                  <p v-else class="text-center my-4">List Expired COC tidak ada.</p>
                </b-col>
                <b-col md="12" v-if="['COP'].includes(action) && !loading">
                  <div class="d-flex overflow-auto" v-if="list?.cop_list_expired?.data?.length > 0">
                    <table class="table table-bordered" >
                      <thead>
                        <tr>
                          <th class=" text-center">NO</th>
                          <th class=" text-center">Name</th>
                          <th class="text-left">Certificate</th>
                          <th class="text-center">No.&nbsp;Certificate</th>
                          <th class="text-center">Tempat &&nbsp;Tanggal diterbitkan</th>
                          <th class="text-center">Tanggal&nbsp;Berakhir</th>
                          <th class="text-center">File</th>
                        </tr>
                      </thead>
  
                      <tbody>
                        <tr v-for="(crew, i) in filteredList" :key="'crew-' + i" >
                          <td class="text-center">{{ list?.cop_list_expired?.from + i }}</td>
                          <td>
                            <router-link
                              :to="{
                                path: `profile`,
                                query: {
                                  user: 'crew',
                                  id: crew.emp_crewing?.id,
                                },
                              }">{{ crew?.emp_crewing?.fullname }}</router-link><br />
                              <small style="color: gray">NIP : <b>{{ crew?.emp_crewing?.nip ?? '-' }}</b></small>
                          </td>
                          <td class="text-center">{{ crew?.certificate?.name ?? '-' }}</td>
                          <td class="text-center">{{ crew?.document_number ?? '-' }} </td>
                          <td class="text-center">{{ crew?.place_issued + ', ' + moment(crew?.date_issued).format('DD MMMM YYYY')}}</td>
                          <td class="text-center">{{ crew?.validity_period && crew?.validity_period !== 'null' ? moment(crew.validity_period).format('DD MMMM YYYY') : '-'}}</td>
                          <td class="text-center" v-if="crew.file_url" style="cursor: pointer;" @click="handleOpenFilePreview(crew.file_url)"
                            ><i class="fa fa-file-alt text-primary"></i
                          ></td>
                          <td class="text-center" v-else>{{ '-' }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="mt-3" v-if="list?.cop_list_expired?.data?.length > 0 || !filtering">
                    <b-pagination
                      :value="list?.cop_list_expired?.current_page"
                      :total-rows="list?.cop_list_expired?.total"
                      :per-page="list?.cop_list_expired?.per_page"
                      first-number
                      @change="getListExpiredCocCop"
                      align="center"
                    />
                  </div>
                  <p v-else class="text-center my-4">List COP tidak ada.</p>
                </b-col>
              </b-row>
            </template>
          </iq-card>
    </b-col>
  </b-row>
</template>

<script>
import moment from 'moment'

export default {
  name: 'SlideExpiredCocCop',
  props:{
    exportCocCop:{
      type:Function,
      default:() => {}
    },
    loading:{
      type:Boolean,
      default:false
    },   
    action:{
      type:String,
      default:''
    },  
    getListExpiredCocCop:{
      type:Function,
      default:true
    },    
    list:{
      type:Object,
      default: () => {}
    }
  },
  data(){
    return {
      exporting:false,
      filtering:false,
      searchQuery:''
    }
  },
  computed: {
    filteredList() {
      const searchLower = this.searchQuery.toLowerCase();
      if(['COC'].includes(this.action) && searchLower){
        return this.list?.coc_list_expired?.data?.filter(
          (item) =>
            item?.emp_crewing?.fullname?.toLowerCase().includes(searchLower) ||
            item?.emp_crewing?.nip?.toLowerCase().includes(searchLower) ||
            item?.certificate?.name.toLowerCase().includes(searchLower) 
        );
      } else if (['COP'].includes(this.action)){ 
        if(searchLower && this.filtering){
          return this.list?.cop_list_expired?.data?.filter(
            (item) =>
              item?.emp_crewing?.fullname?.toLowerCase().includes(searchLower) ||
              item?.emp_crewing?.nip?.toLowerCase().includes(searchLower) ||
              item?.certificate?.name.toLowerCase().includes(searchLower) 
          );
        } else {
          return this.list?.cop_list_expired?.data
        }
      }
    },
  },
  watch:{
    'searchQuery': {
      async handler(value) {
        if(!value) {
          this.filtering = false
          await this.getListExpiredCocCop(1, 50)
        }
      },
      immediate: true,
      deep: true,
    }, 
  },
  methods:{
    handleOpenFilePreview: function (url) {
      window.open(url, "_blank");
    },
    async onFilter(){
      this.filtering = true
      await this.getListExpiredCocCop(1, 1000000)
    },    
    async exportTable(){
      this.exporting = true 
      await this.exportCocCop()
      this.exporting = false
    }
  }
}
</script>