<template>
  <b-container fluid>
    <b-row>
      <b-col md="12" class="mb-2" v-if="!loading_real_shipment">
        <b-button variant="primary" class="float-right mr-1" size="sm" type="button" @click="openSlideFormRealShipment('ADD', null)"><i class="fa fa-plus"></i> &nbsp; ADD REAL SHIPMENT</b-button>
      </b-col>
      <!-- FILTER -->
      <!-- <b-col md="12">
        <b-row>
          <b-col md="4">
            <b-form-group label="Periode" label-for="periode">
              <date-picker v-model="dateRange" type="date" range placeholder="Select date range"></date-picker>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Search" label-for="Period">
              <b-form-input v-model="params.search"  placeholder="Search..."></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Status Payment" label-for="status">
              <b-form-select plain v-model="params.status" class="mb-2">
                <b-form-select-option value="">All</b-form-select-option>
                <b-form-select-option value="request">Request</b-form-select-option>
                <b-form-select-option value="paid">Paid</b-form-select-option>
                <b-form-select-option value="revision">Revision</b-form-select-option>
                <b-form-select-option value="canceled">Canceled</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="1">
            <b-form-group style label="Filter" label-for="button_search">
              <b-button variant="primary" @click="getListRealShipment" type='button'><i class="fa fa-search"></i></b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col> -->

      <!-- CONTENT TABLE -->
      <b-col md="12" v-if="!loading_real_shipment">
        <template>
          <div class="d-flex overflow-auto">
            <table class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th class="text-center" rowspan="2" style="vertical-align:middle; background-color:#E4EAA3">NO</th>
                  <th class="text-center" rowspan="2" style="vertical-align:middle; min-width: 200px; background-color:#E4EAA3">STATUS</th>
                  <th class="text-center" rowspan="2" style="vertical-align:middle; min-width: 350px; background-color:#E4EAA3">ID SHIPMENT PLAN</th>
                  <th class="text-center" colspan="2" style="min-width: 250px; background-color:#E4EAA3">PLAN DATE</th>
                  <th class="text-center" rowspan="2" style="vertical-align:middle; min-width: 250px; background-color:#E4EAA3">PLAN QUANTITY</th>
                  <th class="text-center" rowspan="2" style="vertical-align:middle; min-width: 250px; background-color:#BDE5EB">FLEET</th>
                  <th class="text-center" rowspan="2" style="vertical-align:middle; min-width: 250px; background-color:#BDE5EB">CARGO TYPE</th>
                  <th class="text-center" rowspan="2" style="vertical-align:middle; min-width: 250px; background-color:#BDE5EB">CARGO LOADING</th>
                  <th class="text-center" rowspan="2" style="vertical-align:middle; min-width: 250px; background-color:#BDE5EB">CARGO UNLOADING</th>
                  <th class="text-center" colspan="5" style="min-width: 250px; background-color:#BDE5EB">LOADING</th>
                  <th class="text-center" colspan="5" style="min-width: 250px; background-color:#BDE5EB">UNLOADING</th>
                  <th class="text-right" rowspan="2" style="vertical-align:middle; min-width: 250px; background-color:#BDE5EB">PRICE</th>
                  <th class="text-right" rowspan="2" style="vertical-align:middle; min-width: 250px; background-color:#BDE5EB">PRICE TO VENDOR</th>
                  <th class="text-center bg-warning border-dark " rowspan="2" style="vertical-align:middle; width:5px; position:sticky; right:0; z-index:1">ACTION</th>
                </tr>
                <tr>
                  <th class="text-center text-truncate" style="background-color:#E4EAA3">ETA POL</th>
                  <th class="text-center text-truncate" style="background-color:#E4EAA3">ETA POD</th>
                  <th class="text-center text-truncate" style="background-color:#BDE5EB">POL</th>
                  <th class="text-center text-truncate" style="background-color:#BDE5EB">TA POL / READY</th>
                  <th class="text-center text-truncate" style="background-color:#BDE5EB">BERTHING</th>
                  <th class="text-center text-truncate" style="background-color:#BDE5EB">COMMENCED</th>
                  <th class="text-center text-truncate" style="background-color:#BDE5EB">COMPLETED</th>
                  <th class="text-center text-truncate" style="background-color:#BDE5EB">POD</th>
                  <th class="text-center text-truncate" style="background-color:#BDE5EB">TA POD / READY</th>
                  <th class="text-center text-truncate" style="background-color:#BDE5EB">BERTHING</th>
                  <th class="text-center text-truncate" style="background-color:#BDE5EB">COMMENCED</th>
                  <th class="text-center text-truncate" style="background-color:#BDE5EB">COMPLETED</th>
                </tr>
              </thead>
              <tbody v-if="list?.data?.length > 0">
                <template>
                  <tr v-for="(state, index) in list?.data" :key="index">
                    <td class="text-truncate">{{index + 1}}</td>
                    <td class="text-truncate">{{state?.status}}</td>
                    <td class="text-truncate">{{state?.project_plan?.shipment_plan_detail_number}}</td>
                    <td class="text-truncate">{{state?.dataTrip?.tripRecord?.startDate}}</td>
                    <td class="text-truncate">{{state?.dataTrip?.tripRecord?.endDate}}</td>
                    <td class="text-truncate">{{state?.project_plan?.plan_quantity?.toLocaleString()}}</td>
                    <td class="text-truncate ">
                      <div class="d-flex w-100">
                        <b-badge class="border border-primary text-primary text-uppercase mr-1" variant="none" v-for="(obj, objIndex) in state?.dataTrip?.vehicle" :key="objIndex">{{ obj?.name }}</b-badge>
                      </div>
                    </td>
                    <td class="text-truncate">{{state?.dataTrip?.tripRecord?.cargoType}}</td>
                    <td class="text-truncate">{{state?.dataTrip?.tripRecord?.cargoLoading?.toLocaleString()}}</td>
                    <td class="text-truncate">{{state?.dataTrip?.tripRecord?.cargoUnloading?.toLocaleString()}}</td>
                    <td class="text-truncate">{{state?.dataTrip?.tripRecord?.portLoading?.portName}}</td>
                    <td class="text-truncate">{{state?.dataTrip?.tripRecord?.polArrivalTime}}</td>
                    <td class="text-truncate">{{state?.dataTrip?.tripRecord?.polBerthing}}</td>
                    <td class="text-truncate">{{state?.dataTrip?.tripRecord?.polCommenced}}</td>
                    <td class="text-truncate">{{state?.dataTrip?.tripRecord?.polCompleted}}</td>
                    <td class="text-truncate">{{state?.dataTrip?.tripRecord?.portDischarge?.portName}}</td>
                    <td class="text-truncate">{{state?.dataTrip?.tripRecord?.podArrivalTime}}</td>
                    <td class="text-truncate">{{state?.dataTrip?.tripRecord?.podBerthing}}</td>
                    <td class="text-truncate">{{state?.dataTrip?.tripRecord?.podCommenced}}</td>
                    <td class="text-truncate">{{state?.dataTrip?.tripRecord?.podCompleted}}</td>
                    <td class="text-right">{{state?.price?.toLocaleString()}}</td>
                    <td class="text-right">{{state?.price_to_vendor?.toLocaleString()}}</td>
                    <td class="text-center bg-warning border-dark " style="width:5px; position:sticky; right:0; z-index:1">
                      <b-dropdown 
                        size="lg"
                        variant="link iq-bg-primary iq-border-radius-10"
                        toggle-class="text-decoration-none"
                        no-caret
                      >
                        <template v-slot:button-content>
                          <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                            <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                          </span>
                        </template>
                        <b-dropdown-item href="#" variant="primary" @click="openSlideFormRealShipment">
                          <i class="fa fa-eye mr-2"></i>Detail
                        </b-dropdown-item>
                        <b-dropdown-item href="#" variant="primary" @click="onAddPrice(state?.id)" v-b-modal.add-price>
                          <i class="fa fa-pen mr-2"></i>Add Price
                        </b-dropdown-item>
                        <!-- <b-dropdown-divider></b-dropdown-divider> -->
                        <b-dropdown-item href="#" variant="warning">
                          <i class="fa fa-envelope mr-2"></i>Change Status
                        </b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </template>

        <div class="mt-3" v-if="list.data.length > 0">
          <b-pagination
            v-model="list.meta.page"
            :total-rows="list.meta.totalItem"
            @change="getListRealShipment"
            :per-page="list.meta.perPage"
            first-number
            align="center"
          ></b-pagination>
        </div>
        <div class="d-flex align-items-center justify-content-center w-100 my-2 mb-4" v-else>
          <strong class="text-muted">No Data Real Shipment.</strong>
        </div>
      </b-col>

      <b-col md="12" v-if="loading_real_shipment">
        <div class="alert alert-info m-2">
          Loading...
        </div>
      </b-col>

       <!-- SLIDE - FORM REAL SHIPMENT -->
       <b-col md="12">
        <b-sidebar
          v-model="slideFormRealShipment"
          id="slideFormRealShipment"
          :title="`Create Real Shipment`"
          width="100%"
          backdrop-variant="dark"
          bg-variant="white"
          right
          backdrop
          shadow>
          <template #default="{hide}">
            <SlideFormRealShipment
              :closeSlideFormRealShipment="closeSlideFormRealShipment"
              :refreshData="refreshData"
              :optionsFleets="optionsFleets"
              :projectId="projectData?.id"
              :action="action"
            />
          </template>
        </b-sidebar>
      </b-col>
      <!-- SLIDE - FORM REAL SHIPMENT END -->

      <!-- MODAL ADD PRICE -->
      <b-modal
        id="add-price"
        size="md"
        title="Add Price"
        cancel-title="Close"
        hide-footer
      >
        <form action="#" method="post" @submit.prevent="savePrice">
          <b-row>
            <b-form-group class="col-sm-12" label="Price">
              <b-form-input
                type="number"
                name="price"
                v-model="formPrice.price"
                placeholder="0.00"
              ></b-form-input>
              <span>{{ formatCurrency(formPrice.price) }}</span>
            </b-form-group>
            <b-form-group class="col-sm-12" label="Price to Vendor">
              <b-form-input
                type="number"
                name="price_to_vendor"
                v-model="formPrice.price_to_vendor"
                placeholder="0.00"
              ></b-form-input>
              <span>{{ formatCurrency(formPrice.price_to_vendor) }}</span>
            </b-form-group>

            <b-col :md="'3'">
              <b-button
                variant="secondary"
                block
                @click="$bvModal.hide('add-price')"
                type="button"
                >Close</b-button
              >
            </b-col>
            <b-col md="9">
              <b-button
                variant="primary"
                block
                type="submit"
              >
                <span>Save</span>
              </b-button>
            </b-col>
          </b-row>
        </form>
      </b-modal>
      <!-- MODAL ADD PRICE END -->
    </b-row>
  </b-container>
</template>

<script>
  import _ from 'lodash'
  import moment from 'moment'
  import { fleetsActions, crewingActions, busdevActions, voyagesActions } from '@src/Utils/helper'
  import SlideFormRealShipment from './SlideFormRealShipment.vue'

  export default {
    name:'TabRealShipment',
    props:{
      projectData:{
        type:Object,
        default:null
      }, 
      optionsFleets:{
        type:Array,
        default:() => []
      },
    },
    components:{
      SlideFormRealShipment
    },
    data(){
      return{
        slideFormRealShipment:false,
        loading_real_shipment:false,

        id:null,
        action:'',
        dateRange:[],
        params: {
          startDate:null,
          endDate:null,
          search:'',
          status:'',
        },
        paymentTotal:{},
        list:{
          data:[],
          meta:null
        },
        meta:null,

        formPrice:{
          price:null,
          price_to_vendor:null
        }
      }
    },
    watch:{
      'projectData.id': {
        deep: true,
        immediate:true,
        handler: function (value) {
          if (value) {
            this.getListRealShipment()
          }
        },
      },
    },
    mounted(){
    },
    computed:{},
    methods:{
      ...busdevActions,
      ...voyagesActions,
      formattedCurrencyIDR(number){
        const formattedNumber = new Intl.NumberFormat('id-ID').format(number);
        let formattedIDR = formattedNumber.replace('.', ',').replace(',', '.');
        // Check if there are decimal digits present
        let decimalIndex = formattedIDR.indexOf(',');
        if (decimalIndex === -1) {
          // No decimal digits present, append ",00"
          formattedIDR += ',00';
        } else if (formattedIDR.length - decimalIndex - 1 === 1) {
          // Only one decimal digit present, append trailing zero
          formattedIDR += '0';
        }

        return formattedIDR
      },
      formatCurrency(value) {
        return new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        }).format(value)
      },
      showVehicleName(value){
        let vehicle = this.optionsFleets.find(obj => obj.value == value)

        return vehicle?.text ?? '-'
      },

      closeSlideFormRealShipment(){
        this.id = null
        this.action = ''
        this.slideFormRealShipment = false
      },  
      openSlideFormRealShipment(value, id){
        this.id = null
        this.action = value
        this.slideFormRealShipment = !this.slideFormRealShipment;

        if(id) this.id = id
      },
     
      async getListRealShipment(page){
        this.loading_real_shipment = true
        let params = {
          params:{
            ...this.params,
            page: page || 1,
            perPage: 25,
            // startDate: this.dateRange?.[0] ? moment(this.dateRange?.[0]).format('YYYY-MM-DD') : null,
            // endDate: this.dateRange?.[1] ? moment(this.dateRange?.[1]).format('YYYY-MM-DD') : null,
          },
          project_contract_id: this.projectData.id
        }

        // Iterate over each property in params
        for (const prop in params.params) {
          if (params.params.hasOwnProperty(prop)) {
            // Check conditions for each property and delete if necessary
            if ((params.params[prop] === '') || (params.params[prop] === null)) {
              delete params.params[prop];
            }
          }
        }

        const res = await this.fetchListRealShipment(params)

     
        if(res?.status === 'success'){
          let dataTrip = null
          let tempArr = []

          if(res?.data?.data?.data?.length > 0){
            const promises = res?.data?.data?.data?.map(async val => {
              let params = {
                tripId: val.trip_id,
                vehicleId: val.vehicle_id
              }
              let trip = await this.getVoyageTripDetail(params)

              if(trip?.status === 'success'){
                dataTrip = trip?.data

                tempArr.push({
                  ...val,
                  dataTrip
                })
              } else {
                tempArr.push({
                  ...val,
                  dataTrip: null
                })
              }
            })

            await Promise.all(promises)
          }

          this.list.data = tempArr
          this.list.meta = res?.data?.data?.meta

          this.loading_real_shipment = false
          console.log(this.list.data, "this.list")
        }

        this.loading_real_shipment = false
      },
      refreshData(){
        this.getListRealShipment(1)
      },
      onAddPrice(id){
        if(id) this.id = id
      },

      async savePrice(){
        let payload = {
          ...this.formPrice
        }

        if(this.id){
          const res = await this.savePriceRealShipment({id: this.id, data: payload})
          if (res.status == 'success') {
            this.refreshData()
            this.$swal(
              'Success!',
              'Price Saved.',
              'success'
            )
            this.formPrice = {
              price:null,
              price_to_vendor:null
            }
            this.$bvModal.hide('add-price')
          } else {
            this.$swal(
              `Oops!`,
              res?.data?.message ?? 'Something went wrong.',
              'error'
            )
          }
        }
      }
    }
  }
</script>
