<template>
  <b-row class="m-4">
    <b-col md="12" v-if="!loading">
      <fieldset class="form-group border p-3">
        <legend class="w-auto px-2 text-primary">DETAIL TRIP</legend>
        <b-row  class="p-2">
          <b-col md="6">
            <b-row class="m-2">
              <b-col md="6">
                <b-form-group label="FLEET">
                  <strong class="text-muted">{{vehicle?.name}}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="SECOND FLEET">
                  <strong class="text-muted">-</strong>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="m-2 py-2" style="background-color:#E5F9FF">
              <b-col md="12" class="mb-2">
                <strong class="text-primary">LOADING ACTIVITY RECORD</strong>
              </b-col>
              <b-col md="6">
                <b-form-group label="Port of Loading">
                  <strong class="text-muted">-</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Agent POL">
                  <strong class="text-muted">-</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Draught Survey">
                  <strong class="text-muted">{{ detailData?.polDraughtSurvey ?? 0 }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Arival Time POL">
                  <strong class="text-muted">{{ detailData?.polArrivalTime ? moment(detailData?.polArrivalTime, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Anchor Up">
                  <strong class="text-muted">{{ detailData?.polAnchorUp ? moment(detailData?.polAnchorUp, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="In Position / Berthing">
                  <strong class="text-muted">{{ detailData?.polBerthing ? moment(detailData?.polBerthing, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Cast Out">
                  <strong class="text-muted">{{ detailData?.polCastOut ? moment(detailData?.polCastOut, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="LHV">
                  <strong class="text-muted">{{ detailData?.polLhv ? moment(detailData?.polLhv, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Document on Board POL">
                  <strong class="text-muted">{{ detailData?.polDocOnBoard ? moment(detailData?.polDocOnBoard, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Departure to POD">
                  <strong class="text-muted">{{ detailData?.polDeparture ? moment(detailData?.polDeparture, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}</strong>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row class="m-2">
              <b-col md="6">
                <b-form-group label="PROJECT" style="opacity:0">
                  <strong class="text-muted">-</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="PROJECT" style="opacity:0">
                  <strong class="text-muted">-</strong>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="m-2  py-2" style="background-color:#EFFEE6">
              <b-col md="12" class="mb-2">
                <strong class="text-primary">LOADING ACTIVITY RECORD</strong>
              </b-col>
              <b-col md="6">
                <b-form-group label="Port of Discharge">
                  <strong class="text-muted">-</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Agent POD">
                  <strong class="text-muted">-</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Draught Survey">
                  <strong class="text-muted">{{ detailData?.podDraughtSurvey ?? 0 }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Arival Time POL">
                  <strong class="text-muted">{{ detailData?.podArrivalTime ? moment(detailData?.podArrivalTime, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Anchor Up">
                  <strong class="text-muted">{{ detailData?.podAnchorUp ? moment(detailData?.podAnchorUp, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="In Position / Berthing">
                  <strong class="text-muted">{{ detailData?.podBerthing ? moment(detailData?.podBerthing, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Cast Out">
                  <strong class="text-muted">{{ detailData?.podCastOut ? moment(detailData?.podCastOut, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="LHV">
                  <strong class="text-muted">{{ detailData?.podLhv ? moment(detailData?.podLhv, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Document on Board POL">
                  <strong class="text-muted">{{ detailData?.podDocOnBoard ? moment(detailData?.podDocOnBoard, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Departure to POD">
                  <strong class="text-muted">{{ detailData?.podDeparture ? moment(detailData?.podDeparture, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}</strong>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        
        <!-- TABLE CONTENT -->
        <b-row class="p-3">
          <b-col md="12">
            <h4><strong class="text-primary">Activities</strong></h4>
            <hr>
            <div class="d-flex overflow-auto">
              <table class="table table-striped">
                  <thead>
                    <tr>
                      <th style="width:5px" class="text-center table-info text-truncate">NO</th>
                      <th class="text-center table-info text-truncate">START DATE</th>                       
                      <th class="text-center table-info text-truncate">END DATE</th>                       
                      <th class="text-center table-info text-truncate">REASON</th>                       
                      <th class="text-center table-info text-truncate">HOURS</th>                       
                      <th class="text-center table-info text-truncate">CARGO LOADING</th>                       
                      <th class="text-center table-info text-truncate">CARGO UNLOADING</th>                       
                      <th class="text-center table-info text-truncate">CURRENT POSITION</th>                       
                      <th class="text-center table-info text-truncate">REMARKS</th>                       
                      <th class="text-center table-info text-truncate">GPS</th>                       
                      <th class="text-center table-info text-truncate">SPEED</th>                       
                      <th class="text-center table-info text-truncate">REASON</th>                                             
                      <th class="text-center table-info">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(state, index) in activities" :key="index">
                      <td class="text-center">{{index + 1}}</td>
                      <td class="text-center text-truncate">{{ state?.startDate ? moment(state?.startDate, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}</td>
                      <td class="text-center text-truncate">{{ state?.endDate ? moment(state?.endDate, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}</td>
                      <td class="text-center text-truncate">{{ state?.reason?.name ?? '-' }}</td>
                      <td class="text-center text-truncate">{{ state?.activityDurationFormat }}</td>
                      <td class="text-center text-truncate">{{ state?.cargoLoading ?? '-' }}</td>
                      <td class="text-center text-truncate">{{ state?.cargoUnloading ?? '-' }}</td>
                      <td class="text-center text-truncate">{{ state?.currentPosition ?? '-' }}</td>
                      <td class="text-center">{{ state?.remark ?? '-' }}</td>
                      <td class="text-center">-</td>
                      <td class="text-center">{{ state?.speed }}</td>
                      <td class="text-center">{{ state?.reason?.name }}</td>
                      <td class="text-center">
                        <b-dropdown 
                          size="lg"
                          variant="link iq-bg-primary iq-border-radius-10"
                          toggle-class="text-decoration-none"
                          no-caret
                        >
                          <template v-slot:button-content>
                            <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                              <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                            </span>
                          </template>
                          <b-dropdown-item href="#" variant="primary">
                            <i class="fa fa-eye mr-2"></i>Detail
                          </b-dropdown-item>
                          <b-dropdown-item href="#" variant="primary">
                            <i class="fa fa-pen mr-2"></i>Edit
                          </b-dropdown-item>
                          <b-dropdown-divider></b-dropdown-divider>
                          <b-dropdown-item href="#" variant="danger" >
                            <i class="fa fa-trash mr-2"></i>Delete
                          </b-dropdown-item>
                        </b-dropdown>
                      </td>
                    </tr>
                  </tbody>
                </table>
            </div>
          </b-col>
          <b-col md="12">
              <div class="mt-3">
                <b-pagination :value="1" :total-rows="50" align="center" />
              </div>
            </b-col>
        </b-row>
 
         <!-- TABLE CONTENT MATRIX -->
         <b-row class="p-3">
          <b-col md="12">
            <h4><strong class="text-primary">Voyage Matrix</strong></h4>
            <hr>
            <div class="d-flex overflow-auto">
              <table class="table table-striped">
                  <thead>
                    <tr >
                      <th class="text-center table-info text-truncate text-uppercase" v-for="(head, headIndex) in matrix" :key="headIndex">
                        {{head?.name}}
                      </th>                       
                      <th class="text-center table-info" style="width:5px; position: sticky; right: 0; z-index: 1">#</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-center text-truncate" v-for="(body, bodyIndex) in matrix" :key="bodyIndex">
                        {{body?.durationFormat ?? '-'}}
                      </td>
                      <td class="text-right table-info" style="width:5px; position: sticky; right: 0; z-index: 1">
                        <b-dropdown 
                          size="lg"
                          variant="link iq-bg-primary iq-border-radius-10"
                          toggle-class="text-decoration-none"
                          no-caret
                        >
                          <template v-slot:button-content>
                            <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                              <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                            </span>
                          </template>
                          <b-dropdown-item href="#" variant="primary">
                            <i class="fa fa-eye mr-2"></i>Detail
                          </b-dropdown-item>
                          <b-dropdown-item href="#" variant="primary">
                            <i class="fa fa-pen mr-2"></i>Edit
                          </b-dropdown-item>
                          <b-dropdown-divider></b-dropdown-divider>
                          <b-dropdown-item href="#" variant="danger" >
                            <i class="fa fa-trash mr-2"></i>Delete
                          </b-dropdown-item>
                        </b-dropdown>
                      </td>
                    </tr>
                  </tbody>
                </table>
            </div>
          </b-col>
          <b-col md="12">
              <div class="mt-3">
                <b-pagination :value="1" :total-rows="50" align="center" />
              </div>
            </b-col>
        </b-row>
      </fieldset>
    </b-col>
    
    <b-col md="12" v-else>
      <div class="alert alert-info m-4">
        Loading...
      </div>
    </b-col>
  </b-row>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { fleetsActions, voyagesActions } from '@src/Utils/helper'

export default {
  name: 'SlideDetailRealisasi',
  props:{
    id:{
      type:Number,
      default:null
    }
  },
  components:{
  },
  data(){
  return {
    loading:false,

    detailData: null,
    vehicle: null,
    matrix: [],
    activities: [],
    summaryActivities: [],
  }
  },
  async mounted () {
  },
  computed: {
  },
  watch: {
    id: {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (value) {
          this.getDetailTrip(value)
        }
      },
    },
  },
  methods:{
    ...voyagesActions,
    async getDetailTrip(tripId){
      let params = {
        tripId,
        vehicleId: Number(this.$route.query.vehicleId),
      }
      let res = await this.getVoyageTripDetail(params)
      console.log(res)
      if(res.status === 'success'){
        let data = res?.data

        this.matrix = data?.matrix
        this.activities = data?.activities
        this.summaryActivities = data?.summaryActivities

        this.detailData = data?.tripRecord
        this.vehicle = data?.vehicle
      }
    }
  }

}
</script>