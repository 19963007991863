<template>
  <form @submit.prevent="onSendMail"> 
    <b-row class="m-3">
      <b-col md="12" v-if="!loading">
        <b-row>
          <b-col lg="12" class="mb-4">
            <div :class="`d-flex align-items-center justify-content-end w-100`">
              <b-button
                type="button"
                variant="danger"
                class="mr-3"
                @click="closeSlideSendMail"
              >
                <div>
                  <i class="fa fa-ban"></i>
                  <span>Cancel</span>
                </div>
              </b-button>
              <b-button
                variant="primary"
                type="submit"
              >
                <span
                  v-if="saving"
                  class="spinner-border spinner-border-sm"
                />
                <div v-else>
                  <i class="fa fa-save"></i>
                  <span>Send Mail</span>
                </div>
              </b-button>
            </div>
            <hr>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Vehicle"
              label-for="vehicle_id"
            >
              <v-multiselect 
                v-model="form.vehicle_id" 
                :options="listVehicle" 
                placeholder="Select vehicle" 
                track-by="name" 
                label="name"
                @input="onSelectedVehicle"
              >
                <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
              </v-multiselect>
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="form.vehicle_id">
            <b-form-group
              label="Collection Forms"
              label-for="collection_forms"
            >
              <v-multiselect 
                v-model="form.collection_of_forms_id"
                :options="listCollectionForm" 
                placeholder="Select forms" 
                track-by="name" 
                label="name"
              >
                <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
              </v-multiselect>
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="form.collection_of_forms_id">
            <fieldset class="form-group border p-3 bg-default">
              <legend class="w-auto px-2 h5 text-primary">Head Mail</legend>
              <b-form-group label="Title" label-for="title">
                <b-form-input type="text" id="title" placeholder="Type Text" v-model="form.title"></b-form-input>
              </b-form-group>
              <b-form-group label="To" label-for="to">
                <v-multiselect 
                  v-model="form.to" 
                  :options="optionsUsers" 
                  placeholder="Select PIC..." 
                  track-by="email" 
                  label="email"
                  :multiple="true"
                >
                  <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                </v-multiselect>
              </b-form-group>
            </fieldset>
          </b-col>
          <b-col md="8" v-if="form.collection_of_forms_id">
            <fieldset class="form-group border p-3 bg-default">
              <legend class="w-auto px-2 h5 text-primary">Body Mail</legend>
              <div >
                <wysiwyg :style="{ height: '500px' }" v-model="form.content" />
              </div>
            </fieldset>
          </b-col> 
        </b-row>
      </b-col>

      <b-col md="12" v-else>
        <div class="alert alert-info m-4">
          Loading...
        </div>
      </b-col>
    </b-row>
  </form>
</template>

<script>
  import _ from 'lodash'
  import moment from 'moment'
  import { 
    busdevActions,
    fleetsActions,
    usersActions,
  } from '@src/Utils/helper'
  import { saveAs } from 'file-saver'

  export default {
    name:'SlideSendMail',
    props:{
      closeSlideSendMail:{
        type:Function,
        default:() => {}
      },  
    },
    components:{
    },
    data(){
      return{
        apiUrl: process.env.VUE_APP_API_BUSDEV_BASE_URL,

        saving: false,
        loading: false,

        listCollectionForm: [],
        listVehicle: [],
        optionsUsers: [],

        form:{
          vehicle_id:null, 
          collection_of_forms_id: null,
          to:[],
          title:'',
          priority:false, 
          content:``,
        },
        
        user_id: JSON.parse(localStorage.getItem('profile')).id,
        user_mail: JSON.parse(localStorage.getItem('profile')).email,
        user_name: JSON.parse(localStorage.getItem('profile')).userDetail.name,
      }
    },
    watch:{
      'form.collection_of_forms_id':{
        handler(value) {
          this.form.title = `Reminder Pengisian Form ${this.form?.collection_of_forms_id?.name}`
          this.form.content = `
            <div style="text-align: center;">
              <strong>[REMINDER NOTIFICATION]</strong>
            </div>
            
            <p>Dear Tim,</p>
            
            <p>Mohon dapat di lakukan update data terbaru Inspection Checklist untuk Kapal <b>${this.form?.vehicle_id?.name}</b> pada bagian <b>${this.form?.collection_of_forms_id?.name}</b> data yang terupdate di system saat ini terakhir pada tanggal ${moment(this.form?.collection_of_forms_id?.createdAt).format('DD MMMM YYYY')}.</p>
            
            <p>Terima Kasih,</p>
            
            <p style="text-align: right;">Best Regards,</p>

            <b>
              <div style="text-align: right;">
                <b style="color: inherit; font-family: inherit;"><br></b></div><div style="text-align: right;">
                  <b style="color: inherit; font-family: inherit;"><br></b></div><div style="text-align: right;">
                    <b style="color: inherit; font-family: inherit;">${this.user_name}r</b>
              </div>
            </b>
          `
        },
      }
    },
    mounted(){
      this.fetchFleets()
      this.fetchUsers()
    },
    computed:{},
    methods:{
      ...busdevActions,
      ...fleetsActions,
      ...usersActions,
      async onSelectedVehicle(){
        if(this.form.vehicle_id.vehicleType.id) await this.getVehicleTypeForm()
      }, 
      async getVehicleTypeForm() {
        this.listCollectionForm = []

        let params = {
          vehicle_type_id: Number(this.form.vehicle_id.vehicleType.id)
        }

        let res = await this.getVehicleTypeFormsWithoutChild(params)

        if (res?.data?.status === true && res?.data?.data?.length > 0) {
          this.listCollectionForm = res.data.data?.map(val => {
            return {
              ...val,
              ...val?.collection_of_forms
            }
          })

          console.log(this.listCollectionForm )
        }
      },
      async fetchFleets() {
        let params = this.$options.filters.cleanObject({
          page: 1,
          perPage: 9999,
          showAll: true,
          active: true,
        })
        
        let res = await this.getFleets(params)

        if (res.status) {
          if (res.data && res.data.length > 0) {
            this.listVehicle = res.data
          }
        }
      },
      async fetchUsers() {
        this.optionsUsers = []
        let params = {}
        const res = await this.getUsersPic(params)

        if (res.status === 'success') {
          this.optionsUsers = res.data.data.map((val) => ({
            ...val,
            value: val.id,
            text: val.name,
          }))
        }
      },
      formatCurrency(value) {
        return new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        }).format(value)
      },
      resetForm(){
        this.form = {
          vehicle_id:null, 
          collection_of_forms_id: null,
          to:[],
          title:'',
          priority:false, 
          content:``,
        }
      },
      async onSendMail(){
        if(this.form.to?.length <= 0) return this.$swal(`Required!`, `Mohon isi alamat penerima email.`, 'warning')
        this.saving = true

        let payload = {
          "sender_name": this.user_name,
          "sender_mail":this.user_mail,
          "to":this.form.to?.map(val => val.email),
          "subject":this.form.title,
          "content":this.form.content,
          "intro":"Inspection Checklist Reminder.",
          "outro":"Thank you for reading this email, as you know this email is automatically",
        }
        const res = await this.sendMailInspectionChecklist({data:payload})

        if(res.data.status === true){
          this.resetForm()
          this.closeSlideSendMail()
          this.$swal(`Success!`, `Send Mail Success.`, 'success')
        } else {
          this.$swal(`Oops!`, `Something went wrong.`, 'error')
        }

        this.saving = false
      }
    }
  } 
</script>

<!-- <style scoped>
/* Ensure file input is hidden */
#file-upload {
  display: none;
}
</style> -->
