<template>
  <b-container fluid>
    <b-row>
      <b-modal v-model="modal.filter" centered ok-title="Filter" title="Filter Data" @ok="getRobList()" size="lg">
        <b-row class="px-3">
          <b-col cols="12">
            <b-form-group label="Date Range Filter" label-for="from"  label-cols-sm="4" label-cols-lg="3" class="mb-3">
              <date-picker @change="onChangeDate()" v-model="dateRange" type="date" range placeholder="Select date range" class="w-100"></date-picker>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Category:" label-for="kind-fleet" label-cols-sm="4" label-cols-lg="3" class="mb-3">
              <v-multiselect v-model="form.selectedCategory" :options="vehicleTypes" placeholder="Choose category fleet" label="text" track-by="value"></v-multiselect>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Fleet/Equipment:" label-for="fleet-equipment" label-cols-sm="4" label-cols-lg="3">
              <v-multiselect
                v-model="selectedFleetEquipment"
                tag-placeholder="Category"
                placeholder="Search or add fleets" label="name" track-by="code" :options="fleetsByCategory" :multiple="true" :taggable="true">
              </v-multiselect>
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"><strong>SUMMARY OF DAILY FUEL, WATER, & CARGOS REMAINING ONBOARD</strong></h4>
          </template>
          <template v-slot:body>
            <div class="d-flex justify-content-between px-3 pt-3">
              <div class="d-flex">
                <b-form-input size="lg" placeholder="search..."></b-form-input>
                <b-button variant="primary" class="mx-2">
                  <i class="fa fa-search"></i>
                </b-button>
                <b-button
                  variant="outline-primary"
                  class="d-flex align-items-center"
                  @click="toggleModalFilter"
                >
                  <i class="fas fa-sliders"></i>
                  <p class="text-truncate m-0"> Filter Data</p>
                </b-button>
              </div>
              <div>
                <b-button variant="secondary" class="mr-2">
<!--              <template v-if="!loading_export_structure">-->
                <i class="fa fa-file-excel"></i> &nbsp; Export
<!--              </template>-->
<!--              <template v-else>-->
<!--                    <span-->
<!--                      class="spinner-border spinner-border-sm"-->
<!--                      role="status"-->
<!--                      aria-hidden="true"-->
<!--                    ></span>-->
<!--                Exporting...-->
<!--              </template>-->
            </b-button>
            <b-button variant="primary" v-b-modal.form-rh @click="resetForm()"><i class="fa fa-plus-circle"></i> &nbsp; Add Summary ROB</b-button>
                <b-modal id="form-rh" size="lg" :title="`${selectedDetailVehicleId ? 'Edit' : 'Tambah'} SUMMARY OF DAILY FUEL, WATER, & CARGOS REMAINING ONBOARD`" ok-title="Save" cancel-title="Cancel" centered>
                  <b-row class="mb-5">
                    <b-col md="6">
                      <b-form-group label-for="dateIssued" label="Date">
                        <date-picker style="width: 100%;" v-model="form.date" :disabled="selectedDetailVehicleId" value-type="DD-MM-YYYY" format="DD MMM YYYY" type="date" placeholder="Select date"></date-picker>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group label-for="dateIssued" label="Choose your Fleet/Equipment">
                        <v-multiselect v-model="form.selectedVehicle" :disabled="selectedDetailVehicleId" @input="getLatestRobList" :options="fleets" placeholder="Choose your fleet" label="name" track-by="code"></v-multiselect>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <hr>
                  <b-row>
                    <b-col cols="12">
                      <table class="table table-striped">
                        <thead>
                        <tr>
                          <th width="15%">Type</th>
                          <th width="20%">Opening (ROB From Previous Day)</th>
                          <th width="20%">Consumption (Based on Actual Sounding)</th>
                          <th width="15%">Received</th>
                          <th width="15%">Transferred</th>
                          <th width="15%">Closing</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-if="form.selectedVehicle === null">
                          <td colspan="6">Silahkan pilih fleet</td>
                        </tr>

                        <tr v-for="(rob, index) in form.robs" :key="index">
                          <td>{{rob.robTypeName}}</td>
                          <td><p><b-form-input type="number" v-model="rob.opening" @change="calculateActualRob(index)" placeholder="consumption" value="20000"></b-form-input></p></td>
                          <td>
                            <b-form-input type="number" v-model="rob.consumption" @change="calculateActualRob(index)" placeholder="consumption" value="10000"></b-form-input>
                          </td>
                          <td><b-form-input type="number" v-model="rob.received" @change="calculateActualRob(index)" placeholder="received" value="5000"></b-form-input></td>
                          <td><b-form-input type="number" v-model="rob.transferred" @change="calculateActualRob(index)" placeholder="transferred"></b-form-input></td>
                          <td><strong>{{ rob.actualRob }} Ltrs</strong></td>
                        </tr>
                        </tbody>
                      </table>
                    </b-col>
                    <b-col cols="12" class="mt-3">
                      <b-form-group label="Remarks" label-for="Remarks">
                        <b-form-textarea id="remarks" v-model="form.remark" rows="2"></b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <template #modal-footer="{ cancel }">
                    <b-row>
                      <b-col md="12" class="text-center">
                        <b-button variant="none" class="iq-bg-danger text-right" @click="cancel()">Cancel</b-button>
                        <b-button type="submit" variant="primary" @click="save()" class="ml-2 text-right">Save</b-button>
                      </b-col>
                    </b-row>
                  </template>
                </b-modal>
              </div>
            </div>
            <b-row class="p-3">
              <b-col cols="12">
                <template v-for="(r, index) in rob">
                  <b-card no-body class="mb-1" :key="`mat-${index}`">
                    <b-card-header header-tag="header" class="p-1" role="tab" v-b-toggle="'matrix' + r.vehicle[Object.keys(r.vehicle)[0]].id">
                      <h4 style="cursor: pointer; font-weight: bold" class="p-3">{{r.vehicle[Object.keys(r.vehicle)[0]].name}}

                      </h4>
                    </b-card-header>
                    <b-collapse :id="'matrix' + r.vehicle[Object.keys(r.vehicle)[0]].id" role="tabpanel">
                      <b-card-body>
                        <table class="table table-sm table-striped">
                          <thead>
                          <tr>
                            <th width="12%">Date</th>
                            <th width="45%">Detail Summary ROB</th>
                            <th width="15%">Remarks</th>
                            <th width="5%" class="text-center">#</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(robHistory, indexRobHistory) in r.robHistory" :key="indexRobHistory">
                            <td>{{ robHistory.date }}</td>
                            <td>
                              <table class="table-dark">
                                <thead>
                                <tr>
                                  <th width="20%">Type</th>
                                  <th width="20%">Previous</th>
                                  <th width="15%">Cons</th>
                                  <th width="15%">Received</th>
                                  <th width="15%">Transferred</th>
                                  <th width="15%">Closing</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(detail, indexDetail) in robHistory.robs" :key="indexDetail">
                                  <td>{{ detail.robTypeName }}</td>
                                  <td><small>{{ detail.logDate }}</small><p>{{ detail.actualRob }} Ltrs</p></td>
                                  <td>{{ detail.consumption }}</td>
                                  <td>{{ detail.received }}</td>
                                  <td>{{ detail.transferred }}</td>
                                  <td><strong>{{ detail.opening }} Ltrs</strong></td>
                                </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>{{robHistory.remark}}</td>
                            <td class="text-center">
                              <a v-if="indexRobHistory === 0" @click="deleteData(r.vehicle[Object.keys(r.vehicle)[0]].id, robHistory.date)"><span class="text-danger"><i class="fa fa-trash" /></span></a>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </template>
              </b-col>
<!--              <b-col class="mt-4">-->
<!--                <b-pagination-->
<!--                  v-model="currentPage"-->
<!--                  :total-rows="total"-->
<!--                  :per-page="params.perPage"-->
<!--                  first-number-->
<!--                  align="center"-->
<!--                ></b-pagination>-->
<!--              </b-col>-->
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { fleetsActions, maintenanceActions } from '@src/Utils/helper'
import moment from 'moment'

export default {
  name: 'SummaryROB',
  data () {
    return {
      dateRange: [],
      total: 0,
      currentPage: 1,
      vehicleTypes: [],
      selectedFleetEquipment: [],
      fleets: [],
      isLoading: false,
      rob: [],
      params: {
        action: '',
        search: '',
        status: null,
        startDate: null,
        endDate: null,
        perPage: 25,
        page: 1,
        vehicleIds: [],
        jobTypeIds: [],
        jobClassIds: []
      },

      form: {
        selectedVehicle: null,
        selectedCategory: null,
        date: '',
        remark: '',
        vehicleId: '',
        robs: []
      },

      selectedDetailVehicleId: null,

      modal:{
        filter:false
      }
    }
  },
  watch: {
    'form.selectedCategory' (value) {
      if(value) {
        this.selectedFleetEquipment = []
      }
    }
  },
  computed: {
    fleetsByCategory () {
      if (this.form.selectedCategory) {
        return this.fleets.filter(fleet => parseInt(fleet.vehicleType.id) === parseInt(this.form.selectedCategory.value))
      }

      return this.fleets
    }
  },
  methods: {
    ...fleetsActions,
    ...maintenanceActions,
    async fetchFleets () {
      this.fleets = []

      let params = this.$options.filters.cleanObject({
        page: 1,
        showAll: true,
        active: true,
        search: ''
      })
      let res = await this.getFleets(params)

      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.forEach(el => {
            this.fleets.push({
              name: el.name,
              code: el.id,
              vehicleType: el.vehicleType,
            })
          })
        }
      }
    },
    async fetchFleetTypes () {
      let params = { active: true }
      let res = await this.getFleetTypes(params)

      this.vehicleTypes = []
      if (res.data) {
        res.data.forEach(f => {
          this.vehicleTypes.push({
            checked: true,
            value: f.id,
            text: f.name,
            disabled: false
          })
        })
      }
    },
    async getRobList () {
      this.isLoading = true
      this.rob = []

      const paramsQ = {
        vehicleIds: this.selectedFleetEquipment.map(fleet => fleet.code).toString(),
        vehicleTypeIds: this.vehicleTypes.filter(type => type.checked).map(fleet => fleet.value).toString(),
        perPage: this.params.perPage,
        page: this.params.page,
        startDate: this.params.startDate,
        endDate: this.params.endDate,
      }

      if (paramsQ.search === '') {
        delete paramsQ.search
      }

      if (paramsQ.vehicleIds === '') {
        delete paramsQ.vehicleIds
      }

      if (paramsQ.vehicleTypeIds === '') {
        delete paramsQ.vehicleTypeIds
      }

      const res = await this.getRob(paramsQ)

      if (res.status === 'success') {
        if (res.data.data && res.data.data.length > 0) {
          this.rob = res.data.data
          this.total = res.data.total
        }
        this.isLoading = false
      } else {
        this.isLoading = false
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },
    async getLatestRobList ({ code }) {
      const res = await this.getLatestRob(code)
      if (res.status === 'success') {
        if (res.data.data) {
          let data = res.data.data

          data.rob.forEach(rob => {
            rob.robId = rob.robId
            rob.fixActualRob = rob.actualRob
            rob.opening = rob.actualRob
            rob.consumption = 0
            rob.received = 0
            rob.transferred = 0
          })

          this.form.robs = data.rob
          this.form.vehicleId = code
        }
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },

    async save() {
      this.form.date = this.$options.filters.parseDate(
        this.form.date,
        'DD-MM-YYYY'
      )
      const res = await this.saveRob({
        method: this.selectedDetailVehicleId ? 'put' : 'post',
        data: this.form
      })
      if (res.status === 'success') {
        this.resetForm()
        this.$bvModal.hide('form-rh')
        this.getRobList()
        this.$swal(
          `Maintenance Rob!`,
          `Maintenance Rob successfully Saved`,
          'success'
        )
      } else {
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },

    edit (data) {
      this.form.date = this.$options.filters.parseDate(
        data.date,
        'YYYY-MM-DD'
      )
      this.form.remark = data.remark
      this.form.selectedVehicle = {
        name: data.vehicle.name,
        code: data.vehicle.id
      }
      this.selectedDetailVehicleId = data.vehicle.id
      this.getLatestRobList({ code: data.vehicle.id })
      this.$bvModal.show('form-rh')
    },

    deleteData (vehicleId, date) {
      this.$swal({
        title: 'Delete Rob?',
        text: `Rob will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = await this.deleteRob({ vehicleId, date })
          if (res.status !== 'success') {
            if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
          } else {
            this.getRobList()
            this.$swal(
              `Maintenance Rob!`,
              `Maintenance Rob successfully deleted`,
              'success'
            )
          }
        }
      })
    },

    resetForm () {
      this.selectedDetailVehicleId = null
      this.form = {
        selectedVehicle: null,
        selectedCategory: null,
        date: '',
        remark: '',
        vehicleId: '',
        robs: []
      }
    },
    onChangeDate() {
      this.params.startDate = this.dateRange ? moment(this.dateRange[0]).format('DD-MM-YYYY') : null
      this.params.endDate = this.dateRange ? moment(this.dateRange[1]).format('DD-MM-YYYY') : null

      if(this.params.startDate && this.params.endDate) {
        let a = moment(this.dateRange[0])
        let b = moment(this.dateRange[1])
        let diffDays = b.diff(a, 'days')

        if(diffDays > 31) {
          this.$swal(
            `Oops!`,
            'Maksimal Rentang Tanggal adalah 31 Hari',
            'error'
          )
          this.dateRange = []
        }
      }
    },
    calculateActualRob(index) {
      if(this.form.robs[index]) {
        // this.form.robs[index].actualRob = parseInt(this.form.robs[index].fixActualRob) + (parseInt(this.form.robs[index].opening) + parseInt(this.form.robs[index].consumption) + parseInt(this.form.robs[index].received) + parseInt(this.form.robs[index].transferred))
        this.form.robs[index].actualRob = (parseInt(this.form.robs[index].opening) + parseInt(this.form.robs[index].received)) - (parseInt(this.form.robs[index].consumption) + parseInt(this.form.robs[index].transferred))
      }
    },
    initFilterDateRange () {
      var date = new Date(); var y = date.getFullYear(); var m = date.getMonth()
      var firstDay = new Date(y, m, 1)
      var lastDay = new Date(y, m + 1, 0)

      this.dateRange = [firstDay, lastDay]
    },
    toggleModalFilter(){
      this.modal.filter = !this.modal.filter
    }
  },
  mounted () {
    this.initFilterDateRange()
    this.onChangeDate()
    this.fetchFleets()
    this.fetchFleetTypes()
    this.getRobList()
    xray.index()
  }
}
</script>
