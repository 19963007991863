<template>
  <b-row>
    <b-col lg="12" class="px-5 py-2">
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title text-primary"><strong>List of Crew PKL Not Completed</strong></h4>
        </template>
        <template v-slot:body>
          <b-row>
            <b-col md="12">
              <div class="d-flex w-100 align-items-center justify-content-between mb-4">
                <b-button variant="primary" @click="onExportListPklCrew" v-if="list?.data?.length > 0">
                  <div class="d-flex gap-2 align-items-center"> 
                    <span class="spinner-border spinner-border-sm mr-2" v-if="exporting"></span>
                    <i class="fa fa-file-excel mr-2" v-else></i> 
                    <span>Export</span>
                  </div>
                </b-button>
              </div>
            </b-col>
            <b-col md="3">
              <b-form-group label="Search">
                <b-form-input v-model="params.filter" placeholder="Search..."></b-form-input>
              </b-form-group>
            </b-col> 
            <b-col md="3">
              <b-form-group label="Fleet">
                <v-select
                  v-model="params.vehicle_id"
                  label="text"
                  :options="fleetOptions"
                  :reduce="
                    (fleetOptions) => fleetOptions.value
                  "
                  placeholder="Select..."
                  required
                ></v-select>
              </b-form-group>
            </b-col> 
            <b-col md="3">
              <b-form-group label="Company">
                <v-select
                  v-model="params.company_id"
                  label="text"
                  :options="optionsCompanies"
                  :reduce="
                    (optionsCompanies) => optionsCompanies.value
                  "
                  placeholder="Select..."
                  required
                ></v-select>
              </b-form-group>
            </b-col> 
            <b-col md="1">
              <b-form-group label="Filter">
                <b-button
                  @click="getListPklCrew"
                  variant="primary"
                  ><i class="fa fa-filter"></i
                ></b-button>
              </b-form-group>
            </b-col>
            <b-col  v-if="loading" md="12">
              <div class="alert alert-info m-4 align-items-center ">
                <span class="spinner-border spinner-border-sm mr-2"></span>  Loading...
              </div>
            </b-col v-else>
            <b-col md="12">
              <div class="d-flex overflow-auto" v-if="list?.data?.length > 0">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th class=" text-center">NO</th>
                      <th class=" text-center text-truncate">Name</th>
                      <th class=" text-center text-truncate">Fleet</th>
                      <th class=" text-center text-truncate">Position</th>
                      <th class=" text-center text-truncate">Join on Board</th>
                      <th class=" text-center text-truncate ">Sign On</th>
                      <th class=" text-center text-truncate">Sign Off</th>
                      <th class=" text-center">Status</th>
                    </tr>
                  </thead>
  
                  <tbody>
                    <tr v-for="(crew, i) in list?.data" :key="'crew-' + i" >
                      <td class="text-center">{{ i + 1 }}</td>
                      <td class="text-truncate">
                        <router-link
                          :to="{
                            path: `profile`,
                            query: {
                              user: 'crew',
                              id: crew?.id,
                            },
                          }">{{ crew?.fullname ?? '-' }}</router-link><br />
                          <small style="color: gray">NIP : <b>{{ crew?.nip ?? '-' }}</b></small>
                      </td>
                      <td class="text-center text-truncate">{{ crew?.vehicle?.name ?? '-' }}
                        <br />
                        <small style="color: gray">{{crew?.company?.company ?? '-'}}</small>
                      </td>
                      <td class="text-center text-truncate">{{ crew?.position?.name ?? '-' }} </td>
                      <td class="text-center text-truncate">{{ crew?.join_on_board ? moment(crew?.join_on_board).format('DD MMM YYYY HH:mm') : '-' }}
                        <b-badge
                          :variant="
                            joinOnBoardStatus(crew) === 'More than 1 year'
                              ? 'success d-block'
                              : joinOnBoardStatus(crew) === 'More than 6 month'
                              ? 'primary d-block'
                              : joinOnBoardStatus(crew) === 'More than 3 month'
                              ? 'warning d-block'
                              : 'dark d-block'
                          "
                          v-if="joinOnBoardStatus(crew)">{{ joinOnBoardStatus(crew) }}</b-badge>
                      </td>
                      <td class="text-center text-truncate">{{ crew?.sign_on ? moment(crew?.sign_on).format('DD MMM YYYY HH:mm') : '-' }}</td>
                      <td class="text-center text-truncate">{{ crew?.sign_off ? moment(crew?.sign_off).format('DD MMM YYYY HH:mm') :  '-' }} <br /> </td>
                      <td class="text-center text-truncate">
                        <b-badge class="mb-1" :variant="`${crew?.status_emp_crewing?.alias === 'active' ? 'success' : 'warning'} d-block`"
                          >{{ crew?.status_emp_crewing?.name ?? '-' }}
                        </b-badge>
                        <b-badge variant="danger d-block" v-if="expiredContract(crew)"
                          >{{ expiredContract(crew) }}
                        </b-badge>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="mt-3" v-if="list?.data?.length > 0">
                <b-pagination
                  :value="list?.current_page"
                  :total-rows="list?.total"
                  :per-page="list?.per_page"
                  first-number
                  @change="getListPklCrew"
                  align="center"
                />
              </div>
              <p v-else class="text-center my-4">List Crew PKL Not Completed doesnt Exist.</p>
            </b-col>
          </b-row>
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>

<script>
import moment from 'moment'
import { saveAs } from 'file-saver'
import { crewingActions } from '@src/Utils/helper'

export default {
  name: 'SlideListPkl',
  props:{  
    fleetOptions:{
      type:Array,
      default:() => []
    }, 
    optionsCompanies:{
      type:Array,
      default:() => []
    },
  },
  data(){
    return {
      loading:false,
      exporting:false,
      filter:'',

      params:{
        filter:'',
        company_id:null,
        vehicle_id:null,
      },

      list: []
    }
  },
  computed: {
    filteredList() {
      const searchLower = this.filter.toLowerCase();
      return this.list?.filter((item) => item?.crewing?.fullname?.toLowerCase().includes(searchLower));
    },
  },
  created() {
    this.getListPklCrew = _.debounce(this.getListPklCrew, 500)
  },
  watch:{
     'filter': {
      async handler(value) {
        this.getListPklCrew()
      },
      immediate:true,
      deep: true,
    }, 
  },
  async mounted(){
    await this.getListPklCrew()
  },
  methods:{
    ...crewingActions,
    joinOnBoardStatus(data) {
      if (!data?.join_on_board) return ''

      if (data?.working_period > 12) {
        return 'More than 1 year'
      } else if (data?.working_period > 6) {
        return 'More than 6 month'
      } else if (data?.working_period > 3) {
        return 'More than 3 month'
      } else {
        return 'Less than 3 month'
      }
    },
    expiredContract(data) {
      return moment(data?.sign_off).format('DD-MM-YYYY') <
        moment(new Date()).format('DD-MM-YYYY') &&
        data?.status_emp_crewing?.name === 'Active'
        ? 'Out of Contract'
        : ''
    },
    async getListPklCrew(page){
      this.loading = true
  
      let params = {
        export: 0,
        page: page || 1,
        per_page:25,
        ...this.params
      }

      if(!params.filter) delete params.filter
      if(!params.vehicle_id) delete params.vehicle_id
      if(!params.company_id) delete params.filtcompany_ider

      const res = await this.fetchListPklCrew(params)

      if(res.status === "success"){
        this.list = res.data
        this.loading = false
      } else {
        this.list = null
        this.loading = false
      }
    },
    async onExportListPklCrew(){
      try {
        this.exporting = true

        let params = {
          export: 1,
          filter: this.filter
        }
        if(!params.filter) delete params.filter

        const res = await this.exportListPklCrew(params)

        saveAs(res.data, 'List of Crew PKL Not Completed.xlsx')
        this.exporting = false
      } catch (error) {
        this.$swal(`Oops!`, 'Export Failed: ' + error, 'error')
        this.exporting = false
      }
    },
  }
}
</script>