<template>
  <div>
    <b-row>
      <b-col sm="8">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title  text-primary">
              <strong>List Master Question Type</strong>
            </h4>
          </template>
          <template #headerAction>
            <b-form
              class="row w-100"
              @submit.prevent="getQuestionType()"
            >
              <b-col cols="12" md="10" class="p-0">
                <b-form-input
                  id="name"
                  type="text"
                  placeholder="Search..."
                  v-model="questionTypeList.params.name"
                ></b-form-input>
              </b-col>
              <b-col cols="12" md="2" class="p-0">
                <b-button type="submit" variant="primary" class="ml-2"
                  ><i class="fa fa-search"></i
                ></b-button>
              </b-col>
            </b-form>
          </template>
          <template v-slot:body>
            <div v-if="questionTypeList._loading" class="text-center my-5">
              <b-spinner type="grow" label="Spinning"></b-spinner>
            </div>
            <template v-else>
              <div class="table-responsive" v-if="questionTypeList.data && questionTypeList.data.length > 0">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th width="10%">No</th>
                      <th width="25%">Code</th>
                      <th width="25%">Name</th>
                      <th width="25%">Rule</th>
                      <th width="10%">Status</th>
                      <th width="15%" class="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(p, i) in questionTypeList.data" :key="`p-${i}`">
                      <td>{{ i + 1 }}</td>
                      <td>{{ p.code }}</td>
                      <td>{{ p.name }}</td>
                      <td>{{ p.rule }}</td>
                      <td>{{ p.status ? 'Active' : 'Inactive' }}</td>
                      <td>
                        <div class="d-flex">
                          <b-button variant="success" size="sm" class="mr-2" @click="editFormQuestionType(p)"><i class="fa fa-edit"></i></b-button>
                          <b-button variant="danger" size="sm" @click="onDeleteQuestionType(p)">
                            <i v-if="!delete_question_type" class="fa fa-trash"></i>
                            <template v-else>
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Deleting...
                            </template>
                          </b-button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="questionTypeList.data && questionTypeList.data.length > 0">
                <b-pagination
                  v-model="questionTypeList.meta.page"
                  :total-rows="questionTypeList.meta.totalItem"
                  @change="getQuestionType"
                  :per-page="questionTypeList.meta.perPage"
                  first-number
                  align="center"
                ></b-pagination>
              </div>
              <p v-else class="text-center my-4">No Question Type data found.</p>
            </template>
          </template>
        </iq-card>
      </b-col>
      <b-col sm="4">
        <iq-card
          :class="{
            'border border-danger shadow-lg': question_type_edit,
          }"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title"
            >{{ question_type_edit ? 'Edit' : 'Add' }} Question Type</h4
            >
          </template>
          <template v-slot:body>
            <b-form @submit.prevent="onSaveQuestionType()">
              <b-form-group label="Code" label-for="code">
                <b-form-input v-model="formQuestionType.code" id="code" type="text" placeholder="Type text" maxlength="30"></b-form-input>
              </b-form-group>
              <b-form-group label="Name" label-for="name">
                <b-form-input v-model="formQuestionType.name" id="name" type="text" placeholder="Type text" maxlength="30"></b-form-input>
              </b-form-group>
              <b-form-group label="Rule" label-for="rule">
                <b-form-input v-model="formQuestionType.rule" id="rule" type="text" placeholder="Type text" maxlength="30"></b-form-input>
              </b-form-group>
              <b-form-group label="Status" label-for="status">
                <b-form-checkbox v-model="formQuestionType.status" name="check-button" switch inline>Active / Inactive
                </b-form-checkbox>
              </b-form-group>
              <hr />
              <b-form-group class="text-right">
                <b-button type="submit" variant="primary">
                  <span v-if="!question_type_edit">Add</span>
                  <span v-else>Save Edit</span>
                </b-button>
                <b-button
                  type="button"
                  variant="danger"
                  class="iq-bg-danger ml-3"
                  @click="resetFormQuestionType()"
                >Cancel</b-button>
              </b-form-group>
            </b-form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { busdevActions } from '@src/Utils/helper'
import _ from 'lodash'
export default {
  name: 'MasterQuestionType',
  data () {
    return {
      listQuestionType:[],
      questionTypeList: {
        data: [],
        _loading: true,
        detail: null,
        params: {
          name: '',
          // active: true,
          perPage: 30,
          page: 1
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        }
      },

      loading_question_type:false,
      saving_question_type:false,
      delete_question_type:false,

      formQuestionType: {
        "code": "",
        "name": "",
        "rule": "",
        "status": true // 1 = active, 0 = inactive
      },

      question_type_edit: null,
      question_type_id: null
    }
  },
  async mounted () {
    await this.getQuestionType()
  },
  created () {
    this.getQuestionType = _.debounce(this.getQuestionType, 200)
  },
  methods: {
    ...busdevActions,

    async getQuestionType (page) {
      this.questionTypeList._loading = true
      this.questionTypeList.data = []
      let params = {
        ...this.questionTypeList.params,
        page: page || 1
      }
      if(!params.name) delete params.name 

      const res = await this.fetchQuestionType(params)
  
      if (res.data.status == true) {
        if(res?.data?.data?.data.length > 0){
          this.questionTypeList.data = res.data.data?.data
          this.questionTypeList.meta = res.data.data?.meta
        }
        this.questionTypeList._loading = false
      } else {
        this.questionTypeList._loading = false
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },

    async onSaveQuestionType () {
      this.saving_question_type = true
      let payload = {
        ...this.formQuestionType,
        status: this.formQuestionType.status ? 1 : 0
      }

      let res = null
      if(this.question_type_edit && this.question_type_id){
        res = await this.updateQuestionType({data: payload, id: this.question_type_id})
      } else if (!this.question_type_edit) {
        res = await this.createQuestionType({data: payload})
      }

      console.log(res.data, "what is this")

      if (res.data.status === true) {
        this.$swal(`${this.question_type_edit ? 'Edit' : "Add"} Question Type Success!`, res.data.message, 'success')
        this.resetFormQuestionType()
        this.getQuestionType()
        this.saving_question_type = false
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
        this.saving_question_type = false
      }
      this.saving_question_type = false
    },

    resetFormQuestionType () {
      this.formQuestionType = {
        "code": "",
        "name": "",
        "rule": "",
        "status": true // 1 = active, 0 = inactive
      }
      this.question_type_edit = false
      this.question_type_id = null
    },

    editFormQuestionType (data) {
      this.formQuestionType = {
        "code": data?.code,
        "name": data?.name,
        "rule": data?.rule,
        "status": data?.status ? true : false // 1 = active, 0 = inactive
      }
      this.question_type_edit = true
      this.question_type_id = data.id
    },

    async onDeleteQuestionType (data) {
      this.$swal({
        title: 'Delete Question Type?',
        text: `Question Type ${data.name} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.saving_question_type = true
          let res = await this.deleteQuestionType({id: data.id})
          if (res.status != 'success') {
            if (res.data.message != null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
            this.saving_question_type = false
          } else {
            this.$swal(
              `Question Type deleted!`,
              `Question Type ${data.name} successfully deleted`,
              'success'
            )
            await this.getQuestionType()
            this.saving_question_type = false
          }
        }
      })
    },
  }
}
</script>

<style scoped>
</style>
