var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-sidebar', {
    staticClass: "p-0 m-0",
    attrs: {
      "visible": _vm.isOpen,
      "right": "",
      "width": "700px",
      "bg-variant": "white",
      "header-class": "header-class",
      "shadow": ""
    },
    on: {
      "hidden": _vm.handleCloseModal
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div')];
      },
      proxy: true
    }, {
      key: "default",
      fn: function fn() {
        return [_c('div', {
          staticClass: "container-slider"
        }, [_c('div', {
          staticClass: "w-100 border-bottom d-flex justify-content-between align-items-center shadow-sm head-slider"
        }, [_c('i', {
          staticClass: "fas fa-close cursor-pointer title",
          on: {
            "click": _vm.handleCloseModal
          }
        }), _c('strong', {
          staticClass: "p-0 title"
        }, [_vm._v("Create Repair Maintenance")])]), _c('div', {
          staticClass: "w-100 flex justify-content-center"
        }, [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.handleSubmit.apply(null, arguments);
            }
          }
        }, [_c('b-row', {
          staticClass: "p-4"
        }, [_vm._l(_vm.fields, function (item, i) {
          return _c('b-col', {
            key: i,
            attrs: {
              "sm": item.cols
            }
          }, [item.type == 'select' ? [_c('b-form-group', {
            attrs: {
              "label-align": "left",
              "label-for": item.label,
              "label": item.label
            }
          }, [_c('b-select', {
            attrs: {
              "plain": "",
              "id": item.label,
              "options": item.options,
              "size": "lg"
            }
          })], 1)] : _vm._e(), item.type == 'date' ? [_c('b-form-group', {
            attrs: {
              "label-align": "left",
              "label-for": item.label,
              "label": item.label
            }
          }, [_c('date-picker', {
            staticStyle: {
              "width": "100%"
            },
            attrs: {
              "format": "DD MMM YYYY",
              "type": "date",
              "id": item.label,
              "placeholder": "Select date"
            }
          })], 1)] : _vm._e(), item.type == 'file' ? [_c('b-form-group', {
            attrs: {
              "label-align": "left",
              "label-for": item.label,
              "label": item.label
            }
          }, [_c('b-form-file', {
            attrs: {
              "multiple": "",
              "id": item.label,
              "placeholder": "Choose a file",
              "accept": "application/pdf"
            }
          })], 1)] : _vm._e(), item.type == 'area' ? [_c('b-form-group', {
            attrs: {
              "label-align": "left",
              "label-for": item.label,
              "label": item.label,
              "placeholder": ""
            }
          }, [_c('wysiwyg', {
            style: {
              height: '300px'
            },
            attrs: {
              "id": item.label
            }
          })], 1)] : _vm._e()], 2);
        }), _c('b-col', {
          attrs: {
            "sm": "6"
          }
        }, [_c('button', {
          staticClass: "btn btn-primary w-100 p-2",
          attrs: {
            "type": "submit"
          }
        }, [_vm._v("Submit")])])], 2)], 1)], 1)])];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }