var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "headerClass": "bg-head-budget-docking-category"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-white"
        }, [_c('strong', [_vm._v("BUDGET DOCKING BY CATEGORY")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticStyle: {
            "min-height": "350px"
          }
        }, [_c('ApexChart', {
          attrs: {
            "element": "chart-budget-docking-by-category",
            "chartOption": _vm.chartBudgetDockingCategory
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }