var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Inventory Adjustment")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center gap-4 float-right"
        }, [_c('a', {
          staticClass: "text-primary mr-4",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          },
          on: {
            "click": _vm.onExportStockAdjustment
          }
        }, [_c('i', {
          staticClass: "fa fa-file-excel mr-2"
        }), _c('span', [_vm._v("Export Data")])]), _c('a', {
          staticClass: "text-primary mr-4",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          },
          on: {
            "click": function click($event) {
              return _vm.openslideFormStockAdjustment('ADD', null, 'TRANSFER');
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus mr-2"
        }), _c('span', [_vm._v("Transfer")])]), _c('a', {
          staticClass: "text-primary mr-4",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          },
          on: {
            "click": function click($event) {
              return _vm.openslideFormStockAdjustment('ADD', null, 'ADJUSTMENT');
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus mr-2"
        }), _c('span', [_vm._v("Adjustment")])])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$list, _vm$list$data, _vm$list2, _vm$list2$data, _vm$list3, _vm$list3$meta, _vm$list4, _vm$list4$meta;
        return [_c('b-row', {
          staticClass: "mb-4"
        }, [_c('b-col', {
          staticClass: "mb-4",
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "w-100 d-flex border"
        })]), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "search"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Search Code/Name...",
            "name": "search",
            "value": _vm.value
          },
          on: {
            "input": function input($event) {
              return _vm.$emit('input', $event.target.value);
            }
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Date",
            "label-for": "search"
          }
        }, [_c('date-picker', {
          attrs: {
            "type": "date",
            "range": "",
            "format": "DD MMM YYYY",
            "placeholder": "Select date range"
          },
          model: {
            value: _vm.dateRange,
            callback: function callback($$v) {
              _vm.dateRange = $$v;
            },
            expression: "dateRange"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Filter",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          attrs: {
            "type": "button",
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.getListStockAdjustment(1, _vm.dateRange);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_vm.loading ? _c('div', {
          staticClass: "alert alert-info"
        }, [_vm._v("Loading...")]) : _vm._e(), _c('iq-card', [((_vm$list = _vm.list) === null || _vm$list === void 0 ? void 0 : (_vm$list$data = _vm$list.data) === null || _vm$list$data === void 0 ? void 0 : _vm$list$data.length) > 0 ? _c('div', {
          staticClass: "overflow-auto d-flex"
        }, [_c('table', {
          staticClass: "table table-striped table-bordered"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center",
          staticStyle: {
            "width": "20px"
          }
        }, [_vm._v("No")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Adjustment Type")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Vehicle")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Date")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Proof Number")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Total Qty")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Notes")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Total Items")]), _c('th', {
          staticClass: "text-center",
          staticStyle: {
            "width": "20px"
          }
        }, [_vm._v("#")])])]), _c('tbody', _vm._l(_vm.list.data, function (state, index) {
          var _state$from_vehicle, _state$to_vehicle, _state$to_vehicle2, _state$notes, _state$details;
          return _c('tr', {
            key: index
          }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', {
            staticClass: "text-truncate"
          }, [_c('a', {
            staticClass: "text-primary mr-4",
            staticStyle: {
              "cursor": "pointer"
            },
            attrs: {
              "role": "button"
            },
            on: {
              "click": function click($event) {
                return _vm.openSlideDetailAdjustment(state);
              }
            }
          }, [_c('span', [_vm._v(_vm._s((state === null || state === void 0 ? void 0 : state.type) === 'stock_in' ? 'Stock In' : 'Stock Out'))])])]), _c('td', [state !== null && state !== void 0 && state.from_vehicle_id ? _c('div', {
            attrs: {
              "className": "d-flex flex-column"
            }
          }, [_c('span', {
            staticClass: "text-truncate"
          }, [_vm._v("From: "), _c('strong', {
            staticClass: "text-muted"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$from_vehicle = state.from_vehicle) === null || _state$from_vehicle === void 0 ? void 0 : _state$from_vehicle.name))])]), _vm._v(" "), _c('br'), _c('span', {
            staticClass: "text-truncate"
          }, [_vm._v("To: "), _c('strong', {
            staticClass: "text-muted"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$to_vehicle = state.to_vehicle) === null || _state$to_vehicle === void 0 ? void 0 : _state$to_vehicle.name))])])]) : _c('div', [_c('strong', {
            staticClass: "text-truncate text-muted"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$to_vehicle2 = state.to_vehicle) === null || _state$to_vehicle2 === void 0 ? void 0 : _state$to_vehicle2.name))])])]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(state !== null && state !== void 0 && state.date ? _vm.moment(state === null || state === void 0 ? void 0 : state.date).format('DD MMM YYYY') : '-'))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.proof_number))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(_vm.countTotalQty(state === null || state === void 0 ? void 0 : state.details)))]), _c('td', [_vm._v(_vm._s((_state$notes = state === null || state === void 0 ? void 0 : state.notes) !== null && _state$notes !== void 0 ? _state$notes : '-'))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$details = state.details) === null || _state$details === void 0 ? void 0 : _state$details.length))]), _c('td', [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('i', {
            staticClass: "fa fa-pen text-primary mr-3",
            staticStyle: {
              "cursor": "pointer"
            },
            on: {
              "click": function click($event) {
                return _vm.openslideFormStockAdjustment('EDIT', state, 'ADJUSTMENT');
              }
            }
          }), _c('i', {
            staticClass: "fa fa-trash text-danger",
            staticStyle: {
              "cursor": "pointer"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeleteStockAdjustment(state === null || state === void 0 ? void 0 : state.id);
              }
            }
          })])])]);
        }), 0)])]) : _vm._e(), ((_vm$list2 = _vm.list) === null || _vm$list2 === void 0 ? void 0 : (_vm$list2$data = _vm$list2.data) === null || _vm$list2$data === void 0 ? void 0 : _vm$list2$data.length) > 0 ? _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": (_vm$list3 = _vm.list) === null || _vm$list3 === void 0 ? void 0 : (_vm$list3$meta = _vm$list3.meta) === null || _vm$list3$meta === void 0 ? void 0 : _vm$list3$meta.totalPage,
            "per-page": (_vm$list4 = _vm.list) === null || _vm$list4 === void 0 ? void 0 : (_vm$list4$meta = _vm$list4.meta) === null || _vm$list4$meta === void 0 ? void 0 : _vm$list4$meta.limit,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": function change($event) {
              return _vm.getListStockAdjustment($event, _vm.dateRange);
            }
          },
          model: {
            value: _vm.page,
            callback: function callback($$v) {
              _vm.page = $$v;
            },
            expression: "page"
          }
        })], 1) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("No Stock Adjustment data found.")])])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "form-StockAdjustment",
      "title": "".concat(_vm.action, " Stock Adjustment"),
      "width": "100%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_c('b-row', {
          staticClass: "m-3"
        }, [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 text-primary"
        }, [_vm._v("Stock")]), _c('b-row', {
          staticClass: "mt-2"
        }, [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('FormStock', {
          attrs: {
            "action": _vm.action,
            "type": _vm.type,
            "edit-id": _vm.editId,
            "detailData": _vm.detailData,
            "closeSlide": _vm.closeslideFormStockAdjustment,
            "refresh-data": _vm.refreshData,
            "optionsFleets": _vm.optionsFleets,
            "vehicle_type_id": _vm.vehicle_type_id
          }
        })], 1)], 1)], 1)])], 1)];
      }
    }]),
    model: {
      value: _vm.slideFormStockAdjustment,
      callback: function callback($$v) {
        _vm.slideFormStockAdjustment = $$v;
      },
      expression: "slideFormStockAdjustment"
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "detail-adjustment",
      "title": "Preview Detail",
      "width": "100%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var hide = _ref2.hide;
        return [_c('b-row', {
          staticClass: "m-3"
        }, [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('DetailStockAdjustment', {
          attrs: {
            "detailData": _vm.detailData,
            "closeSlide": _vm.clodeSlideDetailAdjustment
          }
        })], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.slideDetailAdjustment,
      callback: function callback($$v) {
        _vm.slideDetailAdjustment = $$v;
      },
      expression: "slideDetailAdjustment"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }