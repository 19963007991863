var render = function render(){
  var _vm$detailForm$code, _vm$detailForm, _vm$detailForm$name, _vm$detailForm2, _vm$detailForm$title, _vm$detailForm3, _vm$detailForm$descri, _vm$detailForm4, _vm$detailForm$questi, _vm$detailForm5, _vm$detailForm5$quest, _vm$detailForm6, _vm$detailForm7, _vm$detailForm8, _vm$detailForm9, _vm$detailForm10, _vm$detailForm11, _vm$detailForm11$coll;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Forms")]), _c('table', {
    staticClass: "table mb-0 table-striped"
  }, [_c('tbody', [_c('tr', [_c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Code")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s((_vm$detailForm$code = (_vm$detailForm = _vm.detailForm) === null || _vm$detailForm === void 0 ? void 0 : _vm$detailForm.code) !== null && _vm$detailForm$code !== void 0 ? _vm$detailForm$code : '-'))]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Name")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s((_vm$detailForm$name = (_vm$detailForm2 = _vm.detailForm) === null || _vm$detailForm2 === void 0 ? void 0 : _vm$detailForm2.name) !== null && _vm$detailForm$name !== void 0 ? _vm$detailForm$name : '-'))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Title")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s((_vm$detailForm$title = (_vm$detailForm3 = _vm.detailForm) === null || _vm$detailForm3 === void 0 ? void 0 : _vm$detailForm3.title) !== null && _vm$detailForm$title !== void 0 ? _vm$detailForm$title : '-'))]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Description")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s((_vm$detailForm$descri = (_vm$detailForm4 = _vm.detailForm) === null || _vm$detailForm4 === void 0 ? void 0 : _vm$detailForm4.description) !== null && _vm$detailForm$descri !== void 0 ? _vm$detailForm$descri : '-'))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Question Type")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s((_vm$detailForm$questi = (_vm$detailForm5 = _vm.detailForm) === null || _vm$detailForm5 === void 0 ? void 0 : (_vm$detailForm5$quest = _vm$detailForm5.question_type) === null || _vm$detailForm5$quest === void 0 ? void 0 : _vm$detailForm5$quest.name) !== null && _vm$detailForm$questi !== void 0 ? _vm$detailForm$questi : '-'))]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Form for Vehicle")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s((_vm$detailForm6 = _vm.detailForm) !== null && _vm$detailForm6 !== void 0 && _vm$detailForm6.form_for_vehicle ? 'Yes' : 'No'))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Using Remarks")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s((_vm$detailForm7 = _vm.detailForm) !== null && _vm$detailForm7 !== void 0 && _vm$detailForm7.using_remarks ? 'Yes' : 'No'))]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Status")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s((_vm$detailForm8 = _vm.detailForm) !== null && _vm$detailForm8 !== void 0 && _vm$detailForm8.status ? 'Active' : 'Inacive'))])])])])])]), _c('b-col', {
    attrs: {
      "md": '6'
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Field")]), _c('table', {
    staticClass: "table mb-0 table-striped"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Label")]), _c('th', [_vm._v("Type")])])]), _c('tbody', _vm._l((_vm$detailForm9 = _vm.detailForm) === null || _vm$detailForm9 === void 0 ? void 0 : _vm$detailForm9.field, function (state, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.label))]), _c('td', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.type))])]);
  }), 0)])])]), _c('b-col', {
    attrs: {
      "md": '6'
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Signature")]), _c('table', {
    staticClass: "table mb-0 table-striped"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Label")]), _c('th', [_vm._v("Type")])])]), _c('tbody', _vm._l((_vm$detailForm10 = _vm.detailForm) === null || _vm$detailForm10 === void 0 ? void 0 : _vm$detailForm10.signature, function (state, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.label))]), _c('td', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.type))])]);
  }), 0)])])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Question Type Content")]), ((_vm$detailForm11 = _vm.detailForm) === null || _vm$detailForm11 === void 0 ? void 0 : (_vm$detailForm11$coll = _vm$detailForm11.collection_of_forms_category) === null || _vm$detailForm11$coll === void 0 ? void 0 : _vm$detailForm11$coll.length) > 0 ? _c('div', {
    staticClass: "table-responsive"
  }, _vm._l(_vm.detailForm.collection_of_forms_category, function (parentQuestion, parentQuestionIndex) {
    var _parentQuestion$inspe, _parentQuestion$inspe2;
    return _c('table', {
      key: parentQuestionIndex,
      staticClass: "table table-striped"
    }, [_c('thead', [!parentQuestion.parent_id && (parentQuestion === null || parentQuestion === void 0 ? void 0 : (_parentQuestion$inspe = parentQuestion.inspection_checklist_question) === null || _parentQuestion$inspe === void 0 ? void 0 : _parentQuestion$inspe.length) <= 0 ? _c('tr', [_c('th', {
      staticClass: "align-middle text-center",
      attrs: {
        "colspan": "6"
      }
    }, [_vm._v(_vm._s(parentQuestion === null || parentQuestion === void 0 ? void 0 : parentQuestion.name))])]) : _c('tr', [_c('th', {
      staticStyle: {
        "max-width": "20px"
      }
    }, [_vm._v("No")]), _c('th', [_vm._v(_vm._s(parentQuestion === null || parentQuestion === void 0 ? void 0 : parentQuestion.name))])])]), (parentQuestion === null || parentQuestion === void 0 ? void 0 : (_parentQuestion$inspe2 = parentQuestion.inspection_checklist_question) === null || _parentQuestion$inspe2 === void 0 ? void 0 : _parentQuestion$inspe2.length) > 0 ? _c('tbody', _vm._l(parentQuestion === null || parentQuestion === void 0 ? void 0 : parentQuestion.inspection_checklist_question, function (question, questionId) {
      return _c('tr', {
        key: questionId
      }, [_c('td', {
        staticStyle: {
          "max-width": "20px"
        }
      }, [_vm._v(_vm._s(questionId + 1))]), _c('td', [_vm._v(_vm._s(question.question))])]);
    }), 0) : _vm._e()]);
  }), 0) : _vm._e()])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }