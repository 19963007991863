<template>
  <b-row class="px-3">
    <b-col md="12">
      <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
        <template #headerTitle>
          <h5 class="card-title text-primary"
            ><strong>Budget Technical</strong></h5
          >
        </template>
        <template #body>
          <b-row>
            <b-col md="12">
              <table class="table mb-0 table-striped table-hover">
                <thead>
                  <th>Company</th>
                  <th class="text-center">
                    <div>% Realization Aug 2024</div>
                    <small>(Budget Vs Realization)</small>
                  </th>
                  <th class="text-center">
                    <div>% Realisasi YTD </div>
                    <small> (Budget Vs Realization)</small>
                  </th>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in chartData" :key="i">
                    <td>
                      <router-link :to="'/ops/budget-technical/' + i">
                        {{ item.title }}
                      </router-link>
                    </td>
                    <td class="text-center" style="color: #18c63e">97.87%</td>
                    <td class="text-center" style="color: red">100.84%</td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col lg="6" v-for="(chart, index) in chartData" :key="index">
              <LineChartBudget
                :title="chart.title"
                :element="`${chart.element}-${index}`"
                :chartData="chart.data"
              />
            </b-col>
          </b-row>
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>

<script>
import LineChartBudget from './Chart/LineChartBudget.vue'

export default {
  components: {
    LineChartBudget,
  },
  data() {
    return {
      chartData: [
        {
          title: 'PT. Energy Transporter Indonesia',
          elemnt: 'energy-transporter-indonesia',
          data: [12, 34, 56, 78, 56, 12, 56, 78, 89, 56, 23, 89],
        },
        {
          title: 'PT. Transcoal Pacific',
          elemnt: 'transcoal-pacific',
          data: [12, 34, 56, 78, 56, 12, 56, 78, 89, 56, 23, 89],
        },
        {
          title: 'PT. Sentra Makmur Lines',
          elemnt: 'sentra-makmur-lines',
          data: [12, 34, 56, 78, 56, 12, 56, 78, 89, 56, 23, 89],
        },
        {
          title: 'PT. Renjani Maritim Transportasi',
          elemnt: 'renjani-maritim-transportasi',
          data: [12, 34, 56, 78, 56, 12, 56, 78, 89, 56, 23, 89],
        },
      ],
    }
  },
}
</script>

<style scoped>
table th {
  background: #012061;
  color: white;
  padding: 4px 12px;
  vertical-align: middle !important;
}
</style>
