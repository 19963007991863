<template>
  <div class="row">
    <b-col md="12" class="mb-4">
      <div class="d-flex align-items-center gap-4 float-right">
        <a class="text-primary mr-4" role="button" style="cursor: pointer" @click="openForm" v-if="addPermission">
          <i class="fa fa-plus mr-2" />
          <span>Create Vendor Type</span>
        </a>
      </div>
    </b-col>
    <b-col md="12">
      <div v-if="loading" class="alert alert-info">Loading...</div>
        <div class="overflow-auto d-flex">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th class="text-center align-middle" style="width: 20px">No</th>
                <th class="text-center text-truncate">Name</th>
                <th class="text-center text-truncate">Status</th>
                <th class="text-center align-middle" style="width: 20px">#</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(state, index) in data" :key="index">
                <td>{{ index + 1 }}</td>
                <td class="text-truncate">{{ state.name }}</td>
                <td>{{ state.status ? "Active" : 'Inactive' }}</td>
                <td v-if="updatePermission || deletePermission">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-pen text-primary mr-3" style="cursor: pointer;" @click="onEdit(state)" />
                    <i class="fa fa-trash text-danger" style="cursor: pointer;" @click="onDelete(state)" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    </b-col>

    <b-col lg="12">
      <b-sidebar v-model="showFormType" id="form-type" :title="`${edit ? 'EDIT' : 'ADD'} VENDOR TYPE`"  width="50%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
        <template #default="{hide}">
          <fieldset class="form-group border p-3 m-5">
            <form  @submit="onSubmit" action="#">
              <legend class="w-auto px-2 text-primary">Vendor Type</legend>
                <b-row class="mt-2">
                  <b-col md="12">
                    <b-form-group label="*Vendor Type Name" label-for="name">
                      <b-form-input
                        required
                        id="name"
                        type="text"
                        placeholder="Type text"
                        v-model="form.name"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group>
                      <b-form-checkbox
                        name="status"
                        switch
                        inline
                        v-model="form.status"
                      >
                        Active / Inactive
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>

                  <b-col md="12">
                    <div class="d-flex align-items-center justify-content-end w-100">
                      <b-button
                        :disabled="saving"
                        block
                        variant="primary"
                        type="submit"
                        style="width:40%"
                      >
                        <span
                          v-if="saving"
                          class="spinner-border spinner-border-sm"
                        />
                        <div v-else>
                          <i class="fa fa-save"></i>
                          <span>Submit Data</span>
                        </div>
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </form>
            </fieldset>
          </template>
      </b-sidebar>
    </b-col>
  </div>
</template>
<script>
  import _ from 'lodash'
  import moment from 'moment'
  import { busdevActions } from '@src/Utils/helper'

  export default {
    name: 'TabVendorType',
    props:{
      refreshMasterData: {
        type: Function,
        default: true
      },
    },
    data() {
      return {
        showFormType:false,

        saving:false,
        loading:false,
        data:[],

        id:null,
        edit:false,
        form: {
          name:'',
          status:true
        }
      }
    },
    components:{},
    watch:{},
    computed: {
      addPermission(){
        let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
          .find(obj => obj.menu === 'Apps').child
          .find(obj => obj.menu === 'Business Development').child
          .find(obj => obj.menu === 'Busdev Vendor').add

        return hasAccess
      },
      updatePermission(){
        let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
          .find(obj => obj.menu === 'Apps').child
          .find(obj => obj.menu === 'Business Development').child
          .find(obj => obj.menu === 'Busdev Vendor').update

        return hasAccess
      },
      deletePermission(){
        let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
          .find(obj => obj.menu === 'Apps').child
          .find(obj => obj.menu === 'Business Development').child
          .find(obj => obj.menu === 'Busdev Vendor').delete

        return hasAccess
      },
    },
    async mounted() {
      await this.getListVendorType()
    },
    methods:{
      ...busdevActions,
      openForm(){  
        this.edit = false
        this.id = null

        this.showFormType = true
        this.$root.$emit('bv::toggle::collapse', `form-type`)
      }, 
      onEdit(data){
        this.edit = true
        this.id = data.id
        this.form = {
          name:data.name,
          status:data.status ? true : false
        }

        this.showFormType = true
        this.$root.$emit('bv::toggle::collapse', `form-type`)
      },
      resetForm(){
        this.form = {
          name:'',
          status:true
        }
      },
      refreshData(){
        this.showFormType = false
        this.getListVendorType()
      },
      async getListVendorType() {
        let res = await this.fetchVendorType()

        if (res.data.status === true) {
          this.data = res.data.data
        }
      },

      async onDelete(data){
        this.$swal({
            title: `Are you sure want to delete ${data?.name} Vendor Type  ?`,
            text: "Yes, i'm sure.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
          if (result.isConfirmed) {
            const res = await this.deleteVendorType({id:data.id})
            if(res.status === false || res.status === 400 || res.status === 500){
              this.$swal(
                'Failed!',
                'Something went wrong.',
                'error'
              )
              return 
            } 
            this.$swal(
              'Success!',
              'Success delete Vendor.',
              'success'
            )
            this.refreshData()
            this.refreshMasterData()
          }
        })
      },

      async onSubmit (e){
        e.preventDefault()
        this.saving = true

        let payload = {
          ...this.form,
        }

        let res = null
        if(this.edit && this.id){
          res = await this.updateVendorType({data: payload, id: this.id})
        } else {
          res = await this.createVendorType({data: payload})
        }

        if([500, 404, 403, 422].includes(res.status)){
          this.saving = false
          this.$swal(`Oops.`, res?.data?.message, 'error')
          return
        }

        if(res?.data?.status === true){
          this.resetForm()
          this.refreshData()
          this.refreshMasterData()
          this.$swal(`Success!`, `Create Vendor Type Success.`, 'success')
        } else {
          this.$swal(`Oops.`, res?.data?.message, 'error')
        }

        this.saving = false

      }
    }
  }
</script>