var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-info rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-info"
        }, [_c('i', {
          staticClass: "fa fa-spinner"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h4', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_c('b', [_vm._v("215")])])]), _c('h6', {}, [_vm._v("Maintenance Towards")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-danger rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-danger"
        }, [_c('i', {
          staticClass: "fas fa-exclamation-triangle"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h4', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_c('b', [_vm._v("375")])])]), _c('h6', {}, [_vm._v("Maintenance Overdue")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-warning rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-warning"
        }, [_c('i', {
          staticClass: "fas fa-history"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h4', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_c('b', [_vm._v("35")])])]), _c('h6', {}, [_vm._v("Maintenance Postponded")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-success rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-success"
        }, [_c('i', {
          staticClass: "far fa-check-circle"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h4', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_c('b', [_vm._v("45")])])]), _c('h6', {}, [_vm._v("Maintenance Active")])])])];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_vm._l(_vm.costDocking, function (item, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "lg": "6"
      }
    }, [_c('iq-card', {
      scopedSlots: _vm._u([{
        key: "headerTitle",
        fn: function fn() {
          return [_c('h4', [_vm._v(_vm._s(item.title))])];
        },
        proxy: true
      }, {
        key: "body",
        fn: function fn() {
          return [_c('HighChart', {
            ref: item.type,
            refInFor: true,
            attrs: {
              "option": item.bodyData
            }
          })];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), _vm._l(_vm.charts, function (item, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "lg": "6"
      }
    }, [_c('iq-card', {
      scopedSlots: _vm._u([{
        key: "headerTitle",
        fn: function fn() {
          return [_c('h4', [_vm._v(_vm._s(item.title))])];
        },
        proxy: true
      }, {
        key: "body",
        fn: function fn() {
          return [_c('ApexChart', {
            attrs: {
              "element": item.type,
              "chartOption": item.bodyData
            }
          })];
        },
        proxy: true
      }], null, true)
    })], 1);
  })], 2), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("MAINTENANCE HISTORY - TB. TAVIA 1")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', {
          staticClass: "mb-3"
        }, [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', [_vm._v("Select Equipment")]), _c('b-form-select-option', [_vm._v("Accomodation")]), _c('b-form-select-option', [_vm._v("Deck & Outfiting")]), _c('b-form-select-option', [_vm._v("Main Engine")])];
            },
            proxy: true
          }])
        })], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "primary",
            "size": "sm"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1), _c('table', {
          staticClass: "table table-striped table-sm",
          staticStyle: {
            "font-size": "10pt"
          }
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("JOB NAME - TYPE")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("EQUIPMENT")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TOTAL MAINTENANCE")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("FREQUESCY")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("LAST MAINTENANCE")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("DUE DATE / HOURS")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("STATUS")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ACTION")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "pl-3"
        }, [_vm._v("WATER TIGHT DOOR - INSPECTION")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("DECK & OUT FITTING EQUIPMENT")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("6")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("300 H")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("1.800 H")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("2.100 H")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("OVERDUE")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "sm",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.detail_maintenance_counter",
            modifiers: {
              "detail_maintenance_counter": true
            }
          }],
          attrs: {
            "href": "#",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-info-circle mr-2"
        }), _vm._v("Detail ")])], 1)], 1)]), _c('tr', [_c('td', {
          staticClass: "pl-3"
        }, [_vm._v("ACCOMODATION ROOM - TEST")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("ACCOMMODATION")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("8")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("3 M")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("12/10/2023")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("12/01/2023")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v("ACTIVE")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "sm",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.detail_maintenance_calendar",
            modifiers: {
              "detail_maintenance_calendar": true
            }
          }],
          attrs: {
            "href": "#",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-info-circle mr-2"
        }), _vm._v("Detail ")])], 1)], 1)]), _c('tr', [_c('td', {
          staticClass: "pl-3"
        }, [_vm._v("ANODE / ZINK ROD - PORT - CLEANING")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("MAIN ENGINE")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("4")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("1.200 H")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("4.800")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("6.000")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("TOWARDS")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "sm",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.detail_maintenance_counter",
            modifiers: {
              "detail_maintenance_counter": true
            }
          }],
          attrs: {
            "href": "#",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-info-circle mr-2"
        }), _vm._v("Detail ")])], 1)], 1)])])]), _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "value": 1,
            "total-rows": 50,
            "align": "center"
          }
        })], 1), _c('b-sidebar', {
          attrs: {
            "id": "detail_maintenance_calendar",
            "title": "TB. TAVIA 1 - ACCOMODATION ROOM",
            "width": "70%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref) {
              var hide = _ref.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Running Hours Info")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("RH ME - PORTSIDER")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "28%"
                }
              }, [_vm._v("820 Hours")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("RH AE - PORTSIDER")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "28%"
                }
              }, [_vm._v("770 Hours")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("RH ME - STARBOARD")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "28%"
                }
              }, [_vm._v("820 Hours")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("RH AE - STARBOARD")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "28%"
                }
              }, [_vm._v("770 Hours")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Maintenance Info")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Job Code")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "28%"
                }
              }, [_vm._v("TV001-01")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Job Class")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "28%"
                }
              }, [_vm._v("CM - CORRECTIVE MAINTENANCE")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Job Type")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "28%"
                }
              }, [_vm._v("JT014 - TEST")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Job Name")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "28%"
                }
              }, [_vm._v("ACCOMODATION ROOM - TEST")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Equipment")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "28%"
                }
              }, [_vm._v("1.1 - ACCOMODATION ROOM")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Interval/Frequency")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "28%"
                }
              }, [_vm._v("3 Month")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Tigger")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "28%"
                }
              }, [_vm._v("CALENDAR")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Status")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "28%"
                }
              }, [_c('b-badge', {
                attrs: {
                  "variant": "success"
                }
              }, [_vm._v("ACTIVE")])], 1)]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Total Cost")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('th', {
                attrs: {
                  "width": "28%"
                }
              }, [_vm._v("Rp. 122.150.000,-")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }), _c('td', {
                attrs: {
                  "width": "28%"
                }
              })])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Equipment Info - ACCOMODATION ROOM")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "23%"
                }
              }, [_vm._v("Type No")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("-")]), _c('th', {
                attrs: {
                  "width": "23%"
                }
              }, [_vm._v("Serial No")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("-")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "23%"
                }
              }, [_vm._v("Maker No")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("-")]), _c('th', {
                attrs: {
                  "width": "23%"
                }
              }, [_vm._v("Maker")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("-")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "23%"
                }
              }, [_vm._v("Drawing No")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("-")]), _c('th', {
                attrs: {
                  "width": "23%"
                }
              }, [_vm._v("Certificate")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("-")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("Additional Information")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("-")]), _c('th', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("Descriptions")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("-")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("ACCOMODATION ROOM")]), _c('table', {
                staticClass: "table table-striped table-sm"
              }, [_c('thead', [_c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("LAST MAINTENANCE")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("TOTAL COST")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("DUE DATE / HOURS")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("ATTACHMENT")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("ACTION")])])]), _c('tbody', [_c('tr', [_c('td', {
                staticClass: "text-center"
              }, [_vm._v("28/09/2023")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Rp. 18.750.000")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("28/12/2023")]), _c('td', {
                staticClass: "text-center"
              }, [_c('i', {
                staticClass: "far fa-file-pdf text-primary"
              })]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-dropdown', {
                attrs: {
                  "size": "sm",
                  "variant": "link iq-bg-primary iq-border-radius-10",
                  "toggle-class": "text-decoration-none",
                  "no-caret": ""
                },
                scopedSlots: _vm._u([{
                  key: "button-content",
                  fn: function fn() {
                    return [_c('span', {
                      staticClass: "dropdown-toggle p-0",
                      attrs: {
                        "id": "dropdownMenuButton5",
                        "data-toggle": "dropdown"
                      }
                    }, [_c('i', {
                      staticClass: "fa fa-pencil-square-o m-0 text-primary"
                    })])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-dropdown-item', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.detail_maintenance",
                  modifiers: {
                    "detail_maintenance": true
                  }
                }],
                attrs: {
                  "href": "#",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list mr-2"
              }), _vm._v("Notes ")])], 1)], 1)]), _c('tr', [_c('td', {
                staticClass: "text-center"
              }, [_vm._v("20/06/2023")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Rp. 26.000.000")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("25/09/2023")]), _c('td', {
                staticClass: "text-center"
              }, [_c('i', {
                staticClass: "far fa-file-pdf text-primary"
              })]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-dropdown', {
                attrs: {
                  "size": "sm",
                  "variant": "link iq-bg-primary iq-border-radius-10",
                  "toggle-class": "text-decoration-none",
                  "no-caret": ""
                },
                scopedSlots: _vm._u([{
                  key: "button-content",
                  fn: function fn() {
                    return [_c('span', {
                      staticClass: "dropdown-toggle p-0",
                      attrs: {
                        "id": "dropdownMenuButton5",
                        "data-toggle": "dropdown"
                      }
                    }, [_c('i', {
                      staticClass: "fa fa-pencil-square-o m-0 text-primary"
                    })])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-dropdown-item', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.detail_maintenance",
                  modifiers: {
                    "detail_maintenance": true
                  }
                }],
                attrs: {
                  "href": "#",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list mr-2"
              }), _vm._v("Notes ")])], 1)], 1)]), _c('tr', [_c('td', {
                staticClass: "text-center"
              }, [_vm._v("20/03/2023")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Rp. 35.000.000")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("20/06/2023")]), _c('td', {
                staticClass: "text-center"
              }, [_c('i', {
                staticClass: "far fa-file-pdf text-primary"
              })]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-dropdown', {
                attrs: {
                  "size": "sm",
                  "variant": "link iq-bg-primary iq-border-radius-10",
                  "toggle-class": "text-decoration-none",
                  "no-caret": ""
                },
                scopedSlots: _vm._u([{
                  key: "button-content",
                  fn: function fn() {
                    return [_c('span', {
                      staticClass: "dropdown-toggle p-0",
                      attrs: {
                        "id": "dropdownMenuButton5",
                        "data-toggle": "dropdown"
                      }
                    }, [_c('i', {
                      staticClass: "fa fa-pencil-square-o m-0 text-primary"
                    })])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-dropdown-item', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.detail_maintenance",
                  modifiers: {
                    "detail_maintenance": true
                  }
                }],
                attrs: {
                  "href": "#",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list mr-2"
              }), _vm._v("Notes ")])], 1)], 1)]), _c('tr', [_c('td', {
                staticClass: "text-center"
              }, [_vm._v("12/01/2023")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Rp. 27.000.000")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("12/03/2023")]), _c('td', {
                staticClass: "text-center"
              }, [_c('i', {
                staticClass: "far fa-file-pdf text-primary"
              })]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-dropdown', {
                attrs: {
                  "size": "sm",
                  "variant": "link iq-bg-primary iq-border-radius-10",
                  "toggle-class": "text-decoration-none",
                  "no-caret": ""
                },
                scopedSlots: _vm._u([{
                  key: "button-content",
                  fn: function fn() {
                    return [_c('span', {
                      staticClass: "dropdown-toggle p-0",
                      attrs: {
                        "id": "dropdownMenuButton5",
                        "data-toggle": "dropdown"
                      }
                    }, [_c('i', {
                      staticClass: "fa fa-pencil-square-o m-0 text-primary"
                    })])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-dropdown-item', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.detail_maintenance",
                  modifiers: {
                    "detail_maintenance": true
                  }
                }],
                attrs: {
                  "href": "#",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list mr-2"
              }), _vm._v("Notes ")])], 1)], 1)])])]), _c('div', {
                staticClass: "mt-3"
              }, [_c('b-pagination', {
                attrs: {
                  "value": 1,
                  "total-rows": 50,
                  "align": "center"
                }
              })], 1)])])], 1)];
            }
          }])
        }), _c('b-sidebar', {
          attrs: {
            "id": "detail_maintenance_counter",
            "title": "TB. TAVIA 1 - WATER TIGHT DOOR",
            "width": "70%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var hide = _ref2.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Running Hours Info")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("RH ME - PORTSIDER")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "28%"
                }
              }, [_vm._v("820 Hours")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("RH AE - PORTSIDER")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "28%"
                }
              }, [_vm._v("770 Hours")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("RH ME - STARBOARD")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "28%"
                }
              }, [_vm._v("820 Hours")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("RH AE - STARBOARD")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "28%"
                }
              }, [_vm._v("770 Hours")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Maintenance Info")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Job Code")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "28%"
                }
              }, [_vm._v("TV001-01")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Job Class")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "28%"
                }
              }, [_vm._v("CM - CORRECTIVE MAINTENANCE")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Job Type")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "28%"
                }
              }, [_vm._v("JT014 - INSPECTION")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Job Name")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "28%"
                }
              }, [_vm._v("WATER TIGHT DOOR - INSPECTION")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Equipment")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "28%"
                }
              }, [_vm._v("16.2.1 - WATER TIGHT DOOR")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Interval/Frequency")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "28%"
                }
              }, [_vm._v("300 H")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Tigger")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "28%"
                }
              }, [_vm._v("COUNTER (RH)")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Status")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "28%"
                }
              }, [_c('b-badge', {
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("OVERDUE")])], 1)]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Total Cost")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('th', {
                attrs: {
                  "width": "28%"
                }
              }, [_vm._v("Rp. 122.150.000,-")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }), _c('td', {
                attrs: {
                  "width": "28%"
                }
              })])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Equipment Info - ACCOMODATION ROOM")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "23%"
                }
              }, [_vm._v("Type No")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("-")]), _c('th', {
                attrs: {
                  "width": "23%"
                }
              }, [_vm._v("Serial No")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("-")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "23%"
                }
              }, [_vm._v("Maker No")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("-")]), _c('th', {
                attrs: {
                  "width": "23%"
                }
              }, [_vm._v("Maker")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("-")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "23%"
                }
              }, [_vm._v("Drawing No")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("-")]), _c('th', {
                attrs: {
                  "width": "23%"
                }
              }, [_vm._v("Certificate")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("-")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("Additional Information")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("-")]), _c('th', {
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("Descriptions")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "2%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("-")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("WATER TIGHT DOOR")]), _c('table', {
                staticClass: "table table-striped table-sm"
              }, [_c('thead', [_c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("LAST MAINTENANCE")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("TOTAL COST")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("DUE DATE / HOURS")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("ATTACHMENT")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("ACTION")])])]), _c('tbody', [_c('tr', [_c('td', {
                staticClass: "text-center"
              }, [_vm._v("10/12/2023 - "), _c('b', [_vm._v("(810 Hours)")])]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Rp. 15.400.000")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("840 Hours")]), _c('td', {
                staticClass: "text-center"
              }, [_c('i', {
                staticClass: "far fa-file-pdf text-primary"
              })]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-dropdown', {
                attrs: {
                  "size": "sm",
                  "variant": "link iq-bg-primary iq-border-radius-10",
                  "toggle-class": "text-decoration-none",
                  "no-caret": ""
                },
                scopedSlots: _vm._u([{
                  key: "button-content",
                  fn: function fn() {
                    return [_c('span', {
                      staticClass: "dropdown-toggle p-0",
                      attrs: {
                        "id": "dropdownMenuButton5",
                        "data-toggle": "dropdown"
                      }
                    }, [_c('i', {
                      staticClass: "fa fa-pencil-square-o m-0 text-primary"
                    })])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-dropdown-item', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.detail_maintenance",
                  modifiers: {
                    "detail_maintenance": true
                  }
                }],
                attrs: {
                  "href": "#",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list mr-2"
              }), _vm._v("Notes ")])], 1)], 1)]), _c('tr', [_c('td', {
                staticClass: "text-center"
              }, [_vm._v("18/09/2023 - "), _c('b', [_vm._v("(780 Hours)")])]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Rp. 18.750.000")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("810 Hours")]), _c('td', {
                staticClass: "text-center"
              }, [_c('i', {
                staticClass: "far fa-file-pdf text-primary"
              })]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-dropdown', {
                attrs: {
                  "size": "sm",
                  "variant": "link iq-bg-primary iq-border-radius-10",
                  "toggle-class": "text-decoration-none",
                  "no-caret": ""
                },
                scopedSlots: _vm._u([{
                  key: "button-content",
                  fn: function fn() {
                    return [_c('span', {
                      staticClass: "dropdown-toggle p-0",
                      attrs: {
                        "id": "dropdownMenuButton5",
                        "data-toggle": "dropdown"
                      }
                    }, [_c('i', {
                      staticClass: "fa fa-pencil-square-o m-0 text-primary"
                    })])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-dropdown-item', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.detail_maintenance",
                  modifiers: {
                    "detail_maintenance": true
                  }
                }],
                attrs: {
                  "href": "#",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list mr-2"
              }), _vm._v("Notes ")])], 1)], 1)]), _c('tr', [_c('td', {
                staticClass: "text-center"
              }, [_vm._v("20/06/2023 - "), _c('b', [_vm._v("(750 Hours)")])]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Rp. 26.000.000")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("780 Hours")]), _c('td', {
                staticClass: "text-center"
              }, [_c('i', {
                staticClass: "far fa-file-pdf text-primary"
              })]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-dropdown', {
                attrs: {
                  "size": "sm",
                  "variant": "link iq-bg-primary iq-border-radius-10",
                  "toggle-class": "text-decoration-none",
                  "no-caret": ""
                },
                scopedSlots: _vm._u([{
                  key: "button-content",
                  fn: function fn() {
                    return [_c('span', {
                      staticClass: "dropdown-toggle p-0",
                      attrs: {
                        "id": "dropdownMenuButton5",
                        "data-toggle": "dropdown"
                      }
                    }, [_c('i', {
                      staticClass: "fa fa-pencil-square-o m-0 text-primary"
                    })])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-dropdown-item', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.detail_maintenance",
                  modifiers: {
                    "detail_maintenance": true
                  }
                }],
                attrs: {
                  "href": "#",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list mr-2"
              }), _vm._v("Notes ")])], 1)], 1)]), _c('tr', [_c('td', {
                staticClass: "text-center"
              }, [_vm._v("15/03/2023 - "), _c('b', [_vm._v("(720 Hours)")])]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Rp. 35.000.000")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("750 Hours")]), _c('td', {
                staticClass: "text-center"
              }, [_c('i', {
                staticClass: "far fa-file-pdf text-primary"
              })]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-dropdown', {
                attrs: {
                  "size": "sm",
                  "variant": "link iq-bg-primary iq-border-radius-10",
                  "toggle-class": "text-decoration-none",
                  "no-caret": ""
                },
                scopedSlots: _vm._u([{
                  key: "button-content",
                  fn: function fn() {
                    return [_c('span', {
                      staticClass: "dropdown-toggle p-0",
                      attrs: {
                        "id": "dropdownMenuButton5",
                        "data-toggle": "dropdown"
                      }
                    }, [_c('i', {
                      staticClass: "fa fa-pencil-square-o m-0 text-primary"
                    })])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-dropdown-item', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.detail_maintenance",
                  modifiers: {
                    "detail_maintenance": true
                  }
                }],
                attrs: {
                  "href": "#",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list mr-2"
              }), _vm._v("Notes ")])], 1)], 1)]), _c('tr', [_c('td', {
                staticClass: "text-center"
              }, [_vm._v("12/01/2023 - "), _c('b', [_vm._v("(690 Hours)")])]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Rp. 27.000.000")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("720 Hours")]), _c('td', {
                staticClass: "text-center"
              }, [_c('i', {
                staticClass: "far fa-file-pdf text-primary"
              })]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-dropdown', {
                attrs: {
                  "size": "sm",
                  "variant": "link iq-bg-primary iq-border-radius-10",
                  "toggle-class": "text-decoration-none",
                  "no-caret": ""
                },
                scopedSlots: _vm._u([{
                  key: "button-content",
                  fn: function fn() {
                    return [_c('span', {
                      staticClass: "dropdown-toggle p-0",
                      attrs: {
                        "id": "dropdownMenuButton5",
                        "data-toggle": "dropdown"
                      }
                    }, [_c('i', {
                      staticClass: "fa fa-pencil-square-o m-0 text-primary"
                    })])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-dropdown-item', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.detail_maintenance",
                  modifiers: {
                    "detail_maintenance": true
                  }
                }],
                attrs: {
                  "href": "#",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fas fa-clipboard-list mr-2"
              }), _vm._v("Notes ")])], 1)], 1)])])]), _c('div', {
                staticClass: "mt-3"
              }, [_c('b-pagination', {
                attrs: {
                  "value": 1,
                  "total-rows": 50,
                  "align": "center"
                }
              })], 1)])])], 1)];
            }
          }])
        })];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }