var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "m-3"
  }, [!_vm.loading ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    class: "d-flex align-items-center justify-content-end w-100"
  }, [_c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "type": "button",
      "variant": "danger"
    },
    on: {
      "click": _vm.closeSlideHistoryMail
    }
  }, [_c('div', [_c('i', {
    staticClass: "fa fa-ban"
  }), _c('span', [_vm._v("Cancel")])])])], 1)]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('h5', {
    staticClass: "card-title text-primary"
  }, [_c('strong', [_vm._v("HISTORY MAIL")])]), _c('ul', {
    staticClass: "iq-timeline m-0 p-0",
    staticStyle: {
      "height": "650px"
    }
  }, _vm._l(_vm.history, function (state, index) {
    var _state$attachment;
    return _c('li', {
      key: index + 1
    }, [_c('b-row', {
      staticClass: "m-0"
    }, [_c('b-col', {
      attrs: {
        "md": "12"
      }
    }, [_c('div', {
      staticClass: "timeline-dots-fill"
    }), _c('div', {
      staticClass: "d-flex flex-column mb-2"
    }, [_c('span', {
      staticClass: "text-primary"
    }, [_vm._v("Sender: ")]), _c('strong', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.sender_mail))]), _c('small', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.sender_name))])]), _c('div', {
      staticClass: "d-flex flex-column mb-2"
    }, [_c('span', {
      staticClass: "text-primary"
    }, [_vm._v("Receiver: ")]), _c('strong', [_vm._v("to: " + _vm._s(state === null || state === void 0 ? void 0 : state.to))]), _c('strong', [_vm._v("cc: " + _vm._s(state === null || state === void 0 ? void 0 : state.cc))])]), (state === null || state === void 0 ? void 0 : (_state$attachment = state.attachment) === null || _state$attachment === void 0 ? void 0 : _state$attachment.length) > 0 ? _c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('span', {
      staticClass: "text-primary mb-1"
    }, [_vm._v("Attachments: ")]), _c('div', {
      staticClass: "d-flex flex-wrap"
    }, _vm._l(state === null || state === void 0 ? void 0 : state.attachment, function (file, fileIndex) {
      return _c('img', {
        key: fileIndex,
        staticClass: "mr-2 mb-2",
        staticStyle: {
          "cursor": "pointer"
        },
        attrs: {
          "src": require("../../../assets/images/activity/pdf.png"),
          "alt": "img",
          "height": "30px"
        },
        on: {
          "click": function click($event) {
            return _vm.handleOpenFilePreview("".concat(_vm.apiUrl, "/").concat(file === null || file === void 0 ? void 0 : file.file_url));
          }
        }
      });
    }), 0)]) : _vm._e(), _c('hr')])], 1)], 1);
  }), 0)])], 1)], 1) : _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "alert alert-info m-4"
  }, [_vm._v(" Loading... ")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }