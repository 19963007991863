<template>
  <div id="my-page">
    <b-container fluid>
      <div class="pdf-exclude">
        <b-button
          v-if="$route.query.preview === 'false'"
          variant="primary"
          class="d-inline-block"
          type="button"
          @click="$router.go(-1)"
        >
          <i class="fa fa-arrow-left"></i>
          <span>Kembali</span>
        </b-button>
      </div>
      <b-row>
        <b-col lg="4">
          <iq-card class-name="iq-user-profile-block1">
            <template v-slot:body>
              <div class="user-details-block">
                <div class="user-profile text-center">
                  <img
                    v-if="$route.query.user === 'crew'"
                    :src="user?.photo ? user?.photo_url : default_photo"
                    alt="profile-img"
                    class="avatar-130 img-fluid"
                  />
                  <img
                    v-else
                    :src="user?.personal_data?.photo ? user?.personal_data?.photo_url : default_photo"
                    alt="profile-img"
                    class="avatar-130 img-fluid"
                  />
                </div>

                <div
                  class="text-center mt-3"
                  v-if="$route.query.user === 'candidate'"
                >
                  <h4><b>{{ user?.personal_data?.full_name ?? '-' }}</b></h4>
                </div>
                <div
                  v-if="$route.query.user === 'crew'"
                  style="position: absolute; top:0; right:0;"
                  @click="openModalSwapPosition(true)"
                >
                  <b-button
                    v-if="user?.status_emp_crewing?.alias !== 'finish' && user?.status_emp_crewing?.alias !== 'unfinish' && user?.status_emp_crewing?.alias !== 'blacklist' && $route.query.preview === 'false'"
                    variant="warning"
                    class="d-inline-block"
                    type="button"
                    v-b-modal.swap-position
                  >
                    <!-- <i class="fa fa-arrow-left"></i> -->
                    <span>Swap Position</span>
                  </b-button>
                </div>

                <div
                  class="text-center mt-3"
                  v-if="$route.query.user === 'crew'"
                >
                  <b :class="user?.status_emp_crewing?.alias === 'active' ? 'text-success' : 'text-warning'">{{ user?.status_emp_crewing?.name ?? '-' }}</b>
                </div>
                <div
                  class="text-center"
                  v-if="$route.query.user === 'crew'"
                >
                  <h4><b>{{ user?.personal_data?.full_name ?? '-' }}</b></h4>
                  <strong class="text-muted">{{ user?.company?.company ?? '-' }}</strong><br />
                  <strong class="text-muted">{{ user?.position?.name ?? '-' }}</strong><br />
                  <strong>({{ user?.vehicle?.name ?? '-' }})</strong> 
                  
                  <p>
                    {{ countAge }} Tahun,
                    {{ user?.personal_data?.place_of_birth ?? '-' }}
                    <br />
                    <b-badge variant="primary"
                      >{{ user?.status_hire ?? '-' }},
                      {{
                        moment(new Date(user?.created_at)).format(
                          'DD MMMM YYYY'
                        ) ?? '-'
                      }}</b-badge
                    >
                  </p>
                </div>
              </div>
              <div class="mb-4 pdf-exclude" >
                <b-button
                  v-if="$route.query.preview === 'false'"
                  variant="primary"
                  block
                  type="button"
                  className="mb-1"
                  @click="
                    $router.push({
                      path: '/crewing/profile-edit',
                      query: {
                        user: $route.query.user,
                        id: $route.query.id,
                      },
                    })
                  "
                >
                  <i class="fa fa-edit"></i>
                  <span>Edit Profile</span>
                </b-button>

                <b-button
                  v-if="user?.status_emp_crewing?.alias !== 'finish' && user?.status_emp_crewing?.alias !== 'unfinish' && user?.status_emp_crewing?.alias !== 'blacklist' && $route.query.preview === 'false'"
                  variant="warning"
                  block
                  type="button"
                  v-b-modal.request-signoff
                >
                  <span>Request Sign Off</span>
                </b-button>
              </div>
              <b-alert :show="true" class="text-center text-white bg-primary" v-if="$route.query.user === 'crew'"> 
                <div class="iq-alert-text d-flex w-100 align-items-center  justify-content-between ">
                  <i class="fa fa-check" style="opacity:0" />
                  <strong>JOIN DATE</strong>
                  <i class="fa fa-pen" style="cursor:pointer" v-if="!isEditJoinDate" @click="isEditJoinDate = !isEditJoinDate" />
                  <i class="fa fa-ban" style="cursor:pointer" v-else @click="isEditJoinDate = !isEditJoinDate" />
                </div>
              </b-alert>
              <table class="table table-striped" v-if="$route.query.user === 'crew'">
                <tbody>
                  <tr>
                    <td><strong>Sign On</strong></td>
                    <td>
                      <b-form-input
                        v-if="isEditJoinDate && user.sign_on"
                        id="sign_on"
                        name="sign_on"
                        type="datetime-local"
                        v-model="formJoinDate.sign_on" 
                      />
                      <span v-else>{{ user?.sign_on ? moment(new Date(user?.sign_on)).format('DD MMMM YYYY HH:mm') : "-" }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Join On Board</strong></td>
                    <td>
                      <b-form-input
                        v-if="isEditJoinDate && user.join_on_board"
                        id="join_on_board"
                        name="join_on_board"
                        type="datetime-local"
                        v-model="formJoinDate.join_on_board" 
                      />
                      <span v-else>{{ user?.join_on_board ? moment(new Date(user?.join_on_board)).format('DD MMMM YYYY HH:mm') : '-' }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Sign Off</strong></td>
                    <td>
                      <b-form-input
                        v-if="isEditJoinDate && user.sign_off"
                        id="sign_off"
                        name="sign_off"
                        type="datetime-local"
                        :disabled="!user?.sign_off"
                        v-model="formJoinDate.sign_off" 
                      />
                      <span v-else>{{ user?.sign_off ? moment(new Date(user?.sign_off)).format('DD MMMM YYYY HH:mm') : '-' }}</span>
                    </td>
                  </tr> 
                  <tr  v-if="user?.reason_sign_off_list?.length > 0">
                    <td><strong>Reason</strong></td>
                    <td class="text-start">
                      <div class="gap-1">
                        <div v-for="(obj, index) in user?.reason_sign_off_list" :key="index + 'signoff-list'">
                          <strong>></strong>{{ " " }}
                          <span>{{ obj.name  }}</span> <br />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Notice Sign Off</strong></td>
                    <td>
                      <div class="d-flex flex-column" v-if="user?.notice_off">
                        <span>{{ user?.notice_off ? moment(new Date(user?.notice_off)).format('DD MMMM YYYY HH:mm') : '-' }}</span>
                        <span class="text-muted text-capitalize">'{{ user?.detail_notice_off }}'</span>
                      </div>
                      <span v-else>-</span>
                    </td>
                  </tr>
                  <tr v-if="isEditJoinDate">
                    <td colspan="2" class="text-right">
                      <b-button type="button" variant="primary" @click="onEditJoinDate"><i class="fa fa-pen mr-1" /> Edit Join Date</b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-alert :show="true" class="text-center text-white bg-primary">
                <div class="iq-alert-text"><strong>DATA DIRI</strong></div>
              </b-alert>
              <table class="table table-striped">
                <tbody>
                  <tr v-if="$route.query.user === 'crew'">
                    <td><strong>Nomor Pegawai</strong></td>
                    <td>{{ user?.nip ?? '-' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Nomor KTP</strong></td>
                    <td>{{ user?.personal_data?.nik_ktp ?? '-' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Nomor KK</strong></td>
                    <td>{{ user?.personal_data?.no_kk ?? '-' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Jenis Kelamin</strong></td>
                    <td>{{ user?.personal_data?.gender ?? '-' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Seaferer Code</strong></td>
                    <td>{{ user?.personal_data?.seaferer_code ?? '-' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Nomor NPWP</strong></td>
                    <td>{{ user?.personal_data?.npwp_number ?? '-' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Nomor BPJS Kesehatan</strong></td>
                    <td>{{ user?.personal_data?.bpjs_kes_number ?? '-' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Nomor Seamanbook</strong></td>
                    <td>{{ user?.personal_data?.seaman_book_number ?? '-' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Nomor Passport</strong></td>
                    <td>{{ user?.personal_data?.passport_number ?? '-' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Nomor BPJS Ketenagakerjaan</strong></td>
                    <td>{{ user?.personal_data?.bpjs_tk_number ?? '-' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Perusaahaan</strong></td>
                    <td>{{ user?.company?.company ?? '-' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Jabatan/Ijazah</strong></td>
                    <td>{{ user?.personal_data?.position?.name ?? '-' }}</td>
                  </tr>
                  <tr v-if="$route.query.user === 'crew'">
                    <td><strong>Kapal/Fleet</strong></td>
                    <td>{{ user?.vehicle?.name ?? '-' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Kewarganegaraan</strong></td>
                    <td>{{ user?.personal_data?.citizenship ?? '-' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Tempat/Tanggal Lahir</strong></td>
                    <td>
                      {{ user?.personal_data?.place_of_birth ?? '-' }} /&nbsp;
                      {{ user?.personal_data?.date_of_birth ?? '-' }}</td
                    >
                  </tr>
                  <tr>
                    <td><strong>Golongan Darah</strong></td>
                    <td>{{ user?.personal_data?.blood_type?.alias }}</td>
                  </tr>
                  <tr>
                    <td><strong>Agama</strong></td>
                    <td>{{ user?.personal_data?.religion?.name }}</td>
                  </tr>
                  <tr>
                    <td><strong>Status</strong></td>
                    <td>{{ user?.personal_data?.marital_status?.name }}</td>
                  </tr>
                  <!-- <tr>
                    <td><strong>Jumlah Banyak Anak</strong></td>
                    <td>{{
                      user?.personal_data?.number_of_children ?? '-'
                    }}</td>
                  </tr> -->
                  <tr>
                    <td><strong>Nomor Tlp./HP</strong></td>
                    <td>{{ user?.personal_data?.hp_number ?? '-' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Email</strong></td>
                    <td>{{ user?.personal_data?.email ?? '-' }}</td>
                  </tr>
                  <tr>
                    <td colspan="2"><strong>Alamat KTP</strong></td>
                  </tr>
                  <tr>
                    <td colspan="2" v-if="$route.query.user === 'candidate'">
                      {{ user?.alamat_ktp?.[0]?.alamat }},
                      No.{{ user?.alamat_ktp?.[0]?.no_rumah }},   
                      {{ user?.alamat_ktp?.[0]?.provinsi }},
                      {{ user?.alamat_ktp?.[0]?.kabupaten }},
                      {{ user?.alamat_ktp?.[0]?.kecamatan }},
                      {{ user?.alamat_ktp?.[0]?.kelurahan }},
                      {{ user?.alamat_ktp?.[0]?.kode_pos }}
                    </td>
                    <td colspan="2" v-else>
                      {{ user?.address?.[0]?.alamat }},
                      No.{{ user?.address?.[0]?.no_rumah }},   
                      {{ user?.address?.[0]?.provinsi }},
                      {{ user?.address?.[0]?.kabupaten }},
                      {{ user?.address?.[0]?.kecamatan }},
                      {{ user?.address?.[0]?.kelurahan }},
                      {{ user?.address?.[0]?.kode_pos }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2"><strong>Alamat Domisili</strong></td>
                  </tr>
                  <tr>
                    <td colspan="2" v-if="$route.query.user === 'candidate'">
                      {{ user?.alamat_domisili?.[0]?.alamat }},
                      No.{{ user?.alamat_domisili?.[0]?.no_rumah }},   
                      {{ user?.alamat_domisili?.[0]?.provinsi }},
                      {{ user?.alamat_domisili?.[0]?.kabupaten }},
                      {{ user?.alamat_domisili?.[0]?.kecamatan }},
                      {{ user?.alamat_domisili?.[0]?.kelurahan }},
                      {{ user?.alamat_domisili?.[0]?.kode_pos }}
                    </td> 
                    <td colspan="2" v-else>
                      {{ user?.address?.[1]?.alamat }},
                      No.{{ user?.address?.[1]?.no_rumah }},   
                      {{ user?.address?.[1]?.provinsi }},
                      {{ user?.address?.[1]?.kabupaten }},
                      {{ user?.address?.[1]?.kecamatan }},
                      {{ user?.address?.[1]?.kelurahan }},
                      {{ user?.address?.[1]?.kode_pos }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </iq-card>
        

          <iq-card v-if="$route.query.user === 'crew'">
            <template v-slot:body>
              <table class="table table-striped">
                <tbody>
                  <tr class="bg-info">
                    <td colspan="2" class="text-center"
                      ><strong>Recommendation By</strong></td
                    >
                  </tr>
                  <tr>
                    <td colspan="2"><strong>{{ user?.personal_data?.recommendation_by ?? '-'  }}</strong></td>
                  </tr>
                </tbody>
              </table>
            </template>
          </iq-card>

          <iq-card v-if="$route.query.user === 'crew'">
            <template v-slot:body>
              <table class="table table-striped">
                <tbody>
                  <tr class="bg-info">
                    <td colspan="2" class="text-center"
                      ><strong>Verification Kemenhub</strong></td
                    >
                  </tr>
                  <tr v-if="user?.personal_data?.verification_by">
                    <td><strong>Verification By</strong></td>
                    <td>{{ user?.personal_data?.verification_by ?? '-' }}</td>
                  </tr>
                  <tr v-if="user?.personal_data?.seaferer_code_verification_url">
                    <td width="50%"><strong>Verification File</strong></td>
                    <td width="10%"><i style="cursor:pointer" class="fas fa-download mr-2 text-primary" @click="handleOpenFilePreview(user?.personal_data?.seaferer_code_verification_url)"></i></td>
                  </tr>
                  <tr v-if="!user?.personal_data?.verification_by && !user?.personal_data?.seaferer_code_verification_url">
                    <td width="100%"><strong class="text-muted">CANDIDATE BELUM MELAKUKAN VERIFICATION</strong></td>
                  </tr>
                </tbody>
              </table>
            </template>
          </iq-card>

          <iq-card>
            <template v-slot:body>
              <table class="table table-striped">
                <tbody>
                  <tr class="bg-info">
                    <td colspan="2" class="text-center"
                      ><strong>PAKAIAN & SEPATU</strong></td
                    >
                  </tr>
                  <tr>
                    <td><strong>Tinggi Badan</strong></td>
                    <td>{{ user?.personal_data?.height ?? '-' }} CM</td>
                  </tr>
                  <tr>
                    <td><strong>Berat Badan</strong></td>
                    <td>{{ user?.personal_data?.weight ?? '-' }} KG</td>
                  </tr>
                  <tr>
                    <td><strong>Wearpack</strong></td>
                    <td>{{ user?.personal_data?.wearpack ?? '-' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Sepatu</strong></td>
                    <td>{{ user?.personal_data?.size_of_shoes ?? '-' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Helmet</strong></td>
                    <td>{{ user?.personal_data?.helmet ?? '-' }}</td>
                  </tr>
                </tbody>
              </table>
            </template>
          </iq-card>

          <iq-card>
            <template v-slot:body>
              <table class="table table-striped">
                <tbody>
                  <tr class="bg-info">
                    <td colspan="2" class="text-center"
                      ><strong>AKUN BANK</strong></td
                    >
                  </tr>
                  <tr>
                    <td><strong>Atas Nama</strong></td>
                    <td>{{ user?.personal_data?.rekening_name ?? '-' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Nama Bank</strong></td>
                    <td>{{ user?.personal_data?.bank?.name ?? '-' }} ({{user?.personal_data?.bank?.alias}})</td>
                  </tr>
                  <tr>
                    <td><strong>Nomor Rekening</strong></td>
                    <td>{{ user?.personal_data?.rekening_number ?? '-' }}</td>
                  </tr>
                
                </tbody>
              </table>
            </template>
          </iq-card>

          <iq-card>
            <template v-slot:body>
              <table class="table table-striped">
                <tbody>
                  <tr class="bg-info">
                    <td colspan="2" class="text-center"
                      ><strong>EMERGENCY CALL</strong></td
                    >
                  </tr>
                  <tr>
                    <td ><strong>Nama&nbsp;Lengkap</strong></td>
                    <td >{{
                      user?.family?.[0]?.nama_lengkap ?? '-'
                    }}</td>
                  </tr>
                  <tr>
                    <td><strong>No. Tlp/HP</strong></td>
                    <td>{{ user?.family?.[0]?.no_hp ?? '-' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Hubungan</strong></td>
                    <td>{{
                      user?.family?.[0]?.status ?? '-'
                    }}</td>
                  </tr>
                  <tr>
                    <td><strong>alamat</strong></td>
                    <td colspan="2">
                      {{ user?.family?.[0]?.alamat ?? '-' }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </iq-card>
        </b-col>
        <b-col lg="8">
          <b-row v-if="$route.query.user === 'crew'">
            <b-col lg="12">
              <iq-card v-if="user?.position_id === 1 || user?.position_id === 2">
                <template v-slot:headerTitle>
                  <h4 class="card-title text-primary">EVALUASI CONDUITE ({{ user?.position?.name }})</h4>
                </template>

                <template v-slot:body>
                  <b-row class="mb-3">
                    <b-col md="12">
                      <span> Search Condite Date:</span>
                    </b-col>
                    <b-col md="12">
                      <input
                        class="form-control"
                        type="month"
                        v-model="selectedMonth"
                      />
                      <small style="float: right"
                        >click <i class="fa fa-calendar"></i> to show
                      </small>
                    </b-col>
                  </b-row>
                  <b-row class="mb-4">
                    <b-col md="12">
                      <div class="table-responsive">
                        <table
                          class="table table-striped table-hover mb-0"
                          style="width: 1200px"
                          v-if="user?.conduct?.length > 0"
                        >
                          <thead>
                            <tr>
                              <th class="align-middle text-center" rowspan="2"
                                >Tanggal Condite</th
                              >
                              <th class="align-middle text-center" rowspan="2"
                                >Periode Evaluasi</th
                              >
                              <th class="align-middle text-center" colspan="4"
                                >Nama</th
                              >
                              <th class="align-middle text-center" colspan="4"
                                >Rekomendasi</th
                              >
                            </tr>
                            <tr>
                              <th class="align-middle text-center">Manager</th>
                              <th class="align-middle text-center">User</th>
                              <th class="align-middle text-center">KKM</th>
                              <th class="align-middle text-center">Master</th>

                              <th class="align-middle text-center"> Manager</th>
                              <th class="align-middle text-center"> User</th>
                              <th class="align-middle text-center"> KKM</th>
                              <th class="align-middle text-center"> Master</th>
                            </tr>
                            <tr> </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(state, index) in user?.condite"
                              v-bind:key="index"
                              style="cursor: pointer"
                              v-b-modal.condite
                              @click="modalCondite(state, index)"
                            >
                              <td class="text-center">{{
                                moment(new Date(state?.date_of_condite)).format(
                                  'DD MMMM YYYY'
                                )
                              }}</td>
                              <td class="text-center">{{ state?.evaluation_period ?? '-' }}</td>
                              <td class="text-center">{{ state?.name_manager ?? '-' }}</td>
                              <td class="text-center">{{ state?.name_user ?? '-' }}</td>
                              <td class="text-center">{{ state?.name4 ?? '-' }}</td>
                              <td class="text-center">{{ state?.name_master ?? '-' }}</td>

                              <td class="text-center">{{ state?.recommendation_manager ?? '-' }}</td>
                              <td class="text-center">{{ state?.recommendation_user ?? '-' }}</td>
                              <td class="text-center">{{ state?.recommendation4 ?? '-' }}</td>
                              <td class="text-center">{{ state?.recommendation_master ?? '-' }}</td>
                            </tr>
                          </tbody>
                        </table>
                        <div
                          class="d-flex w-100 justify-content-center align-items-center"
                          v-if="user?.condite.length <= 0 && user?.conduct.length <= 0"
                        >
                          <strong> CREW BELUM MELAKUKAN CONDITE</strong>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>

              <iq-card v-else>
                <template v-slot:headerTitle>
                  <h4 class="card-title text-primary">EVALUASI CONDUITE ({{ user?.position?.name }})</h4>
                </template>

                <template v-slot:body>
                  <b-row class="mb-3">
                    <b-col md="12">
                      <span> Search Condite Date:</span>
                    </b-col>
                    <b-col md="12">
                      <input
                        class="form-control"
                        type="month"
                        v-model="selectedMonth"
                      />
                      <small style="float: right"
                        >click <i class="fa fa-calendar"></i> to show
                      </small>
                    </b-col>
                  </b-row>
                  <b-row class="mb-4">
                    <b-col md="12">
                      <div class="table-responsive">
                        <table
                          class="table table-striped table-hover mb-0"
                          style="width: 1200px"
                          v-if="user?.conduct?.length > 0"
                        >
                          <thead>
                            <tr>
                              <th class="align-middle text-center" rowspan="2"
                                >Tanggal Condite</th
                              >
                              <th class="align-middle text-center" rowspan="2"
                                >Nama Evaluasi</th
                              >
                              <th class="align-middle text-center" colspan="4"
                                >Nama</th
                              >
                              <th class="align-middle text-center" colspan="4"
                                >Rekomendasi</th
                              >
                            </tr>
                            <tr>
                              <th class="align-middle text-center">Approved By</th>
                              <th class="align-middle text-center">Checked By</th>
                              <th class="align-middle text-center">Rated By</th>
                              <th class="align-middle text-center">Verified By</th>

                              <th class="align-middle text-center"> Manager</th>
                              <th class="align-middle text-center"> User</th>
                              <th class="align-middle text-center"> KKM</th>
                              <th class="align-middle text-center"> Master</th>
                            </tr>
                            <tr> </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(state, index) in user?.conduct"
                              v-bind:key="index"
                              style="cursor: pointer"
                              v-b-modal.condite
                              @click="modalConduct(state, index)"
                            >
                              <td class="text-center">{{
                                moment(new Date(state?.date_of_evaluation)).format(
                                  'DD MMMM YYYY'
                                )
                              }}</td>
                              <td class="text-center">{{ state?.name_of_evaluation ?? '-' }}</td>
                              <td class="text-center">{{ state?.approved_by ?? '-' }}</td>
                              <td class="text-center">{{ state?.checked_by ?? '-' }}</td>
                              <td class="text-center">{{ state?.rated_by ?? '-' }}</td>
                              <td class="text-center">{{ state?.verified_by ?? '-' }}</td>

                              <td class="text-center">{{ state?.recommendation1 ?? '-' }}</td>
                              <td class="text-center">{{ state?.recommendation2 ?? '-' }}</td>
                              <td class="text-center">{{ state?.recommendation3 ?? '-' }}</td>
                              <td class="text-center">{{ state?.recommendation4 ?? '-' }}</td>
                            </tr>
                          </tbody>
                        </table>
                        <div
                          class="d-flex w-100 justify-content-center align-items-center"
                          v-if="user?.condite.length <= 0"
                        >
                          <strong> CREW BELUM MELAKUKAN CONDITE</strong>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
            </b-col>
          </b-row>

          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">INTERVIEW RESULT</h4>
            </template>
            <template v-slot:body>
              <table
                class="table table-striped"
                v-if="user?.interview?.length > 0"
              >
                <tbody>
                  <tr>
                    <td colspan="2"><strong>Urgensi Interview</strong></td>
                    <td colspan="2" class="text-center">{{
                      user?.interview[0].urgensi
                    }}</td>
                  </tr>
                  <tr>
                    <td colspan="2"><strong>Tanggal Interview</strong></td>
                    <td colspan="2" class="text-center">{{
                      user?.interview[0].date
                    }}</td>
                  </tr>
                  <tr>
                    <td colspan="2"><strong>Lokasi Interview</strong></td>
                    <td colspan="2" class="text-center">{{
                      user?.interview[0].location
                    }}</td>
                  </tr>
                  <tr>
                    <td colspan="2"><strong>Section By</strong></td>
                    <td colspan="2" class="text-center">{{
                      notes_by_section?.[0]?.name
                    }}</td>
                  </tr>
                  <tr>
                    <td><strong>Total Score</strong></td>
                    <td>{{ user?.interview[0].total }}</td>
                    <td><strong>Average Score</strong></td>
                    <td>{{ user?.interview[0].average }}</td>
                  </tr>
                  <tr>
                    <td colspan="2"><strong>Status</strong></td>
                    <td
                      colspan="2"
                      :class="`text-center ${
                        user?.interview[0].status === 'Passed'
                          ? 'text-success'
                          : 'text-danger'
                      }`"
                      >{{ user?.interview[0].status }}</td
                    >
                  </tr>
                </tbody>
              </table>

              <div
                class="d-flex w-100 align-items-center justify-content-center"
                v-else
              >
                <strong class="text-muted"
                  >BELUM ADA DATA INTERVIEW</strong
                >
              </div>
            </template>
          </iq-card>

          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">MEDICAL CHECKUP</h4>
            </template>
            <template v-slot:body>
              <table
                class="table table-striped"
                v-if="user?.medical_checkup?.length > 0"
              >
                <thead>
                  <tr>
                    <th>Nama File</th>
                    <th>File</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(state, index) in user?.medical_checkup"
                    v-bind:key="index"
                  >
                    <td width="90%">{{ state?.nama_document ?? 'N/A' }}</td>
                    <td width="10%"
                      ><a href="" v-if="state.file"
                        ><i class="fas fa-download mr-3 text-primary"></i></a
                    ></td>
                  </tr>
                </tbody>
              </table>
              <div
                class="d-flex w-100 align-items-center justify-content-center"
                v-else
              >
                <strong class="text-muted"
                  >BELUM ADA DATA MEDICAL CHECKUP</strong
                >
              </div>
            </template>
          </iq-card>

          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">INDUCTION</h4>
            </template>
            <template v-slot:body>
              <table
                class="table table-striped"
                v-if="user?.induction?.length > 0"
              >
                <thead>
                  <tr>
                    <th><strong>Induction By</strong></th>
                    <th><strong>Date Induction</strong></th>
                    <th><strong>Notes</strong></th>
                    <th><strong>File</strong></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(state, index) in user?.induction"
                    v-bind:key="index"
                  >
                    <td>{{ state?.induction_by ?? '-' }}</td>
                    <td>{{ state?.induction_date ?? '-' }}</td>
                    <td>{{ state?.notes ?? '-' }}</td>
                    <td
                      ><a href="" v-if="state.attachment"
                        ><i class="fas fa-download mr-3 text-primary"></i></a
                    ></td> </tr
                ></tbody>
              </table>

              <div
                class="d-flex w-100 align-items-center justify-content-center"
                v-else
              >
                <strong class="text-muted"
                  >BELUM ADA DATA INDUCTION</strong
                >
              </div>
            </template>
          </iq-card>

          <iq-card v-if="$route.query.user === 'crew'">
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">FLEET HISTORY</h4>
            </template>
            <template v-slot:body>
              <div
                class="d-flex w-100 justify-content-end align-items-center mb-2"
              >
                <b-button
                  v-if="$route.query.preview === 'false'"
                  variant="success"
                  type="button"
                  v-b-modal.swap-vehicle
                  @click="() => {
                    resetFormSwapHistory(); 
                    openModalSwapPosition(false)
                    swap_form_edit = false
                  }"
                >
                  <i class="fa fa-plus"></i> <span>Tambah</span>
                </b-button>
              </div>
              <div class="overflow-auto">
                <table
                  class="table table-striped mb-0"
                  style="width: 1200px"
                  v-if="user?.history_position_swap?.length > 0"
                >
                  <thead>
                    <tr>
                      <th class="align-middle text-left">Nama &nbsp; Lengkap</th>
                      <th class="align-middle text-center">Seaferer Code</th>
                      <th class="align-middle text-center">Nomor Pegawai</th>
                      <th class="align-middle text-center">Jabatan</th>
                      <th class="align-middle text-center">Company</th>
                      <th class="align-middle text-center">Fleet</th>
                      <th class="align-middle text-center">Port/Pelabuhan</th>
                      <th class="align-middle text-center">Join Onboard</th>
                      <th class="align-middle text-center">Sign On</th>
                      <th class="align-middle text-center">Sign Off</th>
                      <th class="align-middle text-center">Created By</th>
                      <th class="align-middle text-center">Status Crew</th>
                      <th class="align-middle text-center">Status Approval</th>
                      <th class="align-middle text-center">Reason</th>
                      <th class="align-middle text-center" v-if="$route.query.preview === 'false'">Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(state, index) in user?.history_position_swap"
                      v-bind:key="index"
                    >
                      <td class="text-left">{{ user?.fullname ?? '-' }}</td>
                      <td class="text-center">{{
                        user?.personal_data?.seaferer_code ?? '-'
                      }}</td>
                      <td class="text-center">{{ state?.nip ?? '-' }}</td>
                      <td class="text-center"
                        >{{ state?.position?.name ?? '-' }}
                      </td>
                      <td class="text-center"
                        >{{ state?.company?.company ?? '-' }}
                      </td> 
                      <td class="text-center">{{
                        state?.vehicle?.name ?? '-'
                      }}</td>
                      <td class="text-center">{{
                        state?.port_id ?? '-'
                      }}</td>
                    
                      <td class="text-center truncate">{{
                        moment(new Date(state?.join_on_board)).format(
                          'DD MMMM YYYY HH:mm'
                        )
                      }}</td>
                      <td class="text-center truncate">{{
                        moment(new Date(state?.start_date)).format(
                          'DD MMMM YYYY HH:mm'
                        )
                      }}</td>
                      <td class="text-center truncate">{{ state?.end_date ? moment(new Date(state?.end_date)).format('DD MMMM YYYY HH:mm') : '-' }}</td>
                      <td class="text-center">{{
                        state?.created_by ?? '-'
                      }}</td>
                      <td :class="`text-center ${state?.status_emp_crewing?.alias === 'active' ? 'text-success' : state?.status_emp_crewing?.alias === 'blacklist' ?  'text-danger' : 'text-warning'}`">{{ state?.status_emp_crewing?.name }}</td>
                      <td>
                        <div>
                          <span :class="`text-center ${state?.approval_status === 'approved' ? 'text-success' : 'text-warning'} text-capitalize`">{{ state?.approval_status }} <br />
                            <small v-if="val?.approval_by">Approved by: {{val?.approval_by}}</small> <br v-if="val?.approval_by" />
                            <small v-if="val?.approval_reason" class="text-muted">Reason: {{val?.approval_reason}}</small>
                          </span>
                        </div>
                      </td>
                      <td class="text-start">
                        <div class="gap-1">
                          <div v-for="(obj, index) in state.reason_sign_off_list" :key="index + 'signoff-list'">
                            <strong :class="state.reason_blacklist_id ? 'text-danger' : ''">></strong>{{ " " }}
                            <span v-if="state.reason_blacklist_id"  class="text-danger" >{{ state?.reason_blacklist?.name }}</span> 
                            <span v-else>{{ obj.name  }}</span> <br />
                          </div>
                        </div>
                      </td>
                      <td
                        class="text-center"
                        v-if="$route.query.preview === 'false'"
                      >
                      <i class="fa fa-edit text-primary" 
                        @click="openModalEdit(state)"
                        style="cursor: pointer"
                        ></i>
                        <br v-if="index !== 0" />
                        <i class="fa fa-trash text-danger"
                          v-if="index !== 0"
                          @click="onDeleteFleetHistory(state.id)"
                          style="cursor: pointer">
                        </i>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  class="d-flex w-100 align-items-center justify-content-center"
                  v-else
                >
                  <strong class="text-muted"
                    >BELUM ADA DATA FLEET HISTORY</strong
                  >
                </div>
              </div>
            </template>
          </iq-card>

          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">DATA KELUARGA</h4>
            </template>
           
            <template v-slot:body>
              <div
                class="d-flex w-100 justify-content-end align-items-center mb-2"
              >
                <b-button
                  v-if="$route.query.preview === 'false'"
                  variant="success"
                  type="button"
                  v-b-modal.keluarga
                  @click="is_edit = false"
                >
                  <i class="fa fa-plus"></i> <span>Tambah</span>
                </b-button>
              </div>
              <table
                class="table table-striped table-responsive mb-0"
                v-if="user?.family.length > 0"
              >
                <thead>
                  <tr>
                    <th class="text-center">No</th>
                    <th class="text-left">Nama &nbsp; Lengkap</th>
                    <th class="text-center">Hubungan</th>
                    <th class="text-center">Jenis&nbsp;Kelamin</th>
                    <th class="text-center">Tempat/&nbsp;Tanggal Lahir</th>
                    <th class="text-center">Umur</th>
                    <th class="text-center">Pekerjaan</th>
                    <th class="text-center">No.Hp</th>
                    <th class="text-center" v-if="$route.query.preview === 'false'">Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(state, index) in user?.family" v-bind:key="index">
                    <td class="text-center">{{ index + 1 }}</td>
                    <td class="text-left">{{ state.nama_lengkap }}</td>
                    <td class="text-center">{{ state.status }}</td>
                    <td class="text-center">{{ state.jenis_kelamin }}</td>
                    <td class="text-center">{{ state.tempat_lahir }}/{{ moment(state.tanggal_lahir).format('DD MMMM YYYY') }}</td>
                    <td class="text-center">{{ state.age_family }}</td>
                    <td class="text-center">{{ state.pekerjaan }}</td>
                    <td class="text-center">{{ state.no_hp }}</td>
                    <td class="text-center" v-if="$route.query.preview === 'false'">
                        <i class="fa fa-edit text-primary"  
                          @click="editKeluarga(state)"
                          style="cursor: pointer">
                        </i>
                        <br />
                        <i class="fa fa-trash text-danger"
                          @click="onDeleteKeluarga(state.id)"
                          style="cursor: pointer">
                        </i>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                class="d-flex w-100 justify-content-center align-items-center"
                v-else
              >
                <strong>DATA KELUARGA TIDAK ADA</strong>
              </div>
            </template>
          </iq-card>

          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">SERTIFIKAT KOMPETENSI</h4>
            </template>
            <template v-slot:body>
              <div
                class="d-flex w-100 justify-content-end align-items-center mb-2"
              >
                <b-button
                  v-if="$route.query.preview === 'false'"
                  variant="success"
                  type="button"
                  v-b-modal.kompetensi
                  @click="is_edit = false"
                >
                  <i class="fa fa-plus"></i> <span>Tambah</span>
                </b-button>
              </div>
              <table
                class="table table-striped table-responsive mb-0"
                v-if="
                  $route.query.user === 'candidate'
                    ? user?.certificate_competency.length
                    : user?.certificate_of_competency.length
                "
              >
                <thead>
                  <tr>
                    <th class="text-center">No</th>
                    <th class="text-left">Nama&nbsp;Dokumen</th>
                    <th class="text-center">Nomor&nbsp;Dokumen</th>
                    <th class="text-center">Tempat &&nbsp;Tanggal diterbitkan</th>
                    <th class="text-center">Tanggal&nbsp;Berakhir</th>
                    <th class="text-center">File</th>
                    <th class="text-center" v-if="$route.query.preview === 'false'">Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(state, index) in $route.query.user === 'candidate'
                      ? user?.certificate_competency
                      : user?.certificate_of_competency"
                    v-bind:key="index"
                  >
                    <td class="text-center">{{ index + 1 }}</td>
                    <td class="text-left">{{ state?.certificate?.name }}</td>
                    <td class="text-center">{{ state.document_number }}</td>
                    <td class="text-center">{{
                      state.place_issued + ', ' + moment(state.date_issued).format('DD MMMM YYYY')
                    }}</td>
                    <td class="text-center">{{ state.validity_period && state.validity_period !== 'null' ? moment(state.validity_period).format('DD MMMM YYYY') : '-'}}</td>
                    <td class="text-center" v-if="state.file_url" style="cursor: pointer;" @click="handleOpenFilePreview(state.file_url)"
                      ><i class="fa fa-file-alt text-primary"></i
                    ></td>
                    <td class="text-center" v-else>{{ '-' }}</td>
                    <td class="text-center" v-if="$route.query.preview === 'false'">
                        <i class="fa fa-edit text-primary" 
                          @click="editCompetency(state)" 
                          style="cursor: pointer">
                        </i>
                        <br />
                        <i class="fa fa-trash text-danger"
                          @click="onDeleteCompetency(state.id)"
                          style="cursor: pointer">
                        </i>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                class="d-flex w-100 justify-content-center align-items-center"
                v-else
              >
                <strong>DATA SERTIFIKAT KOMPETENSI TIDAK ADA</strong>
              </div>
            </template>
          </iq-card>

          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">SERTIFIKAT PROFESI</h4>
            </template>
            <template v-slot:body>
              <div
                class="d-flex w-100 justify-content-end align-items-center mb-2"
              >
                <b-button
                  v-if="$route.query.preview === 'false'"
                  variant="success"
                  type="button"
                  v-b-modal.profesi
                  @click="is_edit = false"
                >
                  <i class="fa fa-plus"></i> <span>Tambah</span>
                </b-button>
              </div>
              <table
                class="table table-striped table-responsive mb-0"
                v-if="
                  $route.query.user === 'candidate'
                    ? user?.certificate_proficiency.length
                    : user?.certificate_of_proficiency.length
                "
              >
                <thead>
                  <tr>
                    <th class="text-center">No</th>
                    <th class="text-left">Nama&nbsp;Dokumen</th>
                    <th class="text-center">Nomor&nbsp;Dokumen</th>
                    <th class="text-center">Tempat &&nbsp;Tanggal diterbitkan</th>
                    <th class="text-center">Tanggal Berakhir</th>
                    <th class="text-center">File</th>
                    <th class="text-center" v-if="$route.query.preview === 'false'">Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(state, index) in $route.query.user === 'candidate'
                      ? user?.certificate_proficiency
                      : user?.certificate_of_proficiency"
                    v-bind:key="index"
                  >
                    <td class="text-center">{{ index + 1 }}</td>
                    <td class="text-left">{{ state?.certificate?.name }}</td>
                    <td class="text-center">{{ state.document_number }}</td>
                    <td class="text-center">{{
                     state.place_issued  + ', ' + moment(state.date_issued).format('DD MMMM YYYY')
                    }}</td>
                    <td class="text-center">{{ state.validity_period && state.validity_period !== 'null' ? moment(state.validity_period).format('DD MMMM YYYY') : '-'}}</td>
                    <td class="text-center" v-if="state.file_url" style="cursor: pointer;" @click="handleOpenFilePreview(state.file_url)"
                      ><i class="fa fa-file-alt text-primary"></i
                    ></td>
                    <td class="text-center" v-else>{{ '-' }}</td>
                    <td class="text-center" v-if="$route.query.preview === 'false'">
                        <i class="fa fa-edit text-primary"  
                          @click="editProficiency(state)"
                          style="cursor: pointer">
                        </i>
                        <br />
                        <i class="fa fa-trash text-danger"
                          @click="onDeleteProficiency(state.id)"
                          style="cursor: pointer">
                        </i>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                class="d-flex w-100 justify-content-center align-items-center"
                v-else
              >
                <strong>DATA SERTIFIKAT PROFESI TIDAK ADA</strong>
              </div>
            </template>
          </iq-card>

          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">PENGALAMAN BERLAYAR</h4>
            </template>
            <template v-slot:body>
              <div
                class="d-flex w-100 justify-content-end align-items-center mb-2"
              >
                <b-button
                  v-if="$route.query.preview === 'false'"
                  variant="success"
                  type="button"
                  v-b-modal.pengalaman_berlayar
                  @click="is_edit = false"
                >
                  <i class="fa fa-plus"></i> <span>Tambah</span>
                </b-button>
              </div>
              <div class="table-responsive">
                <table
                  class="table table-striped mb-0"
                  v-if="
                  $route.query.user === 'candidate'
                    ? user?.pekerjaan?.length
                    : user?.work_experience?.length
                "
                >
                  <thead>
                    <tr>
                      <th class="text-left align-middle" rowspan="2"
                        >Perusahaan</th
                      >
                      <th class="text-center align-middle" rowspan="2"
                        >Negara</th
                      >
                      <th class="text-center align-middle" rowspan="2"
                        >Lokasi</th
                      >
                      <th class="text-center align-middle" rowspan="2"
                        >Nama Kapal</th
                      >

                      <th class="text-center align-middle" rowspan="2"
                        >Bendera Kapal</th
                      >
                      <th class="text-center align-middle" rowspan="2"
                        >Area Operasi</th
                      >
                      <th class="text-center align-middle" colspan="2"
                        >Periode of Service</th
                      >

                      <th class="text-center align-middle" rowspan="2"
                        >Jabatan</th
                      >
                      <th class="text-center align-middle" rowspan="2">Gaji</th>
                      <th class="text-center align-middle" rowspan="2"
                        >Deskripsi Pekerjaan</th
                      >
                      <th class="text-center align-middle" rowspan="2"
                        >Fasilitas</th
                      >
                      <th class="text-center align-middle" rowspan="2"
                        >Pemberi Rekomendasi</th
                      >
                      <th class="text-center align-middle" rowspan="2"
                        >Kontak Pemberi Rekomendasi</th
                      >
                      <th class="text-center align-middle" rowspan="2"
                        >No Perusahaan</th
                      >
                      <th class="text-center align-middle" rowspan="2"
                        >Alasan Berhenti</th
                      >
                      <th class="text-center align-middle" rowspan="2"
                      v-if="$route.query.preview === 'false'"
                        >Aksi</th
                      >
                    </tr>
                    <tr>
                      <th class="text-center align-middle w-50"> Tahun Masuk </th>
                      <th class="text-center align-middle w-50"> Tahun Berhenti </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                    v-for="(state, index) in $route.query.user === 'candidate'
                        ? user?.pekerjaan
                        : user?.work_experience"
                      v-bind:key="index"
                    >
                      <td class="text-left">{{ state?.perusahaan }}</td>
                      <td class="text-center">{{ state?.negara }}</td>
                      <td class="text-center">{{ state?.lokasi }}</td>
                      <td class="text-center">{{ state?.nama_kapal }}</td>
                      <td class="text-center">{{ state?.bendera_kapal }}</td>
                      <td class="text-center">{{ state?.area_operasi }}</td>
                      <td class="text-center w-50">{{ state?.tahun_masuk && state?.tahun_masuk !== 'null' ?
                        moment(new Date(state?.tahun_masuk)).format(
                          'DD MMMM YYYY'
                        ): '-'
                      }}</td>
                      <td class="text-center w-50">{{ state?.tahun_berhenti && state?.tahun_berhenti !== 'null' ?
                        moment(new Date(state?.tahun_berhenti)).format(
                          'DD MMMM YYYY'
                        ) : '-'
                      }}</td>
                      <td class="text-center">{{ state?.jabatan }}</td>
                      <td class="text-center">{{ formatCurrency(state?.gaji) }}</td>
                      <td class="text-center">{{
                        state?.deskripsi_pekerjaan
                      }}</td>
                      <td class="text-center">{{ state?.fasilitas }}</td>
                      <td class="text-center">{{
                        state?.pemberi_rekomendasi
                      }}</td>
                      <td class="text-center">{{
                        state?.kontak_pemberi_informasi
                      }}</td>
                      <td class="text-center">{{
                        state?.no_tlp_perusahaan
                      }}</td>

                      <td class="text-center">{{ state?.alasan_berhenti }}</td>
                      <td class="text-center" v-if="$route.query.preview === 'false'">
                        <i class="fa fa-edit text-primary" 
                          @click="editPekerjaan(state)" 
                          style="cursor: pointer">
                        </i>
                        <br />
                        <i class="fa fa-trash text-danger"
                          @click="onDeletePekerjaan(state.id)"
                          style="cursor: pointer">
                        </i>
                    </td>
                    </tr>
                  </tbody>  
                </table>
                <div
                  class="d-flex w-100 justify-content-center align-items-center"
                  v-else
                >
                  <strong>DATA PENGALAMAN BERLAYAR TIDAK ADA</strong>
                </div>
              </div>
            </template>
          </iq-card>

          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">DOKUMEN PERJALANAN</h4>
            </template>
            <template v-slot:body>
              <div
                class="d-flex w-100 justify-content-end align-items-center mb-2"
              >
                <b-button
                  v-if="$route.query.preview === 'false'"
                  variant="success"
                  type="button"
                  v-b-modal.dokumen_perjalanan
                  @click="is_edit = false"
                >
                  <i class="fa fa-plus"></i> <span>Tambah</span>
                </b-button>
              </div>
              <table
                class="table table-striped table-responsive mb-0"
                v-if="user?.travel_document.length > 0"
              >
                <thead>
                  <tr>
                    <th class="text-center">No</th>
                    <th class="text-left">Nama&nbsp;Document</th>
                    <th class="text-center">No Dokumen</th>
                    <th class="text-center w-50">Place & Date Issued</th>
                    <th class="text-center w-50">Masa Berlaku</th>
                    <th class="text-center">File</th>
                    <th class="text-center" v-if="$route.query.preview === 'false'">Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(state, index) in user?.travel_document"
                    v-bind:key="index"
                  >
                    <td class="text-center">{{ index + 1 }}</td>
                    <td class="text-left">{{ state.document_list?.name }}</td>
                    <td class="text-center">{{ state.document_number }}</td>
                    <td class="text-center w-50">{{
                       state.place_issued + ', ' + moment(state.date_issued).format('DD MMMM YYYY')
                    }}</td>
                    <td class="text-center">{{ state.validity_period  && state.validity_period !== 'null' ? moment(state.validity_period).format('DD MMMM YYYY') : '-'}}</td>
                    <td class="text-center" style="cursor: pointer;" v-if="state.file_url" @click="handleOpenFilePreview(state.file_url)"
                      ><i class="fa fa-file-alt text-primary"></i
                    ></td>
                    <td class="text-center" v-else>{{ '-' }}</td>
                    <td class="text-center" v-if="$route.query.preview === 'false'">
                        <i class="fa fa-edit text-primary"  
                          @click="editTravelDokumen(state)"
                          style="cursor: pointer">
                        </i>
                        <br />
                        <i class="fa fa-trash text-danger"
                          @click="onDeleteTravelDokumen(state.id)"
                          style="cursor: pointer">
                        </i>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                class="d-flex w-100 justify-content-center align-items-center"
                v-else
              >
                <strong>DATA DOKUMEN PERJALANAN TIDAK ADA</strong>
              </div>
            </template>
          </iq-card>

          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary">DOKUMEN PENDUKUNG</h4>
            </template>
            <template v-slot:body>
              <div
                class="d-flex w-100 justify-content-end align-items-center mb-2"
              >
                <b-button
                  v-if="$route.query.preview === 'false'"
                  variant="success"
                  type="button"
                  v-b-modal.dokumen_pendukung
                  @click="is_edit = false"
                >
                  <i class="fa fa-plus"></i> <span>Tambah</span>
                </b-button>
              </div>
              <table
                class="table table-striped mb-0"
                v-if="user?.support_document?.length > 0"
              >
                <thead>
                  <tr>
                    <th class="text-center">No</th>
                    <th class="text-left">Nama&nbsp;Dokumen</th>
                    <th class="text-center">File</th>
                    <th class="text-center" v-if="$route.query.preview === 'false'">Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(state, index) in user?.support_document"
                    v-bind:key="index"
                  >
                    <td class="text-center">{{ index + 1 }}</td>
                    <td class="text-left">{{ state?.document_list?.name ?? 'N/A' }}</td>
                    <td class="text-center" style="cursor: pointer;" v-if="state.file_url" @click="handleOpenFilePreview(state.file_url)"
                      ><i class="fa fa-file-alt text-primary"></i
                    ></td>
                    <td class="text-center" v-else>{{ '-' }}</td>
                    <td class="text-center" v-if="$route.query.preview === 'false'">
                        <i class="fa fa-edit text-primary"  
                          @click="editDokumenPendukung(state)"
                          style="cursor: pointer">
                        </i>
                        <br />
                        <i class="fa fa-trash text-danger"
                          @click="onDeleteDokumenPendukung(state.id)"
                          style="cursor: pointer">
                        </i>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                class="d-flex w-100 justify-content-center align-items-center"
                v-else
              >
                <strong>DATA DOKUMEN PENDUKUNG TIDAK ADA</strong>
              </div>
            </template>
          </iq-card>

          <iq-card class="pdf-exclude">
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary text-uppercase"
                >EXPORT PROFILE
                {{ $route.query.user === 'candidate' ? 'Candidate' : 'Crew' }} -
                {{ user?.personal_data?.full_name ?? '-' }}</h4
              >
            </template>
            <template v-slot:body>
              <b-button
                block
                variant="success"
                type="button"
                @click="printPDF(user?.personal_data?.full_name)"
                :disabled="isLoading"
              >
                <span v-if="isLoading" class="spinner-border spinner-border-sm" />

                <span v-else>Export to PDF</span>
              </b-button>
            </template>
          </iq-card>
        </b-col>
      </b-row>

      <b-modal
        size="xl"
        title="Condite Crew"
        cancel-title="Close"
        v-model="showModal"
        hide-footer
      >
        <fieldset class="form-group border p-3" v-if="(user?.position_id === 1 || user?.position_id === 2)">
          <legend class="w-auto px-2 text-primary">Preview Condite</legend>
          <b-row>
            <FormCondite :previewMode="true" :dataCondites="dataCondite" />
            <b-col md="3">
              <div class="form-group">
                <small class="mb-3"
                  ><strong
                    >Rekomendasi oleh Nakhoda / Chief Officer <br />
                  </strong>
                  Recommendation by Master</small
                >

                <b-form-textarea
                  style="font-size: 9pt"
                  rows="2"
                  placeholder="Comments if any / Komentar jika ada"
                  disabled
                  :value="conditeDetail?.recommendation_master"
                ></b-form-textarea>
              </div>
              <hr />
              <div class="form-group row">
                <label
                  label="Clasification *"
                  for="nama_kandidiat"
                  class="col-sm-6"
                  >Wawancara Oleh</label
                >

                <div class="col-sm-6">
                  <b-form-input
                    id="job_name"
                    type="text"
                    placeholder="Nama"
                    disabled
                    :value="conditeDetail?.name_master"
                    required
                  ></b-form-input>
                </div>
              </div>
            </b-col>
            <b-col md="3">
              <div class="form-group">
                <small class="mb-3"
                  ><strong
                    >Rekomendasi oleh KKM (khusus untuk crew bagian mesin)
                    <br />
                  </strong>
                  Recommendation by Chief Engineer (only for engine crew)</small
                >

                <b-form-textarea
                  style="font-size: 9pt"
                  rows="2"
                  placeholder="Comments if any / Komentar jika ada"
                  disabled
                  :value="conditeDetail?.recommendation_user"
                  required
                ></b-form-textarea>
              </div>
              <hr />
              <div class="form-group row">
                <label
                  label="Clasification *"
                  for="nama_kandidiat"
                  class="col-sm-6"
                  >Wawancara Oleh</label
                >

                <div class="col-sm-6">
                  <b-form-input
                    id="job_name"
                    type="text"
                    placeholder="Nama"
                    disabled
                    :value="conditeDetail?.name_user"
                    required
                  ></b-form-input>
                </div>
              </div>
            </b-col>
            <b-col md="3">
              <div class="form-group">
                <small class="mb-3"
                  ><strong
                    >Rekomendasi oleh Port Captain / Port Engineer <br />
                  </strong>
                  Recommendation by Port Captain / Port Engineer</small
                >

                <b-form-textarea
                  style="font-size: 9pt"
                  rows="2"
                  placeholder="Comments if any / Komentar jika ada"
                  required
                  disabled
                  :value="conditeDetail?.recommendation4"
                ></b-form-textarea>
              </div>
              <hr />
              <div class="form-group row">
                <label
                  label="Clasification *"
                  for="nama_kandidiat"
                  class="col-sm-6"
                  >Wawancara Oleh</label
                >

                <div class="col-sm-6">
                  <b-form-input
                    id="job_name"
                    type="text"
                    placeholder="Nama"
                    disabled
                    :value="conditeDetail?.name4"
                    required
                  ></b-form-input>
                </div>
              </div>
            </b-col>
            <b-col md="3">
              <div class="form-group">
                <small class="mb-3"
                  ><strong>Rekomendasi oleh Site Manager <br /> </strong>
                  Recommendation by Site Manager</small
                >

                <b-form-textarea
                  style="font-size: 9pt"
                  rows="2"
                  placeholder="Comments if any / Komentar jika ada"
                  required
                  disabled
                  :value="conditeDetail?.recommendation_manager"
                ></b-form-textarea>
              </div>
              <hr />
              <div class="form-group row">
                <label
                  label="Clasification *"
                  for="nama_kandidiat"
                  class="col-sm-6"
                  >Wawancara Oleh</label
                >

                <div class="col-sm-6">
                  <b-form-input
                    id="job_name"
                    type="text"
                    placeholder="Nama"
                    disabled
                    :value="conditeDetail?.name_manager"
                    required
                  ></b-form-input>
                </div>
              </div>
            </b-col>
          </b-row>
        </fieldset>

        <div v-else>
          <fieldset class="form-group border p-3">
                <b-row>
                  <b-col md="12">
                    <table class="table table-striped">
                      <thead>
                        <tr class="text-center">
                          <th width="40%">DESKRIPSI</th>
                          <th width="20%">PENILAIAN</th>
                          <th width="40%">NOTES</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(state, index) in merged_aspects"
                          :key="index"
                        >
                          <th>{{ state.content }}</th>
                          <td class="text-center">
                            <b-form-select
                              plain
                              v-model="state.value"
                              :options="optionsPoints"
                              size="sm"
                              class="col-md-12"
                              required
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="null"
                                  >Nilai</b-form-select-option
                                >
                              </template>
                            </b-form-select>
                          </td>
                          <td
                            ><b-form-textarea
                              rows="4"
                              value="Notes"
                              v-model="state.remarks"
                            ></b-form-textarea
                          ></td>
                        </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
              </fieldset>
              <b-row class="mt-3">
                <b-col md="6">
                  <h4 class="text-primary"
                    >Disetujui Oleh / <i>Approval by </i></h4
                  >
                  <hr />
                  <b-form-group label="PIC" label-for="pic">
                    <b-form-input 
                      :value="detailConduct.approved_by"
                      disabled
                    />
                  </b-form-group>
  
                  <b-form-group label="Notes / Remarks" label-for="pic">
                    <b-form-textarea
                      rows="2"
                      :value="detailConduct.recommendation1"
                      disabled
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <h4 class="text-primary"
                    >Diverifikasi Oleh / <i>Verified by </i></h4
                  >
                  <hr />
                  <b-form-group label="PIC" label-for="pic">
                    <b-form-input 
                      :value="detailConduct.verified_by"
                      disabled
                    />
                  </b-form-group>
  
                  <b-form-group label="Notes / Remarks" label-for="pic">
                    <b-form-textarea
                      rows="2"
                      :value="detailConduct.recommendation2"
                      disabled
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <h4 class="text-primary"
                    >Diperiksa Oleh / <i>Checked by </i></h4
                  >
                  <hr />
                  <b-form-group label="PIC" label-for="pic">
                    <b-form-input 
                      :value="detailConduct.checked_by"
                      disabled
                    />
                  </b-form-group>
  
                  <b-form-group label="Notes / Remarks" label-for="pic">
                    <b-form-textarea
                      rows="2"
                      :value="detailConduct.recommendation3"
                      disabled
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <h4 class="text-primary">Dinilai Oleh / <i>Rated By </i></h4>
                  <hr />
                  <b-form-group label="PIC" label-for="pic">
                    <b-form-input 
                      :value="detailConduct.rated_by"
                      disabled
                    />
                  </b-form-group>
  
                  <b-form-group label="Notes / Remarks" label-for="pic">
                    <b-form-textarea
                      rows="2"
                      :value="detailConduct.recommendation4"
                      disabled
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
        </div>
      </b-modal>

      <b-modal
        id="swap-vehicle"
        size="lg"
        :title="swap_form_edit ? 'Edit History Position' : 'Add History Position'"
        cancel-title="Close"
        hide-footer
      >
        <form action="#" method="post" @submit="onSaveSwapVehicle">
          <b-row>
            <b-col md="12">
              <b-card no-body class="mb-1">
                <b-card-body>
                  <b-row>
                    <b-col lg='12' v-if="!swap_form_edit">
                      <b-form-group label="Swap ?" label-for="swap">
                        <b-form-checkbox
                          v-model="swap_form.swap"
                          name="check-button"
                          switch
                          @change="() => {
                            swap_form.status_emp_crewing = null
                          }"
                        >{{ swap_form.swap ? "Yes" : "No" }}</b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <b-col lg="12" v-if="swap_form.swap !== true">
                      <b-form-group label="Status Crew" label-for="status_crew">
                        <b-form-select
                          plain
                          v-model="swap_form.status_emp_crewing"
                          :options="options_status_crew_fleet"
                          size="sm"
                          required
                          @change="() => {
                            swap_form.status_emp_crewing === 4 ? 
                            options_reason_signoff = options_reason_signoff.map(val => {
                              return{
                                ...val,
                                disabled: val.id === 7 ? false : true,
                                checked: val.id === 7 ? true : false
                              }
                            }) : 
                            options_reason_signoff = options_reason_signoff.map(val => {
                              return{
                                ...val,
                                disabled:false,
                                checked:false
                              }
                            })
                          }"
                        >
                          <template v-slot:first>
                            <b-form-select-option  :value="null"
                              >Select Status Crew</b-form-select-option
                            >
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col md="12" v-if="swap_form.status_emp_crewing !== 1 && swap_form.status_emp_crewing !== null && swap_form.status_emp_crewing !== 5 && swap_form.swap !== true">
                      <span class="text-black">Reason Sign Off</span>
                      <table class="table table-striped">
                        <thead>
                          <tr class="text-center">
                            <th width="90%">DESKRIPSI</th>
                            <th width="10%">STATUS</th>
                          </tr>
                        </thead>    
                        <tbody>
                          <tr
                            v-for="(state, index) in options_reason_signoff"
                            v-bind:key="index"
                          >
                            <th> {{ state.name }}</th>
                            <td class="text-center">
                              <template>
                                <b-form-checkbox
                                  v-model="state.checked"
                                  name="check-button"
                                  class="ml-4"
                                  switch
                                  inline
                                  :disabled="state.disabled"
                                  @change="
                                    selectStatusSignOffCrew(state, index, 'swap')
                                  "
                                >
                                </b-form-checkbox>
                              </template>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-col>
                    <b-col md="12" v-if="options_reason_signoff?.[6]?.checked && swap_form.swap !== true">
                      <b-form-group label="Reason Blacklist">
                        <b-form-select
                          plain
                          v-model="swap_form.reason_blacklist_id"
                          :options="optionBlacklistReasons"
                          size="lg"
                          aria-placeholder="Pilih Alasan"
                          required
                          name="reason_off"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="null"
                              >Pilih Alasan</b-form-select-option
                            >
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="12">
                      <b-form-group label="Vehicles" label-for="vehicle_id">
                        <v-select
                          v-model="swap_form.vehicle_id"
                          label="text"
                          :options="vehicleOptions"
                          :reduce="(vehicleOptions) => vehicleOptions.value"
                          placeholder="Select Type of Vehicle"
                          required
                        ></v-select>
                      </b-form-group>
                    </b-col>

                    <b-col lg="12">
                      <b-form-group
                        label="Join on Board"
                        label-for="join_on_board"
                      >
                        <b-form-input
                          type="datetime-local"
                          id="join_on_board"
                          v-model="swap_form.join_on_board"
                          required
                        ></b-form-input>
                        <small style="float: right"
                          >click <i class="fa fa-calendar"></i> to show
                        </small>
                      </b-form-group>
                    </b-col>
                    <b-col lg="12">
                      <b-form-group
                        label="Sign On"
                        label-for="start_date"
                      >
                        <b-form-input
                          type="datetime-local"
                          id="start_date"
                          v-model="swap_form.start_date"
                          required
                        ></b-form-input>
                        <small style="float: right"
                          >click <i class="fa fa-calendar"></i> to show
                        </small>
                      </b-form-group>
                    </b-col>
                    <b-col lg="12" v-if="!swap_form.swap && Number(swap_form.status_emp_crewing) !== 1">
                      <b-form-group
                        label="Sign Off"
                        label-for="end_date"
                      >
                        <b-form-input
                          type="datetime-local"
                          id="end_date"
                          v-model="swap_form.end_date"
                          :required="swap_form.status_emp_crewing !== 1"
                        ></b-form-input>
                        <small style="float: right"
                          >click <i class="fa fa-calendar"></i> to show
                        </small>
                      </b-form-group>
                    </b-col>

                    <b-col lg="12">
                      <b-form-group label="Company" label-for="company_id">
                        <b-form-select
                          plain
                          v-model="swap_form.company_id"
                          size="sm"
                          :options="companyOptions"
                          required
                          placeholder="Select options..."
                        >
                          <template v-slot:first>
                            <b-form-select-option
                              :value="''"
                            >Select Company</b-form-select-option>
                          </template>
                        </b-form-select> </b-form-group
                    ></b-col>

                    <b-col lg="12">
                      <b-form-group label="Port">
                        <v-select
                          v-model="swap_form.port_id"
                          label="text"
                          :options="portOptions"
                          :reduce="(portOptions) => portOptions.value"
                          placeholder="Select port..."
                          required
                        ></v-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="12">
                      <b-form-group label="Work Area" label-for="work_area_id">
                        <b-form-select
                          plain
                          v-model="swap_form.work_area_id"
                          size="sm"
                          :options="workareaOptions"
                          required
                        >
                          <template v-slot:first>
                            <b-form-select-option
                              :value="''"
                            >Select Work Area</b-form-select-option>
                          </template>
                        </b-form-select> </b-form-group
                    ></b-col>
                    <b-col lg="12">
                      <b-form-group label="Position/Jabatan" label-for="position_id">
                        <v-select
                          v-model="swap_form.position_id"
                          label="text"
                          :options="master_list_position"
                          :reduce="(master_list_position) => master_list_position.value"
                          placeholder="Select Position"
                          required
                        ></v-select>
                      </b-form-group>
                    </b-col>

                    <b-form-group
                      class="col-md-12"
                      label="Attachment"
                      label-for="attachment"
                    >
                      <div class="custom-file">
                        <input
                          type="file"
                          class="custom-file-input"
                          id="validatedCustomFile"
                          accept="application/*,image/png,image/jpeg,image/jpg"
                          @change="(event) => prefiewFiles(event, swap_form)"
                        />
                        <label
                          class="custom-file-label"
                          for="validatedCustomFile"
                          v-if="!swap_form.file"
                          >Browse file...</label
                        >
                        <label
                          class="custom-file-label"
                          for="validatedCustomFile"
                          v-else
                          >{{ swap_form.file_label }}</label
                        >
                      </div>
                      <small class="text-muted">
                        File Type * :
                        <b-badge variant="info"
                          >.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs |
                          .pdf</b-badge
                        >
                        <br />
                        File CapaKota * :
                        <b-badge variant="info">10 MB / File</b-badge>
                      </small>
                    </b-form-group>

                    <b-col lg="12">
                      <b-form-group
                        type="textarea"
                        label="Notes"
                        label-for="remarks"
                      >
                        <b-form-textarea
                          class="form-control"
                          id="remarks"
                          name="remarks"
                          rows="3"
                          v-model="swap_form.remarks"
                        ></b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col :md="'6'">
              <b-button variant="secondary" block @click="hideModal"
                >Close</b-button
              >
            </b-col>
            <b-col md="6">
              <b-button variant="primary" block type="submit" :disabled="saving"
                ><i class="fa fa-edit" v-if="!saving"></i>
                <span v-if="!saving">SUBMIT</span>
                <span v-if="saving" class="spinner-border spinner-border-sm" />
              </b-button>
            </b-col>
          </b-row>
        </form>
      </b-modal>

      <b-modal
        id="keluarga"
        size="lg"
        :title="is_edit ? 'Update ' : 'Tambah ' + 'Data Keluarga'"
        cancel-title="Close"
        hide-footer
      >
        <form action="#" method="post" @submit="onSaveKeluarga">
          <fieldset class="form-group border p-3 mt-1" v-if="!is_edit">
            <legend class="w-auto text-primary" style="font-size: 12pt"
              >DATA KELUARGA</legend
            >
            <div v-for="(state, index) in user_edit.keluarga" v-bind:key="index">
              <div
                v-if="index > 0"
                class="d-flex w-100 px-1 my-1 justify-content-end cursor-pointer"
                @click="deleteFamilyForm(index)"
              >
                <i class="fa fa-trash"></i>
              </div>
              <b-row>
                <b-form-group
                  class="col-md-6"
                  label="Nama Lengkap"
                  label-for="nama_lengkap"
                >
                  <b-form-input
                    id="nama_lengkap"
                    name="nama_lengkap"
                    placeholder="Type Text ..."
                    v-model="state.nama_lengkap"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  label="Hubungan"
                  label-for="hubungan"
                >
                  <b-form-select
                    plain
                    v-model="state.status"
                    :options="hubungan"
                    size="sm"
                    required
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Select Hubungan</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-row>
              <b-row>
                <b-form-group
                  class="col-md-4"
                  label="Tempat Lahir"
                  label-for="tempat_lahir"
                >
                  <b-form-input
                    id="tempat_lahir"
                    name="tempat_lahir"
                    placeholder="Type Text ..."
                    v-model="state.tempat_lahir"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Tanggal Lahir"
                  label-for="tanggal_lahir"
                >
                  <b-form-input
                    type="date"
                    id="tanggal_lahir"
                    name="tanggal_lahir"
                    placeholder="Type Text ..."
                    v-model="state.tanggal_lahir"
                    @change="calculateAge(state)"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Umur"
                  label-for="age_family"
                >
                  <b-form-input
                    type="number"
                    id="age_family"
                    name="age_family"
                    placeholder="Type Number ..."
                    v-model="state.age_family"
                    disabled
                    required
                  ></b-form-input>
                </b-form-group>
              </b-row>
              <b-row>
                <b-form-group
                  class="col-md-4"
                  label="Jenis Kelamin"
                  label-for="jenis_kelamin"
                >
                  <b-form-select
                    plain
                    v-model="state.jenis_kelamin"
                    :options="jenisKelaminFamily"
                    size="sm"
                    required
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Select Jenis Kelamin</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
              
                <b-form-group
                  class="col-md-4"
                  label="Pekerjaan"
                  label-for="pekerjaan"
                >
                  <b-form-input
                    id="pekerjaan"
                    name="pekerjaan"
                    v-model="state.pekerjaan"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="No. Tlp/Handphone"
                  label-for="handphone"
                >
                  <b-form-input
                    type="number"
                    id="handphone"
                    name="handphone"
                    placeholder="Type Number ..."
                    v-model="state.no_hp"
                    required
                  ></b-form-input>
                </b-form-group>
                  <b-form-group
                    class="col-md-12"
                    label="Alamat"
                    label-for="alamat"
                  >
                    <b-form-textarea
                      id="alamat"
                      name="alamat"
                      rows="3"
                      v-model="state.alamat"
                      required
                    ></b-form-textarea>
                  </b-form-group>
                <div
                  v-if="user_edit.keluarga.length > 1"
                  class="d-flex w-100 px-2 my-1 border-bottom"
                ></div>
              </b-row>
            </div>
           
            <b-row>
              <b-col md="12">
                <b-button
                  variant="success"
                  class="mt-1"
                  @click="addFamilyForm"
                >
                  <i class="fa fa-plus"></i> ADD FORM
                </b-button>
              </b-col>
            </b-row>
          </fieldset>
          <div v-else>
              <b-row>
                <b-form-group
                  class="col-md-6"
                  label="Nama Lengkap"
                  label-for="nama_lengkap"
                >
                  <b-form-input
                    id="nama_lengkap"
                    name="nama_lengkap"
                    placeholder="Type Text ..."
                    v-model="update_keluarga.nama_lengkap"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  label="Hubungan"
                  label-for="hubungan"
                >
                  <b-form-select
                    plain
                    v-model="update_keluarga.status"
                    :options="hubungan"
                    size="sm"
                    required
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Select Hubungan</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-row>
              <b-row>
                <b-form-group
                  class="col-md-4"
                  label="Tempat Lahir"
                  label-for="tempat_lahir"
                >
                  <b-form-input
                    id="tempat_lahir"
                    name="tempat_lahir"
                    placeholder="Type Text ..."
                    v-model="update_keluarga.tempat_lahir"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Tanggal Lahir"
                  label-for="tanggal_lahir"
                >
                  <b-form-input
                    type="date"
                    id="tanggal_lahir"
                    name="tanggal_lahir"
                    placeholder="Type Text ..."
                    v-model="update_keluarga.tanggal_lahir"
                    @change="calculateAge(update_keluarga)"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Umur"
                  label-for="age_family"
                >
                  <b-form-input
                    type="number"
                    id="age_family"
                    name="age_family"
                    placeholder="Type Number ..."
                    v-model="update_keluarga.age_family"
                    disabled
                    required
                  ></b-form-input>
                </b-form-group>
              </b-row>
              <b-row>
                <b-form-group
                  class="col-md-4"
                  label="Jenis Kelamin"
                  label-for="jenis_kelamin"
                >
                  <b-form-select
                    plain
                    v-model="update_keluarga.jenis_kelamin"
                    :options="jenisKelaminFamily"
                    size="sm"
                    required
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Select Jenis Kelamin</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
              
                <b-form-group
                  class="col-md-4"
                  label="Pekerjaan"
                  label-for="pekerjaan"
                >
                  <b-form-input
                    id="pekerjaan"
                    name="pekerjaan"
                    v-model="update_keluarga.pekerjaan"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="No. Tlp/Handphone"
                  label-for="handphone"
                >
                  <b-form-input
                    type="number"
                    id="handphone"
                    name="handphone"
                    placeholder="Type Number ..."
                    v-model="update_keluarga.no_hp"
                    required
                  ></b-form-input>
                </b-form-group>
                  <b-form-group
                    class="col-md-12"
                    label="Alamat"
                    label-for="alamat"
                  >
                    <b-form-textarea
                      id="alamat"
                      name="alamat"
                      rows="3"
                      v-model="update_keluarga.alamat"
                      required
                    ></b-form-textarea>
                  </b-form-group>
              </b-row>
          </div>
    
          <b-button
            :disabled="saving_keluarga"
            block
            variant="primary"
            type="submit"
            class="my-3"
          >
            <span
              v-if="saving_keluarga"
              class="spinner-border spinner-border-sm"
            />
            <span v-else>{{is_edit ? "Update" : "Tambah"}} Data Keluarga</span>
          </b-button>
        </form>
      </b-modal>

      <b-modal
        id="kompetensi"
        size="lg"
        :title="is_edit ? 'Update Data Sertifikat Kompentensi' : 'Tambah Data Sertifikat Kompentensi'"
        cancel-title="Close"
        hide-footer
      >
        <form action="#" method="post" @submit="onSaveCompetency">
          <fieldset class="form-group border p-3 mt-1" v-if="!is_edit">
            <legend class="w-auto text-primary" style="font-size: 12pt"
              >SERTIFIKAT KOMPETENSI</legend
            >
            <div
              v-for="(state, index) in user_edit.certificate_of_competency"
              v-bind:key="index"
            >
              <div
                v-if="index > 0"
                class="d-flex w-100 px-1 my-1 justify-content-end cursor-pointer"
                @click="deleteCertificateOfCompetencyForm(index)"
              >
                <i class="fa fa-trash"></i>
              </div>
              <b-row>
                <b-form-group
                  class="col-md-4"
                  label="Nama Dokumen"
                  label-for="certificate_list_id"
                >
                  <v-select
                    v-model="state.certificate_list_id"
                    label="text"
                    :options="coc_options"
                    :reduce="(coc_options) => coc_options.value"
                    placeholder="Select certificate..."
                    name="certificate_list_id"
                    required
                  ></v-select>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Level"
                  label-for="sub_education"
                >
                  <b-form-select
                    plain
                    v-model="state.sub_education"
                    required
                    size="sm"
                  >
                    <template v-slot:first>
                      <b-form-select-option value="none">Select Options</b-form-select-option>
                      <b-form-select-option value="management">Manajemen level</b-form-select-option>
                      <b-form-select-option value="operational">Operational level</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="No Dokumen"
                  label-for="nomor_sertifikat"
                >
                  <b-form-input
                    id="nomor_sertifikat"
                    name="nomor_sertifikat"
                    placeholder="Type Number ..."
                    v-model="state.document_number"
                    required
                  ></b-form-input>
                </b-form-group>
    
                <b-form-group
                  class="col-md-4"
                  label="Tanggal Terbit"
                  label-for="date_issued"
                >
                  <b-form-input
                    type="date"
                    id="date_issued"
                    v-model="state.date_issued"
                    required
                  ></b-form-input>
                  <small style="float: right"
                    >click <i class="fa fa-calendar"></i> to show
                  </small>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Tanggal Berakhir"
                  label-for="masa_berlaku"
                  v-if="!isUnlimited(state.certificate_list_id, true)"
                >
                  <b-form-input
                    type="date"
                    id="masa_berlaku"
                    v-model="state.validity_period"
                  ></b-form-input>
                  <small style="float: right"
                    >click <i class="fa fa-calendar"></i> to show
                  </small>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Tempat diterbitkan"
                  label-for="place_issued"
                >
                  <b-form-input
                    id="place_issued"
                    name="place_issued"
                    placeholder="Type Text ..."
                    v-model="state.place_issued"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-12"
                  label="Attachment"
                  label-for="attachment"
                >
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="validatedCustomFile"
                      @change="(event) => prefiewFiles(event, state)"
                      :required="!is_edit"
                      accept="image/*,application/*"
                    />
                    <label
                      class="custom-file-label"
                      for="validatedCustomFile"
                      v-if="!state.file"
                      >Browse file...</label
                    >
                    <label
                      class="custom-file-label"
                      for="validatedCustomFile"
                      v-else
                      >{{ state.file_label }}</label
                    >
                  </div>
                  <small
                      class="text-primary text-right"
                      style="cursor:pointer"
                      v-if="state.file_url"
                      @click="handleOpenFilePreview(state.file_url)">Open File
                  </small>
                    <br />
                  <small class="text-muted">
                    File Type * :
                    <b-badge variant="info"
                      >.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs |
                      .pdf</b-badge
                    >
                    <br />
                    File CapaKota * :
                    <b-badge variant="info">10 MB / File</b-badge>
                  </small>
                </b-form-group>
              </b-row>
    
              <div
                v-if="user_edit.certificate_of_competency.length > 1"
                class="d-flex w-100 px-2 my-1 border-bottom"
              ></div>
            </div>
            <b-row>
              <b-col md="12">
                <b-button
                  variant="success"
                  class="mt-1"
                  @click="addCertificateOfCompetencyForm"
                >
                  <i class="fa fa-plus"></i> ADD FORM
                </b-button>
              </b-col>
            </b-row>
          </fieldset>
          <div v-else>
              <b-row>
                <b-form-group
                  class="col-md-4"
                  label="Nama Dokumen"
                  label-for="certificate_list_id"
                >
                  <v-select
                  v-model="update_certificate_of_competency.certificate_list_id"
                    label="text"
                    :options="coc_options"
                    :reduce="(coc_options) => coc_options.value"
                    placeholder="Select certificate..."
                    name="certificate_list_id"
                    required
                  ></v-select>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Level"
                  label-for="sub_education"
                >
                  <b-form-select
                    plain
                    v-model="update_certificate_of_competency.sub_education"
                    required
                    size="sm"
                  >
                    <template v-slot:first>
                      <b-form-select-option value="none">Select Options</b-form-select-option>
                      <b-form-select-option value="management">Manajemen level</b-form-select-option>
                      <b-form-select-option value="operational">Operational level</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="No Dokumen"
                  label-for="nomor_sertifikat"
                >
                  <b-form-input
                    id="nomor_sertifikat"
                    name="nomor_sertifikat"
                    placeholder="Type Number ..."
                    v-model="update_certificate_of_competency.document_number"
                    required
                  ></b-form-input>
                </b-form-group>
    
                <b-form-group
                  class="col-md-4"
                  label="Tanggal Terbit"
                  label-for="date_issued"
                >
                  <b-form-input
                    type="date"
                    id="date_issued"
                    v-model="update_certificate_of_competency.date_issued"
                    required
                  ></b-form-input>
                  <small style="float: right"
                    >click <i class="fa fa-calendar"></i> to show
                  </small>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Tanggal Berakhir"
                  label-for="masa_berlaku"
                >
                  <b-form-input
                    type="date"
                    id="masa_berlaku"
                    v-model="update_certificate_of_competency.validity_period"
                  ></b-form-input>
                  <small style="float: right"
                    >click <i class="fa fa-calendar"></i> to show
                  </small>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Tempat diterbitkan"
                  label-for="place_issued"
                >
                  <b-form-input
                    id="place_issued"
                    name="place_issued"
                    placeholder="Type Text ..."
                    v-model="update_certificate_of_competency.place_issued"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-12"
                  label="Attachment"
                  label-for="attachment"
                >
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="validatedCustomFile"
                      @change="(event) => prefiewFiles(event, update_certificate_of_competency)"
                      :required="!is_edit"
                    />
                    <label
                      class="custom-file-label"
                      for="validatedCustomFile"
                      v-if="!update_certificate_of_competency.file"
                      >Browse file...</label
                    >
                    <label
                      class="custom-file-label"
                      for="validatedCustomFile"
                      v-else
                      >{{ update_certificate_of_competency.file_label }}</label
                    >
                  </div>
                    <br />
                  <small class="text-muted">
                    File Type * :
                    <b-badge variant="info"
                      >.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs |
                      .pdf</b-badge
                    >
                    <br />
                    File CapaKota * :
                    <b-badge variant="info">10 MB / File</b-badge>
                  </small>
                </b-form-group>
              </b-row>
          </div>
          <b-button
            :disabled="saving_competency"
            block
            variant="primary"
            type="submit"
            class="my-3"
          >
            <span
              v-if="saving_competency"
              class="spinner-border spinner-border-sm"
            />
            <span v-else>{{ is_edit ? 'Update' : 'Tambah' }} Data Certificate of Competency</span>
          </b-button>
        </form>
      </b-modal>

      <b-modal
        id="dokumen_perjalanan"
        size="lg"
        :title="is_edit ? 'Update Data Dokumen Perjalanan' : 'Tambah Data Dokumen Perjalanan'"
        cancel-title="Close"
        hide-footer
      >
        <form action="#" method="post" @submit="onSaveTravelDokumen">
          <fieldset class="form-group border p-3 mt-1" v-if="!is_edit">
            <legend class="w-auto text-primary" style="font-size: 12pt"
              >TRAVEL DOCUMENT</legend
            >
            <div
              v-for="(state, index) in user_edit.travel_document"
              v-bind:key="index"
            >
              <div
                v-if="index > 0"
                class="d-flex w-100 px-1 my-1 justify-content-end cursor-pointer"
                @click="deleteTravelDocument(index)"
              >
                <i class="fa fa-trash text-danger"></i>
              </div>
              <b-row>
                <b-form-group label="Nama Dokumen" class="col-md-6">
                  <b-form-select
                    plain
                    v-model="state.document_list_id"
                    required
                    size="sm"
                    :options="travel_document_options"
                    name="document_list_id"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="''"
                        >Select Document</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
    
                <b-form-group
                  class="col-md-6"
                  label="Nomor Dokumen"
                  label-for="nomor_dokumen"
                >
                  <b-form-input
                    id="nomor_dokumen"
                    name="nomor_dokumen"
                    placeholder="Type Number ..."
                    v-model="state.document_number"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-row>
              <b-row>
                <b-form-group
                  class="col-md-6"
                  label="Date Issued"
                  label-for="date_issued"
                >
                  <b-form-input
                    type="date"
                    id="date_issued"
                    v-model="state.date_issued"
                    required
                  ></b-form-input>
                  <small style="float: right"
                    >click <i class="fa fa-calendar"></i> to show
                  </small>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  label="Masa Berlaku"
                  label-for="masa_berlaku"
                >
                  <b-form-input
                    type="date"
                    id="masa_berlaku"
                    v-model="state.validity_period"
                  ></b-form-input>
                  <small style="float: right"
                    >click <i class="fa fa-calendar"></i> to show
                  </small>
                </b-form-group>
              </b-row>
              <b-row>
                <b-form-group
                  class="col-md-6"
                  label="Place Issued"
                  label-for="place_issued"
                >
                  <b-form-input
                    id="place_issued"
                    name="place_issued"
                    placeholder="Type Text ..."
                    v-model="state.place_issued"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  label="Attachment"
                  label-for="attachment"
                >
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="validatedCustomFile"
                      :required="!is_edit"
                      @change="(event) => prefiewFiles(event, state)"
                      accept="image/*,application/*"
                    />
                    <label
                      class="custom-file-label"
                      for="validatedCustomFile"
                      v-if="!state.file"
                      >Browse file...</label
                    >
                    <label
                      class="custom-file-label"
                      for="validatedCustomFile"
                      v-else
                      >{{ state.file_label }}</label
                    >
                  </div>
                    <small
                      class="text-primary text-right"
                      style="cursor:pointer"
                      v-if="state.file"
                      @click="handleOpenFilePreview(state.file_url)">Open File</small>
                    <br />
                  <small class="text-muted">
                    File Type * :
                    <b-badge variant="info"
                      >.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs |
                      .pdf</b-badge
                    >
                    <br />
                    File CapaKota * :
                    <b-badge variant="info">10 MB / File</b-badge>
                  </small>
                </b-form-group>
              </b-row>
              <div
                v-if="user_edit.travel_document.length > 1"
                class="d-flex w-100 px-2 my-1 border-bottom"
              ></div>
            </div>
            <b-row>
              <b-col md="12">
                <b-button
                  variant="success"
                  class="mt-1"
                  @click="addTravelDocumentForm"
                >
                  <i class="fa fa-plus"></i> ADD FORM
                </b-button>
              </b-col>
            </b-row>
          </fieldset>
          <div v-else>
              <b-row>
                <b-form-group label="Nama Dokumen" class="col-md-6">
                  <b-form-select
                    plain
                    v-model="update_travel_document.document_list_id"
                    required
                    size="sm"
                    :options="travel_document_options"
                    name="document_list_id"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="''"
                        >Select Document</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
    
                <b-form-group
                  class="col-md-6"
                  label="Nomor Dokumen"
                  label-for="nomor_dokumen"
                >
                  <b-form-input
                    id="nomor_dokumen"
                    name="nomor_dokumen"
                    placeholder="Type Number ..."
                    v-model="update_travel_document.document_number"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-row>
              <b-row>
                <b-form-group
                  class="col-md-6"
                  label="Date Issued"
                  label-for="date_issued"
                >
                  <b-form-input
                    type="date"
                    id="date_issued"
                    v-model="update_travel_document.date_issued"
                    required
                  ></b-form-input>
                  <small style="float: right"
                    >click <i class="fa fa-calendar"></i> to show
                  </small>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  label="Masa Berlaku"
                  label-for="masa_berlaku"
                >
                  <b-form-input
                    type="date"
                    id="masa_berlaku"
                    v-model="update_travel_document.validity_period"
                  ></b-form-input>
                  <small style="float: right"
                    >click <i class="fa fa-calendar"></i> to show
                  </small>
                </b-form-group>
              </b-row>
              <b-row>
                <b-form-group
                  class="col-md-6"
                  label="Place Issued"
                  label-for="place_issued"
                >
                  <b-form-input
                    id="place_issued"
                    name="place_issued"
                    placeholder="Type Text ..."
                    v-model="update_travel_document.place_issued"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  label="Attachment"
                  label-for="attachment"
                >
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="validatedCustomFile"
                      :required="!is_edit"
                      @change="(event) => prefiewFiles(event, update_travel_document)"
                    />
                    <label
                      class="custom-file-label"
                      for="validatedCustomFile"
                      v-if="!update_travel_document.file"
                      >Browse file...</label
                    >
                    <label
                      class="custom-file-label"
                      for="validatedCustomFile"
                      v-else
                      >{{ update_travel_document.file_label }}</label
                    >
                  </div>
                  <small class="text-muted">
                    File Type * :
                    <b-badge variant="info"
                      >.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs |
                      .pdf</b-badge
                    >
                    <br />
                    File CapaKota * :
                    <b-badge variant="info">10 MB / File</b-badge>
                  </small>
                </b-form-group>
              </b-row>
          </div>
          <b-button
            :disabled="saving_traveldocument"
            block
            variant="primary"
            type="submit"
            class="my-3"
          >
            <span
              v-if="saving_traveldocument"
              class="spinner-border spinner-border-sm"
            />
            <span v-else>{{ is_edit ? 'Update' : 'Tambah' }} Data Travel Document</span>
          </b-button>
        </form>
      </b-modal>

      <b-modal
        id="profesi"
        size="lg"
        :title="is_edit ?  'Update Data Sertifikat Profesi' : 'Tambah Data Sertifikat Profesi'"
        cancel-title="Close"
        hide-footer
      >
        <form action="#" method="post" @submit="onSaveProficiency">
          <fieldset class="form-group border p-3 mt-1" v-if="!is_edit">
            <legend class="w-auto text-primary" style="font-size: 12pt"
              >CERTIFICATE OF PROFICIENCY</legend
            >
            <div
              v-for="(state, index) in user_edit.certificate_of_proficiency"
              v-bind:key="index"
            >
              <div
                v-if="
                  index > 0
                "
                class="d-flex w-100 px-1 my-1 justify-content-end cursor-pointer"
                @click="deleteCertificateOfProfiencyForm(index)"
              >
                <i class="fa fa-trash text-danger"></i>
              </div>
              <b-row>
                <b-form-group label="Nama Sertifikat" class="col-md-6">
                  <b-form-select
                    plain
                    v-model="state.certificate_list_id"
                    required
                    size="sm"
                    :options="cop_options"
                    name="document_list_id"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="''"
                        >Select Certificate</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  label="Nomor Dokumen"
                  label-for="nomor_dokumen"
                >
                  <b-form-input
                    id="nomor_dokumen"
                    name="nomor_dokumen"
                    placeholder="Type Number ..."
                    v-model="state.document_number"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-row>
              <b-row>
                <b-form-group
                  class="col-md-6"
                  label="Date Issued"
                  label-for="date_issued"
                >
                  <b-form-input
                    type="date"
                    id="date_issued"
                    v-model="state.date_issued"
                    required
                  ></b-form-input>
                  <small style="float: right"
                    >click <i class="fa fa-calendar"></i> to show
                  </small>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  label="Masa Berlaku"
                  label-for="masa_berlaku"
                  v-if="!isUnlimited(state.certificate_list_id, false)"
                >
                  <b-form-input
                    type="date"
                    id="masa_berlaku"
                    v-model="state.validity_period"
                  ></b-form-input>
                  <small style="float: right"
                    >click <i class="fa fa-calendar"></i> to show
                  </small>
                </b-form-group>
              </b-row>
              <b-row>
                <b-form-group
                  class="col-md-6"
                  label="Place Issued"
                  label-for="place_issued"
                >
                  <b-form-input
                    id="place_issued"
                    name="place_issued"
                    placeholder="Type Text ..."
                    v-model="state.place_issued"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  label="Attachment"
                  label-for="attachment"
                >
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="validatedCustomFile"
                      @change="(event) => prefiewFiles(event, state)"
                      :required="!is_edit"
                      accept="image/*,application/*"
                    />
                    <label
                      class="custom-file-label"
                      for="validatedCustomFile"
                      v-if="!state.file"
                      >Browse file...</label
                    >
                    <label
                      class="custom-file-label"
                      for="validatedCustomFile"
                      v-else
                      >{{ state.file_label }}</label
                    >
                  </div>
                  <small
                      class="text-primary text-right"
                      style="cursor:pointer"
                      v-if="state.file_url"
                      @click="handleOpenFilePreview(state.file_url)">Open File
                  </small>
                    <br />
                  <small class="text-muted">
                    File Type * :
                    <b-badge variant="info"
                      >.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs |
                      .pdf</b-badge
                    >
                    <br />
                    File CapaKota * :
                    <b-badge variant="info">10 MB / File</b-badge>
                  </small>
                </b-form-group>
              </b-row>
              <div
                v-if="user_edit.certificate_of_proficiency.length > 1"
                class="d-flex w-100 px-2 my-1 border-bottom"
              ></div>
            </div>
            <b-row>
              <b-col md="12">
                <b-button
                  variant="success"
                  class="mt-1"
                  @click="addCertificateOfProfiencyForm"
                >
                  <i class="fa fa-plus"></i> ADD FORM
                </b-button>
              </b-col>
            </b-row>
          </fieldset>
          <div v-else>
            <b-row>
              <b-form-group label="Nama Sertifikat" class="col-md-6">
                <b-form-select
                  plain
                  v-model="update_certificate_of_proficiency.certificate_list_id"
                  required
                  size="sm"
                  :options="cop_options"
                  name="document_list_id"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="''"
                      >Select Certificate</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>
              <b-form-group
                class="col-md-6"
                label="Nomor Dokumen"
                label-for="nomor_dokumen"
              >
                <b-form-input
                  id="nomor_dokumen"
                  name="nomor_dokumen"
                  placeholder="Type Number ..."
                  v-model="update_certificate_of_proficiency.document_number"
                  required
                ></b-form-input>
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group
                class="col-md-6"
                label="Date Issued"
                label-for="date_issued"
              >
                <b-form-input
                  type="date"
                  id="date_issued"
                  v-model="update_certificate_of_proficiency.date_issued"
                  required
                ></b-form-input>
                <small style="float: right"
                  >click <i class="fa fa-calendar"></i> to show
                </small>
              </b-form-group>
              <b-form-group
                class="col-md-6"
                label="Masa Berlaku"
                label-for="masa_berlaku"
              >
                <b-form-input
                  type="date"
                  id="masa_berlaku"
                  v-model="update_certificate_of_proficiency.validity_period"
                
                ></b-form-input>
                <small style="float: right"
                  >click <i class="fa fa-calendar"></i> to show
                </small>
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group
                class="col-md-6"
                label="Place Issued"
                label-for="place_issued"
              >
                <b-form-input
                  id="place_issued"
                  name="place_issued"
                  placeholder="Type Text ..."
                  v-model="update_certificate_of_proficiency.place_issued"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                class="col-md-6"
                label="Attachment"
                label-for="attachment"
              >
                <div class="custom-file">
                  <input
                    type="file"
                    class="custom-file-input"
                    id="validatedCustomFile"
                    @change="(event) => prefiewFiles(event, update_certificate_of_proficiency)"
                    :required="!is_edit"
                  />
                  <label
                    class="custom-file-label"
                    for="validatedCustomFile"
                    v-if="!update_certificate_of_proficiency.file"
                    >Browse file...</label
                  >
                  <label
                    class="custom-file-label"
                    for="validatedCustomFile"
                    v-else
                    >{{ update_certificate_of_proficiency.file_label }}</label
                  >
                </div>
                  <br />
                <small class="text-muted">
                  File Type * :
                  <b-badge variant="info"
                    >.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs |
                    .pdf</b-badge
                  >
                  <br />
                  File CapaKota * :
                  <b-badge variant="info">10 MB / File</b-badge>
                </small>
              </b-form-group>
            </b-row>
          </div>
          <b-button
            :disabled="saving_proficiency"
            block
            variant="primary"
            type="submit"
            class="my-3"
          >
            <span
              v-if="saving_proficiency"
              class="spinner-border spinner-border-sm"
            />
            <span v-else>{{ is_edit ? 'Update' : 'Tambah' }} Data Certificate of Proficiency</span>
          </b-button>
        </form>
      </b-modal>

      <b-modal
        id="pengalaman_berlayar"
        size="lg"
        :title="is_edit ? 'Update ' : 'Tambah ' + 'Data Pengalaman Berlayar'"
        cancel-title="Close"
        hide-footer
      >
        <form action="#" method="post" @submit="onSavePekerjaan">
          <fieldset class="form-group border p-3 mt-1" v-if="!is_edit">
            <legend class="w-auto text-primary" style="font-size: 12pt"
              >PENGALAMAN BERLAYAR</legend
            >
            <div
              v-for="(state, index) in user_edit.pekerjaan"
              v-bind:key="index"
            >
              <div
                v-if="index > 0"
                class="d-flex w-100 px-1 my-1 justify-content-end cursor-pointer"
                @click="deletePekerjaanForm(index)"
              >
                <i class="fa fa-trash text-danger"></i>
              </div>
    
              <b-row>
                <b-form-group
                  class="col-md-4"
                  label="Nama Perusahaan"
                  label-for="perusahaan"
                >
                  <b-form-input
                    id="perusahaan"
                    name="perusahaan"
                    placeholder="Type Text ..."
                    v-model="state.perusahaan"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Lokasi"
                  label-for="lokasi"
                >
                  <b-form-input
                    id="lokasi"
                    name="lokasi"
                    placeholder="Type Text ..."
                    v-model="state.lokasi"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Negara"
                  label-for="negara"
                >
                  <b-form-input
                    id="negara"
                    name="negara"
                    placeholder="Type Text ..."
                    v-model="state.negara"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Tahun Masuk"
                  label-for="tahun_masuk"
                >
                  <b-form-input
                    type="date"
                    id="tahun_masuk"
                    v-model="state.tahun_masuk"
                    required
                  ></b-form-input>
                  <small style="float: right"
                    >click <i class="fa fa-calendar"></i> to show
                  </small>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Tahun Keluar"
                  label-for="tahun_berhenti"
                >
                  <b-form-input
                    type="date"
                    id="tahun_berhenti"
                    v-model="state.tahun_berhenti"
                    required
                  ></b-form-input>
                  <small style="float: right"
                    >click <i class="fa fa-calendar"></i> to show
                  </small>
                </b-form-group>
    
                <b-form-group
                  class="col-md-4"
                  label="Nama Kapal"
                  label-for="nama_kapal"
                >
                  <b-form-input
                    id="nama_kapal"
                    name="nama_kapal"
                    placeholder="Type Text ..."
                    v-model="state.nama_kapal"
                    required
                  ></b-form-input>
                </b-form-group>
    
                <b-form-group
                  class="col-md-4"
                  label="Bendera Kapal"
                  label-for="bendera_kapal"
                >
                  <b-form-input
                    id="bendera_kapal"
                    name="bendera_kapal"
                    placeholder="Type Text ..."
                    v-model="state.bendera_kapal"
                    required
                  ></b-form-input>
                </b-form-group>
    
                <b-form-group
                  class="col-md-4"
                  label="Area Operasi"
                  label-for="area_operasi"
                >
                  <b-form-input
                    id="area_operasi"
                    name="area_operasi"
                    placeholder="Type Text ..."
                    v-model="state.area_operasi"
                    required
                  ></b-form-input>
                </b-form-group>
    
                <b-form-group
                  class="col-md-4"
                  label="Jabatan"
                  label-for="jabatan"
                >
                  <b-form-input
                    id="jabatan"
                    name="jabatan"
                    placeholder="Type Text ..."
                    v-model="state.jabatan"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Gaji"
                  label-for="gaji"
                >
                  <b-form-input
                    type="number"
                    id="gaji"
                    name="gaji"
                    placeholder="Type Number ..."
                    v-model="state.gaji"
                    required
                  ></b-form-input>
                  <b-form-text class="text-muted">
                    {{ formatCurrency(state.gaji) }}
                  </b-form-text>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Fasilitas"
                  label-for="fasilitas"
                >
                  <b-form-input
                    id="fasilitas"
                    name="fasilitas"
                    placeholder="Type Text ..."
                    v-model="state.fasilitas"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Alasan Berhenti"
                  label-for="alasan_berhenti"
                >
                  <b-form-input
                    id="alasan_berhenti"
                    name="alasan_berhenti"
                    placeholder="Type Text ..."
                    v-model="state.alasan_berhenti"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Pemberi Rekomendasi"
                  label-for="pemberi_rekomendasi"
                >
                  <b-form-input
                    id="pemberi_rekomendasi"
                    name="pemberi_rekomendasi"
                    placeholder="Type Text ..."
                    v-model="state.pemberi_rekomendasi"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Kontak Pemberi Rekomendasi"
                  label-for="kontak_pemberi_informasi"
                >
                  <b-form-input
                    id="kontak_pemberi_informasi"
                    name="kontak_pemberi_informasi"
                    placeholder="Type Number ..."
                    v-model="state.kontak_pemberi_informasi"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="No. Tlp Perusaahaan"
                  label-for="no_tlp_perusahaan"
                >
                  <b-form-input
                    id="no_tlp_perusahaan"
                    name="no_tlp_perusahaan"
                    placeholder="Type Number ..."
                    v-model="state.no_tlp_perusahaan"
                    required
                  ></b-form-input>
                </b-form-group>
    
                <b-form-group
                  class="col-md-12"
                  label="Deskripsi Pekerjaan"
                  label-for="deskripsi_pekerjaan"
                >
                  <b-form-textarea
                    id="deskripsi_pekerjaan"
                    name="deskripsi_pekerjaan"
                    placeholder="Type Text ..."
                    v-model="state.deskripsi_pekerjaan"
                    required
                  ></b-form-textarea>
                </b-form-group>
              </b-row>
              <div
                v-if="user_edit.pekerjaan.length > 1"
                class="d-flex w-100 px-2 my-1 border-bottom"
              ></div>
            </div>
            <b-row>
              <b-col md="12">
                <b-button
                  variant="success"
                  class="mt-1"
                  @click="addPekerjaanForm"
                >
                  <i class="fa fa-plus"></i> ADD FORM
                </b-button>
              </b-col>
            </b-row>
          </fieldset>
          <div v-else >
              <b-row>
                <b-form-group
                  class="col-md-4"
                  label="Nama Perusahaan"
                  label-for="perusahaan"
                >
                  <b-form-input
                    id="perusahaan"
                    name="perusahaan"
                    placeholder="Type Text ..."
                    v-model="update_pekerjaan.perusahaan"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Lokasi"
                  label-for="lokasi"
                >
                  <b-form-input
                    id="lokasi"
                    name="lokasi"
                    placeholder="Type Text ..."
                    v-model="update_pekerjaan.lokasi"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Negara"
                  label-for="negara"
                >
                  <b-form-input
                    id="negara"
                    name="negara"
                    placeholder="Type Text ..."
                    v-model="update_pekerjaan.negara"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Tahun Masuk"
                  label-for="tahun_masuk"
                >
                  <b-form-input
                    type="date"
                    id="tahun_masuk"
                    v-model="update_pekerjaan.tahun_masuk"
                    required
                  ></b-form-input>
                  <small style="float: right"
                    >click <i class="fa fa-calendar"></i> to show
                  </small>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Tahun Keluar"
                  label-for="tahun_berhenti"
                >
                  <b-form-input
                    type="date"
                    id="tahun_berhenti"
                    v-model="update_pekerjaan.tahun_berhenti"
                    required
                  ></b-form-input>
                  <small style="float: right"
                    >click <i class="fa fa-calendar"></i> to show
                  </small>
                </b-form-group>
    
                <b-form-group
                  class="col-md-4"
                  label="Nama Kapal"
                  label-for="nama_kapal"
                >
                  <b-form-input
                    id="nama_kapal"
                    name="nama_kapal"
                    placeholder="Type Text ..."
                    v-model="update_pekerjaan.nama_kapal"
                    required
                  ></b-form-input>
                </b-form-group>
    
                <b-form-group
                  class="col-md-4"
                  label="Bendera Kapal"
                  label-for="bendera_kapal"
                >
                  <b-form-input
                    id="bendera_kapal"
                    name="bendera_kapal"
                    placeholder="Type Text ..."
                    v-model="update_pekerjaan.bendera_kapal"
                    required
                  ></b-form-input>
                </b-form-group>
    
                <b-form-group
                  class="col-md-4"
                  label="Area Operasi"
                  label-for="area_operasi"
                >
                  <b-form-input
                    id="area_operasi"
                    name="area_operasi"
                    placeholder="Type Text ..."
                    v-model="update_pekerjaan.area_operasi"
                    required
                  ></b-form-input>
                </b-form-group>
    
                <b-form-group
                  class="col-md-4"
                  label="Jabatan"
                  label-for="jabatan"
                >
                  <b-form-input
                    id="jabatan"
                    name="jabatan"
                    placeholder="Type Text ..."
                    v-model="update_pekerjaan.jabatan"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Gaji"
                  label-for="gaji"
                >
                  <b-form-input
                    type="number"
                    id="gaji"
                    name="gaji"
                    placeholder="Type Number ..."
                    v-model="update_pekerjaan.gaji"
                    required
                  ></b-form-input>
                  <b-form-text class="text-muted">
                    {{ formatCurrency(update_pekerjaan.gaji) }}
                  </b-form-text>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Fasilitas"
                  label-for="fasilitas"
                >
                  <b-form-input
                    id="fasilitas"
                    name="fasilitas"
                    placeholder="Type Text ..."
                    v-model="update_pekerjaan.fasilitas"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Alasan Berhenti"
                  label-for="alasan_berhenti"
                >
                  <b-form-input
                    id="alasan_berhenti"
                    name="alasan_berhenti"
                    placeholder="Type Text ..."
                    v-model="update_pekerjaan.alasan_berhenti"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Pemberi Rekomendasi"
                  label-for="pemberi_rekomendasi"
                >
                  <b-form-input
                    id="pemberi_rekomendasi"
                    name="pemberi_rekomendasi"
                    placeholder="Type Text ..."
                    v-model="update_pekerjaan.pemberi_rekomendasi"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="Kontak Pemberi Rekomendasi"
                  label-for="kontak_pemberi_informasi"
                >
                  <b-form-input
                    id="kontak_pemberi_informasi"
                    name="kontak_pemberi_informasi"
                    placeholder="Type Number ..."
                    v-model="update_pekerjaan.kontak_pemberi_informasi"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-4"
                  label="No. Tlp Perusaahaan"
                  label-for="no_tlp_perusahaan"
                >
                  <b-form-input
                    id="no_tlp_perusahaan"
                    name="no_tlp_perusahaan"
                    placeholder="Type Number ..."
                    v-model="update_pekerjaan.no_tlp_perusahaan"
                    required
                  ></b-form-input>
                </b-form-group>
    
                <b-form-group
                  class="col-md-12"
                  label="Deskripsi Pekerjaan"
                  label-for="deskripsi_pekerjaan"
                >
                  <b-form-textarea
                    id="deskripsi_pekerjaan"
                    name="deskripsi_pekerjaan"
                    placeholder="Type Text ..."
                    v-model="update_pekerjaan.deskripsi_pekerjaan"
                    required
                  ></b-form-textarea>
                </b-form-group>
              </b-row>
          </div>
          <b-button
            :disabled="saving_pekerjaan"
            block
            variant="primary"
            type="submit"
            class="my-3"
          >
            <span
              v-if="saving_pekerjaan"
              class="spinner-border spinner-border-sm"
            />
            <span v-else>{{ is_edit ? 'Update' : "Tambah" }} Data Pekerjaan</span>
          </b-button>
        </form>
      </b-modal>
      
      <b-modal
        id="dokumen_pendukung"
        size="lg"
        :title="is_edit ? 'Update Data Dokumen Pendukung' : 'Tambah Data Dokumen Pendukung'"
        cancel-title="Close"
        hide-footer
      >
        <form action="#" method="post" @submit="onSaveDokumenPenting">
          <fieldset class="form-group border p-3 mt-1" v-if="!is_edit">
            <legend class="w-auto text-primary" style="font-size: 12pt"
              >DOKUMEN PENDUKUNG</legend
            >
            <b-row
              v-for="(state, index) in user_edit.support_dokumen"
              v-bind:key="index"
            >
              <div
                v-if="index > 0"
                class="d-flex w-100 px-1 my-1 justify-content-end cursor-pointer"
                @click="deleteSupportdokumen(index)"
              >
                <i class="fa fa-trash text-danger"></i>
              </div>
              <b-col>
                <b-form-group label="Nama Dokumen" label-for="nama_dokumen">
                  <b-form-select
                    plain
                    v-model="state.document_list_id"
                    :options="master_list_document"
                    id="nama_dokumen"
                    size="sm"
                    required
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Select Type Document</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="File" label-for="file">
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="validatedCustomFile"
                      @change="(event) => prefiewFiles(event, state)"
                      accept="image/x-png,image/jpeg,image/jpg,application/*"
                      :required="!is_edit"
                    />
    
                    <label
                      class="custom-file-label"
                      for="validatedCustomFile"
                      v-if="!state.file"
                      >Browse file...</label
                    ><label
                      class="custom-file-label"
                      for="validatedCustomFile"
                      v-else
                      >{{ state.file_label }}</label
                    >
                  </div>
                  <small
                      class="text-primary text-right"
                      style="cursor:pointer"
                      v-if="state.file_url"
                      @click="handleOpenFilePreview(state.file_url)">Open File</small>
                    <br />
                  <small class="text-muted">
                    File Type * :
                    <b-badge variant="info"
                      >.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs |
                      .pdf</b-badge
                    >
                    <br />
                    File CapaKota * :
                    <b-badge variant="info">10 MB / File</b-badge>
                  </small>
                </b-form-group>
              </b-col>
              <div
                v-if="user_edit.support_dokumen.length > 1"
                class="d-flex w-100 px-2 my-1 border-bottom"
              ></div>
            </b-row>

            <b-row>
              <b-col md="12">
                <b-button
                  variant="success"
                  class="mt-1"
                  @click="addSupportdokumen"
                  ><i class="fa fa-plus"></i> ADD FORM</b-button
                >
              </b-col>
            </b-row>
          </fieldset>

          <b-row v-else>
              <b-col>
                <b-form-group label="Nama Dokumen" label-for="nama_dokumen">
                  <b-form-select
                    plain
                    v-model="update_support_dokumen.document_list_id"
                    :options="master_list_document"
                    id="nama_dokumen"
                    size="sm"
                    required
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Select Type Document</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="File" label-for="file">
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="validatedCustomFile"
                      @change="(event) => prefiewFiles(event, update_support_dokumen)"
                      accept="image/x-png,image/gif,image/jpeg,application/*"
                      :required="!is_edit"
                    />
    
                    <label
                      class="custom-file-label"
                      for="validatedCustomFile"
                      v-if="!update_support_dokumen.file"
                      >Browse file...</label
                    ><label
                      class="custom-file-label"
                      for="validatedCustomFile"
                      v-else
                      >{{ update_support_dokumen.file_label }}</label
                    >
                  </div>
                  <small class="text-muted">
                    File Type * :
                    <b-badge variant="info"
                      >.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs |
                      .pdf</b-badge
                    >
                    <br />
                    File CapaKota * :
                    <b-badge variant="info">10 MB / File</b-badge>
                  </small>
                </b-form-group>
              </b-col>
          </b-row>
    
          <b-button
            :disabled="saving_supportdokumen"
            block
            variant="primary"
            type="submit"
            class="my-3"
          >
            <span
              v-if="saving_supportdokumen"
              class="spinner-border spinner-border-sm"
            />
            <span v-else>{{ is_edit ? 'Update' : 'Tambah' }} Data Support Document</span>
          </b-button>
        </form>
      </b-modal>  
      
      <b-modal
        id="request-signoff"
        size="lg"
        :title="'Request Sign Off'"
        cancel-title="Close"
        hide-footer
      >
        <form action="#" method="post" @submit.prevent="onRequestSignOff">
          <fieldset class="form-group border p-3 mt-1">
            <legend class="w-auto text-primary" style="font-size: 12pt"
              >Form Request Sign Off</legend
            >
            <b-row>
              <b-col md="12">
                <b-form-group label="Status Crew" label-for="status_crew">
                  <b-form-select
                    plain
                    v-model="form_request_sign_off.status_emp_crewing_id"
                    :options="optionsEmployeeStatus"
                    size="sm"
                    required
                    @change="changeEmployeeStatus"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Select Status Crew</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>  
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Notice Off"
                  label-for="notice_off"
                >
                  <b-form-input
                    type="date"
                    id="notice_off"
                    v-model="form_request_sign_off.notice_off"
                  ></b-form-input>
                  <small style="float: right"
                    >click <i class="fa fa-calendar"></i> to show
                  </small>
                </b-form-group>
              </b-col> 
              <b-col md="12">
                <b-form-group
                  label="Sign Off"
                  label-for="sign_off"
                >
                  <b-form-input
                    type="datetime-local"
                    id="sign_off"
                    v-model="form_request_sign_off.sign_off"
                    required
                  ></b-form-input>
                  <small style="float: right"
                    >click <i class="fa fa-calendar"></i> to show
                  </small>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <span class="text-black">Reason Sign Off</span>
                <table class="table table-striped">
                  <thead>
                    <tr class="text-center">
                      <th width="90%">DESKRIPSI</th>
                      <th width="10%">STATUS</th>
                    </tr>
                  </thead>    
                  <tbody>
                    <tr
                      v-for="(state, index) in options_reason_signoff"
                      v-bind:key="index"
                    >
                      <th> {{ state.name }}</th>
                      <td class="text-center">
                        <template>
                          <b-form-checkbox
                            v-model="state.checked"
                            name="check-button"
                            class="ml-4"
                            switch
                            inline
                            :disabled="state.disabled"
                            @change="
                              selectStatusSignOffCrew(state, index, 'request')
                            "
                          >
                          </b-form-checkbox>
                        </template>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
              <b-col md="12" v-if="options_reason_signoff?.[6]?.checked">
                <b-form-group label="Reason Blacklist">
                  <b-form-select
                    plain
                    v-model="form_request_sign_off.reason_blacklist_id"
                    :options="optionBlacklistReasons"
                    size="lg"
                    aria-placeholder="Pilih Alasan"
                    required
                    name="reason_off"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Pilih Alasan</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="12" v-if="options_reason_signoff?.[6]?.checked">
                <b-form-group label="Remarks">
                    <b-form-textarea
                      rows="3"
                      name="remarks"
                      v-model="form_request_sign_off.remarks"
                      placeholder="Notes..."
                    ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
          </fieldset>
          <b-button variant="primary" block type="submit" :disabled="signoff_loading"
            ><i class="fa fa-save" v-if="!signoff_loading"></i>
            <span v-if="!signoff_loading">SUBMIT</span>
            <span v-if="signoff_loading" class="spinner-border spinner-border-sm" />
          </b-button>
        </form>
      </b-modal>

      <b-modal
        id="swap-position"
        size="lg"
        title="Swap"
        cancel-title="Close"
        hide-footer
      >
        <form action="#" method="post" @submit="onSaveSwapPosition">
          <b-row>
            <b-col md="12">
              <b-card no-body class="mb-1">
                <b-card-body>
                  <b-row>
                    <b-col lg="12">
                      <b-form-group label="Vehicles" label-for="vehicle_id">
                        <v-select
                          v-model="swap_position.vehicle_id"
                          label="text"
                          :options="vehicleOptions"
                          :reduce="(vehicleOptions) => vehicleOptions.value"
                          placeholder="Select Type of Vehicle"
                          required
                        ></v-select>
                      </b-form-group>
                    </b-col>

                    <b-col lg="12">
                      <b-form-group
                        label="Join on Board"
                        label-for="join_on_board"
                      >
                        <b-form-input
                          type="datetime-local"
                          id="join_on_board"
                          v-model="swap_position.join_on_board"
                          required
                        ></b-form-input>
                        <small style="float: right"
                          >click <i class="fa fa-calendar"></i> to show
                        </small>
                      </b-form-group>
                    </b-col>
                    <b-col lg="12">
                      <b-form-group
                        label="Sign On"
                        label-for="start_date"
                      >
                        <b-form-input
                          type="datetime-local"
                          id="start_date"
                          v-model="swap_position.start_date"
                        ></b-form-input>
                        <small style="float: right"
                          >click <i class="fa fa-calendar"></i> to show
                        </small>
                      </b-form-group>
                    </b-col>

                    <b-col lg="12">
                      <b-form-group label="Company" label-for="company_id">
                        <b-form-select
                          plain
                          v-model="swap_position.company_id"
                          size="sm"
                          :options="companyOptions"
                          placeholder="Select options..."
                        >
                          <template v-slot:first>
                            <b-form-select-option
                              :value="''"
                            >Select Company</b-form-select-option>
                          </template>
                        </b-form-select> </b-form-group
                    ></b-col>
                    <b-col lg="12">
                      <b-form-group label="Port">
                        <v-select
                          v-model="swap_position.port_id"
                          label="text"
                          :options="portOptions"
                          :reduce="(portOptions) => portOptions.value"
                          placeholder="Select port..."
                          required
                        ></v-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="12">
                      <b-form-group label="Position/Jabatan" label-for="position_id">
                        <v-select
                          v-model="swap_position.position_id"
                          label="text"
                          :options="master_list_position"
                          :reduce="(master_list_position) => master_list_position.value"
                          placeholder="Select Position"
                          required
                        ></v-select>
                      </b-form-group>
                    </b-col>

                    <b-col lg="12">
                      <b-form-group
                        label="Attachment"
                        label-for="attachment"
                      >
                        <div class="custom-file">
                          <input
                            type="file"
                            class="custom-file-input"
                            id="validatedCustomFile"
                            accept="application/*,image/png,image/jpeg,image/jpg"
                            @change="(event) => prefiewFiles(event, swap_position)"
                          />
                          <label
                            class="custom-file-label"
                            for="validatedCustomFile"
                            v-if="!swap_position.file"
                            >Browse file...</label
                          >
                          <label
                            class="custom-file-label"
                            for="validatedCustomFile"
                            v-else
                            >{{ swap_position.file_label }}</label
                          >
                        </div>
                        <small class="text-muted">
                          File Type * :
                          <b-badge variant="info"
                            >.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs |
                            .pdf</b-badge
                          >
                          <br />
                          File CapaKota * :
                          <b-badge variant="info">10 MB / File</b-badge>
                        </small>
                      </b-form-group>
                    </b-col>

                    <b-col lg="12">
                      <b-form-group
                        type="textarea"
                        label="Notes"
                        label-for="remarks"
                      >
                        <b-form-textarea
                          class="form-control"
                          id="remarks"
                          name="remarks"
                          rows="3"
                          v-model="swap_position.remarks"

                        ></b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col :md="'6'">
              <b-button variant="secondary" block @click="hideModal"
                >Close</b-button
              >
            </b-col>
            <b-col md="6">
              <b-button variant="primary" block type="submit" :disabled="saving"
                ><i class="fa fa-edit" v-if="!saving"></i>
                <span v-if="!saving">SUBMIT</span>
                <span v-if="saving" class="spinner-border spinner-border-sm" />
              </b-button>
            </b-col>
          </b-row>
        </form>
      </b-modal>
    </b-container>
  </div>
</template>
<script>

import { xray } from '../../config/pluginInit'
import { crewingActions, vehiclesActions } from '@src/Utils/helper'
import moment from 'moment'
import jsPDF from 'jspdf'
import iqCard from '@/src/components/xray/cards/iq-card.vue'
import html2canvas from 'html2canvas'
import FormCondite from '@/src/components/crewing/FormCondite.vue'
import optionsData from '@src/core/mock/data'
import { LGridLayer } from 'vue2-leaflet'

export default {
  name: 'ProfileUser',
  data() {
    return {
      ...optionsData,
      is_editSwap: false,
      signoff_loading: false,
      dateRange: [
        moment().startOf('month').format('YYYY-MM-DD'),
        moment().endOf('month').format('YYYY-MM-DD'),
      ],
      saving: false,
      dataCondite: null,
      conditeDetail: null,
      showModal: false,
      isLoading: false,
      user: null,
      isEditJoinDate:false,
      default_photo: require('../../assets/images/user/11.png'),
      condite_content: null,
      condite_questions: null,
      notes_by_section: null,
      comments: null,
      selectedMonth: moment(new Date()).format('YYYY-MM'),
      optionsEmployeeStatus:[],
      optionBlacklistReasons: [],
      form_blacklist:{
        reason_off:null,
        remarks:''
      },
      options_status_crew_fleet:[],
      master_list_position:[],
      swap_form_edit:false,
      swap_form: {
        id: null,
        vehicle_id: null,
        position_id: null,
        start_date: null,
        end_date: null,
        join_on_board: null,
        publish: 'Active',
        remarks: '',
        work_area_id: null,
        company_id: null,
        port_id: null,
        letter_of_no: null,
        status_emp_crewing: 1,
        file: null,
        file_label: null,
        swap:false,
        reason_blacklist_id:null
      },
      swap_position: {
        id: null,
        vehicle_id: null,
        position_id: null,
        start_date: null,
        end_date: null,
        join_on_board: null,
        publish: 'Active',
        remarks: '',
        work_area_id: null,
        company_id: null,
        port_id: null,
        letter_of_no: null,
        status_emp_crewing: 1,
        file: null,
        file_label: null,
        swap:true
      },
      swap_position_id:null,
      swap_position_nip:null,

      // CONDUCT
      optionsPoints: [],
      conduct_aspects: [],
      conduct_points: [],
      merged_aspects: [],
      detailConduct:{},

      // OPTIONS
      vehicleOptions: [],
      companyOptions: [],
      workareaOptions: [],
      portOptions: [],

      optionsVehicles: [],
      optionsPositions: [],
      master_list_document: [],
      master_list_document_system: [],

      travel_document_options: [],
      coc_options: [],
      cop_options: [],

      is_edit:false,

      saving_keluarga: false,
      saving_traveldocument: false,
      saving_competency: false,
      saving_proficiency: false,
      saving_pekerjaan: false,
      saving_supportdokumen: false,

      user_edit: {
        keluarga: [
          {
            status: '',
            nama_lengkap: '',
            tempat_lahir: '',
            tanggal_lahir: '',
            jenis_kelamin: '',
            pekerjaan: '',
            alamat: '',
            no_hp: '',
            age_family: 0,
          },
        ],
        travel_document: [
          {
            document_list_id: '',
            document_number: '',
            place_issued: '',
            date_issued: '',
            validity_period: '',
            file: null,
            file_label: '',
          },
        ],
        certificate_of_competency: [
          {
            certificate_list_id: null,
            sub_education: "none",
            document_number: '',
            place_issued: '',
            date_issued: null,
            validity_period: null,
            file: null,
            file_label: '',
          },
        ],
        certificate_of_proficiency: [
          {
            certificate_list_id: "",
						document_number: "",
						place_issued: "",
						date_issued: null,
						validity_period: null,
						file: null,
            file_label: '',
          },
        ],
        pekerjaan: [
          {
            jabatan: '',
            nama_kapal: '',
            no_tlp_perusahaan: null,
            perusahaan: '',
            lokasi: '',
            negara: '',
            bendera_kapal: '',
            area_operasi: '',
            tahun_masuk: '',
            tahun_berhenti: '',
            gaji: null,
            alasan_berhenti: '',
            fasilitas: '',
            pemberi_rekomendasi: '',
            kontak_pemberi_informasi: '',
            deskripsi_pekerjaan: '',
          },
        ],
        support_dokumen: [
          {
            document_list_id: '',
            file: null,
            file_label: '',
          },
        ],
      },
      
      update_keluarga :{
        status: '',
        nama_lengkap: '',
        tempat_lahir: '',
        tanggal_lahir: '',
        jenis_kelamin: '',
        pekerjaan: '',
        alamat: '',
        no_hp: '',
        age_family: 0,
      },
      update_travel_document: {
        document_list_id: '',
        document_number: '',
        place_issued: '',
        date_issued: '',
        validity_period: '',
        file: null,
        file_label: '',
      },
      update_certificate_of_competency: {
        certificate_list_id: '',
        sub_education: "none",
        document_number: '',
        place_issued: '',
        date_issued: null,
        validity_period: null,
        file: null,
        file_label: '',
      },
      update_certificate_of_proficiency: {
        certificate_list_id: null,
        document_number: "",
        place_issued: "",
        date_issued: null,
        validity_period: null,
        file: null,
        file_label: '',
      },
      update_pekerjaan: {
          jabatan: '',
          nama_kapal: '',
          no_tlp_perusahaan: null,
          perusahaan: '',
          lokasi: '',
          negara: '',
          bendera_kapal: '',
          area_operasi: '',
          tahun_masuk: '',
          tahun_berhenti: '',
          gaji: null,
          alasan_berhenti: '',
          fasilitas: '',
          pemberi_rekomendasi: '',
          kontak_pemberi_informasi: '',
          deskripsi_pekerjaan: '',
      },
      update_support_dokumen: {
          document_list_id: '',
          file: null,
          file_label: '',
      },
      options_reason_signoff:[],
      form_request_sign_off:{
        notice_off:null,
        sign_off:null,
        reason_signoff_id:[],
        status_emp_crewing_id:null,
        is_blacklist:'',
        reason_blacklist_id:null,
      },
      formJoinDate: {
        sign_on: null,
        join_on_board:null,
        sign_off:null
      }
    }
  },
  components: { iqCard, FormCondite },
  async mounted() {
    xray.index()
    this.fetchDocumentList()
    await this.fetchTravelDocumentList()
    await this.getCertificateCompetencyList()
    await this.getCertificateProficiencyList()
    await this.getPositionsList()
    await this.fetchKapal()
    await this.fetchListReasonSignOff()
    await this.getBlacklistReasons()
    await this.getEmployeeStatus()
    await this.getListPosition()

    if (this.$route.query.user === 'crew') {
      await this.getProfileCrew()
      await this.getConditeModel()
    } else if (this.$route.query.user === 'candidate') {
      await this.fetchProfileCandidate()
    }
    await this.getVehicles()
    await this.getCompany()
    await this.getWorkArea()
    await this.getPorts()
  },
  computed: {
    countAge() {
      let userAge = moment().diff(
        new Date(this?.user?.personal_data?.date_of_birth),
        'years'
      )
      return userAge
    },
    filteredCondite() {
      if (!this.selectedMonth) {
        return this.charts
      }

      const [selectedYear, selectedMonth] = this.selectedMonth.split('-')

      return this.charts.filter((item) => {
        const [itemYear, itemMonth] = item.date_of_condite.split('-')
        return itemYear === selectedYear && itemMonth === selectedMonth
      })
    },
  },
  methods: {
    ...crewingActions,
    isUnlimited(id, isKompetensi){
      if(isKompetensi) {
        let unlimitedCertif = this.coc_options.find(obj => obj.id === id)

        return unlimitedCertif?.unlimited
      } else {
        let unlimitedCertif = this.cop_options.find(obj => obj.id === id)

        return unlimitedCertif?.unlimited
      }
    },
    changeEmployeeStatus: function () {
      if(Number(this.form_request_sign_off.status_emp_crewing_id) === 4){
        this.options_reason_signoff.forEach((val, i) => {
          val.disabled = true
          val.checked = false
        })

        this.options_reason_signoff[6].disabled = false
        this.options_reason_signoff[6].checked = true
      } else {
        this.options_reason_signoff.forEach((val, i) => {
          val.disabled = false
          val.checked = false
        })
      }
    },

    selectStatusSignOffCrew: function (state, index) {
      if (state.id === 7 && state?.checked) {
        this.options_reason_signoff.forEach((val, i) => {
          if (state.id !== val.id) {
            val.disabled = true
            val.checked = false
          }
          this.form_request_sign_off.status_emp_crewing_id = 3
        })
      } else if (state.id === 7 && !state?.checked) {
        this.options_reason_signoff.forEach((val, i) => {
          if (state.id !== val.id) {
            val.disabled = false
            val.checked = false
          }
          this.form_request_sign_off.status_emp_crewing_id = 4
          this.form_request_sign_off.reason_blacklist_id = null
        })
      }
    },
    handleOpenFilePreview: function (url) {
      window.open(url, "_blank");
    },
    onChangeDateRange() {
      this.swap_form.start_date = this.dateRange
        ? `${moment(this.dateRange[0]).format('YYYY-MM-DD')}`
        : null
      this.swap_form.end_date = this.dateRange
        ? `${moment(this.dateRange[1]).format('YYYY-MM-DD')}`
        : null
    },
    prefiewFiles: function (event, state) {
      const input = event.target
      if (input.files && input.files[0]) {
        state.file_label = input.files[0].name
        state.file = input.files[0]
      }
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      }).format(value)
    },

    printPDF(fullname) {
      this.isLoading = true
      const pdf = new jsPDF()
      const element = document.getElementById('my-page')

       html2canvas(element, {
        ignoreElements: (element) => element.classList.contains('pdf-exclude'),
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png')
        pdf.addImage(
          imgData,
          'PNG',
          0,
          0,
          pdf.internal.pageSize.getWidth(),
          pdf.internal.pageSize.getHeight()
        )
        pdf.save(
          `Profile ${
            this.$route.query.user === 'candidate' ? 'Candidate' : 'Crew'
          } - ${fullname}.pdf`
        )
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
      })
    },
    modalCondite(data, index) {
      this.dataCondite = data.condite_content

      this.conditeDetail = data

      this.showModal = true
    },

    openModalEdit(data) {    
      this.swap_form_edit = true
      this.swap_form.id = data.id
      this.swap_form.vehicle_id = Number(data.vehicle_id)
      this.swap_form.position_id = Number(data.position_id)
      this.swap_form.start_date = data?.start_date ? moment(data?.start_date).format('YYYY-MM-DD HH:mm') : null
      this.swap_form.end_date = data?.end_date ? moment(data?.end_date).format('YYYY-MM-DD HH:mm') : null
      this.swap_form.join_on_board = data?.join_on_board ? moment(data?.join_on_board).format('YYYY-MM-DD HH:mm') : null
      this.swap_form.reason_blacklist_id = Number(data.reason_blacklist_id)
      this.swap_form.publish = data.publish
      this.swap_form.remarks = data.remarks
      this.swap_form.work_area_id = Number(data.work_area_id)
      this.swap_form.company_id = Number(data.company_id)
      this.swap_form.port_id = data.port_id
      this.swap_form.status_emp_crewing = Number(data.status_emp_crewing_id)
      this.swap_form.swap = Boolean(0)

      this.$bvModal.show('swap-vehicle')
    },

    editKeluarga(data) {
      this.is_edit = true
      this.update_keluarga = {
        id:data.id,
        status: data.status,
        nama_lengkap: data.nama_lengkap,
        tempat_lahir: data.tempat_lahir,
        tanggal_lahir: data.tanggal_lahir,
        jenis_kelamin: data.jenis_kelamin,
        pekerjaan: data.pekerjaan,
        alamat: data.alamat,
        no_hp: data.no_hp,
        age_family: data.age_family,
      }
      this.$bvModal.show('keluarga')
    },
    editTravelDokumen(data) {
      this.is_edit = true
      this.update_travel_document = {
        id:data.id,
        document_list_id: data?.document_list_id,
        document_number: data.document_number,
        place_issued: data.place_issued,
        date_issued: data.date_issued,
        validity_period: data.validity_period,
        file: null,
        file_label: '',
      },
      this.$bvModal.show('dokumen_perjalanan')
    },
    editCompetency(data) {
      this.is_edit = true
      this.update_certificate_of_competency = {
        id:data.id,
        certificate_list_id: Number(data.certificate_list_id),
        sub_education: data?.sub_education,
        document_number: data.document_number,
        place_issued: data.place_issued,
        date_issued: data.date_issued,
        validity_period: data.validity_period,
        file: null,
        file_label: '',
      },
      this.$bvModal.show('kompetensi')
    },
    editProficiency(data) {
      this.is_edit = true
      this.update_certificate_of_proficiency = {
        id:data.id,
        certificate_list_id: data.certificate_list_id,
        document_number: data.document_number,
        place_issued: data.place_issued,
        date_issued: data.date_issued,
        validity_period: data.validity_period,
        file: null,
        file_label: '',
      },
      this.$bvModal.show('profesi')
    },
    editPekerjaan(data) {
      this.is_edit = true
      this.update_pekerjaan = {
          id:data.id,
          jabatan: data.jabatan,
          nama_kapal: data.nama_kapal,
          no_tlp_perusahaan: data.no_tlp_perusahaan,
          perusahaan: data.perusahaan,
          lokasi: data.lokasi,
          negara: data.negara,
          bendera_kapal: data.bendera_kapal,
          area_operasi: data.area_operasi,
          tahun_masuk: data.tahun_masuk,
          tahun_berhenti: data.tahun_berhenti,
          gaji: data.gaji,
          alasan_berhenti: data.alasan_berhenti,
          fasilitas: data.fasilitas,
          pemberi_rekomendasi: data.pemberi_rekomendasi,
          kontak_pemberi_informasi: data.kontak_pemberi_informasi,
          deskripsi_pekerjaan: data.deskripsi_pekerjaan,
      },
      this.$bvModal.show('pengalaman_berlayar')
    }, 
    editDokumenPendukung(data) {
      this.is_edit = true
      this.update_support_dokumen = {
          id:data.id,
          document_list_id: data.document_list_id,
          file: null,
          file_label: '',
      },
      this.$bvModal.show('dokumen_pendukung')
    },

    async hideModal() {
      Reflect.deleteProperty(this.swap_form, 'id')
      Reflect.deleteProperty(this.swap_form, 'nip')

      this.resetFormSwapHistory()
      this.options_reason_signoff = this.options_reason_signoff.map(val => {
        return {
          ...val,
          disabled: false,
          checked: false,
        }
      })
      this.$bvModal.hide('swap-position')
      this.$bvModal.hide('swap-vehicle')
    },
    openModalSwapPosition(isSwapPosition){
      if (this.user.history_position_swap.length === 0) {
        return null;
      }

      // Clone the array before sorting
      const sortedItems = [...this.user.history_position_swap];
      // Sort the array by date in descending order
      sortedItems.sort((a, b) => new Date(b.join_on_board) - new Date(a.join_on_board));
      // Return the first item in the sorted array
      let newestObject = sortedItems[0];

     // Get the current date
      const signOffDate = new Date(newestObject?.end_date); 
      const joinOnBoardDate = new Date(newestObject?.join_on_board); 
      
      // Subtract one day (24 hours)
      const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
      let nextDate = null

      if(newestObject?.end_date){
        nextDate = new Date(signOffDate.getTime() + oneDayInMilliseconds);
      } else if (newestObject?.join_on_board){
        nextDate = new Date(joinOnBoardDate.getTime() + oneDayInMilliseconds);
      } else {
        nextDate = null
      }
      nextDate = nextDate ? moment(nextDate).format('YYYY-MM-DD HH:mm:ss') : null

      if(isSwapPosition){
        this.swap_position.vehicle_id = Number(newestObject.vehicle_id)
        this.swap_position.position_id = Number(this.user.personal_data.position_id)
        this.swap_position.start_date = nextDate ? nextDate : null
        this.swap_position.join_on_board = nextDate ? nextDate : null
        this.swap_position.company_id = Number(newestObject.company_id)
        this.swap_position.port_id = newestObject.port_id
        this.swap_position.letter_of_no = newestObject?.letter_of_no ?? null

        this.swap_position_id = newestObject.id
        this.swap_position_nip = newestObject.nip
      } else {
        this.swap_form.vehicle_id = Number(newestObject.vehicle_id)
        this.swap_form.position_id = Number(this.user.personal_data.position_id)
        this.swap_form.start_date = nextDate ? nextDate : null
        this.swap_form.join_on_board = nextDate ? nextDate : null
        this.swap_form.work_area_id = Number(newestObject.work_area_id)
        this.swap_form.company_id = Number(newestObject.company_id)
        this.swap_form.port_id = newestObject.port_id
        this.swap_form.letter_of_no = newestObject.letter_of_no
      }
    },
    async onDeleteFleetHistory (id){
      this.$swal({
        title: 'Hapus Fleet History?',
        text: "Ya, Saya yakin.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let method = 'deleteFleetHistory'
          const { status } = await this[method]({id:id})

          if(status === false || status === 400 || status === 500 || status === 'error' || status === 404){
            this.$swal(`Oops!`, `Gagal Delete Fleet History.`, 'error')
            return 
          }

          if(this.$route.query.user === 'crew'){
            await this.getProfileCrew()
          } else {
            await this.fetchProfileCandidate()
          }

          this.$swal(`Success!`, `Berhasil Delete Fleet History.`, 'success')
        }
      }) 

    },
    async getBlacklistReasons() {
      let res = await this.fetchBlacklistReasons()
      if (res.status) {
        if (res.data.data.length > 0) {
          res.data.data.map((state) => {
            this.optionBlacklistReasons.push({
              value: state.id,
              text: state.name,
            })
          })
        }
      }
    },
    async modalConduct(state, index){
      let res = await this.fetchConductModel()
      if (res.status) {

        res.data.points.map((val) => {
          this.optionsPoints.push({
            id:val.id,
            value: val.value,
            text: `${val.value_letter} / ${val.name}`,
          })
        })

        this.conduct_aspects = res.data.aspects
        this.conduct_points = res.data.points.sort((a, b) => a.value - b.value)

        this.conduct_aspects.map((val) => {
          this.merged_aspects.push({
            condite_aspect_id: val.id,
            content: val.content,
            value: null,
            remarks: '',
          })
        })

        if(state.evaluation_condite_content.length > 0){
          this.merged_aspects.map(val => {
            state.evaluation_condite_content.map(obj => {
              if(obj.condite_aspect_id === val.condite_aspect_id){
                let value_point = this.optionsPoints.find(point => point.id === obj.point_value_id)
                val.value = value_point.value
                val.remarks = obj.remark_by_user
              }
            })
            return val
          })
        }
        this.detailConduct.approved_by = state.approved_by
        this.detailConduct.checked_by = state.checked_by
        this.detailConduct.rated_by = state.rated_by
        this.detailConduct.verified_by = state.verified_by

        this.detailConduct.recommendation1 = state.recommendation1
        this.detailConduct.recommendation2 = state.recommendation2
        this.detailConduct.recommendation3 = state.recommendation3
        this.detailConduct.recommendation4 = state.recommendation4
      }

      this.showModal = true
    },

    async fetchKapal() {
      let params = {
        page: 1,
        active: true,
        perPage: 999,
        showAll:true
      }
      let res = await this.fetchVehicles(params)
      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.map((state) => {
            this.optionsVehicles.push({
              value: state.id,
              text: state.name,
            })
          })
        }
      }
    },
    async fetchListReasonSignOff() {
      let res = await this.masterSignOffReasons()
      if (res.status) {
        if (res?.data?.data && res?.data?.data?.length > 0) {
          let tempArr = []
          res.data?.data?.map((state) => {
            tempArr.push({
              ...state,
              disabled: false,
              checked: false,
            })
          })
          this.options_reason_signoff = tempArr
        }
      }
    },
    calculateAge(state) {
      const today = new Date();
      const birthdate = new Date(state.tanggal_lahir);
      let age = today.getFullYear() - birthdate.getFullYear();
      const monthDiff = today.getMonth() - birthdate.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthdate.getDate())) {
        age--;
      }
      state.age_family = age;
    },
    async getListPosition() {
      const { status, data } = await this.fetchPositionList()

      data?.map((val) => {
        this.master_list_position.push({
          value: val.id_position,
          text: val.name_position,
        })
      })

      this.list_position = data
    },

    async fetchDocumentList() {
      const { status, data } = await this.masterDocumentList()

      if (status !== 'success') {
        this.$swal(`Oops!`, `User candidate tidak ada.`, 'error')
        return
      }
    
      data?.data?.map((val) => {
        if (val.type === 'manual') {
          this.master_list_document.push({
            value: val.id,
            text: val.name,
          })
        }
        if (val.type === 'system') {
          this.master_list_document_system.push(val)
        }
      })
    },  
    
    async getEmployeeStatus() {
      const { status, data } = await this.fetchEmployeeStatus()

      if (status !== 'success') {
        this.$swal(`Oops!`, `User candidate tidak ada.`, 'error')
        return
      }
    
      let tempArr = []
      let tempArrFleet = []
      data?.data?.map((val) => {
        tempArr.push({
          id:val.id,
          alias:val.alias,
          text:val.name,
          value:val.id
        })
      })
      tempArrFleet = tempArr
      // .filter(obj => obj.id !== 5)
      tempArr = tempArr.filter(obj => obj.id !== 5 && obj.id !== 1)
      
      this.options_status_crew_fleet = tempArrFleet
      this.optionsEmployeeStatus = tempArr
    }, 
    
    async fetchTravelDocumentList() {
      let params = {
        type: "manual",
				is_travel: 1,
      }

      const { status, data } = await this.masterDocumentList(params)

      data?.data?.map((val) => {
        this.travel_document_options.push({
          value: val.id,
          text: val.name,
        })
      })
    }, 
    
    async getCertificateCompetencyList() {
      let params = {
				type: "competency",
			};

      const { status, data } = await this.fetchCertificateList(params)

      data?.data?.map((val) => {
        this.coc_options.push({
          ...val,
          value: val.id,
          text: val.name,
        })
      })
    }, 
    
    async getPositionsList() {
      const { status, data } = await this.fetchPositionList()

      data?.map((val) => {
        this.optionsPositions.push({
          value: Number(val.id_position),
          text: val.name_position,
        })
      })
    }, 
    
    async getCertificateProficiencyList() {
      let params = {
				type: "proficiency",
			};

      const { status, data } = await this.fetchCertificateList(params)

      data?.data?.map((val) => {
        this.cop_options.push({
          ...val,
          value: val.id,
          text: val.name,
        })
      })
    },


    async getVehicles() {
      let method = 'fetchVehicles'

      let params = {
        page: 1,
        active: true,
        perPage: 300,
      }

      const { status, data } = await this[method](params)

      data.map((val) => {
        this.vehicleOptions.push({
          value: val.id,
          text: val.name,
        })
      })
    },
    addPekerjaanForm() {
      this.user_edit.pekerjaan.push({
        jabatan: '',
        nama_kapal: '',
        no_tlp_perusahaan: null,
        perusahaan: '',
        lokasi: '',
        negara: '',
        bendera_kapal: '',
        area_operasi: '',
        tahun_masuk: '',
        tahun_berhenti: '',
        gaji: null,
        alasan_berhenti: '',
        fasilitas: '',
        pemberi_rekomendasi: '',
        kontak_pemberi_informasi: '',
        deskripsi_pekerjaan: '',
      })
    },
    deletePekerjaanForm(index) {
      this.user_edit.pekerjaan.splice(index, 1)
    },
    // ----------------------------------
    // Pendidikan Adding and Removing Forms
    addFamilyForm() {
      this.user_edit.keluarga.push({
        status: '',
        nama_lengkap: '',
        tempat_lahir: '',
        tanggal_lahir: '',
        jenis_kelamin: '',
        pekerjaan: '',
        alamat: '',
        no_hp: '',
        age_family: 0,
      })
    },
    deleteFamilyForm(index) {
      this.user_edit.keluarga.splice(index, 1)
    },
    // ----------------------------------
    // Pendidikan Adding and Removing Forms
    addTravelDocumentForm() {
      this.user_edit.travel_document.push({
        document_name: '',
        document_number: '',
        place_issued: '',
        date_issued: '',
        validity_period: '',
        file: null,
        file_label: '',
        category_document: null,
      })
    },
    deleteTravelDocument(index) {
      this.user_edit.travel_document.splice(index, 1)
    },
    // ----------------------------------
    // CertificateCompetency Adding and Removing Forms
    addCertificateOfCompetencyForm() {
      this.user_edit.certificate_of_competency.push({
        certificate_list_id: '',
        sub_education: "none",
        document_number: '',
        place_issued: '',
        date_issued: null,
        validity_period: null,
        file: null,
        file_label: '',
      })
    },
    deleteCertificateOfCompetencyForm(index) {
      this.user_edit.certificate_of_competency.splice(index, 1)
    },
    // ----------------------------------
    // CertificateCompetency Adding and Removing Forms
    addCertificateOfProfiencyForm() {
      this.user_edit.certificate_of_proficiency.push({
        certificate_list_id: null,
        document_number: '',
        place_issued: '',
        date_issued: '',
        validity_period: '',
        file: null,
        file_label: '',
      })
    },
    deleteCertificateOfProfiencyForm(index) {
      this.user_edit.certificate_of_proficiency.splice(index, 1)
    },
    // ----------------------------------
    addSupportdokumen() {
      this.user_edit.support_dokumen.push({
        document_list_id: '',
        file: null,
        file_label: '',
      })
    },
    deleteSupportdokumen(index) {
      this.user_edit.support_dokumen.splice(index, 1)
    },
    resetFormSwapHistory(){
      this.swap_form = {
        id: null,
        vehicle_id: null,
        start_date: null,
        end_date: null,
        join_on_board: null,
        publish: 'Active',
        remarks: '',
        work_area_id: null,
        company_id: null,
        port_id: null,
        letter_of_no: null,
        status_emp_crewing: 1,
        file: null,
        file_label: null,
        swap:false,
        reason_blacklist_id:null
      }

      this.swap_position = {
        id: null,
        vehicle_id: null,
        start_date: null,
        end_date: null,
        join_on_board: null,
        publish: 'Active',
        remarks: '',
        work_area_id: null,
        company_id: null,
        port_id: null,
        letter_of_no: null,
        status_emp_crewing: 1,
        file: null,
        file_label: null,
        swap:false
      }
    },
    async getCompany() {
      let method = 'fetchCompanies'

      const { status, data } = await this[method]()
      data.map((val) => {
        this.companyOptions.push({
          value: val.id,
          text: val.company,
        })
      })
    },

    async onRequestSignOff(){
      this.signoff_loading = true
      let method = 'setRequestSignOff'

      let tempArr = []
      this.options_reason_signoff.map((val) => {
        if (val.checked) {
          tempArr.push(val.id)
        }
      })

      let payload = {
        id:this.$route.query.id,
        data: {
          ...this.form_request_sign_off,
          reason_signoff_id: tempArr,
          is_blacklist: Number(this.form_request_sign_off.status_emp_crewing_id) === 4 ? 'yes' : 'no',
          sign_off: moment(this.form_request_sign_off.sign_off).format('YYYY-MM-DD'),
          sign_off_time: moment(this.form_request_sign_off.sign_off).format('HH:mm:ss')
        }
      }

      if(!payload?.data?.reason_blacklist_id) delete payload.data.reason_blacklist_id
      if(!payload?.data?.remarks) delete payload.data.remarks

      const { status, data } = await this[method](payload)

      if (status !== 'success') {
          this.$swal(`Oops!`, `Request Sign Off Gagal.`, 'error')
          this.signoff_loading = false
          return
      }

      this.$swal(
        `Berhasil!`, 
        payload?.data?.reason_blacklist_id ? 
        `Blacklist dan Sign Off Crew berhasil.` : 
        `Request Sign Off Berhasil.`, 
        'success'
      )
      this.signoff_loading = false
      this.$bvModal.hide('request-signoff')
      this.form_request_sign_off = {
        notice_off:null,
        sign_off:null,
        reason_signoff_id:[],
        reason_blacklist_id:null,
      }
    },

    async getWorkArea() {
      let method = 'fetchWorkArea'

      const { status, data } = await this[method]()

      data?.data?.map((val) => {
        this.workareaOptions.push({
          value: val.id,
          text: val.name,
        })
      })
    },
    async getPorts() {
      let method = 'fetchPorts'

      let params = {
        page: 1,
        perPage: 10000,
      }

      const { status, data } = await this[method](params)

      data?.map((val) => {
        this.portOptions.push({
          value: `${val.id}-${val.portName}`,
          text: `${val.portName}`,
        })
      })
    },

    async fetchProfileCandidate() {
      if (this.$route.query.id) {
        let method = 'getProfileCandidate'

        const { status, data } = await this[method](this.$route.query.id)

        if (status !== 'success') {
          this.$swal(`Oops!`, `User candidate tidak ada.`, 'error')
          return
        }

        this.user = data

        this.notes_by_section = data?.interview?.[0]?.notes_by_section
          ? JSON.parse(data?.interview?.[0]?.notes_by_section)
          : ''
        this.comments = data?.interview?.[0]?.comments
          ? JSON.parse(data?.interview?.[0]?.comments)
          : ''
      }
    },
    async getProfileCrew() {
      if (this.$route.query.id) {
        let method = 'getCrewDetail'

        const { status, data } = await this[method](this.$route.query.id)

        if (status !== 'success') {
          this.$swal(`Oops!`, `Crew tidak ada.`, 'error')
          return
        }
        let tempArr = []
        this.user = data?.[0]

        data?.[0]?.condite?.map((val) => {
          let newArr = val.condite_content.map((item) => item.point_value)

          tempArr.push({
            date_of_condite: val.date_of_condite,
            data: newArr,
          })
        })

        this.notes_by_section = data?.[0]?.interview?.[0]?.notes_by_section
          ? JSON.parse(data?.[0].interview?.[0]?.notes_by_section)
          : ''
        this.comments = data?.[0]?.interview?.[0]?.comments
          ? JSON.parse(data?.[0]?.interview?.[0]?.comments)
          : ''

        let history_position_swap = data?.[0]?.history_position_swap
        history_position_swap = history_position_swap.sort((a, b) => b.id - a.id);

        this.user.history_position_swap = history_position_swap

        this.condite_content = tempArr

        this.formJoinDate = {
          sign_on: this.user?.sign_on ? moment(new Date(this.user?.sign_on)).format('YYYY-MM-DD HH:mm') : null,
          join_on_board:this.user?.join_on_board ? moment(new Date(this.user?.join_on_board)).format('YYYY-MM-DD HH:mm') : null,
          sign_off:this.user?.sign_off ? moment(new Date(this.user?.sign_off)).format('YYYY-MM-DD HH:mm') : null
        }
      }
    },
    async getConditeModel() {
      let res = await this.fetchConditeModel()
      if (res.status === true) {
        this.condite_questions = res?.data?.questions.map((val) => val.uraian)
      }
    },
    async onSaveSwapVehicle(e) {
      e.preventDefault()
      this.saving = true

      let method = 'setSwapPosition'

      const formData = new FormData()

      formData.append('vehicle_id', this.swap_form.vehicle_id)
      formData.append('position_id', this.swap_form.position_id)
      formData.append('start_date', moment(new Date(this.swap_form.start_date)).format('YYYY-MM-DD HH:mm:ss'))
      if(this.swap_form.end_date){
        formData.append('end_date', moment(new Date(this.swap_form.end_date)).format('YYYY-MM-DD HH:mm:ss'))
        formData.append('end_date_time', moment(new Date(this.swap_form.end_date)).format('HH:mm:ss'))
      }
      formData.append('join_on_board', moment(new Date(this.swap_form.join_on_board)).format('YYYY-MM-DD HH:mm:ss'))
      formData.append('join_on_board_time', moment(new Date(this.swap_form.join_on_board)).format('HH:mm:ss'))
      formData.append('publish', 'Active')
      formData.append('remarks', this.swap_form.remarks)
      formData.append('work_area_id', this.swap_form.work_area_id)
      formData.append('company_id', this.swap_form.company_id)
      formData.append('port_id', this.swap_form.port_id)
      formData.append('letter_of_no', null)
      if(this.swap_form.file){
        formData.append('attachment', this.swap_form.file)
      }
      formData.append('swap', this.swap_form.swap ? 1 : 0)
      
      formData.append('status_emp_crewing_id',!this.swap_form.swap ? this.swap_form.status_emp_crewing : 1)
      formData.append('is_blacklist', this.swap_form.status_emp_crewing === 4 ? 'yes' : 'no')

      if(this.swap_form.status_emp_crewing !== 1 && this.swap_form.status_emp_crewing !== 5){
        let tempArr = []
        this.options_reason_signoff.map((val) => {
          if (val.checked) {
            tempArr.push(val.id)
          }
        })

        formData.append('reason_signoff_id', JSON.stringify(tempArr))
      }

      if(this.options_reason_signoff[6].checked) formData.append('reason_blacklist_id', this.swap_form.reason_blacklist_id)

      if (this.swap_form.id) {
        formData.append('id', this.swap_form.id)
        formData.append('nip', this.user.nip)
      }

      const res = await this[method]({
        emp_crewing_id: this.user.id,
        formData,
      })

      if (res.status === 'success') {
        this.saving = false
        this.resetFormSwapHistory()
        await this.getProfileCrew()
        await this.fetchListReasonSignOff()
        this.$bvModal.hide('swap-vehicle')
        this.$swal(`Berhasil!`, `Berhasil Swap Position.`, 'success')
       
      } else {
        this.saving = false
        this.$swal(`Oops!`, res?.data, 'error')
      }

      this.saving = false
    },

    async onSaveSwapPosition(e) {
      e.preventDefault()
      this.saving = true

      let method = 'setSwapPosition'

      const formData = new FormData()

      formData.append('vehicle_id', this.swap_position.vehicle_id)
      formData.append('position_id', this.swap_position.position_id)
      formData.append('is_blacklist', 'no')
      formData.append('status_emp_crewing_id', 1)
      formData.append('start_date', moment(this.swap_position.start_date).format('YYYY-MM-DD HH:mm:ss'))
      if(this.swap_position.end_date) {
        formData.append('end_date', moment(this.swap_position.end_date).format('YYYY-MM-DD HH:mm:ss'))
        formData.append('end_date_time',  moment(this.swap_position.end_date).format('HH:mm:ss'))
      }
      formData.append('join_on_board', moment(this.swap_position.join_on_board).format('YYYY-MM-DD HH:mm:ss'))
      formData.append('join_on_board_time', moment(this.swap_position.join_on_board).format('HH:mm:ss'))
      formData.append('remarks', this.swap_position.remarks)
      formData.append('company_id', this.swap_position.company_id)
      formData.append('port_id', this.swap_position.port_id)
      formData.append('letter_of_no', null)
      if(this.swap_position.file){
        formData.append('attachment', this.swap_position.file)
      }
      formData.append('swap', 1)

      const res = await this[method]({
        emp_crewing_id: this.user.id,
        formData,
      })

      if (res.status === 'success') {
        this.$bvModal.hide('swap-position')
        this.resetFormSwapHistory()
        await this.getProfileCrew()
        this.$swal(`Berhasil!`, `Berhasil Swap Position.`, 'success')
        this.saving = false
      } else {
        this.saving = false
        return this.$swal(`Oops!`, res?.data, 'error')
      }
      this.saving = false
    },

    async onSaveKeluarga(e) {
      e.preventDefault()
      this.saving_keluarga = true

      let method = this.is_edit ? 'updateKeluarga' : 'createKeluarga'
      
      let formData = new FormData()

      formData.append('tipe', this.$route.query.user)

      this.user_edit.keluarga.forEach((item, index) => {
        for (const property in item) {
          formData.append(
            `keluarga[${index}][${property}]`,
            item[property]
          )
        }
      })

     if(this.is_edit){
      const { status, data } = await this[method]({
        id: this.update_keluarga.id,
        data: this.update_keluarga
      })

      if (status !== 'success') {
        this.saving_keluarga = false

        this.$swal(`Oops!`, `Gagal Tambah Data Keluarga.`, 'error')
        return
      }
      
      if(this.$route.query.user === 'crew'){
        await this.getProfileCrew()
      } else {
        await this.fetchProfileCandidate()
      }
      this.saving_keluarga = false
      this.$swal(`Success!`, `Berhasil Tambah Data Keluarga.`, 'success')
      this.$bvModal.hide('keluarga')
     } else {
      const { status, data } = await this[method]({
        id: this.$route.query.id,
        data: formData
      })

      if (status !== 'success') {
        this.saving_keluarga = false

        this.$swal(`Oops!`, `Gagal Tambah Data Keluarga.`, 'error')
        return
      }

      if(this.$route.query.user === 'crew'){
        await this.getProfileCrew()
      } else {
        await this.fetchProfileCandidate()
      }
      this.user_edit.keluarga = [
          {
            status: '',
            nama_lengkap: '',
            tempat_lahir: '',
            tanggal_lahir: '',
            jenis_kelamin: '',
            pekerjaan: '',
            alamat: '',
            no_hp: '',
            age_family: 0,
          },
      ]
      this.saving_keluarga = false
      this.$swal(`Success!`, `Berhasil Tambah Data Keluarga.`, 'success')
      this.$bvModal.hide('keluarga')
     }
    },
    async onDeleteKeluarga(id){
      let method = 'deleteKeluarga'
      
      this.$swal({
        title: 'Hapus Data Keluarga?',
        text: "Ya, Saya yakin.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { status } = await this[method]({id:id})
          this.saving_keluarga = false

          if(status === false || status === 400 || status === 500 || status === 'error'){
            this.$swal(`Oops!`, `Gagal Delete Data Keluarga.`, 'error')
            return 
          }

          if(this.$route.query.user === 'crew'){
            await this.getProfileCrew()
          } else {
            await this.fetchProfileCandidate()
          }
          this.saving_keluarga = false
          this.$swal(`Success!`, `Berhasil Delete Data Keluarga.`, 'success')
        }
      }) 
    },

    async onSavePekerjaan(e) {
      e.preventDefault()
      this.saving_pekerjaan = true

      let method = this.is_edit ? 'updatePekerjaan' : 'createPekerjaan'

      let formData = new FormData()

      formData.append('tipe', this.$route.query.user)

      this.user_edit.pekerjaan.forEach((item, index) => {
        for (const property in item) {
          formData.append(
            `pekerjaan[${index}][${property}]`,
            item[property]
          )
        }
      })

      if(this.is_edit){
        const { status, data } = await this[method]({
          id: this.update_pekerjaan.id,
          data: this.update_pekerjaan
        })

        if (status !== 'success') {
          this.saving_pekerjaan = false

          this.$swal(`Oops!`, `Gagal Update Data Pekerjaan.`, 'error')
          return
        }

        if(this.$route.query.user === 'crew'){
          await this.getProfileCrew()
        } else {
          await this.fetchProfileCandidate()
        }
        this.saving_pekerjaan = false
        this.$swal(`Success!`, `Berhasil Update Data Pekerjaan.`, 'success')
        this.$bvModal.hide('pengalaman_berlayar')
      } else {
        const { status, data } = await this[method]({
          id: this.$route.query.id,
          data: formData
        })

        if (status !== 'success') {
          this.saving_pekerjaan = false

          this.$swal(`Oops!`, `Gagal Tambah Data Pekerjaan.`, 'error')
          return
        }

        if(this.$route.query.user === 'crew'){
          await this.getProfileCrew()
        } else {
          await this.fetchProfileCandidate()
        }
        this.user_edit.pekerjaan = [
          {
            jabatan: '',
            nama_kapal: '',
            no_tlp_perusahaan: null,
            perusahaan: '',
            lokasi: '',
            negara: '',
            bendera_kapal: '',
            area_operasi: '',
            tahun_masuk: '',
            tahun_berhenti: '',
            gaji: null,
            alasan_berhenti: '',
            fasilitas: '',
            pemberi_rekomendasi: '',
            kontak_pemberi_informasi: '',
            deskripsi_pekerjaan: '',
          },
        ],
        this.saving_pekerjaan = false
        this.$swal(`Success!`, `Berhasil Tambah Data Pekerjaan.`, 'success')
        this.$bvModal.hide('pengalaman_berlayar')
      }
    },
    async onDeletePekerjaan(id){
      let method = 'deletePekerjaan'
      
      this.$swal({
        title: 'Hapus Data Pekerjaan?',
        text: "Ya, Saya yakin.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { status } = await this[method]({id:id})
          this.saving_keluarga = false

          if(status === false || status === 400 || status === 500 || status === 'error'){
            this.$swal(`Oops!`, `Gagal Delete Data Pekerjaan.`, 'error')
            return 
          } 

        if(this.$route.query.user === 'crew'){
          await this.getProfileCrew()
        } else {
          await this.fetchProfileCandidate()
        }
          this.saving_keluarga = false
          this.$swal(`Success!`, `Berhasil Delete Data Pekerjaan.`, 'success')
        }
      }) 
    },

    async onSaveTravelDokumen(e) {
      e.preventDefault()
      this.saving_traveldocument = true

      let method = this.is_edit ? 'updateTravelDokumen' : 'createTravelDokumen'

      let formData = new FormData()
      formData.append('tipe', this.$route.query.user)

      let newTravelDocument = []
      this.user_edit.travel_document.map((val) => {
        newTravelDocument.push({
          document_list_id: val.document_list_id,
          document_number: val.document_number,
          place_issued: val.place_issued,
          date_issued: val.date_issued,
          validity_period: val.validity_period,
          file: val.file,
        })
      })

      newTravelDocument.forEach((item, index) => {
        for (const property in item) {
          formData.append(
            `travel_document[${index}][${property}]`,
            item[property]
          )
        }
      })

      if(this.is_edit){
        let formDataEdit = new FormData()
        formDataEdit.append('document_list_id', this.update_travel_document.document_list_id)
        formDataEdit.append('document_number', this.update_travel_document.document_number)
        formDataEdit.append('place_issued', this.update_travel_document.place_issued)
        formDataEdit.append('date_issued', this.update_travel_document.date_issued)
        formDataEdit.append('validity_period', this.update_travel_document.validity_period)
        formDataEdit.append('file', this.update_travel_document.file)

        const { status, data } = await this[method]({
          id: this.update_travel_document.id,
          data: formDataEdit
        })

        if (status !== 'success') {
          this.saving_traveldocument = false

          this.$swal(`Oops!`, `Gagal Update Data Travel Dokumen.`, 'error')
          return
        }

        if(this.$route.query.user === 'crew'){
          await this.getProfileCrew()
        } else {
          await this.fetchProfileCandidate()
        }
        this.saving_traveldocument = false
        this.$swal(`Success!`, `Berhasil Update Data Travel Dokumen.`, 'success')
        this.$bvModal.hide('dokumen_perjalanan')
      } else {
        const { status, data } = await this[method]({
          id: this.$route.query.id,
          data: formData
        })

        if (status !== 'success') {
          this.saving_traveldocument = false

          this.$swal(`Oops!`, `Gagal Tambah Data Dokumen Perjalanan.`, 'error')
          return
        }

        if(this.$route.query.user === 'crew'){
          await this.getProfileCrew()
        } else {
          await this.fetchProfileCandidate()
        }
        this.user_edit.travel_document = [
          {
            document_list_id: '',
            document_number: '',
            place_issued: '',
            date_issued: '',
            validity_period: '',
            file: null,
            file_label: '',
          },
        ],
        this.saving_traveldocument = false
        this.$swal(`Success!`, `Berhasil Tambah Data Dokumen Perjalanan.`, 'success')
        this.$bvModal.hide('dokumen_perjalanan')
      }
    },
    async onDeleteTravelDokumen(id){
      let method = 'deleteTravelDokumen'
      
      this.$swal({
        title: 'Hapus Data Dokumen Perjalanan?',
        text: "Ya, Saya yakin.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { status } = await this[method]({id:id})

          if(status === false || status === 400 || status === 500 || status === 'error'){
            this.$swal(`Oops!`, `Gagal Delete Data Dokumen Perjalanan.`, 'error')
            return 
          } 

        if(this.$route.query.user === 'crew'){
          await this.getProfileCrew()
        } else {
          await this.fetchProfileCandidate()
        }
          this.$swal(`Success!`, `Berhasil Delete Data Dokumen Perjalanan.`, 'success')
        }
      }) 
    },

    async onSaveCompetency(e) {
      e.preventDefault()
      this.saving_competency = true

      let method = this.is_edit ? 'updateCompetency' : 'createCompetency'
      let formData = new FormData()

      formData.append('tipe', this.$route.query.user)

      let newCoc = []
      this.user_edit.certificate_of_competency.map((val) => {
        newCoc.push({
          certificate_list_id: val.certificate_list_id,
          sub_education: val.sub_education,
          document_number: val.document_number,
          place_issued: val.place_issued,
          date_issued: val.date_issued,
          validity_period: val.validity_period,
          file: val.file,
        })
      })
      newCoc.forEach((item, index) => {
        for (const property in item) {
          delete item.file_label
          formData.append(
            `certificate_of_competency[${index}][${property}]`,
            item[property]
          )
        }
      })

      if(this.is_edit){
        let formDataEdit = new FormData()
        formDataEdit.append('certificate_list_id', this.update_certificate_of_competency.certificate_list_id)
        formDataEdit.append('sub_education', this.update_certificate_of_competency.sub_education)
        formDataEdit.append('document_number', this.update_certificate_of_competency.document_number)
        formDataEdit.append('place_issued', this.update_certificate_of_competency.place_issued)
        formDataEdit.append('date_issued', this.update_certificate_of_competency.date_issued)
        formDataEdit.append('validity_period', this.update_certificate_of_competency.validity_period)
        formDataEdit.append('file', this.update_certificate_of_competency.file)
 
        const { status, data } = await this[method]({
          id: this.update_certificate_of_competency.id,
          data: formDataEdit
        })

        if (status !== 'success') {
          this.saving_competency = false

          this.$swal(`Oops!`, `Gagal Update Data Competency.`, 'error')
          return
        }

        if(this.$route.query.user === 'crew'){
          await this.getProfileCrew()
        } else {
          await this.fetchProfileCandidate()
        }
        this.saving_competency = false
        this.$swal(`Success!`, `Berhasil Update Data Competency.`, 'success')
        this.$bvModal.hide('kompetensi')
      } else {
        const { status, data } = await this[method]({
          id: this.$route.query.id,
          data: formData
        })

        if (status !== 'success') {
          this.saving_competency = false

          this.$swal(`Oops!`, `Gagal Tambah Data Competency.`, 'error')
          return
        }

        if(this.$route.query.user === 'crew'){
          await this.getProfileCrew()
        } else {
          await this.fetchProfileCandidate()
        }
        this.user_edit.certificate_of_competency = [
          {
            certificate_list_id: null,
            sub_education: "none",
            document_number: '',
            place_issued: '',
            date_issued: null,
            validity_period: null,
            file: null,
            file_label: '',
          },
        ],
        this.saving_competency = false
        this.$swal(`Success!`, `Berhasil Tambah Data Competency.`, 'success')
        this.$bvModal.hide('kompetensi')
      }
    },
    async onDeleteCompetency(id){
      let method = 'deleteCompetency'
      
      this.$swal({
        title: 'Hapus Data Competency?',
        text: "Ya, Saya yakin.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { status } = await this[method]({id:id})
          this.saving_competency = false

          if(status === false || status === 400 || status === 500 || status === 'error'){
            this.$swal(`Oops!`, `Gagal Delete Data Competency.`, 'error')
            return 
          } 

          if(this.$route.query.user === 'crew'){
            await this.getProfileCrew()
          } else {
            await this.fetchProfileCandidate()
          }
          this.saving_competency = false
          this.$swal(`Success!`, `Berhasil Delete Data Competency.`, 'success')
        }
      }) 
    },

    async onSaveProficiency(e) {
      e.preventDefault()

      this.saving_proficiency = true

      let method = this.is_edit ? 'updateProficiency' : 'createProficiency'

      let formData = new FormData()
      formData.append('tipe', this.$route.query.user)

      let newCop = []
      this.user_edit.certificate_of_proficiency.map((val) => {
        newCop.push({
          certificate_list_id: val.certificate_list_id,
          document_number: val.document_number,
          place_issued: val.place_issued,
          date_issued: val.date_issued,
          validity_period: val.validity_period,
          file: val.file,
        })
      })
      newCop.forEach((item, index) => {
        for (const property in item) {
          formData.append(
            `certificate_of_proficiency[${index}][${property}]`,
            item[property]
          )
        }
      })

      if(this.is_edit){
        let formDataEdit = new FormData()

        formDataEdit.append('certificate_list_id', this.update_certificate_of_proficiency.certificate_list_id)
        formDataEdit.append('document_number', this.update_certificate_of_proficiency.document_number)
        formDataEdit.append('place_issued', this.update_certificate_of_proficiency.place_issued)
        formDataEdit.append('date_issued', this.update_certificate_of_proficiency.date_issued)
        formDataEdit.append('validity_period', this.update_certificate_of_proficiency.validity_period)
        formDataEdit.append('file', this.update_certificate_of_proficiency.file)
     
        const { status, data } = await this[method]({
          id: this.update_certificate_of_proficiency.id,
          data: formDataEdit
        })

        if (status !== 'success') {
          this.saving_proficiency = false

          this.$swal(`Oops!`, `Gagal Update Data Proficiency.`, 'error')
          return
        }

        if(this.$route.query.user === 'crew'){
          await this.getProfileCrew()
        } else {
          await this.fetchProfileCandidate()
        }
        this.saving_proficiency = false
        this.$swal(`Success!`, `Berhasil Update Data Proficiency.`, 'success')
        this.$bvModal.hide('profesi')
      } else {
        const { status, data } = await this[method]({
          id: this.$route.query.id,
          data: formData
        })

        if (status !== 'success') {
          this.saving_proficiency = false

          this.$swal(`Oops!`, `Gagal Tambah Data Proficiency.`, 'error')
          return
        }

        if(this.$route.query.user === 'crew'){
          await this.getProfileCrew()
        } else {
          await this.fetchProfileCandidate()
        }
        this.user_edit.certificate_of_proficiency = [
          {
            certificate_list_id: "",
						document_number: "",
						place_issued: "",
						date_issued: null,
						validity_period: null,
						file: null,
            file_label: '',
          },
        ],
        this.saving_proficiency = false
        this.$swal(`Success!`, `Berhasil Tambah Data Proficiency.`, 'success')
        this.$bvModal.hide('profesi')
      }
    },
    async onDeleteProficiency(id){
      let method = 'deleteProficiency'
      
      this.$swal({
        title: 'Hapus Data Proficiency?',
        text: "Ya, Saya yakin.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { status } = await this[method]({id:id})
          this.saving_proficiency = false

          if(status === false || status === 400 || status === 500 || status === 'error'){
            this.$swal(`Oops!`, `Gagal Delete Data Proficiency.`, 'error')
            return 
          } 

        if(this.$route.query.user === 'crew'){
          await this.getProfileCrew()
        } else {
          await this.fetchProfileCandidate()
        }
          this.saving_proficiency = false
          this.$swal(`Success!`, `Berhasil Delete Data Proficiency.`, 'success')
        }
      }) 
    },
    
    async onSaveDokumenPenting(e) {
      e.preventDefault()
      this.saving_supportdokumen = true

      let method = 'createOrUpdateDokumenPendukung'

      let newSupportDokumen = []

      let err = null

      if(this.is_edit) {
        let formDataEdit = new FormData()
        formDataEdit.append('tipe', this.$route.query.user)
        formDataEdit.append('id', this.update_support_dokumen.id)
        formDataEdit.append(`document_list_id`, Number(this.update_support_dokumen.document_list_id))
        formDataEdit.append(`file`, this.update_support_dokumen.file)


        const { status, data } = await this[method]({
          id: this.$route.query.id,
          data: formDataEdit,
        })

        if (status !== 'success') {
          this.saving_supportdokumen = false

          this.$swal(`Oops!`, `Gagal Update Support Dokumen.`, 'error')
          return
        }
        
        if(this.$route.query.user === 'crew'){
          await this.getProfileCrew()
        } else {
          await this.fetchProfileCandidate()
        }
        this.saving_supportdokumen = false
        this.$swal(`Success!`, `Berhasil Update Support Dokumen.`, 'success')
        this.$bvModal.hide('dokumen_pendukung')

      } else {
        this.user_edit.support_dokumen.map((val) => {
          newSupportDokumen.push({
            document_list_id: val.document_list_id,
            file: val.file,
          })
        })
        const promises = newSupportDokumen.map(async (item, index) => {
          let formData = new FormData()

          formData.append('tipe', this.$route.query.user)
          formData.append(`document_list_id`, item.document_list_id)
          formData.append(`file`, item.file)

          const { status, data } = await this[method]({
            id: this.$route.query.id,
            data: formData,
          })

          if (status === 400) {
            err = status
          }
        })

        await Promise.all(promises)

        if (err === 400) {
            this.saving_supportdokumen = false
            return this.$swal(
              `Oops!`,
              `Gagal Update Data Support Dokumen.`,
              'error'
            )
          }

          if(this.$route.query.user === 'crew'){
            await this.getProfileCrew()
          } else {
            await this.fetchProfileCandidate()
          }
          this.user_edit.support_dokumen = [
            {
              document_list_id: '',
              file: null,
              file_label: '',
            },
          ],
          this.$swal(`Success!`, `Berhasil Tambah Support Dokumen.`, 'success')
          this.saving_supportdokumen = false
          this.$bvModal.hide('dokumen_pendukung')
      }
    },
    async onDeleteDokumenPendukung(id){
      let method = 'deleteDokumenPendukung'
      
      this.$swal({
        title: 'Hapus Data Dokumen Pendukung?',
        text: "Ya, Saya yakin.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { status } = await this[method]({id:id})
          if(status === false || status === 400 || status === 500 || status === 'error'){
            this.$swal(`Oops!`, `Gagal Delete Data Dokumen Pendukung.`, 'error')
            return 
          } 

          if(this.$route.query.user === 'crew'){
          await this.getProfileCrew()
        } else {
          await this.fetchProfileCandidate()
        }
          this.$swal(`Success!`, `Berhasil Delete Data Dokumen Pendukung.`, 'success')
        }
      }) 
    }, 
    async onEditJoinDate(){
      let payload = {}

      if(this.formJoinDate.join_on_board) payload.join_on_board = moment(new Date(this.formJoinDate.join_on_board)).format('YYYY-MM-DD HH:mm:ss')
      if(this.formJoinDate.sign_on)  payload.sign_on = moment(new Date(this.formJoinDate.sign_on)).format('YYYY-MM-DD HH:mm:ss')
      if(this.formJoinDate.sign_off)  payload.sign_off = moment(new Date(this.formJoinDate.sign_off)).format('YYYY-MM-DD HH:mm:ss')

      const { status, data } = await this.updateJoinDateCrew({
        id: this.$route.query.id,
        data: payload,
      })

      if (status !== 'success') {
        this.$swal(`Oops!`, `${data}`, 'error')
        return
      }

      this.isEditJoinDate = !this.isEditJoinDate
      this.$swal(`Success!`, `Berhasil Update Join Date.`, 'success')
    }
  },
}
</script>
