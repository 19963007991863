import axios from './auth'

const USERS_BASE_URL = `${process.env.VUE_APP_API_OPS_BASE_URL}/users`;

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async getUsers({ commit }, params = {}) {
    const url = USERS_BASE_URL;

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params
    };

    try {
      const response = await axios(config);
      let data = response.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async getUsersPic({ commit }, params = {}) {
    const url = `${USERS_BASE_URL}/pic`;

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params
    };

    try {
      const response = await axios(config);
      let data = response.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async getUserById({ commit }, payload) {
    const url = `${USERS_BASE_URL}/${payload}`;

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async registerUser({ commit }, payload) {
    const url = `${USERS_BASE_URL}/registration`;

    const method = "post";
    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json"
    };
    const config = {
      url,
      method,
      data: payload,
      headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async updateUser({ commit }, payload) {
    const url = `${USERS_BASE_URL}/${payload.user_id}`;

    const method = "put";
    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json"
    };
    const config = {
      url,
      method,
      data: payload.data,
      headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async updateUserPhoto({ commit }, payload) {
    const url = `${USERS_BASE_URL}/photo/${payload.user_id}`;

    const method = "post";
    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json"
    };
    const config = {
      url,
      method,
      data: payload.data,
      headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async updateUserStatus({ commit }, payload) {
    const url = `${USERS_BASE_URL}/status/${payload.user_id}`;
    let urlencoded = new URLSearchParams()
    urlencoded.append('isActive', payload.active)

    const method = "put";
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };
    const config = {
      url,
      method,
      data: urlencoded,
      headers
    };

    try {
      const response = await axios(config);
      let data = response.data.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
}
