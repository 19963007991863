var render = function render(){
  var _vm$projectData, _vm$projectData$proje, _vm$projectData2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSavePaymentList.apply(null, arguments);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [['EDIT', 'ADD'].includes(_vm.action) ? _c('b-button', {
    staticClass: "float-right mr-1",
    attrs: {
      "variant": "primary",
      "size": "sm",
      "type": "submit"
    }
  }, [_c('i', {
    staticClass: "fa fa-save"
  }), _vm._v(" " + _vm._s(['EDIT'].includes(_vm.action) ? 'Update' : 'Create') + " Payment List  ")]) : _vm._e(), _c('b-button', {
    staticClass: "float-right mr-1",
    attrs: {
      "variant": "danger",
      "size": "sm",
      "type": "button"
    },
    on: {
      "click": _vm.closeSlideFormPayment
    }
  }, [_c('i', {
    staticClass: "fa fa-ban"
  }), _vm._v(" Cancel  ")])], 1), _c('b-col', {
    staticClass: "p-4",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Request Name"
    }
  }, [['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-input', {
    attrs: {
      "placeholder": "Type Text",
      "type": "text"
    },
    model: {
      value: _vm.form.request_name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "request_name", $$v);
      },
      expression: "form.request_name"
    }
  }) : _vm._e(), ['PREVIEW'].includes(_vm.action) ? _c('strong', [_vm._v(_vm._s(_vm.paymentData.request_name))]) : _vm._e()], 1)], 1), ((_vm$projectData = _vm.projectData) === null || _vm$projectData === void 0 ? void 0 : (_vm$projectData$proje = _vm$projectData.project_contract_plan) === null || _vm$projectData$proje === void 0 ? void 0 : _vm$projectData$proje.length) > 0 ? _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "For Trip"
    }
  }, [['EDIT', 'ADD'].includes(_vm.action) ? _c('v-select', {
    attrs: {
      "label": "shipment_plan_detail_number",
      "track-by": "id",
      "name": "plan_id",
      "options": (_vm$projectData2 = _vm.projectData) === null || _vm$projectData2 === void 0 ? void 0 : _vm$projectData2.project_contract_plan,
      "reduce": function reduce(plan) {
        return plan.id;
      },
      "placeholder": "Select...",
      "required": ""
    },
    model: {
      value: _vm.form.plan_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "plan_id", $$v);
      },
      expression: "form.plan_id"
    }
  }) : _vm._e(), ['PREVIEW'].includes(_vm.action) ? _c('strong', [_vm._v(_vm._s(_vm.paymentData.project_plan.shipment_plan_detail_number))]) : _vm._e()], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Request Date"
    }
  }, [['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-input', {
    attrs: {
      "type": "date",
      "required": ""
    },
    model: {
      value: _vm.form.request_date,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "request_date", $$v);
      },
      expression: "form.request_date"
    }
  }) : _vm._e(), ['PREVIEW'].includes(_vm.action) ? _c('strong', [_vm._v(_vm._s(_vm.moment(_vm.paymentData.request_date).format('DD MMMM YYYY')))]) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Real Pricing"
    }
  }, [['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-input', {
    attrs: {
      "placeholder": "0",
      "type": "number"
    },
    model: {
      value: _vm.form.real_pricing,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "real_pricing", $$v);
      },
      expression: "form.real_pricing"
    }
  }) : _vm._e(), ['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-text', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.form.real_pricing)))]) : _vm._e(), ['PREVIEW'].includes(_vm.action) ? _c('strong', [_vm._v(_vm._s(_vm.formatCurrency(_vm.paymentData.real_pricing)))]) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Balance"
    }
  }, [['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-input', {
    attrs: {
      "placeholder": "0",
      "type": "number"
    },
    on: {
      "change": _vm.onChangeBalance
    },
    model: {
      value: _vm.form.balance,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "balance", $$v);
      },
      expression: "form.balance"
    }
  }) : _vm._e(), ['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-text', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.form.balance)))]) : _vm._e(), ['PREVIEW'].includes(_vm.action) ? _c('strong', [_vm._v(_vm._s(_vm.formatCurrency(_vm.paymentData.balance)))]) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Invoice Price"
    }
  }, [['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-input', {
    attrs: {
      "placeholder": "0",
      "type": "number"
    },
    on: {
      "change": function change() {
        _vm.onChangeInvoicePrice();
        _vm.countTaxTotal();
        _vm.countInvoiceTotal();
      }
    },
    model: {
      value: _vm.form.invoice_price,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "invoice_price", $$v);
      },
      expression: "form.invoice_price"
    }
  }) : _vm._e(), ['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-text', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.form.invoice_price)))]) : _vm._e(), ['PREVIEW'].includes(_vm.action) ? _c('strong', [_vm._v(_vm._s(_vm.formatCurrency(_vm.paymentData.invoice_price)))]) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Use Tax"
    }
  }, [['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-checkbox', {
    attrs: {
      "id": "use_tax",
      "label": "Yes / No",
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.form.use_tax,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "use_tax", $$v);
      },
      expression: "form.use_tax"
    }
  }) : _vm._e(), ['PREVIEW'].includes(_vm.action) ? _c('strong', [_vm._v(_vm._s(_vm.paymentData.use_tax ? 'Yes' : 'No'))]) : _vm._e()], 1)], 1), _vm.form.use_tax ? [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tax Name"
    }
  }, [['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-input', {
    attrs: {
      "placeholder": "Type Text",
      "type": "text"
    },
    model: {
      value: _vm.form.name_tax,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name_tax", $$v);
      },
      expression: "form.name_tax"
    }
  }) : _vm._e(), ['PREVIEW'].includes(_vm.action) ? _c('strong', [_vm._v(_vm._s(_vm.paymentData.name_tax))]) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tax Percentage (%)"
    }
  }, [['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-input', {
    attrs: {
      "placeholder": "0.00",
      "type": "number",
      "step": "0.001"
    },
    on: {
      "change": function change() {
        _vm.countTaxTotal();
        _vm.countInvoiceTotal();
      }
    },
    model: {
      value: _vm.form.tax_percentage,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "tax_percentage", $$v);
      },
      expression: "form.tax_percentage"
    }
  }) : _vm._e(), ['PREVIEW'].includes(_vm.action) ? _c('strong', [_vm._v(_vm._s(Number(_vm.paymentData.tax_percentage).toLocaleString()))]) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tax Total"
    }
  }, [['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-input', {
    attrs: {
      "placeholder": "0",
      "type": "number"
    },
    model: {
      value: _vm.form.tax_total,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "tax_total", $$v);
      },
      expression: "form.tax_total"
    }
  }) : _vm._e(), ['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-text', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.form.tax_total)))]) : _vm._e(), ['PREVIEW'].includes(_vm.action) ? _c('strong', [_vm._v(_vm._s(_vm.formatCurrency(_vm.paymentData.tax_total)))]) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Invoice Total"
    }
  }, [['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-input', {
    attrs: {
      "placeholder": "0",
      "type": "number"
    },
    model: {
      value: _vm.form.invoice_total,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "invoice_total", $$v);
      },
      expression: "form.invoice_total"
    }
  }) : _vm._e(), ['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-text', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.form.invoice_total)))]) : _vm._e(), ['PREVIEW'].includes(_vm.action) ? _c('strong', [_vm._v(_vm._s(_vm.formatCurrency(_vm.paymentData.tax_total)))]) : _vm._e()], 1)], 1)] : _vm._e(), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('strong', [_vm._v("Deduction")]), _c('div', {
    staticClass: "overflow-auto"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "width": "20px"
    }
  }, [_vm._v("#")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "vertical-align": "middle",
      "width": "250px"
    }
  }, [_vm._v("Name")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "vertical-align": "middle",
      "width": "250px"
    }
  }, [_vm._v("Amount")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "vertical-align": "middle",
      "width": "250px"
    }
  }, [_vm._v("Remarks")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "vertical-align": "middle",
      "width": "250px"
    }
  }, [_vm._v("File")])])]), ['ADD', 'EDIT'].includes(_vm.action) ? _c('tbody', _vm._l(_vm.form.deduction, function (state, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "text-center align-top"
    }, [index !== 0 ? _c('b-button', {
      attrs: {
        "size": "sm",
        "variant": "danger"
      },
      on: {
        "click": function click() {
          _vm.form.deduction.splice(index, 1);
          _vm.form.invoice_price += state.amount;
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash pr-0"
    })]) : _vm._e()], 1), _c('td', {
      staticClass: "align-top"
    }, [_c('b-form-input', {
      attrs: {
        "placeholder": "Type Text"
      },
      model: {
        value: state.name,
        callback: function callback($$v) {
          _vm.$set(state, "name", $$v);
        },
        expression: "state.name"
      }
    })], 1), _c('td', {
      staticClass: "align-top"
    }, [_c('b-form-input', {
      attrs: {
        "placeholder": "Type Number"
      },
      on: {
        "blur": _vm.countInvoicePrice
      },
      model: {
        value: state.amount,
        callback: function callback($$v) {
          _vm.$set(state, "amount", $$v);
        },
        expression: "state.amount"
      }
    }), ['EDIT', 'ADD'].includes(_vm.action) ? _c('b-form-text', {
      staticClass: "text-muted"
    }, [_vm._v(_vm._s(_vm.formatCurrency(state.amount)))]) : _vm._e()], 1), _c('td', {
      staticClass: "align-top"
    }, [_c('b-form-input', {
      attrs: {
        "placeholder": "Type Text"
      },
      model: {
        value: state.remarks,
        callback: function callback($$v) {
          _vm.$set(state, "remarks", $$v);
        },
        expression: "state.remarks"
      }
    })], 1), _c('td', [_c('b-form-file', {
      attrs: {
        "id": 'fileSupport' + index,
        "placeholder": "Choose a file",
        "accept": "application/pdf"
      },
      model: {
        value: state.file,
        callback: function callback($$v) {
          _vm.$set(state, "file", $$v);
        },
        expression: "state.file"
      }
    }), _c('span', {
      staticClass: "text-primary",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.handleOpenFilePreview(state === null || state === void 0 ? void 0 : state.file_url);
        }
      }
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.file_name))])], 1)]);
  }), 0) : _c('tbody', _vm._l(_vm.form.deduction, function (state, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "text-center align-top"
    }, [_vm._v("#")]), _c('td', {
      staticClass: "text-center align-top"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.name))]), _c('td', {
      staticClass: "text-center align-top"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.amount))]), _c('td', {
      staticClass: "text-center align-top"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.remarks))]), _c('td', {
      staticClass: "text-center"
    }, [_c('span', {
      staticClass: "text-primary",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.handleOpenFilePreview(state === null || state === void 0 ? void 0 : state.file_url);
        }
      }
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.file_name))])])]);
  }), 0)])]), ['ADD', 'EDIT'].includes(_vm.action) ? _c('b-button', {
    attrs: {
      "block": "",
      "variant": "success",
      "type": "button"
    },
    on: {
      "click": _vm.addDeduction
    }
  }, [_c('i', {
    staticClass: "fa fa-plus pr-0"
  }), _vm._v(" ADD MORE DEDUCTION")]) : _vm._e()], 1)], 2)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }