<template>
    <b-row>
      <b-col :sm="!menuPermission('update') && !menuPermission('add') ? '12' : '8'">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"><strong>List Master Task Type</strong></h4>
            </template>
            <template v-slot:body>
              <b-form @submit.prevent="fetchMasterTaskType()">
                <b-form-group
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    style="width: 200px;"
                    type="text"
                    placeholder="Search..."
                    v-model="params.name"
                  ></b-form-input>
                </b-form-group>
              </b-form>
              <div v-if="loading" class="text-center my-5">
                <b-spinner type="grow" label="Spinning"></b-spinner>
              </div>
              <template v-else>
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th width="10%">No</th>
                        <th width="25%">Name</th>
                        <th width="25%">Alias</th>
                        <th width="10%">Active</th>
                        <th width="15%">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(p, i) in filteredTableData" :key="`p-${i}`">
                        <td class="text-center">{{ startRowIndexData + i }}</td>
                        <td>{{ p.name }}</td>
                        <td>{{ p.alias ? p.alias : '-' }}</td>
                        <td>{{ p.is_active === true ? 'Active' : 'Inactive' }}</td>
                        <td>
                          <b-button
                            v-if="menuPermission('update')"
                            variant="success"
                            size="sm"
                            class="mr-2"
                            @click="onEditTaskType(p)"
                            ><i class="fa fa-edit"></i
                          ></b-button>
                          <b-button
                            v-if="menuPermission('delete')"
                            variant="danger"
                            size="sm"
                            @click="onDeleteTaskType(p)"
                          >
                            <i
                              v-if="!loading_delete"
                              class="fa fa-trash"
                            ></i>
                            
                            <span v-else
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </b-button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-if="displayedData.length > 0">
                  <b-pagination
                    v-model="data.currentPage"
                    :total-rows="totalData"
                    :per-page="data.perPage"
                    aria-controls="my-table"
                    align="center"
                    first-number
                  />
                </div>
                <p v-else class="text-center my-4">No task type data found.</p>
              </template>
            </template>
          </iq-card>
      </b-col>
      <b-col sm="4"
        v-if="menuPermission('update') || menuPermission('add')">
        <iq-card
          :class="{
            'border border-danger shadow-lg': edit_mode,
          }"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary"
              ><strong>{{ edit_mode ? 'Edit' : 'Add' }} Task Type</strong></h4
            >
          </template>
          <template v-slot:body>
            <b-form @submit.prevent="submitFormTaskType">
              <b-form-group
                label="Name Task Type"
                label-for="name_task_type"
              >
                <b-form-input
                  id="name_task_type"
                  type="text"
                  placeholder="Type text..."
                  maxlength="30"
                  v-model="form.name"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                label="Alias Task Type"
                label-for="alias_task_type"
              >
                <b-form-input
                  id="alias_task_type"
                  type="text"
                  placeholder="Type text..."
                  maxlength="30"
                  v-model="form.alias"
                ></b-form-input>
              </b-form-group>

              <b-form-group label="Is Active" label-for="is_actove">
                <b-form-checkbox
                  id="is_active"
                  v-model="form.is_active"
                  name="is_active"
                  switch
                  inline
                >
                  {{ form.is_active ? 'Active' : 'Inactive' }}
                </b-form-checkbox>
              </b-form-group>
              <hr />
              <b-form-group class="text-right">
                <b-button type="submit" variant="primary">
                  <span v-if="!loading_submit">{{
                    edit_mode || !menuPermission('add') ? 'Save' : 'Add'
                  }}</span>
                  <template v-else>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Saving...
                  </template>
                </b-button>
                <b-button
                  type="button"
                  variant="danger"
                  class="iq-bg-danger ml-3"
                  @click.prevent="resetFormTaskType"
                  >Cancel</b-button
                >
              </b-form-group>
            </b-form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
</template>

<script>
import { srActions } from '@src/Utils/helper'
import _ from 'lodash'

export default {
  name: 'SrMasterTaskType',
  data () {
    return {
        loading:false,
        loading_delete:false,
        loading_submit:false,

        edit_mode:false,

        params:{
            name:''
        },

        data: {
            currentPage: 1,
            perPage: 10,
            data:[]
        },

        selected_id:null,
        form: {
            name:'',
            alias:'',
            is_active:true,
        }
    }
  },
  computed: {
    totalData() {
      return this.data.data.length;
    },
    displayedData() {
      const start = (this.data.currentPage - 1) * this.data.perPage;
      const end = start + this.data.perPage;
      return this.data.data.slice(start, end);
    },
    startRowIndexData() {
      return (this.data.currentPage - 1) * this.data.perPage + 1;
    },
    filteredTableData() {
    if (this.params.name) {
      const searchRegex = new RegExp(this.params.name, 'i'); // case-insensitive search
      return this.displayedData.filter(item => searchRegex.test(item.name));
    } else {
      return this.displayedData; // no search query, return all data
    }
  }
  },
  async mounted () {
   await this.fetchMasterTaskType()
  },
  methods: {
   ...srActions,
    menuPermission (output) {
       const { menuObject } = this.$route.query
    
       if (!menuObject) {
         return true
       }
    
       return JSON.parse(window.atob(menuObject))[output]
    },
    onEditTaskType (task_type) {
        this.edit_mode = true
        this.selected_id = task_type.id
        this.form = {
          name: task_type.name,
          alias: task_type.alias,
          is_active: task_type.is_active  === true ? true : false,
        }
    },
    onDeleteTaskType (payload) {
        this.$swal({
          title: 'Delete Task Type?',
          text: `Task Type ${payload.name} will be deleted and cannot be recovered.`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Delete'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.loading_delete = true
            let res = await this.deleteMasterTaskType({id:payload.id})
            if (res.status != 'success') {
              this.loading_delete = false
              this.$swal(
                  'Oops!',
                  'Something wrong with the server. Please try again later.',
                  'error'
                )
            } else {
              this.loading_delete = false
              this.fetchMasterTaskType()
              this.$swal(
                `Task Type deleted!`,
                `Task Type ${payload.name} successfully deleted`,
                'success'
              )
            }
          }
        })
    },
    resetFormTaskType () {
        this.edit_mode = false
        this.form = {
            name:'',
            alias:'',
            is_active: true,
        }
    },
    async fetchMasterTaskType () {
        this.loading = true

        const res = await this.getMasterTaskType()

        if(res.status === 'success'){
            this.data.data = res.data
        }        

        this.loading = false
    },
    async submitFormTaskType () {
        this.loading_submit = true
  
        let payload = {
          id: this.selected_id,
          data: {
            name: this.form.name,
            alias: this.form.alias,
            is_active: this.form.is_active === true ? true : false,
          }
        }
  
        let form_has_empty_value = false
        for (var key in payload.data) {
          if (payload.data[key] === '') form_has_empty_value = true
          else if (Array.isArray(payload.data[key]) && !payload.data[key].length) { form_has_empty_value = true }
        }
  
        if (form_has_empty_value) {
          this.$swal(
            'Empty form!',
            'Please check again and fill all the forms.',
            'error'
          )
        } else {
          if (!this.edit_mode) {
            let res = await this.createMasterTaskType({data: payload.data})
            if (res.status == 'success') {
              this.resetFormTaskType()
              this.fetchMasterTaskType()
              this.$swal(
                'Task Type added!',
                'Task Type added successfully.',
                'success'
              )
            } else {
                this.$swal(
                  'Oops!',
                  'Something wrong with the server. Please try again later.',
                  'error'
                )
            }
          } else {  
            let res = await this.updateMasterTaskType(payload)
            console.log(res)
            if (res.status == 'success') {
              this.resetFormTaskType()
              this.fetchMasterTaskType()
              this.$swal(
                'Task Type updated!',
                'Task Type updated successfully.',
                'success'
              )
            } else {
                this.$swal(
                  'Oops!',
                  'Something wrong with the server. Please try again later.',
                  'error'
                )
            }
          }
        }
        this.loading_submit = false
      },

  }
}
</script>