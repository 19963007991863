var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('form', {
    attrs: {
      "action": "#"
    },
    on: {
      "submit": _vm.onSave
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end w-100"
  }, [_c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "danger",
      "type": "button"
    },
    on: {
      "click": _vm.closeslideFormItem
    }
  }, [_c('div', [_c('i', {
    staticClass: "fa fa-ban"
  }), _c('span', [_vm._v("Cancel")])])]), _c('b-button', {
    attrs: {
      "disabled": _vm.saving,
      "variant": "primary",
      "type": "submit"
    }
  }, [_vm.saving ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _c('div', [_c('i', {
    staticClass: "fa fa-save"
  }), _c('span', [_vm._v("Submit Data")])])])], 1)]), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('hr'), _c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "nav-fill mb-3",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-3 p-0",
          attrs: {
            "active": true,
            "href": "#info",
            "title": "INFORMASI BARANG"
          },
          nativeOn: {
            "click": function click($event) {
              _vm.tab = 'INFO';
            }
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-3 p-0",
          attrs: {
            "active": false,
            "href": "#quantity",
            "title": "KUANTITAS"
          },
          nativeOn: {
            "click": function click($event) {
              _vm.tab = 'QUANTITY';
            }
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-3 p-0",
          attrs: {
            "active": false,
            "href": "#price",
            "title": "HARGA"
          },
          nativeOn: {
            "click": function click($event) {
              _vm.tab = 'PRICE';
            }
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "tab-content"
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "info"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "For Fleet Type",
      "label-for": "selectAll"
    }
  }, [_c('v-multiselect', {
    attrs: {
      "placeholder": "Search Fleet Type",
      "label": "name",
      "track-by": "id",
      "options": _vm.optionsFleetType,
      "multiple": true,
      "required": ""
    },
    model: {
      value: _vm.form.vehicleType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "vehicleType", $$v);
      },
      expression: "form.vehicleType"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v("Fleet type not found.")])]), _c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.selectAll,
      callback: function callback($$v) {
        _vm.selectAll = $$v;
      },
      expression: "selectAll"
    }
  }, [_vm._v(" Select All Fleet Type ")])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "*Code",
      "label-for": "code"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.form.code))]) : _c('b-form-input', {
    attrs: {
      "id": "code",
      "type": "text",
      "placeholder": "Type text",
      "required": ""
    },
    model: {
      value: _vm.form.code,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "code", $$v);
      },
      expression: "form.code"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "*Name",
      "label-for": "name"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.form.name))]) : _c('b-form-input', {
    attrs: {
      "id": "name",
      "type": "text",
      "placeholder": "Type text",
      "required": ""
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "*Spesification",
      "label-for": "spesification"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.form.spesification_id))]) : _c('v-select', {
    attrs: {
      "label": "name",
      "options": _vm.optionsSpesification,
      "reduce": function reduce(type) {
        return type.id;
      },
      "required": "",
      "placeholder": "Select..."
    },
    model: {
      value: _vm.form.spesification_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "spesification_id", $$v);
      },
      expression: "form.spesification_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "*Category Item",
      "label-for": "category_id"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.form.category_id))]) : _c('v-select', {
    attrs: {
      "label": "name",
      "options": _vm.optionsCategory,
      "reduce": function reduce(type) {
        return type.id;
      },
      "required": "",
      "placeholder": "Select..."
    },
    model: {
      value: _vm.form.category_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "category_id", $$v);
      },
      expression: "form.category_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "*Satuan",
      "label-for": "unit"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.form.unit_id))]) : _c('v-select', {
    attrs: {
      "label": "alias",
      "options": _vm.optionsUnit,
      "reduce": function reduce(type) {
        return type.id;
      },
      "placeholder": "Select..."
    },
    model: {
      value: _vm.form.unit_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "unit_id", $$v);
      },
      expression: "form.unit_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Harga Satuan",
      "label-for": "unit_price"
    }
  }, [_vm.previewData ? _c('span', [_vm._v(_vm._s(_vm.form.unit_price))]) : _c('b-form-input', {
    attrs: {
      "id": "unit_price",
      "type": "number",
      "placeholder": "0"
    },
    model: {
      value: _vm.form.unit_price,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "unit_price", $$v);
      },
      expression: "form.unit_price"
    }
  }), _c('span', {
    staticClass: "mb-2"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.form.unit_price)))]), _vm._v(" "), _c('br'), _c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.form.unit_price_tax_inc,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "unit_price_tax_inc", $$v);
      },
      expression: "form.unit_price_tax_inc"
    }
  }, [_vm._v(" Termasuk Pajak ")])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Gambar",
      "label-for": "photo"
    }
  }, [_c('b-form-file', {
    attrs: {
      "id": "photo",
      "accept": "image/*"
    },
    on: {
      "change": function change($event) {
        return _vm.onHandleUpload($event, 'photo');
      }
    }
  }), _vm.form.photo ? _c('span', {
    staticClass: "text-primary"
  }, [_vm._v(_vm._s(_vm.form.photo.split('/').pop()))]) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "File",
      "label-for": "File"
    }
  }, [_c('b-form-file', {
    attrs: {
      "id": "file",
      "accept": "application/*"
    },
    on: {
      "change": function change($event) {
        return _vm.onHandleUpload($event, 'file');
      }
    }
  }), _vm.form.file ? _c('span', {
    staticClass: "text-primary"
  }, [_vm._v(_vm._s(_vm.form.file.split('/').pop()))]) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Method Inspection QC",
      "label-for": "inspection_qc"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.form.qc_method_check))]) : _c('b-form-select', {
    attrs: {
      "id": "qc_method_check",
      "plain": "",
      "size": "md"
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": ''
          }
        }, [_vm._v("Select...")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.qc_method_check,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "qc_method_check", $$v);
      },
      expression: "form.qc_method_check"
    }
  }, [_c('b-form-select-option', {
    attrs: {
      "value": 'lot'
    }
  }, [_vm._v("Lot")]), _c('b-form-select-option', {
    attrs: {
      "value": 'sampling'
    }
  }, [_vm._v("Sampling")])], 1)], 1)], 1), _vm.form.qc_method_check === 'sampling' ? _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Value",
      "label-for": "inspection_qc"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.form.qc_method_check_value_sample))]) : _c('b-form-input', {
    attrs: {
      "id": "qc_method_check_value_sample",
      "type": "number",
      "placeholder": "0"
    },
    model: {
      value: _vm.form.qc_method_check_value_sample,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "qc_method_check_value_sample", $$v);
      },
      expression: "form.qc_method_check_value_sample"
    }
  })], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Keterangan",
      "label-for": "description"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.form.description))]) : _c('b-form-textarea', {
    attrs: {
      "id": "description",
      "type": "text",
      "placeholder": "Type Text"
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1)], 1)], 1)], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "quantity"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jumlah Stok Aman",
      "label-for": "safe_stock_qty"
    }
  }, [_vm.previewData ? _c('span', [_vm._v(_vm._s(_vm.form.safe_stock_qty))]) : _c('b-form-input', {
    attrs: {
      "id": "safe_stock_qty",
      "type": "number",
      "placeholder": "0"
    },
    model: {
      value: _vm.form.safe_stock_qty,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "safe_stock_qty", $$v);
      },
      expression: "form.safe_stock_qty"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jumlah Minimum Pembelian",
      "label-for": "minimum_buy_qty"
    }
  }, [_vm.previewData ? _c('span', [_vm._v(_vm._s(_vm.form.minimum_buy_qty))]) : _c('b-form-input', {
    attrs: {
      "id": "minimum_buy_qty",
      "type": "number",
      "placeholder": "0"
    },
    model: {
      value: _vm.form.minimum_buy_qty,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "minimum_buy_qty", $$v);
      },
      expression: "form.minimum_buy_qty"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Periode Pengadaan",
      "label-for": "procurement_period"
    }
  }, [_vm.previewData ? _c('span', [_vm._v(_vm._s(_vm.form.procurement_period))]) : _c('b-form-input', {
    attrs: {
      "id": "procurement_period",
      "type": "text",
      "placeholder": "Type Text"
    },
    model: {
      value: _vm.form.procurement_period,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "procurement_period", $$v);
      },
      expression: "form.procurement_period"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Brand",
      "label-for": "brand"
    }
  }, [_vm.previewData ? _c('span', [_vm._v(_vm._s(_vm.form.brand))]) : _c('b-form-input', {
    attrs: {
      "id": "brand",
      "type": "text",
      "placeholder": "Type Text"
    },
    model: {
      value: _vm.form.brand,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "brand", $$v);
      },
      expression: "form.brand"
    }
  })], 1)], 1)], 1)], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "price"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Harga Beli",
      "label-for": "brand"
    }
  }, [_vm.previewData ? _c('span', [_vm._v(_vm._s(_vm.form.purchase_price))]) : _c('b-form-input', {
    attrs: {
      "id": "purchase_price",
      "type": "number",
      "placeholder": "0"
    },
    model: {
      value: _vm.form.purchase_price,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "purchase_price", $$v);
      },
      expression: "form.purchase_price"
    }
  }), _c('span', {
    staticClass: "mb-2"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.form.purchase_price)))]), _vm._v(" "), _c('br'), _c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.form.purchase_price_tax_inc,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "purchase_price_tax_inc", $$v);
      },
      expression: "form.purchase_price_tax_inc"
    }
  }, [_vm._v(" Termasuk Pajak ")])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Harga Jual",
      "label-for": "sell_price"
    }
  }, [_vm.previewData ? _c('span', [_vm._v(_vm._s(_vm.form.sell_price))]) : _c('b-form-input', {
    attrs: {
      "id": "sell_price",
      "type": "number",
      "placeholder": "0"
    },
    model: {
      value: _vm.form.sell_price,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "sell_price", $$v);
      },
      expression: "form.sell_price"
    }
  }), _c('span', {
    staticClass: "mb-2"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.form.sell_price)))]), _vm._v(" "), _c('br'), _c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.form.sell_price_tax_inc,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "sell_price_tax_inc", $$v);
      },
      expression: "form.sell_price_tax_inc"
    }
  }, [_vm._v(" Termasuk Pajak ")])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Harga Event",
      "label-for": "event_price"
    }
  }, [_vm.previewData ? _c('span', [_vm._v(_vm._s(_vm.form.event_price))]) : _c('b-form-input', {
    attrs: {
      "id": "event_price",
      "type": "number",
      "placeholder": "0"
    },
    model: {
      value: _vm.form.event_price,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "event_price", $$v);
      },
      expression: "form.event_price"
    }
  }), _c('span', {
    staticClass: "mb-2"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.form.event_price)))]), _vm._v(" "), _c('br'), _c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.form.event_price_tax_inc,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "event_price_tax_inc", $$v);
      },
      expression: "form.event_price_tax_inc"
    }
  }, [_vm._v(" Termasuk Pajak ")])], 1)], 1)], 1)], 1)], 1)])], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }