<template>
  <b-col sm="12" class="p-0">
    <b-row>
      <b-col
        md="6"
        xl="3"
        style="margin-bottom: 2rem"
        v-for="(item, i) in summaries"
        :key="i"
      >
        <div
          class="d-flex align-items-center bg-white p-3 shadow-sm card-certificate h-full"
        >
          <div class="card-icon mr-2" :style="`background: ${item.color} ;`">
            <FleetCircleOutline color="black" />
          </div>
          <div class="text-left">
            <h5 class="line-height mb-1"
              ><strong>{{ item.total ?? '-' }}</strong></h5
            >
            <h6 class="">
              {{ item.name }}
            </h6>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-col>
</template>
<script>
import FleetCircleOutline from './Icons/FleetCircleOutline.vue'

export default {
  props: {
    meta: {
      type: Object,
    },
  },
  components: {
    FleetCircleOutline,
  },
  data() {
    return {
      summaries: [
        {
          name: 'Toward Maintenance',
          total: this.meta?.towards,
          color: '#71FFD7',
          prop: 'towards',
        },
        {
          name: 'Overdue Maintenance',
          total: this.meta?.overdue,
          color: '#FFD1D1',
          prop: 'overdue',
        },
        {
          name: 'Posponed Maintenance',
          total: this.meta?.postponed,
          color: '#FFD3A0',
          prop: 'postponed',
        },
        {
          name: 'Next 3 Month',
          total: this.meta?.['3months'],
          color: '#B9D1FF',
          prop: '3months',
        },
      ],
    }
  },
  watch: {
    meta: {
      handler(newVal) {
        for (const key in newVal) {
          this.summaries.forEach((el, i) => {
            if (key == el.prop) {
              el.total = newVal[key]
            }
          })
        }
      },
      deep: true,
    },
  },
}
</script>
<style scoped>
.card-certificate {
  border-radius: 1rem;
  transition: all;
  transition-duration: 250ms;
}
.card-certificate:hover {
  scale: 1.05;
}
.card-icon {
  border-radius: 1rem;
  font-size: 2rem;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
