<template>
  <b-container fluid>
    <!-- Tab -->
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items class="col-md-3 p-0" :active="true" href="#structure" title="Ship Particular"/>
                <tab-nav-items class="col-md-3 p-0" :active="false" href="#parts" title="Master Equipment"/>
                <tab-nav-items class="col-md-3 p-0" :active="false" href="#jobClass" title="Job Class"/>
                <tab-nav-items class="col-md-3 p-0" :active="false" href="#job" title="Job Type / Index"/>
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Tab -->
    <div id="pills-tabContent-2" class="tab-content">
      <!-- Ship Particular -->
      <tab-content-item :active="true" id="structure">
        <b-row>
          <b-col sm="8">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title text-primary">
                  <strong>Ship Particular</strong>
                </h4>
              </template>
              <template v-slot:body>
                <b-form class="d-flex mb-3" @submit.prevent="getComponentStructList()">
                  <b-form-input
                    v-model="componentStruct.params.search"
                    id="fleet_name"
                    style="width: 200px;"
                    type="text"
                    class="mr-2"
                    placeholder="Search..."
                  ></b-form-input>
                    <v-select style="width: 200px;" label="name" :options="typeFleet" v-model="componentStruct.params.vehicleTypeId" :reduce="typeFleet => typeFleet.code"></v-select>
                  <b-button type="submit" variant="primary" class="ml-2"><i class="fa fa-search"></i></b-button>
                </b-form>
                <b-row>
                  <b-col cols="12">
                    <b-alert
                      variant="success mb-3"
                      :show="structure_notification.show"
                      dismissible
                      fade
                      >{{ structure_notification.text }}</b-alert >
                    <vue-nestable v-model="componentStruct.data"  @change="structuresChanged">
                      <template slot-scope="{ item }">
                          <vue-nestable-handle :item="item">
                            <div class="d-flex align-items-center justify-content-between item-nestable p-1 pr-3 shadow-sm">
                              <div class="d-flex align-items-center">
                                <div class="border-right px-2 mr-1">
                                  <i class="fas fa-bars"></i>
                                </div>
                                <p class="text-sm m-0">{{ item.text }}</p>
                              </div>
                              <div class="d-flex align-items-center">
                                <small><span class="text-info ml-5 cursor-pointer" @click="getComponentStructDetail(item.id)"><i class="fa fa-edit" /></span></small>
                                <small><span class="text-danger ml-1 cursor-pointer" @click="onDeleteComponentStructure(item)"><i class="fa fa-trash" /></span></small>
                              </div>
                            </div>
                          </vue-nestable-handle>
                        </template>
                    </vue-nestable>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
          </b-col>
          <b-col sm="4">
            <iq-card
              :class="{
                'border border-danger shadow-lg': componentStruct.editMode,
              }"
            >
              <template v-slot:headerTitle>
                <h4 class="card-title text-primary"
                ><strong>{{ componentStruct.editMode ? 'Edit' : 'Add' }} Ship Particular</strong></h4
                >
              </template>
              <template v-slot:body>
                <b-form @submit.prevent="saveComponentStruct()">
                  <b-form-group label="Fleet Name" label-for="fleet">
                    <v-select v-model="componentStruct.form.vehicleTypeId" id="fleet" append-to-body  label="name" :options="typeFleet" :reduce="structureParent => structureParent.code"></v-select>
                  </b-form-group>
                  <b-form-group label="Ship Particular Name" label-for="project_location">
                    <b-form-input v-model="componentStruct.form.componentName"  id="Power Name" type="text" placeholder="Ship Particular Name" maxlength="30"></b-form-input>
                  </b-form-group>
                  <b-form-group label-for="Description" label="Description">
                    <b-form-textarea v-model="componentStruct.form.description"  id="notes" rows="3" placeholder="Description"></b-form-textarea>
                  </b-form-group>
                  <b-form-group label="Parent" label-for="parent">
                    <v-select v-model="componentStruct.form.parentId"  append-to-body :calculate-position="withPopper" label="text" :options="structureParent" :reduce="structureParent => structureParent.value" v-bind:class="{ disabled: true }"></v-select>
                  </b-form-group>
                  <b-form-group label="Set as Header (No Have Equipment Apply)" label-for="technical_module">
                    <b-form-checkbox v-model="componentStruct.form.haveEquipment" name="check-button" switch inline><template v-if="componentStruct.form.haveEquipment === false">No</template><template v-else>Yes</template></b-form-checkbox>
                  </b-form-group>
                  <hr />
                  <b-form-group class="text-right">
                    <b-button type="submit" variant="primary">
                      <span>{{ componentStruct.editMode ? 'Save' : 'Add'}}</span>
                    </b-button>
                    <b-button
                      @click="initFormComponentStruct()"
                      variant="danger"
                      class="iq-bg-danger ml-3"
                    >Cancel</b-button>
                  </b-form-group>
                </b-form>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </tab-content-item>
      <!-- End Ship Particular -->
      <!-- Component Parts -->
      <tab-content-item :active="false" id="parts">
        <b-row>
          <b-col sm="8">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title text-primary">
                  <strong>Component Parts - Equipment</strong>
                </h4>
              </template>
              <template v-slot:body>
                <b-form class="d-flex mb-3" @submit.prevent="getMasterEquipmentList()">
                    <b-form-input
                      id="fleet_name"
                      style="width: 200px;"
                      type="text"
                      placeholder="Search..."
                      v-model="masterEquipment.params.search"
                    ></b-form-input>
                    <b-button type="submit" variant="primary" class="ml-2"><i class="fa fa-search"></i></b-button>
                </b-form>
                <b-row>
                  <b-col cols="12">
                    <div class="table-responsive">
                      <table class="table mb-0 table-striped table-hover">
                        <thead class="thead-dark text-center">
                        <tr>
                          <!-- <th class="text-truncate text-left" >No.</th> -->
                          <th class="text-truncate text-left" >ID Equipment</th>
                          <th class="text-truncate text-left" >Equipment Name</th>
                          <th class="text-truncate text-left" >Component For</th>
                          <th class="text-truncate text-center" >Status</th>
                          <th class="text-truncate text-left" >Action</th>
                        </tr>
                        </thead>
                        <tbody v-if="masterEquipment.data && masterEquipment.data.length > 0">
                        <tr v-for="(each, i) in masterEquipment.data" :key="i">
                          <!-- <td>{{ getOverallIndex(i, 'masterEquipment')}}</td> -->
                          <td class="text-truncate">{{ each.equipmentId || '-' }}</td>
                          <td class="text-truncate">{{ each.equipmentName || '-' }}</td>
                          <!-- <td>{{ each.description || '-' }}</td> -->
                          <td class="text-truncate">
                            <template v-if=" each.vehicleTypes && each.vehicleTypes.length > 0">
                              <b-badge 
                            v-for="(vehicleData, j) in each.vehicleTypes" :key="j"
                            variant="secondary" class="mr-1">{{ vehicleData.name }}</b-badge>
                            </template>
                            <template  v-else>
                              <span>-</span>
                            </template>
                          </td>
                          <td class="text-truncate text-center"><b-badge :variant="each.active ? 'success' : 'danger'">{{ each.active ? 'Active' : 'Inactive' }}</b-badge> </td>
                          <td>
                            <span class="text-info mr-1 cursor-pointer" @click="initFormMasterEquipment(each)"><i class="fa fa-edit mr-2" /></span>
                            <span class="text-danger cursor-pointer" @click="onDeleteMasterEquipment(each)"><i class="fa fa-trash" /></span>
                          </td>
                        </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td class="text-center" colspan="8">No Data</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-col>
                  <b-col class="mt-4"  v-if="masterEquipment.data && masterEquipment.data.length > 0">
                    <b-pagination
                      v-model="masterEquipment.meta.currentPage"
                      :total-rows="masterEquipment.meta.total"
                      @change="getMasterEquipmentList"
                      :per-page="masterEquipment.meta.perPage"
                      first-number
                      align="center"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
          </b-col>
          <b-col sm="4">
            <iq-card
              :class="{
                'border border-danger shadow-lg': masterEquipment.editMode,
              }"
            >
              <template v-slot:headerTitle>
                <h4 class="card-title text-primary"
                ><strong>{{  masterEquipment.editMode ? 'Edit' : 'Add' }} Component Parts - Equipment</strong></h4
                >
              </template>
              <template v-slot:body>
                <b-form @submit.prevent="saveMasterEquipment()" @reset="initFormMasterEquipment()">
                  <b-form-group label="ID Equipment" label-for="project_location">
                    <b-form-input v-model="masterEquipment.form.equipmentId" id="Power Name" type="text" placeholder="ID Equipment" maxlength="30"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Equipment Name" label-for="project_location">
                    <b-form-input v-model="masterEquipment.form.equipmentName"  id="Power Name" type="text" placeholder="Equipment Name" maxlength="30"></b-form-input>
                  </b-form-group>
                  <b-form-group label-for="Description" label="Description">
                    <b-form-textarea  v-model="masterEquipment.form.description" id="notes" rows="3" placeholder="Description"></b-form-textarea>
                  </b-form-group>
                  <b-form-group label="Component For" label-for="parent">
                    <v-multiselect v-model="masterEquipment.form.vehicleTypes" ag-placeholder="Category" placeholder="Search or add a category" label="name" track-by="code" :options="typeFleet" :multiple="true" :taggable="true">
                    </v-multiselect>
                  </b-form-group>
                  <b-form-group label="Status" label-for="technical_module">
                    <b-form-checkbox v-model="masterEquipment.form.active"  name="check-button" switch inline><template v-if="masterEquipment.form.active === false">Inactive</template><template v-else>Active</template></b-form-checkbox>
                  </b-form-group>
                  <hr />
                  <b-form-group class="text-right">
                    <b-button type="submit" variant="primary">
                      <span>{{ masterEquipment.editMode ? 'Save' : 'Add'}}</span>
                    </b-button>
                    <b-button
                      type="reset"
                      variant="danger"
                      class="iq-bg-danger ml-3"
                    >Cancel</b-button>
                  </b-form-group>
                </b-form>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </tab-content-item>
      <!-- End Component Parts -->
      <!-- Job Class -->
      <tab-content-item :active="false" id="jobClass">
        <b-row>
          <b-col sm="8">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title text-primary"><strong>Job Class Data</strong></h4>
              </template>
              <template v-slot:body>
                <b-form class="d-flex mb-3" @submit.prevent="getJobClassList()">
                  <b-form-input
                    v-model="jobClass.params.search"
                    id="fleet_name"
                    style="width: 200px;"
                    type="text"
                    placeholder="Search..."
                  ></b-form-input>
                  <b-button type="submit" variant="primary" class="ml-2"><i class="fa fa-search"></i></b-button>
                </b-form>
                <b-row>
                  <b-col cols="12">
                    <div class="table-responsive">
                      <table class="table mb-0 table-striped table-hover">
                        <thead class="thead-dark text-center">
                        <tr>
                          <th class="text-truncate text-left" >No.</th>
                          <th class="text-truncate text-left" >Job Class Code</th>
                          <th class="text-truncate text-left" >Job Class</th>
                          <th class="text-truncate text-left" >Description</th>
                          <th class="text-truncate text-center" >Status</th>
                          <th class="text-truncate text-left" >Action</th>
                        </tr>
                        </thead>
                        <tbody v-if="jobClass.data && jobClass.data.length > 0">
                        <tr v-for="(each, i) in jobClass.data" :key="i">
                          <td class="text-truncate">{{ getOverallIndex(i, 'jobClass') }}</td>
                          <td class="text-truncate">{{ each.jobClassCode }}</td>
                          <td class="text-truncate">{{ each.jobClass }}</td>
                          <td class="text-truncate"><span v-if="each.description" v-html="each.description"></span> <span v-else>-</span></td>
                          <td class="text-truncate text-center"><b-badge :variant="each.active ? 'success' : 'danger'">{{ each.active ? 'Active' : 'Inactive' }}</b-badge> </td>
                          <td class="text-truncate">
                            <span class="text-info mr-1 cursor-pointer mr-2" @click="initFormJobClass(each)"><i class="fa fa-edit" /></span>
                            <span class="text-danger cursor-pointer" @click="onDeleteJobClass(each)"><i class="fa fa-trash" /></span>
                          </td>
                        </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td class="text-center" colspan="8">No Data</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-col>
                  <b-col class="mt-4" v-if="jobClass.data && jobClass.data.length > 0">
                    <b-pagination
                      v-model="jobClass.meta.currentPage"
                      :total-rows="jobClass.meta.total"
                      @change="getJobClassList"
                      :per-page="jobClass.meta.perPage"
                      first-number
                      align="center"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
          </b-col>
          <b-col sm="4">
            <iq-card
              :class="{
                'border border-danger shadow-lg': jobClass.editMode,
              }"
            >
              <template v-slot:headerTitle>
                <h4 class="card-title text-primary"
                ><strong>{{ jobClass.editMode ? 'Edit' : 'Add' }} Job Class</strong></h4
                >
              </template>
              <template v-slot:body>
                <b-form @submit.prevent="saveJobClass()" @reset.prevent="initFormJobClass()">
                  <b-form-group label="Job Class Code" label-for="project_location">
                    <b-form-input v-model="jobClass.form.jobClassCode"  id="Power Name" type="text" placeholder="Job Class Code" maxlength="30"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Job Class" label-for="project_location">
                    <b-form-input v-model="jobClass.form.jobClass"  id="Power Name" type="text" placeholder="Job Class" maxlength="30"></b-form-input>
                  </b-form-group>
                  <b-form-group label-for="Description" label="Description">
                    <tinymce id="d1" v-model="jobClass.form.description"></tinymce>
                  </b-form-group>
                  <b-form-group label="Status" label-for="technical_module">
                    <b-form-checkbox v-model="jobClass.form.active" name="check-button" switch inline><template v-if="jobType.form.active === false">Inactive</template><template v-else>Active</template></b-form-checkbox>
                  </b-form-group>
                  <hr />
                  <b-form-group class="text-right">
                    <b-button type="submit" variant="primary">
                      <span>{{ jobClass.editMode ? 'Save' : 'Add'}}</span>
                    </b-button>
                    <b-button
                      type="reset"
                      variant="danger"
                      class="iq-bg-danger ml-3"
                    >Cancel</b-button>
                  </b-form-group>
                </b-form>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </tab-content-item>
      <!-- End Job Class -->
      <!-- Job Type -->
      <tab-content-item :active="false" id="job">
        <b-row>
          <b-col sm="8">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title text-primary"><strong>Job Type / Job Index Data</strong></h4>
              </template>
              <template v-slot:body>
                <b-form class="d-flex mb-3" @submit.prevent="getJobTypeList()">
                  <b-form-input
                    v-model="jobType.params.search"
                    id="fleet_name"
                    style="width: 200px;"
                    type="text"
                    placeholder="Search..."
                  ></b-form-input>
                  <b-button type="submit" variant="primary" class="ml-2"><i class="fa fa-search"></i></b-button>
                </b-form>
                <b-row>
                  <b-col cols="12">
                    <div class="table-responsive">
                      <table class="table mb-0 table-striped table-hover">
                        <thead class="thead-dark text-center">
                        <tr>
                          <th class="text-truncate text-left" >No.</th>
                          <th class="text-truncate text-left" >Job Type Code</th>
                          <th class="text-truncate text-left" >Job Type / Job Index</th>
                          <th class="text-truncate text-left" >Description</th>
                          <th class="text-truncate text-left" >Status</th>
                          <th class="text-truncate text-left" >Action</th>
                        </tr>
                        </thead>
                        <tbody v-if="jobType.data && jobType.data.length > 0">
                          <tr v-for="(each, i) in jobType.data" :key="i">
                            <td class="text-truncate">{{ getOverallIndex(i, 'jobType')}}</td>
                            <td class="text-truncate">{{ each.jobTypeCode }}</td>
                            <td class="text-truncate">{{ each.jobTypeIndex }}</td>
                            <td class="text-truncate">{{ each.description }}</td>
                            <td class="text-truncate"><b-badge :variant="each.active ? 'success' : 'danger'">{{ each.active ? 'Active' : 'Inactive' }}</b-badge> </td>
                            <td class="text-truncate">
                              <span class="text-info mr-1 cursor-pointer" @click="initFormJobType(each)"><i class="fa fa-edit" /></span>
                              <span class="text-danger cursor-pointer" @click="onDeleteJobType(each)"><i class="fa fa-trash" /></span>
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td class="text-center" colspan="8">No Data</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-col>
                  <b-col class="mt-4" v-if="jobType.data && jobType.data.length > 0">
                    <b-pagination
                      v-model="jobType.meta.currentPage"
                      :total-rows="jobType.meta.total"
                      @change="getJobTypeList"
                      :per-page="jobType.meta.perPage"
                      first-number
                      align="center"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
          </b-col>
          <b-col sm="4">
            <iq-card
              :class="{
                'border border-danger shadow-lg': jobType.editMode,
              }"
            >
              <template v-slot:headerTitle>
                <h4 class="card-title text-primary"
                ><strong>{{ jobType.editMode ? 'Edit' : 'Add' }} Job Type / Job Index</strong></h4
                >
              </template>
              <template v-slot:body>
                <b-form @submit.prevent="saveJobType()" @reset.prevent="initFormJobType()">
                  <b-form-group label="Job Type Code" label-for="project_location">
                    <b-form-input v-model="jobType.form.jobTypeCode" id="Power Name" type="text" placeholder="Job Type Code" maxlength="30"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Job Type / Job Index" label-for="project_location">
                    <b-form-input v-model="jobType.form.jobTypeIndex" id="Power Name" type="text" placeholder="Job Type" maxlength="30"></b-form-input>
                  </b-form-group>
                  <b-form-group label-for="Description" label="Description">
                  <b-form-textarea
                    id="textarea"
                    v-model="jobType.form.description"
                    placeholder="Enter something..."
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                  </b-form-group>
                  <b-form-group label="Status" label-for="technical_module">
                    <b-form-checkbox
                    v-model="jobType.form.active" name="check-button" switch inline><template v-if="jobType.form.status === false">Inactive</template><template v-else>Active</template></b-form-checkbox>
                  </b-form-group>
                  <hr />
                  <b-form-group class="text-right">
                    <b-button type="submit" variant="primary">
                      <span>{{ jobType.editMode ? 'Save' : 'Add'}}</span>
                    </b-button>
                    <b-button
                      type="reset"
                      variant="danger"
                      class="iq-bg-danger ml-3"
                    >Cancel</b-button>
                  </b-form-group>
                </b-form>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </tab-content-item>
      <!-- End Job Type -->
    </div>
  </b-container>
</template>

<script>
import { createPopper } from '@popperjs/core'
import { maintenanceActions, fleetsActions } from '@src/Utils/helper'

export default {
  name: 'MaintenanceMasterEquipment',
  data () {
    return {
      rows: 100,
      perPage: 1,
      currentPage: 5,
      tinyOptions: {
        'height': 500
      },
      loading_export_structure: false,
      form_structure_edit_mode: false,
      noComponent: false,
      status: true,
      structure_notification: {
        show: false,
        text: '',
      },
      structureParent: [],
      nestableItems: [
        {
          id: 0,
          text: 'Acocommodation & Hull',
          children: [{
            id: 60,
            text: 'Tanks'
          }]
        }, {
          id: 1,
          text: 'Deck Equipment & Outfitting',
          children: [
            {
              'text': 'Mooring Equipment',
              'selected': true
            },
            {
              'text': 'Propeller',
              'selected': true
            },
            {
              'text': 'Winch & Windlass',
              'selected': true
            }
          ]
        }, {
          id: 2,
          text: 'Electrical Components & Parts'
        }, {
          id: 3,
          text: 'Engine & Other Machinery',
          'children': [
            {
              'text': 'Gear Box',
              'selected': true
            },
            {
              'text': 'Generator # Auxiliary Engine',
              'selected': true
            },
            {
              'text': 'Generator Emergency',
              'selected': true
            }
          ]
        }, {
          id: 4,
          text: 'Machinery Equipment',
          'children': [
            {
              'text': 'Main Engine',
              'selected': true
            }
          ]
        }, {
          id: 5,
          text: 'Fire & Safety Equipment',
          'children': [
            {
              'text': 'Safety Equipment',
              'selected': true
            }
          ]
        }, {
          id: 6,
          text: 'Navigation & Communication Equipment',
          'children': [
            {
              'text': 'Radio EQ',
              'selected': true
            }
          ]
        }, {
          id: 7,
          text: 'Pipes & Fitting'
        }, {
          id: 8,
          text: 'Pumps'
        }
      ],
      selectedFleetCategory: 'tugboat',
      fleetCategory: [
        {
          text: 'Tug Boat',
          color: 'success',
          value: 'tugboat'
        },
        {
          text: 'Barge',
          color: 'warning',
          value: 'barge'
        },
        {
          text: 'Mother Vessel',
          color: 'primary',
          value: 'mother_vessel'
        },
        {
          text: 'Heavy Equipment',
          color: 'primary',
          value: 'heavy_equipment'
        },
        {
          text: 'Pusher Tug',
          color: 'primary',
          value: 'pusher_tug'
        },
        {
          text: 'Pusher Barge',
          color: 'primary',
          value: 'pusher_barge'
        }
      ],
      typeFleet: [],
      jobType: {
        editMode: false,
        params : {
          search: '',
          perPage: 20,
          page: 1,
          export: true
        },
        data : [],
        form: {
          jobTypeCode: '',
          jobTypeIndex: '',
          description: '',
          active: true
        },
        detailData: null,
        meta : {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0,
        }
      },
      jobClass: {
        editMode: false,
        params : {
          search: '',
          perPage: 20,
          page: 1,
          export: true
        },
        data : [],
        form: {
          jobClassCode: '',
          jobClass: '',
          description: '',
          active: true
        },
        detailData: null,
        meta : {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0,
        }
      },
      masterEquipment: {
        editMode: false,
        params : {
          search: '',
          perPage: 25,
          page: 1,
          export: true
        },
        data : [],
        form: {
          equipmentId: '',
          equipmentName: '',
          description: '',
          vehicleTypes: [],
          active: true
        },
        detailData: null,
        meta : {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0,
        }
      },
      componentStruct: {
        editMode: false,
        params : {
          search: '',
          vehicleTypeId: 1
        },
        data : [],
        form: {
          parentId: null,
          componentName: '',
          description: '',
          vehicleTypeId: 1,
          haveEquipment: true
        },
        detailData: null
      }
    }
  },
  mounted() {
    this.getVehicleTypeData();
    this.getComponentStructList();
    this.getJobTypeList();
    this.getJobClassList();
    this.getMasterEquipmentList();
  },
  methods: {
    ...fleetsActions,
    ...maintenanceActions,
    withPopper (dropdownList, component, { width }) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      dropdownList.style.width = width

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1]
            }
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn ({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              )
            }
          }
        ]
      })

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy()
    },


    async getVehicleTypeData() {
      const res = await this.getFleetTypes()

        if (res.status == 'success' && res.data && res.data.length > 0) {
          res.data.forEach(element => {
            if(element.active) {
              this.typeFleet.push({
                name: element.name,
                color: element.name === 'Tugboat' ? 'success' : (element.name === 'Barge' ?'warning' :' primary'),
                code: element.id,
              })
            }
          });
        } else {
          this.$swal(
            `Oops!`,
            res.data,
            'error'
          )
        }
    },

    async getJobTypeList(page) {
      this.jobType.params.page = page || 1
      this.jobType.data = [];
      const res = await this.getJobType(this.jobType.params)

        if (res.status == 'success') {
          if(res.data.data && res.data.data.length > 0) {
            this.jobType.data = res.data.data;
            this.jobType.meta.perPage = res.data.perPage;
            this.jobType.meta.currentPage = res.data.currentPage;
            this.jobType.meta.total = res.data.total;
            this.jobType.meta.totalPage = res.data.totalPage;
          }
        } else {
          this.$swal(
            `Oops!`,
            res.data,
            'error'
          )
        }
    },

    async getJobClassList(page) {
      this.jobClass.params.page = page || 1
      this.jobClass.data = [];
      const res = await this.getJobClass(this.jobClass.params)

        if (res.status == 'success') {
          if(res.data.data && res.data.data.length > 0) {
            this.jobClass.data = res.data.data;
            this.jobClass.meta.perPage = res.data.perPage;
            this.jobClass.meta.currentPage = res.data.currentPage;
            this.jobClass.meta.total = res.data.total;
            this.jobClass.meta.totalPage = res.data.totalPage;
          }
        } else {
          this.$swal(
            `Oops!`,
            res.data,
            'error'
          )
        }
    },

    async getMasterEquipmentList(page) {
      this.masterEquipment.params.page = page || 1
      this.masterEquipment.data = [];
      const res = await this.getMasterEquipment(this.masterEquipment.params)

        if (res.status == 'success') {
          if(res.data.data && res.data.data.length > 0) {
            this.masterEquipment.data = res.data.data;
            this.masterEquipment.data.forEach(el => {
              let dataTemp = []
              if(el.vehicleTypes && el.vehicleTypes.length > 0) {
                el.vehicleTypes.forEach(el2 => {
                  dataTemp.push({
                    code : el2.id,
                    name : el2.name,
                  })
                });
                el.vehicleTypes = dataTemp;
              }
            })
            this.masterEquipment.meta.perPage = res.data.perPage;
            this.masterEquipment.meta.currentPage = res.data.currentPage;
            this.masterEquipment.meta.total = res.data.total;
            this.masterEquipment.meta.totalPage = res.data.totalPage;
          }
        } else {
          this.$swal(
            `Oops!`,
            res.data,
            'error'
          )
        }
    },

    async getComponentStructList() {
      this.componentStruct.data = [];
      this.structureParent = []
      const paramsQ = this.componentStruct.params;
      if (this.componentStruct.params.search === '' || this.componentStruct.params.search === null) {
        delete paramsQ.search
      }
      if (this.componentStruct.params.vehicleTypeId === '' || this.componentStruct.params.vehicleTypeId === null) {
        delete paramsQ.vehicleTypeId
      }
      const res = await this.getComponent(paramsQ)

        if (res.status == 'success') {
          if(res.data.maintenanceComponents && res.data.maintenanceComponents.length > 0) {
            const dataTemp = res.data.maintenanceComponents;
            dataTemp.forEach((s) => this.componentStruct.data .push(this.putStructureObject(s)))
          }
        } else {
          this.$swal(
            `Oops!`,
            res.data,
            'error'
          )
        }
    },

    async getComponentStructDetail(id) {
      this.componentStruct.detailData = null;
      const res = await this.getComponentById(id)

        if (res.status == 'success') {
          if(res.data) {
            this.componentStruct.detailData = res.data;
            this.initFormComponentStruct(this.componentStruct.detailData)
          }
        } else {
          this.$swal(
            `Oops!`,
            res.data,
            'error'
          )
        }
    },

    initFormJobType(data) {
      if (data) {
        this.jobType.editMode = true;
        this.jobType.detailData = data;
      } else {
        this.jobType.detailData = null
        this.jobType.editMode = false;
      }
      this.jobType.form = {
        jobTypeCode: this.jobType.editMode ? data.jobTypeCode : '',
        jobTypeIndex: this.jobType.editMode ? data.jobTypeIndex : '',
        description: this.jobType.editMode ? data.description : '',
        active: this.jobType.editMode ? data.active : true
      }
    },

    initFormJobClass(data) {
      if (data) {
        this.jobClass.editMode = true;
        this.jobClass.detailData = data;
      } else {
        this.jobClass.detailData = null
        this.jobClass.editMode = false;
      }
      this.jobClass.form = {
        jobClassCode: this.jobClass.editMode ? data.jobClassCode : '',
        jobClass: this.jobClass.editMode ? data.jobClass : '',
        description: this.jobClass.editMode ? data.description : '',
        active: this.jobClass.editMode ? data.active : true
      }
    },

    initFormMasterEquipment(data) {
      if (data) {
        this.masterEquipment.editMode = true;
        this.masterEquipment.detailData = data;
      } else {
        this.masterEquipment.detailData = null
        this.masterEquipment.editMode = false;
      }
      this.masterEquipment.form = {
        equipmentId: this.masterEquipment.editMode ? data.equipmentId : '',
        equipmentName: this.masterEquipment.editMode ? data.equipmentName : '',
        description: this.masterEquipment.editMode ? data.description : '',
        vehicleTypes: this.masterEquipment.editMode ? data.vehicleTypes : [],
        active: this.masterEquipment.editMode ? data.active : true
      }
    },

    initFormComponentStruct(data) {
      if (data) {
        this.componentStruct.editMode = true;
      } else {
        this.componentStruct.detailData = null
        this.componentStruct.editMode = false;
      }
      this.componentStruct.form = {
        vehicleTypeId: this.componentStruct.editMode ? data.vehicleType.id : 1,
        componentName: this.componentStruct.editMode ? data.componentName : '',
        description: this.componentStruct.editMode ? data.description : '',
        parentId: this.componentStruct.editMode ? data.parentId : null,
        haveEquipment: this.componentStruct.editMode ? data.haveEquipment : true
      }
    },

    async saveJobType() {
      this.$swal.fire({
          title: 'Processing...',
          allowEscapeKey: false,
          allowOutsideClick: false,
          onOpen: () => {
            swal.showLoading();
          }
        });


      if (this.jobType.editMode) {
        const res = await this.updateJobType({ id : this.jobType.detailData.id , data : this.jobType.form})
        if (res.status == 'success') {
          this.$swal('Update Job Type Success!', res.data.message, 'success');
          this.initFormJobType();
          this.getJobTypeList();
        } else {
          this.$swal(
            `Oops!`,
            res.data.message,
            'error'
          )
        }
      }else {
        const res = await this.createJobType(this.jobType.form)
        if (res.status == 'success') {
          this.$swal('Add Job Type Success!', res.data.message, 'success');
          this.initFormJobType();
          this.getJobTypeList();
        } else {
          this.$swal(
            `Oops!`,
            res.data.message,
            'error'
          )
        }
      }
    },

    async saveJobClass() {
      this.$swal.fire({
          title: 'Processing...',
          allowEscapeKey: false,
          allowOutsideClick: false,
          onOpen: () => {
            swal.showLoading();
          }
        });


      if (this.jobClass.editMode) {
        const res = await this.updateJobClass({ id : this.jobClass.detailData.id , data : this.jobClass.form})
        if (res.status == 'success') {
          this.$swal('Update Job Class Success!', res.data.message, 'success');
          this.initFormJobClass();
          this.getJobClassList();
        } else {
          this.$swal(
            `Oops!`,
            res.data.message,
            'error'
          )
        }
      }else {
        const res = await this.createJobClass(this.jobClass.form)
        if (res.status == 'success') {
          this.$swal('Add Job Class Success!', res.data.message, 'success');
          this.initFormJobClass();
          this.getJobClassList();
        } else {
          this.$swal(
            `Oops!`,
            res.data.message,
            'error'
          )
        }
      }
    },

    async saveMasterEquipment() {
      console.log(this.masterEquipment.form)
      this.$swal.fire({
          title: 'Processing...',
          allowEscapeKey: false,
          allowOutsideClick: false,
          onOpen: () => {
            swal.showLoading();
          }
        });

        if(this.masterEquipment.form.vehicleTypes && this.masterEquipment.form.vehicleTypes.length > 0) {
          let dataTemp = [];
          this.masterEquipment.form.vehicleTypes.forEach(el => {
            dataTemp.push({id: el.code})
          })
          this.masterEquipment.form.vehicleTypes = dataTemp;
        }


      if (this.masterEquipment.editMode) {
        const res = await this.updateMasterEquipment({ id : this.masterEquipment.detailData.id , data : this.masterEquipment.form})
        if (res.status == 'success') {
          this.$swal('Update Master Equipment Success!', res.data.message, 'success');
          this.initFormMasterEquipment();
          this.getMasterEquipmentList();
        } else {
          this.$swal(
            `Oops!`,
            res.data.message,
            'error'
          )
        }
      }else {
        const res = await this.createMasterEquipment(this.masterEquipment.form)
        if (res.status == 'success') {
          this.$swal('Add Master Equipment Success!', res.data.message, 'success');
          this.initFormMasterEquipment();
          this.getMasterEquipmentList();
        } else {
          this.$swal(
            `Oops!`,
            res.data.message,
            'error'
          )
        }
      }
    },

    async saveComponentStruct() {
      if (this.componentStruct.form.parentId) {
        this.componentStruct.form.parentId = Number(this.componentStruct.form.parentId)
      }
      this.$swal.fire({
          title: 'Processing...',
          allowEscapeKey: false,
          allowOutsideClick: false,
          onOpen: () => {
            swal.showLoading();
          }
        });

      if (this.componentStruct.editMode) {
        const res = await this.updateComponent({ id : this.componentStruct.detailData.id , data : this.componentStruct.form})
        if (res.status == 'success') {
          this.$swal('Update Component Success!', res.data.message, 'success');
          this.initFormComponentStruct();
          this.getComponentStructList();
        } else {
          this.$swal(
            `Oops!`,
            res.data.message,
            'error'
          )
        }
      }else {
        const res = await this.createComponent(this.componentStruct.form)
        if (res.status == 'success') {
          this.$swal('Add Component Success!', res.data.message, 'success');
          this.initFormComponentStruct();
          this.getComponentStructList();
        } else {
          this.$swal(
            `Oops!`,
            res.data.message,
            'error'
          )
        }
      }
    },


    onDeleteJobType(dataVal) {
      this.$swal({
        title: 'Delete Job Type?',
        text: `Job Type ${dataVal.jobTypeCode} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = await this.deleteJobTypeById(dataVal.id)
          if (res.status != 'success') {
            if (res.data.message != null)
              this.$swal('Error', res.data.message, 'error')
            else
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
          } else {
            this.getJobTypeList();
            this.$swal(
              `Job Type  deleted!`,
              `Job Type  ${dataVal.jobTypeCode} successfully deleted`,
              'success'
            )
          }
        }
      })
    },

    onDeleteJobClass(dataVal) {
      this.$swal({
        title: 'Delete Job Class?',
        text: `Job Class ${dataVal.jobClassCode} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = await this.deleteJobClassById(dataVal.id)
          if (res.status != 'success') {
            if (res.data.message != null)
              this.$swal('Error', res.data.message, 'error')
            else
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
          } else {
            this.getJobClassList();
            this.$swal(
              `Job Class deleted!`,
              `Job Class ${dataVal.jobClassCode} successfully deleted`,
              'success'
            )
          }
        }
      })
    },

    onDeleteMasterEquipment(dataVal) {
      this.$swal({
        title: 'Delete Master Equipment?',
        text: `Master Equipment ${dataVal.equipmentName} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = await this.deleteMasterById(dataVal.id)
          if (res.status != 'success') {
            if (res.data.message != null)
              this.$swal('Error', res.data.message, 'error')
            else
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
          } else {
            this.getMasterEquipmentList();
            this.$swal(
              `Master Equipment deleted!`,
              `Master Equipment ${dataVal.equipmentName} successfully deleted`,
              'success'
            )
          }
        }
      })
    },

    onDeleteComponentStructure(dataVal) {
      this.$swal({
        title: 'Delete Ship Particular?',
        text: `Ship Particular ${dataVal.text} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res = await this.deleteComponentById(dataVal.id)
          if (res.status != 'success') {
            if (res.data.message != null)
              this.$swal('Error', res.data.message, 'error')
            else
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
          } else {
            this.getComponentStructList();
            this.$swal(
              `Ship Particular deleted!`,
              `Ship Particular ${dataVal.text} successfully deleted`,
              'success'
            )
          }
        }
      })
    },

    getOverallIndex(index, type) {
      switch (type) {
        case 'jobType':
          return (this.jobType.meta.currentPage * this.jobType.meta.perPage) - this.jobType.meta.perPage + index + 1
          break;
        case 'jobClass':
          return (this.jobClass.meta.currentPage * this.jobClass.meta.perPage) - this.jobClass.meta.perPage + index + 1
          break;
        case 'masterEquipment':
          return (this.masterEquipment.meta.currentPage * this.masterEquipment.meta.perPage) - this.masterEquipment.meta.perPage + index + 1
          break;

        default:
          break;
      }
    },


    putStructureObject(object) {
      let children = []

      if (object.child.length > 0) {
        object.child.forEach((child) =>
          children.push(this.putStructureObject(child))
        )
      }

      if (this.structureParent.findIndex(val => val.value === object.component.id) === -1) {
        this.structureParent.push({value: object.component.id, text: object.component.componentName})
      }

      return {
        id: object.component.id,
        text: object.component.componentName,
        children,
      }
    },


    async structuresChanged(val, opt) {
      this.structure_notification.show = false
      let res = await this.saveComponentStructures({params: this.componentStruct.params, data : this.componentStruct.data})
      if (res.status == 'success') {
        this.structure_notification.text = `Ship Particular changed! Position of ${val.text} updated!`
      } else {
        if (res.data.message != null)
          this.structure_notification.text = `Error! ${res.data.message}!`
        else
          this.structure_notification.text = `Error! Something wrong with the server. Please try again later.`
      }
      this.structure_notification.show = true
    },
  }
}
</script>

<style scoped>
 table th{
  background: #049FB0 !important;
 }
 .text-sm{
  font-size: small;
 }
 .item-nestable{
  border-radius: 8px;
  background: #F9FAFB;
  color: gray !important;
 }
</style>
