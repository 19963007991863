<template>
  <div>
    <ApexChart
      :element="`chart-breakdown-${element}`"
      :chartOption="chartFleetAvailability"
    />
  </div>
</template>
<script>
export default {
  props: {
    series: {
      type: Array,
      default: () => {
        return []
      },
    },
    element: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      chartFleetAvailability: {
        chart: {
          height: 350,
          type: 'line',
          stacked: false,
        },
        colors: ['#FFA500', '#40A0FC', '#FF0000'],
        stroke: {
          width: [0, 0, 4],
        },
        plotOptions: {
          bar: {
            columnWidth: '90%',
            dataLabels: {
              position: 'center', // Set position to 'top'
              hideOverflowingLabels: true,
              total: {
                enabled: false,
                offsetX: 0,
                offsetY: 0,
                style: {
                  color: '#373d3f',
                }
              }
            },
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '13px',
          },
        },
        legend: {
          show: true,
        },
        series: this.series,
        xaxis: {
          categories: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ],
        },
        yaxis: {
          labels: {
            formatter: (val) => {
              return val + '%' // memformat label sumbu y menjadi persentase
            },
          },
          max: 100, // set nilai maksimum pada sumbu y
          tickAmount: 5,
          axisBorder: {
            show: true,
            color: '#000000', // Warna garis y-axis
          },
          axisTicks: {
            show: true,
            color: '#000000', // Warna ticks y-axis
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + '%'
            },
          },
        },
        fill: {
          type: 'gradient', // Menambahkan fill tipe gradient
          gradient: {
            shade: 'light',
            type: 'vertical', // Gradasi vertikal
            shadeIntensity: 0.5,
            gradientToColors: ['#DB8E01', '#1235C5'], // Warna akhir gradasi
            inverseColors: false,
            opacityFrom: 0.85,
            opacityTo: 0.95,
            stops: [0, 100], // Titik awal dan akhir gradasi
          },
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
      },
    }
  },
}
</script>
