<template>
  <b-container fluid>
    <div class="mb-3">
      <b-button
        variant="primary"
        class="d-inline-block"
        type="button"
        @click="$router.go(-1)"
      >
        <i class="fa fa-arrow-left"></i>
        <span>Kembali</span>
      </b-button>
    </div>
    <b-row>
      <b-col lg="4">
        <form action="#" method="post" @submit="onSaveDataDiri">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">UPDATE DATA {{ $route.query.user === 'crew' ? "CREW" : "CANDIDATE" }} </h4>
            </template>
            <template v-slot:body>
              <b-form-group class="text-center">
                <div class="add-img-user profile-img-edit">
                  <b-img
                    class="profile-pic height-150 width-150"
                    fluid
                    :src="user.data_diri.photo ? user.data_diri.photo : user.data_diri.default_photo"
                    alt="profile-pic"
                  />
                  <input type="hidden" v-model="user.data_diri.photo" />
                  <div class="p-image">
                    <b-button
                      variant="none"
                      class="upload-button iq-bg-primary position-relative"
                    >
                      <input
                        type="file"
                        @change="previewImage"
                        class="h-100 position-absolute"
                        accept="image/*"
                        style="opacity: 0"
                      />
                      File Upload
                    </b-button>
                  </div>
                </div>
                <div class="img-extension mt-3">
                  <div class="d-inline-block align-items-center">
                    <span>Only</span>
                    <b-link href="javascript:void(0);">.jpg</b-link>
                    <b-link href="javascript:void(0);">.png</b-link>
                    <b-link href="javascript:void(0);">.jpeg</b-link>
                    <span>allowed</span>
                  </div>
                </div>
              </b-form-group>
              <b-alert :show="true" class="text-center text-white bg-primary">
                <div class="iq-alert-text">
                  <strong>DATA DIRI</strong>
                </div>
              </b-alert>
              <b-form-group label="Nama Lengkap" label-for="nama_lengkap">
                <b-form-input
                  id="nama_lengkap"
                  name="nama_lengkap"
                  placeholder="Type Text ..."
                  v-model="user.data_diri.full_name"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Nama Panggilan" label-for="nama_panggilan">
                <b-form-input
                  id="nama_panggilan"
                  name="nama_panggilan"
                  placeholder="Type Text ..."
                  v-model="user.data_diri.nick_name"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Nomor Induk Kependudukan (NIK)"
                label-for="nik"
              >
                <b-form-input
                  id="nik"
                  name="nik"
                  placeholder="Type Number ..."
                  v-model="user.data_diri.nik_ktp"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Nomor Kartu Keluarga (KK)"
                label-for="no_kk"
              >
                <b-form-input
                  id="no_kk"
                  name="no_kk"
                  placeholder="Type Number ..."
                  v-model="user.data_diri.no_kk"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Seaferer Code" label-for="seafererCode">
                <b-form-input
                  id="seafererCode"
                  name="seafererCode"
                  placeholder="Type Number ..."
                  v-model="user.data_diri.seaferer_code"
                  required
                ></b-form-input>
                <b-form-text
                  id="seafererCode-live-help"
                  class="text-danger"
                  v-if="error?.seaferer_code"
                  >*{{ error?.seaferer_code }}</b-form-text
                >
              </b-form-group>
              <b-form-group label="Seaman Book Number" label-for="seafererCode">
                <b-form-input
                  id="seamanBookNumber"
                  name="seamanBookNumber"
                  placeholder="Type Number ..."
                  v-model="user.data_diri.seaman_book_number"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Passport Number" label-for="passportNumber">
                <b-form-input
                  id="passportNumber"
                  name="passportNumber"
                  placeholder="Type Number ..."
                  v-model="user.data_diri.passport_number"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                label="Nomor Pokok Wajib Pajak (NPWP)"
                label-for="npwp"
              >
                <b-form-input
                  id="npwp"
                  name="npwp"
                  placeholder="Type Number ..."
                  v-model="user.data_diri.npwp_number"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                  label="Jabatan / Ijazah"
                  label-for="jabatan"
                >
                  <b-form-select
                    plain
                    v-model="user.data_diri.last_position"
                    :options="optionsPositions"
                    size="sm"
                    disabled
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Select Jabatan</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
             
              <b-row v-if="$route.query.user === 'crew'">
                <b-form-group
                  class="col-md-6"
                  label="Nama Kapal"
                  label-for="kapal"
                >
                  <b-form-select
                    plain
                    v-model="user.data_diri.vehicle_id"
                    :options="optionsVehicles"
                    size="sm"
                    disabled
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Select Nama Kapal</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                    label="Pelabuhan"
                    label-for="port_id"
                  >
                  <b-form-input
                    id="port_id"
                    name="port_id"
                    placeholder="Type text ..."
                    v-model="user.data_diri.port_id"
                    disabled
                  >
                  </b-form-input>
                </b-form-group>
              </b-row>
            
              <b-row>
                <b-form-group
                  class="col-md-6"
                  label="Tempat Lahir"
                  label-for="tempat_lahir"
                >
                  <b-form-input
                    id="tempat_lahir"
                    name="tempat_lahir"
                    placeholder="Type Text ..."
                    v-model="user.data_diri.place_of_birth"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  label="Tanggal Lahir"
                  label-for="tanggal_lahir"
                >
                  <b-form-input
                    type="date"
                    id="tanggal_lahir"
                    v-model="user.data_diri.date_of_birth"
                    required
                  ></b-form-input>
                  <small style="float: right"
                    >click <i class="fa fa-calendar"></i> to show
                  </small>
                </b-form-group>
              </b-row>
              <b-row>
                <b-form-group
                  class="col-md-6"
                  label="Golongan Darah"
                  label-for="golongan_darah"
                >
                  <b-form-select
                    plain
                    v-model="user.data_diri.golongan_darah_id"
                    :options="options_bloodtype"
                    size="sm"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Golongan Darah</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group class="col-md-6" label="Agama" label-for="Agama">
                  <b-form-select
                    plain
                    v-model="user.data_diri.religion_id"
                    :options="optionsAgama"
                    size="sm"
                    required
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Select Agama</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-row>
              <b-row>
                <b-form-group
                  class="col-md-6"
                  label="Status Pernikahan"
                  label-for="StatusPernikahan"
                >
                  <b-form-select
                    plain
                    v-model="user.data_diri.marital_status_id"
                    :options="optionsStatusPernikahan"
                    size="sm"
                    required
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Select Status</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group label="Kewarganegaraan" label-for="kewarganegaraan" class="col-md-6">
                  <b-form-select
                    plain
                    v-model="user.data_diri.citizenship"
                    size="sm"
                    required
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="''"
                        >Select Kewarganegaraan</b-form-select-option
                      >
                      <b-form-select-option value="WNI"
                        >Warga Negara Indonesia (WNI)</b-form-select-option
                      >
                      <b-form-select-option value="WNA"
                        >Warga Negara Asing (WNA)</b-form-select-option
                      >
                    </template>
                </b-form-select>
              </b-form-group>
              </b-row>
              <b-row>
                <b-form-group
                  class="col-md-6"
                  label="No. Tlp / Handphone"
                  label-for="no_handphone"
                >
                  <b-form-input
                    id="no_handphone"
                    name="no_handphone"
                    placeholder="Type Number ..."
                    v-model="user.data_diri.hp_number"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-md-6" label="Email" label-for="email">
                  <b-form-input
                    id="email"
                    name="email"
                    placeholder="Type Text ..."
                    v-model="user.data_diri.email"
                    required
                  ></b-form-input>
                  <b-form-text
                    id="email-live-help"
                    class="text-danger"
                    v-if="error?.email"
                    >*{{ error?.email }}</b-form-text
                  >
                </b-form-group>
              </b-row>
              <b-row>
                <b-form-group label="Status Pajak" label-for="ptkp_id" class="col-md-12">
                  <b-form-select
                    plain
                    v-model="user.data_diri.ptkp_id"
                    size="sm"
                    :options="options_status_pajak"
                    placeholder="Select Options..."
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Select status pajak...</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-row>
              <b-row>
                <b-form-group
                  class="col-md-12"
                  label="No BPJS Kesehatan"
                  label-for="bpjs_kes_number"
                >
                  <b-form-input
                    id="bpjs_kes_number"
                    name="bpjs_kes_number"
                    placeholder="Type Number ..."
                    v-model="user.data_diri.bpjs_kes_number"
                  ></b-form-input>
                </b-form-group>
              </b-row>

              <b-row>
                <b-form-group
                  class="col-md-12"
                  label="Recommendation By"
                  label-for="recommendation_by"
                >
                  <b-form-input
                    id="recommendation_by"
                    name="recommendation_by"
                    placeholder="Type Text ..."
                    v-model="user.data_diri.recommendation_by"
                  ></b-form-input>
                </b-form-group>
              </b-row>

              <b-row>
                <b-form-group
                  class="col-md-12"
                  label="No BPJS Ketenagakerjaan"
                  label-for="bpjs_tk_number"
                >
                  <b-form-input
                    id="bpjs_tk_number"
                    name="bpjs_tk_number"
                    placeholder="Type Number ..."
                    v-model="user.data_diri.bpjs_tk_number"
                  ></b-form-input>
                </b-form-group>
              </b-row>

              <b-row>
                <b-form-group  
                    class="col-md-12"
                    label="CV Upload"
                    label-for="cv_file">
                  <b-form-file
                    id="cv_file"
                    v-model="user.data_diri.cv_file"
                    accept="image/*,application/*"
                    :required="$route.query.user === 'candidate' && !user.cv_file"
                  ></b-form-file>
                  <small v-if="user?.cv_file" @click="handleOpenFilePreview(user?.cv_file_url)" class="text-primary" style="cursor: pointer;">
                    Open File
                  </small>
                  <br />
                  <small class="text-muted">
                    File Type * :
                    <b-badge variant="info"
                      >.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs |
                      .pdf</b-badge
                    >
                    <br />
                    File CapaKota * :
                    <b-badge variant="info">3 MB / File</b-badge>
                  </small>
                </b-form-group>
              </b-row>

              <b-alert :show="true" class="text-center text-white bg-primary">
                <div class="iq-alert-text">
                  <strong>PAKAIAN & SEPATU</strong>
                </div>
              </b-alert>

              <b-row>
                <b-form-group class="col-md-6" label="Tinggi Badan" label-for="height">
                  <b-form-input
                    id="height"
                    name="height"
                    placeholder="Type Text ..."
                    v-model="user.data_diri.height"

                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-md-6" label="Berat Badan" label-for="weight">
                  <b-form-input
                    id="weight"
                    name="weight"
                    placeholder="Type Text ..."
                    v-model="user.data_diri.weight"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  label="Wearpack"
                  label-for="wearpack"
                >
                  <b-form-select
                    plain
                    v-model="user.data_diri.wearpack"
                    :options="wearpackCrew"
                    size="sm"
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Select Wearpack</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group class="col-md-6" label="Helmet" label-for="helmet">
                  <b-form-input
                    id="helmet"
                    name="helmet"
                    placeholder="Type Text ..."
                    v-model="user.data_diri.helmet"

                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-md-6" label="Size of Shoes" label-for="size_of_shoes">
                  <b-form-input
                    id="size_of_shoes"
                    name="size_of_shoes"
                    placeholder="Type Text ..."
                    v-model="user.data_diri.size_of_shoes"

                  ></b-form-input>
                </b-form-group>
              </b-row>
              <b-alert :show="true" class="text-center text-white bg-primary">
                <div class="iq-alert-text">
                  <strong>AKUN BANK</strong>
                </div>
              </b-alert>
              <b-row>
                <b-form-group
                  class="col-md-6"
                  label="Atas Nama"
                  label-for="atas_nama"
                >
                  <b-form-input
                    id="atas_nama"
                    name="atas_nama"
                    placeholder="Type Text ..."
                    v-model="user.data_diri.rekening_name"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  label="Nama Bank"
                  label-for="nama_bank"
                >
                  <b-form-select
                    plain
                    v-model="user.data_diri.bank_id"
                    :options="optionsBank"
                    size="sm"
                    required
                  >
                    <template v-slot:first>
                      <b-form-select-option :value="null"
                        >Select Bank</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-row>
              <b-form-group label="Nomor Rekening" label-for="no_rek">
                <b-form-input
                  type="number"
                  id="no_rek"
                  name="no_rek"
                  placeholder="Type Number ..."
                  v-model="user.data_diri.rekening_number"
                  required
                ></b-form-input>
              </b-form-group>
              
              <b-alert :show="true" class="text-center text-white bg-primary" v-if="$route.query.user === 'crew'">
                <div class="iq-alert-text">
                  <strong>JOIN DATE</strong>
                </div>
              </b-alert>

              <b-row v-if="$route.query.user === 'crew'">
                <b-form-group label="Sign On" label-for="sign_on" class="col-md-6">
                <b-form-input
                  id="sign_on"
                  name="sign_on"
                  type="datetime-local"
                  v-model="user.data_diri.sign_on"
                ></b-form-input>
              </b-form-group>
              <b-form-group label="Join Onboard" label-for="join_onboard" class="col-md-6">
                <b-form-input
                  id="join_onboard"
                  name="join_onboard"
                  type="datetime-local"
                  v-model="user.data_diri.join_on_board"
                ></b-form-input>
              </b-form-group>
              </b-row>
              
              <b-button
                :disabled="saving_datadiri"
                block
                variant="success"
                type="submit"
                class="mt-3"
              >
                <span
                  v-if="saving_datadiri"
                  class="spinner-border spinner-border-sm"
                />
                <span v-else>Update Data Diri</span>
              </b-button>
            </template>
          </iq-card>
        </form>
      </b-col>

      <b-col lg="8">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">UPDATE ALAMAT</h4>
          </template>
          <template v-slot:body>
            <form action="#" method="post" @submit="onSaveAlamat">
              <fieldset class="form-group border p-3 mt-1">
                <legend class="w-auto text-primary" style="font-size: 12pt"
                  >ALAMAT KTP</legend
                >
                <FormInputAddressEdit
                  v-model="user.ktp_address"
                  :alamat="user.alamat[0]"
                />
              </fieldset>
              <fieldset class="form-group border p-3 mt-1">
                <legend class="w-auto text-primary" style="font-size: 12pt"
                  >ALAMAT DOMISILI</legend
                >
                <FormInputAddressEdit
                  v-model="user.domicile_address"
                  :checked="user._checked"
                  :alamat="user.alamat[1]"
                />
                <b-row>
                  <b-form-group class="col-md-12">
                    <b-form-checkbox
                      v-model="user._checked"
                      name="check-button"
                      switch
                    >
                      <small class="text-muted">
                        Alamat domisili sama dengan alamat ktp</small
                      >
                    </b-form-checkbox>
                  </b-form-group>
                </b-row>
              </fieldset>
              <b-button
                :disabled="saving_alamat"
                block
                variant="success"
                type="submit"
                class="my-3"
              >
                <span
                  v-if="saving_alamat"
                  class="spinner-border spinner-border-sm"
                />
                <span v-else>Update alamat</span>
              </b-button>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { crewingActions } from '@src/Utils/helper'
import moment from 'moment'
import FormInputAddressEdit from '@src/components/crewing/FormInputAddressEdit.vue'
import optionsData from '@src/core/mock/data'
import IqCard from '@/src/components/xray/cards/iq-card.vue'

export default {
  name: 'EditProfileUser',
  data() {
    return {
      ...optionsData,
      saving_datadiri: false,
      saving_alamat: false,

      options_status_pajak:[],
      options_bloodtype: [],
      optionsVehicles: [],
      optionsPositions: [],
      master_list_document: [],
      master_list_document_system: [],

      travel_document_options: [],
      coc_options: [],
      cop_options: [],
      education_options: [],
      //   masih kurang
      error: {
        email: '',
        seaferer_code: '',
      },
      //   User Data
      user: {
        _saving: false,
        _checked: false,
        cv_file:null,
        cv_file_url:null,

        data_diri: {
          photo: require('../../assets/images/user/11.png'),
          photo_file: null,
          cv_file:null,
          ptkp_id: null,
          full_name: '',
          nick_name: '',
          seaferer_code: '',
          hp_number: null,
          gender: '',
          seaman_book_number: '',
          npwp_number: '',
          no_kk: '',
          passport_number: '',
          place_of_birth: '',
          date_of_birth: null,
          age: 0,
          marital_status_id: null,
          citizenship: '',
          email: '',
          nik_ktp: '',
          last_position: null,
          golongan_darah_id: null,
          religion_id: null,
          recommendation_by: '',

          bank_id: null,
          rekening_name: null,
          rekening_number: null,

          height: null,
          weight: null,
          wearpack: null,
          size_of_shoes: null,
          helmet: null,

          vehicle_id: null,
          port_id: '',

          bpjs_tk_number: null,
          bpjs_kes_number: null,

          sign_on: null,
          join_on_board: null,
        },
        domicile_address: {
          provinsi: null,
          kabupaten: null,
          kecamatan: null,
          kelurahan: null,
          kode_pos: null,
          alamat: '',
          no_rumah: '',
        },
        ktp_address: {
          provinsi: null,
          kabupaten: null,
          kecamatan: null,
          kelurahan: null,
          kode_pos: null,
          alamat: '',
          no_rumah: '',
        },
        alamat: [
          {
            tipe_alamat: 'ktp',
            alamat: '',
            no_rumah: '',
            kecamatan: '',
            kelurahan: '',
            kabupaten: '',
            provinsi: '',
            kode_pos: '',
          },
          {
            tipe_alamat: 'domisili',
            alamat: '',
            no_rumah: '',
            kecamatan: '',
            kelurahan: '',
            kabupaten: '',
            provinsi: '',
            kode_pos: '',
          },
        ],
      },
    }
  },
  watch: {
    'user._checked': {
      handler(value) {
        if (value) {
          this.user.domicile_address.provinsi = this.user.ktp_address.provinsi
          this.user.domicile_address.kabupaten = this.user.ktp_address.kabupaten
          this.user.domicile_address.kecamatan = this.user.ktp_address.kecamatan
          this.user.domicile_address.kelurahan = this.user.ktp_address.kelurahan
          this.user.domicile_address.kode_pos = this.user.ktp_address.kode_pos
          this.user.domicile_address.alamat = this.user.ktp_address.alamat
          this.user.domicile_address.no_rumah = this.user.ktp_address.no_rumah
        } else {
          this.user.domicile_address.provinsi = null
          this.user.domicile_address.kabupaten = null
          this.user.domicile_address.kecamatan = null
          this.user.domicile_address.kelurahan = null
          this.user.domicile_address.kode_pos = 0
          this.user.domicile_address.alamat = ''
          this.user.domicile_address.no_rumah = ''
        }
      },
    },
    'user.data_diri.date_of_birth': {
      handler(value) {
        if (value) {
          let userAge = moment().diff(new Date(value), 'years')
          this.user.data_diri.age = userAge
        }
      },
    },
  },
  components: {
    FormInputAddressEdit,
    IqCard,
  },
  async mounted() {
    if (this.$route.query.user === 'crew') {
      await this.getProfileCrew()
    } else if (this.$route.query.user === 'candidate') {
      await this.fetchProfileCandidate()
    }

    this.fetchDocumentList()
    await this.fetchTravelDocumentList()
    await this.getCertificateCompetencyList()
    await this.getCertificateProficiencyList()
    await this.getEducationsList()
    await this.getPositionsList()
    await this.fetchKapal()
    await this.getMasterStatusPajak()
    await this.getListBloodtype()
  },

  methods: {
    ...crewingActions,
    formatCurrency(value) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      }).format(value)
    },
    previewImage: function (event) {
      const input = event.target
      if (input.files && input.files[0]) {
        const reader = new FileReader()
        this.user.data_diri.photo_file = input.files[0]
        reader.onload = (e) => {
          this.user.data_diri.photo = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      }
    },
    prefiewFiles: function (event, state) {
      const input = event.target
      if (input.files && input.files[0]) {
        state.file_label = input.files[0].name
        state.file = input.files[0]
      }
    },
    handleOpenFilePreview: function (url) {
      window.open(url, "_blank");
    },
    async getListBloodtype() {
      const { status, data } = await this.fetchLisBloodType()

      data?.data?.map((val) => {
        this.options_bloodtype.push({
          value:val.id,
          text:val.alias
        })
      })
    }, 
    async fetchKapal() {
      let params = {
        page: 1,
        active: true,
        perPage: 999,
        showAll:true
      }
      let res = await this.fetchVehicles(params)
      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.map((state) => {
            this.optionsVehicles.push({
              value: state.id,
              text: state.name,
            })
          })
        }
      }
    },
    async getMasterStatusPajak() {
      const { status, data } = await this.fetchMaterPajak()

      data?.data?.map((val) => {
        this.options_status_pajak.push({
          value:val.id,
          text:`${val.kode_desc} - ${val.kode}`
        })
      })
    }, 
    async getListPosition() {
      const { status, data } = await this.fetchPositionList()

      data?.map((val) => {
        this.master_list_position.push({
          value: val.id_position,
          text: val.name_position,
        })
      })

      this.list_position = data
    },

    async fetchDocumentList() {
      const { status, data } = await this.masterDocumentList()

      if (status !== 'success') {
        this.$swal(`Oops!`, `User candidate tidak ada.`, 'error')
        return
      }
    
      data?.data?.map((val) => {
        if (val.type === 'manual') {
          this.master_list_document.push({
            value: val.id,
            text: val.name,
          })
        }
        if (val.type === 'system') {
          this.master_list_document_system.push(val)
        }
      })
    }, 
    
    async fetchTravelDocumentList() {
      let params = {
        type: "manual",
				is_travel: 1,
      }

      const { status, data } = await this.masterDocumentList(params)

      data?.data?.map((val) => {
        this.travel_document_options.push({
          value: val.id,
          text: val.name,
        })
      })
    }, 
    
    async getCertificateCompetencyList() {
      let params = {
				type: "competency",
			};

      const { status, data } = await this.fetchCertificateList(params)

      data?.data?.map((val) => {
        this.coc_options.push({
          value: val.id,
          text: val.name,
        })
      })
    }, 
    
    async getPositionsList() {
      const { status, data } = await this.fetchPositionList()

      data?.map((val) => {
        this.optionsPositions.push({
          value: Number(val.id_position),
          text: val.name_position,
        })
      })
    }, 
    
    async getCertificateProficiencyList() {
      let params = {
				type: "proficiency",
			};

      const { status, data } = await this.fetchCertificateList(params)

      data?.data?.map((val) => {
        this.cop_options.push({
          value: val.id,
          text: val.name,
        })
      })
    },

    async getEducationsList() {
      const {data} = await this.fetchEducationList()

      data?.map((val) => {
        this.education_options.push({
          value: val.id,
          text: val.name,
        })
      })
    },

    async getProfileCrew() {
      if (this.$route.query.id) {
        let method = 'getCrewDetail'

        const { status, data } = await this[method](this.$route.query.id)

        if (status !== 'success') {
          this.$swal(`Oops!`, `Crew tidak ada.`, 'error')
          return
        }

        this.user.cv_file = data?.[0]?.personal_data?.cv_file ?? null,
        this.user.cv_file_url = data?.[0]?.personal_data?.cv_file_url ?? null,
        this.user.data_diri.photo = data?.[0]?.photo_url ?? require('../../assets/images/user/11.png'),
        this.user.data_diri.full_name = data?.[0]?.personal_data?.full_name ?? ''
        this.user.data_diri.nick_name = data?.[0]?.personal_data?.nick_name ?? ''
        this.user.data_diri.seaferer_code = data?.[0]?.personal_data?.seaferer_code ?? ''
        this.user.data_diri.hp_number = data?.[0]?.personal_data?.hp_number ?? ''
        this.user.data_diri.gender = data?.[0]?.personal_data?.gender ?? ''
        this.user.data_diri.seaman_book_number = data?.[0]?.personal_data?.seaman_book_number ?? ''
        this.user.data_diri.npwp_number = data?.[0]?.personal_data?.npwp_number ?? ''
        this.user.data_diri.recommendation_by = data?.[0]?.personal_data?.recommendation_by ?? ''
        
        this.user.data_diri.passport_number = data?.[0]?.personal_data?.passport_number ?? ''
        this.user.data_diri.place_of_birth = data?.[0]?.personal_data?.place_of_birth ?? ''
        this.user.data_diri.date_of_birth = data?.[0]?.personal_data?.date_of_birth ?? null
        this.user.data_diri.age = data?.[0]?.personal_data?.age ?? 0
        this.user.data_diri.religion = data?.[0]?.personal_data?.religion?.id ?? null
        this.user.data_diri.marital_status_id = data?.[0]?.personal_data?.marital_status?.id ?? null
        this.user.data_diri.citizenship = data?.[0]?.personal_data?.citizenship ?? ''
        this.user.data_diri.email = data?.[0]?.personal_data?.email ?? ''
        this.user.data_diri.nik_ktp = data?.[0]?.personal_data?.nik_ktp ?? ''
        this.user.data_diri.last_position = data?.[0]?.position_id ?? null
        this.user.data_diri.ptkp_id = data?.[0]?.personal_data?.ptkp_id ?? null

        this.user.data_diri.no_kk = data?.[0]?.personal_data?.no_kk ?? ''
        this.user.data_diri.vehicle_id = data?.[0]?.vehicle_id ?? null
        this.user.data_diri.port_id = data?.[0]?.port_id ?? null
        this.user.data_diri.golongan_darah_id = data?.[0]?.personal_data?.golongan_darah_id ?? null
        this.user.data_diri.religion_id = data?.[0]?.personal_data?.religion?.id ?? null
        this.user.data_diri.bpjs_kes_number = data?.[0]?.personal_data?.bpjs_kes_number ?? null
        this.user.data_diri.bpjs_tk_number = data?.[0]?.personal_data?.bpjs_tk_number ?? null

        this.user.data_diri.height = data?.[0]?.personal_data?.height ?? null
        this.user.data_diri.weight = data?.[0]?.personal_data?.weight ?? null
        this.user.data_diri.wearpack = data?.[0]?.personal_data?.wearpack ?? null
        this.user.data_diri.size_of_shoes = data?.[0]?.personal_data?.size_of_shoes ?? null
        this.user.data_diri.helmet = data?.[0]?.personal_data?.helmet ?? null

        this.user.data_diri.sign_on = data?.[0]?.sign_on ? moment(data?.[0]?.sign_on).format('YYYY-MM-DD HH:mm:ss') : null
        this.user.data_diri.join_on_board = data?.[0]?.join_on_board ? moment(data?.[0]?.join_on_board).format('YYYY-MM-DD HH:mm:ss') : null

        this.user.data_diri.bank_id = data?.[0]?.personal_data?.bank_id ?? null
        this.user.data_diri.rekening_name = data?.[0]?.personal_data?.rekening_name ?? null
        this.user.data_diri.rekening_number = data?.[0]?.personal_data?.rekening_number ?? null

        this.user.domicile_address.alamat = data?.[0]?.address?.[1]?.alamat
        this.user.alamat[1].provinsi = data?.[0]?.address?.[1]?.provinsi
        this.user.alamat[1].kabupaten = data?.[0]?.address?.[1]?.kabupaten
        this.user.alamat[1].kecamatan = data?.[0]?.address?.[1]?.kecamatan
        this.user.alamat[1].kelurahan = data?.[0]?.address?.[1]?.kelurahan
        this.user.domicile_address.kode_pos = data?.[0]?.address?.[1]?.kode_pos
        this.user.domicile_address.no_rumah = data?.[0]?.address?.[1]?.no_rumah

        this.user.ktp_address.alamat = data?.[0]?.address?.[0]?.alamat
        this.user.alamat[0].provinsi = data?.[0]?.address?.[0]?.provinsi
        this.user.alamat[0].kabupaten = data?.[0]?.address?.[0]?.kabupaten
        this.user.alamat[0].kecamatan = data?.[0]?.address?.[0]?.kecamatan
        this.user.alamat[0].kelurahan = data?.[0]?.address?.[0]?.kelurahan
        this.user.ktp_address.kode_pos = data?.[0]?.address?.[0]?.kode_pos
        this.user.ktp_address.no_rumah = data?.[0]?.address?.[0]?.no_rumah
      }
    },

    async fetchProfileCandidate() {
      if (this.$route.query.id) {
        let method = 'getProfileCandidate'

        const { status, data } = await this[method](this.$route.query.id)

        if (status !== 'success') {
          this.$swal(`Oops!`, `User candidate tidak ada.`, 'error')
          return
        }

        this.user.cv_file = data?.personal_data?.cv_file ?? null,
        this.user.cv_file_url = data?.personal_data?.cv_file_url ?? null,
        this.user.data_diri.photo = data?.personal_data?.photo_url ?? ''
        this.user.data_diri.full_name = data?.personal_data?.full_name ?? ''
        this.user.data_diri.nick_name = data?.personal_data?.nick_name ?? ''
        this.user.data_diri.seaferer_code = data?.personal_data?.seaferer_code ?? ''
        this.user.data_diri.hp_number = data?.personal_data?.hp_number ?? ''
        this.user.data_diri.gender = data?.personal_data?.gender ?? ''
        this.user.data_diri.seaman_book_number = data?.personal_data?.seaman_book_number ?? ''
        this.user.data_diri.passport_number = data?.personal_data?.passport_number ?? ''
        this.user.data_diri.place_of_birth = data?.personal_data?.place_of_birth ?? ''
        this.user.data_diri.date_of_birth = data?.personal_data?.date_of_birth ?? null
        this.user.data_diri.age = data?.personal_data?.age ?? 0
        this.user.data_diri.religion = data?.personal_data?.religion?.id ?? null
        this.user.data_diri.marital_status_id = data?.personal_data?.marital_status?.id ?? null
        this.user.data_diri.citizenship = data?.personal_data?.citizenship ?? ''
        this.user.data_diri.email = data?.personal_data?.email ?? ''
        this.user.data_diri.nik_ktp = data?.personal_data?.nik_ktp ?? ''
        this.user.data_diri.last_position = data?.personal_data?.position_id ?? null
        this.user.data_diri.vehicle_id = data?.vehicle_id ?? null
        this.user.data_diri.ptkp_id = data?.personal_data?.ptkp_id ?? null

        this.user.data_diri.no_kk = data?.personal_data?.no_kk ?? ''
        this.user.data_diri.port_id = data?.port_id ?? null
        this.user.data_diri.golongan_darah_id = data?.personal_data?.golongan_darah_id ?? null
        this.user.data_diri.religion_id = data?.personal_data?.religion?.id ?? null
        this.user.data_diri.bpjs_kes_number = data?.personal_data?.bpjs_kes_number ?? null
        this.user.data_diri.bpjs_tk_number = data?.personal_data?.bpjs_tk_number ?? null
        this.user.data_diri.npwp_number = data?.personal_data?.npwp_number ?? null

        this.user.data_diri.height = data?.personal_data?.height ?? null
        this.user.data_diri.weight = data?.personal_data?.weight ?? null
        this.user.data_diri.wearpack = data?.personal_data?.wearpack ?? null
        this.user.data_diri.size_of_shoes = data?.personal_data?.size_of_shoes ?? null
        this.user.data_diri.helmet = data?.personal_data?.helmet ?? null

        this.user.data_diri.sign_on = data?.sign_on ?? null
        this.user.data_diri.join_on_board = data?.join_on_board ?? null

        this.user.data_diri.bank_id = data?.personal_data?.bank_id ?? null
        this.user.data_diri.rekening_name = data?.personal_data?.rekening_name ?? null
        this.user.data_diri.rekening_number = data?.personal_data?.rekening_number ?? null

        this.user.domicile_address.alamat = data?.alamat_domisili?.[0]?.alamat
        this.user.alamat[1].provinsi = data?.alamat_domisili?.[0]?.provinsi
        this.user.alamat[1].kabupaten = data?.alamat_domisili?.[0]?.kabupaten
        this.user.alamat[1].kecamatan = data?.alamat_domisili?.[0]?.kecamatan
        this.user.alamat[1].kelurahan = data?.alamat_domisili?.[0]?.kelurahan
        this.user.domicile_address.kode_pos = data?.alamat_domisili?.[0]?.kode_pos
        this.user.domicile_address.no_rumah = data?.alamat_domisili?.[0]?.no_rumah

        this.user.ktp_address.alamat = data?.alamat_ktp?.[0]?.alamat
        this.user.alamat[0].provinsi = data?.alamat_ktp?.[0]?.provinsi
        this.user.alamat[0].kabupaten = data?.alamat_ktp?.[0]?.kabupaten
        this.user.alamat[0].kecamatan = data?.alamat_ktp?.[0]?.kecamatan
        this.user.alamat[0].kelurahan = data?.alamat_ktp?.[0]?.kelurahan
        this.user.ktp_address.kode_pos = data?.alamat_ktp?.[0]?.kode_pos
        this.user.ktp_address.no_rumah = data?.alamat_ktp?.[0]?.no_rumah

        this.user.ktp_address.kode_pos
        this.user.domicile_address.no_rumah
      }
    },

    async onSaveDataDiri(e) {
      e.preventDefault()

      if(this.$route.query.user === 'candidate' && !this.user.cv_file && !this.user.data_diri.cv_file){
        return this.$swal(`Oops!`, `CV upload harus diisi, silahkan cek form kembali.`, 'error')
      }

      this.saving_datadiri = true

      let method = 'updateDataDiri'
      let formData = new FormData()

      if(this.user.data_diri.photo_file){
        formData.append('photo', this.user.data_diri.photo_file)
      }
      
      if(this.user.data_diri.cv_file){
        formData.append('cv_file', this.user.data_diri.cv_file)
      }
      formData.append('tipe', this.$route.query.user)
      formData.append('full_name', this.user.data_diri.full_name)
      formData.append('ptkp_id', this.user.data_diri.ptkp_id)
      formData.append('nick_name', this.user.data_diri.nick_name)
      formData.append('seaferer_code', this.user.data_diri.seaferer_code)
      formData.append('hp_number', this.user.data_diri.hp_number)
      formData.append('gender', this.user.data_diri.gender)
      formData.append('seaman_book_number', this.user.data_diri.seaman_book_number)
      formData.append('passport_number', this.user.data_diri.passport_number)
      formData.append('npwp_number', this.user.data_diri.npwp_number)
      formData.append('place_of_birth', this.user.data_diri.place_of_birth)
      formData.append('citizenship', this.user.data_diri.citizenship)
      formData.append('email', this.user.data_diri.email)
      formData.append('nik_ktp', this.user.data_diri.nik_ktp)
      formData.append('no_kk', this.user.data_diri.no_kk)
      // formData.append('last_position', this.user.data_diri.last_position)

      // formData.append('vehicle_id', this.user.data_diri.vehicle_id)
      // formData.append('port_id', this.user.data_diri.port_id)
      formData.append('place_of_birth', this.user.data_diri.place_of_birth)
      formData.append('date_of_birth', this.user.data_diri.date_of_birth)
      // formData.append('age', this.user.data_diri.age)
      formData.append('religion_id', this.user.data_diri.religion_id)
      formData.append('marital_status_id', this.user.data_diri.marital_status_id)
      formData.append('golongan_darah_id', this.user.data_diri.golongan_darah_id)

      formData.append('bpjs_tk_number', this.user.data_diri.bpjs_tk_number)
      formData.append('bpjs_kes_number', this.user.data_diri.bpjs_kes_number)

      if(this.user.data_diri.recommendation_by) formData.append('recommendation_by', this.user.data_diri.recommendation_by)

      if(this.user.data_diri.sign_on) formData.append('sign_on', moment(new Date(this.user.data_diri.sign_on)).format('YYYY-MM-DD HH:mm:ss'))
      if(this.user.data_diri.join_on_board) {
        formData.append('join_on_board', moment(new Date(this.user.data_diri.join_on_board)).format('YYYY-MM-DD HH:mm:ss'))
        formData.append('join_on_board_time', moment(new Date(this.user.data_diri.join_on_board)).format('HH:mm:ss'))
      }

      formData.append('height', this.user.data_diri.height)
      formData.append('weight', this.user.data_diri.weight)
      formData.append('wearpack', this.user.data_diri.wearpack)
      formData.append('size_of_shoes', this.user.data_diri.size_of_shoes)
      formData.append('helmet', this.user.data_diri.helmet)

      formData.append('rekening_name', this.user.data_diri.rekening_name)
      formData.append('rekening_number', this.user.data_diri.rekening_number)
      formData.append('bank_id', this.user.data_diri.bank_id)

      const { status, data } = await this[method]({
        id: this.$route.query.id,
        formData,
      })

      if (status !== 'success') {
        this.saving_datadiri = false
        this.error.email = data?.email?.[0]
        this.error.seaferer_code = data?.seaferer_code?.[0]
        this.$swal(`Oops!`, `${data}`, 'error')
        return
      }

      if(this.$route.query.user === 'crew'){
        await this.getProfileCrew()
      }else {
        await this.fetchProfileCandidate()
      }

      this.saving_datadiri = false
      this.$swal(`Success!`, `Berhasil Update Data Diri.`, 'success')
    },

    async onSaveAlamat(e) {
      e.preventDefault()
      this.saving_alamat = true

      let method = 'updateAlamat'

      this.user.alamat[1].alamat = this.user.domicile_address.alamat
      this.user.alamat[1].provinsi = this.user.domicile_address.provinsi
      this.user.alamat[1].kabupaten = this.user.domicile_address.kabupaten
      this.user.alamat[1].kecamatan = this.user.domicile_address.kecamatan
      this.user.alamat[1].kelurahan = this.user.domicile_address.kelurahan
      this.user.alamat[1].kode_pos = this.user.domicile_address.kode_pos
      this.user.alamat[1].no_rumah = this.user.domicile_address.no_rumah

      this.user.alamat[0].alamat = this.user.ktp_address.alamat
      this.user.alamat[0].provinsi = this.user.ktp_address.provinsi
      this.user.alamat[0].kabupaten = this.user.ktp_address.kabupaten
      this.user.alamat[0].kecamatan = this.user.ktp_address.kecamatan
      this.user.alamat[0].kelurahan = this.user.ktp_address.kelurahan
      this.user.alamat[0].kode_pos = this.user.ktp_address.kode_pos
      this.user.alamat[0].no_rumah = this.user.ktp_address.no_rumah

      let formData = new FormData()

      formData.append('tipe', this.$route.query.user)

      this.user.alamat.forEach((item, index) => {
        for (const property in item) {
          formData.append(
            `alamat[${index}][${property}]`,
            item[property]
          )
        }
      })

      const { status, data } = await this[method]({
        id: this.$route.query.id,
        data: formData,
      })

      if (status !== 'success') {
        this.saving_alamat = false

        this.$swal(`Oops!`, `${data}.`, 'error')
        return
      }

      this.saving_alamat = false
      this.$swal(`Success!`, `Berhasil Update Alamat.`, 'success')
    },
  },
}
</script>
