<template>
  <b-row>
    <b-col md="12">
      <iq-card>
        <template v-slot:headerTitle>
            <h5 class="card-title text-primary"><strong>PLATFORM MODULE</strong></h5>
        </template>
        <!-- <template v-slot:headerAction>
          <b-button @click="openSlideForm('ADD')"  variant="primary" class="float-right mr-1" size="sm"><i class="fa fa-plus"></i> &nbsp; CREATE PLATFORM MODULE</b-button>
        </template> -->

        <!-- FILTER START -->
        <!-- <template>
          <b-row class="m-2">
            <b-col lg="2">
              <b-form-group label="All Transfer Type" label-for="movementType">
                <b-form-select plain class="mb-2" placeholder="Select Options..." v-model="list.params.movementType" name="movementType">
                  <b-form-select-option value="">Select options</b-form-select-option>
                  <b-form-select-option value="new">New</b-form-select-option>
                  <b-form-select-option value="rotate">Rotate</b-form-select-option>
                  <b-form-select-option value="reduce">Reduce</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>  
            <b-col lg="2">
              <b-form-group label="Status" label-for="status">
                <b-form-select plain class="mb-2" v-model="list.params.state">
                  <b-form-select-option value="">All</b-form-select-option>
                  <b-form-select-option value="active">Active</b-form-select-option>
                  <b-form-select-option value="request">Request</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col lg="3">
              <b-form-group label="Date" label-for="Join on Board">
                <date-picker
                  v-model="dateRange"
                  type="date"
                  range
                  placeholder="Select date range"
                ></date-picker>
              </b-form-group>
            </b-col>
            <b-col lg="2"></b-col>
            <b-col lg="2">
              <b-form-group label="Search PIC" label-for="stage_id" >
                <b-form-input placeholder="Search..." v-model="list.params.search" />
              </b-form-group>
            </b-col>
            <b-col lg="1">
              <b-form-group style label="Filter" label-for="button_search">
                <b-button variant="primary" class="mb-3 mr-1" @click="fetchListPlatformModule(1)"><i class="fa fa-search"></i></b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </template> -->

        <!-- LIST -->
        <template>
          <b-row class="m-2">
            <b-col md="12">
              <div class="d-flex w-100 overflow-auto ">
                <table class="table table-striped table-sm">
                  <thead>
                    <tr>
                      <th class="text-center">No</th>
                      <th class="text-center" style="width:150px">Alias</th>
                      <th class="text-center">Name</th>
                      <th class="text-center">Show ESS Mobile</th>
                      <th class="text-center">Request</th>
                      <th class="text-center">Approval</th>
                      <th class="text-center" v-if="!edit">Approval Users</th>
                      <th class="text-right">#</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(state, index) in list?.data" :key="index">
                      <th class="text-center">{{ index + 1 }}</th>
                      <td class="text-center">{{ state?.alias ?? '-' }}</td>
                      <td class="text-center">{{ state?.name ?? '-' }}</td>
                      <td :class="`text-center ${state?.showEssMobile ? 'text-success' : 'text-danger'}`" v-if="!edit">{{ state?.showEssMobile ? 'Yes' : "No" }}</td>
                      <td :class="`text-center`" v-if="edit">
                        <b-form-checkbox v-model="form.showEssMobile" name="check-button" switch> Active / Inactive </b-form-checkbox>
                      </td>
                      <td :class="`text-center ${state?.request ? 'text-success' : 'text-danger'}`" v-if="!edit">{{ state?.request ? 'Yes' : "No" }}</td> 
                      <td :class="`text-center`" v-if="edit">
                        <b-form-checkbox v-model="form.request" name="check-button" switch>Active / Inactive</b-form-checkbox>
                      </td>
                      <td :class="`text-center` "v-if="!edit">
                        <b-button variant="outline-primary" type="button" @click="openModal(state, true)" v-if="!state?.approval">No Approval</b-button>
                        <b-button variant="outline-primary" v-else type="button" @click="openModal(state, true)">Approval</b-button>
                      </td>
                      <td :class="`text-center`" v-if="!edit">
                        <div class="d-flex flex-wrap">
                           <b-badge v-for="(obj, objIndex) in state?.user" :key="objIndex" variant="primary" class=" mr-2 mb-2">{{ obj?.name }}</b-badge>
                        </div>
                      </td>
                      <td :class="`text-center`" v-if="edit">
                        <b-form-checkbox v-model="form.approval" name="check-button" switch>Active / Inactive</b-form-checkbox>
                      </td>
                      <td class="text-right">
                        <div class="d-flex flex-column align-items-end">
                          <i class="fa fa-pen text-primary mb-2" style="cursor:pointer" @click="onEdit(state)" v-if="!edit"></i>
                          <div class="d-flex flex-column  align-items-center justify-content-end" v-else>
                            <i class="fa fa-check text-success mb-2" style="cursor:pointer" @click="onUpdatePlatformModule(state)"></i>
                            <i class="fa fa-ban text-danger" style="cursor:pointer" @click="edit = !edit"></i>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="list.data?.length <= 0">
                      <td colspan="9" class="text-center"><strong class="text-muted">No data exists.</strong></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- <div class="mt-3" v-if="list.data.length > 0">
                <b-pagination
                  v-model="list.meta.currentPage"
                  :total-rows="list.meta.total"
                  :per-page="list.meta.perPage"
                  first-number
                  @change="fetchListPlatformModule"
                  align="center"
                />
              </div> -->
            </b-col>
          </b-row>
        </template>

        <!-- SLIDE - FORM PLATFORM MODULE -->
        <b-modal
          id="approval-modal"
          size="md"
          :title="`Approval`"
          cancel-title="Close"
          hide-footer
        >
          <template #default="{hide}">
            <b-row>
              <b-col lg="12">
                <b-form-group label="List Users" label-for="list_users">
                  <v-select 
                    v-model="form.approvalRules" 
                    multiple 
                    label="name"
                    :options="listUsers" 
                    :reduce="user => user.id"
                    placeholder="Select Users..."
                  />
                </b-form-group>
                <div class="d-flex w-100 border my-2" />
                <div class="d-flex w-100 align-items-center justify-content-end ">
                  <b-button variant="danger" type="button" class="mr-2" @click="openModal(null, false)">
                    <i class="fa fa-ban mr-2" /> Cancel
                  </b-button>
                  <b-button variant="success" type="button" @click="onStatusApproval">
                    <i class="fa fa-check mr-2" /> Approve
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </template>
        </b-modal>
        <!-- SLIDE - FORM PLATFORM MODULE  END -->
      </iq-card>
    </b-col>
  </b-row>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { fleetsActions, platformModuleActions, usersActions } from '@src/Utils/helper'

export default {
  name: 'PlatformModule',
  data() {
    return{
      action:'',
      slideAddPlatformModule: false,
      edit: false,
      dateRange:[],

      list:{
        loading:false,
        saving:false,
        params:{
          movementType:'', 
          search:'',
          state:''
        },
        data:[],
        meta:{}
      },

      id:null,

      form: {
        "showEssMobile": true,
        "request": true,
        "approval": false,
        "approvalRules": []
      },
      listUsers: []
    }

  },
  created(){
    this.fetchListPlatformModule = _.debounce(this.fetchListPlatformModule, 500)
  },
  async mounted () {
    await this.fetchListPlatformModule()
    await this.fetchUsers()
  },
  components:{},

  methods: {
    ...fleetsActions,
    ...usersActions,
    ...platformModuleActions,
    refreshData(){
      this.slideAddPlatformModule = !this.slideAddPlatformModule
      this.action = ''

      this.fetchListPlatformModule()
    },
    updateAction(newAction){
      this.action = newAction
    },
    onEdit(data){
      this.edit = !this.edit
      this.form = {
        "showEssMobile": data.showEssMobile,
        "request": data.request,
        "approval": data.approval,
        approvalRules: data.user?.length > 0 ? data.user.map(val => val.id) : []
      }
    },
    openModal(data, isOpen){
      this.id = data.id
      this.form = {
        ...this.form,
        "showEssMobile": data.showEssMobile,
        "request": data.request,
        "approval": data.approval,
        approvalRules: data.user?.length > 0 ? data.user.map(val => val.id) : []
      }

      if(isOpen){
        this.$nextTick(() => {
          this.$bvModal.show('approval-modal')
        })
      } else {
        this.$nextTick(() => {
          this.$bvModal.hide('approval-modal')
        })
      }
    },
    async fetchListPlatformModule() {
      let params = {
        ...this.list.params,
      }

      // Iterate over each property in params
      for (const prop in params) {
        if (params.hasOwnProperty(prop)) {
          // Check conditions for each property and delete if necessary
          if ((params[prop] === '') || (params[prop] === null)) {
            delete params[prop];
          }
        }
      }

      this.list.loading = true

      const {status, data} = await this.getListPlatformModule(params)

      if (status === 'success') {
        // this.list.meta.perPage = data.perPage
        // this.list.meta.currentPage = data.currentPage
        // this.list.meta.total = data.total
        // this.list.meta.totalPage = data.totalPage

        if (data.data.length > 0) {
          this.list.data = data.data.map(val => {
            val.edit = false

            return val
          })
        } else {
          this.list.data = []
        }
      }
      this.list.loading = false
    },

    async fetchUsers () {
      let res = await this.getUsers({
        page: 1,
        showAll: true,
        perPage: 10000
      })

      this.listUsers = res.data.data ? res.data.data.map(user => {
        return {
          id: user.id,
          name: user.userDetail?.name
        }
      }) : []
    },

    async onUpdatePlatformModule(data){
      let payload = {
        id:data.id ?? null,
        data: {
          ...this.form
        }
      }

      this.$swal({
          title: `Are you sure want to update it?`,
          text: "Yes, i'm sure.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: `Yes, update it!`
      }).then(async (result) => {
          if (result.isConfirmed) {
            const res = await this.updatePlatformModule(payload)

            if([400, 500, 404, 409, 401, 402].includes(res.status)){
              this.$swal(`Oops.`, res?.data?.message ?? 'Something went wrong.', 'error')
            } else {
              await this.fetchListPlatformModule()
              this.$swal(
                'Success!',
                `Success Update PLATFORM MODULE.`,
                'success'
              )
              this.edit = false
            }
          }
      })
    },

    // APPROVAL
    async onStatusApproval(){
      if(this.form.approvalRules.length <= 0) {
        return this.$swal('Oops.', 'Users Approval required. please select 1 at least.', 'warning')
      }
      let payload = {
        id:this.id ?? null,
        data: {
          ...this.form,
          approval:true
        }
      }

      this.$swal({
          title: `Are you sure want to approve it?`,
          text: "Yes, i'm sure.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: `Yes, approve it!`
      }).then(async (result) => {
          if (result.isConfirmed) {
            const res = await this.updatePlatformModule(payload)

            if([400, 500, 404, 409, 401, 402].includes(res.status)){
              this.$swal(`Oops.`, res?.data?.message ?? 'Something went wrong.', 'error')
            } else {
              await this.fetchListPlatformModule()
              this.$swal(
                'Success!',
                `Success Update PLATFORM MODULE.`,
                'success'
              )
              this.$nextTick(() => {
                this.$bvModal.hide('approval-modal')
              })
            }
          }
      })
    },
  }
}
</script>