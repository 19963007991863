var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Setup Component")])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', {
          staticClass: "p-3"
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "5"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "options": _vm.fleets,
            "placeholder": "Choose your fleet",
            "label": "fleet",
            "track-by": "fleet"
          },
          on: {
            "input": _vm.onChange
          },
          model: {
            value: _vm.selectedFleet,
            callback: function callback($$v) {
              _vm.selectedFleet = $$v;
            },
            expression: "selectedFleet"
          }
        })], 1), _vm.selectedFleet ? _c('b-col', {
          staticClass: "text-right",
          attrs: {
            "cols": "12",
            "md": "7"
          }
        }, [_c('b-button', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.clone_component",
            modifiers: {
              "clone_component": true
            }
          }],
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-clone"
        }), _vm._v(" Duplikasi Komponen Kapal")]), _c('b-modal', {
          attrs: {
            "id": "clone_component",
            "size": "xl",
            "title": "Duplikasi Komponen Kapal",
            "header-bg-variant": "light",
            "header-text-variant": "dark",
            "footer-bg-variant": "light",
            "footer-text-variant": "dark",
            "cancel-title": "Close",
            "hide-footer": ""
          }
        }, [_c('b-alert', {
          attrs: {
            "show": true,
            "variant": "primary"
          }
        }, [_c('div', {
          staticClass: "iq-alert-icon"
        }, [_c('i', {
          staticClass: "ri-alert-line"
        })]), _c('div', {
          staticClass: "iq-alert-text"
        }, [_c('b', [_vm._v("Perhatikan")]), _vm._v(" Pastikan kapal yang akan diduplikasi komponen nya sudah sesuai datanya")])]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Dari Komponen Kapal*",
            "label-for": "fleet"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "options": _vm.fleetsDuplication,
            "placeholder": "Choose your fleet",
            "label": "fleet",
            "track-by": "fleet"
          },
          on: {
            "input": _vm.onChangeDuplication
          },
          model: {
            value: _vm.selectedFleetDuplication,
            callback: function callback($$v) {
              _vm.selectedFleetDuplication = $$v;
            },
            expression: "selectedFleetDuplication"
          }
        })], 1)], 1)], 1), _vm.fleetDetailDataDuplication && _vm.selectedFleetDuplication && !_vm.isFetchingDuplication ? _c('b-col', {
          staticClass: "mb-4",
          attrs: {
            "lg": "12"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "lg": "12"
          }
        }, [_c('iq-card', {
          attrs: {
            "class-name": "iq-card-block iq-card-stretch iq-card-height"
          },
          scopedSlots: _vm._u([{
            key: "headerTitle",
            fn: function fn() {
              return [_c('h4', {
                staticClass: "card-title text-primary"
              }, [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.preview",
                  modifiers: {
                    "preview": true
                  }
                }],
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('strong', [_vm._v(_vm._s(_vm.fleetDetailDataDuplication.name) + " - Preview")])])])];
            },
            proxy: true
          }, {
            key: "headerAction",
            fn: function fn() {
              return [_c('a', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.preview",
                  modifiers: {
                    "preview": true
                  }
                }],
                staticClass: "text-primary float-right",
                staticStyle: {
                  "cursor": "pointer"
                },
                attrs: {
                  "role": "button"
                }
              }, [_c('i', {
                staticClass: "fa fa-eye"
              })])];
            },
            proxy: true
          }, {
            key: "body",
            fn: function fn() {
              return [_c('b-collapse', {
                staticClass: "mb-2",
                attrs: {
                  "id": "preview"
                }
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "2"
                }
              }, [_c('b-img', _vm._b({
                staticClass: "w-100",
                attrs: {
                  "id": "imgVehicle",
                  "src": _vm.fleetDetailDataDuplication.img ? _vm.fleetDetailDataDuplication.img.url == null || _vm.fleetDetailDataDuplication.img.url == '' ? _vm.fleetDetailDataDuplication.img.url : require('@src/assets/images/Km3FO09.png') : require('@src/assets/images/Km3FO09.png'),
                  "alt": "Foto Kapal"
                },
                on: {
                  "error": _vm.handleBrokenImage
                }
              }, 'b-img', {
                height: 144
              }, false))], 1), _c('b-col', {
                staticClass: "pl-3",
                attrs: {
                  "md": "5"
                }
              }, [_c('div', {
                staticClass: "table-responsive"
              }, [_c('table', {
                staticClass: "table table-borderless table-striped"
              }, [_c('tbody', [_c('tr', [_c('td', {
                attrs: {
                  "width": "35%"
                }
              }, [_vm._v("Code")]), _c('td', {
                attrs: {
                  "width": "65%"
                }
              }, [_c('strong', [_vm._v(_vm._s(_vm.fleetDetailDataDuplication.code))])])]), _c('tr', [_c('td', [_vm._v("Name of Fleet")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.fleetDetailDataDuplication.name))])])]), _c('tr', [_c('td', [_vm._v("Year & Build")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.fleetDetailDataDuplication.yearBuildCountry))])])]), _c('tr', [_c('td', [_vm._v("Flag")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.fleetDetailDataDuplication.flag))])])]), _c('tr', [_c('td', [_vm._v("Company")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.fleetDetailDataDuplication.company ? _vm.fleetDetailData.company.company : '-'))])])]), _c('tr', [_c('td', [_vm._v("Ownership")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.fleetDetailDataDuplication.ownership))])])])])])])]), _c('b-col', {
                attrs: {
                  "md": "5"
                }
              }, [_c('nav', {
                attrs: {
                  "aria-label": "breadcrumb "
                }
              }, [_c('ol', {
                staticClass: "breadcrumb bg-primary"
              }, [_c('li', {
                staticClass: "breadcrumb-item active text-white",
                attrs: {
                  "aria-current": "page"
                }
              }, [_vm._v("POWER")])])]), _vm.fleetDetailDataDuplication.powers && _vm.fleetDetailDataDuplication.powers.length > 0 ? _c('ol', {
                attrs: {
                  "type": "numbered"
                }
              }, _vm._l(_vm.fleetDetailDataDuplication.powers, function (each, i) {
                return _c('li', {
                  key: i
                }, [_vm._v(" " + _vm._s(each.powerType.name || '') + " - " + _vm._s(each.powerSeries.name || '') + " " + _vm._s(each.powerSeries.model || '') + " "), _c('span', {
                  staticClass: "pull-right"
                }, [_vm._v("Actual RH: "), _c('strong', [_vm._v(_vm._s(each.actualRunningHours || '-') + " RH")])])]);
              }), 0) : _c('ol', {
                attrs: {
                  "type": "numbered"
                }
              }, [_c('li', [_vm._v("No Data")])]), _c('nav', {
                attrs: {
                  "aria-label": "breadcrumb "
                }
              }, [_c('ol', {
                staticClass: "breadcrumb bg-primary"
              }, [_c('li', {
                staticClass: "breadcrumb-item active text-white",
                attrs: {
                  "aria-current": "page"
                }
              }, [_vm._v("ROB Type")])])]), _vm.fleetDetailDataDuplication.robs && _vm.fleetDetailDataDuplication.robs.length > 0 ? _c('ol', {
                attrs: {
                  "type": "numbered"
                }
              }, _vm._l(_vm.fleetDetailDataDuplication.robs, function (each, i) {
                return _c('li', {
                  key: i
                }, [_vm._v(" " + _vm._s(each.robType.name || '') + " - " + _vm._s(each.robSeries.name || '') + " "), _c('span', {
                  staticClass: "pull-right"
                }, [_vm._v("Current ROB: "), _c('strong', [_vm._v(_vm._s(each.actualRob || '-') + " TOB")])])]);
              }), 0) : _c('ol', {
                attrs: {
                  "type": "numbered"
                }
              }, [_c('li', [_vm._v("No Data")])])])], 1)], 1)];
            },
            proxy: true
          }], null, false, 2731533555)
        })], 1), _c('b-col', {
          attrs: {
            "lg": "4"
          }
        }, [_c('iq-card', {
          attrs: {
            "class-name": "iq-card-block iq-card-stretch iq-card-height"
          },
          scopedSlots: _vm._u([{
            key: "body",
            fn: function fn() {
              return [_c('b-row', [_vm.tree && _vm.tree.length > 0 ? _c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('v-jstree', {
                attrs: {
                  "data": _vm.tree,
                  "show-checkbox": "",
                  "allow-batch": "",
                  "whole-row": ""
                },
                on: {
                  "item-click": _vm.itemClickDuplication
                }
              })], 1) : _c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('b-alert', {
                attrs: {
                  "show": "",
                  "variant": "primary"
                }
              }, [_vm._v("No Data")])], 1)], 1)];
            },
            proxy: true
          }], null, false, 3664087517)
        })], 1), _c('b-col', {
          attrs: {
            "lg": "8"
          }
        }, [_c('iq-card', {
          attrs: {
            "class-name": "iq-card-block iq-card-stretch iq-card-height"
          },
          scopedSlots: _vm._u([{
            key: "body",
            fn: function fn() {
              return [_vm.componentIdDuplication ? _c('b-row', [_c('b-col', {
                staticClass: "flex-row flex-nowrap",
                attrs: {
                  "cols": "12"
                }
              }, [_vm.haveEquipmentDuplication === false ? [_c('b-alert', {
                attrs: {
                  "show": "",
                  "variant": "warning"
                }
              }, [_vm._v("This is parent component!")])] : _c('div', {
                staticClass: "responsive-table",
                staticStyle: {
                  "overflow-x": "auto"
                }
              }, [_c('table', {
                staticClass: "table table-striped table-borderless"
              }, [_c('thead', [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Equipment")]), _c('th', [_vm._v("Type")]), _c('th', [_vm._v("Serial No")]), _c('th', [_vm._v("Drawing No")]), _c('th', [_vm._v("Maker No")]), _c('th', [_vm._v("Maker")])])]), _c('tbody', [_vm.setupComponentDuplication.data && _vm.setupComponentDuplication.data.length > 0 ? _vm._l(_vm.setupComponentDuplication.data, function (each, i) {
                return _c('tr', {
                  key: i
                }, [_c('td', [_vm._v(_vm._s(i + 1))]), _c('td', [_c('span', {
                  staticClass: "cursor-pointer text-primary"
                }, [_vm._v(_vm._s(each.maintenanceMaster.equipmentName))])]), _c('td', [_vm._v(_vm._s(each.typeNo))]), _c('td', [_vm._v(_vm._s(each.serialNo))]), _c('td', [_vm._v(_vm._s(each.drawingNo))]), _c('td', [_vm._v(_vm._s(each.makerNo))]), _c('td', [_vm._v(_vm._s(each.maker))])]);
              }) : _vm.setupComponentDuplication.data.length === 0 && _vm.setupComponentDuplication._loading ? _c('tr', [_c('td', {
                staticClass: "text-center",
                attrs: {
                  "colspan": "8"
                }
              }, [_c('b-spinner', {
                attrs: {
                  "type": "grow",
                  "label": "Spinning"
                }
              })], 1)]) : _c('tr', [_c('td', {
                attrs: {
                  "colspan": "9"
                }
              }, [_c('b-alert', {
                attrs: {
                  "show": "",
                  "variant": "warning"
                }
              }, [_vm._v("No Data")])], 1)])], 2)])]), _vm.setupComponentDuplication.data && _vm.setupComponentDuplication.data.length > 0 ? _c('div', [_c('b-pagination', {
                attrs: {
                  "total-rows": _vm.setupComponentDuplication.meta.total,
                  "per-page": _vm.setupComponentDuplication.meta.perPage,
                  "first-number": "",
                  "align": "center"
                },
                on: {
                  "change": _vm.fetchComponentSetupDuplication
                },
                model: {
                  value: _vm.setupComponentDuplication.meta.currentPage,
                  callback: function callback($$v) {
                    _vm.$set(_vm.setupComponentDuplication.meta, "currentPage", $$v);
                  },
                  expression: "setupComponentDuplication.meta.currentPage"
                }
              })], 1) : _vm._e(), _vm.setupComponentDuplication.detail ? _c('b-sidebar', {
                attrs: {
                  "id": "sidebar-view",
                  "no-header": "",
                  "width": "900px",
                  "backdrop-variant": "dark",
                  "right": "",
                  "backdrop": "",
                  "shadow": ""
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref) {
                    var hide = _ref.hide;
                    return [_c('div', {
                      staticClass: "p-3"
                    }, [_c('a', {
                      on: {
                        "click": hide
                      }
                    }, [_c('i', {
                      staticClass: "fa fa-times",
                      staticStyle: {
                        "cursor": "pointer"
                      }
                    })]), _vm._v(" "), _c('span', {
                      staticClass: "pull-right"
                    }, [_c('h5', {
                      attrs: {
                        "id": "sidebar-no-header-title"
                      }
                    }, [_vm._v(_vm._s(_vm.setupComponent.detail.maintenanceMaster.equipmentName))])]), _c('b-row', {
                      staticClass: "mt-4"
                    }, [_c('b-col', {
                      attrs: {
                        "cols": "10"
                      }
                    }, [_c('nav', {
                      attrs: {
                        "aria-label": "breadcrumb "
                      }
                    }, [_c('ol', {
                      staticClass: "breadcrumb bg-primary"
                    }, [_c('li', {
                      staticClass: "breadcrumb-item active text-white",
                      attrs: {
                        "aria-current": "page"
                      }
                    }, [_vm._v(_vm._s(_vm.setupComponent.detail.component.componentName) + " > "), _c('b-badge', {
                      attrs: {
                        "variant": "light"
                      }
                    }, [_vm._v(_vm._s(_vm.setupComponent.detail.maintenanceMaster.equipmentName))])], 1)])])]), _c('b-col', {
                      staticClass: "text-right pull-right",
                      attrs: {
                        "cols": "2"
                      }
                    }, [_c('b-dropdown', {
                      attrs: {
                        "size": "sm",
                        "text": "Small",
                        "variant": "light",
                        "right": ""
                      }
                    }, [_c('template', {
                      slot: "button-content"
                    }, [_c('i', {
                      staticClass: "fa fa-ellipsis-h"
                    })]), _c('b-dropdown-item', {
                      attrs: {
                        "variant": "primary"
                      },
                      on: {
                        "click": function click($event) {
                          return _vm.initForm(true);
                        }
                      }
                    }, [_c('i', {
                      staticClass: "fa fa-edit"
                    }), _vm._v(" Edit")]), _c('b-dropdown-item', {
                      attrs: {
                        "variant": "danger"
                      },
                      on: {
                        "click": function click($event) {
                          return _vm.onDelete(_vm.setupComponent.detail);
                        }
                      }
                    }, [_c('i', {
                      staticClass: "fa fa-trash"
                    }), _vm._v(" Delete")])], 2)], 1), _c('b-col', {
                      attrs: {
                        "cols": "12"
                      }
                    }, [_c('table', {
                      staticClass: "table table-striped"
                    }, [_c('tbody', [_c('tr', [_c('td', {
                      attrs: {
                        "width": "15%"
                      }
                    }, [_vm._v("Type No")]), _c('td', {
                      attrs: {
                        "width": "35%"
                      }
                    }, [_c('strong', [_vm._v(_vm._s(_vm.setupComponent.detail.typeNo || '-'))])]), _c('td', {
                      attrs: {
                        "width": "15%"
                      }
                    }, [_vm._v("Serial No")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.setupComponent.detail.serialNo || '-'))])])]), _c('tr', [_c('td', {
                      attrs: {
                        "width": "15%"
                      }
                    }, [_vm._v("Maker No")]), _c('td', {
                      attrs: {
                        "width": "35%"
                      }
                    }, [_c('strong', [_vm._v(_vm._s(_vm.setupComponent.detail.makerNo || '-'))])]), _c('td', {
                      attrs: {
                        "width": "15%"
                      }
                    }, [_vm._v("Maker")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.setupComponent.detail.maker || '-'))])])]), _c('tr', [_c('td', {
                      attrs: {
                        "width": "15%"
                      }
                    }, [_vm._v("Drawing No")]), _c('td', {
                      attrs: {
                        "width": "35%"
                      }
                    }, [_c('strong', [_vm._v(_vm._s(_vm.setupComponent.detail.drawingNo || '-'))])]), _c('td', {
                      attrs: {
                        "width": "15%"
                      }
                    }, [_vm._v("Descriptions")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.setupComponent.detail.description || '-'))])])]), _c('tr', [_c('td', {
                      attrs: {
                        "width": "15%"
                      }
                    }, [_vm._v("Additional Information")]), _c('td', {
                      attrs: {
                        "width": "35%"
                      }
                    }, [_c('strong', [_vm._v(_vm._s(_vm.setupComponent.detail.additionalInfo || '-'))])]), _c('td', {
                      attrs: {
                        "width": "15%"
                      }
                    }, [_vm._v("Certificate")]), _c('td', [_vm.setupComponent.detail.certificateVehicle && _vm.setupComponent.detail.certificateVehicle.length > 0 ? _vm._l(_vm.setupComponent.detail.certificateVehicle, function (each, i) {
                      return _c('strong', {
                        key: i
                      }, [_vm._v(_vm._s(each))]);
                    }) : _c('strong', [_vm._v("-")])], 2)])])])]), _c('b-col', {
                      staticClass: "mb-3",
                      attrs: {
                        "md": "12"
                      }
                    }, [_c('hr'), _c('h5', {
                      staticClass: "text-center"
                    }, [_c('strong', [_vm._v("Support Document")])])]), _c('b-col', {
                      staticClass: "mb-4",
                      attrs: {
                        "md": "12"
                      }
                    }, [_c('table', {
                      staticClass: "table mb-0 table-borderless table-box-shadow"
                    }, [_c('thead', [_c('tr', [_c('th', {
                      attrs: {
                        "width": "5%"
                      }
                    }, [_vm._v("No.")]), _c('th', {
                      attrs: {
                        "width": "50%"
                      }
                    }, [_vm._v("Name of File / Document")]), _c('th', {
                      attrs: {
                        "width": "35%"
                      }
                    }, [_vm._v("File / Document")])])]), _c('tbody', _vm._l(_vm.setupComponent.detail.maintenanceSetupDoc, function (each, i) {
                      return _c('tr', {
                        key: i
                      }, [_c('td', [_vm._v(_vm._s(i + 1))]), _c('td', [_vm._v(_vm._s(each.fileName))]), _c('td', [_c('b-button-group', [_c('a', {
                        directives: [{
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: {
                            "hover": true
                          }
                        }],
                        staticClass: "btn btn-primary",
                        attrs: {
                          "href": each.url,
                          "title": "Preview File",
                          "target": "_blank"
                        }
                      }, [_c('i', {
                        staticClass: "fa fa-eye"
                      })]), _c('a', {
                        directives: [{
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: {
                            "hover": true
                          }
                        }],
                        staticClass: "btn btn-dark",
                        attrs: {
                          "href": each.url,
                          "title": "Download File",
                          "download": ""
                        }
                      }, [_c('i', {
                        staticClass: "fa fa-download"
                      })])])], 1)]);
                    }), 0)])])], 1)], 1)];
                  }
                }], null, false, 2767954099),
                model: {
                  value: _vm.sideBarDetail,
                  callback: function callback($$v) {
                    _vm.sideBarDetail = $$v;
                  },
                  expression: "sideBarDetail"
                }
              }) : _vm._e()], 2)], 1) : _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-alert', {
                attrs: {
                  "show": "",
                  "variant": "warning"
                }
              }, [_vm._v("Please select component first!")])], 1)], 1)];
            },
            proxy: true
          }], null, false, 4195906149)
        })], 1)], 1)], 1) : _vm._e(), _vm.isFetchingDuplication ? _c('b-col', {
          attrs: {
            "lg": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex justify-content-center"
        }, [_c('span', {
          staticClass: "ml-3"
        }, [_vm._v("Loading...")]), _c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1)]) : _vm._e(), !_vm.fleetDetailDataDuplication && !_vm.selectedFleetDuplication && !_vm.isFetchingDuplication ? _c('b-col', {
          attrs: {
            "lg": "12"
          }
        }, [_c('b-alert', {
          attrs: {
            "show": "",
            "variant": "warning"
          }
        }, [_vm._v("Please select vehicle first!")])], 1) : _vm._e(), _c('b-col', {
          attrs: {
            "lg": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center justify-content-end"
        }, [_c('b-button', {
          staticClass: "mr-2",
          attrs: {
            "variant": "dark",
            "type": "button"
          },
          on: {
            "click": function click($event) {
              _vm.$nextTick(function () {
                return _vm.$bvModal.hide('clone_component');
              });
            }
          }
        }, [_vm._v(" Cancel ")]), _c('b-button', {
          attrs: {
            "variant": "primary",
            "type": "button",
            "disabled": _vm.savingDuplicate
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.onSaveDuplicate.apply(null, arguments);
            }
          }
        }, [_vm._v(" Save ")])], 1)])], 1)], 1) : _vm._e()], 1)];
      },
      proxy: true
    }])
  })], 1), _vm.fleetDetailData && _vm.selectedFleet && !_vm.isFetching ? _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.preview",
            modifiers: {
              "preview": true
            }
          }],
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('strong', [_vm._v(_vm._s(_vm.fleetDetailData.name) + " - Preview")])])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.preview",
            modifiers: {
              "preview": true
            }
          }],
          staticClass: "text-primary float-right",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-eye"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "preview"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-img', _vm._b({
          staticClass: "w-100",
          attrs: {
            "id": "imgVehicle",
            "src": _vm.fleetDetailData.img ? _vm.fleetDetailData.img.url == null || _vm.fleetDetailData.img.url == '' ? _vm.fleetDetailData.img.url : require('@src/assets/images/Km3FO09.png') : require('@src/assets/images/Km3FO09.png'),
            "alt": "Foto Kapal"
          },
          on: {
            "error": _vm.handleBrokenImage
          }
        }, 'b-img', {
          height: 144
        }, false))], 1), _c('b-col', {
          staticClass: "pl-3",
          attrs: {
            "md": "5"
          }
        }, [_c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-borderless table-striped"
        }, [_c('tbody', [_c('tr', [_c('td', {
          attrs: {
            "width": "35%"
          }
        }, [_vm._v("Code")]), _c('td', {
          attrs: {
            "width": "65%"
          }
        }, [_c('strong', [_vm._v(_vm._s(_vm.fleetDetailData.code))])])]), _c('tr', [_c('td', [_vm._v("Name of Fleet")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.fleetDetailData.name))])])]), _c('tr', [_c('td', [_vm._v("Year & Build")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.fleetDetailData.yearBuildCountry))])])]), _c('tr', [_c('td', [_vm._v("Flag")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.fleetDetailData.flag))])])]), _c('tr', [_c('td', [_vm._v("Company")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.fleetDetailData.company ? _vm.fleetDetailData.company.company : '-'))])])]), _c('tr', [_c('td', [_vm._v("Ownership")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.fleetDetailData.ownership))])])])])])])]), _c('b-col', {
          attrs: {
            "md": "5"
          }
        }, [_c('nav', {
          attrs: {
            "aria-label": "breadcrumb "
          }
        }, [_c('ol', {
          staticClass: "breadcrumb bg-primary"
        }, [_c('li', {
          staticClass: "breadcrumb-item active text-white",
          attrs: {
            "aria-current": "page"
          }
        }, [_vm._v("POWER")])])]), _vm.fleetDetailData.powers && _vm.fleetDetailData.powers.length > 0 ? _c('ol', {
          attrs: {
            "type": "numbered"
          }
        }, _vm._l(_vm.fleetDetailData.powers, function (each, i) {
          return _c('li', {
            key: i
          }, [_vm._v(" " + _vm._s(each.powerType.name || '') + " - " + _vm._s(each.powerSeries.name || '') + " " + _vm._s(each.powerSeries.model || '') + " "), _c('span', {
            staticClass: "pull-right"
          }, [_vm._v("Actual RH: "), _c('strong', [_vm._v(_vm._s(each.actualRunningHours || '-') + " RH")])])]);
        }), 0) : _c('ol', {
          attrs: {
            "type": "numbered"
          }
        }, [_c('li', [_vm._v("No Data")])]), _c('nav', {
          attrs: {
            "aria-label": "breadcrumb "
          }
        }, [_c('ol', {
          staticClass: "breadcrumb bg-primary"
        }, [_c('li', {
          staticClass: "breadcrumb-item active text-white",
          attrs: {
            "aria-current": "page"
          }
        }, [_vm._v("ROB Type")])])]), _vm.fleetDetailData.robs && _vm.fleetDetailData.robs.length > 0 ? _c('ol', {
          attrs: {
            "type": "numbered"
          }
        }, _vm._l(_vm.fleetDetailData.robs, function (each, i) {
          return _c('li', {
            key: i
          }, [_vm._v(" " + _vm._s(each.robType.name || '') + " - " + _vm._s(each.robSeries.name || '') + " "), _c('span', {
            staticClass: "pull-right"
          }, [_vm._v("Current ROB: "), _c('strong', [_vm._v(_vm._s(each.actualRob || '-') + " TOB")])])]);
        }), 0) : _c('ol', {
          attrs: {
            "type": "numbered"
          }
        }, [_c('li', [_vm._v("No Data")])])])], 1)], 1)];
      },
      proxy: true
    }], null, false, 1909178057)
  })], 1), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_vm.tree && _vm.tree.length > 0 ? _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('v-jstree', {
          attrs: {
            "data": _vm.tree,
            "show-checkbox": "",
            "allow-batch": "",
            "whole-row": ""
          },
          on: {
            "item-click": _vm.itemClick
          }
        })], 1) : _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('b-alert', {
          attrs: {
            "show": "",
            "variant": "primary"
          }
        }, [_vm._v("No Data")])], 1)], 1)];
      },
      proxy: true
    }], null, false, 228894727)
  })], 1), _c('b-col', {
    attrs: {
      "lg": "8"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_vm.componentId ? _c('b-row', [_c('b-col', {
          staticClass: "flex-row flex-nowrap",
          attrs: {
            "cols": "12"
          }
        }, [_vm.haveEquipment === false ? [_c('b-alert', {
          attrs: {
            "show": "",
            "variant": "warning"
          }
        }, [_vm._v("This is parent component!")])] : _c('div', {
          staticClass: "responsive-table",
          staticStyle: {
            "overflow-x": "auto"
          }
        }, [_c('table', {
          staticClass: "table table-striped table-borderless"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Equipment")]), _c('th', [_vm._v("Type")]), _c('th', [_vm._v("Serial No")]), _c('th', [_vm._v("Drawing No")]), _c('th', [_vm._v("Maker No")]), _c('th', [_vm._v("Maker")])])]), _c('tbody', [_vm.setupComponent.data && _vm.setupComponent.data.length > 0 ? _vm._l(_vm.setupComponent.data, function (each, i) {
          return _c('tr', {
            key: i
          }, [_c('td', [_vm._v(_vm._s(i + 1))]), _c('td', [_c('span', {
            staticClass: "cursor-pointer text-primary",
            on: {
              "click": function click($event) {
                return _vm.fetchComponentSetupDetail(each.id);
              }
            }
          }, [_vm._v(_vm._s(each.maintenanceMaster.equipmentName))])]), _c('td', [_vm._v(_vm._s(each.typeNo))]), _c('td', [_vm._v(_vm._s(each.serialNo))]), _c('td', [_vm._v(_vm._s(each.drawingNo))]), _c('td', [_vm._v(_vm._s(each.makerNo))]), _c('td', [_vm._v(_vm._s(each.maker))])]);
        }) : _vm.setupComponent.data.length === 0 && _vm.setupComponent._loading ? _c('tr', [_c('td', {
          staticClass: "text-center",
          attrs: {
            "colspan": "8"
          }
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1)]) : _c('tr', [_c('td', {
          attrs: {
            "colspan": "9"
          }
        }, [_c('b-alert', {
          attrs: {
            "show": "",
            "variant": "warning"
          }
        }, [_vm._v("No Data")])], 1)])], 2)])]), _vm.setupComponent.data && _vm.setupComponent.data.length > 0 ? _c('div', [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.setupComponent.meta.total,
            "per-page": _vm.setupComponent.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.fetchComponentSetup
          },
          model: {
            value: _vm.setupComponent.meta.currentPage,
            callback: function callback($$v) {
              _vm.$set(_vm.setupComponent.meta, "currentPage", $$v);
            },
            expression: "setupComponent.meta.currentPage"
          }
        })], 1) : _vm._e(), _vm.setupComponent.detail ? _c('b-sidebar', {
          attrs: {
            "id": "sidebar-view",
            "no-header": "",
            "width": "900px",
            "backdrop-variant": "dark",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var hide = _ref2.hide;
              return [_c('div', {
                staticClass: "p-3"
              }, [_c('a', {
                on: {
                  "click": hide
                }
              }, [_c('i', {
                staticClass: "fa fa-times",
                staticStyle: {
                  "cursor": "pointer"
                }
              })]), _vm._v(" "), _c('span', {
                staticClass: "pull-right"
              }, [_c('h5', {
                attrs: {
                  "id": "sidebar-no-header-title"
                }
              }, [_vm._v(_vm._s(_vm.setupComponent.detail.maintenanceMaster.equipmentName))])]), _c('b-row', {
                staticClass: "mt-4"
              }, [_c('b-col', {
                attrs: {
                  "cols": "10"
                }
              }, [_c('nav', {
                attrs: {
                  "aria-label": "breadcrumb "
                }
              }, [_c('ol', {
                staticClass: "breadcrumb bg-primary"
              }, [_c('li', {
                staticClass: "breadcrumb-item active text-white",
                attrs: {
                  "aria-current": "page"
                }
              }, [_vm._v(_vm._s(_vm.setupComponent.detail.component.componentName) + " > "), _c('b-badge', {
                attrs: {
                  "variant": "light"
                }
              }, [_vm._v(_vm._s(_vm.setupComponent.detail.maintenanceMaster.equipmentName))])], 1)])])]), _c('b-col', {
                staticClass: "text-right pull-right",
                attrs: {
                  "cols": "2"
                }
              }, [_c('b-dropdown', {
                attrs: {
                  "size": "sm",
                  "text": "Small",
                  "variant": "light",
                  "right": ""
                }
              }, [_c('template', {
                slot: "button-content"
              }, [_c('i', {
                staticClass: "fa fa-ellipsis-h"
              })]), _c('b-dropdown-item', {
                attrs: {
                  "variant": "primary"
                },
                on: {
                  "click": function click($event) {
                    return _vm.initForm(true);
                  }
                }
              }, [_c('i', {
                staticClass: "fa fa-edit"
              }), _vm._v(" Edit")]), _c('b-dropdown-item', {
                attrs: {
                  "variant": "danger"
                },
                on: {
                  "click": function click($event) {
                    return _vm.onDelete(_vm.setupComponent.detail);
                  }
                }
              }, [_c('i', {
                staticClass: "fa fa-trash"
              }), _vm._v(" Delete")])], 2)], 1), _c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Type No")]), _c('td', {
                attrs: {
                  "width": "35%"
                }
              }, [_c('strong', [_vm._v(_vm._s(_vm.setupComponent.detail.typeNo || '-'))])]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Serial No")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.setupComponent.detail.serialNo || '-'))])])]), _c('tr', [_c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Maker No")]), _c('td', {
                attrs: {
                  "width": "35%"
                }
              }, [_c('strong', [_vm._v(_vm._s(_vm.setupComponent.detail.makerNo || '-'))])]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Maker")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.setupComponent.detail.maker || '-'))])])]), _c('tr', [_c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Drawing No")]), _c('td', {
                attrs: {
                  "width": "35%"
                }
              }, [_c('strong', [_vm._v(_vm._s(_vm.setupComponent.detail.drawingNo || '-'))])]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Descriptions")]), _c('td', [_c('strong', [_vm._v(_vm._s(_vm.setupComponent.detail.description || '-'))])])]), _c('tr', [_c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Additional Information")]), _c('td', {
                attrs: {
                  "width": "35%"
                }
              }, [_c('strong', [_vm._v(_vm._s(_vm.setupComponent.detail.additionalInfo || '-'))])]), _c('td', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Certificate")]), _c('td', [_vm.setupComponent.detail.certificateVehicle && _vm.setupComponent.detail.certificateVehicle.length > 0 ? _vm._l(_vm.setupComponent.detail.certificateVehicle, function (each, i) {
                return _c('strong', {
                  key: i
                }, [_vm._v(_vm._s(each))]);
              }) : _c('strong', [_vm._v("-")])], 2)])])])]), _c('b-col', {
                staticClass: "mb-3",
                attrs: {
                  "md": "12"
                }
              }, [_c('hr'), _c('h5', {
                staticClass: "text-center"
              }, [_c('strong', [_vm._v("Support Document")])])]), _c('b-col', {
                staticClass: "mb-4",
                attrs: {
                  "md": "12"
                }
              }, [_c('table', {
                staticClass: "table mb-0 table-borderless table-box-shadow"
              }, [_c('thead', [_c('tr', [_c('th', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v("No.")]), _c('th', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Name of File / Document")]), _c('th', {
                attrs: {
                  "width": "35%"
                }
              }, [_vm._v("File / Document")])])]), _c('tbody', _vm._l(_vm.setupComponent.detail.maintenanceSetupDoc, function (each, i) {
                return _c('tr', {
                  key: i
                }, [_c('td', [_vm._v(_vm._s(i + 1))]), _c('td', [_vm._v(_vm._s(each.fileName))]), _c('td', [_c('b-button-group', [_c('a', {
                  directives: [{
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: {
                      "hover": true
                    }
                  }],
                  staticClass: "btn btn-primary",
                  attrs: {
                    "href": each.url,
                    "title": "Preview File",
                    "target": "_blank"
                  }
                }, [_c('i', {
                  staticClass: "fa fa-eye"
                })]), _c('a', {
                  directives: [{
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: {
                      "hover": true
                    }
                  }],
                  staticClass: "btn btn-dark",
                  attrs: {
                    "href": each.url,
                    "title": "Download File",
                    "download": ""
                  }
                }, [_c('i', {
                  staticClass: "fa fa-download"
                })])])], 1)]);
              }), 0)])])], 1)], 1)];
            }
          }], null, false, 2767954099),
          model: {
            value: _vm.sideBarDetail,
            callback: function callback($$v) {
              _vm.sideBarDetail = $$v;
            },
            expression: "sideBarDetail"
          }
        }) : _vm._e(), _vm.haveEquipment === true ? _c('b-button', {
          staticClass: "mt-2 mb-3",
          attrs: {
            "variant": "warning",
            "block": ""
          },
          on: {
            "click": function click($event) {
              return _vm.initForm();
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" Add Component Part / Equipment")]) : _vm._e(), _c('b-sidebar', {
          attrs: {
            "id": "add-component",
            "title": (_vm.isEdit ? 'Edit' : 'Add') + ' Component',
            "width": "900px",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var hide = _ref3.hide;
              return [_c('b-row', {
                staticClass: "pt-2 pl-3 pr-3 pb-2"
              }, [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Information Component Part / Equipment")]), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Component Part / Equipment:",
                  "label-for": "equipment"
                }
              }, [_c('v-select', {
                attrs: {
                  "label": "text",
                  "options": _vm.equipmentListFiltered,
                  "reduce": function reduce(equipmentListFiltered) {
                    return equipmentListFiltered.value;
                  }
                },
                model: {
                  value: _vm.formSetupComponent.maintenanceMasterId,
                  callback: function callback($$v) {
                    _vm.$set(_vm.formSetupComponent, "maintenanceMasterId", $$v);
                  },
                  expression: "formSetupComponent.maintenanceMasterId"
                }
              })], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Type No",
                  "label-for": "componentNo"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "placeholder": "Type",
                  "maxlength": "30"
                },
                model: {
                  value: _vm.formSetupComponent.typeNo,
                  callback: function callback($$v) {
                    _vm.$set(_vm.formSetupComponent, "typeNo", $$v);
                  },
                  expression: "formSetupComponent.typeNo"
                }
              })], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Serial No",
                  "label-for": "componentNo"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "placeholder": "Serial",
                  "maxlength": "30"
                },
                model: {
                  value: _vm.formSetupComponent.serialNo,
                  callback: function callback($$v) {
                    _vm.$set(_vm.formSetupComponent, "serialNo", $$v);
                  },
                  expression: "formSetupComponent.serialNo"
                }
              })], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Maker No",
                  "label-for": "componentNo"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "placeholder": "Maker No",
                  "maxlength": "30"
                },
                model: {
                  value: _vm.formSetupComponent.makerNo,
                  callback: function callback($$v) {
                    _vm.$set(_vm.formSetupComponent, "makerNo", $$v);
                  },
                  expression: "formSetupComponent.makerNo"
                }
              })], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Maker",
                  "label-for": "componentNo"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "placeholder": "Maker",
                  "maxlength": "30"
                },
                model: {
                  value: _vm.formSetupComponent.maker,
                  callback: function callback($$v) {
                    _vm.$set(_vm.formSetupComponent, "maker", $$v);
                  },
                  expression: "formSetupComponent.maker"
                }
              })], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Drawing No",
                  "label-for": "componentNo"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "placeholder": "Drawing",
                  "maxlength": "30"
                },
                model: {
                  value: _vm.formSetupComponent.drawingNo,
                  callback: function callback($$v) {
                    _vm.$set(_vm.formSetupComponent, "drawingNo", $$v);
                  },
                  expression: "formSetupComponent.drawingNo"
                }
              })], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label-for": "Description",
                  "label": "Description"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "notes",
                  "rows": "2",
                  "placeholder": "Description"
                },
                model: {
                  value: _vm.formSetupComponent.description,
                  callback: function callback($$v) {
                    _vm.$set(_vm.formSetupComponent, "description", $$v);
                  },
                  expression: "formSetupComponent.description"
                }
              })], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label-for": "Additional",
                  "label": "Additional Information"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "notes",
                  "rows": "2",
                  "placeholder": "Additional Information"
                },
                model: {
                  value: _vm.formSetupComponent.additionalInfo,
                  callback: function callback($$v) {
                    _vm.$set(_vm.formSetupComponent, "additionalInfo", $$v);
                  },
                  expression: "formSetupComponent.additionalInfo"
                }
              })], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Link to Certificate ?",
                  "label-for": "parent"
                }
              }, [_c('b-form-checkbox', {
                attrs: {
                  "name": "check-button",
                  "switch": "",
                  "inline": ""
                },
                model: {
                  value: _vm.formSetupComponent.haveCertificate,
                  callback: function callback($$v) {
                    _vm.$set(_vm.formSetupComponent, "haveCertificate", $$v);
                  },
                  expression: "formSetupComponent.haveCertificate"
                }
              }, [_vm.formSetupComponent.haveCertificate === false ? [_vm._v("No")] : [_vm._v("Yes")]], 2)], 1)], 1)], 1)], 1), _vm.formSetupComponent.haveCertificate === true ? _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Certificate Link")]), _c('table', {
                staticClass: "table mb-0 table-borderless table-box-shadow"
              }, [_c('thead', [_c('tr', [_c('th', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v("No.")]), _c('th', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Certificate List")]), _c('th', {
                attrs: {
                  "width": "10%"
                }
              }, [_vm._v("#")])])]), _c('tbody', _vm._l(_vm.formCertificateList.certificate, function (cert, index) {
                return _c('tr', {
                  key: index
                }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_c('v-select', {
                  attrs: {
                    "label": "text",
                    "options": _vm.certificateList,
                    "reduce": function reduce(certificateList) {
                      return certificateList.value;
                    }
                  },
                  model: {
                    value: cert.name,
                    callback: function callback($$v) {
                      _vm.$set(cert, "name", $$v);
                    },
                    expression: "cert.name"
                  }
                })], 1), _c('td', [_vm.formCertificateList.certificate.length > 1 ? [_c('b-button', {
                  staticClass: "mb-3 mr-1",
                  attrs: {
                    "size": "sm",
                    "variant": "danger"
                  },
                  on: {
                    "click": function click($event) {
                      $event.preventDefault();
                      return _vm.removeCertificateList(index);
                    }
                  }
                }, [_c('i', {
                  staticClass: "fa fa-trash"
                })])] : _vm._e()], 2)]);
              }), 0)]), _c('b-button', {
                staticClass: "mt-2 mb-3",
                attrs: {
                  "variant": "info",
                  "block": ""
                },
                on: {
                  "click": function click($event) {
                    $event.preventDefault();
                    return _vm.addCertificateList();
                  }
                }
              }, [_c('i', {
                staticClass: "fa fa-plus"
              }), _vm._v(" Add Certificate Link to this Component")])], 1) : _vm._e(), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Support Document")]), _c('table', {
                staticClass: "table mb-0 table-borderless table-box-shadow"
              }, [_c('thead', [_c('tr', [_c('th', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v("No.")]), _c('th', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("Name of File / Document")]), _c('th', {
                attrs: {
                  "width": "35%"
                }
              }, [_vm._v("File / Document")]), _c('th', {
                attrs: {
                  "width": "10%"
                }
              }, [_vm._v("#")])])]), _c('tbody', _vm._l(_vm.formFileSupportList.fileSupport, function (fileSupport, index) {
                return _c('tr', {
                  key: index
                }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_c('b-form-input', {
                  attrs: {
                    "type": "text",
                    "placeholder": "Name of File / Document Support"
                  },
                  model: {
                    value: fileSupport.fileName,
                    callback: function callback($$v) {
                      _vm.$set(fileSupport, "fileName", $$v);
                    },
                    expression: "fileSupport.fileName"
                  }
                })], 1), _c('td', [_c('b-form-file', {
                  attrs: {
                    "id": 'fileSupport' + index,
                    "placeholder": "Choose a file",
                    "accept": "application/pdf"
                  },
                  on: {
                    "change": function change($event) {
                      return _vm.uploadFileDoc($event, index);
                    }
                  }
                })], 1), _c('td', [_vm.formFileSupportList.fileSupport.length > 1 ? [_c('b-button', {
                  staticClass: "mb-3 mr-1",
                  attrs: {
                    "size": "sm",
                    "variant": "danger"
                  },
                  on: {
                    "click": function click($event) {
                      $event.preventDefault();
                      return _vm.removeFileSupportList(index);
                    }
                  }
                }, [_c('i', {
                  staticClass: "fa fa-trash"
                })])] : _vm._e()], 2)]);
              }), 0)]), _c('b-button', {
                staticClass: "mt-2 mb-3",
                attrs: {
                  "variant": "warning",
                  "block": ""
                },
                on: {
                  "click": function click($event) {
                    $event.preventDefault();
                    return _vm.addFileSupportList();
                  }
                }
              }, [_c('i', {
                staticClass: "fa fa-plus"
              }), _vm._v(" Add More File Support")])], 1)]), _c('b-col', {
                staticClass: "text-right",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-card-text', [_c('small', {
                staticClass: "text-muted"
              }, [_vm._v("Sign * : "), _c('b-badge', {
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("Mandatory (Must be Input)")])], 1)]), _c('b-button', {
                staticClass: "mr-2 text-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary",
                  "disabled": _vm.formFileSupportList.isUploading
                },
                on: {
                  "click": function click($event) {
                    return _vm.saveData();
                  }
                }
              }, [_vm._v("Submit")]), _c('b-button', {
                staticClass: "iq-bg-danger text-right",
                attrs: {
                  "type": "reset",
                  "variant": "none"
                },
                on: {
                  "click": hide
                }
              }, [_vm._v("Cancel")])], 1)], 1)];
            }
          }], null, false, 998661395),
          model: {
            value: _vm.isSidebarAddComponent,
            callback: function callback($$v) {
              _vm.isSidebarAddComponent = $$v;
            },
            expression: "isSidebarAddComponent"
          }
        })], 2)], 1) : _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-alert', {
          attrs: {
            "show": "",
            "variant": "warning"
          }
        }, [_vm._v("Please select component first!")])], 1)], 1)];
      },
      proxy: true
    }], null, false, 3028145228)
  })], 1)], 1)], 1) : _vm._e(), _vm.isFetching ? _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('span', {
    staticClass: "ml-3"
  }, [_vm._v("Loading...")]), _c('b-spinner', {
    attrs: {
      "type": "grow",
      "label": "Spinning"
    }
  })], 1)]) : _vm._e(), !_vm.fleetDetailData && !_vm.selectedFleet && !_vm.isFetching ? _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-alert', {
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v("Please select vehicle first!")])], 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }