<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">
              <strong>Inventory</strong>
            </h4>
          </template>
          <template v-slot:headerAction>
            <div class="d-flex align-items-center gap-4 float-right">
              <a class="text-primary mr-4" role="button" style="cursor: pointer" @click="onExportStock">
                <i class="fa fa-file-excel mr-2" />
                <span>Export Data</span>
              </a>
              <a class="text-primary mr-4" role="button" style="cursor: pointer" @click="openslideFormStock('ADD', null, 'TRANSFER')">
                <i class="fa fa-plus mr-2" />
                <span>Transfer</span>
              </a>
              <a class="text-primary mr-4" role="button" style="cursor: pointer"  @click="openslideFormStock('ADD', null, 'ADJUSTMENT')">
                <i class="fa fa-plus mr-2" />
                <span>Adjustment</span>
              </a>
            </div>
          </template>
          <template v-slot:body>
            <!-- FILTERING Stock -->
            <b-row class="mb-4">
              <b-col md="12" class="mb-4">
                <div class="w-100 d-flex border" />
              </b-col>
              <b-col md="3">
                <b-form-group label="Search" label-for="search">
                  <b-form-input
                    type="text"
                    placeholder="Search Code/Name..."
                    name="search"
                    :value="value" 
                    @input="$emit('input', $event.target.value)"
                  ></b-form-input>
                </b-form-group>
              </b-col>   
              <b-col md="4">
                <b-form-group label="Date" label-for="search">
                  <date-picker v-model="dateRange" type="date" range format="DD MMM YYYY" placeholder="Select date range"></date-picker>
                </b-form-group>
              </b-col> 
              <b-col md="1">
                <b-form-group label="Filter" label-for="button_search">
                  <b-button
                    @click="getListStock(1, dateRange)"
                    type="button"
                    variant="primary"
                    ><i class="fa fa-filter"></i
                  ></b-button>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- TABLE CONTENT -->
            <b-row>
              <b-col md="12">
                <div v-if="loading" class="alert alert-info">Loading...</div>
                <iq-card> 
                  <div class="overflow-auto d-flex" v-if="list?.data?.length > 0">
                    <table class="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th class="text-center" style="width: 20px">No</th>
                          <th class="text-center text-truncate">Image</th>
                          <th class="text-center text-truncate">Code</th>
                          <th class="text-center text-truncate" style="min-width: 200px">Name</th>
                          <th class="text-center text-truncate">Category</th>
                          <th class="text-center text-truncate">Stock Availability</th>
                          <th class="text-center" style="width: 20px">#</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(state, index) in list.data" :key="index">
                          <td>{{ index + 1 }}</td>
                          <td class="text-truncate">-</td>
                          <td class="text-truncate">{{ state?.code }}</td>
                          <td class="text-truncate">{{ state?.name }}</td>
                          <td class="text-truncate">{{ state?.category?.name }}</td>
                          <td class="text-center">{{ state?.stock_available ?? '-' }}</td>
                          <td >
                            <div class="d-flex align-items-center">
                              <i class="fa fa-eye text-primary" style="cursor: pointer;" @click="openSlidePreviewStock(state)" />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="mt-3" v-if="list?.data?.length > 0">
                    <b-pagination
                      v-model="page"
                      :total-rows="list?.meta?.totalPage"
                      @change="getListStock($event, dateRange)"
                      :per-page="list?.meta?.limit"
                      first-number
                      align="center"
                    ></b-pagination>
                  </div>
                  <p v-else class="text-center my-4">No Stock data found.</p>
                </iq-card>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>

      <b-col lg="12">
        <b-sidebar v-model="slideFormStock" id="form-stock" :title="`${action} STOCK ${type}`" width="100%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
          <template #default="{hide}">
            <b-row class="m-3">
              <b-col cols="12">
                <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 text-primary">Stock</legend>
                    <b-row class="mt-2">
                      <b-col md="12">
                        <FormStock 
                          :action="action" 
                          :type="type" 
                          :edit-id="editId" 
                          :closeSlide="closeslideFormStock" 
                          :refresh-data="refreshData" 
                          :optionsFleets="optionsFleets" 
                          :vehicle_type_id="vehicle_type_id" 
                        />
                      </b-col>
                    </b-row>
                </fieldset>
              </b-col>
            </b-row>
            </template>
        </b-sidebar>
      </b-col>

      <b-col lg="12">
        <b-sidebar v-model="slidePreviewStock" id="preview-stock" :title="`Detail Stock`" width="100%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
          <template #default="{hide}">
            <b-row class="m-3">
              <b-col cols="12">
                <DetailStock 
                  :detailData="detailData" 
                  :closeSlide="clodeSlideDetail" 
                />
              </b-col>
            </b-row>
          </template>
        </b-sidebar>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
  import _ from 'lodash'
  import { createPopper } from '@popperjs/core'
  import moment from 'moment'
  import { busdevActions, fleetsActions } from '@src/Utils/helper'
  import FormStock from './FormStock.vue'
  import DetailStock from './DetailStock.vue'
  import { saveAs } from 'file-saver'

  export default {
    name: 'TabStock',
    props:{
      value:{
        type: String,
      },
      optionsFleets:{
        type: Array,
        default: () => []
      },  
      vehicle_type_id:{
        type: Number,
        default: null
      },   
      getListStock:{
        type: Function,
        default: () => {}
      },    
      refreshMasterData:{
        type: Function,
        default: () => {}
      },   
      list:{
        type: Object,
        default: () => {}
      }, 
    },
    data() {
      return {
        exporting:false,
        loading:false,

        slidePreviewStock:false,
        slideFormStock:false,

        dateRange:[],
        page:1,
        detailData:null,

        action:'',
        type:'',
        editId:null,
      }
    },
    components:{
      FormStock,
      DetailStock,
    },
    computed: {
      addPermission(){
        let hasAccess = true
        // let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
        //   .find(obj => obj.menu === 'Apps').child
        //   .find(obj => obj.menu === 'Business Development').child
        //   .find(obj => obj.menu === 'Busdev Stock').add

        return hasAccess
      },
      updatePermission(){
        let hasAccess = true
        // let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
        //   .find(obj => obj.menu === 'Apps').child
        //   .find(obj => obj.menu === 'Business Development').child
        //   .find(obj => obj.menu === 'Busdev Stock').update

        return hasAccess
      },
      deletePermission(){
        let hasAccess = true
        // let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
        //   .find(obj => obj.menu === 'Apps').child
        //   .find(obj => obj.menu === 'Business Development').child
        //   .find(obj => obj.menu === 'Busdev Stock').delete

        return hasAccess
      },
    },
    async mounted() {
      this.setDefaultDate()

    },
    methods: {
      ...busdevActions,
      ...fleetsActions,
      setDefaultDate() {
        const currentDate = moment();

        let dayOfYear = moment().startOf('year');
        let substractedDayOfYear = dayOfYear.subtract(1, 'year')
     
        const startDate = currentDate.month() === 0 ? substractedDayOfYear : moment().startOf('year');
        const endDate = currentDate;

        const formattedStartDate = new Date(startDate);
        const formattedEndDate = new Date(endDate);

        this.dateRange = [formattedStartDate, formattedEndDate]
      },
      openSlidePreviewStock(data){
        this.detailData = data

        this.slidePreviewStock = true
        this.$root.$emit('bv::toggle::collapse', `preview-stock`)
      },
      clodeSlideDetail(){
        this.editId = null

        this.slidePreviewStock = false
        this.$root.$emit('bv::toggle::collapse', `preview-stock`)
      }, 
      openslideFormStock(action, id, type){
        this.action = action
        this.type = type
        this.editId = id || null

        this.slideFormStock = true
        this.$root.$emit('bv::toggle::collapse', `form-stock`)
      },
      closeslideFormStock(){
        this.action = ''
        this.editId = null

        this.slideFormStock = true
        this.$root.$emit('bv::toggle::collapse', `form-stock`)
      }, 
      refreshData(){
        this.slideFormStock = false
        this.$root.$emit('bv::toggle::collapse', `form-stock`)

        this.refreshMasterData()
      },
      
      async onExportStock() {
        this.exporting = true
        try {
          let params = {
          }

          let res = await this.exportVehicleStockData({params, vehicle_id: Number(this.$route.query.vehicleId)})

          if(res.status === 'success'){
            saveAs(res.data, 'List Stock.xlsx')
          }
        } catch (error) {
          this.$swal(
            'Failed Export!',
            'Something went wrong.',
            'error'
          ) 
        }

        this.exporting = false
      },

      async onDeleteStock(data){
        this.$swal({
            title: `Are you sure want to delete Stock Code ${data?.code} ?`,
            text: "Yes, i'm sure.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
            // const res = await this.deleteStock({id:data.id})
            // if(res.status === false || res.status === 400 || res.status === 500){
            //     this.$swal(
            //         'Failed!',
            //         'Something went wrong.',
            //         'error'
            //     )
            //     return 
            // } 
                await this.refreshMasterData()
                this.$swal(
                    'Success!',
                    'Success delete Materia Category.',
                    'success'
                )
            }
        })
      },
    }
  }
</script>