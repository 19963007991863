var render = function render(){
  var _vm$projectData, _vm$projectData2, _vm$projectData3, _vm$projectData3$flee, _vm$projectData4, _vm$projectData5, _vm$projectData6, _vm$projectData7, _vm$projectData8, _vm$projectData9, _vm$projectData10, _vm$projectData10$con, _vm$projectData$cargo, _vm$projectData11, _vm$projectData11$car, _vm$projectData$terms, _vm$projectData12, _vm$projectData12$ter, _vm$projectData13, _vm$projectData14, _vm$totalQuantityAdd, _vm$projectData15, _vm$projectData16, _vm$projectData17, _vm$projectData18, _vm$projectData19, _vm$projectData20, _vm$projectData21, _vm$projectData22, _vm$projectData23;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "m-3"
  }, [!_vm.loading ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [['ADD'].includes(_vm.action) ? _c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 h5 text-primary"
  }, [_vm._v("General Information "), _c('span', [_c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.generalInformation",
      modifiers: {
        "generalInformation": true
      }
    }],
    attrs: {
      "size": "sm",
      "variant": "primary"
    }
  }, [_c('i', {
    staticClass: "fa fa-eye pr-0"
  }), _vm._v(" Detail ")])], 1)]), _c('b-collapse', {
    attrs: {
      "id": "generalInformation"
    }
  }, [_c('b-row', {
    staticClass: "pl-3 pr-3"
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Project",
      "label-for": "project"
    }
  }, [_c('strong', [_vm._v(" AI Project - Transhipment ")])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Customer",
      "label-for": "customer"
    }
  }, [_c('strong', [_vm._v(" 123-412345-12323 - Dendy Gamer ")])])], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('hr'), _c('div', {
    staticClass: "d-flex flex-column overflow-auto"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('tbody', [_c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Fleets")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%",
      "colSpan": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "lg": "3"
    }
  }, [_c('span', [_vm._v("Fleet ownership")]), _vm._v(" "), _c('br'), _c('b-badge', {
    staticClass: "border border-dark text-dark text-uppercase mb-2",
    attrs: {
      "variant": "none"
    }
  }, [_vm._v(_vm._s(((_vm$projectData = _vm.projectData) === null || _vm$projectData === void 0 ? void 0 : _vm$projectData.fleet_ownership) === 'own-ship' ? "OWN SHIP" : 'CHARTERED'))])], 1), (_vm$projectData2 = _vm.projectData) !== null && _vm$projectData2 !== void 0 && _vm$projectData2.fleet_ownership ? _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('span', [_vm._v("Fleet Type")]), _vm._v(" "), _c('br'), _c('b-badge', {
    staticClass: "border border-dark text-dark text-uppercase mb-2",
    attrs: {
      "variant": "none"
    }
  }, [_vm._v(_vm._s((_vm$projectData3 = _vm.projectData) === null || _vm$projectData3 === void 0 ? void 0 : (_vm$projectData3$flee = _vm$projectData3.fleet_type) === null || _vm$projectData3$flee === void 0 ? void 0 : _vm$projectData3$flee.name))])], 1) : _vm._e(), (_vm$projectData4 = _vm.projectData) !== null && _vm$projectData4 !== void 0 && _vm$projectData4.fleet_type_id ? _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('span', [_vm._v("Fleets")]), _vm._v(" "), _c('br'), _c('div', {
    staticClass: "d-flex flex-warp"
  }, _vm._l((_vm$projectData5 = _vm.projectData) === null || _vm$projectData5 === void 0 ? void 0 : _vm$projectData5.fleets, function (objDetail, detailIndex) {
    return _c('b-badge', {
      key: detailIndex,
      staticClass: "border border-primary text-primary text-uppercase mb-2 mr-2",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v(_vm._s(objDetail === null || objDetail === void 0 ? void 0 : objDetail.vehicle_name))]);
  }), 1)]) : _vm._e()], 1)], 1)])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Periode Start")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('span', [_vm._v(_vm._s((_vm$projectData6 = _vm.projectData) !== null && _vm$projectData6 !== void 0 && _vm$projectData6.periode_start ? _vm.moment((_vm$projectData7 = _vm.projectData) === null || _vm$projectData7 === void 0 ? void 0 : _vm$projectData7.periode_start).format('DD MMMM YYYY') : '-'))])]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Periode End")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('span', [_vm._v(_vm._s((_vm$projectData8 = _vm.projectData) !== null && _vm$projectData8 !== void 0 && _vm$projectData8.periode_start ? _vm.moment((_vm$projectData9 = _vm.projectData) === null || _vm$projectData9 === void 0 ? void 0 : _vm$projectData9.periode_start).format('DD MMMM YYYY') : '-'))])])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Contract Type")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('span', [_vm._v(_vm._s((_vm$projectData10 = _vm.projectData) === null || _vm$projectData10 === void 0 ? void 0 : (_vm$projectData10$con = _vm$projectData10.contract_type) === null || _vm$projectData10$con === void 0 ? void 0 : _vm$projectData10$con.name))])]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Cargo Type")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('span', [_vm._v(_vm._s((_vm$projectData$cargo = (_vm$projectData11 = _vm.projectData) === null || _vm$projectData11 === void 0 ? void 0 : (_vm$projectData11$car = _vm$projectData11.cargo_type) === null || _vm$projectData11$car === void 0 ? void 0 : _vm$projectData11$car.name) !== null && _vm$projectData$cargo !== void 0 ? _vm$projectData$cargo : '-'))])])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Term")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('span', [_vm._v(_vm._s((_vm$projectData$terms = (_vm$projectData12 = _vm.projectData) === null || _vm$projectData12 === void 0 ? void 0 : (_vm$projectData12$ter = _vm$projectData12.terms) === null || _vm$projectData12$ter === void 0 ? void 0 : _vm$projectData12$ter.name) !== null && _vm$projectData$terms !== void 0 ? _vm$projectData$terms : '-') + " "), _c('br'), _vm._v(" Remarks: " + _vm._s((_vm$projectData13 = _vm.projectData) === null || _vm$projectData13 === void 0 ? void 0 : _vm$projectData13.terms_remarks))])]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Total Quantity")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [(_vm$projectData14 = _vm.projectData) !== null && _vm$projectData14 !== void 0 && _vm$projectData14.cargo_type_id ? _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v(_vm._s((_vm$totalQuantityAdd = _vm.totalQuantityAdd) === null || _vm$totalQuantityAdd === void 0 ? void 0 : _vm$totalQuantityAdd.toLocaleString()))]), _c('strong', [_vm._v(_vm._s((_vm$projectData15 = _vm.projectData) === null || _vm$projectData15 === void 0 ? void 0 : _vm$projectData15.unit))])]) : _vm._e()])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("POL")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column mb-2 w-100"
  }, _vm._l((_vm$projectData16 = _vm.projectData) === null || _vm$projectData16 === void 0 ? void 0 : _vm$projectData16.pol, function (obj, polIndex) {
    var _obj$value;
    return _c('span', {
      key: polIndex
    }, [_vm._v(_vm._s(polIndex + 1) + ". " + _vm._s((_obj$value = obj === null || obj === void 0 ? void 0 : obj.value) !== null && _obj$value !== void 0 ? _obj$value : '-'))]);
  }), 0)]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("POD")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column mb-2 w-100"
  }, _vm._l((_vm$projectData17 = _vm.projectData) === null || _vm$projectData17 === void 0 ? void 0 : _vm$projectData17.pod, function (obj, podIndex) {
    var _obj$value2;
    return _c('span', {
      key: podIndex
    }, [_vm._v(_vm._s(podIndex + 1) + ". " + _vm._s((_obj$value2 = obj === null || obj === void 0 ? void 0 : obj.value) !== null && _obj$value2 !== void 0 ? _obj$value2 : '-'))]);
  }), 0)])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Laycan")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('span', [_vm._v(_vm._s((_vm$projectData18 = _vm.projectData) !== null && _vm$projectData18 !== void 0 && _vm$projectData18.laycan ? _vm.moment((_vm$projectData19 = _vm.projectData) === null || _vm$projectData19 === void 0 ? void 0 : _vm$projectData19.laycan).format('DD MMMM YYYY') : '-'))])]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Distance")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('span', [_vm._v(_vm._s((_vm$projectData20 = _vm.projectData) === null || _vm$projectData20 === void 0 ? void 0 : _vm$projectData20.distance))])])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Freigh Rate (Est)")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('span', [_vm._v(_vm._s(_vm.formatCurrency((_vm$projectData21 = _vm.projectData) === null || _vm$projectData21 === void 0 ? void 0 : _vm$projectData21.freight_rate_est)))]), _c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(((_vm$projectData22 = _vm.projectData) === null || _vm$projectData22 === void 0 ? void 0 : _vm$projectData22.ppn) == 'include' ? 'Include ' : 'Exclude ') + " PPN")])])])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Remarks")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%",
      "colSpan": "4"
    }
  }, [_c('span', [_vm._v(_vm._s((_vm$projectData23 = _vm.projectData) === null || _vm$projectData23 === void 0 ? void 0 : _vm$projectData23.remarks))])])])])])])])], 1)], 1)], 1) : _vm._e(), ['ADD'].includes(_vm.action) ? _c('fieldset', {
    staticClass: "form-group border p-3 bg-default"
  }, [_c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSaveSpalProcess.apply(null, arguments);
      }
    }
  }, [_c('legend', {
    staticClass: "w-auto px-2 h5 text-primary"
  }, [_vm._v("SPAL / Contract Input")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Title",
      "label-for": "title"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "id": "title",
      "placeholder": "Type Text"
    },
    model: {
      value: _vm.form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "File Upload",
      "label-for": "attachment"
    }
  }, [_c('b-form-file', {
    staticClass: "border p-2",
    attrs: {
      "plain": "",
      "accept": "application/*",
      "multiple": "",
      "name": "attachment",
      "id": "attachment"
    },
    model: {
      value: _vm.form.attachment,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "attachment", $$v);
      },
      expression: "form.attachment"
    }
  }), _c('small', [_vm._v("If you want to upload multiple file, press \"ctrl / command\" in your keyboard")])], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Notes",
      "label-for": "notes"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "description",
      "rows": "3",
      "placeholder": "Type Text ..."
    },
    model: {
      value: _vm.form.notes,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "notes", $$v);
      },
      expression: "form.notes"
    }
  })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Is Fix SPAL / CONTRACT ?",
      "label-for": "is_fix_spal"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.form.is_fix_spal,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "is_fix_spal", $$v);
      },
      expression: "form.is_fix_spal"
    }
  }, [_vm._v(" Yes / No ")])], 1)], 1), _c('b-col', [_c('b-button', {
    staticClass: "pull-right",
    attrs: {
      "type": "submit",
      "variant": "success"
    }
  }, [_vm.saving ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _c('div', [_c('i', {
    staticClass: "fa fa-save"
  }), _vm._v(" "), _c('span', [_vm._v("Save Data")])])])], 1)], 1)], 1)]) : _vm._e(), ['ADD', 'PREVIEW'].includes(_vm.action) ? _c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 h5 text-primary"
  }, [_vm._v("history "), _c('span', [_c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.history",
      modifiers: {
        "history": true
      }
    }],
    attrs: {
      "size": "sm",
      "variant": "primary"
    }
  }, [_c('i', {
    staticClass: "fa fa-eye pr-0"
  }), _vm._v(" View History ")])], 1)]), _c('b-collapse', {
    attrs: {
      "id": "history"
    }
  }, [_c('b-row', {
    staticClass: "pl-3 pr-3"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Title")]), _c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("File")]), _c('th', [_vm._v("Notes")]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Comment")])])]), _c('tbody', [_vm._l(_vm.listHistorySpal, function (history, historyIndex) {
    var _history$attachment, _history$notes, _history$comment;
    return [_c('tr', {
      key: historyIndex
    }, [_c('td', [_vm._v(_vm._s(historyIndex + 1) + ".")]), _c('td', [_vm._v(_vm._s(history === null || history === void 0 ? void 0 : history.title))]), _c('td', [(history === null || history === void 0 ? void 0 : (_history$attachment = history.attachment) === null || _history$attachment === void 0 ? void 0 : _history$attachment.length) > 0 ? _c('div', {
      staticClass: "d-flex flex-wrap"
    }, [_vm._l(history === null || history === void 0 ? void 0 : history.attachment, function (file, fileIndex) {
      return [['application/pdf'].includes(file === null || file === void 0 ? void 0 : file.file_extension) ? _c('img', {
        key: fileIndex + '-history-file',
        staticClass: "mr-2 mb-2",
        staticStyle: {
          "cursor": "pointer"
        },
        attrs: {
          "src": require("../../../assets/images/activity/pdf.png"),
          "alt": "img",
          "height": "30px"
        },
        on: {
          "click": function click($event) {
            return _vm.handleOpenFilePreview("".concat(_vm.apiUrl, "/").concat(file === null || file === void 0 ? void 0 : file.file_url));
          }
        }
      }) : _c('img', {
        key: fileIndex + '-history-file',
        staticClass: "mr-2 mb-2",
        staticStyle: {
          "cursor": "pointer"
        },
        attrs: {
          "src": "".concat(_vm.apiUrl, "/").concat(file === null || file === void 0 ? void 0 : file.file_url),
          "alt": "img",
          "height": "30px"
        }
      })];
    })], 2) : _c('div', [_vm._v(" - ")])]), _c('td', [_vm._v(_vm._s((_history$notes = history === null || history === void 0 ? void 0 : history.notes) !== null && _history$notes !== void 0 ? _history$notes : '-'))]), _c('td', [_c('b-button', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle.comment",
        modifiers: {
          "comment": true
        }
      }],
      attrs: {
        "size": "sm",
        "variant": "primary"
      }
    }, [_c('i', {
      staticClass: "fa fa-comment pr-0"
    }), _vm._v(" Comment ")])], 1)]), _c('tr', [_c('td', {
      attrs: {
        "colspan": "5"
      }
    }, [_c('b-collapse', {
      attrs: {
        "id": "comment"
      }
    }, [_c('table', {
      staticClass: "table mb-0",
      attrs: {
        "width": "100%"
      }
    }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Comment")])])]), _c('tbody', [(history === null || history === void 0 ? void 0 : (_history$comment = history.comment) === null || _history$comment === void 0 ? void 0 : _history$comment.length) > 0 ? _vm._l(history === null || history === void 0 ? void 0 : history.comment, function (comment, commentIndex) {
      var _comment$user_name, _comment$comment_atta;
      return _c('tr', {
        key: commentIndex
      }, [_c('td', [_c('h6', {
        staticClass: "float-left mb-1"
      }, [_c('strong', [_vm._v(_vm._s((_comment$user_name = comment === null || comment === void 0 ? void 0 : comment.user_name) !== null && _comment$user_name !== void 0 ? _comment$user_name : '-'))])]), _c('br'), _c('small', [_vm._v(_vm._s(comment !== null && comment !== void 0 && comment.createdAt ? _vm.moment(comment === null || comment === void 0 ? void 0 : comment.createdAt).format('DD MMM YYYY HH:mm') : '-'))]), _c('br'), _c('span', [_vm._v(_vm._s(comment === null || comment === void 0 ? void 0 : comment.comment))]), (comment === null || comment === void 0 ? void 0 : (_comment$comment_atta = comment.comment_attachment) === null || _comment$comment_atta === void 0 ? void 0 : _comment$comment_atta.length) > 0 ? _c('div', {
        staticClass: "d-flex flex-column m-0"
      }, [_c('small', [_c('strong', {
        staticClass: "mb-2"
      }, [_vm._v("Attachment:")])]), _c('div', {
        staticClass: "d-flex flex-wrap"
      }, [_vm._l(comment === null || comment === void 0 ? void 0 : comment.comment_attachment, function (commentFile, commentFileIndex) {
        return [['application/pdf'].includes(commentFile === null || commentFile === void 0 ? void 0 : commentFile.file_extension) ? _c('img', {
          key: commentFileIndex + '-comment-file',
          staticClass: "mr-2 mb-2",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "src": require("../../../assets/images/activity/pdf.png"),
            "alt": "img",
            "height": "30px"
          },
          on: {
            "click": function click($event) {
              return _vm.handleOpenFilePreview("".concat(_vm.apiUrl, "/").concat(commentFile === null || commentFile === void 0 ? void 0 : commentFile.file_url));
            }
          }
        }) : _c('img', {
          key: commentFileIndex + '-comment-file',
          staticClass: "mr-2 mb-2",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "src": "".concat(_vm.apiUrl, "/").concat(commentFile === null || commentFile === void 0 ? void 0 : commentFile.file_url),
            "alt": "img",
            "height": "30px"
          }
        })];
      })], 2)]) : _vm._e()])]);
    }) : _c('tr', [_c('td', [_c('span', {
      staticClass: "text-center text-muted"
    }, [_vm._v("No comment yet, be the first comment.")])])]), _c('tr', [_c('td', [_c('div', {
      staticClass: "chat-footer p-3 bg-white"
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "chat-attagement d-flex"
    }, [_c('a', {
      attrs: {
        "href": "javascript:void(0)"
      }
    }, [_c('i', {
      staticClass: "fa fa-smile-o pr-3",
      attrs: {
        "aria-hidden": "true"
      }
    })]), _c('div', {
      staticClass: "d-inline-block",
      attrs: {
        "id": "file-upload"
      }
    }, [_c('i', {
      staticClass: "fa fa-paperclip pr-3",
      staticStyle: {
        "cursor": "pointer"
      },
      attrs: {
        "aria-hidden": "true"
      },
      on: {
        "click": _vm.openFileInput
      }
    })]), _c('b-form-file', {
      staticClass: "border p-2",
      attrs: {
        "id": "file-upload",
        "plain": "",
        "accept": "application/*",
        "multiple": "",
        "name": "attachment"
      },
      model: {
        value: _vm.formComment.attachment,
        callback: function callback($$v) {
          _vm.$set(_vm.formComment, "attachment", $$v);
        },
        expression: "formComment.attachment"
      }
    })], 1), _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.formComment.comment,
        expression: "formComment.comment"
      }],
      staticClass: "form-control mr-3",
      attrs: {
        "type": "text",
        "placeholder": "Type your message"
      },
      domProps: {
        "value": _vm.formComment.comment
      },
      on: {
        "input": function input($event) {
          if ($event.target.composing) return;
          _vm.$set(_vm.formComment, "comment", $event.target.value);
        }
      }
    }), _c('button', {
      staticClass: "btn btn-primary d-flex align-items-center p-2",
      attrs: {
        "type": "button"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.onAddComment(history === null || history === void 0 ? void 0 : history.id);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-paper-plane",
      attrs: {
        "aria-hidden": "true"
      }
    }), _c('span', {
      staticClass: "d-none d-lg-block ml-1"
    }, [_vm._v("Send")])])]), _vm.formComment.attachment.length > 0 ? _c('div', {
      staticClass: "d-flex mt-2 align-items-center"
    }, [_c('span', {
      staticClass: "mr-2"
    }, [_c('strong', [_vm._v(_vm._s(_vm.formComment.attachment.length))]), _vm._v(" Attachment")]), _c('a', {
      attrs: {
        "href": "javascript:void(0)"
      },
      on: {
        "click": _vm.resetAttachmentComment
      }
    }, [_c('i', {
      staticClass: "fa fa-trash text-danger",
      attrs: {
        "aria-hidden": "true"
      }
    })])]) : _vm._e()])])])], 2)])])], 1)])];
  })], 2)])])], 1)], 1)], 1) : _vm._e()]) : _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "alert alert-info m-4"
  }, [_vm._v(" Loading... ")])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }