var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "iq-edit-profile d-flex",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-4 p-0",
          attrs: {
            "active": true,
            "href": "#hasil_rakor",
            "title": "SHIPMENT PLANNING"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-4 p-0",
          attrs: {
            "active": false,
            "href": "#proses_penawaran",
            "title": "PROSES PENAWARAN"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-4 p-0",
          attrs: {
            "active": false,
            "href": "#realisasi",
            "title": "REALISASI"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "iq-edit-list-data"
  }, [_c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "pills-tabContent-2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "hasil_rakor"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("SHIPMENT PLANNING")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('b-dropdown', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Export Data',
            expression: "'Export Data'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "link iq-bg-primary",
            "toggle-class": "text-decoration-none",
            "no-caret": "",
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-save m-0"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-excel mr-2"
        }), _vm._v("Export Excel")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-pdf mr-2"
        }), _vm._v("Export PDF")])], 1), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Refresh Data',
            expression: "'Refresh Data'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "primary",
            "size": "sm"
          }
        }, [_c('i', {
          staticClass: "fa fa-refresh"
        })]), _c('b-button', {
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "primary",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              _vm.slideAddRakor = !_vm.slideAddRakor;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v("   ADD PLAN")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Period",
            "label-for": "Period"
          }
        }, [_c('date-picker', {
          attrs: {
            "lang": _vm.lang,
            "type": "date",
            "range": "",
            "placeholder": "Select date range"
          },
          model: {
            value: _vm.dateRange,
            callback: function callback($$v) {
              _vm.dateRange = $$v;
            },
            expression: "dateRange"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Cargo Type",
            "label-for": "cargo"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": ""
          }
        }, [_c('b-form-select-option', [_vm._v("All")]), _c('b-form-select-option', [_vm._v("COAL")]), _c('b-form-select-option', [_vm._v("SPLIT STONE")]), _c('b-form-select-option', [_vm._v("NICKEL")]), _c('b-form-select-option', [_vm._v("CPO")]), _c('b-form-select-option', [_vm._v("ASD")]), _c('b-form-select-option', [_vm._v("OTHERS")])], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Ownership",
            "label-for": "cargo"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": ""
          }
        }, [_c('b-form-select-option', [_vm._v("All")]), _c('b-form-select-option', [_vm._v("Own Ship")]), _c('b-form-select-option', [_vm._v("Third Party")])], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Keyword",
            "label-for": "keyword"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "id": "keyword"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-striped table-sm"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("NO")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("PROJECT")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TYPE")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("JUMLAH SHIPMENT")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("REALISASI SHIPMENT")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TOTAL VOLUME")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("REALISASI VOLUME")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ACTION")])])]), _c('tbody', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("1")]), _c('td', [_vm._v("AI PROJECT - LONG HAULING")]), _c('th', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          staticClass: "border border-primary text-primary",
          attrs: {
            "variant": "none"
          }
        }, [_vm._v("EXISTING")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("20 SHIPMENT")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("17 SHIPMENT")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("150.000 MT")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("145.800 MT")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.slideDetailPlan = !_vm.slideDetailPlan;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-eye pr-0"
        }), _vm._v(" Detail ")])], 1)]), _c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("2")]), _c('td', [_vm._v("KPC PROJECT - LONG HAULING")]), _c('th', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          staticClass: "border border-primary text-primary",
          attrs: {
            "variant": "none"
          }
        }, [_vm._v("EXISTING")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("13 SHIPMENT")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("11 SHIPMENT")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("122.000 MT")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("118.800 MT")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.slideDetailPlan = !_vm.slideDetailPlan;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-eye pr-0"
        }), _vm._v(" Detail ")])], 1)])])]), _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "value": 1,
            "total-rows": 50,
            "align": "center"
          }
        })], 1)])], 1), _c('b-sidebar', {
          attrs: {
            "id": "detail_rakor",
            "title": "TAHUN 2023",
            "width": "70%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref) {
              var hide = _ref.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('table', {
                staticClass: "table table-striped table-sm"
              }, [_c('thead', [_c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("NO")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("POL")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("POD")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("TERM")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("PLAN DATE")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("PLAN TRIP")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("PLAN QUANTITY")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("ACTION")])])]), _c('tbody', [_c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("1")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Asam-Asam")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("LABUAN")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("CIF")]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-badge', {
                staticClass: "mb-1",
                attrs: {
                  "variant": "success d-block"
                }
              }, [_vm._v("18/01/2023")]), _c('b-badge', {
                staticClass: "mb-1",
                attrs: {
                  "variant": "success d-block"
                }
              }, [_vm._v("20/01/2023")]), _c('b-badge', {
                staticClass: "mb-1",
                attrs: {
                  "variant": "success d-block"
                }
              }, [_vm._v("25/01/2023")])], 1), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("3")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("30.000")]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-dropdown', {
                attrs: {
                  "size": "lg",
                  "variant": "link iq-bg-primary iq-border-radius-10",
                  "toggle-class": "text-decoration-none",
                  "no-caret": ""
                },
                scopedSlots: _vm._u([{
                  key: "button-content",
                  fn: function fn() {
                    return [_c('span', {
                      staticClass: "dropdown-toggle p-0",
                      attrs: {
                        "id": "dropdownMenuButton5",
                        "data-toggle": "dropdown"
                      }
                    }, [_c('i', {
                      staticClass: "fa fa-eye m-0 text-primary"
                    })])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-dropdown-item', {
                attrs: {
                  "href": "#",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o mr-2"
              }), _vm._v("EDIT")]), _c('b-dropdown-item', {
                attrs: {
                  "href": "#",
                  "variant": "danger"
                }
              }, [_c('i', {
                staticClass: "fa fa-trash mr-2"
              }), _vm._v("HAPUS")])], 1)], 1)]), _c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("2")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Asam-Asam")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("LABUAN")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("FOB")]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-badge', {
                staticClass: "mb-1",
                attrs: {
                  "variant": "success d-block"
                }
              }, [_vm._v("18/01/2023")]), _c('b-badge', {
                staticClass: "mb-1",
                attrs: {
                  "variant": "success d-block"
                }
              }, [_vm._v("23/01/2023")]), _c('b-badge', {
                staticClass: "mb-1",
                attrs: {
                  "variant": "success d-block"
                }
              }, [_vm._v("27/01/2023")])], 1), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("3")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("37.000")]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-dropdown', {
                attrs: {
                  "size": "lg",
                  "variant": "link iq-bg-primary iq-border-radius-10",
                  "toggle-class": "text-decoration-none",
                  "no-caret": ""
                },
                scopedSlots: _vm._u([{
                  key: "button-content",
                  fn: function fn() {
                    return [_c('span', {
                      staticClass: "dropdown-toggle p-0",
                      attrs: {
                        "id": "dropdownMenuButton5",
                        "data-toggle": "dropdown"
                      }
                    }, [_c('i', {
                      staticClass: "fa fa-eye m-0 text-primary"
                    })])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-dropdown-item', {
                attrs: {
                  "href": "#",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o mr-2"
              }), _vm._v("EDIT")]), _c('b-dropdown-item', {
                attrs: {
                  "href": "#",
                  "variant": "danger"
                }
              }, [_c('i', {
                staticClass: "fa fa-trash mr-2"
              }), _vm._v("HAPUS")])], 1)], 1)])])]), _c('div', {
                staticClass: "mt-3"
              }, [_c('b-pagination', {
                attrs: {
                  "value": 1,
                  "total-rows": 50,
                  "align": "center"
                }
              })], 1)])], 1)];
            }
          }]),
          model: {
            value: _vm.slideDetailRakor,
            callback: function callback($$v) {
              _vm.slideDetailRakor = $$v;
            },
            expression: "slideDetailRakor"
          }
        }), _c('b-sidebar', {
          attrs: {
            "id": "add_rakor",
            "title": "ADD PLAN",
            "width": "100%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var hide = _ref2.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "cols": "6"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Project Info")]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "primary"
                }
              }, [_vm._l(_vm.company, function (item, index) {
                return [_c('b-form-radio', {
                  key: index,
                  attrs: {
                    "inline": "",
                    "name": item.name,
                    "value": item.value,
                    "disabled": item.disabled
                  },
                  model: {
                    value: _vm.companyActive[item[Object.keys(item)[0]]],
                    callback: function callback($$v) {
                      _vm.$set(_vm.companyActive, item[Object.keys(item)[0]], $$v);
                    },
                    expression: "companyActive[item[Object.keys(item)[0]]]"
                  }
                }, [_vm._v(_vm._s(item.label))])];
              })], 2), _c('b-form-group', {
                attrs: {
                  "label-for": "dateIssued",
                  "label": "Project Filter"
                }
              }, [_c('v-multiselect', {
                attrs: {
                  "options": _vm.projectOptions,
                  "multiple": true,
                  "group-values": "sub",
                  "group-label": "project",
                  "group-select": true,
                  "placeholder": "Select one",
                  "track-by": "name",
                  "label": "name"
                },
                model: {
                  value: _vm.filterDailyOps.subProject,
                  callback: function callback($$v) {
                    _vm.$set(_vm.filterDailyOps, "subProject", $$v);
                  },
                  expression: "filterDailyOps.subProject"
                }
              }, [_c('span', {
                attrs: {
                  "slot": "noResult"
                },
                slot: "noResult"
              }, [_vm._v("Oops! No elements found. Consider changing the search query.")])])], 1)], 1)]), _c('b-col', {
                attrs: {
                  "cols": "6"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Company Info")]), _c('table', {
                staticClass: "table mb-0 table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Nama Perusahaan")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Arutmin Indonesia")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Alamat")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Jl. H. R. Rasuna Said, RT.2/RW.5")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("No. Telepon")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("021 123 456")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Email")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("info@arutmin.com")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Negara")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Indonesia")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Provinsi")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("DKI Jakarta")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Kota")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Jakarta Selatan")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Kecamatan")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Setia Budi")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Kelurahan")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Karet Pedurenan")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }), _c('td', {
                attrs: {
                  "width": "25%"
                }
              })]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("PIC")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Agung Tri")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("No. Handphone")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("0812613875137")])])])])])]), _c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Detail Plan")]), _c('table', {
                staticClass: "table table-striped"
              }, [_c('thead', [_c('tr', [_c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "vertical-align": "middle"
                },
                attrs: {
                  "rowspan": "2"
                }
              }, [_vm._v("NO")]), _c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "vertical-align": "middle"
                },
                attrs: {
                  "rowspan": "2"
                }
              }, [_vm._v("DESCRIPTION")]), _c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "vertical-align": "middle"
                },
                attrs: {
                  "rowspan": "2",
                  "width": "8%"
                }
              }, [_vm._v("FLEET")]), _c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "vertical-align": "middle"
                },
                attrs: {
                  "rowspan": "2"
                }
              }, [_vm._v("CARGO TYPE")]), _c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "vertical-align": "middle"
                },
                attrs: {
                  "rowspan": "2"
                }
              }, [_vm._v("LOCATION")]), _c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "vertical-align": "middle"
                },
                attrs: {
                  "rowspan": "2"
                }
              }, [_vm._v("SHIPMENT (MONTH)")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "colspan": "2"
                }
              }, [_vm._v("PLAN DATE")]), _c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "vertical-align": "middle"
                },
                attrs: {
                  "rowspan": "2"
                }
              }, [_vm._v("PLAN QUANTITY")]), _c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "vertical-align": "middle"
                },
                attrs: {
                  "rowspan": "2"
                }
              }, [_vm._v("REMARKS")]), _c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "vertical-align": "middle"
                },
                attrs: {
                  "rowspan": "2"
                }
              }, [_vm._v("ACTION")])]), _c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("ETA POL")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("ETA POD")])])]), _c('tbody', [_c('tr', [_c('th', {
                staticClass: "text-center align-top"
              }, [_vm._v("#1")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_c('b-form-input', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "text",
                  "id": "nama_perusahaan",
                  "size": "sm"
                }
              })], 1), _c('td', {
                staticClass: "text-left align-top"
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_vm._l(_vm.ownership, function (item, index) {
                return [_c('b-form-radio', {
                  key: index,
                  attrs: {
                    "inline": "",
                    "name": item.name,
                    "value": item.value,
                    "disabled": item.disabled
                  },
                  model: {
                    value: _vm.ownershipActive[item[Object.keys(item)[0]]],
                    callback: function callback($$v) {
                      _vm.$set(_vm.ownershipActive, item[Object.keys(item)[0]], $$v);
                    },
                    expression: "ownershipActive[item[Object.keys(item)[0]]]"
                  }
                }, [_vm._v(_vm._s(item.label))])];
              })], 2), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "text",
                  "id": "nama_perusahaan",
                  "size": "sm",
                  "placeholder": "input fleet"
                }
              })], 1)], 1)], 1), _c('td', {
                staticClass: "text-left align-top"
              }, [_c('b-form-group', {
                staticClass: "row mb-2",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Cargo",
                  "label-for": "descriptionItem"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "text",
                  "id": "nama_perusahaan",
                  "size": "sm",
                  "placeholder": "Cargo Type"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row mb-2",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Term",
                  "label-for": "descriptionItem"
                }
              }, [_c('b-form-select', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "plain": "",
                  "size": "sm"
                }
              }, [_c('b-form-select-option', [_vm._v("SELECT TERM")]), _c('b-form-select-option', [_vm._v("CIF")]), _c('b-form-select-option', [_vm._v("FOB")])], 1)], 1)], 1), _c('td', {
                staticClass: "text-left align-top"
              }, [_c('b-form-group', {
                staticClass: "row mb-2",
                attrs: {
                  "label-cols-md": "4",
                  "label": "POL",
                  "label-for": "descriptionItem"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "text",
                  "id": "nama_perusahaan",
                  "size": "sm"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row mb-2",
                attrs: {
                  "label-cols-md": "4",
                  "label": "POD",
                  "label-for": "descriptionItem"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "text",
                  "id": "nama_perusahaan",
                  "size": "sm"
                }
              })], 1)], 1), _c('td', {
                staticClass: "text-center align-top"
              }, [_c('b-form-select', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "plain": "",
                  "size": "sm"
                }
              }, [_c('b-form-select-option', [_vm._v("SELECT MONTH")]), _c('b-form-select-option', [_vm._v("JANUARI")]), _c('b-form-select-option', [_vm._v("FEBRUARI")]), _c('b-form-select-option', [_vm._v("MARET")]), _c('b-form-select-option', [_vm._v("APRIL")]), _c('b-form-select-option', [_vm._v("MEI")]), _c('b-form-select-option', [_vm._v("JUNI")]), _c('b-form-select-option', [_vm._v("JULI")]), _c('b-form-select-option', [_vm._v("AGUSTUS")]), _c('b-form-select-option', [_vm._v("SEPTEMBER")]), _c('b-form-select-option', [_vm._v("OKTOBER")]), _c('b-form-select-option', [_vm._v("NOVEMBER")]), _c('b-form-select-option', [_vm._v("DESEMBER")])], 1)], 1), _c('td', {
                staticClass: "text-center align-top",
                attrs: {
                  "colspan": "2"
                }
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form-input', {
                staticClass: "mb-1",
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "date",
                  "id": "plan_date",
                  "size": "sm"
                }
              })], 1), _c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form-input', {
                staticClass: "mb-1",
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "date",
                  "id": "plan_date",
                  "size": "sm"
                }
              })], 1)], 1)], 1), _c('td', {
                staticClass: "text-center align-top"
              }, [_c('b-form-input', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "number",
                  "id": "plan_cargo",
                  "size": "sm"
                }
              })], 1), _c('td', {
                staticClass: "text-center align-top"
              }, [_c('b-form-textarea', {
                attrs: {
                  "rows": "2",
                  "value": "Comments if any / Komentar jika ada"
                }
              })], 1), _c('td', {
                staticClass: "text-center align-top"
              }, [_c('b-button', {
                attrs: {
                  "size": "sm",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-trash pr-0"
              })])], 1)]), _c('tr', [_c('th', {
                staticClass: "text-center align-top"
              }, [_vm._v("#2")]), _c('td', {
                staticClass: "text-center align-top"
              }, [_c('b-form-input', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "text",
                  "id": "nama_perusahaan",
                  "size": "sm"
                }
              })], 1), _c('td', {
                staticClass: "text-left align-top"
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_vm._l(_vm.ownership, function (item, index) {
                return [_c('b-form-radio', {
                  key: index,
                  attrs: {
                    "inline": "",
                    "name": item.name,
                    "value": item.value,
                    "disabled": item.disabled
                  },
                  model: {
                    value: _vm.ownershipActive[item[Object.keys(item)[0]]],
                    callback: function callback($$v) {
                      _vm.$set(_vm.ownershipActive, item[Object.keys(item)[0]], $$v);
                    },
                    expression: "ownershipActive[item[Object.keys(item)[0]]]"
                  }
                }, [_vm._v(_vm._s(item.label))])];
              })], 2), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "text",
                  "id": "nama_perusahaan",
                  "size": "sm",
                  "placeholder": "input fleet"
                }
              })], 1)], 1)], 1), _c('td', {
                staticClass: "text-left align-top"
              }, [_c('b-form-group', {
                staticClass: "row mb-2",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Cargo",
                  "label-for": "descriptionItem"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "text",
                  "id": "nama_perusahaan",
                  "size": "sm",
                  "placeholder": "Cargo Type"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row mb-2",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Term",
                  "label-for": "descriptionItem"
                }
              }, [_c('b-form-select', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "plain": "",
                  "size": "sm"
                }
              }, [_c('b-form-select-option', [_vm._v("SELECT TERM")]), _c('b-form-select-option', [_vm._v("CIF")]), _c('b-form-select-option', [_vm._v("FOB")])], 1)], 1)], 1), _c('td', {
                staticClass: "text-left align-top"
              }, [_c('b-form-group', {
                staticClass: "row mb-2",
                attrs: {
                  "label-cols-md": "4",
                  "label": "POL",
                  "label-for": "descriptionItem"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "text",
                  "id": "nama_perusahaan",
                  "size": "sm"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row mb-2",
                attrs: {
                  "label-cols-md": "4",
                  "label": "POD",
                  "label-for": "descriptionItem"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "text",
                  "id": "nama_perusahaan",
                  "size": "sm"
                }
              })], 1)], 1), _c('td', {
                staticClass: "text-center align-top"
              }, [_c('b-form-select', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "plain": "",
                  "size": "sm"
                }
              }, [_c('b-form-select-option', [_vm._v("SELECT MONTH")]), _c('b-form-select-option', [_vm._v("JANUARI")]), _c('b-form-select-option', [_vm._v("FEBRUARI")]), _c('b-form-select-option', [_vm._v("MARET")]), _c('b-form-select-option', [_vm._v("APRIL")]), _c('b-form-select-option', [_vm._v("MEI")]), _c('b-form-select-option', [_vm._v("JUNI")]), _c('b-form-select-option', [_vm._v("JULI")]), _c('b-form-select-option', [_vm._v("AGUSTUS")]), _c('b-form-select-option', [_vm._v("SEPTEMBER")]), _c('b-form-select-option', [_vm._v("OKTOBER")]), _c('b-form-select-option', [_vm._v("NOVEMBER")]), _c('b-form-select-option', [_vm._v("DESEMBER")])], 1)], 1), _c('td', {
                staticClass: "text-center align-top",
                attrs: {
                  "colspan": "2"
                }
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form-input', {
                staticClass: "mb-1",
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "date",
                  "id": "plan_date",
                  "size": "sm"
                }
              })], 1), _c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form-input', {
                staticClass: "mb-1",
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "date",
                  "id": "plan_date",
                  "size": "sm"
                }
              })], 1)], 1)], 1), _c('td', {
                staticClass: "text-center align-top"
              }, [_c('b-form-input', {
                staticStyle: {
                  "border": "solid 1px gray"
                },
                attrs: {
                  "type": "number",
                  "id": "plan_cargo",
                  "size": "sm"
                }
              })], 1), _c('td', {
                staticClass: "text-center align-top"
              }, [_c('b-form-textarea', {
                attrs: {
                  "rows": "2",
                  "value": "Comments if any / Komentar jika ada"
                }
              })], 1), _c('td', {
                staticClass: "text-center align-top"
              }, [_c('b-button', {
                attrs: {
                  "size": "sm",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-trash pr-0"
              })])], 1)])])]), _c('b-button', {
                attrs: {
                  "block": "",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-plus pr-0"
              }), _vm._v(" ADD MORE DATA")])], 1)])], 1)];
            }
          }]),
          model: {
            value: _vm.slideAddRakor,
            callback: function callback($$v) {
              _vm.slideAddRakor = $$v;
            },
            expression: "slideAddRakor"
          }
        }), _c('b-sidebar', {
          attrs: {
            "id": "add_rakor",
            "title": "PLAN & REALIZATION",
            "width": "100%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var hide = _ref3.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "2"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Status",
                  "label-for": "status"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": ""
                }
              }, [_c('b-form-select-option', [_vm._v("All")]), _c('b-form-select-option', [_vm._v("Created")]), _c('b-form-select-option', [_vm._v("Offered")]), _c('b-form-select-option', [_vm._v("Running")])], 1)], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "2"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Port of Discharge",
                  "label-for": "pod"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": ""
                }
              }, [_c('b-form-select-option', [_vm._v("All")]), _c('b-form-select-option', [_vm._v("ASAM ASAM")]), _c('b-form-select-option', [_vm._v("PAITON")]), _c('b-form-select-option', [_vm._v("KENDARI")])], 1)], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "2"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Search",
                  "label-for": "button_search"
                }
              }, [_c('b-button', {
                staticClass: "mb-3 mr-1",
                attrs: {
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-search"
              })])], 1)], 1)], 1), _c('div', {
                staticClass: "table-responsive"
              }, [_c('table', {
                staticClass: "table",
                staticStyle: {
                  "width": "5200px",
                  "overflow-y": "scroll"
                }
              }, [_c('thead', [_c('tr', [_c('th', {
                staticClass: "text-center table-primary",
                staticStyle: {
                  "position": "sticky",
                  "left": "0px",
                  "width": "50px"
                }
              }, [_vm._v("NO")]), _c('th', {
                staticClass: "text-center table-primary",
                staticStyle: {
                  "position": "sticky",
                  "left": "50px",
                  "width": "200px"
                }
              }, [_vm._v("FLEET")]), _c('th', {
                staticClass: "text-center table-primary",
                staticStyle: {
                  "width": "100px"
                }
              }, [_vm._v("MODA")]), _c('th', {
                staticClass: "text-center table-primary",
                staticStyle: {
                  "position": "sticky",
                  "left": "250px",
                  "width": "150px"
                }
              }, [_vm._v("CARGO")]), _c('th', {
                staticClass: "text-center table-primary",
                staticStyle: {
                  "position": "sticky",
                  "left": "400px",
                  "width": "200px"
                }
              }, [_vm._v("POL")]), _c('th', {
                staticClass: "text-center table-primary",
                staticStyle: {
                  "position": "sticky",
                  "left": "600px",
                  "width": "200px"
                }
              }, [_vm._v("POD")]), _c('th', {
                staticClass: "text-center table-primary",
                staticStyle: {
                  "width": "100px"
                }
              }, [_vm._v("ETA POL")]), _c('th', {
                staticClass: "text-center table-primary",
                staticStyle: {
                  "width": "100px"
                }
              }, [_vm._v("ETA POD")]), _c('th', {
                staticClass: "text-center table-primary",
                staticStyle: {
                  "width": "150px"
                }
              }, [_vm._v("PLAN QTY")]), _c('th', {
                staticClass: "text-center table-primary",
                staticStyle: {
                  "width": "100px"
                }
              }, [_vm._v("UNIT RATE")]), _c('th', {
                staticClass: "text-center table-primary"
              }, [_vm._v("REMARKS")]), _c('th', {
                staticClass: "text-center table-primary",
                staticStyle: {
                  "position": "sticky",
                  "left": "800px",
                  "width": "150px"
                }
              }, [_vm._v("STATUS")]), _c('th', {
                staticClass: "text-center table-info",
                staticStyle: {
                  "width": "250px"
                }
              }, [_vm._v("SHIPPER")]), _c('th', {
                staticClass: "text-center table-info"
              }, [_vm._v("SHIPMENT")]), _c('th', {
                staticClass: "text-center table-info"
              }, [_vm._v("STATUS")]), _c('th', {
                staticClass: "text-center table-info"
              }, [_vm._v("MODA")]), _c('th', {
                staticClass: "text-center table-info",
                staticStyle: {
                  "position": "sticky",
                  "left": "950px",
                  "width": "200px"
                }
              }, [_vm._v("FLEET")]), _c('th', {
                staticClass: "text-center table-info",
                staticStyle: {
                  "width": "200px"
                }
              }, [_vm._v("COMPANY")]), _c('th', {
                staticClass: "text-center table-info",
                staticStyle: {
                  "width": "150px"
                }
              }, [_vm._v("CARGO TYPE")]), _c('th', {
                staticClass: "text-center table-info",
                staticStyle: {
                  "position": "sticky",
                  "left": "1150px",
                  "width": "200px"
                }
              }, [_vm._v("POL")]), _c('th', {
                staticClass: "text-center table-info",
                staticStyle: {
                  "width": "150px"
                }
              }, [_vm._v("TA POL/READY")]), _c('th', {
                staticClass: "text-center table-info",
                staticStyle: {
                  "width": "150px"
                }
              }, [_vm._v("BERTHING")]), _c('th', {
                staticClass: "text-center table-info",
                staticStyle: {
                  "width": "150px"
                }
              }, [_vm._v("COMMENCED")]), _c('th', {
                staticClass: "text-center table-info"
              }, [_vm._v("COMPLETED")]), _c('th', {
                staticClass: "text-center table-info"
              }, [_vm._v("CARGO LOADING")]), _c('th', {
                staticClass: "text-center table-info",
                staticStyle: {
                  "position": "sticky",
                  "left": "1350px",
                  "width": "200px"
                }
              }, [_vm._v("POD")]), _c('th', {
                staticClass: "text-center table-info"
              }, [_vm._v("TA POD/READY")]), _c('th', {
                staticClass: "text-center table-info"
              }, [_vm._v("BERTHING")]), _c('th', {
                staticClass: "text-center table-info"
              }, [_vm._v("COMMENCED")]), _c('th', {
                staticClass: "text-center table-info"
              }, [_vm._v("COMPLETED")]), _c('th', {
                staticClass: "text-center table-info"
              }, [_vm._v("CARGO UNLOADING")]), _c('th', {
                staticClass: "text-center table-info"
              }, [_vm._v("PRICE")]), _c('th', {
                staticClass: "text-center table-info"
              }, [_vm._v("3RD PARTY RATE")]), _c('th', {
                staticClass: "text-center table-info"
              }, [_vm._v("ACTION")])])]), _c('tbody', [_c('tr', [_c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "0px",
                  "width": "50px",
                  "background-color": "white"
                }
              }, [_vm._v("#1")]), _c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "50px",
                  "width": "200px",
                  "background-color": "white"
                }
              }, [_vm._v("TB.DHARMA 1 / BG. 3304"), _c('br'), _c('b-badge', {
                attrs: {
                  "variant": "primary"
                }
              }, [_vm._v("THIRD PARTY")])], 1), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("300ft")]), _c('td', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "250px",
                  "width": "150px",
                  "background-color": "white"
                }
              }, [_vm._v("COAL")]), _c('td', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "400px",
                  "width": "200px",
                  "background-color": "white"
                }
              }, [_c('strong', [_vm._v("ASAM ASAM")])]), _c('td', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "600px",
                  "width": "200px",
                  "background-color": "white"
                }
              }, [_c('strong', [_vm._v("PAITON")])]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("10/01/2023")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("16/01/2023")]), _c('td', {
                staticClass: "text-center"
              }, [_c('strong', [_vm._v("7.000")])]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("MT")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "800px",
                  "width": "150px",
                  "background-color": "white"
                }
              }, [_c('b-badge', {
                attrs: {
                  "variant": "primary"
                }
              }, [_vm._v("CREATED")])], 1), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "950px",
                  "width": "150px",
                  "background-color": "white"
                }
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "1150px",
                  "width": "200px",
                  "background-color": "white"
                }
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "1350px",
                  "width": "200px",
                  "background-color": "white"
                }
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-dropdown', {
                attrs: {
                  "size": "lg",
                  "variant": "link iq-bg-primary iq-border-radius-10",
                  "toggle-class": "text-decoration-none",
                  "no-caret": ""
                },
                scopedSlots: _vm._u([{
                  key: "button-content",
                  fn: function fn() {
                    return [_c('span', {
                      staticClass: "dropdown-toggle p-0",
                      attrs: {
                        "id": "dropdownMenuButton5",
                        "data-toggle": "dropdown"
                      }
                    }, [_c('i', {
                      staticClass: "fa fa-pencil-square-o m-0 text-primary"
                    })])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-dropdown-item', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.add-activity",
                  modifiers: {
                    "add-activity": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('i', {
                staticClass: "fa fa-info-circle mr-1"
              }), _vm._v("Show ")]), _c('b-dropdown-item', {
                attrs: {
                  "href": "#"
                }
              }, [_c('i', {
                staticClass: "fa fa-trash mr-1"
              }), _vm._v("Delete ")])], 1)], 1)]), _c('tr', [_c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "0px",
                  "width": "50px",
                  "background-color": "white"
                }
              }, [_vm._v("#2")]), _c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "50px",
                  "width": "200px",
                  "background-color": "white"
                }
              }, [_vm._v("TB.DHARMA 1 / BG. 3004"), _c('br'), _c('b-badge', {
                attrs: {
                  "variant": "primary"
                }
              }, [_vm._v("THIRD PARTY")])], 1), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("300ft")]), _c('td', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "250px",
                  "width": "150px",
                  "background-color": "white"
                }
              }, [_vm._v("COAL")]), _c('td', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "400px",
                  "width": "200px",
                  "background-color": "white"
                }
              }, [_c('strong', [_vm._v("ASAM ASAM")])]), _c('td', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "600px",
                  "width": "200px",
                  "background-color": "white"
                }
              }, [_c('strong', [_vm._v("PAITON")])]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("10/01/2023")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("16/01/2023")]), _c('td', {
                staticClass: "text-center"
              }, [_c('strong', [_vm._v("7.500")])]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("MT")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "800px",
                  "width": "150px",
                  "background-color": "white"
                }
              }, [_c('b-badge', {
                attrs: {
                  "variant": "light"
                }
              }, [_vm._v("OFFERED")])], 1), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "950px",
                  "width": "150px",
                  "background-color": "white"
                }
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "1150px",
                  "width": "200px",
                  "background-color": "white"
                }
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "1350px",
                  "width": "200px",
                  "background-color": "white"
                }
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-dropdown', {
                attrs: {
                  "size": "lg",
                  "variant": "link iq-bg-primary iq-border-radius-10",
                  "toggle-class": "text-decoration-none",
                  "no-caret": ""
                },
                scopedSlots: _vm._u([{
                  key: "button-content",
                  fn: function fn() {
                    return [_c('span', {
                      staticClass: "dropdown-toggle p-0",
                      attrs: {
                        "id": "dropdownMenuButton5",
                        "data-toggle": "dropdown"
                      }
                    }, [_c('i', {
                      staticClass: "fa fa-pencil-square-o m-0 text-primary"
                    })])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-dropdown-item', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.add-activity",
                  modifiers: {
                    "add-activity": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('i', {
                staticClass: "fa fa-info-circle mr-1"
              }), _vm._v("Show ")]), _c('b-dropdown-item', {
                attrs: {
                  "href": "#"
                }
              }, [_c('i', {
                staticClass: "fa fa-trash mr-1"
              }), _vm._v("Delete ")])], 1)], 1)]), _c('tr', [_c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "0px",
                  "width": "50px",
                  "background-color": "white"
                }
              }, [_vm._v("#3")]), _c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "50px",
                  "width": "200px",
                  "background-color": "white"
                }
              }, [_vm._v("TB.ETI 102 / BG. 3004"), _c('br'), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("OWN SHIP")])], 1), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("300ft")]), _c('td', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "250px",
                  "width": "150px",
                  "background-color": "white"
                }
              }, [_vm._v("COAL")]), _c('td', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "400px",
                  "width": "200px",
                  "background-color": "white"
                }
              }, [_c('strong', [_vm._v("ASAM ASAM")])]), _c('td', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "600px",
                  "width": "200px",
                  "background-color": "white"
                }
              }, [_c('strong', [_vm._v("PAITON")])]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("10/01/2023")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("16/01/2023")]), _c('td', {
                staticClass: "text-center"
              }, [_c('strong', [_vm._v("7.500")])]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("MT")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "800px",
                  "width": "150px",
                  "background-color": "white"
                }
              }, [_c('b-badge', {
                attrs: {
                  "variant": "success"
                }
              }, [_vm._v("RUNNING")])], 1), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("PLN Rembang")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("JANUARY")]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "success"
                }
              }, [_vm._v("Completed")])], 1), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("330 ft")]), _c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "950px",
                  "width": "150px",
                  "background-color": "white"
                }
              }, [_vm._v("TB.ETI 102 / BG. 3301"), _c('br'), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("OWN SHIP")])], 1), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("PT.Transcoal Pacific")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Nickel")]), _c('td', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "1150px",
                  "width": "200px",
                  "background-color": "white"
                }
              }, [_vm._v("MP Asam2")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("31-Dec-21 00.00")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("02-Jan-22 02.10")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("02-Jan-22 03.05")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("02-Jan-22 05.05")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("7.606 MT")]), _c('td', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "1350px",
                  "width": "200px",
                  "background-color": "white"
                }
              }, [_vm._v("LABUAN")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("04-Jan-22 20.00")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("04-Jan-22 20.00")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("04-Jan-22 20.00")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("04-Jan-22 20.00")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("7.506 MT")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("IDR 198.078")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-dropdown', {
                attrs: {
                  "size": "lg",
                  "variant": "link iq-bg-primary iq-border-radius-10",
                  "toggle-class": "text-decoration-none",
                  "no-caret": ""
                },
                scopedSlots: _vm._u([{
                  key: "button-content",
                  fn: function fn() {
                    return [_c('span', {
                      staticClass: "dropdown-toggle p-0",
                      attrs: {
                        "id": "dropdownMenuButton5",
                        "data-toggle": "dropdown"
                      }
                    }, [_c('i', {
                      staticClass: "fa fa-pencil-square-o m-0 text-primary"
                    })])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-dropdown-item', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.add-activity",
                  modifiers: {
                    "add-activity": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('i', {
                staticClass: "fa fa-info-circle mr-1"
              }), _vm._v("Show ")]), _c('b-dropdown-item', {
                attrs: {
                  "href": "#"
                }
              }, [_c('i', {
                staticClass: "fa fa-trash mr-1"
              }), _vm._v("Delete ")])], 1)], 1)]), _c('tr', [_c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "0px",
                  "width": "50px",
                  "background-color": "white"
                }
              }, [_vm._v("#4")]), _c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "50px",
                  "width": "200px",
                  "background-color": "white"
                }
              }, [_vm._v("TB.ETI 102 / BG. 3004"), _c('br'), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("OWN SHIP")])], 1), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("300ft")]), _c('td', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "250px",
                  "width": "150px",
                  "background-color": "white"
                }
              }, [_vm._v("COAL")]), _c('td', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "400px",
                  "width": "200px",
                  "background-color": "white"
                }
              }, [_c('strong', [_vm._v("ASAM ASAM")])]), _c('td', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "600px",
                  "width": "200px",
                  "background-color": "white"
                }
              }, [_c('strong', [_vm._v("PAITON")])]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("10/01/2023")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("16/01/2023")]), _c('td', {
                staticClass: "text-center"
              }, [_c('strong', [_vm._v("7.500")])]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("MT")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "800px",
                  "width": "150px",
                  "background-color": "white"
                }
              }, [_c('b-badge', {
                attrs: {
                  "variant": "success"
                }
              }, [_vm._v("RUNNING")])], 1), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("PLN Rembang")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("JANUARY")]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-badge', {
                attrs: {
                  "variant": "success"
                }
              }, [_vm._v("Completed")])], 1), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("330 ft")]), _c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "950px",
                  "width": "150px",
                  "background-color": "white"
                }
              }, [_vm._v("TB.ETI 102 / BG. 3301"), _c('br'), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("OWN SHIP")])], 1), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("PT.Transcoal Pacific")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Nickel")]), _c('td', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "1150px",
                  "width": "200px",
                  "background-color": "white"
                }
              }, [_vm._v("MP Asam2")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("31-Dec-21 00.00")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("02-Jan-22 02.10")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("02-Jan-22 03.05")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("02-Jan-22 05.05")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("7.606 MT")]), _c('td', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "1350px",
                  "width": "200px",
                  "background-color": "white"
                }
              }, [_vm._v("LABUAN")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("04-Jan-22 20.00")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("04-Jan-22 20.00")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("04-Jan-22 20.00")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("04-Jan-22 20.00")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("7.506 MT")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("IDR 198.078")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-dropdown', {
                attrs: {
                  "size": "lg",
                  "variant": "link iq-bg-primary iq-border-radius-10",
                  "toggle-class": "text-decoration-none",
                  "no-caret": ""
                },
                scopedSlots: _vm._u([{
                  key: "button-content",
                  fn: function fn() {
                    return [_c('span', {
                      staticClass: "dropdown-toggle p-0",
                      attrs: {
                        "id": "dropdownMenuButton5",
                        "data-toggle": "dropdown"
                      }
                    }, [_c('i', {
                      staticClass: "fa fa-pencil-square-o m-0 text-primary"
                    })])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-dropdown-item', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.add-activity",
                  modifiers: {
                    "add-activity": true
                  }
                }],
                attrs: {
                  "href": "#"
                }
              }, [_c('i', {
                staticClass: "fa fa-info-circle mr-1"
              }), _vm._v("Show ")]), _c('b-dropdown-item', {
                attrs: {
                  "href": "#"
                }
              }, [_c('i', {
                staticClass: "fa fa-trash mr-1"
              }), _vm._v("Delete ")])], 1)], 1)])])])])], 1)], 1)];
            }
          }]),
          model: {
            value: _vm.slideDetailPlan,
            callback: function callback($$v) {
              _vm.slideDetailPlan = $$v;
            },
            expression: "slideDetailPlan"
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "proses_penawaran"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("PROSES PENAWARAN")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('b-dropdown', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Export Data',
            expression: "'Export Data'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "link iq-bg-primary",
            "toggle-class": "text-decoration-none",
            "no-caret": "",
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-save m-0"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-excel mr-2"
        }), _vm._v("Export Excel")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-pdf mr-2"
        }), _vm._v("Export PDF")])], 1), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Refresh Data',
            expression: "'Refresh Data'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "primary",
            "size": "sm"
          }
        }, [_c('i', {
          staticClass: "fa fa-refresh"
        })]), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Add Data',
            expression: "'Add Data'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "primary",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              _vm.addPurchaseRequest = !_vm.addPurchaseRequest;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" Add PFA (Pre Fixture Analysis)")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', {
          staticClass: "mb-4"
        }, [_c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-input', {
          staticStyle: {
            "border": "solid 1px gray"
          },
          attrs: {
            "type": "text",
            "id": "search_name",
            "size": "sm",
            "placeholder": "search name"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "primary",
            "size": "sm"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1), _c('b-col', {
          attrs: {
            "md": "9"
          }
        })], 1), _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("PFA ID")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("NAME")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("FLEET")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("DATE")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("DESCRIPTION")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("QUANTITY")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("FREIGHT")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("REVENUE")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("PROFIT")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("PROFIT (%)")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("STATUS")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ACTION")])])]), _c('tbody', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              _vm.addPurchaseRequest = !_vm.addPurchaseRequest;
            }
          }
        }, [_vm._v("SP-2210001")])]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("PRE FIXTURE ANALYSIS Jetty CLM Malili - OSS MOROSI")]), _c('td', {
          staticClass: "text-center"
        }, [_c('strong', [_vm._v("Tug Boat :")]), _vm._v("TB ETI 102 "), _c('br'), _vm._v(" "), _c('strong', [_vm._v("Barge : ")]), _vm._v(" BG ETI 3008 "), _c('br')]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("25 Juli 2022 - 26 Juli 2022")]), _c('td', {
          staticClass: "text-center"
        }, [_c('strong', [_vm._v("POL : ")]), _vm._v("Jetty CLM"), _c('br'), _c('strong', [_vm._v("POD : ")]), _vm._v("OSS Morosi"), _c('strong', [_vm._v("KIND OF CARGO : ")]), _vm._v("NIKEL")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v(" 7.400 ")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("140.000")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("1.050.000.000")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("1.050.000.000")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          staticStyle: {
            "font-size": "16pt"
          },
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("15%")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('div', {
          staticClass: "mb-1",
          staticStyle: {
            "font-size": "14pt"
          }
        }, [_c('b-badge', {
          attrs: {
            "variant": "primary"
          }
        }, [_vm._v("Proposed")])], 1), _c('div', {
          staticClass: "mb-1",
          staticStyle: {
            "font-size": "14pt"
          }
        }, [_c('b-badge', {
          attrs: {
            "variant": "secondary"
          }
        }, [_vm._v("Needs Review")])], 1), _c('div', {
          staticClass: "mb-1",
          staticStyle: {
            "font-size": "14pt"
          }
        }, [_c('b-badge', {
          attrs: {
            "variant": "warning"
          }
        }, [_vm._v("Needs Approval")])], 1), _c('div', {
          staticClass: "mb-1",
          staticStyle: {
            "font-size": "14pt"
          }
        }, [_c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("Draft SPAL / Contract")])], 1), _c('div', {
          staticClass: "mb-1",
          staticStyle: {
            "font-size": "14pt"
          }
        }, [_c('b-badge', {
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("Realized")])], 1)]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-list-alt m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-edit mr-2"
        }), _vm._v("Update")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-eye mr-2"
        }), _vm._v("Review")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-check mr-2"
        }), _vm._v("Approved")]), _c('b-dropdown-item', {
          attrs: {
            "variant": "dark"
          },
          on: {
            "click": function click($event) {
              _vm.spalHistory = !_vm.spalHistory;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-pencil-square-o mr-2"
        }), _vm._v("Process SPAL / Contract")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-trash mr-2"
        }), _vm._v("Delete")])], 1)], 1)])])])]), _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "value": 1,
            "total-rows": 50,
            "align": "center"
          }
        })], 1), _c('b-sidebar', {
          attrs: {
            "id": "addPurchaseRequest",
            "title": "ADD PFA(PRE FIXTURE ANALYSIS)",
            "width": "60%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var hide = _ref4.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h5 text-primary"
              }, [_vm._v("General Information")]), _c('b-row', {
                staticClass: "pl-3 pr-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form', [_c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Shipment Planning",
                  "label-for": "inventoryID"
                }
              }, [_vm._l(_vm.shipmentPlanning, function (item, index) {
                return [_c('b-form-radio', {
                  key: index,
                  attrs: {
                    "inline": "",
                    "name": item.name,
                    "value": item.value,
                    "disabled": item.disabled
                  },
                  model: {
                    value: _vm.shipmentPlanningActive[item[Object.keys(item)[0]]],
                    callback: function callback($$v) {
                      _vm.$set(_vm.shipmentPlanningActive, item[Object.keys(item)[0]], $$v);
                    },
                    expression: "shipmentPlanningActive[item[Object.keys(item)[0]]]"
                  }
                }, [_vm._v(_vm._s(item.label))])];
              })], 2), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "PFA ID",
                  "label-for": "inventoryID"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "orderNumber",
                  "placeholder": "<< CREATE PFA ID >>"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Name Pfa",
                  "label-for": "descriptionItem"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "descriptionItem"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Voyage",
                  "label-for": "uom"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": ""
                }
              }, [_c('b-form-select-option', [_vm._v("1")]), _c('b-form-select-option', [_vm._v("2")]), _c('b-form-select-option', [_vm._v("3")]), _c('b-form-select-option', [_vm._v("4")])], 1)], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Port of Loading",
                  "label-for": "descriptionItem"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "text",
                  "id": "descriptionItem"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Port of Dischare",
                  "label-for": "descriptionItem"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "text",
                  "id": "descriptionItem"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Revenue",
                  "label-for": "location"
                }
              }, [_c('h3', [_c('b-badge', {
                attrs: {
                  "variant": "success"
                }
              }, [_vm._v("IDR 1.050.000.000")])], 1)])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form', [_c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "From Planning",
                  "label-for": "inventoryID"
                }
              }, [_c('v-multiselect', {
                attrs: {
                  "options": _vm.projectOptions,
                  "group-values": "sub",
                  "group-label": "project",
                  "placeholder": "Select one",
                  "track-by": "name",
                  "label": "name"
                },
                model: {
                  value: _vm.projectValue,
                  callback: function callback($$v) {
                    _vm.projectValue = $$v;
                  },
                  expression: "projectValue"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Tugboat",
                  "label-for": "Department"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionDepartment,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Tugboat")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectDepartment,
                  callback: function callback($$v) {
                    _vm.selectDepartment = $$v;
                  },
                  expression: "selectDepartment"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Barge",
                  "label-for": "Department"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionDepartment,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Barge")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectDepartment,
                  callback: function callback($$v) {
                    _vm.selectDepartment = $$v;
                  },
                  expression: "selectDepartment"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Kind of Cargo",
                  "label-for": "picName"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": ""
                }
              }, [_c('b-form-select-option', [_vm._v("Coal")]), _c('b-form-select-option', [_vm._v("Nickel")]), _c('b-form-select-option', [_vm._v("CPO")]), _c('b-form-select-option', [_vm._v("HSD")]), _c('b-form-select-option', [_vm._v("Split Stone")]), _c('b-form-select-option', [_vm._v("Others")])], 1)], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Quantity",
                  "label-for": "location"
                }
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "5"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": ""
                }
              }, [_c('b-form-select-option', [_vm._v("㎥")]), _c('b-form-select-option', [_vm._v("Tonnage")]), _c('b-form-select-option', [_vm._v("Lump Sum")]), _c('b-form-select-option', [_vm._v("Liter")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "7"
                }
              }, [_c('b-form-input', {
                staticClass: "text-right",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "qty"
                }
              })], 1)], 1)], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Freight",
                  "label-for": "location"
                }
              }, [_c('b-form-input', {
                staticClass: "text-right",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "qty"
                }
              })], 1)], 1)], 1)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h5 text-primary"
              }, [_vm._v("Distace, Speed, Est Time(Hour)")]), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('div', {
                staticClass: "table-responsive"
              }, [_c('table', {
                staticClass: "table table-striped"
              }, [_c('thead', [_c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("Laden")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("Ballast")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("Est Dist (NM)")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("Speed")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("Est Time(Hour)")])])]), _c('tbody', [_c('tr', [_c('td', [_c('b-form-input', {
                staticClass: "text-left",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "text",
                  "id": "qty",
                  "value": "Jetty CLM"
                }
              })], 1), _c('td', [_c('b-form-input', {
                staticClass: "text-left",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "text",
                  "id": "qty",
                  "value": "OSS Morosi"
                }
              })], 1), _c('td', [_c('b-form-input', {
                staticClass: "text-center",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "qty",
                  "value": "310"
                }
              })], 1), _c('td', [_c('b-form-input', {
                staticClass: "text-center",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "qty",
                  "value": "3.5"
                }
              })], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('h5', [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("88.57")])], 1)])]), _c('tr', [_c('td', [_c('b-form-input', {
                staticClass: "text-left",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "text",
                  "id": "qty",
                  "value": "OSS Morosi"
                }
              })], 1), _c('td', [_c('b-form-input', {
                staticClass: "text-left",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "text",
                  "id": "qty",
                  "value": "Jetty CLM"
                }
              })], 1), _c('td', [_c('b-form-input', {
                staticClass: "text-center",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "qty",
                  "value": "310"
                }
              })], 1), _c('td', [_c('b-form-input', {
                staticClass: "text-center",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "qty",
                  "value": "5.5"
                }
              })], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('h5', [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("56.36")])], 1)])]), _c('tr', [_c('td', {
                attrs: {
                  "colspan": "5"
                }
              }, [_c('b-button', {
                attrs: {
                  "variant": "info",
                  "block": ""
                }
              }, [_c('i', {
                staticClass: "fa fa-plus"
              }), _vm._v(" Add Data")])], 1)])])])])])], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h5 text-primary"
              }, [_vm._v("Cost Factor")]), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('div', {
                staticClass: "table-responsive"
              }, [_c('table', {
                staticClass: "table table-striped"
              }, [_c('thead', [_c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("Item")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("Price")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("Charter Hire / Day")]), _c('td', [_c('b-form-input', {
                staticClass: "text-center",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "qty"
                }
              })], 1)]), _c('tr', [_c('td', [_vm._v("Bunker Cost / litre")]), _c('td', [_c('b-form-input', {
                staticClass: "text-center",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "qty"
                }
              })], 1)]), _c('tr', [_c('td', [_vm._v("F Water Cost / MT")]), _c('td', [_c('b-form-input', {
                staticClass: "text-center",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "qty"
                }
              })], 1)])])])])])], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h5 text-primary"
              }, [_vm._v("Time Voyage")]), _c('b-row', [_c('b-col', {
                staticClass: "mb-4",
                attrs: {
                  "md": "12"
                }
              }, [_c('div', {
                staticClass: "table-responsive"
              }, [_c('table', {
                staticClass: "table table-striped"
              }, [_c('thead', [_c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("Distances steamed (Ballast / Laden)")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("Steaming Time / hours")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("Cons")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("Present position to load port")]), _c('td', [_c('b-form-input', {
                staticClass: "text-center",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "qty"
                }
              })], 1), _c('td')]), _c('tr', [_c('td', [_vm._v("Manouver Loading / Discharging")]), _c('td', [_c('b-form-input', {
                staticClass: "text-center",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "qty"
                }
              })], 1), _c('td')]), _c('tr', [_c('td', [_vm._v("Waiting Time")]), _c('td', [_c('b-form-input', {
                staticClass: "text-center",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "qty"
                }
              })], 1), _c('td')]), _c('tr', [_c('td', [_vm._v("Laden")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v(" 88,57 ")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("11.071,43")])]), _c('tr', [_c('td', [_vm._v("Ballast/MT")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v(" 56,36 ")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("7.045,45")])]), _c('tr', [_c('td', [_vm._v("Manouver Loading / Discharging")]), _c('td', {
                staticClass: "text-center"
              }), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("1.000,00")])]), _c('tr', [_c('td', [_vm._v("A/E OB")]), _c('td', {
                staticClass: "text-center"
              }), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("1.000,00")])])]), _c('tfoot', [_c('tr', [_c('td', {
                staticClass: "bg-warning"
              }, [_vm._v("Total duration(hour/Day) & Consumption")]), _c('td', {
                staticClass: "bg-info"
              }, [_vm._v("144,94")]), _c('td', {
                staticClass: "bg-info"
              }, [_vm._v("19.116,88 ")])])])])])]), _c('b-col', {
                staticClass: "mb-4",
                attrs: {
                  "md": "12"
                }
              }, [_c('div', {
                staticClass: "table-responsive"
              }, [_c('table', {
                staticClass: "table table-striped"
              }, [_c('thead', [_c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("Port Stay (load/discharge)")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("Steaming Time / day")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("Idling/waiting")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("Port stay at LP (est) ")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v(" 4 ")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("500,00")])]), _c('tr', [_c('td', [_vm._v("Port Stay at DP (est)")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v(" 4 ")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("500,00")])]), _c('tr', [_c('td', [_vm._v("A/E Barge")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v(" 0 ")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("0,00")])]), _c('tr', [_c('td', [_vm._v("Pump Discharge")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v(" 0 ")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("0,00")])])]), _c('tfoot', [_c('tr', [_c('td', {
                staticClass: "bg-warning"
              }, [_vm._v("Total duration & Total Bunker Consumption")]), _c('td', {
                staticClass: "bg-info"
              }, [_vm._v("8,00")]), _c('td', {
                staticClass: "bg-info"
              }, [_vm._v("1.000,00 ")])]), _c('tr', [_c('td', {
                staticClass: "bg-warning"
              }, [_vm._v("Grand total duration (day) & consumption ")]), _c('td', {
                staticClass: "bg-info"
              }, [_vm._v("14,04")]), _c('td', {
                staticClass: "bg-info"
              }, [_vm._v("20.116,88")])])])])])])], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h5 text-primary"
              }, [_vm._v("Miscellaneous Charge")]), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('div', {
                staticClass: "table-responsive"
              }, [_c('table', {
                staticClass: "table table-striped"
              }, [_c('thead', [_c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("Item")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("Cost")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("Liner Term")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v(" - ")])]), _c('tr', [_c('td', [_vm._v("Freigt Tax (1,2 %)")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v(" Rp. 12.600.000,- ")])]), _c('tr', [_c('td', [_vm._v("C/E/V")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v(" - ")])]), _c('tr', [_c('td', [_vm._v("Insurance")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v(" - ")])]), _c('tr', [_c('td', [_vm._v("Premi Kapal")]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-input', {
                staticClass: "text-center",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "qty"
                }
              })], 1)]), _c('tr', [_c('td', [_vm._v("Channel Fee")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v(" - ")])])]), _c('tfoot', [_c('tr', [_c('td', [_c('strong', [_vm._v("TOTAL")])]), _c('td', {
                staticClass: "text-center bg-success"
              }, [_vm._v("Rp28.600.000,00 ")])])])])])])], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h5 text-primary"
              }, [_vm._v("Operational Expenses")]), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('div', {
                staticClass: "table-responsive"
              }, [_c('table', {
                staticClass: "table table-striped"
              }, [_c('thead', [_c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("Operation")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("Expense")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("Total charter hire costs")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v(" Rp292.478.355 ")])]), _c('tr', [_c('td', [_vm._v("Bunker costs")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v(" Rp412.396.104 ")])]), _c('tr', [_c('td', [_vm._v("Fresh water cost ")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v(" Rp412.396.104 ")])]), _c('tr', [_c('td', [_vm._v("Agency Charge / costs (load port) ")]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-input', {
                staticClass: "text-center",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "qty"
                }
              })], 1)]), _c('tr', [_c('td', [_vm._v("Agency Charge / costs (discharge port)")]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-input', {
                staticClass: "text-center",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "qty"
                }
              })], 1)]), _c('tr', [_c('td', [_vm._v("Surveyor Loading & Discharging Port ")]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-input', {
                staticClass: "text-center",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "qty"
                }
              })], 1)]), _c('tr', [_c('td', [_vm._v("Jasa Ramdoor")]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-input', {
                staticClass: "text-center",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "qty"
                }
              })], 1)]), _c('tr', [_c('td', [_vm._v("Assist & Pandu / Buka Tutup Terpal")]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-input', {
                staticClass: "text-center",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "qty"
                }
              })], 1)]), _c('tr', [_c('td', [_vm._v("Cleaning Barge")]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-input', {
                staticClass: "text-center",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "qty"
                }
              })], 1)]), _c('tr', [_c('td', [_vm._v("PNI Surveyor")]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-input', {
                staticClass: "text-center",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "qty"
                }
              })], 1)]), _c('tr', [_c('td', [_vm._v("Marketing Fee")]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-input', {
                staticClass: "text-center",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "qty"
                }
              })], 1)]), _c('tr', [_c('td', [_vm._v("Terpal")]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-input', {
                staticClass: "text-center",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "qty"
                }
              })], 1)]), _c('tr', [_c('td', [_vm._v("Others")]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-input', {
                staticClass: "text-center",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "qty"
                }
              })], 1)])]), _c('tfoot', [_c('tr', [_c('td', [_c('strong', [_vm._v("Total Operational Expenses")])]), _c('td', {
                staticClass: "text-center bg-primary"
              }, [_vm._v(" Rp768.182.251 ")])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Revenue ")])]), _c('td', {
                staticClass: "text-center bg-primary"
              }, [_vm._v(" Rp1.050.000.000 ")])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Grand Total Expenses ")])]), _c('td', {
                staticClass: "text-center bg-primary"
              }, [_vm._v(" Rp796.782.251 ")])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Profit ")])]), _c('td', {
                staticClass: "text-center bg-success"
              }, [_vm._v(" Rp253.217.749 ")])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Profit %")])]), _c('td', {
                staticClass: "text-center bg-success"
              }, [_vm._v(" 31,78% "), _c('small', [_vm._v("from Equity ( Grand Total Expenses)")])])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Profit Per MT")])]), _c('td', {
                staticClass: "text-center bg-success"
              }, [_vm._v(" 33.762,367 ")])])])])])])], 1)], 1)]), _c('b-col', {
                staticClass: "mt-5 mb-4",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-button', {
                attrs: {
                  "variant": "primary",
                  "block": ""
                }
              }, [_vm._v("Submit")])], 1)], 1)];
            }
          }]),
          model: {
            value: _vm.addPurchaseRequest,
            callback: function callback($$v) {
              _vm.addPurchaseRequest = $$v;
            },
            expression: "addPurchaseRequest"
          }
        }), _c('b-sidebar', {
          attrs: {
            "id": "spalHistory",
            "title": "SPAL / Contract Process",
            "width": "60%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var hide = _ref5.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h5 text-primary"
              }, [_vm._v("General Information "), _c('span', [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.generalInformation",
                  modifiers: {
                    "generalInformation": true
                  }
                }],
                attrs: {
                  "size": "sm",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-eye pr-0"
              }), _vm._v(" Detail ")])], 1)]), _c('b-collapse', {
                attrs: {
                  "id": "generalInformation"
                }
              }, [_c('b-row', {
                staticClass: "pl-3 pr-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form', [_c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Shipment Planning",
                  "label-for": "inventoryID"
                }
              }, [_vm._l(_vm.shipmentPlanning, function (item, index) {
                return [_c('b-form-radio', {
                  key: index,
                  attrs: {
                    "inline": "",
                    "name": item.name,
                    "value": item.value,
                    "disabled": item.disabled
                  },
                  model: {
                    value: _vm.shipmentPlanningActive[item[Object.keys(item)[0]]],
                    callback: function callback($$v) {
                      _vm.$set(_vm.shipmentPlanningActive, item[Object.keys(item)[0]], $$v);
                    },
                    expression: "shipmentPlanningActive[item[Object.keys(item)[0]]]"
                  }
                }, [_vm._v(_vm._s(item.label))])];
              })], 2), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "PFA ID",
                  "label-for": "inventoryID"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "id": "orderNumber",
                  "placeholder": "<< CREATE PFA ID >>"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Name Pfa",
                  "label-for": "descriptionItem"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "descriptionItem"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Voyage",
                  "label-for": "uom"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": ""
                }
              }, [_c('b-form-select-option', [_vm._v("1")]), _c('b-form-select-option', [_vm._v("2")]), _c('b-form-select-option', [_vm._v("3")]), _c('b-form-select-option', [_vm._v("4")])], 1)], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Port of Loading",
                  "label-for": "descriptionItem"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "text",
                  "id": "descriptionItem"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Port of Dischare",
                  "label-for": "descriptionItem"
                }
              }, [_c('b-form-input', {
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "text",
                  "id": "descriptionItem"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Revenue",
                  "label-for": "location"
                }
              }, [_c('h3', [_c('b-badge', {
                attrs: {
                  "variant": "success"
                }
              }, [_vm._v("IDR 1.050.000.000")])], 1)])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "6"
                }
              }, [_c('b-form', [_c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "From Planning",
                  "label-for": "inventoryID"
                }
              }, [_c('v-multiselect', {
                attrs: {
                  "options": _vm.projectOptions,
                  "group-values": "sub",
                  "group-label": "project",
                  "placeholder": "Select one",
                  "track-by": "name",
                  "label": "name"
                },
                model: {
                  value: _vm.projectValue,
                  callback: function callback($$v) {
                    _vm.projectValue = $$v;
                  },
                  expression: "projectValue"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Tugboat",
                  "label-for": "Department"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionDepartment,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Tugboat")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectDepartment,
                  callback: function callback($$v) {
                    _vm.selectDepartment = $$v;
                  },
                  expression: "selectDepartment"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Barge",
                  "label-for": "Department"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionDepartment,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Barge")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectDepartment,
                  callback: function callback($$v) {
                    _vm.selectDepartment = $$v;
                  },
                  expression: "selectDepartment"
                }
              })], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Kind of Cargo",
                  "label-for": "picName"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": ""
                }
              }, [_c('b-form-select-option', [_vm._v("Coal")]), _c('b-form-select-option', [_vm._v("Nickel")]), _c('b-form-select-option', [_vm._v("CPO")]), _c('b-form-select-option', [_vm._v("ASD")]), _c('b-form-select-option', [_vm._v("Split Stone")]), _c('b-form-select-option', [_vm._v("Others")])], 1)], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Quantity",
                  "label-for": "location"
                }
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "5"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": ""
                }
              }, [_c('b-form-select-option', [_vm._v("M3")]), _c('b-form-select-option', [_vm._v("Tonnage")])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "7"
                }
              }, [_c('b-form-input', {
                staticClass: "text-right",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "qty"
                }
              })], 1)], 1)], 1), _c('b-form-group', {
                staticClass: "row",
                attrs: {
                  "label-cols-md": "4",
                  "label": "Freight",
                  "label-for": "location"
                }
              }, [_c('b-form-input', {
                staticClass: "text-right",
                staticStyle: {
                  "height": "40px"
                },
                attrs: {
                  "type": "number",
                  "id": "qty"
                }
              })], 1)], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('table', {
                staticClass: "table table-striped"
              }, [_c('tfoot', [_c('tr', [_c('td', [_c('strong', [_vm._v("Total Operational Expenses")])]), _c('td', {
                staticClass: "text-center bg-primary"
              }, [_vm._v(" Rp768.182.251 ")])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Grand Total Expenses ")])]), _c('td', {
                staticClass: "text-center bg-primary"
              }, [_vm._v(" Rp796.782.251 ")])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Profit ")])]), _c('td', {
                staticClass: "text-center bg-success"
              }, [_vm._v(" Rp253.217.749 ")])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Profit %")])]), _c('td', {
                staticClass: "text-center bg-success"
              }, [_vm._v(" 31,78% "), _c('small', [_vm._v("from Equity ( Grand Total Expenses)")])])])])])])], 1)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3 bg-default"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h5 text-primary"
              }, [_vm._v("SPAL / Contract Input")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Title",
                  "label-for": "date"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "start"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "File Upload",
                  "label-for": "berita_acara"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile"
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', [_vm._v("If you want to upload multiple file, press \"ctrl / command\" in your keyboard")])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Type Text ..."
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Is Fix SPAL / CONTRACT ?",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.fix, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1), _c('b-col', [_c('b-button', {
                staticClass: "pull-right",
                attrs: {
                  "type": "submit",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-save"
              }), _vm._v(" Submit Data")])], 1)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h5 text-primary"
              }, [_vm._v("History")]), _c('b-row', {
                staticClass: "pl-3 pr-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('table', {
                staticClass: "table table-striped"
              }, [_c('thead', [_c('tr', [_c('th', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v("No")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Title")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("File")]), _c('th', [_vm._v("Notes")]), _c('th', {
                attrs: {
                  "width": "15%"
                }
              }, [_vm._v("Comment")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v("1.")]), _c('td', [_vm._v("PFA")]), _c('td', [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image",
                  "height": "50px"
                }
              })])]), _c('td', [_vm._v("Generated By System")]), _c('td', [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.comment",
                  modifiers: {
                    "comment": true
                  }
                }],
                attrs: {
                  "size": "sm",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-comment pr-0"
              }), _vm._v(" Comment ")])], 1)]), _c('tr', [_c('td', {
                attrs: {
                  "colspan": "5"
                }
              }, [_c('b-collapse', {
                attrs: {
                  "id": "comment"
                }
              }, [_c('table', {
                staticClass: "table mb-0",
                attrs: {
                  "width": "100%"
                }
              }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Comment")])])]), _c('tbody', [_c('tr', [_c('td', [_c('h6', {
                staticClass: "float-left mb-1"
              }, [_c('strong', [_vm._v("Tri Leksono")])]), _c('br'), _c('small', [_vm._v("22 November 2019 10:12")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Revisi dibagian Muatan dong")])])])]), _c('tr', [_c('td', [_c('div', {
                staticClass: "chat-footer p-3 bg-white"
              }, [_c('form', {
                staticClass: "d-flex align-items-center",
                attrs: {
                  "action": "javascript:void(0);"
                }
              }, [_c('div', {
                staticClass: "chat-attagement d-flex"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0)"
                }
              }, [_c('i', {
                staticClass: "fa fa-smile-o pr-3",
                attrs: {
                  "aria-hidden": "true"
                }
              })]), _c('a', {
                attrs: {
                  "href": "javascript:void(0)"
                }
              }, [_c('i', {
                staticClass: "fa fa-paperclip pr-3",
                attrs: {
                  "aria-hidden": "true"
                }
              })])]), _c('input', {
                staticClass: "form-control mr-3",
                attrs: {
                  "type": "text",
                  "placeholder": "Type your message"
                }
              }), _c('button', {
                staticClass: "btn btn-primary d-flex align-items-center p-2",
                attrs: {
                  "type": "submit"
                }
              }, [_c('i', {
                staticClass: "fas fa-paper-plane",
                attrs: {
                  "aria-hidden": "true"
                }
              }), _c('span', {
                staticClass: "d-none d-lg-block ml-1"
              }, [_vm._v("Send")])])])])])])])])])], 1)]), _c('tr', [_c('td', [_vm._v("2.")]), _c('td', [_vm._v("Shipping Instruction")]), _c('td', [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image",
                  "height": "50px"
                }
              })])]), _c('td', [_vm._v("-")]), _c('td', [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.comment2",
                  modifiers: {
                    "comment2": true
                  }
                }],
                attrs: {
                  "size": "sm",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-comment pr-0"
              }), _vm._v(" Comment ")])], 1)]), _c('tr', [_c('td', {
                attrs: {
                  "colspan": "5"
                }
              }, [_c('b-collapse', {
                attrs: {
                  "id": "comment2"
                }
              }, [_c('table', {
                staticClass: "table mb-0",
                attrs: {
                  "width": "100%"
                }
              }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Comment")])])]), _c('tbody', [_c('tr', [_c('td', [_c('h6', {
                staticClass: "float-left mb-1"
              }, [_c('strong', [_vm._v("Tri Leksono")])]), _c('br'), _c('small', [_vm._v("22 November 2019 10:12")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Revisi dibagian Muatan dong")])])])]), _c('tr', [_c('td', [_c('div', {
                staticClass: "chat-footer p-3 bg-white"
              }, [_c('form', {
                staticClass: "d-flex align-items-center",
                attrs: {
                  "action": "javascript:void(0);"
                }
              }, [_c('div', {
                staticClass: "chat-attagement d-flex"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0)"
                }
              }, [_c('i', {
                staticClass: "fa fa-smile-o pr-3",
                attrs: {
                  "aria-hidden": "true"
                }
              })]), _c('a', {
                attrs: {
                  "href": "javascript:void(0)"
                }
              }, [_c('i', {
                staticClass: "fa fa-paperclip pr-3",
                attrs: {
                  "aria-hidden": "true"
                }
              })])]), _c('input', {
                staticClass: "form-control mr-3",
                attrs: {
                  "type": "text",
                  "placeholder": "Type your message"
                }
              }), _c('button', {
                staticClass: "btn btn-primary d-flex align-items-center p-2",
                attrs: {
                  "type": "submit"
                }
              }, [_c('i', {
                staticClass: "fas fa-paper-plane",
                attrs: {
                  "aria-hidden": "true"
                }
              }), _c('span', {
                staticClass: "d-none d-lg-block ml-1"
              }, [_vm._v("Send")])])])])])])])])])], 1)]), _c('tr', [_c('td', [_vm._v("3.")]), _c('td', [_vm._v("Draft SPAL / Contract")]), _c('td', [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image",
                  "height": "50px"
                }
              })])]), _c('td', [_vm._v("-")]), _c('td', [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.comment3",
                  modifiers: {
                    "comment3": true
                  }
                }],
                attrs: {
                  "size": "sm",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-comment pr-0"
              }), _vm._v(" Comment ")])], 1)]), _c('tr', [_c('td', {
                attrs: {
                  "colspan": "5"
                }
              }, [_c('b-collapse', {
                attrs: {
                  "id": "comment3"
                }
              }, [_c('table', {
                staticClass: "table mb-0",
                attrs: {
                  "width": "100%"
                }
              }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Comment")])])]), _c('tbody', [_c('tr', [_c('td', [_c('h6', {
                staticClass: "float-left mb-1"
              }, [_c('strong', [_vm._v("Tri Leksono")])]), _c('br'), _c('small', [_vm._v("22 November 2019 10:12")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Revisi dibagian Muatan dong")])])])]), _c('tr', [_c('td', [_c('div', {
                staticClass: "chat-footer p-3 bg-white"
              }, [_c('form', {
                staticClass: "d-flex align-items-center",
                attrs: {
                  "action": "javascript:void(0);"
                }
              }, [_c('div', {
                staticClass: "chat-attagement d-flex"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0)"
                }
              }, [_c('i', {
                staticClass: "fa fa-smile-o pr-3",
                attrs: {
                  "aria-hidden": "true"
                }
              })]), _c('a', {
                attrs: {
                  "href": "javascript:void(0)"
                }
              }, [_c('i', {
                staticClass: "fa fa-paperclip pr-3",
                attrs: {
                  "aria-hidden": "true"
                }
              })])]), _c('input', {
                staticClass: "form-control mr-3",
                attrs: {
                  "type": "text",
                  "placeholder": "Type your message"
                }
              }), _c('button', {
                staticClass: "btn btn-primary d-flex align-items-center p-2",
                attrs: {
                  "type": "submit"
                }
              }, [_c('i', {
                staticClass: "fas fa-paper-plane",
                attrs: {
                  "aria-hidden": "true"
                }
              }), _c('span', {
                staticClass: "d-none d-lg-block ml-1"
              }, [_vm._v("Send")])])])])])])])])])], 1)])])])])], 1)], 1)])], 1)];
            }
          }]),
          model: {
            value: _vm.spalHistory,
            callback: function callback($$v) {
              _vm.spalHistory = $$v;
            },
            expression: "spalHistory"
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "realisasi"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("REALISASI")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('b-dropdown', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Export Data',
            expression: "'Export Data'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "link iq-bg-primary",
            "toggle-class": "text-decoration-none",
            "no-caret": "",
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-save m-0"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-excel mr-2"
        }), _vm._v("Export Excel")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#",
            "variant": "dark"
          }
        }, [_c('i', {
          staticClass: "fa fa-file-pdf mr-2"
        }), _vm._v("Export PDF")])], 1), _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.top",
            value: 'Refresh Data',
            expression: "'Refresh Data'",
            modifiers: {
              "top": true
            }
          }],
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "primary",
            "size": "sm"
          }
        }, [_c('i', {
          staticClass: "fa fa-refresh"
        })]), _c('b-button', {
          staticClass: "float-right mr-1",
          attrs: {
            "variant": "primary",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              _vm.realisasiData = !_vm.realisasiData;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v("   ADD REALISASI DATA")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Period",
            "label-for": "Period"
          }
        }, [_c('date-picker', {
          attrs: {
            "lang": _vm.lang,
            "type": "date",
            "range": "",
            "placeholder": "Select date range"
          },
          model: {
            value: _vm.dateRange,
            callback: function callback($$v) {
              _vm.dateRange = $$v;
            },
            expression: "dateRange"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Cargo Type",
            "label-for": "cargo"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": ""
          }
        }, [_c('b-form-select-option', [_vm._v("All")]), _c('b-form-select-option', [_vm._v("COAL")]), _c('b-form-select-option', [_vm._v("SPLIT STONE")]), _c('b-form-select-option', [_vm._v("NICKEL")]), _c('b-form-select-option', [_vm._v("CPO")]), _c('b-form-select-option', [_vm._v("ASD")]), _c('b-form-select-option', [_vm._v("OTHERS")])], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Ownership",
            "label-for": "cargo"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": ""
          }
        }, [_c('b-form-select-option', [_vm._v("All")]), _c('b-form-select-option', [_vm._v("Own Ship")]), _c('b-form-select-option', [_vm._v("Third Party")])], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Keyword",
            "label-for": "keyword"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "id": "keyword"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table",
          staticStyle: {
            "width": "5000px",
            "max-height": "750px",
            "overflow-y": "scroll"
          }
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center table-info",
          staticStyle: {
            "position": "sticky",
            "left": "0px",
            "width": "50px"
          }
        }, [_vm._v("NO")]), _c('th', {
          staticClass: "text-center table-info",
          staticStyle: {
            "position": "sticky",
            "left": "50px",
            "width": "150px"
          }
        }, [_vm._v("SHIPPER")]), _c('th', {
          staticClass: "text-center table-info",
          staticStyle: {
            "position": "sticky",
            "left": "200px",
            "width": "250px"
          }
        }, [_vm._v("SHIPMENT")]), _c('th', {
          staticClass: "text-center table-info",
          staticStyle: {
            "position": "sticky",
            "left": "450px",
            "width": "100px"
          }
        }, [_vm._v("STATUS")]), _c('th', {
          staticClass: "text-center table-info"
        }, [_vm._v("MODA")]), _c('th', {
          staticClass: "text-center table-info",
          staticStyle: {
            "width": "200px"
          }
        }, [_vm._v("FLEET")]), _c('th', {
          staticClass: "text-center table-info",
          staticStyle: {
            "width": "200px"
          }
        }, [_vm._v("COMPANY")]), _c('th', {
          staticClass: "text-center table-info",
          staticStyle: {
            "width": "150px"
          }
        }, [_vm._v("CARGO TYPE")]), _c('th', {
          staticClass: "text-center table-info",
          staticStyle: {
            "width": "150px"
          }
        }, [_vm._v("POL")]), _c('th', {
          staticClass: "text-center table-info",
          staticStyle: {
            "width": "150px"
          }
        }, [_vm._v("TA POL/READY")]), _c('th', {
          staticClass: "text-center table-info",
          staticStyle: {
            "width": "150px"
          }
        }, [_vm._v("BERTHING")]), _c('th', {
          staticClass: "text-center table-info",
          staticStyle: {
            "width": "150px"
          }
        }, [_vm._v("COMMENCED")]), _c('th', {
          staticClass: "text-center table-info"
        }, [_vm._v("COMPLETED")]), _c('th', {
          staticClass: "text-center table-info"
        }, [_vm._v("CARGO LOADING")]), _c('th', {
          staticClass: "text-center table-info"
        }, [_vm._v("POD")]), _c('th', {
          staticClass: "text-center table-info"
        }, [_vm._v("TA POD/READY")]), _c('th', {
          staticClass: "text-center table-info"
        }, [_vm._v("BERTHING")]), _c('th', {
          staticClass: "text-center table-info"
        }, [_vm._v("COMMENCED")]), _c('th', {
          staticClass: "text-center table-info"
        }, [_vm._v("COMPLETED")]), _c('th', {
          staticClass: "text-center table-info"
        }, [_vm._v("CARGO UNLOADING")]), _c('th', {
          staticClass: "text-center table-info"
        }, [_vm._v("PRICE")]), _c('th', {
          staticClass: "text-center table-info"
        }, [_vm._v("PRICE TO VENDOR")]), _c('th', {
          staticClass: "text-center table-info"
        }, [_vm._v("ACTION")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-center",
          staticStyle: {
            "position": "sticky",
            "left": "0px",
            "width": "50px",
            "background-color": "white"
          }
        }, [_vm._v("1")]), _c('td', {
          staticClass: "text-center",
          staticStyle: {
            "position": "sticky",
            "left": "50px",
            "width": "150px",
            "background-color": "white"
          }
        }, [_vm._v("PLN Rembang - Longhauling"), _c('br'), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("PT.Arutmin Indonesia")])], 1), _c('td', {
          staticClass: "text-center",
          staticStyle: {
            "position": "sticky",
            "left": "200px",
            "width": "250px",
            "background-color": "white"
          }
        }, [_vm._v("JANUARY")]), _c('td', {
          staticClass: "text-center",
          staticStyle: {
            "position": "sticky",
            "left": "450px",
            "width": "100px",
            "background-color": "white"
          }
        }, [_c('b-badge', {
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("Completed")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("330 ft")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("PT.TCP 201/PB.TCP 3301")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("PT.Transcoal Pacific")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Nickel")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("MP Asam2")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("31-Dec-21 00.00")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("02-Jan-22 02.10")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("02-Jan-22 03.05")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("02-Jan-22 05.05")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("7.606 MT")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("LABUAN")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("04-Jan-22 20.00")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("04-Jan-22 20.00")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("04-Jan-22 20.00")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("04-Jan-22 20.00")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("7.506 MT")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("IDR 198.078")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.add-activity",
            modifiers: {
              "add-activity": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-info-circle mr-1"
        }), _vm._v("Show ")]), _c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-trash mr-1"
        }), _vm._v("Delete ")])], 1)], 1)])])])])]), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "value": 1,
            "total-rows": 50,
            "align": "center"
          }
        })], 1)])], 1), _c('b-sidebar', {
          attrs: {
            "id": "realisasiData",
            "title": "ADD REALISASI DATA",
            "width": "90%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref6) {
              var hide = _ref6.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3 bg-default"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h5 text-primary"
              }, [_vm._v("Find Data")]), _c('b-form-row', [_c('b-col', {
                attrs: {
                  "md": "2"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Realisasi Period",
                  "label-for": "Period"
                }
              }, [_c('date-picker', {
                attrs: {
                  "lang": _vm.lang,
                  "type": "month",
                  "placeholder": "Select Month"
                },
                model: {
                  value: _vm.realisasiPeriod,
                  callback: function callback($$v) {
                    _vm.realisasiPeriod = $$v;
                  },
                  expression: "realisasiPeriod"
                }
              })], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label-for": "dateIssued",
                  "label": "Project Filter"
                }
              }, [_c('v-multiselect', {
                attrs: {
                  "options": _vm.projectOptions,
                  "multiple": true,
                  "group-values": "sub",
                  "group-label": "project",
                  "group-select": true,
                  "placeholder": "Select one",
                  "track-by": "name",
                  "label": "name"
                },
                model: {
                  value: _vm.filterDailyOps.subProject,
                  callback: function callback($$v) {
                    _vm.$set(_vm.filterDailyOps, "subProject", $$v);
                  },
                  expression: "filterDailyOps.subProject"
                }
              }, [_c('span', {
                attrs: {
                  "slot": "noResult"
                },
                slot: "noResult"
              }, [_vm._v("Oops! No elements found. Consider changing the search query.")])])], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "2"
                }
              }, [_c('b-form-group', {
                staticClass: "mb-0",
                attrs: {
                  "label": "Search",
                  "label-for": "kind-fleet"
                }
              }, [_c('b-button', {
                attrs: {
                  "type": "submit",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-search"
              })])], 1)], 1)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h5 text-primary"
              }, [_vm._v("Select Shipper Data")]), _c('b-row', {
                staticClass: "pl-3 pr-3"
              }, [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-warning",
                attrs: {
                  "show": true,
                  "variant": " "
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "ri-information-fill"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Not Found Data of Shipper in this period")])])], 1), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('div', {
                staticClass: "table-responsive"
              }, [_c('table', {
                staticClass: "table table-striped table-sm"
              }, [_c('thead', [_c('tr', {
                staticClass: "bg-primary"
              }, [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("NO")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("TYPE")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("PROJECT")])])]), _c('tbody', [_c('tr', {
                staticClass: "iq-bg-primary"
              }, [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("1")]), _c('th', {
                staticClass: "text-center"
              }, [_c('b-badge', {
                staticClass: "border border-primary text-primary",
                attrs: {
                  "variant": "none"
                }
              }, [_vm._v("EXISTING")])], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('strong', [_vm._v("ARUTMIN")])])]), _c('tr', [_c('td', {
                attrs: {
                  "colspan": "3"
                }
              }, [_c('table', {
                staticClass: "table mb-0",
                attrs: {
                  "width": "100%"
                }
              }, [_c('thead', [_c('tr', {
                staticClass: "bg-info"
              }, [_c('th', [_vm._v("#")]), _c('th', {
                attrs: {
                  "width": "17%"
                }
              }, [_vm._v("Description")]), _c('th', [_vm._v("Fleet")]), _c('th', [_vm._v("Cargo & Term")]), _c('th', [_vm._v("Location")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("Shipment Plan")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("Plan Quantity")]), _c('th', [_vm._v("Remarks")])])]), _c('tbody', [_c('tr', [_c('td', [_c('input', {
                attrs: {
                  "type": "checkbox",
                  "name": "shipper1",
                  "id": "shipper1"
                }
              })]), _c('td', [_vm._v("PLN Rembang")]), _c('td', [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Third Party")]), _c('br'), _vm._v(" TB.Dharma 1")], 1), _c('td', [_c('b-form-group', {
                staticClass: "row mb-2",
                attrs: {
                  "label-cols-md": "5",
                  "label": "CARGO TYPE",
                  "label-for": "descriptionItem"
                }
              }, [_c('p', [_c('strong', [_vm._v("COAL")])])]), _c('b-form-group', {
                staticClass: "row mb-2",
                attrs: {
                  "label-cols-md": "5",
                  "label": "TERM",
                  "label-for": "descriptionItem"
                }
              }, [_c('p', [_c('strong', [_vm._v("CIF")])])])], 1), _c('td', [_c('b-form-group', {
                staticClass: "row mb-2",
                attrs: {
                  "label-cols-md": "4",
                  "label": "POL",
                  "label-for": "descriptionItem"
                }
              }, [_c('p', [_c('strong', [_vm._v("Paiton")])])]), _c('b-form-group', {
                staticClass: "row mb-2",
                attrs: {
                  "label-cols-md": "4",
                  "label": "POD",
                  "label-for": "descriptionItem"
                }
              }, [_c('p', [_c('strong', [_vm._v("Rembang")])])])], 1), _c('td', [_c('b-row', [_c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "12"
                }
              }, [_c('strong', [_vm._v("Januari")])]), _c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "6"
                }
              }, [_c('strong', [_vm._v("ETA POL")])]), _c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "6"
                }
              }, [_c('strong', [_vm._v("ETA POD")])]), _c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "6"
                }
              }, [_c('p', [_vm._v("10 Januari 2023")])]), _c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "6"
                }
              }, [_c('p', [_vm._v("16 Januari 2023")])])], 1)], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('strong', [_vm._v("7.000 MT")])]), _c('td', [_vm._v("-")])]), _c('tr', [_c('td', [_c('input', {
                attrs: {
                  "type": "checkbox",
                  "name": "shipper2",
                  "id": "shipper2"
                }
              })]), _c('td', [_vm._v("PLN Rembang")]), _c('td', [_c('b-badge', {
                attrs: {
                  "variant": "primary"
                }
              }, [_vm._v("Own Ship")]), _c('br'), _vm._v(" TB.ETI 102")], 1), _c('td', [_c('b-form-group', {
                staticClass: "row mb-2",
                attrs: {
                  "label-cols-md": "5",
                  "label": "CARGO TYPE",
                  "label-for": "descriptionItem"
                }
              }, [_c('p', [_c('strong', [_vm._v("COAL")])])]), _c('b-form-group', {
                staticClass: "row mb-2",
                attrs: {
                  "label-cols-md": "5",
                  "label": "TERM",
                  "label-for": "descriptionItem"
                }
              }, [_c('p', [_c('strong', [_vm._v("CIF")])])])], 1), _c('td', [_c('b-form-group', {
                staticClass: "row mb-2",
                attrs: {
                  "label-cols-md": "4",
                  "label": "POL",
                  "label-for": "descriptionItem"
                }
              }, [_c('p', [_c('strong', [_vm._v("Paiton")])])]), _c('b-form-group', {
                staticClass: "row mb-2",
                attrs: {
                  "label-cols-md": "4",
                  "label": "POD",
                  "label-for": "descriptionItem"
                }
              }, [_c('p', [_c('strong', [_vm._v("Rembang")])])])], 1), _c('td', [_c('b-row', [_c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "12"
                }
              }, [_c('strong', [_vm._v("Januari")])]), _c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "6"
                }
              }, [_c('strong', [_vm._v("ETA POL")])]), _c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "6"
                }
              }, [_c('strong', [_vm._v("ETA POD")])]), _c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "6"
                }
              }, [_c('p', [_vm._v("19 Januari 2023")])]), _c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "6"
                }
              }, [_c('p', [_vm._v("23 Januari 2023")])])], 1)], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('strong', [_vm._v("7.500 MT")])]), _c('td', [_vm._v("-")])])])])])]), _c('tr', {
                staticClass: "iq-bg-primary"
              }, [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("2")]), _c('th', {
                staticClass: "text-center"
              }, [_c('b-badge', {
                staticStyle: {
                  "font-size": "12pt"
                },
                attrs: {
                  "variant": "success"
                }
              }, [_vm._v("New")])], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('strong', {
                staticStyle: {
                  "font-size": "12pt"
                }
              }, [_vm._v("Pertamina Gas")])])]), _c('tr', [_c('td', {
                attrs: {
                  "colspan": "3"
                }
              }, [_c('table', {
                staticClass: "table mb-0",
                attrs: {
                  "width": "100%"
                }
              }, [_c('thead', [_c('tr', {
                staticClass: "bg-info"
              }, [_c('th', [_vm._v("#")]), _c('th', {
                attrs: {
                  "width": "17%"
                }
              }, [_vm._v("Description")]), _c('th', [_vm._v("Fleet")]), _c('th', [_vm._v("Cargo & Term")]), _c('th', [_vm._v("Location")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "18%"
                }
              }, [_vm._v("Shipment Plan")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("Plan Quantity")]), _c('th', [_vm._v("Remarks")])])]), _c('tbody', [_c('tr', [_c('td', [_c('input', {
                attrs: {
                  "type": "checkbox",
                  "name": "shipper3",
                  "id": "shipper3"
                }
              })]), _c('td', [_vm._v("PLN Rembang")]), _c('td', [_c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("Third Party")]), _c('br'), _vm._v(" TB.Dharma 1")], 1), _c('td', [_c('b-form-group', {
                staticClass: "row mb-2",
                attrs: {
                  "label-cols-md": "5",
                  "label": "CARGO TYPE",
                  "label-for": "descriptionItem"
                }
              }, [_c('p', [_c('strong', [_vm._v("COAL")])])]), _c('b-form-group', {
                staticClass: "row mb-2",
                attrs: {
                  "label-cols-md": "5",
                  "label": "TERM",
                  "label-for": "descriptionItem"
                }
              }, [_c('p', [_c('strong', [_vm._v("CIF")])])])], 1), _c('td', [_c('b-form-group', {
                staticClass: "row mb-2",
                attrs: {
                  "label-cols-md": "4",
                  "label": "POL",
                  "label-for": "descriptionItem"
                }
              }, [_c('p', [_c('strong', [_vm._v("Paiton")])])]), _c('b-form-group', {
                staticClass: "row mb-2",
                attrs: {
                  "label-cols-md": "4",
                  "label": "POD",
                  "label-for": "descriptionItem"
                }
              }, [_c('p', [_c('strong', [_vm._v("Rembang")])])])], 1), _c('td', [_c('b-row', [_c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "12"
                }
              }, [_c('strong', [_vm._v("Januari")])]), _c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "6"
                }
              }, [_c('strong', [_vm._v("ETA POL")])]), _c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "6"
                }
              }, [_c('strong', [_vm._v("ETA POD")])]), _c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "6"
                }
              }, [_c('p', [_vm._v("10 Januari 2023")])]), _c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "6"
                }
              }, [_c('p', [_vm._v("16 Januari 2023")])])], 1)], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('strong', [_vm._v("29.000 MT")])]), _c('td', [_vm._v("-")])])])])])])])])])])], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 h5 text-primary"
              }, [_vm._v("Select Operational Data")]), _c('b-row', {
                staticClass: "pl-3 pr-3"
              }, [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('b-alert', {
                staticClass: "text-white bg-warning",
                attrs: {
                  "show": true,
                  "variant": " "
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "ri-information-fill"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Operational Not Yet Input Data For This Period, Please Input First")])])], 1), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('div', {
                staticClass: "table-responsive"
              }, [_c('table', {
                staticClass: "table table-striped",
                staticStyle: {
                  "width": "3700px",
                  "max-height": "750px",
                  "overflow-y": "scroll"
                }
              }, [_c('thead', {
                staticClass: "thead-dark text-center"
              }, [_c('tr', [_c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "0px",
                  "background-color": "#039BAB"
                },
                attrs: {
                  "width": "50px"
                }
              }, [_vm._v("#")]), _c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "45px",
                  "background-color": "#039BAB"
                },
                attrs: {
                  "width": "250px"
                }
              }, [_vm._v("PROJECT")]), _c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "295px",
                  "background-color": "#039BAB"
                },
                attrs: {
                  "width": "150px"
                }
              }, [_vm._v("FLEET")]), _c('th', {
                staticClass: "text-center",
                staticStyle: {
                  "position": "sticky",
                  "left": "425px",
                  "background-color": "#039BAB"
                },
                attrs: {
                  "width": "250px"
                }
              }, [_vm._v("STATUS")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "127px"
                }
              }, [_vm._v("MODA")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "197px"
                }
              }, [_vm._v("COMPANY")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "127px"
                }
              }, [_vm._v("CARGO TYPE")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "197px"
                }
              }, [_vm._v("POL")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "187px"
                }
              }, [_vm._v("TA POL/READY")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "147px"
                }
              }, [_vm._v("BERTHING")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "147px"
                }
              }, [_vm._v("COMMENCED")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "147px"
                }
              }, [_vm._v("COMPLETED")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "147px"
                }
              }, [_vm._v("CARGO LOADING")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "197px"
                }
              }, [_vm._v("POD")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "147px"
                }
              }, [_vm._v("TA POD/READY")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "147px"
                }
              }, [_vm._v("BERTHING")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "147px"
                }
              }, [_vm._v("COMMENCED")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "147px"
                }
              }, [_vm._v("COMPLETED")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "147px"
                }
              }, [_vm._v("CARGO UNLOADING")])])]), _c('tbody', [_c('tr', [_c('td', {
                staticStyle: {
                  "position": "sticky",
                  "left": "0px",
                  "background-color": "#039BAB",
                  "color": "#ffffff"
                }
              }, [_c('input', {
                attrs: {
                  "type": "checkbox",
                  "name": "voyage",
                  "id": "voyage"
                }
              })]), _c('td', {
                staticStyle: {
                  "position": "sticky",
                  "left": "45px",
                  "background-color": "#039BAB",
                  "color": "#ffffff"
                }
              }, [_vm._v("AI PROJECT - LONG HAULING")]), _c('td', {
                staticStyle: {
                  "position": "sticky",
                  "left": "295px",
                  "background-color": "#039BAB",
                  "color": "#ffffff"
                }
              }, [_vm._v("PT.TCP 201/PB.TCP 3301")]), _c('td', {
                staticStyle: {
                  "position": "sticky",
                  "left": "425px",
                  "background-color": "#039BAB",
                  "color": "#ffffff"
                }
              }, [_c('b-badge', {
                staticStyle: {
                  "font-size": "12pt"
                },
                attrs: {
                  "variant": "success"
                }
              }, [_vm._v("Completed")])], 1), _c('td', [_vm._v("330 ft")]), _c('td', [_vm._v("PT.Transcoal Pacific")]), _c('td', [_vm._v("Nickel")]), _c('td', [_vm._v("MP Asam2")]), _c('td', [_vm._v("31-Dec-21 00.00")]), _c('td', [_vm._v("02-Jan-22 02.10")]), _c('td', [_vm._v("02-Jan-22 03.05")]), _c('td', [_vm._v("02-Jan-22 05.05")]), _c('td', [_vm._v("7.606 MT")]), _c('td', [_vm._v("LABUAN")]), _c('td', [_vm._v("04-Jan-22 20.00")]), _c('td', [_vm._v("04-Jan-22 20.00")]), _c('td', [_vm._v("04-Jan-22 20.00")]), _c('td', [_vm._v("04-Jan-22 20.00")]), _c('td', [_vm._v("7.506 MT")])]), _c('tr', [_c('td', {
                staticStyle: {
                  "position": "sticky",
                  "left": "0px",
                  "background-color": "#039BAB",
                  "color": "#ffffff"
                }
              }, [_c('input', {
                attrs: {
                  "type": "checkbox",
                  "name": "voyage",
                  "id": "voyage"
                }
              })]), _c('td', {
                staticStyle: {
                  "position": "sticky",
                  "left": "45px",
                  "background-color": "#039BAB",
                  "color": "#ffffff"
                }
              }, [_vm._v("AI PROJECT - LONG HAULING")]), _c('td', {
                staticStyle: {
                  "position": "sticky",
                  "left": "295px",
                  "background-color": "#039BAB",
                  "color": "#ffffff"
                }
              }, [_vm._v("PT.TCP 202/PB.TCP 3302")]), _c('td', {
                staticStyle: {
                  "position": "sticky",
                  "left": "425px",
                  "background-color": "#039BAB",
                  "color": "#ffffff"
                }
              }, [_c('b-badge', {
                staticStyle: {
                  "font-size": "12pt"
                },
                attrs: {
                  "variant": "success"
                }
              }, [_vm._v("Completed")])], 1), _c('td', [_vm._v("330 ft")]), _c('td', [_vm._v("PT.Transcoal Pacific")]), _c('td', [_vm._v("Nickel")]), _c('td', [_vm._v("MP Asam2")]), _c('td', [_vm._v("31-Dec-21 00.00")]), _c('td', [_vm._v("02-Jan-22 02.10")]), _c('td', [_vm._v("02-Jan-22 03.05")]), _c('td', [_vm._v("02-Jan-22 05.05")]), _c('td', [_vm._v("7.606 MT")]), _c('td', [_vm._v("LABUAN")]), _c('td', [_vm._v("04-Jan-22 20.00")]), _c('td', [_vm._v("04-Jan-22 20.00")]), _c('td', [_vm._v("04-Jan-22 20.00")]), _c('td', [_vm._v("04-Jan-22 20.00")]), _c('td', [_vm._v("7.506 MT")])])])])])])], 1), _c('b-row', {
                staticClass: "mt-4"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label-for": "dateIssued",
                  "label": "Company"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.CompanyName,
                  "id": "gender"
                },
                model: {
                  value: _vm.companySelected,
                  callback: function callback($$v) {
                    _vm.companySelected = $$v;
                  },
                  expression: "companySelected"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Price",
                  "label-for": "cost"
                }
              }, [_c('b-input-group', {
                attrs: {
                  "size": "md",
                  "prepend": "Rp"
                }
              }, [_c('b-form-input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number"
                }
              })], 1)], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Price to Vendor",
                  "label-for": "cost"
                }
              }, [_c('b-input-group', {
                attrs: {
                  "size": "md",
                  "prepend": "Rp"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "number"
                }
              })], 1)], 1)], 1)], 1)], 1)], 1)], 1)]), _c('b-col', {
                staticClass: "mt-5",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-button', {
                attrs: {
                  "size": "lg",
                  "variant": "success",
                  "block": ""
                }
              }, [_c('i', {
                staticClass: "fa fa-save"
              }), _vm._v(" Save Data ")])], 1)], 1)];
            }
          }]),
          model: {
            value: _vm.realisasiData,
            callback: function callback($$v) {
              _vm.realisasiData = $$v;
            },
            expression: "realisasiData"
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1)])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }