var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "41",
      "height": "40",
      "viewBox": "0 0 41 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "x": "1.58301",
      "y": "0.75",
      "width": "38.5",
      "height": "38.5",
      "rx": "19.25",
      "stroke": _vm.color,
      "stroke-width": "1.5"
    }
  }), _c('path', {
    attrs: {
      "d": "M30.5984 13.4345L27.3985 10.2346C27.3242 10.1602 27.236 10.1012 27.1388 10.061C27.0417 10.0207 26.9376 10 26.8325 10C26.7274 10 26.6233 10.0207 26.5262 10.061C26.429 10.1012 26.3408 10.1602 26.2665 10.2346L16.6668 19.8343C16.5926 19.9087 16.5337 19.9969 16.4935 20.094C16.4534 20.1911 16.4327 20.2952 16.4328 20.4003V23.6002C16.4328 23.8124 16.5171 24.0158 16.6671 24.1659C16.8172 24.3159 17.0206 24.4002 17.2328 24.4002H20.4327C20.5378 24.4003 20.6419 24.3796 20.739 24.3395C20.8361 24.2993 20.9243 24.2404 20.9987 24.1662L30.5984 14.5665C30.6728 14.4922 30.7318 14.404 30.772 14.3068C30.8123 14.2097 30.833 14.1056 30.833 14.0005C30.833 13.8954 30.8123 13.7913 30.772 13.6942C30.7318 13.597 30.6728 13.5088 30.5984 13.4345ZM20.1017 22.8002H18.0328V20.7313L24.4326 14.3315L26.5015 16.4004L20.1017 22.8002ZM27.6325 15.2695L25.5635 13.2005L26.8325 11.9316L28.9014 14.0005L27.6325 15.2695ZM30.0324 20.4003V28.4001C30.0324 28.8244 29.8638 29.2313 29.5638 29.5314C29.2637 29.8314 28.8568 30 28.4325 30H12.433C12.0086 30 11.6017 29.8314 11.3016 29.5314C11.0016 29.2313 10.833 28.8244 10.833 28.4001V12.4006C10.833 11.9762 11.0016 11.5693 11.3016 11.2692C11.6017 10.9692 12.0086 10.8006 12.433 10.8006H20.4327C20.6449 10.8006 20.8483 10.8849 20.9984 11.0349C21.1484 11.1849 21.2327 11.3884 21.2327 11.6006C21.2327 11.8127 21.1484 12.0162 20.9984 12.1662C20.8483 12.3163 20.6449 12.4006 20.4327 12.4006H12.433V28.4001H28.4325V20.4003C28.4325 20.1881 28.5167 19.9847 28.6668 19.8346C28.8168 19.6846 29.0203 19.6003 29.2324 19.6003C29.4446 19.6003 29.6481 19.6846 29.7981 19.8346C29.9481 19.9847 30.0324 20.1881 30.0324 20.4003Z",
      "fill": _vm.color
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }