<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <span>Tab PFA</span>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import _ from 'lodash'
  import moment from 'moment'
  import { fleetsActions, crewingActions, busdevActions } from '@src/Utils/helper'


  export default {
    name:'TabPFA',
    props:{

    },
    components:{
    },
    data(){
      return{
      }
    },
    watch:{

    },
    mounted(){},
    methods:{
      ...crewingActions,
      ...fleetsActions,
      ...busdevActions,
    }
  }
</script>
