var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "fab-container"
  }, [['SUPER ADMIN', 'PORT CAPTAIN', 'MANAGEMENT', 'RADIO CONTROL ROOM', 'MARINE INSURANCE', 'MARINE INSURANCE ADMIN'].includes(_vm.role) ? _c('div', {
    staticClass: "fab shadow",
    on: {
      "click": function click($event) {
        _vm.dashboard = 'operational';
      }
    }
  }, [_c('div', {
    staticClass: "fab-content bg-blue"
  }, [_c('span', [_c('i', {
    staticClass: "ri ri-safari-fill mr-2"
  })]), _c('span', {
    staticStyle: {
      "font-family": "Arial, Helvetica, sans-serif"
    }
  }, [_vm._v("Operational")])])]) : _vm._e(), ['SUPER ADMIN', 'MANAGEMENT', 'MARINE INSURANCE', 'MARINE INSURANCE ADMIN'].includes(_vm.role) ? _c('div', {
    staticClass: "fab shadow",
    on: {
      "click": function click($event) {
        _vm.dashboard = 'mi';
      }
    }
  }, [_c('div', {
    staticClass: "fab-content bg-warning"
  }, [_c('span', [_c('i', {
    staticClass: "ri ri-ship-2-line mr-2"
  })]), _c('span', {
    staticStyle: {
      "font-family": "Arial, Helvetica, sans-serif"
    }
  }, [_vm._v("Marine Insurance")])])]) : _vm._e(), ['SUPER ADMIN', 'MANAGEMENT', 'MARINE INSURANCE', 'MARINE INSURANCE ADMIN'].includes(_vm.role) ? _c('div', {
    staticClass: "fab shadow",
    on: {
      "click": function click($event) {
        _vm.dashboard = 'mi_list_unsettled';
      }
    }
  }, [_c('div', {
    staticClass: "fab-content bg-dark"
  }, [_c('span', [_c('i', {
    staticClass: "ri ri-ship-fill mr-2"
  })]), _c('span', {
    staticStyle: {
      "font-family": "Arial, Helvetica, sans-serif"
    }
  }, [_vm._v("Vessel Case")])])]) : _vm._e(), ['SUPER ADMIN', 'MANAGEMENT', 'MARINE INSURANCE', 'MARINE INSURANCE ADMIN', 'CREWING'].includes(_vm.role) ? _c('div', {
    staticClass: "fab shadow",
    on: {
      "click": function click($event) {
        _vm.dashboard = 'crewing';
      }
    }
  }, [_c('div', {
    staticClass: "fab-content bg-dark"
  }, [_c('span', [_c('i', {
    staticClass: "las la-users mr-2"
  })]), _c('span', {
    staticStyle: {
      "font-family": "Arial, Helvetica, sans-serif"
    }
  }, [_vm._v("Crewing")])])]) : _vm._e()]), _vm.layout === 'maps' ? _c('b-row', [_c('ContentMaps', {
    attrs: {
      "fleetActivities": _vm.fleetActivities,
      "fetchFleetDetail": _vm.fetchFleetDetail,
      "onDeleteFleet": _vm.onDeleteFleet
    }
  })], 1) : _vm._e(), _c('b-row', {
    staticClass: "mb-3",
    staticStyle: {
      "z-index": "999 !important"
    }
  }, [_c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_vm.layout === 'maps' ? _c('div', {
    staticClass: "justify-content-end text-left"
  }, [_c('FilterMaps', {
    attrs: {
      "vehicleTypes": _vm.vehicleTypes,
      "categoryOwnership": _vm.categoryOwnership,
      "fetchFleetActivities": _vm.fetchFleetActivities,
      "maintenance": _vm.maintenance,
      "fleetDetail": _vm.fleetDetail
    },
    model: {
      value: _vm.params,
      callback: function callback($$v) {
        _vm.params = $$v;
      },
      expression: "params"
    }
  })], 1) : _vm._e()]), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "justify-content-end text-right"
  }, [_c('strong', {
    class: {
      'text-white': _vm.layout === 'maps'
    }
  }, [_vm._v("Display :  ")]), _c('div', {
    staticClass: "btn-group"
  }, [_c('button', {
    staticClass: "btn btn-outline-primary",
    class: {
      active: _vm.layout === 'list',
      'btn-outline-light': _vm.layout === 'maps'
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.layout = 'list';
      }
    }
  }, [_c('span', {
    staticClass: "fa fa-list"
  }), _vm._v(" Dashboard List ")]), _c('button', {
    staticClass: "btn btn-outline-primary",
    class: {
      active: _vm.layout === 'maps'
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.layout = 'maps';
      }
    }
  }, [_c('span', {
    staticClass: "fa fa-map"
  }), _vm._v(" Maps ")])])])])], 1), _vm.layout === 'list' ? _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    staticClass: "iq-card-block iq-card-stretch iq-card-height text-center",
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "btn-group w-100"
        }, [_vm.isOperationalDashboardHasAccess ? _c('button', {
          staticClass: "btn btn-outline-primary p-3 btn-menu",
          class: {
            'btn-menu-active': _vm.dashboard === 'operational'
          },
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              _vm.dashboard = 'operational';
            },
            "mouseover": function mouseover($event) {
              _vm.tabMenuOptions.operational.isHover = true;
            },
            "mouseleave": function mouseleave($event) {
              _vm.tabMenuOptions.operational.isHover = false;
            }
          }
        }, [_vm._v(" Operational "), _c('OperationalIcon', {
          staticClass: "ml-2",
          attrs: {
            "color": _vm.tabMenuOptions.operational.isHover || _vm.dashboard === 'operational' ? 'white' : '#02B3B7'
          }
        })], 1) : _vm._e(), _vm.isMIDashboardHasAccess ? _c('button', {
          staticClass: "btn btn-outline-primary p-3 btn-menu",
          class: {
            'btn-menu-active': _vm.dashboard === 'mi'
          },
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              _vm.dashboard = 'mi';
            },
            "mouseover": function mouseover($event) {
              _vm.tabMenuOptions.mi.isHover = true;
            },
            "mouseleave": function mouseleave($event) {
              _vm.tabMenuOptions.mi.isHover = false;
            }
          }
        }, [_vm._v(" Marine Insurance "), _c('MarineInsurance', {
          staticClass: "ml-2",
          attrs: {
            "color": _vm.tabMenuOptions.mi.isHover || _vm.dashboard === 'mi' ? 'white' : '#02B3B7'
          }
        })], 1) : _vm._e(), _vm.isMIDashboardHasAccess ? _c('button', {
          staticClass: "btn btn-outline-primary p-3 btn-menu",
          class: {
            'btn-menu-active': _vm.dashboard === 'crewing'
          },
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              _vm.dashboard = 'crewing';
            },
            "mouseover": function mouseover($event) {
              _vm.tabMenuOptions.crewing.isHover = true;
            },
            "mouseleave": function mouseleave($event) {
              _vm.tabMenuOptions.crewing.isHover = false;
            }
          }
        }, [_vm._v(" Crewing "), _c('CrewingIconCircle', {
          staticClass: "ml-2",
          attrs: {
            "color": _vm.tabMenuOptions.crewing.isHover || _vm.dashboard === 'crewing' ? 'white' : '#02B3B7'
          }
        })], 1) : _vm._e(), _vm.isMIDashboardHasAccess ? _c('button', {
          staticClass: "btn btn-outline-primary p-3 btn-menu",
          class: {
            'btn-menu-active': _vm.dashboard === 'technical_maintenance'
          },
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              _vm.dashboard = 'technical_maintenance';
            },
            "mouseover": function mouseover($event) {
              _vm.tabMenuOptions.technical.isHover = true;
            },
            "mouseleave": function mouseleave($event) {
              _vm.tabMenuOptions.technical.isHover = false;
            }
          }
        }, [_vm._v(" Technical "), _c('TechnicalIcon', {
          staticClass: "ml-2",
          attrs: {
            "color": _vm.tabMenuOptions.technical.isHover || _vm.dashboard === 'technical_maintenance' ? 'white' : '#02B3B7'
          }
        })], 1) : _vm._e(), _vm.isMIDashboardHasAccess ? _c('button', {
          staticClass: "btn btn-outline-primary p-3 btn-menu",
          class: {
            'btn-menu-active': _vm.dashboard === 'mi_list_unsettled'
          },
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              _vm.dashboard = 'mi_list_unsettled';
            },
            "mouseover": function mouseover($event) {
              _vm.tabMenuOptions.businesDev.isHover = true;
            },
            "mouseleave": function mouseleave($event) {
              _vm.tabMenuOptions.businesDev.isHover = false;
            }
          }
        }, [_vm._v(" Business Development "), _c('BusinessIcon', {
          staticClass: "ml-2",
          attrs: {
            "color": _vm.tabMenuOptions.businesDev.isHover || _vm.dashboard === 'mi_list_unsettled' ? 'white' : '#02B3B7'
          }
        })], 1) : _vm._e()])];
      },
      proxy: true
    }], null, false, 3035804651)
  })], 1), _vm.dashboard === 'operational' ? [_c('OperationalDashboard', {
    attrs: {
      "vehicleTypes": _vm.vehicleTypes,
      "vehicle": _vm.vehicle,
      "projectOptions": _vm.projectOptions,
      "optionsUsers": _vm.optionsUsers
    }
  })] : _vm.dashboard === 'mi' ? [_c('SummaryManagement')] : _vm.dashboard === 'technical_maintenance' ? [_c('TechnicalDashboardMain')] : _vm.dashboard === 'crewing' ? [_c('CrewingDashboard')] : _vm.dashboard === 'mi_list_unsettled' ? [_c('ListInitialTable', {
    attrs: {
      "show-status-action": false,
      "show-filter": false,
      "non-settled": "",
      "type-form": "claim"
    }
  })] : _vm._e()], 2) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }