var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('form', {
    attrs: {
      "action": "#",
      "method": "post"
    },
    on: {
      "submit": _vm.onSave
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("EVALUASI KONDIT / "), _c('i', [_vm._v("CONDUITE EVALUATION")])])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$userDetails$fulln, _vm$userDetails, _vm$userDetails$no_kt, _vm$userDetails2, _vm$userDetails$perso, _vm$userDetails3, _vm$userDetails3$pers, _vm$userDetails$perso2, _vm$userDetails4, _vm$userDetails4$pers, _vm$userDetails$perso3, _vm$userDetails5, _vm$userDetails5$pers, _vm$userDetails$posit, _vm$userDetails6, _vm$userDetails6$posi, _vm$userDetails$nip, _vm$userDetails7, _vm$userDetails$compa, _vm$userDetails8, _vm$userDetails8$comp, _vm$userDetails$perso4, _vm$userDetails9, _vm$userDetails9$pers, _vm$userDetails$vehic, _vm$userDetails10, _vm$userDetails10$veh, _vm$userDetails$seama, _vm$userDetails11, _vm$userDetails$port$, _vm$userDetails12, _vm$userDetails12$por, _vm$userDetails$perso5, _vm$userDetails13, _vm$userDetails13$per, _vm$userDetails$sign_, _vm$userDetails14, _vm$userDetails$perso6, _vm$userDetails15, _vm$userDetails15$per, _vm$userDetails$join_, _vm$userDetails16, _vm$userDetails$email, _vm$userDetails17, _vm$userDetails$sign_2, _vm$userDetails18, _vm$userDetails$candi, _vm$userDetails19, _vm$userDetails19$can, _vm$userDetails19$can2, _vm$userDetails19$can3, _vm$userDetails20, _vm$userDetails21;
        return [_c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 text-primary"
        }, [_vm._v("Crew Information")]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "10"
          }
        }, [_c('table', {
          staticClass: "table table-striped mt-2"
        }, [_c('tbody', [_c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Nama Crew")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$fulln = (_vm$userDetails = _vm.userDetails) === null || _vm$userDetails === void 0 ? void 0 : _vm$userDetails.fullname) !== null && _vm$userDetails$fulln !== void 0 ? _vm$userDetails$fulln : '-'))]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("No KTP")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$no_kt = (_vm$userDetails2 = _vm.userDetails) === null || _vm$userDetails2 === void 0 ? void 0 : _vm$userDetails2.no_ktp) !== null && _vm$userDetails$no_kt !== void 0 ? _vm$userDetails$no_kt : '-'))])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Tempat/Tanggal Lahir")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$perso = (_vm$userDetails3 = _vm.userDetails) === null || _vm$userDetails3 === void 0 ? void 0 : (_vm$userDetails3$pers = _vm$userDetails3.personal_data) === null || _vm$userDetails3$pers === void 0 ? void 0 : _vm$userDetails3$pers.place_of_birth) !== null && _vm$userDetails$perso !== void 0 ? _vm$userDetails$perso : '-') + ", " + _vm._s((_vm$userDetails$perso2 = (_vm$userDetails4 = _vm.userDetails) === null || _vm$userDetails4 === void 0 ? void 0 : (_vm$userDetails4$pers = _vm$userDetails4.personal_data) === null || _vm$userDetails4$pers === void 0 ? void 0 : _vm$userDetails4$pers.date_of_birth) !== null && _vm$userDetails$perso2 !== void 0 ? _vm$userDetails$perso2 : '-'))]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("No NPWP")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$perso3 = (_vm$userDetails5 = _vm.userDetails) === null || _vm$userDetails5 === void 0 ? void 0 : (_vm$userDetails5$pers = _vm$userDetails5.personal_data) === null || _vm$userDetails5$pers === void 0 ? void 0 : _vm$userDetails5$pers.npwp_number) !== null && _vm$userDetails$perso3 !== void 0 ? _vm$userDetails$perso3 : '-'))])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Jabatan")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$posit = (_vm$userDetails6 = _vm.userDetails) === null || _vm$userDetails6 === void 0 ? void 0 : (_vm$userDetails6$posi = _vm$userDetails6.position) === null || _vm$userDetails6$posi === void 0 ? void 0 : _vm$userDetails6$posi.name) !== null && _vm$userDetails$posit !== void 0 ? _vm$userDetails$posit : '-'))]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("ID Crew")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$nip = (_vm$userDetails7 = _vm.userDetails) === null || _vm$userDetails7 === void 0 ? void 0 : _vm$userDetails7.nip) !== null && _vm$userDetails$nip !== void 0 ? _vm$userDetails$nip : '-'))])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Perusahaan")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$compa = (_vm$userDetails8 = _vm.userDetails) === null || _vm$userDetails8 === void 0 ? void 0 : (_vm$userDetails8$comp = _vm$userDetails8.company) === null || _vm$userDetails8$comp === void 0 ? void 0 : _vm$userDetails8$comp.company) !== null && _vm$userDetails$compa !== void 0 ? _vm$userDetails$compa : '-'))]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Seaferer Code")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$perso4 = (_vm$userDetails9 = _vm.userDetails) === null || _vm$userDetails9 === void 0 ? void 0 : (_vm$userDetails9$pers = _vm$userDetails9.personal_data) === null || _vm$userDetails9$pers === void 0 ? void 0 : _vm$userDetails9$pers.seaferer_code) !== null && _vm$userDetails$perso4 !== void 0 ? _vm$userDetails$perso4 : '-'))])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Nama Kapal")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$vehic = (_vm$userDetails10 = _vm.userDetails) === null || _vm$userDetails10 === void 0 ? void 0 : (_vm$userDetails10$veh = _vm$userDetails10.vehicle) === null || _vm$userDetails10$veh === void 0 ? void 0 : _vm$userDetails10$veh.name) !== null && _vm$userDetails$vehic !== void 0 ? _vm$userDetails$vehic : '-'))]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Seaman Book")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$seama = (_vm$userDetails11 = _vm.userDetails) === null || _vm$userDetails11 === void 0 ? void 0 : _vm$userDetails11.seamant_book) !== null && _vm$userDetails$seama !== void 0 ? _vm$userDetails$seama : '-'))])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Pelabuhan")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$port$ = (_vm$userDetails12 = _vm.userDetails) === null || _vm$userDetails12 === void 0 ? void 0 : (_vm$userDetails12$por = _vm$userDetails12.port) === null || _vm$userDetails12$por === void 0 ? void 0 : _vm$userDetails12$por.port_name) !== null && _vm$userDetails$port$ !== void 0 ? _vm$userDetails$port$ : '-'))]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("No Passport")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$perso5 = (_vm$userDetails13 = _vm.userDetails) === null || _vm$userDetails13 === void 0 ? void 0 : (_vm$userDetails13$per = _vm$userDetails13.personal_data) === null || _vm$userDetails13$per === void 0 ? void 0 : _vm$userDetails13$per.passport_number) !== null && _vm$userDetails$perso5 !== void 0 ? _vm$userDetails$perso5 : '-'))])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Sign On")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$sign_ = (_vm$userDetails14 = _vm.userDetails) === null || _vm$userDetails14 === void 0 ? void 0 : _vm$userDetails14.sign_on) !== null && _vm$userDetails$sign_ !== void 0 ? _vm$userDetails$sign_ : '-'))]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Nomor Tlp / Hp")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$perso6 = (_vm$userDetails15 = _vm.userDetails) === null || _vm$userDetails15 === void 0 ? void 0 : (_vm$userDetails15$per = _vm$userDetails15.personal_data) === null || _vm$userDetails15$per === void 0 ? void 0 : _vm$userDetails15$per.hp_number) !== null && _vm$userDetails$perso6 !== void 0 ? _vm$userDetails$perso6 : '-'))])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Join Onboard")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$join_ = (_vm$userDetails16 = _vm.userDetails) === null || _vm$userDetails16 === void 0 ? void 0 : _vm$userDetails16.join_on_board) !== null && _vm$userDetails$join_ !== void 0 ? _vm$userDetails$join_ : '-'))]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Email")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$email = (_vm$userDetails17 = _vm.userDetails) === null || _vm$userDetails17 === void 0 ? void 0 : _vm$userDetails17.email) !== null && _vm$userDetails$email !== void 0 ? _vm$userDetails$email : '-'))])]), _c('tr', [_c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Sign Off")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(_vm._s((_vm$userDetails$sign_2 = (_vm$userDetails18 = _vm.userDetails) === null || _vm$userDetails18 === void 0 ? void 0 : _vm$userDetails18.sign_off) !== null && _vm$userDetails$sign_2 !== void 0 ? _vm$userDetails$sign_2 : '-'))]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Alamat")]), _c('td', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v(" " + _vm._s((_vm$userDetails$candi = (_vm$userDetails19 = _vm.userDetails) === null || _vm$userDetails19 === void 0 ? void 0 : (_vm$userDetails19$can = _vm$userDetails19.candidate) === null || _vm$userDetails19$can === void 0 ? void 0 : (_vm$userDetails19$can2 = _vm$userDetails19$can.alamat_ktp) === null || _vm$userDetails19$can2 === void 0 ? void 0 : (_vm$userDetails19$can3 = _vm$userDetails19$can2[0]) === null || _vm$userDetails19$can3 === void 0 ? void 0 : _vm$userDetails19$can3.alamat) !== null && _vm$userDetails$candi !== void 0 ? _vm$userDetails$candi : '-') + " ")])])])])]), _c('b-col', {
          staticClass: "text-center",
          attrs: {
            "md": "2"
          }
        }, [_c('b-img', {
          staticClass: "ml-0",
          attrs: {
            "thumbnail": "",
            "src": (_vm$userDetails20 = _vm.userDetails) !== null && _vm$userDetails20 !== void 0 && _vm$userDetails20.photo_url ? (_vm$userDetails21 = _vm.userDetails) === null || _vm$userDetails21 === void 0 ? void 0 : _vm$userDetails21.photo_url : require('../../assets/images/page-img/10.jpg'),
            "alt": "Responsive image"
          }
        })], 1)], 1)], 1), _c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 text-primary"
        }, [_vm._v("Form Evaluasi Conduite")]), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', {
          staticClass: "text-center"
        }, [_c('th', {
          attrs: {
            "width": "40%"
          }
        }, [_vm._v("DESKRIPSI")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("PENILAIAN")]), _c('th', {
          attrs: {
            "width": "40%"
          }
        }, [_vm._v("NOTES")])])]), _c('tbody', _vm._l(_vm.merged_aspects, function (state, index) {
          return _c('tr', {
            key: index
          }, [_c('th', [_vm._v(_vm._s(state.content))]), _c('td', {
            staticClass: "text-center"
          }, [_c('b-form-select', {
            staticClass: "col-md-12",
            attrs: {
              "plain": "",
              "options": _vm.optionsPoints,
              "size": "sm",
              "required": ""
            },
            scopedSlots: _vm._u([{
              key: "first",
              fn: function fn() {
                return [_c('b-form-select-option', {
                  attrs: {
                    "value": null
                  }
                }, [_vm._v("Nilai")])];
              },
              proxy: true
            }], null, true),
            model: {
              value: state.value,
              callback: function callback($$v) {
                _vm.$set(state, "value", $$v);
              },
              expression: "state.value"
            }
          })], 1), _c('td', [_c('b-form-textarea', {
            attrs: {
              "rows": "4",
              "value": "Notes"
            },
            model: {
              value: state.remarks,
              callback: function callback($$v) {
                _vm.$set(state, "remarks", $$v);
              },
              expression: "state.remarks"
            }
          })], 1)]);
        }), 0)])])], 1)], 1), _c('b-row', {
          staticClass: "mt-3"
        }, [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('h4', {
          staticClass: "text-primary"
        }, [_vm._v("Disetujui Oleh / "), _c('i', [_vm._v("Approval by ")])]), _c('hr'), _c('b-form-group', {
          attrs: {
            "label": "PIC",
            "label-for": "pic"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.optionsExpertUsers,
            "reduce": function reduce(optionsExpertUsers) {
              return optionsExpertUsers.text;
            },
            "placeholder": "Select Type of Expert User",
            "required": ""
          },
          model: {
            value: _vm.form.approved_by,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "approved_by", $$v);
            },
            expression: "form.approved_by"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Notes / Remarks",
            "label-for": "pic"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "rows": "2",
            "value": "Comments if any / Komentar jika ada"
          },
          model: {
            value: _vm.form.recommendation1,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "recommendation1", $$v);
            },
            expression: "form.recommendation1"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('h4', {
          staticClass: "text-primary"
        }, [_vm._v("Diverifikasi Oleh / "), _c('i', [_vm._v("Verified by ")])]), _c('hr'), _c('b-form-group', {
          attrs: {
            "label": "PIC",
            "label-for": "pic"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.optionsExpertUsers,
            "reduce": function reduce(optionsExpertUsers) {
              return optionsExpertUsers.text;
            },
            "placeholder": "Select Type of Expert User",
            "required": ""
          },
          model: {
            value: _vm.form.verified_by,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "verified_by", $$v);
            },
            expression: "form.verified_by"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Notes / Remarks",
            "label-for": "pic"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "rows": "2",
            "value": "Comments if any / Komentar jika ada"
          },
          model: {
            value: _vm.form.recommendation2,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "recommendation2", $$v);
            },
            expression: "form.recommendation2"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('h4', {
          staticClass: "text-primary"
        }, [_vm._v("Diperiksa Oleh / "), _c('i', [_vm._v("Checked by ")])]), _c('hr'), _c('b-form-group', {
          attrs: {
            "label": "PIC",
            "label-for": "pic"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.optionsExpertUsers,
            "reduce": function reduce(optionsExpertUsers) {
              return optionsExpertUsers.text;
            },
            "placeholder": "Select Type of Expert User",
            "required": ""
          },
          model: {
            value: _vm.form.checked_by,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "checked_by", $$v);
            },
            expression: "form.checked_by"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Notes / Remarks",
            "label-for": "pic"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "rows": "2",
            "value": "Comments if any / Komentar jika ada"
          },
          model: {
            value: _vm.form.recommendation3,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "recommendation3", $$v);
            },
            expression: "form.recommendation3"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('h4', {
          staticClass: "text-primary"
        }, [_vm._v("Dinilai Oleh / "), _c('i', [_vm._v("Rated By ")])]), _c('hr'), _c('b-form-group', {
          attrs: {
            "label": "PIC",
            "label-for": "pic"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.optionsExpertUsers,
            "reduce": function reduce(optionsExpertUsers) {
              return optionsExpertUsers.text;
            },
            "placeholder": "Select Type of Expert User",
            "required": ""
          },
          model: {
            value: _vm.form.rated_by,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "rated_by", $$v);
            },
            expression: "form.rated_by"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Notes / Remarks",
            "label-for": "pic"
          }
        }, [_c('b-form-textarea', {
          attrs: {
            "rows": "2",
            "value": "Comments if any / Komentar jika ada"
          },
          model: {
            value: _vm.form.recommendation4,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "recommendation4", $$v);
            },
            expression: "form.recommendation4"
          }
        })], 1)], 1), _c('b-col', {
          staticClass: "mt-3",
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          staticClass: "mt-3",
          attrs: {
            "disabled": _vm.saving,
            "variant": "primary",
            "type": "submit"
          }
        }, [_vm.saving ? _c('span', {
          staticClass: "spinner-border spinner-border-sm"
        }) : _c('span', [_vm._v("Save")])]), _c('b-button', {
          staticClass: "iq-bg-danger float-right",
          attrs: {
            "type": "reset",
            "variant": "none"
          }
        }, [_c('i', {
          staticClass: "fa fa-close"
        }), _vm._v(" Reset Form")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }