<template>
  <div>
    <!-- <tab-nav :tabs="true" align="center">
      <tab-nav-items :active="true" href="#cargo" title="Master Data Cargo Type" />
      <tab-nav-items :active="false" href="#unit" title="Master Data Unit" />
    </tab-nav> -->
    <CustomNav
        :items="navItems"
        class="mb-4"
        @item-click="(val) => (viewMenu = val)"
      ></CustomNav>
    <b-row v-if="viewMenu == 'cargo'">
      <b-col sm="8">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">
              <strong>List Master Data Cargo Type</strong>
            </h4>
          </template>
          <template #headerAction>
            <b-form
              class="row w-100"
              @submit.prevent="getCargoType()"
            >
              <b-col cols="12" md="10" class="p-0">
                <b-form-input
                  id="name"
                  type="text"
                  placeholder="Search..."
                  v-model="cargoTypeList.params.name"
                ></b-form-input>
              </b-col>
              <b-col cols="12" md="2" class="p-0">
                <b-button type="submit" variant="primary" class="ml-2"
                  ><i class="fa fa-search"></i
                ></b-button>
              </b-col>
            </b-form>
          </template>
          <template v-slot:body>
            <div v-if="cargoTypeList._loading" class="text-center my-5">
              <b-spinner type="grow" label="Spinning"></b-spinner>
            </div>
            <template v-else>
              <div class="table-responsive" v-if="cargoTypeList.data && cargoTypeList.data.length > 0">
                <table class="table table-striped">
                  <thead>
                  <tr>
                    <th width="10%">No</th>
                    <th >Name</th>
                    <th >Alias</th>
                    <th >Unit</th>
                    <th >Status</th>
                    <th width="10%" class="text-center">#</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(p, i) in cargoTypeList.data" :key="`p-${i}`">
                    <td>{{ i + 1 }}</td>
                    <td>{{ p.name }}</td>
                    <td>{{ p.alias }}</td>
                    <td><b-badge class="border border-primary text-primary text-uppercase" variant="none">{{ p?.unit?.name ?? '-' }}</b-badge></td>
                    <td :class="p.status ? 'text-success' : 'text-danger'">{{ p.status ? 'Active' : 'Inactive' }}</td>
                    <td>
                      <div class="d-flex justify-content-center">
                        <b-button variant="success" size="sm" class="mr-2" @click="editFormCargoType(p)"><i class="fa fa-edit"></i></b-button>
                        <b-button variant="danger" size="sm" @click="onDeleteCargoType(p)">
                          <i v-if="!delete_cargo_type" class="fa fa-trash"></i>
                          <template v-else>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Deleting...
                          </template>
                        </b-button>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div v-if="cargoTypeList.data && cargoTypeList.data.length > 0">
                <b-pagination
                  v-model="cargoTypeList.meta.page"
                  :total-rows="cargoTypeList.meta.totalItem"
                  @change="getCargoType"
                  :per-page="cargoTypeList.meta.perPage"
                  first-number
                  align="center"
                ></b-pagination>
              </div>
              <p v-else class="text-center my-4">No Cargo Type data found.</p>
            </template>
          </template>
        </iq-card>
      </b-col>
      <b-col sm="4">
        <iq-card
          :class="{
            'border border-danger shadow-lg': cargo_type_edit,
          }"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ cargo_type_edit ? 'Edit' : 'Add' }} Cargo Type</h4>
          </template>
          <template v-slot:body>
            <b-form @submit.prevent="onSaveCargoType()">
              <b-form-group label="Name" label-for="name">
                <b-form-input v-model="formCargoType.name" id="name" type="text" placeholder="Type text" maxlength="30"></b-form-input>
              </b-form-group>
              <b-form-group label="Alias" label-for="alias">
                <b-form-input v-model="formCargoType.alias" id="alias" type="text" placeholder="Type text" maxlength="30"></b-form-input>
              </b-form-group>
              <b-form-group label="Unit" label-for="unit_id">
                <v-select
                  v-model="formCargoType.unit_id"
                  label="name"
                  :options="unitList.data"
                  :reduce="(obj) => obj.id"
                  placeholder="Select unit..."
                  @input="onChangeUnit"
                ></v-select>
                <div class="d-flex flex-wrap mt-2" v-if="unitChild?.length > 0">
                  <b-badge v-for="(child, childIndex) in unitChild" :key="childIndex" class="border border-primary text-primary text-uppercase mb-1 mr-2" variant="none">{{ child.name }}</b-badge>
                </div>
              </b-form-group>
              <b-form-group label="Status" label-for="status">
                <b-form-checkbox v-model="formCargoType.status" name="check-button" switch inline>Active / Inactive
                </b-form-checkbox>
              </b-form-group>
              <hr />
              <b-form-group class="text-right">
                <b-button type="submit" variant="primary">
                  <span v-if="!cargo_type_edit">Add</span>
                  <span v-else>Save Edit</span>
                </b-button>
                <b-button
                  type="button"
                  variant="danger"
                  class="iq-bg-danger ml-3"
                  @click="resetFormCargoType()"
                >Cancel</b-button>
              </b-form-group>
            </b-form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-row v-if="viewMenu == 'unit'">
      <b-col sm="8">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">
              <strong>List Master Data Unit</strong>
            </h4>
          </template>
          <template #headerAction>
            <b-form
              class="row w-100"
              @submit.prevent="getUnit()"
            >
              <b-col cols="12" md="10" class="p-0">
                <b-form-input
                  id="name"
                  type="text"
                  placeholder="Search..."
                  v-model="unitList.params.name"
                ></b-form-input>
              </b-col>
              <b-col cols="12" md="2" class="p-0">
                <b-button type="submit" variant="primary" class="ml-2"
                  ><i class="fa fa-search"></i
                ></b-button>
              </b-col>
            </b-form>
          </template>
          <template v-slot:body>
            <div v-if="unitList._loading" class="text-center my-5">
              <b-spinner type="grow" label="Spinning"></b-spinner>
            </div>
            <template v-else>
              <div v-if="unitList.data && unitList.data.length > 0">
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Alias</th>
                        <th>Child</th>
                        <th class="text-center">#</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(state, index) in unitList.data" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>{{ state.name }}</td>
                        <td>{{ state.alias }}</td>
                        <td>
                          <div class="d-flex flex-wrap" v-if="state?.child?.length > 0">
                            <b-badge v-for="(child, childIndex) in state?.child" :key="childIndex" class="border border-primary text-primary text-uppercase mb-1 mr-2" variant="none">{{ child.name }}</b-badge>
                          </div>
                          <span v-else>-</span>
                        </td>
                        <td class="text-center">
                          <div class="d-flex justify-content-center">
                            <b-button varant="success" size="sm" class="mr-2"><i class="fa fa-edit" @click="editFormUnit(state)"></i></b-button>
                            <b-button variant="danger" size="sm" @click="onDeleteUnit(state)">
                              <i v-if="!delete_unit" class="fa fa-trash"></i>
                              <template v-else>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Deleting...
                              </template>
                            </b-button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <p v-else class="text-center my-4">No Unit data found.</p>
            </template>
          </template>
        </iq-card>
      </b-col>
      <b-col sm="4">
        <iq-card
          :class="{
        'border border-danger shadow-lg': unit_edit,
        }"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title"
            >{{ unit_edit ? 'Edit' : 'Add' }} Unit</h4
            >
          </template>
          <template v-slot:body>
            <b-form @submit.prevent="onSaveUnit()">
              <b-form-group label="Name" label-for="name">
                <b-form-input v-model="formUnit.name" id="name" type="text" placeholder="Type text"></b-form-input>
              </b-form-group>
              <b-form-group label="Alias" label-for="alias">
                <b-form-input v-model="formUnit.alias" id="alias" type="text" placeholder="Type text" maxlength="30"></b-form-input>
              </b-form-group>  
              <b-form-group label="Child" label-for="child">
                <v-multiselect
                  v-model="formUnit.child"
                  :options="optionsUnit"
                  :multiple="true"
                  placeholder="Select unit..."
                  track-by="id"
                  label="name"
                ></v-multiselect>
              </b-form-group>
              <hr />
              <b-form-group class="text-right">
                <b-button type="submit" variant="primary">
                  <span v-if="saving_unit" class="spinner-border spinner-border-sm"/>
                  <span v-else-if="!unit_edit && !saving_unit">Add</span>
                  <span v-else-if="unit_edit && !saving_unit">Save Edit</span>
                </b-button>
                <b-button
                  type="button"
                  variant="danger"
                  class="iq-bg-danger ml-3"
                  @click="resetFormUnit()"
                >Cancel</b-button>
              </b-form-group>
            </b-form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CustomNav from '@/src/components/global/CustomNav.vue';
import { busdevActions } from '@src/Utils/helper'
import _ from 'lodash'
export default {
  name: 'MasterCargoType',
  components:{
    CustomNav
  },
  data () {
    return {
      listCargoType:[],
      listParent:[],
      viewMenu: 'cargo',

      optionsUnit:[],
      unitChild:[],

      unitList: {
        data: [],
        _loading: true,
        detail: null,
        params: {
          name: '',
          // active: true,
          perPage: 10000,
          page: 1
        },
        meta: {
          perPage: 0,
          page: 0,
          totalItem: 0,
          totalPages: 0
        }
      },
      cargoTypeList: {
        data: [],
        _loading: true,
        detail: null,
        params: {
          name: '',
          // active: true,
          perPage: 30,
          page: 1
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        }
      },
      loading_unit:false,
      saving_unit:false,
      delete_unit:false,

      loading_cargo_type:false,
      saving_cargo_type:false,
      delete_cargo_type:false,

      formUnit: {
        alias: '',
        name: '',
        child: null,
      },
      formCargoType: {
        alias: '',
        name: '',
        unit_id: null,
        status: true,
      },
      unit_edit: null,
      unit_id: null,
      cargo_type_edit: null,
      cargo_type_id: null,
      navItems: [
        { label: 'Master Data Cargo Type', value: 'cargo' },
        { label: 'Master Data Unit', value: 'unit' },
      ],
    }
  },
  async mounted () {
    await this.getCargoType()

    await this.getUnit()
  },
  created () {
    this.getCargoType = _.debounce(this.getCargoType, 200)
    this.getUnit = _.debounce(this.getUnit, 200)
  },
  methods: {
    ...busdevActions,
    onChangeUnit(){
      let child = this.unitList.data.find(obj => obj.id == this.formCargoType.unit_id)

      this.unitChild = child?.child
    },
    async getCargoType (page) {
      this.cargoTypeList._loading = true
      this.cargoTypeList.data = []
      let params = {
        ...this.cargoTypeList.params,
        page: page || 1
      }
      if(!params.name) delete params.name 

      const res = await this.fetchCargoType(params)
  
      if (res.data.status == true) {
        this.cargoTypeList.data = res.data.data
      } else {
        this.cargoTypeList._loading = false
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
      this.cargoTypeList._loading = false
    },
    async getUnit (page) {
      this.unitList._loading = true
      this.unitList.data = []
      let params = {
        ...this.unitList.params,
        page: page || 1
      }
      if(!params.name) delete params.name 

      const res = await this.fetchUnit(params)

      console.log(res, "what res is this")

      if (res.data.status == true) {
        this.unitList.data = res.data.data

        this.optionsUnit = this.unitList.data.filter(val => val.child.length <= 0)
      } else {
        this.unitList._loading = false
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
      this.unitList._loading = false
    },

    async onSaveCargoType () {
      this.saving_cargo_type = true
      let payload = {
        ...this.formCargoType,
        status: this.formCargoType.status ? 1 : 0
      }

      let res = null
      if(this.cargo_type_edit && this.cargo_type_edit){
        res = await this.updateCargoType({data: payload, id: this.cargo_type_id})
      } else if (!this.cargo_type_edit) {
        res = await this.createCargoType({data: payload})
      }

      if (res.data.status === true) {
        this.$swal(`${this.cargo_type_edit ? 'Edit' : "Add"} Cargo Type Success!`, res.data.message, 'success')
        this.resetFormCargoType()
        this.getCargoType()
        this.saving_cargo_type = false
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
        this.saving_cargo_type = false
      }
      this.saving_cargo_type = false
    },
    async onSaveUnit() {
      this.saving_unit = true
      let payload = {
        ...this.formUnit,
      }

      let res = null
      if(this.unit_edit && this.unit_id){
        res = await this.updateUnit({data: payload, id: this.unit_id})
      } else if (!this.unit_edit) {
        res = await this.createUnit({data: payload})
      }

      if (res.data.status === true) {
        this.$swal(`${this.unit_edit ? 'Edit' : "Add"} Unit Success!`, res?.data?.message, 'success')
        this.resetFormUnit()
        this.getUnit()
        this.saving_unit = false
      } else {
        this.$swal(
          `Oops!`,
          res?.data?.message,
          'error'
        )
        this.saving_unit = false
      }
      this.saving_unit = false
    },

    resetFormCargoType (data) {
      this.formCargoType = {
        alias: '',
        name: '',
        unit_id: null,
        status: true,
      }
      this.cargo_type_edit = false
      this.cargo_type_id = null
    },
    resetFormUnit (data) {
      this.formUnit = {
        "name":'',
        "alias":'',
        "name":[],
      }
      this.unit_edit = false
      this.unit_id = null
    },

    editFormCargoType (data) {
      this.formCargoType = {
        "alias":data.alias,
        "name":data.name,
        "unit_id":data.unit_id,
        "status":data.status == 1 ? true : false
      }
      this.cargo_type_edit = true
      this.cargo_type_id = data.id
    },
    editFormUnit (data) {
      this.formUnit = {
        "name":data?.name,
        "alias":data?.alias,
        "child":data?.child,
      }
      this.unit_edit = true
      this.unit_id = data.id
    },

    async onDeleteCargoType (data) {
      this.$swal({
        title: 'Delete Cargo Type?',
        text: `Cargo Type ${data.name} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.saving_cargo_type = true
          let res = await this.deleteCargoType({id: data.id})
          if (res.status != 'success') {
            if (res.data.message != null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
            this.saving_cargo_type = false
          } else {
            this.$swal(
              `Cargo Type deleted!`,
              `Cargo Type ${data.name} successfully deleted`,
              'success'
            )
            await this.getCargoType()
            this.saving_cargo_type = false
          }
        }
      })
    },
    async onDeleteUnit (data) {
      this.$swal({
        title: 'Delete Unit?',
        text: `${data.name} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.saving_unit = true
          let res = await this.deleteUnit({id: data.id})
          if (res.status != 'success') {
            if (res.data.message != null) {
              this.$swal('Error', res.data.message, 'error')
            } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
            this.saving_unit = false
          } else {
            this.$swal(
              `Unit deleted!`,
              `${data.name} successfully deleted`,
              'success'
            )
            await this.getUnit()
            this.saving_unit = false
          }
        }
      })
    },
  }
}
</script>

<style scoped>
</style>
