<template>
  <b-row>
    <b-col md="12">
      <!-- Calendar view -->
      <div class="text-center section">
        <!-- {{ maintenance }} -->
        <v-calendar
          class="custom-calendar max-w-full"
          :masks="masks"
          :attributes="attributes"
          disable-page-swipe
          is-expanded
        >
          <template v-slot:day-content="{ day, attributes }">
            <div
              class="flex flex-col h-full z-1 border px-1 text-end overflow-auto"
              style="min-height: 124px; max-height: 240px; height: 100%"
            >
              <p class="m-0 text-base text-gray-900 text-end"
                ><strong>{{ day.day }}</strong></p
              >
              <div class="flex-grow overflow-y-auto overflow-x-auto">
                <div
                  v-for="attr in attributes"
                  :key="attr.key"
                  class="leading-tight rounded-sm p-1 mt-0 mb-1 text-white"
                >
                  <b-button
                    :key="attr.key"
                    :id="`popover-1-${attr.customData.data.id}`"
                    class="w-100 text-left"
                    style="font-size: x-small"
                    :variant="attr.customData.class"
                  >
                    <span style="font-weight: 600">{{
                      attr.customData.data.vehicleName
                    }}</span>
                    <br />
                    {{ attr.customData.data.jobName }}</b-button
                  >
                  <b-popover
                    :target="`popover-1-${attr.customData.data.id}`"
                    placement="top"
                    triggers="hover focus"
                    :content="attr.customData.class"
                  >
                    <div
                      v-if="
                        canAdd &&
                        ['TOWARDS', 'OVERDUE', 'POSTPONE', 'NEW'].includes(
                          attr.customData.data.status
                        )
                      "
                      @click.prevent="
                        $emit('viewDetailMaintenance', {
                          id: attr.customData.data.id,
                          type: 'ON_MAINTENANCE',
                        })
                      "
                      style="font-size: small"
                      class="cursor-pointer min-w-50 border p-1 px-2 mb-1 text-primary"
                      ><i class="fa fa-tasks"></i> Maintenance</div
                    >
                    <div
                      v-if="
                        ['APPROVE'].includes(attr.customData.data.status) &&
                        canApprove
                      "
                      style="font-size: small"
                      class="cursor-pointer min-w-50 border p-1 px-2 mb-1 text-info"
                      @click.prevent="
                        $emit('onApprove', attr.customData.data.id)
                      "
                      ><i class="fa fa-thumbs-up"></i> Approve</div
                    >
                    <div
                      v-if="
                        ['TOWARDS', 'OVERDUE', 'NEW'].includes(
                          attr.customData.data.status
                        )
                      "
                      @click.prevent="
                        $emit('viewDetailMaintenance', {
                          id: attr.customData.data.id,
                          type: 'ON_POSTPONE',
                        })
                      "
                      style="font-size: small"
                      class="cursor-pointer min-w-50 border p-1 px-2 mb-1 text-warning"
                      ><i class="fa fa-clock"></i> Postpone</div
                    >
                    <div
                      v-if="
                        ['MAINTENANCE', 'REVIEW'].includes(
                          attr.customData.data.status
                        ) && canReview
                      "
                      @click.prevent="
                        $emit('onReview', attr.customData.data.id)
                      "
                      style="font-size: small"
                      class="cursor-pointer min-w-50 border p-1 px-2 mb-1 text-info"
                      ><i class="fa fa-search"></i> Review</div
                    >
                    <div
                      v-if="
                        ['APPROVE', 'REVIEW'].includes(
                          attr.customData.data.status
                        )
                      "
                      @click.prevent="
                        $emit('viewDetailMaintenance', {
                          id: attr.customData.data.id,
                          type: 'ON_MAINTENANCE',
                          isEdit: true,
                        })
                      "
                      style="font-size: small"
                      class="cursor-pointer min-w-50 border p-1 px-2 mb-1 text-primary"
                      ><i class="fa fa-pen"></i> Edit</div
                    >
                    <div
                      v-if="
                        ['TOWARDS', 'OVERDUE', 'NEW'].includes(
                          attr.customData.data.status
                        )
                      "
                      style="font-size: small"
                      class="cursor-pointer min-w-50 border p-1 px-2 mb-1 text-danger"
                      @click.prevent="
                        $emit('viewDetailMaintenance', {
                          id: attr.customData.data.id,
                          type: 'ON_CONVERT',
                        })
                      "
                      ><i class="fa fa-tools"></i> Convert Repair</div
                    >
                  </b-popover>
                </div>
              </div>
            </div>
          </template>
        </v-calendar>
      </div>
      <!-- Calendar view -->
    </b-col>
  </b-row>
</template>

<script>
import moment from 'moment'
export default {
  name: 'ProgressCalendar',
  props: {
    maintenance: {
      type: Array,
      default: () => [],
    },
    canAdd: Boolean,
    canApprove: Boolean,
    canReview: Boolean,
  },
  components: {},
  data() {
    return {
      date: null,
      test: this.maintenance,
      masks: {
        weekdays: 'WWW',
      },
      attributes: [],
    }
  },
  methods: {
    setDataCalender(newVal) {
      let temp = []
      newVal.forEach((el, i) => {
        temp.push({
          key: 'maintance-calender' + i + 1,
          bar: true,
          customData: {
            data: el,
            class: `${this.statusColor(el.status)}`,
          },
          dates: moment(el.dueDate, 'DD-MM-YYYY').format('YYYY MM DD'),
        })
      })
      this.attributes = temp
    },
    statusColor(status) {
      let color
      switch (status) {
        case 'OVERDUE':
          color = 'danger'

          break
        case 'TOWARDS':
          color = 'info'
          break
        case 'REVIEW':
          color = 'warning'
          break
        case 'APPROVE':
          color = 'success'
          break
        case 'POSTPONE':
          color = 'info'
          break
        case 'NEW':
          color = 'primary'
          break
        default:
          day = 'light'
      }
      return color
    },
  },
  watch: {
    maintenance: {
      handler(newVal) {
        this.setDataCalender(newVal)
      },
      deep: true,
    },
    count: function (newVal) {
      console.log(newVal)
    },
  },

  mounted() {
    this.setDataCalender(this.maintenance)
  },
}
</script>

<style scoped>
.min-w-50 {
  min-width: 124px;
}
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  display: none;
}

.custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;

  border-radius: 0;
  width: 100%;

  & .vc-header {
    background-color: #f1f5f8;
    padding: 10px 0;
  }
  & .vc-weeks {
    padding: 0;
  }
  & .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    padding: 5px 0;
  }
  & .vc-day {
    padding: 0 5px 3px 5px;
    text-align: left;
    height: var(--day-height);
    min-width: var(--day-width);
    background-color: white;
    &.weekday-1,
    &.weekday-7 {
      background-color: #eff8ff;
    }
    &:not(.on-bottom) {
      border-bottom: var(--day-border);
      &.weekday-1 {
        border-bottom: var(--day-border-highlight);
      }
    }
    &:not(.on-right) {
      border-right: var(--day-border);
    }
  }
  & .vc-day-dots {
    margin-bottom: 5px;
  }
}
</style>
