<template>
  <svg
    width="26"
    height="22"
    viewBox="0 0 26 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.07129 10.8167H9.42842V12.6024H4.07129V10.8167ZM4.07129 7.24525H12.9998V9.03096H4.07129V7.24525ZM4.07129 3.67383H12.9998V5.45954H4.07129V3.67383Z"
      :fill="color"
    />
    <path
      d="M11.2143 19.7459H2.28571V1.88874H23.7142V10.8173H25.5V1.88874C25.5 1.41514 25.3118 0.960936 24.9769 0.62605C24.642 0.291164 24.1878 0.103027 23.7142 0.103027H2.28571C1.81211 0.103027 1.35791 0.291164 1.02302 0.62605C0.688137 0.960936 0.5 1.41514 0.5 1.88874V19.7459C0.5 20.2195 0.688137 20.6737 1.02302 21.0085C1.35791 21.3434 1.81211 21.5316 2.28571 21.5316H11.2143V19.7459Z"
      :fill="color"
    />
    <path
      d="M18.3576 19.3795L16.0451 17.067L14.7861 18.326L18.3576 21.8974L25.5004 14.7545L24.2415 13.4956L18.3576 19.3795Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
}
</script>
