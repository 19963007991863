<template>
  <b-collapse tag="ul" :class="className" :visible="open" :id="idName" :accordion="accordianName">
    <li v-for="(item,index) in items" :key="index" :class="{ 'active' : activeLink(item) }">
      <i v-if="!item.heading" class="ri-subtract-line" />
      <span v-if="!item.heading">{{ $t(item.menu) }}</span>
      <router-link :to="{ name: item.props, query: { menuId: item.menuId } }" v-if="item.heading && item.className != 'true'" :class="`iq-waves-effect ${activeLink(item) && item.child ? 'active' : activeLink(item) ? 'active' : ''}`" v-b-toggle="item.menu">
        <i :class="item.icon" v-if="item.iconClass"/>
        <template v-else v-html="item.icon"></template>
        <span>{{ $t(item.menu) }}</span>
      </router-link>
    </li>
    <li class="d-flex mt-5" style="cursor: pointer;overflow:auto;top:180px;">
      <button type="button" class="btn btn-secondary btn-block" @click="clearCache" style=""> 
        <i class="fa fa-refresh mr-2" />
        <span>Clear Cache</span>
      </button>
    </li>
  </b-collapse>
</template>
<script>
import List from './ListStyle1'
import { xray } from '../../../config/pluginInit'
export default {
  name: 'List',
  props: {
    items: Array,
    className: { type: String, default: 'iq-menu' },
    horizontal: Boolean,
    open: { type: Boolean, default: false },
    idName: { type: String, default: 'sidebar' },
    accordianName: { type: String, default: 'sidebar' }
  },
  components: {
    List
  },
  methods: {
    activeLink (item) {
      return xray.getActiveLink(item, this.$route.name)
    },
    clearCache() {
      var all_cookies = document.cookie.split(";");
      for (var i = 0; i < all_cookies.length; i++) {
          var single_cookie = all_cookies[i];
          var cookie_index = single_cookie.indexOf("=");
          var cookie_name = cookie_index > -1 ? all_cookies.substr(0, cookie_index) : single_cookie;
          document.cookie = cookie_name + "=;expires=" + new Date().toUTCString();
      }

      var cookies = document.cookie.split("; ");
      for (var c = 0; c < cookies.length; c++) {
          var d = window.location.hostname.split(".");
          while (d.length > 0) {
              var cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' + d.join('.') + ' ;path=';
              var p = location.pathname.split('/');
              document.cookie = cookieBase + '/';
              while (p.length > 0) {
                  document.cookie = cookieBase + p.join('/');
                  p.pop();
              };
              d.shift();
          }
      }

      window.location.reload(true);
    }
  }
}
</script>
