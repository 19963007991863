<template>
  <b-col sm="12" class="p-0">
    <b-row>
      <b-col md="6" xl="3"  style="margin-bottom:2rem ;" v-for="(item, i) in certificate" :key="i">
        <router-link :to="item.path">
          <div
            class="d-flex align-items-center bg-white p-3 shadow-sm card-certificate h-full"
          >
          <div class="card-icon mr-2" :style="`background: ${item.color} ;`">
            <!-- <i :class="item.icon" size="lg"></i> -->
             <component :is="item.icon"></component>
          </div>
            <div class="text-left">
              <h5 class="line-height mb-1"><strong>{{ item.total ?? '-' }}</strong></h5>
              <h5 class="">
                {{ item.name }}
              </h5>
            </div>
          </div>
        </router-link>
      </b-col>
    </b-row>
  </b-col>
</template>
<script>
import { dashboardActions } from '@/src/Utils/helper'
import CertificateIconActive from './CertificateIcon/CertificateIconActive.vue';
import CertificateIconChecked from './CertificateIcon/CertificateIconChecked.vue';
import CertificateIconExpired from './CertificateIcon/CertificateIconExpired.vue';
import CertificateIconProgress from './CertificateIcon/CertificateIconProgress.vue';

export default {
  components:{
    CertificateIconActive,
    CertificateIconChecked,
    CertificateIconExpired,
    CertificateIconProgress
  },
  data() {
    return {
      statsCertificateData: {
        totalCertificate: null,
        totalExpired: null,
        totalOneMonth: null,
        totalProcess: null,
      },
      certificate: [
        {
          name: 'Total Certificate',
          path: '/ops/certificate',
          total: '',
          icon: CertificateIconActive,
          color:'#71FFD7',
        },
        {
          name: 'Certificate Expired',
          path: '/ops/certificate?status_certificate=expired',
          total: '',
          icon: CertificateIconExpired,
          color:'#FFD1D1'
        },
        {
          name: '1 Month Will Expired',
          path: '/ops/certificate?status_certificate=nextMonth',
          total: '',
          icon: CertificateIconChecked,
          color:'#FFDFF8'
        },
        {
          name: 'In the Progress',
          path: '/ops/certificate?status=progress',
          total: '',
          icon: CertificateIconProgress,
          color:'#B9D1FF'
        },
      ],
    }
  },
  methods: {
    ...dashboardActions,
    async fetchStatsCertificate() {
      try {
        let res = await this.getStatsCertificate()
        const data = res.data
        this.certificate.find((el) => el.name == 'Total Certificate').total =
          data.totalCertificate
        this.certificate.find((el) => el.name == 'Certificate Expired').total =
          data.totalExpired
        this.certificate.find((el) => el.name == '1 Month Will Expired').total =
          data.totalOneMonth
        this.certificate.find((el) => el.name == 'In the Progress').total =
          data.totalProcess
      } catch (error) {
        console.log(error)
      }
    },
  },
  mounted() {
    this.fetchStatsCertificate()
  },
}
</script>
<style scoped>
.card-certificate {
  border-radius: 1rem;
  transition: all;
  transition-duration:250ms;
}
.card-certificate:hover {
  scale: 1.05;
}
.card-icon {
  border-radius: 1rem;
  font-size: 2rem;
  width: 4rem;
  height:4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
