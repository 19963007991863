var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.checked ? _c('b-row', [_c('b-form-group', {
    staticClass: "col-md-3",
    attrs: {
      "label": "Provinsi",
      "label-for": "Provinsi"
    }
  }, [_c('v-select', {
    staticClass: "style-chooser",
    attrs: {
      "id": "provinsi",
      "label": "name",
      "options": _vm.option.provience,
      "reduce": function reduce(option) {
        return option.name;
      },
      "placeholder": "Pilih Provinsi",
      "clearable": false,
      "disabled": _vm.checked
    },
    on: {
      "input": function input(e) {
        return _vm.onSelect(e, 'provinsi');
      }
    },
    model: {
      value: _vm.form.provinsi,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "provinsi", $$v);
      },
      expression: "form.provinsi"
    }
  })], 1), _c('b-form-group', {
    staticClass: "col-md-3",
    attrs: {
      "label": "Kota / Kabupaten",
      "label-for": "Kota"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "Kota",
      "label": "name",
      "options": _vm.option.cities,
      "reduce": function reduce(option) {
        return option.name;
      },
      "placeholder": _vm.form.kecamatan ? 'Pilih Kabupaten' : 'Pilih Provinsi Terlebih Dahulu',
      "clearable": false,
      "disabled": _vm.checked || this.loadingKota || !_vm.form.provinsi
    },
    on: {
      "input": function input(e) {
        return _vm.onSelect(e, 'kabupaten');
      }
    },
    model: {
      value: _vm.form.kabupaten,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "kabupaten", $$v);
      },
      expression: "form.kabupaten"
    }
  })], 1), _c('b-form-group', {
    staticClass: "col-md-3",
    attrs: {
      "label": "Kecamatan",
      "label-for": "Kecamatan"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "Kecamatan",
      "label": "name",
      "options": _vm.option.districts,
      "reduce": function reduce(option) {
        return option.name;
      },
      "placeholder": _vm.form.kabupaten ? 'Pilih Kecamatan' : 'Pilih Kabupaten Terlebih Dahulu',
      "clearable": false,
      "disabled": _vm.checked || this.loadingKecamatan || !_vm.form.kabupaten
    },
    on: {
      "input": function input(e) {
        return _vm.onSelect(e, 'kecamatan');
      }
    },
    model: {
      value: _vm.form.kecamatan,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "kecamatan", $$v);
      },
      expression: "form.kecamatan"
    }
  })], 1), _c('b-form-group', {
    staticClass: "col-md-3",
    attrs: {
      "label": "Kelurahan",
      "label-for": "Kelurahan"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "Kecamatan",
      "label": "name",
      "options": _vm.option.districts,
      "reduce": function reduce(option) {
        return option.name;
      },
      "placeholder": _vm.form.kecamatan ? 'Pilih Kelurahan' : 'Pilih Kecamatan Terlebih Dahulu',
      "clearable": false,
      "disabled": _vm.checked || this.loadingKelurahan || !_vm.form.kecamatan
    },
    model: {
      value: _vm.form.kelurahan,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "kelurahan", $$v);
      },
      expression: "form.kelurahan"
    }
  })], 1), _c('b-form-group', {
    staticClass: "col-md-3",
    attrs: {
      "label": "No Rumah",
      "label-for": "no_rumah"
    }
  }, [_c('b-form-input', {
    staticStyle: {
      "height": "35px"
    },
    attrs: {
      "id": "no_rumah",
      "type": "number",
      "name": "no_rumah",
      "placeholder": "Type Number...",
      "disabled": _vm.checked,
      "required": ""
    },
    on: {
      "keydown": _vm.checkKeyNumber
    },
    model: {
      value: _vm.form.no_rumah,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "no_rumah", $$v);
      },
      expression: "form.no_rumah"
    }
  })], 1), _c('b-form-group', {
    staticClass: "col-md-3",
    attrs: {
      "label": "Kode POS",
      "label-for": "kode_pos"
    }
  }, [_c('b-form-input', {
    staticStyle: {
      "height": "35px"
    },
    attrs: {
      "id": "kode_pos",
      "type": "number",
      "name": "kode_pos",
      "disabled": _vm.checked,
      "required": ""
    },
    on: {
      "keydown": _vm.checkKeyNumber
    },
    model: {
      value: _vm.form.kode_pos,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "kode_pos", $$v);
      },
      expression: "form.kode_pos"
    }
  })], 1), _c('b-form-group', {
    staticClass: "col-md-6",
    attrs: {
      "label": "Alamat Domisili",
      "label-for": "alamat_domisili"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "alamat_domisili",
      "name": "alamat_domisili",
      "rows": "1",
      "disabled": _vm.checked,
      "required": "",
      "placeholder": "Alamat..."
    },
    model: {
      value: _vm.form.alamat,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "alamat", $$v);
      },
      expression: "form.alamat"
    }
  })], 1)], 1) : _c('b-row', [_c('b-form-group', {
    staticClass: "col-md-3",
    attrs: {
      "label": "Provinsi",
      "label-for": "Provinsi"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.form.provinsi ? _vm.form.provinsi : 'Kosong pilih alamat KTP terlebih dahulu'))])]), _c('b-form-group', {
    staticClass: "col-md-3",
    attrs: {
      "label": "Kabupaten",
      "label-for": "Kabupaten"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.form.kabupaten ? _vm.form.kabupaten : 'Kosong pilih alamat KTP terlebih dahulu'))])]), _c('b-form-group', {
    staticClass: "col-md-3",
    attrs: {
      "label": "Kecamatan",
      "label-for": "Kecamatan"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.form.kecamatan ? _vm.form.kecamatan : 'Kosong pilih alamat KTP terlebih dahulu'))])]), _c('b-form-group', {
    staticClass: "col-md-3",
    attrs: {
      "label": "Kelurahan",
      "label-for": "Kelurahan"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.form.kelurahan ? _vm.form.kelurahan : 'Kosong pilih alamat KTP terlebih dahulu'))])]), _c('b-form-group', {
    staticClass: "col-md-3",
    attrs: {
      "label": "No Rumah",
      "label-for": "no_rumah"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.form.no_rumah ? _vm.form.no_rumah : 'Kosong pilih alamat KTP terlebih dahulu'))])]), _c('b-form-group', {
    staticClass: "col-md-3",
    attrs: {
      "label": "Kode Pos",
      "label-for": "Kode Pos"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.form.kode_pos ? _vm.form.kode_pos : 'Kosong pilih alamat KTP terlebih dahulu'))])]), _c('b-form-group', {
    staticClass: "col-md-6",
    attrs: {
      "label": "Alamat",
      "label-for": "Alamat"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.form.alamat ? _vm.form.alamat : 'Kosong Kosong pilih alamat KTP terlebih dahulu'))])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }