var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_c('b-button', {
    staticClass: "d-inline-block",
    attrs: {
      "variant": "primary",
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-arrow-left"
  }), _c('span', [_vm._v("Kembali")])])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('form', {
    attrs: {
      "action": "#",
      "method": "post"
    },
    on: {
      "submit": _vm.onSaveDataDiri
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("UPDATE DATA " + _vm._s(_vm.$route.query.user === 'crew' ? "CREW" : "CANDIDATE") + " ")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$error, _vm$error2, _vm$error3, _vm$error4, _vm$user;
        return [_c('b-form-group', {
          staticClass: "text-center"
        }, [_c('div', {
          staticClass: "add-img-user profile-img-edit"
        }, [_c('b-img', {
          staticClass: "profile-pic height-150 width-150",
          attrs: {
            "fluid": "",
            "src": _vm.user.data_diri.photo ? _vm.user.data_diri.photo : _vm.user.data_diri.default_photo,
            "alt": "profile-pic"
          }
        }), _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.user.data_diri.photo,
            expression: "user.data_diri.photo"
          }],
          attrs: {
            "type": "hidden"
          },
          domProps: {
            "value": _vm.user.data_diri.photo
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.user.data_diri, "photo", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "p-image"
        }, [_c('b-button', {
          staticClass: "upload-button iq-bg-primary position-relative",
          attrs: {
            "variant": "none"
          }
        }, [_c('input', {
          staticClass: "h-100 position-absolute",
          staticStyle: {
            "opacity": "0"
          },
          attrs: {
            "type": "file",
            "accept": "image/*"
          },
          on: {
            "change": _vm.previewImage
          }
        }), _vm._v(" File Upload ")])], 1)], 1), _c('div', {
          staticClass: "img-extension mt-3"
        }, [_c('div', {
          staticClass: "d-inline-block align-items-center"
        }, [_c('span', [_vm._v("Only")]), _c('b-link', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_vm._v(".jpg")]), _c('b-link', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_vm._v(".png")]), _c('b-link', {
          attrs: {
            "href": "javascript:void(0);"
          }
        }, [_vm._v(".jpeg")]), _c('span', [_vm._v("allowed")])], 1)])]), _c('b-alert', {
          staticClass: "text-center text-white bg-primary",
          attrs: {
            "show": true
          }
        }, [_c('div', {
          staticClass: "iq-alert-text"
        }, [_c('strong', [_vm._v("DATA DIRI")])])]), _c('b-form-group', {
          attrs: {
            "label": "Nama Lengkap",
            "label-for": "nama_lengkap"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "nama_lengkap",
            "name": "nama_lengkap",
            "placeholder": "Type Text ...",
            "required": ""
          },
          model: {
            value: _vm.user.data_diri.full_name,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "full_name", $$v);
            },
            expression: "user.data_diri.full_name"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Nama Panggilan",
            "label-for": "nama_panggilan"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "nama_panggilan",
            "name": "nama_panggilan",
            "placeholder": "Type Text ...",
            "required": ""
          },
          model: {
            value: _vm.user.data_diri.nick_name,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "nick_name", $$v);
            },
            expression: "user.data_diri.nick_name"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Nomor Induk Kependudukan (NIK)",
            "label-for": "nik"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "nik",
            "name": "nik",
            "placeholder": "Type Number ...",
            "required": ""
          },
          model: {
            value: _vm.user.data_diri.nik_ktp,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "nik_ktp", $$v);
            },
            expression: "user.data_diri.nik_ktp"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Nomor Kartu Keluarga (KK)",
            "label-for": "no_kk"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "no_kk",
            "name": "no_kk",
            "placeholder": "Type Number ...",
            "required": ""
          },
          model: {
            value: _vm.user.data_diri.no_kk,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "no_kk", $$v);
            },
            expression: "user.data_diri.no_kk"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Seaferer Code",
            "label-for": "seafererCode"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "seafererCode",
            "name": "seafererCode",
            "placeholder": "Type Number ...",
            "required": ""
          },
          model: {
            value: _vm.user.data_diri.seaferer_code,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "seaferer_code", $$v);
            },
            expression: "user.data_diri.seaferer_code"
          }
        }), (_vm$error = _vm.error) !== null && _vm$error !== void 0 && _vm$error.seaferer_code ? _c('b-form-text', {
          staticClass: "text-danger",
          attrs: {
            "id": "seafererCode-live-help"
          }
        }, [_vm._v("*" + _vm._s((_vm$error2 = _vm.error) === null || _vm$error2 === void 0 ? void 0 : _vm$error2.seaferer_code))]) : _vm._e()], 1), _c('b-form-group', {
          attrs: {
            "label": "Seaman Book Number",
            "label-for": "seafererCode"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "seamanBookNumber",
            "name": "seamanBookNumber",
            "placeholder": "Type Number ...",
            "required": ""
          },
          model: {
            value: _vm.user.data_diri.seaman_book_number,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "seaman_book_number", $$v);
            },
            expression: "user.data_diri.seaman_book_number"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Passport Number",
            "label-for": "passportNumber"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "passportNumber",
            "name": "passportNumber",
            "placeholder": "Type Number ..."
          },
          model: {
            value: _vm.user.data_diri.passport_number,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "passport_number", $$v);
            },
            expression: "user.data_diri.passport_number"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Nomor Pokok Wajib Pajak (NPWP)",
            "label-for": "npwp"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "npwp",
            "name": "npwp",
            "placeholder": "Type Number ...",
            "required": ""
          },
          model: {
            value: _vm.user.data_diri.npwp_number,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "npwp_number", $$v);
            },
            expression: "user.data_diri.npwp_number"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Jabatan / Ijazah",
            "label-for": "jabatan"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionsPositions,
            "size": "sm",
            "disabled": ""
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Jabatan")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.user.data_diri.last_position,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "last_position", $$v);
            },
            expression: "user.data_diri.last_position"
          }
        })], 1), _vm.$route.query.user === 'crew' ? _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Nama Kapal",
            "label-for": "kapal"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionsVehicles,
            "size": "sm",
            "disabled": ""
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Nama Kapal")])];
            },
            proxy: true
          }], null, false, 2375183714),
          model: {
            value: _vm.user.data_diri.vehicle_id,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "vehicle_id", $$v);
            },
            expression: "user.data_diri.vehicle_id"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Pelabuhan",
            "label-for": "port_id"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "port_id",
            "name": "port_id",
            "placeholder": "Type text ...",
            "disabled": ""
          },
          model: {
            value: _vm.user.data_diri.port_id,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "port_id", $$v);
            },
            expression: "user.data_diri.port_id"
          }
        })], 1)], 1) : _vm._e(), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Tempat Lahir",
            "label-for": "tempat_lahir"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "tempat_lahir",
            "name": "tempat_lahir",
            "placeholder": "Type Text ...",
            "required": ""
          },
          model: {
            value: _vm.user.data_diri.place_of_birth,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "place_of_birth", $$v);
            },
            expression: "user.data_diri.place_of_birth"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Tanggal Lahir",
            "label-for": "tanggal_lahir"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "date",
            "id": "tanggal_lahir",
            "required": ""
          },
          model: {
            value: _vm.user.data_diri.date_of_birth,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "date_of_birth", $$v);
            },
            expression: "user.data_diri.date_of_birth"
          }
        }), _c('small', {
          staticStyle: {
            "float": "right"
          }
        }, [_vm._v("click "), _c('i', {
          staticClass: "fa fa-calendar"
        }), _vm._v(" to show ")])], 1)], 1), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Golongan Darah",
            "label-for": "golongan_darah"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.options_bloodtype,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Golongan Darah")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.user.data_diri.golongan_darah_id,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "golongan_darah_id", $$v);
            },
            expression: "user.data_diri.golongan_darah_id"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Agama",
            "label-for": "Agama"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionsAgama,
            "size": "sm",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Agama")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.user.data_diri.religion_id,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "religion_id", $$v);
            },
            expression: "user.data_diri.religion_id"
          }
        })], 1)], 1), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Status Pernikahan",
            "label-for": "StatusPernikahan"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionsStatusPernikahan,
            "size": "sm",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Status")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.user.data_diri.marital_status_id,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "marital_status_id", $$v);
            },
            expression: "user.data_diri.marital_status_id"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Kewarganegaraan",
            "label-for": "kewarganegaraan"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "size": "sm",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": ''
                }
              }, [_vm._v("Select Kewarganegaraan")]), _c('b-form-select-option', {
                attrs: {
                  "value": "WNI"
                }
              }, [_vm._v("Warga Negara Indonesia (WNI)")]), _c('b-form-select-option', {
                attrs: {
                  "value": "WNA"
                }
              }, [_vm._v("Warga Negara Asing (WNA)")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.user.data_diri.citizenship,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "citizenship", $$v);
            },
            expression: "user.data_diri.citizenship"
          }
        })], 1)], 1), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "No. Tlp / Handphone",
            "label-for": "no_handphone"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "no_handphone",
            "name": "no_handphone",
            "placeholder": "Type Number ...",
            "required": ""
          },
          model: {
            value: _vm.user.data_diri.hp_number,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "hp_number", $$v);
            },
            expression: "user.data_diri.hp_number"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Email",
            "label-for": "email"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "email",
            "name": "email",
            "placeholder": "Type Text ...",
            "required": ""
          },
          model: {
            value: _vm.user.data_diri.email,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "email", $$v);
            },
            expression: "user.data_diri.email"
          }
        }), (_vm$error3 = _vm.error) !== null && _vm$error3 !== void 0 && _vm$error3.email ? _c('b-form-text', {
          staticClass: "text-danger",
          attrs: {
            "id": "email-live-help"
          }
        }, [_vm._v("*" + _vm._s((_vm$error4 = _vm.error) === null || _vm$error4 === void 0 ? void 0 : _vm$error4.email))]) : _vm._e()], 1)], 1), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-12",
          attrs: {
            "label": "Status Pajak",
            "label-for": "ptkp_id"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "size": "sm",
            "options": _vm.options_status_pajak,
            "placeholder": "Select Options..."
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select status pajak...")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.user.data_diri.ptkp_id,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "ptkp_id", $$v);
            },
            expression: "user.data_diri.ptkp_id"
          }
        })], 1)], 1), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-12",
          attrs: {
            "label": "No BPJS Kesehatan",
            "label-for": "bpjs_kes_number"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "bpjs_kes_number",
            "name": "bpjs_kes_number",
            "placeholder": "Type Number ..."
          },
          model: {
            value: _vm.user.data_diri.bpjs_kes_number,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "bpjs_kes_number", $$v);
            },
            expression: "user.data_diri.bpjs_kes_number"
          }
        })], 1)], 1), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-12",
          attrs: {
            "label": "Recommendation By",
            "label-for": "recommendation_by"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "recommendation_by",
            "name": "recommendation_by",
            "placeholder": "Type Text ..."
          },
          model: {
            value: _vm.user.data_diri.recommendation_by,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "recommendation_by", $$v);
            },
            expression: "user.data_diri.recommendation_by"
          }
        })], 1)], 1), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-12",
          attrs: {
            "label": "No BPJS Ketenagakerjaan",
            "label-for": "bpjs_tk_number"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "bpjs_tk_number",
            "name": "bpjs_tk_number",
            "placeholder": "Type Number ..."
          },
          model: {
            value: _vm.user.data_diri.bpjs_tk_number,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "bpjs_tk_number", $$v);
            },
            expression: "user.data_diri.bpjs_tk_number"
          }
        })], 1)], 1), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-12",
          attrs: {
            "label": "CV Upload",
            "label-for": "cv_file"
          }
        }, [_c('b-form-file', {
          attrs: {
            "id": "cv_file",
            "accept": "image/*,application/*",
            "required": _vm.$route.query.user === 'candidate' && !_vm.user.cv_file
          },
          model: {
            value: _vm.user.data_diri.cv_file,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "cv_file", $$v);
            },
            expression: "user.data_diri.cv_file"
          }
        }), (_vm$user = _vm.user) !== null && _vm$user !== void 0 && _vm$user.cv_file ? _c('small', {
          staticClass: "text-primary",
          staticStyle: {
            "cursor": "pointer"
          },
          on: {
            "click": function click($event) {
              var _vm$user2;
              return _vm.handleOpenFilePreview((_vm$user2 = _vm.user) === null || _vm$user2 === void 0 ? void 0 : _vm$user2.cv_file_url);
            }
          }
        }, [_vm._v(" Open File ")]) : _vm._e(), _c('br'), _c('small', {
          staticClass: "text-muted"
        }, [_vm._v(" File Type * : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File CapaKota * : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v("3 MB / File")])], 1)], 1)], 1), _c('b-alert', {
          staticClass: "text-center text-white bg-primary",
          attrs: {
            "show": true
          }
        }, [_c('div', {
          staticClass: "iq-alert-text"
        }, [_c('strong', [_vm._v("PAKAIAN & SEPATU")])])]), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Tinggi Badan",
            "label-for": "height"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "height",
            "name": "height",
            "placeholder": "Type Text ..."
          },
          model: {
            value: _vm.user.data_diri.height,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "height", $$v);
            },
            expression: "user.data_diri.height"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Berat Badan",
            "label-for": "weight"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "weight",
            "name": "weight",
            "placeholder": "Type Text ..."
          },
          model: {
            value: _vm.user.data_diri.weight,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "weight", $$v);
            },
            expression: "user.data_diri.weight"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Wearpack",
            "label-for": "wearpack"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.wearpackCrew,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Wearpack")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.user.data_diri.wearpack,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "wearpack", $$v);
            },
            expression: "user.data_diri.wearpack"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Helmet",
            "label-for": "helmet"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "helmet",
            "name": "helmet",
            "placeholder": "Type Text ..."
          },
          model: {
            value: _vm.user.data_diri.helmet,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "helmet", $$v);
            },
            expression: "user.data_diri.helmet"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Size of Shoes",
            "label-for": "size_of_shoes"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "size_of_shoes",
            "name": "size_of_shoes",
            "placeholder": "Type Text ..."
          },
          model: {
            value: _vm.user.data_diri.size_of_shoes,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "size_of_shoes", $$v);
            },
            expression: "user.data_diri.size_of_shoes"
          }
        })], 1)], 1), _c('b-alert', {
          staticClass: "text-center text-white bg-primary",
          attrs: {
            "show": true
          }
        }, [_c('div', {
          staticClass: "iq-alert-text"
        }, [_c('strong', [_vm._v("AKUN BANK")])])]), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Atas Nama",
            "label-for": "atas_nama"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "atas_nama",
            "name": "atas_nama",
            "placeholder": "Type Text ...",
            "required": ""
          },
          model: {
            value: _vm.user.data_diri.rekening_name,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "rekening_name", $$v);
            },
            expression: "user.data_diri.rekening_name"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Nama Bank",
            "label-for": "nama_bank"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionsBank,
            "size": "sm",
            "required": ""
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Bank")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.user.data_diri.bank_id,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "bank_id", $$v);
            },
            expression: "user.data_diri.bank_id"
          }
        })], 1)], 1), _c('b-form-group', {
          attrs: {
            "label": "Nomor Rekening",
            "label-for": "no_rek"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "number",
            "id": "no_rek",
            "name": "no_rek",
            "placeholder": "Type Number ...",
            "required": ""
          },
          model: {
            value: _vm.user.data_diri.rekening_number,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "rekening_number", $$v);
            },
            expression: "user.data_diri.rekening_number"
          }
        })], 1), _vm.$route.query.user === 'crew' ? _c('b-alert', {
          staticClass: "text-center text-white bg-primary",
          attrs: {
            "show": true
          }
        }, [_c('div', {
          staticClass: "iq-alert-text"
        }, [_c('strong', [_vm._v("JOIN DATE")])])]) : _vm._e(), _vm.$route.query.user === 'crew' ? _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Sign On",
            "label-for": "sign_on"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "sign_on",
            "name": "sign_on",
            "type": "datetime-local"
          },
          model: {
            value: _vm.user.data_diri.sign_on,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "sign_on", $$v);
            },
            expression: "user.data_diri.sign_on"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-6",
          attrs: {
            "label": "Join Onboard",
            "label-for": "join_onboard"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "join_onboard",
            "name": "join_onboard",
            "type": "datetime-local"
          },
          model: {
            value: _vm.user.data_diri.join_on_board,
            callback: function callback($$v) {
              _vm.$set(_vm.user.data_diri, "join_on_board", $$v);
            },
            expression: "user.data_diri.join_on_board"
          }
        })], 1)], 1) : _vm._e(), _c('b-button', {
          staticClass: "mt-3",
          attrs: {
            "disabled": _vm.saving_datadiri,
            "block": "",
            "variant": "success",
            "type": "submit"
          }
        }, [_vm.saving_datadiri ? _c('span', {
          staticClass: "spinner-border spinner-border-sm"
        }) : _c('span', [_vm._v("Update Data Diri")])])];
      },
      proxy: true
    }])
  })], 1)]), _c('b-col', {
    attrs: {
      "lg": "8"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("UPDATE ALAMAT")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('form', {
          attrs: {
            "action": "#",
            "method": "post"
          },
          on: {
            "submit": _vm.onSaveAlamat
          }
        }, [_c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary",
          staticStyle: {
            "font-size": "12pt"
          }
        }, [_vm._v("ALAMAT KTP")]), _c('FormInputAddressEdit', {
          attrs: {
            "alamat": _vm.user.alamat[0]
          },
          model: {
            value: _vm.user.ktp_address,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "ktp_address", $$v);
            },
            expression: "user.ktp_address"
          }
        })], 1), _c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary",
          staticStyle: {
            "font-size": "12pt"
          }
        }, [_vm._v("ALAMAT DOMISILI")]), _c('FormInputAddressEdit', {
          attrs: {
            "checked": _vm.user._checked,
            "alamat": _vm.user.alamat[1]
          },
          model: {
            value: _vm.user.domicile_address,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "domicile_address", $$v);
            },
            expression: "user.domicile_address"
          }
        }), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-12"
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": ""
          },
          model: {
            value: _vm.user._checked,
            callback: function callback($$v) {
              _vm.$set(_vm.user, "_checked", $$v);
            },
            expression: "user._checked"
          }
        }, [_c('small', {
          staticClass: "text-muted"
        }, [_vm._v(" Alamat domisili sama dengan alamat ktp")])])], 1)], 1)], 1), _c('b-button', {
          staticClass: "my-3",
          attrs: {
            "disabled": _vm.saving_alamat,
            "block": "",
            "variant": "success",
            "type": "submit"
          }
        }, [_vm.saving_alamat ? _c('span', {
          staticClass: "spinner-border spinner-border-sm"
        }) : _c('span', [_vm._v("Update alamat")])])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }