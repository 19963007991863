<template>
  <b-container fluid>
    <form  @submit="onSave" action="#">
      <b-row>
        <b-col md="12">
          <div class="d-flex align-items-center justify-content-end w-100">
            <b-button
           
                block
                variant="primary"
                type="submit"
                style="width:15%"
              >
                <span
                  v-if="saving"
                  class="spinner-border spinner-border-sm"
                />
                <div v-else>
                  <i class="fa fa-save"></i>
                  <span>Submit Data</span>
                </div>
              </b-button>
            </div>
          </b-col>
        <b-col lg="12" class="mb-4">
          <b-row>
            <b-col md="3">
              <b-form-group label="* Vendor ID" label-for="vendor_id">
                <b-form-input
                  required
                  id="vendor_id"
                  type="text"
                  placeholder="Type text"
                  v-model="formVendor.vendor_id"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="* Vendor Status" label-for="vendor_status">
                <b-form-select
                  required
                  id="vendor_status"
                  plain
                  size="md"
                  v-model="formVendor.vendor_status"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="''">Select status...</b-form-select-option>
                    <b-form-select-option :value="'active'">Active</b-form-select-option>
                    <b-form-select-option :value="'on-hold'">On Hold</b-form-select-option>
                    <b-form-select-option :value="'credit-hold'">Credit Hold</b-form-select-option>
                    <b-form-select-option :value="'one-time'">One-Time</b-form-select-option>
                    <b-form-select-option :value="'inactive'">Inactive</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <b-form-group label="* Vendor Class" label-for="vendor_class_id">
                <b-form-select
                  required
                  id="vendor_class_id"
                  plain
                  size="md"
                  :options="optionsVendorClass"
                  v-model="formVendor.vendor_class_id"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null">Select status...</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Vendor Type" label-for="vendor_type_id">
                <b-form-select
                  id="vendor_type_id"
                  plain
                  size="md"
                  :options="optionsVendorType"
                  v-model="formVendor.vendor_type_id"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null">Select type...</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row> 
          <b-row>
            <b-col md="3">
              <b-form-group label="Vendor Category" label-for="category">
                <b-form-select
                  id="category"
                  plain
                  size="md"
                  v-model="formVendor.category"
                  required
                >
                  <template v-slot:first>
                    <b-form-select-option :value="''">Select category...</b-form-select-option>
                    <b-form-select-option :value="'temp'">Temp</b-form-select-option>
                    <b-form-select-option :value="'selected'">Selected</b-form-select-option>
                    <b-form-select-option :value="'rejected'">Rejected</b-form-select-option>
                    <b-form-select-option :value="'none'">None</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="12" >
          <iq-card body-class="p-0">
            <template v-slot:body>
              <div class="iq-edit-list">
                <tab-nav :pills="true" class="nav-fill mb-3">
                  <tab-nav-items class="col-md-2 p-0" :active="true" @click.native="tab = 'GENERAL'" href="#general" title="General"/>
                  <tab-nav-items class="col-md-2 p-0" :active="false" @click.native="tab = 'FINANCIAL'" href="#financial" title="Financial"/>
                  <tab-nav-items class="col-md-2 p-0" :active="false" @click.native="tab = 'VENDOR_FILE'" href="#vendor-file" title="Vendor File"/>
                </tab-nav>
              </div>
            </template>
          </iq-card>
        </b-col>
        <b-col lg="12">
          <div class="tab-content">
            <!-- General -->
            <tab-content-item :active="true" id="general">
              <div class="row">
                <b-col sm="4">
                  <fieldset class="form-group border p-3 mb-2">
                    <legend class="w-auto px-2 text-primary">Account Info</legend>
                    <b-row class="mt-2">
                      <b-col md="12">
                        <b-form-group label="* Account Name" label-for="name">
                          <b-form-input
                            id="name"
                            type="text"
                            placeholder="Type text"
                            v-model="formVendor.name"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </fieldset>
                  <fieldset class="form-group border p-3 mb-2">
                    <legend class="w-auto px-2 text-primary">Account Address</legend>
                    <b-row class="mt-2">
                      <b-col md="12">
                        <b-form-group label="* Country" label-for="country_id">
                          <v-select
                            label="text"
                            :options="optionsCountry"
                            :reduce="(type) => type.value"
                            v-model="formVendor.country_id"
                            placeholder="Select country..."
                          ></v-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Province" label-for="province">
                          <b-form-input
                            id="province"
                            type="text"
                            placeholder="Type text"
                            v-model="formVendor.province"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="City" label-for="city">
                          <b-form-input
                            id="city"
                            type="text"
                            placeholder="Type text"
                            v-model="formVendor.city"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="District" label-for="customer_district">
                          <b-form-input
                            id="customer_district"
                            type="text"
                            placeholder="Type text"
                            v-model="formVendor.district"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Village" label-for="village">
                          <b-form-input
                            id="village"
                            type="text"
                            placeholder="Type text"
                            v-model="formVendor.village"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Address" label-for="address">
                          <b-form-textarea
                            id="address"
                            rows="4"
                            placeholder="Type text"
                            v-model="formVendor.address"
                          ></b-form-textarea>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </fieldset>
                  <fieldset class="form-group border p-3 mb-2">
                    <legend class="w-auto px-2 text-primary">Additional Account Info</legend>
                    <b-row class="mt-2">
                      <b-col md="12">
                        <b-form-group label="Logo" label-for="logo">
                          <b-form-file
                            id="file"
                            v-model="formVendor.logo"
                            accept="image/*"
                          ></b-form-file>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Web" label-for="web">
                          <b-form-input
                            id="web"
                            type="text"
                            placeholder="Type text"
                            v-model="formVendor.web"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </fieldset>
                </b-col>
                <b-col sm="4">
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 text-primary">Primary Contact</legend>
                    <b-row class="mt-2">
                      <b-col md="12">
                        <b-form-group label="Fullname" label-for="fullname">
                          <b-form-input
                            id="fullname"
                            type="text"
                            placeholder="Type text"
                            v-model="formVendor.fullname"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Job Title" label-for="pic_job_title">
                          <b-form-input
                            id="pic_job_title"
                            type="text"
                            placeholder="Type text"
                            v-model="formVendor.pic_job_title"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Email" label-for="pic_email">
                          <b-form-input
                            id="pic_email"
                            type="email"
                            placeholder="Type text"
                            v-model="formVendor.pic_email"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Phone" label-for="pic_phone">
                          <b-form-input
                            id="pic_phone"
                            type="text"
                            placeholder="Type text"
                            v-model="formVendor.pic_phone"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </fieldset>
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 text-primary">Vendor Properties</legend>
                    <b-row class="mt-2">
                      <b-col md="12">
                        <b-form-group>
                          <b-form-checkbox
                            name="check-button"
                            switch
                            inline
                            v-model="formVendor.landed_cost_vendor"
                          >
                            Landed Cost Vendor
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group>
                          <b-form-checkbox
                            name="check-button"
                            switch
                            inline
                            v-model="formVendor.vendor_tax_agency"
                          >
                            Vendor is Tax Agency
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </fieldset>
                  <fieldset class="form-group border p-3">
                    <legend class="w-auto px-2 text-primary">Other Info</legend>
                    <b-row class="mt-2">
                      <b-col md="12">
                        <b-form-group label="NPWP Number" label-for="npwp_number">
                          <b-form-input
                            id="npwp_number"
                            type="number"
                            placeholder="Type number"
                            v-model="formVendor.npwp_number"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="SKTD Number" label-for="sktd_number">
                          <b-form-input
                            id="sktd_number"
                            type="number"
                            placeholder="Type number"
                            v-model="formVendor.sktd_number"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="NIB Number" label-for="nib_number">
                          <b-form-input
                            id="nib_number"
                            type="number"
                            placeholder="Type number"
                            v-model="formVendor.nib_number"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Bank" label-for="bank_id">
                          <v-select
                            label="text"
                            :options="optionsBank"
                            :reduce="(type) => type.value"
                            v-model="formVendor.bank_id"
                            placeholder="Select bank..."
                          ></v-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Bank Account Name" label-for="bank_account_name">
                          <b-form-input
                            id="bank_account_name"
                            type="text"
                            placeholder="Type text"
                            v-model="formVendor.bank_account_name"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </fieldset>
                </b-col>
              </div>
            </tab-content-item>
            <!-- End General -->
            <!-- Financial -->
            <tab-content-item :active="false" id="financial">
              <div class="row">
                <b-col sm="6">
                  <fieldset class="form-group border p-3 mb-2">
                    <legend class="w-auto px-2 text-primary">Financial Settings</legend>
                    <b-row class="mt-2">
                      <b-col md="12">
                        <b-form-group label="Terms" label-for="terms_id">
                          <b-form-select
                            id="terms_id"
                            plain
                            size="md"
                            v-model="formVendor.terms_id"
                            :options="optionsTerms"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null">Select terms....</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="12">
                        <b-form-group label="Restict Visibility To" label-for="restrict_visibility_to">
                          <b-form-input
                            id="restrict_visibility_to"
                            type="text"
                            placeholder="Type text"
                            v-model="formVendor.restrict_visibility_to"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group label="Currency ID" label-for="currency_id">
                          <b-form-select
                            id="currency_id"
                            plain
                            size="md"
                            v-model="formVendor.currency_id"
                            :options="optionsCurrency"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null">Select Currency....</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group label="Enable Currency Override">
                          <b-form-checkbox
                            name="check-button"
                            switch
                            inline
                            v-model="formVendor.enable_currency_override"
                          >
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group label="Curr. Rate Type" label-for="currency_rate_type_id">
                          <b-form-select
                            id="currency_rate_type_id"
                            plain
                            size="md"
                            v-model="formVendor.currency_rate_type_id"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null">Select Currency Rate Type....</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group label="Enable Rate Override">
                          <b-form-checkbox
                            name="check-button"
                            switch
                            inline
                            v-model="formVendor.enable_rate_override"
                          >
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </fieldset>
                </b-col>
              </div>
            </tab-content-item>
            <!-- End General -->
            <!-- Vendor File -->
            <tab-content-item :active="false" id="vendor-file">
              <div class="row">
                <b-col sm="12">
                  <fieldset class="form-group border p-3 mb-2">
                    <b-row class="mt-2">
                      <b-col md="12" class="mb-2">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="d-flex align-items-center">
                            <a class="text-primary mr-4" role="button" style="cursor: pointer"  @click="addRowVendor">
                              <i class="fa fa-plus mr-2" />
                              <span>Add Row</span>
                            </a>
                          </div>
                        </div>  
                      </b-col>
                      <b-col md="12">
                        <div class="overflow-auto">
                          <table class="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th class="text-center align-middle" style="width: 30px">#</th>
                                <th class="text-center align-middle" style="width: 20px">No</th>
                                <th class="text-left align-middle" style="min-width: 300px;">Document List</th>
                                <th class="text-left align-middle" style="min-width: 150px;">File</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(state, index) in formVendorFile" :key="index">
                                <td>
                                  <i class="fa fa-trash text-danger" @click="formVendorFile.splice(index, 1)" style="cursor: pointer;" />
                                </td>
                                <td style="width: 20px">{{ index + 1 }}</td>
                                <td>
                                  <b-form-select
                                    id="customer_status"
                                    plain
                                    required
                                    size="md"
                                    v-model="state.document_list_id"
                                    :options="optionsDocumentList"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="null">Select Document...</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                </td>
                                <td>
                                  <b-form-group>
                                    <b-form-file
                                      plain
                                      id="file"
                                      v-model="state.file"
                                      accept="image/*,application/*"
                                      multiple
                                      class="form-control"
                                    ></b-form-file>
                                  </b-form-group>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </b-col>
                    </b-row>
                  </fieldset>
                </b-col>
              </div>
            </tab-content-item>
            <!-- End Vendor File -->
          </div>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>
<script>
  import _ from 'lodash'
  import moment from 'moment'
  import { busdevActions } from '@src/Utils/helper'

  export default {
    name: 'FormVendor',
    props:{
      isEdit: {
        type: Boolean,
        default: false
      },
      editId: {
        type: Number,
        default: null
      },
      refreshData: {
        type: Function,
        default: true
      }, 
      optionsVendorClass: {
        type: Array,
        default: []
      },
      optionsVendorType: {
        type: Array,
        default: []
      },
    },
    data() {
      return {
        loading:false,
        saving:false,

        optionsDocumentList: [],
        optionsTerms: [],
        optionsCurrency: [],
        optionsCountry: [],
        optionsBank: [],

        formVendor:{
          vendor_id:'',
          vendor_class_id:Number(this.optionsVendorClass?.[0]?.value),
          vendor_type_id:Number(this.optionsVendorType?.[0]?.value),
          vendor_status:'active', // 'active', 'on-hold', 'credit-hold','one-time','inactive'
          name:'',
          category:'none', // 'temp','selected','rejected','none'
          logo:null,
          phone:'',
          email_official:'',
          npwp_number:'',
          sktd_number:'',
          nib_number:'',
          bank_id:null,
          bank_account_name:'',
          country_id:'',
          province:'',
          city:'',
          district:'',
          village:'',
          address:'',
          web:'',
          ext_refe_nbr:'',
          parent_id:null,
          fullname:'',
          pic_phone:'',
          pic_job_title:'',
          pic_email:'',
          landed_cost_vendor:null,
          vendor_tax_agency:null,
          terms_id:null,
          currency_id:null,
          currency_rate_type_id:null,
          enable_currency_override:null,
          enable_rate_override:null,
          restrict_visibility_to:null,
        },
        formVendorFile:[]
      }
    },
    components:{},
    watch:{
      'isEdit': {
        handler(value) {
          if(value){
            this.getVendorById()
          } else {
            this.resetFormVendor()
          }
        },
        immediate: true,
      },
      'optionsVendorClass.length':{
        handler(value){
          this.formVendor.vendor_class_id = Number(this.optionsVendorClass?.[0]?.value)
        }
      },
      'optionsVendorType.length':{
        handler(value){
          this.formVendor.vendor_type_id = Number(this.optionsVendorType?.[0]?.value)
        }
      }
    },
    computed: {},
    async mounted() {
      this.getCurrency()
      this.getBank()
    
      this.getTerms()
      this.getDocumentList()
      this.getCountry()
    },
    methods: {
      ...busdevActions,
      addRowVendor (){
        this.formVendorFile.push({
          document_list_id:null,
          file:null,
        })
      },
      resetFormVendor(){
        this.formVendor = {
          vendor_id:'',
          vendor_class_id:Number(this.optionsVendorClass?.[0]?.value),
          vendor_type_id:Number(this.optionsVendorType?.[0]?.value),
          vendor_status:'active', // 'active', 'on-hold', 'credit-hold','one-time','inactive'
          name:'',
          category:'none', // 'temp','selected','rejected','none'
          logo:null,
          phone:'',
          email_official:'',
          npwp_number:'',
          sktd_number:'',
          nib_number:'',
          bank_id:null,
          bank_account_name:'',
          country_id:'',
          province:'',
          city:'',
          district:'',
          village:'',
          address:'',
          web:'',
          ext_refe_nbr:'',
          parent_id:null,
          fullname:'',
          pic_phone:'',
          pic_job_title:'',
          pic_email:'',
          landed_cost_vendor:null,
          vendor_tax_agency:null,
          terms_id:null,
          currency_id:null,
          currency_rate_type_id:null,
          enable_currency_override:null,
          enable_rate_override:null,
          restrict_visibility_to:null,
        }
        this.formVendorFile = []
      },
      async getVendorById(){
        if(this.isEdit && this.editId){
          const res = await this.fetchVendorById({id: this.editId})

          if(res.status === 'success'){
            this.formVendorFile = []
            const data = res.data.data

            if(data?.vendor_file?.length > 0){
              data.vendor_file.map(val => {
                this.formVendorFile.push({
                  document_list_id:val.document_list_id,
                  file:null,
                  url:val.file_location
                })
              })
            }

            this.formVendor = { 
              vendor_id:data.vendor_id,
              vendor_class_id:data.vendor_class_id,
              vendor_type_id:data.vendor_type_id,
              vendor_status:data.vendor_status,
              name:data.name,
              category:data.category,
              logo:null,
              phone:data.logo,
              email_official:data.email_official,
              npwp_number:data.npwp_number,
              sktd_number:data.sktd_number,
              nib_number:data.nib_number,
              bank_id:data.bank_id,
              bank_account_name:data.bank_account,
              country_id:data.country_id,
              province:data.province,
              city:data.city,
              district:data.district,
              village:data.village,
              address:data.address,
              web:data.web,
              ext_refe_nbr:data.ext_refe_nbr,
              parent_id:data.parent_id,
              fullname:data.fullname,
              pic_phone:data.pic_phone,
              pic_job_title:data.pic_job_title,
              pic_email:data.pic_email,
              landed_cost_vendor:data.landed_cost_vendor ? true : false,
              vendor_tax_agency:data.vendor_tax_agency ? true : false,
              terms_id:data.terms_id,
              currency_id:data.currency_id,
              currency_rate_type_id:data?.currency_rate_type_id,
              enable_currency_override:data.enable_currency_override ? true : false,
              enable_rate_override:data.enable_rate_override ? true : false,
              restrict_visibility_to:data.restrict_visibility_to,
            }
          }
        }
      },

      async getCurrency() {
        let res = await this.fetchCurrency()
        if (res.data.status === true) {
          if (res.data.data && res.data.data.length > 0) {
            res.data.data.map((state) => {
              this.optionsCurrency.push({
                value: state.id,
                text: state.alias,
              })
            })
          }
        }
      },  
      async getBank() {
        let res = await this.fetchMasterBank()
        if (res.data.status === true) {
          if (res.data.data && res.data.data.length > 0) {
            res.data.data.map((state) => {
              this.optionsBank.push({
                value: state.id,
                text: `${state.code} - ${state.name}`,
              })
            })
          }
        }
      },  
      async getTerms() {
        let res = await this.fetchTerms()
        if (res.data.status === true) {
          if (res.data.data && res.data.data.length > 0) {
            res.data.data.map((state) => {
              this.optionsTerms.push({
                value: state.id,
                text: state.name,
              })
            })
          }
        }
      },
      async getDocumentList() {
        let res = await this.fetchDocumentList()
        if (res.data.status === true) {
          if (res.data.data && res.data.data.length > 0) {
            res.data.data.map((state) => {
              this.optionsDocumentList.push({
                value: state.id,
                text: state.name,
              })
            })
          }
        }
      },
      async getCountry() {
        let res = await this.fetchCountry()
        if (res.data.status === true) {
          if (res.data.data && res.data.data.length > 0) {
            res.data.data.map((state) => {
              this.optionsCountry.push({
                value: state.code,
                text: `${state.code} - ${state.name}`,
              })
            })
          }
        }
      },

      async onSave(e) {
        e.preventDefault()
        this.saving = true

        let payload = {
          ...this.formVendor,
          vendor:this.formVendorFile
        }

        console.log(payload)

        let formData = new FormData()

        formData.append('vendor_id', payload.vendor_id)
        formData.append('vendor_class_id', Number(payload.vendor_class_id))
        formData.append('vendor_type_id', Number(payload.vendor_type_id))
        formData.append('vendor_status', payload.vendor_status)
        formData.append('name', payload.name)
        formData.append('category', payload.category)
        formData.append('logo', payload.logo)
        formData.append('phone', payload.phone)
        formData.append('email_official', payload.email_official)
        formData.append('npwp_number', payload.npwp_number)
        formData.append('sktd_number', payload.sktd_number)
        formData.append('nib_number', payload.nib_number)
        formData.append('bank_id', Number(payload.bank_id))
        formData.append('bank_account_name', payload.bank_account_name)
        formData.append('country_id', payload.country_id)
        formData.append('province', payload.province)
        formData.append('city', payload.city)
        formData.append('district', payload.district)
        formData.append('village', payload.village)
        formData.append('address', payload.address)
        formData.append('web', payload.web)
        formData.append('ext_refe_nbr', payload.ext_refe_nbr)
        formData.append('parent_id', payload.parent_id)
        formData.append('fullname', payload.fullname)
        formData.append('pic_phone', payload.pic_phone)
        formData.append('pic_job_title', payload.pic_job_title)
        formData.append('pic_email', payload.pic_email)
        formData.append('landed_cost_vendor', payload.landed_cost_vendor ? 1 : 0)
        formData.append('vendor_tax_agency', payload.vendor_tax_agency ? 1 : 0)
        formData.append('terms_id', Number(payload.terms_id))
        formData.append('currency_id', Number(payload.currency_id))
        formData.append('currency_rate_type_id', payload.currency_rate_type_id ? 1 : 0)
        formData.append('enable_currency_override', payload.enable_currency_override ? 1 : 0)
        formData.append('restrict_visibility_to', payload.restrict_visibility_to)

        payload?.vendor?.forEach((item, index) => {
            item?.file?.forEach((file, fileIndex) => {
                formData.append(`vendor_file[${index}]`, file);
            });
   
            formData.append(`vendor[${index}][document_list_id]`, index + 1)
        })

        let res = null
        if(this.isEdit && this.editId){
          res = await this.updateVendor({data: formData, id: this.editId})
        } else {
          res = await this.createVendor({data: formData})
        }

        if([500, 404, 403, 422].includes(res.status)){
          this.saving = false
          this.$swal(`Oops.`, res?.data?.message, 'error')
          return
        }

        if(res?.data?.status === true){
          this.refreshData()
          this.$root.$emit('bv::toggle::collapse', `add-vendor`)
          this.$swal(`Success!`, `Create Vendor Success.`, 'success')
        } else {
          this.$swal(`Oops.`, res?.data?.message, 'error')
        }

        this.saving = false
      }
    }
  }
</script>