<template>
  <b-row class="px-4">
    <b-col md="12">
      <TechnicalExpanseChartBudget />
    </b-col>
    <b-col md="12">
      <table class="table table-striped">
        <thead>
          <th
            :class="i !== 0 ? 'text-right' : 'text-left'"
            v-for="(col, i) in columns"
            :key="i"
            >{{ col }}</th
          >
        </thead>
        <tbody>
          <tr v-for="(item, index) in [1, 2, 3]" :key="index">
            <td class="text-truncate">January</td>
            <td class="text-truncate text-right">Rp. 626.167.256</td>
            <td class="text-truncate text-right">Rp. 254.126.776</td>
            <td class="text-truncate text-right">Rp. 267.076.180</td>
            <td class="text-truncate text-right">Rp. 1.315.816.560</td>
            <td class="text-truncate text-right">Rp. 247.240.000</td>
            <td class="text-truncate text-right">Rp. 461.332.473</td>
            <td class="text-truncate text-right">Rp. 3.171.759.245</td>
          </tr>
        </tbody>
      </table>
    </b-col>
  </b-row>
</template>
<script>
import TechnicalExpanseChartBudget from './Chart/TechnicalExpanseChartBudget.vue'

export default {
  components: {
    TechnicalExpanseChartBudget,
  },
  data() {
    return {
      columns: [
        'Month',
        'Running Store',
        'Maintenance',
        'Repair',
        'Sparepart',
        'Lube Oil',
        'Others/Warehouse',
        'Total',
      ],
    }
  },
}
</script>
<style scoped>
table th {
  background: #012061;
  color: white;
  padding: 4px 12px;
  vertical-align: middle !important;
}
</style>
