<template>
   <div v-if="loading" class="alert alert-info m-4">
      Loading...
    </div>
    <b-row v-else class="m-4">
      <b-col lg="12" v-if="projectForms.length > 0">
        <iq-card body-class="p-0">
         <template v-slot:body>
           <div class="iq-edit-list">
             <tab-nav :pills="true" class="nav-fill mb-4 rounded shadow">
              <div class="d-flex w-100 overflow-auto align-item-center">
                <div v-for="(state, index) in projectForms" :key="index" :class="`col-md-2 p-2 rounded ${activeTab === index ? 'bg-primary': ''}`" style="cursor: pointer;"  @click="changeTab(index)">
                  <div class="d-flex align-items-center justify-content-center w-100">
                    <span class="text-truncate ">{{ state.tabTitle }}</span>
                  </div>
                </div>
              </div>
             </tab-nav>
           </div>
         </template>
       </iq-card>
      </b-col>
      <b-col lg="12">
        <div class="border my-2 w-100"></div>
      </b-col>
      <b-col lg="12"  v-if="projectForms.length > 0">
        <div class="tab-content">
          <div v-for="(form, formIndex) in projectForms" :key="formIndex" class="tab-pane" :class="{ active: activeTab === formIndex }">
            <div class="d-flex my-2 w-100 align-items-center justify-content-end">
              <b-button 
                variant="success" 
                @click="openInspectionChecklistSlide(false, true, form, detailProjectContract.vehicle_id, detailProjectContract.vehicle?.vehicle_type?.name, detailProjectContract?.id)"
              ><i class="fa fa-plus mr-2" /> Add Inspection</b-button>
            </div>
            <div class="overflow-auto d-flex">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr  v-if="form.inspection_checklist.length > 0">
                    <th class="text-center align-middle" style="width: 20px">√</th>
                    <th class="text-center align-middle text-truncate">Fleet</th>
                    <th class="text-center align-middle text-truncate">Field</th>
                    <th class="text-center align-middle text-truncate">Signature</th>
                    <th class="text-center align-middle text-truncate">Status</th>
                    <th class="text-center align-middle" style="width: 20px">#</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="form.inspection_checklist.length > 0">
                    <tr v-for="(state, index) in form.inspection_checklist" :key="index">
                      <td>
                        <b-form-checkbox
                          v-model="state.checked"
                        />
                      </td>
                      <td>
                        <div class="d-flex flex-column">
                          <strong class="text-truncate">{{ state?.vehicle?.name ?? '-' }}</strong>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex flex-column">
                            <div class="d-flex mb-1" v-for="(field, fieldIndex) in state?.field" :key="fieldIndex">
                              <strong class="mr-2 text-truncate">{{ field?.label }}: </strong>
                              <span class="text-truncate">{{ field?.value ? handleTypeDate(field?.value) : '-' }}</span>
                            </div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex flex-column">
                            <div class="d-flex mb-1" v-for="(signature, signatureIndex) in state?.signature" :key="signatureIndex">
                              <strong class="mr-2 text-truncate">{{ signature?.label }}: </strong>
                              <span class="text-truncate">{{ signature?.value ? handleTypeDate(signature?.value) : '-' }}</span>
                            </div>
                        </div>
                      </td>
                      <td>
                        <strong class="text-primary text-capitalize">{{ state?.status }}</strong>
                      </td>
                      <td>
                        <b-dropdown v-b-tooltip.top="'Action'"  variant="link iq-bg-primary" toggle-class="text-decoration-none" no-caret class="float-right mr-1" size="sm">
                          <template v-slot:button-content>
                              <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                              <i class="fa fa-pen m-0"></i>
                              </span>
                          </template>
                          <b-dropdown-item href="#" variant="primary" @click="openInspectionChecklistSlide(true, false, state, detailProjectContract.vehicle_id, detailProjectContract.vehicle?.vehicle_type?.name, detailProjectContract?.id)"><i class="fa fa-eye mr-2" ></i>Preview</b-dropdown-item>
                          <!-- <b-dropdown-item href="#" variant="success" v-else ><i class="fa fa-plus mr-2" ></i>Add Inspection Checklist</b-dropdown-item> -->
                        </b-dropdown>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td colSpan="6" class="text-center">
                        There's no inspection yet.
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>

            <div class="d-flex align-items-center justify-content-end w-100 mt-4"  v-if="form.inspection_checklist.length > 0">
              <b-button
                :disabled="saving"
                block
                variant="primary"
                type="button"
                style="width:20%"
                @click.prevent="onSave"
              >
                <span
                  v-if="saving"
                  class="spinner-border spinner-border-sm"
                />
                <div v-else>
                  <i class="fa fa-save"></i>
                  <span>Submit Data</span>
                </div>
              </b-button>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { projectsActions, companiesActions, fleetsActions, busdevActions, crewingActions } from '@src/Utils/helper'

export default {
  name: 'SlideProjectForms',
  props:{
    formId:{
      type:Number,
      default:0
    },
    openInspectionChecklistSlide:{
      type:Function,
      default:true
    },
    savedProjectForm:{
      type:Function,
      default:true
    }
  },
  data() {
    return {
      saving:false, 

      loading: false,
      activeTab:0,
      detailProjectContract:null,
      projectForms: []
    }
  },
  async mounted () {},
  computed: {},
  watch: {
    formId: {
      deep: true,
      handler: function (value) {
        if (value) {
          this.getDetailProjectContract(value)
        }
      },
    }
  },
  methods:{
    ...projectsActions,
    ...fleetsActions,
    ...companiesActions,
    ...crewingActions,
    ...busdevActions,
    onChangeChecked(value, formIndex, inspectIndex){
      this.projectForms[formIndex].inspection_checklist.map((val, valIndex) => {
        if(valIndex == inspectIndex){
          val.checked = value
        } else {
          val.checked = false
        }
        return val
      })

    },
    changeTab(index) {
      this.activeTab = index;
    },
    handleTypeDate(value){
      if(this.validDate(value)){
        return moment(value).format('DD MMMM YYYY')
      } else if (this.validDateTime(value)){
        return moment(value).format('HH:mm A')
      } else {
        return value
      }
    },
    validDate(val, format = 'YYYY-MM-DD'){
      return moment(val, format, true).isValid();
    },  
    validDateTime(val, format = 'YYYY-MM-DDTHH:mm'){
      return moment(val, format, true).isValid();
    },  
    async getDetailProjectContract(id){
      this.loading = true
      this.projectForms = []

      let res = await this.fetchDetailProjectContract({id})

      if (res.data.status === true) {
        this.detailProjectContract = res.data.data
        console.log(this.detailProjectContract, "detailProjectContract COC")

        let rawData = res.data.data.project_forms
        let combinedData = {};
        rawData.forEach(item => {
          if (!combinedData[item.id]) {
            combinedData[item.id] = {
              ...item,
              inspection_checklist_id: [item.inspection_checklist_id] 
            };
          } else {
            combinedData[item.id].inspection_checklist_id.push(item.inspection_checklist_id);
          }
        });
        let output = Object.values(combinedData);

        let filteredForms = output.map(form => {
          return {
            ...form,
            field:JSON.parse(form.field),
            signature:JSON.parse(form.signature),
            question_type_content:JSON.parse(form.question_type_content),
            tabTitle:`${form.code}/${form.name}`,
            inspection_checklist: form.inspection_checklist.length > 0 ? form.inspection_checklist.map((inspection, inspectionIndex) => {
              let isIspectionIdExist = form.inspection_checklist_id.find(x => x === inspection.id)

              return {
                ...inspection,
                checked: isIspectionIdExist ? true : false,
                field: JSON.parse(inspection.field),
                signature: JSON.parse(inspection.signature),
                question_content: JSON.parse(inspection.question_content),
                question_type_content:JSON.parse(form.question_type_content),
                question_type:form.question_type
              }
            }) : []
          }
        })

        this.projectForms = filteredForms
      } else {
        this.detailProjectContract = null
        this.projectForms = []
      }
      this.loading = false
    },

    async onSave(){
      this.saving = true

      let isDataChecked = false

      let newArr = []
      this.projectForms.map(val => {
        if(val.inspection_checklist?.length > 0){
          val.inspection_checklist.map(obj => {
            if(obj.checked){
              newArr.push({
                inspection_checklist_id: obj.id,
                project_contract_id: Number(this.formId),
                collection_forms_id: obj.collection_of_forms_id,
              })

              isDataChecked = true
            }
          })
        }
      })

      if(!isDataChecked) {
        this.saving = false
        this.$swal(`Required.`, 'Choose a form, before submit', 'warning')
        return
      }
      
      let payload = {
        id: Number(this.formId),
        projectForms: newArr
      }

      const res = await this.setProjectForm({data: payload})

      if(res?.data?.status == true){
        this.saving = false
        this.savedProjectForm()
        this.$swal(`Success!`, `Set Project Form Success.`, 'success')
      } else {
        this.saving = false
        this.$swal(`Oops.`, res?.data?.message, 'error')
      }
      this.saving = false
    }
  }
}
</script>

let data = [
  {
    id:1,
    subId:1,
    goId:2
  },
  {
    id:2,
    subId:1,
    goId:2
  },
  {
    id:3,
    subId:2,
    goId:3
  }
 
]
