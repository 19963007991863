var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-col', {
    staticClass: "mb-3",
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', {
    staticClass: "text-center"
  }, [_c('th', {
    staticClass: "align-middle",
    attrs: {
      "width": "18%",
      "rowspan": "2"
    }
  }, [_vm._v("URAIAN")]), _vm._l(_vm.condite_points, function (point, index) {
    return _c('th', {
      key: index,
      attrs: {
        "width": "15%"
      }
    }, [_vm._v("NILAI Poin = " + _vm._s(point.value))]);
  }), _c('th', {
    staticClass: "align-middle",
    attrs: {
      "width": "12%",
      "rowspan": "2"
    }
  }, [_vm._v("POIN")])], 2), _c('tr', {
    staticClass: "text-center"
  }, _vm._l(_vm.condite_points, function (point, index) {
    return _c('th', {
      key: index
    }, [_vm._v(_vm._s(point.name))]);
  }), 0)]), _c('tbody', _vm._l(_vm.merged_condite, function (state, index) {
    return _c('tr', {
      key: index,
      staticStyle: {
        "font-size": "9pt"
      }
    }, [_c('th', [_vm._v(" " + _vm._s(state.uraian) + " ")]), _vm._l(state.description, function (val, i) {
      return _c('td', {
        key: i
      }, [_vm._v(" " + _vm._s(val.description) + " ")]);
    }), _c('td', {
      staticClass: "text-center"
    }, [!_vm.previewMode ? _c('b-form-select', {
      staticClass: "col-md-12",
      attrs: {
        "plain": "",
        "options": _vm.optionsPoints,
        "size": "sm",
        "required": ""
      },
      scopedSlots: _vm._u([{
        key: "first",
        fn: function fn() {
          return [_c('b-form-select-option', {
            attrs: {
              "value": null
            }
          }, [_vm._v("Nilai")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: state.value,
        callback: function callback($$v) {
          _vm.$set(state, "value", $$v);
        },
        expression: "state.value"
      }
    }) : _c('span', {
      staticClass: "text-muted"
    }, [_vm._v(_vm._s(state.value))])], 1)], 2);
  }), 0)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }