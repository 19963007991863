<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items class="col-md-4 p-0" :active="true" href="#hasil_rakor" title="SHIPMENT PLANNING" />
                <tab-nav-items class="col-md-4 p-0" :active="false" href="#proses_penawaran" title="PROSES PENAWARAN" />
                <tab-nav-items class="col-md-4 p-0" :active="false" href="#realisasi" title="REALISASI" />
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="12">
        <div class="iq-edit-list-data">
          <div id="pills-tabContent-2" class="tab-content">
            <tab-content-item :active="true" id="hasil_rakor" >
              <iq-card>
                <template v-slot:headerTitle>
                    <h5 class="card-title text-primary"><strong>SHIPMENT PLANNING</strong></h5>
                </template>
                <template v-slot:headerAction>
                  <b-dropdown v-b-tooltip.top="'Export Data'"  variant="link iq-bg-primary" toggle-class="text-decoration-none" no-caret class="float-right mr-1" size="sm">
                      <template v-slot:button-content>
                          <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                          <i class="fa fa-save m-0"></i>
                          </span>
                      </template>
                      <b-dropdown-item href="#" variant="dark"><i class="fa fa-file-excel mr-2"></i>Export Excel</b-dropdown-item>
                      <b-dropdown-item href="#" variant="dark"><i class="fa fa-file-pdf mr-2"></i>Export PDF</b-dropdown-item>
                  </b-dropdown>
                  <b-button v-b-tooltip.top="'Refresh Data'" variant="primary" class="float-right mr-1" size="sm"><i class="fa fa-refresh"></i></b-button>
                  <b-button @click="slideAddRakor = !slideAddRakor;"  variant="primary" class="float-right mr-1" size="sm"><i class="fa fa-plus"></i> &nbsp; ADD PLAN</b-button>
                </template>
                <template v-slot:body>
                  <!-- FILTER START -->
                  <b-row>
                      <b-col md="3">
                        <b-form-group label="Period" label-for="Period">
                          <date-picker :lang="lang" v-model="dateRange" type="date" range placeholder="Select date range"></date-picker>
                        </b-form-group>
                      </b-col>
                      <b-col md="2">
                        <b-form-group label="Cargo Type" label-for="cargo">
                          <b-form-select plain>
                            <b-form-select-option>All</b-form-select-option>
                            <b-form-select-option>COAL</b-form-select-option>
                            <b-form-select-option>SPLIT STONE</b-form-select-option>
                            <b-form-select-option>NICKEL</b-form-select-option>
                            <b-form-select-option>CPO</b-form-select-option>
                            <b-form-select-option>ASD</b-form-select-option>
                            <b-form-select-option>OTHERS</b-form-select-option>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="2">
                        <b-form-group label="Ownership" label-for="cargo">
                          <b-form-select plain>
                            <b-form-select-option>All</b-form-select-option>
                            <b-form-select-option>Own Ship</b-form-select-option>
                            <b-form-select-option>Third Party</b-form-select-option>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="2">
                        <b-form-group label="Keyword" label-for="keyword">
                          <b-form-input type="text" id="keyword"></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="2">
                        <b-form-group style label="Search" label-for="button_search">
                          <b-button variant="primary" class="mb-3 mr-1"><i class="fa fa-search"></i></b-button>
                        </b-form-group>
                      </b-col>
                  </b-row>
                  <!-- FILTER END -->

                  <b-row>
                    <b-col md="12">
                      <table class="table table-striped table-sm">
                        <thead>
                          <tr>
                            <th class="text-center">NO</th>
                            <th class="text-center">PROJECT</th>
                            <th class="text-center">TYPE</th>
                            <th class="text-center">JUMLAH SHIPMENT</th>
                            <th class="text-center">REALISASI SHIPMENT</th>
                            <th class="text-center">TOTAL VOLUME</th>
                            <th class="text-center">REALISASI VOLUME</th>
                            <th class="text-center">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th class="text-center">1</th>
                            <td>AI PROJECT - LONG HAULING</td>
                            <th class="text-center"><b-badge class="border border-primary text-primary" variant="none">EXISTING</b-badge></th>
                            <td class="text-center">20 SHIPMENT</td>
                            <td class="text-center">17 SHIPMENT</td>
                            <td class="text-center">150.000 MT</td>
                            <td class="text-center">145.800 MT</td>
                            <td class="text-center">
                              <b-button size="sm" variant="primary" @click="slideDetailPlan = !slideDetailPlan;">
                                <i class="fa fa-eye pr-0"></i> Detail
                              </b-button>
                            </td>
                            <tr>
                            <th class="text-center">2</th>
                            <td>KPC PROJECT - LONG HAULING</td>
                            <th class="text-center"><b-badge class="border border-primary text-primary" variant="none">EXISTING</b-badge></th>
                            <td class="text-center">13 SHIPMENT</td>
                            <td class="text-center">11 SHIPMENT</td>
                            <td class="text-center">122.000 MT</td>
                            <td class="text-center">118.800 MT</td>
                            <td class="text-center">
                              <b-button size="sm" variant="primary" @click="slideDetailPlan = !slideDetailPlan;">
                                <i class="fa fa-eye pr-0"></i> Detail
                              </b-button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="mt-3">
                        <b-pagination :value="1" :total-rows="50" align="center" />
                      </div>
                    </b-col>
                  </b-row>

                  <!-- SLIDE - FORM DETAIL RAKOR -->
                  <b-sidebar
                    v-model="slideDetailRakor"
                    id="detail_rakor"
                    title="TAHUN 2023"
                    width="70%"
                    backdrop-variant="dark"
                    bg-variant="white"
                    right
                    backdrop
                    shadow>
                    <template #default="{hide}">
                      <b-row class="m-3">
                        <b-col cols="12">
                          <table class="table table-striped table-sm">
                            <thead>
                              <tr>
                                <th class="text-center">NO</th>
                                <th class="text-center">POL</th>
                                <th class="text-center">POD</th>
                                <th class="text-center">TERM</th>
                                <th class="text-center">PLAN DATE</th>
                                <th class="text-center">PLAN TRIP</th>
                                <th class="text-center">PLAN QUANTITY</th>
                                <th class="text-center">ACTION</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th class="text-center">1</th>
                                <td class="text-center">Asam-Asam</td>
                                <td class="text-center">LABUAN</td>
                                <td class="text-center">CIF</td>
                                <td class="text-center">
                                  <b-badge variant="success d-block" class="mb-1">18/01/2023</b-badge>
                                  <b-badge variant="success d-block" class="mb-1">20/01/2023</b-badge>
                                  <b-badge variant="success d-block" class="mb-1">25/01/2023</b-badge>
                                </td>
                                <td class="text-center">3</td>
                                <td class="text-center">30.000</td>
                                <td class="text-center">
                                  <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                    <template v-slot:button-content>
                                      <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                        <i class="fa fa-eye m-0 text-primary"></i>
                                      </span>
                                    </template>
                                    <b-dropdown-item href="#" variant="info"><i class="fa fa-pencil-square-o mr-2"></i>EDIT</b-dropdown-item>
                                    <b-dropdown-item href="#" variant="danger"><i class="fa fa-trash mr-2"></i>HAPUS</b-dropdown-item>
                                  </b-dropdown>
                                </td>
                              </tr>
                              <tr>
                                <th class="text-center">2</th>
                                <td class="text-center">Asam-Asam</td>
                                <td class="text-center">LABUAN</td>
                                <td class="text-center">FOB</td>
                                <td class="text-center">
                                  <b-badge variant="success d-block" class="mb-1">18/01/2023</b-badge>
                                  <b-badge variant="success d-block" class="mb-1">23/01/2023</b-badge>
                                  <b-badge variant="success d-block" class="mb-1">27/01/2023</b-badge>
                                </td>
                                <td class="text-center">3</td>
                                <td class="text-center">37.000</td>
                                <td class="text-center">
                                  <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                    <template v-slot:button-content>
                                      <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                        <i class="fa fa-eye m-0 text-primary"></i>
                                      </span>
                                    </template>
                                    <b-dropdown-item href="#" variant="info"><i class="fa fa-pencil-square-o mr-2"></i>EDIT</b-dropdown-item>
                                    <b-dropdown-item href="#" variant="danger"><i class="fa fa-trash mr-2"></i>HAPUS</b-dropdown-item>
                                  </b-dropdown>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="mt-3">
                            <b-pagination :value="1" :total-rows="50" align="center" />
                          </div>
                        </b-col>
                      </b-row>
                    </template>
                  </b-sidebar>
                  <!-- SLIDE - FORM DETAIL RAKOR END -->

                  <!-- SLIDE - FORM ADD PLAN -->
                  <b-sidebar
                    v-model="slideAddRakor"
                    id="add_rakor"
                    title="ADD PLAN"
                    width="100%"
                    backdrop-variant="dark"
                    bg-variant="white"
                    right
                    backdrop
                    shadow>
                    <template #default="{hide}">
                      <b-row class="m-3">
                        <b-col cols="6">
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Project Info</legend>
                            <b-alert :show="true" variant="primary">
                              <template v-for="(item,index) in company">
                                <b-form-radio inline v-model="companyActive[item[Object.keys(item)[0]]]" :name="item.name" :key="index" :value="item.value" :disabled="item.disabled">{{ item.label }}</b-form-radio>
                              </template>
                            </b-alert>
                            <b-form-group label-for="dateIssued" label="Project Filter">
                              <v-multiselect v-model="filterDailyOps.subProject" :options="projectOptions" :multiple="true" group-values="sub" group-label="project" :group-select="true" placeholder="Select one" track-by="name" label="name">
                                <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                              </v-multiselect>
                            </b-form-group>
                          </fieldset>
                        </b-col>
                        <b-col cols="6">
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Company Info</legend>
                            <table class="table mb-0 table-striped">
                              <tbody>
                                <tr>
                                  <th width="20%">Nama Perusahaan</th>
                                  <td width="5%">:</td>
                                  <td width="25%">Arutmin Indonesia</td>
                                  <th width="20%">Alamat</th>
                                  <td width="5%">:</td>
                                  <td width="25%">Jl. H. R. Rasuna Said, RT.2/RW.5</td>
                                </tr>
                                <tr>
                                  <th width="20%">No. Telepon</th>
                                  <td width="5%">:</td>
                                  <td width="25%">021 123 456</td>
                                  <th width="20%">Email</th>
                                  <td width="5%">:</td>
                                  <td width="25%">info@arutmin.com</td>
                                </tr>

                                <tr>
                                  <th width="20%">Negara</th>
                                  <td width="5%">:</td>
                                  <td width="25%">Indonesia</td>
                                  <th width="20%">Provinsi</th>
                                  <td width="5%">:</td>
                                  <td width="25%">DKI Jakarta</td>
                                </tr>

                                <tr>
                                  <th width="20%">Kota</th>
                                  <td width="5%">:</td>
                                  <td width="25%">Jakarta Selatan</td>
                                  <th width="20%">Kecamatan</th>
                                  <td width="5%">:</td>
                                  <td width="25%">Setia Budi</td>
                                </tr>

                                <tr>
                                  <th width="20%">Kelurahan</th>
                                  <td width="5%">:</td>
                                  <td width="25%">Karet Pedurenan</td>
                                  <th width="20%"></th>
                                  <td width="5%"></td>
                                  <td width="25%"></td>
                                </tr>

                                <tr>
                                  <th width="20%">PIC</th>
                                  <td width="5%">:</td>
                                  <td width="25%">Agung Tri</td>
                                  <th width="20%">No. Handphone</th>
                                  <td width="5%">:</td>
                                  <td width="25%">0812613875137</td>
                                </tr>
                              </tbody>
                            </table>
                          </fieldset>
                        </b-col>
                        <b-col cols="12">
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 text-primary">Detail Plan</legend>
                            <table class="table table-striped">
                              <thead>
                                <tr>
                                  <th class="text-center" rowspan="2" style="vertical-align:middle;">NO</th>
                                  <th class="text-center" rowspan="2" style="vertical-align:middle;">DESCRIPTION</th>
                                  <th class="text-center" rowspan="2" style="vertical-align:middle;" width="8%">FLEET</th>
                                  <th class="text-center" rowspan="2" style="vertical-align:middle;">CARGO TYPE</th>
                                  <th class="text-center" rowspan="2" style="vertical-align:middle;">LOCATION</th>
                                  <th class="text-center" rowspan="2" style="vertical-align:middle;">SHIPMENT (MONTH)</th>
                                  <th class="text-center" colspan="2">PLAN DATE</th>
                                  <th class="text-center" rowspan="2" style="vertical-align:middle;">PLAN QUANTITY</th>
                                  <th class="text-center" rowspan="2" style="vertical-align:middle;">REMARKS</th>
                                  <th class="text-center" rowspan="2" style="vertical-align:middle;">ACTION</th>
                                </tr>
                                <tr>
                                  <th class="text-center">ETA POL</th>
                                  <th class="text-center">ETA POD</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <th class="text-center align-top">#1</th>
                                  <td class="text-center align-top">
                                    <b-form-input type="text" id="nama_perusahaan" size="sm" style="border: solid 1px gray;"></b-form-input>
                                  </td>
                                  <td class="text-left align-top">
                                    <b-row>
                                      <b-col md="12">
                                        <template v-for="(item,index) in ownership">
                                          <b-form-radio inline v-model="ownershipActive[item[Object.keys(item)[0]]]" :name="item.name" :key="index" :value="item.value" :disabled="item.disabled">{{ item.label }}</b-form-radio>
                                        </template>
                                      </b-col>
                                      <b-col md="12">
                                        <b-form-input type="text" id="nama_perusahaan" size="sm" style="border: solid 1px gray;" placeholder="input fleet"></b-form-input>
                                      </b-col>
                                    </b-row>
                                  </td>
                                  <td class="text-left align-top">
                                    <b-form-group class="row mb-2" label-cols-md="4" label="Cargo" label-for="descriptionItem">
                                      <b-form-input type="text" id="nama_perusahaan" size="sm" style="border: solid 1px gray;" placeholder="Cargo Type"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="row mb-2" label-cols-md="4" label="Term" label-for="descriptionItem">
                                      <b-form-select plain size="sm" style="border: solid 1px gray;">
                                        <b-form-select-option>SELECT TERM</b-form-select-option>
                                        <b-form-select-option>CIF</b-form-select-option>
                                        <b-form-select-option>FOB</b-form-select-option>
                                      </b-form-select>
                                    </b-form-group>
                                  </td>
                                  <td class="text-left align-top">
                                    <b-form-group class="row mb-2" label-cols-md="4" label="POL" label-for="descriptionItem"> 
                                      <b-form-input type="text" id="nama_perusahaan" size="sm" style="border: solid 1px gray;"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="row mb-2" label-cols-md="4" label="POD" label-for="descriptionItem">
                                      <b-form-input type="text" id="nama_perusahaan" size="sm" style="border: solid 1px gray;"></b-form-input>
                                    </b-form-group>
                                  </td>
                                  <td class="text-center align-top">
                                    <b-form-select plain size="sm" style="border: solid 1px gray;">
                                      <b-form-select-option>SELECT MONTH</b-form-select-option>
                                      <b-form-select-option>JANUARI</b-form-select-option>
                                      <b-form-select-option>FEBRUARI</b-form-select-option>
                                      <b-form-select-option>MARET</b-form-select-option>
                                      <b-form-select-option>APRIL</b-form-select-option>
                                      <b-form-select-option>MEI</b-form-select-option>
                                      <b-form-select-option>JUNI</b-form-select-option>
                                      <b-form-select-option>JULI</b-form-select-option>
                                      <b-form-select-option>AGUSTUS</b-form-select-option>
                                      <b-form-select-option>SEPTEMBER</b-form-select-option>
                                      <b-form-select-option>OKTOBER</b-form-select-option>
                                      <b-form-select-option>NOVEMBER</b-form-select-option>
                                      <b-form-select-option>DESEMBER</b-form-select-option>
                                    </b-form-select>
                                  </td>
                                  <td class="text-center align-top" colspan="2">
                                    <b-row>
                                      <b-col md="6">
                                        <b-form-input type="date" id="plan_date" class="mb-1" size="sm" style="border: solid 1px gray;"></b-form-input>
                                      </b-col>
                                      <b-col md="6">
                                        <b-form-input type="date" id="plan_date" class="mb-1" size="sm" style="border: solid 1px gray;"></b-form-input>
                                      </b-col>
                                    </b-row>
                                  </td>
                                  <td class="text-center align-top">
                                    <b-form-input type="number" id="plan_cargo" size="sm" style="border: solid 1px gray;"></b-form-input>
                                  </td>
                                  <td class="text-center align-top">
                                    <b-form-textarea rows="2" value="Comments if any / Komentar jika ada"></b-form-textarea>
                                  </td>
                                  <td class="text-center align-top">
                                    <b-button size="sm" variant="primary"><i class="fa fa-trash pr-0"></i></b-button>
                                  </td>
                                </tr>
                                <tr>
                                  <th class="text-center align-top">#2</th>
                                  <td class="text-center align-top">
                                    <b-form-input type="text" id="nama_perusahaan" size="sm" style="border: solid 1px gray;"></b-form-input>
                                  </td>
                                  <td class="text-left align-top">
                                    <b-row>
                                      <b-col md="12">
                                        <template v-for="(item,index) in ownership">
                                          <b-form-radio inline v-model="ownershipActive[item[Object.keys(item)[0]]]" :name="item.name" :key="index" :value="item.value" :disabled="item.disabled">{{ item.label }}</b-form-radio>
                                        </template>
                                      </b-col>
                                      <b-col md="12">
                                        <b-form-input type="text" id="nama_perusahaan" size="sm" style="border: solid 1px gray;" placeholder="input fleet"></b-form-input>
                                      </b-col>
                                    </b-row>
                                  </td>
                                  <td class="text-left align-top">
                                    <b-form-group class="row mb-2" label-cols-md="4" label="Cargo" label-for="descriptionItem">
                                      <b-form-input type="text" id="nama_perusahaan" size="sm" style="border: solid 1px gray;" placeholder="Cargo Type"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="row mb-2" label-cols-md="4" label="Term" label-for="descriptionItem">
                                      <b-form-select plain size="sm" style="border: solid 1px gray;">
                                        <b-form-select-option>SELECT TERM</b-form-select-option>
                                        <b-form-select-option>CIF</b-form-select-option>
                                        <b-form-select-option>FOB</b-form-select-option>
                                      </b-form-select>
                                    </b-form-group>
                                  </td>
                                  <td class="text-left align-top">
                                    <b-form-group class="row mb-2" label-cols-md="4" label="POL" label-for="descriptionItem">
                                      <b-form-input type="text" id="nama_perusahaan" size="sm" style="border: solid 1px gray;"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="row mb-2" label-cols-md="4" label="POD" label-for="descriptionItem">
                                      <b-form-input type="text" id="nama_perusahaan" size="sm" style="border: solid 1px gray;"></b-form-input>
                                    </b-form-group>
                                  </td>
                                  <td class="text-center align-top">
                                    <b-form-select plain size="sm" style="border: solid 1px gray;">
                                      <b-form-select-option>SELECT MONTH</b-form-select-option>
                                      <b-form-select-option>JANUARI</b-form-select-option>
                                      <b-form-select-option>FEBRUARI</b-form-select-option>
                                      <b-form-select-option>MARET</b-form-select-option>
                                      <b-form-select-option>APRIL</b-form-select-option>
                                      <b-form-select-option>MEI</b-form-select-option>
                                      <b-form-select-option>JUNI</b-form-select-option>
                                      <b-form-select-option>JULI</b-form-select-option>
                                      <b-form-select-option>AGUSTUS</b-form-select-option>
                                      <b-form-select-option>SEPTEMBER</b-form-select-option>
                                      <b-form-select-option>OKTOBER</b-form-select-option>
                                      <b-form-select-option>NOVEMBER</b-form-select-option>
                                      <b-form-select-option>DESEMBER</b-form-select-option>
                                    </b-form-select>
                                  </td>
                                  <td class="text-center align-top" colspan="2">
                                    <b-row>
                                      <b-col md="6">
                                        <b-form-input type="date" id="plan_date" class="mb-1" size="sm" style="border: solid 1px gray;"></b-form-input>
                                      </b-col>
                                      <b-col md="6">
                                        <b-form-input type="date" id="plan_date" class="mb-1" size="sm" style="border: solid 1px gray;"></b-form-input>
                                      </b-col>
                                    </b-row>
                                  </td>
                                  <td class="text-center align-top">
                                    <b-form-input type="number" id="plan_cargo" size="sm" style="border: solid 1px gray;"></b-form-input>
                                  </td>
                                  <td class="text-center align-top">
                                    <b-form-textarea rows="2" value="Comments if any / Komentar jika ada"></b-form-textarea>
                                  </td>
                                  <td class="text-center align-top">
                                    <b-button size="sm" variant="primary"><i class="fa fa-trash pr-0"></i></b-button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <b-button block variant="primary"><i class="fa fa-plus pr-0"></i> ADD MORE DATA</b-button>
                          </fieldset>
                        </b-col>
                      </b-row>
                    </template>
                  </b-sidebar>
                  <!-- SLIDE - FORM ADD PLAN END -->

                  <!-- SLIDE DETAIL PLAN -->
                  <b-sidebar
                    v-model="slideDetailPlan"
                    id="add_rakor"
                    title="PLAN & REALIZATION"
                    width="100%"
                    backdrop-variant="dark"
                    bg-variant="white"
                    right
                    backdrop
                    shadow>
                    <template #default="{hide}">
                      <b-row class="m-3">
                        <b-col cols="12">
                          <b-row>
                            <b-col md="2">
                              <b-form-group label="Status" label-for="status">
                                <b-form-select plain>
                                  <b-form-select-option>All</b-form-select-option>
                                  <b-form-select-option>Created</b-form-select-option>
                                  <b-form-select-option>Offered</b-form-select-option>
                                  <b-form-select-option>Running</b-form-select-option>
                                </b-form-select>
                              </b-form-group>
                            </b-col>
                            <b-col md="2">
                              <b-form-group label="Port of Discharge" label-for="pod">
                                <b-form-select plain>
                                  <b-form-select-option>All</b-form-select-option>
                                  <b-form-select-option>ASAM ASAM</b-form-select-option>
                                  <b-form-select-option>PAITON</b-form-select-option>
                                  <b-form-select-option>KENDARI</b-form-select-option>
                                </b-form-select>
                              </b-form-group>
                            </b-col>
                            <b-col md="2">
                              <b-form-group style label="Search" label-for="button_search">
                                <b-button variant="primary" class="mb-3 mr-1"><i class="fa fa-search"></i></b-button>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <div class="table-responsive">
                          <table class="table" style="width:5200px;overflow-y:scroll;">
                            <thead>
                              <tr>
                                <th style="position:sticky;left:0px; width:50px" class="text-center table-primary">NO</th>
                                <th style="position:sticky;left:50px; width:200px" class="text-center table-primary">FLEET</th>
                                <th style="width:100px" class="text-center table-primary">MODA</th>
                                <th style="position:sticky;left:250px; width:150px" class="text-center table-primary">CARGO</th>
                                <th style="position:sticky;left:400px; width:200px" class="text-center table-primary">POL</th>
                                <th style="position:sticky;left:600px; width:200px" class="text-center table-primary">POD</th>
                                <th style="width:100px" class="text-center table-primary">ETA POL</th>
                                <th style="width:100px" class="text-center table-primary">ETA POD</th>
                                <th style="width:150px" class="text-center table-primary">PLAN QTY</th>
                                <th style="width:100px" class="text-center table-primary">UNIT RATE</th>
                                <th class="text-center table-primary">REMARKS</th>
                                <th style="position:sticky;left:800px; width:150px" class="text-center table-primary">STATUS</th>
                                <th style="width:250px" class="text-center table-info">SHIPPER</th>
                                <th class="text-center table-info">SHIPMENT</th>
                                <th class="text-center table-info">STATUS</th>
                                <th class="text-center table-info">MODA</th>
                                <th style="position:sticky;left:950px; width:200px" class="text-center table-info">FLEET</th>
                                <th style="width:200px" class="text-center table-info">COMPANY</th>
                                <th style="width:150px" class="text-center table-info">CARGO TYPE</th>
                                <th style="position:sticky;left:1150px; width:200px" class="text-center table-info">POL</th>
                                <th style="width:150px" class="text-center table-info">TA POL/READY</th>
                                <th style="width:150px" class="text-center table-info">BERTHING</th>
                                <th style="width:150px" class="text-center table-info">COMMENCED</th>                          
                                <th class="text-center table-info">COMPLETED</th>
                                <th class="text-center table-info">CARGO LOADING</th>  
                                <th style="position:sticky;left:1350px; width:200px" class="text-center table-info">POD</th>
                                <th class="text-center table-info">TA POD/READY</th>
                                <th class="text-center table-info">BERTHING</th>
                                <th class="text-center table-info">COMMENCED</th>                          
                                <th class="text-center table-info">COMPLETED</th>
                                <th class="text-center table-info">CARGO UNLOADING</th>
                                <th class="text-center table-info">PRICE</th>
                                <th class="text-center table-info">3RD PARTY RATE</th>
                                <th class="text-center table-info">ACTION</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th style="position:sticky;left:0px; width:50px; background-color:white;" class="text-center">#1</th>
                                <th style="position:sticky;left:50px; width:200px; background-color:white;" class="text-center">TB.DHARMA 1 / BG. 3304<br><b-badge variant="primary">THIRD PARTY</b-badge></th>
                                <td class="text-center">300ft</td>
                                <td style="position:sticky;left:250px; width:150px; background-color:white;" class="text-center">COAL</td>
                                <td style="position:sticky;left:400px; width:200px; background-color:white;" class="text-center"><strong>ASAM ASAM</strong></td>
                                <td style="position:sticky;left:600px; width:200px; background-color:white;" class="text-center"><strong>PAITON</strong></td>
                                <td class="text-center">10/01/2023</td>
                                <td class="text-center">16/01/2023</td>
                                <td class="text-center"><strong>7.000</strong></td>
                                <td class="text-center">MT</td>
                                <td class="text-center">-</td>
                                <td style="position:sticky;left:800px; width:150px; background-color:white;" class="text-center"><b-badge variant="primary">CREATED</b-badge></td>
                                <td class="text-center">-</td>
                                <td class="text-center">-</td>
                                <td class="text-center">-</td>
                                <td class="text-center">-</td>
                                <th style="position:sticky;left:950px; width:150px; background-color:white;" class="text-center">-</th>
                                <td class="text-center">-</td>
                                <td class="text-center">-</td>
                                <td style="position:sticky;left:1150px; width:200px; background-color:white;" class="text-center">-</td>
                                <td class="text-center">-</td>
                                <td class="text-center">-</td>
                                <td class="text-center">-</td>
                                <td class="text-center">-</td>
                                <td class="text-center">-</td>
                                <td style="position:sticky;left:1350px; width:200px; background-color:white;" class="text-center">-</td>
                                <td class="text-center">-</td>
                                <td class="text-center">-</td>
                                <td class="text-center">-</td>
                                <td class="text-center">-</td>
                                <td class="text-center">-</td>
                                <td class="text-center">-</td>
                                <td class="text-center">-</td>
                                <td class="text-center">
                                  <b-dropdown size="lg" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                    <template v-slot:button-content>
                                      <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                        <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                      </span>
                                    </template>
                                    <b-dropdown-item href="#" v-b-toggle.add-activity>
                                      <i class="fa fa-info-circle mr-1"></i>Show
                                    </b-dropdown-item>
                                    <b-dropdown-item href="#">
                                      <i class="fa fa-trash mr-1"></i>Delete
                                    </b-dropdown-item>
                                  </b-dropdown>
                                </td>
                              </tr>
                              <tr>
                                <th style="position:sticky;left:0px; width:50px; background-color:white;" class="text-center">#2</th>
                                <th style="position:sticky;left:50px; width:200px; background-color:white;" class="text-center">TB.DHARMA 1 / BG. 3004<br><b-badge variant="primary">THIRD PARTY</b-badge></th>
                                <td class="text-center">300ft</td>
                                <td style="position:sticky;left:250px; width:150px; background-color:white;" class="text-center">COAL</td>
                                <td style="position:sticky;left:400px; width:200px; background-color:white;" class="text-center"><strong>ASAM ASAM</strong></td>
                                <td style="position:sticky;left:600px; width:200px; background-color:white;" class="text-center"><strong>PAITON</strong></td>
                                <td class="text-center">10/01/2023</td>
                                <td class="text-center">16/01/2023</td>
                                <td class="text-center"><strong>7.500</strong></td>
                                <td class="text-center">MT</td>
                                <td class="text-center">-</td>
                                <td style="position:sticky;left:800px; width:150px; background-color:white;" class="text-center"><b-badge variant="light">OFFERED</b-badge></td>
                                <td class="text-center">-</td>
                                <td class="text-center">-</td>
                                <td class="text-center">-</td>
                                <td class="text-center">-</td>
                                <th style="position:sticky;left:950px; width:150px; background-color:white;" class="text-center">-</th>
                                <td class="text-center">-</td>
                                <td class="text-center">-</td>
                                <td style="position:sticky;left:1150px; width:200px; background-color:white;" class="text-center">-</td>
                                <td class="text-center">-</td>
                                <td class="text-center">-</td>
                                <td class="text-center">-</td>
                                <td class="text-center">-</td>
                                <td class="text-center">-</td>
                                <td style="position:sticky;left:1350px; width:200px; background-color:white;" class="text-center">-</td>
                                <td class="text-center">-</td>
                                <td class="text-center">-</td>
                                <td class="text-center">-</td>
                                <td class="text-center">-</td>
                                <td class="text-center">-</td>
                                <td class="text-center">-</td>
                                <td class="text-center">-</td>
                                <td class="text-center">
                                  <b-dropdown size="lg" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                    <template v-slot:button-content>
                                      <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                        <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                      </span>
                                    </template>
                                    <b-dropdown-item href="#" v-b-toggle.add-activity>
                                      <i class="fa fa-info-circle mr-1"></i>Show
                                    </b-dropdown-item>
                                    <b-dropdown-item href="#">
                                      <i class="fa fa-trash mr-1"></i>Delete
                                    </b-dropdown-item>
                                  </b-dropdown>
                                </td>
                              </tr>
                              <tr>
                                <th style="position:sticky;left:0px; width:50px; background-color:white;" class="text-center">#3</th>
                                <th style="position:sticky;left:50px; width:200px; background-color:white;" class="text-center">TB.ETI 102 / BG. 3004<br><b-badge variant="info">OWN SHIP</b-badge></th>
                                <td class="text-center">300ft</td>
                                <td style="position:sticky;left:250px; width:150px; background-color:white;" class="text-center">COAL</td>
                                <td style="position:sticky;left:400px; width:200px; background-color:white;" class="text-center"><strong>ASAM ASAM</strong></td>
                                <td style="position:sticky;left:600px; width:200px; background-color:white;" class="text-center"><strong>PAITON</strong></td>
                                <td class="text-center">10/01/2023</td>
                                <td class="text-center">16/01/2023</td>
                                <td class="text-center"><strong>7.500</strong></td>
                                <td class="text-center">MT</td>
                                <td class="text-center">-</td>
                                <td style="position:sticky;left:800px; width:150px; background-color:white;" class="text-center"><b-badge variant="success">RUNNING</b-badge></td>
                                <td class="text-center">PLN Rembang</td>
                                <td class="text-center">JANUARY</td>
                                <td class="text-center"><b-badge variant="success">Completed</b-badge></td>
                                <td class="text-center">330 ft</td>
                                <th style="position:sticky;left:950px; width:150px; background-color:white;" class="text-center">TB.ETI 102 / BG. 3301<br><b-badge variant="info">OWN SHIP</b-badge></th>
                                <td class="text-center">PT.Transcoal Pacific</td>
                                <td class="text-center">Nickel</td>
                                <td style="position:sticky;left:1150px; width:200px; background-color:white;" class="text-center">MP Asam2</td>
                                <td class="text-center">31-Dec-21 00.00</td>
                                <td class="text-center">02-Jan-22 02.10</td>
                                <td class="text-center">02-Jan-22 03.05</td>
                                <td class="text-center">02-Jan-22 05.05</td>
                                <td class="text-center">7.606 MT</td>
                                <td style="position:sticky;left:1350px; width:200px; background-color:white;" class="text-center">LABUAN</td>
                                <td class="text-center">04-Jan-22 20.00</td>
                                <td class="text-center">04-Jan-22 20.00</td>
                                <td class="text-center">04-Jan-22 20.00</td>
                                <td class="text-center">04-Jan-22 20.00</td>
                                <td class="text-center">7.506 MT</td>
                                <td class="text-center">IDR 198.078</td>
                                <td class="text-center">-</td>
                                <td class="text-center">
                                  <b-dropdown size="lg" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                    <template v-slot:button-content>
                                      <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                        <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                      </span>
                                    </template>
                                    <b-dropdown-item href="#" v-b-toggle.add-activity>
                                      <i class="fa fa-info-circle mr-1"></i>Show
                                    </b-dropdown-item>
                                    <b-dropdown-item href="#">
                                      <i class="fa fa-trash mr-1"></i>Delete
                                    </b-dropdown-item>
                                  </b-dropdown>
                                </td>
                              </tr>
                              <tr>
                                <th style="position:sticky;left:0px; width:50px; background-color:white;" class="text-center">#4</th>
                                <th style="position:sticky;left:50px; width:200px; background-color:white;" class="text-center">TB.ETI 102 / BG. 3004<br><b-badge variant="info">OWN SHIP</b-badge></th>
                                <td class="text-center">300ft</td>
                                <td style="position:sticky;left:250px; width:150px; background-color:white;" class="text-center">COAL</td>
                                <td style="position:sticky;left:400px; width:200px; background-color:white;" class="text-center"><strong>ASAM ASAM</strong></td>
                                <td style="position:sticky;left:600px; width:200px; background-color:white;" class="text-center"><strong>PAITON</strong></td>
                                <td class="text-center">10/01/2023</td>
                                <td class="text-center">16/01/2023</td>
                                <td class="text-center"><strong>7.500</strong></td>
                                <td class="text-center">MT</td>
                                <td class="text-center">-</td>
                                <td style="position:sticky;left:800px; width:150px; background-color:white;" class="text-center"><b-badge variant="success">RUNNING</b-badge></td>
                                <td class="text-center">PLN Rembang</td>
                                <td class="text-center">JANUARY</td>
                                <td class="text-center"><b-badge variant="success">Completed</b-badge></td>
                                <td class="text-center">330 ft</td>
                                <th style="position:sticky;left:950px; width:150px; background-color:white;" class="text-center">TB.ETI 102 / BG. 3301<br><b-badge variant="info">OWN SHIP</b-badge></th>
                                <td class="text-center">PT.Transcoal Pacific</td>
                                <td class="text-center">Nickel</td>
                                <td style="position:sticky;left:1150px; width:200px; background-color:white;" class="text-center">MP Asam2</td>
                                <td class="text-center">31-Dec-21 00.00</td>
                                <td class="text-center">02-Jan-22 02.10</td>
                                <td class="text-center">02-Jan-22 03.05</td>
                                <td class="text-center">02-Jan-22 05.05</td>
                                <td class="text-center">7.606 MT</td>
                                <td style="position:sticky;left:1350px; width:200px; background-color:white;" class="text-center">LABUAN</td>
                                <td class="text-center">04-Jan-22 20.00</td>
                                <td class="text-center">04-Jan-22 20.00</td>
                                <td class="text-center">04-Jan-22 20.00</td>
                                <td class="text-center">04-Jan-22 20.00</td>
                                <td class="text-center">7.506 MT</td>
                                <td class="text-center">IDR 198.078</td>
                                <td class="text-center">-</td>
                                <td class="text-center">
                                  <b-dropdown size="lg" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                    <template v-slot:button-content>
                                      <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                        <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                      </span>
                                    </template>
                                    <b-dropdown-item href="#" v-b-toggle.add-activity>
                                      <i class="fa fa-info-circle mr-1"></i>Show
                                    </b-dropdown-item>
                                    <b-dropdown-item href="#">
                                      <i class="fa fa-trash mr-1"></i>Delete
                                    </b-dropdown-item>
                                  </b-dropdown>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        </b-col>
                      </b-row>
                    </template>
                  </b-sidebar>

                </template>
              </iq-card>
            </tab-content-item>

            <tab-content-item :active="false" id="proses_penawaran">
              <iq-card>
                <template v-slot:headerTitle>
                    <h5 class="card-title text-primary"><strong>PROSES PENAWARAN</strong></h5>
                </template>
                <template v-slot:headerAction>
                  <b-dropdown v-b-tooltip.top="'Export Data'"  variant="link iq-bg-primary" toggle-class="text-decoration-none" no-caret class="float-right mr-1" size="sm">
                      <template v-slot:button-content>
                          <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                          <i class="fa fa-save m-0"></i>
                          </span>
                      </template>
                      <b-dropdown-item href="#" variant="dark"><i class="fa fa-file-excel mr-2"></i>Export Excel</b-dropdown-item>
                      <b-dropdown-item href="#" variant="dark"><i class="fa fa-file-pdf mr-2"></i>Export PDF</b-dropdown-item>
                  </b-dropdown>
                  <b-button v-b-tooltip.top="'Refresh Data'" variant="primary" class="float-right mr-1" size="sm"><i class="fa fa-refresh"></i></b-button>
                  <b-button v-b-tooltip.top="'Add Data'" @click="addPurchaseRequest = !addPurchaseRequest;" variant="primary" class="float-right mr-1" size="sm"><i class="fa fa-plus"></i> Add PFA (Pre Fixture Analysis)</b-button>
                </template>
                <template v-slot:body>
                    <b-row class="mb-4">
                      <b-col md="2">
                        <b-form-input type="text" id="search_name" size="sm" style="border: solid 1px gray;" placeholder="search name"></b-form-input>
                      </b-col>
                      <b-col md="1">
                          <b-button variant="primary" size="sm"><i class="fa fa-search"></i></b-button>
                      </b-col>
                      <b-col md="9">
                          
                      </b-col>
                    </b-row>
                    <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="text-center">PFA ID</th>
                            <th class="text-center">NAME</th>
                            <th class="text-center">FLEET</th>
                            <th class="text-center">DATE</th>
                            <th class="text-center">DESCRIPTION</th>
                            <th class="text-center">QUANTITY</th>
                            <th class="text-center">FREIGHT</th>
                            <th class="text-center">REVENUE</th>
                            <th class="text-center">PROFIT</th>
                            <th class="text-center">PROFIT (%)</th>
                            <th class="text-center">STATUS</th>                          
                            <th class="text-center">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th class="text-center"><a href="#" @click="addPurchaseRequest = !addPurchaseRequest;">SP-2210001</a></th>
                            <td class="text-center">PRE FIXTURE ANALYSIS Jetty CLM Malili - OSS MOROSI</td>
                            <td class="text-center"><strong>Tug Boat :</strong>TB ETI 102 <br/> <strong>Barge : </strong> BG ETI 3008 <br/></td>
                            <td class="text-center">25 Juli 2022 - 26 Juli 2022</td>
                            <td class="text-center"><strong>POL : </strong>Jetty CLM<br/><strong>POD : </strong>OSS Morosi<strong>KIND OF CARGO : </strong>NIKEL</td>
                            <td class="text-center">
                              7.400
                            </td>
                            <td class="text-center">140.000</td>
                            <td class="text-center">1.050.000.000</td>
                            <td class="text-center">1.050.000.000</td>
                            <td class="text-center"><b-badge variant="success" style="font-size:16pt;">15%</b-badge></td>
                            <td class="text-center">
                              <div class="mb-1" style="font-size:14pt;"><b-badge variant="primary">Proposed</b-badge></div>
                              <div class="mb-1" style="font-size:14pt;"><b-badge variant="secondary">Needs Review</b-badge></div>
                              <div class="mb-1" style="font-size:14pt;"><b-badge variant="warning">Needs Approval</b-badge></div>
                              <div class="mb-1" style="font-size:14pt;"><b-badge variant="info">Draft SPAL / Contract</b-badge></div>
                              <div class="mb-1" style="font-size:14pt;"><b-badge variant="success">Realized</b-badge></div>
                            </td>
                            <td class="text-center">
                                <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                    <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="fa fa-list-alt m-0 text-primary"></i>
                                    </span>
                                </template>
                                <b-dropdown-item href="#" variant="dark"><i class="fa fa-edit mr-2"></i>Update</b-dropdown-item>
                                <b-dropdown-item href="#" variant="dark"><i class="fa fa-eye mr-2"></i>Review</b-dropdown-item>
                                <b-dropdown-item href="#" variant="dark"><i class="fa fa-check mr-2"></i>Approved</b-dropdown-item>
                                <b-dropdown-item @click="spalHistory = !spalHistory;" variant="dark"><i class="fa fa-pencil-square-o mr-2"></i>Process SPAL / Contract</b-dropdown-item>
                                <b-dropdown-item href="#" variant="dark"><i class="fa fa-trash mr-2"></i>Delete</b-dropdown-item>
                                </b-dropdown>
                            </td>
                          </tr>
                        </tbody>
                    </table>
                    </div>
                  <div class="mt-3">
                      <b-pagination :value="1" :total-rows="50" align="center" />
                  </div>

                  <b-sidebar
                    v-model="addPurchaseRequest"
                    id="addPurchaseRequest"
                    title="ADD PFA(PRE FIXTURE ANALYSIS)"
                    width="60%"
                    backdrop-variant="dark"
                    bg-variant="white"
                    right
                    backdrop
                    shadow>
                    <template #default="{hide}">
                      <b-row class="m-3">
                        <b-col md="12">
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 h5 text-primary">General Information</legend>
                            <b-row class="pl-3 pr-3">
                              <b-col md="6">
                                <b-form>
                                  <b-form-group class="row" label-cols-md="4" label="Shipment Planning" label-for="inventoryID">
                                    <!-- <b-alert :show="true" variant="primary"> -->
                                      <template v-for="(item,index) in shipmentPlanning">
                                        <b-form-radio inline v-model="shipmentPlanningActive[item[Object.keys(item)[0]]]" :name="item.name" :key="index" :value="item.value" :disabled="item.disabled">{{ item.label }}</b-form-radio>
                                      </template>
                                    <!-- </b-alert> -->
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="PFA ID" label-for="inventoryID">
                                    <b-form-input id="orderNumber" style="height:40px" placeholder="<< CREATE PFA ID >>"></b-form-input>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Name Pfa" label-for="descriptionItem">
                                    <b-form-input type="number" id="descriptionItem" style="height:40px"></b-form-input>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Voyage" label-for="uom">
                                    <b-form-select plain>
                                      <b-form-select-option>1</b-form-select-option>
                                      <b-form-select-option>2</b-form-select-option>
                                      <b-form-select-option>3</b-form-select-option>
                                      <b-form-select-option>4</b-form-select-option>
                                    </b-form-select>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Port of Loading" label-for="descriptionItem">
                                    <b-form-input type="text" id="descriptionItem" style="height:40px"></b-form-input>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Port of Dischare" label-for="descriptionItem">
                                    <b-form-input type="text" id="descriptionItem" style="height:40px"></b-form-input>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Revenue" label-for="location">
                                    <h3><b-badge variant="success">IDR 1.050.000.000</b-badge></h3>
                                  </b-form-group>
                                </b-form>
                              </b-col>

                              <b-col md="6">
                                <b-form>
                                  <b-form-group class="row" label-cols-md="4" label="From Planning" label-for="inventoryID">
                                    <v-multiselect v-model="projectValue" :options="projectOptions" group-values="sub" group-label="project" placeholder="Select one" track-by="name" label="name"></v-multiselect>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Tugboat" label-for="Department">
                                    <b-form-select plain v-model="selectDepartment" :options="optionDepartment" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Select Tugboat</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Barge" label-for="Department">
                                    <b-form-select plain v-model="selectDepartment" :options="optionDepartment" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Select Barge</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Kind of Cargo" label-for="picName">
                                    <b-form-select plain>
                                      <b-form-select-option>Coal</b-form-select-option>
                                      <b-form-select-option>Nickel</b-form-select-option>
                                      <b-form-select-option>CPO</b-form-select-option>
                                      <b-form-select-option>HSD</b-form-select-option>
                                      <b-form-select-option>Split Stone</b-form-select-option>
                                      <b-form-select-option>Others</b-form-select-option>
                                    </b-form-select>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Quantity" label-for="location">
                                    <b-row>
                                      <b-col md="5">
                                        <b-form-select plain>
                                          <b-form-select-option>&#x33A5;</b-form-select-option>
                                          <b-form-select-option>Tonnage</b-form-select-option>
                                          <b-form-select-option>Lump Sum</b-form-select-option>
                                          <b-form-select-option>Liter</b-form-select-option>
                                        </b-form-select>
                                      </b-col>
                                      <b-col md="7">
                                        <b-form-input type="number" class="text-right" id="qty" style="height:40px"></b-form-input>
                                      </b-col>
                                    </b-row>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Freight" label-for="location">
                                    <b-form-input type="number" class="text-right" id="qty" style="height:40px"></b-form-input>
                                  </b-form-group>
                                </b-form>
                              </b-col>
                            </b-row>
                          </fieldset>
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 h5 text-primary">Distace, Speed, Est Time(Hour)</legend>
                            <b-row>
                              <b-col md="12">
                                <div class="table-responsive">
                                  <table class="table table-striped">
                                      <thead>
                                        <tr>
                                          <th class="text-center">Laden</th>
                                          <th class="text-center">Ballast</th>
                                          <th class="text-center">Est Dist (NM)</th>
                                          <th class="text-center">Speed</th>
                                          <th class="text-center">Est Time(Hour)</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>
                                            <b-form-input type="text" class="text-left" id="qty" style="height:40px" value="Jetty CLM"></b-form-input>
                                          </td>
                                          <td>
                                            <b-form-input type="text" class="text-left" id="qty" style="height:40px" value="OSS Morosi"></b-form-input>
                                          </td>
                                          <td>
                                            <b-form-input type="number" class="text-center" id="qty" style="height:40px" value="310"></b-form-input>
                                          </td>
                                          <td>
                                            <b-form-input type="number" class="text-center" id="qty" style="height:40px" value="3.5"></b-form-input>
                                          </td>
                                          <td class="text-center">
                                            <h5><b-badge variant="info">88.57</b-badge></h5>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <b-form-input type="text" class="text-left" id="qty" style="height:40px" value="OSS Morosi"></b-form-input>
                                          </td>
                                          <td>
                                            <b-form-input type="text" class="text-left" id="qty" style="height:40px" value="Jetty CLM"></b-form-input>
                                          </td>
                                          <td>
                                            <b-form-input type="number" class="text-center" id="qty" style="height:40px" value="310"></b-form-input>
                                          </td>
                                          <td>
                                            <b-form-input type="number" class="text-center" id="qty" style="height:40px" value="5.5"></b-form-input>
                                          </td>
                                          <td class="text-center">
                                            <h5><b-badge variant="info">56.36</b-badge></h5>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colspan="5"><b-button variant="info" block><i class="fa fa-plus"></i> Add Data</b-button></td>
                                        </tr>
                                      </tbody>
                                  </table>
                                </div>
                              </b-col>
                              <!-- <b-col md="6">
                                <b-form>
                                  <b-form-group class="row" label-cols-md="4" label="Item Class" label-for="itemClass">
                                    <b-form-select plain v-model="selectItemClass" :options="optionItemClass" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Select Item Class</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Item Type" label-for="itemType">
                                    <b-form-select plain v-model="selectItemType" :options="optionItemType" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Select Item Type</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Valuation Method" label-for="ValuationMethod">
                                    <b-form-select plain v-model="selectValuationMethod" :options="optionValuationMethod" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Select Valuation Method</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                </b-form>
                              </b-col>
                              <b-col md="6">
                                <b-form>
                                  <b-form-group class="row" label-cols-md="4" label="Tax Category" label-for="taxtCategory">
                                    <b-form-select plain v-model="selecttaxtCategory" :options="optiontaxtCategory" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Select Tax Category</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Is a Kit" label-for="isKit">
                                    <template v-for="(item,index) in text">
                                      <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color float-left" :key="index">
                                        <div class="custom-switch-inner">
                                          <p class="mb-0"> {{ item.label }} </p>
                                          <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                          <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel">
                                          </label>
                                        </div>
                                      </div>
                                    </template>
                                  </b-form-group>
                                  <b-form-group class="row" label-cols-md="4" label="Warehouse" label-for="WareHouse">
                                    <b-form-select plain v-model="selectWareHouse" :options="optionWareHouse" size="lg">
                                      <template v-slot:first>
                                      <b-form-select-option :value="null">Select Ware House</b-form-select-option>
                                      </template>
                                    </b-form-select>
                                  </b-form-group>
                                </b-form>
                              </b-col> -->
                            </b-row>
                          </fieldset>
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 h5 text-primary">Cost Factor</legend>
                            <b-row>
                              <b-col md="12">
                                <div class="table-responsive">
                                  <table class="table table-striped">
                                      <thead>
                                        <tr>
                                          <th class="text-center">Item</th>
                                          <th class="text-center">Price</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>Charter Hire / Day</td>
                                          <td>
                                            <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Bunker Cost / litre</td>
                                          <td>
                                            <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>F Water Cost / MT</td>
                                          <td>
                                            <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                          </td>
                                        </tr>
                                      </tbody>
                                  </table>
                                </div>
                            </b-col>
                            </b-row>
                          </fieldset>
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 h5 text-primary">Time Voyage</legend>
                            <b-row>
                              <b-col md="12" class="mb-4">
                                <div class="table-responsive">
                                  <table class="table table-striped">
                                      <thead>
                                        <tr>
                                          <th class="text-center">Distances  steamed (Ballast / Laden)</th>
                                          <th class="text-center">Steaming Time / hours</th>
                                          <th class="text-center">Cons</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>Present position to load port</td>
                                          <td>
                                            <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                          </td>
                                          <td></td>
                                        </tr>
                                        <tr>
                                          <td>Manouver Loading / Discharging</td>
                                          <td>
                                            <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                          </td>
                                          <td></td>
                                        </tr>
                                        <tr>
                                          <td>Waiting Time</td>
                                          <td>
                                            <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                          </td>
                                          <td></td>
                                        </tr>
                                        <tr>
                                          <td>Laden</td>
                                          <td class="text-center">
                                            88,57
                                          </td>
                                          <td class="text-center">11.071,43</td>
                                        </tr>
                                        <tr>
                                          <td>Ballast/MT</td>
                                          <td class="text-center">
                                            56,36	
                                          </td>
                                          <td class="text-center">7.045,45</td>
                                        </tr>
                                        <tr>
                                          <td>Manouver Loading / Discharging</td>
                                          <td class="text-center">
                                          </td>
                                          <td class="text-center">1.000,00</td>
                                        </tr>
                                        <tr>
                                          <td>A/E OB</td>
                                          <td class="text-center">
                                          </td>
                                          <td class="text-center">1.000,00</td>
                                        </tr>
                                      </tbody>
                                      <tfoot>
                                        <tr>
                                          <td class="bg-warning">Total duration(hour/Day) & Consumption</td>
                                          <td class="bg-info">144,94</td>
                                          <td class="bg-info">19.116,88 </td>
                                        </tr>
                                      </tfoot>
                                  </table>
                                </div>
                              </b-col>
                              <b-col md="12" class="mb-4">
                                <div class="table-responsive">
                                  <table class="table table-striped">
                                      <thead>
                                        <tr>
                                          <th class="text-center">Port Stay (load/discharge)</th>
                                          <th class="text-center">Steaming Time / day</th>
                                          <th class="text-center">Idling/waiting</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>Port stay at LP (est)	</td>
                                          <td class="text-center">
                                            4
                                          </td>
                                          <td class="text-center">500,00</td>
                                        </tr>
                                        <tr>
                                          <td>Port Stay at DP (est)</td>
                                          <td class="text-center">
                                            4
                                          </td>
                                          <td class="text-center">500,00</td>
                                        </tr>
                                        <tr>
                                          <td>A/E Barge</td>
                                          <td class="text-center">
                                            0
                                          </td>
                                          <td class="text-center">0,00</td>
                                        </tr>
                                        <tr>
                                          <td>Pump Discharge</td>
                                          <td class="text-center">
                                            0
                                          </td>
                                          <td class="text-center">0,00</td>
                                        </tr>
                                      </tbody>
                                      <tfoot>
                                        <tr>
                                          <td class="bg-warning">Total duration & Total Bunker Consumption</td>
                                          <td class="bg-info">8,00</td>
                                          <td class="bg-info">1.000,00 </td>
                                        </tr>
                                        <tr>
                                          <td class="bg-warning">Grand total duration (day) & consumption	</td>
                                          <td class="bg-info">14,04</td>
                                          <td class="bg-info">20.116,88</td>
                                        </tr>
                                      </tfoot>
                                  </table>
                                </div>
                              </b-col>
                            </b-row>
                          </fieldset>
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 h5 text-primary">Miscellaneous Charge</legend>
                            <b-row>
                              <b-col md="12">
                                <div class="table-responsive">
                                  <table class="table table-striped">
                                      <thead>
                                        <tr>
                                          <th class="text-center">Item</th>
                                          <th class="text-center">Cost</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>Liner Term</td>
                                          <td class="text-center">
                                            -
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Freigt Tax (1,2 %)</td>
                                          <td class="text-center">
                                            Rp. 12.600.000,-
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>C/E/V</td>
                                          <td class="text-center">
                                            -
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Insurance</td>
                                          <td class="text-center">
                                            -
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Premi Kapal</td>
                                          <td class="text-center">
                                            <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Channel Fee</td>
                                          <td class="text-center">
                                            -
                                          </td>
                                        </tr>
                                      </tbody>
                                      <tfoot>
                                        <tr>
                                          <td><strong>TOTAL</strong></td>
                                          <td class="text-center bg-success">Rp28.600.000,00 </td>
                                        </tr>
                                      </tfoot>
                                  </table>
                                </div>
                            </b-col>
                            </b-row>
                          </fieldset>
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 h5 text-primary">Operational Expenses</legend>
                            <b-row>
                              <b-col md="12">
                                <div class="table-responsive">
                                  <table class="table table-striped">
                                      <thead>
                                        <tr>
                                          <th class="text-center">Operation</th>
                                          <th class="text-center">Expense</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>Total charter hire costs</td>
                                          <td class="text-center">
                                            Rp292.478.355
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Bunker costs</td>
                                          <td class="text-center">
                                            Rp412.396.104
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Fresh water cost </td>
                                          <td class="text-center">
                                            Rp412.396.104
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Agency Charge / costs (load port)	</td>
                                          <td class="text-center">
                                            <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Agency Charge / costs (discharge port)</td>
                                          <td class="text-center">
                                            <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Surveyor Loading & Discharging Port	</td>
                                          <td class="text-center">
                                            <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Jasa Ramdoor</td>
                                          <td class="text-center">
                                            <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Assist & Pandu / Buka Tutup Terpal</td>
                                          <td class="text-center">
                                            <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Cleaning Barge</td>
                                          <td class="text-center">
                                            <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>PNI Surveyor</td>
                                          <td class="text-center">
                                            <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Marketing Fee</td>
                                          <td class="text-center">
                                            <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Terpal</td>
                                          <td class="text-center">
                                            <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Others</td>
                                          <td class="text-center">
                                            <b-form-input type="number" class="text-center" id="qty" style="height:40px"></b-form-input>
                                          </td>
                                        </tr>
                                      </tbody>
                                      <tfoot>
                                        <tr>
                                          <td><strong>Total Operational Expenses</strong></td>
                                          <td class="text-center bg-primary"> Rp768.182.251 </td>
                                        </tr>
                                        <tr>
                                          <td><strong>Revenue	</strong></td>
                                          <td class="text-center bg-primary">  Rp1.050.000.000  </td>
                                        </tr>
                                        <tr>
                                          <td><strong>Grand Total Expenses	</strong></td>
                                          <td class="text-center bg-primary">   Rp796.782.251   </td>
                                        </tr>
                                        <tr>
                                          <td><strong>Profit	</strong></td>
                                          <td class="text-center bg-success">    Rp253.217.749   </td>
                                        </tr>
                                        <tr>
                                          <td><strong>Profit	%</strong></td>
                                          <td class="text-center bg-success">    31,78% <small>from Equity ( Grand Total Expenses)</small>   </td>
                                        </tr>
                                        <tr>
                                          <td><strong>Profit Per MT</strong></td>
                                          <td class="text-center bg-success">    33.762,367 </td>
                                        </tr>
                                      </tfoot>
                                  </table>
                                </div>
                            </b-col>
                            </b-row>
                          </fieldset>
                        </b-col>
                        <b-col md="12" class="mt-5 mb-4">
                          <b-button variant="primary" block>Submit</b-button>
                        </b-col>
                      </b-row>
                    </template>
                  </b-sidebar>
                  <!-- SPAL HISTORY -->
                  <b-sidebar
                    v-model="spalHistory"
                    id="spalHistory"
                    title="SPAL / Contract Process"
                    width="60%"
                    backdrop-variant="dark"
                    bg-variant="white"
                    right
                    backdrop
                    shadow>
                    <template #default="{hide}">
                      <b-row class="m-3">
                        <b-col md="12">
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 h5 text-primary">General Information <span><b-button size="sm" variant="primary" v-b-toggle.generalInformation>
                                <i class="fa fa-eye pr-0"></i> Detail
                              </b-button></span>
                            </legend>
                            <b-collapse id="generalInformation">
                              <b-row class="pl-3 pr-3">
                                <b-col md="6">
                                  <b-form>
                                    <b-form-group class="row" label-cols-md="4" label="Shipment Planning" label-for="inventoryID">
                                      <!-- <b-alert :show="true" variant="primary"> -->
                                        <template v-for="(item,index) in shipmentPlanning">
                                          <b-form-radio inline v-model="shipmentPlanningActive[item[Object.keys(item)[0]]]" :name="item.name" :key="index" :value="item.value" :disabled="item.disabled">{{ item.label }}</b-form-radio>
                                        </template>
                                      <!-- </b-alert> -->
                                    </b-form-group>
                                    <b-form-group class="row" label-cols-md="4" label="PFA ID" label-for="inventoryID">
                                      <b-form-input id="orderNumber" style="height:40px" placeholder="<< CREATE PFA ID >>"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="row" label-cols-md="4" label="Name Pfa" label-for="descriptionItem">
                                      <b-form-input type="number" id="descriptionItem" style="height:40px"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="row" label-cols-md="4" label="Voyage" label-for="uom">
                                      <b-form-select plain>
                                        <b-form-select-option>1</b-form-select-option>
                                        <b-form-select-option>2</b-form-select-option>
                                        <b-form-select-option>3</b-form-select-option>
                                        <b-form-select-option>4</b-form-select-option>
                                      </b-form-select>
                                    </b-form-group>
                                    <b-form-group class="row" label-cols-md="4" label="Port of Loading" label-for="descriptionItem">
                                      <b-form-input type="text" id="descriptionItem" style="height:40px"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="row" label-cols-md="4" label="Port of Dischare" label-for="descriptionItem">
                                      <b-form-input type="text" id="descriptionItem" style="height:40px"></b-form-input>
                                    </b-form-group>
                                    <b-form-group class="row" label-cols-md="4" label="Revenue" label-for="location">
                                      <h3><b-badge variant="success">IDR 1.050.000.000</b-badge></h3>
                                    </b-form-group>
                                  </b-form>
                                </b-col>

                                <b-col md="6">
                                  <b-form>
                                    <b-form-group class="row" label-cols-md="4" label="From Planning" label-for="inventoryID">
                                      <v-multiselect v-model="projectValue" :options="projectOptions" group-values="sub" group-label="project" placeholder="Select one" track-by="name" label="name"></v-multiselect>
                                    </b-form-group>
                                    <b-form-group class="row" label-cols-md="4" label="Tugboat" label-for="Department">
                                      <b-form-select plain v-model="selectDepartment" :options="optionDepartment" size="lg">
                                        <template v-slot:first>
                                        <b-form-select-option :value="null">Select Tugboat</b-form-select-option>
                                        </template>
                                      </b-form-select>
                                    </b-form-group>
                                    <b-form-group class="row" label-cols-md="4" label="Barge" label-for="Department">
                                      <b-form-select plain v-model="selectDepartment" :options="optionDepartment" size="lg">
                                        <template v-slot:first>
                                        <b-form-select-option :value="null">Select Barge</b-form-select-option>
                                        </template>
                                      </b-form-select>
                                    </b-form-group>
                                    <b-form-group class="row" label-cols-md="4" label="Kind of Cargo" label-for="picName">
                                      <b-form-select plain>
                                        <b-form-select-option>Coal</b-form-select-option>
                                        <b-form-select-option>Nickel</b-form-select-option>
                                        <b-form-select-option>CPO</b-form-select-option>
                                        <b-form-select-option>ASD</b-form-select-option>
                                        <b-form-select-option>Split Stone</b-form-select-option>
                                        <b-form-select-option>Others</b-form-select-option>
                                      </b-form-select>
                                    </b-form-group>
                                    <b-form-group class="row" label-cols-md="4" label="Quantity" label-for="location">
                                      <b-row>
                                        <b-col md="5">
                                          <b-form-select plain>
                                            <b-form-select-option>M3</b-form-select-option>
                                            <b-form-select-option>Tonnage</b-form-select-option>
                                          </b-form-select>
                                        </b-col>
                                        <b-col md="7">
                                          <b-form-input type="number" class="text-right" id="qty" style="height:40px"></b-form-input>
                                        </b-col>
                                      </b-row>
                                    </b-form-group>
                                    <b-form-group class="row" label-cols-md="4" label="Freight" label-for="location">
                                      <b-form-input type="number" class="text-right" id="qty" style="height:40px"></b-form-input>
                                    </b-form-group>
                                  </b-form>
                                </b-col>

                                <b-col md="12">
                                  <table class="table table-striped">
                                    <tfoot>
                                          <tr>
                                            <td><strong>Total Operational Expenses</strong></td>
                                            <td class="text-center bg-primary"> Rp768.182.251 </td>
                                          </tr>
                                          <tr>
                                            <td><strong>Grand Total Expenses	</strong></td>
                                            <td class="text-center bg-primary">   Rp796.782.251   </td>
                                          </tr>
                                          <tr>
                                            <td><strong>Profit	</strong></td>
                                            <td class="text-center bg-success">    Rp253.217.749   </td>
                                          </tr>
                                          <tr>
                                            <td><strong>Profit	%</strong></td>
                                            <td class="text-center bg-success">    31,78% <small>from Equity ( Grand Total Expenses)</small>   </td>
                                          </tr>
                                        </tfoot>
                                  </table>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </fieldset>
                          <fieldset class="form-group border p-3 bg-default">
                            <legend class="w-auto px-2 h5 text-primary">SPAL / Contract Input</legend>
                            <b-form-row>
                              <b-col>
                                <b-form-group label="Title" label-for="date">
                                  <b-form-input type="text" id="start"></b-form-input>
                                </b-form-group>
                              </b-col>
                              <b-col>
                                <b-form-group label="File Upload" label-for="berita_acara">
                                  <div class="custom-file">
                                    <input type="file" class="custom-file-input" id="validatedCustomFile">
                                    <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                  </div>
                                  <small>If you want to upload multiple file, press "ctrl / command" in your keyboard</small>
                                </b-form-group>
                              </b-col>
                            </b-form-row>
                            <b-form-row>
                              <b-col>
                                <b-form-group label="Notes" label-for="notes">
                                  <b-form-textarea id="description" rows="3" placeholder="Type Text ..."></b-form-textarea>
                                </b-form-group>
                              </b-col>
                            </b-form-row>
                            <b-form-row>
                              <b-col>
                                <b-form-group label="Is Fix SPAL / CONTRACT ?" label-for="internal_man_power">
                                  <template v-for="(item,index) in fix">
                                    <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" :key="index">
                                      <div class="custom-switch-inner">
                                        <p class="mb-0"> {{ item.label }} </p>
                                        <input type="checkbox" class="custom-control-input" :class="'bg-'+item.class" :id="'customSwitch-11'+index" v-model="item.checked">
                                        <label class="custom-control-label" :for="'customSwitch-11'+index" :data-on-label="item.activeLabel" :data-off-label="item.inactiveLabel"></label>
                                      </div>
                                    </div>
                                  </template>
                                </b-form-group>
                              </b-col>
                              <b-col>
                                <b-button type="submit" variant="success" class="pull-right"><i class="fa fa-save"></i> Submit Data</b-button>
                              </b-col>
                            </b-form-row>
                          </fieldset>
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 h5 text-primary">History</legend>
                            <b-row class="pl-3 pr-3">
                              <b-col md="12">
                                <table class="table table-striped">
                                  <thead>
                                    <tr>
                                      <th width="5%">No</th>
                                      <th width="20%">Title</th>
                                      <th width="20%">File</th>
                                      <th>Notes</th>
                                      <th width="15%">Comment</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>1.</td>
                                      <td>PFA</td>
                                      <td>
                                        <a href="javascript:void(0);">
                                          <img src="../../assets/images/activity/pdf.png" alt="gallary-image" height="50px">
                                        </a>
                                      </td>
                                      <td>Generated By System</td>
                                      <td>
                                        <b-button size="sm" variant="primary" v-b-toggle.comment>
                                          <i class="fa fa-comment pr-0"></i> Comment
                                        </b-button>
                                      </td>
                                    </tr>
                                    <!-- Comment Box-->
                                    <tr>
                                      <td colspan="5">
                                        <b-collapse id="comment">
                                        <table class="table mb-0" width="100%">
                                          <thead>
                                            <tr>
                                              <th>Comment</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>
                                                <h6 class="float-left mb-1"><strong>Tri Leksono</strong></h6>
                                                <br />
                                                <small>22 November 2019 10:12</small>
                                                <div class="d-inline-block w-100">
                                                  <p>Revisi dibagian Muatan dong</p>
                                                </div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <div class="chat-footer p-3 bg-white">
                                                  <form class="d-flex align-items-center"  action="javascript:void(0);">
                                                    <div class="chat-attagement d-flex">
                                                      <a href="javascript:void(0)"><i class="fa fa-smile-o pr-3" aria-hidden="true"></i></a>
                                                      <a href="javascript:void(0)"><i class="fa fa-paperclip pr-3" aria-hidden="true"></i></a>
                                                    </div>
                                                    <input type="text" class="form-control mr-3" placeholder="Type your message">
                                                    <button type="submit" class="btn btn-primary d-flex align-items-center p-2"><i class="fas fa-paper-plane" aria-hidden="true"></i><span class="d-none d-lg-block ml-1">Send</span></button>
                                                  </form>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        </b-collapse>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>2.</td>
                                      <td>Shipping Instruction</td>
                                      <td>
                                        <a href="javascript:void(0);">
                                          <img src="../../assets/images/activity/pdf.png" alt="gallary-image" height="50px">
                                        </a>
                                      </td>
                                      <td>-</td>
                                      <td>
                                        <b-button size="sm" variant="primary" v-b-toggle.comment2>
                                          <i class="fa fa-comment pr-0"></i> Comment
                                        </b-button>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="5">
                                        <b-collapse id="comment2">
                                        <table class="table mb-0" width="100%">
                                          <thead>
                                            <tr>
                                              <th>Comment</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>
                                                <h6 class="float-left mb-1"><strong>Tri Leksono</strong></h6>
                                                <br />
                                                <small>22 November 2019 10:12</small>
                                                <div class="d-inline-block w-100">
                                                  <p>Revisi dibagian Muatan dong</p>
                                                </div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <div class="chat-footer p-3 bg-white">
                                                  <form class="d-flex align-items-center"  action="javascript:void(0);">
                                                    <div class="chat-attagement d-flex">
                                                      <a href="javascript:void(0)"><i class="fa fa-smile-o pr-3" aria-hidden="true"></i></a>
                                                      <a href="javascript:void(0)"><i class="fa fa-paperclip pr-3" aria-hidden="true"></i></a>
                                                    </div>
                                                    <input type="text" class="form-control mr-3" placeholder="Type your message">
                                                    <button type="submit" class="btn btn-primary d-flex align-items-center p-2"><i class="fas fa-paper-plane" aria-hidden="true"></i><span class="d-none d-lg-block ml-1">Send</span></button>
                                                  </form>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        </b-collapse>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>3.</td>
                                      <td>Draft SPAL / Contract</td>
                                      <td>
                                        <a href="javascript:void(0);">
                                          <img src="../../assets/images/activity/pdf.png" alt="gallary-image" height="50px">
                                        </a>
                                      </td>
                                      <td>-</td>
                                      <td>
                                        <b-button size="sm" variant="primary" v-b-toggle.comment3>
                                          <i class="fa fa-comment pr-0"></i> Comment
                                        </b-button>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="5">
                                        <b-collapse id="comment3">
                                        <table class="table mb-0" width="100%">
                                          <thead>
                                            <tr>
                                              <th>Comment</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>
                                                <h6 class="float-left mb-1"><strong>Tri Leksono</strong></h6>
                                                <br />
                                                <small>22 November 2019 10:12</small>
                                                <div class="d-inline-block w-100">
                                                  <p>Revisi dibagian Muatan dong</p>
                                                </div>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <div class="chat-footer p-3 bg-white">
                                                  <form class="d-flex align-items-center"  action="javascript:void(0);">
                                                    <div class="chat-attagement d-flex">
                                                      <a href="javascript:void(0)"><i class="fa fa-smile-o pr-3" aria-hidden="true"></i></a>
                                                      <a href="javascript:void(0)"><i class="fa fa-paperclip pr-3" aria-hidden="true"></i></a>
                                                    </div>
                                                    <input type="text" class="form-control mr-3" placeholder="Type your message">
                                                    <button type="submit" class="btn btn-primary d-flex align-items-center p-2"><i class="fas fa-paper-plane" aria-hidden="true"></i><span class="d-none d-lg-block ml-1">Send</span></button>
                                                  </form>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        </b-collapse>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </b-col>
                            </b-row>
                          </fieldset>
                        </b-col>
                      </b-row>
                    </template>
                  </b-sidebar>
                </template>
              </iq-card>
            </tab-content-item>

            <tab-content-item :active="false" id="realisasi">
              <iq-card>
                <template v-slot:headerTitle>
                    <h5 class="card-title text-primary"><strong>REALISASI</strong></h5>
                </template>
                <template v-slot:headerAction>
                  <b-dropdown v-b-tooltip.top="'Export Data'"  variant="link iq-bg-primary" toggle-class="text-decoration-none" no-caret class="float-right mr-1" size="sm">
                      <template v-slot:button-content>
                          <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                          <i class="fa fa-save m-0"></i>
                          </span>
                      </template>
                      <b-dropdown-item href="#" variant="dark"><i class="fa fa-file-excel mr-2"></i>Export Excel</b-dropdown-item>
                      <b-dropdown-item href="#" variant="dark"><i class="fa fa-file-pdf mr-2"></i>Export PDF</b-dropdown-item>
                  </b-dropdown>
                  <b-button v-b-tooltip.top="'Refresh Data'" variant="primary" class="float-right mr-1" size="sm"><i class="fa fa-refresh"></i></b-button>
                  <b-button @click="realisasiData = !realisasiData;"  variant="primary" class="float-right mr-1" size="sm"><i class="fa fa-plus"></i> &nbsp; ADD REALISASI DATA</b-button>
                </template>
                <template v-slot:body>
                  <!-- FILTER START -->
                  <b-row>
                      <b-col md="3">
                        <b-form-group label="Period" label-for="Period">
                          <date-picker :lang="lang" v-model="dateRange" type="date" range placeholder="Select date range"></date-picker>
                        </b-form-group>
                      </b-col>
                      <b-col md="2">
                        <b-form-group label="Cargo Type" label-for="cargo">
                          <b-form-select plain>
                            <b-form-select-option>All</b-form-select-option>
                            <b-form-select-option>COAL</b-form-select-option>
                            <b-form-select-option>SPLIT STONE</b-form-select-option>
                            <b-form-select-option>NICKEL</b-form-select-option>
                            <b-form-select-option>CPO</b-form-select-option>
                            <b-form-select-option>ASD</b-form-select-option>
                            <b-form-select-option>OTHERS</b-form-select-option>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="2">
                        <b-form-group label="Ownership" label-for="cargo">
                          <b-form-select plain>
                            <b-form-select-option>All</b-form-select-option>
                            <b-form-select-option>Own Ship</b-form-select-option>
                            <b-form-select-option>Third Party</b-form-select-option>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="2">
                        <b-form-group label="Keyword" label-for="keyword">
                          <b-form-input type="text" id="keyword"></b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col md="2">
                        <b-form-group style label="Search" label-for="button_search">
                          <b-button variant="primary" class="mb-3 mr-1"><i class="fa fa-search"></i></b-button>
                        </b-form-group>
                      </b-col>
                  </b-row>
                  <!-- FILTER END -->

                  <b-row>
                    <b-col md="12">
                      <div class="table-responsive">
                        <table class="table" style="width:5000px;max-height:750px;overflow-y:scroll;">
                          <thead>
                            <tr>
                              <!-- <th style="position:sticky;left:0px; width:50px" class="text-center table-primary">NO</th>
                              <th style="position:sticky;left:50px; width:150px" class="text-center table-primary">FLEET</th>
                              <th style="position:sticky;left:200px; width:250px" class="text-center table-primary">PROJECT NAME</th>
                              <th style="position:sticky;left:450px; width:150px" class="text-center table-primary">CARGO & TERM</th> -->

                              <th style="position:sticky;left:0px; width:50px" class="text-center table-info">NO</th>
                              <th style="position:sticky;left:50px; width:150px" class="text-center table-info">SHIPPER</th>
                              <th style="position:sticky;left:200px; width:250px" class="text-center table-info">SHIPMENT</th>
                              <th style="position:sticky;left:450px; width:100px" class="text-center table-info">STATUS</th>
                              <th class="text-center table-info">MODA</th>
                              <th style="width:200px" class="text-center table-info">FLEET</th>
                              <th style="width:200px" class="text-center table-info">COMPANY</th>
                              <th style="width:150px" class="text-center table-info">CARGO TYPE</th>
                              <th style="width:150px" class="text-center table-info">POL</th>
                              <th style="width:150px" class="text-center table-info">TA POL/READY</th>
                              <th style="width:150px" class="text-center table-info">BERTHING</th>
                              <th style="width:150px" class="text-center table-info">COMMENCED</th>                          
                              <th class="text-center table-info">COMPLETED</th>
                              <th class="text-center table-info">CARGO LOADING</th>  
                              <th class="text-center table-info">POD</th>
                              <th class="text-center table-info">TA POD/READY</th>
                              <th class="text-center table-info">BERTHING</th>
                              <th class="text-center table-info">COMMENCED</th>                          
                              <th class="text-center table-info">COMPLETED</th>
                              <th class="text-center table-info">CARGO UNLOADING</th>
                              <th class="text-center table-info">PRICE</th>
                              <th class="text-center table-info">PRICE TO VENDOR</th>
                              <th class="text-center table-info">ACTION</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style="position:sticky;left:0px; width:50px; background-color: white;" class="text-center">1</td>
                              <td style="position:sticky;left:50px; width:150px; background-color: white;" class="text-center">PLN Rembang - Longhauling<br><b-badge variant="info">PT.Arutmin Indonesia</b-badge></td>
                              <td style="position:sticky;left:200px; width:250px; background-color: white;" class="text-center">JANUARY</td>
                              <td style="position:sticky;left:450px; width:100px; background-color:white;" class="text-center"><b-badge variant="success">Completed</b-badge></td>
                              <td class="text-center">330 ft</td>
                              <td class="text-center">PT.TCP 201/PB.TCP 3301</td>
                              <td class="text-center">PT.Transcoal Pacific</td>
                              <td class="text-center">Nickel</td>
                              <td class="text-center">MP Asam2</td>
                              <td class="text-center">31-Dec-21 00.00</td>
                              <td class="text-center">02-Jan-22 02.10</td>
                              <td class="text-center">02-Jan-22 03.05</td>
                              <td class="text-center">02-Jan-22 05.05</td>
                              <td class="text-center">7.606 MT</td>
                              <td class="text-center">LABUAN</td>
                              <td class="text-center">04-Jan-22 20.00</td>
                              <td class="text-center">04-Jan-22 20.00</td>
                              <td class="text-center">04-Jan-22 20.00</td>
                              <td class="text-center">04-Jan-22 20.00</td>
                              <td class="text-center">7.506 MT</td>
                              <td class="text-center">IDR 198.078</td>
                              <td class="text-center">-</td>
                              <td class="text-center">
                                <b-dropdown size="lg" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                  <template v-slot:button-content>
                                    <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                      <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                    </span>
                                  </template>
                                  <b-dropdown-item href="#" v-b-toggle.add-activity>
                                    <i class="fa fa-info-circle mr-1"></i>Show
                                  </b-dropdown-item>
                                  <b-dropdown-item href="#">
                                    <i class="fa fa-trash mr-1"></i>Delete
                                  </b-dropdown-item>
                                </b-dropdown>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-col>
                    <b-col md="12">
                      <div class="mt-3">
                          <b-pagination :value="1" :total-rows="50" align="center" />
                      </div>
                    </b-col>
                  </b-row>

                  <!-- REALISASI DATA -->
                  <b-sidebar
                    v-model="realisasiData"
                    id="realisasiData"
                    title="ADD REALISASI DATA"
                    width="90%"
                    backdrop-variant="dark"
                    bg-variant="white"
                    right
                    backdrop
                    shadow>
                    <template #default="{hide}">
                      <b-row class="m-3">
                        <b-col md="12">
                          <fieldset class="form-group border p-3 bg-default">
                            <legend class="w-auto px-2 h5 text-primary">Find Data</legend>
                            <b-form-row>
                              <b-col md="2">
                                <b-form-group label="Realisasi Period" label-for="Period">
                                  <date-picker :lang="lang" v-model="realisasiPeriod" type="month" placeholder="Select Month"></date-picker>
                                </b-form-group>
                              </b-col>
                              <b-col md="3">
                                <b-form-group label-for="dateIssued" label="Project Filter">
                                  <v-multiselect v-model="filterDailyOps.subProject" :options="projectOptions" :multiple="true" group-values="sub" group-label="project" :group-select="true" placeholder="Select one" track-by="name" label="name">
                                    <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                                  </v-multiselect>
                                </b-form-group>
                              </b-col>
                              <b-col md="2">
                                <b-form-group label="Search" label-for="kind-fleet" class="mb-0">
                                  <b-button type="submit" variant="success"><i class="fa fa-search"></i></b-button>
                                </b-form-group>
                              </b-col>
                            </b-form-row>
                          </fieldset>
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 h5 text-primary">Select Shipper Data</legend>
                            <b-row class="pl-3 pr-3">
                              <b-col cols="12">
                                <b-alert :show="true" variant=" " class="text-white bg-warning">
                                  <div class="iq-alert-icon">
                                    <i class="ri-information-fill"></i>
                                  </div>
                                  <div class="iq-alert-text">Not Found Data of Shipper in this period</div>
                                </b-alert>
                              </b-col>
                              <b-col md="12">
                                <div class="table-responsive">
                                  <table class="table table-striped table-sm">
                                    <thead>
                                      <tr class="bg-primary">
                                        <th class="text-center">NO</th>
                                        <th class="text-center">TYPE</th>
                                        <th class="text-center">PROJECT</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr class="iq-bg-primary">
                                        <th class="text-center">1</th>
                                        <th class="text-center"><b-badge class="border border-primary text-primary" variant="none">EXISTING</b-badge></th>
                                        <td class="text-center"><strong>ARUTMIN</strong></td>
                                      </tr>
                                      <tr>
                                        <td colspan="3">
                                          <table class="table mb-0" width="100%">
                                            <thead>
                                              <tr class="bg-info">
                                                <th>#</th>
                                                <th width="17%">Description</th>
                                                <th>Fleet</th>
                                                <th>Cargo & Term</th>
                                                <th>Location</th>
                                                <th class="text-center" width="18%">Shipment Plan</th>
                                                <th class="text-center">Plan Quantity</th>
                                                <th>Remarks</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td><input type="checkbox" name="shipper1" id="shipper1"/></td>
                                                <td>PLN Rembang</td>
                                                <td><b-badge variant="info">Third Party</b-badge><br/> TB.Dharma 1</td>
                                                <td>
                                                  <b-form-group class="row mb-2" label-cols-md="5" label="CARGO TYPE" label-for="descriptionItem">
                                                    <p><strong>COAL</strong></p>
                                                  </b-form-group>
                                                  <b-form-group class="row mb-2" label-cols-md="5" label="TERM" label-for="descriptionItem">
                                                    <p><strong>CIF</strong></p>
                                                  </b-form-group>
                                                </td>
                                                <td>
                                                  <b-form-group class="row mb-2" label-cols-md="4" label="POL" label-for="descriptionItem">
                                                    <p><strong>Paiton</strong></p>
                                                  </b-form-group>
                                                  <b-form-group class="row mb-2" label-cols-md="4" label="POD" label-for="descriptionItem">
                                                    <p><strong>Rembang</strong></p>
                                                  </b-form-group>
                                                </td>
                                                <td>
                                                  <b-row>
                                                    <b-col md="12" class="text-center"><strong>Januari</strong></b-col>
                                                    <b-col md="6" class="text-center">
                                                      <strong>ETA POL</strong>
                                                    </b-col>
                                                    <b-col md="6" class="text-center">
                                                      <strong>ETA POD</strong>
                                                    </b-col>
                                                    <b-col md="6" class="text-center">
                                                      <p>10 Januari 2023</p>
                                                    </b-col>
                                                    <b-col md="6" class="text-center">
                                                      <p>16 Januari 2023</p>
                                                    </b-col>
                                                  </b-row>
                                                </td>
                                                <td class="text-center"><strong>7.000 MT</strong></td>
                                                <td>-</td>
                                              </tr>
                                              <tr>
                                                <td><input type="checkbox" name="shipper2" id="shipper2"/></td>
                                                <td>PLN Rembang</td>
                                                <td><b-badge variant="primary">Own Ship</b-badge><br/> TB.ETI 102</td>
                                                <td>
                                                  <b-form-group class="row mb-2" label-cols-md="5" label="CARGO TYPE" label-for="descriptionItem">
                                                    <p><strong>COAL</strong></p>
                                                  </b-form-group>
                                                  <b-form-group class="row mb-2" label-cols-md="5" label="TERM" label-for="descriptionItem">
                                                    <p><strong>CIF</strong></p>
                                                  </b-form-group>
                                                </td>
                                                <td>
                                                  <b-form-group class="row mb-2" label-cols-md="4" label="POL" label-for="descriptionItem">
                                                    <p><strong>Paiton</strong></p>
                                                  </b-form-group>
                                                  <b-form-group class="row mb-2" label-cols-md="4" label="POD" label-for="descriptionItem">
                                                    <p><strong>Rembang</strong></p>
                                                  </b-form-group>
                                                </td>
                                                <td>
                                                  <b-row>
                                                    <b-col md="12" class="text-center"><strong>Januari</strong></b-col>
                                                    <b-col md="6" class="text-center">
                                                      <strong>ETA POL</strong>
                                                    </b-col>
                                                    <b-col md="6" class="text-center">
                                                      <strong>ETA POD</strong>
                                                    </b-col>
                                                    <b-col md="6" class="text-center">
                                                      <p>19 Januari 2023</p>
                                                    </b-col>
                                                    <b-col md="6" class="text-center">
                                                      <p>23 Januari 2023</p>
                                                    </b-col>
                                                  </b-row>
                                                </td>
                                                <td class="text-center"><strong>7.500 MT</strong></td>
                                                <td>-</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                      <tr class="iq-bg-primary">
                                        <th class="text-center">2</th>
                                        <th class="text-center"><b-badge variant="success" style="font-size:12pt;">New</b-badge></th>
                                        <td class="text-center"><strong style="font-size:12pt;">Pertamina Gas</strong></td>
                                      </tr>
                                      <tr>
                                        <td colspan="3">
                                          <table class="table mb-0" width="100%">
                                            <thead>
                                              <tr class="bg-info">
                                                <th>#</th>
                                                <th width="17%">Description</th>
                                                <th>Fleet</th>
                                                <th>Cargo & Term</th>
                                                <th>Location</th>
                                                <th class="text-center" width="18%">Shipment Plan</th>
                                                <th class="text-center">Plan Quantity</th>
                                                <th>Remarks</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td><input type="checkbox" name="shipper3" id="shipper3"/></td>
                                                <td>PLN Rembang</td>
                                                <td><b-badge variant="info">Third Party</b-badge><br/> TB.Dharma 1</td>
                                                <td>
                                                  <b-form-group class="row mb-2" label-cols-md="5" label="CARGO TYPE" label-for="descriptionItem">
                                                    <p><strong>COAL</strong></p>
                                                  </b-form-group>
                                                  <b-form-group class="row mb-2" label-cols-md="5" label="TERM" label-for="descriptionItem">
                                                    <p><strong>CIF</strong></p>
                                                  </b-form-group>
                                                </td>
                                                <td>
                                                  <b-form-group class="row mb-2" label-cols-md="4" label="POL" label-for="descriptionItem">
                                                    <p><strong>Paiton</strong></p>
                                                  </b-form-group>
                                                  <b-form-group class="row mb-2" label-cols-md="4" label="POD" label-for="descriptionItem">
                                                    <p><strong>Rembang</strong></p>
                                                  </b-form-group>
                                                </td>
                                                <td>
                                                  <b-row>
                                                    <b-col md="12" class="text-center"><strong>Januari</strong></b-col>
                                                    <b-col md="6" class="text-center">
                                                      <strong>ETA POL</strong>
                                                    </b-col>
                                                    <b-col md="6" class="text-center">
                                                      <strong>ETA POD</strong>
                                                    </b-col>
                                                    <b-col md="6" class="text-center">
                                                      <p>10 Januari 2023</p>
                                                    </b-col>
                                                    <b-col md="6" class="text-center">
                                                      <p>16 Januari 2023</p>
                                                    </b-col>
                                                  </b-row>
                                                </td>
                                                <td class="text-center"><strong>29.000 MT</strong></td>
                                                <td>-</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </b-col>
                            </b-row>
                          </fieldset>
                          <fieldset class="form-group border p-3">
                            <legend class="w-auto px-2 h5 text-primary">Select Operational Data</legend>
                            <b-row class="pl-3 pr-3">
                              <b-col cols="12">
                                <b-alert :show="true" variant=" " class="text-white bg-warning">
                                  <div class="iq-alert-icon">
                                    <i class="ri-information-fill"></i>
                                  </div>
                                  <div class="iq-alert-text">Operational Not Yet Input Data For This Period, Please Input First</div>
                                </b-alert>
                              </b-col>
                              <b-col md="12">
                                <div class="table-responsive">
                                  <table class="table table-striped" style="width: 3700px;max-height: 750px;overflow-y: scroll;">
                                    <thead class="thead-dark text-center">
                                      <tr>
                                        <th class="text-center" style="position:sticky;left:0px;background-color:#039BAB;" width="50px">#</th>
                                        <th class="text-center" style="position:sticky;left:45px;background-color:#039BAB;" width="250px">PROJECT</th>
                                        <th class="text-center" style="position:sticky;left:295px;background-color:#039BAB;" width="150px">FLEET</th>
                                        <th class="text-center" style="position:sticky;left:425px;background-color:#039BAB;" width="250px">STATUS</th>
                                        <th class="text-center" width="127px">MODA</th>
                                        <th class="text-center" width="197px">COMPANY</th>
                                        <th class="text-center" width="127px">CARGO TYPE</th>
                                        <th class="text-center" width="197px">POL</th>
                                        <th class="text-center" width="187px">TA POL/READY</th>
                                        <th class="text-center" width="147px">BERTHING</th>
                                        <th class="text-center" width="147px">COMMENCED</th>                          
                                        <th class="text-center" width="147px">COMPLETED</th>
                                        <th class="text-center" width="147px">CARGO LOADING</th>  
                                        <th class="text-center" width="197px">POD</th>
                                        <th class="text-center" width="147px">TA POD/READY</th>
                                        <th class="text-center" width="147px">BERTHING</th>
                                        <th class="text-center" width="147px">COMMENCED</th>                          
                                        <th class="text-center" width="147px">COMPLETED</th>
                                        <th class="text-center" width="147px">CARGO UNLOADING</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td style="position:sticky;left:0px;background-color:#039BAB;color:#ffffff;"><input type="checkbox" name="voyage" id="voyage"/></td>
                                        <td style="position:sticky;left:45px;background-color:#039BAB;color:#ffffff;">AI PROJECT - LONG HAULING</td>
                                        <td style="position:sticky;left:295px;background-color:#039BAB;color:#ffffff;">PT.TCP 201/PB.TCP 3301</td>
                                        <td style="position:sticky;left:425px;background-color:#039BAB;color:#ffffff;"><b-badge variant="success" style="font-size:12pt;">Completed</b-badge></td>
                                        <td>330 ft</td>
                                        <td>PT.Transcoal Pacific</td>
                                        <td>Nickel</td>
                                        <td>MP Asam2</td>
                                        <td>31-Dec-21 00.00</td>
                                        <td>02-Jan-22 02.10</td>
                                        <td>02-Jan-22 03.05</td>
                                        <td>02-Jan-22 05.05</td>
                                        <td>7.606 MT</td>
                                        <td>LABUAN</td>
                                        <td>04-Jan-22 20.00</td>
                                        <td>04-Jan-22 20.00</td>
                                        <td>04-Jan-22 20.00</td>
                                        <td>04-Jan-22 20.00</td>
                                        <td>7.506 MT</td>
                                      </tr>
                                      <tr>
                                        <td style="position:sticky;left:0px;background-color:#039BAB;color:#ffffff;"><input type="checkbox" name="voyage" id="voyage"/></td>
                                        <td style="position:sticky;left:45px;background-color:#039BAB;color:#ffffff;">AI PROJECT - LONG HAULING</td>
                                        <td style="position:sticky;left:295px;background-color:#039BAB;color:#ffffff;">PT.TCP 202/PB.TCP 3302</td>
                                        <td style="position:sticky;left:425px;background-color:#039BAB;color:#ffffff;"><b-badge variant="success" style="font-size:12pt;">Completed</b-badge></td>
                                        <td>330 ft</td>
                                        <td>PT.Transcoal Pacific</td>
                                        <td>Nickel</td>
                                        <td>MP Asam2</td>
                                        <td>31-Dec-21 00.00</td>
                                        <td>02-Jan-22 02.10</td>
                                        <td>02-Jan-22 03.05</td>
                                        <td>02-Jan-22 05.05</td>
                                        <td>7.606 MT</td>
                                        <td>LABUAN</td>
                                        <td>04-Jan-22 20.00</td>
                                        <td>04-Jan-22 20.00</td>
                                        <td>04-Jan-22 20.00</td>
                                        <td>04-Jan-22 20.00</td>
                                        <td>7.506 MT</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </b-col>
                            </b-row>
                            <b-row class="mt-4">
                              <b-col md="12">
                                <b-row>
                                  <b-col>
                                    <b-form-group label-for="dateIssued" label="Company">
                                      <b-form-select
                                        plain
                                        :options="CompanyName"
                                        id="gender"
                                        v-model="companySelected"
                                      >
                                      </b-form-select>
                                    </b-form-group>
                                  </b-col>
                                  <b-col>
                                    <b-form-group label="Price" label-for="cost">
                                      <b-input-group size="md" prepend="Rp">
                                        <b-form-input type="number" class="form-control"></b-form-input>
                                      </b-input-group>
                                    </b-form-group>
                                  </b-col>
                                  <b-col>
                                    <b-form-group label="Price to Vendor" label-for="cost">
                                      <b-input-group size="md" prepend="Rp">
                                        <b-form-input type="number"></b-form-input>
                                      </b-input-group>
                                    </b-form-group>
                                  </b-col>
                                </b-row>
                              </b-col>
                            </b-row>
                          </fieldset>
                        </b-col>
                        <b-col md="12" class="mt-5">
                          <b-button size="lg" variant="success" block>
                            <i class="fa fa-save"></i> Save Data
                          </b-button>
                        </b-col>
                      </b-row>
                    </template>
                  </b-sidebar>
                </template>
              </iq-card>
            </tab-content-item>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import _ from 'lodash'
import moment from 'moment'
import { createPopper } from '@popperjs/core'
import { projectsActions, companiesActions, fleetsActions } from '@src/Utils/helper'

export default {
  name: 'ProfileEdit',
  async mounted () {
    xray.index()
    await this.getProjectList()
    await this.fetchFleets()
    await this.getCompanyListData()
  },
  data () {
    return {
      vehicle: '',
      projectValue: [],
      filterDailyOps: {
        date: moment().format('YYYY-MM-DD'),
        ownership: 'OWNED',
        subProject: [],
        vehicleTypeIds: [1, 2]
      },
      CompanyName: [{ value: null, text: 'Select Company' }],
      companySelected: null,
      optionsVehicles: [],
      crewList: {
        isFiltering: false,
        params: {
          fullname: '',
          vehicle_id: null,
          from: null,
          to: null,
          position_id: null,
          status: null,
        },
        data: {
          head: [],
          body: [],
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0,
        },
      },
      realisasiPeriod: '',
      projectValue: '',
      projectOptions: [
      ],

      pageDetailSalesOrder: false,
      addPurchaseRequest: false,
      spalHistory: false,
      realisasiData: false,

      // fleet
      slideDetailRakor: false,
      slideAddRakor: false,
      slideDetailPlan: false,
      
      // COMPANY
      companyActive: {
        single: 'active',
        disable: 'active',
        number: 'two',
        colorDisabled: 'five',
        color: 'danger'
      },
      company: [
        {
          name: 'single',
          label: 'EXISTING PROJECT',
          value: 'active',
          disabled: false
        },
        {
          name: 'single',
          label: 'NEW PROJECT',
          value: 'inactive',
          disabled: false
        }
      ],
      shipmentPlanningActive: {
        single: 'yes',
        disable: 'yes',
        number: 'two',
        colorDisabled: 'five',
        color: 'danger'
      },
      shipmentPlanning: [
        {
          name: 'single',
          label: 'Yes',
          value: 'yes',
          disabled: false
        },
        {
          name: 'single',
          label: 'No',
          value: 'no',
          disabled: false
        }
      ],

      // NEGARA
      stateActive: {
        single: 'active',
        disable: 'active',
        number: 'two',
        colorDisabled: 'five',
        color: 'danger'
      },
      state: [
        {
          name: 'single',
          label: 'INDONESIA',
          value: 'active',
          disabled: false
        },
        {
          name: 'single',
          label: 'FOREIGN',
          value: 'inactive',
          disabled: false
        }
      ],

      // OWNERSHIP
      ownershipActive: {
        single: 'active',
        disable: 'active',
        number: 'two',
        colorDisabled: 'five',
        color: 'danger'
      },
      ownership: [
        {
          name: 'ownship',
          label: 'OWN SHIP',
          value: 'active',
          disabled: false
        },
        {
          name: 'chartered',
          label: 'CHARTERED',
          value: 'inactive',
          disabled: false
        }
      ],

      // SELECT PROVINSI
      selectedPlanning: 1,
      optionPlanning: [
        { value: '1', text: 'Arutmin == PLN Rembang - Longhauling' },
        { value: '2', text: 'KPC == PLN Rembang' }
      ],
      
      // SELECT PROVINSI
      selectedProvinsi: 1,
      optionProvinsi: [
        { value: '1', text: 'DKI JAKARTA' },
        { value: '2', text: 'JAWA BARAT' },
        { value: '3', text: 'JAWA TENGAH' },
        { value: '4', text: 'JAWA TIMUR' }
      ],

      // SELECT PROVINSI
      selectedKota: 4,
      optionKota: [
        { value: '1', text: 'JAKARTA PUSAT' },
        { value: '2', text: 'JAKARTA UTARA' },
        { value: '3', text: 'JAKARTA TIMUR' },
        { value: '4', text: 'JAKARTA SELATAN' },
        { value: '5', text: 'JAKARTA BARAT' },
        { value: '6', text: 'KEPULAUAN SERIBU' },
      ],

      // SELECT KECAMATAN
      selectedKecamatan: 1,
      optionKecamatan: [
        { value: '1', text: 'KEBAYORAN LAMA' },
        { value: '2', text: 'KEBAYORAN BARU' },
        { value: '3', text: 'GROGOL' },
        { value: '4', text: 'PASAR MINGGU' }
      ],

      // SELECT KELURAHAN
      selectedKelurahan: 1,
      optionKelurahan: [
        { value: '1', text: 'PONDOK PINANG' },
        { value: '2', text: 'PESANGGRAHAN' },
        { value: '3', text: 'PETUKANGAN' }
      ],

      // SELECT TAHUN
      selectedTahun: null,
      optionTahun: [
        { value: '1', text: '2023' },
        { value: '2', text: '2022' },
        { value: '3', text: '2021' },
        { value: '3', text: '2020' }
      ],
      fix: [{
          activeLabel: 'Yes',
          inactiveLabel: 'No',
          class: 'primary',
          checked: false
        }],
    }
  },
  methods: {
    ...projectsActions,
    ...fleetsActions,
    ...companiesActions,
    async getCompanyListData() {
      const res = await this.getCompanies()

      if (res.status == 'success' && res.data && res.data.length > 0) {
        res.data.forEach((element) => {
          this.CompanyName.push({
            // text: `${element.company} - ${element.location}`,
            text: `${element.company}`,
            value: element.id,
          })
        })
      } else {
        this.$swal(`Oops!`, res.data, 'error')
      }
    },
    async getProjectList () {
      let params = {
        active: true,
        search: '',
        page: 1,
        perPage: 250
      }
      const res = await this.getProjects(params)
      if (res.status == 'success') {
        if (res.data.data && res.data.data.length > 0) {
          res.data.data.forEach(val => {
            this.projectOptions.push({
              project: val.projectName,
              sub: val.subProject
            })
          })
        }
      } else {
        this.$swal(
          `Oops!`,
          res.data.data,
          'error'
        )
      }
    },
    async fetchFleets() {
      this.fleetsLoading = true
      this.fleets = []

      let params = this.$options.filters.cleanObject({
        page: 1,
        perPage: 9999,
        showAll: true,
        active: true,
        search: '',
      })
      let res = await this.getFleets(params)

      if (res.status) {
        this.fleetsLoading = false
        if (res.data && res.data.length > 0) {
          this.fleetOptions = res.data.map((val) => ({
            text: val.name,
            value: val.id,
          }))
        }
      }
    },
  }
}
</script>
<style scoped>
/*
*
* ==========================================
* CUSTOM UTIL CLASSES
* ==========================================
*
*/
.table-fixed tbody {
    height: 300px;
    overflow-y: auto;
    width: 100%;
}

.table-fixed thead,
.table-fixed tbody,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
    display: block;
}

.table-fixed tbody td,
.table-fixed tbody th,
.table-fixed thead > tr > th {
    float: left;
    position: relative;

    &::after {
        content: '';
        clear: both;
        display: block;
    }
}
</style>