var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    staticClass: "px-5 py-2",
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v(_vm._s(_vm.action === 'COC' ? 'List Expired COC' : 'List Expired COP'))])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$list, _vm$list$coc_list_exp, _vm$list$coc_list_exp2, _vm$list2, _vm$list2$cop_list_ex, _vm$list2$cop_list_ex2, _vm$list3, _vm$list3$coc_list_ex, _vm$list3$coc_list_ex2, _vm$list4, _vm$list4$cop_list_ex, _vm$list4$cop_list_ex2, _vm$list5, _vm$list5$coc_list_ex, _vm$list5$coc_list_ex2, _vm$list6, _vm$list6$coc_list_ex, _vm$list8, _vm$list8$coc_list_ex, _vm$list8$coc_list_ex2, _vm$list9, _vm$list9$coc_list_ex, _vm$list10, _vm$list10$coc_list_e, _vm$list11, _vm$list11$coc_list_e, _vm$list12, _vm$list12$cop_list_e, _vm$list12$cop_list_e2, _vm$list14, _vm$list14$cop_list_e, _vm$list14$cop_list_e2, _vm$list15, _vm$list15$cop_list_e, _vm$list16, _vm$list16$cop_list_e, _vm$list17, _vm$list17$cop_list_e;
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [((_vm$list = _vm.list) === null || _vm$list === void 0 ? void 0 : (_vm$list$coc_list_exp = _vm$list.coc_list_expired) === null || _vm$list$coc_list_exp === void 0 ? void 0 : (_vm$list$coc_list_exp2 = _vm$list$coc_list_exp.data) === null || _vm$list$coc_list_exp2 === void 0 ? void 0 : _vm$list$coc_list_exp2.length) > 0 || ((_vm$list2 = _vm.list) === null || _vm$list2 === void 0 ? void 0 : (_vm$list2$cop_list_ex = _vm$list2.cop_list_expired) === null || _vm$list2$cop_list_ex === void 0 ? void 0 : (_vm$list2$cop_list_ex2 = _vm$list2$cop_list_ex.data) === null || _vm$list2$cop_list_ex2 === void 0 ? void 0 : _vm$list2$cop_list_ex2.length) > 0 ? _c('b-button', {
          staticClass: "mb-2",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.exportTable();
            }
          }
        }, [_c('div', {
          staticClass: "d-flex gap-2 align-items-center"
        }, [_vm.exporting ? _c('span', {
          staticClass: "spinner-border spinner-border-sm mr-2"
        }) : _c('i', {
          staticClass: "fa fa-file-excel mr-2"
        }), _c('span', [_vm._v("Export")])])]) : _vm._e(), ((_vm$list3 = _vm.list) === null || _vm$list3 === void 0 ? void 0 : (_vm$list3$coc_list_ex = _vm$list3.coc_list_expired) === null || _vm$list3$coc_list_ex === void 0 ? void 0 : (_vm$list3$coc_list_ex2 = _vm$list3$coc_list_ex.data) === null || _vm$list3$coc_list_ex2 === void 0 ? void 0 : _vm$list3$coc_list_ex2.length) > 0 || ((_vm$list4 = _vm.list) === null || _vm$list4 === void 0 ? void 0 : (_vm$list4$cop_list_ex = _vm$list4.cop_list_expired) === null || _vm$list4$cop_list_ex === void 0 ? void 0 : (_vm$list4$cop_list_ex2 = _vm$list4$cop_list_ex.data) === null || _vm$list4$cop_list_ex2 === void 0 ? void 0 : _vm$list4$cop_list_ex2.length) > 0 ? _c('div', {
          staticClass: "d-flex w-100 align-items-center justify-content-between mb-4"
        }, [_c('b-form-input', {
          staticClass: "align-self-end w-50",
          attrs: {
            "placeholder": "Search name/nip/certificate..."
          },
          model: {
            value: _vm.searchQuery,
            callback: function callback($$v) {
              _vm.searchQuery = $$v;
            },
            expression: "searchQuery"
          }
        }), _c('b-button', {
          attrs: {
            "type": "button",
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.onFilter.apply(null, arguments);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1) : _vm._e()], 1), _vm.loading ? _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "alert alert-info m-4 align-items-center"
        }, [_c('span', {
          staticClass: "spinner-border spinner-border-sm mr-2"
        }), _vm._v(" Loading... ")])]) : _vm._e(), ['COC'].includes(_vm.action) && !_vm.loading ? _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [((_vm$list5 = _vm.list) === null || _vm$list5 === void 0 ? void 0 : (_vm$list5$coc_list_ex = _vm$list5.coc_list_expired) === null || _vm$list5$coc_list_ex === void 0 ? void 0 : (_vm$list5$coc_list_ex2 = _vm$list5$coc_list_ex.data) === null || _vm$list5$coc_list_ex2 === void 0 ? void 0 : _vm$list5$coc_list_ex2.length) > 0 ? _c('div', {
          staticClass: "d-flex overflow-auto"
        }, [_c('table', {
          staticClass: "table table-bordered"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("NO")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Name")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v("Certificate")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("No. Certificate")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Tempat & Tanggal diterbitkan")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Tanggal Berakhir")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("File")])])]), _c('tbody', _vm._l((_vm$list6 = _vm.list) === null || _vm$list6 === void 0 ? void 0 : (_vm$list6$coc_list_ex = _vm$list6.coc_list_expired) === null || _vm$list6$coc_list_ex === void 0 ? void 0 : _vm$list6$coc_list_ex.data, function (crew, i) {
          var _vm$list7, _vm$list7$coc_list_ex, _crew$emp_crewing, _crew$emp_crewing2, _crew$emp_crewing$nip, _crew$emp_crewing3, _crew$certificate$nam, _crew$certificate, _crew$document_number;
          return _c('tr', {
            key: 'crew-' + i
          }, [_c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(((_vm$list7 = _vm.list) === null || _vm$list7 === void 0 ? void 0 : (_vm$list7$coc_list_ex = _vm$list7.coc_list_expired) === null || _vm$list7$coc_list_ex === void 0 ? void 0 : _vm$list7$coc_list_ex.from) + i))]), _c('td', [_c('router-link', {
            attrs: {
              "to": {
                path: "profile",
                query: {
                  user: 'crew',
                  id: (_crew$emp_crewing = crew.emp_crewing) === null || _crew$emp_crewing === void 0 ? void 0 : _crew$emp_crewing.id
                }
              }
            }
          }, [_vm._v(_vm._s((_crew$emp_crewing2 = crew.emp_crewing) === null || _crew$emp_crewing2 === void 0 ? void 0 : _crew$emp_crewing2.fullname))]), _c('br'), _c('small', {
            staticStyle: {
              "color": "gray"
            }
          }, [_vm._v("NIP : "), _c('b', [_vm._v(_vm._s((_crew$emp_crewing$nip = crew === null || crew === void 0 ? void 0 : (_crew$emp_crewing3 = crew.emp_crewing) === null || _crew$emp_crewing3 === void 0 ? void 0 : _crew$emp_crewing3.nip) !== null && _crew$emp_crewing$nip !== void 0 ? _crew$emp_crewing$nip : '-'))])])], 1), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s((_crew$certificate$nam = crew === null || crew === void 0 ? void 0 : (_crew$certificate = crew.certificate) === null || _crew$certificate === void 0 ? void 0 : _crew$certificate.name) !== null && _crew$certificate$nam !== void 0 ? _crew$certificate$nam : '-'))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s((_crew$document_number = crew === null || crew === void 0 ? void 0 : crew.document_number) !== null && _crew$document_number !== void 0 ? _crew$document_number : '-') + " ")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s((crew === null || crew === void 0 ? void 0 : crew.place_issued) + ', ' + _vm.moment(crew === null || crew === void 0 ? void 0 : crew.date_issued).format('DD MMMM YYYY')))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(crew !== null && crew !== void 0 && crew.validity_period && (crew === null || crew === void 0 ? void 0 : crew.validity_period) !== 'null' ? _vm.moment(crew.validity_period).format('DD MMMM YYYY') : '-'))]), crew.file_url ? _c('td', {
            staticClass: "text-center",
            staticStyle: {
              "cursor": "pointer"
            },
            on: {
              "click": function click($event) {
                return _vm.handleOpenFilePreview(crew.file_url);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-file-alt text-primary"
          })]) : _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s('-'))])]);
        }), 0)])]) : _vm._e(), ((_vm$list8 = _vm.list) === null || _vm$list8 === void 0 ? void 0 : (_vm$list8$coc_list_ex = _vm$list8.coc_list_expired) === null || _vm$list8$coc_list_ex === void 0 ? void 0 : (_vm$list8$coc_list_ex2 = _vm$list8$coc_list_ex.data) === null || _vm$list8$coc_list_ex2 === void 0 ? void 0 : _vm$list8$coc_list_ex2.length) > 0 ? _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "value": (_vm$list9 = _vm.list) === null || _vm$list9 === void 0 ? void 0 : (_vm$list9$coc_list_ex = _vm$list9.coc_list_expired) === null || _vm$list9$coc_list_ex === void 0 ? void 0 : _vm$list9$coc_list_ex.current_page,
            "total-rows": (_vm$list10 = _vm.list) === null || _vm$list10 === void 0 ? void 0 : (_vm$list10$coc_list_e = _vm$list10.coc_list_expired) === null || _vm$list10$coc_list_e === void 0 ? void 0 : _vm$list10$coc_list_e.total,
            "per-page": (_vm$list11 = _vm.list) === null || _vm$list11 === void 0 ? void 0 : (_vm$list11$coc_list_e = _vm$list11.coc_list_expired) === null || _vm$list11$coc_list_e === void 0 ? void 0 : _vm$list11$coc_list_e.per_page,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getListExpiredCocCop
          }
        })], 1) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("List Expired COC tidak ada.")])]) : _vm._e(), ['COP'].includes(_vm.action) && !_vm.loading ? _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [((_vm$list12 = _vm.list) === null || _vm$list12 === void 0 ? void 0 : (_vm$list12$cop_list_e = _vm$list12.cop_list_expired) === null || _vm$list12$cop_list_e === void 0 ? void 0 : (_vm$list12$cop_list_e2 = _vm$list12$cop_list_e.data) === null || _vm$list12$cop_list_e2 === void 0 ? void 0 : _vm$list12$cop_list_e2.length) > 0 ? _c('div', {
          staticClass: "d-flex overflow-auto"
        }, [_c('table', {
          staticClass: "table table-bordered"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("NO")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Name")]), _c('th', {
          staticClass: "text-left"
        }, [_vm._v("Certificate")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("No. Certificate")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Tempat & Tanggal diterbitkan")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Tanggal Berakhir")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("File")])])]), _c('tbody', _vm._l(_vm.filteredList, function (crew, i) {
          var _vm$list13, _vm$list13$cop_list_e, _crew$emp_crewing4, _crew$emp_crewing5, _crew$emp_crewing$nip2, _crew$emp_crewing6, _crew$certificate$nam2, _crew$certificate2, _crew$document_number2;
          return _c('tr', {
            key: 'crew-' + i
          }, [_c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(((_vm$list13 = _vm.list) === null || _vm$list13 === void 0 ? void 0 : (_vm$list13$cop_list_e = _vm$list13.cop_list_expired) === null || _vm$list13$cop_list_e === void 0 ? void 0 : _vm$list13$cop_list_e.from) + i))]), _c('td', [_c('router-link', {
            attrs: {
              "to": {
                path: "profile",
                query: {
                  user: 'crew',
                  id: (_crew$emp_crewing4 = crew.emp_crewing) === null || _crew$emp_crewing4 === void 0 ? void 0 : _crew$emp_crewing4.id
                }
              }
            }
          }, [_vm._v(_vm._s(crew === null || crew === void 0 ? void 0 : (_crew$emp_crewing5 = crew.emp_crewing) === null || _crew$emp_crewing5 === void 0 ? void 0 : _crew$emp_crewing5.fullname))]), _c('br'), _c('small', {
            staticStyle: {
              "color": "gray"
            }
          }, [_vm._v("NIP : "), _c('b', [_vm._v(_vm._s((_crew$emp_crewing$nip2 = crew === null || crew === void 0 ? void 0 : (_crew$emp_crewing6 = crew.emp_crewing) === null || _crew$emp_crewing6 === void 0 ? void 0 : _crew$emp_crewing6.nip) !== null && _crew$emp_crewing$nip2 !== void 0 ? _crew$emp_crewing$nip2 : '-'))])])], 1), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s((_crew$certificate$nam2 = crew === null || crew === void 0 ? void 0 : (_crew$certificate2 = crew.certificate) === null || _crew$certificate2 === void 0 ? void 0 : _crew$certificate2.name) !== null && _crew$certificate$nam2 !== void 0 ? _crew$certificate$nam2 : '-'))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s((_crew$document_number2 = crew === null || crew === void 0 ? void 0 : crew.document_number) !== null && _crew$document_number2 !== void 0 ? _crew$document_number2 : '-') + " ")]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s((crew === null || crew === void 0 ? void 0 : crew.place_issued) + ', ' + _vm.moment(crew === null || crew === void 0 ? void 0 : crew.date_issued).format('DD MMMM YYYY')))]), _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(crew !== null && crew !== void 0 && crew.validity_period && (crew === null || crew === void 0 ? void 0 : crew.validity_period) !== 'null' ? _vm.moment(crew.validity_period).format('DD MMMM YYYY') : '-'))]), crew.file_url ? _c('td', {
            staticClass: "text-center",
            staticStyle: {
              "cursor": "pointer"
            },
            on: {
              "click": function click($event) {
                return _vm.handleOpenFilePreview(crew.file_url);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-file-alt text-primary"
          })]) : _c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s('-'))])]);
        }), 0)])]) : _vm._e(), ((_vm$list14 = _vm.list) === null || _vm$list14 === void 0 ? void 0 : (_vm$list14$cop_list_e = _vm$list14.cop_list_expired) === null || _vm$list14$cop_list_e === void 0 ? void 0 : (_vm$list14$cop_list_e2 = _vm$list14$cop_list_e.data) === null || _vm$list14$cop_list_e2 === void 0 ? void 0 : _vm$list14$cop_list_e2.length) > 0 || !_vm.filtering ? _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "value": (_vm$list15 = _vm.list) === null || _vm$list15 === void 0 ? void 0 : (_vm$list15$cop_list_e = _vm$list15.cop_list_expired) === null || _vm$list15$cop_list_e === void 0 ? void 0 : _vm$list15$cop_list_e.current_page,
            "total-rows": (_vm$list16 = _vm.list) === null || _vm$list16 === void 0 ? void 0 : (_vm$list16$cop_list_e = _vm$list16.cop_list_expired) === null || _vm$list16$cop_list_e === void 0 ? void 0 : _vm$list16$cop_list_e.total,
            "per-page": (_vm$list17 = _vm.list) === null || _vm$list17 === void 0 ? void 0 : (_vm$list17$cop_list_e = _vm$list17.cop_list_expired) === null || _vm$list17$cop_list_e === void 0 ? void 0 : _vm$list17$cop_list_e.per_page,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getListExpiredCocCop
          }
        })], 1) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("List COP tidak ada.")])]) : _vm._e()], 1)];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }