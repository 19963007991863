var render = function render(){
  var _vm$items, _vm$meta, _vm$meta2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    attrs: {
      "action": "#"
    },
    on: {
      "submit": _vm.onSave
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end w-100"
  }, [_c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "danger",
      "type": "button"
    },
    on: {
      "click": _vm.closeSlide
    }
  }, [_c('div', [_c('i', {
    staticClass: "fa fa-ban"
  }), _c('span', [_vm._v("Cancel")])])]), _c('b-button', {
    attrs: {
      "disabled": _vm.saving,
      "variant": "primary",
      "type": "submit"
    }
  }, [_vm.saving ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _c('div', [_c('i', {
    staticClass: "fa fa-save"
  }), _c('span', [_vm._v("Submit Data")])])])], 1), _c('hr')]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [['TRANSFER'].includes(_vm.type) ? _c('b-row', [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "*Date",
      "label-for": "date"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "date",
      "type": "date",
      "required": ""
    },
    model: {
      value: _vm.form.date,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "date", $$v);
      },
      expression: "form.date"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "*From Vehicle",
      "label-for": "from_vehicle_id"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "name",
      "options": _vm.optionsFleets,
      "reduce": function reduce(type) {
        return type.id;
      },
      "placeholder": "Select...",
      "disabled": ""
    },
    model: {
      value: _vm.form.from_vehicle_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "from_vehicle_id", $$v);
      },
      expression: "form.from_vehicle_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "*To Vehicle",
      "label-for": "to_vehicle_id"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "name",
      "options": _vm.optionsFleets,
      "reduce": function reduce(type) {
        return type.id;
      },
      "placeholder": "Select..."
    },
    model: {
      value: _vm.form.to_vehicle_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "to_vehicle_id", $$v);
      },
      expression: "form.to_vehicle_id"
    }
  })], 1)], 1)], 1) : _vm._e(), ['ADJUSTMENT'].includes(_vm.type) ? _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "*Vehicle",
      "label-for": "to_vehicle_id"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "name",
      "options": _vm.optionsFleets,
      "reduce": function reduce(type) {
        return type.id;
      },
      "placeholder": "Select...",
      "disabled": ""
    },
    model: {
      value: _vm.form.to_vehicle_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "to_vehicle_id", $$v);
      },
      expression: "form.to_vehicle_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "*Date",
      "label-for": "date"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "date",
      "type": "date"
    },
    model: {
      value: _vm.form.date,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "date", $$v);
      },
      expression: "form.date"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Proof Number",
      "label-for": "proof_number"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Type Text",
      "id": "text",
      "type": "text"
    },
    model: {
      value: _vm.form.proof_number,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "proof_number", $$v);
      },
      expression: "form.proof_number"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [['ADJUSTMENT'].includes(_vm.type) ? _c('b-form-group', {
    attrs: {
      "label": "Type",
      "label-for": "type"
    }
  }, [_c('b-form-select', {
    attrs: {
      "required": "",
      "id": "type",
      "plain": "",
      "size": "md"
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": ''
          }
        }, [_vm._v("Select...")])];
      },
      proxy: true
    }], null, false, 4249437539),
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }, [_c('b-form-select-option', {
    attrs: {
      "value": 'stock_in'
    }
  }, [_vm._v("Stock In")]), _c('b-form-select-option', {
    attrs: {
      "value": 'stock_out'
    }
  }, [_vm._v("Stock Out")])], 1)], 1) : _vm._e()], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between w-100 mb-2"
  }, [_c('label', [_c('strong', [_vm._v("*Select Products")])]), _c('div', {
    staticClass: "d-flex align-items-center w-auto"
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Search product..."
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _c('i', {
    staticClass: "fa fa-search ml-2 text-primary text-lg"
  })], 1)]), _c('div', {
    staticClass: "d-flex overflow-auto",
    staticStyle: {
      "max-height": "330px"
    }
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', {
    staticClass: "sticky-top bg-primary"
  }, [_c('tr', [_c('th', {
    staticClass: "text-center",
    staticStyle: {
      "width": "5px"
    }
  }, [_vm._v("#")]), _c('th', {
    staticClass: "text-truncate",
    staticStyle: {
      "width": "220px"
    }
  }, [_vm._v("Product Code")]), _c('th', {
    staticStyle: {
      "min-width": "250px"
    }
  }, [_vm._v("Product")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Category")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("Spesifikasi")])])]), _c('tbody', _vm._l(_vm.items, function (val, valIndex) {
    var _val$category, _val$spesification;
    return _c('tr', {
      key: valIndex
    }, [_c('td', {
      staticClass: "text-center"
    }, [_c('b-form-checkbox', {
      staticClass: "ml-2",
      attrs: {
        "value": true,
        "unchecked-value": false
      },
      on: {
        "change": function change($event) {
          return _vm.onHandleCheckedProduct(val);
        }
      },
      model: {
        value: val.checked,
        callback: function callback($$v) {
          _vm.$set(val, "checked", $$v);
        },
        expression: "val.checked"
      }
    })], 1), _c('td', {
      staticStyle: {
        "width": "220px"
      }
    }, [_vm._v(_vm._s(val === null || val === void 0 ? void 0 : val.code))]), _c('td', [_vm._v(_vm._s(val === null || val === void 0 ? void 0 : val.name))]), _c('td', [_vm._v(_vm._s(val === null || val === void 0 ? void 0 : (_val$category = val.category) === null || _val$category === void 0 ? void 0 : _val$category.name))]), _c('td', [_vm._v(_vm._s(val === null || val === void 0 ? void 0 : (_val$spesification = val.spesification) === null || _val$spesification === void 0 ? void 0 : _val$spesification.name))])]);
  }), 0)])]), ((_vm$items = _vm.items) === null || _vm$items === void 0 ? void 0 : _vm$items.length) > 0 ? _c('div', {
    staticClass: "mt-3"
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": (_vm$meta = _vm.meta) === null || _vm$meta === void 0 ? void 0 : _vm$meta.totalItem,
      "per-page": (_vm$meta2 = _vm.meta) === null || _vm$meta2 === void 0 ? void 0 : _vm$meta2.perPage,
      "first-number": "",
      "align": "center"
    },
    on: {
      "change": _vm.getListItem
    },
    model: {
      value: _vm.page,
      callback: function callback($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1) : _vm._e()])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('label', {
    staticClass: "text-primary"
  }, [_c('strong', [_vm._v("Selected Products")])]), _c('div', {
    staticClass: "d-flex overflow-auto"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("PRODUCT")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("PRODUCT CODE")]), ['ADD'].includes(_vm.action) ? _c('th', {
    staticClass: "text-truncate text-center"
  }, [_vm._v("AVAILABLE STOCK")]) : _vm._e(), ['EDIT'].includes(_vm.action) ? _c('th', {
    staticClass: "text-truncate text-center"
  }, [_vm._v("BEGINNING STOCK")]) : _vm._e(), _c('th', {
    staticClass: "text-truncate text-center",
    staticStyle: {
      "min-width": "200px"
    }
  }, [_vm._v("QUANTITY")]), ['ADD'].includes(_vm.action) ? _c('th', {
    staticClass: "text-truncate text-center"
  }, [_vm._v("NEW STOCK")]) : _vm._e(), ['EDIT'].includes(_vm.action) ? _c('th', {
    staticClass: "text-truncate text-center"
  }, [_vm._v("UPDATE STOCK")]) : _vm._e(), ['ADJUSTMENT'].includes(_vm.type) ? _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("TYPE")]) : _vm._e(), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("REMARKS")]), ['ADD', 'EDIT'].includes(_vm.action) ? _c('th', {
    staticStyle: {
      "width": "5px"
    }
  }, [_vm._v("#")]) : _vm._e()])]), _c('tbody', [_vm.selected_products.length > 0 && _vm.form.type ? _vm._l(_vm.selected_products, function (state, index) {
    var _state$stock_availabl, _state$beginning_stoc;
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.name))]), _c('td', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.code))]), ['ADD'].includes(_vm.action) ? _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s((_state$stock_availabl = state === null || state === void 0 ? void 0 : state.stock_available) !== null && _state$stock_availabl !== void 0 ? _state$stock_availabl : 0))]) : _vm._e(), ['EDIT'].includes(_vm.action) ? _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s((_state$beginning_stoc = state === null || state === void 0 ? void 0 : state.beginning_stock) !== null && _state$beginning_stoc !== void 0 ? _state$beginning_stoc : 0))]) : _vm._e(), _c('td', [_c('b-form-input', {
      staticClass: "text-center",
      attrs: {
        "placeholder": "0",
        "type": "number",
        "max": _vm.form.type === 'stock_out' ? state === null || state === void 0 ? void 0 : state.stock_available : 1000000
      },
      model: {
        value: state.qty,
        callback: function callback($$v) {
          _vm.$set(state, "qty", $$v);
        },
        expression: "state.qty"
      }
    })], 1), ['transfer_stock', 'stock_out'].includes(_vm.form.type) ? _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(Number(state === null || state === void 0 ? void 0 : state.stock_available) - Number(state.qty)))]) : _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(Number(state === null || state === void 0 ? void 0 : state.stock_available) + Number(state.qty)))]), ['ADJUSTMENT'].includes(_vm.type) ? _c('td', [_vm._v(_vm._s(_vm.form.type === 'stock_in' ? "Stock In" : 'Stock Out'))]) : _vm._e(), _c('td', [_c('b-form-textarea', {
      attrs: {
        "placeholder": "Type Text"
      },
      model: {
        value: state.remarks,
        callback: function callback($$v) {
          _vm.$set(state, "remarks", $$v);
        },
        expression: "state.remarks"
      }
    })], 1), ['ADD', 'EDIT'].includes(_vm.action) ? _c('td', {
      staticStyle: {
        "width": "5px"
      }
    }, [state !== null && state !== void 0 && state.itemId ? _c('i', {
      staticClass: "fa fa-trash text-danger",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.onRemove(state === null || state === void 0 ? void 0 : state.itemId, index, true);
        }
      }
    }) : _c('i', {
      staticClass: "fa fa-ban text-danger",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.onRemove(state === null || state === void 0 ? void 0 : state.id, index, false);
        }
      }
    })]) : _vm._e()]);
  }) : _vm._e()], 2)])]), _vm.selected_products.length <= 0 || !_vm.form.type ? _c('div', {
    staticClass: "d-flex w-100 justify-content-center"
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v("Select Products & Type First.")])]) : _vm._e(), _c('hr')])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Notes",
      "label-for": "notes"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "placeholder": "Type Text",
      "id": "text",
      "type": "text",
      "rows": "4"
    },
    model: {
      value: _vm.form.notes,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "notes", $$v);
      },
      expression: "form.notes"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }