var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary p-0 m-0"
        }, [_c('strong', [_vm._v("SERVICE & REPAIR DASHBOARD")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-filter",
            modifiers: {
              "collapse-filter": true
            }
          }],
          staticClass: "text-primary float-right",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "visible": "",
            "id": "collapse-filter"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('date-picker', {
          attrs: {
            "lang": _vm.lang,
            "type": "date",
            "range": "",
            "placeholder": "Select date range"
          },
          model: {
            value: _vm.params.dateRange,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "dateRange", $$v);
            },
            expression: "params.dateRange"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "iq-edit-list"
  }, [_c('tab-nav', {
    staticClass: "iq-edit-profile d-flex",
    attrs: {
      "pills": true
    }
  }, [_c('tab-nav-items', {
    staticClass: "col-md-4 p-0",
    attrs: {
      "active": true,
      "href": "#request",
      "title": "REQUEST & PROGRESS"
    }
  }), _c('tab-nav-items', {
    staticClass: "col-md-4 p-0",
    attrs: {
      "active": false,
      "href": "#finish",
      "title": "FINISH REPAIR & MAINTENANCE"
    }
  }), _c('tab-nav-items', {
    staticClass: "col-md-4 p-0",
    attrs: {
      "active": false,
      "href": "#report",
      "title": "REPORT REPAIR & MAINTENANCE"
    }
  })], 1)], 1)]), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "iq-edit-list-data"
  }, [_c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "pills-tabContent-2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "request"
    }
  }, [_c('TabRequestAndProgress', {
    attrs: {
      "readOnly": true,
      "fleetId": Number(_vm.$route.query.vehicleId),
      "query": _vm.params
    }
  })], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "finish"
    }
  }, [_c('TabFinish', {
    attrs: {
      "fleetId": Number(_vm.$route.query.vehicleId),
      "query": _vm.params,
      "readOnly": true
    }
  })], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "report"
    }
  }, [_c('TabReport', {
    attrs: {
      "fleetId": Number(_vm.$route.query.vehicleId),
      "query": _vm.params,
      "readOnly": true
    }
  })], 1)], 1)])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }