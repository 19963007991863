var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center gap-4 float-right"
  }, [_vm.addPermission ? _c('a', {
    staticClass: "text-primary mr-4",
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "role": "button"
    },
    on: {
      "click": _vm.openForm
    }
  }, [_c('i', {
    staticClass: "fa fa-plus mr-2"
  }), _c('span', [_vm._v("Create Customer Class")])]) : _vm._e()])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_vm.loading ? _c('div', {
    staticClass: "alert alert-info"
  }, [_vm._v("Loading...")]) : _vm._e(), _c('div', {
    staticClass: "overflow-auto d-flex"
  }, [_c('table', {
    staticClass: "table table-striped table-bordered"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center align-middle",
    staticStyle: {
      "width": "20px"
    }
  }, [_vm._v("No")]), _c('th', {
    staticClass: "text-left align-middle"
  }, [_vm._v("Name")]), _c('th', {
    staticClass: "text-left align-middle"
  }, [_vm._v("Alias")]), _c('th', {
    staticClass: "text-left align-middle"
  }, [_vm._v("Status")]), _c('th', {
    staticClass: "text-center align-middle",
    staticStyle: {
      "width": "20px"
    }
  }, [_vm._v("#")])])]), _c('tbody', _vm._l(_vm.data, function (state, index) {
    var _state$name, _state$alias;
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s((_state$name = state === null || state === void 0 ? void 0 : state.name) !== null && _state$name !== void 0 ? _state$name : '-'))]), _c('td', [_vm._v(_vm._s((_state$alias = state === null || state === void 0 ? void 0 : state.alias) !== null && _state$alias !== void 0 ? _state$alias : '-'))]), _c('td', [_vm._v(_vm._s(state !== null && state !== void 0 && state.status ? "Active" : "Inactive"))]), _vm.updatePermission || _vm.deletePermission ? _c('td', [_c('div', {
      staticClass: "d-flex flex-column align-items-center"
    }, [_c('i', {
      staticClass: "fa fa-pen text-primary mb-3",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.onEdit(state);
        }
      }
    }), _c('i', {
      staticClass: "fa fa-trash text-danger",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.onDelete(state);
        }
      }
    })])]) : _vm._e()]);
  }), 0)])])]), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "form-class",
      "title": "".concat(_vm.edit ? 'EDIT' : 'ADD', " Customer Class"),
      "width": "50%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_c('fieldset', {
          staticClass: "form-group border p-3 m-5"
        }, [_c('form', {
          attrs: {
            "action": "#"
          },
          on: {
            "submit": _vm.onSubmit
          }
        }, [_c('legend', {
          staticClass: "w-auto px-2 text-primary"
        }, [_vm._v("Customer Class")]), _c('b-row', {
          staticClass: "mt-2"
        }, [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "*Customer Class Name",
            "label-for": "name"
          }
        }, [_c('b-form-input', {
          attrs: {
            "required": "",
            "id": "name",
            "type": "text",
            "name": "name",
            "placeholder": "Type text"
          },
          model: {
            value: _vm.form.name,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "name", $$v);
            },
            expression: "form.name"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "*Customer Class Alias",
            "label-for": "alias"
          }
        }, [_c('b-form-input', {
          attrs: {
            "required": "",
            "id": "alias",
            "type": "text",
            "placeholder": "Type text"
          },
          model: {
            value: _vm.form.alias,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "alias", $$v);
            },
            expression: "form.alias"
          }
        })], 1), _c('b-form-group', [_c('b-form-checkbox', {
          attrs: {
            "name": "status",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.form.status,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "status", $$v);
            },
            expression: "form.status"
          }
        }, [_vm._v(" Active / Inactive ")])], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center justify-content-end w-100"
        }, [_c('b-button', {
          staticStyle: {
            "width": "40%"
          },
          attrs: {
            "disabled": _vm.saving,
            "block": "",
            "variant": "primary",
            "type": "submit"
          }
        }, [_vm.saving ? _c('span', {
          staticClass: "spinner-border spinner-border-sm"
        }) : _c('div', [_c('i', {
          staticClass: "fa fa-save"
        }), _c('span', [_vm._v("Submit Data")])])])], 1)])], 1)], 1)])];
      }
    }]),
    model: {
      value: _vm.showFormClass,
      callback: function callback($$v) {
        _vm.showFormClass = $$v;
      },
      expression: "showFormClass"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }