var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "18",
      "height": "26",
      "viewBox": "0 0 18 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M5.25 4.87549C5.25 4.70973 5.31585 4.55076 5.43306 4.43355C5.55027 4.31634 5.70924 4.25049 5.875 4.25049H12.125C12.2908 4.25049 12.4497 4.31634 12.5669 4.43355C12.6842 4.55076 12.75 4.70973 12.75 4.87549C12.75 5.04125 12.6842 5.20022 12.5669 5.31743C12.4497 5.43464 12.2908 5.50049 12.125 5.50049H5.875C5.70924 5.50049 5.55027 5.43464 5.43306 5.31743C5.31585 5.20022 5.25 5.04125 5.25 4.87549ZM3.375 8.00049C3.20924 8.00049 3.05027 8.06634 2.93306 8.18355C2.81585 8.30076 2.75 8.45973 2.75 8.62549C2.75 8.79125 2.81585 8.95022 2.93306 9.06743C3.05027 9.18464 3.20924 9.25049 3.375 9.25049H14.625C14.7908 9.25049 14.9497 9.18464 15.0669 9.06743C15.1842 8.95022 15.25 8.79125 15.25 8.62549C15.25 8.45973 15.1842 8.30076 15.0669 8.18355C14.9497 8.06634 14.7908 8.00049 14.625 8.00049H3.375ZM2.75 11.1255C2.75 10.9597 2.81585 10.8008 2.93306 10.6835C3.05027 10.5663 3.20924 10.5005 3.375 10.5005H14.625C14.7908 10.5005 14.9497 10.5663 15.0669 10.6835C15.1842 10.8008 15.25 10.9597 15.25 11.1255C15.25 11.2912 15.1842 11.4502 15.0669 11.5674C14.9497 11.6846 14.7908 11.7505 14.625 11.7505H3.375C3.20924 11.7505 3.05027 11.6846 2.93306 11.5674C2.81585 11.4502 2.75 11.2912 2.75 11.1255ZM3.375 13.0005C3.20924 13.0005 3.05027 13.0663 2.93306 13.1835C2.81585 13.3008 2.75 13.4597 2.75 13.6255C2.75 13.7912 2.81585 13.9502 2.93306 14.0674C3.05027 14.1846 3.20924 14.2505 3.375 14.2505H14.625C14.7908 14.2505 14.9497 14.1846 15.0669 14.0674C15.1842 13.9502 15.25 13.7912 15.25 13.6255C15.25 13.4597 15.1842 13.3008 15.0669 13.1835C14.9497 13.0663 14.7908 13.0005 14.625 13.0005H3.375Z",
      "fill": _vm.color
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M17.75 20.5C17.75 21.163 17.4866 21.7989 17.0178 22.2678C16.5489 22.7366 15.913 23 15.25 23H13.375V25.5L11.5 24.5625L9.625 25.5V23H2.75C2.08696 23 1.45107 22.7366 0.982233 22.2678C0.513392 21.7989 0.25 21.163 0.25 20.5V3C0.25 2.33696 0.513392 1.70107 0.982233 1.23223C1.45107 0.763392 2.08696 0.5 2.75 0.5H15.25C15.913 0.5 16.5489 0.763392 17.0178 1.23223C17.4866 1.70107 17.75 2.33696 17.75 3V20.5ZM2.75 1.75C2.41848 1.75 2.10054 1.8817 1.86612 2.11612C1.6317 2.35054 1.5 2.66848 1.5 3V20.5C1.5 20.8315 1.6317 21.1495 1.86612 21.3839C2.10054 21.6183 2.41848 21.75 2.75 21.75H9.625V20.2787C9.30677 19.9179 9.09942 19.4729 9.02783 18.9971C8.95624 18.5214 9.02345 18.0351 9.22141 17.5966C9.41936 17.1581 9.73963 16.786 10.1438 16.525C10.548 16.264 11.0189 16.1252 11.5 16.1252C11.9811 16.1252 12.452 16.264 12.8562 16.525C13.2604 16.786 13.5806 17.1581 13.7786 17.5966C13.9765 18.0351 14.0438 18.5214 13.9722 18.9971C13.9006 19.4729 13.6932 19.9179 13.375 20.2787V21.75H15.25C15.5815 21.75 15.8995 21.6183 16.1339 21.3839C16.3683 21.1495 16.5 20.8315 16.5 20.5V3C16.5 2.66848 16.3683 2.35054 16.1339 2.11612C15.8995 1.8817 15.5815 1.75 15.25 1.75H2.75ZM12.125 21.0462C11.7151 21.1521 11.2849 21.1521 10.875 21.0462V23.4775L11.5 23.165L12.125 23.4775V21.0462ZM11.5 19.875C11.8315 19.875 12.1495 19.7433 12.3839 19.5089C12.6183 19.2745 12.75 18.9565 12.75 18.625C12.75 18.2935 12.6183 17.9755 12.3839 17.7411C12.1495 17.5067 11.8315 17.375 11.5 17.375C11.1685 17.375 10.8505 17.5067 10.6161 17.7411C10.3817 17.9755 10.25 18.2935 10.25 18.625C10.25 18.9565 10.3817 19.2745 10.6161 19.5089C10.8505 19.7433 11.1685 19.875 11.5 19.875Z",
      "fill": _vm.color
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }