var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("REPORT")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Period",
            "label-for": "Period"
          }
        }, [_c('date-picker', {
          attrs: {
            "type": "date",
            "range": "",
            "placeholder": "Select date range"
          },
          model: {
            value: _vm.dateRange,
            callback: function callback($$v) {
              _vm.dateRange = $$v;
            },
            expression: "dateRange"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Company",
            "label-for": "cargo"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": ""
          }
        }, [_c('b-form-select-option', [_vm._v("All")]), _c('b-form-select-option', [_vm._v("Own Ship")]), _c('b-form-select-option', [_vm._v("Third Party")])], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Cargo Type",
            "label-for": "cargo"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": ""
          }
        }, [_c('b-form-select-option', [_vm._v("All")]), _c('b-form-select-option', [_vm._v("COAL")]), _c('b-form-select-option', [_vm._v("SPLIT STONE")]), _c('b-form-select-option', [_vm._v("NICKEL")]), _c('b-form-select-option', [_vm._v("CPO")]), _c('b-form-select-option', [_vm._v("ASD")]), _c('b-form-select-option', [_vm._v("OTHERS")])], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('h4', [_c('strong', {
          staticClass: "text-primary"
        }, [_vm._v("Cargo By Project")])]), _c('div', {
          staticClass: "w-100 d-flex border my-2"
        })]), _c('b-col', {
          attrs: {
            "md": "5"
          }
        }, [_c('ApexChart', {
          attrs: {
            "element": "bar-chart-1-",
            "chartOption": _vm.patientChart1
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "7"
          }
        }, [_c('AmChart', {
          attrs: {
            "element": "pie-chart-1-",
            "type": "pie",
            "option": _vm.bodyData
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "w-100 d-flex border my-2"
        })]), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('h4', [_c('strong', {
          staticClass: "text-primary"
        }, [_vm._v("Revenue By PT")])]), _c('ApexChart', {
          attrs: {
            "element": "bar-chart-2-",
            "chartOption": _vm.patientChart2
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "6"
          }
        }, [_c('h4', [_c('strong', {
          staticClass: "text-primary"
        }, [_vm._v("Cargo By PT")])]), _c('ApexChart', {
          attrs: {
            "element": "bar-chart-3-",
            "chartOption": _vm.patientChart3
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }