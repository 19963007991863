<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">
              <strong>INSPECTION CHECKLIST</strong>
            </h4>
          </template>
          <template v-slot:headerAction>
            <div class="d-flex align-items-center gap-4 float-right">
              <a class="text-primary mr-4" role="button" style="cursor: pointer" @click="getListInspectionChecklist">
                <i class="fa fa-rotate-right mr-2" />
                <span>Refresh Data</span>
              </a>  
              <a class="text-warning mr-4" role="button" style="cursor: pointer" @click="openSlideSendMail">
                <i class="fa fa-envelope mr-2" />
                <span>Send Mail</span>
              </a>
              <a class="text-primary mr-4" role="button" style="cursor: pointer" @click="addInspectionChecklist" v-if="addPermission">
                <i class="fa fa-plus mr-2" />
                <span>Create Data</span>
              </a>
            </div>
          </template>
          <template v-slot:body>
            <!-- FILTERING CUSTOMERS -->
            <b-row class="mb-4">
              <b-col md="3">
                <b-form-group label="Search" label-for="search">
                  <b-form-input placeholder="Search code/name..." name="search" v-model="params.search"></b-form-input>
                </b-form-group>
              </b-col> 
              <b-col md="3">
                <b-form-group label="Vehicle" label-for="vehicle_id">
                <v-select
                  label="text"
                  name="vehicle_id"
                  v-model="params.vehicle_id"
                  :options="optionsFleets"
                  :reduce="(optionsFleets) => optionsFleets.value"
                  placeholder="Select Fleet"
                ></v-select>
              </b-form-group>
              </b-col>
              <b-col md="1">
                <b-form-group label="Filter" label-for="button_search">
                  <b-button
                    variant="primary"
                    type="button"
                    @click="getListInspectionChecklist"
                    ><i class="fa fa-filter"></i
                  ></b-button>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row v-if="loading">
              <b-col md="12">
                <div class="text-center my-5">
                  <b-spinner type="grow" label="Spinning"></b-spinner>
                </div>
              </b-col>
            </b-row>
            <!-- TABLE CONTENT -->
            <b-row v-else>
              <b-col md="12">
                <div v-if="loading" class="alert alert-info">Loading...</div>
                <iq-card> 
                  <div class="d-flex overflow-auto">
                    <table class="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th class="text-center align-middle" style="width: 20px">No</th>
                          <th class="text-left align-middle text-truncate">Fleet</th>
                          <th class="text-left align-middle text-truncate">Code/Name</th>
                          <th class="text-left align-middle text-truncate">Field</th>
                          <th class="text-left align-middle text-truncate">Signature</th>
                          <th class="text-left align-middle text-truncate">Status</th>
                          <th class="text-center align-middle" style="width: 20px">#</th>
                        </tr>
                      </thead>
                      <tbody v-if="listInspectionChecklist?.data?.length > 0">
                        <tr v-for="(state, index) in listInspectionChecklist.data" :key="index">
                          <td>{{ index + 1 }}</td>
                          <td>
                            <div class="d-flex flex-column">
                              <strong class="text-truncate">{{ state?.vehicle?.name }}</strong>
                              <small class="text-truncate">({{ state?.vehicle?.vehicle_type?.name }})</small>
                            </div>
                          </td>
                          <td class="text-truncate">{{ state?.collection_of_forms?.code }} - {{ state?.collection_of_forms?.name }}</td>
                          <td>
                            <div class="d-flex flex-column">
                                <div class="d-flex mb-1" v-for="(field, fieldIndex) in state?.field" :key="fieldIndex">
                                  <strong class="mr-2 text-truncate">{{ field?.label }}: </strong>
                                  <span class="text-truncate">{{ field?.value ? handleTypeDate(field?.value) : '-' }}</span>
                                </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex flex-column">
                                <div class="d-flex mb-1" v-for="(signature, signatureIndex) in state?.signature" :key="signatureIndex">
                                  <strong class="mr-2 text-truncate">{{ signature?.label }}: </strong>
                                  <span class="text-truncate">{{ signature?.value ? handleTypeDate(signature?.value) : '-' }}</span>
                                </div>
                            </div>
                          </td>

                          <td><strong class="text-primary text-capitalize">{{ state?.status }}</strong></td>
                          <td v-if="updatePermission || deletePermission">
                              <b-dropdown v-b-tooltip.top="'Action'"  variant="link iq-bg-primary" toggle-class="text-decoration-none" no-caret class="float-right mr-1" size="sm">
                                <template v-slot:button-content>
                                    <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="fa fa-pen m-0"></i>
                                    </span>
                                </template>
                                <b-dropdown-item href="#" variant="primary" @click="detailInspectionChecklist(state?.id)"><i class="fa fa-eye mr-2" ></i>Detail</b-dropdown-item>
                                <b-dropdown-item href="#" variant="danger" @click="onDeleteInspectionChecklist(state)"><i class="fa fa-trash mr-2" ></i>Delete</b-dropdown-item>
                                <b-dropdown-divider></b-dropdown-divider>
                                <b-dropdown-item href="#" variant="primary" @click="onExport(state?.id, 'pdf')"><i class="fa fa-file-pdf mr-2" ></i>Export PDF</b-dropdown-item>
                                <b-dropdown-item href="#" variant="primary" @click="onExport(state?.id, 'excel')" disabled><i class="fa fa-file-excel mr-2" ></i>Export Excel</b-dropdown-item>
                              </b-dropdown>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="mt-3" v-if="listInspectionChecklist?.data?.length > 0">
                    <b-pagination
                      v-model="listInspectionChecklist.meta.page"
                      :total-rows="listInspectionChecklist.meta.totalItem"
                      @change="getListInspectionChecklist"
                      :per-page="listInspectionChecklist.meta.perPage"
                      first-number
                      align="center"
                    ></b-pagination>
                  </div>
                  <p v-else class="text-center my-4">No Inspection Checklist Data Found.</p>
                </iq-card>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>

      <b-col lg="12">
        <b-sidebar v-model="showAddInspectionChecklist" id="add-inspection-checklist" :title="`${isEdit ? 'EDIT' : 'CREATE'} INSPECTION CHECKLIST`" width="80%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
          <template #default="{hide}">
            <b-row class="m-3">
              <b-col cols="12">
                <FormInspectionChecklist 
                  :reset-form-to-initial-value="resetFormToInitialValue"
                />
              </b-col>
            </b-row>
            </template>
        </b-sidebar>
      </b-col>

      <b-col lg="12">
        <b-sidebar v-model="showDetailEditInspectionChecklist" id="detail-edit-inspection-checklist" :title="`${isEdit ? 'EDIT' : 'DETAIL'} INSPECTION CHECKLIST`" width="80%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
          <template #default="{hide}">
            <b-row class="m-3">
              <b-col cols="12">
                <DetailEditInspectionChecklist 
                  :is-edit="isEdit" 
                  :edit-id="editId" 
                  :reset-form-to-initial-value="resetFormToInitialValue"
                />
              </b-col>
            </b-row>
            </template>
        </b-sidebar>
      </b-col>

      <!-- SLIDE - SEND MAIL -->
      <b-col md="12">
        <b-sidebar
          v-model="slideSendMail"
          id="slideSendMail"
          :title="'Send Mail'"
          width="90%"
          backdrop-variant="dark"
          bg-variant="white"
          right
          backdrop
          shadow>
          <template #default="{hide}">
            <SlideSendMail
              :closeSlideSendMail="closeSlideSendMail"
            />
          </template>
        </b-sidebar>
      </b-col>
      <!-- SLIDE - SEND MAIL END -->
    </b-row>
  </b-container>
</template>
<script>
  import _ from 'lodash'
  import moment from 'moment'
  import { busdevActions, fleetsActions } from '@src/Utils/helper'
  import FormInspectionChecklist from './FormInspectionChecklist.vue'
  import DetailEditInspectionChecklist from './DetailEditInspectionChecklist.vue'
  import SlideSendMail from './SlideSendMail.vue'
  import { saveAs } from 'file-saver'

  export default {
    name: 'InspectionChecklist',
    data() {
      return {
        loading:false,
        exporting:false,
        showAddInspectionChecklist:false,
        showDetailEditInspectionChecklist:false,
        slideSendMail:false,

        optionsFleets:[],

        isEdit:false,
        editId:null,

        params:{
          page: 1,
          perPage:25,
          search:'',
          vehicle_id:'',
        },

        listInspectionChecklist: {
          data:[],
          meta:null
        }
      }
    },
    components:{
      FormInspectionChecklist,
      DetailEditInspectionChecklist,
      SlideSendMail,
    },
    created() {
    this.getListInspectionChecklist = _.debounce(this.getListInspectionChecklist, 500)
  },
  computed:{
    addPermission(){
      let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
        .find(obj => obj.menu === 'Apps').child
        .find(obj => obj.menu === 'Business Development').child
        .find(obj => obj.menu === 'Inspection Checklist').add

      return hasAccess
    },
    updatePermission(){
      let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
        .find(obj => obj.menu === 'Apps').child
        .find(obj => obj.menu === 'Business Development').child
        .find(obj => obj.menu === 'Inspection Checklist').update

      return hasAccess
    },
    deletePermission(){
      let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
        .find(obj => obj.menu === 'Apps').child
        .find(obj => obj.menu === 'Business Development').child
        .find(obj => obj.menu === 'Inspection Checklist').delete

      return hasAccess
    },
  },
  async mounted() {
    this.fetchFleets()
    this.getListInspectionChecklist()
  },
  
  methods: {
    ...busdevActions,
    ...fleetsActions,
    openSlideSendMail(id = null){
      this.slideSendMail = !this.slideSendMail;
    },  
    closeSlideSendMail(){
      this.slideSendMail = false
    },  

    handleTypeDate(value){
      if(this.validDate(value)){
        return moment(value).format('DD MMMM YYYY')
      } else if (this.validDateTime(value)){
        return moment(value).format('HH:mm A')
      } else {
        return value
      }
    },
    validDate(val, format = 'YYYY-MM-DD'){
      return moment(val, format, true).isValid();
    },  
    validDateTime(val, format = 'YYYY-MM-DDTHH:mm'){
      return moment(val, format, true).isValid();
    },  
    addInspectionChecklist(){
      this.isEdit = false
      this.editId = null

      this.showAddInspectionChecklist = true
      this.$root.$emit('bv::toggle::collapse', `add-inspection-checklist`)
    }, 
    onEditInspection(){
      this.isEdit = !this.isEdit
    },
    detailInspectionChecklist(id){
      this.isEdit = false
      this.editId = id

      this.showDetailEditInspectionChecklist = true
      this.$root.$emit('bv::toggle::collapse', `detail-edit-inspection-checklist`)
    },
    resetFormToInitialValue(){
      this.isEdit = false
      this.editId = null

      this.showAddInspectionChecklist = false
      this.showDetailEditInspectionChecklist = false
      this.getListInspectionChecklist()
    },
    async fetchFleets() {
      let params = this.$options.filters.cleanObject({
        page: 1,
        perPage: 9999,
        showAll: true,
        active: true,
      })
      
      let res = await this.getFleets(params)

      if (res.status) {
        if (res.data && res.data.length > 0) {
          this.optionsFleets = res.data.map((val) => ({
            text: val.name,
            value: val.id,
          }))
        }
      }
    },
    async getListInspectionChecklist(page) {
      this.listInspectionChecklist = {
        data:[],
        meta:null
      }

      let params = {
        page: page || 1,
        ...this.params,
      }

      if(!params.search) delete params.search
      if(!params.vehicle_id) delete params.vehicle_id

      this.loading = true

      let res = await this.fetchInspectionChecklist(params)

      if (res?.data?.status === true && res?.data?.data?.data?.length > 0) {
        this.listInspectionChecklist.data = res.data.data?.data.map(val => {
          val.signature = JSON.parse(val.signature)
          val.field = JSON.parse(val.field)
          val.question_content = JSON.parse(val.question_content)
          return val
        })
        this.listInspectionChecklist.meta = res.data.data?.meta
      }

      this.loading = false
    },

    async onDeleteInspectionChecklist(data){
      this.$swal({
          title: `Are you sure want to delete ${data?.collection_of_forms?.code}/${data?.collection_of_forms?.name} Inspection Checklist?`,
          text: "Yes, i'm sure.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
      }).then(async (result) => {
          if (result.isConfirmed) {
            const res = await this.deleteInspectionChecklist({id:data.id})
            if(res?.data?.status === true){
              await this.getListInspectionChecklist()
              this.$swal(
                  'Success!',
                  'Success delete Inspection Checklist.',
                  'success'
              )
            } else {
              this.$swal(
                    'Failed!',
                    'Something went wrong.',
                    'error'
                )
              return 
            }
            
          }
      })
    },

    async onExport(id, fileType){
      this.exporting = true
      console.log(id, fileType,"is clickerd?")
      const res = await this.exportInspectionChecklist({id, fileType})

      if(fileType == 'excel'){
      if(res.status === 'success'){
        saveAs(res.data, 'Inspection Checklist.xlsx')
      } else {
        console.log('Something went wrong')
      }
      } else if(fileType == 'pdf'){
      if(res.status === 'success'){
        saveAs(res.data, 'Inspection Checklist.pdf')
      } else {
        console.log('Something went wrong')
      }
      } else {
      console.log('Something went wrong')
      }

      this.exporting = false
    }
  }
  }
</script>