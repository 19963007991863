var render = function render(){
  var _vm$projectData, _vm$projectData$proje, _vm$projectData$proje2, _vm$projectData2, _vm$projectData2$proj, _vm$projectData3, _vm$projectData3$cust, _vm$projectData4, _vm$projectData4$cust, _vm$projectData5, _vm$projectData6, _vm$projectData7, _vm$projectData8, _vm$projectData9, _vm$projectData10, _vm$projectData11, _vm$projectData12, _vm$projectData13, _vm$projectData14, _vm$projectData14$con, _vm$projectData$cargo, _vm$projectData15, _vm$projectData15$car, _vm$projectData$terms, _vm$projectData16, _vm$projectData16$ter, _vm$projectData17, _vm$projectData18, _vm$projectData19, _vm$projectData19$tot, _vm$projectData20, _vm$projectData21, _vm$projectData22, _vm$projectData23, _vm$projectData24, _vm$projectData25, _vm$projectData26, _vm$projectData27, _vm$projectData28, _vm$projectData29, _vm$projectData30, _vm$projectData30$pro, _vm$projectData31, _vm$listTrips, _vm$listTrips2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [!_vm.project_data_loading ? _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    staticClass: "float-right mr-1",
    attrs: {
      "variant": "primary",
      "size": "sm",
      "type": "button"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onSubmit.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-save"
  }), _vm._v(" Submit  ")]), _c('b-button', {
    staticClass: "float-right mr-1",
    attrs: {
      "variant": "danger",
      "size": "sm",
      "type": "button"
    },
    on: {
      "click": _vm.closeSlideFormRealShipment
    }
  }, [_c('i', {
    staticClass: "fa fa-ban"
  }), _vm._v(" Cancel  ")])], 1), _c('b-col', {
    staticClass: "p-4",
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Project")]), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "dateIssued",
      "label": "Projects"
    }
  }, [_c('span', [_vm._v(_vm._s((_vm$projectData = _vm.projectData) === null || _vm$projectData === void 0 ? void 0 : (_vm$projectData$proje = _vm$projectData.project_sub) === null || _vm$projectData$proje === void 0 ? void 0 : (_vm$projectData$proje2 = _vm$projectData$proje.project) === null || _vm$projectData$proje2 === void 0 ? void 0 : _vm$projectData$proje2.project_name) + " - " + _vm._s((_vm$projectData2 = _vm.projectData) === null || _vm$projectData2 === void 0 ? void 0 : (_vm$projectData2$proj = _vm$projectData2.project_sub) === null || _vm$projectData2$proj === void 0 ? void 0 : _vm$projectData2$proj.name))])])], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Customer",
      "label-for": "customer_id"
    }
  }, [_c('span', [_vm._v(_vm._s((_vm$projectData3 = _vm.projectData) === null || _vm$projectData3 === void 0 ? void 0 : (_vm$projectData3$cust = _vm$projectData3.customer) === null || _vm$projectData3$cust === void 0 ? void 0 : _vm$projectData3$cust.customer_code) + " - " + _vm._s((_vm$projectData4 = _vm.projectData) === null || _vm$projectData4 === void 0 ? void 0 : (_vm$projectData4$cust = _vm$projectData4.customer) === null || _vm$projectData4$cust === void 0 ? void 0 : _vm$projectData4$cust.account_name))])])], 1)], 1)], 1)]), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": '12'
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Detail Project Contract")]), _c('table', {
    staticClass: "table table-striped"
  }, [_c('tbody', [_c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Company")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v("-")]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }), _c('td', {
    attrs: {
      "width": "5%"
    }
  }), _c('td', {
    attrs: {
      "width": "30%"
    }
  })]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Fleets")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%",
      "colSpan": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "lg": "3"
    }
  }, [_c('span', [_vm._v("Fleet ownership")]), _vm._v(" "), _c('br'), _c('b-badge', {
    staticClass: "border border-dark text-dark text-uppercase mb-2",
    attrs: {
      "variant": "none"
    }
  }, [_vm._v(_vm._s(((_vm$projectData5 = _vm.projectData) === null || _vm$projectData5 === void 0 ? void 0 : _vm$projectData5.fleet_ownership) === 'own-ship' ? "OWN SHIP" : 'CHARTERED'))])], 1), (_vm$projectData6 = _vm.projectData) !== null && _vm$projectData6 !== void 0 && _vm$projectData6.fleet_ownership ? _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('span', [_vm._v("Fleet Type")]), _vm._v(" "), _c('br'), _c('b-badge', {
    staticClass: "border border-dark text-dark text-uppercase mb-2",
    attrs: {
      "variant": "none"
    }
  }, [_vm._v("Tug & Barge")])], 1) : _vm._e()], 1), (_vm$projectData7 = _vm.projectData) !== null && _vm$projectData7 !== void 0 && _vm$projectData7.fleet_type_id ? _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_vm._v("Fleets")]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-warp"
  }, _vm._l((_vm$projectData8 = _vm.projectData) === null || _vm$projectData8 === void 0 ? void 0 : _vm$projectData8.fleets, function (objDetail, detailIndex) {
    return _c('b-badge', {
      key: detailIndex,
      staticClass: "border border-primary text-primary text-uppercase mb-2 mr-2",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v("TB. TAVIA 00" + _vm._s(detailIndex + 1))]);
  }), 1)])], 1) : _vm._e()], 1)])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Collection of Forms")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-wrap gap-2"
  }, _vm._l((_vm$projectData9 = _vm.projectData) === null || _vm$projectData9 === void 0 ? void 0 : _vm$projectData9.project_forms, function (val, index) {
    var _val$collection_forms;
    return _c('b-badge', {
      key: index,
      staticClass: "border border-primary text-primary text-uppercase m-1",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v(" " + _vm._s(val === null || val === void 0 ? void 0 : (_val$collection_forms = val.collection_forms) === null || _val$collection_forms === void 0 ? void 0 : _val$collection_forms.name) + " ")]);
  }), 1)]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }), _c('td', {
    attrs: {
      "width": "5%"
    }
  }), _c('td', {
    attrs: {
      "width": "30%"
    }
  })]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Periode Start")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('span', [_vm._v(_vm._s((_vm$projectData10 = _vm.projectData) !== null && _vm$projectData10 !== void 0 && _vm$projectData10.periode_start ? _vm.moment((_vm$projectData11 = _vm.projectData) === null || _vm$projectData11 === void 0 ? void 0 : _vm$projectData11.periode_start).format('DD MMMM YYYY') : '-'))])]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Periode End")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('span', [_vm._v(_vm._s((_vm$projectData12 = _vm.projectData) !== null && _vm$projectData12 !== void 0 && _vm$projectData12.periode_start ? _vm.moment((_vm$projectData13 = _vm.projectData) === null || _vm$projectData13 === void 0 ? void 0 : _vm$projectData13.periode_start).format('DD MMMM YYYY') : '-'))])])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Contract Type")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('span', [_vm._v(_vm._s((_vm$projectData14 = _vm.projectData) === null || _vm$projectData14 === void 0 ? void 0 : (_vm$projectData14$con = _vm$projectData14.contract_type) === null || _vm$projectData14$con === void 0 ? void 0 : _vm$projectData14$con.name))])]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Cargo Type")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('span', [_vm._v(_vm._s((_vm$projectData$cargo = (_vm$projectData15 = _vm.projectData) === null || _vm$projectData15 === void 0 ? void 0 : (_vm$projectData15$car = _vm$projectData15.cargo_type) === null || _vm$projectData15$car === void 0 ? void 0 : _vm$projectData15$car.name) !== null && _vm$projectData$cargo !== void 0 ? _vm$projectData$cargo : '-'))])])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Term")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('span', [_vm._v(_vm._s((_vm$projectData$terms = (_vm$projectData16 = _vm.projectData) === null || _vm$projectData16 === void 0 ? void 0 : (_vm$projectData16$ter = _vm$projectData16.terms) === null || _vm$projectData16$ter === void 0 ? void 0 : _vm$projectData16$ter.name) !== null && _vm$projectData$terms !== void 0 ? _vm$projectData$terms : '-') + " "), _c('br'), _vm._v(" Remarks: " + _vm._s((_vm$projectData17 = _vm.projectData) === null || _vm$projectData17 === void 0 ? void 0 : _vm$projectData17.terms_remarks))])]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Total Quantity")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [(_vm$projectData18 = _vm.projectData) !== null && _vm$projectData18 !== void 0 && _vm$projectData18.cargo_type_id ? _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('span', {
    staticClass: "mr-2"
  }, [_vm._v(_vm._s((_vm$projectData19 = _vm.projectData) === null || _vm$projectData19 === void 0 ? void 0 : (_vm$projectData19$tot = _vm$projectData19.total_quantity) === null || _vm$projectData19$tot === void 0 ? void 0 : _vm$projectData19$tot.toLocaleString()))]), _c('strong', [_vm._v(_vm._s((_vm$projectData20 = _vm.projectData) === null || _vm$projectData20 === void 0 ? void 0 : _vm$projectData20.unit))])]) : _c('div', [_c('i', [_vm._v("Choose cargo type first.")])])])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("POL")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column mb-2 w-100"
  }, _vm._l((_vm$projectData21 = _vm.projectData) === null || _vm$projectData21 === void 0 ? void 0 : _vm$projectData21.pol, function (obj, polIndex) {
    var _obj$value;
    return _c('span', {
      key: polIndex
    }, [_vm._v(_vm._s(polIndex + 1) + ". " + _vm._s((_obj$value = obj === null || obj === void 0 ? void 0 : obj.value) !== null && _obj$value !== void 0 ? _obj$value : '-'))]);
  }), 0)]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("POD")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column mb-2 w-100"
  }, _vm._l((_vm$projectData22 = _vm.projectData) === null || _vm$projectData22 === void 0 ? void 0 : _vm$projectData22.pod, function (obj, podIndex) {
    var _obj$value2;
    return _c('span', {
      key: podIndex
    }, [_vm._v(_vm._s(podIndex + 1) + ". " + _vm._s((_obj$value2 = obj === null || obj === void 0 ? void 0 : obj.value) !== null && _obj$value2 !== void 0 ? _obj$value2 : '-'))]);
  }), 0)])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Laycan")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('span', [_vm._v(_vm._s((_vm$projectData23 = _vm.projectData) !== null && _vm$projectData23 !== void 0 && _vm$projectData23.laycan ? _vm.moment((_vm$projectData24 = _vm.projectData) === null || _vm$projectData24 === void 0 ? void 0 : _vm$projectData24.laycan).format('DD MMMM YYYY') : '-'))])]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Distance")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('span', [_vm._v(_vm._s((_vm$projectData25 = _vm.projectData) === null || _vm$projectData25 === void 0 ? void 0 : _vm$projectData25.distance))])])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Shipment Plan Number")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$projectData26 = _vm.projectData) === null || _vm$projectData26 === void 0 ? void 0 : _vm$projectData26.shipment_plan_number))])]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Freigh Rate (Est)")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('span', [_vm._v(_vm._s(_vm.formatCurrency((_vm$projectData27 = _vm.projectData) === null || _vm$projectData27 === void 0 ? void 0 : _vm$projectData27.freight_rate_est)))]), _c('strong', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(((_vm$projectData28 = _vm.projectData) === null || _vm$projectData28 === void 0 ? void 0 : _vm$projectData28.ppn) == 'include' ? 'Include ' : 'Exclude ') + " PPN")])])])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Remarks")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%",
      "colSpan": "4"
    }
  }, [_c('span', [_vm._v(_vm._s((_vm$projectData29 = _vm.projectData) === null || _vm$projectData29 === void 0 ? void 0 : _vm$projectData29.remarks))])])])])])])])], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Project Contract Plan")]), _c('div', {
    staticClass: "overflow-auto"
  }, [_c('table', {
    staticClass: "table table-striped table-bordered"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "table-info text-center"
  }, [_vm._v("#")]), _c('th', {
    staticClass: "table-info text-truncate"
  }, [_vm._v("No")]), _c('th', {
    staticClass: "table-info text-truncate"
  }, [_vm._v("S.Plan Number")]), _c('th', {
    staticClass: "table-info text-truncate"
  }, [_vm._v("Description")]), _c('th', {
    staticClass: "table-info text-truncate"
  }, [_vm._v("Fleet")]), _c('th', {
    staticClass: "table-info text-truncate"
  }, [_vm._v("Cargo Type")]), _c('th', {
    staticClass: "table-info text-truncate"
  }, [_vm._v("Location")]), _c('th', {
    staticClass: "table-info text-truncate"
  }, [_vm._v("Plan Date")]), _c('th', {
    staticClass: "table-info text-truncate"
  }, [_vm._v("Plan Quantity")]), _c('th', {
    staticClass: "table-info text-truncate"
  }, [_vm._v("Remarks")]), _c('th', {
    staticClass: "table-info text-truncate"
  }, [_vm._v("Status")])])]), ((_vm$projectData30 = _vm.projectData) === null || _vm$projectData30 === void 0 ? void 0 : (_vm$projectData30$pro = _vm$projectData30.project_contract_plan) === null || _vm$projectData30$pro === void 0 ? void 0 : _vm$projectData30$pro.length) > 0 ? _c('tbody', _vm._l((_vm$projectData31 = _vm.projectData) === null || _vm$projectData31 === void 0 ? void 0 : _vm$projectData31.project_contract_plan, function (val, index) {
    var _val$cargo_type, _val$plan_quantity;
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "text-center"
    }, [_c('b-form-checkbox', {
      attrs: {
        "name": "checked",
        "disabled": val.disabled
      },
      on: {
        "change": _vm.onCheckedPlan
      },
      model: {
        value: val.checked,
        callback: function callback($$v) {
          _vm.$set(val, "checked", $$v);
        },
        expression: "val.checked"
      }
    })], 1), _c('td', [_vm._v(_vm._s(index + 1))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(val === null || val === void 0 ? void 0 : val.shipment_plan_detail_number))]), _c('td', [_vm._v(_vm._s(val === null || val === void 0 ? void 0 : val.description))]), _c('td', {
      staticClass: "text-truncate"
    }, [_c('div', {
      staticClass: "d-flex w-100"
    }, _vm._l(val === null || val === void 0 ? void 0 : val.fleets, function (obj, objIndex) {
      return _c('b-badge', {
        key: objIndex,
        staticClass: "border border-primary text-primary text-uppercase mr-1",
        attrs: {
          "variant": "none"
        }
      }, [_vm._v(_vm._s(_vm.showVehicleName(obj.vehicle_id)))]);
    }), 1)]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(val === null || val === void 0 ? void 0 : (_val$cargo_type = val.cargo_type) === null || _val$cargo_type === void 0 ? void 0 : _val$cargo_type.name))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v("-")]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v("-")]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(val === null || val === void 0 ? void 0 : (_val$plan_quantity = val.plan_quantity) === null || _val$plan_quantity === void 0 ? void 0 : _val$plan_quantity.toLocaleString()))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(val === null || val === void 0 ? void 0 : val.remarks))]), _c('td', {
      staticClass: "text-truncate text-capitalize"
    }, [_vm._v(_vm._s(val === null || val === void 0 ? void 0 : val.status))])]);
  }), 0) : _vm._e()])])])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Select Operational Data")]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "5"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "date",
      "label": "Date"
    }
  }, [_c('date-picker', {
    style: {
      width: '100%'
    },
    attrs: {
      "type": "date",
      "range": "",
      "placeholder": "Select date range"
    },
    model: {
      value: _vm.dateRange,
      callback: function callback($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "dateIssued",
      "label": "Select Fleet"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "text",
      "name": "vehicle_id",
      "options": _vm.optionsFleets,
      "reduce": function reduce(optionsFleets) {
        return optionsFleets.value;
      },
      "placeholder": "Select Fleet"
    },
    model: {
      value: _vm.params.vehicle_id,
      callback: function callback($$v) {
        _vm.$set(_vm.params, "vehicle_id", $$v);
      },
      expression: "params.vehicle_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "date",
      "label": "Find"
    }
  }, [_c('b-button', {
    staticClass: "px-4",
    attrs: {
      "type": "button",
      "variant": "primary"
    },
    on: {
      "click": _vm.fetchVoyageByTrips
    }
  }, [_vm._v(" FIND DATA ")])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex overflow-auto"
  }, [_c('table', {
    staticClass: "table table-bordered table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center",
    staticStyle: {
      "width": "5px",
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("#")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("FLEET")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("STATUS")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("CARGO TYPE")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("CARGO UNLOADING")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("CARGO LOADING")]), _c('th', {
    staticClass: "table-primary text-truncate"
  }, [_vm._v("Arival Time POL")]), _c('th', {
    staticClass: "table-primary text-truncate"
  }, [_vm._v("BL Date")]), _c('th', {
    staticClass: "table-primary text-truncate"
  }, [_vm._v("NOR")]), _c('th', {
    staticClass: "table-primary text-truncate"
  }, [_vm._v("NOA")]), _c('th', {
    staticClass: "table-primary text-truncate"
  }, [_vm._v("Anchor Up")]), _c('th', {
    staticClass: "table-primary text-truncate"
  }, [_vm._v("In Position / Berthing")]), _c('th', {
    staticClass: "table-primary text-truncate"
  }, [_vm._v("Commenced")]), _c('th', {
    staticClass: "table-primary text-truncate"
  }, [_vm._v("Completed")]), _c('th', {
    staticClass: "table-primary text-truncate"
  }, [_vm._v("Draught Survey")]), _c('th', {
    staticClass: "table-primary text-truncate"
  }, [_vm._v("Cast In")]), _c('th', {
    staticClass: "table-primary text-truncate"
  }, [_vm._v("Cast Out")]), _c('th', {
    staticClass: "table-primary text-truncate"
  }, [_vm._v("LHV")]), _c('th', {
    staticClass: "table-primary text-truncate"
  }, [_vm._v("Document on Board")]), _c('th', {
    staticClass: "table-primary text-truncate"
  }, [_vm._v("Departure to POD")]), _c('th', {
    staticClass: "table-primary text-truncate"
  }, [_vm._v("Remarks")]), _c('th', {
    staticClass: "table-info text-truncate"
  }, [_vm._v("Arival Time POD")]), _c('th', {
    staticClass: "table-info text-truncate"
  }, [_vm._v("BL Date")]), _c('th', {
    staticClass: "table-info text-truncate"
  }, [_vm._v("NOR")]), _c('th', {
    staticClass: "table-info text-truncate"
  }, [_vm._v("NOA")]), _c('th', {
    staticClass: "table-info text-truncate"
  }, [_vm._v("Anchor Up")]), _c('th', {
    staticClass: "table-info text-truncate"
  }, [_vm._v("In Position / Berthing")]), _c('th', {
    staticClass: "table-info text-truncate"
  }, [_vm._v("Commenced")]), _c('th', {
    staticClass: "table-info text-truncate"
  }, [_vm._v("Completed")]), _c('th', {
    staticClass: "table-info text-truncate"
  }, [_vm._v("Draught Survey")]), _c('th', {
    staticClass: "table-info text-truncate"
  }, [_vm._v("Cast In")]), _c('th', {
    staticClass: "table-info text-truncate"
  }, [_vm._v("Cast Out")]), _c('th', {
    staticClass: "table-info text-truncate"
  }, [_vm._v("LHV")]), _c('th', {
    staticClass: "table-info text-truncate"
  }, [_vm._v("Document on Board")]), _c('th', {
    staticClass: "table-info text-truncate"
  }, [_vm._v("Departure to POL")]), _c('th', {
    staticClass: "table-info text-truncate"
  }, [_vm._v("Remarks")])])]), ((_vm$listTrips = _vm.listTrips) === null || _vm$listTrips === void 0 ? void 0 : _vm$listTrips.length) > 0 ? _c('tbody', _vm._l(_vm.listTrips, function (val, index) {
    var _val$tripRecord, _val$tripRecord2, _val$tripRecord3, _val$tripRecord3$carg, _val$tripRecord4, _val$tripRecord5, _val$tripRecord6, _val$tripRecord7, _val$tripRecord8, _val$tripRecord9, _val$tripRecord10, _val$tripRecord11, _val$tripRecord12, _val$tripRecord13, _val$tripRecord$polDr, _val$tripRecord14, _val$tripRecord15, _val$tripRecord16, _val$tripRecord17, _val$tripRecord18, _val$tripRecord19, _val$tripRecord20, _val$tripRecord21, _val$tripRecord22, _val$tripRecord23, _val$tripRecord24, _val$tripRecord25, _val$tripRecord26, _val$tripRecord27, _val$tripRecord28, _val$tripRecord29, _val$tripRecord30, _val$tripRecord31, _val$tripRecord32, _val$tripRecord$podDr, _val$tripRecord33, _val$tripRecord34, _val$tripRecord35, _val$tripRecord36, _val$tripRecord37, _val$tripRecord38, _val$tripRecord39, _val$tripRecord40, _val$tripRecord41;
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "text-center"
    }, [_c('b-form-checkbox', {
      attrs: {
        "name": "checked"
      },
      model: {
        value: val.checked,
        callback: function callback($$v) {
          _vm.$set(val, "checked", $$v);
        },
        expression: "val.checked"
      }
    })], 1), _c('td', {
      staticClass: "text-truncate"
    }, [_c('div', {
      staticClass: "d-flex w-100"
    }, _vm._l(val === null || val === void 0 ? void 0 : val.vehicle, function (obj, objIndex) {
      return _c('b-badge', {
        key: objIndex,
        staticClass: "border border-primary text-primary text-uppercase mr-1",
        attrs: {
          "variant": "none"
        }
      }, [_vm._v(_vm._s(obj === null || obj === void 0 ? void 0 : obj.name))]);
    }), 1)]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(val === null || val === void 0 ? void 0 : (_val$tripRecord = val.tripRecord) === null || _val$tripRecord === void 0 ? void 0 : _val$tripRecord.status))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(val === null || val === void 0 ? void 0 : (_val$tripRecord2 = val.tripRecord) === null || _val$tripRecord2 === void 0 ? void 0 : _val$tripRecord2.cargoType))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(val === null || val === void 0 ? void 0 : (_val$tripRecord3 = val.tripRecord) === null || _val$tripRecord3 === void 0 ? void 0 : (_val$tripRecord3$carg = _val$tripRecord3.cargoUnloading) === null || _val$tripRecord3$carg === void 0 ? void 0 : _val$tripRecord3$carg.toLocaleString()))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(" " + _vm._s(val !== null && val !== void 0 && (_val$tripRecord4 = val.tripRecord) !== null && _val$tripRecord4 !== void 0 && _val$tripRecord4.polArrivalTime ? _vm.moment(val === null || val === void 0 ? void 0 : (_val$tripRecord5 = val.tripRecord) === null || _val$tripRecord5 === void 0 ? void 0 : _val$tripRecord5.polArrivalTime, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
      staticClass: "text-truncate text-center"
    }, [_vm._v("-")]), _c('td', {
      staticClass: "text-truncate text-center"
    }, [_vm._v("-")]), _c('td', {
      staticClass: "text-truncate text-center"
    }, [_vm._v("-")]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(" " + _vm._s(val !== null && val !== void 0 && (_val$tripRecord6 = val.tripRecord) !== null && _val$tripRecord6 !== void 0 && _val$tripRecord6.polAnchorUp ? _vm.moment(val === null || val === void 0 ? void 0 : (_val$tripRecord7 = val.tripRecord) === null || _val$tripRecord7 === void 0 ? void 0 : _val$tripRecord7.polAnchorUp, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(" " + _vm._s(val !== null && val !== void 0 && (_val$tripRecord8 = val.tripRecord) !== null && _val$tripRecord8 !== void 0 && _val$tripRecord8.polBerthing ? _vm.moment(val === null || val === void 0 ? void 0 : (_val$tripRecord9 = val.tripRecord) === null || _val$tripRecord9 === void 0 ? void 0 : _val$tripRecord9.polBerthing, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(" " + _vm._s(val !== null && val !== void 0 && (_val$tripRecord10 = val.tripRecord) !== null && _val$tripRecord10 !== void 0 && _val$tripRecord10.polCommenced ? _vm.moment(val === null || val === void 0 ? void 0 : (_val$tripRecord11 = val.tripRecord) === null || _val$tripRecord11 === void 0 ? void 0 : _val$tripRecord11.polCommenced, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(" " + _vm._s(val !== null && val !== void 0 && (_val$tripRecord12 = val.tripRecord) !== null && _val$tripRecord12 !== void 0 && _val$tripRecord12.polCompleted ? _vm.moment(val === null || val === void 0 ? void 0 : (_val$tripRecord13 = val.tripRecord) === null || _val$tripRecord13 === void 0 ? void 0 : _val$tripRecord13.polCompleted, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
      staticClass: "text-truncate text-center"
    }, [_vm._v(_vm._s((_val$tripRecord$polDr = val === null || val === void 0 ? void 0 : (_val$tripRecord14 = val.tripRecord) === null || _val$tripRecord14 === void 0 ? void 0 : _val$tripRecord14.polDraughtSurvey) !== null && _val$tripRecord$polDr !== void 0 ? _val$tripRecord$polDr : 0))]), _c('td', {
      staticClass: "text-truncate text-center"
    }, [_vm._v("-")]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(" " + _vm._s(val !== null && val !== void 0 && (_val$tripRecord15 = val.tripRecord) !== null && _val$tripRecord15 !== void 0 && _val$tripRecord15.polCastOut ? _vm.moment(val === null || val === void 0 ? void 0 : (_val$tripRecord16 = val.tripRecord) === null || _val$tripRecord16 === void 0 ? void 0 : _val$tripRecord16.polCastOut, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(" " + _vm._s(val !== null && val !== void 0 && (_val$tripRecord17 = val.tripRecord) !== null && _val$tripRecord17 !== void 0 && _val$tripRecord17.polLhv ? _vm.moment(val === null || val === void 0 ? void 0 : (_val$tripRecord18 = val.tripRecord) === null || _val$tripRecord18 === void 0 ? void 0 : _val$tripRecord18.polLhv, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(" " + _vm._s(val !== null && val !== void 0 && (_val$tripRecord19 = val.tripRecord) !== null && _val$tripRecord19 !== void 0 && _val$tripRecord19.polDocOnBoard ? _vm.moment(val === null || val === void 0 ? void 0 : (_val$tripRecord20 = val.tripRecord) === null || _val$tripRecord20 === void 0 ? void 0 : _val$tripRecord20.polDocOnBoard, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(" " + _vm._s(val !== null && val !== void 0 && (_val$tripRecord21 = val.tripRecord) !== null && _val$tripRecord21 !== void 0 && _val$tripRecord21.polDeparture ? _vm.moment(val === null || val === void 0 ? void 0 : (_val$tripRecord22 = val.tripRecord) === null || _val$tripRecord22 === void 0 ? void 0 : _val$tripRecord22.polDeparture, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
      staticClass: "text-truncate text-center"
    }, [_vm._v("-")]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(" " + _vm._s(val !== null && val !== void 0 && (_val$tripRecord23 = val.tripRecord) !== null && _val$tripRecord23 !== void 0 && _val$tripRecord23.podArrivalTime ? _vm.moment(val === null || val === void 0 ? void 0 : (_val$tripRecord24 = val.tripRecord) === null || _val$tripRecord24 === void 0 ? void 0 : _val$tripRecord24.podArrivalTime, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
      staticClass: "text-truncate text-center"
    }, [_vm._v("-")]), _c('td', {
      staticClass: "text-truncate text-center"
    }, [_vm._v("-")]), _c('td', {
      staticClass: "text-truncate text-center"
    }, [_vm._v("-")]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(" " + _vm._s(val !== null && val !== void 0 && (_val$tripRecord25 = val.tripRecord) !== null && _val$tripRecord25 !== void 0 && _val$tripRecord25.podAnchorUp ? _vm.moment(val === null || val === void 0 ? void 0 : (_val$tripRecord26 = val.tripRecord) === null || _val$tripRecord26 === void 0 ? void 0 : _val$tripRecord26.podAnchorUp, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(" " + _vm._s(val !== null && val !== void 0 && (_val$tripRecord27 = val.tripRecord) !== null && _val$tripRecord27 !== void 0 && _val$tripRecord27.podBerthing ? _vm.moment(val === null || val === void 0 ? void 0 : (_val$tripRecord28 = val.tripRecord) === null || _val$tripRecord28 === void 0 ? void 0 : _val$tripRecord28.podBerthing, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(" " + _vm._s(val !== null && val !== void 0 && (_val$tripRecord29 = val.tripRecord) !== null && _val$tripRecord29 !== void 0 && _val$tripRecord29.podCommenced ? _vm.moment(val === null || val === void 0 ? void 0 : (_val$tripRecord30 = val.tripRecord) === null || _val$tripRecord30 === void 0 ? void 0 : _val$tripRecord30.podCommenced, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(" " + _vm._s(val !== null && val !== void 0 && (_val$tripRecord31 = val.tripRecord) !== null && _val$tripRecord31 !== void 0 && _val$tripRecord31.podCompleted ? _vm.moment(val === null || val === void 0 ? void 0 : (_val$tripRecord32 = val.tripRecord) === null || _val$tripRecord32 === void 0 ? void 0 : _val$tripRecord32.podCompleted, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
      staticClass: "text-truncate text-center"
    }, [_vm._v(_vm._s((_val$tripRecord$podDr = val === null || val === void 0 ? void 0 : (_val$tripRecord33 = val.tripRecord) === null || _val$tripRecord33 === void 0 ? void 0 : _val$tripRecord33.podDraughtSurvey) !== null && _val$tripRecord$podDr !== void 0 ? _val$tripRecord$podDr : 0))]), _c('td', {
      staticClass: "text-truncate text-center"
    }, [_vm._v("-")]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(" " + _vm._s(val !== null && val !== void 0 && (_val$tripRecord34 = val.tripRecord) !== null && _val$tripRecord34 !== void 0 && _val$tripRecord34.podCastOut ? _vm.moment(val === null || val === void 0 ? void 0 : (_val$tripRecord35 = val.tripRecord) === null || _val$tripRecord35 === void 0 ? void 0 : _val$tripRecord35.podCastOut, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(" " + _vm._s(val !== null && val !== void 0 && (_val$tripRecord36 = val.tripRecord) !== null && _val$tripRecord36 !== void 0 && _val$tripRecord36.podLhv ? _vm.moment(val === null || val === void 0 ? void 0 : (_val$tripRecord37 = val.tripRecord) === null || _val$tripRecord37 === void 0 ? void 0 : _val$tripRecord37.podLhv, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(" " + _vm._s(val !== null && val !== void 0 && (_val$tripRecord38 = val.tripRecord) !== null && _val$tripRecord38 !== void 0 && _val$tripRecord38.podDocOnBoard ? _vm.moment(val === null || val === void 0 ? void 0 : (_val$tripRecord39 = val.tripRecord) === null || _val$tripRecord39 === void 0 ? void 0 : _val$tripRecord39.podDocOnBoard, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(" " + _vm._s(val !== null && val !== void 0 && (_val$tripRecord40 = val.tripRecord) !== null && _val$tripRecord40 !== void 0 && _val$tripRecord40.podDeparture ? _vm.moment(val === null || val === void 0 ? void 0 : (_val$tripRecord41 = val.tripRecord) === null || _val$tripRecord41 === void 0 ? void 0 : _val$tripRecord41.podDeparture, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-') + " ")])]);
  }), 0) : _vm._e()])]), ((_vm$listTrips2 = _vm.listTrips) === null || _vm$listTrips2 === void 0 ? void 0 : _vm$listTrips2.length) <= 0 ? _c('div', {
    staticClass: "d-flex align-items-center justify-content-center w-100 my-2 mb-4"
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v("No data found. Select other plan.")])]) : _vm._e()])], 1)], 1)])], 1)], 1), _c('b-col', {
    staticClass: "mb-4 mt-0",
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center"
  }, [_c('b-button', {
    staticClass: "float-right mr-1",
    attrs: {
      "variant": "danger",
      "size": "sm",
      "type": "button"
    },
    on: {
      "click": _vm.closeSlideFormRealShipment
    }
  }, [_c('i', {
    staticClass: "fa fa-ban"
  }), _vm._v(" Cancel  ")]), _c('b-button', {
    staticClass: "float-right mr-1",
    attrs: {
      "variant": "primary",
      "size": "sm",
      "type": "submit"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onSubmit.apply(null, arguments);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-save"
  }), _vm._v(" Submit  ")])], 1)])], 1) : _c('div', {
    staticClass: "alert alert-info m-4"
  }, [_vm._v(" Loading... ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }