var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('section', {
    staticClass: "sign-in-page"
  }, [_c('b-container', {
    staticClass: "sign-in-page-bg mt-5 p-0"
  }, [_c('div', {
    staticClass: "row no-gutters"
  }, [_c('div', {
    staticClass: "col-md-6 text-center position-relative"
  }, [_c('div', {
    staticClass: "sign-in-detail text-white"
  }, [_c('a', {
    staticClass: "sign-in-logo mb-5",
    attrs: {
      "href": "#"
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": _vm.logo,
      "alt": "logo"
    }
  })]), _c('Slick', {
    attrs: {
      "option": _vm.slickOptions
    }
  }, [_c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "top"
  }, [_c('div', {
    staticClass: "boat"
  }, [_c('img', {
    attrs: {
      "src": require('../../assets/images/Km3FO09.png'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "waves"
  })]), _c('div', {
    staticClass: "bottom"
  }, [_c('div', {
    staticClass: "float"
  })]), _c('h4', {
    staticClass: "mb-1 text-white"
  }, [_vm._v("Manage your Tug Boat Operations")]), _c('p', [_vm._v("This system will help you to manage your Tug Boat Operations.")])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "top"
  }, [_c('div', {
    staticClass: "boat"
  }, [_c('img', {
    attrs: {
      "src": require('../../assets/images/Barge.png'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "waves"
  })]), _c('div', {
    staticClass: "bottom"
  }, [_c('div', {
    staticClass: "float"
  })]), _c('h4', {
    staticClass: "mb-1 text-white"
  }, [_vm._v("Manage your Barge Operations")]), _c('p', [_vm._v("This system will help you to manage your Tug Boat Operations.")])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "top"
  }, [_c('div', {
    staticClass: "boat"
  }, [_c('img', {
    attrs: {
      "src": require('../../assets/images/fleet/PusherTug.png'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "waves"
  })]), _c('div', {
    staticClass: "bottom"
  }, [_c('div', {
    staticClass: "float"
  })]), _c('h4', {
    staticClass: "mb-1 text-white"
  }, [_vm._v("Manage your Pusher Tug")]), _c('p', [_vm._v("This system will help you to manage your Pusher Tug Operations.")])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "top"
  }, [_c('div', {
    staticClass: "boat"
  }, [_c('img', {
    attrs: {
      "src": require('../../assets/images/fleet/PusherBarge.png'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "waves"
  })]), _c('div', {
    staticClass: "bottom"
  }, [_c('div', {
    staticClass: "float"
  })]), _c('h4', {
    staticClass: "mb-1 text-white"
  }, [_vm._v("Manage your Pusher Barge")]), _c('p', [_vm._v("This system will help you to manage your Pusher Barge Operations.")])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "top"
  }, [_c('div', {
    staticClass: "boat"
  }, [_c('img', {
    attrs: {
      "src": require('../../assets/images/fleet/MotherVessel2.png'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "waves"
  })]), _c('div', {
    staticClass: "bottom"
  }, [_c('div', {
    staticClass: "float"
  })]), _c('h4', {
    staticClass: "mb-1 text-white"
  }, [_vm._v("Manage your Mother Vessel")]), _c('p', [_vm._v("This system will help you to manage your Mother Vessel Operations.")])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "top3"
  }, [_c('div', {
    staticClass: "boat2"
  }, [_c('img', {
    attrs: {
      "src": require('../../assets/images/fleet/FloatingCrane.gif'),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "waves2"
  })]), _c('div', {
    staticClass: "bottom"
  }, [_c('div', {
    staticClass: "float2"
  })]), _c('h4', {
    staticClass: "mb-1 text-white"
  }, [_vm._v("Manage your Floating Crane")]), _c('p', [_vm._v("This system will help you to manage your Floating Crane Operations.")])]), _c('div', {
    staticClass: "item"
  }, [_c('div', {
    staticClass: "top-static"
  }, [_c('div', {
    staticClass: "boat-static"
  }, [_c('img', {
    staticStyle: {
      "margin-top": "-50px"
    },
    attrs: {
      "src": require('../../assets/images/fleet/HeavyEquipment.gif'),
      "alt": ""
    }
  })])]), _c('h4', {
    staticClass: "mb-1 text-white"
  }, [_vm._v("Manage your Heavy Equipment")]), _c('p', [_vm._v("This system will help you to manage your Heavy Equipment Operations.")])])])], 1)]), _c('div', {
    staticClass: "col-md-6 position-relative"
  }, [_c('div', {
    staticClass: "sign-in-from"
  }, [_c('router-view')], 1)])])])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }