var render = function render(){
  var _vm$list, _vm$list$data, _vm$list2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [!_vm.loading_real_shipment ? _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    staticClass: "float-right mr-1",
    attrs: {
      "variant": "primary",
      "size": "sm",
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.openSlideFormRealShipment('ADD', null);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v("   ADD REAL SHIPMENT")])], 1) : _vm._e(), !_vm.loading_real_shipment ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [[_c('div', {
    staticClass: "d-flex overflow-auto"
  }, [_c('table', {
    staticClass: "table table-bordered table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "background-color": "#E4EAA3"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("NO")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "200px",
      "background-color": "#E4EAA3"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("STATUS")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "350px",
      "background-color": "#E4EAA3"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("ID SHIPMENT PLAN")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "min-width": "250px",
      "background-color": "#E4EAA3"
    },
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("PLAN DATE")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "250px",
      "background-color": "#E4EAA3"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("PLAN QUANTITY")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "250px",
      "background-color": "#BDE5EB"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("FLEET")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "250px",
      "background-color": "#BDE5EB"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("CARGO TYPE")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "250px",
      "background-color": "#BDE5EB"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("CARGO LOADING")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "250px",
      "background-color": "#BDE5EB"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("CARGO UNLOADING")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "min-width": "250px",
      "background-color": "#BDE5EB"
    },
    attrs: {
      "colspan": "5"
    }
  }, [_vm._v("LOADING")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "min-width": "250px",
      "background-color": "#BDE5EB"
    },
    attrs: {
      "colspan": "5"
    }
  }, [_vm._v("UNLOADING")]), _c('th', {
    staticClass: "text-right",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "250px",
      "background-color": "#BDE5EB"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("PRICE")]), _c('th', {
    staticClass: "text-right",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "250px",
      "background-color": "#BDE5EB"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("PRICE TO VENDOR")]), _c('th', {
    staticClass: "text-center bg-warning border-dark",
    staticStyle: {
      "vertical-align": "middle",
      "width": "5px",
      "position": "sticky",
      "right": "0",
      "z-index": "1"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("ACTION")])]), _c('tr', [_c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#E4EAA3"
    }
  }, [_vm._v("ETA POL")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#E4EAA3"
    }
  }, [_vm._v("ETA POD")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("POL")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("TA POL / READY")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("BERTHING")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("COMMENCED")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("COMPLETED")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("POD")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("TA POD / READY")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("BERTHING")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("COMMENCED")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "background-color": "#BDE5EB"
    }
  }, [_vm._v("COMPLETED")])])]), ((_vm$list = _vm.list) === null || _vm$list === void 0 ? void 0 : (_vm$list$data = _vm$list.data) === null || _vm$list$data === void 0 ? void 0 : _vm$list$data.length) > 0 ? _c('tbody', [_vm._l((_vm$list2 = _vm.list) === null || _vm$list2 === void 0 ? void 0 : _vm$list2.data, function (state, index) {
    var _state$project_plan, _state$dataTrip, _state$dataTrip$tripR, _state$dataTrip2, _state$dataTrip2$trip, _state$project_plan2, _state$project_plan2$, _state$dataTrip3, _state$dataTrip4, _state$dataTrip4$trip, _state$dataTrip5, _state$dataTrip5$trip, _state$dataTrip5$trip2, _state$dataTrip6, _state$dataTrip6$trip, _state$dataTrip6$trip2, _state$dataTrip7, _state$dataTrip7$trip, _state$dataTrip7$trip2, _state$dataTrip8, _state$dataTrip8$trip, _state$dataTrip9, _state$dataTrip9$trip, _state$dataTrip10, _state$dataTrip10$tri, _state$dataTrip11, _state$dataTrip11$tri, _state$dataTrip12, _state$dataTrip12$tri, _state$dataTrip12$tri2, _state$dataTrip13, _state$dataTrip13$tri, _state$dataTrip14, _state$dataTrip14$tri, _state$dataTrip15, _state$dataTrip15$tri, _state$dataTrip16, _state$dataTrip16$tri, _state$price, _state$price_to_vendo;
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(index + 1))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.status))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$project_plan = state.project_plan) === null || _state$project_plan === void 0 ? void 0 : _state$project_plan.shipment_plan_detail_number))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$dataTrip = state.dataTrip) === null || _state$dataTrip === void 0 ? void 0 : (_state$dataTrip$tripR = _state$dataTrip.tripRecord) === null || _state$dataTrip$tripR === void 0 ? void 0 : _state$dataTrip$tripR.startDate))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$dataTrip2 = state.dataTrip) === null || _state$dataTrip2 === void 0 ? void 0 : (_state$dataTrip2$trip = _state$dataTrip2.tripRecord) === null || _state$dataTrip2$trip === void 0 ? void 0 : _state$dataTrip2$trip.endDate))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$project_plan2 = state.project_plan) === null || _state$project_plan2 === void 0 ? void 0 : (_state$project_plan2$ = _state$project_plan2.plan_quantity) === null || _state$project_plan2$ === void 0 ? void 0 : _state$project_plan2$.toLocaleString()))]), _c('td', {
      staticClass: "text-truncate"
    }, [_c('div', {
      staticClass: "d-flex w-100"
    }, _vm._l(state === null || state === void 0 ? void 0 : (_state$dataTrip3 = state.dataTrip) === null || _state$dataTrip3 === void 0 ? void 0 : _state$dataTrip3.vehicle, function (obj, objIndex) {
      return _c('b-badge', {
        key: objIndex,
        staticClass: "border border-primary text-primary text-uppercase mr-1",
        attrs: {
          "variant": "none"
        }
      }, [_vm._v(_vm._s(obj === null || obj === void 0 ? void 0 : obj.name))]);
    }), 1)]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$dataTrip4 = state.dataTrip) === null || _state$dataTrip4 === void 0 ? void 0 : (_state$dataTrip4$trip = _state$dataTrip4.tripRecord) === null || _state$dataTrip4$trip === void 0 ? void 0 : _state$dataTrip4$trip.cargoType))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$dataTrip5 = state.dataTrip) === null || _state$dataTrip5 === void 0 ? void 0 : (_state$dataTrip5$trip = _state$dataTrip5.tripRecord) === null || _state$dataTrip5$trip === void 0 ? void 0 : (_state$dataTrip5$trip2 = _state$dataTrip5$trip.cargoLoading) === null || _state$dataTrip5$trip2 === void 0 ? void 0 : _state$dataTrip5$trip2.toLocaleString()))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$dataTrip6 = state.dataTrip) === null || _state$dataTrip6 === void 0 ? void 0 : (_state$dataTrip6$trip = _state$dataTrip6.tripRecord) === null || _state$dataTrip6$trip === void 0 ? void 0 : (_state$dataTrip6$trip2 = _state$dataTrip6$trip.cargoUnloading) === null || _state$dataTrip6$trip2 === void 0 ? void 0 : _state$dataTrip6$trip2.toLocaleString()))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$dataTrip7 = state.dataTrip) === null || _state$dataTrip7 === void 0 ? void 0 : (_state$dataTrip7$trip = _state$dataTrip7.tripRecord) === null || _state$dataTrip7$trip === void 0 ? void 0 : (_state$dataTrip7$trip2 = _state$dataTrip7$trip.portLoading) === null || _state$dataTrip7$trip2 === void 0 ? void 0 : _state$dataTrip7$trip2.portName))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$dataTrip8 = state.dataTrip) === null || _state$dataTrip8 === void 0 ? void 0 : (_state$dataTrip8$trip = _state$dataTrip8.tripRecord) === null || _state$dataTrip8$trip === void 0 ? void 0 : _state$dataTrip8$trip.polArrivalTime))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$dataTrip9 = state.dataTrip) === null || _state$dataTrip9 === void 0 ? void 0 : (_state$dataTrip9$trip = _state$dataTrip9.tripRecord) === null || _state$dataTrip9$trip === void 0 ? void 0 : _state$dataTrip9$trip.polBerthing))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$dataTrip10 = state.dataTrip) === null || _state$dataTrip10 === void 0 ? void 0 : (_state$dataTrip10$tri = _state$dataTrip10.tripRecord) === null || _state$dataTrip10$tri === void 0 ? void 0 : _state$dataTrip10$tri.polCommenced))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$dataTrip11 = state.dataTrip) === null || _state$dataTrip11 === void 0 ? void 0 : (_state$dataTrip11$tri = _state$dataTrip11.tripRecord) === null || _state$dataTrip11$tri === void 0 ? void 0 : _state$dataTrip11$tri.polCompleted))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$dataTrip12 = state.dataTrip) === null || _state$dataTrip12 === void 0 ? void 0 : (_state$dataTrip12$tri = _state$dataTrip12.tripRecord) === null || _state$dataTrip12$tri === void 0 ? void 0 : (_state$dataTrip12$tri2 = _state$dataTrip12$tri.portDischarge) === null || _state$dataTrip12$tri2 === void 0 ? void 0 : _state$dataTrip12$tri2.portName))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$dataTrip13 = state.dataTrip) === null || _state$dataTrip13 === void 0 ? void 0 : (_state$dataTrip13$tri = _state$dataTrip13.tripRecord) === null || _state$dataTrip13$tri === void 0 ? void 0 : _state$dataTrip13$tri.podArrivalTime))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$dataTrip14 = state.dataTrip) === null || _state$dataTrip14 === void 0 ? void 0 : (_state$dataTrip14$tri = _state$dataTrip14.tripRecord) === null || _state$dataTrip14$tri === void 0 ? void 0 : _state$dataTrip14$tri.podBerthing))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$dataTrip15 = state.dataTrip) === null || _state$dataTrip15 === void 0 ? void 0 : (_state$dataTrip15$tri = _state$dataTrip15.tripRecord) === null || _state$dataTrip15$tri === void 0 ? void 0 : _state$dataTrip15$tri.podCommenced))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$dataTrip16 = state.dataTrip) === null || _state$dataTrip16 === void 0 ? void 0 : (_state$dataTrip16$tri = _state$dataTrip16.tripRecord) === null || _state$dataTrip16$tri === void 0 ? void 0 : _state$dataTrip16$tri.podCompleted))]), _c('td', {
      staticClass: "text-right"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$price = state.price) === null || _state$price === void 0 ? void 0 : _state$price.toLocaleString()))]), _c('td', {
      staticClass: "text-right"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$price_to_vendo = state.price_to_vendor) === null || _state$price_to_vendo === void 0 ? void 0 : _state$price_to_vendo.toLocaleString()))]), _c('td', {
      staticClass: "text-center bg-warning border-dark",
      staticStyle: {
        "width": "5px",
        "position": "sticky",
        "right": "0",
        "z-index": "1"
      }
    }, [_c('b-dropdown', {
      attrs: {
        "size": "lg",
        "variant": "link iq-bg-primary iq-border-radius-10",
        "toggle-class": "text-decoration-none",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function fn() {
          return [_c('span', {
            staticClass: "dropdown-toggle p-0",
            attrs: {
              "id": "dropdownMenuButton5",
              "data-toggle": "dropdown"
            }
          }, [_c('i', {
            staticClass: "fa fa-pencil-square-o m-0 text-primary"
          })])];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": "primary"
      },
      on: {
        "click": _vm.openSlideFormRealShipment
      }
    }, [_c('i', {
      staticClass: "fa fa-eye mr-2"
    }), _vm._v("Detail ")]), _c('b-dropdown-item', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal.add-price",
        modifiers: {
          "add-price": true
        }
      }],
      attrs: {
        "href": "#",
        "variant": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.onAddPrice(state === null || state === void 0 ? void 0 : state.id);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-pen mr-2"
    }), _vm._v("Add Price ")]), _c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": "warning"
      }
    }, [_c('i', {
      staticClass: "fa fa-envelope mr-2"
    }), _vm._v("Change Status ")])], 1)], 1)]);
  })], 2) : _vm._e()])])], _vm.list.data.length > 0 ? _c('div', {
    staticClass: "mt-3"
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.list.meta.totalItem,
      "per-page": _vm.list.meta.perPage,
      "first-number": "",
      "align": "center"
    },
    on: {
      "change": _vm.getListRealShipment
    },
    model: {
      value: _vm.list.meta.page,
      callback: function callback($$v) {
        _vm.$set(_vm.list.meta, "page", $$v);
      },
      expression: "list.meta.page"
    }
  })], 1) : _c('div', {
    staticClass: "d-flex align-items-center justify-content-center w-100 my-2 mb-4"
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v("No Data Real Shipment.")])])], 2) : _vm._e(), _vm.loading_real_shipment ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "alert alert-info m-2"
  }, [_vm._v(" Loading... ")])]) : _vm._e(), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "slideFormRealShipment",
      "title": "Create Real Shipment",
      "width": "100%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _vm$projectData;
        var hide = _ref.hide;
        return [_c('SlideFormRealShipment', {
          attrs: {
            "closeSlideFormRealShipment": _vm.closeSlideFormRealShipment,
            "refreshData": _vm.refreshData,
            "optionsFleets": _vm.optionsFleets,
            "projectId": (_vm$projectData = _vm.projectData) === null || _vm$projectData === void 0 ? void 0 : _vm$projectData.id,
            "action": _vm.action
          }
        })];
      }
    }]),
    model: {
      value: _vm.slideFormRealShipment,
      callback: function callback($$v) {
        _vm.slideFormRealShipment = $$v;
      },
      expression: "slideFormRealShipment"
    }
  })], 1), _c('b-modal', {
    attrs: {
      "id": "add-price",
      "size": "md",
      "title": "Add Price",
      "cancel-title": "Close",
      "hide-footer": ""
    }
  }, [_c('form', {
    attrs: {
      "action": "#",
      "method": "post"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.savePrice.apply(null, arguments);
      }
    }
  }, [_c('b-row', [_c('b-form-group', {
    staticClass: "col-sm-12",
    attrs: {
      "label": "Price"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "name": "price",
      "placeholder": "0.00"
    },
    model: {
      value: _vm.formPrice.price,
      callback: function callback($$v) {
        _vm.$set(_vm.formPrice, "price", $$v);
      },
      expression: "formPrice.price"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.formatCurrency(_vm.formPrice.price)))])], 1), _c('b-form-group', {
    staticClass: "col-sm-12",
    attrs: {
      "label": "Price to Vendor"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "name": "price_to_vendor",
      "placeholder": "0.00"
    },
    model: {
      value: _vm.formPrice.price_to_vendor,
      callback: function callback($$v) {
        _vm.$set(_vm.formPrice, "price_to_vendor", $$v);
      },
      expression: "formPrice.price_to_vendor"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.formatCurrency(_vm.formPrice.price_to_vendor)))])], 1), _c('b-col', {
    attrs: {
      "md": '3'
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "secondary",
      "block": "",
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.$bvModal.hide('add-price');
      }
    }
  }, [_vm._v("Close")])], 1), _c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "block": "",
      "type": "submit"
    }
  }, [_c('span', [_vm._v("Save")])])], 1)], 1)], 1)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }