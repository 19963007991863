<template>
  <ApexChart
    :element="`chart-breakdown-percentage-${element}`"
    :chartOption="chartFleetBreakdown"
  />
</template>
<script>
export default {
  props: {
    series: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    element: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      chartFleetBreakdown: {
        chart: {
          type: 'line',
          height: 300,
          zoom: {
            enabled: false,
          },
        },
        legend: {
          show: true,
          showForSingleSeries: true,
        },
        series: this.series,
        stroke: {
          curve: 'straight',
          width: [3],
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          categories: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ],
        },
        colors: ['#FF0000'],
        yaxis: {
          labels: {
            formatter: (val) => {
              return val // memformat label sumbu y menjadi persentase
            },
          },
          max: 100, // set nilai maksimum pada sumbu y
          tickAmount: 5,
        },
        fill: {
          opacity: 2,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + '%'
            },
          },
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
      },
    }
  },
}
</script>
