var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "iq-edit-profile d-flex",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-2 p-0",
          attrs: {
            "active": true,
            "href": "#checklist",
            "title": "CHECKLIST"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-2 p-0",
          attrs: {
            "active": false,
            "href": "#question-type",
            "title": "QUESTION TYPE"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-2 p-0",
          attrs: {
            "active": false,
            "href": "#cargo-type",
            "title": "CARGO TYPE"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-2 p-0",
          attrs: {
            "active": false,
            "href": "#contract-type",
            "title": "CONTRACT TYPE"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-2 p-0",
          attrs: {
            "active": false,
            "href": "#trip-distance",
            "title": "TRIP DISTANCE"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-2 p-0",
          attrs: {
            "active": false,
            "href": "#project-terms",
            "title": "PROJECT TERMS"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "pills-tabContent-2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "checklist"
    }
  }, [_c('MasterChecklist')], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "question-type"
    }
  }, [_c('MasterQuestionType')], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "cargo-type"
    }
  }, [_c('MasterCargoType')], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "contract-type"
    }
  }, [_c('MasterContractType')], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "trip-distance"
    }
  }, [_c('MasterTripDistance')], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "project-terms"
    }
  }, [_c('MasterProjectTerms')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }