var render = function render(){
  var _vm$total;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-100",
    staticStyle: {
      "margin-bottom": "2rem"
    },
    attrs: {
      "md": "6",
      "xl": "3"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center bg-white p-3 shadow-sm card-certificate h-full cursor-pointer"
  }, [_c('div', {
    staticClass: "card-icon mr-2",
    style: "background: ".concat(_vm.bgIcon, " ;")
  }, [_vm._t("icon")], 2), _c('div', {
    staticClass: "text-left"
  }, [_c('h5', {
    staticClass: "line-height mb-1"
  }, [_c('strong', [_vm._v(_vm._s((_vm$total = _vm.total) !== null && _vm$total !== void 0 ? _vm$total : '-'))])]), _c('h5', {}, [_vm._v(" " + _vm._s(_vm.title) + " ")])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }