<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <b-row class="mb-2">
          <b-col lg="12">
            <div class="d-flex w-100 align-items-center justify-content-end">
              <b-button variant="danger" class="mr-2" @click="openSlideCreateWhatsapp"> 
                <i class="fa fa-ban mr-2"></i> Discard
              </b-button>
              <b-button variant="primary">
                <i class="fa fa-check"></i> Save
              </b-button>
            </div>
          </b-col>
        </b-row>
        <div class="border w-100 d-flex my-4"></div>
        <b-row class="mb-2">
          <b-col lg="12">
            <div class="d-flex align-items-center justify-content-start w-100">
              <b-button variant="success" class="mb-2"> 
                Test Template
              </b-button>
            </div>
          </b-col>
          <b-col lg="6">
            <b-form-group label="Name">
              <b-form-input placeholder="Type Text"></b-form-input>
            </b-form-group>
            <b-form-group label="Message">
              <b-form-input placeholder="Type Text"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group label="Broadcast Template">
              <b-form-input placeholder="Type Text"></b-form-input>
            </b-form-group>
            <b-form-group label="Namespace Content"></b-form-group>
          </b-col>
        </b-row>
        <div class="border w-100 d-flex my-4"></div>
        <b-row class="mb-2">
          <b-col lg="12">
            <b-row>
              <b-col lg="12" >
                <div class="">
                  <tab-nav :pills="true" class=" d-flex">
                    <tab-nav-items
                      class="p-0"
                      :active="true"
                      href="#variables"
                      title="VARIABLES"
                    />
                  </tab-nav>
                </div>
              </b-col>
              <b-col lg="12"><div class="border w-100 d-flex my-4"></div></b-col>
              <b-col lg="12">
                <div id="pills-tabContent-2" class="tab-content">
                  <tab-content-item :active="true" id="content">
                    <div>
                      <table class="table table-striped table-hover">
                        <thead>
                          <tr>
                            <th>NAME</th>
                            <th>DESCRIPTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr style="cursor: pointer;" @click="$nextTick(() => {$bvModal.show('preview-wa')})">
                            <td>${applicant_name}</td>
                            <td>Applicant Name</td>
                          </tr>
                          <tr style="cursor: pointer;" @click="$nextTick(() => {$bvModal.show('preview-wa')})">
                            <td>${survey_url}</td>
                            <td>Survey or Test URL</td>
                          </tr>
                          <tr style="cursor: pointer;" @click="$nextTick(() => {$bvModal.show('preview-wa')})">
                            <td>${survey_name}</td>
                            <td>Survey or Test Name</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="border w-100 d-flex my-2"></div>
                    <div class="d-flex align-items-center justify-content-between w-100">
                      <div class="d-flex">
                        <b-button variant="primary" class="mr-2" size="sm">Send Message</b-button>
                        <b-button variant="primary" class="mr-2" size="sm">Log Note</b-button>
                        <b-button variant="primary" class="mr-2" size="sm">Schedule activity</b-button>
                      </div>
                      <div class="d-flex">
                        <div class="mr-4 text-dark"><i class="fa fa-paperclip "></i> 0</div>
                        <div class="mr-4 text-dark">Follow</div>
                        <div class="text-dark"><i class="fa fa-user"></i> 0</div>
                      </div>
                    </div>
                    <div class="border w-100 d-flex my-2"></div>
                    <div class="d-flex align-items-center justify-content-center w-100">
                      <strong>Today</strong> 
                    </div>
                    <div class="d-flex align-items-center">
                      <b-avatar class="mr-2"></b-avatar>
                      <div class="d-flex flex-column">
                        <strong class="text-muted">Demo Trans Energy Logistik <small>- 2 hours ago</small></strong>
                        <span>Creating a new record...</span>
                      </div>
                    </div>
                    <div class="border w-100 d-flex"></div>
                  </tab-content-item>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>

      <!-- MODAL PREVIEW -->
      <b-modal
        id="preview-wa"
        size="xl"
        title="Open: Wa Variable"
        cancel-title="Close"
        hide-footer
      >
        <b-row>
          <b-col lg="6">
            <b-form-group label="Wa">
              <b-form-input placeholder="Type Text"></b-form-input>
            </b-form-group>
            <b-form-group label="Description">
              <b-form-input placeholder="Applicant Name"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group label="Name">
              <b-form-input placeholder="${applicant_name}"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col lg="12"><div class="border w-100 d-flex my-4"></div></b-col>
          <b-col lg="12">
            <div class="d-flex">
              <b-button variant="success" class="mr-2" size="sm">Save & Close</b-button>
              <b-button variant="success" class="mr-2" size="sm">Save & New</b-button>
              <b-button variant="dark" class="mr-2" size="sm" @click="$nextTick(() => {$bvModal.hide('preview-wa')})">Discard</b-button>
            </div>
          </b-col>
        </b-row>
      </b-modal>
      <!-- MEDICAL CHECKUP END -->
    </b-row>
  </b-container>
</template>

<script>
  import _ from 'lodash'
  import moment from 'moment'
  import { projectsActions, companiesActions, fleetsActions, busdevActions, crewingActions } from '@src/Utils/helper'

  export default {
    name:'SlideFormCreateWhatsapp',
    props:{
      openSlideCreateWhatsapp: {
        type:Function,
        default: true
      }
    },
    components:{
     
    },
    data(){
      return{

      }
    },
    mounted(){},
    methods:{
    }
  }
</script>
