var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ApexChart', {
    attrs: {
      "element": "chart-breakdown-vs-availability-".concat(_vm.element),
      "chartOption": _vm.chartFleetBreakdown
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }