<template>
  <b-row class="m-4">
    <b-col md="12" v-if="data">
      <b-row class="mt-2">
        <b-col md="12" class="text-right">
          <b-button variant="primary" @click="onExport(inspectionChecklistData.id, 'pdf')" :disabled="exporting">
            <span
              v-if="exporting"
              class="spinner-border spinner-border-sm"
            />
            <i v-else class="fa fa-file mr-2" /> 
            Export PDF 
          </b-button>
        </b-col>
        <b-col md="12" >
          <div class="border my-2 mx-3 w-100"></div>
          <b-row>
            <b-col md="12">
              <b-form-group label="Title">
                <b-form-input v-if="isAddInspectionChecklist" v-model="form.title" type="text" placeholder="Type Text" required  />
                <strong v-else>{{ data?.title }}</strong>
              </b-form-group>
            </b-col>
            <template v-if="isAddInspectionChecklist">
              <b-col md="6" v-for="(state, index) in data?.field" :key="index">
                <b-form-group :label="state?.label">
                  <b-form-input v-model="state.value" type="number" placeholder="Type Number" v-if="state.type === 'Numeric Type'" :required="state?.required" />
                  <b-form-input v-model="state.value" placeholder="Type Text" v-if="state.type === 'Text Type'" :required="state?.required" />
                  <b-form-textarea v-model="state.value" placeholder="Type Text" v-if="state.type === 'Textarea'" :required="state?.required" />
                  <b-form-input v-model="state.value"  type="date" v-if="state.type === 'Date'" :required="state?.required" />
                  <b-form-input v-model="state.value" type="datetime-local" v-if="state.type === 'Datetime'" :required="state?.required" />
                  <b-form-checkbox v-model="state.value" name="check-button" switch v-if="state.type === 'Boolean Type'">Yes / No </b-form-checkbox>
                </b-form-group>
              </b-col>
            </template>
            <template v-else>
              <b-col md="6" v-for="(state, index) in data?.field" :key="index">
                <b-form-group :label="state?.label">
                  <strong>{{ handleTypeDate(state?.value) }}</strong>
                </b-form-group>
              </b-col>
            </template>
          </b-row>
        </b-col>
        <div class="border my-2 mx-3 w-100"></div>
        
        <b-col md="12">
          <fieldset class="form-group border p-3">
            <legend class="w-auto px-2 text-primary">Question Type Content</legend>
            <div v-if="data.question_content.length > 0" class="w-100">
              <table class="table table-striped" v-for="(parentQuestion, parentQuestionIndex) in data.question_content" :key="parentQuestionIndex">
                <thead>
                  <tr v-if="!parentQuestion.parent_id && parentQuestion?.inspection_checklist_question?.length <= 0">
                    <th colspan="6" class="align-middle text-center">{{ parentQuestion?.name }}</th>
                  </tr>
                  <tr v-else>
                    <th style="max-width:20px">No</th>
                    <th>{{parentQuestion?.name }}</th>
                    <template v-if="Array.isArray(data?.question_type_content)">
                      <th v-for="(questionType, questionTypeIndex) in data?.question_type_content" :key="questionTypeIndex">
                        {{ questionType?.label }}
                      </th>
                    </template>
                    <template v-else>
                      <th v-if="['date', 'single-lines-text-box', 'datetime', 'numerical-value'].includes(data.question_type.code)">{{ data?.question_type_content?.label }}</th>
                    </template>
                    <th v-if="data.using_remarks">Remarks</th>
                  </tr>
                </thead>
                <tbody v-if="data?.question_content?.length > 0">
                  <tr v-for="(question, questionIndex) in parentQuestion?.inspection_checklist_question" :key="questionIndex">
                    <td style="max-width:20px">{{ questionIndex + 1 }}</td>
                    <td>{{ question.question }}</td>
                    <template v-if="data.question_type.code == 'multiple-choice-multiple-answers-allowed'">
                      <td v-for="(questionType, questionTypeIndex) in data?.question_type_content" :key="questionTypeIndex">
                        <b-form-checkbox v-if="isAddInspectionChecklist" v-model="question.answer[questionTypeIndex].value" :value="questionType?.label" :unchecked-value="''" />
                        <span v-else>{{ question.answer?.[questionTypeIndex]?.value ? '✔️' : '' }}</span>
                      </td>
                    </template>
                    <template v-if="data.question_type.code == 'multiple-choice-only-one-answer'">
                      <td v-for="(questionType, questionTypeIndex) in data?.question_type_content" :key="questionTypeIndex">
                        <b-form-checkbox v-if="isAddInspectionChecklist" v-model="question.answer[questionTypeIndex].value" :value="questionType?.label" :unchecked-value="''" />
                        <span v-else>{{ question.answer?.[questionTypeIndex]?.value ? '✔️' : '' }}</span>
                      </td>
                    </template>
                    <template v-if="['single-lines-text-box'].includes(data.question_type.code)">
                      <td>
                        <b-form-input v-if="isAddInspectionChecklist" v-model="question.answer" placeholder="Type Text" />
                        <span v-else>{{ handleTypeDate(question?.answer) }}</span>
                      </td>
                    </template>
                    <template v-if="['multiple-lines-text-box'].includes(data.question_type.code)">
                      <td v-for="(questionType, questionTypeIndex) in data?.question_type_content" :key="questionTypeIndex">
                        <b-form-input v-if="isAddInspectionChecklist" v-model="question.answer[questionTypeIndex].value" placeholder="Type Text" />
                        <span v-else>{{ handleTypeDate(question.answer[questionTypeIndex].value) }}</span>
                      </td>
                    </template>
                    <template v-if="['numerical-value'].includes(data.question_type.code)">
                      <td>
                        <b-form-input v-if="isAddInspectionChecklist" v-model="question.answer" type="number" placeholder="Type Number" />
                        <span v-else>{{ handleTypeDate(question.answer) }}</span>
                      </td>
                    </template>
                    <template v-if="['date'].includes(data.question_type.code)">
                      <td>
                        <b-form-input v-if="isAddInspectionChecklist" v-model="question.answer" type="date" />
                        <span v-else>{{ handleTypeDate(question.answer) }}</span>
                      </td>
                    </template>
                    <template v-if="['datetime'].includes(data.question_type.code)">
                      <td>
                        <b-form-input v-if="isAddInspectionChecklist" v-model="question.answer" type="datetime-local" />
                        <span v-else>{{ handleTypeDate(question.answer) }}</span>
                      </td>
                    </template>
                    <td v-if="data.using_remarks">
                      <b-form-textarea v-if="isAddInspectionChecklist" v-model="question.remarks" />
                      <span v-else>{{ handleTypeDate(question?.remarks) }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </fieldset>
        </b-col>

        <div class="border my-2 mx-3 w-100"></div>

        <b-col md="12" class="mt-2">
          <b-row>
            <template v-if="isAddInspectionChecklist">
              <b-col md="3" v-for="(state, index) in data?.signature" :key="index">
                <b-form-group :label="state?.label">
                  <b-form-input v-model="state.value" type="number" placeholder="Type Number" v-if="state.type === 'Numeric Type'" :required="state?.required" />
                  <b-form-input v-model="state.value" placeholder="Type Text" v-if="state.type === 'Text Type'" :required="state?.required" />
                  <b-form-textarea v-model="state.value" placeholder="Type Text" v-if="state.type === 'Textarea'" :required="state?.required" />
                  <b-form-input v-model="state.value" type="date" v-if="state.type === 'Date'" :required="state?.required" />
                  <b-form-input v-model="state.value" type="datetime-local" v-if="state.type === 'Datetime'" :required="state?.required" />
                  <b-form-checkbox v-model="state.value" name="check-button" switch v-if="state.type === 'Boolean Type'" >Yes / No </b-form-checkbox>
                </b-form-group>
              </b-col>
            </template>
            <template v-else>
              <b-col md="3" v-for="(state, index) in data?.signature" :key="index">
                <b-form-group :label="state?.label">
                  <strong>{{ handleTypeDate(state?.value) }}</strong>
                </b-form-group>
              </b-col>
            </template>
          </b-row>
        </b-col>
      </b-row>
    </b-col>

    <b-col md="12" v-if="data && isAddInspectionChecklist">
      <div class="d-flex align-items-center justify-content-end w-100">
        <b-button
          block
          variant="danger"
          type="button"
          style="width:15%"
          class="mr-4 mt-2"
          @click="isAddInspectionChecklist = false"
        >
          <i class="fa fa-remove"></i>
          <span>Cancel</span>
        </b-button>

        <b-button
          :disabled="saving"
          block
          variant="primary"
          type="button"
          style="width:20%"
          @click.prevent="onSave"
        >
          <span
            v-if="saving"
            class="spinner-border spinner-border-sm"
          />
          <div v-else>
            <i class="fa fa-save"></i>
            <span>Submit Data</span>
          </div>
        </b-button>
      </div>
    </b-col>
    <b-col lg="12" v-if="!data">
      <span>No Project Forms Data.</span>
    </b-col>
  </b-row>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { projectsActions, companiesActions, fleetsActions, busdevActions, crewingActions } from '@src/Utils/helper'

export default {
  name: 'SlideInspectionChecklist',
  props:{
    isAddInspectionChecklist:{
      type:Boolean,
      default:false
    },
    isPreviewInspectionChecklist:{
      type:Boolean,
      default:false
    }, 
    inspectionChecklistData:{
      type:Object,
      default:null
    }, 
    refreshSlideForms:{
      type:Function,
      default:null
    },
  },
  data() {
    return {
      saving: false, 
      exporting: false, 
      
      data: null, 
      form:{
        vehicle_id: null,
        collection_of_forms_id: null,
        title: '',
      },
 
    }
  },
  async mounted () {

  },
  computed: {},
  watch:{
    'inspectionChecklistData.id': {
      handler(n, o) {
        this.modifyData()
      },
      immediate: true,
      deep: true,
    },
  },
  methods:{
    ...projectsActions, 
    ...companiesActions, 
    ...fleetsActions, 
    ...busdevActions, 
    ...crewingActions,
    handleTypeDate(value){
      if(this.validDate(value)){
        return moment(value).format('DD MMMM YYYY')
      } else if (this.validDateTime(value)){
        return moment(value).format('HH:mm A')
      } else {
        return value
      }
    },
    validDate(val, format = 'YYYY-MM-DD'){
      return moment(val, format, true).isValid();
    },  
    validDateTime(val, format = 'YYYY-MM-DDTHH:mm'){
      return moment(val, format, true).isValid();
    },  
    modifyQuestionsValue(oldVal, questionType, content){
      let newVal = oldVal
      if(questionType === 'multiple-lines-text-box'){
        newVal.map(val => {
          if(val.inspection_checklist_question.length > 0){
            val.inspection_checklist_question.map(obj => {
              obj.remarks = ''
              obj.answer = content.map(() => {
                  return {
                    value: ''
                  }
                })
              return obj
            })
          }
          return val
        })
      } else if(questionType === 'single-lines-text-box'){
        newVal.map(val => {
          if(val.inspection_checklist_question.length > 0){
            val.inspection_checklist_question.map(obj => {
              obj.answer = ''
              obj.remarks = ''
              return obj
            })
          }
          return val
        })
      } else if(questionType === 'numerical-value'){
        newVal.map(val => {
          if(val.inspection_checklist_question.length > 0){
            val.inspection_checklist_question.map(obj => {
              obj.answer = ''
              obj.remarks = ''
              return obj
            })
          }
          
          return val
        })
      } else if(questionType === 'date'){
        newVal.map(val => {
          if(val.inspection_checklist_question.length > 0){
            val.inspection_checklist_question.map(obj => {
              obj.answer = ''
              obj.remarks = ''
              return obj
            })
          }
          return val
        })
      } else if(questionType === 'datetime'){
        newVal.map(val => {
          if(val.inspection_checklist_question.length > 0){
            val.inspection_checklist_question.map(obj => {
              obj.answer = ''
              obj.remarks = ''
              return obj
            })
          }
          return val
        })
      } else if(questionType === 'multiple-choice-only-one-answer'){
        newVal = newVal.map(val => {
          if(val.inspection_checklist_question.length > 0){
            val.inspection_checklist_question.map(obj => {
              obj.answer = content.map((s,i) => {
                  return {
                    index: i,
                    value: ''
                  }
                })
              obj.remarks = ''
              return obj
            })
          }
          
          return val
        })
      } else if(questionType === 'multiple-choice-multiple-answers-allowed'){ 
        newVal.map(val => {
          if(val.inspection_checklist_question.length > 0){
            val.inspection_checklist_question.map(obj => {
              obj.remarks = ''
              obj.answer = content.map((s,i) => {
                  return {
                    index: i,
                    value: ''
                  }
                })
              return obj
            })
          }
          return val
        })
      }

      return newVal
    },
    modifyData(){
      if(this.isPreviewInspectionChecklist){
        let tempData = this.inspectionChecklistData

        this.form.title = tempData.title
        this.form.collection_of_forms_id = tempData?.id
        this.form.vehicle_id = tempData?.vehicle_id
      
        this.data = tempData
      } else if (this.isAddInspectionChecklist) {
        let tempData  = this.inspectionChecklistData

        console.log(tempData, "WHILE ADD")

        tempData.question_type_content = tempData.question_type_content
        tempData.signature = tempData.signature.map(val => {return {...val, value:''}})
        tempData.field = tempData.field.map(val => {return {...val, value:''}})
        tempData.question_content = this.modifyQuestionsValue(tempData.collection_of_forms_category, tempData.question_type.code, tempData.question_type_content)

        this.form.title = `${tempData?.vehicle_type_name} - Inspection Report`
        this.form.collection_of_forms_id = tempData.id
        this.form.vehicle_id = tempData.vehicle_id

        this.data = tempData
      } else {
        this.data = null
      }
    },
    resetForm(){
      this.form = {
        vehicle_id: null,
        collection_of_forms_id: null,
        title: '',
      }
    },

    async onExport(id, fileType){
      this.exporting = true
      console.log(id, fileType,"is clickerd?")
      const res = await this.exportInspectionChecklist({id, fileType})

      if(fileType == 'excel'){
        if(res.status === 'success'){
          saveAs(res.data, 'Inspection Checklist.xlsx')
        } else {
          console.log('Something went wrong')
        }
      } else if(fileType == 'pdf'){
        if(res.status === 'success'){
          saveAs(res.data, 'Inspection Checklist.pdf')
        } else {
          console.log('Something went wrong')
        }
        } else {
        console.log('Something went wrong')
      }
      this.exporting = false
    },

    async onSave () {
      this.saving = true
      let tempPayload = {
        ...this.form,
        ...this.data
      }

      let payload = {
        vehicle_id: tempPayload.vehicle_id,
        collection_of_forms_id: tempPayload.collection_of_forms_id,
        field: tempPayload.field.map(val => {
          return {
            label: val?.label,
            type: val?.type,
            required: val?.required,
            value: val?.value,
          }
        }),
        signature: tempPayload.signature.map(val => {
          return {
            label: val?.label,
            type: val?.type,
            required: val?.required,
            value: val?.value,
          }
        }),
        question_content:JSON.stringify(tempPayload.question_content),
        title: this.form.title
      }

      payload.field = JSON.stringify(payload.field)
      payload.signature = JSON.stringify(payload.signature)
      
      let  res = await this.createInspectionChecklist({data: payload})

      if (res?.data?.status === true) {
        this.$swal(`Add Inspection Checklist Success!`, res?.data?.message, 'success')
        this.refreshSlideForms(tempPayload.form_id)
        this.resetForm()
        this.saving = false
      } else {
        this.$swal(
          `Oops!`,
          res?.data,
          'error'
        )
        this.saving = false
      }
      this.saving = false
    }
  }
}
</script>
