<template>
  <b-container fluid>
    <form action="#" method="post" @submit="onSave">
      <b-row>
        <b-col lg="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title text-primary"
                ><strong
                  >EVALUASI KONDIT / <i>CONDUITE EVALUATION</i></strong
                ></h4
              >
            </template>
            <template v-slot:body>
              <fieldset class="form-group border p-3">
                <legend class="w-auto px-2 text-primary"
                  >Crew Information</legend
                >
                <b-row>
                  <b-col md="10">
                    <table class="table table-striped mt-2">
                      <tbody>
                        <tr>
                          <th width="20%">Nama Crew</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">{{
                            userDetails?.fullname ?? '-'
                          }}</td>
                          <th width="20%">No KTP</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">{{ userDetails?.no_ktp ?? '-' }}</td>
                        </tr>
                        <tr>
                          <th width="20%">Tempat/Tanggal Lahir</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%"
                            >{{
                              userDetails?.personal_data?.place_of_birth ?? '-'
                            }},
                            {{
                              userDetails?.personal_data?.date_of_birth ?? '-'
                            }}</td
                          >
                          <th width="20%">No NPWP</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">{{
                            userDetails?.personal_data?.npwp_number ?? '-'
                          }}</td>
                        </tr>
                        <tr>
                          <th width="20%">Jabatan</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">{{
                            userDetails?.position?.name ?? '-'
                          }}</td>
                          <th width="20%">ID Crew</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">{{ userDetails?.nip ?? '-' }}</td>
                        </tr>
                        <tr>
                          <th width="20%">Perusahaan</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">{{
                            userDetails?.company?.company ?? '-'
                          }}</td>
                          <th width="20%">Seaferer Code</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">{{
                            userDetails?.personal_data?.seaferer_code ?? '-'
                          }}</td>
                        </tr>
                        <tr>
                          <th width="20%">Nama Kapal</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">{{
                            userDetails?.vehicle?.name ?? '-'
                          }}</td>
                          <th width="20%">Seaman Book</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">{{
                            userDetails?.seamant_book ?? '-'
                          }}</td>
                        </tr>
                        <tr>
                          <th width="20%">Pelabuhan</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">{{
                            userDetails?.port?.port_name ?? '-'
                          }}</td>
                          <th width="20%">No Passport</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">{{
                            userDetails?.personal_data?.passport_number ?? '-'
                          }}</td>
                        </tr>
                        <tr>
                          <th width="20%">Sign On</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">{{ userDetails?.sign_on ?? '-' }}</td>
                          <th width="20%">Nomor Tlp / Hp</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">{{
                            userDetails?.personal_data?.hp_number ?? '-'
                          }}</td>
                        </tr>
                        <tr>
                          <th width="20%">Join Onboard</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">{{
                            userDetails?.join_on_board ?? '-'
                          }}</td>
                          <th width="20%">Email</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">{{ userDetails?.email ?? '-' }}</td>
                        </tr>
                        <tr>
                          <th width="20%">Sign Off</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">{{
                            userDetails?.sign_off ?? '-'
                          }}</td>
                          <th width="20%">Alamat</th>
                          <td width="5%" class="text-center">:</td>
                          <td width="25%">
                            {{
                              userDetails?.candidate?.alamat_ktp?.[0]?.alamat ??
                              '-'
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-col>
                  <b-col md="2" class="text-center">
                    <b-img
                      thumbnail
                      class="ml-0"
                      :src="userDetails?.photo_url ? userDetails?.photo_url : require('../../assets/images/page-img/10.jpg')"
                      alt="Responsive image"
                    ></b-img>
                  </b-col>
                </b-row>
              </fieldset>
              <fieldset class="form-group border p-3">
                <legend class="w-auto px-2 text-primary"
                  >Form Evaluasi Conduite</legend
                >
                <b-row>
                  <b-col md="12">
                    <table class="table table-striped">
                      <thead>
                        <tr class="text-center">
                          <th width="40%">DESKRIPSI</th>
                          <th width="20%">PENILAIAN</th>
                          <th width="40%">NOTES</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(state, index) in merged_aspects"
                          :key="index"
                        >
                          <th>{{ state.content }}</th>
                          <td class="text-center">
                            <b-form-select
                              plain
                              v-model="state.value"
                              :options="optionsPoints"
                              size="sm"
                              class="col-md-12"
                              required
                            >
                              <template v-slot:first>
                                <b-form-select-option :value="null"
                                  >Nilai</b-form-select-option
                                >
                              </template>
                            </b-form-select>
                          </td>
                          <td
                            ><b-form-textarea
                              rows="4"
                              value="Notes"
                              v-model="state.remarks"
                            ></b-form-textarea
                          ></td>
                        </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
              </fieldset>
              <b-row class="mt-3">
                <b-col md="6">
                  <h4 class="text-primary"
                    >Disetujui Oleh / <i>Approval by </i></h4
                  >
                  <hr />
                  <b-form-group label="PIC" label-for="pic">
                    <v-select
                      v-model="form.approved_by"
                      label="text"
                      :options="optionsExpertUsers"
                      :reduce="(optionsExpertUsers) => optionsExpertUsers.text"
                      placeholder="Select Type of Expert User"
                      required
                    ></v-select>
                  </b-form-group>

                  <b-form-group label="Notes / Remarks" label-for="pic">
                    <b-form-textarea
                      rows="2"
                      value="Comments if any / Komentar jika ada"
                      v-model="form.recommendation1"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <h4 class="text-primary"
                    >Diverifikasi Oleh / <i>Verified by </i></h4
                  >
                  <hr />
                  <b-form-group label="PIC" label-for="pic">
                    <v-select
                      v-model="form.verified_by"
                      label="text"
                      :options="optionsExpertUsers"
                      :reduce="(optionsExpertUsers) => optionsExpertUsers.text"
                      placeholder="Select Type of Expert User"
                      required
                    ></v-select>
                  </b-form-group>

                  <b-form-group label="Notes / Remarks" label-for="pic">
                    <b-form-textarea
                      rows="2"
                      value="Comments if any / Komentar jika ada"
                      v-model="form.recommendation2"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <h4 class="text-primary"
                    >Diperiksa Oleh / <i>Checked by </i></h4
                  >
                  <hr />
                  <b-form-group label="PIC" label-for="pic">
                    <v-select
                      v-model="form.checked_by"
                      label="text"
                      :options="optionsExpertUsers"
                      :reduce="(optionsExpertUsers) => optionsExpertUsers.text"
                      placeholder="Select Type of Expert User"
                      required
                    ></v-select>
                  </b-form-group>

                  <b-form-group label="Notes / Remarks" label-for="pic">
                    <b-form-textarea
                      rows="2"
                      value="Comments if any / Komentar jika ada"
                      v-model="form.recommendation3"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <h4 class="text-primary">Dinilai Oleh / <i>Rated By </i></h4>
                  <hr />
                  <b-form-group label="PIC" label-for="pic">
                    <v-select
                      v-model="form.rated_by"
                      label="text"
                      :options="optionsExpertUsers"
                      :reduce="(optionsExpertUsers) => optionsExpertUsers.text"
                      placeholder="Select Type of Expert User"
                      required
                    ></v-select>
                  </b-form-group>

                  <b-form-group label="Notes / Remarks" label-for="pic">
                    <b-form-textarea
                      rows="2"
                      value="Comments if any / Komentar jika ada"
                      v-model="form.recommendation4"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>

                <b-col md="12" class="mt-3">
                  <b-button
                    :disabled="saving"
                    variant="primary"
                    type="submit"
                    class="mt-3"
                  >
                    <span
                      v-if="saving"
                      class="spinner-border spinner-border-sm"
                    />

                    <span v-else>Save</span>
                  </b-button>
                  <b-button
                    type="reset"
                    variant="none"
                    class="iq-bg-danger float-right"
                    ><i class="fa fa-close"></i> Reset Form</b-button
                  >
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row></form
    >
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import _ from 'lodash'
import { fleetsActions } from '@src/Utils/helper'
import { crewingActions } from '@src/Utils/helper'
import { createPopper } from '@popperjs/core'
import moment from 'moment'

export default {
  name: 'Conduct',

  data() {
    return {
      userDetails: null,
      saving:false,

      optionsExpertUsers: [],
      optionsPoints: [],

      conduct_aspects: [],
      conduct_points: [],

      merged_aspects: [],

      form: {
        name_of_evaluation: '', // done
        emp_crewing_id: null, //done
        date_of_evaluation: moment(new Date()).format('YYYY-MM-DD'), // done
        doc_number: '', // done
        description: '', // done
        total_point: 0, // done
        average_point: 0, // done
        recommendation1: '', // done
        recommendation2: '', // done
        recommendation3: '', // done
        recommendation4: '', // done
        approved_by: '', // done
        verified_by: '', // done
        checked_by: '', // done
        rated_by: '', // done
        contents: [], // done
      },
    }
  },
  async mounted() {
    xray.index()
    // this.fetchFleets()
    await this.getProfileUser()
    await this.getConductModel()
    await this.fetchDataUserExpert()
  },

  methods: {
    ...crewingActions,

    async fetchDataUserExpert() {
      let params = {
        page: 1,
        active: true,
        perPage: 200,
      }

      const { status, data } = await this.fetchUserExpert(params)

      if (status) {
        if (data && data.length > 0) {
          data.map((state) => {
            this.optionsExpertUsers.push({
              value: state.id,
              text: state.username,
            })
          })
        }
      }
    },
    async getProfileUser() {
      if (this.$route.params.id) {
        let method = 'getCrewDetail'

        const { status, data } = await this[method](this.$route.params.id)

        console.log(this.$route.params.id, 'ID CREW')
        console.log(data, 'THIS IS PROFILE CREW')

        if (status !== 'success') {
          this.$swal(`Oops!`, `User candidate tidak ada.`, 'error')
          return
        }

        this.userDetails = data[0]

        this.form.emp_crewing_id = data[0].personal_data.emp_crewing_id
        this.form.name_of_evaluation = data[0].fullname
      }
    },
    async getConductModel() {
      let res = await this.fetchConductModel()
      if (res.status) {
        console.log(res.data, 'THIS IS CONDITE')

        res.data.points.map((val) => {
          this.optionsPoints.push({
            value: val.value,
            text: `${val.value_letter} / ${val.name}`,
          })
        })

        this.conduct_aspects = res.data.aspects
        this.conduct_points = res.data.points.sort((a, b) => a.value - b.value)

        this.conduct_aspects.map((val) => {
          this.merged_aspects.push({
            condite_aspect_id: val.id,
            content: val.content,
            value: null,
            remarks: '',
          })
        })
      }
    },

    async onSave(e) {
      e.preventDefault()
      this.saving = true

      this.merged_aspects.map((item) => {
        this.conduct_points.map((val) => {
          if (item.value === val.value) {
            item.point_id = val.id
          }
        })
      })

      this.form.total_point = this.merged_aspects.reduce(
        (o, n) => o + n.value,
        0
      )
      this.form.average_point =
        this.merged_aspects.reduce((o, n) => o + n.value, 0) /
        this.merged_aspects.length

      this.merged_aspects.map((val) => {
        this.form.contents.push({
          condite_aspect_id: val.condite_aspect_id,
          point_id: val.point_id,
          remarks: val.remarks,
        })
      })

      let method = 'setConductCrew'

      const { status } = await this[method](this.form)

      if (status !== 'success') {
        this.saving = false
        this.$swal(`Oops!`, `Harap isi semua form.`, 'error')
        return
      }

      this.$swal(`Berhasil!`, `Conduite Crew Berhasil.`, 'success')
      this.saving = false
      this.$router.push('/crewing/list-crew')
    },
  },
}
</script>
