<template>
<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="50" height="50" rx="10" fill="#C8EFFF"/>
<path d="M12.8025 24.3677C12.8514 23.9085 12.8861 23.4478 12.9492 22.9905C13.2143 20.9248 14.027 18.9676 15.3032 17.3219C17.1774 14.8881 19.616 13.3113 22.6396 12.7415C26.4678 12.0225 29.9198 12.9068 32.9106 15.4217C35.0479 17.2196 36.4174 19.515 36.9471 22.2563C37.6401 25.8424 36.8982 29.1359 34.6904 32.0622C34.2189 32.6877 33.6266 33.2228 33.0896 33.7989C33.0407 33.8508 32.9869 33.8967 32.9037 33.977L33.7958 34.0919C33.7714 34.332 33.7469 34.5619 33.7205 34.8353L31.5196 34.5976C31.6013 33.8605 31.68 33.1465 31.7641 32.4026L32.5129 32.4794C32.4821 32.7728 32.4533 33.0428 32.4195 33.3666C33.3111 32.6163 34.0208 31.7731 34.6395 30.8184L33.3297 30.0407L33.7112 29.3888L35.0274 30.163C35.8836 28.5972 36.3624 26.8531 36.4257 25.0696H34.9041V24.3125H36.4257C36.3682 22.5346 35.8949 20.795 35.0435 19.2332L33.723 20.0021C33.5938 19.7825 33.4725 19.5766 33.3405 19.3531L34.6293 18.5915C34.2639 17.6706 32.0693 15.4682 31.1273 15.0789L30.3609 16.3667L29.7119 15.9817L30.4857 14.6646C28.9216 13.8062 27.177 13.3291 25.3937 13.2722V14.7854H24.6302V13.2751C22.8206 13.3314 21.1498 13.7935 19.5544 14.6519L20.3247 15.9725L19.6757 16.3535L18.8956 15.0432C17.48 15.9428 16.2781 17.141 15.3741 18.5539L16.6874 19.3364C16.5587 19.5556 16.4345 19.7673 16.3054 19.9884L14.9848 19.2132C14.126 20.7783 13.6482 22.5238 13.5904 24.3081H15.1066V25.051H12.8025V24.3677Z" fill="black"/>
<path d="M21.3954 37.4995C21.3861 37.0549 21.3748 36.6103 21.368 36.1662C21.3641 35.9309 21.3729 35.6957 21.368 35.4609C21.3636 35.3225 21.4169 35.276 21.5538 35.2795C21.8126 35.2863 22.0723 35.2795 22.3315 35.2829C22.6802 35.2878 22.9208 35.4643 23.0343 35.7832C23.1449 36.0923 23.0671 36.3726 22.8157 36.5956C22.7927 36.6162 22.7736 36.6406 22.7453 36.6714L23.2045 37.4995H22.6176C22.5198 37.3249 22.4127 37.1571 22.3334 36.9747C22.24 36.7604 22.0943 36.7301 21.8849 36.7922V37.4995H21.3954ZM21.8947 36.3413C22.1085 36.2797 22.3555 36.4391 22.5017 36.2014C22.5274 36.1488 22.5408 36.091 22.5408 36.0324C22.5408 35.9739 22.5274 35.9161 22.5017 35.8634C22.355 35.6292 22.1104 35.773 21.8938 35.7402L21.8947 36.3413Z" fill="black"/>
<path d="M16.5133 37.4999C16.168 37.3424 15.8701 37.137 15.7395 36.756C15.5439 36.1887 15.8242 35.5641 16.3685 35.3509C16.631 35.2453 16.9246 35.2478 17.1853 35.3577C17.4461 35.4676 17.6528 35.6761 17.7605 35.9378C17.8755 36.2048 17.8826 36.506 17.7804 36.7782C17.6782 37.0504 17.4745 37.2724 17.2122 37.3977C17.1418 37.431 17.0718 37.4662 17.0014 37.5004L16.5133 37.4999ZM16.7578 35.7363C16.4204 35.7387 16.2003 36.0014 16.2057 36.3956C16.2101 36.7746 16.4321 37.0314 16.7593 37.0314C17.0865 37.0314 17.3306 36.7634 17.333 36.3814C17.3355 35.9994 17.0958 35.7319 16.7588 35.7363H16.7578Z" fill="black"/>
<path d="M13.0471 37.5002V35.2993H13.5362V36.1176H14.4053V35.2988H14.8817C14.8949 35.3145 14.9135 35.3262 14.9135 35.338C14.9106 36.0589 14.9067 36.7796 14.9018 37.5002H14.4127V36.5935H13.5362V37.5002H13.0471Z" fill="black"/>
<path d="M19.2962 37.5007C18.7142 37.2885 18.5572 36.8287 18.569 36.2653C18.5753 35.9498 18.569 35.6339 18.569 35.3008H19.0928C19.0928 35.6603 19.0845 36.0085 19.0967 36.3558C19.1027 36.5085 19.1313 36.6595 19.1818 36.8038C19.2049 36.8845 19.2591 36.9527 19.3324 36.9935C19.4058 37.0343 19.4923 37.0443 19.5731 37.0214C19.7046 36.9862 19.8631 36.8918 19.9213 36.7769C19.9986 36.6267 20.0054 36.4345 20.0128 36.257C20.026 35.9425 20.0162 35.627 20.0162 35.2969H20.5028C20.5028 35.7674 20.5307 36.231 20.4945 36.6893C20.4618 37.1104 20.1737 37.3613 19.7853 37.4993L19.2962 37.5007Z" fill="black"/>
<path d="M24.3738 37.5006L23.7429 37.2443L23.9439 36.8354C24.186 36.9014 24.4125 36.9821 24.6458 37.0183C24.7402 37.032 24.8502 36.9445 24.9529 36.9029C24.8771 36.8281 24.8135 36.7293 24.7231 36.6838C24.5798 36.6124 24.4159 36.5825 24.2653 36.5233C23.9537 36.4011 23.8217 36.2059 23.8417 35.9169C23.8529 35.7782 23.906 35.6462 23.9941 35.5384C24.0822 35.4307 24.201 35.3523 24.3347 35.3138C24.726 35.2082 25.085 35.2987 25.4406 35.5095C25.3672 35.6469 25.3007 35.7726 25.2449 35.8763C25.0141 35.8274 24.8047 35.7638 24.5935 35.7413C24.526 35.7344 24.4467 35.8298 24.3738 35.8787C24.4257 35.9438 24.4643 36.0362 24.5318 36.068C24.6786 36.1355 24.8385 36.1697 24.9921 36.2221C25.3613 36.3483 25.4949 36.5062 25.4988 36.8168C25.5027 37.1509 25.3784 37.3152 25.0155 37.4575C24.9948 37.4691 24.9756 37.4832 24.9583 37.4996L24.3738 37.5006Z" fill="black"/>
<path d="M28.6918 32.2667H30.07V34.5576H28.6981V36.7003H26.15V34.5635H21.7902C21.7902 34.0891 21.8283 33.6401 21.7809 33.2004C21.7124 32.5587 21.9276 32.0696 22.3301 31.5702C23.8561 29.6667 25.3395 27.7313 26.8469 25.8136C26.9297 25.7232 27.044 25.668 27.1663 25.6595C27.5801 25.6419 27.9977 25.6654 28.41 25.6473C28.635 25.638 28.6976 25.706 28.6967 25.9315C28.6883 27.9323 28.6918 29.9332 28.6918 31.9341V32.2667ZM26.4297 29.5009L24.3163 32.255H26.4297V29.5009Z" fill="black"/>
<path d="M14.5907 28.9021C14.0297 28.254 13.499 27.6412 12.9204 26.9721C13.2838 26.7276 13.61 26.4551 13.9798 26.2678C15.515 25.4892 17.1276 25.2524 18.7959 25.7469C19.7408 26.0272 20.3928 26.6547 20.5967 27.6466C20.8119 28.6952 20.5067 29.6029 19.6895 30.296C19.3266 30.6041 18.9069 30.8491 18.5063 31.1088C17.941 31.4762 17.3658 31.8263 16.797 32.1883C16.7177 32.2372 16.6502 32.312 16.5309 32.4181H20.8266V34.6973H12.9522C12.9248 33.366 13.1826 32.1438 14.307 31.3094C14.9756 30.8139 15.7254 30.4246 16.435 29.9888C16.774 29.781 17.1261 29.5917 17.4489 29.3613C17.8754 29.0571 17.9683 28.7989 17.8534 28.4125C17.7409 28.0334 17.4064 27.7864 16.9608 27.7312C16.1592 27.6334 15.5175 27.9444 14.9648 28.4898C14.8386 28.613 14.7261 28.7519 14.5907 28.9021Z" fill="black"/>
<path d="M24.6238 16.4727H25.3926V16.769C25.3926 18.8347 25.3918 20.9001 25.3902 22.9654C25.3902 23.1204 25.4181 23.2036 25.5858 23.2671C25.981 23.4178 26.2784 23.6995 26.4212 24.1025C26.4882 24.2908 26.5899 24.3162 26.7636 24.3158C28.0567 24.3094 29.3499 24.3123 30.643 24.3123H30.9478V25.0616H30.6714C29.3621 25.0616 28.0528 25.0641 26.7435 25.0616C26.5801 25.0616 26.4897 25.0934 26.4217 25.2685C26.2968 25.5849 26.0692 25.8501 25.7754 26.0215C25.4816 26.1928 25.1387 26.2605 24.8018 26.2134C24.1523 26.1283 23.5752 25.5664 23.4969 24.9418C23.4025 24.1925 23.7595 23.5322 24.4262 23.2701C24.5871 23.207 24.6272 23.1331 24.6267 22.9727C24.6218 20.9071 24.6207 18.8417 24.6233 16.7764L24.6238 16.4727ZM25.015 25.4588C25.1675 25.4553 25.3154 25.4067 25.4403 25.3192C25.5651 25.2317 25.6612 25.1092 25.7165 24.9672C25.7718 24.8251 25.7838 24.6699 25.751 24.521C25.7182 24.3721 25.642 24.2363 25.5321 24.1307C25.4222 24.0251 25.2834 23.9544 25.1334 23.9275C24.9833 23.9006 24.8287 23.9188 24.6889 23.9797C24.5492 24.0406 24.4306 24.1415 24.3482 24.2698C24.2657 24.398 24.2231 24.5478 24.2257 24.7002C24.2299 24.9058 24.3155 25.1013 24.4636 25.2438C24.6118 25.3864 24.8104 25.4644 25.016 25.4607L25.015 25.4588Z" fill="black"/>
</svg>

</template>
<script>
export default {
  props: {
    color: {
      default: 'white',
      type: String,
    },
  },
}
</script>
