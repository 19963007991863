var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "px-3"
  }, [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('h6', {
    staticClass: "mb-1",
    staticStyle: {
      "color": "#3a5de7"
    }
  }, [_c('strong', [_vm._v("KPC PROJECT - TRANSHIPMENT")])]), _c('div', [_c('b-badge', {
    staticClass: "p-2",
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v("TB.SML 301")]), _c('b-badge', {
    staticClass: "p-2 ml-1",
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v("BG. SML 104")])], 1)])], 1), _c('b-row', {
    staticClass: "mb-3"
  }, [_c('p', {
    staticClass: "mb-0 text-black col-3"
  }, [_vm._v("POL")]), _c('p', {
    staticClass: "mb-0 text-black col-3"
  }, [_vm._v(": "), _c('strong', [_vm._v("Asam - Asam")])]), _c('p', {
    staticClass: "mb-0 text-black col-3"
  }, [_vm._v("Actual COB")]), _c('p', {
    staticClass: "mb-0 text-black col-3"
  }, [_vm._v(": "), _c('strong', [_vm._v("12.034.555 MT")])]), _c('p', {
    staticClass: "mb-0 text-black col-3"
  }, [_vm._v("POD")]), _c('p', {
    staticClass: "mb-0 text-black col-3"
  }, [_vm._v(": "), _c('strong', [_vm._v("Sangatta")])]), _c('p', {
    staticClass: "mb-0 text-black col-3"
  }, [_vm._v("Cargo Type")]), _c('p', {
    staticClass: "mb-0 text-black col-3"
  }, [_vm._v(": "), _c('strong', [_vm._v("Coal")])]), _c('p', {
    staticClass: "mb-0 text-black col-3"
  }, [_vm._v("Arrival Time POL")]), _c('p', {
    staticClass: "mb-0 text-black col-3"
  }, [_vm._v(": "), _c('strong', [_vm._v("05 July 2024 14:20")])]), _c('p', {
    staticClass: "mb-0 text-black col-3"
  }, [_vm._v("Completed")]), _c('p', {
    staticClass: "mb-0 text-black col-3"
  }, [_vm._v(": "), _c('strong', [_vm._v("05 July 2024 123:20")])]), _c('p', {
    staticClass: "mb-0 text-black col-3"
  }, [_vm._v("Arrival Time POD")]), _c('p', {
    staticClass: "mb-0 text-black col-3"
  }, [_vm._v(": "), _c('strong', [_vm._v("05 July 1993 21:00")])]), _c('p', {
    staticClass: "mb-0 text-black col-3"
  }, [_vm._v("Total Steaming Time")]), _c('p', {
    staticClass: "mb-0 text-black col-3"
  }, [_vm._v(": "), _c('strong', [_vm._v("7,67")])])]), _c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6"
    }
  }, [_c('p', {
    staticClass: "text-black mb-1",
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v("Input Type")]), _c('div', {
    staticClass: "btn-group"
  }, _vm._l(_vm.inputTypeOpt, function (btn, i) {
    return _c('b-button', {
      key: i,
      class: {
        active: _vm.inputTypeSelected == btn
      },
      attrs: {
        "variant": "outline-primary"
      },
      on: {
        "click": function click($event) {
          _vm.inputTypeSelected = btn;
        }
      }
    }, [_vm._v(_vm._s(btn))]);
  }), 1)]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Currency",
      "label-for": "currency"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "currency"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Freight Rate",
      "label-for": "fr"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "fr"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "TAX",
      "label-for": "fr"
    }
  }, [_c('b-form-radio', {
    attrs: {
      "name": "radio-1",
      "value": "A"
    }
  }, [_vm._v("Include PPN")]), _c('b-form-radio', {
    attrs: {
      "name": "radio-2",
      "value": "B"
    }
  }, [_vm._v("Exclude PPN")])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Total",
      "label-for": "total"
    }
  }, [_c('h5', [_c('strong', [_vm._v("IDR 2.345.000.000,-")])])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }