var render = function render(){
  var _vm$formData;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "overflow-auto d-flex"
  }, [_c('table', {
    staticClass: "table table-striped table-bordered"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-left align-middle text-truncate"
  }, [_vm._v("Fleet")]), _c('th', {
    staticClass: "text-left align-middle text-truncate"
  }, [_vm._v("Field")]), _c('th', {
    staticClass: "text-left align-middle text-truncate"
  }, [_vm._v("Signature")]), _c('th', {
    staticClass: "text-left align-middle text-truncate"
  }, [_vm._v("Status")]), _c('th', {
    staticClass: "text-center align-middle",
    staticStyle: {
      "width": "20px"
    }
  }, [_vm._v("#")])])]), _c('tbody', [((_vm$formData = _vm.formData) === null || _vm$formData === void 0 ? void 0 : _vm$formData.inspectionData.length) > 0 ? _vm._l(_vm.formData.inspectionData, function (state, index) {
    var _state$vehicle$name, _state$vehicle;
    return _c('tr', {
      key: index
    }, [_c('td', [_c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('strong', [_vm._v(_vm._s((_state$vehicle$name = state === null || state === void 0 ? void 0 : (_state$vehicle = state.vehicle) === null || _state$vehicle === void 0 ? void 0 : _state$vehicle.name) !== null && _state$vehicle$name !== void 0 ? _state$vehicle$name : '-'))])])]), _c('td', [_c('div', {
      staticClass: "d-flex flex-column"
    }, _vm._l(state === null || state === void 0 ? void 0 : state.field, function (field, fieldIndex) {
      return _c('div', {
        key: fieldIndex,
        staticClass: "d-flex mb-1"
      }, [_c('strong', {
        staticClass: "mr-2"
      }, [_vm._v(_vm._s(field === null || field === void 0 ? void 0 : field.label) + ": ")]), _c('span', [_vm._v(_vm._s(field !== null && field !== void 0 && field.value ? _vm.handleTypeDate(field === null || field === void 0 ? void 0 : field.value) : '-'))])]);
    }), 0)]), _c('td', [_c('div', {
      staticClass: "d-flex flex-column"
    }, _vm._l(state === null || state === void 0 ? void 0 : state.signature, function (signature, signatureIndex) {
      return _c('div', {
        key: signatureIndex,
        staticClass: "d-flex mb-1"
      }, [_c('strong', {
        staticClass: "mr-2"
      }, [_vm._v(_vm._s(signature === null || signature === void 0 ? void 0 : signature.label) + ": ")]), _c('span', [_vm._v(_vm._s(signature !== null && signature !== void 0 && signature.value ? _vm.handleTypeDate(signature === null || signature === void 0 ? void 0 : signature.value) : '-'))])]);
    }), 0)]), _c('td', [_c('strong', {
      staticClass: "text-primary text-capitalize"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.status))])]), _c('td', [_c('b-dropdown', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.top",
        value: 'Action',
        expression: "'Action'",
        modifiers: {
          "top": true
        }
      }],
      staticClass: "float-right mr-1",
      attrs: {
        "variant": "link iq-bg-primary",
        "toggle-class": "text-decoration-none",
        "no-caret": "",
        "size": "sm"
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function fn() {
          return [_c('span', {
            staticClass: "dropdown-toggle p-0",
            attrs: {
              "id": "dropdownMenuButton5",
              "formData-toggle": "dropdown"
            }
          }, [_c('i', {
            staticClass: "fa fa-file m-0"
          })])];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": "primary"
      },
      on: {
        "click": function click($event) {
          var _vm$formData2, _vm$formData$vehicle, _vm$formData$vehicle$, _vm$formData3;
          return _vm.openInspectionChecklistSlide(true, false, state, (_vm$formData2 = _vm.formData) === null || _vm$formData2 === void 0 ? void 0 : _vm$formData2.vehicle_id, (_vm$formData$vehicle = _vm.formData.vehicle) === null || _vm$formData$vehicle === void 0 ? void 0 : (_vm$formData$vehicle$ = _vm$formData$vehicle.vehicle_type) === null || _vm$formData$vehicle$ === void 0 ? void 0 : _vm$formData$vehicle$.name, (_vm$formData3 = _vm.formData) === null || _vm$formData3 === void 0 ? void 0 : _vm$formData3.id);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-eye mr-2"
    }), _vm._v("Preview")]), _c('b-dropdown-divider'), _c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.onExport(state === null || state === void 0 ? void 0 : state.id, 'pdf');
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-file mr-2"
    }), _vm._v("Export PDF")])], 1)], 1)]);
  }) : [_c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colSpan": "6"
    }
  }, [_vm._v(" There's no inspection yet. ")])])]], 2)])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }