<template>
  <b-container fluid>
    <form @submit.prevent="onSaveRealInvoice">
      <b-row>
        <b-col md="12">
          <b-button variant="primary" class="float-right mr-1" size="sm" type="submit"><i class="fa fa-save"></i> Save Real Invoice&nbsp; </b-button>
          <b-button variant="danger" class="float-right mr-1" size="sm" type="button" @click="closeSlideRealInvoice"><i class="fa fa-ban"></i> Cancel&nbsp; </b-button>
        </b-col>
        <b-col md="12" class="p-4">
          <b-row>
            <b-col md="12">
              <b-form-group label="Real Invoice Number">
                <b-form-input placeholder="Type Text" type="text" v-model="form.real_invoice_number"  />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Invoice Date">
                <b-form-input type="date" v-model="form.invoice_date" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Due Date">
                <b-form-input type="date" v-model="form.due_date" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group label="Invoice File">
                <b-form-file accept="application/*" plain multiple v-model="form.attachment" name='file' id="attactment_file" class="border p-2"></b-form-file>
              </b-form-group>
            </b-col>
            
            <b-col md="12">
              <b-form-group label="Status Invoice">
                <b-form-select plain v-model="form.status" class="form-control" name="status">
                  <template v-slot:first>
                    <b-form-select-option value="">select...</b-form-select-option>
                  </template>
                  <b-form-select-option value="request">Request</b-form-select-option>
                  <b-form-select-option value="paid">Paid</b-form-select-option>
                  <b-form-select-option value="canceled">Canceled</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>

<script>
  import _ from 'lodash'
  import moment from 'moment'
  import { busdevActions } from '@src/Utils/helper'

  export default {
    name:'SlideRealInvoice',
    props:{
      refreshData:{
        type:Function,
        default:true
      }, 
      closeSlideRealInvoice:{
        type:Function,
        default:true
      }, 
      projectData:{
        type:Object,
        default:null
      },  
      paymentId:{
        type:Number,
        default:null
      }, 
    },
    components:{
    },
    data(){
      return{
        form:{
          real_invoice_number:'',
          invoice_date:null,
          due_date:null,
          status:'request', // request, paid, canceled
          attachment:[]
        }
      }
    },
    watch:{
    },
    mounted(){},
    methods:{
      ...busdevActions,
      formatCurrency(value) {
        return new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        }).format(value)
      },

      resetForm(){
        this.form = {
          real_invoice_number:'',
          invoice_date:null,
          due_date:null,
          status:'request', // request, paid, canceled
          attachment:[]
        }
      },

      async onSaveRealInvoice(){
        let payload = {
          ...this.form,
          invoice_date: moment(this.form.invoice_date).format('YYYY-MM-DD'),
          due_date: moment(this.form.due_date).format('YYYY-MM-DD')
        }

        console.log(payload, 'payload')

        let formData = new FormData()
        formData.append('real_invoice_number', payload.real_invoice_number)
        formData.append('due_date', payload.due_date)
        formData.append('invoice_date', payload.invoice_date)
        formData.append('status', payload.status)

        if(payload.attachment?.length > 0){
          // Append each file to FormData
          for (let i = 0; i < payload.attachment.length; i++) {
            formData.append('files', payload.attachment[i]);
          }
        }

        const res = await this.addRealInvoice({data:formData, id: Number(this.paymentId)})

        if(res.data.status === true){
          this.refreshData()
          this.resetForm()
          this.closeSlideRealInvoice()
          this.$swal(`Success!`, `Create Real Invoice.`, 'success')
        }
      }
    }
  }
</script>
