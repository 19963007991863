<template>
  <b-row>
    <b-col md="12">
      <iq-card>
        <template v-slot:headerTitle>
            <h5 class="card-title text-primary"><strong>SHIPMENT REAL</strong></h5>
        </template>
        <template v-slot:body>
          <!-- FILTER START -->
           <div class="d-flex justify-content-between mb-3">
             <div class="d-flex">
                <b-form class="d-flex" @submit.prevent="onFilter">
                  <b-form-input placeholder="search project..." style="width: 220px;" v-model="params.keyword"></b-form-input>
                  <b-button variant="primary" class="mx-2" type="submit">
                    <i class="fa fa-search"></i>
                  </b-button>
                </b-form>
               <b-button
                 variant="outline-primary"
                 class="d-flex align-items-center"
                 @click="toggleModalFilter"
               >
                 <i class="fas fa-sliders"></i>
                 <p class="text-truncate m-0"> Filter Data</p>
               </b-button>
             </div>
             <div class="d-flex">
              <!-- <b-button @click="refreshDataTable" variant="primary" class="float-right mr-1"><i class="fa fa-refresh"></i> &nbsp; Refresh Data</b-button> -->
              <!-- <b-button variant="primary" size="md" class="mx-1"><i class="fa fa-file-excel"></i> Export</b-button> -->
              <b-dropdown v-b-tooltip.top="'Export Data'"  variant="link iq-bg-primary" toggle-class="text-decoration-none" no-caret class="mr-1">
                <template v-slot:button-content>
                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                    <i class="fa fa-download mr-1"></i> Export
                  </span>
                </template>
                <b-dropdown-item href="#" variant="dark"><i class="fa fa-file-excel mr-2"></i>Export Excel</b-dropdown-item>
                <b-dropdown-item href="#" variant="dark"><i class="fa fa-file-pdf mr-2"></i>Export PDF</b-dropdown-item>
              </b-dropdown>
              <b-button @click="slideRunningShipment = true" variant="primary" class="float-right mr-1"><i class="fa fa-search"></i>Operational Data Running Shipment</b-button>
             </div>
           </div>

           <b-modal v-model="modal.filter" centered title="Filter Data" ok-title="Filter" @ok="onFilter">
             <b-row class="">
               <b-col md="12">
                 <b-form-group label="Period" label-for="Period" label-cols-sm="4" label-cols-lg="3" class="mb-3">
                   <date-picker v-model="dateRange" type="date" range placeholder="Select date range" :style="{width: '100%'}"></date-picker>
                 </b-form-group>
               </b-col>
               <!-- <b-col md="12">
                 <b-form-group label="Keyword" label-for="keyword" label-cols-sm="4" label-cols-lg="3" class="mb-3">
                   <b-form-input type="text" id="keyword" v-model="params.keyword" placeholder="Search project..."></b-form-input>
                 </b-form-group>
               </b-col> -->
               <b-col md="12">
                 <b-form-group label="Cargo Type" label-for="cargo" label-cols-sm="4" label-cols-lg="3" class="mb-3">
                   <b-form-select plain :options="optionsCargoType" v-model="params.cargo_type_id"> 
                     <b-form-select-option :value="null">All</b-form-select-option>
                   </b-form-select>
                 </b-form-group>
               </b-col>
               <b-col md="12">
                 <b-form-group label="Stage Status" label-for="stage_id" label-cols-sm="4" label-cols-lg="3" class="mb-3">
                   <b-form-select plain v-model="params.stage_id" class="mb-2" :options="optionsStage">
                     <b-form-select-option :value="null">All</b-form-select-option>
                   </b-form-select>
                 </b-form-group>
               </b-col>   
               <b-col md="12" >
                 <b-form-group label="Ownership" label-for="fleet_ownership" label-cols-sm="4" label-cols-lg="3" class="mb-3">
                   <b-form-select plain v-model="params.fleet_ownership" class="mb-2">
                     <b-form-select-option value="">All</b-form-select-option>
                     <b-form-select-option value="own-ship">Own Ship</b-form-select-option>
                     <b-form-select-option value="chartered">Chartered</b-form-select-option>
                   </b-form-select>
                 </b-form-group>
               </b-col>  
               <b-col md="12" v-if="params.fleet_ownership">
                 <b-form-group label="Vehicle" label-for="vehicle_id" label-cols-sm="4" label-cols-lg="3">
                   <v-select
                     label="text"
                     name="vehicle_id"
                     v-model="params.vehicle_id"
                     :options="optionsFleets"
                     :reduce="(optionsFleets) => optionsFleets.value"
                     placeholder="Select Fleet"
                   ></v-select>
                 </b-form-group>
               </b-col>
             </b-row>
           </b-modal>
          <!-- FILTER END -->

            
        <!-- Show Value Filter -->

        <div class="px-3 pb-3">
          <b-row class="border rounded-md pt-2">
              <b-col md="2" v-if="params.cargo_type_id">
                <b-form-group label="Cargo Type :">
                  <p class="mb-0 text-black" style="font-weight: 500;">{{optionsCargoType.find((el, i)=>el.id == params.cargo_type_id)?.name ?? '-'}}</p>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Contract Type :" >
                  <p class="mb-0 text-black" style="font-weight: 500;">{{optionsContractType.find((el, i)=>el.id == params.contract_type_id)?.name ?? 'All'}}</p>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Stage Status :">
                  <p class="mb-0 text-black" style="font-weight: 500;">{{optionsStage.find((el, i)=>el.value == params.stage_id)?.text ?? 'All'}}</p>
                </b-form-group>
              </b-col>   
              <b-col md="2">
                <b-form-group label="Ownership">
                  <p class="mb-0 text-black" style="font-weight: 500;">{{ params.ownership  ?? "All"}}</p>
                </b-form-group>
              </b-col>  
              <b-col md="4">
                <b-form-group label="Period :" v-if="dateRange.length">
                  <div class="d-flex">
                    <p class="mb-0 text-black" style="font-weight: 500;" >{{ dateRange[0] ? moment(dateRange[0]).format("DD MMMM YYYY"):'' }} - <span>{{ dateRange[1] ? moment(dateRange[1]).format("DD MMMM YYYY"):'' }}</span></p>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
        </div>
        
        <!-- END Show Value Filter -->


          <b-row>
            <b-col md="12" v-if="!loading">
              <div class="d-flex overflow-auto">
                <table class="table">
                  <thead>
                    <tr>
                      <th style="width:5px" class="text-center table-info text-truncate">NO</th>
                      <th class="text-center table-info text-truncate">SHIPPER</th>                       
                      <th class="text-center table-info text-truncate">TYPE</th>                       
                      <th class="text-right table-info text-truncate">SHIPMENT PLAN</th>                       
                      <th class="text-right table-info text-truncate">VOLUME PLAN</th>                       
                      <th class="text-right table-info text-truncate">SHIPMENT REAL</th>                       
                      <th class="text-right table-info text-truncate">VOLUME REAL</th>                       
                      <th class="text-center table-info">STAGE</th>                       
                      <th class="text-center table-info">ACTION</th>
                    </tr>
                  </thead>
                  <tbody v-if="list.data.length > 0">
                    <tr v-for="(state, index) in list.data" :key="index">
                      <th class="text-center bg-white text-black">{{ index + 1 }}</th>
                      <td class="text-truncate">
                        <div class="d-flex align-items-center" style="cursor: pointer" @click="openNewWindow(state?.customer?.id)">
                          <b-avatar
                            size="34"
                            variant="warning"
                            :src="require('../../../assets/images/user/11.png')"
                          ></b-avatar>
                          <h6 class="ml-2 text-primary">
                            <strong>
                              {{ state?.customer?.account_name ?? 'N/A' }}
                            </strong>
                          </h6>
                        </div>
                      </td>
                      <td class="text-center text-truncate text-primary" style="cursor:pointer" @click="openSlideFormAddPlan('PREVIEW', state?.id)">{{ state?.cargo_type?.name }}</td>
                      <td class="text-right text-truncate text-primary" style="cursor:pointer" @click="openSlideFormAddPlan('PREVIEW', state?.id)">{{ state?.shipment }} SHIPMENT</td>
                      <td class="text-right text-truncate text-primary" style="cursor:pointer" @click="openSlideFormAddPlan('PREVIEW', state?.id)">{{ state?.volume ? state?.volume?.toLocaleString() : '0' }} {{ state?.unit }}</td>
                      <td class="text-right text-truncate ">{{ state?.shipment_real }} SHIPMENT</td>
                      <td class="text-right text-truncate">{{ state?.volume_real ? state?.volume_real?.toLocaleString() : '0' }} {{ state?.unit }}</td>
                      <td class="text-center bg-white text-black">
                        <b-dropdown v-b-tooltip.top="'Action'"  variant="link iq-bg-primary" toggle-class="text-decoration-none" no-caret class="float-right mr-1" size="sm">
                          <template v-slot:button-content>
                            <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                              {{state?.project_stage?.title}}
                            </span>
                          </template>
                          <b-dropdown-item href="#" 
                            :variant="state?.detail_project_stage_id === obj.id ? 'primary' : 'secondary'"
                            v-for="(obj, objIndex) in listProjectStage" :key="objIndex"
                          >
                            <strong @click="onChangeStatusStage(index, state?.id, obj.id)">{{ obj?.title }}</strong>
                          </b-dropdown-item>
                        </b-dropdown>
                      </td>
                      <td class="text-center bg-white text-black">
                        <b-dropdown 
                          size="lg"
                          variant="link iq-bg-primary iq-border-radius-10"
                          toggle-class="text-decoration-none"
                          no-caret
                        >
                          <template v-slot:button-content>
                            <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                              <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                            </span>
                          </template>
                          <b-dropdown-item href="#" variant="primary" @click="openSlideFormAddPlan('PREVIEW', state?.id)">
                            <i class="fa fa-eye mr-2"></i>Detail
                          </b-dropdown-item>
                          <b-dropdown-item href="#" variant="primary" @click="openSlideFormRealShipment('ADD', state?.id)">
                            <i class="fa fa-plus mr-2"></i>Add Real Shipment
                          </b-dropdown-item>
                          <b-dropdown-divider></b-dropdown-divider>
                          <b-dropdown-item href="#" variant="danger" >
                            <i class="fa fa-arrow-left mr-2"></i>Back to Plan
                          </b-dropdown-item>
                        </b-dropdown>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="mt-3" v-if="list.data.length > 0">
                <b-pagination
                  v-model="page"
                  :total-rows="list.meta.totalItem"
                  @change="getListRunningShipmentTable"
                  :per-page="list.meta.perPage"
                  first-number
                  align="center"
                ></b-pagination>
              </div>
            </b-col>
            <b-col md="12" v-else> 
              <div  class="alert alert-info">
                Loading...
              </div>
            </b-col>
          </b-row>
        </template>
      </iq-card>
    </b-col>

    <b-col md="12">
      <!-- SLIDE - FORM ADD PLAN -->
      <b-sidebar
      v-model="slideAddRakor"
      id="add_rakor"
      :title="['PREVIEW', 'EDIT'].includes(action) ? 'DETAIL PLAN' : `ADD PLAN`"
      width="100%"
      backdrop-variant="dark"
      bg-variant="white"
      right
      backdrop
      shadow>
      <template #default="{hide}">
        <FormAddPlan 
          :open-customer-slide="() => {}" 
          :refresh-data-table="refreshDataTable" 
          :refreshDataTableWithoutCloseModal="() => {}" 
          :action="action"
          :edit-id="editId"
          :openInspectionChecklistSlide="openInspectionChecklistSlide"
          :optionsCompanies="optionsCompanies"
          @updateAction="updateAction" 
        />
      </template>
    </b-sidebar>
    <!-- SLIDE - FORM ADD PLAN END -->
    </b-col>

       <!-- SLIDE - FORM REAL SHIPMENT -->
       <b-col md="12">
        <b-sidebar
          v-model="slideFormRealShipment"
          id="slideFormRealShipment"
          :title="`Create Real Shipment`"
          width="90%"
          backdrop-variant="dark"
          bg-variant="white"
          right
          backdrop
          shadow>
          <template #default="{hide}">
            <SlideFormRealShipment
              :closeSlideFormRealShipment="closeSlideFormRealShipment"
              :refreshData="() => {}"
              :optionsFleets="optionsFleets"
              :projectId="idRealShipment"
              :action="actionRealShipment"
            />
          </template>
        </b-sidebar>
      </b-col>
      <!-- SLIDE - FORM REAL SHIPMENT END -->
    
    <!-- SLIDE - RUNNING SHIPMENT -->

    <b-col md="12">
      <b-sidebar
          v-model="slideRunningShipment"
          id="slideRunningShipment"
          :title="`Operational Data Running Shipment`"
          width="90%"
          backdrop-variant="dark"
          bg-variant="white"
          right
          backdrop
          shadow>
          <SlideRunningShipment/>
      </b-sidebar>
    </b-col>
    
    <!-- SLIDE - RUNNING SHIPMENT -->

    <!-- SLIDE - FORM REAL SHIPMENT -->
    <!-- <b-col md="12">
      <b-sidebar
        v-model="slideFormRealShipment"
        id="slideFormRealShipment"
        :title="`Create Real Shipment`"
        width="90%"
        backdrop-variant="dark"
        bg-variant="white"
        right
        backdrop
        shadow>
        <template #default="{hide}">
          <SlideFormRealShipment
            :closeSlideFormRealShipment="closeSlideFormRealShipment"
            :refreshData="() => {}"
            :projectData="{}"
            :id="null"
            :action="'ADD'"
          />
        </template>
      </b-sidebar>
    </b-col> -->
    <!-- SLIDE - FORM REAL SHIPMENT END -->

    <!-- SLIDE - DETAIL REALISASI -->
    <!-- <b-col md="12">
      <b-sidebar
        v-model="slideDetailRealisasi"
        id="slideDetailRealisasi"
        :title="`Detail Real Shipment`"
        width="100%"
        backdrop-variant="dark"
        bg-variant="white"
        right
        backdrop
        shadow>
        <template #default="{hide}">
          <SlideDetailRealisasi />
        </template>
      </b-sidebar>
    </b-col> -->
    <!-- SLIDE - DETAIL REALISASI END -->
  </b-row>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { projectsActions, companiesActions, fleetsActions, busdevActions, crewingActions } from '@src/Utils/helper'
import SlideFormRealShipment from './SlideFormRealShipment.vue'
import SlideDetailRealisasi from './SlideDetailRealisasi.vue'
import { saveAs } from 'file-saver'
import FormAddPlan from './FormAddPlan.vue' 
import SlideRunningShipment from './SlideRunningShipment.vue'

export default {
  name: 'TabRealisasi',
  data () {
    return {
      action:'',
      idRealShipment:null,
      actionRealShipment:'',

      isEdit:false,
      editId:null,
      pfaId:null,

      dateRange:[],
      // Slide
      slideFormRealShipment: false,
      slideDetailRealisasi: false,
      slideRunningShipment: false,

      exporting: false,
      saving: false,
      loading:false,
      detail_project_loading:false,
      fleet_loading:false,

      slideAddPreviewInspectionChecklist: false,
      isPreviewInspectionChecklist: false,
      isAddInspectionChecklist: false,
      inspectionChecklistData: null,
      slideAddRakor: null,

      projectOptions: [],
      optionsCompanies: [],
      optionsCustomerClass: [],
      optionsTerms: [],
      optionsFleets: [],
      optionsContractType: [],
      optionsCargoType: [],
      optionsStage:[],
      listProjectStage:[],

      ownership: [
        {
          name: 'ownship',
          label: 'OWN SHIP',
          value: 'own-ship',
          disabled: false
        },
        {
          name: 'chartered',
          label: 'CHARTERED',
          value: 'chartered',
          disabled: false
        }
      ],

      dateRange:[],
      page:1,
      params:{
        periode_start:null,
        periode_end:null,
        cargo_type_id:null,
        fleet_ownership:'',
        keyword:'',
        stage_id:null
      },

      list: {
        data:[],
        meta:null
      },

      userProfileId: JSON.parse(localStorage.getItem('profile')).id,
      userProfileName: JSON.parse(localStorage.getItem('profile')).userDetail.name,
      modal:{
        filter:false
      }
    }
  },
  components:{
    FormAddPlan,
    SlideFormRealShipment,
    SlideDetailRealisasi,
    SlideRunningShipment
  },
  async mounted () {
    await this.getListRunningShipmentTable() 
    await this.fetchPerusahaan() 
    await this.fetchFleets() 
    await this.getCargoType() 
    await this.getListProjectStage()
  },
  computed:{
    addPermission(){
      let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
        .find(obj => obj.menu === 'Apps').child
        .find(obj => obj.menu === 'Business Development').child
        .find(obj => obj.menu === 'Business Development Record').add

      return hasAccess
    },
    updatePermission(){
      let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
        .find(obj => obj.menu === 'Apps').child
        .find(obj => obj.menu === 'Business Development').child
        .find(obj => obj.menu === 'Business Development Record').update

      return hasAccess
    },
    deletePermission(){
      let hasAccess = JSON.parse(localStorage.getItem('profile')).menu
        .find(obj => obj.menu === 'Apps').child
        .find(obj => obj.menu === 'Business Development').child
        .find(obj => obj.menu === 'Business Development Record').delete

      return hasAccess
    },
  },
  methods: {
    ...projectsActions,
    ...fleetsActions,
    ...companiesActions,
    ...busdevActions,
    ...crewingActions,
    toggleModalFilter(){
      this.modal.filter = !this.modal.filter
    },

    openNewWindow(id) {
      const queryParamValue = 'yourValue';
      const url = this.$router.resolve({ path: '/busdev/customer', query: {id} }).href;
      window.open(url, '_blank'); // Opens in a new tab/window
    },

    showVehicleName(value){
      let vehicle = this.optionsFleets.find(obj => obj.value == value)

      return vehicle?.text ?? '-'
    },

    openSlideFormRealShipment(value, id){
      this.idRealShipment = null 
      this.actionRealShipment = value
      this.slideFormRealShipment = !this.slideFormRealShipment;

      if(id) this.idRealShipment = id
    },
    closeSlideFormRealShipment(){
      this.idRealShipment = null
      this.actionRealShipment = ''
      this.slideFormRealShipment = false
    },  

    closeSlideDetailRealisasi(){
      this.action = ''
      this.slideDetailRealisasi = false
    },  
    openSlideDetailRealisasi(value, id){
      this.id = null
      this.slideDetailRealisasi = !this.slideDetailRealisasi;

      if(id) this.id = id
    },

    updateAction(newAction){
      this.action = newAction
    },

    openInspectionChecklistSlide(isPreview, isAdd, data, vehicle_id, vehicle_type_name, form_id){
      this.inspectionChecklistData = null

      this.slideAddPreviewInspectionChecklist = true
      this.isPreviewInspectionChecklist = isPreview
      this.isAddInspectionChecklist = isAdd
      this.inspectionChecklistData = data
      this.inspectionChecklistData.vehicle_id = vehicle_id
      this.inspectionChecklistData.vehicle_type_name = vehicle_type_name
      this.inspectionChecklistData.form_id = form_id
    }, 

    openSlideFormAddPlan(value, id){
      this.editId = null
      this.action = value
      this.slideAddRakor = !this.slideAddRakor;

      if(id) this.editId = id
    },

    refreshDataTable(){
      this.getListRunningShipmentTable()
    },

    async fetchPerusahaan() {
      let res = await this.fetchCompanies()
      if (res.status) {
        if (res.data && res.data.length > 0) {
          res.data.map((state) => {
            this.optionsCompanies.push({
              value: state.id,
              text: state.company,
            })
          })
        }
      }
    },
    async fetchFleets(ownership) {
      // this.fleet_loading = true
      let params = this.$options.filters.cleanObject({
        page: 1,
        perPage: 9999,
        showAll: true,
        active: true,
        ownership
      })

      if(!params.ownership) delete params.ownership
      
      let res = await this.getFleets(params)

      if (res.status) {
        if (res.data && res.data.length > 0) {
          this.optionsFleets = res.data.map((val) => ({
            text: val.name,
            value: val.id,
          }))
        }
      }

      // this.fleet_loading = false
    },
    async getCargoType() {
      let res = await this.fetchCargoType()
      if (res.data.status === true && res.data.data.length > 0) {
        res.data.data.map((state) => {
          this.optionsCargoType.push({
            ...state,
            value: state.id,
            text: state.name,
          })
        })
      }
    }, 
    async getListProjectStage () {
      this.listProjectStage = []
      let res = await this.fetchDetailProjectStage()
      if (res?.status == 'success' || res.data.status == true) {
        if (res.data.data && res.data.data.length > 0) {
          this.optionsStage = res.data.data.map(val => {
            return {
              text:val.title,
              value:val.id
            }
          })

          this.optionsStage = this.optionsStage.filter(obj => ['Process', 'Done'].includes(obj.text))
          this.listProjectStage = res.data.data
        }
      } 
    },
    async onChangeStatusStage(index, id, project_stage_id){
      let payload = {
        id,
        project_stage_id
      }

      const res = await this.changeStatusStage({data: payload})

      if(res.data.status === true){
        console.log('Status Stage Changed')
        this.getListRunningShipmentTable()
      }
    },

    async getListRunningShipmentTable (page = 1){
      this.loading = true
      this.list = {
        data:[],
        meta:null
      }
      let params = {
        page: page,
        perPage:50,
        ...this.params,
        periode_start:this.dateRange?.[0] ? moment(this.dateRange?.[0]).format('YYYY-MM-DD') : null ,
        periode_end:this.dateRange?.[1] ? moment(this.dateRange?.[1]).format('YYYY-MM-DD') : null,
      }

      if(!params.keyword) delete params.keyword
      if(!params.cargo_type_id) delete params.cargo_type_id
      if(!params.fleet_ownership) delete params.fleet_ownership
      if(!params.periode_start) delete params.periode_start
      if(!params.periode_end) delete params.periode_end

      let res = await this.fetchRunningShipmentTable(params)

      if (res.data.status === true && res.data.data?.data.length > 0) {
        this.list.data = res.data.data?.data
        this.list.meta = res.data.data?.meta
      } 
      this.loading = false
    },  

     // FILTER
     async onFilter(){
      await this.getListRunningShipmentTable()
    },
 
  }
}
</script>