<template>
  <b-container fluid>
    <StepWizard current-step="COST_BREAKDOWN" />
    <form v-if="detailMi && detailSurvey" action="" @submit.prevent="onSave">
      <b-row>
        <b-col lg="12">
          <iq-card>
            <template v-slot:body>
              <b-row>
                <b-col cols="6">
                  <img :src="image" alt="logo" class="img-fluid w-20">
                </b-col>
                <b-col cols="6" align-self="center">
                  <h4 class="mb-0 float-right text-primary"><b>{{ title }}</b></h4>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col lg="12">
                  <div class="table-responsive-sm">
                    <b-table-simple>
                      <thead>
                      <tr width="100%">
                        <th class="text-center" width="5%">NO</th>
                        <th width="45%">DESCRIPTION / ITEM</th>
                        <th width="20%">TOTAL</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(cb, index) in listBreakdowns" :key="index">
                        <td class="text-center">{{index+1}}</td>
                        <td>{{cb.description}}</td>
                        <td>
                          <b-form-input type="number" v-model="cb.value" placeholder="Text Number..." ></b-form-input>
                          <b-form-text class="text-muted"> {{ formatCurrency(cb.value) }}</b-form-text>
                        </td>
                      </tr>
                      <tr class="iq-bg-info">
                        <td colspan="4" class="text-right"><h5><b>SUB TOTAL</b></h5></td>
                        <td colspan="2"><h5><b>IDR. {{numberFormat(subTotal)}}</b></h5></td>
                      </tr>
                      <tr class="iq-bg-secondary">
                        <td colspan="4" class="text-right"><h5><b>DEDUCTIBLE</b></h5></td>
                        <td colspan="2">
                          <b-form-input type="number" v-model="form.deductible" required ></b-form-input>
                          <template v-if="detailMi.type_coverage && detailMi.type_coverage.name === 'Hull & Machinery'">
                            <br/><small>Price List Kapal HM x Deductible %</small>
                          </template>
                        </td>
                      </tr>
                      <tr class="iq-bg-primary">
                        <td colspan="4" class="text-right"><h5><b>GRAND TOTAL</b></h5></td>
                        <td colspan="2"><h5><b>IDR. {{numberFormat(grandTotal)}}</b></h5></td>
                      </tr>
                      </tbody>
                    </b-table-simple>
                  </div>
                </b-col>
                <b-col offset="6" cols="6" class="text-right">
                  <b-button variant="link mr-3">
                    <i class="ri-printer-line"></i>
                    Download Print
                  </b-button>
                  <b-button type="submit" variant="primary">
                    Submit
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { companiesActions, fleetsActions, miActions } from '@src/Utils/helper'
import { numberFormat } from '@src/plugins/helpers'
import StepNavigation from '@src/views/MI/component/StepNavigation'
import StepWizard from '@src/views/MI/component/StepWizard'

export default {
  name: 'Invoice',
  components: { StepNavigation, StepWizard },
  async mounted () {
    xray.index()
    await this.fetchMi()
    await this.fetchSurvey()
  },
  data () {
    return {
      permission_user:JSON.parse(localStorage.getItem('profile')).role.roles,
      detailMi: null,
      detailSurvey: null,
      listBreakdowns: [],
      image: require('../../assets/images/logo.png'),
      title: 'COST BREAKDOWN',
      form: {
        _saving: false,
        sub_total: 0,
        deductible: 0,
        grand_total: 0
      }
    }
  },
  computed: {
    userPermission(){
      if(['SUPER ADMIN', 'MARINE INSURANCE', 'MARINE INSURANCE ADMIN', 'MARINE INSURANCE MANAGER'].includes(this.permission_user)){
        return true
      } else{
        return false
      } 
    },
    subTotal () {
      let ret = 0

      this.listBreakdowns.forEach(list => {
        if (list.value > -0) {
          ret += parseInt(list.value)
        }
      })

      return ret
    },

    grandTotal () {
      return parseInt(this.subTotal) - parseInt(this.form.deductible)
    }
  },
  methods: {
    ...fleetsActions,
    ...companiesActions,
    ...miActions,
    numberFormat (number) {
      return numberFormat(number)
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      }).format(value)
    },
    async fetchMi () {
      const { data, status } = await this.fetchDetailMi({
        id: this.$route.query.formId,
        with: 'vehicle,type_coverage,type_loss,company,documents,company_claimant,status,creator,histories'
      })

      if ([500, 404, 403].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        return
      }

      this.detailMi = data
    },
    async fetchSurvey () {
      const { data, status } = await this.getCostBreakdownByFormId({
        id: this.$route.query.formId
      })

      if ([500, 404, 403].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        return
      }

      this.listBreakdowns = data.items
      this.detailSurvey = data

      if (data.breakdown && data.breakdown.deductible > 0) {
        this.form.deductible = data.breakdown.deductible || 0
      } else {
        this.fetchDeductible()
      }
    },
    async fetchDeductible () {
      const { data, status } = await this.getFormDeductible({
        id: this.$route.query.formId
      })

      if ([500, 404, 403].includes(status)) {
        return
      }

      this.form.deductible = data || 0
    },
    async onSave (e) {
      e.preventDefault()
      this.form._saving = true
      const contents = []
      this.listBreakdowns.forEach(content => {
        contents.push({
          'id': content.id,
          'value': parseInt(content.value)
        })
      })

      const { data, status } = await this.saveCostBreakdown({
        'form_id': this.$route.query.formId,
        'deductible': parseInt(this.form.deductible),
        'grand_total': parseInt(this.grandTotal),
        contents
      })

      if (status === 422) {
        this.$swal(
          `Oops!`,
          'Semua Field Wajib Diisi',
          'error'
        )
        this.form._saving = false

        return
      }

      if ([500, 404, 403].includes(status)) {
        this.$swal(
          `Oops!`,
          'Ada Yang Salah',
          'error'
        )
        this.form._saving = false

        return
      }

      this.$swal(
        `Tersimpan`,
        'Data Tersimpan',
        'success'
      )

      setTimeout(() => {
        this.$router.push('/mi/list-initial')
      }, 1500)

      this.form._saving = false
    }
  }
}
</script>
