<template>
  <div :class="`bg-white shadow rounded px-3 py-3 border border-white`">
    <div class="d-flex flex-column w-100">
      <div class="d-flex align-items-center justify-content-between">
        <span><strong class="text-dark">{{task.project}}</strong></span>
        <b-dropdown 
          size="lg"
          variant="link iq-bg-primary iq-border-radius-10"
          toggle-class="text-decoration-none"
          no-caret
        >
          <template v-slot:button-content>
            <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
              <i class="fa fa-pencil-square-o m-0 text-primary"></i>
            </span>
          </template>
          <b-dropdown-item href="#" variant="primary" @click="openSlideFormAddPlan('PREVIEW', task?.id)">
            <i class="fa fa-eye mr-2"></i>Detail
          </b-dropdown-item>
          <b-dropdown-item href="#" variant="warning" @click="openPfaSlide(task?.id)" v-if="task?.is_pfa">
            <i class="fa fa-info-circle mr-2"></i>PFA
          </b-dropdown-item>
          <b-dropdown-item href="#" variant="primary" @click="openProjectFormSlide(task?.id, task?.project)">
            <i class="fa fa-info-circle mr-2"></i>Collection Forms
          </b-dropdown-item>
          <b-dropdown-item href="#" variant="success" @click="openSlideSpalProcess('ADD', task?.id)" v-if="task?.project_stage?.spal_process">
            <i class="fa fa-info-circle mr-2"></i>Spal Process
          </b-dropdown-item>
          <b-dropdown-divider v-if="approvalPermission(task?.user_approval) === true || ['System Checker'].includes(userProfileName) && Number(task?.count_approval) !== Number(task?.many_approval)"></b-dropdown-divider>
          <b-dropdown-item href="#" variant="primary" v-if="approvalPermission(task?.user_approval) === true || ['System Checker'].includes(userProfileName) && Number(task?.count_approval) !== Number(task?.many_approval)" v-b-modal.project-approval @click="projectId = task?.id">
            <i class="fa fa-check mr-2"></i>Approval
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item href="#" variant="danger" @click="onDeleteProjectContract(task)">
            <i class="fa fa-trash mr-2"></i>Delete
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <!-- <b-form-rating no-border inline size="sm" variant="primary" v-model="task.prioritize" @change="onChangePrioritize(task.project_contract_id, task.prioritize)"></b-form-rating> -->
      <!-- <small class="text-center"><strong class="text-primary">{{ prioritizeProject(task.prioritize) }}</strong></small> -->
      <span><strong class="text-primary">{{task.project_type === 'existing' ? 'Existing' : "New Project" }}</strong></span>
      <span><strong class="text-muted">{{task.shipment}} SHIPMENT</strong></span>
      <span><strong class="text-muted">{{task.volume}} VOLUME</strong></span>
    </div>
    <div class="mt-4 align-items-center">
      <span><strong>Periode:</strong></span> <br/>
      <span class="text-sm text-dark"><strong>{{moment(task.periode_start).format("DD MMM YYYY")}}</strong> s/d <strong>{{moment(task.periode_end).format("DD MMM YYYY")}}</strong></span>
    </div>
    <small class="text-muted text-capitalize text-end">Created by: <strong class="text-primary">{{ task?.project_log?.[0]?.name }}</strong></small>
  </div>
</template>
<script>
import moment from 'moment';

export default {
  name:'TaskCard',
  props: {
    task: {
      type: Object,
      default: () => ({})
    },
    openSlideDetailProjectContract:{
      type:Function,
      default:() => {}
    }, 
    openSlideSpalProcess:{
      type:Function,
      default:() => {}
    },
    onChangePrioritize:{
      type:Function,
      default:() => {}
    }, 
    onDeleteProjectContract:{
      type:Function,
      default:() => {}
    },
    openPfaSlide:{
      type:Function,
      default:() => {}
    },
    openProjectFormSlide:{
      type:Function,
      default:() => {}
    }, 
    openSlideFormAddPlan:{
      type:Function,
      default:() => {}
    }, 
    onSaveApproval:{
      type:Function,
      default:() => {}
    },
  },
  data(){
    return {
      userProfileId: JSON.parse(localStorage.getItem('profile')).id,
      userProfileName: JSON.parse(localStorage.getItem('profile')).userDetail.name
    }
  },
  computed: {},
  methods:{
    approvalPermission(userArr){
      let tempArr = userArr.map(val => val.id)
      let countApproval = userArr.filter(user => user.status_approval !== "").length
      let manyApproval = userArr.length

      if (countApproval == manyApproval){
        return false
      } else if(tempArr.includes(this.userProfileId)){
        return true
      }  else { 
        return false
      }
      // return true
    },
    prioritizeProject(prioritize){
      if(prioritize == '1'){
        return 'Low'
      } else if (prioritize == '2'){
        return 'Medium'
      }else if (prioritize == '3'){
        return 'High'
      }else if (prioritize == '4'){
        return 'Very High'
      }else if (prioritize == '5'){
        return 'Immediately'
      } else {
        return "-"
      }
      // 'Prioritize: 1 = low, 2 = medium, 3 = high, 4 = very high, 5 = immediately'
    },
  }
};
</script>
