import axios from './auth'

const BASE_URL = `${process.env.VUE_APP_API_OPS_BASE_URL}/platform-modules`

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async updatePlatformModule({ commit }, payload = {}) {
    const url = `${BASE_URL}/${payload.id}`

    const method = 'put'
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    const config = {
      url,
      method,
      headers,
      data: payload.data
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data,
      }
    }
  },
  async getPlatformModuleById({ commit }, params = {}) {
    const url = `${BASE_URL}/${params.id}`

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
    };

    try {
      const response = await axios(config);
      let data = response.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async getListPlatformModule({ commit }, params = {}) {
    const url = `${BASE_URL}`

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params
    };

    try {
      const response = await axios(config);
      let data = response.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
}