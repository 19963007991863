<template>
  <ApexChart
    :element="`chart-technical-expanses-budget`"
    :chartOption="chartTechnicalExpanse"
  />
</template>
<script>
export default {
  data() {
    return {
      chartTechnicalExpanse: {
        chart: {
          type: 'bar',
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '90%',
            distributed: true,
          },
        },
        legend: {
          show: false,
        },
        colors: [
          '#0188AF',
          '#0000FF',
          '#F7941D',
          '#283D92',
          '#05B3B7',
          '#E85D04',
        ],
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '16px',
          },
        },
        series: [
          {
            data: [12, 34, 56, 78, 56, 30],
          },
        ],
        xaxis: {
          categories: [
            'Running Store',
            'Maintenance',
            'Repair',
            'Sparepart',
            'Lube Oil',
            'Others',
          ],
        },
        yaxis: {
          labels: {
            formatter: (val) => {
              return val // memformat label sumbu y menjadi persentase
            },
          },
          max: 100, // set nilai maksimum pada sumbu y
          tickAmount: 5,
        },
        fill: {
          opacity: 2,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + '%'
            },
          },
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        title: {
          text: 'Technical Expenses Juli 2024',
          floating: true,
          offsetY: 0,
          opacity: '0.7',
          align: 'center',
          style: {
            color: '#0088AF',
          },
        },
      },
    }
  },
}
</script>
