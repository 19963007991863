var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("FLEET PERFOMANCE")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Vessel",
            "label-for": "vessel"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "tag-placeholder": "Category",
            "placeholder": "Fleet Category",
            "label": "text",
            "track-by": "value",
            "options": _vm.fleetCategory,
            "multiple": true,
            "taggable": true
          },
          model: {
            value: _vm.fleetCategorySelect,
            callback: function callback($$v) {
              _vm.fleetCategorySelect = $$v;
            },
            expression: "fleetCategorySelect"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Company",
            "label-for": "company"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionCompany,
            "size": "sm"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Company")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedCompany,
            callback: function callback($$v) {
              _vm.selectedCompany = $$v;
            },
            expression: "selectedCompany"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Periode",
            "label-for": "periode"
          }
        }, [_c('date-picker', {
          attrs: {
            "lang": _vm.lang,
            "type": "date",
            "range": "",
            "placeholder": "Select date range"
          },
          model: {
            value: _vm.dateRange,
            callback: function callback($$v) {
              _vm.dateRange = $$v;
            },
            expression: "dateRange"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped table-bordered"
        }, [_c('thead', [_c('tr', {
          staticClass: "bg-primary"
        }, [_c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("NO")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("VESSEL")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("FREIGHT CHARTER")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("TIME CHARTER")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("BUNKER")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("SUB KONTRAKTOR")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("STEVEDORING")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("AGENCY")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("CREW")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("SALLARY/ALLOWANCE")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("CERTIFICATE & DOCUMENTATION")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("REPAIRING & MAINTENANCE")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("PROJECT CONSUMABLE")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("INSURANCE")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("DEMORAGE/DISPATCH")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("OTHER COST")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "colspan": "3"
          }
        }, [_vm._v("TOTAL COST")])]), _c('tr', {
          staticClass: "bg-primary"
        }, [_c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("REVENEU")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("TOTAL GROSS PROFIT (LOSS)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("MARGIN (%)")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("-")])])])])])])], 1), _c('b-sidebar', {
          attrs: {
            "id": "detail_rakor",
            "title": "TAHUN 2023",
            "width": "70%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref) {
              var hide = _ref.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('table', {
                staticClass: "table table-striped table-sm"
              }, [_c('thead', [_c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("NO")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("LOCATION")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("TERM")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("PLAN DATE")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("PLAN TRIP")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("PLAN CARGO")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("ACTION")])])]), _c('tbody', [_c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("1")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("LABUAN")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("CIF")]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-badge', {
                staticClass: "mb-1",
                attrs: {
                  "variant": "success d-block"
                }
              }, [_vm._v("18/01/2023")]), _c('b-badge', {
                staticClass: "mb-1",
                attrs: {
                  "variant": "success d-block"
                }
              }, [_vm._v("20/01/2023")]), _c('b-badge', {
                staticClass: "mb-1",
                attrs: {
                  "variant": "success d-block"
                }
              }, [_vm._v("25/01/2023")])], 1), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("3")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("30.000")]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-dropdown', {
                attrs: {
                  "size": "lg",
                  "variant": "link iq-bg-primary iq-border-radius-10",
                  "toggle-class": "text-decoration-none",
                  "no-caret": ""
                },
                scopedSlots: _vm._u([{
                  key: "button-content",
                  fn: function fn() {
                    return [_c('span', {
                      staticClass: "dropdown-toggle p-0",
                      attrs: {
                        "id": "dropdownMenuButton5",
                        "data-toggle": "dropdown"
                      }
                    }, [_c('i', {
                      staticClass: "fa fa-eye m-0 text-primary"
                    })])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-dropdown-item', {
                attrs: {
                  "href": "#",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o mr-2"
              }), _vm._v("EDIT")]), _c('b-dropdown-item', {
                attrs: {
                  "href": "#",
                  "variant": "danger"
                }
              }, [_c('i', {
                staticClass: "fa fa-trash mr-2"
              }), _vm._v("HAPUS")])], 1)], 1)]), _c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("2")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("LABUAN")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("FOB")]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-badge', {
                staticClass: "mb-1",
                attrs: {
                  "variant": "success d-block"
                }
              }, [_vm._v("18/01/2023")]), _c('b-badge', {
                staticClass: "mb-1",
                attrs: {
                  "variant": "success d-block"
                }
              }, [_vm._v("23/01/2023")]), _c('b-badge', {
                staticClass: "mb-1",
                attrs: {
                  "variant": "success d-block"
                }
              }, [_vm._v("27/01/2023")])], 1), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("3")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("37.000")]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-dropdown', {
                attrs: {
                  "size": "lg",
                  "variant": "link iq-bg-primary iq-border-radius-10",
                  "toggle-class": "text-decoration-none",
                  "no-caret": ""
                },
                scopedSlots: _vm._u([{
                  key: "button-content",
                  fn: function fn() {
                    return [_c('span', {
                      staticClass: "dropdown-toggle p-0",
                      attrs: {
                        "id": "dropdownMenuButton5",
                        "data-toggle": "dropdown"
                      }
                    }, [_c('i', {
                      staticClass: "fa fa-eye m-0 text-primary"
                    })])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-dropdown-item', {
                attrs: {
                  "href": "#",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o mr-2"
              }), _vm._v("EDIT")]), _c('b-dropdown-item', {
                attrs: {
                  "href": "#",
                  "variant": "danger"
                }
              }, [_c('i', {
                staticClass: "fa fa-trash mr-2"
              }), _vm._v("HAPUS")])], 1)], 1)])])]), _c('div', {
                staticClass: "mt-3"
              }, [_c('b-pagination', {
                attrs: {
                  "value": 1,
                  "total-rows": 50,
                  "align": "center"
                }
              })], 1)])], 1)];
            }
          }]),
          model: {
            value: _vm.slideDetailRakor,
            callback: function callback($$v) {
              _vm.slideDetailRakor = $$v;
            },
            expression: "slideDetailRakor"
          }
        }), _c('b-sidebar', {
          attrs: {
            "id": "add_rakor",
            "title": "ADD BUDGET",
            "width": "100%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var hide = _ref2.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Company",
                  "label-for": "company"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionCompany,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Company")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedCompany,
                  callback: function callback($$v) {
                    _vm.selectedCompany = $$v;
                  },
                  expression: "selectedCompany"
                }
              })], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "2"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Tahun",
                  "label-for": "tahun"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionTahun,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Tahun")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedTahun,
                  callback: function callback($$v) {
                    _vm.selectedTahun = $$v;
                  },
                  expression: "selectedTahun"
                }
              })], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "2"
                }
              }, [_c('b-form-group', {
                attrs: {
                  "label": "Search",
                  "label-for": "button_search"
                }
              }, [_c('b-button', {
                staticClass: "mb-3 mr-1",
                attrs: {
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-search"
              })])], 1)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('b-row', {
                staticClass: "mb-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_vm._l(_vm.client, function (item, index) {
                return [_c('b-form-radio', {
                  key: index,
                  attrs: {
                    "inline": "",
                    "name": item.name,
                    "value": item.value,
                    "disabled": item.disabled
                  },
                  model: {
                    value: _vm.clientActive[item[Object.keys(item)[0]]],
                    callback: function callback($$v) {
                      _vm.$set(_vm.clientActive, item[Object.keys(item)[0]], $$v);
                    },
                    expression: "clientActive[item[Object.keys(item)[0]]]"
                  }
                }, [_vm._v(_vm._s(item.label))])];
              })], 2), _c('b-col', {
                attrs: {
                  "md": "3"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionCompany,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Company")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedCompany,
                  callback: function callback($$v) {
                    _vm.selectedCompany = $$v;
                  },
                  expression: "selectedCompany"
                }
              })], 1)], 1), _c('table', {
                staticClass: "table table-striped"
              }, [_c('thead', [_c('tr', [_c('th', {
                staticClass: "text-center align-middle",
                attrs: {
                  "rowspan": "2"
                }
              }, [_vm._v("NO")]), _c('th', {
                staticClass: "text-center align-middle",
                attrs: {
                  "rowspan": "2"
                }
              }, [_vm._v("DESKRIPSI")]), _c('th', {
                staticClass: "text-center align-middle",
                attrs: {
                  "rowspan": "2"
                }
              }, [_vm._v("SPESIFIKASI")]), _c('th', {
                staticClass: "text-center align-middle",
                attrs: {
                  "rowspan": "2"
                }
              }, [_vm._v("OWNED/CHARTERED")]), _c('th', {
                staticClass: "text-center align-middle",
                attrs: {
                  "colspan": "2"
                }
              }, [_vm._v("JAN")]), _c('th', {
                staticClass: "text-center align-middle",
                attrs: {
                  "colspan": "2"
                }
              }, [_vm._v("FEB")]), _c('th', {
                staticClass: "text-center align-middle",
                attrs: {
                  "colspan": "2"
                }
              }, [_vm._v("MAR")])]), _c('tr', [_c('th', {
                staticClass: "text-center align-middle"
              }, [_vm._v("CARGO")]), _c('th', {
                staticClass: "text-center align-middle"
              }, [_vm._v("SALES")]), _c('th', {
                staticClass: "text-center align-middle"
              }, [_vm._v("CARGO")]), _c('th', {
                staticClass: "text-center align-middle"
              }, [_vm._v("SALES")]), _c('th', {
                staticClass: "text-center align-middle"
              }, [_vm._v("CARGO")]), _c('th', {
                staticClass: "text-center align-middle"
              }, [_vm._v("SALES")])])]), _c('tbody', [_c('tr', [_c('th', {
                staticClass: "text-center",
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v("AI - PLTU PROJECT")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("-")])]), _c('tr', [_c('th', {
                staticClass: "text-center"
              }, [_vm._v("#1")]), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "keyword"
                }
              })], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "keyword"
                }
              })], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "size": "sm"
                }
              }, [_c('b-form-select-option', [_vm._v("CHARTERED")]), _c('b-form-select-option', [_vm._v("OWNED")])], 1)], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "keyword"
                }
              })], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "keyword"
                }
              })], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "keyword"
                }
              })], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "keyword"
                }
              })], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "keyword"
                }
              })], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "keyword"
                }
              })], 1)])])]), _c('b-button', {
                attrs: {
                  "block": "",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-plus pr-0"
              }), _vm._v(" ADD MORE DATA")]), _c('b-button', {
                attrs: {
                  "block": "",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-plus pr-0"
              }), _vm._v(" ADD MORE PROJECT")])], 1)], 1)], 1)];
            }
          }]),
          model: {
            value: _vm.slideAddRakor,
            callback: function callback($$v) {
              _vm.slideAddRakor = $$v;
            },
            expression: "slideAddRakor"
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }