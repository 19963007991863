<template>
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1533 13.5014C13.413 14.2417 12.9971 15.2458 12.9971 16.2928C12.9971 17.3398 13.413 18.3439 14.1533 19.0843C14.8937 19.8246 15.8978 20.2405 16.9448 20.2405C17.9918 20.2405 18.9959 19.8246 19.7362 19.0843M20.5099 14.5843C20.1153 13.7601 19.4471 13.0984 18.6191 12.7119"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.3161 19.5789V25.5L16.9477 23.5263L19.5793 25.5V19.5789M10.3687 21.5526H3.78978C3.09184 21.5526 2.42249 21.2754 1.92897 20.7819C1.43546 20.2883 1.1582 19.619 1.1582 18.9211V5.76316C1.1582 4.31579 2.34241 3.13158 3.78978 3.13158M9.05294 3.13158H22.2108C22.9088 3.13158 23.5781 3.40883 24.0716 3.90235C24.5652 4.39587 24.8424 5.06522 24.8424 5.76316V18.9211M5.10557 8.39474H9.05294M14.3161 8.39474H20.895M5.10557 12.3421H9.05294M5.10557 16.2895H7.73715M1.1582 0.5L24.8424 24.1842"
      :stroke="color"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
}
</script>
