<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
              <h5 class="card-title text-primary"><strong>FLEET PERFOMANCE</strong></h5>
          </template>
          <template v-slot:body>
            <!-- FILTER START -->
            <b-row>
              <b-col md="3">
                <b-form-group label="Vessel" label-for="vessel">
                  <v-multiselect v-model="fleetCategorySelect" tag-placeholder="Category" placeholder="Fleet Category" label="text" track-by="value" :options="fleetCategory" :multiple="true" :taggable="true"></v-multiselect>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Company" label-for="company">
                  <b-form-select plain v-model="selectedCompany" :options="optionCompany" size="sm">
                    <template v-slot:first>
                    <b-form-select-option :value="null">Select Company</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Periode" label-for="periode">
                  <date-picker :lang="lang" v-model="dateRange" type="date" range placeholder="Select date range"></date-picker>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group style label="Search" label-for="button_search">
                  <b-button variant="primary" class="mb-3 mr-1"><i class="fa fa-search"></i></b-button>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- FILTER END -->

            <b-row>
              <b-col md="12">
                <div class="table-responsive">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr class="bg-primary">
                        <th class="text-center align-middle" rowspan="2">NO</th>
                        <th class="text-center align-middle" rowspan="2">VESSEL</th>
                        <th class="text-center align-middle" rowspan="2">FREIGHT&nbsp;CHARTER</th>
                        <th class="text-center align-middle" rowspan="2">TIME&nbsp;CHARTER</th>
                        <th class="text-center align-middle" rowspan="2">BUNKER</th>
                        <th class="text-center align-middle" rowspan="2">SUB&nbsp;KONTRAKTOR</th>
                        <th class="text-center align-middle" rowspan="2">STEVEDORING</th>
                        <th class="text-center align-middle" rowspan="2">AGENCY</th>
                        <th class="text-center align-middle" rowspan="2">CREW</th>
                        <th class="text-center align-middle" rowspan="2">SALLARY/ALLOWANCE</th>
                        <th class="text-center align-middle" rowspan="2">CERTIFICATE&nbsp;&amp;&nbsp;DOCUMENTATION</th>
                        <th class="text-center align-middle" rowspan="2">REPAIRING&nbsp;&amp;&nbsp;MAINTENANCE</th>
                        <th class="text-center align-middle" rowspan="2">PROJECT&nbsp;CONSUMABLE</th>
                        <th class="text-center align-middle" rowspan="2">INSURANCE</th>
                        <th class="text-center align-middle" rowspan="2">DEMORAGE/DISPATCH</th>
                        <th class="text-center align-middle" rowspan="2">OTHER&nbsp;COST</th>
                        <th class="text-center align-middle" colspan="3">TOTAL&nbsp;COST</th>
                      </tr>
                      <tr class="bg-primary">
                        <th class="text-center align-middle">REVENEU</th>
                        <th class="text-center align-middle">TOTAL&nbsp;GROSS&nbsp;PROFIT&nbsp;(LOSS)</th>
                        <th class="text-center align-middle">MARGIN&nbsp;(%)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-center">-</td>
                        <td class="text-center">-</td>
                        <td class="text-center">-</td>
                        <td class="text-center">-</td>
                        <td class="text-center">-</td>
                        <td class="text-center">-</td>
                        <td class="text-center">-</td>
                        <td class="text-center">-</td>
                        <td class="text-center">-</td>
                        <td class="text-center">-</td>
                        <td class="text-center">-</td>
                        <td class="text-center">-</td>
                        <td class="text-center">-</td>
                        <td class="text-center">-</td>
                        <td class="text-center">-</td>
                        <td class="text-center">-</td>
                        <td class="text-center">-</td>
                        <td class="text-center">-</td>
                        <td class="text-center">-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>

            <!-- SLIDE - FORM DETAIL RAKOR -->
            <b-sidebar
              v-model="slideDetailRakor"
              id="detail_rakor"
              title="TAHUN 2023"
              width="70%"
              backdrop-variant="dark"
              bg-variant="white"
              right
              backdrop
              shadow>
              <template #default="{hide}">
                <b-row class="m-3">
                  <b-col cols="12">
                    <table class="table table-striped table-sm">
                      <thead>
                        <tr>
                          <th class="text-center">NO</th>
                          <th class="text-center">LOCATION</th>
                          <th class="text-center">TERM</th>
                          <th class="text-center">PLAN DATE</th>
                          <th class="text-center">PLAN TRIP</th>
                          <th class="text-center">PLAN CARGO</th>
                          <th class="text-center">ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th class="text-center">1</th>
                          <td class="text-center">LABUAN</td>
                          <td class="text-center">CIF</td>
                          <td class="text-center">
                            <b-badge variant="success d-block" class="mb-1">18/01/2023</b-badge>
                            <b-badge variant="success d-block" class="mb-1">20/01/2023</b-badge>
                            <b-badge variant="success d-block" class="mb-1">25/01/2023</b-badge>
                          </td>
                          <td class="text-center">3</td>
                          <td class="text-center">30.000</td>
                          <td class="text-center">
                            <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                              <template v-slot:button-content>
                                <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                  <i class="fa fa-eye m-0 text-primary"></i>
                                </span>
                              </template>
                              <b-dropdown-item href="#" variant="info"><i class="fa fa-pencil-square-o mr-2"></i>EDIT</b-dropdown-item>
                              <b-dropdown-item href="#" variant="danger"><i class="fa fa-trash mr-2"></i>HAPUS</b-dropdown-item>
                            </b-dropdown>
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center">2</th>
                          <td class="text-center">LABUAN</td>
                          <td class="text-center">FOB</td>
                          <td class="text-center">
                            <b-badge variant="success d-block" class="mb-1">18/01/2023</b-badge>
                            <b-badge variant="success d-block" class="mb-1">23/01/2023</b-badge>
                            <b-badge variant="success d-block" class="mb-1">27/01/2023</b-badge>
                          </td>
                          <td class="text-center">3</td>
                          <td class="text-center">37.000</td>
                          <td class="text-center">
                            <b-dropdown size="lg"  variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                              <template v-slot:button-content>
                                <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                  <i class="fa fa-eye m-0 text-primary"></i>
                                </span>
                              </template>
                              <b-dropdown-item href="#" variant="info"><i class="fa fa-pencil-square-o mr-2"></i>EDIT</b-dropdown-item>
                              <b-dropdown-item href="#" variant="danger"><i class="fa fa-trash mr-2"></i>HAPUS</b-dropdown-item>
                            </b-dropdown>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="mt-3">
                      <b-pagination :value="1" :total-rows="50" align="center" />
                    </div>
                  </b-col>
                </b-row>
              </template>
            </b-sidebar>
            <!-- SLIDE - FORM DETAIL RAKOR END -->

            <!-- SLIDE - FORM ADD PLAN -->
            <b-sidebar
              v-model="slideAddRakor"
              id="add_rakor"
              title="ADD BUDGET"
              width="100%"
              backdrop-variant="dark"
              bg-variant="white"
              right
              backdrop
              shadow>
              <template #default="{hide}">
                <b-row class="m-3">
                  <b-col cols="12">
                    <!-- FILTER -->
                    <b-row>
                      <b-col md="3">
                        <b-form-group label="Company" label-for="company">
                          <b-form-select plain v-model="selectedCompany" :options="optionCompany" size="sm">
                            <template v-slot:first>
                            <b-form-select-option :value="null">Select Company</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="2">
                        <b-form-group label="Tahun" label-for="tahun">
                          <b-form-select plain v-model="selectedTahun" :options="optionTahun" size="sm">
                            <template v-slot:first>
                            <b-form-select-option :value="null">Select Tahun</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-form-group>
                      </b-col>
                      <b-col md="2">
                        <b-form-group style label="Search" label-for="button_search">
                          <b-button variant="primary" class="mb-3 mr-1"><i class="fa fa-search"></i></b-button>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <!-- FILTER END -->
                    <fieldset class="form-group border p-3">
                      <b-row class="mb-3">
                        <b-col md="3">
                          <template v-for="(item,index) in client">
                            <b-form-radio inline v-model="clientActive[item[Object.keys(item)[0]]]" :name="item.name" :key="index" :value="item.value" :disabled="item.disabled">{{ item.label }}</b-form-radio>
                          </template>
                        </b-col>
                        <b-col md="3">
                          <b-form-select plain v-model="selectedCompany" :options="optionCompany" size="sm">
                            <template v-slot:first>
                            <b-form-select-option :value="null">Select Company</b-form-select-option>
                            </template>
                          </b-form-select>
                        </b-col>
                      </b-row>
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="text-center align-middle" rowspan="2">NO</th>
                            <th class="text-center align-middle" rowspan="2">DESKRIPSI</th>
                            <th class="text-center align-middle" rowspan="2">SPESIFIKASI</th>
                            <th class="text-center align-middle" rowspan="2">OWNED/CHARTERED</th>
                            <th class="text-center align-middle" colspan="2">JAN</th>
                            <th class="text-center align-middle" colspan="2">FEB</th>
                            <th class="text-center align-middle" colspan="2">MAR</th>
                          </tr>
                          <tr>
                            <th class="text-center align-middle">CARGO</th>
                            <th class="text-center align-middle">SALES</th>
                            <th class="text-center align-middle">CARGO</th>
                            <th class="text-center align-middle">SALES</th>
                            <th class="text-center align-middle">CARGO</th>
                            <th class="text-center align-middle">SALES</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th class="text-center" colspan="4">AI - PLTU PROJECT</th>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                            <td class="text-center">-</td>
                          </tr>
                          <tr>
                            <th class="text-center">#1</th>
                            <td class="text-center"><b-form-input type="text" id="keyword"></b-form-input></td>
                            <td class="text-center"><b-form-input type="text" id="keyword"></b-form-input></td>
                            <td class="text-center">
                              <b-form-select plain size="sm">
                                <b-form-select-option>CHARTERED</b-form-select-option>
                                <b-form-select-option>OWNED</b-form-select-option>
                              </b-form-select>
                            </td>
                            <td class="text-center"><b-form-input type="text" id="keyword"></b-form-input></td>
                            <td class="text-center"><b-form-input type="text" id="keyword"></b-form-input></td>
                            <td class="text-center"><b-form-input type="text" id="keyword"></b-form-input></td>
                            <td class="text-center"><b-form-input type="text" id="keyword"></b-form-input></td>
                            <td class="text-center"><b-form-input type="text" id="keyword"></b-form-input></td>
                            <td class="text-center"><b-form-input type="text" id="keyword"></b-form-input></td>
                          </tr>
                        </tbody>
                      </table>
                      <b-button block variant="primary"><i class="fa fa-plus pr-0"></i> ADD MORE DATA</b-button>
                      <b-button block variant="success"><i class="fa fa-plus pr-0"></i> ADD MORE PROJECT</b-button>
                    </fieldset>
                  </b-col>
                </b-row>
              </template>
            </b-sidebar>
            <!-- SLIDE - FORM ADD PLAN END -->
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import _ from 'lodash'
import {
  fleetsActions
} from '@src/Utils/helper'
import { createPopper } from '@popperjs/core'

export default {
  name: 'ProfileEdit',
  mounted () {
    xray.index()
    this.fetchFleets()
  },
  data () {
    return {
      // fleet
      slideDetailRakor: false,
      slideAddRakor: false,

      fleetCategorySelect:[],
      fleetCategory: [
        {
          text: 'Tug Boat',
          color: 'success',
          value: 'tugboat'
        },
        {
          text: 'Barge',
          color: 'warning',
          value: 'barge'
        },
        {
          text: 'Mother Vessel',
          color: 'primary',
          value: 'mother_vessel'
        },
        {
          text: 'Heavy Equipment',
          color: 'primary',
          value: 'heavy_equipment'
        },
        {
          text: 'Pusher Tug',
          color: 'primary',
          value: 'pusher_tug'
        },
        {
          text: 'Pusher Barge',
          color: 'primary',
          value: 'pusher_barge'
        }
      ],

      client: [
        {
          name: 'single',
          label: 'NEW CLIENT',
          value: 'active',
          disabled: false
        },
        {
          name: 'single',
          label: 'EXISTING CLIENT',
          value: 'inactive',
          disabled: false
        }
      ],

      // SELECT COMPANY
      selectedCompany: 1,
      optionCompany: [
        { value: '1', text: 'PT. ENERGI TRANSPORTER INDONESIA' },
        { value: '2', text: 'PT. SENTRA MAKMUR LINES' }
      ],
    }
  }
}
</script>