var render = function render(){
  var _vm$detailSpal, _vm$detailSpal$contra, _attrs;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [!_vm.detail_loading ? _c('form', {
    attrs: {
      "action": "#"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSave.apply(null, arguments);
      }
    }
  }, [_vm.detail_loading ? _c('b-row', {
    staticClass: "p-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "text-center my-5"
  }, [_c('b-spinner', {
    attrs: {
      "type": "grow",
      "label": "Spinning"
    }
  })], 1)])], 1) : _c('b-row', {
    staticClass: "p-2"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', {
    staticClass: "mt-2"
  }, [['PREVIEW'].includes(_vm.action) ? _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "lg": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.changeAction('EDIT');
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-pen"
  }), _vm._v(" EDIT SPAL")])], 1) : _vm._e(), ['EDIT'].includes(_vm.action) ? _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "lg": "12"
    }
  }, [_c('b-button', {
    attrs: {
      "size": "sm",
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        return _vm.changeAction('PREVIEW');
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-ban"
  }), _vm._v(" CANCEL")])], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "NAME OF TEMPLATE"
    }
  }, [['ADD', 'EDIT'].includes(_vm.action) ? _c('b-form-input', {
    attrs: {
      "placeholder": "Type Text"
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }) : _c('span', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.form.name))])], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "CONTRACT TYPE"
    }
  }, [['ADD', 'EDIT'].includes(_vm.action) ? _c('b-form-select', {
    attrs: {
      "plain": "",
      "size": "sm",
      "placeholder": "Select Options...",
      "options": _vm.optionsContractType
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("Select Options")])];
      },
      proxy: true
    }], null, false, 449514246),
    model: {
      value: _vm.form.contract_type_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "contract_type_id", $$v);
      },
      expression: "form.contract_type_id"
    }
  }) : _c('span', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s((_vm$detailSpal = _vm.detailSpal) === null || _vm$detailSpal === void 0 ? void 0 : (_vm$detailSpal$contra = _vm$detailSpal.contract_type) === null || _vm$detailSpal$contra === void 0 ? void 0 : _vm$detailSpal$contra.name))])], 1)], 1)], 1), _c('b-row', {
    staticClass: "my-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "LANGUAGE"
    }
  }, [['ADD', 'EDIT'].includes(_vm.action) ? _c('v-multiselect', {
    attrs: (_attrs = {
      "options": [{
        text: 'INDONESIA',
        value: 'IND'
      }, {
        text: 'ENGLISH',
        value: 'ENG'
      }, {
        text: 'MANDARIN',
        value: 'MDN'
      }],
      "multiple": true,
      "group-label": "text",
      "group-select": true,
      "placeholder": "",
      "track-by": "value",
      "label": "text"
    }, _attrs["placeholder"] = "Select languange", _attrs),
    model: {
      value: _vm.form.language,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "language", $$v);
      },
      expression: "form.language"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v("Oops! No elements found. Consider changing the searchquery.")])]) : _c('div', {
    staticClass: "d-flex flex-wrap"
  }, _vm._l(_vm.form.language, function (state, index) {
    return _c('b-badge', {
      key: index,
      staticClass: "border border-primary text-primary text-uppercase mb-2 mr-2",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.text))]);
  }), 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "border d-flex my-2 w-100"
  })])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": ['ADD', 'EDIT'].includes(_vm.action) ? '8' : '12'
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "CONTENT"
    }
  }, [_c('wysiwyg', {
    attrs: {
      "height": '400px',
      "disabled": ""
    },
    model: {
      value: _vm.form.content,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1)], 1), ['ADD', 'EDIT'].includes(_vm.action) ? _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('span', [_vm._v("LIST OF VARIABLES")]), _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "alert alert-primary"
  }, [_vm._v(" SHIPMENT PLANNING ")])]), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-wrap"
  }, _vm._l(_vm.listVariables.shipmentPlanning, function (state, index) {
    return _c('b-badge', {
      key: index,
      class: "border border-primary ".concat(state.selected ? '' : 'text-primary', " text-uppercase mr-2 mb-2"),
      staticStyle: {
        "cursor": "pointer"
      },
      attrs: {
        "variant": state.selected ? 'primary' : 'none'
      },
      on: {
        "click": function click($event) {
          return _vm.onHandleVariables(state);
        }
      }
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.label))]);
  }), 1)])], 1), _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "alert alert-primary"
  }, [_vm._v(" CUSTOMER INFO ")])]), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-wrap"
  }, _vm._l(_vm.listVariables.customer, function (state, index) {
    return _c('b-badge', {
      key: index,
      class: "border border-primary ".concat(state.selected ? '' : 'text-primary', " text-uppercase mr-2 mb-2"),
      staticStyle: {
        "cursor": "pointer"
      },
      attrs: {
        "variant": state.selected ? 'primary' : 'none'
      },
      on: {
        "click": function click($event) {
          return _vm.onHandleVariables(state);
        }
      }
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.label))]);
  }), 1)])], 1), _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "alert alert-primary"
  }, [_vm._v(" OTHERS ")])]), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-wrap"
  }, _vm._l(_vm.listVariables.others, function (state, index) {
    return _c('b-badge', {
      key: index,
      class: "border border-primary ".concat(state.selected ? '' : 'text-primary', " text-uppercase mr-2 mb-2"),
      staticStyle: {
        "cursor": "pointer"
      },
      attrs: {
        "variant": state.selected ? 'primary' : 'none'
      },
      on: {
        "click": function click($event) {
          return _vm.onHandleVariables(state);
        }
      }
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.label))]);
  }), 1)])], 1)], 1) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "border w-100 d-flex my-4"
  })]), ['ADD', 'EDIT'].includes(_vm.action) ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end w-100"
  }, [_c('b-button', {
    staticClass: "mr-4 mt-2",
    staticStyle: {
      "width": "15%"
    },
    attrs: {
      "block": "",
      "variant": "danger",
      "type": "button"
    }
  }, [_c('i', {
    staticClass: "fa fa-remove"
  }), _c('span', [_vm._v("Cancel")])]), _c('b-button', {
    staticStyle: {
      "width": "20%"
    },
    attrs: {
      "disabled": _vm.saving,
      "block": "",
      "variant": "primary",
      "type": "submit"
    }
  }, [_vm.saving ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _c('div', [_c('i', {
    staticClass: "fa fa-save"
  }), _c('span', [_vm._v("Submit Data")])])])], 1)]) : _vm._e()], 1)], 1) : _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "alert alert-info m-4"
  }, [_vm._v(" Loading... ")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }