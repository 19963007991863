<template>
  <b-container fluid>
    <!-- Tab -->
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items class="col-md-3 p-0" :active="true" href="#masterCategory" :title="findMenuPermissionLv2ByIndex('menu', $route.query.menuId, 0)" />
                <tab-nav-items class="col-md-3 p-0" :active="false" href="#masterModule" :title="findMenuPermissionLv2ByIndex('menu', $route.query.menuId, 1)" />
                <tab-nav-items class="col-md-3 p-0" :active="false" href="#setting" :title="findMenuPermissionLv2ByIndex('menu', $route.query.menuId, 2)" />
                <tab-nav-items class="col-md-3 p-0" :active="false" href="#fleetForms" title="Fleet Forms" />
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Tab -->
    <div id="pills-tabContent-2" class="tab-content">
      <tab-content-item :active="true" id="masterCategory">
        <b-row>
          <b-col :md="!findMenuPermissionLv2ByIndex('update', $route.query.menuId, 0) && !findMenuPermissionLv2ByIndex('add', $route.query.menuId, 0) ? '12' : '8'">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title text-primary">
                  <strong>List Fleets Category</strong>
                </h4>
              </template>
              <template v-slot:body>
                <div v-if="loading_category" class="text-center my-5">
                  <b-spinner type="grow" label="Spinning"></b-spinner>
                </div>
                <template v-else>
                  <div class="overflow-auto" v-if="fleet_types.length > 0">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th width="5%">No</th>
                          <th width="10%">Group</th>
                          <th width="10%">Category</th>
                          <th width="10%">Crew</th>
                          <th width="20%" class="text-truncate">Sea Trial / Section</th>
                          <th width="20%">Towing</th>
                          <th width="10%">Images/Icon</th>
                          <th width="5%">Status</th>
                          <th width="10%" class="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(c, i) in fleet_types" :key="`c-${i}`">
                          <td>{{ i + 1 }}</td>
                          <td>{{ c?.vehicleTypeGroup?.name }}</td>
                          <td>{{ c?.name }}</td>
                          <td><b-badge variant="success">{{ c.crewing ? 'Yes' : 'No' }}</b-badge><br/>Capacity : {{ c?.crewCapacity }}</td>
                          <td><b-badge variant="success">Sea Trial: {{ c?.seaTrial ? 'Yes' : 'No' }}</b-badge><br/>
                            <div class="d-flex flex-column">
                              <small>Section : </small>
                              <b-badge v-for="(state, index) in c?.vehicleTypeSection" :key="index" class="border border-primary text-primary text-uppercase mb-1 mr-1" variant="none">{{ state.name }}</b-badge>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex flex-column">
                              <b-badge v-for="(state, index) in c?.vehicleTypeTow" :key="index" class="border border-primary text-primary text-uppercase mb-1 mr-1" variant="none">{{ state.name }}</b-badge>
                            </div>
                          </td>
                          <td>
                            <img
                              v-if="c.icon"
                              :src="c.icon.url"
                              :alt="c.name"
                              width="175px"
                              height="auto"
                            />
                            <span v-else>-</span>
                          </td>
                          <td>{{ c.active ? 'Active' : 'Inactive' }}</td>
                          <td class="d-flex">
                            <b-button
                              v-if="findMenuPermissionLv2ByIndex('update', $route.query.menuId, 0)"
                              variant="success"
                              size="sm"
                              class="mr-2"
                              @click="onEditCategory(c)"
                              ><i class="fa fa-edit"></i
                            ></b-button>
                            <b-button
                              v-if="findMenuPermissionLv2ByIndex('delete', $route.query.menuId, 0)"
                              variant="danger"
                              size="sm"
                              @click="onDeleteCategory(c)"
                              ><i class="fa fa-trash"></i
                            ></b-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p v-else class="text-center my-4">No categories data found.</p>
                </template>
              </template>
            </iq-card>
          </b-col>
          <b-col md="4"
            v-if="findMenuPermissionLv2ByIndex('update', $route.query.menuId, 0) || findMenuPermissionLv2ByIndex('add', $route.query.menuId, 0)">
            <iq-card :class="{ 'border border-danger shadow-lg': form_category_edit_mode }">
              <template v-slot:headerTitle>
                <h4 class="card-title text-primary">
                  <strong>{{ form_category_edit_mode ? 'Edit' : 'Add' }} Fleets Category</strong>
                </h4>
              </template>
              <template v-slot:body>
                <b-form @submit.prevent="onSubmitFormCategory">
                  <b-form-group
                    label="Category Name"
                    label-for="category_name"
                  >
                    <b-form-input
                      id="category_name"
                      type="text"
                      placeholder="Category Name"
                      v-model="form.category.name"
                    ></b-form-input>
                  </b-form-group>
                  
                  <b-form-group label="Type group" label-for="type_group">
                    <b-row>
                      <b-col>
                        <v-multiselect
                          :options="optionsVehicleTypeGroup"
                          placeholder="Select Vehicle Type Group"
                          track-by="id"
                          label="name"
                          v-model="form.category.group"
                        >
                          <span slot="noResult"
                          >Oops! No elements found. Consider changing the search
                            query.</span
                          >
                        </v-multiselect>
                      </b-col>
                      <b-button variant="outline-primary" class="mr-2" v-b-modal.type-group v-b-tooltip.hover title="Add More Type Group"><i class="fa fa-plus"></i></b-button>
                    </b-row>
                  </b-form-group>

                  <b-form-group
                    label="Icon"
                    label-for="imagesModulUrl"
                    description="Accept only .jpg, .jpeg, .gif, and .png"
                  >
                    <img
                      v-if="form.category.icon && form.category.icon.url"
                      :src="form.category.icon.url"
                      :alt="form.category.name"
                      width="256px"
                      height="auto"
                      class="mb-3"
                    />
                    <b-form-file
                      accept="image/jpeg, image/png, image/jpg, image/gif"
                      name="category_icon"
                      :disabled="loading_upload_icon"
                      :browse-text="
                        loading_upload_icon ? 'Uploading...' : 'Browse'
                      "
                      ref="form_upload_icon"
                      @change="onChangeIcon"
                    ></b-form-file>
                  </b-form-group>
                  <b-form-group label="There's Crew ?" label-for="is_crewing">
                    <b-form-checkbox
                      name="check-button"
                      switch
                      inline
                      v-model="form.category.isCrewing"
                    >
                      Active / Inactive
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group label="Crew Capacity" label-for="crew_capacity" v-if="form.category.isCrewing">
                    <b-form-input id="crew_capacity" type="number" v-model="form.category.crewCapacity" placeholder="Crew Capacity"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Need Sea Trial ?" label-for="sea_trial">
                    <b-form-checkbox
                      name="check-button"
                      switch
                      inline
                      v-model="form.category.seaTrial"
                    >
                      Active / Inactive
                    </b-form-checkbox>
                  </b-form-group>
                  <b-form-group label="Section" label-for="section">
                    <b-row>
                      <b-col>
                        <v-multiselect
                          :options="optionsVehicleTypeSection"
                          :multiple="true"
                          placeholder="Select Vehicle Section"
                          track-by="id"
                          label="name"
                          v-model="form.category.vehicleTypeSectionIds"
                        >
                          <span slot="noResult"
                          >Oops! No elements found. Consider changing the search
                            query.</span
                          >
                        </v-multiselect>
                      </b-col>
                      <b-button variant="outline-primary" class="mr-2" v-b-modal.type-section v-b-tooltip.hover title="Add More Section"><i class="fa fa-plus"></i></b-button>
                    </b-row>
                  </b-form-group>
                  <b-form-group label="Fleet Towing" label-for="vehicleTowing">
                    <b-row>
                      <b-col>
                        <v-multiselect
                          :options="fleet_types"
                          :multiple="true"
                          placeholder="Select Fleet Towing"
                          track-by="id"
                          label="name"
                          v-model="form.category.vehicleTowIds"
                        >
                          <span slot="noResult"
                          >Oops! No elements found. Consider changing the search
                            query.</span
                          >
                        </v-multiselect>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group label="Status" label-for="sub_sub_project">
                    <b-form-checkbox
                      v-model="form.category.active"
                      name="check-button"
                      switch
                      inline
                    >
                      {{ form.category.active ? 'Active' : 'Inactive' }}
                    </b-form-checkbox>
                  </b-form-group>
                  <hr />
                  <b-form-group class="text-right">
                    <b-button type="submit" variant="primary">
                      <span v-if="!loading_form_category">{{
                        form_category_edit_mode || !findMenuPermissionLv2ByIndex('add', $route.query.menuId, 0) ? 'Save' : 'Add'
                      }}</span>
                      <template v-else>
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Saving...
                      </template>
                    </b-button>
                    <b-button
                      type="button"
                      variant="danger"
                      class="iq-bg-danger ml-3"
                      @click.prevent="resetFormCategory"
                      >Cancel</b-button
                    >
                  </b-form-group>
                </b-form>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </tab-content-item>

      <tab-content-item :active="false" id="masterModule" >
        <b-row>
          <b-col  :md="!findMenuPermissionLv2ByIndex('update', $route.query.menuId, 1) && !findMenuPermissionLv2ByIndex('add', $route.query.menuId, 1) ? '12' : '8'">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title text-primary">
                  <strong>List Module / Fitur</strong>
                </h4>
              </template>
              <template v-slot:body>
                <div v-if="loading_module" class="text-center my-5">
                  <b-spinner type="grow" label="Spinning"></b-spinner>
                </div>
                <template v-else>
                  <div class="table-responsive" v-if="modules.length > 0">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th width="5%">No</th>
                          <th width="20%" class="text-truncate">Modul / Fitur Name</th>
                          <th width="15%" class="text-truncate">Modul / Fitur Url</th>
                          <th width="15%">Icon</th>
                          <th width="15%" class="text-truncate">Same Category</th>
                          <th width="10%">Status</th>
                          <th width="15%" class="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(m, i) in modules" :key="`mod-${i}`">
                          <td>{{ i + 1 }}</td>
                          <td>{{ m.name }}</td>
                          <td>{{ m.url }}</td>
                          <td>
                            <img
                              v-if="m.icon"
                              :src="m.icon.url"
                              :alt="m.name"
                              width="64px"
                              height="auto"
                            />
                            <span v-else>-</span>
                          </td>
                          <td>
                            {{ m.sameCategory ? 'Yes' : 'No' }}
                            <template v-if="m.referenceId">
                              , with
                              <br />
                              <strong>{{ m.referenceId.name }}</strong>
                            </template>
                          </td>
                          <td>{{ m.active ? 'Active' : 'Inactive' }}</td>
                          <td class="d-flex">
                            <b-button
                              v-if="findMenuPermissionLv2ByIndex('update', $route.query.menuId, 1)"
                              variant="success"
                              size="sm"
                              class="mr-2"
                              @click="onEditModule(m)"
                              ><i class="fa fa-edit"></i
                            ></b-button>
                            <b-button
                              v-if="findMenuPermissionLv2ByIndex('delete', $route.query.menuId, 1)"
                              variant="danger"
                              size="sm"
                              @click="onDeleteModule(m)"
                              ><i class="fa fa-trash"></i
                            ></b-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p v-else class="text-center my-4">No module data found.</p>
                </template>
              </template>
            </iq-card>
          </b-col>

          <b-col md="4"
            v-if="findMenuPermissionLv2ByIndex('update', $route.query.menuId, 1) || findMenuPermissionLv2ByIndex('add', $route.query.menuId, 1)">
            <iq-card :class="{ 'border border-danger shadow-lg': form_module_edit_mode }">
              <template v-slot:headerTitle>
                <h4 class="card-title text-primary">
                  <strong>{{ form_module_edit_mode ? 'Edit' : 'Add' }} Module / Fitur</strong>
                </h4>
              </template>
              <template v-slot:body>
                <b-form @submit.prevent="onSubmitFormModule">
                  <b-form-group
                    label="Modul / Fitur Name"
                    label-for="modulName"
                  >
                    <b-form-input
                      id="modulName"
                      type="text"
                      placeholder="Modul / Fitur Name"
                      v-model="form.module.name"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label="Modul / Fitur Url"
                    label-for="modulUrl"
                  >
                    <div class="input-group">
                      <div class="input-group-append">
                        <span class="input-group-text"
                          >https://base_url/</span
                        >
                      </div>
                      <b-form-input
                        id="modulUrl"
                        type="text"
                        placeholder="Url after base"
                        v-model="form.module.url"
                      ></b-form-input>
                    </div>
                  </b-form-group>

                  <b-form-group
                    label="Images Fitur / Modul"
                    label-for="imagesModulUrl"
                    description="Accept only .jpg, .jpeg, and .png"
                  >
                    <img
                      v-if="form.module.icon && form.module.icon.url"
                      :src="form.module.icon.url"
                      :alt="form.module.name"
                      width="128px"
                      height="auto"
                      class="mb-3"
                    />
                    <b-form-file
                      accept="image/jpeg, image/png, image/jpg"
                      name="module_icon"
                      :disabled="loading_upload_image"
                      :browse-text="
                        loading_upload_image ? 'Uploading...' : 'Browse'
                      "
                      ref="form_upload_image"
                      @change="onChangeImage"
                    ></b-form-file>
                  </b-form-group>

                  <b-form-group
                    label="Same category with existing module?"
                    label-for="has_sub"
                  >
                    <b-form-checkbox
                      v-model="form.module.sameCategory"
                      name="check-button"
                      switch
                      inline
                    >
                      {{ form.module.sameCategory ? 'Yes' : 'No' }}
                    </b-form-checkbox>
                  </b-form-group>

                  <b-form-group
                    v-if="form.module.sameCategory"
                    label="Same with module"
                    label-for="category"
                  >
                    <v-select
                      v-model="form.module.referenceId"
                      label="name"
                      :options="modules"
                      :reduce="(m) => m.id"
                    ></v-select>
                  </b-form-group>

                  <b-form-group label="Status" label-for="status">
                    <b-form-checkbox
                      v-model="form.module.active"
                      name="check-button"
                      switch
                      inline
                    >
                      {{ form.module.active ? 'Active' : 'Inactive' }}
                    </b-form-checkbox>
                  </b-form-group>
                  <hr />
                  <b-form-group class="text-right">
                    <b-button type="submit" variant="primary">
                      <span v-if="!loading_form_module">{{
                        form_module_edit_mode || !findMenuPermissionLv2ByIndex('add', $route.query.menuId, 1) ? 'Save' : 'Add'
                      }}</span>
                      <template v-else>
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Saving...
                      </template>
                    </b-button>
                    <b-button
                      type="button"
                      variant="danger"
                      class="iq-bg-danger ml-3"
                      @click.prevent="resetFormModule"
                      >Cancel</b-button
                    >
                  </b-form-group>
                </b-form>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </tab-content-item>

      <tab-content-item :active="false" id="setting" >
        <b-row>
          <b-col md="12">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title text-primary">
                  <strong>Setup Fleets Module</strong>
                </h4>
              </template>
              <template v-slot:body>
                <div class="table-responsive">
                  <table class="table mb-0 table-striped table-hover">
                    <thead class="thead-dark text-center">
                      <tr>
                        <th width="20%">Category</th>
                        <th v-for="(m, i) in modules" :key="`th-mod-${i}`" class="text-truncate">{{ m.name }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(cat, ic) in merged_module_categories" :key="`cat-${ic}`">
                        <td>{{ cat.name }}</td>
                        <td v-for="(m, i) in cat.modules" :key="`bd-mod-${i}`" class="text-center">
                          <b-form-checkbox
                            name="operational-1"
                            :disabled="!findMenuPermissionLv2ByIndex('update', $route.query.menuId, 2)"
                            switch
                            inline
                            v-model="m.hasAccess"
                          >
                          </b-form-checkbox>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </template>
              <template v-slot:footer
                v-if="findMenuPermissionLv2ByIndex('update', $route.query.menuId, 2)">
                <b-row>
                  <b-col cols="12" class="text-right">
                    <b-button variant="primary" class="mb-3 mr-1" @click="saveSetting">
                      <span v-if="!loading_form_setting">Save</span>
                      <template v-else>
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Saving...
                      </template>
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </tab-content-item>

      <tab-content-item :active="false" id="fleetForms" >
        <b-row>
          <b-col :md="!findMenuPermissionLv2ByIndex('update', $route.query.menuId, 3) && !findMenuPermissionLv2ByIndex('add', $route.query.menuId, 3) ? '12' : '8'">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title text-primary">
                  <strong>List Fleet Forms</strong>
                </h4>
              </template>
              <template v-slot:body>
                <div v-if="loading_fleet_form" class="text-center my-5">
                  <b-spinner type="grow" label="Spinning"></b-spinner>
                </div>
                <template v-else>
                  <div class="table-responsive" v-if="fleet_collection_forms.data.length > 0">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th width="10%">No</th>
                          <th>Fleet Types</th>
                          <th>Collection of Forms</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(state, index) in fleet_collection_forms.data" :key="index">
                          <td>{{ index + 1 }}</td>
                          <td>{{ state?.vehicle_type?.name }}</td>
                          <td>
                            <div class="d-flex flex-column align-items-start">
                              <b-badge v-for="(form, formsIndex) in state?.collection_forms" :key="formsIndex" class="border border-primary text-primary text-uppercase mb-2" variant="none" @click="onShowDetailForm(form)" style="cursor:pointer">{{ form?.name }}</b-badge>
                            </div>
                          </td>
                          <td>
                            <b-button
                              v-if="findMenuPermissionLv2ByIndex('update', $route.query.menuId, 3)"
                              variant="success"
                              size="sm"
                              class="mr-2"
                              @click="onEditFleetForm(state)"
                              ><i class="fa fa-edit"></i
                            ></b-button>
                            <b-button
                              v-if="findMenuPermissionLv2ByIndex('delete', $route.query.menuId, 3)"
                              variant="danger"
                              size="sm"
                              @click="onDeleteFleetForm(state)"
                              ><i class="fa fa-trash"></i
                            ></b-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <b-pagination
                    v-if="fleet_collection_forms.data.length > 0"
                    v-model="fleet_collection_forms.meta.page"
                    :total-rows="fleet_collection_forms.meta.totalItem"
                    @change="getVehicleTypeForm"
                    :per-page="fleet_collection_forms.meta.perPage"
                    first-number
                    align="center"
                  ></b-pagination>
                  <p v-else class="text-center my-4"
                    >No Fleet Forms data found.</p
                  >
                </template>
              </template>
            </iq-card>
          </b-col>
          <b-col md="4"
            v-if="findMenuPermissionLv2ByIndex('update', $route.query.menuId, 3) || findMenuPermissionLv2ByIndex('add', $route.query.menuId, 3)">
            <iq-card :class="{ 'border border-danger shadow-lg': form_fleet_form_edit_mode }">
              <template v-slot:headerTitle>
                <h4 class="card-title text-primary">
                  <strong>{{ form_fleet_form_edit_mode ? 'Edit' : 'Add' }} Fleets Forms</strong>
                </h4>
              </template>
              <template v-slot:body>
                <b-form @submit.prevent="onSubmitFormFleet">
                  <b-form-group
                    label="Fleet Types"
                    label-for="fleet_types"
                  >
                    <v-multiselect 
                      v-model="form.fleet_form.fleet_types" 
                      :options="optionsFleetTypes" 
                      placeholder="Select Fleet Types" 
                      track-by="name" 
                      label="name"
                    >
                      <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                    </v-multiselect>
                  </b-form-group>
                  <b-form-group
                    label="Collection Forms"
                    label-for="collection_forms"
                  >
                    <v-multiselect 
                      v-model="form.fleet_form.collection_forms" 
                      :options="collectionFormOptions" 
                      :multiple="true" 
                      :group-select="true" 
                      placeholder="Select forms" 
                      track-by="name" 
                      label="name"
                    >
                      <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                    </v-multiselect>
                  </b-form-group>
                  <b-form-group label="Selected Forms" v-if="form.fleet_form.collection_forms.length > 0">
                    <div class="d-flex flex-column">
                      <b-badge @click="onShowDetailForm(state)" style="cursor:pointer" variant="none" class="border border-primary text-primary mb-1" v-for="(state, index) in form.fleet_form.collection_forms" :key="index">
                        {{ state?.name }}
                      </b-badge>
                    </div>
                  </b-form-group>
                  <hr />
                  <b-form-group class="text-right">
                    <b-button type="submit" variant="primary">
                      <span v-if="!loading_form_fleet_form">{{
                        form_fleet_form_edit_mode || !findMenuPermissionLv2ByIndex('add', $route.query.menuId, 3) ? 'Save' : 'Add'
                      }}</span>
                      <template v-else>
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Saving...
                      </template>
                    </b-button>
                    <b-button
                      type="button"
                      variant="danger"
                      class="iq-bg-danger ml-3"
                      @click.prevent="resetFormsFleet"
                      >Cancel</b-button
                    >
                  </b-form-group>
                </b-form>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </tab-content-item>
    </div>

    <b-row>
      <b-col md="12">
        <b-sidebar v-model="showDetailForm" id="add-customer" title="Detail Forms" width="70%" backdrop-variant="dark" bg-variant="white" right backdrop shadow>
          <template #default="{hide}">
            <b-row class="m-3">
              <b-col cols="12">
                <SlideDetailForm :detail-form="detailForm" />
              </b-col>
            </b-row>
            </template>
        </b-sidebar>
      </b-col>
    </b-row>

    <b-modal
      id="type-group"
      size="md"
      title="Add Vehicle Type Group"
      cancel-title="Close"
      hide-footer
    >
      <form
        method="post"
        @submit.prevent="onSaveTypeGroup"
      >
        <b-row>
          <b-col lg="12">
            <b-form-group label="Name">
              <b-form-input placeholder="Type Text" v-model="form.typeGroup.name" required />
            </b-form-group>
          </b-col>
          <b-col lg="12">
            <b-form-group label="alias">
              <b-form-input placeholder="Type Text" v-model="form.typeGroup.alias" required />
            </b-form-group>
          </b-col lg="12">
          <b-col lg="12">
            <div class="d-flex w-100 justify-content-end">
              <b-button variant="dark" class="mr-2" type="button">Cancel</b-button>
              <b-button variant="primary" type="submit">Submit</b-button>
            </div>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <b-modal
      id="type-section"
      size="md"
      title="Add Vehicle Type Section"
      cancel-title="Close"
      hide-footer
    >
      <form
        method="post"
        @submit.prevent="onSaveTypeSection"
      >
        <b-row>
          <b-col lg="12">
            <b-form-group label="Code">
              <b-form-input placeholder="Type Text" v-model="form.typeSection.code" required />
            </b-form-group>
          </b-col>
          <b-col lg="12">
            <b-form-group label="Name">
              <b-form-input placeholder="Type Text" v-model="form.typeSection.name" required  />
            </b-form-group>
          </b-col>
          <b-col lg="12">
            <div class="d-flex w-100 justify-content-end">
              <b-button variant="dark" class="mr-2" type="button">Cancel</b-button>
              <b-button variant="primary" type="submit">Submit</b-button>
            </div>
          </b-col>
        </b-row>
      </form>
    </b-modal>
  </b-container>
</template>
<script>
import { fleetsActions, filesActions, modulesActions, busdevActions } from '@src/Utils/helper'
import _ from 'lodash'
import SlideDetailForm from './SlideDetailForm.vue'

export default {
  name: 'ModuleCategory',
  components:{
    SlideDetailForm
  },
  data () {
    return {
      showDetailForm: false,
      detailForm: null,

      optionsFleetTypes: [],
      optionsVehicleTypeSection: [],
      optionsVehicleTypeGroup: [],
      fleet_types: [],
      modules: [],
      module_categories: [],
      merged_module_categories: [],
      fleet_collection_forms: {
        data:[],
        meta:null
      },
      collectionFormOptions: [],
      form_fleet_id: null,
      selected_category: {},
      selected_module: {},
      selected_fleet_form: {},
      uploaded_category_icon: {},
      uploaded_module_image: {},
      loading_category: true,
      loading_module: true,
      loading_fleet_form: true,
      loading_form_category: false,
      loading_form_module: false,
      loading_form_setting: false,
      loading_form_fleet_form: false,
      loading_delete_category: false,
      loading_delete_module: false,
      loading_delete_fleet_form: false,
      loading_upload_icon: false,
      loading_upload_image: false,
      form_category_edit_mode: false,
      form_module_edit_mode: false,
      form_fleet_form_edit_mode: false,
      category_params: {
        active: true,
        search: '',
      },
      form: {
        typeGroup: {
          name:'',
          alias:''
        },
        typeSection: {
          code:'',
          name:'',
          status:1
        },
        category: {
          name: '',
          icon: {
            url: '',
            iconName: '',
          },
          isCrewing:false,
          crewCapacity:null,
          seaTrial:false,
          vehicleTypeSectionIds:[],
          vehicleTowIds:[],
          active: true,
        },
        module: {
          name: '',
          url: '',
          icon: {
            url: '',
            iconName: '',
          },
          sameCategory: false,
          referenceId: '',
          active: true,
        },
        fleet_form: {
          fleet_types:null,
          collection_forms:[],
        },
      },
      user: {},
    }
  },
  async mounted() {
    await this.fetchFleetTypes()
    await this.fetchModules()
    this.fetchModuleCategories()
    
    await this.getListCollectionForm()
    await this.getVehicleTypeForm()
    await this.getVehicleTypeMasterSection()
    await this.getVehicleTypeGroup()
  },
  created() {
    this.getVehicleTypeForm = _.debounce(this.getVehicleTypeForm, 500)
  },
  methods: {
    ...fleetsActions,
    ...filesActions,
    ...modulesActions,
    ...busdevActions,
    onShowDetailForm(data){
      this.showDetailForm = !this.showDetailForm

      this.detailForm = data
    },
    async getVehicleTypeMasterSection () {
      let params = {
        page: 1,
        perPage:100000,
        status:1
      }

      const res = await this.fetchVehicleTypeMasterSection(params)

      if (res.data.status == true && res?.data?.data?.data.length > 0) {
        this.optionsVehicleTypeSection = res?.data?.data?.data
      } 
    },
     async getVehicleTypeGroup () {
      const res = await this.fetchVehicleTypeGroup()

      if (res.data.status == true && res?.data?.data?.length > 0) {
        this.optionsVehicleTypeGroup = res?.data?.data
      } 
    },
    async fetchFleetTypes() {
      this.loading_category = true
      let params = this.$options.filters.cleanObject(this.category_params)
      let res = await this.getFleetTypes(params)

      this.fleet_types = res.data
      this.optionsFleetTypes = res.data
      this.loading_category = false
    },
    async getListCollectionForm(page = 1) {
        this.collectionFormOptions = []

        let params = {
          form_for_vehicle: 1
        }

        let res = await this.fetchOptionsCollectionForm(params)

        if (res?.data?.status === true && res?.data?.data?.length > 0) {
          this.collectionFormOptions = res.data.data
        }
      }, 
    async getVehicleTypeForm(page = 1) {
      this.loading_fleet_form = true
      this.fleet_collection_forms = {
        data:[],
        meta:null
      }
      let params = {
        page:page || 1,
        perPage:10
      }

      let res = await this.fetchVehicleTypeForm(params)

      if (res?.data?.status === true && res?.data?.data?.data?.length > 0) {
        this.fleet_collection_forms = {
          data:res.data.data?.data.map(obj => {
            return {
              ...obj,
              signature: obj?.signature ? JSON.parse(obj?.signature) : [],
              question_type_content:  obj?.question_type_content ? JSON.parse(obj?.question_type_content) : [],
              field: obj.field ? JSON.parse(obj?.field) : [],
            }
          }),
          meta:res.data.data?.meta
        }

        if(this.fleet_collection_forms.data.length > 0 && this.optionsFleetTypes.length > 0){
          this.fleet_collection_forms.data.map(val => {
            this.optionsFleetTypes = this.optionsFleetTypes.filter(obj => val.vehicle_type_id !== obj.id)
          })
        }
      }

      this.loading_fleet_form = false
    },
    async fetchModules() {
      this.loading_module = true
      let res = await this.getModules()

      this.modules = res.data
      this.loading_module = false
    },
    async fetchModuleCategories() {
      let res = await this.getModuleCategories()
      this.module_categories = res.data
      this.mergeModuleCategories()
    },
    async onSubmitFormCategory() {
      this.loading_form_category = true

      let payload = {
        ...this.form.category,
        groupId: this.form.category.group.id,
        crewCapacity:Number(this.form.category.crewCapacity),
        vehicleTypeSectionIds:this.form.category.vehicleTypeSectionIds.map(obj => obj.id),
        vehicleTowIds:this.form.category.vehicleTowIds.map(obj => obj.id),
      }
      delete payload.group
      if (payload.icon && payload.icon.url) payload.icon = payload.icon.iconName
      else payload.icon = ''

      let form_has_empty_value = false
      for (var key in payload) {
        if (payload[key] === '') form_has_empty_value = true
        else if (Array.isArray(payload[key]) && !payload[key].length)
          form_has_empty_value = true
      }

      if (form_has_empty_value){
        this.$swal(
          'Empty form!',
          'Please check again and fill all the forms.',
          'error'
        )
      } else {
        if (!this.form_category_edit_mode) {
          let res = await this.addFleetType({data:payload})
          if (res.status == 'success') {
            this.resetFormCategory()
            this.fetchFleetTypes()
            this.$swal(
              'Category added!',
              'Category added successfully.',
              'success'
            )
          } else {
            if (res.data.message != null)
              this.$swal('Error', res.data.message, 'error')
            else
              this.$swal(
                'Error',
                'Something wrong with the server. Please try again later.',
                'error'
              )
          }
        } else {
          let res = await this.updateFleetType({
            id: this.selected_category.id,
            data: payload,
          })
          if (res.status == 'success') {
            this.resetFormCategory()
            this.fetchFleetTypes()
            this.$swal(
              'Category updated!',
              'Category updated successfully.',
              'success'
            )
          } else {
            if (res.data.message != null)
              this.$swal('Error', res.data.message, 'error')
            else
              this.$swal(
                'Error',
                'Something wrong with the server. Please try again later.',
                'error'
              )
          }
        }
      }
      this.loading_form_category = false
    },
    async onSubmitFormModule() {
      this.loading_form_module = true

      let payload = {
        name: this.form.module.name,
        url: this.form.module.url,
        iconName: this.form.module.icon.iconName,
        sameCategory: this.form.module.sameCategory,
        active: this.form.module.active,
      }
      
      if (this.form.module.sameCategory) {
        Object.assign(payload, { referenceId: this.form.module.referenceId })
      }

      let form_has_empty_value = false
      for (var key in payload) {
        if (['referenceId', 'sameCategory'].includes(key)) {
          continue
        } else {
          if (payload[key] === '')
            form_has_empty_value = true
          else if (Array.isArray(payload[key]) && !payload[key].length)
            form_has_empty_value = true
        }
      }

      if (form_has_empty_value)
        this.$swal(
          'Empty form!',
          'Please check again and fill all the forms.',
          'error'
        )
      else {
        if (!this.form_module_edit_mode) {
          let res = await this.addModule(payload)
          if (res.status == 'success') {
            this.resetFormModule()
            this.fetchModules()
            this.$swal('Module added!', 'Module added successfully.', 'success')
          } else {
            if (res.data.message != null)
              this.$swal('Error', res.data.message, 'error')
            else
              this.$swal(
                'Error',
                'Something wrong with the server. Please try again later.',
                'error'
              )
          }
        } else {
          let res = await this.updateModule({
            id: this.selected_module.id,
            data: payload,
          })
          if (res.status == 'success') {
            this.resetFormModule()
            this.fetchModules()
            this.$swal(
              'Module updated!',
              'Module updated successfully.',
              'success'
            )
          } else {
            if (res.data.message != null)
              this.$swal('Error', res.data.message, 'error')
            else
              this.$swal(
                'Error',
                'Something wrong with the server. Please try again later.',
                'error'
              )
          }
        }
      }
      this.loading_form_module = false
    },
    async uploadFile(photo, is_module = false) {
      let formData = new FormData()
      formData.append('file', photo)

      let res = await this.upload(formData)
      if (res.status == 'success') {
        if (is_module) {
          this.loading_upload_image = false
          this.uploaded_module_image = res.data
          this.form.module.icon = {
            url: res.data.url,
            iconName: res.data.fileName,
          }
        } else {
          this.loading_upload_icon = false
          this.uploaded_category_icon = res.data
          this.form.category.icon = {
            url: res.data.url,
            iconName: res.data.fileName,
          }
        }
      } else {
        if (is_module) this.loading_upload_image = false
        else this.loading_upload_icon = false

        if (res.data.message != null)
          this.$swal('Error', res.data.message, 'error')
        else
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
      }
    },
    async saveSetting() {
      this.loading_form_setting = true
      let payload = []

      this.merged_module_categories.forEach(category => {
        let modules = []

        category.modules.forEach(m => modules.push({
          id: m.id,
          hasAccess: m.hasAccess
        }))

        payload.push({
          id: category.id,
          modules
        })
      })

      let res = await this.saveModuleCategories(payload)
      if (res.status == 'success') {
        this.fetchModuleCategories()
        this.$swal('Setting saved!', 'Module categories updated successfully.', 'success')
      } else {
        if (res.data.message != null)
          this.$swal('Error', res.data.message, 'error')
        else
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
      }
      this.loading_form_setting = false
    },
    async onSubmitFormFleet() {
      this.loading_form_fleet_form = true
      let payload = this.form.fleet_form

      payload = {
        vehicle_type_id: this.form.fleet_form.fleet_types.id,
        fleet_forms:this.form.fleet_form.collection_forms.map(obj => obj.id)
      }

      if (!this.form_fleet_form_edit_mode) {
          let res = await this.createVehicleTypeForm({data:payload})
          if (res.data.status === true) {
            this.resetFormsFleet()
            this.getVehicleTypeForm()
            this.$swal(
              'Fleet Forms added!',
              'Fleet Forms added successfully.',
              'success'
            )
          } else {
            if (res.data.message != null)
              this.$swal('Error', res.data.message, 'error')
            else
              this.$swal(
                'Error',
                'Something wrong with the server. Please try again later.',
                'error'
              )
          }
      } else {
        let res = await this.updateVehicleTypeForm({
          id: this.selected_fleet_form.id,
          data: payload,
        })
        if (res.status == 'success') {
          this.resetFormsFleet()
          this.getVehicleTypeForm()
          this.$swal(
            'Fleet Forms updated!',
            'Fleet Forms updated successfully.',
            'success'
          )
        } else {
          if (res.data.message != null)
            this.$swal('Error', res.data.message, 'error')
          else
            this.$swal(
              'Error',
              'Something wrong with the server. Please try again later.',
              'error'
            )
        }
      }
      this.loading_form_fleet_form = false
    },
    onChangeIcon(event) {
      this.loading_upload_icon = true
      const files = [...event.target.files]

      const file_name = files[0].name

      const reader = new FileReader()
      reader.onload = (e) => {
        this.form.category.icon = {
          url: e.target.result,
          iconName: file_name,
        }
      }
      reader.readAsDataURL(files[0])

      this.uploadFile(files[0])
    },
    onChangeImage(event) {
      this.loading_upload_image = true
      const files = [...event.target.files]

      const file_name = files[0].name

      const reader = new FileReader()
      reader.onload = (e) => {
        this.form.module.icon.url = e.target.result
        this.form.module.icon.iconName = file_name
      }
      reader.readAsDataURL(files[0])
      
      this.uploadFile(files[0], true)
    },
    async onEditCategory(category) {
      let findGroup = this.optionsVehicleTypeGroup.find(obj => obj?.id == category?.vehicleTypeGroup?.id)

      this.form_category_edit_mode = true
      this.selected_category = category
      this.form.category = {
        name: category?.name,
        active: category?.active,
        icon: category?.icon,
        isCrewing: category?.crewing,
        group: findGroup ?? null,
        seaTrial: category?.seaTrial,
        crewCapacity: category?.crewCapacity,
        vehicleTypeSectionIds:category?.vehicleTypeSection,
        vehicleTowIds:category?.vehicleTypeTow,
      }
    },
    onEditModule(module_obj) {
      this.form_module_edit_mode = true
      this.selected_module = module_obj
      this.form.module = {
        name: module_obj.name,
        url: module_obj.url,
        icon: module_obj.icon,
        sameCategory: module_obj.sameCategory,
        referenceId: module_obj.referenceId ? module_obj.referenceId.id : null,
        active: module_obj.active,
      }
    },
    onEditFleetForm(fleet_form) {
      this.form_fleet_form_edit_mode = true
      this.selected_fleet_form = fleet_form
      this.form.fleet_form = {
        fleet_types:fleet_form.vehicle_type,
        collection_forms:fleet_form.collection_forms,
      }
    },
    onDeleteCategory(category) {
      this.$swal({
        title: 'Delete category?',
        text: `Category ${category.name} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading_delete_category = true
          let res = await this.deleteFleetType(category.id)
          if (res.status != 'success') {
            this.loading_delete_category = false
            if (res.data.message != null)
              this.$swal('Error', res.data.message, 'error')
            else
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
          } else {
            await this.deleteVehicleTypeSectionRelation({vehicle_type_id: category.id})
            this.loading_delete_category = false
            this.fetchFleetTypes()
            this.$swal(
              `Category deleted!`,
              `Category ${category.name} successfully deleted`,
              'success'
            )
          }
        }
      })
    },
    onDeleteModule(module_obj) {
      this.$swal({
        title: 'Delete module?',
        text: `Module ${module_obj.name} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading_delete_module = true
          let res = await this.deleteModule(module_obj.id)
          if (res.status != 'success') {
            this.loading_delete_module = false
            if (res.data.message != null)
              this.$swal('Error', res.data.message, 'error')
            else
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
          } else {
            this.loading_delete_module = false
            this.fetchFleetTypes()
            this.$swal(
              `Module deleted!`,
              `Module ${module_obj.name} successfully deleted`,
              'success'
            )
          }
        }
      })
    },
    onDeleteFleetForm(fleet_form) {
      this.$swal({
        title: 'Delete Fleet Form?',
        text: `Module ${fleet_form.vehicle_type.name} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading_delete_fleet_form = true
          let res = await this.deleteVehicleTypeForm({id: fleet_form.vehicle_type_id})
          if (res.status != 'success') {
            this.loading_delete_fleet_form = false
            if (res.data.message != null)
              this.$swal('Error', res.data.message, 'error')
            else
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
          } else {
            this.loading_delete_fleet_form = false
            this.fetchFleetTypes()
            this.getVehicleTypeForm()
            this.$swal(
              `Fleet Forms deleted!`,
              `Fleet Forms ${fleet_form.vehicle_type.name} successfully deleted`,
              'success'
            )
          }
        }
      })
    },
    resetFormCategory() {
      this.$refs.form_upload_icon.files = []
      this.form_category_edit_mode = false
      this.selected_category = {}
      this.form.category = {
        name: '',
        icon: {
          url: '',
          iconName: '',
        },
        isCrewing:false,
        crewCapacity:null,
        seaTrial:false,
        section:[],
        active: true,
      }
    },
    resetFormModule() {
      this.$refs.form_upload_image.files = []
      this.form_module_edit_mode = false
      this.selected_module = {}
      this.form.module = {
        name: '',
        url: '',
        icon: {
          url: '',
          iconName: '',
        },
        sameCategory: false,
        referenceId: '',
        active: true,
      }
    },
    resetFormsFleet() {
      this.form_fleet_form_edit_mode = false
      this.form.fleet_form = {
        fleet_types: null,
        collection_forms: []
      }
    },
    isCategoryHasAccess(module_id, categories_module) {
      let result = categories_module.find(cm => cm.id == module_id)
      if (result) return result.hasAccess
      return false
    },
    mergeModuleCategories() {
      if (this.module_categories.length > 0) {
        this.merged_module_categories = []
        this.module_categories.forEach(cat => {
          let modules = []
          this.modules.forEach(m => {
            modules.push({
              id: m.id,
              name: m.name,
              hasAccess: this.isCategoryHasAccess(m.id, cat.modules)
            })
          })
  
          this.merged_module_categories.push({
            id: cat.id,
            name: cat.name,
            modules
          })
        })
      }
    },

    async onSaveTypeGroup(){
      let payload = this.form.typeGroup

      let res = await this.createVehicleTypeGroup({data: payload})

      if(res.data.status === true){
        await this.getVehicleTypeGroup()
        this.form.typeGroup.name = ''
        this.form.typeGroup.alias = ''
        this.$nextTick(() => this.$bvModal.hide('type-group'))
        this.$swal(`Success!`, `Create Type Group.`, 'success')
      } else {
        this.$swal(`Oops.`, res?.data?.message, 'error')
      }
    },
    async onSaveTypeSection(){
      let payload = this.form.typeSection

      let res = await this.createVehicleTypeMasterSection({data: payload})

      if(res.data.status === true){
        await this.getVehicleTypeMasterSection()
        this.form.typeSection.code = ''
        this.form.typeSection.name = ''
        this.$nextTick(() => this.$bvModal.hide('type-section'))
        this.$swal(`Success!`, `Create Type Section.`, 'success')
      } else {
        this.$swal(`Oops.`, res?.data?.message, 'error')
      }
    },
  },
}
</script>
<style scoped>
table th{
  background: #099bab !important;
  color: white;
}
</style>