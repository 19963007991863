var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M20.0308 0.5C20.1335 0.543055 20.2387 0.581216 20.3385 0.630142C20.8826 0.897276 21.1952 1.33174 21.1977 1.94086C21.2084 3.69876 21.2026 5.45665 21.2006 7.21406C21.2006 7.5399 20.9883 7.77132 20.7113 7.76643C20.4344 7.76154 20.2304 7.53403 20.2299 7.20329C20.228 5.5271 20.228 3.85075 20.2299 2.17423C20.2299 1.63997 20.0655 1.47558 19.5293 1.47558H2.16904C1.63868 1.47558 1.47478 1.64241 1.47478 2.18059C1.47478 9.38896 1.47478 16.5988 1.47478 23.8101C1.47478 24.3654 1.6333 24.5254 2.18323 24.5254H19.5156C20.068 24.5254 20.227 24.3659 20.227 23.813C20.227 22.7063 20.227 21.5997 20.227 20.493C20.227 20.0947 20.5523 19.852 20.8904 19.9895C20.9836 20.0224 21.0639 20.0842 21.1193 20.166C21.1748 20.2478 21.2026 20.3453 21.1986 20.444C21.1986 21.6809 21.2114 22.9182 21.1913 24.1545C21.1801 24.8434 20.5626 25.4428 19.8703 25.4946C19.7974 25.5 19.7235 25.5 19.6506 25.5H2.05162C1.27125 25.501 0.710077 25.0743 0.53737 24.3488C0.50985 24.2217 0.497208 24.0918 0.499697 23.9618C0.499697 16.6556 0.499697 9.34819 0.499697 2.03969C0.499697 1.29798 0.867617 0.793554 1.56823 0.554797C1.60375 0.539351 1.63795 0.521025 1.67049 0.5L20.0308 0.5Z",
      "fill": _vm.color
    }
  }), _c('path', {
    attrs: {
      "d": "M25.5007 8.94792C25.2311 9.49882 24.9904 10.0659 24.6861 10.5967C22.8635 13.7752 21.0312 16.9484 19.1893 20.1162C19.0775 20.303 18.9302 20.4662 18.7558 20.5966C17.8207 21.3011 16.8781 21.9956 15.9279 22.6799C15.7933 22.7797 15.6332 22.8392 15.4661 22.8516C15.1906 22.8594 15.0081 22.6006 15.0394 22.2855C15.1089 21.582 15.1862 20.8794 15.265 20.1768C15.3217 19.6597 15.376 19.1425 15.4465 18.6278C15.465 18.4595 15.5148 18.296 15.5933 18.1459C17.5992 14.6605 19.6093 11.1776 21.6234 7.69738C22.3034 6.52317 23.6572 6.23793 24.7125 7.03248C25.1004 7.32362 25.3643 7.75021 25.4518 8.22724C25.4648 8.27338 25.4811 8.31851 25.5007 8.36228V8.94792ZM18.4774 19.3916C20.1272 16.5343 21.7594 13.7074 23.4033 10.8594L21.5509 9.78895L16.6236 18.3211L18.4774 19.3916ZM23.9008 10.0081C24.0882 9.67104 24.2776 9.37553 24.4243 9.0624C24.5297 8.83126 24.5473 8.56964 24.4737 8.32649C24.4001 8.08334 24.2405 7.87534 24.0246 7.74141C23.5843 7.46205 22.9717 7.52956 22.6576 7.93711C22.4238 8.24045 22.2481 8.58929 22.0378 8.93177L23.9008 10.0081ZM17.7592 20.1147L16.357 19.3079C16.2812 19.9929 16.2102 20.624 16.1354 21.3095L17.7592 20.1147Z",
      "fill": _vm.color
    }
  }), _c('path', {
    attrs: {
      "d": "M10.8465 3.13867C11.7356 3.13781 12.6049 3.40083 13.3444 3.89443C14.0838 4.38802 14.6602 5.08998 15.0004 5.9114C15.3406 6.73281 15.4294 7.63671 15.2555 8.50861C15.0816 9.38052 14.6528 10.1812 14.0234 10.8092C13.3941 11.4372 12.5926 11.8643 11.7203 12.0365C10.848 12.2086 9.94432 12.1179 9.12362 11.776C8.30291 11.4341 7.60215 10.8563 7.11009 10.1158C6.61803 9.3753 6.35681 8.50542 6.35952 7.61634C6.36288 6.42822 6.83699 5.28985 7.678 4.45059C8.51901 3.61132 9.65835 3.13958 10.8465 3.13867ZM13.6577 9.68002C14.5873 8.56012 14.6891 6.51699 13.3412 5.15832C12.6989 4.50993 11.8289 4.13786 10.9163 4.12132C10.0038 4.10478 9.12088 4.44508 8.45549 5.06977C7.05573 6.37168 7.05573 8.44759 7.93639 9.55184C8.16976 9.37473 8.41097 9.18245 8.66196 9.00338C8.91294 8.82432 9.17029 8.6609 9.42519 8.49064C9.09837 7.8644 9.06951 7.7367 9.09495 7.05027C9.12968 6.10992 9.93109 5.33788 10.8592 5.35207C11.3149 5.35991 11.7502 5.54221 12.0755 5.86142C12.4008 6.18062 12.5913 6.61243 12.6078 7.06789C12.6274 7.57965 12.577 8.06891 12.2453 8.50777C12.813 8.76433 13.301 9.16932 13.6577 9.68002ZM12.9635 10.4139C12.4552 9.60566 11.736 9.18343 10.8059 9.20055C9.90907 9.21719 9.21286 9.63306 8.7466 10.4134C9.95506 11.3753 11.6768 11.3777 12.9635 10.4159V10.4139ZM11.6327 7.27044C11.6327 6.73568 11.3641 6.38293 10.9321 6.35113C10.5001 6.31933 10.162 6.57716 10.0823 7.01162C9.9761 7.58748 10.1865 8.04395 10.6297 8.16676C10.7536 8.20197 10.8839 8.20783 11.0104 8.18388C11.4292 8.09826 11.6332 7.78758 11.6327 7.2724V7.27044Z",
      "fill": _vm.color
    }
  }), _c('path', {
    attrs: {
      "d": "M10.0172 13.6845C11.139 13.6845 12.2604 13.6805 13.3818 13.6894C13.5257 13.6902 13.6668 13.7297 13.7903 13.8038C13.9606 13.9134 14.0124 14.1028 13.9552 14.3004C13.932 14.3919 13.8819 14.4743 13.8113 14.537C13.7407 14.5996 13.653 14.6396 13.5594 14.6517C13.4944 14.6596 13.429 14.6621 13.3637 14.6591H6.68385C6.65156 14.6591 6.61878 14.6591 6.586 14.6591C6.27337 14.6473 6.06299 14.4453 6.06739 14.1634C6.07228 13.8909 6.27826 13.6923 6.57915 13.6864C6.93631 13.6801 7.29395 13.6864 7.6516 13.6864L10.0172 13.6845Z",
      "fill": _vm.color
    }
  }), _c('path', {
    attrs: {
      "d": "M10.0074 17.3951C8.87772 17.3951 7.74803 17.3951 6.61834 17.3951C6.38399 17.3951 6.20541 17.3105 6.10756 17.0942C6.02634 16.9142 6.04151 16.7165 6.19122 16.6011C6.32913 16.4954 6.49615 16.4346 6.66971 16.4269C8.44962 16.4147 10.2295 16.4191 12.0094 16.4191C12.4806 16.4191 12.9522 16.4166 13.4234 16.4191C13.759 16.4215 13.9738 16.6148 13.9733 16.9083C13.9728 17.2019 13.7576 17.3927 13.421 17.3937C12.2829 17.3963 11.1451 17.3968 10.0074 17.3951Z",
      "fill": _vm.color
    }
  }), _c('path', {
    attrs: {
      "d": "M10.0171 20.128H6.72543C6.6438 20.1316 6.56203 20.129 6.4808 20.1201C6.36516 20.1032 6.25954 20.045 6.18344 19.9563C6.10735 19.8676 6.06589 19.7543 6.06672 19.6375C6.06756 19.5206 6.11063 19.4079 6.18799 19.3203C6.26534 19.2327 6.37178 19.176 6.48765 19.1607C6.56893 19.1528 6.65067 19.1505 6.73228 19.1539H13.3181C13.3998 19.1505 13.4815 19.1532 13.5628 19.1617C13.6784 19.1787 13.784 19.237 13.8601 19.3257C13.9361 19.4145 13.9775 19.5277 13.9766 19.6446C13.9757 19.7615 13.9326 19.8741 13.8552 19.9617C13.7778 20.0493 13.6713 20.1059 13.5554 20.1211C13.4742 20.1291 13.3924 20.1314 13.3108 20.128H10.0171Z",
      "fill": _vm.color
    }
  }), _c('path', {
    attrs: {
      "d": "M12.168 22.8635C11.7541 22.8635 11.3402 22.8635 10.9262 22.8635C10.5867 22.8635 10.3714 22.6761 10.3651 22.388C10.3587 22.0905 10.5794 21.8914 10.9287 21.8904C11.7565 21.8881 12.5843 21.8881 13.4121 21.8904C13.7517 21.8904 13.967 22.0778 13.9733 22.3659C13.9797 22.6634 13.759 22.8616 13.4102 22.8635C12.9963 22.8655 12.5843 22.8635 12.168 22.8635Z",
      "fill": _vm.color
    }
  }), _c('path', {
    attrs: {
      "d": "M4.95194 14.1797C4.9509 14.3071 4.89939 14.4289 4.80869 14.5184C4.71798 14.6079 4.59549 14.6578 4.46806 14.6572C4.40316 14.6579 4.33878 14.6455 4.27873 14.6209C4.21867 14.5963 4.16416 14.5599 4.1184 14.5138C4.07265 14.4678 4.03659 14.4131 4.01234 14.3529C3.9881 14.2927 3.97616 14.2282 3.97724 14.1633C3.97832 14.0984 3.99239 14.0344 4.01862 13.975C4.04486 13.9156 4.08272 13.8621 4.12998 13.8176C4.17724 13.7732 4.23293 13.7386 4.29377 13.716C4.35461 13.6934 4.41936 13.6832 4.48421 13.686C4.61156 13.6899 4.73222 13.7439 4.81985 13.8364C4.90748 13.9289 4.95497 14.0523 4.95194 14.1797Z",
      "fill": _vm.color
    }
  }), _c('path', {
    attrs: {
      "d": "M4.95194 16.9053C4.95299 17.0327 4.90365 17.1553 4.81466 17.2465C4.72568 17.3377 4.60427 17.39 4.47687 17.3921C4.21072 17.3955 3.96952 17.1553 3.97832 16.8945C3.98642 16.7674 4.04205 16.648 4.13415 16.5601C4.22626 16.4722 4.34808 16.4221 4.47541 16.4199C4.6028 16.4216 4.72436 16.4735 4.81362 16.5645C4.90288 16.6554 4.9526 16.7779 4.95194 16.9053Z",
      "fill": _vm.color
    }
  }), _c('path', {
    attrs: {
      "d": "M4.95196 19.6455C4.95144 19.773 4.90052 19.895 4.81032 19.985C4.72012 20.0751 4.59797 20.1257 4.47053 20.126C4.34168 20.1245 4.2187 20.0719 4.12865 19.9797C4.03859 19.8876 3.98884 19.7634 3.99033 19.6345C3.99182 19.5057 4.04444 19.3827 4.1366 19.2926C4.22877 19.2026 4.35294 19.1528 4.48179 19.1543C4.60918 19.1574 4.73016 19.2108 4.81828 19.3029C4.90639 19.3949 4.95446 19.5181 4.95196 19.6455Z",
      "fill": _vm.color
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }