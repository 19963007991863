<template>
  <b-nav class="custom-nav" :class="{ 'custom-nav-primary': primary }">
    <b-nav-item
      v-for="(item, index) in items"
      :key="index"
      class="custom-nav-item"
      :class="{
        active: activeIndex === index,
        'bg-variant-primary': primary && activeIndex === index,
        'bg-default': !primary && activeIndex === index,
        'hover-primary': primary,
        'hover-default': !primary,
      }"
      @click="handleClick(item.value, index)"
    >
      <div
        class="m-0 w-100 h-100 nav-item-text"
        :class="{
          'text-primary': activeIndex === index && !primary,
          'text-white': activeIndex === index && primary,
          'text-secondary': activeIndex != index,
          'hover-text-primary': primary,
          'hover-text-default': !primary,
        }"
      >
        <p class="m-0" style="font-weight: 500">
          {{ item.label }}
        </p>
      </div>
    </b-nav-item>
  </b-nav>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    primary: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeIndex: 0,
    }
  },
  methods: {
    handleClick(value, index) {
      this.activeIndex = index
      this.$emit('item-click', value)
    },
  },
}
</script>

<style scoped>
.bg-variant-primary {
  background: #049fb0;
}
.bg-default {
  background: rgb(249 250 251);
}
.hover-primary:hover {
  background: #049fb0;
}
.hover-default:hover {
  background: rgb(249 250 251);
}
.hover-text-primary:hover {
  color: rgb(249 250 251) !important;
}
.hover-text-default:hover {
  color: #049fb0 !important;
}

.custom-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 2px solid rgb(241 245 249);
}

.custom-nav-item {
  position: relative;
  flex-grow: 1;
  text-align: center;
  transition: background-color 0.3s ease;
  border-bottom: 2px solid #ced4da;
}
.custom-nav-primary {
  background: #f0edf1;
}
.custom-nav-item::before {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #049fb0;
  transition: width 0.3s ease, left 0.3s ease;
}

/* Animasi bergerak dari tengah ke luar saat hover */
.custom-nav-item:hover::before {
  width: 100%;
  left: 0;
}

/* Border penuh untuk item aktif */
.custom-nav-item.active::before {
  width: 100%;
  left: 0;
}
/* .nav-item-text:hover {
  color: #049fb0 !important;
  height: 100%;
} */
</style>
