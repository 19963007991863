<template>
  <b-row>
    <b-col md="6" class="d-flex justify-content-between">
      <div class="d-flex mb-3">
        <b-form-input size="lg" placeholder="search..."></b-form-input>
        <b-button variant="primary" class="mx-2">
          <i class="fa fa-search"></i>
        </b-button>
        <b-button
          variant="outline-primary"
          class="d-flex align-items-center"
          @click="modal.filter = true"
        >
          <i class="fas fa-sliders"></i>
          <p class="text-truncate m-0"> Filter Data </p>
        </b-button>
      </div>
      <b-modal
        v-model="modal.filter"
        centered
        size="lg"
        title="Filter Data"
        ok-title="Filter"
        @ok="getMaintenanceFinishedList()"
      >
        <b-row class="px-3">
          <b-col md="12">
            <b-form-group
              label="Date"
              label-for="dateRange"
              label-cols-sm="4"
              label-cols-lg="3"
            >
              <date-picker
                style="width: 100%"
                v-model="maintenanceFinished.dateRange"
                type="date"
                range
                placeholder="Select date range"
              ></date-picker>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Fleet Category"
              label-for="categoryId"
              label-cols-sm="4"
              label-cols-lg="3"
            >
              <v-multiselect
                tag-placeholder="Fleets"
                placeholder="Search Fleets"
                label="name"
                track-by="code"
                :options="[]"
                :multiple="true"
                :taggable="true"
              >
              </v-multiselect>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Fleet Name"
              label-for="vehicleIds"
              label-cols-sm="4"
              label-cols-lg="3"
            >
              <v-multiselect
                v-model="maintenanceFinished.params.vehicleIds"
                tag-placeholder="Fleets"
                placeholder="Search Fleets"
                label="name"
                track-by="code"
                :options="fleetCategoryOpt"
                :multiple="true"
                :taggable="true"
              >
              </v-multiselect>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Status"
              label-for="status"
              label-cols-sm="4"
              label-cols-lg="3"
            >
              <b-form-select
                plain
                :options="statusList"
                v-model="maintenanceFinished.params.status"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Filter Options"
              label-for="ownership"
              label-cols-sm="4"
              label-cols-lg="3"
            >
              <div class="d-flex">
                <b-form-radio
                  v-model="filterOptionSelected"
                  name="radio-a"
                  class="mr-3"
                  value="jobClass"
                  >Job Class</b-form-radio
                >
                <b-form-radio
                  v-model="filterOptionSelected"
                  name="radio-b"
                  value="jobType"
                  >Job Type</b-form-radio
                >
              </div>
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="filterOptionSelected == 'jobClass'">
            <b-form-group
              label="Job Class"
              label-for="job-class"
              label-cols-sm="4"
              label-cols-lg="3"
            >
              <v-multiselect
                v-model="maintenanceFinished.params.jobClassIds"
                tag-placeholder=""
                placeholder="Search job class"
                label="name"
                id="job-class"
                track-by="code"
                :options="jobClassList"
                :multiple="true"
                :taggable="true"
              >
              </v-multiselect>
            </b-form-group>
          </b-col>
          <b-col md="12" v-else>
            <b-form-group
              label="Job Type"
              label-for="job-type"
              label-cols-sm="4"
              label-cols-lg="3"
            >
              <v-multiselect
                v-model="maintenanceFinished.params.jobTypeIds"
                tag-placeholder=""
                placeholder="Search job class"
                label="name"
                id="job-type"
                track-by="code"
                :options="jobTypeList"
                :multiple="true"
                :taggable="true"
              >
              </v-multiselect>
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>
    </b-col>
    <b-col md="12">
      <div class="d-flex overflow-auto">
        <table class="table">
          <thead>
            <tr class="bg-primary">
              <th
                v-for="(col, i) in columns"
                :key="i"
                class="text-truncate"
                style="background-color: #099bab; color: white"
                :class="col.style"
                >{{ col.label ?? col }}</th
              >
            </tr>
          </thead>
          <tbody>
            <template v-if="!maintenanceFinished.isLoading && maintenanceFinished.data.length">
              <tr
                v-for="(job, index) in maintenanceFinished.data"
                :key="index"
                :style="`background:${index % 2 != 0 ? 'white' : '#f5f5f5'}`"
              >
                <td
                  class="text-center sticky-column column-1"
                  :style="`background:${index % 2 != 0 ? 'white' : '#f5f5f5'}`"
                  >{{ index + 1 }}</td
                >
                <td
                  class="text-truncate text-left sticky-column column-2"
                  :style="`background:${index % 2 != 0 ? 'white' : '#f5f5f5'}`"
                >
                  <a
                    href="#"
                    @click.prevent="
                      viewDetailMaintenance(job.id, 'VIEW_DETAIL')
                    "
                    >{{ job.vehicleName ?? '-' }}</a
                  >
                </td>
                <td
                  class="text-truncate text-left sticky-column column-3"
                  :style="`background:${index % 2 != 0 ? 'white' : '#f5f5f5'}`"
                  >{{ job.componentName ?? '-' }}</td
                >
                <td class="text-truncate text-left">{{
                  job.equipmentName ?? '-'
                }}</td>
                <td class="text-truncate text-left">{{
                  job.jobName ?? '-'
                }}</td>
                <td class="text-truncate text-left">{{
                  job.jobClass ?? '-'
                }}</td>
                <td class="text-truncate text-left"
                  ><b-badge variant="success">{{
                    job.trigger ?? '-'
                  }}</b-badge></td
                >
                <td class="text-truncate text-left">{{
                  job.interval ?? '-'
                }}</td>
                <td class="text-truncate text-left"
                  >{{ job.lastMaintenanceDate }}
                  <span v-if="job.lastMaintenanceHours"
                    >| {{ job.lastMaintenanceHours }}</span
                  ></td
                >
                <td class="text-truncate text-left">{{
                  job.dueDate ?? '-'
                }}</td>
                <td class="text-truncate text-left text-right"
                  >Rp. {{ job?.cost ?? 0 }}</td
                >
                <td class="text-truncate text-left" style="cursor: pointer">
                  <div
                    class="p-1 d-flex align-items-center px-2 rounded-sm"
                    style="background-color: #eceeed"
                  >
                    <i class="fa fa-download mr-2" style="color: #3862dd" />
                    Attachment.pdf
                  </div>
                </td>
                <td class="text-right">
                  <b-dropdown
                    size="sm"
                    variant="link iq-border-radius-10"
                    class="rounded-sm"
                    style="background-color: #3d3d3d"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <span
                        class="dropdown-toggle p-0 text-white"
                        id="dropdownMenuButton5"
                        data-toggle="dropdown"
                      >
                        Action
                      </span>
                    </template>
                    <b-dropdown-item href="#" variant="primary">
                      <i class="fas fa-arrow-circle-down mr-2"></i> Swap
                      Position
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </template>
            <template v-if="!maintenanceFinished.isLoading && !maintenanceFinished.data.length">
                <tr>
                  <td class="text-truncate" colspan="4">No data available </td>
                </tr>
            </template>
            <template v-if="maintenanceFinished.isLoading">
              <tr>
                <td colspan="12">
                  <div class="w-100 text-center py-5">
                    <b-spinner></b-spinner>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </b-col>
    <!-- Sidebar View -->
    <b-sidebar
      v-model="viewDetailMaintenanceSidebar"
      id="view-detail-maintenance"
      title="View Job Maintenance"
      width="70%"
      backdrop-variant="dark"
      bg-variant="white"
      right
      backdrop
      shadow
    >
      <template>
        <ViewMaintenanceDetail
          v-if="viewDetailMaintenanceSidebar"
          :isEdit="isEdit"
          :maintenance-id="selectedMaintenanceId"
          :action="actionMaintenanceSidebar"
        />
      </template>
    </b-sidebar>
    <!-- End Sidebar View -->
  </b-row>
</template>

<script>
import { maintenanceActions } from '@/src/Utils/helper'
import moment from 'moment'
import ViewMaintenanceDetail from './ViewMaintenanceDetail.vue'

export default {
  name: 'MaintenanceSummaryListFinish',
  props: {
    fleetCategoryOpt: {
      type: Array,
      default: () => [],
    },
    jobClassList: {
      type: Array,
      default: () => [],
    },
    statusList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ViewMaintenanceDetail,
  },
  data() {
    return {
      columns: [
        {
          label: 'No',
          style: 'sticky-column column-1',
        },
        {
          label: 'Fleet Name',
          style: 'sticky-column column-2',
        },
        {
          label: 'Ship Particular',
          style: 'sticky-column column-3',
        },
        'Equipment',
        'Job Name',
        'Job Class',
        'Trigger',
        'Frequency',
        'Last Maintenance',
        'Finish Maintenance',
        'Cost',
        'Attachment',
        'Action',
      ],
      filterOptionSelected: 'jobClass',
      maintenanceFinished: {
        isLoading: false,
        dateRange: [
          new Date(moment().startOf('month')),
          new Date(moment().endOf('month')),
        ],
        params: {
          action: '',
          search: '',
          status: '',
          startDate: null,
          endDate: null,
          perPage: 25,
          page: 1,
          vehicleIds: [],
          jobTypeIds: [],
          jobClassIds: [],
        },
        data: [],
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0,
        },
      },
      modal: {
        filter: false,
      },
      view_style: 'list',
      selectedMaintenanceId: null,
      viewDetailMaintenanceSidebar: false,
      isEdit: false,
      actionMaintenanceSidebar: 'VIEW_DETAIL',
    }
  },
  methods: {
    ...maintenanceActions,
    async getMaintenanceFinishedList(page) {
      this.maintenanceFinished.isLoading = true
      this.maintenanceFinished.data = []
      this.maintenanceFinished.params.page = page || 1

      if (this.maintenanceFinished.selectedJob === 'job_class') {
        this.maintenanceFinished.params.jobTypeIds = []
      } else {
        this.maintenanceFinished.params.jobClassIds = []
      }

      const paramsQ = {
        search: this.maintenanceFinished.params.search,
        perPage: this.maintenanceFinished.params.perPage,
        page: this.maintenanceFinished.params.page,
        startDate: this.maintenanceFinished.params.startDate,
        endDate: this.maintenanceFinished.params.endDate,
        status: this.maintenanceFinished.params.status,
        vehicleIds: this.maintenanceFinished.params.vehicleIds,
        jobTypeIds: this.maintenanceFinished.params.jobTypeIds,
        jobClassIds: this.maintenanceFinished.params.jobClassIds,
        startDate: moment(this.maintenanceFinished.dateRange[0]).format(
          'DD-MM-YYYY'
        ),
        endDate: moment(this.maintenanceFinished.dateRange[1]).format(
          'DD-MM-YYYY'
        ),
      }

      if (paramsQ.vehicleIds && paramsQ.vehicleIds.length > 0) {
        let dataTemp = []
        paramsQ.vehicleIds.forEach((el) => {
          dataTemp.push(el.code)
        })
        paramsQ.vehicleIds = dataTemp
        paramsQ.vehicleIds = paramsQ.vehicleIds.toString()
      }
      if (paramsQ.jobTypeIds && paramsQ.jobTypeIds.length > 0) {
        let dataTemp = []
        paramsQ.jobTypeIds.forEach((el) => {
          dataTemp.push(el.code)
        })
        paramsQ.jobTypeIds = dataTemp
        paramsQ.jobTypeIds = paramsQ.jobTypeIds.toString()
      }
      if (paramsQ.jobClassIds && paramsQ.jobClassIds.length > 0) {
        let dataTemp = []
        paramsQ.jobClassIds.forEach((el) => {
          dataTemp.push(el.code)
        })
        paramsQ.jobClassIds = dataTemp
        paramsQ.jobClassIds = paramsQ.jobClassIds.toString()
      }
      if (paramsQ.search === '') {
        delete paramsQ.search
      }
      if (paramsQ.status === '') {
        delete paramsQ.status
      }
      const res = await this.getMaintenanceFinished(paramsQ)
      if (res.status == 'success') {
        if (res.data.data && res.data.data.length > 0) {
          this.maintenanceFinished.data = res.data.data
          this.maintenanceFinished.meta.perPage = res.data.perPage
          this.maintenanceFinished.meta.currentPage = res.data.currentPage
          this.maintenanceFinished.meta.total = res.data.total
          this.maintenanceFinished.meta.totalPage = res.data.totalPage
        }
        this.maintenanceFinished.isLoading = false
      } else {
        this.maintenanceFinished.data = []
        this.maintenanceFinished.isLoading = false
        this.$swal(`Oops!`, res.data, 'error')
      }
    },
    viewDetailMaintenance(id, action = 'VIEW_DETAIL', isEdit = false) {
      if (typeof id !== 'undefined' && id) {
        this.selectedMaintenanceId = id
        this.viewDetailMaintenanceSidebar = true
        this.actionMaintenanceSidebar = action
        this.isEdit = isEdit
      }
    },
  },
  mounted() {
    this.getMaintenanceFinishedList()
  },
}
</script>

<style scoped>
.sticky-column {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
}
.column-1 {
  left: 0;
}
.column-2 {
  left: 2.4rem;
}
.column-3 {
  left: 9.4rem;
}
</style>
