var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-sidebar', {
    attrs: {
      "id": "modal---".concat(_vm.id),
      "title": _vm.modalTitle,
      "width": "100%",
      "backdrop-variant": "dark",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_vm.loading ? _c('div', {
          staticClass: "text-center"
        }, [_vm._v(" Loading Content... ")]) : _c('b-row', {
          staticClass: "m-3"
        }, [_vm.fleetDetail ? _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('span', {
          staticClass: "mr-5"
        }, [_c('h4', [_vm._v("Fleet / Equipment : "), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(_vm._s(_vm.fleetDetail.name))])], 1)]), _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table"
        }, [_c('tbody', [_c('tr', {
          staticClass: "bg-primary"
        }, [_c('td', [_c('ul', {
          staticClass: "text-left"
        }, _vm._l(_vm.fleetDetail.powers, function (power, powerIndex) {
          return _c('li', {
            key: powerIndex
          }, [_vm._v(_vm._s(power.powerType ? power.powerType.name : '-') + " - " + _vm._s(power.powerSeries ? power.powerSeries.name : '-') + " " + _vm._s(power.powerSeries ? power.powerSeries.model : '-') + " "), _c('span', {
            staticClass: "pull-right"
          }, [_vm._v("Actual RH : "), _c('b-badge', {
            attrs: {
              "variant": "info"
            }
          }, [_c('strong', [_vm._v(_vm._s(power.actualRunningHours) + " RH")])])], 1)]);
        }), 0)])])])])])]) : _vm._e(), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2"
        }, [_vm._v("Select Docking Job")]), _c('b-row', [['CREATE_EMERGENCY', 'CREATE_FROM_SCHEDULE', 'UPDATE', 'REVIEW', 'APPROVE'].includes(_vm.action) ? _c('b-col', {
          staticClass: "mb-3",
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "job_name",
            "type": "text",
            "placeholder": "Search Job Name"
          },
          model: {
            value: _vm.jobKeyword,
            callback: function callback($$v) {
              _vm.jobKeyword = $$v;
            },
            expression: "jobKeyword"
          }
        })], 1) : _vm._e(), _vm.action !== 'DETAIL' ? _c('b-col', {
          attrs: {
            "md": '4'
          }
        }, [_c('v-jstree', {
          attrs: {
            "data": _vm.filteredJobs,
            "show-checkbox": "",
            "allow-batch": "",
            "whole-row": "",
            "multiple": ""
          },
          on: {
            "item-click": _vm.itemClickInitComponent
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_) {
              return [_c('div', {
                staticStyle: {
                  "display": "inherit",
                  "width": "200px"
                }
              }, [!_.model.loading ? _c('i', {
                class: _.vm.themeIconClasses,
                attrs: {
                  "role": "presentation"
                }
              }) : _vm._e(), _vm._v(" " + _vm._s(_.model.text) + " ")])];
            }
          }], null, true)
        })], 1) : _vm._e(), _c('b-col', {
          attrs: {
            "md": _vm.action !== 'DETAIL' ? '8' : '12'
          }
        }, [_c('b-row', [_c('b-col', {
          staticClass: "mt-3",
          attrs: {
            "md": "12"
          }
        }, [['CREATE_EMERGENCY', 'CREATE_FROM_SCHEDULE', 'UPDATE', 'REVIEW'].includes(_vm.action) ? _c('b-button', {
          staticClass: "mt-3",
          attrs: {
            "block": "",
            "variant": "success"
          },
          on: {
            "click": _vm.onAddJob
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" ADD DOCKING JOB")]) : _vm._e()], 1), _c('b-col', {
          staticClass: "mt-3",
          attrs: {
            "md": "12"
          }
        }, [_vm.selectedGroupOfJobs.length > 0 || !['CREATE_EMERGENCY', 'CREATE_FROM_SCHEDULE'].includes(_vm.action) ? [_c('DockingScheduleForm', {
          attrs: {
            "action": _vm.action,
            "docking-id": _vm.dockingId,
            "vehicle-id": _vm.vehicleId,
            "docking-data": _vm.dockingData,
            "fleet-detail": _vm.fleetDetail,
            "group-of-jobs": _vm.selectedGroupOfJobs
          },
          on: {
            "submit": _vm.onDockingActionSubmit,
            "onFetch": _vm.onFetchDockingDetail
          }
        })] : _vm._e()], 2)], 1)], 1)], 1)], 1)])], 1)];
      }
    }]),
    model: {
      value: _vm.model,
      callback: function callback($$v) {
        _vm.model = $$v;
      },
      expression: "model"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }