<template>
  <b-container fluid>
    <b-modal v-model="modal.filter" centered ok-title="Filter" title="Filter Data" @ok="fetchLogs(1)">
      <b-row>
        <b-col md="12">
          <b-form-group 
            label="Date Range" 
            label-for="dateRange" 
            label-cols-sm="4"
            label-cols-lg="3"
            class="mb-3">
            <date-picker
              style="width: 100%"
              v-model="dateRange"
              type="date"
              range
              placeholder="Select date range"
              format="DD-MM-YYYY"
            ></date-picker>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Role" label-for="role"
            label-cols-sm="4"
            label-cols-lg="3"
            class="mb-3" >
            <v-select
              v-model="params.roleId"
              label="roles"
              :options="roles"
              :reduce="(role) => role.id"
              placeholder="Select Role"
            ></v-select>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="User" label-for="user" label-cols-sm="4" label-cols-lg="3" class="mb-3">
            <v-select
              v-model="userFilters"
              multiple
              :getOptionLabel="(user) => user.userDetail.name"
              :options="users"
              :reduce="(user) => user.id"
              placeholder="Select User"
              @input="onChangeUsers"
            ></v-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title text-primary"
          ><strong>Log History Users</strong></h4
        >
      </template>
      <template v-slot:body>
        <div class="d-flex justify-content-between mb-3">
          <div class="d-flex">
            <b-form-input size="lg" placeholder="search..."></b-form-input>
            <b-button variant="primary" class="mx-2">
              <i class="fa fa-search"></i>
            </b-button>
            <b-button
              variant="outline-primary"
              class="d-flex align-items-center"
              @click="toggleModalFilter"
            >
              <i class="fas fa-sliders"></i>
              <p class="text-truncate m-0"> Filter Data</p>
            </b-button>
          </div>
          <div>
            <div v-if="findMenuPermission(1, 'export', $route.query.menuId)">
              <button class="btn btn-success"
                @click="onExportLogs"
                :disabled="export_loading"
              >
                <span
                  v-if="export_loading"
                  class="spinner-border spinner-border-sm"
                />
                <div v-else class="d-flex gap-2 align-items-center"> 
                  <i class="fa fa-file-excel"></i> 
                  <span>Export</span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div v-if="loading" class="text-center my-5">
          <b-spinner type="grow" label="Spinning"></b-spinner>
        </div>
        <template v-else>
          <div class="table-responsive" v-if="logs.length > 0">
            <table class="table mb-0 table-striped table-hover">
              <thead class="thead-dark">
                <tr>
                  <th width="5%" class="text-center">No</th>
                  <th width="15%" class="text-center">Date</th>
                  <th width="20%" class="text-center">User</th>
                  <th width="15%" class="text-center">Role</th>
                  <th width="30%" class="text-center">Aktivitas</th>
                  <th width="15%" class="text-center">IP/Browser</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in logs" :key="index">
                  <td class="text-center">{{ index + 1 }}</td>
                  <td class="text-truncate text-center">{{ item.date ? moment(item.date, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}</td>
                  <td class="text-truncate text-center">{{ item.name }}</td>
                  <td class="text-truncate text-center">{{ item.role }}</td>
                  <td class="text-truncate text-center">{{ item.activity }}</td>
                  <td class="text-truncate text-center">{{ item.ip }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p v-else class="text-center my-5">No logs data found.</p>
          <b-pagination
            v-model="meta.currentPage"
            :total-rows="meta.total"
            @change="fetchLogs"
            :per-page="meta.perPage"
            first-number
            align="center"
          ></b-pagination>
        </template>
      </template>
    </iq-card>
  </b-container>
</template>

<script>
import { rolesActions, usersActions, logsActions } from '@src/Utils/helper'
import { saveAs } from 'file-saver'
import moment from 'moment'

export default {
  name: 'Logs',
  data () {
    return {
      params: {
        page: 1,
        roleId: '',
        userIds: '',
        startDate: '',
        endDate:'',
      },
      meta: {
        perPage: 50,
        currentPage: 0,
        total: 0,
        totalPage: 0
      },
      export_loading:false,
      loading: true,
      dateRange: [moment(new Date()).startOf("month").toDate(), moment(new Date()).endOf("month").toDate()],
      userFilters: [],
      roles: [],
      users: [],
      logs: [],
      modal:{
        filter:false
      }
    }
  },
  mounted () {
    this.fetchRoles()
    this.fetchUsers()
    this.fetchLogs()
  },
  methods: {
    ...rolesActions,
    ...usersActions,
    ...logsActions,
    compareDates(a, b) {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);

      if (dateA > dateB) return -1; // Return a negative value to sort in descending order
      if (dateA < dateB) return 1;  // Return a positive value to sort in ascending order
      return 0; // Dates are equal
    },
    async fetchRoles () {
      let res = await this.getRoles()
      this.roles = res.data
      this.roles.unshift({
        id: '',
        roles: 'All'
      })
    },
    async fetchUsers () {
      let { data } = await this.getUsers({ page: 1, showAll: true, perPage:1000 })
      if (data.data) {
        this.users = data.data
        this.users.unshift({
          id: '',
          userDetail: {
            name: 'All'
          }
        })
      }
    },
    async fetchLogs (page = 1) {
      this.params.page = page || 1

      this.loading = true
      let params = this.$options.filters.cleanObject(this.params)

      if(this.dateRange[0] !== null && this.dateRange[1] !== null){
        params.startDate = moment(this.dateRange[0]).format('DD-MM-YYYY HH:mm')
        params.endDate = moment(this.dateRange[1]).format('DD-MM-YYYY HH:mm')
      } else {
        params.startDate = null
        params.endDate = null
      }

      if(!params.startDate) delete params.startDate
      if(!params.endDate) delete params.endDate
      if(!params.roleId) delete params.roleId
      if(!params.userIds) delete params.userIds

      let { data } = await this.getLogs(params)

      if (data.data) {
        this.meta.perPage = data.perPage
        this.meta.currentPage = data.currentPage
        this.meta.total = data.total
        this.meta.totalPage = data.totalPage

        this.logs = data.data.sort(this.compareDates);

        this.loading = false
      }
    },
    onChangeDate () {
      this.fetchLogs()
    },
    onChangeUsers () {
      this.params.userIds = this.userFilters.join(',')
      // this.fetchLogs()
    },
    async onExportLogs(){
      this.export_loading = true
      let params = this.$options.filters.cleanObject(this.params)


      if(this.dateRange.length > 0){
        params.startDate = moment(this.dateRange[0]).format('DD-MM-YYYY HH:mm')
        params.endDate = moment(this.dateRange[1]).format('DD-MM-YYYY HH:mm')
      }
      
      const res = await this.exportLogs(params)

      if(res.status !== "success"){
        this.$swal(`Oops!`, `Something went wrong.`, 'error')
        this.export_loading = false
        return
      }
      saveAs(res.data, 'Logs Users.xlsx')
      this.export_loading = false
    },
    toggleModalFilter(){
      this.modal.filter = !this.modal.filter
    }
  }
}
</script>

<style scoped>
</style>
