<template>
  <b-row>
    <b-col md="12">
      <b-modal
        v-model="modal.filter"
        centered
        size="lg"
        title="Filter Data"
        ok-title="Filter"
        @ok="getMaintenanceList"
      >
        <b-row class="px-3">
          <b-col md="12">
            <b-form-group
              label="Period"
              label-for="dateRange"
              label-cols-sm="4"
              label-cols-lg="3"
            >
              <date-picker
                style="width: 100%"
                v-model="maintenance.dateRange"
                type="date"
                range
                placeholder="Select date range"
              ></date-picker>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Fleet Category"
              label-for="categoryId"
              label-cols-sm="4"
              label-cols-lg="3"
            >
              <v-multiselect
                v-model="maintenance.params.vehicleTypeIds"
                tag-placeholder="Fleets"
                placeholder="Search Fleets"
                label="name"
                track-by="code"
                :options="fleetCategoryOpt"
                :multiple="true"
                :taggable="true"
              >
              </v-multiselect>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Fleet Name"
              label-for="vehicleIds"
              label-cols-sm="4"
              label-cols-lg="3"
            >
              <v-multiselect
                v-model="maintenance.params.vehicleIds"
                tag-placeholder="Fleets"
                placeholder="Search Fleets"
                label="name"
                track-by="code"
                :options="fleetNameOpt"
                :multiple="true"
                :taggable="true"
              >
              </v-multiselect>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Status"
              label-for="status"
              label-cols-sm="4"
              label-cols-lg="3"
            >
              <b-form-select
                plain
                :options="statusList"
                v-model="maintenance.params.status"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Filter Options"
              label-for="ownership"
              label-cols-sm="4"
              label-cols-lg="3"
            >
              <div class="d-flex">
                <b-form-radio
                  v-model="filterOptionSelected"
                  name="radio-a"
                  class="mr-3"
                  value="jobClass"
                  >Job Class</b-form-radio
                >
                <b-form-radio
                  v-model="filterOptionSelected"
                  name="radio-b"
                  value="jobType"
                  >Job Type</b-form-radio
                >
              </div>
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="filterOptionSelected == 'jobClass'">
            <b-form-group
              label="Job Class"
              label-for="job-class"
              label-cols-sm="4"
              label-cols-lg="3"
            >
              <v-multiselect
                v-model="maintenance.params.jobClassIds"
                tag-placeholder=""
                placeholder="Search job class"
                label="name"
                id="job-class"
                track-by="code"
                :options="jobClassList"
                :multiple="true"
                :taggable="true"
              >
              </v-multiselect>
            </b-form-group>
          </b-col>
          <b-col md="12" v-else>
            <b-form-group
              label="Job Type"
              label-for="job-type"
              label-cols-sm="4"
              label-cols-lg="3"
            >
              <v-multiselect
                v-model="maintenance.params.jobTypeIds"
                tag-placeholder=""
                placeholder="Search job class"
                label="name"
                id="job-type"
                track-by="code"
                :options="jobTypeList"
                :multiple="true"
                :taggable="true"
              >
              </v-multiselect>
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>
    </b-col>

    <b-col md="12">
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title text-primary">
            <strong>Maintenance List</strong>
          </h4>
        </template>
        <template v-slot:body>
          <MaintenanceSummaryCards :meta="maintenance.meta" />
          <CustomNav
            :items="navItems"
            class="mb-3"
            @item-click="(val) => (viewMenu = val)"
          ></CustomNav>
          <template v-if="viewMenu === 'progress'">
            <MaintenanceSummaryListProgress
              :jobClassList="jobClassList"
              :jobTypeList="jobTypeList"
              @toggleFilter="toggleFilter"
              :maintenance="maintenance"
              @refetch="getMaintenanceList"
              :filterOptionSelected="filterOptionSelected"
              :statusList="statusList"
            />
          </template>
          <template v-if="viewMenu === 'finish'">
            <MaintenanceSummaryListFinish
              :jobClassList="jobClassList"
              :jobTypeList="jobTypeList"
              :fleetCategoryOpt="fleetCategoryOpt"
              :statusList="statusList"
            />
          </template>
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>

<script>
import AdvancedFilter from './Components/AdvancedFilter.vue'
import MaintenanceSummaryListProgress from './MaintenanceSummaryListProgress.vue'
import MaintenanceSummaryListFinish from './MaintenanceSummaryListFinish.vue'
import CustomNav from '@/src/components/global/CustomNav.vue'
import MaintenanceSummaryCards from './Components/MaintenanceSummaryCards.vue'
import {
  filesActions,
  fleetsActions,
  maintenanceActions,
} from '@/src/Utils/helper'
import moment from 'moment'

export default {
  name: 'MaintenanceSummaryList',
  props: {},
  components: {
    AdvancedFilter,
    MaintenanceSummaryListProgress,
    MaintenanceSummaryListFinish,
    CustomNav,
    MaintenanceSummaryCards,
  },
  mounted() {},
  data() {
    return {
      viewMenu: 'progress',
      filterOptionSelected: 'jobClass',
      tabMenuOptions: {
        progress: {
          isHover: false,
          isActive: false,
        },
        finish: {
          isHover: false,
          isActive: false,
        },
      },
      list: {},
      modal: {
        filter: false,
      },
      loading: {
        fleet: false,
      },
      navItems: [
        { label: 'Maintenance List', value: 'progress' },
        { label: 'Maintenance Finish', value: 'finish' },
      ],
      statusList: [
        { value: null, text: 'All' },
        { value: 'TOWARDS', text: 'Towards Jobs' },
        { value: 'OVERDUE', text: 'Overdue' },
        { value: 'POSTPONE', text: 'Postponed' },
        { value: 'MONTHS3', text: 'Next 3 Month' },
        { value: 'MONTHS6', text: 'Next 6 Month' },
        { value: 'MONTHS12', text: 'Next 12 Month' },
      ],
      fleetCategoryOpt: [],
      fleetNameOpt: [],
      jobClassList: [],
      jobTypeList: [],
      maintenance: {
        selectedJob: 'job_class',
        dateRange: [
          new Date(moment().startOf('month')),
          new Date(moment().endOf('month')),
        ],
        selectedJobClass: null,
        isLoading: false,
        editMode: false,
        params: {
          action: '',
          search: '',
          status: null,
          startDate: null,
          endDate: null,
          perPage: 25,
          page: 1,
          vehicleIds: [],
          jobTypeIds: [],
          jobClassIds: [],
          vehicleTypeIds:[]
        },
        data: [],
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0,
          towards: 0,
          overdue: 0,
          postponed: 0,
          '3months': 0,
          '6months': 0,
          '12months': 0,
        },
      },
    }
  },
  methods: {
    ...fleetsActions,
    ...maintenanceActions,
    ...filesActions,
    toggleFilter() {
      this.modal.filter = !this.modal.filter
    },
    async fetchFleets() {
      let params = {
        page: 1,
        perPage: 1000,
      }
      try {
        this.fleetNameOpt = []
        const res = await this.getFleets(params)
        res.data.forEach((el) => {
          el.componentSetup = []
          this.fleetNameOpt.push({
            name: el.name,
            code: el.id,
          })
        })
      } catch (error) {
        console.log(error)
      }
    },
    async fetchFleetCategories() {
      let params = {
        page: 1,
        perPage: 1000,
      }
      try {
        this.fleetCategoryOpt = []
        const res = await this.getFleetTypes(params)
        res.data.forEach((el) => {
          el.componentSetup = []
          this.fleetCategoryOpt.push({
            name: el.name,
            code: el.id,
          })
        })
      } catch (error) {
        console.log(error)
      }
    },
    async getJobClassList() {
      const params = {
        page: 1,
        perPage: 1000,
      }

      try {
        this.jobClassList = []
        const res = await this.getJobClass(params)
        res.data.data.forEach((el) => {
          this.jobClassList.push({
            name: `${el.jobClassCode} - ${el.jobClass}`,
            code: el.id,
          })
        })
      } catch (error) {
        console.log(error)
      }
    },
    async getJobTypeList() {
      try {
        const params = {
          page: 1,
          perPage: 1000,
        }
        this.jobTypeList = []
        const res = await this.getJobType(params)
        res.data.data.forEach((el) => {
          this.jobTypeList.push({
            name: `${el.jobTypeCode} - ${el.jobTypeIndex}`,
            code: el.id,
          })
        })
      } catch (error) {
        console.log(error)
      }
    },
    async getMaintenanceList() {
      this.maintenance.isLoading = true
      this.maintenance.data = []

      if (this.maintenance.selectedJob === 'job_class') {
        this.maintenance.params.jobTypeIds = []
      } else {
        this.maintenance.params.jobClassIds = []
      }

      const paramsQ = {
        search: this.maintenance.params.search,
        perPage: this.maintenance.params.perPage,
        page: this.maintenance.params.page,
        status: this.maintenance.params.status,
        vehicleIds: this.maintenance.params.vehicleIds,
        jobTypeIds: this.maintenance.params.jobTypeIds,
        jobClassIds: this.maintenance.params.jobClassIds,
        // startDate: moment(this.maintenance.dateRange[0]).format('DD-MM-YYYY'),
        // endDate: moment(this.maintenance.dateRange[1]).format('DD-MM-YYYY')
      }

      if (paramsQ.vehicleIds && paramsQ.vehicleIds.length > 0) {
        let dataTemp = []
        paramsQ.vehicleIds.forEach((el) => {
          dataTemp.push(el.code)
        })
        paramsQ.vehicleIds = dataTemp
        paramsQ.vehicleIds = paramsQ.vehicleIds.toString()
      }
      if (paramsQ.jobTypeIds && paramsQ.jobTypeIds.length > 0) {
        let dataTemp = []
        paramsQ.jobTypeIds.forEach((el) => {
          dataTemp.push(el.code)
        })
        paramsQ.jobTypeIds = dataTemp
        paramsQ.jobTypeIds = paramsQ.jobTypeIds.toString()
      }
      if (paramsQ.jobClassIds && paramsQ.jobClassIds.length > 0) {
        let dataTemp = []
        paramsQ.jobClassIds.forEach((el) => {
          dataTemp.push(el.code)
        })
        paramsQ.jobClassIds = dataTemp
        paramsQ.jobClassIds = paramsQ.jobClassIds.toString()
      }
      if (paramsQ.search === '') {
        delete paramsQ.search
      }
      if (paramsQ.status === '') {
        delete paramsQ.status
      }

      try {
        const res = await this.getMaintenance(paramsQ)
        const sortedData = res.data.data.sort((a, b) => {
          const dateA = new Date(
            a.lastMaintenanceDate.split('-').reverse().join('-')
          )
          const dateB = new Date(
            b.lastMaintenanceDate.split('-').reverse().join('-')
          )

          // If the years are different, prioritize entries from 2022
          if (dateA.getFullYear() !== dateB.getFullYear()) {
            return dateA.getFullYear() - dateB.getFullYear()
          }

          // If the years are the same, proceed with regular sorting
          return dateA - dateB
        })

        // console.log(sortedData, 'SORTED DATA')

        this.maintenance.data = sortedData
        this.maintenance.meta.perPage = res.data.perPage
        this.maintenance.meta.currentPage = res.data.currentPage
        this.maintenance.meta.total = res.data.total
        this.maintenance.meta.totalPage = res.data.totalPage

        this.maintenance.meta.towards = res.data.meta[0]?.towards
        this.maintenance.meta.overdue = res.data.meta[0]?.overdue
        this.maintenance.meta.postponed = res.data.meta[0]?.postponed
        this.maintenance.meta['3months'] = res.data.meta[0]?.['3months']
        this.maintenance.meta['6months'] = res.data.meta[0]?.['6months']

        this.maintenance.isLoading = false
      } catch (error) {
        this.maintenance.data = []
        this.maintenance.isLoading = false
        this.$swal(`Oops!`, res.data, 'error')
      }
    },
  },
  mounted() {
    this.fetchFleets()
    this.fetchFleetCategories()
    this.getJobClassList()
    this.getJobTypeList()
    this.getMaintenanceList()
  },
}
</script>

<style scoped>
.custom-button {
  background-color: #2bdcaa; /* Custom color */
  color: white; /* Text color */
  margin-top: 32px;
}
.btn-menu:hover {
  background: #213f8b !important;
  border: #213f8b;
}
.btn-menu-active {
  background: #213f8b !important;
  color: white !important;
}
</style>
