var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    staticClass: "d-flex justify-content-between",
    attrs: {
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex mb-3"
  }, [_c('b-form-input', {
    attrs: {
      "size": "lg",
      "placeholder": "search..."
    }
  }), _c('b-button', {
    staticClass: "mx-2",
    attrs: {
      "variant": "primary"
    }
  }, [_c('i', {
    staticClass: "fa fa-search"
  })]), _c('b-button', {
    staticClass: "d-flex align-items-center",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        _vm.modal.filter = true;
      }
    }
  }, [_c('i', {
    staticClass: "fas fa-sliders"
  }), _c('p', {
    staticClass: "text-truncate m-0"
  }, [_vm._v(" Filter Data ")])])], 1), _c('b-modal', {
    attrs: {
      "centered": "",
      "size": "lg",
      "title": "Filter Data",
      "ok-title": "Filter"
    },
    on: {
      "ok": function ok($event) {
        return _vm.getMaintenanceFinishedList();
      }
    },
    model: {
      value: _vm.modal.filter,
      callback: function callback($$v) {
        _vm.$set(_vm.modal, "filter", $$v);
      },
      expression: "modal.filter"
    }
  }, [_c('b-row', {
    staticClass: "px-3"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Date",
      "label-for": "dateRange",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('date-picker', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "type": "date",
      "range": "",
      "placeholder": "Select date range"
    },
    model: {
      value: _vm.maintenanceFinished.dateRange,
      callback: function callback($$v) {
        _vm.$set(_vm.maintenanceFinished, "dateRange", $$v);
      },
      expression: "maintenanceFinished.dateRange"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fleet Category",
      "label-for": "categoryId",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('v-multiselect', {
    attrs: {
      "tag-placeholder": "Fleets",
      "placeholder": "Search Fleets",
      "label": "name",
      "track-by": "code",
      "options": [],
      "multiple": true,
      "taggable": true
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fleet Name",
      "label-for": "vehicleIds",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('v-multiselect', {
    attrs: {
      "tag-placeholder": "Fleets",
      "placeholder": "Search Fleets",
      "label": "name",
      "track-by": "code",
      "options": _vm.fleetCategoryOpt,
      "multiple": true,
      "taggable": true
    },
    model: {
      value: _vm.maintenanceFinished.params.vehicleIds,
      callback: function callback($$v) {
        _vm.$set(_vm.maintenanceFinished.params, "vehicleIds", $$v);
      },
      expression: "maintenanceFinished.params.vehicleIds"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status",
      "label-for": "status",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": "",
      "options": _vm.statusList
    },
    model: {
      value: _vm.maintenanceFinished.params.status,
      callback: function callback($$v) {
        _vm.$set(_vm.maintenanceFinished.params, "status", $$v);
      },
      expression: "maintenanceFinished.params.status"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Filter Options",
      "label-for": "ownership",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('b-form-radio', {
    staticClass: "mr-3",
    attrs: {
      "name": "radio-a",
      "value": "jobClass"
    },
    model: {
      value: _vm.filterOptionSelected,
      callback: function callback($$v) {
        _vm.filterOptionSelected = $$v;
      },
      expression: "filterOptionSelected"
    }
  }, [_vm._v("Job Class")]), _c('b-form-radio', {
    attrs: {
      "name": "radio-b",
      "value": "jobType"
    },
    model: {
      value: _vm.filterOptionSelected,
      callback: function callback($$v) {
        _vm.filterOptionSelected = $$v;
      },
      expression: "filterOptionSelected"
    }
  }, [_vm._v("Job Type")])], 1)])], 1), _vm.filterOptionSelected == 'jobClass' ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Job Class",
      "label-for": "job-class",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('v-multiselect', {
    attrs: {
      "tag-placeholder": "",
      "placeholder": "Search job class",
      "label": "name",
      "id": "job-class",
      "track-by": "code",
      "options": _vm.jobClassList,
      "multiple": true,
      "taggable": true
    },
    model: {
      value: _vm.maintenanceFinished.params.jobClassIds,
      callback: function callback($$v) {
        _vm.$set(_vm.maintenanceFinished.params, "jobClassIds", $$v);
      },
      expression: "maintenanceFinished.params.jobClassIds"
    }
  })], 1)], 1) : _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Job Type",
      "label-for": "job-type",
      "label-cols-sm": "4",
      "label-cols-lg": "3"
    }
  }, [_c('v-multiselect', {
    attrs: {
      "tag-placeholder": "",
      "placeholder": "Search job class",
      "label": "name",
      "id": "job-type",
      "track-by": "code",
      "options": _vm.jobTypeList,
      "multiple": true,
      "taggable": true
    },
    model: {
      value: _vm.maintenanceFinished.params.jobTypeIds,
      callback: function callback($$v) {
        _vm.$set(_vm.maintenanceFinished.params, "jobTypeIds", $$v);
      },
      expression: "maintenanceFinished.params.jobTypeIds"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex overflow-auto"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', {
    staticClass: "bg-primary"
  }, _vm._l(_vm.columns, function (col, i) {
    var _col$label;
    return _c('th', {
      key: i,
      staticClass: "text-truncate",
      class: col.style,
      staticStyle: {
        "background-color": "#099bab",
        "color": "white"
      }
    }, [_vm._v(_vm._s((_col$label = col.label) !== null && _col$label !== void 0 ? _col$label : col))]);
  }), 0)]), _c('tbody', [!_vm.maintenanceFinished.isLoading && _vm.maintenanceFinished.data.length ? _vm._l(_vm.maintenanceFinished.data, function (job, index) {
    var _job$vehicleName, _job$componentName, _job$equipmentName, _job$jobName, _job$jobClass, _job$trigger, _job$interval, _job$dueDate, _job$cost;
    return _c('tr', {
      key: index,
      style: "background:".concat(index % 2 != 0 ? 'white' : '#f5f5f5')
    }, [_c('td', {
      staticClass: "text-center sticky-column column-1",
      style: "background:".concat(index % 2 != 0 ? 'white' : '#f5f5f5')
    }, [_vm._v(_vm._s(index + 1))]), _c('td', {
      staticClass: "text-truncate text-left sticky-column column-2",
      style: "background:".concat(index % 2 != 0 ? 'white' : '#f5f5f5')
    }, [_c('a', {
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.viewDetailMaintenance(job.id, 'VIEW_DETAIL');
        }
      }
    }, [_vm._v(_vm._s((_job$vehicleName = job.vehicleName) !== null && _job$vehicleName !== void 0 ? _job$vehicleName : '-'))])]), _c('td', {
      staticClass: "text-truncate text-left sticky-column column-3",
      style: "background:".concat(index % 2 != 0 ? 'white' : '#f5f5f5')
    }, [_vm._v(_vm._s((_job$componentName = job.componentName) !== null && _job$componentName !== void 0 ? _job$componentName : '-'))]), _c('td', {
      staticClass: "text-truncate text-left"
    }, [_vm._v(_vm._s((_job$equipmentName = job.equipmentName) !== null && _job$equipmentName !== void 0 ? _job$equipmentName : '-'))]), _c('td', {
      staticClass: "text-truncate text-left"
    }, [_vm._v(_vm._s((_job$jobName = job.jobName) !== null && _job$jobName !== void 0 ? _job$jobName : '-'))]), _c('td', {
      staticClass: "text-truncate text-left"
    }, [_vm._v(_vm._s((_job$jobClass = job.jobClass) !== null && _job$jobClass !== void 0 ? _job$jobClass : '-'))]), _c('td', {
      staticClass: "text-truncate text-left"
    }, [_c('b-badge', {
      attrs: {
        "variant": "success"
      }
    }, [_vm._v(_vm._s((_job$trigger = job.trigger) !== null && _job$trigger !== void 0 ? _job$trigger : '-'))])], 1), _c('td', {
      staticClass: "text-truncate text-left"
    }, [_vm._v(_vm._s((_job$interval = job.interval) !== null && _job$interval !== void 0 ? _job$interval : '-'))]), _c('td', {
      staticClass: "text-truncate text-left"
    }, [_vm._v(_vm._s(job.lastMaintenanceDate) + " "), job.lastMaintenanceHours ? _c('span', [_vm._v("| " + _vm._s(job.lastMaintenanceHours))]) : _vm._e()]), _c('td', {
      staticClass: "text-truncate text-left"
    }, [_vm._v(_vm._s((_job$dueDate = job.dueDate) !== null && _job$dueDate !== void 0 ? _job$dueDate : '-'))]), _c('td', {
      staticClass: "text-truncate text-left text-right"
    }, [_vm._v("Rp. " + _vm._s((_job$cost = job === null || job === void 0 ? void 0 : job.cost) !== null && _job$cost !== void 0 ? _job$cost : 0))]), _c('td', {
      staticClass: "text-truncate text-left",
      staticStyle: {
        "cursor": "pointer"
      }
    }, [_c('div', {
      staticClass: "p-1 d-flex align-items-center px-2 rounded-sm",
      staticStyle: {
        "background-color": "#eceeed"
      }
    }, [_c('i', {
      staticClass: "fa fa-download mr-2",
      staticStyle: {
        "color": "#3862dd"
      }
    }), _vm._v(" Attachment.pdf ")])]), _c('td', {
      staticClass: "text-right"
    }, [_c('b-dropdown', {
      staticClass: "rounded-sm",
      staticStyle: {
        "background-color": "#3d3d3d"
      },
      attrs: {
        "size": "sm",
        "variant": "link iq-border-radius-10",
        "toggle-class": "text-decoration-none",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function fn() {
          return [_c('span', {
            staticClass: "dropdown-toggle p-0 text-white",
            attrs: {
              "id": "dropdownMenuButton5",
              "data-toggle": "dropdown"
            }
          }, [_vm._v(" Action ")])];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": "primary"
      }
    }, [_c('i', {
      staticClass: "fas fa-arrow-circle-down mr-2"
    }), _vm._v(" Swap Position ")])], 1)], 1)]);
  }) : _vm._e(), !_vm.maintenanceFinished.isLoading && !_vm.maintenanceFinished.data.length ? [_c('tr', [_c('td', {
    staticClass: "text-truncate",
    attrs: {
      "colspan": "4"
    }
  }, [_vm._v("No data available ")])])] : _vm._e(), _vm.maintenanceFinished.isLoading ? [_c('tr', [_c('td', {
    attrs: {
      "colspan": "12"
    }
  }, [_c('div', {
    staticClass: "w-100 text-center py-5"
  }, [_c('b-spinner')], 1)])])] : _vm._e()], 2)])])]), _c('b-sidebar', {
    attrs: {
      "id": "view-detail-maintenance",
      "title": "View Job Maintenance",
      "width": "70%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    model: {
      value: _vm.viewDetailMaintenanceSidebar,
      callback: function callback($$v) {
        _vm.viewDetailMaintenanceSidebar = $$v;
      },
      expression: "viewDetailMaintenanceSidebar"
    }
  }, [[_vm.viewDetailMaintenanceSidebar ? _c('ViewMaintenanceDetail', {
    attrs: {
      "isEdit": _vm.isEdit,
      "maintenance-id": _vm.selectedMaintenanceId,
      "action": _vm.actionMaintenanceSidebar
    }
  }) : _vm._e()]], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }