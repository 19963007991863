<template>
  <div>
    <b-row>
      <b-col md="4">
        <b-input v-model="keyword" class="mb-3" placeholder="Search..."></b-input>
      </b-col>
      <b-col md="12" class="mt-4 m-auto">
        <iq-card
          class-name="iq-card-block iq-card-stretch iq-card-height"
          body-class=""
        >
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">
              <strong>Evaluations</strong>
            </h4>
          </template>
          <template v-slot:headerAction>
            <a
              class="text-primary float-right"
              v-b-toggle.collapse-evaluations
              role="button"
              style="cursor: pointer"
            >
              <i class="fa fa-filter" />
            </a>
          </template>
          <template v-slot:body>
            <b-collapse id="collapse-evaluations" class="mb-2" visible>
              <table class="table table-bordered">
                <thead>
                <tr class="bg-warning">
                  <th class="">Vessel</th>
                  <th class="">Activity Stages</th>
                  <th class="">Milestone</th>
                  <th class="">Job Description</th>
                  <th class="">Success Indicator</th>
                  <th class="">Evaluation Method</th>
                  <th class="">Frequency</th>
                  <th class="">Executor</th>
                  <th class="">Follow Up</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="evaluations.length === 0">
                  <td colspan="9">No Data or Search not found</td>
                </tr>
                <template
                  v-for="(list, index) in evaluations"
                >
                  <tr :key="index" v-if="list.data.length > 0">
                    <td :rowspan="Number(list.data.length)+1">
                      {{ list.vehicle ? list.vehicle.name : '-' }}
                    </td>
                  </tr>
                  <tr :key="index" v-else>
                    <td :rowspan="Number(list.data.length)+1">
                      {{ list.vehicle ? list.vehicle.name : '-' }}
                    </td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                  </tr>
                  <tr
                    v-for="(listData, indexData) in list.data"
                    :key="indexData"
                  >
                    <td>
                      {{listData.activity_stage}}
                    </td>
                    <td>
                      {{listData.milestone}}
                    </td>
                    <td>
                      {{listData.job_description}}
                    </td>
                    <td>
                      {{listData.success_indicator}}
                    </td>
                    <td>
                      {{listData.evaluation_result}}
                    </td>
                    <td>
                      {{listData.frequency}}
                    </td>
                    <td>
                      {{listData.executor}}
                    </td>
                    <td>
                      {{listData.follow_up_needs}}
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </b-collapse>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { numberFormat } from '@src/plugins/helpers'

export default {
  name: 'SummaryManagementEvaluations',
  props: {
    lists: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    evaluations () {
      if (!this.lists) {
        return []
      }

      return this.lists.filter(form => {
        if (!this.keyword) {
          return true
        }

        return (form.vehicle && form.vehicle.name.toString().toLowerCase().includes(this.keyword.toLowerCase()))
      })
    }
  },
  data () {
    return {
      keyword: ''
    }
  },
  methods: {
    numberFormat (number) {
      return numberFormat(number)
    }
  }
}
</script>

<style scoped>

</style>
