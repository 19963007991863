<template>
    <b-container>
        <b-row>
            <b-col lg="12">
                <iq-card body-class="p-0">
                    <template v-slot:headerTitle>
                        <h4 class="card-title text-primary p-0 m-0">
                            <strong>SERVICE & REPAIR DASHBOARD</strong>
                        </h4>
                    </template>
                    <template v-slot:headerAction>
                        <a class="text-primary float-right" v-b-toggle.collapse-filter role="button" style="cursor: pointer">
                            <i class="fa fa-filter" />
                        </a>
                    </template>
                    <template v-slot:body>
                        <b-collapse visible id="collapse-filter" class="mb-2">
                            <b-row>
                                <b-col md="6">
                                    <date-picker :lang="lang" v-model="params.dateRange" type="date" range placeholder="Select date range"></date-picker>
                                </b-col>
                            </b-row>
                        </b-collapse>
                    </template>
                </iq-card>
            </b-col>
            <b-col lg="12" class="mb-4">
                <div class="iq-edit-list">
                    <tab-nav :pills="true" class="iq-edit-profile d-flex">
                        <tab-nav-items class="col-md-4 p-0" :active="true" href="#request" title="REQUEST & PROGRESS" />
                        <tab-nav-items class="col-md-4 p-0" :active="false" href="#finish" title="FINISH REPAIR & MAINTENANCE" />
                        <tab-nav-items class="col-md-4 p-0" :active="false" href="#report" title="REPORT REPAIR & MAINTENANCE" />
                    </tab-nav>
                </div>
            </b-col>
            <b-col lg="12">
                <div class="iq-edit-list-data">
                    <div id="pills-tabContent-2" class="tab-content">
                    <tab-content-item :active="true" id="request">
                        <TabRequestAndProgress :readOnly="true" :fleetId="Number($route.query.vehicleId)" :query="params" />
                    </tab-content-item>
                    <tab-content-item :active="false" id="finish">
                        <TabFinish :fleetId="Number($route.query.vehicleId)" :query="params" :readOnly="true" />
                    </tab-content-item>
                    <tab-content-item :active="false" id="report">
                        <TabReport :fleetId="Number($route.query.vehicleId)" :query="params" :readOnly="true" />
                    </tab-content-item>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import { xray }from '../../config/pluginInit'
import {
      fleetsActions,
      crewingActions,
      srActions
    } from '@src/Utils/helper'
import moment from 'moment'
import TabRequestAndProgress from './TabRequestAndProgress.vue'
import TabFinish from './TabFinish.vue'
import TabReport from './TabReport.vue'

export default {
    name: 'ServiceRepairSummary',
    data(){
        return {
            data:null,
            params:{
                fleetCategory: [],
                fleet:null,
                dateRange: null,
                status:null
            },
        }
    },
    components:{
        TabRequestAndProgress,
        TabFinish,
        TabReport
    },
   async mounted () {
        xray.index()
        this.params.dateRange = this.defaultRange;
    },
    computed: {
        defaultRange() {
          const currentDate = new Date() ;
          const nextDay = new Date(currentDate);
          nextDay.setDate(currentDate.getDate() + 1);
          const firstMonthOfTheYear = new Date(
            currentDate.getFullYear(),
            0,
            1
          );
          return [firstMonthOfTheYear, nextDay];
        },
    },
    methods:{
        ...srActions,
        ...crewingActions,
        formatCurrency(value) {
          return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
          }).format(value)
        },
         handleOpenFilePreview: function (url) {
            window.open(url, "_blank");
        },
        departmentName(value){
        //   let department_name = this.optionsDepartment.find(obj => value === obj.value)
        let department_name = null
          return department_name?.text ?? "N/A"
        },
    }

}
</script>
