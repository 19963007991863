var render = function render(){
  var _vm$maintenanceDetail, _vm$maintenanceDetail2, _vm$maintenanceDetail3, _vm$maintenanceDetail4, _vm$maintenanceDetail5, _vm$maintenanceDetail6, _vm$maintenanceDetail9, _vm$maintenanceDetail10, _vm$maintenanceDetail13, _vm$maintenanceDetail14, _vm$maintenanceDetail15, _vm$maintenanceDetail20, _vm$maintenanceDetail21, _vm$maintenanceDetail22, _vm$maintenanceDetail23, _vm$maintenanceDetail24, _vm$maintenanceDetail25, _vm$maintenanceDetail26, _vm$maintenanceDetail27, _vm$maintenanceDetail28, _vm$maintenanceDetail29, _vm$maintenanceDetail30, _vm$maintenanceDetail31, _vm$maintenanceDetail32, _vm$maintenanceDetail33, _vm$maintenanceDetail34, _vm$maintenanceDetail35, _vm$maintenanceDetail36, _vm$maintenanceDetail37, _vm$maintenanceDetail38, _vm$maintenanceDetail39, _vm$maintenanceDetail40, _vm$maintenanceDetail41, _vm$maintenanceDetail42, _vm$maintenanceDetail43, _vm$maintenanceDetail44, _vm$maintenanceDetail45, _vm$maintenanceDetail46, _vm$maintenanceDetail47, _vm$maintenanceDetail48, _vm$maintenanceDetail49;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "m-4 bg-white",
    staticStyle: {
      "border-radius": "12px"
    }
  }, [_vm.maintenanceDetailData ? _c('b-row', {
    staticClass: "p-4"
  }, [_vm.stateAction === 'VIEW_DETAIL' ? _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "12"
    }
  }, [['REVIEW'].includes(_vm.maintenanceDetailData.status) && _vm.canReview ? _c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "info",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.onReview(_vm.maintenanceId, true);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-eye"
  }), _vm._v(" Review ")]) : _vm._e(), ['APPROVE'].includes(_vm.maintenanceDetailData.status) && _vm.canApprove ? _c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "success",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.onApprove(_vm.maintenanceId, true);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-check"
  }), _vm._v(" Approve ")]) : _vm._e(), ['TOWARDS', 'OVERDUE'].includes(_vm.maintenanceDetailData.status) && _vm.canAdd ? _c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "primary",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.onAction('ON_MAINTENANCE');
      }
    }
  }, [_c('i', {
    staticClass: "las la-tasks"
  }), _vm._v("Maintenance ")]) : _vm._e(), ['TOWARDS', 'OVERDUE'].includes(_vm.maintenanceDetailData.status) ? _c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "warning",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.onAction('ON_POSTPONE');
      }
    }
  }, [_c('i', {
    staticClass: "las la-calendar"
  }), _vm._v(" Postpone ")]) : _vm._e()], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "w-50 p-5 text-center"
  }, [_c('img', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "src": (_vm$maintenanceDetail = _vm.maintenanceDetailData.vehicle.vehicleType.icon.url) !== null && _vm$maintenanceDetail !== void 0 ? _vm$maintenanceDetail : require('@src/assets/images/Km3FO09.png'),
      "alt": "images"
    }
  })]), _c('div', {
    staticClass: "w-50"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "mr-2 mb-2"
  }, [_c('IconFleet')], 1), _c('div', {
    staticClass: "d-flex flex-column align-items-start"
  }, [_c('strong', {
    staticClass: "text-primary"
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.vehicle.name) + " 🇮🇩")]), _c('span', [_vm._v(_vm._s(_vm.maintenanceDetailData.vehicle.company.company))])])]), _c('div', {
    staticClass: "d-flex"
  }, [_c('table', {
    staticClass: "table table-striped",
    staticStyle: {
      "font-size": "14px"
    }
  }, [_c('tbody', [_c('tr', [_c('td', [_vm._v("Fleet Type")]), _c('th', [_vm._v(_vm._s(_vm.maintenanceDetailData.vehicle.vehicleType.name))])]), _c('tr', [_c('td', [_vm._v("Call Sign")]), _c('th', [_vm._v("-")])]), _c('tr', [_c('td', [_vm._v("Flag")]), _c('th', [_vm._v(_vm._s((_vm$maintenanceDetail2 = _vm.maintenanceDetailData) === null || _vm$maintenanceDetail2 === void 0 ? void 0 : _vm$maintenanceDetail2.vehicle.flag))])]), _c('tr', [_c('td', [_vm._v("Year & Building")]), _c('th', [_vm._v(_vm._s((_vm$maintenanceDetail3 = _vm.maintenanceDetailData) === null || _vm$maintenanceDetail3 === void 0 ? void 0 : _vm$maintenanceDetail3.vehicle.yearBuildCountry) + " / " + _vm._s((_vm$maintenanceDetail4 = _vm.maintenanceDetailData) === null || _vm$maintenanceDetail4 === void 0 ? void 0 : _vm$maintenanceDetail4.vehicle.flag))])])])])])])])]), _c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "md": "6"
    }
  }, [_c('CustomNav', {
    staticClass: "mb-3",
    attrs: {
      "items": _vm.navItems2
    },
    on: {
      "item-click": function itemClick(val) {
        return _vm.viewMenu2 = val;
      }
    }
  }), _c('b-row', [_vm.viewMenu2 === 'mp' ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [['ON_MAINTENANCE', 'ON_POSTPONE', 'ON_CONVERT'].includes(_vm.stateAction) && !_vm.isEdit ? [_vm.stateAction === 'ON_MAINTENANCE' ? _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Maintenance Date *"
    }
  }, [_c('date-picker', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "value-type": "DD-MM-YYYY",
      "format": "DD MMM YYYY",
      "type": "date",
      "placeholder": "Select date"
    },
    model: {
      value: _vm.formMaintenance.maintenanceDate,
      callback: function callback($$v) {
        _vm.$set(_vm.formMaintenance, "maintenanceDate", $$v);
      },
      expression: "formMaintenance.maintenanceDate"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Cost"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formMaintenance.cost,
      expression: "formMaintenance.cost"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number",
      "placeholder": "0"
    },
    domProps: {
      "value": _vm.formMaintenance.cost
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formMaintenance, "cost", $event.target.value);
      }
    }
  }), _c('div', {
    staticClass: "text-right"
  }, [_vm._v(" Rp. " + _vm._s(_vm.numberFormat(_vm.formMaintenance.cost)) + " ")])])], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "File Maintenance"
    }
  }, [_c('b-form-file', {
    attrs: {
      "placeholder": "Choose a file",
      "name": "select-file-maintenance"
    },
    on: {
      "change": _vm.uploadFile
    }
  }), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v(" File Type * : "), _c('b-badge', {
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v("2 MB / File")])], 1)], 1)], 1), _vm.maintenanceDetailData.trigger === 'COUNTER' ? _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Actual Running Hours *"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formMaintenance.maintenanceRunningHours,
      expression: "formMaintenance.maintenanceRunningHours"
    }],
    staticClass: "form-control",
    attrs: {
      "placeholder": "0",
      "type": "number"
    },
    domProps: {
      "value": _vm.formMaintenance.maintenanceRunningHours
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formMaintenance, "maintenanceRunningHours", $event.target.value);
      }
    }
  })])], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Notes *"
    }
  }, [_c('wysiwyg', {
    model: {
      value: _vm.formMaintenance.notes,
      callback: function callback($$v) {
        _vm.$set(_vm.formMaintenance, "notes", $$v);
      },
      expression: "formMaintenance.notes"
    }
  }), _c('small', [_vm._v("Sign * : "), _c('strong', [_vm._v("Mandatory (Must Fill)")])])], 1)], 1), _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "md": "6"
    }
  }, [_c('button', {
    staticClass: "btn btn-outline-primary w-100",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.onAction('VIEW_DETAIL');
      }
    }
  }, [_vm._v("Cancel")])]), _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "md": "6"
    }
  }, [_c('button', {
    staticClass: "btn btn-primary w-100",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.saveMaintenance();
      }
    }
  }, [_vm._v("Submit")])])], 1) : _vm.stateAction === 'ON_POSTPONE' ? _c('b-row', [_vm.maintenanceDetailData.trigger === 'COUNTER' ? _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Extra Hours *"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formPostpone.extraHours,
      expression: "formPostpone.extraHours"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.formPostpone.extraHours
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formPostpone, "extraHours", $event.target.value);
      }
    }
  })])], 1) : _vm._e(), _vm.maintenanceDetailData.trigger === 'CALENDAR' ? _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Extra Date *"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formPostpone.extraDate,
      expression: "formPostpone.extraDate"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "date"
    },
    domProps: {
      "value": _vm.formPostpone.extraDate
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.formPostpone, "extraDate", $event.target.value);
      }
    }
  })])], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Reason *"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "rows": "3",
      "placeholder": "Type Reason"
    },
    model: {
      value: _vm.formPostpone.reason,
      callback: function callback($$v) {
        _vm.$set(_vm.formPostpone, "reason", $$v);
      },
      expression: "formPostpone.reason"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "md": "6"
    }
  }, [_c('button', {
    staticClass: "btn btn-outline-primary w-100",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.onAction('VIEW_DETAIL');
      }
    }
  }, [_vm._v("Cancel")])]), _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "md": "6"
    }
  }, [_c('button', {
    staticClass: "btn btn-primary w-100",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.savePostpone();
      }
    }
  }, [_vm._v("Submit")])])], 1) : _vm.stateAction === 'ON_CONVERT' ? _c('b-row') : _vm._e()] : _vm._e(), ['REVIEW', 'APPROVE', 'FINISH'].includes(_vm.maintenanceDetailData.status) ? [_c('table', {
    staticClass: "table table-borderless"
  }, [_c('tbody', [_c('tr', [_c('th', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v("Last Maintenance Date")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "65%"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.lastMaintenanceDate))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v("Current Maintenance Date")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _vm.isEdit ? _c('td', {
    attrs: {
      "width": "65%"
    }
  }, [_c('date-picker', {
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "value-type": "DD-MM-YYYY",
      "format": "DD MMM YYYY",
      "type": "date",
      "placeholder": "Select date"
    },
    model: {
      value: _vm.formEdit.maintenanceDate,
      callback: function callback($$v) {
        _vm.$set(_vm.formEdit, "maintenanceDate", $$v);
      },
      expression: "formEdit.maintenanceDate"
    }
  })], 1) : _c('td', {
    attrs: {
      "width": "65%"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.maintenanceDate))])]), _vm.maintenanceDetailData.trigger === 'COUNTER' ? _c('tr', [_c('th', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v("Maintenance Running Hours")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _vm.isEdit ? _c('td', {
    attrs: {
      "width": "65%"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "placeholder": "0"
    },
    model: {
      value: _vm.formEdit.maintenanceRunningHours,
      callback: function callback($$v) {
        _vm.$set(_vm.formEdit, "maintenanceRunningHours", $$v);
      },
      expression: "formEdit.maintenanceRunningHours"
    }
  })], 1) : _c('td', {
    attrs: {
      "width": "65%"
    }
  }, [_vm.maintenanceDetailData.trigger === 'COUNTER' ? _c('p', [_vm._v(_vm._s(_vm.maintenanceDetailData.lastMaintenanceHours) + " HOURS")]) : _vm._e()])]) : _vm._e(), _c('tr', [_c('th', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v("Attachment")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _vm.isEdit ? _c('td', {
    attrs: {
      "width": "65%"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('b-form-file', {
    attrs: {
      "placeholder": "Choose a file",
      "name": "select-file-maintenance",
      "accept": "application/*"
    },
    on: {
      "change": _vm.uploadFile
    }
  }), _vm.formEdit.fileName ? _c('span', {
    staticClass: "text-primary"
  }, [_vm._v("New File: "), _c('i', {
    staticClass: "fa fa-file"
  }), _vm._v(" " + _vm._s(_vm.formEdit.fileName))]) : _vm._e(), (_vm$maintenanceDetail5 = _vm.maintenanceDetailData) !== null && _vm$maintenanceDetail5 !== void 0 && (_vm$maintenanceDetail6 = _vm$maintenanceDetail5.file) !== null && _vm$maintenanceDetail6 !== void 0 && _vm$maintenanceDetail6.fileName ? _c('span', {
    staticClass: "text-secondary",
    on: {
      "click": function click($event) {
        var _vm$maintenanceDetail7, _vm$maintenanceDetail8;
        return _vm.handleOpenFilePreview((_vm$maintenanceDetail7 = _vm.maintenanceDetailData) === null || _vm$maintenanceDetail7 === void 0 ? void 0 : (_vm$maintenanceDetail8 = _vm$maintenanceDetail7.file) === null || _vm$maintenanceDetail8 === void 0 ? void 0 : _vm$maintenanceDetail8.url);
      }
    }
  }, [_vm._v("Existed File: "), _c('i', {
    staticClass: "fa fa-file"
  }), _vm._v(" " + _vm._s((_vm$maintenanceDetail9 = _vm.maintenanceDetailData) === null || _vm$maintenanceDetail9 === void 0 ? void 0 : (_vm$maintenanceDetail10 = _vm$maintenanceDetail9.file) === null || _vm$maintenanceDetail10 === void 0 ? void 0 : _vm$maintenanceDetail10.fileName))]) : _vm._e()], 1)]) : _c('td', {
    attrs: {
      "width": "65%"
    }
  }, [_c('span', {
    staticClass: "text-primary",
    on: {
      "click": function click($event) {
        var _vm$maintenanceDetail11, _vm$maintenanceDetail12;
        return _vm.handleOpenFilePreview((_vm$maintenanceDetail11 = _vm.maintenanceDetailData) === null || _vm$maintenanceDetail11 === void 0 ? void 0 : (_vm$maintenanceDetail12 = _vm$maintenanceDetail11.file) === null || _vm$maintenanceDetail12 === void 0 ? void 0 : _vm$maintenanceDetail12.url);
      }
    }
  }, [_vm._v(_vm._s((_vm$maintenanceDetail13 = _vm.maintenanceDetailData) === null || _vm$maintenanceDetail13 === void 0 ? void 0 : (_vm$maintenanceDetail14 = _vm$maintenanceDetail13.file) === null || _vm$maintenanceDetail14 === void 0 ? void 0 : _vm$maintenanceDetail14.fileName))])])]), _c('tr', [_c('th', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v("Cost")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _vm.isEdit ? _c('td', {
    attrs: {
      "width": "65%"
    }
  }, [_c('b-input-group', {
    attrs: {
      "prepend": "Rp"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "placeholder": "0"
    },
    model: {
      value: _vm.formEdit.cost,
      callback: function callback($$v) {
        _vm.$set(_vm.formEdit, "cost", $$v);
      },
      expression: "formEdit.cost"
    }
  })], 1), _c('div', {
    staticClass: "text-right"
  }, [_vm._v(" Rp. " + _vm._s(_vm.numberFormat(_vm.formEdit.cost)) + " ")])], 1) : _c('td', {
    attrs: {
      "width": "65%"
    }
  }, [_vm._v("Rp." + _vm._s(_vm.numberFormat(_vm.maintenanceDetailData.cost)))])]), _c('tr', [_c('th', {
    staticClass: "align-top"
  }, [_vm._v("Current Notes")]), _c('td', {
    staticClass: "text-center align-top"
  }, [_vm._v(":")]), _vm.isEdit ? _c('td', {
    attrs: {
      "colspan": "4"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "placeholder": "Type Text"
    },
    model: {
      value: _vm.formEdit.notes,
      callback: function callback($$v) {
        _vm.$set(_vm.formEdit, "notes", $$v);
      },
      expression: "formEdit.notes"
    }
  })], 1) : _c('td', {
    attrs: {
      "colspan": "4"
    }
  }, [_vm.maintenanceDetailData.status === 'APPROVE' ? _c('span', [_vm._v(_vm._s(_vm.maintenanceDetailData.finishNotes))]) : _c('span', [_vm._v(_vm._s(_vm.maintenanceDetailData.notes))])])])])]), _vm.isEdit ? _c('b-row', [_c('b-col', {
    staticClass: "text-center",
    attrs: {
      "md": "6"
    }
  }, [_c('button', {
    staticClass: "btn btn-outline-primary w-100",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.onAction('VIEW_DETAIL');
      }
    }
  }, [_vm._v("Cancel")])]), _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "md": "6"
    }
  }, [_c('button', {
    staticClass: "btn btn-primary w-100",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.editDoMaintenance();
      }
    }
  }, [_vm._v("Save Update")])])], 1) : _vm._e()] : _vm._e()], 2) : _vm._e(), _vm.viewMenu2 === 'lh' ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "overflow-auto d-flex w-100",
    staticStyle: {
      "max-height": "400px"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "p-3 px-3"
  }, _vm._l((_vm$maintenanceDetail15 = _vm.maintenanceDetailData) === null || _vm$maintenanceDetail15 === void 0 ? void 0 : _vm$maintenanceDetail15.logs, function (state, index) {
    var _vm$maintenanceDetail16, _vm$maintenanceDetail17, _vm$maintenanceDetail18, _vm$maintenanceDetail19;
    return _c('div', {
      key: index,
      staticClass: "log-history px-3",
      class: {
        'border-l-blue': index + 1 !== ((_vm$maintenanceDetail16 = _vm.maintenanceDetailData) === null || _vm$maintenanceDetail16 === void 0 ? void 0 : (_vm$maintenanceDetail17 = _vm$maintenanceDetail16.logs) === null || _vm$maintenanceDetail17 === void 0 ? void 0 : _vm$maintenanceDetail17.length)
      }
    }, [_c('div', {
      staticClass: "circle-dot",
      class: index + 1 == ((_vm$maintenanceDetail18 = _vm.maintenanceDetailData) === null || _vm$maintenanceDetail18 === void 0 ? void 0 : (_vm$maintenanceDetail19 = _vm$maintenanceDetail18.logs) === null || _vm$maintenanceDetail19 === void 0 ? void 0 : _vm$maintenanceDetail19.length) ? 'left-8' : 'left-10'
    }, [_c('div', {
      staticClass: "dot"
    })]), _c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('strong', {
      staticClass: "mr-2"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.createdBy))]), _c('span', {
      staticClass: "text-primary"
    }, [_c('i', {
      staticClass: "fa fa-calendar"
    }), _vm._v(" " + _vm._s(_vm.moment(state.createdAt, 'DD-MM-YYYY').format('DD MMMM YYYY')))]), _c('b-badge', {
      attrs: {
        "variant": "warning"
      }
    }, [_vm._v(_vm._s(state.status))]), _c('div', {
      staticClass: "mb-4"
    })], 1)]);
  }), 0)])], 1)], 1)]) : _vm._e(), _vm.viewMenu2 === 'com' ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Notes *"
    }
  }, [_c('wysiwyg', {
    model: {
      value: _vm.formComment.comment,
      callback: function callback($$v) {
        _vm.$set(_vm.formComment, "comment", $$v);
      },
      expression: "formComment.comment"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Attachment"
    }
  }, [_c('b-form-file', {
    attrs: {
      "placeholder": "Choose a file",
      "disabled": ""
    }
  }), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v(" File Type * : "), _c('b-badge', {
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
    attrs: {
      "variant": "warning"
    }
  }, [_vm._v("2 MB / File")])], 1)], 1)], 1), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "6"
    }
  }, [_c('button', {
    staticClass: "btn btn-outline-primary w-100",
    attrs: {
      "disabled": !_vm.formComment.comment,
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.formComment.comment = '';
      }
    }
  }, [_vm._v("Cancel")])]), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "6"
    }
  }, [_c('button', {
    staticClass: "btn btn-primary w-100",
    attrs: {
      "disabled": !_vm.formComment.comment,
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.saveComment();
      }
    }
  }, [_vm._v("Submit Comment")])])], 1), _c('div', {
    staticClass: "overflow-auto d-flex w-100",
    staticStyle: {
      "max-height": "400px"
    }
  }, [_vm.maintenanceDetailData.comments.length > 0 ? _c('b-row', _vm._l(_vm.maintenanceDetailData.comments, function (comment, commentIndex) {
    return _c('b-col', {
      key: commentIndex,
      staticClass: "mb-4",
      attrs: {
        "md": "12"
      }
    }, [_c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('span', {
      staticClass: "text-primary"
    }, [_c('i', {
      staticClass: "fa fa-calendar"
    }), _vm._v(" " + _vm._s(_vm.moment(comment.createdAt).format('DD MMMM YYYY HH:mm')))]), _c('strong', {
      staticClass: "mr-2"
    }, [_vm._v(_vm._s(comment.name))]), _c('span', [_vm._v(_vm._s(comment.comment))])]), _c('div', {
      staticClass: "border my-2 w-100"
    })]);
  }), 1) : _c('span', {
    staticClass: "text-muted text-center"
  }, [_vm._v("Tidak ada komentar.")])], 1)], 1) : _vm._e()], 1)], 1), _c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "md": "6"
    }
  }, [_c('CustomNav', {
    staticClass: "mb-3",
    attrs: {
      "items": _vm.navItems
    },
    on: {
      "item-click": function itemClick(val) {
        return _vm.viewMenu1 = val;
      }
    }
  }), _c('b-row', [_vm.viewMenu1 === 'rh' ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-truncate",
    staticStyle: {
      "background-color": "#099bab",
      "color": "white"
    }
  }, [_vm._v("Engine Name")]), _c('th', {
    staticClass: "text-truncate",
    staticStyle: {
      "background-color": "#099bab",
      "color": "white"
    }
  }, [_vm._v("Engine Branch")]), _c('th', {
    staticClass: "text-truncate",
    staticStyle: {
      "background-color": "#099bab",
      "color": "white"
    }
  }, [_vm._v("Last RH Date")]), _c('th', {
    staticClass: "text-truncate",
    staticStyle: {
      "background-color": "#099bab",
      "color": "white"
    }
  }, [_vm._v("Last RH")]), _c('th', {
    staticClass: "text-truncate",
    staticStyle: {
      "background-color": "#099bab",
      "color": "white"
    }
  }, [_vm._v("Total RH")])])]), _c('tbody', _vm._l(_vm.maintenanceDetailData.vehicle.powers, function (engine, engineIndex) {
    return _c('tr', {
      key: engineIndex
    }, [_c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(engine.powerType.name))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(engine.powerSeries.name) + " " + _vm._s(engine.powerSeries.fuel))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v("-")]), _c('th', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s(engine.actualRunningHours) + " Hours")]), _c('th', {
      staticClass: "text-truncate"
    }, [_vm._v("-")])]);
  }), 0)])]) : _vm._e(), _vm.viewMenu1 === 'mi' ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table table-borderless"
  }, [_c('tbody', [_c('tr', [_c('th', {
    attrs: {
      "width": "18%"
    }
  }, [_vm._v("Job Code")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.jobCode))]), _c('th', {
    attrs: {
      "width": "18%"
    }
  }, [_vm._v("Job Class")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s("".concat(_vm.maintenanceDetailData.jobClass.jobClassCode, " - ").concat(_vm.maintenanceDetailData.jobClass.jobClass)))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "18%"
    }
  }, [_vm._v("Job Type")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.jobType.jobTypeCode) + " " + _vm._s(_vm.maintenanceDetailData.jobType.description))]), _c('th', {
    attrs: {
      "width": "18%"
    }
  }, [_vm._v("Job Name")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.jobName))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "18%"
    }
  }, [_vm._v("Type - Serial No")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.maintenanceMaster.equipmentId) + " - " + _vm._s(_vm.maintenanceDetailData.maintenanceMaster.equipmentName))]), _c('th', {
    attrs: {
      "width": "18%"
    }
  }, [_vm._v("Interval/Frequency")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _vm.maintenanceDetailData.trigger === 'CALENDAR' ? _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.interval) + " MONTH")]) : _vm._e(), _vm.maintenanceDetailData.trigger === 'COUNTER' ? _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.interval) + " HOURS")]) : _vm._e()]), _c('tr', [_c('th', {
    attrs: {
      "width": "18%"
    }
  }, [_vm._v("Trigger")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _vm.maintenanceDetailData.trigger === 'CALENDAR' ? _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v("CALENDAR")]) : _vm.maintenanceDetailData.trigger === 'COUNTER' ? _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v("COUNTER")]) : _vm._e(), _c('th', {
    attrs: {
      "width": "18%"
    }
  }, [_vm._v("Status")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('b-badge', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.status))])], 1)]), _vm.maintenanceDetailData.trigger === 'CALENDAR' ? _c('tr', [_c('th', {
    attrs: {
      "width": "18%"
    }
  }, [_vm._v("Last Maintenance")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.lastMaintenanceDate))]), _c('th', {
    attrs: {
      "width": "18%"
    }
  }, [_vm._v(_vm._s(((_vm$maintenanceDetail20 = _vm.maintenanceDetailData) === null || _vm$maintenanceDetail20 === void 0 ? void 0 : _vm$maintenanceDetail20.status) === 'FINISH' ? 'Finish Maintenance' : 'Possible Maintenance'))]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.possibleMaintenanceDate))])]) : _vm._e(), _c('tr', [_c('th', {
    attrs: {
      "width": "18%"
    }
  }, [_vm._v("Last Cost")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s((_vm$maintenanceDetail21 = _vm.maintenanceDetailData.cost) === null || _vm$maintenanceDetail21 === void 0 ? void 0 : _vm$maintenanceDetail21.toLocaleString()))]), _c('th', {
    attrs: {
      "width": "18%"
    }
  }, [_vm._v("Attachment")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm.maintenanceDetailData.file ? _c('ul', {
    staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
  }, [_c('li', {
    staticClass: "col-md-1 col-1 pb-1",
    on: {
      "click": function click($event) {
        return _vm.handleOpenFilePreview(_vm.maintenanceDetailData.file);
      }
    }
  }, [_c('img', {
    staticClass: "img-fluid",
    attrs: {
      "src": require("../../assets/images/activity/pdf.png"),
      "alt": "gallary-image"
    }
  })])]) : _c('span', {
    staticClass: "text-muted"
  }, [_vm._v("No File Exist..")])])]), _c('tr', [_c('th', {
    staticClass: "align-top"
  }, [_vm._v("Job Description")]), _c('td', {
    staticClass: "text-center align-top"
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "colspan": "4"
    }
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.maintenanceDetailData.jobDesc)
    }
  })])]), _c('tr', [_c('th', {
    staticClass: "align-top"
  }, [_vm._v("Last Notes")]), _c('td', {
    staticClass: "text-center align-top"
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "colspan": "4"
    }
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.maintenanceDetailData.notes)
    }
  }, [_vm._v(_vm._s(_vm.maintenanceDetailData.notes || '-') + "\">")])])])])])]) : _vm._e(), _vm.viewMenu1 === 'ei' ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table table-borderless"
  }, [_c('tbody', [_c('tr', [_c('th', {
    attrs: {
      "width": "23%"
    }
  }, [_vm._v("Equipment Name")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s((_vm$maintenanceDetail22 = (_vm$maintenanceDetail23 = _vm.maintenanceDetailData.possibleMaintenanceHours) === null || _vm$maintenanceDetail23 === void 0 ? void 0 : (_vm$maintenanceDetail24 = _vm$maintenanceDetail23.componentSetup) === null || _vm$maintenanceDetail24 === void 0 ? void 0 : _vm$maintenanceDetail24.typeNo) !== null && _vm$maintenanceDetail22 !== void 0 ? _vm$maintenanceDetail22 : '-'))]), _c('th', {
    attrs: {
      "width": "23%"
    }
  }, [_vm._v("Type Number")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s((_vm$maintenanceDetail25 = (_vm$maintenanceDetail26 = _vm.maintenanceDetailData.possibleMaintenanceHours) === null || _vm$maintenanceDetail26 === void 0 ? void 0 : (_vm$maintenanceDetail27 = _vm$maintenanceDetail26.componentSetup) === null || _vm$maintenanceDetail27 === void 0 ? void 0 : _vm$maintenanceDetail27.typeNo) !== null && _vm$maintenanceDetail25 !== void 0 ? _vm$maintenanceDetail25 : '-'))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "23%"
    }
  }, [_vm._v("Serial Number")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s((_vm$maintenanceDetail28 = (_vm$maintenanceDetail29 = _vm.maintenanceDetailData.possibleMaintenanceHours) === null || _vm$maintenanceDetail29 === void 0 ? void 0 : (_vm$maintenanceDetail30 = _vm$maintenanceDetail29.componentSetup) === null || _vm$maintenanceDetail30 === void 0 ? void 0 : _vm$maintenanceDetail30.serialNo) !== null && _vm$maintenanceDetail28 !== void 0 ? _vm$maintenanceDetail28 : '-'))]), _c('th', {
    attrs: {
      "width": "23%"
    }
  }, [_vm._v("Maker Number")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s((_vm$maintenanceDetail31 = (_vm$maintenanceDetail32 = _vm.maintenanceDetailData.possibleMaintenanceHours) === null || _vm$maintenanceDetail32 === void 0 ? void 0 : (_vm$maintenanceDetail33 = _vm$maintenanceDetail32.componentSetup) === null || _vm$maintenanceDetail33 === void 0 ? void 0 : _vm$maintenanceDetail33.makerNo) !== null && _vm$maintenanceDetail31 !== void 0 ? _vm$maintenanceDetail31 : '-'))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "23%"
    }
  }, [_vm._v("Maker")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s((_vm$maintenanceDetail34 = (_vm$maintenanceDetail35 = _vm.maintenanceDetailData.possibleMaintenanceHours) === null || _vm$maintenanceDetail35 === void 0 ? void 0 : (_vm$maintenanceDetail36 = _vm$maintenanceDetail35.componentSetup) === null || _vm$maintenanceDetail36 === void 0 ? void 0 : _vm$maintenanceDetail36.maker) !== null && _vm$maintenanceDetail34 !== void 0 ? _vm$maintenanceDetail34 : '-'))]), _c('th', {
    attrs: {
      "width": "23%"
    }
  }, [_vm._v("Drawing Number")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s((_vm$maintenanceDetail37 = (_vm$maintenanceDetail38 = _vm.maintenanceDetailData.possibleMaintenanceHours) === null || _vm$maintenanceDetail38 === void 0 ? void 0 : (_vm$maintenanceDetail39 = _vm$maintenanceDetail38.componentSetup) === null || _vm$maintenanceDetail39 === void 0 ? void 0 : _vm$maintenanceDetail39.drawingNo) !== null && _vm$maintenanceDetail37 !== void 0 ? _vm$maintenanceDetail37 : '-'))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "23%"
    }
  }, [_vm._v("Certificate")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s((_vm$maintenanceDetail40 = _vm.maintenanceDetailData.possibleMaintenanceHours) !== null && _vm$maintenanceDetail40 !== void 0 && (_vm$maintenanceDetail41 = _vm$maintenanceDetail40.componentSetup) !== null && _vm$maintenanceDetail41 !== void 0 && _vm$maintenanceDetail41.haveCertificate ? 'Yes' : 'No'))]), _c('th', {
    attrs: {
      "width": "23%"
    }
  }), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }), _c('td', {
    attrs: {
      "width": "25%"
    }
  })]), _c('tr', [_c('th', {
    attrs: {
      "width": "23%"
    }
  }, [_vm._v("Certificate")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s((_vm$maintenanceDetail42 = _vm.maintenanceDetailData.possibleMaintenanceHours) !== null && _vm$maintenanceDetail42 !== void 0 && (_vm$maintenanceDetail43 = _vm$maintenanceDetail42.componentSetup) !== null && _vm$maintenanceDetail43 !== void 0 && _vm$maintenanceDetail43.haveCertificate ? 'Yes' : 'No'))]), _c('th', {
    attrs: {
      "width": "23%"
    }
  }), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }), _c('td', {
    attrs: {
      "width": "25%"
    }
  })]), _c('tr', [_c('th', {
    attrs: {
      "width": "18%"
    }
  }, [_vm._v("Additional Info")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s((_vm$maintenanceDetail44 = (_vm$maintenanceDetail45 = _vm.maintenanceDetailData.possibleMaintenanceHours) === null || _vm$maintenanceDetail45 === void 0 ? void 0 : (_vm$maintenanceDetail46 = _vm$maintenanceDetail45.componentSetup) === null || _vm$maintenanceDetail46 === void 0 ? void 0 : _vm$maintenanceDetail46.additionalInfo) !== null && _vm$maintenanceDetail44 !== void 0 ? _vm$maintenanceDetail44 : '-'))]), _c('th', {
    attrs: {
      "width": "23%"
    }
  }), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }), _c('td', {
    attrs: {
      "width": "25%"
    }
  })]), _c('tr', [_c('th', {
    attrs: {
      "width": "18%"
    }
  }, [_vm._v("Descriptions")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "25%"
    }
  }, [_vm._v(_vm._s((_vm$maintenanceDetail47 = (_vm$maintenanceDetail48 = _vm.maintenanceDetailData.possibleMaintenanceHours) === null || _vm$maintenanceDetail48 === void 0 ? void 0 : (_vm$maintenanceDetail49 = _vm$maintenanceDetail48.componentSetup) === null || _vm$maintenanceDetail49 === void 0 ? void 0 : _vm$maintenanceDetail49.description) !== null && _vm$maintenanceDetail47 !== void 0 ? _vm$maintenanceDetail47 : '-'))]), _c('th', {
    attrs: {
      "width": "23%"
    }
  }), _c('td', {
    staticClass: "text-center",
    attrs: {
      "width": "2%"
    }
  }), _c('td', {
    attrs: {
      "width": "25%"
    }
  })])])])]) : _vm._e()], 1)], 1)], 1)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }