var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "py-4",
    staticStyle: {
      "background": "rgb(241 245 249)",
      "min-height": "calc(100vh - 60px)"
    }
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('b-row', {}, [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Period",
            "label-for": "Period"
          }
        }, [_c('date-picker', {
          style: {
            width: '100%'
          },
          attrs: {
            "type": "date",
            "range": "",
            "placeholder": "Select date range"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "status"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": ""
          },
          model: {
            value: _vm.params.status,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "status", $$v);
            },
            expression: "params.status"
          }
        }, [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("All")]), _c('b-form-select-option', {
          attrs: {
            "value": "progress"
          }
        }, [_vm._v("Progress")]), _c('b-form-select-option', {
          attrs: {
            "value": "completed"
          }
        }, [_vm._v("Completed")])], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Filter Option",
            "label-for": "cargo"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": ""
          },
          model: {
            value: _vm.filterOptSelected,
            callback: function callback($$v) {
              _vm.filterOptSelected = $$v;
            },
            expression: "filterOptSelected"
          }
        }, [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("All")]), _c('b-form-select-option', {
          attrs: {
            "value": "by-project"
          }
        }, [_vm._v("By Project")]), _c('b-form-select-option', {
          attrs: {
            "value": "by-fleet"
          }
        }, [_vm._v("By Fleet")])], 1)], 1)], 1), _vm.filterOptSelected == 'by-fleet' ? _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Fleet Name",
            "label-for": "fleet-categtory"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "id": "fleet-category",
            "multiple": true,
            "placeholder": "Select Categories",
            "label": "name",
            "options": _vm.fleetNameOpt,
            "track-by": "code"
          },
          model: {
            value: _vm.params.vehicleIds,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "vehicleIds", $$v);
            },
            expression: "params.vehicleIds"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Oops! No elements found. Consider changing the search query.")])])], 1)], 1) : _vm._e(), _vm.filterOptSelected == 'by-project' ? _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Project Name",
            "label-for": "fleet-categtory"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "id": "fleet-category",
            "multiple": true,
            "placeholder": "Select Categories",
            "label": "name",
            "options": _vm.projectNameOpt,
            "track-by": "code"
          },
          model: {
            value: _vm.params.projectIds,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "projectIds", $$v);
            },
            expression: "params.projectIds"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Oops! No elements found. Consider changing the search query.")])])], 1)], 1) : _vm._e(), _c('b-col', {
          staticClass: "d-flex align-items-end",
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          staticClass: "w-100"
        }, [_c('b-button', {
          staticClass: "w-100",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fas fa-search"
        }), _vm._v(" Show Data ")])], 1)], 1)], 1), _c('div', {
          staticClass: "d-flex justify-content-between align-items-center mb-3"
        }, [_c('h5', {
          staticClass: "mb-0"
        }, [_c('strong', [_vm._v("Operational Data Running Shipment")])]), _c('b-button', {
          staticClass: "mx-2 d-flex",
          attrs: {
            "variant": "outline-primary",
            "size": "md"
          }
        }, [_c('i', {
          staticClass: "fa fa-download"
        }), _vm._v(" Export ")])], 1), _c('div', [_c('div', {
          staticClass: "mb-3 p-3",
          staticStyle: {
            "background": "#e5e4e4"
          }
        }, [_c('h5', [_c('strong', [_vm._v("KPC Project - Transhipment")])])]), _c('div', {
          staticClass: "overflow-auto"
        }, [_c('table', {
          staticClass: "table mb-0 table-striped table-hover"
        }, [_c('thead', _vm._l(_vm.columns, function (column, index) {
          return _c('th', {
            key: index,
            staticClass: "text-truncate column-primary"
          }, [_vm._v(_vm._s(column))]);
        }), 0), _c('tbody', {
          staticClass: "bg-white"
        }, _vm._l(4, function (item, index) {
          return _c('tr', {
            key: index
          }, [_c('td', [_vm._v(_vm._s(index))]), _c('td', {
            staticClass: "text-truncate"
          }, [_c('b-badge', {
            attrs: {
              "variant": "warning"
            }
          }, [_vm._v("TB.SML 301")]), _c('b-badge', {
            staticClass: "ml-2",
            attrs: {
              "variant": "primary"
            }
          }, [_vm._v("BG. SML 104")])], 1), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v("Kintap")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v("Asam-Asam")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v("FC SAMARINDA FIDELITY & AKM 3")]), _c('td', {
            staticClass: "text-truncate bg-primary"
          }, [_vm._v("12.034.555")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v("Completed")]), _c('td', {
            staticClass: "text-truncate"
          }, [_c('b-button', {
            attrs: {
              "variant": "primary"
            },
            on: {
              "click": function click($event) {
                _vm.modal.freightRate = true;
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-plus"
          }), _vm._v(" Input Freight ")])], 1), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v("26/06/2024 22.30.00")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v("26/06/2024 22.30.00")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v("26/06/2024 22.30.00")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v("26/06/2024 22.30.00")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v("26/06/2024 22.30.00")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v("26/06/2024 22.30.00")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v("26/06/2024 22.30.00")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v("26/06/2024 22.30.00")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v("26/06/2024 22.30.00")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v("26/06/2024 22.30.00")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v("26/06/2024 22.30.00")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v("26/06/2024 22.30.00")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v("26/06/2024 22.30.00")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v("26/06/2024 22.30.00")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v("26/06/2024 22.30.00")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v("26/06/2024 22.30.00")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v("26/06/2024 22.30.00")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v("26/06/2024 22.30.00")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v("26/06/2024 22.30.00")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v("26/06/2024 22.30.00")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v("26/06/2024 22.30.00")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v("26/06/2024 22.30.00")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v("26/06/2024 22.30.00")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v("26/06/2024 22.30.00")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v("27/06/2024 18.15.00")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v("0,50")]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v("27/06/2024 18.45.00")])]);
        }), 0)])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "title": "Input Freight Rate",
      "centered": "",
      "size": "lg",
      "ok-title": "Submit",
      "cancel-title": "Close",
      "title-class": "title-modal"
    },
    model: {
      value: _vm.modal.freightRate,
      callback: function callback($$v) {
        _vm.$set(_vm.modal, "freightRate", $$v);
      },
      expression: "modal.freightRate"
    }
  }, [_c('FormFreightRate')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }