<template>
    <b-container fluid>
      <form action="#" @submit.prevent="onSave" v-if="!detail_loading">
        <b-row v-if="detail_loading" class="p-2">
          <b-col md="12">
            <div class="text-center my-5">
                <b-spinner type="grow" label="Spinning"></b-spinner>
            </div>
          </b-col>
        </b-row>
        <b-row v-else class="p-2">
          <b-col md="12">
            <b-row class="mt-2">
              <b-col lg="12" class="text-right" v-if="['PREVIEW'].includes(action)">
                <b-button size="sm" variant="primary" @click="changeAction('EDIT')"><i class="fa fa-pen" /> EDIT SPAL</b-button>
              </b-col>
              <b-col lg="12" class="text-right" v-if="['EDIT'].includes(action)">
                <b-button size="sm" variant="danger" @click="changeAction('PREVIEW')"><i class="fa fa-ban" /> CANCEL</b-button>
              </b-col>
              <b-col lg="6">
                <b-form-group
                  label="NAME OF TEMPLATE"
                >
                  <b-form-input v-if="['ADD', 'EDIT'].includes(action)" placeholder="Type Text" v-model="form.name" />
                  <span v-else class="text-muted">{{ form.name }}</span>
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group label="CONTRACT TYPE">
                  <b-form-select v-if="['ADD', 'EDIT'].includes(action)" plain size="sm" placeholder="Select Options..." :options="optionsContractType" v-model="form.contract_type_id">
                    <template v-slot:first>
                      <b-form-select-option :value="null">Select Options</b-form-select-option>
                    </template>
                  </b-form-select>
                  <span v-else class="text-muted">{{ detailSpal?.contract_type?.name }}</span>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="my-2">
              <b-col lg="6">
                <b-form-group label="LANGUAGE"class="mb-0">
                  <v-multiselect v-if="['ADD', 'EDIT'].includes(action)"
                    :options="[{
                      text:'INDONESIA',
                      value:'IND'
                    },{
                      text:'ENGLISH',
                      value:'ENG'
                    },{
                      text:'MANDARIN',
                      value:'MDN'
                    }]"
                    :multiple="true"
                    group-label="text"
                    :group-select="true"
                    placeholder=""
                    track-by="value"
                    label="text"
                    placeholder="Select languange"
                    v-model="form.language"
                  >
                    <span slot="noResult">Oops! No elements found. Consider changing the searchquery.</span>
                  </v-multiselect>
                  <div class="d-flex flex-wrap" v-else>
                    <b-badge v-for="(state, index) in form.language" :key="index" class="border border-primary text-primary text-uppercase mb-2 mr-2" variant="none">{{ state?.text }}</b-badge>
                  </div>
                </b-form-group>
              </b-col> 
            </b-row>
            <b-row>
              <b-col lg="12">
                <div class="border d-flex my-2 w-100" />
              </b-col>
            </b-row>

            <!-- WYSIWYG EDITOR -->
            <b-row>
              <b-col :lg="['ADD', 'EDIT'].includes(action) ? '8' : '12'">
                <!-- <b-form-group label="LOGO">
                  <b-form-file type="file" @change="handleFileUpload" />
                </b-form-group> -->
                
                <b-form-group label="CONTENT">
                  <wysiwyg v-model="form.content" :height="'400px'" disabled />
                </b-form-group>
              </b-col>

              <b-col lg="4" v-if="['ADD', 'EDIT'].includes(action)">
                <span>LIST OF VARIABLES</span>
                <b-row class="mb-2">
                  <b-col lg="12">
                    <div class="alert alert-primary">
                      SHIPMENT PLANNING
                    </div>
                  </b-col>
                  <b-col lg="12" >
                    <div class="d-flex flex-wrap">
                      <b-badge v-for="(state, index) in listVariables.shipmentPlanning" :key="index" 
                        :class="`border border-primary ${state.selected ? '' : 'text-primary'} text-uppercase mr-2 mb-2`" 
                        style="cursor:pointer;" :variant="state.selected ? 'primary' : 'none'" @click="onHandleVariables(state)"
                      >{{ state?.label }}</b-badge>
                    </div>
                  </b-col>
                </b-row>

                <b-row class="mb-2">
                  <b-col lg="12">
                    <div class="alert alert-primary">
                      CUSTOMER INFO
                    </div>
                  </b-col>
                  <b-col lg="12" >
                    <div class="d-flex flex-wrap">
                      <b-badge v-for="(state, index) in listVariables.customer" :key="index" 
                        :class="`border border-primary ${state.selected ? '' : 'text-primary'} text-uppercase mr-2 mb-2`" 
                        style="cursor:pointer;" :variant="state.selected ? 'primary' : 'none'" @click="onHandleVariables(state)"
                      >{{ state?.label }}</b-badge>
                    </div>
                  </b-col>
                </b-row>

                <b-row class="mb-2">
                  <b-col lg="12">
                    <div class="alert alert-primary">
                      OTHERS
                    </div>
                  </b-col>
                  <b-col lg="12" >
                    <div class="d-flex flex-wrap">
                      <b-badge v-for="(state, index) in listVariables.others" :key="index" 
                        :class="`border border-primary ${state.selected ? '' : 'text-primary'} text-uppercase mr-2 mb-2`" 
                        style="cursor:pointer;" :variant="state.selected ? 'primary' : 'none'" @click="onHandleVariables(state)"
                      >{{ state?.label }}</b-badge>
                    </div>
                  </b-col>
                </b-row>
               
              </b-col>
            </b-row>
          </b-col>
  
          <b-col lg="12">
            <div class="border w-100 d-flex my-4"></div>
          </b-col>
  
          <b-col md="12" v-if="['ADD', 'EDIT'].includes(action)">
            <div class="d-flex align-items-center justify-content-end w-100">
              <b-button
                block
                variant="danger"
                type="button"
                style="width:15%"
                class="mr-4 mt-2"
              >
                <i class="fa fa-remove"></i>
                <span>Cancel</span>
              </b-button>
  
              <b-button
                :disabled="saving"
                block
                variant="primary"
                type="submit"
                style="width:20%"
              >
              <span
                v-if="saving"
                class="spinner-border spinner-border-sm"
              />
              <div v-else>
                <i class="fa fa-save"></i>
                <span>Submit Data</span>
              </div>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </form>
      <b-row v-else>
        <b-col lg="12">
          <div class="alert alert-info m-4">
            Loading...
          </div>
        </b-col>
      </b-row>
    </b-container>
  </template>
    
    <script>
    import { busdevActions, fleetsActions, crewingActions } from '@src/Utils/helper'
    import _ from 'lodash'
    
    export default {
      name: 'SlideFormSpal',
      props:{
        optionsContractType:{
          type: Array,
          default: []
        },
        refreshData: {
          type: Function,
          default: true
        },
        action: {
          type: String,
          default: ''
        },
        editId: {
          type: Number,
          default: null
        }
      },
      components:{},
      data () {
        return {
          detail_loading:false,
          saving:false,

          detailSpal:null,

          listVariables: {
            shipmentPlanning:[
              {label:'Collection of Forms', value:'collection_of_forms', selected:false},
              {label:'Periode Start', value:'periode_start', selected:false},
              {label:'Periode End', value:'periode_end', selected:false},
              {label:'Fleet', value:'fleet', selected:false},
              {label:'Contract Type', value:'contract_type', selected:false},
              {label:'Cargo Type', value:'cargo_type', selected:false},
              {label:'Cargo Type', value:'cargo_type', selected:false},
              {label:'Terms', value:'terms', selected:false},
              {label:'Total Volume', value:'total_volume', selected:false},
              {label:'POL', value:'pol', selected:false},
              {label:'POD', value:'pod', selected:false},
              {label:'Laycan', value:'laycan', selected:false},
              {label:'Distance', value:'distance', selected:false},
              {label:'Duration', value:'duration', selected:false},
              {label:'Freight Rate', value:'freight_rate', selected:false},
            ],
            customer:[
              {label:'Customer Name', value:'customer_name', selected:false},
              {label:'Address', value:'address', selected:false},
              {label:'PIC', value:'pic', selected:false},
              {label:'Email', value:'email', selected:false},
            ],
            others:[
              {label:'Signature', value:'signature', selected:false},
              {label:'Logo', value:'logo', selected:false},
              {label:'QR Code', value:'qr_code', selected:false},
            ]
          },

          form:{
            content:'<div style="text-align: center; color:gray;">&lt;&lt;CONTENT TITLE&gt;&gt;</div><div style="text-align: left;"><br><br></div>',
            language:[],
            name:'',
            contract_type_id:null,
          }
        }
      },
      watch:{ 
        editId: {
          deep: true,
          handler: function (value) {
            if (value) {
              this.getSpalById(value)
            }
          },
        },
        action: {
          deep: true,
          handler: function (value) {
            if (value === "ADD") {
              this.resetForm()
            }
          },
        }
      },
      async mounted () {
      },
      methods: {
        ...busdevActions,
        ...fleetsActions,
        changeAction(val) {
          // Emit an event to notify the parent component
          console.log(val, "WHAT ACTION IS THIS")
          this.$emit('updateAction', val);
        },
        onHandleVariables(state){
          state.selected = !state.selected

          if(state.selected){
            this.form.content += `<span style="color:gray;">&lt;&lt;${state?.label}&gt;&gt;</span>`
          } else {
            this.form.content = this.form.content.replace(`<span style="color:gray;">&lt;&lt;${state?.label}&gt;&gt;</span>`, '')
          }
        },
        handleFileUpload(event) {
          const fileInput = event.target;
          const file = fileInput.files[0];

          if (file) {
            this.insertAttachment(file);
            
            fileInput.value = '';
          }
        },
        insertAttachment(file) {
          this.form.content = this.form.content.replace('&lt;&lt;LOGO&gt;&gt;', `<img src="${URL.createObjectURL(file)}" alt="Logo" style="width="50px"; height="50px"" />`);
        },
        async getSpalById (id) {
          this.detail_loading = true

          let res = await this.fetchSpalById({id})

          if(res.data.status === true){
            let data = res.data.data
            this.detailSpal = data
            this.form = {
              content:data?.content ? JSON.parse(data.content) : '<div style="text-align: center; color:gray;">&lt;&lt;CONTENT TITLE&gt;&gt;</div><div style="text-align: left;"><br><br></div>',
              language:data?.language ? JSON.parse(data.language) : [],
              name: data?.name ?? '',
              contract_type_id: data?.contract_type_id ?? null,
            }
          }

          this.detail_loading = false
        },
        resetForm(){
          this.form = {
            content:'<div style="text-align: center; color:gray;">&lt;&lt;CONTENT TITLE&gt;&gt;</div><div style="text-align: left;"><br><br></div>',
            language:[],
            name:'',
            contract_type_id:null,
          }
        },
        async onSave(){
          let payload = {
            ...this.form,
          }

          // VALIDATION
          if(payload.language.length <= 0) return this.$swal(`Oops.`, 'Language required, at least 1.', 'warning')

          payload.language = JSON.stringify(payload.language)
          payload.content = JSON.stringify(payload.content)

          let res = null
          if(this.editId){
            res = await this.updateSpal({data: payload, id: Number(this.editId)})
          } else {
            res = await this.createSpal({data: payload})
          }
       
          if(res?.data?.status === true){
            this.saving = false

            this.refreshData()
            this.resetForm()
            this.$swal(`Success!`, `Create Spal Success.`, 'success')
          } else {
            this.saving = false
            this.$swal(`Oops.`, res?.data?.message, 'error')
          }

          this.saving = false
        }
      }
    }
    </script>
    
    <style scoped>
    </style>
    