<template>
  <div class="maintenance-summary-board">
    <!-- Top Section !-->
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">
              <strong>Filter Maintenance Dashboard</strong>
            </h4>
          </template>
          <template v-slot:body>
            <div class="mb-2">
              <AdvancedFilter
                title="Advance Filter - Maintenance Dashboard"
              >
                <template #body>
                  <b-row>
                    <b-col md="3" v-if="tab !== 'TASK_LIST'">
                      <b-form-group label="Date Range:" label-for="Date Range">
                        <date-picker style="width:100%" @change="onChangeDate()" v-model="maintenance.dateRange" format="DD MMM YYYY" type="date" range placeholder="Select date range"></date-picker>
                      </b-form-group>
                    </b-col>
                    <b-col md="6" v-if="!queryVehicleId">
                      <b-form-group label="Fleet/Equipment:" label-for="fleet-equipment">
                        <v-multiselect v-model="maintenance.params.vehicleIds" :disabled="queryVehicleId"  tag-placeholder="Category" placeholder="Search or add a category" label="name" track-by="code" :options="fleetEquipments" :multiple="true" :taggable="true" >
                        </v-multiselect>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group label="Choose your Job Filter" label-for="fleet-equipment">
                        <template v-for="(item,index) in jobOptions">
                          <b-form-radio v-model="maintenance.selectedJob" class="custom-radio-color-checked" inline :color="item.color" name="color" :key="index" :value="item.value" :disabled="item.disabled">{{ item.label }}</b-form-radio>
                        </template>
                      </b-form-group>
                    </b-col>
                    <b-col md="3" v-if="maintenance.selectedJob === 'job_class'">
                      <b-form-group label="Job Class" label-for="fleet-equipment">
                        <v-multiselect v-model="maintenance.params.jobClassIds" tag-placeholder="Category" placeholder="Search or add a job class" label="name" track-by="code" :options="jobClassList" :multiple="true" :taggable="true" >
                        </v-multiselect>
                      </b-form-group>
                    </b-col>
                    <b-col md="3" v-if="maintenance.selectedJob === 'job_type'">
                      <b-form-group label="Job Type" label-for="fleet-equipment">
                        <v-multiselect v-model="maintenance.params.jobTypeIds" tag-placeholder="Category" placeholder="Search or add a job type" label="name" track-by="code" :options="jobTypeList" :multiple="true" :taggable="true" >
                        </v-multiselect>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group label="Job Name/Equipment" label-for="equipment">
                        <b-form-input v-model="maintenance.params.search" type="text" placeholder="search..."></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group label="Status" label-for="status">
                        <b-form-select
                          plain
                          v-model="maintenance.params.status"
                          name="status"
                        >
                          <template v-slot:first>
                            <b-form-select-option :value="''">All</b-form-select-option>
                            <b-form-select-option :value="'ACTIVE'">ACTIVE</b-form-select-option>
                            <b-form-select-option :value="'REVIEW'">REVIEW</b-form-select-option>
                            <b-form-select-option :value="'APPROVE'">APPROVE</b-form-select-option>
                            <b-form-select-option :value="'MAINTENANCE'">MAINTENANCE</b-form-select-option>
                            <b-form-select-option :value="'OVERDUE'">OVERDUE</b-form-select-option>
                            <b-form-select-option :value="'TOWARDS'">TOWARDS</b-form-select-option>
                            <b-form-select-option :value="'FINISH'">FINISH</b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col :md="tab !== 'TASK_LIST' ? '3' : '6'">
                      <button @click="getMaintenanceBoard()" type="button" class="btn w-100"  :style="{ backgroundColor: '#2BDCAA', color: 'white', marginTop:'32px' }" block><span class="mr-2">Filter Data</span> <i class="fa fa-search" /></button>
                    </b-col>
                  </b-row>
                </template>
              </AdvancedFilter>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Top Section !-->
    <!-- Content Section !-->
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="nav-fill mb-3">
                <tab-nav-items class="col-md-6 p-0" :active="true" @click.native="tab = 'TASK_LIST'" href="#task-list" title="Task List"/>
                <tab-nav-items class="col-md-6 p-0" :active="false" @click.native="tab = 'FINISHED'" href="#finished" title="Finished"/>
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="12">
        <div class="tab-content">
          <!-- Task List -->
          <tab-content-item :active="true" id="task-list">
            <div class="row">
              <b-col sm="12">
                <b-row>
                  <b-col md="4">
                    <b-card
                      text-variant="white"
                      bg-variant="blue"
                      class="iq-mb-3"
                    >
                      <b-card-title class="text-white text-center"><h1 class="text-white">{{maintenance.meta.towards}}</h1></b-card-title>
                      <blockquote class="blockquote mb-0 text-center">
                        <p class="font-size-22"><strong>Towards Jobs</strong></p>
                      </blockquote>
                    </b-card>
                  </b-col>
                  <b-col md="4">
                    <b-card
                      text-variant="white"
                      bg-variant="danger"
                      class="iq-mb-3"
                    >
                      <b-card-title class="text-white text-center"><h1 class="text-white">{{maintenance.meta.overdue}}</h1></b-card-title>
                      <blockquote class="blockquote mb-0 text-center">
                        <p class="font-size-22"><strong>Overdue</strong></p>
                      </blockquote>
                    </b-card>
                  </b-col>
                  <b-col md="4">
                    <b-card
                      text-variant="white"
                      bg-variant="warning"
                      class="iq-mb-3"
                    >
                      <b-card-title class="text-white text-center"><h1 class="text-white">{{maintenance.meta.postponed}}</h1></b-card-title>
                      <blockquote class="blockquote mb-0 text-center">
                        <p class="font-size-22"><strong>Postponed</strong></p>
                      </blockquote>
                    </b-card>
                  </b-col>
                  <b-col md="4">
                    <iq-card class-name="iq-card-block iq-card-stretch">
                      <template v-slot:body >
                        <div class="d-flex align-items-center justify-content-between" @click="maintenance.params.status = 'MONTHS3'">
                          <div class="text-left">
                            <h5 class="card-title mb-2 mt-2 text-primary"><strong>Next 3 Month</strong></h5>
                            <h3 class="mb-0 line-height">{{maintenance.meta['3months']}}</h3>
                          </div>
                          <div class="rounded-circle iq-card-icon bg-warning"><i class="fa fa-calendar-week"></i></div>
                        </div>
                      </template>
                    </iq-card>
                  </b-col>
                  <b-col md="4">
                    <iq-card class-name="iq-card-block iq-card-stretch">
                      <template v-slot:body >
                        <div class="d-flex align-items-center justify-content-between" @click="maintenance.params.status = 'MONTHS6'">
                          <div class="text-left">
                            <h5 class="card-title mb-2 mt-2 text-primary"><strong>Next 6 Month</strong></h5>
                            <h3 class="mb-0 line-height">{{maintenance.meta['6months']}}</h3>
                          </div>
                          <div class="rounded-circle iq-card-icon bg-primary"><i class="fa fa-calendar-alt"></i></div>
                        </div>
                      </template>
                    </iq-card>
                  </b-col>
                  <b-col md="4">
                    <iq-card class-name="iq-card-block iq-card-stretch">
                      <template v-slot:body >
                        <div class="d-flex align-items-center justify-content-between" @click="maintenance.params.status = 'MONTHS12'">
                          <div class="text-left">
                            <h5 class="card-title mb-2 mt-2 text-primary"><strong>Next 12 Month</strong></h5>
                            <h3 class="mb-0 line-height">{{maintenance.meta['12months']}}</h3>
                          </div>
                          <div class="rounded-circle iq-card-icon bg-success"><i class="fa fa-calendar"></i></div>
                        </div>
                      </template>
                    </iq-card>
                  </b-col>
                </b-row>
              </b-col>
              <!-- Detail Job -->
              <b-col cols="12">
                <iq-card class-name="iq-card-block iq-card-stretch pt-3 bg-light">
                  <b-row class="mb-2">
                    <b-col cols="4" class="text-center"></b-col>
                    <b-col cols="4" class="text-center">
                      <b-form-group label="Select Your Data:">
                        <v-select class="bg-light text-white" label="text" v-model="maintenance.params.status" :options="statusList" :reduce="statusList => statusList.value" append-to-body :calculate-position="withPopper"></v-select>
                      </b-form-group>
                    </b-col>
                    <b-col cols="4" class="text-center"></b-col>
                  </b-row>
                </iq-card>
              </b-col>
              <b-col sm="12">
                <iq-card class-name="iq-card-block iq-card-stretch">
                  <template v-slot:headerTitle>
                    <h4 class="card-title text-primary"><strong>Task List Data {{queryVehicleId && maintenance.params.vehicleIds[0] ? ' - ' + maintenance.params.vehicleIds[0].name : ''}}</strong> <b-badge variant="danger">{{selectedStatusList.text}}</b-badge></h4>
                  </template>
                  <template v-slot:body>
                    <b-row>
                      <b-col cols="12" class="mt-3 mb-3">
                        <div class="d-flex overflow-auto">
                          <table class="table mb-0 table-striped table-sm">
                            <thead>
                              <tr>
                                <th class="text-center text-truncate">No</th>
                                <th class="text-center text-truncate" style="min-width:250px" v-if="!queryVehicleId">Fleet/Equipment</th>
                                <th class="text-center text-truncate" style="min-width:200px">Job&nbsp;Name&nbsp;-&nbsp;Code</th>
                                <th class="text-center text-truncate">Equipment&nbsp;Name</th>
                                <th class="text-center text-truncate">Frequency</th>
                                <th class="text-center text-truncate">Last&nbsp;Maintenance</th>
                                <th class="text-center text-truncate">Due&nbsp;Date&nbsp;/&nbsp;Hours</th>
                                <th class="text-center text-truncate">Status</th>
                                <th class="text-center text-truncate">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                            <template v-for="(job, index) in maintenance.data">
                              <tr :key="index">
                                <th class="text-center text-truncate">{{ index+1 }}</th>
                                <td class="text-left" v-if="!queryVehicleId"><a href="#" @click.prevent="viewDetailMaintenance(job.id,'VIEW_DETAIL')">{{ job.vehicleName }} - {{ job.equipmentName }}</a></td>
                                <td class="text-left"><a href="#" @click.prevent="viewDetailMaintenance(job.id, 'VIEW_DETAIL')">{{ job.jobName }}</a></td>
                                <td class="text-center text-truncate">{{ job.componentName }}</td>
                                <td class="text-center text-truncate">{{ job.interval }}</td>
                                <td class="text-center text-truncate">{{ job.lastMaintenanceDate ? moment(job.lastMaintenanceDate, "DD-MM-YYYY").format('DD MMM YYYY') : '-' }}</td>
                                <td class="text-center">
                                  <span>{{ job.dueDate }}</span>
                                  <div v-if="job.pendingDate === null || job.pendingDate === '' ||  job.pendingDate === undefined"></div>
                                  <div v-else><small>Last Due #1 : {{job.pendingDate}}</small></div>
                                </td>
                                <td class="text-center">
                                  <template v-if="job.status === 'OVERDUE'">
                                    <b-badge :id="'popover-status'+index" variant="danger">OVERDUE / {{job.statusDetail && job.statusDetail.includes('days') ? generateDayDurations(parseInt(job.statusDetail.replace('days','').trim())).durationString : job.statusDetail}}</b-badge>
                                  </template>
                                  <template v-else-if="job.status === 'TOWARDS'">
                                    <b-badge :id="'popover-status'+index" variant="info">TOWARDS</b-badge>
                                  </template>
                                  <template v-else-if="job.status === 'REVIEW'">
                                    <b-badge :id="'popover-status'+index" variant="warning">REVIEW</b-badge>
                                  </template>
                                  <template v-else-if="job.status === 'APPROVE'">
                                    <b-badge :id="'popover-status'+index" variant="success">APPROVE</b-badge>
                                  </template>
                                  <template v-else-if="job.status === 'POSTPONE'">
                                    <b-badge :id="'popover-status'+index" variant="info">TOWARDS (POSTPONE)</b-badge>
                                  </template>
                                  <template v-else-if="job.status === 'NEW'">
                                    <b-badge :id="'popover-status'+index" variant="primary">ACTIVE</b-badge>
                                  </template>
                                  <template v-else>
                                    <b-badge variant="light">{{job.status}}</b-badge>
                                  </template>
                                </td>
                                <td>
                                  <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                    <template v-slot:button-content>
                                      <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                        <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                      </span>
                                    </template>
                                    <b-dropdown-item size="sm" href="#" v-if="canAdd && ['TOWARDS','OVERDUE','POSTPONE','NEW'].includes(job.status)" @click.prevent="viewDetailMaintenance(job.id, 'ON_MAINTENANCE')" variant="primary"><i class="fa fa-tasks"></i> Maintenance</b-dropdown-item>
                                    <b-dropdown-item size="sm" href="#" v-if="['APPROVE'].includes(job.status) && canApprove" variant="info" @click.prevent="onApprove(job.id)"><i class="fa fa-thumbs-up"></i> Approve</b-dropdown-item>
                                    <b-dropdown-item size="sm" href="#" v-if="['TOWARDS','OVERDUE','NEW'].includes(job.status)" @click.prevent="viewDetailMaintenance(job.id, 'ON_POSTPONE')" variant="warning"><i class="fa fa-clock"></i> Postpone</b-dropdown-item>
                                    <b-dropdown-item size="sm" href="#" v-if="['MAINTENANCE','REVIEW'].includes(job.status) && canReview" @click.prevent="onReview(job.id)" variant="info"><i class="fa fa-search"></i> Review</b-dropdown-item>
                                    <b-dropdown-item size="sm" href="#" v-if="['APPROVE','REVIEW'].includes(job.status)" @click.prevent="viewDetailMaintenance(job.id, 'ON_MAINTENANCE', true)" variant="primary"><i class="fa fa-pen"></i> Edit</b-dropdown-item>
                                    <b-dropdown-item size="sm" href="#" v-if="['TOWARDS','OVERDUE','NEW'].includes(job.status)" variant="danger" @click.prevent="viewDetailMaintenance(job.id, 'ON_CONVERT')"><i class="fa fa-tools"></i> Convert Repair</b-dropdown-item>
                                  </b-dropdown>
                                </td>
                              </tr>
                            </template>
                            </tbody>
                          </table>
                        </div>
                      </b-col>
                    </b-row>
                  
                    <b-row class="mt-4">
                      <b-col col-6>
                        Total: {{ maintenance.meta.total }} &nbsp;
                        | &nbsp; Per page:
                        {{ maintenance.meta.perPage }}
                      </b-col>
                      <b-col col-6>
                        <b-pagination
                          v-model="maintenance.params.page"
                          :total-rows="maintenance.meta.total"
                          :per-page="maintenance.meta.perPage"
                          align="right"
                          @input="getMaintenanceList"
                        ></b-pagination>
                      </b-col>
                    </b-row>
                  </template>
                </iq-card>
              </b-col>
              <!-- Detail Job -->
            </div>
          </tab-content-item>
          <!-- End Task List -->
          <!-- Finished -->
          <tab-content-item :active="false" id="finished">
            <b-row>
              <!-- Total Finished -->
              <b-col md="12" class="p-2">
                <b-card bg-variant="default" class="iq-mb-3">
                  <b-row>
                    <b-col md="2">
                      <b-card-title class="text-center"><h3>{{maintenanceFinished.meta.total}}</h3></b-card-title>
                      <blockquote class="blockquote mb-0 text-center">
                        <p class="font-size-18"><strong>Finished</strong></p>
                      </blockquote>
                    </b-col>
                    <b-col md="3">
                      <b-form-group label-for="dateIssued" label="Date Range">
                        <h5><strong>{{computedDateRangeText}}</strong></h5>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group label-for="dateIssued" label="Fleet / Equipment">
                        <b-badge v-if="maintenance.params.vehicleIds.length === 0" variant="warning" class="mr-1">
                          Semua Fleet
                        </b-badge>
                        <b-badge v-for="(vehicle, index) in maintenance.params.vehicleIds" :key="index" variant="warning" class="mr-1">
                          {{ vehicle.name }}
                        </b-badge>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label-for="dateIssued" label="Job">
                        <b-badge v-if="maintenance.selectedJob === 'job_class'" variant="info">Job Class</b-badge>
                        <b-badge v-else-if="maintenance.selectedJob === 'job_type'" variant="info">Job Type</b-badge>
                        <h6 v-if="maintenance.selectedJob === 'job_class'" ><strong>{{maintenance.params.jobClassIds.map(jobClass => jobClass.name).join(', ')}}</strong></h6>
                        <h6 v-else-if="maintenance.selectedJob === 'job_type'" ><strong>{{maintenance.params.jobTypeIds.map(jobType => jobType.name).join(', ')}}</strong></h6>
                      </b-form-group>
                    </b-col>
                    <b-col md="2">
                      <b-form-group label-for="dateIssued" label="Keyword Search">
                        <h5><strong>{{maintenance.params.search || '-'}}</strong></h5>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <!-- End Total Finished -->
              <!-- List Finished -->
              <b-col sm="12">
                <iq-card class-name="iq-card-block iq-card-stretch">
                  <template v-slot:body >
                    <div class="d-flex overflow-auto">
                      <table class="table mb-0 table-striped table-hover">
                        <thead class="text-center">
                        <tr>
                          <th>No</th>
                          <th class="text-truncate" style="min-width:250px">Fleet/Equipment</th>
                          <th class="text-truncate" style="min-width:200px">Job Name</th>
                          <th class="text-truncate">Component Name</th>
                          <th class="text-truncate">Frequency</th>
                          <th class="text-truncate">Last Maintenance</th>
                          <th class="text-truncate">Finish Maintenance</th>
                          <th class="text-truncate">Cost</th>
                          <th class="text-truncate">File</th>
                          <th class="text-truncate">Status</th>
                          <th class="text-truncate">Approved by</th>
                        </tr>
                        </thead>
                        <tbody>
                          <tr  v-for="(job, index) in maintenanceFinished.data" :key="index">
                            <td>{{ index+1 }}</td>
                            <td class="text-left"><a href="#" @click.prevent="viewDetailMaintenance(job.id,'VIEW_DETAIL')">{{ job.vehicleName }}</a></td>
                            <td class="text-left"><a href="#" @click.prevent="viewDetailMaintenance(job.id, 'VIEW_DETAIL')">{{ job.jobName }}</a></td>
                            <td class="text-truncate">{{ job.componentName }}</td>
                            <td class="text-truncate">{{ job.interval }}</td>
                            <td class="text-center text-truncate">{{ job.lastMaintenanceDate ? moment(job.lastMaintenanceDate, "DD-MM-YYYY").format('DD MMM YYYY') : '-' }}</td>
                            <td class="text-truncate text-center"><span>{{ job.dueDate }}</span></td>
                            <td class="text-truncate text-right">{{ job?.cost ?? 0 }}</td>
                            <td>-</td>
                            <td class="text-truncate"><b-badge variant="success">{{ job.status }}</b-badge></td>
                            <td class="text-truncate">{{ job.approveBy }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </template>
                  <b-col>
                    <b-row class="mt-4">
                      <b-col col-6>
                        Total: {{ maintenanceFinished.meta.total }} &nbsp;
                        | &nbsp; Per page:
                        {{ maintenanceFinished.meta.perPage }}
                      </b-col>
                      <b-col col-6>
                        <b-pagination
                          v-model="maintenanceFinished.params.page"
                          :total-rows="maintenanceFinished.meta.total"
                          :per-page="maintenanceFinished.meta.perPage"
                          align="right"
                          @input="getMaintenanceFinishedList"
                        ></b-pagination>
                      </b-col>
                    </b-row>
                  </b-col>
                </iq-card>
              </b-col>
              <!-- End List Finished -->
            </b-row>
          </tab-content-item>
        </div>
        <!-- Sidebar View -->
        <b-sidebar
          v-model="viewDetailMaintenanceSidebar"
          id="view-detail-maintenance"
          title="View Job Maintenance"
          width="70%"
          backdrop-variant="dark"
          bg-variant="white"
          right
          backdrop
          shadow>
          <template #default="{ hide }">
            <ViewMaintenanceDetail 
              v-if="viewDetailMaintenanceSidebar" 
              :isEdit="isEdit" 
              :jobClassList="jobClassList" 
              :jobTypeList="jobTypeList" 
              @save="closeDetailMaintenance()" 
              :maintenance-id="selectedMaintenanceId" 
              :action="actionMaintenanceSidebar" 
              :onApprove="onApprove"
              :onReview="onReview"
            />
          </template>
        </b-sidebar>
        <!-- End Sidebar View -->
      </b-col>
      
    </b-row>
    <!-- End Content Section !-->
  </div>
</template>

<script>
import {
  fleetsActions, maintenanceActions, filesActions
} from '@src/Utils/helper'
import { createPopper } from '@popperjs/core'
import moment from 'moment'
import ViewMaintenanceDetail from '@src/views/Maintenance/ViewMaintenanceDetail'
import AdvancedFilter from './Components/AdvancedFilter'

export default {
  name: 'MaintenanceSummaryBoard',
  components: { ViewMaintenanceDetail, AdvancedFilter },
  data () {
    return {
      tab: 'TASK_LIST',
      exportLoading: false,
      fleetsLoading: false,
      fleets: [],
      fleetEquipments: [],
      fleetParams: {
        page: 1,
        perPage: 9999,
        showAll: true,
        active: true,
        search: ''
      },
      maintenance: {
        selectedJob: 'job_class',
        dateRange: [new Date(moment().startOf('month')), new Date(moment().endOf('month'))],
        selectedJobClass: null,
        isLoading: false,
        editMode: false,
        params: {
          action: '',
          search: '',
          status: '',
          startDate: null,
          endDate: null,
          perPage: 25,
          page: 1,
          vehicleIds: [],
          jobTypeIds: [],
          jobClassIds: []
        },
        data: [],
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0,
          towards: 0,
          overdue: 0,
          postponed: 0,
          '3months': 0,
          '6months': 0,
          '12months': 0,
        }
      },
      maintenanceFinished: {
        isLoading: false,
        params: {
          action: '',
          search: '',
          status: '',
          startDate: null,
          endDate: null,
          perPage: 25,
          page: 1,
          vehicleIds: [],
          jobTypeIds: [],
          jobClassIds: []
        },
        data: [],
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        }
      },
      jobOptions: [
        {
          label: 'Job Class ',
          color: 'primary',
          value: 'job_class'
        },
        {
          label: 'Job Type / Index',
          color: 'success',
          value: 'job_type'
        }
      ],
      statusList: [
        { value: null, text: 'All' },
        { value: 'TOWARDS', text: 'Towards Jobs' },
        { value: 'OVERDUE', text: 'Overdue' },
        { value: 'POSTPONE', text: 'Postponed' },
        { value: 'MONTHS3', text: 'Next 3 Month' },
        { value: 'MONTHS6', text: 'Next 6 Month' },
        { value: 'MONTHS12', text: 'Next 12 Month' }
      ],
      actionSelectList: [
        { value: 'review', text: 'Review' },
        { value: 'approve', text: 'Approve' },
        { value: 'maintenance', text: 'Maintenance' },
        { value: 'postponed', text: 'Postponed' }
      ],
      jobClassList: [],
      jobTypeList: [],
      selectedMaintenanceId: null,
      viewDetailMaintenanceSidebar: false,
      isEdit: false,
      actionMaintenanceSidebar: 'VIEW_DETAIL',
      lsProfile: JSON.parse(localStorage.getItem('profile'))
    }
  },
  computed: {
    selectedStatusList () {
      return this.maintenance.params.status ? this.statusList.find(status => status.value === this.maintenance.params.status) : ''
    },
    computedDateRangeText () {
      return this.maintenance.dateRange ? `${moment(this.maintenance.dateRange[0]).format('DD MMM YYYY')} - ${moment(this.maintenance.dateRange[1]).format('DD MMM YYYY')}` : 'Semua Tanggal'
    },
    'queryVehicleId' () {
      return this.$route.query.vehicleId
    },
    maintenancePermission () {
      return this.lsProfile.menu.find(menu => menu.menu === 'Apps')?.child.find(child => child.menu === 'Technical')?.child.find(child => child.menu === 'Maintenance')
    },
    canAdd () {
      return this.maintenancePermission ? this.maintenancePermission?.add : false
    },
    canEdit () {
      return this.maintenancePermission ? this.maintenancePermission?.update : false
    },
    canApprove () {
      return this.maintenancePermission ? this.maintenancePermission?.approval : false
    },
    canReview () {
      return this.maintenancePermission ? this.maintenancePermission?.review : false
    },
    canDelete () {
      return this.maintenancePermission ? this.maintenancePermission?.delete : false
    },
    canExport () {
      return this.maintenancePermission ? this.maintenancePermission?.export : false
    },
  },
  watch: {
    'maintenance.params.status' (value) {
      this.getMaintenanceBoard()
    },
  },
  methods: {
    ...fleetsActions,
    ...maintenanceActions,
    ...filesActions,
    async getJobTypeList () {
      const params = {
        page: 1,
        perPage: 1000
      }
      this.jobTypeList = []
      const res = await this.getJobType(params)

      if (res.status == 'success') {
        if (res.data.data && res.data.data.length > 0) {
          res.data.data.forEach(el => {
            this.jobTypeList.push({
              name: `${el.jobTypeCode} - ${el.jobTypeIndex}`,
              code: el.id
            })
          })
        }
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    async getJobClassList () {
      const params = {
        page: 1,
        perPage: 1000
      }
      this.jobClassList = []
      const res = await this.getJobClass(params)

      if (res.status == 'success') {
        if (res.data.data && res.data.data.length > 0) {
          res.data.data.forEach(el => {
            this.jobClassList.push({
              name: `${el.jobClassCode} - ${el.jobClass}`,
              code: el.id
            })
          })
        }
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    async fetchFleets () {
      this.fleetsLoading = true
      this.fleets = []

      let params = this.$options.filters.cleanObject(this.fleetParams)
      let res = await this.getFleets(params)

      if (res.status) {
        this.fleetsLoading = false
        if (res.data && res.data.length > 0) {
          res.data.forEach(el => {
            el.componentSetup = []
            this.fleets.push(el)
            this.fleetEquipments.push({
              name: el.name,
              code: el.id
            })
          })
        }
      }
    },
    async getMaintenanceList () {
      this.maintenance.isLoading = true
      this.maintenance.data = []

      if (this.maintenance.selectedJob === 'job_class') {
        this.maintenance.params.jobTypeIds = []
      } else {
        this.maintenance.params.jobClassIds = []
      }

      const paramsQ = {
        search: this.maintenance.params.search,
        perPage: this.maintenance.params.perPage,
        page: this.maintenance.params.page,
        status: this.maintenance.params.status,
        vehicleIds: this.maintenance.params.vehicleIds,
        jobTypeIds: this.maintenance.params.jobTypeIds,
        jobClassIds: this.maintenance.params.jobClassIds
      }

      if (paramsQ.vehicleIds && paramsQ.vehicleIds.length > 0) {
        let dataTemp = []
        paramsQ.vehicleIds.forEach(el => {
          dataTemp.push(el.code)
        })
        paramsQ.vehicleIds = dataTemp
        paramsQ.vehicleIds = paramsQ.vehicleIds.toString()
      }
      if (paramsQ.jobTypeIds && paramsQ.jobTypeIds.length > 0) {
        let dataTemp = []
        paramsQ.jobTypeIds.forEach(el => {
          dataTemp.push(el.code)
        })
        paramsQ.jobTypeIds = dataTemp
        paramsQ.jobTypeIds = paramsQ.jobTypeIds.toString()
      }
      if (paramsQ.jobClassIds && paramsQ.jobClassIds.length > 0) {
        let dataTemp = []
        paramsQ.jobClassIds.forEach(el => {
          dataTemp.push(el.code)
        })
        paramsQ.jobClassIds = dataTemp
        paramsQ.jobClassIds = paramsQ.jobClassIds.toString()
      }
      if (paramsQ.search === '') {
        delete paramsQ.search
      }
      if (paramsQ.status === '') {
        delete paramsQ.status
      }

      const res = await this.getMaintenance(paramsQ)

      if (res.status == 'success') {
        if (res.data.data && res.data.data.length > 0) {
          const sortedData = res.data.data.sort((a, b) => {
            const dateA = new Date(
              a.lastMaintenanceDate.split("-").reverse().join("-")
            );
            const dateB = new Date(
              b.lastMaintenanceDate.split("-").reverse().join("-")
            );

            // If the years are different, prioritize entries from 2022
            if (dateA.getFullYear() !== dateB.getFullYear()) {
              return dateA.getFullYear() - dateB.getFullYear();
            }

            // If the years are the same, proceed with regular sorting
            return dateA - dateB;
          });

          console.log(sortedData, "SORTED DATA")

          this.maintenance.data = sortedData
          this.maintenance.meta.perPage = res.data.perPage
          this.maintenance.meta.currentPage = res.data.currentPage
          this.maintenance.meta.total = res.data.total
          this.maintenance.meta.totalPage = res.data.totalPage

          this.maintenance.meta.towards = res.data.meta[0]?.towards
          this.maintenance.meta.overdue = res.data.meta[0]?.overdue
          this.maintenance.meta.postponed = res.data.meta[0]?.postponed
          this.maintenance.meta['3months'] = res.data.meta[0]?.['3months']
          this.maintenance.meta['6months'] = res.data.meta[0]?.['6months']
          this.maintenance.meta['12months'] = res.data.meta[0]?.['12months']
        }
        this.maintenance.isLoading = false
      } else {
        this.maintenance.data = []
        this.maintenance.isLoading = false
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    async getMaintenanceFinishedList (page) {
      this.maintenanceFinished.isLoading = true
      this.maintenanceFinished.data = []
      this.maintenanceFinished.params.page = page || 1

      if (this.maintenance.selectedJob === 'job_class') {
        this.maintenance.params.jobTypeIds = []
      } else {
        this.maintenance.params.jobClassIds = []
      }

      const paramsQ = {
        search: this.maintenance.params.search,
        perPage: this.maintenanceFinished.params.perPage,
        page: this.maintenanceFinished.params.page,
        startDate: this.maintenance.params.startDate,
        endDate: this.maintenance.params.endDate,
        status: this.maintenance.params.status,
        vehicleIds: this.maintenance.params.vehicleIds,
        jobTypeIds: this.maintenance.params.jobTypeIds,
        jobClassIds: this.maintenance.params.jobClassIds
      }

      if (paramsQ.vehicleIds && paramsQ.vehicleIds.length > 0) {
        let dataTemp = []
        paramsQ.vehicleIds.forEach(el => {
          dataTemp.push(el.code)
        })
        paramsQ.vehicleIds = dataTemp
        paramsQ.vehicleIds = paramsQ.vehicleIds.toString()
      }
      if (paramsQ.jobTypeIds && paramsQ.jobTypeIds.length > 0) {
        let dataTemp = []
        paramsQ.jobTypeIds.forEach(el => {
          dataTemp.push(el.code)
        })
        paramsQ.jobTypeIds = dataTemp
        paramsQ.jobTypeIds = paramsQ.jobTypeIds.toString()
      }
      if (paramsQ.jobClassIds && paramsQ.jobClassIds.length > 0) {
        let dataTemp = []
        paramsQ.jobClassIds.forEach(el => {
          dataTemp.push(el.code)
        })
        paramsQ.jobClassIds = dataTemp
        paramsQ.jobClassIds = paramsQ.jobClassIds.toString()
      }
      if (paramsQ.search === '') {
        delete paramsQ.search
      }
      if (paramsQ.status === '') {
        delete paramsQ.status
      }

      const res = await this.getMaintenanceFinished(paramsQ)

      if (res.status == 'success') {
        if (res.data.data && res.data.data.length > 0) {
          this.maintenanceFinished.data = res.data.data
          this.maintenanceFinished.meta.perPage = res.data.perPage
          this.maintenanceFinished.meta.currentPage = res.data.currentPage
          this.maintenanceFinished.meta.total = res.data.total
          this.maintenanceFinished.meta.totalPage = res.data.totalPage
        }
        this.maintenanceFinished.isLoading = false
      } else {
        this.maintenanceFinished.data = []
        this.maintenanceFinished.isLoading = false
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    async getMaintenanceBoard() {
      await this.getMaintenanceList()
      await this.getMaintenanceFinishedList()
    },
    doExport () {

    },
    onChangeDate() {
      this.maintenance.params.startDate = this.maintenance.dateRange ? moment(this.maintenance.dateRange[0]).format('DD-MM-YYYY') : null
      this.maintenance.params.endDate = this.maintenance.dateRange ? moment(this.maintenance.dateRange[1]).format('DD-MM-YYYY') : null
    },
    withPopper (dropdownList, component, { width }) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      dropdownList.style.width = width

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1]
            }
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn ({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              )
            }
          }
        ]
      })

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy()
    },
    viewDetailMaintenance (id, action = 'VIEW_DETAIL', isEdit = false) {
      if(typeof id !== 'undefined' && id) {
        this.selectedMaintenanceId = id
        this.viewDetailMaintenanceSidebar = true
        this.actionMaintenanceSidebar = action
        this.isEdit = isEdit
      }
    },

    async closeDetailMaintenance () {
      this.selectedMaintenanceId = null
      this.viewDetailMaintenanceSidebar = false
      await this.getMaintenanceBoard()
    },
    onReview (id, fromDetail) {
      this.$swal({
        title: 'Lakukan Review Maintenance ?',
        text: `Periksa Kembali Seluruh Data`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Review'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let payload = {
            id
          }
          const res = await this.saveReviewMaintenance(payload)

          if (res.status === 'success') {
            this.$swal(
              `Review Berhasil`,
              '',
              'success'
            )
            if(fromDetail){
              this.closeDetailMaintenance()
            } else {
              await this.getMaintenanceBoard()
            }
          } else {
            this.$swal(
              `Oops!`,
              res.data.message,
              'error'
            )
          }
        }
      })
    },
    onApprove (id, fromDetail) {
      this.$swal({
        title: 'Lakukan Approve Maintenance ?',
        text: `Periksa Kembali Seluruh Data`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Approve'
      }).then(async (result) => {
        if (result.isConfirmed) {
          let payload = {
            id
          }
          const res = await this.saveApproveMaintenance(payload)

          if (res.status === 'success') {
            this.$swal(
              `Approve Berhasil`,
              '',
              'success'
            )
           
            if(fromDetail){
              this.closeDetailMaintenance()
            } else {
              await this.getMaintenanceBoard()
            }
          } else {
            this.$swal(
              `Oops!`,
              res.data.message,
              'error'
            )
          }
        }
      })
    },
    generateDayDurations (days) {
      let start = moment()
      let end = moment().add(days, 'days')
      let difference = moment(end).diff(moment(start))
      let duration = moment.duration(difference)
      let durationString = ''

      if (Math.round(duration.years()) > 0) {
        durationString += `${Math.round(duration.years())} years `
      }

      if (Math.round(duration.months()) > 0) {
        durationString += `${Math.round(duration.months())} months `
      }

      if (Math.round(duration.weeks()) > 0) {
        durationString += `${Math.round(duration.weeks())} weeks `
      }

      if (Math.round(duration.days()) > 0) {
        durationString += `${Math.round(duration.days())} days `
      }

      return {
        start,
        end,
        duration: difference / 1000,
        durationString
      }
    }
  },
  created () {
    this.getJobTypeList()
    this.getJobClassList()
  },
  async mounted () {
    this.onChangeDate()
    await this.fetchFleets()
    if(typeof this.$route.query.vehicleId !== 'undefined') {
      this.maintenance.params.vehicleIds = this.fleetEquipments.filter(fleet => parseInt(fleet.code) === parseInt(this.$route.query.vehicleId))
    }
    await this.getMaintenanceBoard()
  },
}
</script>

<style scoped>

</style>
