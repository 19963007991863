var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('b-row', _vm._l(_vm.cards, function (card, index) {
          return _c('b-col', {
            key: index,
            staticClass: "mb-3 mb-lg-0",
            attrs: {
              "lg": "3",
              "md": "6"
            }
          }, [_c('div', {
            staticClass: "p-3 d-flex align-items-center card-container",
            style: {
              borderRadius: '12px',
              background: card.bg
            }
          }, [_c('img', {
            staticClass: "card-image",
            staticStyle: {
              "width": "84px",
              "height": "100px"
            },
            attrs: {
              "src": require("@/src/assets/images/icon/".concat(card.img)),
              "alt": ""
            }
          }), _c('div', [_c('h6', {
            staticStyle: {
              "color": "#fff000"
            }
          }, [_c('strong', [_vm._v(_vm._s(card.title))])]), _c('h5', {
            staticClass: "text-white"
          }, [_c('strong', [_vm._v(_vm._s(card.value))])]), _c('p', {
            staticClass: "mb-0 text-xs text-white"
          }, [_vm._v(_vm._s(index !== 0 ? 'Percentage :' : '')), _c('strong', [_vm._v(_vm._s(card.percent))])])])])]);
        }), 1)];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }