var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "text-center section"
  }, [_c('v-calendar', {
    staticClass: "custom-calendar max-w-full",
    attrs: {
      "masks": _vm.masks,
      "attributes": _vm.attributes,
      "disable-page-swipe": "",
      "is-expanded": ""
    },
    scopedSlots: _vm._u([{
      key: "day-content",
      fn: function fn(_ref) {
        var day = _ref.day,
          attributes = _ref.attributes;
        return [_c('div', {
          staticClass: "flex flex-col h-full z-1 border px-1 text-end overflow-auto",
          staticStyle: {
            "min-height": "124px",
            "max-height": "240px",
            "height": "100%"
          }
        }, [_c('p', {
          staticClass: "m-0 text-base text-gray-900 text-end"
        }, [_c('strong', [_vm._v(_vm._s(day.day))])]), _c('div', {
          staticClass: "flex-grow overflow-y-auto overflow-x-auto"
        }, _vm._l(attributes, function (attr) {
          return _c('div', {
            key: attr.key,
            staticClass: "leading-tight rounded-sm p-1 mt-0 mb-1 text-white"
          }, [_c('b-button', {
            key: attr.key,
            staticClass: "w-100 text-left",
            staticStyle: {
              "font-size": "x-small"
            },
            attrs: {
              "id": "popover-1-".concat(attr.customData.data.id),
              "variant": attr.customData.class
            }
          }, [_c('span', {
            staticStyle: {
              "font-weight": "600"
            }
          }, [_vm._v(_vm._s(attr.customData.data.vehicleName))]), _c('br'), _vm._v(" " + _vm._s(attr.customData.data.jobName))]), _c('b-popover', {
            attrs: {
              "target": "popover-1-".concat(attr.customData.data.id),
              "placement": "top",
              "triggers": "hover focus",
              "content": attr.customData.class
            }
          }, [_vm.canAdd && ['TOWARDS', 'OVERDUE', 'POSTPONE', 'NEW'].includes(attr.customData.data.status) ? _c('div', {
            staticClass: "cursor-pointer min-w-50 border p-1 px-2 mb-1 text-primary",
            staticStyle: {
              "font-size": "small"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.$emit('viewDetailMaintenance', {
                  id: attr.customData.data.id,
                  type: 'ON_MAINTENANCE'
                });
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-tasks"
          }), _vm._v(" Maintenance")]) : _vm._e(), ['APPROVE'].includes(attr.customData.data.status) && _vm.canApprove ? _c('div', {
            staticClass: "cursor-pointer min-w-50 border p-1 px-2 mb-1 text-info",
            staticStyle: {
              "font-size": "small"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.$emit('onApprove', attr.customData.data.id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-thumbs-up"
          }), _vm._v(" Approve")]) : _vm._e(), ['TOWARDS', 'OVERDUE', 'NEW'].includes(attr.customData.data.status) ? _c('div', {
            staticClass: "cursor-pointer min-w-50 border p-1 px-2 mb-1 text-warning",
            staticStyle: {
              "font-size": "small"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.$emit('viewDetailMaintenance', {
                  id: attr.customData.data.id,
                  type: 'ON_POSTPONE'
                });
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-clock"
          }), _vm._v(" Postpone")]) : _vm._e(), ['MAINTENANCE', 'REVIEW'].includes(attr.customData.data.status) && _vm.canReview ? _c('div', {
            staticClass: "cursor-pointer min-w-50 border p-1 px-2 mb-1 text-info",
            staticStyle: {
              "font-size": "small"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.$emit('onReview', attr.customData.data.id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-search"
          }), _vm._v(" Review")]) : _vm._e(), ['APPROVE', 'REVIEW'].includes(attr.customData.data.status) ? _c('div', {
            staticClass: "cursor-pointer min-w-50 border p-1 px-2 mb-1 text-primary",
            staticStyle: {
              "font-size": "small"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.$emit('viewDetailMaintenance', {
                  id: attr.customData.data.id,
                  type: 'ON_MAINTENANCE',
                  isEdit: true
                });
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-pen"
          }), _vm._v(" Edit")]) : _vm._e(), ['TOWARDS', 'OVERDUE', 'NEW'].includes(attr.customData.data.status) ? _c('div', {
            staticClass: "cursor-pointer min-w-50 border p-1 px-2 mb-1 text-danger",
            staticStyle: {
              "font-size": "small"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.$emit('viewDetailMaintenance', {
                  id: attr.customData.data.id,
                  type: 'ON_CONVERT'
                });
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-tools"
          }), _vm._v(" Convert Repair")]) : _vm._e()])], 1);
        }), 0)])];
      }
    }])
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }