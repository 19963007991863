var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    staticClass: "px-5 py-2",
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("List of Crew PKL Not Completed")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$list, _vm$list$data, _vm$list2, _vm$list2$data, _vm$list3, _vm$list4, _vm$list4$data, _vm$list5, _vm$list6, _vm$list7;
        return [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex w-100 align-items-center justify-content-between mb-4"
        }, [((_vm$list = _vm.list) === null || _vm$list === void 0 ? void 0 : (_vm$list$data = _vm$list.data) === null || _vm$list$data === void 0 ? void 0 : _vm$list$data.length) > 0 ? _c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.onExportListPklCrew
          }
        }, [_c('div', {
          staticClass: "d-flex gap-2 align-items-center"
        }, [_vm.exporting ? _c('span', {
          staticClass: "spinner-border spinner-border-sm mr-2"
        }) : _c('i', {
          staticClass: "fa fa-file-excel mr-2"
        }), _c('span', [_vm._v("Export")])])]) : _vm._e()], 1)]), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "Search..."
          },
          model: {
            value: _vm.params.filter,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "filter", $$v);
            },
            expression: "params.filter"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Fleet"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.fleetOptions,
            "reduce": function reduce(fleetOptions) {
              return fleetOptions.value;
            },
            "placeholder": "Select...",
            "required": ""
          },
          model: {
            value: _vm.params.vehicle_id,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "vehicle_id", $$v);
            },
            expression: "params.vehicle_id"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Company"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.optionsCompanies,
            "reduce": function reduce(optionsCompanies) {
              return optionsCompanies.value;
            },
            "placeholder": "Select...",
            "required": ""
          },
          model: {
            value: _vm.params.company_id,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "company_id", $$v);
            },
            expression: "params.company_id"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Filter"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.getListPklCrew
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1), _vm.loading ? _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "alert alert-info m-4 align-items-center"
        }, [_c('span', {
          staticClass: "spinner-border spinner-border-sm mr-2"
        }), _vm._v(" Loading... ")])]) : _vm._e(), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [((_vm$list2 = _vm.list) === null || _vm$list2 === void 0 ? void 0 : (_vm$list2$data = _vm$list2.data) === null || _vm$list2$data === void 0 ? void 0 : _vm$list2$data.length) > 0 ? _c('div', {
          staticClass: "d-flex overflow-auto"
        }, [_c('table', {
          staticClass: "table table-bordered"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("NO")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Name")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Fleet")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Position")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Join on Board")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Sign On")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Sign Off")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Status")])])]), _c('tbody', _vm._l((_vm$list3 = _vm.list) === null || _vm$list3 === void 0 ? void 0 : _vm$list3.data, function (crew, i) {
          var _crew$fullname, _crew$nip, _crew$vehicle$name, _crew$vehicle, _crew$company$company, _crew$company, _crew$position$name, _crew$position, _crew$status_emp_crew, _crew$status_emp_crew2, _crew$status_emp_crew3;
          return _c('tr', {
            key: 'crew-' + i
          }, [_c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(i + 1))]), _c('td', {
            staticClass: "text-truncate"
          }, [_c('router-link', {
            attrs: {
              "to": {
                path: "profile",
                query: {
                  user: 'crew',
                  id: crew === null || crew === void 0 ? void 0 : crew.id
                }
              }
            }
          }, [_vm._v(_vm._s((_crew$fullname = crew === null || crew === void 0 ? void 0 : crew.fullname) !== null && _crew$fullname !== void 0 ? _crew$fullname : '-'))]), _c('br'), _c('small', {
            staticStyle: {
              "color": "gray"
            }
          }, [_vm._v("NIP : "), _c('b', [_vm._v(_vm._s((_crew$nip = crew === null || crew === void 0 ? void 0 : crew.nip) !== null && _crew$nip !== void 0 ? _crew$nip : '-'))])])], 1), _c('td', {
            staticClass: "text-center text-truncate"
          }, [_vm._v(_vm._s((_crew$vehicle$name = crew === null || crew === void 0 ? void 0 : (_crew$vehicle = crew.vehicle) === null || _crew$vehicle === void 0 ? void 0 : _crew$vehicle.name) !== null && _crew$vehicle$name !== void 0 ? _crew$vehicle$name : '-') + " "), _c('br'), _c('small', {
            staticStyle: {
              "color": "gray"
            }
          }, [_vm._v(_vm._s((_crew$company$company = crew === null || crew === void 0 ? void 0 : (_crew$company = crew.company) === null || _crew$company === void 0 ? void 0 : _crew$company.company) !== null && _crew$company$company !== void 0 ? _crew$company$company : '-'))])]), _c('td', {
            staticClass: "text-center text-truncate"
          }, [_vm._v(_vm._s((_crew$position$name = crew === null || crew === void 0 ? void 0 : (_crew$position = crew.position) === null || _crew$position === void 0 ? void 0 : _crew$position.name) !== null && _crew$position$name !== void 0 ? _crew$position$name : '-') + " ")]), _c('td', {
            staticClass: "text-center text-truncate"
          }, [_vm._v(_vm._s(crew !== null && crew !== void 0 && crew.join_on_board ? _vm.moment(crew === null || crew === void 0 ? void 0 : crew.join_on_board).format('DD MMM YYYY HH:mm') : '-') + " "), _vm.joinOnBoardStatus(crew) ? _c('b-badge', {
            attrs: {
              "variant": _vm.joinOnBoardStatus(crew) === 'More than 1 year' ? 'success d-block' : _vm.joinOnBoardStatus(crew) === 'More than 6 month' ? 'primary d-block' : _vm.joinOnBoardStatus(crew) === 'More than 3 month' ? 'warning d-block' : 'dark d-block'
            }
          }, [_vm._v(_vm._s(_vm.joinOnBoardStatus(crew)))]) : _vm._e()], 1), _c('td', {
            staticClass: "text-center text-truncate"
          }, [_vm._v(_vm._s(crew !== null && crew !== void 0 && crew.sign_on ? _vm.moment(crew === null || crew === void 0 ? void 0 : crew.sign_on).format('DD MMM YYYY HH:mm') : '-'))]), _c('td', {
            staticClass: "text-center text-truncate"
          }, [_vm._v(_vm._s(crew !== null && crew !== void 0 && crew.sign_off ? _vm.moment(crew === null || crew === void 0 ? void 0 : crew.sign_off).format('DD MMM YYYY HH:mm') : '-') + " "), _c('br')]), _c('td', {
            staticClass: "text-center text-truncate"
          }, [_c('b-badge', {
            staticClass: "mb-1",
            attrs: {
              "variant": "".concat((crew === null || crew === void 0 ? void 0 : (_crew$status_emp_crew = crew.status_emp_crewing) === null || _crew$status_emp_crew === void 0 ? void 0 : _crew$status_emp_crew.alias) === 'active' ? 'success' : 'warning', " d-block")
            }
          }, [_vm._v(_vm._s((_crew$status_emp_crew2 = crew === null || crew === void 0 ? void 0 : (_crew$status_emp_crew3 = crew.status_emp_crewing) === null || _crew$status_emp_crew3 === void 0 ? void 0 : _crew$status_emp_crew3.name) !== null && _crew$status_emp_crew2 !== void 0 ? _crew$status_emp_crew2 : '-') + " ")]), _vm.expiredContract(crew) ? _c('b-badge', {
            attrs: {
              "variant": "danger d-block"
            }
          }, [_vm._v(_vm._s(_vm.expiredContract(crew)) + " ")]) : _vm._e()], 1)]);
        }), 0)])]) : _vm._e(), ((_vm$list4 = _vm.list) === null || _vm$list4 === void 0 ? void 0 : (_vm$list4$data = _vm$list4.data) === null || _vm$list4$data === void 0 ? void 0 : _vm$list4$data.length) > 0 ? _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "value": (_vm$list5 = _vm.list) === null || _vm$list5 === void 0 ? void 0 : _vm$list5.current_page,
            "total-rows": (_vm$list6 = _vm.list) === null || _vm$list6 === void 0 ? void 0 : _vm$list6.total,
            "per-page": (_vm$list7 = _vm.list) === null || _vm$list7 === void 0 ? void 0 : _vm$list7.per_page,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getListPklCrew
          }
        })], 1) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("List Crew PKL Not Completed doesnt Exist.")])])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }