<template>
  <b-container fluid>
    <b-row>
      <b-col md="6" lg="3">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-info rounded">
          <template v-slot:body >
            <div class="d-flex align-items-center justify-content-between">
              <div class="rounded-circle iq-card-icon bg-info"><i class="fa fa-spinner"></i></div>
              <div class="text-right">
                <h4 class="mb-0"><span class="counter"><b>215</b></span></h4>
                <h6 class="">Maintenance Towards</h6>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col md="6" lg="3">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-danger rounded">
          <template v-slot:body>
            <div class="d-flex align-items-center justify-content-between">
              <div class="rounded-circle iq-card-icon bg-danger"><i class="fas fa-exclamation-triangle"></i></div>
              <div class="text-right">
                <h4 class="mb-0"><span class="counter"><b>375</b></span></h4>
                <h6 class="">Maintenance Overdue</h6>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col md="6" lg="3">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-warning rounded">
          <template v-slot:body >
            <div class="d-flex align-items-center justify-content-between">
              <div class="rounded-circle iq-card-icon bg-warning"><i class="fas fa-history"></i></div>
              <div class="text-right">
                <h4 class="mb-0"><span class="counter"><b>35</b></span></h4>
                <h6 class="">Maintenance Postponded</h6>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col md="6" lg="3">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-success rounded">
          <template v-slot:body >
            <div class="d-flex align-items-center justify-content-between">
              <div class="rounded-circle iq-card-icon bg-success"><i class="far fa-check-circle"></i></div>
              <div class="text-right">
                <h4 class="mb-0"><span class="counter"><b>45</b></span></h4>
                <h6 class="">Maintenance Active</h6>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" v-for="(item,index) in costDocking" :key="index">
        <iq-card>
          <template v-slot:headerTitle>
            <h4>{{ item.title }}</h4>
          </template>
          <template v-slot:body>
            <HighChart :ref="item.type" :option="item.bodyData"/>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="6" v-for="(item,index) in charts" :key="index">
        <iq-card>
          <template v-slot:headerTitle>
            <h4>{{ item.title }}</h4>
          </template>
          <template v-slot:body>
            <ApexChart :element="item.type" :chartOption="item.bodyData"/>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">
              <strong>MAINTENANCE HISTORY - TB. TAVIA 1</strong>
            </h4>
          </template>
          <template v-slot:body>
            <!-- FILTER DOCKING PROGRESS-->
            <b-row class="mb-3">
              <b-col md="3">
                <b-form-select plain size="sm">
                  <template v-slot:first>
                  <b-form-select-option>Select Equipment</b-form-select-option>
                  <b-form-select-option>Accomodation</b-form-select-option>
                  <b-form-select-option>Deck & Outfiting</b-form-select-option>
                  <b-form-select-option>Main Engine</b-form-select-option>
                  </template>
                </b-form-select>
              </b-col>
              <b-col md="1">
                <b-button variant="primary" size="sm"><i class="fa fa-filter"></i></b-button>
              </b-col>
            </b-row>
            <!-- FILTER DOCKING PROGRESS END -->
            <table class="table table-striped table-sm" style="font-size: 10pt;">
              <thead>
                <tr>
                  <th class="text-center">JOB NAME - TYPE</th>
                  <th class="text-center">EQUIPMENT</th>
                  <th class="text-center">TOTAL MAINTENANCE</th>
                  <th class="text-center">FREQUESCY</th>
                  <th class="text-center">LAST MAINTENANCE</th>
                  <th class="text-center">DUE DATE / HOURS</th>
                  <th class="text-center">STATUS</th>
                  <th class="text-center">ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="pl-3">WATER TIGHT DOOR - INSPECTION</td>
                  <td class="text-center">DECK & OUT FITTING EQUIPMENT</td>
                  <td class="text-center">6</td>
                  <td class="text-center">300 H</td>
                  <td class="text-center">1.800 H</td>
                  <td class="text-center">2.100 H</td>
                  <td class="text-center">
                    <b-badge variant="danger">OVERDUE</b-badge>
                  </td>
                  <td class="text-center">
                    <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10"
                      toggle-class="text-decoration-none" no-caret>
                      <template v-slot:button-content>
                        <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                          <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                        </span>
                      </template>
                      <b-dropdown-item href="#" v-b-toggle.detail_maintenance_counter variant="primary">
                        <i class="fa fa-info-circle mr-2"></i>Detail
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
                <tr>
                  <td class="pl-3">ACCOMODATION ROOM - TEST</td>
                  <td class="text-center">ACCOMMODATION</td>
                  <td class="text-center">8</td>
                  <td class="text-center">3 M</td>
                  <td class="text-center">12/10/2023</td>
                  <td class="text-center">12/01/2023</td>
                  <td class="text-center">
                    <b-badge variant="primary">ACTIVE</b-badge>
                  </td>
                  <td class="text-center">
                    <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10"
                      toggle-class="text-decoration-none" no-caret>
                      <template v-slot:button-content>
                        <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                          <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                        </span>
                      </template>
                      <b-dropdown-item href="#" v-b-toggle.detail_maintenance_calendar variant="primary">
                        <i class="fa fa-info-circle mr-2"></i>Detail
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
                <tr>
                  <td class="pl-3">ANODE / ZINK ROD - PORT - CLEANING</td>
                  <td class="text-center">MAIN ENGINE</td>
                  <td class="text-center">4</td>
                  <td class="text-center">1.200 H</td>
                  <td class="text-center">4.800</td>
                  <td class="text-center">6.000</td>
                  <td class="text-center">
                    <b-badge variant="info">TOWARDS</b-badge>
                  </td>
                  <td class="text-center">
                    <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10"
                      toggle-class="text-decoration-none" no-caret>
                      <template v-slot:button-content>
                        <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                          <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                        </span>
                      </template>
                      <b-dropdown-item href="#" v-b-toggle.detail_maintenance_counter variant="primary">
                        <i class="fa fa-info-circle mr-2"></i>Detail
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="mt-3">
              <b-pagination :value="1" :total-rows="50" align="center" />
            </div>
            <b-sidebar id="detail_maintenance_calendar" title="TB. TAVIA 1 - ACCOMODATION ROOM" width="70%" backdrop-variant="dark"
              bg-variant="white" right backdrop shadow>
              <template #default="{hide}">
                <b-row class="m-3">
                  <b-col cols="12">
                    <fieldset class="form-group border p-3">
                      <legend class="w-auto px-2">Running Hours Info</legend>
                      <table class="table table-striped">
                        <tbody>
                          <tr>
                            <th width="20%">RH ME - PORTSIDER</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="28%">820 Hours</td>
                            <th width="20%">RH AE - PORTSIDER</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="28%">770 Hours</td>
                          </tr>
                          <tr>
                            <th width="20%">RH ME - STARBOARD</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="28%">820 Hours</td>
                            <th width="20%">RH AE - STARBOARD</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="28%">770 Hours</td>
                          </tr>
                        </tbody>
                      </table>
                    </fieldset>
                    <fieldset class="form-group border p-3">
                      <legend class="w-auto px-2">Maintenance Info</legend>
                      <table class="table table-striped">
                        <tbody>
                          <tr>
                            <th width="20%">Job Code</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="28%">TV001-01</td>
                            <th width="20%">Job Class</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="28%">CM - CORRECTIVE MAINTENANCE</td>
                          </tr>
                          <tr>
                            <th width="20%">Job Type</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="28%">JT014 - TEST</td>
                            <th width="20%">Job Name</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="28%">ACCOMODATION ROOM - TEST</td>
                          </tr>
                          <tr>
                            <th width="20%">Equipment</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="28%">1.1 - ACCOMODATION ROOM</td>
                            <th width="20%">Interval/Frequency</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="28%">3 Month</td>
                          </tr>
                          <tr>
                            <th width="20%">Tigger</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="28%">CALENDAR</td>
                            <th width="20%">Status</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="28%"><b-badge variant="success">ACTIVE</b-badge></td>
                          </tr>
                          <tr>
                            <th width="20%">Total Cost</th>
                            <td width="2%" class="text-center">:</td>
                            <th width="28%">Rp. 122.150.000,-</th>
                            <th width="20%"></th>
                            <td width="2%" class="text-center"></td>
                            <td width="28%"></td>
                          </tr>
                        </tbody>
                      </table>
                    </fieldset>
                    <fieldset class="form-group border p-3">
                      <legend class="w-auto px-2">Equipment Info - ACCOMODATION ROOM</legend>
                      <table class="table table-striped">
                        <tbody>
                          <tr>
                            <th width="23%">Type No</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="25%">-</td>
                            <th width="23%">Serial No</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="25%">-</td>
                          </tr>
                          <tr>
                            <th width="23%">Maker No</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="25%">-</td>
                            <th width="23%">Maker</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="25%">-</td>
                          </tr>
                          <tr>
                            <th width="23%">Drawing No</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="25%">-</td>
                            <th width="23%">Certificate</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="25%">-</td>
                          </tr>
                          <tr>
                            <th width="18%">Additional Information</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="25%">-</td>
                            <th width="18%">Descriptions</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="25%">-</td>
                          </tr>
                        </tbody>
                      </table>
                    </fieldset>
                    <fieldset class="form-group border p-3">
                      <legend class="w-auto px-2">ACCOMODATION ROOM</legend>
                      <table class="table table-striped table-sm">
                        <thead>
                          <tr>
                            <th class="text-center">LAST MAINTENANCE</th>
                            <th class="text-center">TOTAL COST</th>
                            <th class="text-center">DUE DATE / HOURS</th>
                            <th class="text-center">ATTACHMENT</th>
                            <th class="text-center">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center">28/09/2023</td>
                            <td class="text-center">Rp. 18.750.000</td>
                            <td class="text-center">28/12/2023</td>
                            <td class="text-center">
                              <i class="far fa-file-pdf text-primary"></i>
                            </td>
                            <td class="text-center">
                              <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10"
                                toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                  </span>
                                </template>
                                <b-dropdown-item href="#" v-b-toggle.detail_maintenance variant="primary">
                                  <i class="fas fa-clipboard-list mr-2"></i>Notes
                                </b-dropdown-item>
                              </b-dropdown>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">20/06/2023</td>
                            <td class="text-center">Rp. 26.000.000</td>
                            <td class="text-center">25/09/2023</td>
                            <td class="text-center">
                              <i class="far fa-file-pdf text-primary"></i>
                            </td>
                            <td class="text-center">
                              <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10"
                                toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                  </span>
                                </template>
                                <b-dropdown-item href="#" v-b-toggle.detail_maintenance variant="primary">
                                  <i class="fas fa-clipboard-list mr-2"></i>Notes
                                </b-dropdown-item>
                              </b-dropdown>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">20/03/2023</td>
                            <td class="text-center">Rp. 35.000.000</td>
                            <td class="text-center">20/06/2023</td>
                            <td class="text-center">
                              <i class="far fa-file-pdf text-primary"></i>
                            </td>
                            <td class="text-center">
                              <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10"
                                toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                  </span>
                                </template>
                                <b-dropdown-item href="#" v-b-toggle.detail_maintenance variant="primary">
                                  <i class="fas fa-clipboard-list mr-2"></i>Notes
                                </b-dropdown-item>
                              </b-dropdown>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">12/01/2023</b></td>
                            <td class="text-center">Rp. 27.000.000</td>
                            <td class="text-center">12/03/2023</td>
                            <td class="text-center">
                              <i class="far fa-file-pdf text-primary"></i>
                            </td>
                            <td class="text-center">
                              <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10"
                                toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                  </span>
                                </template>
                                <b-dropdown-item href="#" v-b-toggle.detail_maintenance variant="primary">
                                  <i class="fas fa-clipboard-list mr-2"></i>Notes
                                </b-dropdown-item>
                              </b-dropdown>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="mt-3">
                        <b-pagination :value="1" :total-rows="50" align="center" />
                      </div>
                    </fieldset>
                  </b-col>
                </b-row>
              </template>
            </b-sidebar>
            <b-sidebar id="detail_maintenance_counter" title="TB. TAVIA 1 - WATER TIGHT DOOR" width="70%" backdrop-variant="dark"
              bg-variant="white" right backdrop shadow>
              <template #default="{hide}">
                <b-row class="m-3">
                  <b-col cols="12">
                    <fieldset class="form-group border p-3">
                      <legend class="w-auto px-2">Running Hours Info</legend>
                      <table class="table table-striped">
                        <tbody>
                          <tr>
                            <th width="20%">RH ME - PORTSIDER</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="28%">820 Hours</td>
                            <th width="20%">RH AE - PORTSIDER</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="28%">770 Hours</td>
                          </tr>
                          <tr>
                            <th width="20%">RH ME - STARBOARD</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="28%">820 Hours</td>
                            <th width="20%">RH AE - STARBOARD</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="28%">770 Hours</td>
                          </tr>
                        </tbody>
                      </table>
                    </fieldset>
                    <fieldset class="form-group border p-3">
                      <legend class="w-auto px-2">Maintenance Info</legend>
                      <table class="table table-striped">
                        <tbody>
                          <tr>
                            <th width="20%">Job Code</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="28%">TV001-01</td>
                            <th width="20%">Job Class</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="28%">CM - CORRECTIVE MAINTENANCE</td>
                          </tr>
                          <tr>
                            <th width="20%">Job Type</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="28%">JT014 - INSPECTION</td>
                            <th width="20%">Job Name</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="28%">WATER TIGHT DOOR - INSPECTION</td>
                          </tr>
                          <tr>
                            <th width="20%">Equipment</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="28%">16.2.1 - WATER TIGHT DOOR</td>
                            <th width="20%">Interval/Frequency</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="28%">300 H</td>
                          </tr>
                          <tr>
                            <th width="20%">Tigger</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="28%">COUNTER (RH)</td>
                            <th width="20%">Status</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="28%"><b-badge variant="warning">OVERDUE</b-badge></td>
                          </tr>
                          <tr>
                            <th width="20%">Total Cost</th>
                            <td width="2%" class="text-center">:</td>
                            <th width="28%">Rp. 122.150.000,-</th>
                            <th width="20%"></th>
                            <td width="2%" class="text-center"></td>
                            <td width="28%"></td>
                          </tr>
                        </tbody>
                      </table>
                    </fieldset>
                    <fieldset class="form-group border p-3">
                      <legend class="w-auto px-2">Equipment Info - ACCOMODATION ROOM</legend>
                      <table class="table table-striped">
                        <tbody>
                          <tr>
                            <th width="23%">Type No</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="25%">-</td>
                            <th width="23%">Serial No</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="25%">-</td>
                          </tr>
                          <tr>
                            <th width="23%">Maker No</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="25%">-</td>
                            <th width="23%">Maker</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="25%">-</td>
                          </tr>
                          <tr>
                            <th width="23%">Drawing No</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="25%">-</td>
                            <th width="23%">Certificate</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="25%">-</td>
                          </tr>
                          <tr>
                            <th width="18%">Additional Information</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="25%">-</td>
                            <th width="18%">Descriptions</th>
                            <td width="2%" class="text-center">:</td>
                            <td width="25%">-</td>
                          </tr>
                        </tbody>
                      </table>
                    </fieldset>
                    <fieldset class="form-group border p-3">
                      <legend class="w-auto px-2">WATER TIGHT DOOR</legend>
                      <table class="table table-striped table-sm">
                        <thead>
                          <tr>
                            <th class="text-center">LAST MAINTENANCE</th>
                            <th class="text-center">TOTAL COST</th>
                            <th class="text-center">DUE DATE / HOURS</th>
                            <th class="text-center">ATTACHMENT</th>
                            <th class="text-center">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center">10/12/2023 - <b>(810 Hours)</b></td>
                            <td class="text-center">Rp. 15.400.000</td>
                            <td class="text-center">840 Hours</td>
                            <td class="text-center">
                              <i class="far fa-file-pdf text-primary"></i>
                            </td>
                            <td class="text-center">
                              <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10"
                                toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                  </span>
                                </template>
                                <b-dropdown-item href="#" v-b-toggle.detail_maintenance variant="primary">
                                  <i class="fas fa-clipboard-list mr-2"></i>Notes
                                </b-dropdown-item>
                              </b-dropdown>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">18/09/2023 - <b>(780 Hours)</b></td>
                            <td class="text-center">Rp. 18.750.000</td>
                            <td class="text-center">810 Hours</td>
                            <td class="text-center">
                              <i class="far fa-file-pdf text-primary"></i>
                            </td>
                            <td class="text-center">
                              <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10"
                                toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                  </span>
                                </template>
                                <b-dropdown-item href="#" v-b-toggle.detail_maintenance variant="primary">
                                  <i class="fas fa-clipboard-list mr-2"></i>Notes
                                </b-dropdown-item>
                              </b-dropdown>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">20/06/2023 - <b>(750 Hours)</b></td>
                            <td class="text-center">Rp. 26.000.000</td>
                            <td class="text-center">780 Hours</td>
                            <td class="text-center">
                              <i class="far fa-file-pdf text-primary"></i>
                            </td>
                            <td class="text-center">
                              <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10"
                                toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                  </span>
                                </template>
                                <b-dropdown-item href="#" v-b-toggle.detail_maintenance variant="primary">
                                  <i class="fas fa-clipboard-list mr-2"></i>Notes
                                </b-dropdown-item>
                              </b-dropdown>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">15/03/2023 - <b>(720 Hours)</b></td>
                            <td class="text-center">Rp. 35.000.000</td>
                            <td class="text-center">750 Hours</td>
                            <td class="text-center">
                              <i class="far fa-file-pdf text-primary"></i>
                            </td>
                            <td class="text-center">
                              <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10"
                                toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                  </span>
                                </template>
                                <b-dropdown-item href="#" v-b-toggle.detail_maintenance variant="primary">
                                  <i class="fas fa-clipboard-list mr-2"></i>Notes
                                </b-dropdown-item>
                              </b-dropdown>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">12/01/2023 - <b>(690 Hours)</b></td>
                            <td class="text-center">Rp. 27.000.000</td>
                            <td class="text-center">720 Hours</td>
                            <td class="text-center">
                              <i class="far fa-file-pdf text-primary"></i>
                            </td>
                            <td class="text-center">
                              <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10"
                                toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                  </span>
                                </template>
                                <b-dropdown-item href="#" v-b-toggle.detail_maintenance variant="primary">
                                  <i class="fas fa-clipboard-list mr-2"></i>Notes
                                </b-dropdown-item>
                              </b-dropdown>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="mt-3">
                        <b-pagination :value="1" :total-rows="50" align="center" />
                      </div>
                    </fieldset>
                  </b-col>
                </b-row>
              </template>
            </b-sidebar>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import {
  xray
}
from '../../config/pluginInit'
export default {
  name: 'DockingHistory',
  mounted() {
    xray.index()
  },
  data () {
    return {
      charts : [
        {
          title: 'Maintenance By Equipment',
          type: 'bar',
          color: '#2D4A9E',
          bodyData: {
            chart: {
              height: 400,
              type: 'bar',
              toolbar: {
                show: false
              }
            },
            plotOptions: {
              bar: {
                horizontal: true
              }
            },
            dataLabels: {
              enabled: false
            },
            series: [{
              data: [17, 21, 16, 23, 32, 15, 52, 26, 19, 37, 43, 44, 12, 51, 34, 34, 32]
            }],
            xaxis: {
              categories: ['Accommodation', 'Hull & Load Line', 'Tanks', 'Mooring & Anchor Equipment', 'Fire Fighting Appliance', 'Life Saving Appliance', 'Navigation Equipment', 'Radio Equipment', 'Main Engine', 'Aux Engine', 'Gearbox', 'Engine & Other Machinery', 'Propulsion & Rudder System', 'Electrical & Component Part', 'Anchor Machinery', 'Cargo Engine', 'Cargo Pump']
            }
          }
        }
      ],
      costDocking:[
        {
          title: 'Cost',
          bodyData: {
            chart: {
              renderTo: 'high-3d-chart',
              type: 'column',
              height: 420,
              options3d: {
                enabled: true,
                alpha: 15,
                beta: 15,
                depth: 50,
                viewDistance: 25
              }
            },
            title: {
              text: 'All Maintenance Cost'
            },
            xAxis: {
                type: 'datetime'
            },
            plotOptions: {
                series: {
                    pointStart: Date.UTC(2023, 6, 1),
                    pointIntervalUnit: 'month'
                }
            },
            series:
            [
              {
                name: "Cost",
                data: [125500000, 162000000, 126000000, 185000000, 105000000, 176000000],
                color: '#F7941D'
              }
            ]
          }
        },
      ],
    }
  }
}
</script>
