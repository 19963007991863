var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("SERVICE & REPAIR DASHBOARD")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-filter",
            modifiers: {
              "collapse-filter": true
            }
          }],
          staticClass: "text-primary float-right",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "visible": "",
            "id": "collapse-filter"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "tag-placeholder": "Category",
            "placeholder": "Fleet Category",
            "label": "text",
            "track-by": "value",
            "options": _vm.fleetCategory,
            "multiple": true,
            "taggable": true
          },
          model: {
            value: _vm.fleetCategorySelect,
            callback: function callback($$v) {
              _vm.fleetCategorySelect = $$v;
            },
            expression: "fleetCategorySelect"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.kapal,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Fleet")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedFleets,
            callback: function callback($$v) {
              _vm.selectedFleets = $$v;
            },
            expression: "selectedFleets"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": _vm.optionsStatus,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Select Status")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.selectedStatus,
            callback: function callback($$v) {
              _vm.selectedStatus = $$v;
            },
            expression: "selectedStatus"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('date-picker', {
          attrs: {
            "lang": _vm.lang,
            "type": "date",
            "range": "",
            "placeholder": "Select date range"
          },
          model: {
            value: _vm.dateRange,
            callback: function callback($$v) {
              _vm.dateRange = $$v;
            },
            expression: "dateRange"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "iq-edit-profile d-flex",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-4 p-0",
          attrs: {
            "active": true,
            "href": "#request",
            "title": "REQUEST & PROGRESS"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-4 p-0",
          attrs: {
            "active": false,
            "href": "#finish",
            "title": "FINISH REPAIR & MAINTENANCE"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-4 p-0",
          attrs: {
            "active": false,
            "href": "#report",
            "title": "REPORT REPAIR & MAINTENANCE"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "iq-edit-list-data"
  }, [_c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "pills-tabContent-2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "request"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("REQUEST REPAIR & MAINTENANCE")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('b-button', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.add-init",
            modifiers: {
              "add-init": true
            }
          }],
          attrs: {
            "block": "",
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.showInitModal = !_vm.showInitModal;
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v("   Add Data ")]), _c('b-modal', {
          attrs: {
            "id": "add-init",
            "size": "xl",
            "title": "ADD REPAIR & MAINTENANCE",
            "header-bg-variant": "light",
            "header-text-variant": "dark",
            "footer-bg-variant": "light",
            "footer-text-variant": "dark"
          },
          scopedSlots: _vm._u([{
            key: "modal-footer",
            fn: function fn(_ref) {
              var ok = _ref.ok,
                cancel = _ref.cancel,
                hide = _ref.hide;
              return [_c('b-row', [_c('b-col', {
                staticClass: "text-center",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-button', {
                staticClass: "iq-bg-danger text-right",
                attrs: {
                  "variant": "none"
                },
                on: {
                  "click": function click($event) {
                    return cancel();
                  }
                }
              }, [_vm._v("Cancel")]), _c('b-button', {
                staticClass: "ml-2 text-right",
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                },
                on: {
                  "click": function click($event) {
                    _vm.showInitModalNext = !_vm.showInitModalNext;
                    _vm.showInitModal = !_vm.showInitModal;
                  }
                }
              }, [_vm._v("Next "), _c('i', {
                staticClass: "fa fa-arrow-right"
              })])], 1)], 1)];
            }
          }]),
          model: {
            value: _vm.showInitModal,
            callback: function callback($$v) {
              _vm.showInitModal = $$v;
            },
            expression: "showInitModal"
          }
        }, [_c('b-row', {
          staticClass: "mb-4"
        }, [_c('b-col', {
          staticClass: "text-right",
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Search fleets..."
          },
          on: {
            "input": _vm.fetchFleets
          },
          model: {
            value: _vm.fleet_params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.fleet_params, "search", $$v);
            },
            expression: "fleet_params.search"
          }
        })], 1)], 1), _vm.loading_fleet ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.fleets.length > 0 ? _c('b-row', _vm._l(_vm.fleets, function (fleet, i) {
          return _c('b-col', {
            key: "fleet-".concat(i),
            staticClass: "text-center py-5 border-top",
            attrs: {
              "md": "3"
            }
          }, [_c('div', {
            staticClass: "custom-control custom-checkbox image-checkbox"
          }, [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: _vm.checkedFleets,
              expression: "checkedFleets"
            }],
            staticClass: "custom-control-input",
            attrs: {
              "id": "ck-fl-".concat(i),
              "type": "checkbox"
            },
            domProps: {
              "value": fleet.id,
              "checked": Array.isArray(_vm.checkedFleets) ? _vm._i(_vm.checkedFleets, fleet.id) > -1 : _vm.checkedFleets
            },
            on: {
              "change": function change($event) {
                var $$a = _vm.checkedFleets,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;
                if (Array.isArray($$a)) {
                  var $$v = fleet.id,
                    $$i = _vm._i($$a, $$v);
                  if ($$el.checked) {
                    $$i < 0 && (_vm.checkedFleets = $$a.concat([$$v]));
                  } else {
                    $$i > -1 && (_vm.checkedFleets = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                  }
                } else {
                  _vm.checkedFleets = $$c;
                }
              }
            }
          }), _c('label', {
            staticClass: "custom-control-label",
            attrs: {
              "for": "ck-fl-".concat(i)
            }
          }, [_c('img', {
            staticClass: "img-fluid",
            attrs: {
              "src": _vm._f("checkPhoto")(fleet.vehicleType.icon.url, require('@src/assets/images/fleet/PusherBarge.png')),
              "alt": "#"
            }
          })]), _c('small', [_c('strong', [_vm._v(_vm._s(fleet.name))])])])]);
        }), 1) : _c('div', {
          staticClass: "text-center my-5"
        }, [_c('p', {
          staticClass: "mb-0 text-muted"
        }, [_vm._v("No fleets data found.")])])]], 2)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('table', {
          staticClass: "table table-striped table-sm"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("FLEET NAME")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("LOCATION")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TANGGAL")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TOTAL REPAIR")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("CLASSIFICATION")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("OFFICE/PIC")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("STATUS")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ACTION")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. TCP 201")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Lubuk Tutung")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("05-Jul-2021")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("11 Repairs")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          staticClass: "mb-1",
          attrs: {
            "variant": "success d-block"
          }
        }, [_vm._v("Minor : 2 Repair")]), _c('b-badge', {
          staticClass: "mb-1",
          attrs: {
            "variant": "info d-block"
          }
        }, [_vm._v("Medium : 6 Repair")]), _c('b-badge', {
          attrs: {
            "variant": "danger d-block"
          }
        }, [_vm._v("Major : 3 Repair")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v(" Asam Asam Office "), _c('br'), _c('small', [_vm._v("PIC : "), _c('strong', [_vm._v("Darmawan")])])]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "info d-block"
          }
        }, [_vm._v("Review")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.review_sr",
            modifiers: {
              "review_sr": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-eye mr-2"
        }), _vm._v("Review ")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.update_sr",
            modifiers: {
              "update_sr": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-pencil-square mr-2"
        }), _vm._v("Update Data ")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.add-job",
            modifiers: {
              "add-job": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-plus mr-2"
        }), _vm._v("Add Job ")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.detail_sr",
            modifiers: {
              "detail_sr": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-info-circle mr-2"
        }), _vm._v("Detail ")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.reject-modal",
            modifiers: {
              "reject-modal": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-remove mr-2"
        }), _vm._v("Reject R & M ")])], 1)], 1)]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("PT. TCP 207")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Celukan Bawang")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("21-Oct-2022")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("12 Repairs")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          staticClass: "mb-1",
          attrs: {
            "variant": "success d-block"
          }
        }, [_vm._v("Minor : 10 Repair")]), _c('b-badge', {
          staticClass: "mb-1",
          attrs: {
            "variant": "info d-block"
          }
        }, [_vm._v("Medium : 2 Repair")]), _c('b-badge', {
          attrs: {
            "variant": "danger d-block"
          }
        }, [_vm._v("Major : 0 Repair")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v(" Sangatta Office "), _c('br'), _c('small', [_vm._v("PIC : "), _c('strong', [_vm._v("Hendra Ridwan")])])]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "info d-block"
          }
        }, [_vm._v("Review")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.review_sr",
            modifiers: {
              "review_sr": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-eye mr-2"
        }), _vm._v("Review ")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.update_sr",
            modifiers: {
              "update_sr": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-pencil-square mr-2"
        }), _vm._v("Update Data ")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.add-job",
            modifiers: {
              "add-job": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-plus mr-2"
        }), _vm._v("Add Job ")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.detail_sr",
            modifiers: {
              "detail_sr": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-info-circle mr-2"
        }), _vm._v("Detail ")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.reject-modal",
            modifiers: {
              "reject-modal": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-remove mr-2"
        }), _vm._v("Reject R & M ")])], 1)], 1)]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("PT. ETI 301")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Sangatta")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("02-Feb-2022")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("8 Repairs")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          staticClass: "mb-1",
          attrs: {
            "variant": "success d-block"
          }
        }, [_vm._v("Minor : 5 Repair")]), _c('b-badge', {
          staticClass: "mb-1",
          attrs: {
            "variant": "info d-block"
          }
        }, [_vm._v("Medium : 2 Repair")]), _c('b-badge', {
          attrs: {
            "variant": "danger d-block"
          }
        }, [_vm._v("Major : 1 Repair")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v(" Kendari Office "), _c('br'), _c('small', [_vm._v("PIC : "), _c('strong', [_vm._v("Nurul Akbar")])])]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          attrs: {
            "variant": "primary d-block"
          }
        }, [_vm._v("Approve")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.approve_sr",
            modifiers: {
              "approve_sr": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-check mr-2"
        }), _vm._v("Approve ")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.add-job",
            modifiers: {
              "add-job": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-plus mr-2"
        }), _vm._v("Add Job ")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.detail_sr",
            modifiers: {
              "detail_sr": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-info-circle mr-2"
        }), _vm._v("Detail ")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.reject-modal",
            modifiers: {
              "reject-modal": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-remove mr-2"
        }), _vm._v("Reject R & M ")])], 1)], 1)])])]), _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "value": 1,
            "total-rows": 50,
            "align": "center"
          }
        })], 1), _c('b-sidebar', {
          attrs: {
            "id": "add-sr",
            "title": "ADD REPAIR & MAINTENANCE - TB. TCP 201",
            "width": "100%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var hide = _ref2.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Engine Information")]), _c('table', {
                staticClass: "table mb-0 table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', [_vm._v("Main Engine 1 - Yanmar 12LAK")]), _c('td', {
                staticClass: "text-right"
              }, [_vm._v("Actual RH : "), _c('strong', [_vm._v("2.800 RH")])])]), _c('tr', [_c('th', [_vm._v("Main Engine 1 - Yanmar 12LAK")]), _c('td', {
                staticClass: "text-right"
              }, [_vm._v("Actual RH : "), _c('strong', [_vm._v("2.800 RH")])])]), _c('tr', [_c('th', [_vm._v("Auxuauxiliary Engine 1 - Cummin KW32")]), _c('td', {
                staticClass: "text-right"
              }, [_vm._v("Actual RH : "), _c('strong', [_vm._v("1.800 RH")])])]), _c('tr', [_c('th', [_vm._v("Auxuauxiliary Engine 1 - Cummin KW32")]), _c('td', {
                staticClass: "text-right"
              }, [_vm._v("Actual RH : "), _c('strong', [_vm._v("1.800 RH")])])])])])])]), _c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Service & Repair Form")]), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "10"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "id": "job_name",
                  "type": "text",
                  "placeholder": "Type Text ..."
                }
              })], 1), _c('b-col', {
                attrs: {
                  "md": "2"
                }
              }, [_c('b-button', {
                attrs: {
                  "variant": "success",
                  "block": ""
                }
              }, [_vm._v("SEARCH")])], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('v-jstree', {
                staticClass: "mt-3",
                attrs: {
                  "data": _vm.tree,
                  "show-checkbox": "",
                  "allow-batch": "",
                  "whole-row": "",
                  "item-events": _vm.itemEvents
                },
                on: {
                  "item-click": _vm.itemClick
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_) {
                    return [_c('div', {
                      staticStyle: {
                        "display": "inherit",
                        "width": "200px"
                      },
                      on: {
                        "click": function click($event) {
                          if (!$event.ctrlKey) return null;
                          return _vm.customItemClickWithCtrl.apply(null, arguments);
                        }
                      }
                    }, [!_.model.loading ? _c('i', {
                      class: _.vm.themeIconClasses,
                      attrs: {
                        "role": "presentation"
                      }
                    }) : _vm._e(), _vm._v(" " + _vm._s(_.model.text) + " ")])];
                  }
                }], null, true)
              })], 1), _c('b-col', {
                attrs: {
                  "md": "8"
                }
              }, [_c('b-row', {
                staticClass: "mt-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "primary"
                }
              }, [_c('div', {
                staticClass: "custom-control custom-control-inline"
              }, [_c('label', [_c('b', [_vm._v("SELECT JOB FROM :")])])]), _c('div', {
                staticClass: "custom-control custom-control-inline custom-radio"
              }, [_c('input', {
                staticClass: "custom-control-input",
                attrs: {
                  "type": "radio",
                  "name": "number",
                  "value": "one",
                  "id": "fleet_equipment"
                }
              }), _c('label', {
                staticClass: "custom-control-label",
                attrs: {
                  "for": "fleet_equipment"
                }
              }, [_vm._v("From Equipment")])]), _c('div', {
                staticClass: "custom-control custom-control-inline custom-radio"
              }, [_c('input', {
                staticClass: "custom-control-input",
                attrs: {
                  "type": "radio",
                  "name": "number",
                  "value": "two",
                  "id": "general_sr"
                }
              }), _c('label', {
                staticClass: "custom-control-label",
                attrs: {
                  "for": "general_sr"
                }
              }, [_vm._v("Custom")])])])], 1), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Detail Information")]), _c('table', {
                staticClass: "table mb-0 table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Lokasi Pekerjaan")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "placeholder": "Type Text ..."
                }
              })], 1), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Tanggal")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date"
                }
              })], 1)]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Site & Group")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "placeholder": "Type Text ..."
                }
              })], 1), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("P.I.C")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "placeholder": "Type Text ..."
                }
              })], 1)])])])]), _c('hr'), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Repair #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Equipment Name",
                  "label-for": "equipmentName"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "placeholder": "Type Text ..."
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Work Item",
                  "label-for": "repairTitle"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "placeholder": "Type Text ..."
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Type R & M *",
                  "label-for": "type_repair"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionsTypeRM,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Type R & M")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedTypeRM,
                  callback: function callback($$v) {
                    _vm.selectedTypeRM = $$v;
                  },
                  expression: "selectedTypeRM"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Department*",
                  "label-for": "department"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionsDepartment,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Department")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedDepartment,
                  callback: function callback($$v) {
                    _vm.selectedDepartment = $$v;
                  },
                  expression: "selectedDepartment"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Category *",
                  "label-for": "category"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.category,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Category")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedCategory,
                  callback: function callback($$v) {
                    _vm.selectedCategory = $$v;
                  },
                  expression: "selectedCategory"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Clasification *",
                  "label-for": "clasification"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.clasification,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Clasification")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedClasification,
                  callback: function callback($$v) {
                    _vm.selectedClasification = $$v;
                  },
                  expression: "selectedClasification"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Cause *",
                  "label-for": "cause"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionsCause,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Cause")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedCause,
                  callback: function callback($$v) {
                    _vm.selectedCause = $$v;
                  },
                  expression: "selectedCause"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Kind of Task *",
                  "label-for": "kind_of_task"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.kindTask,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Kind of Task")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedKindTask,
                  callback: function callback($$v) {
                    _vm.selectedKindTask = $$v;
                  },
                  expression: "selectedKindTask"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Berita Acara",
                  "label-for": "attactment_file"
                }
              }, [_c('b-form-file', {
                attrs: {
                  "plain": "",
                  "id": "attactment_file"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Rencana Mulai *",
                  "label-for": "rencana_mulai"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "rencana_mulai"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Rencana Selesai *",
                  "label-for": "rencana_selesai"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "rencana_selesai"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Estimasi Hari",
                  "label-for": "estimasi_hari"
                }
              }, [_c('b-button', {
                staticClass: "mb-1",
                attrs: {
                  "variant": "outline-primary"
                }
              }, [_vm._v(" Perkiraan Pekerjaan "), _c('b-badge', {
                staticClass: "ml-2",
                attrs: {
                  "variant": "primary"
                }
              }, [_vm._v("7 Hari")])], 1)], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Description",
                  "label-for": "description"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Type Text ..."
                }
              })], 1)], 1)], 1), _c('b-row', {
                staticClass: "mt-2"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-button', {
                staticClass: "pull-right",
                attrs: {
                  "variant": "primary",
                  "size": "sm"
                }
              }, [_c('i', {
                staticClass: "fa fa-trash"
              }), _vm._v(" Delete Repair Job ")])], 1)], 1)], 1), _c('b-button', {
                attrs: {
                  "variant": "success",
                  "block": ""
                }
              }, [_c('i', {
                staticClass: "fa fa-plus"
              }), _vm._v(" ADD JOB REPAIR & MAINTENANCE ")])], 1), _c('b-col', {
                staticClass: "text-right mt-2",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-card-text', [_c('small', {
                staticClass: "text-muted"
              }, [_vm._v("Sign * : "), _c('b-badge', {
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("Mandatory (Must be Input)")])], 1)]), _c('b-button', {
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-save"
              }), _vm._v(" Save ")]), _c('b-button', {
                staticClass: "iq-bg-danger ml-3",
                attrs: {
                  "type": "submit",
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" Reset Form ")])], 1)], 1)], 1)], 1)], 1)])], 1)];
            }
          }]),
          model: {
            value: _vm.showInitModalNext,
            callback: function callback($$v) {
              _vm.showInitModalNext = $$v;
            },
            expression: "showInitModalNext"
          }
        }), _c('b-sidebar', {
          attrs: {
            "id": "add-job",
            "title": "ADD JOB REPAIR & MAINTENANCE - TB. TCP 201",
            "width": "100%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var hide = _ref3.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Engine Information")]), _c('table', {
                staticClass: "table mb-0 table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', [_vm._v("Main Engine 1 - Yanmar 12LAK")]), _c('td', {
                staticClass: "text-right"
              }, [_vm._v("Actual RH : "), _c('strong', [_vm._v("2.800 RH")])])]), _c('tr', [_c('th', [_vm._v("Main Engine 1 - Yanmar 12LAK")]), _c('td', {
                staticClass: "text-right"
              }, [_vm._v("Actual RH : "), _c('strong', [_vm._v("2.800 RH")])])]), _c('tr', [_c('th', [_vm._v("Auxuauxiliary Engine 1 - Cummin KW32")]), _c('td', {
                staticClass: "text-right"
              }, [_vm._v("Actual RH : "), _c('strong', [_vm._v("1.800 RH")])])]), _c('tr', [_c('th', [_vm._v("Auxuauxiliary Engine 1 - Cummin KW32")]), _c('td', {
                staticClass: "text-right"
              }, [_vm._v("Actual RH : "), _c('strong', [_vm._v("1.800 RH")])])])])])])]), _c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Service & Repair Form")]), _c('b-row', [_c('b-col', {
                attrs: {
                  "md": "10"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "id": "job_name",
                  "type": "text",
                  "placeholder": "Type Text ..."
                }
              })], 1), _c('b-col', {
                attrs: {
                  "md": "2"
                }
              }, [_c('b-button', {
                attrs: {
                  "variant": "success",
                  "block": ""
                }
              }, [_vm._v("SEARCH")])], 1), _c('b-col', {
                attrs: {
                  "md": "4"
                }
              }, [_c('v-jstree', {
                staticClass: "mt-3",
                attrs: {
                  "data": _vm.tree,
                  "show-checkbox": "",
                  "allow-batch": "",
                  "whole-row": "",
                  "item-events": _vm.itemEvents
                },
                on: {
                  "item-click": _vm.itemClick
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_) {
                    return [_c('div', {
                      staticStyle: {
                        "display": "inherit",
                        "width": "200px"
                      },
                      on: {
                        "click": function click($event) {
                          if (!$event.ctrlKey) return null;
                          return _vm.customItemClickWithCtrl.apply(null, arguments);
                        }
                      }
                    }, [!_.model.loading ? _c('i', {
                      class: _.vm.themeIconClasses,
                      attrs: {
                        "role": "presentation"
                      }
                    }) : _vm._e(), _vm._v(" " + _vm._s(_.model.text) + " ")])];
                  }
                }], null, true)
              })], 1), _c('b-col', {
                attrs: {
                  "md": "8"
                }
              }, [_c('b-row', {
                staticClass: "mt-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "primary"
                }
              }, [_c('div', {
                staticClass: "custom-control custom-control-inline"
              }, [_c('label', [_c('b', [_vm._v("SELECT JOB FROM :")])])]), _c('div', {
                staticClass: "custom-control custom-control-inline custom-radio"
              }, [_c('input', {
                staticClass: "custom-control-input",
                attrs: {
                  "type": "radio",
                  "name": "number",
                  "value": "one",
                  "id": "fleet_equipment"
                }
              }), _c('label', {
                staticClass: "custom-control-label",
                attrs: {
                  "for": "fleet_equipment"
                }
              }, [_vm._v("From Equipment")])]), _c('div', {
                staticClass: "custom-control custom-control-inline custom-radio"
              }, [_c('input', {
                staticClass: "custom-control-input",
                attrs: {
                  "type": "radio",
                  "name": "number",
                  "value": "two",
                  "id": "general_sr"
                }
              }), _c('label', {
                staticClass: "custom-control-label",
                attrs: {
                  "for": "general_sr"
                }
              }, [_vm._v("Custom")])])])], 1), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Detail Information")]), _c('table', {
                staticClass: "table mb-0 table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Lokasi Pekerjaan")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Morosi")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Tanggal")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("15 Juni 2022")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Office / Site")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Kendari")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("PIC Repair")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Sangatta")])])])])]), _c('div', {
                staticClass: "accordion mb-3",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-1",
                  modifiers: {
                    "job-1-1": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" FUEL INJECTOR - PEBAIKAN KEBOCORAN FUEL INJECTOR "), _c('strong', [_vm._v("(REVIEW)")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-1-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-3"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Equipment Name")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Fuel Injector")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Repair Title")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Perbaikan Kebocoran Fuel Injector")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Type R & M")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Running Maintenance")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Department")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Engine")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Category")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Mechanical")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Classification")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Minor (3 Days)")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Cause")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Material Fatique")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Kind Task")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Repair")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Rencana Mulai")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("12 Januari 2023")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Rencana Selesai (Est)")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("15 Januari 2023")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Berita Acara")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                staticClass: "text-center align-top",
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                staticClass: "align-top",
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Description")]), _c('td', {
                staticClass: "text-center align-top",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                staticClass: "align-top",
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                attrs: {
                  "type": "number"
                }
              }, [_c('li', [_vm._v("- Remove the injector")]), _c('li', [_vm._v(" - Clean up deposit "), _c('ul', [_c('li', [_vm._v("- Clean parts using a brass brush to prevent damage to polished surfaces.")]), _c('li', [_vm._v("- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.")]), _c('li', [_vm._v("- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.")])])]), _c('li', [_vm._v("- Pressure injection test, check if the pressure isn't same with maker standart.")]), _c('li', [_vm._v("- Fit up")])])])])])])])], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-2",
                  modifiers: {
                    "job-1-2": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "warning"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" SENSOR - KALIBRASI SENSOR SONAR "), _c('strong', [_vm._v("(PROGRESS)")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-1-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-3"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Equipment Name")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Sensor")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Repair Title")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Kalibrasi Sensor Sonar")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Type R & M")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Running Maintenance")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Department")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Deck")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Category")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("General Service")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Classification")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Medium (7 Days)")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Cause")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Human Error")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Kind Task")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Renew")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Rencana Mulai")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("20 Januari 2023")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Rencana Selesai (Est)")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("27 Januari 2023")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Estimasi Cost")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_vm._v("Rp. 15.000.000,-")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Berita Acara")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                staticClass: "align-top",
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Description")]), _c('td', {
                staticClass: "align-top text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                staticClass: "align-top",
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                attrs: {
                  "type": "number"
                }
              }, [_c('li', [_vm._v("- Remove the injector")]), _c('li', [_vm._v(" - Clean up deposit "), _c('ul', [_c('li', [_vm._v("- Clean parts using a brass brush to prevent damage to polished surfaces.")]), _c('li', [_vm._v("- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.")]), _c('li', [_vm._v("- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.")])])]), _c('li', [_vm._v("- Pressure injection test, check if the pressure isn't same with maker standart.")]), _c('li', [_vm._v("- Fit up")])])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('table', {
                staticClass: "table table-striped table-sm"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("NAMA LENGKAP")]), _c('th', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("JABATAN")])]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_vm._v("Agung Tri Laksono")]), _c('td', [_vm._v("Manager Engineer")])]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_vm._v("Nurul Akbar")]), _c('td', [_vm._v("Senior Engineer")])]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_vm._v("Hanel Prilian")]), _c('td', [_vm._v("Engineer")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor")]), _c('table', {
                staticClass: "table table-striped table-sm"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3"
              }, [_vm._v("NAMA KONTRAKTOR")]), _c('th', [_vm._v("PIC/EXECUTIVE ENGINEER")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("QUOTATION")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("FINAL QUOTATION")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("BERITA ACARA")])]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_vm._v("PT. Cahaya Marine")]), _c('td', [_vm._v("Dendy Prasetya")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Rp. 30.000.000,-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Rp. 27.500.000,-")]), _c('td', {
                staticClass: "text-center"
              }, [_c('i', {
                staticClass: "fa fa-file-pdf text-primary h4 mr-2"
              }), _c('i', {
                staticClass: "fa fa-file-pdf text-primary h4 mr-2"
              }), _c('i', {
                staticClass: "fa fa-file-pdf text-primary h4 mr-2"
              })])])])])])])], 1)], 1), _c('hr'), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Add Job - #1")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Equipment Name",
                  "label-for": "equipmentName"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "placeholder": "Type Text ..."
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Repair Title",
                  "label-for": "repairTitle"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "placeholder": "Type Text ..."
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Type R & M *",
                  "label-for": "type_repair"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionsTypeRM,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Type R & M")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedTypeRM,
                  callback: function callback($$v) {
                    _vm.selectedTypeRM = $$v;
                  },
                  expression: "selectedTypeRM"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Department*",
                  "label-for": "department"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionsDepartment,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Department")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedDepartment,
                  callback: function callback($$v) {
                    _vm.selectedDepartment = $$v;
                  },
                  expression: "selectedDepartment"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Category *",
                  "label-for": "category"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.category,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Category")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedCategory,
                  callback: function callback($$v) {
                    _vm.selectedCategory = $$v;
                  },
                  expression: "selectedCategory"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Clasification *",
                  "label-for": "clasification"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.clasification,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Clasification")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedClasification,
                  callback: function callback($$v) {
                    _vm.selectedClasification = $$v;
                  },
                  expression: "selectedClasification"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Cause *",
                  "label-for": "cause"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionsCause,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Cause")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedCause,
                  callback: function callback($$v) {
                    _vm.selectedCause = $$v;
                  },
                  expression: "selectedCause"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Kind of Task *",
                  "label-for": "kind_of_task"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.kindTask,
                  "size": "lg"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Kind of Task")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedKindTask,
                  callback: function callback($$v) {
                    _vm.selectedKindTask = $$v;
                  },
                  expression: "selectedKindTask"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Berita Acara",
                  "label-for": "attactment_file"
                }
              }, [_c('b-form-file', {
                attrs: {
                  "plain": "",
                  "id": "attactment_file"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Rencana Mulai *",
                  "label-for": "rencana_mulai"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "rencana_mulai"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Rencana Selesai *",
                  "label-for": "rencana_selesai"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "rencana_selesai"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Estimasi Hari",
                  "label-for": "estimasi_hari"
                }
              }, [_c('b-button', {
                staticClass: "mb-1",
                attrs: {
                  "variant": "outline-primary"
                }
              }, [_vm._v(" Perkiraan Pekerjaan "), _c('b-badge', {
                staticClass: "ml-2",
                attrs: {
                  "variant": "primary"
                }
              }, [_vm._v("7 Hari")])], 1)], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Description",
                  "label-for": "description"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Type Text ..."
                }
              })], 1)], 1)], 1), _c('b-row', {
                staticClass: "mt-2"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-button', {
                staticClass: "pull-right",
                attrs: {
                  "variant": "primary",
                  "size": "sm"
                }
              }, [_c('i', {
                staticClass: "fa fa-trash"
              }), _vm._v(" Delete Repair Job ")])], 1)], 1)], 1), _c('b-button', {
                attrs: {
                  "variant": "success",
                  "block": ""
                }
              }, [_c('i', {
                staticClass: "fa fa-plus"
              }), _vm._v(" ADD JOB REPAIR & MAINTENANCE ")])], 1), _c('b-col', {
                staticClass: "text-right mt-2",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-card-text', [_c('small', {
                staticClass: "text-muted"
              }, [_vm._v("Sign * : "), _c('b-badge', {
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("Mandatory (Must be Input)")])], 1)]), _c('b-button', {
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-save"
              }), _vm._v(" Save ")]), _c('b-button', {
                staticClass: "iq-bg-danger ml-3",
                attrs: {
                  "type": "submit",
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" Reset Form ")])], 1)], 1)], 1)], 1)], 1)])], 1)];
            }
          }])
        }), _c('b-sidebar', {
          attrs: {
            "id": "review_sr",
            "title": "REVIEW REPAIR & MAINTENANCE - TB. ETI 201",
            "width": "70%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var hide = _ref4.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Detail Information")]), _c('table', {
                staticClass: "table mb-0 table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Lokasi Pekerjaan")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Morosi")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Tanggal")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("15 Juni 2022")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Office / Site")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Kendari")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("PIC Repair")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Sangatta")])])])])]), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-1",
                  modifiers: {
                    "job-1-1": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" FUEL INJECTOR - PEBAIKAN KEBOCORAN FUEL INJECTOR ")])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-1-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-3"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Equipment Name")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Fuel Injector")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Repair Title")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Perbaikan Kebocoran Fuel Injector")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Type R & M")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Running Maintenance")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Department")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Engine")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Category")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Mechanical")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Classification")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Minor (3 Days)")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Cause")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Material Fatique")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Kind Task")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Repair")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Rencana Mulai")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("12 Januari 2023")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Rencana Selesai (Est)")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("15 Januari 2023")])]), _c('tr', [_c('td', {
                attrs: {
                  "width": "20%"
                }
              }, [_c('b', [_vm._v("Estimate Cost")])]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "Type Number ..."
                }
              })])]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Estimasi Hari")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("3 Hari")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Berita Acara")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Description")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                staticClass: "align-top",
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                attrs: {
                  "type": "number"
                }
              }, [_c('li', [_vm._v("- Remove the injector")]), _c('li', [_vm._v(" - Clean up deposit "), _c('ul', [_c('li', [_vm._v("- Clean parts using a brass brush to prevent damage to polished surfaces.")]), _c('li', [_vm._v("- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.")]), _c('li', [_vm._v("- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.")])])]), _c('li', [_vm._v("- Pressure injection test, check if the pressure isn't same with maker standart.")]), _c('li', [_vm._v("- Fit up")])])])])])]), _c('hr'), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('table', {
                staticClass: "table table-borderless"
              }, [_c('tbody', [_c('tr', {
                staticClass: "iq-bg-primary"
              }, [_c('th', {
                attrs: {
                  "width": "45%"
                }
              }, [_vm._v("NAMA LENGKAP")]), _c('th', {
                attrs: {
                  "width": "45%"
                }
              }, [_vm._v("JABATAN")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "10%"
                }
              }, [_vm._v("ACTION")])]), _c('tr', [_c('td', [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "size": "sm",
                  "placeholder": "Type Text ..."
                }
              })], 1), _c('td', [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionsJabatan,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('b-button', {
                attrs: {
                  "variant": "danger"
                }
              }, [_c('i', {
                staticClass: "fa fa-trash pr-0"
              })])], 1)]), _c('tr', [_c('td', [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "size": "sm",
                  "placeholder": "Type Text ..."
                }
              })], 1), _c('td', [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionsJabatan,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('b-button', {
                attrs: {
                  "variant": "danger"
                }
              }, [_c('i', {
                staticClass: "fa fa-trash pr-0"
              })])], 1)])])]), _c('b-button', {
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER ")])], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "Type Text ..."
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Type Text ..."
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "Type Number ..."
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "Type Number ..."
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Type Text ..."
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Berita Acara",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('hr'), _c('b-button', {
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR ")])], 1)], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-2",
                  modifiers: {
                    "job-1-2": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" SENSOR - KALIBRASI SENSOR SONAR ")])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-1-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-3"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Equipment Name")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Sensor")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Repair Title")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Kalibrasi Sensor Sonar")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Type R & M")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Running Maintenance")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Department")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Deck")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Category")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("General Service")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Classification")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Medium (7 Days)")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Cause")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Human Error")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Kind Task")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Renew")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Rencana Mulai")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("20 Januari 2023")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Rencana Selesai (Est)")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("27 Januari 2023")])]), _c('tr', [_c('td', {
                attrs: {
                  "width": "20%"
                }
              }, [_c('b', [_vm._v("Estimate Cost")])]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran"
                }
              })])]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Estimasi Hari")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("7 Hari")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Berita Acara")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Description")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                staticClass: "align-top",
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                attrs: {
                  "type": "number"
                }
              }, [_c('li', [_vm._v("- Remove the injector")]), _c('li', [_vm._v(" - Clean up deposit "), _c('ul', [_c('li', [_vm._v("- Clean parts using a brass brush to prevent damage to polished surfaces.")]), _c('li', [_vm._v("- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.")]), _c('li', [_vm._v("- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.")])])]), _c('li', [_vm._v("- Pressure injection test, check if the pressure isn't same with maker standart.")]), _c('li', [_vm._v("- Fit up")])])])])])]), _c('hr'), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Internal Man Power",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('table', {
                staticClass: "table table-borderless"
              }, [_c('tbody', [_c('tr', {
                staticClass: "iq-bg-primary"
              }, [_c('th', {
                attrs: {
                  "width": "45%"
                }
              }, [_vm._v("NAMA LENGKAP")]), _c('th', {
                attrs: {
                  "width": "45%"
                }
              }, [_vm._v("JABATAN")]), _c('th', {
                staticClass: "text-center",
                attrs: {
                  "width": "10%"
                }
              }, [_vm._v("ACTION")])]), _c('tr', [_c('td', [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "size": "sm",
                  "placeholder": "Type Text ..."
                }
              })], 1), _c('td', [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionsJabatan,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('b-button', {
                attrs: {
                  "variant": "danger"
                }
              }, [_c('i', {
                staticClass: "fa fa-trash pr-0"
              })])], 1)]), _c('tr', [_c('td', [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_internal_man_power",
                  "size": "sm",
                  "placeholder": "Type Text ..."
                }
              })], 1), _c('td', [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionsJabatan,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Jabatan")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedJabatan,
                  callback: function callback($$v) {
                    _vm.selectedJabatan = $$v;
                  },
                  expression: "selectedJabatan"
                }
              })], 1), _c('td', {
                staticClass: "text-center"
              }, [_c('b-button', {
                attrs: {
                  "variant": "danger"
                }
              }, [_c('i', {
                staticClass: "fa fa-trash pr-0"
              })])], 1)])])]), _c('b-button', {
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD INTERNAL MAN POWER ")])], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Sub Kontraktor",
                  "label-for": "subkontraktor"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1)], 1), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Nama Kontraktor *",
                  "label-for": "nama_kontraktor"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "nama_kontraktor",
                  "placeholder": "Type Text ..."
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "PIC / Executive Engineer *",
                  "label-for": "executive_engineer"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "id": "executive_engineer",
                  "placeholder": "Type Text ..."
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran",
                  "label-for": "penawaran"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran",
                  "placeholder": "Type Number ..."
                }
              })])])], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Penawaran Nego",
                  "label-for": "penawaran_nego"
                }
              }, [_c('div', {
                staticClass: "input-group"
              }, [_c('div', {
                staticClass: "input-group-prepend"
              }, [_c('span', {
                staticClass: "input-group-text bg-primary",
                attrs: {
                  "id": "inputGroupPrepend2"
                }
              }, [_vm._v("Rp.")])]), _c('input', {
                staticClass: "form-control",
                attrs: {
                  "type": "number",
                  "id": "penawaran_nego",
                  "placeholder": "Type Number ..."
                }
              })])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "2",
                  "placeholder": "Type Text ..."
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Berita Acara",
                  "label-for": "doc_shipyard"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile",
                  "required": ""
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])]), _c('small', {
                staticClass: "text-muted"
              }, [_vm._v(" File Type * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v(".jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf")]), _c('br'), _vm._v(" File Capacity * : "), _c('b-badge', {
                attrs: {
                  "variant": "info"
                }
              }, [_vm._v("3 MB / File")])], 1)])], 1)], 1), _c('hr'), _c('b-button', {
                attrs: {
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-users"
              }), _vm._v(" ADD SUB KONTRAKTOR ")])], 1)], 1)], 1)], 1)]), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-button', {
                staticClass: "mt-2 pull-right",
                attrs: {
                  "variant": "primary"
                }
              }, [_vm._v("REVIEW & SUBMIT")])], 1)], 1)];
            }
          }])
        }), _c('b-sidebar', {
          attrs: {
            "id": "approve_sr",
            "title": "APPROVE REPAIR & MAINTENANCE - TB. ETI 201",
            "width": "70%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref5) {
              var hide = _ref5.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Detail Information")]), _c('table', {
                staticClass: "table mb-0 table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Lokasi Pekerjaan")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Morosi")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Tanggal")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("15 Juni 2022")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Office / Site")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Kendari")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("PIC Repair")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Sangatta")])])])])]), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-1",
                  modifiers: {
                    "job-1-1": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" FUEL INJECTOR - PEBAIKAN KEBOCORAN FUEL INJECTOR ")])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-1-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-3"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Equipment Name")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Fuel Injector")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Repair Title")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Perbaikan Kebocoran Fuel Injector")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Type R & M")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Running Maintenance")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Department")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Engine")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Category")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Mechanical")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Classification")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Minor (3 Days)")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Cause")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Material Fatique")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Kind Task")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Repair")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Rencana Mulai")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("12 Januari 2023")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Rencana Selesai (Est)")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("15 Januari 2023")])]), _c('tr', [_c('td', {
                attrs: {
                  "width": "20%"
                }
              }, [_c('b', [_vm._v("Estimate Cost")])]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp. 15.000.000,-")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Estimasi Hari")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("3 Hari")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Berita Acara")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Description")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                staticClass: "align-top",
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                attrs: {
                  "type": "number"
                }
              }, [_c('li', [_vm._v("- Remove the injector")]), _c('li', [_vm._v(" - Clean up deposit "), _c('ul', [_c('li', [_vm._v("- Clean parts using a brass brush to prevent damage to polished surfaces.")]), _c('li', [_vm._v("- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.")]), _c('li', [_vm._v("- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.")])])]), _c('li', [_vm._v("- Pressure injection test, check if the pressure isn't same with maker standart.")]), _c('li', [_vm._v("- Fit up")])])])])])]), _c('hr'), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('table', {
                staticClass: "table table-striped table-sm"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("NAMA LENGKAP")]), _c('th', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("JABATAN")])]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_vm._v("Agung Tri Laksono")]), _c('td', [_vm._v("Manager Engineer")])]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_vm._v("Nurul Akbar")]), _c('td', [_vm._v("Senior Engineer")])]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_vm._v("Hanel Prilian")]), _c('td', [_vm._v("Engineer")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor")]), _c('table', {
                staticClass: "table table-striped table-sm"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3"
              }, [_vm._v("NAMA KONTRAKTOR")]), _c('th', [_vm._v("PIC/EXECUTIVE ENGINEER")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("QUOTATION")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("FINAL QUOTATION")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("BERITA ACARA")])]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_vm._v("PT. Cahaya Marine")]), _c('td', [_vm._v("Dendy Prasetya")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Rp. 30.000.000,-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Rp. 27.500.000,-")]), _c('td', {
                staticClass: "text-center"
              }, [_c('i', {
                staticClass: "fa fa-file-pdf text-primary h4 mr-2"
              }), _c('i', {
                staticClass: "fa fa-file-pdf text-primary h4 mr-2"
              }), _c('i', {
                staticClass: "fa fa-file-pdf text-primary h4 mr-2"
              })])])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE JOB")])], 1)], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-2",
                  modifiers: {
                    "job-1-2": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" SENSOR - KALIBRASI SENSOR SONAR ")])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-1-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-3"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Equipment Name")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Sensor")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Repair Title")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Kalibrasi Sensor Sonar")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Type R & M")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Running Maintenance")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Department")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Deck")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Category")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("General Service")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Classification")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Medium (7 Days)")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Cause")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Human Error")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Kind Task")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Renew")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Rencana Mulai")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("20 Januari 2023")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Rencana Selesai (Est)")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("27 Januari 2023")])]), _c('tr', [_c('td', {
                attrs: {
                  "width": "20%"
                }
              }, [_c('b', [_vm._v("Estimate Cost")])]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Rp. 18.000.000,-")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Estimasi Hari")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("7 Hari")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Berita Acara")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Description")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                staticClass: "align-top",
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                attrs: {
                  "type": "number"
                }
              }, [_c('li', [_vm._v("- Remove the injector")]), _c('li', [_vm._v(" - Clean up deposit "), _c('ul', [_c('li', [_vm._v("- Clean parts using a brass brush to prevent damage to polished surfaces.")]), _c('li', [_vm._v("- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.")]), _c('li', [_vm._v("- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.")])])]), _c('li', [_vm._v("- Pressure injection test, check if the pressure isn't same with maker standart.")]), _c('li', [_vm._v("- Fit up")])])])])])]), _c('hr'), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('table', {
                staticClass: "table table-striped table-sm"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("NAMA LENGKAP")]), _c('th', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("JABATAN")])]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_vm._v("Agung Tri Laksono")]), _c('td', [_vm._v("Manager Engineer")])]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_vm._v("Nurul Akbar")]), _c('td', [_vm._v("Senior Engineer")])]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_vm._v("Hanel Prilian")]), _c('td', [_vm._v("Engineer")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor")]), _c('table', {
                staticClass: "table table-striped table-sm"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3"
              }, [_vm._v("NAMA KONTRAKTOR")]), _c('th', [_vm._v("PIC/EXECUTIVE ENGINEER")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("QUOTATION")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("FINAL QUOTATION")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("BERITA ACARA")])]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_vm._v("PT. Cahaya Marine")]), _c('td', [_vm._v("Dendy Prasetya")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Rp. 30.000.000,-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Rp. 27.500.000,-")]), _c('td', {
                staticClass: "text-center"
              }, [_c('i', {
                staticClass: "fa fa-file-pdf text-primary h4 mr-2"
              }), _c('i', {
                staticClass: "fa fa-file-pdf text-primary h4 mr-2"
              }), _c('i', {
                staticClass: "fa fa-file-pdf text-primary h4 mr-2"
              })])])])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "success"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("APPROVED")])])]), _c('b-alert', {
                attrs: {
                  "show": true,
                  "variant": "danger"
                }
              }, [_c('div', {
                staticClass: "iq-alert-icon"
              }, [_c('i', {
                staticClass: "fa fa-close"
              })]), _c('div', {
                staticClass: "iq-alert-text"
              }, [_vm._v("Docking Upslip "), _c('b', [_vm._v("REJECT")])])]), _c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.reason_reject",
                  modifiers: {
                    "reason_reject": true
                  }
                }],
                staticClass: "iq-bg-danger float-right mb-3 ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" REJECT")]), _c('b-button', {
                staticClass: "float-right",
                attrs: {
                  "type": "submit",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-thumbs-up"
              }), _vm._v(" APPROVE JOB")])], 1)], 1)], 1)]), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('b-button', {
                staticClass: "mt-2 pull-right",
                attrs: {
                  "variant": "primary"
                }
              }, [_vm._v("APPROVE ALL & SUBMIT")])], 1)], 1)];
            }
          }])
        }), _c('b-sidebar', {
          attrs: {
            "id": "detail_sr",
            "title": "DETAIL REPAIR & MAINTENANCE - TB. ETI 201",
            "width": "70%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref6) {
              var hide = _ref6.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Detail Information")]), _c('table', {
                staticClass: "table mb-0 table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Lokasi Pekerjaan")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Morosi")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Tanggal")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("15 Juni 2022")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Office / Site")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Kendari")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("PIC Repair")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Sangatta")])])])])]), _c('div', {
                staticClass: "accordion mb-3",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-1",
                  modifiers: {
                    "job-1-1": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" FUEL INJECTOR - PEBAIKAN KEBOCORAN FUEL INJECTOR "), _c('strong', [_vm._v("(REVIEW)")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-1-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-3"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Equipment Name")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Fuel Injector")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Repair Title")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Perbaikan Kebocoran Fuel Injector")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Type R & M")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Running Maintenance")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Department")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Engine")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Category")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Mechanical")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Classification")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Minor (3 Days)")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Cause")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Material Fatique")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Kind Task")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Repair")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Rencana Mulai")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("12 Januari 2023")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Rencana Selesai (Est)")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("15 Januari 2023")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Berita Acara")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                staticClass: "align-top",
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Description")]), _c('td', {
                staticClass: "align-top text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                staticClass: "align-top",
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                attrs: {
                  "type": "number"
                }
              }, [_c('li', [_vm._v("- Remove the injector")]), _c('li', [_vm._v(" - Clean up deposit "), _c('ul', [_c('li', [_vm._v("- Clean parts using a brass brush to prevent damage to polished surfaces.")]), _c('li', [_vm._v("- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.")]), _c('li', [_vm._v("- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.")])])]), _c('li', [_vm._v("- Pressure injection test, check if the pressure isn't same with maker standart.")]), _c('li', [_vm._v("- Fit up")])])])])])])])], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-2",
                  modifiers: {
                    "job-1-2": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" SENSOR - KALIBRASI SENSOR SONAR "), _c('strong', [_vm._v("(APPROVE)")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-1-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-3"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Equipment Name")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Sensor")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Repair Title")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Kalibrasi Sensor Sonar")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Type R & M")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Running Maintenance")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Department")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Deck")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Category")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("General Service")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Classification")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Medium (7 Days)")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Cause")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Human Error")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Kind Task")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Renew")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Rencana Mulai")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("20 Januari 2023")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Rencana Selesai (Est)")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("27 Januari 2023")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Berita Acara")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                staticClass: "align-top",
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Description")]), _c('td', {
                staticClass: "align-top text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                staticClass: "align-top",
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                attrs: {
                  "type": "number"
                }
              }, [_c('li', [_vm._v("- Remove the injector")]), _c('li', [_vm._v(" - Clean up deposit "), _c('ul', [_c('li', [_vm._v("- Clean parts using a brass brush to prevent damage to polished surfaces.")]), _c('li', [_vm._v("- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.")]), _c('li', [_vm._v("- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.")])])]), _c('li', [_vm._v("- Pressure injection test, check if the pressure isn't same with maker standart.")]), _c('li', [_vm._v("- Fit up")])])])])])])])], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-3",
                  modifiers: {
                    "job-1-3": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "warning"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" ELECTRICAL - PERBAIAKAN KORSLETING LISTRIK "), _c('strong', [_vm._v("(PROGESS)")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-1-3",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-3"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Equipment Name")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Electrical")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Repair Title")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Perbaiakan Korsleting Ruang Deck")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Type R & M")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Emergency Maintenance")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Department")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Deck")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Category")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Electrical")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Classification")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Minor (3 Days)")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Cause")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Material Fatique")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Kind Task")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Repair")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Rencana Mulai")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("18 Januari 2023")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Rencana Selesai (Est)")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("21 Januari 2023")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Berita Acara")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                staticClass: "align-top",
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Description")]), _c('td', {
                staticClass: "align-top text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                staticClass: "align-top",
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                attrs: {
                  "type": "number"
                }
              }, [_c('li', [_vm._v("- Remove the injector")]), _c('li', [_vm._v(" - Clean up deposit "), _c('ul', [_c('li', [_vm._v("- Clean parts using a brass brush to prevent damage to polished surfaces.")]), _c('li', [_vm._v("- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.")]), _c('li', [_vm._v("- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.")])])]), _c('li', [_vm._v("- Pressure injection test, check if the pressure isn't same with maker standart.")]), _c('li', [_vm._v("- Fit up")])])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('table', {
                staticClass: "table table-striped table-sm"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("NAMA LENGKAP")]), _c('th', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("JABATAN")])]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_vm._v("Agung Tri Laksono")]), _c('td', [_vm._v("Manager Engineer")])]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_vm._v("Nurul Akbar")]), _c('td', [_vm._v("Senior Engineer")])]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_vm._v("Hanel Prilian")]), _c('td', [_vm._v("Engineer")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("REPAIR & MAINTENANCE TIME LINE (PROGESS & DELAY FACTOR)")]), _c('ul', {
                staticClass: "iq-timeline",
                staticStyle: {
                  "height": "500px"
                }
              }, [_c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("24 November 2019 15:45")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Melakukan inspeksi pada kerusakan")]), _c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/6.jpg"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/1.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/4.jpg"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/3.jpg"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("23 November 2019 21:25")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Sparepart tiba kapal")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Tri Leksono")]), _c('br'), _c('small', [_vm._v("22 November 2019 10:12")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Menunggu sparepart tiba")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("21 November 2019 11:21")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Melengkapi dokumen perbaikan")]), _c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])])], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-4",
                  modifiers: {
                    "job-1-4": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" ELECTRICAL - INSTALL LO COOLER AE PORTSIDE "), _c('strong', [_vm._v("(FINISH)")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-1-4",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-3"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Equipment Name")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Electrical")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Repair Title")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Install LO cooler AE portside")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Type R & M")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Plan Maintenance")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Department")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Engine")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Category")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Electrical")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Classification")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Major (21 Days)")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Cause")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Weather")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Kind Task")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Renew")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Rencana Mulai")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("10 Januari 2023")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Rencana Selesai (Est)")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("01 Februari 2023")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Berita Acara")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                staticClass: "align-top",
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Description")]), _c('td', {
                staticClass: "align-top text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                staticClass: "align-top",
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                attrs: {
                  "type": "number"
                }
              }, [_c('li', [_vm._v("- Remove the injector")]), _c('li', [_vm._v(" - Clean up deposit "), _c('ul', [_c('li', [_vm._v("- Clean parts using a brass brush to prevent damage to polished surfaces.")]), _c('li', [_vm._v("- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.")]), _c('li', [_vm._v("- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.")])])]), _c('li', [_vm._v("- Pressure injection test, check if the pressure isn't same with maker standart.")]), _c('li', [_vm._v("- Fit up")])])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('table', {
                staticClass: "table table-striped table-sm"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("NAMA LENGKAP")]), _c('th', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("JABATAN")])]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_vm._v("Agung Tri Laksono")]), _c('td', [_vm._v("Manager Engineer")])]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_vm._v("Nurul Akbar")]), _c('td', [_vm._v("Senior Engineer")])]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_vm._v("Hanel Prilian")]), _c('td', [_vm._v("Engineer")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor")]), _c('table', {
                staticClass: "table table-striped table-sm"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3"
              }, [_vm._v("NAMA KONTRAKTOR")]), _c('th', [_vm._v("PIC/EXECUTIVE ENGINEER")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("QUOTATION")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("FINAL QUOTATION")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("BERITA ACARA")])]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_vm._v("PT. Cahaya Marine")]), _c('td', [_vm._v("Dendy Prasetya")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Rp. 30.000.000,-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Rp. 27.500.000,-")]), _c('td', {
                staticClass: "text-center"
              }, [_c('i', {
                staticClass: "fa fa-file-pdf text-primary h4 mr-2"
              }), _c('i', {
                staticClass: "fa fa-file-pdf text-primary h4 mr-2"
              }), _c('i', {
                staticClass: "fa fa-file-pdf text-primary h4 mr-2"
              })])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("REPAIR & MAINTENANCE TIME LINE (PROGESS & DELAY FACTOR)")]), _c('ul', {
                staticClass: "iq-timeline",
                staticStyle: {
                  "height": "500px"
                }
              }, [_c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("24 November 2019 15:45")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Melakukan inspeksi pada kerusakan")]), _c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/6.jpg"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/1.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/4.jpg"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/3.jpg"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("23 November 2019 21:25")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Sparepart tiba kapal")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Tri Leksono")]), _c('br'), _c('small', [_vm._v("22 November 2019 10:12")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Menunggu sparepart tiba")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("21 November 2019 11:21")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Melengkapi dokumen perbaikan")]), _c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])])], 1)], 1)])], 1)];
            }
          }])
        }), _c('b-sidebar', {
          attrs: {
            "id": "update_sr",
            "title": "UPDATE REPAIR & MAINTENANCE - TB. ETI 201",
            "width": "70%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref7) {
              var hide = _ref7.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "cols": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Detail Information")]), _c('table', {
                staticClass: "table mb-0 table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Lokasi Pekerjaan")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "placeholder": "Type Text ..."
                }
              })], 1), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Tanggal")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date"
                }
              })], 1)]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Office / Site")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "placeholder": "Type Text ..."
                }
              })], 1), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("PIC Repair")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "placeholder": "Type Text ..."
                }
              })], 1)])])])]), _c('div', {
                staticClass: "accordion",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-1",
                  modifiers: {
                    "job-1-1": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" FUEL INJECTOR - PEBAIKAN KEBOCORAN FUEL INJECTOR ")])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-1-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3 mt-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("FUEL INJECTOR - PEBAIKAN KEBOCORAN FUEL INJECTOR")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Equipment Name",
                  "label-for": "equipmentName"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "placeholder": "Type Text ..."
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Repair Title",
                  "label-for": "repairTitle"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "placeholder": "Type Text ..."
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Type R & M *",
                  "label-for": "type_repair"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionsTypeRM,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Type R & M")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedTypeRM,
                  callback: function callback($$v) {
                    _vm.selectedTypeRM = $$v;
                  },
                  expression: "selectedTypeRM"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Department*",
                  "label-for": "department"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionsDepartment,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Department")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedDepartment,
                  callback: function callback($$v) {
                    _vm.selectedDepartment = $$v;
                  },
                  expression: "selectedDepartment"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Category *",
                  "label-for": "category"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.category,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Category")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedCategory,
                  callback: function callback($$v) {
                    _vm.selectedCategory = $$v;
                  },
                  expression: "selectedCategory"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Clasification *",
                  "label-for": "clasification"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.clasification,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Clasification")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedClasification,
                  callback: function callback($$v) {
                    _vm.selectedClasification = $$v;
                  },
                  expression: "selectedClasification"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Cause *",
                  "label-for": "cause"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionsCause,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Cause")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedCause,
                  callback: function callback($$v) {
                    _vm.selectedCause = $$v;
                  },
                  expression: "selectedCause"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Kind of Task *",
                  "label-for": "kind_of_task"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.kindTask,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Kind of Task")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedKindTask,
                  callback: function callback($$v) {
                    _vm.selectedKindTask = $$v;
                  },
                  expression: "selectedKindTask"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Berita Acara",
                  "label-for": "attactment_file"
                }
              }, [_c('b-form-file', {
                attrs: {
                  "plain": "",
                  "id": "attactment_file"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Rencana Mulai *",
                  "label-for": "rencana_mulai"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "rencana_mulai"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Rencana Selesai *",
                  "label-for": "rencana_selesai"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "rencana_selesai"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Estimasi Hari",
                  "label-for": "estimasi_hari"
                }
              }, [_c('b-button', {
                staticClass: "mb-1",
                attrs: {
                  "variant": "outline-primary"
                }
              }, [_vm._v(" Perkiraan Pekerjaan "), _c('b-badge', {
                staticClass: "ml-2",
                attrs: {
                  "variant": "primary"
                }
              }, [_vm._v("7 Hari")])], 1)], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Description",
                  "label-for": "description"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Type Text ..."
                }
              })], 1)], 1)], 1)], 1)])], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-2",
                  modifiers: {
                    "job-1-2": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" SENSOR - KALIBRASI SENSOR SONAR ")])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-1-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3 mt-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("SENSOR - KALIBRASI SENSOR SONAR")]), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Equipment Name",
                  "label-for": "equipmentName"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "placeholder": "Type Text ..."
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Repair Title",
                  "label-for": "repairTitle"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "text",
                  "placeholder": "Type Text ..."
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Type R & M *",
                  "label-for": "type_repair"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionsTypeRM,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Type R & M")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedTypeRM,
                  callback: function callback($$v) {
                    _vm.selectedTypeRM = $$v;
                  },
                  expression: "selectedTypeRM"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Department*",
                  "label-for": "department"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionsDepartment,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Department")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedDepartment,
                  callback: function callback($$v) {
                    _vm.selectedDepartment = $$v;
                  },
                  expression: "selectedDepartment"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Category *",
                  "label-for": "category"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.category,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Category")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedCategory,
                  callback: function callback($$v) {
                    _vm.selectedCategory = $$v;
                  },
                  expression: "selectedCategory"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Clasification *",
                  "label-for": "clasification"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.clasification,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Clasification")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedClasification,
                  callback: function callback($$v) {
                    _vm.selectedClasification = $$v;
                  },
                  expression: "selectedClasification"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Cause *",
                  "label-for": "cause"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionsCause,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Cause")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedCause,
                  callback: function callback($$v) {
                    _vm.selectedCause = $$v;
                  },
                  expression: "selectedCause"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Kind of Task *",
                  "label-for": "kind_of_task"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.kindTask,
                  "size": "sm"
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Kind of Task")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedKindTask,
                  callback: function callback($$v) {
                    _vm.selectedKindTask = $$v;
                  },
                  expression: "selectedKindTask"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Berita Acara",
                  "label-for": "attactment_file"
                }
              }, [_c('b-form-file', {
                attrs: {
                  "plain": "",
                  "id": "attactment_file"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Rencana Mulai *",
                  "label-for": "rencana_mulai"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "rencana_mulai"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Rencana Selesai *",
                  "label-for": "rencana_selesai"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "rencana_selesai"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Estimasi Hari",
                  "label-for": "estimasi_hari"
                }
              }, [_c('b-button', {
                staticClass: "mb-1",
                attrs: {
                  "variant": "outline-primary"
                }
              }, [_vm._v(" Perkiraan Pekerjaan "), _c('b-badge', {
                staticClass: "ml-2",
                attrs: {
                  "variant": "primary"
                }
              }, [_vm._v("7 Hari")])], 1)], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Description",
                  "label-for": "description"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Type Text ..."
                }
              })], 1)], 1)], 1)], 1)])], 1)], 1)]), _c('b-col', {
                staticClass: "text-right",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-card-text', [_c('small', {
                staticClass: "text-muted"
              }, [_vm._v("Sign * : "), _c('b-badge', {
                attrs: {
                  "variant": "warning"
                }
              }, [_vm._v("Mandatory (Must be Input)")])], 1)]), _c('b-button', {
                attrs: {
                  "type": "submit",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-save"
              }), _vm._v(" Save ")]), _c('b-button', {
                staticClass: "iq-bg-danger ml-3",
                attrs: {
                  "type": "submit",
                  "variant": "none"
                }
              }, [_c('i', {
                staticClass: "fa fa-close"
              }), _vm._v(" Reset Form ")])], 1)], 1)];
            }
          }])
        })];
      },
      proxy: true
    }])
  }), _c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("PROGRESS REPAIR & MAINTENANCE")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("FLEET NAME")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("LOCATION")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TANGGAL")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TOTAL R & M")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("CLASSIFICATION")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("OFFICE/PIC")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ACTION")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. SML 301")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Tanjung Bara")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("12-Jun-2021")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("5 Repairs")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          staticClass: "mb-1",
          attrs: {
            "variant": "success d-block"
          }
        }, [_vm._v("Minor : 2 Repair")]), _c('b-badge', {
          staticClass: "mb-1",
          attrs: {
            "variant": "primary d-block"
          }
        }, [_vm._v("Medium : 3 Repair")]), _c('b-badge', {
          attrs: {
            "variant": "danger d-block"
          }
        }, [_vm._v("Major : 0 Repair")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v(" Asam Asam Office "), _c('br'), _c('small', [_vm._v("PIC : "), _c('strong', [_vm._v("Darmawan")])])]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.add-activity",
            modifiers: {
              "add-activity": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-comments mr-2"
        }), _vm._v("Update Activity ")]), _c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.add-job",
            modifiers: {
              "add-job": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-plus mr-2"
        }), _vm._v("Add Job ")])], 1)], 1)])])]), _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "value": 1,
            "total-rows": 50,
            "align": "center"
          }
        })], 1), _c('b-sidebar', {
          attrs: {
            "id": "add-activity",
            "title": "UPDATE REPAIR & MAINTENANCE TIME LINE (PROGESS & DELAY FACTOR)",
            "width": "100%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref8) {
              var hide = _ref8.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "7"
                }
              }, [_c('table', {
                staticClass: "table table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Equipment Name")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Fuel Injector")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Repair Title")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Perbaikan Kebocoran Fuel Injector")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Type R & M")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Running Maintenance")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Department")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Engine")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Category")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Mechanical")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Classification")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Minor (3 Days)")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Cause")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Material Fatique")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Kind Task")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Repair")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Rencana Mulai")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("12 Januari 2023")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Rencana Selesai")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("15 Januari 2023")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Berita Acara")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                staticClass: "align-top",
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Description")]), _c('td', {
                staticClass: "align-top text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                staticClass: "align-top",
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                attrs: {
                  "type": "number"
                }
              }, [_c('li', [_vm._v("- Remove the injector")]), _c('li', [_vm._v(" - Clean up deposit "), _c('ul', [_c('li', [_vm._v("- Clean parts using a brass brush to prevent damage to polished surfaces.")]), _c('li', [_vm._v("- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.")]), _c('li', [_vm._v("- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.")])])]), _c('li', [_vm._v("- Pressure injection test, check if the pressure isn't same with maker standart.")]), _c('li', [_vm._v("- Fit up")])])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('table', {
                staticClass: "table table-striped table-sm"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("NAMA LENGKAP")]), _c('th', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("JABATAN")])]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_vm._v("Agung Tri Laksono")]), _c('td', [_vm._v("Manager Engineer")])]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_vm._v("Nurul Akbar")]), _c('td', [_vm._v("Senior Engineer")])]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_vm._v("Hanel Prilian")]), _c('td', [_vm._v("Engineer")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor")]), _c('table', {
                staticClass: "table table-striped table-sm"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3"
              }, [_vm._v("NAMA KONTRAKTOR")]), _c('th', [_vm._v("PIC/EXECUTIVE ENGINEER")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("QUOTATION")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("FINAL QUOTATION")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("BERITA ACARA")])]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_vm._v("PT. Cahaya Marine")]), _c('td', [_vm._v("Dendy Prasetya")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Rp. 30.000.000,-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Rp. 27.500.000,-")]), _c('td', {
                staticClass: "text-center"
              }, [_c('i', {
                staticClass: "fa fa-file-pdf text-primary h4 mr-2"
              }), _c('i', {
                staticClass: "fa fa-file-pdf text-primary h4 mr-2"
              }), _c('i', {
                staticClass: "fa fa-file-pdf text-primary h4 mr-2"
              })])])])])])]), _c('b-col', {
                attrs: {
                  "md": "5"
                }
              }, [_c('tab-content-item', {
                attrs: {
                  "active": true,
                  "id": "power"
                }
              }, [_c('tab-nav', {
                attrs: {
                  "tabs": true
                }
              }, [_c('tab-nav-items', {
                attrs: {
                  "active": true,
                  "href": "#activity",
                  "title": "Activity"
                }
              }), _c('tab-nav-items', {
                attrs: {
                  "active": false,
                  "href": "#logs",
                  "title": "Logs"
                }
              })], 1), _c('div', {
                staticClass: "tab-content",
                attrs: {
                  "id": "myTabContent"
                }
              }, [_c('tab-content-item', {
                attrs: {
                  "active": true,
                  "id": "activity"
                }
              }, [_c('b-row', [_c('b-col', {
                staticClass: "mb-5",
                attrs: {
                  "md": "12"
                }
              }, [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Select Repair & Maintenance",
                  "label-for": "repair_maintenance"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionsRepairMaintenance
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Repair & Maintenance")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedRepairMaintenance,
                  callback: function callback($$v) {
                    _vm.selectedRepairMaintenance = $$v;
                  },
                  expression: "selectedRepairMaintenance"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Select Activity",
                  "label-for": "activity"
                }
              }, [_c('b-form-select', {
                attrs: {
                  "plain": "",
                  "options": _vm.optionsActivity
                },
                scopedSlots: _vm._u([{
                  key: "first",
                  fn: function fn() {
                    return [_c('b-form-select-option', {
                      attrs: {
                        "value": null
                      }
                    }, [_vm._v("Select Activity")])];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.selectedActivity,
                  callback: function callback($$v) {
                    _vm.selectedActivity = $$v;
                  },
                  expression: "selectedActivity"
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Tanggal Aktual *",
                  "label-for": "date"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "type": "date",
                  "id": "start"
                }
              })], 1)], 1), _c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Berita Acara",
                  "label-for": "berita_acara"
                }
              }, [_c('div', {
                staticClass: "custom-file"
              }, [_c('input', {
                staticClass: "custom-file-input",
                attrs: {
                  "type": "file",
                  "id": "validatedCustomFile"
                }
              }), _c('label', {
                staticClass: "custom-file-label",
                attrs: {
                  "for": "validatedCustomFile"
                }
              }, [_vm._v("Browse file...")])])])], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Notes",
                  "label-for": "notes"
                }
              }, [_c('b-form-textarea', {
                attrs: {
                  "id": "description",
                  "rows": "3",
                  "placeholder": "Type Text ..."
                }
              })], 1)], 1)], 1), _c('b-form-row', [_c('b-col', [_c('b-form-group', {
                attrs: {
                  "label": "Is Finish Job ?",
                  "label-for": "internal_man_power"
                }
              }, [_vm._l(_vm.text, function (item, index) {
                return [_c('div', {
                  key: index,
                  staticClass: "custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color"
                }, [_c('div', {
                  staticClass: "custom-switch-inner"
                }, [_c('p', {
                  staticClass: "mb-0"
                }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('input', {
                  directives: [{
                    name: "model",
                    rawName: "v-model",
                    value: item.checked,
                    expression: "item.checked"
                  }],
                  staticClass: "custom-control-input",
                  class: 'bg-' + item.class,
                  attrs: {
                    "type": "checkbox",
                    "id": 'customSwitch-11' + index
                  },
                  domProps: {
                    "checked": Array.isArray(item.checked) ? _vm._i(item.checked, null) > -1 : item.checked
                  },
                  on: {
                    "change": function change($event) {
                      var $$a = item.checked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v);
                        if ($$el.checked) {
                          $$i < 0 && _vm.$set(item, "checked", $$a.concat([$$v]));
                        } else {
                          $$i > -1 && _vm.$set(item, "checked", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                        }
                      } else {
                        _vm.$set(item, "checked", $$c);
                      }
                    }
                  }
                }), _c('label', {
                  staticClass: "custom-control-label",
                  attrs: {
                    "for": 'customSwitch-11' + index,
                    "data-on-label": item.activeLabel,
                    "data-off-label": item.inactiveLabel
                  }
                })])])];
              })], 2)], 1), _c('b-col', [_c('b-button', {
                staticClass: "pull-right",
                attrs: {
                  "type": "submit",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-save"
              }), _vm._v(" Submit Data")])], 1)], 1)], 1), _c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('ul', {
                staticClass: "iq-timeline",
                staticStyle: {
                  "height": "100%"
                }
              }, [_c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("24 November 2019 15:45")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Melakukan inspeksi pada kerusakan")]), _c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/6.jpg"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/1.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/4.jpg"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/3.jpg"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("23 November 2019 21:25")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Barang tiba di galangan")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Tri Leksono")]), _c('br'), _c('small', [_vm._v("22 November 2019 10:12")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Menunggu sparepart tiba")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("21 November 2019 11:21")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Melengkapi dokumen perbaikan")]), _c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])], 1)], 1), _c('tab-content-item', {
                attrs: {
                  "active": false,
                  "id": "logs"
                }
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('ul', {
                staticClass: "iq-timeline",
                staticStyle: {
                  "height": "100%"
                }
              }, [_c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("19 Oktober 2019 21:25")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Service Repair Finish")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("18 Oktober 2019 15:41")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Update Stage")]), _c('b-button', {
                staticClass: "mr-1",
                attrs: {
                  "variant": "success"
                }
              }, [_vm._v("REVIEW & APPROVE")]), _c('i', {
                staticClass: "fa fa-arrow-circle-right",
                attrs: {
                  "aria-hidden": "true"
                }
              }), _c('b-button', {
                staticClass: "ml-1",
                attrs: {
                  "variant": "danger"
                }
              }, [_vm._v("REJECT")])], 1)]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("System")]), _c('br'), _c('small', [_vm._v("17 Oktober 2019 18:30")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("System mengirim email ke : "), _c('b-badge', {
                staticClass: "border border-primary text-primary ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_vm._v("aguuungtriilaksono@gmail.com")]), _c('b-badge', {
                staticClass: "border border-primary text-primary ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_vm._v("nurulakbar93@gmail.com")])], 1)])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("17 Oktober 2019 18:30")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Update Stage")]), _c('b-button', {
                staticClass: "mr-1",
                attrs: {
                  "variant": "primary"
                }
              }, [_vm._v("REQUEST")]), _c('i', {
                staticClass: "fa fa-arrow-circle-right",
                attrs: {
                  "aria-hidden": "true"
                }
              }), _c('b-button', {
                staticClass: "ml-1",
                attrs: {
                  "variant": "success"
                }
              }, [_vm._v("REVIEW & APPROVE")])], 1)]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("System")]), _c('br'), _c('small', [_vm._v("15 Oktober 2019 16:00")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("System mengirim email ke : "), _c('b-badge', {
                staticClass: "border border-primary text-primary ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_vm._v("aguuungtriilaksono@gmail.com")]), _c('b-badge', {
                staticClass: "border border-primary text-primary ml-1",
                attrs: {
                  "variant": "none"
                }
              }, [_vm._v("nurulakbar93@gmail.com")])], 1)])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("15 Oktober 2019 20:24")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Mengupdate data service repair")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("15 Oktober 2019 16:00")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Membuat data service repair")])])])])])], 1)], 1)], 1)], 1)], 1)], 1)];
            }
          }])
        })];
      },
      proxy: true
    }])
  })], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "finish"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("HISTORY SERVICE REPAIR")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("FLEET NAME")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TOTAL COST")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TOTAL REPAIR")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("STATUS")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("MINOR")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("MEDIUM")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("MAJOR")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ACTION")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. TCP 201")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Rp. 1.238.203.200,-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("53 Jobs ")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          staticClass: "mb-1",
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("On Time : 45 Jobs")]), _c('br'), _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("Delay : 23 Jobs")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("15 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("31 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("22 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "sm",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_c('router-link', {
          attrs: {
            "to": "/templates/service-repair-history"
          }
        }, [_c('i', {
          staticClass: "fa fa-ship mr-2"
        }), _vm._v("Detail History ")])], 1)], 1)], 1)]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. ETI 203")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Rp. 1.375.123.180,-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("36 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          staticClass: "mb-1",
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("On Time : 32 Jobs")]), _c('br'), _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("Delay : 4 Jobs")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("15 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("12 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("16 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "sm",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_c('router-link', {
          attrs: {
            "to": "/templates/service-repair-history"
          }
        }, [_c('i', {
          staticClass: "fa fa-ship mr-2"
        }), _vm._v("Detail History ")])], 1)], 1)], 1)]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("TB. SML 201")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Rp. 1.387.384.000,-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("72 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          staticClass: "mb-1",
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("On Time : 52 Jobs")]), _c('br'), _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("Delay : 20 Jobs")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("21 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("17 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("19 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "sm",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_c('router-link', {
          attrs: {
            "to": "/templates/service-repair-history"
          }
        }, [_c('i', {
          staticClass: "fa fa-ship mr-2"
        }), _vm._v("Detail History ")])], 1)], 1)], 1)]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("BG. ETI ")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Rp. 1.387.384.000,-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("72 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          staticClass: "mb-1",
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("On Time : 52 Jobs")]), _c('br'), _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("Delay : 20 Jobs")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("21 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("17 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("19 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "sm",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_c('router-link', {
          attrs: {
            "to": "/templates/service-repair-history"
          }
        }, [_c('i', {
          staticClass: "fa fa-ship mr-2"
        }), _vm._v("Detail History ")])], 1)], 1)], 1)])])]), _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "value": 1,
            "total-rows": 50,
            "align": "center"
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "report"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-primary rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-primary"
        }, [_c('i', {
          staticClass: "fa fa-ship"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h2', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_vm._v("23")])]), _c('h5', {}, [_vm._v("Fleet Repairs")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-warning rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-warning"
        }, [_c('i', {
          staticClass: "fas fa-dollar-sign"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h3', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_vm._v("3.552.017.210,-")])]), _c('h5', {}, [_vm._v("Total Cost All Fleet")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-danger rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-danger"
        }, [_c('i', {
          staticClass: "fa fa-tools"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h2', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_vm._v("432")])]), _c('h5', {}, [_vm._v("Total Repairs All Fleet")])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "3"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-card-block iq-card-stretch iq-card-height",
      "body-class": "iq-bg-info rounded"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "rounded-circle iq-card-icon bg-info"
        }, [_c('i', {
          staticClass: "fa fa-spinner"
        })]), _c('div', {
          staticClass: "text-right"
        }, [_c('h2', {
          staticClass: "mb-0"
        }, [_c('span', {
          staticClass: "counter"
        }, [_vm._v("36")])]), _c('h5', {}, [_vm._v("On Progress Job")])])])];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-row', [_vm._l(_vm.classification, function (item, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "lg": "6"
      }
    }, [_c('iq-card', {
      scopedSlots: _vm._u([{
        key: "headerTitle",
        fn: function fn() {
          return [_c('h4', [_vm._v(_vm._s(item.title))])];
        },
        proxy: true
      }, {
        key: "body",
        fn: function fn() {
          return [_c('HighChart', {
            ref: item.type,
            refInFor: true,
            attrs: {
              "option": item.bodyData
            }
          })];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), _vm._l(_vm.cause, function (item, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "lg": "6"
      }
    }, [_c('iq-card', {
      scopedSlots: _vm._u([{
        key: "headerTitle",
        fn: function fn() {
          return [_c('h4', [_vm._v(_vm._s(item.title))])];
        },
        proxy: true
      }, {
        key: "body",
        fn: function fn() {
          return [_c('HighChart', {
            ref: item.type,
            refInFor: true,
            attrs: {
              "option": item.bodyData
            }
          })];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), _vm._l(_vm.typeRM, function (item, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "lg": "6"
      }
    }, [_c('iq-card', {
      scopedSlots: _vm._u([{
        key: "headerTitle",
        fn: function fn() {
          return [_c('h4', [_vm._v(_vm._s(item.title))])];
        },
        proxy: true
      }, {
        key: "body",
        fn: function fn() {
          return [_c('HighChart', {
            ref: item.type,
            refInFor: true,
            attrs: {
              "option": item.bodyData
            }
          })];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), _vm._l(_vm.costRM, function (item, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "lg": "6"
      }
    }, [_c('iq-card', {
      scopedSlots: _vm._u([{
        key: "headerTitle",
        fn: function fn() {
          return [_c('h4', [_vm._v(_vm._s(item.title))])];
        },
        proxy: true
      }, {
        key: "body",
        fn: function fn() {
          return [_c('HighChart', {
            ref: item.type,
            refInFor: true,
            attrs: {
              "option": item.bodyData
            }
          })];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), _vm._l(_vm.equipmentRM, function (item, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "lg": "6"
      }
    }, [_c('iq-card', {
      scopedSlots: _vm._u([{
        key: "headerTitle",
        fn: function fn() {
          return [_c('h4', [_vm._v(_vm._s(item.title))])];
        },
        proxy: true
      }, {
        key: "body",
        fn: function fn() {
          return [_c('HighChart', {
            ref: item.type,
            refInFor: true,
            attrs: {
              "option": item.bodyData
            }
          })];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), _vm._l(_vm.categoryRM, function (item, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "lg": "6"
      }
    }, [_c('iq-card', {
      scopedSlots: _vm._u([{
        key: "headerTitle",
        fn: function fn() {
          return [_c('h4', [_vm._v(_vm._s(item.title))])];
        },
        proxy: true
      }, {
        key: "body",
        fn: function fn() {
          return [_c('HighChart', {
            ref: item.type,
            refInFor: true,
            attrs: {
              "option": item.bodyData
            }
          })];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Matriks Realisasi R & M")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("Group")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Rencana R & M (jam)")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Aktual R&M (jam)")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Realisasi (%)")])])]), _c('tbody', [_c('tr', [_c('th', [_vm._v("Transhipment")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("840 Jam")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("216 Jam")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("26%")])]), _c('tr', [_c('th', [_vm._v("Long Hauling")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("48 Jam")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("48 Jam")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("0%")])]), _c('tr', [_c('th', [_vm._v("Fuel Storage")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("168 Jam")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("72 Jam")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("43%")])]), _c('tr', [_c('th', [_vm._v("Assist Tug")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("12 Jam")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("9 Jam")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("5%")])]), _c('tr', [_c('th', [_vm._v("TOTAL")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("1056 Jam")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("336 Jam")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("32%")])])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Matriks Prosentase R & M")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("Group")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Armada")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Jam Operasi")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Jam R & M")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Persentase R & M")])])]), _c('tbody', [_c('tr', [_c('th', [_vm._v("Transhipment")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("17")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("12.648 Jam")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("216 Jam")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("1.71%")])]), _c('tr', [_c('th', [_vm._v("Long Hauling")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("1")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("774 Jam")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("72 Jam")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("968%")])]), _c('tr', [_c('th', [_vm._v("Fuel Storage")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("5")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("168 Jam")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("72 Jam")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("43%")])]), _c('tr', [_c('th', [_vm._v("Assist Tug")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("7")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("12 Jam")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("9 Jam")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("5%")])]), _c('tr', [_c('th', [_vm._v("TOTAL")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("3")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("1056 Jam")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("336 Jam")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("32%")])])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Type R & M")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('table', {
          staticClass: "table table-striped table-sm"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("PM (Job)")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("RM (Job)")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("PB (Job)")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("EB (Job)")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("45 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("18 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("5 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("3 Jobs")])])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Status R & M")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('table', {
          staticClass: "table table-striped table-sm"
        }, [_c('tbody', [_c('tr', [_c('th', [_vm._v("OPEN (On Progress Job)")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("18 Jobs")])]), _c('tr', [_c('th', [_vm._v("CLOSE (Finish Job)")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("45 Jobs")])])])])];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h5', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Vessel Type R & M")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('table', {
          staticClass: "table table-striped"
        }, [_c('tbody', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("Fleet Type")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Total Job")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Minor")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Medium")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Major")])]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("Tug Boat")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("76 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("51 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("34 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("12 Jobs")])]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("Barge")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("84 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("15 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("53 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("9 Jobs")])]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("Pusher Tug")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("67 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("43 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("15 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("4 Jobs")])]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("Pusher Barge")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("91 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("56 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("31 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("14 Jobs")])])])])];
      },
      proxy: true
    }])
  })], 1)], 2)], 1)], 1)])])], 1), _c('b-modal', {
    attrs: {
      "id": "reject-modal",
      "size": "l",
      "title": "REJECT REPAIR & MAINTENANCE",
      "ok-title": "Save Changes",
      "cancel-title": "Close"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Select Repair List",
      "label-for": "repair_list"
    }
  }, [_c('b-form-select', {
    attrs: {
      "plain": ""
    }
  }, [[_c('b-form-select-option', [_vm._v("Select Reason Group")]), _c('b-form-select-option', [_vm._v("Reason 1")]), _c('b-form-select-option', [_vm._v("Reason 2")]), _c('b-form-select-option', [_vm._v("Reason 3")])]], 2)], 1), _c('b-form-group', {
    attrs: {
      "label": "Reason",
      "label-for": "reason"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "reason",
      "rows": "2",
      "placeholder": "Type Text ..."
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }