var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("COLLECTION FORMS")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center gap-4 float-right"
        }, [_vm.addPermission ? _c('a', {
          staticClass: "text-primary mr-4",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          },
          on: {
            "click": _vm.addCollectionForm
          }
        }, [_c('i', {
          staticClass: "fa fa-plus mr-2"
        }), _c('span', [_vm._v("Create Data")])]) : _vm._e()])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$listsCollectionFo, _vm$listsCollectionFo2, _vm$listsCollectionFo3, _vm$listsCollectionFo4;
        return [_c('b-row', {
          staticClass: "mb-4"
        }, [_c('b-col', {
          attrs: {
            "md": "4"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "search",
            "label-for": "search"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "Search code/name...",
            "name": "search"
          },
          model: {
            value: _vm.params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "search", $$v);
            },
            expression: "params.search"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Filter",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "primary",
            "type": "button"
          },
          on: {
            "click": _vm.getListCollectionForm
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1)], 1), _vm.loading ? _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1)])], 1) : _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_vm.loading ? _c('div', {
          staticClass: "alert alert-info"
        }, [_vm._v("Loading...")]) : _vm._e(), _c('iq-card', [((_vm$listsCollectionFo = _vm.listsCollectionForm) === null || _vm$listsCollectionFo === void 0 ? void 0 : (_vm$listsCollectionFo2 = _vm$listsCollectionFo.data) === null || _vm$listsCollectionFo2 === void 0 ? void 0 : _vm$listsCollectionFo2.length) > 0 ? _c('div', {
          staticClass: "overflow-auto d-flex"
        }, [_c('table', {
          staticClass: "table table-striped table-bordered"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center align-middle",
          staticStyle: {
            "width": "20px"
          }
        }, [_vm._v("No")]), _c('th', {
          staticClass: "text-left align-middle text-truncate"
        }, [_vm._v("Code")]), _c('th', {
          staticClass: "text-left align-middle text-truncate"
        }, [_vm._v("Name")]), _c('th', {
          staticClass: "text-left align-middle text-truncate"
        }, [_vm._v("Title")]), _c('th', {
          staticClass: "text-left align-middle text-truncate",
          staticStyle: {
            "width": "200px"
          }
        }, [_vm._v("Description")]), _c('th', {
          staticClass: "text-left align-middle text-truncate"
        }, [_vm._v("Question Type")]), _c('th', {
          staticClass: "text-left align-middle text-truncate"
        }, [_vm._v("Using Remarks")]), _c('th', {
          staticClass: "text-left align-middle text-truncate"
        }, [_vm._v("Status")]), _c('th', {
          staticClass: "text-center align-middle",
          staticStyle: {
            "width": "20px"
          }
        }, [_vm._v("#")])])]), _c('tbody', _vm._l(_vm.listsCollectionForm.data, function (collectionForm, collectionFormIndex) {
          var _collectionForm$title, _collectionForm$quest;
          return _c('tr', {
            key: collectionFormIndex
          }, [_c('td', [_vm._v(_vm._s(collectionFormIndex + 1))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(collectionForm === null || collectionForm === void 0 ? void 0 : collectionForm.code))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(collectionForm === null || collectionForm === void 0 ? void 0 : collectionForm.name))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s((_collectionForm$title = collectionForm === null || collectionForm === void 0 ? void 0 : collectionForm.title) !== null && _collectionForm$title !== void 0 ? _collectionForm$title : '-'))]), _c('td', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.top",
              value: collectionForm === null || collectionForm === void 0 ? void 0 : collectionForm.description,
              expression: "collectionForm?.description",
              modifiers: {
                "top": true
              }
            }],
            staticClass: "text-truncate",
            staticStyle: {
              "max-width": "200px"
            }
          }, [_vm._v(_vm._s(collectionForm === null || collectionForm === void 0 ? void 0 : collectionForm.description))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(collectionForm === null || collectionForm === void 0 ? void 0 : (_collectionForm$quest = collectionForm.question_type) === null || _collectionForm$quest === void 0 ? void 0 : _collectionForm$quest.name))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(collectionForm !== null && collectionForm !== void 0 && collectionForm.using_remarks ? 'Yes' : "No"))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s(collectionForm !== null && collectionForm !== void 0 && collectionForm.status ? 'Active' : "Inactive"))]), _vm.updatePermission || _vm.deletePermission ? _c('td', [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('i', {
            staticClass: "fa fa-pen text-primary mr-3",
            staticStyle: {
              "cursor": "pointer"
            },
            on: {
              "click": function click($event) {
                return _vm.editCollectionForm(collectionForm === null || collectionForm === void 0 ? void 0 : collectionForm.id);
              }
            }
          }), _c('i', {
            staticClass: "fa fa-trash text-danger",
            staticStyle: {
              "cursor": "pointer"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeleteForm(collectionForm);
              }
            }
          })])]) : _vm._e()]);
        }), 0)])]) : _vm._e(), ((_vm$listsCollectionFo3 = _vm.listsCollectionForm) === null || _vm$listsCollectionFo3 === void 0 ? void 0 : (_vm$listsCollectionFo4 = _vm$listsCollectionFo3.data) === null || _vm$listsCollectionFo4 === void 0 ? void 0 : _vm$listsCollectionFo4.length) > 0 ? _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.listsCollectionForm.meta.totalItem,
            "per-page": _vm.listsCollectionForm.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getListCollectionForm
          },
          model: {
            value: _vm.params.page,
            callback: function callback($$v) {
              _vm.$set(_vm.params, "page", $$v);
            },
            expression: "params.page"
          }
        })], 1) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("No forms data found.")])])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "add-collection-form",
      "title": "".concat(_vm.isEdit ? 'EDIT' : 'ADD', " FORMS"),
      "width": "100%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_c('b-row', {
          staticClass: "m-3"
        }, [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('fieldset', {
          staticClass: "form-group border p-3"
        }, [_c('legend', {
          staticClass: "w-auto px-2 text-primary"
        }, [_vm._v(_vm._s(_vm.isEdit ? 'Update' : 'Create') + " Sections and Questions")]), _c('b-row', {
          staticClass: "mt-2"
        }, [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('FormCollectionForm', {
          attrs: {
            "is-edit": _vm.isEdit,
            "edit-id": _vm.editId,
            "refreshData": _vm.refreshData,
            "closeModalForm": _vm.closeModalForm
          }
        })], 1)], 1)], 1)])], 1)];
      }
    }]),
    model: {
      value: _vm.showAddCollectionForm,
      callback: function callback($$v) {
        _vm.showAddCollectionForm = $$v;
      },
      expression: "showAddCollectionForm"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }