import axios from './auth'

const PICMOVEMENT_BASEURL = `${process.env.VUE_APP_API_OPS_BASE_URL}/vehicle-pic`

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async createPICMovement({ commit }, payload = {}) {
    const url = `${PICMOVEMENT_BASEURL}/${payload.url}`

    const method = 'post'
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    const config = {
      url,
      method,
      headers,
      data: payload.data,
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data,
      }
    }
  },
  async approvalPICMovement({ commit }, payload = {}) {
    const url = `${PICMOVEMENT_BASEURL}/${payload.url}/${payload.id}`

    const method = 'put'
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
    const config = {
      url,
      method,
      headers,
      data: payload.data
    }

    try {
      const response = await axios(config)
      let data = response.data.data
      return {
        status: 'success',
        data,
      }
    } catch (e) {
      return {
        status: e.response.status,
        data: e.response.data,
      }
    }
  },
  async getListPicMovement({ commit }, params = {}) {
    const url = `${PICMOVEMENT_BASEURL}`

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params
    };

    try {
      const response = await axios(config);
      let data = response.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async getPicMovementDetail({ commit }, params = {}) {
    const url = `${PICMOVEMENT_BASEURL}/${params.id}`

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
    };

    try {
      const response = await axios(config);
      let data = response.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async getPicMovementByVehicle({ commit }, params = {}) {
    const url = `${PICMOVEMENT_BASEURL}/vehicle/${params.vehicleId}`

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
    };

    try {
      const response = await axios(config);
      let data = response.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async getVehicleByPic({ commit }, params = {}) {
    const url = `${PICMOVEMENT_BASEURL}/pic/${params.userId}`

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params:params.params
    };

    try {
      const response = await axios(config);
      let data = response.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
}