<template>
  <div class="border p-3 rounded-lg mb-3">
    <div class="pb-0 d-flex gap-2 cursor-pointer" @click="collapse = !collapse">
      <p class="text-primary text-nowrap">{{ title }}</p>
      <span class="border-top border-primary w-100 mx-3 line-h"></span>
      <!-- <div class="border border-t"></div> -->
      <div aria-controls="collapse-1" class="p-0 cursor-pointer"
        ><span v-if="collapse" class="fa fa-solid fa-chevron-up"></span
        ><span v-else class="fa fa-solid fa-chevron-down"></span
      ></div>
    </div>
    <b-collapse id="collapse-1" v-model="collapse" class="mt-2">
      <slot name="body" />
    </b-collapse>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      default: '',
      type: String,
    },
  },
  data() {
    return {
      collapse: true,
    }
  },
}
</script>
<style scoped>
.line-h {
  margin-top: 12px;
}
</style>
