var render = function render(){
  var _vm$detailProjectCont4, _vm$detailProjectCont5, _vm$detailProjectCont6, _vm$detailProjectCont7, _vm$detailProjectCont8, _vm$detailProjectCont9, _vm$detailProjectCont10, _vm$detailProjectCont11, _vm$detailProjectCont12, _vm$projectDetail, _vm$projectDetail$pro, _vm$projectDetail2, _vm$projectDetail3, _vm$projectDetail3$pr, _vm$projectDetail$sta, _vm$projectDetail4, _vm$customerDetail$ac, _vm$customerDetail, _vm$customerDetail$ad, _vm$customerDetail2, _ref, _vm$customerDetail$ph, _vm$customerDetail3, _vm$customerDetail4, _vm$customerDetail$em, _vm$customerDetail5, _vm$customerDetail$co, _vm$customerDetail6, _vm$customerDetail6$c, _vm$customerDetail$pr, _vm$customerDetail7, _vm$customerDetail$ci, _vm$customerDetail8, _vm$customerDetail$di, _vm$customerDetail9, _vm$customerDetail$vi, _vm$customerDetail10, _vm$customerDetail$na, _vm$customerDetail11, _ref2, _vm$customerDetail$mo, _vm$customerDetail12, _vm$customerDetail13, _vm$detailProjectCont13, _vm$form, _vm$optionsForms, _vm$form2, _vm$form3, _vm$form4, _vm$form5, _vm$detailProjectCont14, _vm$detailProjectCont15, _vm$detailProjectCont16, _vm$detailProjectCont17, _vm$detailProjectCont18, _vm$detailProjectCont19, _vm$detailProjectCont20, _vm$detailProjectCont21, _vm$totalQuantityEdit, _vm$form6, _vm$form7, _vm$form8, _vm$form9, _vm$form10, _vm$form11;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.detail_project_loading ? _c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSaveEdit.apply(null, arguments);
      }
    }
  }, [_c('b-row', {
    staticClass: "m-3"
  }, [_c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center w-100 justify-content-end"
  }, _vm._l(_vm.listProjectStage, function (state, index) {
    var _vm$detailProjectCont, _vm$detailProjectCont3, _vm$listProjectStage;
    return _c('div', {
      key: index
    }, [_c('b-badge', {
      staticClass: "border border-primary text-primary text-uppercase",
      staticStyle: {
        "cursor": "pointer"
      },
      attrs: {
        "variant": ((_vm$detailProjectCont = _vm.detailProjectContract) === null || _vm$detailProjectCont === void 0 ? void 0 : _vm$detailProjectCont.detail_project_stage_id) === state.id ? 'primary' : 'none'
      },
      on: {
        "click": function click($event) {
          var _vm$detailProjectCont2;
          return _vm.onChangeStatusStage((_vm$detailProjectCont2 = _vm.detailProjectContract) === null || _vm$detailProjectCont2 === void 0 ? void 0 : _vm$detailProjectCont2.id, state.id);
        }
      }
    }, [_c('span', {
      class: ((_vm$detailProjectCont3 = _vm.detailProjectContract) === null || _vm$detailProjectCont3 === void 0 ? void 0 : _vm$detailProjectCont3.detail_project_stage_id) === state.id ? 'text-white' : ''
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.title))])]), ((_vm$listProjectStage = _vm.listProjectStage) === null || _vm$listProjectStage === void 0 ? void 0 : _vm$listProjectStage.length) - 1 !== index ? _c('strong', [_vm._v(" >> ")]) : _vm._e()], 1);
  }), 0)]), _c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    class: "d-flex align-items-center justify-content-end w-100"
  }, [_vm.isEdit ? _c('b-button', {
    staticClass: "mt-2 mr-3",
    staticStyle: {
      "width": "15%"
    },
    attrs: {
      "disabled": _vm.saving,
      "block": "",
      "variant": "primary",
      "type": "submit"
    }
  }, [_vm.saving ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _c('div', [_c('i', {
    staticClass: "fa fa-save"
  }), _c('span', [_vm._v("Submit Data")])])]) : _vm._e(), _vm.isEdit ? _c('b-button', {
    staticStyle: {
      "width": "10%"
    },
    attrs: {
      "disabled": _vm.saving,
      "block": "",
      "type": "button",
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        _vm.isEdit = false;
      }
    }
  }, [_c('div', [_c('i', {
    staticClass: "fa fa-ban"
  }), _c('span', [_vm._v("Cancel")])])]) : _vm._e(), !_vm.isEdit ? _c('b-button', {
    staticStyle: {
      "width": "15%"
    },
    attrs: {
      "type": "button",
      "block": "",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.isEdit = true;
      }
    }
  }, [_c('div', [_c('i', {
    staticClass: "fa fa-pen"
  }), _c('span', [_vm._v("Edit Data")])])]) : _vm._e()], 1)]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Project Contract Info")]), _c('b-form-group', {
    attrs: {
      "label-for": "dateIssued",
      "label": "Projects"
    }
  }, [_vm.isEdit ? _c('v-select', {
    attrs: {
      "label": "text",
      "name": "project_sub_id",
      "options": _vm.projectOptions,
      "reduce": function reduce(projectOptions) {
        return projectOptions.value;
      },
      "placeholder": "Select Project",
      "required": ""
    },
    on: {
      "input": _vm.onSelectedProjectEdit
    },
    model: {
      value: _vm.form.project_sub_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "project_sub_id", $$v);
      },
      expression: "form.project_sub_id"
    }
  }) : _c('span', [_vm._v(_vm._s((_vm$detailProjectCont4 = _vm.detailProjectContract) === null || _vm$detailProjectCont4 === void 0 ? void 0 : (_vm$detailProjectCont5 = _vm$detailProjectCont4.project_sub) === null || _vm$detailProjectCont5 === void 0 ? void 0 : (_vm$detailProjectCont6 = _vm$detailProjectCont5.project) === null || _vm$detailProjectCont6 === void 0 ? void 0 : _vm$detailProjectCont6.project_name) + " - " + _vm._s((_vm$detailProjectCont7 = _vm.detailProjectContract) === null || _vm$detailProjectCont7 === void 0 ? void 0 : (_vm$detailProjectCont8 = _vm$detailProjectCont7.project_sub) === null || _vm$detailProjectCont8 === void 0 ? void 0 : _vm$detailProjectCont8.name))])], 1), _c('b-form-group', {
    attrs: {
      "label": "Customer",
      "label-for": "customer_id"
    }
  }, [_vm.isEdit ? _c('v-select', {
    attrs: {
      "label": "text",
      "name": "customer_id",
      "options": _vm.optionsListCustomer,
      "reduce": function reduce(optionsListCustomer) {
        return optionsListCustomer.value;
      },
      "placeholder": "Select Customer",
      "required": ""
    },
    on: {
      "input": _vm.onSelectedCustomerEdit
    },
    model: {
      value: _vm.form.customer_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "customer_id", $$v);
      },
      expression: "form.customer_id"
    }
  }) : _c('span', [_vm._v(_vm._s((_vm$detailProjectCont9 = _vm.detailProjectContract) === null || _vm$detailProjectCont9 === void 0 ? void 0 : (_vm$detailProjectCont10 = _vm$detailProjectCont9.customer) === null || _vm$detailProjectCont10 === void 0 ? void 0 : _vm$detailProjectCont10.customer_code) + " - " + _vm._s((_vm$detailProjectCont11 = _vm.detailProjectContract) === null || _vm$detailProjectCont11 === void 0 ? void 0 : (_vm$detailProjectCont12 = _vm$detailProjectCont11.customer) === null || _vm$detailProjectCont12 === void 0 ? void 0 : _vm$detailProjectCont12.account_name))])], 1)], 1)]), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-row', [_vm.form.project_sub_id ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Project Info")]), _c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("PROJECT NAME")]), _c('th', [_vm._v("PROJECT SUB NAME")]), _c('th', [_vm._v("LOCATION")]), _c('th', [_vm._v("STATUS")])])]), _c('tbody', [_c('tr', [_c('td', [_vm._v(_vm._s((_vm$projectDetail = _vm.projectDetail) === null || _vm$projectDetail === void 0 ? void 0 : (_vm$projectDetail$pro = _vm$projectDetail.project) === null || _vm$projectDetail$pro === void 0 ? void 0 : _vm$projectDetail$pro.project_name))]), _c('td', [_vm._v(_vm._s((_vm$projectDetail2 = _vm.projectDetail) === null || _vm$projectDetail2 === void 0 ? void 0 : _vm$projectDetail2.name))]), _c('td', [_vm._v(_vm._s((_vm$projectDetail3 = _vm.projectDetail) === null || _vm$projectDetail3 === void 0 ? void 0 : (_vm$projectDetail3$pr = _vm$projectDetail3.project) === null || _vm$projectDetail3$pr === void 0 ? void 0 : _vm$projectDetail3$pr.location))]), _c('td', [_vm._v(_vm._s((_vm$projectDetail$sta = (_vm$projectDetail4 = _vm.projectDetail) === null || _vm$projectDetail4 === void 0 ? void 0 : _vm$projectDetail4.status) !== null && _vm$projectDetail$sta !== void 0 ? _vm$projectDetail$sta : '-'))])])])])])]) : _vm._e(), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.form.customer_id ? _c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Company Info")]), _c('table', {
    staticClass: "table mb-0 table-striped"
  }, [_c('tbody', [_c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Nama Perusahaan")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s((_vm$customerDetail$ac = (_vm$customerDetail = _vm.customerDetail) === null || _vm$customerDetail === void 0 ? void 0 : _vm$customerDetail.account_name) !== null && _vm$customerDetail$ac !== void 0 ? _vm$customerDetail$ac : '-'))]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Alamat")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s((_vm$customerDetail$ad = (_vm$customerDetail2 = _vm.customerDetail) === null || _vm$customerDetail2 === void 0 ? void 0 : _vm$customerDetail2.address) !== null && _vm$customerDetail$ad !== void 0 ? _vm$customerDetail$ad : '-'))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("No. Telepon")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s((_ref = (_vm$customerDetail$ph = (_vm$customerDetail3 = _vm.customerDetail) === null || _vm$customerDetail3 === void 0 ? void 0 : _vm$customerDetail3.phone1) !== null && _vm$customerDetail$ph !== void 0 ? _vm$customerDetail$ph : (_vm$customerDetail4 = _vm.customerDetail) === null || _vm$customerDetail4 === void 0 ? void 0 : _vm$customerDetail4.phone2) !== null && _ref !== void 0 ? _ref : '-'))]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Email")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s((_vm$customerDetail$em = (_vm$customerDetail5 = _vm.customerDetail) === null || _vm$customerDetail5 === void 0 ? void 0 : _vm$customerDetail5.email) !== null && _vm$customerDetail$em !== void 0 ? _vm$customerDetail$em : '-'))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Negara")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s((_vm$customerDetail$co = (_vm$customerDetail6 = _vm.customerDetail) === null || _vm$customerDetail6 === void 0 ? void 0 : (_vm$customerDetail6$c = _vm$customerDetail6.country) === null || _vm$customerDetail6$c === void 0 ? void 0 : _vm$customerDetail6$c.name) !== null && _vm$customerDetail$co !== void 0 ? _vm$customerDetail$co : '-'))]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Provinsi")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s((_vm$customerDetail$pr = (_vm$customerDetail7 = _vm.customerDetail) === null || _vm$customerDetail7 === void 0 ? void 0 : _vm$customerDetail7.province) !== null && _vm$customerDetail$pr !== void 0 ? _vm$customerDetail$pr : '-'))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Kota")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s((_vm$customerDetail$ci = (_vm$customerDetail8 = _vm.customerDetail) === null || _vm$customerDetail8 === void 0 ? void 0 : _vm$customerDetail8.city) !== null && _vm$customerDetail$ci !== void 0 ? _vm$customerDetail$ci : '-'))]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Kecamatan")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s((_vm$customerDetail$di = (_vm$customerDetail9 = _vm.customerDetail) === null || _vm$customerDetail9 === void 0 ? void 0 : _vm$customerDetail9.district) !== null && _vm$customerDetail$di !== void 0 ? _vm$customerDetail$di : '-'))])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Kelurahan")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s((_vm$customerDetail$vi = (_vm$customerDetail10 = _vm.customerDetail) === null || _vm$customerDetail10 === void 0 ? void 0 : _vm$customerDetail10.village) !== null && _vm$customerDetail$vi !== void 0 ? _vm$customerDetail$vi : '-'))]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }), _c('td', {
    attrs: {
      "width": "5%"
    }
  }), _c('td', {
    attrs: {
      "width": "30%"
    }
  })]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("PIC")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s((_vm$customerDetail$na = (_vm$customerDetail11 = _vm.customerDetail) === null || _vm$customerDetail11 === void 0 ? void 0 : _vm$customerDetail11.name) !== null && _vm$customerDetail$na !== void 0 ? _vm$customerDetail$na : '-'))]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("No. Handphone")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm._v(_vm._s((_ref2 = (_vm$customerDetail$mo = (_vm$customerDetail12 = _vm.customerDetail) === null || _vm$customerDetail12 === void 0 ? void 0 : _vm$customerDetail12.mobile1) !== null && _vm$customerDetail$mo !== void 0 ? _vm$customerDetail$mo : (_vm$customerDetail13 = _vm.customerDetail) === null || _vm$customerDetail13 === void 0 ? void 0 : _vm$customerDetail13.mobile2) !== null && _ref2 !== void 0 ? _ref2 : '-'))])])])])]) : _vm._e()])], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Log History Activity")]), _c('div', {
    staticClass: "overflow-auto d-flex w-100",
    staticStyle: {
      "max-height": "544px"
    }
  }, [_c('b-row', _vm._l((_vm$detailProjectCont13 = _vm.detailProjectContract) === null || _vm$detailProjectCont13 === void 0 ? void 0 : _vm$detailProjectCont13.project_log, function (state, index) {
    return _c('b-col', {
      key: index,
      staticClass: "mb-4",
      attrs: {
        "lg": "12"
      }
    }, [_c('div', {
      staticClass: "d-flex"
    }, [_c('strong', {
      staticClass: "mr-2"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.name) + " -")]), _c('span', {
      staticClass: "text-muted"
    }, [_vm._v(_vm._s(_vm.moment(state.date_log).format('DD MMMM YYYY')))])]), _c('span', [_vm._v(_vm._s(state.log_description))]), _c('br'), _c('strong', [_vm._v("----")])]);
  }), 1)], 1)])]), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Detail Project Contract " + _vm._s(_vm.isEdit ? 'Form' : ''))]), _c('table', {
    staticClass: "table table-striped"
  }, [_c('tbody', [_c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Vehicle")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm.isEdit ? _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('b-form-select', {
    staticClass: "form-control",
    attrs: {
      "plain": "",
      "required": "",
      "name": "fleet_ownership"
    },
    on: {
      "change": function change($event) {
        _vm.onDataIntoPlan('fleet_ownership', _vm.form.fleet_ownership);
        _vm.onChangeOwnership(_vm.form.fleet_ownership);
      }
    },
    model: {
      value: _vm.form.fleet_ownership,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "fleet_ownership", $$v);
      },
      expression: "form.fleet_ownership"
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("Select ownership...")]), _c('option', {
    attrs: {
      "value": "own-ship"
    }
  }, [_vm._v("OWN SHIP")]), _c('option', {
    attrs: {
      "value": "chartered"
    }
  }, [_vm._v("CHARTERED")])]), _vm.form.fleet_ownership ? _c('div', {
    staticClass: "d-flex mt-2 flex-column w-100"
  }, [_c('strong', [_vm._v(_vm._s(_vm.vehicleName(_vm.form.vehicle_id)))]), _c('b-button', {
    attrs: {
      "variant": "primary",
      "size": "sm"
    },
    on: {
      "click": _vm.onOpenSlideDetailVehicle
    }
  }, [_vm._v(_vm._s(_vm.form.fleet_ownership ? 'Change ' : 'Select ') + " vehicle")])], 1) : _vm._e()], 1) : _c('div', {
    staticClass: "d-flex flex-column align-items-center justify-content-center w-100"
  }, [_c('strong', [_vm._v(_vm._s(_vm.vehicleName(_vm.form.vehicle_id)))]), _c('b-badge', {
    staticClass: "border border-primary text-primary text-uppercase",
    attrs: {
      "variant": "none"
    }
  }, [_vm._v(_vm._s((_vm$form = _vm.form) === null || _vm$form === void 0 ? void 0 : _vm$form.fleet_ownership))])], 1)]), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Collection of Forms")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _vm.isEdit ? _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [((_vm$optionsForms = _vm.optionsForms) === null || _vm$optionsForms === void 0 ? void 0 : _vm$optionsForms.length) > 0 ? _c('v-multiselect', {
    attrs: {
      "options": _vm.optionsForms,
      "multiple": true,
      "group-select": true,
      "placeholder": "Select Inspection",
      "track-by": "label",
      "label": "label"
    },
    model: {
      value: _vm.form.project_forms,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "project_forms", $$v);
      },
      expression: "form.project_forms"
    }
  }) : _c('strong', {
    staticClass: "text-muted"
  }, [_vm._v("No forms.")])], 1) : _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-wrap gap-2"
  }, _vm._l(_vm.form.project_forms, function (state, index) {
    return _c('b-badge', {
      key: index,
      staticClass: "border border-primary text-primary text-uppercase m-1",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v(" " + _vm._s(state === null || state === void 0 ? void 0 : state.label) + " ")]);
  }), 1)])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Periode Start")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm.isEdit ? _c('b-form-input', {
    attrs: {
      "id": "periode_start",
      "name": "periode_start",
      "type": "date"
    },
    model: {
      value: _vm.form.periode_start,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "periode_start", $$v);
      },
      expression: "form.periode_start"
    }
  }) : _c('span', [_vm._v(_vm._s((_vm$form2 = _vm.form) !== null && _vm$form2 !== void 0 && _vm$form2.periode_start ? _vm.moment((_vm$form3 = _vm.form) === null || _vm$form3 === void 0 ? void 0 : _vm$form3.periode_start).format('DD MMMM YYYY') : '-'))])], 1), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Periode End")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm.isEdit ? _c('b-form-input', {
    attrs: {
      "id": "periode_end",
      "name": "periode_end",
      "type": "date"
    },
    model: {
      value: _vm.form.periode_end,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "periode_end", $$v);
      },
      expression: "form.periode_end"
    }
  }) : _c('span', [_vm._v(_vm._s((_vm$form4 = _vm.form) !== null && _vm$form4 !== void 0 && _vm$form4.periode_start ? _vm.moment((_vm$form5 = _vm.form) === null || _vm$form5 === void 0 ? void 0 : _vm$form5.periode_start).format('DD MMMM YYYY') : '-'))])], 1)]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Cargo Type")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm.isEdit ? _c('b-form-select', {
    attrs: {
      "plain": "",
      "size": "sm",
      "options": _vm.optionsCargoType
    },
    on: {
      "change": function change($event) {
        return _vm.onDataIntoPlan('cargo_type_id', _vm.form.cargo_type_id);
      }
    },
    model: {
      value: _vm.form.cargo_type_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "cargo_type_id", $$v);
      },
      expression: "form.cargo_type_id"
    }
  }, [_c('b-form-select-option', {
    attrs: {
      "value": null
    }
  }, [_vm._v("Select Cargo Type")])], 1) : _c('span', [_vm._v(_vm._s((_vm$detailProjectCont14 = (_vm$detailProjectCont15 = _vm.detailProjectContract) === null || _vm$detailProjectCont15 === void 0 ? void 0 : (_vm$detailProjectCont16 = _vm$detailProjectCont15.cargo_type) === null || _vm$detailProjectCont16 === void 0 ? void 0 : _vm$detailProjectCont16.name) !== null && _vm$detailProjectCont14 !== void 0 ? _vm$detailProjectCont14 : '-'))])], 1), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Contract Type")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm.isEdit ? _c('v-multiselect', {
    attrs: {
      "placeholder": "search/add contract",
      "label": "name",
      "track-by": "id",
      "close-on-select": true,
      "clear-on-select": true,
      "options": _vm.optionsContractType
    },
    on: {
      "search-change": _vm.searchContractType
    },
    model: {
      value: _vm.form.contract_type_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "contract_type_id", $$v);
      },
      expression: "form.contract_type_id"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    on: {
      "click": _vm.doAddContractType
    },
    slot: "noResult"
  }, [_vm._v(" Not Found. Click to add "), _c('strong', [_vm._v(_vm._s(_vm.contractTypeName))])])]) : _c('span', [_vm._v(_vm._s((_vm$detailProjectCont17 = _vm.detailProjectContract) === null || _vm$detailProjectCont17 === void 0 ? void 0 : (_vm$detailProjectCont18 = _vm$detailProjectCont17.contract_type) === null || _vm$detailProjectCont18 === void 0 ? void 0 : _vm$detailProjectCont18.name))])], 1)]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Term")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm.isEdit ? _c('b-form-select', {
    attrs: {
      "plain": "",
      "size": "sm",
      "options": _vm.optionsTerms
    },
    on: {
      "change": function change($event) {
        return _vm.onDataIntoPlan('terms_id', _vm.form.terms_id);
      }
    },
    model: {
      value: _vm.form.terms_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "terms_id", $$v);
      },
      expression: "form.terms_id"
    }
  }, [_c('b-form-select-option', {
    attrs: {
      "value": null
    }
  }, [_vm._v("Select Term")])], 1) : _c('span', [_vm._v(_vm._s((_vm$detailProjectCont19 = (_vm$detailProjectCont20 = _vm.detailProjectContract) === null || _vm$detailProjectCont20 === void 0 ? void 0 : (_vm$detailProjectCont21 = _vm$detailProjectCont20.terms) === null || _vm$detailProjectCont21 === void 0 ? void 0 : _vm$detailProjectCont21.name) !== null && _vm$detailProjectCont19 !== void 0 ? _vm$detailProjectCont19 : '-'))])], 1), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Total Volume/Quantity")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_c('span', {
    staticClass: "form-control"
  }, [_vm._v(_vm._s((_vm$totalQuantityEdit = _vm.totalQuantityEdit) === null || _vm$totalQuantityEdit === void 0 ? void 0 : _vm$totalQuantityEdit.toLocaleString()))])])]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("POL")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm.isEdit ? _c('b-form-input', {
    attrs: {
      "id": "pol",
      "name": "pol",
      "placeholder": "Type Text ..."
    },
    on: {
      "change": function change($event) {
        return _vm.onDataIntoPlan('pol', _vm.form.pol);
      }
    },
    model: {
      value: _vm.form.pol,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "pol", $$v);
      },
      expression: "form.pol"
    }
  }) : _c('span', [_vm._v(_vm._s((_vm$form6 = _vm.form) === null || _vm$form6 === void 0 ? void 0 : _vm$form6.pol))])], 1), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("POD")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm.isEdit ? _c('b-form-input', {
    attrs: {
      "id": "pod",
      "name": "pod",
      "placeholder": "Type Text ..."
    },
    on: {
      "change": function change($event) {
        return _vm.onDataIntoPlan('pod', _vm.form.pod);
      }
    },
    model: {
      value: _vm.form.pod,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "pod", $$v);
      },
      expression: "form.pod"
    }
  }) : _c('span', [_vm._v(_vm._s((_vm$form7 = _vm.form) === null || _vm$form7 === void 0 ? void 0 : _vm$form7.pod))])], 1)]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Laycan")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm.isEdit ? _c('b-form-input', {
    attrs: {
      "id": "laycan",
      "name": "laycan",
      "placeholder": "Type Text ..."
    },
    model: {
      value: _vm.form.laycan,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "laycan", $$v);
      },
      expression: "form.laycan"
    }
  }) : _c('span', [_vm._v(_vm._s((_vm$form8 = _vm.form) === null || _vm$form8 === void 0 ? void 0 : _vm$form8.laycan))])], 1), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Distance")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm.isEdit ? _c('b-form-input', {
    attrs: {
      "id": "distance",
      "name": "distance",
      "placeholder": "Type Number ...",
      "type": "number"
    },
    model: {
      value: _vm.form.distance,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "distance", $$v);
      },
      expression: "form.distance"
    }
  }) : _c('span', [_vm._v(_vm._s((_vm$form9 = _vm.form) === null || _vm$form9 === void 0 ? void 0 : _vm$form9.distance))])], 1)]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Duration")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm.isEdit ? _c('b-form-input', {
    attrs: {
      "id": "duration",
      "name": "duration",
      "placeholder": "Type Number ...",
      "type": "number"
    },
    model: {
      value: _vm.form.duration,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "duration", $$v);
      },
      expression: "form.duration"
    }
  }) : _c('span', [_vm._v(_vm._s((_vm$form10 = _vm.form) === null || _vm$form10 === void 0 ? void 0 : _vm$form10.duration))])], 1), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Freigh Rate Excl.Ppn")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm.isEdit ? _c('b-form-input', {
    attrs: {
      "id": "freight_rate_excl_ppn",
      "name": "freight_rate_excl_ppn",
      "placeholder": "Type Text ..."
    },
    model: {
      value: _vm.form.freight_rate_excl_ppn,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "freight_rate_excl_ppn", $$v);
      },
      expression: "form.freight_rate_excl_ppn"
    }
  }) : _c('span', [_vm._v(_vm._s((_vm$form11 = _vm.form) === null || _vm$form11 === void 0 ? void 0 : _vm$form11.freight_rate_excl_ppn))])], 1)]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Remarks")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%",
      "colSpan": "4"
    }
  }, [_vm.isEdit ? _c('b-form-textarea', {
    attrs: {
      "id": "remarks",
      "name": "remarks",
      "placeholder": "Type Text ..."
    },
    model: {
      value: _vm.form.remarks,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "remarks", $$v);
      },
      expression: "form.remarks"
    }
  }) : _c('span', [_vm._v(_vm._s(_vm.form.remarks))])], 1)]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Needs More Detail Plan ?")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm.isEdit ? _c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.form.need_more_detail,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "need_more_detail", $$v);
      },
      expression: "form.need_more_detail"
    }
  }, [_vm._v(" Yes / No ")]) : _c('span', {
    class: _vm.form.need_more_detail ? 'text-success' : 'text-danger'
  }, [_vm._v(_vm._s(_vm.form.need_more_detail ? 'Yes' : 'No'))])], 1), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("is Tender ?")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm.isEdit ? _c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.form.is_tender,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "is_tender", $$v);
      },
      expression: "form.is_tender"
    }
  }, [_vm._v(" Yes / No ")]) : _c('span', {
    class: _vm.form.is_tender ? 'text-success' : 'text-danger'
  }, [_vm._v(_vm._s(_vm.form.is_tender ? 'Yes' : 'No'))])], 1)]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Needs Pre-Fixtured Analysis?")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm.isEdit ? _c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.form.is_pfa,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "is_pfa", $$v);
      },
      expression: "form.is_pfa"
    }
  }, [_vm._v(" Yes / No ")]) : _c('span', {
    class: _vm.form.is_pfa ? 'text-success' : 'text-danger'
  }, [_vm._v(_vm._s(_vm.form.is_pfa ? 'Yes' : 'No'))])], 1), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Need Document?")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm.isEdit ? _c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.form.need_document,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "need_document", $$v);
      },
      expression: "form.need_document"
    }
  }, [_vm._v(" Yes / No ")]) : _c('span', {
    class: _vm.form.need_document ? 'text-success' : 'text-danger'
  }, [_vm._v(_vm._s(_vm.form.need_document ? 'Yes' : 'No'))])], 1)]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Is this project needs approval ?")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm.isEdit ? _c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.form.need_approval,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "need_approval", $$v);
      },
      expression: "form.need_approval"
    }
  }, [_vm._v(" Yes / No ")]) : _c('span', {
    class: _vm.form.need_approval ? 'text-success' : 'text-danger'
  }, [_vm._v(_vm._s(_vm.form.need_approval ? 'Yes' : 'No'))])], 1), _vm.form.need_approval ? _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("How much approval is needed?")]) : _vm._e(), _vm.form.need_approval ? _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]) : _vm._e(), _vm.form.need_approval ? _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm.isEdit ? _c('b-form-select', {
    attrs: {
      "plain": "",
      "size": "sm",
      "required": ""
    },
    on: {
      "change": _vm.onChangeManyApproval
    },
    model: {
      value: _vm.form.many_approval,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "many_approval", $$v);
      },
      expression: "form.many_approval"
    }
  }, [_c('b-form-select-option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("Select values....")]), _c('b-form-select-option', {
    attrs: {
      "value": "1"
    }
  }, [_vm._v("1")]), _c('b-form-select-option', {
    attrs: {
      "value": "2"
    }
  }, [_vm._v("2")]), _c('b-form-select-option', {
    attrs: {
      "value": "3"
    }
  }, [_vm._v("3")]), _c('b-form-select-option', {
    attrs: {
      "value": "4"
    }
  }, [_vm._v("4")])], 1) : _c('span', [_vm._v(_vm._s(_vm.form.many_approval) + " User")])], 1) : _vm._e()]), _vm.form.many_approval ? _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("User Approval")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _vm.isEdit ? _c('td', {
    attrs: {
      "width": "30%",
      "colSpan": "4"
    }
  }, _vm._l(_vm.form.user_approval, function (user, userIndex) {
    return _c('div', {
      key: userIndex,
      staticClass: "d-flex align-items-center mb-2"
    }, [_c('strong', [_vm._v("#" + _vm._s(userIndex + 1))]), _c('v-select', {
      staticClass: "w-100 ml-2",
      attrs: {
        "label": "text",
        "name": "user",
        "options": _vm.optionsExpertUsers,
        "reduce": function reduce(optionsExpertUsers) {
          return optionsExpertUsers.value;
        },
        "placeholder": "Select User",
        "required": ""
      },
      model: {
        value: user.user,
        callback: function callback($$v) {
          _vm.$set(user, "user", $$v);
        },
        expression: "user.user"
      }
    })], 1);
  }), 0) : _c('td', {
    attrs: {
      "width": "30%",
      "colSpan": "4"
    }
  }, _vm._l(_vm.form.user_approval, function (user, userIndex) {
    return _c('div', {
      key: userIndex,
      staticClass: "d-flex align-items-center mb-2"
    }, [_c('strong', {
      staticClass: "mr-2"
    }, [_vm._v("#" + _vm._s(userIndex + 1))]), _c('span', [_vm._v(_vm._s(_vm.userExpertName(user === null || user === void 0 ? void 0 : user.user)))])]);
  }), 0)]) : _vm._e()])])])])], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-row', [_vm.form.is_tender ? _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Tender Form")]), _c('table', {
    staticClass: "table table-striped"
  }, [_c('tbody', [_c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("PIC")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm.isEdit ? _c('b-form-input', {
    attrs: {
      "placeholder": "Type Text",
      "required": ""
    },
    model: {
      value: _vm.form.formTender.pic,
      callback: function callback($$v) {
        _vm.$set(_vm.form.formTender, "pic", $$v);
      },
      expression: "form.formTender.pic"
    }
  }) : _c('span', [_vm._v(_vm._s(_vm.form.formTender.pic))])], 1), _c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Url")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "width": "30%"
    }
  }, [_vm.isEdit ? _c('b-form-input', {
    attrs: {
      "placeholder": "Type Text",
      "required": ""
    },
    model: {
      value: _vm.form.formTender.url,
      callback: function callback($$v) {
        _vm.$set(_vm.form.formTender, "url", $$v);
      },
      expression: "form.formTender.url"
    }
  }) : _c('span', [_vm._v(_vm._s(_vm.form.formTender.url))])], 1)]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("Description")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "colSpan": "4"
    }
  }, [_vm.isEdit ? _c('b-form-textarea', {
    attrs: {
      "placeholder": "Type Text",
      "required": ""
    },
    model: {
      value: _vm.form.formTender.description_of_tender,
      callback: function callback($$v) {
        _vm.$set(_vm.form.formTender, "description_of_tender", $$v);
      },
      expression: "form.formTender.description_of_tender"
    }
  }) : _c('span', [_vm._v(_vm._s(_vm.form.formTender.description_of_tender))])], 1)]), _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("File")]), _c('td', {
    attrs: {
      "width": "5%"
    }
  }, [_vm._v(":")]), _c('td', {
    attrs: {
      "colSpan": "4"
    }
  }, [_vm.isEdit ? _c('b-form-file', {
    staticClass: "form-control",
    attrs: {
      "plain": "",
      "id": "file",
      "accept": "image/*,application/*",
      "multiple": ""
    },
    model: {
      value: _vm.form.formTender.file,
      callback: function callback($$v) {
        _vm.$set(_vm.form.formTender, "file", $$v);
      },
      expression: "form.formTender.file"
    }
  }) : _c('img', {
    staticStyle: {
      "width": "40px",
      "height": "40px"
    },
    attrs: {
      "src": require("../../../assets/images/activity/pdf.png"),
      "alt": "img-image"
    }
  })], 1)])])])])]) : _vm._e(), _vm.form.need_document ? _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Document Attachment")]), _c('div', {
    staticClass: "overflow-auto"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "width": "20px"
    }
  }, [_vm._v("NO")]), _c('th', [_vm._v("Name of File / Document")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle"
    }
  }, [_vm._v("File / Document")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "width": "20px"
    }
  }, [_vm._v("#")])])]), _vm.isEdit ? _c('tbody', _vm._l(_vm.form.form_document, function (state, index) {
    return _c('tr', {
      key: index
    }, [_c('th', {
      staticClass: "text-center align-top"
    }, [_vm._v("#" + _vm._s(index + 1))]), _c('td', [_c('b-form-input', {
      attrs: {
        "placeholder": "Name of File",
        "required": ""
      },
      model: {
        value: state.filename,
        callback: function callback($$v) {
          _vm.$set(state, "filename", $$v);
        },
        expression: "state.filename"
      }
    })], 1), _c('td', {
      staticClass: "align-top"
    }, [_c('b-form-file', {
      attrs: {
        "required": state !== null && state !== void 0 && state.url ? false : true,
        "id": 'fileSupport' + index,
        "placeholder": "Choose a file",
        "accept": "application/pdf"
      },
      model: {
        value: state.file,
        callback: function callback($$v) {
          _vm.$set(state, "file", $$v);
        },
        expression: "state.file"
      }
    }), _c('a', {
      staticClass: "text-primary",
      attrs: {
        "href": state === null || state === void 0 ? void 0 : state.url
      }
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.filename) + "." + _vm._s(state === null || state === void 0 ? void 0 : state.extension))])], 1), _c('td', {
      staticClass: "text-center align-top"
    }, [index !== 0 ? _c('b-button', {
      attrs: {
        "size": "sm",
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.form.form_document.splice(index, 1);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash pr-0"
    })]) : _vm._e()], 1)]);
  }), 0) : _c('tbody', _vm._l(_vm.detailProjectContract.project_document, function (state, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "text-center align-top"
    }, [_vm._v(_vm._s(index + 1))]), _c('td', [_c('span', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.filename))])]), _c('td', {
      staticClass: "text-center"
    }, [_c('a', {
      staticClass: "text-primary",
      attrs: {
        "href": state === null || state === void 0 ? void 0 : state.url
      }
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.filename) + "." + _vm._s(state === null || state === void 0 ? void 0 : state.extension))])]), _c('td')]);
  }), 0)])]), _vm.isEdit ? _c('b-button', {
    attrs: {
      "block": "",
      "variant": "success"
    },
    on: {
      "click": function click($event) {
        return _vm.addFormDocument();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus pr-0"
  }), _vm._v(" ADD MORE ATTACHMENT")]) : _vm._e()], 1)]) : _vm._e()], 1)], 1)], 1)], 1), _vm.form.need_more_detail ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Detail Project Contract Plan")]), _c('div', {
    staticClass: "overflow-auto"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("NO")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "350px"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("DESCRIPTION")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "250px"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("FLEET")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "300px"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("CARGO TYPE")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "250px"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("LOCATION")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "250px"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("SHIPMENT (MONTH)")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "min-width": "250px"
    },
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("PLAN DATE")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "250px"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("PLAN QUANTITY")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle",
      "min-width": "250px"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("REMARKS")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "vertical-align": "middle"
    },
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v("ACTION")])]), _c('tr', [_c('th', {
    staticClass: "text-center",
    staticStyle: {
      "min-width": "250px"
    }
  }, [_vm._v("ETA POL")]), _c('th', {
    staticClass: "text-center",
    staticStyle: {
      "min-width": "250px"
    }
  }, [_vm._v("ETA POD")])])]), _c('tbody', _vm._l(_vm.form.detail_plan, function (state, index) {
    var _state$description, _state$cargo_type, _state$terms, _state$remarks;
    return _c('tr', {
      key: index
    }, [_c('th', {
      staticClass: "text-center align-top"
    }, [_vm._v("#" + _vm._s(index + 1))]), _c('td', {
      staticClass: "text-center align-top"
    }, [_vm.isEdit ? _c('b-form-textarea', {
      staticStyle: {
        "border": "solid 1px gray"
      },
      attrs: {
        "placeholder": "Type Text",
        "type": "text",
        "id": "description",
        "name": "description",
        "size": "sm"
      },
      model: {
        value: state.description,
        callback: function callback($$v) {
          _vm.$set(state, "description", $$v);
        },
        expression: "state.description"
      }
    }) : _c('span', [_vm._v(_vm._s((_state$description = state === null || state === void 0 ? void 0 : state.description) !== null && _state$description !== void 0 ? _state$description : '-'))])], 1), _c('td', {
      staticClass: "text-left align-top"
    }, [_vm.isEdit ? _c('b-row', [_c('b-col', {
      staticClass: "mb-2",
      attrs: {
        "lg": "12"
      }
    }, [_c('b-form-select', {
      staticClass: "form-control",
      attrs: {
        "plain": "",
        "required": ""
      },
      model: {
        value: state.fleet_ownership,
        callback: function callback($$v) {
          _vm.$set(state, "fleet_ownership", $$v);
        },
        expression: "state.fleet_ownership"
      }
    }, [_c('option', {
      attrs: {
        "value": ""
      }
    }, [_vm._v("Select ownership...")]), _c('option', {
      attrs: {
        "value": "own-ship"
      }
    }, [_vm._v("OWN SHIP")]), _c('option', {
      attrs: {
        "value": "chartered"
      }
    }, [_vm._v("CHARTERED")])])], 1), state.fleet_ownership ? _c('b-col', {
      staticStyle: {
        "z-index": "100000"
      },
      attrs: {
        "lg": "12"
      }
    }, [_c('v-select', {
      attrs: {
        "required": "",
        "label": "text",
        "name": "vehicle_id",
        "options": _vm.optionsFleets,
        "reduce": function reduce(optionsFleets) {
          return optionsFleets.value;
        },
        "placeholder": "Select Fleet"
      },
      model: {
        value: state.vehicle_id,
        callback: function callback($$v) {
          _vm.$set(state, "vehicle_id", $$v);
        },
        expression: "state.vehicle_id"
      }
    })], 1) : _vm._e()], 1) : _c('div', {
      staticClass: "d-flex flex-column align-items-center justify-content-center w-100"
    }, [_c('strong', [_vm._v(_vm._s(_vm.vehicleName(state.vehicle_id)))]), _c('b-badge', {
      staticClass: "border border-primary text-primary text-uppercase",
      attrs: {
        "variant": "none"
      }
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.fleet_ownership))])], 1)], 1), _c('td', {
      staticClass: "text-left align-top"
    }, [_c('b-form-group', {
      staticClass: "row mb-2",
      attrs: {
        "label-cols-md": "4",
        "label": "Cargo Type",
        "label-for": "cargo_type_id"
      }
    }, [_vm.isEdit ? _c('b-form-select', {
      staticStyle: {
        "border": "solid 1px gray"
      },
      attrs: {
        "plain": "",
        "size": "sm",
        "options": _vm.optionsCargoType
      },
      model: {
        value: state.cargo_type_id,
        callback: function callback($$v) {
          _vm.$set(state, "cargo_type_id", $$v);
        },
        expression: "state.cargo_type_id"
      }
    }, [_c('b-form-select-option', {
      attrs: {
        "value": null
      }
    }, [_vm._v("Select Cargo Type")])], 1) : _c('b-badge', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$cargo_type = state.cargo_type) === null || _state$cargo_type === void 0 ? void 0 : _state$cargo_type.name))])], 1), _c('b-form-group', {
      staticClass: "row mb-2",
      attrs: {
        "label-cols-md": "4",
        "label": "Term",
        "label-for": "descriptionItem"
      }
    }, [_vm.isEdit ? _c('b-form-select', {
      staticStyle: {
        "border": "solid 1px gray"
      },
      attrs: {
        "plain": "",
        "size": "sm",
        "options": _vm.optionsTerms
      },
      model: {
        value: state.terms_id,
        callback: function callback($$v) {
          _vm.$set(state, "terms_id", $$v);
        },
        expression: "state.terms_id"
      }
    }, [_c('b-form-select-option', {
      attrs: {
        "value": null
      }
    }, [_vm._v("Select Term")])], 1) : _c('b-badge', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$terms = state.terms) === null || _state$terms === void 0 ? void 0 : _state$terms.name))])], 1)], 1), _c('td', {
      staticClass: "text-left align-top"
    }, [_c('b-form-group', {
      staticClass: "row mb-2",
      attrs: {
        "label-cols-md": "4",
        "label": "POL",
        "label-for": "descriptionItem"
      }
    }, [_vm.isEdit ? _c('b-form-input', {
      staticStyle: {
        "border": "solid 1px gray"
      },
      attrs: {
        "type": "text",
        "id": "pol",
        "size": "sm"
      },
      model: {
        value: state.pol,
        callback: function callback($$v) {
          _vm.$set(state, "pol", $$v);
        },
        expression: "state.pol"
      }
    }) : _c('span', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.pol))])], 1), _c('b-form-group', {
      staticClass: "row mb-2",
      attrs: {
        "label-cols-md": "4",
        "label": "POD",
        "label-for": "descriptionItem"
      }
    }, [_vm.isEdit ? _c('b-form-input', {
      staticStyle: {
        "border": "solid 1px gray"
      },
      attrs: {
        "type": "text",
        "id": "pod",
        "size": "sm"
      },
      model: {
        value: state.pod,
        callback: function callback($$v) {
          _vm.$set(state, "pod", $$v);
        },
        expression: "state.pod"
      }
    }) : _c('span', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.pod))])], 1)], 1), _c('td', {
      staticClass: "text-center align-top"
    }, [_vm.isEdit ? _c('b-form-select', {
      staticStyle: {
        "border": "solid 1px gray"
      },
      attrs: {
        "plain": "",
        "size": "sm"
      },
      model: {
        value: state.shipment,
        callback: function callback($$v) {
          _vm.$set(state, "shipment", $$v);
        },
        expression: "state.shipment"
      }
    }, [_c('b-form-select-option', {
      attrs: {
        "value": ""
      }
    }, [_vm._v("SELECT MONTH")]), _c('b-form-select-option', {
      attrs: {
        "value": "JANUARY"
      }
    }, [_vm._v("JANUARI")]), _c('b-form-select-option', {
      attrs: {
        "value": "FEBRUARI"
      }
    }, [_vm._v("FEBRUARI")]), _c('b-form-select-option', {
      attrs: {
        "value": "MARET"
      }
    }, [_vm._v("MARET")]), _c('b-form-select-option', {
      attrs: {
        "value": "APRIL"
      }
    }, [_vm._v("APRIL")]), _c('b-form-select-option', {
      attrs: {
        "value": "MEI"
      }
    }, [_vm._v("MEI")]), _c('b-form-select-option', {
      attrs: {
        "value": "JUNI"
      }
    }, [_vm._v("JUNI")]), _c('b-form-select-option', {
      attrs: {
        "value": "JULI"
      }
    }, [_vm._v("JULI")]), _c('b-form-select-option', {
      attrs: {
        "value": "AGUSTUS"
      }
    }, [_vm._v("AGUSTUS")]), _c('b-form-select-option', {
      attrs: {
        "value": "SEPTEMBER"
      }
    }, [_vm._v("SEPTEMBER")]), _c('b-form-select-option', {
      attrs: {
        "value": "OKTOBER"
      }
    }, [_vm._v("OKTOBER")]), _c('b-form-select-option', {
      attrs: {
        "value": "NOVEMBER"
      }
    }, [_vm._v("NOVEMBER")]), _c('b-form-select-option', {
      attrs: {
        "value": "DESEMBER"
      }
    }, [_vm._v("DESEMBER")])], 1) : _c('span', [_vm._v(_vm._s(state.shipment))])], 1), _c('td', {
      staticClass: "text-center align-top",
      attrs: {
        "colspan": "2"
      }
    }, [_c('b-row', [_c('b-col', {
      attrs: {
        "lg": "6"
      }
    }, [_vm.isEdit ? _c('b-form-input', {
      staticClass: "mb-1",
      staticStyle: {
        "border": "solid 1px gray"
      },
      attrs: {
        "type": "date",
        "id": "eta_pol",
        "size": "sm"
      },
      model: {
        value: state.eta_pol,
        callback: function callback($$v) {
          _vm.$set(state, "eta_pol", $$v);
        },
        expression: "state.eta_pol"
      }
    }) : _c('span', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.eta_pol))])], 1), _c('b-col', {
      attrs: {
        "lg": "6"
      }
    }, [_vm.isEdit ? _c('b-form-input', {
      staticClass: "mb-1",
      staticStyle: {
        "border": "solid 1px gray"
      },
      attrs: {
        "type": "date",
        "id": "eta_pod",
        "size": "sm"
      },
      model: {
        value: state.eta_pod,
        callback: function callback($$v) {
          _vm.$set(state, "eta_pod", $$v);
        },
        expression: "state.eta_pod"
      }
    }) : _c('span', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.eta_pod))])], 1)], 1)], 1), _c('td', {
      staticClass: "text-center align-top"
    }, [_vm.isEdit ? _c('b-form-input', {
      staticStyle: {
        "border": "solid 1px gray"
      },
      attrs: {
        "type": "number",
        "id": "plan_quantity",
        "size": "sm",
        "placeholder": "0"
      },
      model: {
        value: state.plan_quantity,
        callback: function callback($$v) {
          _vm.$set(state, "plan_quantity", $$v);
        },
        expression: "state.plan_quantity"
      }
    }) : _c('span', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.eta_pod))])], 1), _c('td', {
      staticClass: "text-center align-top"
    }, [_vm.isEdit ? _c('b-form-textarea', {
      attrs: {
        "rows": "2",
        "placeholder": "Comments if any / Komentar jika ada",
        "name": "remarks"
      },
      model: {
        value: state.remarks,
        callback: function callback($$v) {
          _vm.$set(state, "remarks", $$v);
        },
        expression: "state.remarks"
      }
    }) : _c('span', [_vm._v(_vm._s((_state$remarks = state === null || state === void 0 ? void 0 : state.remarks) !== null && _state$remarks !== void 0 ? _state$remarks : '-'))])], 1), _c('td', {
      staticClass: "text-center align-top"
    }, [_vm.isEdit ? _c('b-button', {
      attrs: {
        "size": "sm",
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.onDeleteProjectContractPlan(state.id, index);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash pr-0"
    })]) : _vm._e()], 1)]);
  }), 0)])]), _vm.isEdit ? _c('b-button', {
    attrs: {
      "block": "",
      "variant": "success"
    },
    on: {
      "click": function click($event) {
        return _vm.addDetailPlanData(true);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus pr-0"
  }), _vm._v(" ADD MORE DATA")]) : _vm._e()], 1)]) : _vm._e(), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "detail_vehicle",
      "title": "Detail Vehicle",
      "width": "100%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var hide = _ref3.hide;
        return [_c('SlidePreviewDetailVehicle', {
          attrs: {
            "options-fleets": _vm.optionsFleets,
            "vehicle-id": _vm.form.vehicle_id,
            "ownership": _vm.form.fleet_ownership,
            "on-open-slide-detail-vehicle": _vm.onOpenSlideDetailVehicle
          },
          on: {
            "updateVehicleId": _vm.updateVehicleId
          }
        })];
      }
    }], null, false, 3832777266),
    model: {
      value: _vm.slidePreviewDetailVehicle,
      callback: function callback($$v) {
        _vm.slidePreviewDetailVehicle = $$v;
      },
      expression: "slidePreviewDetailVehicle"
    }
  })], 1)], 1)], 1) : _c('div', {
    staticClass: "alert alert-info m-4"
  }, [_vm._v(" Loading... ")]);

}
var staticRenderFns = []

export { render, staticRenderFns }