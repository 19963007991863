<template>
  <b-row>
    <b-col md="12">
      <iq-card headerClass="bg-head-budget-plan-vs-expanse">
        <template v-slot:headerTitle>
          <h4 class="card-title text-white"
            ><strong>BUDGET PLAN VS BUDGET EXPENSES</strong></h4
          >
        </template>
        <template v-slot:body>
          <div style="min-height: 350px">
            <ApexChart
              :element="`chart-budget-plan-vs-expanse`"
              :chartOption="chartBudgetPlanVsExapnse"
            />
          </div>
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      chartBudgetPlanVsExapnse: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
        },
        series: [
          {
            name: 'Budget Plan',
            data: [44, 55, 41, 37, 22, 43, 21, 55, 41, 37, 22, 43],
            color: '#40A0FC',
          },
          {
            name: 'Budget Expanse',
            data: [53, 32, 33, 52, 13, 43, 32, 55, 41, 37, 22, 43],
            color: '#FFA500',
          },
        ],
        plotOptions: {
          bar: {
            dataLabels: {
              total: {
                enabled: false,
                offsetX: 0,
                style: {
                  fontSize: '13px',
                  fontWeight: 900,
                },
              },
            },
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },
        xaxis: {
          categories: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ],
        },
        yaxis: {
          labels: {
            formatter: (val) => {
              return val + '%' // memformat label sumbu y menjadi persentase
            },
          },
          max: 100, // set nilai maksimum pada sumbu y
          tickAmount: 5,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + 'K'
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: 'bottom',
        },
        fill: {
          type: 'gradient', // Menggunakan gradient fill
          gradient: {
            shade: 'light', // Warna gradient (light atau dark)
            type: 'vertical', // Radial gradient untuk pie chart
            shadeIntensity: 0.5, // Intensitas shade
            gradientToColors: ['#1235C5', '#DB8E01'], // Warna tujuan gradient
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [10, 100], // Gradient stops dari 0% hingga 100%
          },
        },
      },
    }
  },
  methods: {
    next() {
      this.chartIdx = this.chartIdx + 1
    },
    prev() {
      this.chartIdx = this.chartIdx - 1
    },
  },
}
</script>
<style>
.bg-head-budget-plan-vs-expanse {
  background: #ff0000;
}
</style>
