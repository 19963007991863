var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("HISTORY REPAIR & MAINTENANCE - TB. ETI 201")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_c('a', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.collapse-filter",
            modifiers: {
              "collapse-filter": true
            }
          }],
          staticClass: "text-primary float-right",
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "role": "button"
          }
        }, [_c('i', {
          staticClass: "fa fa-calendar"
        })])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "id": "collapse-filter"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('date-picker', {
          attrs: {
            "lang": _vm.lang,
            "type": "date",
            "range": "",
            "placeholder": "Select date range"
          },
          model: {
            value: _vm.dateRange,
            callback: function callback($$v) {
              _vm.dateRange = $$v;
            },
            expression: "dateRange"
          }
        })], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-button', {
          staticClass: "mb-3 mr-1",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)], 1)], 1), _c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("TOTAL REPAIR")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TOTAL COST")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("LOKASI")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("TANGGAL")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("STATUS")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("MINOR")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("MEDIUM")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("MAJOR")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("ACTION")])])]), _c('tbody', [_c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("53 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Rp. 1.238.203.200,-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("MOROSI - KENDARI")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("15 Juni 2022")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          staticClass: "mb-1",
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("On Time : 45 Jobs")]), _c('br'), _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("Delay : 23 Jobs")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("15 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("31 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("22 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "sm",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.detail_sr",
            modifiers: {
              "detail_sr": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-info-circle mr-2"
        }), _vm._v("Detail ")])], 1)], 1)], 1)]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("36 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Rp. 1.375.123.180,-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("CELUKAN BAWANG")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("18 Januari 2022")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          staticClass: "mb-1",
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("On Time : 32 Jobs")]), _c('br'), _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("Delay : 4 Jobs")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("15 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("12 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("16 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "sm",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.detail_sr",
            modifiers: {
              "detail_sr": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-info-circle mr-2"
        }), _vm._v("Detail ")])], 1)], 1)], 1)]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("64 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Rp. 1.837.129.500,-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("TEGAL")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("25 September 2021")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          staticClass: "mb-1",
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("On Time : 43 Jobs")]), _c('br'), _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("Delay : 21 Jobs")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("15 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("11 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("23 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "sm",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.detail_sr",
            modifiers: {
              "detail_sr": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-info-circle mr-2"
        }), _vm._v("Detail ")])], 1)], 1)], 1)]), _c('tr', [_c('td', {
          staticClass: "text-center"
        }, [_vm._v("72 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("Rp. 1.387.384.000,-")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("SANGATTA")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("08 Maret 2021")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-badge', {
          staticClass: "mb-1",
          attrs: {
            "variant": "success"
          }
        }, [_vm._v("On Time : 52 Jobs")]), _c('br'), _c('b-badge', {
          attrs: {
            "variant": "danger"
          }
        }, [_vm._v("Delay : 20 Jobs")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("21 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("17 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_vm._v("19 Jobs")]), _c('td', {
          staticClass: "text-center"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "sm",
            "variant": "link iq-bg-primary iq-border-radius-10",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('span', {
                staticClass: "dropdown-toggle p-0",
                attrs: {
                  "id": "dropdownMenuButton5",
                  "data-toggle": "dropdown"
                }
              }, [_c('i', {
                staticClass: "fa fa-pencil-square-o m-0 text-primary"
              })])];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_c('b-dropdown-item', {
          directives: [{
            name: "b-toggle",
            rawName: "v-b-toggle.detail_sr",
            modifiers: {
              "detail_sr": true
            }
          }],
          attrs: {
            "href": "#"
          }
        }, [_c('i', {
          staticClass: "fa fa-info-circle mr-2"
        }), _vm._v("Detail ")])], 1)], 1)], 1)])])]), _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "value": 1,
            "total-rows": 50,
            "align": "center"
          }
        })], 1), _c('b-sidebar', {
          attrs: {
            "id": "detail_sr",
            "title": "DETAIL REPAIR & MAINTENANCE - TB. ETI 201",
            "width": "70%",
            "backdrop-variant": "dark",
            "bg-variant": "white",
            "right": "",
            "backdrop": "",
            "shadow": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref) {
              var hide = _ref.hide;
              return [_c('b-row', {
                staticClass: "m-3"
              }, [_c('b-col', {
                attrs: {
                  "md": "12"
                }
              }, [_c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2 text-primary"
              }, [_vm._v("Detail Information")]), _c('table', {
                staticClass: "table mb-0 table-striped"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Lokasi Pekerjaan")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Morosi")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Tanggal")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("15 Juni 2022")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Office / Site")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Kendari")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("PIC Repair")]), _c('td', {
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Sangatta")])])])])]), _c('div', {
                staticClass: "accordion mb-3",
                attrs: {
                  "role": "tablist"
                }
              }, [_c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-1",
                  modifiers: {
                    "job-1-1": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "info"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" FUEL INJECTOR - PEBAIKAN KEBOCORAN FUEL INJECTOR "), _c('strong', [_vm._v("(REVIEW)")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-1-1",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-3"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Equipment Name")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Fuel Injector")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Repair Title")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Perbaikan Kebocoran Fuel Injector")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Type R & M")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Running Maintenance")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Department")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Engine")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Category")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Mechanical")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Classification")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Minor (3 Days)")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Cause")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Material Fatique")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Kind Task")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Repair")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Tanggal Mulai")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("12 Januari 2023")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Tanggal Selesai (Est)")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("15 Januari 2023")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Berita Acara")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                staticClass: "align-top",
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Description")]), _c('td', {
                staticClass: "align-top text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                staticClass: "align-top",
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                attrs: {
                  "type": "number"
                }
              }, [_c('li', [_vm._v("- Remove the injector")]), _c('li', [_vm._v(" - Clean up deposit "), _c('ul', [_c('li', [_vm._v("- Clean parts using a brass brush to prevent damage to polished surfaces.")]), _c('li', [_vm._v("- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.")]), _c('li', [_vm._v("- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.")])])]), _c('li', [_vm._v("- Pressure injection test, check if the pressure isn't same with maker standart.")]), _c('li', [_vm._v("- Fit up")])])])])])])])], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-2",
                  modifiers: {
                    "job-1-2": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "primary"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" SENSOR - KALIBRASI SENSOR SONAR "), _c('strong', [_vm._v("(APPROVE)")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-1-2",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-3"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Equipment Name")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Sensor")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Repair Title")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Kalibrasi Sensor Sonar")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Type R & M")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Running Maintenance")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Department")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Deck")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Category")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("General Service")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Classification")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Medium (7 Days)")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Cause")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Human Error")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Kind Task")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Renew")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Tanggal Mulai")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("20 Januari 2023")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Tanggal Selesai (Est)")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("27 Januari 2023")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Berita Acara")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                staticClass: "align-top",
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Description")]), _c('td', {
                staticClass: "align-top text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                staticClass: "align-top",
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                attrs: {
                  "type": "number"
                }
              }, [_c('li', [_vm._v("- Remove the injector")]), _c('li', [_vm._v(" - Clean up deposit "), _c('ul', [_c('li', [_vm._v("- Clean parts using a brass brush to prevent damage to polished surfaces.")]), _c('li', [_vm._v("- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.")]), _c('li', [_vm._v("- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.")])])]), _c('li', [_vm._v("- Pressure injection test, check if the pressure isn't same with maker standart.")]), _c('li', [_vm._v("- Fit up")])])])])])])])], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-3",
                  modifiers: {
                    "job-1-3": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "warning"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" ELECTRICAL - PERBAIAKAN KORSLETING LISTRIK "), _c('strong', [_vm._v("(PROGESS)")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-1-3",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-3"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Equipment Name")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Electrical")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Repair Title")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Perbaiakan Korsleting Ruang Deck")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Type R & M")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Emergency Maintenance")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Department")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Deck")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Category")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Electrical")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Classification")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Minor (3 Days)")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Cause")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Material Fatique")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Kind Task")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Repair")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Tanggal Mulai")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("18 Januari 2023")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Tanggal Selesai (Est)")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("21 Januari 2023")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Berita Acara")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                staticClass: "align-top",
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Description")]), _c('td', {
                staticClass: "align-top text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                staticClass: "align-top",
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                attrs: {
                  "type": "number"
                }
              }, [_c('li', [_vm._v("- Remove the injector")]), _c('li', [_vm._v(" - Clean up deposit "), _c('ul', [_c('li', [_vm._v("- Clean parts using a brass brush to prevent damage to polished surfaces.")]), _c('li', [_vm._v("- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.")]), _c('li', [_vm._v("- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.")])])]), _c('li', [_vm._v("- Pressure injection test, check if the pressure isn't same with maker standart.")]), _c('li', [_vm._v("- Fit up")])])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('table', {
                staticClass: "table table-striped table-sm"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("NAMA LENGKAP")]), _c('th', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("JABATAN")])]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_vm._v("Agung Tri Laksono")]), _c('td', [_vm._v("Manager Engineer")])]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_vm._v("Nurul Akbar")]), _c('td', [_vm._v("Senior Engineer")])]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_vm._v("Hanel Prilian")]), _c('td', [_vm._v("Engineer")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("REPAIR & MAINTENANCE TIME LINE (PROGESS & DELAY FACTOR)")]), _c('ul', {
                staticClass: "iq-timeline",
                staticStyle: {
                  "height": "500px"
                }
              }, [_c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("24 November 2019 15:45")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Melakukan inspeksi pada kerusakan")]), _c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/6.jpg"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/1.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/4.jpg"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/3.jpg"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("23 November 2019 21:25")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Sparepart tiba kapal")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Tri Leksono")]), _c('br'), _c('small', [_vm._v("22 November 2019 10:12")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Menunggu sparepart tiba")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("21 November 2019 11:21")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Melengkapi dokumen perbaikan")]), _c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])])], 1), _c('b-card', {
                attrs: {
                  "no-body": ""
                }
              }, [_c('b-card-header', {
                staticClass: "p-1",
                staticStyle: {
                  "background-color": "white",
                  "border": "none"
                },
                attrs: {
                  "header-tag": "header",
                  "role": "tab"
                }
              }, [_c('b-button', {
                directives: [{
                  name: "b-toggle",
                  rawName: "v-b-toggle.job-1-4",
                  modifiers: {
                    "job-1-4": true
                  }
                }],
                staticClass: "text-left",
                attrs: {
                  "block": "",
                  "variant": "success"
                }
              }, [_c('i', {
                staticClass: "fa fa-chevron-circle-down"
              }), _vm._v(" ELECTRICAL - INSTALL LO COOLER AE PORTSIDE "), _c('strong', [_vm._v("(FINISH)")])])], 1), _c('b-collapse', {
                attrs: {
                  "id": "job-1-4",
                  "accordion": "my-accordion-sub",
                  "role": "tabpanel"
                }
              }, [_c('table', {
                staticClass: "table table-striped mt-3"
              }, [_c('tbody', [_c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Equipment Name")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Electrical")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Repair Title")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Install LO cooler AE portside")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Type R & M")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Plan Maintenance")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Department")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Engine")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Category")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Electrical")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Classification")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Major (21 Days)")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Cause")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Weather")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Kind Task")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("Renew")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Tanggal Mulai")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("10 Januari 2023")]), _c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Tanggal Selesai (Est)")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "width": "25%"
                }
              }, [_vm._v("01 Februari 2023")])]), _c('tr', [_c('th', {
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Berita Acara")]), _c('td', {
                staticClass: "text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('tr', [_c('th', {
                staticClass: "align-top",
                attrs: {
                  "width": "20%"
                }
              }, [_vm._v("Description")]), _c('td', {
                staticClass: "align-top text-center",
                attrs: {
                  "width": "5%"
                }
              }, [_vm._v(":")]), _c('td', {
                staticClass: "align-top",
                attrs: {
                  "colspan": "4"
                }
              }, [_c('ul', {
                attrs: {
                  "type": "number"
                }
              }, [_c('li', [_vm._v("- Remove the injector")]), _c('li', [_vm._v(" - Clean up deposit "), _c('ul', [_c('li', [_vm._v("- Clean parts using a brass brush to prevent damage to polished surfaces.")]), _c('li', [_vm._v("- Nozzle holder can be cleaned in solvent tank, however, nozzle valve assembly should be cleaned by hand to prevent damage.")]), _c('li', [_vm._v("- Inspect needle valve for signs of burning, if burned or discoloration is evident, valve assembly should be replaced.")])])]), _c('li', [_vm._v("- Pressure injection test, check if the pressure isn't same with maker standart.")]), _c('li', [_vm._v("- Fit up")])])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Internal Man Power")]), _c('table', {
                staticClass: "table table-striped table-sm"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3",
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("NAMA LENGKAP")]), _c('th', {
                attrs: {
                  "width": "50%"
                }
              }, [_vm._v("JABATAN")])]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_vm._v("Agung Tri Laksono")]), _c('td', [_vm._v("Manager Engineer")])]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_vm._v("Nurul Akbar")]), _c('td', [_vm._v("Senior Engineer")])]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_vm._v("Hanel Prilian")]), _c('td', [_vm._v("Engineer")])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("Sub Kontraktor")]), _c('table', {
                staticClass: "table table-striped table-sm"
              }, [_c('tbody', [_c('tr', [_c('th', {
                staticClass: "pl-3"
              }, [_vm._v("NAMA KONTRAKTOR")]), _c('th', [_vm._v("PIC/EXECUTIVE ENGINEER")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("QUOTATION")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("FINAL QUOTATION")]), _c('th', {
                staticClass: "text-center"
              }, [_vm._v("BERITA ACARA")])]), _c('tr', [_c('td', {
                staticClass: "pl-3"
              }, [_vm._v("PT. Cahaya Marine")]), _c('td', [_vm._v("Dendy Prasetya")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Rp. 30.000.000,-")]), _c('td', {
                staticClass: "text-center"
              }, [_vm._v("Rp. 27.500.000,-")]), _c('td', {
                staticClass: "text-center"
              }, [_c('i', {
                staticClass: "fa fa-file-pdf text-primary h4 mr-2"
              }), _c('i', {
                staticClass: "fa fa-file-pdf text-primary h4 mr-2"
              }), _c('i', {
                staticClass: "fa fa-file-pdf text-primary h4 mr-2"
              })])])])])]), _c('fieldset', {
                staticClass: "form-group border p-3"
              }, [_c('legend', {
                staticClass: "w-auto px-2"
              }, [_vm._v("REPAIR & MAINTENANCE TIME LINE (PROGESS & DELAY FACTOR)")]), _c('ul', {
                staticClass: "iq-timeline",
                staticStyle: {
                  "height": "500px"
                }
              }, [_c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("24 November 2019 15:45")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Melakukan inspeksi pada kerusakan")]), _c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/6.jpg"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/1.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/4.jpg"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-2 col-2 pb-2"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/3.jpg"),
                  "alt": "gallary-image"
                }
              })])])])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Agung Tri Laksono")]), _c('br'), _c('small', [_vm._v("23 November 2019 21:25")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Sparepart tiba kapal")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Tri Leksono")]), _c('br'), _c('small', [_vm._v("22 November 2019 10:12")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Menunggu sparepart tiba")])])]), _c('li', [_c('div', {
                staticClass: "timeline-dots border-success"
              }), _c('h6', {
                staticClass: "float-left mb-1"
              }, [_vm._v("Nurul Akbar")]), _c('br'), _c('small', [_vm._v("21 November 2019 11:21")]), _c('div', {
                staticClass: "d-inline-block w-100"
              }, [_c('p', [_vm._v("Melengkapi dokumen perbaikan")]), _c('ul', {
                staticClass: "profile-img-gallary d-flex flex-wrap p-0 m-0"
              }, [_c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/pdf.png"),
                  "alt": "gallary-image"
                }
              })])]), _c('li', {
                staticClass: "col-md-1 col-1"
              }, [_c('a', {
                attrs: {
                  "href": "javascript:void(0);"
                }
              }, [_c('img', {
                staticClass: "img-fluid",
                attrs: {
                  "src": require("../../assets/images/activity/excel.png"),
                  "alt": "gallary-image"
                }
              })])])])])])])])])], 1)], 1)])], 1)];
            }
          }])
        })];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }