var render = function render(){
  var _vm$detailData, _vm$detailData$total_, _vm$detailData2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end w-100"
  }, [_c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "danger",
      "type": "button"
    },
    on: {
      "click": _vm.closeSlide
    }
  }, [_c('div', [_c('i', {
    staticClass: "fa fa-ban"
  }), _c('span', [_vm._v("Cancel")])])])], 1), _c('hr')]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3"
  }, [_c('legend', {
    staticClass: "w-auto px-2 text-primary"
  }, [_vm._v("Detail Stock Adjustment")]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex overflow-auto"
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', {
    staticClass: "bg-primary"
  }, [_c('tr', [_c('th', {
    staticClass: "text-truncate",
    staticStyle: {
      "width": "220px"
    }
  }, [_vm._v("PRODUCT CODE")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("PRODUCT")]), _c('th', {
    staticClass: "text-truncate text-center"
  }, [_vm._v("AVAILABLE STOCK")]), _c('th', {
    staticClass: "text-truncate text-center"
  }, [_vm._v("QTY")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("TYPE")]), _c('th', {
    staticClass: "text-truncate"
  }, [_vm._v("REMARKS")])])]), _c('tbody', _vm._l((_vm$detailData = _vm.detailData) === null || _vm$detailData === void 0 ? void 0 : _vm$detailData.details, function (state, index) {
    var _state$items, _state$items2, _state$qty, _state$remarks;
    return _c('tr', [_c('td', {
      staticStyle: {
        "width": "220px"
      }
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$items = state.items) === null || _state$items === void 0 ? void 0 : _state$items.code))]), _c('td', [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : (_state$items2 = state.items) === null || _state$items2 === void 0 ? void 0 : _state$items2.name))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(state === null || state === void 0 ? void 0 : state.stock_available))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s((_state$qty = state === null || state === void 0 ? void 0 : state.qty) !== null && _state$qty !== void 0 ? _state$qty : 0))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s((state === null || state === void 0 ? void 0 : state.type) === 'stock_in' ? 'Stock In' : 'Stock Out'))]), _c('td', [_vm._v(_vm._s((_state$remarks = state === null || state === void 0 ? void 0 : state.remarks) !== null && _state$remarks !== void 0 ? _state$remarks : '-'))])]);
  }), 0), _c('tfoot', [_c('tr', [_c('th', {
    staticClass: "text-center",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("Total Qty")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s((_vm$detailData$total_ = (_vm$detailData2 = _vm.detailData) === null || _vm$detailData2 === void 0 ? void 0 : _vm$detailData2.total_qty) !== null && _vm$detailData$total_ !== void 0 ? _vm$detailData$total_ : 0))])])])])])])], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }