var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('tab-nav', {
    staticClass: "button-container bg-white rounded mb-4 px-3 btn-group",
    attrs: {
      "pills": true
    }
  }, _vm._l(_vm.listNav, function (item, index) {
    return _c('tab-nav-items', {
      key: index,
      staticClass: "p-0 text-center py-3 rounded",
      attrs: {
        "active": item.active,
        "href": item.href,
        "title": item.title
      }
    });
  }), 1), _c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "pills-tabContent-2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "task-type"
    }
  }, [_c('SrMasterTaskType')], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "category"
    }
  }, [_c('SrMasterCategory')], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "classification"
    }
  }, [_c('SrMasterClassification')], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "cause"
    }
  }, [_c('SrMasterCause')], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "kind-task"
    }
  }, [_c('SrMasterKindTask')], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "activity"
    }
  }, [_c('SrMasterActivity')], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "reject-reason"
    }
  }, [_c('SrMasterRejectReason')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }