<template>
  <b-container fluid>
    <form @submit.prevent="onSaveTripDocument">
      <b-row>
        <b-col md="12">
          <b-button variant="primary" class="float-right mr-1" size="sm" type="submit" v-if="['EDIT', 'ADD'].includes(action)"><i class="fa fa-save"></i> Create Trip Document&nbsp; </b-button>
          <b-button variant="danger" class="float-right mr-1" size="sm" type="button" @click="closeSlideFormTripDocument"><i class="fa fa-ban"></i> Cancel&nbsp; </b-button>
        </b-col>
        <b-col md="12" class="p-4">
          <b-row>
            <b-col md="6">
              <b-form-group label="Document Name">
                <b-form-input v-if="['EDIT', 'ADD'].includes(action)" placeholder="Type Text" type="text" v-model="form.name_document"  />
                <strong v-if="['PREVIEW'].includes(action)">{{form.name_document}}</strong>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="projectData?.project_contract_plan?.length > 0">
              <b-form-group label="For Trip">
                <v-select
                  v-if="['EDIT', 'ADD'].includes(action)"
                  label="shipment_plan_detail_number"
                  track-by="id"
                  name="plan_id"
                  v-model="form.plan_id"
                  :options="projectData?.project_contract_plan"
                  :reduce="(plan) => plan.id"
                  placeholder="Select..."
                  required
                ></v-select>
                <strong v-if="['PREVIEW'].includes(action)">{{form.plan_id}}</strong>
              </b-form-group>
            </b-col>
            
            <b-col md="12">
              <b-form-group label="Documents">
                <b-form-file v-if="['EDIT', 'ADD'].includes(action)" accept="application/*" multiple v-model="form.document" name='document' id="document" class="border p-2"></b-form-file>
                <div class="d-flex flex-column" v-if="tripData?.trip_attachment?.length > 0 && ['EDIT', 'PREVIEW'].includes(action)">
                  <div class="d-flex align-items-center mb-1" style="cursor:pointer" v-for="(file, fileIndex) in tripData?.trip_attachment" :key="fileIndex"  @click="handleOpenFilePreview(`${apiUrl}/${file?.file_location}`)">
                    <i class="fa fa-file text-primary mr-2" />
                    <span class="text-primary text-truncate">{{file.file_name}}</span>
                  </div>
                </div>
              </b-form-group>
            </b-col>

          </b-row>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>

<script>
  import _ from 'lodash'
  import moment from 'moment'
  import { busdevActions } from '@src/Utils/helper'

  export default {
    name:'SlideTripDocument',
    props:{
      action:{
        type:String,
        default:''
      },  
      id:{
        type:Number,
        default:null
      }, 
      refreshData:{
        type:Function,
        default:true
      }, 
      closeSlideFormTripDocument:{
        type:Function,
        default:true
      },  
      projectData:{
        type:Object,
        default:null
      }, 
    },
    components:{
    },
    data(){
      return{
        apiUrl: process.env.VUE_APP_API_BUSDEV_BASE_URL,

        form:{
          name_document: '',
          plan_id: '',
          document: [],
        },

        tripData:null
      }
    },
    watch:{
      id: {
        deep: true,
        handler: function (value) {
          if (value) {
            this.getDetailTripDocument(value)
          }
        },
      },
      action: {
        deep: true,
        handler: function (value) {
          if (value === "ADD") {
            this.resetForm()
          }
        },
      }
    },
    mounted(){},
    methods:{
      ...busdevActions,
      handleOpenFilePreview: function (url) {
        window.open(url, "_blank");
      },
      resetForm(){
        this.form = {
          name_document: '',
          plan_id: '',
          document: [],
        }
      },

      async getDetailTripDocument(id){
        const res = await this.fetchTripDocumentById({id})

        if(res?.data?.status === true){
          let data = res.data.data

          this.tripData = data
          
          this.form = {
            name_document: data?.name_document,
            plan_id: data?.plan_id,
            document: [],
          }
        }
      },

      async onSaveTripDocument(){
        let payload = {
          ...this.form,
          project_contract_id: this.projectData.id
        }

        let formData = new FormData()
        formData.append('name_document', payload.name_document)
        formData.append('plan_id', payload.plan_id)
        formData.append('project_contract_id', payload.project_contract_id)

        if(payload.document?.length > 0){
          // Append each file to FormData
          for (let i = 0; i < payload.document.length; i++) {
            formData.append('files', payload.document[i]);
          }
        }

        let res = null

        if(['EDIT'].includes(this.action) && this.id){
          res = await this.updateTripDocument({data:formData, id: Number(this.id)})
        } else {
          res = await this.createTripDocument({data:formData})
        }

        if(res.data.status === true){
          this.refreshData()
          this.resetForm()
          this.closeSlideFormTripDocument()
          this.$swal(`Success!`, `Create Trip Document Success.`, 'success')
        }
      }
    }
  }
</script>
