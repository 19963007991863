<template>
  <b-row>
    <b-col lg="12" class="px-5 py-2">
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title text-primary"><strong>List Notice Sign Off</strong></h4>
        </template>
        <template v-slot:body>
          <b-row>
            <b-col md="12" class="mb-2">
              <b-button variant="primary" @click="onExportListNoticeSignOff" v-if="list?.data?.length > 0">
                <div class="d-flex gap-2 align-items-center"> 
                  <span class="spinner-border spinner-border-sm mr-2" v-if="exporting"></span>
                  <i class="fa fa-file-excel mr-2" v-else></i> 
                  <span>Export</span>
                </div>
              </b-button>
            </b-col>
            <b-col md="3">
              <b-form-group label="Search">
                <b-form-input v-model="query.filter" placeholder="Search..."></b-form-input>
              </b-form-group>
            </b-col> 
            <b-col md="3">
              <b-form-group label="Position">
                <v-select
                  v-model="query.position_id"
                  label="text"
                  :options="optionsPosition"
                  :reduce="
                    (optionsPosition) => optionsPosition.value
                  "
                  placeholder="Select..."
                  required
                ></v-select>
              </b-form-group>
            </b-col> 
            <b-col md="3">
              <b-form-group label="Fleet">
                <v-select
                  v-model="query.vehicle_id"
                  label="text"
                  :options="fleetOptions"
                  :reduce="
                    (fleetOptions) => fleetOptions.value
                  "
                  placeholder="Select..."
                  required
                ></v-select>
              </b-form-group>
            </b-col> 
            <b-col md="3">
              <b-form-group label="Company">
                <v-select
                  v-model="query.company_id"
                  label="text"
                  :options="optionsCompanies"
                  :reduce="
                    (optionsCompanies) => optionsCompanies.value
                  "
                  placeholder="Select..."
                  required
                ></v-select>
              </b-form-group>
            </b-col> 
            <b-col md="4" class="mb-2">
              <b-form-group label="Date">
                <date-picker
                v-model="dateRange"
                type="date"
                range
                placeholder="Select date range"
              ></date-picker>
              </b-form-group>
            </b-col>
            <b-col md="1">
              <b-form-group label="Filter">
                <b-button
                  @click="filterData"
                  variant="primary"
                  ><i class="fa fa-filter"></i
                ></b-button>
              </b-form-group>
            </b-col>
            <b-col  v-if="loading" md="12">
              <div class="alert alert-info m-4 align-items-center ">
                <span class="spinner-border spinner-border-sm mr-2"></span>  Loading...
              </div>
            </b-col>
            <b-col md="12" v-else>
              <div class="d-flex overflow-auto" v-if="list?.data?.length > 0">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th class=" text-center">NO</th>
                      <th class=" text-center text-truncate">Name</th>
                      <th class=" text-center text-truncate">Fleet</th>
                      <th class=" text-center text-truncate">Position</th>
                      <th class=" text-center text-truncate">Join on Board</th>
                      <th class=" text-center text-truncate ">Sign On</th>
                      <th class=" text-center text-truncate">Notice Sign Off</th>
                      <th class=" text-center">Status</th>
                    </tr>
                  </thead>
  
                  <tbody>
                    <tr v-for="(crew, i) in list?.data" :key="'crew-' + i" >
                      <td class="text-center">{{ i + 1 }}</td>
                      <td class="text-truncate">
                        <router-link
                          :to="{
                            path: `profile`,
                            query: {
                              user: 'crew',
                              id: crew?.id,
                            },
                          }">{{ crew?.fullname ?? '-' }}</router-link><br />
                          <small style="color: gray">NIP : <b>{{ crew?.nip ?? '-' }}</b></small>
                      </td>
                      <td class="text-center text-truncate">{{ crew?.vehicle?.name ?? '-' }}
                        <br />
                        <small style="color: gray">{{crew?.company?.company ?? '-'}}</small>
                      </td>
                      <td class="text-center text-truncate">{{ crew?.position?.name ?? '-' }} </td>
                      <td class="text-center text-truncate">{{ crew?.join_on_board ? moment(crew?.join_on_board).format('DD MMM YYYY HH:mm') : '-' }}
                        <b-badge
                          :variant="
                            joinOnBoardStatus(crew) === 'More than 1 year'
                              ? 'success d-block'
                              : joinOnBoardStatus(crew) === 'More than 6 month'
                              ? 'primary d-block'
                              : joinOnBoardStatus(crew) === 'More than 3 month'
                              ? 'warning d-block'
                              : 'dark d-block'
                          "
                          v-if="joinOnBoardStatus(crew)">{{ joinOnBoardStatus(crew) }}</b-badge>
                      </td>
                      <td class="text-center text-truncate">{{ crew?.sign_on ? moment(crew?.sign_on).format('DD MMM YYYY HH:mm') : '-' }}</td>
                      <td class="text-center text-truncate">{{ crew?.notice_off ? moment(crew?.notice_off).format('DD MMM YYYY HH:mm') :  '-' }} <br /> </td>
                      <td class="text-center text-truncate">
                        <b-badge class="mb-1" :variant="`${crew?.status_emp_crewing?.alias === 'active' ? 'success' : 'warning'} d-block`"
                          >{{ crew?.status_emp_crewing?.name ?? '-' }}
                        </b-badge>
                        <b-badge variant="danger d-block" v-if="expiredContract(crew)"
                          >{{ expiredContract(crew) }}
                        </b-badge>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="mt-3" v-if="list?.data?.length > 0">
                <b-pagination
                  :value="list?.current_page"
                  :total-rows="list?.total"
                  :per-page="list?.per_page"
                  first-number
                  @change="getListNoticeSignOffCrew"
                  align="center"
                />
              </div>
              <p v-else class="text-center my-4">List Notice Sign Off Crew tidak ada.</p>
            </b-col>
          </b-row>
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>

<script>
import moment from 'moment'
import { saveAs } from 'file-saver'
import { crewingActions } from '@src/Utils/helper'

export default {
  name: 'SlideListNoticeSignOff',
  props:{  
    fleetOptions:{
      type:Array,
      default:() => []
    }, 
    optionsPosition:{
      type:Array,
      default:() => []
    }, 
    optionsCompanies:{
      type:Array,
      default:() => []
    },
  },
  data(){
    return {
      loading:false,
      exporting:false,

      dateRange: [new Date(moment().startOf('year')), new Date(moment().endOf('year'))],

      list:null,
      query:{
        filter:'',
        company_id:null,
        vehicle_id:null,
        position_id:null,
      }
    }
  },
  async mounted(){
    await this.getListNoticeSignOffCrew()
  },
  methods:{
    ...crewingActions,
    joinOnBoardStatus(data) {
      if (!data?.emp_crewing?.join_on_board) return ''
      if (data.emp_crewing?.working_period > 12) {
        return 'More than 1 year'
      } else if (data.emp_crewing?.working_period > 6) {
        return 'More than 6 month'
      } else if (data.emp_crewing?.working_period > 3) {
        return 'More than 3 month'
      } else {
        return 'Less than 3 month'
      }
    },
    expiredContract(data) {
      return moment(data?.emp_crewing?.sign_off).format('DD-MM-YYYY') <
        moment(new Date()).format('DD-MM-YYYY') &&
        data?.emp_crewing?.status_emp_crewing?.name === 'Active'
        ? 'Out of Contract'
        : ''
    },
    async getListNoticeSignOffCrew(page){
      try {
        this.loading = true

        let params = {
          ...this.query,
          export: 0,
          page: page || 1,
          per_page:50,
          start_date: this.dateRange?.[0] ? moment(new Date(this.dateRange[0])).format('YYYY-MM-DD') : null,
          end_date: this.dateRange?.[1] ? moment(new Date(this.dateRange[1])).format('YYYY-MM-DD') : null
        }

        if(!params.company_id) delete params.company_id
        if(!params.vehicle_id) delete params.vehicle_id
        if(!params.position_id) delete params.position_id
        if(!params.filter) delete params.filter
        if(!params.start_date) delete params.start_date
        if(!params.end_date) delete params.end_date

        const res = await this.fetchListNoticeSignOff(params)

        this.list = res.data
        this.loading = false
      } catch (err) {
        this.loading = false
        this.list = null
      }
    },

    async onExportListNoticeSignOff(){
      try {
        this.exporting = true

        let params = {
          ...this.query,
          export: 1,
          start_date: this.dateRange?.[0] ? moment(new Date(this.dateRange[0])).format('YYYY-MM-DD') : null,
          end_date: this.dateRange?.[1] ? moment(new Date(this.dateRange[1])).format('YYYY-MM-DD') : null
        }

        if(!params.company_id) delete params.company_id
        if(!params.vehicle_id) delete params.vehicle_id
        if(!params.position_id) delete params.position_id
        if(!params.filter) delete params.filter
        if(!params.start_date) delete params.start_date
        if(!params.end_date) delete params.end_date

        const res = await this.exportListNoticeSignOff(params)

        saveAs(res?.data, 'List Notice Sign Off.xlsx')
        this.exporting = false
      } catch (error) {
        this.$swal(`Oops!`, 'Export Failed: ' + error, 'error')
        this.exporting = false
      }
    },

    async filterData(){
      await this.getListNoticeSignOffCrew(1)
    },
  }
}
</script>