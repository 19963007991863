var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.activeRecommendation ? _c('div', [_c('b-modal', {
    attrs: {
      "size": "lg",
      "ok-title": "Filter",
      "centered": ""
    },
    on: {
      "ok": function ok($event) {
        return _vm.handleFetchRecommendation(1);
      }
    },
    model: {
      value: _vm.modal.filter,
      callback: function callback($$v) {
        _vm.$set(_vm.modal, "filter", $$v);
      },
      expression: "modal.filter"
    }
  }, [_c('b-row', {
    staticClass: "px-3"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "dateIssued",
      "label": "Date Range"
    }
  }, [_c('date-picker', {
    staticClass: "w-100",
    attrs: {
      "type": "date",
      "value-type": "YYYY-MM-DD",
      "range": "",
      "placeholder": "Select date range"
    },
    on: {
      "change": _vm.handleDateRange
    },
    model: {
      value: _vm.dateRange,
      callback: function callback($$v) {
        _vm.dateRange = $$v;
      },
      expression: "dateRange"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "dateIssued",
      "label": "Fleet/Vessel"
    }
  }, [_vm.vehicleId ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.fleets.find(function (fleet) {
    return fleet.code === _vm.vehicleId;
  }) ? _vm.fleets.find(function (fleet) {
    return fleet.code === _vm.vehicleId;
  }).name : '-') + " ")]) : _c('v-select', {
    attrs: {
      "label": "name",
      "options": _vm.fleets,
      "reduce": function reduce(fleetList) {
        return fleetList.code;
      }
    },
    model: {
      value: _vm.recommendations.params.vehicle_id,
      callback: function callback($$v) {
        _vm.$set(_vm.recommendations.params, "vehicle_id", $$v);
      },
      expression: "recommendations.params.vehicle_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "dateIssued",
      "label": "Type of Coverage"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "name",
      "options": _vm.typeCoverages,
      "reduce": function reduce(typeOfCoverage) {
        return typeOfCoverage.code;
      }
    },
    model: {
      value: _vm.recommendations.params.type_coverage_id,
      callback: function callback($$v) {
        _vm.$set(_vm.recommendations.params, "type_coverage_id", $$v);
      },
      expression: "recommendations.params.type_coverage_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "dateIssued",
      "label": "Type of Loss"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "name",
      "options": _vm.typeLoss,
      "reduce": function reduce(typeOfLoss) {
        return typeOfLoss.code;
      }
    },
    model: {
      value: _vm.recommendations.params.type_loss_id,
      callback: function callback($$v) {
        _vm.$set(_vm.recommendations.params, "type_loss_id", $$v);
      },
      expression: "recommendations.params.type_loss_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "dateIssued",
      "label": "Status"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "text",
      "options": _vm.statusOptions,
      "reduce": function reduce(statusOptions) {
        return statusOptions.value;
      }
    },
    model: {
      value: _vm.recommendations.params.status_id,
      callback: function callback($$v) {
        _vm.$set(_vm.recommendations.params, "status_id", $$v);
      },
      expression: "recommendations.params.status_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "1"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Filter",
      "label-for": "button_search"
    }
  }, [_c('b-button', {
    staticClass: "mb-3 mr-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {}
    }
  }, [_c('i', {
    staticClass: "fa fa-filter"
  })])], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Recommendation Form List")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-content-between mb-3"
        }, [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-form-input', {
          attrs: {
            "size": "md",
            "placeholder": "search..."
          }
        }), _c('b-button', {
          staticClass: "mx-2",
          attrs: {
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })]), _c('b-button', {
          staticClass: "d-flex align-items-center",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": _vm.toggleModalFilter
          }
        }, [_c('i', {
          staticClass: "fas fa-sliders"
        }), _c('p', {
          staticClass: "text-truncate m-0"
        }, [_vm._v(" Filter Data")])])], 1), _c('div', [_vm.canExport || _vm.canExportRecommendation ? _c('router-link', {
          attrs: {
            "to": "#"
          }
        }, [_c('button', {
          staticClass: "btn btn-dark mr-1"
        }, [_c('i', {
          staticClass: "fa fa-file-excel"
        }), _vm._v(" Export")])]) : _vm._e(), _vm.canAdd && _vm.canAddRecommendation ? _c('router-link', {
          attrs: {
            "to": "/mi/add-form-recommendation"
          }
        }, [_c('button', {
          staticClass: "btn btn-primary"
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" Add Form Recommendation")])]) : _vm._e()], 1)]), _c('b-row', [_c('b-col', {
          staticClass: "text-right",
          attrs: {
            "md": "12"
          }
        }), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('table', {
          staticClass: "table mb-0 table-borderless table-box-shadow"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("No.")]), _c('th', [_vm._v("Permasalahan")]), _c('th', [_vm._v("Vessel Case")]), _c('th', [_vm._v("Root Cause")]), _c('th', [_vm._v("Rekomendasi")]), _c('th', [_vm._v("Cause of Loss")]), _c('th', [_vm._v("Status")]), _c('th', [_vm._v("Closed Date")]), _c('th', [_vm._v("Action")])])]), _c('tbody', [_vm.recommendations.data.length === 0 || _vm.recommendations.isLoading ? _c('tr', [_c('td', {
          staticClass: "text-center",
          attrs: {
            "colspan": "10"
          }
        }, [_vm._v(_vm._s(_vm.recommendations.isLoading ? 'Loading...' : "No Data"))])]) : _vm._e(), _vm._l(_vm.recommendations.data, function (data, index) {
          return _c('tr', {
            key: index
          }, [_c('td', [_vm._v(_vm._s(_vm.recommendations.meta.pageNumber + index))]), _c('td', [_vm._v(_vm._s(data.problem))]), _c('td', [_vm._v(_vm._s(data.vessel_case))]), _c('td', [_vm._v(_vm._s(data.root_cause))]), _c('td', [_vm._v(_vm._s(data.recommendation))]), _c('td', [_vm._v(_vm._s(_vm.causesOfLoss.find(function (val) {
            return data.cause_of_loss_id === val.code;
          }) ? _vm.causesOfLoss.find(function (val) {
            return data.cause_of_loss_id === val.code;
          }).name : '-'))]), _c('td', [_vm._v(_vm._s(data.status))]), _c('td', [_vm._v(_vm._s(data.closed_at))]), _c('td', [_vm.canEditRecommendation ? _c('b-button', {
            attrs: {
              "variant": "info",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.handleEditRecommendation(data.id, data.form_id);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]) : _vm._e(), _vm.canDeleteRecommendation ? _c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.handleDeleteRecommendation(data);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })]) : _vm._e()], 1)]);
        })], 2)]), _c('div', {
          staticClass: "mt-3 mb-3"
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.recommendations.meta.total,
            "per-page": _vm.recommendations.meta.perPage,
            "align": "center"
          },
          on: {
            "input": _vm.handleFetchRecommendation
          },
          model: {
            value: _vm.recommendations.params.page,
            callback: function callback($$v) {
              _vm.$set(_vm.recommendations.params, "page", $$v);
            },
            expression: "recommendations.params.page"
          }
        })], 1)])], 1)];
      },
      proxy: true
    }], null, false, 2866273102)
  })], 1)], 1)], 1) : _c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('p', [_vm._v("You don't have permission to access this menu")])];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }