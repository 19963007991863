<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:headerTitle>
            <h4 class="card-title">Setup Component</h4>
          </template>
          <template v-slot:headerAction>
            <!-- <b-button variant="secondary">
              <template v-if="!loading_export_structure">
                <i class="fa fa-file-excel"></i> &nbsp; Export
              </template>
              <template v-else>
                    <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>
                Exporting...
              </template>
            </b-button> -->
          </template>
          <template v-slot:body>
            <b-row class="p-3">
              <b-col cols="12" md="5">
                <v-multiselect v-model="selectedFleet" @input="onChange" :options="fleets" placeholder="Choose your fleet" label="fleet" track-by="fleet"></v-multiselect>
              </b-col>
              <!-- DATA DETAIL FLEET 2ND -->
              <b-col cols="12" md="7" class="text-right" v-if="selectedFleet">
                <b-button v-b-modal.clone_component variant="primary"><i class="fa fa-clone"></i> Duplikasi Komponen Kapal</b-button>
                <!-- <b-button class="mr-2" v-b-modal.import variant="dark"><i class="fa fa-file-import"></i> Import</b-button> -->
                <!-- <span><b-button variant="success"><i class="fa fa-save"></i> Save</b-button></span> -->
                <b-modal id="clone_component" size="xl" title="Duplikasi Komponen Kapal" header-bg-variant="light" header-text-variant="dark" footer-bg-variant="light" footer-text-variant="dark" cancel-title="Close" hide-footer>
                  <b-alert :show="true" variant="primary">
                    <div class="iq-alert-icon">
                      <i class="ri-alert-line"></i>
                    </div>
                    <div class="iq-alert-text"><b>Perhatikan</b> Pastikan kapal yang akan diduplikasi komponen nya sudah sesuai datanya</div>
                  </b-alert>
                  <b-row>
                    <b-col md="12">
                      <b-form-group label="Dari Komponen Kapal*" label-for="fleet">
                        <v-multiselect 
                          v-model="selectedFleetDuplication"
                          :options="fleetsDuplication" 
                          @input="onChangeDuplication"
                          placeholder="Choose your fleet" 
                          label="fleet" track-by="fleet"
                        ></v-multiselect>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-col lg="12" class="mb-4" v-if="fleetDetailDataDuplication && selectedFleetDuplication && !isFetchingDuplication">
                    <b-row>
                      <b-col lg="12" >
                        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
                          <template v-slot:headerTitle>
                            <h4 class="card-title text-primary">
                              <a v-b-toggle.preview role="button" style="cursor: pointer"><strong>{{fleetDetailDataDuplication.name}} - Preview</strong></a>
                            </h4>
                          </template>
                          <template v-slot:headerAction>
                            <a class="text-primary float-right" v-b-toggle.preview role="button" style="cursor: pointer">
                              <i class="fa fa-eye" />
                            </a>
                          </template>
                          <template v-slot:body>
                            <b-collapse id="preview" class="mb-2">
                              <b-row>
                                <b-col md="2">
                                  <b-img
                                  class="w-100"
                                  id="imgVehicle"
                                  :src="fleetDetailDataDuplication.img ?
                                  (fleetDetailDataDuplication.img.url == null || fleetDetailDataDuplication.img.url == '' ? fleetDetailDataDuplication.img.url : require('@src/assets/images/Km3FO09.png'))
                                  : require('@src/assets/images/Km3FO09.png')" alt="Foto Kapal" v-bind="{ height: 144 }" @error="handleBrokenImage"/>
                                </b-col>
                                <b-col md="5" class="pl-3">
                                  <div class="table-responsive">
                                    <table class="table table-borderless table-striped">
                                      <tbody>
                                        <tr>
                                          <td width="35%">Code</td>
                                          <td width="65%"><strong>{{fleetDetailDataDuplication.code}}</strong></td>
                                        </tr>
                                        <tr>
                                          <td>Name of Fleet</td>
                                          <td><strong>{{fleetDetailDataDuplication.name}}</strong></td>
                                        </tr>
                                        <tr>
                                          <td>Year & Build</td>
                                          <td><strong>{{fleetDetailDataDuplication.yearBuildCountry}}</strong></td>
                                        </tr>
                                        <tr>
                                          <td>Flag</td>
                                          <td><strong>{{fleetDetailDataDuplication.flag}}</strong></td>
                                        </tr>
                                        <tr>
                                          <td>Company</td>
                                          <td><strong>{{fleetDetailDataDuplication.company ? fleetDetailData.company.company : '-'}}</strong></td>
                                        </tr>
                                        <tr>
                                          <td>Ownership</td>
                                          <td><strong>{{fleetDetailDataDuplication.ownership}}</strong></td>
                                        </tr>
                                        <!-- <tr>
                                          <td>Complete Date</td>
                                          <td><strong>{{fleetDetailData.ownership}}</strong></td>
                                        </tr> -->
                                      </tbody>
                                    </table>
                                  </div>
                                </b-col>
                                <b-col md="5">
                                  <nav aria-label="breadcrumb ">
                                    <ol class="breadcrumb bg-primary">
                                      <li class="breadcrumb-item active text-white" aria-current="page">POWER</li>
                                    </ol>
                                  </nav>
                                  <ol type="numbered" v-if="fleetDetailDataDuplication.powers && fleetDetailDataDuplication.powers.length > 0">
                                    <li v-for="(each, i) in fleetDetailDataDuplication.powers" :key="i">
                                      {{each.powerType.name || ''}} - {{each.powerSeries.name || ''}} {{each.powerSeries.model || ''}} <span class="pull-right">Actual RH: <strong>{{each.actualRunningHours || '-'}} RH</strong></span></li>
                                  </ol>
                                  <ol type="numbered" v-else>
                                    <li>No Data</li>
                                  </ol>
                                  <nav aria-label="breadcrumb ">
                                    <ol class="breadcrumb bg-primary">
                                      <li class="breadcrumb-item active text-white" aria-current="page">ROB Type</li>
                                    </ol>
                                  </nav>
                                  <ol type="numbered" v-if="fleetDetailDataDuplication.robs && fleetDetailDataDuplication.robs.length > 0">
                                    <li v-for="(each, i) in fleetDetailDataDuplication.robs" :key="i">
                                      {{each.robType.name || ''}} - {{each.robSeries.name || ''}} <span class="pull-right">Current ROB: <strong>{{each.actualRob || '-'}} TOB</strong></span></li>
                                  </ol>
                                  <ol type="numbered" v-else>
                                    <li>No Data</li>
                                  </ol>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </template>
                        </iq-card>
                      </b-col>
                      <b-col lg="4">
                        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
                          <template v-slot:body>
                            <b-row>
                              <b-col cols="12" v-if="tree && tree.length > 0">
                                <v-jstree :data="tree" show-checkbox allow-batch whole-row @item-click="itemClickDuplication"></v-jstree>
                              </b-col>
                              <b-col cols="12" v-else>
                                <b-alert show variant="primary">No Data</b-alert>
                              </b-col>
                            </b-row>
                          </template>
                        </iq-card>
                      </b-col>
                      <b-col lg="8">
                        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
                          <template v-slot:body>
                            <b-row v-if="componentIdDuplication">
                              <b-col cols="12" class="flex-row flex-nowrap">
                                <template v-if="haveEquipmentDuplication === false">
                                  <b-alert show variant="warning">This is parent component!</b-alert>
                                </template>
                                <div v-else class="responsive-table" style="overflow-x: auto">
                                  <table class="table table-striped table-borderless">
                                    <thead>
                                      <tr>
                                        <th>No</th>
                                        <th>Equipment</th>
                                        <th>Type</th>
                                        <th>Serial No</th>
                                        <th>Drawing No</th>
                                        <!-- <th>Descriptions</th> -->
                                        <th>Maker No</th>
                                        <th>Maker</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <template v-if="setupComponentDuplication.data && setupComponentDuplication.data.length > 0">
                                      <tr v-for="(each, i) in setupComponentDuplication.data" :key="i">
                                        <td>{{i+1}}</td>
                                        <!--  @click="fetchComponentSetupDetail(each.id)" -->
                                        <td><span class="cursor-pointer text-primary" >{{each.maintenanceMaster.equipmentName}}</span></td>
                                        <td>{{each.typeNo}}</td>
                                        <td>{{each.serialNo}}</td>
                                        <td>{{each.drawingNo}}</td>
                                        <!-- <td>{{each.description}}</td> -->
                                        <td>{{each.makerNo}}</td>
                                        <td>{{each.maker}}</td>
                                      </tr>
                                      </template>
                                      <tr v-else-if="setupComponentDuplication.data.length === 0 && setupComponentDuplication._loading">
                                        <td colspan="8" class="text-center"><b-spinner type="grow" label="Spinning"></b-spinner></td>
                                      </tr>
                                      <tr v-else>
                                        <td colspan="9">
                                          <b-alert show variant="warning">No Data</b-alert>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div v-if="setupComponentDuplication.data && setupComponentDuplication.data.length > 0">
                                  <b-pagination
                                  v-model="setupComponentDuplication.meta.currentPage"
                                  :total-rows="setupComponentDuplication.meta.total"
                                  @change="fetchComponentSetupDuplication"
                                  :per-page="setupComponentDuplication.meta.perPage"
                                  first-number
                                  align="center"
                                ></b-pagination>
                                </div>
                                <b-sidebar
                                  v-if="setupComponentDuplication.detail"
                                  id="sidebar-view"
                                  v-model="sideBarDetail"
                                  no-header
                                  width="900px"
                                  backdrop-variant="dark"
                                  right
                                  backdrop
                                  shadow>
                                  <template #default="{ hide }">
                                    <div class="p-3">
                                      <a @click="hide"><i class="fa fa-times" style="cursor:pointer;"></i></a>  <span class="pull-right"><h5 id="sidebar-no-header-title">{{setupComponent.detail.maintenanceMaster.equipmentName}}</h5></span>
                                      <b-row class="mt-4">
                                        <b-col cols="10">
                                          <nav aria-label="breadcrumb ">
                                            <ol class="breadcrumb bg-primary">
                                              <li class="breadcrumb-item active text-white" aria-current="page">{{setupComponent.detail.component.componentName}} > <b-badge variant="light">{{setupComponent.detail.maintenanceMaster.equipmentName}}</b-badge> </li>
                                            </ol>
                                          </nav>
                                        </b-col>
                                        <b-col cols="2" class="text-right pull-right">
                                          <b-dropdown size="sm" text="Small" variant="light" right>
                                            <template slot="button-content">
                                              <i class="fa fa-ellipsis-h"></i>
                                            </template>
                                            <b-dropdown-item variant="primary" @click="initForm(true)"><i class="fa fa-edit"></i> Edit</b-dropdown-item>
                                            <b-dropdown-item variant="danger"  @click="onDelete(setupComponent.detail)"><i class="fa fa-trash"></i> Delete</b-dropdown-item>
                                          </b-dropdown>
                                        </b-col>
                                        <b-col cols="12">
                                          <table class="table table-striped">
                                            <tbody>
                                              <tr>
                                                <td width="15%">Type No</td>
                                                <td width="35%"><strong>{{setupComponent.detail.typeNo || '-'}}</strong></td>
                                                <td width="15%">Serial No</td>
                                                <td><strong>{{setupComponent.detail.serialNo || '-'}}</strong></td>
                                              </tr>
                                              <tr>
                                                <td width="15%">Maker No</td>
                                                <td width="35%"><strong>{{setupComponent.detail.makerNo || '-'}}</strong></td>
                                                <td width="15%">Maker</td>
                                                <td><strong>{{setupComponent.detail.maker || '-'}}</strong></td>
                                              </tr>
                                              <tr>
                                                <td width="15%">Drawing No</td>
                                                <td width="35%"><strong>{{setupComponent.detail.drawingNo || '-'}}</strong></td>
                                                <td width="15%">Descriptions</td>
                                                <td><strong>{{setupComponent.detail.description || '-'}}</strong></td>
                                              </tr>
                                              <tr>
                                                <td width="15%">Additional Information</td>
                                                <td width="35%"><strong>{{setupComponent.detail.additionalInfo || '-'}}</strong></td>
                                                <td width="15%">Certificate</td>
                                                <td>
                                                  <template v-if="setupComponent.detail.certificateVehicle && setupComponent.detail.certificateVehicle.length > 0">
                                                  <strong
                                                  v-for="(each, i) in setupComponent.detail.certificateVehicle" :key="i">{{each}}</strong>
                                                  </template>
                                                  <strong v-else>-</strong>
                                                  </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </b-col>
                                        <b-col md="12" class="mb-3">
                                          <hr>
                                          <h5 class="text-center"><strong>Support Document</strong></h5>
                                        </b-col>
                                        <b-col md="12" class="mb-4">
                                          <table class="table mb-0 table-borderless table-box-shadow">
                                            <thead>
                                            <tr>
                                              <th width="5%">No.</th>
                                              <th width="50%">Name of File / Document</th>
                                              <th width="35%">File / Document</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(each, i) in setupComponent.detail.maintenanceSetupDoc" :key="i">
                                              <td>{{i+1}}</td>
                                              <td>{{each.fileName}}</td>
                                              <td>
                                                <b-button-group>
                                                  <a :href="each.url" class="btn btn-primary" v-b-tooltip.hover title="Preview File" target="_blank"><i class="fa fa-eye"></i></a>
                                                  <a :href="each.url" class="btn btn-dark" v-b-tooltip.hover title="Download File" download=""><i class="fa fa-download"></i></a>
                                                </b-button-group>
                                              </td>
                                            </tr>
                                            </tbody>
                                          </table>
                                        </b-col>
                                      </b-row>
                                    </div>
                                  </template>
                                </b-sidebar>
                              </b-col>
                            </b-row>
                            <b-row v-else>
                              <b-col md="12">
                                <b-alert show variant="warning">Please select component first!</b-alert>
                              </b-col>
                            </b-row>
                          </template>
                        </iq-card>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col lg="12" v-if="isFetchingDuplication">
                    <div class="d-flex justify-content-center">
                      <span class="ml-3">Loading...</span>
                      <b-spinner type="grow" label="Spinning"></b-spinner>
                    </div>
                  </b-col>
                  <b-col lg="12" v-if="!fleetDetailDataDuplication && !selectedFleetDuplication && !isFetchingDuplication">
                    <b-alert show variant="warning">Please select vehicle first!</b-alert>
                  </b-col>

                  <b-col lg="12">
                    <div class="d-flex align-items-center justify-content-end">
                      <b-button variant="dark" class="mr-2" type="button" @click="$nextTick(() => $bvModal.hide('clone_component'))">
                        Cancel
                      </b-button>
                      <b-button variant="primary" type="button" @click.prevent="onSaveDuplicate" :disabled="savingDuplicate">
                        Save
                      </b-button>
                    </div>
                  </b-col>
                </b-modal>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>

      <!-- DATA DETAIL FLEET 1ST -->
      <b-col lg="12" v-if="fleetDetailData && selectedFleet && !isFetching">
          <b-row>
            <b-col lg="12" >
              <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
                <template v-slot:headerTitle>
                  <h4 class="card-title text-primary">
                    <a v-b-toggle.preview role="button" style="cursor: pointer"><strong>{{fleetDetailData.name}} - Preview</strong></a>
                  </h4>
                </template>
                <template v-slot:headerAction>
                  <a class="text-primary float-right" v-b-toggle.preview role="button" style="cursor: pointer">
                    <i class="fa fa-eye" />
                  </a>
                </template>
                <template v-slot:body>
                  <b-collapse id="preview" class="mb-2">
                    <b-row>
                      <b-col md="2">
                        <b-img
                        class="w-100"
                        id="imgVehicle"
                        :src="fleetDetailData.img ?
                        (fleetDetailData.img.url == null || fleetDetailData.img.url == '' ? fleetDetailData.img.url : require('@src/assets/images/Km3FO09.png'))
                        : require('@src/assets/images/Km3FO09.png')" alt="Foto Kapal" v-bind="{ height: 144 }" @error="handleBrokenImage"/>
                      </b-col>
                      <b-col md="5" class="pl-3">
                        <div class="table-responsive">
                          <table class="table table-borderless table-striped">
                            <tbody>
                              <tr>
                                <td width="35%">Code</td>
                                <td width="65%"><strong>{{fleetDetailData.code}}</strong></td>
                              </tr>
                              <tr>
                                <td>Name of Fleet</td>
                                <td><strong>{{fleetDetailData.name}}</strong></td>
                              </tr>
                              <tr>
                                <td>Year & Build</td>
                                <td><strong>{{fleetDetailData.yearBuildCountry}}</strong></td>
                              </tr>
                              <tr>
                                <td>Flag</td>
                                <td><strong>{{fleetDetailData.flag}}</strong></td>
                              </tr>
                              <tr>
                                <td>Company</td>
                                <td><strong>{{fleetDetailData.company ? fleetDetailData.company.company : '-'}}</strong></td>
                              </tr>
                              <tr>
                                <td>Ownership</td>
                                <td><strong>{{fleetDetailData.ownership}}</strong></td>
                              </tr>
                              <!-- <tr>
                                <td>Complete Date</td>
                                <td><strong>{{fleetDetailData.ownership}}</strong></td>
                              </tr> -->
                            </tbody>
                          </table>
                        </div>
                      </b-col>
                      <b-col md="5">
                        <nav aria-label="breadcrumb ">
                          <ol class="breadcrumb bg-primary">
                            <li class="breadcrumb-item active text-white" aria-current="page">POWER</li>
                          </ol>
                        </nav>
                        <ol type="numbered" v-if="fleetDetailData.powers && fleetDetailData.powers.length > 0">
                          <li v-for="(each, i) in fleetDetailData.powers" :key="i">
                            {{each.powerType.name || ''}} - {{each.powerSeries.name || ''}} {{each.powerSeries.model || ''}} <span class="pull-right">Actual RH: <strong>{{each.actualRunningHours || '-'}} RH</strong></span></li>
                        </ol>
                        <ol type="numbered" v-else>
                          <li>No Data</li>
                        </ol>
                        <nav aria-label="breadcrumb ">
                          <ol class="breadcrumb bg-primary">
                            <li class="breadcrumb-item active text-white" aria-current="page">ROB Type</li>
                          </ol>
                        </nav>
                        <ol type="numbered" v-if="fleetDetailData.robs && fleetDetailData.robs.length > 0">
                          <li v-for="(each, i) in fleetDetailData.robs" :key="i">
                            {{each.robType.name || ''}} - {{each.robSeries.name || ''}} <span class="pull-right">Current ROB: <strong>{{each.actualRob || '-'}} TOB</strong></span></li>
                        </ol>
                        <ol type="numbered" v-else>
                          <li>No Data</li>
                        </ol>
                      </b-col>
                    </b-row>
                  </b-collapse>
                </template>
              </iq-card>
            </b-col>
            <b-col lg="4">
              <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
                <template v-slot:body>
                  <b-row>
                    <b-col cols="12" v-if="tree && tree.length > 0">
                      <v-jstree :data="tree" show-checkbox allow-batch whole-row @item-click="itemClick"></v-jstree>
                    </b-col>
                    <b-col cols="12" v-else>
                      <b-alert show variant="primary">No Data</b-alert>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
            </b-col>
            <b-col lg="8">
              <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
                <template v-slot:body>
                  <b-row v-if="componentId">
                    <b-col cols="12" class="flex-row flex-nowrap">
                      <template v-if="haveEquipment === false">
                        <b-alert show variant="warning">This is parent component!</b-alert>
                      </template>
                      <div v-else class="responsive-table" style="overflow-x: auto">
                        <table class="table table-striped table-borderless">
                          <thead>
                            <tr>
                              <th>No</th>
                              <th>Equipment</th>
                              <th>Type</th>
                              <th>Serial No</th>
                              <th>Drawing No</th>
                              <!-- <th>Descriptions</th> -->
                              <th>Maker No</th>
                              <th>Maker</th>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-if="setupComponent.data && setupComponent.data.length > 0">
                            <tr v-for="(each, i) in setupComponent.data" :key="i">
                              <td>{{i+1}}</td>
                              <td><span class="cursor-pointer text-primary"  @click="fetchComponentSetupDetail(each.id)">{{each.maintenanceMaster.equipmentName}}</span></td>
                              <td>{{each.typeNo}}</td>
                              <td>{{each.serialNo}}</td>
                              <td>{{each.drawingNo}}</td>
                              <!-- <td>{{each.description}}</td> -->
                              <td>{{each.makerNo}}</td>
                              <td>{{each.maker}}</td>
                            </tr>
                            </template>
                            <tr v-else-if="setupComponent.data.length === 0 && setupComponent._loading">
                              <td colspan="8" class="text-center"><b-spinner type="grow" label="Spinning"></b-spinner></td>
                            </tr>
                            <tr v-else>
                              <td colspan="9">
                                 <b-alert show variant="warning">No Data</b-alert>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div v-if="setupComponent.data && setupComponent.data.length > 0">
                        <b-pagination
                        v-model="setupComponent.meta.currentPage"
                        :total-rows="setupComponent.meta.total"
                        @change="fetchComponentSetup"
                        :per-page="setupComponent.meta.perPage"
                        first-number
                        align="center"
                      ></b-pagination>
                      </div>
                      <b-sidebar
                        v-if="setupComponent.detail"
                        id="sidebar-view"
                        v-model="sideBarDetail"
                        no-header
                        width="900px"
                        backdrop-variant="dark"
                        right
                        backdrop
                        shadow>
                        <template #default="{ hide }">
                          <div class="p-3">
                            <a @click="hide"><i class="fa fa-times" style="cursor:pointer;"></i></a>  <span class="pull-right"><h5 id="sidebar-no-header-title">{{setupComponent.detail.maintenanceMaster.equipmentName}}</h5></span>
                            <b-row class="mt-4">
                              <b-col cols="10">
                                <nav aria-label="breadcrumb ">
                                  <ol class="breadcrumb bg-primary">
                                    <li class="breadcrumb-item active text-white" aria-current="page">{{setupComponent.detail.component.componentName}} > <b-badge variant="light">{{setupComponent.detail.maintenanceMaster.equipmentName}}</b-badge> </li>
                                  </ol>
                                </nav>
                              </b-col>
                              <b-col cols="2" class="text-right pull-right">
                                <b-dropdown size="sm" text="Small" variant="light" right>
                                  <template slot="button-content">
                                    <i class="fa fa-ellipsis-h"></i>
                                  </template>
                                  <b-dropdown-item variant="primary" @click="initForm(true)"><i class="fa fa-edit"></i> Edit</b-dropdown-item>
                                  <b-dropdown-item variant="danger"  @click="onDelete(setupComponent.detail)"><i class="fa fa-trash"></i> Delete</b-dropdown-item>
                                </b-dropdown>
                              </b-col>
                              <b-col cols="12">
                                <table class="table table-striped">
                                  <tbody>
                                    <tr>
                                      <td width="15%">Type No</td>
                                      <td width="35%"><strong>{{setupComponent.detail.typeNo || '-'}}</strong></td>
                                      <td width="15%">Serial No</td>
                                      <td><strong>{{setupComponent.detail.serialNo || '-'}}</strong></td>
                                    </tr>
                                    <tr>
                                      <td width="15%">Maker No</td>
                                      <td width="35%"><strong>{{setupComponent.detail.makerNo || '-'}}</strong></td>
                                      <td width="15%">Maker</td>
                                      <td><strong>{{setupComponent.detail.maker || '-'}}</strong></td>
                                    </tr>
                                    <tr>
                                      <td width="15%">Drawing No</td>
                                      <td width="35%"><strong>{{setupComponent.detail.drawingNo || '-'}}</strong></td>
                                      <td width="15%">Descriptions</td>
                                      <td><strong>{{setupComponent.detail.description || '-'}}</strong></td>
                                    </tr>
                                    <tr>
                                      <td width="15%">Additional Information</td>
                                      <td width="35%"><strong>{{setupComponent.detail.additionalInfo || '-'}}</strong></td>
                                      <td width="15%">Certificate</td>
                                      <td>
                                        <template v-if="setupComponent.detail.certificateVehicle && setupComponent.detail.certificateVehicle.length > 0">
                                        <strong
                                        v-for="(each, i) in setupComponent.detail.certificateVehicle" :key="i">{{each}}</strong>
                                        </template>
                                        <strong v-else>-</strong>
                                        </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </b-col>
                              <b-col md="12" class="mb-3">
                                <hr>
                                <h5 class="text-center"><strong>Support Document</strong></h5>
                              </b-col>
                              <b-col md="12" class="mb-4">
                                <table class="table mb-0 table-borderless table-box-shadow">
                                  <thead>
                                  <tr>
                                    <th width="5%">No.</th>
                                    <th width="50%">Name of File / Document</th>
                                    <th width="35%">File / Document</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr v-for="(each, i) in setupComponent.detail.maintenanceSetupDoc" :key="i">
                                    <td>{{i+1}}</td>
                                    <td>{{each.fileName}}</td>
                                    <td>
                                      <b-button-group>
                                        <a :href="each.url" class="btn btn-primary" v-b-tooltip.hover title="Preview File" target="_blank"><i class="fa fa-eye"></i></a>
                                        <a :href="each.url" class="btn btn-dark" v-b-tooltip.hover title="Download File" download=""><i class="fa fa-download"></i></a>
                                      </b-button-group>
                                    </td>
                                  </tr>
                                  </tbody>
                                </table>
                              </b-col>
                            </b-row>
                          </div>
                        </template>
                      </b-sidebar>
                      <b-button v-if="haveEquipment === true" variant="warning" block class="mt-2 mb-3" @click="initForm()"><i class="fa fa-plus"></i> Add Component Part / Equipment</b-button>
                      <b-sidebar
                         v-model="isSidebarAddComponent"
                        id="add-component"
                        :title="(isEdit ? 'Edit' : 'Add') + ' Component'"
                        width="900px"
                        backdrop-variant="dark"
                        bg-variant="white"
                        right
                        backdrop
                        shadow>
                        <template #default="{ hide }">
                        <b-row class="pt-2 pl-3 pr-3 pb-2">
                          <b-col cols="12">
                            <fieldset class="form-group border p-3">
                              <legend class="w-auto px-2">Information Component Part / Equipment</legend>
                              <b-row>
                                <b-col md="6">
                                  <b-form-group label="Component Part / Equipment:" label-for="equipment">
                                    <v-select label="text" v-model="formSetupComponent.maintenanceMasterId" :options="equipmentListFiltered" :reduce="equipmentListFiltered => equipmentListFiltered.value"></v-select>
                                  </b-form-group>
                                </b-col>
                                <!-- <b-col md="2" class="d-none">
                                  <b-form-group label="Have Parent ?" label-for="parent">
                                    <b-form-checkbox v-model="parent" name="check-button" switch inline><template v-if="parent === false">No</template><template v-else>Yes</template></b-form-checkbox>
                                  </b-form-group>
                                </b-col> -->
                                <!-- <b-col md="4">
                                  <b-form-group label="Choose your parent equipment" label-for="equipment" v-if="parent === true">
                                    <v-select label="text" v-model="equipment" :options="equipmentList" :reduce="equipmentList => equipmentList.value"></v-select>
                                  </b-form-group>
                                </b-col> -->
                                <b-col md="6">
                                  <b-form-group label="Type No" label-for="componentNo">
                                    <b-form-input v-model="formSetupComponent.typeNo" type="text" placeholder="Type" maxlength="30"></b-form-input>
                                  </b-form-group>
                                </b-col>
                                <b-col md="6">
                                  <b-form-group label="Serial No" label-for="componentNo">
                                    <b-form-input v-model="formSetupComponent.serialNo"  type="text" placeholder="Serial" maxlength="30"></b-form-input>
                                  </b-form-group>
                                </b-col>
                                <b-col md="6">
                                  <b-form-group label="Maker No" label-for="componentNo">
                                    <b-form-input v-model="formSetupComponent.makerNo" type="text" placeholder="Maker No" maxlength="30"></b-form-input>
                                  </b-form-group>
                                </b-col>
                                <b-col md="6">
                                  <b-form-group label="Maker" label-for="componentNo">
                                    <b-form-input v-model="formSetupComponent.maker" type="text" placeholder="Maker" maxlength="30"></b-form-input>
                                  </b-form-group>
                                </b-col>
                                <b-col md="6">
                                  <b-form-group label="Drawing No" label-for="componentNo">
                                    <b-form-input v-model="formSetupComponent.drawingNo" type="text" placeholder="Drawing" maxlength="30"></b-form-input>
                                  </b-form-group>
                                </b-col>
                                <b-col md="6">
                                  <b-form-group label-for="Description" label="Description">
                                    <b-form-textarea v-model="formSetupComponent.description" id="notes" rows="2" placeholder="Description"></b-form-textarea>
                                  </b-form-group>
                                </b-col>
                                <b-col md="6">
                                  <b-form-group label-for="Additional" label="Additional Information">
                                    <b-form-textarea v-model="formSetupComponent.additionalInfo" id="notes" rows="2" placeholder="Additional Information"></b-form-textarea>
                                  </b-form-group>
                                </b-col>
                                <b-col md="6">
                                  <b-form-group label="Link to Certificate ?" label-for="parent">
                                    <b-form-checkbox v-model="formSetupComponent.haveCertificate" name="check-button" switch inline><template v-if="formSetupComponent.haveCertificate === false">No</template><template v-else>Yes</template></b-form-checkbox>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </fieldset>
                            <fieldset class="form-group border p-3" v-if="formSetupComponent.haveCertificate === true">
                              <legend class="w-auto px-2">Certificate Link</legend>
                              <table class="table mb-0 table-borderless table-box-shadow">
                                <thead>
                                <tr>
                                  <th width="5%">No.</th>
                                  <th width="50%">Certificate List</th>
                                  <th width="10%">#</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(cert, index) in formCertificateList.certificate" :key="index">
                                  <td>{{index+1}}</td>
                                  <td><v-select label="text" v-model="cert.name" :options="certificateList" :reduce="certificateList => certificateList.value"></v-select></td>
                                  <td>
                                    <template v-if="formCertificateList.certificate.length > 1">
                                      <b-button @click.prevent="removeCertificateList(index)" size="sm" variant="danger" class="mb-3 mr-1"><i class="fa fa-trash"></i></b-button>
                                    </template>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                              <b-button variant="info" block class="mt-2 mb-3" @click.prevent="addCertificateList()"><i class="fa fa-plus"></i> Add Certificate Link to this Component</b-button>
                            </fieldset>
                            <fieldset class="form-group border p-3">
                              <legend class="w-auto px-2">Support Document</legend>
                              <table class="table mb-0 table-borderless table-box-shadow">
                                <thead>
                                <tr>
                                  <th width="5%">No.</th>
                                  <th width="50%">Name of File / Document</th>
                                  <th width="35%">File / Document</th>
                                  <th width="10%">#</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(fileSupport, index) in formFileSupportList.fileSupport" :key="index">
                                  <td>{{index+1}}</td>
                                  <td><b-form-input type="text" v-model="fileSupport.fileName" placeholder="Name of File / Document Support"></b-form-input></td>
                                  <td><b-form-file
                                  :id="'fileSupport'+index"
                                  placeholder="Choose a file"
                                  accept="application/pdf"
                                  @change="uploadFileDoc($event,index)"></b-form-file></td>
                                  <td>
                                    <template v-if="formFileSupportList.fileSupport.length > 1">
                                      <b-button @click.prevent="removeFileSupportList(index)" size="sm" variant="danger" class="mb-3 mr-1"><i class="fa fa-trash"></i></b-button>
                                    </template>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                              <b-button variant="warning" block class="mt-2 mb-3" @click.prevent="addFileSupportList()"><i class="fa fa-plus"></i> Add More File Support</b-button>
                            </fieldset>
                          </b-col>
                          <b-col md="12" class="text-right">
                            <b-card-text><small class="text-muted">Sign * : <b-badge variant="warning">Mandatory (Must be Input)</b-badge></small></b-card-text>
                            <b-button type="submit" variant="primary" class="mr-2 text-right" @click="saveData();" :disabled="formFileSupportList.isUploading">Submit</b-button>
                            <b-button type="reset" variant="none" class="iq-bg-danger text-right" @click="hide">Cancel</b-button>
                          </b-col>
                        </b-row>
                        </template>
                      </b-sidebar>
                    </b-col>
                  </b-row>
                  <b-row v-else>
                    <b-col md="12">
                      <b-alert show variant="warning">Please select component first!</b-alert>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
            </b-col>
          </b-row>
      </b-col>
      <b-col lg="12" v-if="isFetching">
        <div class="d-flex justify-content-center">
          <span class="ml-3">Loading...</span>
          <b-spinner type="grow" label="Spinning"></b-spinner>
        </div>
      </b-col>
      <b-col lg="12" v-if="!fleetDetailData && !selectedFleet && !isFetching">
        <b-alert show variant="warning">Please select vehicle first!</b-alert>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { fleetsActions, maintenanceActions, certificatesActions, filesActions } from '@src/Utils/helper'

export default {
  name: 'MaintenanceSetupComponent',
  data () {
    return {
      savingDuplicate: false,
      isEdit: false,
      sideBarDetail: false,
      isSidebarAddComponent: false,
      selectedFleet: null,
      selectedFleetDuplication: null,
      fleets: [],
      fleetsDuplication: [],
      fleetDetailData: null,
      fleetDetailDataDuplication: null,
      isFetching: false,
      isFetchingDuplication: false,
      tree: [],

     

      setupComponentDuplication: {
        data: [],
        detail: null,
        _loading: true,
        params: {
          search: '',
          perPage: 25,
          page: 1
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        }
      }, 

      setupComponent: {
        data: [],
        detail: null,
        _loading: true,
        params: {
          search: '',
          perPage: 25,
          page: 1
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0
        }
      },
      formSetupComponent: {
        typeNo: '',
        makerNo: '',
        drawingNo: '',
        serialNo: '',
        maker: '',
        description: '',
        additionalInfo: '',
        haveCertificate: false,
        maintenanceMasterId: null,
        certificateVehicleIds: [],
        maintenanceSetupDoc: []
      },
      equipment: '',
      equipmentList: [],
      equipmentListFiltered: [],
      parent: false,
      linkCertificate: false,
      formCertificateList: {
        _saving: false,
        certificate: [
          {
            id: '',
            name: ''
          }
        ]
      },
      certificateList: [],
      formFileSupportList: {
        isUploading: false,
        fileSupport: [
          {
            fileName: '',
            fileUploadName: ''
          }
        ]
      },
      componentId: null,
      componentIdDuplication: null,
      haveEquipment: null,
      haveEquipmentDuplication: null
    }
  },
  mounted () {
    this.fetchFleet()
  },
  methods: {
    ...fleetsActions,
    ...maintenanceActions,
    ...certificatesActions,
    ...filesActions,
    initForm (isEditVal) {
      this.sideBarDetail = false
      this.isEdit = isEditVal || false

      this.formSetupComponent = {
        typeNo: this.isEdit ? this.setupComponent.detail.typeNo : '',
        makerNo: this.isEdit ? this.setupComponent.detail.makerNo : '',
        drawingNo: this.isEdit ? this.setupComponent.detail.drawingNo : '',
        serialNo: this.isEdit ? this.setupComponent.detail.serialNo : '',
        maker: this.isEdit ? this.setupComponent.detail.maker : '',
        description: this.isEdit ? this.setupComponent.detail.description : '',
        additionalInfo: this.isEdit ? this.setupComponent.detail.additionalInfo : '',
        haveCertificate: this.isEdit ? this.setupComponent.detail.haveCertificate : false,
        maintenanceMasterId: this.isEdit ? this.setupComponent.detail.maintenanceMaster.id : null,
        certificateVehicleIds: [],
        maintenanceSetupDoc: []
      }

      if (this.isEdit) {
        if (this.setupComponent.detail.maintenanceSetupDoc && this.setupComponent.detail.maintenanceSetupDoc.length > 0) {
          this.formFileSupportList.fileSupport = []
          this.setupComponent.detail.maintenanceSetupDoc.forEach(el => {
            this.formFileSupportList.fileSupport.push({
              fileName: el.fileName,
              fileUploadName: el.fileName
            })
          })
        } else {
          this.formFileSupportList = {
            isUploading: false,
            fileSupport: [
              {
                fileName: '',
                fileUploadName: ''
              }
            ]
          }
        }

        if (this.setupComponent.detail.certificateVehicle && this.setupComponent.detail.certificateVehicle.length > 0) {
          this.formFileSupportList.fileSupport = []
          this.setupComponent.detail.certificateVehicle.forEach(el => {
            this.formCertificateList.certificate.push({
              id: el.id,
              name: el.certificateName
            })
          })
        } else {
          this.formCertificateList = {
            _saving: false,
            certificate: [
              {
                id: '',
                name: ''
              }
            ]
          }
        }
      } else {
        this.formCertificateList = {
          _saving: false,
          certificate: [
            {
              id: '',
              name: ''
            }
          ]
        }

        this.formFileSupportList = {
          isUploading: false,
          fileSupport: [
            {
              fileName: '',
              fileUploadName: ''
            }
          ]
        }
      }
      this.isSidebarAddComponent = true
    },
    async getMasterEquipmentList () {
      this.equipmentList = []
      const paramsQ = {
        page: 1,
        perPage: 1000,
        vehicleTypeIds: this.fleetDetailData.vehicleType.id
      }
      const res = await this.getMasterEquipment(paramsQ)

      if (res.status == 'success') {
        if (res.data.data && res.data.data.length > 0) {
          res.data.data.forEach(el => {
            let idxFind = el.vehicleTypes.findIndex(val => val.id === this.fleetDetailData.vehicleType.id)
            if (idxFind !== -1) {
              this.equipmentList.push({ value: el.id, text: el.equipmentName })
            }
          })
        }
      } else {
        this.$swal(
          `Oops!`,
          res.data,
          'error'
        )
      }
    },
    async fetchCertificates () {
      this.certificateList = []
      const paramsQ = {
        page: 1,
        active: true,
        perPage: 2500,
        vehicle: this.fleetDetailData.id
      }
      let res = await this.getCertificates(paramsQ)

      if (res.status == 'success') {
        const { data, pagination } = res
        if (data) {
          data.forEach(el => {
            this.certificateList.push({ value: el.id, text: el.name })
          })
        }
      } else {
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }

      this.loading_certificates = false
    },
    async fetchFleet () {
      let params = {
        page: 1,
        active: true,
        showAll: true,
        vehicleTypeId: [1, 2, 3, 4]
      }

      params.vehicleTypeId = params.vehicleTypeId.toString()

      let res = await this.getFleetActivities(params)

      if (res.status == 'success' && res.data && res.data.length > 0) {
        res.data.forEach(el => {
          this.fleets.push({
            id: el.id,
            fleet: el.name,
            status: ''
          })
        })
      } else {
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },
    async fetchFleetDetail (id) {
      this.isFetching = true
      const res = await this.getFleetById(id)

      if (res.status == 'success') {
        this.fleetDetailData = res.data
        this.setupComponentDuplication.data = []
        this.getComponentStruct(this.fleetDetailData.vehicleType.id)
        this.getMasterEquipmentList()
        this.fetchCertificates()
        this.isFetching = false
      } else {
        this.isFetching = false
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },
    onChange (e) {
      if (e) {
        this.fleetsDuplication = []
        this.fetchFleetDetail(e.id)

        let newFleet = this.fleets.filter(obj => obj.id !== e.id)
        this.fleetsDuplication = newFleet
      }
    }, 
    async onChangeDuplication (e) {
      if (e.id) {
        this.isFetchingDuplication = true

        const res = await this.getFleetById(e.id)

        if (res.status == 'success') {
          this.fleetDetailDataDuplication = res.data
          this.setupComponent.data = []
          this.getComponentStruct(this.fleetDetailDataDuplication.vehicleType.id)
          this.getMasterEquipmentList()
          this.fetchCertificates()
          this.isFetchingDuplication = false
        } else {
          this.isFetchingDuplication = false
          this.$swal(
            `Oops!`,
            res.data.message,
            'error'
          )
        }
      }
    },
    async getComponentStruct (id) {
      this.tree = []
      const paramsQ = {
        vehicleTypeId: id
      }
      const res = await this.getComponent(paramsQ)

      if (res.status == 'success') {
        if (res.data.maintenanceComponents && res.data.maintenanceComponents.length > 0) {
          const dataTemp = res.data.maintenanceComponents
          dataTemp.forEach((s) => this.tree.push(this.putStructureObject(s)))
        }
      } else {
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },
    putStructureObject (object) {
      let children = []

      if (object.child.length > 0) {
        object.child.forEach((child) =>
          children.push(this.putStructureObject(child))
        )
      }

      if (this.tree.findIndex(val => val.value === object.component.id) === -1) {
        // this.tree.push({id: object.component.id, value: object.component.id, text: object.component.componentName})
      }

      return {
        id: object.component.id,
        text: object.component.componentName,
        haveEquipment: object.component.haveEquipment,
        opened: true,
        selected: false,
        children
      }
    },
    fleetWithStatus ({ fleet, status }) {
      return `${fleet} — [${status}]`
    },
    itemClick (node) {
      if (node && node.model && node.model.id) {
        this.componentId = node.model.id
        this.haveEquipment = node.model.haveEquipment
        this.fetchComponentSetup()
      }
    },
    itemClickDuplication (node) {
      if (node && node.model && node.model.id) {
        this.componentIdDuplication = node.model.id
        this.haveEquipmentDuplication = node.model.haveEquipment
        this.fetchComponentSetupDuplication()
      }
    },

    async fetchComponentSetupDuplication (page) {
      this.setupComponentDuplication.params.page = page || 1
      this.setupComponentDuplication._loading = true
      this.setupComponentDuplication.data = []
      const params = this.setupComponentDuplication.params

      const reqData = {
        vehicleId: this.fleetDetailDataDuplication.id,
        componentId: this.componentIdDuplication,
        params
      }

      let res = await this.getComponentSetup(reqData)

      if (res.data.status == 'success' || res.data.status == true) {
        if (res.data.data && res.data.data.length > 0) {
          this.setupComponentDuplication.data = res.data.data
          this.setupComponentDuplication.meta.perPage = res.data.perPage
          this.setupComponentDuplication.meta.currentPage = res.data.currentPage
          this.setupComponentDuplication.meta.total = res.data.total
          this.setupComponentDuplication.meta.totalPage = res.data.totalPage
        }
        this.setupComponentDuplication._loading = false

        const filteredEquipmentList = this.equipmentList.filter(item1 => !this.setupComponentDuplication.data.some(item2 => item1.value === item2.id));

        this.equipmentListFiltered = filteredEquipmentList
      } else {
        this.setupComponentDuplication._loading = false
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },
    async fetchComponentSetup (page) {
      this.setupComponent.params.page = page || 1
      this.setupComponent._loading = true
      this.setupComponent.data = []
      const params = this.setupComponent.params

      const reqData = {
        vehicleId: this.fleetDetailData.id,
        componentId: this.componentId,
        params
      }

      let res = await this.getComponentSetup(reqData)

      if (res.data.status == 'success' || res.data.status == true) {
        if (res.data.data && res.data.data.length > 0) {
          this.setupComponent.data = res.data.data
          this.setupComponent.meta.perPage = res.data.perPage
          this.setupComponent.meta.currentPage = res.data.currentPage
          this.setupComponent.meta.total = res.data.total
          this.setupComponent.meta.totalPage = res.data.totalPage
        }
        this.setupComponent._loading = false

        const filteredEquipmentList = this.equipmentList.filter(item1 => !this.setupComponent.data.some(item2 => item1.value === item2.id));

        this.equipmentListFiltered = filteredEquipmentList
      } else {
        this.setupComponent._loading = false
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },

    async fetchComponentSetupDetail (id) {
      const reqData = {
        vehicleId: this.fleetDetailData.id,
        componentId: this.componentId,
        id: id
      }
      const res = await this.getComponentSetupById(reqData)

      if (res.status == 'success') {
        this.setupComponent.detail = res.data
        this.sideBarDetail = true
      } else {
        this.sideBarDetail = false
        this.$swal(
          `Oops!`,
          res.data.message,
          'error'
        )
      }
    },
    addFileSupportList () {
      this.formFileSupportList.fileSupport.push(
        {
          fileName: '',
          fileUploadName: ''
        }
      )
    },
    removeFileSupportList (index) {
      this.formFileSupportList.fileSupport.splice(index, 1)
    },
    addCertificateList () {
      this.formCertificateList.certificate.push(
        {
          id: '',
          name: ''
        }
      )
    },
    removeCertificateList (index) {
      this.formCertificateList.certificate.splice(index, 1)
    },
    onDelete (dataVal) {
      this.$swal({
        title: 'Delete Component Part / Equipment ?',
        text: `Component Part / Equipment ${dataVal.maintenanceMaster.equipmentName} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const dataReq = {
            vehicleId: this.fleetDetailData.id,
            componentId: this.componentId,
            id: dataVal.id
          }
          let res = await this.deleteComponentSetupyId(dataReq)
          if (res.status != 'success') {
            if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
            }
          } else {
            this.fetchComponentSetup()
            this.$swal(
              `Job Type deleted!`,
              `Job Type ${dataVal.maintenanceMaster.equipmentName} successfully deleted`,
              'success'
            )
            this.sideBarDetail = false
          }
        }
      })
    },
    async uploadFileDoc (event, i) {
      this.formFileSupportList.isUploading = true

      const files = [...event.target.files]
      let file = files[0]

      let formData = new FormData()
      formData.append('file', file)

      let res = await this.upload(formData)
      if (res.status == 'success') {
        this.formFileSupportList.fileSupport[i].fileUploadName = res.data.fileName
        this.formFileSupportList.isUploading = false
      } else {
        this.formFileSupportList.isUploading = false
        if (res.data.message != null) {
          this.$swal('Error', res.data.message, 'error')
        } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
        }
      }
    },
    async saveData () {
      this.$swal.fire({
        title: 'Processing...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading()
        }
      })

      if (this.formCertificateList.certificate && this.formCertificateList.certificate.length > 0 && this.formCertificateList.haveCertificate) {
        this.formSetupComponent.certificateVehicleIds = []
        this.formCertificateList.certificate.forEach(el => {
          this.formSetupComponent.certificateVehicleIds.push(el.name)
        })
      }

      this.formSetupComponent.maintenanceSetupDoc = this.formFileSupportList.fileSupport

      const dataNeed = {
        vehicleId: this.fleetDetailData.id,
        componentId: this.componentId,
        payload: this.formSetupComponent
      }

      if (this.isEdit) {
        dataNeed.id = this.setupComponent.detail.id
        let res = await this.updateComponentSetup(dataNeed)
        if (res.status == 'success') {
          this.$swal('Update Component Part / Equipment Success!', res.data.message, 'success')
          this.fetchComponentSetup()
          this.isSidebarAddComponent = false
        } else {
          if (res.data.message != null) {
            this.$swal('Error', res.data.message, 'error')
          } else {
            this.$swal(
              'Error',
              'Something wrong with the server. Please try again later.',
              'error'
            )
          }
        }
      } else {
        let res = await this.createComponentSetup(dataNeed)
        if (res.status == 'success') {
          this.$swal('Add Component Part / Equipment Success!', res.data.message, 'success')
          this.fetchComponentSetup()
          this.isSidebarAddComponent = false
        } else {
          if (res.data.message != null) {
            this.$swal('Error', res.data.message, 'error')
          } else {
            this.$swal(
              'Error',
              'Something wrong with the server. Please try again later.',
              'error'
            )
          }
        }
      }
    }, 
    handleBrokenImage (data) {
      data.target.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAMAAACahl6sAAAAbFBMVEX///8AAAA/Pz9paWlubm6amprt7e3h4eFxcXEiIiIfHx8xMTGdnZ2oqKihoaEWFhby8vJYWFgPDw+2trbl5eVfX188PDx8fHwRERGDg4NcXFwqKioICAhmZmYbGxs3NzfV1dVJSUlQUFDJycmqtOwwAAAElElEQVR4nO3da1caMRAGYEBXKqLcFC9g1fb//8fWQ5VNJuxMMpeknHk/Sh33ORTYDbOT0cjj8Xg8Ho/H4/F4PB6Px+PxeDwejyczy+5lop3t9Xq/nr31fvIeH8b0g15u1S2hY7MYV8gtcFxn/f5iAxxKRzocruNvIsnyv3w+PrMI/3d1CoeJRsIxHndBiZX4UeKRcYxXQY2J9FHiEXKMJ5UhUo7aEDFHZchczFEX8ijnqAr5IegYgKxlz6/2uGP5kFlzTYJM4r/DygXuyM/pw1WDqDgqQHQc9hAlhzkEOi5lChtD1BzGED2HLUTRYQrRdFhCVB2GEF2HHUTZYQbRdlhB1B1GkEd1hw3kXt9hAwFLZPIOG8hPfYcN5Cl07HjV0jGBzM7lGTmb18jZvGuNnvUlRp/sl+oSq3MtdYnZ2a+2xO56RFlieIWoK7G8ZleV+CpKUc5lXet8VhrPZ+1XT9LC9yMikia+sZKQVIDoSGpAVCRVIBqSOpCE5JlZsRIkIbnnFawFgZIXXr1qECCZ8crVg4x2IeSJV62dZ+SaV66d18gDr14771pXvIL+OcKKQmdNI+da/A6hNs5+vYPumBauEL2Drp/6qygyjvrrWkKO6iuNKcd8ux/PLqZ5pSuv/SYcV9vDQ3tw98Jg6nbQpRw334/e5RRvrYOu58iTNNZBFziyJG110EWOHElTHXTAkSFpqYMu4aBLGuqgSzrIknZ6UU44qJJmuoNOOoiSVjroBhw0iRxk8/w4P7F+i3fQDTpIEinI5nC33Wvi/n5KB92vQQdFIgT5vmf8JrU8hb9G0LsOUYkMpHfve0pCeNdiS0QgwT38KQmhg44rkYBEswhSEkI/DVMiAAEzFbQk4P5qWUhiNkQNCRuSnHFRQcKFnJjVYS9hQk7OHDGX8CADs1OsJSzI4AyYUkl8hkmUcCDILJtCyfs6UQuXMCDoTJ5CyS1WNykphxBmCxVK0GE/KUkxhDQjqUwyhcMtcEkphDjrqUwC/wUuKYSQZ1YVSaZ4XSApg2TM3iqSbPMlRZCsGWIlEnj9gkpKIJmz0FISpPPhN6VuKCmAZM90o0n6KzBLUt1Akg8pmE1HkgQddNh5CpRkQ4pm7FEkQQfd8DJXSpILKZwVmJIMddC9EeseJZmQ4pmHUDLYQUd8RnqSPAhjdmMsGe6go71G+pIsCGsGZSh5BY/3H6a9ax0yz4cwZ2n2JdCR/znylS4Xwp4JepRAR/iNO+WT/ZguDyIw2/RLgjnIb1r/0uVARGa0HiSog3D2G6ajQ4RmzX5KUEfi/AVLR4WIzcy9uXoAP4sd+BUiTEeDYAsbGYHjkEFnDfb1VTK0OY2aAY47dskqEOBA17Xw1IDAGab0s5OTqQCBjvgb4JLYQ3Qc9hAlhzlEy2ENUXMYQ/QcthBFhylE02EJUXUYQnQddhBlhxlE2xFB1LbtUHdE23ZobaSi74g2UlHa2sbAEW1to7PZkIEj3mxIZfsnuKeF/PMBHJ8bcq2k9oXY7hbrxQ5082XvBYEluSGXx+PxeDwej8fj8Xg8Ho/H4/F4PB7PcP4A9/9OftapMF4AAAAASUVORK5CYII='
    },
    
    async onSaveDuplicate () {
      this.savingDuplicate = true

      let payload = {
        vehicleId: this.fleetDetailData?.id,
        data: {
          componentSetupIds:this.setupComponentDuplication.data.map(obj => obj.id)
        }
      }

      let res = await this.createDuplicateComponent(payload)

      if (res.status == 'success') {
        this.$nextTick(() => {
          this.$bvModal.hide('clone_component')
        })
        this.$swal('Duplicate component success!', res.data.message, 'success')
        // this.fetchComponentSetup()
        this.savingDuplicate = false
      } else {
        if (res.data.message != null) {
          this.$swal('Error', res.data.message, 'error')
          this.savingDuplicate = false
        } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
          this.savingDuplicate = false
        }
        return
      }
      this.$nextTick(() => {
        this.$bvModal.hide('clone_component')
      })
      this.savingDuplicate = false
    },

  }
}
</script>

<style>
.tree-wholerow-ul .tree-wholerow {
  z-index: 0 !important;
}
</style>
