import axios from './auth'

const LOGS_BASE_URL = `${process.env.VUE_APP_API_OPS_BASE_URL}/user-logs`;

export const state = {}

export const getters = {}

export const mutations = {}

export const actions = {
  async getLogs({ commit }, params = {}) {
    const url = LOGS_BASE_URL;

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params
    };

    try {
      const response = await axios(config);
      let data = response.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
  async exportLogs({ commit }, params = {}) {
    const url = `${LOGS_BASE_URL}/export`;

    const method = "get";
    const headers = {};
    const config = {
      url,
      method,
      headers,
      params,
      responseType: 'blob'
    };

    try {
      const response = await axios(config);
      let data = response.data;
      return {
        status: 'success',
        data,
      };
    } catch (e) {
      return {
        status: 'error',
        data: e.response.data
      };
    }
  },
}
