var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('StepWizard', {
    attrs: {
      "current-step": "EVALUATION"
    }
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.onSave();
            }
          }
        }, [_vm.incident ? _c('b-row', [_c('b-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('img', {
          staticClass: "img-fluid w-20",
          attrs: {
            "src": _vm.image,
            "alt": "logo"
          }
        })]), _c('b-col', {
          attrs: {
            "cols": "6",
            "align-self": "center"
          }
        }), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('hr')]), _c('b-col', {
          staticClass: "mb-3",
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('h5', {
          staticClass: "card-title text-success"
        }, [_c('b', [_vm._v("INFORMATION OF ACCIDENT/INCIDENT")])]), _c('b-form-group', {
          staticClass: "row",
          attrs: {
            "label-cols-sm": "3",
            "label": "Company *",
            "label-for": "company"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "value": _vm.incident.company.company,
            "readonly": ""
          }
        })], 1), _c('b-form-group', {
          staticClass: "row",
          attrs: {
            "label-cols-sm": "3",
            "label": "Fleet Name *",
            "label-for": "fleets"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "value": _vm.incident.vehicle.name,
            "readonly": ""
          }
        })], 1), _c('b-form-group', {
          staticClass: "row",
          attrs: {
            "label-cols-sm": "3",
            "label": "Date of Loss (DOL) *",
            "label-for": "dol"
          }
        }, [_c('b-form-input', {
          attrs: {
            "value": _vm.incident.date_of_loss,
            "type": "text",
            "readonly": ""
          }
        })], 1), _c('b-form-group', {
          staticClass: "row",
          attrs: {
            "label-cols-sm": "3",
            "label": "Place of Loss (POL) *",
            "label-for": "pol"
          }
        }, [_c('b-form-input', {
          attrs: {
            "value": _vm.incident.place_of_loss,
            "type": "text",
            "readonly": ""
          }
        })], 1), _c('b-form-group', {
          staticClass: "row",
          attrs: {
            "label-cols-sm": "3",
            "label": "Type of Coverage *",
            "label-for": "coverage"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "value": _vm.incident.type_coverage.name,
            "readonly": ""
          }
        })], 1), _c('b-form-group', {
          staticClass: "row",
          attrs: {
            "label-cols-sm": "3",
            "label": "Type of Loss *",
            "label-for": "loss"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "value": _vm.incident.type_loss.name,
            "readonly": ""
          }
        })], 1)], 1), _c('b-col', {
          staticClass: "mb-3",
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('h5', {
          staticClass: "card-title text-success"
        }, [_c('b', [_vm._v("CLAIMANT")])]), _c('b-form-group', {
          staticClass: "row",
          attrs: {
            "label-cols-sm": "3",
            "label": "Company *",
            "label-for": "company"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "value": _vm.incident.company.company,
            "readonly": ""
          }
        })], 1), _c('b-form-group', {
          staticClass: "row",
          attrs: {
            "label-cols-sm": "3",
            "label": "Name *",
            "label-for": "name"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "value": _vm.incident.name,
            "readonly": ""
          }
        })], 1), _c('b-form-group', {
          staticClass: "row",
          attrs: {
            "label-cols-sm": "3",
            "label": "Position *",
            "label-for": "position"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "value": _vm.incident.position,
            "readonly": ""
          }
        })], 1), _c('b-form-group', {
          staticClass: "row",
          attrs: {
            "label-cols-sm": "3",
            "label": "Department of FLeet *",
            "label-for": "departments"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "value": _vm.incident.department ? _vm.incident.department.name : '-',
            "readonly": ""
          }
        })], 1), _c('b-form-group', {
          staticClass: "row",
          attrs: {
            "label-cols-sm": "3",
            "label": "Cause of Loss",
            "label-for": "cause_of_loss"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "value": _vm.incident.cause_of_loss ? _vm.incident.cause_of_loss.name : '-',
            "readonly": ""
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('hr')]), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('h3', [_vm._v("Recommendation")]), _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table mb-0 table-borderless table-box-shadow"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("No.")]), _c('th', [_vm._v("Permasalahan")]), _c('th', [_vm._v("Vessel Case")]), _c('th', [_vm._v("Root Cause")]), _c('th', [_vm._v("Rekomendasi")]), _c('th', [_vm._v("Cause of Loss")]), _c('th', [_vm._v("Status")]), _c('th', [_vm._v("Closed Date")])])]), _c('tbody', _vm._l(_vm.recommendations, function (recommendation, idx) {
          return _c('tr', {
            key: recommendation.id
          }, [_c('td', [_vm._v(_vm._s(idx + 1) + ". ")]), _c('td', [_vm._v(_vm._s(recommendation.problem))]), _c('td', [_vm._v(_vm._s(recommendation.vessel_case))]), _c('td', [_vm._v(_vm._s(recommendation.root_cause))]), _c('td', [_vm._v(_vm._s(recommendation.recommendation))]), _c('td', [_vm._v(_vm._s(recommendation.cause_of_loss_id))]), _c('td', [_vm._v(_vm._s(recommendation.status))]), _c('td', [_vm._v(_vm._s(recommendation.closed_at))])]);
        }), 0)])])]), _c('b-col', {
          staticClass: "mt-4",
          attrs: {
            "cols": "12"
          }
        }, [_c('h3', [_vm._v("Notif Mail to Users")]), _c('b-form-group', {
          staticClass: "mt-2"
        }, [_c('v-multiselect', {
          attrs: {
            "placeholder": "Notif user",
            "label": "name",
            "track-by": "id",
            "options": _vm.optionsUser,
            "multiple": true
          },
          model: {
            value: _vm.notifMailUser,
            callback: function callback($$v) {
              _vm.notifMailUser = $$v;
            },
            expression: "notifMailUser"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Data not found.")])])], 1)], 1), _c('b-col', {
          staticClass: "mt-4",
          attrs: {
            "cols": "12"
          }
        }, [_c('h3', [_vm._v("Input Evaluation")]), _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', [_vm._v("No.")]), _c('th', [_vm._v("Tahapan Kegiatan")]), _c('th', [_vm._v("Sasaran Tahapan "), _c('br'), _vm._v("(Milestone)")]), _c('th', [_vm._v("Uraian Tugas")]), _c('th', [_vm._v("Indikator Keberhasilan")]), _c('th', [_vm._v("Metode Evaluasi")]), _c('th', [_vm._v("Frekuensi")]), _c('th', [_vm._v("Pelaksana")]), _c('th', [_vm._v("Kebutuhan Tindak lanjut")]), _c('th', [_vm._v("#")])])]), _c('tbody', _vm._l(_vm.forms, function (form, idx) {
          return _c('tr', {
            key: idx
          }, [_c('td', [_vm._v(_vm._s(idx + 1))]), _c('td', [_c('b-form-textarea', {
            attrs: {
              "id": "tahapan-kegiatan",
              "rows": "3"
            },
            model: {
              value: form.activity_stage,
              callback: function callback($$v) {
                _vm.$set(form, "activity_stage", $$v);
              },
              expression: "form.activity_stage"
            }
          })], 1), _c('td', [_c('b-form-textarea', {
            attrs: {
              "id": "sasaran-tahapan",
              "rows": "3"
            },
            model: {
              value: form.milestone,
              callback: function callback($$v) {
                _vm.$set(form, "milestone", $$v);
              },
              expression: "form.milestone"
            }
          })], 1), _c('td', [_c('b-form-textarea', {
            attrs: {
              "id": "uraian-tugas",
              "rows": "3"
            },
            model: {
              value: form.job_description,
              callback: function callback($$v) {
                _vm.$set(form, "job_description", $$v);
              },
              expression: "form.job_description"
            }
          })], 1), _c('td', [_c('b-form-textarea', {
            attrs: {
              "id": "indikator-keberhasilan",
              "rows": "3"
            },
            model: {
              value: form.success_indicator,
              callback: function callback($$v) {
                _vm.$set(form, "success_indicator", $$v);
              },
              expression: "form.success_indicator"
            }
          })], 1), _c('td', [_c('b-form-textarea', {
            attrs: {
              "id": "hasil-evaluasi",
              "rows": "3"
            },
            model: {
              value: form.evaluation_result,
              callback: function callback($$v) {
                _vm.$set(form, "evaluation_result", $$v);
              },
              expression: "form.evaluation_result"
            }
          })], 1), _c('td', [_c('b-form-textarea', {
            attrs: {
              "id": "frekuensi",
              "rows": "3"
            },
            model: {
              value: form.frequency,
              callback: function callback($$v) {
                _vm.$set(form, "frequency", $$v);
              },
              expression: "form.frequency"
            }
          })], 1), _c('td', [_c('b-form-textarea', {
            attrs: {
              "id": "pelaksana",
              "rows": "3"
            },
            model: {
              value: form.executor,
              callback: function callback($$v) {
                _vm.$set(form, "executor", $$v);
              },
              expression: "form.executor"
            }
          })], 1), _c('td', [_vm._l(_vm.typeFormInsurance, function (item, index) {
            return [_c('b-form-radio', {
              key: index,
              staticClass: "custom-radio-color-checked",
              attrs: {
                "inline": "",
                "color": item.color,
                "name": "follow_up_needs-".concat(idx + 1),
                "value": item.value,
                "id": "follow_up_needs-".concat(item.value, "-").concat(idx + 1)
              },
              model: {
                value: form.follow_up_needs,
                callback: function callback($$v) {
                  _vm.$set(form, "follow_up_needs", $$v);
                },
                expression: "form.follow_up_needs"
              }
            }, [_c('strong', [_c('h5', [_vm._v(_vm._s(item.label))])])])];
          })], 2), _c('td', [_c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.deleteForm(idx);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })])], 1)]);
        }), 0)]), _c('b-button', {
          staticClass: "mt-2 mb-3",
          attrs: {
            "variant": "warning",
            "block": ""
          },
          on: {
            "click": _vm.addForms
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" Add More Data")])], 1)])], 1) : _vm._e(), _c('b-row', [_c('b-col', {
          staticClass: "text-right mt-4",
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "disabled": _vm.saving,
            "variant": "primary"
          }
        }, [_vm._v("Submit")]), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "disabled": _vm.saving,
            "variant": "none"
          },
          on: {
            "click": function click($event) {
              return _vm.$router.go(-1);
            }
          }
        }, [_vm._v("Cancel ")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }