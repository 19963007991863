var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("MASTER PFA")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          staticClass: "row"
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search pfa :",
            "label-for": "kind-fleet"
          }
        }, [_c('b-form-input', {
          staticClass: "w-100",
          attrs: {
            "id": "name",
            "type": "text",
            "placeholder": "Search..."
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "button_search"
          }
        }, [_c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "8"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("List Master PFA")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_vm.list._loading ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.list.data && _vm.list.data.length > 0 ? _c('div', {
          staticClass: "d-flex w-100 overflow-auto"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("No")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Vehicle Type")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "width": "30%"
          }
        }, [_vm._v("Component Expense")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "width": "30%"
          }
        }, [_vm._v("Cost Factor")]), _c('th', {
          staticClass: "text-truncate",
          attrs: {
            "width": "30%"
          }
        }, [_vm._v("Port Stay")]), _c('th', {
          staticClass: "text-right",
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("#")])])]), _c('tbody', _vm._l(_vm.list.data, function (p, i) {
          return _c('tr', {
            key: "p-".concat(i)
          }, [_c('td', [_vm._v(_vm._s(i + 1))]), _c('td', [_vm._v(_vm._s(p.vehice_type_name))]), _c('td', [p.component_expense.length > 0 ? _c('div', {
            staticClass: "d-flex flex-wrap w-100"
          }, _vm._l(p === null || p === void 0 ? void 0 : p.component_expense, function (obj, objIndex) {
            return _c('b-badge', {
              key: objIndex,
              staticClass: "border border-primary text-primary text-uppercase mr-2 mb-2",
              attrs: {
                "variant": "none"
              }
            }, [_vm._v(_vm._s(obj === null || obj === void 0 ? void 0 : obj.name))]);
          }), 1) : _vm._e()]), _c('td', [p.cost_factor.length > 0 ? _c('div', {
            staticClass: "d-flex flex-wrap w-100"
          }, _vm._l(p === null || p === void 0 ? void 0 : p.cost_factor, function (obj, objIndex) {
            return _c('b-badge', {
              key: objIndex,
              staticClass: "border border-primary text-primary text-uppercase mr-2 mb-2",
              attrs: {
                "variant": "none"
              }
            }, [_vm._v(_vm._s(obj === null || obj === void 0 ? void 0 : obj.name))]);
          }), 1) : _vm._e()]), _c('td', [p.port_stay.length > 0 ? _c('div', {
            staticClass: "d-flex flex-wrap w-100"
          }, _vm._l(p === null || p === void 0 ? void 0 : p.port_stay, function (obj, objIndex) {
            return _c('b-badge', {
              key: objIndex,
              staticClass: "border border-primary text-primary text-uppercase mr-2 mb-2",
              attrs: {
                "variant": "none"
              }
            }, [_vm._v(_vm._s(obj === null || obj === void 0 ? void 0 : obj.name))]);
          }), 1) : _vm._e()]), _c('td', {
            staticClass: "text-right"
          }, [_c('b-button', {
            staticClass: "mb-2",
            attrs: {
              "variant": "success",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.editForm(p);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]), _c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onDelete(p);
              }
            }
          }, [!_vm.deleted ? _c('i', {
            staticClass: "fa fa-trash"
          }) : [_c('span', {
            staticClass: "spinner-border spinner-border-sm",
            attrs: {
              "role": "status",
              "aria-hidden": "true"
            }
          }), _vm._v("Deleting... ")]], 2)], 1)]);
        }), 0)])]) : _vm._e(), _vm.list.data && _vm.list.data.length > 0 ? _c('div', [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.list.meta.totalItem,
            "per-page": _vm.list.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getMasterPFA
          },
          model: {
            value: _vm.list.meta.page,
            callback: function callback($$v) {
              _vm.$set(_vm.list.meta, "page", $$v);
            },
            expression: "list.meta.page"
          }
        })], 1) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("No Master PFA data found.")])]];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('iq-card', {
    class: {
      'border border-danger shadow-lg': _vm.edit
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v(_vm._s(_vm.edit ? 'Edit' : 'Add') + " Master PFA")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.onSave();
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Vehicle Type",
            "label-for": "vehicle_type"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "options": _vm.optionsVehicleType,
            "placeholder": "Select Vehicle Type Group",
            "track-by": "id",
            "label": "name",
            "disabled": _vm.edit
          },
          model: {
            value: _vm.form.vehicle_type,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "vehicle_type", $$v);
            },
            expression: "form.vehicle_type"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Oops! No elements found.")])])], 1), _c('b-form-group', {
          attrs: {
            "label": "Component Expense",
            "label-for": "project_name"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "placeholder": "Select component",
            "label": "name",
            "track-by": "id",
            "options": _vm.optionsComponent,
            "multiple": true
          },
          model: {
            value: _vm.form.component_expense,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "component_expense", $$v);
            },
            expression: "form.component_expense"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Data not found.")])])], 1), _c('b-form-group', {
          attrs: {
            "label": "Cost Factor",
            "label-for": "name"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "placeholder": "Select Cost factor",
            "label": "name",
            "track-by": "id",
            "options": _vm.optionsCostFactor,
            "multiple": true
          },
          model: {
            value: _vm.form.cost_factor,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "cost_factor", $$v);
            },
            expression: "form.cost_factor"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Data not found.")])])], 1), _c('b-form-group', {
          attrs: {
            "label": "Port Stay",
            "label-for": "name"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "placeholder": "Select Port Stay",
            "label": "name",
            "track-by": "id",
            "options": _vm.optionsPortStay,
            "multiple": true
          },
          model: {
            value: _vm.form.port_stay,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "port_stay", $$v);
            },
            expression: "form.port_stay"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Data not found.")])])], 1), _c('hr'), _c('b-form-group', {
          staticClass: "text-right"
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [!_vm.edit ? _c('span', [_vm._v("Add")]) : _c('span', [_vm._v("Save Edit")])]), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "button",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              return _vm.resetForm();
            }
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }