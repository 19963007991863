<template>
  <b-row>
    <b-col md="12">
      <iq-card headerClass="bg-head-total-expanse">
        <template v-slot:headerTitle>
          <h4 class="card-title text-white"
            ><strong>BUDGET EXPENSES</strong></h4
          >
        </template>
        <template v-slot:body>
          <div style="min-height: 368px">
            <ApexChart
              :element="`chart-budget-expanse`"
              :chartOption="chartBudgetExapnse"
            />
          </div>
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      chartBudgetExapnse: {
        series: [25, 31, 12.8, 15, 8.5, 8.5],
        chart: {
          width: 500,
          type: 'pie',
        },
        labels: ['BreakLube Oil', 'Maintenance','Repair','Running Store','Sparepart','Others'],
        colors: ['#40A0FC', '#1EF627', '#FFA500', '#FF0000', '#9E00FF', '#FFF000'],
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -40,
              minAngleToShowLabel: 10,
              style: {
                fontfontSize: '18px',
              },
            },
          },
        },
        fill: {
          type: 'gradient', // Menggunakan gradient fill
          gradient: {
            shade: 'light', // Warna gradient (light atau dark)
            type: 'radial', // Radial gradient untuk pie chart
            shadeIntensity: 0.5, // Intensitas shade
            gradientToColors: ['#1235C5', '#17A31D', '#DB8E01', '#C30101', '#6301A0','#C4B800'], // Warna tujuan gradient
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [10, 100], // Gradient stops dari 0% hingga 100%
          },
        },
      },
    }
  },
  methods: {
    next() {
      this.chartIdx = this.chartIdx + 1
    },
    prev() {
      this.chartIdx = this.chartIdx - 1
    },
  },
}
</script>
<style>
.bg-head-total-expanse {
  background: #3A5DE7;
}
</style>
