<template>
  <b-container fluid>
    <!-- Tab -->
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items
                  class="col-md-6 p-0"
                  :active="true"
                  href="#privilege"
                  :title="
                    findMenuPermissionLv2ByIndex('menu', $route.query.menuId, 0)
                  "
                />
                <tab-nav-items
                  class="col-md-6 p-0"
                  :active="false"
                  href="#structure"
                  :title="
                    findMenuPermissionLv2ByIndex('menu', $route.query.menuId, 1)
                  "
                />
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <!-- End Tab -->
    <div id="pills-tabContent-2" class="tab-content">
      <!-- privilege -->
      <tab-content-item :active="true" id="privilege">
        <!-- Top Section !-->
        <b-row>
          <b-col lg="12">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title text-primary">
                  <strong>Role & Previlege</strong>
                </h4>
              </template>
              <template v-slot:body>
                <b-row class="mb-3">
                  <b-col cols="12" md="4">
                    <v-select
                      v-model="selectedRole"
                      label="roles"
                      :options="roles"
                      @input="mergeMenus"
                    ></v-select>
                  </b-col>
                  <b-col cols="12" md="2">
                    <b-button
                      v-if="
                        findMenuPermissionLv2ByIndex(
                          'update',
                          $route.query.menuId,
                          0
                        )
                      "
                      v-b-modal.edit-role
                      size="sm"
                      variant="primary"
                      class="mr-1"
                      v-b-tooltip.hover
                      title="Edit Role Name"
                    >
                      <template v-if="!loading_update">
                        <i class="fa fa-edit"></i>
                      </template>
                      <template v-else>
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span class="sr-only">Loading...</span>
                        Updating role...
                      </template>
                    </b-button>
                    <b-button
                      v-if="
                        findMenuPermissionLv2ByIndex(
                          'delete',
                          $route.query.menuId,
                          0
                        )
                      "
                      size="sm"
                      variant="danger"
                      class="mr-1"
                      v-b-tooltip.hover
                      title="Delete Role"
                      @click="onDeleteRole"
                    >
                      <template v-if="!loading_delete">
                        <i class="fa fa-trash"></i>
                      </template>
                      <template v-else>
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span class="sr-only">Loading...</span>
                        Deleting role...
                      </template>
                    </b-button>
                    <b-modal
                      id="edit-role"
                      size="sm"
                      :title="`Edit Role ${selectedRole.roles}`"
                      ok-title="Save"
                      cancel-title="Cancel"
                      centered
                      @ok="onUpdateRole"
                    >
                      <b-form-input
                        v-model="selectedRole.roles"
                        placeholder="Name of Role"
                        type="text"
                      ></b-form-input>
                    </b-modal>
                  </b-col>
                  <b-col cols="12" md="6" class="text-right">
                    <b-button
                      v-b-modal.add-role
                      variant="light"
                      class="mr-2"
                      v-if="
                        findMenuPermissionLv2ByIndex(
                          'add',
                          $route.query.menuId,
                          0
                        )
                      "
                    >
                      <template v-if="!loading_add"> Add New Role </template>
                      <template v-else>
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span class="sr-only">Loading...</span>
                        Adding role...
                      </template>
                    </b-button>
                    <b-button
                      variant="primary"
                      @click="onSavePrivilege"
                      v-if="
                        findMenuPermissionLv2ByIndex(
                          'update',
                          $route.query.menuId,
                          0
                        )
                      "
                    >
                      <template v-if="!loading_privilege">
                        Save Privileges
                      </template>
                      <template v-else>
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span class="sr-only">Loading...</span>
                        Saving Privileges...
                      </template>
                    </b-button>
                    <b-modal
                      id="add-role"
                      size="sm"
                      title="Add Role"
                      ok-title="Add New Role"
                      cancel-title="Cancel"
                      centered
                      @ok="onAddNewRole"
                    >
                      <b-form-input
                        v-model="newRole"
                        placeholder="Name of Role"
                        type="text"
                      ></b-form-input>
                    </b-modal>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <div v-if="loading_menu" class="text-center my-5">
                      <b-spinner type="grow" label="Spinning"></b-spinner>
                    </div>
                    <template v-else>
                      <div v-if="menus.length > 0" class="table-responsive">
                        <table class="table mb-0 table-striped table-hover">
                          <thead class="thead-dark text-center">
                            <tr>
                              <th width="5%">No.</th>
                              <th width="35%">Data</th>
                              <th width="10%">Add</th>
                              <th width="10%">Update</th>
                              <th width="10%">Delete</th>
                              <th width="10%">Export</th>
                              <th width="10%">Review</th>
                              <th width="10%">Approval</th>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-for="(item, key) in menus">
                              <tr :key="`menu-${key}`">
                                <td class="text-center">
                                  <b-form-checkbox
                                    :disabled="
                                      !findMenuPermissionLv2ByIndex(
                                        'update',
                                        $route.query.menuId,
                                        0
                                      )
                                    "
                                    v-model="checkedMenus[key].active"
                                    :name="`basic-checkbox-${key}`"
                                    @change="selectMenu(key)"
                                  ></b-form-checkbox>
                                </td>
                                <td>{{ item.menu }}</td>
                                <td class="text-center">
                                  <template v-if="item.add">
                                    <b-form-checkbox
                                      v-model="checkedMenus[key].add"
                                      name="check-button"
                                      switch
                                      inline
                                      :disabled="
                                        !findMenuPermissionLv2ByIndex(
                                          'update',
                                          $route.query.menuId,
                                          0
                                        )
                                      "
                                      @change="handleParentActive(key)"
                                    >
                                    </b-form-checkbox>
                                  </template>
                                  <template v-else>&nbsp;</template>
                                </td>
                                <td class="text-center">
                                  <template v-if="item.update">
                                    <b-form-checkbox
                                      :key="key"
                                      v-model="checkedMenus[key].update"
                                      name="check-button"
                                      switch
                                      inline
                                      :disabled="
                                        !findMenuPermissionLv2ByIndex(
                                          'update',
                                          $route.query.menuId,
                                          0
                                        )
                                      "
                                      @change="handleParentActive(key)"
                                    >
                                    </b-form-checkbox>
                                  </template>
                                  <template v-else>&nbsp;</template>
                                </td>
                                <td class="text-center">
                                  <template v-if="item.delete">
                                    <b-form-checkbox
                                      :key="key"
                                      v-model="checkedMenus[key].delete"
                                      name="check-button"
                                      switch
                                      inline
                                      :disabled="
                                        !findMenuPermissionLv2ByIndex(
                                          'update',
                                          $route.query.menuId,
                                          0
                                        )
                                      "
                                      @change="handleParentActive(key)"
                                    >
                                    </b-form-checkbox>
                                  </template>
                                  <template v-else>&nbsp;</template>
                                </td>
                                <td class="text-center">
                                  <template v-if="item.export">
                                    <b-form-checkbox
                                      :key="key"
                                      v-model="checkedMenus[key].export"
                                      name="check-button"
                                      switch
                                      inline
                                      :disabled="
                                        !findMenuPermissionLv2ByIndex(
                                          'update',
                                          $route.query.menuId,
                                          0
                                        )
                                      "
                                      @change="handleParentActive(key)"
                                    >
                                    </b-form-checkbox>
                                  </template>
                                  <template v-else>&nbsp;</template>
                                </td>
                                <td class="text-center">
                                  <template v-if="item.review">
                                    <b-form-checkbox
                                      :key="key"
                                      v-model="checkedMenus[key].review"
                                      name="check-button"
                                      switch
                                      inline
                                      :disabled="
                                        !findMenuPermissionLv2ByIndex(
                                          'update',
                                          $route.query.menuId,
                                          0
                                        )
                                      "
                                      @change="handleParentActive(key)"
                                    >
                                    </b-form-checkbox>
                                  </template>
                                  <template v-else>&nbsp;</template>
                                </td>
                                <td class="text-center">
                                  <template v-if="item.approval">
                                    <b-form-checkbox
                                      :key="key"
                                      v-model="checkedMenus[key].approval"
                                      name="check-button"
                                      switch
                                      inline
                                      :disabled="
                                        !findMenuPermissionLv2ByIndex(
                                          'update',
                                          $route.query.menuId,
                                          0
                                        )
                                      "
                                      @change="handleParentActive(key)"
                                    >
                                    </b-form-checkbox>
                                  </template>
                                  <template v-else>&nbsp;</template>
                                </td>
                              </tr>

                              <!-- SUB MENU -->
                              <template v-for="(subMenuItem, keySub) in item.child">
                                <tr :key="`submenu-${key}${keySub}`">
                                  <td class="text-center">
                                    <b-form-checkbox
                                      v-model="checkedMenus[key].child[keySub].active"
                                      :name="`submenu-ck-${keySub}`"
                                      :disabled="
                                        !findMenuPermissionLv2ByIndex(
                                          'update',
                                          $route.query.menuId,
                                          0
                                        )
                                      "
                                      @change="selectMenu(key, keySub, false)"
                                    ></b-form-checkbox>
                                  </td>
                                  <td
                                    ><span style="margin-right: 60px"></span
                                    >{{ subMenuItem.menu }}</td
                                  >
                                  <td class="text-center">
                                    <template v-if="subMenuItem.add">
                                      <b-form-checkbox
                                        :key="keySub"
                                        v-model="checkedMenus[key].child[keySub].add"
                                        name="check-button"
                                        switch
                                        inline
                                        :disabled="
                                          !findMenuPermissionLv2ByIndex(
                                            'update',
                                            $route.query.menuId,
                                            0
                                          )
                                        "
                                        @change="handleParentActive(key, keySub, false)"
                                      >
                                      </b-form-checkbox>
                                    </template>
                                    <template v-else>&nbsp;</template>
                                  </td>
                                  <td class="text-center">
                                    <template v-if="subMenuItem.update">
                                      <b-form-checkbox
                                        :key="keySub"
                                        v-model="checkedMenus[key].child[keySub].update"
                                        name="check-button"
                                        switch
                                        inline
                                        :disabled="
                                          !findMenuPermissionLv2ByIndex(
                                            'update',
                                            $route.query.menuId,
                                            0
                                          )
                                        "
                                        @change="handleParentActive(key, keySub, false)"
                                      >
                                      </b-form-checkbox>
                                    </template>
                                    <template v-else>&nbsp;</template>
                                  </td>
                                  <td class="text-center">
                                    <template v-if="subMenuItem.delete">
                                      <b-form-checkbox
                                        :key="keySub"
                                        v-model="checkedMenus[key].child[keySub].delete"
                                        name="check-button"
                                        switch
                                        inline
                                        :disabled="
                                          !findMenuPermissionLv2ByIndex(
                                            'update',
                                            $route.query.menuId,
                                            0
                                          )
                                        "
                                        @change="handleParentActive(key, keySub, false)"
                                      >
                                      </b-form-checkbox>
                                    </template>
                                    <template v-else>&nbsp;</template>
                                  </td>
                                  <td class="text-center">
                                    <template v-if="subMenuItem.export">
                                      <b-form-checkbox
                                        :key="keySub"
                                        v-model="checkedMenus[key].child[keySub].export"
                                        name="check-button"
                                        switch
                                        inline
                                        :disabled="
                                          !findMenuPermissionLv2ByIndex(
                                            'update',
                                            $route.query.menuId,
                                            0
                                          )
                                        "
                                        @change="handleParentActive(key, keySub, false)"
                                      >
                                      </b-form-checkbox>
                                    </template>
                                    <template v-else>&nbsp;</template>
                                  </td>
                                  <td class="text-center">
                                    <template v-if="subMenuItem.review">
                                      <b-form-checkbox
                                        :key="keySub"
                                        v-model="checkedMenus[key].child[keySub].review"
                                        name="check-button"
                                        switch
                                        inline
                                        :disabled="
                                          !findMenuPermissionLv2ByIndex(
                                            'update',
                                            $route.query.menuId,
                                            0
                                          )
                                        "
                                        @change="handleParentActive(key, keySub, false)"
                                      >
                                      </b-form-checkbox>
                                    </template>
                                    <template v-else>&nbsp;</template>
                                  </td>
                                  <td class="text-center">
                                    <template v-if="subMenuItem.approval">
                                      <b-form-checkbox
                                        :key="keySub"
                                        v-model="checkedMenus[key].child[keySub].approval"
                                        name="check-button"
                                        switch
                                        inline
                                        :disabled="
                                          !findMenuPermissionLv2ByIndex(
                                            'update',
                                            $route.query.menuId,
                                            0
                                          )
                                        "
                                        @change="handleParentActive(key, keySub, false)"
                                      >
                                      </b-form-checkbox>
                                    </template>
                                    <template v-else>&nbsp;</template>
                                  </td>
                                </tr>

                                <!-- TAB MENU / SUB CHILD -->
                                <template
                                  v-for="(tabMenuItem, keyTab) in subMenuItem.child"
                                >
                                  <tr :key="`keytab-${key}${keySub}${keyTab}`">
                                    <td class="text-center">
                                      <b-form-checkbox
                                        v-model="
                                          checkedMenus[key].child[keySub].child[keyTab]
                                            .active
                                        "
                                        :name="`tab-ck-${keySub}`"
                                        :disabled="
                                          !findMenuPermissionLv2ByIndex(
                                            'update',
                                            $route.query.menuId,
                                            0
                                          )
                                        "
                                        @change="
                                          selectMenu(key, keySub, false, true, keyTab)
                                        "
                                      ></b-form-checkbox>
                                    </td>
                                    <td
                                      ><span style="margin-right: 100px"></span
                                      >{{ tabMenuItem.menu }}</td
                                    >
                                    <td class="text-center">
                                      <template v-if="tabMenuItem.add">
                                        <b-form-checkbox
                                          :key="keySub"
                                          v-model="
                                            checkedMenus[key].child[keySub].child[keyTab]
                                              .add
                                          "
                                          name="check-button"
                                          switch
                                          inline
                                          :disabled="
                                            !findMenuPermissionLv2ByIndex(
                                              'update',
                                              $route.query.menuId,
                                              0
                                            )
                                          "
                                          @change="
                                            handleParentActive(
                                              key,
                                              keySub,
                                              false,
                                              true,
                                              keyTab
                                            )
                                          "
                                        >
                                        </b-form-checkbox>
                                      </template>
                                      <template v-else>&nbsp;</template>
                                    </td>
                                    <td class="text-center">
                                      <template v-if="tabMenuItem.update">
                                        <b-form-checkbox
                                          v-model="
                                            checkedMenus[key].child[keySub].child[keyTab]
                                              .update
                                          "
                                          name="check-button"
                                          switch
                                          inline
                                          :disabled="
                                            !findMenuPermissionLv2ByIndex(
                                              'update',
                                              $route.query.menuId,
                                              0
                                            )
                                          "
                                          @change="
                                            handleParentActive(
                                              key,
                                              keySub,
                                              false,
                                              true,
                                              keyTab
                                            )
                                          "
                                        >
                                        </b-form-checkbox>
                                      </template>
                                      <template v-else>&nbsp;</template>
                                    </td>
                                    <td class="text-center">
                                      <template v-if="tabMenuItem.delete">
                                        <b-form-checkbox
                                          v-model="
                                            checkedMenus[key].child[keySub].child[keyTab]
                                              .delete
                                          "
                                          name="check-button"
                                          switch
                                          inline
                                          :disabled="
                                            !findMenuPermissionLv2ByIndex(
                                              'update',
                                              $route.query.menuId,
                                              0
                                            )
                                          "
                                          @change="
                                            handleParentActive(
                                              key,
                                              keySub,
                                              false,
                                              true,
                                              keyTab
                                            )
                                          "
                                        >
                                        </b-form-checkbox>
                                      </template>
                                      <template v-else>&nbsp;</template>
                                    </td>
                                    <td class="text-center">
                                      <template v-if="tabMenuItem.export">
                                        <b-form-checkbox
                                          v-model="
                                            checkedMenus[key].child[keySub].child[keyTab]
                                              .export
                                          "
                                          name="check-button"
                                          switch
                                          inline
                                          :disabled="
                                            !findMenuPermissionLv2ByIndex(
                                              'update',
                                              $route.query.menuId,
                                              0
                                            )
                                          "
                                          @change="
                                            handleParentActive(
                                              key,
                                              keySub,
                                              false,
                                              true,
                                              keyTab
                                            )
                                          "
                                        >
                                        </b-form-checkbox>
                                      </template>
                                      <template v-else>&nbsp;</template>
                                    </td>
                                    <td class="text-center">
                                      <template v-if="tabMenuItem.review">
                                        <b-form-checkbox
                                          v-model="
                                            checkedMenus[key].child[keySub].child[keyTab]
                                              .review
                                          "
                                          name="check-button"
                                          switch
                                          inline
                                          :disabled="
                                            !findMenuPermissionLv2ByIndex(
                                              'update',
                                              $route.query.menuId,
                                              0
                                            )
                                          "
                                          @change="
                                            handleParentActive(
                                              key,
                                              keySub,
                                              false,
                                              true,
                                              keyTab
                                            )
                                          "
                                        >
                                        </b-form-checkbox>
                                      </template>
                                      <template v-else>&nbsp;</template>
                                    </td>
                                    <td class="text-center">
                                      <template v-if="tabMenuItem.approval">
                                        <b-form-checkbox
                                          v-model="
                                            checkedMenus[key].child[keySub].child[keyTab]
                                              .approval
                                          "
                                          name="check-button"
                                          switch
                                          inline
                                          :disabled="
                                            !findMenuPermissionLv2ByIndex(
                                              'update',
                                              $route.query.menuId,
                                              0
                                            )
                                          "
                                          @change="
                                            handleParentActive(
                                              key,
                                              keySub,
                                              false,
                                              true,
                                              keyTab
                                            )
                                          "
                                        >
                                        </b-form-checkbox>
                                      </template>
                                      <template v-else>&nbsp;</template>
                                    </td>
                                  </tr>
                                </template>
                              </template>
                            </template>
                          </tbody>
                        </table>
                      </div>
                      <div v-else class="text-center my-5">
                        <p class="mb-0 text-muted"
                          >No privilege data found on selected role.</p
                        >
                      </div>
                    </template>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
          </b-col>
        </b-row>
        <!-- End Top Section !-->
        <!-- Content Section !-->
        <b-card class="mb-4">

        </b-card>
        <!-- End Content Section !-->
      </tab-content-item>
      <!-- End privilege -->
      <!-- structure -->
      <tab-content-item :active="false" id="structure">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">
              <strong>User Structure</strong>
            </h4>
          </template>
          <template v-slot:body>
            <b-alert
              variant="success"
              :show="structure_notification.show"
              dismissible
              fade
              >{{ structure_notification.text }}</b-alert
            >

            <vue-nestable v-model="structures" @change="structuresChanged">
              <vue-nestable-handle slot-scope="{ item }" :item="item">
                <div
                  class="w-100 d-flex align-items-center justify-content-between background-slate py-2 px-3"
                >
                  <div class="d-flex align-items-center">
                    <div class="pr-2 border-right mr-2">
                      <i class="fas fa-bars"></i>
                    </div>
                    <b-avatar
                      variant="warning"
                      size="26px"
                      class="mr-1"
                    ></b-avatar>
                    <h6 class="text-md">
                      {{ item.text }}
                    </h6>
                  </div>
                  <button
                    class="btn text-white"
                    style="background: #3a5de7"
                    @click="$router.push(`/edit-user/${item.id}`)"
                  >
                    <i class="fa fa-edit p-0 m-0"></i> Update
                  </button>
                </div>
              </vue-nestable-handle>
            </vue-nestable>
          </template>
        </iq-card>
      </tab-content-item>
      <!-- End structure -->
    </div>
  </b-container>
</template>
<script>
import {
  rolesActions,
  menusActions,
  structuresActions,
} from '@src/Utils/helper'

export default {
  name: 'UserRolePrivilege',
  data() {
    return {
      roles: [],
      menus: [],
      checkedMenus: [],
      structures: [],
      selectedRole: {},
      newRole: '',
      loading_menu: false,
      loading_add: false,
      loading_update: false,
      loading_delete: false,
      loading_privilege: false,
      structure_notification: {
        show: false,
        text: '',
      },
    }
  },
  async mounted() {
    await this.fetchRoles()
    await this.fetchMenus()
    this.mergeMenus()
    this.fetchStructures()
  },
  methods: {
    ...rolesActions,
    ...menusActions,
    ...structuresActions,
    async fetchRoles(update = false, selectedRole = {}) {
      let res = await this.getRoles()
      this.roles = res.data
      if (update) this.selectedRole = selectedRole
      else this.selectedRole = res.data[0]
    },
    async fetchMenus() {
      let res = await this.getMenus()

      if (res.status == 'error') this.menus = []
      else this.menus = res.data
    },
    async mergeMenus() {
      this.loading_menu = true
      let res_role = await this.getMenusByRole(this.selectedRole.id)

      this.checkedMenus = []

      let menus = this.menus
      let checked_menus = res_role.data
      if (res_role.status == 'error') checked_menus = []

      let merged_menus = []

      menus.forEach((menu) => {
        let sub_menus = []
        let found_menu = checked_menus.find((m) => m.menuId == menu.menuId)

        if (menu.child.length > 0) {
          menu.child.forEach((sub_menu) => {
            let tabs = []
            let found_sub_menu = null

            if (found_menu)
              found_sub_menu = found_menu.child.find(
                (m) => m.menuId == sub_menu.menuId
              )

            if (sub_menu.child.length > 0) {
              sub_menu.child.forEach((tab) => {
                let found_tab = []

                if (found_sub_menu)
                  found_tab = found_sub_menu.child.find(
                    (m) => m.menuId == tab.menuId
                  )

                let to_push_tabs = {
                  menuId: tab.menuId,
                  active: false,
                  add: false,
                  update: false,
                  delete: false,
                  export: false,
                  review: false,
                  approval: false,
                }
                if (found_sub_menu) {
                  if (found_tab) {
                    to_push_tabs = {
                      menuId: found_tab.menuId,
                      active: found_tab.active,
                      add: found_tab.active ? found_tab.add : false,
                      update: found_tab.active ? found_tab.update : false,
                      delete: found_tab.active ? found_tab.delete : false,
                      export: found_tab.active ? found_tab.export : false,
                      review: found_tab.active ? found_tab.review : false,
                      approval: found_tab.active ? found_tab.approval : false,
                    }
                  }
                }

                tabs.push(to_push_tabs)
              })
            }

            let to_push_sub_menu = {
              menuId: sub_menu.menuId,
              active: false,
              add: false,
              update: false,
              delete: false,
              export: false,
              review: false,
              approval: false,
              child: tabs,
            }

            if (found_menu) {
              if (found_sub_menu) {
                to_push_sub_menu = {
                  menuId: found_sub_menu.menuId,
                  active: found_sub_menu.active,
                  add: found_sub_menu.active ? found_sub_menu.add : false,
                  update: found_sub_menu.active ? found_sub_menu.update : false,
                  delete: found_sub_menu.active ? found_sub_menu.delete : false,
                  export: found_sub_menu.active ? found_sub_menu.export : false,
                  review: found_sub_menu.active ? found_sub_menu.review : false,
                  approval: found_sub_menu.active
                    ? found_sub_menu.approval
                    : false,
                  child: tabs,
                }
              }
            }

            sub_menus.push(to_push_sub_menu)
          })
        }

        let to_push = {
          menuId: menu.menuId,
          active: false,
          add: false,
          update: false,
          delete: false,
          export: false,
          review: false,
          approval: false,
          child: sub_menus,
        }

        if (found_menu) {
          to_push = {
            menuId: found_menu.menuId,
            active: found_menu.active,
            add: found_menu.active ? found_menu.add : false,
            update: found_menu.active ? found_menu.update : false,
            delete: found_menu.active ? found_menu.delete : false,
            export: found_menu.active ? found_menu.export : false,
            review: found_menu.active ? found_menu.review : false,
            approval: found_menu.active ? found_menu.approval : false,
            child: sub_menus,
          }
        }

        merged_menus.push(to_push)
      })

      this.checkedMenus = merged_menus
      this.loading_menu = false
    },
    async onAddNewRole() {
      this.loading_add = true
      if (this.newRole !== '') {
        let res = await this.addRole(this.newRole)
        if (res.status == 'success') {
          this.loading_add = false
          await this.fetchRoles(true, res.data)
          this.mergeMenus()
          this.$swal(
            'New Role Added!',
            `Role ${this.newRole} added successfully.`,
            'success'
          )
        } else {
          this.loading_add = false
          if (res.data.message != null)
            this.$swal('Error', res.data.message, 'error')
          else
            this.$swal(
              'Error',
              'Something wrong with the server. Please try again later.',
              'error'
            )
        }
      } else {
        this.loading_add = false
        this.$swal('Error', 'Role name cannot be empty', 'error')
      }
    },
    async onUpdateRole() {
      this.loading_update = true
      let res = await this.updateRole(this.selectedRole)
      if (res.status == 'success') {
        this.loading_update = false
        this.fetchRoles(true, this.selectedRole)
        this.$swal(
          'Role Updated!',
          'Role has been updated successfully.',
          'success'
        )
      } else {
        this.loading_update = false
        if (res.data.message != null)
          this.$swal('Error', res.data.message, 'error')
        else
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
      }
    },
    async onDeleteRole() {
      this.$swal({
        title: 'Delete role?',
        text: `${this.selectedRole.roles} will be deleted and cannot be recovered.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.loading_delete = true
          let res = await this.deleteRole(this.selectedRole.id)
          if (res.status != 'success') {
            this.loading_delete = false
            if (res.data.message != null)
              this.$swal('Error', res.data.message, 'error')
            else
              this.$swal(
                'Error',
                'Something wrong with the server. Cannot get user data.',
                'error'
              )
          } else {
            this.loading_delete = false
            await this.fetchRoles()
            this.mergeMenus()
            this.$swal(
              `Role deleted!`,
              `Role ${this.selectedRole.roles} successfully deleted`,
              'success'
            )
          }
        }
      })
    },
    async fetchStructures() {
      this.structures = []
      let res = await this.getStructures()
      res.data.forEach((s) => this.structures.push(this.putStructureObject(s)))
    },
    async onSavePrivilege() {
      this.loading_privilege = true

      let privileges = []

      this.checkedMenus.forEach((menu) => {
        if (menu.active) {
          privileges.push({
            menuId: menu.menuId,
            active: menu.active,
            add: menu.add,
            update: menu.update,
            delete: menu.delete,
            export: menu.export,
            review: menu.review,
            approval: menu.approval,
          })

          menu.child.forEach((sub_menu) => {
            if (sub_menu.active) {
              privileges.push({
                menuId: sub_menu.menuId,
                active: sub_menu.active,
                add: sub_menu.add,
                update: sub_menu.update,
                delete: sub_menu.delete,
                export: sub_menu.export,
                review: sub_menu.review,
                approval: sub_menu.approval,
              })

              sub_menu.child.forEach((tab) => {
                if (tab.active) {
                  privileges.push({
                    menuId: tab.menuId,
                    active: tab.active,
                    add: tab.add,
                    update: tab.update,
                    delete: tab.delete,
                    export: tab.export,
                    review: tab.review,
                    approval: tab.approval,
                  })
                }
              })
            }
          })
        }
      })

      let res = await this.saveRolePrivilege({
        roleId: this.selectedRole.id,
        data: privileges,
      })
      if (res.status == 'success') {
        this.loading_privilege = false
        this.$swal(
          'Privilege saved!',
          `Privilege of role ${this.selectedRole.roles} saved successfully.`,
          'success'
        )
      } else {
        this.loading_privilege = false
        if (res.data.message != null)
          this.$swal('Error', res.data.message, 'error')
        else
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
      }
    },
    async structuresChanged(val, opt) {
      if (
        !this.findMenuPermissionLv2ByIndex(
          'update',
          this.$route.query.menuId,
          1
        )
      ) {
        return
      }

      this.structure_notification.show = false
      let res = await this.saveStructures(this.structures)
      if (res.status == 'success') {
        this.structure_notification.text = `User structure changed! Position of ${val.text} updated!`
      } else {
        if (res.data.message != null)
          this.structure_notification.text = `Error! ${res.data.message}!`
        else
          this.structure_notification.text = `Error! Something wrong with the server. Please try again later.`
      }
      this.structure_notification.show = true
    },
    putStructureObject(object) {
      let children = []

      if (object.child.length > 0) {
        object.child.forEach((child) =>
          children.push(this.putStructureObject(child))
        )
      }

      return {
        id: object.user.id,
        text: object.user.name,
        children,
      }
    },
    selectMenu(
      parent_index,
      child_index = null,
      parent = true,
      tab = false,
      tab_index = null
    ) {
      let menu_to_reset = this.checkedMenus[parent_index]

      if (!parent) {
        menu_to_reset = this.checkedMenus[parent_index].child[child_index]
      }

      if (tab) {
        menu_to_reset =
          this.checkedMenus[parent_index].child[child_index].child[tab_index]
      }

      // IF USER UNSELECT THE MENU
      if (!menu_to_reset.active) {
        // OF COURSE we want to make unselected menu marked as not selected
        menu_to_reset.add = false
        menu_to_reset.delete = false
        menu_to_reset.update = false
        menu_to_reset.review = false
        menu_to_reset.approval = false
        menu_to_reset.export = false

        // IF USER UNSELECT PARENT/MENU > we want to make sure
        // it's child (submenu & tabmenu) also unselected
        if (parent && !tab) {
          if (this.checkedMenus[parent_index].child.length > 0) {
            this.checkedMenus[parent_index].child.forEach((sub_menu) => {
              sub_menu.active = false
              sub_menu.add = false
              sub_menu.delete = false
              sub_menu.update = false
              sub_menu.review = false
              sub_menu.approval = false
              sub_menu.export = false

              if (sub_menu.child.length > 0) {
                sub_menu.child.forEach((tab_menu) => {
                  tab_menu.active = false
                  tab_menu.add = false
                  tab_menu.delete = false
                  tab_menu.update = false
                  tab_menu.review = false
                  tab_menu.approval = false
                  tab_menu.export = false
                })
              }
            })
          }
        }

        // IF USER UNSELECT SUBMENU > we want to make sure it's child (tabmenu) also unselected
        if (!parent && !tab) {
          if (
            this.checkedMenus[parent_index].child[child_index].child.length > 0
          ) {
            this.checkedMenus[parent_index].child[child_index].child.forEach(
              (tab_menu) => {
                tab_menu.active = false
                tab_menu.add = false
                tab_menu.delete = false
                tab_menu.update = false
                tab_menu.review = false
                tab_menu.approval = false
                tab_menu.export = false
              }
            )
          }
        }

        // IF USER SELECT THE MENU
      } else {
        // IF SUBMENU SELECTED > we want also to make parent/menu active
        if (!parent && !tab) {
          this.checkedMenus[parent_index].active = true
        }

        // IF TABMENU SELECTED > we want also to make parent/menu & submenu active
        if (!parent && tab) {
          this.checkedMenus[parent_index].active = true
          this.checkedMenus[parent_index].child[child_index].active = true
        }

        // OF COURSE we want to make selected menu marked as active
        menu_to_reset.active = true
        menu_to_reset.add = true
        menu_to_reset.delete = true
        menu_to_reset.update = true
        menu_to_reset.review = true
        menu_to_reset.approval = true
        menu_to_reset.export = true
      }
    },
    handleParentActive(
      parent_index,
      child_index = null,
      parent = true,
      tab = false,
      tab_index = null
    ) {
      if (parent) this.checkedMenus[parent_index].active = true
      else if (tab) {
        this.checkedMenus[parent_index].active = true
        this.checkedMenus[parent_index].child[child_index].active = true
        this.checkedMenus[parent_index].child[child_index].child[
          tab_index
        ].active = true
      } else {
        this.checkedMenus[parent_index].active = true
        this.checkedMenus[parent_index].child[child_index].active = true
      }
    },
  },
}
</script>
<style scoped>
.background-slate {
  background: #f4f4f4;
  border-radius: 10px;
}
.text-md {
  font-size: 16px;
  font-weight: 500;
}
</style>
