<template>
  <b-col md="12">
    <!-- Add Fleet -->
    <a id="add-vehicle" v-b-modal.add-fleet href="#" class="button-float">
      <i class="fa fa-plus my-button-float"></i>
      <i class="fa fa-plus fa fa-ship my-button-float"></i>
    </a>
    <b-modal
      id="add-fleet"
      size="xl"
      :no-close-on-backdrop="true"
      :header-bg-variant="modal.headerBgVariant"
      :header-text-variant="modal.headerTextVariant"
      :body-bg-variant="modal.bodyBgVariant"
      :body-text-variant="modal.bodyTextVariant"
      hide-footer
    >
      <template v-slot:modal-header="{ close }">
        <h5>Add More Your Fleet</h5>
        <!-- Emulate built in modal header close button action -->
        <b-button size="md" variant="outline-danger" @click="close">
          <i class="fa fa-times"></i> Close
        </b-button>
      </template>
      <AddFleet
        v-bind:isAddForChild="true"
        @childAction="$bvModal.hide('add-fleet')"
      />
    </b-modal>
    <b-tooltip target="add-vehicle">Add More Your Fleet</b-tooltip>
    <!-- End Add Fleet -->
    <b-col sm="12">
      <div
        style="
          height: 100%;
          width: 100%;
          position: fixed;
          margin-left: -30px;
          margin-top: -120px;
        "
      >
        <l-map
          :zoom="zoom"
          :center="center"
          :options="mapOptions"
          style="width: 100%; height: 100%"
          @update:center="centerUpdate"
          @update:zoom="zoomUpdate"
        >
          <l-tile-layer :url="url" :attribution="attribution" />
          <l-control-zoom position="bottomleft"></l-control-zoom>
          <template v-for="(f, i) in newFleetActivities">
            <template v-if="f?.latitude && f?.longitude">
              <l-marker
                :lat-lng="[f?.latitude, f?.longitude]"
                :key="`fleet-${i}`"
              >
                <l-icon :icon-size="iconSize" :icon-anchor="iconAnchor">
                  <img
                    :src="f?.vehicleType?.icon?.url ?? require('../../../assets/images/fleet/PusherTug.png')"
                    style="width: 72px"
                  />
                </l-icon>
                <l-popup
                  :options="{ maxWidth: 'auto' }"
                  :lat-lng="[f?.latitude, f?.longitude]"
                >
                  <div style="width: 400px">
                    <!-- <router-link to="/ops/operational">
                      <h4>{{ f?.name }}</h4>
                    </router-link> -->
                    <h4>{{ f?.name }}</h4>
                    <div
                      class="mb-5 position-absolute"
                      style="right: 20px; top: 10px"
                    >
                      <b-dropdown
                        size="sm"
                        text="Small"
                        variant="light"
                        right
                      >
                        <template slot="button-content">
                          <i class="fa fa-ellipsis-h"></i>
                        </template>
                        <b-dropdown-item
                          variant="primary"
                          @click="fetchFleetDetail(f?.id)"
                        >
                          <i class="fa fa-edit"></i> Edit
                        </b-dropdown-item>
                        <b-dropdown-item
                          variant="danger"
                          @click="onDeleteFleet(f)"
                        >
                          <i class="fa fa-trash"></i> Delete
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                    <b-row>
                      <b-col md="12">
                        <b-card-text>
                          <b-row class="text-center">
                            <template v-for="(m, im) in f?.modules">
                              <b-col
                                cols="2"
                                md="2"
                                class="mr-2 mb-2"
                                :key="`m-${im}`"
                                v-if="
                                  m?.hasAccess &&
                                  (validateTechnicalPermission(m?.url) ||
                                    validateOperationalPermission(m?.url) ||
                                    validateReportPermission(m?.url))
                                "
                              >
                                <router-link
                                  :to="{
                                    path: m?.url,
                                    query: { vehicleId: f?.id },
                                  }"
                                >
                                  <img
                                    v-if="m?.icon && m?.icon?.url"
                                    :src="m?.icon?.url"
                                    alt="icon-image"
                                    v-b-tooltip.top="m?.name"
                                    style="width:70px; height:70px"
                                  />
                                  <img
                                    v-else
                                    src="@assets/images/flaticon/operational.png"
                                    alt="icon-image"
                                    v-b-tooltip.top="'Operational'"
                                    style="width:70px; height:70px"
                                  />
                                </router-link>
                              </b-col>
                            </template>
                            <!-- <b-col md="12"><router-link to="/ops/docking"><b-button block variant="warning" class="mb-3"
                                  ><i class="fa fa-ship"></i>Docking Intermediate
                                  Survey</b-button
                                ></router-link></b-col> -->
                          </b-row>
                        </b-card-text>
                      </b-col>
                    </b-row>
                  </div>
                </l-popup>
                <l-tooltip :options="{ permanent: false, interactive: true }">{{ f?.name }}</l-tooltip>
              </l-marker>
            </template>
          </template>
        </l-map>
      </div>
    </b-col>
  </b-col>
</template>


<script>
import { xray } from '../../../config/pluginInit'
import { latLng, icon } from 'leaflet'
import AddFleet from '../../MasterPages/AddFleet.vue'

export default {
  name: 'ContentMaps',
  components: {
    AddFleet,
  },
  props:{
    fleetActivities:{
      type:Array,
      default:() => []
    }, 
    fetchFleetDetail:{
      type:Function,
      default:() => []
    },
    onDeleteFleet:{
      type:Function,
      default:() => {}
    },  
    close:{
      type:Function,
      default:() => {}
    },
  },
  computed: {
    technicalPermission() {
      return this.lsProfile.menu
        .find((menu) => menu.menu === 'Apps')
        ?.child.find((child) => child?.menu === 'Technical')
    },
    operationalPermission() {
      return this.lsProfile.menu
        .find((menu) => menu?.menu === 'Apps')
        ?.child.find((child) => child?.menu === 'Operations')
    },
    reportPermission() {
      return this.lsProfile.menu
        .find((menu) => menu?.menu === 'Apps')
        ?.child.find((child) => child?.menu === 'Report')
    },
  },
  mounted () {
    xray.index()

    console.log(this.newFleetActivities, "newFleetActivities from components")
  },
  data() {
    return {
      lsProfile: JSON.parse(localStorage.getItem('profile')),

      newFleetActivities:[],
      modal: {
        show: false,
        variants: [
          'primary',
          'secondary',
          'success',
          'warning',
          'danger',
          'info',
          'light',
          'dark',
        ],
        headerBgVariant: 'light',
        headerTextVariant: 'dark',
        bodyBgVariant: 'default',
        bodyTextVariant: 'dark',
        footerBgVariant: '#000000',
        footerTextVariant: 'dark',
      },

      zoom: 5,
      center: latLng(-2.548926, 118.0148634),
      url: 'https://api.maptiler.com/maps/hybrid/{z}/{x}/{y}.jpg?key=T7IfHizkxdz98X76UCBi',
      attribution:
        '&copy; <a href="http://osm?.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 11.5,
      currentCenter: latLng(-2.548926, 118.0148634),
      showParagraph: true,
      mapOptions: {
        zoomSnap: 0.5,
        zoomControl: false,
      },
      showMap: true,
      withPopup: latLng(-2.548926, 118.0148634),
      icon: icon({
        iconUrl:
          'https://upload.wikimedia.org/wikipedia/commons/thumb/a/ab/Tugboat_diagram-en.svg/2560px-Tugboat_diagram-en.svg.png',
        iconSize: [62, 67],
        iconAnchor: [16, 37],
      }),
      lang: {
        formatLocale: {
          // MMMM
          months: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ],
          // MMM
          monthsShort: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ],
          // dddd
          weekdays: [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
          ],
          // ddd
          weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          // dd
          weekdaysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
          // first day of week
          firstDayOfWeek: 0,
          // first week contains January 1st.
          firstWeekContainsDate: 1,
        },
        monthBeforeYear: false,
      },
      iconSize: [62, 67],
      iconAnchor: [16, 37],
      dateRange: [],
      config: {
        dateFormat: 'Y-m-d',
        inline: true,
      },
    }
  },
  watch:{
    'fleetActivities.length': {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (value) {
          this.newFleetActivities = this.fleetActivities
        }
      },
    }
  },
  methods: {
    validateTechnicalPermission(url) {
      let getPermission = this.technicalPermission
        ? this.technicalPermission.child.find((menu) => menu.url === url)
        : null
      return getPermission ? getPermission.active : false
    },
    validateOperationalPermission(url) {
      let getPermission = this.operationalPermission
        ? this.operationalPermission.child.find((menu) => menu.url === url)
        : null
      return getPermission ? getPermission.active : false
    },
    validateReportPermission(url) {
      let getPermission = this.reportPermission
        ? this.reportPermission.child.find((menu) => menu.url === url)
        : null
      return getPermission ? getPermission.active : false
    },

    zoomUpdate(zoom) {
      this.zoom = zoom
    },
    centerUpdate(center) {
      this.center = center
    },

    getLatLng(lat, lng) {
      return latLng(lat, lng)
    },
    getIcon(icon) {
      if (!icon || !icon.url) {
        return 'fleet/PusherTug.png'
      }

      return this.$options.filters.checkPhoto(icon.url, 'fleet/PusherTug.png')
    },
  }
}
</script>
