<template>
  <div class="px-3">
    <b-row class="mb-2">
      <b-col md="12">
        <h6 class="mb-1" style="color: #3a5de7"
          ><strong>KPC PROJECT - TRANSHIPMENT</strong></h6
        >
        <div>
          <b-badge variant="warning" class="p-2">TB.SML 301</b-badge>
          <b-badge variant="primary" class="p-2 ml-1">BG. SML 104</b-badge>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <p class="mb-0 text-black col-3">POL</p>
      <p class="mb-0 text-black col-3">: <strong>Asam - Asam</strong></p>
      <p class="mb-0 text-black col-3">Actual COB</p>
      <p class="mb-0 text-black col-3">: <strong>12.034.555 MT</strong></p>
      <p class="mb-0 text-black col-3">POD</p>
      <p class="mb-0 text-black col-3">: <strong>Sangatta</strong></p>
      <p class="mb-0 text-black col-3">Cargo Type</p>
      <p class="mb-0 text-black col-3">: <strong>Coal</strong></p>
      <p class="mb-0 text-black col-3">Arrival Time POL</p>
      <p class="mb-0 text-black col-3">: <strong>05 July 2024 14:20</strong></p>
      <p class="mb-0 text-black col-3">Completed</p>
      <p class="mb-0 text-black col-3"
        >: <strong>05 July 2024 123:20</strong></p
      >
      <p class="mb-0 text-black col-3">Arrival Time POD</p>
      <p class="mb-0 text-black col-3">: <strong>05 July 1993 21:00</strong></p>
      <p class="mb-0 text-black col-3">Total Steaming Time</p>
      <p class="mb-0 text-black col-3">: <strong>7,67</strong></p>
    </b-row>
    <b-row>
      <b-col md="6" class="mb-2">
        <p class="text-black mb-1" style="font-weight: 500">Input Type</p>
        <div class="btn-group">
          <b-button
            variant="outline-primary"
            v-for="(btn, i) in inputTypeOpt"
            :key="i"
            @click="inputTypeSelected = btn"
            :class="{ active: inputTypeSelected == btn }"
            >{{ btn }}</b-button
          >
        </div>
      </b-col>
      <b-col md="6"></b-col>
      <b-col md="6">
        <b-form-group label="Currency" label-for="currency">
          <b-form-input id="currency"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="Freight Rate" label-for="fr">
          <b-form-input id="fr"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="TAX" label-for="fr">
          <b-form-radio name="radio-1" value="A">Include PPN</b-form-radio>
          <b-form-radio name="radio-2" value="B">Exclude PPN</b-form-radio>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="Total" label-for="total">
          <h5><strong>IDR 2.345.000.000,-</strong></h5>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      inputTypeOpt: ['By Shipment Plan Data', 'By Form Inpu'],
      inputTypeSelected: 'By Shipment Plan Data',
    }
  },
}
</script>
