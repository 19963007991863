<template>
  <b-container fluid>
    <form @submit.prevent="onSavePaymentList">
      <b-row>
        <b-col md="12">
          <b-button variant="primary" class="float-right mr-1" size="sm" type="submit" v-if="['EDIT', 'ADD'].includes(action)"><i class="fa fa-save"></i> {{ ['EDIT'].includes(action) ? 'Update' : 'Create' }} Payment List&nbsp; </b-button>
          <b-button variant="danger" class="float-right mr-1" size="sm" type="button" @click="closeSlideFormPayment"><i class="fa fa-ban"></i> Cancel&nbsp; </b-button>
        </b-col>
        <b-col md="12" class="p-4">
          <b-row>
            <b-col md="6">
              <b-form-group label="Request Name">
                <b-form-input v-if="['EDIT', 'ADD'].includes(action)" placeholder="Type Text" type="text" v-model="form.request_name"  />
                <strong v-if="['PREVIEW'].includes(action)">{{paymentData.request_name}}</strong>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="projectData?.project_contract_plan?.length > 0">
              <b-form-group label="For Trip">
                <v-select
                  v-if="['EDIT', 'ADD'].includes(action)"
                  label="shipment_plan_detail_number"
                  track-by="id"
                  name="plan_id"
                  v-model="form.plan_id"
                  :options="projectData?.project_contract_plan"
                  :reduce="(plan) => plan.id"
                  placeholder="Select..."
                  required
                ></v-select>
                <strong v-if="['PREVIEW'].includes(action)">{{paymentData.project_plan.shipment_plan_detail_number}}</strong>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Request Date">
                <b-form-input v-if="['EDIT', 'ADD'].includes(action)" type="date" v-model="form.request_date" required />
                <strong v-if="['PREVIEW'].includes(action)">{{moment(paymentData.request_date).format('DD MMMM YYYY')}}</strong>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Real Pricing">
                <b-form-input v-if="['EDIT', 'ADD'].includes(action)" placeholder="0" type="number" v-model="form.real_pricing" />
                <b-form-text v-if="['EDIT', 'ADD'].includes(action)" class="text-muted">{{ formatCurrency(form.real_pricing) }}</b-form-text>
                <strong v-if="['PREVIEW'].includes(action)">{{formatCurrency(paymentData.real_pricing)}}</strong>
              </b-form-group>
            </b-col> 
            <b-col md="6">
              <b-form-group label="Balance">
                <b-form-input v-if="['EDIT', 'ADD'].includes(action)" placeholder="0" type="number" v-model="form.balance" @change="onChangeBalance" />
                <b-form-text v-if="['EDIT', 'ADD'].includes(action)" class="text-muted">{{ formatCurrency(form.balance) }}</b-form-text>
                <strong v-if="['PREVIEW'].includes(action)">{{formatCurrency(paymentData.balance)}}</strong>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Invoice Price">
                <b-form-input v-if="['EDIT', 'ADD'].includes(action)" placeholder="0" type="number" v-model="form.invoice_price" @change="() => {onChangeInvoicePrice(); countTaxTotal(); countInvoiceTotal()}" />
                <b-form-text v-if="['EDIT', 'ADD'].includes(action)" class="text-muted">{{ formatCurrency(form.invoice_price) }}</b-form-text>
                <strong v-if="['PREVIEW'].includes(action)">{{ formatCurrency(paymentData.invoice_price) }}</strong>
              </b-form-group>
            </b-col>  
            <b-col md="6">
              <b-form-group label="Use Tax">
                <b-form-checkbox 
                  v-if="['EDIT', 'ADD'].includes(action)"
                  id="use_tax" 
                  v-model="form.use_tax" 
                  label="Yes / No" 
                  name="check-button"
                  switch
                  inline
                />
                <strong v-if="['PREVIEW'].includes(action)">{{ paymentData.use_tax ? 'Yes' : 'No' }}</strong>
              </b-form-group>
            </b-col>
            <template v-if="form.use_tax">
              <b-col md="6">
                <b-form-group label="Tax Name">
                  <b-form-input v-if="['EDIT', 'ADD'].includes(action)" placeholder="Type Text" type="text" v-model="form.name_tax" />
                  <strong v-if="['PREVIEW'].includes(action)">{{ paymentData.name_tax }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Tax Percentage (%)">
                  <b-form-input v-if="['EDIT', 'ADD'].includes(action)" placeholder="0.00" type="number" v-model="form.tax_percentage" step="0.001" @change="() => {countTaxTotal(); countInvoiceTotal()}" />
                    <strong v-if="['PREVIEW'].includes(action)">{{ Number(paymentData.tax_percentage).toLocaleString() }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Tax Total">
                  <b-form-input v-if="['EDIT', 'ADD'].includes(action)" placeholder="0" type="number" v-model="form.tax_total" />
                  <b-form-text v-if="['EDIT', 'ADD'].includes(action)" class="text-muted">{{ formatCurrency(form.tax_total) }}</b-form-text>
                  <strong v-if="['PREVIEW'].includes(action)">{{ formatCurrency(paymentData.tax_total) }}</strong>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Invoice Total">
                  <b-form-input v-if="['EDIT', 'ADD'].includes(action)" placeholder="0" type="number" v-model="form.invoice_total" />
                  <b-form-text v-if="['EDIT', 'ADD'].includes(action)" class="text-muted">{{ formatCurrency(form.invoice_total) }}</b-form-text>
                  <strong v-if="['PREVIEW'].includes(action)">{{ formatCurrency(paymentData.tax_total) }}</strong>
                </b-form-group>
              </b-col>
            </template>
            <b-col md="12">
              <strong>Deduction</strong>
              <div class="overflow-auto">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th class="text-center" style="vertical-align:middle; width: 20px">#</th>
                      <th class="text-center text-truncate" style="vertical-align:middle; width: 250px">Name</th>
                      <th class="text-center text-truncate" style="vertical-align:middle; width: 250px">Amount</th>
                      <th class="text-center text-truncate" style="vertical-align:middle; width: 250px">Remarks</th>
                      <th class="text-center text-truncate" style="vertical-align:middle; width: 250px">File</th>
                    </tr>
                  </thead>
                  <tbody v-if="['ADD', 'EDIT'].includes(action)"> 
                    <tr v-for="(state, index) in form.deduction" :key="index">
                      <td class="text-center align-top">
                        <b-button size="sm" variant="danger" @click="() => {
                          form.deduction.splice(index, 1);
                          form.invoice_price += state.amount;
                        }" v-if="index !== 0"><i class="fa fa-trash pr-0"></i></b-button>
                      </td>
                      <td class="align-top">
                        <b-form-input placeholder="Type Text" v-model="state.name" />
                      </td>
                      <td class="align-top">
                        <b-form-input placeholder="Type Number" v-model="state.amount" @blur="countInvoicePrice" />
                        <b-form-text v-if="['EDIT', 'ADD'].includes(action)" class="text-muted">{{ formatCurrency(state.amount) }}</b-form-text>
                      </td>
                      <td class="align-top">
                        <b-form-input placeholder="Type Text" v-model="state.remarks" />
                      </td>
                      <td>
                        <b-form-file
                          :id="'fileSupport'+index"
                          placeholder="Choose a file"
                          accept="application/pdf"
                          v-model="state.file"
                        >
                        </b-form-file>
                        <span @click="handleOpenFilePreview(state?.file_url)" class="text-primary" style="cursor: pointer">{{ state?.file_name }}</span>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr v-for="(state, index) in form.deduction" :key="index">
                      <td class="text-center align-top">#</td>
                      <td class="text-center align-top">{{ state?.name }}</td>
                      <td class="text-center align-top">{{ state?.amount }}</td>
                      <td class="text-center align-top">{{ state?.remarks }}</td>
                      <td class="text-center">
                        <span @click="handleOpenFilePreview(state?.file_url)" class="text-primary" style="cursor: pointer">{{ state?.file_name }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <b-button v-if="['ADD', 'EDIT'].includes(action)" block variant="success" type="button" @click="addDeduction"><i class="fa fa-plus pr-0"></i> ADD MORE DEDUCTION</b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>

<script>
  import _ from 'lodash'
  import moment from 'moment'
  import { busdevActions } from '@src/Utils/helper'

  export default {
    name:'TabPaymentList',
    props:{
      action:{
        type:String,
        default:''
      },  
      id:{
        type:Number,
        default:null
      },  
      totalUnpaid:{
        type:Number,
        default:0
      }, 
      refreshData:{
        type:Function,
        default:true
      }, 
      closeSlideFormPayment:{
        type:Function,
        default:true
      },  
      projectData:{
        type:Object,
        default:null
      }, 
    },
    components:{
    },
    data(){
      return{
        apiUrl: process.env.VUE_APP_API_BUSDEV_BASE_URL,
        form:{
          request_name:'',
          plan_id:null,
          request_date:null,
          real_pricing: this.projectData?.freight_rate_est * this.projectData?.total_quantity,
          balance:null,
          invoice_price:null,
          use_tax:false,
          name_tax:'',
          tax_percentage:null,
          tax_total:null,
          invoice_total:null,

          deduction:[{
            name:'',
            amount:null,
            remarks:'',
            file:''
          }]
        },
        paymentData: null
      }
    },
    computed:{
      totalAmount() {
        return this.form?.deduction?.reduce((total, item) => total + Number(item?.amount), 0);
      },
    },
    watch:{
      id: {
        deep: true,
        handler: function (value) {
          if (value) {
            this.getDetailPayment(value)
          }
        },
      },
      action: {
        deep: true,
        handler: function (value) {
          if (value === "ADD") {
            this.form = {
              ...this.form,
              request_name:'',
              plan_id:null,
              request_date:null,
              invoice_price:null,
              use_tax:false,
              name_tax:'',
              tax_percentage:null,
              tax_total:null,
              invoice_total:null,
              real_pricing:this.projectData?.freight_rate_est * this.projectData?.total_quantity,
              balance: this.totalUnpaid,

              deduction:[]
            }
          }
        },
      },
    },
    mounted(){
      console.log(this.form)
    },
    methods:{
      ...busdevActions,
      handleOpenFilePreview: function (url) {
        window.open(`${this.apiUrl}/${url}`, "_blank");
      },
      countInvoiceTotal(){
        if(this.form.invoice_price && this.form.tax_total){
          this.form.invoice_total = Number(this.form.invoice_price) + Number(this.form.tax_total)
        }
      },
      countInvoicePrice(){
        this.form.invoice_price = Number(this.form.real_pricing) - Number(this.form.balance) - Number(this.totalAmount)
      },
      countTaxTotal(){
        if(this.form.invoice_price && this.form.tax_percentage){
          this.form.tax_total = Number(this.form.invoice_price) * Number(this.form.tax_percentage)
        }
      },
      onChangeBalance(){
        if(this.form.balance && this.form.real_pricing){
          // this.form.invoice_price = Number(this.form.balance) * Number(this.form.real_pricing) / 100
          this.form.invoice_price = Number(this.form.balance) - Number(this.form.real_pricing) - this.totalAmount
        }
      },
      onChangeInvoicePrice(){
        if(this.form.invoice_price && this.form.real_pricing){
          this.form.balance = Number(this.form.invoice_price) / Number(this.form.real_pricing) * 100
        }
      },

      formatCurrency(value) {
        return new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        }).format(value)
      },
      addDeduction(){
        this.form.deduction.push({
          name:'',
          amount:null,
          remarks:'',
          file:''
        })
      },
      resetForm(){
        this.form = {
          request_name:'',
          plan_id:null,
          request_date:null,
          real_pricing:null,
          balance:null,
          invoice_price:null,
          use_tax:false,
          name_tax:'',
          tax_percentage:null,
          tax_total:null,
          invoice_total:null,

          deduction:[]
        }
      },

      async getDetailPayment(id){
        const res = await this.fetchPaymentListById({id})

        if(res?.data?.status === true){
          let data = res.data.data
          this.paymentData = data
          this.form = {
            request_name: data.request_name,
            plan_id: data.plan_id,
            request_date: data.request_date ? moment(data.request_date).format('YYYY-MM-DD') : null,
            real_pricing: data.real_pricing,
            balance: data.balance,
            invoice_price: data.invoice_price,
            use_tax: data.use_tax,
            name_tax: data.name_tax,
            tax_percentage: data.tax_percentage,
            tax_total: data.tax_total,
            invoice_total: data.invoice_total,

            deduction:data?.deduction?.length > 0 ? data?.deduction : []
          }
        }
      },

      async onSavePaymentList(){
        let payload ={
          ...this.form,
          request_date: moment(this.form.request_date).format('YYYY-MM-DD'),
          project_contract_id: this.projectData.id
        }

        let res = null

        if(['EDIT'].includes(this.action) && this.id){
          res = await this.updatePaymentList({data:payload, id: Number(this.id)})
        } else {
          res = await this.createPaymentList({data:payload})
        }

        if(payload?.deduction?.length > 0){
            const formData = new FormData()
  
            if(this.form.deduction && this.form.deduction?.length > 0){
              this.form.deduction?.forEach((item, index) => {
                for (const property in item) {
                  formData.append(`deduction[${index}][${property}]`, item[property])
                }
              });
            }

            await this.createOrUpdatePaymentListDeduction({id: res.data.data.id, data: formData})
          }
          
        if(res.data.status === true){
          this.refreshData()
          this.resetForm()
          this.closeSlideFormPayment()
          this.$swal(`Success!`, `Create Payment List Success.`, 'success')
        }
      }
    }
  }
</script>
