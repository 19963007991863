<template>
  <b-container fluid>
    <b-row v-if="!project_data_loading">
      <b-col md="12">
        <b-button variant="primary" class="float-right mr-1" size="sm" type="button"  @click.prevent="onSubmit"><i class="fa fa-save"></i> Submit&nbsp; </b-button>
        <b-button variant="danger" class="float-right mr-1" size="sm" type="button" @click="closeSlideFormRealShipment"><i class="fa fa-ban"></i> Cancel&nbsp; </b-button>
      </b-col>
      <b-col md="12" class="p-4">
        <b-row>
          <b-col cols="12">
            <fieldset class="form-group border p-3">
              <legend class="w-auto px-2 text-primary">Project</legend>
              <b-row>
                <b-col lg="6">
                  <b-form-group label-for="dateIssued" label="Projects">
                    <span>{{ projectData?.project_sub?.project?.project_name }} - {{ projectData?.project_sub?.name }}</span>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label="Customer"
                    label-for="customer_id"
                  >
                    <span>{{ projectData?.customer?.customer_code }} - {{ projectData?.customer?.account_name }}</span>
                  </b-form-group>
                </b-col>
              </b-row>
            </fieldset>
          </b-col>

          <!-- DETAIL PROJECT CONTRACT -->
          <b-col lg="12">
            <b-row>
              <b-col :lg="'12'">
                <fieldset class="form-group border p-3">
                  <legend class="w-auto px-2 text-primary">Detail Project Contract</legend>
                  <table class="table table-striped">
                    <tbody>
                      <tr>
                        <th width="15%">Company</th>
                        <td width="5%">:</td>
                        <td width="30%">-</td> 
                        <th width="15%"></th>
                        <td width="5%"></td>
                        <td width="30%"></td> 
                      </tr>
                      <tr>
                        <th width="15%">Fleets</th>
                        <td width="5%">:</td>
                        <td width="30%" colSpan="4">
                          <div class="d-flex flex-column">
                            <b-row class="mb-2">
                              <b-col lg="3" class="mb-2">
                                <span>Fleet ownership</span> <br />
                                <b-badge class="border border-dark text-dark text-uppercase mb-2" variant="none">{{ projectData?.fleet_ownership === 'own-ship' ? "OWN SHIP" : 'CHARTERED' }}</b-badge>
                              </b-col>
                              <b-col lg="3" v-if="projectData?.fleet_ownership">
                                <span>Fleet Type</span> <br />
                                <b-badge class="border border-dark text-dark text-uppercase mb-2" variant="none">Tug & Barge</b-badge>
                              </b-col>
                            </b-row>
                            <b-row v-if="projectData?.fleet_type_id">
                              <b-col lg='12'>Fleets</b-col>
                              <b-col md="12" >
                                <div class="d-flex flex-warp">
                                  <b-badge v-for="(objDetail, detailIndex) in projectData?.fleets" :key="detailIndex" class="border border-primary text-primary text-uppercase mb-2 mr-2" variant="none">TB. TAVIA 00{{ detailIndex + 1 }}</b-badge>
                                </div>
                              </b-col>
                            </b-row>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th width="15%">Collection of Forms</th>
                        <td width="5%">:</td>
                        <td width="30%">
                          <div class="d-flex flex-wrap gap-2">
                            <b-badge 
                              v-for="(val, index) in projectData?.project_forms"
                              :key="index"
                              class="border border-primary text-primary text-uppercase m-1" 
                              variant="none"
                            >
                              {{ val?.collection_forms?.name }}
                            </b-badge>
                          </div>
                        </td>
                        <th width="15%"></th>
                        <td width="5%"></td>
                        <td width="30%"></td>
                      </tr>
                      <tr>
                        <th width="15%">Periode Start</th>
                        <td width="5%">:</td>
                        <td width="30%">
                          <span>{{ projectData?.periode_start ? moment(projectData?.periode_start).format('DD MMMM YYYY') : '-' }}</span>
                        </td>
                        <th width="15%">Periode End</th>
                        <td width="5%">:</td>
                        <td width="30%">
                          <span>{{ projectData?.periode_start ? moment(projectData?.periode_start).format('DD MMMM YYYY') : '-' }}</span>
                        </td>
                      </tr>
                      <tr>
                        <th width="15%">Contract Type</th>
                        <td width="5%">:</td>
                        <td width="30%">
                          <span>{{ projectData?.contract_type?.name }}</span>
                        </td>
                        <th width="15%">Cargo Type</th>
                        <td width="5%">:</td>
                        <td width="30%">
                          <span>{{ projectData?.cargo_type?.name ?? '-' }}</span>
                        </td>
                      </tr>
                      <tr>
                        <th width="15%">Term</th>
                        <td width="5%">:</td>
                        <td width="30%">
                          <span>{{ projectData?.terms?.name ?? '-' }} <br /> Remarks: {{ projectData?.terms_remarks }}</span>
                        </td> 
                        <th width="15%">Total Quantity</th>
                        <td width="5%">:</td>
                        <td width="30%">
                          <div class="d-flex align-items-center" v-if="projectData?.cargo_type_id">
                            <span class="mr-2">{{ projectData?.total_quantity?.toLocaleString() }}</span>
                            <strong >{{ projectData?.unit }}</strong>
                          </div>
                          <div v-else>
                            <i>Choose cargo type first.</i>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th width="15%">POL</th>
                        <td width="5%">:</td>
                        <td width="30%">
                            <div class="d-flex flex-column mb-2 w-100">
                              <span v-for="(obj, polIndex) in projectData?.pol" :key="polIndex">{{polIndex + 1}}. {{ obj?.value ?? '-' }}</span>
                            </div>
                          </td>
                        <th width="15%">POD</th>
                        <td width="5%">:</td>
                        <td width="30%">
                          <div class="d-flex flex-column mb-2 w-100">
                            <span v-for="(obj, podIndex) in projectData?.pod" :key="podIndex">{{podIndex + 1}}. {{ obj?.value ?? '-' }}</span>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th width="15%">Laycan</th>
                        <td width="5%">:</td>
                        <td width="30%">
                          <span>{{ projectData?.laycan ? moment(projectData?.laycan).format('DD MMMM YYYY') : '-' }}</span>
                        </td>
                        <th width="15%">Distance</th>
                        <td width="5%">:</td>
                        <td width="30%">
                          <span>{{ projectData?.distance }}</span>
                        </td>
                      </tr>
                      <tr>
                        <th width="15%">Shipment Plan Number</th>
                        <td width="5%">:</td>
                        <td width="30%">
                          <strong class="text-muted">{{ projectData?.shipment_plan_number }}</strong>
                        </td> 
                        <th width="15%">Freigh Rate (Est)</th>
                        <td width="5%">:</td>
                        <td width="30%">
                          <div class="d-flex flex-column">
                            <span>{{ formatCurrency(projectData?.freight_rate_est) }}</span>
                            <strong class="text-muted">{{ projectData?.ppn == 'include' ? 'Include ' : 'Exclude ' }} PPN</strong>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th width="15%">Remarks</th>
                        <td width="5%">:</td>
                        <td width="30%" colSpan="4">
                          <span>{{ projectData?.remarks }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </fieldset>
              </b-col>
            </b-row>
          </b-col>

          <b-col lg="12">
            <fieldset class="form-group border p-3">
              <legend class="w-auto px-2 text-primary">Project Contract Plan</legend>
              <div class="overflow-auto">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th class="table-info text-center">#</th>
                      <th class="table-info text-truncate">No</th>
                      <th class="table-info text-truncate">S.Plan Number</th>
                      <th class="table-info text-truncate">Description</th>
                      <th class="table-info text-truncate">Fleet</th>
                      <th class="table-info text-truncate">Cargo Type</th>
                      <th class="table-info text-truncate">Location</th>
                      <th class="table-info text-truncate">Plan Date</th>
                      <th class="table-info text-truncate">Plan Quantity</th>
                      <th class="table-info text-truncate">Remarks</th>
                      <th class="table-info text-truncate">Status</th>
                    </tr>
                  </thead>
                  <tbody v-if="projectData?.project_contract_plan?.length > 0">
                    <tr v-for="(val, index) in projectData?.project_contract_plan" :key="index">
                      <td class="text-center">
                        <b-form-checkbox
                          name="checked"
                          v-model="val.checked"
                          @change="onCheckedPlan"
                          :disabled="val.disabled"
                        >
                        </b-form-checkbox>
                      </td>
                      <td>{{ index + 1 }}</td>
                      <td class="text-truncate">{{val?.shipment_plan_detail_number}}</td>
                      <td>{{val?.description}}</td>
                      <td class="text-truncate ">
                        <div class="d-flex w-100">
                          <b-badge class="border border-primary text-primary text-uppercase mr-1" variant="none" v-for="(obj, objIndex) in val?.fleets" :key="objIndex">{{ showVehicleName(obj.vehicle_id)  }}</b-badge>
                        </div>
                      </td>
                      <td class="text-truncate">{{val?.cargo_type?.name}}</td>
                      <td class="text-truncate">-</td>
                      <td class="text-truncate">-</td>
                      <td class="text-truncate">{{val?.plan_quantity?.toLocaleString()}}</td>
                      <td class="text-truncate">{{val?.remarks}}</td>
                      <td class="text-truncate text-capitalize">{{val?.status}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </fieldset>
          </b-col>

          <!-- OPERATIONAL DATA -->
          <b-col md="12">
            <fieldset class="form-group border p-3">
              <legend class="w-auto px-2 text-primary">Select Operational Data</legend>
              <b-row>
                <b-col md="5">
                  <b-form-group label-for="date" label="Date">
                    <date-picker v-model="dateRange" type="date" range placeholder="Select date range" :style="{width: '100%'}"></date-picker>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label-for="dateIssued" label="Select Fleet">
                    <v-select
                      label="text"
                      name="vehicle_id"
                      v-model="params.vehicle_id"
                      :options="optionsFleets"
                      :reduce="(optionsFleets) => optionsFleets.value"
                      placeholder="Select Fleet"
                    ></v-select>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label-for="date" label="Find" >
                    <b-button type="button" variant="primary" class="px-4" @click="fetchVoyageByTrips"> FIND DATA
                    </b-button>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <div class="d-flex overflow-auto">
                    <table class="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th class="text-center" style="width:5px; background-color:#BDE5EB">#</th>
                          <th class="text-center text-truncate" style="background-color:#BDE5EB">FLEET</th>
                          <th class="text-center text-truncate" style="background-color:#BDE5EB">STATUS</th>
                          <th class="text-center text-truncate" style="background-color:#BDE5EB">CARGO TYPE</th>
                          <th class="text-center text-truncate" style="background-color:#BDE5EB">CARGO UNLOADING</th>
                          <th class="text-center text-truncate" style="background-color:#BDE5EB">CARGO LOADING</th>
                          <th class="table-primary text-truncate">Arival Time POL</th>
                          <th class="table-primary text-truncate">BL Date</th>
                          <th class="table-primary text-truncate">NOR</th>
                          <th class="table-primary text-truncate">NOA</th>
                          <th class="table-primary text-truncate">Anchor Up</th>
                          <th class="table-primary text-truncate">In Position / Berthing</th>
                          <th class="table-primary text-truncate">Commenced</th>
                          <th class="table-primary text-truncate">Completed</th>
                          <th class="table-primary text-truncate">Draught Survey</th>
                          <th class="table-primary text-truncate">Cast In</th>
                          <th class="table-primary text-truncate">Cast Out</th>
                          <th class="table-primary text-truncate">LHV</th>
                          <th class="table-primary text-truncate">Document on Board</th>
                          <th class="table-primary text-truncate">Departure to POD</th>
                          <th class="table-primary text-truncate">Remarks</th>
      
      
                          <th class="table-info text-truncate">Arival Time POD</th>
                          <th class="table-info text-truncate">BL Date</th>
                          <th class="table-info text-truncate">NOR</th>
                          <th class="table-info text-truncate">NOA</th>
                          <th class="table-info text-truncate">Anchor Up</th>
                          <th class="table-info text-truncate">In Position / Berthing</th>
                          <th class="table-info text-truncate">Commenced</th>
                          <th class="table-info text-truncate">Completed</th>
                          <th class="table-info text-truncate">Draught Survey</th>
                          <th class="table-info text-truncate">Cast In</th>
                          <th class="table-info text-truncate">Cast Out</th>
                          <th class="table-info text-truncate">LHV</th>
                          <th class="table-info text-truncate">Document on Board</th>
                          <th class="table-info text-truncate">Departure to POL</th>
                          <th class="table-info text-truncate">Remarks</th>
      
                          <!-- <th class="text-center text-truncate" style="background-color:#BDE5EB">TA POL / READY</th>
                          <th class="text-center text-truncate" style="background-color:#BDE5EB">BERTHING</th>
                          <th class="text-center text-truncate" style="background-color:#BDE5EB">COMMENCED</th>
                          <th class="text-center text-truncate" style="background-color:#BDE5EB">COMPLETED</th>
                          <th class="text-center text-truncate" style="background-color:#BDE5EB">POD</th>
                          <th class="text-center text-truncate" style="background-color:#BDE5EB">TA POD / READY</th> -->
                          <!-- <th class="text-center bg-warning border-dark " style="vertical-align:middle; width:5px; position:sticky; right:0; z-index:1">ACTION</th> -->
                        </tr>
                      </thead>
                      <tbody v-if="listTrips?.length > 0">
                        <tr v-for="(val, index) in listTrips" :key="index">
                          <td class="text-center">
                            <b-form-checkbox
                              name="checked"
                              v-model="val.checked"
                            >
                            </b-form-checkbox>
                          </td>
                          <td class="text-truncate">
                            <div class="d-flex w-100">
                              <b-badge class="border border-primary text-primary text-uppercase mr-1" variant="none" v-for="(obj, objIndex) in val?.vehicle" :key="objIndex">{{ obj?.name }}</b-badge>
                            </div>
                          </td>
                          <td class="text-truncate">{{val?.tripRecord?.status}}</td>
                          <td class="text-truncate">{{val?.tripRecord?.cargoType}}</td>
                          <td class="text-truncate">{{val?.tripRecord?.cargoUnloading?.toLocaleString()}}</td>
                            <td class="text-truncate">
                              {{ val?.tripRecord?.polArrivalTime ? moment(val?.tripRecord?.polArrivalTime, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                            </td>
                            <td class="text-truncate text-center">-</td>
                            <td class="text-truncate text-center">-</td>
                            <td class="text-truncate text-center">-</td>
                            <td class="text-truncate">
                              {{ val?.tripRecord?.polAnchorUp ? moment(val?.tripRecord?.polAnchorUp, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                            </td>
                            <td class="text-truncate">
                              {{ val?.tripRecord?.polBerthing ? moment(val?.tripRecord?.polBerthing, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                            </td>
                            <td class="text-truncate">
                              {{ val?.tripRecord?.polCommenced ? moment(val?.tripRecord?.polCommenced, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                            </td>
                            <td class="text-truncate">
                              {{ val?.tripRecord?.polCompleted ? moment(val?.tripRecord?.polCompleted, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                            </td>
                            <td class="text-truncate text-center">{{val?.tripRecord?.polDraughtSurvey ?? 0}}</td>
                            <td class="text-truncate text-center">-</td>
                            <td class="text-truncate">
                              {{ val?.tripRecord?.polCastOut ? moment(val?.tripRecord?.polCastOut, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                            </td> 
                            <td class="text-truncate">
                              {{ val?.tripRecord?.polLhv ? moment(val?.tripRecord?.polLhv, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                            </td>
                            <td class="text-truncate">
                              {{ val?.tripRecord?.polDocOnBoard ? moment(val?.tripRecord?.polDocOnBoard, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                            </td>
                            <td class="text-truncate">
                              {{ val?.tripRecord?.polDeparture ? moment(val?.tripRecord?.polDeparture, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                            </td>
                            <td class="text-truncate text-center">-</td>
                            <td class="text-truncate">
                              {{ val?.tripRecord?.podArrivalTime ? moment(val?.tripRecord?.podArrivalTime, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                            </td>
                            <td class="text-truncate text-center">-</td>
                            <td class="text-truncate text-center">-</td>
                            <td class="text-truncate text-center">-</td>
                            <td class="text-truncate">
                              {{ val?.tripRecord?.podAnchorUp ? moment(val?.tripRecord?.podAnchorUp, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                            </td>
                            <td class="text-truncate">
                              {{ val?.tripRecord?.podBerthing ? moment(val?.tripRecord?.podBerthing, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                            </td>
                            <td class="text-truncate">
                              {{ val?.tripRecord?.podCommenced ? moment(val?.tripRecord?.podCommenced, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                            </td>
                            <td class="text-truncate">
                              {{ val?.tripRecord?.podCompleted ? moment(val?.tripRecord?.podCompleted, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                            </td>
                            <td class="text-truncate text-center">{{val?.tripRecord?.podDraughtSurvey ?? 0}}</td>
                            <td class="text-truncate text-center">-</td>
                            <td class="text-truncate">
                              {{ val?.tripRecord?.podCastOut ? moment(val?.tripRecord?.podCastOut, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                            </td> 
                            <td class="text-truncate">
                              {{ val?.tripRecord?.podLhv ? moment(val?.tripRecord?.podLhv, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                            </td>
                            <td class="text-truncate">
                              {{ val?.tripRecord?.podDocOnBoard ? moment(val?.tripRecord?.podDocOnBoard, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                            </td>
                            <td class="text-truncate">
                              {{ val?.tripRecord?.podDeparture ? moment(val?.tripRecord?.podDeparture, 'DD-MM-YYYY HH:mm').format('DD MMM YYYY HH:mm') : '-' }}
                            </td>
                          <!-- <td class="text-center bg-warning border-dark " style="width:5px; position:sticky; right:0; z-index:1">
                            <b-dropdown 
                              size="lg"
                              variant="link iq-bg-primary iq-border-radius-10"
                              toggle-class="text-decoration-none"
                              no-caret
                            >
                              <template v-slot:button-content>
                                <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                  <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                </span>
                              </template>
                              <b-dropdown-item href="#" variant="primary" >
                                <i class="fa fa-eye mr-2"></i>Detail
                              </b-dropdown-item>
                            </b-dropdown>
                          </td> -->
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="d-flex align-items-center justify-content-center w-100 my-2 mb-4" v-if="listTrips?.length <= 0">
                    <strong class="text-muted">No data found. Select other plan.</strong>
                  </div>
                </b-col>
              </b-row>
            </fieldset>
          </b-col>
        </b-row>
      </b-col>

      <b-col md="12" class="mb-4 mt-0">
        <div class="d-flex align-items-center justify-content-center">
          <b-button variant="danger" class="float-right mr-1" size="sm" type="button" @click="closeSlideFormRealShipment"><i class="fa fa-ban"></i> Cancel&nbsp; </b-button>
          <b-button variant="primary" class="float-right mr-1" size="sm" type="submit"  @click.prevent="onSubmit"><i class="fa fa-save"></i> Submit&nbsp; </b-button>
        </div>
      </b-col>
    </b-row>

    <div v-else class="alert alert-info m-4">
      Loading...
    </div>
  </b-container>
</template>

<script>
  import _ from 'lodash'
  import moment from 'moment'
  import { busdevActions, voyagesActions } from '@src/Utils/helper'

  export default {
    name:'SlideFormRealShipment',
    props:{
      action:{
        type:String,
        default:''
      },  
      projectId:{
        type:Number,
        default:null
      }, 
      refreshData:{
        type:Function,
        default:() => {}
      }, 
      optionsFleets:{
        type:Array,
        default:() => []
      }, 
      closeSlideFormRealShipment:{
        type:Function,
        default:() => {}
      },  
    },
    components:{
    },
    data(){
      return{
        project_data_loading:false,

        projectData:null,

        listTrips:[],

        dateRange: [new Date(moment().startOf('year')), new Date(moment().endOf('year'))],
        params: {
          vehicle_id: null
        }
      }
    },
    watch:{
      action: {
        deep: true,
        handler: function (value) {
          if (value === "ADD") {
            this.resetForm()
          }
        },
      },
      projectId: {
        deep: true,
        immediate:true,
        handler: function (value) {
          if (value) {
            this.getProjectData(value)
          }
        },
      },
    },
    async mounted(){
    },
    // computed:{
    //   totalQuantityAdd() {
    //     return this.projectData?.detail_plan?.reduce((total, item) => total + Number(item?.plan_quantity), 0);
    //   },
    // },
    methods:{
      ...voyagesActions,
      ...busdevActions,
      showVehicleName(value){
        let vehicle = this.optionsFleets.find(obj => obj.value == value)

        return vehicle?.text ?? '-'
      },
      
      formatCurrency(value) {
        return new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        }).format(value)
      },

      resetForm(){
      },

      async onCheckedPlan(){
        let checkedPlan = this.projectData?.project_contract_plan?.find(obj => obj?.checked)

        if(checkedPlan){
          this.params.vehicle_id = checkedPlan?.fleets?.[0]?.vehicle_id
          await this.fetchVoyageByTrips()
        }
      },

      // FETCHING DATA EDIT & DETAIL PLAN
      async getProjectData(id){
        this.project_data_loading = true

        let res = await this.fetchDetailProjectContractWithoutPFA({id})

        if (res.data.status === true) {
          this.projectData = res.data.data

          if(this.projectData.project_contract_plan?.length > 0){
            this.projectData.project_contract_plan = this.projectData.project_contract_plan?.map(obj => {
              return {
                ...obj,
                checked: obj.real_shipment?.id ? true : false,
                disabled: obj.real_shipment?.id ? true : false
              }
            }) 
          }

          this.onCheckedPlan()
        } else {
          this.projectData = null
        }
        this.project_data_loading = false
      },

      async fetchVoyageByTrips() {
        let params = {
          page: 1,
          perPage: 500000000,
          vehicleId: Number(this.params.vehicle_id),
          ...this.params,
          startDate: this.dateRange?.[0] ? moment(this.dateRange?.[0]).format('DD-MM-YYYY') : null,
          endDate: this.dateRange?.[1] ? moment(this.dateRange?.[1]).format('DD-MM-YYYY') : null, 
        }

        // Iterate over each property in params
        for (const prop in params) {
          if (params.hasOwnProperty(prop)) {
            // Check conditions for each property and delete if necessary
            if ((params[prop] === '') || (params[prop] === null)) {
              delete params[prop];
            }
          }
        }

        const res = await this.getVoyageTrips(params)

        if (res.status == 'success') {
          this.listTrips = res.data?.map(val => {
            let checkedPlan = this.projectData?.project_contract_plan?.find(obj => obj?.checked)

            return {
              ...val,
              checked: checkedPlan?.real_shipment?.trip_id === val?.tripRecord?.id ? true : false,
              disabled: checkedPlan?.real_shipment?.trip_id === val?.tripRecord?.id ? true : false
            }
          })
        } else {
          this.listTrips = []
        }
      }, 

      async onSubmit(){
        let checkedPlan = this.projectData?.project_contract_plan?.find(obj => obj?.checked && obj.disabled)
        let checkedTrip = this.listTrips?.find(obj => obj?.checked && obj.disabled)

        if(!checkedPlan || !checkedTrip) return this.$swal(
          `Plan & Trip Required!`,
          'Choose Plan & Trip first.',
          'warning'
        )

        let payload = {
          project_contract_id: this.projectData?.id,
          project_plan_id: checkedPlan?.id,
          trip_id: checkedTrip?.tripRecord?.id,
          vehicle_id: this.params?.vehicle_id,
        }

        const res = await this.saveRealShipment({data: payload})

        if (res.status == 'success') {
          this.refreshData()
          this.$swal(
              'Success!',
              'Real Shipment Saved.',
              'success'
            )
          this.closeSlideFormRealShipment()
        } else {
          this.$swal(
            `Oops!`,
            res?.data?.message ?? 'Something went wrong.',
            'error'
          )
        }
      }
    }
  }
</script>
