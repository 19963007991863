var render = function render(){
  var _vm$form, _vm$form$attachment, _vm$form2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSendMail.apply(null, arguments);
      }
    }
  }, [_c('b-row', {
    staticClass: "m-3"
  }, [!_vm.loading ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "lg": "12"
    }
  }, [_c('div', {
    class: "d-flex align-items-center justify-content-end w-100"
  }, [_c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "type": "button",
      "variant": "danger"
    },
    on: {
      "click": _vm.closeSlideSendMail
    }
  }, [_c('div', [_c('i', {
    staticClass: "fa fa-ban"
  }), _c('span', [_vm._v("Cancel")])])]), _c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit"
    }
  }, [_vm.saving ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _c('div', [_c('i', {
    staticClass: "fa fa-save"
  }), _c('span', [_vm._v("Send Mail")])])])], 1), _c('hr')]), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3 bg-default"
  }, [_c('legend', {
    staticClass: "w-auto px-2 h5 text-primary"
  }, [_vm._v("Head Mail")]), _c('b-form-group', {
    attrs: {
      "label": "To",
      "label-for": "to"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "id": "to",
      "placeholder": "Type Text"
    },
    model: {
      value: _vm.form.to,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "to", $$v);
      },
      expression: "form.to"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "CC",
      "label-for": "cc"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "id": "cc",
      "placeholder": "Type Text"
    },
    model: {
      value: _vm.form.cc,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "cc", $$v);
      },
      expression: "form.cc"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Title",
      "label-for": "title"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "id": "title",
      "placeholder": "Type Text"
    },
    model: {
      value: _vm.form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Priority",
      "label-for": "priority"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.form.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "priority", $$v);
      },
      expression: "form.priority"
    }
  }, [_vm._v(" Yes / No ")])], 1), _c('b-form-group', {
    attrs: {
      "label": "Attachment"
    }
  }, [_c('v-multiselect', {
    attrs: {
      "multiple": true,
      "options": _vm.optionsFile,
      "placeholder": "Select file...",
      "track-by": "file_name",
      "label": "file_name"
    },
    model: {
      value: _vm.form.attachment,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "attachment", $$v);
      },
      expression: "form.attachment"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v("No data found.")])])], 1), _c('b-form-group', {
    attrs: {
      "label": "File"
    }
  }, [_c('b-form-file', {
    attrs: {
      "plain": "",
      "multiple": "",
      "name": "files",
      "id": "attactment_file",
      "accept": "image/*,application/*"
    },
    model: {
      value: _vm.files,
      callback: function callback($$v) {
        _vm.files = $$v;
      },
      expression: "files"
    }
  })], 1), ((_vm$form = _vm.form) === null || _vm$form === void 0 ? void 0 : (_vm$form$attachment = _vm$form.attachment) === null || _vm$form$attachment === void 0 ? void 0 : _vm$form$attachment.length) > 0 ? _c('div', {
    staticClass: "d-flex flex-column"
  }, _vm._l((_vm$form2 = _vm.form) === null || _vm$form2 === void 0 ? void 0 : _vm$form2.attachment, function (file, fileIndex) {
    return _c('b-button', {
      key: fileIndex,
      staticClass: "m-0 p-0 mb-2 text-primary text-left",
      attrs: {
        "variant": "link",
        "type": "button"
      }
    }, [_c('i', {
      staticClass: "fa fa-file text-primary"
    }), _vm._v(" " + _vm._s(file === null || file === void 0 ? void 0 : file.file_name) + " ")]);
  }), 1) : _vm._e()], 1)]), _c('b-col', {
    attrs: {
      "md": "8"
    }
  }, [_c('fieldset', {
    staticClass: "form-group border p-3 bg-default"
  }, [_c('legend', {
    staticClass: "w-auto px-2 h5 text-primary"
  }, [_vm._v("Body Mail")]), _c('div', [_c('wysiwyg', {
    style: {
      height: '500px'
    },
    model: {
      value: _vm.form.content,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1)])])], 1)], 1) : _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "alert alert-info m-4"
  }, [_vm._v(" Loading... ")])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }