var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": !_vm.menuPermission('add') && !_vm.menuPermission('update') ? '12' : '8'
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("List Master Port")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-content-between mb-3"
        }, [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-form-input', {
          staticStyle: {
            "width": "240px"
          },
          attrs: {
            "id": "fleet_name",
            "type": "text",
            "placeholder": "Search Ports..."
          },
          model: {
            value: _vm.ports.params.name,
            callback: function callback($$v) {
              _vm.$set(_vm.ports.params, "name", $$v);
            },
            expression: "ports.params.name"
          }
        }), _c('b-button', {
          staticClass: "ml-2",
          staticStyle: {
            "max-width": "max-content"
          },
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.fetchPorts(1);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1), _vm.menuPermission('export') ? _c('div', [_c('b-button', {
          attrs: {
            "block": "",
            "variant": "primary"
          },
          on: {
            "click": _vm.doExport
          }
        }, [!_vm.loading_export ? [_c('i', {
          staticClass: "fa fa-file-excel"
        }), _vm._v("   Export Excel ")] : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _vm._v(" Exporting... ")]], 2)], 1) : _vm._e()]), _vm.ports._loading ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.ports.data && _vm.ports.data.length > 0 ? _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center",
          attrs: {
            "width": "5%"
          }
        }, [_vm._v("No")]), _c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Port")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Type")]), _c('th', {
          attrs: {
            "width": "35%"
          }
        }, [_vm._v("PIC")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.ports.data, function (port, i) {
          return _c('tr', {
            key: "port-".concat(i)
          }, [_c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(_vm.ports.meta.currentPage > 1 ? _vm.ports.meta.perPage + i + 1 : i + 1))]), _c('td', [_vm._v(_vm._s(port.portName))]), _c('td', [_vm._v(_vm._s(_vm._f("replace")(port.portType, '_', ' ')))]), _c('td', [_c('ul', {
            staticClass: "m-0 pl-3"
          }, _vm._l(port.user, function (user, is) {
            return _c('li', {
              key: "user-".concat(is),
              staticStyle: {
                "list-style-type": "circle"
              }
            }, [_vm._v(_vm._s(user.name))]);
          }), 0)]), _c('td', [_vm.menuPermission('update') ? _c('b-button', {
            staticClass: "mr-2",
            attrs: {
              "variant": "success",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onEdit(port);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]) : _vm._e(), _vm.menuPermission('delete') ? _c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeletePort(port);
              }
            }
          }, [!_vm.loading_delete ? _c('i', {
            staticClass: "fa fa-trash"
          }) : [_c('span', {
            staticClass: "spinner-border spinner-border-sm",
            attrs: {
              "role": "status",
              "aria-hidden": "true"
            }
          }), _vm._v(" Deleting... ")]], 2) : _vm._e()], 1)]);
        }), 0)])]) : _vm._e(), _vm.ports.data && _vm.ports.data.length > 0 ? _c('div', [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.ports.meta.total,
            "per-page": _vm.ports.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.fetchPorts
          },
          model: {
            value: _vm.ports.meta.currentPage,
            callback: function callback($$v) {
              _vm.$set(_vm.ports.meta, "currentPage", $$v);
            },
            expression: "ports.meta.currentPage"
          }
        })], 1) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("No ports data found.")])]];
      },
      proxy: true
    }])
  })], 1), _vm.menuPermission('update') || _vm.menuPermission('add') ? _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('iq-card', {
    class: {
      'border border-danger shadow-lg': _vm.edit_mode
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v(_vm._s(_vm.edit_mode ? 'Edit' : 'Add') + " Port")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', [_c('b-form-group', {
          attrs: {
            "label": "Port",
            "label-for": "port_name"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "port_name",
            "type": "text",
            "placeholder": "Port"
          },
          model: {
            value: _vm.form.portName,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "portName", $$v);
            },
            expression: "form.portName"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Type Port",
            "label-for": "type_port"
          }
        }, [_c('v-select', {
          attrs: {
            "getOptionLabel": function getOptionLabel(type) {
              return type.text;
            },
            "options": _vm.port_types,
            "reduce": function reduce(type) {
              return type.value;
            },
            "placeholder": "Select Type"
          },
          model: {
            value: _vm.form.portType,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "portType", $$v);
            },
            expression: "form.portType"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "PIC",
            "label-for": "user"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.users,
            "reduce": function reduce(users) {
              return users.value;
            },
            "placeholder": "Select PIC",
            "multiple": true
          },
          model: {
            value: _vm.form.user,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "user", $$v);
            },
            expression: "form.user"
          }
        })], 1), _c('b-form-group', {
          staticClass: "text-right"
        }, [_c('b-button', {
          attrs: {
            "type": "button",
            "variant": "primary"
          },
          on: {
            "click": _vm.onSubmit
          }
        }, [!_vm.loading_form ? [_vm._v(" " + _vm._s(_vm.edit_mode || !_vm.menuPermission('add') ? 'Save' : 'Add') + " ")] : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _vm._v(" Saving... ")]], 2), _c('b-button', {
          staticClass: "iq-bg-danger ml-2",
          attrs: {
            "type": "button",
            "variant": "danger"
          },
          on: {
            "click": _vm.onCancel
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }], null, false, 27893968)
  })], 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }