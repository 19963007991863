var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "iq-edit-profile d-flex",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-6 p-0",
          attrs: {
            "active": true,
            "href": "#privilege",
            "title": _vm.findMenuPermissionLv2ByIndex('menu', _vm.$route.query.menuId, 0)
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-6 p-0",
          attrs: {
            "active": false,
            "href": "#structure",
            "title": _vm.findMenuPermissionLv2ByIndex('menu', _vm.$route.query.menuId, 1)
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "pills-tabContent-2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "privilege"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("Role & Previlege")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-row', {
          staticClass: "mb-3"
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "4"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "roles",
            "options": _vm.roles
          },
          on: {
            "input": _vm.mergeMenus
          },
          model: {
            value: _vm.selectedRole,
            callback: function callback($$v) {
              _vm.selectedRole = $$v;
            },
            expression: "selectedRole"
          }
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "2"
          }
        }, [_vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 0) ? _c('b-button', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.edit-role",
            modifiers: {
              "edit-role": true
            }
          }, {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "primary",
            "title": "Edit Role Name"
          }
        }, [!_vm.loading_update ? [_c('i', {
          staticClass: "fa fa-edit"
        })] : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _c('span', {
          staticClass: "sr-only"
        }, [_vm._v("Loading...")]), _vm._v(" Updating role... ")]], 2) : _vm._e(), _vm.findMenuPermissionLv2ByIndex('delete', _vm.$route.query.menuId, 0) ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "danger",
            "title": "Delete Role"
          },
          on: {
            "click": _vm.onDeleteRole
          }
        }, [!_vm.loading_delete ? [_c('i', {
          staticClass: "fa fa-trash"
        })] : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _c('span', {
          staticClass: "sr-only"
        }, [_vm._v("Loading...")]), _vm._v(" Deleting role... ")]], 2) : _vm._e(), _c('b-modal', {
          attrs: {
            "id": "edit-role",
            "size": "sm",
            "title": "Edit Role ".concat(_vm.selectedRole.roles),
            "ok-title": "Save",
            "cancel-title": "Cancel",
            "centered": ""
          },
          on: {
            "ok": _vm.onUpdateRole
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "Name of Role",
            "type": "text"
          },
          model: {
            value: _vm.selectedRole.roles,
            callback: function callback($$v) {
              _vm.$set(_vm.selectedRole, "roles", $$v);
            },
            expression: "selectedRole.roles"
          }
        })], 1)], 1), _c('b-col', {
          staticClass: "text-right",
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_vm.findMenuPermissionLv2ByIndex('add', _vm.$route.query.menuId, 0) ? _c('b-button', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.add-role",
            modifiers: {
              "add-role": true
            }
          }],
          staticClass: "mr-2",
          attrs: {
            "variant": "light"
          }
        }, [!_vm.loading_add ? [_vm._v(" Add New Role ")] : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _c('span', {
          staticClass: "sr-only"
        }, [_vm._v("Loading...")]), _vm._v(" Adding role... ")]], 2) : _vm._e(), _vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 0) ? _c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.onSavePrivilege
          }
        }, [!_vm.loading_privilege ? [_vm._v(" Save Privileges ")] : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _c('span', {
          staticClass: "sr-only"
        }, [_vm._v("Loading...")]), _vm._v(" Saving Privileges... ")]], 2) : _vm._e(), _c('b-modal', {
          attrs: {
            "id": "add-role",
            "size": "sm",
            "title": "Add Role",
            "ok-title": "Add New Role",
            "cancel-title": "Cancel",
            "centered": ""
          },
          on: {
            "ok": _vm.onAddNewRole
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "Name of Role",
            "type": "text"
          },
          model: {
            value: _vm.newRole,
            callback: function callback($$v) {
              _vm.newRole = $$v;
            },
            expression: "newRole"
          }
        })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_vm.loading_menu ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.menus.length > 0 ? _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table mb-0 table-striped table-hover"
        }, [_c('thead', {
          staticClass: "thead-dark text-center"
        }, [_c('tr', [_c('th', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v("No.")]), _c('th', {
          attrs: {
            "width": "35%"
          }
        }, [_vm._v("Data")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Add")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Update")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Delete")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Export")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Review")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Approval")])])]), _c('tbody', [_vm._l(_vm.menus, function (item, key) {
          return [_c('tr', {
            key: "menu-".concat(key)
          }, [_c('td', {
            staticClass: "text-center"
          }, [_c('b-form-checkbox', {
            attrs: {
              "disabled": !_vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 0),
              "name": "basic-checkbox-".concat(key)
            },
            on: {
              "change": function change($event) {
                return _vm.selectMenu(key);
              }
            },
            model: {
              value: _vm.checkedMenus[key].active,
              callback: function callback($$v) {
                _vm.$set(_vm.checkedMenus[key], "active", $$v);
              },
              expression: "checkedMenus[key].active"
            }
          })], 1), _c('td', [_vm._v(_vm._s(item.menu))]), _c('td', {
            staticClass: "text-center"
          }, [item.add ? [_c('b-form-checkbox', {
            attrs: {
              "name": "check-button",
              "switch": "",
              "inline": "",
              "disabled": !_vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 0)
            },
            on: {
              "change": function change($event) {
                return _vm.handleParentActive(key);
              }
            },
            model: {
              value: _vm.checkedMenus[key].add,
              callback: function callback($$v) {
                _vm.$set(_vm.checkedMenus[key], "add", $$v);
              },
              expression: "checkedMenus[key].add"
            }
          })] : [_vm._v(" ")]], 2), _c('td', {
            staticClass: "text-center"
          }, [item.update ? [_c('b-form-checkbox', {
            key: key,
            attrs: {
              "name": "check-button",
              "switch": "",
              "inline": "",
              "disabled": !_vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 0)
            },
            on: {
              "change": function change($event) {
                return _vm.handleParentActive(key);
              }
            },
            model: {
              value: _vm.checkedMenus[key].update,
              callback: function callback($$v) {
                _vm.$set(_vm.checkedMenus[key], "update", $$v);
              },
              expression: "checkedMenus[key].update"
            }
          })] : [_vm._v(" ")]], 2), _c('td', {
            staticClass: "text-center"
          }, [item.delete ? [_c('b-form-checkbox', {
            key: key,
            attrs: {
              "name": "check-button",
              "switch": "",
              "inline": "",
              "disabled": !_vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 0)
            },
            on: {
              "change": function change($event) {
                return _vm.handleParentActive(key);
              }
            },
            model: {
              value: _vm.checkedMenus[key].delete,
              callback: function callback($$v) {
                _vm.$set(_vm.checkedMenus[key], "delete", $$v);
              },
              expression: "checkedMenus[key].delete"
            }
          })] : [_vm._v(" ")]], 2), _c('td', {
            staticClass: "text-center"
          }, [item.export ? [_c('b-form-checkbox', {
            key: key,
            attrs: {
              "name": "check-button",
              "switch": "",
              "inline": "",
              "disabled": !_vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 0)
            },
            on: {
              "change": function change($event) {
                return _vm.handleParentActive(key);
              }
            },
            model: {
              value: _vm.checkedMenus[key].export,
              callback: function callback($$v) {
                _vm.$set(_vm.checkedMenus[key], "export", $$v);
              },
              expression: "checkedMenus[key].export"
            }
          })] : [_vm._v(" ")]], 2), _c('td', {
            staticClass: "text-center"
          }, [item.review ? [_c('b-form-checkbox', {
            key: key,
            attrs: {
              "name": "check-button",
              "switch": "",
              "inline": "",
              "disabled": !_vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 0)
            },
            on: {
              "change": function change($event) {
                return _vm.handleParentActive(key);
              }
            },
            model: {
              value: _vm.checkedMenus[key].review,
              callback: function callback($$v) {
                _vm.$set(_vm.checkedMenus[key], "review", $$v);
              },
              expression: "checkedMenus[key].review"
            }
          })] : [_vm._v(" ")]], 2), _c('td', {
            staticClass: "text-center"
          }, [item.approval ? [_c('b-form-checkbox', {
            key: key,
            attrs: {
              "name": "check-button",
              "switch": "",
              "inline": "",
              "disabled": !_vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 0)
            },
            on: {
              "change": function change($event) {
                return _vm.handleParentActive(key);
              }
            },
            model: {
              value: _vm.checkedMenus[key].approval,
              callback: function callback($$v) {
                _vm.$set(_vm.checkedMenus[key], "approval", $$v);
              },
              expression: "checkedMenus[key].approval"
            }
          })] : [_vm._v(" ")]], 2)]), _vm._l(item.child, function (subMenuItem, keySub) {
            return [_c('tr', {
              key: "submenu-".concat(key).concat(keySub)
            }, [_c('td', {
              staticClass: "text-center"
            }, [_c('b-form-checkbox', {
              attrs: {
                "name": "submenu-ck-".concat(keySub),
                "disabled": !_vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 0)
              },
              on: {
                "change": function change($event) {
                  return _vm.selectMenu(key, keySub, false);
                }
              },
              model: {
                value: _vm.checkedMenus[key].child[keySub].active,
                callback: function callback($$v) {
                  _vm.$set(_vm.checkedMenus[key].child[keySub], "active", $$v);
                },
                expression: "checkedMenus[key].child[keySub].active"
              }
            })], 1), _c('td', [_c('span', {
              staticStyle: {
                "margin-right": "60px"
              }
            }), _vm._v(_vm._s(subMenuItem.menu))]), _c('td', {
              staticClass: "text-center"
            }, [subMenuItem.add ? [_c('b-form-checkbox', {
              key: keySub,
              attrs: {
                "name": "check-button",
                "switch": "",
                "inline": "",
                "disabled": !_vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 0)
              },
              on: {
                "change": function change($event) {
                  return _vm.handleParentActive(key, keySub, false);
                }
              },
              model: {
                value: _vm.checkedMenus[key].child[keySub].add,
                callback: function callback($$v) {
                  _vm.$set(_vm.checkedMenus[key].child[keySub], "add", $$v);
                },
                expression: "checkedMenus[key].child[keySub].add"
              }
            })] : [_vm._v(" ")]], 2), _c('td', {
              staticClass: "text-center"
            }, [subMenuItem.update ? [_c('b-form-checkbox', {
              key: keySub,
              attrs: {
                "name": "check-button",
                "switch": "",
                "inline": "",
                "disabled": !_vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 0)
              },
              on: {
                "change": function change($event) {
                  return _vm.handleParentActive(key, keySub, false);
                }
              },
              model: {
                value: _vm.checkedMenus[key].child[keySub].update,
                callback: function callback($$v) {
                  _vm.$set(_vm.checkedMenus[key].child[keySub], "update", $$v);
                },
                expression: "checkedMenus[key].child[keySub].update"
              }
            })] : [_vm._v(" ")]], 2), _c('td', {
              staticClass: "text-center"
            }, [subMenuItem.delete ? [_c('b-form-checkbox', {
              key: keySub,
              attrs: {
                "name": "check-button",
                "switch": "",
                "inline": "",
                "disabled": !_vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 0)
              },
              on: {
                "change": function change($event) {
                  return _vm.handleParentActive(key, keySub, false);
                }
              },
              model: {
                value: _vm.checkedMenus[key].child[keySub].delete,
                callback: function callback($$v) {
                  _vm.$set(_vm.checkedMenus[key].child[keySub], "delete", $$v);
                },
                expression: "checkedMenus[key].child[keySub].delete"
              }
            })] : [_vm._v(" ")]], 2), _c('td', {
              staticClass: "text-center"
            }, [subMenuItem.export ? [_c('b-form-checkbox', {
              key: keySub,
              attrs: {
                "name": "check-button",
                "switch": "",
                "inline": "",
                "disabled": !_vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 0)
              },
              on: {
                "change": function change($event) {
                  return _vm.handleParentActive(key, keySub, false);
                }
              },
              model: {
                value: _vm.checkedMenus[key].child[keySub].export,
                callback: function callback($$v) {
                  _vm.$set(_vm.checkedMenus[key].child[keySub], "export", $$v);
                },
                expression: "checkedMenus[key].child[keySub].export"
              }
            })] : [_vm._v(" ")]], 2), _c('td', {
              staticClass: "text-center"
            }, [subMenuItem.review ? [_c('b-form-checkbox', {
              key: keySub,
              attrs: {
                "name": "check-button",
                "switch": "",
                "inline": "",
                "disabled": !_vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 0)
              },
              on: {
                "change": function change($event) {
                  return _vm.handleParentActive(key, keySub, false);
                }
              },
              model: {
                value: _vm.checkedMenus[key].child[keySub].review,
                callback: function callback($$v) {
                  _vm.$set(_vm.checkedMenus[key].child[keySub], "review", $$v);
                },
                expression: "checkedMenus[key].child[keySub].review"
              }
            })] : [_vm._v(" ")]], 2), _c('td', {
              staticClass: "text-center"
            }, [subMenuItem.approval ? [_c('b-form-checkbox', {
              key: keySub,
              attrs: {
                "name": "check-button",
                "switch": "",
                "inline": "",
                "disabled": !_vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 0)
              },
              on: {
                "change": function change($event) {
                  return _vm.handleParentActive(key, keySub, false);
                }
              },
              model: {
                value: _vm.checkedMenus[key].child[keySub].approval,
                callback: function callback($$v) {
                  _vm.$set(_vm.checkedMenus[key].child[keySub], "approval", $$v);
                },
                expression: "checkedMenus[key].child[keySub].approval"
              }
            })] : [_vm._v(" ")]], 2)]), _vm._l(subMenuItem.child, function (tabMenuItem, keyTab) {
              return [_c('tr', {
                key: "keytab-".concat(key).concat(keySub).concat(keyTab)
              }, [_c('td', {
                staticClass: "text-center"
              }, [_c('b-form-checkbox', {
                attrs: {
                  "name": "tab-ck-".concat(keySub),
                  "disabled": !_vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 0)
                },
                on: {
                  "change": function change($event) {
                    return _vm.selectMenu(key, keySub, false, true, keyTab);
                  }
                },
                model: {
                  value: _vm.checkedMenus[key].child[keySub].child[keyTab].active,
                  callback: function callback($$v) {
                    _vm.$set(_vm.checkedMenus[key].child[keySub].child[keyTab], "active", $$v);
                  },
                  expression: "\n                                        checkedMenus[key].child[keySub].child[keyTab]\n                                          .active\n                                      "
                }
              })], 1), _c('td', [_c('span', {
                staticStyle: {
                  "margin-right": "100px"
                }
              }), _vm._v(_vm._s(tabMenuItem.menu))]), _c('td', {
                staticClass: "text-center"
              }, [tabMenuItem.add ? [_c('b-form-checkbox', {
                key: keySub,
                attrs: {
                  "name": "check-button",
                  "switch": "",
                  "inline": "",
                  "disabled": !_vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 0)
                },
                on: {
                  "change": function change($event) {
                    return _vm.handleParentActive(key, keySub, false, true, keyTab);
                  }
                },
                model: {
                  value: _vm.checkedMenus[key].child[keySub].child[keyTab].add,
                  callback: function callback($$v) {
                    _vm.$set(_vm.checkedMenus[key].child[keySub].child[keyTab], "add", $$v);
                  },
                  expression: "\n                                          checkedMenus[key].child[keySub].child[keyTab]\n                                            .add\n                                        "
                }
              })] : [_vm._v(" ")]], 2), _c('td', {
                staticClass: "text-center"
              }, [tabMenuItem.update ? [_c('b-form-checkbox', {
                attrs: {
                  "name": "check-button",
                  "switch": "",
                  "inline": "",
                  "disabled": !_vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 0)
                },
                on: {
                  "change": function change($event) {
                    return _vm.handleParentActive(key, keySub, false, true, keyTab);
                  }
                },
                model: {
                  value: _vm.checkedMenus[key].child[keySub].child[keyTab].update,
                  callback: function callback($$v) {
                    _vm.$set(_vm.checkedMenus[key].child[keySub].child[keyTab], "update", $$v);
                  },
                  expression: "\n                                          checkedMenus[key].child[keySub].child[keyTab]\n                                            .update\n                                        "
                }
              })] : [_vm._v(" ")]], 2), _c('td', {
                staticClass: "text-center"
              }, [tabMenuItem.delete ? [_c('b-form-checkbox', {
                attrs: {
                  "name": "check-button",
                  "switch": "",
                  "inline": "",
                  "disabled": !_vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 0)
                },
                on: {
                  "change": function change($event) {
                    return _vm.handleParentActive(key, keySub, false, true, keyTab);
                  }
                },
                model: {
                  value: _vm.checkedMenus[key].child[keySub].child[keyTab].delete,
                  callback: function callback($$v) {
                    _vm.$set(_vm.checkedMenus[key].child[keySub].child[keyTab], "delete", $$v);
                  },
                  expression: "\n                                          checkedMenus[key].child[keySub].child[keyTab]\n                                            .delete\n                                        "
                }
              })] : [_vm._v(" ")]], 2), _c('td', {
                staticClass: "text-center"
              }, [tabMenuItem.export ? [_c('b-form-checkbox', {
                attrs: {
                  "name": "check-button",
                  "switch": "",
                  "inline": "",
                  "disabled": !_vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 0)
                },
                on: {
                  "change": function change($event) {
                    return _vm.handleParentActive(key, keySub, false, true, keyTab);
                  }
                },
                model: {
                  value: _vm.checkedMenus[key].child[keySub].child[keyTab].export,
                  callback: function callback($$v) {
                    _vm.$set(_vm.checkedMenus[key].child[keySub].child[keyTab], "export", $$v);
                  },
                  expression: "\n                                          checkedMenus[key].child[keySub].child[keyTab]\n                                            .export\n                                        "
                }
              })] : [_vm._v(" ")]], 2), _c('td', {
                staticClass: "text-center"
              }, [tabMenuItem.review ? [_c('b-form-checkbox', {
                attrs: {
                  "name": "check-button",
                  "switch": "",
                  "inline": "",
                  "disabled": !_vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 0)
                },
                on: {
                  "change": function change($event) {
                    return _vm.handleParentActive(key, keySub, false, true, keyTab);
                  }
                },
                model: {
                  value: _vm.checkedMenus[key].child[keySub].child[keyTab].review,
                  callback: function callback($$v) {
                    _vm.$set(_vm.checkedMenus[key].child[keySub].child[keyTab], "review", $$v);
                  },
                  expression: "\n                                          checkedMenus[key].child[keySub].child[keyTab]\n                                            .review\n                                        "
                }
              })] : [_vm._v(" ")]], 2), _c('td', {
                staticClass: "text-center"
              }, [tabMenuItem.approval ? [_c('b-form-checkbox', {
                attrs: {
                  "name": "check-button",
                  "switch": "",
                  "inline": "",
                  "disabled": !_vm.findMenuPermissionLv2ByIndex('update', _vm.$route.query.menuId, 0)
                },
                on: {
                  "change": function change($event) {
                    return _vm.handleParentActive(key, keySub, false, true, keyTab);
                  }
                },
                model: {
                  value: _vm.checkedMenus[key].child[keySub].child[keyTab].approval,
                  callback: function callback($$v) {
                    _vm.$set(_vm.checkedMenus[key].child[keySub].child[keyTab], "approval", $$v);
                  },
                  expression: "\n                                          checkedMenus[key].child[keySub].child[keyTab]\n                                            .approval\n                                        "
                }
              })] : [_vm._v(" ")]], 2)])];
            })];
          })];
        })], 2)])]) : _c('div', {
          staticClass: "text-center my-5"
        }, [_c('p', {
          staticClass: "mb-0 text-muted"
        }, [_vm._v("No privilege data found on selected role.")])])]], 2)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('b-card', {
    staticClass: "mb-4"
  })], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "structure"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("User Structure")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-alert', {
          attrs: {
            "variant": "success",
            "show": _vm.structure_notification.show,
            "dismissible": "",
            "fade": ""
          }
        }, [_vm._v(_vm._s(_vm.structure_notification.text))]), _c('vue-nestable', {
          on: {
            "change": _vm.structuresChanged
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref) {
              var item = _ref.item;
              return _c('vue-nestable-handle', {
                attrs: {
                  "item": item
                }
              }, [_c('div', {
                staticClass: "w-100 d-flex align-items-center justify-content-between background-slate py-2 px-3"
              }, [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('div', {
                staticClass: "pr-2 border-right mr-2"
              }, [_c('i', {
                staticClass: "fas fa-bars"
              })]), _c('b-avatar', {
                staticClass: "mr-1",
                attrs: {
                  "variant": "warning",
                  "size": "26px"
                }
              }), _c('h6', {
                staticClass: "text-md"
              }, [_vm._v(" " + _vm._s(item.text) + " ")])], 1), _c('button', {
                staticClass: "btn text-white",
                staticStyle: {
                  "background": "#3a5de7"
                },
                on: {
                  "click": function click($event) {
                    return _vm.$router.push("/edit-user/".concat(item.id));
                  }
                }
              }, [_c('i', {
                staticClass: "fa fa-edit p-0 m-0"
              }), _vm._v(" Update ")])])]);
            }
          }]),
          model: {
            value: _vm.structures,
            callback: function callback($$v) {
              _vm.structures = $$v;
            },
            expression: "structures"
          }
        })];
      },
      proxy: true
    }])
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }