<template>
  <b-container fluid>
    <b-row>
      <b-col md="6" lg="3">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-primary rounded">
          <template v-slot:body>
            <div class="d-flex align-items-center justify-content-between">
              <div class="rounded-circle iq-card-icon bg-primary"><i class="fa fa-ship"></i></div>
              <div class="text-right">
                <h4 class="mb-0"><span class="counter"><b>7</b></span></h4>
                <h6 class="">Total Docking</h6>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col md="6" lg="3">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-warning rounded">
          <template v-slot:body >
            <div class="d-flex align-items-center justify-content-between">
              <div class="rounded-circle iq-card-icon bg-warning"><i class="fas fa-dollar-sign"></i></div>
              <div class="text-right">
                <h4 class="mb-0"><span class="counter"><b>Rp. 3.552.017.210</b></span></h4>
                <h6 class="">Total Cost All Fleet</h6>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col md="6" lg="3">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-danger rounded">
          <template v-slot:body >
            <div class="d-flex align-items-center justify-content-between">
              <div class="rounded-circle iq-card-icon bg-danger"><i class="fa fa-tools"></i></div>
              <div class="text-right">
                <h4 class="mb-0"><span class="counter"><b>45</b></span></h4>
                <h6 class="">Total Job Docking</h6>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col md="6" lg="3">
        <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-info rounded">
          <template v-slot:body >
            <div class="d-flex align-items-center justify-content-between">
              <div class="rounded-circle iq-card-icon bg-info"><i class="fa fa-spinner"></i></div>
              <div class="text-right">
                <h4 class="mb-0"><span class="counter"><b>36</b></span></h4>
                <h6 class="">On Progress Job</h6>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12" v-for="(item,index) in costDocking" :key="index">
        <iq-card>
          <template v-slot:headerTitle>
            <h4>{{ item.title }}</h4>
          </template>
          <template v-slot:body>
            <HighChart :ref="item.type" :option="item.bodyData"/>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title text-primary">
              <strong>DOCKING HISTORY - TB. TAVIA 1</strong>
            </h4>
          </template>
          <template v-slot:body>
            <!-- FILTER DOCKING PROGRESS-->
            <b-row class="mb-3">
              <b-col md="3">
                <b-form-select plain size="sm">
                  <template v-slot:first>
                  <b-form-select-option>Select Docking Type</b-form-select-option>
                  <b-form-select-option>Intermediate Survey</b-form-select-option>
                  <b-form-select-option>Special Survey</b-form-select-option>
                  <b-form-select-option>Emergency Docking</b-form-select-option>
                  </template>
                </b-form-select>
              </b-col>
              <b-col md="1">
                <b-button variant="primary"size="sm"><i class="fa fa-filter"></i></b-button>
              </b-col>
            </b-row>
            <!-- FILTER DOCKING PROGRESS END -->
            <table class="table table-striped table-sm">
              <thead>
                <tr>
                  <th class="text-center">SHIPYARD NAME</th>
                  <th class="text-center">DOCKING TYPE</th>
                  <th class="text-center">TOTAL JOB</th>
                  <th class="text-center">TOTAL COST</th>
                  <th class="text-center">UPSLIP/DOCKING DATE</th>
                  <th class="text-center">STATUS</th>
                  <th class="text-center">ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="pl-4">PT. KRAKATAU SHIPYARD</td>
                  <td class="text-center">
                    <b-badge variant="info">Intermediate Survey</b-badge>
                  </td>
                  <td class="text-center">53 Jobs</td>
                  <td class="text-center">Rp. 1.238.203.200,-</td>
                  <td class="text-center">15 Juni 2022</td>
                  <td class="text-center">
                    <b-badge variant="success" class="mr-1">On Time : 45 Jobs</b-badge>
                    <b-badge variant="danger">Delay : 23 Jobs</b-badge>
                  </td>
                  <td class="text-center">
                    <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10"
                      toggle-class="text-decoration-none" no-caret>
                      <template v-slot:button-content>
                        <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                          <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                        </span>
                      </template>
                      <b-dropdown-item href="#" v-b-toggle.detail_docking variant="primary">
                        <i class="fa fa-info-circle mr-2"></i>Detail
                      </b-dropdown-item>
                      <b-dropdown-item variant="primary">
                        <a href="" @click="slideHistoryMonthlyReport = !slideHistoryMonthlyReport;"><i class="fa fa-history mr-2"></i>Monthly Report</a>
                      </b-dropdown-item>
                      <b-dropdown-item href="#" variant="primary">
                        <i class="fa fa-trash mr-2"></i>Delete
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
                <tr>
                  <td class="pl-4">ANUGRAH BUANA SHIPYARD</td>
                  <td class="text-center">
                    <b-badge variant="info">Intermediate Survey</b-badge>
                  </td>
                  <td class="text-center">36 Jobs</td>
                  <td class="text-center">Rp. 1.375.123.180,-</td>
                  <td class="text-center">18 Januari 2022</td>
                  <td class="text-center">
                    <b-badge variant="success" class="mr-1">On Time : 32 Jobs</b-badge>
                    <b-badge variant="danger">Delay : 4 Jobs</b-badge>
                  </td>
                  <td class="text-center">
                    <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10"
                      toggle-class="text-decoration-none" no-caret>
                      <template v-slot:button-content>
                        <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                          <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                        </span>
                      </template>
                      <b-dropdown-item href="#" v-b-toggle.detail_docking variant="primary">
                        <i class="fa fa-info-circle mr-2"></i>Detail
                      </b-dropdown-item>
                      <b-dropdown-item variant="primary">
                        <a href="" @click="slideHistoryMonthlyReport = !slideHistoryMonthlyReport;"><i class="fa fa-history mr-2"></i>Monthly Report</a>
                      </b-dropdown-item>
                      <b-dropdown-item href="#" variant="primary">
                        <i class="fa fa-trash mr-2"></i>Delete
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
                <tr>
                  <td class="pl-4">MARIBAYA SHIPYARD</td>
                  <td class="text-center">
                    <b-badge variant="primary">Special Survey</b-badge>
                  </td>
                  <td class="text-center">64 Jobs</td>
                  <td class="text-center">Rp. 1.837.129.500,-</td>
                  <td class="text-center">25 September 2021</td>
                  <td class="text-center">
                    <b-badge variant="success" class="mr-1">On Time : 43 Jobs</b-badge>
                    <b-badge variant="danger">Delay : 21 Jobs</b-badge>
                  </td>
                  <td class="text-center">
                    <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10"
                      toggle-class="text-decoration-none" no-caret>
                      <template v-slot:button-content>
                        <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                          <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                        </span>
                      </template>
                      <b-dropdown-item href="#" v-b-toggle.detail_docking variant="primary">
                        <i class="fa fa-info-circle mr-2"></i>Detail
                      </b-dropdown-item>
                      <b-dropdown-item variant="primary">
                        <a href="" @click="slideHistoryMonthlyReport = !slideHistoryMonthlyReport;"><i class="fa fa-history mr-2"></i>Monthly Report</a>
                      </b-dropdown-item>
                      <b-dropdown-item href="#" variant="primary">
                        <i class="fa fa-trash mr-2"></i>Delete
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
                <tr>
                  <td class="pl-4">PT. GLOBAL SHIPYARD INDONESIA</td>
                  <td class="text-center">
                    <b-badge variant="danger">Emergency Docking</b-badge>
                  </td>
                  <td class="text-center">72 Jobs</td>
                  <td class="text-center">Rp. 1.387.384.000,-</td>
                  <td class="text-center">08 Maret 2021</td>
                  <td class="text-center">
                    <b-badge variant="success" class="mr-1">On Time : 52 Jobs</b-badge>
                    <b-badge variant="danger">Delay : 20 Jobs</b-badge>
                  </td>
                  <td class="text-center">
                    <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10"
                      toggle-class="text-decoration-none" no-caret>
                      <template v-slot:button-content>
                        <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                          <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                        </span>
                      </template>
                      <b-dropdown-item href="#" v-b-toggle.detail_docking variant="primary">
                        <i class="fa fa-info-circle mr-2"></i>Detail
                      </b-dropdown-item>
                      <b-dropdown-item variant="primary">
                        <a href="" @click="slideHistoryMonthlyReport = !slideHistoryMonthlyReport;"><i class="fa fa-history mr-2"></i>Monthly Report</a>
                      </b-dropdown-item>
                      <b-dropdown-item href="#" variant="primary">
                        <i class="fa fa-trash mr-2"></i>Delete
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="mt-3">
              <b-pagination :value="1" :total-rows="50" align="center" />
            </div>
            <!-- SLIDE DETAIL -->
            <b-sidebar id="detail_docking" title="DETAIL DOCKING - TB. TAVIA 1" width="60%" backdrop-variant="dark"
              bg-variant="white" right backdrop shadow>
              <template #default="{hide}">
                <b-row class="m-3">
                  <b-col cols="12">
                    <fieldset class="form-group border p-3">
                      <legend class="w-auto px-2 text-primary">Docking Info</legend>
                      <table class="table table-striped">
                        <tbody>
                          <tr>
                            <th width="20%">Nama Shipyard*</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">Samudara Indonesia, PT</td>
                            <th width="20%">PIC*</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">Nurul Akbar</td>
                          </tr>
                          <tr>
                            <th width="20%">Jenis Pekerjaan*</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">General Overhaul</td>
                            <th width="20%">Estimasi Budget</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">Rp. 256.000.000,-</td>
                          </tr>
                          <tr>
                            <th width="20%">TA. Shipyard*</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">01 Oktober 2023</td>
                            <th width="20%">Floating Repair</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">03 Oktober 2023</td>
                          </tr>
                          <tr>
                            <th width="20%">Upslip*</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">05 Oktober 2023</td>
                            <th width="20%">Downslip*</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">25 Oktober 2023</td>
                          </tr>
                          <tr>
                            <th width="20%">Sea Trial</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">27 Oktober 2023</td>
                            <th width="20%">Delivery</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">31 Oktober 2023</td>
                          </tr>
                          <tr>
                            <th width="20%">Waiting Space</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">5 Hari</td>
                            <th width="20%">Target Days</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">60 Hari</td>
                          </tr>
                          <tr>
                            <th width="20%">Estimasi Tonase</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">8.9</td>
                            <th width="20%">Dokumen Shipyard</th>
                            <td width="5%" class="text-center">:</td>
                            <td width="25%">
                              <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                              </ul>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </fieldset>
                    <fieldset class="form-group border p-3">
                      <legend class="w-auto px-2 text-primary">Docking Job</legend>
                      <b-alert :show="true" variant="primary">
                        <div class="iq-alert-icon">
                          <i class="fa fa-percent"></i>
                        </div>
                        <div class="iq-alert-text">Docking Job Category <b>100%</b></div>
                      </b-alert>

                      <a href="#" v-b-toggle.jobCat-1>
                        <b-alert :show="true" class="text-white bg-primary">
                          <div class="iq-alert-icon">
                            <i class="fas fa-tasks"></i>
                          </div>
                          <div class="iq-alert-text">Docking / Undocking <b>(2 Jobs) - 30%</b></div>
                          <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                            <i class="far fa-hand-pointer"></i> Click Here
                          </a>
                        </b-alert>
                      </a>
                      <b-collapse id="jobCat-1" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                          <b-alert :show="true" variant="danger">
                            <div class="iq-alert-icon">
                              <i class="fa fa-percent"></i>
                            </div>
                            <div class="iq-alert-text">Docking Job <b>100%</b></div>
                          </b-alert>
                          <div class="accordion" role="tablist">
                            <b-card no-body >
                              <a href="#" v-b-toggle.job-1-1>
                                <b-alert :show="true" class="text-white bg-success">
                                  <div class="iq-alert-icon">
                                    <i class="fas fa-clipboard-list"></i>
                                  </div>
                                  <div class="iq-alert-text"><b>Upslip</b> - Persentase Pekerjaan <b>50% (Finish)</b></div>
                                  <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                    <i class="far fa-hand-pointer"></i> Click Here
                                  </a>
                                </b-alert>
                              </a>
                              <b-collapse id="job-1-1" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Upslip</legend>
                                  <table class="table table-striped mt-2">
                                    <tbody>
                                      <tr>
                                        <th width="20%">Start Date</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">2022-06-10</td>
                                        <th width="20%">Est. Finish</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">2022-06-15</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Bobot Pekerjaan</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">50%</td>
                                        <th width="20%">Dokumen</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Created By</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Nurul Akbar</td>
                                        <th width="20%">Review By</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Agung Tri Laksono</td>
                                      </tr>
                                      <tr>
                                        <th class="align-top">Notes</th>
                                        <td class="text-center align-top">:</td>
                                        <td colspan="4">
                                          Check water tubes/plates and clean/free from clogged.<br>
                                          Check  from any corrosion <br>
                                          Check  zink anodes,  replace when necessary<br>
                                          Check  leakage after assembly
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Internal Man Power</legend>
                                  <table class="table table-striped mt-2">
                                    <tbody>
                                      <tr>
                                        <th width="50%" class="pl-3">Agung Tri Laksono</th>
                                        <td width="50%">First Engineer</td>
                                      </tr>
                                      <tr>
                                        <th width="50%" class="pl-3">Nurul Akbar</th>
                                        <td width="50%">Second Engineer</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                  <table class="table table-striped">
                                    <tbody>
                                      <tr>
                                        <th width="20%">Nama Kontraktor </th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">PT. CAHAYA PELAYARAN</td>
                                        <th width="20%">Executive Engineer</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Agung Tri Laksono</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Penawaran</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">328.000.000</td>
                                        <th width="20%">Penawaran Nego</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">325.000.000</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Notes</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Document</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Sub Kontraktor #2</legend>
                                  <table class="table table-striped">
                                    <tbody>
                                      <tr>
                                        <th width="20%">Nama Kontraktor </th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">PT. INTAN BAHARI</td>
                                        <th width="20%">Executive Engineer</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Heri Budianto</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Penawaran</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">178.000.000</td>
                                        <th width="20%">Penawaran Nego</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">175.000.000</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Notes</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">Perbaikan lambung</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Document</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Daily Progress</legend>
                                  <b-col md="12" class="mb-5">
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Select Docking Job" label-for="docking_job">
                                          <b-form-select plain v-model="selectedDockingJob" :options="docking_job">
                                            <template v-slot:first>
                                            <b-form-select-option :value="null">List Docking Job</b-form-select-option>
                                            </template>
                                          </b-form-select>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label-for="progress_bar"><h4 class="mb-2">Progrees : <b-badge variant="warning">80%</b-badge></h4>
                                          <b-progress :value="80" variant="success" max="100"></b-progress>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Start *" label-for="start">
                                          <b-form-input type="date" id="start"></b-form-input>
                                          <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                        </b-form-group>
                                      </b-col>
                                      <b-col>
                                        <b-form-group label="Progress Pekerjaan *" label-for="progress_pekerjaan">
                                          <div class="input-group">
                                            <div class="input-group-prepend">
                                              <span class="input-group-text bg-primary" id="progress_pekerjaan">%</span>
                                            </div>
                                            <input type="text" class="form-control" id="progress_pekerjaan" placeholder="80">
                                          </div>
                                        </b-form-group>
                                      </b-col>
                                      <b-col>
                                        <b-form-group label="Report Progress" label-for="report_progress">
                                          <div class="custom-file">
                                            <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                            <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                          </div>
                                          <small class="text-muted">
                                            File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                            File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                          </small>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Notes" label-for="notes">
                                          <b-form-textarea id="description" rows="3" placeholder="Check water tubes/plates and clean/free from clogged."></b-form-textarea>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>

                                    <div class="form-group row align-items-center mt-3 m-0">
                                      <div class="d-flex flex-grow-1 align-items-center">
                                        <div class="send-panel">
                                          <label for="file" class="mb-0 iq-bg-primary rounded">
                                            <input type="file" id="file" style="display: none;">
                                            <a>
                                              <i class="ri-attachment-line"></i>
                                            </a>
                                          </label>
                                          <label class="ml-2 mb-0 iq-bg-primary rounded">
                                            <a href="javascript:void(0)">
                                              <i class="ri-gallery-line text-primary"></i>
                                            </a>
                                          </label>
                                        </div>
                                      </div>
                                      <div class="d-flex align-items-center">
                                        <div class="send-panel float-left">
                                          <div class="send-btn">
                                            <b-button variant="success"><i class="fa fa-check"></i> Finish</b-button>
                                            <b-button variant="primary" class="ml-2"><i class="fa fa-comment"></i> Update Activity</b-button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </b-col>
                                  <ul class="iq-timeline" style="height:100%">
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                        <small>24 November 2019 15:45</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melakukan inspeksi pada kerusakan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/6.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/1.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Agung Tri Laksono</h6><br/>
                                        <small>23 November 2019 21:25</small>
                                        <div class="d-inline-block w-100">
                                          <p>Barang tiba di galangan</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Tri Leksono</h6><br/>
                                        <small>22 November 2019 10:12</small>
                                        <div class="d-inline-block w-100">
                                          <p>Menunggu sparepart tiba</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                        <small>21 November 2019 11:21</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melengkapi dokumen perbaikan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </div>
                                    </li>
                                  </ul>
                                </fieldset>
                              </b-collapse>
                            </b-card>
                            <b-card no-body >
                              <a href="#" v-b-toggle.job-1-2>
                                <b-alert :show="true" class="text-white bg-info">
                                  <div class="iq-alert-icon">
                                    <i class="fas fa-clipboard-list"></i>
                                  </div>
                                  <div class="iq-alert-text"><b>Downslip</b> - Persentase Pekerjaan <b>50% (Progress)</b></div>
                                  <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                    <i class="far fa-hand-pointer"></i> Click Here
                                  </a>
                                </b-alert>
                              </a>
                              <b-collapse id="job-1-2" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Downslip</legend>
                                  <table class="table table-striped mt-2">
                                    <tbody>
                                      <tr>
                                        <th width="20%">Start Date</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">2022-06-10</td>
                                        <th width="20%">Est. Finish</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">2022-06-15</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Bobot Pekerjaan</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">50%</td>
                                        <th width="20%">Dokumen</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Created By</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Nurul Akbar</td>
                                        <th width="20%">Review By</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Agung Tri Laksono</td>
                                      </tr>
                                      <tr>
                                        <th class="align-top">Notes</th>
                                        <td class="text-center align-top">:</td>
                                        <td colspan="4">
                                          Check water tubes/plates and clean/free from clogged.<br>
                                          Check  from any corrosion <br>
                                          Check  zink anodes,  replace when necessary<br>
                                          Check  leakage after assembly
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Internal Man Power</legend>
                                  <table class="table table-striped mt-2">
                                    <tbody>
                                      <tr>
                                        <th width="50%" class="pl-3">Agung Tri Laksono</th>
                                        <td width="50%">First Engineer</td>
                                      </tr>
                                      <tr>
                                        <th width="50%" class="pl-3">Nurul Akbar</th>
                                        <td width="50%">Second Engineer</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                  <table class="table table-striped">
                                    <tbody>
                                      <tr>
                                        <th width="20%">Nama Kontraktor </th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">PT. CAHAYA PELAYARAN</td>
                                        <th width="20%">Executive Engineer</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Agung Tri Laksono</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Penawaran</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">328.000.000</td>
                                        <th width="20%">Penawaran Nego</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">325.000.000</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Notes</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Document</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Daily Progress</legend>
                                  <b-col md="12" class="mb-5">
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Select Docking Job" label-for="docking_job">
                                          <b-form-select plain v-model="selectedDockingJob" :options="docking_job">
                                            <template v-slot:first>
                                            <b-form-select-option :value="null">List Docking Job</b-form-select-option>
                                            </template>
                                          </b-form-select>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label-for="progress_bar"><h4 class="mb-2">Progrees : <b-badge variant="warning">80%</b-badge></h4>
                                          <b-progress :value="80" variant="success" max="100"></b-progress>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Start *" label-for="start">
                                          <b-form-input type="date" id="start"></b-form-input>
                                          <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                        </b-form-group>
                                      </b-col>
                                      <b-col>
                                        <b-form-group label="Progress Pekerjaan *" label-for="progress_pekerjaan">
                                          <div class="input-group">
                                            <div class="input-group-prepend">
                                              <span class="input-group-text bg-primary" id="progress_pekerjaan">%</span>
                                            </div>
                                            <input type="text" class="form-control" id="progress_pekerjaan" placeholder="80">
                                          </div>
                                        </b-form-group>
                                      </b-col>
                                      <b-col>
                                        <b-form-group label="Report Progress" label-for="report_progress">
                                          <div class="custom-file">
                                            <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                            <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                          </div>
                                          <small class="text-muted">
                                            File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                            File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                          </small>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Notes" label-for="notes">
                                          <b-form-textarea id="description" rows="3" placeholder="Check water tubes/plates and clean/free from clogged."></b-form-textarea>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>

                                    <div class="form-group row align-items-center mt-3 m-0">
                                      <div class="d-flex flex-grow-1 align-items-center">
                                        <div class="send-panel">
                                          <label for="file" class="mb-0 iq-bg-primary rounded">
                                            <input type="file" id="file" style="display: none;">
                                            <a>
                                              <i class="ri-attachment-line"></i>
                                            </a>
                                          </label>
                                          <label class="ml-2 mb-0 iq-bg-primary rounded">
                                            <a href="javascript:void(0)">
                                              <i class="ri-gallery-line text-primary"></i>
                                            </a>
                                          </label>
                                        </div>
                                      </div>
                                      <div class="d-flex align-items-center">
                                        <div class="send-panel float-left">
                                          <div class="send-btn">
                                            <b-button variant="success"><i class="fa fa-check"></i> Finish</b-button>
                                            <b-button variant="primary" class="ml-2"><i class="fa fa-comment"></i> Update Activity</b-button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </b-col>
                                  <ul class="iq-timeline" style="height:100%">
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                        <small>24 November 2019 15:45</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melakukan inspeksi pada kerusakan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/6.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/1.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Agung Tri Laksono</h6><br/>
                                        <small>23 November 2019 21:25</small>
                                        <div class="d-inline-block w-100">
                                          <p>Barang tiba di galangan</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Tri Leksono</h6><br/>
                                        <small>22 November 2019 10:12</small>
                                        <div class="d-inline-block w-100">
                                          <p>Menunggu sparepart tiba</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                        <small>21 November 2019 11:21</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melengkapi dokumen perbaikan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </div>
                                    </li>
                                  </ul>
                                </fieldset>
                              </b-collapse>
                            </b-card>
                          </div>
                        </b-card-body>
                      </b-collapse>

                      <a href="#" v-b-toggle.jobCat-2>
                        <b-alert :show="true" class="text-white bg-primary">
                          <div class="iq-alert-icon">
                            <i class="fas fa-tasks"></i>
                          </div>
                          <div class="iq-alert-text">Sand Blasting <b>(4 Jobs) - 70%</b></div>
                          <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                            <i class="far fa-hand-pointer"></i> Click Here
                          </a>
                        </b-alert>
                      </a>
                      <b-collapse id="jobCat-2" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                          <b-alert :show="true" variant="danger">
                            <div class="iq-alert-icon">
                              <i class="fa fa-percent"></i>
                            </div>
                            <div class="iq-alert-text">Docking Job <b>100%</b></div>
                          </b-alert>
                          <div class="accordion" role="tablist">
                            <b-card no-body >
                              <a href="#" v-b-toggle.job-2-1>
                                <b-alert :show="true" class="text-white bg-success">
                                  <div class="iq-alert-icon">
                                    <i class="fas fa-clipboard-list"></i>
                                  </div>
                                  <div class="iq-alert-text"><b>Scraping Teripting di Lambung & Lunas</b> - Persentase Pekerjaan <b>25% (Finish)</b></div>
                                  <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                    <i class="far fa-hand-pointer"></i> Click Here
                                  </a>
                                </b-alert>
                              </a>
                              <b-collapse id="job-2-1" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Scraping Teripting di Lambung & Lunas</legend>
                                  <table class="table table-striped mt-2">
                                    <tbody>
                                      <tr>
                                        <th width="20%">Start Date</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">2022-06-10</td>
                                        <th width="20%">Est. Finish</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">2022-06-15</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Bobot Pekerjaan</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">50%</td>
                                        <th width="20%">Dokumen</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Created By</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Nurul Akbar</td>
                                        <th width="20%">Review By</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Agung Tri Laksono</td>
                                      </tr>
                                      <tr>
                                        <th class="align-top">Notes</th>
                                        <td class="text-center align-top">:</td>
                                        <td colspan="4">
                                          Check water tubes/plates and clean/free from clogged.<br>
                                          Check  from any corrosion <br>
                                          Check  zink anodes,  replace when necessary<br>
                                          Check  leakage after assembly
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Internal Man Power</legend>
                                  <table class="table table-striped mt-2">
                                    <tbody>
                                      <tr>
                                        <th width="50%" class="pl-3">Agung Tri Laksono</th>
                                        <td width="50%">First Engineer</td>
                                      </tr>
                                      <tr>
                                        <th width="50%" class="pl-3">Nurul Akbar</th>
                                        <td width="50%">Second Engineer</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                  <table class="table table-striped">
                                    <tbody>
                                      <tr>
                                        <th width="20%">Nama Kontraktor </th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">PT. CAHAYA PELAYARAN</td>
                                        <th width="20%">Executive Engineer</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Agung Tri Laksono</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Penawaran</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">328.000.000</td>
                                        <th width="20%">Penawaran Nego</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">325.000.000</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Notes</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Document</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Daily Progress</legend>
                                  <b-col md="12" class="mb-5">
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Select Docking Job" label-for="docking_job">
                                          <b-form-select plain v-model="selectedDockingJob" :options="docking_job">
                                            <template v-slot:first>
                                            <b-form-select-option :value="null">List Docking Job</b-form-select-option>
                                            </template>
                                          </b-form-select>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label-for="progress_bar"><h4 class="mb-2">Progrees : <b-badge variant="warning">80%</b-badge></h4>
                                          <b-progress :value="80" variant="success" max="100"></b-progress>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Start *" label-for="start">
                                          <b-form-input type="date" id="start"></b-form-input>
                                          <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                        </b-form-group>
                                      </b-col>
                                      <b-col>
                                        <b-form-group label="Progress Pekerjaan *" label-for="progress_pekerjaan">
                                          <div class="input-group">
                                            <div class="input-group-prepend">
                                              <span class="input-group-text bg-primary" id="progress_pekerjaan">%</span>
                                            </div>
                                            <input type="text" class="form-control" id="progress_pekerjaan" placeholder="80">
                                          </div>
                                        </b-form-group>
                                      </b-col>
                                      <b-col>
                                        <b-form-group label="Report Progress" label-for="report_progress">
                                          <div class="custom-file">
                                            <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                            <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                          </div>
                                          <small class="text-muted">
                                            File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                            File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                          </small>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Notes" label-for="notes">
                                          <b-form-textarea id="description" rows="3" placeholder="Check water tubes/plates and clean/free from clogged."></b-form-textarea>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>

                                    <div class="form-group row align-items-center mt-3 m-0">
                                      <div class="d-flex flex-grow-1 align-items-center">
                                        <div class="send-panel">
                                          <label for="file" class="mb-0 iq-bg-primary rounded">
                                            <input type="file" id="file" style="display: none;">
                                            <a>
                                              <i class="ri-attachment-line"></i>
                                            </a>
                                          </label>
                                          <label class="ml-2 mb-0 iq-bg-primary rounded">
                                            <a href="javascript:void(0)">
                                              <i class="ri-gallery-line text-primary"></i>
                                            </a>
                                          </label>
                                        </div>
                                      </div>
                                      <div class="d-flex align-items-center">
                                        <div class="send-panel float-left">
                                          <div class="send-btn">
                                            <b-button variant="success"><i class="fa fa-check"></i> Finish</b-button>
                                            <b-button variant="primary" class="ml-2"><i class="fa fa-comment"></i> Update Activity</b-button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </b-col>
                                  <ul class="iq-timeline" style="height:100%">
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                        <small>24 November 2019 15:45</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melakukan inspeksi pada kerusakan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/6.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/1.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Agung Tri Laksono</h6><br/>
                                        <small>23 November 2019 21:25</small>
                                        <div class="d-inline-block w-100">
                                          <p>Barang tiba di galangan</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Tri Leksono</h6><br/>
                                        <small>22 November 2019 10:12</small>
                                        <div class="d-inline-block w-100">
                                          <p>Menunggu sparepart tiba</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                        <small>21 November 2019 11:21</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melengkapi dokumen perbaikan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </div>
                                    </li>
                                  </ul>
                                </fieldset>
                              </b-collapse>
                            </b-card>
                            <b-card no-body >
                              <a href="#" v-b-toggle.job-2-2>
                                <b-alert :show="true" class="text-white bg-success">
                                  <div class="iq-alert-icon">
                                    <i class="fas fa-clipboard-list"></i>
                                  </div>
                                  <div class="iq-alert-text"><b>Pencucian Lambung dengan Air Tawar</b> - Persentase Pekerjaan <b>25% (Finish)</b></div>
                                  <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                    <i class="far fa-hand-pointer"></i> Click Here
                                  </a>
                                </b-alert>
                              </a>
                              <b-collapse id="job-2-2" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Pencucian Lambung dengan Air Tawar</legend>
                                  <table class="table table-striped mt-2">
                                    <tbody>
                                      <tr>
                                        <th width="20%">Start Date</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">2022-06-10</td>
                                        <th width="20%">Est. Finish</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">2022-06-15</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Bobot Pekerjaan</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">50%</td>
                                        <th width="20%">Dokumen</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Created By</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Nurul Akbar</td>
                                        <th width="20%">Review By</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Agung Tri Laksono</td>
                                      </tr>
                                      <tr>
                                        <th class="align-top">Notes</th>
                                        <td class="text-center align-top">:</td>
                                        <td colspan="4">
                                          Check water tubes/plates and clean/free from clogged.<br>
                                          Check  from any corrosion <br>
                                          Check  zink anodes,  replace when necessary<br>
                                          Check  leakage after assembly
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Internal Man Power</legend>
                                  <table class="table table-striped mt-2">
                                    <tbody>
                                      <tr>
                                        <th width="50%" class="pl-3">Agung Tri Laksono</th>
                                        <td width="50%">First Engineer</td>
                                      </tr>
                                      <tr>
                                        <th width="50%" class="pl-3">Nurul Akbar</th>
                                        <td width="50%">Second Engineer</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                  <table class="table table-striped">
                                    <tbody>
                                      <tr>
                                        <th width="20%">Nama Kontraktor </th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">PT. CAHAYA PELAYARAN</td>
                                        <th width="20%">Executive Engineer</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Agung Tri Laksono</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Penawaran</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">328.000.000</td>
                                        <th width="20%">Penawaran Nego</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">325.000.000</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Notes</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Document</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Daily Progress</legend>
                                  <b-col md="12" class="mb-5">
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Select Docking Job" label-for="docking_job">
                                          <b-form-select plain v-model="selectedDockingJob" :options="docking_job">
                                            <template v-slot:first>
                                            <b-form-select-option :value="null">List Docking Job</b-form-select-option>
                                            </template>
                                          </b-form-select>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label-for="progress_bar"><h4 class="mb-2">Progrees : <b-badge variant="warning">80%</b-badge></h4>
                                          <b-progress :value="80" variant="success" max="100"></b-progress>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Start *" label-for="start">
                                          <b-form-input type="date" id="start"></b-form-input>
                                          <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                        </b-form-group>
                                      </b-col>
                                      <b-col>
                                        <b-form-group label="Progress Pekerjaan *" label-for="progress_pekerjaan">
                                          <div class="input-group">
                                            <div class="input-group-prepend">
                                              <span class="input-group-text bg-primary" id="progress_pekerjaan">%</span>
                                            </div>
                                            <input type="text" class="form-control" id="progress_pekerjaan" placeholder="80">
                                          </div>
                                        </b-form-group>
                                      </b-col>
                                      <b-col>
                                        <b-form-group label="Report Progress" label-for="report_progress">
                                          <div class="custom-file">
                                            <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                            <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                          </div>
                                          <small class="text-muted">
                                            File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                            File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                          </small>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Notes" label-for="notes">
                                          <b-form-textarea id="description" rows="3" placeholder="Check water tubes/plates and clean/free from clogged."></b-form-textarea>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>

                                    <div class="form-group row align-items-center mt-3 m-0">
                                      <div class="d-flex flex-grow-1 align-items-center">
                                        <div class="send-panel">
                                          <label for="file" class="mb-0 iq-bg-primary rounded">
                                            <input type="file" id="file" style="display: none;">
                                            <a>
                                              <i class="ri-attachment-line"></i>
                                            </a>
                                          </label>
                                          <label class="ml-2 mb-0 iq-bg-primary rounded">
                                            <a href="javascript:void(0)">
                                              <i class="ri-gallery-line text-primary"></i>
                                            </a>
                                          </label>
                                        </div>
                                      </div>
                                      <div class="d-flex align-items-center">
                                        <div class="send-panel float-left">
                                          <div class="send-btn">
                                            <b-button variant="success"><i class="fa fa-check"></i> Finish</b-button>
                                            <b-button variant="primary" class="ml-2"><i class="fa fa-comment"></i> Update Activity</b-button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </b-col>
                                  <ul class="iq-timeline" style="height:100%">
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                        <small>24 November 2019 15:45</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melakukan inspeksi pada kerusakan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/6.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/1.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Agung Tri Laksono</h6><br/>
                                        <small>23 November 2019 21:25</small>
                                        <div class="d-inline-block w-100">
                                          <p>Barang tiba di galangan</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Tri Leksono</h6><br/>
                                        <small>22 November 2019 10:12</small>
                                        <div class="d-inline-block w-100">
                                          <p>Menunggu sparepart tiba</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                        <small>21 November 2019 11:21</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melengkapi dokumen perbaikan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </div>
                                    </li>
                                  </ul>
                                </fieldset>
                              </b-collapse>
                            </b-card>
                            <b-card no-body >
                              <a href="#" v-b-toggle.job-2-3>
                                <b-alert :show="true" class="text-white bg-info">
                                  <div class="iq-alert-icon">
                                    <i class="fas fa-clipboard-list"></i>
                                  </div>
                                  <div class="iq-alert-text"><b>Sand Blasting Lunas</b> - Persentase Pekerjaan <b>25% (Progress)</b></div>
                                  <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                    <i class="far fa-hand-pointer"></i> Click Here
                                  </a>
                                </b-alert>
                              </a>
                              <b-collapse id="job-2-3" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Sand Blasting Lunas</legend>
                                  <table class="table table-striped mt-2">
                                    <tbody>
                                      <tr>
                                        <th width="20%">Start Date</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">2022-06-10</td>
                                        <th width="20%">Est. Finish</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">2022-06-15</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Bobot Pekerjaan</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">50%</td>
                                        <th width="20%">Dokumen</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Created By</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Nurul Akbar</td>
                                        <th width="20%">Review By</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Agung Tri Laksono</td>
                                      </tr>
                                      <tr>
                                        <th class="align-top">Notes</th>
                                        <td class="text-center align-top">:</td>
                                        <td colspan="4">
                                          Check water tubes/plates and clean/free from clogged.<br>
                                          Check  from any corrosion <br>
                                          Check  zink anodes,  replace when necessary<br>
                                          Check  leakage after assembly
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Internal Man Power</legend>
                                  <table class="table table-striped mt-2">
                                    <tbody>
                                      <tr>
                                        <th width="50%" class="pl-3">Agung Tri Laksono</th>
                                        <td width="50%">First Engineer</td>
                                      </tr>
                                      <tr>
                                        <th width="50%" class="pl-3">Nurul Akbar</th>
                                        <td width="50%">Second Engineer</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                  <table class="table table-striped">
                                    <tbody>
                                      <tr>
                                        <th width="20%">Nama Kontraktor </th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">PT. CAHAYA PELAYARAN</td>
                                        <th width="20%">Executive Engineer</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Agung Tri Laksono</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Penawaran</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">328.000.000</td>
                                        <th width="20%">Penawaran Nego</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">325.000.000</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Notes</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Document</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Daily Progress</legend>
                                  <b-col md="12" class="mb-5">
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Select Docking Job" label-for="docking_job">
                                          <b-form-select plain v-model="selectedDockingJob" :options="docking_job">
                                            <template v-slot:first>
                                            <b-form-select-option :value="null">List Docking Job</b-form-select-option>
                                            </template>
                                          </b-form-select>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label-for="progress_bar"><h4 class="mb-2">Progrees : <b-badge variant="warning">80%</b-badge></h4>
                                          <b-progress :value="80" variant="success" max="100"></b-progress>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Start *" label-for="start">
                                          <b-form-input type="date" id="start"></b-form-input>
                                          <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                        </b-form-group>
                                      </b-col>
                                      <b-col>
                                        <b-form-group label="Progress Pekerjaan *" label-for="progress_pekerjaan">
                                          <div class="input-group">
                                            <div class="input-group-prepend">
                                              <span class="input-group-text bg-primary" id="progress_pekerjaan">%</span>
                                            </div>
                                            <input type="text" class="form-control" id="progress_pekerjaan" placeholder="80">
                                          </div>
                                        </b-form-group>
                                      </b-col>
                                      <b-col>
                                        <b-form-group label="Report Progress" label-for="report_progress">
                                          <div class="custom-file">
                                            <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                            <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                          </div>
                                          <small class="text-muted">
                                            File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                            File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                          </small>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Notes" label-for="notes">
                                          <b-form-textarea id="description" rows="3" placeholder="Check water tubes/plates and clean/free from clogged."></b-form-textarea>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>

                                    <div class="form-group row align-items-center mt-3 m-0">
                                      <div class="d-flex flex-grow-1 align-items-center">
                                        <div class="send-panel">
                                          <label for="file" class="mb-0 iq-bg-primary rounded">
                                            <input type="file" id="file" style="display: none;">
                                            <a>
                                              <i class="ri-attachment-line"></i>
                                            </a>
                                          </label>
                                          <label class="ml-2 mb-0 iq-bg-primary rounded">
                                            <a href="javascript:void(0)">
                                              <i class="ri-gallery-line text-primary"></i>
                                            </a>
                                          </label>
                                        </div>
                                      </div>
                                      <div class="d-flex align-items-center">
                                        <div class="send-panel float-left">
                                          <div class="send-btn">
                                            <b-button variant="success"><i class="fa fa-check"></i> Finish</b-button>
                                            <b-button variant="primary" class="ml-2"><i class="fa fa-comment"></i> Update Activity</b-button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </b-col>
                                  <ul class="iq-timeline" style="height:100%">
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                        <small>24 November 2019 15:45</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melakukan inspeksi pada kerusakan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/6.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/1.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Agung Tri Laksono</h6><br/>
                                        <small>23 November 2019 21:25</small>
                                        <div class="d-inline-block w-100">
                                          <p>Barang tiba di galangan</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Tri Leksono</h6><br/>
                                        <small>22 November 2019 10:12</small>
                                        <div class="d-inline-block w-100">
                                          <p>Menunggu sparepart tiba</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                        <small>21 November 2019 11:21</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melengkapi dokumen perbaikan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </div>
                                    </li>
                                  </ul>
                                </fieldset>
                              </b-collapse>
                            </b-card>
                            <b-card no-body >
                              <a href="#" v-b-toggle.job-2-4>
                                <b-alert :show="true" class="text-white bg-warning">
                                  <div class="iq-alert-icon">
                                    <i class="fas fa-clipboard-list"></i>
                                  </div>
                                  <div class="iq-alert-text"><b>Sand Blasting Deck & Bullwark</b> - Persentase Pekerjaan <b>15% (Progress)</b></div>
                                  <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                    <i class="far fa-hand-pointer"></i> Click Here
                                  </a>
                                </b-alert>
                              </a>
                              <b-collapse id="job-2-4" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Sand Blasting Lunas</legend>
                                  <table class="table table-striped mt-2">
                                    <tbody>
                                      <tr>
                                        <th width="20%">Start Date</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">2022-06-10</td>
                                        <th width="20%">Est. Finish</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">2022-06-15</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Bobot Pekerjaan</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">50%</td>
                                        <th width="20%">Dokumen</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Created By</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Nurul Akbar</td>
                                        <th width="20%">Review By</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Agung Tri Laksono</td>
                                      </tr>
                                      <tr>
                                        <th class="align-top">Notes</th>
                                        <td class="text-center align-top">:</td>
                                        <td colspan="4">
                                          Check water tubes/plates and clean/free from clogged.<br>
                                          Check  from any corrosion <br>
                                          Check  zink anodes,  replace when necessary<br>
                                          Check  leakage after assembly
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Internal Man Power</legend>
                                  <table class="table table-striped mt-2">
                                    <tbody>
                                      <tr>
                                        <th width="50%" class="pl-3">Agung Tri Laksono</th>
                                        <td width="50%">First Engineer</td>
                                      </tr>
                                      <tr>
                                        <th width="50%" class="pl-3">Nurul Akbar</th>
                                        <td width="50%">Second Engineer</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                  <table class="table table-striped">
                                    <tbody>
                                      <tr>
                                        <th width="20%">Nama Kontraktor </th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">PT. CAHAYA PELAYARAN</td>
                                        <th width="20%">Executive Engineer</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Agung Tri Laksono</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Penawaran</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">328.000.000</td>
                                        <th width="20%">Penawaran Nego</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">325.000.000</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Notes</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Document</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Daily Progress</legend>
                                  <b-col md="12" class="mb-5">
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Select Docking Job" label-for="docking_job">
                                          <b-form-select plain v-model="selectedDockingJob" :options="docking_job">
                                            <template v-slot:first>
                                            <b-form-select-option :value="null">List Docking Job</b-form-select-option>
                                            </template>
                                          </b-form-select>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label-for="progress_bar"><h4 class="mb-2">Progrees : <b-badge variant="warning">80%</b-badge></h4>
                                          <b-progress :value="80" variant="success" max="100"></b-progress>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Start *" label-for="start">
                                          <b-form-input type="date" id="start"></b-form-input>
                                          <small style="float: right">click <i class="fa fa-calendar"></i> to show</small>
                                        </b-form-group>
                                      </b-col>
                                      <b-col>
                                        <b-form-group label="Progress Pekerjaan *" label-for="progress_pekerjaan">
                                          <div class="input-group">
                                            <div class="input-group-prepend">
                                              <span class="input-group-text bg-primary" id="progress_pekerjaan">%</span>
                                            </div>
                                            <input type="text" class="form-control" id="progress_pekerjaan" placeholder="80">
                                          </div>
                                        </b-form-group>
                                      </b-col>
                                      <b-col>
                                        <b-form-group label="Report Progress" label-for="report_progress">
                                          <div class="custom-file">
                                            <input type="file" class="custom-file-input" id="validatedCustomFile" required>
                                            <label class="custom-file-label" for="validatedCustomFile">Browse file...</label>
                                          </div>
                                          <small class="text-muted">
                                            File Type * : <b-badge variant="info">.jpg | .jpeg | .png | .xlx | .xlxs | .doc | .docs | .pdf</b-badge><br>
                                            File Capacity * : <b-badge variant="info">3 MB / File</b-badge>
                                          </small>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>
                                    <b-form-row>
                                      <b-col>
                                        <b-form-group label="Notes" label-for="notes">
                                          <b-form-textarea id="description" rows="3" placeholder="Check water tubes/plates and clean/free from clogged."></b-form-textarea>
                                        </b-form-group>
                                      </b-col>
                                    </b-form-row>

                                    <div class="form-group row align-items-center mt-3 m-0">
                                      <div class="d-flex flex-grow-1 align-items-center">
                                        <div class="send-panel">
                                          <label for="file" class="mb-0 iq-bg-primary rounded">
                                            <input type="file" id="file" style="display: none;">
                                            <a>
                                              <i class="ri-attachment-line"></i>
                                            </a>
                                          </label>
                                          <label class="ml-2 mb-0 iq-bg-primary rounded">
                                            <a href="javascript:void(0)">
                                              <i class="ri-gallery-line text-primary"></i>
                                            </a>
                                          </label>
                                        </div>
                                      </div>
                                      <div class="d-flex align-items-center">
                                        <div class="send-panel float-left">
                                          <div class="send-btn">
                                            <b-button variant="success"><i class="fa fa-check"></i> Finish</b-button>
                                            <b-button variant="primary" class="ml-2"><i class="fa fa-comment"></i> Update Activity</b-button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </b-col>
                                  <ul class="iq-timeline" style="height:100%">
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                        <small>24 November 2019 15:45</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melakukan inspeksi pada kerusakan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/6.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/1.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/4.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/3.jpg" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Agung Tri Laksono</h6><br/>
                                        <small>23 November 2019 21:25</small>
                                        <div class="d-inline-block w-100">
                                          <p>Barang tiba di galangan</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Tri Leksono</h6><br/>
                                        <small>22 November 2019 10:12</small>
                                        <div class="d-inline-block w-100">
                                          <p>Menunggu sparepart tiba</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="timeline-dots border-success"></div>
                                        <h6 class="float-left mb-1">Nurul Akbar</h6><br/>
                                        <small>21 November 2019 11:21</small>
                                        <div class="d-inline-block w-100">
                                          <p>Melengkapi dokumen perbaikan</p>
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-2 col-2 pb-2"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </div>
                                    </li>
                                  </ul>
                                </fieldset>
                              </b-collapse>
                            </b-card>
                            <b-card no-body >
                              <a href="#" v-b-toggle.job-2-5>
                                <b-alert :show="true" class="text-white bg-danger">
                                  <div class="iq-alert-icon">
                                    <i class="fas fa-clipboard-list"></i>
                                  </div>
                                  <div class="iq-alert-text"><b>Sand Blasting Main Deck</b> - Persentase Pekerjaan <b>10% (New)</b></div>
                                  <a class="text-primary float-right text-white" role="button" style="cursor: pointer">
                                    <i class="far fa-hand-pointer"></i> Click Here
                                  </a>
                                </b-alert>
                              </a>
                              <b-collapse id="job-2-5" class="p-2" accordion="my-accordion-sub" role="tabpanel">
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Sand Blasting Main Deck</legend>
                                  <table class="table table-striped mt-2">
                                    <tbody>
                                      <tr>
                                        <th width="20%">Start Date</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">2022-06-10</td>
                                        <th width="20%">Est. Finish</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">2022-06-15</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Bobot Pekerjaan</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">50%</td>
                                        <th width="20%">Dokumen</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Created By</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Nurul Akbar</td>
                                        <th width="20%">Review By</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Agung Tri Laksono</td>
                                      </tr>
                                      <tr>
                                        <th class="align-top">Notes</th>
                                        <td class="text-center align-top">:</td>
                                        <td colspan="4">
                                          Check water tubes/plates and clean/free from clogged.<br>
                                          Check  from any corrosion <br>
                                          Check  zink anodes,  replace when necessary<br>
                                          Check  leakage after assembly
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Internal Man Power</legend>
                                  <table class="table table-striped mt-2">
                                    <tbody>
                                      <tr>
                                        <th width="50%" class="pl-3">Agung Tri Laksono</th>
                                        <td width="50%">First Engineer</td>
                                      </tr>
                                      <tr>
                                        <th width="50%" class="pl-3">Nurul Akbar</th>
                                        <td width="50%">Second Engineer</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                                <fieldset class="form-group border p-3">
                                  <legend class="w-auto px-2">Sub Kontraktor #1</legend>
                                  <table class="table table-striped">
                                    <tbody>
                                      <tr>
                                        <th width="20%">Nama Kontraktor </th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">PT. CAHAYA PELAYARAN</td>
                                        <th width="20%">Executive Engineer</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">Agung Tri Laksono</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Penawaran</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">328.000.000</td>
                                        <th width="20%">Penawaran Nego</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td width="25%">325.000.000</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Notes</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">Pekerjaan dilakukan oleh sub kontraktor pada bagian sebelah kanan</td>
                                      </tr>
                                      <tr>
                                        <th width="20%">Document</th>
                                        <td width="5%" class="text-center">:</td>
                                        <td colspan="4">
                                          <ul class="profile-img-gallary d-flex flex-wrap p-0 m-0">
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/pdf.png" alt="gallary-image" class="img-fluid"></a></li>
                                            <li class="col-md-1 col-1 pb-1"><a href="javascript:void(0);"><img src="../../assets/images/activity/excel.png" alt="gallary-image" class="img-fluid"></a></li>
                                          </ul>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </fieldset>
                              </b-collapse>
                            </b-card>
                          </div>
                        </b-card-body>
                      </b-collapse>
                    </fieldset>
                  </b-col>
                </b-row>
              </template>
            </b-sidebar>
            <!-- SLIDE END -->

            <b-sidebar
              v-model="slideHistoryMonthlyReport"
              id="slideHistoryMonthlyReport"
              title="HISTORY MONTHLY REPORT"
              width="100%"
              backdrop-variant="dark"
              bg-variant="white"
              right
              backdrop
              shadow>
              <template #default="{hide}">
                <b-row class="m-3">
                  <b-col cols="12">
                    <fieldset class="form-group border p-3">
                      <legend class="w-auto px-2 text-primary">TB. TAVIA 1</legend>
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th class="text-center">Bulan Report</th>
                            <th class="text-center">TA. Arrival</th>
                            <th class="text-center">Star Floating</th>
                            <th class="text-center">Uplsip</th>
                            <th class="text-center">Delivery Date</th>
                            <th class="text-center">Waiting Space</th>
                            <th class="text-center">Target Days</th>
                            <th class="text-center text-primary">Performance Monthly</th>
                            <th class="text-center text-danger">Actual Docking Days</th>
                            <th class="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center">30 Sept 2023</td>
                            <td class="text-center">07/06/2023</td>
                            <td class="text-center">15/06/2023</td>
                            <td class="text-center">04/07/2023</td>
                            <td class="text-center">-</td>
                            <td class="text-center">27 Days</td>
                            <td class="text-center">60 Days</td>
                            <td class="text-center bg-primary">30 Days</td>
                            <td class="text-center bg-danger">88 Days</td>
                            <td class="text-center">
                              <a href=""><b-button variant="primary" class="mr-1"><i class="fa fa-ship"></i></b-button></a>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">31 Agustus 2023</td>
                            <td class="text-center">07/06/2023</td>
                            <td class="text-center">15/06/2023</td>
                            <td class="text-center">04/07/2023</td>
                            <td class="text-center">-</td>
                            <td class="text-center">27 Days</td>
                            <td class="text-center">60 Days</td>
                            <td class="text-center bg-primary">31 Days</td>
                            <td class="text-center bg-danger">57 Days</td>
                            <td class="text-center">
                              <a href=""><b-button variant="primary" class="mr-1"><i class="fa fa-ship"></i></b-button></a>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">31 Juli 2023</td>
                            <td class="text-center">07/06/2023</td>
                            <td class="text-center">15/06/2023</td>
                            <td class="text-center">04/07/2023</td>
                            <td class="text-center">-</td>
                            <td class="text-center">27 Days</td>
                            <td class="text-center">60 Days</td>
                            <td class="text-center bg-primary">31 Days</td>
                            <td class="text-center bg-danger">26 Days</td>
                            <td class="text-center">
                              <a href=""><b-button variant="primary" class="mr-1"><i class="fa fa-ship"></i></b-button></a>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">30 Juni 2023</td>
                            <td class="text-center">07/06/2023</td>
                            <td class="text-center">15/06/2023</td>
                            <td class="text-center">04/07/2023</td>
                            <td class="text-center">-</td>
                            <td class="text-center">27 Days</td>
                            <td class="text-center">60 Days</td>
                            <td class="text-center bg-primary">30 Days</td>
                            <td class="text-center bg-danger">26 Days</td>
                            <td class="text-center">
                              <a href=""><b-button variant="primary" class="mr-1"><i class="fa fa-ship"></i></b-button></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </fieldset>
                  </b-col>
                </b-row>
              </template>
            </b-sidebar>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import {
  xray
}
from '../../config/pluginInit'
export default {
  name: 'DockingHistory',
  mounted() {
    xray.index()
  },
  data () {
    return {
      slideHistoryMonthlyReport: false,
      costDocking:[
        {
          title: 'Cost',
          bodyData: {
            chart: {
              renderTo: 'high-3d-chart',
              type: 'column',
              height: 500,
              options3d: {
                enabled: true,
                alpha: 15,
                beta: 15,
                depth: 50,
                viewDistance: 25
              }
            },
            title: {
              text: 'All Docking Cost'
            },
            xAxis: {
                type: 'datetime'
            },
            plotOptions: {
                series: {
                    pointStart: Date.UTC(2022, 0, 1),
                    pointIntervalUnit: 'month'
                }
            },
            series:
            [
              {
                name: "Realisasi",
                data: [105000000, 176000000, 125500000, 162000000, 126000000, 185000000],
                color: '#2D4A9E'
              },
              {
                name: "Plan",
                data: [125500000, 162000000, 126000000, 185000000, 105000000, 176000000],
                color: '#F7941D'
              }
            ]
          }
        },
      ],
    }
  }
}
</script>
