<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items class="col-md-4 p-0" :active="false" href="#gantt" title="GANTT" />
                <tab-nav-items class="col-md-4 p-0" :active="false" href="#finish" title="FINISH" />
                <tab-nav-items class="col-md-4 p-0" :active="true" href="#report" title="REPORT" />
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="12">
        <div class="iq-edit-list-data">
          <div id="pills-tabContent-2" class="tab-content">
            <!-- GANTT -->
            <tab-content-item :active="false" id="gantt">
              <iq-card>
                <template v-slot:headerTitle>
                    <h5 class="card-title text-primary"><strong>GANTT CHART</strong></h5>
                </template>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                      <b-img :src="require('../../assets/images/gantt.png')" fluid alt="Responsive image"></b-img>
                    </b-col>
                  </b-row>
                </template>
              </iq-card>
            </tab-content-item>
            <!-- FINISH -->
            <tab-content-item :active="false" id="finish">
              <b-row>
                <b-col sm="12">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h5 class="card-title text-primary">
                        <strong>MAINTENANCE FINISH</strong>
                      </h5>
                    </template>
                    <template v-slot:body>
                      <!-- FILTER DOCKING PROGRESS-->
                      <b-row class="mb-3">
                          <b-col md="4">
                            <v-multiselect v-model="fleetCategorySelect" tag-placeholder="Category" placeholder="Fleet Category" label="text" track-by="value" :options="fleetCategory" :multiple="true" :taggable="true">
                              </v-multiselect>
                          </b-col>
                          <b-col md="2">
                            <b-form-select plain v-model="selectedDockingType" :options="docking_type" size="sm">
                              <template v-slot:first>
                              <b-form-select-option :value="null">Select Docking Type</b-form-select-option>
                              </template>
                            </b-form-select>
                          </b-col>
                          <b-col md="3">
                            <date-picker :lang="lang" v-model="dateRange" type="date" range placeholder="Select date range" size="sm"></date-picker>
                          </b-col>
                          <b-col md="1">
                            <b-button variant="primary" size="sm"><i class="fa fa-filter"></i></b-button>
                          </b-col>
                      </b-row>
                      <!-- FILTER DOCKING PROGRESS END -->
                      <table class="table table-striped table-sm">
                        <thead>
                          <tr>
                            <th class="text-center">FLEET NAME</th>
                            <th class="text-center">TOTAL COST</th>
                            <th class="text-center">TOTAL MAINTENANCE</th>
                            <th class="text-center">PERFOMANCE INDICATOR</th>
                            <th class="text-center">CALENDAR TRIGGER</th>
                            <th class="text-center">COUNTER TRIGGER</th>
                            <th class="text-center">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="text-center">TB. TAVIA 1</td>
                            <td class="text-center">Rp. 1.238.203.200,-</td>
                            <td class="text-center">53</td>
                            <td class="text-center">
                              <b-badge variant="success" class="mr-1">On Schedule : 45</b-badge>
                              <b-badge variant="danger">Overdue : 23</b-badge>
                            </td>
                            <td class="text-center">15</td>
                            <td class="text-center">31</td>
                            <td class="text-center">
                              <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                  </span>
                                </template>
                                <b-dropdown-item href="#">
                                  <router-link to="/templates/maintenance-history">
                                    <i class="fa fa-cogs mr-2"></i>Detail Maintenance
                                  </router-link>
                                </b-dropdown-item>
                              </b-dropdown>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">TB. TAVIA 2</td>
                            <td class="text-center">Rp. 1.375.123.180,-</td>
                            <td class="text-center">36</td>
                            <td class="text-center">
                              <b-badge variant="success" class="mr-1">On Schedule : 32</b-badge>
                              <b-badge variant="danger">Overdue : 4</b-badge>
                            </td>
                            <td class="text-center">15</td>
                            <td class="text-center">12</td>
                            <td class="text-center">
                              <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                  </span>
                                </template>
                                <b-dropdown-item href="#">
                                  <router-link to="/templates/maintenance-history">
                                    <i class="fa fa-cogs mr-2"></i>Detail Maintenance
                                  </router-link>
                                </b-dropdown-item>
                              </b-dropdown>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">TB. TAVIA 3</td>
                            <td class="text-center">Rp. 1.387.384.000,-</td>
                            <td class="text-center">61</td>
                            <td class="text-center">
                              <b-badge variant="success" class="mr-1">On Schedule : 52</b-badge>
                              <b-badge variant="danger">Overdue : 20</b-badge>
                            </td>
                            <td class="text-center">21</td>
                            <td class="text-center">17</td>
                            <td class="text-center">
                              <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                  </span>
                                </template>
                                <b-dropdown-item href="#">
                                  <router-link to="/templates/maintenance-history">
                                    <i class="fa fa-cogs mr-2"></i>Detail Maintenance
                                  </router-link>
                                </b-dropdown-item>
                              </b-dropdown>
                            </td>
                          </tr>
                          <tr>
                            <td class="text-center">BG. TAVIA 4 </td>
                            <td class="text-center">Rp. 1.387.384.000,-</td>
                            <td class="text-center">72</td>
                            <td class="text-center">
                              <b-badge variant="success" class="mr-1">On Schedule : 52</b-badge>
                              <b-badge variant="danger">Overdue : 20</b-badge>
                            </td>
                            <td class="text-center">36</td>
                            <td class="text-center">21</td>
                            <td class="text-center">
                              <b-dropdown size="sm" variant="link iq-bg-primary iq-border-radius-10" toggle-class="text-decoration-none" no-caret>
                                <template v-slot:button-content>
                                  <span class="dropdown-toggle p-0" id="dropdownMenuButton5" data-toggle="dropdown">
                                    <i class="fa fa-pencil-square-o m-0 text-primary"></i>
                                  </span>
                                </template>
                                <b-dropdown-item href="#">
                                  <router-link to="/templates/maintenance-history">
                                    <i class="fa fa-cogs mr-2"></i>Detail Maintenance
                                  </router-link>
                                </b-dropdown-item>
                              </b-dropdown>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="mt-3">
                        <b-pagination :value="1" :total-rows="50" align="center" />
                      </div>
                    </template>
                  </iq-card>
                </b-col>
              </b-row>
            </tab-content-item>

            <!-- REPORT -->
            <tab-content-item :active="true" id="report">
              <b-row>
                <b-col md="12">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h5 class="card-title text-primary">
                        <strong>MAINTENANCE REPORT</strong>
                      </h5>
                    </template>
                    <template v-slot:body>
                      <!-- FILTER DOCKING PROGRESS-->
                      <b-row>
                          <b-col md="3">
                            <v-multiselect v-model="fleetCategorySelect" tag-placeholder="Category" placeholder="Fleet Category" label="text" track-by="value" :options="fleetCategory" :multiple="true" :taggable="true">
                              </v-multiselect>
                          </b-col>
                          <b-col md="3">
                            <v-multiselect v-model="fleetCategorySelect" tag-placeholder="Category" placeholder="Project Filter" label="text" track-by="value" :options="fleetCategory" :multiple="true" :taggable="true">
                              </v-multiselect>
                          </b-col>
                          <b-col md="2">
                            <b-form-select plain size="sm">
                              <template v-slot:first>
                              <b-form-select-option>Category Ownership</b-form-select-option>
                              <b-form-select-option>Chartered</b-form-select-option>
                              <b-form-select-option>Own Fleet</b-form-select-option>
                              </template>
                            </b-form-select>
                          </b-col>
                          <b-col md="3">
                            <date-picker :lang="lang" v-model="dateRange" type="date" range placeholder="Select date range" size="sm"></date-picker>
                          </b-col>
                          <b-col md="1">
                            <b-button variant="primary" size="sm"><i class="fa fa-filter"></i></b-button>
                          </b-col>
                      </b-row>
                      <!-- FILTER DOCKING PROGRESS END -->
                    </template>
                  </iq-card>
                </b-col>
                
                <b-col md="6" lg="3">
                  <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-info rounded">
                    <template v-slot:body >
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="rounded-circle iq-card-icon bg-info"><i class="fa fa-spinner"></i></div>
                        <div class="text-right">
                          <h4 class="mb-0"><span class="counter"><b>215</b></span></h4>
                          <h6 class="">Maintenance Towards</h6>
                        </div>
                      </div>
                    </template>
                  </iq-card>
                </b-col>
                <b-col md="6" lg="3">
                  <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-danger rounded">
                    <template v-slot:body>
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="rounded-circle iq-card-icon bg-danger"><i class="fas fa-exclamation-triangle"></i></div>
                        <div class="text-right">
                          <h4 class="mb-0"><span class="counter"><b>375</b></span></h4>
                          <h6 class="">Maintenance Overdue</h6>
                        </div>
                      </div>
                    </template>
                  </iq-card>
                </b-col>
                <b-col md="6" lg="3">
                  <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-warning rounded">
                    <template v-slot:body >
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="rounded-circle iq-card-icon bg-warning"><i class="fas fa-history"></i></div>
                        <div class="text-right">
                          <h4 class="mb-0"><span class="counter"><b>35</b></span></h4>
                          <h6 class="">Maintenance Postponded</h6>
                        </div>
                      </div>
                    </template>
                  </iq-card>
                </b-col>
                <b-col md="6" lg="3">
                  <iq-card class-name="iq-card-block iq-card-stretch iq-card-height" body-class="iq-bg-success rounded">
                    <template v-slot:body >
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="rounded-circle iq-card-icon bg-success"><i class="far fa-check-circle"></i></div>
                        <div class="text-right">
                          <h4 class="mb-0"><span class="counter"><b>45</b></span></h4>
                          <h6 class="">Maintenance Active</h6>
                        </div>
                      </div>
                    </template>
                  </iq-card>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="6" v-for="(item,index) in costMaintenance" :key="index">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4>{{ item.title }}</h4>
                    </template>
                    <template v-slot:body>
                      <HighChart :ref="item.type" :option="item.bodyData"/>
                    </template>
                  </iq-card>
                </b-col>
                <b-col lg="6">
                  <b-row>
                    <b-col lg="6" v-for="(item,index) in costTugboat" :key="index">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4>{{ item.title }}</h4>
                        </template>
                        <template v-slot:body>
                          <HighChart :ref="item.type" :option="item.bodyData"/>
                        </template>
                      </iq-card>
                    </b-col>
                    <b-col lg="6" v-for="(item,index) in costBarge" :key="index">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4>{{ item.title }}</h4>
                        </template>
                        <template v-slot:body>
                          <HighChart :ref="item.type" :option="item.bodyData"/>
                        </template>
                      </iq-card>
                    </b-col>
                    <b-col lg="6" v-for="(item,index) in costPusherTug" :key="index">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4>{{ item.title }}</h4>
                        </template>
                        <template v-slot:body>
                          <HighChart :ref="item.type" :option="item.bodyData"/>
                        </template>
                      </iq-card>
                    </b-col>
                    <b-col lg="6" v-for="(item,index) in costPusherBarge" :key="index">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4>{{ item.title }}</h4>
                        </template>
                        <template v-slot:body>
                          <HighChart :ref="item.type" :option="item.bodyData"/>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col lg="12" v-for="(item,index) in charts" :key="index">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4>{{ item.title }}</h4>
                    </template>
                    <template v-slot:body>
                      <ApexChart :element="item.type" :chartOption="item.bodyData"/>
                    </template>
                  </iq-card>
                </b-col>
                <b-col lg="6" v-for="(item,index) in jobType" :key="index">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4>{{ item.title }}</h4>
                    </template>
                    <template v-slot:body>
                      <HighChart :ref="item.type" :option="item.bodyData"/>
                    </template>
                  </iq-card>
                </b-col>
                <b-col lg="6" v-for="(item,index) in jobClass" :key="index">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4>{{ item.title }}</h4>
                    </template>
                    <template v-slot:body>
                      <HighChart :ref="item.type" :option="item.bodyData"/>
                    </template>
                  </iq-card>
                </b-col>
              </b-row>
            </tab-content-item>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import _ from 'lodash'
import {
  fleetsActions
} from '@src/Utils/helper'
import { createPopper } from '@popperjs/core'

export default {
  name: 'ProfileEdit',
  mounted () {
    xray.index()
    this.fetchFleets()
  },
  data () {
    return {
      charts : [
        {
          title: 'Maintenance By Equipment',
          type: 'bar',
          color: '#2D4A9E',
          bodyData: {
            chart: {
              height: 500,
              type: 'bar',
              toolbar: {
                show: false
              }
            },
            plotOptions: {
              bar: {
                horizontal: true
              }
            },
            dataLabels: {
              enabled: false
            },
            series: [{
              data: [470, 540, 580, 690, 1100, 1200, 1380, 672, 236, 1772, 476, 872, 882, 726, 265, 276, 1276]
            }],
            xaxis: {
              categories: ['Accommodation', 'Hull & Load Line', 'Tanks', 'Mooring & Anchor Equipment', 'Fire Fighting Appliance', 'Life Saving Appliance', 'Navigation Equipment', 'Radio Equipment', 'Main Engine', 'Aux Engine', 'Gearbox', 'Engine & Other Machinery', 'Propulsion & Rudder System', 'Electrical & Component Part', 'Anchor Machinery', 'Cargo Engine', 'Cargo Pump']
            }
          }
        }
      ],
      jobType:[
        {
          title: 'Maintenance By Job Type',
          bodyData: {
            chart: {
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              type: 'pie'
            },
            colorAxis: {},
            title: {
              text: 'Percentage Data By Job Type'
            },
            tooltip: {
              pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
              }
            },
            series: [{
              name: 'Brands',
              colorByPoint: true,
              data: [
                {
                  name: 'Inspection',
                  y: 61.41,
                  color: '#0084ff'
                },
                {
                  name: 'Cleaning',
                  y: 11.84,
                  color: '#00ca00'
                },
                {
                  name: 'Change',
                  y: 10.85,
                  color: '#e64141'
                },
                {
                  name: 'Merger Test',
                  y: 4.67,
                  color: '#ffd400'
                },
                {
                  name: 'Calibration',
                  y: 4.18,
                  color: '#00d0ff'
                },
                {
                  name: 'Withdraw',
                  y: 1.64,
                  color: '#374948'
                },
                {
                  name: 'Top Overhaul',
                  y: 1.6,
                  color: '#E9EDF4'
                },
                {
                  name: 'General Overhaul',
                  y: 1.2,
                  color: '#777D74'
                },
                {
                  name: 'Replacement',
                  y: 2.61,
                  color: '#ffcc00'
                }
              ]
            }]
          }
        }
      ],
      jobClass:[
        {
          title: 'Maintenance By Job Class',
          bodyData: {
            chart: {
              plotBackgroundColor: null,
              plotBorderWidth: null,
              plotShadow: false,
              type: 'pie'
            },
            colorAxis: {},
            title: {
              text: 'Percentage Data By Job Class'
            },
            tooltip: {
              pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
              }
            },
            series: [{
              name: 'Brands',
              colorByPoint: true,
              data: [
                {
                  name: 'Preventive',
                  y: 61.41,
                  color: '#0084ff'
                },
                {
                  name: 'Corrective',
                  y: 11.84,
                  color: '#00ca00'
                },
                {
                  name: 'Cleaning',
                  y: 10.85,
                  color: '#e64141'
                },
                {
                  name: 'Setting',
                  y: 4.67,
                  color: '#ffd400'
                },
                {
                  name: 'Part Replace',
                  y: 4.18,
                  color: '#00d0ff'
                },
                {
                  name: 'Unit Replace',
                  y: 2.25,
                  color: '#374948'
                },
                {
                  name: 'Service Repair',
                  y: 2.8,
                  color: '#E9EDF4'
                }
              ]
            }]
          }
        }
      ],
      costMaintenance:[
        {
          title: 'Cost Maintenance All Fleet',
          bodyData: {
            chart: {
              renderTo: 'high-3d-chart',
              type: 'column',
              height: 500,
              options3d: {
                enabled: true,
                alpha: 15,
                beta: 15,
                depth: 50,
                viewDistance: 25
              }
            },
            title: {
              text: 'Cost All Fleet'
            },
            xAxis: {
                type: 'datetime'
            },
            plotOptions: {
                series: {
                    pointStart: Date.UTC(2022, 0, 1),
                    pointIntervalUnit: 'month'
                }
            },
            series:
            [
              {
                name: "All Fleet",
                data: [105000000, 176000000, 125500000, 162000000, 126000000, 185000000],
                color: '#2D4A9E'
              }
            ]
          }
        },
      ],
      costTugboat:
      [
        {
          title: 'Cost Tugboat',
          bodyData: {
            chart: {
              renderTo: 'high-3d-chart',
              type: 'column',
              height: 180,
              options3d: {
                enabled: true,
                alpha: 15,
                beta: 15,
                depth: 50,
                viewDistance: 25
              }
            },
            title: {
              text: 'Tugboat'
            },
            xAxis: {
                type: 'datetime'
            },
            plotOptions: {
                series: {
                    pointStart: Date.UTC(2022, 0, 1),
                    pointIntervalUnit: 'month'
                }
            },
            series:
            [
              {
                name: "Tugboat",
                data: [165200000, 126000000, 176000000, 124000000, 127000000, 128800000],
                color: '#2D4A9E'
              }
            ]
          }
        },
      ],
      costBarge:
      [
        {
          title: 'Cost Barge',
          bodyData: {
            chart: {
              renderTo: 'high-3d-chart',
              type: 'column',
              height: 180,
              options3d: {
                enabled: true,
                alpha: 15,
                beta: 15,
                depth: 50,
                viewDistance: 25
              }
            },
            title: {
              text: 'Barge'
            },
            xAxis: {
                type: 'datetime'
            },
            plotOptions: {
                series: {
                    pointStart: Date.UTC(2022, 0, 1),
                    pointIntervalUnit: 'month'
                }
            },
            series:
            [
              {
                name: "Barge",
                data: [176000000, 124000000, 127000000, 165200000, 126000000, 128800000],
                color: '#F26822'
              }
            ]
          }
        },
      ],
      costPusherTug:
      [
        {
          title: 'Cost Pusher Tug',
          bodyData: {
            chart: {
              renderTo: 'high-3d-chart',
              type: 'column',
              height: 180,
              options3d: {
                enabled: true,
                alpha: 15,
                beta: 15,
                depth: 50,
                viewDistance: 25
              }
            },
            title: {
              text: 'Pusher Tug'
            },
            xAxis: {
                type: 'datetime'
            },
            plotOptions: {
                series: {
                    pointStart: Date.UTC(2022, 0, 1),
                    pointIntervalUnit: 'month'
                }
            },
            series:
            [
              {
                name: "Pusher Tug",
                data: [165200000, 126000000, 176000000, 124000000, 127000000, 128800000],
                color: '#27AAE1'
              }
            ]
          }
        },
      ],
      costPusherBarge:
      [
        {
          title: 'Cost Pusher Barge',
          bodyData: {
            chart: {
              renderTo: 'high-3d-chart',
              type: 'column',
              height: 180,
              options3d: {
                enabled: true,
                alpha: 15,
                beta: 15,
                depth: 50,
                viewDistance: 25
              }
            },
            title: {
              text: 'Pusher Barge'
            },
            xAxis: {
                type: 'datetime'
            },
            plotOptions: {
                series: {
                    pointStart: Date.UTC(2022, 0, 1),
                    pointIntervalUnit: 'month'
                }
            },
            series:
            [
              {
                name: "Pusher Barge",
                data: [126000000, 176000000, 165200000, 124000000, 127000000, 128800000],
                color: '#009444'
              }
            ]
          }
        },
      ],

    }
  }
}
</script>