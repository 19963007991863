var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('form', {
    attrs: {
      "action": "#"
    },
    on: {
      "submit": _vm.onSaveForms
    }
  }, [_vm.edit_loading ? _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-center"
  }, [_c('i', {
    staticClass: "spinner-border spinner-border-lg text-primary mr-2"
  }), _c('h5', {
    staticClass: "text-primary"
  }, [_vm._v("Fetching forms, please wait a moment.")])])])], 1) : _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end w-100"
  }, [_c('b-button', {
    staticClass: "mr-4",
    attrs: {
      "variant": "danger",
      "type": "button"
    },
    on: {
      "click": _vm.closeModalForm
    }
  }, [_c('i', {
    staticClass: "fa fa-remove"
  }), _c('span', [_vm._v("Cancel")])]), _c('b-button', {
    attrs: {
      "disabled": _vm.saving,
      "variant": "primary",
      "type": "submit"
    }
  }, [_vm.saving ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _c('div', [_c('i', {
    staticClass: "fa fa-save"
  }), _c('span', [_vm._v("Submit Data")])])])], 1), _c('hr')]), _c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "lg": "6"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Code",
      "label-for": "code"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "code",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.forms.code,
      callback: function callback($$v) {
        _vm.$set(_vm.forms, "code", $$v);
      },
      expression: "forms.code"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Name",
      "label-for": "name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "name",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.forms.name,
      callback: function callback($$v) {
        _vm.$set(_vm.forms, "name", $$v);
      },
      expression: "forms.name"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Title",
      "label-for": "title"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "title",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.forms.title,
      callback: function callback($$v) {
        _vm.$set(_vm.forms, "title", $$v);
      },
      expression: "forms.title"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Desciption",
      "label-for": "description"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "description",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.forms.description,
      callback: function callback($$v) {
        _vm.$set(_vm.forms, "description", $$v);
      },
      expression: "forms.description"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status",
      "label-for": "status"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": ""
    },
    model: {
      value: _vm.forms.status,
      callback: function callback($$v) {
        _vm.$set(_vm.forms, "status", $$v);
      },
      expression: "forms.status"
    }
  }, [_vm._v("Active / Inactive ")])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Using Remarks",
      "label-for": "using_remarks"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": ""
    },
    model: {
      value: _vm.forms.using_remarks,
      callback: function callback($$v) {
        _vm.$set(_vm.forms, "using_remarks", $$v);
      },
      expression: "forms.using_remarks"
    }
  }, [_vm._v("Yes / No ")])], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('strong', [_vm._v("Field")])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, _vm._l(_vm.forms.field, function (state, index) {
    return _c('div', {
      key: index,
      staticClass: "d-flex flex-column align-items-center"
    }, [_c('div', {
      staticClass: "d-flex align-items-center w-100"
    }, [_c('b-form-group', {
      staticClass: "mr-2 w-50",
      attrs: {
        "label": 'Label #' + (index + 1),
        "label-for": "name"
      }
    }, [_c('b-form-input', {
      attrs: {
        "id": "name",
        "type": "text",
        "placeholder": "Type text",
        "required": ""
      },
      model: {
        value: state.label,
        callback: function callback($$v) {
          _vm.$set(state, "label", $$v);
        },
        expression: "state.label"
      }
    })], 1), _c('b-form-group', {
      staticClass: "mr-4 w-50",
      attrs: {
        "label": 'Type Data #' + (index + 1),
        "label-for": "name"
      }
    }, [_c('v-select', {
      attrs: {
        "label": "text",
        "options": _vm.listTypeData,
        "reduce": function reduce(type) {
          return type.value;
        },
        "required": ""
      },
      model: {
        value: state.type,
        callback: function callback($$v) {
          _vm.$set(state, "type", $$v);
        },
        expression: "state.type"
      }
    })], 1), _c('i', {
      staticClass: "fa fa-trash text-danger mt-2",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click() {
          _vm.forms.field.splice(index, 1);
        }
      }
    })], 1), _c('b-form-group', {
      staticClass: "align-self-end"
    }, [_c('b-form-checkbox', {
      attrs: {
        "name": "check-button",
        "switch": ""
      },
      model: {
        value: state.required,
        callback: function callback($$v) {
          _vm.$set(state, "required", $$v);
        },
        expression: "state.required"
      }
    }, [_vm._v("is Required? ")])], 1)], 1);
  }), 0), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end w-100"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.addField
    }
  }, [_c('span', [_vm._v("Add a line")])])], 1)])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('strong', [_vm._v("Signature")])]), _vm._l(_vm.forms.signature, function (state, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "md": "12"
      }
    }, [_c('div', {
      staticClass: "d-flex flex-column align-items-center"
    }, [_c('div', {
      staticClass: "d-flex align-items-center w-100"
    }, [_c('b-form-group', {
      staticClass: "mr-2 w-50",
      attrs: {
        "label": 'Signature Label #' + (index + 1),
        "label-for": "name"
      }
    }, [_c('b-form-input', {
      attrs: {
        "id": "name",
        "type": "text",
        "placeholder": "Type text",
        "required": ""
      },
      model: {
        value: state.label,
        callback: function callback($$v) {
          _vm.$set(state, "label", $$v);
        },
        expression: "state.label"
      }
    })], 1), _c('b-form-group', {
      staticClass: "mr-4 w-50",
      attrs: {
        "label": 'Signature Type Data #' + (index + 1),
        "label-for": "name"
      }
    }, [_c('v-select', {
      attrs: {
        "label": "text",
        "options": _vm.listTypeData,
        "reduce": function reduce(type) {
          return type.value;
        },
        "required": ""
      },
      model: {
        value: state.type,
        callback: function callback($$v) {
          _vm.$set(state, "type", $$v);
        },
        expression: "state.type"
      }
    })], 1), _c('i', {
      staticClass: "fa fa-trash text-danger mt-2",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click() {
          _vm.forms.signature.splice(index, 1);
        }
      }
    })], 1), _c('b-form-group', {
      staticClass: "align-self-end"
    }, [_c('b-form-checkbox', {
      attrs: {
        "name": "check-button",
        "switch": ""
      },
      model: {
        value: state.required,
        callback: function callback($$v) {
          _vm.$set(state, "required", $$v);
        },
        expression: "state.required"
      }
    }, [_vm._v("is Required? ")])], 1)], 1)]);
  }), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end w-100"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.addSignature
    }
  }, [_c('span', [_vm._v("Add a line")])])], 1)])], 2), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('strong', [_vm._v("Question Type")])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, _vm._l(_vm.listQuestionsType, function (state, index) {
    return _c('div', {
      key: index,
      staticClass: "custom-control custom-control-inline custom-radio"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.forms.question_type_id,
        expression: "forms.question_type_id"
      }],
      staticClass: "custom-control-input",
      attrs: {
        "type": "radio",
        "name": "question_type_id",
        "id": state.code
      },
      domProps: {
        "value": state.code,
        "checked": _vm._q(_vm.forms.question_type_id, state.code)
      },
      on: {
        "change": function change($event) {
          return _vm.$set(_vm.forms, "question_type_id", state.code);
        }
      }
    }), _c('label', {
      staticClass: "custom-control-label",
      attrs: {
        "for": state.code
      }
    }, [_vm._v(_vm._s(state.name))])]);
  }), 0)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_vm.forms.question_type_id === 'multiple-lines-text-box' ? _c('b-row', [_vm._l(_vm.forms.question_type_content, function (state, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "md": "12"
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('b-form-group', {
      staticClass: "mr-4 w-100",
      attrs: {
        "label": 'Text Box #' + (index + 1),
        "label-for": "name"
      }
    }, [_c('b-form-input', {
      attrs: {
        "id": "name",
        "type": "text",
        "placeholder": "Type text"
      },
      model: {
        value: state.label,
        callback: function callback($$v) {
          _vm.$set(state, "label", $$v);
        },
        expression: "state.label"
      }
    })], 1), _c('i', {
      staticClass: "fa fa-trash text-danger mt-2",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click() {
          _vm.forms.question_type_content.splice(index, 1);
        }
      }
    })], 1)]);
  }), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end w-100"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.addQuestionType
    }
  }, [_c('span', [_vm._v("Add a line")])])], 1)])], 2) : _vm.forms.question_type_id === 'single-lines-text-box' ? _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Label"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "text",
      "type": "text",
      "placeholder": "Type Text",
      "required": ""
    },
    model: {
      value: _vm.forms.question_type_content.label,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.question_type_content, "label", $$v);
      },
      expression: "forms.question_type_content.label"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Input must be an Email"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "value": true,
      "unchecked-value": false
    },
    model: {
      value: _vm.forms.question_type_content.input_must_be_email,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.question_type_content, "input_must_be_email", $$v);
      },
      expression: "forms.question_type_content.input_must_be_email"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Save as user email"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "value": true,
      "unchecked-value": false
    },
    model: {
      value: _vm.forms.question_type_content.save_as_user_email,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.question_type_content, "save_as_user_email", $$v);
      },
      expression: "forms.question_type_content.save_as_user_email"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Save as user nickname"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "value": true,
      "unchecked-value": false
    },
    model: {
      value: _vm.forms.question_type_content.save_as_user_nickname,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.question_type_content, "save_as_user_nickname", $$v);
      },
      expression: "forms.question_type_content.save_as_user_nickname"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Validate entry"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "value": true,
      "unchecked-value": false
    },
    model: {
      value: _vm.forms.question_type_content.validate_entry,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.question_type_content, "validate_entry", $$v);
      },
      expression: "forms.question_type_content.validate_entry"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Minumum Text Length",
      "label-for": "name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "name",
      "type": "number",
      "placeholder": "0"
    },
    model: {
      value: _vm.forms.question_type_content.minimum_text_length,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.question_type_content, "minimum_text_length", $$v);
      },
      expression: "forms.question_type_content.minimum_text_length"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Maximum Text Length",
      "label-for": "name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "name",
      "type": "number",
      "placeholder": "0"
    },
    model: {
      value: _vm.forms.question_type_content.maximum_text_length,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.question_type_content, "maximum_text_length", $$v);
      },
      expression: "forms.question_type_content.maximum_text_length"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Validation Error Message",
      "label-for": "name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "text",
      "type": "text",
      "placeholder": "Ext: The answer you entered is not valid"
    },
    model: {
      value: _vm.forms.question_type_content.validation_error_message,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.question_type_content, "validation_error_message", $$v);
      },
      expression: "forms.question_type_content.validation_error_message"
    }
  })], 1)], 1)], 1) : _vm.forms.question_type_id === 'numerical-value' ? _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Label"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "text",
      "type": "text",
      "placeholder": "Type Text",
      "required": ""
    },
    model: {
      value: _vm.forms.question_type_content.label,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.question_type_content, "label", $$v);
      },
      expression: "forms.question_type_content.label"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "status-job",
      "label": "Validate entry"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "value": true,
      "unchecked-value": false
    },
    model: {
      value: _vm.forms.question_type_content.validate_entry,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.question_type_content, "validate_entry", $$v);
      },
      expression: "forms.question_type_content.validate_entry"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Minumum Value",
      "label-for": "name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "name",
      "type": "number",
      "placeholder": "0"
    },
    model: {
      value: _vm.forms.question_type_content.minimum_value,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.question_type_content, "minimum_value", $$v);
      },
      expression: "forms.question_type_content.minimum_value"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Maximum Value",
      "label-for": "name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "name",
      "type": "number",
      "placeholder": "0"
    },
    model: {
      value: _vm.forms.question_type_content.maximum_value,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.question_type_content, "maximum_value", $$v);
      },
      expression: "forms.question_type_content.maximum_value"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Validation Error Message",
      "label-for": "name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "text",
      "type": "text",
      "placeholder": "Ext: The answer you entered is not valid"
    },
    model: {
      value: _vm.forms.question_type_content.validation_error_message,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.question_type_content, "validation_error_message", $$v);
      },
      expression: "forms.question_type_content.validation_error_message"
    }
  })], 1)], 1)], 1) : _vm.forms.question_type_id === 'date' ? _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Label"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "text",
      "type": "text",
      "placeholder": "Type Text",
      "required": ""
    },
    model: {
      value: _vm.forms.question_type_content.label,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.question_type_content, "label", $$v);
      },
      expression: "forms.question_type_content.label"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "status-job",
      "label": "Label"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "value": true,
      "unchecked-value": false
    },
    model: {
      value: _vm.forms.question_type_content.validate_entry,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.question_type_content, "validate_entry", $$v);
      },
      expression: "forms.question_type_content.validate_entry"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "status-job",
      "label": "Validate entry"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "value": true,
      "unchecked-value": false
    },
    model: {
      value: _vm.forms.question_type_content.validate_entry,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.question_type_content, "validate_entry", $$v);
      },
      expression: "forms.question_type_content.validate_entry"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "status-job",
      "label": "Minimum Date"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.forms.question_type_content.minimum_date,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.question_type_content, "minimum_date", $$v);
      },
      expression: "forms.question_type_content.minimum_date"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "status-job",
      "label": "Maximum Date"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date"
    },
    model: {
      value: _vm.forms.question_type_content.maximum_date,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.question_type_content, "maximum_date", $$v);
      },
      expression: "forms.question_type_content.maximum_date"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Validation Error Message",
      "label-for": "name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "text",
      "type": "text",
      "placeholder": "Ext: The answer you entered is not valid"
    },
    model: {
      value: _vm.forms.question_type_content.validation_error_message,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.question_type_content, "validation_error_message", $$v);
      },
      expression: "forms.question_type_content.validation_error_message"
    }
  })], 1)], 1)], 1) : _vm.forms.question_type_id === 'datetime' ? _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Label"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "text",
      "type": "text",
      "placeholder": "Type Text",
      "required": ""
    },
    model: {
      value: _vm.forms.question_type_content.label,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.question_type_content, "label", $$v);
      },
      expression: "forms.question_type_content.label"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "status-job",
      "label": "Validate entry"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "value": true,
      "unchecked-value": false
    },
    model: {
      value: _vm.forms.question_type_content.validate_entry,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.question_type_content, "validate_entry", $$v);
      },
      expression: "forms.question_type_content.validate_entry"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "status-job",
      "label": "Minimum Date"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "datetime-local"
    },
    model: {
      value: _vm.forms.question_type_content.minimum_datetime,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.question_type_content, "minimum_datetime", $$v);
      },
      expression: "forms.question_type_content.minimum_datetime"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "status-job",
      "label": "Maximum Date"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "datetime-local"
    },
    model: {
      value: _vm.forms.question_type_content.maximum_datetime,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.question_type_content, "maximum_datetime", $$v);
      },
      expression: "forms.question_type_content.maximum_datetime"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Validation Error Message",
      "label-for": "name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "text",
      "type": "text",
      "placeholder": "Ext: The answer you entered is not valid"
    },
    model: {
      value: _vm.forms.question_type_content.validation_error_message,
      callback: function callback($$v) {
        _vm.$set(_vm.forms.question_type_content, "validation_error_message", $$v);
      },
      expression: "forms.question_type_content.validation_error_message"
    }
  })], 1)], 1)], 1) : _vm.forms.question_type_id === 'multiple-choice-only-one-answer' ? _c('b-row', [_vm._l(_vm.forms.question_type_content, function (state, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "md": "12"
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('b-form-group', {
      staticClass: "mr-4 w-100",
      attrs: {
        "label": 'Radio Label #' + (index + 1),
        "label-for": "name"
      }
    }, [_c('b-form-input', {
      attrs: {
        "id": "name",
        "type": "text",
        "placeholder": "Type text"
      },
      model: {
        value: state.label,
        callback: function callback($$v) {
          _vm.$set(state, "label", $$v);
        },
        expression: "state.label"
      }
    })], 1), _c('i', {
      staticClass: "fa fa-trash text-danger mt-2",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click() {
          _vm.forms.question_type_content.splice(index, 1);
        }
      }
    })], 1)]);
  }), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end w-100"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.addQuestionType
    }
  }, [_c('span', [_vm._v("Add a line")])])], 1)])], 2) : _vm.forms.question_type_id === 'multiple-choice-multiple-answers-allowed' ? _c('b-row', [_vm._l(_vm.forms.question_type_content, function (state, index) {
    return _c('b-col', {
      key: index,
      attrs: {
        "md": "12"
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('b-form-group', {
      staticClass: "mr-4 w-100",
      attrs: {
        "label": 'Checkbox Label #' + (index + 1),
        "label-for": "name"
      }
    }, [_c('b-form-input', {
      attrs: {
        "id": "name",
        "type": "text",
        "placeholder": "Type text"
      },
      model: {
        value: state.label,
        callback: function callback($$v) {
          _vm.$set(state, "label", $$v);
        },
        expression: "state.label"
      }
    })], 1), _c('i', {
      staticClass: "fa fa-trash text-danger mt-2",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function click() {
          _vm.forms.question_type_content.splice(index, 1);
        }
      }
    })], 1)]);
  }), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end w-100"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.addQuestionType
    }
  }, [_c('span', [_vm._v("Add a line")])])], 1)])], 2) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "border my-2 bg-muted"
  })]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Form for Vehicle / Vehicle Type?",
      "label-for": "form_for_vehicle"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": ""
    },
    model: {
      value: _vm.forms.form_for_vehicle,
      callback: function callback($$v) {
        _vm.$set(_vm.forms, "form_for_vehicle", $$v);
      },
      expression: "forms.form_for_vehicle"
    }
  }, [_vm._v(" Yes / No ")])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Default for Shipment Planning",
      "label-for": "default_for_shipment_planning"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": ""
    },
    model: {
      value: _vm.forms.default_for_shipment_planning,
      callback: function callback($$v) {
        _vm.$set(_vm.forms, "default_for_shipment_planning", $$v);
      },
      expression: "forms.default_for_shipment_planning"
    }
  }, [_vm._v(" Yes / No ")])], 1)], 1)], 1), _vm.forms.form_for_vehicle ? _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "* Vehicle Type Section",
      "label-for": "company_id"
    }
  }, [_c('b-form-select', {
    attrs: {
      "required": "",
      "plain": "",
      "size": "sm",
      "options": _vm.listVehicleTypeMasterSection
    },
    on: {
      "input": _vm.onSelectedVehicleTypeSection
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v("Select Vehicle Type Section")])];
      },
      proxy: true
    }], null, false, 2353843487),
    model: {
      value: _vm.vehicle_type_m_section_id,
      callback: function callback($$v) {
        _vm.vehicle_type_m_section_id = $$v;
      },
      expression: "vehicle_type_m_section_id"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_vm.listQuestionsTable.length > 0 && _vm.forms.form_for_vehicle ? _c('div', {
    staticClass: "table-responsive"
  }, _vm._l(_vm.listQuestionsTable, function (parentQuestion, parentQuestionIndex) {
    var _parentQuestion$inspe, _parentQuestion$inspe2;
    return _c('table', {
      key: parentQuestionIndex,
      staticClass: "table table-striped"
    }, [_c('thead', [!parentQuestion.parent_id && (parentQuestion === null || parentQuestion === void 0 ? void 0 : (_parentQuestion$inspe = parentQuestion.inspection_checklist_question) === null || _parentQuestion$inspe === void 0 ? void 0 : _parentQuestion$inspe.length) <= 0 ? _c('tr', [_c('th', {
      staticClass: "align-middle text-center",
      attrs: {
        "colspan": "6"
      }
    }, [_vm._v(_vm._s(parentQuestion === null || parentQuestion === void 0 ? void 0 : parentQuestion.name))])]) : _c('tr', [_c('th', {
      staticStyle: {
        "width": "5%"
      }
    }, [_vm._v("No")]), _c('th', {
      staticStyle: {
        "width": "50%"
      }
    }, [_vm._v(_vm._s(parentQuestion === null || parentQuestion === void 0 ? void 0 : parentQuestion.name))]), _c('th', {
      staticStyle: {
        "width": "35%"
      }
    }, [_vm._v("Alias")]), _c('th', {
      staticStyle: {
        "width": "10%"
      }
    }, [_vm._v("Status")]), _c('th', {
      staticClass: "text-right",
      staticStyle: {
        "width": "5%"
      }
    }, [_vm._v("#")])])]), (parentQuestion === null || parentQuestion === void 0 ? void 0 : (_parentQuestion$inspe2 = parentQuestion.inspection_checklist_question) === null || _parentQuestion$inspe2 === void 0 ? void 0 : _parentQuestion$inspe2.length) > 0 ? _c('tbody', [_vm._l(parentQuestion === null || parentQuestion === void 0 ? void 0 : parentQuestion.inspection_checklist_question, function (question, questionIndex) {
      return _c('tr', {
        key: questionIndex
      }, [_c('td', [_vm._v(_vm._s(questionIndex + 1))]), question.edit ? _c('td', [_c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: question.question,
          expression: "question.question"
        }],
        staticClass: "form-control",
        attrs: {
          "type": "text",
          "name": "question"
        },
        domProps: {
          "value": question.question
        },
        on: {
          "input": function input($event) {
            if ($event.target.composing) return;
            _vm.$set(question, "question", $event.target.value);
          }
        }
      })]) : _c('td', [_vm._v(_vm._s(question.question))]), question.edit ? _c('td', [_c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: question.alias,
          expression: "question.alias"
        }],
        staticClass: "form-control",
        attrs: {
          "type": "text",
          "name": "alias"
        },
        domProps: {
          "value": question.alias
        },
        on: {
          "input": function input($event) {
            if ($event.target.composing) return;
            _vm.$set(question, "alias", $event.target.value);
          }
        }
      })]) : _c('td', [_vm._v(_vm._s(question.alias))]), _c('td', {
        class: question.status ? 'text-success' : 'text-danger'
      }, [_vm._v(_vm._s(question.status ? 'Active' : 'Inactive'))]), _c('td', {
        staticClass: "text-right d-flex flex-column"
      }, [_c('b-button', {
        staticClass: "mb-2",
        attrs: {
          "variant": "success",
          "size": "sm"
        },
        on: {
          "click": function click($event) {
            return _vm.onEditQuestion(parentQuestionIndex, questionIndex);
          }
        }
      }, [_c('i', {
        class: question.edit ? 'fa fa-check' : 'fa fa-edit'
      })]), _c('b-button', {
        attrs: {
          "variant": "danger",
          "size": "sm"
        },
        on: {
          "click": function click($event) {
            return _vm.onDeleteQuestion(parentQuestionIndex, questionIndex);
          }
        }
      }, [_c('i', {
        staticClass: "fa fa-trash"
      })])], 1)]);
    }), _c('tr', [_c('td', {
      attrs: {
        "colspan": "5"
      }
    }, [_c('b-button', {
      attrs: {
        "variant": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.onAddQuestion(parentQuestionIndex);
        }
      }
    }, [_vm._v("Add Question +")])], 1)])], 2) : _vm._e()]);
  }), 0) : _vm._e(), _vm.listQuestionsTable.length <= 0 && _vm.forms.form_for_vehicle ? _c('div', {
    staticClass: "d-flex"
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v("Choose Vehicle Type Section First.")])]) : _vm._e()])], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }