var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('form', {
    attrs: {
      "action": "#"
    },
    on: {
      "submit": _vm.onSave
    }
  }, [_c('b-row', [!_vm.previewData ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end w-100"
  }, [_c('b-button', {
    attrs: {
      "disabled": _vm.saving,
      "variant": "primary",
      "type": "submit"
    }
  }, [_vm.saving ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _c('div', [_c('i', {
    staticClass: "fa fa-save"
  }), _c('span', [_vm._v("Submit Data")])])])], 1)]) : _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end w-100"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "button"
    },
    on: {
      "click": function click($event) {
        _vm.$nextTick(function () {
          return _vm.$bvModal.hide('form-inventory');
        });
      }
    }
  }, [_c('div', [_c('i', {
    staticClass: "fa fa-check"
  }), _c('span', [_vm._v("Select Spesification")])])])], 1)]), _c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "lg": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "*Code",
      "label-for": "code"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.form.code))]) : _c('b-form-input', {
    attrs: {
      "id": "code",
      "type": "text",
      "placeholder": "Type text",
      "required": ""
    },
    model: {
      value: _vm.form.code,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "code", $$v);
      },
      expression: "form.code"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "*Name",
      "label-for": "name"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.form.name))]) : _c('b-form-input', {
    attrs: {
      "id": "name",
      "type": "text",
      "placeholder": "Type text",
      "required": ""
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Description",
      "label-for": "description"
    }
  }, [_vm.previewData ? _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v(_vm._s(_vm.form.description))]) : _c('b-form-textarea', {
    attrs: {
      "id": "description",
      "type": "text",
      "placeholder": "Type text"
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status",
      "label-for": "status"
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": ""
    },
    model: {
      value: _vm.form.active,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "active", $$v);
      },
      expression: "form.active"
    }
  }, [_vm._v("Active / Inactive ")])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "For Fleet Type",
      "label-for": "vehicleType"
    }
  }, [_c('v-multiselect', {
    attrs: {
      "placeholder": "Search Fleet Type",
      "label": "name",
      "track-by": "id",
      "options": _vm.optionsFleetType,
      "multiple": true
    },
    model: {
      value: _vm.form.vehicleType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "vehicleType", $$v);
      },
      expression: "form.vehicleType"
    }
  }, [_c('span', {
    attrs: {
      "slot": "noResult"
    },
    slot: "noResult"
  }, [_vm._v("Fleet type not found.")])]), _c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.selectAll,
      callback: function callback($$v) {
        _vm.selectAll = $$v;
      },
      expression: "selectAll"
    }
  }, [_vm._v(" Select All Fleet Type ")])], 1)], 1)], 1)], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }