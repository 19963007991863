<template>
  <div md="6" xl="3" class="w-100" style="margin-bottom: 2rem">
    <div
      class="d-flex align-items-center bg-white p-3 shadow-sm card-certificate h-full cursor-pointer"
    >
      <div class="card-icon mr-2" :style="`background: ${bgIcon} ;`">
        <slot name="icon" />
      </div>
      <div class="text-left">
        <h5 class="line-height mb-1"
          ><strong>{{ total ?? '-' }}</strong></h5
        >
        <h5 class="">
          {{ title }}
        </h5>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    total: {
        type:String | Number,
        default:''
    },
    title: {
        type:String,
        default:''
    },
    bgIcon:  {
        type:String,
        default:''
    },
  },
}
</script>
<style scoped>
.card-certificate {
  border-radius: 1rem;
  transition: all;
  transition-duration:250ms;
}
.card-certificate:hover {
  scale: 1.05;
}
.card-icon {
  border-radius: 1rem;
  font-size: 2rem;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
