var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('div', {
    staticClass: "d-flex justify-content-end py-3"
  }, [_c('b-dropdown', {
    staticClass: "mr-2",
    attrs: {
      "text": "Filter Column",
      "variant": "primary"
    }
  }, [_c('div', {
    staticClass: "p-3"
  }, [_vm._l(_vm.columns, function (col, i) {
    return [i > 2 ? _c('b-form-checkbox', {
      key: i,
      staticClass: "text-truncate",
      attrs: {
        "id": "".concat(i)
      },
      model: {
        value: col.value,
        callback: function callback($$v) {
          _vm.$set(col, "value", $$v);
        },
        expression: "col.value"
      }
    }, [_vm._v(_vm._s(col.label))]) : _vm._e()];
  })], 2)]), [_vm._t("table-action")]], 2), _c('div', {
    staticClass: "d-flex overflow-auto relative"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_vm._l(_vm.columns, function (col, index) {
    return [col.value ? _c('th', {
      key: index,
      staticClass: "text-left text-truncate text-white",
      class: {
        'sticky-column': index < 3,
        'left-3': index == 1,
        'left-26': index == 2
      },
      staticStyle: {
        "background": "#049fb0"
      }
    }, [_vm._v(_vm._s(col.label))]) : _vm._e()];
  })], 2)]), _c('tbody', _vm._l(_vm.tableData.body, function (data, index) {
    var _data$personal_data, _data$personal_data$s, _data$personal_data2, _data$vehicle$name, _data$vehicle, _data$company$company, _data$company, _data$position$name, _data$position, _data$status_emp_crew, _data$status_emp_crew2, _data$status_emp_crew3;
    return _c('tr', {
      key: index,
      style: "background:".concat(index % 2 != 0 ? 'white' : '#f5f5f5', ";left:3rem")
    }, [_vm.columnValue('No') ? _c('td', {
      staticClass: "text-center sticky-column",
      style: "background:".concat(index % 2 != 0 ? 'white' : '#f5f5f5')
    }, [_vm._v(_vm._s(_vm.indexes + index))]) : _vm._e(), _c('td', {
      staticClass: "text-truncate sticky-column",
      style: "background:".concat(index % 2 != 0 ? 'white' : '#f5f5f5', ";left:2.7rem")
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('b-avatar', {
      attrs: {
        "size": "34",
        "variant": "warning",
        "src": (_data$personal_data = data.personal_data) === null || _data$personal_data === void 0 ? void 0 : _data$personal_data.photo_url
      }
    }), _c('h6', {
      staticClass: "ml-2"
    }, [_c('strong', [_vm._v(_vm._s(data === null || data === void 0 ? void 0 : data.fullname) + " - " + _vm._s(data === null || data === void 0 ? void 0 : data.nip))])])], 1)]), !_vm.isPreview && _vm.columnValue('ACTIONS') ? _c('td', {
      staticClass: "sticky-column left-26",
      style: "background:".concat(index % 2 != 0 ? 'white' : '#f5f5f5')
    }, [_c('b-dropdown', {
      attrs: {
        "size": "sm",
        "variant": "dark",
        "toggle-class": "text-decoration-none",
        "text": "Actions",
        "dropright": "",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function fn() {
          return [_vm._v(" Actions "), _c('i', {
            staticClass: "fa fa-chevron-down ml-1"
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle.swap-position",
        modifiers: {
          "swap-position": true
        }
      }],
      attrs: {
        "href": "#",
        "variant": "primary"
      },
      on: {
        "click": function click() {
          return _vm.setDataUser(data);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-arrow-circle-down mr-2"
    }), _vm._v("Swap Position")]), _c('b-dropdown-item', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal.notice_sign_off",
        modifiers: {
          "notice_sign_off": true
        }
      }],
      attrs: {
        "href": "#",
        "variant": "primary"
      },
      on: {
        "click": function click() {
          return _vm.setDataUser(data);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-arrow-circle-down mr-2"
    }), _vm._v("Notice Sign Off ")]), _c('b-dropdown-item', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle.sign-off",
        modifiers: {
          "sign-off": true
        }
      }],
      attrs: {
        "href": "#",
        "variant": "primary"
      },
      on: {
        "click": function click() {
          return _vm.setDataUser(data);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-arrow-circle-down mr-2"
    }), _vm._v("Sign Off")]), !data.status_condite_time ? _c('b-dropdown-item', [_c('router-link', {
      attrs: {
        "to": {
          path: (data === null || data === void 0 ? void 0 : data.position_id) === 1 || (data === null || data === void 0 ? void 0 : data.position_id) === 2 ? "/crewing/condite/".concat(data.id) : "/crewing/conduct/".concat(data.id),
          params: {
            id: data.id
          }
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-arrow-circle-down mr-2"
    }), _vm._v("Penilaian Conduite")])], 1) : _vm._e(), _c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": "primary"
      },
      on: {
        "click": function click() {
          return _vm.handleDownloadPKL(data.id, data.fullname);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-file mr-2"
    }), _vm._v("Download Surat PKL")]), _c('b-dropdown-divider'), _c('b-dropdown-item', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle.blacklist-crew",
        modifiers: {
          "blacklist-crew": true
        }
      }],
      attrs: {
        "href": "#",
        "variant": "danger"
      },
      on: {
        "click": function click() {
          return _vm.setDataUser(data);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-ban mr-2"
    }), _vm._v("Black List")]), _c('b-dropdown-item', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal.delete-crew",
        modifiers: {
          "delete-crew": true
        }
      }],
      attrs: {
        "href": "#",
        "variant": "danger"
      },
      on: {
        "click": function click() {
          return _vm.setDataUser(data);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-user mr-2"
    }), _vm._v("Delete Crew")])], 1)], 1) : _vm._e(), _vm.columnValue('SEAFERER CODE') ? _c('td', {
      staticClass: "text-truncate text-left"
    }, [_vm._v(_vm._s((_data$personal_data$s = data === null || data === void 0 ? void 0 : (_data$personal_data2 = data.personal_data) === null || _data$personal_data2 === void 0 ? void 0 : _data$personal_data2.seaferer_code) !== null && _data$personal_data$s !== void 0 ? _data$personal_data$s : '-'))]) : _vm._e(), _vm.columnValue('FLEET NAME') ? _c('td', {
      staticClass: "text-left text-truncate",
      staticStyle: {
        "text-align": "left !important"
      }
    }, [_vm._v(_vm._s((_data$vehicle$name = data === null || data === void 0 ? void 0 : (_data$vehicle = data.vehicle) === null || _data$vehicle === void 0 ? void 0 : _data$vehicle.name) !== null && _data$vehicle$name !== void 0 ? _data$vehicle$name : '-'))]) : _vm._e(), _vm.columnValue('COMPANY') ? _c('td', {
      staticClass: "text-left text-truncate"
    }, [_vm._v(_vm._s((_data$company$company = data === null || data === void 0 ? void 0 : (_data$company = data.company) === null || _data$company === void 0 ? void 0 : _data$company.company) !== null && _data$company$company !== void 0 ? _data$company$company : '-'))]) : _vm._e(), _vm.columnValue('POSITION') ? _c('td', {
      staticClass: "text-left text-truncate"
    }, [_vm._v(_vm._s((_data$position$name = data === null || data === void 0 ? void 0 : (_data$position = data.position) === null || _data$position === void 0 ? void 0 : _data$position.name) !== null && _data$position$name !== void 0 ? _data$position$name : '-') + " ")]) : _vm._e(), _vm.columnValue('SIGN ON') ? _c('td', {
      staticClass: "text-left text-truncate"
    }, [_vm._v(_vm._s(data !== null && data !== void 0 && data.sign_on ? _vm.moment(data === null || data === void 0 ? void 0 : data.sign_on).format('DD MMM YYYY HH:mm') : '-'))]) : _vm._e(), _vm.columnValue('JOIN ON BOARD') ? _c('td', {
      staticClass: "text-left text-truncate"
    }, [_vm._v(_vm._s(data !== null && data !== void 0 && data.join_on_board ? _vm.moment(data === null || data === void 0 ? void 0 : data.join_on_board).format('DD MMM YYYY HH:mm') : '-') + " ")]) : _vm._e(), _vm.columnValue('LENGTH OF JOINT') ? _c('td', {
      staticClass: "text-center text-truncate"
    }, [_vm.joinOnBoardStatus(data) ? _c('b-badge', {
      staticClass: "text-truncate",
      attrs: {
        "variant": _vm.joinOnBoardStatus(data) === 'More than 1 year' ? 'success d-block' : _vm.joinOnBoardStatus(data) === 'More than 6 month' ? 'primary d-block' : _vm.joinOnBoardStatus(data) === 'More than 3 month' ? 'warning d-block' : 'dark d-block'
      }
    }, [_vm._v(_vm._s(_vm.joinOnBoardStatus(data)) + " ")]) : _vm._e()], 1) : _vm._e(), _vm.columnValue('SIGN OFF') ? _c('td', {
      staticClass: "text-left text-truncate"
    }, [_vm._v(" " + _vm._s(data !== null && data !== void 0 && data.sign_off ? _vm.moment(data === null || data === void 0 ? void 0 : data.sign_off).format('DD MMM YYYY HH:mm') : '-') + " ")]) : _vm._e(), _vm.columnValue('STATUS') ? _c('td', {
      staticClass: "text-left"
    }, [_c('b-badge', {
      staticClass: "mb-0 text-truncate",
      attrs: {
        "variant": "".concat((data === null || data === void 0 ? void 0 : (_data$status_emp_crew = data.status_emp_crewing) === null || _data$status_emp_crew === void 0 ? void 0 : _data$status_emp_crew.alias) === 'active' ? 'success' : 'warning', " d-block")
      }
    }, [_vm._v(_vm._s((_data$status_emp_crew2 = data === null || data === void 0 ? void 0 : (_data$status_emp_crew3 = data.status_emp_crewing) === null || _data$status_emp_crew3 === void 0 ? void 0 : _data$status_emp_crew3.name) !== null && _data$status_emp_crew2 !== void 0 ? _data$status_emp_crew2 : '-') + " ")])], 1) : _vm._e()]);
  }), 0)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }