<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" >
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="nav-fill mb-3">
                <tab-nav-items class="col-md-2 p-0" :active="true" @click.native="tab = 'spesification'" href="#spesification" title="Spesification"/>
                <tab-nav-items class="col-md-2 p-0" :active="false" @click.native="tab = 'category'" href="#category" title="Item Category"/>
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="12">
        <div class="tab-content">
          <tab-content-item :active="true" id="spesification">
            <MasterSpesification :refreshMasterData="refreshMasterData" />
          </tab-content-item>
          <tab-content-item :active="false" id="category">
            <MasterMaterialCategory :refreshMasterData="refreshMasterData" />
          </tab-content-item>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
  import _ from 'lodash'
  import moment from 'moment'
  import { busdevActions } from '@src/Utils/helper'
  import MasterSpesification from './MasterSpesification.vue'
  import MasterMaterialCategory from './MasterMaterialCategory.vue'

  export default {
    name: 'ContentInventoryMaster',
    props:{
      refreshMasterData: {
        type: Function,
        default: () => {}
      },
    },
    data() {
      return {
      
      }
    },
    components:{
      MasterSpesification,
      MasterMaterialCategory
    },
    watch:{},
    computed: {},
    async mounted() {
    },
    methods:{
      ...busdevActions
    }
  }
</script>