var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "px-4"
  }, [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('TechnicalExpanseChartBudget')], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table table-striped"
  }, [_c('thead', _vm._l(_vm.columns, function (col, i) {
    return _c('th', {
      key: i,
      class: i !== 0 ? 'text-right' : 'text-left'
    }, [_vm._v(_vm._s(col))]);
  }), 0), _c('tbody', _vm._l([1, 2, 3], function (item, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "text-truncate"
    }, [_vm._v("January")]), _c('td', {
      staticClass: "text-truncate text-right"
    }, [_vm._v("Rp. 626.167.256")]), _c('td', {
      staticClass: "text-truncate text-right"
    }, [_vm._v("Rp. 254.126.776")]), _c('td', {
      staticClass: "text-truncate text-right"
    }, [_vm._v("Rp. 267.076.180")]), _c('td', {
      staticClass: "text-truncate text-right"
    }, [_vm._v("Rp. 1.315.816.560")]), _c('td', {
      staticClass: "text-truncate text-right"
    }, [_vm._v("Rp. 247.240.000")]), _c('td', {
      staticClass: "text-truncate text-right"
    }, [_vm._v("Rp. 461.332.473")]), _c('td', {
      staticClass: "text-truncate text-right"
    }, [_vm._v("Rp. 3.171.759.245")])]);
  }), 0)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }