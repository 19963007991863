import { render, staticRenderFns } from "./UserRolePrivilege.vue?vue&type=template&id=6a26e5d8&scoped=true"
import script from "./UserRolePrivilege.vue?vue&type=script&lang=js"
export * from "./UserRolePrivilege.vue?vue&type=script&lang=js"
import style0 from "./UserRolePrivilege.vue?vue&type=style&index=0&id=6a26e5d8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a26e5d8",
  null
  
)

export default component.exports