<template>
  <div class="w-100 border-line mb-3">
    <h5 text-title bg-white px-3 class="text-title pr-3">{{ text }}</h5>
  </div>
</template>
<script>
export default {
    props:{
        text:{
            type:String,
            default:''
        }
    }
}
</script>
<style scoped>
.border-line {
  border-top: 2px solid blue;
}
.text-title {
  color: #017aff !important;
  margin-top: -13px;
  width: max-content;
  background: white;
}
</style>
