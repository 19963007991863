<template>
  <b-row :class="{ 'px-3': showFilter }">
    <b-col v-if="showFilter" md="12">
      <iq-card
        class-name="iq-card-block iq-card-stretch iq-card-height"
        body-class="pb-1"
      >
        <template #headerTitle>
          <p class="text-primary text-nowrap m-0">Advance Search</p>
        </template>
        <template #body>
          <b-row class="">
            <b-col md="1">
              <b-form-group label-align="left" label-for="year">
                <b-form-select
                  plain
                  :options="['2022', '2022']"
                  id="year"
                  size="lg"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label-align="left" label-for="period">
                <b-form-select
                  plain
                  :options="['January-Juli', 'Juli-Juli']"
                  id="period"
                  size="lg"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label-align="left" label-for="period">
                <b-form-select
                  plain
                  :options="['January-Juli', 'Juli-Juli']"
                  id="period"
                  size="lg"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="1">
              <div class="d-flex align-items-center h-100 pb-3">
                <button class="btn btn-primary px-3 py-2"
                  ><span class="fa fa-search"></span
                ></button>
              </div>
            </b-col>
            <b-col md="5">
              <div class="d-flex justify-content-end h-100 pb-3">
                <button class="btn btn-primary px-3 py-2 gap-2 mr-3"
                  ><span class="fa fa-file-excel mr-2"></span>Export</button
                >
                <button
                  class="btn btn-primary px-3 py-2 gap-2"
                  @click="isOpenCreateRM = true"
                  ><span class="fa fa-plus mr-2"></span>Create Reapair
                  Maintenance</button
                >
              </div>
            </b-col>
          </b-row>
        </template>
      </iq-card>
    </b-col>
    <b-col md="12">
      <iq-card
        class-name="iq-card-block iq-card-stretch iq-card-height"
        body-class="pb-1"
      >
        <template #headerTitle>
          <h4>
            <strong class="text-primary">Breakdown Record</strong>
          </h4>
        </template>
        <template #body>
          <b-row class="p-3">
            <b-col md="12" class="overflow-auto p-0">
              <table class="w-100 mb-3">
                <thead class="bg-primary text-truncate">
                  <th v-for="(col, i) in columns" :key="i" class="p-2 fs-5">{{
                    col
                  }}</th>
                </thead>
                <tbody>
                  <tr
                    class="text-black"
                    v-for="(col, i) in 5"
                    :key="i"
                    :class="{ 'table-row-slate': i % 2 == 0 }"
                  >
                    <td class="p-2 text-truncate">{{ i + 1 }}</td>
                    <td class="p-2 text-truncate">BG. SML 104</td>
                    <td class="p-2 text-truncate">Barge</td>
                    <td class="p-2 text-truncate">Repair</td>
                    <td class="p-2 text-truncate">03 Mar 2024</td>
                    <td class="p-2 text-truncate">10 Mar 2024</td>
                    <td class="p-2 text-truncate">Lubricating Oil Cooler</td>
                    <td class="p-2 text-truncate">Subkontraktor</td>
                    <td class="p-2 text-truncate"> Lubricating Oil Cooler </td>
                    <td class="p-2 text-truncate"> Lubricating Oil Cooler </td>
                    <td class="p-2 text-truncate"> Subkontraktor </td>
                    <td class="p-2 text-truncate"> Iwan </td>
                  </tr>
                </tbody>
              </table>
            </b-col>
            <b-col class="my-2">
              <p class="d-inline"
                >Total Rows : <span class="text-black">10</span></p
              >
              <div class="d-inline float-right"
                ><b-pagination
                  :total-rows="10"
                  :per-page="5"
                  aria-controls="my-table"
                ></b-pagination
              ></div>
            </b-col>
          </b-row>
        </template>
      </iq-card>
    </b-col>
    <CreateRepairMaintenance
      :isOpen="isOpenCreateRM"
      @close="isOpenCreateRM = false"
    />
  </b-row>
</template>
<script>
import CreateRepairMaintenance from './CreateRepairMaintenance.vue'

export default {
  components: { CreateRepairMaintenance },
  props: {
    showFilter: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isOpenCreateRM: false,
      items: [
        { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
        { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
        { age: 89, first_name: 'Geneva', last_name: 'Wilson' },
        { age: 38, first_name: 'Jami', last_name: 'Carney' },
      ],
      columns: [
        'No',
        'Vessel Name',
        'Vessel Type',
        'Breakdown Type',
        'Time Start',
        'Time End',
        'Total Days',
        'Equipment',
        'Issue',
        'Remedy',
        'Done By',
        'Pic',
      ],
    }
  },
}
</script>
<style scoped>
.table-row-slate {
  background: #f9fafb;
}
.table {
  overflow: auto;
}
</style>
