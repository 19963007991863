var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    staticClass: "px-5 py-2",
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("List Birthday Crew")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$list, _vm$list$data, _vm$list2, _vm$list2$data, _vm$list3, _vm$list5, _vm$list5$data, _vm$list6, _vm$list7, _vm$list8;
        return [_c('b-row', [((_vm$list = _vm.list) === null || _vm$list === void 0 ? void 0 : (_vm$list$data = _vm$list.data) === null || _vm$list$data === void 0 ? void 0 : _vm$list$data.length) > 0 ? _c('b-col', {
          staticClass: "mb-2",
          attrs: {
            "md": "12"
          }
        }, [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.onExportListBirtday
          }
        }, [_c('div', {
          staticClass: "d-flex gap-2 align-items-center"
        }, [_vm.exporting ? _c('span', {
          staticClass: "spinner-border spinner-border-sm mr-2"
        }) : _c('i', {
          staticClass: "fa fa-file-excel mr-2"
        }), _c('span', [_vm._v("Export")])])])], 1) : _vm._e(), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "d-flex w-100 align-items-center justify-content-between mb-4"
        }, [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-form-input', {
          staticClass: "align-self-end w-50 mr-2",
          attrs: {
            "placeholder": "Search name..."
          },
          model: {
            value: _vm.filter,
            callback: function callback($$v) {
              _vm.filter = $$v;
            },
            expression: "filter"
          }
        }), _c('date-picker', {
          attrs: {
            "type": "date",
            "range": "",
            "placeholder": "Select date range",
            "value-type": "YYYY-MM-DD"
          },
          model: {
            value: _vm.dateRangeBirthday,
            callback: function callback($$v) {
              _vm.dateRangeBirthday = $$v;
            },
            expression: "dateRangeBirthday"
          }
        })], 1), _c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.filterDate
          }
        }, [_c('i', {
          staticClass: "fa fa-filter"
        })])], 1)]), _vm.loading ? _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('div', {
          staticClass: "alert alert-info m-4 align-items-center"
        }, [_c('span', {
          staticClass: "spinner-border spinner-border-sm mr-2"
        }), _vm._v(" Loading... ")])]) : _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [((_vm$list2 = _vm.list) === null || _vm$list2 === void 0 ? void 0 : (_vm$list2$data = _vm$list2.data) === null || _vm$list2$data === void 0 ? void 0 : _vm$list2$data.length) > 0 ? _c('div', {
          staticClass: "d-flex overflow-auto"
        }, [_c('table', {
          staticClass: "table table-bordered"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center"
        }, [_vm._v("NO")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Name")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Fleet")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Position")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Birthday")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Age")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Join on Board")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Sign On")]), _c('th', {
          staticClass: "text-center text-truncate"
        }, [_vm._v("Sign Off")]), _c('th', {
          staticClass: "text-center"
        }, [_vm._v("Status")])])]), _c('tbody', _vm._l((_vm$list3 = _vm.list) === null || _vm$list3 === void 0 ? void 0 : _vm$list3.data, function (crew, i) {
          var _vm$list4, _crew$emp_crewing, _crew$emp_crewing$nip, _crew$emp_crewing2, _crew$emp_crewing$veh, _crew$emp_crewing3, _crew$emp_crewing3$ve, _crew$emp_crewing$com, _crew$emp_crewing4, _crew$emp_crewing4$co, _crew$emp_crewing$pos, _crew$emp_crewing5, _crew$emp_crewing5$po, _crew$emp_crewing6, _crew$emp_crewing7, _crew$emp_crewing8, _crew$emp_crewing9, _crew$emp_crewing10, _crew$emp_crewing11, _crew$status_emp_crew, _crew$status_emp_crew2, _crew$status_emp_crew3;
          return _c('tr', {
            key: 'crew-' + i
          }, [_c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(((_vm$list4 = _vm.list) === null || _vm$list4 === void 0 ? void 0 : _vm$list4.from) + i))]), _c('td', {
            staticClass: "text-truncate"
          }, [_c('router-link', {
            attrs: {
              "to": {
                path: "profile",
                query: {
                  user: 'crew',
                  id: crew.emp_crewing_id
                }
              }
            }
          }, [_vm._v(_vm._s(crew === null || crew === void 0 ? void 0 : (_crew$emp_crewing = crew.emp_crewing) === null || _crew$emp_crewing === void 0 ? void 0 : _crew$emp_crewing.fullname))]), _c('br'), _c('small', {
            staticStyle: {
              "color": "gray"
            }
          }, [_vm._v("NIP : "), _c('b', [_vm._v(_vm._s((_crew$emp_crewing$nip = crew === null || crew === void 0 ? void 0 : (_crew$emp_crewing2 = crew.emp_crewing) === null || _crew$emp_crewing2 === void 0 ? void 0 : _crew$emp_crewing2.nip) !== null && _crew$emp_crewing$nip !== void 0 ? _crew$emp_crewing$nip : '-'))])])], 1), _c('td', {
            staticClass: "text-center text-truncate"
          }, [_vm._v(_vm._s((_crew$emp_crewing$veh = crew === null || crew === void 0 ? void 0 : (_crew$emp_crewing3 = crew.emp_crewing) === null || _crew$emp_crewing3 === void 0 ? void 0 : (_crew$emp_crewing3$ve = _crew$emp_crewing3.vehicle) === null || _crew$emp_crewing3$ve === void 0 ? void 0 : _crew$emp_crewing3$ve.name) !== null && _crew$emp_crewing$veh !== void 0 ? _crew$emp_crewing$veh : '-') + " "), _c('br'), _c('small', {
            staticStyle: {
              "color": "gray"
            }
          }, [_vm._v(_vm._s((_crew$emp_crewing$com = crew === null || crew === void 0 ? void 0 : (_crew$emp_crewing4 = crew.emp_crewing) === null || _crew$emp_crewing4 === void 0 ? void 0 : (_crew$emp_crewing4$co = _crew$emp_crewing4.company) === null || _crew$emp_crewing4$co === void 0 ? void 0 : _crew$emp_crewing4$co.company) !== null && _crew$emp_crewing$com !== void 0 ? _crew$emp_crewing$com : '-'))])]), _c('td', {
            staticClass: "text-center text-truncate"
          }, [_vm._v(_vm._s((_crew$emp_crewing$pos = crew === null || crew === void 0 ? void 0 : (_crew$emp_crewing5 = crew.emp_crewing) === null || _crew$emp_crewing5 === void 0 ? void 0 : (_crew$emp_crewing5$po = _crew$emp_crewing5.position) === null || _crew$emp_crewing5$po === void 0 ? void 0 : _crew$emp_crewing5$po.name) !== null && _crew$emp_crewing$pos !== void 0 ? _crew$emp_crewing$pos : '-') + " ")]), _c('td', {
            staticClass: "text-center text-truncate"
          }, [_vm._v(_vm._s(crew !== null && crew !== void 0 && crew.date_of_birth ? _vm.moment(crew === null || crew === void 0 ? void 0 : crew.date_of_birth).format('DD MMM YYYY') : '-') + " ")]), _c('td', {
            staticClass: "text-center text-truncate"
          }, [_vm._v(_vm._s(crew !== null && crew !== void 0 && crew.date_of_birth ? _vm.calculateAge(crew === null || crew === void 0 ? void 0 : crew.date_of_birth) : '-') + " ")]), _c('td', {
            staticClass: "text-center text-truncate"
          }, [_vm._v(_vm._s(crew !== null && crew !== void 0 && (_crew$emp_crewing6 = crew.emp_crewing) !== null && _crew$emp_crewing6 !== void 0 && _crew$emp_crewing6.join_on_board ? _vm.moment(crew === null || crew === void 0 ? void 0 : (_crew$emp_crewing7 = crew.emp_crewing) === null || _crew$emp_crewing7 === void 0 ? void 0 : _crew$emp_crewing7.join_on_board).format('DD MMM YYYY HH:mm') : '-') + " "), _vm.joinOnBoardStatus(crew) ? _c('b-badge', {
            attrs: {
              "variant": _vm.joinOnBoardStatus(crew) === 'More than 1 year' ? 'success d-block' : _vm.joinOnBoardStatus(crew) === 'More than 6 month' ? 'primary d-block' : _vm.joinOnBoardStatus(crew) === 'More than 3 month' ? 'warning d-block' : 'dark d-block'
            }
          }, [_vm._v(_vm._s(_vm.joinOnBoardStatus(crew)))]) : _vm._e()], 1), _c('td', {
            staticClass: "text-center text-truncate"
          }, [_vm._v(_vm._s(crew !== null && crew !== void 0 && (_crew$emp_crewing8 = crew.emp_crewing) !== null && _crew$emp_crewing8 !== void 0 && _crew$emp_crewing8.sign_on ? _vm.moment(crew === null || crew === void 0 ? void 0 : (_crew$emp_crewing9 = crew.emp_crewing) === null || _crew$emp_crewing9 === void 0 ? void 0 : _crew$emp_crewing9.sign_on).format('DD MMM YYYY HH:mm') : '-'))]), _c('td', {
            staticClass: "text-center text-truncate"
          }, [_vm._v(_vm._s(crew !== null && crew !== void 0 && (_crew$emp_crewing10 = crew.emp_crewing) !== null && _crew$emp_crewing10 !== void 0 && _crew$emp_crewing10.sign_off ? _vm.moment(crew === null || crew === void 0 ? void 0 : (_crew$emp_crewing11 = crew.emp_crewing) === null || _crew$emp_crewing11 === void 0 ? void 0 : _crew$emp_crewing11.sign_off).format('DD MMM YYYY HH:mm') : '-') + " "), _c('br')]), _c('td', {
            staticClass: "text-center text-truncate"
          }, [_c('b-badge', {
            staticClass: "mb-1",
            attrs: {
              "variant": "".concat((crew === null || crew === void 0 ? void 0 : (_crew$status_emp_crew = crew.status_emp_crewing) === null || _crew$status_emp_crew === void 0 ? void 0 : _crew$status_emp_crew.alias) === 'active' ? 'success' : 'warning', " d-block")
            }
          }, [_vm._v(_vm._s((_crew$status_emp_crew2 = crew === null || crew === void 0 ? void 0 : (_crew$status_emp_crew3 = crew.status_emp_crewing) === null || _crew$status_emp_crew3 === void 0 ? void 0 : _crew$status_emp_crew3.name) !== null && _crew$status_emp_crew2 !== void 0 ? _crew$status_emp_crew2 : '-') + " ")]), _vm.expiredContract(crew) ? _c('b-badge', {
            attrs: {
              "variant": "danger d-block"
            }
          }, [_vm._v(_vm._s(_vm.expiredContract(crew)) + " ")]) : _vm._e()], 1)]);
        }), 0)])]) : _vm._e(), ((_vm$list5 = _vm.list) === null || _vm$list5 === void 0 ? void 0 : (_vm$list5$data = _vm$list5.data) === null || _vm$list5$data === void 0 ? void 0 : _vm$list5$data.length) > 0 ? _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "value": (_vm$list6 = _vm.list) === null || _vm$list6 === void 0 ? void 0 : _vm$list6.current_page,
            "total-rows": (_vm$list7 = _vm.list) === null || _vm$list7 === void 0 ? void 0 : _vm$list7.total,
            "per-page": (_vm$list8 = _vm.list) === null || _vm$list8 === void 0 ? void 0 : _vm$list8.per_page,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getListBirtdayCrew
          }
        })], 1) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("List Crew Birthday tidak ada.")])])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }