var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.dockingDetail && _vm.fleetDetail ? _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('iq-card', {
    attrs: {
      "class-name": "iq-user-profile-block1"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "user-details-block"
        }, [_c('div', {
          staticClass: "user-profile text-center"
        }, [_c('img', {
          staticClass: "avatar-130 img-fluid",
          attrs: {
            "src": require("../../assets/images/ship.png"),
            "alt": "profile-img"
          }
        })]), _c('div', {
          staticClass: "text-center mt-3"
        }, [_c('h4', [_c('b', [_vm._v(_vm._s(_vm.fleetDetail.name))])]), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(_vm._s(_vm.fleetDetail.company.company))])], 1)]), _c('table', {
          staticClass: "table table-borderless mt-3"
        }, [_c('tbody', [_c('tr', {
          staticClass: "bg-primary"
        }, [_c('td', {
          staticClass: "text-center",
          attrs: {
            "colspan": "2"
          }
        }, [_c('strong', [_vm._v("SHIP PARTICULAR")])])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Type Kapal")])]), _c('td', [_vm._v(_vm._s(_vm.fleetDetail.vehicleType.name))])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Bendera")])]), _c('td', [_vm._v(_vm._s(_vm.fleetDetail.flag))])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Klasifikasi")])]), _c('td', [_vm._v(_vm._s(_vm.fleetDetail.classification))])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Tahun Pembuatan")])]), _c('td', [_vm._v("-")])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Tempat Pembangunan")])]), _c('td', [_vm._v(_vm._s(_vm.fleetDetail.yearBuildCountry))])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Tempat Pendaftaran")])]), _c('td', [_vm._v("-")])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Ukuran Kapal")])]), _c('td', [_vm._v(_vm._s(_vm.fleetDetail.dimension.width.meter) + " x " + _vm._s(_vm.fleetDetail.dimension.length.meter) + " x " + _vm._s(_vm.fleetDetail.dimension.depth.meter) + " meter")])]), _c('tr', [_c('td', [_c('strong', [_vm._v("Main Engine")])]), _c('td', [_c('ul', {
          staticClass: "text-left"
        }, _vm._l(_vm.fleetDetail.powers, function (power) {
          return _c('li', [_vm._v(_vm._s(power.powerType ? power.powerType.name : '-') + " - " + _vm._s(power.powerSeries ? power.powerSeries.name : '-') + " " + _vm._s(power.powerSeries ? power.powerSeries.model : '-') + " "), _c('span', {
            staticClass: "pull-right"
          }, [_vm._v("Actual RH : "), _c('b-badge', {
            attrs: {
              "variant": "info"
            }
          }, [_c('strong', [_vm._v(_vm._s(power.actualRunningHours) + " RH")])])], 1)]);
        }), 0)])])])])];
      },
      proxy: true
    }], null, false, 314218648)
  })], 1), _c('b-col', {
    attrs: {
      "lg": "8"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("FINAL SEA TRIAL RECORD")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary",
          staticStyle: {
            "font-size": "12pt"
          }
        }, [_vm._v("PRINCIPAL DIMENSION")]), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "L.O.A*",
            "label-for": "loa"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form.loa,
            expression: "form.loa"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "step": ".001"
          },
          domProps: {
            "value": _vm.form.loa
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form, "loa", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("mtr")])])])]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "L.W.L*",
            "label-for": "lwl"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form.lwl,
            expression: "form.lwl"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "step": ".001"
          },
          domProps: {
            "value": _vm.form.lwl
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form, "lwl", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("mtr")])])])]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "L.B.P*",
            "label-for": "lbp"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form.lbp,
            expression: "form.lbp"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "step": ".001"
          },
          domProps: {
            "value": _vm.form.lbp
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form, "lbp", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("mtr")])])])])], 1), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "B mld*",
            "label-for": "bmld"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form.bmld,
            expression: "form.bmld"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "step": ".001"
          },
          domProps: {
            "value": _vm.form.bmld
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form, "bmld", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("mtr")])])])]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "D mld*",
            "label-for": "dmld"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form.dmld,
            expression: "form.dmld"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "step": ".001"
          },
          domProps: {
            "value": _vm.form.dmld
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form, "dmld", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("mtr")])])])]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "T*",
            "label-for": "t"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form.t,
            expression: "form.t"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "step": ".001"
          },
          domProps: {
            "value": _vm.form.t
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form, "t", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("mtr")])])])])], 1), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Main Engine",
            "label-for": "main_engine"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.fleetDetail.powers,
            "get-option-label": function getOptionLabel(type) {
              return type.powerType.name;
            },
            "reduce": function reduce(type) {
              return type.id;
            }
          },
          model: {
            value: _vm.form.mainEngine,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "mainEngine", $$v);
            },
            expression: "form.mainEngine"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Generator",
            "label-for": "generator"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.fleetDetail.powers,
            "get-option-label": function getOptionLabel(type) {
              return type.powerType.name;
            },
            "reduce": function reduce(type) {
              return type.id;
            }
          },
          model: {
            value: _vm.form.generator,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "generator", $$v);
            },
            expression: "form.generator"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Gearbox Ration*",
            "label-for": "gearbox_ratio"
          }
        }, [_c('b-form-input', {
          model: {
            value: _vm.form.gearboxRotation,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "gearboxRotation", $$v);
            },
            expression: "form.gearboxRotation"
          }
        })], 1)], 1)], 1), _c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary",
          staticStyle: {
            "font-size": "12pt"
          }
        }, [_vm._v("FULL LOAD RECORD")]), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Tanggal Percobaan*",
            "label-for": "tanggal_percobaan"
          }
        }, [_c('date-picker', {
          attrs: {
            "required": "",
            "value-type": "DD-MM-YYYY",
            "type": "date",
            "placeholder": "Select date",
            "format": "DD MMM YYYY"
          },
          model: {
            value: _vm.form.trialDate,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "trialDate", $$v);
            },
            expression: "form.trialDate"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Tempat Percobaan*",
            "label-for": "tempat_percobaan"
          }
        }, [_c('b-form-input', {
          attrs: {
            "required": ""
          },
          model: {
            value: _vm.form.trialPlace,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "trialPlace", $$v);
            },
            expression: "form.trialPlace"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Kedalaman",
            "label-for": "kedalaman"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form.depth,
            expression: "form.depth"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "id": "kedalaman",
            "step": ".001"
          },
          domProps: {
            "value": _vm.form.depth
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form, "depth", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("mtr")])])])]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Jarak Pengukuran",
            "label-for": "jarak_pengukuran"
          }
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form.measurementDistance,
            expression: "form.measurementDistance"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "step": ".001"
          },
          domProps: {
            "value": _vm.form.measurementDistance
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form, "measurementDistance", $event.target.value);
            }
          }
        })]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Arah Angin",
            "label-for": "arah_angin"
          }
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form.windDirection,
            expression: "form.windDirection"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "step": ".001"
          },
          domProps: {
            "value": _vm.form.windDirection
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form, "windDirection", $event.target.value);
            }
          }
        })]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Cuaca",
            "label-for": "cuaca"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form.weather,
            expression: "form.weather"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "step": ".001"
          },
          domProps: {
            "value": _vm.form.weather
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form, "weather", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("°C")])])])]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Sarat Depan",
            "label-for": "sarat_depan"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form.saratDepan,
            expression: "form.saratDepan"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "step": ".001"
          },
          domProps: {
            "value": _vm.form.saratDepan
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form, "saratDepan", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("mtr")])])])]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Perbedaan Trim",
            "label-for": "perbedaan_trim"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form.trimDifference,
            expression: "form.trimDifference"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "step": ".001"
          },
          domProps: {
            "value": _vm.form.trimDifference
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form, "trimDifference", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("mtr")])])])]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Sarat Belakang",
            "label-for": "sarat_belakang"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form.saratBelakang,
            expression: "form.saratBelakang"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "step": ".001"
          },
          domProps: {
            "value": _vm.form.saratBelakang
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form, "saratBelakang", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("mtr")])])])]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Tempratur Udara",
            "label-for": "tempratur_udara"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form.airTemperature,
            expression: "form.airTemperature"
          }],
          staticClass: "form-control",
          attrs: {
            "type": "number",
            "step": ".001"
          },
          domProps: {
            "value": _vm.form.airTemperature
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form, "airTemperature", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("°C")])])])])], 1)], 1), _c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary",
          staticStyle: {
            "font-size": "12pt"
          }
        }, [_vm._v("MUATAN DAN TANGKI")]), _c('b-row', [_c('b-form-group', {
          staticClass: "col-md-12",
          attrs: {
            "label": "Muatan",
            "label-for": "muatan"
          }
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form.load,
            expression: "form.load"
          }],
          staticClass: "form-control",
          domProps: {
            "value": _vm.form.load
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form, "load", $event.target.value);
            }
          }
        })]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Jumlah Orang Atas Kapal",
            "label-for": "jml_crew"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form.numberOfPeople,
            expression: "form.numberOfPeople"
          }],
          staticClass: "form-control",
          domProps: {
            "value": _vm.form.numberOfPeople
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form, "numberOfPeople", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("Orang")])])])]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Tangki Induk BBM",
            "label-for": "tangkiIndukBBM"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form.mainGasTank,
            expression: "form.mainGasTank"
          }],
          staticClass: "form-control",
          domProps: {
            "value": _vm.form.mainGasTank
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form, "mainGasTank", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("Liter")])])])]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Tangki Induk Harian",
            "label-for": "tangkiIndukHarian"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form.dailyGasTank,
            expression: "form.dailyGasTank"
          }],
          staticClass: "form-control",
          domProps: {
            "value": _vm.form.dailyGasTank
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form, "dailyGasTank", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("Liter")])])])]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Tangki Induk Oli",
            "label-for": "tangkiIndukOli"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form.mainOilTank,
            expression: "form.mainOilTank"
          }],
          staticClass: "form-control",
          domProps: {
            "value": _vm.form.mainOilTank
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form, "mainOilTank", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("Liter")])])])]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Tangki Harian Oli",
            "label-for": "tangkiHarianOli"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form.dailyOilTank,
            expression: "form.dailyOilTank"
          }],
          staticClass: "form-control",
          domProps: {
            "value": _vm.form.dailyOilTank
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form, "dailyOilTank", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("Liter")])])])]), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Tangki Air Tawar",
            "label-for": "TangkiAirTawar"
          }
        }, [_c('div', {
          staticClass: "input-group"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.form.waterTank,
            expression: "form.waterTank"
          }],
          staticClass: "form-control",
          domProps: {
            "value": _vm.form.waterTank
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) return;
              _vm.$set(_vm.form, "waterTank", $event.target.value);
            }
          }
        }), _c('div', {
          staticClass: "input-group-prepend"
        }, [_c('span', {
          staticClass: "input-group-text bg-primary"
        }, [_vm._v("Liter")])])])])], 1)], 1), _c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary",
          staticStyle: {
            "font-size": "12pt"
          }
        }, [_vm._v("MAIN ENGINE RECORD")]), _c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary",
          staticStyle: {
            "font-size": "12pt"
          }
        }, [_c('strong', [_vm._v("ME - Starboard Side")])]), _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-bordered"
        }, [_c('thead', {
          staticClass: "text-primary"
        }, [_c('tr', [_c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v(" NO ")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Load (Percent)")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Time (Menit)")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Speed (Knots)")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "colspan": "5"
          }
        }, [_vm._v("MAIN ENGINE")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "colspan": "2"
          }
        }, [_vm._v("GEARBOX")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Temp. Cover Crankcase (°C)")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Interm. Shaft Propeller (°C)")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Shaft Propeller (°C)")])]), _c('tr', [_c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("Engine (RPM)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("Exhaust Temperatur (°C)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("FW Temp (°C)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("LO Temp (°C)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("LO Press (kg/cm2)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("LO Press (Psi)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("LO Temp (°C)")])])]), _c('tbody', _vm._l(_vm.form.meStarboard, function (data, index) {
          return _c('tr', {
            key: index
          }, [_c('td', [_c('b', [_vm._v(" # " + _vm._s(index + 1) + " ")]), _c('br'), _c('a', {
            staticClass: "text-danger",
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.onDeleteMeStarboard(index);
              }
            }
          }, [_vm._v("Remove")])]), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "load",
              "name": "load",
              "step": ".001"
            },
            model: {
              value: data.load,
              callback: function callback($$v) {
                _vm.$set(data, "load", $$v);
              },
              expression: "data.load"
            }
          })], 1), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "time",
              "name": "time",
              "step": ".001"
            },
            model: {
              value: data.time,
              callback: function callback($$v) {
                _vm.$set(data, "time", $$v);
              },
              expression: "data.time"
            }
          })], 1), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "speed",
              "name": "speed",
              "step": ".001"
            },
            model: {
              value: data.speed,
              callback: function callback($$v) {
                _vm.$set(data, "speed", $$v);
              },
              expression: "data.speed"
            }
          })], 1), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "engine_rpm",
              "name": "engine_rpm",
              "step": ".001"
            },
            model: {
              value: data.engine,
              callback: function callback($$v) {
                _vm.$set(data, "engine", $$v);
              },
              expression: "data.engine"
            }
          })], 1), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "exhaust_temp",
              "name": "exhaust_temp",
              "step": ".001"
            },
            model: {
              value: data.exhaustTemperatur,
              callback: function callback($$v) {
                _vm.$set(data, "exhaustTemperatur", $$v);
              },
              expression: "data.exhaustTemperatur"
            }
          })], 1), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "fw_temp",
              "name": "fw_temp",
              "step": ".001"
            },
            model: {
              value: data.fwTemp,
              callback: function callback($$v) {
                _vm.$set(data, "fwTemp", $$v);
              },
              expression: "data.fwTemp"
            }
          })], 1), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "lo_temp_me",
              "name": "lo_temp_me",
              "step": ".001"
            },
            model: {
              value: data.loTempEngine,
              callback: function callback($$v) {
                _vm.$set(data, "loTempEngine", $$v);
              },
              expression: "data.loTempEngine"
            }
          })], 1), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "lo_press_me",
              "name": "lo_press_me",
              "step": ".001"
            },
            model: {
              value: data.loPressEngine,
              callback: function callback($$v) {
                _vm.$set(data, "loPressEngine", $$v);
              },
              expression: "data.loPressEngine"
            }
          })], 1), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "lo_press_gb",
              "name": "lo_press_gb",
              "step": ".001"
            },
            model: {
              value: data.loPressGearbox,
              callback: function callback($$v) {
                _vm.$set(data, "loPressGearbox", $$v);
              },
              expression: "data.loPressGearbox"
            }
          })], 1), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "lo_temp_gb",
              "name": "lo_temp_gb",
              "step": ".001"
            },
            model: {
              value: data.loTempGearbox,
              callback: function callback($$v) {
                _vm.$set(data, "loTempGearbox", $$v);
              },
              expression: "data.loTempGearbox"
            }
          })], 1), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "temp_cover",
              "name": "temp_cover",
              "step": ".001"
            },
            model: {
              value: data.tempCoverCrankcase,
              callback: function callback($$v) {
                _vm.$set(data, "tempCoverCrankcase", $$v);
              },
              expression: "data.tempCoverCrankcase"
            }
          })], 1), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "interm_shaft_propeller",
              "name": "interm_shaft_propeller",
              "step": ".001"
            },
            model: {
              value: data.intermShaftPropeller,
              callback: function callback($$v) {
                _vm.$set(data, "intermShaftPropeller", $$v);
              },
              expression: "data.intermShaftPropeller"
            }
          })], 1), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "shaft_propeller",
              "name": "shaft_propeller",
              "step": ".001"
            },
            model: {
              value: data.shaftPropeller,
              callback: function callback($$v) {
                _vm.$set(data, "shaftPropeller", $$v);
              },
              expression: "data.shaftPropeller"
            }
          })], 1)]);
        }), 0)])]), _c('b-button', {
          staticClass: "mt-1",
          attrs: {
            "variant": "success"
          },
          on: {
            "click": function click($event) {
              return _vm.onAddMeStarboard();
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" ADD FORM")])], 1), _c('hr'), _c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary",
          staticStyle: {
            "font-size": "12pt"
          }
        }, [_c('strong', [_vm._v("ME - Port Side")])]), _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-bordered"
        }, [_c('thead', {
          staticClass: "text-primary"
        }, [_c('tr', [_c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v(" NO ")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Load (Percent)")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Time (Menit)")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Speed (Knots)")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "colspan": "5"
          }
        }, [_vm._v("MAIN ENGINE")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "colspan": "2"
          }
        }, [_vm._v("GEARBOX")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Temp. Cover Crankcase (°C)")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Interm. Shaft Propeller (°C)")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Shaft Propeller (°C)")])]), _c('tr', [_c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("Engine (RPM)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("Exhaust Temperatur (°C)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("FW Temp (°C)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("LO Temp (°C)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("LO Press (kg/cm2)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("LO Press (Psi)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("LO Temp (°C)")])])]), _c('tbody', _vm._l(_vm.form.mePort, function (data, index) {
          return _c('tr', [_c('td', [_c('b', [_vm._v(" # " + _vm._s(index + 1) + " ")]), _c('br'), _c('a', {
            staticClass: "text-danger",
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.onDeleteMePort(index);
              }
            }
          }, [_vm._v("Remove")])]), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "load",
              "name": "load",
              "step": ".001"
            },
            model: {
              value: data.load,
              callback: function callback($$v) {
                _vm.$set(data, "load", $$v);
              },
              expression: "data.load"
            }
          })], 1), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "time",
              "name": "time",
              "step": ".001"
            },
            model: {
              value: data.time,
              callback: function callback($$v) {
                _vm.$set(data, "time", $$v);
              },
              expression: "data.time"
            }
          })], 1), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "speed",
              "name": "speed",
              "step": ".001"
            },
            model: {
              value: data.speed,
              callback: function callback($$v) {
                _vm.$set(data, "speed", $$v);
              },
              expression: "data.speed"
            }
          })], 1), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "engine_rpm",
              "name": "engine_rpm",
              "step": ".001"
            },
            model: {
              value: data.engine,
              callback: function callback($$v) {
                _vm.$set(data, "engine", $$v);
              },
              expression: "data.engine"
            }
          })], 1), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "exhaust_temp",
              "name": "exhaust_temp",
              "step": ".001"
            },
            model: {
              value: data.exhaustTemperatur,
              callback: function callback($$v) {
                _vm.$set(data, "exhaustTemperatur", $$v);
              },
              expression: "data.exhaustTemperatur"
            }
          })], 1), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "fw_temp",
              "name": "fw_temp",
              "step": ".001"
            },
            model: {
              value: data.fwTemp,
              callback: function callback($$v) {
                _vm.$set(data, "fwTemp", $$v);
              },
              expression: "data.fwTemp"
            }
          })], 1), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "lo_temp_me",
              "name": "lo_temp_me",
              "step": ".001"
            },
            model: {
              value: data.loTempEngine,
              callback: function callback($$v) {
                _vm.$set(data, "loTempEngine", $$v);
              },
              expression: "data.loTempEngine"
            }
          })], 1), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "lo_press_me",
              "name": "lo_press_me",
              "step": ".001"
            },
            model: {
              value: data.loPressEngine,
              callback: function callback($$v) {
                _vm.$set(data, "loPressEngine", $$v);
              },
              expression: "data.loPressEngine"
            }
          })], 1), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "lo_press_gb",
              "name": "lo_press_gb",
              "step": ".001"
            },
            model: {
              value: data.loPressGearbox,
              callback: function callback($$v) {
                _vm.$set(data, "loPressGearbox", $$v);
              },
              expression: "data.loPressGearbox"
            }
          })], 1), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "lo_temp_gb",
              "name": "lo_temp_gb",
              "step": ".001"
            },
            model: {
              value: data.loTempGearbox,
              callback: function callback($$v) {
                _vm.$set(data, "loTempGearbox", $$v);
              },
              expression: "data.loTempGearbox"
            }
          })], 1), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "temp_cover",
              "name": "temp_cover",
              "step": ".001"
            },
            model: {
              value: data.tempCoverCrankcase,
              callback: function callback($$v) {
                _vm.$set(data, "tempCoverCrankcase", $$v);
              },
              expression: "data.tempCoverCrankcase"
            }
          })], 1), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "interm_shaft_propeller",
              "name": "interm_shaft_propeller",
              "step": ".001"
            },
            model: {
              value: data.intermShaftPropeller,
              callback: function callback($$v) {
                _vm.$set(data, "intermShaftPropeller", $$v);
              },
              expression: "data.intermShaftPropeller"
            }
          })], 1), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "shaft_propeller",
              "name": "shaft_propeller",
              "step": ".001"
            },
            model: {
              value: data.shaftPropeller,
              callback: function callback($$v) {
                _vm.$set(data, "shaftPropeller", $$v);
              },
              expression: "data.shaftPropeller"
            }
          })], 1)]);
        }), 0)])]), _c('b-button', {
          staticClass: "mt-1",
          attrs: {
            "variant": "success"
          },
          on: {
            "click": function click($event) {
              return _vm.onAddMePort();
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" ADD FORM")])], 1), _c('b', [_vm._v("Notes : ")]), _vm._v("RPM bervariasi per kapal, bisa sampai 1600 RPM ")]), _c('fieldset', {
          staticClass: "form-group border p-3 mt-1"
        }, [_c('legend', {
          staticClass: "w-auto text-primary",
          staticStyle: {
            "font-size": "12pt"
          }
        }, [_vm._v("AUXILIARY ENGINE RECORD")]), _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-bordered"
        }, [_c('thead', {
          staticClass: "text-primary"
        }, [_c('tr', [_c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v(" NO ")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Load (Percent)")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Engine (RPM)")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "colspan": "5"
          }
        }, [_vm._v("ENGINE RECORD")]), _c('th', {
          staticClass: "text-center align-middle",
          attrs: {
            "rowspan": "2"
          }
        }, [_vm._v("Temp. Cover Crankcase (°C)")])]), _c('tr', [_c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("LO Press (kg/cm2)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("SW Press (kg/cm2)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("LO Temp (°C)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("FW Temp (°C)")]), _c('th', {
          staticClass: "text-center align-middle"
        }, [_vm._v("Exhaust Temp (°C)")])])]), _c('tbody', _vm._l(_vm.form.aeRecord, function (data, index) {
          return _c('tr', {
            key: index
          }, [_c('td', [_c('b', [_vm._v("# " + _vm._s(index + 1))]), _c('br'), _c('a', {
            staticClass: "text-danger",
            attrs: {
              "href": "#"
            },
            on: {
              "click": function click($event) {
                $event.preventDefault();
                return _vm.onDeleteAeRecord(index);
              }
            }
          }, [_vm._v("Remove")])]), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "load",
              "name": "load",
              "step": ".001"
            },
            model: {
              value: data.load,
              callback: function callback($$v) {
                _vm.$set(data, "load", $$v);
              },
              expression: "data.load"
            }
          })], 1), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "engine_rpm",
              "name": "engine_rpm",
              "step": ".001"
            },
            model: {
              value: data.engine,
              callback: function callback($$v) {
                _vm.$set(data, "engine", $$v);
              },
              expression: "data.engine"
            }
          })], 1), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "lo_press_1",
              "name": "lo_press_1",
              "step": ".001"
            },
            model: {
              value: data.loPress,
              callback: function callback($$v) {
                _vm.$set(data, "loPress", $$v);
              },
              expression: "data.loPress"
            }
          })], 1), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "sw_press",
              "name": "sw_press",
              "step": ".001"
            },
            model: {
              value: data.swPress,
              callback: function callback($$v) {
                _vm.$set(data, "swPress", $$v);
              },
              expression: "data.swPress"
            }
          })], 1), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "lo_temp",
              "name": "lo_temp",
              "step": ".001"
            },
            model: {
              value: data.loTemp,
              callback: function callback($$v) {
                _vm.$set(data, "loTemp", $$v);
              },
              expression: "data.loTemp"
            }
          })], 1), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "fw_temp",
              "name": "fw_temp",
              "step": ".001"
            },
            model: {
              value: data.fwTemp,
              callback: function callback($$v) {
                _vm.$set(data, "fwTemp", $$v);
              },
              expression: "data.fwTemp"
            }
          })], 1), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "exhaust_temp",
              "name": "exhaust_temp",
              "step": ".001"
            },
            model: {
              value: data.exhaustTemp,
              callback: function callback($$v) {
                _vm.$set(data, "exhaustTemp", $$v);
              },
              expression: "data.exhaustTemp"
            }
          })], 1), _c('td', [_c('b-form-input', {
            attrs: {
              "type": "number",
              "id": "temp_cover",
              "name": "temp_cover",
              "step": ".001"
            },
            model: {
              value: data.tempCoverCrankcase,
              callback: function callback($$v) {
                _vm.$set(data, "tempCoverCrankcase", $$v);
              },
              expression: "data.tempCoverCrankcase"
            }
          })], 1)]);
        }), 0)])]), _c('b-button', {
          staticClass: "mt-1",
          attrs: {
            "variant": "success"
          },
          on: {
            "click": function click($event) {
              return _vm.onAddAeRecord();
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-plus"
        }), _vm._v(" ADD FORM")])], 1), _c('hr'), _c('b-row', [_c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "RECOMMENDATION",
            "label-for": "recomendation"
          }
        }, [_c('wysiwyg', {
          model: {
            value: _vm.form.recommendation,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "recommendation", $$v);
            },
            expression: "form.recommendation"
          }
        })], 1)], 1), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Prepared By - OS",
            "label-for": "prepared_by"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.users,
            "reduce": function reduce(type) {
              return type.value;
            }
          },
          model: {
            value: _vm.form.preparedBy,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "preparedBy", $$v);
            },
            expression: "form.preparedBy"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Acknowledge By - INCO Mechanic",
            "label-for": "acknowledge_by"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.users,
            "reduce": function reduce(type) {
              return type.value;
            }
          },
          model: {
            value: _vm.form.ackByInco,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "ackByInco", $$v);
            },
            expression: "form.ackByInco"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": "Acknowledge By - SPT / Mechanic",
            "label-for": "acknowledge_by"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.users,
            "reduce": function reduce(type) {
              return type.value;
            }
          },
          model: {
            value: _vm.form.ackBySpt,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "ackBySpt", $$v);
            },
            expression: "form.ackBySpt"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": 'Acknowledge By - ' + _vm.fleetDetail.name,
            "label-for": "acknowledge_by"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.users,
            "reduce": function reduce(type) {
              return type.value;
            }
          },
          model: {
            value: _vm.form.ackByKkm,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "ackByKkm", $$v);
            },
            expression: "form.ackByKkm"
          }
        })], 1), _c('b-form-group', {
          staticClass: "col-md-4",
          attrs: {
            "label": 'Acknowledge By - Captain ' + _vm.fleetDetail.name,
            "label-for": "acknowledge_by"
          }
        }, [_c('v-select', {
          attrs: {
            "label": "text",
            "options": _vm.users,
            "reduce": function reduce(type) {
              return type.value;
            }
          },
          model: {
            value: _vm.form.ackByCaptain,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "ackByCaptain", $$v);
            },
            expression: "form.ackByCaptain"
          }
        })], 1)], 1), _c('b-button', {
          staticClass: "mt-3",
          attrs: {
            "block": "",
            "variant": "primary",
            "type": "submit"
          }
        }, [_vm._v("Submit Data")])];
      },
      proxy: true
    }], null, false, 1355213632)
  })], 1)], 1)], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }