var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-100 border-line mb-3"
  }, [_c('h5', {
    staticClass: "text-title pr-3",
    attrs: {
      "text-title": "",
      "bg-white": "",
      "px-3": ""
    }
  }, [_vm._v(_vm._s(_vm.text))])]);

}
var staticRenderFns = []

export { render, staticRenderFns }