var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-col', {
    staticClass: "p-0",
    attrs: {
      "sm": "12"
    }
  }, [_c('b-row', _vm._l(_vm.certificate, function (item, i) {
    var _item$total;
    return _c('b-col', {
      key: i,
      staticStyle: {
        "margin-bottom": "2rem"
      },
      attrs: {
        "md": "6",
        "xl": "3"
      }
    }, [_c('router-link', {
      attrs: {
        "to": item.path
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center bg-white p-3 shadow-sm card-certificate h-full"
    }, [_c('div', {
      staticClass: "card-icon mr-2",
      style: "background: ".concat(item.color, " ;")
    }, [_c(item.icon, {
      tag: "component"
    })], 1), _c('div', {
      staticClass: "text-left"
    }, [_c('h5', {
      staticClass: "line-height mb-1"
    }, [_c('strong', [_vm._v(_vm._s((_item$total = item.total) !== null && _item$total !== void 0 ? _item$total : '-'))])]), _c('h5', {}, [_vm._v(" " + _vm._s(item.name) + " ")])])])])], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }