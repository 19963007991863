var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "border p-3 rounded-lg mb-3"
  }, [_c('div', {
    staticClass: "pb-0 d-flex gap-2 cursor-pointer",
    on: {
      "click": function click($event) {
        _vm.collapse = !_vm.collapse;
      }
    }
  }, [_c('p', {
    staticClass: "text-primary text-nowrap"
  }, [_vm._v(_vm._s(_vm.title))]), _c('span', {
    staticClass: "border-top border-primary w-100 mx-3 line-h"
  }), _c('div', {
    staticClass: "p-0 cursor-pointer",
    attrs: {
      "aria-controls": "collapse-1"
    }
  }, [_vm.collapse ? _c('span', {
    staticClass: "fa fa-solid fa-chevron-up"
  }) : _c('span', {
    staticClass: "fa fa-solid fa-chevron-down"
  })])]), _c('b-collapse', {
    staticClass: "mt-2",
    attrs: {
      "id": "collapse-1"
    },
    model: {
      value: _vm.collapse,
      callback: function callback($$v) {
        _vm.collapse = $$v;
      },
      expression: "collapse"
    }
  }, [_vm._t("body")], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }