<template>
  <form @submit.prevent="onSavePlan" v-if="!detail_project_loading"> 
    <b-row class="m-3">
      <!-- CHANGE STAGE ON EDIT ACTION -->
      <b-col lg="12" class="mb-4" v-if="['EDIT', 'PREVIEW'].includes(action)">
        <div class="d-flex align-items-center w-100 justify-content-end">
          <div v-for="(state, index) in listProjectStage" :key="index">
            <b-badge 
              class="border border-primary text-primary text-uppercase" 
              :variant="detailProjectContract?.detail_project_stage_id === state.id ? 'primary' : 'none'" 
              style="cursor: pointer;" 
              @click="onChangeStatusStage(detailProjectContract?.id, state.id)"
            >
              <span :class="detailProjectContract?.detail_project_stage_id === state.id ? 'text-white' : ''">{{ state?.title }}</span>
            </b-badge>
            <strong v-if="listProjectStage?.length - 1 !== index">&nbsp;>>&nbsp;</strong>
          </div>
        </div>
      </b-col>
    
      <!-- TAB DATA -->
      <b-col lg="12" v-if="['EDIT', 'PREVIEW'].includes(action)" class="mb-3">
        <b-row>
          <div @click="activeTab = 1" style="cursor:pointer"
            :class="`col-md-2 ${activeTab === 1 ? 'bg-primary' : 'text-muted'} py-2 px-2 rounded-circle justify-content-center d-flex`">
            <strong>General</strong>
          </div>
          <div @click="activeTab = 2" style="cursor:pointer"
            :class="`col-md-2 ${activeTab === 2 ? 'bg-primary' : 'text-muted'} py-2 px-2 rounded-circle justify-content-center d-flex`">
            <strong>Form List</strong>
          </div>
          <div @click="activeTab = 3" style="cursor:pointer"
            :class="`col-md-2 ${activeTab === 3 ? 'bg-primary' : 'text-muted'} py-2 px-2 rounded-circle justify-content-center d-flex`">
            <strong>PFA</strong>
          </div>
          <div @click="activeTab = 4" style="cursor:pointer" v-if="detailProjectContract?.project_stage?.spal_process" class="col-md-2 p-0"
            :class="`col-md-2 ${activeTab === 4 ? 'bg-primary' : 'text-muted'} py-2 px-2 rounded-circle justify-content-center d-flex`">
            <strong>Spal Process</strong>
          </div>
          <div @click="activeTab = 5" style="cursor:pointer" v-if="detailProjectContract?.project_stage?.is_running"
            :class="`col-md-2 ${activeTab === 5 ? 'bg-primary' : 'text-muted'} py-2 px-2 rounded-circle justify-content-center d-flex`">
            <strong>Trip Document</strong>
          </div>
          <div @click="activeTab = 6" style="cursor:pointer" v-if="detailProjectContract?.project_stage?.is_running || detailProjectContract?.project_stage?.is_end_stage"
            :class="`col-md-2 ${activeTab === 6 ? 'bg-primary' : 'text-muted'} py-2 px-2 rounded-circle justify-content-center d-flex`">
            <strong>Payment List</strong>
          </div>
          <div  @click="activeTab = 7" style="cursor:pointer"
            :class="`col-md-2 ${activeTab === 7 ? 'bg-primary' : 'text-muted'} py-2 px-2 rounded-circle justify-content-center d-flex`">
            <strong>Real Shipment</strong>
          </div>
        </b-row>
        <hr>
      </b-col>

      <b-col md="12">
        <div v-if="activeTab === 1" id="general">
            <!-- SUBMIT BUTTON -->
          <b-col lg="12" class="mb-4" v-if="['EDIT', 'PREVIEW'].includes(action)">
            <div :class="`d-flex align-items-center justify-content-end w-100`">
              <b-button
                v-if="['EDIT'].includes(action)"
                :disabled="saving"
                block
                variant="primary"
                type="submit"
                style="width:15%"
                class="mt-2 mr-3"
              >
                <span
                  v-if="saving"
                  class="spinner-border spinner-border-sm"
                />
                <div v-else>
                  <i class="fa fa-save"></i>
                  <span>Submit Data</span>
                </div>
              </b-button>
              <b-button
                  v-if="['EDIT'].includes(action)"
                  :disabled="saving"
                  block
                  type="button"
                  variant="danger"
                  style="width:10%"
      
                  @click="changeAction('PREVIEW')"
                >
                  <div>
                    <i class="fa fa-ban"></i>
                    <span>Cancel</span>
                  </div>
                </b-button>

                <b-button
                  type="button"
                  v-if="['PREVIEW'].includes(action)"
                  block
                  variant="primary"
                  style="width:15%"
                  @click="changeAction('EDIT')"
                >
                  <div>
                    <i class="fa fa-pen"></i>
                    <span>Edit Data</span>
                  </div>
                </b-button>
            </div>
          </b-col>

          <b-col lg="12" v-if="['ADD'].includes(action)">
            <div class="d-flex align-items-center justify-content-end w-100">
              <b-button
                :disabled="saving"
                block
                variant="primary"
                type="submit"
                style="width:15%"
              >
                <span
                  v-if="saving"
                  class="spinner-border spinner-border-sm"
                />
                <div v-else>
                  <i class="fa fa-save"></i>
                  <span>Submit Data</span>
                </div>
              </b-button>
            </div>
          </b-col>
          <b-col cols="12">
            <fieldset class="form-group border p-3">
              <legend class="w-auto px-2 text-primary">Project</legend>
              <b-row v-if="['ADD'].includes(action)">
                <b-col lg="12">
                  <b-alert :show="true" variant="primary">
                    <template v-for="(item,index) in company">
                      <b-form-radio inline v-model="project_type" :name="item.name" :key="index" :value="item.value">{{ item.label }}</b-form-radio>
                    </template>
                  </b-alert>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="6" v-if="project_type === 'existing'">
                  <b-form-group label-for="dateIssued" label="Projects" v-if="project_type === 'existing'">
                    <v-select
                      v-if="['ADD', 'EDIT'].includes(action)"
                      label="text"
                      name="project_sub_id"
                      v-model="form.project_sub_id"
                      :options="projects"
                      :reduce="
                        (projects) => projects.value
                      "
                      placeholder="Select Project"
                      required
                      @input="onSelectedProject"
                    ></v-select>
                    <strong v-else>{{ detailProjectContract?.project_sub?.project?.project_name }} - {{ detailProjectContract?.project_sub?.name }}</strong>
                  </b-form-group>
                </b-col>
                <b-col :lg="project_type === 'new_project' ? '12' : '6'">
                  <b-form-group
                    label="Customer"
                    label-for="customer_id"
                  >
                    <v-select
                      v-if="['ADD', 'EDIT'].includes(action)"
                      label="text"
                      name="customer_id"
                      v-model="form.customer_id"
                      :options="optionsListCustomer"
                      :reduce="
                        (optionsListCustomer) => optionsListCustomer.value
                      "
                      placeholder="Select Customer"
                      required
                      @input="onSelectedCustomer(form.customer_id)"
                    ></v-select>
                    <strong v-else>{{ detailProjectContract?.customer?.customer_code }} - {{ detailProjectContract?.customer?.account_name }}</strong>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-button @click="openCustomerSlide"  variant="success" size="sm" v-if="project_type === 'new_project'"><i class="fa fa-plus"></i> &nbsp; ADD NEW CUSTOMER</b-button>
            </fieldset>
          </b-col>
          <b-col lg="12">
            <b-row>
              <!-- PROJECT PLAN -->
              <b-col lg="6" md="12" v-if="project_type === 'existing' && form.customer_id && form.project_sub_id">
                <fieldset class="form-group border p-3">
                  <legend class="w-auto px-2 text-primary">Project Info</legend>
                  <div class="d-flex w-100 overflow-auto ">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th class="text-truncate">PROJECT NAME</th>
                          <th class="text-truncate">PROJECT SUB NAME</th>
                          <th class="text-truncate">LOCATION</th>
                          <th>STATUS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{ projectDetail?.projectName }}</td>
                          <td>{{ projectDetail?.projectSub }}</td>
                          <td>{{ projectDetail?.location }}</td>
                          <td>{{ projectDetail?.status ? 'Active' : 'Inactive' }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </fieldset>
              </b-col>

              <!-- LOG ACITIVITY HISTORY PROJECT CONTRACT -->
              <b-col lg="6" v-if="['EDIT', 'PREVIEW'].includes(action)">
                <fieldset class="form-group border p-3">
                  <legend class="w-auto px-2 text-primary">Log History Activity</legend>
            
                  <div class="overflow-auto d-flex w-100 " style="max-height:544px">
                    <b-row>
                      <b-col lg="12" class="mb-4" v-for="(state, index) in detailProjectContract?.project_log" :key="index">
                        <div class="d-flex">
                          <strong class="mr-2">{{ state?.name }} -</strong>
                          <span class="text-muted">{{ moment(state.date_log).format('DD MMMM YYYY') }}</span>
                        </div>
                        <span>{{ state.log_description }}</span>
                        <!-- <div class="ml-4">
                          > <strong class="text-muted">New</strong> -> <strong class="text-primary">Proposition</strong> <i>(Stage)</i>
                        </div> -->
                        <br />
                        <strong>----</strong>
                      </b-col>
                    </b-row>
                  </div>
                </fieldset>
              </b-col>

              <!-- FORM PROJECT -->
              <b-col cols="6" v-if="(project_type === 'new_project' && form.customer_id) && ['ADD'].includes(action)">
                <fieldset class="form-group border p-3">
                  <legend class="w-auto px-2 text-primary">Project Form</legend>
                  <b-form @submit.prevent="submitFormProject">
                    <b-form-group label="Project Name" label-for="project_name">
                      <v-multiselect
                        v-model="formProject.selected_project"
                        placeholder="Search or add new project"
                        label="text"
                        track-by="value"
                        :close-on-select="true"
                        :clear-on-select="true"
                        :options="projects"
                        @search-change="searchProjects"
                        required
                      >
                        <span slot="noResult" @click="doAddProject">
                          Not Found. Click to add
                          <strong>{{ formProject.projectName }}</strong>
                        </span>
                      </v-multiselect>
                    </b-form-group>

                    <b-form-group
                      label="Project Location"
                      label-for="project_location"
                    >
                      <b-form-input
                        id="port_name"
                        type="text"
                        placeholder="Project Location"
                        maxlength="30"
                        v-model="formProject.location"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group
                      label="Sub Project"
                      label-for="sub_project_name"
                    >
                      <b-form-input
                        id="port_name"
                        type="text"
                        placeholder="Sub Project Name"
                        class="d-inline-block"
                        maxlength="30"
                        v-model="formProject.subName"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Ship to ship?" label-for="sts">
                      <b-form-checkbox
                        v-model="formProject.sts"
                        name="check-button"
                        switch
                        inline
                      >
                        {{ formProject.sts ? 'Yes' : 'No' }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-form>
                </fieldset>
              </b-col>
              <!-- COMPANY INFO -->
              <b-col lg="6" md='12'>
                <fieldset class="form-group border p-3" v-if="(form.customer_id && form.project_sub_id && project_type === 'existing') || (project_type === 'new_project' && form.customer_id)">
                  <legend class="w-auto px-2 text-primary">Company Info</legend>
                  <div class="d-flex w-100 overflow-auto ">
                    <table class="table mb-0 table-striped">
                      <tbody>
                        <tr>
                          <th width="15%" class="text-truncate">Nama Perusahaan</th>
                          <td width="5%">:</td>
                          <td width="30%" class="text-truncate">{{ customerDetail?.account_name ?? '-' }}</td>
                          <th width="15%">Alamat</th>
                          <td width="5%">:</td>
                          <td width="30%" class="text-truncate" style="max-width:200px"  v-b-tooltip.top="customerDetail?.address">{{ customerDetail?.address ?? '-' }}</td>
                        </tr>
                        <tr>
                          <th width="15%">No. Telepon</th>
                          <td width="5%">:</td>
                          <td width="30%">{{ customerDetail?.phone1 ?? customerDetail?.phone2 ?? '-' }}</td>
                          <th width="15%">Email</th>
                          <td width="5%">:</td>
                          <td width="30%">{{ customerDetail?.email ?? '-' }}</td>
                        </tr>
      
                        <tr>
                          <th width="15%">Negara</th>
                          <td width="5%">:</td>
                          <td width="30%">{{ customerDetail?.country_id ?? '-' }}</td>
                          <th width="15%">Provinsi</th>
                          <td width="5%">:</td>
                          <td width="30%">{{ customerDetail?.province ?? '-' }}</td>
                        </tr>
      
                        <tr>
                          <th width="15%">Kota</th>
                          <td width="5%">:</td>
                          <td width="30%" class='text-truncate'>{{ customerDetail?.city ?? '-' }}</td>
                          <th width="15%">Kecamatan</th>
                          <td width="5%">:</td>
                          <td width="30%">{{ customerDetail?.district ?? '-' }}</td>
                        </tr>
      
                        <tr>
                          <th width="15%">Kelurahan</th>
                          <td width="5%">:</td>
                          <td width="30%" class='text-truncate'>{{ customerDetail?.village ?? '-' }}</td>
                          <th width="15%"></th>
                          <td width="5%"></td>
                          <td width="30%"></td>
                        </tr>
      
                        <tr>
                          <th width="15%" class="text-truncate">PIC</th>
                          <td width="5%">:</td>
                          <td width="30%" class="text-truncate">{{ customerDetail?.name ?? '-' }}</td>
                          <th width="15%" class="text-truncate">No. Handphone</th>
                          <td width="5%">:</td>
                          <td width="30%" class="text-truncate">{{ customerDetail?.mobile1 ?? customerDetail?.mobile2 ?? '-' }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </fieldset>
              </b-col>
            </b-row>
          </b-col>
          <!-- DETAIL PROJECT CONTRACT -->
          <b-col lg="12">
            <b-row>
              <!-- DETAIL PROJECT CONTRACT FORM -->
              <b-col 
                :lg="'12'"
                v-if="(form.customer_id && form.project_sub_id && project_type === 'existing') || (project_type === 'new_project' && form.customer_id)"
              >
                <fieldset class="form-group border p-3">
                  <legend class="w-auto px-2 text-primary">Detail Project Contract Form</legend>
                  <b-row>
                    <b-col md="4">
                      <b-form-group label="Contract Number" label-for="contract">
                        <b-form-input
                          v-if="['ADD', 'EDIT'].includes(action)"
                          id="contract_number"
                          type="text"
                          placeholder="Type text"
                          v-model="form.contract_number"
                        ></b-form-input>
                        <strong v-else class="text-capitalize">{{ form.contract_number }}</strong>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="Company" label-for="company_id">
                        <v-select
                          v-if="['ADD', 'EDIT'].includes(action)"
                          name="company_id"
                          v-model="form.company_id"
                          :options="optionsCompanies"
                          label="text"
                          track-by="value"
                          :reduce="
                            (optionsCompanies) => optionsCompanies.value
                          "
                          placeholder="Select..."
                          class="w-100 mb-2"
                        ></v-select>
                        <strong v-else class="text-truncate">{{ showCompanyLabel(form.company_id)}}</strong>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <div class="d-flex">
                        <b-form-group label="Third Party" label-for="third_party" class="mr-2">
                          <b-form-checkbox
                            name="check-button"
                            switch
                            inline
                            v-model="form.third_party"
                            @change="() => {
                              if(form.third_party){
                                form.fleet_ownership = 'chartered'
                                onDataIntoPlan('fleet_ownership', form.fleet_ownership)
                              } else {
                                form.fleet_ownership = 'own-ship'
                                onDataIntoPlan('fleet_ownership', form.fleet_ownership)
                              }
                            }"
                          >
                            Yes / No
                          </b-form-checkbox>
                        </b-form-group>  
                        <b-form-group label="Third Party Company" label-for="third_party_company" v-if="form.third_party">
                          <template>
                            <b-form-input
                              v-if="['ADD', 'EDIT'].includes(action)"
                              id="third_party_company"
                              type="text"
                              placeholder="Type text"
                              v-model="form.third_party_company"
                            ></b-form-input>
                            <strong v-else class="text-capitalize">{{ form.third_party_company }}</strong>
                          </template>
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col md="4">
                      <template>
                        <div class="d-flex flex-column" v-if="['ADD','EDIT'].includes(action)">
                          <b-row>
                            <b-col md="6">
                              <span>Fleet ownership</span>
                              <b-form-select plain v-model="form.fleet_ownership" class="form-control" required name="fleet_ownership"
                                @change="onDataIntoPlan('fleet_ownership', form.fleet_ownership); onChangeOwnership(form.fleet_ownership)"
                                :disabled="form.third_party"
                              >
                                <option value="">Select ownership...</option>
                                <option value="own-ship">OWN SHIP</option>
                                <option value="chartered">CHARTERED</option>
                              </b-form-select>
                            </b-col>
                            <b-col md="6"  v-if="form.fleet_ownership">
                              <span>Fleet Type</span>
                              <b-form-select plain v-model="form.fleet_type_id" :options="optionsFleetTypes" class="form-control" required name="fleet_type_id" @change="onChangeFleetType(false, null, null); onDataIntoPlan('fleet_type_id', form.fleet_type_id);">
                                <template v-slot:first>
                                  <b-form-select-option :value="null">Select fleet type...</b-form-select-option>
                                </template>
                              </b-form-select>
                            </b-col>
                          </b-row>
                        </div>
                        <div class="d-flex flex-column" v-else>
                          <b-row class="mb-2">
                            <b-col lg="6" class="mb-2">
                              <span>Fleet ownership</span> <br />
                              <b-badge class="border border-dark text-dark text-uppercase mb-2" variant="none">{{ form.fleet_ownership === 'own-ship' ? "OWN SHIP" : 'CHARTERED' }}</b-badge>
                            </b-col>
                            <b-col lg="6" v-if="form.fleet_ownership">
                              <span>Fleet Type</span> <br />
                              <b-badge class="border border-dark text-dark text-uppercase mb-2" variant="none">{{ detailProjectContract?.fleet_type?.name }}</b-badge>
                            </b-col>
                          </b-row>
                        </div>
                      </template>
                    </b-col>
                    <b-col md="4" v-if="form.fleet_type_id">
                      <b-row v-if="['ADD','EDIT'].includes(action)">
                        <b-col md="4" v-for="(obj, objIndex) in form.fleets" :key="objIndex">
                          <div class="d-flex mt-2 flex-column w-100">
                            <strong class="text-truncate">{{ showVehicleName(obj.vehicle_id) }}</strong>
                            <b-button variant="primary" size="sm" @click="onOpenSlideDetailVehicle(obj.vehicle_id, objIndex, false, null)">{{ obj.vehicle_id ? 'Change ' : 'Select ' }} fleet</b-button>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row v-else>
                        <b-col lg='12'>Fleets</b-col>
                        <b-col md="4" >
                          <div class="d-flex flex-warp">
                            <b-badge v-for="(objDetail, detailIndex) in form.fleets" :key="detailIndex" class="border border-primary text-primary text-uppercase mb-2 mr-2" variant="none">{{ objDetail.vehicle_name }}</b-badge>
                          </div>
                        </b-col>
                      </b-row>
                    </b-col>
                  
                    <b-col md="4">
                      <b-form-group label="Collections Of Forms" label-for="contract">
                        <template v-if="['ADD','EDIT'].includes(action)">
                          <v-multiselect 
                            v-if="optionsForms.length > 0"
                            v-model="form.project_forms"
                            :options="optionsForms" 
                            :multiple="true" 
                            :group-select="true" 
                            placeholder="Select Forms" 
                            track-by="label" 
                            label="label"
                          />     
                          <strong v-else class="text-muted">No forms yet.</strong>
                        </template> 
                        <template v-else>
                          <div class="d-flex flex-wrap gap-2">
                            <b-badge 
                              v-for="(state, index) in form.project_forms"
                              :key="index"
                              class="border border-primary text-primary text-uppercase m-1" 
                              variant="none"
                            >
                              {{ state?.label }}
                            </b-badge>
                          </div>
                        </template>
                      </b-form-group>
                    </b-col>

                    <b-col md="4" v-if="!form.fleet_type_id" />

                    <b-col md="4">
                      <b-form-group label="Periode Start" label-for="contract">
                        <b-form-input
                          v-if="['ADD','EDIT'].includes(action)"
                          id="periode_start"
                          name="periode_start"
                          type="date"
                          v-model="form.periode_start"
                        ></b-form-input>
                        <strong v-else>{{ form?.periode_start ? moment(form?.periode_start).format('DD MMMM YYYY') : '-' }}</strong>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="Periode End" label-for="contract">
                        <b-form-input
                          v-if="['ADD','EDIT'].includes(action)"
                          id="periode_end"
                          name="periode_end"
                          type="date"
                          v-model="form.periode_end"
                        ></b-form-input>
                        <strong v-else>{{ form?.periode_start ? moment(form?.periode_start).format('DD MMMM YYYY') : '-' }}</strong>
                      </b-form-group>
                    </b-col> 
                    <b-col md="4">
                      <b-form-group label="Contract Type" label-for="contract">
                        <v-multiselect
                          v-if="['ADD','EDIT'].includes(action)"
                          v-model="form.contract_type_id"
                          placeholder="search/add contract"
                          label="name"
                          track-by="id"
                          :close-on-select="true"
                          :clear-on-select="true"
                          :options="optionsContractType"
                          @search-change="searchContractType"
                          required
                        >
                          <span slot="noResult" @click="doAddContractType">
                            Not Found. Click to add
                            <strong>{{ contractTypeName }}</strong>
                          </span>
                        </v-multiselect>   
                        <span v-if="['ADD','EDIT'].includes(action) && form.contract_type_id">{{ form.contract_type_id?.is_spal ? "Yes, using spal" : 'Not using spal' }}</span>
                        <strong v-if="['PREVIEW'].includes(action)">{{ detailProjectContract?.contract_type?.name }}</strong>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="Cargo Type" label-for="contract">
                        <v-select
                          v-if="['ADD', 'EDIT'].includes(action)"
                          label="text"
                          name="cargo_type_id"
                          v-model="form.cargo_type_id"
                          :options="optionsCargoType"
                          :reduce="
                            (optionsCargoType) => optionsCargoType.value
                          "
                          placeholder="Select..."
                          class="w-100 mb-2"
                          @input="onDataIntoPlan('cargo_type_id', form.cargo_type_id); onChangeCargoType()"
                          required
                        ></v-select>
                        <strong v-else>{{ detailProjectContract?.cargo_type?.name ?? '-' }}</strong>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="Terms" label-for="contract">
                        <v-select
                          v-if="['ADD', 'EDIT'].includes(action)"
                          label="text"
                          name="cargo_type_id"
                          v-model="form.terms_id"
                          :options="optionsTerms"
                          :reduce="
                            (optionsTerms) => optionsTerms.value
                          "
                          placeholder="Select..."
                          class="w-100 mb-1"
                        ></v-select>
                        <b-form-textarea v-if="['ADD','EDIT'].includes(action)" placeholder="Type Text" v-model="form.terms_remarks"></b-form-textarea>
                        <strong v-else>{{ detailProjectContract?.terms?.name ?? '-' }} <br /> Remarks: {{ form.terms_remarks }}</strong>
                      </b-form-group>
                    </b-col> 
                    <b-col md="4">
                      <b-form-group label="Total Quantity" label-for="contract">
                        <div class="d-flex align-items-center" v-if="form.cargo_type_id">
                          <strong class="mr-2">{{ totalQuantityAdd?.toLocaleString() }}</strong>
                          <strong v-if="optionUnit.length <= 0 && !unit_others">{{ form.unit }}</strong>
                          <v-select
                            v-if="optionUnit.length > 0 && !unit_others"
                            label="text"
                            name="unit"
                            v-model="form.unit"
                            :options="optionUnit"
                            :reduce="
                              (optionUnit) => optionUnit.value
                            "
                            placeholder="Select..."
                            class="w-100 mb-2"
                          ></v-select>
                          <b-form-input v-if="unit_others" size="sm" placeholder="Type Text" class="w-50" v-model="form.unit" />
                        </div>
                        <div v-else>
                          <i>Choose cargo type first.</i>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="POL" label-for="pol">
                        <template v-if="['ADD','EDIT'].includes(action)">
                          <div class="d-flex align-items-center mb-2" v-for="(obj, polIndex) in form.pol" :key="polIndex">
                            <v-select 
                              v-model="obj.value" 
                              label="text" 
                              :options="positionList" 
                              :reduce="positionList => positionList.text" 
                              placeholder="Select..." 
                              style="background-color:#ffffff;"
                              class="mr-2 w-100"
                              @input="onDataIntoPlan('pol', form.pol)"
                            />
                            <b-form-input v-if="obj.value === 'Others'" class="mr-2" size="sm" placeholder="Type Text" v-model="obj.valueOther" />
                            <i class="fa fa-plus text-success border border-success p-1 rounded-circle" style="cursor:pointer" @click="form.pol.push({value:'', valueOther:''})" v-if="polIndex === 0" />
                            <i class="fa fa-trash text-danger border border-danger p-1 rounded-circle" style="cursor:pointer" @click="form.pol.splice(polIndex, 1)" v-else />
                          </div>
                        </template>
                        <template v-else>
                          <div class="d-flex flex-wrap w-100">
                            <b-badge 
                              v-for="(obj, polIndex) in form.pol" :key="polIndex" 
                              class="border border-primary text-primary text-uppercase mb-2 mr-2" 
                              variant="none"
                            >
                              {{ obj?.value === "Others" ? obj.valueOther : obj?.value ?? '-' }}
                            </b-badge>
                          </div>
                        </template>
                      </b-form-group>
                    </b-col> 
                    <b-col md="4">
                      <b-form-group label="POD" label-for="pod">
                        <template v-if="['ADD','EDIT'].includes(action)">
                          <div class="d-flex align-items-center mb-2" v-for="(obj, podIndex) in form.pod" :key="podIndex">
                            <v-select 
                              v-model="obj.value" 
                              label="text" 
                              :options="positionList" 
                              :reduce="positionList => positionList.text" 
                              placeholder="Select..." 
                              style="background-color:#ffffff;"
                              class="mr-2 w-100"
                              @input="onDataIntoPlan('pod', form.pod)"
                            />
                            <b-form-input v-if="obj.value === 'Others'" class="mr-2" size="sm" placeholder="Type Text" v-model="obj.valueOther" />
                            <i class="fa fa-plus text-success border border-success p-1 rounded-circle" style="cursor:pointer" @click="form.pod.push({value:'', valueOther:''})" v-if="podIndex === 0" />
                            <i class="fa fa-trash text-danger border border-danger p-1 rounded-circle" style="cursor:pointer" @click="form.pod.splice(podIndex, 1)" v-else />
                          </div>
                        </template>
                        <template v-else>
                          <div class="d-flex flex-wrap w-100">
                            <b-badge 
                              v-for="(obj, podIndex) in form.pod" :key="podIndex" 
                              class="border border-primary text-primary text-uppercase mb-2 mr-2" 
                              variant="none"
                            >
                              {{ obj?.value === "Others" ? obj.valueOther : obj?.value ?? '-' }}
                            </b-badge>
                          </div>
                        </template>
                      </b-form-group>
                    </b-col> 
                    <b-col md="4">
                      <b-form-group label="Laycan" label-for="laycan">
                        <date-picker v-if="['ADD','EDIT'].includes(action)" 
                          v-model="form.laycan" type="date" range placeholder="Select date range" format="DD-MM-YYYY"
                          style="width: 100%;"
                        ></date-picker>
                        <div class="d-flex" v-else>
                          <strong>{{ form?.laycan?.[0] ? moment(form?.laycan?.[0] ).format('DD MMM YYYY') : '-' }}</strong> 
                          <span class="mx-2">s/d</span>
                          <strong>{{ form?.laycan?.[1] ? moment(form?.laycan?.[1] ).format('DD MMM YYYY') : '-' }}</strong>
                        </div>
                      </b-form-group>
                    </b-col> 
                    <b-col md="4">
                      <b-form-group label="Distance" label-for="distance">
                        <b-form-input
                          v-if="['ADD','EDIT'].includes(action)"
                          id="distance"
                          name="distance"
                          placeholder="Type Number ..."
                          type="number"
                          v-model="form.distance"
                        ></b-form-input>
                        <small v-if="['ADD','EDIT'].includes(action)">{{form.distance ? Number(form.distance)?.toLocaleString() : 0 }}</small>
                        <strong v-else>{{ form?.distance?.toLocaleString() }}</strong>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-row>
                        <b-col md="6">
                          <b-form-group label="Currency" label-for="currency_id">
                            <v-select 
                              v-if="['ADD','EDIT'].includes(action)"
                              v-model="form.currency_id" 
                              label="text" 
                              :options="optionsCurrency" 
                              :reduce="optionsCurrency => optionsCurrency.value" 
                              placeholder="Select..." 
                              style="background-color:#ffffff;"
                              class="mr-2 w-100"
                            />
                            <strong v-else>{{ optionsCurrency?.find(obj => obj.value === form?.currency_id)?.text}}</strong>
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group label="Currency Rate" label-for="currency_rate" v-if="form?.currency_id && optionsCurrency?.find(obj => obj?.value === form.currency_id)?.text !== 'IDR'">
                            <b-form-input
                              v-if="['ADD','EDIT'].includes(action)"
                              id="currency_rate"
                              name="currency_rate"
                              type="number"
                              placeholder="0"
                              v-model="form.currency_rate"
                            ></b-form-input>
                            <small v-if="['ADD','EDIT'].includes(action)">{{ formatCurrency(Number(form.currency_rate)) }}</small>
                            <strong v-else>{{ formatCurrency(Number(form.currency_rate)) }}</strong>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>  
                    <b-col md="4">
                      <b-form-group label="Freight Rate (Est)" label-for="shipmentPlanNumber">
                        <template v-if="['ADD','EDIT'].includes(action)">
                          <div class="d-flex flex-column ">
                            <b-form-input
                              id="freight_rate_est"
                              name="freight_rate_est"
                              placeholder="Type Number ..."
                              v-model="form.freight_rate_est"
                              type="number"
                              class="mb-2"
                              required
                            ></b-form-input>
                            <small v-if="optionsCurrency?.find(obj => obj?.value === form.currency_id)?.text !== 'IDR'">$ {{ Number(form.freight_rate_est)?.toLocaleString() }}</small>
                            <small v-else>{{ formatCurrency(form.freight_rate_est) }}</small>
                            <b-form-radio-group inline v-model="form.ppn">
                              <b-form-radio value="include" name="ppn" key="include">Include PPN</b-form-radio>
                              <b-form-radio value="exclude" name="ppn" key="exclude">Exclude PPN</b-form-radio>
                            </b-form-radio-group>
                          </div>
                        </template>
                        <template v-else>
                          <div class="d-flex flex-column">
                            <strong>{{ formatCurrency(form?.freight_rate_est) }}</strong>
                            <strong class="text-muted">{{ form.ppn == 'include' ? 'Include ' : 'Exclude ' }} PPN</strong>
                          </div>
                        </template>
                      </b-form-group>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="Shipment Plan Number" label-for="shipmentPlanNumber">
                        <strong class="text-muted">{{ shipmentPlanNumber }}</strong>
                      </b-form-group>
                    </b-col>  
                    <b-col md="8">
                      <b-form-group label="Remarks" label-for="remarks">
                        <b-form-textarea
                          v-if="['ADD','EDIT'].includes(action)"
                          id="remarks"
                          name="remarks"
                          placeholder="Type Text ..."
                          v-model="form.remarks"
                        ></b-form-textarea>
                        <strong v-else>{{ form.remarks }}</strong>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <div class="d-flex flex-wrap">
                        <b-form-group label="Need More Detail" label-for="remarks" class="mr-4">
                          <b-form-checkbox
                            name="check-button"
                            switch
                            inline
                            v-model="form.need_more_detail"
                            disabled
                          >
                            Yes / No
                          </b-form-checkbox>
                        </b-form-group>
                        <b-form-group label="Need Tender" label-for="remarks" class="mr-4">
                          <b-form-checkbox
                            name="check-button"
                            switch
                            inline
                            v-model="form.is_tender"
                            :disabled="['PREVIEW'].includes(action)"
                          >
                            Yes / No
                          </b-form-checkbox>
                        </b-form-group> 
                        <b-form-group label="Need Pre-Fixtured Analysis" label-for="remarks" class="mr-4">
                          <b-form-checkbox
                            name="check-button"
                            switch
                            inline
                            v-model="form.is_pfa"
                            :disabled="['PREVIEW'].includes(action)"
                          >
                            Yes / No
                          </b-form-checkbox>
                        </b-form-group>
                        <b-form-group label="Need Document" label-for="remarks" class="mr-4">
                          <b-form-checkbox
                            name="check-button"
                            switch
                            inline
                            v-model="form.need_document"
                            :disabled="['PREVIEW'].includes(action)"
                          >
                            Yes / No
                          </b-form-checkbox>
                        </b-form-group>
                        <b-form-group label="Project need approvals" label-for="remarks">
                          <b-form-checkbox
                            name="check-button"
                            switch
                            inline
                            v-model="form.need_approval"
                            :disabled="['PREVIEW', 'EDIT'].includes(action)"
                          >
                            Yes / No
                          </b-form-checkbox>
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col md="4">
                      <b-form-group label="How much approval is needed?" label-for="shipmentPlanNumber" v-if="form.need_approval">
                        <b-form-select 
                          v-if="['ADD'].includes(action)"
                          plain size="sm" v-model="form.many_approval" required @change="onChangeManyApproval"> 
                          <b-form-select-option value="">Select values....</b-form-select-option>
                          <b-form-select-option value="1">1</b-form-select-option>
                          <b-form-select-option value="2">2</b-form-select-option>
                          <b-form-select-option value="3">3</b-form-select-option>
                          <b-form-select-option value="4">4</b-form-select-option>
                        </b-form-select>
                        <strong v-else>{{ form.many_approval }} Approvals</strong>
                      </b-form-group>
                    </b-col>  
                    <b-col md="4">
                      <b-form-group label="Users Approval" label-for="shipmentPlanNumber" v-if="form.many_approval">
                        <template v-if="['ADD','EDIT'].includes(action)"> 
                          <div class="d-flex align-items-center mb-2" v-for="(user, userIndex) in form.user_approval" :key="userIndex">
                            <strong>{{ userIndex + 1}}. </strong>
                            <v-select
                              label="text"
                              name="user"
                              v-model="user.user"
                              :options="optionsExpertUsers"
                              :reduce="
                                (optionsExpertUsers) => optionsExpertUsers.value
                              "
                              placeholder="Select User"
                              class="w-100 ml-2"
                              required
                            ></v-select>
                          </div>
                        </template>
                        <template v-else>
                          <div class="d-flex align-items-center mb-2" v-for="(user, userIndex) in detailProjectContract?.user_approval" :key="userIndex">
                            <strong class="mr-2">{{ userIndex + 1 }}.</strong>
                            <strong>{{ user?.name }}</strong>
                          </div>
                        </template>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </fieldset>
              </b-col>
              <b-col lg="12">
                <b-row>
                  <!-- TENDER -->
                  <b-col lg="6" v-if="(form.is_tender && form.customer_id && form.project_sub_id) || (form.is_tender && form.customer_id)">
                    <fieldset class="form-group border p-3">
                      <legend class="w-auto px-2 text-primary">Tender Form</legend>
                      <table class="table table-striped">
                        <tbody>
                          <tr>
                            <th width="15%">PIC</th>
                            <td width="5%">:</td>
                            <td width="30%">
                              <b-form-input v-if="['ADD', 'EDIT'].includes(action)" placeholder="Type Text" required v-model="form.formTender.pic" />
                              <span v-else>{{ form.formTender.pic }}</span>
                            </td>
                            <th width="15%">Url</th>
                            <td width="5%">:</td>
                            <td width="30%">
                              <b-form-input v-if="['ADD', 'EDIT'].includes(action)" placeholder="Type Text" required v-model="form.formTender.url" />
                              <span v-else>{{ form.formTender.url }}</span>
                            </td>
                          </tr>
                          <tr>
                            <th width="15%">Description</th>
                            <td width="5%">:</td>
                            <td colSpan="4">
                              <b-form-textarea v-if="['ADD', 'EDIT'].includes(action)" placeholder="Type Text" required v-model="form.formTender.description_of_tender" />
                              <span v-else>{{ form.formTender.description_of_tender }}</span>
                            </td>
                          </tr>
                          <tr>
                            <th width="15%">File</th>
                            <td width="5%">:</td>
                            <td  colSpan="4">
                              <b-form-file
                                v-if="['ADD', 'EDIT'].includes(action)"
                                plain
                                id="file"
                                v-model="form.formTender.file"
                                accept="image/*,application/*"
                                multiple
                                class="form-control"
                              ></b-form-file>
                              <img v-else src="../../../assets/images/activity/pdf.png" alt="img-image" style="width: 40px; height: 40px;" v-else />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </fieldset>
                  </b-col>
                  <!-- DOCUMENT ATTACHMENT -->
                  <b-col lg="6" v-if="form.need_document && form.customer_id && form.project_sub_id">
                    <fieldset class="form-group border p-3">
                      <legend class="w-auto px-2 text-primary">Document Attachment</legend>
                      <div class="overflow-auto" style="max-height:300px">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th class="text-center" style="vertical-align:middle; width: 20px">NO</th>
                              <th class="text-center text-truncate" style="vertical-align:middle; width: 250px">Name of File / Document</th>
                              <th class="text-center text-truncate">File / Document</th>
                              <th class="text-center" style="vertical-align:middle; width: 20px">#</th>
                            </tr>
                          </thead>
                          <tbody v-if="['ADD', 'EDIT'].includes(action)"> 
                            <tr v-for="(state, index) in form.form_document" :key="index">
                              <td class="text-center align-top">{{ index + 1 }}</td>
                              <td class="align-top">
                                <b-form-input placeholder="Name of File" v-model="state.filename" required />
                              </td>
                              <td>
                                <b-form-file
                                  :required="state?.url ? false : true"
                                  :id="'fileSupport'+index"
                                  placeholder="Choose a file"
                                  accept="application/pdf"
                                  v-model="state.file"
                                  >
                                </b-form-file>
                                <a class="text-primary">{{ state?.filename }}</a>
                              </td>
                              <td class="text-center align-top">
                                <b-button size="sm" variant="danger" @click="form.form_document.splice(index, 1)" v-if="index !== 0"><i class="fa fa-trash pr-0"></i></b-button>
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-else>
                              <tr v-for="(state, index) in detailProjectContract.project_document" :key="index">
                                <td class="text-center align-top">{{ index + 1 }}</td>
                                <td>
                                  <span>{{ state?.filename }}</span>
                                </td>
                                <td class="text-center">
                                  <a :href="state?.url" class="text-primary">{{ state?.filename }}.{{ state?.extension }}</a>
                                </td>
                                <td></td>
                              </tr>
                            </tbody>
                        </table>
                      </div>
                      <b-button v-if="['ADD', 'EDIT'].includes(action)" block variant="success" @click="addFormDocument()"><i class="fa fa-plus pr-0"></i> ADD MORE ATTACHMENT</b-button>
                    </fieldset>
                  </b-col> 
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <!-- DETAIL PLAN -->
          <b-col lg="12" v-if="(form.need_more_detail && form.customer_id && form.project_sub_id && project_type === 'existing') || (form.need_more_detail && form.customer_id && project_type === 'new_project')">
            <fieldset class="form-group border p-3">
              <legend class="w-auto px-2 text-primary">Detail Project Contract Plan</legend>
              <div class="d-flex w-100 overflow-auto" style="max-height:500px">
                <table class="table table-bordered table-sm">
                  <thead>
                    <tr>
                      <th class="text-center" rowspan="2" style="vertical-align:middle;">NO</th>
                      <th class="text-center text-truncate" rowspan="2" style="vertical-align:middle; min-width: 200px;" >SHIPMENT PLAN DETAIL NUMBER</th>
                      <th class="text-center text-truncate" rowspan="2" style="vertical-align:middle; min-width: 350px;" >DESCRIPTION</th>
                      <th class="text-center text-truncate" rowspan="2" style="vertical-align:middle; min-width: 250px;">FLEET</th>
                      <th class="text-center text-truncate" rowspan="2" style="vertical-align:middle; min-width: 300px;">CARGO TYPE</th>
                      <th class="text-center text-truncate" rowspan="2" style="vertical-align:middle; min-width: 300px;">DRAUGHT TYPE</th>
                      <th class="text-center text-truncate" rowspan="2" style="vertical-align:middle; min-width: 300px;">LOCATION</th>
                      <th class="text-center text-truncate" rowspan="2" style="vertical-align:middle; min-width: 250px;">SHIPMENT (MONTH)</th>
                      <th class="text-center text-truncate" colspan="2" style="min-width: 250px;">PLAN DATE</th>
                      <th class="text-center text-truncate" rowspan="2" style="vertical-align:middle; min-width: 250px;">PLAN QUANTITY</th>
                      <th class="text-center text-truncate" rowspan="2" style="vertical-align:middle; min-width: 250px;">REMARKS</th>
                      <th class="text-center text-truncate" rowspan="2" style="vertical-align:middle;">ACTION</th>
                    </tr>
                    <tr>
                      <th class="text-center text-truncate" style="min-width: 250px;">ETA POL</th>
                      <th class="text-center text-truncate" style="min-width: 250px;">ETA POD</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(state, index) in form?.detail_plan" :key="index">
                      <td class="text-center align-top">{{ index + 1 }}</td>
                      <td class="text-center text-truncate align-top"><span class="text-muted">{{shipmentPlanNumber +`-${formatNumber(index + 1)}`}}</span></td>
                      <td class="text-center align-top">
                        <b-form-textarea v-if="['ADD', 'EDIT'].includes(action)" placeholder="Type Text" type="text" id="description" name="description" v-model="state.description" size="sm" rows="4"></b-form-textarea>
                        <span v-else>{{ state?.description ?? '-' }}</span>
                      </td>
                      <td class="text-left align-top" v-if="['ADD', 'EDIT'].includes(action)">
                        <b-row class="mb-2">
                          <b-col lg="12" class="mb-2">
                            <span>Fleet ownership</span>
                            <b-form-select plain v-model="state.fleet_ownership" class="form-control" required name="fleet_ownership"
                              @change="onChangeOwnership(state.fleet_ownership)"
                            >
                              <option value="">Select ownership...</option>
                              <option value="own-ship">OWN SHIP</option>
                              <option value="chartered">CHARTERED</option>
                            </b-form-select>
                          </b-col>
                          <b-col lg="12" v-if="state.fleet_ownership">
                            <span>Fleet Type</span>
                            <b-form-select plain v-model="form.fleet_type_id" :options="optionsFleetTypes" class="form-control" required name="fleet_type_id" @change="onChangeFleetType(true, form.fleet_type_id, index);">
                              <template v-slot:first>
                                <b-form-select-option :value="null">Select fleet type...</b-form-select-option>
                              </template>
                            </b-form-select>
                          </b-col>
                        </b-row>
                        <b-row v-if="state.fleet_type_id">
                          <b-col md="12" v-for="(objDetail, detailIndex) in state.fleets" :key="detailIndex">
                            <div class="d-flex mt-2 flex-column w-100">
                              <strong class="text-truncate">{{ showVehicleName(objDetail.vehicle_id) }}</strong>
                              <b-button variant="primary" size="sm" @click="onOpenSlideDetailVehicle(objDetail?.vehicle_id, detailIndex, true, index)">{{ objDetail.vehicle_id ? 'Change ' : 'Select ' }} fleet</b-button>
                            </div>
                          </b-col>
                        </b-row>
                      </td>
                      <td class="text-left align-top" v-else>
                        <b-row class="mb-2">
                          <b-col lg="6" class="mb-2">
                            <span class="text-truncate">Fleet ownership</span> <br />
                            <b-badge class="border border-dark text-dark text-uppercase mb-2" variant="none">{{ state.fleet_ownership === 'own-ship' ? "OWN SHIP" : 'CHARTERED' }}</b-badge>
                          </b-col>
                          <b-col lg="6" v-if="state.fleet_ownership">
                            <span>Fleet Type</span> <br />
                            <b-badge class="border border-dark text-dark text-uppercase mb-2" variant="none">{{ showVehicleTypeName(state.fleet_type_id) }}</b-badge>
                          </b-col>
                        </b-row>
                        <b-row v-if="state.fleet_type_id">
                          <b-col lg='12'>Fleets</b-col>
                          <b-col md="12">
                            <div class="d-flex flex-wrap">
                              <b-badge v-for="(objDetail, detailIndex) in state.fleets" :key="detailIndex" class="border border-primary text-primary text-uppercase mb-2 mr-2" variant="none">{{ showVehicleName(objDetail.vehicle_id) }}</b-badge>
                            </div>
                          </b-col>
                        </b-row>
                      </td>
                      <td class="text-left align-top">
                        <b-form-group class="mb-1" label="Cargo Type" label-for="cargo_type_id">
                          <v-select
                            v-if="['ADD', 'EDIT'].includes(action)"
                            label="text"
                            name="cargo_type_id"
                            v-model="state.cargo_type_id"
                            :options="optionsCargoType"
                            :reduce="
                              (optionsCargoType) => optionsCargoType.value
                            "
                            placeholder="Select User"
                            class="w-100 mb-2"
                            required
                          ></v-select>
                          <b-badge v-else class="border border-dark text-dark text-uppercase mb-2" variant="none">{{ cargoTypeLabel(state.cargo_type_id) }}</b-badge>
                        </b-form-group>
                        <b-form-group class="mb-1" label="Acceptance Date" label-for="acceptance_date">
                          <date-picker v-if="['ADD', 'EDIT'].includes(action)" v-model="state.acceptance_date" class="custom-date-picker" value-type="DD-MM-YYYY HH:mm" type="datetime" placeholder="Select date" format="DD-MM-YYYY HH:mm" style="width: 100%;"></date-picker>
                          <strong v-else>{{ state.acceptance_date ? moment(state.acceptance_date).format('DD MMM YYYY HH:mm') : '' }}</strong>
                        </b-form-group>
                        <div class="d-flex w-100 border mb-2" />
                        <b-form-group class="mb-2 d-flex flex-column" label="Term">
                          <b-form-select  v-if="['ADD', 'EDIT'].includes(action)"  plain size="sm"  :options="optionsProjectTerms" v-model="state.terms_id">
                            <b-form-select-option :value="null">Select Term</b-form-select-option>
                          </b-form-select>
                          <b-badge v-else class="border border-dark text-dark text-uppercase mb-2" variant="none">{{ optionsProjectTerms?.find(obj => obj.value === state?.terms_id)?.label }}</b-badge>
                          <b-form-textarea v-if="['ADD', 'EDIT'].includes(action)" rows="2" placeholder="Text Type" class="mt-2" v-model="state.terms_remarks" /> 
                          <span v-else> <br/> Remarks: <span class="text-muted">{{ state?.terms_remarks ?? '-' }}</span></span>
                        </b-form-group>
                      </td>
                      <td class="text-left align-top">
                        <b-form-select v-if="['ADD', 'EDIT'].includes(action)" plain size="sm" v-model="state.draught_type">
                          <b-form-select-option value="draught_survey_loading">Draught Survey Loading</b-form-select-option>
                          <b-form-select-option value="draught_survey_discharge">Draught Survey Discharge</b-form-select-option>
                        </b-form-select>
                        <span v-else>{{ capitalizeString(state.draught_type) }}</span>
                      </td>
                      <td class="text-left align-top">
                          <span>POL</span>
                          <template v-if="['ADD', 'EDIT'].includes(action)">
                            <div class="d-flex align-items-center mb-2 w-100" v-for="(obj, polIndex) in state.pol" :key="polIndex">
                              <v-select 
                                v-model="form.detail_plan[index].pol[polIndex].value" 
                                label="text" 
                                :options="positionList" 
                                :reduce="positionList => positionList.text" 
                                placeholder="Select..." 
                                style="background-color:#ffffff;"
                                class="mr-2 w-100"
                              />
                              <b-form-input v-if="obj.value === 'Others'" class="mr-2" size="sm" placeholder="Type Text" v-model="form.detail_plan[index].pol[polIndex].valueOther" />
                              <i class="fa fa-plus text-success border border-success p-1 rounded-circle" style="cursor:pointer" @click="state.pol.push({value:'', valueOther:''})" v-if="polIndex === 0" />
                              <i class="fa fa-trash text-danger border border-danger p-1 rounded-circle" style="cursor:pointer" @click="state.pol.splice(polIndex, 1)" v-else />
                            </div>
                          </template>

                          <template v-else>
                            <div class="d-flex flex-wrap w-100">
                              <b-badge 
                                v-for="(obj, polIndex) in state.pol" :key="polIndex" 
                                class="border border-primary text-primary text-uppercase mb-2 mr-2" 
                                variant="none"
                              >
                                {{ obj?.value === "Others" ? obj.valueOther : obj?.value ?? '-' }}
                              </b-badge>
                            </div>
                          </template>
                          <div class="d-flex w-100 border mb-2" />
                          <span>POD</span>
                          <template v-if="['ADD', 'EDIT'].includes(action)">
                            <div class="d-flex align-items-center mb-2 w-100" v-for="(obj, podIndex) in state.pod" :key="podIndex">
                              <v-select 
                                v-model="form.detail_plan[index].pod[podIndex].value" 
                                label="text" 
                                :options="positionList" 
                                :reduce="positionList => positionList.text" 
                                placeholder="Select..." 
                                style="background-color:#ffffff;"
                                class="mr-2 w-100"
                              />
                              <b-form-input v-if="obj.value === 'Others'" class="mr-2" size="sm" placeholder="Type Text" v-model="obj.valueOther" />
                              <i class="fa fa-plus text-success border border-success p-1 rounded-circle" style="cursor:pointer" @click="state.pod.push({value:'', valueOther:''})" v-if="podIndex === 0" />
                              <i class="fa fa-trash text-danger border border-danger p-1 rounded-circle" style="cursor:pointer" @click="state.pod.splice(podIndex, 1)" v-else />
                            </div>
                          </template>
                          <template v-else>
                            <div class="d-flex flex-wrap w-100">
                              <b-badge 
                                v-for="(obj, podIndex) in state.pod" :key="podIndex" 
                                class="border border-primary text-primary text-uppercase mb-2 mr-2" 
                                variant="none"
                              >
                                {{ obj?.value === "Others" ? obj.valueOther : obj?.value ?? '-' }}
                              </b-badge>
                            </div>
                          </template>
                      </td>
                      <td class="text-center align-top">
                        <b-form-select v-if="['ADD', 'EDIT'].includes(action)"  plain size="sm"  v-model="state.shipment">
                          <b-form-select-option value="">SELECT MONTH</b-form-select-option>
                          <b-form-select-option value="JANUARY">JANUARI</b-form-select-option>
                          <b-form-select-option value="FEBRUARI">FEBRUARI</b-form-select-option>
                          <b-form-select-option value="MARET">MARET</b-form-select-option>
                          <b-form-select-option value="APRIL">APRIL</b-form-select-option>
                          <b-form-select-option value="MEI">MEI</b-form-select-option>
                          <b-form-select-option value="JUNI">JUNI</b-form-select-option>
                          <b-form-select-option value="JULI">JULI</b-form-select-option>
                          <b-form-select-option value="AGUSTUS">AGUSTUS</b-form-select-option>
                          <b-form-select-option value="SEPTEMBER">SEPTEMBER</b-form-select-option>
                          <b-form-select-option value="OKTOBER">OKTOBER</b-form-select-option>
                          <b-form-select-option value="NOVEMBER">NOVEMBER</b-form-select-option>
                          <b-form-select-option value="DESEMBER">DESEMBER</b-form-select-option>
                        </b-form-select>
                        <span v-else>{{ state?.shipment }}</span>
                      </td>
                      <td class="text-center align-top" colspan="2">
                        <b-row>
                          <b-col md="6">
                            <b-form-input v-if="['ADD', 'EDIT'].includes(action)"  type="date" id="eta_pol" v-model="state.eta_pol" class="mb-1" size="sm" ></b-form-input>
                            <span v-else>{{ state.eta_pol ? moment(state.eta_pol).format('DD MMMM YYYY') : '-' }}</span>
                          </b-col>
                          <b-col md="6">
                            <b-form-input v-if="['ADD', 'EDIT'].includes(action)"  type="date" id="eta_pod" v-model="state.eta_pod" class="mb-1" size="sm" ></b-form-input>
                            <span v-else>{{ state.eta_pod ? moment(state.eta_pod).format('DD MMMM YYYY') : '-' }}</span>
                          </b-col>
                        </b-row>
                      </td>
                      <td class=" align-top">
                        <b-form-input v-if="['ADD', 'EDIT'].includes(action)" type="number" id="plan_quantity" v-model="state.plan_quantity" size="sm"  placeholder="0" required></b-form-input>
                        <small class="text-left" v-if="['ADD', 'EDIT'].includes(action)">{{state.plan_quantity ? Number(state.plan_quantity)?.toLocaleString() : 0 }}</small>
                        <span v-else>{{ state.plan_quantity.toLocaleString() }}</span>
                      </td>
                      <td class="text-center align-top">
                        <b-form-textarea v-if="['ADD', 'EDIT'].includes(action)" rows="4" placeholder="Comments if any / Komentar jika ada" v-model="state.remarks" name="remarks"></b-form-textarea>
                        <span v-else>{{ state?.remarks ?? '-' }}</span>
                      </td>
                      <td class="text-center align-top">
                        <b-button size="sm" variant="danger" @click="form.detail_plan.splice(index, 1)" v-if="index !== 0 && ['ADD', 'EDIT'].includes(action)"><i class="fa fa-trash pr-0"></i></b-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <b-button v-if="['ADD', 'EDIT'].includes(action)" block variant="success" @click="addDetailPlanData(false)"><i class="fa fa-plus pr-0"></i> ADD MORE PLAN</b-button>
            </fieldset>
          </b-col>
          <b-col lg="12" v-if="['ADD'].includes(action)">
            <div class="d-flex align-items-center justify-content-end w-100">
              <b-button
                :disabled="saving"
                block
                variant="primary"
                type="submit"
                style="width:15%"
              >
                <span
                  v-if="saving"
                  class="spinner-border spinner-border-sm"
                />
                <div v-else>
                  <i class="fa fa-save"></i>
                  <span>Submit Data</span>
                </div>
              </b-button>
            </div>
          </b-col>
          <b-col md="12">
            <!-- SLIDE - PREVIEW DETAIL FLEETS -->
            <b-sidebar
              v-model="slidePreviewDetailVehicle"
              id="detail_vehicle"
              title="Detail Fleet"
              width="100%"
              backdrop-variant="dark"
              bg-variant="white"
              right
              backdrop
              shadow>
              <template #default="{hide}">
                <SlidePreviewDetailVehicle 
                  :options-fleets="optionsFilteredFleets" 
                  :fleet="selectedFleet" 
                  :fleet-type-id="form.fleet_type_id" 
                  @updateVehicleId="updateVehicleId" 
                  :ownership="form.fleet_ownership" 
                  :on-open-slide-detail-vehicle="onOpenSlideDetailVehicle"
                />
              </template>
            </b-sidebar>
            <!-- SLIDE - PREVIEW DETAIL FLEETS END -->
          </b-col>
        </div>
        <div v-if="activeTab === 2" id="form-list">
          <TabFormList 
            :formData="detailProjectContract" 
            :openInspectionChecklistSlide="openInspectionChecklistSlide" 
          />
        </div>
        <div v-if="activeTab === 3" id="pfa">
          <SlideFormPFA 
            :pfaId="detailProjectContract?.id" 
            :pfaAction="'PREVIEW'" 
            :options-cargo-type="optionsCargoType" 
            :optionsFleets="optionsFleets" 
            :onExportPFA="onExportPFA" 
            :refreshDataTable="() => {}" 
            :openPfaSlide="() => {}" 
          />
        </div>
        <div v-if="activeTab === 4" id="spal-process">
          <SlideSpalProcess
            :id="detailProjectContract?.id"
            :closeSlideSpalProcess="() => {}"
            :action="'PREVIEW'"
          />
        </div>
        <div v-if="activeTab === 5" id="trip-document">
          <TabTripDocument :projectData="detailProjectContract"  />
        </div>  
        <div v-if="activeTab === 6" id="payment-list">
          <TabPaymentList :projectData="detailProjectContract" />
        </div>
        <div v-if="activeTab === 7" id="real-shipment">
          <TabRealShipment 
            :projectData="detailProjectContract" 
            :optionsFleets="optionsFleets" 
          />
        </div>
      </b-col>
    </b-row>
  </form>
  <div v-else class="alert alert-info m-4">
    Loading...
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { projectsActions, companiesActions, fleetsActions, busdevActions, crewingActions, portsActions } from '@src/Utils/helper'
import SlidePreviewDetailVehicle from './SlidePreviewDetailVehicle.vue'
import TabFormList from './TabFormList.vue'
import TabPaymentList from './TabPaymentList.vue'
import TabRealShipment from './TabRealShipment.vue'
import TabPFA from './TabPFA.vue'
import TabTripDocument from './TabTripDocument.vue'
import SlideFormPFA from './SlideFormPFA.vue'
import SlideSpalProcess from './SlideSpalProcess.vue'
import { saveAs } from 'file-saver'

export default {
  name: 'FormAddPlan',
  props:{
    action:{
      type:String,
      default:''
    },  
    editId:{
      type:Number,
      default:true
    }, 
    optionsCompanies:{
      type:Array,
      default:() => []
    },  
    openCustomerSlide:{
      type:Function,
      default:true
    },  
    openInspectionChecklistSlide:{
      type:Function,
      default:true
    }, 
    refreshDataTable:{
      type:Function,
      default:true
    },  
    refreshDataTableWithoutCloseModal:{
      type:Function,
      default:true
    }, 
  },
  data() {
    return {
      activeTab:1,

      saving: false,
      loading:false,
      fleet_loading:false,
      detail_project_loading:false,
      isEdit:false,

      next_id:null,

      selectedFleet:null,
      fleetIndex:null,
      isDetail:false,
      detailIndex:0,

      slidePreviewDetailVehicle:false,

      optionsCurrency:[],
      projectOptions: [],
      optionsFleetTypes: [],
      optionsListCustomer: [],
      optionsTerms: [],
      optionsFleets: [],
      optionsFilteredFleets: [],
      optionsContractType: [],
      optionsCargoType: [],
      optionsExpertUsers: [],
      optionsForms: [],
      optionUnit: [],
      unit_others: false,
      listProjectStage:[],
      optionsProjectTerms:[],

      customerDetail:null,
      projectDetail:null,
      
      selectedCustomerData: null,

      project_type:'existing',

      detailProjectContract:null,
      
      // COMPANY
      company: [
        {
          name: 'single',
          label: 'EXISTING PROJECT',
          value: 'existing',
          disabled: false
        },
        {
          name: 'single',
          label: 'NEW PROJECT',
          value: 'new_project',
          disabled: false
        }
      ],

      // OWNERSHIP
      ownership: [
        {
          name: 'ownship',
          label: 'OWN SHIP',
          value: 'own-ship',
          disabled: false
        },
        {
          name: 'chartered',
          label: 'CHARTERED',
          value: 'chartered',
          disabled: false
        }
      ],

      positionList:[
        { value: 'Others', text: 'Others' }
      ],

      projects:[],
      formProject: {
        selected_project: null,
        projectName: '',
        location:'',
        subName:'',
        sts:0,
      },
      contractTypeName:'',
      form: {
        contract_number:null,
        third_party:false,
        third_party_company:'',
        currency_id: null,
        currency_rate:1,

        "active": 1, // 1 = active , 0 = Inactive
        "created_by": JSON.parse(localStorage.getItem('profile')).id,
        "created_by_email": JSON.parse(localStorage.getItem('profile')).email,
        "created_name": JSON.parse(localStorage.getItem('profile')).userDetail.name,
        "created_date": moment().format('YYYY-MM-DD'),
        "update_by":null,
        "update_date": "",
        "status":"proposed", // proposed, running, waiting, process

        shipment_plan_number:'',
        company_id:null,
        customer_id:null,
        "project_sub_id":null,
        "contract_type_id":null,
        "cargo_type_id":null,
        "total_quantity":null,
        "unit":'',
        "detail_project_stage_id":null,
        "history_project_id":null,
        "terms_id":null,
        "terms_remakrs":'',
        "periode_start":null,
        "periode_end":null,
        "fleet_ownership":"own-ship",
        "fleet_type_id":"",
        "fleets":[],
        "laycan":[],
        "pol":[{value:'',valueOther:''}],
        "pod":[{value:'',valueOther:''}],
        "distance":"",
        // "duration":"",
        "freight_rate_est":null,
        // "freight_rate":null,
        "ppn":'exclude',
        "remarks":"",

        is_tender: false,
        need_more_detail:true,
        need_approval:true,
        need_document:true,
        is_pfa:false,
        many_approval:'1',
        user_approval:[{
          user:null
        }],
        project_forms:[],

        form_document: [
          {
            filename:'',
            file:null
          }
        ],
        formTender:{
          pic:'',
          url:'',
          description_of_tender:'',
          file:''
        },
        detail_plan:[
          {
            "shipment_plan_detail_number":"",
            "status":"plan", // plan, progress, closed
            "description":"",
            "fleet_ownership":"own-ship",
            "fleet_type_id":null,
            "fleets":[],
            "cargo_type_id":null,
            "terms_id":null,
            "terms_remarks":'',
            "pol":[{value:'',valueOther:''}],
            "pod":[{value:'',valueOther:''}],
            "shipment":"",
            "eta_pol":"",
            "eta_pod":"",
            "plan_quantity":null,
            "remarks":"",

            acceptance_date:null,
            draught_type: 'draught_survey_loading'
          }
        ],
      },
    }
  },
  components:{
    SlidePreviewDetailVehicle,
    TabFormList,
    TabPaymentList,
    TabRealShipment,
    TabTripDocument,
    SlideFormPFA,
    SlideSpalProcess,
  },
  async mounted () {
    await this.getNextId()
    await this.getCurrency()
    await this.getPortList()
    await this.getListCustomer()
    await this.getContractType()
    await this.getVehicleType()
    await this.getCargoType()
    await this.getTerms()
    await this.fetchListProjects()
    await this.getProjectList()
    await this.fetchFleets()
    await this.getListCollectionForm()
    await this.fetchDataUserExpert()
    await this.getListProjectStage()
    await this.getListProjectTerms()
  },
  computed: {
    totalQuantityAdd() {
      return this.form.detail_plan.reduce((total, item) => total + Number(item.plan_quantity), 0);
    },
    shipmentPlanNumber(){
      if(this.form.fleet_ownership && this.form.contract_type_id && this.next_id) {
        let ownership = this.form.fleet_ownership === 'own-ship' ? 'ownship' : 'chartered'
        return `${ownership}-${this.form.contract_type_id?.alias}-${moment().format('YYMM')}-${this.formatNumber(this.next_id)}`
      } else {
        return 'Choose fleet ownership and contract type first.'
      }
    },
  },
  watch: {
    project_type: {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (value) {
          this.resetFormPlan()
        }
      },
    },
    editId: {
      deep: true,
      immediate:true,
      handler: function (value) {
        if (value) {
          this.getDetailProjectContract(value)
        }
      },
    },
    action: {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (value === "ADD") {
          this.resetFormPlan()
          this.activeTab = 1
        }
      },
    }
  },
  methods: {
    ...portsActions,
    ...projectsActions,
    ...fleetsActions,
    ...companiesActions,
    ...crewingActions,
    ...busdevActions,
    async getNextId(){
      let res = await this.fetchProjectContractNextId()

      if (res.data.status === true) {
        this.next_id = res.data.data
      } else {
        this.next_id = 0
      }
    },
    capitalizeString(str) {
      return str
        .split('_') // Split the string into an array
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
        .join(' '); // Join the words back into a single string with spaces
    },
    formatNumber(num) {
      return num.toString().padStart(4, '0');
    },
    async getCurrency() {
      let res = await this.fetchCurrency()
      if (res.data.status === true) {
        if (res.data.data && res.data.data.length > 0) {
          res.data.data.map((state) => {
            this.optionsCurrency.push({
              value: state.id,
              text: state.alias,
            })
          })
        }
      }

      this.form.currency_id = this.optionsCurrency?.find(obj => obj.text === 'IDR')?.value
    },  
    async getPortList () {
      let params = {
        active: true,
        page: 1,
        perPage: 250
      }
      const res = await this.getPorts(params)
      if (res.status == 'success') {
        if (res.data.data && res.data.data.length > 0) {
          res.data.data.forEach(val => {
            this.positionList.push({ value: val.id, text: val.portName })
          })
        }
      } else {
        this.$swal(
          `Oops!`,
          res.data.data,
          'error'
        )
      }
    },
    async onChangeStatusStage(id, project_stage_id){
      let payload = {
        id,
        project_stage_id
      }

      const res = await this.changeStatusStage({data: payload})

      if(res.data.status === true){
        this.detailProjectContract.detail_project_stage_id = project_stage_id

        await this.getListProjectStage()
        this.refreshDataTableWithoutCloseModal()
      }
    },
    changeAction(val) {
      // Emit an event to notify the parent component
      console.log(val, "WHAT ACTION IS THIS")
      this.$emit('updateAction', val);
    },
    onOpenSlideDetailVehicle(vehicle_id, index, isDetail, indexDetail){
      this.slidePreviewDetailVehicle = !this.slidePreviewDetailVehicle

      this.selectedFleet = vehicle_id
      this.fleetIndex = index
      this.isDetail = isDetail
      this.detailIndex = indexDetail
    },
    updateVehicleId(fleetId) {
      // Update vehicleId when received from the child component
      if(this.isDetail){
        this.form.detail_plan[this.detailIndex].fleets[this.fleetIndex].vehicle_id = fleetId
      } else {
        this.form.fleets[this.fleetIndex].vehicle_id = fleetId
        this.form.detail_plan[0].fleets[this.fleetIndex].vehicle_id = fleetId
      }
    },
    onChangeFleetType(isDetail, fleetType, index){
      if(isDetail){
        let fleets = []
        let findTow = this.optionsFleetTypes.find(obj => obj.value == fleetType)
        if(findTow?.vehicleTypeTow.length > 0){
          findTow.vehicleTypeTow.map(tow => {
            fleets.push({
              vehicle_id:null
            })
          })
        } else {
          fleets.push({
            vehicle_id:null
          })
        }

        this.form.detail_plan[index].fleets = fleets
      } else {
        this.form.fleets = []
        let fleetsTypes = []
        let findTow = this.optionsFleetTypes.find(obj => obj.value == this.form.fleet_type_id)
        fleetsTypes.push(findTow.id)
        if(findTow?.vehicleTypeTow?.length > 0){
          findTow.vehicleTypeTow.map(tow => {
            fleetsTypes.push(tow.id)
            this.form.fleets.push({
              vehicle_id:null
            })
          })
        } else {
          this.form.fleets.push({
            vehicle_id:null
          })
        }

        if(fleetsTypes.length > 0) this.optionsFilteredFleets = this.optionsFleets.filter(fleet => fleetsTypes.includes(fleet.vehicle_type_id));
        console.log(fleetsTypes, "fleetsTypes")
        console.log(this.optionsFilteredFleets, "WHAT IS FLEET IS THIS")
        this.form.detail_plan[0].fleets = this.form.fleets
      }
    },
    showVehicleTypeName(value){
      let vehicleType = this.optionsFleetTypes.find(obj => obj.value == value)

      return vehicleType?.text ?? ''
    },
    showVehicleName(value){
      let vehicle = this.optionsFleets.find(obj => obj.value == value)

      return vehicle?.text ?? 'Select fleet first'
    }, 
    showCompanyLabel(value){
      let companies = this.optionsCompanies.find(obj => obj.value == value)

      return companies?.text ?? ''
    },
    userExpertName(id){
      let user = this.optionsExpertUsers.find(obj => obj.value === id)

      return user?.text
    },
    cargoTypeLabel(val){
      let label = this.optionsCargoType.find(obj => obj.id === val)

      return label?.name
    },
    termsLabel(val){
      let label = this.optionsTerms.find(obj => obj.value === val)

      return label?.text
    },
    onChangeCargoType(){
      if(this.form.cargo_type_id){
        let cargoUnit = this.optionsCargoType.find(obj => obj.id === Number(this.form.cargo_type_id))

        if(cargoUnit.unit.child.length > 0){
          console.log(cargoUnit, "argoUnit.unit.child")
          this.form.unit = cargoUnit.unit.child[0].name
          this.optionUnit = cargoUnit.unit.child.map(val => {
            return {
              text: val.name,
              value: val.name,
            }
          })
          this.unit_others = false
        } else if (cargoUnit.unit.alias === 'others'){
          this.optionUnit = []
          this.form.unit = ''
          this.unit_others = true
        } else {
          this.optionUnit = []
          this.form.unit = cargoUnit.unit.name
          this.unit_others = false
        }
      }
    },
    onChangeManyApproval(){
      let selectedUsers = [...this.form.user_approval]
      let many = Number(this.form.many_approval)

      let approvalUsers = []
      for(let i = 0; i < many; i++){
        approvalUsers.push({
          user: selectedUsers[i]?.user ?? null
        })
      }

      this.form.user_approval = approvalUsers
    },
    onDataIntoPlan(key, value){
      this.form.detail_plan[0][key] = value
    },
    onSelectOwnership(){},
    vehicleName(id){
      let vehicle = this.optionsFleets.find(obj => obj.value == id)

      return vehicle?.text
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      }).format(value)
    },
    refreshData(){
      this.project_type = 'existing'
      this.getListCustomer()
      this.refreshDataTable()
    },
    onSelectedCustomer(id){
      const customer = this.optionsListCustomer.find(obj => obj.value === id)
      if(customer){
        this.customerDetail = customer
      }
    },
    onSelectedProject(){
      const project = this.projects.find(obj => obj.value === this.form.project_sub_id)

      if(project){
        this.projectDetail = project
      }
      this.form.customer_id = project?.bdCustomer?.id
      this.onSelectedCustomer(project?.bdCustomer?.id)
    }, 
    addFormDocument(){
      this.form.form_document.push({
          filename:'',
          file:null
      })
    },
    addDetailPlanData(){
      let existedDetailPlan = this.form.detail_plan[0]
      this.form.detail_plan.push(existedDetailPlan)
    },
    resetFormPlan(){
      this.formProject = {
        selected_project: null,
        projectName: '',
        location:'',
        subName:'',
        "active": 1, // 1 = active , 0 = Inactive
        "sts": 0, // 1 = active , 0 = Inactive
        "created_by": JSON.parse(localStorage.getItem('profile')).id,
        "created_date": moment().format('YYYY-MM-DD'),
        "update_by":null,
        "update_date": "",
        "status":"proposed", // proposed, running, waiting, process
      }
      this.contractTypeName = '',
      this.form = {
        ...this.form,
        "active": 1, // 1 = active , 0 = Inactive
        "created_by": JSON.parse(localStorage.getItem('profile')).id,
        "created_by_email": JSON.parse(localStorage.getItem('profile')).email,
        "created_name": JSON.parse(localStorage.getItem('profile')).userDetail.name,
        "created_date": moment().format('YYYY-MM-DD'),
        "update_by":null,
        "update_date": "",
        "status":"proposed", // proposed, running, waiting, process

        customer_id:null,
        "project_sub_id":null,
        "contract_type_id":null,
        "cargo_type_id":null,
        "detail_project_stage_id":null,
        "history_project_id":null,
        "terms_id":null,
        "terms_remarks":'',
        "periode_start":null,
        "periode_end":null,
        "fleet_ownership":"own-ship",
        "fleets":"",
        total_quantity:null,
        unit:'',
        "laycan":[],
        "pol":[{value:'',valueOther:''}],
        "pod":[{value:'',valueOther:''}],
        "distance":"",
        // "duration":"",
        "freight_rate_est":null,
        // "freight_rate":"",
        "ppn":'exclude',
        "remarks":"",

        is_tender: false,
        need_more_detail:true,
        need_approval:true,
        need_document:true,
        is_pfa:false,
        many_approval:'1',
        user_approval:[{user:null}],
        form_document: [
          {
            filename:'',
            file:null
          }
        ],
        formTender:{
          pic:'',
          url:'',
          description_of_tender:'',
          file:''
        },
        detail_plan:[
          {
            "shipment_plan_detail_number":"",
            "status":"plan", // plan, progress, closed
            "description":"",
            "fleet_ownership":"own-ship",
            "fleet_type_id":null,
            "fleets":[],
            "cargo_type_id":null,
            "terms_id":null,
            "terms_remarks":'',
            "pol":[{value:'',valueOther:''}],
            "pod":[{value:'',valueOther:''}],
            "shipment":"",
            "eta_pol":"",
            "eta_pod":"",
            "plan_quantity":null,
            "remarks":"",
            "acceptance_date":"",
            draught_type: 'draught_survey_loading'
          }
        ],
      }

      if(this.project_type == 'existing') {
        this.form.is_pfa = false
      } else {
        this.form.is_pfa = true
      }

      this.getListCollectionForm()
    },
    async onExportPFA(project_contract_id, file){
      let params = {
        project_contract_id,
        file
      }
      const res = await this.exportPFA(params)

      if(file == 'excel'){
      if(res.status === 'success'){
        saveAs(res.data, 'PRE FIXTURE ANALYSIS.xlsx')
      } else {
        console.log('Something went wrong')
      }
      } else if(file == 'pdf'){
      if(res.status === 'success'){
        saveAs(res.data, 'PRE FIXTURE ANALYSIS.pdf')
      } else {
        console.log('Something went wrong')
      }
      } else {
      console.log('Something went wrong')
      }
      
    }, 
    async onChangeOwnership(value){
      let ownership = value === 'own-ship' ? 'OWNED' : 'CHARTERED'
      this.form.fleet_type_id = null
      await this.fetchFleets(ownership)
    },
    searchContractType (query) {
      this.contractTypeName = query
    },
    async doAddContractType () {
      let payload = {
        name:this.contractTypeName,
        alias:this.contractTypeName,
        status:1
      }
      let res = await this.createContractType({data: payload})
      if (res.data.status == true) {
        this.form.contract_type_id = res.data.data
        this.getContractType()
      } else {
        if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
        }
      }
    },
    async doAddProject () {
      let payload = {
        project_name:this.formProject.projectName ,
        active: this.formProject.active,
        sts: this.formProject.sts,
        isSts: this.formProject.sts,
        created_by: Number(this.formProject.created_by),
        created_date: this.formProject.created_date,
      }
      let res = await this.createProject({data: payload})
      console.log(res, "PROJECT RESPONSES")
      if (res.status == 'success') {
        this.formProject.selected_project = {
          id:res.data.data.id,
          projectName: res.data.data.project_name,
        }
        this.fetchListProjects()
      } else {
        if (res.data.message != null) { this.$swal('Error', res.data.message, 'error') } else {
          this.$swal(
            'Error',
            'Something wrong with the server. Please try again later.',
            'error'
          )
        }
      }
    },
    searchProjects (query) {
      this.formProject.projectName = query
    },

    // FETCHING DATA EDIT & DETAIL PLAN
    async getDetailProjectContract(id){
      this.detail_project_loading = true

      let res = await this.fetchDetailProjectContract({id})

      if (res.data.status === true) {
        this.detailProjectContract = res.data.data

        const customer = this.optionsListCustomer.find(obj => obj.value === this.detailProjectContract.customer_id)
        const project = this.projectOptions.find(obj => obj.value === this.detailProjectContract.project_sub_id)
        if(project) this.projectDetail = {
          ...project,
          projectName: project?.project?.project_name,
          projectSub: project?.name,
          location: project?.project?.location,
          status: project?.project?.active,
        }
        if(customer) this.customerDetail = customer

        this.form = {
          ...this.form, 
          "created_by": JSON.parse(localStorage.getItem('profile')).id,
          "created_by_email": JSON.parse(localStorage.getItem('profile')).email,
          "created_name": JSON.parse(localStorage.getItem('profile')).userDetail.name,
          "created_date": moment().format('YYYY-MM-DD'),
          customer_id: this.detailProjectContract.customer_id,
          company_id: Number(this.detailProjectContract.company_id),
          "project_sub_id":this.detailProjectContract.project_sub_id,
          "contract_type_id":this.detailProjectContract.contract_type,
          "cargo_type_id":this.detailProjectContract.cargo_type_id,
          "terms_id":this.detailProjectContract.terms_id,
          "terms_remarks":this.detailProjectContract.terms_remarks,
          "periode_start":moment(this.detailProjectContract.periode_start).format('YYYY-MM-DD'),
          "periode_end":moment(this.detailProjectContract.periode_end).format('YYYY-MM-DD'),
          "fleet_ownership":this.detailProjectContract.fleet_ownership,
          "fleets":this.detailProjectContract.fleets,
          "fleet_type_id":this.detailProjectContract.fleet_type_id,
          "laycan": [this.detailProjectContract.laycan_start && moment(this.detailProjectContract.laycan_start).format('YYYY-MM-DD'), this.detailProjectContract.laycan_end && moment(this.detailProjectContract.laycan_end).format('YYYY-MM-DD')],
          "pol":this.detailProjectContract.pol,
          "pod":this.detailProjectContract.pod,
          "distance":this.detailProjectContract.distance,
          // "duration":this.detailProjectContract.duration,
          "unit":this.detailProjectContract.unit,
          "total_quantity":this.detailProjectContract.total_quantity,
          "freight_rate_est":Number(this.detailProjectContract.freight_rate_est),
          // "freight_rate":this.detailProjectContract.freight_rate,
          "ppn":this.detailProjectContract.ppn,
          "remarks":this.detailProjectContract.remarks,
          is_tender: this.detailProjectContract.is_tender ? true : false,
          need_more_detail: this.detailProjectContract.need_more_detail ? true : false,
          need_approval: this.detailProjectContract.need_approval ? true : false,
          need_document: this.detailProjectContract.need_document ? true : false,
          many_approval: this.detailProjectContract?.user_approval?.length.toString(),
          is_pfa: this.detailProjectContract.is_pfa ? true : false,

          currency_id: this.detailProjectContract.currency_id,
          currency_rate: this.detailProjectContract.currency_rate || 1,
          third_party: this.detailProjectContract.third_party,
          third_party_company: this.detailProjectContract.third_party_company,
          contract_number: this.detailProjectContract.contract_number,
          
          detail_plan: this.detailProjectContract?.project_contract_plan?.length > 0 
                      ? this.detailProjectContract?.project_contract_plan?.map(val => {
                        val.acceptance_date = val.acceptance_date ? moment(val.acceptance_date).format('DD-MM-YYYY HH:mm'): null
                        return val
                      })
                      : [{
                          "shipment_plan_detail_number":"",
                          "status":"plan", // plan, progress, closed
                          "description":"",
                          "fleet_ownership":"own-ship",
                          "fleet_type_id":null,
                          "fleets":[],
                          "cargo_type_id":null,
                          "terms_id":null,
                          "terms_remarks":'',
                          "pol":[{value:'',valueOther:''}],
                          "pod":[{value:'',valueOther:''}],
                          "shipment":"",
                          "eta_pol":"",
                          "eta_pod":"",
                          "plan_quantity":null,
                          "remarks":"",
                          "acceptance_date":"",
                          draught_type: 'draught_survey_loading'
                        }],
          user_approval: this.detailProjectContract?.user_approval?.length > 0 ? this.detailProjectContract?.user_approval.map(val => {
            return {
              user: val.id
            }
          }) : [{user:null}],
          project_forms: this.detailProjectContract?.project_forms?.length > 0 ? this.detailProjectContract?.project_forms?.map(val => {
            return {
              ...val,
              label: `${val.code}/${val.name}`
            }
          }) : [],
        }

        if(this.form.is_tender){
          this.form.formTender = {
            pic:this.detailProjectContract.project_tender.pic,
            url:this.detailProjectContract.project_tender.url,
            description_of_tender:this.detailProjectContract.project_tender.description_of_tender,
            file:null
          }
        }

        if(this.form.need_document){
          this.form.form_document = this.detailProjectContract.project_document
        }

        let inspectionData = []

        let rawData = res.data.data.project_forms
        let combinedData = {};
        rawData.forEach(item => {
          if (!combinedData[item.id]) {
            combinedData[item.id] = {
              ...item,
              inspection_checklist_id: [item.inspection_checklist_id] 
            };
          } else {
            combinedData[item.id].inspection_checklist_id.push(item.inspection_checklist_id);
          }
        });
        let output = Object.values(combinedData);

        let tempForms = output.map(form => {
          return {
            ...form,
            field:JSON.parse(form.field),
            signature:JSON.parse(form.signature),
            question_type_content:JSON.parse(form.question_type_content),
            tabTitle:`${form.code}/${form.name}`,
            inspection_checklist: form.inspection_checklist.length > 0 ? form.inspection_checklist.map(inspection => {
              let isIspectionIdExist = form.inspection_checklist_id.find(x => x === inspection.id)

              if(isIspectionIdExist){
                inspectionData.push({
                  ...inspection,
                  code: form.code,
                  name:form.name ,
                  title: form.title,
                  field: JSON.parse(inspection.field),
                  signature: JSON.parse(inspection.signature),
                  question_content: JSON.parse(inspection.question_content),
                  question_type_content:JSON.parse(form.question_type_content),
                  question_type:form.question_type
                })
              }
            }) : []
          }
        })

        this.detailProjectContract.inspectionData = inspectionData
      } else {
        this.detailProjectContract = null
      }
      this.detail_project_loading = false
    },

    // FETCHING MASTER DATA
    async getListCustomer (){
      this.optionsListCustomer = []
      let params = {  
        page:1,
        perPage:100000,
        status:1
      }

      let res = await this.fetchCustomer(params)

      if (res.data.status === true && res.data.data?.data.length > 0) {
        res.data.data?.data.map(val => {
          this.optionsListCustomer?.push({
            ...val,
            value: val.id,
            text:`${val.customer_code} - ${val.account_name}`
          })
        })
      }
    },  
    async getListProjectTerms (){
      this.optionsProjectTerms = []
      let params = {  
        page:1,
        perPage:100000,
        status:1
      }

      let res = await this.fetcBusdevMProjTerms(params)

      if (res.data.status === true && res.data.data?.data.length > 0) {
        res.data.data?.data.map(val => {
          this.optionsProjectTerms?.push({
            value: val.id,
            text:`${val.name}`
          })
        })
      }
    }, 
    async getListCollectionForm() {
      this.optionsForms = []
      let res = await this.fetchOptionsCollectionForm()

      if (res?.data?.status === true && res?.data?.data?.length > 0) {
        this.optionsForms = res.data.data.map(val => {
          return {
            ...val,
            label: `${val.code}/${val.name}`
          }
        })

        let selectedForms = this.optionsForms?.filter(obj => obj.default_for_shipment_planning === 1)

        if(this.action == 'ADD') this.form.project_forms = selectedForms
      } 
    },  
    
    async getVehicleType() {
      this.optionsFleetTypes = []
      let {status, data} = await this.getFleetTypes()

      if (status === 'success') {
        this.optionsFleetTypes = data.map(val => {
          return {
            ...val,
            value:val.id,
            text:val.name
          }
        })
      } 
    }, 
    
    async fetchDataUserExpert() {
      let params = {
        page: 1,
        active: true,
        perPage: 200,
      }
      const { status, data } = await this.fetchUserExpert(params)

      if (status) {
        if (data && data.length > 0) {
          data.map((state) => {
            this.optionsExpertUsers.push({
              value: state.id,
              text: state.userDetail.name,
              email: state.email,
            })
          })
        }
      }
    },
    async getTerms() {
      let res = await this.fetchTerms()
      if (res.data.status === true && res.data.data.length > 0) {
        res.data.data.map((state) => {
          this.optionsTerms.push({
            value: state.id,
            text: state.name,
          })
        })
      }
    },  
    async getCargoType() {
      let res = await this.fetchCargoType()
      if (res.data.status === true && res.data.data.length > 0) {
        res.data.data.map((state) => {
          this.optionsCargoType.push({
            ...state,
            value: state.id,
            text: state.name,
          })
        })
      }
    }, 
    async getContractType() {
      let res = await this.fetchContractType()
      if (res.data.status === true && res.data.data.length > 0) {
        this.optionsContractType = res.data.data
      }
    },
    async getListProjectStage () {
      this.listProjectStage = []
      let res = await this.fetchDetailProjectStage()
      if (res?.status == 'success' || res.data.status == true) {
        if (res.data.data && res.data.data.length > 0) {
          this.listProjectStage = res.data.data
        }
      } 
    },
    async fetchListProjects () {
      this.projects = []
      let params = {
        perPage: 10000,
        page: 1
      }

      let res = await this.getProjects(params)

      if ((res?.data?.status == 'success' || res?.data?.status == true) && res.data.data.length > 0) {
        this.projects = []
        res.data.data?.map(val => {
          val.subProject?.map(obj => {
            this.projects.push({
              value: obj.id,
              text: `${val.projectName} - ${obj.name}`,
              bdCustomer: val.bdCustomer,
              isSts: val.isSts,
              sts: val.sts,
              vehicleType: val.vehicleType,
              projectId: val.id,
              projectName:val.projectName,
              projectSub:obj.name,
              location:val.location,
              status:val.active,
            })
          })
        })
      } else {
        this.projects = []
      }

      console.log(this.projects, "this.projects")
    },
    async getProjectList () {
      const res = await this.fetchProjects()
      if (res.data.status == true) {
        if (res.data.data && res.data.data.length > 0) {
          res.data.data.forEach(val => {
            this.projectOptions.push({
              ...val,
              text: `${val.project.project_name} - ${val.name}`,
              value: val.id
            })
          })
        }
      }
    },
    async fetchFleets(ownership) {
      this.fleet_loading = true
      let params = this.$options.filters.cleanObject({
        page: 1,
        perPage: 9999,
        showAll: true,
        active: true,
        ownership
      })

      if(!params.ownership) delete params.ownership
      
      let res = await this.getFleets(params)

      if (res.status) {
        if (res.data && res.data.length > 0) {
          this.optionsFleets = res.data.map((val) => ({
            text: val.name,
            value: val.id,
            vehicle_type_id: val.vehicleType.id,
            checked:false
          }))
          this.optionsFilteredFleets = this.optionsFleets
        }
      }

      this.fleet_loading = false
    },
    
    async onSavePlan(){
      this.saving = true

      let findProcessStage = this.listProjectStage.find(obj => obj.title == 'Process')

      if(!this.form?.contract_type_id?.id){
        this.$swal(`Oops.`, 'Contract type required.', 'warning')
        this.saving = false
        return 
      }

      let payload = {
        ...this.form,
        ...this.formProject,
        shipment_plan_number:this.shipmentPlanNumber,
        company_id:Number(this.form.company_id),
        project_id: this.project_type === 'new_project' ? Number(this.formProject?.selected_project?.id) : Number(this.form.project_sub_id),
        total_quantity: Number(this.totalQuantityAdd),
        detail_project_stage_id:Number(findProcessStage?.id),
        project_type: this.project_type,
        laycan_start: this.form.laycan?.[0] ? moment(this.form.laycan?.[0]).format('YYYY-MM-DD') : '',
        laycan_end: this.form.laycan?.[1] ? moment(this.form.laycan?.[1]).format('YYYY-MM-DD') : '',
        contract_type_id: Number(this.form.contract_type_id?.id),
        is_tender: this.form.is_tender ? 1 : 0,
        need_more_detail: this.form.need_more_detail ? 1 : 0,
        need_approval: this.form.need_approval ? 1 : 0,
        need_document: this.form.need_document ? 1 : 0,
        freight_rate_est: this.form.freight_rate_est ?? 0,
        many_approval: Number(this.form.many_approval),
        is_pfa: this.form.is_pfa ? 1 : 0,
        currency_rate: this.form.currency_rate ? Number(this.form.currency_rate) : 1,
        detail_plan: this.form.need_more_detail ? this.form.detail_plan?.map((val, index) => {
          return {
            ...val,
            shipment_plan_detail_number: this.shipmentPlanNumber + `-${this.formatNumber(index + 1)}`,
            acceptance_date: val?.acceptance_date ? moment(new Date(val?.acceptance_date)).format('YYYY-MM-DD HH:mm') : null
          }
        }) : [],
        project_forms: this.form.project_forms.map(val => {
          return {
            collection_forms_id:val?.id,
            inspection_checklist_id: null
          }
        }),
        user_approval: this.form.user_approval.map(val => {
          let userExpert = this.optionsExpertUsers.find(obj => obj.value == val.user)
          return {
            id: val.user,
            email:userExpert?.email,
            name:userExpert?.text,
            status_approval:''
          }
        })
      }
      delete payload.laycan

      payload.fleets = payload.fleets.map(val => {
        return{
          vehicle_id: val.vehicle_id,
          vehicle_name: this.vehicleName(val.vehicle_id)
        }
      })  

      if(this.editId && this.action === 'EDIT'){
        let res = await this.updateProjectContract({data: payload, id: Number(this.detailProjectContract?.id)})

        if([500, 404, 403, 422].includes(res.status)){
          this.saving = false
          this.$swal(`Oops.`, res?.message ?? res?.data?.message ?? 'Something went wrong!', 'error')
          return
        }

        if(res?.data?.status === true){
          if(payload?.is_tender){
            const formData = new FormData()
  
            formData.append('pic', this.form.formTender.pic)
            formData.append('url', this.form.formTender.url)
            formData.append('description_of_tender', this.form.formTender.description_of_tender)
            if(this.form?.formTender?.file?.length > 0){
              this.form.formTender.file?.forEach((file, fileIndex) => {
                formData.append(`file[${fileIndex}]`, file);
              });
            }
  
            await this.updateProjectContractTender({data: formData, id:Number(this.detailProjectContract?.id)})
          }
          const promises = payload.user_approval.map(async val => {
            let payload = {
              "to":val?.email,
              "name":`Dear, ${val?.name}`,
              "subject":"Project Plan Edited",
              "instructions":`Edited by: ${JSON.parse(localStorage.getItem('profile')).userDetail.name}`,
              "intro":"Project Planning Edited.",
              "outro":"Thank you for reading this email, as you know this email is automatically",
              "buttonText":"Go to Dashboard",
              "project_contract_id": Number(this.detailProjectContract?.id),
              "url":"busdev/dashboard",
              "urlQuery":""
            }
  
            await this.sendEmailNotification({data: payload})
          })

          let otherPayloadEmail = {
            "to":payload.created_by_email,
            "name":`Dear, ${payload.created_name}`,
            "subject":"Project Plan Edited",
            "instructions":`Edited by: ${JSON.parse(localStorage.getItem('profile')).userDetail.name}`,
            "intro":"Project Planning Edited.",
            "outro":"Thank you for reading this email, as you know this email is automatically",
            "buttonText":"Go to Dashboard",
            "project_contract_id": Number(this.detailProjectContract?.id),
            "url":"busdev/dashboard",
            "urlQuery":""
          }
  
          await this.sendEmailNotification({data: otherPayloadEmail})
  
          await Promise.all(promises)

          this.saving = false

          this.refreshData()
          this.resetFormPlan()
          this.$swal(`Success!`, `Edit Plan Success.`, 'success')
        } else {
          this.saving = false
          this.$swal(`Oops.`, res?.data?.message, 'error')
        }

      } else {
        let res = await this.createShipmentPlanning({data: payload})
  
        if([500, 404, 403, 422].includes(res.status)){
          this.saving = false
          this.$swal(`Oops.`, res?.message ?? res?.data?.message ?? 'Something went wrong!', 'error')
          return
        }
  
        if(res?.data?.status === true){
          if(payload?.is_tender){
            const formData = new FormData()
  
            formData.append('project_contract_id', res.data.data.id)
            formData.append('pic', this.form.formTender.pic)
            formData.append('url', this.form.formTender.url)
            formData.append('description_of_tender', this.form.formTender.description_of_tender)
            if(this.form.formTender.file && this.form.formTender.file?.length > 0){
              this.form.formTender.file?.forEach((file, fileIndex) => {
                formData.append(`file[${fileIndex}]`, file);
              });
            }
  
            await this.createProjectContractTender({data: formData})
          } 
          
          if(payload?.need_document){
            const formData = new FormData()
  
            if(this.form.form_document && this.form.form_document?.length > 0){
              formData.append('project_contract_id', res.data.data.id)
              this.form.form_document?.forEach((item, index) => {
                for (const property in item) {
                  formData.append(`document[${index}][${property}]`, item[property])
                }
              });
            }
  
            await this.createProjectContractDocument({data: formData})
          }
  
          const promises = payload.user_approval.map(async val => {
            let payloadEmail = {
              "to":val.email,
              "name":`Dear, ${val.name}`,
              "subject":"Project Plan Created",
              "instructions":"",
              "intro":"Project Planning Created.",
              "outro":"Thank you for reading this email, as you know this email is automatically",
              "buttonText":"Go to Dashboard",
              "project_contract_id": Number(res?.data?.data?.id),
              "url":`approval-mail?value=`,
              "urlQuery":{
                  "id": Number(res?.data?.data?.id),
                  "userId":val.id,
                  "userName": val.name
              }
            }
  
            await this.sendEmailNotification({data: payloadEmail})
          })
          let otherPayloadEmail = {
            "to":payload.created_by_email,
            "name":`Dear, ${payload.created_name}`,
            "subject":"Project Plan Created",
            "instructions":"",
            "intro":"Project Planning Created.",
            "outro":"Thank you for reading this email, as you know this email is automatically",
            "buttonText":"Go to Dashboard",
            "project_contract_id": Number(res?.data?.data?.id),
            "url":`approval-mail?value=`,
            "urlQuery":{
                "id": Number(res?.data?.data?.id),
                "userId":payload.created_by,
                "userName": payload.created_name
            }
          }
  
          await this.sendEmailNotification({data: otherPayloadEmail})
  
          await Promise.all(promises)
  
          this.saving = false
          this.refreshData()
          this.resetFormPlan()
          this.$swal(`Success!`, `Create Plan Success.`, 'success')
        } else {
          this.saving = false
          this.$swal(`Oops.`, res?.data?.message, 'error')
        }
      }

      this.saving = false
    },
  }
}
</script>

