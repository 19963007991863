var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h6', {
          staticClass: "card-title"
        }, [_c('strong', [_vm._v("BLACK LIST CREW")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-collapse', {
          staticClass: "mb-2",
          attrs: {
            "visible": "",
            "id": "collapse-filter"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "md": "2"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Search",
            "label-for": "search"
          }
        }, [_c('b-form-input', {
          attrs: {
            "type": "text",
            "placeholder": "Ketikan Nama"
          },
          model: {
            value: _vm.blackList.params.full_name,
            callback: function callback($$v) {
              _vm.$set(_vm.blackList.params, "full_name", $$v);
            },
            expression: "blackList.params.full_name"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Crew Status",
            "label-for": "jabatan"
          }
        }, [_c('b-form-select', {
          attrs: {
            "plain": "",
            "options": [],
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Pilih Jabatan")])];
            },
            proxy: true
          }])
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Perusahaan",
            "label-for": "perusahaan"
          }
        }, [_c('b-form-select', {
          attrs: {
            "id": "perusahaan",
            "plain": "",
            "options": [],
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Pilih Jabatan")])];
            },
            proxy: true
          }])
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "3"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Jabatan",
            "label-for": "jabatan"
          }
        }, [_c('b-form-select', {
          attrs: {
            "id": "jabatan",
            "plain": "",
            "options": _vm.optionsPosition,
            "size": "lg"
          },
          scopedSlots: _vm._u([{
            key: "first",
            fn: function fn() {
              return [_c('b-form-select-option', {
                attrs: {
                  "value": null
                }
              }, [_vm._v("Pilih Jabatan")])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.blackList.params.position_id,
            callback: function callback($$v) {
              _vm.$set(_vm.blackList.params, "position_id", $$v);
            },
            expression: "blackList.params.position_id"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "1"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Filter",
            "label-for": "button_search"
          }
        }, [_c('button', {
          staticClass: "btn mb-3 text-white",
          staticStyle: {
            "background": "#2bdcaa"
          },
          on: {
            "click": function click($event) {
              return _vm.getBlackList(null);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])])], 1)], 1)], 1), _c('div', {
          staticClass: "d-flex w-100 justify-content-end my-3"
        }, [_c('b-button', {
          staticClass: "mr-2",
          attrs: {
            "variant": "primary",
            "disabled": _vm.blackList.loading
          },
          on: {
            "click": function click($event) {
              return _vm.exportBlacklist();
            }
          }
        }, [_vm.blackList.loading ? _c('span', {
          staticClass: "spinner-border spinner-border-sm"
        }) : _c('div', {
          staticClass: "d-flex gap-2 align-items-center"
        }, [_c('span', [_vm._v("Export Blacklist")]), _c('i', {
          staticClass: "fa fa-upload ml-2"
        })])])], 1), _c('div', {
          staticClass: "d-flex overflow-auto"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          staticClass: "text-center text-truncate bg-tosca"
        }, [_vm._v("No")]), _c('th', {
          staticClass: "text-truncate bg-tosca"
        }, [_vm._v("Crew Name")]), _c('th', {
          staticClass: "text-truncate bg-tosca"
        }, [_vm._v("Seafer Code")]), _c('th', {
          staticClass: "text-truncate bg-tosca"
        }, [_vm._v("Position")]), _c('th', {
          staticClass: "text-truncate bg-tosca"
        }, [_vm._v("Reason Off")]), _c('th', {
          staticClass: "text-truncate bg-tosca"
        }, [_vm._v("REMARKS")])])]), _c('tbody', _vm._l(_vm.blackList.data, function (data, index) {
          var _data$seaferer_code, _data$position$name, _data$position, _data$reason_off, _data$remarks;
          return _c('tr', {
            key: index
          }, [_c('td', {
            staticClass: "text-truncate text-center"
          }, [_vm._v(_vm._s(index + 1))]), _c('td', {
            staticClass: "text-truncate text-left"
          }, [_c('router-link', {
            attrs: {
              "to": {
                path: "profile",
                query: {
                  user: 'candidate',
                  id: data.id
                }
              }
            }
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('b-avatar', {
            attrs: {
              "size": "34",
              "variant": "warning",
              "src": data.photo || require('../../assets/images/page-img/10.jpg')
            }
          }), _c('h6', {
            staticClass: "ml-2"
          }, [_vm._v(_vm._s(data === null || data === void 0 ? void 0 : data.full_name))])], 1)])], 1), _c('td', {
            staticClass: "text-left text-truncate"
          }, [_vm._v(_vm._s((_data$seaferer_code = data === null || data === void 0 ? void 0 : data.seaferer_code) !== null && _data$seaferer_code !== void 0 ? _data$seaferer_code : '-'))]), _c('td', {
            staticClass: "text-left text-truncate"
          }, [_vm._v(_vm._s((_data$position$name = data === null || data === void 0 ? void 0 : (_data$position = data.position) === null || _data$position === void 0 ? void 0 : _data$position.name) !== null && _data$position$name !== void 0 ? _data$position$name : '-') + " ")]), _c('td', {
            staticClass: "text-left max-w-md"
          }, [_vm._v(_vm._s((_data$reason_off = data === null || data === void 0 ? void 0 : data.reason_off) !== null && _data$reason_off !== void 0 ? _data$reason_off : '-'))]), _c('td', {
            staticClass: "text-truncate"
          }, [_vm._v(_vm._s((_data$remarks = data === null || data === void 0 ? void 0 : data.remarks) !== null && _data$remarks !== void 0 ? _data$remarks : 'Tanpa keterangan'))])]);
        }), 0)])]), _vm.blackList.data.length > 0 ? _c('div', {
          staticClass: "mt-3"
        }, [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.blackList.meta.total,
            "per-page": _vm.blackList.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.getBlackList
          },
          model: {
            value: _vm.blackList.meta.currentPage,
            callback: function callback($$v) {
              _vm.$set(_vm.blackList.meta, "currentPage", $$v);
            },
            expression: "blackList.meta.currentPage"
          }
        })], 1) : _c('p', {
          staticClass: "text-left my-4"
        }, [_vm._v("List blacklist belum ada.")])];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }