var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-button', {
    staticClass: "float-right mr-1",
    attrs: {
      "variant": "primary",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        return _vm.openSlideFormTripDocument('ADD', null);
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v("   ADD DATA")])], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Search",
      "label-for": "Search"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Search..."
    },
    model: {
      value: _vm.params.search,
      callback: function callback($$v) {
        _vm.$set(_vm.params, "search", $$v);
      },
      expression: "params.search"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "1"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Filter",
      "label-for": "button_search"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "button"
    },
    on: {
      "click": _vm.getListTripDocument
    }
  }, [_c('i', {
    staticClass: "fa fa-search"
  })])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex overflow-auto"
  }, [_c('table', {
    staticClass: "table table-bordered"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "align-middle",
    staticStyle: {
      "width": "5px"
    }
  }, [_vm._v("No")]), _c('th', {
    staticClass: "text-center text-truncate",
    staticStyle: {
      "min-width": "300px"
    }
  }, [_vm._v("Name of Document")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("Doc for Trip")]), _c('th', {
    staticClass: "text-center text-truncate"
  }, [_vm._v("Document")]), _c('th', {
    staticStyle: {
      "width": "5px"
    }
  }, [_vm._v("Action")])])]), _c('tbody', [_vm.list.length > 0 ? _vm._l(_vm.list, function (state, index) {
    var _state$name_document, _state$project_plan$s, _state$project_plan;
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s((_state$name_document = state === null || state === void 0 ? void 0 : state.name_document) !== null && _state$name_document !== void 0 ? _state$name_document : '-'))]), _c('td', {
      staticClass: "text-truncate"
    }, [_vm._v(_vm._s((_state$project_plan$s = state === null || state === void 0 ? void 0 : (_state$project_plan = state.project_plan) === null || _state$project_plan === void 0 ? void 0 : _state$project_plan.shipment_plan_detail_number) !== null && _state$project_plan$s !== void 0 ? _state$project_plan$s : '-'))]), _c('td', [_c('div', {
      staticClass: "d-flex flex-column"
    }, _vm._l(state === null || state === void 0 ? void 0 : state.trip_attachment, function (file, fileIndex) {
      return _c('div', {
        key: fileIndex,
        staticClass: "d-flex align-items-center mb-1",
        staticStyle: {
          "cursor": "pointer"
        },
        on: {
          "click": function click($event) {
            return _vm.handleOpenFilePreview("".concat(_vm.apiUrl, "/").concat(file === null || file === void 0 ? void 0 : file.file_location));
          }
        }
      }, [_c('i', {
        staticClass: "fa fa-file text-primary mr-2"
      }), _c('span', {
        staticClass: "text-primary text-truncate"
      }, [_vm._v(_vm._s(file.file_name))])]);
    }), 0)]), _c('td', [_c('b-dropdown', {
      attrs: {
        "size": "lg",
        "variant": "link iq-bg-primary iq-border-radius-10",
        "toggle-class": "text-decoration-none",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function fn() {
          return [_c('span', {
            staticClass: "dropdown-toggle p-0",
            attrs: {
              "id": "dropdownMenuButton5",
              "data-toggle": "dropdown"
            }
          }, [_c('i', {
            staticClass: "fa fa-pencil-square-o m-0 text-primary"
          })])];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.openSlideFormTripDocument('PREVIEW', state === null || state === void 0 ? void 0 : state.id);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-eye mr-2"
    }), _vm._v("Detail ")]), _c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.openSlideFormTripDocument('EDIT', state === null || state === void 0 ? void 0 : state.id);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-pen mr-2"
    }), _vm._v("Edit ")]), _c('b-dropdown-divider'), _c('b-dropdown-item', {
      attrs: {
        "href": "#",
        "variant": "danger"
      },
      on: {
        "click": function click($event) {
          return _vm.onDeleteTripDocument(state);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash mr-2"
    }), _vm._v("Delete ")])], 1)], 1)]);
  }) : _vm._e()], 2)])]), _vm.list.length > 0 ? _c('div', {
    staticClass: "mt-3"
  }, [_c('b-pagination', {
    attrs: {
      "first-number": "",
      "align": "center"
    }
  })], 1) : _c('div', {
    staticClass: "d-flex align-items-center justify-content-center w-100 my-2 mb-4"
  }, [_c('strong', {
    staticClass: "text-muted"
  }, [_vm._v("No Data Trip Document.")])])]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-sidebar', {
    attrs: {
      "id": "slideFormTripDocument",
      "title": "Create Trip Document",
      "width": "70%",
      "backdrop-variant": "dark",
      "bg-variant": "white",
      "right": "",
      "backdrop": "",
      "shadow": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_c('SlideTripDocument', {
          attrs: {
            "closeSlideFormTripDocument": _vm.closeSlideFormTripDocument,
            "refreshData": _vm.refreshData,
            "projectData": _vm.projectData,
            "id": _vm.id,
            "action": _vm.action
          }
        })];
      }
    }]),
    model: {
      value: _vm.slideFormTripDocument,
      callback: function callback($$v) {
        _vm.slideFormTripDocument = $$v;
      },
      expression: "slideFormTripDocument"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }