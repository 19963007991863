var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('iq-card', {
    attrs: {
      "body-class": "p-0"
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "iq-edit-list"
        }, [_c('tab-nav', {
          staticClass: "iq-edit-profile d-flex",
          attrs: {
            "pills": true
          }
        }, [_c('tab-nav-items', {
          staticClass: "col-md-6 p-0",
          attrs: {
            "active": true,
            "href": "#project",
            "title": "MASTER DATA PROJECT"
          }
        }), _c('tab-nav-items', {
          staticClass: "col-md-6 p-0",
          attrs: {
            "active": false,
            "href": "#idle",
            "title": "MASTER DATA REASON"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c('div', {
    staticClass: "tab-content",
    attrs: {
      "id": "pills-tabContent-2"
    }
  }, [_c('tab-content-item', {
    attrs: {
      "active": true,
      "id": "project"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": !_vm.menuPermission('update') && !_vm.menuPermission('add') ? '12' : '8'
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("List Master Data Project")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-content-between"
        }, [_c('div', {
          staticClass: "d-flex mb-3"
        }, [_c('b-form-input', {
          staticStyle: {
            "width": "240px"
          },
          attrs: {
            "id": "fleet_name",
            "type": "text",
            "placeholder": "Search..."
          },
          model: {
            value: _vm.projects.params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.projects.params, "search", $$v);
            },
            expression: "projects.params.search"
          }
        }), _c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.fetchProjects(1);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1), _vm.menuPermission('export') ? _c('div', [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.doExportProjects
          }
        }, [!_vm.loading_export_project ? [_c('i', {
          staticClass: "fa fa-file-excel"
        }), _vm._v("   Export ")] : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _vm._v(" Exporting... ")]], 2)], 1) : _vm._e()]), _vm.projects._loading ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.projects.data && _vm.projects.data.length > 0 ? _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("No")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Name Project")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Sub Project")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Location")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Customer Name")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Status")]), _c('th', {
          attrs: {
            "width": "15%"
          }
        }, [_vm._v("Action")])])]), _c('tbody', _vm._l(_vm.projects.data, function (p, i) {
          var _p$bdCustomer, _p$bdCustomer2;
          return _c('tr', {
            key: "p-".concat(i)
          }, [_c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(_vm.projects.meta.currentPage > 1 ? _vm.projects.meta.perPage + i + 1 : i + 1))]), _c('td', [_vm._v(_vm._s(p.projectName))]), _c('td', [p.subProject.length > 0 ? _c('ol', {
            staticClass: "m-0 pl-3"
          }, _vm._l(p.subProject, function (sp, i_sp) {
            return _c('li', {
              key: "sp-".concat(i_sp)
            }, [_vm._v(" " + _vm._s(sp.name) + " ")]);
          }), 0) : [_vm._v("-")]], 2), _c('td', [_vm._v(_vm._s(p.location ? p.location : '-'))]), _c('td', {
            staticClass: "text-truncate"
          }, [_c('a', {
            attrs: {
              "href": "#"
            }
          }, [_vm._v(_vm._s(p === null || p === void 0 ? void 0 : (_p$bdCustomer = p.bdCustomer) === null || _p$bdCustomer === void 0 ? void 0 : _p$bdCustomer.customerCode) + " - " + _vm._s(p === null || p === void 0 ? void 0 : (_p$bdCustomer2 = p.bdCustomer) === null || _p$bdCustomer2 === void 0 ? void 0 : _p$bdCustomer2.name))])]), _c('td', [_vm._v(_vm._s(p.active ? 'Active' : 'Inactive'))]), _c('td', {
            staticClass: "d-flex"
          }, [_vm.menuPermission('update') ? _c('b-button', {
            staticClass: "mr-2",
            attrs: {
              "variant": "success",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onEditProject(p);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]) : _vm._e(), _vm.menuPermission('delete') ? _c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeleteProject(p);
              }
            }
          }, [!_vm.loading_delete_project ? _c('i', {
            staticClass: "fa fa-trash"
          }) : [_c('span', {
            staticClass: "spinner-border spinner-border-sm",
            attrs: {
              "role": "status",
              "aria-hidden": "true"
            }
          }), _vm._v(" Deleting... ")]], 2) : _vm._e()], 1)]);
        }), 0)])]) : _vm._e(), _vm.projects.data && _vm.projects.data.length > 0 ? _c('div', [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.projects.meta.total,
            "per-page": _vm.projects.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.fetchProjects
          },
          model: {
            value: _vm.projects.meta.currentPage,
            callback: function callback($$v) {
              _vm.$set(_vm.projects.meta, "currentPage", $$v);
            },
            expression: "projects.meta.currentPage"
          }
        })], 1) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("No projects data found.")])]];
      },
      proxy: true
    }])
  })], 1), _vm.menuPermission('update') || _vm.menuPermission('add') ? _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('iq-card', {
    class: {
      'border border-danger shadow-lg': _vm.form_project_edit_mode
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v(_vm._s(_vm.form_project_edit_mode ? 'Edit' : 'Add') + " Project")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$customerDetail, _vm$customerDetail2;
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.submitFormProject.apply(null, arguments);
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Project Name",
            "label-for": "project_name"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "placeholder": "Search or add new project",
            "label": "projectName",
            "track-by": "id",
            "close-on-select": true,
            "clear-on-select": true,
            "options": _vm.projects.data
          },
          on: {
            "search-change": _vm.searchProjects
          },
          model: {
            value: _vm.form.selected_project,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "selected_project", $$v);
            },
            expression: "form.selected_project"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          on: {
            "click": _vm.doAddProject
          },
          slot: "noResult"
        }, [_vm._v(" Not Found. Click to add "), _c('strong', [_vm._v(_vm._s(_vm.form.projectName))])])])], 1), _c('b-form-group', {
          attrs: {
            "label": "Ship to ship?",
            "label-for": "sts"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.form.project.sts,
            callback: function callback($$v) {
              _vm.$set(_vm.form.project, "sts", $$v);
            },
            expression: "form.project.sts"
          }
        }, [_vm._v(" " + _vm._s(_vm.form.project.sts ? 'Yes' : 'no') + " ")])], 1), _c('b-form-group', {
          attrs: {
            "label": "Category For",
            "label-for": "category_for"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "placeholder": "Search categories",
            "label": "name",
            "track-by": "id",
            "options": _vm.fleet_types,
            "multiple": true
          },
          model: {
            value: _vm.form.project.vehicleType,
            callback: function callback($$v) {
              _vm.$set(_vm.form.project, "vehicleType", $$v);
            },
            expression: "form.project.vehicleType"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Category not found.")])])], 1), _c('b-form-group', {
          attrs: {
            "label": "Project Location",
            "label-for": "project_location"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "port_name",
            "type": "text",
            "placeholder": "Project Location",
            "maxlength": "30"
          },
          model: {
            value: _vm.form.project.location,
            callback: function callback($$v) {
              _vm.$set(_vm.form.project, "location", $$v);
            },
            expression: "form.project.location"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Sub Project",
            "label-for": "sub_project_name"
          }
        }, [_c('b-row', [_c('b-col', [_c('b-form-input', {
          staticClass: "d-inline-block",
          attrs: {
            "id": "port_name",
            "type": "text",
            "placeholder": "Sub Project Name",
            "maxlength": "30"
          },
          model: {
            value: _vm.form.project.subProject[0].name,
            callback: function callback($$v) {
              _vm.$set(_vm.form.project.subProject[0], "name", $$v);
            },
            expression: "form.project.subProject[0].name"
          }
        })], 1), _c('b-button', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.edit-sub-project",
            modifiers: {
              "edit-sub-project": true
            }
          }, {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-2",
          attrs: {
            "variant": "outline-primary",
            "title": "Setting Sub Project"
          }
        }, [_c('i', {
          staticClass: "fa fa-cog"
        })]), _c('b-modal', {
          attrs: {
            "id": "edit-sub-project",
            "size": "md",
            "title": "Edit Sub Projects",
            "ok-title": "Save",
            "cancel-title": "Cancel",
            "centered": ""
          }
        }, [_vm._l(_vm.form.project.subProject, function (sp, i) {
          return [_c('b-input-group', {
            key: "spf-".concat(i),
            staticClass: "mb-3",
            attrs: {
              "prepend": (i + 1).toString()
            }
          }, [_c('b-form-input', {
            attrs: {
              "placeholder": "Sub Project ".concat(i + 1, " Name"),
              "type": "text",
              "maxlength": "30"
            },
            model: {
              value: sp.name,
              callback: function callback($$v) {
                _vm.$set(sp, "name", $$v);
              },
              expression: "sp.name"
            }
          }), _c('b-input-group-append', [_c('b-button', {
            attrs: {
              "variant": "outline-danger"
            },
            on: {
              "click": function click($event) {
                return _vm.deleteSubProject(i);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-times"
          })])], 1)], 1)];
        }), _c('div', {
          staticClass: "mt-3"
        }, [_c('a', {
          attrs: {
            "href": ""
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.addSubProject.apply(null, arguments);
            }
          }
        }, [_vm._v("Add New Sub Project")])])], 2)], 1)], 1), _c('b-form-group', {
          attrs: {
            "label": "Customer Name",
            "label-for": "customer_name"
          }
        }, [_vm.customerDetail ? _c('strong', {
          staticClass: "mb-2"
        }, [_vm._v(_vm._s((_vm$customerDetail = _vm.customerDetail) === null || _vm$customerDetail === void 0 ? void 0 : _vm$customerDetail.customer_code) + " - " + _vm._s((_vm$customerDetail2 = _vm.customerDetail) === null || _vm$customerDetail2 === void 0 ? void 0 : _vm$customerDetail2.name))]) : _vm._e(), _vm._v(" "), _vm.customerDetail ? _c('br') : _vm._e(), _c('b-button', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.new-customer",
            modifiers: {
              "new-customer": true
            }
          }, {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-2",
          attrs: {
            "variant": "info",
            "title": "Select Customer Data"
          },
          on: {
            "click": _vm.openCustomer
          }
        }, [_c('i', {
          staticClass: "fa fa-user"
        }), _vm._v(" Select Customer Data")])], 1), _c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "sub_sub_project"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.form.project.active,
            callback: function callback($$v) {
              _vm.$set(_vm.form.project, "active", $$v);
            },
            expression: "form.project.active"
          }
        }, [_vm._v(" " + _vm._s(_vm.form.project.active ? 'Active' : 'Inactive') + " ")])], 1), _c('hr'), _c('b-form-group', {
          staticClass: "text-right"
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [!_vm.loading_form_project ? _c('span', [_vm._v(_vm._s(_vm.form_project_edit_mode || !_vm.menuPermission('add') ? 'Save' : 'Add'))]) : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _vm._v(" Saving... ")]], 2), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "button",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.resetFormProject.apply(null, arguments);
            }
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }], null, false, 1714029567)
  })], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "lg": "12"
    }
  }, [_c('b-modal', {
    attrs: {
      "id": "new-customer",
      "size": "xl",
      "title": "Select Customer",
      "header-bg-variant": "light",
      "header-text-variant": "dark",
      "hide-footer": ""
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "lg": "12",
      "align": "start"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "variant": "success"
    },
    on: {
      "click": _vm.addNewCustomer
    }
  }, [_c('i', {
    staticClass: "fa fa-plus mr-2"
  }), _vm._v(" Add New Customer ")]), _c('b-button', {
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": _vm.cancelAddCustomer
    }
  }, [_c('i', {
    staticClass: "fa fa-ban mr-2"
  }), _vm._v(" Cancel ")])], 1)]), !_vm.addCustomer ? _c('b-col', {
    staticClass: "mb-4",
    attrs: {
      "lg": "12"
    }
  }, [_c('v-multiselect', {
    attrs: {
      "options": _vm.optionsCustomer,
      "placeholder": "Choose customer",
      "label": "account_name",
      "track-by": "account_name"
    },
    on: {
      "input": _vm.onChangeCustomer
    },
    model: {
      value: _vm.form.project.bdCustomerId,
      callback: function callback($$v) {
        _vm.$set(_vm.form.project, "bdCustomerId", $$v);
      },
      expression: "form.project.bdCustomerId"
    }
  })], 1) : _vm._e(), _vm.editIdCustomer || _vm.addCustomer ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('FormCustomer', {
    attrs: {
      "is-edit": _vm.isEditCustomer,
      "edit-id": _vm.editIdCustomer,
      "refreshData": _vm.refreshData,
      "preview-data": _vm.previewDataCustomer
    }
  })], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1), _c('tab-content-item', {
    attrs: {
      "active": false,
      "id": "idle"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "sm": !_vm.menuPermission('update') && !_vm.menuPermission('add') ? '12' : '8'
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('strong', [_vm._v("List Master Data Reason (Idle)")])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex mb-3 justify-content-between"
        }, [_c('div', {
          staticClass: "d-flex"
        }, [_c('b-form-input', {
          staticStyle: {
            "width": "240px"
          },
          attrs: {
            "id": "fleet_name",
            "type": "text",
            "placeholder": "Search..."
          },
          model: {
            value: _vm.reasons.params.search,
            callback: function callback($$v) {
              _vm.$set(_vm.reasons.params, "search", $$v);
            },
            expression: "reasons.params.search"
          }
        }), _c('b-button', {
          staticClass: "ml-2",
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.fetchReasons(1);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-search"
        })])], 1), _c('div', [_vm.menuPermission('export') ? _c('div', [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.doExportReasons
          }
        }, [!_vm.loading_export_reason ? [_c('i', {
          staticClass: "fa fa-file-excel"
        }), _vm._v("   Export ")] : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _vm._v(" Exporting... ")]], 2)], 1) : _vm._e()])]), _vm.reasons._loading ? _c('div', {
          staticClass: "text-center my-5"
        }, [_c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        })], 1) : [_vm.reasons.data && _vm.reasons.data.length > 0 ? _c('div', {
          staticClass: "table-responsive"
        }, [_c('table', {
          staticClass: "table table-striped"
        }, [_c('thead', [_c('tr', [_c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("No")]), _c('th', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("Reason")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Sort")]), _c('th', {
          attrs: {
            "width": "25%"
          }
        }, [_vm._v("Fleet Category")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Status")]), _c('th', {
          attrs: {
            "width": "10%"
          }
        }, [_vm._v("Action")])])]), _c('draggable', {
          attrs: {
            "tag": "tbody",
            "options": {
              handle: '.drag-handle'
            }
          },
          on: {
            "end": _vm.onMove
          },
          model: {
            value: _vm.reasons.data,
            callback: function callback($$v) {
              _vm.$set(_vm.reasons, "data", $$v);
            },
            expression: "reasons.data"
          }
        }, _vm._l(_vm.reasons.data, function (r, i) {
          return _c('tr', {
            key: "r-".concat(i)
          }, [_c('td', {
            staticClass: "text-center"
          }, [_vm._v(_vm._s(_vm.reasons.meta.currentPage > 1 ? _vm.reasons.meta.perPage + i + 1 : i + 1))]), _c('td', [_vm._v(_vm._s(r.name))]), _c('td', [_vm._v(_vm._s(r.position))]), _c('td', _vm._l(r.vehicleType, function (vehicle, index) {
            return _c('span', {
              key: "vh-".concat(index)
            }, [_vm._v(" " + _vm._s(vehicle.name) + " "), index != r.vehicleType.length - 1 ? _c('span', [_vm._v(", ")]) : _vm._e()]);
          }), 0), _c('td', [_vm._v(_vm._s(r.active ? 'Active' : 'Inactive'))]), _c('td', [_c('div', {
            staticClass: "d-flex mb-2"
          }, [_vm.menuPermission('update') ? _c('b-button', {
            staticClass: "mr-2",
            attrs: {
              "variant": "success",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onEditReason(r);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-edit"
          })]) : _vm._e(), _vm.menuPermission('update') ? _c('b-button', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              modifiers: {
                "hover": true
              }
            }],
            staticClass: "drag-handle",
            attrs: {
              "title": "Drag me!",
              "variant": "primary"
            }
          }, [_c('i', {
            staticClass: "fa fa-hand-pointer"
          })]) : _vm._e()], 1), _vm.menuPermission('delete') ? _c('b-button', {
            attrs: {
              "variant": "danger",
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                return _vm.onDeleteReason(r);
              }
            }
          }, [_c('i', {
            staticClass: "fa fa-trash"
          })]) : _vm._e()], 1)]);
        }), 0)], 1)]) : _vm._e(), _vm.reasons.data && _vm.reasons.data.length > 0 ? _c('div', [_c('b-pagination', {
          attrs: {
            "total-rows": _vm.reasons.meta.total,
            "per-page": _vm.reasons.meta.perPage,
            "first-number": "",
            "align": "center"
          },
          on: {
            "change": _vm.fetchReasons
          },
          model: {
            value: _vm.reasons.meta.currentPage,
            callback: function callback($$v) {
              _vm.$set(_vm.reasons.meta, "currentPage", $$v);
            },
            expression: "reasons.meta.currentPage"
          }
        })], 1) : _c('p', {
          staticClass: "text-center my-4"
        }, [_vm._v("No reasons data found.")])]];
      },
      proxy: true
    }])
  })], 1), _vm.menuPermission('update') || _vm.menuPermission('add') ? _c('b-col', {
    attrs: {
      "sm": "4"
    }
  }, [_c('iq-card', {
    class: {
      'border border-danger shadow-lg': _vm.form_reason_edit_mode
    },
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v(_vm._s(_vm.form_reason_edit_mode ? 'Edit' : 'Add') + " Reason (Idle)")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.submitFormReason.apply(null, arguments);
            }
          }
        }, [_c('b-form-group', {
          attrs: {
            "label": "Reason Name",
            "label-for": "reason_name"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "port_name",
            "type": "text",
            "placeholder": "Reason Name"
          },
          model: {
            value: _vm.form_reason.name,
            callback: function callback($$v) {
              _vm.$set(_vm.form_reason, "name", $$v);
            },
            expression: "form_reason.name"
          }
        })], 1), _c('b-form-group', {
          attrs: {
            "label": "Category For",
            "label-for": "category_for"
          }
        }, [_c('v-multiselect', {
          attrs: {
            "placeholder": "Search categories",
            "label": "name",
            "track-by": "id",
            "options": _vm.fleet_types,
            "multiple": true
          },
          model: {
            value: _vm.form_reason.vehicleType,
            callback: function callback($$v) {
              _vm.$set(_vm.form_reason, "vehicleType", $$v);
            },
            expression: "form_reason.vehicleType"
          }
        }, [_c('span', {
          attrs: {
            "slot": "noResult"
          },
          slot: "noResult"
        }, [_vm._v("Category not found.")])])], 1), _c('b-form-group', {
          attrs: {
            "label": "Sort (Last)",
            "label-for": "sort"
          }
        }, [!_vm.form_reason_edit_mode ? _c('h3', [_vm._v(_vm._s(_vm.lastIndexData))]) : _c('h3', [_vm._v(_vm._s(_vm.form_reason.position))])]), _c('b-form-group', {
          attrs: {
            "label": "Status",
            "label-for": "status"
          }
        }, [_c('b-form-checkbox', {
          attrs: {
            "name": "check-button",
            "switch": "",
            "inline": ""
          },
          model: {
            value: _vm.form_reason.active,
            callback: function callback($$v) {
              _vm.$set(_vm.form_reason, "active", $$v);
            },
            expression: "form_reason.active"
          }
        }, [_vm._v(" " + _vm._s(_vm.form_reason.active ? 'Active' : 'Inactive') + " ")])], 1), _c('hr'), _c('b-form-group', {
          staticClass: "text-right"
        }, [_c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [!_vm.loading_form_reason ? _c('span', [_vm._v(_vm._s(_vm.form_reason_edit_mode || !_vm.menuPermission('add') ? 'Save' : 'Add'))]) : [_c('span', {
          staticClass: "spinner-border spinner-border-sm",
          attrs: {
            "role": "status",
            "aria-hidden": "true"
          }
        }), _vm._v(" Saving... ")]], 2), _c('b-button', {
          staticClass: "iq-bg-danger ml-3",
          attrs: {
            "type": "submit",
            "variant": "none"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.resetFormReason.apply(null, arguments);
            }
          }
        }, [_vm._v("Cancel")])], 1)], 1)];
      },
      proxy: true
    }], null, false, 4176493914)
  })], 1) : _vm._e()], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }