var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('StepWizard', {
    attrs: {
      "current-step": "CLAIM_ANALYSIS"
    }
  }), _c('div', {
    staticClass: "row mb-2"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('b-button', {
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/mi/list-initial');
      }
    }
  }, [_vm._v("Back")])], 1)]), _c('b-row', [_vm.detailMi ? _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "headerTitle",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "card-title text-primary"
        }, [_c('b', [_vm._v("INITIAL CLAIM ANALYSIS")])])];
      },
      proxy: true
    }, {
      key: "headerAction",
      fn: function fn() {
        return [_vm.detailFillSurvey ? [!_vm.isEditMode === true ? _c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.isEditMode = !_vm.isEditMode;
            }
          }
        }, [_vm._v("Edit")]) : _c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              _vm.isEditMode = !_vm.isEditMode;
            }
          }
        }, [_vm._v("Preview")])] : _vm._e()];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('form', {
          attrs: {
            "action": ""
          },
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return _vm.onSave.apply(null, arguments);
            }
          }
        }, [_c('b-row', [_c('b-col', {
          staticClass: "mb-3",
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "row",
          attrs: {
            "label-cols-sm": "3",
            "label": "Fleet *",
            "label-for": "fleets"
          }
        }, [_vm.detailFillSurvey && !_vm.isEditMode ? _c('p', {
          staticClass: "form-control-static mb-0 mt-1"
        }, [_vm._v(_vm._s(_vm.detailMi.vehicle.name))]) : _c('b-form-input', {
          attrs: {
            "readonly": "",
            "value": _vm.detailMi.vehicle.name || '-',
            "type": "text"
          }
        })], 1), _c('b-form-group', {
          staticClass: "row",
          attrs: {
            "label-cols-sm": "3",
            "label": "Cause of Loss *",
            "label-for": "col"
          }
        }, [_vm.detailFillSurvey && !_vm.isEditMode ? _c('p', {
          staticClass: "form-control-static mb-0 mt-1"
        }, [_vm._v(_vm._s(_vm.detailMi.type_loss.name))]) : _c('b-form-input', {
          attrs: {
            "readonly": "",
            "value": _vm.detailMi.type_loss.name || '-',
            "type": "text"
          }
        })], 1), _c('b-form-group', {
          staticClass: "row",
          attrs: {
            "label-cols-sm": "3",
            "label": "Date of Loss (DOL) *",
            "label-for": "dol"
          }
        }, [_vm.detailFillSurvey && !_vm.isEditMode ? _c('p', {
          staticClass: "form-control-static mb-0 mt-1"
        }, [_vm._v(_vm._s(_vm.detailMi.date_of_loss))]) : _c('b-form-input', {
          attrs: {
            "readonly": "",
            "value": _vm.detailMi.date_of_loss || '-',
            "type": "text"
          }
        })], 1)], 1), _c('b-col', {
          staticClass: "mb-3",
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "row",
          attrs: {
            "label-cols-sm": "3",
            "label": "Place of Loss (POL) *",
            "label-for": "pol"
          }
        }, [_vm.detailFillSurvey && !_vm.isEditMode ? _c('p', {
          staticClass: "form-control-static mb-0 mt-1"
        }, [_vm._v(_vm._s(_vm.detailMi.place_of_loss))]) : _c('b-form-input', {
          attrs: {
            "readonly": "",
            "value": _vm.detailMi.place_of_loss || '-',
            "type": "text"
          }
        })], 1), _c('b-form-group', {
          staticClass: "row",
          attrs: {
            "label-cols-sm": "3",
            "label": "Periode of Coverage*",
            "label-for": "periodeOfCoverage"
          }
        }, [_vm.detailFillSurvey && !_vm.isEditMode ? _c('p', {
          staticClass: "form-control-static mb-0 mt-1"
        }, [_vm._v(_vm._s(_vm.form.coverage_period))]) : _c('date-picker', {
          attrs: {
            "type": "year",
            "range": "",
            "value-type": "YYYY",
            "format": "YYYY",
            "placeholder": "Select Year"
          },
          model: {
            value: _vm.form.coverage_period,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "coverage_period", $$v);
            },
            expression: "form.coverage_period"
          }
        })], 1), _c('b-form-group', {
          staticClass: "row",
          attrs: {
            "label-cols-sm": "3",
            "label": "Policy Number *",
            "label-for": "police_number"
          }
        }, [_vm.detailFillSurvey && !_vm.isEditMode ? _c('p', {
          staticClass: "form-control-static mb-0 mt-1"
        }, [_vm._v(_vm._s(_vm.form.police_number))]) : _c('b-form-input', {
          attrs: {
            "required": "",
            "type": "text"
          },
          model: {
            value: _vm.form.police_number,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "police_number", $$v);
            },
            expression: "form.police_number"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('hr')]), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }), _c('b-col', {
          attrs: {
            "md": "8"
          }
        }, [_c('table', {
          staticClass: "table table-dark table-striped text-white"
        }, [_c('tbody', [_c('tr', [_c('td', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("80 - 100")]), _c('td', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "75%"
          }
        }, [_vm._v("Recommended")])]), _c('tr', [_c('td', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("65 - 79")]), _c('td', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "75%"
          }
        }, [_vm._v("With Consideration")])]), _c('tr', [_c('td', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("55 - 64")]), _c('td', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "75%"
          }
        }, [_vm._v("It Needs Special Attention")])]), _c('tr', [_c('td', {
          attrs: {
            "width": "20%"
          }
        }, [_vm._v("0 - 54")]), _c('td', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v(":")]), _c('td', {
          attrs: {
            "width": "75%"
          }
        }, [_vm._v("Not Recommended")])])])])]), _c('b-col', {
          attrs: {
            "md": "2"
          }
        }), _vm.detailSurvey ? _c('b-col', {
          attrs: {
            "md": "12"
          }
        }, [_c('b-table-simple', {
          attrs: {
            "responsive": "",
            "width": "100%",
            "striped": ""
          }
        }, [_c('b-thead', [_c('b-th', {
          attrs: {
            "width": "5%"
          }
        }, [_vm._v("NO")]), _vm._l(_vm.detailSurvey.headers, function (header, headerIndex) {
          return _c('b-th', {
            key: headerIndex,
            attrs: {
              "width": headerIndex === 3 ? '450px' : ''
            }
          }, [_vm._v(_vm._s(header.label_name))]);
        })], 2), _vm.detailSurvey.headers[0] ? _c('b-tbody', [_vm._l(_vm.detailSurvey.headers[0].contents.length, function (number, numberIndex) {
          return _c('b-tr', {
            key: numberIndex
          }, [_c('b-td', [_vm._v(_vm._s(number))]), _vm._l(_vm.detailSurvey.headers, function (header, headerIndexes) {
            return _c('b-td', {
              key: headerIndexes
            }, [header.contents[number - 1] ? [header.contents[number - 1].text ? [_vm._v(" " + _vm._s(header.contents[number - 1].text) + " ")] : header.contents[number - 1].input ? [_vm.detailFillSurvey && !_vm.isEditMode ? [_c('p', {
              staticClass: "form-control-static mb-0 mt-1"
            }, [_vm._v(_vm._s(header.contents[number - 1].input.value) + " ")]), header.contents[number - 1].input.alias === 'max100' ? _c('input', {
              staticClass: "survey-input-max100",
              attrs: {
                "type": "hidden"
              },
              domProps: {
                "value": header.contents[number - 1].input.value
              }
            }) : _vm._e()] : [header.contents[number - 1].input.alias === 'max100' ? _c('b-form-input', {
              staticClass: "survey-input-max100",
              attrs: {
                "type": "number",
                "maxlength": 3,
                "min": 1,
                "max": 100
              },
              on: {
                "blur": function blur($event) {
                  return _vm.onGenerateTotal();
                }
              },
              model: {
                value: header.contents[number - 1].input.value,
                callback: function callback($$v) {
                  _vm.$set(header.contents[number - 1].input, "value", $$v);
                },
                expression: "header.contents[number - 1].input.value"
              }
            }) : header.contents[number - 1].input.alias === 'field' ? _c('b-form-textarea', {
              attrs: {
                "rows": "6"
              },
              model: {
                value: header.contents[number - 1].input.value,
                callback: function callback($$v) {
                  _vm.$set(header.contents[number - 1].input, "value", $$v);
                },
                expression: "header.contents[number - 1].input.value"
              }
            }) : _vm._e()]] : _vm._e()] : _vm._e()], 2);
          })], 2);
        }), _c('b-tr', {
          staticClass: "iq-bg-primary"
        }, [_c('b-td', {
          staticClass: "text-right",
          attrs: {
            "colspan": "3"
          }
        }, [_c('h5', [_c('b', [_vm._v("Nilai Seluruhnya")])])]), _c('b-td', {
          staticClass: "text-center"
        }, [_c('h5', [_c('b', [_vm._v(_vm._s(_vm.totalAll))])])]), _c('b-td', [_vm._v(" ")])], 1)], 2) : _vm._e()], 1)], 1) : _vm._e(), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Conclusion (Kesimpulan)")]), _c('b-form-group', {
          attrs: {
            "label": "Decribe of Conclusion"
          }
        }, [_vm.detailFillSurvey && !_vm.isEditMode ? _c('p', {
          staticClass: "form-control-static mb-0 mt-1"
        }, [_vm._v(_vm._s(_vm.form.conclusion))]) : _c('b-form-textarea', {
          attrs: {
            "id": "exampleFormControlTextarea1",
            "required": "",
            "rows": "5"
          },
          model: {
            value: _vm.form.conclusion,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "conclusion", $$v);
            },
            expression: "form.conclusion"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('h4', {
          staticClass: "card-title"
        }, [_vm._v("Comments or Suggestions (Komentar atau Saran-saran)")]), _c('b-form-group', {
          attrs: {
            "label": "Decribe Comment or Suggestions"
          }
        }, [_vm.detailFillSurvey && !_vm.isEditMode ? _c('p', {
          staticClass: "form-control-static mb-0 mt-1"
        }, [_vm._v(_vm._s(_vm.form.comments) + " ")]) : _c('b-form-textarea', {
          attrs: {
            "id": "exampleFormControlTextarea1",
            "required": "",
            "rows": "5"
          },
          model: {
            value: _vm.form.comments,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "comments", $$v);
            },
            expression: "form.comments"
          }
        })], 1)], 1), _c('b-col', {
          staticClass: "pull-right",
          attrs: {
            "cols": "12",
            "md": "12"
          }
        }, [_vm.detailFillSurvey && !_vm.isEditMode ? _c('p', {
          staticClass: "form-control-static mb-0 mt-1"
        }) : _c('b-button', {
          attrs: {
            "type": "submit",
            "variant": "primary"
          }
        }, [_vm._v("Submit")])], 1)], 1)], 1)];
      },
      proxy: true
    }], null, false, 223793661)
  })], 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }