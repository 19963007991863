var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    staticClass: "mt-4",
    attrs: {
      "cols": "12"
    }
  }, [_c('iq-card', {
    scopedSlots: _vm._u([{
      key: "body",
      fn: function fn() {
        return [_c('b-row', _vm._l(_vm.cards, function (card, index) {
          return _c('b-col', {
            key: index,
            attrs: {
              "md": "4"
            }
          }, [_c('div', {
            staticClass: "p-3 d-flex align-items-center card-container",
            style: {
              borderRadius: '12px',
              background: card.bg
            }
          }, [_c('div', {
            staticClass: "bg-white d-flex justify-content-center align-items-center mr-2",
            staticStyle: {
              "width": "78px",
              "height": "78px",
              "border-radius": "8px"
            }
          }, [_c('img', {
            attrs: {
              "src": require("@/src/assets/images/icon/".concat(card.img)),
              "alt": ""
            }
          })]), _c('div', [_c('h6', {
            staticStyle: {
              "color": "#fff000"
            }
          }, [_c('strong', [_vm._v(_vm._s(card.title))])]), _c('h5', {
            staticClass: "text-white"
          }, [_c('strong', [_vm._v(_vm._s(card.value))])]), _c('p', {
            staticClass: "mb-0 text-xs text-white"
          }, [_vm._v("Percentage : "), _c('strong', [_vm._v(_vm._s(card.percent))])])])])]);
        }), 1)];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }