<template>
  <svg
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_128_12980)">
      <path
        d="M20.4939 25.7002C23.1203 25.7002 25.1631 23.5602 25.1631 20.9338C25.1631 18.3074 23.0231 16.2646 20.3967 16.2646C17.7703 16.2646 15.7275 18.4047 15.7275 20.9338C15.7275 23.5602 17.8676 25.7002 20.4939 25.7002ZM20.3967 18.2101C20.4939 18.2101 20.4939 18.2101 20.3967 18.2101C21.953 18.2101 23.2176 19.4747 23.2176 21.0311C23.2176 22.5874 21.953 23.7547 20.3967 23.7547C18.8403 23.7547 17.673 22.4902 17.673 21.0311C17.673 19.4747 18.9376 18.2101 20.3967 18.2101Z"
        :fill="color"
        fill-opacity="0.819"
      />
      <path
        d="M34.8899 25.1169C33.0417 23.4632 30.6099 22.5878 28.0808 22.6851H27.3026C27.108 23.4632 26.8162 24.1442 26.4271 24.7278C27.0108 24.6305 27.4971 24.6305 28.0808 24.6305C29.929 24.5333 31.7772 25.1169 33.2363 26.1869V33.1906H35.1817V25.4087L34.8899 25.1169Z"
        :fill="color"
        fill-opacity="0.819"
      />
      <path
        d="M25.8441 16.4583C26.3305 15.291 27.6923 14.7074 28.9569 15.1937C30.1242 15.6801 30.7078 17.0419 30.2214 18.3065C29.8323 19.1819 28.9569 19.7656 28.0814 19.7656C27.8869 19.7656 27.5951 19.7656 27.4005 19.6683C27.4978 20.1547 27.4978 20.6411 27.4978 21.0301V21.6138C27.6923 21.6138 27.8869 21.7111 28.0814 21.7111C30.5133 21.7111 32.4587 19.7656 32.4587 17.431C32.4587 14.9992 30.5133 13.0537 28.1787 13.0537C26.6223 13.0537 25.2605 13.8319 24.4823 15.1937C24.9687 15.4856 25.455 15.8746 25.8441 16.4583Z"
        :fill="color"
        fill-opacity="0.819"
      />
      <path
        d="M14.7546 24.8255C14.3655 24.2418 14.0737 23.5609 13.8792 22.7827H13.101C10.5719 22.6854 8.14002 23.5609 6.29182 25.1173L6 25.4091V33.191H7.94547V26.1873C9.50185 25.1173 11.2528 24.5336 13.101 24.6309C13.6846 24.6309 14.2683 24.7282 14.7546 24.8255Z"
        :fill="color"
        fill-opacity="0.819"
      />
      <path
        d="M13.101 21.6146C13.2956 21.6146 13.4901 21.6146 13.6846 21.5173V20.9337C13.6846 20.4473 13.6846 19.9609 13.7819 19.5718C13.5874 19.6691 13.2956 19.6691 13.101 19.6691C11.8365 19.6691 10.7664 18.5991 10.7664 17.3345C10.7664 16.07 11.8365 15 13.101 15C14.0737 15 14.9492 15.5836 15.3383 16.4591C15.7274 15.9727 16.311 15.4863 16.7974 15.0972C15.5328 13.0545 12.9065 12.3736 10.8637 13.6381C8.82097 14.9027 8.14006 17.5291 9.40461 19.5718C10.1828 20.8364 11.5446 21.6146 13.101 21.6146Z"
        :fill="color"
        fill-opacity="0.819"
      />
      <path
        d="M28.4705 30.954L28.276 30.6621C26.3305 28.5221 23.6068 27.2576 20.6886 27.3548C17.7704 27.2576 14.9495 28.5221 13.004 30.6621L12.8094 30.954V38.3468C12.8094 39.2222 13.4904 40.0004 14.4631 40.0004H26.9141C27.7896 40.0004 28.5678 39.2222 28.5678 38.3468V30.954H28.4705ZM26.525 38.0549H14.7549V31.6349C16.3113 30.0785 18.4513 29.3003 20.6886 29.3003C22.8286 29.203 24.9686 30.0785 26.525 31.6349V38.0549Z"
        :fill="color"
        fill-opacity="0.819"
      />
    </g>
    <rect
      x="1.25"
      y="0.75"
      width="38.5"
      height="38.5"
      rx="19.25"
      :stroke="color"
      stroke-width="1.5"
    />
    <defs>
      <clipPath id="clip0_128_12980">
        <rect x="0.5" width="40" height="40" rx="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      default: '#02B3B7',
      type: String,
    },
  },
}
</script>
