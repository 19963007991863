<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items
                  class="col-md-6 p-0"
                  :active="true"
                  href="#data"
                  title="MASTER DATA"
                />
                <tab-nav-items
                  class="col-md-6 p-0"
                  :active="false"
                  href="#pfa"
                  title="PFA"
                />  
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- <b-col lg="12" class="mb-4">
        <div class="d-flex w-100 align-items-center justify-content-center rounded bg-primary p-4">
          <h4 class="text-white"><strong>Master Pre Fixture Analysis (PFA)</strong></h4>
        </div>
      </b-col> -->
      <b-col lg="12">
        <div id="pills-tabContent-2" class="tab-content">
          <tab-content-item :active="true" id="data">
            <tab-nav :tabs="true" align="center">
              <tab-nav-items :active="true" href="#component-expense" title="Component Expense" />
              <tab-nav-items :active="false" href="#time-voyage" title="Time Voyage" />
              <tab-nav-items :active="false" href="#charter-company" title="Charter Company" />
              <tab-nav-items :active="false" href="#cost-factor" title="Cost Factor" />
              <tab-nav-items :active="false" href="#miscellaneous-charge" title="Miscellaneous Charge" />
              <tab-nav-items :active="false" href="#port-stay" title="Port Stay" />
              <tab-nav-items :active="false" href="#result-plus" title="Result Plus" />
            </tab-nav>
            <div id="myTabContent2" class="tab-content">
              <tab-content-item :active="true" id="component-expense">
                <MasterComponent />
              </tab-content-item>
              <tab-content-item :active="false" id="time-voyage">
                <MasterTimeVoyage />
              </tab-content-item>
              <tab-content-item :active="false" id="charter-company">
                <MasterCharterCompany />
              </tab-content-item>
              <tab-content-item :active="false" id="cost-factor">
                <MasterCostFactor />
              </tab-content-item>
              <tab-content-item :active="false" id="miscellaneous-charge">
                <MasterMiscellaneousCharge />
              </tab-content-item>
              <tab-content-item :active="false" id="port-stay">
                <MasterPortStay />
              </tab-content-item>
              <tab-content-item :active="false" id="result-plus">
                <MasterResultPlus />
              </tab-content-item>
            </div>
          </tab-content-item>
          <tab-content-item :active="false" id="pfa">
            <Pfa />
          </tab-content-item>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { busdevActions } from '@src/Utils/helper'
import _ from 'lodash'
import MasterCharterCompany from './MasterCharterCompany.vue'
import MasterTimeVoyage from './MasterTimeVoyage.vue'
import MasterCostFactor from './MasterCostFactor.vue'
import MasterPortStay from './MasterPortStay.vue'
import MasterResultPlus from './MasterResultPlus.vue'
import MasterMiscellaneousCharge from './MasterResultPlus.vue'
import MasterComponent from './MasterComponent.vue'
import Pfa from './Pfa.vue'

export default {
  name: 'MasterPFA',
  data () {
    return {
   
    }
  },
  components:{
    MasterCharterCompany,
    MasterTimeVoyage,
    MasterCostFactor,
    MasterPortStay,
    MasterResultPlus,
    MasterMiscellaneousCharge,
    MasterComponent,
    Pfa,
  },

  methods: {
    ...busdevActions,
  }
}
</script>

<style scoped>
</style>
