<template>
  <b-row>
    <b-col sm="8">
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title text-primary"><strong>Job Category</strong></h4>
        </template>
        <template v-slot:body>
          <b-form class="d-flex mb-3">
            <b-form-input
              id="search_job_category"
              v-model="lists.params.name"
              style="width: 200px;"
              type="text"
              placeholder="Type Here..."
            ></b-form-input>
            <b-button
              @click.prevent="getJobCategoriesData()"
              type="submit"
              variant="primary"
              class="ml-2"
              ><i class="fa fa-search"></i
            ></b-button>
          </b-form>
          <b-row>
            <b-col cols="12">
              <div class="table-responsive">
                <table class="table mb-0 table-striped table-hover">
                  <thead class="thead-dark text-center">
                    <tr>
                      <th>No.</th>
                      <th>Job Category</th>
                      <th>Fleet Category</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in lists.data" :key="index">
                      <td>{{ index + 1 }}.</td>
                      <td>{{ data.name }}</td>
                      <td v-if="data.vehicleType.length > 0">
                        <div
                          v-for="(type, typeIndex) in data.vehicleType"
                          :key="typeIndex"
                        >
                          <b-badge variant="primary">{{ type.name }}</b-badge>
                          <br />
                        </div>
                      </td>
                      <td v-else>-</td>
                      <td>
                        <b-badge
                          v-if="data.active"
                          class="border border-success text-success"
                          variant="none"
                          >Active</b-badge
                        >
                        <b-badge
                          v-else
                          class="border border-danger text-danger"
                          variant="none"
                          >InActive</b-badge
                        >
                      </td>
                      <td>
                        <span
                          class="text-info mr-1 cursor-pointer"
                          @click="getJobCategoryDetailData(data.id)"
                          ><i class="fa fa-edit"
                        /></span>
                        <!--                      <span class="text-danger cursor-pointer" @click="deleteJobCategoryData(data.id)"><i class="fa fa-trash" /></span>-->
                      </td>
                    </tr>
                    <tr v-if="lists.data.length === 0 && !lists.isLoading">
                      <td colspan="5"> No Data </td>
                    </tr>
                    <tr v-if="lists.isLoading">
                      <td colspan="5"> Loading </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-col>
            <b-col class="mt-4">
              <b-pagination
                :value="lists.meta.currentPage"
                v-model="lists.meta.currentPage"
                :total-rows="lists.meta.total"
                @change="getJobCategoriesData"
                :per-page="lists.meta.perPage"
                first-number
                align="center"
              ></b-pagination>
            </b-col>
          </b-row>
        </template>
      </iq-card>
    </b-col>
    <b-col sm="4">
      <iq-card
        :class="{
          'border border-danger shadow-lg': form.id,
        }"
      >
        <template v-slot:headerTitle>
          <h4 class="card-title text-primary"
            ><strong>{{ form.id ? 'Edit' : 'Add' }} Job Category</strong></h4
          >
        </template>
        <template v-slot:body>
          <b-form @submit.prevent="onSave()">
            <b-form-group
              label="Job Category Name*"
              label-for="job_category_name"
            >
              <b-form-input
                id="Job Category Name"
                required
                v-model="form.name"
                type="text"
                placeholder="Job Name"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label-for="status-category"
              label="Status, Active / Inactive"
            >
              <b-form-checkbox
                required
                v-model="form.active"
                switch
                :value="true"
                :unchecked-value="false"
              >
              </b-form-checkbox>
            </b-form-group>
            <b-form-group label="Fleet Category*" label-for="job_id">
              <v-select
                required
                label="text"
                :options="vehicleTypes"
                :reduce="(type) => type.value"
                v-model="form.vehicleTypeId"
              ></v-select>
            </b-form-group>
            <b-form-group class="text-right">
              <b-button :disabled="form.saving" type="submit" variant="primary">
                <span>Save</span>
              </b-button>
              <b-button
                @click="resetForm()"
                variant="danger"
                class="iq-bg-danger ml-3"
                >Cancel</b-button
              >
            </b-form-group>
          </b-form>
        </template>
      </iq-card>
    </b-col>
  </b-row>
</template>

<script>
import { fleetsActions, dockingActions } from '@src/Utils/helper'

export default {
  name: 'DockingMasterDataJobCategory',
  components: {},

  data() {
    return {
      vehicleTypes: [],
      lists: {
        isLoading: true,
        data: [],
        params: {
          name: '',
          page: 1,
          perPage: 1000,
          isActive: true,
        },
        meta: {
          perPage: 0,
          currentPage: 0,
          total: 0,
          totalPage: 0,
        },
      },

      form: {
        id: null,
        saving: false,
        name: '',
        active: true,
        vehicleTypeId: '',
      },
      tempDataLists: [],
    }
  },
  mounted() {
    this.getJobCategoriesData()
    this.getVehicleTypes()
  },
  methods: {
    ...dockingActions,
    ...fleetsActions,
    async getVehicleTypes() {
      let params = { active: true }
      let res = await this.getFleetTypes(params)

      let data = []

      if (res.data) {
        res.data.forEach((f) => {
          data.push({
            checked: true,
            value: f.id,
            text: f.name,
            disabled: false,
          })
        })
      }

      this.vehicleTypes = data
    },
    async getJobCategoriesData(page = 1) {
      this.lists.params.page = page
      let res = await this.getJobCategories(this.lists.params)
      this.lists.isLoading = true

      if (res.status === 'success') {
        if (res.data) {
          let newArr = res.data.data.reduce((acc, obj) => {
            let name = obj.name
            let existingObj = acc.find((val) => val.name === name)

            if (existingObj) {
              existingObj.vehicleType.push(obj.vehicleType)
            } else {
              acc.push({
                ...obj,
                vehicleType: [obj.vehicleType],
              })
            }
            return acc
          }, [])

          this.lists.data = newArr
          this.tempDataLists = res.data.data

          this.lists.meta.perPage = res.data.perPage
          this.lists.meta.currentPage = res.data.currentPage
          this.lists.meta.total = res.data.total
          this.lists.meta.totalPage = res.data.totalPage
        }
        this.lists.isLoading = false
      } else {
        this.lists.data = []
        this.lists.isLoading = false
        this.$swal(`Oops!`, res.data, 'error')
      }
    },
    async getJobCategoryDetailData(id) {
      if (id) {
        const { status, data } = await this.getJobCategoryDetail({
          id,
        })

        if (status === 'success') {
          if (data) {
            this.form.id = data.id
            this.form.name = data.name
            this.form.active = data.active
            this.form.vehicleTypeId = data.vehicleType
              ? data.vehicleType.id
              : ''
          }
        }
      }
    },
    resetForm() {
      this.form = {
        id: null,
        saving: false,
        name: '',
        active: false,
        vehicleTypeId: [],
      }
    },
    async onSave() {
      this.form.saving = true

      let method = 'saveJobCategory'

      if (this.form.id) {
        method = 'updateJobCategory'
      }

      const { status } = await this[method](this.form)

      if (status !== 'success') {
        this.$swal(`Oops!`, 'Semua Field Wajib Diisi', 'error')
        this.form.saving = false
        return
      }

      this.$swal(`Tersimpan`, 'Data Tersimpan', 'success')

      this.resetForm()
      this.getJobCategoriesData(1)

      this.form.saving = false
    },
    async deleteJobCategoryData(id) {
      if (id) {
        this.$swal({
          title: 'Yakin menghapus data ?',
          text: `Periksa Kembali Seluruh Data`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Hapus',
        }).then(async (result) => {
          if (result.isConfirmed) {
            const { status, data } = await this.deleteJobCategory({
              id,
            })

            if (status === 'success') {
              this.$swal(`Data Terhapus`, '', 'success')
              await this.getJobCategoriesData(1)
            } else {
              this.$swal(`Oops!`, data.message, 'error')
            }
          }
        })
      }
    },
  },
}
</script>

<style scoped></style>
